import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import IconButton from "../../../shared/v2/IconButton";
import { Cancel, Check, PencilSolidV6, Trash } from "../../../shared/v2/Icomoon";
import formatter from "../../../shared/formatters";
import { TD, TR } from "../../../Reports/components/BusinessDashboard/Table/Table";
import Button from "../../../shared/v2/Button";
import TextInput from "../../../shared/v2/TextInput";
import NumberField from "../../../Goals/components/NumberField";
import DatePicker from "../../../shared/v2/DatePicker";

export const ROW_BORDER = "tw-border-solid tw-border-gray-10 tw-border-b-[1px] tw-border-x-0 tw-border-t-0";
const FROM_FORMAT = "YYYY-MM-DD";
const TO_FORMAT = "MM/DD/YYYY";

const ActivityHistoryRow = ({ activity, isEditable, onDelete, onUpdate }) => {
  const [showDeleteCover, setShowDeleteCover] = useState(false);
  const [tempActivity, setTempActivity] = useState(null);
  const setValue = (name, value) => setTempActivity((prevValue) => ({ ...prevValue, [name]: value }));

  return (
    <TR key={activity.id} className="tw-relative tw-align-top" wrapText>
      <TD className={ROW_BORDER}>{activity.user.fullName}</TD>

      <TD className={`${ROW_BORDER} tw-text-center`}>
        {tempActivity ? (
          <NumberField
            defaultValue={tempActivity.amount}
            format={activity.goalType.format}
            onChange={(value) => setValue("amount", value)}
          />
        ) : (
          formatter[activity.goalType.format](activity.amount)
        )}
      </TD>

      <TD className={ROW_BORDER}>
        {tempActivity ? (
          <DatePicker
            value={moment(tempActivity.activity_date, FROM_FORMAT).format(TO_FORMAT)}
            onChange={(value) => setValue("activity_date", moment(value, TO_FORMAT).toISOString())}
            inputProps={{ size: 10 }}
          />
        ) : (
          moment(activity.activityDate, FROM_FORMAT).format(TO_FORMAT)
        )}
      </TD>

      <TD className={ROW_BORDER} colSpan={isEditable ? 0 : 3}>
        <div className="tw-min-w-[200px] tw-max-w-[400px] tw-w-full">
          {tempActivity ? (
            <TextInput
              multiline
              value={tempActivity.note_content}
              onChange={(e) => setValue("note_content", e.target.value)}
            />
          ) : (
            activity.notes?.[0]?.content
          )}
        </div>
      </TD>

      {isEditable && (
        <>
          <TD className={ROW_BORDER}>
            {tempActivity ? (
              <IconButton
                onClick={() => {
                  onUpdate(tempActivity);
                  setTempActivity(null);
                }}
              >
                <Check />
              </IconButton>
            ) : (
              <IconButton
                schema="tertiary"
                onClick={() =>
                  setTempActivity({
                    id: activity.id,
                    amount: activity.amount,
                    activity_date: activity.activityDate,
                    note_content: activity.notes?.[0]?.content || "",
                  })
                }
              >
                <PencilSolidV6 />
              </IconButton>
            )}
          </TD>

          <TD className={ROW_BORDER}>
            {tempActivity ? (
              <IconButton schema="tertiary" onClick={() => setTempActivity(null)}>
                <Cancel />
              </IconButton>
            ) : (
              <IconButton schema="misc-trash" onClick={() => setShowDeleteCover(true)}>
                <Trash />
              </IconButton>
            )}
          </TD>
        </>
      )}

      {showDeleteCover && (
        <td className="tw-absolute tw-inset-0 tw-bg-gray-10 tw-bg-opacity-75 tw-p-[12px] tw-flex tw-justify-end tw-gap-[16px]">
          <Button
            schema="warning"
            onClick={() => {
              setShowDeleteCover(false);
              onDelete(activity);
            }}
          >
            Delete
          </Button>
          <Button schema="tertiary" onClick={() => setShowDeleteCover(false)}>
            Cancel
          </Button>
        </td>
      )}
    </TR>
  );
};

ActivityHistoryRow.propTypes = {
  activity: PropTypes.objectOf({}).isRequired,
  isEditable: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ActivityHistoryRow;
