import React from "react";
import PropTypes from "prop-types";

const LABEL_CLASSES = `
  bs-print:tw-border bs-print:tw-border-solid bs-print:tw-border-black
  tw-inline tw-p-0 tw-text-10px tw-font-bold tw-leading-none
  tw-text-gray-base tw-text-center tw-whitespace-nowrap tw-align-baseline tw-rounded-0.25em
  tw-uppercase tw-temp-label-font-family
`;

const LABEL_B_CLASSES =
  "tw-uppercase tw-text-gray-base tw-text-10px tw-leading-none tw-temp-label-font-family";

export const Label = ({ children, className, bold, htmlFor, ...props }) =>
  bold ? (
    <label htmlFor={htmlFor}>
      <b className={`${LABEL_B_CLASSES} ${className}`} {...props}>
        {children}
      </b>
    </label>
  ) : (
    <label htmlFor={htmlFor} className={`${LABEL_CLASSES} ${className}`} {...props}>
      {children}
    </label>
  );
Label.propTypes = {
  children: PropTypes.node,
  bold: PropTypes.bool,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};
Label.defaultProps = {
  children: null,
  bold: false,
  className: "",
  htmlFor: null,
};

export default Label;
