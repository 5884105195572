import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import AssignedPlanDetail from "./AssignedPlanDetail";
import {
  PlayOrPauseButton,
  TrashIcon,
} from "../../TransactionDetailsPage/SideRight/AutoPlans/Components/AssignedPlanIconButtons";

const PlanModal = ({
  activeAssignedActionId,
  assignedPlan,
  closeModal,
  deleteAssignedAction,
  getAssignedPlan,
  isAssignedActionUpdating,
  isDeleteAssignedActionModalOpen,
  isPlanModalOpen,
  previewAssignedAction,
  showDeleteAssignedActionModal,
  showDeleteAssignedPlanModal,
  updateAssignedAction,
  updateAssignedPlan,
}) => {
  const updateCallback = () => {
    getAssignedPlan(`/assigned_plans/${assignedPlan.id}`);
  };

  const updatePlanState = (state) => {
    const form = { state };
    updateAssignedPlan(`/assigned_plans/${assignedPlan.id}`, form, updateCallback);
  };

  return (
    <Modal
      show={isPlanModalOpen}
      onHide={() => closeModal()}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-w-[983px] tw-space-y-32px tw-m-auto"
    >
      <Modal.Header
        closeButton
        size="small"
        title={
          <div className="tw-flex tw-gap-[16px]">
            <span>
              {`Auto Plan: ${assignedPlan.name}`}
              <span className={`state ${assignedPlan.state}`} />
            </span>

            {assignedPlan.state !== "deleted" && (
              <div className="tw-flex tw-gap-[8px]">
                <PlayOrPauseButton
                  isPaused={assignedPlan.state === "paused"}
                  onClick={() => updatePlanState(assignedPlan.state === "paused" ? "started" : "paused")}
                />
                <TrashIcon onClick={() => showDeleteAssignedPlanModal(assignedPlan)} />
              </div>
            )}
          </div>
        }
      />

      <Modal.Body className="tw-h-[60vh] tw-flex tw-border-solid tw-border-0 tw-border-t tw-border-b tw-border-neutral-gray-10">
        <AssignedPlanDetail
          isAssignedActionUpdating={isAssignedActionUpdating}
          assignedPlan={assignedPlan}
          previewAssignedAction={previewAssignedAction}
          activeAssignedActionId={activeAssignedActionId}
          onActionUpdate={updateAssignedAction}
          onActionDelete={deleteAssignedAction}
          onShowDeleteModal={showDeleteAssignedActionModal}
          isDeleteModalOpen={isDeleteAssignedActionModalOpen}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PlanModal;

PlanModal.propTypes = {
  activeAssignedActionId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteAssignedAction: PropTypes.func.isRequired,
  getAssignedPlan: PropTypes.func.isRequired,
  isAssignedActionUpdating: PropTypes.bool.isRequired,
  isDeleteAssignedActionModalOpen: PropTypes.bool.isRequired,
  isPlanModalOpen: PropTypes.bool.isRequired,
  previewAssignedAction: PropTypes.func.isRequired,
  showDeleteAssignedActionModal: PropTypes.func.isRequired,
  showDeleteAssignedPlanModal: PropTypes.func.isRequired,
  updateAssignedAction: PropTypes.func.isRequired,
  updateAssignedPlan: PropTypes.func.isRequired,
};
