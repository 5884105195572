import React, { useEffect, useRef } from "react";

import ListingCard from "./ListingCard";

const ListingGrid = ({
  listings,
  highlightedListing,
  selectedListings,
  maxListingsSelected,
  availableHeight,
  onToggleShare,
  onShowDetails,
  buyerMatches
}) => {
  const cardRefs = useRef({});

  const boxShadow = { boxShadow: "0px 2px 8px rgba(213, 213, 213, 0.5)" };

  useEffect(() => {
    if (cardRefs.current[highlightedListing]) {
      cardRefs.current[highlightedListing].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedListing]);

  return (
    <div
      style={{ maxHeight: `${availableHeight}px` }}
      className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0"
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, 320px)`,
          rowGap: "24px",
          columnGap: "20px",
          paddingBottom: "8px"
        }}
      >
        {listings.length > 0 &&
          listings.map((listing) => (
            <div
              key={listing.blossorId}
              style={boxShadow}
              className={`tw-rounded-4px tw-w-[320px] tw-cursor-pointer ${
                listing.blossorId === highlightedListing
                  ? "tw-border-teal tw-border-1px tw-border-solid tw-border-opacity-50"
                  : ""
              }`}
              onClick={() => onShowDetails(listing.blossorId)}
              ref={(e) => (cardRefs.current[listing.blossorId] = e)}
            >
              <ListingCard
                listing={listing}
                isChecked={selectedListings.some((l) => l.blossorId === listing.blossorId)}
                maxListingsSelected={maxListingsSelected}
                size="medium"
                includeNewBanner
                onToggleShare={onToggleShare}
                buyerMatches={buyerMatches[listing.blossorId]}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListingGrid;
