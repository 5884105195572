/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PenSolidV6 = (props) => (
  <Icon {...props} name="Pen Solid V6">
    <path d="M11.334.604a2.003 2.003 0 0 1 2.831 0l1.231 1.232a2.002 2.002 0 0 1 0 2.83l-1.512 1.513-4.063-4.062L11.334.605zm1.844 6.28L5.89 14.168a2.738 2.738 0 0 1-1.169.694l-3.76 1.106a.705.705 0 0 1-.742-.216c-.194-.166-.267-.45-.189-.716l1.106-3.759c.13-.441.368-.844.694-1.169l7.285-7.286 4.063 4.061z" />
  </Icon>
);

export default PenSolidV6;
