import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DocumentListItem from "@shared/ModalUpload/DocumentListItem";

import { DocumentPropType, MetaPropType } from "../propTypes";

import Button from "../../../../shared/v2/Button";
import Checkbox from "../../../../shared/v2/Checkbox";
import IconButton from "../../../../shared/v2/IconButton";
import { CaretUp, CaretDown, Download, Trash, Upload as UploadIcon } from "../../../../shared/v2/Icomoon";

import {
  toggleUploadModal,
  toggleDeleteModal,
  toggleDocumentsSelected,
  setDocumentIndex,
  renameDocumentError,
} from "../actions/creators";
import { renameDocumentAsThunk, sortDocumentsAsThunk } from "../actions/thunks";

const headerClassNames = {
  th: "tw-text-neutral-gray-50 tw-text-12d tw-font-semibold",
};

const getColor = (column, orderBy, orderDir, direction) => {
  if (orderBy !== column) return "tw-text-neutral-gray-50";
  if ((orderDir === "asc" && direction === "asc") || (orderDir === "desc" && direction === "desc"))
    return "tw-text-neutral-gray-100";

  return "tw-text-neutral-gray-50";
};

const UpDown = ({ column, orderBy, orderDir }) => (
  <div className="tw-flex tw-flex-col tw-ml-4px">
    <CaretUp className={`${getColor(column, orderBy, orderDir, "asc")} tw--mb-5px`} size="m" />
    <CaretDown className={getColor(column, orderBy, orderDir, "desc")} size="m" />
  </div>
);

UpDown.propTypes = {
  column: PropTypes.string,
  orderBy: PropTypes.string,
  orderDir: PropTypes.string,
};

UpDown.defaultProps = {
  column: "filename",
  orderBy: "date",
  orderDir: "desc",
};

const Table = ({ dispatch, search, documents, meta, isFetchingDocuments, errors }) => {
  const context = "documents-table";

  const [orderBy, setOrderBy] = useState("date");
  const [orderDir, setOrderDir] = useState("desc");

  const allChecked = useMemo(() => documents.every((doc) => doc.isSelected), [documents]);

  const shouldOpenBulkActions = useMemo(() => documents.some((doc) => doc.isSelected), [documents]);

  const handleOnChangeAll = (checkStatus) => {
    dispatch(
      toggleDocumentsSelected(
        context,
        documents.map((doc) => doc.id),
        checkStatus,
      ),
    );
  };

  const handleSelect = (checkedStatus, documentId) => {
    dispatch(toggleDocumentsSelected(context, [documentId], checkedStatus));
  };

  const handleSortDocumentsByFilename = () => {
    setOrderBy("filename");
    const newOrderDir = orderDir === "desc" ? "asc" : "desc";
    setOrderDir(newOrderDir);
    dispatch(sortDocumentsAsThunk("file_name", newOrderDir));
  };

  const handleSortDocumentsByTimeUpload = () => {
    setOrderBy("date");
    const newOrderDir = orderDir === "desc" ? "asc" : "desc";
    setOrderDir(newOrderDir);
    dispatch(sortDocumentsAsThunk("created_at", newOrderDir));
  };

  const handleBulkDelete = () => {
    const documentIds = documents.filter((doc) => doc.isSelected).map((doc) => doc.id);
    dispatch(toggleDeleteModal(documentIds, false));
  };

  const handleBulkDownload = () => {
    const documentsToDownload = documents.filter((doc) => doc.isSelected);

    documentsToDownload.forEach((doc) => {
      // TODO the ideal solution would be to create a zip file with all the documents

      // This requires the user to accept popups
      window.open(doc.expiringUrl, "_blank");
    });
  };

  const handleKeyDownSortByFilename = (e) => {
    if (e.key === "Enter") {
      handleSortDocumentsByFilename();
    }
  };

  const handleKeyDownSortByTimeUpload = (e) => {
    if (e.key === "Enter") {
      handleSortDocumentsByFilename();
    }
  };

  const handleRenameDocument = (documentId, newName) => {
    dispatch(renameDocumentAsThunk(context, documentId, newName));
  };

  const handleDeleteDocuments = (documentIds) => {
    dispatch(toggleDeleteModal(documentIds, false));
  };

  const handlePreview = (index) => {
    dispatch(setDocumentIndex(context, index));
  };

  const clearDocumentErrors = (documentId) => {
    dispatch(renameDocumentError(context, documentId, []));
  };

  if (isFetchingDocuments) {
    return <p>Loading documents...</p>;
  }

  if (errors.length) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-py-72px" data-cy="documents-error-container">
        <p className="tw-text-center tw-text-semantic-red-100" data-cy="documents-error-message">
          {errors.join(" ")}
        </p>
      </div>
    );
  }

  return (
    <div className="tw-mb-30px">
      {documents.length > 0 ? (
        <table className="tw-table-fixed tw-w-full" data-cy={context}>
          <thead className="tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-b-1px tw-border-solid tw-border-gray-10">
            <tr>
              <th className="tw-w-30px">
                <div className="tw-flex tw-items-center">
                  <Checkbox
                    aria-label="check all"
                    id="tdp-documents-check-all"
                    name="tdp-documents-check-all"
                    value="tdp-documents-check-all"
                    checked={allChecked}
                    onChange={(e) => {
                      handleOnChangeAll(e.target.checked);
                    }}
                    data-cy="documents-table-select-all"
                  />
                </div>
              </th>
              <th className={`${headerClassNames.th} tw-w-full tw-h-40px`}>
                <div className="tw-flex tw-items-center">
                  <div
                    role="menuitem"
                    tabIndex={0}
                    onKeyDown={handleKeyDownSortByFilename}
                    onClick={handleSortDocumentsByFilename}
                    className="tw-cursor-pointer tw-flex"
                    data-cy="documents-sort-by-filename"
                  >
                    <span>Name</span> <UpDown column="filename" orderBy={orderBy} orderDir={orderDir} />
                  </div>
                  {shouldOpenBulkActions && (
                    <div
                      className="tw-flex tw-items-center tw-ml-10px"
                      data-cy="documents-table-bulk-actions"
                    >
                      <IconButton
                        schema="tertiary"
                        size="small"
                        className="tw-mr-8px"
                        onClick={handleBulkDownload}
                        data-cy="documents-table-download-button"
                      >
                        <Download size="m" />
                      </IconButton>
                      <IconButton
                        schema="misc-trash"
                        size="small"
                        onClick={handleBulkDelete}
                        data-cy="documents-table-delete-button"
                      >
                        <Trash className="tw-text-neutral-gray-75" size="m" />
                      </IconButton>
                    </div>
                  )}
                </div>
              </th>
              <th className={`${headerClassNames.th} tw-w-80px tw-text-center tw-invisible`}>Viewers</th>
              <th className={`${headerClassNames.th} tw-w-[100px] tw-text-center`}>Uploaded by</th>
              <th className={`${headerClassNames.th} tw-w-[120px]`}>
                <div
                  role="menuitem"
                  tabIndex={0}
                  onKeyDown={handleKeyDownSortByTimeUpload}
                  onClick={handleSortDocumentsByTimeUpload}
                  className="tw-cursor-pointer tw-flex tw-items-center"
                  data-cy="documents-sort-by-created-at"
                >
                  <span>Time Uploaded</span> <UpDown column="date" orderBy={orderBy} orderDir={orderDir} />
                </div>
              </th>
              <th className={`${headerClassNames.th} tw-w-24px tw-text-center tw-invisible`}>Context</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <DocumentListItem
                key={doc.id}
                context={context}
                document={doc}
                index={index}
                meta={meta}
                isSelected={doc.isSelected}
                renameDocument={handleRenameDocument}
                deleteDocuments={handleDeleteDocuments}
                onSelect={handleSelect}
                clearDocumentErrors={clearDocumentErrors}
                handlePreview={() => handlePreview(index)}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div
          className="tw-flex tw-flex-col tw-text-center tw-items-center tw-pt-72px tw-pb-72px"
          data-cy="documents-empty-container"
        >
          {search !== "" ? (
            <p data-cy="documents-empty-message">
              We didn&apos;t find any documents when searching for <b>{search}</b>.
            </p>
          ) : (
            <>
              <p data-cy="documents-empty-message">No documents have been uploaded.</p>
              <Button
                schema="secondary"
                size="medium"
                className="tw-uppercase tw-px-20px tw-flex tw-w-fit"
                onClick={() => {
                  dispatch(toggleUploadModal(true));
                }}
              >
                <span className="tw-pr-4px">Upload</span> <UploadIcon size="l" />
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  dispatch: PropTypes.func,
  search: PropTypes.string,
  documents: PropTypes.arrayOf(DocumentPropType),
  meta: MetaPropType,
  isFetchingDocuments: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
};

Table.defaultProps = {
  dispatch() {},
  search: "",
  documents: [],
  meta: [],
  isFetchingDocuments: false,
  errors: [],
};

const mapStateToProps = (state) => ({
  search: state.tdpDocumentsReducer.search,
  documents: state.tdpDocumentsReducer.documents,
  meta: state.tdpDocumentsReducer.meta,
  isFetchingDocuments: state.tdpDocumentsReducer.isFetchingDocuments,
  errors: state.tdpDocumentsReducer.errors,
});

export default connect(mapStateToProps)(Table);
