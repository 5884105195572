import React, { useRef, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import statusIntentCss from "./statusIntentDropdown.module.css";
import colors from "./colors.module.css";
import { useClickOutside } from "../hookHelpers";
import Button from "@shared/v2/Button";

export const getStatusClass = (stat) => {
  switch (stat) {
    case "new":
      return colors.statusNew;
    case "unqualified":
      return colors.statusUnqual;
    case "hot":
      return colors.statusHot;
    case "watch":
      return colors.statusWatch;
    case "nurture":
      return colors.statusNurt;
    case "past-client":
      return colors.statusPast;
    case "past":
      return colors.statusPast;
    case "inactive":
      return colors.statusInactive;
    case "trash":
      return colors.statusTrash;
    case "archived":
      return colors.statusArchived;
    case "pending":
      return colors.statusPending;
    case "active-client":
      return colors.statusActiveClient;
    case "prospective-client":
      return colors.statusProspectiveClient;
    default:
      return colors.statusOther;
  }
};

const StatusDropdown = ({ className, btnClassName, onChange, statusOptions, status, person, isLoading }) => {
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);
  useClickOutside(ref, () => setExpanded(false));

  const updateValue = (value) => {
    setExpanded(false);
    onChange(value);
  };

  if (!person) return null;

  return (
    <div className={`${className} tw-relative`} ref={ref}>
      <Button
        className={`tw-border-none tw-cursor-pointer tw-whitespace-nowrap tw-text-11d tw-leading-[16px]
        tw-font-bold tw-rounded-full tw-w-[120px] tw-px-[8px] tw-py-[4px] tw-uppercase ${statusIntentCss.statusIntentButton
          } ${status !== "active-client" &&
            status !== "prospective-client" &&
            status !== "brivity-user" &&
            status !== "non-compete"
            ? statusIntentCss.selectedStatusIntentText
            : ""
          } ${getStatusClass(status)} ${expanded ? statusIntentCss.active : ""} ${btnClassName}`}
        onClick={() => {
          if (
            status !== "active-client" &&
            status !== "prospective-client" &&
            status !== "brivity-user" &&
            status !== "non-compete"
          ) {
            setExpanded((exp) => !exp);
          }
        }}
        isLoading={isLoading}
      >
        {status === "prospective-client" ? "PROSPECTIVE" : (status || "new").replace("-", " ").toUpperCase()}
      </Button>
      {expanded && (
        <div className="tw-absolute tw-z-1000 tw-left-[-8px] tw-top-[calc(100%+8px)] tw-bg-white tw-shadow-dropdown tw-flex tw-flex-col tw-gap-[8px] tw-p-[8px] tw-rounded-[8px]">
          {statusOptions.map(([label, value]) => (
            <button
              key={value}
              onClick={(e) => updateValue(value)}
              className={`tw-font-bold tw-border-none hover:tw-ring-1 tw-text-[11px] tw-rounded-full tw-w-[120px] tw-leading-[24px] tw-font-boldtw-inline-block ${getStatusClass(
                value,
              )}`}
            >
              {label.toUpperCase()}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;

StatusDropdown.propTypes = {
  className: string,
  onChange: func,
  statusOptions: arrayOf([]),
  status: string,
  person: shape({}),
  btnClassName: string,
  isLoading: bool,
};

StatusDropdown.defaultProps = {
  className: null,
  onChange: () => { },
  statusOptions: [],
  status: "new",
  person: null,
  shape: {},
  isLoading: false,
  btnClassName: "",
};