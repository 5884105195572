import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Avatar from "@shared/v2/Avatar";
import Tooltip from "@shared/v2/Tooltip";
import TextButton from "@shared/v2/TextButton";
import { BoxArchiveSolidV6 } from "@shared/v2/Icomoon/Icons";
import TextLink from "@shared/v2/TextLink";
import { handleLoanUpdate } from "../../../actions/personDetailActionCreators";
import AddTransactionModal from "./AddTransactionModal";
import FinancingStagesDropDown from "./FinancingStagesDropDown/FinancingStagesDropDown";
import MortgageLoanMenu from "./MortgageLoanMenu";
import { timeStampFormat } from "../Mortgage";

const LABEL_CLASS = "tw-text-[12px] tw-leading-[16px] tw-text-neutral-gray-75 tw-font-semibold ";
const VALUE_CLASS = "tw-text-[14px] tw-leading-[20px] tw-text-neutral-gray-75 tw-font-normal ";

const MortgageCard = ({ mortgageLoan, loansFinancingStages, person }) => {
  const dispatch = useDispatch();
  const [showModal, setshowModal] = useState(false);

  const {
    id,
    archived,
    financing_stage,
    loan_officer_name,
    notes,
    updated_at,
    listing,
    primary_borrower,
    coborrowers,
  } = mortgageLoan;

  const closeModal = () => {
    setshowModal(false);
  };

  const openModal = () => {
    setshowModal(true);
  };

  const onUpdateStage = (stage) => {
    const param = {
      financing_stage: stage.value,
    };
    dispatch(handleLoanUpdate(person.data?.id, param, id));
  };

  const handleAddRemoveTransaction = (associatedTransaction) => {
    const param = {
      listing_id: associatedTransaction?.value || null,
    };
    dispatch(handleLoanUpdate(person.data?.id, param, id));
  };

  const handleAddRemoveCoBorrower = (selectedCoBorrower, isDestroy) => {
    const param = {
      coborrowers_attributes: isDestroy
        ? [{ id: selectedCoBorrower?.value, _destroy: true }]
        : [{ person_id: selectedCoBorrower?.value }],
    };
    dispatch(handleLoanUpdate(person.data?.id, param, id));
  };

  const handleToggleArchive = () => {
    const param = {
      archived: !archived,
    };
    dispatch(handleLoanUpdate(person.data?.id, param, id));
  };

  const handleOnClickAvatar = (slug) => {
    window.open(`/people/${slug}`, "_blank");
  };

  const handleOnClickTransaction = (link) => {
    if (link) {
      window.open(`${link}`, "_blank");
    }
  };
  return (
    <>
      <div className="tw-pb-[8px]">
        <div className="tw-shadow-card tw-p-[16px] tw-bg-white tw-rounded-[7px] tw-overflow-y-auto tw-max-h-[396px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[12px] tw-overflow-hidden">
            <div className="tw-pb-[8px]">
              <div className={LABEL_CLASS}>Loan For</div>
            </div>
            <MortgageLoanMenu
              handleRemoveTransaction={handleAddRemoveTransaction}
              handleAddRemoveCoBorrower={handleAddRemoveCoBorrower}
              handleToggleArchive={handleToggleArchive}
              coborrowers={coborrowers}
              primary_borrower={primary_borrower}
              archived={archived}
            />
          </div>
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[12px] tw-overflow-hidden tw-pb-[16px]">
            <div className={`${VALUE_CLASS} !tw-text-theme-brand`}>
              {listing ? (
                <span className="tw-cursor-pointer" onClick={() => handleOnClickTransaction(listing?.link)}>
                  {`${listing?.address?.street_address || ""} ${listing?.address?.city || ""} ${listing?.address?.locality || ""} ${listing?.address?.postal_code || ""} ${listing?.address?.country || ""}`}
                </span>
              ) : (
                <TextButton onClick={openModal} className="tw-p-0">
                  Add Transaction to Loan
                </TextButton>
              )}
            </div>
            <div className="tw-w-[24px]" />
          </div>
          <div className="tw-pb-[16px]">
            <div className="tw-pb-[8px]">
              <div className={LABEL_CLASS}>Loan Officer</div>
            </div>
            <div>
              <div className={VALUE_CLASS}>{loan_officer_name || ""}</div>
            </div>
          </div>
          <div className="tw-pb-[16px]">
            <div className="tw-pb-[4px]">
              <div className={LABEL_CLASS}>Financing Stage</div>
            </div>
            <div>
              <div className={VALUE_CLASS}>
                <FinancingStagesDropDown
                  selectedStage={financing_stage}
                  financingStagesMapping={loansFinancingStages}
                  onUpdateStage={onUpdateStage}
                />
              </div>
            </div>
          </div>
          <div className="tw-pb-[16px]">
            <div className="tw-pb-[8px]">
              <div className={LABEL_CLASS}>Notes</div>
            </div>
            <div>
              <div className={`${VALUE_CLASS} !tw-text-[12px] !tw-leading-[16px]`}>{notes || ""}</div>
            </div>
          </div>
          <div className="tw-pb-[16px]">
            <div className="tw-pb-[8px]">
              <div className={LABEL_CLASS}>Borrowers</div>
            </div>
            <div className="tw-flex tw-gap-[4px] tw-cursor-pointer">
              <Tooltip
                trigger={
                  <Avatar
                    key={primary_borrower.id}
                    size="small"
                    onClick={() => handleOnClickAvatar(primary_borrower?.slug)}
                    src={primary_borrower?.avatar}
                    alt={primary_borrower?.full_name}
                  />
                }
                content={`${primary_borrower?.full_name || "Anyone"} (borrower)`}
                placement="right"
              />
              {coborrowers.map((coborrower) => (
                <Tooltip
                  trigger={
                    <Avatar
                      key={coborrower.id}
                      size="small"
                      src={coborrower?.avatar}
                      alt={coborrower?.full_name}
                      onClick={() => handleOnClickAvatar(coborrower?.slug)}
                    />
                  }
                  content={`${coborrower?.full_name || "Anyone"} (co-borrower)`}
                  placement="right"
                />
              ))}
            </div>
          </div>
          <div className="tw-text-12d tw-italic tw-flex tw-justify-between">
            <span className="tw-text-gray-50">Last Updated:</span>
            <span className="tw-text-gray-75">{timeStampFormat(updated_at)}</span>
          </div>
          {archived && (
            <div className="tw-pt-[16px]">
              <div className="tw-border-[2px] tw-border-neutral-gray-10 tw-border-solid tw-rounded-[7px] tw-bg-gray-5">
                <div className="tw-py-[12px] tw-px-[10px] tw-text-[14px] tw-leading-[20px] tw-font-bold tw-flex tw-items-center">
                  <BoxArchiveSolidV6 size="l" />
                  <div className="tw-pl-[16px]">
                    <div>Archived Loan</div>
                    <TextLink
                      onClick={handleToggleArchive}
                      className="tw-text-[12px] tw-leading-[16px] tw-font-normal"
                    >
                      Remove Loan from Archive
                    </TextLink>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <AddTransactionModal
          person={person}
          modalOpen={showModal}
          closeModal={closeModal}
          handleAddTransaction={handleAddRemoveTransaction}
        />
      )}
    </>
  );
};

MortgageCard.propTypes = {
  mortgageLoan: PropTypes.shape({
    id: PropTypes.string,
    archived: PropTypes.bool,
    financing_stage: PropTypes.string,
    loan_officer_name: PropTypes.string,
    notes: PropTypes.string,
    updated_at: PropTypes.string,
    listing: PropTypes.shape({
      id: PropTypes.number,
      link: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        locality: PropTypes.string,
        postal_code: PropTypes.string,
        street_address: PropTypes.string,
      }),
    }),
    primary_borrower: PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string,
    }),
    coborrowers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        full_name: PropTypes.string,
        slug: PropTypes.string,
      }),
    ),
  }),
  person: PropTypes.shape({}),
  loansFinancingStages: PropTypes.array,
};
MortgageCard.defaultProps = {
  mortgageLoan: {},
  person: {},
  loansFinancingStages: [],
};
export default MortgageCard;
