import React, { useState } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, bool, number, string } from "prop-types";

import { downloadFile } from "../../../../shared/AWSUploader";

import useEnabledButtons from "./hooks";
import ModalDeleteImages from "../ModalDeleteImages";
import Button from "../../../../shared/v2/Button";
import Download from "../../../../shared/v2/Icomoon/Icons/Download";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";

const ImageActionButtons = ({ listingImgs, useMLSphotos }) => {
  const enable = useEnabledButtons(listingImgs);
  const [isModalOpen, toggleModal] = useState(false);
  const onDownloadAll = () => {
    listingImgs
      .filter((l) => l.checked)
      .forEach((l) => {
        setTimeout(() => {
          downloadFile(l.original, l.id);
        }, 300);
      });
  };
  return (
    <>
      <ModalDeleteImages isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <div className={`tw-mr-[12px] tw-h-[24px] ${enable ? "tw-flex" : "tw-hidden"}`}>
        <Button className="tw-flex tw-items-center tw-mr-[8px]" schema="secondary" onClick={onDownloadAll}>
          <span className="tw-mr-[4px]">Download</span>
          <Download />
        </Button>
        {!useMLSphotos && (
          <Button
            className="tw-flex tw-items-center"
            schema="warning"
            onClick={() => toggleModal((prev) => !prev)}
          >
            <span className="tw-mr-[4px]">Delete</span>
            <Trash />
          </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  listingImgs: state.tdpDetailsReducer.listingImgs,
  useMLSphotos: state.tdpDetailsReducer.useMLSphotos,
});

ImageActionButtons.propTypes = {
  useMLSphotos: bool,
  listingImgs: arrayOf(
    shape({
      checked: bool,
      id: number,
      url: string,
    }),
  ),
};

ImageActionButtons.defaultProps = {
  useMLSphotos: false,
  listingImgs: [],
};

export default connect(mapStateToProps)(ImageActionButtons);
