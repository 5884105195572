/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AngleDownSolidV6 = (props) => (
  <Icon {...props} name="Angle Down Solid V6">
    <path d="M8 13.166c-.341 0-.682-.13-.942-.391L.392 6.109a1.332 1.332 0 1 1 1.885-1.885L8 9.949l5.724-5.724a1.332 1.332 0 1 1 1.885 1.885l-6.666 6.666c-.26.26-.602.39-.944.39z" />
  </Icon>
);

export default AngleDownSolidV6;
