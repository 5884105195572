import React from "react";

export const RemovableBubble = ({
  selectionId,
  selectionName,
  inputIdPrefix,
  labelPrefix,
  onClick,
  inputFieldName,
}) => (
  <div key={selectionId} className="tw-inline-block">
    <input
      className="filter-bubble-checkbox"
      type="checkbox"
      name={inputFieldName}
      id={`${inputIdPrefix}${selectionId}`}
      value={selectionId}
      checked="checked"
      readOnly
    />
    <div className="removable-bubble tw-mr-[3px]" onClick={onClick}>
      <label className="info-label tw-cursor-pointer" htmlFor={`${inputIdPrefix}${selectionId}`}>
        {labelPrefix} {selectionName}
      </label>
      <i className="fa fa-remove tw-pl-[3px]"></i>
    </div>
  </div>
);

export const RemovableBubbles = ({
  selections,
  inputIdPrefix,
  labelPrefix,
  onRemoveBubbleClickHandlr,
  inputFieldName,
}) =>
  selections.map(({ label, value }) => (
    <RemovableBubble
      key={value}
      selectionId={value}
      selectionName={label}
      inputIdPrefix={inputIdPrefix}
      labelPrefix={labelPrefix}
      inputFieldName={inputFieldName}
      onClick={() => onRemoveBubbleClickHandlr(value)}
    />
  ));