/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover/Popover";
import Button from "@shared/v2/Button";
import { ArrowUpRightFromSquareRegularV6 } from "@shared/v2/Icomoon/Icons";

export const FINANCING_STAGES_MAPPING = [
  { label: "REFERRAL SENT", value: "Referral Sent" },
  { label: "WORKING LEAD", value: "Working Lead" },
  { label: "APPLICATION", value: "Application" },
  { label: "PRE-APPROVED", value: "Pre-Approved" },
  { label: "PROCESSING", value: "Processing" },
  { label: "UNDERWRITING", value: "Underwriting" },
  { label: "COND. APPROVAL", value: "Cond. Approval" },
  { label: "FINAL APPROVAL", value: "Final Approval" },
  { label: "SIGNING", value: "Signing" },
  { label: "COMPLETED", value: "Completed" },
  { label: "ON HOLD", value: "On Hold" },
  { label: "UNQUALIFIED: NURTURE", value: "Unqualified: Nurture" },
  { label: "UNQUALIFIED: INACTIVE", value: "Unqualified: Inactive" },
];

const FinancingStagesDropDown = ({
  financingStagesMapping,
  selectedStage,
  onUpdateStage,
  containerClassName,
  isLoading,
}) => {
  const [stage, setStage] = useState(selectedStage);
  const [showStagePicker, setShowStagePicker] = useState(false);

  const selectedTitle = financingStagesMapping.find((obj) => obj?.value === stage)?.label || "";

  const selectStage = (stage) => {
    setShowStagePicker(false);
    setStage(stage.value);
    onUpdateStage(stage);
  };

  useEffect(() => {
    if (selectedStage) {
      setStage(selectedStage);
    } else {
      setStage(financingStagesMapping.length > 0 ? financingStagesMapping[0]?.value : "");
    }
  }, [selectedStage]);

  const handleClick = () => {
    window.open(
      "https://knowledge.brivity.com/en/articles/5527189-email-reputation-deliverability",
      "_blank",
    );
  };

  return (
    <div className="tw-relative tw-flex tw-justify-start">
      <Popover open={showStagePicker} onOpenChange={setShowStagePicker}>
        <PopoverTrigger>
          <Button
            data-cy="financing-stage-button"
            schema="tertiary"
            isLoading={isLoading}
            onClick={() => setShowStagePicker(!showStagePicker)}
            className={`!tw-p-0 tw-bg-transparent tw-border-none tw-flex tw-justify-start tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center
            tw-uppercase tw-text-center tw-align-middle tw-cursor-pointer tw-w-[180px] tw-h-[24px] tw-text-[#666] tw-text-11px tw-leading-[16px]
            tw-font-bold hover:!tw-bg-transparent hover:tw-border-transparent !tw-bg-transparent tw-border-transparent ${containerClassName}`}
          >
            {selectedTitle}
            <i
              className={`fa fa-chevron-down tw-ml-[4px] tw-text-11d tw-transition-transform ${
                showStagePicker && "fa-rotate-180"
              }`}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="tw-h-[320px] tw-z-[3000]">
          {financingStagesMapping.map((financingStageOption, i) => (
            <PopoverItem
              className="tw-flex tw-justify-center tw-text-[#666] tw-leading-[16px] tw-text-11px tw-font-bold tw-align-middle tw-py-[8px] tw-text-center tw-uppercase tw-w-[160px]"
              key={i}
              onClick={() => selectStage(financingStageOption)}
            >
              {financingStageOption?.label}
            </PopoverItem>
          ))}
          <div className="tw-inline-block tw-border-[1px] tw-border-solid tw-border-gray-10" />
          <div className="tw-flex tw-bg-white" onClick={handleClick}>
            <div
              className="tw-flex tw-flex-row tw-justify-center tw-flex-nowrap tw-items-center tw-grow tw-shrink-0 tw-text-theme-brand tw-text-[12px] tw-leading-[16px]
        tw-font-normal tw-align-middle tw-py-[8px] tw-text-center tw-cursor-pointer"
            >
              <span className="tw-pr-[5px]">Status Info </span>
              <ArrowUpRightFromSquareRegularV6 size="m" />
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

FinancingStagesDropDown.propTypes = {
  selectedStage: Proptypes.string,
  containerClassName: Proptypes.string,
  optionClassName: Proptypes.string,
  financingStagesMapping: Proptypes.array,
  onUpdateStage: Proptypes.func,
  isLoading: Proptypes.bool,
};

FinancingStagesDropDown.defaultProps = {
  selectedStage: "",
  containerClassName: "",
  optionClassName: "",
  financingStagesMapping: FINANCING_STAGES_MAPPING,
  onUpdateStage: null,
  isLoading: false,
};

export default FinancingStagesDropDown;
