// Will add when referral type is released
export const TYPE_STYLE = {
  buyer: "tw-border-semantic-blue-10 tw-text-semantic-blue-110",
  seller: "tw-border-semantic-yellow-10 tw-text-semantic-yellow-110",
  tenant: "tw-border-semantic-green-10 tw-text-semantic-green-110",
  landlord: "tw-border-semantic-red-10 tw-text-semantic-red-110",
};

// Will add when referral type is released
export const TYPE_TEXT = {
  buyer: "BUYER",
  seller: "SELLER",
  tenant: "TENANT",
  landlord: "LANDLORD",
};
