import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormControl } from "./FormControl";

export const Input = forwardRef((props, ref) => (
  <FormControl
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    componentClass="input"
    ref={ref}
  />
));

Input.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["default", "small", "large", "auto"]),
  variant: PropTypes.oneOf(["default", "unstyled"]),
  deprecatedOverridePaddingClasses: PropTypes.string,
};

Input.defaultProps = {
  className: "",
  size: "default",
  variant: "default",
  deprecatedOverridePaddingClasses: "",
};

export default Input;
