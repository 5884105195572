import React from "react";

import Dropdown, { getAvatarFromDropdownData } from "../../../../../shared/v2/Dropdown";
import { customDefaultProps, customPropTypes } from "../../../../../shared/v2/Dropdown/utils";

const MemberOption = ({ componentProps, data, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.Option {...componentProps} preserveStyle>
    <div className="tw-flex tw-items-center tw-gap-8px" data-cy="dropdownOption">
      {getAvatarFromDropdownData(componentProps, data)}
      {children}
    </div>
  </Dropdown.Option>
);

MemberOption.propTypes = customPropTypes;

MemberOption.defaultProps = customDefaultProps;

export default MemberOption;
