import React, { useState } from "react";
import { bool, func, number, string } from "prop-types";
import { connect } from "react-redux";
import LinkPeople from "../LinkPeople";
import LinkMain from "../LinkMain";
import EditBtnCirc from "../EditBtnCirc";
import { putLinkAsThunk } from "../actions/thunks";
import ModalEditLink from "../ModalEditLink";

const LinkGroup = ({ link, title, id, enablePeople, listingUuid, onSaveLinkAsThunk }) => {
  const [openEditLinkModal, setOpenEditLinkModal] = useState(false);

  const editLink = (newTitle, newLink) =>
    onSaveLinkAsThunk({
      uuid: listingUuid,
      key: "links",
      value: [{ id, title: newTitle, url: newLink }],
      inOption: "listing_links_attributes",
      group: "links",
    });

  const removeLink = () =>
    onSaveLinkAsThunk({
      uuid: listingUuid,
      key: "links",
      value: [{ id, _destroy: true }],
      inOption: "listing_links_attributes",
      group: "links",
    });

  return (
    <>
      <div className="tw-mb-20px" data-cy="link-container">
        <div className="tw-flex tw-items-center tw-mb-[8px]">
          <div className="tw-mr-auto tw-text-14px tw-text-neutral-gray-75 tw-font-semibold">{title}</div>
          {enablePeople ? <LinkPeople /> : <EditBtnCirc onClick={() => setOpenEditLinkModal(true)} />}
        </div>
        <LinkMain link={link} />
      </div>
      <ModalEditLink
        show={openEditLinkModal}
        onHide={() => setOpenEditLinkModal(false)}
        linkValue={link}
        titleValue={title}
        onRemove={removeLink}
        onSave={editLink}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  listingUuid: state.tdpDetailsReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveLinkAsThunk: (payload) => dispatch(putLinkAsThunk(payload)),
});

LinkGroup.propTypes = {
  link: string,
  title: string,
  id: number,
  enablePeople: bool,
  listingUuid: string.isRequired,
  onSaveLinkAsThunk: func.isRequired,
};

LinkGroup.defaultProps = {
  link: "",
  title: "Property Page",
  id: null,
  enablePeople: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkGroup);
