/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Reset = (props) => (
  <Icon {...props} name="Reset">
    <path d="M7.985 0a7.972 7.972 0 0 0-5.512 2.216L1.321 1.064a.774.774 0 0 0-1.322.547v4.324c0 .428.347.774.774.774h4.324a.774.774 0 0 0 .547-1.322L4.297 4.04A5.378 5.378 0 0 1 7.95 2.58c2.981-.026 5.493 2.386 5.467 5.466-.024 2.921-2.393 5.373-5.419 5.373a5.375 5.375 0 0 1-3.569-1.341.387.387 0 0 0-.528.018l-1.279 1.279a.388.388 0 0 0 .016.562 8 8 0 0 0 13.361-5.938c0-4.413-3.601-8.008-8.015-8z" />
  </Icon>
);

export default Reset;
