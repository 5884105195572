import React from "react";
import PropTypes from "prop-types";
import useAgentTransactions from "./services/useAgentTransactions";
import Loader from "../../../Dashboard/components/Loader";
import Error from "../DashboardShared/Error";
import StatCard from "../DashboardShared/StatCard";
import { getListingUrl } from "../DashboardShared/helpers/createLeadSourceChartOptions";
import { Section } from "../BusinessDashboard";

const BORDER_CLASS = "tw-flex-1 tw-border-solid tw-border-neutral-gray-10 tw-border-y-0 tw-border-l-0";

const AgentTransactions = ({ agentId, agentUuid, year }) => {
  const {
    data: { closings, pendings, activeListings, comingSoon, pipeline },
    error,
    loading,
  } = useAgentTransactions({ agentUuid, year });

  if (error) return <Error show />;

  return (
    <Section className="!tw-flex-row tw-flex-wrap tw-gap-[8px]">
      <StatCard
        className={BORDER_CLASS}
        label="Closed (YTD)"
        mainStat="Closed (YTD)"
        stats={{
          "Closed (YTD)": { value: closings.closedUnits },
          Volume: { value: closings.closedVolume },
          "Net Income": { value: closings.closedNetIncome },
        }}
        link={getListingUrl({ dataKey: "sold", year, agentId })}
      />
      <StatCard
        className={BORDER_CLASS}
        label="Pendings"
        mainStat="Pendings"
        stats={{
          Pendings: { value: pendings.pendedUnits },
          "Est. Volume": { value: pendings.pendedVolume },
          "Est. Income": { value: pendings.estIncome },
        }}
        link={getListingUrl({ dataKey: "pending", year, agentId })}
      />
      <StatCard
        className={BORDER_CLASS}
        label="Active"
        mainStat="Active Listings"
        stats={{
          "Active Listings": { value: activeListings.activeUnits },
          "Est. Volume": { value: activeListings.activeVolume },
          "Est. Income": { value: activeListings.activeIncome },
        }}
        link={getListingUrl({ dataKey: "active", year, agentId })}
      />
      <StatCard
        className={BORDER_CLASS}
        label="Coming Soon Listings"
        mainStat="Coming Soon Listings"
        stats={{
          "Coming Soon Listings": { value: comingSoon.comingSoonUnits },
          "Est. Volume": { value: comingSoon.comingSoonVolume },
          "Est. Income": { value: comingSoon.comingSoonIncome },
        }}
        link={getListingUrl({ dataKey: "coming_soon", year, agentId })}
      />
      <StatCard
        label="Pipeline"
        mainStat="Pipeline"
        stats={{
          Pipeline: { value: pipeline.pipelineUnits },
          "Est. Volume": { value: pipeline.pipelineVolume },
          "Est. Income": { value: pipeline.pipelineIncome },
        }}
        link={getListingUrl({ dataKey: "pipeline", year, agentId })}
      />
      {loading && <Loader />}
    </Section>
  );
};

AgentTransactions.propTypes = {
  agentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  agentUuid: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AgentTransactions;
