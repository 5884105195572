/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CheckSolidV6 = (props) => (
  <Icon {...props} name="Check Solid V6">
    <path d="M14.706 3.294a.998.998 0 0 1 0 1.413l-8 8a.998.998 0 0 1-1.413 0l-4.001-4a.999.999 0 1 1 1.414-1.413l3.265 3.291 7.322-7.291a.998.998 0 0 1 1.413 0z" />
  </Icon>
);

export default CheckSolidV6;
