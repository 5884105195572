import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CircleExclamationRegularV6 } from "@shared/v2/Icomoon";
import twilioErrorCodes from "@shared/v2/twilioErrorCodes";
import PreTooltipContent from "./PreTooltipContent";

const MessageErrorTooltip = ({ error, errorCode, failedRecipients, size }) => {
  if (!error) return null;
  const twilioErrorMessage = twilioErrorCodes[errorCode];

  return (
    <Tooltip
      innerClassName="tw-max-w-[250px] "
      multiline
      placement="bottom"
      trigger={
        <CircleExclamationRegularV6
          size={size}
          className={error === "allFailed" ? "tw-text-semantic-red-120" : "tw-text-semantic-yellow-120"}
        />
      }
      content={
        <PreTooltipContent>
          This message failed to send to{" "}
          {error === "allFailed" ? "all recipients." : "to the following recipients:"}
          {"\n"}
          {error === "someFailed" && (
            <ul className="tw-m-0 tw-px-[16px] tw-py-[4px]">
              {failedRecipients.map((recipient, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>{recipient}</li>
              ))}
            </ul>
          )}
          {"\n"}
          {twilioErrorMessage}
        </PreTooltipContent>
      }
    />
  );
};

MessageErrorTooltip.propTypes = {
  error: PropTypes.oneOf(["allFailed", "someFailed", false]),
  errorCode: PropTypes.number,
  failedRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.string,
};

MessageErrorTooltip.defaultProps = {
  error: false,
  errorCode: undefined,
  size: "xl",
};

export default MessageErrorTooltip;
