import React, { useEffect } from "react";
import { connect } from "react-redux";
import { func, number, string } from "prop-types";

import { getAllTasksAsThunk } from "../actions/thunks";
import { clearTasksAndFilters } from "../actions/creators";

import Label from "../Label";
import TaskList from "../TaskList";
import EmptyStates from "../EmptyStates";

const Main = ({ onGetAllTasksAsThunk, uuid, hasActiveTasks, onClearActiveTasks }) => {
  useEffect(() => {
    if (uuid.length) {
      onGetAllTasksAsThunk(uuid);
    }

    return () => {
      onClearActiveTasks();
    };
  }, []);

  return (
    <div className="Main">
      <Label />
      {hasActiveTasks ? <TaskList /> : <EmptyStates />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpTasksReducer.uuid,
  hasActiveTasks: state.tdpTasksReducer.activeTasks.length,
  primaryActiveList: state.tdpTasksReducer.primaryActiveList,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllTasksAsThunk: (uuid, primaryActiveList) => dispatch(getAllTasksAsThunk(uuid, primaryActiveList)),
  onClearActiveTasks: () => dispatch(clearTasksAndFilters()),
});

Main.propTypes = {
  uuid: string,
  onGetAllTasksAsThunk: func,
  hasActiveTasks: number,
  onClearActiveTasks: func,
};

Main.defaultProps = {
  uuid: "",
  onGetAllTasksAsThunk: null,
  hasActiveTasks: 0,
  onClearActiveTasks: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
