/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { string } from "prop-types";

import LinkField from "../../../../shared/v2/LinkField";

const LinkMain = ({ link }) => (
  <LinkField
    value={link}
    placeholder=""
    readOnly
    actionLabel="COPY LINK"
    onActionButtonClick={() => navigator.clipboard.writeText(link)}
    secondaryActionLabel="PREVIEW"
    onSecondaryActionButtonClick={() => window.open(link, "_blank").focus()}
    data-cy="link-content"
  />
);

LinkMain.propTypes = {
  link: string,
};

LinkMain.defaultProps = {
  link: "",
};

export default LinkMain;
