/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ShareNodesSolidV6 = (props) => (
  <Icon {...props} name="Share Nodes Solid V6">
    <path d="M9.919 6.159 6.978 7.628c.016.122-.006.219-.006.372 0 .125.022.222.006.372l2.941 1.469A2.977 2.977 0 0 1 12 9c1.656 0 3 1.316 3 3 0 1.656-1.344 3-3 3-1.684 0-3-1.344-3-3 0-.153.006-.25.022-.372l-2.941-1.469A3 3 0 0 1 1 8c0-1.684 1.343-3 3-3 .809 0 1.544.319 2.081.841l2.941-1.469A3.187 3.187 0 0 1 9 4c0-1.657 1.316-3 3-3a3 3 0 1 1-2.081 5.159z" />
  </Icon>
);

export default ShareNodesSolidV6;
