import React from "react";
import PropTypes from "prop-types";

const FormRow = ({ children, className }) => (
  <div className={`tw-flex tw-flex-wrap tw-gap-[24px] ${className}`}>{children}</div>
);

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FormRow.defaultProps = {
  className: "",
};

export default FormRow;
