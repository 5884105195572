const commonBeforePlacementClasses = "before:tw-inset-y-[-2px]";
const beforeSelectedPlacementClasses = `${commonBeforePlacementClasses} before:tw-inset-y-[-2px] before:tw-left-[-4px] before:tw-right-[1px]`;
const beforePlacementHoverClasses = `${commonBeforePlacementClasses} before:tw-left-[-8px] before:tw-right-[2px]`;
export const generateCommonClasses = ({ isFirst, isLast, selected, isInHoverSelection, isHovering }) => `
  tw-py-[6px] tw-min-w-[65px] 
  tw-relative tw-border-solid focus-visible:tw-outline-0 hover:tw-border-brivity-blue-100 focus:tw-border-brivity-blue-100
  ${isFirst && `tw-rounded-l-lg ${!selected && "tw-border-l-gray-15"}`}
  ${isLast && `tw-rounded-r-lg ${!selected && "tw-border-r-gray-15"}`}
  ${selected && !isHovering ? `tw-border-brivity-blue-100 tw-bg-brivity-blue-10 tw-z-10` : "tw-bg-white"}
  ${
    (selected || isInHoverSelection) &&
    ` before:tw-content-[''] before:tw-absolute
          before:tw-border-solid before:tw-border-y-2 before:tw-border-x-0 before:tw-border-brivity-blue-100`
  }
  ${isHovering || isInHoverSelection ? "tw-border-brivity-blue-100 tw-bg-white tw-z-20" : "tw-bg-white"}
  ${!isHovering && !isInHoverSelection && !selected && "tw-border-gray-15"}
`;

export const generateSingleClasses = ({ selected }) =>
  selected
    ? "tw-border-x-2 tw-rounded-lg"
    : "hover:tw-border-x-2 hover:tw-rounded-lg tw-border-x-transparent";

export const generateMultipleClasses = ({
  selected,
  bothSelected,
  isHovering,
  hoverBefore,
  hoverAfter,
  multiAllSelected,
  isInHoverSelection,
  isSelectingRange,
  isStart,
  isEnd,
  isFirst,
}) => {
  if (bothSelected) {
    return `hover:tw-rounded-lg hover:tw-border-x-2 focus:tw-rounded-lg focus:tw-border-x-2
      ${!isStart && !isEnd && !isHovering && "tw-border-x-transparent"}
      ${!isFirst && !isStart && selected && beforeSelectedPlacementClasses}
      ${isStart && "tw-rounded-l-lg tw-border-r-transparent"}
      ${isEnd && "tw-rounded-r-lg tw-border-l-transparent"}
      ${isFirst && "tw-rounded-l-lg tw-border-r-transparent"}
    `;
  }
  return `tw-border-x-2 ${selected && "tw-rounded-lg"}
    ${!isStart && !isEnd && !selected && "tw-border-x-transparent"}
    ${
      isHovering &&
      hoverBefore &&
      !multiAllSelected &&
      "tw-border-r-transparent hover:tw-rounded-l-lg focus:tw-border-l-2 focus:tw-rounded-l-lg"
    }
    ${
      isHovering &&
      hoverAfter &&
      !multiAllSelected &&
      "tw-border-l-transparent hover:tw-rounded-r-lg focus:tw-rounded-r-lg"
    }
    ${
      isHovering &&
      (hoverBefore || hoverAfter) &&
      multiAllSelected &&
      "hover:tw-rounded-lg focus:tw-rounded-lg"
    }
    ${
      isSelectingRange &&
      isHovering &&
      hoverBefore &&
      "hover:tw-border-r-transparent focus:tw-border-r-transparent"
    }
    ${
      isSelectingRange &&
      isHovering &&
      hoverAfter &&
      "hover:tw-border-l-transparent focus:tw-border-l-transparent"
    }
    ${isSelectingRange && hoverBefore && isInHoverSelection && !isHovering && beforePlacementHoverClasses}
      ${isSelectingRange && hoverAfter && isInHoverSelection && !selected && beforePlacementHoverClasses}
  `;
};

export const reduceWhiteSpace = (string) => string?.replace(/\s+/g, " ").trim();
