import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DragHandle from "../../../../../shared/v2/DragHandle";
import { option, optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { Trash, Edit } from "../../../../../shared/v2/Icomoon";
import IconButton from "../../../../../shared/v2/IconButton";
import InlineEditingInput from "../../../../../shared/v2/InlineEditingInput";
import InlineEditingSelect from "../../../../../shared/v2/InlineEditingSelect";
import { formatNumber } from "../../../../../shared/v2/inputFormatUtils";

const formatNumberIfFlat = (value, type, commas) => (type === "flat" ? formatNumber(value, commas) : value);

const ExpenseIncomeLineItem = ({
  expenseOrIncome,
  selectedOption,
  options: optionsFromProps,
  onOptionChange,
  notes,
  value,
  valueType: valueTypeFromProps,
  onValueChange,
  calculatedValue,
  subtotal,
  // TODO: Currently this just shows/hides the edit IconButton - it does not
  // disable any of the other editing (value, expense/income type, etc.).
  // Confirm whether these cases also need to be handled or not.
  isEditable,
  onEditLineItem,
  isRemovable,
  onRemoveLineItem,
  isDraggingOver,
  dragHandleProps,
}) => {
  const [options, setOptions] = useState(optionsFromProps);
  const [customOption, setCustomOption] = useState(null);
  const [valueType, setValueType] = useState(valueTypeFromProps);

  useEffect(() => setOptions(optionsFromProps), [optionsFromProps]);
  useEffect(() => setValueType(valueTypeFromProps), [valueTypeFromProps]);

  const handleCustomNameChange = (newValue) => {
    if (newValue) {
      const newOption = {
        ...customOption,
        meta: {
          ...customOption.meta,
          name: newValue,
        },
      };
      setOptions((prevOptions) =>
        prevOptions.map((prevOption) => {
          if (prevOption.value === customOption.value) {
            return newOption;
          }

          return prevOption;
        }),
      );
      onOptionChange(newOption);
    } else {
      setCustomOption(null);
    }
  };

  const renderLabel = () => {
    if (customOption) {
      // While the custom expense/income is being edited, the label could become an empty string,
      // so we style the add button differently
      const isLabelEmpty = selectedOption.label === "";

      return (
        <InlineEditingInput
          externalEditing
          value={customOption.meta.name}
          placeholder={`Name custom ${expenseOrIncome}`}
          onChange={handleCustomNameChange}
          className="tw-w-fit"
          displayClassName={`${
            isLabelEmpty ? "" : "!tw-text-neutral-gray-50 "
            // Manually configuring line-height and negative margins to match the designs
          }tw-font-semibold tw-leading-20px tw-mt-[-7px]`}
          editorClassName="tw-mt-[-8px]"
          onConfirmEditing={() => setCustomOption(null)}
          onCancelEditing={() => setCustomOption(null)}
        />
      );
    }

    if (options?.length) {
      return (
        <InlineEditingSelect
          displayLabel={selectedOption.meta.isCustomOption ? selectedOption.meta.name : ""}
          value={selectedOption}
          options={options}
          onSelect={(newOption) => {
            if (newOption.meta.isCustomOption) {
              setCustomOption(newOption);
            }
          }}
          onChange={(newOption) => {
            if (!newOption.meta.isCustomOption) {
              onOptionChange(newOption);
            }
          }}
          // Manually configuring line-height and negative margins to match the designs
          displayClassName="tw-w-fit !tw-text-neutral-gray-50 tw-font-semibold tw-leading-20px tw-mt-[-7px]"
          editorClassName="tw-mt-[-8px]"
          displayIconAlwaysVisible={false}
          menuShouldComeToFront
        />
      );
    }

    return (
      <span className="tw-text-neutral-gray-50 tw-font-semibold tw-leading-20px">{selectedOption.label}</span>
    );
  };

  const isExpense = expenseOrIncome === "expense";

  return (
    <div
      className={`group-lineitem tw-flex tw-gap-[8px] tw-px-16px tw-py-11px
        tw-border tw-border-solid tw-rounded-4px tw-border-transparent${
          isDraggingOver ? "" : " hover:tw-border-neutral-gray-10"
        }`}
      data-cy="financials-line-item"
    >
      <span className="tw-grow tw-flex tw-items-center tw-gap-[15px]">
        <span
          // Using another flex to position the icon at the center and to avoid drag'n'drop outside
          // of the icon
          className="tw-flex tw-items-center tw-w-12px tw-h-12px"
          data-cy="financials-line-item-drag-handle"
          {...dragHandleProps}
        >
          <DragHandle orientation="vertical" className="hover:tw-cursor-grab active:tw-cursor-grabbing" />
        </span>
        <span className="tw-grow tw-flex tw-flex-col tw-gap-[8px]">
          <div className="tw-h-20px" data-cy="financials-line-item-name">
            {renderLabel()}
          </div>
          {notes && (
            <span
              className="tw-text-12d tw-text-neutral-gray-75 tw-line-clamp-[10]"
              data-cy="financials-line-item-notes"
            >
              {notes}
            </span>
          )}
          <div className="tw-h-20px tw-w-fit" data-cy="financials-line-item-value">
            <InlineEditingInput.FlatPercent
              value={formatNumberIfFlat(value, valueType, true)}
              placeholder="0.00"
              flatOrPercent={valueType}
              onChange={(newValue) => onValueChange(newValue, valueType)}
              onFlatSelected={() => setValueType("flat")}
              onPercentSelected={() => setValueType("percent")}
              // Manually configuring line-height and negative margins to match the designs
              displayClassName="tw-leading-20px tw-mt-[-7px]"
              editorClassName="tw-mt-[-8px]"
            />
          </div>
        </span>
      </span>

      <span className="tw-flex tw-flex-col tw-justify-between tw-gap-[8px]">
        <span
          className={`tw-h-20px tw-leading-20px tw-flex tw-gap-[6px] tw-ml-auto tw-invisible${
            // `[.group-lineitem:hover_&]` is a workaround using Tailwind's arbitrary variant for
            // the new nested groups feature
            // See https://github.com/tailwindlabs/tailwindcss/issues/1192#issuecomment-1236260002
            // TODO: refactor to use the Tailwind's nested groups feature (v3.2+)
            isDraggingOver ? "" : " [.group-lineitem:hover_&]:tw-visible"
          }`}
        >
          {isEditable && (
            <IconButton
              size="small"
              schema="tertiary"
              onClick={onEditLineItem}
              data-cy="financials-line-item-edit"
            >
              <Edit />
            </IconButton>
          )}

          {isRemovable && (
            <IconButton
              size="small"
              schema="misc-trash"
              onClick={onRemoveLineItem}
              data-cy="financials-line-item-remove"
            >
              <Trash />
            </IconButton>
          )}
        </span>

        <span className="tw-h-20px tw-leading-20px tw-flex tw-gap-[28px]">
          <span
            className={`${
              isExpense ? "tw-text-semantic-red-100" : "tw-text-semantic-green-100"
            } tw-whitespace-nowrap`}
            data-cy="financials-line-item-calculated-value"
          >
            {isExpense ? "-" : "+"}(${calculatedValue ? formatNumber(calculatedValue, true) : "0.00"})
          </span>
          <span className="tw-whitespace-nowrap" data-cy="financials-line-item-subtotal">
            ${subtotal ? formatNumber(subtotal, true) : "0.00"}
          </span>
        </span>
      </span>
    </div>
  );
};

ExpenseIncomeLineItem.propTypes = {
  expenseOrIncome: PropTypes.oneOf(["expense", "income"]).isRequired,
  selectedOption: optionShape.isRequired,
  options: PropTypes.arrayOf(option),
  onOptionChange: PropTypes.func,
  notes: PropTypes.string,
  value: PropTypes.string.isRequired,
  valueType: PropTypes.oneOf(["flat", "percent"]).isRequired,
  onValueChange: PropTypes.func,
  calculatedValue: PropTypes.string.isRequired,
  subtotal: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onEditLineItem: PropTypes.func,
  isRemovable: PropTypes.bool,
  onRemoveLineItem: PropTypes.func,
  isDraggingOver: PropTypes.bool,
  // Ignoring the validation here since we don't want to rely on internal implementation details
  // from react-beautiful-dnd
  // eslint-disable-next-line react/forbid-prop-types
  dragHandleProps: PropTypes.any,
};

ExpenseIncomeLineItem.defaultProps = {
  options: [],
  onOptionChange: null,
  notes: "",
  onValueChange: () => {},
  isEditable: true,
  onEditLineItem: null,
  isRemovable: true,
  onRemoveLineItem: null,
  isDraggingOver: false,
  dragHandleProps: undefined,
};

export default ExpenseIncomeLineItem;
