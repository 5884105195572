import React from "react";
import { func, number } from "prop-types";

import useSelectedRadio from "./hooks";

import RadioButton from "../../../../shared/v2/RadioButton/RadioButton";

const ModalAddEditPriorityRadios = ({ payloadHandlrs, priority }) => {
  const [selectedRadio, selectedRadioHandlr] = useSelectedRadio(payloadHandlrs, priority);

  return (
    <div className="tw-mt-24px">
      <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-mb-8px">
        Priority (1: Highest, 9: Lowest)
      </div>
      <div className="tw-flex tw-justify-between">
        {Array.from({ length: 9 }, (_, idx) => (
          <RadioButton
            label={idx + 1}
            name={`radio--${idx + 1}`}
            key={idx + 1}
            id={`radio--${idx + 1}`}
            value={idx + 1}
            checked={selectedRadio === idx + 1}
            className="tw-cursor-pointer"
            onClick={() => selectedRadioHandlr(idx)}
          />
        ))}
      </div>
    </div>
  );
};

ModalAddEditPriorityRadios.propTypes = {
  payloadHandlrs: func,
  priority: number,
};

ModalAddEditPriorityRadios.defaultProps = {
  payloadHandlrs: null,
  priority: 9,
};

export default ModalAddEditPriorityRadios;
