/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import CLASSES from "./classes.json";

const {
  BASE_CLASSES,
  INLINE_CLASSES,
  NORMAL_CLASSES,
  INLINE_MARGIN_CLASSES,
  NORMAL_MARGIN_CLASSES,
  BASE_LABEL_CLASSES,
  BASE_LABEL_PADDING_CLASSES,
  INLINE_LABEL_CLASSES,
  NORMAL_LABEL_CLASSES,
  BASE_LABEL_TEXT_CLASSES,
  BASE_RADIO_CLASSES,
  BASE_RADIO_MARGIN_CLASSES,
} = CLASSES;

export const Radio = React.forwardRef(
  (
    {
      id: idFromProps,
      className,
      labelClassName,
      radioClassName,
      labelTextClassName,
      name,
      inline,
      deprecatedOverrideMarginClasses,
      deprecatedOverrideLabelPaddingClasses,
      deprecatedOverrideRadioMarginClasses,
      children,
      ...props
    },
    ref,
  ) => {
    const id = useGivenOrGeneratedId("radio", idFromProps);

    const classes = `
      ${BASE_CLASSES}
      ${inline ? INLINE_CLASSES : NORMAL_CLASSES}
      ${deprecatedOverrideMarginClasses || (inline ? INLINE_MARGIN_CLASSES : NORMAL_MARGIN_CLASSES)}
      ${className}
    `;

    const labelClasses = `
      ${BASE_LABEL_CLASSES}
      ${deprecatedOverrideLabelPaddingClasses || BASE_LABEL_PADDING_CLASSES}
      ${inline ? INLINE_LABEL_CLASSES : NORMAL_LABEL_CLASSES}
      ${labelClassName}
    `;

    const labelTextClasses = `
      ${BASE_LABEL_TEXT_CLASSES}
      ${labelTextClassName}
    `;

    const radioClasses = `
      ${BASE_RADIO_CLASSES}
      ${deprecatedOverrideRadioMarginClasses || BASE_RADIO_MARGIN_CLASSES}
      ${radioClassName}
    `;

    return (
      <div className={classes}>
        <label htmlFor={id} className={labelClasses}>
          <input ref={ref} name={name} id={id} type="radio" className={radioClasses} {...props} />
          <span className={labelTextClasses}>{children}</span>
        </label>
      </div>
    );
  },
);

Radio.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  radioClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  deprecatedOverrideMarginClasses: PropTypes.string,
  deprecatedOverrideLabelPaddingClasses: PropTypes.string,
  deprecatedOverrideRadioMarginClasses: PropTypes.string,
  children: PropTypes.node,
};

Radio.defaultProps = {
  id: "",
  className: "",
  labelClassName: "",
  radioClassName: "",
  labelTextClassName: "",
  inline: false,
  deprecatedOverrideMarginClasses: "",
  deprecatedOverrideLabelPaddingClasses: "",
  deprecatedOverrideRadioMarginClasses: "",
  children: null,
};

export default Radio;
