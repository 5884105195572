import React, { useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { Col, Row } from "../../../../shared/v1";
import TextInput from "../../../../shared/v2/TextInput";
import Checkbox from "../../../../shared/v2/Checkbox";
import * as actionCreators from "../actions/thunks";
import Button from "../../../../shared/v2/Button";
import errorShape from "../model/error";
import Error from "../Error";
import LoadingOverlay from "../../../../shared/v2/LoadingOverlay";

const Sitebody = ({
  submitAdSiteAsThunk,
  isFromManageModal,
  uuid,
  planId,
  isLoading,
  submitAdSiteError,
  closeModal,
}) => {
  const [checked, toggleChecked] = useState(false);
  const [nameVal, setsitename] = useState(null);
  const [urlVal, seturl] = useState(null);
  const handleSubmit = () => {
    const data = {
      url: urlVal,
      position: null,
      name: nameVal,
      complete: checked,
    };
    submitAdSiteAsThunk(uuid, planId, data, closeModal, isFromManageModal);
  };
  return (
    <LoadingOverlay isLoading={isLoading}>
      {submitAdSiteError && <Error message={submitAdSiteError.message} />}
      <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pt-[32px]">
        <Col size={12} className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0">
          <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
            Site Name <span className="tw-text-coral">*</span>
          </div>
          <TextInput
            onChange={(e) => setsitename(e.target.value)}
            className="tw-w-full"
            data-cy="marketing-advertising-plan-adsite-modal-name-input"
          />
          <div className="tw-text-[14px] tw-pt-[24px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
            URL
          </div>
          <TextInput
            onChange={(e) => seturl(e.target.value)}
            className="tw-w-full tw-mb-[8px]"
            data-cy="marketing-advertising-plan-adsite-modal-url-input"
          />
          <Checkbox
            disabled={!nameVal || !urlVal}
            checked={checked}
            label="Share with client"
            onChange={() => toggleChecked(!checked)}
            data-cy="marketing-advertising-plan-adsite-modal-share-checkbox"
          />
        </Col>
      </Row>
      <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex ">
        <Col xs={8} className="tw-m-0 tw-p-0">
          <Button
            disabled={isLoading}
            onClick={closeModal}
            className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
            schema="tertiary"
            size="medium"
            data-cy="marketing-advertising-plan-adsite-modal-cancel-button"
          >
            CANCEL
          </Button>
        </Col>
        <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            className="tw-flex tw-items-center"
            schema="primary"
            size="medium"
            data-cy="marketing-advertising-plan-adsite-modal-add-button"
          >
            ADD
          </Button>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

Sitebody.propTypes = {
  isFromManageModal: Proptypes.bool,
  submitAdSiteAsThunk: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
  isLoading: Proptypes.bool,
  submitAdSiteError: errorShape,
  closeModal: Proptypes.func,
  planId: Proptypes.string,
};
Sitebody.defaultProps = {
  submitAdSiteError: null,
  isLoading: false,
  isFromManageModal: false,
  closeModal: null,
  planId: null,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  submitAdSiteError: state.tdpMarketingReducer.submitAdSiteError,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  submitAdSiteAsThunk: (uuid, planId, data, closeModal, isFromManageModal) =>
    dispatch(actionCreators.submitAdSiteAsThunk(uuid, planId, data, closeModal, isFromManageModal)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sitebody);
