/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from "react";
import Proptypes from "prop-types";
import StageOption from "./StageOption";
import { Popover, PopoverContent, PopoverTrigger } from "@shared/v2/Popover/Popover";
import Button from "@shared/v2/Button";

// not adding value for "LEAD STAGES" because we are only using it to display as header in dropdown option
export const LEAD_STAGE_MAPPING = [
  {
    label: "LEAD STAGES",
    value: "LEAD STAGES",
    header: "lead",
  },
  {
    label: "NEW LEAD",
    value: "New lead",
    type: "lead",
  },
  {
    label: "ATTEMPTED CONTACT",
    value: "Attempted contact",
    type: "lead",
  },
  {
    label: "SPOKE WITH CUSTOMER",
    value: "Spoke with customer",
    type: "lead",
  },
  {
    label: "APPOINTMENT SET",
    value: "Appointment set",
    type: "lead",
  },
  {
    label: "MET WITH CUSTOMER",
    value: "Met with customer",
    type: "lead",
  },
  {
    label: "SHOWING HOMES",
    value: "Showing homes",
    type: "lead",
  },
  {
    label: "LISTING AGREEMENT",
    value: "Listing agreement",
    type: "lead",
  },
  {
    label: "ACTIVE LISTING",
    value: "Active listing",
    type: "lead",
  },
  {
    label: "SUBMITTING OFFERS",
    value: "Submitting offers",
    type: "lead",
  },
  {
    label: "UNDER CONTRACT",
    value: "Under contract",
    type: "lead",
  },
  {
    label: "SALE CLOSED",
    value: "Sale closed",
    type: "lead",
  },
  {
    label: "NURTURE",
    value: "Nurture",
    type: "lead",
  },
  {
    label: "REJECTED",
    value: "Rejected",
    type: "lead",
  },
];

// not adding value for "CANDIDATE STAGES" because we are only using it to display as header in dropdown option
export const CANDIDATE_STAGE_MAPPING = [
  {
    label: "CANDIDATE/RECRUIT STAGES",
    value: "CANDIDATE/RECRUIT STAGES",
    header: "candidate",
  },
  {
    label: "NEW CANDIDATE",
    value: "New candidate",
    type: "candidate",
  },
  {
    label: "ATTEMPTED CONTACT",
    value: "Attempted contact",
    type: "candidate",
  },
  {
    label: "SPOKE WITH CANDIDATE",
    value: "Spoke with candidate",
    type: "candidate",
  },
  {
    label: "APPOINTMENT SET",
    value: "Appointment set",
    type: "candidate",
  },
  {
    label: "MET WITH CANDIDATE",
    value: "Met with candidate",
    type: "candidate",
  },
  {
    label: "SCREENING",
    value: "Screening",
    type: "candidate",
  },
  {
    label: "SIGNING APPT. SET",
    value: "Signing appt. set",
    type: "candidate",
  },
  {
    label: "SIGNED",
    value: "Signed",
    type: "candidate",
  },
  {
    label: "NURTURE CANDIDATE",
    value: "Nurture candidate",
    type: "candidate",
  },
  {
    label: "REJECTED CANDIDATE",
    value: "Rejected candidate",
    type: "candidate",
  },
  {
    label: "DECLINED OFFER",
    value: "Declined offer",
    type: "candidate",
  },
];

const StagesDropDown = ({
  leadStageMapping,
  candidateStageMapping,
  selectedStage,
  onUpdateStage,
  containerClassName,
  isLoading,
  optionClassName,
}) => {
  const [stage, setStage] = useState(selectedStage);
  const [showStagePicker, setShowStagePicker] = useState(false);

  const selectedTitle =
    [...leadStageMapping, ...candidateStageMapping].find((obj) => obj?.value === stage)?.label || "";

  const selectStage = (stage) => {
    setShowStagePicker(false);
    setStage(stage.value);
    onUpdateStage(stage);
  };

  useEffect(() => {
    if (selectedStage) {
      setStage(selectedStage);
    } else {
      setStage([...leadStageMapping, ...candidateStageMapping].length > 0 ? leadStageMapping[1]?.value : "");
    }
  }, [selectedStage]);

  return (
    <div className="tw-relative tw-flex tw-justify-start">
      <Popover open={showStagePicker} onOpenChange={setShowStagePicker}>
        <PopoverTrigger>
          <Button
            data-cy="leads-stage-button"
            schema='tertiary'
            isLoading={isLoading}
            onClick={() => setShowStagePicker(!showStagePicker)}
            className={`tw-bg-transparent tw-border-none tw-flex tw-justify-start tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center
            tw-uppercase tw-text-center tw-align-middle tw-cursor-pointer tw-w-[180px] tw-h-[24px] tw-text-[#666] tw-text-11px tw-leading-[16px]
            tw-font-bold hover:!tw-bg-transparent hover:tw-border-transparent !tw-bg-transparent tw-border-transparent ${containerClassName}`}>
            {selectedTitle}
            <i
              className={`fa fa-chevron-down tw-ml-[4px] tw-text-11d tw-transition-transform ${showStagePicker && "fa-rotate-180"
                }`}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="tw-h-[240px] tw-z-[3000]">
          {[...leadStageMapping, ...candidateStageMapping].map((stageOption, i) => (
            <StageOption
              key={i}
              containerClassName="tw-w-[180px]"
              stageOption={stageOption}
              selectStage={() => selectStage(stageOption)}
            />
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};

StagesDropDown.propTypes = {
  selectedStage: Proptypes.string,
  containerClassName: Proptypes.string,
  optionClassName: Proptypes.string,
  leadStageMapping: Proptypes.array,
  candidateStageMapping: Proptypes.array,
  onUpdateStage: Proptypes.func,
  isLoading: Proptypes.bool,
};

StagesDropDown.defaultProps = {
  selectedStage: "",
  containerClassName: "",
  optionClassName: "",
  leadStageMapping: LEAD_STAGE_MAPPING,
  candidateStageMapping: CANDIDATE_STAGE_MAPPING,
  onUpdateStage: null,
  isLoading: false,
};

export default StagesDropDown;
