import React from "react";
import { createPortal } from "react-dom";
import Button from "../shared/v2/Button/Button";

const OktaError = ({ imageUrl }) =>
  createPortal(
    <div className="tw-absolute tw-bg-white tw-inset-0 tw-z-1000 tw-flex tw-justify-center tw-items-center">
      <div className="tw-flex tw-flex-col tw-gap-[16px] tw-max-w-[418px] tw-items-center tw-text-center">
        <img className="tw-max-w-[355px]" src={imageUrl} alt="Question Mark" />
        <p className="tw-m-0 tw-text-18d">Looks like you don’t have a CRM account!</p>
        <p className="tw-m-0 tw-text-14d">
          We couldn’t find a CRM account with the email you entered. If you believe this was a mistake, you
          can try again. Or if this issue persists, you can contact support by calling 855-427-4848.
        </p>
        <Button
          className="!tw-bg-teal hover:!tw-bg-teal/80"
          size="medium"
          onClick={() => window.location.assign("/login")}
        >
          Try Again
        </Button>
      </div>
    </div>,
    document.body,
  );

export default OktaError;
