import React from "react";
import ChevronDownSolidV3 from "../../../shared/v2/Icomoon/Icons/ChevronDownSolidV3";

const getIntentClass = (type) => {
  switch (type) {
    case "seller":
      return "tw-text-semantic-yellow-110 tw-border-semantic-yellow-10 hover:tw-border-semantic-yellow-25 active:tw-border-semantic-yellow-50 tw-outline-current";
    case "seller/buyer":
      return "tw-text-semantic-purple-110 tw-border-semantic-purple-10 hover:tw-border-semantic-purple-25 active:tw-border-semantic-purple-50 tw-outline-current";
    case "tenant":
      return "tw-text-semantic-green-110 tw-border-semantic-green-10 hover:tw-border-semantic-green-25 active:tw-border-semantic-green-50 tw-outline-current";
    case "landlord":
      return "tw-text-semantic-red-110 tw-border-semantic-red-10 hover:tw-border-semantic-red-25 active:tw-border-semantic-red-50 tw-outline-current";
    case "recruit":
      return "tw-text-semantic-orange-110 tw-border-semantic-orange-10 hover:tw-border-semantic-orange-25 active:tw-border-semantic-orange-50 tw-outline-current";
    case "candidate":
      return "tw-text-semantic-pink-110 tw-border-semantic-pink-10 hover:tw-border-semantic-pink-25 active:tw-border-semantic-pink-50 tw-outline-current";
    case "n/a":
      return "tw-text-neutral-gray-75 tw-border-neutral-gray-10 hover:tw-border-neutral-gray-30 active:tw-border-neutral-gray-50 tw-outline-current";
    default:
      return "tw-text-semantic-blue-110 tw-border-semantic-blue-10 hover:tw-border-semantic-blue-25 active:tw-border-semantic-blue-50 tw-outline-current";
  }
};

const IntentButton = ({ className = "", isMenu, label, onClick, type, ...props }) => (
  <button
    onClick={onClick}
    className={`tw-bg-white tw-whitespace-nowrap tw-text-11d tw-leading-[16px] tw-font-bold tw-border-[2px] tw-border-solid tw-rounded-[4px] tw-px-[8px] tw-py-[2px] tw-uppercase ${isMenu ? "tw-min-w-[120px]" : ""
      } ${getIntentClass(type)} ${className}`}
    {...props}
  >
    {label == "N/A" ? "NONE" : label}{" "}
    {isMenu && <ChevronDownSolidV3 size="s" className="tw-stroke-current" />}
  </button>
);

export default IntentButton;
