import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { snakeCase } from "lodash";
import Checkbox from "../../shared/v2/Checkbox/Checkbox";
import FloatingForm from "../../shared/v2/Modal/FloatingForm";
import TextInput from "../../shared/v2/TextInput/TextInput";
import NumberField from "./NumberField";

const formToData = (form) => ({
  goal: {
    default: true,
    goal_value: form.elements.goal_value.value.replaceAll(",", ""),
  },
  goalType: {
    display_name: form.elements.display_name.value,
    format: form.elements.format.checked ? "currency" : "number",
    name: snakeCase(form.elements.display_name.value),
  },
});

const checkFormValidity = (form) => {
  const errors = {};
  if (!form.elements.display_name.value.trim()) errors.display_name = "This field is required";
  return Object.keys(errors).length > 0 ? errors : true;
};

const CustomActivityGoalModal = ({ onClose, onSubmit, show }) => {
  const formRef = useRef(null);
  const [errors, setErrors] = useState(null);
  const [isCurrency, setIsCurrency] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrorsOrValid = checkFormValidity(formRef.current);
    if (formErrorsOrValid === true) onSubmit(formToData(formRef.current));
    else setErrors(formErrorsOrValid);
  };

  return (
    <FloatingForm
      title="Add new custom goal"
      onCancel={onClose}
      show={show}
      onSecondary={onClose}
      primaryOption="Add"
      onPrimary={handleSubmit}
      closeOnClickOutside
      closeOnEscape
    >
      <form
        ref={formRef}
        className="tw-flex tw-flex-col tw-gap-[24px]"
        onSubmit={handleSubmit}
        onChange={() => setErrors(null)}
      >
        <div>
          <label htmlFor="goalName">
            Goal Name <span className="tw-text-semantic-red-100">*</span>
          </label>
          <TextInput id="goalName" name="display_name" required error={errors?.display_name} />
        </div>
        <div>
          <label htmlFor="teamDefaultValue">
            Team Default Value <span className="tw-text-semantic-red-100">*</span>
          </label>
          <NumberField
            id="teamDefaultValue"
            name="goal_value"
            format={isCurrency ? "currency" : "number"}
            required
          />
        </div>
        <Checkbox
          onChange={(e) => setIsCurrency(e.target.checked)}
          checked={isCurrency}
          value={isCurrency}
          name="format"
          id="format"
          label="This is a financial goal (prepend '$' to the input)"
        />
      </form>
    </FloatingForm>
  );
};

CustomActivityGoalModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomActivityGoalModal;
