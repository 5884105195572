import React, { useState } from "react";
import { deleteSearch } from "./services";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";

const DeleteSearch = ({ userId, searchId, category, onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteAndClose = async () => {
    setIsDeleting(true);
    await deleteSearch(userId, searchId);

    setIsDeleting(false);
    onClose(true);
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full"
      contentClassName="tw-m-auto tw-flex tw-flex-col tw-max-w-[466px] tw-gap-24px"
      onHide={onClose}
      show
    >
      <Modal.Header title={`Delete ${category} Search`} closeButton />
      <Modal.Body>
        <div className="tw-mb-32px">
          Are you sure you want to delete this search? This action cannot be undone.
        </div>
        <hr className="tw-w-full !tw-m-0" />
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-between">
          <Button schema="tertiary" size="medium" onClick={onClose}>
            Cancel
          </Button>
          <Button schema="warning" size="medium" onClick={deleteAndClose} isLoading={isDeleting}>
            Yes, Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSearch;
