import React from "react";
import { arrayOf, func, number, string } from "prop-types";
import { connect } from "react-redux";

import { togglePrimaryFilter } from "../actions/creators";

import FILTER_DICTIONARY from "./fixtures";

const { BORDER_DICT, BG_DICT, TEXT_DICT, CIRCLE_DICT } = FILTER_DICTIONARY;

const Filter = ({ color, children, activeTarget, count, onTogglePrimaryFilter, primaryActiveList }) => (
  <button
    type="button"
    className={`tw-px-[12px] tw-whitespace-nowrap tw-border-[1px] tw-bg-transparent tw-border-solid tw-rounded-full tw-text-[12px] tw-flex tw-items-center tw-cursor-pointer tw-mr-6px ${
      primaryActiveList.includes(activeTarget)
        ? `${BORDER_DICT[color]} ${BG_DICT[color]}`
        : "tw-border-gray-15"
    }`}
    onClick={() => onTogglePrimaryFilter(activeTarget)}
  >
    <div className={`tw-rounded-full tw-w-[8px] tw-h-[8px] tw-mr-[6px] ${CIRCLE_DICT[color]}`} />
    <span className={primaryActiveList.includes(activeTarget) ? TEXT_DICT[color] : ""}>
      {children} ({count})
    </span>
  </button>
);

const mapStateToProps = (state) => ({
  primaryActiveList: state.tdpTasksReducer.primaryActiveList,
});

const mapDispatchToProps = (dispatch) => ({
  onTogglePrimaryFilter: (primaryFilter) => dispatch(togglePrimaryFilter(primaryFilter)),
});

Filter.propTypes = {
  color: string,
  children: string,
  activeTarget: string,
  count: number,
  onTogglePrimaryFilter: func,
  primaryActiveList: arrayOf(string),
};

Filter.defaultProps = {
  color: "",
  children: "",
  activeTarget: "",
  count: 0,
  onTogglePrimaryFilter: () => {},
  primaryActiveList: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
