import axios from 'axios';

const getAvailablePropertyTypes = async (personId, mlsIds) => {
  const url = `/people/${personId}/listing_alert_property_types.json?mls_ids=${mlsIds.join(',')}`;
  const { data } = await axios.get(url, createHeaders());

  return data.property_types;
};

const getAvailableFeatureFilters = async (personId, mlsIds) => {
  const url = `/people/${personId}/listing_alert_filter_options.json?mls_ids=${mlsIds.join(',')}`;
  const { data } = await axios.get(url, createHeaders());

  return data.filter_options;
};

const getWebsiteDomain = async personId => {
  const url = `/people/${personId}/listing_alert_website_domain.json`;
  const { data } = await axios.get(url, createHeaders());

  return data.website_domain;
};

const getListingCounts = async (personId, alert) => {
  const url = `/people/${personId}/listing_alert_counts.json`;
  const { data } = await axios.post(url, alert, createHeaders());

  return data.total;
};

const getInstantAlertsEnabled = async (personId) => {
  const url = `/people/${personId}/listing_alerts/instant_alerts_enabled.json`;
  const { data } = await axios.get(url);

  return data.instant_alerts_enabled;
};

const getQueryStringForIdx = async (personId, alert) => {
  const url = `/people/${personId}/listing_alert_search_query.json`;
  const { data } = await axios.post(url, alert, createHeaders());

  return data.search_query;
};

const getAlerts = async personSlug => {
  const url = `/people/${personSlug}/listing_alerts`;
  return axios.get(url, createHeaders());
}

const createAlert = async (personId, alert) => {
  const url = `/people/${personId}/listing_alerts`
  return axios.post(url, alert, createHeaders());
};

const updateAlert = async (personId, alertId, alert) => {
  const url = `/people/${personId}/listing_alerts/${alertId}`
  return axios.patch(url, alert, createHeaders());
};

const getTemplates = async (personId) => {
  const url = `/listing_alert_templates`;
  const { data } = await axios.get(url, createHeaders());

  return data || [];
};

export {
  getAvailablePropertyTypes,
  getAvailableFeatureFilters,
  getWebsiteDomain,
  getListingCounts,
  getInstantAlertsEnabled,
  getQueryStringForIdx,
  getAlerts,
  createAlert,
  updateAlert,
  getTemplates
};

const createHeaders = () => {
  return {
    headers: {
      "X-CSRF-Token": ReactOnRails.authenticityToken(),
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  };
};
