import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "../../../shared/v1";

const AgentDropdown = ({ agents, formatOptions, option, selectedAgents }) => (
  <div data-cy="agents-dropdown-wrapper" style={{ paddingBottom: "30px" }}>
    <label
      htmlFor="transactions-filter_agent-dropdown"
      style={{
        letterSpacing: "0.2px",
        fontSize: "14px",
        fontFamily: "Open Sans",
      }}
    >
      <b>AGENT</b>
    </label>
    <Dropdown
      id="transactions-filter_agent-dropdown"
      components={{ Option: option }}
      placeholder={selectedAgents.length > 1 ? "Multiple Users" : selectedAgents[0]}
      value={selectedAgents.length === 1 ? selectedAgents[0] : null}
      closeMenuOnSelect={false}
      isClearable={false}
      options={formatOptions(agents)}
      variant="flatGray"
    />
  </div>
);

AgentDropdown.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formatOptions: PropTypes.func.isRequired,
  option: PropTypes.node.isRequired,
  selectedAgents: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AgentDropdown;
