/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FileLinesSolidV6 = (props) => (
  <Icon {...props} name="File Lines Solid V6">
    <path d="M10 0v4h4l-4-4zM9 4V0H3.5A1.5 1.5 0 0 0 2 1.5v13A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5V5h-3.972C9.447 5 9 4.553 9 4zm1.5 9h-5c-.275 0-.5-.225-.5-.5s.225-.5.5-.5h5a.5.5 0 0 1 0 1zm0-2h-5c-.275 0-.5-.225-.5-.5s.225-.5.5-.5h5a.5.5 0 0 1 0 1zm.5-2.5c0 .275-.225.5-.5.5h-5c-.275 0-.5-.225-.5-.5s.225-.5.5-.5h5c.275 0 .5.225.5.5z" />
  </Icon>
);

export default FileLinesSolidV6;
