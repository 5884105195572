import React from "react";
import PropTypes from "prop-types";

import { PlusSolidV6 } from "../../../shared/v2/Icomoon";
import Dropdown from "../../../shared/v2/Dropdown/Dropdown";

const GroupHeading = ({ children, componentProps }) => {
  if (children === "new") {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Dropdown.GroupHeading {...componentProps} />;
  }

  return (
    <Dropdown.GroupHeading
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...componentProps}
      className="!tw-bg-white"
      preserveStyle
    >
      <div className="tw-flex tw-flex-row tw-w-full tw-gap-6px tw-items-center">
        <div className="tw-text-12px tw-font-semibold tw-text-gray-50 tw-whitespace-nowrap">{children}</div>
        <span className="tw-w-full tw-h-1px tw-bg-gray-10" />
      </div>
    </Dropdown.GroupHeading>
  );
};

GroupHeading.propTypes = {
  children: PropTypes.shape({}).isRequired,
  componentProps: PropTypes.shape({}).isRequired,
};

const SingleValue = ({ componentProps, children }) => (
  <Dropdown.Menu
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    preserveStyle
  >
    {children}
  </Dropdown.Menu>
);

SingleValue.propTypes = {
  children: PropTypes.shape({}).isRequired,
  componentProps: PropTypes.shape({}).isRequired,
};

const formatOptionLabel = ({ label, value }) =>
  value === "new" ? (
    <div>
      <PlusSolidV6 className="tw-h-[12px] tw-w-[12px] tw-mr-8px" />
      {label}
    </div>
  ) : (
    label
  );

const SourcesDropdown = ({ source, setSource, sources, trackingNumberName }) => {
  const value =
    source === "new" && trackingNumberName
      ? { label: trackingNumberName, value: "new" }
      : sources.find((s) => s?.value === source);

  const groupedSources = [
    {
      label: "new",
      options: [
        { label: "Make new source with Tracking Number Name", value: "new", shouldBeDisplayed: false },
      ],
    },
    {
      label: "Existing Sources",
      options: sources,
    },
  ];

  return (
    <Dropdown
      placeholder="Search or select source"
      isDisabled={!trackingNumberName}
      isSearchable
      value={value}
      options={groupedSources}
      onChange={(s) => setSource(s.value)}
      components={{
        GroupHeading,
        SingleValue,
      }}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

SourcesDropdown.propTypes = {
  source: PropTypes.string.isRequired,
  setSource: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trackingNumberName: PropTypes.string.isRequired,
};

export default SourcesDropdown;
