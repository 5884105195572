import React from "react";
import PropTypes from "prop-types";
import { Col, Row, ListGroup, ListGroupItem } from "react-bootstrap";

const FormErrors = ({ formErrors }) => (
  <Row style={{ marginTop: "8px" }}>
    <Col md={2}>There were {Object.keys(formErrors).length} errors.</Col>
    <Col md={10}>
      <ListGroup>
        {Object.entries(formErrors).map(([name, messages]) => (
          <ListGroupItem key={`error-${name}`}>
            {name}: {messages.join(", ")}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Col>
  </Row>
);

FormErrors.propTypes = {
  formErrors: PropTypes.shape().isRequired,
};

export default FormErrors;
