import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import ViewAllTransactionsModal from "./modals/ViewAllTransactionsModal";
import TypeCell from "../../Transactions/components/shared/TypeCell";
import cards from "./cards.module.css";
import transaction from "./transactions.module.css";
import css from "./person-detail-styles.module.css";
import { isReferral } from "../../TransactionDetailsPage/shared/helpers";
import ThumbnailIcon from "../../Transactions/components/shared/ThumbnailIcon";
import ImageCell from "../../Transactions/components/shared/ImageCell";
import * as actions from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import AddTransactionModal from "../../Transactions/AddTransactionModal";
import Loading from "../../Goals/components/Loader";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import TextButton from "@shared/v2/TextButton";

const Transactions = ({ newMilestoneId }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);
  const personFullName = `${person.data?.attributes?.first_name || ""} ${person.data?.attributes?.last_name || ""}`.trim();
  const transactions = useSelector((state) => state.personDetail.transactions);
  const transactionCount = useSelector((state) => state.personDetail.transactionCount);
  const transactionModalOpen = useSelector((state) => state.personDetail.transactionModalOpen);
  const [viewTransactionModal, setViewTransactionModal] = useState(false);

  useEffect(() => {
    if (person.data?.id) dispatch(actions.fetchTransactions(person.data.id));
  }, [person.data?.id]);

  const fetchListingType = (transactionType, buyerTransaction) => {
    if (isReferral(transactionType)) {
      return transactionType;
    }
    return buyerTransaction ? "buyer" : "seller";
  };

  return (
    <CollapsibleElement
      id="pdp-transactions"
      title={
        <span className="tw-flex tw-items-center tw-gap-[8px]">
          Transactions
          {transactionCount > 0 && (
            <div className="tw-inline-flex tw-justify-center tw-items-center tw-px-[2px] tw-min-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-rounded-full">
              {transactionCount}
            </div>
          )}
        </span>
      }
      buttonText="+ ADD"
      buttonClickFunction={() => dispatch(actions.toggleTransactionModal())}
    >
      {!person.data && <Loading />}
      <div id="person-transactions-card">
        {transactions.length === 0 && (
          <div className={css.personDetailEmptyStateText}>
            Transactions associated with this contact will appear here.
          </div>
        )}
        <Row>
          {transactions
            .slice(0, 3)
            .map(
              (
                {
                  address,
                  link,
                  mls,
                  status,
                  status_class,
                  transaction_type,
                  buyer_transaction,
                  photo_without_placeholder,
                },
                i,
              ) => (
                <div key={i} className={css.materialDesignBox}>
                  <div className={cards.cardRow} style={{ display: "inline-block", width: "100%" }}>
                    <Col xs={12} style={{ padding: "0px" }}>
                      <div style={{ float: "left", marginRight: "14px" }}>
                        <div className="tw-flex tw-w-[95px] tw-h-[63px] tw-justify-center tw-items-center">
                          {photo_without_placeholder ? (
                            <ImageCell
                              containerClass="tw-w-[95px] tw-h-[63px]"
                              value={photo_without_placeholder}
                              isEditable={false}
                            />
                          ) : (
                            <ThumbnailIcon
                              onClick={() => window.open(link, "_self")}
                              containerSize="full"
                              transactionType={fetchListingType(transaction_type, buyer_transaction)}
                              size="xxxl"
                            />
                          )}
                        </div>
                      </div>
                      <div className={transaction.overflow}>
                        <div className={`${transaction.transactionDetails} overflow`}>
                          <a href={link}>{address}</a>
                          {mls !== "#" && <p>MLS {mls}</p>}
                          <div className={`${mls === "#" && "tw-pt-[4px]"} tw-flex tw-items-center`}>
                            <TypeCell value={fetchListingType(transaction_type, buyer_transaction)} />
                            <p style={{ fontWeight: "bold" }} className={classNames(status_class, "tw-ml-2")}>
                              {status}
                            </p>
                          </div>
                          <div className="fade-out" style={{ top: "0", width: "25px" }} />
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              ),
            )}
        </Row>
        {transactionCount > 3 && (
          <TextButton className="tw-mt-[10px]" onClick={() => setViewTransactionModal(true)}>
            View All
          </TextButton>
        )}
      </div>

      <ViewAllTransactionsModal
        show={viewTransactionModal}
        person={person}
        onClose={() => setViewTransactionModal(false)}
      />

      <AddTransactionModal
        currentUserId={currentUser.id}
        currentUserFullName={currentUser.fullName}
        currentUserTeamId={currentUser.person.id}
        personId={person.data?.id}
        personName={personFullName}
        newMilestoneId={newMilestoneId}
        showModal={transactionModalOpen}
        onClose={() => dispatch(actions.toggleTransactionModal())}
        isStandalone
      />
    </CollapsibleElement>
  );
};

export default Transactions;
