import React from "react";

import { ListCheckSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const NewTaskAcitivity = ({ activity, onReadChange }) => {
  const { assignerName, taskDueDate, taskType, taskSubType } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={ListCheckSolidV6}
      childContainerClass="!tw-mr-[0px]"
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        {taskSubType ? <span className="tw-whitespace-nowrap">{taskSubType}</span> : null}
        <span className="tw-whitespace-nowrap">Type: {taskType}</span>
        <PipedElements>
          <span className="tw-whitespace-nowrap">Due: {taskDueDate}</span>
          <span className="tw-whitespace-nowrap">From: {assignerName}</span>
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

NewTaskAcitivity.propTypes = {
  ...activityCommonPropTypes,
};

NewTaskAcitivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default NewTaskAcitivity;
