import React from "react";
import Skeleton from "./Skeleton";
import formatPhoneNumber from "@shared/FormatPhoneNumber";

const TimelinePhoneNumbersArchivedConversations = ({
  event: {
    interacted_at_absolute: interactedAt,
    timeline_fields: { phone_number: phoneNumber },
  },
}) => {
  const formattedNumber = formatPhoneNumber(phoneNumber);

  return (
    <Skeleton
      iconClassName="fa6 fa6-box-archive"
      title={<span className="tw-font-bold">System archived existing conversation(s)</span>}
      timestamp={interactedAt}
      body={
        <p>
          The phone number {formattedNumber} was removed from this contact. As a result, any existing
          conversations with this phone number were archived.
        </p>
      }
    />
  );
};

export default TimelinePhoneNumbersArchivedConversations;
