import React from "react";

import PropTypes from "prop-types";

import { FLYOUT_BODY_CLASSES } from "./Flyout.styles";

const FlyoutBody = ({ children, className }) => (
  <div className={`${FLYOUT_BODY_CLASSES} ${className || ""}`}>{children}</div>
);

FlyoutBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FlyoutBody.defaultProps = {
  children: null,
  className: "",
};

export default FlyoutBody;
