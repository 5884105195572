import { shape, string, number, bool, oneOfType } from "prop-types";

const shapeValue = shape({ label: string, value: string });

const shapeContingent = shape({
  numOfDays: string,
  next: string,
  event: shapeValue,
});

export const shapeRecurring = shape({
  frequency: shapeValue,
  recurring_every_so_many_days: number,
  recurring_day_of_week: shapeValue,
});

export const payloadPropTypes = shape({
  title: string,
  Instructions: string,
  Notes: string,
  Priority: number,
  Visibility: bool,
  recurring: oneOfType([bool, shapeRecurring]),
  contingent: oneOfType([bool, shapeContingent]),
  Date: string,
});

export const PAYLOAD_DEFAULT = {
  title: "",
  Instructions: "",
  Notes: "",
  Priority: null,
  Visibility: false,
  recurring: null,
  contingent: null,
  Date: null,
};
