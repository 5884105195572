import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Tooltip from "@shared/v2/Tooltip";
import { homeAppTxt } from "@shared/Utilities";
import "./HomeAppLink.scss";
import IconButton from "@shared/v2/IconButton";
import {
  CommentDotsSolidV6,
  EnvelopeSolidV6,
  LinkSolidV6,
  MobileScreenSolidV6,
  SpinnerSolidV6,
} from "@shared/v2/Icomoon";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import { intlFormatDistance } from "date-fns";

class HomeAppLink extends Component {
  constructor(props) {
    super(props);
    const { inviteSent } = props;
    this.state = { loading: false, inviteSent };
  }

  handleSelectedType = (type, appUrl) => {
    const { personId, recipientFirstName, recipientEmailAddress, teamName, currentUserFullName } = this.props;
    const sharedUrlContent = "Download my Brivity Home search app to search for homes here: ";

    if (type === "email") {
      const emailBody = `${sharedUrlContent} <a href="${appUrl}" target="_blank">${appUrl}</a>.<br/><br/>`;
      const event = new CustomEvent("homeAppEmailClick", {
        detail: {
          personId,
          emailBody,
        },
      });
      window.dispatchEvent(event);
    } else if (type === "text") {
      const event = new CustomEvent("homeAppSmsClick", {
        detail: {
          personId,
          text: homeAppTxt({
            primaryAgentName: currentUserFullName,
            brivityAppLink: appUrl,
            recipientFirstName,
            recipientEmailAddress,
            teamName,
          }),
        },
      });
      window.dispatchEvent(event);
    } else if (type === "copy") {
      navigator.clipboard.writeText(appUrl).catch((error) => {
        console.log(error);
      });
    }
  };

  fetchBrivityHomeURL = (type) => {
    const { slug } = this.props;
    this.setState({ loading: true });

    axios
      .get(`/people/${slug}/home_app`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({ loading: false }, () => this.handleSelectedType(type, response.data.url));
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  formattedLabel() {
    const { lastLogin } = this.props;
    const { inviteSent } = this.state;
    let label = "";

    if (lastLogin !== null) {
      label = intlFormatDistance(new Date(lastLogin), new Date());
    } else if (inviteSent !== null) {
      label = "Sent";
    }
    return label;
  }

  render() {
    const { loading } = this.state;
    const { emailActive, smsActive } = this.props;
    const label = this.formattedLabel();

    return (
      <div className="home-app-column">
        {!emailActive ? (
          <Tooltip
            multiline
            placement="left"
            innerClassName="tw-max-w-[180px]"
            content="An email address is required to log in to Brivity Home. Please add an email address to the contact before sending an invite."
            trigger={
              <IconButton schema="transparent" disabled>
                <MobileScreenSolidV6 size="xl" />
              </IconButton>
            }
          />
        ) : (
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <IconButton schema="transparent" disabled={loading}>
                {loading ? (
                  <SpinnerSolidV6 className="tw-animate-spin" size="xl" />
                ) : (
                  <MobileScreenSolidV6 size="xl" />
                )}
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              {smsActive && (
                <PopoverItem icon={<CommentDotsSolidV6 />} onClick={() => this.fetchBrivityHomeURL("text")}>
                  Text
                </PopoverItem>
              )}
              <PopoverItem icon={<EnvelopeSolidV6 />} onClick={() => this.fetchBrivityHomeURL("email")}>
                Email
              </PopoverItem>
              <PopoverItem icon={<LinkSolidV6 />} onClick={() => this.fetchBrivityHomeURL("copy")}>
                Copy Link
              </PopoverItem>
            </PopoverContent>
          </Popover>
        )}
        <h6 className={label && "lower-row"}>
          <span>{label}</span>
        </h6>
      </div>
    );
  }
}

HomeAppLink.propTypes = {
  slug: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
  emailActive: PropTypes.bool.isRequired,
  smsActive: PropTypes.bool.isRequired,
  lastLogin: PropTypes.string,
  inviteSent: PropTypes.string,
  recipientFirstName: PropTypes.string.isRequired,
  recipientEmailAddress: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  currentUserFullName: PropTypes.string.isRequired,
};

HomeAppLink.defaultProps = {
  lastLogin: null,
  inviteSent: null,
};

export default HomeAppLink;
