import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import Tab from "@shared/v2/Tab";
import TabBar from "@shared/v2/TabBar";
import Checkbox from "@shared/v2/Checkbox";
import { Section, SectionLabel } from "./Section";
import TextToggle from "../../../shared/v2/TextToggle/TextToggle";
import useAgentProduction from "./services/useAgentProduction";
import Dot from "./Dot";
import Loader from "../../../Dashboard/components/Loader";
import Error from "../DashboardShared/Error";

const AgentProduction = ({ year }) => {
  const chartRef = useRef(null);
  const [type, setType] = useState("buyerSeller");
  const [option, setOption] = useState("units");
  const [showClosed, setShowClosed] = useState(true);
  const [showPending, setShowPending] = useState(true);
  const [rerender, setRerender] = useState(true);

  const { chartOptions, data, error, loading } = useAgentProduction({
    type,
    option,
    showClosed,
    showPending,
    year,
  });

  useEffect(() => {
    if (chartRef.current) chartRef.current.resize();
  }, [data?.datasets?.[0]?.data.length]);

  useEffect(() => {
    setRerender(false);
  }, [type, option]);

  useEffect(() => {
    if (!rerender) setRerender(true);
  }, [rerender]);

  return (
    <Section className="tw-gap-[16px]">
      <SectionLabel>Agent Production</SectionLabel>
      <TabBar>
        <Tab
          label="Buyer & Seller"
          onClick={() => setType("buyerSeller")}
          selected={type === "buyerSeller"}
        />
        <Tab
          label="Tenant & Landlord"
          onClick={() => setType("tenantLandlord")}
          selected={type === "tenantLandlord"}
        />
        <Tab label="Referrals" onClick={() => setType("referrals")} selected={type === "referrals"} />
      </TabBar>
      <div className="tw-flex tw-justify-between tw-mb-[16px]">
        <TextToggle
          className="tw-self-start"
          size="small"
          name="agentProductionToggle"
          options={[
            {
              label: "Units",
              value: "units",
            },
            {
              label: "Volume",
              value: "volume",
            },
            {
              label: "GCI",
              value: "gci",
            },
          ]}
          value={option}
          onChange={(e) => setOption(e.target.value)}
        />
        <div className="tw-flex tw-gap-[16px]">
          <Checkbox
            checked={showClosed}
            onChange={() => setShowClosed(!showClosed)}
            label={
              <span className="tw-flex tw-gap-[6px] tw-items-center">
                <Dot className="tw-bg-theme-primary" /> Closed
              </span>
            }
          />
          {type !== "referrals" && (
            <Checkbox
              checked={showPending}
              onChange={() => setShowPending(!showPending)}
              label={
                <span className="tw-flex tw-gap-[6px] tw-items-center">
                  <Dot className="tw-bg-semantic-yellow-100" /> Pending
                </span>
              }
            />
          )}
        </div>
      </div>
      <Error show={Boolean(error)} />
      {rerender && <Bar ref={chartRef} data={data} options={chartOptions} plugins={[ChartDataLabels]} />}
      {loading && <Loader />}
    </Section>
  );
};

AgentProduction.propTypes = {
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AgentProduction;
