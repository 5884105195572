import React from "react";
import { bool, func, shape } from "prop-types";

import Button from "../../../../shared/v2/Button";
import { Plus } from "../../../../shared/v2/Icomoon";

import BUTTONS from "./constants";

const ModalAddEditTaskButtons = ({ enabledAddEditInputs, setEnabledAddEditInputs, payload }) => (
  <div className="tw-flex">
    {BUTTONS.map((btnTxt) => (
      <Button
        key={btnTxt}
        schema="secondary"
        className={`tw-mr-8px tw-mt-24px ${
          (enabledAddEditInputs[btnTxt.toLowerCase()] || payload[btnTxt.toLowerCase()]) && "tw-hidden"
        }`}
        onClick={() =>
          setEnabledAddEditInputs((prev) => ({
            ...prev,
            [btnTxt.toLowerCase()]: !prev[btnTxt.toLowerCase()],
          }))
        }
      >
        <Plus size="s" className="tw-mr-4px" />
        <span>{btnTxt}</span>
      </Button>
    ))}
  </div>
);

ModalAddEditTaskButtons.propTypes = {
  enabledAddEditInputs: shape({
    Instructions: bool,
    Notes: bool,
  }),
  setEnabledAddEditInputs: func,
  payload: shape({}),
};

ModalAddEditTaskButtons.defaultProps = {
  enabledAddEditInputs: {
    Instructions: false,
    Notes: false,
  },
  setEnabledAddEditInputs: null,
  payload: null,
};

export default ModalAddEditTaskButtons;
