import React from "react";
import { kebabCase } from "lodash";
import { Col, Row, FormGroup, TextArea, Panel, Clearfix, Input } from "../../../../shared/v1";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import AddExpenseModal from "../AddExpenseModal";
import Tooltip from "../../../../shared/v2/Tooltip";
import { Info, Plus } from "../../../../shared/v2/Icomoon";
import DragHandle from "../../shared/DragHandle";
import TransactionInput from "../../shared/TransactionInput";
import Button from "../../../../shared/v2/Button";
import { companyOptions } from "./helpers";

const SortableItem = sortableElement(({ value, sortIndex, accountData, setAccountData }) => {
  const { name } = value;
  return (
    <div className="transaction-gci-content content-additional">
      <Row className="tw-border-0 tw-border-t tw-border-solid tw-border-gray tw-pt-[10px]">
        <DragHandle />

        <Col size={2}>
          <TransactionInput
            label={name}
            value={value.value}
            isPercentage={!!value.percentage}
            name={`offTax${sortIndex}`}
            onValueUpdate={(e) => {
              if (!!value.percentage) {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  value.value = e.target.value;
                  setAccountData(accountData);
                }
              } else {
                value.value = e.target.value;
                setAccountData(accountData);
              }
            }}
            onRadioUpdate={(e) => {
              if (e.target.value === "true" && value.value > 100) {
                value.value = "";
              }
              value.percentage = e.target.value === "true";
              setAccountData(accountData);
            }}
          />
        </Col>

        <Col size={4}>
          <FormGroup className="tw-min-h-[50px]">
            <label className="tw-block tw-mb-[5px]">Note</label>

            <TextArea
              size="auto"
              className="description"
              name={`offnote${sortIndex}`}
              placeholder=""
              value={value.notes || ""}
              onChange={(e) => {
                value.notes = e.target.value;
                setAccountData(accountData);
              }}
            />
          </FormGroup>
        </Col>

        <div className="delete-expense">
          <a
            onClick={() => {
              accountData.company_expense_template.custom_agent_expenses_attributes[sortIndex]._destroy = "1";
              let position = 0;
              accountData.company_expense_template.custom_agent_expenses_attributes.map((obj) => {
                if (obj._destroy) {
                  if (obj.position) {
                    delete obj["position"];
                  }
                } else {
                  obj.position = position + 1;
                  position = position + 1;
                }
              });
              setAccountData(accountData);
            }}
          >
            <i className="fa fa-times" />
          </a>
        </div>
      </Row>
    </div>
  );
});

const SortableContainer = sortableContainer(({ items, accountData, setAccountData }) => {
  return (
    <Row>
      {items.map(
        (value, index) =>
          !value._destroy && (
            <SortableItem
              key={`office-${index}`}
              index={index}
              value={value}
              sortIndex={index}
              accountData={accountData}
              setAccountData={setAccountData}
            />
          ),
      )}
    </Row>
  );
});

const CompanyForm = ({
  isModalOpen,
  openNewExpenseModal,
  setCompanyExpense,
  accountData,
  setAccountData,
  closeNewExpenseModal,
  expenseType,
  activeAgentExpense,
}) => {
  const {
    company_expense_template: company_expense,
    company_expense_types,
    company_revenue_types,
    transaction_expense_types,
    agent_expense_types,
  } = accountData;
  const { id, name, custom_agent_expenses_attributes: expenses } = company_expense;
  const onAddExpense = () => {
    openNewExpenseModal();
    setCompanyExpense();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    accountData.company_expense_template.custom_agent_expenses_attributes = arrayMove(
      accountData.company_expense_template.custom_agent_expenses_attributes,
      oldIndex,
      newIndex,
    );
    let position = 0;
    accountData.company_expense_template.custom_agent_expenses_attributes.map((obj) => {
      if (obj._destroy) {
        if (obj.position) {
          delete obj["position"];
        }
      } else {
        obj.position = position + 1;
        position = position + 1;
      }
    });
    setAccountData(accountData);
  };

  return (
    <div className="accordion" id={`accordion${id}`}>
      <Panel className="tw-border-gray" deprecatedOverrideMarginClasses="tw-m-0">
        <Panel.Heading className="tw-bg-[#fff]" deprecatedOverrideBorderClasses="tw-border-0">
          <Panel.Title
            className="title tw-text-brand-primary"
            deprecatedOverrideTextClasses="tw-text-[15px] tw-leading-[1.1]"
          >
            <Panel.Trigger
              data-cy={`financials-setting-accordion`}
              className="tw-no-underline hover:tw-no-underline active:tw-no-underline focus:tw-no-underline tw-relative"
              data-parent={`#accordion${id}`}
              id={`company${id}`}
            >
              {name}
            </Panel.Trigger>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse
          className="!tw-max-h-fit"
          id={`company${id}`}
          data-cy={`financial-settings-company-form-${kebabCase(name)}`}
        >
          <Panel.Body
            className="tw-bg-[#fbfafa] tw-border-0 tw-border-t tw-border-solid tw-border-[#ddd]"
            deprecatedOverridePaddingClasses="tw-p-0"
          >
            <Clearfix className="transaction-gci-wrap">
              <div className="transaction-gci-options">
                <div className="transaction-gci-header">
                  <h3>Details</h3>
                </div>

                <div className="transaction-gci-content">
                  <Row>
                    <Col size={2}>
                      <FormGroup className="tw-min-h-[50px]">
                        <label className="tw-block tw-mb-[5px]">
                          Company Name
                          <Tooltip
                            multiline
                            placement="top"
                            content={
                              <div className="tw-w-[226px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                                Company Name is how your team will be labeled in transaction financials. It
                                does not affect the Team Name field in Account Settings.
                              </div>
                            }
                            trigger={<Info className="tw-mb-[-1px] tw-ml-6px" />}
                          />
                        </label>
                        <Input
                          name="companyName"
                          value={name}
                          onChange={(e) => {
                            accountData.company_expense_template.name = e.target.value;
                            accountData.company_name = e.target.value;
                            setAccountData(accountData);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="transaction-gci-options">
                <div className="transaction-gci-header dnd">
                  <h3>Company Expenses/Income</h3>
                  <Button
                    data-cy="add-company-transaction"
                    className="tw-float-right"
                    schema="primary"
                    size="medium"
                    onClick={onAddExpense}
                  >
                    <Plus size="m" className="tw-mr-[6px]" />
                    ADD
                  </Button>
                </div>

                <SortableContainer
                  items={expenses}
                  onSortEnd={onSortEnd}
                  accountData={accountData}
                  setAccountData={setAccountData}
                  useDragHandle
                />
              </div>
            </Clearfix>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
      {isModalOpen && (
        <AddExpenseModal
          isModalOpen={isModalOpen}
          closeNewExpenseModal={closeNewExpenseModal}
          accountData={accountData}
          setAccountData={setAccountData}
          expenseType={expenseType}
          companyList={companyOptions(company_expense_types, company_revenue_types)}
          transactionExpenseList={transaction_expense_types}
          agentExpenseList={agent_expense_types}
          activeAgentExpense={activeAgentExpense}
        />
      )}
    </div>
  );
};
export default CompanyForm;
