/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Word = (props) => (
  <Icon {...props} name="Word">
    <path d="M9 4.25V0H2.75A.748.748 0 0 0 2 .75v14.5c0 .416.334.75.75.75h10.5c.416 0 .75-.334.75-.75V5H9.75A.752.752 0 0 1 9 4.25zM10.784 8h.747c.241 0 .419.222.366.459l-1.188 5.25a.372.372 0 0 1-.366.291H9.155a.374.374 0 0 1-.362-.284c-.806-3.234-.65-2.537-.8-3.453h-.016c-.034.447-.075.544-.8 3.453a.374.374 0 0 1-.363.284H5.655a.375.375 0 0 1-.366-.294l-1.181-5.25A.374.374 0 0 1 4.474 8h.766c.178 0 .334.125.369.303.487 2.437.628 3.422.656 3.819.05-.319.228-1.022.919-3.834a.372.372 0 0 1 .366-.284h.909c.175 0 .325.119.366.287.75 3.138.9 3.875.925 4.044-.006-.35-.081-.556.675-4.038A.362.362 0 0 1 10.784 8zM14 3.809V4h-4V0h.191c.2 0 .391.078.531.219l3.059 3.063A.746.746 0 0 1 14 3.81z" />
  </Icon>
);

export default Word;
