import React from "react";
import { connect } from "react-redux";
import {
  addTransactionReportAccess,
  removeTransactionReportAccess,
  updateTransactionReportAccess,
} from "../actions/creators";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import TextButton from "../../../../shared/v2/TextButton/TextButton";
import FormElement from "./FormElement";
import AvatarControl from "./AvatarControl";
import AvatarOption from "./AvatarOption";
import DisplayPerson from "./DisplayPerson";

const TransactionReportAccess = ({
  formData,
  people,
  addTransactionReportAccess,
  updateTransactionReportAccess,
  removeTransactionReportAccess,
}) => {
  const selectedTransactionReportAccessUsers = formData?.transactionReportAccessUsers?.map((p) => p.value?.value);
  const availableTransactionReportAccessUsers = people.filter((p) => selectedTransactionReportAccessUsers.indexOf(p.value) <= -1);
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      {formData?.transactionReportAccessUsers?.map((person, idx) => (
        <FormElement key={idx} label={`Operations - Base Financial Statement Access User ${idx + 1}`}>
          <DisplayPerson
            value={person.value}
            options={availableTransactionReportAccessUsers}
            onDelete={() => {
              removeTransactionReportAccess({ index: idx });
              if(idx === 0 && selectedTransactionReportAccessUsers.length === 1) addTransactionReportAccess();
            }}
            onChange={(val) => updateTransactionReportAccess({ index: idx , value: val })}
          />
        </FormElement>
      ))}
      <TextButton onClick={addTransactionReportAccess} size="medium" className="tw-self-start">
        + Add
      </TextButton>
    </div>
  );
};

export default connect(({ superUsersBranding: { formData, people } }) => ({ formData, people }), {
  addTransactionReportAccess,
  removeTransactionReportAccess,
  updateTransactionReportAccess,
})(TransactionReportAccess);
