import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_BRANDING = "superUserBranding";
const wrap = curriedNamespaceAction(REDUCER_BRANDING);

export const setFormData = (keyValue) => wrap({ type: "SET_FORM_VALUE", keyValue });
export const addOperationalPartner = () => wrap({ type: "ADD_PARTNER" });
export const updateOperationalPartner = (data) => wrap({ type: "UPDATE_PARTNER", ...data });
export const removeOperationalPartner = (data) => wrap({ type: "REMOVE_PARTNER", ...data });

export const addCommOnlyOperationalPartner = () => wrap({ type: "ADD_COMM_PARTNER" });
export const updateCommOnlyOperationalPartner = (data) => wrap({ type: "UPDATE_COMM_PARTNER", ...data });
export const removeCommOnlyOperationalPartner = (data) => wrap({ type: "REMOVE_COMM_PARTNER", ...data });

export const addTransactionReportAccess = () => wrap({ type: "ADD_TRANSACTION_REPORT_ACCESS" });
export const updateTransactionReportAccess = (data) => wrap({ type: "UPDATE_TRANSACTION_REPORT_ACCESS", ...data });
export const removeTransactionReportAccess = (data) => wrap({ type: "REMOVE_TRANSACTION_REPORT_ACCESS", ...data });

export const saveForm = (data) => wrap({ type: "SAVE_FORM", ...data });
export const saveFormError = (data) => wrap({ type: "SAVE_FORM_ERROR", ...data });
export const saveFormSuccess = (data) => wrap({ type: "SAVE_FORM_SUCCESS", ...data });
