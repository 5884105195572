/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Link = (props) => (
  <Icon {...props} name="Link">
    <path d="M10.207 5.793a4.748 4.748 0 0 1 .011 6.706l-.011.012-2.1 2.1c-1.852 1.852-4.866 1.852-6.718 0s-1.852-4.866 0-6.717l1.16-1.16a.5.5 0 0 1 .853.331c.02.554.12 1.11.303 1.648a.503.503 0 0 1-.118.519l-.409.409c-.876.876-.903 2.302-.036 3.186a2.253 2.253 0 0 0 3.198.016l2.1-2.1a2.25 2.25 0 0 0-.323-3.45.498.498 0 0 1-.217-.394c-.012-.33.105-.67.366-.931l.658-.658a.5.5 0 0 1 .643-.054c.229.16.444.34.641.537zm4.404-4.404a4.755 4.755 0 0 0-6.717 0l-2.1 2.1-.011.012a4.748 4.748 0 0 0 .652 7.243c.2.14.471.118.643-.054l.658-.658c.261-.261.378-.601.366-.931a.501.501 0 0 0-.217-.394 2.25 2.25 0 0 1-.323-3.45l2.1-2.1a2.253 2.253 0 0 1 3.198.016c.867.884.84 2.31-.036 3.186l-.409.409a.503.503 0 0 0-.118.519c.183.537.283 1.094.303 1.648a.5.5 0 0 0 .853.331l1.159-1.16a4.755 4.755 0 0 0 0-6.717z" />
  </Icon>
);

export default Link;
