import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';

const UserTable = ({ currentSuperUserRole, onEditUser, users }) => {
  const [oktaStatuses, setOktaStatuses] = useState([])

  useEffect(() => {
    if (users.length === 0) return;

    axios.get(`/support/accounts/${users[0].account_id}/users/okta_statuses`)
      .then((response) => {
        const tempOktaStatuses = {};

        users.forEach(user => {
          const oktaStatus = response.data.users.find((os) => os.user_id === user.id)

          tempOktaStatuses[user.id] = oktaStatus;
        })

        setOktaStatuses(tempOktaStatuses);
      })
  }, [])

  const renderBecomeButton = (user) =>  {
    const link = `/super_users/${user.id}/become?become_user_email=${encodeURIComponent(user.email)}`

    return (
      <a className="btn btn-primary" data-method="post" href={link} rel="nofollow">
        Become
      </a>
    )
  }

  const renderEditButton = (user) => {
    if (currentSuperUserRole === "place") { return null }

    return (
      <button
        type="button"
        className="btn btn-success"
        onClick={() => onEditUser(user)}
        key={user.id}
        style={{ cursor: 'pointer' }}
      >
        Edit
      </button>
    )
  }

  const renderOktaStatus = (userId) => {
    if (!oktaStatuses) { return null }

    const oktaStatus = oktaStatuses[userId]

    if (oktaStatus) {
      return <span>
        {oktaStatus.okta_status &&
          <a href={oktaStatus.admin_center_link}>{oktaStatus.okta_status}</a>
        }
        {!oktaStatus.okta_status &&
          "not found"
        }
      </span>
    }

    return <span>(loading)</span>
  }

  return <Table className="table table-striped table-hover">
    <thead>
      <tr>
        <th>Tools</th>
        <th>ID</th>
        <th>UUID</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Active</th>
        <th>CRM Access</th>
        <th>Managed Phone</th>
        <th>Status</th>
        <th>Okta Status</th>
        <th>Type</th>
        <th>External</th>
      </tr>
    </thead>
    <tbody>
      { users.map(user => 
        <tr key={user.uuid}>
          <td>
            {renderBecomeButton(user)}
            {renderEditButton(user)}
          </td>
          <td>{user.id}</td>
          <td>{user.uuid}</td>
          <td>{user.person ? user.person.first_name : "MISSING PERSON"}</td>
          <td>{user.person ? user.person.last_name : "N/A"}</td>
          <td>{user.email}</td>
          <td>{user.active.toString()}</td>
          <td>{user?.crm_access.toString()}</td>        
          <td>{user.managed_phone_number}</td>
          <td>{user.person ? user.person.status : "N/A"}</td>
          <td>{renderOktaStatus(user.id)}</td>
          <td>{user.person ? user.person.type_name : "N/A"}</td>
          <td>{user.external}</td>
        </tr>
        )
      }
    </tbody>
  </Table>
}

const UserShape = PropTypes.shape({
  account_id: PropTypes.number
})

UserTable.propTypes = {
  currentSuperUserRole: PropTypes.string.isRequired,
  onEditUser: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(UserShape).isRequired
}

export default UserTable;
