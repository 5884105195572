import { useState, useEffect, useMemo } from "react";
import { caseTransformingAxios as axios } from "../../shared/v2/caseTransformingAxios";

const GOALS_API_URL = "/api/v4/goals";
const GOAL_TYPES_API_URL = "/api/v4/goal_types";
const weeklyMultiplier = 0.01923;
const monthlyMultiplier = 0.08333;

export const goalValueToYearly = (value, period, goalTypeName) => {
  if (goalTypeName === "per_agent_productivity" || goalTypeName === "operator_percent_production")
    return value;
  if (period === "Weekly") return value / weeklyMultiplier;
  if (period === "Monthly") return value / monthlyMultiplier;
  return value;
};

const getGoals = ({ signal, params }) =>
  axios.get(GOALS_API_URL, {
    params,
    signal,
  });
const createGoal = (goal, userUuid) => axios.post(GOALS_API_URL, { goal }, { params: { userUuid } });
const updateGoal = (goal, userUuid) =>
  axios.put(`${GOALS_API_URL}/${goal.id}`, { goal }, { params: { userUuid } });
const createGoalType = (goalType) => axios.post(GOAL_TYPES_API_URL, goalType);
const updateGoalType = (goalType) => axios.put(`${GOAL_TYPES_API_URL}/${goalType.id}`, goalType);
const deleteGoalType = (goalType) => axios.delete(`${GOAL_TYPES_API_URL}/${goalType.id}`);

const transformGoal = (goalType, goal, period) => {
  let multiplier = 1;
  if (goalType.name === "per_agent_productivity" || goalType.name === "operator_percent_production") {
    multiplier = 1;
  } else if (period === "Weekly") {
    multiplier = weeklyMultiplier;
  } else if (period === "Monthly") {
    multiplier = monthlyMultiplier;
  }

  return {
    ...goal,
    goalValue: Number((goal.goalValue * multiplier).toFixed(goalType.precision)),
  };
};

const useGoals = ({ agentId, year, load = true, period = "Yearly", section }) => {
  const [rawData, setRawData] = useState({ defaults: {}, goals: {}, goalTypes: [] });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const goalTypes = useMemo(
    () =>
      rawData.goalTypes
        .filter((goalType) => (section === "agent_production" ? goalType.name !== "closed_gci" : true))
        .map((goalType) => {
          let precision = 0;
          if (goalType.goalSection === "lead_interaction") {
            precision = 1;
          } else if (
            goalType.name === "per_agent_productivity" ||
            goalType.name === "operator_percent_production" ||
            goalType.format === "currency"
          ) {
            precision = 2;
          }
          return {
            ...goalType,
            precision,
          };
        }),
    [rawData],
  );

  const defaults = useMemo(
    () =>
      goalTypes.reduce((obj, goalType) => {
        let goal = rawData.defaults[goalType.id];
        if (goal) {
          obj[goalType.id] = transformGoal(goalType, rawData.defaults[goalType.id], period);
        }
        return obj;
      }, {}),
    [goalTypes, rawData, period],
  );

  const goals = useMemo(
    () =>
      goalTypes.reduce((obj, goalType) => {
        let goal = rawData.goals[goalType.id];
        if (goal) {
          obj[goalType.id] = transformGoal(goalType, rawData.goals[goalType.id], period);
        }
        return obj;
      }, {}),
    [goalTypes, rawData, period],
  );

  useEffect(() => {
    if (!load) return () => {};
    const abortController = new AbortController();
    setLoading(true);
    getGoals({ params: { userUuid: agentId, year, goalSection: section }, signal: abortController.signal })
      .then((res) => setRawData(res.data))
      .catch((err) => setError(err.data))
      .finally(() => setLoading(false));

    return () => abortController.abort();
  }, [agentId, year, section, load]);

  const actions = {
    createGoalType(goalType) {
      setLoading(true);
      return createGoalType(goalType)
        .then(({ data }) => {
          setRawData((prevRawData) => ({
            ...prevRawData,
            goalTypes: [...prevRawData.goalTypes, data],
          }));
          return data;
        })
        .catch(setError)
        .finally(() => setLoading(false));
    },
    updateGoalType(goalType) {
      setLoading(true);
      return updateGoalType(goalType)
        .then(({ data }) => {
          setRawData((prevRawData) => ({
            ...prevRawData,
            goalTypes: prevRawData.goalTypes.map((goalType) => (goalType.id === data.id ? data : goalType)),
          }));
          return data;
        })
        .catch(setError)
        .finally(() => setLoading(false));
    },
    deleteGoalType(goalType) {
      setLoading(true);
      return deleteGoalType(goalType)
        .then(({ data }) => {
          setRawData((prevRawData) => ({
            ...prevRawData,
            goalTypes: prevRawData.goalTypes.filter((gt) => gt.id !== goalType.id),
          }));
          return data;
        })
        .catch(setError)
        .finally(() => setLoading(false));
    },
    createGoal(goal, userUuid) {
      setLoading(true);
      return createGoal(goal, userUuid)
        .then((res) => {
          const newGoal = res.data;
          setRawData((prevRawData) => {
            const goalsKey = newGoal.default ? "defaults" : "goals";
            return {
              ...prevRawData,
              [goalsKey]: {
                ...prevRawData[goalsKey],
                [newGoal.goalTypeId]: newGoal,
              },
            };
          });
        })
        .catch((err) => setError(err.data))
        .finally(() => setLoading(false));
    },
    updateGoal(goal, userUuid) {
      setLoading(true);
      return updateGoal(goal, userUuid)
        .then((res) => {
          const updatedGoal = res.data;
          setRawData((prevRawData) => {
            const goalsKey = updatedGoal.default ? "defaults" : "goals";
            return {
              ...prevRawData,
              [goalsKey]: {
                ...prevRawData[goalsKey],
                [updatedGoal.goalTypeId]: updatedGoal,
              },
            };
          });
        })
        .catch((err) => setError(err.data))
        .finally(() => setLoading(false));
    },
  };

  return { actions, defaults, goals, goalTypes, error, loading };
};

export default useGoals;
