import React, { Component } from "react";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import Modal from "@shared/v2/Modal";
import SmsConversation from "../../SmsConversation/components/SmsConversation";

const SmsModal = ({
  modalOpen,
  closeModal,
  person,
  defaultHomeAppTabContent,
  phone,
  messagingServiceUrl,
  listingId,
  gabbiAi,
}) => {
  const viewPhone = phone || viewPhoneFromFlat({ value: person.data.attributes.primary_phone_number });

  return (
    <Modal
      show={modalOpen}
      onHide={closeModal}
      contentSize="medium"
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="Send Text" closeButton />
      <Modal.Body className="tw-overflow-auto">
        <SmsConversation
          gabbiAi={gabbiAi}
          messagingServiceUrl={messagingServiceUrl}
          personName={`${person.data.attributes.first_name} ${person.data.attributes.last_name}`}
          personPhoneNumber={person.data.attributes.primary_phone_number}
          personId={person.data.attributes.id}
          personUuid={person.data.attributes.uuid}
          closeModal={closeModal}
          defaultHomeAppTabContent={defaultHomeAppTabContent}
          primaryPhone={viewPhone}
          listingId={listingId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SmsModal;
