import React, { useState } from "react";
import { arrayOf, bool, string } from "prop-types";
import { connect } from "react-redux";

import withTaskSelectionMode from "../HOCs/withTaskSelectionMode";

import { TaskHeaderAvatars, TaskHeaderAutoPlansText } from "./fractals";
import { Info } from "../../../../shared/v2/Icomoon";
import HoverInfoTxt from "../HoverInfoTxt";

const TasksHeader = ({ activeUnassigned, activeTeamMembers, activeAutoPlans, isTasksSelectionMode }) => {
  const [isHoverInfoOn, setIsHoverInfoOn] = useState(false);

  return (
    <div className="tw-flex tw-items-center">
      <span className="tw-mr-8px">
        {!isTasksSelectionMode && activeUnassigned && "Unassigned Tasks"}
        {(!!activeTeamMembers.length || !!activeAutoPlans.length) && !isTasksSelectionMode && "Tasks for:"}
        {!activeUnassigned &&
          !activeTeamMembers.length &&
          !activeAutoPlans.length &&
          !isTasksSelectionMode &&
          "All Tasks"}
        {isTasksSelectionMode && "Task Selection"}
      </span>
      {isTasksSelectionMode && (
        <Info onMouseEnter={() => setIsHoverInfoOn(true)} onMouseLeave={() => setIsHoverInfoOn(false)} />
      )}
      <HoverInfoTxt
        show={isHoverInfoOn}
        containerStyling="tw-w-[306px] tw--ml-12px tw--mt-[58px]"
        textStyling="tw-font-normal tw-text-14px"
        arrowStyling="tw-mt-[26px]"
      >
        Select 1 or more tasks to apply a task action.
      </HoverInfoTxt>
      <div>
        {!!activeTeamMembers.length && !isTasksSelectionMode && (
          <TaskHeaderAvatars activeTeamMembers={activeTeamMembers} />
        )}
        <TaskHeaderAutoPlansText activeAutoPlans={activeAutoPlans} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUnassigned: state.tdpTasksReducer.secondaryFilters.activeUnassigned,
  activeTeamMembers: state.tdpTasksReducer.secondaryFilters.activeTeamMembers,
  activeAutoPlans: state.tdpTasksReducer.secondaryFilters.activeAutoPlans,
});

TasksHeader.propTypes = {
  activeUnassigned: bool,
  activeTeamMembers: arrayOf(string),
  activeAutoPlans: arrayOf(string),
  isTasksSelectionMode: bool,
};

TasksHeader.defaultProps = {
  activeUnassigned: false,
  activeTeamMembers: [],
  activeAutoPlans: [],
  isTasksSelectionMode: bool,
};

export default connect(mapStateToProps)(withTaskSelectionMode(TasksHeader));
