import React from "react";
import PropTypes from "prop-types";
import Rating from "../Rating";
import Dropdown from "../../../../shared/v2/Dropdown";

const RatingDropdownOption = ({ componentProps, data }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.Option {...componentProps} preserveStyle>
    <Rating rating={Number.parseInt(data?.value ?? 0, 10)} size="l" className="tw-top-2px" />
  </Dropdown.Option>
);

RatingDropdownOption.propTypes = {
  // `componentProps` and `data` are an internal implementation detail of the Dropdown component,
  // we don't want to validate their shape here.
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

RatingDropdownOption.defaultProps = {
  componentProps: {},
  data: {},
};

export default RatingDropdownOption;
