import React, { Component } from "react";
import axios from "axios";
import "./profile-icon.scss";
import Tooltip from "./v2/Tooltip";

export default class ProfileIcon extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      link: props.link,
      name: props.name,
      initials: props.initials,
      showName: props.showName,
      id: props.userId,
      placement: props.placement ? props.placement : "right",
    };
  }

  componentWillUnmount() {
    this.state.cancel_taskable && this.state.cancel_taskable();
  }

  componentDidMount() {
    if (this.props.dynamic) {
      this.getUser();
    }
  }

  getUser = () => {
    var task_this = this;
    axios
      .get(`/profile_icon?id=${this.state.id}`, {
        cancelToken: new axios.CancelToken(function executor(c) {
          task_this.setState({ cancel_get_user: c });
        }),
      })
      .then((response) => {
        this.setState({
          link: response.data.link,
          name: response.data.name,
          initials: response.data.initials,
        });
      })
      .catch((response) => {
        console.log("no assigned");
      });
  };

  render() {
    return (
      <div>
        {this.state.link && (
          <Tooltip
            placement={this.state.placement}
            trigger={<img className="profile-icon" src={this.state.link} />}
            content={this.state.name}
          />
        )}
        {this.state.initials && (
          <Tooltip
            placement={this.state.placement}
            trigger={<span className="circled user-image default-avatar">{this.state.initials}</span>}
            content={this.state.name}
          />
        )}
        {this.state.showName && <p>{this.state.name}</p>}
      </div>
    );
  }
}
