import React from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/v2/Modal/Modal";

const onRefRender = (div) => {
  if (!div) return;
  const zapScript = document.createElement("script");
  zapScript.type = "text/javascript";
  zapScript.src =
    "https://zapier.com/zapbook/embed/widget.js?services=brivity&container=true&limit=11&html_id=zapier-widget-content";
  div.appendChild(zapScript);
};

const ZapierWidgetModal = ({ onClose, show }) => (
  <Modal
    className="tw-flex tw-justify-center tw-items-center"
    dialogClassName="tw-max-w-[600px] tw-w-full"
    show={show}
    onHide={onClose}
  >
    <Modal.Header closeButton className="tw-pb-[16px]" />
    <Modal.Body id="zapier-widget-content" className="tw-max-h-[80vh] tw-overflow-y-auto">
      <div ref={onRefRender} />
    </Modal.Body>
  </Modal>
);

ZapierWidgetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ZapierWidgetModal;
