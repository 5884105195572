import React from "react";
import { arrayOf, element } from "prop-types";

const Main = ({ children }) => (
  <div className="tw-bg-white tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-8px tw-grow tw-w-[52%] tw-h-fit tw-p-32px">
    {children}
  </div>
);

Main.propTypes = {
  children: arrayOf(element),
};

Main.defaultProps = {
  children: [],
};

export default Main;
