import React, { useRef, useState } from "react";
import { bool, func, shape, string } from "prop-types";
import moment from "moment";
import { isDate } from "lodash";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  IS_REFERRAL_CALENDAR_OPEN,
  LISTING_UUID,
  TIMEFRAME_EXACT,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { useClickOutside } from "../../../../shared/hookHelpers";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import { referralCalendarEditCancel } from "../../actions/creators";
import { putRefTimeFrameAsThunk } from "../../actions/thunks";

import DateSelector from "../../../../shared/v2/DateSelector/DateSelector";
import TextButton from "../../../../shared/v2/TextButton";
import Button from "../../../../shared/v2/Button";

const UNKNOWN_OPTION = { label: "Unknown", value: "Unknown" };

const useClickOutsideCalendar = (cancelViewingCalendar) => {
  const target = useRef(null);
  const cancelCalendarViewHandlr = () => cancelViewingCalendar();
  useClickOutside(target, cancelCalendarViewHandlr);

  return target;
};

const useDateHandlr = (timeframeExact) => {
  const [date, setDate] = useState(isDate(timeframeExact) ? timeframeExact : moment().format("MM/DD/YYYY"));
  const onDateChangeHandlr = (e) => setDate(e.date);

  return { date, onDateChangeHandlr };
};

// This one is reusable
export const TimeFrameDateSelector = ({
  show,
  refOuter,
  dateSelectorOnChangeHandlr,
  dateSelectorValue,
  clearHandlr,
  submitHandlr,
  containerClassName,
  subContainerClassName,
  clearBtnClassName,
  minDate,
}) =>
  show && (
    <div className={`tw-relative tw-h-36px tw-z-40 ${containerClassName}`} ref={refOuter}>
      <div className={`tw-absolute tw-shadow-dropdown ${subContainerClassName}`}>
        <DateSelector onChange={dateSelectorOnChangeHandlr} value={dateSelectorValue} minDate={minDate} />
        <hr className="tw-mt-0 tw-mb-0 tw-pt-5px tw-pb-5px tw-bg-white tw-border-neutral-gray-10 tw-w-full" />
        <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-bg-white tw-pb-4 tw-px-12px">
          <TextButton
            className={`tw-px-0 ${clearBtnClassName}`}
            onClick={clearHandlr}
            type="button"
            schema="default"
            size="small"
            data-cy="inline-editing-datepicker-clear"
          >
            Clear
          </TextButton>
          <Button
            className="tw-ml-auto"
            onClick={submitHandlr}
            schema="primary"
            size="small"
            data-cy="inline-editing-datepicker-done"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );

// This is not reusable
const TimeFrameDateSelectorWrapper = ({
  show,
  onReferralCalendarEditCancel,
  onPutRefTimeFrameAsThunk,
  timeframeExact,
  uuid,
}) => {
  // Handles state mgmt on calendar
  const { date, onDateChangeHandlr } = useDateHandlr(timeframeExact);

  // Handles clicks outside of calender wrapper
  const cancelViewingCalendar = () => onReferralCalendarEditCancel(UNKNOWN_OPTION);
  const target = useClickOutsideCalendar(cancelViewingCalendar);

  // Handles clear and submit btns
  const clearHandlr = () => onDateChangeHandlr({ date: "" });

  const submitHandlr = () =>
    isDate(date)
      ? cancelViewingCalendar()
      : onPutRefTimeFrameAsThunk({ label: "Custom", value: "Custom", uuid }, date);

  return (
    <TimeFrameDateSelector
      show={show}
      refOuter={target}
      dateSelectorValue={date}
      clearHandlr={clearHandlr}
      submitHandlr={submitHandlr}
      dateSelectorOnChangeHandlr={onDateChangeHandlr}
    />
  );
};

const withReduxState = tdpDetails({
  WrappedComponent: TimeFrameDateSelectorWrapper,
  states: [IS_REFERRAL_CALENDAR_OPEN, TIMEFRAME_EXACT, LISTING_UUID],
  aliases: "show",
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: [referralCalendarEditCancel, putRefTimeFrameAsThunk],
  aliases: ["onReferralCalendarEditCancel", "onPutRefTimeFrameAsThunk"],
});

TimeFrameDateSelectorWrapper.propTypes = {
  show: bool,
  onReferralCalendarEditCancel: func,
  onPutRefTimeFrameAsThunk: func,
  timeframeExact: string,
  uuid: string,
};

TimeFrameDateSelectorWrapper.defaultProps = {
  show: false,
  onReferralCalendarEditCancel: () => {},
  onPutRefTimeFrameAsThunk: () => {},
  timeframeExact: "",
  uuid: null,
};

TimeFrameDateSelector.propTypes = {
  show: bool,
  refOuter: shape({}),
  dateSelectorOnChangeHandlr: func,
  dateSelectorValue: string,
  clearHandlr: func,
  submitHandlr: func,
  containerClassName: string,
  subContainerClassName: string,
  clearBtnClassName: string,
  minDate: string,
};

TimeFrameDateSelector.defaultProps = {
  show: false,
  refOuter: {},
  dateSelectorOnChangeHandlr: () => {},
  dateSelectorValue: "",
  clearHandlr: () => {},
  submitHandlr: () => {},
  containerClassName: "",
  subContainerClassName: "",
  clearBtnClassName: "",
  minDate: null,
};
