import React, { useState, useEffect } from "react";

import EmailModal from "./EmailModal";
import { formatListing, getUserInfoForSend } from "./service";
import Button from "@shared/v2/Button";
import { ShareFromSquareSolidV6 } from "@shared/v2/Icomoon";

const IndexSendListings = ({ listing }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const isDisabled = selectAllChecked || leads.length === 0;

  useEffect(() => {
    const DROPDOWN_CLOSE_EVENT = "select-opt:selected";
    const LEAD_SELECTED_EVENT = "lead-row:selected";

    const updateSelectedLeads = (e) => {
      if (document.getElementById("is-all-selected").value === "true") {
        setSelectAllChecked(true);
        return;
      }

      const selected = getSelectedLeads();
      setSelectAllChecked(false);
      setLeads(selected);
    };

    window.addEventListener(DROPDOWN_CLOSE_EVENT, updateSelectedLeads);
    window.addEventListener(LEAD_SELECTED_EVENT, updateSelectedLeads);

    return () => {
      window.removeEventListener(DROPDOWN_CLOSE_EVENT, updateSelectedLeads);
      window.removeEventListener(LEAD_SELECTED_EVENT, updateSelectedLeads);
    };
  }, []);

  useEffect(() => {
    const get = async () => {
      const info = await getUserInfoForSend();
      setUserInfo(info);
    };

    get();
  }, []);

  return (
    <>
      <Button size="medium" className="tw-ml-[8px]" disabled={isDisabled} onClick={() => setModalOpen(true)}>
        <ShareFromSquareSolidV6 size="m" className="tw-mr-[6px]" />
        Share Listing
      </Button>
      {modalOpen && (
        <EmailModal
          listings={[formatListing(listing)]}
          idxDomain={userInfo.idxDomain}
          sender={userInfo.sender}
          brokerImage={userInfo.brokerImage}
          selectedRecipients={leads}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default IndexSendListings;

const getSelectedLeads = () => {
  const rows = document.querySelectorAll("table.all-people-table input:checked") || [];
  const leads = [];
  rows.forEach((r) => {
    const row = r.closest("tr");
    const lead = {
      personId: row.getAttribute("data-person-id"),
      email: row.getAttribute("data-primary-email"),
      fullName: row.getAttribute("data-full-name"),
    };

    if (lead.personId && lead.email && lead.fullName) {
      leads.push(lead);
    }
  });

  return leads;
};
