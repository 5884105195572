import React, { useState } from "react";

import { yearOptions, dropDownOptions, lotSizeOptions } from "../../../shared/ListingAlertHelpers";
import ToggleableButton from "../ToggleableButton";
import StatusOptions from "./StatusOptions";
import PriceInput from "./PriceInput";
import PropertyTypeOptions from "./PropertyTypeOptions";
import FeatureSection from "./FeatureSection";
import { ExistingFeatureFields } from "../helpers";
import Modal from "@shared/v2/Modal";
import Dropdown from "@shared/v2/Dropdown";
import Button from "@shared/v2/Button";
import ButtonCustom from "../Button";
import TextButton from "@shared/v2/TextButton";
import FilterPill from "@shared/v2/FilterPill";

const Filters = ({ existingFilters, onApply, onClose, propertyTypes, featureFields, availableStatuses }) => {
  const [filters, setFilters] = useState(mergeFilters(existingFilters));

  const onChange = (field, value) => {
    updateFilters(field, value || "");
  };

  const toggleListingTypes = (listingType) => {
    updateFilters(listingType, !filters[listingType]);
  };

  // Assumes an array of primitives
  const onToggle = (field, value) => {
    const selected = filters[field]?.includes(value)
      ? filters[field].filter((v) => v !== value)
      : [...filters[field], value];

    updateFilters(field, selected);
  };

  const toggleFeatureField = (searchCategory, searchValue) => {
    const isSelected = filters[searchCategory]?.fields?.some(s => s.term === searchValue);
    const fields = isSelected
      ? filters[searchCategory].fields.filter(s => s.term !== searchValue)
      : [...(filters[searchCategory]?.fields || []), { term: searchValue, matchAny: false, excludeTerm: false }];

    updateFilters(searchCategory, fields.length ? { fields } : "");
  };

  const updateFilters = (field, value) => {
    setFilters(previousFilters => ({
      ...previousFilters,
      [field]: value
    }));
  };

  const resetFilters = (e) => {
    setFilters(createDefaultFilters());
  };

  const applyFilters = (e) => {
    onApply(filters);
    onClose();
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      contentClassName="tw-flex tw-flex-col tw-gap-32px tw-max-w-[658px] !tw-p-0"
      show
      onHide={onClose}
    >
      <Modal.Header
        className="tw-px-32px tw-pt-32px"
        title="Filter"
        closeButton
      />
      <Modal.Body className="tw-overflow-y-auto tw-px-32px">
        <Column className="tw-gap-32px tw-h-[75vh]">
          <Row className="tw-gap-32px">
            <Column>
              <Row className="tw-mb-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">Price</span>
              </Row>
              <Row className="tw-gap-12px">
                <PriceInput
                  name="priceMin"
                  placeholder="No Min"
                  onChange={(e) => onChange("priceMin", e)}
                  value={filters.priceMin}
                />
                <PriceInput
                  name="priceMax"
                  placeholder="No Max"
                  onChange={(e) => onChange("priceMax", e)}
                  value={filters.priceMax}
                />
              </Row>
            </Column>
            <FilterDropdownSection
              label="Square Feet"
              name="sqFt"
              onUpdate={onChange}
              minValue={filters.sqFtMin}
              maxValue={filters.sqFtMax}
              minOptions={squareFeetOptions("No Min")}
              maxOptions={squareFeetOptions("No Max")}
            />
          </Row>
          <Row>
            <Column>
              <Row className="tw-mb-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">Listing Types</span>
              </Row>
              <Row className="tw-gap-4px">
                <FilterPill onClick={() => toggleListingTypes("showTeamListings")} selected={filters.showTeamListings}>
                  Company Listings
                </FilterPill>
                <FilterPill onClick={() => toggleListingTypes("showOnlyNew")} selected={filters.showOnlyNew}>
                  New Listings
                </FilterPill>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column>
              <Row className="tw-mb-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">Listing Status</span>
              </Row>
              <StatusOptions
                statuses={filters.statuses}
                availableStatuses={availableStatuses}
                onToggle={(s) => onToggle("statuses", s)}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Row className="tw-mb-8px">
                <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">Property Types</span>
              </Row>
              <PropertyTypeOptions
                propertyTypes={filters.propertyTypes}
                availablePropertyTypes={propertyTypes}
                onToggle={(s) => onToggle("propertyTypes", s)}
              />
            </Column>
          </Row>
          <Row className="tw-gap-32px">
            <FilterDropdownSection
              label="Bedrooms"
              name="beds"
              onUpdate={onChange}
              minValue={filters.bedsMin}
              maxValue={filters.bedsMax}
              minOptions={dropDownOptions("No Min")}
              maxOptions={dropDownOptions("No Max")}
            />
            <FilterDropdownSection
              label="Bathrooms"
              name="baths"
              onUpdate={onChange}
              minValue={filters.bathsMin}
              maxValue={filters.bathsMax}
              minOptions={dropDownOptions("No Min")}
              maxOptions={dropDownOptions("No Max")}
            />
          </Row>
          <Row className="tw-gap-32px">
            <FilterDropdownSection
              label="Lot Size"
              name="lotSize"
              onUpdate={onChange}
              minValue={filters.lotSizeMin}
              maxValue={filters.lotSizeMax}
              minOptions={lotSizeOptions("No Min")}
              maxOptions={lotSizeOptions("No Max")}
            />
            <FilterDropdownSection
              label="Year Built"
              name="year"
              onUpdate={onChange}
              minValue={filters.yearMin}
              maxValue={filters.yearMax}
              minOptions={yearOptions("No Min")}
              maxOptions={yearOptions("No Max")}
            />
          </Row>
          <Row className="tw-gap-32px">
            <FilterDropdownSection
              label="Garage"
              name="garage"
              onUpdate={onChange}
              minValue={filters.garageMin}
              maxValue={filters.garageMax}
              minOptions={dropDownOptions("No Min")}
              maxOptions={dropDownOptions("No Max")}
            />
            <FilterDropdownSection
              label="Stories"
              name="stories"
              onUpdate={onChange}
              minValue={filters.storiesMin}
              minOptions={dropDownOptions("No Min")}
              includeMax={false}
            />
          </Row>
          {!!featureFields && (
            <Column className="tw-gap-y-32px">
              {Object.entries(featureFields).map(([header, options]) => (
                <FeatureSection
                  header={header}
                  options={options}
                  filters={filters}
                  toggleFeatureField={toggleFeatureField}
                />
              ))}
            </Column>
          )}
        </Column>
      </Modal.Body>
      <Modal.Footer className="tw-px-32px tw-pb-32px">
        <Row className="tw-items-center">
          <Button
            onClick={onClose}
            size="medium"
            schema="tertiary"
          >
            Cancel
          </Button>
          <TextButton
            className="tw-ml-auto tw-mr-20px"
            size="medium"
            text="Clear All"
            onClick={resetFilters}
          >
            Clear All
          </TextButton>
          <Button
            onClick={applyFilters}
            size="medium"
            schema="primary"
          >
            Apply
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default Filters;

const mergeFilters = (existingFilters = {}) => ({ ...createDefaultFilters(), ...existingFilters });

const createDefaultFilters = () => ({
  priceMin: "",
  priceMax: "",
  sqFtMin: "",
  sqFtMax: "",
  showOnlyNew: false,
  showTeamListings: false,
  statuses: [],
  propertyTypes: [],
  bedsMin: "",
  bedsMax: "",
  bathsMin: "",
  bathsMax: "",
  lotSizeMin: "",
  lotSizeMax: "",
  yearMin: "",
  yearMax: "",
  garageMin: "",
  garageMax: "",
  storiesMin: "",
  storiesMax: "",
  ...(ExistingFeatureFields.reduce((acc, curr) => ({...acc, [curr]: ""}), {}))
});

const FilterDropdownSection = ({
  label,
  name,
  onUpdate,
  minValue,
  maxValue,
  minOptions,
  maxOptions,
  className,
  includeMax = true,
}) => (
  <Column className={className}>
    <Row className="tw-mb-8px">
      <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">{label}</span>
    </Row>
    <Row>
      <div className="tw-mr-12px tw-w-[134px]">
        <Dropdown
          isSearchable={false}
          placeholder="Any"
          isClearable={false}
          options={minOptions}
          onChange={(opt) => onUpdate(`${name}Min`, opt.value)}
          value={minOptions.find(m => m.value === minValue)}
          variant="outlined"
        />
      </div>
      {includeMax && (
        <div className="tw-w-[134px]">
          <Dropdown
            isSearchable={false}
            placeholder="Any"
            isClearable={false}
            options={maxOptions}
            onChange={(opt) => onUpdate(`${name}Max`, opt.value)}
            value={maxOptions.find(m => m.value === maxValue)}
            variant="outlined"
          />
        </div>
      )}
    </Row>
  </Column>
);

const Row = ({ children, className = "", ...props }) => (
  <div className={`tw-flex tw-flex-row ${className}`} {...props}>
    {children}
  </div>
);

const Column = ({ children, className = "", ...props }) => (
  <div className={`tw-flex tw-flex-col ${className}`} {...props}>
    {children}
  </div>
);

const squareFeetOptions = (firstOption) => {
  const formatter = Intl.NumberFormat();

  const options = [
    "600",
    "800",
    "1000",
    "1200",
    "1400",
    "1600",
    "1800",
    "2000",
    "2250",
    "2500",
    "2750",
    "3000",
    "3500",
    "4000",
    "4500",
    "5000",
    "6000",
    "7000",
    "8000",
    "9000",
    "10000",
  ].map((val) => ({ label: formatter.format(val), value: val }));

  return [{ label: firstOption, value: "" }, ...options];
};
