/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FacebookCircleSolidV6 = (props) => (
  <Icon {...props} name="Facebook Circle Solid V6">
    <path d="M15.75 8C15.75 3.719 12.281.25 8 .25S.25 3.719.25 8a7.753 7.753 0 0 0 6.539 7.656V10.24H4.82V8h1.969V6.293c0-1.942 1.156-3.015 2.927-3.015.848 0 1.735.151 1.735.151v1.906h-.978c-.962 0-1.263.598-1.263 1.21V8h2.149l-.344 2.24H9.209v5.416A7.753 7.753 0 0 0 15.748 8z" />
  </Icon>
);

export default FacebookCircleSolidV6;
