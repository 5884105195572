import React from "react";

import { func, string } from "prop-types";

import { usePlaceSelector } from "../../reducers/layoutReducer";

const AvailableIcon = ({ onSelect, classNameFont, fontPrefix }) => {
  const isPlaceTheme = usePlaceSelector()
  
  return (
    <i
      onClick={onSelect} 
      className={`tw-text-gray-base tw-text-32px tw-w-[32px] tw-h-[32px] tw-text-center tw-cursor-pointer ${fontPrefix}-${classNameFont} ${ isPlaceTheme ? "hover:tw-text-[#3270FA]" : "hover:tw-text-teal"}`}/>
  )
}

export default AvailableIcon;

AvailableIcon.propTypes = {
  onSelect: func, 
  classNameFont: string,
  fontPrefix: string
}

AvailableIcon.defaultProps = {
  onSelect: () => {}, 
  classNameFont: '',
  fontPrefix: 'fa6 fa6'
}
