import axios from "axios";

export const getAllNotesFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/notes/?uuid=${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const postNoteToApi = (note, uuid) =>
  axios.post(`/api/v4/transactions/notes/?uuid=${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    note,
  });

export const patchNoteFromApi = (note, uuid) =>
  axios.patch(`/api/v4/transactions/notes/${note.id}/?uuid=${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    note,
  });

export const deleteNoteFromApi = (note, uuid) =>
  axios.delete(`/api/v4/transactions/notes/${note.id}?uuid=${uuid}`, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });
