/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ListCheckSolidV6 = (props) => (
  <Icon {...props} name="List Check Solid V6">
    <path d="M4.753 1.193a.75.75 0 0 1 .053 1.059L2.557 4.753a.643.643 0 0 1-.538.219.587.587 0 0 1-.55-.219l-1.25-1.25c-.293-.266-.293-.741 0-1.033s.768-.293 1.061 0l.691.69 1.722-1.911a.75.75 0 0 1 1.059-.056zm0 5.001a.75.75 0 0 1 .053 1.059l-2.249 2.5a.643.643 0 0 1-.538.219.587.587 0 0 1-.55-.219l-1.25-1.25c-.293-.266-.293-.741 0-1.034a.754.754 0 0 1 1.061 0l.691.691 1.722-1.912a.75.75 0 0 1 1.059-.053zM7 3a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm-2 5a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1zm-5 0a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 0 13z" />
  </Icon>
);

export default ListCheckSolidV6;
