import React from "react";
import { Col, Row, Alert, Button } from "../../../shared/v1";
import TransactionGCI from "./TransactionGCI";
import TransactionGCIForm from "./TransactionGCIForm";
import { Agents } from "./Agents";
import { AgentsForm } from "./AgentsForm";
import Error from "../../../shared/Error";

import "../shared/Financials.sass";
import Company from "./Company";
import CompanyForm from "./Company/CompanyForm";

class AccountFinancial extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  saveData = () => {
    this.props.submitTransactionData(this.props.accountData);
  };

  showEdit = () => {
    this.props.showEdit();
  };

  hideAlertMsg = () => {
    this.props.hideAlert();
  };

  componentDidUpdate() {
    const {
      setAgentExpenseList,
      setTransactionExpenseList,
      setCompanyExpenseList,
      setCompanyRevenueList,
      accountData: {
        agent_expense_types,
        transaction_expense_types,
        company_expense_types,
        company_revenue_types,
      },
    } = this.props;

    setTransactionExpenseList(transaction_expense_types);
    setAgentExpenseList(agent_expense_types);
    setCompanyExpenseList(company_expense_types);
    setCompanyRevenueList(company_revenue_types);
  }

  render() {
    const {
      isModalOpen,
      accountData,
      openNewExpenseModal,
      closeNewExpenseModal,
      setAccountData,
      unsetAgentExpense,
      setAgentExpense,
      setCompanyExpense,
      expenseType,
      setActiveAgentExpense,
      activeAgentExpense,
      isShowAlert,
      isShowDangerAlert,
      errors,
      isShowEdit,
    } = this.props;

    if (!!accountData.account_financial_template?.custom_account_expenses_attributes) {
      accountData.account_financial_template.custom_account_expenses_attributes.sort(function (a, b) {
        return a.position - b.position;
      });
    }
    if (!!accountData.company_expense_template?.custom_agent_expenses_attributes) {
      accountData.company_expense_template.custom_agent_expenses_attributes.sort(function (a, b) {
        return a.position - b.position;
      });
    }
    if (!!accountData.agent_expense_templates?.agent_expenses) {
      accountData.agent_expense_templates.agent_expenses.forEach((element) => {
        element.custom_agent_expenses_attributes.sort(function (a, b) {
          return a.position - b.position;
        });
      });
    }

    return (
      <div data-cy="financials-setting-wrapper" id="content" className="tw-px-[16px] setting-financial-view">
        {isShowAlert && (
          <Alert variant="success" role="alert">
            Successfully updated.
          </Alert>
        )}
        {isShowDangerAlert && <Error errorMessage={errors} />}
        <div className="financial-header">
          <h2>Transactions GCI</h2>
          <Row>
            <Col size={8}>
              <p>
                Set the default values to populate upon creating a new transaction. These figures can be
                adjusted in the transaction details if they become unique to the deal. The order of
                calculation for each expense is top to bottom and can be changed by dragging and dropping.
              </p>
            </Col>
            <Col size={4}>
              <div className="tw-text-right">
                <span>
                  {isShowEdit && (
                    <Button
                      data-cy="financials-settings-save-btn-0"
                      variant="info"
                      invert
                      className="focus:tw-border-[#8c8c8c] hover:tw-border-[#adadad] active:tw-border-[#adadad] active:hover:tw-border-[#8c8c8c] active:hover:tw-text-[#333]"
                      onClick={() => {
                        this.saveData();
                      }}
                    >
                      SAVE
                    </Button>
                  )}

                  {!isShowEdit && (
                    <Button
                      data-cy="financials-setting-edit-0"
                      variant="default"
                      onClick={() => {
                        this.showEdit();
                      }}
                    >
                      EDIT
                    </Button>
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </div>

        {!isShowEdit && <TransactionGCI accountData={accountData} />}

        {isShowEdit && (
          <TransactionGCIForm
            isModalOpen={isModalOpen}
            openNewExpenseModal={openNewExpenseModal}
            closeNewExpenseModal={closeNewExpenseModal}
            setAccountData={setAccountData}
            accountData={accountData}
            unsetAgentExpense={unsetAgentExpense}
            expenseType={expenseType}
            activeAgentExpense={activeAgentExpense}
          />
        )}
        <div className="financial-header">
          <h2>Company Financials</h2>
        </div>
        {!isShowEdit && <Company accountData={accountData} />}
        {isShowEdit && (
          <CompanyForm
            isModalOpen={isModalOpen}
            openNewExpenseModal={openNewExpenseModal}
            closeNewExpenseModal={closeNewExpenseModal}
            setAccountData={setAccountData}
            accountData={accountData}
            setCompanyExpense={setCompanyExpense}
            expenseType={expenseType}
            activeAgentExpense={activeAgentExpense}
          />
        )}
        <div className="financial-header">
          <h2>Agent's Expenses</h2>
        </div>

        {!isShowEdit && <Agents />}

        {isShowEdit && (
          <AgentsForm
            isModalOpen={isModalOpen}
            openNewExpenseModal={openNewExpenseModal}
            closeNewExpenseModal={closeNewExpenseModal}
            setAccountData={setAccountData}
            accountData={accountData}
            setAgentExpense={setAgentExpense}
            setActiveAgentExpense={setActiveAgentExpense}
          />
        )}
      </div>
    );
  }
}

export default AccountFinancial;
