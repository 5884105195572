/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from "react";
import { EditIcon } from "../icons";

const ImageCell = ({ rounded, value, isEditable, alt, ratio, containerClass }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const iconType = <EditIcon isEditable={isEditable} />;

  const image = (
    <img
      className={`tw-max-w-full ${containerClass} ${rounded ? "tw-rounded-full" : ""}`}
      alt={alt}
      src={value}
    />
  );

  const ratioClasses = ratio === "wide" ? "tw-aspect-w-16 tw-aspect-h-9" : "";
  const blankNonEditableDisplay = <span>—</span>;
  const blankEditableDisplay = <span className="tw-text-teal">Add</span>;
  const nonEditableDisplay = image;
  const editableDisplay = (
    <>
      {image}
      {iconType}
    </>
  );
  const isEmpty = !currentValue || currentValue.length === 0;

  const maybeToggleToInput = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const triggerableContainer = (children) => (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      className={`${ratioClasses} ${containerClass} tw-flex tw-flex-row tw-align-center tw-justify-start tw-flex-nowrap tw-w-48px tw-rounded-4px tw-overflow-hidden`}
      onClick={maybeToggleToInput}
      role="button"
    >
      {children}
    </div>
  );

  const input = (
    <input
      value={currentValue}
      ref={(newInput) => newInput && newInput.focus()}
      onChange={(e) => setCurrentValue(e.target.value)}
      onBlur={() => setIsEditing(false)}
    />
  );

  let content = null;

  if (!isEditable && !isEmpty) {
    content = nonEditableDisplay;
  } else if (!isEditable && isEmpty) {
    content = blankNonEditableDisplay;
  } else if (isEditable && isEditing) {
    content = input;
  } else if (isEditable && !isEditing && isEmpty) {
    content = blankEditableDisplay;
  } else if (isEditable && !isEditing && !isEmpty) {
    content = editableDisplay;
  }

  return triggerableContainer(content);
};

export default ImageCell;
