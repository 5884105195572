export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeFromColumn = (column, removeIndex) => {
  const newColumn = {
    ...column,
    items: [...column.items],
  };
  newColumn.items.splice(removeIndex, 1);
  return newColumn;
};

export const addToColumn = (column, item, index) => {
  const newColumn = {
    ...column,
    items: [...column.items],
  };
  // in line modification of items
  newColumn.items.splice(index, 0, item);
  return newColumn;
};

export const transferBetweenColumns = ({ columns, sourceId, targetId, sourceIndex, targetIndex }) => {
  const sourceColumn = columns[sourceId];
  const destinationColumn = columns[targetId];
  const item = sourceColumn.items[sourceIndex];
  item.status = targetId;

  return {
    ...columns,
    [sourceId]: removeFromColumn(sourceColumn, sourceIndex),
    [targetId]: addToColumn(destinationColumn, item, targetIndex),
  };
};
