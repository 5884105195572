import PropTypes from "prop-types";

export const ADS_TYPES = ["ads-retarget", "ads-remarket"];

export const QUESTION_TYPES = [
  "contact_form_ask_question",
  "contact_form_ask_a_question",
  "contact_form_questions_about",
];

export const SHOWING_TYPES = ["scheduled_showing", "showing_scheduled", "ad_scheduled_showing"];

export const HOME_ESTIMATE_TYPES = [
  "home_value",
  "contact_form_get_accurate_estimate",
  "get_accurate_estimate",
];

export const LABEL_CATEGORIES = {
  home_value: "submitted a home valuation",
  contact_form_get_accurate_estimate: "submitted a home valuation",
  get_accurate_estimate: "submitted a home valuation",
  ad_scheduled_showing: "requested a showing",
  scheduled_showing: "requested a showing",
  showing_scheduled: "requested a showing",
  contact_form_ask_question: "asked a question",
  contact_form_ask_a_question: "asked a question",
  contact_form_questions_about: "asked a question",
  start_offer: "submitted a start offer form",
  "ads-retarget": "returned to website via Brivity Connect retargeting campaign",
  "ads-remarket": "returned to website via Brivity Connect remarketing campaign",
};

export const getIconClass = (kind) => {
  if (ADS_TYPES.includes(kind)) return "repeat";
  if (QUESTION_TYPES.includes(kind)) return "question";
  if (SHOWING_TYPES.includes(kind)) return "home";
  if (HOME_ESTIMATE_TYPES.includes(kind)) return "dollar";
  return "briefcase";
};

export const getEventDetailType = (kind) => {
  if (ADS_TYPES.includes(kind)) return kind;
  if (QUESTION_TYPES.includes(kind)) return "asked_a_question";
  if (SHOWING_TYPES.includes(kind)) return "requested_showing";
  if (HOME_ESTIMATE_TYPES.includes(kind)) return "home_value";
  return "start_offer";
};

export const eventTypeProps = PropTypes.shape({
  kind: PropTypes.string,
  email_present: PropTypes.bool,
  managed_number: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  person: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  person_phone_number: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  timeline_fields: PropTypes.any.isRequired,
});
