import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import DetailDateDropdown from "../DetailDateDropdown";
import { updateDate } from "../../../SideRight/Dates/actions/thunks";

const EarnestMoneyDateDropdown = ({ dates, date, updateDate, uuid, label, containerClass }) => {
  const saveUpdateDate = (id, data) => updateDate(uuid, id, data);
  const [options, setOptions] = useState(dates?.available_dates || []);
  const [idFromLists, setFromLists] = useState(null);

  const getIdByName = (name) => {
    const item = options.find((item) => item.name === name);
    return item ? item.id : null;
  };

  useEffect(() => {
    setOptions(dates?.available_dates || []);
    if (!date?.id) {
      setFromLists(getIdByName(date?.name || label));
    }
  }, [dates.available_dates]);

  return (
    <DetailDateDropdown
      date={date}
      isNew={date?.id ? false : true}
      label={date?.name || label}
      idFromLists={idFromLists}
      onBlur={saveUpdateDate}
      dataCy="earnestmoney"
      containerClass={containerClass}
    />
  );
};

EarnestMoneyDateDropdown.propTypes = {
  updateDate: func,
  uuid: string,
  label: string,
  date: shape({
    id: string,
    date: string,
    name: string,
    include_time: bool,
    display_time: string,
  }),
  containerClass: string,
};

EarnestMoneyDateDropdown.defaultProps = {
  updateDate: () => {},
  uuid: null,
  label: "",
  date: null,
  containerClass: "",
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  dates: state.tdpDatesReducer.dates,
});

const mapDispatchToProps = (dispatch) => ({
  updateDate: (uuid, optionId, data) => dispatch(updateDate(uuid, optionId, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EarnestMoneyDateDropdown);
