import React from 'react';
import CCFor from "../../AutoPlans/components/CCFor";
import './cc_field.scss'

export default class CCEmails extends React.Component {
  state = {
    emails: this.props.ccEmails || []
  };

  handleEmailSelected = ({value: email}) => {
    if (this.state.emails.indexOf(email) > -1) {
      return
    } else {
      this.setState((prevState) => ({emails: prevState.emails.concat(email)}));
    }
  };

  handleRemoveEmail = (emailToRemove) => {
    this.setState((prevState) => ({
      emails: prevState.emails.filter((email) => email !== emailToRemove)
    }));
  };

  render() {
    return (
      <div>
        <label>CC</label>
        <CCFor
          type={'email'}
          placeholder={'Enter Email Address'}
          onOptionSelect={(option) => this.handleEmailSelected(option)}
        />
        <div className='alert-cc-selections'>
          {this.state.emails.map((email) => {
            return (
              <div className='alert-cc-email' key={email}>
                <input
                  type={'hidden'}
                  name={'listing_alert[cc_emails][]'}
                  value={email}
                />
                <input
                  type={'text'}
                  className={'form-control input-sm'}
                  value={email}
                  placeholder={email}
                  disabled={true}
                />
                <a
                  className={'remove-cc'}
                  onClick={() => this.handleRemoveEmail(email)}
                >
                  <i className="fa fa-remove"/>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}
