import React from "react";
import { bool } from "prop-types";

import { VisibilityVisible, VisibilityHidden } from "../../../../shared/v2/Icomoon";

const VisibilityView = ({ visibility }) =>
  visibility ? (
    <VisibilityVisible size="l" className="tw-text-neutral-gray-50" />
  ) : (
    <VisibilityHidden size="l" className="tw-text-neutral-gray-30" />
  );

VisibilityView.propTypes = {
  visibility: bool,
};

VisibilityView.defaultProps = {
  visibility: false,
};

export default VisibilityView;
