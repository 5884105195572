import { schemaGenerator } from "../utils";

const baseClasses = [
  "tw-border-neutral-gray-15",
  "tw-border-solid",
  "tw-border-[1px]",
  "tw-rounded-full",
  "tw-select-none",
  "tw-mb-0",
  "tw-whitespace-nowrap",
];

const sizeClasses = {
  small: ["tw-text-12d", "tw-px-12px", "tw-py-3px"],
  medium: ["tw-text-14d", "tw-px-16px", "tw-py-8px"],
};

const variantClasses = {
  gray: {
    default: [
      ...baseClasses,
      "tw-text-neutral-gray-75",
      "peer-checked:tw-text-white",
      "peer-checked:tw-bg-neutral-gray-75",
      "peer-checked:tw-border-neutral-gray-75",
      "peer-enabled:tw-cursor-pointer",
    ],
    hover: [
      "peer-enabled:hover:tw-text-neutral-gray-75",
      "peer-enabled:hover:tw-bg-neutral-gray-10",
      "peer-enabled:hover:tw-border-neutral-gray-10",

      "peer-enabled:peer-checked:hover:tw-text-white",
      "peer-enabled:peer-checked:hover:tw-bg-neutral-gray-50",
      "peer-enabled:peer-checked:hover:tw-border-neutral-gray-50",
    ],
    focused: ["peer-focus-visible:tw-outline-theme"],
    disabled: ["peer-disabled:tw-text-neutral-gray-30"],
  },
  primary: {
    default: [
      ...baseClasses,
      "tw-text-neutral-gray-75",
      "peer-checked:tw-text-white",
      "peer-checked:tw-bg-[var(--filter-pill-selected)]",
      "peer-checked:tw-border-[var(--filter-pill-selected)]",
      "peer-enabled:tw-cursor-pointer",
    ],
    hover: [
      "peer-enabled:hover:tw-text-[var(--filter-pill-hover)]",
      "peer-enabled:hover:tw-border-[var(--filter-pill-hover)]",

      "peer-enabled:peer-checked:hover:tw-text-white",
      "peer-enabled:peer-checked:hover:tw-bg-[var(--filter-pill-selected-hover)]",
      "peer-enabled:peer-checked:hover:tw-border-[var(--filter-pill-selected-hover)]",
    ],
    focused: ["peer-focus-visible:tw-outline-theme"],
    disabled: [
      "peer-disabled:tw-text-neutral-gray-30",
      "peer-disabled:tw-bg-transparent",
      "peer-disabled:tw-border-neutral-gray-30",
    ],
  },
};

const sizeDictionary = schemaGenerator(sizeClasses);
const variantDictionary = schemaGenerator(variantClasses);

export { variantDictionary, sizeDictionary };
