import React from 'react';
import { Modal } from 'react-bootstrap';
import css from './BulkMarketReports.module.css'
import cmaAppStoreScreenshot from '../../shared/images/cmaAppStoreScreenshot.png'

class BulkReportsNoPlatformModal extends React.PureComponent {
  handleClick = (e) => {
    e.preventDefault()
    this.props.closeNoPlatformModal()
  }

  cancelModal = (e) => {
    e.preventDefault()
    this.props.closeNoPlatformModal(true)
  }

  render() {
    return(
      <Modal
       className={css.modal}
       backdrop='static'
       onHide={this.cancelModal}
       show>
       <Modal.Header closeButton>
       </Modal.Header>
       <Modal.Body className={css.modalBody}>
        <img className={css.modalImage} src={cmaAppStoreScreenshot} alt="CMA Screenshot" />
        <div className={css.modalText}>
          <h4>Upgrade to the full Brivity Platform to get Market Reports</h4>
          <hr className="brivity-hr "></hr>
          <div className={css.modalInnerText}>
            <p>Market Reports are one of the best ways to nurture seller leads and your sphere by
            showing them what's going on in the real estate market in their neighborhood.</p>
            <p>Market Reports are included with Brivity CMA, which is part of the full Brivity Platform.</p>
            <p><strong>Contact us at (866) 295-3322 or sales@brivity.com to upgrade to the Brivity Platform today!</strong></p>
            <div className={css.getDemo}><span
              className="modal-submit"
              onClick={this.handleClick}>&nbsp;Get a Demo&nbsp;</span>
            </div>
          </div>
        </div>
       </Modal.Body>
       <Modal.Footer>
       </Modal.Footer>
     </Modal>
    )
  }
}

export default BulkReportsNoPlatformModal;
