import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { produce } from "immer";
import person_detail_css from "./person-detail-styles.module.css";
import Input from "../../shared/Input";
import form_css from "./form.module.css";
import SocialIcon from "./SocialIcon";
import css from "./person-detail-styles.module.css";
import buttons from "./button.module.css";
import { updatePersonPromise } from "../actions/updatePerson";
import { useDispatch, useSelector } from "react-redux";
import { closeEditSocialMediaModal, updatePersonData } from "../actions/personDetailActionCreators";

const EditSocialMediaForm = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const [editablePerson, setEditablePerson] = useState(person);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setEditablePerson(person);
  }, [person]);

  const handleEditableValueChange = (value, key) => {
    setEditablePerson(
      produce(editablePerson, (draft) => {
        draft.data.attributes[key].value = value;
      }),
    );
  };

  const saveChanges = () => {
    updatePersonPromise(editablePerson)
      .then((r) => {
        dispatch(updatePersonData(r.data));
        dispatch(closeEditSocialMediaModal());
      })
      .catch((err) => {
        setErrors(err.response.data.errors || ["Something went wrong..."]);
      });
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          {errors && (
            <Alert bsStyle="danger">
              {errors.map((error) => (
                <span>
                  <strong>Error: </strong> {error}
                  <br />
                </span>
              ))}
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.facebook.value} icon="fa fa-facebook" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>FACEBOOK</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.facebook.value}
                placeholder={"Facebook profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "facebook")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.twitter.value} icon="fab fa6-x-twitter" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>Twitter</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.twitter.value}
                placeholder={"X profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "twitter")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.linkedin.value} icon="fa fa-linkedin" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>LINKEDIN</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.linkedin.value}
                placeholder={"LinkedIn profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "linkedin")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.instagram.value} icon="fa fa-instagram" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>INSTAGRAM</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.instagram.value}
                placeholder={"Instagram profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "instagram")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.youtube.value} icon="fab fa6-youtube" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>YOUTUBE</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.youtube.value}
                placeholder={"Youtube profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "youtube")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.tiktok.value} icon="fab fa6-tiktok" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>TIKTOK</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.tiktok.value}
                placeholder={"Tiktok profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "tiktok")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-start">
            <div
              className={css.brivityPersonContactSocial}
              style={{ paddingTop: "15px", paddingRight: "15px" }}
            >
              <SocialIcon link={editablePerson.data.attributes.pinterest.value} icon="fab fa6-pinterest" />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label className={person_detail_css.personDetailLabel}>PINTEREST</label>
              <Input
                type="text"
                className={form_css.formControl}
                value={editablePerson.data.attributes.pinterest.value}
                placeholder={"Pinterest profile page URL"}
                onChange={(e) => handleEditableValueChange(e.target.value, "pinterest")}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="d-flex flex-justify-space-between p-t-20">
            <a
              className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
              onClick={() => dispatch(closeEditSocialMediaModal())}
            >
              Cancel
            </a>
            <a
              className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
              onClick={saveChanges}
            >
              Save Changes
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditSocialMediaForm;
