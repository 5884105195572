import React from "react";

import ListingStatus from "./ListingStatus";
import ListingImages from "./ListingImages";
import Checkbox from "../../Transactions/components/shared/Checkbox";
import { formatNumber, formatValue } from "./helpers";

const ListingCard = ({
  listing,
  isChecked,
  onToggleShare,
  maxListingsSelected,
  size,
  includeNewBanner = false,
  buyerMatches = {}
}) => {
  const cardValues = SizeValues[size || "medium"];

  const addToShare = (e) => {
    e.stopPropagation();
    onToggleShare(listing);
  };

  return (
    <div className="tw-flex-col tw-bg-white tw-overflow-hidden tw-rounded-4px">
      <div className="tw-flex tw-relative">
        <ListingImages images={listing.photos} size={size} />
        <div className="tw-absolute tw-right-5 tw-top-5" onClick={(e) => e.stopPropagation()}>
          <Checkbox checked={isChecked} onChange={addToShare} disabled={maxListingsSelected && !isChecked} />
        </div>
        {includeNewBanner && listing.daysOnSite <= 7 && (
          <div className="tw-absolute tw-flex tw-justify-center tw-items-center tw-px-8px tw-py-2px tw-top-5 tw-left-5 tw-bg-teal tw-rounded-2px">
            <span className="tw-text-white tw-font-bold tw-text-14px">NEW</span>
          </div>
        )}
        {buyerMatches.matches && buyerMatches.matches.length > 0 && (
          <div className="tw-absolute tw-bg-white tw-right-0 tw-bottom-0 tw-border-teal tw-border-solid tw-border-[1.5px] tw-border-r-0 tw-border-b-0 tw-rounded-tl-lg">
            <a onClick={e => e.stopPropagation()} className="hover:tw-no-underline tw-px-8px tw-py-4px" href={buyerMatches.linkToIndex} target="_blank"><span className="tw-text-teal tw-font-semibold tw-text-12px">{buyerMatches.countText} {buyerMatches.count === 1 ? "match" : "matches"}</span></a>
          </div>
        )}
      </div>
      <div className={`tw-flex tw-flex-row ${cardValues.infoSectionPadding} tw-justify-between`}>
        <div className={`tw-flex tw-flex-col`}>
          <div className={`tw-flex tw-items-center ${cardValues.infoBetweenMargin}`}>
            <span className={`${cardValues.mainFont} tw-text-gray-99 tw-mr-4px`}>
              {formatValue(listing.price, true)}
            </span>
            <span className="tw-text-12px tw-font-semibold tw-mx-4px">
              <ListingStatus status={listing.standardStatus} includeIcon={false} />
            </span>
            <span className="tw-text-12px tw-text-gray-50 tw-mx-4px">
              {listing.daysOnSite} {listing.daysOnSite === 1 ? "day" : "days"} ago
            </span>
          </div>
          <span
            className={`tw-flex ${cardValues.infoBetweenMargin} ${cardValues.secondaryFont} tw-text-gray-75`}
          >
            {formatValue(listing.streetAddress)}
          </span>
          <div className={`tw-flex tw-items-center`}>
            <span className={`${cardValues.tertiaryFontSize} tw-text-gray-50`}>{`${formatValue(
              listing.bedrooms,
            )} Beds`}</span>
            <span className="tw-w-1px tw-h-10px tw-bg-gray-15 tw-mx-8px"></span>
            <span className={`${cardValues.tertiaryFontSize} tw-text-gray-50`}>{`${formatValue(
              listing.bathsTotalDecimal,
            )} Baths`}</span>
            <span className="tw-w-1px tw-h-10px tw-bg-gray-15 tw-mx-8px"></span>
            <span className={`${cardValues.tertiaryFontSize} tw-text-gray-50`}>{`${formatNumber(
              listing.squareFeet,
            )} SQFT`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;

const SizeValues = {
  small: {
    infoSectionPadding: "tw-pt-6px tw-pb-8px tw-pr-12px tw-pl-12px",
    infoBetweenMargin: "tw-mb-3px",
    mainFont: "tw-text-14px tw-font-semibold",
    secondaryFont: "tw-text-12px",
    tertiaryFontSize: "tw-text-12px",
    shareBubbleWidth: "tw-w-24px",
    shareBubbleHeight: "tw-h-24px",
    shareIconSize: 9,
  },
  medium: {
    infoSectionPadding: "tw-pt-12px tw-pb-14px tw-pr-20px tw-pl-16px",
    infoBetweenMargin: "tw-mb-6px",
    mainFont: "tw-text-18px tw-font-bold",
    secondaryFont: "tw-text-14px tw-font-semibold",
    tertiaryFontSize: "tw-text-12px",
    shareBubbleWidth: "tw-w-32px",
    shareBubbleHeight: "tw-h-32px",
    shareIconSize: 14,
  },
};
