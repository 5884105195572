/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleSolidV6 = (props) => (
  <Icon {...props} name="Circle Solid V6">
    <path d="M16 8c0 4.419-3.581 8-8 8s-8-3.581-8-8c0-4.419 3.581-8 8-8s8 3.581 8 8z" />
  </Icon>
);

export default CircleSolidV6;
