import { produce } from "immer";
import * as actionCreators from "../PartnerSearch/actions/creators";

export const defaultState = {
  newSourceId: null,
  searchTab: "google_place",
  searchTerm: "",
  searchPlaceOnly: true,
  results: [],
  isSearching: false,
  hasSearchingError: false,
  hasOtherPartnersInSearch: false,
  detailsId: null,
  loadingDetails: false,
  partnerDetails: null,
  hasLoadingError: false,
};

const partnerSearchReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.changeSearchTab().type:
      return produce(state, (draft) => {
        draft.searchTab = payload.tab;
        draft.searchTerm = "";
      });
    case actionCreators.changeSearchTerm().type:
      return produce(state, (draft) => {
        draft.searchTerm = payload.term;
      });
    case actionCreators.changePlaceOnlySearch().type:
      return produce(state, (draft) => {
        draft.searchPlaceOnly = payload.checked;
      });
    case actionCreators.changeIsSearching().type:
      return produce(state, (draft) => {
        draft.isSearching = payload.searching;
        if (payload.searching) {
          draft.results = [];
        }
      });
    case actionCreators.setDetailsId().type:
      return produce(state, (draft) => {
        draft.detailsId = payload.id;
      });

    case actionCreators.getDetails().type:
      return produce(state, (draft) => {
        draft.loadingDetails = true;
        draft.hasLoadingError = false;
      });
    case actionCreators.getDetailsError().type:
      return produce(state, (draft) => {
        draft.loadingDetails = false;
        draft.hasLoadingError = true;
      });
    case actionCreators.getDetailsSuccess().type:
      return produce(state, (draft) => {
        draft.loadingDetails = false;
        draft.hasLoadingError = false;
        draft.partnerDetails = payload?.data?.data;
      });

    case actionCreators.searchPartners().type:
      return produce(state, (draft) => {
        draft.isSearching = true;
        draft.hasSearchingError = false;
        draft.hasOtherPartnersInSearch = false;
      });
    case actionCreators.searchPartnersError().type:
      return produce(state, (draft) => {
        draft.isSearching = false;
        draft.results = [];
        draft.hasSearchingError = true;
        draft.hasOtherPartnersInSearch = false;
      });
    case actionCreators.searchPartnersSuccess().type:
      return produce(state, (draft) => {
        draft.isSearching = false;
        draft.hasSearchingError = false;
        draft.results = payload.results?.people;
      });
    default:
      return state;
  }
};

export default partnerSearchReducer;
