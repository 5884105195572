/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BuildingSolidV6 = (props) => (
  <Icon {...props} name="Building Solid V6">
    <path d="M12.5 0A1.5 1.5 0 0 1 14 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-3v-2.5a1.5 1.5 0 0 0-3 0V16h-3A1.5 1.5 0 0 1 2 14.5v-13A1.5 1.5 0 0 1 3.5 0h9zM4 8.5a.5.5 0 0 0 .5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1a.5.5 0 0 0-.5.5v1zM7.5 7c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1zM10 8.5c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5v1zM4.5 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1zM7 4.5c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5v1zM10.5 3c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1z" />
  </Icon>
);

export default BuildingSolidV6;
