import React from "react";
import { Radio, FormGroup, Input } from "../../../shared/v1";

class ExpenseInput extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isPercentage: !!props.isPercentage,
      disableFlip: !!props.disableFlip,
      inputSymbol: !!props.isPercentage ? "%" : "$",
      inputClass: !!props.isPercentage ? "percentage-input" : "flat-input",
    };
  }

  setInputType = (isPercentage) => {
    this.setState({
      ...this.state,
      isPercentage: isPercentage,
      inputSymbol: !!isPercentage ? "%" : "$",
      inputClass: !!isPercentage ? "percentage-input" : "flat-input",
    });
  };

  componentWillReceiveProps = (newProps, newContext) => {
    this.setInputType(!!newProps.isPercentage);
  };

  render() {
    const { label, value, name, onValueUpdate, onRadioUpdate } = this.props;

    return (
      <FormGroup className="tw-min-h-[50px]">
        {!!label && <label className="tw-block tw-mb-[5px]">{label}</label>}

        <div className={`${this.state.inputClass} transaction-input`}>
          <Input size="small" value={value} onChange={(e) => onValueUpdate(e)} />
          <span>{this.state.inputSymbol}</span>
        </div>

        {!this.state.disableFlip && (
          <div className="tw-mt-[-3px] tw-pb-[5px]">
            <Radio
              className={`transaction-input-flip ${!!this.state.isPercentage ? "selected" : ""}`}
              labelClassName="tw-text-[14px] tw-text-gray-base"
              deprecatedOverrideLabelPaddingClasses="tw-pl-0"
              labelTextClassName="tw-leading-[14px] tw-align-middle"
              name={name}
              value="true"
              inline={true}
              checked={!!this.state.isPercentage}
              onChange={(e) => {
                this.setInputType(true);
                onRadioUpdate(e);
              }}
            >
              Percent
            </Radio>

            <Radio
              className={`transaction-input-flip ${!!this.state.isPercentage ? "" : "selected"} tw-ml-[30px]`}
              labelClassName="tw-text-[14px] tw-text-gray-base"
              deprecatedOverrideLabelPaddingClasses="tw-pl-0"
              labelTextClassName="tw-leading-[14px] tw-align-middle"
              name={name}
              value="false"
              inline={true}
              checked={!this.state.isPercentage}
              onChange={(e) => {
                this.setInputType(false);
                onRadioUpdate(e);
              }}
            >
              Flat
            </Radio>
          </div>
        )}
      </FormGroup>
    );
  }
}

export default ExpenseInput;
