import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Checkbox from "@shared/v2/Checkbox";
import {
  CheckSolidV6,
  CopyRegularV6,
  PhoneSolidV6,
  ThumbsUpSolidV6,
  UserPlusSolidV6,
} from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import TextButton from "@shared/v2/TextButton";
import Tooltip from "@shared/v2/Tooltip";
import Dnc from "./Dnc";
import { prospectNameColor } from "./helpers";

const Prospect = ({ prospect, addProspectAsContact, callProspect }) => (
  <div className="tw-flex tw-flex-row tw-justify-between">
    <div className="tw-flex tw-flex-col tw-gap-2px">
      <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
        <span
          className={`tw-text-14px tw-max-w-[170px] tw-overflow-hidden tw-overflow-ellipsis ${prospectNameColor(prospect)} ${prospect.name ? "" : "tw-italic"}`}
        >
          {prospect.name || "No Name"}
        </span>
        {prospect.isRecommended && <ThumbsUpSolidV6 />}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-4px">
        <span className="tw-text-12px tw-text-gray-75">{prospect.phoneFormatted || "--"}</span>
        <Dnc show={prospect.isDnc} />
      </div>
    </div>
    <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
      <Tooltip
        trigger={
          <IconButton schema="primary" size="small" onClick={callProspect} disabled={!prospect.phone}>
            <PhoneSolidV6 size="s" />
          </IconButton>
        }
        placement="right"
        content={<span>Call prospect</span>}
      />
      <Tooltip
        trigger={
          <IconButton
            schema="secondary"
            size="small"
            onClick={addProspectAsContact}
            disabled={prospect.isMyLead}
          >
            <UserPlusSolidV6 size="s" />
          </IconButton>
        }
        placement="right"
        content={<span>Add as lead</span>}
      />
    </div>
  </div>
);

Prospect.propTypes = {
  prospect: PropTypes.shape().isRequired,
  addProspectAsContact: PropTypes.func.isRequired,
  callProspect: PropTypes.func.isRequired,
};

const PropertyCard = ({
  property,
  isSelected,
  select,
  addProspectAsContact,
  callProspect,
  config,
  setPropertyForDetails,
}) => {
  const [copied, setCopied] = useState(false);
  const prospects = useMemo(() => property.prospects?.slice(0, 3), [property]);

  useEffect(() => {
    let mounted = true;
    if (copied) {
      setTimeout(() => {
        if (mounted) setCopied(false);
      }, 2000);
    }

    return () => {
      mounted = false;
    };
  }, [copied]);

  return (
    <div className="tw-group tw-flex tw-flex-col tw-w-[279px] tw-h-[284px] tw-cursor-default tw-border-[1.5px] tw-border-solid tw-border-theme-primary tw-rounded-8px tw-bg-white tw-p-16px tw-whitespace-nowrap tw-font-sans">
      <div className="tw-flex tw-flex-row tw-gap-6px tw-mb-8px">
        <Checkbox checked={isSelected} onChange={select} />
        <span className="tw-text-gray-50 tw-font-bold tw-text-11d">
          {property.status}: {property.statusDateDisplay}
        </span>
      </div>
      <div className="tw-flex tw-items-center tw-gap-[8px] tw-mb-[6px]">
        <TextButton
          className="!tw-p-0 tw-max-w-[220px] tw-overflow-hidden tw-overflow-ellipsis tw-text-18px tw-text-gray-75"
          schema="sentence"
          onClick={() => setPropertyForDetails(property.id)}
        >
          {property.streetAddress}
        </TextButton>
        {copied ? (
          <CheckSolidV6 className="tw-text-semantic-green-120" />
        ) : (
          <IconButton
            className="tw-flex-none tw-invisible group-hover:tw-visible"
            schema="tertiary"
            size="small"
            onClick={() => {
              window
                .copyTextToClipboard?.(
                  `${property.streetAddress}, ${property.city} ${property.state} ${property.postalCode}`,
                )
                .then(() => setCopied(true));
            }}
          >
            <CopyRegularV6 />
          </IconButton>
        )}
      </div>
      <div className="tw-flex tw-flex-row tw-text-gray-50 tw-gap-8px">
        {config.hasPrice ? (
          <div className="tw-flex tw-flex-col tw-w-full tw-gap-2px">
            <span className="tw-text-10px tw-font-semibold">Price</span>
            <span className="tw-text-12px">{property.listPriceFormatted || "--"}</span>
          </div>
        ) : null}
        <div className="tw-flex tw-flex-col tw-w-full tw-gap-2px">
          <span className="tw-text-10px tw-font-semibold">{config.domOrList}</span>
          <span className="tw-text-12px">{property[config.domOrListField] || "--"}</span>
        </div>
      </div>
      <hr className="!tw-my-12px tw-w-full" />
      {prospects.length > 0 ? (
        <div className="tw-flex tw-flex-col tw-gap-12px">
          {prospects.map((prospect, i) => (
            <Prospect
              // eslint-disable-next-line react/no-array-index-key
              key={`${property.id}-${i}`}
              prospect={prospect}
              addProspectAsContact={() => addProspectAsContact(property, prospect)}
              callProspect={() => callProspect({ property, prospect })}
            />
          ))}
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-1">
          <span className="tw-max-w-[125px] tw-text-center tw-text-wrap tw-text-14px tw-italic tw-text-gray-75">
            No potential owners found for this property
          </span>
        </div>
      )}
    </div>
  );
};

PropertyCard.propTypes = {
  property: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  addProspectAsContact: PropTypes.func.isRequired,
  callProspect: PropTypes.func.isRequired,
  config: PropTypes.shape().isRequired,
  setPropertyForDetails: PropTypes.func.isRequired,
};

export default PropertyCard;
