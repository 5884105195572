import React, { useReducer } from "react";
import { bool, func } from "prop-types";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import TextInput from "../../../../shared/v2/TextInput";

const isValid = (state) => state.title.trim().length !== 0 && state.url.trim().length !== 0;
const canSave = (state) => isValid(state) && !state.saving;

const reducer = (state, action) => {
  switch (action.type) {
    case "setTitle":
      return { ...state, title: action.title, disabled: !canSave({ ...state, title: action.title }) };
    case "setUrl":
      return { ...state, url: action.url, disabled: !canSave({ ...state, url: action.url }) };
    case "save":
      return { ...state, saving: true, disabled: true };
    case "saveSuccess":
      return { ...state, saving: false };
    default:
      return state;
  }
};

const ModalAddLink = ({ isModalOpen, toggleModal, onSave }) => {
  const [state, dispatch] = useReducer(reducer, { title: "", url: "", saving: false, disabled: true });
  const { title, url, disabled } = state;

  return (
    <Modal
      show={isModalOpen}
      onHide={() => toggleModal((prev) => !prev)}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      closeButton
    >
      <Modal.Header title="Add A Link" className="tw-mb-32px" closeButton />
      <Modal.Body className="tw-mb-32px">
        <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
          Title <span className="tw-text-coral">*</span>
        </div>
        <TextInput
          onChange={(e) => dispatch({ type: "setTitle", title: e.target.value })}
          className="tw-mb-32px"
          data-cy="new-link-title"
        />
        <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
          Link <span className="tw-text-coral">*</span>
        </div>
        <TextInput
          data-cy="new-link-url"
          onChange={(e) => dispatch({ type: "setUrl", url: e.target.value })}
        />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={() => toggleModal((prev) => !prev)}>
          Cancel
        </Button>
        <Button
          size="medium"
          schema="primary"
          disabled={disabled}
          onClick={async () => {
            if (!disabled) {
              dispatch({ type: "save" });
              // FIXME: refactor to handle error cases
              await onSave({ title, url });
              toggleModal((prev) => !prev);
              dispatch({ type: "saveSuccess" });
            }
          }}
          data-cy="modal-confirm-button"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalAddLink.propTypes = {
  isModalOpen: bool,
  toggleModal: func,
  onSave: func.isRequired,
};

ModalAddLink.defaultProps = {
  isModalOpen: false,
  toggleModal: () => {},
};

export default ModalAddLink;
