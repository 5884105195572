import React from "react";

import { formatPrice } from "../helpers";
import TextInput from "@shared/v2/TextInput";

const PriceInput = ({ name, placeholder, onChange, value }) => {
  const formattedValue = formatPrice(value) || "";

  const cleanValue = (e) => {
    const cleaned = e.target?.value.replace(/\D/g, "");
    onChange(cleaned);
  };

  return (
    <TextInput
      containerClassName="tw-w-[134px]"
      name={name}
      placeholder={placeholder}
      onChange={cleanValue}
      value={formattedValue}
    />
  );
};

export default PriceInput;
