/* eslint-disable class-methods-use-this */
/* global soe */
import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Tooltip from "@shared/v2/Tooltip";
import "./AssignedPlans.scss";
import AssignedPlansCard from "./AssignedPlansCard";
import "./AssignedPlansCard.scss";
import {
  renderApplyModal,
  renderDeleteActionModal,
  renderPlanModal,
  renderDeletePlanModal,
  renderPlanPreviewModal,
} from "./ModalHelpers";

class AssignedPlansPreview extends React.Component {
  componentDidMount() {
    const { platformAccount } = this.props;
    if (!platformAccount) Brivity.AccountsAppstore.start();
  }

  stateDescription = (state) => {
    if (state === "running") {
      return "Applied auto plan(s) running";
    }
    if (state === "empty") {
      return "No auto plan applied";
    }
    if (state === "completed") {
      return "Applied auto plan(s) completed";
    }
    if (state === "paused") {
      return "Applied auto plan(s) paused";
    }
    if (state === "deleted") {
      return "Applied auto plan(s) deleted";
    }
    return "";
  };

  handleOpenPlans = () => {
    const { platformAccount, showPlansModal } = this.props;
    if (platformAccount) showPlansModal();
    if (!platformAccount) soe.toggleLightBox("brivitysales");
  };

  stateTooltip(state) {
    const { platformAccount } = this.props;
    if (!platformAccount) {
      return <div>Brivity Platform feature - Get a Demo</div>;
    }

    return (
      <div className="tw-flex tw-gap-[8px]">
        <i className={`fa fa-bolt ${state}`} />
        {this.stateDescription(state)}
      </div>
    );
  }

  stateOfAssignedPlans() {
    // TODO: disabled eqeqeq for numbers, need further investigation to make sure we are always comparing numbers and not strings
    const { assignedPlansState } = this.props;
    // eslint-disable-next-line eqeqeq
    if (assignedPlansState == 0 || assignedPlansState === "assigned_plans_none") {
      return "empty";
    }
    // eslint-disable-next-line eqeqeq
    if (assignedPlansState == 1 || assignedPlansState === "assigned_plans_running") {
      return "running";
    }
    // eslint-disable-next-line eqeqeq
    if (assignedPlansState == 2 || assignedPlansState === "assigned_plans_paused") {
      return "paused";
    }
    // eslint-disable-next-line eqeqeq
    if (assignedPlansState == 3 || assignedPlansState === "assigned_plans_completed") {
      return "completed";
    }
    // eslint-disable-next-line eqeqeq
    if (assignedPlansState == 4 || assignedPlansState === "assigned_plans_deleted") {
      return "deleted";
    }
    return "empty";
  }

  renderPlansModal() {
    const {
      isPlansModalOpen,
      assignedPlans,
      closeModal,
      assignableName,
      updateAssignedPlan,
      showApplyModal,
      showPlanModal,
      toggleCompletedPlans,
      toggleDeletedPlans,
      showCompletedPlans,
      showDeletedPlans,
      showDeleteAssignedPlanModal,
      getAssignedPlans: _getAssignedPlans,
      assignedPlansPath,
      assignableId,
      assignableType,
    } = this.props;

    const getAssignedPlans = () => {
      _getAssignedPlans(assignedPlansPath, assignableId, assignableType);
    };

    return (
      <Modal
        show={isPlansModalOpen}
        onHide={() => closeModal()}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
        contentClassName="tw-w-[620px] tw-space-y-32px tw-m-auto"
      >
        <Modal.Header size="small" title={`Auto Plans for ${assignableName}`} closeButton />
        <Modal.Body className="tw-max-h-[60vh] tw-flex tw-border-solid tw-border-0 tw-border-t tw-border-b tw-border-neutral-gray-10">
          <AssignedPlansCard
            toggleDeletedPlans={toggleDeletedPlans}
            toggleCompletedPlans={toggleCompletedPlans}
            showDeletedPlans={showDeletedPlans}
            showCompletedPlans={showCompletedPlans}
            onShowApplyModal={showApplyModal}
            onShowPlanModal={showPlanModal}
            onShowDeletePlanModal={showDeleteAssignedPlanModal}
            assignedPlans={assignedPlans}
            getAssignedPlans={getAssignedPlans}
            onCloseAction={closeModal}
            onUpdate={updateAssignedPlan}
          />
        </Modal.Body>
      </Modal>
    );
  }

  renderModal() {
    const {
      isApplyModalOpen,
      isPlansModalOpen,
      isPlanModalOpen,
      isDeleteAssignedActionModalOpen,
      isDeletePlanModalOpen,
      isPlanPreviewModalOpen,
    } = this.props;

    if (isPlansModalOpen) {
      return this.renderPlansModal();
    }
    if (isApplyModalOpen) {
      return renderApplyModal(this.props);
    }
    if (isPlanModalOpen) {
      return renderPlanModal(this.props);
    }
    if (isDeleteAssignedActionModalOpen) {
      return renderDeleteActionModal(this.props);
    }
    if (isDeletePlanModalOpen) {
      return renderDeletePlanModal(this.props);
    }
    if (isPlanPreviewModalOpen) {
      const { showApplyModal } = this.props;
      return renderPlanPreviewModal({ ...this.props, closeModal: () => showApplyModal() });
    }
    return null;
  }

  render() {
    const state = this.stateOfAssignedPlans();

    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="assigned-plans-column flex-row d-flex space-evenly flex-justify-center pseudo-link"
          onClick={this.handleOpenPlans}
        >
          <Tooltip
            id="assigned-plans-tooltip"
            placement="right"
            trigger={<i className={`fa fa-bolt ${state}`} />}
            content={this.stateTooltip(state)}
          />
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

export default AssignedPlansPreview;

AssignedPlansPreview.propTypes = {
  assignableId: PropTypes.string.isRequired,
  assignableName: PropTypes.string.isRequired,
  assignableType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlans: PropTypes.arrayOf(PropTypes.any).isRequired,
  assignedPlansPath: PropTypes.string.isRequired,
  assignedPlansState: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  getAssignedPlans: PropTypes.func.isRequired,
  isApplyModalOpen: PropTypes.bool.isRequired,
  isDeleteAssignedActionModalOpen: PropTypes.bool.isRequired,
  isDeletePlanModalOpen: PropTypes.bool.isRequired,
  isPlanModalOpen: PropTypes.bool.isRequired,
  isPlanPreviewModalOpen: PropTypes.bool.isRequired,
  isPlansModalOpen: PropTypes.bool.isRequired,
  platformAccount: PropTypes.bool.isRequired,
  showApplyModal: PropTypes.func.isRequired,
  showCompletedPlans: PropTypes.bool.isRequired,
  showDeleteAssignedPlanModal: PropTypes.func.isRequired,
  showDeletedPlans: PropTypes.bool.isRequired,
  showPlanModal: PropTypes.func.isRequired,
  showPlansModal: PropTypes.func.isRequired,
  toggleCompletedPlans: PropTypes.func.isRequired,
  toggleDeletedPlans: PropTypes.func.isRequired,
  updateAssignedPlan: PropTypes.func.isRequired,
};
