import React from "react";
import PropTypes from "prop-types";

const classNames = {
  green: "tw-bg-semantic-green-110 tw-text-white",
  "green-light":
    "tw-bg-semantic-green-5 tw-text-[#177335] tw-border-semantic-green-100 !tw-px-[15px] !tw-pb-[7px] tw-pt-[8px] tw-border-solid tw-border-[1px] tw-border-t-0",
  gray: "tw-bg-neutral-gray-50 tw-text-white",
  "gray-light": "tw-bg-neutral-gray-5",
  warning: "tw-bg-semantic-yellow-110 tw-text-white",
  "warning-light": "tw-bg-semantic-yellow-5 tw-text-semantic-yellow-120",
  danger: "tw-bg-semantic-red-110 tw-text-white",
};

const CallBarContainer = ({ children, className, variant }) => (
  <div
    className={`tw-text-12d tw-leading-[20px] tw-font-semibold tw-px-[16px] tw-py-[8px] first-of-type:tw-rounded-t-[8px] last-of-type:tw-rounded-b-[8px] ${classNames[variant]} ${className}`}
  >
    {children}
  </div>
);

CallBarContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "green",
    "green-light",
    "gray",
    "gray-light",
    "warning",
    "warning-light",
    "danger",
  ]),
};

CallBarContainer.defaultProps = {
  className: "",
  variant: "green",
};

export default CallBarContainer;
