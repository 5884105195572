import React from "react";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/TextButton";

const ShowMoreLess = ({ showMore = false, onClick }) => {
  const clickHnalder = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <TextButton className="tw-p-0 tw-pb-[16px]" onClick={clickHnalder}>
      SHOW {showMore ? "LESS" : "MORE"}
    </TextButton>
  );
};

ShowMoreLess.propTypes = {
  showMore: PropTypes.bool,
  onClick: PropTypes.func,
};

ShowMoreLess.defaultProps = {
  showMore: false,
  onClick: null,
};

export default ShowMoreLess;
