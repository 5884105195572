/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const GridSolidV6 = (props) => (
  <Icon {...props} name="Grid Solid V6">
    <path d="M1 2.25C1 1.56 1.56 1 2.25 1h1.5C4.441 1 5 1.56 5 2.25v1.5A1.25 1.25 0 0 1 3.75 5h-1.5A1.25 1.25 0 0 1 1 3.75v-1.5zm0 5C1 6.559 1.56 6 2.25 6h1.5C4.441 6 5 6.559 5 7.25v1.5A1.25 1.25 0 0 1 3.75 10h-1.5A1.25 1.25 0 0 1 1 8.75v-1.5zm4 6.5A1.25 1.25 0 0 1 3.75 15h-1.5A1.25 1.25 0 0 1 1 13.75v-1.5c0-.691.56-1.25 1.25-1.25h1.5c.691 0 1.25.559 1.25 1.25v1.5zm1-11.5C6 1.56 6.559 1 7.25 1h1.5C9.441 1 10 1.56 10 2.25v1.5A1.25 1.25 0 0 1 8.75 5h-1.5A1.25 1.25 0 0 1 6 3.75v-1.5zm4 6.5A1.25 1.25 0 0 1 8.75 10h-1.5A1.25 1.25 0 0 1 6 8.75v-1.5C6 6.559 6.559 6 7.25 6h1.5C9.441 6 10 6.559 10 7.25v1.5zm-4 3.5c0-.691.559-1.25 1.25-1.25h1.5c.691 0 1.25.559 1.25 1.25v1.5A1.25 1.25 0 0 1 8.75 15h-1.5A1.25 1.25 0 0 1 6 13.75v-1.5zm9-8.5A1.25 1.25 0 0 1 13.75 5h-1.5A1.25 1.25 0 0 1 11 3.75v-1.5c0-.69.559-1.25 1.25-1.25h1.5c.691 0 1.25.56 1.25 1.25v1.5zm-4 3.5c0-.691.559-1.25 1.25-1.25h1.5c.691 0 1.25.559 1.25 1.25v1.5A1.25 1.25 0 0 1 13.75 10h-1.5A1.25 1.25 0 0 1 11 8.75v-1.5zm4 6.5A1.25 1.25 0 0 1 13.75 15h-1.5A1.25 1.25 0 0 1 11 13.75v-1.5c0-.691.559-1.25 1.25-1.25h1.5c.691 0 1.25.559 1.25 1.25v1.5z" />
  </Icon>
);

export default GridSolidV6;
