import React from "react";
import { calculatePercent } from "./reportHelpers";

const TableRow = ({ children }) => {
  return <div className="user td">{children}</div>;
};

const TableData = (props) => (
  <div className={`${props.className} text-center`}>
    <strong>{props.value}</strong>
    {props.total >= 0 && (
      <span style={{ fontSize: "13px" }}> ({calculatePercent(props.total, props.value).toFixed(0)}%)</span>
    )}
  </div>
);

const AgentReportTotalRow = (props) => (
  <TableRow>
    <TableData value="Totals" className="col-xs-2" />
    <TableData value={props.total_leads} className="col-xs-1" />
    <TableData value={props.total_emails} className="col-xs-1" total={props.total_leads} />
    <TableData value={props.total_calls} className="col-xs-1" total={props.total_leads} />
    <TableData value={props.total_contacted} className="col-xs-1" total={props.total_leads} />
    <TableData value={props.total_texts} className="col-xs-1" total={props.total_leads} />
    <div className="col-xs-4">
      <TableData className="col-xs-4" />
      <TableData className="col-xs-4" />
      <TableData className="col-xs-4" />
    </div>
    <TableData className="col-xs-1" value={props.total_logins} />
    <div className="col-xs-1" />
  </TableRow>
);
export default AgentReportTotalRow;
