import React from "react";

import { CircleQuestionSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const QuestionsActivity = ({ activity, onReadChange }) => {
  const { propertyStreetAddress } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={CircleQuestionSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        {propertyStreetAddress && <span>Address: {propertyStreetAddress}</span>}
      </div>
    </ActivitiesContainer>
  );
};

export default QuestionsActivity;

QuestionsActivity.propTypes = {
  ...activityCommonPropTypes,
};

QuestionsActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
