import axios from "axios";

const fetchAssignedPlansPending = () => ({
  type: "FETCH_ASSIGNED_PLANS_PENDING",
});

const fetchAssignedPlansSuccess = (assignedPlans) => ({
  type: "FETCH_ASSIGNED_PLANS_SUCCESS",
  assignedPlans,
});

const fetchAssignedPlansFailure = () => ({
  type: "FETCH_ASSIGNED_PLANS_FAILURE",
});

const fetchAssignedPlanPending = () => ({
  type: "FETCH_ASSIGNED_PLAN_PENDING",
});

const fetchAssignedPlanSuccess = (assignedPlan) => ({
  type: "FETCH_ASSIGNED_PLAN_SUCCESS",
  assignedPlan,
});

const createAssignedPlanFailure = (errors) => ({
  type: "CREATE_ASSIGNED_PLAN_FAILURE",
  isCreatingAssignedPlan: false,
  errors,
});

const createAssignedPlanPending = () => ({
  type: "CREATE_ASSIGNED_PLAN_PENDING",
  isCreatingAssignedPlan: true,
});

const createAssignedPlanSuccess = (assignedPlan) => ({
  type: "CREATE_ASSIGNED_PLAN_SUCCESS",
  isCreatingAssignedPlan: false,
  assignedPlan,
});

const updateAssignedPlanSuccess = (assignedPlan) => ({
  type: "UPDATE_ASSIGNED_PLAN_SUCCESS",
  assignedPlan,
});

const updateAssignedPlanFailure = (errors) => ({
  type: "UPDATE_ASSIGNED_PLAN_FAILURE",
  errors,
});

const updateAssignedActionPending = () => ({
  type: "UPDATE_ASSIGNED_ACTION_PENDING",
});

const updateAssignedActionFailure = () => ({
  type: "UPDATE_ASSIGNED_ACTION_FAILURE",
});

const updateAssignedActionSuccess = (assignedAction) => ({
  type: "UPDATE_ASSIGNED_ACTION_SUCCESS",
  assignedAction,
});

export const showApplyModal = () => (dispatch) => {
  dispatch({ type: "SHOW_APPLY_MODAL" });
};

export const showPlansModal = () => (dispatch) => {
  dispatch({ type: "SHOW_PLANS_MODAL" });
};

export const showPlanModal = (assignedPlan) => (dispatch) => {
  axios
    .get(`/assigned_plans/${assignedPlan.id}`, {
      responseType: "json",
      contentType: "application/json",
    })
    .then((results) => {
      dispatch({ type: "SHOW_PLAN_MODAL", assignedPlan: results.data.assigned_plan });
    })
    .catch();
};

export const showPreviewPlanModal = (autoPlanId, assignableId, assignableType) => (dispatch) => {
  axios
    .get(`/auto_plans/${autoPlanId}/preview`, {
      params: {
        assignable_id: assignableId,
        assignable_type: assignableType,
      },
    })
    .then((res) => dispatch({ type: "SHOW_PLAN_PREVIEW_MODAL", autoPlan: res.data.auto_plan }))
    .catch(() => {  } );
};

export const showDeleteAssignedActionModal = (actionToDelete) => (dispatch) => {
  dispatch({
    type: "SHOW_DELETE_ASSIGNED_ACTION_MODAL",
    actionToDelete,
  });
};

export const showDeleteAssignedPlanModal = (planToDelete) => (dispatch) => {
  dispatch({
    type: "SHOW_DELETE_ASSIGNED_PLAN_MODAL",
    planToDelete,
  });
};

export const closeModal =
  (callback = null) =>
  (dispatch) => {
    dispatch({ type: "CLOSE_MODAL" });
    if (callback) {
      dispatch(callback);
    }
  };

export const toggleCompletedPlans = () => (dispatch) => {
  dispatch({ type: "TOGGLE_COMPLETED_PLANS" });
};

export const toggleDeletedPlans = () => (dispatch) => {
  dispatch({ type: "TOGGLE_DELETED_PLANS" });
};

export const previewAssignedAction = (assignedAction) => (dispatch) => {
  dispatch({
    type: "PREVIEW_ASSIGNED_ACTION",
    assignedAction,
  });
};

export const previewAutoPlanAction = (actionId) => (dispatch) => {
  dispatch({
    type: "PREVIEW_AUTO_PLAN_ACTION",
    actionId,
  });
};

export const submitApplyModal = (assignedPlanPath, form) => (dispatch) => {
  dispatch(createAssignedPlanPending());

  axios
    .post(assignedPlanPath, {
      assigned_plan: form,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((res) => {
      dispatch(createAssignedPlanSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createAssignedPlanFailure(err.response.data.errors));
    });
};

export const getAssignedPlans = (assignedPlansPath, assignableId, assignableType) => (dispatch) => {
  dispatch(fetchAssignedPlansPending());

  axios
    .get(assignedPlansPath, {
      responseType: "json",
      contentType: "application/json",
      params: { assignable_type: assignableType, assignable_id: assignableId },
    })
    .then((results) => {
      dispatch(fetchAssignedPlansSuccess(results.data));
    })
    .catch(() => {
      dispatch(fetchAssignedPlansFailure());
    });
};

export const updateAssignedPlan = (assignedPlanPath, form, callback) => {
  const formWithToken = { ...form, authenticity_token: ReactOnRails.authenticityToken() };

  return (dispatch) => {
    axios
      .patch(assignedPlanPath, formWithToken)
      .then((results) => {
        dispatch(updateAssignedPlanSuccess(results.data.assigned_plan));
        if (callback) {
          dispatch(callback);
        }
      })
      .catch((err) => {
        dispatch(updateAssignedPlanFailure(err.response.data.errors));
      });
  };
};

export const updateAssignedAction = (assignedActionPath, form = {}) => {
  const formWithToken = { ...form, authenticity_token: ReactOnRails.authenticityToken() };

  return (dispatch) => {
    dispatch(updateAssignedActionPending());

    axios
      .patch(assignedActionPath, formWithToken)
      .then((results) => {
        dispatch(updateAssignedActionSuccess(results.data));
      })
      .catch(() => {
        dispatch(updateAssignedActionFailure());
      });
  };
};

export const deleteAssignedAction = (id, assignedActionPath, callback) => (dispatch) => {
  axios
    .delete(assignedActionPath, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
    .then(() => {
      dispatch({
        type: "DELETE_ASSIGNED_ACTION_SUCCESS",
        assignedActionId: id,
      });
      if (callback) {
        dispatch(callback);
      }
    })
    .catch((err) => {
      dispatch({
        type: "DELETE_ASSIGNED_ACTION_FAILURE",
        error: err.response.data.message,
      });
    });
};

export const updateValues = (name, value) => ({
  type: "UPDATE_VALUES",
  name,
  value,
});

export const getAssignedPlan = (assignedPlanPath) => (dispatch) => {
  dispatch(fetchAssignedPlanPending());

  axios
    .get(assignedPlanPath)
    .then((res) => dispatch(fetchAssignedPlanSuccess(res.data.assigned_plan)))
    .catch((err) => {
      dispatch({
        type: "FETCH_ASSIGNED_PLAN_FAILURE",
        errors: err.response.data.message,
        isFetching: false,
      });
    });
};
