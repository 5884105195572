import React from "react";
import { connect } from "react-redux";

import { toggleModalAddTaskOpen } from "../actions/creators";
import taskFilterHelper from "./helpers";

import Button from "../../../../shared/v2/Button";

const NO_TASK_STYLING = {
  container:
    "tw--mt-[4px] tw-border-t-[1px] tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-neutral-gray-50 tw-border-solid tw-pt-[16px] tw-flex tw-flex-col tw-items-center",
  text: "tw-font-normal tw-font tw-text-neutral-gray-50 tw-mb-[16px]",
};

const mapStateToPropsFilters = (state) => ({
  primaryActiveList: state.tdpTasksReducer.primaryActiveList,
});

const mapDispatchToPropsNoTasks = (dispatch) => ({
  onToggleModalAddTaskOpen: (boolean) => dispatch(toggleModalAddTaskOpen(boolean)),
});

export const NoTasks = connect(
  null,
  mapDispatchToPropsNoTasks,
)(({ onToggleModalAddTaskOpen }) => (
  <div className={NO_TASK_STYLING.container}>
    <div className={NO_TASK_STYLING.text}>No tasks have been added to this transaction.</div>
    <Button schema="secondary" onClick={() => onToggleModalAddTaskOpen(true)}>
      ADD TASK
    </Button>
  </div>
));

export const NoTasksInFilters = connect(mapStateToPropsFilters)(({ primaryActiveList }) => (
  <div className={NO_TASK_STYLING.container}>
    <div className={NO_TASK_STYLING.text}>{taskFilterHelper(primaryActiveList)}</div>
  </div>
));
