import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";
import FieldSelect from "../../shared/UtilsV1/FieldSelect";
import { getRevenuePartnerOptions } from "../../Settings/Users/api";
import RevenuePartnerSearchableDropdown from "./RevenuePartnerSearchableDropdown";
import { setPersonValue } from "../../reducers/contactsReducer";

const revenuePartnerSourceOptions = [
  { label: "Search PLACE users", value: "Search PLACE users" },
  { label: "Pre-PLACE", value: "Pre-PLACE" },
  { label: "No Revenue Partner in Agent Agreement", value: "No revenue partner" },
];

function AddRevenuePartnerModal({ show, onClose }) {
  const dispatch = useDispatch();
  const [selectedRevenuePartnerSourceOption, setSelectedRevenuePartnerSourceOption] = useState(null);
  const [selectedRevenuePartner, setSelectedRevenuePartner] = useState(null);

  const [revenuePartnerSearchText, setRevenuePartnerSearchText] = useState("");
  const [revenuePartnerSearchOptions, setRevenuePartnerSearchOptions] = useState([]);
  const [isAllRequiredFieldsFilled, setIsAllRequiredFieldsFill] = useState(false);

  useEffect(() => {
    if (selectedRevenuePartnerSourceOption && selectedRevenuePartnerSourceOption !== "Search PLACE users") {
      setIsAllRequiredFieldsFill(true);
      setSelectedRevenuePartner(null);
    } else if (
      selectedRevenuePartnerSourceOption &&
      selectedRevenuePartnerSourceOption === "Search PLACE users" &&
      selectedRevenuePartner
    ) {
      setIsAllRequiredFieldsFill(true);
    } else {
      setIsAllRequiredFieldsFill(false);
    }
  }, [selectedRevenuePartnerSourceOption, selectedRevenuePartner]);

  const onAddRevenuePartner = () => {
    dispatch(setPersonValue({ key: "revenuePartnerType", value: "Person" }));
    dispatch(
      setPersonValue({
        key: "revenuePartnerPersonId",
        value: selectedRevenuePartner.revenuePartnerPersonId,
      }),
    );
    dispatch(
      setPersonValue({
        key: "revenuePartnerPersonName",
        value: selectedRevenuePartner.revenuePartnerPersonName,
      }),
    );
    dispatch(
      setPersonValue({
        key: "revenuePartnerPersonTeamName",
        value: selectedRevenuePartner.revenuePartnerPersonTeamName,
      }),
    );
  };

  const handleOnAddRevenuePartner = () => {
    if (selectedRevenuePartnerSourceOption === "Search PLACE users") {
      onAddRevenuePartner();
    } else {
      dispatch(setPersonValue({ key: "revenuePartnerType", value: selectedRevenuePartnerSourceOption }));
    }
    onClose();
  };

  useEffect(() => {
    const fetchOptionsData = async () => {
      const res = await getRevenuePartnerOptions(revenuePartnerSearchText);
      setRevenuePartnerSearchOptions([
        ...res.data.map((option) => ({
          label: `${option.full_name} (${option.team_name})`,
          value: {
            value: option.person_id,
            data: {
              revenuePartnerPersonId: option.person_id,
              revenuePartnerPersonName: option.full_name,
              revenuePartnerPersonTeamName: option.team_name,
            },
          },
        })),
      ]);
    };
    // Update debounced function after 500 ms
    const handler = setTimeout(() => {
      if (revenuePartnerSearchText.length > 0) {
        fetchOptionsData();
      } else {
        setRevenuePartnerSearchOptions([]);
      }
    }, 500);
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [revenuePartnerSearchText]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[440px]"
    >
      <Modal.Header title="Add Revenue Partner" className="tw-mb-32px" closeButton />
      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-30">
            You are about to add a revenue partner for this user. The user’s revenue partner is the person who
            referred them to the company.
          </div>
          <div className="tw-font-semibold tw-text-14d tw-text-neutral-gray-75">
            <span className="tw-text-brivity-coral-100 tw-font-bold ">ATTENTION:</span> Adding a revenue
            partner can only be done once per user. The revenue partner cannot be changed or removed once
            added.
          </div>

          <FieldSelect
            label="Revenue Partner Source"
            placeholder="Select revenue partner source"
            value={selectedRevenuePartnerSourceOption}
            required
            options={revenuePartnerSourceOptions}
            onChange={(value) => setSelectedRevenuePartnerSourceOption(value)}
          />
          {
            // Email will be disable, becasue it will take from Revenue Partner options, and not editable
            // Current revenue partner data only contains id, name, team name
            // needed avatar and email
          }
          {selectedRevenuePartnerSourceOption === "Search PLACE users" && (
            <RevenuePartnerSearchableDropdown
              label="Revenue Partner"
              options={revenuePartnerSearchOptions}
              setSearchText={setRevenuePartnerSearchText}
              searchText={revenuePartnerSearchText}
              onChange={(value) => {
                setSelectedRevenuePartner(value.data);
              }}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          schema="primary"
          onClick={handleOnAddRevenuePartner}
          disabled={!isAllRequiredFieldsFilled}
          data-cy="revenue_partner_modal_add_revenue_partner_btn"
        >
          Add Revenue Partner
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddRevenuePartnerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddRevenuePartnerModal;
