import namespaceAction from "../../../../../reducers/helpers";
import { saveDetail } from "../../../../Header/actions/creators";

const REDUCER_NAME = "tdpThumbnailSelector";
const wrap = namespaceAction(REDUCER_NAME);

export const showModal = (show) => wrap({ type: "SHOW_MODAL", show });
export const reset = () => wrap({ type: "RESET" });

export const setSaving = () => wrap({ type: "SAVING" });
export const setSaved = () => wrap({ type: "SAVED" });
export const setSaveError = (error) => wrap({ type: "SAVE_ERROR", error });

export const setAvailablePhotos = (availablePhotos) => wrap({ type: "SET_PHOTOS", availablePhotos });
export const selectPhoto = (photo) => wrap({ type: "SELECT_PHOTO", photo });
export const setThumnail = saveDetail;
