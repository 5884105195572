import React from "react";
import { reorderNotesBasedOnStarred } from "../../../Transactions/utils/helpers";
import Modal from "../../../shared/v2/Modal";
import Button from "../../../shared/v2/Button";
import { PenToSquareSolidV6 } from "../../../shared/v2/Icomoon";
import NoteCard from "../notes/NoteCard";

const ViewAllNotes = ({ notes, onClose, onAddNote, onStar, onEdit, onDelete }) => (
  <Modal
    id="view-all-listing-alerts-modal"
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[910px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    show
    onHide={onClose}
  >
    <Modal.Header title="Notes" closeButton />
    <Modal.Body className="tw-overflow-auto tw-flex tw-flex-wrap tw-gap-[24px] tw-p-[16px] tw-m-[-16px]">
      <button
        className="tw-shadow-card tw-flex tw-flex-col tw-gap-[24px] tw-justify-center tw-items-center tw-border-none tw-rounded-[8px] tw-p-[20px] tw-bg-white tw-h-[227px] tw-w-[265px]"
        onClick={onAddNote}
      >
        <PenToSquareSolidV6 className="tw-text-theme-primary tw-w-[48px] tw-h-[48px]" size="custom" />
        <span className="tw-text-theme-primary">Create New Note</span>
      </button>
      {reorderNotesBasedOnStarred(notes).map((note) => (
        <NoteCard
          key={note.id}
          note={note}
          onStar={() => onStar(note)}
          onEdit={() => onEdit(note)}
          onDelete={() => onDelete(note)}
        />
      ))}
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onClose}>
        Cancel
      </Button>
      <Button size="medium" onClick={onClose}>
        Done
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ViewAllNotes;
