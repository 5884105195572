import React from "react";
import CustomControl from "../custom/Control";
import { customDefaultProps, customPropTypes } from "../utils";

// This default Control is identical to the native Control,
// but it's necessary because we're modifying the children for the Control at the custom wrapper,
// so we need it to behave as if it was a custom component to render the modified children

const Control = ({ componentProps, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CustomControl {...componentProps} preserveStyle>
    {children}
  </CustomControl>
);

Control.propTypes = customPropTypes;

Control.defaultProps = customDefaultProps;

export default Control;
