import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Alert from "@shared/v2/Alert";
import CallBar from "./components/CallBar";
import useDialer from "./hooks/useDialer";
import Loading from "../Goals/components/Loader";
import CallOutcome from "./components/CallOutcome";
import CallNotes from "./components/CallNotes";
import CallButton from "./components/CallButton";
import SinglePerson from "./components/SinglePerson";
import PlatformMessage from "./components/PlatformMessage";

// To use pass in the leadUuid prop containing a person uuid
const SingleDialer = ({ leadUuid }) => {
  const leadUuids = useMemo(() => [leadUuid], [leadUuid]);

  const {
    actions,
    data: { callOutcome, person, phoneNumber },
    error,
    loading,
  } = useDialer();

  useEffect(() => {
    actions.setLeadUuids(leadUuids);
  }, [leadUuids]);

  useEffect(
    () => () => {
      actions.setLeadUuids(null);
    },
    [],
  );

  return (
    <div className="tw-relative tw-flex tw-flex-col tw-gap-[24px]">
      <CallBar />

      <PlatformMessage />

      <div className="tw-flex tw-justify-between tw-items-end">
        <SinglePerson />
        <CallButton />
      </div>

      <CallOutcome />

      <CallNotes
        isDnc={phoneNumber?.meta.isDnc}
        mentionableIdOrUuid={person?.uuid}
        mentionableType="Person"
      />

      {error && <Alert variant="error" text={error} />}

      <div className="tw-flex tw-justify-between">
        <Button
          schema="tertiary"
          size="medium"
          onClick={() => {
            actions.setCallOutcome(null);
            actions.setComment("");
          }}
        >
          Clear
        </Button>
        <Button size="medium" onClick={() => actions.logCall()} disabled={!callOutcome}>
          Save & Log Call
        </Button>
      </div>
      {loading && <Loading />}
    </div>
  );
};

SingleDialer.propTypes = {
  leadUuid: PropTypes.string,
};

SingleDialer.defaultProps = {
  leadUuid: null,
};

export default SingleDialer;
