/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VolumeSolidV6 = (props) => (
  <Icon {...props} name="Volume Solid V6">
    <path d="M12.35 5.942a.665.665 0 0 0-.937.095.665.665 0 0 0 .094.938c.313.259.494.631.494 1.025s-.181.767-.495 1.024a.666.666 0 1 0 .844 1.033c.626-.513.984-1.26.984-2.057s-.358-1.544-.983-2.058zm1.681-2.053a.668.668 0 0 0-.845 1.031c.943.772 1.482 1.894 1.482 3.08s-.54 2.308-1.481 3.081a.667.667 0 1 0 .846 1.031c1.252-1.026 1.969-2.526 1.969-4.112s-.717-3.086-1.969-4.111zM9.256 1.857a.889.889 0 0 0-.956.146L4.551 5.334H2.223c-.736 0-1.333.597-1.333 1.308v2.642c0 .736.597 1.308 1.333 1.308h2.329l3.747 3.331c.166.222.377.3.591.3a.89.89 0 0 0 .889-.889V2.667c0-.35-.203-.692-.522-.81z" />
  </Icon>
);

export default VolumeSolidV6;
