import React from "react";

import { HouseSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const HomeValueActivity = ({ activity, onReadChange }) => {
  const {
    propertyStreetAddress,
    propertyState,
    propertyZipCode,
    subjectFirstName,
    subjectLastName,
    beds,
    baths,
    sqFeet,
  } = activity.data;

  return (
    <ActivitiesContainer
      activity={activity}
      Icon={HouseSolidV6}
      title={`New Home Value: ${subjectFirstName} ${subjectLastName}`}
      onReadChange={onReadChange}
    >
      <div className={STYLES.contentContainer}>
        <span>{`Address: ${propertyStreetAddress} ${propertyState}, ${propertyZipCode}`}</span>
        <PipedElements>
          {beds && <span>Bed: {beds}</span>}
          {baths && <span>Bath: {baths}</span>}
          {sqFeet && <span>SQFT: ${sqFeet}</span>}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

export default HomeValueActivity;

HomeValueActivity.propTypes = {
  ...activityCommonPropTypes,
};

HomeValueActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
