import { useState, useEffect, useCallback } from "react";

export const useMinMax = (minVal, maxVal, valueDisplay) => {
  const [tmpMin, setTmpMin] = useState(valueDisplay(minVal));
  const [tmpMax, setTmpMax] = useState(valueDisplay(maxVal));
  return [tmpMin, setTmpMin, tmpMax, setTmpMax];
};

export const useGetPercent = (min, max) =>
  useCallback((value) => (!min && !max ? 100 : Math.floor(((value - min) / (max - min)) * 100)), [min, max]);

export const useSetSliderBar = (minVal, maxVal, getPercent, maxValRef, minValRef, setRange) =>
  useEffect(() => {
    const minNum = +minVal.replace(/[^0-9]/g, "");
    // Set width of the range to decrease from the left side
    if (maxValRef.current) {
      const minPercent = getPercent(minNum);
      const maxPercent = getPercent(+maxValRef.current.value) || 100; // Preceding with '+' converts the value from type string to type number
      setRange("left", `${minPercent + 1}%`); // 1 is always added to ensure the bar mask stays behind the control
      setRange("width", `${maxPercent - minPercent}%`);
    }
  }, [minVal, maxVal]);
