import React, {Component} from 'react';
import { Button, Modal } from '../../shared/v1';

export default class DeleteModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.props
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, ...nextProps})
  }

  render() {
    return(
      <Modal
        size={'sm'}
        show={this.state.showDeleteModal}
        onHide={this.state.closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Fair warning: unlike archiving a person, deleting a Task is forever. Your client won't be able to see that you deleted it, but they won't see if you did it, either.
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            className="modal-cancel tw-float-left"
            onClick={this.state.closeDeleteModal}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="danger"
            invert
            className="tw-float-right"
            onClick={this.state.deleteTasksHandler}>
            Yes. Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
