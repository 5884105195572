import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// TODO: replace with v6 icons
import { faGripHorizontal, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import styleSchema from "./DragHandle.styles";

const DragHandle = ({ orientation, size, className, ...otherProps }) => (
  <FontAwesomeIcon
    icon={orientation === "horizontal" ? faGripHorizontal : faGripVertical}
    className={`${styleSchema.regular} ${styleSchema[size]} ${className}`}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  />
);

DragHandle.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
  size: PropTypes.oneOf(["medium", "large"]),
  className: PropTypes.string,
};

DragHandle.defaultProps = {
  size: "medium",
  className: "",
};

export default DragHandle;
