import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Error from "../../../Reports/components/DashboardShared/Error";
import Button from "../../../shared/v2/Button";
import DatePicker from "../../../shared/v2/DatePicker";
import Dropdown from "../../../shared/v2/Dropdown";
import { ChevronRight } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import useLeadGenActivities from "../../services/useLeadGenActivities";
import Loader from "../Loader";
import LeadGenActivityCard from "./LeadGenActivityCard";
import NewLeadGenActivityModal from "./NewLeadGenActivityModal";
import ViewActivityHistoryModal from "./ViewActivityHistoryModal";

const FROM_FORMAT = "MM/DD/YYYY";
const DATE_FORMAT = "YYYY-MM-DD";

const PERIOD_OPTIONS = [
  { value: "this_week", label: "This Week (Mon - Sun)" },
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "last_week", label: "Last Week (Mon - Sun)" },
  { value: "this_month", label: "This Month" },
  { value: "last_month", label: "Last Month" },
  { value: "this_quarter", label: "This Quarter" },
  { value: "last_quarter", label: "Last Quarter" },
  { value: "this_year", label: "This Year" },
  { value: "last_year", label: "Last Year" },
  { value: "custom", label: "Custom" },
];

const getDate = (position, period) => {
  let date;
  switch (period.value) {
    case "today":
      date = moment()[position]("day");
      break;
    case "yesterday":
      date = moment().subtract(1, "day")[position]("day");
      break;
    case "this_week":
      date = moment()[position]("isoWeek");
      if (date.year() !== moment().year()) date = moment()[position]("year");
      break;
    case "last_week":
      date = moment().subtract(1, "week")[position]("isoWeek");
      if (date.year() !== moment().year()) date = moment()[position]("year");
      break;
    case "this_month":
      date = moment()[position]("month");
      break;
    case "last_month":
      date = moment().subtract(1, "month")[position]("month");
      break;
    case "this_quarter":
      date = moment()[position]("quarter");
      break;
    case "last_quarter":
      date = moment().subtract(1, "quarter")[position]("quarter");
      break;
    case "this_year":
      date = moment()[position]("year");
      break;
    case "last_year":
      date = moment().subtract(1, "year")[position]("year");
      break;
    default:
      date = position === "startOf" ? moment().subtract(7, "days") : moment();
      date = date[position]("day");
      if (date.year() !== moment().year()) date = moment()[position]("year");
  }
  return date.format(FROM_FORMAT);
};

const LeadGenActivities = ({ userUuids }) => {
  const [startDate, setStartDate] = useState(getDate("startOf", PERIOD_OPTIONS[0]));
  const [endDate, setEndDate] = useState(getDate("endOf", PERIOD_OPTIONS[0]));
  const [period, setPeriod] = useState(PERIOD_OPTIONS[0]);
  const [viewHistoryModalGoalType, setViewHistoryModalGoalType] = useState(null);
  const [newLeadGenActivityOpen, setNewLeadGenActivityOpen] = useState(false);
  const [newLeadGenActivityOpenForType, setNewLeadGenActivityOpenForType] = useState(null);
  const { actions, data, error, loading } = useLeadGenActivities({
    period: period.value,
    startDate: moment(startDate, FROM_FORMAT).format(DATE_FORMAT),
    endDate: moment(endDate, FROM_FORMAT).format(DATE_FORMAT),
    userUuids,
  });

  useEffect(() => {
    setStartDate(getDate("startOf", period));
    setEndDate(getDate("endOf", period));
  }, [period]);

  useEffect(() => {
    if (viewHistoryModalGoalType === null) actions.refreshData();
  }, [viewHistoryModalGoalType]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-[20px] tw-flex-1 tw-relative tw-bg-white tw-min-w-[300px] tw-p-[20px] tw-rounded-[8px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <h3 className="tw-m-0 tw-text-18d tw-font-normal">Activity Tracking</h3>
        <TextButton
          className="tw-flex tw-items-center tw-text-14d"
          schema="sentence"
          onClick={() => window.location.assign("/settings/goals")}
        >
          Goal Settings <ChevronRight size="s" className="before:tw-align-middle tw-ml-[2px]" />
        </TextButton>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[12px]">
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <Dropdown
            containerClassName="tw-flex-1"
            options={PERIOD_OPTIONS}
            value={period}
            onChange={setPeriod}
          />
          {period?.value === "custom" && (
            <div className="tw-flex tw-gap-[8px]">
              <DatePicker
                containerClassName="tw-flex-1"
                inputProps={{ size: 1 }}
                value={startDate}
                onChange={(value) => {
                  const momentStartDate = moment(startDate, FROM_FORMAT);
                  const momentValue = moment(value, FROM_FORMAT);
                  if (momentStartDate.year() !== momentValue.year()) {
                    setEndDate(moment(endDate, FROM_FORMAT).year(momentValue.year()).format(FROM_FORMAT));
                  }
                  setStartDate(moment(value, FROM_FORMAT).format(FROM_FORMAT));
                }}
              />
              <DatePicker
                containerClassName="tw-flex-1"
                inputProps={{ size: 1 }}
                value={endDate}
                onChange={(value) => {
                  const momentEndDate = moment(endDate, FROM_FORMAT);
                  const momentValue = moment(value, FROM_FORMAT);
                  if (momentEndDate.year() !== momentValue.year()) {
                    setStartDate(moment(startDate, FROM_FORMAT).year(momentValue.year()).format(FROM_FORMAT));
                  }
                  setEndDate(moment(value, FROM_FORMAT).format(FROM_FORMAT));
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-5" />
      <div className="tw-flex tw-flex-col tw-gap-[20px]">
        <span className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold">Brivity Activities</span>
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-x-[20px] tw-gap-y-[36px]">
          {data?.goalTypes
            .filter(({ goalSection }) => goalSection === "lead_interaction")
            .map((goalType) => (
              <LeadGenActivityCard
                key={goalType.id}
                label={goalType.displayName}
                goalValue={data.goals[goalType.id]?.goalValue || 0}
                onClick={() => setNewLeadGenActivityOpenForType(String(goalType.id))}
                onViewHistory={() => setViewHistoryModalGoalType(goalType)}
                value={data.brivityActivities[goalType.id] || 0}
              />
            ))}
        </div>
        <div className="tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-5" />
        <div className="tw-flex tw-justify-between tw-items-center">
          <span className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold">Logged Activities</span>
          <Button schema="secondary" onClick={() => setNewLeadGenActivityOpen(true)}>
            + Log
          </Button>
        </div>
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-x-[20px] tw-gap-y-[36px] tw-pb-[20px]">
          {data?.goalTypes
            .filter(({ goalSection }) => goalSection === "activity")
            .map((goalType) => (
              <LeadGenActivityCard
                key={goalType.id}
                isButton
                label={goalType.displayName}
                goalValue={data.goals[goalType.id]?.goalValue || 0}
                onClick={() => setNewLeadGenActivityOpenForType(String(goalType.id))}
                onViewHistory={() => setViewHistoryModalGoalType(goalType)}
                value={data.loggedActivities[goalType.id] || 0}
              />
            ))}
        </div>
      </div>

      <NewLeadGenActivityModal
        goalTypes={(data?.goalTypes || []).filter(({ goalSection }) => goalSection === "activity")}
        open={newLeadGenActivityOpen}
        openForType={newLeadGenActivityOpenForType}
        onClose={() => {
          setNewLeadGenActivityOpen(false);
          setNewLeadGenActivityOpenForType(null);
        }}
        onSubmit={actions.createAgentActivity}
      />

      <ViewActivityHistoryModal
        userUuids={userUuids}
        goalType={viewHistoryModalGoalType}
        onClose={() => setViewHistoryModalGoalType(null)}
      />

      <Error className="tw-mt-[20px]" show={Boolean(error)} onClose={() => actions.setError(null)} />
      {loading && <Loader />}
    </div>
  );
};

LeadGenActivities.propTypes = {
  userUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.shape({ admin: PropTypes.bool, id: PropTypes.number }),
};

LeadGenActivities.defaultProps = {
  currentUser: null,
};

export default LeadGenActivities;
