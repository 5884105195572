import React from "react";
import Avatar from "../../../shared/v2/Avatar";

import { Section, SectionLabel } from "../BusinessDashboard/Section";
import Dash from "./Dash";
import { Activities } from "./helpers";
import "./leaderboards.scss";
import Loader from "../../../Dashboard/components/Loader";

const TeamLeaderboard = ({ users = [], selectedActivity, isPlaceUser, isLoading }) => {
  const activityName = Activities.find(({ key }) => key === selectedActivity).shortName;
  const filteredUsers = users.slice(0, 5);

  return (
    <Section className="tw-flex-1 !tw-p-32px !bs-xs:tw-p-32px">
      <SectionLabel>
        Top {filteredUsers.length} Rankings {Dash}{" "}
        {Activities.find(({ key }) => key === selectedActivity).fullName}
      </SectionLabel>
      <div className="tw-relative tw-flex tw-flex-row tw-pb-2px tw-pt-16px">
        {filteredUsers.map((user) => (
          <UserPortrait
            key={user["user_id"]}
            name={`${user["first_name"]} ${user["last_name"]}`}
            avatar={user["avatar_url"]}
            rank={user[selectedActivity].rank}
            activityCount={user[selectedActivity].count}
            activityName={activityName}
            isPlaceUser={isPlaceUser}
          />
        ))}
        {isLoading && <Loader />}
      </div>
    </Section>
  );
};

export default TeamLeaderboard;

const UserPortrait = ({ name, avatar, rank, activityCount, activityName, isPlaceUser }) => {
  const rankMargin = rank ? "tw-left-[-4px]" : "tw-left-[-8px]";
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-end tw-min-h-[182px] tw-border-solid tw-border-[1px] tw-border-y-0 tw-border-l-0 tw-border-neutral-gray-10 last-of-type:tw-border-0">
      <div className="tw-mx-[32px]">
        <Avatar size="none" src={avatar} alt={name} className="tw-w-[58.5px] tw-h-[58.5px]">
          {name}
        </Avatar>
        <div className={isPlaceUser ? "diamond" : "diamond-brivity"}>
          <span
            className={`tw-z-[1] tw-relative ${rankMargin} tw-top-[8px] tw-text-white tw-text-16px tw-font-bold`}
          >
            {rank || Dash}
          </span>
        </div>
      </div>
      <div className="tw-mb-8px tw-w-[96px] tw-text-gray-75 tw-text-12px tw-text-center tw-font-semibold tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">
        {name}
      </div>
      <div className="tw-mb-8px">
        <span className="tw-text-gray-75 tw-text-18px tw-font-bold">{activityCount}</span>
      </div>
      <div>
        <span className="tw-text-gray-75 tw-text-12px tw-font-normal">{activityName}</span>
      </div>
    </div>
  );
};
