import React, { Component } from "react";
import { DeleteNoteModal, EditNoteModal } from "../../../Transactions/components/Notes/helpers/forms";
import Linkify from "linkify-react";

const mentionMap = new Map();

export default class PublicActivityNoteEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showDiscardModal: false,
      showDeleteModal: false,
      markdown_content: props.event.object_attributes.markdown_content,
      note: {
        content: this.calculateNoteContent(props.event.object_attributes.markdown_content),
        notable_id: props.event.object_attributes.notable_id,
        notable_type: props.event.object_attributes.notable_type,
        id: props.event.object_attributes.id,
        starred: props.event.object_attributes.starred,
        externally_visible: props.event.object_attributes.externally_visible,
        created_by: props.event.object_attributes.created_by,
      },
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event.object_attributes !== this.props.event.object_attributes) {
      this.setState({
        note: { content: this.calculateNoteContent(this.props.event.object_attributes.markdown_content) },
      });
    }
  }

  calculateNoteContent(content) {
    return content.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, name, id) => {
      mentionMap.set(id, name);
      return `${window.location.host}/people/${id}`;
    });
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  submitEditNoteForm = (note) => {
    this.props.updateNote(note);
    this.setState({
      note: { ...note, content: this.calculateNoteContent(note.content) },
      markdown_content: note.content,
    });
    this.hideModal();
  };

  setShowDiscardModal = (showDiscardModal) => {
    this.setState({
      showDiscardModal,
    });
  };

  setShowDeleteModal = (showDeleteModal) => {
    this.setState({
      showDeleteModal,
    });
  };

  handleDelete = (note) => {
    this.props.deleteNote(note);
    this.setState({
      showDeleteModal: false,
      showModal: false,
    });
  };

  render() {
    return (
      <div>
        <div className="timeline-event" onClick={this.showModal}>
          <div className="row">
            <div className="col-xs-1">
              <i className="fa fa-pencil-square-o timeline-icon-circle" />
            </div>

            <div className="col-xs-8">
              <div>
                {this.state.note.created_by === "gabbi" ? (
                  <>
                    <span className="bold-detail">AI Assistant</span> added a note
                  </>
                ) : (
                  <>
                    <span className="bold-detail">
                      {this.props.event.agent_person.first_name} {this.props.event.agent_person.last_name}
                    </span>{" "}
                    added a note
                  </>
                )}
              </div>
              <div className="timeline-description-text">
                Contents:&nbsp;
                <Linkify
                  className="tw-whitespace-pre-wrap tw-break-words"
                  tagName="span"
                  options={{
                    defaultProtocol: "https",
                    target: "_blank",
                    render: {
                      url: ({ attributes, content }) => {
                        const potentialMentionId = content.split("/").pop();
                        return (
                          <a
                            className="tw-text-theme-primary"
                            onClick={(e) => e.stopPropagation()}
                            {...attributes}
                          >
                            {mentionMap.has(potentialMentionId)
                              ? `@${mentionMap.get(potentialMentionId)}`
                              : content}
                          </a>
                        );
                      },
                    },
                  }}
                >
                  {this.state.note.content}
                </Linkify>
              </div>
            </div>
            <div className="col-xs-3 time-ago">
              <span>{this.props.event.interacted_at_absolute}</span>
            </div>
          </div>
        </div>

        {this.state.showModal && (
          <EditNoteModal
            isEditing
            notableId={this.props.event.object_attributes.notable_id}
            notableType="Person"
            note={{ ...this.state.note, content: this.state.markdown_content }}
            onClose={this.hideModal}
            onDelete={() => this.setShowDeleteModal(true)}
            updateNote={this.submitEditNoteForm}
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteNoteModal
            onClose={() => this.setShowDeleteModal(false)}
            onDelete={() => this.handleDelete(this.props.event.object_attributes)}
          />
        )}
      </div>
    );
  }
}
