import axios from "axios";
import React, { useState } from "react";
import SuperUsersTable from "./SuperUsersTable";
import NewSuperUserForm from "./NewSuperUserForm";
import EditSuperUserForm from "./EditSuperUserForm";
import SuperUserRequests from "./SuperUserRequests";
import HeaderControls from "./HeaderControls";
import PaginationWrapper from './PaginationWrapper';

const SuperUsersIndex = (props) => {
  const { backUrl, roleOptions } = props;

  const [superUsers, setSuperUsers] = useState(props.superUsers);

  const [editingSuperUser, setEditingSuperUser] = useState(undefined);

  const [formErrors, setFormErrors] = useState(undefined);

  const [paginationInfo, setPaginationInfo] = useState(props.paginationInfo);

  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  const [currentRoleFilter, setCurrentRoleFilter] = useState("");

  const SuperUserPanelViews = {
    SUPER_USERS_TABLE: "Super Users Table",
    NEW_SUPER_USER_FORM: "New Super Users Form",
    EDIT_SUPER_USER_FORM: "Edit Super Users Form"
  };

  const [panelView, setPanelView] = useState(
    SuperUserPanelViews.SUPER_USERS_TABLE
  );

  const showTableView = () => {
    setFormErrors(undefined);

    setPanelView(SuperUserPanelViews.SUPER_USERS_TABLE);
  }

  const refresh = async (pageNum = 1) => {
    const response = await SuperUserRequests.index(pageNum);

    setSuperUsers(response.super_users);

    setPaginationInfo(response.pagination_info);
  }

  const onSubmitCreate = async (params) => {
    try {
      await SuperUserRequests.create(params);

      await refresh();

      showTableView();
    } catch (e) {
      setFormErrors(e.response.data);
    }
  };

  const onSubmitUpdate = async (id, params) => {
    try {
      await SuperUserRequests.update(id, params)

      await refresh();

      showTableView();
    } catch (e) {
      setFormErrors(e.response.data);
    }
  }

  const onCancelForm = (e) => {
    e.preventDefault();

    showTableView();
  };

  const onRemoveSuperUser = async (superUserId) => {
    try {
      await SuperUserRequests.destroy(superUserId);

      await refresh();
    } catch (e) {
      // flash warning?
    }
  }

  const onSearchUsers = async (searchTerm, roleFilter) => {
    setCurrentSearchTerm(searchTerm);
    setCurrentRoleFilter(roleFilter);
    try {
      if (searchTerm === "" && roleFilter === "") {
        await refresh();
      } else {
        const response = await SuperUserRequests.search(searchTerm, roleFilter);
        setSuperUsers(response.super_users);

        setPaginationInfo(response.pagination_info);
      }
    } catch (e) {
      // flash warning?
    }
  }

  const onEditSuperUser = (superUser) => {
    setFormErrors(undefined);

    setEditingSuperUser(superUser);

    setPanelView(SuperUserPanelViews.EDIT_SUPER_USER_FORM);
  };

  const onGoToPage = async (pageNum) => {
    const response = await SuperUserRequests.search(currentSearchTerm, currentRoleFilter, pageNum);

    setSuperUsers(response.super_users);

    setPaginationInfo(response.pagination_info);
  };

  return (
    <div className="panel">
      <div className="panel-header">Super Users</div>

      <div className="panel-body">
        {
          panelView === SuperUserPanelViews.SUPER_USERS_TABLE &&
            <div>
              <HeaderControls
                backUrl={backUrl}
                onAddSuperUser={
                  () => setPanelView(SuperUserPanelViews.NEW_SUPER_USER_FORM)
                }
                onSearch={onSearchUsers}
                roleOptions={roleOptions}
              />
              <SuperUsersTable
                superUsers={superUsers}
                onRemove={onRemoveSuperUser}
                onEdit={onEditSuperUser}
              />
              <PaginationWrapper
                paginationInfo={paginationInfo}
                onGoToPage={onGoToPage}
              />
            </div>
        }
        {
          panelView === SuperUserPanelViews.NEW_SUPER_USER_FORM &&
            <NewSuperUserForm
              onCancel={onCancelForm}
              onSubmit={onSubmitCreate}
              roleOptions={roleOptions}
              formErrors={formErrors}
            />
        }
        {
          panelView === SuperUserPanelViews.EDIT_SUPER_USER_FORM &&
            <EditSuperUserForm
              onCancel={onCancelForm}
              onSubmit={onSubmitUpdate}
              roleOptions={roleOptions}
              formErrors={formErrors}
              superUser={editingSuperUser}
            />
        }
      </div>
    </div>
  );
}

export default SuperUsersIndex;
