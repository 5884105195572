import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown/Dropdown";

const AssociatedTransaction = ({
  availableTransactions,
  associatedTransaction,
  SetAssociatedTransaction,
}) => (
  <div>
    <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
      <div className="tw-col-span-2">
        <Dropdown
          menuShouldComeToFront
          value={associatedTransaction}
          options={availableTransactions}
          onChange={(value) => SetAssociatedTransaction(value)}
          label="Associated Transaction"
          isSearchable
          toolTipInfoContent={
            <div className="tw-w-[406px] tw-text-left tw-font-normal tw-leading-[20px] tw-text-[14px]">
              If this agreement is for an existing transaction, select it here
            </div>
          }
          toolTipPlacement="top"
        />
      </div>
    </div>
  </div>
);

AssociatedTransaction.propTypes = {
  availableTransactions: PropTypes.arrayOf(PropTypes.shape({})),
  associatedTransaction: PropTypes.shape({}),
  SetAssociatedTransaction: PropTypes.func,
};
AssociatedTransaction.defaultProps = {
  availableTransactions: [],
  associatedTransaction: null,
  SetAssociatedTransaction: () => {},
};
export default AssociatedTransaction;
