import React from "react";
import Button from "./Button";
import UserListing from "./UserListing";

const RemoveableUser = ({ id, name, imageUrl, image, onClick, block = false }) => (
  <span className={!block ? "tw-inline-flex" : "tw-flex tw-my-2"}>
    <UserListing name={name} imageUrl={imageUrl} image={image} />
    <Button className="tw-ml-4" type="default" onClick={onClick} icon="fa-close" className="tw-text-center" />
  </span>
);

export default RemoveableUser;
