import * as creators from "./creators";
import { getBusinessSettingsApi, saveBusinessSettingsApi, removeBusinessImageApi } from "../services";

export const loadBusinessSettings = () => async (dispatch) => {
  dispatch(creators.getBusinessSettings());
  const onFailure = (error) => dispatch(creators.getBusinessSettingsError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.getBusinessSettingsSuccess({ data: response.data }));
  };
  return getBusinessSettingsApi({ successCb: onSuccess, errorCb: onFailure });
};

export const saveValue = (key, value) => async (dispatch) => {
  const m = { account: {} };
  m.account[key] = value !== undefined ? value : null;
  dispatch(creators.saveBusinessSettings());
  const onFailure = (error) => dispatch(creators.saveBusinessSettingsError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.saveBusinessSettingsSuccess({ data: response.data }));
  };
  return saveBusinessSettingsApi({ data: m, successCb: onSuccess, errorCb: onFailure });
};

export const removeImage = (isTeam) => async (dispatch) => {
  dispatch(creators.removeBusinessImage());
  const onFailure = (error) => dispatch(creators.removeBusinessImageError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.removeBusinessImageSuccess({ data: response.data }));
  };
  return removeBusinessImageApi({ isTeam, successCb: onSuccess, errorCb: onFailure });
};

export const saveOffice = (model) => async (dispatch) => {
  const m = { account: model };
  dispatch(creators.addOffice());
  const onFailure = (error) => dispatch(creators.addOfficeError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.addOfficeSuccess({ data: response.data }));
  };
  return saveBusinessSettingsApi({ data: m, successCb: onSuccess, errorCb: onFailure });
};
