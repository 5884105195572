import React from "react";
import { useSelector } from "react-redux";

const getFontColor = (env) => {
  switch (env) {
    case "development":
      return "tw-text-brand-warning";
    case "staging":
      return "tw-text-brand-danger";
    case "acceptance":
      return "tw-text-brand-info";
    default:
      return "tw-text-[#777777]";
  }
};

const getLogoText = (env) => {
  switch (env) {
    case "development":
      return "Dev";
    case "staging":
      return "Stage";
    case "acceptance":
      return "Acceptance";
    default:
      return "Brivity";
  }
};

const Logo = () => {
  const env = useSelector(({ layout }) => layout.env);
  const theme = useSelector(({ layout }) => layout.theme);
  return (
    <a className="tw-flex tw-gap-[8px]" href="/tasks/due_today">
      <img src={theme.logo} alt="Logo" className="tw-max-h-[28px] tw-max-w-full" />
      {theme.name === "brivity-theme" && (
        <h3 className={`tw-m-0 tw-font-helvetica tw-uppercase ${getFontColor(env)}`}>{getLogoText(env)}</h3>
      )}
    </a>
  );
};

export default Logo;
