import PropTypes from "prop-types";

const paginationShape = PropTypes.shape({
  current: PropTypes.number,
  next: PropTypes.number,
  previous: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
});

export default paginationShape;
