import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import button from "../button.module.css";
import modal from "../modal.module.css";
import appointments from "../appointments.module.css";
import HeaderTitle from "./HeaderTitle";
import axios from "axios";
import TaskModal from "../tasks/TaskModal";

class ViewAllAppointmentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAppointments: [],
      selectedTask: null,
      editAppointment: false,
    };
    this.getAllAppointments(this.props.person.data.attributes.uuid);
  }

  getAllAppointments = (id) => {
    axios
      .get("/tasks/appointments", {
        params: { id, limit: 100 },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        this.getAllAppointmentsSuccess(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllAppointmentsSuccess = (data) => {
    this.setState({ ...this.state, allAppointments: data.tasks });
  };

  editTask = (task) => this.setState({ selectedTask: task, editAppointment: true });

  closeTaskDetails = () => this.setState({ editAppointment: false });
  render() {
    const allAppointments = this.state.allAppointments;
    return (
      <>
        <Modal
          id="view-all-appointments-modal"
          className="person-details-modal"
          show={!this.state.editAppointment}
          onHide={this.props.onClose}
        >
          <Modal.Header>
            <Modal.Title>
              <HeaderTitle title="Appointments" onClose={this.props.onClose} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={modal.modalBodyContainer}>
              {allAppointments.map((object_attributes) => (
                <Row key={object_attributes.id} className={appointments.appointmentRow}>
                  <Col xs={10}>
                    <div className="pull-left">
                      <i className={`${appointments.checkMark} fa fa-calendar-check-o`} />
                    </div>
                    <div className={`${appointments.content} pull-right`}>
                      <div className="overflow">
                        <div className="fade-out tw-top-0" />
                      </div>
                      <p>
                        {" "}
                        <strong>Name</strong> {object_attributes.name}
                      </p>
                      <p>
                        {" "}
                        <strong>Due</strong>{" "}
                        {moment(object_attributes.due_on).format("MMMM D, YYYY [at] h:mm A")}{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Appointment Type:</strong>
                        {object_attributes.appointment_type}{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Outcome:</strong> {object_attributes.outcome}{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Location:</strong> {object_attributes.location}{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Notes:</strong> {object_attributes.note}{" "}
                      </p>
                    </div>
                  </Col>
                  <button
                    className={`${button.btn} ${button.btnLg} ${button.btnBlue} pull-right m-r-10`}
                    onClick={() => this.editTask(object_attributes)}
                  >
                    Edit
                  </button>
                  <Col xs={2} className="text-right">
                    <span>{object_attributes.logged_ago}</span>
                  </Col>
                </Row>
              ))}
            </div>
            <Row>
              <Col xs={12}>
                <a
                  className={`${button.btn} ${button.btnLg} ${button.btnBlue} pull-right`}
                  onClick={this.props.onClose}
                >
                  {" "}
                  DONE{" "}
                </a>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {this.state.editAppointment && (
          <TaskModal
            closeTaskModal={this.closeTaskDetails}
            agents={this.props.agents}
            account_uuid={this.props.account_uuid}
            user={this.props.user}
            taskable={this.props.taskable}
            accountMilestoneNames={this.props.accountMilestoneNames}
            task={this.state.selectedTask}
            updateTask={(task) => {
              this.setState({
                allAppointments: this.state.allAppointments.map((t) => (t.id === task.id ? task : t)),
              });
              this.props.updateTask({ ...task });
            }}
          />
        )}
      </>
    );
  }
}

export default ViewAllAppointmentsModal;
