import React from "react";
import PropTypes from "prop-types";
import format from "@shared/formatters";

const HomeInfoOverlay = ({ timelineFields }) => (
  <div className="tw-relative tw-flex tw-justify-center tw-items-center tw-w-[260px] tw-h-[150px] tw-rounded-[4px] tw-overflow-hidden">
    {timelineFields.photo && (
      <img className="tw-w-full tw-h-full tw-object-cover" src={timelineFields.photo} alt="Property" />
    )}
    <div className="tw-flex tw-flex-col tw-gap-[4x] tw-justify-end tw-absolute tw-inset-0 tw-bg-black/50 tw-p-[16px] tw-text-white">
      {timelineFields.price && <div className="tw-text-18d">{format.currency(timelineFields.price, 0)}</div>}
      <div>
        <div>{timelineFields.street_address}</div>
        <div>
          {timelineFields.city}, {timelineFields.state} {timelineFields.postal_code}
        </div>
        <div>
          {typeof timelineFields.beds === "number" && <span>{timelineFields.beds} Bed</span>}{" "}
          {typeof timelineFields.baths === "number" && <span>{timelineFields.baths} Bath</span>}
        </div>
      </div>
    </div>
  </div>
);

HomeInfoOverlay.propTypes = {
  timelineFields: PropTypes.shape({
    baths: PropTypes.number,
    beds: PropTypes.number,
    city: PropTypes.string,
    photo: PropTypes.string,
    postal_code: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    state: PropTypes.string,
    street_address: PropTypes.string,
  }).isRequired,
};

export default HomeInfoOverlay;
