import axios from "axios";
import { tryCatchHandlr } from "@shared/Utilities"

export const getEnvoyReferralsFormDataApi = async(personId) => {
  const [res, err] = await tryCatchHandlr(axios.get(`/api/v4/contact_people/${personId}/envoy_referrals/form_options`))

  return {res, err}
};

export const postEnvoyReferralApi = async(personId, data) => {
  const [res, err] = await tryCatchHandlr(axios.post(`/api/v4/contact_people/${personId}/envoy_referrals`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-csrf-token": window.ReactOnRails.authenticityToken(),
    }}))

  return {res, err}
}
