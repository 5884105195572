import React from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "./FieldLabelV1";
import TextInput from "../v2/TextInput/TextInput";

function FieldEmailV1({ className, placeholder, onChange, required, label, value, flexStyle, disabled }) {
  const isValidEmail = (email) => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) !== null
    );
  };

  return (
    <div className={flexStyle} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required }} />}
      <TextInput
        placeholder={placeholder}
        className={className}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        value={value ? value : ""}
      />
    </div>
  );
}
FieldEmailV1.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  flexStyle: PropTypes.string,
  disabled: PropTypes.bool,
};

FieldEmailV1.defaultProps = {
  className: "",
  placeholder: "",
  onChange: () => {},
  required: false,
  label: "",
  value: "",
  flexStyle: "tw-flex-1",
  disabled: false,
};
export default FieldEmailV1;
