import React from "react";

export default function RemoveablePill({ onClick, label }) {
  return (
    <small className="tw-bg-gray-10 tw-rounded-3xl tw-px-3 tw-py-1 tw-mt-2 tw-mr-2 tw-inline-block tw-text-xs">
      {label}
      <button
        onClick={(e) => {
          e.preventDefault();
          onClick();
        } }
        className="tw-p-0 tw-bg-transparent tw-border-0 tw-ml-1 tw-inline-block"
      >
        <i className="fa fa-remove" />
      </button>
    </small>
  );
}