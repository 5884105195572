import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Input from '../../../shared/Input';
import { radioButtonClass } from '../../../shared/ListingAlertHelpers';
import form from '../form.module.css';
import properties from './properties.module.css'
import ButtonDropDown from "../ButtonDropDown";

const PropertyFilters = ({ isFavorited, toggleButtons, allViewCount, favoriteCount, sortBy, handleSelectSortBy }) => {
  const buttonOptions = [{ label: 'Most Recent', value: 'most_recent' }, { label: 'Most Views', value: 'most_views' }, { label: 'Oldest', value: 'oldest' }]

  return (
    <Row className={`${properties.filters} !tw-mb-24px tw-pb-24px tw-border-0 tw-border-solid tw-border-b tw-border-neutral-gray-10`}>
      <Col xs={12} className="form-group">
        <ButtonDropDown
          value={sortBy}
          options={buttonOptions}
          handleSelect={handleSelectSortBy}
        />
        <div className={`${form.fauxRadioButton} ${properties.viewOrFavorite}`} style={{ marginLeft: "35px" }}>
          <Input
            id='unique-views'
            onClick={() => toggleButtons()}
            value={!isFavorited}
          />
          <label htmlFor='unique-views' className={radioButtonClass(!isFavorited)} />
          <span className={properties.filterText}>Unique Properties Viewed ({allViewCount})</span>
        </div>
        <div className={`${form.fauxRadioButton} ${properties.viewOrFavorite}`}>
          <Input
            id='favorites'
            onClick={() => toggleButtons()}
            value={isFavorited}
          />
          <label htmlFor='favorites' className={radioButtonClass(isFavorited)} />
          <span className={properties.filterText}>Favorites ({favoriteCount})</span>
        </div>
      </Col>
    </Row>
  );
};

export default PropertyFilters;
