import React from 'react';
import css from './report-styles.module.css';

class StatusFilter extends React.Component {
  render() {
    const { value, label, onChange, isChecked } = this.props;
    return (
      <div className='checkbox' style={{marginRight: '40px', marginTop: '10px'}}>
        <input
          type='checkbox'
          id={value}
          value={value}
          onChange={(e) => onChange(value, e.target.checked)}
          checked={isChecked} />

        <label className={css.checkBoxLabel} htmlFor={value} style={{color: '#666',paddingLeft: '20px',fontSize: '14px'}}>{label}</label>
    </div>
    )
  }
}
export default StatusFilter;
