import React, { useState, useRef, useEffect } from "react";

import PageBar from "./PageBar";
import { useViewSize } from "./customHooks";
import SearchListingsTable from "./SearchListingsTable";
import SearchMap from "./SearchMap";
import ListingGrid from "./ListingGrid";

const Listings = ({
  listings,
  listingCount,
  searchModel,
  selectedListings,
  onToggleShare,
  maxListingsSelected,
  applySort,
  openListingDetailsModal,
  openAgentDetailsModal,
  agentDetailsEnabled,
  updateSearchPage,
  viewOptions,
  isSearching,
  viewedListings,
  buyerMatches
}) => {
  const listingsContainer = useRef(null);
  const [listingsContainerHeight, setListingsContainerHeight] = useState(null);
  const [, viewHeight] = useViewSize();
  const [highlightedListing, setHighlightedListing] = useState(null);

  const shouldShowPageBar = () => listingCount > searchModel.queryOptions.limit;

  useEffect(() => {
    if (listingCount) {
      const pageBarHeight = shouldShowPageBar() ? 64 : 0;
      const unavailableSpace = listingsContainer?.current?.getBoundingClientRect()?.top;

      const heightAvailable = viewHeight - (unavailableSpace + pageBarHeight);
      setListingsContainerHeight(heightAvailable);
    }
  }, [listingCount, viewHeight]);

  return (
    <div className="tw-flex tw-flex-row">
      {isSearching && <Loading height={listingsContainerHeight + (shouldShowPageBar() ? 96 : 32)} />}
      {viewOptions.map && (
        <div className="tw-flex tw-flex-col tw-pr-10px tw-w-1/2">
          <SearchMap
            listings={listings}
            viewedListings={viewedListings}
            onClickListing={(listingId) => setHighlightedListing(listingId)}
            onUserDragMap={(polygon) => {}}
            onToggleShare={onToggleShare}
            maxListingsSelected={maxListingsSelected}
            selectedListings={selectedListings}
          />
        </div>
      )}
      <div className={`tw-flex tw-flex-col tw-px-10px ${viewOptions.map ? "tw-w-1/2" : "tw-w-full"}`}>
        <div ref={listingsContainer}>
          {viewOptions.grid ? (
            <ListingGrid
              listings={listings}
              onShowDetails={openListingDetailsModal}
              selectedListings={selectedListings}
              onToggleShare={onToggleShare}
              maxListingsSelected={maxListingsSelected}
              highlightedListing={highlightedListing}
              availableHeight={listingsContainerHeight}
              buyerMatches={buyerMatches}
            />
          ) : (
            <SearchListingsTable
              mlsListings={listings}
              highlightedListing={highlightedListing}
              selectedListings={selectedListings}
              onToggleShare={onToggleShare}
              maxListingsSelected={maxListingsSelected}
              availableHeight={listingsContainerHeight}
              onSort={applySort}
              currentSort={searchModel.getSort()}
              onShowDetails={openListingDetailsModal}
              onShowAgentDetails={openAgentDetailsModal}
              agentDetailsEnabled={agentDetailsEnabled}
              buyerMatches={buyerMatches}
            />
          )}
        </div>
        {shouldShowPageBar() && (
          <PageBar
            totalCount={listingCount}
            currentOffset={searchModel.queryOptions.offset}
            pageSize={searchModel.queryOptions.limit}
            onChangePage={updateSearchPage}
          />
        )}
      </div>
    </div>
  );
};

export default Listings;

const Loading = ({ height }) => {
  const style = { height: `${height}px` };

  return (
    <div
      style={style}
      className="tw-absolute tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-bg-opacity-50 tw-z-50"
    >
      <i className="tw-text-36px tw-text-gray-50 fa fa-spinner fa-spin" />
    </div>
  );
};
