/* eslint-disable no-underscore-dangle */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import RemoveFieldButton from "@shared/v2/RemoveFieldButton";
import Dropdown from "@shared/v2/Dropdown";
import TextInput from "@shared/v2/TextInput";

function AddressView({
  removable,
  currentAddress,
  options,
  stateOptions,
  onRemoveAddressHandlr,
  onTypeHandlr,
  onStreetHandlr,
  onCityHandlr,
  onPostalCodeHandlr,
  onLocalityHandlr,
}) {
  const {
    address_type: addressType,
    city,
    postal_code: postalCode,
    street_address: streetAddress,
    locality,
  } = currentAddress;

  const allOptions = useMemo(
    () =>
      [].concat(
        ...stateOptions.map((category) =>
          category.options.map((option) => ({ label: option.value, value: option.value })),
        ),
      ),
    [stateOptions],
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px]">
      <div className="tw-flex tw-gap-2">
        <Dropdown
          containerClassName="tw-w-full"
          label="Address Type"
          options={options}
          onChange={onTypeHandlr}
          value={options.find((o) => o.value === addressType)}
          placeholder="Choose One"
        />

        {removable && (
          <RemoveFieldButton onClick={onRemoveAddressHandlr} className="tw-mt-auto tw-mb-[1px]" />
        )}
      </div>
      <TextInput label="Street Address" onChange={onStreetHandlr} value={streetAddress} />
      <div className="tw-flex tw-gap-6">
        <TextInput containerClassName="tw-flex-1" label="City" onChange={onCityHandlr} value={city} />
        <div className="tw-flex-1 tw-flex tw-gap-3">
          <Dropdown
            containerClassName="tw-flex-1"
            label="State / Province"
            options={stateOptions}
            onChange={onLocalityHandlr}
            value={allOptions.find((o) => o.value === locality)}
            placeholder="Select"
            isSearchable
            menuShouldComeToFront
          />
          <TextInput label="ZIP / Postal Code" onChange={onPostalCodeHandlr} value={postalCode} />
        </div>
      </div>
      <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
    </div>
  );
}
const addressPropType = PropTypes.shape({
  address_type: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  street_address: PropTypes.string,
  postal_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  _destroy: PropTypes.bool,
});
AddressView.propTypes = {
  removable: PropTypes.bool,
  currentAddress: addressPropType,
  onRemoveAddressHandlr: PropTypes.func,
  onTypeHandlr: PropTypes.func,
  onStreetHandlr: PropTypes.func,
  onCityHandlr: PropTypes.func,
  onPostalCodeHandlr: PropTypes.func,
  onLocalityHandlr: PropTypes.func,
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    }),
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

AddressView.defaultProps = {
  removable: false,
  currentAddress: null,
  options: [],
  onRemoveAddressHandlr: () => {},
  onTypeHandlr: () => {},
  onStreetHandlr: () => {},
  onCityHandlr: () => {},
  onPostalCodeHandlr: () => {},
  onLocalityHandlr: () => {},
};
export default AddressView;
