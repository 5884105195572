/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CaretUp = (props) => (
  <Icon {...props} name="Caret Up">
    <path d="M12.887 11.294H3.113c-.676 0-1.014-.84-.536-1.332l4.885-5.028a.746.746 0 0 1 1.075 0l4.885 5.028c.479.492.141 1.332-.536 1.332z" />
  </Icon>
);

export default CaretUp;
