import React from "react";
import { shape } from "prop-types";

import Avatar from "@shared/v2/Avatar";
import Tooltip from "@shared/v2/Tooltip";

const TaskAvatar = ({ assignedPerson }) => (
  <div className="tw-flex tw-justify-center tw-h-32px tw-mr-[25px] tw-w-[32px]">
    <Tooltip
      content={assignedPerson?.fullName ?? "Anyone"}
      trigger={
        <Avatar
          size="medium-small"
          className="tw-cursor-pointer"
          alt={assignedPerson?.fullName ?? "Anyone"}
          src={assignedPerson ? assignedPerson?.avatar && assignedPerson?.avatar : "/images/users/team.svg"}
        >
          {assignedPerson?.fullName ?? "Anyone"}
        </Avatar>
      }
    />
  </div>
);

TaskAvatar.propTypes = {
  assignedPerson: shape({}),
};

TaskAvatar.defaultProps = {
  assignedPerson: null,
};

export default TaskAvatar;
