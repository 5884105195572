import React from "react";
import PropTypes from "prop-types";
import { Check, Close } from "../Icomoon";
import Tooltip from "../Tooltip";
import styleSchema from "./CancelConfirm.styles";

const CancelConfirm = ({ onCancelEditing, onConfirmEditing }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-space-x-[12px] tw-pr-[4px]">
    <span className={styleSchema.divider} />
    <button
      type="button"
      className="tw-bg-transparent tw-border-0 tw-p-0"
      onClick={onCancelEditing}
      aria-label="cancel"
    >
      <Tooltip trigger={<Close className={styleSchema.cancel} size="m" />} content="Cancel" placement="top" />
    </button>
    <button
      type="button"
      className="tw-bg-transparent tw-border-0 tw-p-0"
      onClick={onConfirmEditing}
      aria-label="save"
    >
      <Tooltip trigger={<Check className={styleSchema.confirm} size="m" />} content="Save" placement="top" />
    </button>
  </div>
);

CancelConfirm.propTypes = {
  onCancelEditing: PropTypes.func.isRequired,
  onConfirmEditing: PropTypes.func.isRequired,
};

export default CancelConfirm;
