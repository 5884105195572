import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import ViewAllListingAlertsModal from "../modals/ViewAllListingAlertsModal";
import AddListingAlertModal from "../modals/AddListingAlertModal";
import EditListingAlertModal from "../modals/EditListingAlertModal";
import { getAlerts } from "./service";

export default function AllListingAlertPreview({ person, emailDetails, geoCodeAccountAddress }) {
  const [editListingAlertModalOpen, setEditListingAlertModalOpen] = useState(false);
  const [addListingAlertModalOpen, setAddListingAlertModalOpen] = useState(false);
  const [listingAlertsModalOpen, setListingAlertsModalOpen] = useState(false);
  const [indexOfAlertToEdit, setIndexOfAlertToEdit] = useState(0);
  const [listingAlerts, setListingAlerts] = useState([]);
  const [mlsIds, setMlsIds] = useState([]);
  const [websiteUrl, setWebsiteUrl] = useState("");

  const openListingAlertModal = async () => {
    if (emailDetails) {
      const response = await getAlerts(person.slug);
      setListingAlerts(response.data.alerts);
      setWebsiteUrl(response.data.website_url);
      setMlsIds(response.data.mls_ids);
      setListingAlertsModalOpen(true);
    }
  };

  const closeListingAlertModal = () => {
    setListingAlertsModalOpen(false);
  };

  const closeEditListingAlertModal = (status) => {
    setEditListingAlertModalOpen(false);
    setIndexOfAlertToEdit(0);
  };

  const deleteAlert = (alertIndex) => {
    const alertId = listingAlerts[alertIndex].alertId;
    const url = `/people/${person.id}/listing_alerts/${alertId}`;
    axios
      .delete(url, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
      .then((response) => {
        setListingAlerts(listingAlerts.filter((alert) => alert.alertId !== alertId));
      })
      .catch((response) => console.log(response));
  };

  const openEditFromViewAll = (alertIndex) => {
    setIndexOfAlertToEdit(alertIndex);
    setEditListingAlertModalOpen(true);
    setListingAlertsModalOpen(false);
  };

  const openAddFromViewAll = () => {
    setListingAlertsModalOpen(false);
    setAddListingAlertModalOpen(true);
  };

  const tooltipDescription = () => {
    const hasActiveIdxAlerts = person.active_idx_alerts && person.active_idx_alerts > 0;
    const hasIdxAlerts = person.idx_alerts && person.idx_alerts > 0;
    if (hasActiveIdxAlerts) {
      return "Listing Alerts sending";
    }
    if (hasIdxAlerts) {
      return "Not sending Listing Alerts";
    }
    return "No Listing Alerts";
  };

  const getColorStyle = () => {
    const hasActiveIdxAlerts = person.active_idx_alerts && person.active_idx_alerts > 0;
    const hasIdxAlerts = person.idx_alerts && person.idx_alerts > 0;
    if (hasActiveIdxAlerts) {
      return "active";
    }
    if (hasIdxAlerts) {
      return "inactive";
    }
    return "zero";
  };

  const getAlertCount = () => {
    return person.idx_alerts || "0";
  };

  const closeAddListingAlertModal = () => {
    setAddListingAlertModalOpen(false);
    setIndexOfAlertToEdit(0);
  };

  return (
    <div>
      {
        <div className={"flex-row d-flex space-evenly flex-justify-center " + (emailDetails && "pseudo-link")}>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={<Tooltip id="market-report-tooltip-wrap">{tooltipDescription()}</Tooltip>}>
            <h6 className={`tw-font-bold ${getColorStyle()}`} onClick={openListingAlertModal}>
              {getAlertCount()}
            </h6>
          </OverlayTrigger>
        </div>
      }
      {listingAlertsModalOpen && (
        <ViewAllListingAlertsModal
          onClose={closeListingAlertModal}
          listingAlerts={listingAlerts}
          openEditModal={openEditFromViewAll}
          handleDelete={deleteAlert}
          mlsIds={mlsIds}
          websiteUrl={websiteUrl}
          openAddModal={openAddFromViewAll}
        />
      )}
      {addListingAlertModalOpen && (
        <AddListingAlertModal
          person={person}
          closeListingAlertModal={closeAddListingAlertModal}
          mlsIds={mlsIds}
          geoCodeAccountAddress={geoCodeAccountAddress}
        />
      )}
      {editListingAlertModalOpen && (
        <EditListingAlertModal
          person={person}
          closeListingAlertModal={closeEditListingAlertModal}
          alert={listingAlerts[indexOfAlertToEdit]}
          mlsIds={mlsIds}
          geoCodeAccountAddress={geoCodeAccountAddress}
        />
      )}
    </div>
  );
}
