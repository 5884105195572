import React from "react";
import PropTypes from "prop-types";

const PreviewPlaceholder = ({ children }) => (
  <div
    // 612px and 792px are defined in the Figma designs
    className="tw-flex tw-justify-center tw-items-center tw-w-[612px] tw-h-[792px] tw-bg-white"
  >
    {children}
  </div>
);

PreviewPlaceholder.propTypes = {
  children: PropTypes.node,
};

PreviewPlaceholder.defaultProps = {
  children: null,
};

export default PreviewPlaceholder;
