import React from "react";
import Proptypes from "prop-types";
import RangeButtons from "../RangeButtons";

const BedsSelector = ({ value, onChange }) => (
  <div data-cy="bed-selector-wrapper" className="tw-mb-[24px]">
    <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Beds</span>
    <p className="tw-mb-[8px] tw-text-12d tw-text-gray-75">Select multiple options to create a range</p>
    <RangeButtons
      allowMultiple
      value={value}
      options={[
        { name: "Any", value: null },
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
        { name: "5+", value: 5 },
      ]}
      onChange={(min, max) => onChange(min, max)}
    />
  </div>
);

export default BedsSelector;

BedsSelector.propTypes = {
  value: Proptypes.arrayOf(Proptypes.number),
  onChange: Proptypes.func,
};

BedsSelector.defaultProps = {
  value: undefined,
  onChange: () => {},
};
