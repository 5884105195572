/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import React from "react";
import Select, { components as SelectComponents } from "react-select";
import Creatable from "react-select/creatable";
import { makePropsCompatible } from "./utils";
import { sharedStyles, variantStyles } from "./styles";

const DownChevron = ({ menuIsOpen, ...props }) => (
  <svg
    height={15}
    width={15}
    viewBox="5 2.5 15 15"
    aria-hidden="true"
    focusable="false"
    style={{ fill: menuIsOpen ? "rgb(153, 153, 153)" : "inherit" }}
    {...props}
  >
    <polygon points={menuIsOpen ? "5,12 10,7 15,12" : "5,7 10,12 15,7"} />
  </svg>
);

const DefaultDropdownIndicator = ({ selectProps: { menuIsOpen }, ...props }) => {
  const { className = "", ...otherProps } = props;

  return (
    <SelectComponents.DropdownIndicator className={`${className} tw-cursor-pointer`} {...otherProps}>
      <DownChevron menuIsOpen={menuIsOpen} />
    </SelectComponents.DropdownIndicator>
  );
};

const getStylesFor = (variant, stylesFromProps) =>
  stylesFromProps || {
    ...sharedStyles,
    ...variantStyles[variant],
  };

const getComponentsFor = (componentsFromProps) => ({
  ...componentsFromProps,
  DropdownIndicator: componentsFromProps?.DropdownIndicator || DefaultDropdownIndicator,
});

const AbstractSelect = (props, ConcreteSelectImplementation) => {
  const {
    components: componentsFromProps,
    styles: stylesFromProps,
    className,
    variant,
    ...otherProps
  } = props;

  const classNameWithPrefix = `react-select__container ${className}`;
  const components = getComponentsFor(componentsFromProps);
  const styles = getStylesFor(variant, stylesFromProps);
  const adaptedProps = makePropsCompatible(otherProps);

  return (
    <ConcreteSelectImplementation
      className={classNameWithPrefix}
      components={components}
      styles={styles}
      {...adaptedProps}
    />
  );
};

const Dropdown = (props) => AbstractSelect(props, Select);
Dropdown.defaultProps = { className: "", variant: "default" };

Dropdown.Creatable = (props) => AbstractSelect(props, Creatable);
Dropdown.Creatable.defaultProps = Dropdown.defaultProps;

export { Dropdown };
export default Dropdown;
