import React from "react";
import Proptypes from "prop-types";
import { tdpDates } from "../../../../shared/HOCs/withRedux";
import { DATE_LISTED } from "../../../../shared/reduxRoutes/tdpDatesRoutes";
import ModifyDate from "./ModifyDate";

const DateListed = ({ date_listed_milestone: dateListed }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[2px] tw-px-[12px] tw-h-[44px] tw-min-h-[44px] tw-rounded tw-bg-tinted-gray-50 tw-text-gray-75 tw-text-[14px] tw-font-semibold tw-leading-[20px]">
    Date Listed
    <ModifyDate date={dateListed} />
  </div>
);

DateListed.propTypes = {
  date_listed_milestone: Proptypes.shape({
    id: Proptypes.string,
    name: Proptypes.string,
    date: Proptypes.string,
  }),
};
DateListed.defaultProps = {
  date_listed_milestone: null,
};

const DateListedWrapped = tdpDates({
  WrappedComponent: DateListed,
  states: DATE_LISTED,
});

export default DateListedWrapped;
