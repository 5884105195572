import React from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import InlineEditingInput from "../InlineEditingInput";
import {
  extractNumeric,
  formatNumber,
  isValidPercentage,
  isValidValue,
  removeLeadingZeroes,
} from "../../inputFormatUtils";

const validateInput = (value) => {
  if (!value) {
    return { isValid: true, newValue: "" };
  }

  const newValue = removeLeadingZeroes(extractNumeric(value)) || "0";
  const isValid = isValidValue(newValue) && isValidPercentage(newValue);
  return { isValid, newValue };
};

const formatInput = (value) => formatNumber(value, true);

const Percent = ({ id, value, placeholder, onChange, withCancelConfirm, className, ...otherProps }) => (
  <InlineEditingInput
    id={id}
    value={value}
    suffix="%"
    placeholder={placeholder}
    onChange={onChange}
    additionalTrailing={<span>%</span>}
    withCancelConfirm={withCancelConfirm}
    spacing={0}
    className={className}
    size={placeholder?.length || InlineEditingInput.numericDefaultSize}
    validateInput={validateInput}
    formatInput={formatInput}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  />
);

Percent.propTypes = {
  id: PropTypes.string,
  value: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  withCancelConfirm: PropTypes.bool,
  className: PropTypes.string,
};

Percent.defaultProps = {
  id: null,
  placeholder: "",
  onChange: null,
  withCancelConfirm: true,
  className: "",
};

export default Percent;
