/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import RadioButton from "@shared/v2/RadioButton";
import { subtleM } from "@shared/styles";

import { Col, Radio, Input, Dropdown, Row, TextArea } from "../../shared/v1";
import {
  timeOptions,
  priorityOptions,
  weeklyOptions,
  monthlyOptions,
  recurringFrequencyOptions,
  delayFactorOptions,
  timeUnitOptionsForEvents,
  timeUnitOptions,
} from "./helpers";
import CCFor from "./CCFor";
import AiAssistant from "../../shared/AiAssistant/AiAssistant";
import IconButton from "../../shared/v2/IconButton/IconButton";
import { Close } from "../../shared/v2/Icomoon";

const Detail = ({ label, value, onChange }) => (
  <Col size={12}>
    <label>{label}</label>
    <Input
      size="small"
      name="name"
      onChange={(e) => onChange({ [e.target.name]: e.target.value })}
      value={value}
    />
  </Col>
);

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const InstructionsLink = ({ onShow }) => (
  <Col size={3}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
    <a className="add-link" href="javascript:void(0);" onClick={onShow}>
      <i className="fa fa fa-plus-square" />
      Add Instructions
    </a>
  </Col>
);

InstructionsLink.propTypes = {
  onShow: PropTypes.func.isRequired,
};

const NoteLink = ({ onShow }) => (
  <Col size={3}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
    <a className="add-link" href="javascript:void(0);" onClick={onShow}>
      <i className="fa fa fa-plus-square" />
      Add Notes
    </a>
  </Col>
);

NoteLink.propTypes = {
  onShow: PropTypes.func.isRequired,
};

const Instructions = ({ onHide, value, onChange }) => (
  <Col size={12}>
    <div className="tw-flex tw-justify-end tw-gap-[8px] tw-absolute tw-top-[-4px] tw-right-[10px]">
      <AiAssistant
        textareaId="actionplan-instructions"
        messageType="Generic"
        onInsertClick={(text) => onChange({ instructions: text })}
      />
      <IconButton size="small" schema="transparent" onClick={onHide}>
        <Close />
      </IconButton>
    </div>
    <label>Instructions</label>
    <TextArea
      id="actionplan-instructions"
      size="auto"
      name="instructions"
      value={value}
      onChange={(e) => onChange({ [e.target.name]: e.target.value })}
    />
  </Col>
);

Instructions.propTypes = {
  onHide: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Note = ({ onHide, value, onChange }) => (
  <Col size={12}>
    <label>Notes</label>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      className="toggle-close-link float-right"
      // eslint-disable-next-line no-script-url
      href="javascript:void(0);"
      onClick={onHide}
      aria-label="close"
    >
      <i className="fa fa fa-close" />
    </a>
    <TextArea
      size="auto"
      name="note"
      value={value}
      onChange={(e) => onChange({ [e.target.name]: e.target.value })}
    />
  </Col>
);

Note.propTypes = {
  onHide: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PriorityOptions = ({ value, onChange }) => (
  <div>
    <Col size={4}>
      <label>Priority (1: highest, 9: lowest)</label>
    </Col>
    <Col size={12}>
      {priorityOptions.map((op) => (
        <Radio
          key={op.value}
          name="priority"
          className="tw-mr-20px tw-pt-7px"
          labelClassName="tw-text-gray-base tw-text-[14px]"
          deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
          value={op.value}
          inline
          checked={op.value === value}
          onChange={(e) => onChange({ [e.target.name]: parseInt(e.target.value, 10) })}
        >
          {op.label}
        </Radio>
      ))}
    </Col>
  </div>
);

PriorityOptions.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const visibilityOptions = [
  {
    value: false,
    label: (
      <div>
        <i className="fa fa-eye-slash p-r-10" />
        <span className="text-base">Hidden from Viewers</span>
      </div>
    ),
  },
  {
    value: true,
    label: (
      <div>
        <i className="fa fa-eye p-r-10" />
        <span className="text-base">Visible to Viewers</span>
      </div>
    ),
  },
];

const VisibilitySection = ({ onChange, value }) => (
  <div>
    <Col size={8}>
      <label>Visibility</label>
      <Dropdown
        isSearchable={false}
        isClearable={false}
        onUpdate={(opt) => onChange({ visibility: opt.value })}
        value={value || false}
        name="visibility"
        options={visibilityOptions}
      />
    </Col>
  </div>
);

VisibilitySection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const anyoneOption = { label: "Anyone", value: "", name: "assigned_to_id", for_type: "all" };

const AssignTo = ({
  prependAnyoneOption,
  options: propsOptions,
  placeholder: propsPlaceholder,
  label,
  value,
  onChange,
}) => {
  const options = prependAnyoneOption ? [anyoneOption, ...propsOptions] : propsOptions;
  const placeholder = propsPlaceholder || "Select Role/User";

  return (
    <div>
      <Col size={6}>
        <label>{label}</label>

        <Dropdown
          placeholder={placeholder}
          onUpdate={(opt) => onChange(opt)}
          isClearable={false}
          value={value}
          options={options}
        />
      </Col>
    </div>
  );
};

AssignTo.propTypes = {
  prependAnyoneOption: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const RunTime = ({ label, value, onChange }) => (
  <div>
    <Col size={3}>
      <label>{label}</label>
      <div className="tw-w-[131px]">
        <Dropdown
          value={value}
          name="run_time"
          isClearable={false}
          onUpdate={(opt) => onChange({ run_time: opt.value })}
          options={timeOptions()}
        />
      </div>
    </Col>
  </div>
);

RunTime.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ComputedTime = ({ parentActionId, onChange, triggers }) => (
  <div>
    <div className="flex-grow">
      <Dropdown
        value={parentActionId || "Trigger"}
        onUpdate={(opt) => onChange({ parent_action_id: opt.value })}
        isSearchable={false}
        isClearable={false}
        name="parent_action_id"
        options={triggers}
      />
    </div>
  </div>
);

ComputedTime.propTypes = {
  parentActionId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  triggers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const Event = ({ delay, delayFactor, onChange, milestoneId, milestoneOptions }) => {
  const currentDelay = delay || 0;

  return (
    <div className="d-flex">
      <div style={{ width: "100px" }}>
        <Dropdown
          name="delay_factor"
          value={delayFactor}
          onUpdate={(opt) => onChange({ delay_factor: opt.value, delay: currentDelay * -1 })}
          isSearchable={false}
          isClearable={false}
          options={delayFactorOptions}
        />
      </div>
      <div className="p-l-5 flex-grow">
        <Dropdown
          value={milestoneId}
          onUpdate={(opt) => onChange({ milestone_id: opt.value })}
          isSearchable
          isClearable={false}
          name="milestone_id"
          options={milestoneOptions}
        />
      </div>
    </div>
  );
};

Event.propTypes = {
  delay: PropTypes.number.isRequired,
  delayFactor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  milestoneId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestoneOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const Schedule = ({ type, ...props }) => (
  <Col size={8}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {type === "event" && <Event {...props} />}
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {type === "computed_time" && <ComputedTime {...props} />}
  </Col>
);

Schedule.propTypes = {
  type: PropTypes.string.isRequired,
};

const ScheduleSelector = ({ scheduleType, onChange }) => (
  <div>
    <Col size={12}>
      <div className="d-flex p-b-10">
        <Radio
          key="computed_time"
          name="schedule_type"
          className="tw-mr-20px tw-pt-7px"
          labelClassName="tw-text-gray-base tw-text-[14px]"
          deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
          value="computed_time"
          inline
          checked={scheduleType === "computed_time"}
          onChange={(e) => onChange(e.target.value)}
        >
          Computed Time
        </Radio>
        <Radio
          key="event"
          name="schedule_type"
          className="tw-pt-7px"
          labelClassName="tw-text-gray-base tw-text-[14px]"
          deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
          value="event"
          inline
          checked={scheduleType === "event"}
          onChange={(e) => onChange(e.target.value)}
        >
          Event
        </Radio>
      </div>
    </Col>
  </div>
);

ScheduleSelector.propTypes = {
  scheduleType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Delay = ({
  delayFactor = 1,
  delayOnChange,
  delayValue,
  delayUnitValue,
  delayUnitOnChange,
  scheduleType,
}) => (
  <Col size={4}>
    <div className="d-flex flex-row">
      <Input
        size="small"
        type="number"
        className="tw-max-w-60px"
        min="0"
        name="delay"
        onChange={(e) => delayOnChange({ [e.target.name]: e.target.value * delayFactor })}
        value={Math.abs(delayValue)}
      />
      <div className="p-l-5 flex-grow">
        <Dropdown
          name="delay_unit"
          value={delayUnitValue}
          onUpdate={(opt) => delayUnitOnChange(opt.value)}
          searchable={false}
          clearable={false}
          options={scheduleType === "event" ? timeUnitOptionsForEvents : timeUnitOptions}
        />
      </div>
    </div>
  </Col>
);

Delay.propTypes = {
  delayFactor: PropTypes.number.isRequired,
  delayOnChange: PropTypes.func.isRequired,
  delayValue: PropTypes.number.isRequired,
  delayUnitValue: PropTypes.string.isRequired,
  delayUnitOnChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};

const DelayAndScheduleLabel = ({ delayLabel, scheduleLabel }) => (
  <Col size={12}>
    <Row>
      <Col size={4}>
        <label>{delayLabel}</label>
      </Col>

      <Col size={8}>
        <label>{scheduleLabel}</label>
      </Col>
    </Row>
  </Col>
);

DelayAndScheduleLabel.propTypes = {
  delayLabel: PropTypes.string.isRequired,
  scheduleLabel: PropTypes.string.isRequired,
};

const recurringOnOptions = (type) => {
  if (type === "weekly") {
    return weeklyOptions;
  }
  if (type === "monthly") {
    return monthlyOptions;
  }
  return [];
};

const recurringFrequencyAttributesToUpdate = (newFrequency) => {
  if (newFrequency === "never") {
    return { recurring_frequency: newFrequency, recurring_days_of_frequency: null, recurring_limit: "" };
  }
  return { recurring_frequency: newFrequency, recurring_days_of_frequency: null };
};

const daysOfFrequencyValueHelper = (value) => {
  if (value && value[0]) {
    return value[0];
  }
  return undefined;
};

const RecurringOptions = ({ onChange, frequencyValue, daysOfFrequencyValue, limitValue }) => (
  <div>
    <Col size={12}>
      <Row>
        <Col size={4}>
          <label>Recurring Frequency</label>
          <Dropdown
            placeholder="Select.."
            onUpdate={(opt) => onChange(recurringFrequencyAttributesToUpdate(opt.value))}
            clearable={false}
            value={frequencyValue}
            options={recurringFrequencyOptions}
          />
        </Col>

        {(frequencyValue === "weekly" || frequencyValue === "monthly") && (
          <Col size={4}>
            <label>Repeat On</label>
            <Dropdown
              placeholder="Select.."
              onUpdate={(opt) => onChange({ recurring_days_of_frequency: [opt.value] })}
              isClearable={false}
              value={daysOfFrequencyValueHelper(daysOfFrequencyValue)}
              options={recurringOnOptions(frequencyValue)}
            />
          </Col>
        )}
        {frequencyValue === "every" && (
          <Col size={4}>
            <label>Recur after how many days</label>
            <div>
              <Input
                size="small"
                type="number"
                min="1"
                max="999"
                name="recurring_limit"
                onChange={(e) => onChange({ recurring_days_of_frequency: [e.target.value] })}
                value={+daysOfFrequencyValueHelper(daysOfFrequencyValue)}
              />
            </div>
          </Col>
        )}
      </Row>
    </Col>

    {frequencyValue && frequencyValue !== "never" && (
      <Col size={12} className="p-t-5">
        <div>
          <label>Ends</label>
        </div>

        <div className="d-flex flex-row flex-items-center">
          <div style={{ width: "100px" }}>
            <Dropdown
              onUpdate={(opt) => onChange({ recurring_limit: opt.value })}
              isClearable={false}
              value={limitValue ? 1 : ""}
              options={[
                { value: "", label: "Never" },
                { value: 1, label: "After" },
              ]}
            />
          </div>

          {limitValue && (
            <div className="d-flex flex-items-center flex-grow">
              <div className="p-l-5">
                <Input
                  size="small"
                  type="number"
                  className="tw-max-w-60px"
                  min="0"
                  name="recurring_limit"
                  onChange={(e) => onChange({ recurring_limit: e.target.value })}
                  value={limitValue}
                />
              </div>
              <div className="p-l-10 flex-grow">occurences</div>
            </div>
          )}
        </div>
      </Col>
    )}
  </div>
);

RecurringOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  frequencyValue: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  daysOfFrequencyValue: PropTypes.arrayOf(PropTypes.any).isRequired,
  limitValue: PropTypes.string.isRequired,
};

const EmailCCSelector = ({
  planType,
  emails,
  label,
  name,
  onOptionSelect,
  onOptionRemove,
  onRadioChange,
  onToggleBCC,
  placeholder,
  roles,
  showAddBCC,
  type,
  people,
}) => (
  <div>
    <Col size={12}>
      <label>{label}</label>

      <div>
        <div className="tw-float-right" hidden={!showAddBCC}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="carbon-copy-link tw-text-brand-info" href="#" onClick={onToggleBCC}>
            <i className="fa fa-plus-circle" /> Add BCC
          </a>
        </div>

        <div className="d-flex p-b-10">
          <Radio
            key="people"
            name={name}
            className="tw-mr-20px tw-pt-7px"
            labelClassName="tw-text-gray-base tw-text-[14px]"
            deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
            value="people"
            inline
            checked={type === "people"}
            onChange={(e) => onRadioChange(e)}
          >
            People
          </Radio>

          <Radio
            key="email"
            name={name}
            className="tw-mr-20px tw-pt-7px"
            labelClassName="tw-text-gray-base tw-text-[14px]"
            deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
            value="email"
            inline
            checked={type === "email"}
            onChange={(e) => onRadioChange(e)}
          >
            Email
          </Radio>

          <Radio
            key="role"
            name={name}
            className="tw-mr-20px tw-pt-7px"
            labelClassName="tw-text-gray-base tw-text-[14px]"
            deprecatedOverrideRadioMarginClasses="!tw-mt-2px !tw--ml-20px"
            value="role"
            inline
            checked={type === "role"}
            onChange={(e) => onRadioChange(e)}
          >
            Role
          </Radio>
        </div>
      </div>

      <CCFor planType={planType} type={type} placeholder={placeholder} onOptionSelect={onOptionSelect} />

      <div className="cc-selections">
        {(people || []).map((person) => (
          <div className="cc-selection cc-person" key={person.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(person)}>
              <i className="fa fa-remove" />
            </a>
            person: {person.label} {!!person.email && person.email}
          </div>
        ))}

        {(emails || []).map((email) => (
          <div className="cc-selection cc-email" key={email.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(email)}>
              <i className="fa fa-remove" />
            </a>
            email: {email.label}
          </div>
        ))}

        {(roles || []).map((role) => (
          <div className="cc-selection cc-role" key={role.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(role)}>
              <i className="fa fa-remove" />
            </a>
            role: {role.label}
          </div>
        ))}
      </div>
    </Col>
  </div>
);

const EmailCCSelectorV2 = ({
  planType,
  emails,
  label,
  onOptionSelect,
  onOptionRemove,
  onRadioChange,
  placeholder,
  roles,
  type,
  people,
}) => (
  <div>
    <Col size={12}>
      <label className={subtleM}>{label}</label>
      <div className="tw-flex tw-gap-[24px] tw-mb-[12px]">
        <RadioButton
          value='people'
          label='People'
          className='tw-cursor-pointer'
          checked={type === 'people'}
          onClick={onRadioChange}
        />
        <RadioButton
          value='email'
          label='Email'
          className='tw-cursor-pointer'
          checked={type === 'email'}
          onClick={onRadioChange}
        />
        <RadioButton
          value='role'
          label='Role'
          className='tw-cursor-pointer'
          checked={type === 'role'}
          onClick={onRadioChange}
        />
      </div>
      <CCFor planType={planType} type={type} placeholder={placeholder} onOptionSelect={onOptionSelect} />

      <div className="cc-selections">
        {(people || []).map((person) => (
          <div className="cc-selection cc-person" key={person.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(person)}>
              <i className="fa fa-remove" />
            </a>
            person: {person.label} {!!person.email && person.email}
          </div>
        ))}

        {(emails || []).map((email) => (
          <div className="cc-selection cc-email" key={email.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(email)}>
              <i className="fa fa-remove" />
            </a>
            email: {email.label}
          </div>
        ))}

        {(roles || []).map((role) => (
          <div className="cc-selection cc-role" key={role.value}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, react/jsx-no-comment-textnodes, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid */}
            <a onClick={() => onOptionRemove(role)}>
              <i className="fa fa-remove" />
            </a>
            role: {role.label}
          </div>
        ))}
      </div>
    </Col>
  </div>
);

EmailCCSelector.propTypes = {
  planType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  onOptionRemove: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onToggleBCC: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  showAddBCC: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
};

EmailCCSelectorV2.propTypes = {
  planType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  onOptionRemove: PropTypes.func.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export {
  AssignTo,
  Delay,
  DelayAndScheduleLabel,
  Detail,
  Instructions,
  InstructionsLink,
  Note,
  NoteLink,
  PriorityOptions,
  RecurringOptions,
  RunTime,
  Schedule,
  ScheduleSelector,
  VisibilitySection,
  EmailCCSelector,
  EmailCCSelectorV2
};
