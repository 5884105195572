import React from "react";
import { connect } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { bool, func, string } from "prop-types";

import { deleteImg } from "../actions/creators";
import { downloadFile } from "../../../../shared/AWSUploader";
import ListingImgPreviewCtrls from "../ListingImgPreviewCtrls";
import Modal from "../../../../shared/v2/Modal";

import "./styles.scss";
import useImgStyle from "./hooks";

const ModalPreviewImg = ({ isModalOpen, toggleModal, url, id }) => {
  const [imgStyle, setMirrored] = useImgStyle(false);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => toggleModal((prev) => !prev)}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-full tw-h-full"
      dialogClassName="tw-max-w-[869px] tw-max-h-[714px] !tw-w-full tw-h-full"
      closeButton
    >
      <Modal.Header closeButton>
        <div className="!tw--mt-[32px]">
          <div className="tw-pb-[20px] tw-flex tw-items-center tw-border-gray-15 tw-border-solid tw-border-t-0 tw-border-r-0 tw-border-l-0">
            <div className="tw-ml-[12px]">
              <div className="tw-font-bold">image.jpg</div>
              <div className="tw-mt-[4px]">
                <span className="tw-text-gray-50">Cyrus Texttest</span>
                <span className="tw-text-gray-50 tw-ml-[8px]">Aug 4th at 3:53 PM</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="tw-mb-36px">
        <div className="tw-w-[630px] tw-h-[420px] tw-mx-auto tw-mt-[68px]">
          <TransformWrapper maxScale={2} pinch={{ disabled: true }} wheel={{ disabled: true }}>
            {({ centerView }) => (
              <>
                <TransformComponent>
                  <img className={imgStyle()} src={url} alt={url} />
                </TransformComponent>
                <ListingImgPreviewCtrls
                  setMirrored={setMirrored}
                  centerView={centerView}
                  onDownload={() => {
                    downloadFile(url, id);
                  }}
                />
              </>
            )}
          </TransformWrapper>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteImg: (id) => dispatch(deleteImg(id)),
});

ModalPreviewImg.propTypes = {
  isModalOpen: bool,
  toggleModal: func,
  url: string,
  id: string,
};

ModalPreviewImg.defaultProps = {
  isModalOpen: false,
  toggleModal: () => {},
  url: "",
  id: "",
};

export default connect(null, mapDispatchToProps)(ModalPreviewImg);
