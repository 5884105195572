import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getExternalApi } from "@shared/externalApi";
import { getEmptyConversation } from "../helpers/defaults";
import {
  focusConversation,
  getMessages,
  setConflictConversation,
  setErrors,
  transformConversation,
} from "../../reducers/messagingReducer";
import {
  useFocusedConversation,
  useMessagingServiceUrl,
  useRecipientsHash,
} from "../../reducers/messagingReducer/selectors";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const useDraftConversationMessage = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const focusedConversation = useFocusedConversation();
  const messagingServiceUrl = useMessagingServiceUrl();
  const messagingApi = getExternalApi(messagingServiceUrl);
  const recipientsHash = useRecipientsHash();

  useEffect(() => {
    let messagesAbortController;
    const abortController = new AbortController();
    const recipientsWithNumbers = Object.values(recipientsHash);
    if (focusedConversation.uuid === "temp") {
      dispatch(focusConversation(getEmptyConversation(currentUser)));
      if (
        recipientsWithNumbers.length > 0 &&
        recipientsWithNumbers.every((r) => r.number?.value || (r.number?.empty || true) !== true)
      ) {
        const users = recipientsWithNumbers.map((r) => ({
          personUuid: r.recipient.value,
          address: r.number.value,
          isConversationOwner: false,
        }));
        const conversationPromise = messagingApi
          .post(
            "/Conversation/WithUsers",
            {
              conversationType: "groupSms",
              isChannel: false,
              users,
            },
            {
              signal: abortController.signal,
            },
          )
          .catch((err) => {
            if (err?.response?.status === 409 && err?.response?.data) {
              return {
                data: {
                  ...err.response.data,
                  isConflict: true,
                },
              };
            }
            throw err;
          })
          .then(({ data }) => transformConversation(data));

        conversationPromise
          .then((conversation) => {
            if (!conversation.uuid) return () => {};
            if (conversation?.isConflict) return dispatch(setConflictConversation(conversation));
            const { uuid, ...newFocusedConversation } = conversation;
            const conversationOwnerPersonUuids = conversation.conversationUsers
              ?.filter((u) => u.isConversationOwner)
              .map((u) => u.personUuid);
            dispatch(
              focusConversation({
                ...focusedConversation,
                ...newFocusedConversation,
                conflictUuid: uuid,
                conversationOwnerPersonUuids,
              }),
            );
            messagesAbortController = dispatch(getMessages({ messagesPage: 1, uuid }));
            return messagesAbortController;
          })
          .catch((err) => {
            if (axios.isCancel(err)) return;
            dispatch(
              setErrors({
                messagesErrors: [err?.response?.data || "Whoops! Something went wrong."],
              }),
            );
          });
      }
    }
    return () => {
      abortController.abort();
      messagesAbortController?.abort();
    };
  }, [currentUser, focusedConversation.uuid, recipientsHash]);
};

export default useDraftConversationMessage;
