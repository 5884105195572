import { replace, snakeCase, startCase, toNumber, capitalize } from "lodash";
import moment from "moment";
import format from "../../../shared/formatters";
import { removeCommas } from "../../../shared/v2/inputFormatUtils";
import { calcStatus, detailFinder, getAvatar } from "./helpers";

export const adaptDetailsPayloadToApi = (payload) => {
  switch (payload.key) {
    case "est_close_price":
    case "close_price":
      return {
        ...payload,
        value: Number(removeCommas(payload.value)),
      };
    default:
      return payload;
  }
};

export const adaptGetAllListingInfo = (data) => ({
  transactionDetails: {
    title: data.title,
    use_custom_title: data.use_custom_title,
    publishedState: data.published ? "Published" : "Draft",
    status: calcStatus(data.status),
    est_close_price: format.number(data.est_close_price, 2, true),
    type: data.type,
  },
  listingDetails: {
    price: data.price,
    source: data.source,
    mls_number: data.mls_number,
    valid_mls_number: data["valid_mls_number?"],
  },
  propertyDetails: {
    street_address: data.address?.street_address,
    city: data.address?.city,
    state: data.address?.locality,
    zip: data.address?.postal_code,
    beds: detailFinder(data.details, "beds")?.value,
    baths: detailFinder(data.details, "baths")?.value,
    sqft: detailFinder(data.details, "sqft")?.value,
    property_type: data.property_type,
  },
  primaryAgentDetails: {
    agent: data.agent ? `${data.agent.first_name} ${data.agent.last_name}` : null,
    agent_id: data.agent?.id || null,
    agent_avatar: getAvatar(data.agent?.avatar),
    primaryAgentRole: data.agent?.role,
  },
  headerDetails: {
    days_on_market: data?.days_on_market,
    photo: data?.primary_mls_photo ? { id: 1, cloud_location: data?.primary_mls_photo } : data?.photo,
    price: data.price,
    type: data.type,
    listing_id: data.id,
    published: data.published,
    address_id: data.address?.id,
    error: null,
    statusCode: null,
  },
  referralPartner: {
    avatar: getAvatar(data.referral_partner?.avatar),
    name: data.referral_partner
      ? `${data.referral_partner?.first_name} ${data.referral_partner?.last_name}`
      : null,
  },
  referralDetails: {
    clientIntent: data.client_intent,
    addressField: data.desired_locations,
    transactionAmt: format.number(data.transaction_amount, 2, true),
    brokerage: data.brokerage,
    referralFee: data.referral_fee ? format.number(data.referral_fee, 2, !data["fee_percentage?"]) : null,
    isReferralFeePercentage: data["fee_percentage?"],
    timeframe: {
      label: capitalize(startCase(data.timeframe)),
      value: capitalize(startCase(data.timeframe)),
    },
    timeframeExact: moment(data.timeframe_start, "YYYY-MM-DD").format("MM/DD/YYYY"),
    timeframeRead: data.timeframe_interpolated,
    team: data.team,
  },
  additionalDetails: {},
  propertyPagePath: "",
  links: "",
  listingImgs: [],
  checkAll: null,
});

export const adaptTimeFrame = ({ label: timeframe, uuid }, exactDate) => {
  const timeframeStart = timeframe === "Custom" ? moment(exactDate, "MM/DD/YYYY") : moment();

  return {
    timeframe_start: timeframeStart.format("YYYY-MM-DD"),
    timeframe: snakeCase(timeframe),
    uuid,
  };
};

export const adaptTransactionAmt = ({ transactionAmount, uuid }) => ({
  key: "transaction_amount",
  value: toNumber(replace(transactionAmount, /,/g, "")),
  uuid,
});

export const adaptReferralFee = ({ referralFee, isFeePercentage, uuid }) => ({
  referral_fee: referralFee * 1,
  is_fee_percentage: isFeePercentage,
  uuid,
});

export const adaptStatus = ({ status, uuid }) => ({
  key: "status",
  value: status === "comingsoon" ? "coming soon" : status,
  uuid,
});

export const adaptTitle = ({ title, uuid }) => ({
  key: "title",
  value: title,
  uuid,
});

export const adaptClosedPrice = (data) => format.number(data.close_price, 2, true);
