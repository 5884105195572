import React from "react";
import { connect } from "react-redux";
import { arrayOf, func, string } from "prop-types";

import { filterTasksByAutoPlans } from "../actions/creators";

import Checkbox from "../../../../shared/v2/Checkbox";

const AutoPlan = ({ autoPlan, onFilterByAutoPlans, activeAutoPlans }) => (
  <button
    type="button"
    onClick={() => onFilterByAutoPlans(autoPlan)}
    className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-gray-57 tw-border-0 tw-bg-transparent tw-m-0 tw-p-0 tw-mb-10px"
  >
    <Checkbox checked={activeAutoPlans.includes(autoPlan)} value={autoPlan} className="tw-z-40" />
    <div className="tw-ml-8px">{autoPlan}</div>
  </button>
);

const mapStateToProps = (state) => ({
  activeAutoPlans: state.tdpTasksReducer.secondaryFilters.activeAutoPlans,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterByAutoPlans: (autoPlan) => dispatch(filterTasksByAutoPlans(autoPlan)),
});

AutoPlan.propTypes = {
  autoPlan: string,
  onFilterByAutoPlans: func,
  activeAutoPlans: arrayOf(string),
};

AutoPlan.defaultProps = {
  autoPlan: null,
  onFilterByAutoPlans: () => {},
  activeAutoPlans: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPlan);
