import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FieldLabelV1 from "../../shared/UtilsV1/FieldLabelV1";
import TagV1 from "../../shared/UtilsV1/TagV1";
import Button from "../../shared/v2/Button";
import FieldTextV1 from "../../shared/UtilsV1/FieldTextV1";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactPerson } from "../../reducers/contactsReducer/selectors";

function CertificationsAndDesignationsSection() {
  const dispatch = useDispatch();
  const { certificates } = useContactPerson();
  const [input, setInput] = useState(null);

  return (
    <div className="tw-flex-1">
      <FieldLabelV1 label="Certifications & Designations" />

      <div className="tw-flex tw-gap-[8px]">
        <div className="tw-flex tw-flex-1 tw-items-center tw-gap-[8px]">
          <FieldTextV1
            onChange={(value) => {
              setInput(value);
            }}
            value={input}
            data-cy="certification_input"
          />
        </div>
        <Button
          schema="primary"
          onClick={() => {
            const tempCertificates =
              !certificates || certificates.length <= 0 ? [input] : [...certificates, input];

            dispatch(setPersonValue({ key: "certificates", value: [...tempCertificates] }));
            setInput(null);
          }}
          className="tw-flex tw-items-center"
          disabled={!input || (certificates && certificates.includes(input))}
          size="medium"
          data-cy="certification_btn"
        >
          ADD
        </Button>
      </div>

      <div className="tw-flex tw-gap-[6px] tw-flex-wrap tw-mt-2">
        {certificates &&
          certificates.map((item) => (
            <TagV1
              key={item}
              text={item}
              onRemove={() =>
                dispatch(
                  setPersonValue({
                    key: "certificates",
                    value: certificates.filter((itemName) => itemName !== item),
                  }),
                )
              }
            />
          ))}
      </div>
    </div>
  );
}

export default CertificationsAndDesignationsSection;
