import React from "react";
import { Col } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";

class AssignTaskToSelection extends React.Component {
  render() {
    const { handleTaskChange, task, agents, taskDetails } = this.props;
    return (
      <Col size={6} className="tw-mb-[16px]">
        <Dropdown
          menuShouldComeToFront
          label="Assign Task to"
          isSearchable
          name="assigned_to_user_id"
          onChange={(agent, handler) =>
            handleTaskChange({ target: { name: handler?.name, value: agent?.value } })
          }
          isClearable={false}
          value={agents?.find((agent) => agent.value === task.assigned_to_user_id) || null}
          options={agents}
          isDisabled={taskDetails ? true : false}
          isLoading={!agents?.length}
        />
      </Col>
    );
  }
}

export default AssignTaskToSelection;
