import {
  getDocumentsFromApi,
  getAllDocumentViewersFromApi,
  renameDocumentFromApi,
  uploadDocumentFromApi,
  deleteDocumentsFromApi,
  searchDocumentsFromApi,
  sortDocumentsFromApi,
} from "../api/apiWrappers";

import {
  getDocuments,
  getDocumentsStart,
  getDocumentsError,
  getSidebarDocuments,
  getSidebarDocumentsStart,
  getSidebarDocumentsError,
  getDocumentsFromSearch,
  searchDocumentsError,
  getDocumentsFromSort,
  sortDocumentsError,
  getAllDocumentViewers,
  renameDocumentSuccess,
  renameDocumentError,
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  deleteDocumentsSuccess,
  deleteDocumentsError,
  uploadCDADocSuccess,
} from "./creators";

import {
  adapterGetDocuments,
  adapterDocumentFromRename,
  adapterDocumentFromUpload,
  adapterGetAllDocumentViewers,
} from "../api/apiAdapters";
import { fetchFinancials } from "../../Financials/actions/thunks";

/**
 * @param {string} uuid
 * @param {number} page
 * @param {bool} quiet When `true`, fetches documents without setting the `isFetchingDocuments` flag,
 * and without reporting errors.
 *
 * Useful for when we want to update the documents without triggering a loading screen,
 * or when we don't want to show an error message if the request failed.
 */
export const getDocumentsAsThunk = (page, quiet) => async (dispatch, getState) => {
  let response;

  const { search, orderBy, orderDir, uuid } = getState().tdpDocumentsReducer;

  if (!quiet) {
    dispatch(getDocumentsStart());
  }

  try {
    response = await getDocumentsFromApi(uuid, page, search, orderBy, orderDir);
  } catch (error) {
    if (quiet) {
      return;
    }

    if (error.response?.data?.errors) {
      dispatch(getDocumentsError(error.response.data.errors));
    } else {
      dispatch(getDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
    }

    return;
  }

  if (response.status === 200) {
    const adaptedData = adapterGetDocuments(response.data);
    dispatch(getDocuments(adaptedData.documents, adaptedData.meta, uuid));
  } else if (!quiet) {
    dispatch(getDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
  }
};

/**
 * @param {string} uuid
 * @param {bool} quiet When `true`, fetches documents without setting the `isFetchingDocuments` flag,
 * and without reporting errors.
 *
 * Useful for when we want to update the documents without triggering a loading screen,
 * or when we don't want to show an error message if the request failed.
 */
export const getSidebarDocumentsAsThunk = (quiet) => async (dispatch, getState) => {
  let response;

  const { uuid } = getState().tdpDocumentsReducer;

  if (!quiet) {
    dispatch(getSidebarDocumentsStart());
  }

  try {
    response = await getDocumentsFromApi(uuid);
  } catch (error) {
    if (quiet) {
      return;
    }

    if (error.response?.data?.errors) {
      dispatch(getSidebarDocumentsError(error.response.data.errors));
    } else {
      dispatch(getSidebarDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
    }

    return;
  }

  if (response.status === 200) {
    const adaptedData = adapterGetDocuments(response.data);
    dispatch(getSidebarDocuments(adaptedData.documents, adaptedData.meta));
  } else if (!quiet) {
    dispatch(getSidebarDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
  }
};

export const getAllDocumentViewersAsThunk = (documentId) => async (dispatch, getState) => {
  let response;

  const { uuid } = getState().tdpDocumentsReducer;

  try {
    response = await getAllDocumentViewersFromApi(uuid, documentId);
  } catch (error) {
    // TODO: this should be handled once we add viewers
  }

  if (response.status === 200) {
    const adaptedData = adapterGetAllDocumentViewers(response.data);
    dispatch(getAllDocumentViewers(documentId, adaptedData.viewers));
  } else {
    // TODO: this should be handled once we add viewers
  }
};

export const searchDocumentsAsThunk = (search) => async (dispatch, getState) => {
  let response;

  const { orderBy, orderDir, uuid } = getState().tdpDocumentsReducer;

  dispatch(getDocumentsStart());

  try {
    response = await searchDocumentsFromApi(uuid, search, orderBy, orderDir);
  } catch (error) {
    if (error.response?.data?.errors) {
      dispatch(searchDocumentsError(search, error.response.data.errors));
    } else {
      dispatch(searchDocumentsError(search, ["Oops, something went wrong, try again in a few minutes."]));
    }

    return;
  }

  if (response.status === 200) {
    const adaptedData = adapterGetDocuments(response.data);
    dispatch(getDocumentsFromSearch(adaptedData.documents, adaptedData.meta, search));
  } else {
    dispatch(searchDocumentsError(search, ["Oops, something went wrong, try again in a few minutes."]));
  }
};

export const renameDocumentAsThunk = (context, documentId, newName) => async (dispatch, getState) => {
  let response;

  const { search, orderBy, meta, uuid } = getState().tdpDocumentsReducer;

  try {
    response = await renameDocumentFromApi(uuid, documentId, newName);
  } catch (error) {
    if (error.response?.data?.errors) {
      dispatch(renameDocumentError(context, documentId, error.response.data.errors));
    } else {
      dispatch(
        renameDocumentError(context, documentId, ["Oops, something went wrong, try again in a few minutes."]),
      );
    }

    return;
  }

  if (response.status === 200) {
    const document = adapterDocumentFromRename(response.data.document, newName);

    const adaptedResponse = {
      document,
    };

    dispatch(renameDocumentSuccess(documentId, adaptedResponse));

    if (context === "CDA-document") {
      dispatch(fetchFinancials());
    } else if (search || orderBy === "file_name") {
      // When searching or ordering by file name, refresh documents after renaming
      dispatch(getDocumentsAsThunk(meta.pagination.current, true));
    }
  } else {
    dispatch(
      renameDocumentError(context, documentId, ["Oops, something went wrong, try again in a few minutes."]),
    );
  }
};

export const uploadDocumentAsThunk = (data, formData, isFinancialsCDA) => async (dispatch, getState) => {
  let response;

  const { documents, search, orderBy, meta: statefulMeta, uuid } = getState().tdpDocumentsReducer;

  dispatch(uploadDocumentStart(data));

  try {
    response = await uploadDocumentFromApi(uuid, formData);
  } catch (error) {
    if (error.response?.data?.errors) {
      dispatch(uploadDocumentError(isFinancialsCDA, data.uploadKey, error.response.data.errors));
    } else {
      dispatch(
        uploadDocumentError(isFinancialsCDA, data.uploadKey, [
          "Oops, something went wrong, try again in a few minutes.",
        ]),
      );
    }

    return;
  }

  if (response.status === 200) {
    let dispatchDocType;
    const document = adapterDocumentFromUpload(response.data.document, response.data.meta);
    const { meta } = response.data;

    const adaptedResponse = {
      document,
      meta,
    };

    if (isFinancialsCDA) {
      dispatch(fetchFinancials());
      dispatchDocType = uploadCDADocSuccess;
    } else {
      dispatchDocType = uploadDocumentSuccess;

      // When searching or ordering by file name,
      // or not in the first page,
      // or in the first page and the number of documents exceeds the count per page,
      // refresh documents after uploading
      if (
        search ||
        orderBy === "file_name" ||
        statefulMeta.pagination.current > 1 ||
        documents.length + 1 > statefulMeta.pagination.countPerPage
      ) {
        dispatch(getDocumentsAsThunk(statefulMeta.pagination.current, true));
      }
    }

    dispatch(dispatchDocType(adaptedResponse, data.uploadKey));
  } else {
    dispatch(
      uploadDocumentError(isFinancialsCDA, data.uploadKey, [
        "Oops, something went wrong, try again in a few minutes.",
      ]),
    );
  }
};

export const deleteDocumentsAsThunk = (documentIds, isFinancialsCDA) => async (dispatch, getState) => {
  let response;

  const { documents, sidebarDocuments, search, orderBy, orderDir, meta, uuid } =
    getState().tdpDocumentsReducer;

  try {
    response = await deleteDocumentsFromApi(uuid, documentIds);
  } catch (error) {
    if (error.response?.data?.errors) {
      dispatch(deleteDocumentsError(error.response.data.errors));
    } else {
      dispatch(deleteDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
    }

    return;
  }

  if (response.status === 204) {
    dispatch(deleteDocumentsSuccess(documentIds));

    if (isFinancialsCDA) {
      dispatch(fetchFinancials());
    } else {
      // When all documents from the current page have been deleted and not in the first page,
      // go back to the previous page,
      // or refresh the current page when not in the last page
      if (documentIds.length === documents.length && meta.pagination.current > 1) {
        dispatch(getDocumentsAsThunk(meta.pagination.previous, true));
      } else if (meta.pagination.next) {
        dispatch(getDocumentsAsThunk(meta.pagination.current, true));
      }

      // When not in the first page or in the first page with search/sort,
      // refresh the sidebar documents if any document was deleted from it
      if (meta.pagination.current > 1 || search || orderBy || orderDir) {
        const wasAnySidebarDocumentDeleted = sidebarDocuments.some((doc) => documentIds.includes(doc.id));

        if (wasAnySidebarDocumentDeleted) {
          dispatch(getSidebarDocumentsAsThunk(true));
        }
      }
    }
  } else {
    dispatch(deleteDocumentsError(["Oops, something went wrong, try again in a few minutes."]));
  }
};

export const sortDocumentsAsThunk = (orderBy, orderDir) => async (dispatch, getState) => {
  let response;

  const { search, uuid } = getState().tdpDocumentsReducer;

  try {
    response = await sortDocumentsFromApi(uuid, orderBy, orderDir, search);
  } catch (error) {
    if (error.response?.data?.errors) {
      dispatch(sortDocumentsError(orderBy, orderDir, error.response.data.errors));
    } else {
      dispatch(
        sortDocumentsError(orderBy, orderDir, ["Oops, something went wrong, try again in a few minutes."]),
      );
    }

    return;
  }

  if (response.status === 200) {
    const adaptedData = adapterGetDocuments(response.data);
    dispatch(getDocumentsFromSort(adaptedData.documents, adaptedData.meta, orderBy, orderDir));
  } else {
    dispatch(
      sortDocumentsError(orderBy, orderDir, ["Oops, something went wrong, try again in a few minutes."]),
    );
  }
};
