/* eslint-disable no-param-reassign */
import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { trim } from "lodash";
import moment from "moment";
import { useDebounce } from "@shared/hookHelpers";
import { responseDataToCamelCase } from "@shared/v2/caseTransformingAxios";
import { markAllAsRead, markAsRead, searchActivities, getUnreadCount } from "../api";

const formatDate = (dateString) => {
  const date = moment(dateString, "MM/DD/YYYY");

  if (date.isSame(moment(), "day")) return "Today";
  if (date.isSame(moment().subtract(1, "day"), "day")) return "Yesterday";

  return date.format("MM/DD/YY");
};

const groupAndSortByDate = (data) => {
  if (data.length === 0) return [];
  const groupedData = data.reduce((acc, activity) => {
    if (!activity) return acc;

    const dateKey = moment.utc(activity.createdAt).local().format("MM/DD/YYYY");

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    acc[dateKey].push(activity);

    return acc;
  }, {});

  Object.keys(groupedData).forEach((date) => {
    groupedData[date].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const sortedArray = Object.entries(groupedData)
    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
    .map(([date, activities]) => ({ date: formatDate(date), activities }));

  return sortedArray;
};

const transformNotificationData = (notification) => ({
  ...notification,
  data:
    typeof notification.data === "string"
      ? responseDataToCamelCase(JSON.parse(notification.data || "{}"))
      : notification.data,
});

const LIMIT = 30;

const useActivities = ({ query: initQuery, show, activitiesRef }) => {
  const query = useDebounce(trim(initQuery), 300);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [unreadCount, setUnreadCount] = useState(0);
  const [data, setData] = useState([]);
  const [sortedActivities, setSortedActivities] = useState(groupAndSortByDate(data));
  const [loading, setLoading] = useState(false);
  const [readFilter, setReadFilter] = useState(false);
  const [category, setCategory] = useState("all");

  const resetData = (abortSignal) => {
    setLoading(true);
    searchActivities({
      params: { page: 1, limit: LIMIT, read: readFilter, category: category === "all" ? null : category },
      signal: abortSignal,
    }).then((d) => {
      setLoading(false);
      activitiesRef.current.scrollTop = 0;
      setPage(1);
      if (d?.length >= LIMIT) setMaxPage(page + 1);

      setData((d || []).map(transformNotificationData));
    });
  };

  useEffect(() => {
    getUnreadCount().then((count) => setUnreadCount(count));
  }, []);

  useEffect(() => {
    const refedData = data.map((d) => ({ ...d, ref: createRef(null) }));

    setSortedActivities(
      groupAndSortByDate(refedData).map((group) => ({ ...group, dateRef: createRef(null) })),
    );
  }, [data]);

  useEffect(() => {
    setLoading(false);
  }, [sortedActivities]);

  useEffect(() => {
    if (page > 1 && show) {
      setLoading(true);
      searchActivities({
        params: {
          term: query,
          page,
          limit: LIMIT,
          read: readFilter,
          category: category === "all" ? null : category,
        },
      })
        .then((d) => {
          if (d.length >= LIMIT) setMaxPage(page + 1);
          setData((prev) => [...prev, ...(d || []).map(transformNotificationData)]);
        })
        .catch(() => setLoading(false));
    }
  }, [page]);

  useEffect(() => {
    const abortController = new AbortController();

    if (show) {
      resetData(abortController.signal);
    }
    return () => abortController.abort();
  }, [readFilter, category]);

  useEffect(() => {
    if (!show) return () => {};
    const abortController = new AbortController();
    if (show) {
      resetData(abortController.signal);
    }

    return () => abortController.abort();
  }, [show]);

  useEffect(() => {
    if (!query && show) {
      resetData();
      return () => {};
    }
    const abortController = new AbortController();

    setLoading(true);

    if (query && show) {
      searchActivities({
        params: {
          term: query,
          page: 1,
          limit: LIMIT,
          read: readFilter,
          category: category === "all" ? null : category,
        },
        signal: abortController.signal,
      })
        .then((d) => {
          setPage(1);
          setMaxPage(d && d.length >= LIMIT ? 2 : 1);
          setData((d || []).map(transformNotificationData));
        })
        .catch((err) => {
          if (!axios.isCancel(err)) setLoading(false);
        });
    }

    return () => {
      abortController.abort();
    };
  }, [query]);

  const actions = {
    onReadChange: (activity) => {
      markAsRead(activity).then(() => {
        setUnreadCount((c) => c + (activity.read ? 1 : -1));
        setData((prev) => prev.filter((a) => a.id !== activity.id));
      });
    },

    markAllAsRead: () => {
      setLoading(true);

      markAllAsRead()
        .then(() => {
          setUnreadCount(0);
          setData([]);
        })
        .catch(() => setLoading(false));
    },

    loadMore: () => {
      if (data.length >= 30 && page < maxPage) setPage((p) => p + 1);
    },
    toggleReadFilter: () => {
      setReadFilter((prev) => !prev);
    },
    setCategory: (ct) => {
      setCategory(ct);
    },
  };

  return { actions, activityGroups: sortedActivities, loading, unreadCount, readFilter, category };
};

export default useActivities;
