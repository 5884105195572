import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteNoteModal, EditNoteModal } from "../../../Transactions/components/Notes/helpers/forms";
import ViewAllNotes from "../modals/ViewAllNotes";
import { reorderNotesBasedOnStarred } from "../../../Transactions/utils/helpers";
import * as actions from "../../actions/personDetailActionCreators";
import CollapsibleElement from "../CollapsibleElement";
import Loading from "../../../Goals/components/Loader";
import NoteCard from "./NoteCard";
import TextButton from "../../../shared/v2/TextButton";

const CardNotes = () => {
  const dispatch = useDispatch();

  const person = useSelector((state) => state.personDetail.person);
  const notes = useSelector((state) => state.personDetail.notes);

  const toggleStarred = (starred) => dispatch(actions.toggleStarred(starred));
  const updateNote = (...args) => dispatch(actions.updateNote(...args));
  const deleteNote = (note) => dispatch(actions.deleteNote(note));
  const submitNote = (...args) => dispatch(actions.submitNote(...args));

  const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showViewAllModal, setShowViewAllModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (person.data?.id) dispatch(actions.getNotes(person));
  }, [person.data?.id]);

  return (
    <CollapsibleElement
      id="pdp-notes"
      title="Notes"
      buttonText="+ ADD"
      buttonClickFunction={() => setAddNoteModalOpen(true)}
    >
      {!person.data && <Loading />}

      {notes.length === 0 && <p>Write a note about this contact or use @ to notify someone on your team.</p>}

      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        {reorderNotesBasedOnStarred(notes)
          .slice(0, 3)
          .map((note) => (
            <NoteCard
              key={note.id}
              note={note}
              variant="short"
              onStar={() => toggleStarred(note)}
              onDelete={() => setShowDeleteForm(note)}
              onEdit={() => setShowEditForm(note)}
            />
          ))}

        {notes.length > 3 && (
          <TextButton className="tw-self-start" onClick={() => setShowViewAllModal(!showViewAllModal)}>
            View All
          </TextButton>
        )}
      </div>

      {showViewAllModal && (
        <ViewAllNotes
          notes={notes}
          onClose={() => setShowViewAllModal(false)}
          onAddNote={() => setAddNoteModalOpen(true)}
          onStar={(note) => toggleStarred(note)}
          onEdit={(note) => setShowEditForm(note)}
          onDelete={(note) => setShowDeleteForm(note)}
        />
      )}

      {addNoteModalOpen && (
        <EditNoteModal
          notableType="Person"
          notableId={person.data?.id}
          onClose={() => setAddNoteModalOpen(false)}
          updateNote={submitNote}
        />
      )}

      {showEditForm && (
        <EditNoteModal
          isEditing
          note={{ ...showEditForm, content: showEditForm.markdown_content }}
          notableId={person.data?.id}
          notableType="Person"
          onClose={() => setShowEditForm(false)}
          onDelete={() => setShowDeleteForm(showEditForm)}
          updateNote={updateNote}
        />
      )}

      {showDeleteForm && (
        <DeleteNoteModal
          onClose={() => setShowDeleteForm(false)}
          onDelete={() => {
            deleteNote(showDeleteForm);
            setShowEditForm(false);
            setShowDeleteForm(false);
          }}
        />
      )}
    </CollapsibleElement>
  );
};

export default CardNotes;
