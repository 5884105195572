import React from "react";
import PropTypes from "prop-types";
import { Section } from "../BusinessDashboard";
import MetricsGrid from "./MetricsGrid";
import useTotalByType from "./services/useTotalByType";
import Error from "../DashboardShared/Error";
import Loader from "../../../Dashboard/components/Loader";

const TotalAgentsStaffSection = ({ year, type }) => {
  const { data, error, loading } = useTotalByType({ year, type });
  return (
    <Section className="tw-gap-[16px] tw-flex-1 tw-min-h-[230px] tw-min-w-[320px]">
      <Error show={Boolean(error)} message="Whoops! Something went wrong." />
      <MetricsGrid
        showTextToggle={false}
        titleName={data?.titleName}
        titleValue={data?.titleValue}
        metrics={data?.metrics}
      />
      {loading && <Loader />}
    </Section>
  );
};

TotalAgentsStaffSection.propTypes = {
  type: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TotalAgentsStaffSection;
