/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const InboxSolidV6 = (props) => (
  <Icon {...props} name="Inbox Solid V6">
    <path d="M13.969 1.758C13.86 1.313 13.46.972 13 .972H3c-.459 0-.858.313-.97.758L.116 9.528a4.002 4.002 0 0 0-.116.956v3.015a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-3.015a4.01 4.01 0 0 0-.116-.957l-1.915-7.77zM11 11H5L4 9H2.28l1.501-6h8.437l1.501 6h-1.72l-1 2z" />
  </Icon>
);

export default InboxSolidV6;
