/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const XSolidV6 = (props) => (
  <Icon {...props} name="X Solid V6">
    <path d="M13.769 13.359a1 1 0 0 1-1.536 1.28L8 9.534l-4.231 5.078a.999.999 0 1 1-1.536-1.28l4.466-5.359-4.466-5.332a1 1 0 1 1 1.536-1.28l4.232 5.077 4.231-5.078a1 1 0 1 1 1.536 1.28L9.302 7.999l4.467 5.36z" />
  </Icon>
);

export default XSolidV6;
