import React from "react";
import PropTypes from "prop-types";
import { CheckCircleRegularV3, CircleExclamationRegularV6 } from "@shared/v2/Icomoon/Icons";
import MessageErrorTooltip from "../../../Inbox/components/MessageErrorTooltip";

const getStatusColor = (deliveryStatus) => {
  switch (deliveryStatus) {
    case "Undelivered":
    case "Undelivered to all":
      return "tw-text-semantic-red-110";
    case "Undelivered to some":
      return "tw-text-[#E5941B]";
    default:
      return "tw-text-gray-50";
  }
};

const getConvertedStatus = (deliveryStatus) =>
  deliveryStatus === "Undelivered to some" ? "someFailed" : "allFailed";

const SmsStatus = ({ deliveryStatus, errorCode, failedRecipients }) => {
  const getStatusIcon = () => {
    if (deliveryStatus === "Delivered" || deliveryStatus === "Delivered to all") {
      return <CheckCircleRegularV3 className="tw-align-middle tw-text-gray-50" />;
    } else if (
      deliveryStatus === "Undelivered" ||
      deliveryStatus === "Undelivered to all" ||
      deliveryStatus === "Undelivered to some"
    ) {
      return errorCode ? (
        <MessageErrorTooltip
          error={getConvertedStatus(deliveryStatus)}
          errorCode={errorCode}
          failedRecipients={failedRecipients}
          size="l"
        />
      ) : (
        <CircleExclamationRegularV6 className={`tw-align-middle ${getStatusColor(deliveryStatus)}`} />
      );
    } else {
      return null;
    }
  };

  if (!deliveryStatus) return null;

  return (
    <div className="tw-inline-flex tw-items-center tw-gap-[8px] tw-whitespace-nowrap">
      <span className={`tw-align-middle tw-text-12px ${getStatusColor(deliveryStatus)}`}>
        {deliveryStatus}
      </span>
      {getStatusIcon()}
    </div>
  );
};

SmsStatus.propTypes = {
  deliveryStatus: PropTypes.string,
  errorCode: PropTypes.number,
  failedRecipients: PropTypes.arrayOf(PropTypes.string),
};

SmsStatus.defaultProps = {
  deliveryStatus: "",
  errorCode: undefined,
  nonDeliveryReasons: [],
};

export default SmsStatus;
