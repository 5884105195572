/* eslint-disable no-param-reassign */
import React from "react";
import { components } from "react-select";
import PropTypes from "prop-types";

const SingleValue = ({ className, preserveStyle, children, ...otherProps }) => {
  if (!preserveStyle) {
    // So that the default styles aren't applied, for full customization
    otherProps.getStyles = () => ({});
  }

  return (
    <components.SingleValue {...otherProps} className={className}>
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  className: PropTypes.string,
  preserveStyle: PropTypes.bool,
  children: PropTypes.node,
};

SingleValue.defaultProps = {
  className: "",
  preserveStyle: false,
  children: null,
};

export default SingleValue;
