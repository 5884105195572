/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChartPieSolidV6 = (props) => (
  <Icon {...props} name="Chart Pie Solid V6">
    <path d="M8.444 1.349c0-.251.169-.461.444-.461A6.222 6.222 0 0 1 15.11 7.11c0 .25-.211.444-.461.444H8.443V1.348zM.889 8.444c0-3.369 2.503-6.157 5.725-6.604a.43.43 0 0 1 .497.427v6.622l4.347 4.347a.421.421 0 0 1-.042.644A6.666 6.666 0 0 1 .888 8.444zm14.622.445c.256 0 .461.217.428.472a6.661 6.661 0 0 1-2.053 3.953c-.167.133-.428.144-.589-.017L8.889 8.889h6.622z" />
  </Icon>
);

export default ChartPieSolidV6;
