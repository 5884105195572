import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Checkbox from "../../../../../shared/v2/Checkbox";
import { ContextPropType } from "../../propTypes";

const DocumentCheckbox = ({ context, isSelected, onSelect, id }) => {
  const checkboxId = useMemo(() => `${context}-${String(id)}`, [context, id]);

  const [checked, setChecked] = useState(isSelected);

  useEffect(() => {
    setChecked(isSelected);
  }, [isSelected]);

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const handleOnChange = (checkStatus) => {
    setChecked(checkStatus);
    onSelect(checkStatus, id);
  };

  return (
    <div className="tw-flex tw-items-center">
      <Checkbox
        id={checkboxId}
        name={checkboxId}
        value={checkboxId}
        checked={checked}
        onClick={handleClick}
        onChange={(e) => {
          handleOnChange(e.target.checked);
        }}
        data-cy="document-list-item-checkbox"
      />
    </div>
  );
};

DocumentCheckbox.propTypes = {
  context: ContextPropType.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DocumentCheckbox.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  id: "",
};

export default DocumentCheckbox;
