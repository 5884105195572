import React from "react";
import PropTypes from "prop-types";
import { justifyValue, alignValue, spaceValue } from "./styleHelpers";

const Cluster = ({ justify, align, space, children }) => (
  <div
    className={`
      tw-flex tw-flex-wrap tw-flex-row
      ${justifyValue(justify)}
      ${alignValue(align)}
      ${spaceValue(space)}
    `}
  >
    {children}
  </div>
);

Cluster.propTypes = {
  justify: PropTypes.oneOf(["", "start", "end", "center", "between", "around", "evenly"]),
  align: PropTypes.oneOf(["", "start", "end", "center", "baseline", "stretch"]),
  space: PropTypes.string,
  children: PropTypes.node,
};

Cluster.defaultProps = {
  justify: "",
  align: "",
  space: "",
  children: null,
};

export default Cluster;
