import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Dropdown } from "../../../shared/v1";

const LastViewedMarketReportSelect = ({
  isDisabled,
  lastViewedMarketReportRangeOptions,
  previousSelectedRange,
}) => {
  const [selectedOption, setSelectedOption] = useState(previousSelectedRange || null);

  useEffect(() => {
    if (isDisabled === true) {
      setSelectedOption(null);
    }
  }, [isDisabled]);

  return (
    <Row>
      <Col xs={12}>
        <label htmlFor="last_viewed_market_report_filter">Last Market Report View</label>
      </Col>
      <Col xs={6}>
        <input type="hidden" name="leads_filter[selected_last_viewed_market_report_filter][]" value="" />
        <Dropdown
          id="last_viewed_market_report_filter"
          name="leads_filter[selected_last_viewed_market_report_filter][]"
          options={lastViewedMarketReportRangeOptions}
          placeholder="Select..."
          isClearable={false}
          onUpdate={(option) => setSelectedOption(option.value)}
          isDisabled={isDisabled}
          value={selectedOption}
        />
      </Col>
    </Row>
  );
};

LastViewedMarketReportSelect.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  lastViewedMarketReportRangeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  previousSelectedRange: PropTypes.string.isRequired,
};

export default LastViewedMarketReportSelect;
