import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ShadowScroll = ({ children, className, fromColor = "tw-from-[rgba(0,0,0,0.1)]" }) => {
  const contentRef = useRef(null);
  useEffect(() => {
    const onScroll = () => {
      const contentDiv = contentRef.current;
      const wrapperDiv = contentDiv.parentElement;
      const shadowTopDiv = wrapperDiv.querySelector(".tw-top-0");
      const shadowBottomDiv = wrapperDiv.querySelector(".tw-bottom-0");
      const contentScrollHeight = contentDiv.scrollHeight - wrapperDiv.offsetHeight;
      const currentScroll = contentDiv.scrollTop / contentScrollHeight;
      shadowTopDiv.style.opacity = currentScroll;
      shadowBottomDiv.style.opacity = 1 - currentScroll;
    };
    contentRef.current.addEventListener("scroll", onScroll);
    return () => contentRef.current.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="tw-relative">
      <div
        className={`tw-content-[''] tw-absolute tw-left-0 tw-w-full tw-h-[8px] tw-top-0 tw-bg-gradient-to-b ${fromColor} tw-to-transparent tw-opacity-0`}
      />
      <div
        className={`tw-content-[''] tw-absolute tw-left-0 tw-w-full tw-h-[8px] tw-bottom-0 tw-bg-gradient-to-t ${fromColor} tw-to-transparent`}
      />
      <div ref={contentRef} className={`tw-overflow-auto ${className}`}>
        {children}
      </div>
    </div>
  );
};

ShadowScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fromColor: PropTypes.string,
};

ShadowScroll.defaultProps = {
  className: "",
  fromColor: "tw-from-[rgba(0,0,0,0.1)]",
};

export default ShadowScroll;
