import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Avatar from "@shared/v2/Avatar";
import TextLink from "@shared/v2/TextLink";
import {
  ArrowUpRightFromSquareSolidV6,
  EnvelopeRegularV6,
  MobileRegularV6,
  PhoneSolidV6,
  XmarkSolidV6,
} from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import Tooltip from "@shared/v2/Tooltip";

const BASE_STYLES =
  "tw-bg-[#fafafa] tw-border-none tw-outline tw-outline-[1px] tw-outline-neutral-gray-10 tw-rounded-[4px] tw-px-[12px] tw-py-[8px] tw-flex tw-flex-col tw-items-stretch tw-gap-[8px]";
const BUTTON_STYLES =
  "hover:tw-outline-theme-primary focus:tw-outline-theme-primary active:tw-outline-theme-primary active:tw-outline-[2px]";
const SELECTED_STYLES = "!tw-outline-theme-primary !tw-outline-[2px]";

const ContactCard = ({ contact, disabled, onSelect, onRemove, selected, showViewLink }) => {
  const fullName = useMemo(
    () => `${contact?.firstName || ""} ${contact?.lastName || ""}`.trim() || "No Name",
    [contact],
  );
  const Tag = onSelect ? "button" : "div";

  const content = (
    <Tag
      className={`${BASE_STYLES}${onSelect ? ` ${BUTTON_STYLES}` : ""}${selected ? ` ${SELECTED_STYLES}` : ""}`}
      onClick={disabled ? undefined : () => onSelect?.(contact)}
    >
      <div className="tw-w-full tw-flex tw-justify-between tw-gap-[8px]">
        <div
          className={`${disabled ? "tw-text-gray-30" : ""} tw-flex tw-items-center tw-gap-[8px] tw-overflow-hidden`}
        >
          <Avatar alt={fullName} src={contact.avatarUrl} />
          <span className="tw-text-14d tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden">
            {fullName}
          </span>
        </div>
        <div className="tw-flex tw-gap-[4px]">
          {showViewLink && (
            <TextLink
              href={`/people/${contact.slug}`}
              target="_blank"
              className="tw-flex tw-items-center tw-gap-[4px]"
            >
              View <ArrowUpRightFromSquareSolidV6 />
            </TextLink>
          )}
          {onRemove && (
            <IconButton className="tw-mr-[-6px]" schema="transparent" size="small" onClick={onRemove}>
              <XmarkSolidV6 size="l" className="tw-fill-[#999999]" />
            </IconButton>
          )}
        </div>
      </div>
      {contact.phoneDetails
        .filter((phone) => phone.value)
        .map((phone) => (
          <div
            key={phone.id}
            className="tw-text-14d tw-text-gray-50 tw-flex tw-items-center tw-gap-[4px] tw-overflow-hidden"
          >
            {phone.category === "mobile" ? (
              <MobileRegularV6 className="tw-fill-current" />
            ) : (
              <PhoneSolidV6 className="tw-fill-current" />
            )}
            <span className="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden">
              {phone.friendlyPhone}
            </span>
          </div>
        ))}
      {contact.emailDetails.map((email) => (
        <div
          key={email.id}
          className="tw-text-14d tw-text-gray-50 tw-flex tw-items-center tw-gap-[4px] tw-overflow-hidden"
        >
          <EnvelopeRegularV6 className="tw-fill-current" />
          <span className="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden">{email.value}</span>
        </div>
      ))}
    </Tag>
  );

  if (disabled) {
    return (
      <Tooltip
        placement="left"
        innerClassName="tw-max-w-[200px]"
        trigger={content}
        content="This lead cannot be merged with the lead you’ve selected because they are both associated with transactions."
      />
    );
  }

  return content;
};

ContactCard.propTypes = {
  contact: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.bool,
  showViewLink: PropTypes.bool,
};

ContactCard.defaultProps = {
  disabled: false,
  onSelect: null,
  onRemove: null,
  selected: false,
  showViewLink: false,
};

export default ContactCard;
