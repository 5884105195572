import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "../../../../shared/v1";
import HomeSearchingIcon from "./HouseSearchingIcon";
import HomeSearch from "./HomeSearch";
import errorShape from "../model/error";
import Error from "../Error";

const NoListing = ({ autoCompleteError, importSearchError }) => (
  <div className="tw-pb-[32px]">
    <div className="tw-rounded-lg tw-border tw-border-gray-10 tw-border-solid ">
      <div className="tw-p-[24px] tw-bg-gray-2_5 tw-leading-[20px] tw-text-[14px] tw-font-normal tw-text-gray-75">
        {autoCompleteError && <Error message={autoCompleteError.message} />}
        {importSearchError && <Error message={importSearchError.message} />}
        To display marketing materials created in Brivity Marketer, an MLS # is required.
        <div className="tw-pt-[16px]">Import the listing’s MLS #:</div>
        <div className="tw-grow tw-pt-[8px]">
          <HomeSearch />
        </div>
        <div className="tw-pt-[32px]">
          <Row className="tw-m-0 tw-mx-[0px] tw-flex tw-items-center tw-p-0">
            <Col
              className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pr-[16px] tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-50"
              size={6}
            >
              If the listing doesn’t have an MLS #, you can still create marketing materials in Brivity
              Marketer, but they won’t appear on this page.&nbsp;
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span // eslint-disable-line jsx-a11y/no-static-element-interactions
                onClick={() => window.open("https://ads.brivitymarketer.com/v2", "_blank")}
                className="tw-text-teal tw-cursor-pointer"
              >
                Launch Brivity Marketer
              </span>
            </Col>
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 " size={6}>
              <HomeSearchingIcon />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
);

NoListing.propTypes = {
  autoCompleteError: errorShape,
  importSearchError: errorShape,
};

NoListing.defaultProps = {
  autoCompleteError: null,
  importSearchError: null,
};
const mapStateToProps = (state) => ({
  autoCompleteError: state.tdpMarketingReducer.autoCompleteError,
  importSearchError: state.tdpMarketingReducer.importSearchError,
});

export default connect(mapStateToProps)(NoListing);
