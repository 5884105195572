/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { func, shape, string } from "prop-types";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import { TrashCanSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";
import TextComboInput from "@shared/v2/TextComboInput";
import TextButton from "@shared/v2/TextButton";
import IconPickerModal from "@shared/IconPickerModal";
import { isStrInputLen } from "../../ModalViews/helpers";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { addCustomLink, deleteCustomLink, setCustomLink } from "../../../reducers/contactsReducer";

const STYLES = {
  button:
    "tw-w-[44px] tw-h-[36px] tw-flex tw-items-center tw-justify-center !tw-rounded-[4px] !tw-border-gray-15 tw-border-solid tw-border tw-border-width-[1px] !tw-bg-transparent",
};

const { button } = STYLES;

const CustomLink = ({ onDelete, onTitleChange, setIconPickerCustomLink, customLink, onURLChange }) => {
  const { title, url, icon } = customLink;
  return (
    <div className="tw-flex tw-justify-between">
      <Button className={button} onClick={() => setIconPickerCustomLink(customLink)}>
        <i className={`${icon} tw-text-gray-75 tw-text-[20px]`} />
      </Button>
      <TextComboInput
        placeholder="Link Name"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        error={isStrInputLen(title)}
        isRequired
      />
      <TextComboInput
        placeholder="URL"
        value={url}
        onChange={(e) => onURLChange(e.target.value)}
        error={isStrInputLen(url)}
        isRequired
      />
      <Button className={button} onClick={onDelete}>
        <TrashCanSolidV6 size="xl" className="tw-text-gray-75" />
      </Button>
    </div>
  );
};

const CustomLinks = () => {
  const [iconPickerCustomLink, setIconPickerCustomLink] = useState(null);
  const dispatch = useDispatch();
  const { customLinksAttributes } = useContactPerson().loginUserAttributes;

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        {customLinksAttributes
          .filter((customLink) => !customLink._destroy)
          .map((customLink) => (
            <CustomLink
              key={customLink.order}
              customLink={customLink}
              onTitleChange={(title) => dispatch(setCustomLink({ order: customLink.order, title }))}
              onURLChange={(url) => dispatch(setCustomLink({ order: customLink.order, url }))}
              onDelete={() => dispatch(deleteCustomLink({ order: customLink.order }))}
              setIconPickerCustomLink={setIconPickerCustomLink}
            />
          ))}
      </div>
      <TextButton onClick={() => dispatch(addCustomLink())} className="tw-w-[90px] tw-p-0">
        <PlusSolidV6 size="s" />
        <span className="tw-ml-[4px]">Custom Link</span>
      </TextButton>
      <IconPickerModal
        onHide={() => setIconPickerCustomLink(false)}
        onSelect={(icon) => dispatch(setCustomLink({ order: iconPickerCustomLink.order, icon }))}
        show={iconPickerCustomLink}
      />
    </>
  );
};

export default CustomLinks;

CustomLink.propTypes = {
  onDelete: func.isRequired,
  setIconPickerCustomLink: func.isRequired,
  onTitleChange: func.isRequired,
  onURLChange: func.isRequired,
  customLink: shape({
    icon: string,
    title: string,
    url: string,
  }).isRequired,
};
