/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import IconButton from "../IconButton/IconButton";
import { ChevronLeft, ChevronRight } from "../Icomoon";

const ArrowLink = ({ targetPage, onClick, children }) => (
  <IconButton
    schema="tertiary"
    size="medium"
    onClick={() => onClick(targetPage)}
    data-cy="pagination-link"
    disabled={!targetPage}
  >
    {children}
  </IconButton>
);

export const LeftLink = ({ targetPage, onClick }) => (
  <ArrowLink onClick={onClick} targetPage={targetPage}>
    <ChevronLeft size="m" />
  </ArrowLink>
);
export const RightLink = ({ targetPage, onClick }) => (
  <ArrowLink onClick={onClick} targetPage={targetPage}>
    <ChevronRight size="m" />
  </ArrowLink>
);

LeftLink.propTypes = {
  targetPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

LeftLink.defaultProps = {
  targetPage: null,
};

RightLink.propTypes = {
  targetPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

RightLink.defaultProps = {
  targetPage: null,
};

ArrowLink.propTypes = {
  targetPage: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

ArrowLink.defaultProps = {
  targetPage: null,
};
