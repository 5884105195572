import React, { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Alert from "@shared/v2/Alert";
import Loading from "../../Goals/components/Loader";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";

const MyBrivityReferrals = () => {
  const featureFlags = useFeatureFlags();
  const [embedData, setEmbedData] = useState({});

  useEffect(() => {
    if (!featureFlags.referralRock) return;
    caseTransformingAxios
      .post("/referral_rock_embeds")
      .then(({ data }) => setEmbedData(data))
      .catch(() => setEmbedData({ error: true }));
  }, [featureFlags.referralRock]);

  if (!featureFlags.referralRock) return null;

  if (embedData.error) {
    return (
      <div className="tw-flex-1 tw-p-[24px]">
        <Alert
          variant="error"
          title="Whoops something went wrong!"
          text="There was an error loading My Product Referrals"
        />
      </div>
    );
  }

  if (!embedData.fullEmbedUrl) return <Loading />;

  return (
    <iframe
      className="tw-border-none"
      src={embedData.fullEmbedUrl}
      width="100%"
      height="100%"
      title="My Product Referrals"
    />
  );
};

export default MyBrivityReferrals;
