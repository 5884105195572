/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowsRepeatSolidV6 = (props) => (
  <Icon {...props} name="Arrows Repeat Solid V6">
    <path d="M5.163 5h5.425L9.295 6.293a.999.999 0 1 0 1.414 1.414l2.972-3a.999.999 0 0 0 0-1.414l-2.972-3a.999.999 0 1 0-1.414 1.414L10.588 3H5.163A5.38 5.38 0 0 0 .052 6.684a.994.994 0 0 0 .633 1.262 1.001 1.001 0 0 0 1.265-.631 3.383 3.383 0 0 1 3.213-2.316zm10.153 3.053A.971.971 0 0 0 14.972 8c-.419 0-.809.265-.949.684C13.591 10.069 12.297 11 10.837 11H5.412l1.293-1.293a.999.999 0 1 0-1.414-1.414l-2.972 3a.999.999 0 0 0 0 1.414l2.972 3a.999.999 0 1 0 1.414-1.414L5.412 13h5.425a5.378 5.378 0 0 0 5.109-3.684.993.993 0 0 0-.631-1.262z" />
  </Icon>
);

export default ArrowsRepeatSolidV6;
