import React from "react";
import Proptypes from "prop-types";
import transactionCss from "../PersonDetail/components/transactions.module.css";

const ListingPreviewCard = ({ containerClassName, listingData, clearListing }) => {
  const {
    beds,
    bedrooms,
    city,
    baths,
    bathsTotalDecimal,
    listAgentName,
    listOfficeName,
    mlsNumber,
    photos,
    postalCode,
    price,
    sqFt,
    squareFeet,
    state,
    streetAddress,
  } = listingData ?? {};
  return (
    <div className={`${transactionCss.previewCard} ${containerClassName}`}>
      <div className={transactionCss.header}>
        <span>{`${listAgentName ? `Listed by ${listAgentName}` : listOfficeName || "Listing"}`}</span>
        <div
          aria-label="delete"
          role="button"
          tabIndex={0}
          className={transactionCss.deleteIcon}
          onKeyDown={() => clearListing(null)}
          onClick={() => clearListing(null)}
        >
          <i className="fa fa-trash" />
        </div>
      </div>
      <div className={transactionCss.content}>
        <div className={transactionCss.listingImg}>
          <img src={photos.length ? photos[0].url : ""} alt="listing" />
        </div>
        <div className={transactionCss.details}>
          <p>{streetAddress?.toUpperCase() || "No address available"}</p>
          <p>{`${city || "-"}, ${state || "-"} ${postalCode || "-"}`}</p>
          {Number.isInteger(parseInt(price, 10)) && <p>${price.toLocaleString()}</p>}
          <p>{`${beds || bedrooms || "-"} beds | ${baths || bathsTotalDecimal || "-"} baths | ${sqFt?.toLocaleString() || squareFeet?.toLocaleString() || "-"} sqft`}</p>
        </div>
      </div>
      <div>{mlsNumber && <span className={transactionCss.mlsCredit}>{`${`MLS #${mlsNumber}`}`}</span>}</div>
    </div>
  );
};

export default ListingPreviewCard;

ListingPreviewCard.propTypes = {
  listingData: Proptypes.shape({ name: Proptypes.bool }),
  clearListing: Proptypes.func,
  containerClassName: Proptypes.string,
};

ListingPreviewCard.defaultProps = {
  listingData: null,
  clearListing: null,
  containerClassName: "",
};
