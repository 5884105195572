import React from "react";
import PropTypes from "prop-types";
import { TRANSACTION_STATUS } from "../../ListingTransactionModal";

import AddTransactionModal from "../../AddTransactionModal";

const WrapperAddTransactionModal = ({ columnStatus, ...props }) => (
  <AddTransactionModal
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
    defaultStatus={TRANSACTION_STATUS.find((status) => status.value.replace(" ", "") === columnStatus)}
  />
);
WrapperAddTransactionModal.propTypes = {
  columnStatus: PropTypes.string,
};

WrapperAddTransactionModal.defaultProps = {
  columnStatus: null,
};

export default WrapperAddTransactionModal;
