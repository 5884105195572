import React from "react";
import { string } from "prop-types";
import { SideContainer, WidgetContainer } from "../UI/Containers";
import Divider from "../UI/Divider";
import AutoPlans from "./AutoPlans";
import Dates from "./Dates";
import Marketing from "./Marketing";
import { tdpDetails } from "../../shared/HOCs/withRedux";
import { TYPE } from "../../shared/reduxRoutes/tdpDetailsRoutes";
import { isReferral } from "../shared/helpers";

const ReferralSideRight = () => (
  <SideContainer>
    <div className="tw-block">
      <WidgetContainer>
        <Dates />
      </WidgetContainer>
      <Divider />
      <WidgetContainer>
        <AutoPlans />
      </WidgetContainer>
    </div>
  </SideContainer>
);

const NonReferralSideRight = () => (
  <SideContainer>
    <div className="tw-block">
      <WidgetContainer>
        <Dates />
      </WidgetContainer>
      <Divider />
      <WidgetContainer>
        <AutoPlans />
      </WidgetContainer>
      <Divider />
      <WidgetContainer>
        <Marketing />
      </WidgetContainer>
    </div>
  </SideContainer>
);

const SideRight = ({ type }) => (!isReferral(type) ? <NonReferralSideRight /> : <ReferralSideRight />);

SideRight.propTypes = {
  type: string,
};

SideRight.defaultProps = {
  type: null,
};
export default tdpDetails({ WrappedComponent: SideRight, states: TYPE });
