export const RECURRING_WEEKDAYS = [
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays",
  "Sundays",
];

export const CONTINGENT_RECURRING = {
  contingent: "contingent",
  recurring: "recurring",
};

export const EVENTS = [
  "To-Do",
  "Appointment",
  "Call",
  "Text",
  "Email",
  "Mail",
  "Social Media",
  "Door Knock",
].map((event) => ({ label: event, value: event }));

export const DEFAULT_FILTERS = {
  primaryFilters: {
    byName: {
      overdue: {
        count: 0,
        tasks: [],
      },
      due_today: {
        count: 0,
        tasks: [],
      },
      upcoming: {
        count: 0,
        tasks: [],
      },
      contingent: {
        count: 0,
        tasks: [],
      },
      completed_at: {
        count: 0,
        tasks: [],
      },
    },
  },
  secondaryFilters: {
    byTeamMember: {},
    byAutoPlans: {},
    byUnAssigned: {
      count: 0,
      tasks: [],
    },
  },
  byContingentId: {},
};
