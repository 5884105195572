import axios from "axios";
import queryString from "query-string";
import ReactOnRails from "react-on-rails";

const dashboardPreferencePath = () => "/api/v4/dashboard_preferences";
const saveListingFiltersPath = "/listing_filters";
const listingsPath = (listing) => `/api/v4/listings${listing ? `/${listing.id}` : ""}`;
const listingsDeletionPath = () => `/listings/delete_multiple`;
const financialsPath = (listing) => `${listingsPath(listing)}/financials`;
const aggregationsPath = (filters) =>
  `${listingsPath()}/aggregations?${queryString.stringify(filters, { arrayFormat: "bracket" })}`;
const listingsQueryPath = (filters) =>
  `${listingsPath()}?${queryString.stringify(filters, { arrayFormat: "bracket" })}`;

const csrfToken = () => ReactOnRails.authenticityToken();
const apiWrapper = {
  saveFiltersModal: {
    create: async (data) =>
      axios.post(saveListingFiltersPath, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-csrf-token": csrfToken(),
        },
      }),
  },
  listings: {
    list: async (filters) => axios.get(listingsQueryPath(filters)),
    show: async (id) => axios.get(listingsPath({ id })),
    update: async (listing, changes) =>
      axios({
        method: "put",
        url: listingsPath(listing),
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: { listing: changes },
      }),
    delete: async (listing) =>
      axios({
        method: "delete",
        url: listingsDeletionPath(),
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: { ids: [listing.id] },
      }),
  },
  financials: {
    list: async (listing) => axios.get(financialsPath(listing)),
    index: async () => axios.get(aggregationsPath()),
    create: async (listing, detailData) =>
      axios({
        method: "post",
        url: financialsPath(listing),
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: { transaction_income: { ...detailData } },
      }),
  },
  preferences: {
    create: async ({ dashboard, preferences }) =>
      axios({
        method: "post",
        url: dashboardPreferencePath(),
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: { dashboard_preference: { dashboard, configuration: { ...preferences } } },
      }),
  },
};

export default apiWrapper;
