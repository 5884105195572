import React from "react";
import PropTypes from "prop-types";
import Linkify from "linkify-react";

import { runAtLabel, visibilityLabel } from "../../../../../../AssignedPlans/components/helpers";
import { RecurringDetailInnerFields } from "../../../../../../AssignedPlans/components/RecurringDetail";

import ActionDetailField from "./ActionDetailField";
import ActionRunAtFields from "./ActionRunAtFields";

import { eventDetailStringFor, shouldShowContingencyInfoFor, shouldShowEventFieldFor } from "./helpers";

/** Details for a Task action. */
const TaskActionDetails = ({ action }) => {
  const shouldShowRecurrenceInfo = action.recurringFrequency !== "never";
  const shouldShowInstructions = !!action.instructions;
  const shouldShowNotes = !!action.note;

  return (
    <>
      <div className="tw-pb-12px tw-border-solid tw-border-0 tw-border-b tw-border-neutral-gray-10">
        <ActionDetailField name="Task Name">
          <div className="tw-text-18d">{action.name}</div>
        </ActionDetailField>
      </div>

      <div className="tw-py-12px tw-border-solid tw-border-0 tw-border-b tw-border-neutral-gray-10">
        <div className="tw-pb-16px tw-grid tw-grid-cols-4 tw-justify-between">
          <ActionDetailField name={runAtLabel(action.type, action.state)}>
            <ActionRunAtFields
              actionState={action.state}
              actionCompletedAt={action.completedAt}
              actionRunAt={action.runAt}
            />
            {action.overdue && <div className="tw-text-brivity-coral-100">Overdue</div>}
          </ActionDetailField>

          <ActionDetailField
            name="Type"
            // TODO: This is supposed to be the _task's_ type, not the action's
            // type. Unfortunately though, it seems like that is not a part of
            // the current API response, so I'm skipping this for the MVP.
            value={action.type}
          />

          <span>
            <span className="tw-w-fit tw-flex tw-flex-col tw-items-center tw-gap-y-4px">
              <div className="tw-font-semibold">Priority</div>
              <div>
                {action.priority ? (
                  <img
                    width="24px"
                    height="24px"
                    src={`/images/task_priorities/${action.priority}.svg`}
                    alt={`Task Priority ${action.priority}`}
                  />
                ) : (
                  "Unset"
                )}
              </div>
            </span>
          </span>

          <ActionDetailField name="Task for" value={action.assignedToLabel} />
        </div>

        <div className="tw-grid tw-grid-cols-4 tw-gap-x-12px tw-justify-between">
          <ActionDetailField name="Visibility" value={visibilityLabel(action.visibility)} />
          <ActionDetailField
            name="Event"
            value={eventDetailStringFor(action)}
            hideUnless={shouldShowEventFieldFor(action)}
          />
          <ActionDetailField name="Recurring" hideUnless={shouldShowRecurrenceInfo}>
            <RecurringDetailInnerFields
              recurringFrequency={action.recurringFrequency}
              recurringDaysOfFrequency={action.recurringDaysOfFrequency}
              recurringLimit={action.recurringLimit || 0}
            />
          </ActionDetailField>
          <ActionDetailField
            name="Contingent"
            value={action.parentAction?.name}
            hideUnless={shouldShowContingencyInfoFor(action)}
          />
        </div>
      </div>

      <div className="tw-py-12px">
        <ActionDetailField name="Instructions" hideUnless={shouldShowInstructions}>
          <Linkify
            className="tw-whitespace-pre-wrap"
            tagName="p"
            options={{ defaultProtocol: "https", target: "_blank" }}
          >
            {action.instructions}
          </Linkify>
        </ActionDetailField>

        <ActionDetailField name="Notes" hideUnless={shouldShowNotes}>
          <Linkify
            className="tw-whitespace-pre-wrap"
            tagName="p"
            options={{ defaultProtocol: "https", target: "_blank" }}
          >
            {action.note}
          </Linkify>
        </ActionDetailField>
      </div>
    </>
  );
};

TaskActionDetails.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,

    priority: PropTypes.number,
    overdue: PropTypes.bool,

    completedAt: PropTypes.string,
    runAt: PropTypes.string,

    instructions: PropTypes.string,
    note: PropTypes.string,

    recurringDaysOfFrequency: PropTypes.arrayOf(PropTypes.number),
    recurringFrequency: PropTypes.string,
    recurringLimit: PropTypes.string,

    assignedToLabel: PropTypes.string.isRequired,
    parentAction: PropTypes.shape({ name: PropTypes.string.isRequired }),
    visibility: PropTypes.bool,
  }).isRequired,
};

export default TaskActionDetails;
