import { connect } from 'react-redux';
import TransactionFinancial from '../components/transaction/TransactionFinancial';
import * as actions from '../actions/transaction';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({ ...state.transactionFinancial });

// Don't forget to actually use connect!
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, actions)(TransactionFinancial);
