/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MobileScreenSolidV6 = (props) => (
  <Icon {...props} name="Mobile Screen Solid V6">
    <path d="M12 0H4a1.5 1.5 0 0 0-1.5 1.5v13A1.5 1.5 0 0 0 4 16h8a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 12 0zM9.5 13.972c0 .303-.225.528-.5.528H6.972a.501.501 0 0 1 .028-1h2c.275 0 .5.225.5.472zm2-1.972h-7V2h7v10z" />
  </Icon>
);

export default MobileScreenSolidV6;
