/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";

import { every } from "lodash";

import { caseTransformingAxios as axios } from "../../../shared/v2/caseTransformingAxios";
import { tryCatchHandlr } from "../../../shared/Utilities";
import { GraduationCapSolidV6, PlaceCustomv6, UserGraduateSolidV6 } from "../../../shared/v2/Icomoon";

import ResourceLaunchers from "./ResourceLaunchers";
import Loader from "../../../Dashboard/components/Loader";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const checkLinkUrls = (placeLaunchers) => !every(placeLaunchers, ([, item]) => item.linkUrl === "/");

const DEFAULT_PLACE_RESOURCES = {
  "PLACE Academy": { Icon: GraduationCapSolidV6, linkUrl: "https://learn.place.com/enrollments" },
  "Report Cards": { Icon: UserGraduateSolidV6, linkUrl: "https://learn.place.com/manage/reports/groups" },
  "Our PLACE": { Icon: PlaceCustomv6, linkUrl: "/" },
};

const PlaceResources = () => {
  const [resourcesByLabel, setResourcesByLabel] = useState(DEFAULT_PLACE_RESOURCES);
  const [isLoading, setIsLoading] = useState(true);
  const { isAdmin } = useCurrentUser().account;

  // Sets linkURL in resourcesByLabel on GET app_links;
  useEffect(() => {
    (async () => {
      const req = axios.get("/api/v4/app_links");
      const [res] = await tryCatchHandlr(req);

      setIsLoading(false);
      if (res) {
        setResourcesByLabel((prev) => {
          res.data?.forEach?.(({ label, linkUrl }) => {
            if (prev[label]) prev[label].linkUrl = linkUrl;
          });
          return prev;
        });
      }
    })();
  }, []);

  useEffect(() => {
    setResourcesByLabel((prev) => {
      if (!isAdmin) {
        delete prev["Report Cards"];
        return prev;
      }
      return DEFAULT_PLACE_RESOURCES;
    });
  }, [isAdmin]);

  const iterativeResources = Object.entries(resourcesByLabel);

  return (
    checkLinkUrls(iterativeResources) && (
      <div className="tw-p-[16px] tw-border-b-color-ebebeb tw-border-solid tw-border-l-0 tw-border-b-[3px] tw-border-r-0 tw-border-t-0">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-8px">
          <span className="tw-text-black">PLACE Resources</span>
          <div className="tw-w-[40px] tw-h-[22px] tw-p-[3px] tw-pl-[8px] tw-pr-[8px] tw-rounded-full tw-bg-semantic-blue-100 tw-text-10px tw-text-white tw-flex tw-items-center tw-justify-center">
            NEW
          </div>
        </div>
        <div className="tw-flex tw-justify-between tw-flex-wrap">
          {isLoading && <Loader containerClass="tw-pt-0 tw-flex tw-items-center" />}
          <ResourceLaunchers
            resources={iterativeResources
              .map(
                ([label, { Icon, linkUrl }]) =>
                  linkUrl !== "/" && {
                    label,
                    Icon,
                    linkUrl,
                  },
              )
              .filter(Boolean)}
          />
        </div>
      </div>
    )
  );
};

export default PlaceResources;
