import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment/moment';
import properties from './properties.module.css'
import { formatedPrice } from '../../../shared/ListingAlertHelpers';
import ReactTooltip from 'react-tooltip'

const Properties = ({ stats }) => {
  return (
    <Row  className={properties.listingGrid}>
      {
        stats.map(({ bathrooms, bedrooms, city, favorite, history_ext, photo, price, state, street, url, zip }, index) => (
          <Col key={index} xs={3} className={properties.listingContainer}>
            <div>
              <div className={properties.cardBody}>
                <div style={{ overflow: "hidden" }}>
                  { favorite && <i className={`${properties.favorited} fa fa-heart`} /> }
                  <a
                    href={url}
                    className={properties.listingPhoto}
                    target="_blank"
                    style={{ backgroundImage: `url(${photo})`}}
                  >
                    <div className={properties.photoText}>
                      <div className={properties.price}>{formatedPrice(price)}</div>
                      <div className={properties.address}>{street}</div>
                      <div className={properties.address}>{city}, {state} {zip}</div>
                      <div className={properties.bedsBaths}>{bedrooms} Bed | {bathrooms} Bath</div>
                    </div>
                  </a>
                </div>
                <div className={properties.histories}>
                  <div className={properties.header}>
                    <div className={properties.historyLabel}>View History</div>
                    <div className={properties.viewBubble}>VIEWS: {history_ext.length}</div>
                  </div>
                  {
                    history_ext.map((hist, index) => (
                      <div key={index} className={properties.dateAndTime}>
                        <span className={properties.date}>{moment(hist.viewed_at).format("MM/D/YYYY")}</span>
                        <span> at {moment(hist.viewed_at).format("h:mm A")}</span>
                        { 
                          hist.is_mobile && <i className="fa fa-mobile empty float-right" data-tip="Viewed from Brivity Home App" /> 
                        }
                        <ReactTooltip
                          className={properties.propertiesTooltip}
                          place="right"
                          arrowColor="#666666" 
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  );
};

export default Properties;
