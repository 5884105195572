/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BookmarkSolidV6 = (props) => (
  <Icon {...props} name="Bookmark Solid V6">
    <path d="M3.5 0h9A1.5 1.5 0 0 1 14 1.5v13.741a.76.76 0 0 1-1.196.622L8.001 12.5l-4.804 3.363a.76.76 0 0 1-1.196-.622V1.5a1.5 1.5 0 0 1 1.5-1.5z" />
  </Icon>
);

export default BookmarkSolidV6;
