/* eslint-disable react/prop-types, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, react/jsx-props-no-spreading */

import React, { forwardRef } from "react";
import PillButton from "./shared/PillButton";
import AppliedFiltersWithSave from "./shared/Filters/AppliedFiltersWithSave";
import ColumnsDropdown from "./ColumnsDropdown";
import { sortBy } from "../utils/helpers";
import SortingMenu from "./shared/SortingMenu";
import ViewToggle from "../../SearchListings/components/ViewToggle";

const prepareOptions = (options) =>
  sortBy(
    Object.keys(options).map((key) => ({ id: key, ...options[key] })),
    "position",
  );

const Headings = forwardRef(
  (
    {
      openFilterModal,
      removeFilter,
      clearFilters,
      rawFilters,
      openSaveFiltersModal,
      closeSaveFiltersModal,
      saveListingFilters,
      saveFiltersData,
      filters,
      renderableColumns,
      setRenderableColumns,
      reorderColumn,
      updateDisplay,
      display,
      order,
      setOrder,
    },
    ref,
  ) => (
    <div ref={ref} className="tw-z-30 tw-space-y-10px tw-px-[16px] tw-bg-white tw-shadow tw-sticky tw-top-0">
      <div className="tw-flex tw-justify-between tw-items-center tw-pt-10px tw-pb-10px">
        <div className="tw-flex tw-items-center tw-mb-4px">
          <h1 className="tw-text-27px tw-text-gray-99 tw-m-0 tw-font-light">Transactions</h1>
        </div>

        <div className="tw-flex tw-items-center tw-space-x-6">
          <DisplaySwitch display={display} updateDisplay={updateDisplay} />
          {display === "list" && (
            <ColumnsDropdown
              options={prepareOptions(renderableColumns)}
              onChange={setRenderableColumns}
              onDrag={reorderColumn}
            />
          )}
          <SortingMenu setOrder={setOrder} order={order} />
          <PillButton
            onClick={openFilterModal}
            className="tw-border-teal tw-bg-teal tw-cursor-pointer tw-text-white tw-px-20px tw-py-8px"
            data-cy="filter-opener"
          >
            <span className="tw-font-bold tw-align-middle tw-uppercase" role="button">
              Filter
            </span>
          </PillButton>
        </div>
      </div>
      {filters.length > 0 && (
        <div className="tw-flex tw-justify-start tw-flex-wrap tw-pb-[10px]">
          <AppliedFiltersWithSave
            openSaveFiltersModal={openSaveFiltersModal}
            closeSaveFiltersModal={closeSaveFiltersModal}
            saveFiltersData={saveFiltersData}
            saveListingFilters={saveListingFilters}
            rawFilters={rawFilters}
            filters={filters}
            onChange={removeFilter}
            clearFilters={clearFilters}
          />
        </div>
      )}
    </div>
  ),
);

const DisplaySwitch = ({ display, updateDisplay }) => {
  const activeListing = display === "list";

  return (
    <>
      <ViewToggle
        onClick={() => !activeListing && updateDisplay()}
        isActive={activeListing}
        iconClassName="fa-th-list"
        mxOverride=" "
      />
      <ViewToggle
        onClick={() => activeListing && updateDisplay()}
        isActive={!activeListing}
        iconClassName="fa-th-large"
        mxOverride=" "
      />
    </>
  );
};

export default Headings;
