import React from 'react';

const PreviousPageLink = ({ prevPage, onClick }) => {
  const leftArrow = <i className="fa fa-long-arrow-left" aria-hidden="true" />;

  if (prevPage) { 
    return (
      <a className='prev_page' onClick={() => onClick(prevPage)}>
        { leftArrow } Previous
      </a>
    );
  }
  return <span className="prev_page disabled">{ leftArrow } Previous</span>;
};

const PageLinks = ({ totalPages, currentPage, onClick }) => {
  const range = 4;

  const pageGap = (key) => <span key={key} className="gap">...</span>;

  const pageLink = (pageNumber) => {
    const key = `pagination-${pageNumber}`;

    if (pageNumber === currentPage){
      return <em key={key} className="current">{pageNumber} </em>
    }

    return <a key={key} onClick={()=> onClick(pageNumber)}>{pageNumber} </a>
  };

  const leftPages = (currentPage > range) ?
    [pageLink(1), pageLink(2), pageGap(totalPages + 1)] : [];

  const rightPages = ((totalPages - currentPage) > range ) ?
    [pageGap(totalPages + 2), pageLink(totalPages - 1), pageLink(totalPages)] : [];
  
  const start = Math.max(currentPage - range, 0);

  const end = Math.min(currentPage + range, totalPages);

  const centerPageRange = [...Array.from(new Array(end - start),(val,index)=>index+start)];

  const centerPages = centerPageRange.map(i => { return pageLink(i + 1) });

  return leftPages.concat(centerPages).concat(rightPages);
}

const NextPageLink = ({ nextPage, onClick }) => {
  const rightArrow = <i className="fa fa-long-arrow-right" aria-hidden="true" />

  if(nextPage){
    return (
      <a className='next_page' onClick={()=>onClick(nextPage)}>
        Next {rightArrow}
      </a>
    );
  }

  return <span className='next_page disabled'>Next {rightArrow}</span>;
}

const Pagination = ({
  currentPage,
  nextPage,
  prevPage,
  totalPages,
  totalCount,
  onGoToPage
}) => {
  const handleGoToPage = (pageNum) => {
    pageNum && onGoToPage && onGoToPage(pageNum);
  }

  return (
    <div className="pagination" style={{ cursor: "pointer" }}>
      <PreviousPageLink prevPage={prevPage} onClick={handleGoToPage} />
      { " " }
      <PageLinks
        totalPages={totalPages}
        currentPage={currentPage}
        onClick={handleGoToPage}
      />
      { " " }
      <NextPageLink nextPage={nextPage} onClick={handleGoToPage} />
    </div>
  )
}

export default Pagination;
