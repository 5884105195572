import React from "react";
import PropTypes from "prop-types";

const ModalHeader = ({ handleClear }) => (
  <span style={{ color: "#59C4C4" }}>
    <div
      style={{
        fontSize: "2.25em",
        fontWeight: "500",
        padding: "10px 0",
      }}
    >
      Filter
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <span
        style={{
          fontSize: "12px",
          padding: "20px 0",
          fontWeight: "300",
          textTransform: "capitalize",
        }}
        className="btn  pull-right"
        onClick={(e) => handleClear(e)}
      >
        Clear All
      </span>
    </div>
  </span>
);

ModalHeader.propTypes = {
  handleClear: PropTypes.func.isRequired,
};

export default ModalHeader;
