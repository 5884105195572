import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import button from './button.module.css';

class ButtonDropDown extends Component {
  state = {
    expanded: false
  }

  collapse = () => this.setState({ expanded: false })

  expand = () => {
    if (!this.state.expanded) {
      this.setState({ expanded: true })
    }
  }

  handleSelect = (option) => {
    this.setState({ expanded: false })
    this.props.handleSelect(option)
  }

  label = (value, options) => {
    const optionObject = options.find(option => option.value === value)
    return optionObject.label.toUpperCase()
  }

  render() {
    const content = {
      background: "#fff",
      borderRadius: "0 0 5px 5px",
      padding: "10px 0 0 0",
      position: "absolute",
      left: "0",
      marginTop: "10px",
      boxShadow: "rgba(224, 224, 224, 0.4) 0px 2px 4px 0px",
      minWidth: "105px",
      zIndex: "9999",
      fontSize: "12px",
      cursor: "pointer"
    }
    const { value, options } = this.props
    const dropdown = (
      <div style={content}>
        {
          options.map((option) => (
            <Col xs={12} className="m-b-10" key={option.value}>
              <div onClick={e => this.handleSelect(option)}>
                {option.label.toUpperCase()}
              </div>
            </Col>
          ))
        }
      </div>
    )

    return (
      <div
        className={`${button.btnSm} ${button.btnDropdown} ${button.btnGray}`}
        tabIndex="0"
        onClick={() => { this.expand() }}
        onBlur={() => { this.collapse() }}
        style={{ fontSize: "12px", color: "#666", fontWeight: "bold", padding: "5px 10px 5px 15px", outline: "0" }}
      >
        <span>{this.label(value, options)}</span>
        <i className="fa fa-chevron-down" style={{ marginLeft: "6px", fontSize: "11px" }} />
          <Row style={{ position: "relative" }}>
            { this.state.expanded && dropdown }
          </Row>
      </div>
    )
  }
}

export default ButtonDropDown;
