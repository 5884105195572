import React from "react";
import PropTypes from "prop-types";

import schemaStyle from "./ButtonToggleOption.styles";

const ButtonToggleOption = ({ className, name, label, value, checked, onChange, ...otherProps }) => (
  <label className="tw-m-0 tw-cursor-pointer">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className="tw-sr-only tw-peer"
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />

    <div
      role="radio"
      aria-checked={checked}
      className={`
        tw-inline-flex tw-justify-center tw-items-center
        tw-w-[36px] tw-h-[36px]
        tw-border-solid tw-border-[1px] tw-rounded-[2px]
        tw-text-14d
        ${schemaStyle.regular}
        ${className}
      `}
    >
      {label}
    </div>
  </label>
);

ButtonToggleOption.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

ButtonToggleOption.defaultProps = {
  className: "",
  name: null,
  checked: false,
  onChange: null,
};

export default ButtonToggleOption;
