import React from "react";

const CmaCreatedByEvent = ({ event }) => {
  const { report_created_by, report_location, report_url } = event.timeline_fields;

  return (
    <div className="timeline-event market-report-timeline-event">
      <div className="row">
        <div className="col-xs-1">
          <div className="timeline-icon-circle">
            <img src="/images/CMA-icon.svg" alt="CMA Icon" />
          </div>
        </div>
        <div className="col-xs-7">
          <div>
            <span className="bold-detail">{report_created_by}</span>
            {" created a CMA"}
          </div>
          <div className="timeline-description-text">
            <div>
              Location:{" "}
              <a
                href={report_url}
                target="_blank"
                className="tw-text-brivity-blue-100 hover:tw-text-brivity-blue-100 hover:tw-underline"
              >
                {report_location}
              </a>
            </div>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );
};

export default CmaCreatedByEvent;
