import React from "react";
import { string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { ADDRESS_FIELD } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import ReferralDesiredAddressAdd from "../ReferralDesiredAddressAdd";
import ReferralDesiredAddressRead from "../ReferralDesiredAddressRead";

const ReferralDesiredAddressVal = ({ addressField }) =>
  addressField ? <ReferralDesiredAddressRead /> : <ReferralDesiredAddressAdd />;

ReferralDesiredAddressVal.propTypes = {
  addressField: string,
};

ReferralDesiredAddressVal.defaultProps = {
  addressField: null,
};

export default tdpDetails({
  WrappedComponent: ReferralDesiredAddressVal,
  states: ADDRESS_FIELD,
});
