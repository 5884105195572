import axios from "axios";

const basePath = "/api/v4/transactions/details";
const listingPath = (listingUuid) => `${basePath}/${listingUuid}`;
const options = {
  authenticity_token: window.ReactOnRails.authenticityToken(),
};

const apiClient = {
  listDetails: (listingUuid) => axios.get(listingPath(listingUuid), options),
  listAccountSources: (listingUuid) => axios.get(`${listingPath(listingUuid)}/sources`, options),
  listAccountPropertyTypes: (listingUuid) => axios.get(`${listingPath(listingUuid)}/property_types`, options),
  save: (listingUuid, payload) => axios.put(listingPath(listingUuid), { ...options, ...payload }),
  deleteListingImage: (imageDeletionUrl) =>
    axios({
      url: imageDeletionUrl,
      method: "delete",
      headers: {
        Accept: "text/javascript",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  updatePropertyDetail: (uuid, payload) =>
    axios.put(`${listingPath(uuid)}`, {
      ...options,
      listing: {
        details: [payload],
      },
    }),
};

export default apiClient;
