import React from "react";
import transaction_css from "../../PersonDetail/components/transactions.module.css";

const ListingsAutocompleteResults = ({ selectResult, childPosition, setChildPosition, searchResults, deleteSearch }) => {
  return (
    <>
      {searchResults.length > 0 && (
        <div className={`${transaction_css.searchResults} tw-max-h-[400px]`}>
          <div className={transaction_css.resultChildren}>
            {searchResults.map((result, index, array) => (
              <AutocompleteResult
                key={`${result.category}-${result.value}-${index}`}
                result={result}
                index={index}
                array={array}
                selectResult={selectResult}
                deleteSearch={deleteSearch}
                childPosition={childPosition}
                setChildPosition={setChildPosition}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ListingsAutocompleteResults;

const AutocompleteResult = ({result, index, array, selectResult, deleteSearch, childPosition, setChildPosition}) => {
  const prevResult = array[index - 1];
  const isHover = childPosition === index;
  const isSavedSearch = result.category === "Saved Searches";
  const categoryHeader = categoryHandler(prevResult, result);

  const displayValue = result.category === "Office" || result.category === "Agent"
    ? `${result.value} — #${result.selected_fields.officeIds?.[0] || result.selected_fields.agentId}`
    : result.value;

  const className = `
    tw-flex tw-flex-row tw-justify-between tw-px-12px tw-py-6px tw-cursor-pointer
    ${isHover ? "tw-bg-tinted-gray-50" : ""}
  `;

  const handleDelete = (e, searchId) => {
    e.stopPropagation();
    deleteSearch(searchId);
  };

  return (
    <div>
      {categoryHeader && (
        <div className={`tw-px-12px tw-py-8px tw-bg-tinted-gray-50 tw-font-semibold tw-text-12px`}>
          {categoryHeader}
        </div>
      )}
      <div
        className={className}
        onMouseOver={() => setChildPosition(index)}
        onMouseDown={() => selectResult(result)}
      >
        <span className="tw-w-full">{displayValue}</span>
        {isSavedSearch && isHover
          ? <i className="fa fa-times tw-mr-16px tw-text-gray-75 tw-cursor-pointer" onMouseDown={(e) => handleDelete(e, result.id)} />
          : ""
        }
      </div>
    </div>
  );
};

const categoryHandler = (prevResult, result) => {
  if (prevResult?.category === result.category) {
    return null;
  }

  switch (result.category) {
    case "NeighborhoodCityState":
      return "Neighborhood";
    case "CityState":
      return "City";
    case "CountyState":
      return "County";
    default:
      return result.category;
  }
};
