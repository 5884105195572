import React, { useEffect, useState } from "react";
import axios from "axios";
import Proptypes from "prop-types";
import {
  ApartmentSolidV6,
  Home,
  HouseUserSolidV6,
  MultiUser,
  PlusSolidV6,
  ShareNodesSolidV6,
} from "@shared/v2/Icomoon";
import Modal from "@shared/v2/Modal";
import ListingTransactionModal from "./ListingTransactionModal";
import ReferralTransactionModal from "./ReferralTransactionModal";
import IconButton from "../shared/v2/IconButton/IconButton";
import colors from "../Reports/components/DashboardShared/helpers/colors";
import { useCurrentUser } from "../reducers/layoutReducer/selectors";

const TransactionButton = ({ onClick, Icon, text }) => {
  const isPlaceTheme = document.body.classList.contains("place-theme");

  return (
    <button
      type="button"
      className={`transaction-type-picker tw-bg-white tw-flex tw-items-center tw-justify-start tw-px-[40px] tw-py-[24px] tw-gap-[16px] tw-rounded
      tw-transition-colors tw-border-solid tw-border-[1px] tw-border-gray-10 tw-ring-theme-primary
      hover:tw-text-theme-primary hover:tw-border-theme-primary
      ${isPlaceTheme ? "active:tw-bg-[rgb(230,237,250)]" : "active:tw-bg-[rgb(238,249,249)]"} active:tw-ring-[1px] active:tw-border-theme-primary active:tw-text-theme-primary
      ${isPlaceTheme ? "focus:tw-bg-[rgb(230,237,250)]" : "focus:tw-bg-[rgb(238,249,249)]"} focus:tw-outline-none focus:tw-ring-[1px] focus:tw-border-theme-primary focus:tw-text-theme-primary`}
      onClick={onClick}
      onKeyUp={(e) => ["Enter", " "].includes(e.key) && onClick()}
    >
      <Icon size="xxl" className="tw-fill-current" />
      <span className="tw-text-[18px] tw-font-bold transaction-type">{text}</span>
    </button>
  );
};

const loadSources = async () => {
  const response = await axios.get("/sources");
  return response.data.sources.map((source) => ({ label: source.text, value: source.id }));
};

const loadPropertyTypes = async () => {
  const response = await axios.get("/transaction_property_types");
  return response.data.property_types.map((propertyType) => ({
    label: propertyType.name,
    value: propertyType.id,
  }));
};

const loadMlses = async (mlsIds = [], currentUserId) => {
  if (mlsIds.length) return mlsIds;
  // This route throws a 500 when there are no MLS IDs which...shouldn't happen
  // So this try/catch is necessary for an otherwise normal response
  try {
    const response = await axios.get(`/users/${currentUserId}/mls_ids`);
    return response.data.ids;
  } catch (err) {
    return null;
  }
};
const AddTransactionModal = ({
  isStandalone,
  disableReferralBtn,
  newMilestoneId,
  showModal,
  defaultStatus,
  onClose,
  personId,
  personName,
}) => {
  const currentUser = useCurrentUser();
  const [state, _setState] = useState({
    showModalState: false,
    showListingModal: false,
    showPendingModal: false,
    showReferralModal: false,
    sources: [],
    mlsIds: [],
    propertyTypes: [],
    defaultPropertyType: "",
  });

  const setState = (newState) => _setState((currentState) => ({ ...currentState, ...newState }));

  const loadDependencies = async () => {
    const { showModalState } = state;

    if (!showModalState && !showModal) return;

    const [sources, propertyTypes, mlsIds] = await Promise.all([
      loadSources(),
      loadPropertyTypes(),

      loadMlses(state.mlsIds, currentUser.id),
    ]);

    const defaultPropertyType = (propertyTypes || []).find((p) => p.label === "Residential") || {
      label: "Residential",
      value: -1,
    };

    setState({ sources, propertyTypes, defaultPropertyType, mlsIds });
  };

  useEffect(() => {
    loadDependencies();
  }, [state.showModalState, showModal]);

  const showModalfunc = () => setState({ showModalState: true });

  const closeModal = () => {
    if (onClose) onClose();
    setState({ showModalState: false });
  };

  const addSellerTransaction = () => {
    closeModal();
    setState({ transactionType: "SellerListing", showTransactionModal: true });
  };

  const addBuyerTransaction = () => {
    closeModal();
    setState({ transactionType: "BuyerListing", showTransactionModal: true });
  };

  const addTenantTransaction = () => {
    closeModal();
    setState({ transactionType: "TenantListing", showTransactionModal: true });
  };

  const addLandlordTransaction = () => {
    closeModal();
    setState({ transactionType: "LandlordListing", showTransactionModal: true });
  };

  const addReferralTransaction = () => {
    closeModal();
    setState({ transactionType: "ReferralListing", showReferralModal: true });
  };

  const closeTransaction = () => setState({ showTransactionModal: false });

  const closeReferralModal = () => {
    setState({ showReferralModal: false });
  };

  const [defaultStatusState, setDefaultStatus] = useState(null);
  useEffect(() => {
    if (defaultStatus) setDefaultStatus(defaultStatus);
  }, [defaultStatus]);
  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}

      {!isStandalone && (
        <IconButton
          schema="transparent"
          onClick={showModalfunc}
          className={`${colors.theme === "place" ? "tw-text-white" : ""} hover:tw-bg-gray/10`}
          data-cy="listing-add-0"
        >
          <PlusSolidV6 />
        </IconButton>
      )}
      <Modal
        className="add-transaction-modal tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[380px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        id="add-transaction-modal"
        show={state.showModalState || showModal}
        onHide={closeModal}
      >
        <Modal.Header title="Select Transaction Type" />
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[20px]">
          <TransactionButton text="Buyer" Icon={MultiUser} onClick={addBuyerTransaction} />
          <TransactionButton text="Seller" Icon={Home} onClick={addSellerTransaction} />
          <TransactionButton text="Tenant" Icon={HouseUserSolidV6} onClick={addTenantTransaction} />
          <TransactionButton text="Landlord" Icon={ApartmentSolidV6} onClick={addLandlordTransaction} />
          {!disableReferralBtn && (
            <TransactionButton text="Referral" Icon={ShareNodesSolidV6} onClick={addReferralTransaction} />
          )}
        </Modal.Body>
      </Modal>
      {state.showTransactionModal && (
        <ListingTransactionModal
          transactionType={state.transactionType}
          newMilestoneId={newMilestoneId}
          closeModal={closeTransaction}
          currentUserId={currentUser.id}
          propertyTypes={state.propertyTypes}
          defaultPropertyType={state.defaultPropertyType}
          sources={state.sources}
          mlsIds={state.mlsIds}
          defaultStatus={defaultStatusState}
          personId={personId}
        />
      )}
      {state.showReferralModal && (
        <ReferralTransactionModal
          closeModal={closeReferralModal}
          fullName={currentUser.fullName}
          currentUserTeamId={currentUser.person.id}
          sources={state.sources}
          personId={personId}
          personName={personName}
        />
      )}
    </>
  );
};

export default AddTransactionModal;

TransactionButton.propTypes = {
  onClick: Proptypes.func,
  Icon: Proptypes.node,
  text: Proptypes.string,
};

TransactionButton.defaultProps = {
  onClick: null,
  Icon: null,
  text: "",
};

AddTransactionModal.propTypes = {
  showModal: Proptypes.bool,
  onClose: Proptypes.func,
  defaultStatus: Proptypes.string,
  isStandalone: Proptypes.bool,
  disableReferralBtn: Proptypes.bool,
  newMilestoneId: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  personId: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  personName: Proptypes.string,
};

AddTransactionModal.defaultProps = {
  showModal: false,
  onClose: null,
  defaultStatus: null,
  isStandalone: false,
  disableReferralBtn: false,
  newMilestoneId: null,
  personId: null,
  personName: null,
};
