const setMinMax = (val, min, max) => {
  let value = val;
  if (val <= min) {
    value = max;
  }
  return value;
};

export const onMinRangeChangeFn = (maxVal, numMaxVal, setTmpMin, onChange, valueDisplay) => (e) => {
  let value = +e.target.value.replace(/[^0-9]/g, "");
  if (value > numMaxVal) {
    value = numMaxVal - 1000;
  }

  setTmpMin(valueDisplay(value));
  onChange({ min: value.toString(), max: maxVal });
};

export const onMaxRangeChangeFn = (minVal, numMinVal, setTmpMax, onChange, valueDisplay, max) => (e) => {
  let value = +e.target.value.replace(/[^0-9]/g, "");

  if (value < numMinVal) {
    value = numMinVal + 1000;
  } else if (value === max) {
    value = null;
  }

  setTmpMax(valueDisplay(value));
  onChange({ min: minVal, max: value?.toString() });
};

export const onMinInputChangeFn = (minVal, maxVal, setTmpMin, onChange) => (e) => {
  const value = +e.target.value.replace(/[^0-9]/g, "");
  setTmpMin(e.target.value);

  if (minVal !== value) {
    onChange({ min: value?.toString(), max: maxVal });
  }
};

export const onMaxInputChangeFn = (minVal, maxVal, setTmpMax, onChange) => (e) => {
  const value = +e.target.value.replace(/[^0-9]/g, "");
  setTmpMax(e.target.value);
  if (maxVal !== value) {
    onChange({ min: minVal, max: value.toString() });
  }
};

export const onMinInputBlurFn =
  (numMaxVal, setTmpMin, valueDisplay, min, max, minVal, maxVal, onChange) => (e) => {
    let value = +e.target.value.replace(/[^0-9]/g, "");
    if (value > numMaxVal) {
      value = numMaxVal - 1;
    }
    value = setMinMax(value, min - 1, max);
    setTmpMin(valueDisplay(value));
    if (minVal !== value) {
      onChange({ min: value.toString(), max: maxVal });
    }
  };

export const onMaxInputBlurFn =
  (numMinVal, setTmpMax, valueDisplay, min, max, minVal, maxVal, onChange) => (e) => {
    let value = +e.target.value.replace(/[^0-9]/g, "");
    if (value > "" && value < numMinVal) {
      value = numMinVal + 1;
    }
    // value = setMinMax(value, min, max);
    setTmpMax(valueDisplay(value));
    if (maxVal !== value) {
      onChange({ min: minVal, max: value.toString() });
    }
  };

export const isNumeric = (e) => {
  const key = e.which ? e.which : e.keyCode;
  const ret =
    key === 8 || // backspace
    key === 9 || // tab
    key === 13 || // enter
    key === 46 || // delete
    // key == 110 || // decimal point
    key === 188 || // comma
    // key == 190 || // period
    (key >= 35 && key <= 40) || // arrows, home, end
    (key >= 48 && key <= 57) || // numbers
    (key >= 96 && key <= 105); // numpad numbers
  if (!ret) {
    e.preventDefault();
  }
};
