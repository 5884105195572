/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HeartOutline = (props) => (
  <Icon {...props} name="Heart Outline">
    <path d="M13.951 2.362C12.251.933 9.626 1.148 8 2.803 6.374 1.148 3.748.93 2.049 2.362-.162 4.223.161 7.258 1.737 8.866l5.157 5.254a1.542 1.542 0 0 0 2.212.003l5.157-5.254c1.573-1.608 1.902-4.643-.312-6.507zm-.694 5.513L8.1 13.129c-.071.071-.129.071-.2 0L2.743 7.875c-1.073-1.094-1.291-3.164.215-4.431 1.144-.962 2.908-.817 4.014.309l1.029 1.05 1.029-1.05c1.111-1.132 2.876-1.27 4.014-.312 1.503 1.267 1.279 3.349.215 4.434z" />
  </Icon>
);

export default HeartOutline;
