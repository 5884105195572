import React, { useState } from 'react';
import FormWrapper from "./form_controls/FormWrapper";
import EmailInput from "./form_controls/EmailInput";
import PasswordInput from "./form_controls/PasswordInput";
import RoleSelectInput from "./form_controls/RoleSelectInput";
import ChangePasswordCheckboxInput from "./form_controls/ChangePasswordCheckboxInput";

const EditSuperUserForm = (props) => {
  const { onCancel, onSubmit, roleOptions, formErrors, superUser } = props;

  const [email, setEmail] = useState(superUser.email);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [role, setRole] = useState(superUser.role);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const params = { email, role };

    if (changePassword) {
      params.password = password;
    }

    onSubmit(superUser.id, params);
  };

  const validate = (name) => {
    if (formErrors && formErrors[name]) {
      return "error"
    }
    return null;
  }

  return (
    <FormWrapper
      formErrors={formErrors}
      onCancel={onCancel}
      onSubmit={handleOnSubmit}
    >
      <EmailInput
        value={email}
        validationState={validate("email")} 
        onChange={(e) => setEmail(e.target.value)}
      />

      <ChangePasswordCheckboxInput
        value={changePassword}
        onChange={(e) => setChangePassword(e.target.checked)}
      />

      { changePassword &&
        <PasswordInput
          value={password}
          validationState={validate("password")}
          onChange={(e) => setPassword(e.target.value) }
        />
      }

      <RoleSelectInput
        value={role}
        validationState={validate('role')}
        onChange={(e) => setRole(e.target.value)}
        options={roleOptions}
      />
    </FormWrapper>
  );
}

export default EditSuperUserForm;
