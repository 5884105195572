/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, string, bool, number, func } from "prop-types";
import { tdpTasks, tdpDetails } from "../../../../shared/HOCs/withRedux";

import { taskSelect } from "../actions/creators";
import { editTaskAsThunk } from "../actions/thunks";
import { defaultState } from "../ModalAddEditTask/constants";

import withFilters from "../HOCs/withFilters";
import TASK_STYLES from "./constants";
import {
  IS_TASK_SELECTION_MODE,
  UUID,
  SELECTED_TASK,
  CURRENT_USER,
} from "../../../../shared/reduxRoutes/tdpTasksRoutes";

import Checkbox from "../../../../shared/v2/Checkbox";
import TaskAvatar from "../TaskAvatar";
import ModalShowTask from "../ModalShowTask";
import ModalDeleteTask from "../ModalDeleteTask";
import ModalAddEditTask from "../ModalAddEditTask";
import TaskItemNameFrame from "../TaskItemNameFrame";
import VisibilityView from "./fractals";
import Tooltip from "../../../../shared/v2/Tooltip";
import { contingentText } from "../ModalShowTask/helpers";

const Task = ({
  currentUser,
  task,
  onEditTaskAsThunk,
  onTaskSelect,
  uuid,
  taskFilters,
  isTasksSelectionMode,
  selectedTasks,
  useCompletedUser,
}) => {
  const {
    name,
    assigned_person,
    priority,
    due_on,
    externally_visible,
    id,
    completed_at,
    assignedPlan,
    completed_by,
    contingent,
  } = task;
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isTaskModalDeleteOpen, setTaskModalDelete] = useState(false);
  const [isModalAddTaskOpen, setModalAddTaskOpen] = useState(false);

  const [checked, toggleChecked] = useState(false);
  useEffect(() => {
    toggleChecked(!!completed_at);
  }, [completed_at]);

  let displayUser = useCompletedUser ? completed_by : assigned_person;
  const displayDate = useCompletedUser ? completed_at : due_on;
  if (displayUser === undefined) {
    displayUser = assigned_person;
  }
  return (
    <>
      <ModalShowTask
        isTaskModalOpen={isTaskModalOpen}
        setTaskModalOpen={setTaskModalOpen}
        task={task}
        setTaskModalDelete={setTaskModalDelete}
        setModalAddTaskOpen={setModalAddTaskOpen}
      />
      <ModalDeleteTask
        setTaskModalOpen={setTaskModalOpen}
        isTaskModalDeleteOpen={isTaskModalDeleteOpen}
        setTaskModalDelete={setTaskModalDelete}
        ids={[id]}
      />
      <ModalAddEditTask
        show={isModalAddTaskOpen}
        toggleModalAddTaskOpen={setModalAddTaskOpen}
        task={task}
        toggleModalShowTask={setTaskModalOpen}
      />
      <div
        className={`tw-h-[64px] tw-w-full tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-b-[1px] tw-border-solid tw-border-neutral-gray-50 tw-flex tw-items-center ${
          selectedTasks.includes(id) && TASK_STYLES.taskSelected
        }`}
      >
        {isTasksSelectionMode ? (
          <Checkbox
            value={null}
            className="tw-cursor-pointer tw-border-neutral-gray-50"
            checked={selectedTasks.includes(id)}
            onClick={() => {
              onTaskSelect(id);
            }}
          />
        ) : (
          <Checkbox
            className="tw-cursor-pointer"
            value={null}
            checked={checked}
            onClick={() => {
              toggleChecked((prev) => !prev);
              onEditTaskAsThunk(
                {
                  ...defaultState(uuid, task),
                  ...(() =>
                    checked
                      ? { run: true }
                      : { completed: "true", completed_by_user_id: currentUser.uuid })(),
                  id,
                },
                uuid,
                taskFilters,
              );
            }}
          />
        )}
        <TaskItemNameFrame
          hasAssignedPlan={!!assignedPlan}
          nameOnClick={() => setTaskModalOpen((prev) => !prev)}
          name={name}
        />
        <div className="tw-ml-auto tw-flex tw-items-center">
          <TaskAvatar assignedPerson={displayUser} />
          <div className="tw-w-[43px] tw-flex tw-justify-center tw-mr-[25px]">
            <div className="tw-rounded-full tw-h-24px tw-border-neutral-gray-50 tw-border-solid tw-flex tw-items-center tw-justify-center tw-px-[9.5px] tw-w-24px">
              {priority}
            </div>
          </div>
          <Tooltip
            placement="top"
            trigger={
              <div className="tw-mr-[25px] tw-w-[54px] tw-flex tw-justify-center">{displayDate || "TBD"}</div>
            }
            content={contingentText(contingent, due_on)}
            tooltipClassName="tw-ml-[-12px]"
          />
          <div className="tw-w-[49px] tw-flex tw-justify-center">
            <VisibilityView visibility={externally_visible} />
          </div>
        </div>
      </div>
    </>
  );
};

Task.propTypes = {
  task: shape({
    assigned_person: shape({}),
    completed_at: string,
    due_on: string,
    dueness: string,
    externally_visible: bool,
    id: string,
    name: string,
    priority: number,
    state: string,
  }),
  onEditTaskAsThunk: func,
  onTaskSelect: func,
  isTasksSelectionMode: bool,
  uuid: string,
  taskFilters: string,
  selectedTasks: arrayOf(string),
  useCompletedUser: bool,
  currentUser: shape({
    uuid: string.isRequired,
  }).isRequired,
};

Task.defaultProps = {
  task: {
    assigned_person: null,
    completed_at: null,
    due_on: null,
    dueness: null,
    externally_visible: false,
    id: null,
    name: null,
    priority: 0,
    state: null,
  },
  onEditTaskAsThunk: null,
  onTaskSelect: null,
  isTasksSelectionMode: false,
  uuid: "",
  taskFilters: "",
  selectedTasks: [],
  useCompletedUser: false,
};

const mapDispatchToProps = (dispatch) => ({
  onEditTaskAsThunk: (payload, uuid, filtered) => dispatch(editTaskAsThunk(payload, uuid, filtered)),
  onTaskSelect: (id) => dispatch(taskSelect(id)),
});

const TaskWrapped = connect(null, mapDispatchToProps)(withFilters(Task));

const TaskWrapComp = tdpTasks({
  WrappedComponent: TaskWrapped,
  states: [IS_TASK_SELECTION_MODE, UUID, SELECTED_TASK],
});

export default tdpDetails({
  WrappedComponent: TaskWrapComp,
  states: [CURRENT_USER],
});
