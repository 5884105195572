import React from "react";
import Proptypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { useOnMinMaxSelectedChange, useMinMaxState } from "../../hookHelpers";
import { hasMinMaxError } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";

const baseOptions = [
  {
    label: "2,000 sqft",
    value: "2000",
  },
  {
    label: "4,500 sqft",
    value: "4500",
  },
  {
    label: "6,500 sqft",
    value: "6500",
  },
  {
    label: "8,000 sqft",
    value: "8000",
  },
  {
    label: "0.25 acres",
    value: "10890",
  },
  {
    label: "0.5 acres",
    value: "21780",
  },
  {
    label: "1 acres",
    value: "43560",
  },
  {
    label: "1.5 acres",
    value: "65340",
  },
  {
    label: "2 acres",
    value: "87120",
  },
  {
    label: "2.5 acres",
    value: "108900",
  },
  {
    label: "3 acres",
    value: "130680",
  },
  {
    label: "3.5 acres",
    value: "152460",
  },
  {
    label: "4 acres",
    value: "174240",
  },
  {
    label: "4.5 acres",
    value: "196020",
  },
  {
    label: "5 acres",
    value: "217800",
  },
  {
    label: "10 acres",
    value: "435600",
  },
  {
    label: "15 acres",
    value: "653400",
  },
  {
    label: "20 acres",
    value: "871200",
  },
  {
    label: "25 acres",
    value: "1089000",
  },
  {
    label: "50 acres",
    value: "2178000",
  },
  {
    label: "100 acres",
    value: "4356000",
  },
  {
    label: "500 acres",
    value: "21780000",
  },
  {
    label: "1,000 acres",
    value: "43560000",
  },
];
const minOptions = [{ label: "No min", value: null }, ...baseOptions];
const maxOptions = [{ label: "No max", value: null }, ...baseOptions];
const LotSize = ({ min, max, onChange }) => {
  const [selectedMin, onSelectMin, selectedMax, onSelectMax] = useMinMaxState(min, max);
  const hasError = hasMinMaxError(selectedMin, selectedMax);
  const debouncedMin = useDebounce(selectedMin, 500);
  const debouncedMax = useDebounce(selectedMax, 500);
  useOnMinMaxSelectedChange(debouncedMin, debouncedMax, hasError, onChange);

  return (
    <div data-cy="lot-wrapper" className="tw-mb-[24px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Lot Size</span>
      <div className="tw-flex tw-gap-[24px]">
        <Dropdown
          containerClassName="tw-flex-1"
          name="min_lot"
          value={minOptions.find((o) => o.value === selectedMin)}
          options={minOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="No Min"
          onChange={(opt) => onSelectMin(opt.value)}
        />
        <Dropdown
          containerClassName="tw-flex-1"
          error={hasError ? "Max must be greater than min" : ""}
          name="max_lot"
          value={maxOptions.find((o) => o.value === selectedMax)}
          options={maxOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="No Max"
          onChange={(opt) => onSelectMax(opt.value)}
        />
      </div>
    </div>
  );
};
export default LotSize;

LotSize.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  onChange: Proptypes.func,
};

LotSize.defaultProps = {
  min: undefined,
  max: undefined,
  onChange: () => {},
};
