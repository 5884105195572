import React from "react";
import TextComboInput from "@shared/v2/TextComboInput";

import { primaryLight, bodyS } from "@shared/styles";
import { func, string } from "prop-types";

const TaskDetailField = ({value, setValue}) => (
  <div>
    <TextComboInput
      label='Task Details'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      isRequired/>
    <div className={`${bodyS} ${primaryLight} tw-mt-4px`}>{value ? value.length : 0} / 255</div>
  </div>
);

export default TaskDetailField;

TaskDetailField.propTypes = {
  value: string, 
  setValue: func
}

TaskDetailField.defaultProps = {
  value: '', 
  setValue: () => {}
}