import React, { useEffect, useState, useMemo } from "react";
import { CirclePlaySolidV6 } from "../v2/Icomoon";

import axios from "axios";
import { tryCatchHandlr } from "../Utilities";

// Renders Text
export const TextRender = ({ text, className }) => <div className={className}>{text}</div>;

// Renders Thumbnail given URL has meta[property="og:url"]
export const ThumbNailRender = ({ url, title, description, image, isVid }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className="tw-w-[356px] tw-h-[252px] tw-rounded-[8px] tw-border tw-border-gray-10 tw-m-[6px] tw-bg-tinted-gray-50 tw-flex tw-flex-col hover:tw-no-underline tw-group tw-overflow-hidden"
  >
    <div className="tw-relative tw-bg-black tw-flex tw-justify-center">
      <div
        className={`${isVid && "group-hover:tw-bg-black"} tw-opacity-20 tw-w-full tw-h-full tw-absolute`}
      ></div>
      {isVid && (
        <CirclePlaySolidV6
          size="xxxl"
          className="group-hover:tw-visible tw-invisible  tw-absolute tw-inset-0 tw-m-auto tw-text-white"
        />
      )}
      <img src={image} alt={title} className="tw-h-[200px]" />
    </div>
    <div className="tw-py-[8px] tw-px-[16px] tw-overflow-hidden tw-whitespace-nowrap">
      <div className="tw-text-11px tw-font-bold tw-text-black tw-leading-[16px] tw-overflow-ellipsis tw-overflow-hidden">
        {title}
      </div>
      <div className="tw-text-gray-50 tw-text-10px tw-leading-[16px] tw-overflow-ellipsis tw-overflow-hidden">
        {description}
      </div>
    </div>
  </a>
);

// Renders anchor given URL does not have meta[property="og:url"]
export const AnchorRender = ({ text, className }) => (
  <a href={text} className={`tw-underline ${className}`} target="_blank" rel="noreferrer">
    {text}
  </a>
);

// Branches Link either from Anchor <-> Thumbnail preview
export const LinkRender = ({ text, className }) => {
  const [{ url, title, description, image, isVid }, setPreview] = useState({
    url: null,
    title: null,
    description: null,
    image: null,
    isVid: false,
  });

  useEffect(() => {
    (async () => {
      const req = axios.get(text);
      const [res] = await tryCatchHandlr(req);

      const html = res?.data;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      const title = doc?.querySelector('meta[property="og:title"]')?.getAttribute("content");
      const description = doc?.querySelector('meta[property="og:description"]')?.getAttribute("content");
      const url =
        doc?.querySelector('meta[property="og:video"]')?.getAttribute("content") ||
        doc?.querySelector('meta[property="og:url"]')?.getAttribute("content");
      const image = doc?.querySelector('meta[property="og:image"]')?.getAttribute("content");
      const isVid = Boolean(doc?.querySelector('meta[property="og:video"]')?.getAttribute("content"));

      setPreview({
        url,
        title,
        description,
        image,
        isVid,
      });
    })();
  }, [text]);

  return url ? (
    <ThumbNailRender
      url={url}
      title={title}
      description={description}
      className={className}
      image={image}
      isVid={isVid}
    />
  ) : (
    <AnchorRender text={text} className={`${className} tw-break-all`} />
  );
};

// Branches Message from to either Span <-> Link
const LinkTextRender = ({ text, className }) => {
  const LinkText = useMemo(() => (text.match(/^https?:\/\/\S+$/) ? LinkRender : TextRender), [text]);

  return <LinkText text={text} className={className} />;
};

export default LinkTextRender;
