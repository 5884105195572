import axios from "axios";
import { tryCatchHandlr } from "../helpers/helper";

export const setNotes = (notes) => ({
  type: "SET_NOTES",
  notes,
});

export const toggleShowCreateForm = (bool) => ({
  type: "TOGGLE_SHOW_FORM",
  bool,
});

export const toggleShowEditForm = (bool) => ({
  type: "TOGGLE_SHOW_EDIT_FORM",
  bool,
});

export const toggleShowDeleteForm = (bool) => ({
  type: "TOGGLE_SHOW_DELETE_FORM",
  bool,
});

export const toggleHasError = (bool) => ({
  type: "TOGGLE_HAS_ERROR",
  bool,
});

export const setErrorMessage = (message) => ({
  type: "ERROR_MESSAGE",
  message,
});

export const toggleExternallyVisibleAction = (bool) => ({
  type: "EXTERNALLY_VISIBLE",
  bool,
});

export const toggleStarredAction = (bool) => ({
  type: "STARRED",
  bool,
});

export const submitNote = (note) => ({
  type: "SUBMIT_NOTE",
  note,
});

export const deleteNoteSuccess = (note) => ({
  type: "DELETE_NOTE",
  note,
});

export const updateNoteSuccess = (note) => ({
  type: "UPDATE_NOTE",
  note,
});

const noteUrlHelper = (note) => {
  // eslint-disable-next-line default-case
  switch (note.notable_type.toLowerCase()) {
    case "listing":
      return `/listings/${note.notable_id}/notes`;
    case "project":
      return `/projects/${note.notable_id}/notes`;
    case "person":
      return `/people/${note.notable_id}/notes`;
  }
  return "";
};

export const createNote = (note) => {
  let req;
  return async (dispatch) => {
    req = axios.post(noteUrlHelper(note), {
      note,
      authenticity_token: ReactOnRails.authenticityToken(),
    });

    const [res, err] = await tryCatchHandlr(req);
    if (err) {
      dispatch(toggleHasError(true));
      dispatch(setErrorMessage(err.response.data.content));
      return;
    }
    dispatch(toggleShowCreateForm(false));
    dispatch(submitNote(res.data));
    dispatch(toggleStarredAction(false));
    dispatch(toggleExternallyVisibleAction(true));
    dispatch(toggleHasError(false));
    dispatch(setErrorMessage(""));
  };
};

export const updateNote = (note) => {
  let req;
  return async (dispatch) => {
    req = axios.patch(`${noteUrlHelper(note)}/${note.id}`, {
      note: { ...note },
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    const [, err] = await tryCatchHandlr(req);
    if (err) {
      dispatch(toggleHasError(true));
      dispatch(setErrorMessage(err.response.data.content));
      return;
    }
    dispatch(updateNoteSuccess(note));
    dispatch(toggleHasError(false));
    dispatch(setErrorMessage(""));
  };
};

export const deleteNote = (note) => async (dispatch) => {
  axios
    .delete(`${noteUrlHelper(note)}/${note.id}`, {
      data: {
        authenticity_token: ReactOnRails.authenticityToken(),
      },
    })
    .then(() => {
      dispatch(deleteNoteSuccess(note));
    })
    .catch((err) => {
      dispatch(toggleHasError(true));
      dispatch(setErrorMessage(err.response.data.content));
    });
};

export const toggleExternallyVisible = (note) => async (dispatch) => {
  axios
    .patch(`${noteUrlHelper(note)}/${note.id}`, {
      note: { ...note, externally_visible: !note.externally_visible },
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((res) => {
      dispatch(updateNoteSuccess(res.data));
      dispatch(toggleExternallyVisibleAction(!note.externally_visible));
    })
    .catch((err) => {
      dispatch(toggleHasError(true));
      dispatch(setErrorMessage(err.response.data.content));
    });
};

export const toggleStarred = (note) => async (dispatch) => {
  axios
    .patch(`${noteUrlHelper(note)}/${note.id}`, {
      note: { ...note, starred: !note.starred },
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((res) => {
      dispatch(updateNoteSuccess(res.data));
      dispatch(toggleStarredAction(!note.starred));
    })
    .catch((err) => {
      dispatch(toggleHasError(true));
      dispatch(setErrorMessage(err.response.data.content));
    });
};
