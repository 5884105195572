import { toggleShowCreateForm, toggleShowEditForm, toggleShowDeleteForm, toggleHasError, setErrorMessage, toggleStarredAction, toggleExternallyVisibleAction, submitNote, deleteNoteSuccess, updateNoteSuccess } from "../Transactions/components/Notes/actions/notesActionCreators";

const initialState = {
    showCreateForm: false,
    showEditForm: false,
    hasError: false,
    errorMessage: null,
    current_user: {},
    notableId: null,
    notableType: '',
    notes: [],
    starred:false,
    externallyVisible:false
}

const notesReducer= ( state = initialState, payload ) =>{
    switch (payload.type) {
        case toggleShowCreateForm().type:
            return {
                ...state,
                showCreateForm: payload.bool
            }
        case toggleShowEditForm().type:
            return {
                ...state,
                showEditForm: payload.bool
            }
        case toggleShowDeleteForm().type:
            return {
                ...state,
                showDeleteForm: payload.bool
            }
        
        case toggleHasError().type:
            return {
                ...state,
                hasError: payload.bool
            }
        case setErrorMessage().type:
            return {
                ...state,
                errorMessage: payload.message
            }
        case toggleStarredAction().type:
            return {
                ...state,
                starred: payload.bool
            }
        case toggleExternallyVisibleAction().type:
            return {
                ...state,
                externallyVisible: payload.bool
            }
        case submitNote().type:
            return {
                ...state,
                notes: [ payload.note, ...state.notes ]
            }
        case deleteNoteSuccess().type:
            return {
                ...state,
                notes: state.notes.filter(note => note.id !== payload.note.id)
            }
        case updateNoteSuccess().type:
            return {
                ...state,
                notes: state.notes.map(note => {
                    return note.id === payload.note.id ? payload.note : note;
                })
            }
        default:
            return state;
    }
}

export default notesReducer;
