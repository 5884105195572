import React from "react";

import DetailSectionHeader from "../DetailSectionHeader";
import ClientNameField from "../ClientNameField";
import ClientIntentField from "../ClientIntentField";
import FinancingField from "../FinancingField";
import DesiredLocationsField from "../DesiredLocationsField";

const ClientIntentDetails = () => (
  <div className="tw-mb-24px">
    <DetailSectionHeader header="Client Details" />
    <ClientNameField />
    <ClientIntentField />
    <FinancingField />
    <DesiredLocationsField />
  </div>
);

export default ClientIntentDetails;
