import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const IntersectionObserverNode = ({ height, onIntersect }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return () => {};

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) onIntersect();
      },
      {
        root: ref.current.parentElement,
        threshold: 1,
      },
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [onIntersect]);

  return <div ref={ref} style={{ minHeight: height }} />;
};

IntersectionObserverNode.propTypes = {
  height: PropTypes.number,
  onIntersect: PropTypes.func.isRequired,
};

IntersectionObserverNode.defaultProps = {
  height: 0,
};

export default IntersectionObserverNode;
