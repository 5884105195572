import React from "react";
import TransactionInput from "../../shared/TransactionInput";
import { Row, Col, FormGroup, TextArea } from "../../../../shared/v1";
import DragHandle from "../../shared/DragHandle";
import { connect } from "react-redux";
import * as actionCreators from "../../../actions/account";

const mapDispatchToProps = (dispatch) => ({
  setAgentExpenseData: (attr, data, agentPosition, expPosition) =>
    dispatch(actionCreators.setAgentExpenseData(attr, data, agentPosition, expPosition)),
  destroyAgentExpense: (agentPosition, expPosition) =>
    dispatch(actionCreators.destroyAgentExpense(agentPosition, expPosition)),
});

export const ExpenseForm = connect(
  null,
  mapDispatchToProps,
)((props) => {
  const { value, destroyAgentExpense, agentPosition, sortIndex: expPosition, setAgentExpenseData } = props;

  return (
    <div className="transaction-gci-options tw-bg-transparent tw-p-0 tw-border-0">
      <div className="transaction-gci-content content-additional">
        <Row className="tw-border-0 tw-border-t tw-border-solid tw-border-gray tw-pt-[10px]">
          <DragHandle />

          <Col size={2}>
            <TransactionInput
              label={value.name}
              value={value.value}
              isPercentage={!!value.percentage}
              name={`agExpense${expPosition}`}
              onValueUpdate={(e) => {
                if (!!value.percentage) {
                  if (e.target.value >= 0 && e.target.value <= 100) {
                    setAgentExpenseData("value", e.target.value, agentPosition, value.position);
                  }
                } else {
                  setAgentExpenseData("value", e.target.value, agentPosition, value.position);
                }
              }}
              onRadioUpdate={(e) => {
                if (e.target.value === "true" && value.value > 100) {
                  setAgentExpenseData("value", "", agentPosition, value.position);
                }
                setAgentExpenseData("percentage", e.target.value === "true", agentPosition, value.position);
              }}
            />
          </Col>

          <Col size={4}>
            <FormGroup className="tw-min-h-[50px]">
              <label className="tw-block tw-mb-[5px]">Notes</label>
              <TextArea
                size="auto"
                className="description"
                name={`agnote${expPosition}`}
                placeholder=""
                value={value.notes || ""}
                onChange={(e) => {
                  setAgentExpenseData("notes", e.target.value, agentPosition, value.position);
                }}
              />
            </FormGroup>
          </Col>

          <div className="delete-expense">
            <a
              onClick={() => {
                destroyAgentExpense(agentPosition, value.position);
              }}
              data-cy="financial-settings-destroy-expense-anchor"
            >
              <i className="fa fa-times" />
            </a>
          </div>
        </Row>
      </div>
    </div>
  );
});
