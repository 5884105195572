/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { toNumber } from "lodash";
import { string } from "prop-types";

import format from "../../../../shared/formatters";
import { tdpFinancials } from "../../../../shared/HOCs/withRedux";
import { CLOSED_VOLUME, HEADER_CLOSE_PRICE } from "../../../../shared/reduxRoutes/tdpFinancialsRoutes";

const normalizeNumToString = (num) => toNumber(`${num}`.replace(/,/g, ""));

const ClosePriceVal = ({ closePriceVal, headerClosePriceVal }) => {
  const val = closePriceVal || headerClosePriceVal;
  const cpVal = val === null ? "0" : val;

  return (
    <div className="tw-flex tw-h-36px">
      <div className="tw-text-18px tw-font-bold tw-leading-36px tw-tracking-normal">
        {format.currency(normalizeNumToString(cpVal))}
      </div>
    </div>
  );
};

const WithFinancialsPipelineClosePriceVal = tdpFinancials({
  WrappedComponent: ClosePriceVal,
  states: [CLOSED_VOLUME, HEADER_CLOSE_PRICE],
  aliases: ["closePriceVal", "headerClosePriceVal"],
});

export default WithFinancialsPipelineClosePriceVal;

ClosePriceVal.propTypes = {
  closePriceVal: string,
  headerClosePriceVal: string,
};

ClosePriceVal.defaultProps = {
  closePriceVal: null,
  headerClosePriceVal: null,
};
