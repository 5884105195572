import React from "react";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { bool, func } from "prop-types";

const UnAssignModal = ({ show, onHideHandlr, onSubmitHandlr }) => (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center tw-p-[32px]"
    contentClassName="tw-w-[440px]"
    onHide={onHideHandlr}
  >
    <Modal.Header title="Un-assign Brivity Number" className="tw-mb-32px tw-text-28d" closeButton />
    <Modal.Body className="tw-gap-[20px] tw-flex tw-flex-col tw-mb-32px">
      <div className="tw-text-14d">Are you sure you want to do this?</div>
      <div className="tw-text-14d">
        Un-assigning this Brivity number will close and archive all text conversations that include this
        number, including group texts.{" "}
      </div>
      <div className="tw-text-14d">
        Text message history will not be visible on contact detail pages or in the message center. Timeline
        events will still be visible.
      </div>
      <div className="tw-text-14d">
        If this number is reassigned, texting any lead will start a new conversation thread and may send an
        opt-out message.
      </div>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onHideHandlr}>
        CANCEL
      </Button>
      <Button size="medium" schema="warning" onClick={onSubmitHandlr}>
        Yes, Un-assign
      </Button>
    </Modal.Footer>
  </Modal>
);

export default UnAssignModal;

UnAssignModal.propTypes = {
  show: bool,
  onHideHandlr: func.isRequired,
  onSubmitHandlr: func.isRequired,
};

UnAssignModal.defaultProps = {
  show: false,
};
