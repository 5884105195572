import React from "react";
import PropTypes from "prop-types";

const SharedEmail = ({ email, handleRemoveEmail }) => (
  <div style={{ display: "inline-block", margin: "1px 0 2px 0" }}>
    <div className="shared-email-bubble" contentEditable={false}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span className="fa fa-times" onClick={() => handleRemoveEmail(email)} />
      <span>{email}</span>
    </div>
  </div>
);

export default SharedEmail;

SharedEmail.propTypes = {
  email: PropTypes.string.isRequired,
  handleRemoveEmail: PropTypes.func.isRequired,
};
