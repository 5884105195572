import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import errorShape from "../../Main/Feedback/model/error";
import feedbackShape from "../../Main/Feedback/model/feedback";
import formDataShape from "../../Main/Feedback/model/formData";
import metaShape from "../../Main/Feedback/model/meta";
import {
  getAllFeedbacks,
  hideFeedbackForm,
  newFeedback,
  showFeedbackForm,
} from "../../Main/Feedback/actions/thunks";
import Marketing from "./Marketing";

const MarketingWrapper = ({ dispatch, uuid, feedbacks, meta, error, formData }) => (
  <Marketing
    getAllFeedbacks={() => dispatch(getAllFeedbacks(uuid))}
    newFeedback={(feedback) => dispatch(newFeedback(uuid, feedback))}
    hideFeedbackForm={() => dispatch(hideFeedbackForm())}
    showFeedbackForm={(data) => dispatch(showFeedbackForm(data))}
    uuid={uuid}
    feedbacks={feedbacks}
    meta={meta}
    error={error}
    formData={formData}
  />
);

MarketingWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  feedbacks: PropTypes.arrayOf(feedbackShape),
  meta: metaShape,
  error: errorShape,
  formData: formDataShape,
};

MarketingWrapper.defaultProps = {
  feedbacks: [],
  meta: {
    averageRating: 0.0,
    pagination: {
      totalPages: 0,
      totalCount: 0,
    },
  },
  error: null,
  formData: {
    action: "new",
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
};

const mapStateToProps = (state) => ({
  uuid: state.tdpFeedbackReducer.uuid,
  feedbacks: state.tdpFeedbackReducer.feedbacks,
  meta: state.tdpFeedbackReducer.meta,
  error: state.tdpFeedbackReducer.error,
  formData: state.tdpFeedbackReducer.formData,
});

export default connect(mapStateToProps)(MarketingWrapper);
