import React from "react";
import PropTypes from "prop-types";

const BACKGROUND_COLOR_CLASSES =
  "tw-bg-white enabled:hover:tw-bg-gray-lighter enabled:active:hover:tw-bg-gray-lighter disabled:tw-bg-gray-lighter";

const BORDER_COLOR_CLASSES =
  "enabled:tw-border-brand-danger enabled:focus:tw-border-color-8c8c8c enabled:hover:tw-border-color-adadad enabled:active:tw-border-color-adadad enabled:active:hover:tw-border-color-8c8c8c disabled:tw-border-brand-danger disabled:hover:tw-border-color-ccc";

const TEXT_COLOR_CLASSES =
  "tw-text-gray-darker focus:tw-text-gray-darker hover:!tw-text-gray-darker active:tw-text-gray-darker active:hover:!tw-text-color-333";

const SIZE_CLASSES = {
  large: "tw-leading-1.3333333 tw-rounded-6px",
  small: "tw-leading-normal tw-rounded-3px",
  extraSmall: "tw-leading-normal tw-rounded-3px",
  default: "tw-leading-1.428571429 tw-rounded-4px",
};

const BASE_CLASSES =
  "tw-inline-block tw-font-semibold hover:tw-no-underline focus:tw-no-underline tw-border tw-border-solid tw-border-r-3px tw-border-b-3px tw-uppercase tw-py-0.3em tw-px-0.8em tw-text-15px tw-text-center tw-align-middle tw-inline-block tw-mb-0 tw-whitespace-nowrap tw-touch-action-manipulation tw-select-none tw-bg-none focus:tw-outline-bootstrap active:tw-outline-0 enabled:tw-cursor-pointer enabled:active:tw-shadow-bootstrap-15 disabled:!tw-cursor-not-allowed disabled:tw-opacity-65 disabled:tw-shadow-none";

const sizeClasses = (size) => SIZE_CLASSES[size];

export const DeleteButton = React.forwardRef(({ className, size, onClick, ...props }, ref) => {
  const classes = `
    ${BASE_CLASSES}
    ${BACKGROUND_COLOR_CLASSES}
    ${BORDER_COLOR_CLASSES}
    ${TEXT_COLOR_CLASSES}
		${sizeClasses(size)}
    ${className}
  `;

  return (
    <button ref={ref} type="button" className={classes} onClick={onClick} {...props}>
      Delete
    </button>
  );
});

DeleteButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["large", "small", "extraSmall", "default"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  className: "",
  size: "default",
  disabled: false,
  onClick: null,
};

export default DeleteButton;
