import React from "react";
import PropTypes from "prop-types";

import { ModalContext } from "./ModalContext";
import CLASSES from "./classes.json";

const { MODAL_HEADER_CLASSES, MODAL_HEADER_BUTTON_CLASSES, SR_ONLY_CLASSES } = CLASSES;

const CloseButton = ({ onClick, className, variant: variantFromProps, ...props }) => {
  const { variant: variantFromContext } = React.useContext(ModalContext);
  const variant = variantFromProps || variantFromContext;

  return (
    <button
      className={`${MODAL_HEADER_BUTTON_CLASSES.common} ${MODAL_HEADER_BUTTON_CLASSES[variant]} ${className}`}
      type="button"
      aria-label="Close"
      onClick={onClick}
      {...props}
    >
      <span aria-hidden="true">×</span>
      <span className={SR_ONLY_CLASSES}>Close</span>
    </button>
  );
};
CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["classic", "modern"]),
};
CloseButton.defaultProps = {
  className: "",
  variant: null,
};

const ModalHeader = ({
  closeButton,
  className,
  variant: variantFromProps,
  deprecatedOverridePaddingClasses,
  children,
  ...props
}) => {
  const { onHide, variant: variantFromContext } = React.useContext(ModalContext);
  const variant = variantFromProps || variantFromContext;

  const classes = `
    ${MODAL_HEADER_CLASSES.common}
    ${deprecatedOverridePaddingClasses || MODAL_HEADER_CLASSES[variant]}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {closeButton && <CloseButton onClick={onHide} />}
      {children}
    </div>
  );
};
ModalHeader.propTypes = {
  closeButton: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["classic", "modern"]),
  deprecatedOverridePaddingClasses: PropTypes.string,
  children: PropTypes.node,
};
ModalHeader.defaultProps = {
  closeButton: false,
  className: "",
  variant: null,
  deprecatedOverridePaddingClasses: "",
  children: null,
};

ModalHeader.CloseButton = CloseButton;

export default ModalHeader;
