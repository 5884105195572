import React from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "./FieldLabelV1";
import TextInput from "../v2/TextInput/TextInput";

function FieldTextWithIconV1({
  className,
  placeholder,
  label,
  value,
  flexStyle,
  iconClassName,
  onChange,
  ...otherProps
}) {
  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && (
        <FieldLabelV1
          {...{
            label,
          }}
        />
      )}
      <div className="tw-flex tw-gap-[12px]">
        <div className="tw-flex-1">
          <TextInput
            placeholder={placeholder}
            className={className}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value || ""}
            {...otherProps}
          />
        </div>

        {iconClassName && (
          <div
            className={`tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-36px tw-h-36px ${
              value
                ? "tw-bg-theme-nav-focus tw-text-theme-text-button-color"
                : "tw-bg-neutral-gray-10 tw-text-neutral-gray-30"
            }`}
          >
            {iconClassName === "fa-tiktok" ? (
              <TikTokIcon />
            ) : (
              <i className={`fa ${iconClassName}`} aria-hidden="true" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

FieldTextWithIconV1.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  flexStyle: PropTypes.string,
  iconClassName: PropTypes.string,
  onChange: PropTypes.func,
};

FieldTextWithIconV1.defaultProps = {
  className: "",
  placeholder: "",
  label: "",
  value: "",
  flexStyle: "tw-flex-1",
  iconClassName: "",

  onChange: () => {},
};

export default FieldTextWithIconV1;

function TikTokIcon() {
  return (
    <div className=" tw-w-16px tw-h-16px ">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="tiktok"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="svg-inline--fa fa-tiktok fa-w-14"
      >
        <path
          fill="currentColor"
          d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
          className=""
        ></path>
      </svg>
    </div>
  );
}
