import React, { useContext } from "react";
import PropTypes from "prop-types";
import actions from "../actions";
// eslint-disable-next-line import/no-cycle
import { UpdateFromPipelineContext } from "./Wrapper";

import { WithShowPicker as OptionsStatusCell } from "./shared/OptionsStatusCell/OptionsStatusCell";

const TDIOptionsStatusCell = ({ listing }) => {
  const { dispatch } = useContext(UpdateFromPipelineContext);

  const onChangeHandlr = async (_status) => {
    if (_status === listing.status) return;

    // Dispatching state changes before preflight req, will revert if theres error on actions.listings.updateStateRequest
    const copyListing = JSON.parse(JSON.stringify(listing));
    dispatch(actions.listings.updateListing(listing, _status));
    dispatch(actions.listings.updateStatusRequest(copyListing, { status: _status }));
  };

  return (
    <OptionsStatusCell
      transactionType={listing.type}
      status={listing.status}
      selectedStatus={onChangeHandlr}
      uuid={listing.uuid}
    />
  );
};

TDIOptionsStatusCell.propTypes = {
  listing: PropTypes.shape({
    address: PropTypes.shape({ streetAddress: PropTypes.string }),
    status: PropTypes.string,
    type: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

TDIOptionsStatusCell.defaultProps = {
  listing: null,
};
export default TDIOptionsStatusCell;
