/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronUp = (props) => (
  <Icon {...props} name="Chevron Up">
    <path d="m8.585 3.673 6.702 6.702a.827.827 0 0 1 0 1.17l-.782.782a.827.827 0 0 1-1.169.001L8 7.017l-5.336 5.311a.828.828 0 0 1-1.169-.001l-.782-.782a.827.827 0 0 1 0-1.17l6.702-6.702a.827.827 0 0 1 1.17 0z" />
  </Icon>
);

export default ChevronUp;
