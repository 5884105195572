import React from "react";
import Proptypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";

const OPTIONS = [
  {
    label: "Never",
    value: "never",
  },
  {
    label: "Weekly",
    value: "every_week",
  },
  {
    label: "Every 2 weeks",
    value: "every_2_weeks",
  },
  {
    label: "Every month",
    value: "every_month",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Annually",
    value: "annually",
  },
];
const DripFrequency = ({ value, onChange }) => (
  <div data-cy="lot-wrapper" className="tw-mb-[4px]">
    <Dropdown
      label="Drip Frequency"
      name="drip_frequency"
      value={OPTIONS.find((o) => o.value === value)}
      options={OPTIONS}
      isClearable={false}
      isSearchable={false}
      onChange={(opt) => onChange(opt.value)}
    />
  </div>
);
export default DripFrequency;

DripFrequency.propTypes = {
  value: Proptypes.string,
  onChange: Proptypes.func,
};

DripFrequency.defaultProps = {
  value: "",
  onChange: () => {},
};
