import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class TimelineQualificationFormEvent extends Component {
  constructor(props, context){
    super(props, context)
  }

  details = () => {
    const {event} = this.props
    const eventId = event.id
    let description = []
    const {timeline_fields} = event

    const ordered_details = {
      selling_or_buying: 'Selling or Buying',
      buy_time_frame: 'Buy Time Frame',
      sell_time_frame: 'Sell Time Frame',
      time_frame: 'Time Frame',
      'mortgage_pre-approval': 'Mortgage Pre-Approval',
      'morgage_pre-approval': 'Mortgage Pre-Approval', // Support typo until code and data are updated
      scheduled_consult: 'Scheduled Consult',
      consultation: 'Consultation',
    }

    for (var key in ordered_details){
      const value = timeline_fields[key]
      if(!value) { continue }

      const titleized_value = capitalize(value)
      const titleized_key = ordered_details[key]

      if(key == 'time_frame') {
        const selling_or_buying = timeline_fields['selling_or_buying']

        if(!selling_or_buying){
          description.push(titleized_key + ": " + titleized_value)
        }else {
          const lower_selling_or_buying = selling_or_buying.toLocaleLowerCase()

          if(lower_selling_or_buying == 'buying'){
            description.push("Buy Time Frame: " + titleized_value)
          }else if(lower_selling_or_buying == 'selling'){
            description.push("Sell Time Frame: " + titleized_value)
          }else if(lower_selling_or_buying == 'both'){
            description.push("Buy Time Frame: " + titleized_value)
            description.push("Sell Time Frame: " + titleized_value)
          }else {
            description.push(titleized_key + ": " + titleized_value)
          }
        }
      }else {
        description.push(titleized_key + ": " + titleized_value)
      }
    }

    return(
      description.length > 0 &&
      <div className="row bold-detail details-row details-header">
        Details:
      </div> &&
      description.map(description =>{
        return(
          <div className="row details-row" key={`${eventId}-${description}`}>
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
        )
      })
    )
  }

  row = () => {
    const {event} = this.props

    return (
      <div className="timeline-event timeline-submitted-form">
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">Submitted Form:</span>
            {` Qualification`}
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
