import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  BookBlankSolidV6,
  GraduationCapSolidV6,
  QuestionCircleRegularV3,
  VideoSolidV6,
} from "../../shared/v2/Icomoon";
import IconButton from "../../shared/v2/IconButton/IconButton";
import { useClickOutside } from "../../shared/hookHelpers";
import Popover, { PopoverLink } from "../Popover";

const Help = () => {
  const theme = useSelector(({ layout }) => layout.theme);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  useClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} className="tw-relative">
      <IconButton
        className={`${
          theme.name === "place-theme" ? "tw-text-white" : ""
        }  hover:tw-bg-gray/20 active:tw-bg-gray/30`}
        schema="transparent"
        id="helpDropdown"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={() => setOpen((o) => !o)}
      >
        <QuestionCircleRegularV3 size="xxl" />
      </IconButton>

      <Popover aria-labelledby="helpDropdown" className="tw-w-[200px]" open={open}>
        <ul className="tw-m-0 tw-p-0">
          {theme.name !== "place-theme" && (
            <PopoverLink
              href="https://learn.brivity.com"
              icon={GraduationCapSolidV6}
              title="Brivity Academy"
              target="_blank"
            />
          )}
          <PopoverLink
            href="https://www.brivityknowledge.com/docs/live-training"
            icon={VideoSolidV6}
            title="Live Brivity Training"
            target="_blank"
          />
          <PopoverLink
            href="https://www.brivityknowledge.com/"
            icon={BookBlankSolidV6}
            title="Knowledge Center"
            target="_blank"
          />
        </ul>
      </Popover>
    </div>
  );
};

export default Help;
