import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Dropdown from "../../shared/v2/Dropdown/Dropdown";
import Button from "../../shared/v2/Button";
import userName from "./userName";

const TollFreeAssignForm = ({ users, tollFreeNumbers, assignPhone, buyPhone }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTollFreeNumber, setSelectedTollFreeNumber] = useState(null);

  const userOptions = useMemo(
    () =>
      users
        .map((user) => ({ value: user.id, label: userName(user) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [users],
  );

  return (
    <div className="row">
      <div className="col-xs-3">
        <div className="form-group">
          <Dropdown
            id="user"
            label="Select a User"
            placeholder="Select"
            options={userOptions}
            value={selectedUser}
            onChange={(option) => setSelectedUser(option)}
          />
        </div>
      </div>

      <div className="col-xs-3">
        <div className="form-group">
          <Dropdown
            id="toll-free-number"
            label="Select Unassigned Toll Free Number"
            placeholder="Select"
            options={tollFreeNumbers.map((t) => ({ value: t.id, label: t.toll_free_phone_number }))}
            value={selectedTollFreeNumber}
            onChange={(option) => setSelectedTollFreeNumber(option)}
          />
        </div>
      </div>

      <div className="col-xs-3 tw-pt-[25px]">
        <Button
          size="medium"
          schema="primary"
          onClick={() => assignPhone(selectedUser.value, selectedTollFreeNumber.value)}
        >
          Assign
        </Button>
      </div>

      <div className="col-xs-3 tw-pt-[25px]">
        <Button size="medium" schema="secondary" onClick={() => buyPhone()}>
          Buy a new Toll-Free Number
        </Button>
      </div>
    </div>
  );
};

TollFreeAssignForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tollFreeNumbers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assignPhone: PropTypes.func.isRequired,
  buyPhone: PropTypes.func.isRequired,
};

export default TollFreeAssignForm;
