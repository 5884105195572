import React, { useEffect } from "react";
import { connect } from "react-redux";
import { arrayOf, func, shape, string } from "prop-types";

import ErrorBanner from "../../../shared/v2/ErrorBanner";
import { Prompt } from "../../../shared/v2/Modal";

import {
  getAllDetailsAsThunk,
  getSourcesAsThunk,
  getPropertyTypesAsThunk,
  clearDetailsFailureAsThunk,
} from "./actions/thunks";

import DetailsWrapper from "./DetailsWrapper";
import DescriptionWrapper from "./DescriptionWrapper";
import LinksWrapper from "./LinksWrapper";
import ListingImagesWrapper from "./ListingImagesWrapper";
import { isReferral } from "../../shared/helpers";

const Details = ({
  uuid,
  error,
  type,
  onGetAllDetailsAsThunk,
  onGetSourcesAsThunk,
  onGetPropertyTypesAsThunk,
  onClearDetailsFailureAsThunk,
}) => {
  useEffect(() => {
    if (uuid.length) {
      onGetAllDetailsAsThunk(uuid);
      onGetSourcesAsThunk(uuid);
      onGetPropertyTypesAsThunk(uuid);
    }
  }, [uuid]);

  const errorPrompt = (
    <Prompt
      show={!!error}
      title={error?.title ?? "Error"}
      description={<ErrorBanner errors={error?.errors} />}
      primaryOption="OK"
      onCancel={onClearDetailsFailureAsThunk}
      onPrimary={onClearDetailsFailureAsThunk}
    />
  );

  const DescriptionLinksImages = (
    <>
      <DescriptionWrapper />
      <LinksWrapper />
      <ListingImagesWrapper />
    </>
  );

  return (
    <>
      {errorPrompt}
      <DetailsWrapper />
      {!isReferral(type) && DescriptionLinksImages}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetAllDetailsAsThunk: (uuid) => dispatch(getAllDetailsAsThunk(uuid)),
  onGetSourcesAsThunk: (uuid) => dispatch(getSourcesAsThunk(uuid)),
  onGetPropertyTypesAsThunk: (uuid) => dispatch(getPropertyTypesAsThunk(uuid)),
  onClearDetailsFailureAsThunk: () => dispatch(clearDetailsFailureAsThunk()),
});

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  error: state.tdpDetailsReducer.error,
  type: state.tdpDetailsReducer.headerDetails.type,
});

Details.propTypes = {
  uuid: string,
  error: shape({
    title: string,
    errors: arrayOf(string),
  }),
  type: string,
  onGetAllDetailsAsThunk: func.isRequired,
  onGetPropertyTypesAsThunk: func.isRequired,
  onGetSourcesAsThunk: func.isRequired,
  onClearDetailsFailureAsThunk: func.isRequired,
};

Details.defaultProps = {
  uuid: "",
  error: null,
  type: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
