import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ReportHeader = (props) => {
  const { size, handleSort, name, styles, label, sortKey, sortDesc, className, tooltipDesc } = props;
  const style = { display: "table-cell", ...styles };
  return (
    <div style={style} className={`${className} sort-header`} onClick={() => handleSort(name)}>
      <h6>
        {tooltipDesc ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={
              <Tooltip id="contacted">
                <span>{tooltipDesc}</span>
              </Tooltip>
            }
          >
            <span>{label}</span>
          </OverlayTrigger>
        ) : (
          label
        )}
        <span className={"arrows"}>
          <i className={`fa fa-sort-up ${sortKey === name && sortDesc == false && "selected"}`} />
          <i className={`fa fa-sort-down ${sortKey === name && sortDesc == true && "selected"}`} />
        </span>
      </h6>
      {name === ("contacted_count", "appointment_report_contacted_count") && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="contacted">
              <span>Based on the call outcome of "Talked to Contact"</span>
            </Tooltip>
          }
        >
          <span style={{ position: "relative", marginLeft: ".6em" }}>
            <div
              style={{
                width: "17px",
                display: "inline-block",
                height: "17px",
                borderRadius: "50%",
                textAlign: "center",
                position: "relative",
                backgroundColor: "#59C4C4",
                top: "2px",
              }}
            >
              <span
                style={{
                  fontFamily: "Lucida Bright, Georgia, serif",
                  fontSize: "12px",
                  color: "#FFF",
                  position: "relative",
                  fontWeight: "bold",
                  top: "-2px",
                }}
              >
                i
              </span>
            </div>
          </span>
        </OverlayTrigger>
      )}
      {name === "text_report_lead_text_count" && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="text_report_lead_text_count">
              <span>Leads texted who are assigned to the agent</span>
            </Tooltip>
          }
        >
          <span style={{ position: "relative", marginLeft: ".6em" }}>
            <div
              style={{
                width: "17px",
                display: "inline-block",
                height: "17px",
                borderRadius: "50%",
                textAlign: "center",
                position: "relative",
                backgroundColor: "#59C4C4",
                top: "2px",
              }}
            >
              <span
                style={{
                  fontFamily: "Lucida Bright, Georgia, serif",
                  fontSize: "12px",
                  color: "#FFF",
                  position: "relative",
                  fontWeight: "bold",
                  top: "-2px",
                }}
              >
                i
              </span>
            </div>
          </span>
        </OverlayTrigger>
      )}
      {name === "text_report_non_lead_text_count" && (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="text_report_non_lead_text_count">
              <span>Other contacts texted who are not assigned to the agent</span>
            </Tooltip>
          }
        >
          <span style={{ position: "relative", marginLeft: ".6em" }}>
            <div
              style={{
                width: "17px",
                display: "inline-block",
                height: "17px",
                borderRadius: "50%",
                textAlign: "center",
                position: "relative",
                backgroundColor: "#59C4C4",
                top: "2px",
              }}
            >
              <span
                style={{
                  fontFamily: "Lucida Bright, Georgia, serif",
                  fontSize: "12px",
                  color: "#FFF",
                  position: "relative",
                  fontWeight: "bold",
                  top: "-2px",
                }}
              >
                i
              </span>
            </div>
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default ReportHeader;
