/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ShareSolidV6 = (props) => (
  <Icon {...props} name="Share Solid V6">
    <path d="m15.741 7.069-5.5 4.722a.75.75 0 0 1-1.24-.568v-2.72c-4.847.069-6.936 1.272-5.519 5.803.157.503-.45.892-.877.582-1.367-.994-2.604-2.894-2.604-4.816 0-4.756 3.981-5.762 9-5.822V1.751a.75.75 0 0 1 1.24-.567l5.5 4.722c.347.326.347.864 0 1.164z" />
  </Icon>
);

export default ShareSolidV6;
