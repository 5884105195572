/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import PageGap from "./PageGap";
import { LeftLink, RightLink } from "./ArrowLink";
import { NumberedLink } from "./NumberedLink";
import { paginationOptionsWithExplicitGaps } from "./helpers";

const NumberedLinks = ({ totalPages, currentPage, onClick, maxEdgeSize, maxCentralNeighbors }) =>
  paginationOptionsWithExplicitGaps({
    totalPages,
    currentPage,
    maxCentralNeighbors,
    maxEdgeSize,
  }).map((pageNumber, index) =>
    pageNumber ? (
      <NumberedLink
        key={`pagination-${pageNumber}`}
        currentPage={currentPage}
        pageNumber={pageNumber}
        onClick={onClick}
      />
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <PageGap key={totalPages + index} />
    ),
  );

const Pagination = ({
  currentPage,
  totalPages,
  onGoToPage,
  maxCentralNeighbors,
  maxEdgeSize,
  ...otherProps
}) => {
  const handleGoToPage = (pageNum) => pageNum && onGoToPage && onGoToPage(pageNum);

  return (
    <div
      data-cy="pagination-holder"
      className="tw-flex tw-justify-items-center tw-items-center tw-space-x-1"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <LeftLink targetPage={currentPage - 1 > 0 ? currentPage - 1 : null} onClick={handleGoToPage} />
      <NumberedLinks
        totalPages={totalPages}
        currentPage={currentPage}
        onClick={handleGoToPage}
        maxCentralNeighbors={maxCentralNeighbors}
        maxEdgeSize={maxEdgeSize}
      />
      <RightLink
        targetPage={currentPage + 1 <= totalPages ? currentPage + 1 : null}
        onClick={handleGoToPage}
      />
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  maxCentralNeighbors: PropTypes.number,
  maxEdgeSize: PropTypes.number,
};

Pagination.defaultProps = {
  maxCentralNeighbors: 4,
  maxEdgeSize: 2,
};

export default Pagination;
