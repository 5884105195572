import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_INTERACTIONS = "tdpInteractions";
const wrap = curriedNamespaceAction(REDUCER_TDP_INTERACTIONS);

export const getSMSHistory = (smsHistory) => wrap({ type: "GET_SMS_HISTORY", smsHistory });

export const closeModal = (bool) => wrap({ type: "CLOSE_MODAL", bool });

export const errorHandler = (error) => wrap({ type: "ERROR_HANDLER", error });

export const sendingMessage = (bool) => wrap({ type: "SENDING_MESSAGE", bool });

export const fetchingMessages = (bool) => wrap({ type: "FETCHING_MESSAGES", bool });

export const conversationLoaded = (bool) => wrap({ type: "CONVERSATION_LOADED", bool });

export const moreSmsToLoad = (bool) => wrap({ type: "MORE_SMS_TO_LOAD", bool });

export const isEmailSent = (bool) => wrap({ type: "IS_EMAIL_SENT", bool });

export const sendEmailError = (error) => wrap({ type: "SEND_EMAIL_ERROR", error });

export const emailTemplates = (templates) => wrap({ type: "EMAIL_TEMPLATES", templates });

export const parsedTemplate = (template) => wrap({ type: "PARSED_TEMPLATE", template });

export const interactableEmails = (term) => wrap({ type: "GET_INTERACTABLE_EMAILS", term });

export const clearEmailError = () => wrap({ type: "ClEAR_EMAIL_ERROR" });

export const setConversation = (conversation) => wrap({ type: "SET_CONVERSATION", conversation });
