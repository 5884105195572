import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "./actions/creators";
import { saveBrandingForm } from "./actions/thunks";

import FormElement from "./components/FormElement";
import RadioOptions from "./components/RadioOptions";
import DisplayPerson from "./components/DisplayPerson";
import TransactionReportAccess from "./components/TransactionReportAccess";
import Dropdown from "../../../shared/v2/Dropdown";
import TextInput from "../../../shared/v2/TextInput";
import DatePicker from "../../../shared/v2/DatePicker";
import Button from "../../../shared/v2/Button";
import AvatarControl from "./components/AvatarControl";
import AvatarOption from "./components/AvatarOption";
import TextButton from "../../../shared/v2/TextButton/TextButton";
import CommOnlyOperator from "./components/CommOnlyOperator";

const SuperUsersBranding = ({
  isSaving,
  saveError,
  id,
  isPlaceSuperUser,
  brands,
  brokerages,
  partners,
  activePlaceTeams,
  formData,
  setFormData,
  addOperationalPartner,
  updateOperationalPartner,
  removeOperationalPartner,
  saveBrandingForm,
}) => {
  const selectedPartners = [
    formData?.operationalPartner?.value,
    ...formData?.operationalPartners?.map((p) => p.value?.value),
  ].filter((o) => o !== undefined);
  const availablePartners = partners.filter((p) => selectedPartners.indexOf(p.value) <= -1);
  const disabled =
    (formData?.branding?.label === "PLACE" && formData?.brokerage === null) ||
    (formData?.branding?.label === "PLACE" &&
      formData?.teamType === "Subteam" &&
      formData?.parentTeamAccount === null) ||
    formData?.teamName <= "" ||
    (formData?.branding?.label === "PLACE" && formData?.operationalPartner === null);
  return (
    <div className="brivity-theme tw-text-left tw-space-y-8 tw-my-8">
      {saveError && <div className="alert tw-bg-red-100 tw-text-red-500">{saveError}</div>}

      {isPlaceSuperUser ? (
        <FormElement label="Brand">PLACE</FormElement>
      ) : (
        <FormElement label="Brand">
          <Dropdown
            value={formData?.branding}
            onChange={(opt) => {
              setFormData({ branding: opt });

              if (opt.label === "PLACE") {
                setFormData({ networkAgent: "No" });
              }
            }}
            options={brands}
            isSearchable
            clearable
          />
        </FormElement>
      )}

      {formData?.branding?.label === "PLACE - Archived" && (
        <FormElement label="Partnership End Date">
          <DatePicker
            inputProps={{
              placeholder: "MM / DD / YYYY",
            }}
            value={formData?.placePartnershipEnded || ""}
            onChange={(val) => setFormData({ placePartnershipEnded: val })}
          />
        </FormElement>
      )}

      {formData?.branding?.label === "PLACE" && (
        <>
          <FormElement required label="Brokerage Affiliation">
            <Dropdown
              placeholder="Select brokerage affiliation"
              options={brokerages}
              value={formData?.brokerage}
              onChange={(opt) => setFormData({ brokerage: opt })}
              isSearchable
              clearable
            />
          </FormElement>
          <FormElement label="Network Agent?">
            <RadioOptions
              onChange={(opt) => setFormData({ networkAgent: opt.value })}
              options={[
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
              ]}
              value={formData?.networkAgent}
            />
          </FormElement>
          {formData?.networkAgent === "No" && (
            <>
              <FormElement label="Team Type">
                <RadioOptions
                  onChange={(opt) => setFormData({ teamType: opt.value })}
                  value={formData?.teamType}
                  options={[
                    { label: "PLACE Team", value: "PLACE Team" },
                    { label: "Subteam", value: "Subteam" },
                  ]}
                />
              </FormElement>
              {formData?.teamType === "Subteam" && (
                <FormElement label="Parent PLACE Team" required>
                  <Dropdown
                    placeholder="Select Parent Team"
                    options={activePlaceTeams}
                    value={formData?.parentTeamAccount}
                    onChange={(opt) => setFormData({ parentTeamAccount: opt })}
                    isSearchable
                    clearable
                  />
                </FormElement>
              )}
              <div className="tw-flex tw-space-x-4">
                <FormElement label="Team Name" required className="tw-flex-1">
                  <TextInput
                    value={formData?.teamName || ""}
                    onChange={(e) => setFormData({ teamName: e.target.value })}
                  />
                </FormElement>
                <FormElement label="Entity Number">
                  <TextInput
                    value={formData?.entityNumber || ""}
                    onChange={(e) => setFormData({ entityNumber: e.target.value })}
                  />
                </FormElement>
              </div>
              <FormElement label="Entity Name">
                <TextInput
                  value={formData?.entityName || ""}
                  onChange={(e) => setFormData({ entityName: e.target.value })}
                />
              </FormElement>
              <FormElement label="Partnership Effective Date">
                <DatePicker
                  inputProps={{
                    placeholder: "MM / DD / YYYY",
                  }}
                  value={formData?.partnershipEffective || ""}
                  onChange={(val) => setFormData({ partnershipEffective: val })}
                />
              </FormElement>
              <FormElement label="Current Contract Effective Date">
                <DatePicker
                  inputProps={{
                    placeholder: "MM / DD / YYYY",
                  }}
                  value={formData?.currentContractEffective || ""}
                  onChange={(val) => setFormData({ currentContractEffective: val })}
                />
              </FormElement>
              <FormElement label="Go Live Date">
                <DatePicker
                  inputProps={{
                    placeholder: "MM / DD / YYYY",
                  }}
                  value={formData?.goLive || ""}
                  onChange={(val) => setFormData({ goLive: val })}
                />
              </FormElement>
              <FormElement 
                tooltip="If set to false, all new users can be invited to activate their account but will not be able to access the CRM. You can override this setting on the super user “Users” page."
                label="Give New Users CRM Access by default?">
                <RadioOptions
                  onChange={(opt) => setFormData({ defaultCrmAccess: opt.value })}
                  options={[
                    { label: 'True', value: true },
                    { label: 'False', value: false },
                  ]}
                  value={formData?.defaultCrmAccess}
                />
              </FormElement>
              <div className="tw-flex tw-flex-col tw-gap-[8px]">
                <FormElement label="Operator" required>
                  <Dropdown
                    placeholder="Select Operator"
                    options={availablePartners}
                    value={formData?.operationalPartner}
                    onChange={(opt) => setFormData({ operationalPartner: opt })}
                    isSearchable
                    clearable
                    components={{
                      Control: AvatarControl,
                      Option: AvatarOption,
                    }}
                  />
                </FormElement>
                {formData?.operationalPartners?.map((partner, idx) => (
                  <FormElement key={idx} label={`Operator ${idx + 2}`}>
                    <DisplayPerson
                      value={partner.value}
                      options={availablePartners}
                      onDelete={() => removeOperationalPartner({ index: idx })}
                      onChange={(val) => updateOperationalPartner({ index: idx, value: val })}
                    />
                  </FormElement>
                ))}
                <TextButton onClick={addOperationalPartner} size="medium" className="tw-self-start">
                  + Operator
                </TextButton>
              </div>

              <CommOnlyOperator />
              <TransactionReportAccess />
            </>
          )}
        </>
      )}
      <div className="tw-text-center">
        <Button
          className="tw-min-w-[200px]"
          onClick={() => saveBrandingForm(id, formData)}
          size="medium"
          disabled={disabled || isSaving}
          isLoading={isSaving}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSaving: state.superUsersBranding.isSaving,
  saveError: state.superUsersBranding.saveError,
  id: state.superUsersBranding.id,
  isPlaceSuperUser: state.superUsersBranding.isPlaceSuperUser,
  iSearchingUsers: state.superUsersBranding.iSearchingUsers,
  brands: state.superUsersBranding.brands,
  brokerages: state.superUsersBranding.brokerages,
  placeBrandedAccounts: state.superUsersBranding.placeBrandedAccounts,
  activePlaceTeams: state.superUsersBranding.activePlaceTeams,
  partners: state.superUsersBranding.partners,
  people: state.superUsersBranding.people,
  formData: state.superUsersBranding.formData,
});

SuperUsersBranding.propTypes = {
  isSaving: PropTypes.bool,
  saveError: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPlaceSuperUser: PropTypes.bool,
  brands: PropTypes.arrayOf(PropTypes.shape({})),
  brokerages: PropTypes.arrayOf(PropTypes.shape({})),
  partners: PropTypes.arrayOf(PropTypes.shape({})),
  placeBrandedAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  activePlaceTeams: PropTypes.arrayOf(PropTypes.shape({})),
  formData: PropTypes.shape({}),
  setFormData: PropTypes.func,
  addOperationalPartner: PropTypes.func,
  updateOperationalPartner: PropTypes.func,
  removeOperationalPartner: PropTypes.func,
  addCommOnlyOperationalPartner: PropTypes.func,
  updateCommOnlyOperationalPartner: PropTypes.func,
  removeCommOnlyOperationalPartner: PropTypes.func,
  searchUsers: PropTypes.func,
};

SuperUsersBranding.defaultProps = {
  isSaving: false,
  saveError: null,
  id: null,
  isPlaceSuperUser: null,
  brands: [],
  brokerages: [],
  partners: [],
  placeBrandedAccounts: [],
  activePlaceTeams: [],
  formData: {},
  setFormData: null,
  addOperationalPartner: null,
  updateOperationalPartner: null,
  removeOperationalPartner: null,
  addCommOnlyOperationalPartner: null,
  updateCommOnlyOperationalPartner: null,
  removeCommOnlyOperationalPartner: null,
  searchUsers: null,
};

export default connect(mapStateToProps, { ...creators, saveBrandingForm })(SuperUsersBranding);
