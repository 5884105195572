import React from "react";
import moment from "moment";

import Avatar from "../../../../../shared/v2/Avatar";
import { DocumentPropType } from "../../propTypes";

import { PreviewDetailsDivider, PreviewDetailsGroup, PreviewDetail, PreviewDetailTitle } from "./common";

const PreviewUploadedBy = ({ document }) => (
  <>
    <PreviewDetailsDivider />

    <PreviewDetailsGroup>
      <PreviewDetail gapSize="medium" data-cy="document-preview-uploader">
        <PreviewDetailTitle>Uploaded By</PreviewDetailTitle>

        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center tw-gap-10px">
            <Avatar
              src={document.uploadedByImageUrl}
              alt={document.uploadedByName}
              data-cy="document-preview-uploader-avatar"
            >
              {document.uploadedByName}
            </Avatar>

            <div className="tw-flex tw-flex-col">
              <p
                className="tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-75"
                data-cy="document-preview-uploader-name"
              >
                {document.uploadedByName}
              </p>

              <p
                className="tw-m-0 tw-text-12d tw-font-normal tw-text-neutral-gray-50"
                data-cy="document-preview-uploader-role"
              >
                {document.uploadedByRole}
              </p>
            </div>
          </div>

          <p
            className="tw-m-0 tw-text-12d tw-font-normal tw-text-neutral-gray-75"
            data-cy="document-preview-uploaded-date"
          >
            {moment(document.uploadedAt).format("MM/DD/YYYY hh:mm A")}
          </p>
        </div>
      </PreviewDetail>
    </PreviewDetailsGroup>
  </>
);

PreviewUploadedBy.propTypes = {
  document: DocumentPropType.isRequired,
};

export default PreviewUploadedBy;
