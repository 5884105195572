import React from "react";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

const RecordConfirmationModal = ({ modalOpen, closeModal, onClickRecord, onClickNoRecord }) => (
  <Modal
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[510px]"
    show={modalOpen}
    onHide={closeModal}
  >
    <Modal.Header
      title="You must inform the other party before recording this call."
      className="tw-mb-32px"
      closeButton
    />
    <Modal.Body>
      <div>
        <div className="tw-text-gray-75 tw-flex tw-items-center tw-mb-32px">
          <p className="tw-text-14d tw-text-gray-75">
            Some states and countries require 2-party consent before recording a phone call.
          </p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[40px]">
      <Button schema="tertiary" size="medium" onClick={onClickNoRecord}>
        Don't Record
      </Button>

      <Button schema="primary" size="medium" onClick={onClickRecord}>
        Yes, I have informed them
      </Button>
    </Modal.Footer>
  </Modal>
);

RecordConfirmationModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  onClickRecord: Proptypes.func.isRequired,
  onClickNoRecord: Proptypes.func.isRequired,
};

RecordConfirmationModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
};

export default RecordConfirmationModal;
