/* eslint-disable camelcase */
import { arrayOf, number, shape } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import Filter from "../Filter";

const Filters = ({ primaryFilters }) => {
  const { overdue, due_today, upcoming, contingent, completed_at } = primaryFilters;

  return (
    <div className="tw-flex tw-h-[24px] tw-mb-24px">
      <Filter color="red" activeTarget="overdue" count={overdue.count}>
        Overdue
      </Filter>
      <Filter color="yellow" activeTarget="due_today" count={due_today.count}>
        Due Today
      </Filter>
      <Filter color="green" activeTarget="upcoming" count={upcoming.count}>
        Upcoming
      </Filter>
      <div className="tw-border-l-1px tw-border-r-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-neutral-gray-10 tw-mr-6px" />
      <Filter activeTarget="contingent" count={contingent.count} color="orange">
        Contingent
      </Filter>
      <Filter activeTarget="completed_at" count={completed_at.count} color="blue">
        Completed
      </Filter>
    </div>
  );
};

const mapStateToProps = (state) => ({
  primaryFilters: state.tdpTasksReducer.primaryFilters.byName,
});

const filterShape = shape({
  count: number,
  tasks: arrayOf(number),
});

const filterDefault = {
  count: 0,
  tasks: [],
};

Filters.propTypes = {
  primaryFilters: shape({
    overdue: filterShape,
    due_today: filterShape,
    upcoming: filterShape,
    contingent: filterShape,
    completed_at: filterShape,
  }),
};

Filters.defaultProps = {
  primaryFilters: {
    overdue: { ...filterDefault },
    due_today: { ...filterDefault },
    upcoming: { ...filterDefault },
    contingent: { ...filterDefault },
    completed_at: { ...filterDefault },
  },
};

export default connect(mapStateToProps)(Filters);
