/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const RightFromBracketSolidV6 = (props) => (
  <Icon {...props} name="Right From Bracket Solid V6">
    <path d="M3 15h2a1 1 0 1 0 0-2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2a1 1 0 1 0 0-2H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3zm12.775-7.547-4.503-4.25a.75.75 0 0 0-1.265.545l-.003 2.25h-4a1 1 0 0 0-1.001 1v2a1 1 0 0 0 1.001 1h4l.003 2.222a.751.751 0 0 0 1.265.545l4.503-4.25c.3-.254.3-.779 0-1.063z" />
  </Icon>
);

export default RightFromBracketSolidV6;
