import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { useEffect, useState } from "react";

const useOpenHouseFormOptions = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    caseTransformingAxios
      .get("/api/v4/reporting/open_house_dashboard/forms", { signal: abortController.signal })
      .then((res) => setData(res.data.data.forms))
      .catch(console.log);

    return () => abortController.abort();
  }, []);

  return data;
};

export default useOpenHouseFormOptions;
