import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Input, Dropdown } from "../../shared/v1";
import CCSearchResults from "./CCSearchResults";

export default class CCFor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      highlightedIndex: 0,
      // eslint-disable-next-line react/no-unused-state
      highlightedResult: {},
      search_query: "",
      search_query_length: 0,
      target: "",
      results: [],
    };
  }

  componentDidMount() {
    this.getRoleResults();
  }

  getRoleResults = () => {
    const { planType } = this.props;
    axios({
      method: "get",
      url: `/auto_plans/quick_search_role_recipients?plan_type=${planType}`,
      headers: {
        "X-CSRF-Token": ReactOnRails.authenticityToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.data.length === 0) this.setState({ roleResults: [] });
      else this.showRoleResults(response.data);
    });
  };

  handleSearchChange = (e) => {
    const { search_query_length: searchQueryLength } = this.state;
    const { value } = e.target;

    this.setState({ search_query: value, search_query_length: value.split("").length }, () => {
      if (searchQueryLength < 3) this.setState({ results: [] });

      if (searchQueryLength > 2) this.getResults();
    });
  };

  getResults = () => {
    const { search_query: searchQuery } = this.state;
    const { type } = this.props;
    if (type === "people") {
      axios({
        method: "get",
        url: `/auto_plans/quick_search_email_recipients?search=${searchQuery}`,
        headers: {
          "X-CSRF-Token": ReactOnRails.authenticityToken(),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        if (response.data.length === 0) this.displayZeroResults();
        else this.showResults(response.data);
      });
    } else if (type === "email") {
      const email = searchQuery;
      if (/^[^@\s]+@[^@\s]+$/.test(email)) {
        this.showResults([
          {
            label: email,
            value: email,
            name: "_emails",
            cc_type: "Email",
            icon: "fa fa-envelope",
            color: "tw-text-brand-danger",
          },
        ]);
      }
    }
  };

  hideSearchResults = () => {
    this.setState({ results: [], search_query: "", search_query_length: 0 });
    this.highlight(0);
  };

  displayZeroResults = () => {
    this.setState({ results: [] });
  };

  showResults = (data) => {
    const { highlightedIndex } = this.state;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ results: data, highlightedResult: data[highlightedIndex] });
  };

  showRoleResults = (data) => {
    this.setState({ roleResults: data });
  };

  setTarget = (e) => {
    const { target } = e;

    this.setState({ target, width: target.clientWidth });
  };

  highlight = (index) => {
    this.setState({ highlightedIndex: index });
  };

  unhighlight = () => {
    this.setState({ highlightedIndex: null });
  };

  selectObject = (result) => {
    const { onOptionSelect } = this.props;
    if (result.assigned_to_type === "Role") {
      onOptionSelect({
        name: "_role_uuids",
        label: result.label,
        value: result.value,
        cc_type: result.assigned_to_type,
      });
    } else {
      onOptionSelect(result);
    }
    this.hideSearchResults();
  };

  selectByIndex = () => {
    const { results, highlightedIndex, selected } = this.state;
    const newResult = results[highlightedIndex];
    if (Object.values(selected).length === 0) {
      this.selectObject(newResult);
    }
  };

  indexDown = () => {
    const { results, highlightedIndex } = this.state;
    if (highlightedIndex < results.length - 1) this.setState({ highlightedIndex: highlightedIndex + 1 });
  };

  indexUp = () => {
    const { highlightedIndex } = this.state;
    if (highlightedIndex > 0) this.setState({ highlightedIndex: highlightedIndex - 1 });
  };

  keyPressHandler = (e) => {
    if ([13, 40, 38].includes(e.keyCode)) {
      this.keyPressOptions()[e.keyCode.toString()]();
    }
  };

  highlightedValue = (index, result) => {
    const { highlightedIndex } = this.state;
    // eslint-disable-next-line react/no-unused-state
    if (highlightedIndex === index) this.setState({ highlightedResult: result });
  };

  keyPressOptions = () => ({
    13: this.selectByIndex,
    40: this.indexDown,
    38: this.indexUp,
  });

  render() {
    const {
      highlightedIndex,
      results,
      roleResults,
      search_query_length: searchQueryLength,
      search_query: searchQuery,
      target,
      width,
    } = this.state;
    const { type, icon, placeholder } = this.props;
    return (
      <div>
        {type === "role" ? (
          <Dropdown
            placeholder="Select a role"
            onUpdate={this.selectObject}
            isClearable={false}
            value={null}
            options={roleResults}
          />
        ) : (
          <>
            <Input
              size="small"
              onChange={this.handleSearchChange}
              autoComplete="off"
              onClick={this.setTarget}
              onKeyDown={this.keyPressHandler}
              style={{ position: "relative" }}
              icon={icon}
              value={(searchQuery.split("").length > 0 && searchQuery) || ""}
              name="search_query"
              placeholder={placeholder}
            />

            <span className="fa fa-search tw-absolute tw-top-[3.7em] tw-right-10px tw-z-2 tw-block tw-w-34px tw-h-34px tw-text-center tw-pointer-events-none" />

            <CCSearchResults
              type={type}
              results={results}
              search_query_length={searchQueryLength}
              search_query={searchQuery}
              target={target}
              highlight={this.highlight}
              unhighlight={this.unhighlight}
              highlightedIndex={highlightedIndex}
              highlightedValue={this.highlightedValue}
              hideSearchResults={this.hideSearchResults}
              selectObject={this.selectObject}
              width={width}
            />
          </>
        )}
      </div>
    );
  }
}

CCFor.propTypes = {
  planType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
};
