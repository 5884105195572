import React, { useState, useEffect, useMemo } from "react";
import { Form, FormGroup, InputGroup, FormControl } from "react-bootstrap";
import {debounce, capitalize} from "lodash";



const SearchInput = ({onSearch, options}) => {
  const [currentSearchTerm, _setCurrentSearchTerm] = useState(new URLSearchParams(window.location.search).get("q")||"");
  const [currentRoleFilter, setCurrentRoleFilter] = useState(new URLSearchParams(window.location.search).get("role")||"");

  const setCurrentSearchTerm = useMemo(() => debounce(_setCurrentSearchTerm, 200),[])

  useEffect(() => {onSearch(currentSearchTerm, currentRoleFilter)}, [currentSearchTerm, currentRoleFilter])

  return (
    <Form inline style={{ display: "inline-block", marginLeft: "8px" }}>
      <FormGroup>
        <InputGroup>

          <FormControl
            componentClass="select"
            placeholder="select"
            onChange={(e) => setCurrentRoleFilter(e.target.value)}
            value={currentRoleFilter}
          >
            <option value="">All Roles</option>
            { options.map((o) => <option key={o} value={o}>{capitalize(o)}</option>) }
          </FormControl>
        </InputGroup>
        <InputGroup>
          <InputGroup.Addon>Search by Email</InputGroup.Addon>

          <FormControl
            type="text"
            onChange={(e) => setCurrentSearchTerm(e.target.value)}
          />
        </InputGroup>
      </FormGroup>
    </Form>
  )
}

export default SearchInput;
