import React, {Component} from 'react';
import capitalize from 'capitalize';

export default class HomeValueReportEvent extends Component {
    constructor(props, context) {
        super(props, context)

        this.interacted_at_epoch = this.props.interacted_at_epoch
        this.event = this.props.event
    }

    details = () => {
        let description = [];
        let fields = this.event.timeline_fields;
        for (var key in fields) {
            const titleized_key = key
                .split("_")
                .map((element) => {
                    return capitalize(element);
                })
                .join(" ");
            if (key !== 'search_link') {
                description.push(titleized_key + ": " + fields[key]);
            }
        }
        let report_type_name = this.event.event_type === 'market_report' ? 'Market Report' : 'Home Value Report';

        return (
            <div>
                {description.map((description) => {
                    return (
                        <div key={description} className="row tw-break-words details-row">
                            {description}
                        </div>
                    );
                })}
                {fields.search_link && (
                    <div className="row tw-break-words details-row">
                        <a href={fields.search_link} target="_blank">View {report_type_name}</a>
                    </div>
                )}
            </div>
        )
    }

    row = () => {
        return (
            <div className="timeline-event market-report-timeline-event">
                <div className="row">
                    <div className="col-xs-1">
                        <i className="fa fa-home timeline-icon-circle"/>
                    </div>
                    <div className="col-xs-7">
                        <span className="bold-detail">
                          Event:
                        </span>
                        {' Home Value'}
                        <div className="timeline-description-text">
                            {this.details()}
                        </div>
                    </div>
                    <div className="col-xs-1"/>
                    <div className="col-xs-3 time-ago">
                        <span>{this.event.interacted_at_absolute}</span>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.row()}
            </div>
        )
    }
}
