/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

export default (WrappedComponent) =>
  ({ ...props }) => {
    const [isPencilVisible, setPencilVisible] = useState(false);
    return (
      <WrappedComponent {...props} isPencilVisible={isPencilVisible} setPencilVisible={setPencilVisible} />
    );
  };
