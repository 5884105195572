/* eslint-disable react/prop-types, react/jsx-props-no-spreading */

import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import "./DatePicker.scss";
import TextInput from "../../../shared/v2/TextInput";

const DatePicker = ({ style, className, inputProps, value, ...otherProps }) => {
  const inputRef = useRef();

  useEffect(() => {
    // Force date picker to show after clicking on input. "open" prop on library disables onBlur handler.
    inputRef.current.focus();
  }, []);

  const handleDataOnSave = (momentObj) => {
    otherProps.onChange(momentObj);
  };

  const handleDateOnChange = (val) => {
    if (moment(val, ["MM-DD-YYYY", "YYYY-MM-DD"], true).isValid()) {
      handleDataOnSave(val);
    }
  };

  const handleDateOnBlur = (val) => {
    otherProps.onBlur(val);
  };

  const handleDateOnFocus = () => {
    if (!moment(value, ["MM-DD-YYYY", "YYYY-MM-DD"], true).isValid()) {
      otherProps.onChange(moment().format("YYYY-MM-DD"));
    }
  };

  const showCalendar = (originalOtherProps) => (
    <TextInput
      {...originalOtherProps}
      ref={inputRef}
      className="datepicker-input tw-border-gray-500 tw-border tw-p-0"
    />
  );

  const formatValue = () => {
    if (otherProps.dateFormat !== undefined && otherProps.dateFormat === "MM-DD") {
      return moment(value, "MM-DD").format("MM-DD");
    }
    if (moment(value, "YYYY-MM-DD", true).isValid()) {
      return moment(value, "YYYY-MM-DD").format("MM-DD-YYYY");
    }
    return "";
  };

  return (
    <div className="tw-relative tw-flex tw-flex-col tw-flex-nowrap">
      <Datetime
        {...otherProps}
        style={style}
        className={className}
        value={formatValue()}
        renderInput={showCalendar}
        onChange={handleDateOnChange}
        onBlur={handleDateOnBlur}
        onFocus={handleDateOnFocus}
        inputProps={inputProps}
      />
    </div>
  );
};

export default DatePicker;
