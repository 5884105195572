/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CopyRegularV6 = (props) => (
  <Icon {...props} name="Copy Regular V6">
    <path d="M15.706 2.207 13.792.293A.996.996 0 0 0 13.084 0H7.972a2 2 0 0 0-2 2l.001 8c.028 1.106.924 2 2.028 2h6c1.1 0 2-.9 2-2V2.914a1 1 0 0 0-.294-.707zM14.5 10a.5.5 0 0 1-.5.5H7.972a.5.5 0 0 1-.5-.5V2.004a.5.5 0 0 1 .5-.5h4L12 3a1 1 0 0 0 1 1h1.472v6h.028zm-6 4a.5.5 0 0 1-.5.5H1.972a.5.5 0 0 1-.5-.5L1.5 6.003a.5.5 0 0 1 .5-.5h3.001V4h-3a2 2 0 0 0-2 2v8c0 1.103.896 2 2 2h6c1.1 0 2-.9 2-2v-1H8.529l-.028 1z" />
  </Icon>
);

export default CopyRegularV6;
