/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TreeDeciduousSolidV6 = (props) => (
  <Icon {...props} name="Tree Deciduous Solid V6">
    <path d="M16 9c0 .87-.32 1.608-.957 2.204-.565.53-1.333.796-2.108.796H8.999v3c0 .553-.444 1-.997 1s-1.031-.447-1.031-1l.028-3H3.065c-.775 0-1.543-.266-2.108-.796A2.91 2.91 0 0 1-.001 9c0-.606.168-1.128.5-1.628s.781-.863 1.344-1.094c-.293-.5-.403-1.016-.328-1.547s.285-.988.641-1.375c.355-.387.785-.641 1.297-.766S4.481 2.488 5 2.718c.081-.769.409-1.418.984-1.938S7.231-.001 8-.001c.769 0 1.442.262 2.016.781s.903 1.168.984 1.938c.519-.23 1.035-.253 1.547-.128s.941.379 1.297.766.566.844.641 1.375-.027 1.047-.297 1.547h-.031c.562.23 1.012.594 1.344 1.094s.5 1.022.5 1.628z" />
  </Icon>
);

export default TreeDeciduousSolidV6;
