import React from "react";

const StatCardIcon = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2.65668C6.6745 2.65668 5.6 3.92646 5.6 5.49305C5.6 7.05938 6.6745 8.32942 8 8.32942C9.325 8.32942 10.4 7.05989 10.4 5.49305C10.4 3.92646 9.3255 2.65668 8 2.65668ZM9 6.91123C9 7.02317 8.9105 7.11383 8.8 7.11383H7.2C7.0895 7.11383 7 7.02317 7 6.91123V6.50604C7 6.3941 7.0895 6.30344 7.2 6.30344H7.6V4.89944L7.58825 4.90729C7.56641 4.92206 7.5419 4.93232 7.51614 4.93748C7.49038 4.94265 7.46387 4.94262 7.43812 4.9374C7.41237 4.93218 7.38789 4.92187 7.36608 4.90705C7.34426 4.89224 7.32555 4.87321 7.311 4.85107L7.089 4.514C7.07442 4.49187 7.06429 4.46705 7.05919 4.44095C7.05409 4.41486 7.05412 4.388 7.05928 4.36192C7.06443 4.33583 7.07461 4.31103 7.08923 4.28894C7.10386 4.26684 7.12264 4.24788 7.1445 4.23315L7.52775 3.97433C7.62629 3.90777 7.74207 3.87226 7.8605 3.87227H8.2C8.3105 3.87227 8.4 3.96293 8.4 4.07487V6.30344H8.8C8.9105 6.30344 9 6.3941 9 6.50604V6.91123ZM15.2 0.630707H0.8C0.35825 0.630707 0 0.99361 0 1.4411V9.545C0 9.99249 0.35825 10.3554 0.8 10.3554H15.2C15.6418 10.3554 16 9.99249 16 9.545V1.4411C16 0.99361 15.6418 0.630707 15.2 0.630707ZM14.8 7.51903C13.9163 7.51903 13.2 8.24458 13.2 9.13981H2.8C2.8 8.24458 2.08375 7.51903 1.2 7.51903V3.46707C2.08375 3.46707 2.8 2.74152 2.8 1.84629H13.2C13.2 2.74152 13.9163 3.46707 14.8 3.46707V7.51903Z"
      fill="#59C4C4"
    />
  </svg>
);

export default StatCardIcon;
