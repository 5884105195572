import React from "react";

import { headline2, bodyS, headline3 } from "@shared/styles";
import Title from "../ModalSections/Title";
import Subdomain from "../ModalSections/Subdomain/Subdomain";
import DefaultLinks from "../ModalSections/DefaultLinks";
import CustomLinks from "../ModalSections/CustomLinks";

import { containerView, borderHr } from "./styles";
import AboutMe from "../ModalSections/AboutMe";

const WebsiteInfoViewRef = () => (
  <div className={containerView}>
    <div className={`tw-text-neutral-gray-75 ${headline2}`}>Website Info</div>
    <div className={bodyS}>Changes may take several minutes to reflect on your Brivity website.</div>
    <div className={headline3}>Basic</div>
    <Title />
    <Subdomain />
    <hr className={`${borderHr} tw-m-0`} />
    <div className={headline3}>Review Links</div>
    <div className="tw-text-gray-50">
      Enter the links for the websites you use to collect client reviews. They are added to your <b>Links</b>{" "}
      page. (Example: john.mywebsite.com/links)
    </div>
    <DefaultLinks />
    <div className={headline3}>Custom Links</div>
    <div className="tw-text-gray-50 tw-tracking-[-0.4px]">
      Custom links are added to your <b>Links</b> page. (Example: john.mywebsite.com/links)
    </div>
    <CustomLinks />
    <div className={headline3}>About Me</div>
    <AboutMe />
  </div>
);

export default WebsiteInfoViewRef;
