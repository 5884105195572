import React from "react";
import { bool, func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import {
  ISREFERRALFEEPERCENTAGE,
  LISTING_UUID,
  REFERRALFEE,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import HeaderFinancial from "../../shared/HeaderFinancial";
import InlineEditingInput from "../../../../shared/v2/InlineEditingInput";
import { putRefFeeAsThunk } from "../../actions/thunks";
import { editIsReferralFeePercentage } from "../../actions/creators";

const REF_LABEL = "referral fee";

const flatOrPercentHandlr = (isPercent) => (isPercent ? "percent" : "flat");

const ReferralFee = ({
  referralFee,
  onPutRefFeeAsThunk,
  uuid,
  isReferralFeePercentage,
  onEditIsReferralFeePercentage,
}) => {
  const onChangeHandlr = (refFee) =>
    onPutRefFeeAsThunk({
      referralFee: refFee ? parseFloat(refFee.replace(",", "")) : null,
      isFeePercentage: isReferralFeePercentage,
      uuid,
    });

  return (
    <HeaderFinancial
      value={referralFee}
      label={REF_LABEL}
      onChange={onChangeHandlr}
      InlineEditComponent={InlineEditingInput.FlatPercent}
      flatOrPercent={flatOrPercentHandlr(isReferralFeePercentage)}
      onFlatSelected={() => onEditIsReferralFeePercentage(false)}
      onPercentSelected={() => onEditIsReferralFeePercentage(true)}
    />
  );
};

const withReduxState = tdpDetails({
  WrappedComponent: ReferralFee,
  states: [REFERRALFEE, LISTING_UUID, ISREFERRALFEEPERCENTAGE],
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: [putRefFeeAsThunk, editIsReferralFeePercentage],
  aliases: ["onPutRefFeeAsThunk", "onEditIsReferralFeePercentage"],
});

ReferralFee.propTypes = {
  referralFee: string,
  onPutRefFeeAsThunk: func,
  isReferralFeePercentage: bool,
  onEditIsReferralFeePercentage: func,
  uuid: string.isRequired,
};

ReferralFee.defaultProps = {
  referralFee: null,
  onPutRefFeeAsThunk: () => {},
  isReferralFeePercentage: true,
  onEditIsReferralFeePercentage: () => {},
};
