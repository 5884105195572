/* eslint-disable no-cond-assign */
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import View from "./view";

const Index = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <View />
    </Provider>
  );
};

export default Index;
