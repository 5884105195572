import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import TooltipContainer from "./RingChartTooltip";

const MAX_ANGLE = 359.999;

const polarToCartesianCoords = (c, r, deg) => {
  const rad = (((deg || 0) - 90) * Math.PI) / 180;
  return [c + r * Math.cos(rad), c + r * Math.sin(rad)];
};

const Arc = ({
  center,
  radius,
  startAngle,
  endAngle,
  onMouseEnter,
  onMouseLeave,
  size,
  strokeWidth,
  tooltip,
  variant,
  ...props
}) => {
  const pathRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const push = strokeWidth * 0.15;
  const r = isHovering ? radius + push : radius;
  const [startX, startY] = polarToCartesianCoords(center, r, startAngle);
  const [endX, endY] = polarToCartesianCoords(center, r, endAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  let left = null;
  let top = null;
  if (pathRef.current) {
    if (isHovering) {
      const svg = pathRef.current.parentElement;
      const { x, y } = svg.getBoundingClientRect();
      const svgR = svg.clientWidth / 2 - strokeWidth;
      const [tooltipX, tooltipY] = polarToCartesianCoords(
        center,
        svgR,
        startAngle + (endAngle - startAngle) * 0.9,
      );
      left = window.scrollX + x + tooltipX;
      top = window.scrollY + y + tooltipY - strokeWidth;
    }
  }
  return (
    <>
      <path
        ref={pathRef}
        style={{ strokeDasharray: size * 3, strokeDashoffset: size * 3 }}
        className="tw-animate-path"
        d={`M ${startX} ${startY} A ${r} ${r} 0 ${largeArcFlag} 1 ${endX} ${endY}`}
        fill="none"
        strokeLinecap={variant === "segmented" ? "" : "round"}
        strokeWidth={isHovering ? strokeWidth + push * 2 : strokeWidth}
        onMouseEnter={(e) => {
          setIsHovering(true);
          if (typeof onMouseEnter === "function") onMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          setIsHovering(false);
          if (typeof onMouseLeave === "function") onMouseLeave(e);
        }}
        {...props}
      />
      {tooltip && <TooltipContainer left={left} top={top} tooltip={tooltip} />}
    </>
  );
};

Arc.propTypes = {
  center: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  size: PropTypes.number.isRequired,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tooltip: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(["stacked", "segmented"]).isRequired,
};

Arc.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
  tooltip: null,
};

const RingChart = ({ baseTooltip, children, size, strokeWidth, data, label, title, variant, fullWidth }) => {
  const center = size / 2;
  const radius = center - strokeWidth;

  return (
    <div style={{ width: fullWidth ? "100%" : size }}>
      <div className="tw-relative tw-pb-[100%]">
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          preserveAspectRatio="xMidYMin slice"
          className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
        >
          <Arc
            size={size}
            center={center}
            radius={radius}
            startAngle={0}
            endAngle={MAX_ANGLE}
            stroke="#F5F5F5"
            strokeWidth={strokeWidth}
            variant={variant}
            tooltip={baseTooltip}
          />
          {data.map((d, i) => {
            const startAngle = variant === "segmented" && i > 0 ? data[i - 1].value * MAX_ANGLE : 0;
            const endAngle = Math.min(d.value * MAX_ANGLE, MAX_ANGLE);
            return (
              <Arc
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                tooltip={d.tooltip}
                size={size}
                center={center}
                radius={radius}
                startAngle={startAngle}
                endAngle={endAngle}
                stroke={d.color}
                strokeWidth={strokeWidth}
                variant={variant}
              />
            );
          })}
          {title && (
            <text
              fontSize={0.16 * size}
              fontFamily="Open Sans, sans-serif"
              x="50%"
              y={title ? `${50 - 0.1 * size}%` : "50%"}
              textAnchor="middle"
              fill="#666666"
            >
              <tspan alignmentBaseline="middle">{title}</tspan>
            </text>
          )}
          <text
            fontSize={0.16 * size}
            fontWeight={title ? "bold" : undefined}
            fontFamily="Open Sans, sans-serif"
            x="50%"
            y={title ? `${50 + 0.1 * size}%` : "50%"}
            textAnchor="middle"
            fill="#666666"
          >
            <tspan alignmentBaseline="middle">{label}</tspan>
          </text>
        </svg>
        {children}
      </div>
    </div>
  );
};

RingChart.propTypes = {
  baseTooltip: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  size: PropTypes.number,
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, color: PropTypes.string })),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  variant: PropTypes.oneOf(["stacked", "segmented"]),
  fullWidth: PropTypes.bool,
};

RingChart.defaultProps = {
  baseTooltip: null,
  children: null,
  size: 100,
  strokeWidth: 8,
  data: [],
  label: "",
  title: "",
  variant: "stacked",
  fullWidth: false,
};

export default RingChart;
