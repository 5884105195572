import React, { useMemo } from 'react';

import RemovableBubble from "../../../shared/RemovableBubble";
import { formatPrice } from "../helpers";
import { ExistingFeatureFields } from '../helpers';

const FilterBubbles = ({searchModel, featureFields, clearSearchParams, clearArrayValue, clearFilterOption, clearFeatureOption, saveSearch}) => {
  const featureFieldsWithLabels = useMemo(() => parseFeatureOptions(featureFields), [featureFields]);

  const getOptionValuesAndClear = (description) => {
    const { min, max } = FilterDataForBubbles.find((f) => f.desc === description);
    clearFilterOption(min, max);
  };

  const createFilterBubbles = () => {
    const bubbles = [];
    const arrayFields = [
      { field: "agentIds", textFormatter: (a, identifier) => `${identifier} — #${a}` },
      { field: "officeIds", textFormatter: (o, identifier) => `${identifier} — #${o?.split('|')[0]}` },
      { field: "cityStates", textFormatter: (c) => `${c.city}, ${c.state}` },
      { field: "neighborhoodCityStates", textFormatter: (n) => `${n.neighborhood}, ${n.city}, ${n.state}` },
      { field: "countyStates", textFormatter: (cs) => `${cs.county}, ${cs.state}` },
      { field: "postalCodes", textFormatter: (p) => p },
      { field: "mlsNums", textFormatter: (m) => m },
      { field: "streetAddress", textFormatter: (s) => s },
      { field: "propertyTypes", textFormatter: (p) => p },
      { field: "schoolDistricts", textFormatter: (sd) => sd }
    ];

    arrayFields.forEach(({ field, textFormatter }) => {
      searchModel[field]?.forEach((v) => {
        bubbles.push(
          <RemovableBubble
            value={textFormatter(v, searchModel.getIdentifier(field, v))}
            key={textFormatter(v, searchModel.getIdentifier(field, v))}
            remove={() => clearArrayValue(field, v)}
            includeMargins={false}
          />,
        );
      });
    });

    FilterDataForBubbles.forEach(({ desc, min, max, asPrice }) => {
      const text = createFilterText(desc, searchModel[min], searchModel[max], asPrice);
      if (text) {
        bubbles.push(<RemovableBubble value={text} key={text} remove={() => getOptionValuesAndClear(desc)} includeMargins={false} />);
      }
    });

    ExistingFeatureFields.forEach(f => {
      if (searchModel[f]?.fields) {
        searchModel[f].fields.forEach(ff => {
          const key = makeFeatureKey(f, ff.term);
          bubbles.push(<RemovableBubble value={featureFieldsWithLabels[key]} key={key} remove={() => clearFeatureOption(f, ff.term)} includeMargins={false} />);
        });
      }
    });

    if (bubbles.length) {
      bubbles.push(
        <span
          key="save-search"
          className="tw-whitespace-nowrap tw-text-teal tw-text-12px tw-font-bold tw-cursor-pointer"
          onClick={saveSearch}
        >
          SAVE SEARCH
        </span>,
        <span
          key="clear-all"
          className="tw-whitespace-nowrap tw-text-gray-50 tw-text-12px tw-font-bold tw-cursor-pointer hover:tw-text-semantic-red-100 active:tw-text-semantic-red-120"
          onClick={clearSearchParams}
        >
          CLEAR ALL
        </span>
      );
    }

    return bubbles;
  };

  return createFilterBubbles();
}

export default FilterBubbles;

const makeFeatureKey = (category, value) => `${category}_${value}`;

const parseFeatureOptions = (featureFields) => {
  if (!featureFields) {
    return {};
  }

  return Object.values(featureFields).reduce((acc, vals) => {
    vals.forEach((v) => {
      acc[makeFeatureKey(v.search_category, v.search_value)] = v.label;
    });

    return acc;
  }, {});
};

const FilterDataForBubbles = [
  {
    desc: "Price",
    min: "priceMin",
    max: "priceMax",
    asPrice: true,
  },
  {
    desc: "Square Feet",
    min: "sqFtMin",
    max: "sqFtMax",
  },
  {
    desc: "Beds",
    min: "bedsMin",
    max: "bedsMax",
  },
  {
    desc: "Baths",
    min: "bathsMin",
    max: "bathsMax",
  },
  {
    desc: "Lot Size",
    min: "lotSizeMin",
    max: "lotSizeMax",
  },
  {
    desc: "Year Built",
    min: "yearMin",
    max: "yearMax",
  },
  {
    desc: "Garage Spaces",
    min: "garageMin",
    max: "garageMax",
  },
  {
    desc: "Stories",
    min: "storiesMin",
    max: "storiesMax",
  },
];

const createFilterText = (desc, min, max, asPrice = false) => {
  if (!min && !max) {
    return;
  }

  const minFormatted = asPrice ? formatPrice(min) : min;
  const maxFormatted = asPrice ? formatPrice(max) : max;

  return minFormatted === maxFormatted
    ? `${desc}: ${minFormatted}`
    : `${desc}: ${minFormatted || "No Min"} - ${maxFormatted || "No Max"}`;
};
