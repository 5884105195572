import React from "react";
import Proptypes from "prop-types";
import { useOnMinMaxSelectedChange, useMinMaxState } from "../../hookHelpers";
import { hasMinMaxError } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";

import Slider from "../Slider/multi";

const PriceSelector = ({ min, max, onChange }) => {
  const [selectedMin, onSelectMin, selectedMax, onSelectMax] = useMinMaxState(min, max);
  const hasError = hasMinMaxError(selectedMin, selectedMax);
  const debouncedMin = useDebounce(selectedMin, 500);
  const debouncedMax = useDebounce(selectedMax, 500);
  useOnMinMaxSelectedChange(debouncedMin, debouncedMax, hasError, onChange);
  return (
    <div data-cy="price-input-wrapper" className="tw-mb-[4px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Price</span>
      <Slider
        min={0}
        max={10000000}
        step={1000}
        minVal={selectedMin}
        maxVal={selectedMax}
        onChange={(e) => {
          onSelectMin(e.min);
          onSelectMax(e.max);
        }}
      />
    </div>
  );
};

export default PriceSelector;

PriceSelector.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  onChange: Proptypes.func,
};

PriceSelector.defaultProps = {
  min: undefined,
  max: undefined,
  onChange: () => {},
};
