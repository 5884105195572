import { bodyS } from "@shared/styles";

const STYLES = {
  containerView: "tw-flex tw-flex-col tw-gap-[24px]",
  borderHr: "tw-w-full tw-border-gray-10 tw-m-0",
  inputDescription: `${bodyS} tw-mt-4`,
  defaultLink: "tw-w-[254px]",
};

export default STYLES;

export const { containerView, borderHr, inputDescription, defaultLink } = STYLES;
