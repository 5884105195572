import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "@shared/v2/Typography";
import { CloudArrowUpSolidV6 } from "@shared/v2/Icomoon";

const ImageDropTarget = ({ onChange }) => {
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      let counter = 0;
      const onDrop = (e) => {
        e.preventDefault();
        buttonRef.current.classList.remove("!tw-border-theme-primary", "!tw-border-solid");
        const file = e.dataTransfer.files?.[0];
        if (file?.type.startsWith("image/")) onChange(e.dataTransfer.files?.[0]);
      };

      const onDragOver = (e) => {
        e.preventDefault();
      };

      const onDragEnter = (e) => {
        e.preventDefault();
        counter += 1;
        buttonRef.current.classList.add("!tw-border-theme-primary", "!tw-border-solid");
      };

      const onDragLeave = (e) => {
        e.preventDefault();
        counter -= 1;
        if (counter === 0) buttonRef.current.classList.remove("!tw-border-theme-primary", "!tw-border-solid");
      };

      buttonRef.current.addEventListener("dragover", onDragOver);
      buttonRef.current.addEventListener("dragenter", onDragEnter);
      buttonRef.current.addEventListener("dragleave", onDragLeave);
      buttonRef.current.addEventListener("drop", onDrop);

      return () => {
        buttonRef.current.removeEventListener("dragover", onDragOver);
        buttonRef.current.removeEventListener("dragenter", onDragEnter);
        buttonRef.current.removeEventListener("dragleave", onDragLeave);
        buttonRef.current.removeEventListener("drop", onDrop);
      };
    }
    return () => {};
  }, [buttonRef.current]);

  return (
    <>
      <button
        ref={buttonRef}
        type="button"
        className="tw-bg-gray-5 tw-border-dashed tw-border-[2px] tw-border-gray-med-dark active:tw-border-solid active:tw-border-theme-primary tw-rounded-[8px] tw-flex tw-flex-col tw-items-center tw-gap-[16px] tw-px-[16px] tw-py-[32px]"
        onClick={() => inputRef.current?.click()}
      >
        <CloudArrowUpSolidV6 size="xxxl" />
        <Typography variant="subtitle-m" className="tw-text-center !tw-text-gray-75">
          Click here to upload or drag and drop an image here.
        </Typography>
      </button>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className="!tw-hidden"
        onChange={(e) => onChange(e.target.files?.[0])}
      />
    </>
  );
};

ImageDropTarget.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ImageDropTarget;
