import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckSolidV6, CopySolidV6 } from "@shared/v2/Icomoon";
import FieldLabel from "@shared/v2/FieldLabel";
import IconButton from "@shared/v2/IconButton";

const ListingUuid = () => {
  const uuid = useSelector((state) => state.tdpDetailsReducer.uuid);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <div className="tw-flex tw-justify-between hover:tw-bg-neutral-gray-5 tw-ml-[-8px] tw-py-[8px] tw-pl-[8px] tw-mr-[8px] tw-group">
      <FieldLabel label="Brivity ID" />
      <div className="tw-flex tw-items-center tw-gap-[4px]">
        {uuid}
        <IconButton
          className={`${copied ? "" : "tw-invisible"} group-hover:tw-visible`}
          size="small"
          schema="transparent"
          onClick={() =>
            window
              .copyTextToClipboard(uuid)
              .then(() => setCopied(true))
              .catch(console.log)
          }
        >
          {copied ? <CheckSolidV6 className="!tw-text-semantic-green-120" /> : <CopySolidV6 />}
        </IconButton>
      </div>
    </div>
  );
};

export default ListingUuid;
