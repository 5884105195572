/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import TextInput from "@shared/v2/TextInput/TextInput";
import EmailMessageInput from "../EmailMessageInput";
import DripInput from "../DripInput";
import DripFrequency from "../DripFrequency";

const STYLE = {
  header: "tw-text-18d tw-text-gray-75 tw-mt-18px tw-mb-24px",
  subHeader: "tw-text-14d tw-text-gray-50 tw-font-semibold",
};

const Panel2Form = ({ setFormData, formData }) => {
  const formRef = useRef(null);

  return (
    <form
      ref={formRef} // Attach the ref to the form element
      className="tw-flex-none tw-w-[416px] tw-px-[4px] tw-h-full tw-overflow-y-auto tw-ml-[12px]"
    >
      <hr className="tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-m-0" />
      <div className={STYLE.header}>Email Settings</div>
      <div className="tw-mb-[24px]">
        <TextInput
          label="Market Report Name"
          helperText="This field is visible to your contacts"
          value={formData.name}
          onChange={(e) => {
            setFormData({ name: e.target.value });
          }}
        />
      </div>
      <EmailMessageInput
        value={formData.email_message}
        onChange={(e) => {
          setFormData({ email_message: e.target.value });
        }}
      />
      <DripInput
        value={formData.drip_enabled}
        onChange={(val) => {
          setFormData({
            drip_enabled: val,
            recurring_frequency: !val ? "never" : formData.recurring_frequency,
          });
        }}
      />
      {formData.drip_enabled && (
        <DripFrequency
          value={formData.recurring_frequency}
          onChange={(val) => {
            setFormData({ recurring_frequency: val });
          }}
        />
      )}
    </form>
  );
};

Panel2Form.propTypes = {
  formData: PropTypes.shape({
    drip_enabled: PropTypes.bool,
    email_message: PropTypes.string,
    name: PropTypes.string,
    recurring_frequency: PropTypes.string,
    home_value_adjustment: PropTypes.number,
    home_value_adjustment_as_percentage: PropTypes.bool,
    isHomeAdjPositive: PropTypes.bool,
  }),
  setFormData: PropTypes.func,
};

Panel2Form.defaultProps = {
  formData: {
    drip_enabled: 0,
    email_message: "",
    name: "",
    recurring_frequency: "never",
    home_value_adjustment: 0,
    home_value_adjustment_as_percentage: false,
    isHomeAdjPositive: true,
  },
  setFormData: null,
};

export default Panel2Form;
