import React from "react";
import { string } from "prop-types";

const DetailsHeader = ({ header }) => (
  <div className="tw-flex tw-pr-[16px] tw-items-center tw-mb-8px">
    <div className="tw-mr-[7px] tw-whitespace-pre tw-text-14px tw-text-neutral-gray-75 tw-font-semibold">
      {header}
    </div>
    <hr className="tw-border-2 tw-w-full tw-m-0 tw-border-neutral-gray-10" />
  </div>
);

DetailsHeader.propTypes = {
  header: string,
};

DetailsHeader.defaultProps = {
  header: "",
};

export default DetailsHeader;
