import React from "react";
import { string } from "prop-types";
import { formattedTimeFromUtcDateTimeString } from "@shared/TimeHelpers";

const formatDate = (inputDate) => {
  const [day, month, year] = inputDate.split('/');
  const formattedDate = new Date(`${month}/${day}/${year}`).toLocaleDateString('en-US', {
    month: 'short', // Use 'short' instead of 'long'
    day: 'numeric',
    year: 'numeric', // Include the year
  });

  return formattedDate;
};

const TimeStamp = ({ date, localRunTime }) => (
  <div className="tw-min-w-[124px] tw-w-[10.65%] tw-mr-[16px] tw-pt-[8px]">
    <div className='tw-text-14d'>{formatDate(date)}</div>
    <div className='tw-text-14d tw-uppercase'>{formattedTimeFromUtcDateTimeString(localRunTime)}</div>
  </div>
)

export default TimeStamp;

TimeStamp.propTypes = {
  date: string, 
  localRunTime: string
}

TimeStamp.defaultProps = {
  date: '', 
  localRunTime: ''
}