import React, { useMemo } from "react";
import { Provider } from "react-redux";
import PhoneSettings from "./PhoneSettings";

const PhoneSettingsApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  return (
    <Provider store={store}>
      <PhoneSettings />
    </Provider>
  );
};

export default PhoneSettingsApp;
