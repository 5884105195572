import React, { useState, useMemo } from "react";
import { Button, FormGroup, Col, Input, CancelButton } from "../../../../shared/v1";
import { useExpenseList } from "./customHook";
import { expenseInputHandlr, incomeExpenseInputTxtHandlr } from "./handlers";
import { IncomeExpenseRadio } from "./fractals";
import _ from "lodash";
import { Dropdown } from "../../../../shared/v1";
import ExpenseInput from "../../shared/ExpenseInput";

export const IncomeExpenseForm = (props) => {
  const { modalThis, setIsOpenNewIncomeModal, addIncome, expList, expenseType } = props;

  const { expenseList } = useExpenseList(expList);
  const invertedExpList = useMemo(() => _.invert(expList));
  const [expenseValue, setExpenseValue] = useState("");

  return (
    <form>
      <FormGroup horizontal deprecatedOverrideMarginClasses="tw-mx-0">
        <Col size={12}>
          {addIncome && <IncomeExpenseRadio modalThis={modalThis} setExpenseValue={setExpenseValue} />}
        </Col>
        <Col size={12}>
          <label>Name</label>
          <div>
            {modalThis.state.type === "expense" &&
            invertedExpList[expenseValue.value] !== "Custom" &&
            invertedExpList[expenseValue.value] !== "Custom Income" &&
            invertedExpList[expenseValue.value] !== "Custom Expense" ? (
              <Dropdown
                name="dropdown-expense"
                value={expenseValue || null}
                onChange={(opt) =>
                  expenseInputHandlr(opt, modalThis, setExpenseValue, expenseType, invertedExpList)
                }
                options={expenseList}
                isSearchable={false}
                isClearable={false}
                placeholder={
                  expenseType === "company" ? "Select an expense/income" : "Select new expense category"
                }
                className="tw-mb-4"
                // For some reason, the placeholder is vertically off-center
                // _just_ for this instance, so we force some extra padding here
                // to fix that.
                variantOverrides={{ placeholderPaddingBottom: "2px" }}
              />
            ) : (
              <Input
                size="small"
                value={modalThis.state.name}
                onChange={(e) => {
                  incomeExpenseInputTxtHandlr(e, modalThis, expenseValue, expenseType, invertedExpList);
                }}
              />
            )}
            {modalThis.state.type === "income" && textInput}
          </div>
        </Col>

        <Col size={4}>
          <div data-cy="financials-form-control-wrapper-0">
            <ExpenseInput
              label=""
              value={modalThis.state.value}
              amount={modalThis.state.value}
              isPercentage={!!modalThis.state.percentage}
              name="percentage"
              onValueUpdate={(e) => {
                if (!!modalThis.state.percentage) {
                  modalThis.setState({ value: e.target.value });
                } else {
                  modalThis.setState({ value: e.target.value });
                }
              }}
              onRadioUpdate={(e) => {
                modalThis.setState({ percentage: e.target.value === "true" });
              }}
            />
          </div>
        </Col>
      </FormGroup>
      <FormGroup horizontal deprecatedOverrideMarginClasses="tw-mx-0">
        <Col size={12}>
          <CancelButton
            size="small"
            className="modal-cancel"
            onClick={() => setIsOpenNewIncomeModal(false)}
          />
          <Button
            size="small"
            className="modal-submit tw-float-right financials-btn-0"
            onClick={() => modalThis.saveExpenseData()}
          >
            Save
          </Button>
        </Col>
      </FormGroup>
    </form>
  );
};
