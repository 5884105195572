import { schemaGenerator } from "../utils";

const dictionary = {
  divider: {
    default: [
      "tw-w-[1px]",
      "tw-h-[34px]",
      "tw-border-l",
      "tw-border-r-0",
      "tw-border-t-0",
      "tw-border-b-0",
      "tw-border-solid",
      "tw-border-theme-divider",
    ],
  },
  cancel: {
    default: ["tw-text-neutral-gray-30"],
    active: ["active:tw-text-theme-cancel-icon-active"],
    hover: ["hover:tw-cursor-pointer", "hover:tw-text-theme-cancel-icon-hover"],
  },
  confirm: {
    default: ["tw-text-theme-confirm-icon"],
    active: ["active:tw-text-theme-confirm-icon-active"],
    hover: ["hover:tw-cursor-pointer", "hover:tw-text-theme-confirm-icon-hover"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
