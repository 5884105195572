import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "./Button";
import UserListing from "./UserListing";
import UserCollectionListing from "./UserCollectionListing";
import PondTableRow from "./PondTableRow";

import * as actions from "../actions/default";
import * as apiActions from "../actions/apiActions";

const mapStateToProps = (state) => ({
  collection: state.leadPonds.leadPondsCollection,
  pageSize: state.leadPonds.pageSize,
  page: state.leadPonds.page,
  totalPages: state.leadPonds.totalPages,
  isLoadingMore: state.leadPonds.isLoadingMore,
  isAdmin: state.leadPonds.isAdmin,
});

const columns = [
  { title: "Pond Name" },
  { title: "Display Agent" },
  { title: "Accessible To" },
  { title: "Created By" },
  { title: "Last Modified By" },
  { title: "Edit" },
];

const PondTable = ({
  collection,
  openPondModal,
  loadMorePonds,
  isLoadingMore,
  pageSize,
  page,
  totalPages,
  isAdmin,
}) => {
  const hasMore = totalPages >= page;
  const cellStyles = "tw-p-4 tw-border-b";
  return collection.length == 0 ? (
    <>
      <p className="tw-text-center tw-my-10 tw-text-gray-50">
        {"You don’t have any lead ponds created yet."}
      </p>
      <Button
        className={"tw-mx-auto tw-block"}
        title={"New lead pond"}
        icon={"fa-plus"}
        onClick={() => {
          openPondModal();
        }}
      />
    </>
  ) : (
    <div className="tw-h-full tw-grow tw-w-full">
      <section className=" lp-table-section tw-w-full">
        <table className="data tw-w-full tw-text-[14px] tw-mb-4">
          <thead>
            <tr>
              {columns.map((col, idx) => {
                return (
                  <th key={col.title.replace(/' '/g, "_")} className={`tw-font-normal tw-px-4`}>
                    <small>{col.title}</small>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {collection.map((pond, idx) => {
              return (
                <PondTableRow
                  key={pond.id}
                  isAdmin={isAdmin}
                  editPond={() => {
                    openPondModal({ pond: pond });
                  }}
                  pond={pond}
                />
              );
            })}
          </tbody>
        </table>
        <div className="tw-w-full tw-sticky tw-left-0">
          <div className="tw-flex tw-justify-center ">
            {hasMore ? (
              <div>
                <Button
                  data-cy="lp-load-more"
                  disabled={isLoadingMore}
                  title={isLoadingMore ? "Loading..." : "Load More Ponds"}
                  onClick={() => {
                    loadMorePonds(page, pageSize);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <div className={"tw-h-[100px]  tw-mb-20 "} />
    </div>
  );
};

export default connect(mapStateToProps, { ...actions, ...apiActions })(PondTable);
