import React from "react";
import { string, bool } from "prop-types";

const Tooltip = ({ tooltipEnabled, hoverText, className, pointerClassName }) =>
  tooltipEnabled &&
  hoverText && (
    <div
      className={`tw-h-[32px] tw--mt-[40px] tw-bg-gray-75 tw-absolute tw-flex tw-justify-center tw-px-[8px] tw-py-[6px] tw-rounded-[4px] ${className}`}
    >
      <div className="tw-text-[#FFFFFF]">{hoverText}</div>
      <div
        className={`tw-absolute tw-left-[50%] tw--translate-x-[50%] tw-mx-auto tw-mt-[26px] tw-border-t-gray-75 tw-border-r-transparent tw-border-l-transparent tw-border-b-transparent tw-border-t-[8px] tw-border-r-[7px] tw-border-b-[0px] tw-border-l-[7px] tw-border-solid ${pointerClassName}`}
      />
    </div>
  );

Tooltip.propTypes = {
  tooltipEnabled: bool,
  hoverText: string,
  className: string,
  pointerClassName: string,
};

Tooltip.defaultProps = {
  tooltipEnabled: false,
  hoverText: "",
  className: "",
  pointerClassName: "",
};

export default Tooltip;
