/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HouseSolidV6 = (props) => (
  <Icon {...props} name="House Solid V6">
    <path d="M15.994 7.986c0 .5-.417.892-.889.892h-.889l.019 4.447c0 .078-.006.15-.014.225V14a1.11 1.11 0 0 1-1.111 1.111h-.444c-.031 0-.061-.025-.092-.003-.039-.022-.078.003-.117.003h-1.569A1.11 1.11 0 0 1 9.777 14v-2.444a.888.888 0 0 0-.889-.889H7.11a.888.888 0 0 0-.889.889V14a1.11 1.11 0 0 1-1.111 1.111H3.557c-.042 0-.083-.003-.125-.006a1.118 1.118 0 0 1-.1.006h-.444A1.11 1.11 0 0 1 1.777 14v-3.111l.002-.078V8.878h-.89a.88.88 0 0 1-.89-.892c0-.25.083-.472.278-.667l7.122-6.208c.194-.195.417-.223.611-.223s.417.056.586.195l7.092 6.236c.222.194.336.417.306.667z" />
  </Icon>
);

export default HouseSolidV6;
