/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SpinnerThirdSolidV6 = (props) => (
  <Icon {...props} name="Spinner Third Solid V6">
    <path d="M7.111 1.778c0-.491.397-.889.889-.889a7.11 7.11 0 0 1 6.158 10.667c-.244.425-.789.572-1.239.325-.4-.244-.544-.789-.3-1.239a5.174 5.174 0 0 0 .714-2.667A5.335 5.335 0 0 0 8 2.642a.873.873 0 0 1-.889-.889v.025z" />
  </Icon>
);

export default SpinnerThirdSolidV6;
