import React, { useMemo } from "react";
import { Provider } from "react-redux";
import ConferenceCall from "../ConferenceCall";

export default (props) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  return (
    <Provider store={store}>
      <ConferenceCall {...props} />
    </Provider>
  );
};
