import React, { useState } from "react";
import PropTypes from "prop-types";

import IconButton from "@shared/v2/IconButton";
import { Trash } from "@shared/v2/Icomoon";

import DocumentCheckbox from "./DocumentCheckbox";
import FileInformation from "./FileInformation";
import UploadedBy from "./UploadedBy";
import TimeUploaded from "./TimeUploaded";
import Context from "./Context";
import InlineContext from "./InlineContext";

const rowContainerClassNames = (context) => `
  tw-relative tw-h-64px tw-w-full tw-py-12px tw-bg-white tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-b-1px tw-border-solid tw-border-gray-10 hover:tw-bg-tinted-gray-50
  ${context === "documents-sidebar" ? "last:tw-border-b-0" : ""}
`;

const DocumentListItem = ({
  context,
  document,
  clearDocumentErrors,
  renameDocument,
  deleteDocuments,
  isSelected,
  onSelect,
  handlePreview,
}) => {
  // Lifted state up to parent component because <FileInformation> and <Context> needs to be aware of this boolean/setter
  const [editFilename, setEditFilename] = useState(false);

  // We need to keep track of when the input was open and the user clicked outside,
  // to prevent the preview from opening
  const [clickedOutside, setClickedOutside] = useState(false);

  const handleClickOutside = () => {
    setClickedOutside(true);
  };

  const handleClick = () => {
    if (context === "documents-upload" || clickedOutside || window.getSelection().toString()) {
      // Do not open preview if triggered from the upload modal, or if the user clicked outside, or if the user is selecting a text
      setClickedOutside(false);
      return;
    }

    handlePreview();
  };

  const handleTriggerRename = () => {
    setEditFilename(true);
  };

  const handleDeleteDocument = () => {
    deleteDocuments([document.id]);
  };

  return (
    <tr className={rowContainerClassNames(context)} onClick={handleClick} data-cy="document-list-item">
      {context !== "documents-sidebar" && (
        <td>
          {!document.isUploading && (
            <DocumentCheckbox
              context={context}
              onSelect={onSelect}
              isSelected={isSelected}
              id={document.id}
            />
          )}
        </td>
      )}
      <FileInformation
        context={context}
        editFilename={editFilename}
        setEditFilename={setEditFilename}
        renameDocument={renameDocument}
        previewDocument={handlePreview}
        document={document}
        clearDocumentErrors={clearDocumentErrors}
        onClickOutside={handleClickOutside}
      />
      {document.uploadErrors?.length ? (
        <td
          className="tw-px-7px tw-text-right tw-text-12d tw-font-normal tw-text-semantic-red-100"
          colSpan={2}
          data-cy="document-list-item-error"
        >
          {document.uploadErrors.join(" ")}
        </td>
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td className="tw-text-center" />
          {/* Space for viewers_count */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {context === "documents-table" ? (
            <>
              <UploadedBy document={document} />
              <TimeUploaded document={document} />
              <Context
                document={document}
                triggerRename={handleTriggerRename}
                deleteDocuments={deleteDocuments}
                previewDocument={handlePreview}
              />
            </>
          ) : context === "documents-sidebar" ? (
            <InlineContext document={document} handleDeleteDocument={handleDeleteDocument} />
          ) : (
            <td
              className="tw-px-7px tw-text-right tw-text-12d tw-font-semibold tw-text-neutral-gray-50"
              data-cy="document-list-item-action"
            >
              {document.isUploading ? (
                "Uploading..."
              ) : (
                <IconButton
                  size="small"
                  schema="misc-trash"
                  onClick={handleDeleteDocument}
                  data-cy="document-list-item-delete-button"
                >
                  <Trash className="tw-text-neutral-gray-75" size="m" />
                </IconButton>
              )}
            </td>
          )}
        </>
      )}
    </tr>
  );
};

DocumentListItem.propTypes = {
  handlePreview: PropTypes.func,
  context: PropTypes.shape({}),
  document: PropTypes.shape({}),
  renameDocument: PropTypes.func,
  deleteDocuments: PropTypes.func,
  clearDocumentErrors: PropTypes.func,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

DocumentListItem.defaultProps = {
  handlePreview: () => {},
  document: {},
  renameDocument: () => {},
  deleteDocuments: () => {},
  clearDocumentErrors: () => {},
  isSelected: false,
  onSelect: () => {},
};

export default DocumentListItem;
