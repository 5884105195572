import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../shared/v2/Button";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";

const CustomControl = ({ componentProps, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.Control {...componentProps}>
    <Button schema="secondary">
      <span className="tw-flex tw-min-w-[130px] tw-w-full">{children}</span>
    </Button>
  </Dropdown.Control>
);

CustomControl.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomControl;
