/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FileSolidV3 = (props) => (
  <Icon {...props} name="File Solid V3">
    <path d="M9 4.25V0H2.75A.748.748 0 0 0 2 .75v14.5c0 .416.334.75.75.75h10.5c.416 0 .75-.334.75-.75V5H9.75A.753.753 0 0 1 9 4.25zm5-.441V4h-4V0h.191c.2 0 .391.078.531.219l3.059 3.063A.746.746 0 0 1 14 3.81z" />
  </Icon>
);

export default FileSolidV3;
