import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TaskForm from "./TaskForm";
import HeaderTitle from "../../PersonDetail/components/modals/HeaderTitle";
import moment from "moment-timezone";
import { titleize } from "../../Transactions/utils/helpers";
import IconButton from "../../shared/v2/IconButton/IconButton";
import { PlusSolidV6 } from "../../shared/v2/Icomoon";
import colors from "../../Reports/components/DashboardShared/helpers/colors";

export default function NewTask({
  account_uuid,
  accountMilestoneNames,
  user,
  task_for,
  button_style,
  next_task,
  taskable,
}) {
  const [showModal, setshowModal] = useState(false);
  const [task, settask] = useState({ assigned_to_user_id: user.uuid });
  const [errorMessages, setErrorMessages] = useState("");

  const openModal = (e) => {
    e.preventDefault();
    setshowModal(true);
  };

  const swapForDelete = () => {
    setshowModal(false);
  };

  const closeModal = () => {
    setshowModal(false);
    setErrorMessages("");
  };
  const newTask = () => {};
  const showErrors = (messages) => {
    setErrorMessages(messages);
  };

  const nextDueTasksState = (dueDate) => {
    const today = Brivity.LocalTimeZone ? moment().tz(Brivity.LocalTimeZone).format("YYYY-MM-DD") : "";
    let state = { state_color: "text-info", state: "Upcoming" };
    if (dueDate == today) {
      state = { state_color: "text-warning", state: "Due Today" };
    }
    if (dueDate < today) {
      state = { state_color: "text-danger", state: "Overdue" };
    }
    return state;
  };

  const renderModal = () => {
    return (
      showModal && (
        <Modal show={showModal} className="modal" onHide={closeModal} id="new-task-modal" backdrop="static">
          <Modal.Header>
            <Modal.Title>
              <HeaderTitle title="Create Task" onClose={closeModal} />
            </Modal.Title>
          </Modal.Header>
          <TaskForm
            agentsNeedLoading={true}
            accountUuid={account_uuid}
            task={task}
            isfromIndex={true}
            taskable={taskable}
            taskChange={newTask}
            onCloseAction={closeModal}
            closeModal={closeModal}
            editToDelete={swapForDelete}
            showErrors={showErrors}
            errors={errorMessages}
            deleteButton={false}
            accountMilestoneNames={accountMilestoneNames}
            task_for={task_for}
            showTaskFor={true}
            createTask={true}
            user={user}
          />
        </Modal>
      )
    );
  };

  return (
    <>
      {button_style == "link" ? (
        <div className="tw-pl-[40px]">
          {next_task ? (
            <>
              <h6 className={`tw-m-0 ${nextDueTasksState(next_task.due_on).state_color}`}>
                {titleize(nextDueTasksState(next_task.due_on).state)}
              </h6>
              {next_task.due_on != null && (
                <h6 className="tw-m-0">{moment(next_task.due_on).format("MM-DD-YYYY")}</h6>
              )}
            </>
          ) : (
            <>
              <h6>
                <button
                  onClick={openModal}
                  className="focus:tw-outline-none intent-box none brivity-sidebar-add-button"
                >
                  <i className="fa fa-plus tw-pb-[3px]" style={{ fontSize: "10px" }}></i>
                </button>
              </h6>
            </>
          )}
        </div>
      ) : (
        <IconButton
          schema="transparent"
          title="New Task"
          className={`${colors.theme === "place" ? "tw-text-white" : ""} hover:tw-bg-gray/10`}
          onClick={openModal}
        >
          <PlusSolidV6 />
        </IconButton>
      )}
      {renderModal()}
    </>
  );
}
