import React, { useCallback, useRef, useState } from "react";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useClickOutside } from "../../../shared/hookHelpers";
import IntentButton from "./IntentButton";
import { updatePerson } from "../../actions/personDetailActionCreators";

const IntentDropdown = ({ className }) => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);
  const closeMenu = useCallback(() => setExpanded(false), []);
  useClickOutside(ref, closeMenu, true);

  const updateValue = (key, value) => {
    setExpanded(false);
    dispatch(
      updatePerson(
        produce(person, (draft) => {
          draft.data.attributes[key] = value;
        }),
      ),
    );
  };

  const currentLabel =
    person.meta?.intent_options.find(([, value]) => value === person.data.attributes.lead_type)?.[0] ||
    "Unknown";

  if (!person.data) return null;

  return (
    <div ref={ref} className={`tw-relative ${className}`}>
      <IntentButton
        isMenu
        label={currentLabel}
        onClick={() => setExpanded((exp) => !exp)}
        type={person.data.attributes.lead_type}
      />
      {expanded && (
        <div className="tw-z-2 tw-absolute tw-w-[144px] tw-left-[50%] tw-translate-x-[-50%] tw-top-[100%] tw-p-[12px] tw-flex tw-flex-col tw-gap-[8px] tw-rounded-[8px] tw-bg-white tw-shadow-dropdown">
          {person.meta.intent_options.map(([name, value]) => (
            <IntentButton
              key={value}
              label={name}
              type={value}
              onClick={() => updateValue("lead_type", value)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default IntentDropdown;
