import { schemaGenerator } from "../utils";

export const fontSizeDictionary = {
  medium: "tw-text-14d",
  small: "tw-text-12d",
};

const schemaDictionary = {
  default: {
    default: ["tw-text-theme-text-link-color"],
    active: ["active:tw-text-theme-text-link-color-active"],
    hover: ["hover:tw-text-theme-text-link-color-hover"],
    visited: ["visited:tw-text-theme-text-link-color-visited"],
    focusVisible: ["focus-visible:tw-outline-theme"],
  },
};

const schema = schemaGenerator(schemaDictionary);

export default schema;
