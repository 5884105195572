import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../shared/v2/Dropdown";

const CustomSingleValue = ({ basic, children, componentProps }) => {
  let body = null;
  if (basic) {
    body = children;
  } else {
    const isAllSelected = componentProps.selectProps.value?.[0].value === "all";
    const totalSelected = componentProps.selectProps.value?.length - (isAllSelected ? 1 : 0);
    body = `${isAllSelected ? "All Sources" : "Sources Selected"} (${totalSelected})`;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dropdown.SingleValue {...componentProps}>{body}</Dropdown.SingleValue>
  );
};

// eslint-disable-next-line react/jsx-props-no-spreading
export const CustomBasicSingleValue = (props) => <CustomSingleValue {...props} basic />;

CustomSingleValue.propTypes = {
  basic: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

CustomSingleValue.defaultProps = {
  basic: false,
};

export default CustomSingleValue;
