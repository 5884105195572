/* eslint-disable import/prefer-default-export */
import axios from "axios";

const fetchAutoPlan = () => ({
  type: "FETCH_AUTO_PLAN",
});

const fetchAutoPlanSuccess = (data) => ({
  type: "FETCH_AUTO_PLAN_SUCCESS",
  autoPlan: data.auto_plan,
  timeline: data.timeline,
});

export const toggleEdit = () => ({
  type: "IS_EDITING_NAME",
});

export const toggleTaskInstructions = () => ({
  type: "SHOW_INSTRUCTIONS",
});

export const toggleTaskNote = () => ({
  type: "SHOW_TASK_NOTE",
});

export const handleAutoPlanChange = (name, value) => ({
  type: "UPDATE_AUTO_PLAN_VALUE",
  name,
  value,
});

export const openDeleteActionModal = (activeActionId) => ({
  type: "OPEN_DELETE_ACTION_MODAL",
  activeActionId,
});

export const closeDeleteActionModal = () => ({
  type: "CLOSE_DELETE_ACTION_MODAL",
});
export const openNewActionModal = (actionForm) => ({
  type: "OPEN_NEW_ACTION_MODAL",
  actionForm,
});

export const closeNewActionModal = () => ({
  type: "CLOSE_NEW_ACTION_MODAL",
});

export const openEditActionModal = (action) => ({
  type: "OPEN_EDIT_ACTION_MODAL",
  actionForm: action,
});

export const closeEditActionModal = () => ({
  type: "CLOSE_EDIT_ACTION_MODAL",
});

export const updateActionValues = (name, value) => ({
  type: "UPDATE_ACTION_VALUES",
  name,
  value,
});

export const updateActionManyValues = (formUpdates) => ({
  type: "UPDATE_ACTION_FORM_MULTIPLE_VALUES",
  formUpdates,
});

export const submitActionPending = () => ({
  type: "AUTO_PLAN_EDIT_ACTION_PENDING",
});

export const submitActionFailure = (errorMessage) => ({
  type: "ACTION_FAILURE",
  errorMessage,
});

const createActionSuccess = (action) => ({
  type: "ACTION_CREATE_SUCCESS",
  action,
});

const updateActionSuccess = (action) => ({
  type: "ACTION_UPDATE_SUCCESS",
  action,
});

export const beginDeleteAction = () => ({
  type: "ACTION_DELETE_PENDING",
});

const deleteActionSuccess = (actionId) => ({
  type: "ACTION_DELETE_SUCCESS",
  actionId,
});

const updateAutoPlanNameSuccess = (autoPlan) => ({
  type: "AUTO_PLAN_UPDATE_SUCCESS",
  autoPlan,
});

const updateAutoPlanPreferencesSuccess = (autoPlan) => ({
  type: "AUTO_PLAN_PREFERENCES_UPDATE_SUCCESS",
  autoPlan,
});

const fetchActionSuccess = (activeAction) => ({
  type: "FETCH_ACTION_SUCCESS",
  activeAction,
});

export const saveAutoPlanPreferences = (autoPlanUpdatePath, form) => (dispatch) => {
  axios
    .patch(autoPlanUpdatePath, { auto_plan: form, authenticity_token: ReactOnRails.authenticityToken() })
    .then((res) => dispatch(updateAutoPlanPreferencesSuccess(res.data.auto_plan)))
    .catch(() => {
      dispatch({
        type: "AUTO_PLAN_FAILURE",
      });
    });
};

export const saveAutoPlanName = (autoPlanUpdatePath, autoPlan) => (dispatch) => {
  axios
    .patch(autoPlanUpdatePath, {
      auto_plan: autoPlan,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then((res) => dispatch(updateAutoPlanNameSuccess(res.data.auto_plan)))
    .catch(() => {
      dispatch({
        type: "AUTO_PLAN_FAILURE",
      });
    });
};

export const getAutoPlan = (autoPlanPath) => async (dispatch) => {
  dispatch(fetchAutoPlan());
  try {
    const path = `${autoPlanPath}.json`;
    const res = await axios.get(path);
    await dispatch(fetchAutoPlanSuccess(res.data));
  } catch (err) {
    // need to revisit proper error handling, legacy didn't handle errors either
    dispatch({
      type: "FETCH_AUTO_PLAN_FAILURE",
      errors: err.message,
      isFetching: false,
    });
  }
};

export const saveAction = (actionPath, action, autoPlanPath) => async (dispatch) => {
  dispatch(submitActionPending());
  try {
    const res = await axios.patch(actionPath, {
      auto_plan_action: action,
      authenticity_token: ReactOnRails.authenticityToken(),
    });

    if (autoPlanPath) {
      await dispatch(getAutoPlan(autoPlanPath));
    }
    dispatch(updateActionSuccess(res.data));
  } catch (err) {
    dispatch(submitActionFailure(err.response.data.message));
  }
};

export const createAction = (actionPath, action, autoPlanPath) => async (dispatch) => {
  dispatch(submitActionPending());
  try {
    const res = await axios.post(actionPath, {
      auto_plan_action: action,
      authenticity_token: ReactOnRails.authenticityToken(),
    });

    if (autoPlanPath) {
      await dispatch(getAutoPlan(autoPlanPath));
    }
    dispatch(createActionSuccess(res.data));
  } catch (err) {
    dispatch(submitActionFailure(err.response.data.errors));
  }
};

export const deleteAction = (path, actionId, autoPlanPath) => async (dispatch) => {
  dispatch(beginDeleteAction());
  try {
    await axios.delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } });
    if (autoPlanPath) {
      await dispatch(getAutoPlan(autoPlanPath));
      dispatch(deleteActionSuccess(actionId));
    }
  } catch (err) {
    dispatch({ type: "ACTION_FAILURE" });
  }
};

export const getAction = (actionPath) => (dispatch) => {
  axios
    .get(actionPath)
    .then((res) => dispatch(fetchActionSuccess(res.data)))
    .catch((err) => {
      dispatch({
        type: "FETCH_ACTION_FAILURE",
        errors: err.response.data.message,
        isFetching: false,
      });
    });
};

export const updateAutoPlanPreferencesForm = (name, value) => ({
  type: "AUTO_PLAN_PREFERENCES_FORM_UPDATE",
  name,
  value,
});

export const resetAutoPlanPreferencesForm = () => ({
  type: "AUTO_PLAN_PREFERENCES_FORM_RESET",
});
