import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Button, Checkbox, FormGroup, Col, CancelButton } from "../../shared/v1";
import * as autoPlanActionCreators from "../actions/autoPlanActionCreators";
import { canBeContingent } from "./helpers";
import {
  AssignTo,
  Delay,
  DelayAndScheduleLabel,
  Detail,
  Instructions,
  InstructionsLink,
  Note,
  NoteLink,
  PriorityOptions,
  RecurringOptions,
  RunTime,
  Schedule,
  ScheduleSelector,
  VisibilitySection,
} from "./ActionFormHelpers";

const defaultTask = {
  auto_plan_id: undefined,
  type: "Task",
  name: "",
  priority: 9,
  contingent: false,
  delay_factor: 1,
  schedule_type: "computed_time",
};

class TaskForm extends React.Component {
  toggleInstructions = () => {
    const {
      autoPlanActions: { toggleTaskInstructions },
    } = this.props;
    toggleTaskInstructions();
  };

  toggleNote = () => {
    const {
      autoPlanActions: { toggleTaskNote },
    } = this.props;
    toggleTaskNote();
  };

  render() {
    const {
      action,
      actionsPath,
      assignedToOptions,
      autoPlanPath,
      handleDelayUnitChange,
      handleScheduleTypeChange,
      isSubmitting,
      milestoneOptions,
      onActionSubmit,
      onAssignedToChange,
      onChange,
      onCloseAction,
      planType,
      showInstructions,
      showNote,
      triggers,
    } = this.props;
    const task = { ...defaultTask, ...action };
    const assignedToValue = task.assigned_to_id || task.assigned_to_role;

    return (
      <form>
        <FormGroup className="required" horizontal>
          <Detail label="Task Detail" value={task.name} onChange={onChange} />
        </FormGroup>

        <FormGroup horizontal>
          <ScheduleSelector scheduleType={task.schedule_type} onChange={handleScheduleTypeChange} />

          <DelayAndScheduleLabel
            delayLabel="Due"
            scheduleLabel={task.schedule_type === "computed_time" && "After"}
          />

          <Delay
            delayFactor={task.delay_factor}
            delayOnChange={onChange}
            delayValue={task.delay}
            delayUnitValue={task.delay_unit}
            delayUnitOnChange={handleDelayUnitChange}
            scheduleType={task.schedule_type}
          />

          <Schedule
            type={task.schedule_type}
            delay={task.delay}
            delayFactor={task.delay_factor}
            onChange={onChange}
            parentActionId={task.parent_action_id}
            triggers={triggers}
            milestoneOptions={milestoneOptions}
            milestoneId={task.milestone_id}
          />
        </FormGroup>
        <FormGroup horizontal>
          <Col size={12}>
            <Checkbox
              className="tw-pt-7px"
              labelClassName="tw-text-gray-base tw-text-[14px] tw-select-none"
              deprecatedOverrideCheckboxMarginClasses="!tw-mt-0 !tw--ml-20px"
              name="contingent"
              disabled={!canBeContingent(task.schedule_type, triggers)}
              inline
              onChange={() => onChange({ contingent: !task.contingent })}
              checked={task.contingent}
            >
              Make Contingent (Due time auto-updates based on when the previous action is completed)
            </Checkbox>
          </Col>
        </FormGroup>

        {(task.delay_unit === "days" || task.schedule_type === "event") && (
          <FormGroup horizontal>
            <RunTime label="Due By" value={task.run_time} onChange={onChange} />
          </FormGroup>
        )}

        <FormGroup className="required" horizontal>
          <AssignTo
            label="Assign To"
            placeholder={task.assigned_for_label || "Select..."}
            value={assignedToValue}
            onChange={onAssignedToChange}
            options={assignedToOptions}
            prependAnyoneOption={false}
          />
        </FormGroup>

        <FormGroup horizontal>
          <PriorityOptions value={task.priority} onChange={onChange} />
        </FormGroup>

        {planType === "transactions" && (
          <FormGroup horizontal>
            <VisibilitySection value={task.visibility} onChange={onChange} />
          </FormGroup>
        )}

        <FormGroup horizontal>
          {!showInstructions && <InstructionsLink onShow={this.toggleInstructions} />}
          {!showNote && <NoteLink onShow={this.toggleNote} />}
        </FormGroup>

        {showInstructions && (
          <FormGroup horizontal>
            <Instructions onHide={this.toggleInstructions} onChange={onChange} value={task.instructions} />
          </FormGroup>
        )}

        {showNote && (
          <FormGroup horizontal>
            <Note onHide={this.toggleNote} onChange={onChange} value={task.note} />
          </FormGroup>
        )}

        <FormGroup className="m-b-40" horizontal>
          <RecurringOptions
            onChange={onChange}
            frequencyValue={task.recurring_frequency || "never"}
            daysOfFrequencyValue={task.recurring_days_of_frequency}
            limitValue={task.recurring_limit}
          />
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <CancelButton size="small" className="modal-cancel" onClick={onCloseAction} />
            {isSubmitting ? (
              <Button size="small" variant="info" className="modal-submit tw-float-right" disabled>
                Saving..
              </Button>
            ) : (
              <Button
                size="small"
                variant="info"
                className="modal-submit tw-float-right"
                onClick={() => onActionSubmit(task, actionsPath, autoPlanPath)}
              >
                Save
              </Button>
            )}
          </Col>
        </FormGroup>
      </form>
    );
  }
}

TaskForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  actionsPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedToOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanActions: PropTypes.objectOf(PropTypes.any).isRequired,
  autoPlanPath: PropTypes.string.isRequired,
  handleDelayUnitChange: PropTypes.func.isRequired,
  handleScheduleTypeChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestoneOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onActionSubmit: PropTypes.func.isRequired,
  onAssignedToChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  planType: PropTypes.string.isRequired,
  showInstructions: PropTypes.bool.isRequired,
  showNote: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  triggers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.autoPlanEdit.isSubmitting,
  showInstructions: state.autoPlanEdit.showInstructions,
  showNote: state.autoPlanEdit.showNote,
});

const mapDispatchToProps = (dispatch) => ({
  autoPlanActions: bindActionCreators(autoPlanActionCreators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
