/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const QuestionCircleRegularV3 = (props) => (
  <Icon {...props} name="Question Circle Regular V3">
    <path d="M8 0a8.001 8.001 0 1 0 .002 16.002A8.001 8.001 0 0 0 8 0zm0 14.452A6.448 6.448 0 0 1 1.548 8 6.45 6.45 0 0 1 8 1.548c3.564 0 6.452 2.886 6.452 6.452S11.567 14.452 8 14.452zm3.459-8.233c0 2.163-2.336 2.196-2.336 2.996v.204a.387.387 0 0 1-.387.387H7.264a.387.387 0 0 1-.387-.387V9.14c0-1.153.874-1.614 1.535-1.984.566-.318.914-.534.914-.954 0-.556-.71-.926-1.283-.926-.748 0-1.093.354-1.579.967a.388.388 0 0 1-.538.069l-.898-.681a.387.387 0 0 1-.085-.528c.762-1.119 1.733-1.748 3.244-1.748 1.583 0 3.273 1.236 3.273 2.865zm-2.104 5.394c0 .747-.608 1.355-1.355 1.355s-1.355-.608-1.355-1.355.608-1.355 1.355-1.355 1.355.608 1.355 1.355z" />
  </Icon>
);

export default QuestionCircleRegularV3;
