export const createDocument = (rawDoc, rawMeta, isFinancialsCDA) => ({
  id: rawDoc.id,
  fileName: rawDoc.file_name,
  nakedName: rawDoc.naked_name,
  fileExtension: `.${rawDoc.file_name.split(".").pop()}`,
  fileType: rawDoc.file_type,
  imageIcon: rawDoc.image_icon,
  size: rawDoc.size,
  humanReadableSize: rawDoc.human_readable_size,
  uploadedAt: rawDoc.uploaded_at,
  expiringUrl: rawDoc.expiring_url,
  url: rawDoc.url,
  uploadedById: rawDoc.uploaded_by_id,
  uploadedByImageUrl: rawDoc.uploaded_by_image_url,
  uploadedByName: rawDoc.uploaded_by_name,
  uploadedByUrl: rawDoc.uploaded_by_url,
  canRename: isFinancialsCDA ? true : rawMeta.permissions[rawDoc.id].rename,
  canDelete: isFinancialsCDA ? true : rawMeta.permissions[rawDoc.id].delete,
  viewers: [],
  renameErrors: [],
  isSelected: false,
});

const createDocumentFromUpload = (rawDoc, rawMeta) => ({
  id: rawDoc.id,
  fileName: rawDoc.file_name,
  nakedName: rawDoc.naked_name,
  fileExtension: `.${rawDoc.file_name.split(".").pop()}`,
  fileType: rawDoc.file_type,
  imageIcon: rawDoc.image_icon,
  size: rawDoc.size,
  humanReadableSize: rawDoc.human_readable_size,
  uploadedAt: rawDoc.uploaded_at,
  expiringUrl: rawDoc.expiring_url,
  url: rawDoc.url,
  uploadedById: rawDoc.uploaded_by_id,
  uploadedByImageUrl: rawDoc.uploaded_by_image_url,
  uploadedByName: rawDoc.uploaded_by_name,
  uploadedByUrl: rawDoc.uploaded_by_url,
  canRename: rawMeta.permissions.rename,
  canDelete: rawMeta.permissions.delete,
  viewers: [],
  renameErrors: [],
  isSelected: false,
});

const createDocumentFromRename = (rawDoc, newName) => ({
  fileName: newName,
  nakedName: newName.slice(0, newName.lastIndexOf(".")),
  expiringUrl: rawDoc.expiring_url,
});

const createDocuments = (apiDocuments, rawMeta) =>
  apiDocuments.map((rawDoc) => createDocument(rawDoc, rawMeta, false));

const createDocumentViewer = (rawViewer) => ({
  id: rawViewer.id,
  role: rawViewer.role,
  name: `${rawViewer.first_name} ${rawViewer.last_name}`,
  avatar: rawViewer.avatar,
  isViewer: rawViewer.is_viewer,
});

const createDocumentViewers = (viewers) => viewers.map((rawViewer) => createDocumentViewer(rawViewer));

const createMeta = (rawMeta) => {
  const meta = {
    pagination: {
      current: rawMeta.pagination.current,
      next: rawMeta.pagination.next,
      previous: rawMeta.pagination.previous,
      totalPages: rawMeta.pagination.total_pages,
      totalCount: rawMeta.pagination.total_count,
      countPerPage: 25,
    },
    viewerCounts: { ...rawMeta.viewer_counts },
    permissions: { ...rawMeta.permissions },
    updatedAtMs: Date.now(),
  };

  return meta;
};

export const adapterGetDocuments = (data) => {
  const state = {
    documents: createDocuments(data.documents, data.meta),
    meta: createMeta(data.meta),
  };

  return state;
};

export const adapterGetAllDocumentViewers = (data) => {
  const state = {
    viewers: createDocumentViewers(data.viewers),
  };

  return state;
};

export const adapterDocumentFromRename = (rawDoc, newName) => createDocumentFromRename(rawDoc, newName);

export const adapterDocumentFromUpload = (rawDoc, rawMeta) => createDocumentFromUpload(rawDoc, rawMeta);
