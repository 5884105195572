import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import buttons from "../button.module.css";
import modal from "../modal.module.css";
import DocumentRow from "./DocumentRow";
import HeaderTitle from "./HeaderTitle";
import DeleteAgreementModal from "../Agreements/DeleteAgreementModal";

const ViewAllDocumentsModal = ({ person, onClose, handleDocumentDelete, documents }) => {
  const [saveDocId, setSaveDocId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onClickDeleteDoc = (docId) => {
    setOpenDeleteModal(true);
    setSaveDocId(docId);
  };

  const deleteCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteDoc = () => {
    handleDocumentDelete(person.data.attributes, saveDocId);
    deleteCloseModal();
  };

  return (
    <>
      <Modal id="view-all-documents-modal" className="person-details-modal" show={true} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Documents" onClose={onClose} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "12px" }}>
          <Row className={`${documents.divider} th`}>
            <Col xs={4}>
              <h6 className={documents.columnTitle}>
                NAME
                <span className={documents.arrows} style={{ display: "none" }}>
                  <i className="fa fa-sort-up" />
                  <i className="fa fa-sort-down" />
                </span>
              </h6>
            </Col>
            <Col xs={1}>
              <h6 className={documents.columnTitle}>
                TYPE
                <span className={documents.arrows} style={{ display: "none" }}>
                  <i className="fa fa-sort-up" />
                  <i className="fa fa-sort-down" />
                </span>
              </h6>
            </Col>
            <Col xs={1}>
              <h6 className={documents.columnTitle}>
                SIZE
                <span className={documents.arrows} style={{ display: "none" }}>
                  <i className="fa fa-sort-up" />
                  <i className="fa fa-sort-down" />
                </span>
              </h6>
            </Col>
            <Col xs={2}>
              <h6 className={documents.columnTitle}>
                DATE ADDED
                <span className={documents.arrows} style={{ display: "none" }}>
                  <i className="fa fa-sort-up" />
                  <i className="fa fa-sort-down" />
                </span>
              </h6>
            </Col>
            <Col xs={3}>
              <h6 className={documents.columnTitle}>
                UPLOADED BY
                <span className={documents.arrows} style={{ display: "none" }}>
                  <i className="fa fa-sort-up" />
                  <i className="fa fa-sort-down" />
                </span>
              </h6>
            </Col>
            <Col xs={1}>
              <h6 className={documents.columnTitle}>ACTIONS</h6>
            </Col>
          </Row>
          <div
            id="person-document-uploads"
            className={`${documents.divider} ${modal.modalBodyContainer}`}
            style={{ marginBottom: "30px" }}
          >
            {documents.map((document, index) => (
              <DocumentRow
                key={document.id}
                {...document}
                onClickDeleteDoc={onClickDeleteDoc}
                person={person}
                index={index}
              />
            ))}
          </div>
          <Row>
            <Col xs={12}>
              <button className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray}`} onClick={onClose}>
                CANCEL
              </button>
              <button
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
                onClick={onClose}
              >
                DONE
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {openDeleteModal && (
        <DeleteAgreementModal
          modalOpen={openDeleteModal}
          onDelete={deleteDoc}
          closeModal={deleteCloseModal}
          title="Delete Document?"
          msg="Deleting this document will remove it from both the agreement and the Documents section."
        />
      )}
    </>
  );
};

export default ViewAllDocumentsModal;
