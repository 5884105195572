import React from "react";

import { EyeSolidV6 } from "@shared/v2/Icomoon";

import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import ActivitiesContainer from "./ActivitiesContainer";
import PipedElements from "../PipedElements";

const ViewedPropertyActivity = ({ activity, onReadChange }) => {
  const { propertyStreetAddress, propertyPrice, leadPropertyViews } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={EyeSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        {propertyStreetAddress && <span>Address: {propertyStreetAddress}</span>}
        <PipedElements>
          {propertyPrice && <span>Price: {propertyPrice}</span>}
          {leadPropertyViews && <span>Views: {leadPropertyViews}</span>}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

export default ViewedPropertyActivity;

ViewedPropertyActivity.propTypes = {
  ...activityCommonPropTypes,
};

ViewedPropertyActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
