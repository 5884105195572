/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { Draggable } from "react-beautiful-dnd";
import ExpenseIncomeLineItem from "./ExpenseIncomeLineItem";
import { lineItemShape } from "../../utils";

const DraggableExpenseIncomeLineItem = ({ id, index, children }) => (
  <Draggable draggableId={`draggable-${id}`} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={
          snapshot.isDragging
            ? "tw-bg-white tw-border tw-border-solid tw-rounded-4px tw-border-neutral-gray-10 tw-shadow-dropdown"
            : ""
        }
      >
        {children(provided, snapshot)}
      </div>
    )}
  </Draggable>
);

DraggableExpenseIncomeLineItem.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
};

const DraggableBrokerageSplitAndRoyaltyLineItems = ({
  index,
  brokerageSplitLineItem,
  royaltyLineItem,
  isDraggingOver,
  onBrokerageSplitValueChange,
  onEditBrokerageSplitLineItem,
  onRoyaltyValueChange,
  onEditRoyaltyLineItem,
}) => (
  <DraggableExpenseIncomeLineItem id="brokerage-split-and-royalty" index={index}>
    {(provided) => (
      <>
        <ExpenseIncomeLineItem
          {...brokerageSplitLineItem}
          dragHandleProps={provided.dragHandleProps}
          isDraggingOver={isDraggingOver}
          onValueChange={(...args) => onBrokerageSplitValueChange(brokerageSplitLineItem, ...args)}
          onEditLineItem={(...args) => onEditBrokerageSplitLineItem(brokerageSplitLineItem, ...args)}
        />
        <ExpenseIncomeLineItem
          {...royaltyLineItem}
          dragHandleProps={provided.dragHandleProps}
          isDraggingOver={isDraggingOver}
          onValueChange={(...args) => onRoyaltyValueChange(royaltyLineItem, ...args)}
          onEditLineItem={(...args) => onEditRoyaltyLineItem(royaltyLineItem, ...args)}
        />
      </>
    )}
  </DraggableExpenseIncomeLineItem>
);

DraggableBrokerageSplitAndRoyaltyLineItems.propTypes = {
  index: PropTypes.number.isRequired,
  brokerageSplitLineItem: lineItemShape.isRequired,
  royaltyLineItem: lineItemShape.isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  onBrokerageSplitValueChange: PropTypes.func,
  onEditBrokerageSplitLineItem: PropTypes.func,
  onRoyaltyValueChange: PropTypes.func,
  onEditRoyaltyLineItem: PropTypes.func,
};

DraggableBrokerageSplitAndRoyaltyLineItems.defaultProps = {
  onBrokerageSplitValueChange: () => {},
  onEditBrokerageSplitLineItem: () => {},
  onRoyaltyValueChange: () => {},
  onEditRoyaltyLineItem: () => {},
};

const lineItemIdForDnD = (lineItem) => {
  if (!lineItem.id) {
    return uniqueId(`${lineItem.expenseOrIncome}-line-item-`);
  }

  // Prefix with its type to avoid clashing IDs between expenses and incomes
  return `${lineItem.expenseOrIncome}-line-item-${lineItem.id}`;
};

const DraggableExpenseIncomeLineItems = ({
  lineItems,
  isDraggingOver,
  onOptionChange,
  onValueChange,
  onEditLineItem,
  onRemoveLineItem,
  onBrokerageSplitValueChange,
  onEditBrokerageSplitLineItem,
  onRoyaltyValueChange,
  onEditRoyaltyLineItem,
}) =>
  lineItems.map((lineItem) =>
    lineItem.brokerageSplitItem && lineItem.royaltyItem ? (
      <DraggableBrokerageSplitAndRoyaltyLineItems
        key="brokerage-split-and-royalty"
        index={lineItem.position}
        brokerageSplitLineItem={lineItem.brokerageSplitItem}
        royaltyLineItem={lineItem.royaltyItem}
        isDraggingOver={isDraggingOver}
        onBrokerageSplitValueChange={onBrokerageSplitValueChange}
        onEditBrokerageSplitLineItem={onEditBrokerageSplitLineItem}
        onRoyaltyValueChange={onRoyaltyValueChange}
        onEditRoyaltyLineItem={onEditRoyaltyLineItem}
      />
    ) : (
      <DraggableExpenseIncomeLineItem
        key={lineItemIdForDnD(lineItem)}
        id={lineItemIdForDnD(lineItem)}
        index={lineItem.position}
      >
        {(provided) => (
          <ExpenseIncomeLineItem
            {...lineItem}
            dragHandleProps={provided.dragHandleProps}
            isDraggingOver={isDraggingOver}
            onOptionChange={(...args) => onOptionChange(lineItem, ...args)}
            onValueChange={(...args) => onValueChange(lineItem, ...args)}
            onEditLineItem={(...args) => onEditLineItem(lineItem, ...args)}
            onRemoveLineItem={(...args) => onRemoveLineItem(lineItem, ...args)}
          />
        )}
      </DraggableExpenseIncomeLineItem>
    ),
  );

DraggableExpenseIncomeLineItems.propTypes = {
  lineItems: PropTypes.arrayOf(lineItemShape).isRequired,
  isDraggingOver: PropTypes.bool.isRequired,
  onOptionChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onEditLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
  onBrokerageSplitValueChange: PropTypes.func,
  onEditBrokerageSplitLineItem: PropTypes.func,
  onRoyaltyValueChange: PropTypes.func,
  onEditRoyaltyLineItem: PropTypes.func,
};

DraggableExpenseIncomeLineItems.defaultProps = {
  onOptionChange: () => {},
  onValueChange: () => {},
  onEditLineItem: () => {},
  onRemoveLineItem: () => {},
  onBrokerageSplitValueChange: () => {},
  onEditBrokerageSplitLineItem: () => {},
  onRoyaltyValueChange: () => {},
  onEditRoyaltyLineItem: () => {},
};

export default DraggableExpenseIncomeLineItems;
