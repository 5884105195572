import React from "react";
import Alert from "@shared/v2/Alert";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";

const PlatformMessage = () => {
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();

  if (featureFlags.platformAccount && currentUser.managedNumber) return null;

  return (
    <Alert
      variant="info"
      text="Click to call is a Brivity Platform feature - call (866) 295-3322 or email sales@brivity.com to upgrade today!"
    />
  );
};

export default PlatformMessage;
