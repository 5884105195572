import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import shownAtDateFormat from "../constants";
import feedbackShape from "../model/feedback";
import { showingTypeLabel } from "../model/showingTypes";
import Error from "../Error";
import FeedbackMenuDropdown from "../FeedbackMenuDropdown";
import FeedbackVisibility from "../FeedbackVisibility";
import Rating from "../Rating";
import IconButton from "../../../../shared/v2/IconButton";
import { VerticalDots } from "../../../../shared/v2/Icomoon";
import { Prompt } from "../../../../shared/v2/Modal";
import { useClickOutside } from "../../../../shared/hookHelpers";

const FeedbackCard = ({
  feedback,
  showEditFeedbackForm,
  changeFeedbackVisibility,
  clearChangeFeedbackVisibilityError,
  showDeleteFeedbackPrompt,
}) => {
  const dropdownContainerRef = useRef(null);
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);

  useClickOutside(dropdownContainerRef, () => {
    if (!isDropdownMenuVisible) {
      return;
    }

    setIsDropdownMenuVisible(false);
  });

  const handleOnEdit = () => {
    setIsDropdownMenuVisible(false);
    showEditFeedbackForm(feedback);
  };

  const showingType = showingTypeLabel(feedback.showingType);

  const isVisibilityToggleEnabled = !(
    feedback.meta?.changeVisibility && feedback.meta?.changeVisibility.isLoading
  );

  const handleOnChangeVisibility = (externallyVisible) => {
    changeFeedbackVisibility(feedback.id, externallyVisible);
  };

  const handleOnDelete = () => {
    setIsDropdownMenuVisible(false);
    showDeleteFeedbackPrompt(feedback);
  };

  const didChangeVisibilityFail = feedback.meta?.changeVisibility?.error;

  const changeVisibilityErrorMessage = feedback.meta?.changeVisibility?.error?.message ?? "";

  const clearChangeVisibilityError = () => {
    clearChangeFeedbackVisibilityError(feedback.id);
  };

  return (
    <>
      <div
        // FIXME: Reconsider this, it was added only for testing purposes
        id={`feedback-${feedback.id}`}
        // Use Tailwind JIT due to missing values for spacing/gap
        className="tw-grid tw-grid-rows-[auto_auto_1fr] tw-grid-cols-[auto_1fr] tw-gap-x-[32px] tw-gap-y-[24px] tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-8px tw-mt-24px tw-p-24px"
        data-cy="feedback-card"
      >
        <div className="tw-row-start-1 tw-row-end-1">
          <Rating rating={feedback.liked} size="l" />
          <div className="tw-mt-16px">
            <FeedbackVisibility
              externallyVisible={feedback.externallyVisible}
              iconSize="l"
              data-cy={`feedback-card-externally-${feedback.externallyVisible ? "visible" : "hidden"}`}
            />
          </div>
        </div>
        <span
          className="tw-row-start-1 tw-row-end-1 tw-col-start-2 tw-col-end-2"
          data-cy="feedback-card-content"
        >
          &quot;{feedback.body}&quot;
        </span>
        <hr className="tw-row-start-2 tw-row-end-2 tw-col-start-1 tw-col-end-3 tw-w-full tw-border-neutral-gray-10 tw-my-0" />
        <div className="tw-row-start-3 tw-row-end-3 tw-col-start-1 tw-col-end-1" />
        <div className="tw-row-start-3 tw-row-end-3 tw-col-start-2 tw-col-end-2 tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div>
            {showingType && (
              <span className="tw-text-14d tw-mr-8px" data-cy="feedback-card-showing-type">
                {showingType}
              </span>
            )}
            <span className="tw-text-14d tw-font-semibold tw-mr-8px" data-cy="feedback-card-shown-at">
              {feedback.shownAt.format(shownAtDateFormat)}
            </span>
            <span className="tw-w-1px tw-h-20px tw-border-l tw-border-r-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-neutral-gray-10 tw-mr-8px" />
            <div className="tw-inline tw-text-14d" data-cy="feedback-card-shown-by">
              Shown by: <span className="tw-font-semibold">{feedback.shownBy}</span>
            </div>
          </div>
          <div ref={dropdownContainerRef} className="tw-relative">
            <IconButton
              size="small"
              schema="tertiary"
              className="tw-ml-12px"
              onClick={() => setIsDropdownMenuVisible(!isDropdownMenuVisible)}
              data-cy="feedback-card-action-button"
            >
              <VerticalDots size="l" />
            </IconButton>
            {isDropdownMenuVisible && (
              <FeedbackMenuDropdown
                externallyVisible={feedback.externallyVisible}
                isVisibilityToggleEnabled={isVisibilityToggleEnabled}
                onEdit={handleOnEdit}
                onChangeVisibility={handleOnChangeVisibility}
                onDelete={handleOnDelete}
                className="tw-absolute tw-right-10px tw-z-1000"
              />
            )}
          </div>
        </div>
      </div>
      <Prompt
        show={didChangeVisibilityFail}
        onCancel={clearChangeVisibilityError}
        title="Change Visibility Failed"
        description={<Error message={changeVisibilityErrorMessage} />}
        primaryOption="OK"
        onPrimary={clearChangeVisibilityError}
      />
    </>
  );
};
FeedbackCard.propTypes = {
  feedback: feedbackShape.isRequired,
  showEditFeedbackForm: PropTypes.func.isRequired,
  changeFeedbackVisibility: PropTypes.func.isRequired,
  clearChangeFeedbackVisibilityError: PropTypes.func.isRequired,
  showDeleteFeedbackPrompt: PropTypes.func.isRequired,
};

FeedbackCard.defaultProps = {};

export default FeedbackCard;
