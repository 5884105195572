import { useEffect } from "react";
import { debounce } from "lodash";

import { getAreaCMACounts } from "../../services";

export const calcMinPrice = (min) => (min !== "0" ? Number(min) : null);
export const calcMaxPrice = (max) => (max > "0" ? Number(max) : null);
export const convertToInt = (num) => (num !== null ? Number(num) : null);

const getCounts = debounce(async (fD, isDrawing, setLocationData, setMessage) => {
  const dt = { ...fD };
  dt.property_type = Object.keys(fD.property_type).join("|");
  dt.lead_email = fD.lead_email?.value;
  delete dt.searchLocation;
  dt.min_price = calcMinPrice(dt.min_price);
  dt.max_price = calcMaxPrice(dt.max_price);

  dt.min_area = convertToInt(dt.min_area);
  dt.max_area = convertToInt(dt.max_area);
  dt.min_lot = convertToInt(dt.min_lot);
  dt.max_lot = convertToInt(dt.max_lot);
  dt.min_year_built = convertToInt(dt.min_year_built);
  dt.max_year_built = convertToInt(dt.max_year_built);

  if (dt.min_beds && !dt.max_beds && dt.min_beds < 5) {
    dt.max_beds = dt.min_beds;
  }
  if (fD?.search_type === "radius") {
    delete dt.boundry_coordinates;
    if (fD?.radius_type === "smart-radius") {
      dt.smart_radius = true;
      delete dt.radius;
    }
  } else if (isDrawing) {
    delete dt.radius;
    delete dt.boundry_coordinates;
    delete dt.lat_lng;
    delete dt.street_address;
    delete dt.city;
    delete dt.state;
    delete dt.region;
    delete dt.state_abbreviation;
    delete dt.county;
    delete dt.country;
    delete dt.postal_code;
    if (dt.geo_json) {
      dt.geo_json = JSON.stringify(dt.geo_json);
    }
  } else if (fD?.search_type === "area") {
    // type area
    delete dt.radius;
    if (dt.boundry_coordinates) {
      dt.geo_json = JSON.stringify(dt.boundry_coordinates);
    }
  }
  await getAreaCMACounts(
    dt,
    (resData) => {
      setLocationData(resData.data);
    },
    () => {
      setLocationData(null);
      setMessage({ showError: true });
    },
  );
}, 1000);

export const useFormData = ({
  formData,
  isDrawing,
  setLocationData,
  setFormError,
  setMessage,
  isFormInValid,
  setSearching,
}) =>
  useEffect(() => {
    setLocationData(null);
    const needsMoreCoords = formData.lat_lng?.length < 2;
    const needsMoreAreaData =
      formData.search_type === "area" && !formData.boundry_coordinates && !formData.geo_json;
    setFormError({ lat_lng: needsMoreCoords });
    setFormError({ geo_json: needsMoreAreaData });
    if (
      isFormInValid ||
      needsMoreAreaData ||
      needsMoreCoords ||
      (formData.search_type === "radius" && formData.searchLocation === null) ||
      (isDrawing && formData.geo_json === null)
    ) {
      return;
    }
    setSearching(true);
    getCounts(formData, isDrawing, setLocationData, setMessage, setSearching);
  }, [formData, isFormInValid]);

export const useRunOnce = ({ person, setContactData }) =>
  useEffect(() => {
    setContactData({
      first_name: person?.data?.attributes?.first_name,
      last_name: person?.data?.attributes?.last_name,
      brivity_uuid: person?.data?.attributes?.uuid,
      brivity_lead_id: person?.data?.attributes?.lead?.id,
    });
  }, []);
