import React, { useState } from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import Alert from "@shared/v2/Alert";
import Check from "@shared/v2/Icomoon/Icons/Check";

import Header from "../../../UI/Header";
import ListingImages from "../ListingImages";
import ImagesOptions from "../ImagesOptions";
import ReplaceMLSPhotoModal from "../ReplaceMLSPhotoModal";
import { replacePhotoAsThunk } from "../actions/thunks";
import { clearError } from "../actions/creators";
import ImportListingDetailsModal from "../ImportListingDetailsModal";

const ListingImagesWrapper = ({
  allImagesLength,
  imageErrors,
  clearError,
  useMLSphotos,
  blossorId,
  uuid,
  replacePhotoAsThunk,
}) => {
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [showAlert, setshowAlert] = useState(false);

  const openModal = () => {
    if (blossorId) {
      if (!useMLSphotos) {
        setOpenWarningModal(true);
      } else {
        handleReplace();
      }
    } else {
      setOpenImportModal(true);
    }
  };

  const closeModal = () => {
    setOpenWarningModal(false);
  };

  const closeImportModal = () => {
    setOpenImportModal(false);
  };

  const handleReplace = () => {
    if (blossorId) {
      const payloadListing = {
        use_mls_photos: !useMLSphotos ? true : false,
        blossor_id: blossorId,
      };
      replacePhotoAsThunk(uuid, payloadListing);
    }
    closeModal();
  };
  return (
    <>
      {showAlert && (
        <Alert
          variant="success"
          containerClass="tw-z-10 !tw-top-0 !tw-absolute !tw-right-2/5 !tw-left-2/5"
          text={
            <>
              <Check size="xl" className="tw-w-16px tw-h-16px tw-mb-[-4px]" />
              <span className="tw-pl-[9px]">Listing details imported from MLS</span>
            </>
          }
          role="alert"
        />
      )}

      <Header header={`Listing Photos (${allImagesLength})`} add={<ImagesOptions />} />
      {imageErrors && (
        <div className="tw-w-full tw-flex ">
          <div className="tw-min-w-full tw-mb-[24px]">
            {imageErrors.map((file) => (
              <div className="alert alert-danger tw-w-full tw-mb-[12px]">
                <button type="button" className="close" aria-label="Close" onClick={() => clearError(file)}>
                  <span aria-hidden="true">×</span>
                </button>
                {`${file.name} is too large`}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="tw-text-gray-50 tw-pb-[24px]">
        {blossorId ? (
          <>
            {useMLSphotos ? "Photos are synced from the MLS listing. " : "Photos are manually uploaded. "}
            <span className="tw-text-brand-info tw-cursor-pointer" onClick={openModal}>
              {useMLSphotos ? "Replace with your own photos." : "Replace with photos from the MLS listing."}
            </span>
          </>
        ) : (
          <>
            {allImagesLength > 0 ? (
              <>
                Photos are manually uploaded.{" "}
                <span className="tw-text-brand-info tw-cursor-pointer" onClick={openModal}>
                  Import listing details{" "}
                </span>
                to replace with MLS listing photos
              </>
            ) : (
              <>
                <span className="tw-text-brand-info tw-cursor-pointer" onClick={openModal}>
                  Import listing details{" "}
                </span>
                to sync photos with the MLS listing.
              </>
            )}
          </>
        )}
      </div>
      <ListingImages />
      {openWarningModal && (
        <ReplaceMLSPhotoModal
          modalOpen={openWarningModal}
          closeModal={closeModal}
          handleReplace={handleReplace}
        />
      )}
      {openImportModal && (
        <ImportListingDetailsModal
          modalOpen={openImportModal}
          closeModal={closeImportModal}
          setshowAlert={setshowAlert}
          isFromDetail={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  allImagesLength: state.tdpDetailsReducer.listingImgs.length,
  useMLSphotos: state.tdpDetailsReducer.useMLSphotos,
  blossorId: state.tdpDetailsReducer.blossorId,
  imageErrors: state.tdpDetailsReducer.listingImgErrors,
  uuid: state.tdpDetailsReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  replacePhotoAsThunk: (uuid, payload) => dispatch(replacePhotoAsThunk(uuid, payload)),
  clearError: (error) => dispatch(clearError(error)),
});

ListingImagesWrapper.propTypes = {
  allImagesLength: number,
  useMLSphotos: bool,
  imageErrors: arrayOf(shape({})),
  clearError: func,
  replacePhotoAsThunk: func.isRequired,
  blossorId: string,
  uuid: string,
};

ListingImagesWrapper.defaultProps = {
  allImagesLength: 0,
  useMLSphotos: false,
  imageErrors: null,
  clearError: null,
  blossorId: "",
  uuid: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingImagesWrapper);
