import React from "react";
import { connect } from "react-redux";
import { arrayOf, func, string } from "prop-types";

import { Check, Trash, Calendar, MultiUser } from "../../../../shared/v2/Icomoon";
import withSelectedTasks from "../HOCs/withSelectedTasks";
import withFilters from "../HOCs/withFilters/withFilters";
import { bulkEditTasksAsThunk } from "../actions/thunks";
import {
  setTaskModalBulkDelete,
  setModalBulkRescheduleTaskOpen,
  setModalBulkReassignTaskOpen,
} from "../actions/creators";

import TaskSelection from "./fractals";

const TaskSelections = ({
  onBulkEditTasksAsThunk,
  onSetTaskModalBulkDelete,
  onSetModalRescheduleTaskOpen,
  onSetModalReassignTaskOpen,
  selectedTasks,
  userUuid,
  listingUuid,
  taskFilters,
  primaryActiveList,
}) => (
  <div className="tw-flex tw-gap-[8px] tw-mb-[22px]">
    {primaryActiveList[0] !== "completed_at" ? (
      <>
        <TaskSelection
          Icon={Check}
          schema="semantic-blue"
          onClick={() =>
            onBulkEditTasksAsThunk(selectedTasks, listingUuid, taskFilters, {
              state: "complete",
              user_id: userUuid,
            })
          }
        >
          Complete
        </TaskSelection>
        <TaskSelection Icon={Trash} schema="warning" onClick={onSetTaskModalBulkDelete}>
          Delete
        </TaskSelection>
        <TaskSelection Icon={Calendar} schema="secondary" onClick={onSetModalRescheduleTaskOpen}>
          Reschedule
        </TaskSelection>
        <TaskSelection Icon={MultiUser} schema="secondary" onClick={onSetModalReassignTaskOpen}>
          Reassign
        </TaskSelection>
      </>
    ) : (
      <TaskSelection
        Icon={Check}
        schema="semantic-blue"
        onClick={() =>
          onBulkEditTasksAsThunk(selectedTasks, listingUuid, taskFilters, {
            state: "run",
            user_id: userUuid,
          })
        }
      >
        Mark As Incomplete
      </TaskSelection>
    )}
  </div>
);

TaskSelections.propTypes = {
  onBulkEditTasksAsThunk: func,
  userUuid: string,
  listingUuid: string,
  selectedTasks: arrayOf(string),
  taskFilters: string,
  onSetTaskModalBulkDelete: func,
  onSetModalRescheduleTaskOpen: func,
  onSetModalReassignTaskOpen: func,
  primaryActiveList: arrayOf(string),
};

TaskSelections.defaultProps = {
  onBulkEditTasksAsThunk: null,
  userUuid: null,
  listingUuid: null,
  selectedTasks: [],
  taskFilters: null,
  onSetTaskModalBulkDelete: null,
  onSetModalRescheduleTaskOpen: null,
  onSetModalReassignTaskOpen: null,
  primaryActiveList: [],
};

const mapStateToProps = (state) => ({
  userUuid: state.tdpDetailsReducer.currentUser.uuid,
  listingUuid: state.tdpTasksReducer.uuid,
  primaryActiveList: state.tdpTasksReducer.primaryActiveList,
});

const mapDispatchToProps = (dispatch) => ({
  onBulkEditTasksAsThunk: (selectedTasks, listingUuid, taskFilters, payload) =>
    dispatch(bulkEditTasksAsThunk(selectedTasks, listingUuid, taskFilters, payload)),
  onSetTaskModalBulkDelete: () => dispatch(setTaskModalBulkDelete()),
  onSetModalRescheduleTaskOpen: () => dispatch(setModalBulkRescheduleTaskOpen()),
  onSetModalReassignTaskOpen: () => dispatch(setModalBulkReassignTaskOpen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withFilters(withSelectedTasks(TaskSelections)));
