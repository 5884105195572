import React from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { setTaskModalBulkDelete } from "../actions/creators";

import Task from "../Task";
import ModalDeleteTask from "../ModalDeleteTask";
import ModalBulkRescheduleTask from "../ModalBulkRescheduleTask";
import ModalBulkReassignTask from "../ModalBulkReassignTask";

const TaskList = ({
  activeTasks,
  selectedTasks,
  isModalBulkDeleteOpen,
  onSetTaskModalBulkDelete,
  isCompletedTab,
}) => (
  <>
    <ModalDeleteTask
      isTaskModalDeleteOpen={isModalBulkDeleteOpen}
      setTaskModalDelete={onSetTaskModalBulkDelete}
      ids={selectedTasks}
    />
    <ModalBulkRescheduleTask />
    <ModalBulkReassignTask />
    <div className="tw-border-t-[1px] tw-border-r-0 tw-border-l-0 tw-border-b-0 tw-border-neutral-gray-50 tw-border-solid">
      {activeTasks.map((task) => (
        <Task key={task.id} task={task} useCompletedUser={isCompletedTab} />
      ))}
    </div>
  </>
);

const mapStateToProps = (state) => ({
  activeTasks: state.tdpTasksReducer.activeTasks,
  selectedTasks: state.tdpTasksReducer.selectedTasks,
  isModalBulkDeleteOpen: state.tdpTasksReducer.isModalBulkDeleteOpen,
  isCompletedTab: state.tdpTasksReducer.primaryActiveList.indexOf("completed_at") > -1,
});

const mapDispatchToProps = (dispatch) => ({
  onSetTaskModalBulkDelete: () => dispatch(setTaskModalBulkDelete()),
});

TaskList.propTypes = {
  activeTasks: arrayOf(shape({})),
  selectedTasks: arrayOf(string),
  isModalBulkDeleteOpen: bool,
  onSetTaskModalBulkDelete: func,
  isCompletedTab: bool.isRequired,
};

TaskList.defaultProps = {
  activeTasks: [],
  selectedTasks: arrayOf(string),
  isModalBulkDeleteOpen: false,
  onSetTaskModalBulkDelete: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
