import React, { Component } from 'react';
import axios from 'axios';

import ListingAlertForm from '../../PersonDetail/components/listing_alerts/ListingAlert';
import { FloatingForm } from '@shared/v2/Modal';

class MassSendListingAlerts extends Component {
  state = {
    mlsIds: [],
    selectedIds: [],
    showModal: false,
    validIds: [],
    blueRoofError: false
  }

  getValidLeads = () => {
    const path = "/bulk_listing_alerts/valid_leads"
    let ids = Brivity.PeoplePage.grid().multiSelect().getSelectedObjectIds()

    axios.get(path, { params: { people_ids: ids }})
      .then(res => {
        this.setState({
          validIds: res.data.with_email,
          selectedIds: ids,
          mlsIds: res.data.mls_list,
          showModal: true,
          blueRoofError: false
        })
      })
      .catch((error) => { this.setState({ blueRoofError: true, showModal: true })})
  }

  closeListingAlertModal = () => {
    this.setState({ showModal: false })
  }

  handleClick = () => {
    this.getValidLeads()
  }

  handleFormSubmit = (params, callback) => {
    if (this.state.validIds.length > 0) {
      params["valid_ids"] = this.state.validIds;
    }

    const options = {
      headers: {
        "X-CSRF-Token": ReactOnRails.authenticityToken(),
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    };

    axios.post("/bulk_listing_alerts/bulk_create_alerts", params, options)
    .then(res => {
      this.setState({ showModal: false }, () => { callback(res) })
    })
    .catch(err => {
      callback(err)
    })
  }

  showModal = () => {
    const { mlsIds, selectedIds, validIds } = this.state
    const { geoCodeAccountAddress } = this.props
    const title = "Mass Create Listing Alerts";

    if (this.state.blueRoofError) {
      return (
        <FloatingForm
          title={title}
          show
          onCancel={this.closeListingAlertModal}
          primaryOption="OK"
          onPrimary={this.closeListingAlertModal}
        >
          <div>There is an issue with Mass Create Alerts. Please contact support via chat, or call 855-427-4848.</div>
        </FloatingForm>
      )
    }

    return (
      <ListingAlertForm
        person={{ id: this.state.validIds[0]}}
        closeListingAlertModal={this.closeListingAlertModal}
        mlsIds={mlsIds}
        geoCodeAccountAddress={geoCodeAccountAddress}
        type="new"
        handleFormSubmit={(params, callback)=>this.handleFormSubmit(params, callback)}
        validIds={validIds.length}
        selectedIds={selectedIds.length}
        submitButton="SAVE & SEND"
        title={title}
      />
    )
  }

  render() {
    return(
      <div>
        <div
          onClick={this.handleClick}
          role="button"
        >
          Listing Alert
        </div>
        { this.state.showModal && this.showModal() }
      </div>
    )
  }
}

export default MassSendListingAlerts;
