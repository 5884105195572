import React from "react";
import { bool } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { IS_REFERRAL_DESIRED_ADDRESS_EDIT } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import ReferralDesiredAddressVal from "../ReferralDesiredAddressVal";
import ReferralDesiredAddressEdit from "../ReferralDesiredAddressEdit";

const ReferralDesiredAddress = ({ isReferralDesiredAddressEdit }) => (
  <div className="tw-flex tw-items-center tw-mb-8px">
    <span className="tw-font-semibold">Desired Locations:</span>
    {isReferralDesiredAddressEdit ? <ReferralDesiredAddressEdit /> : <ReferralDesiredAddressVal />}
  </div>
);

ReferralDesiredAddress.propTypes = {
  isReferralDesiredAddressEdit: bool,
};

ReferralDesiredAddress.defaultProps = {
  isReferralDesiredAddressEdit: false,
};

export default tdpDetails({
  WrappedComponent: ReferralDesiredAddress,
  states: IS_REFERRAL_DESIRED_ADDRESS_EDIT,
});
