import React from "react";
import { connect } from "react-redux";
import { string, number, bool, arrayOf, shape } from "prop-types";

import ListingImage from "../ListingImage";
import UploadImage from "../UploadImage";

const ListingImages = ({ listingImgs, useMLSphotos }) => (
  <div className="tw-grid tw-grid-cols-3 tw-grid-rows-3 tw-gap-y-[16px] tw-gap-x-[16px]">
    {!useMLSphotos && (
      <div className="tw-bg-neutral-gray-10 tw-w-[190px] tw-h-[190px]">
        <UploadImage />
      </div>
    )}

    {listingImgs.map(({ id, checked, url, deleteUrl, original, isLoading, name }) => (
      <ListingImage
        key={id}
        id={id}
        url={url}
        name={name}
        checked={checked}
        isLoading={isLoading}
        highResUrl={original}
        deleteUrl={deleteUrl}
      />
    ))}
  </div>
);

const mapStateToProps = (state) => ({
  listingImgs: state.tdpDetailsReducer.listingImgs,
  useMLSphotos: state.tdpDetailsReducer.useMLSphotos,
});

ListingImages.propTypes = {
  useMLSphotos: bool,
  listingImgs: arrayOf(
    shape({
      url: string,
      name: string,
      id: number,
      checked: bool,
      isLoading: bool,
      deleteUrl: string,
    }),
  ),
};

ListingImages.defaultProps = {
  useMLSphotos: false,
  listingImgs: [],
};

export default connect(mapStateToProps)(ListingImages);
