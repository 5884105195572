import React from "react";
import { func, string } from "prop-types";
import { useSelector } from "react-redux";

import { textColor, isPlace } from "./helpers";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const ResourceLauncher = ({ linkUrl, label, Icon }) => {
  const theme = useSelector(({ layout }) => layout.theme.name);
  const currentUser = useCurrentUser();
  const url = label === "AreaPro" ? `${linkUrl}?email=${currentUser.email}` : linkUrl;

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="tw-flex tw-flex-col tw-w-[100px] tw-items-center tw-py-[12px] tw-px-[25px] hover:tw-no-underline hover:tw-bg-neutral-gray-5 focus:tw-no-underline tw-gap-[8px] tw-rounded-[8px] tw-cursor-pointer"
    >
      <Icon size="xxl" className={isPlace(theme) ? "tw-text-black" : "tw-text-teal"} />
      <span className={`tw-text-[12px] tw-leading-[16px] tw-whitespace-nowrap ${textColor(theme)}`}>
        {label}
      </span>
    </a>
  );
};

export default ResourceLauncher;

ResourceLauncher.propTypes = {
  label: string.isRequired,
  Icon: func.isRequired,
  linkUrl: string,
};

ResourceLauncher.defaultProps = {
  linkUrl: "/",
};
