import * as actionCreators from "../TransactionDetailsPage/UI/Modals/ThumbnailSelector/actions/creators";

const defaultState = {
  availablePhotos: null,
  show: false,

  isSaving: false,
  isSaved: false,
  hasSaveError: false,

  selectedPhoto: null,
};

const tdpThumbnailSelector = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.reset().type:
      return {
        ...state,
        selectedPhoto: null,
        availablePhotos: null,
        show: false,
        isSaving: false,
        isSaved: false,
        hasSaveError: false,
      };
    case actionCreators.showModal().type:
      return {
        ...state,
        show: payload.show,
      };
    case actionCreators.setSaving().type:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        hasSaveError: false,
      };
    case actionCreators.setSaved().type:
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        hasSaveError: false,
      };
    case actionCreators.setSaveError().type:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        hasSaveError: true,
      };

    case actionCreators.setAvailablePhotos().type:
      return {
        ...state,
        availablePhotos: payload.availablePhotos,
      };
    case actionCreators.selectPhoto().type:
      return {
        ...state,
        selectedPhoto: payload.photo,
      };

    default:
      return state;
  }
};

export default tdpThumbnailSelector;
