import React from "react";
import PropTypes from "prop-types";

const HorizontalLine = ({ oversized }) => (
  <hr
    className={`tw-border-gray-10 tw-border-solid tw-border-[1px] ${oversized ? "tw-my-0 tw-mx-[-8px]" : "tw-m-0"}`}
  />
);

HorizontalLine.propTypes = {
  oversized: PropTypes.bool,
};

HorizontalLine.defaultProps = {
  oversized: false,
};

export default HorizontalLine;
