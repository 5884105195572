import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import FieldLabelV1 from "./FieldLabelV1";
import DatePicker from "../v2/DatePicker";

function FieldDateV1({
  dateFormat = "MM/DD/YYYY",
  value = "",
  onChange,
  label,
  disabled = false,
  required,
  flexStyle = "tw-flex-1",
  enableTimePicker,
  position,
  noYear,
}) {
  return (
    <div className={flexStyle}>
      {label && <FieldLabelV1 {...{ label, required }} title={label} />}
      <div className="tw-text-sm tw-flex tw-relative tw-justify-center tw-flex-col" data-cy={`${label}`}>
        <DatePicker
          dateFormat={dateFormat}
          onChange={(dateValue) => {
            if (dateValue) {
              onChange(moment(dateValue, dateFormat).format("YYYY-MM-DD"));
            } else {
              onChange(dateValue);
            }
          }}
          value={moment(value).format(dateFormat)}
          inputProps={{
            placeholder: dateFormat,
            disabled: disabled,
          }}
          enableTimePicker={enableTimePicker}
          position={position}
          noYear={noYear}
        />
      </div>
    </div>
  );
}
FieldDateV1.propTypes = {
  dateFormat: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  position: PropTypes.oneOf(["default", "bottom", "bottom-left"]),
  noYear: PropTypes.bool,
};

FieldDateV1.defaultProps = {
  dateFormat: "MM/DD/YYYY",
  value: "",
  onChange: () => {},
  label: "",
  disabled: false,
  required: false,
  flexStyle: "tw-flex-1",
  position: "default",
  noYear: false,
};
export default FieldDateV1;
