import React from "react";
import PropTypes from "prop-types";

import { eventTypeProps, getEventDetailType } from "../utils/events";
import OfferDetails from "./OfferDetails";

const EventTitle = ({ children }) => <span className="tw-text-gray-75 tw-font-semibold">{children}</span>;

EventTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

const EventDetails = ({ event }) => {
  const type = getEventDetailType(event.kind);

  if (type === "home_value") {
    return (
      <div>
        <div>
          <EventTitle>Address: </EventTitle>
          <span>{event.timeline_fields.address}</span>
        </div>
        <div>
          {event.timeline_fields.search_link && (
            <a
              className="tw-text-teal"
              href={event.timeline_fields.search_link}
              target="_blank"
              rel="noreferrer"
            >
              See home value estimate
            </a>
          )}
        </div>
        <div>
          <EventTitle>Bed: </EventTitle>
          <span>{event.timeline_fields.bed} </span>
          <EventTitle>Bath: </EventTitle>
          <span>{event.timeline_fields.bath}</span>
        </div>
        <div>
          <EventTitle>Sqft: </EventTitle>
          {event.timeline_fields.square_feet}
        </div>
      </div>
    );
  }

  if (type === "requested_showing") {
    return (
      <div>
        <div>
          <EventTitle>Type: </EventTitle>
          <span>{event.schedule_tour_type}</span>
        </div>
        <div>
          <EventTitle>Schedule Date: </EventTitle>
          <span>{event.timeline_fields.scheduled_date}</span>
        </div>
        <div>
          <EventTitle>Availability: </EventTitle>
          <span>{event.timeline_fields.availability}</span>
        </div>
        <div>
          <EventTitle>Comment: </EventTitle>
          <span>{event.timeline_fields.comments}</span>
        </div>
      </div>
    );
  }

  if (type === "asked_a_question") {
    const href = event.timeline_fields.comments?.split("URL:")[1];
    return (
      <>
        <div>
          <EventTitle>Comment: </EventTitle>
          <span
            dangerouslySetInnerHTML={{
              __html: event.timeline_fields.comments?.split("URL")[0],
            }}
          />
        </div>
        {href && (
          <div>
            <EventTitle>URL: </EventTitle>
            <a className="tw-text-teal tw-break-all" href={href} target="_blank" rel="noreferrer">
              {href}
            </a>
          </div>
        )}
      </>
    );
  }

  if (type === "start_offer") {
    return <OfferDetails event={event} />;
  }

  if (type === "ads-retarget") {
    return (
      <div>
        <EventTitle>Tag: </EventTitle>
        {event.timeline_fields.utm_campaign}
      </div>
    );
  }

  if (type === "ads-remarket") {
    return (
      <div>
        {event.timeline_fields.referrer && (
          <div>
            Referrer:{" "}
            <a href={event.timeline_fields.referrer} target="_blank" rel="noreferrer">
              {event.timeline_fields.referrer.slice(0, 50)}
            </a>
          </div>
        )}
        {event.timeline_fields.url && (
          <div>
            Landing Page:{" "}
            <a href={event.timeline_fields.url} target="_blank" rel="noreferrer">
              {event.timeline_fields.url.slice(0, 50)}
            </a>
          </div>
        )}
      </div>
    );
  }

  return null;
};

EventDetails.propTypes = {
  event: eventTypeProps.isRequired,
};

export default EventDetails;
