import React from "react";
import { shape } from "prop-types";
import Circle from "../../shared/Circle";

const Action = ({ action, autoPlanActions }) => {
  const iconOptions = {
    Text: "fa fa-commenting-o",
    Task: "fa6 fa6-list",
    Email: "fa6-regular fa6-envelope",
  };

  const actionIcon = () => iconOptions[action.type];

  const fromUserLabel = () => <span className="from-label">from {action.assigned_to_label} </span>;

  const afterActionLabel = () => {
    const { parent_action: parentAction } = action;
    if (!parentAction) {
      return "";
    }

    return (
      <span className="dueTimeLabel">
        Due time is {action.delay} {action.delay_unit} after
        <span className="bold">
          {" "}
          {parentAction.type}: {parentAction.name}
        </span>
        .
      </span>
    );
  };

  const contingentAfterActionLabel = () => {
    const { parent_action: parentAction } = action;
    if (!parentAction) {
      return "";
    }

    return (
      <span className="dueTimeLabel">
        Due time auto-updates {action.delay} {action.delay_unit} after
        <span className="bold">
          {" "}
          {parentAction.type}: {parentAction.name}{" "}
        </span>
        is completed
      </span>
    );
  };

  const dueTimeLabel = () => {
    if (action.contingent && action.parent_action_id) {
      return <span> - {contingentAfterActionLabel()} </span>;
    }
    if (action.parent_action_id) {
      return <span> - {afterActionLabel()} </span>;
    }
    return "";
  };

  return (
    <div>
      <div>
        <div className="auto-plan-timeline-action d-flex flex-items-center">
          <div className="auto-plan-timeline-action-item">
            <Circle circleStyle="brivity-blue-background text-white tw-text-[16px]" icon={actionIcon()} />
          </div>
          <div className="auto-plan-timeline-action-item">
            <div className="d-flex flex-column">
              <div className="d-flex flex-justify-center flex-column">
                <div className="">
                  {`${action.type}: `}
                  <span className="bold-body">{action.name} </span>
                </div>
                <div className="description understated">
                  {fromUserLabel()} {dueTimeLabel()}
                </div>
              </div>
            </div>
          </div>
          <div className="auto-plan-timeline-action-item d-flex">
            <div className="">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
              <a onClick={() => autoPlanActions.openEditActionModal(action)}>
                <i className="fa fa-pencil" />
              </a>
            </div>
            <div className="m-l-20">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
              <a onClick={() => autoPlanActions.openDeleteActionModal(action.id)}>
                <i className="fa fa-trash" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Action.propTypes = {
  action: shape({}),
  autoPlanActions: shape({})
};

Action.defaultProps = {
  action: {},
  autoPlanActions: {}
};

export default Action;