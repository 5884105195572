import React from "react";
import Proptypes from "prop-types";
import { Modal, Col, Row } from "react-bootstrap";
import buttons from "../../PersonDetail/components/button.module.css";
import HeaderTitle from "../../PersonDetail/components/modals/HeaderTitle";

const SuccessModal = ({ modalOpen, closeModal, submitResponse }) => (
  <div>
    <Modal className="modal" backdrop="static" show={modalOpen} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <HeaderTitle title="Manage Access to Person" onClose={closeModal} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>{submitResponse?.content && <p> {submitResponse.content}</p>}</Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-justify-space-between tw-float-right">
              <button
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} m-l-5`}
                type="button"
                onClick={closeModal}
              >
                Ok
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  </div>
);

SuccessModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  submitResponse: Proptypes.shape({ content: Proptypes.string }),
};
SuccessModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  submitResponse: null,
};

export default SuccessModal;
