import { useEffect, useMemo, useState } from "react";
import { caseTransformingAxios } from "../../shared/v2/caseTransformingAxios";

const getActivityHistory = (params, signal) =>
  caseTransformingAxios.get("/api/v4/agent_activities", { params, signal });

const updateActivity = (activity) =>
  caseTransformingAxios.put(`/api/v4/agent_activities/${activity.id}`, activity);

const deleteActivity = (activity) => caseTransformingAxios.delete(`/api/v4/agent_activities/${activity.id}`);

const useActivityHistory = (goalType, userUuids) => {
  const [state, _setState] = useState({ data: null, error: false, loading: false });
  const setState = useMemo(() => (newState) => _setState((_state) => ({ ..._state, ...newState })));

  useEffect(() => {
    if (!goalType) return undefined;
    setState({ loading: true });
    const abortController = new AbortController();
    getActivityHistory({ goalTypeId: goalType.id, userUuids }, abortController.signal)
      .then((res) => setState({ data: res.data, loading: false, error: false }))
      .catch(() => setState({ error: true, loading: false }));
    return () => abortController.abort();
  }, [goalType, userUuids]);

  const actions = {
    updateActivity(activity) {
      setState({ loading: true });
      return updateActivity(activity)
        .then(({ data }) => {
          setState({
            data: state.data.map((d) => (d.id === data.id ? data : d)),
            loading: false,
            error: false,
          });
          return data;
        })
        .catch(() => setState({ loading: false, error: true }));
    },

    deleteActivity(activity) {
      setState({ loading: true });
      return deleteActivity(activity)
        .then(() => {
          setState({ data: state.data.filter((d) => d.id !== activity.id), loading: false, error: false });
          return true;
        })
        .catch(() => setState({ loading: false, error: true }));
    },
  };

  return { actions, ...state };
};

export default useActivityHistory;
