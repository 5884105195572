/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BedEmptyRegularV6 = (props) => (
  <Icon {...props} name="Bed Empty Regular V6">
    <path d="M.6 2.4a.6.6 0 0 1 .6.6v2.6H13c1.658 0 3 1.342 3 3v4.6c0 .332-.267.6-.6.6s-.6-.268-.6-.6v-2H1.2V13A.6.6 0 1 1 0 13V3a.6.6 0 0 1 .6-.6zm.6 4.4V10h13.6V8.6c0-.995-.805-1.8-1.8-1.8H1.2z" />
  </Icon>
);

export default BedEmptyRegularV6;
