import React from "react";

import { ArrowsRepeatSolidV6 } from "@shared/v2/Icomoon";
import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";

const LeadTransferActivity = ({ activity, onReadChange }) => {
  const { secondarySubjectFirstName, secondarySubjectLastName } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={ArrowsRepeatSolidV6}
      title={activity.title}
    >
      <span>{`Transferred from: ${secondarySubjectFirstName} ${secondarySubjectLastName}`}</span>
    </ActivitiesContainer>
  );
};

export default LeadTransferActivity;

LeadTransferActivity.propTypes = {
  ...activityCommonPropTypes,
};

LeadTransferActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
