import React, {Component} from 'react';
import alerts from './listing_alerts.module.css';

class Map extends Component {
  state = {
    gblDrawListener: undefined,
    polygonObject: undefined,
  }

  componentDidMount() {
    const { geoCodeAccountAddress, polygonCoordinates } = this.props
    let map = undefined
    Brivity.SetUpGetBounds()
    const centerOnUS = {
      zoom: 3,
      center: {
        lat: 39.8283,
        lng: -98.5795
      },
      mapTypeId: 'terrain',
      panControl: false
    }

    // Setting up the map.
    // If no polygon coordinates and the account has a geocode address center on that address
    // Otherwise center on the US
    //If there are polygon coordinates draw that on the map and set up listeners for editing and deleting vertices
    if (geoCodeAccountAddress !== undefined && polygonCoordinates === undefined) {
      const geocoder = new google.maps.Geocoder()
      map = new (google.maps.Map)(document.getElementById('listingAlertMap'), { center: { lat: 0, lng: 0 }, zoom: 12 })
      geocoder.geocode({ 'address': geoCodeAccountAddress }, (results, status) => {
        if (status === 'OK') {
          map.setCenter(results[0].geometry.location)
        } else {
          map = new (google.maps.Map)(document.getElementById('listingAlertMap'), centerOnUS)
        }
      })
    } else {
      map = new (google.maps.Map)(document.getElementById('listingAlertMap'), centerOnUS)
      if (polygonCoordinates !== undefined) {
        let polyArray = this.drawPoly(polygonCoordinates)
        let polygonFromCoordinates = new google.maps.Polygon({
          map: map,
          path: polyArray,
          strokeColor: '#656b70',
          strokeOpacity: 0.7,
          strokeWeight: 2,
          fillColor: '#656b70',
          fillOpacity: 0.2,
          editable: true
        })

        // centers on polygon and set up edit and delete listeners
        this.setState({ polygonObject: polygonFromCoordinates }, () => {
          map.fitBounds(this.state.polygonObject.getBounds())
          this.editPolyVertexListener()
          this.clickToDeleteVertexListener()
        })
      }
    }

    const drawControlDiv = document.createElement('div')
    const drawControl = this.drawOrCancelButton(drawControlDiv, map)
    drawControlDiv.index = 1
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(drawControlDiv)
  }

  componentWillUnmount() {
    if (this.state.polygonObject) {
      google.maps.event.clearListeners(this.state.polygonObject.getPath(), 'insert_at')
      google.maps.event.clearListeners(this.state.polygonObject.getPath(), 'remove_at')
      google.maps.event.clearListeners(this.state.polygonObject.getPath(), 'set_at')
      google.maps.event.clearListeners(this.state.polygonObject, 'click')
    }
  }

  drawPoly = (multipolygonWKT) => {
    let polylines = []
    const points = multipolygonWKT.match(/[-]?\d+(\.\d+)?,[-]?\d+(\.\d+)?/g);

    points.forEach((point) => {
      let splitPoint = point.split(',')
      polylines.push({ lat: parseFloat(splitPoint[0]), lng: parseFloat(splitPoint[1]) })
    })

    return polylines
  }

  drawOrCancelButton = (controlDiv, dmap, mobile) => {
    // Set CSS for the control border.
    let controlUI = document.createElement('button')
    controlUI.style.backgroundColor = '#fff'
    controlUI.style.border = '2px solid #fff'
    controlUI.style.borderRadius = '3px'
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
    controlUI.style.cursor = 'pointer'
    controlUI.style.marginTop = '10px'
    controlUI.style.marginRight = '10px'
    controlUI.style.textAlign = 'center'
    controlUI.title = 'Click to do a search by drawing'
    controlUI.setAttribute('type', 'button')
    controlDiv.appendChild(controlUI)

    // Set CSS for the control interior.
    let controlText = document.createElement('div')
    controlText.style.color = 'rgb(25,25,25)'
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
    controlText.style.fontSize = '12px'
    if (this.state.polygonObject === undefined) {
      controlText.innerHTML = '<i class="ss-location"></i> Draw'
    } else {
      controlText.innerHTML = 'Cancel'
    }
    controlText.style.paddingLeft = '5px'
    controlText.style.paddingRight = '5px'
    controlText.style.lineHeight = '28px'

    if (mobile) {
      controlText.setAttribute("id", "drawBtnTextM");
    } else {
      controlText.setAttribute("id", "drawBtnText");
    }

    if (this.props.polygonCoordinates === undefined) {
      controlText.setAttribute("is-active", false);
    } else {
      controlText.setAttribute("is-active", true);
    }

    controlUI.appendChild(controlText);
    let _self = this
    controlUI.addEventListener('click', () => {
      if (mobile) {
        let isActive = $("#drawBtnTextM").attr('is-active');

        if (isActive === 'true') {
          $("#drawBtnTextM").attr('is-active', false);
          $("#drawBtnTextM").html('<i class="ss-location"></i> Draw');
          enableMap(dmap);
          if (this.state.polygonObject !== undefined) {
            this.state.polygonObject.setMap(null)
          }
          // Ensuring all the listeners enabled on 'mousedown' get cleaned
          google.maps.event.clearListeners(dmap.getDiv(), 'mousedown');
          google.maps.event.removeListener(this.state.gblDrawListener.move);
          google.maps.event.clearListeners(dmap, 'mouseup');
        } else {
          $("#drawBtnTextM").attr('is-active', true);
          $("#drawBtnTextM").html('X');

          disableMap(dmap);

          // Validates element exists to avoid and error and prevent a malfunction of the drawing in mobile
          if ($("#listing-grid-results").length) {
            $('html, body').animate({
              scrollTop: $("#listing-grid-results").offset().top
            }, 200);
          }

          google.maps.event.addDomListener(dmap.getDiv(), 'mousedown', function (e) {
            drawFreeHandOnMap(dmap, mobile);
          });
        }
      } else {
        let isActive = $("#drawBtnText").attr('is-active')

        if (isActive === 'true') {
          $("#drawBtnText").attr('is-active', false)
          $("#drawBtnText").html('<i class="ss-location"></i> Draw')
          enableMap(dmap)
          if (this.state.polygonObject !== undefined) {
            this.state.polygonObject.setMap(null)
          }

          // Ensuring all the listeners enabled on 'mousedown' get cleaned
          google.maps.event.clearListeners(dmap.getDiv(), 'mousedown')
          google.maps.event.removeListener(this.state.gblDrawListener.move)
          google.maps.event.clearListeners(dmap, 'mouseup')

        } else {
          $("#drawBtnText").attr('is-active', true)
          $("#drawBtnText").html('Cancel')
          disableMap(dmap)
          google.maps.event.addDomListener(dmap.getDiv(), 'mousedown', function (e) {
            _self.drawFreeHandOnMap(dmap, mobile)
          })
        }
      }
    })
  }

  drawFreeHandOnMap = (dmap, mobile) => {
    if(this.state.polygonObject !== undefined) {
      this.state.polygonObject.setMap(null)
    }
    //the polygon
    let poly = new google.maps.Polyline({
      map: dmap,
      clickable: false,
      strokeColor: '#656b70',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#656b70',
      fillOpacity: 0.2
    })

    //move-listener
    this.setState({ gblDrawListener: google.maps.event.addListener(dmap, 'mousemove', (e) => { poly.getPath().push(e.latLng) }) })

    //mouseup-listener
    let _self = this
    google.maps.event.addListenerOnce(dmap, 'mouseup', (e) => {
      google.maps.event.removeListener(this.state.gblDrawListener.move)
      // Use map range distance as a scaler for tolerance (so state-sized
      // polygons don't have hundreds of geographic points *more than* a hand-
      // drawing can realistically communicate, and neighborhood-sized polygons
      // do get enough points to convey the geographic detail a hand-drawing
      // at that scale *does* accurately capture)
      let mapRangeInDegreesLatLng = calculateMapRangeInDegrees(dmap)
      // simplificationTolerance is presumed to be in the same unit as the
      // points (degrees of lat/long, in this case). Larger tolerance leads
      // to less-refined (chunkier) path.
      let simplificationTolerance = mapRangeInDegreesLatLng/100
      let simplifiedLinePath = simplifyLine(poly,simplificationTolerance)
      //Remove freehand drawing and replace with polygon
      poly.setMap(null);
      let polygonObject = new google.maps.Polygon({
        map: dmap,
        path: simplifiedLinePath,
        strokeColor: '#656b70',
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillColor: '#656b70',
        fillOpacity: 0.2,
        editable: true
      })
      _self.setState({ polygonObject })

      google.maps.event.clearListeners(dmap.getDiv(), 'mousedown')
      this.editPolyVerticies()

      if (mobile) {
        removeMarkersMobile()
      }
      // Enabling map so user can move it
      enableMap(dmap)
      // TODO: the addListener seems to work with dmap but why not _self.polygon... addListener(_self.polygon, 'click', function(event) {
      google.maps.event.addListener(dmap, 'click', function(event) {
        if(event.path != null && event.vertex != null){
          let path = this.getPaths().getAt(event.path)
          if(path.getLength() > 3){
            path.removeAt(event.vertex)
          }
        }
      })
    })
  }

  editPolyVerticies = () => {
    let vertices = this.state.polygonObject.getPath().getArray()
    let contentString = ''

    //Add listeners if a polygon has been drawn for the first time
    if (google.maps.event.hasListeners(this.state.polygonObject.getPath(), 'insert_at') === false) {
      this.editPolyVertexListener()
      this.clickToDeleteVertexListener()
    }

    // Iterate over the vertices.
    let  stopPoint = 0
    let i = 0
    while (i < vertices.length) {
      if (stopPoint == 0) {
        contentString += '(' + vertices[i].lat().toFixed(3) + ',' + vertices[i].lng().toFixed(3) + '),'
      } else {
        if (stopPoint == 5) {
          stopPoint = 0
        } else {
          stopPoint++
        }
      }
      i++
    }
    if (contentString != '') {
      this.props.handlePolygonUpdate(contentString.substr(0, contentString.length - 1))
      // TODO: make sure the counts and url update
    }
  }

  editPolyVertexListener = () => {
    google.maps.event.addListener(this.state.polygonObject.getPath(), 'insert_at', (function(_this) {
      return function(event) {
        return _this.editPolyVerticies();
      };
    })(this));

    google.maps.event.addListener(this.state.polygonObject.getPath(), 'remove_at', (function(_this) {
      return function(event) {
        return _this.editPolyVerticies();
      };
    })(this));

    google.maps.event.addListener(this.state.polygonObject.getPath(), 'set_at', (function(_this) {
      return function(event) {
        return _this.editPolyVerticies();
      };
    })(this));
  }

  clickToDeleteVertexListener = () => {
    google.maps.event.addListener(this.state.polygonObject, 'click', (e) => {
      if (e.path !== null && e.vertex !== null) {
        const path = this.state.polygonObject.getPaths().getAt(e.path)
        if (path.getLength() > 3) {
          path.removeAt(e.vertex)
        }
      }
      return
    })
  }

  render() {
    return (
      <div className={alerts.mapContainer} id="listingAlertMap" />
    )
  }
}

export default Map
