import RadioButton from "@shared/v2/RadioButton";
import React from "react";

const ALL_LABEL = {
  tasks: "All tasks",
  agentActivity: "All Leads",
  appointments: "All Leads",
};

const RANGE_LABEL = {
  tasks: "Newly created tasks (in date range)",
  agentActivity: "Newly assigned leads (in date range)",
  appointments: "Newly assigned leads (in date range)",
};

const AllOrDateRangeRadios = ({ onChange, report, allInRange }) => (
  <div className="tw-flex tw-items-center tw-gap-[8px]">
    <label className="tw-mb-0">Reporting for:</label>

    <div className="tw-flex tw-flex-wrap tw-gap-[16px]">
      <RadioButton label={ALL_LABEL[report]} checked={allInRange} onChange={() => onChange(report, true)} />
      <RadioButton
        label={RANGE_LABEL[report]}
        checked={!allInRange}
        onChange={() => onChange(report, false)}
      />
    </div>
  </div>
);

export default AllOrDateRangeRadios;
