import React, { useState } from "react";
import { caseTransformingAxios } from "../../../shared/v2/caseTransformingAxios";
import TextInput from "../../../shared/v2/TextInput";
import Button from "../../../shared/v2/Button";
import ErrorBanner from "../../../shared/v2/ErrorBanner/ErrorBanner";
import FieldLabel from "../../../shared/v2/FieldLabel";

const secondAuth = (formData) =>
  caseTransformingAxios.post("/api/v4/reporting/powerbi_authenticate", { formData });

const useSecondAuth = () => {
  const [state, setState] = useState({ error: false, loading: false });

  return {
    ...state,
    actions: {
      authorize: (formData, onSuccess) => {
        setState((prevState) => ({ ...prevState, error: false, loading: true }));
        secondAuth(formData)
          .then((res) => {
            setState((prevState) => ({ ...prevState, error: false, loading: false }));
            onSuccess?.(res.data);
          })
          .catch(() => setState((prevState) => ({ ...prevState, error: true, loading: false })));
      },
    },
  };
};

const SecondAuthForm = ({ onSuccess }) => {
  const { actions, error, loading } = useSecondAuth();

  return (
    <div className="tw-h-full tw-flex tw-justify-center tw-items-center tw-p-[8px]">
      <form
        className="tw-bg-white tw-shadow-card tw-rounded-[8px] tw-p-[16px] tw-flex tw-flex-col tw-gap-[16px] tw-max-w-[640px] tw-mx-auto"
        onSubmit={(e) => {
          e.preventDefault();
          const formData = Object.fromEntries(new FormData(e.target).entries());
          actions.authorize(formData, onSuccess);
        }}
      >
        {error && <ErrorBanner errors={["Authorization failed, please try again."]} />}
        <h3>Password Required</h3>
        <p className="tw-mb-0">Enter your Brivity credentials to access this page.</p>
        <FieldLabel className="tw-mb-0px tw-w-fit" label="Username" isRequired />
        <TextInput name="username" type="email" required />
        <FieldLabel className="tw-mb-0px" label="Password" isRequired />
        <TextInput type="password" name="password" required />
        <Button className="tw-self-center" size="medium" type="submit" isLoading={loading}>
          Continue
        </Button>
      </form>
    </div>
  );
};

export default SecondAuthForm;
