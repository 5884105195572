/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronDownSolidV3 = (props) => (
  <Icon {...props} name="Chevron Down Solid V3">
    <path d="M7.378 12.597.257 5.476a.88.88 0 0 1 0-1.244l.831-.83a.878.878 0 0 1 1.242-.001L8 9.044l5.67-5.643a.88.88 0 0 1 1.242.001l.83.83a.88.88 0 0 1 0 1.244l-7.121 7.121a.88.88 0 0 1-1.244 0z" />
  </Icon>
);

export default ChevronDownSolidV3;
