import React, { useState } from "react";
import PropTypes from "prop-types";

import ConfirmTrackingNumber from "./ConfirmTrackingNumber";
import NumberSelection from "./NumberSelection";
import ConfirmDeleteTrackingNumber from "./ConfirmDeleteTrackingNumber";
import TrackingNumberDetailsModal from "./TrackingNumberDetailsModal";
import { CountryCodes } from "./shared";

const TrackingNumberModal = ({
  isNewNumber,
  trackingNumber,
  agents,
  agentId,
  sources,
  onNumbersChanged,
  onClose,
  isPlace,
}) => {
  const [countryCode, setCountryCode] = useState(CountryCodes[0]);
  const [areaCode, setAreaCode] = useState("");
  const [generateTollFree, setGenerateTollFree] = useState(false);
  const [numberOptions, setNumberOptions] = useState([]);
  const [name, setName] = useState(trackingNumber.name || "");
  const [createLead, setCreateLead] = useState(
    trackingNumber.createLead !== undefined ? trackingNumber.createLead : true,
  );
  const [chosenNumber, setChosenNumber] = useState({});
  const [source, setSource] = useState(trackingNumber.source ?? "");
  const [selectedUsers, setSelectedUsers] = useState(trackingNumber.users ?? []);
  const [noAnswerSetting, setNoAnswerSetting] = useState(trackingNumber.noAnswerSetting || "voicemail");
  const [routingMethod, setRoutingMethod] = useState(trackingNumber.routingMethod || "first_to_claim");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [isNumberConfirmed, setIsNumberConfirmed] = useState(false);

  const confirmationModalShowing = showConfirmationModal || showDeleteConfirmationModal;
  const showNumberSelection = isNewNumber && !isNumberConfirmed && !confirmationModalShowing;
  const showDetailsModal = (isNumberConfirmed || !isNewNumber) && !confirmationModalShowing;

  const [voicemailGreetingChanged, setVoicemailGreetingChanged] = useState(false);
  const [voicemailGreetingFile, setVoicemailGreetingFile] = useState(
    trackingNumber.voicemailGreetingUrl || null,
  );
  const [voicemailGreetingFilename, setVoicemailGreetingFilename] = useState(
    trackingNumber.voicemailGreeting || null,
  );

  const confirmTrackingNumber = () => {
    setIsNumberConfirmed(true);
  };

  const purchaseNumber = () => {
    setShowConfirmationModal(true);
  };

  const beginDeletionConfirmation = () => {
    setShowDeleteConfirmationModal(true);
  };

  const chooseNotToDelete = () => {
    setShowDeleteConfirmationModal(false);
  };

  const backToNumberSelection = () => {
    setIsNumberConfirmed(false);
  };

  return (
    <>
      {showNumberSelection && (
        // show the number selection modal
        <NumberSelection
          agentId={agentId}
          chosenNumber={chosenNumber}
          setChosenNumber={setChosenNumber}
          onConfirmNumber={confirmTrackingNumber}
          onClose={onClose}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          areaCode={areaCode}
          setAreaCode={setAreaCode}
          generateTollFree={generateTollFree}
          setGenerateTollFree={setGenerateTollFree}
          numberOptions={numberOptions}
          setNumberOptions={setNumberOptions}
        />
      )}
      {showDetailsModal && (
        <TrackingNumberDetailsModal
          isNewNumber={isNewNumber}
          onClose={onClose}
          trackingNumber={trackingNumber}
          sources={sources}
          agents={agents}
          beginDeletionConfirmation={beginDeletionConfirmation}
          onNumbersChanged={onNumbersChanged}
          routingMethod={routingMethod}
          setRoutingMethod={setRoutingMethod}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          noAnswerSetting={noAnswerSetting}
          setNoAnswerSetting={setNoAnswerSetting}
          voicemailGreetingChanged={voicemailGreetingChanged}
          setVoicemailGreetingChanged={setVoicemailGreetingChanged}
          voicemailGreetingFile={voicemailGreetingFile}
          setVoicemailGreetingFile={setVoicemailGreetingFile}
          voicemailGreetingFilename={voicemailGreetingFilename}
          setVoicemailGreetingFilename={setVoicemailGreetingFilename}
          createLead={createLead}
          setCreateLead={setCreateLead}
          name={name}
          setName={setName}
          source={source}
          setSource={setSource}
          chosenNumber={chosenNumber}
          purchaseNumber={purchaseNumber}
          backToNumberSelection={backToNumberSelection}
          isPlace={isPlace}
        />
      )}
      {showConfirmationModal && (
        <ConfirmTrackingNumber
          onClose={onClose}
          name={name}
          agents={selectedUsers}
          number={chosenNumber}
          routingMethod={routingMethod}
          sourceId={source}
          shouldCreateLeads={createLead}
          noAnswerSetting={noAnswerSetting}
          voicemailGreetingFile={voicemailGreetingFile}
          voicemailGreetingFilename={voicemailGreetingFilename}
          onConfirmBuy={onNumbersChanged}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmDeleteTrackingNumber
          trackingNumberId={trackingNumber.id}
          chooseNotToDelete={chooseNotToDelete}
          onClose={onClose}
          onConfirmDelete={onNumbersChanged}
        />
      )}
    </>
  );
};

TrackingNumberModal.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  agentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  trackingNumber: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createLead: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    routingMethod: PropTypes.string.isRequired,
  }).isRequired,
  onNumbersChanged: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isNewNumber: PropTypes.bool.isRequired,
};

export default TrackingNumberModal;
