import React from "react";
import { Button, Radio, FormGroup, Col, Row, Input, CancelButton } from "../../shared/v1";
import Error from "../../shared/Error";
import capitalize from "capitalize";
import "./Milestones.scss";

class MilestonesForm extends React.Component {
  milestoneFormPath = () => {
    const { milestonesCreatePath, milestonesUpdatePath } = this.props;
    return milestonesCreatePath || milestonesUpdatePath;
  };

  render() {
    const {
      onCloseAction,
      onFormChange,
      onSubmitAction,
      isSubmitting,
      hasError,
      errorMessage,
      milestonesUpdatePath,
      projectsEnabled,
    } = this.props;
    const milestoneForm = this.props.milestoneForm || {};
    const name =
      milestoneForm.name &&
      milestoneForm.name
        .split("_")
        .map((element) => capitalize(element))
        .join(" ");
    const enabled = projectsEnabled;

    return (
      <div className="milestones-index">
        {hasError && <Error errorMessage={errorMessage} />}

        <p className="form-description">
          You can add a new custom date and assign it to people, transactions or projects. These custom dates
          will appear on detail pages as well as email and text placeholders.
        </p>

        <FormGroup horizontal>
          <Col size={12}>
            <label>Date Name</label>
            <Input
              size="small"
              autoFocus
              name="name"
              onChange={(e) => onFormChange(e.target.name, e.target.value)}
              onKeyDown={(e) => e.keyCode == 13 && onSubmitAction(this.milestoneFormPath(), milestoneForm)}
              value={name || ""}
            />
          </Col>
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <Row>
              <Col size={12}>
                <label>Type</label>
              </Col>
            </Row>
            <Row>
              <Col size={3}>
                <Radio
                  name="milestonable_type"
                  className="m-r-10"
                  labelClassName="tw-text-[14px] tw-text-gray-base"
                  value="Person"
                  inline={true}
                  checked={milestoneForm.milestonable_type === "Person"}
                  onChange={(e) => onFormChange("milestonable_type", "Person")}
                >
                  <div className="milestone-form-radio-text">Person</div>
                </Radio>
              </Col>
              <Col size={3}>
                <Radio
                  name="milestonable_type"
                  className="m-r-10"
                  labelClassName="tw-text-[14px] tw-text-gray-base"
                  value="Listing"
                  inline={true}
                  checked={milestoneForm.milestonable_type === "Listing"}
                  onChange={(e) => onFormChange("milestonable_type", "Listing")}
                >
                  <div className="milestone-form-radio-text">Transaction</div>
                </Radio>
              </Col>
              {projectsEnabled && (
                <Col size={3}>
                  <Radio
                    name="milestonable_type"
                    className="m-r-10"
                    labelClassName="tw-text-[14px] tw-text-gray-base"
                    value="Project"
                    inline={true}
                    checked={milestoneForm.milestonable_type === "Project"}
                    onChange={(e) => onFormChange("milestonable_type", "Project")}
                  >
                    <div className="milestone-form-radio-text">Project</div>
                  </Radio>
                </Col>
              )}
            </Row>
          </Col>
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <Row>
              <Col size={12}>
                <label>Include time?</label>
              </Col>
            </Row>
            <Row>
              <Col size={3}>
                <Radio
                  name="include_time"
                  className="m-r-10"
                  labelClassName="tw-text-[14px] tw-text-gray-base"
                  value={true}
                  inline={true}
                  checked={milestoneForm.include_time}
                  onChange={(e) => onFormChange("include_time", true)}
                >
                  <div className="milestone-form-radio-text">Yes</div>
                </Radio>
              </Col>
              <Col size={3}>
                <Radio
                  name="include_time"
                  className="m-r-10"
                  labelClassName="tw-text-[14px] tw-text-gray-base"
                  value={false}
                  inline={true}
                  checked={!milestoneForm.include_time}
                  onChange={(e) => onFormChange("include_time", false)}
                >
                  <div className="milestone-form-radio-text">No</div>
                </Radio>
              </Col>
            </Row>
          </Col>
        </FormGroup>

        {milestoneForm.milestonable_type == "Listing" && (
          <FormGroup horizontal>
            <Col size={12}>
              <Row>
                <Col size={12}>
                  <label>Visible on the client portal?</label>
                </Col>
              </Row>
              <Row>
                <Col size={3}>
                  <Radio
                    name="visible"
                    className="m-r-10"
                    labelClassName="tw-text-[14px] tw-text-gray-base"
                    value={true}
                    inline={true}
                    checked={milestoneForm.visible}
                    onChange={(e) => onFormChange("visible", true)}
                  >
                    <div className="milestone-form-radio-text">Yes</div>
                  </Radio>
                </Col>
                <Col size={3}>
                  <Radio
                    name="visible"
                    className="m-r-10"
                    labelClassName="tw-text-[14px] tw-text-gray-base"
                    value={false}
                    inline={true}
                    checked={!milestoneForm.visible}
                    onChange={(e) => onFormChange("visible", false)}
                  >
                    <div className="milestone-form-radio-text">No</div>
                  </Radio>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        )}

        <FormGroup className="m-t-30 milestones-modal-buttons" horizontal>
          <Col size={12}>
            <CancelButton size="small" className="modal-cancel" onClick={() => onCloseAction()} />
            <Button
              size="small"
              variant="info"
              className="tw-float-right"
              disabled={isSubmitting}
              onClick={() => onSubmitAction(this.milestoneFormPath(), milestoneForm)}
            >
              Save
            </Button>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default MilestonesForm;
