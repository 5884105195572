import { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { useDebounce } from "@shared/hookHelpers";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const useLeadSearch = () => {
  const currentUser = useCurrentUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const debouncedSearch = useDebounce(searchTerm.trim(), 500);

  useEffect(() => {
    if (!debouncedSearch) return () => {};
    const abortController = new AbortController();
    setLoading(true);
    caseTransformingAxios
      .get("/api/v3/leads/search", {
        params: { search: debouncedSearch },
        signal: abortController.signal,
      })
      .then((res) => {
        setLoading(false);
        setData(
          res.data.people
            .filter((p) => p.uuid !== currentUser.person.uuid)
            .map((person) => {
              const alias = [person.firstName, person.lastName].filter(Boolean).join(" ") || "No Name";
              return {
                label: alias,
                value: person.uuid,
                meta: {
                  ...person,
                  alias,
                  phoneNumber: person.phoneNumber ? formatPhoneNumber(person.phoneNumber) : null,
                },
              };
            }),
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    return () => abortController.abort();
  }, [debouncedSearch]);

  return { actions: { setSearchTerm: (s) => setSearchTerm((s || "").trim()) }, data, loading, searchTerm };
};

export default useLeadSearch;
