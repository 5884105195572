import React from "react";

// Copied from:
// https://gitlab.brivity.com/brivity/notifications/-/blob/master/src/app/views/listing_alerts/_property.html.erb
const EmailBodyProperty = ({
  photos,
  listingUrl,
  streetAddress,
  city,
  state,
  postalCode,
  formattedPrice,
  bedrooms,
  bathsTotalDecimal,
}) => {
  return (
    <>
      <table
        className="wrapper"
        role="module"
        data-type="image"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        style={{ tableLayout: "fixed", backgroundColor: "#ffffff" }}
        data-muid="pokxcMaDqFDHfFkYNDFrDY.1.1.2"
      >
        <tbody>
          <tr>
            <td
              style={{ fontSize: "6px", lineHeight: "10px", padding: "0px 0px 0px 0px" }}
              valign="top"
              align="center"
            >
              <a href={listingUrl} target="_blank">
                <img
                  className="max-width"
                  border="0"
                  style={{
                    display: "block",
                    color: "#000000",
                    textDecoration: "none",
                    fontFamily: "Helvetica, arial, sans-serif",
                    fontSize: "16px",
                  }}
                  src={photos[0].emailUrl}
                  alt={streetAddress}
                  width="528"
                  data-proportionally-constrained="true"
                  height=""
                  data-responsive="false"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="module"
        role="module"
        data-type="text"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        style={{
          tableLayout: "fixed",
          backgroundColor: "#ffffff",
          borderCollapse: "separate",
          paddingBottom: "20px",
        }}
        data-muid="qAdD4M1JBFSGC8qYbBd7a.1.4"
        data-mc-module-version="2019-10-22"
      >
        <tbody>
          <tr>
            <td
              style={{ padding: "5px 0px 0px 0px", lineHeight: "22px", textAlign: "inherit" }}
              height="100%"
              valign="top"
              bgcolor=""
              role="module-content"
            >
              <div>
                <div style={{ fontFamily: "inherit", textAlign: "center" }}>
                  <strong>
                    <a
                      href={listingUrl}
                      target="_blank"
                      style={{
                        fontFamily: "arial, helvetica, sans-serif",
                        color: "#333333",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      {streetAddress}
                    </a>
                  </strong>
                  <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
                    <br />
                  </span>
                  <span style={{ fontFamily: "arial, helvetica, sans-serif", fontSize: "14px" }}>
                    {city}, {state} {postalCode}
                  </span>
                  <span style={{ fontFamily: "arial, helvetica, sans-serif" }}>
                    <br />
                  </span>
                  <span
                    style={{ fontFamily: "arial, helvetica, sans-serif", fontSize: "14px", color: "#339933" }}
                  >
                    <strong>{formattedPrice}</strong>
                  </span>
                  <span>
                    &nbsp;|&nbsp; {bedrooms} bed&nbsp; |&nbsp;&nbsp;{bathsTotalDecimal} bath
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EmailBodyProperty;
