import curriedNamespaceAction from "../../../reducers/helpers";

const REDUCER_NAME = "TDP_HEADER";
const wrap = curriedNamespaceAction(REDUCER_NAME);

export const saveDetailsFromHeader = (details) => ({ type: "SAVE_DETAILS_FROM_HEADER", details });

export const saveAllDetails = (details, uuid) => wrap({ type: "SAVE_ALL_DETAILS", details, uuid });

export const saveDetailFromHeader = (key, value, group) =>
  wrap({
    type: "SAVE_DETAIL_FROM_HEADER",
    key,
    value,
    group,
  });

export const setImportModalOpen = (isImportModalOpen, preStatus) =>
  wrap({ type: "SET_IMPORT_MODAL_OPEN", isImportModalOpen, preStatus });

export const saveDetail = (key, value, group) => wrap({ type: "SAVE_DETAIL", key, value, group });

export const saveDetails = (details) => wrap({ type: "SAVE_DETAILS", details });

export const deleteListing = (id) => wrap({ type: "DELETE_LISTING", id });

export const archiveListing = (id) => wrap({ type: "ARCHIVE_LISTING", id });

export const referralDesiredAddressEditOn = () => wrap({ type: "SET_REF_DESIRED_ADDRESS_EDIT_ON" });

export const referralEditTitleModalOn = () => wrap({ type: "SET_REF_MODAL_TITLE_RENAME_ON" });

export const referralEditTitleModalOff = () => wrap({ type: "SET_REF_MODAL_TITLE_RENAME_OFF" });

export const referralDesiredAddressEditOff = () => wrap({ type: "SET_REF_DESIRED_ADDRESS_EDIT_OFF" });

export const referralTimeFrameEditOn = () => wrap({ type: "SET_REF_TIMEFRAME_EDIT_ON" });

export const referralCalendarEditOn = (option) => wrap({ type: "SET_REF_CALENDAR_EDIT_ON", option });

export const referralCalendarEditCancel = (option) => wrap({ type: "SET_REF_CALENDAR_EDIT_CANCEL", option });

export const editReferralDesiredAddress = (address) => wrap({ type: "REF_DESIRED_ADDRESS_EDIT", address });

export const editIsReferralFeePercentage = (bool) => wrap({ type: "REF_IS_REF_FEE_PERC_EDIT", bool });

export const editTimeframeExact = (option, exactDate) =>
  wrap({
    type: "SET_TIMEFRAME_EXACT",
    option,
    exactDate,
  });

export const editReferralTransactionAmt = (transactionAmt) =>
  wrap({
    type: "EDIT_REF_TRANSACTION_AMT",
    transactionAmt,
  });

export const editReferralFee = (payload) =>
  wrap({
    type: "EDIT_REF_REFERRAL_FEE",
    ...payload,
  });

export const editReferralStatus = (status) =>
  wrap({
    type: "EDIT_REF_STATUS",
    status,
  });

export const editReferralTitle = (title) =>
  wrap({
    type: "EDIT_REF_TITLE",
    title,
  });

export const isUpdateAddressModal = (boolean, tempUpdatedStatus, tempPreStatus) =>
  wrap({
    type: "OPEN_UPDATE_ADDRESS_MODAL",
    boolean,
    tempUpdatedStatus,
    tempPreStatus,
  });

export const editAddressFromPipeline = (details) =>
  wrap({
    type: "EDIT_ADDRESS_FROM_PIPELINE",
    details,
  });
