import React from "react";
import { bool, func } from "prop-types";

import { connect } from "react-redux";

import { createTasksByTeamMemberFilter, clearTeamMemberFilter } from "../actions/creators";
import { asyncSetTasksByTeamMemberFilter } from "../actions/thunks";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import TeamMembersInModal from "../TeamMembersInModal";

import modalToggleHandlr from "../handlrs";

const ModalSecondaryFilterMember = ({
  show,
  setModalTeamMemberActive,
  onCreateTasksByTeamMemberFilter,
  onClearTeamMemberFilter,
  onSetBackFilterByTeamMembers,
}) => (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    onHide={() =>
      modalToggleHandlr(onSetBackFilterByTeamMembers, setModalTeamMemberActive, onClearTeamMemberFilter)
    }
    closeButton
  >
    <Modal.Header title="Filter by Team Members" className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px">
      <TeamMembersInModal />
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row">
      <Button
        className="tw-mr-auto"
        size="medium"
        schema="tertiary"
        onClick={() =>
          modalToggleHandlr(onSetBackFilterByTeamMembers, setModalTeamMemberActive, onClearTeamMemberFilter)
        }
      >
        CANCEL
      </Button>
      <Button className="tw-mr-8px" size="medium" schema="secondary" onClick={onClearTeamMemberFilter}>
        CLEAR
      </Button>
      <Button
        size="medium"
        schema="primary"
        onClick={() => modalToggleHandlr(onCreateTasksByTeamMemberFilter, setModalTeamMemberActive)}
      >
        APPLY
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapStateToProps = (state) => ({
  activeTeamMembers: state.tdpTasksReducer.secondaryFilters.activeTeamMembers,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateTasksByTeamMemberFilter: () => dispatch(createTasksByTeamMemberFilter()),
  onClearTeamMemberFilter: () => dispatch(clearTeamMemberFilter()),
  onSetBackFilterByTeamMembers: () => dispatch(asyncSetTasksByTeamMemberFilter()),
});

ModalSecondaryFilterMember.propTypes = {
  show: bool,
  setModalTeamMemberActive: func,
  onCreateTasksByTeamMemberFilter: func,
  onClearTeamMemberFilter: func,
  onSetBackFilterByTeamMembers: func,
};

ModalSecondaryFilterMember.defaultProps = {
  show: false,
  setModalTeamMemberActive: () => {},
  onCreateTasksByTeamMemberFilter: () => {},
  onClearTeamMemberFilter: () => {},
  onSetBackFilterByTeamMembers: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSecondaryFilterMember);
