import React from "react";
import PropTypes from "prop-types";
import {
  HouseChimneySolidV6,
  HouseSolidV6,
} from "@shared/v2/Icomoon";
import { formatPrice } from "../../../SearchListings/components/helpers";
import { format } from "date-fns";

const AddressStatusChangeEvent = ({ event }) => {
  const {
    alert_type: alertType,
    list_price: listPrice,
    street_address: address,
    standard_address: standardAddress,
    status,
    previous_status: previousStatus,
    image,
    status_change_date: statusChangeDate
  } = event.timeline_fields;

  const formattedStatusChangeDate = formatDate(statusChangeDate);

  return (
    <div className="timeline-event">
      <div className="row">
        <div className="col-xs-1">
          <span className="timeline-icon-circle tw-inline-flex tw-justify-center tw-items-center">
            <HouseSolidV6 size="l" />
          </span>
        </div>
        <div className="col-xs-8">
          <div>
            <span className="bold-detail">Address Information</span>
            {` ${alertType === "new" ? "added" : "updated"} for `}
            <span className="bold-detail">{address || standardAddress}</span>
          </div>
          <div className="timeline-description-text">
            {alertType === "new" ? <NewListingStatusText status={status} /> : <StatusText previousStatus={previousStatus} status={status} />}
            <div className="tw-mt-16px">
              <span>List Price: {formatPrice(listPrice) || "--"}</span>
            </div>
            {formattedStatusChangeDate && (
              <div>
                <span>System notified of status change on {formattedStatusChangeDate}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-3 time-ago">
        <span>{event.interacted_at_absolute}</span>
          <div className="tw-mt-18px">
            {image ? <img src={addConstraintsToImageUrl(image)} alt="Property image" /> : NoImageDisplay}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressStatusChangeEvent;

const formatDate = (date) => {
  try {
    return format(new Date(date), "M/d/yyyy");
  } catch (e) {
    return null;
  }
};

AddressStatusChangeEvent.propTypes = {
  event: PropTypes.shape({
    interacted_at_absolute: PropTypes.string,
    timeline_fields: PropTypes.shape({
      alert_type: PropTypes.string,
      list_price: PropTypes.number,
      street_address: PropTypes.string,
      standard_address: PropTypes.string,
      status: PropTypes.string,
      previous_status: PropTypes.string,
      image: PropTypes.string,
      status_change_date: PropTypes.string
    })
  })
};

const NoImageDisplay = (
  <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-[144px] tw-h-[103px] tw-bg-gray-15">
    <HouseChimneySolidV6 size="xxxl" />
  </div>
);

const StatusText = ({status, previousStatus}) => {
  return (
    <div>
      <span>MLS status changed from </span>
      <span className={getListingStatusColors(previousStatus).text}>{previousStatus}</span>
      {" to "}
      <span className={getListingStatusColors(status).text}>{status}</span>
      .
    </div>
  );
};

const NewListingStatusText = ({status}) => {
  return (
    <div>
      <span>MLS status set to </span>
      <span className={getListingStatusColors(status).text}>{status}</span>
      .
    </div>
  );
};

const addConstraintsToImageUrl = (url) => `${url}&height=103&width=144&quality=50&mode=scale`;

const getListingStatusColors = (status) => {
  switch (status) {
    case "Active":
    case "Coming Soon":
      return { text: "tw-text-[#36b25f]", background: "tw-bg-[#EDFAF1]" };
    case "Pending":
    case "Active Under Contract":
      return { text: "tw-text-[#e5941b]", background: "tw-bg-[#faf1e6]" };
    case "Closed":
      return { text: "tw-text-[#e5342e]", background: "tw-bg-[#fae6e6]" };
    case "Withdrawn":
    default:
      return { text: "tw-text-[#666666]", background: "tw-bg-[#f5f7f7]" };
  }
};
