/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronLeft = (props) => (
  <Icon {...props} name="Chevron Left">
    <path d="M3.673 7.415 10.375.713a.827.827 0 0 1 1.17 0l.782.782a.827.827 0 0 1 .001 1.169L7.017 8l5.311 5.336a.828.828 0 0 1-.001 1.169l-.782.782a.827.827 0 0 1-1.17 0L3.673 8.585a.827.827 0 0 1 0-1.17z" />
  </Icon>
);

export default ChevronLeft;
