import React, { useRef } from "react";
import PropTypes from "prop-types";
import FloatingForm from "../../../shared/v2/Modal/FloatingForm";
import NumberField from "../../../Goals/components/NumberField";
import useGoals from "../../../Goals/services/useGoals";

const GoalModal = ({ open, year, goalType, onClose, onGoalCreated }) => {
  const { actions } = useGoals({ year, load: false });
  const formRef = useRef();
  const onFormSubmit = (e) => {
    e?.preventDefault();
    const form = formRef.current;
    const formData = Object.fromEntries(new FormData(form));
    formData.goal_value = formData.goal_value.replaceAll(",", "");
    if (goalType.goalId) {
      actions.updateGoal(formData).then(onGoalCreated);
    } else {
      actions.createGoal(formData).then(onGoalCreated);
    }
  };
  return (
    <FloatingForm
      title={`Set goal for ${goalType?.displayName}`}
      show={open}
      onHide={onClose}
      onCancel={onClose}
      primaryOption="Set Goal"
      onPrimary={onFormSubmit}
      closeOnClickOutside
      closeOnEscape
    >
      <form ref={formRef} onSubmit={onFormSubmit}>
        <NumberField id="goal_value" name="goal_value" required format={goalType?.format} />
        <input type="hidden" name="id" value={goalType?.goalId || ""} />
        <input type="hidden" name="goal_type_id" value={goalType?.goalTypeId || ""} />
        <input type="hidden" name="year" value={year || ""} />
      </form>
    </FloatingForm>
  );
};

export default GoalModal;

GoalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  goalType: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onGoalCreated: PropTypes.func.isRequired,
};

GoalModal.defaultProps = {
  goalType: null,
};
