import React, { useEffect, useState, useRef, useMemo } from "react";

import Table from "./Table";
import { getSavedSearches, usePropertySearch } from "./services";
import { useViewSize } from "../../SearchListings/components/customHooks";
import Map from "./Map";
import Button from "@shared/v2/Button";
import FiltersModal from "./FiltersModal";
import Search from "./Search";
import Options from "./Options";
import EmptyState from "./EmptyState";
import { getDefaultFilters, countFiltersApplied } from "./filters";
import { ArrowUpRightFromSquareSolidV6, Check } from "@shared/v2/Icomoon";
import { formatNumber } from "../../SearchListings/components/helpers";
import PropertyDetails from "./PropertyDetails";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import { getSources } from "../../PhoneSystem/TrackingNumbers/Settings/services";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import { format } from "date-fns";
import PropertyDialerModal from "./Dialer/PropertyDialerModal";
import ProspectDialer from "./Dialer/ProspectDialer";
import SaveSearch from "./SaveSearch";
import DeleteSearch from "./DeleteSearch";
import Alert from "@shared/v2/Alert";
import TextButton from "@shared/v2/TextButton";
import LinkBar from "./LinkBar";
import { SearchTypeConfig } from "./helpers";

const Opportunities = () => {
  const searchBarRef = useRef(null);
  const propertiesContainer = useRef(null);
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const [searchType, setSearchType] = useState("expireds");
  const [locationQuery, setLocationQuery] = useState(null);
  const [filters, setFilters] = useState(getDefaultFilters());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { properties, radius, isSearching, error, updateProspectLeadStatus } = usePropertySearch(
    locationQuery,
    filters,
    searchType,
    setIsFirstLoad,
    featureFlags.opportunitiesTestingData,
  );
  const [selected, setSelected] = useState([]);
  const [highlightedProperty, setHighlightedProperty] = useState(null);
  const [propertyForDetails, setPropertyForDetails] = useState(null);
  const [, viewHeight] = useViewSize();
  const [propertyContainerHeight, setPropertyContainerHeight] = useState(0);
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [prospectToAdd, setProspectToAdd] = useState(null);
  const [propertyToCall, setPropertyToCall] = useState(null);
  const [newLeadSourceId, setNewLeadSourceId] = useState(null);
  const [prospectWithPropertyToCall, setProspectWithPropertyToCall] = useState(null);
  const [savedSearches, setSavedSearches] = useState([]);
  const [saveSearch, setSaveSearch] = useState(false);
  const [searchToDelete, setSearchToDelete] = useState(null);
  const [toastText, setToastText] = useState("");
  const [currentSort, setCurrentSort] = useState({ field: "statusDate", order: "desc" });

  const filterCount = useMemo(() => countFiltersApplied(filters), [filters]);
  const isInitialState = locationQuery === null;
  const isEmptyState = properties?.length === 0;
  const searchConfig = SearchTypeConfig[searchType];

  const trainingLink = "https://www.brivityknowledge.com/docs/expired-listing-opportunities";

  const onSelectProperty = ({ id }) => {
    const updatedSelect = selected.includes(id)
      ? selected.filter((selectedId) => id !== selectedId)
      : [...selected, id];

    setSelected(updatedSelect);
  };

  const onSelectAllProperties = () => {
    const updatedSelect = properties.length === selected.length ? [] : properties.map(({ id }) => id);

    setSelected(updatedSelect);
  };

  const onApplyFilters = (selectedFilters) => {
    setShowFilterModal(false);
    setFilters(selectedFilters);
  };

  const addProspectAsContact = (property, prospect) => {
    const newLead = {
      primaryAgentId: currentUser.id,
      isLead: true,
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      leadType: "seller",
      status: "new",
      sourceId: newLeadSourceId,
      stage: "New lead",
      typeName: "lead",
      addressesAttributes: [
        {
          addressType: "home",
          city: property.city,
          postalCode: property.postalCode,
          streetAddress: property.streetAddress,
          locality: property.state,
        },
      ],
    };

    if (prospect.phone) {
      newLead.phoneDetailsAttributes = [
        {
          category: "mobile",
          name: "phone",
          position: 1,
          value: prospect.phone,
        },
      ];
    }

    setProspectToAdd(newLead);
  };

  const clearProspectCall = () => {
    setProspectWithPropertyToCall(null);
  };

  const selectSavedSearch = ({ search }) => {
    setLocationQuery(search.locationQuery);
    setFilters(search.filters);
  };

  const onSaveSearchClosed = (wasSearchSaved) => {
    setSaveSearch(false);

    if (wasSearchSaved) {
      showADisappearingToast("Your search has been saved successfully.");
      fetchSavedSearches();
    }
  };

  const onDeleteSearchClosed = (wasSearchDeleted) => {
    setSearchToDelete(null);

    if (wasSearchDeleted) {
      showADisappearingToast("Your saved search has successfully been deleted.");
      fetchSavedSearches();
    }
  };

  const fetchSavedSearches = () =>
    getSavedSearches(currentUser.id, searchType).then((s) => setSavedSearches(s));

  const showADisappearingToast = (text) => {
    setToastText(text);

    setTimeout(() => {
      setToastText("");
    }, 7_000);
  };

  const onProspectAddedAsLead = (number) => {
    updateProspectLeadStatus(number);
  };

  const changeSearchType = (view) => {
    setSearchType(view);
    setLocationQuery(null);
    setFilters(getDefaultFilters());
    setIsFirstLoad(true);
  };

  useEffect(() => {
    const get = async () => {
      fetchSavedSearches();
      const { sources } = await getSources();

      setNewLeadSourceId(sources.find((s) => s.label === searchConfig.sourceName)?.value);
    };

    get();
  }, [searchType]);

  useEffect(() => {
    const unavailableSpace = propertiesContainer?.current?.getBoundingClientRect()?.top;

    setPropertyContainerHeight(viewHeight - unavailableSpace);
  }, [viewHeight, isSearching]);

  return (
    <div className="tw-bg-white tw-h-full tw-flex tw-flex-col tw-gap-16px">
      <div className="tw-flex tw-pr-16px tw-pt-24px tw-pl-24px tw-items-center">
        <div className="tw-flex tw-flex-row tw-gap-16px">
          <div className="tw-flex tw-flex-col tw-gap-8px">
            <h1 className="tw-my-0 tw-text-28px tw-text-gray-75">Property Search</h1>
            <span
              className={`tw-text-14px tw-text-gray-50 ${locationQuery && !isSearching ? "" : "tw-invisible"}`}
            >
              {properties.length
                ? `${formatNumber(properties.length)} ${searchConfig.typeName} results`
                : "No results"}
            </span>
          </div>
          <div className="tw-border-0 tw-border-l tw-border-solid tw-border-gray-10 tw-h-65px"></div>
          <LinkBar
            currentView={searchType}
            onClickView={changeSearchType}
            preforeclosuresEnabled={featureFlags.opportunitiesPreforeclosures}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8px tw-ml-auto">
          <div className="tw-flex tw-flex-row tw-justify-end tw-gap-8px">
            <Actions selected={selected} properties={properties} />
            <Button
              schema={filterCount ? "primary" : "secondary"}
              onClick={() => setShowFilterModal(true)}
              size="medium"
              disabled={isInitialState}
            >
              {`Filters${filterCount > 0 ? " (" + filterCount + ")" : ""}`}
            </Button>
          </div>
          <div
            className="tw-cursor-pointer tw-text-12px tw-font-normal tw-text-theme-text-button-color hover:tw-text-theme-text-button-color-hover"
            onClick={() => window.open(trainingLink, "_blank")}
          >
            <div className="tw-flex tw-flex-row tw-gap-6px tw-items-center">
              <span>Learn more about Opportunities</span>
              <ArrowUpRightFromSquareSolidV6 />
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-pr-16px tw-pl-24px tw-gap-12px tw-h-full">
        <Search
          setLocation={(location) => setLocationQuery(location)}
          searchBarRef={searchBarRef}
          savedSearches={savedSearches}
          deleteSearch={(s) => setSearchToDelete(s)}
          selectSavedSearch={selectSavedSearch}
        />
        {!isFirstLoad && (
          <Options
            location={locationQuery}
            setLocation={setLocationQuery}
            filters={filters}
            setFilters={setFilters}
            isMapVisible={isMapVisible}
            setIsMapVisible={setIsMapVisible}
            saveSearch={() => setSaveSearch(true)}
            currentSort={currentSort}
            setCurrentSort={setCurrentSort}
            config={searchConfig}
          />
        )}
        {isFirstLoad ? (
          <EmptyState
            isInitialState={isInitialState}
            onClickSearchButton={() => searchBarRef.current?.focus()}
          />
        ) : (
          <div className="tw-flex tw-flex-row">
            {isSearching ? (
              <Loading
                height={propertyContainerHeight}
                arePropertiesLoaded={properties.length > 0}
                isMapVisible={isMapVisible}
              />
            ) : null}
            {isMapVisible && (
              <div className="tw-flex tw-flex-col tw-w-1/2 tw-mr-20px">
                <Map
                  searchConfig={searchConfig}
                  locationQuery={locationQuery}
                  setLocationQuery={setLocationQuery}
                  properties={properties}
                  radius={radius}
                  selected={selected}
                  onSelect={onSelectProperty}
                  onClickProperty={(propertyId) => setHighlightedProperty(propertyId)}
                  addProspectAsContact={addProspectAsContact}
                  callProspect={setProspectWithPropertyToCall}
                  setPropertyForDetails={setPropertyForDetails}
                />
              </div>
            )}
            <div className={`${isMapVisible ? "tw-w-1/2" : "tw-w-full"} tw-flex tw-flex-col`}>
              <div ref={propertiesContainer}>
                {isEmptyState || error ? (
                  <EmptyState isInitialState={isInitialState} error={error} />
                ) : (
                  <Table
                    properties={properties}
                    selected={selected}
                    onSelect={onSelectProperty}
                    onSelectAll={onSelectAllProperties}
                    availableHeight={propertyContainerHeight}
                    highlightedProperty={highlightedProperty}
                    openPropertyDetails={setPropertyForDetails}
                    callPropertyProspects={(p) => setPropertyToCall(p)}
                    currentSort={currentSort}
                    setCurrentSort={setCurrentSort}
                    config={searchConfig}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showFilterModal && (
        <FiltersModal
          closeModal={() => setShowFilterModal(false)}
          chosenFilters={filters}
          onApply={onApplyFilters}
          config={searchConfig}
        />
      )}
      {propertyForDetails && !prospectToAdd && (
        <PropertyDetails
          property={properties.find((p) => p.id === propertyForDetails)}
          closeModal={() => setPropertyForDetails(null)}
          addProspectAsContact={addProspectAsContact}
          callProspect={setProspectWithPropertyToCall}
          config={searchConfig}
        />
      )}
      {prospectToAdd && (
        <PersonDetailsModal
          onClose={() => {
            setProspectToAdd(null);
            onProspectAddedAsLead(prospectToAdd.phone);
          }}
          person={prospectToAdd}
          containerClassName="!tw-z-[3001]"
          show
        />
      )}
      {propertyToCall && !prospectWithPropertyToCall && (
        <PropertyDialerModal
          property={propertyToCall}
          callProspect={setProspectWithPropertyToCall}
          closeModal={() => setPropertyToCall(null)}
        />
      )}
      {prospectWithPropertyToCall && (
        <ProspectDialer
          property={prospectWithPropertyToCall.property}
          prospect={prospectWithPropertyToCall.prospect}
          agentId={currentUser.id}
          onClose={clearProspectCall}
          onProspectAddedAsLead={onProspectAddedAsLead}
        />
      )}
      {saveSearch && (
        <SaveSearch
          userId={currentUser.id}
          search={{ filters, locationQuery }}
          searchType={searchType}
          onClose={onSaveSearchClosed}
        />
      )}
      {searchToDelete && (
        <DeleteSearch
          userId={currentUser.id}
          searchId={searchToDelete}
          searchType="Expireds"
          onClose={onDeleteSearchClosed}
        />
      )}
      {toastText && <FakeToast text={toastText} clearToast={() => setToastText("")} />}
    </div>
  );
};

export default Opportunities;

const FakeToast = ({ text, clearToast }) => {
  return (
    <Alert
      variant="success"
      containerClass="tw-z-1000 !tw-top-[100px] !tw-fixed !tw-left-1/2 tw--translate-x-1/2"
      role="alert"
      text={
        <div className="tw-flex tw-flex-row">
          <Check size="xl" className="tw-w-16px tw-h-16px tw-mb-[-4px]" />
          <span className="tw-text-nowrap tw-pl-8px tw-pr-16px">{text}</span>
          <TextButton children="Dismiss" schema="success" onClick={clearToast} />
        </div>
      }
    />
  );
};

const Actions = ({ selected, properties }) => {
  const downloadCsv = () => {
    const csv = createCsvString(properties.filter((p) => selected.includes(p.id)));

    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    anchor.target = "_blank";
    anchor.download = `${format(new Date(), "y-LL-dd")}-expireds.csv`;

    anchor.click();
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          schema={selected.length > 0 ? "primary" : "secondary"}
          onClick={() => {}}
          size="medium"
          disabled={selected.length === 0}
        >
          Actions
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverItem onClick={downloadCsv}>Download Records</PopoverItem>
      </PopoverContent>
    </Popover>
  );
};

const Loading = ({ height, arePropertiesLoaded, isMapVisible }) => {
  const paddingRight = !isMapVisible ? 104 : arePropertiesLoaded ? 138 : 124;
  const style = { height: `${height}px`, paddingRight: `${paddingRight}px` };

  return (
    <div
      style={style}
      className="tw-absolute tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-white tw-bg-opacity-50 tw-z-50"
    >
      <i className="tw-text-36px tw-text-gray-50 fa fa-spinner fa-spin" />
    </div>
  );
};

const createCsvString = (properties) => {
  const LineBreak = "\n";
  const header =
    "MLS Number,Status,Status Date,Street Address,City,State,Postal Code,Potential Owner 1 Name,Potential Owner 1 Phone,Potential Owner 1 DNC,Potential Owner 2 Name,Potential Owner 2 Phone,Potential Owner 2 DNC,Potential Owner 3 Name,Potential Owner 3 Phone,Potential Owner 3 DNC,List Price,DOM,Bed,Bath,SQFT,Lot Size (Acres),Year Built,Property Type";

  let csv = header + LineBreak;
  properties.forEach((p) => {
    const values = [
      p.mlsId,
      p.status,
      p.statusDateDisplay,
      p.streetAddress,
      p.city,
      p.state,
      p.postalCode,
      p.prospects?.[0]?.name,
      p.prospects?.[0]?.phoneFormatted,
      p.prospects?.[0]?.isDnc ? "Do Not Call" : "",
      p.prospects?.[1]?.name,
      p.prospects?.[1]?.phoneFormatted,
      p.prospects?.[1]?.isDnc ? "Do Not Call" : "",
      p.prospects?.[2]?.name,
      p.prospects?.[2]?.phoneFormatted,
      p.prospects?.[2]?.isDnc ? "Do Not Call" : "",
      p.listPrice,
      p.daysOnMarket,
      p.beds,
      p.baths,
      p.sqft,
      p.lotSize,
      p.yearBuilt,
      p.propertyType,
    ];

    values.forEach((v) => {
      csv += v ? `"${v}",` : ",";
    });

    csv += LineBreak;
  });

  return csv;
};
