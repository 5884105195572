/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TrashAltSolidV3 = (props) => (
  <Icon {...props} name="Trash Alt Solid V3">
    <path d="M1 2.625V1.75c0-.416.334-.75.75-.75h3.5l.294-.584A.742.742 0 0 1 6.213 0h3.572c.284 0 .544.159.672.416l.294.584h3.5c.416 0 .75.334.75.75v.875a.376.376 0 0 1-.375.375H1.376a.376.376 0 0 1-.375-.375zm13 1.75V14.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5V4.375C2 4.169 2.169 4 2.375 4h11.25c.206 0 .375.169.375.375zM5.5 6.5c0-.275-.225-.5-.5-.5s-.5.225-.5.5v7c0 .275.225.5.5.5s.5-.225.5-.5v-7zm3 0c0-.275-.225-.5-.5-.5s-.5.225-.5.5v7c0 .275.225.5.5.5s.5-.225.5-.5v-7zm3 0c0-.275-.225-.5-.5-.5s-.5.225-.5.5v7c0 .275.225.5.5.5s.5-.225.5-.5v-7z" />
  </Icon>
);

export default TrashAltSolidV3;
