import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

import {
  FLYOUT_ANIMATION_DURATION_MS,
  containerAnimationStyles,
  containerTransitionStyles,
  modalAnimationStyles,
  modalTransitionStyles,
} from "./animationStyles";

import Portal from "../../v1/Portal";
import Backdrop from "./Backdrop";
import FlyoutContextProvider, { FlyoutContext } from "./FlyoutContext";

import FlyoutHeader from "./FlyoutHeader";
import FlyoutBody from "./FlyoutBody";
import IconButton from "../IconButton";
import { Close } from "../Icomoon";

import { FLYOUT_CONTAINER_CLASSES, FLYOUT_MODAL_CLASSES, FLYOUT_CONTENT_CLASSES } from "./Flyout.styles";

/**
 * @summary A Generic Flyout interface, binding state, portal, listeners and allowing any content, including Header and Body.
 * @param {func} onHide - function for what happens clicking outside, cancel or close button.
 * @param {node} header - elements within the header
 * @param {node} children - elements within the body
 * @param {string} className - arbitrary classNames for the outmost component.
 * @param {string} closeOnEscape - Close modal when click ESC.
 * @param {string} closeOnClickOutside - Close modal when click outside.
 * @param {string} showCloseButton - Show Close Button.
 * @param {string} modalTopGap - Margin (px) on top of modal (E.g: Show the navbar on top of modal).
 * */

const Flyout = ({
  show,
  onHide,
  header,
  children,
  className,
  closeOnEscape,
  closeOnClickOutside,
  showCloseButton,
  modalTopGap,
  ...props
}) => {
  const nodeRef = useRef(null);
  const renderFlyoutContainer = (context) => (
    <Portal>
      <Transition
        in={context.show}
        timeout={FLYOUT_ANIMATION_DURATION_MS}
        unmountOnExit
        mountOnEnter
        nodeRef={nodeRef}
      >
        {(modalContainerState) => (
          <div
            className="tw-relative tw-z-3000"
            ref={nodeRef}
            style={{
              ...containerAnimationStyles(FLYOUT_ANIMATION_DURATION_MS),
              ...containerTransitionStyles[modalContainerState],
            }}
          >
            <Backdrop />
            {/* Both of these are rendered inside the FlyoutContext which already provides the keyboard listeners */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              data-cy="modal-content-container-wrapper-n2"
              className={`${FLYOUT_CONTAINER_CLASSES} ${className}`}
              onClick={() => {
                if (closeOnClickOutside) {
                  context.onHide();
                }
              }}
              // Allow arbitrary attributes, mostly for data
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              <div className={FLYOUT_MODAL_CLASSES} data-cy="modal-content-container-wrapper">
                <Transition
                  in={context.show}
                  appear
                  timeout={FLYOUT_ANIMATION_DURATION_MS}
                  unmountOnExit
                  mountOnEnter
                >
                  {(modalState) => (
                    /* This component is meant to be rendered inside a FlyoutContext, which */
                    /* already provides the keyboard listeners so we silence the linter here */
                    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
                    <div
                      style={{
                        ...modalAnimationStyles(FLYOUT_ANIMATION_DURATION_MS),
                        ...modalTransitionStyles[modalState],
                        marginTop: modalTopGap,
                      }}
                      className={FLYOUT_CONTENT_CLASSES}
                      data-cy="modal-content-container"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {showCloseButton && (
                        <div className="tw-absolute tw-right-6 tw-top-6">
                          <IconButton onClick={() => context.onHide()} schema="tertiary">
                            <Close />
                          </IconButton>
                        </div>
                      )}
                      {children}
                    </div>
                  )}
                </Transition>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );

  return (
    <FlyoutContextProvider show={show} onHide={onHide} closeOnEscape={closeOnEscape}>
      <FlyoutContext.Consumer>{(value) => renderFlyoutContainer(value)}</FlyoutContext.Consumer>
    </FlyoutContextProvider>
  );
};
Flyout.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  modalTopGap: PropTypes.string,
};
Flyout.defaultProps = {
  show: false,
  onHide: null,
  header: null,
  children: null,
  className: "",
  closeOnEscape: true,
  closeOnClickOutside: true,
  showCloseButton: true,
  modalTopGap: "0",
};

Flyout.Header = FlyoutHeader;
Flyout.Body = FlyoutBody;

export default Flyout;
