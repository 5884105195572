import { getPostJsonHeaders } from "@shared/axiosHelpers";

import axios from "axios";

export const checkForRoutingNumber = async (agentId) => {
  try {
    await axios.get(`/users/${agentId}/check_for_routing_number`, getPostJsonHeaders());

    return {};
  } catch (e) {
    return createError(e);
  }
};

export const saveManagedNumber = async (agentId, number) => {
  try {
    await axios.post(`/users/${agentId}/save_managed_number`, { number }, getPostJsonHeaders());

    return {};
  } catch (e) {
    return createError(e);
  }
};

const createError = (e) => {
  const error = e.response ? e.response.data.error : "Something went wrong!";

  return { error };
};
