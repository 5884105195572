import React from "react";
import { bool, number, string } from "prop-types";

import Image from "../../../../shared/v2/Icomoon/Icons/Image";
import useToggleAndOptionsHandlr from "./hooks";
import ImageOptions from "../ImageOptions";

const ListingImage = ({ checked, id, url, deleteUrl, highResUrl, isLoading, name }) => {
  const [options, showOptions, toggle, setToggle] = useToggleAndOptionsHandlr(checked);

  return isLoading ? (
    <div
      data-cy="image-upload-processing"
      className="tw-bg-neutral-gray-10 tw-w-[190px] tw-h-[190px] tw-flex tw-flex-col tw-justify-center tw-items-center"
    >
      <Image size="xl" className="tw-mb-8px" />
      <div className="tw-font-bs-sans tw-text-12px tw-font-semibold tw-text-center">
        PROCESSING IMAGE...
        <br />
        {name}
      </div>
    </div>
  ) : (
    <div
      data-cy="listing-image"
      style={{ backgroundImage: `url(${url})` }}
      className="tw-bg-blue-100 tw-w-[190px] tw-h-[190px] tw-bg-no-repeat tw-bg-cover"
      onMouseEnter={() => showOptions(true)}
      onMouseLeave={() => {
        if (!toggle) showOptions(false);
      }}
    >
      <div className={`tw-h-full tw-w-full tw-bg-black/[.5] tw-p-12px ${options ? "tw-flex" : "tw-hidden"}`}>
        <ImageOptions
          checked={checked}
          id={id}
          url={highResUrl}
          setToggle={setToggle}
          showOptions={showOptions}
          deleteUrl={deleteUrl}
        />
      </div>
    </div>
  );
};

ListingImage.propTypes = {
  checked: bool,
  isLoading: bool,
  id: number,
  name: string,
  url: string,
  highResUrl: string,
  deleteUrl: string,
};

ListingImage.defaultProps = {
  checked: false,
  isLoading: false,
  id: null,
  name: "",
  url: "",
  highResUrl: "",
  deleteUrl: null,
};

export default ListingImage;
