import React, { useState } from "react";
import { arrayOf, func, shape } from "prop-types";

import { EmailCCSelectorV2 } from "../ActionFormHelpers";
import { useAutoPlanType } from "../../../reducers/autoPlanEdit";

import { CC_PLACEHOLDERS } from "../../constants";

const CCRadioButtons = ({onSelect, onRemove, ccEmails, ccPeopleUuids, ccRoleUuids}) => {
  const planType = useAutoPlanType();
  const [ccType, setCCType] = useState('people');

  return (
  <EmailCCSelectorV2
    planType={planType}
    label="CC"
    name="ccType"
    type={ccType}
    placeholder={CC_PLACEHOLDERS[ccType]}
    onRadioChange={(e) => setCCType(e.target.value)}
    people={ccPeopleUuids}
    emails={ccEmails}
    roles={ccRoleUuids}
    onOptionSelect={onSelect}
    onOptionRemove={onRemove}
    />
)};

export default CCRadioButtons;

CCRadioButtons.propTypes = {
  onSelect: func.isRequired, 
  onRemove: func.isRequired,
  ccEmails: arrayOf(shape({})), 
  ccPeopleUuids: arrayOf(shape({})),  
  ccRoleUuids: arrayOf(shape({})), 
}

CCRadioButtons.defaultProps = {
  ccEmails: [], 
  ccPeopleUuids: [], 
  ccRoleUuids: []
}