export const widthBar = (item, sum) => (100 * item) / sum;

export const sumOfKey = (array, objkey) => array.reduce((prev, current) => prev + +current[objkey], 0);

export const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const sortByPosition = (arr) =>
  arr.sort((a, b) => {
    if (a.position === b.position) {
      return 0;
    }
    // nulls sort after anything else
    if (a.position === null) {
      return 1;
    }
    if (b.position === null) {
      return -1;
    }
    return a.position - b.position;
  });
