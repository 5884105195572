import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import ButtonToggleOption from "./ButtonToggleOption";

const ButtonToggle = ({ className, name: nameFromProps, value, options, onChange, ...otherProps }) => {
  const name = useGivenOrGeneratedId("button-toggle", nameFromProps);

  return (
    <div
      role="radiogroup"
      className={`
        tw-inline-flex tw-justify-center tw-items-center
        tw-gap-[4px]
        ${className}
      `}
    >
      {options.map((option) => (
        <ButtonToggleOption
          key={option.value}
          name={name}
          label={option.label}
          value={option.value}
          checked={value === option.value}
          onChange={onChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      ))}
    </div>
  );
};

ButtonToggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func,
};

ButtonToggle.defaultProps = {
  className: "",
  name: null,
  onChange: null,
};

export default ButtonToggle;
