import React, { useState } from "react";
import { connect } from "react-redux";
import { arrayOf, shape, string, func } from "prop-types";

import Header from "../../../UI/Header";
import LinkGroup from "../LinkGroup";
import EditBtnCirc from "../EditBtnCirc";

import { putLinkAsThunk } from "../actions/thunks";

import ModalAddLink from "../ModalAddLink";
import TextButton from "../../../../shared/v2/TextButton";
import { Plus } from "../../../../shared/v2/Icomoon";

const LinksWrapper = ({ listingUuid, links, propertyPagePath, onSaveLinkAsThunk }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const urlOrigin = window.location.origin;

  const linksData = (newLink) => [...links, newLink];
  const addLink = (newLink) =>
    onSaveLinkAsThunk({
      uuid: listingUuid,
      key: "links",
      value: linksData(newLink),
      inOption: "listing_links_attributes",
      group: "links",
    });

  return (
    <div className="tw-pb-[32px] tw-mb-[32px] tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-1 tw-border-solid tw-border-neutral-gray-10">
      <ModalAddLink isModalOpen={isModalOpen} toggleModal={toggleModal} onSave={addLink} />
      <Header header="Links" add={<EditBtnCirc />} />
      <LinkGroup enablePeople title="Property Page" link={`${urlOrigin}${propertyPagePath}`} />
      {links.map(({ title, url, id }) => (
        <LinkGroup key={`${title}-${url}-${id}`} title={title} link={url} id={id} />
      ))}
      <TextButton className="tw-px-0" onClick={toggleModal} data-cy="add-link-button">
        <Plus size="s" /> Add Link
      </TextButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  listingUuid: state.tdpDetailsReducer.uuid,
  links: state.tdpDetailsReducer.links,
  propertyPagePath: state.tdpDetailsReducer.propertyPagePath,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveLinkAsThunk: (payload) => dispatch(putLinkAsThunk(payload)),
});

LinksWrapper.propTypes = {
  links: arrayOf(
    shape({
      title: string,
      url: string,
    }),
  ),
  listingUuid: string.isRequired,
  propertyPagePath: string,
  onSaveLinkAsThunk: func.isRequired,
};

LinksWrapper.defaultProps = {
  links: [],
  propertyPagePath: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksWrapper);
