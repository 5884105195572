import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../shared/v2/Dropdown";
import TextButton from "../../../../shared/v2/TextButton";

const ClearIndicator = ({ componentProps }) => (
  <Dropdown.ClearIndicator
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
  >
    <TextButton schema="remove" className="tw-p-0 tw-leading-28px">
      Clear all
    </TextButton>
  </Dropdown.ClearIndicator>
);
ClearIndicator.propTypes = {
  componentProps: PropTypes.shape({}),
};

ClearIndicator.defaultProps = {
  componentProps: null,
};
export default ClearIndicator;
