const GoalLinePlugin = {
  id: "goalLine",
  beforeDatasetsDraw: (chart, args, options) => {
    if (!options.goal) return true;
    const {
      ctx,
      scales: { x, y },
    } = chart;
    const yPixel = y.getPixelForValue(options.goal);

    ctx.save();
    ctx.beginPath();

    ctx.strokeStyle = "#999999";
    ctx.setLineDash([8, 4]);
    ctx.moveTo(x.left, yPixel);
    ctx.lineTo(x.left + x.width, yPixel);
    ctx.stroke();

    ctx.closePath();
    ctx.restore();
    return true;
  },
};

export default GoalLinePlugin;
