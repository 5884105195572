import React, { Component } from "react";
import { Mention, MentionsInput } from "react-mentions";
import Avatar from "@shared/v2/Avatar";
import MentionInputStyles from "./MentionInputStyles";
import mentionStyles from "./mentionable_person.module.css";
import _ from "lodash";

class MentionablePersonTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mentionableUsers: null,
      textareaFocus: false,
    };
  }

  getMentionableUsers = (query, callback) => {
    const { mentionable_id, mentionable_type, isUuid } = this.props;
    if (!this.state.mentionableUsers) {
      fetch(
        `/mentions/mentionable_people?${
          isUuid ? "mentionable_uuid" : "mentionable_id"
        }=${mentionable_id}&mentionable_type=${mentionable_type}`,
      )
        .then((res) => res.json())
        .then((res) =>
          this.setState({ mentionableUsers: res.map((p) => ({ ...p, display: p.full_name })) }, () => {
            this.filterMentionableUsers(query, callback);
          }),
        );
    } else {
      this.filterMentionableUsers(query, callback);
    }
  };

  filterMentionableUsers = (query, callback) => {
    const filteredUsers = _.uniqBy(this.state.mentionableUsers, "id")
      .filter((p) => p.display.toLowerCase().startsWith(query.toLowerCase()))
      .sort((a, b) => a.display.toLowerCase().localeCompare(b.display.toLowerCase()));
    callback(filteredUsers);
  };

  personAvatar = (person) => {
    return <Avatar src={person.avatar} alt={person.full_name} />;
  };

  render() {
    return (
      <MentionsInput
        id={this.props.id}
        style={MentionInputStyles}
        value={this.props.value}
        placeholder="Write a note or use @ to notify someone on your team"
        onChange={(e) => this.props.onChange(e.target.value)}
        readOnly={this.props.isExternal}
      >
        <Mention
          trigger="@"
          data={this.getMentionableUsers}
          displayTransform={(id, display) => `@${display}`}
          markup="[__display__](__id__)"
          className={mentionStyles.mentions__mention}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className="tw-flex tw-flex-row tw-items-center">
              {this.personAvatar(suggestion)}
              <div className="tw-ml-2">{highlightedDisplay}</div>
            </div>
          )}
        />
      </MentionsInput>
    );
  }
}

export default MentionablePersonTextArea;
