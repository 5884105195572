import React from "react";
import { AgentForm } from "../AgentForm";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  agents: state.accountFinancial.accountData?.agent_expense_templates.agent_expenses,
});

export const AgentsForm = connect(
  mapStateToProps,
  null,
)((props) => {
  const { agents } = props;

  return (
    <div className="agent-expenses-wrap">
      {agents.map((agent, i) => (
        <AgentForm agent={agent} agents={agents} agentPosition={i} key={agent.user_id} />
      ))}
    </div>
  );
});
