import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";

import Button from "../../../shared/v2/Button";
import { optionShape } from "../../../shared/v2/Dropdown/utils";
import ErrorBanner, { errorForBanner } from "../../../shared/v2/ErrorBanner";
import { Edit } from "../../../shared/v2/Icomoon";
import LoadingOverlay from "../../../shared/v2/LoadingOverlay";
import TextButton from "../../../shared/v2/TextButton";
import Tooltip from "../../../shared/v2/Tooltip";

import { CollaboratorsWithLineItems } from "./components/TeamMember";

import { addTeamMembers, addCompany, fetchFinancialsSelectableTeamMembers } from "./actions/thunks";
import AddTeamMemberModal from "./components/AddTeamMemberModal";
import TeamMemberSection from "./components/TeamMemberSection";
import TransactionSection from "./components/TransactionSection";
import { emptyTransactionData } from "./components/TransactionGCI/helperData";
import FeatureBanner from "./components/FeatureBanner";
import CDAdoc from "./components/CDAdoc";
import CompanySection from "./components/CompanySection";

const AddTeamMemberorCompanyButton = ({ isTeamMember, hasFinancialData, isActualGCI, onClick }) => (
  <span className="tw-flex tw-justify-center tw-py-8px tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-4px">
    {hasFinancialData ? (
      <TextButton data-cy="add-team-member" onClick={onClick}>
        {isTeamMember ? "+ Team Member" : "+ Company"}
      </TextButton>
    ) : (
      <Tooltip
        placement="top"
        trigger={
          <TextButton data-cy="add-team-member" disabled>
            {isTeamMember ? "+ Team Member" : "+ Company"}
          </TextButton>
        }
        content={`
          Missing "${isActualGCI ? "Close Price" : "Est. Close Price"}" to allow adding ${
            isTeamMember ? "team members" : "company"
          }.
        `}
      />
    )}
  </span>
);

AddTeamMemberorCompanyButton.propTypes = {
  isTeamMember: PropTypes.bool.isRequired,
  hasFinancialData: PropTypes.bool.isRequired,
  isActualGCI: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const FinancialsWrapper = ({ dispatch, listingId, financials }) => {
  const [addingTeamMember, setAddingTeamMember] = useState(false);

  useEffect(() => {
    // Since we reuse the `transactionDetails` data from the details reducer in the financials API adapters,
    // we need to ensure that the financials data is only fetched after the header/details data has been fetched,
    // so we wait until `listingId` exists.
    if (listingId) {
      dispatch(fetchFinancialsSelectableTeamMembers());
    }
  }, [listingId]);

  const {
    adaptedData,
    selectableTeamMembers,
    meta: { isLoading, isUpdating, errors: metaErrors },
  } = financials;
  const collaborators = adaptedData.collaborators || [];
  const company = adaptedData.company || null;
  const transaction = adaptedData.transaction || emptyTransactionData;

  const handleAddTeamMember = () => {
    setAddingTeamMember(true);
  };

  const handleAddCompany = () => {
    dispatch(addCompany());
  };

  const handleSaveAddedTeamMembers = (selectedMembers) => {
    dispatch(addTeamMembers(selectedMembers));
  };

  useEffect(() => {
    if (!isUpdating) {
      setAddingTeamMember(false);
    }
  }, [isUpdating]);

  const errors = useMemo(() => metaErrors.map((error) => errorForBanner(error)), [metaErrors]);

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-24px">
        {errors.length > 0 && <ErrorBanner errors={errors} data-cy="financials-error" />}

        <div className="tw-flex tw-justify-between tw-items-center">
          <h2 className="tw-text-18d tw-font-bold tw-text-neutral-gray-75 tw-m-0">Financials</h2>
          {false && (
            // Hide away for now, as the bulk editor is not a part of the TDP's MVP.
            <Button className="tw-flex tw-items-center tw-gap-4px" size="small" schema="secondary">
              <Edit className="tw-text-neutral-gray-75" />
              <span>Bulk Edit</span>
            </Button>
          )}
        </div>
        <FeatureBanner />
        <CDAdoc />
        <div className="tw-flex tw-flex-col tw-gap-16px">
          <TransactionSection />
          {company && <CompanySection />}
          <TeamMemberSection />

          {false && (
            // Hide away collaborators for now. As of the TDP's MVP, they're not
            // being correctly accounted for within the back-end validations _or_
            // the front-end's calculations, so Product has given us the green
            // light to ignore their existence for now. Also, there's no official
            // design for them in the TDP Figma.
            <CollaboratorsWithLineItems collaborators={collaborators} />
          )}

          <div className="tw-grid tw-grid-flow-col tw-gap-[14px]">
            <AddTeamMemberorCompanyButton
              isTeamMember
              hasFinancialData={transaction.hasFinancialData}
              isActualGCI={transaction.isActualGCI}
              onClick={handleAddTeamMember}
            />
            {!company && (
              <AddTeamMemberorCompanyButton
                isTeamMember={false}
                hasFinancialData={transaction.hasFinancialData}
                isActualGCI={transaction.isActualGCI}
                onClick={handleAddCompany}
              />
            )}
          </div>
          {addingTeamMember && (
            <AddTeamMemberModal
              show
              isAdding={isUpdating}
              members={selectableTeamMembers}
              onAdd={handleSaveAddedTeamMembers}
              onCancel={() => setAddingTeamMember(false)}
            />
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

FinancialsWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,

  listingId: PropTypes.number,

  financials: PropTypes.shape({
    // Schema validation for the inner fields will be handled by the inner
    // components, so we mark them as "any" here.
    adaptedData: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      collaborators: nullable(PropTypes.any).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      transaction: nullable(PropTypes.any).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      company: nullable(PropTypes.any).isRequired,
    }).isRequired,

    selectableTeamMembers: PropTypes.arrayOf(optionShape),

    meta: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired,
      isUpdating: PropTypes.bool.isRequired,
      // TODO: Error handling will likely need to be more advanced than a single
      // string, since we'll need to map individual errors to one or more fields
      // (see the Financials Bulk Editor on Figma for an example of this).
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

FinancialsWrapper.defaultProps = {
  listingId: null,
};

const mapStateToProps = ({ tdpDetailsReducer, tdpFinancialsReducer: reducer }) => ({
  listingId: tdpDetailsReducer.headerDetails.listing_id,
  financials: { ...reducer.financials },
});

export default connect(mapStateToProps)(FinancialsWrapper);
