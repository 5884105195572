import axios from 'axios';

const fetchTriggersSuccess = (triggers) => {
  return {
    type: 'FETCH_TRIGGERS_SUCCESS',
    triggers
  }
}

const createTriggerSuccess = (trigger) => {
  return {
    type: 'CREATE_TRIGGER_SUCCESS',
    trigger
  }
}

const saveTriggerPending = () => {
  return { type: 'SAVE_TRIGGER_PENDING' }
}

const saveTriggerFailure = (errors) => {
  return {
    type: 'SAVE_TRIGGER_FAILURE',
    errorMessage: errors
  }
}

const updateTriggerSuccess = (trigger) => {
  return {
    type: 'UPDATE_TRIGGER_SUCCESS',
    trigger
  }
}

export const showTriggerModal = (triggerForm) => {
  return {
      type: 'OPEN_TRIGGER_MODAL',
      triggerForm
    }
  }

export const closeTriggerModal = () => {
  return {
    type: 'CLOSE_TRIGGER_MODAL'
  }
}

export const openTriggerDeleteModal = (triggerToDelete) => {
  return {
    type: 'OPEN_TRIGGER_DELETE_MODAL',
    triggerToDelete
  }
}

export const closeTriggerDeleteModal = () => {
  return {
    type: 'CLOSE_TRIGGER_DELETE_MODAL'
  }
}

export const deleteTriggerPending = () => {
  return {
    type: 'DELETE_TRIGGER_PENDING'
  }
}

export const deleteTriggerSuccess = (triggerId) => {
  return {
    type: 'DELETE_TRIGGER_SUCCESS',
    triggerId
  }
}

export const deleteTriggerFailure = () => {
  return {
    type: 'DELETE_TRIGGER_FAILURE'
  }
}

export const updateTriggerValues = (name, value) => {
  return {
    type: 'UPDATE_TRIGGER_VALUE',
    name,
    value
  }
}

export const createTrigger = (triggersPath, formData, callback) => {
  return dispatch => {
    dispatch(saveTriggerPending())
    axios.post(triggersPath, { trigger: formData, authenticity_token: ReactOnRails.authenticityToken() })
    .then(res => {
      dispatch(createTriggerSuccess(res.data))
      if(callback) { dispatch(callback) }
    })
    .catch(err => {
      dispatch(saveTriggerFailure(err.response.data.errors))
    })
  }
}

export const updateTrigger = (triggerPath, formData, callback) => {
  return dispatch => {
    dispatch(saveTriggerPending())
    axios.patch(triggerPath, { trigger: formData, authenticity_token: ReactOnRails.authenticityToken() })
    .then(res => {
      dispatch(updateTriggerSuccess(res.data.trigger))
      if(callback) { dispatch(callback) }
    })
    .catch(err => {
      dispatch(saveTriggerFailure(err.response.data.errors))
    })
  }
}

export const getTriggers = (triggersPath) => {
  return dispatch => {
    dispatch({ type: 'FETCH_TRIGGERS' })
    axios.get(triggersPath,{
      responseType: 'json',
      contentType: 'application/json'
    }).then(results => {
      dispatch(fetchTriggersSuccess(results.data))
    })
  }
}

export const deleteTrigger = (id, path, callback) => {
  return dispatch => {
    dispatch(deleteTriggerPending())

    axios.delete(path, { params: {authenticity_token: ReactOnRails.authenticityToken() }})
      .then(res => {
        dispatch(deleteTriggerSuccess(id))
        if(callback) { dispatch(callback) }
      })
      .catch(err => {
        dispatch(deleteTriggerFailure())
      })
  }
}
