import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import WebsiteIndex from "./WebsiteIndex";
import BlogIndex from "./Blog/BlogIndex";
import PostIndex from "./Post/PostIndex";

const WebsiteApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<WebsiteIndex />}>
        <Route index element={<Navigate to="blog" replace />} />
        <Route path="blog" element={<BlogIndex />} />
        <Route path="/post/:uuid" element={<PostIndex />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>,
    ),
    { basename: "/website" },
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default WebsiteApp;
