import React, { useState, useRef } from "react";
import { func, shape, string } from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions/creators";
import inputUploadFn from "./helpers";
import { uploadFiles } from "../../../shared/AWSUploader";
import { UploadSolidV6, SpinnerSolidV3 } from "../../../shared/v2/Icomoon/Icons";
import ActionImage from "../ActionImage";

const mapStateToProps = (state) => ({
  awsDirectPost: state.businessSettings.awsDirectPost,
});

const UploadImage = ({ awsDirectPost, onSave, onRemove, image }) => {
  const ref = useRef();
  const [uploading, setUploading] = useState(false);
  const inputUpload = inputUploadFn({
    onError: () => setUploading(false),
    awsDirectPost,
    uploadFiles,
    onAdd: () => setUploading(true),
    onSave: (file) => {
      setUploading(false);
      onSave(file);
    },
  });

  return (
    <>
      <div className="tw-relative tw-border tw-border-dashed tw-border-neutral-gray-50 tw-overflow-hidden tw-bg-neutral-gray-10 tw-w-[180px] tw-h-[180px] tw-rounded-lg">
        <button
          type="button"
          className="tw-relative tw-bg-transparent tw-border-0 tw-w-[180px] tw-h-[180px] tw-px-[16px] tw-py-[38px] tw-flex tw-flex-col tw-justify-center tw-items-center"
          data-cy="image-uplaoder"
        >
          <UploadSolidV6 size="xxxl" className="tw-mb-[16px]" />
          <div className="tw-font-bs-sans tw-text-[14px] tw-font-semibold">
            Click here to upload or drag and drop images here.
          </div>
          <input
            ref={ref}
            className="tw-absolute tw-top-[-100%] tw-left-[-100%] tw-h-[200%] tw-w-[200%] tw-opacity-0"
            type="file"
            name="files[]"
            multiple
            onInput={inputUpload}
            data-cy="image-uploader-input"
            accept="image/*"
          />
        </button>
        {uploading ? (
          <div className="tw-bg-neutral-gray-10 tw-absolute tw-top-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
            <SpinnerSolidV3 size="l" className="fa-spin" />
          </div>
        ) : (
          <>
            {image > "" && (
              <div className="tw-absolute tw-top-0">
                <ActionImage url={image} onDelete={() => onRemove()} onReplace={() => ref.current.click()} />
              </div>
            )}
          </>
        )}
      </div>
      {image <= "" && (
        <p className="tw-font-[12px] tw-text-neutral-gray-50 tw-mt-[8px] tw-w-[200px]">
          Image must be .jpg or .png format and smaller than 5 MB.
        </p>
      )}
    </>
  );
};
UploadImage.propTypes = {
  awsDirectPost: shape({}).isRequired,
  image: string,
  onSave: func,
  onRemove: func,
};

UploadImage.defaultProps = {
  image: null,
  onSave: null,
  onRemove: null,
};
export default connect(mapStateToProps, actions)(UploadImage);
