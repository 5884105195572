/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LockSolidV6 = (props) => (
  <Icon {...props} name="Lock Solid V6">
    <path d="M3.5 6V4.5a4.501 4.501 0 0 1 9 0V6h.5c1.103 0 2 .897 2 2v6c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h.5zm2 0h5V4.5a2.5 2.5 0 1 0-5 0V6z" />
  </Icon>
);

export default LockSolidV6;
