/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TriangleExclamationSolidV6 = (props) => (
  <Icon {...props} name="Triangle Exclamation Solid V6">
    <path d="M15.822 13.031 9.156 1.656c-.51-.875-1.798-.875-2.312 0L.182 13.031C-.331 13.903.308 15 1.336 15h13.331c1.024 0 1.664-1.094 1.155-1.969zM7.25 5.25a.75.75 0 0 1 1.5 0v4c0 .414-.336.75-.722.75s-.778-.334-.778-.75v-4zM8 13a.983.983 0 1 1 0-1.966A.983.983 0 0 1 8 13z" />
  </Icon>
);

export default TriangleExclamationSolidV6;
