import React, { useEffect, useMemo } from "react";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import Modal from "@shared/v2/Modal";

const SourceModal = ({ sources, show, onHide, onSubmit }) => {
  const options = useMemo(
    () => sources.map((source) => ({ label: source.name, value: source.id })),
    [sources],
  );
  const [name, setName] = React.useState("");
  const error = useMemo(
    () =>
      options.find((s) => s.label.toLowerCase().trim() === name.toLowerCase().trim())
        ? "This source already exists, try something else."
        : "",
    [options, name],
  );

  useEffect(() => {
    if (!show) setName("");
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[640px] tw-max-h-[90vh] tw-w-full"
    >
      <form
        className="tw-flex tw-flex-col tw-gap-[32px]"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit({ name });
        }}
      >
        <Modal.Header title="Add a Source to Brivity" closeButton />
        <Modal.Body>
          <Dropdown
            customFilter={(option, inputValue) =>
              option.label
                .replace(/\s+/g, "")
                .toLowerCase()
                .includes(inputValue.replace(/\s+/g, "").toLowerCase())
            }
            error={error}
            inputValue={name}
            isSearchable
            label="Source Name"
            menuShouldComeToFront
            noOptionsMessage="No Source Found"
            onInputChange={setName}
            onChange={(source) => setName(source?.label || "")}
            options={options}
            placeholder=""
            selectInputOnMenuOpen={false}
            shouldFilterOptions
          />
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={onHide}>
            Cancel
          </Button>
          <Button size="medium" type="submit" disabled={Boolean(error) || !name}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SourceModal;
