import React from "react";

import Checkbox from "@shared/v2/Checkbox";
import Flyout from "@shared/v2/Flyout";

import ListingDetails from "./ListingDetails";
import "../details.scss";

const ListingDetailsModal = ({
  listing,
  buyerMatches,
  isSelected,
  numberSelected,
  selectedLimit,
  onToggleShare,
  sendToLead,
  maxListingsSelected,
  setSharedListing,
  onClose,
  openAgentDetailsModal,
  agentDetailsEnabled,
}) => {
  return (
    <Flyout id="listing-details-modal" show={true} onHide={onClose}>
      <Flyout.Header>
        <Checkbox
          checked={isSelected}
          onChange={() => onToggleShare(listing)}
          disabled={maxListingsSelected && !isSelected}
          label={` ${numberSelected}/${selectedLimit} selected`}
        />
      </Flyout.Header>

      <Flyout.Body className="tw-max-w-[760px]">
        <ListingDetails
          listing={listing}
          setSharedListing={setSharedListing}
          buyerMatches={buyerMatches}
          sendToLead={sendToLead}
          onShowAgentDetails={openAgentDetailsModal}
          agentDetailsEnabled={agentDetailsEnabled}
          onClose={onClose}
        />
      </Flyout.Body>
    </Flyout>
  );
};

export default ListingDetailsModal;
