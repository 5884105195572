import React from "react";
import { shape, string } from "prop-types";

const EventOwnerorReceiver = ({ user }) =>
  user.slug ? (
    <a href={`/people/${user.slug}?tab=details-tab`} className="tw-font-semibold">
      {user.name}{" "}
    </a>
  ) : (
    <span className="tw-font-semibold">{user.name} </span>
  );
EventOwnerorReceiver.propTypes = {
  user: shape({
    slug: string,
    name: string,
  }),
};
EventOwnerorReceiver.defaultProps = {
  user: {
    slug: null,
    name: "",
  },
};
export default EventOwnerorReceiver;
