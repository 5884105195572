export const justifyDictionary = {
  start: "tw-justify-start",
  end: "tw-justify-end",
  center: "tw-justify-center",
  between: "tw-justify-between",
  around: "tw-justify-around",
  evenly: "tw-justify-evenly",
};
export const justifyValue = (justify) => justifyDictionary[justify] || "";

export const alignDictionary = {
  start: "tw-items-start",
  end: "tw-items-end",
  center: "tw-items-center",
  baseline: "tw-items-baseline",
  stretch: "tw-items-stretch",
};

export const alignValue = (align) => alignDictionary[align] || "";

export const spaceDictionary = {
  none: "tw-space-x-0",
  xs: "tw-space-x-[2px]",
  sm: "tw-space-x-[4px]",
  md: "tw-space-x-[6px]",
  lg: "tw-space-x-[8px]",
  xl: "tw-space-x-[12px]",
};

export const spaceValue = (space) => spaceDictionary[space] || space || "";
