import React from "react";
import PropTypes from "prop-types";
import Avatar from "@shared/v2/Avatar";
import Tooltip from "@shared/v2/Tooltip";

const AvatarLink = ({ simplePerson }) => {
  if (simplePerson) {
    return (
      <Tooltip
        trigger={
          <a href={simplePerson.href} className="tw-flex tw-justify-center">
            <Avatar size="medium" src={simplePerson.avatar} alt={simplePerson.fullName} />
          </a>
        }
        content={simplePerson.fullName}
      />
    );
  }
  return "N/A";
};

AvatarLink.propTypes = {
  simplePerson: PropTypes.shape({
    href: PropTypes.string,
    avatar: PropTypes.string,
    fullName: PropTypes.string,
  }),
};

AvatarLink.defaultProps = {
  simplePerson: null,
};

export default AvatarLink;
