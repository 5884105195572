import React from "react";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import ConferenceModal from "./ConferenceModal";

class ConferenceCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callConfirmationOpen: false,
      callModalOpen: false,
      startCall: false,
      selectedLeadUuids: [],
      conferenceUuid: null,
      dialerServiceToken: null,
      isLoading: false,
    };
  }

  closeConfirmationModal = () => {
    this.setState({ callConfirmationOpen: false });
  };

  closeCallModal = () => {
    this.setState({
      callModalOpen: false,
      startCall: false,
      conferenceUuid: null,
      dialerServiceToken: null
    });
  };

  createDialerSession = () => {
    this.setState({
      isLoading: true,
      callModalOpen: true,
    });
    axios
      .post("/dialer/create_dialer_session", {
        lead_uuids: this.state.selectedLeadUuids,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((resp) => {
        this.setState(
          {
            conferenceUuid: resp.data.conference_uuid,
            dialerServiceToken: resp.data.token,
            callConfirmationOpen: false,
            isLoading: false,
            startCall: true,
          }
        );
      });
  };

  launchConfirmModal = () => {
    const peopleRows = $("table.all-people-table input:checked");
    const leadRows = $("table.leads-table tr input:checked");
    const rows = peopleRows.length > 0 ? peopleRows : leadRows;
    let ids = [];
    rows.map((i, j) => {
      ids.push($(j).closest("tr").data().personUuid);
    });

    this.setState({ selectedLeadUuids: ids }, () => {
      this.setState({ callConfirmationOpen: true });
    });
  };

  render() {
    return (
      <div>
        <button
          className="btn-sm btn-info btnselected"
          onClick={this.launchConfirmModal}
        >
          CALL
        </button>
        <ConfirmationModal
          show={this.state.callConfirmationOpen}
          isLoading={this.state.isLoading}
          selectedLeadUuids={this.state.selectedLeadUuids}
          createDialerSession={this.createDialerSession}
          closeConfirmationModal={this.closeConfirmationModal}
        />
        <ConferenceModal
          dialerServiceUrl={this.props.dialerServiceUrl}
          conferenceUuid={this.state.conferenceUuid}
          dialerServiceToken={this.state.dialerServiceToken}
          selectedLeadUuids={this.state.selectedLeadUuids}
          closeCallModal={this.closeCallModal}
          show={this.state.callModalOpen}
          startCall={this.state.startCall}
        />
      </div>
    );
  }
}

export default ConferenceCall;
