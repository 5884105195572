import React, { Component } from "react";
import ListingAlert from "../listing_alerts/ListingAlert";

class EditListingAlertModal extends Component {
  render() {
    const { geoCodeAccountAddress, closeListingAlertModal, mlsIds, person } = this.props;

    return (
      <ListingAlert
        person={person}
        closeListingAlertModal={closeListingAlertModal}
        mlsIds={mlsIds}
        geoCodeAccountAddress={geoCodeAccountAddress}
        type="edit"
        title="Edit Listing Alert"
        {...this.props.alert}
      />
    );
  }
}

export default EditListingAlertModal;
