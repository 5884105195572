/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Search = (props) => (
  <Icon {...props} name="Search">
    <path d="m14.535 12.899-2.617-2.616a.63.63 0 0 0-.446-.184h-.428A5.458 5.458 0 0 0 6.74 1.281 5.458 5.458 0 0 0 1.281 6.74a5.458 5.458 0 0 0 5.459 5.459c1.268 0 2.433-.43 3.359-1.155v.428a.63.63 0 0 0 .184.446l2.616 2.616a.627.627 0 0 0 .89 0l.743-.743a.632.632 0 0 0 .003-.892zm-7.794-2.8A3.357 3.357 0 0 1 3.382 6.74a3.357 3.357 0 0 1 3.359-3.359A3.357 3.357 0 0 1 10.1 6.74a3.357 3.357 0 0 1-3.359 3.359z" />
  </Icon>
);

export default Search;
