import React, { useState, useRef } from "react";
import SelectButton from "./SelectButton";
import { selectOption } from "./SelectOption";
import { useClickOutside } from "../../shared/hookHelpers";

const SelectAll = () => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const ref = useRef(null);
  useClickOutside(ref, () => setShow(false));

  const selectOpt = (type) => {
    if (type !== null) {
      selectOption(type);
      setSelectedOption(type);
      setShow(false);

      const selectMenuEvent = new Event("select-opt:selected");
      window.dispatchEvent(selectMenuEvent);
    }
  };

  const handleToggle = () => {
    setShow(!show);
    if (document.getElementById("select-rows").checked === true) {
      if (selectedOption !== "select-all") {
        setSelectedOption("select-page");
      }
    } else if (
      document.getElementById("select-rows").checked === false &&
      document.getElementById("select-rows").indeterminate === false
    ) {
      setSelectedOption("select-none");
    } else {
      setSelectedOption(null);
    }
  };

  return (
    <div className="tw-relative tw-mb-4px tw-h-[16px] tw-pl-[11px]" ref={ref}>
      <div className="checkboxselect checkbox">
        <input type="hidden" id="is-all-selected" className="tw-hidden" />
        <input type="checkbox" id="select-rows" disabled className="dropdown-item" />
        <button
          type="button"
          className="focus:tw-outline-none tw-bg-transparent tw-border-0"
          id="table-dropdown-toggle"
          onClick={handleToggle}
        >
          <label
            aria-label="select-rows"
            htmlFor="select-rows"
            className="tw-mt-[-4px] tw-mt-0px tw-mb-0px"
          />
        </button>
      </div>
      {show && (
        <div
          id="filter-select"
          className="tw-absolute tw-bg-white tw-rounded-[8px] tw-z-1 tw-shadow-dropdown tw-overflow-hidden"
        >
          <SelectButton
            selectedOption={selectedOption}
            option="All"
            handleClick={() => {
              selectOpt("select-all");
            }}
            id="select-all"
          />
          <SelectButton
            selectedOption={selectedOption}
            option="All on Page"
            handleClick={() => {
              selectOpt("select-page");
            }}
            id="select-page"
          />
          <SelectButton
            selectedOption={selectedOption}
            option="None"
            handleClick={() => {
              selectOpt("select-none");
            }}
            id="select-none"
          />
          <input type="hidden" name="current_source" id="current_source" />
          <input type="hidden" name="current_scope" id="current_scope" value="all_people" />
        </div>
      )}
    </div>
  );
};
export default SelectAll;
