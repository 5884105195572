import PropTypes from "prop-types";
import errorShape from "./error";
import feedbackShape from "./feedback";

const promptDataShape = PropTypes.shape({
  data: feedbackShape,
  isVisible: PropTypes.bool.isRequired,
  error: errorShape,
  isLoading: PropTypes.bool.isRequired,
});

export default promptDataShape;
