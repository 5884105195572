import React from "react";
import { string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { CLIENTINTENT } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import DetailTxtNonEditField from "../shared/DetailTxtNonEditField";

const ClientIntentField = ({ clientIntent }) => (
  <DetailTxtNonEditField dataCy="refDetailsIntent" label="Intent" fieldValue={clientIntent} />
);

export default tdpDetails({
  WrappedComponent: ClientIntentField,
  states: CLIENTINTENT,
});

ClientIntentField.propTypes = {
  clientIntent: string,
};

ClientIntentField.defaultProps = {
  clientIntent: null,
};
