import React, {Component} from 'react';
import { Col, Row } from 'react-bootstrap'
import PolymorphicTask from './PolymorphicTask';
import axios from 'axios';
import SelectAll from '../../shared/SelectAll';
import Pagination from '../../shared/Pagination';
import * as TaskHelpers from './TaskIndexModalHelpers';

export default class PolymorphicGrid extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: props.user,
      tasks: [],
      taskable: props.taskable,
      account: props.account,
      pagination: {},
      page: props.page || 1,
      showButtons: false,
      showSelectAll: false,
      selectNone: false,
      selectAll: false,
      header_buttons: false,
      incomplete_buttons: false,
      showModal: false,
      showDeleteModal: false,
      showRescheduleModal: false,
      showReassignModal: false,
      assigned_to_user_id: 'anyone',
      reschedule_date: moment(),
      selectAllTarget: null,
    }

    this.renderNewPolymorphicTaskModal = TaskHelpers.renderNewPolymorphicTaskModal.bind(this)
    this.showSelectAllPopover = TaskHelpers.showSelectAllPopover.bind(this)
    this.closeSelectAllPopover = TaskHelpers.closeSelectAllPopover.bind(this)

    this.renderRescheduleModal = TaskHelpers.renderRescheduleModal.bind(this)
    this.showRescheduleModal = TaskHelpers.showRescheduleModal.bind(this)
    this.closeRescheduleModal = TaskHelpers.closeRescheduleModal.bind(this)

    this.renderDeleteModal = TaskHelpers.renderDeleteModal.bind(this)
    this.showDeleteModal = TaskHelpers.showDeleteModal.bind(this)
    this.closeDeleteModal = TaskHelpers.closeDeleteModal.bind(this)

    this.renderReassignModal = TaskHelpers.renderReassignModal.bind(this)
    this.showReassignModal = TaskHelpers.showReassignModal.bind(this)
    this.closeReassignModal= TaskHelpers.closeReassignModal.bind(this)

    this.multiSelectWatcher = TaskHelpers.multiSelectWatcher.bind(this)
    this.selectAll = TaskHelpers.selectAll.bind(this)
    this.selectNone = TaskHelpers.selectNone.bind(this)
    this.taskMultiSelect = TaskHelpers.taskMultiSelect.bind(this)
    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this)
    this.handleState = TaskHelpers.handleState.bind(this)
    this.handleRescheduleDate = TaskHelpers.handleRescheduleDate.bind(this)

    this.showModal = TaskHelpers.showModal.bind(this)
    this.closeModal = TaskHelpers.closeModal.bind(this)

    this.selectedTasks = TaskHelpers.selectedTasks.bind(this)
    this.setAgentsForModal = TaskHelpers.setAgentsForModal.bind(this)

    this.uncompleteTasks = TaskHelpers.uncompleteTasks.bind(this)
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this)

    this.reassignTasks = TaskHelpers.reassignTasks.bind(this)
    this.reassignTask = TaskHelpers.reassignTask.bind(this)

    this.deleteTasks = TaskHelpers.deleteTasks.bind(this)
    this.deleteTask = TaskHelpers.deleteTask.bind(this)

    this.completeTasks = TaskHelpers.completeTasks.bind(this)
    this.completeTask = TaskHelpers.completeTask.bind(this)

    this.rescheduleTasks = TaskHelpers.rescheduleTasks.bind(this)
    this.rescheduleTask = TaskHelpers.rescheduleTask.bind(this)

    this.updateTask = TaskHelpers.updateTask.bind(this)
    this.handleGotoPage = this.handleGotoPage.bind(this);
  }

  getTasks = () => {
    let { page } = this.state;
    axios({
      method: "get",
      url: `/tasks/taskable_index?id=${this.props.taskable.uuid}&scopes=${this.props.scopes}`,
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json'
      },
      params: {
        page: page,
      }
    },
    ).then(response => {
      let { meta, tasks } = response.data;
      this.setState({...this.state, ...{ tasks: tasks, pagination: meta }});
    })
  }

  componentWillReceiveProps = (nextProps) => {
    this.multiSelectWatcher()
  }

  showErrors = (messages) => {
    this.setState({error_messages: messages})
  }

  componentDidMount() {
    this.getTasks()
    const TASK_LISTENER = 'task:polymorphic_count_change'
    window.addEventListener(TASK_LISTENER, this.onEvent )
    window.addEventListener('task:update', this.onEvent )
  }

  componentWillUnmount() {
    const TASK_LISTENER = 'task:polymorphic_count_change'
    window.removeEventListener(TASK_LISTENER, this.onEvent)
    window.removeEventListener('task:update', this.onEvent )
  }

  onEvent = () => {
    this.getTasks()
  }

  showErrors = (messages) => {
    this.setState({error_messages: messages})
  }

  filteredTasks = () => {
    return {
      'incomplete': this.state.tasks,
      'completed': this.state.tasks,
      'contingent': this.state.tasks
    }
  }


  handleGotoPage(newPage){
    this.setState({page: newPage}, this.getTasks);
  }

  render() {
    let { pagination } = this.state;
    return(
      <div>
        <SelectAll
          closeSelectAllHandler={this.closeSelectAllPopover}
          showSelectAll={this.state.showSelectAll}
          target={this.state.selectAllTarget}
          selectAllHandler={this.selectAll}
          selectAll={this.state.selectAll}
          selectNone={this.state.selectNone}
          selectNoneHandler={this.selectNone}
        />
        <Row
          className={'last-row ' + `${this.props.dueness}-complete-tasks-grid`}
          data-page={this.props.completeTasksPage || 1}>
          <Col xs={12}>
            <Row
              className={'th tasks-header'}>
              <Col
                xs={5}>
                <h6>
                  {this.props.duenessTitle}
                </h6>
              </Col>
              <Col
                xs={1}
                className={'text-center'}>
                <h6>
                  Date
                </h6>
              </Col>
              <Col
                xs={1}
                className={'text-center'}>
                  <h6>
                    { this.props.agent &&
                      'Priority'
                    }
                    { !this.props.agent &&
                      '\u00A0'
                    }
                  </h6>
              </Col>
              <Col
                xs={1}
                className={'text-center'}>
                <h6>
                  Who
                </h6>
              </Col>
              <div>
                <Col
                  xs={2}
                  className={'text-center border-extension'}>
                  <h6>
                    { this.props.agent &&
                      'Settings'
                    }
                    { !this.props.agent &&
                      '\u00A0'
                    }
                  </h6>
                </Col>
                { this.props.agent &&
                <Col
                  xs={1}
                  className={'border-extension'}>
                  <h6> Edit </h6>
                </Col>
                }
                { !this.props.agent &&
                  <Col xs={1} className={'border-extension'}>
                    <h6>
                      { '\u00A0' }
                    </h6>
                  </Col>
                }
                { this.props.agent &&
                <Col xs={1}
                  className={'border-extension text-center ' + this.props.selectAllClass}
                  style={{position: 'relative'}}>
                  <a
                    style={{position: 'relative'}}
                    onClick={(e) => this.state.tasks && this.showSelectAllPopover(e)}
                    id={'all-incomplete-tasks-dropdown-toggle'}
                    className={'btn btn-secondary select-task-button'}>
                    <h6>
                      Select
                      <i className={'fa fa-sort-down font-size-12 p-l-3'}/>
                    </h6>
                  </a>
                </Col>
                }
                { !this.props.agent &&
                  <Col xs={1} className={'border-extension'}>
                    <h6> &nbsp; </h6>
                  </Col>
                }
              </div>
            </Row>
            <Row className={'button-row within-tab'}>
              <Col xs={12}>
                { !this.state.header_buttons &&
                  <span className={'pull-right'}>
                    &nbsp;
                  </span>
                }
                { this.props.multiSelectButtons && this.state.header_buttons &&
                  <span
                    className={'pull-right'}>
                    <button
                      onClick={this.completeTasks}
                      className={'btn m-l-5 btn-success-inverse btn-sm complete'}>
                      <span>
                        <i
                          className={'fa fa-clock-o'}/>
                        Complete
                      </span>
                    </button>
                    <button
                      onClick={this.showDeleteModal}
                      className={'btn m-l-5 btn-danger-inverse btn-sm delete'}>
                      <span>
                        <i
                          className={'fa fa-times'}/>
                        Delete
                      </span>
                    </button>
                    <button
                      onClick={this.showRescheduleModal}
                      className={'btn m-l-5 btn-info-inverse btn-sm reschedule'}>
                      <span>
                        <i
                          className={'fa fa-clock-o'}/>
                        Reschedule
                      </span>
                    </button>
                    <button
                      onClick={this.showReassignModal}
                      className={'btn m-l-5 btn-warning-inverse btn-sm reassign'}>
                      <span>
                        <i
                          className={'fa fa-users'}/>
                        Reassign
                      </span>
                    </button>
                  </span>
                }
                {  this.props.createButton &&
                  <h5>
                    <a
                      onClick={this.showModal.bind(this)}
                      className='button polymorphic-create-task' href="javascript:void(0)">
                      <i
                        className='fa fa-plus-square text-warning'/>
                      Create New Task
                    </a>
                  </h5>
                }
                { this.props.uncomplete && this.state.header_buttons &&
                  <span>
                    <button
                      onClick={this.uncompleteTasks}
                      className={'btn btn-success-inverse btn-sm uncomplete'}>
                      <span>
                        <i
                          className={'fa fa-square-o'}/>
                        Un-complete
                      </span>
                    </button>
                  </span>
                }
              </Col>
            </Row>
            <div
              className={'clearfix'}>
            </div>
            <div
              className={'completed-tasks content'}>
            </div>
          </Col>
        </Row>
        <div
          className={this.props.dueness}>
          { this.state.tasks && this.filteredTasks()[this.props.dueness].map( task =>
              <PolymorphicTask
                dueness={this.props.dueness}
                agents={this.props.agents}
                agent={this.props.agent}
                getTasks={this.getTasks}
                key={task.id}
                task={task}
                user={this.props.user}
                accountMilestoneNames={this.props.accountMilestoneNames}
                taskChange={this.props.taskChange}
                multiSelectWatcher={this.multiSelectWatcher} />)}
        </div>
        { this.renderNewPolymorphicTaskModal() }
        { this.renderDeleteModal() }
        { this.renderReassignModal() }
        { this.renderRescheduleModal() }
        { pagination.total_count > 35 && pagination &&
          <div className="row">
            <div className="col-xs-6 col-xs-offset-3">
              <Pagination
                currentPage={pagination.current_page}
                nextPage={pagination.next_page}
                prevPage={pagination.prev_page}
                totalPages={pagination.total_pages}
                totalCount={pagination.total_count}
                onGoToPage={this.handleGotoPage}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}
