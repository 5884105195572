import React, { useState } from "react";

import Button from "./Button";
import { useStateWithProps } from "./customHooks";
import Dropdown from "@shared/v2/Dropdown";

const SortMenu = ({ sortField, sortOrder, onSave }) => {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [field, setField] = useStateWithProps(sortField);
  const [order, setOrder] = useStateWithProps(sortOrder || "asc");

  const saveSort = () => {
    onSave(field, order);
    setSortMenuOpen(false);
  };

  const update = (opt, setFn) => {
    const value = opt.value || "";

    setFn(value);
  };

  return (
    <>
      <button
        className="tw-py-2px tw-px-6px tw-cursor-pointer tw-tracking-normal tw-text-center tw-uppercase tw-text-none tw-rounded-30px tw-border-2 tw-border-teal tw-border-solid tw-bg-white"
        onClick={() => setSortMenuOpen(!sortMenuOpen)}
      >
        <span className="tw-flex tw-flex-row tw-items-center tw-whitespace-nowrap tw-text-teal tw-text-11d tw-font-bold">
          SORT BY{sortField ? `: ${SortFields[sortField]}` : ""}
          <i className="fa fa-angle-down tw-text-11d tw-ml-6px" />
        </span>
      </button>
      {sortMenuOpen && (
        <>
          <div className="tw-absolute tw-flex tw-items-center tw-right-112px tw-top-32px tw-flex-row tw-w-[353px] tw-h-[68px] tw-p-16px tw-z-50 tw-bg-white tw-shadow-modal">
            <div className="tw-mr-12px tw-w-[123px]">
              <Dropdown
                isSearchable={false}
                isClearable={false}
                options={fieldOptions}
                onChange={(e) => update(e, setField)}
                value={fieldOptions.find(f => f.value === field)}
              />
            </div>
            <div className="tw-mr-20px tw-w-[123px]">
              <Dropdown
                isSearchable={false}
                isClearable={false}
                options={orderOptions}
                onChange={(e) => update(e, setOrder)}
                value={orderOptions.find(o => o.value === order)}
              />
            </div>
            <Button
              classNameExtra="tw-h-24px"
              color={"blue"}
              text={"SAVE"}
              disabled={!field && "disabled"}
              onClick={saveSort}
            />
          </div>
          <div
            className="tw-fixed tw-z-40 tw-inset-0 tw-bg-transparent"
            onClick={() => setSortMenuOpen(false)}
          ></div>
        </>
      )}
    </>
  );
};

export default SortMenu;

const createOptions = (opts) => {
  return Object.entries(opts).map(([value, label]) => {
    return { value, label };
  });
};

const SortOrder = {
  asc: "Ascending",
  desc: "Descending",
};

const SortFields = {
  bedrooms: "Beds",
  price: "List Price",
  listDate: "List Date",
  squareFeet: "SQFT",
  bathsTotalDecimal: "Total Baths",
};

const orderOptions = createOptions(SortOrder);
const fieldOptions = createOptions(SortFields);
