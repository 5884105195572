import React from "react";
import PropTypes from "prop-types";

import schema from "./Typography.styles";

const htmlTagsByVariant = {
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  "subtitle-m-bold": "h4",
  "subtitle-m": "h4",
  "subtitle-s": "h5",
  "body-m": "p",
  "body-s": "p",
  inherit: "span",
};

export const availableVariants = Object.keys(htmlTagsByVariant);

/**
 * @summary Typography Component. `children` is the only required prop to make it functional.
 * @param {JSX.Element} children - the element that will be rendered with the variant.
 * @param {string} variant - Variant for the content. Each variant has a different style.
 * @param {JSX.Element | string} component - Optional prop. You can change the default HTML tag that will be rendered.
 * @param {string} className - Arbitrary classNames for the component.
 * */
const Typography = ({ children, variant, component, className, ...otherProps }) => {
  const Component = component || htmlTagsByVariant[variant];

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...otherProps} className={`${schema[variant]} ${className}`}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(availableVariants),
  component: PropTypes.elementType,
  className: PropTypes.string,
};

Typography.defaultProps = {
  variant: "body-m",
  component: null,
  className: "",
};

export default Typography;
