import React, { Component} from 'react';
import TaskCount from './TaskCount'
import axios from 'axios';
export default class TaskCounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: {}
    }
  }

  componentWillMount() {
    this.getTaskCounts()
  }

  componentDidMount() {
    const TASK_LISTENER = 'task:count_change'
    window.removeEventListener(TASK_LISTENER, this.onEvent )
    window.addEventListener(TASK_LISTENER, this.onEvent )
  }

  componentWillUnmount() {
    const TASK_LISTENER = 'task:count_change'
    window.removeEventListener(TASK_LISTENER, this.onEvent)
  }

  onEvent = () => {
    this.getTaskCounts()
  }

  getTaskCounts = () => {
    const {enabled} = this.props

    enabled && axios.get('/tasks/counts')
    .then(response => {
      this.setState({count: response.data})
    })
  }

  render() {
    return(
    <ul className={'brivity-nav-menu-sub tw-mb-0 flex-column'} style={{display: 'none'}}>
      <li className={'nav-item'}>
        <a
          href={'/tasks/overdue'}
          className={`tw-block tw-relative tw-py-[10px] tw-px-[15px] nav-link ${this.props.dueness == 'overdue' ? 'active' : '' }`}>
          Overdue
          <TaskCount
            count={this.state.count}
            count_for={'overdue'}/>
      </a>
      </li>
      <li className={'nav-item'}>
        <a
          href={'/tasks/due_today'}
          className={`tw-block tw-relative tw-py-[10px] tw-px-[15px] nav-link ${this.props.dueness == 'due_today' ? 'active' : '' }`}>
          Due Today
          <TaskCount
            count={this.state.count}
            count_for={'due_today'}/>
        </a>
      </li>
      <li className={'nav-item'}>
        <a
          href={'/tasks/upcoming'}
          className={`tw-block tw-relative tw-py-[10px] tw-px-[15px] nav-link ${this.props.dueness == 'upcoming' ? 'active' : '' }`}>
          Upcoming
          <TaskCount
            count={this.state.count}
            count_for={'upcoming'}/>
        </a>
      </li>
      <li className={'nav-item'}>
        <a
            href={'/tasks/completed'}
            className={`tw-block tw-relative tw-py-[10px] tw-px-[15px] nav-link ${this.props.dueness == 'completed' ? 'active' : '' }`}>
          Completed
          <TaskCount
              count={this.state.count}
              count_for={'completed'}/>
        </a>
      </li>
    </ul>
    )
  }
}
