import React from "react";
import { bool, shape, func, string, } from 'prop-types';

import DateSelector from "./v2/DateSelector";
import TextButton from "./v2/TextButton";
import Button from "./v2/Button";

export const DateSelectorWithOptions = ({
  show,
  refOuter,
  dateSelectorOnChangeHandlr,
  dateSelectorValue,
  clearHandlr,
  submitHandlr,
}) =>
  show && (
    <div className="tw-relative tw-h-36px tw-z-40" ref={refOuter}>
      <div className="tw-absolute tw-shadow-dropdown">
        <DateSelector onChange={dateSelectorOnChangeHandlr} value={dateSelectorValue} />
        <hr className="tw-mt-0 tw-mb-0 tw-pt-5px tw-pb-5px tw-bg-white tw-border-neutral-gray-10 tw-w-full" />
        <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-bg-white tw-pb-4 tw-px-12px">
          <TextButton
            className="tw-mr-auto tw-px-0"
            onClick={clearHandlr}
            type="button"
            schema="default"
            size="small"
            data-cy="inline-editing-datepicker-clear"
          >
            Clear
          </TextButton>
          <Button
            onClick={submitHandlr}
            schema="primary"
            size="small"
            data-cy="inline-editing-datepicker-done"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );

DateSelectorWithOptions.propTypes = {
  show: bool,
  refOuter: shape({}),
  dateSelectorOnChangeHandlr: func,
  dateSelectorValue: string,
  clearHandlr: func,
  submitHandlr: func,
};

DateSelectorWithOptions.defaultProps = {
  show: false,
  refOuter: {},
  dateSelectorOnChangeHandlr: () => {},
  dateSelectorValue: "",
  clearHandlr: () => {},
  submitHandlr: () => {},
};