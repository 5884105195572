const FILTER_DICTIONARY = {
  BORDER_DICT: {
    red: "tw-border-semantic-red-100",
    yellow: "tw-border-semantic-yellow-100",
    green: "tw-border-semantic-green-100",
    orange: "tw-border-semantic-orange-100",
    blue: "tw-border-semantic-blue-100",
  },
  BG_DICT: {
    red: "tw-bg-semantic-red-5",
    yellow: "tw-bg-semantic-yellow-5",
    green: "tw-bg-semantic-green-5",
    orange: "tw-bg-semantic-orange-5",
    blue: "tw-bg-semantic-blue-5",
  },
  TEXT_DICT: {
    red: "tw-text-semantic-red-120",
    yellow: "tw-text-semantic-yellow-120",
    green: "tw-text-semantic-green-120",
    orange: "tw-text-semantic-orange-120",
    blue: "tw-text-semantic-blue-120",
  },
  CIRCLE_DICT: {
    red: "tw-bg-semantic-red-50",
    yellow: "tw-bg-semantic-yellow-50",
    green: "tw-bg-semantic-green-50",
    orange: "tw-bg-semantic-orange-50",
    blue: "tw-bg-semantic-blue-50",
  },
};

export default FILTER_DICTIONARY;
