import React, { useEffect } from "react";

import { string, func } from "prop-types";
import { connect } from "react-redux";
import { HeaderContainer } from "../UI/Containers";
import { getAllListingInfoAsThunk } from "./actions/thunks";
import { tdpDetails } from "../../shared/HOCs/withRedux";
import { TRANSACTION_TYPE } from "../../shared/reduxRoutes/tdpDetailsRoutes";
import { isReferral } from "../shared/helpers";
import { fetchFinancials } from "../Main/Financials/actions/thunks";

import Summary from "./Summary";
import ReferralHeader from "./ReferralHeader";

const HeaderChildComponent = ({ type }) => (isReferral(type) ? <ReferralHeader /> : <Summary />);

const HeaderChildComponentWrapper = tdpDetails({
  WrappedComponent: HeaderChildComponent,
  states: TRANSACTION_TYPE,
});

const Header = ({ onGetAllListingInfoAsThunk, onGetFinancials, uuid }) => {
  useEffect(() => {
    onGetFinancials();
    onGetAllListingInfoAsThunk(uuid);
  }, [uuid]);
  return (
    <div className="tw-flex tw-flow-column tw-justify-around tw-items-center">
      <HeaderContainer>
        <HeaderChildComponentWrapper />
      </HeaderContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllListingInfoAsThunk: (uuid) => dispatch(getAllListingInfoAsThunk(uuid)),
  onGetFinancials: () => dispatch(fetchFinancials()),
});

Header.propTypes = {
  onGetAllListingInfoAsThunk: func.isRequired,
  uuid: string.isRequired,
  onGetFinancials: func,
};

Header.defaultProps = {
  onGetFinancials: () => {},
};

HeaderChildComponent.propTypes = {
  type: string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
