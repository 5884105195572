import PropTypes from "prop-types";
import moment from "moment";
import changeVisibilityShape from "./changeVisibility";

const feedbackShape = PropTypes.shape({
  id: PropTypes.number,
  liked: PropTypes.number,
  body: PropTypes.string,
  externallyVisible: PropTypes.bool,
  shownBy: PropTypes.string,
  showingType: PropTypes.string,
  shownAt: PropTypes.instanceOf(moment),
  meta: PropTypes.shape({
    changeVisibility: changeVisibilityShape,
  }),
});

export default feedbackShape;
