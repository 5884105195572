/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleCheckSolidV6 = (props) => (
  <Icon {...props} name="Circle Check Solid V6">
    <path d="M0 8c0-4.419 3.581-8 8-8s8 3.581 8 8c0 4.419-3.581 8-8 8s-8-3.581-8-8zm11.619-1.381a.876.876 0 0 0-1.237-1.237L7.001 8.763 5.62 7.382a.876.876 0 0 0-1.237 1.237l2 2a.876.876 0 0 0 1.237 0l4-4z" />
  </Icon>
);

export default CircleCheckSolidV6;
