import React from "react";
import { Col, FormGroup } from "../../../../shared/v1";
import TransactionInput from "../../shared/TransactionInput";
import * as actionCreators from "../../../actions/account";
import { connect } from "react-redux";
import DatePicker from "../../../../shared/DatePicker";
import DragHandle from "../../shared/DragHandle";
import { kebabCase } from "lodash";

const mapDispatchToProps = (dispatch) => ({
  setAgentBrokerageData: (attr, data, agentPosition) =>
    dispatch(actionCreators.setAgentBrokerageData(attr, data, agentPosition)),
});

const mapStateToProps = (state) => ({
  agents: state.accountFinancial.accountData?.agent_expense_templates.agent_expenses,
});

export const SplitForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { agentPosition, setAgentBrokerageData, agents } = props;
  const agent = agents[agentPosition];

  return (
    <div className="transaction-gci-options tw-bg-transparent tw-p-0 tw-border-0">
      <div className="transaction-gci-content content-additional">
        <div className="tw-border-t tw-border-b-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray tw-pt-4 tw--mr-4 tw--ml-4 tw-flex">
          <DragHandle />
          <Col size={2}>
            <div data-cy={`financial-brokerage-split-form-parent-${kebabCase(agent.name)}`}>
              <TransactionInput
                label="Brokerage Split"
                value={agent.brokerage_split}
                addClassradio1={`data-cy-split-0-${kebabCase(agent.name)}`}
                addClassradio2={`data-cy-split-1-${kebabCase(agent.name)}`}
                isPercentage={!!agent.brokerage_split_percentage}
                onValueUpdate={(e) => {
                  if (!!agent.brokerage_split_percentage) {
                    if (e.target.value >= 0 && e.target.value <= 100) {
                      setAgentBrokerageData("brokerage_split", e.target.value, agentPosition);
                    }
                  } else {
                    setAgentBrokerageData("brokerage_split", e.target.value, agentPosition);
                  }
                }}
                onRadioUpdate={(e) => {
                  if (e.target.value === "true" && agent.brokerage_split > 100) {
                    setAgentBrokerageData("brokerage_split", "", agentPosition);
                  }
                  setAgentBrokerageData(
                    "brokerage_split_percentage",
                    e.target.value === "true",
                    agentPosition,
                  );
                }}
              />
            </div>
          </Col>
          <Col size={2}>
            <div data-cy={`financial-brokerage-cap-form-parent-${kebabCase(agent.name)}`}>
              <TransactionInput
                label="Brokerage Cap"
                value={agent.brokerage_cap || ""}
                isPercentage={false}
                disableFlip={true}
                onValueUpdate={(e) => {
                  setAgentBrokerageData("brokerage_cap", e.target.value, agentPosition);
                }}
              />
            </div>
          </Col>
          <Col size={2}>
            <FormGroup className="tw-min-h-[50px]">
              <label className="tw-block tw-mb-[5px]">Brokerage Cap Reset Date</label>
              <div className="date-input">
                <DatePicker
                  timeFormat={false}
                  dateFormat="MM-DD"
                  name={"brokerage-cap-reset-date"}
                  value={moment(
                    agent.brokerage_cap_reset_month + "-" + agent.brokerage_cap_reset_day,
                    "MM-DD",
                  )}
                  onChange={(e) => {
                    const [month, day] = moment(e).format("MM-DD").split("-");
                    setAgentBrokerageData("brokerage_cap_reset_day", day, agentPosition);
                    setAgentBrokerageData("brokerage_cap_reset_month", month, agentPosition);
                  }}
                />
              </div>
            </FormGroup>
          </Col>
          <Col size={2}>
            <div data-cy={`financial-royalty-form-parent-${kebabCase(agent.name)}`}>
              <TransactionInput
                label="Royalty"
                value={agent.royalty}
                addClassradio1={`data-cy-split-2-${kebabCase(agent.name)}`}
                addClassradio2={`data-cy-split-3-${kebabCase(agent.name)}`}
                isPercentage={!!agent.royalty_percentage}
                name={`rp${agentPosition}`}
                onValueUpdate={(e) => {
                  if (!!agent.royalty_percentage) {
                    if (e.target.value >= 0 && e.target.value <= 100) {
                      setAgentBrokerageData("royalty", e.target.value, agentPosition);
                    }
                  } else {
                    setAgentBrokerageData("royalty", e.target.value, agentPosition);
                  }
                }}
                onRadioUpdate={(e) => {
                  if (e.target.value === "true" && agent.royalty > 100) {
                    setAgentBrokerageData("royalty", "", agentPosition);
                  }
                  setAgentBrokerageData("royalty_percentage", e.target.value === "true", agentPosition);
                }}
              />
            </div>
          </Col>
          <Col size={2}>
            <div data-cy={`financial-royalty-cap-form-parent-${kebabCase(agent.name)}`}>
              <TransactionInput
                label="Royalty Cap"
                value={agent.royalty_cap || ""}
                isPercentage={false}
                disableFlip={true}
                onValueUpdate={(e) => {
                  setAgentBrokerageData("royalty_cap", e.target.value, agentPosition);
                }}
              />
            </div>
          </Col>
          <Col size={2}>
            <FormGroup className="tw-min-h-[50px]">
              <label className="tw-block tw-mb-[5px]">Royalty Cap Reset Date</label>
              <div className="date-input">
                <DatePicker
                  timeFormat={false}
                  dateFormat="MM-DD"
                  name={"royalty-reset-date"}
                  value={moment(agent.royalty_cap_reset_month + "-" + agent.royalty_cap_reset_day, "MM-DD")}
                  onChange={(e) => {
                    const [month, day] = moment(e).format("MM-DD").split("-");
                    setAgentBrokerageData("royalty_cap_reset_day", day, agentPosition);
                    setAgentBrokerageData("royalty_cap_reset_month", month, agentPosition);
                  }}
                />
              </div>
            </FormGroup>
          </Col>
        </div>
      </div>
    </div>
  );
});
