import { useEffect, useState, useRef } from "react";

export const useOnMinMaxSelectedChange = (min, max, hasError, fn) => {
  const isFirstRun = useRef(true);
  return useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!hasError) {
      fn(min, max);
    }
  }, [min, max]);
};

export const useMinMaxState = (min, max) => {
  const [selectedMin, onSelectMin] = useState(min);
  const [selectedMax, onSelectMax] = useState(max);
  return [selectedMin, onSelectMin, selectedMax, onSelectMax];
};

export const useSetPAgent = ({ setFormData, primaryAgent }) =>
  useEffect(() => {
    setFormData({ created_by_brivity_agent_uuid: primaryAgent, primary_agent_uuid: primaryAgent });
  }, [primaryAgent]);

export const useSetPerson = ({ setPerson, person }) =>
  useEffect(() => {
    setPerson(person);
  }, [person]);

export const useLoadReport = ({ id, getMarketReportFn, primaryAgent, tab }) =>
  useEffect(() => {
    if (id && (tab === null || tab === 0)) {
      getMarketReportFn({ id, primary_agent_uuid: primaryAgent });
    }
  }, [id]);
