import React from "react";
import { bool, func, string } from "prop-types";

import useHandlrs from "../hooks";
import { displayHidden } from "../helpers";

import DatePicker from "../../../../shared/v2/DatePicker/DatePicker";

const ModalAddEditTaskDate = ({ payloadHandlrs, show, value }) => {
  const dateHandlr = useHandlrs(payloadHandlrs);

  return (
    <div className={`tw-mt-24px tw-w-[264px] ${displayHidden(show)}`}>
      <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-mb-8px">Date</div>
      <DatePicker onChange={(e) => dateHandlr(e, "date")} value={value} />
    </div>
  );
};

ModalAddEditTaskDate.propTypes = {
  payloadHandlrs: func,
  show: bool,
  value: string,
};

ModalAddEditTaskDate.defaultProps = {
  payloadHandlrs: null,
  show: false,
  value: null,
};

export default ModalAddEditTaskDate;
