import React from "react";
import Proptypes from "prop-types";
import TextButton from "../../../../shared/v2/TextButton";
import { getCardLink } from "../api/helpers";

const CardsCount = ({ cardName, counter, name }) => {
  const getCardUrl = () => getCardLink(cardName);

  return (
    <div className="tw-rounded tw-shadow-19 tw-py-[12px] tw-bg-white tw-text-center">
      <div className="tw-leading-[36px] tw-text-[28px] tw-font-normal tw-text-gray-75 tw-pb-[8px]">
        {counter}
      </div>
      <div className="tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 tw-pb-[8px]">
        {name}
      </div>
      <TextButton onClick={() => window.open(getCardUrl().url, "_blank")} size="small">
        CREATE
      </TextButton>
    </div>
  );
};

CardsCount.propTypes = {
  name: Proptypes.string,
  cardName: Proptypes.string,
  counter: Proptypes.number,
};
CardsCount.defaultProps = {
  name: "",
  cardName: "",
  counter: 0,
};

export default CardsCount;
