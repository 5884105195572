import React from "react";
import { string } from "prop-types";

import OptionStatusCell from "../../../../shared/TDPOptionsStatusCell";
import FieldLabel from "../../../../../shared/v2/FieldLabel";

import { DETAIL_FIELD_CONTAINER } from "../styles";

const DetailStatus = ({ id, label, dataCy, labelClassName }) => (
  <div
    className={`tw-pr-[19px] hover:tw-bg-neutral-gray-5 ${DETAIL_FIELD_CONTAINER} ${labelClassName}`}
    data-cy={dataCy}
  >
    <FieldLabel htmlFor={id} label={label} />
    <OptionStatusCell />
  </div>
);

export default DetailStatus;

DetailStatus.propTypes = {
  id: string,
  label: string,
  dataCy: string,
  labelClassName: string,
};

DetailStatus.defaultProps = {
  id: null,
  label: null,
  dataCy: null,
  labelClassName: "",
};
