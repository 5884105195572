import React, { useState } from "react";
import css from "./person-detail-styles.module.css";
import icons from "./icons.module.css";
import { produce } from "immer";
import { Row, Col } from "react-bootstrap";
import Avatar from "@shared/v2/Avatar";
import Button from "@shared/v2/Button";
import {
  ChevronDownSolidV6,
  ChevronLeftSolidV6,
  ChevronRightSolidV6,
  MergeSolidV6,
  PencilSolidV6,
} from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import IntentDropdown from "./IntentDropdown";
import StatusDropdown from "@shared/StatusDropdown";
import FrequencyDropdown from "./FrequencyDropdown";
import StagesDropDown from "../../shared/StagesDropDown/StagesDropDown";
import { useDispatch, useSelector } from "react-redux";
import { openEditPersonModal, updatePerson } from "../actions/personDetailActionCreators";
import Loading from "../../Goals/components/Loader";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import { upperFirst } from "lodash";
import Tooltip from "@shared/v2/Tooltip";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import * as contactMergeActions from "../../reducers/contactMergeReducer";
import { useContactMergeHasDuplicates } from "../../reducers/contactMergeReducer/selectors";
import useIsEditable from "../shared/useIsEditable";

const PersonInfo = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);
  const hasDuplicates = useContactMergeHasDuplicates();
  const prevPersonSlug = useSelector((state) => state.personDetail.previousSlug);
  const nextPersonSlug = useSelector((state) => state.personDetail.nextSlug);
  const [showEditPopover, setShowEditPopover] = useState(false);
  const personData = person.data?.attributes || {};

  const editButtonEnabled = useIsEditable();

  const editValue = (key, value) => {
    dispatch(
      updatePerson(
        produce(person, (draft) => {
          draft.data.attributes[key] = value;
        }),
      ),
    );
  };
  const onUpdateStage = (stage) => {
    dispatch(
      updatePerson(
        produce(person, (draft) => {
          draft.data.attributes["stage"] = stage.value;
          draft.data.attributes["stage_type"] = stage.type;
        }),
      ),
    );
  };

  const onUpdateStatus = (status) => {
    dispatch(
      updatePerson(
        produce(person, (draft) => {
          draft.data.attributes["status"] = status;
        }),
      ),
    );
  };

  return (
    <div
      id="person-info-card"
      className={`tw-relative tw-p-[20px] ${css.brivityPersonInfoCard} ${css.brivityCardBoxShadow}`}
    >
      {!person.data && <Loading />}
      <div className="tw-flex tw-justify-end tw-gap-[1px]">
        <Button
          className={hasDuplicates ? "tw-rounded-r-none" : ""}
          disabled={!editButtonEnabled}
          onClick={() => dispatch(openEditPersonModal())}
        >
          <PencilSolidV6 className="tw-mr-[4px]" size="s" />
          Edit
        </Button>
        {hasDuplicates && (
          <Popover placement="bottom-end" offset={2} open={showEditPopover} onOpenChange={setShowEditPopover}>
            <PopoverTrigger>
              <Button
                className="tw-rounded-l-none"
                onClick={() => setShowEditPopover((o) => !o)}
                disabled={
                  !currentUser.account.isAdmin ||
                  String(currentUser.person.id) === String(person.data?.id) ||
                  person.data?.attributes?.type_name !== "lead"
                }
              >
                <ChevronDownSolidV6 size="s" />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverItem
                onClick={() => {
                  setShowEditPopover(false);
                  dispatch(contactMergeActions.setOtherContact(null));
                  dispatch(contactMergeActions.setModalIsOpen(true));
                }}
                icon={<MergeSolidV6 />}
              >
                Merge Leads
              </PopoverItem>
            </PopoverContent>
          </Popover>
        )}
      </div>

      <div className="tw-flex tw-justify-between tw-items-center">
        <IconButton
          onClick={() => window.location.assign(`/people/${prevPersonSlug}`)}
          disabled={!prevPersonSlug}
        >
          <ChevronLeftSolidV6 />
        </IconButton>
        <Avatar
          src={personData.avatar_url}
          alt={`${personData.first_name || ""} ${personData.last_name || ""}`.trim()}
          size="extra-large"
        />
        <IconButton
          onClick={() => window.location.assign(`/people/${nextPersonSlug}`)}
          disabled={!nextPersonSlug}
        >
          <ChevronRightSolidV6 />
        </IconButton>
      </div>

      <div className={css.brivityPersonDetailsWrapper}>
        <h4 className={css.brivityPersonDetailsName}>
          {`${personData.first_name || ""} ${personData.last_name || ""}`.trim() || "No Name"}
        </h4>
        <h6 className={css.brivityPersonDetailsSource}>
          <span className="tw-text-color-999 tw-text-[12px] tw-leading-[20px] tw-font-semibold">SOURCE:</span>
          <span className="tw-ml-[8px] tw-text-neutral-grey-75 tw-text-[12px] tw-leading-[20px] tw-font-bold">
            {personData.source}
          </span>
        </h6>
        <Tooltip
          placement="bottom"
          trigger={
            <h6 className="tw-text-neutral-grey-75 tw-text-[12px] tw-leading-[16px] tw-font-bold">
              Registered {moment(personData.created_at).format("MMMM Do YYYY")}
            </h6>
          }
          content={personData.created_at_formatted}
        />
        <hr className="tw-my-16px tw-border-[1px] tw-border-gray-10 " />
        <Row className="tw-px-[10px] tw-h-[24px]">
          <Col xs={5} className="tw-p-0 tw-m-0">
            <div className="tw-text-color-999 tw-text-left tw-text-[10px] tw-leading-[20px] tw-font-semibold">
              LAST INTERACTION:
            </div>
          </Col>
          <Col xs={7} className="tw-p-0 tw-m-0">
            <div
              className={`tw-text-left tw-text-[12px] tw-leading-[20px] tw-font-bold ${
                !personData.last_interacted_at ? "tw-text-[#F2714D]" : "tw-text-neutral-grey-75"
              }`}
            >
              {!personData.last_interacted_at ? "None" : moment(personData.last_interacted_at).fromNow()}
            </div>
          </Col>
        </Row>

        <Row className="tw-px-[10px] tw-h-[24px] tw-mt-[12px]">
          <Col xs={5} className="tw-pt-[3px] tw-p-0 tw-m-0">
            <div className="tw-text-color-999 tw-text-left tw-text-[10px] tw-leading-[20px] tw-font-semibold">
              STAGE:
            </div>
          </Col>
          <Col xs={7} className="tw-p-0 tw-m-0">
            {personData.type_name == "lead" ? (
              <StagesDropDown
                optionClassName="tw-h-[240px] tw-overflow-scroll"
                selectedStage={personData.stage}
                onUpdateStage={onUpdateStage}
              />
            ) : (
              <div
                className="tw-flex tw-justify-start tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center
                  tw-text-center tw-align-middle tw-w-[180px] tw-h-[24px] tw-text-neutral-gray-30 tw-text-11px tw-leading-[16px]
                  tw-font-bold"
              >
                <Tooltip
                  placement="right"
                  trigger="None"
                  content="Stages are unavailable for this person type"
                />
              </div>
            )}
          </Col>
        </Row>

        <Row className="tw-px-[10px] tw-h-[24px] tw-mt-[12px]">
          <Col xs={5} className="tw-pt-[3px] tw-p-0 tw-m-0">
            <div className="tw-text-color-999 tw-text-left tw-text-[10px] tw-leading-[20px] tw-font-semibold">
              BRIVITY STATUS:
            </div>
          </Col>
          <Col xs={7} className="tw-p-0 tw-m-0">
            <StatusDropdown
              person={person.data}
              className="tw-float-left"
              onChange={onUpdateStatus}
              statusOptions={person.meta?.status_options.filter(([, value]) => value !== "past")}
              status={person.data?.attributes?.status || "new"}
            />
          </Col>
        </Row>
        {/* TODO: Users start of with a null lead_type. Do we want to dispaly anything */}
        {personData.lead_type && (
          <Row className="tw-px-[10px] tw-h-[24px] tw-mt-[12px]">
            <Col xs={5} className="tw-pt-[3px] tw-p-0 tw-m-0">
              <div className="tw-text-color-999 tw-text-left tw-text-[10px] tw-leading-[20px] tw-font-semibold">
                INTENT:
              </div>
            </Col>
            <Col xs={7} className="tw-p-0 tw-m-0">
              <IntentDropdown className="tw-float-left tw-ml-[0px]" />
            </Col>
          </Row>
        )}
        <Row className="tw-px-[10px] tw-mt-[12px]">
          <Col xs={5} className="tw-p-0 tw-m-0">
            <div className="tw-text-color-999 tw-text-left tw-text-[10px] tw-leading-[20px] tw-font-semibold">
              PERSON TYPE:
            </div>
          </Col>
          <Col xs={7} className="tw-p-0 tw-m-0">
            <div className="tw-text-left tw-text-neutral-grey-75 tw-text-[12px] tw-leading-[20px] tw-font-bold ">
              {upperFirst(personData.type_name)}
            </div>
          </Col>
        </Row>
        <div className="tw-text-color-999 tw-text-[10px] tw-leading-[16px] tw-font-bold">
          <hr className="tw-my-16px tw-border-[1px] tw-border-gray-10 " />
          <span className="tw-mr-[12px]">SHOW IN LEADS INDEX?</span>
          <div
            className={`tw-align-middle tw-m-0 tw-h-[20px] tw-min-h-[0px] tw-inline-block ${icons.checkbox}`}
          >
            <label className={`${personData.is_lead ? icons.checked : ""}`}>
              <input type="checkbox" onClick={() => editValue("is_lead", !personData.is_lead)} />
            </label>
          </div>
        </div>
        {personData.granted_client_portal_access && (
          <div className="tw-text-color-999 tw-text-[10px] tw-leading-[16px] tw-font-bold">
            <div className="tw-flex tw-justify-center tw-items-center">
              <span className="tw-mr-[12px] tw-p-[4px]">UPDATE FREQUENCY?</span>
              <FrequencyDropdown />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonInfo;
