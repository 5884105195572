import React from "react";
import PropTypes from "prop-types";

const ContactContainer = ({ className, title, children }) => (
  <div
    className={`tw-bg-white tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 tw-rounded-[8px] tw-p-12px tw-flex-1 tw-flex tw-flex-col tw-gap-[12px] tw-overflow-hidden ${className}`}
  >
    <h4 className="tw-m-0 tw-text-14d tw-text-gray-50 tw-font-semibold">{title}</h4>
    <div className="tw-flex tw-flex-col tw-gap-[12px] tw-overflow-auto tw-m-[-12px] tw-p-[12px]">
      {children}
    </div>
  </div>
);

ContactContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ContactContainer.defaultProps = {
  className: "",
  title: "",
};

export default ContactContainer;
