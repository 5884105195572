import React from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import { displayHidden } from "../helpers";

import useModalHandlr from "./hooks";

import { createTasksByUnassignedFilter } from "../actions/creators";

import { SecondaryFilter, Clearer } from "./fractals";
import ModalSecondaryFilterMember from "../ModalSecondaryFilterMember";
import ModalSecondaryFilterAutoPlans from "../ModalSecondaryFilterAutoPlans ";

import SECONDARY_FILTERS from "./fixtures";

const Drawer = ({ show, toggleDrawer, onCreateTasksByUnassignedFilter }) => {
  const {
    modalTeamMemberActive,
    setModalTeamMemberActive,
    modalAutoPlansActive,
    setModalAutoPlansActive,
    secondaryFilterHandlr,
  } = useModalHandlr({
    toggleDrawer,
    onCreateTasksByUnassignedFilter,
  });

  return (
    <div className="tw-w-[264px] tw-shadow-dropdown tw-absolute tw-mt-[24px]">
      <ModalSecondaryFilterMember
        show={modalTeamMemberActive}
        setModalTeamMemberActive={setModalTeamMemberActive}
      />
      <ModalSecondaryFilterAutoPlans
        show={modalAutoPlansActive}
        setModalAutoPlansActive={setModalAutoPlansActive}
      />
      <div className={`tw-w-[264px] tw-bg-white tw-border-neutral-gray-15 ${displayHidden(show)}`}>
        {SECONDARY_FILTERS.map((filterTxt) => (
          <SecondaryFilter key={filterTxt} clicked={() => secondaryFilterHandlr(filterTxt)}>
            {filterTxt}
          </SecondaryFilter>
        ))}
        <div className="tw-flex tw-justify-end tw-p-[12px] tw-border-solid tw-border-t-[1px] tw-border-x-0 tw-border-b-0 tw-border-gray-15">
          <Clearer toggleDrawer={toggleDrawer} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onCreateTasksByUnassignedFilter: () => dispatch(createTasksByUnassignedFilter()),
});

Drawer.propTypes = {
  show: bool,
  toggleDrawer: func,
  onCreateTasksByUnassignedFilter: func,
};

Drawer.defaultProps = {
  show: false,
  toggleDrawer: () => {},
  onCreateTasksByUnassignedFilter: () => {},
};

export default connect(null, mapDispatchToProps)(Drawer);
