import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { init } from "./actions/creators";
import View from "./View";

const Main = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init(props));
  }, []);

  return <View />;
};

export default Main;
