import React from "react";

import { formatPrice } from "../helpers";
import RingChart from "../../../shared/v2/RingChart/RingChart";

const AgentStats = ({listingStats = {}}) => {
  if (!listingStats.closed || !listingStats.stats) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
      <div className="tw-pr-32px">
        <ClosingSection data={listingStats.closed} />
      </div>
      <SummariesSection data={listingStats.stats} />
    </div>
  );
};

export default AgentStats;

const ClosingSection = ({ data: {total, seller, buyer} }) => {
  const sellerVolume =  seller.volume ? formatPrice(seller.volume) : Dash;
  const buyerVolume = buyer.volume ? formatPrice(buyer.volume) : Dash;

  return (
    <div className="tw-flex tw-flex-row">
      <div className="tw-pr-16px">
        <RingChart data={[{color: "#fab957", value: buyer.ratio}, {color: "#59c4c4", value: seller.ratio + buyer.ratio}]} title="Total" label={total} variant="segmented" />
      </div>
      <div className="tw-flex tw-flex-col">
        <span className="tw-text-14px tw-font-semibold tw-text-gray-75 tw-pb-10px">Closings Breakdown</span>
        <div className="tw-flex tw-flex-row">
          <div className="tw-flex tw-flex-col tw-items-center tw-pr-20px tw-text-12px">
            <span className="tw-font-semibold tw-text-gray-50 tw-pb-8px">Type</span>
            <AgentType agentType="Seller" includePadding={true} />
            <AgentType agentType="Buyer"/>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-pr-20px tw-text-12px">
            <span className="tw-font-semibold tw-text-gray-50 tw-pb-8px">Units</span>
            <span className="tw-pb-8px">{seller.units ? seller.units : Dash}</span>
            <span>{buyer.units ? buyer.units : Dash}</span>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-text-12px">
            <span className="tw-font-semibold tw-text-gray-50 tw-pb-8px">Volume</span>
            <span className="tw-pb-8px">{sellerVolume}</span>
            <span>{buyerVolume}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummariesSection = ({data: { avgClosePrice, avgListPrice, grossProduction, totalListings }}) => {
  const style = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    rowGap: "10px",
    columnGap: "12px"
  };

  return (
    <div style={style}>
      <SummaryBlock value={avgListPrice} label="Avg Listing Price" />
      <SummaryBlock value={avgClosePrice} label="Avg Closing Price" />
      <SummaryBlock value={totalListings} label="Total Listings" asPrice={false} />
      <SummaryBlock value={grossProduction} label="Gross Production" />
    </div>
  );
};

const SummaryBlock = ({value, label, asPrice = true}) => {
  value = asPrice ? formatPrice(value) : value;

  return (
    <div className="tw-flex tw-flex-col tw-w-[163px] tw-h-60px tw-pt-12px tw-pl-16px tw-border tw-border-solid tw-border-gray-10 tw-rounded-xl">
      <span className="tw-text-14px tw-font-bold tw-text-gray-75">{value ?? Dash}</span>
      <span className="tw-text-12px tw-font-semibold tw-text-gray-50">{label}</span>
    </div>
  )
};

const AgentType = ({agentType, includePadding = false}) => {
  const color = agentType === "Buyer" ? "tw-bg-yellow-50" : "tw-bg-teal";

  return (
    <div className={`tw-inline-flex tw-space-x-2 tw-items-center ${includePadding ? "tw-pb-8px" : ""}`}>
      <div className={`tw-rounded-full tw-h-8px tw-w-8px tw-flex-1 ${color}`}></div>
      <span className="tw-flex-1">{agentType}</span>
    </div>
  );
};

const Dash = <>&mdash;</>;
