import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@shared/v2/IconButton";
import Typography from "@shared/v2/Typography";
import { ChevronDownSolidV6 } from "@shared/v2/Icomoon";
import PanelCollapse from "@shared/v1/Panel/PanelCollapse";

const CollapsiblePostCard = ({ action, children, title }) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="tw-min-w-[300px]">
      <div className="tw-bg-[#fafafa] tw-rounded-t-[8px] tw-border-solid tw-border-[1px] tw-border-gray-10 tw-pl-[10px] tw-pr-[20px] tw-h-[48px] tw-flex tw-justify-between tw-items-center tw-gap-[24px]">
        <div className="tw-flex tw-items-center tw-gap-[4px]">
          <IconButton schema="transparent" onClick={() => setOpen((o) => !o)}>
            <ChevronDownSolidV6 className={`tw-transition-transform ${open ? "" : "tw-rotate-180"}`} />
          </IconButton>
          <Typography variant="headline3">{title}</Typography>
        </div>
        {action}
      </div>
      <PanelCollapse
        transitionDuration={150}
        collapsed={!open}
        className="tw-relative tw-bg-white tw-rounded-b-[8px] tw-border-solid tw-border-[1px] tw-border-t-0 tw-border-gray-10"
      >
        <div className="tw-px-[20px] tw-py-[24px]">{children}</div>
      </PanelCollapse>
    </div>
  );
};

CollapsiblePostCard.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

CollapsiblePostCard.defaultProps = {
  action: null,
};

export default CollapsiblePostCard;
