/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BullhornSolidV6 = (props) => (
  <Icon {...props} name="Bullhorn Solid V6">
    <path d="M15 5.613c.581.275 1 1.016 1 1.887s-.419 1.613-1 1.888V14a.998.998 0 0 1-1.707.706l-1.366-1.391c-1.5-1.5-3.534-2.316-5.656-2.316h-.272v4a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-4a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h4.272a8.002 8.002 0 0 0 5.656-2.343L13.293.292A1 1 0 0 1 15 .999v4.612zM6.272 6H6v3h.272c2.494 0 4.891.931 6.728 2.603V3.397A9.995 9.995 0 0 1 6.272 6z" />
  </Icon>
);

export default BullhornSolidV6;
