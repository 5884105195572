import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import { clearErrors, upsertCategory, upsertTag } from "../../reducers/websiteReducer/websiteReducer";
import usePostCountForCategoryTag from "../hooks/usePostCountForCategoryTag";
import { useCategoriesError, useTagsError } from "../../reducers/websiteReducer/selectors";

const CategoryTagModal = ({ open, onClose, value: propValue }) => {
  const dispatch = useDispatch();
  const categoriesError = useCategoriesError();
  const tagsError = useTagsError();
  const [value, setValue] = useState(propValue);
  const type = Object.prototype.hasOwnProperty.call(value, "tag") ? "tag" : "category";
  const typeName = type === "tag" ? "Tag" : "Category";

  const count = usePostCountForCategoryTag({ type, uuid: value?.uuid });

  useEffect(() => {
    setValue(propValue);
    if (!open) dispatch(clearErrors());
  }, [open, propValue]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title={`${value.uuid ? "Edit" : "Add"} ${typeName}`} closeButton />

      <Modal.Body className="tw-py-[8px] tw-flex tw-flex-col tw-gap-[32px] tw-overflow-auto">
        {value.uuid && (
          <Alert
            variant="gray"
            text={
              <div className="tw-flex tw-items-center tw-gap-[16px]">
                <CircleInfoSolidV6 size="l" className="tw-text-gray-50" />
                Editing this category will apply the change to {count} posts.
              </div>
            }
          />
        )}
        <TextInput
          autoComplete="off"
          label={`${typeName} Name`}
          isRequired
          maxLength={type === "tag" ? 32 : 64}
          value={value[type] || ""}
          onChange={(e) => {
            const { value: val } = e.target;
            setValue((o) => ({ ...o, [type]: val }));
          }}
          error={categoriesError || tagsError}
        />
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button schema="tertiary" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          onClick={() => {
            const action = type === "tag" ? upsertTag : upsertCategory;
            dispatch(action(value)).then(({ error }) => !error && onClose());
          }}
          disabled={!value[type]?.trim()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CategoryTagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.shape(),
};

CategoryTagModal.defaultProps = {
  value: {},
};

export default CategoryTagModal;
