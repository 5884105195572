import axios from "axios";

const basePath = "/api/v4/transactions";
const apiDocumentsUrl = (uuid) => `${basePath}/${uuid}/documents`;

export const getDocumentsFromApi = async (uuid, page, search, orderBy, orderDir) => {
  const params = {
    ...(page && { page }),
    ...(search && { query: search }),
    ...(orderBy && { order_by: orderBy }),
    ...(orderDir && { order_dir: orderDir }),
  };
  const queryParams = new URLSearchParams(params);

  return axios.get(`${apiDocumentsUrl(uuid)}?${queryParams.toString()}`);
};

export const getAllDocumentViewersFromApi = async (uuid, documentId) =>
  axios.get(`${apiDocumentsUrl(uuid)}/${documentId}/viewers`);

export const searchDocumentsFromApi = async (uuid, search, orderBy, orderDir) => {
  const params = {
    ...(search && { query: search }),
    ...(orderBy && { order_by: orderBy }),
    ...(orderDir && { order_dir: orderDir }),
  };

  const queryParams = new URLSearchParams(params);

  return axios.get(`${apiDocumentsUrl(uuid)}?${queryParams.toString()}`);
};

export const sortDocumentsFromApi = async (uuid, orderBy, orderDir, search) => {
  const params = {
    ...(orderBy && { order_by: orderBy }),
    ...(orderDir && { order_dir: orderDir }),
    ...(search && { query: search }),
  };

  const queryParams = new URLSearchParams(params);

  return axios.get(`${apiDocumentsUrl(uuid)}?${queryParams.toString()}`);
};

export const renameDocumentFromApi = async (uuid, documentId, newName) =>
  axios.patch(`${apiDocumentsUrl(uuid)}/${documentId}/rename`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    file_name: newName,
  });

export const uploadDocumentFromApi = async (uuid, formData) =>
  axios.post(apiDocumentsUrl(uuid), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-csrf-token": window.ReactOnRails.authenticityToken(),
    },
  });

export const deleteDocumentsFromApi = async (uuid, documentIds) =>
  axios.delete(`${apiDocumentsUrl(uuid)}/batch_destroy`, {
    headers: {
      "x-csrf-token": window.ReactOnRails.authenticityToken(),
    },
    data: {
      document_ids: documentIds,
    },
  });
