import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

const shortStatus = {
  'new': 'New',
  'unqualified': 'Unqual',
  'watch': 'Watch',
  'nurture': 'Nurt',
  'hot': 'Hot',
  'past-client': 'Past',
  'inactive': 'Inactiv',
  'archived': 'Arch',
  'trash': 'Trash'
}

export default class KwklyPropertyView extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false,
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  details = () => {
    const fields = this.event.timeline_fields;

    return (
      <div>
        {'Contact viewed a property using KWKLY'}
        <div>Location: {this.address()}</div>
        <div>Keyword: {this.keyword()}</div>
      </div>
    )
  }

  keyword = () => this.event.timeline_fields.keyword

  address = () => {
    const fields = this.event.timeline_fields
    const address = `${fields.address} ${fields.city} ${fields.state} ${fields.zip}`

    return address
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-location-arrow timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                KWKLY property viewed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row bold-detail details-row details-header">
                Details:
              </div>
              {this.details()}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
