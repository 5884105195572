import React from "react";
import {Radio} from 'react-bootstrap';
import {incomeBtnHandlr, expenseBtnHandlr} from './handlers';

export const IncomeExpenseRadio = props => {
  const { modalThis, setExpenseValue } = props;

  return (
	<>
	  <Radio
		className={modalThis.state.type === 'revenue' ? 'transaction-input-flip selected radio-inline' : 'transaction-input-flip radio-inline'}
		name='type'
		value='revenue'
		inline={true}
		onChange={e => incomeBtnHandlr(e, modalThis)}
		checked={modalThis.state.type === 'revenue'}>
		Income
	  </Radio>
	  <Radio
		  className={modalThis.state.type === 'expense' ? 'transaction-input-flip selected radio-inline' : 'transaction-input-flip radio-inline'}
		  name='type'
		  value='expense'
		  inline={true}
		  onChange={e => expenseBtnHandlr(e, modalThis, setExpenseValue)}
		  checked={modalThis.state.type === 'expense'}>
		  Expense
		</Radio>
	</>
  )
}; 
