import React from "react";
import { func } from "prop-types";

import Button from "@shared/v2/Button";
import { ACTION_BTN_LABEL } from "../../styles";

const AddNewActions = ({taskBtnOnClick, emailBtnOnClick, textBtnOnClick}) => (
  <div className="tw-flex flex-row flex-items-center tw-mt-[32px]">
    <div className={ACTION_BTN_LABEL}>Add a new</div>
    <div className="tw-flex tw-gap-[10px]">
      <Button
        key='TaskNewActions'
        size="medium"
        schema='secondary'
        onClick={taskBtnOnClick}
      >
        Task
      </Button>
      <Button
        key='EmailNewActions'
        size="medium"
        schema='secondary'
        onClick={emailBtnOnClick}
      >
        Email
      </Button>
      <Button
        key='TextNewActions'
        size="medium"
        schema='secondary'
        onClick={textBtnOnClick}
      >
        Text
      </Button>                    
    </div>
  </div>
)
export default AddNewActions;

AddNewActions.propTypes = {
  taskBtnOnClick: func.isRequired, 
  emailBtnOnClick: func.isRequired,  
  textBtnOnClick: func.isRequired,
}
