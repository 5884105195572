import React from "react";
import { func, bool } from "prop-types";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";

const ReplaceMLSPhotoModal = ({ modalOpen, closeModal, handleReplace }) => (
  <Modal
    className="tw-flex tw-justify-center tw-items-center"
    // 636px = 616px from Figma + 10px for each horizontal padding (20px)
    dialogClassName="tw-w-[636px] tw-h-auto"
    contentClassName="tw-w-full tw-h-full"
    backdrop="static"
    show={modalOpen}
    onHide={closeModal}
    data-cy="replace-mls-photo-modal"
  >
    <Modal.Header
      titleClassName="tw-mb-32px tw-text-neutral-gray-75"
      title="Replace with MLS Photos"
      closeButton
    />

    <Modal.Body className="tw-mb-32px">
      <div className="tw-w-[392px]">
        Warning: Syncing photos from the MLS listing will replace any manually uploaded photos.
      </div>
    </Modal.Body>

    <Modal.Footer className="tw-flex tw-justify-between">
      <Button
        onClick={closeModal}
        className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
        schema="tertiary"
        size="medium"
        data-cy="mls-photo-cancel-button"
      >
        cancel
      </Button>
      <Button
        className="tw-flex tw-items-center"
        size="medium"
        schema="primary"
        onClick={handleReplace}
        data-cy="mls-photo-replace-button"
      >
        replace
      </Button>
    </Modal.Footer>
  </Modal>
);

ReplaceMLSPhotoModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  handleReplace: func,
};

ReplaceMLSPhotoModal.defaultProps = {
  modalOpen: false,
  closeModal: () => {},
  handleReplace: () => {},
};

export default ReplaceMLSPhotoModal;
