import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import statusIntentCss from "./statusIntentDropdown.module.css";
import button from "./button.module.css";
import DropdownOptions from "./DropdownOptions";
import { updatePerson } from "../actions/personDetailActionCreators";
import { produce } from "immer";

const FREQUENCY_OPTIONS = [
  ["Daily", "daily"],
  ["Weekly", "weekly"],
  ["Never", "never"],
];

function FrequencyDropdown() {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const [expanded, setExpanded] = useState(false);

  const editValue = (key, value) => {
    setExpanded(false);
    dispatch(
      updatePerson(
        produce(person, (draft) => {
          draft.data.attributes[key] = value;
        }),
      ),
    );
  };

  if (!person.data) return null;

  return (
    <div
      className={`tw-p-[0px] ${button.btnSm} ${button.btnDropdown} ${statusIntentCss.selectedStatusIntent} ${
        expanded && statusIntentCss.active
      }`}
      tabIndex="0"
      onClick={() => {
        setExpanded(!expanded);
      }}
      onBlur={() => {
        setExpanded(false);
      }}
    >
      <div className={statusIntentCss.statusIntentButton}>
        {FREQUENCY_OPTIONS.map(
          (s) =>
            person.data.attributes.default_email_frequency == s[1] && (
              <span key={s[1]} className={statusIntentCss.selectedFrequencyText}>
                {s[0]}
                <i className="fa fa-caret-down tw-ml-[6px]" />
              </span>
            ),
        )}
        {expanded && (
          <DropdownOptions
            options={FREQUENCY_OPTIONS}
            editValue={editValue}
            editKey={"default_email_frequency"}
          />
        )}
      </div>
    </div>
  );
}

export default FrequencyDropdown;
