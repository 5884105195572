import React, { useState } from "react";
import Proptypes from "prop-types";
import { Row, Col } from "../../../../shared/v1";
import Button from "../../../../shared/v2/Button";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import IconButton from "../../../../shared/v2/IconButton";
import MultiUser from "../../../../shared/v2/Icomoon/Icons/MultiUser";
import ChevronUp from "../../../../shared/v2/Icomoon/Icons/ChevronUp";
import { DollarSignSolidV6, EyeSolidV3, TintSolidV3 } from "../../../../shared/v2/Icomoon";
import { getCardLink } from "../api/helpers";

const CollapsibleCards = ({ name, impressions, leads, recipients, budget, counter, children }) => {
  const [isOpen, setisOpen] = useState(true);
  const getCardUrl = () => getCardLink(name);

  return (
    <div className="tw-transition tw-ease-out	tw-delay-300 tw-drop-shadow-19">
      <div
        className={`tw-mb-[10px] tw-px-[16px] tw-py-[12px] tw-h-auto tw-rounded-lg tw-border tw-border-gray-10 tw-border-solid 
      ${!isOpen && "tw-max-h-[48px] tw-overflow-hidden"}`}
      >
        <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pb-[4px]">
          <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0" size={12}>
            <div className="tw-flex tw-flex-row tw-justify-between">
              <div className="tw-flex tw-flex-row tw-items-center">
                <span className="tw-text-[18px] tw-pr-[8px] tw-font-bold tw-text-gray-75 ">{counter}</span>
                <span className="tw-pr-[24px] tw-text-[14px] tw-font-bold tw-text-gray-75 ">{name}</span>
                {impressions !== undefined && (
                  <CardSnippet name="Impressions" value={impressions} icon={EyeSolidV3} />
                )}
                {leads !== undefined && <CardSnippet name="Leads" value={leads} icon={TintSolidV3} />}
                {recipients !== undefined && (
                  <CardSnippet name="Recipients" value={recipients} icon={MultiUser} />
                )}
                {budget !== undefined && (
                  <CardSnippet name="Budget" value={`$${budget}`} icon={DollarSignSolidV6} />
                )}
              </div>
              <div className="tw-flex tw-items-center tw-gap-2">
                <Button
                  onClick={() => window.open(getCardUrl().url, "_blank")}
                  className="tw-gap-1	tw-flex tw-items-center"
                  schema="primary"
                  size="small"
                >
                  <Plus size="s" />
                  NEW
                </Button>
                <IconButton
                  className={isOpen ? "tw-pt-[0px]" : "tw-pt-[4px]"}
                  onClick={() => setisOpen(!isOpen)}
                  size="small"
                  schema="tertiary"
                >
                  <ChevronUp size="s" className={!isOpen && "tw-rotate-180"} />
                </IconButton>
              </div>
            </div>
          </Col>
        </Row>
        {children}
      </div>
    </div>
  );
};

const CardSnippet = ({ name, value, icon }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-pr-[24px] tw-text-[12px] tw-font-normal tw-text-gray-75 ">
    {icon({ size: "m", className: "tw-mr-[4px]" })}
    <span className="tw-mr-[4px]">{name}:</span>
    <span className="tw-font-semibold">{value}</span>
  </div>
);

CardSnippet.propTypes = {
  name: Proptypes.string.isRequired,
  icon: Proptypes.func.isRequired,
  value: Proptypes.number,
};

CardSnippet.defaultProps = {
  value: null,
};

CollapsibleCards.propTypes = {
  name: Proptypes.string,
  impressions: Proptypes.number,
  leads: Proptypes.number,
  recipients: Proptypes.number,
  budget: Proptypes.number,
  counter: Proptypes.number,
  children: Proptypes.oneOfType([Proptypes.arrayOf(Proptypes.node), Proptypes.node]),
};
CollapsibleCards.defaultProps = {
  name: "",
  impressions: undefined,
  leads: undefined,
  recipients: undefined,
  budget: undefined,
  counter: undefined,
  children: null,
};
export default CollapsibleCards;
