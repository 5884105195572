import React from "react";
import { AgentActivityHeaders, LeadSourcesHeaders, TasksHeaders } from "./";
import AgentCallsHeaders from "./AgentCallsHeaders";
import AgentTextsHeaders from "./AgentTextsHeaders";
class ReportHeaderSortContainer extends React.PureComponent {
  reportHeaders = {
    teamDashboard: () => null,
    agentActivity: AgentActivityHeaders,
    leadSource: LeadSourcesHeaders,
    tasks: TasksHeaders,
    appointments: () => null,
    agentCalls: AgentCallsHeaders,
    agentTexts: AgentTextsHeaders,
    leaderboards: () => null,
  };

  render() {
    const ReportName = this.reportHeaders[this.props.activeReport || "agentActivity"];
    return <ReportName {...this.props} />;
  }
}
export default ReportHeaderSortContainer;
