import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { ChevronLeft, ChevronRight, CaretDown } from "../Icomoon";

import { UIMODE } from "./utils/constants";

import styleSchema from "./DateSelector.styles";

const renderHeader = (uiMode, navigationDate, noYear) => {
  if (noYear) return navigationDate.format("MMM");

  switch (uiMode) {
    case UIMODE.DAY:
      return navigationDate.format("MMM YYYY");
    case UIMODE.MONTH:
      return navigationDate.format("YYYY");
    default:
      return "";
  }
};

const arialLabelMonthYearNext = (navigationDate, uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return `Go to next month: ${navigationDate.clone().add(1, "month").format("MMM YYYY")} to select a day`;
    case UIMODE.MONTH:
      return `Go to next year: ${navigationDate.clone().add(1, "year").format("YYYY")} to select a month`;
    default:
      return "";
  }
};

const arialLabelMonthYearPrevious = (navigationDate, uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return `Go to previous month: ${navigationDate
        .clone()
        .subtract(1, "month")
        .format("MMM YYYY")} to select a day`;
    case UIMODE.MONTH:
      return `Go to previous year: ${navigationDate
        .clone()
        .subtract(1, "year")
        .format("YYYY")} to select a month`;
    default:
      return "";
  }
};

const arialLabelMonthYearUI = (uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return "Change user interface from day select to month select";
    case UIMODE.MONTH:
      return "Change user interface from month select to day select";
    default:
      return "";
  }
};

const testCurrentPeriod = (uiMode, navigationDate) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return navigationDate.format("YYYY-MM");
    case UIMODE.MONTH:
      return navigationDate.format("YYYY");
    default:
      return "";
  }
};

const PeriodNavigation = ({
  navigationDate,
  handleNavigationClick,
  uiMode,
  handleChangeUI,
  minDate,
  maxDate,
  noYear,
}) => {
  // noYear prop used to toggle prev and next if noYear is true && navigationDate is Jan or Dec respectively
  const checkDateBoundary = (isNext) => {
    if (noYear) {
      return isNext ? navigationDate.format("MMM") === "Dec" : navigationDate.format("MMM") === "Jan";
    }

    const dateBoundary = isNext ? maxDate : minDate;
    if (!dateBoundary) return false;

    const unit = uiMode === UIMODE.MONTH ? "year" : "month";
    const comparator = isNext ? "isSameOrAfter" : "isSameOrBefore";

    return navigationDate[comparator](moment(dateBoundary), unit);
  };

  const isPrevDisabled = checkDateBoundary(false);
  const isNextDisabled = checkDateBoundary(true);

  // handles click on prev and next buttons based on noYear, if noYear it will be month otherwise its either month or year.
  const handleNavigationChange = (isNext) => {
    const operation = isNext ? "add" : "subtract";
    const amount = 1;
    let unit = "month";

    if (!noYear) {
      if (uiMode === UIMODE.MONTH) {
        unit = "year";
      }
    }

    return handleNavigationClick(navigationDate[operation](amount, unit));
  };

  const handleClickPrevious = () => handleNavigationChange(false);
  const handleClickNext = () => handleNavigationChange(true);

  return (
    <div className="tw-flex tw-flex-row tw-justify-between tw-pb-8px">
      <button
        type="button"
        className={styleSchema.navigation}
        onClick={() => handleClickPrevious()}
        aria-label={arialLabelMonthYearPrevious(navigationDate, uiMode)}
        data-test-navigation-previous
        disabled={isPrevDisabled}
      >
        <ChevronLeft size="m" />
      </button>
      <button
        type="button"
        className={styleSchema.navigationMode}
        onClick={() => handleChangeUI()}
        aria-label={arialLabelMonthYearUI(uiMode)}
        data-test-navigation-current-period={testCurrentPeriod(uiMode, navigationDate)}
        data-test-ui-mode={uiMode}
      >
        {renderHeader(uiMode, navigationDate, noYear)} <CaretDown size="m" className="tw-ml-4px" />
      </button>
      <button
        type="button"
        className={styleSchema.navigation}
        onClick={() => handleClickNext()}
        aria-label={arialLabelMonthYearNext(navigationDate, uiMode)}
        data-test-navigation-next
        disabled={isNextDisabled}
      >
        <ChevronRight size="m" />
      </button>
    </div>
  );
};

PeriodNavigation.propTypes = {
  navigationDate: PropTypes.instanceOf(moment),
  handleNavigationClick: PropTypes.func,
  handleChangeUI: PropTypes.func,
  uiMode: PropTypes.oneOf(Object.values(UIMODE)),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  noYear: PropTypes.bool,
};

PeriodNavigation.defaultProps = {
  navigationDate: moment(),
  handleNavigationClick: () => {},
  handleChangeUI: () => {},
  uiMode: UIMODE.DAY,
  minDate: null,
  maxDate: null,
  noYear: false,
};

export default PeriodNavigation;
