import React from "react";
import PropTypes from "prop-types";
import FieldLabel from "@shared/v2/FieldLabel";
import TextInput from "@shared/v2/TextInput";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import FlatPercentToggle from "@shared/v2/FlatPercentToggle";
import FlatPercentInput from "@shared/v2/FlatPercentInput";
import { formatNumber } from "@shared/v2/inputFormatUtils";
import ClientIntentTextBtns from "../../../../Transactions/ClientIntentTextButtons/ClientIntentTextButtons";
import DropdownTeamMembers from "../../../../Transactions/DropdownTeamMembers";
import SearchPeople from "../../../../QuickSearch/components/SearchPeople";

const CLIENT_INTENT_OPTIONS = [
  { label: "Buyer", value: "buyer" },
  { label: "Seller", value: "seller" },
  { label: "Tenant", value: "tenant" },
  { label: "Landlord", value: "landlord" },
];

const formatNumberIfFlat = (value, type, commas) => (type === "flat" ? formatNumber(value, commas) : value);

const ReferralTransactionsField = ({
  transactionTitle,
  setTransactionTitle,
  propertyType,
  setPropertyType,
  referralFee,
  setReferralFee,
  referralAgent,
  setReferralAgent,
  client,
  setClient,
  clientIntent,
  setClientIntent,
  isReferralFeePercentage,
  setIsReferralFeePercentage,
  propertyTypes,
}) => {
  return (
    <div>
      <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
        <div className="tw-col-span-1">
          <FieldLabel label="Transaction Title" isRequired className="tw-mb-3" />
          <TextInput value={transactionTitle} onChange={(e) => setTransactionTitle(e.target.value)} />
        </div>
        <div className="tw-col-span-1">
          <div className="tw-flex tw-items-center tw-gap-[4px] tw-w-[184px]">
            <FlatPercentInput
              className="tw-w-[184px]"
              label="Referral Fee"
              value={formatNumberIfFlat(referralFee, isReferralFeePercentage ? "percent" : "flat", true)}
              placeholder="0.00"
              isPercentage={isReferralFeePercentage}
              onChange={(e) => {
                const value = e.target.value.trim() || null;
                setReferralFee(value);
              }}
            />
            <FlatPercentToggle
              className="tw-mt-28px"
              isPercentage={isReferralFeePercentage}
              onChange={(_, newIsPercentage) => {
                setReferralFee("");
                setIsReferralFeePercentage(newIsPercentage);
              }}
            />
          </div>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
        <div className="tw-col-span-1">
          <FieldLabel label="Referring Agent" isRequired />
          <DropdownTeamMembers
            value={referralAgent}
            setValue={({ label, value }) => setReferralAgent({ label, value })}
          />
        </div>
        <div className="tw-col-span-1">
          <FieldLabel label="Client" isRequired />
          <SearchPeople
            labelClassName="tw-relative"
            drodownClassName="tw-absolute tw-bg-white tw-w-[100%] tw-max-h-[170px]"
            searchBoxValue={client?.label}
            onSelectFunction={(val) => setClient({ label: val.label, value: val.object_id })}
          />
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
        <div className="tw-col-span-2">
          <FieldLabel label="Client Intent" className="tw-mb-[8px]" />
          <ClientIntentTextBtns
            size="medium"
            options={CLIENT_INTENT_OPTIONS}
            value={clientIntent}
            onChange={(e) => setClientIntent(e.target.value)}
          />
        </div>
      </div>
      {clientIntent === "seller" && (
        <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
          <div className="tw-col-span-2">
            <Dropdown
              menuShouldComeToFront
              label="Property Type"
              onChange={(val) => setPropertyType(val)}
              value={propertyType}
              options={propertyTypes}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const optionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
ReferralTransactionsField.propTypes = {
  transactionTitle: PropTypes.string,
  setTransactionTitle: PropTypes.func,
  propertyType: optionShape,
  setPropertyType: PropTypes.func,
  referralFee: PropTypes.string,
  setReferralFee: PropTypes.func,
  referralAgent: optionShape,
  setReferralAgent: PropTypes.func,
  client: PropTypes.string,
  setClient: PropTypes.func,
  clientIntent: PropTypes.string,
  setClientIntent: PropTypes.func,
  isReferralFeePercentage: PropTypes.bool,
  setIsReferralFeePercentage: PropTypes.func,
  propertyTypes: PropTypes.arrayOf(optionShape),
};
ReferralTransactionsField.defaultProps = {
  transactionTitle: "",
  propertyType: null,
  referralFee: "25",
  referralAgent: null,
  client: "",
  clientIntent: "buyer",
  propertyTypes: [],
  isReferralFeePercentage: true,
  setIsReferralFeePercentage: () => {},
  setTransactionTitle: () => {},
  setReferralFee: () => {},
  setReferralAgent: () => {},
  setClient: () => {},
  setClientIntent: () => {},
  setPropertyType: () => {},
};
export default ReferralTransactionsField;
