import PropTypes from "prop-types";
import errorShape from "./error";
import feedbackShape from "./feedback";

const formDataShape = PropTypes.shape({
  action: PropTypes.string.isRequired,
  data: feedbackShape,
  isVisible: PropTypes.bool.isRequired,
  error: errorShape,
  isLoading: PropTypes.bool.isRequired,
});

export default formDataShape;
