export const removeKeyPair = (obj, key) => {
  const newObj = { ...obj };
  delete newObj[key];
  return newObj;
};
export const removeKeys = (obj, keysToRemove) => {
  const newObj = { ...obj }; // Create a shallow copy of the original object

  keysToRemove.forEach((key) => {
    delete newObj[key]; // Delete the specified key from the copied object
  });

  return newObj;
};
