import moment from "moment-timezone";
import { formattedTimeFromUtcDateTimeString, formattedTimeFromUtcTimeString } from "../../shared/TimeHelpers";
import { toTitleCase } from "../../shared/Utilities";

const actionLookup = (actions, id) => actions && actions.find((action) => action.id === id);

const delayUnits = { seconds: "second", minutes: "minute", hours: "hours", days: "day" };

const generateTimeString = (action) => {
  if (action.run_at) {
    return formattedTimeFromUtcDateTimeString(action.run_at);
  }
  if (action.run_time) {
    return formattedTimeFromUtcTimeString(action.run_time);
  }
  return undefined;
};

const actionSubtitleForEvent = (action) => {
  const atOrBy = action.type === "Task" ? "by" : "at";
  let when = "";
  let delayUnitLabel = "";
  let dayLabel = "";
  const timeToRun = generateTimeString(action);

  if (action.delay > 0) {
    dayLabel = `${action.delay} `;
    when = ` after ${atOrBy} `;
    delayUnitLabel = delayUnits[action.delay_unit] + (action.delay > 1 ? "s" : "");
  } else if (action.delay < 0) {
    dayLabel = `${Math.abs(action.delay)} `;
    when = ` before ${atOrBy} `;
    delayUnitLabel = delayUnits[action.delay_unit] + (action.delay < -1 ? "s" : "");
  }

  return `${dayLabel}${delayUnitLabel} ${when} ${timeToRun}`;
};

const actionSubtitle = (action) => {
  if (action.schedule_type === "event" && !action.failed_message) {
    return actionSubtitleForEvent(action);
  }
  if (action.run_at) {
    return formattedTimeFromUtcDateTimeString(action.run_at);
  }
  return undefined;
};

const iconOptions = (type) => {
  switch (type) {
    case "Text":
      return "fa fa-commenting-o";
    case "Task":
      return "fa fa-list";
    case "Email":
      return "fa fa-envelope";
    default:
      return "";
  }
};

const dateLabel = (date) => {
  if (date === "Events") {
    return "Event Based";
  }
  const today = moment().tz(Brivity.LocalTimeZone).format("MM/DD/YYYY");
  const dateString = moment(date).format("MM/DD/YYYY");
  return today === dateString ? "Today" : dateString;
};

const eventDetailString = (action) => {
  const atOrBy = action.type === "Task" ? "by" : "at";
  let when = "";
  let delayUnitLabel = "";
  let dayLabel = "";
  const timeToRun = generateTimeString(action);

  if (action.delay === 0) {
    when = "on ";
  } else if (action.delay > 0) {
    when = "after ";
    delayUnitLabel = delayUnits[action.delay_unit] + (action.delay > 1 ? "s" : "");
    dayLabel = `${action.delay} `;
  } else {
    when = "before ";
    dayLabel = `${Math.abs(action.delay)} `;
    delayUnitLabel = delayUnits[action.delay_unit] + (action.delay < -1 ? "s" : "");
  }
  return `${dayLabel}${delayUnitLabel} ${when} ${toTitleCase(action.milestone_name)} ${atOrBy} ${timeToRun}`;
};

export { actionLookup, actionSubtitle, dateLabel, delayUnits, eventDetailString, iconOptions };
