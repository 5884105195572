import React from 'react';
import { UserDataRow, TaskTotalsRow } from './';
const UserTaskHolder = (props) => {
  const keyValue = props.isAdmin ? 'admin' : 'agent';
  return(
    <div>
      {
        props.agents.map(s =>
          <UserDataRow
            key={`${s[`${keyValue}_user_id`]}`}
            agent={s}
            isAdmin={props.isAdmin} />
        )
      }
      {
        props.agents.length > 1 &&
        <TaskTotalsRow
          total={props.totals}
          completedTotal={props.completedTotal}
          onTimeTotal={props.onTimeTotal}
          lateTotal={props.lateTotal}/>
      }
    </div>
  )
}
export default UserTaskHolder;
