import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DateTime from "react-datetime";
import DropdownDatePicker from "@shared/v2/DropdownDatePicker";
import css from "./person-detail-styles.module.css";
import { updatePersonPromise } from "../actions/updatePerson";
import DatePicker from "../../shared/v2/DatePicker";
import IconButton from "../../shared/v2/IconButton";
import { PencilSolidV6, XmarkSolidV6 } from "../../shared/v2/Icomoon";
import Button from "../../shared/v2/Button";
import PersonDetailError from "./PersonDetailError";

class EditableDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingDate: false,
      editablePerson: JSON.parse(JSON.stringify(props.person)),
      errors: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { person } = this.props;
    if (person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  dateName = () => {
    const { date, elementType } = this.props;
    const name = elementType === "anniversary" ? "home anniversary" : elementType;
    return date.name?.split("_").join(" ") || name;
  };

  onEditDate = () => {
    this.setState({ editingDate: true });
  };

  onDismissDateEdit = () => {
    const { person } = this.props;
    this.setState({
      editingDate: false,
      editablePerson: JSON.parse(JSON.stringify(person)),
      errors: null,
    });
  };

  handleCustomDateChange = (dateId, newDateOrTime, type) => {
    const { editablePerson } = this.state;
    const { custom_dates: customDates } = editablePerson.data.attributes;
    const indexOfDate = customDates.indexOf(customDates.find((date) => date.id === dateId));

    if (type === "date") {
      customDates[indexOfDate].date = newDateOrTime;
    } else {
      customDates[indexOfDate].time_of_day_string = newDateOrTime;
    }

    this.setState({
      editablePerson: {
        ...editablePerson,
        data: {
          ...editablePerson.data,
          attributes: {
            ...editablePerson.data.attributes,
            custom_dates: customDates,
          },
        },
      },
    });
  };

  handleDateChange = (property, value) => {
    const { editablePerson } = this.state;
    this.setState({
      editablePerson: {
        ...editablePerson,
        data: {
          ...editablePerson.data,
          attributes: {
            ...editablePerson.data.attributes,
            [property]: {
              ...editablePerson.data.attributes[property],
              date: value,
            },
          },
        },
      },
    });
  };

  onDateSave = () => {
    const { elementType, updatePersonData } = this.props;
    const { editablePerson } = this.state;
    const { birthdate } = editablePerson.data.attributes;

    if (elementType === "birthdate" && birthdate?.date) {
      const [year, month, day] = birthdate.date.split("-");
      let errors = null;
      if (!month) errors = ["Cannot save without a month"];
      if (!day) errors = ["Cannot save without a day"];
      if (year && !month && !day) errors = ["Cannot save without a month and day"];
      this.setState({ errors });
      if (errors) return;
    }

    updatePersonPromise(editablePerson)
      .then((r) => {
        updatePersonData(r.data);
        this.setState({ editingDate: false, errors: null });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors });
      });
  };

  renderDate = () => {
    const { date } = this.props;
    const format = moment(date.date).year() < 1900 ? "MM/DD" : "MM/DD/YYYY";
    const formattedDate = moment(date.date).format(format);
    if (date.include_time) {
      return `${formattedDate} at ${date.time_of_day_string}`;
    }
    return formattedDate;
  };

  renderAlert = () => {
    const { errors } = this.state;
    // eslint-disable-next-line react/no-array-index-key
    return errors.map((error, i) => <PersonDetailError key={i} error={error} />);
  };

  renderButtons = () => (
    <div className="tw-flex tw-justify-end tw-gap-[8px]">
      <Button onClick={this.onDateSave}>Save</Button>
      <IconButton size="small" onClick={this.onDismissDateEdit}>
        <XmarkSolidV6 />
      </IconButton>
    </div>
  );

  editDateFields = () => {
    const { elementType } = this.props;
    return elementType === "custom_date" ? this.renderCustomDateFields() : this.renderDateFields();
  };

  renderCustomDateFields = () => {
    const { date: propDate, noYear } = this.props;
    const { editablePerson, errors } = this.state;
    const { custom_dates: customDates } = editablePerson.data.attributes;
    const indexOfDate = customDates.indexOf(customDates.find((date) => date.id === propDate.id));
    const customDate = customDates[indexOfDate];
    const date = moment(customDate.date).format("MM/DD/YYYY");

    return (
      <div className="tw-flex tw-justify-between tw-gap-[8px]">
        <div className="tw-flex-1 tw-flex tw-flex-col">
          {errors && this.renderAlert()}
          <DatePicker
            inputProps={{ size: 10 }}
            name={customDate.name}
            value={date}
            onChange={(value) => this.handleCustomDateChange(customDate.id, moment(value), "date")}
            noYear={noYear}
          />
          {customDate.include_time && (
            <DateTime
              dateFormat={false}
              value={customDate.time_of_day_string}
              onChange={(value) =>
                this.handleCustomDateChange(customDate.id, moment(value).format("h:mm A"), "time")
              }
              timeConstraints={{ minutes: { step: 15 } }}
            />
          )}
        </div>
        {this.renderButtons()}
      </div>
    );
  };

  renderDateFields = () => {
    const { elementType, noYear } = this.props;
    const {
      editablePerson: {
        data: { attributes },
      },
      errors,
    } = this.state;
    const date = moment(attributes[elementType].date).format("MM/DD/YYYY");

    return (
      <div className="tw-flex tw-gap-[8px] tw-justify-between">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-[4px]">
          {elementType === "birthdate" && (
            <>
              <DropdownDatePicker
                error={errors?.[0]}
                value={attributes[elementType].date}
                onChange={(d) => this.handleDateChange(elementType, d)}
              />
              {this.renderButtons()}
            </>
          )}
          {elementType !== "birthdate" && (
            <>
              {errors && this.renderAlert()}
              <DatePicker
                inputProps={{ size: 10 }}
                dateFormat="MM/DD/YYYY"
                name={elementType}
                value={date}
                onChange={(value) =>
                  this.handleDateChange(elementType, moment(value, "MM/DD/YYYY").format("YYYY-MM-DD"))
                }
                noYear={noYear}
              />
            </>
          )}
        </div>
        {elementType !== "birthdate" && this.renderButtons()}
      </div>
    );
  };

  render() {
    const { editingDate } = this.state;
    return (
      <div className="tw-flex tw-justify-between tw-gap-[8px] hover:tw-bg-gray-5 tw-group tw-py-[8px] tw-px-[20px]">
        <div className="tw-flex tw-flex-col tw-w-full">
          <div className={css.brivityPersonDetailsLabel}>{this.dateName()}</div>
          {editingDate ? this.editDateFields() : this.renderDate()}
        </div>
        {!editingDate && (
          <div className="tw-invisible group-hover:tw-visible">
            <IconButton size="small" onClick={this.onEditDate}>
              <PencilSolidV6 />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

EditableDate.propTypes = {
  date: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    include_time: PropTypes.bool,
    time_of_day_string: PropTypes.string,
  }).isRequired,
  elementType: PropTypes.string.isRequired,
  noYear: PropTypes.bool,
  person: PropTypes.shape().isRequired,
  updatePersonData: PropTypes.func.isRequired,
};

EditableDate.defaultProps = {
  noYear: false,
};

export default EditableDate;
