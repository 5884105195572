import React from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";

import { checkAllImgs } from "../actions/creators";

import ImageActionButtons from "../ImagesActionButtons";
import Checkbox from "../../../../shared/v2/Checkbox";

const ImagesOptions = ({ onCheckAllImgs, checkAll }) => (
  <div className="tw-ml-auto tw-flex">
    <ImageActionButtons />
    <Checkbox
      name="test"
      id="id--1"
      label="Select all"
      value="asdfasdf"
      className="tw-cursor-pointer"
      checked={checkAll}
      onClick={() => onCheckAllImgs()}
    />
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  onCheckAllImgs: () => dispatch(checkAllImgs()),
});

const mapStateToProps = (state) => ({
  checkAll: state.tdpDetailsReducer.checkAll,
});

ImagesOptions.propTypes = {
  onCheckAllImgs: func,
  checkAll: bool,
};

ImagesOptions.defaultProps = {
  onCheckAllImgs: () => {},
  checkAll: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesOptions);
