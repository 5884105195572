import React from "react";
import axios from "axios";

export default class TwilioSubaccountSid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // eslint-disable-next-line react/prop-types
      subaccountSid: props.subaccountSid,
    };
  }

  handleProvisionSubaccount = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      axios
        .post("/support/twilio/provision_subaccount")
        .then((res) => {
          this.setState({
            subaccountSid: res.data.twilio_sid,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
          // eslint-disable-next-line no-alert
          alert(`There was an error provisioning the subaccount`);
        });
    });
  };

  provisionSubaccountLink = () => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={this.handleProvisionSubaccount}>
      Provision Subaccount
    </a>
  );

  render() {
    const { subaccountSid, loading } = this.state;
    return (
      <>
        {subaccountSid}
        {loading && (
          <div>
            <i className="fa fa-pulse fa-spinner" /> Provisioning Subaccount...
          </div>
        )}
        {!subaccountSid && !loading && this.provisionSubaccountLink()}
      </>
    );
  }
}
