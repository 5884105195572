/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LockOpenSolidV6 = (props) => (
  <Icon {...props} name="Lock Open Solid V6">
    <path d="M9.778 6.222h.889A1.78 1.78 0 0 1 12.445 8v5.333a1.78 1.78 0 0 1-1.778 1.778H1.778A1.779 1.779 0 0 1 0 13.333V8c0-.981.796-1.778 1.778-1.778H8V4.889a4 4 0 0 1 8 0v1.333c0 .492-.397.889-.889.889s-.889-.397-.889-.889V4.889a2.222 2.222 0 1 0-4.444 0v1.333z" />
  </Icon>
);

export default LockOpenSolidV6;
