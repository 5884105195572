import axios from "axios";

const fetchAutoPlans = () => ({
  type: "FETCH_AUTO_PLANS",
  isFetchingAutoPlans: true,
});

const autoPlansSuccess = (autoPlans) => ({
  type: "AUTO_PLANS_SUCCESS",
  autoPlans,
});

export const openAutoPlanDeleteModal = (id) => ({
  type: "OPEN_AUTO_PLAN_DELETE_MODAL",
  activeAutoPlanId: id,
});

export const openAutoPlanShareModal = (id) => ({
  type: "OPEN_AUTO_PLAN_SHARE_MODAL",
  activeAutoPlanId: id,
});

export const closeAutoPlanDeleteModal = () => ({ type: "CLOSE_AUTO_PLAN_DELETE_MODAL" });

export const closeAutoPlanShareModal = () => ({ type: "CLOSE_AUTO_PLAN_SHARE_MODAL" });

export const handleDismissAutoPlanSharedAlert = () => ({ type: "CLOSE_AUTO_PLAN_SHARED_ALERT" });

export const closeModal = () => ({ type: "CLOSE_MODAL" });

export const showNewAutoPlanModal = () => (dispatch) => {
  dispatch({ type: "SHOW_NEW_AUTO_PLAN_MODAL" });
};

export const showAutoPlanPreviewModal = (autoPlanId) => (dispatch) => {
  axios
    .get(`/auto_plans/${autoPlanId}/preview`)
    .then((res) => dispatch({ type: "SHOW_AUTO_PLAN_PREVIEW_MODAL", autoPlan: res.data.auto_plan }))
    .catch();
};

export const previewAutoPlanAction = (actionId) => (dispatch) => {
  dispatch({
    type: "PREVIEW_AUTO_PLAN_ACTION",
    actionId,
  });
};

export const closeNewAutoPlanModal = () => (dispatch) => {
  dispatch({ type: "CLOSE_NEW_AUTO_PLAN_MODAL" });
};

export const cloneAutoPlanPending = () => ({ type: "CLONE_AUTO_PLAN_PENDING" });

export const cloneAutoPlanSuccess = () => ({ type: "CLONE_AUTO_PLAN_SUCCESS" });

export const cloneAutoPlanFailure = (errorMessage) => ({
  type: "CLONE_AUTO_PLAN_FAILURE",
  errorMessage,
});

const deleteAutoPlanFailure = () => ({ type: "DELETE_AUTO_PLAN_FAILURE" });

const deleteAutoPlanSuccess = () => ({ type: "DELETE_AUTO_PLAN_SUCCESS" });

const deleteAutoPlanPending = () => ({ type: "DELETE_AUTO_PLAN_PENDING" });

export const getAutoPlans = (autoPlansPath, type) => {
  const path = `${autoPlansPath}.json?plan_type=${type}&page=null`;
  return (dispatch) => {
    dispatch(fetchAutoPlans());
    axios
      .get(path)
      .then((res) => {
        dispatch(autoPlansSuccess(res.data));
      })
      .catch(() => {
        dispatch({ type: "AUTO_PLANS_FAILURE", isFetchingAutoPlans: false });
      });
  };
};

export const handleClone = (path) => (dispatch) => {
  dispatch(cloneAutoPlanPending());
  axios
    .post(path, { authenticity_token: ReactOnRails.authenticityToken() })
    .then((res) => {
      dispatch(cloneAutoPlanSuccess());
      window.location = `/auto_plans/${res.data.auto_plan.id}`;
    })
    .catch((err) => {
      dispatch(cloneAutoPlanFailure(err.response.data.message));
    });
};

export const deleteAutoPlan = (path) => (dispatch) => {
  dispatch(deleteAutoPlanPending());
  axios
    .delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
    .then(() => {
      dispatch(deleteAutoPlanSuccess());
    })
    .catch(() => {
      dispatch(deleteAutoPlanFailure());
    });
};

export const shareAutoPlan = (autoPlanId, autoPlanName, ownerEmails) => (dispatch) => {
  dispatch({ type: "SHARE_AUTO_PLAN_PENDING" });
  axios
    .post(`/auto_plans/${autoPlanId}/share`, {
      auto_plan_id: autoPlanId,
      emails: ownerEmails,
      authenticity_token: ReactOnRails.authenticityToken(),
    })
    .then(() => {
      dispatch({
        type: "SHARE_AUTO_PLAN_SUCCESS",
        sharedPlanName: autoPlanName,
        numberShared: ownerEmails.length,
      });
    })
    .catch((err) => {
      dispatch({ type: "SHARE_AUTO_PLAN_FAILURE", errors: err.response.data.errors });
    });
};

export const autoPlanFormUpdate = (name, value) => ({
  type: "AUTO_PLAN_FORM_UPDATE",
  name,
  value,
});

export const submitNewAutoPlan = (autoPlanCreatePath, autoPlan) => (dispatch) => {
  dispatch({ type: "AUTO_PLAN_CREATE_PENDING" });

  axios
    .post(autoPlanCreatePath, { auto_plan: autoPlan, authenticity_token: ReactOnRails.authenticityToken() })
    .then((res) => {
      window.location = `/auto_plans/${res.data.auto_plan.id}`;
    })
    .catch((err) => {
      dispatch({ type: "AUTO_PLAN_CREATE_FAILURE", errors: err.response.data.errors });
    });
};

export const toggleTaskActionModal = (bool, action = "post", form = {}) => ({
  type: "AUTO_PLAN_TOGGLE_TASK_ACTION_MODAL",
  bool,
  action,
  form,
});

export const toggleEmailActionModal = (bool, action = "post", form = {}) => ({
  type: "AUTO_PLAN_TOGGLE_EMAIL_ACTION_MODAL",
  bool,
  action,
  form,
});

export const toggleTextActionModal = (bool, action = "post", form = {}) => ({
  type: "AUTO_PLAN_TOGGLE_TEXT_ACTION_MODAL",
  bool,
  action,
  form,
});

export const setPeopleGroups = (generalRoles, teamRoles, userId) => ({
  type: "AUTO_PLAN_EDIT_SET_PEOPLE_GROUP",
  generalRoles,
  teamRoles,
  userId,
});
