import moment from "moment";
import { UIMODE } from "./constants";

import {
  getDateFormat,
  getClosestTime,
  getNextPossibleDateSelectorTime,
  getStartTime,
  getEndTime,
  meridianCount,
  getValidDateFormats,
} from "./helpers";

export const initialState = () => {
  const closestTimeStart = getClosestTime();
  const closestTimeEnd = getNextPossibleDateSelectorTime(closestTimeStart);

  return {
    impureDate: "", // user input. it might be wrong, correct or empty. Do not rely on it. Anything.
    temporaryDate: moment(), // it is always correct. Always a valid date.
    navigationDate: moment(), // internal date state for navigation. Always a valid date.
    temporaryStartTime: closestTimeStart, // user selected start time. Pre-selected Valid times.
    temporaryEndTime: closestTimeEnd, // user selected end time. Pre-selected valid times.
    uiMode: UIMODE.DAY, // UIMODE.day or UIMODE.month
    showTimePicker: false,
    showEndTimePicker: false,
    dateFormatError: "", // show this message when the user input (state.impureDate) is wrong.
  };
};

export const definedInitialState = ({ value, dateFormat, enableTimePicker, enableEndTimePicker }) => {
  const dateOnly = value.split(" ")[0];
  const date = moment(dateOnly, getValidDateFormats(dateFormat));
  const closestTimeStart = getClosestTime();
  const closestTimeEnd = getNextPossibleDateSelectorTime(closestTimeStart);

  return {
    impureDate: date.isValid() ? date.format(getDateFormat(dateFormat)) : "",
    temporaryDate: date.isValid() ? date : moment(),
    navigationDate: date.isValid() ? date : moment(),
    temporaryStartTime: getStartTime(value, enableTimePicker) || closestTimeStart,
    temporaryEndTime: getEndTime(value, enableEndTimePicker) || closestTimeEnd,
    uiMode: UIMODE.DAY,
    showTimePicker: meridianCount(value, enableTimePicker) >= 1,
    showEndTimePicker: meridianCount(value, enableEndTimePicker) >= 2,
    dateFormatError: "",
  };
};

export const init = (initialData) => {
  const dateOnly = initialData.value.split(" ")[0];
  if (moment(dateOnly, getValidDateFormats(initialData.dateFormat)).isValid()) {
    return definedInitialState(initialData);
  }
  return initialState();
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IMPURE_DATE":
      return { ...state, impureDate: action.payload.date, dateFormatError: "" };
    case "SET_ALL_DATES":
      return {
        ...state,
        impureDate: action.payload.impureDate,
        temporaryDate: action.payload.temporaryDate,
        navigationDate: action.payload.navigationDate,
        dateFormatError: "",
      };
    case "INVALID_IMPURE_DATE":
      return { ...state, dateFormatError: action.payload.dateFormatError };
    case "SAVE_SELECTED_DATETIME":
      return {
        ...state,
        impureDate: action.payload.impureDate,
        dateFormatError: "",
      };
    case "SAVE_DATETIME_CLICK_OUTSIDE":
      return {
        ...state,
        impureDate: action.payload.impureDate,
        temporaryDate: action.payload.temporaryDate,
        navigationDate: action.payload.temporaryDate,
        dateFormatError: "",
      };
    case "SET_EMPTY_DATE":
      return {
        ...state,
        impureDate: "",
      };
    case "SET_TEMPORARY_TIME":
      return {
        ...state,
        temporaryStartTime: action.payload.temporaryStartTime,
        temporaryEndTime: action.payload.temporaryEndTime,
      };
    case "SET_TEMPORARY_START_TIME":
      return {
        ...state,
        temporaryStartTime: action.payload.temporaryStartTime,
      };
    case "SET_TEMPORARY_END_TIME":
      return {
        ...state,
        temporaryEndTime: action.payload.temporaryEndTime,
      };
    case "SET_CLICKED_DATE":
      return {
        ...state,
        impureDate: action.payload.impureDate,
        temporaryDate: action.payload.temporaryDate.clone().date(action.payload.temporaryDate.date()),
      };
    case "SET_NAVIGATION_DATE":
      return { ...state, navigationDate: action.payload.navigationDate };
    case "CLICK_NAVIGATION_MONTH_DATE":
      return { ...state, navigationDate: action.payload.navigationDate, uiMode: UIMODE.DAY };
    case "SET_UI_MODE":
      return { ...state, uiMode: action.payload.uiMode };
    case "CLEAR_DATE_PICKER":
      return {
        ...state,
        impureDate: "",
        temporaryStartTime: "",
        temporaryEndTime: "",
        showTimePicker: false,
        showEndTimePicker: false,
        dateFormatError: "",
      };
    case "CLEAR_DATE_FORMAT_ERROR":
      return {
        ...state,
        dateFormatError: "",
      };
    case "SHOW_TIME_PICKER":
      return { ...state, showTimePicker: true };
    case "CLOSE_TIME_PICKER":
      return {
        ...state,
        showTimePicker: false,
        showEndTimePicker: false,
      };
    case "SHOW_END_TIME_PICKER":
      return { ...state, showEndTimePicker: true };
    case "CLOSE_END_TIME_PICKER":
      return { ...state, showEndTimePicker: false };
    default:
      throw new Error("Dateselector unhandled action type");
  }
};
