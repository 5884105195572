import React from "react";
import { func, string } from "prop-types";
import TextComboInput from "@shared/v2/TextComboInput";

const EmailSubjectField = ({value, setValue}) => (
  <TextComboInput
    label='Email Subject'
    value={value}
    onChange={(e) => setValue(e.target.value)}/>
);

export default EmailSubjectField;

EmailSubjectField.propTypes = {
  value: string,
  setValue: func.isRequired,
}

EmailSubjectField.defaultProps = {
  value: '',
}