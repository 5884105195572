import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../shared/v2/Dropdown";

const ShowingTypeDropdownMenu = ({ componentProps, children }) => (
  <Dropdown.Menu
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    preserveStyle
    // The Rating component uses a different z-index to render the filled hearts on top of the
    // outlined ones. Since `ShowingTypeDropdownMenu` and `Rating` are rendered close to each
    // other, we override `ShowingTypeDropdownMenu`'s z-index value to avoid rendering issues.
    className="!tw-z-20"
  >
    {children}
  </Dropdown.Menu>
);

ShowingTypeDropdownMenu.propTypes = {
  // `componentProps` is an internal implementation detail of the Dropdown component, we don't want
  // to validate its shape here.
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.object,
  children: PropTypes.node,
};

ShowingTypeDropdownMenu.defaultProps = {
  componentProps: {},
  children: null,
};

export default ShowingTypeDropdownMenu;
