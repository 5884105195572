/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PencilSolidV6 = (props) => (
  <Icon {...props} name="Pencil Solid V6">
    <path d="M13.178 6.884 5.89 14.168l-1.059-1.059L4.94 13H3.499a.501.501 0 0 1-.5-.5v-1.441l-.109.109c-.148.15-.256.331-.315.531l-.718 2.444 2.442-.719a1.48 1.48 0 0 0 .531-.316l1.059 1.059a2.738 2.738 0 0 1-1.169.694L.96 15.967a.705.705 0 0 1-.742-.216c-.194-.166-.267-.45-.189-.716l1.106-3.759c.13-.441.368-.844.694-1.169l7.285-7.286 4.063 4.061zm2.219-5.048a2.002 2.002 0 0 1 0 2.83l-1.512 1.513-4.063-4.062L11.335.605a2.003 2.003 0 0 1 2.831 0l1.231 1.232z" />
  </Icon>
);

export default PencilSolidV6;
