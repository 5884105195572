/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleInfoSolidV6 = (props) => (
  <Icon {...props} name="Circle Info Solid V6">
    <path d="M8 0C3.581 0 0 3.581 0 8s3.581 8 8 8c4.419 0 8-3.581 8-8s-3.581-8-8-8zm0 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm1.25 8h-2.5a.75.75 0 0 1 0-1.5h.5v-2H7A.75.75 0 0 1 7 7h1a.75.75 0 0 1 .75.75v2.75h.5a.75.75 0 0 1 0 1.5z" />
  </Icon>
);

export default CircleInfoSolidV6;
