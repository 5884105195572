/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ListingCardBody from "./ListingCardBody";
// eslint-disable-next-line import/no-cycle
import ListingCardFooter from "./ListingCardFooter";
import ListingCardHeader from "./ListingCardHeader";

const ListingCard = ({ listing, onStatusChanged, updateListing, sources, onDeletion, permissions }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div
      data-js="listing-card"
      className={`
            tw-group
            tw-flex tw-flex-col 
            tw-h-min
            tw-border-1px tw-border-solid tw-border-neutral-gray-10 tw-border-opacity-100
            tw-bg-white
            tw-rounded-[4px]
            tw-p-[12px]
            tw-space-y-3
            tw-w-full
            `}
      data-listing-id={listing.id}
      data-listing-type={listing.type}
      data-cy="transaction-item-card-view"
    >
      <ListingCardHeader
        listing={listing}
        onArchive={() => updateListing(listing)({ status: "archived" })}
        onDeletion={() => onDeletion(listing)}
        permissions={permissions}
      />
      <ListingCardBody
        listing={listing}
        collapsed={collapsed}
        updateListing={updateListing}
        sources={sources}
        permissions={permissions}
      />

      <ListingCardFooter
        listing={listing}
        onStatusChanged={onStatusChanged}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </div>
  );
};

export default ListingCard;
