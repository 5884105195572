/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { func, string } from "prop-types";

import withDispatch from "../../../shared/HOCs/withDispatch/withDispatch";
import { tdpDetails } from "../../../shared/HOCs/withRedux";
import { putStatusWithPipelineAsThunk } from "../../Header/actions/thunks";
import { isUpdateAddressModal } from "../../Header/actions/creators";

import { WithShowPicker } from "../../../Transactions/components/shared/OptionsStatusCell/OptionsStatusCell";
import { LISTING_UUID, STATUS, STREET_ADDRESS, TYPE } from "../../../shared/reduxRoutes/tdpDetailsRoutes";

const TDPOptionsStatusCell = ({
  type,
  status,
  onSetStatus,
  onUpdateAddressModal,
  uuid,
  streetAddress,
  ...props
}) => {
  const onSetStatusHandlr = (_status) => {
    // If status change is the same as current status, don't do anything
    if (_status === status) return;

    // else do a PUT req to change status
    onSetStatus({
      preStatus: status,
      status: _status,
      streetAddress: streetAddress,
      uuid,
    });
  };

  return (
    <WithShowPicker {...props} transactionType={type} status={status} selectedStatus={onSetStatusHandlr} />
  );
};

const WithStatePipelineOptionsStatusCell = tdpDetails({
  WrappedComponent: TDPOptionsStatusCell,
  states: [STREET_ADDRESS, TYPE, STATUS, LISTING_UUID],
  aliases: ["streetAddress"],
});

export default withDispatch({
  WrappedComponent: WithStatePipelineOptionsStatusCell,
  actions: [putStatusWithPipelineAsThunk, isUpdateAddressModal],
  aliases: ["onSetStatus", "onUpdateAddressModal"],
});

TDPOptionsStatusCell.propTypes = {
  type: string,
  status: string,
  onSetStatus: func,
  onUpdateAddressModal: func,
  streetAddress: string,
  uuid: string.isRequired,
};

TDPOptionsStatusCell.defaultProps = {
  type: "buyer",
  status: "active",
  onSetStatus: () => {},
  streetAddress: null,
  onUpdateAddressModal: () => {},
};
