import React from "react";
import { useMemos } from "./customHooks";
import { AgentDetailForm } from "../AgentDetailForm";

export const AgentsDetailForm = (props) => {
  const {
    onRemoveIncome,
    setFinancialData,
    onAddExpense,
    onSortAdditionalIncome,
    recalculate_team_member_net_income,
    recalculate_all_team_member_net_incomes,
    unitOptions,
  } = props;

  const { listingData, agents } = useMemos(props.listingData);

  return agents.map((income, i) => (
    <AgentDetailForm
      listingData={listingData}
      onRemoveIncome={onRemoveIncome}
      setFinancialData={setFinancialData}
      onAddExpense={onAddExpense}
      onSortAdditionalIncome={onSortAdditionalIncome}
      recalculate_all_team_member_net_incomes={recalculate_all_team_member_net_incomes}
      recalculate_team_member_net_income={recalculate_team_member_net_income}
      unitOptions={unitOptions}
      income={income}
      idx={i}
    />
  ));
};
