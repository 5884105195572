import React from "react";
import PropTypes from "prop-types";
import FieldLabel from "../../../../shared/v2/FieldLabel/FieldLabel";
import Tooltip from "../../../../shared/v2/Tooltip/Tooltip";
import { CircleInfoSolidV6 } from "../../../../shared/v2/Icomoon";

const FormElement = ({ label, children, className, required, tooltip }) => (
  <div
    data-cy={`${label
      ?.toLowerCase()
      ?.replace(/[^a-z0-9\s]/gi, "")
      ?.replace(/ /g, "_")}`}
    className={`tw-flex tw-flex-col tw-gap-[4px] ${className}`}
  >
    <div className="tw-flex tw-items-center tw-gap-[8px]">
      <FieldLabel label={label} isRequired={required} />
      {tooltip && (
        <Tooltip
          tooltipClassName="tw-max-w-[200px]"
          content={tooltip}
          trigger={<CircleInfoSolidV6 className="tw-text-theme-primary" />}
        />
      )}
    </div>
    {children}
  </div>
);

FormElement.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
};

FormElement.defaultProps = {
  label: "",
  required: false,
  className: "",
  tooltip: "",
};

export default FormElement;
