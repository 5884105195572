export const CURRENTUSERID  = 'currentUserId';
export const USERTYPE = 'currentUser.userType';
export const ISADMIN = 'isAdmin';
export const TRANSACTION_TYPE = 'transactionDetails.type';
export const PROPERTY_TYPE = 'propertyDetails.property_type.name';
export const TRANSACTION_TITLE = 'transactionDetails.title';
export const STREET_ADDRESS = 'propertyDetails.street_address';
export const CITY = 'propertyDetails.city';
export const LOCALITY = 'propertyDetails.state';
export const POSTAL_CODE = 'propertyDetails.zip';
export const STATUS = 'transactionDetails.status';
export const EST_CLOSE_PRICE = 'transactionDetails.est_close_price';
export const LISTING_UUID = 'uuid';
export const PRIMARYAGENT_AGENT = 'primaryAgentDetails.agent';
export const PRIMARYAGENT_AGENTAVATAR = 'primaryAgentDetails.agentAvatar';
export const SOURCE = 'listingDetails.source';

export const CLIENTINTENT = 'referralDetails.clientIntent';
export const TRANSACTIONAMT = 'referralDetails.transactionAmt';
export const REFERRALFEE = 'referralDetails.referralFee';
export const ISREFERRALFEEPERCENTAGE = 'referralDetails.isReferralFeePercentage';
export const ADDRESS_FIELD = 'referralDetails.addressField';
export const IS_REFERRAL_DESIRED_ADDRESS_EDIT = 'referralDetails.isReferralDesiredAddressEdit';
export const IS_TIMEFRAME_EDIT = 'referralDetails.isTimeFrameEdit';
export const IS_REFERRAL_CALENDAR_OPEN = 'referralDetails.isReferralCalendarOpen';
export const IS_MODAL_RENAME_TITLE_OPEN = 'referralDetails.isModalRenameTitleOpen';
export const REFERRAL_FEE = 'referralDetails.referralFee';
export const TIMEFRAME = 'referralDetails.timeframe';
export const TIMEFRAME_EXACT = 'referralDetails.timeframeExact';
export const TIMEFRAME_READ = 'referralDetails.timeframeRead';
export const PHOTO = "headerDetails.photo.cloud_location";
export const REFERRALPARTNERBROKERAGE  = 'referralDetails.referralPartnerbrokerage'
export const PROPERTYDETAILS_PROPERTYTYPE = 'propertyDetails.property_type';

export const TYPE = 'headerDetails.type';
export const HEADERDETAILS_PUBLISHED = 'headerDetails.published'

export const CLIENTDETAILS_NAME = 'clientDetails.name';
export const CLIENTDETAILS_PDPLINK = 'clientDetails.pdpLink';
export const CLIENTDETAILS_FINANCING = 'clientDetails.financing'
export const REFERRALPARTNER_TEAM = 'referralDetails.team';
export const REFERRALPARTNER_BROKERAGE  = "referralDetails.brokerage";

export const REFERRALPARTNER_AVATAR = 'referralPartner.avatar';
export const REFERRALPARTNER_NAME = 'referralPartner.name';
export const REFERRALPARTNER_PDPLINK = 'referralPartner.pdpLink';

export const NOTABLEID = 'notableId';

export const ISUPDATEADDRESSMODALOPEN = 'isUpdateAddressModalOpen';
export const TEMP_UPDATED_STATUS = 'tempUpdatedStatus';
export const TEMP_PRE_STATUS = 'tempPreStatus';

export const ACCOUNT_SOURCES = 'meta.accountSources';
