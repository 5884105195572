const addressPayload = ({ name, value, addressId }) => ({
  address_attributes: { [name]: value, id: addressId },
});

const detailPayload = ({ inOption, name, value }) => ({
  [inOption]: [{ name, value }],
});

const linkPayload = ({ value }) => ({
  listing_links_attributes: value,
});

const rawPayload = ({ name, value }) => ({ [name]: value });

const payloadMapper = {
  address_attributes: addressPayload,
  listing_links_attributes: linkPayload,
  null: rawPayload,
};

const choosePayload = (inOption) => payloadMapper[inOption] || detailPayload;

const buildPayload = (inOption, name, value, addressId = null) => {
  const builder = choosePayload(inOption);
  return builder({ name, value, addressId, inOption });
};

export default buildPayload;
