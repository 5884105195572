import FilterPill from "@shared/v2/FilterPill";
import React from "react";

const PropertyTypeOptions = ({ propertyTypes, availablePropertyTypes, onToggle }) => {
  return (
    <div className="tw-flex tw-flex-row tw-flex-wrap tw-w-full tw-gap-4px">
      {availablePropertyTypes.map((propertyType) => (
        <FilterPill
          key={propertyType}
          onClick={() => onToggle(propertyType)}
          selected={propertyTypes.includes(propertyType)}
        >
          {propertyType}
        </FilterPill>
      ))}
    </div>
  );
};

export default PropertyTypeOptions;
