import React, { useState } from "react";
import Proptypes from "prop-types";

import PropTypeButton from "./button";
import ShowMoreLess from "../ShowMoreLess";

import { clickEventCatch } from "../../helpers";

const PropertyList = ({ propertyTypeOptions, setSelected, selectedTypes, hasError }) => {
  const [showMore, setShowMore] = useState(false);

  return propertyTypeOptions ? (
    <>
      <div className={`tw-mb-[8px] tw-text-12d ${hasError ? "tw-text-red-500" : "tw-text-gray-75"}`}>
        Select one or more types
      </div>
      <div className="tw-grid tw-grid-cols-3 tw-gap-[12px]">
        {propertyTypeOptions?.map((opt, idx) => {
          const skipRender = idx > 2 && showMore === false;
          const selected = selectedTypes[opt];
          const clickHandler = () => {
            setSelected((s) => {
              const nS = { ...s };
              if (selected) {
                delete nS[opt];
              } else {
                nS[opt] = true;
              }
              return { ...nS };
            });
          };
          return skipRender ? null : (
            <PropTypeButton
              key={opt}
              opt={opt}
              onClick={(e) => clickEventCatch(e, clickHandler)}
              selected={selected}
            />
          );
        })}
      </div>
      <ShowMoreLess showMore={showMore} onClick={() => setShowMore((s) => !s)} />
    </>
  ) : null;
};

PropertyList.propTypes = {
  propertyTypeOptions: Proptypes.arrayOf(Proptypes.string),
  setSelected: Proptypes.func,
  selectedTypes: Proptypes.arrayOf(Proptypes.string),
  hasError: Proptypes.bool,
};

PropertyList.defaultProps = {
  propertyTypeOptions: null,
  setSelected: null,
  selectedTypes: null,
  hasError: false,
};

export default PropertyList;
