import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "../../../../../shared/v2/Button";

import { confirmAssignedActionDeletion } from "../../actions/creators";
import { deleteAction, markActionAsComplete, restartAction } from "../../actions/thunks";

import { canDeleteAction, canMarkActionAsComplete, canRestartAction } from "./ActionDetails/helpers";

import TaskActionDetails from "./ActionDetails/TaskActionDetails";
import EmailOrTextActionDetails from "./ActionDetails/EmailOrTextActionDetails";
import ActionDeletionConfirmationModal from "./ActionDeletionConfirmationModal";
import ActionList from "./ActionList";

const LeftPanel = ({ children }) => (
  <div className="tw-relative tw-flex tw-w-[45%] tw-border-solid tw-border-0 tw-border-r tw-border-neutral-gray-10">
    <div className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-overflow-y-auto tw-overflow-x-clip">
      {children}
    </div>
  </div>
);
LeftPanel.propTypes = { children: PropTypes.node.isRequired };

const RightPanel = ({ children, footer }) => (
  <div className="tw-relative tw-flex tw-flex-col tw-w-[55%]" data-cy="action-details-pane">
    <div
      className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-overflow-y-auto tw-overflow-x-clip"
      // Hardcoded value is the height for the footer + border, so they don't overlap.
      style={{ height: "calc(100% - 59px)" }}
    >
      {children}
    </div>
    {footer && <div className="tw-relative tw-flex tw-flex-row tw-mt-auto">{footer}</div>}
  </div>
);
RightPanel.propTypes = { children: PropTypes.node.isRequired, footer: PropTypes.node };
RightPanel.defaultProps = { footer: null };

/** Actions for an assigned action (heh). E.g.: delete, restart, mark as complete */
const ActionButtons = ({
  disabled,
  onDeleteClicked,
  onMarkAsCompleteClicked,
  onSendNowClicked,
  showDelete,
  showMarkAsComplete,
  showSendNow,
}) => (
  <div className="tw-w-full tw-pl-24px">
    <div className="tw-flex tw-justify-between tw-py-16px tw-border-solid tw-border-0 tw-border-t tw-border-neutral-gray-10">
      <span>
        {showDelete && (
          <Button
            schema="warning"
            disabled={disabled}
            onClick={onDeleteClicked}
            data-cy="delete-action-button"
          >
            Delete
          </Button>
        )}
      </span>

      <span className="tw-flex tw-gap-12px">
        {showMarkAsComplete && (
          <Button
            schema="secondary"
            disabled={disabled}
            onClick={onMarkAsCompleteClicked}
            data-cy="mark-action-as-complete-button"
          >
            Mark as complete
          </Button>
        )}

        {showSendNow && (
          <Button
            schema="secondary"
            disabled={disabled}
            onClick={onSendNowClicked}
            data-cy="restart-action-button"
          >
            Send now
          </Button>
        )}
      </span>
    </div>
  </div>
);
ActionButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onMarkAsCompleteClicked: PropTypes.func.isRequired,
  onSendNowClicked: PropTypes.func.isRequired,
  showDelete: PropTypes.bool.isRequired,
  showMarkAsComplete: PropTypes.bool.isRequired,
  showSendNow: PropTypes.bool.isRequired,
};

const PlanDetails = ({
  dispatch,
  plan,
  actionDeletionStatus,
  actionDeletionError,
  actionRestartOrMarkAsCompleteStatus,
  showActionDeletionConfirmationModalFor,
}) => {
  const [currentlySelectedActionId, setCurrentlySelectedActionId] = useState(null);

  const planActions = plan.assignedActions || plan.actions;

  useEffect(() => {
    if (currentlySelectedActionId !== null) {
      return;
    }

    const firstAvailableAction = planActions[0];
    setCurrentlySelectedActionId(firstAvailableAction.id);
  }, [plan]);

  const currentlySelectedAction = planActions.find(
    (assignedAction) => assignedAction.id === currentlySelectedActionId,
  );

  if (currentlySelectedActionId === null || currentlySelectedAction === null) {
    // Ignore the first render - we only care about rendering once we have the
    // action selected.
    //
    // The reason we do this instead of simply passing the desired
    // initial/default selected action to `useState` is because then, we can use
    // the `useEffect` to _only_ set `currentlySelectedActionId` if it hasn't
    // been set before.
    //
    // This makes it so that when the plan details reload after, for example,
    // marking an action as complete, we don't lose the user's previous
    // selection.
    return null;
  }

  const ActionDetails =
    currentlySelectedAction?.type === "Task" ? TaskActionDetails : EmailOrTextActionDetails;

  const shouldShowFooterButtons =
    (currentlySelectedAction && canDeleteAction(currentlySelectedAction.state, plan.state)) ||
    canMarkActionAsComplete(currentlySelectedAction.type, plan.state) ||
    canRestartAction(currentlySelectedAction.type, currentlySelectedAction.state, plan.state);

  const calltoActionFooter = (
    <ActionButtons
      disabled={actionRestartOrMarkAsCompleteStatus === "loading"}
      onDeleteClicked={() => dispatch(confirmAssignedActionDeletion(plan.id, currentlySelectedActionId))}
      onMarkAsCompleteClicked={() => dispatch(markActionAsComplete(plan.id, currentlySelectedActionId))}
      onSendNowClicked={() => dispatch(restartAction(plan.id, currentlySelectedActionId))}
      showDelete={canDeleteAction(currentlySelectedAction.state, plan.state)}
      showMarkAsComplete={canMarkActionAsComplete(currentlySelectedAction.type, plan.state)}
      showSendNow={canRestartAction(currentlySelectedAction.type, currentlySelectedAction.state, plan.state)}
    />
  );

  return (
    <div className="tw-relative tw-flex tw-flex-auto">
      <ActionDeletionConfirmationModal
        action={currentlySelectedAction}
        planName={plan.name}
        error={actionDeletionError}
        show={!!showActionDeletionConfirmationModalFor}
        isLoading={actionDeletionStatus === "loading"}
        onConfirm={() => dispatch(deleteAction(plan.id, currentlySelectedActionId))}
        onCancel={() => dispatch(confirmAssignedActionDeletion(null))}
      />

      <LeftPanel>
        {
          // groupedActions has `events` before `days`, which is the opposite of
          // how we want to show these, so we `.reverse()` the array.
          Object.entries(plan.groupedActions)
            .reverse()
            .map(([groupType, groupKeys]) =>
              // groupType is either "events" or "days". Inside, we have either the
              // relevant event name or date (e.g. 20221005).
              Object.entries(groupKeys).map(([dayOrEventName, actionsMetadata]) => {
                const sortingField = groupType === "days" ? "runAt" : "sortKey";

                // At the moment we're iterating over `plan.groupedActions`,
                // which only contains a minimal set of fields for any given
                // action, so we need to go into the `planActions`
                // field, which actually has all the data we'll need for the
                // actions.
                const actions = actionsMetadata
                  .map(({ id: currentActionId }) =>
                    planActions.find((assignedAction) => assignedAction.id === currentActionId),
                  )
                  .sort((a, b) => (a[sortingField] > b[sortingField] ? 1 : -1))
                  .sort((a, b) => ((a.position || 0) > (b.position || 0) ? 1 : -1));

                return (
                  <ActionList
                    key={dayOrEventName}
                    actions={actions}
                    currentlySelectedActionId={currentlySelectedActionId}
                    setCurrentlySelectedActionId={setCurrentlySelectedActionId}
                  />
                );
              }),
            )
        }
      </LeftPanel>

      <RightPanel footer={shouldShowFooterButtons ? calltoActionFooter : null}>
        <div className="tw-pl-24px tw-pt-24px">
          <ActionDetails action={currentlySelectedAction} />
        </div>
      </RightPanel>
    </div>
  );
};

PlanDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // TODO: Type this up properly.
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.any.isRequired,
  actionDeletionStatus: PropTypes.oneOf(["idle", "loading", "succeeded", "failed"]).isRequired,
  actionDeletionError: PropTypes.string,
  actionRestartOrMarkAsCompleteStatus: PropTypes.oneOf(["idle", "loading", "succeeded", "failed"]).isRequired,
  showActionDeletionConfirmationModalFor: PropTypes.shape({
    actionId: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
  }),
};

PlanDetails.defaultProps = {
  showActionDeletionConfirmationModalFor: null,
  actionDeletionError: null,
};

const mapStateToProps = (state) => ({
  actionDeletionStatus: state.tdpAutoPlansReducer.assignedActionDelete.status,
  actionDeletionError: state.tdpAutoPlansReducer.assignedActionDelete.error,
  actionRestartOrMarkAsCompleteStatus: state.tdpAutoPlansReducer.assignedActionRestartOrMarkAsComplete.status,
  showActionDeletionConfirmationModalFor: state.tdpAutoPlansReducer.assignedActionDelete.showModalFor,
});

export default connect(mapStateToProps)(PlanDetails);
