/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UserPlusSolidV6 = (props) => (
  <Icon {...props} name="User Plus Solid V6">
    <path d="M5.6 8a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4zm1.268 1.2H4.333a4.333 4.333 0 0 0-4.332 4.333c0 .48.388.867.867.867h9.467a.865.865 0 0 0 .866-.867A4.333 4.333 0 0 0 6.869 9.2zM15.4 6.6h-1.2V5.4a.6.6 0 1 0-1.2 0v1.2h-1.2a.601.601 0 0 0 0 1.2H13V9a.6.6 0 1 0 1.2 0V7.8h1.2c.333 0 .6-.267.6-.6s-.267-.6-.6-.6z" />
  </Icon>
);

export default UserPlusSolidV6;
