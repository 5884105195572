/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowRightSolidV6 = (props) => (
  <Icon {...props} name="Arrow Right Solid V6">
    <path d="m14.706 8.706-5 5a.997.997 0 0 1-1.413.001.999.999 0 0 1 0-1.414L11.587 9H1.999a1 1 0 1 1 0-2h9.588L8.293 3.706a.999.999 0 1 1 1.414-1.414l5 5a1 1 0 0 1-.002 1.414z" />
  </Icon>
);

export default ArrowRightSolidV6;
