import React from "react";
import { string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  REFERRALPARTNER_NAME,
  REFERRALPARTNER_PDPLINK,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import DetailLinkField from "../shared/DetailLinkField";
import DetailTxtNonEditField from "../shared/DetailTxtNonEditField/DetailTxtNonEditField";

import { DETAIL_FIELD_VALUE_LINK } from "../shared/styles";

const ReferralPartnerFieldWrapper = ({ name, pdpLink }) =>
  name ? (
    <DetailLinkField
      id="ReferralPartnerField"
      label="Referral Partner"
      fieldValue={name}
      txtLink={pdpLink}
      txtBtnClassName={DETAIL_FIELD_VALUE_LINK}
    />
  ) : (
    <DetailTxtNonEditField
      id="ReferralPartnerField"
      label="Referral Partner"
      txtContainerClassName="tw-normal-case"
      fieldValue="No partner assigned"
    />
  );

export default tdpDetails({
  WrappedComponent: ReferralPartnerFieldWrapper,
  states: [REFERRALPARTNER_NAME, REFERRALPARTNER_PDPLINK],
});

ReferralPartnerFieldWrapper.propTypes = {
  name: string,
  pdpLink: string,
};

ReferralPartnerFieldWrapper.defaultProps = {
  name: null,
  pdpLink: null,
};
