/* eslint-disable camelcase */
import {
  createTask,
  adaptPostDueOn,
  createFilters,
  adaptRecurringDays,
  adaptRecurringDayOfWeek,
  adaptContingentRule,
} from "./helpers";
import { DEFAULT_FILTERS } from "../constants";

export const adaptEditedCreatedTask = (task) => createTask(task);

export const adaptPostPutTask = (payload) => ({
  uuid: payload.uuid,
  name: payload.title,
  instructions: payload.instructions,
  note: payload.notes,
  priority: payload.priority,
  externally_visible: payload.visibility,
  type: payload.eventType.value,
  due_on: adaptPostDueOn(payload.date),
  assigned_to_user_id: payload.assignedPerson.value,
  completed_by_user_id: payload.completed_by_user_id,
  recurring: !!payload.recurring,
  completed: payload.completed,
  recurring_frequency: payload.recurring?.frequency?.value,
  recurring_every_so_many_days: adaptRecurringDays(payload),
  recurring_day_of_week: adaptRecurringDayOfWeek(payload),
  run: payload.run,
  contingent: !!payload.contingent,
  contingency_rule: adaptContingentRule(payload),
});

export const adaptGetAllTasks = (tasks = []) => tasks.map((task) => createTask(task));

export const adaptTitle = ({ title }) => ({
  title,
});

export const adaptTeam = (team) =>
  team.map(({ name: label, uuid: value }) => ({ label, value })).sort((a, b) => a - b);

export const adaptFilterPayload = (tasks) => {
  const filters = {
    ...DEFAULT_FILTERS,
  };

  return createFilters(filters, tasks);
};

export const adaptPutBulkEditTask = (listingUuid, selectedTasks, payload) => ({
  uuid: listingUuid,
  tasks: selectedTasks.map((id) => ({ ...payload, id })),
  authenticity_token: window.ReactOnRails.authenticityToken(),
});
