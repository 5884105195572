import React from "react";
import { node } from "prop-types";

const Side = ({ children }) => (
  <div className="tw-bg-white tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-8px tw-w-[24%] tw-max-w-[326px] tw-h-fit">
    {children}
  </div>
);

export default Side;

Side.propTypes = {
  children: node,
};

Side.defaultProps = {
  children: null,
};
