import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../../shared/v2/Button";
import Tooltip from "../../../shared/v2/Tooltip";
import {
  useLoadingCrmUserIdAccess,
  useSuccessCrmUserIdAcccess,
  useErrorCrmUserIdAccess,
} from "../../../reducers/userSettingsReducer";
import {
  SpinnerSolidV3,
  Check,
  TriangleExclamationRegularV6,
  CircleInfoSolidV6,
} from "../../../shared/v2/Icomoon";
import { useSetCrmModal } from "../helper/hookHelper";
import { useSendInviteEmail } from "../actions/apiActions";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const STYLES = {
  label: "tw-text-12d tw-text-gray-50 tw-font-[600]",
  date: "tw-text-14d tw-text-gray-75 tw-font-[400]",
  container: "tw-flex tw-flex-col",
};

const OktaInviteButton = ({ lastActiveDate, oktaInvitedAt, userId, crmAccess, oktaLastSignInAt }) => {
  const currentUser = useCurrentUser();
  const setCrmAccessModalHandlr = useSetCrmModal();
  const sendInviteEmail = useSendInviteEmail();
  const isSendingUserId = useLoadingCrmUserIdAccess() === userId;
  const isSuccessUserId = useSuccessCrmUserIdAcccess() === userId;
  const isErrorUserId = useErrorCrmUserIdAccess() === userId;

  if (lastActiveDate) {
    return (
      <div className={STYLES.container}>
        <div className={STYLES.label}>Brivity Activity</div>
        <div className={STYLES.date}>
          {lastActiveDate ? moment(lastActiveDate).format("MM/DD/YYYY") : "-"}
        </div>
      </div>
    );
  }

  if (oktaLastSignInAt && !crmAccess) {
    return (
      <div className={STYLES.container}>
        <div className={`${STYLES.label} tw-items-center tw-gap-[4px]`}>
          <span>Account Activity</span>
          <Tooltip
            className="tw-ml-[4px] tw-h-[12px]"
            multiline
            placement="top"
            tooltipClassName="tw-text-14d"
            content={<div className="tw-w-[224px]">This user has accessed their PLACE account. </div>}
            trigger={<CircleInfoSolidV6 className="tw-h-[12px] tw-w-[12px] tw-text-black" />}
          />
        </div>
        <div className={STYLES.date}>{moment(oktaLastSignInAt).format("MM/DD/YYYY")}</div>
      </div>
    );
  }

  if (oktaLastSignInAt && crmAccess) {
    return (
      <div className={STYLES.container}>
        <div className={STYLES.label}>Brivity Activity</div>
        <div className={STYLES.date}>-</div>
      </div>
    );
  }

  if (currentUser.account.isAdmin) {
    return (
      <>
        <Button
          size="small"
          schema="primary"
          onClick={() => {
            if (crmAccess) {
              sendInviteEmail(userId);
              return;
            }

            setCrmAccessModalHandlr(true, userId);
          }}
        >
          {oktaInvitedAt ? "Resend Invite" : "Send Invite"}{" "}
          {isSendingUserId && <SpinnerSolidV3 size="m" className="fa-spin tw-ml-[4px] tw-text-white" />}
          {isSuccessUserId && <Check size="m" className="tw-ml-[4px] tw-text-white" />}
        </Button>
        {isErrorUserId && (
          <Tooltip
            placement="top"
            content={<div className="tw-w-[110px]">Failed To Send</div>}
            trigger={
              <TriangleExclamationRegularV6 size="m" className="tw-ml-[4px] tw-text-semantic-red-50" />
            }
          />
        )}
      </>
    );
  }

  return <div className="tw-text-14d tw-font-[400]">-</div>;
};

OktaInviteButton.propTypes = {
  crmAccess: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  lastActiveDate: PropTypes.string,
  oktaInvitedAt: PropTypes.string,
  oktaLastSignInAt: PropTypes.string,
};

OktaInviteButton.defaultProps = {
  lastActiveDate: null,
  oktaInvitedAt: null,
  oktaLastSignInAt: null,
};

export default OktaInviteButton;
