import React from "react";
import { Modal, CancelButton, DeleteButton } from "../../shared/v1";
import Error from "../../shared/Error";

const DeleteMilestoneModal = (props) => {
  const {
    isDeleteMilestoneModalOpen,
    isDeletingMilestone,
    closeDeleteMilestoneModal,
    deleteMilestone,
    milestoneToDelete,
    hasError,
    errorMessage,
    milestonePath,
  } = props;

  return (
    <Modal show={isDeleteMilestoneModalOpen} onHide={() => closeDeleteMilestoneModal()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="tw-text-brand-danger">Do you really want to delete this custom date?</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasError && <Error errorMessage={errorMessage} />}

        <p>You can only delete a custom date if it isn’t in use.</p>
        <div className="p-t-20">
          <CancelButton size="small" className="modal-cancel" onClick={() => closeDeleteMilestoneModal()} />
          <DeleteButton
            className="tw-float-right"
            onClick={() => deleteMilestone(`/new_milestones/${milestoneToDelete.id}`)}
            disabled={isDeletingMilestone}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteMilestoneModal;
