import React from "react";

// Copied from:
// https://gitlab.brivity.com/brivity/notifications/-/blob/master/src/app/views/listing_alerts/_separator.html.erb
const EmailBodySeparator = () => {
  return (
    <table
      className="module"
      role="module"
      data-type="divider"
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      style={{ tableLayout: "fixed", backgroundColor: "#ffffff" }}
      data-muid="cCZeK4swxJ3vQBy8MVQYzg.1"
    >
      <tbody>
        <tr>
          <td
            style={{ padding: "0px 0px 20px 0px" }}
            role="module-content"
            height="100%"
            valign="top"
            bgcolor=""
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              align="center"
              width="100%"
              height="1px"
              style={{ lineHeight: "1px", fontSize: "1px" }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "0px 0px 1px 0px" }} bgcolor="#b8b8b8"></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmailBodySeparator;
