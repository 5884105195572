import { useEffect, useState } from "react";
import { isCancel } from "axios";
import formatter from "../../../../shared/formatters";
import { caseTransformingAxios as axios } from "../../../../shared/v2/caseTransformingAxios";

const keyToFormat = (key) => {
  if (/income|volume/i.test(key)) return "currency";
  return "number";
};

const transformData = ({ transactions }) => ({
  ...Object.keys(transactions).reduce(
    (d, transaction) => ({
      ...d,
      [transaction]: Object.keys(transactions[transaction]).reduce(
        (o, key) => ({ ...o, [key]: formatter[keyToFormat(key)](transactions[transaction][key]) }),
        {},
      ),
    }),
    {},
  ),
});

const useAgentTransactions = ({ agentUuid, year }) => {
  const [data, setData] = useState({
    closings: {},
    pendings: {},
    activeListings: {},
    comingSoon: {},
    pipeline: {},
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    axios
      .get("/api/v4/reporting/agent_dashboard", {
        signal: abortController.signal,
        params: {
          selected_year: year,
          section: "agent_transactions_overview",
          person_uuid: agentUuid,
        },
      })
      .then((res) => {
        setData(
          transformData(res.data),
        );
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [agentUuid, year]);

  return { data, error, loading };
};

export default useAgentTransactions;
