import formatter from "../../../../shared/formatters";
import colors, { BRIVITY_COLORS } from "../helpers/colors";
import getChartTextAlignment from "../helpers/getChartTextAlignment";

export const transformBusinessByLeadSourceData = ({
  checks,
  selectedSources,
  rawData: { leadSources } = { leadSources: [] },
}) => {
  const allSelected = selectedSources[0]?.value === "all";
  const sources = [...leadSources].sort((a, b) => {
    const aTotal = (checks[0] ? a.sold : 0) + (checks[1] ? a.pending : 0);
    const bTotal = (checks[0] ? b.sold : 0) + (checks[1] ? b.pending : 0);
    return bTotal - aTotal;
  });
  const data = sources.reduce(
    (prev, source) =>
      allSelected || selectedSources.some((s) => s.value === source.id) ? prev.concat(source) : prev,
    [],
  );
  return {
    labels: sources.reduce(
      (prev, { id, name }) =>
        allSelected || selectedSources.some((source) => source.value === id) ? prev.concat(name) : prev,
      [],
    ),
    datasets: [
      {
        label: "Closed Units",
        data,
        hidden: !checks[0],
        backgroundColor: colors.color1.default,
        datalabels: {
          color: (context) => {
            const alignment = getChartTextAlignment("number")(context);
            return alignment === "end" ? BRIVITY_COLORS.color1.default : "#ffffff";
          },
          anchor: "end",
          align: getChartTextAlignment("number"),
          formatter: (item, { dataset }) => item[Object.values(dataset.parsing)[0]] || "",
        },
        parsing: {
          xAxisKey: "sold",
          yAxisKey: "sold",
        },
      },
      {
        label: "Pending Units",
        data,
        hidden: !checks[1],
        backgroundColor: BRIVITY_COLORS.color3.default,
        hoverBackgroundColor: BRIVITY_COLORS.color3.hover,
        datalabels: {
          color: (context) => {
            const alignment = getChartTextAlignment("number")(context);
            return alignment === "end" ? BRIVITY_COLORS.color3.default : "#ffffff";
          },
          anchor: "end",
          align: getChartTextAlignment("number"),
          offset: (context) => {
            const alignment = getChartTextAlignment("number", 0)(context);
            return alignment === "end" ? 20 : 4;
          },
          formatter: (item, { dataset }) => item[Object.values(dataset.parsing)[0]],
        },
        parsing: {
          xAxisKey: "pending",
          yAxisKey: "pending",
        },
      },
    ],
  };
};

export const transformSummaryData = (
  { summary } = { summary: { netFromSources: 0, sources: 0, ytdClosedUnits: 0 } },
) => ({
  sources: formatter.number(summary.sources),
  ytdClosedUnits: formatter.number(summary.ytdClosedUnits),
  netFromSources: formatter.currency(summary.netFromSources),
});
