import {
  fetchAllFeedbacksSuccess,
  fetchAllFeedbacksFailure,
  fetchAllFeedbacksStarted,
  setFeedbackFormData,
  createFeedbackStarted,
  createFeedbackSuccess,
  createFeedbackFailure,
  updateFeedbackStarted,
  updateFeedbackFailure,
  updateFeedbackSuccess,
  destroyFeedbackSuccess,
  destroyFeedbackStarted,
  destroyFeedbackFailure,
  setDeleteFeedbackPromptData,
  changeFeedbackVisibilityStarted,
  changeFeedbackVisibilitySuccess,
  changeFeedbackVisibilityFailure,
  clearChangeFeedbackVisibilityFailure,
  updateAllFeedbacksStats,
  loadMoreFeedbacksStarted,
  loadMoreFeedbacksSuccess,
  loadMoreFeedbacksFailure,
} from "../TransactionDetailsPage/Main/Feedback/actions/creators";

export const defaultState = {
  uuid: "",
  feedbacks: [],
  meta: {
    averageRating: 0.0,
    pagination: {
      totalPages: 0,
      totalCount: 0,
    },
  },
  error: null,
  isLoading: false,
  formData: {
    action: "new",
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
  promptData: {
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
};

export const tdpFeedbackReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case fetchAllFeedbacksStarted().type:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case fetchAllFeedbacksSuccess().type:
      return {
        ...state,
        feedbacks: payload.feedbacks,
        meta: payload.meta,
        error: null,
        isLoading: false,
      };
    case fetchAllFeedbacksFailure().type:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
      };
    case loadMoreFeedbacksStarted().type:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case loadMoreFeedbacksSuccess().type: {
      // Since users can add/edit/delete showing feedback while viewing the paginated results, the
      // next page could deliver duplicated items. For this reason, we remove all duplicated items.
      // Note that the pagination (meta object) is also updated here, so there won't be issues
      // about missing items on the last page.
      const existingIds = state.feedbacks.reduce((acc, element) => {
        acc[element.id] = element.id;
        return acc;
      }, {});
      // Remove duplicates in the payload array
      const fetchedFeedbacks = payload.feedbacks.filter((element) => !(element.id in existingIds));
      return {
        ...state,
        feedbacks: [...state.feedbacks, ...fetchedFeedbacks],
        meta: payload.meta,
        error: null,
        isLoading: false,
      };
    }
    case loadMoreFeedbacksFailure().type:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
      };
    case updateAllFeedbacksStats().type:
      return {
        ...state,
        // Only update averageRating and totalCount to avoid interfering with pagination
        meta: {
          ...state.meta,
          averageRating: payload.meta.averageRating,
          pagination: {
            ...state.meta.pagination,
            totalCount: payload.meta.pagination.totalCount,
          },
        },
      };
    case setFeedbackFormData().type:
      return {
        ...state,
        formData: payload.formData,
      };
    case createFeedbackStarted().type:
    case updateFeedbackStarted().type:
      return {
        ...state,
        formData: {
          ...state.formData,
          isLoading: true,
        },
      };
    case createFeedbackSuccess().type:
      return {
        ...state,
        feedbacks: [payload.feedback, ...state.feedbacks],
        meta: {
          ...state.meta,
          pagination: {
            ...state.meta.pagination,
            totalCount: state.meta.pagination.totalCount + 1,
          },
        },
        formData: {
          ...state.formData,
          isLoading: false,
        },
      };
    case createFeedbackFailure().type:
    case updateFeedbackFailure().type:
      return {
        ...state,
        formData: {
          ...state.formData,
          error: payload.error,
          isLoading: false,
        },
      };
    case updateFeedbackSuccess().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedback.id);

      if (index !== -1) {
        feedbacks[index] = payload.feedback;
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
        formData: {
          ...state.formData,
          isLoading: false,
        },
      };
    }
    case changeFeedbackVisibilityStarted().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedbackId);
      const feedback = feedbacks[index];

      if (feedback) {
        feedbacks[index] = {
          ...feedback,
          meta: {
            ...feedback.meta,
            changeVisibility: {
              isLoading: true,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
      };
    }
    case changeFeedbackVisibilitySuccess().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedbackId);
      const feedback = feedbacks[index];

      if (feedback) {
        feedbacks[index] = {
          ...feedback,
          externallyVisible: payload.externallyVisible,
          meta: {
            ...feedback.meta,
            changeVisibility: {
              isLoading: false,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
      };
    }
    case changeFeedbackVisibilityFailure().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedbackId);
      const feedback = feedbacks[index];

      if (feedback) {
        feedbacks[index] = {
          ...feedback,
          meta: {
            ...feedback.meta,
            changeVisibility: {
              isLoading: false,
              error: payload.error,
            },
          },
        };
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
      };
    }
    case clearChangeFeedbackVisibilityFailure().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedbackId);
      const feedback = feedbacks[index];

      if (feedback) {
        feedbacks[index] = {
          ...feedback,
          meta: {
            ...feedback.meta,
            changeVisibility: {
              isLoading: false,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
      };
    }
    case setDeleteFeedbackPromptData().type:
      return {
        ...state,
        promptData: payload.promptData,
      };
    case destroyFeedbackStarted().type:
      return {
        ...state,
        promptData: {
          ...state.promptData,
          isLoading: true,
        },
      };
    case destroyFeedbackSuccess().type: {
      const { feedbacks } = state;

      const index = feedbacks.findIndex((feedback) => feedback.id === payload.feedbackId);

      if (index !== -1) {
        feedbacks.splice(index, 1);
      }

      return {
        ...state,
        feedbacks: [...feedbacks],
        meta: {
          ...state.meta,
          pagination: {
            ...state.meta.pagination,
            totalCount: state.meta.pagination.totalCount - 1,
          },
        },
        promptData: {
          ...state.promptData,
          isLoading: false,
        },
      };
    }
    case destroyFeedbackFailure().type:
      return {
        ...state,
        promptData: {
          ...state.promptData,
          error: payload.error,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
