import React from "react";
import Button from "./Button";
import { Modal } from "react-bootstrap";

const DeleteConfirm = ({ close, confirm }) => (
  <>
    <Modal
      show={true}
      onHide={close}
      bsPrefix="lp-delete"
      backdropClassName="lp-delete-bck"
      dialogClassName="tw-max-w-xl tw-mt-20"
    >
      {/*<ModalMask onClick={close} />*/}
      <div className="tw-p-6">
        <h2 className="tw-mb-10">Delete pond?</h2>
        <p>All leads in this pond will be reassigned to the account owner.</p>
        <div className="tw-flex tw-mt-20">
          <Button type="cancel" title="Cancel" onClick={close} />
          <div className="tw-grow" />
          <Button type="hard-remove" title="Delete" onClick={confirm} />
        </div>
      </div>
    </Modal>
  </>
);

export default DeleteConfirm;
