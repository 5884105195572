import React, { useRef } from "react";
import { bool, string, node } from "prop-types";
import { useClickOutside } from "@shared/hookHelpers";

const DropdownPopUp = ({ show, containerClassName, children, onHide }) => {
  
  const ref = useRef(null);
  useClickOutside(ref, () => onHide());

  return show && (
    <div className={`tw-right-0 tw-mt-[2px] tw-absolute tw-bg-white tw-border-[1px] tw-border-gray-15 tw-rounded-[4px] ${containerClassName}`} ref={ref}>
      {children}
    </div>
  )
};

export default DropdownPopUp;

DropdownPopUp.propTypes = {
  show: bool.isRequired,
  containerClassName: string,
  children: node,
};

DropdownPopUp.defaultProps = {
  containerClassName: '',
  children: null,
};