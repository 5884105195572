export const outboundRecordingNotificationOptions = (isPlace, isTwoPartyConsentAccount) => {
  const options = [
    {
      label: "Always Play",
      value: "always",
      meta: {
        description: "An audio notification message will play every time recording begins.",
      },
    },
  ];

  if (!isPlace) {
    options.push({
      label: "Never Play",
      value: "never",
      meta: {
        description: "No audio message will play when recording starts.",
      },
    });
    options.push({
      label: "Check Location",
      value: "check",
      meta: {
        description:
          "An audio notification message will only play if the contact phone number's area code is from a state that requires 2-party consent.",
      },
    });
  } else if (!isTwoPartyConsentAccount) {
    options.push({
      label: "Check Location",
      value: "check",
      meta: {
        description:
          "An audio notification message will only play if the contact phone number's area code is from a state that requires 2-party consent.",
      },
    });
  }

  return options;
};

export const inboundRecordingNotificationOptions = (isPlace, isTwoPartyConsentAccount) => {
  const options = [
    {
      label: "Always Play",
      value: "always",
      meta: {
        description: "An audio notification message will play every time recording begins.",
      },
    },
  ];

  if (!isTwoPartyConsentAccount || !isPlace) {
    options.push({
      label: "Check Location",
      value: "check",
      meta: {
        description:
          "An audio notification message will only play if the contact phone number's area code is from a state that requires 2-party consent.",
      },
    });
  }

  return options;
};
