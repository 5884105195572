import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defaultOrImage } from "../../shared/DefaultOrImage";
import Moment from 'react-moment';
import css from "./report-styles.module.css";
import {truncateTime} from "./reportHelpers";

const AgentCallsRow = ({agent}) => {
  return(
  <Col xs={12} className={`${css.noWrap} user td`} key={agent.id}>
    <Col xs={2} className='ellipsify'>
      <div style={{display: 'inline-block',padding: '0 10px'}}>
        <a href={`/people/${agent.user_path}`}>
          {defaultOrImage(agent.avatar, agent.agent)}
        </a>
      </div>
      <div style={{display: 'inline-block'}}>
        {agent.agent}
      </div>
    </Col>

    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.call_report_agent_total_lead_count}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.call_report_lead_call_count}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.call_report_manual_calls}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.call_report_dialer_calls}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.call_report_total_calls}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block', position: 'relative'}}>
      {agent.call_report_total_dialer_call_time !== '0 seconds' ?
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement='right'
          overlay={(
            <Tooltip id='call_resp_time' style={{position: 'absolute', left: '0px', top: '0px'}}>
              {agent.call_report_total_dialer_call_time}
            </Tooltip>
          )}>
        <span>
          {truncateTime(agent.call_report_total_dialer_call_time)}
        </span>
        </OverlayTrigger>
        :
        <span>
        {truncateTime(agent.call_report_total_dialer_call_time)}
      </span>
      }
    </div>
  </Col>
  )
}

export default AgentCallsRow;
