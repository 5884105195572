import React from "react";
import { bool, func } from "prop-types";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";

const ModalDeleteImage = ({ isModalOpen, toggleModal, onDelete }) => (
  <Modal
    show={isModalOpen}
    onHide={() => toggleModal((prev) => !prev)}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    closeButton
  >
    <Modal.Header title="Are you sure?" className="tw-mb-32px" closeButton />
    <p className="tw-mb-32px">
      Fair warning: This will remove the image from the listing and this action cannot be undone
    </p>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={() => toggleModal((prev) => !prev)}>
        Cancel
      </Button>
      <Button
        data-cy="listing-img-delete-confirm"
        size="medium"
        schema="primary"
        onClick={() => {
          onDelete();
          toggleModal((prev) => !prev);
        }}
      >
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

ModalDeleteImage.propTypes = {
  isModalOpen: bool,
  toggleModal: func,
  onDelete: func,
};

ModalDeleteImage.defaultProps = {
  isModalOpen: false,
  toggleModal: () => {},
  onDelete: () => {},
};

export default ModalDeleteImage;
