import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";

import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextLink from "@shared/v2/TextLink";

import UploadTable from "./UploadTable";

const ModalUpload = ({
  shouldShowAllCheckbox,
  areAllDocumentsSelected,
  shouldShowBulkActions,

  handlePreview,
  clearDocumentErrors,

  handleSelectAllDocuments,
  handleBulkDelete,
  handleSelect,
  handleRenameDocument,
  handleDeleteDocuments,
  uploadedDocuments,
  context,
  isModalOpen,
  dispatchedThunkCb,
  onHideHandlr,
}) => {
  const uploadIndex = useRef(0);

  const onDrop = useCallback(dispatchedThunkCb, []);

  const { inputRef, getRootProps, getInputProps, isDragActive } = useDropzone({ noClick: true, onDrop });

  const handleHide = () => {
    onHideHandlr();
    uploadIndex.current = 0;
  };

  const handleBrowseClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 636px = 616px from Figma + 10px for each horizontal padding (20px)
      dialogClassName="tw-w-[636px] tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      show={isModalOpen}
      onHide={handleHide}
      data-cy="documents-upload-modal"
    >
      <Modal.Header titleClassName="tw-mb-12px tw-text-neutral-gray-75" title="Upload Document" closeButton />

      <Modal.Body className="tw-mb-32px">
        <p className="tw-m-0 tw-mb-32px tw-text-14d tw-font-normal tw-text-neutral-gray-75">
          Admins and added viewers can preview and download the document(s).
        </p>

        <div
          className={`
            tw-flex tw-flex-col tw-justify-center tw-items-center
            tw-w-full tw-mb-24px tw-py-21px
            tw-border tw-border-dashed tw-rounded-4px
            ${
              isDragActive
                ? "tw-border-tinted-gray-50 tw-bg-tinted-gray-100"
                : "tw-border-tinted-gray-100 tw-bg-tinted-gray-50"
            }
          `}
          data-cy="documents-upload-drop-zone"
          {...getRootProps()}
        >
          <input data-cy="documents-upload-input" {...getInputProps()} />

          <FontAwesomeIcon className="tw-mb-12px tw-text-24px tw-text-neutral-gray-30" icon={faFileAlt} />

          <p className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-neutral-gray-50">
            Drop your files here, or select from computer
          </p>
          <p className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-neutral-gray-50">
            (File Size Limit 15MB)
          </p>
          <TextLink size="small" onClick={handleBrowseClick}>
            Click to browse
          </TextLink>
        </div>

        {uploadedDocuments.length > 0 && (
          <div className="tw-max-h-300px tw-overflow-auto">
            <UploadTable
              shouldShowAllCheckbox={shouldShowAllCheckbox}
              areAllDocumentsSelected={areAllDocumentsSelected}
              shouldShowBulkActions={shouldShowBulkActions}
              handlePreview={handlePreview}
              handleSelectAllDocuments={handleSelectAllDocuments}
              handleBulkDelete={handleBulkDelete}
              handleSelect={handleSelect}
              handleRenameDocument={handleRenameDocument}
              handleDeleteDocuments={handleDeleteDocuments}
              uploadedDocuments={uploadedDocuments}
              clearDocumentErrors={clearDocumentErrors}
              context={context}
            />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-end">
        <Button
          className="tw-flex tw-items-center"
          size="medium"
          schema="primary"
          disabled={uploadedDocuments.length === 0}
          onClick={handleHide}
          data-cy="documents-upload-done-button"
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalUpload.propTypes = {
  uploadedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  isModalOpen: PropTypes.bool,
  dispatchedThunkCb: PropTypes.func,
  onHideHandlr: PropTypes.func,
  shouldShowAllCheckbox: PropTypes.arrayOf(PropTypes.shape({})),
  areAllDocumentsSelected: PropTypes.arrayOf(PropTypes.shape({})),
  shouldShowBulkActions: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelectAllDocuments: PropTypes.func,
  handleBulkDelete: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRenameDocument: PropTypes.func,
  handleDeleteDocuments: PropTypes.func,
  handlePreview: PropTypes.func,
  clearDocumentErrors: PropTypes.func,
  context: PropTypes.string.isRequired,
};

ModalUpload.defaultProps = {
  uploadedDocuments: [],
  isModalOpen: false,
  dispatchedThunkCb: () => {},
  onHideHandlr: () => {},
  shouldShowAllCheckbox: [],
  areAllDocumentsSelected: [],
  shouldShowBulkActions: [],
  handleSelectAllDocuments: () => {},
  handleBulkDelete: () => {},
  handleSelect: () => {},
  handlePreview: () => {},
  handleRenameDocument: () => {},
  handleDeleteDocuments: () => {},
  clearDocumentErrors: () => {},
};

export default ModalUpload;
