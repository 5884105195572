/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const GearSolidV6 = (props) => (
  <Icon {...props} name="Gear Solid V6">
    <path d="M15.497 5.206a.71.71 0 0 1-.197.769l-1.353 1.231a6.108 6.108 0 0 1 0 1.588l1.353 1.231c.212.197.3.5.197.769a7.95 7.95 0 0 1-.491 1.072l-.147.253a8.058 8.058 0 0 1-.691.978.717.717 0 0 1-.766.213l-1.741-.556a6.817 6.817 0 0 1-1.375.797l-.391 1.784c-.063.281-.281.481-.569.556a8.15 8.15 0 0 1-2.656 0c-.288-.075-.506-.275-.569-.556l-.391-1.784a6.128 6.128 0 0 1-1.375-.797l-1.739.556a.715.715 0 0 1-.766-.213 8.17 8.17 0 0 1-.691-.978l-.146-.253A7.72 7.72 0 0 1 .5 10.794a.711.711 0 0 1 .198-.769L2.05 8.794a6.11 6.11 0 0 1 0-1.588L.698 5.975A.708.708 0 0 1 .5 5.206c.138-.372.304-.731.493-1.072l.146-.253c.207-.344.438-.669.691-.977a.711.711 0 0 1 .766-.213l1.739.555A6.012 6.012 0 0 1 5.71 2.45L6.101.666A.71.71 0 0 1 6.67.109a8.058 8.058 0 0 1 2.656 0 .71.71 0 0 1 .569.557l.391 1.784c.466.204.956.473 1.375.796l1.741-.555a.711.711 0 0 1 .766.213c.253.308.484.633.691.977l.147.253c.188.341.353.7.491 1.072zM8 10.5c1.381 0 2.5-1.119 2.5-2.528 0-1.353-1.119-2.5-2.5-2.5s-2.5 1.147-2.5 2.5A2.506 2.506 0 0 0 8 10.5z" />
  </Icon>
);

export default GearSolidV6;
