import React from "react";
import { string } from "prop-types";

const TransactionTitle = ({ title, streetAddress, city, locality, postalCode, className }) => (
  <div className={`tw-text-[28px] tw-font-normal tw-not-italic tw-text-gray-700 ${className}`}>
    {title || `${streetAddress} ${city} ${locality} ${postalCode}` || "Custom Transaction Title"}
  </div>
);

TransactionTitle.propTypes = {
  streetAddress: string,
  city: string,
  locality: string,
  postalCode: string,
  title: string,
  className: string,
};

TransactionTitle.defaultProps = {
  streetAddress: "",
  city: "",
  locality: "",
  postalCode: "",
  title: "",
  className: "",
};

export default TransactionTitle;
