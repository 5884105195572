const BARCOLORS = [
  ['rgba(89,196,196)', 'rgba(2,148,148,1)'], // light blue
  ['rgba(188,52,14)', 'rgba(127,35,9,1)'], // dark red
  ['rgba(244,179,80)', 'rgba(211,135,18,1)'], // yellow
  ['rgba(51,110,123)', 'rgba(19,46,51,1)'], // dark blue
  ['rgba(242,113,77)', 'rgba(190,68,34,1)'], // coral red
  ['rgba(179,179,179)', 'rgba(128,128,128,1)'] // gray
]

const COLORLABELCLASSES = [
  'light-blue',
  'dark-red',
  'yellow',
  'dark-blue',
  'coral-red',
  'gray',
]

export { BARCOLORS, COLORLABELCLASSES }
