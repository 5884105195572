import React from "react";
import { connect } from "react-redux";
import {
  addCommOnlyOperationalPartner,
  removeCommOnlyOperationalPartner,
  updateCommOnlyOperationalPartner,
} from "../actions/creators";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import TextButton from "../../../../shared/v2/TextButton/TextButton";
import FormElement from "./FormElement";
import AvatarControl from "./AvatarControl";
import AvatarOption from "./AvatarOption";
import DisplayPerson from "./DisplayPerson";

const CommOnlyOperator = ({
  formData,
  partners,
  addCommOnlyOperationalPartner,
  updateCommOnlyOperationalPartner,
  removeCommOnlyOperationalPartner,
}) => {
  const selectedCommPartners = formData?.commOnlyOperationalPartners?.map((p) => p.value?.value);
  const availableCommPartners = partners.filter((p) => selectedCommPartners.indexOf(p.value) <= -1);
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <FormElement
        label="Comm-Only Operator"
        tooltip="Individuals who are NOT signers on the PLACE Operating agreement, but serve as a Co-Operator."
      >
        <Dropdown
          placeholder="Select Operator"
          options={availableCommPartners}
          value={formData?.commOnlyOperationalPartners?.[0]?.value}
          onChange={(opt) => updateCommOnlyOperationalPartner({ index: 0, value: opt })}
          isSearchable
          clearable
          components={{
            Control: AvatarControl,
            Option: AvatarOption,
          }}
        />
      </FormElement>
      {formData?.commOnlyOperationalPartners?.slice(1).map((partner, idx) => (
        <FormElement key={idx} label={`Comm-Only Operator ${idx + 2}`}>
          <DisplayPerson
            value={partner.value}
            options={availableCommPartners}
            onDelete={() => removeCommOnlyOperationalPartner({ index: idx + 1 })}
            onChange={(val) => updateCommOnlyOperationalPartner({ index: idx + 1, value: val })}
          />
        </FormElement>
      ))}
      <TextButton onClick={addCommOnlyOperationalPartner} size="medium" className="tw-self-start">
        + Comm-Only Operator
      </TextButton>
    </div>
  );
};

export default connect(({ superUsersBranding: { formData, partners } }) => ({ formData, partners }), {
  addCommOnlyOperationalPartner,
  removeCommOnlyOperationalPartner,
  updateCommOnlyOperationalPartner,
})(CommOnlyOperator);
