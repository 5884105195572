import React, { useEffect } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { isReferral, isSellerLandlordType } from "../../../shared/helpers";
import errorShape from "../model/error";
import Error from "../Error";
import * as actionCreators from "../actions/thunks";
import TimeFrame from "./TimeFrame";
import Header from "../../../UI/Header";
import DateHeaderOptions from "../HeaderOptions/DateHeaderOptions";
import Expiration from "./Expiration";
import DateListed from "./DateListed";
import ReferralExpiration from "./ReferralExpiration";
import CloseDate from "./CloseDate";
import MutualAcceptanceDate from "./MutualAcceptanceDate";

const Date = ({ getAllDates, uuid, type, dates, error, updateError, isUpdate, status }) => {
  useEffect(() => {
    getAllDates(uuid);
  }, [uuid, isUpdate, status]);
  return (
    <div className="tw-flex tw-flex-col tw-gap-[16px]">
      {error && <Error message={error.message} />}
      <Header header="Dates" add={!isReferral(type) && <DateHeaderOptions />} />
      {updateError && <Error message={updateError.message} />}
      {!isReferral(type) ? dates?.expiration && <Expiration /> : <ReferralExpiration />}
      {isSellerLandlordType(type) && <DateListed />}
      <MutualAcceptanceDate type={type} />
      <CloseDate />
      <div className="tw-flex tw-flex-col tw-gap-[24px]">
        {dates?.dates?.map((date) => (
          <TimeFrame key={date.id} date={date} />
        ))}
      </div>
    </div>
  );
};

Date.propTypes = {
  getAllDates: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
  dates: Proptypes.shape({
    expiration: Proptypes.shape({ id: Proptypes.string, name: Proptypes.string, date: Proptypes.string }),
    dates: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  error: errorShape,
  updateError: errorShape,
  isUpdate: Proptypes.bool,
  status: Proptypes.string.isRequired,
  type: Proptypes.string,
};

Date.defaultProps = {
  dates: {},
  error: null,
  updateError: null,
  isUpdate: false,
  type: null,
};

const mapStateToProps = (state) => ({
  status: state.tdpDetailsReducer.transactionDetails.status,
  uuid: state.tdpDatesReducer.uuid,
  dates: state.tdpDatesReducer.dates,
  error: state.tdpDatesReducer.error,
  updateError: state.tdpDatesReducer.updateError,
  isUpdate: state.tdpDatesReducer.isUpdate,
  type: state.tdpDetailsReducer.headerDetails.type,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDates: (uuid) => dispatch(actionCreators.getAllDates(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Date);
