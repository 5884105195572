/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const DollarSignRegularV6 = (props) => (
  <Icon {...props} name="Dollar Sign Regular V6">
    <path d="M8.75 2.296c.853.087 1.65.253 2.234.391a.74.74 0 0 1 .559.904.752.752 0 0 1-.903.556c-.962-.228-2.362-.491-3.559-.391-.594.047-1.076.153-1.417.397-.315.2-.54.487-.631.95-.071.366-.022.6.061.769.088.175.253.35.536.525.6.372 1.466.606 2.553.875l.072.019c.969.247 2.084.531 2.903 1.038.434.269.838.631 1.088 1.131.253.481.287 1.087.191 1.722-.216 1.106-1.019 1.828-2.016 2.206a5.644 5.644 0 0 1-1.672.344v1.519c0 .416-.334.75-.75.75s-.75-.334-.75-.75v-1.553l-.309-.037-.031-.003c-.75-.113-2.127-.431-2.964-.803a.75.75 0 1 1 .609-1.372c.663.294 1.889.587 2.595.691 1.112.156 2.062.072 2.737-.184.659-.253.991-.634 1.078-1.091.072-.394.022-.6-.062-.769-.088-.175-.25-.35-.534-.525-.6-.372-1.494-.606-2.553-.875l-.072-.019c-.969-.247-2.085-.531-2.902-1.038-.436-.269-.837-.631-1.087-1.131-.255-.509-.315-1.087-.192-1.722.171-.909.648-1.519 1.299-1.932.625-.397 1.374-.568 2.099-.627.097-.008.194-.014.291-.018V.751a.749.749 0 1 1 1.5 0v1.546z" />
  </Icon>
);

export default DollarSignRegularV6;
