import React from "react";
import PropTypes from "prop-types";

import FieldError from "../FieldError";
import FieldHelper from "../FieldHelper";
import FieldLabel from "../FieldLabel";

import styleSchema from "./TextInput.styles";

import { useGivenOrGeneratedId } from "../../hookHelpers";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

const TextInput = React.forwardRef(
  (
    {
      id: idFromProps,
      multiline,
      className,
      containerClassName,
      error,
      readOnly,
      helperText,
      label,
      disabled,
      isRequired,
      ...otherProps
    },
    ref,
  ) => {
    let schemaKey = readOnly ? "readonly" : "regular";
    if (error) {
      schemaKey = "error";
    }

    const id = useGivenOrGeneratedId("text-input", idFromProps);

    const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

    return (
      <div className={`tw-flex tw-flex-col ${containerClassName}`} data-cy="text-input-container">
        {label && (
          <FieldLabel
            className="tw-mb-8px"
            htmlFor={id}
            label={label}
            isDisabled={disabled}
            isRequired={isRequired}
          />
        )}
        {multiline ? (
          <textarea
            id={id}
            className={classes}
            readOnly={readOnly}
            data-cy="text-input"
            disabled={disabled}
            {...otherProps}
            ref={ref}
          />
        ) : (
          <input
            id={id}
            className={classes}
            readOnly={readOnly}
            data-cy="text-input"
            disabled={disabled}
            {...otherProps}
            ref={ref}
          />
        )}
        {helperText && <FieldHelper className="tw-mt-4px" helperText={helperText} disabled={disabled} />}
        {error && <FieldError className="tw-mt-4px" error={error} />}
      </div>
    );
  },
);

TextInput.defaultProps = {
  className: "",
  containerClassName: "",
  error: null,
  readOnly: false,
  multiline: false,
  disabled: false,
  isRequired: false,
  helperText: null,
  label: null,
  id: null,
};

TextInput.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.string,
  id: PropTypes.string,
};

export default TextInput;
