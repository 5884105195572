/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ListRegularV6 = (props) => (
  <Icon {...props} name="List Regular V6">
    <path d="M2.75 1.5c.416 0 .75.336.75.75v1.5c0 .416-.334.75-.75.75h-1.5a.749.749 0 0 1-.75-.75v-1.5a.75.75 0 0 1 .75-.75h1.5zm12.5.75a.749.749 0 1 1 0 1.5h-9.5a.749.749 0 1 1 0-1.5h9.5zm0 5c.416 0 .75.334.75.75s-.334.75-.75.75h-9.5C5.334 8.75 5 8.416 5 8s.334-.75.75-.75h9.5zm0 5c.416 0 .75.334.75.75s-.334.75-.75.75h-9.5c-.416 0-.75-.334-.75-.75s.334-.75.75-.75h9.5zM.5 7.25c0-.416.336-.75.75-.75h1.5c.416 0 .75.334.75.75v1.5c0 .416-.334.75-.75.75h-1.5a.749.749 0 0 1-.75-.75v-1.5zm2.25 4.25c.416 0 .75.334.75.75v1.5c0 .416-.334.75-.75.75h-1.5a.749.749 0 0 1-.75-.75v-1.5c0-.416.336-.75.75-.75h1.5z" />
  </Icon>
);

export default ListRegularV6;
