import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import styleSchema from "./Checkbox.styles";

const textColor = (disabled) => (disabled ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-75");

const getVerticalAlignment = (alignment) => {
  if (alignment === "start") return "tw-items-start";
  if (alignment === "end") return "tw-items-end";
  return "tw-items-center";
};

const Checkbox = ({
  id: idFromProps,
  label,
  labelClassName,
  checked,
  indeterminate,
  disabled,
  className,
  valign,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("checkbox", idFromProps);

  const checkboxInput = useRef(null);

  useEffect(() => {
    if (!checkboxInput.current) {
      return;
    }
    // Set the indeterminate value via JavaScript since it can't be set via HTML attribute
    // see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
    checkboxInput.current.indeterminate = indeterminate;
  }, [checkboxInput, indeterminate]);

  return (
    <div
      className={`tw-inline-flex tw-flex-row tw-justify-start ${getVerticalAlignment(valign)} tw-gap-[6px]`}
      data-cy="checkbox-container"
    >
      <input
        ref={checkboxInput}
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={`tw-align-middle ${styleSchema.regular} ${className}`}
        data-cy="checkbox"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      {label && (
        <label
          htmlFor={id}
          className={`tw-m-0 tw-select-none tw-text-14d ${textColor(disabled)} ${labelClassName}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  labelClassName: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  valign: PropTypes.oneOf(["start", "center", "end"]),
};

Checkbox.defaultProps = {
  id: null,
  label: "",
  labelClassName: "",
  checked: false,
  indeterminate: false,
  disabled: false,
  className: "",
  valign: "center",
};

export default Checkbox;
