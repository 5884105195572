import { bool, func, string } from "prop-types";
import React from "react";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  REFERRAL_FEE,
  ISREFERRALFEEPERCENTAGE,
  LISTING_UUID,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { putRefFeeAsThunk } from "../../../Header/actions/thunks";

import InlineEditingInput from "../../../../shared/v2/InlineEditingInput";
import FieldLabel from "../../../../shared/v2/FieldLabel/FieldLabel";
import { editIsReferralFeePercentage } from "../../../Header/actions/creators";

const ReferralFee = ({
  referralFee,
  onPutRefFeeAsThunk,
  onEditIsReferralFeePercentage,
  isReferralFeePercentage,
  uuid,
}) => {
  const onChangeHandlr = (refFee) =>
    onPutRefFeeAsThunk({
      referralFee: parseFloat(refFee.replace(",", "")),
      isFeePercentage: isReferralFeePercentage,
      uuid,
    });

  return (
    <div className="tw-relative tw-py-8px tw-h-[37px] tw-flex tw-items-center">
      <FieldLabel htmlFor="detailReferralInput" label="Referral Fee" className="tw-absolute" />
      <InlineEditingInput.FlatPercent
        className={`tw-absolute tw-right-0 ${referralFee ? "" : "!tw-mr-[21px]"}`}
        id="detailReferralInput"
        value={referralFee}
        prefix={isReferralFeePercentage ? "" : "$ "}
        suffix={isReferralFeePercentage ? " %" : ""}
        onChange={onChangeHandlr}
        flatOrPercent={isReferralFeePercentage ? "percent" : "flat"}
        onFlatSelected={() => onEditIsReferralFeePercentage(false)}
        onPercentSelected={() => onEditIsReferralFeePercentage(true)}
      />
    </div>
  );
};

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralFee,
  states: [REFERRAL_FEE, ISREFERRALFEEPERCENTAGE, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: [putRefFeeAsThunk, editIsReferralFeePercentage],
  aliases: ["onPutRefFeeAsThunk", "onEditIsReferralFeePercentage"],
});

ReferralFee.propTypes = {
  referralFee: string,
  isReferralFeePercentage: bool,
  onPutRefFeeAsThunk: func,
  onEditIsReferralFeePercentage: func,
  uuid: string,
};

ReferralFee.defaultProps = {
  referralFee: null,
  isReferralFeePercentage: false,
  onPutRefFeeAsThunk: () => {},
  onEditIsReferralFeePercentage: () => {},
  uuid: null,
};
