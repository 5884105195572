import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import PeopleResults from "./PeopleResults";
import { Search as SearchIcon, Trash } from "../../../shared/v2/Icomoon";
import TextComboInput from "../../../shared/v2/TextComboInput";
import Tag from "@shared/v2/Tag";
import { Row, Col } from "react-bootstrap";

const createTags = (tag) => ({
  id: tag.id || tag,
  text: tag.text || tag,
});

const adaptTags = (tags) => tags.map((tag) => createTags(tag));

const SearchInteraction = ({ tagsEmail, onSelectFunction, onHide }) => {
  const [isActive, setIsActive] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [tags, setTags] = useState(adaptTags(tagsEmail) || []);
  useEffect(() => {
    const getTags = adaptTags(tagsEmail);
    const haveSameIds =
      getTags.every((itemA) => tags.some((itemB) => itemA.id === itemB.id)) &&
      tags.every((itemB) => getTags.some((itemA) => itemB.id === itemA.id));
    if (!haveSameIds) {
      setTags(getTags);
    }
  }, [tagsEmail]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const hideClick = () => {
    setIsActive(false);
    setCursor(0);
    setSearchResults([]);
    setSearchTerm("");
    setSearchValid(false);
    setTags([]);
    onHide();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setIsActive(false);
      if (!tags.some((tag) => tag.id === searchResults[cursor].id)) {
        setTags([...tags, searchResults[cursor]]);
        setSearchTerm("");
      }
    }

    if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1);
    } else if (e.keyCode === 40 && cursor < searchResults.length - 1) {
      setCursor(cursor + 1);
    }
  };

  const handleHover = (index) => {
    setCursor(index);
  };

  const handleClick = (index, e) => {
    e.preventDefault();
    setCursor(index);
    setIsActive(false);
    if (!tags.some((tag) => tag.id === searchResults[index].id)) {
      setTags([...tags, searchResults[index]]);
      setSearchTerm("");
    }
  };

  const onRemove = (index) => {
    if (index >= 0 && index < tags.length) {
      const updatedTags = [...tags];
      updatedTags.splice(index, 1);
      setTags(updatedTags);
    }
  };

  useEffect(() => {
    const emailTagsVal = tags.map((item) => item.id);
    onSelectFunction(emailTagsVal);
  }, [tags]);

  useEffect(() => {
    if (searchTerm) {
      setIsActive(true);
      setSearchValid(true);
      let encodedTerm = searchTerm.replace("+", "%2B");
      axios
        .get("/interactions/interactable_emails", {
          params: {
            search: encodedTerm,
          },
        })
        .then((response) => {
          const data = response.data;

          setSearchResults(data);
          setSearchValid(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [searchTerm]);

  return (
    <div className="tw-pb-[4px] tw-pt-[8px]">
      <Row>
        <Col xs={11} className="tw-pr-0">
          <TextComboInput
            className="tw-w-full"
            inputClassName="tw-p-0"
            autoComplete="no"
            placeholder="Search Contacts"
            onChange={(e) => handleSearchChange(e.target.value)}
            onFocus={() => setIsActive(true)}
            onBlur={() => setIsActive(false)}
            onKeyDown={handleKeyDown}
            value={searchTerm}
            trailing={
              <div className="tw-flex tw-items-center">
                <SearchIcon className="tw-text-neutral-gray-30" size="l" />
              </div>
            }
          />
        </Col>
        <Col xs={1} className="tw-pl-[4px]">
          <button
            type="button"
            onClick={hideClick}
            className="tw-float-right tw-w-36px tw-h-36px tw-border-1px tw-rounded-[2px] tw-border-solid tw-border-neutral-gray-15 tw-bg-[#F5F7F7] 
        tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-bg-semantic-red-5 hover:tw-border-semantic-red-5 hover:tw-text-semantic-red-100
        active:tw-bg-semantic-red-100 active:tw-border-semantic-red-100 active:tw-text-white"
          >
            <Trash size="l" />
          </button>
        </Col>
      </Row>

      {isActive && (
        <PeopleResults
          searchResults={searchResults}
          onHover={handleHover}
          onClickHandle={handleClick}
          searchTerm={searchTerm}
          searchTermLength={searchTerm.length}
          searchValid={searchValid}
        />
      )}
      {tags.map((tag, index) => (
        <Tag
          key={tag.id}
          className="tw-mt-[4px] tw-mr-[4px]"
          removable={true}
          onRemove={() => onRemove(index)}
        >
          {tag.text}
        </Tag>
      ))}
    </div>
  );
};

PeopleResults.propTypes = {
  onHide: PropTypes.func,
  onSelectFunction: PropTypes.func,
  tagsEmail: PropTypes.arrayOf(PropTypes.string),
};

SearchInteraction.defaultProps = {
  onSelectFunction: null,
  tagsEmail: [],
  onHide: null,
};

export default SearchInteraction;
