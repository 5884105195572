/* eslint-disable no-constant-condition */
import { cloneDeep } from "lodash";

import {
  formatValue as originalFormatValue,
  fullRecalculation,
} from "../../../../Financials/components/shared/FinancialViewHelpers";
import { camelizeKeys, toNumber } from "../../../../shared/Utilities";
import { createDocument } from "../../Docs/api/apiAdapters";

import uiChangeMerger from "./uiChangeMerger";

// $/% is already handled by the front-end components, but the original
// `formatValue` function also adds those, so we strip them here.
const formatValue = (value, isPercentage = false) =>
  originalFormatValue(value, isPercentage)
    // Remove a "$" prefix or "%" suffix.
    .replace(/\$|%/, "");

/**
 * Converts objects from something like `{ "a": 1, "b": 2 }` into:
 *
 * ```
 * [
 *   { label: "a", value: 1 },
 *   { label: "b", value: 2 }
 * ]
 * ```
 *
 * For usage as options in the Dropdown component.
 */
const convertObjectIntoDropdownOptions = (object, { addCustomIncome = false } = {}) => {
  const options = Object.entries(object).map(([key, value]) => ({
    // Rename "Custom" option to "Custom Expense" so that it's in line with the Figma.
    label: key === "Custom" ? "Custom Expense" : key,
    value,
    meta: {
      type:
        key === "Admin Income Fee" ||
        key === "Buyer Fee Income" ||
        key === "Listing Fee Income" ||
        key === "Custom Income"
          ? "income"
          : "expense",
      isCustomOption: key === "Custom" || key === "Custom Income" || key === "Custom Expense",
      name: "",
    },
  }));
  if (addCustomIncome) {
    // As of now, there's no such thing as a custom income,
    // so we add an extra "Custom Income" option to represent this within the front-end.
    options.push({
      label: "Custom Income",
      value: "",
      meta: {
        type: "income",
        isCustomOption: true,
        name: "",
      },
    });
  }
  return options;
};

const convertObjectIntoDropdownOptionsCompany = (object, isExpense) => {
  const options = Object.entries(object).map(([key, value]) => ({
    // Rename "Custom" option to "Custom Expense" so that it's in line with the Figma.
    // eslint-disable-next-line no-nested-ternary
    label: key === "Custom" ? (isExpense ? "Custom Expense" : "Custom Income") : key,
    value,
    meta: {
      type: isExpense ? "expense" : "income",
      isCustomOption: key === "Custom" || key === "Custom Income" || key === "Custom Expense",
      name: "",
    },
  }));
  return options;
};

/**
 * Takes the given array and removes any
 * items where the `_destroy` field is truthy.
 */
const filterDeletedItems = (array) =>
  array
    // This name is due to a Rails pattern, so we ignore the linter here.
    // eslint-disable-next-line no-underscore-dangle
    .filter((x) => !x._destroy);

/**
 * Takes the given array and sorts it by the `position` field, then removes any
 * items where the `_destroy` field is truthy.
 */
const sortByPositionAndFilterDeletedItems = (array) =>
  filterDeletedItems(array.sort((a, b) => a.position - b.position));

/**
 * Takes in raw data (not camelized, as the case sensitivity on the keys is
 * important since they're used as names) and spits out available expense types
 * in a format compatible with the Dropdown component.
 */
const constructLineItemTypeOptionsFrom = (rawData) => ({
  agent: convertObjectIntoDropdownOptions(rawData.agent_expense_types, { addCustomIncome: true }),
  transactionExpense: convertObjectIntoDropdownOptions(rawData.transaction_expense_types, {
    addCustomIncome: false,
  }),
  transactionIncome: convertObjectIntoDropdownOptions(rawData.transaction_revenue_types, {
    addCustomIncome: true,
  }),
  company: { ...rawData.company_expense_types, ...rawData.company_revenue_types }
    ? [
        ...convertObjectIntoDropdownOptionsCompany(rawData.company_revenue_types, false),
        ...convertObjectIntoDropdownOptionsCompany(rawData.company_expense_types, true),
      ]
    : [],
});

/** Adapts a single transaction expense/income line item. */
const adaptTransactionLineItem = (lineItem, rawFinancialData) => {
  const expenseOrIncomeTypeId = lineItem.transactionExpenseTypeId || "";
  const expenseOrIncomeTypeOptions = constructLineItemTypeOptionsFrom(
    rawFinancialData,
  ).transactionExpense.concat(constructLineItemTypeOptionsFrom(rawFinancialData).transactionIncome);
  const selectedOption = expenseOrIncomeTypeOptions.find((option) => option.value === expenseOrIncomeTypeId);
  if (selectedOption.meta.isCustomOption) {
    selectedOption.meta.name = lineItem.name;
  }

  return {
    selectedOption,
    options: expenseOrIncomeTypeOptions,

    id: lineItem.id,
    position: lineItem.position,
    // BE uses "revenue" and "income" interchangeably, but we'll call everything
    // "income" here in the FE for consistency and simplicity's sake.
    expenseOrIncome: lineItem.type.replace("revenue", "income"),
    notes: lineItem.notes,
    value: formatValue(lineItem.value),
    valueType: lineItem.percentage ? "percent" : "flat",
    calculatedValue: formatValue(
      lineItem.type === "expense" ? lineItem.expenseAmount : lineItem.revenueAmount,
    ),
    subtotal: formatValue(lineItem.lineTotal),
  };
};

/**
 * Adapts a transaction's raw financial data from the back-end into the schema
 * expected by the front-end components.
 */
const adaptCDAdocs = (CDAdocuments) => {
  if (CDAdocuments.length) {
    return CDAdocuments.map((doc) => createDocument(doc, null, true));
  }
  return [];
};

const adaptTransaction = (originalRawFinancialData, transactionDetails) => {
  // The calculation code will mutate this object, so we need to make a writable
  // copy of it.
  const rawFinancialData = cloneDeep(originalRawFinancialData);

  // From here on out, we rename "rawFinancialData" to "financialData" to
  // differentiate between how the data comes in (snake_case, etc.) vs. the
  // camelCase, post-calculator version.
  let financialData;

  if (rawFinancialData.transaction_income) {
    // First order of business: create the `additionalIncome` field which doesn't
    // exist in the back-end, but is required to reuse the existing calculation
    // code. There's a bit of snake_case here because that's how the calculation
    // code expects the object's fields to be formatted, so we can't pass in a
    // camelCase version there.
    //
    // This is copy-pasted and adapted from `componentWillReceiveProps` from
    // `TransactionFinancial.jsx`.
    const additionalLineItems = sortByPositionAndFilterDeletedItems([
      ...rawFinancialData.transaction_income.custom_transaction_expenses_attributes,
      ...rawFinancialData.transaction_income.custom_transaction_revenues_attributes,
    ]);

    rawFinancialData.transaction_income.additionalIncome = additionalLineItems;

    // Then, re-use the existing calculation code.
    fullRecalculation(rawFinancialData, (updatedFinancialData) => {
      financialData = camelizeKeys(updatedFinancialData);
    });
  }

  // Finally, convert it into our desired schema.
  const transformedFinancialData = {
    // TODO: From what I could tell, the estimated close price exists both in
    // the listing's details _and_ the listing itself. The commission however,
    // is only present in the details. A little worse still is that
    // `estimated_commission_percentage` never seems to be handled in the FE,
    // even though it exists and is serialized properly.
    //
    // Given how unclear these are, I'm opting to just piggy-back off of the
    // existing data from another reducer for now so I can move quickly. We can
    // reconsider this decision later.
    estimated: {
      closePrice: {
        value: formatValue(transactionDetails.est_close_price || null),
      },
      commission: {
        type: transactionDetails.estimated_commission_percentage ? "percent" : "flat",
        value: formatValue(
          transactionDetails.estimated_commission || null,
          transactionDetails.estimated_commission_percentage,
        ),
      },

      subtotal: null,
      netCommission: null,
      grossCommission: null,
    },

    actual: {
      closePrice: {
        // TODO: Get this cleared up. This value technically exists as:
        // - transaction_income.closed_volume
        // - listing.details["close_price"].value
        // - listing.close_price
        //
        // AFAIK, no-one agrees on what the source of truth should be, so I'm
        // going with #1 since that's the one used in the classic Financials.
        value: formatValue(financialData?.transactionIncome?.closedVolume || null),
      },
      commission: {
        type: financialData?.transactionIncome?.commissionPercentage ? "percent" : "flat",
        value: formatValue(
          financialData?.transactionIncome?.commission || null,
          financialData?.transactionIncome?.commissionPercentage,
        ),
      },

      // Comes from the calculations, field doesn't exist in the back-end.
      subtotal: financialData?.transactionIncome?.grossIncome
        ? formatValue(financialData.transactionIncome.grossIncome)
        : null,

      // Seems to come from the back-end, and the field name is really weird.
      // Try to validate later.
      netCommission: financialData?.transactionIncome?.gci
        ? formatValue(financialData.transactionIncome.gci)
        : null,

      grossCommission: financialData?.transactionIncome?.grossCommission
        ? formatValue(financialData.transactionIncome.grossCommission)
        : null,
    },

    // Indicates whether the financial data for this transaction was created or not
    hasFinancialData: !!financialData,

    // If we have financial data (which is our case since we're running through
    // the function to adapt financial data), default to showing actual GCI.
    isActualGCI: true,

    notes: financialData?.transactionIncome?.commissionNotes || "",

    type: transactionDetails.type,
    showClosePrice: transactionDetails.type !== "referral",
  };

  if (financialData?.transactionIncome?.additionalIncome) {
    transformedFinancialData.lineItems = financialData.transactionIncome.additionalIncome.map((item) =>
      adaptTransactionLineItem(item, rawFinancialData),
    );
  } else {
    transformedFinancialData.lineItems = [];
  }

  return transformedFinancialData;
};

/** Given a company, adapts and spits out all his relevant line items. */
const adaptCompanyLineItems = (companyObject, rawFinancialData) => {
  const expensesOrIncome = sortByPositionAndFilterDeletedItems([
    ...(companyObject.customTeamMemberExpensesAttributes || []),
    ...(companyObject.customTeamMemberRevenuesAttributes || []),
  ]);

  const LineItems = expensesOrIncome.map((item) => {
    const expenseOrIncomeTypeOptions = constructLineItemTypeOptionsFrom(rawFinancialData).company;

    const expenseOrIncomeTypeId =
      (item.type === "expense" ? item.companyExpenseTypeId : item.companyRevenueTypeId) || "";
    const selectedOption = expenseOrIncomeTypeOptions.find(
      (option) => option.value === expenseOrIncomeTypeId,
    );
    if (selectedOption.meta.isCustomOption) {
      selectedOption.meta.name = item.name;
    }
    return {
      expenseOrIncome: item.type.replace("revenue", "income"),
      isRemovable: true,
      isEditable: true,
      id: item.id,
      name: item.name,
      position: item.position,
      selectedOption,
      options: expenseOrIncomeTypeOptions,
      notes: item.notes,
      valueType: item.percentage ? "percent" : "flat",
      value: formatValue(item.value),
      calculatedValue: formatValue(item.type === "expense" ? item.expenseAmount : item.revenueAmount),
      subtotal: formatValue(item.lineTotal),
    };
  });
  return sortByPositionAndFilterDeletedItems(LineItems);
};

const adaptCompany = (rawFinancialData) => {
  const financialData = camelizeKeys(rawFinancialData);
  let companyLineItems = null;
  if (financialData?.companyIncome) {
    companyLineItems = {
      id: financialData.companyIncome.id,
      name: financialData.companyIncome.name,
      type: "company",
      // TODO: This is Primary Agent / Account Owner / Showing Agent / Company, etc. Not
      // present in the current serializers ("TeamMember.role.name").
      role: financialData.companyIncome?.role || "",

      profilePic: financialData.companyIncome.avatar,
      gci: {
        type: financialData.companyIncome.agentGciPercentage ? "percent" : "flat",
        value: formatValue(
          financialData.companyIncome.agentGci,
          financialData.companyIncome.agentGciPercentage,
        ),
      },
      subtotal: formatValue(financialData.companyIncome.grossIncome),
      netIncome: formatValue(financialData.companyIncome.netIncome),
      lineItems: adaptCompanyLineItems(financialData.companyIncome, rawFinancialData),
    };
  }
  return companyLineItems;
};

//
// TODO: Temporary separator, consider breaking apart into different file. Stuff
// below is similar to the above, but for team members.
//

/** Given a team member, adapts and spits out all his relevant line items. */
const adaptTeamMemberLineItems = (teamMemberIncomeObject, rawFinancialData) => {
  // Within the team member's income object, we have the brokerage split and
  // royalty, which must _always_ be handled (e.g. repositioned) together, and
  // only after do we have the other expense/income items.

  // As such, let's start off with those 2.
  const brokerageSplitAndRoyaltyDefaults = {
    expenseOrIncome: "expense",
    isRemovable: false,
    isEditable: true,
  };

  const brokerageSplitItem = {
    ...brokerageSplitAndRoyaltyDefaults,
    selectedOption: { label: "Brokerage Split", value: "brokerage-split" },
    options: [],
    notes: teamMemberIncomeObject.brokerageSplitNotes,
    value: formatValue(
      teamMemberIncomeObject.brokerageSplit,
      teamMemberIncomeObject.brokerageSplitPercentage,
    ),
    valueType: teamMemberIncomeObject.brokerageSplitPercentage ? "percent" : "flat",
    calculatedValue: formatValue(teamMemberIncomeObject.brokerageSplitAmount),
    subtotal: formatValue(teamMemberIncomeObject.brokerageSplitLineTotal),
  };

  const royaltyItem = {
    ...brokerageSplitAndRoyaltyDefaults,
    selectedOption: { label: "Royalty", value: "royalty" },
    options: [],
    notes: teamMemberIncomeObject.royaltyNotes,
    value: formatValue(teamMemberIncomeObject.royalty, teamMemberIncomeObject.royaltyPercentage),
    valueType: teamMemberIncomeObject.royaltyPercentage ? "percent" : "flat",
    calculatedValue: formatValue(teamMemberIncomeObject.royaltyAmount),
    subtotal: formatValue(teamMemberIncomeObject.royaltyLineTotal),
  };

  // Now, we can handle the other incomes and expenses.
  const otherLineItems = sortByPositionAndFilterDeletedItems([
    ...(teamMemberIncomeObject.customTeamMemberExpensesAttributes || []),
    ...(teamMemberIncomeObject.customTeamMemberRevenuesAttributes || []),
  ]).map((item) => {
    const expenseOrIncomeTypeOptions = constructLineItemTypeOptionsFrom(rawFinancialData).agent;

    const expenseOrIncomeTypeId = item.agentExpenseTypeId || "";
    const selectedOption = expenseOrIncomeTypeOptions.find(
      (option) => option.value === expenseOrIncomeTypeId,
    );
    if (selectedOption.meta.isCustomOption) {
      selectedOption.meta.name = item.name;
    }

    const amount = item.expenseAmount || item.revenueAmount;
    const expenseOrIncome = item.expenseAmount ? "expense" : "income";
    return {
      expenseOrIncome,
      isRemovable: true,
      isEditable: true,

      id: item.id,
      position: item.position,

      selectedOption,
      options: expenseOrIncomeTypeOptions,

      notes: item.notes,
      value: formatValue(item.value, item.percentage),
      valueType: item.percentage ? "percent" : "flat",

      calculatedValue: formatValue(amount),
      subtotal: formatValue(item.lineTotal),
    };
  });

  return sortByPositionAndFilterDeletedItems([
    {
      position: teamMemberIncomeObject.position,
      brokerageSplitItem,
      royaltyItem,
    },
    ...otherLineItems,
  ]);
};

/** Given a collaborator, adapts and spits out all his relevant line items. */
const adaptCollaboratorLineItems = (collaboratorObject) => {
  const expenses = sortByPositionAndFilterDeletedItems(
    collaboratorObject.customCollaboratorExpensesAttributes,
  );

  // To explain some of the hardcoded values in here: as far as I could tell,
  // collaborator items are _always_ expenses, _always_ custom and _always_
  // flat.
  return expenses.map((expense) => ({
    expenseOrIncome: "expense",
    isRemovable: true,
    isEditable: true,

    id: expense.id,
    name: expense.name,
    position: expense.position,

    selectedOption: { label: expense.name, value: "custom" },
    options: [],

    notes: expense.notes,

    // Since the value is always flat, the calculated value can't possibly be
    // diferent from the base value, so both of them read from the same field.
    valueType: "flat",
    value: expense.expenseAmount,
    calculatedValue: expense.expenseAmount,

    subtotal: formatValue(expense.lineTotal),
  }));
};

/**
 * Given a transaction's raw financial data, spits out expenses and incomes for
 * the team members.
 */
const adaptTeamMembers = (rawFinancialData, transactionDetails) => {
  const financialData = camelizeKeys(rawFinancialData);

  const teamMemberLineItems = filterDeletedItems(financialData.teamMemberIncomes.incomes).map(
    (incomeObject) => ({
      id: incomeObject.id,
      personId: incomeObject.personId,
      name: incomeObject.name,
      type: "agent",

      profilePic: incomeObject.avatar,

      // TODO: This is Primary Agent / Account Owner / Showing Agent, etc. Not
      // present in the current serializers ("TeamMember.role.name").
      role: "",

      gci: {
        type: incomeObject.agentGciPercentage ? "percent" : "flat",
        value: formatValue(incomeObject.agentGci, incomeObject.agentGciPercentage),
      },

      unit: incomeObject.agentGciUnits,
      notes: incomeObject.agentGciNotes,

      subtotal: formatValue(incomeObject.grossIncome),
      netIncome: formatValue(incomeObject.netIncome),
      lineItems: adaptTeamMemberLineItems(incomeObject, rawFinancialData),

      showUnits: transactionDetails.type !== "referral",
    }),
  );

  return teamMemberLineItems;
};

// A small note on the functions above and below: it _seems_ like collaborators
// are a separate thing from team members (as in: collaborators always come
// after team members, they can't be reordered within themselves, etc.). As such
// I'm handling them with completely separate functions. If this assumption
// turns out to be wrong though, we should refactor to handle everything within
// a single array.

/**
 * Given a transaction's raw financial data, spits out expenses and incomes for
 * the collaborators.
 */
const adaptCollaborators = (rawFinancialData) => {
  const financialData = camelizeKeys(rawFinancialData);

  const collaboratorLineItems = filterDeletedItems(financialData.collaboratorExpenses.expenses).map(
    (expenseObject) => ({
      id: expenseObject.id,
      name: expenseObject.name,
      type: "collaborator",
      // TODO: Might want to have this one come in from the BE instead.
      role: "Collaborator",

      // TODO: For some reason this is shown as a revenue/income on the classic
      // page _and_ it comes in that way from the back-end as well...
      // considering the field is called total**Expense**, I'm doing a * -1 here
      // for sanity but confirm later what's going on here.
      netIncome: formatValue(expenseObject.totalExpense * -1),

      lineItems: adaptCollaboratorLineItems(expenseObject),

      showUnits: false,
    }),
  );

  return collaboratorLineItems;
};

const recalculateRawData = (rawData) => {
  let recalculatedRawData = rawData;

  // `transaction_income` is required to run the fullRecalculation algorithm
  if (!recalculatedRawData.transaction_income) {
    return recalculatedRawData;
  }

  const additionalLineItems = sortByPositionAndFilterDeletedItems([
    ...(recalculatedRawData.transaction_income.custom_transaction_expenses_attributes || []),
    ...(recalculatedRawData.transaction_income.custom_transaction_revenues_attributes || []),
  ]);
  recalculatedRawData.transaction_income.additionalIncome = additionalLineItems;

  fullRecalculation(recalculatedRawData, (newRecalculatedRawData) => {
    recalculatedRawData = newRecalculatedRawData;
  });

  return recalculatedRawData;
};

const removeUnusedPropsFromRawDataInPlace = (rawData) => {
  if (rawData.listing_id) {
    // eslint-disable-next-line no-param-reassign
    delete rawData.listing_id;
  }

  if (rawData.transaction_income?.additionalIncome) {
    // eslint-disable-next-line no-param-reassign
    delete rawData.transaction_income.additionalIncome;
  }

  if (rawData.for_referral) {
    // Referrals have no units to be distributed
    rawData.team_member_incomes.incomes.forEach((income) => {
      // eslint-disable-next-line no-param-reassign
      income.agent_gci_units = 0;
    });
  }
};

export const adaptFromApi = (rawData, transactionDetails) => {
  const base = cloneDeep(rawData);

  const transaction = adaptTransaction(base, transactionDetails);
  const company = adaptCompany(base);
  const teamMembers = adaptTeamMembers(base, transactionDetails);
  const collaborators = adaptCollaborators(base);
  const lineItemTypeOptions = constructLineItemTypeOptionsFrom(base);
  const CDAdocuments = adaptCDAdocs(base.financial_documents);

  removeUnusedPropsFromRawDataInPlace(base);

  return {
    rawData: base,
    adaptedData: { transaction, company, teamMembers, collaborators, CDAdocuments },
    lineItemTypeOptions,
  };
};

/**
 * Takes in UI update data and raw data,
 * and spits out raw data with the UI modifications.
 */
export const adaptFromUi = (updateType, updateData, rawData) => {
  const base = cloneDeep(rawData);

  const [didDataChange, newBase] = uiChangeMerger({ updateType, base, updateData });

  if (!didDataChange) {
    return null;
  }

  const recalculatedRawData = recalculateRawData(newBase);
  removeUnusedPropsFromRawDataInPlace(recalculatedRawData);
  return recalculatedRawData;
};

/**
 * Takes in raw data,
 * and spits out selectable team members in a format compatible with the Dropdown component.
 */
export const adaptSelectableTeamMembers = (rawData) => ({
  selectableTeamMembers: rawData.map((teamMember) => ({
    label: teamMember.name,
    value: teamMember.id,
    meta: {
      brokerageSplit: teamMember.brokerage_split,
      brokerageSplitType: teamMember.brokerage_split_percentage ? "percent" : "flat",
      royalty: teamMember.royalty,
      royaltyType: teamMember.royalty_percentage ? "percent" : "flat",
      customTeamMemberExpensesAttributes: teamMember.custom_team_member_expenses_attributes,
      customTeamMemberRevenuesAttributes: teamMember.custom_team_member_revenues_attributes,
    },
  })),
});

export const adaptClosePriceToApi = (closePrice) => ({ closed_volume: toNumber(closePrice).toString() });
