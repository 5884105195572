import React from "react";

import { Alert } from "../../shared/v1";

const Errors = ({ errors }) => (
  <div className="task-errors">
    {errors && (
      <Alert variant="danger">
        <dl className="tw-mb-0">
          {errors.map((error, index) => (
            <dd key={index}> {error} </dd>
          ))}
        </dl>
      </Alert>
    )}
  </div>
);

export default Errors;
