export const sort = (data, sortKey, sortDesc) => {
  const multiplier = sortDesc ? -1 : 1;
  return data.sort((a, b) => {
    const aVal = a[sortKey]
      ? ["last_login", "agent_last_login", "admin_last_login"].includes(sortKey)
        ? new Date(a[sortKey])
        : a[sortKey]
      : 0;
    const bVal = b[sortKey]
      ? ["last_login", "agent_last_login", "admin_last_login"].includes(sortKey)
        ? new Date(b[sortKey])
        : b[sortKey]
      : 0;
    return aVal > bVal ? multiplier : aVal < bVal ? -multiplier : 0;
  });
};

const sourceDefaults = {
  total_leads: 0,
  new: 0,
  unqualified: 0,
  watch: 0,
  nurture: 0,
  hot: 0,
  past: 0,
  inactive: 0,
  archived: 0,
  trash: 0,
};

const defaultBrivitySources = [
  {
    source: "Brivity CRM",
    ...sourceDefaults,
  },
  {
    source: "Brivity CMA",
    ...sourceDefaults,
  },
  {
    source: "Brivity Connect",
    ...sourceDefaults,
  },
  {
    source: "Brivity IDX",
    ...sourceDefaults,
  },
  {
    source: "Brivity Leads",
    ...sourceDefaults,
  },
  {
    source: "Brivity Valuations",
    ...sourceDefaults,
  },
  {
    source: "Kwkly",
    ...sourceDefaults,
  },
];

const initialState = {
  agents: [],
  callData: [],
  textData: [],
  appointmentData: [],
  dateRange: localStorage.getItem("date_range") || "last_seven_days",
  customRange: false,
  isAllLeadsInRange: false,
  isAllTasksInRange: false,
  isAllAppointmentsInRange: false,
  customDates: {},
  sortDesc: false,
  leadTotal: 0,
  activeFilters: [],
  emailTotal: 0,
  callTotal: 0,
  textTotal: 0,
  contactedTotal: 0,
  adminTasks: [],
  agentTasks: [],
  adminTotalTasks: 0,
  adminTotalCompletedTasks: 0,
  adminTotalOnTimeTasks: 0,
  agentTotalTasks: 0,
  agentTotalCompletedTasks: 0,
  agentTotalOnTimeTasks: 0,
  agentTotalLateTasks: 0,
  brivitySources: [],
  isActivityReportLoaded: false,
  isFetching: true,
};

const reportsReducer = (state = initialState, action) => {
  const defaultState = { ...initialState, ...state };
  switch (action.type) {
    case "CALL_REPORT":
      return {
        ...state,
        total_calls: action.total_calls,
        dateRange: action.dateRange.toLowerCase(),
        callData: sort(action.agents, state.sortKey, state.sortDesc),
      };

    case "TEXT_REPORT":
      return {
        ...state,
        total_texts: action.total_texts,
        dateRange: action.dateRange.toLowerCase(),
        textData: sort(action.agents, state.sortKey, state.sortDesc),
      };

    case "FETCH_REPORTS":
      return {
        ...state,
        agents: actions.agents,
      };

    case "APPOINTMENT_REPORT":
      return {
        ...state,
        total_calls: action.total_calls,
        dateRange: action.dateRange.toLowerCase(),
        appointmentData: sort(action.agents, state.sortKey, state.sortDesc),
      };

    case "SET_ACTIVE_FILTERS":
      const { selectedFilter, isChecked } = action;
      const currentFilters = state.activeFilters || [];
      const activeFilters = isChecked
        ? currentFilters.concat(selectedFilter)
        : currentFilters.filter((filter) => filter !== selectedFilter);

      return {
        ...state,
        activeFilters,
      };

    case "SORT_REPORT":
      const sortKey = action.sortKey;
      const sortDesc = state.sortKey === action.sortKey ? !state.sortDesc : false;
      const sorted = sort(state.agents, sortKey, sortDesc);
      const otherSources = state.otherSources ? sort(state.otherSources, sortKey, sortDesc) : [];
      return {
        ...state,
        dateRange: state.dateRange.toLowerCase(),
        sortKey,
        sortDesc,
        agents: sorted,
        callData: sort(state.callData, sortKey, sortDesc),
        textData: sort(state.textData, sortKey, sortDesc),
        brivitySources: sort(state.brivitySources, sortKey, sortDesc),
        otherSources: otherSources,
        appointmentData: sort(state.appointmentData, sortKey, sortDesc),
        adminTasks: sort(state.adminTasks.filter(Boolean), sortKey, sortDesc),
        agentTasks: sort(state.agentTasks.filter(Boolean), sortKey, sortDesc),
      };
    case "FILTER_REPORT":
      const { total_leads, total_emails, total_calls, total_texts, total_contacted } = action;
      return {
        ...state,
        total_leads,
        total_emails,
        total_calls,
        total_texts,
        total_contacted,
        isActivityReportLoaded: true,
        dateRange: action.dateRange.toLowerCase(),
        agents: sort(action.agents, state.sortKey, state.sortDesc),
      };

    case "FILTER_SOURCE_REPORT":
      return {
        ...state,
        dateRange: action.dateRange.toLowerCase(),
        brivitySources: sort(
          defaultBrivitySources.map((s) => action.brivitySources.find((a) => a.source == s.source) || s),
          state.sortKey,
          state.sortDesc,
        ),
        userId: action.userId,
        otherSources: sort(action.otherSources, state.sortKey, state.sortDesc),
        brivityTotalLeadSourceCount: action.brivityTotalLeadSourceCount,
        brivityStatusCounts: action.brivityStatusCounts,
        otherTotalLeadSourceCount: action.otherTotalLeadSourceCount,
        otherStatusCounts: action.otherStatusCounts,
      };

    case "FILTER_TASK_REPORT":
      const {
        adminTotalTasks,
        adminTotalCompletedTasks,
        adminTotalOnTimeTasks,
        adminTotalLateTasks,
        agentTotalTasks,
        agentTotalCompletedTasks,
        agentTotalOnTimeTasks,
        agentTotalLateTasks,
      } = action;

      return {
        ...state,
        dateRange: action.dateRange.toLowerCase(),
        adminTasks: sort(action.adminTasks.filter(Boolean), state.sortKey, state.sortDesc),
        agentTasks: sort(action.agentTasks.filter(Boolean), state.sortKey, state.sortDesc),
        adminTotalTasks,
        adminTotalCompletedTasks,
        adminTotalOnTimeTasks,
        adminTotalLateTasks,
        agentTotalTasks,
        agentTotalCompletedTasks,
        agentTotalOnTimeTasks,
        agentTotalLateTasks,
      };

    case "IS_CUSTOM_RANGE_ACTIVE":
      return {
        ...state,
        customRange: action.customRangeVisible,
        dateRange: action.dateRange || state.dateRange,
        customDates: action.customDates || {},
      };

    case "SET_CUSTOM_RANGE":
      return {
        ...state,
        customDates: action.range,
      };

    case "SET_LEADS_CONSTRAINT":
      return {
        ...state,
        isAllLeadsInRange: action.isAllLeadsInRange,
      };

    case "SET_TASKS_CONSTRAINT":
      return {
        ...state,
        isAllTasksInRange: action.isAllTasksInRange,
      };

    case "SET_APPOINTMENTS_CONSTRAINT":
      return {
        ...state,
        isAllAppointmentsInRange: action.isAllAppointmentsInRange,
      };

    case "IS_FETCHING":
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.error,
      };
    default:
      return defaultState;
  }
};
export default reportsReducer;
