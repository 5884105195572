import React from "react";
import PropTypes from "prop-types";

const FieldError = ({ error, className }) => (
  <div className={`tw-text-12d tw-text-semantic-red-100 ${className}`} data-cy="input-error-text">
    {error}
  </div>
);

FieldError.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FieldError.defaultProps = {
  className: "",
};

export default FieldError;
