import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DocumentPropType } from "../propTypes";

import Button from "../../../../shared/v2/Button";
import { errorForBanner } from "../../../../shared/v2/ErrorBanner";
import Modal from "../../../../shared/v2/Modal";
import { toggleDeleteModal } from "../actions/creators";
import { deleteDocumentsAsThunk } from "../actions/thunks";

const getDocumentName = (documentId, documents) =>
  documents.find((document) => document.id === documentId).fileName;

const ModalDeleteDocuments = ({ documents, isCDA, documentIdsToDelete, errors, isModalOpen, dispatch }) => {
  const [numDocumentIdsToDelete, setNumDocumentIdsToDelete] = useState(0);

  const handleHide = () => {
    dispatch(toggleDeleteModal([], false));
  };

  const handleDelete = () => {
    dispatch(deleteDocumentsAsThunk(documentIdsToDelete, isCDA));
  };

  useEffect(() => {
    if (documentIdsToDelete.length > 0) {
      setNumDocumentIdsToDelete(documentIdsToDelete.length);
    }
  }, [documentIdsToDelete]);

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 440px is defined in the Figma designs
      contentClassName="tw-w-[440px]"
      show={isModalOpen}
      onHide={handleHide}
      data-cy="documents-delete-modal"
    >
      <Modal.Header
        className="tw-mb-32px"
        title={
          numDocumentIdsToDelete > 1 ? `Delete ${numDocumentIdsToDelete} documents?` : "Delete this document?"
        }
        errors={errors.map(errorForBanner)}
        closeButton
      />

      {documentIdsToDelete.length === 1 && (
        <Modal.Body className="tw-mb-32px">
          <p>Are you sure you want to delete this document?</p>
          <p className="tw-font-semibold">{getDocumentName(documentIdsToDelete[0], documents)}</p>
          <p>Please note that this action cannot be undone.</p>
        </Modal.Body>
      )}

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={handleHide} data-cy="documents-delete-cancel-button">
          Cancel
        </Button>

        <Button
          size="medium"
          schema="warning"
          onClick={handleDelete}
          data-cy="documents-delete-submit-button"
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  documents: state.tdpDocumentsReducer.isCDA
    ? state.tdpDocumentsReducer.CDAdocuments
    : state.tdpDocumentsReducer.documents,
  isCDA: state.tdpDocumentsReducer.isCDA,
  documentIdsToDelete: state.tdpDocumentsReducer.documentIdsToDelete,
  errors: state.tdpDocumentsReducer.deleteErrors,
  isModalOpen: state.tdpDocumentsReducer.isDeleteModalOpen,
});

ModalDeleteDocuments.propTypes = {
  documents: PropTypes.arrayOf(DocumentPropType),
  documentIdsToDelete: PropTypes.arrayOf(PropTypes.number),
  errors: PropTypes.arrayOf(PropTypes.string),
  isModalOpen: PropTypes.bool,
  isCDA: PropTypes.bool,
  dispatch: PropTypes.func,
};

ModalDeleteDocuments.defaultProps = {
  documents: [],
  documentIdsToDelete: [],
  errors: [],
  isModalOpen: false,
  isCDA: false,
  dispatch: () => {},
};

export default connect(mapStateToProps)(ModalDeleteDocuments);
