/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const XmarkSolidV6 = (props) => (
  <Icon {...props} name="Xmark Solid V6">
    <path d="M12.706 11.294a.999.999 0 1 1-1.413 1.413L8 9.416l-3.293 3.291c-.195.197-.451.294-.707.294s-.512-.097-.707-.294a.999.999 0 0 1 0-1.414l3.294-3.294-3.294-3.292a.999.999 0 1 1 1.414-1.414L8 6.588l3.294-3.294a.999.999 0 1 1 1.414 1.414L9.414 8.002l3.292 3.292z" />
  </Icon>
);

export default XmarkSolidV6;
