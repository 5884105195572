import React from "react";
import PropTypes from "prop-types";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { Prompt } from "../../shared/v2/Modal";

function ActivateUserModal({ userId, show, onClose }) {
  const [loading, setLoading] = React.useState(false);

  const activateUserOnClick = () => {
    setLoading(true);
    caseTransformingAxios
      .patch(`/users/${userId}/activate`)
      .then((res) => {
        setLoading(false);
        onClose(res.data || true);
      })
      .catch((err) => onClose(undefined, err?.response?.data?.content || err?.response?.data?.message));
  };

  return (
    <Prompt
      description={
        <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-75">
          Activating a user restores them as an Active User in your CRM and fills a user seat.
        </div>
      }
      onCancel={onClose}
      primaryOption="Activate"
      onPrimary={() => activateUserOnClick()}
      isPrimaryLoading={loading}
      show={show}
      title="Activate User?"
      className="settings-users-activate-user-modal"
    />
  );
}

ActivateUserModal.propTypes = {
  userId: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActivateUserModal;
