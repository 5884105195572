import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import { BriefcaseSolidV6 } from "@shared/v2/Icomoon";
import Loading from "../../../Goals/components/Loader";
import Error from "../DashboardShared/Error";
import useRecruitingPipeline from "./services/useRecruitingPipeline";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import Dropdown from "../../../shared/v2/Dropdown";
import CustomControl from "../BusinessDashboard/Dropdown/CustomControl";
import { CustomBasicSingleValue } from "../BusinessDashboard/Dropdown/CustomSingleValue";
import CustomValueContainer from "../BusinessDashboard/Dropdown/CustomValueContainer";
import PipelineStat from "./PipelineStat";
import getDateDropdownOptions from "../DashboardShared/helpers/getDateDropdownOptions";
import CustomDropdownIndicator from "../BusinessDashboard/Dropdown/CustomDropdownIndicator";
import { useFeatureFlags } from "../../../reducers/layoutReducer/selectors";

const useRecruitingSubscription = () => {
  const [recruitingSubscription, setRecruitingSubscription] = useState(null);
  useEffect(() => {
    caseTransformingAxios
      .get("/api/v4/reporting/recruiter_subscription")
      .then(({ data }) => setRecruitingSubscription(data.active))
      .catch(() => false);
  }, []);
  return recruitingSubscription;
};

const RecruitingPipelineSection = ({ year, isBrivity }) => {
  const featureFlags = useFeatureFlags();
  const monthlyOverviewOptions = useMemo(() => getDateDropdownOptions(year, "extended"), [year]);
  const [rangeType, setRangeType] = useState(monthlyOverviewOptions[0]);
  const { actions, data, error, loading } = useRecruitingPipeline({ isBrivity, rangeType, year });
  const recruitingSubscription = useRecruitingSubscription();

  useEffect(() => setRangeType(monthlyOverviewOptions[0]), [monthlyOverviewOptions, year]);

  return (
    <Section className="tw-relative tw-gap-[24px]">
      <div className="tw-flex tw-justify-between">
        <SectionLabel>Recruiting {isBrivity ? "Funnel" : "Pipeline"}</SectionLabel>
        <Dropdown
          components={{
            Control: CustomControl,
            SingleValue: CustomBasicSingleValue,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator,
          }}
          value={rangeType}
          onChange={setRangeType}
          options={monthlyOverviewOptions}
        />
      </div>

      {isBrivity && featureFlags.recruiterLink && recruitingSubscription === false && (
        <Alert
          variant="brivity"
          text={
            <div className="tw-flex tw-justify-between tw-items-center tw-gap-[16px]">
              <BriefcaseSolidV6 size="l" />
              <div className="tw-mr-auto">
                <h4 className="tw-m-0 tw-text-14d tw-font-semibold">Need more recruits? Get Recruiter.ai</h4>
                Not enough recruits in your pipeline? You could earn an additional $122,000 - $167,000 in
                annual revenue by adding one agent to your team!
              </div>
              <Button
                schema="secondary"
                className="tw-whitespace-nowrap"
                onClick={() => window.open("/launch?slug=brivity-recruiter")}
              >
                Sign Up Now
              </Button>
            </div>
          }
        />
      )}

      <div className="tw-flex tw-justify-between tw-overflow-x-auto tw-overflow-y-hidden tw-mx-[-24px] bs-xs:tw-mx-[-15px]">
        <PipelineStat label="Calls" value={data?.calls} />
        <PipelineStat
          label="Contacts"
          labelTooltip="Call outcome of “talked” in Brivity for leads with the intent of “Recruit” (includes dials via Brivity and logged calls)"
          value={data?.contacted}
        />
        <PipelineStat
          completed={data?.meetAndGreet.held || 0}
          data={data?.meetAndGreet}
          label="Meet & Greet"
          labelTooltip="Initial conversation that is for the intent to recruit but partnership is NOT mentioned; just getting to know the person"
          value={data?.meetAndGreet.set}
        />
        <PipelineStat
          completed={data?.screening.held || 0}
          data={data?.screening}
          label="Screening"
          labelTooltip="Conducting screening questions to determine if you want to recruit the person"
          value={data?.screening.set}
        />
        <PipelineStat
          completed={data?.recruitingFollowUp.held || 0}
          data={data?.recruitingFollowUp}
          label="Follow Up"
          labelTooltip="Continued conversations that keep the recruit engaged with the team and the possibility of partnership"
          value={data?.recruitingFollowUp.set}
        />
        <PipelineStat
          completed={data?.recruitingAppointment.held || 0}
          data={data?.recruitingAppointment}
          label="Recruiting Appts."
          labelTooltip={
            isBrivity
              ? "Having a conversation that directly asks if the recruit would consider partnership"
              : "Executing the Career Opportunity Guide OR having a conversation that directly asks if the recruit would consider partnership"
          }
          value={data?.recruitingAppointment.set}
        />
        <PipelineStat
          completed={data?.signingAppointment.held || 0}
          data={data?.signingAppointment}
          label="Signing Appts."
          labelTooltip="Meeting to discuss expectations and present the offer to a recruit"
          value={data?.signingAppointment.set}
        />
        <PipelineStat label="Signed" value={data?.signed} />
      </div>
      <Error show={Boolean(error)} className="!tw-mb-0 tw-mt-[8px]" onClose={actions.clearError} />
      {loading && <Loading />}
    </Section>
  );
};

RecruitingPipelineSection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isBrivity: PropTypes.bool,
};

RecruitingPipelineSection.defaultProps = {
  isBrivity: false,
};

export default RecruitingPipelineSection;
