import React from "react";
import { useSelector } from "react-redux";

import Header from "./Header";
import Main from "./Main";
import SideLeft from "./SideLeft";
import SideRight from "./SideRight";
import TransactionsNavigation from "./TransactionsNavigation";
import ThumbnailSelectorModal from "./UI/Modals/ThumbnailSelector";
import TDPPipelineStatusModal from "./shared/TDPPipelineStatusModal";
import DocModalUpload from "./shared/DocModalUpload";
import CDAUploadModal from "./shared/CDAUploadModal";
import Preview from "./Main/Docs/Preview";
import SingleDialerModal from "../Dialer/SingleDialerModal";
import ImportModal from "./Main/Details/ImportModal";

const App = () => {
  const listingId = useSelector((state) => state.tdpDetailsReducer.headerDetails.listing_id);
  const listingUuid = useSelector((state) => state.tdpDetailsReducer.uuid);

  return (
    // The minimum width calculus is: 1440px (base min-width) - 2 * 24px (padding) - 56px (sidebar) = 1336px
    <>
      <Preview />
      <DocModalUpload />
      <CDAUploadModal />
      <div className="tw-h-full tw-overflow-auto" id="tdp-app">
        <div className="tw-min-w-[1336px] tw-p-24px">
          <TransactionsNavigation />
          <Header />
          <div className="tw-flex tw-flex-nowrap tw-gap-x-16px">
            <SideLeft />
            <Main />
            <SideRight />
          </div>
          <ThumbnailSelectorModal />
          <TDPPipelineStatusModal />
          <ImportModal />
        </div>
      </div>
      <SingleDialerModal listingId={listingId} listingUuid={listingUuid} />
    </>
  );
};

export default App;
