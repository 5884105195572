import React from "react";
import PropTypes from "prop-types";
import { toTitleCase } from "../../shared/Utilities";

const dayLabels = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const recursRemaininglabel = (amount) => {
  // eslint-disable-next-line eqeqeq
  if (amount == 0 || amount) {
    return `${amount} more times`;
  }
  return "Never ending";
};

const recurringTypelabel = (type, onDaysOfFrequency = null) => {
  if (type === "every") {
    return `Every ${onDaysOfFrequency} days`;
  }

  return toTitleCase(type);
};

const recurringOnlabel = (type, onDaysOfFrequency) => {
  if (!onDaysOfFrequency || onDaysOfFrequency.length < 1) {
    return "";
  }

  if (type === "weekly") {
    return `On ${onDaysOfFrequency.map((day) => dayLabels[day]).join(", ")}`;
  }
  if (type === "monthly") {
    return `On Month Days: ${onDaysOfFrequency.join(", ")}`;
  }
  return "";
};

export const RecurringDetailInnerFields = ({ recurringFrequency, recurringDaysOfFrequency, recurringLimit }) => (
  <>
    <div>{recurringTypelabel(recurringFrequency, recurringDaysOfFrequency)}</div>
    <div>{recurringOnlabel(recurringFrequency, recurringDaysOfFrequency)}</div>
    <div>{recursRemaininglabel(recurringLimit)}</div>
  </>
);

const RecurringDetail = ({ recurringFrequency, recurringDaysOfFrequency, recurringLimit }) => (
  <div>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>Recurring</label>
    <div>
      <RecurringDetailInnerFields
        recurringFrequency={recurringFrequency}
        recurringDaysOfFrequency={recurringDaysOfFrequency}
        recurringLimit={recurringLimit}
      />
    </div>
  </div>
);

export default RecurringDetail;

RecurringDetail.propTypes = {
  recurringFrequency: PropTypes.string.isRequired,
  recurringDaysOfFrequency: PropTypes.arrayOf(PropTypes.number).isRequired,
  recurringLimit: PropTypes.number.isRequired,
};

RecurringDetailInnerFields.propTypes = RecurringDetail.propTypes;
