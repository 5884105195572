import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  agentList: [],
  dateRange: "",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
});

export default dashboardSlice.reducer;
