import React from "react";
import PropTypes from "prop-types";

const SMALL_STYLES = `tw-text-[12px] tw-font-normal tw-text-neutral-gray-50`;

const EmailMessageInput = ({ onChange, value }) => (
  <div data-cy="email-message-wrapper" className="tw-mb-[48px]">
    <div className="tw-flex tw-items-center tw-mb-[8px]">
      <span className="tw-text-base tw-text-gray-50">Email Message</span>
      <small className={`${SMALL_STYLES} tw-px-[10px]`}>optional</small>
    </div>
    <textarea
      className="tw-h-[76px] tw-w-full tw-bg-tinted-gray-50 tw-border tw-border-neutral-gray-30 tw-rounded-md tw-px-[12px] tw-py-[8px]"
      value={value}
      onChange={onChange}
    />
    <p className={SMALL_STYLES}>Add a custom message to the first email for this report</p>
  </div>
);

EmailMessageInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

EmailMessageInput.defaultProps = {
  value: null,
  onChange: () => {},
};

export default EmailMessageInput;
