/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Spinner = (props) => (
  <Icon {...props} name="Spinner">
    <path d="M9.406 1.906a1.406 1.406 0 1 1-2.812 0 1.406 1.406 0 0 1 2.812 0zM8 12.688A1.406 1.406 0 1 0 8 15.5a1.406 1.406 0 0 0 0-2.812zm6.094-6.094a1.406 1.406 0 1 0 0 2.812 1.406 1.406 0 0 0 0-2.812zM3.313 8A1.406 1.406 0 1 0 .501 8a1.406 1.406 0 0 0 2.812 0zm.378 2.903a1.406 1.406 0 1 0 0 2.812 1.406 1.406 0 0 0 0-2.812zm8.618 0a1.406 1.406 0 1 0 0 2.812 1.406 1.406 0 0 0 0-2.812zM3.691 2.285a1.406 1.406 0 1 0 0 2.812 1.406 1.406 0 0 0 0-2.812z" />
  </Icon>
);

export default Spinner;
