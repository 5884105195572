import React from "react";
import { Row, Col } from "react-bootstrap";

const BrokerageExpenses = (
  { transactionResults: { team_member_incomes, meta: { brokerage_cap, for_brokerage_cap, cap_less_total, has_developer_role, is_over, royalty_cap, total } },
    resetTeamMemberIncome
  }
) => {
  const over_under_color = is_over ? "red" : "green";

  const can_reset = (brokerage_expense_amount) => brokerage_expense_amount && brokerage_expense_amount > 0 && has_developer_role

  return (
    <div style={{ fontSize: "16px" }}>
      <Row style={{ borderBottom: "1px solid #666", marginBottom: "10px" }}>
        <Col xs={6}>
          Listing
        </Col>
        <Col xs={6}>
          Expense Amount
        </Col>
      </Row>
      {
        team_member_incomes.length == 0 && <div>No results match that given user/slug</div>
      }
      {
        team_member_incomes.length > 0 && team_member_incomes.map(({ brokerage_expense_amount, id, listing_url, transaction_name, listing_id }) => (
          <Row key={id} style={{ marginBottom: "10px" }}>
            <Col xs={6}>
              <a href={listing_url} target="_blank">{transaction_name}</a>
            </Col>
            <Col xs={5}>{brokerage_expense_amount}</Col>
            {can_reset(brokerage_expense_amount) && <Col xs={1}><a style={{ cursor: "pointer" }} onClick={() => resetTeamMemberIncome(for_brokerage_cap, id, listing_id)}>Reset</a> </Col>}
          </Row>
        ))
      }
      <Row>
        <Col xs={3} xsOffset={6}>Total Accumulated For Cap Period</Col>
      </Row>
      <Row>
        <Col xs={2} xsOffset={6}>${total}</Col>
      </Row>
      <Row>
        <Col xs={2} xsOffset={6}>Agent's {for_brokerage_cap ? "Brokerage Cap" : "Royalty Cap"}</Col>
      </Row>
      <Row>
        <Col xs={2} xsOffset={6}>${for_brokerage_cap ? brokerage_cap : royalty_cap}</Col>
      </Row>
      <Row>
        <Col xs={2} xsOffset={6}>Agent is {is_over ? "over" : "under"} by <span style={{ color: over_under_color }}>${cap_less_total}</span></Col>
      </Row>
    </div>
  )
}

export default BrokerageExpenses;
