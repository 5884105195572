import { useState, useEffect, useLayoutEffect } from "react";

export const useStateWithProps = (initialValue) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  return [state, setState];
};

/**
 * @returns [number, number] view width, view height
 */
export const useViewSize = () => {
  const [viewSize, setViewSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const update = () => {
      setViewSize([window.innerWidth, window.innerHeight]);
    };

    update();
    window.addEventListener("resize", update);

    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  return viewSize;
};
