import React, { useEffect } from "react";
import PropTypes from "prop-types";
import errorShape from "../model/error";
import feedbackShape from "../model/feedback";
import formDataShape from "../model/formData";
import metaShape from "../model/meta";
import promptDataShape from "../model/promptData";
import DeleteFeedbackPrompt from "../DeleteFeedbackPrompt";
import FeedbackBody from "../FeedbackBody";
import FeedbackForm from "../FeedbackForm";
import FeedbackHeader from "../FeedbackHeader";
import FeedbackInfiniteScroll from "../FeedbackInfiniteScroll";

const Feedback = ({
  getAllFeedbacks,
  loadMoreFeedbacks,
  newFeedback,
  editFeedback,
  changeFeedbackVisibility,
  clearChangeFeedbackVisibilityError,
  deleteFeedback,
  showFeedbackForm,
  hideFeedbackForm,
  showDeleteFeedbackPrompt,
  hideDeleteFeedbackPrompt,
  uuid,
  feedbacks,
  meta,
  error,
  isLoading,
  formData,
  promptData,
}) => {
  const defaultDataForFormAndPrompt = {
    isVisible: true,
    error: null,
    isLoading: false,
  };

  const showNewFeedbackForm = () =>
    showFeedbackForm({ ...defaultDataForFormAndPrompt, action: "new", data: null });

  const showEditFeedbackForm = (feedback) =>
    showFeedbackForm({ ...defaultDataForFormAndPrompt, action: "edit", data: feedback });

  const showDeletePrompt = (feedback) =>
    showDeleteFeedbackPrompt({ ...defaultDataForFormAndPrompt, data: feedback });

  useEffect(() => {
    getAllFeedbacks();
  }, [uuid]);

  return (
    <>
      <FeedbackForm
        action={formData.action}
        show={formData.isVisible}
        feedback={formData.data}
        error={formData.error}
        isLoading={formData.isLoading}
        showDeleteFeedbackPrompt={showDeletePrompt}
        onNewFeedback={(feedback) => newFeedback(feedback)}
        onUpdateFeedback={(feedback) => editFeedback(feedback)}
        onCancel={() => hideFeedbackForm()}
      />
      {promptData.data && (
        <DeleteFeedbackPrompt
          show={promptData.isVisible}
          shownBy={promptData.data.shownBy}
          error={promptData.error}
          isLoading={formData.isLoading}
          onDeleteFeedback={() => deleteFeedback(promptData.data.id)}
          onCancel={() => hideDeleteFeedbackPrompt(formData)}
        />
      )}
      <FeedbackHeader
        reviewCount={meta?.pagination?.totalCount ?? 0}
        averageRating={meta?.averageRating ?? 0.0}
        showNewFeedbackForm={showNewFeedbackForm}
      />
      <FeedbackBody
        feedbacks={feedbacks}
        error={error}
        showEditFeedbackForm={showEditFeedbackForm}
        changeFeedbackVisibility={changeFeedbackVisibility}
        clearChangeFeedbackVisibilityError={clearChangeFeedbackVisibilityError}
        showDeleteFeedbackPrompt={showDeletePrompt}
      />
      {meta?.pagination && (
        <FeedbackInfiniteScroll
          pagination={meta.pagination}
          isLoading={isLoading}
          loadMoreFeedbacks={loadMoreFeedbacks}
        />
      )}
    </>
  );
};

Feedback.propTypes = {
  getAllFeedbacks: PropTypes.func.isRequired,
  loadMoreFeedbacks: PropTypes.func.isRequired,
  newFeedback: PropTypes.func.isRequired,
  editFeedback: PropTypes.func.isRequired,
  changeFeedbackVisibility: PropTypes.func.isRequired,
  clearChangeFeedbackVisibilityError: PropTypes.func.isRequired,
  deleteFeedback: PropTypes.func.isRequired,
  showFeedbackForm: PropTypes.func.isRequired,
  hideFeedbackForm: PropTypes.func.isRequired,
  showDeleteFeedbackPrompt: PropTypes.func.isRequired,
  hideDeleteFeedbackPrompt: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  feedbacks: PropTypes.arrayOf(feedbackShape),
  meta: metaShape,
  error: errorShape,
  isLoading: PropTypes.bool,
  formData: formDataShape,
  promptData: promptDataShape,
};

Feedback.defaultProps = {
  feedbacks: [],
  meta: {
    averageRating: 0.0,
    pagination: {
      totalPages: 0,
      totalCount: 0,
    },
  },
  error: null,
  isLoading: false,
  formData: {
    action: "new",
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
  promptData: {
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
};

export default Feedback;
