import React from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import { ChevronDownSolidV6 } from "../../../../shared/v2/Icomoon";

const CustomDropdownIndicator = ({ componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...componentProps} className="!tw-text-inherit">
    <ChevronDownSolidV6 size="s" />
  </components.DropdownIndicator>
);

CustomDropdownIndicator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
};

export default CustomDropdownIndicator;
