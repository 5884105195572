/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { func, shape, string, bool, number } from "prop-types";
import { removeCommas } from "@shared/v2/inputFormatUtils";
import DetailSectionHeader from "../DetailSectionHeader";
import DetailInput from "../DetailInput";
import { putDetailAsThunk } from "../actions/thunks";
import { formatExistingNumber } from "../api/helpers";
import EarnestMoneyDropdown from "../EarnestMoneyDropdown";
import EarnestMoneyDateDropdown from "../EarnestMoneyDateDropdown";

const EarnestMoney = ({ uuid, deposit, onPutDetailAsThunk, listingId }) => {
  const { id, amount, additional_amount, deposit_due, additional_earnest_money_due, hold_earnest_money } =
    deposit;

  const saveValueFor = (key) => (value) => {
    const newVal = {
      id: id || null,
      listing_id: listingId,
      [key]:
        key === "hold_earnest_money"
          ? value?.value
          : key === "additional_amount" || key === "amount"
            ? Number(removeCommas(value))
            : value,
    };

    onPutDetailAsThunk({
      uuid,
      key: "deposit_attributes",
      value: newVal,
      group: "listingDetails",
      stateKey: "deposit",
    });
  };

  return (
    <div className="tw-mb-24px">
      <DetailSectionHeader header="Earnest Money" />
      <EarnestMoneyDropdown
        hold_earnest_money={hold_earnest_money}
        onBlur={saveValueFor("hold_earnest_money")}
        containerClass="tw-grid tw-gap-3 tw-grid-cols-2 tw-relative tw-h-auto tw-mr-[15px]"
      />
      <DetailInput
        dataCy="deposit-amount"
        schema="currency"
        label="Deposit Amount"
        val={formatExistingNumber(amount)}
        onBlur={saveValueFor("amount")}
      />
      <EarnestMoneyDateDropdown
        date={deposit_due}
        label="Deposit Due"
        containerClass={`tw-grid tw-gap-3 tw-grid-cols-2 tw-relative tw-h-auto ${!deposit_due && "tw-mr-[15px]"}`}
      />
      <DetailInput
        dataCy="additional-deposit-amount"
        schema="currency"
        label="Additional Deposit Amount"
        val={formatExistingNumber(additional_amount)}
        onBlur={saveValueFor("additional_amount")}
      />
      <EarnestMoneyDateDropdown
        date={additional_earnest_money_due}
        label="Additional Earnest Money Due"
        containerClass={`tw-grid tw-gap-3 tw-grid-cols-2 tw-relative tw-h-auto ${!additional_earnest_money_due && "tw-mr-[15px]"}`}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  listingId: state.tdpDetailsReducer?.headerDetails?.listing_id,
  deposit: state.tdpDetailsReducer?.listingDetails?.deposit,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});

EarnestMoney.propTypes = {
  uuid: string,
  listingId: number,
  deposit: shape({
    id: number,
    hold_earnest_money: bool,
    amount: number,
    additional_amount: number,
    deposit_due: shape({
      id: string,
      date: string,
      name: string,
      include_time: bool,
      display_time: string,
    }),
    additional_earnest_money_due: shape({
      id: string,
      date: string,
      name: string,
      include_time: bool,
      display_time: string,
    }),
  }),
  onPutDetailAsThunk: func,
};

EarnestMoney.defaultProps = {
  uuid: "",
  listingId: null,
  deposit: null,
  onPutDetailAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(EarnestMoney);
