/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneArrowDownLeftSolidV6 = (props) => (
  <Icon {...props} name="Phone Arrow Down Left Solid V6">
    <path d="m15.409 10.975-3.391-1.453a.972.972 0 0 0-1.14.28l-1.377 1.682A10.923 10.923 0 0 1 4.517 6.5l1.682-1.378a.978.978 0 0 0 .281-1.139L5.024.592A1 1 0 0 0 3.877.026L.756.753a.971.971 0 0 0-.757.951c0 7.884 6.412 14.297 14.297 14.297.458 0 .85-.312.952-.757l.727-3.15a.98.98 0 0 0-.566-1.118zM10 7h3a1 1 0 0 0 0-2h-.586l3.294-3.294A.999.999 0 1 0 14.294.292L11 3.587V3a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1z" />
  </Icon>
);

export default PhoneArrowDownLeftSolidV6;
