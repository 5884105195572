import React, { useMemo } from "react";
import { Provider } from "react-redux";
import configureStore from "../store/configureStore";
import TargetSelector from "./components/TargetSelector";

export default (props) => {
  const store = useMemo(() => configureStore(props), []);
  return (
    <Provider store={store}>
      <TargetSelector />
    </Provider>
  );
};
