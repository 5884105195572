import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FieldLabel from "../../../../shared/v2/FieldLabel";
import FilterPill from "../../../../shared/v2/FilterPill";

const InlineFilter = ({ title, initialOptions, onOptionsChange }) => {
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => onOptionsChange(options), [options]);

  const toggleOption = (optionId) => {
    const updatedOptions = options.map((option) => {
      if (option.id !== optionId) return option;

      return {
        ...option,
        isActive: !option.isActive,
      };
    });

    setOptions(updatedOptions);
  };

  return (
    <div>
      <FieldLabel label={title} className="tw-mb-8px" />
      <div className="tw-flex tw-flex-row tw-gap-4px tw-flex-wrap tw-justify-start">
        {options.map((option) => (
          <FilterPill
            key={option.id}
            id={option.id}
            onClick={() => toggleOption(option.id)}
            selected={option.isActive}
            data-cy={option.id}
          >
            {option.name}
          </FilterPill>
        ))}
      </div>
    </div>
  );
};

const optionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
});

InlineFilter.propTypes = {
  title: PropTypes.string.isRequired,
  initialOptions: PropTypes.arrayOf(optionShape).isRequired,
  onOptionsChange: PropTypes.func.isRequired,
};

export default InlineFilter;
