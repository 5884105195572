import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as api from "./actions/api";
import Spinner from "../shared/v2/Icomoon/Icons/Spinner";
import BrandingCard from "./components/BrandingCard";
import OfficeCards from "./components/OfficeCards";
import ReviewCard from "./components/ReviewCard";
import SocialCard from "./components/SocialCard";
import TeamCard from "./components/TeamCard";

const View = ({ isLoading, loadBusinessSettings }) => {
  useEffect(() => {
    loadBusinessSettings();
  }, []);

  return isLoading ? (
    <div className="tw-pt-[90px] tw-text-center">
      <Spinner size="xxxl" className="fa-spin" />
    </div>
  ) : (
    <div className="tw-p-[16px] tw-bg-tinted-gray-50 tw-grid tw-grid-cols-2 tw-gap-[16px]">
      <TeamCard />
      <BrandingCard />
      <OfficeCards />
      <SocialCard />
      <ReviewCard />
      <input
        type="text"
        className="tw-h-[1px] tw-w-[1px] tw-absolute tw-top-[-100%] tw-left-[-100%]"
        id="businessCopyInput"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.businessSettings.isLoading,
});

View.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadBusinessSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, api)(View);
