import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import RadioButton from "@shared/v2/RadioButton";
import Modal from "@shared/v2/Modal";

import { getAvailableNumbers } from "../../PhoneSystem/TrackingNumbers/Settings/services";
import { saveManagedNumber } from "./services";
import Error from "../../Reports/components/DashboardShared/Error";
import { CountryCodes } from "../../PhoneSystem/TrackingNumbers/Settings/shared";
import Divider from "./Divider";

const AddBrivityPhone = ({ agent, onClose, onNumberSaved }) => {
  const [countryCode, setCountryCode] = useState(CountryCodes[0]);
  const [areaCode, setAreaCode] = useState("");
  const [numberOptions, setNumberOptions] = useState([]);
  const [chosenNumber, setChosenNumber] = useState({});
  const [error, setError] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!agent) {
      setCountryCode(CountryCodes[0]);
      setAreaCode("");
      setNumberOptions([]);
      setChosenNumber({});
      setError("");
      setIsFetching(false);
    }
  }, [agent]);

  const onAreaCodeChange = (e) => setAreaCode(e.target?.value);

  const getNumberOptions = async () => {
    setIsFetching(true);
    const { numbers, error: fetchError } = await getAvailableNumbers(agent.id, countryCode.value, areaCode);

    if (fetchError) {
      setError(fetchError);
    } else {
      setNumberOptions(numbers || []);
    }

    setIsFetching(false);
  };

  const saveNumber = async () => {
    setIsFetching(true);
    const { error: saveError } = await saveManagedNumber(agent.id, chosenNumber.value);

    if (saveError) {
      setIsFetching(false);
      setError(saveError);
    } else {
      onNumberSaved();
    }
  };

  return (
    <Modal
      show={Boolean(agent)}
      onHide={onClose}
      closeOnClickOutside
      contentSize="medium"
      contentClassName="tw-m-auto"
    >
      <Modal.Header title={`Add Brivity Number for ${agent?.fullName}`}>
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
        <Divider verticalMargin="tw-mt-32px" />
      </Modal.Header>
      <Modal.Body className="tw-px-8px">
        <div className="tw-my-24px">
          <span>Add a Brivity Number to call and text from Brivity. </span>
          <span className="tw-font-bold">
            Note: numbers cannot be deleted or reassigned to other team members, so choose wisely!
          </span>
        </div>
        <Divider verticalMargin="tw-mb-24px" />
        <div className="tw-flex tw-flex-row tw-gap-16px tw-mb-24px">
          <div className="tw-flex tw-flex-col">
            <span>Country Code</span>
            <Dropdown
              onChange={setCountryCode}
              className="tw-whitespace-nowrap tw-w-[260px]"
              value={countryCode}
              options={CountryCodes}
            />
          </div>
          <div className="tw-flex tw-flex-col">
            <span>Area Code</span>
            <input
              className="tw-w-[260px] tw-h-36px tw-text-14px tw-text-gray-99 tw-pl-12px tw-border-solid tw-border-1px tw-rounded-sm tw-border-gray-15 tw-bg-tinted-gray-50"
              placeholder="Enter an area code"
              onChange={onAreaCodeChange}
              value={areaCode}
            />
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-mb-24px tw-px-8px">
          <Button
            size="medium"
            schema="secondary"
            disabled={!isFetching && areaCode && countryCode?.value ? "" : "disabled"}
            onClick={getNumberOptions}
          >
            Search
          </Button>
        </div>
        {numberOptions.length > 0 && (
          <div className="tw-mb-[68px]">
            <Divider verticalMargin="tw-mb-24px" />
            <div className="tw-mb-24px">
              <span>Choose a Brivity number:</span>
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-y-8px tw-pl-16px">
              {numberOptions.map((number, i) => (
                <div className={`tw-flex tw-whitespace-nowrap ${i % 2 === 0 ? "" : "tw-ml-auto"}`}>
                  <RadioButton
                    checked={chosenNumber.value === number.value}
                    onChange={() => setChosenNumber(number)}
                  />
                  <span>{number.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Divider verticalMargin="tw-mb-24px" />
        <div className="tw-flex tw-justify-between tw-px-8px">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={!isFetching && chosenNumber.value ? "" : "disabled"}
            onClick={saveNumber}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AddBrivityPhone.propTypes = {
  agent: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onNumberSaved: PropTypes.func.isRequired,
};

AddBrivityPhone.defaultProps = {
  agent: null,
};

export default AddBrivityPhone;
