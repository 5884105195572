import { produce } from "immer";
import { map, remove } from "lodash";

import { saveAllNotes, addNote, saveErrors, updateNote, setEditNoteModal, deleteNote } from "../TransactionDetailsPage/Main/Notes/actions/creators";

export const defaultState = {
  notes: [],
  status: null,
  notableId: null,
  errors: [],
  isEditNoteModalOpen: false,
  editNote: null
};

export const tdpNotesReducer = (state = defaultState, payload) => {
  const updatedNotes = [...state.notes];

  switch (payload.type) {
    case saveAllNotes().type: {
      return produce(state, (draft) => {
        draft.notes = payload.notes
      });
    }
    case addNote().type: {
      return produce(state, (draft) => {
        updatedNotes.unshift(payload.note)
        draft.notes = updatedNotes
      });
    }
    case saveErrors().type: {
      return produce(state, (draft) => {
        draft.status = payload.status;
        draft.errors = payload.errors;
      });
    };
    case updateNote().type: {
      return produce(state, (draft) => {
        const { note: newNote } = payload
        const { id: newNoteId } = newNote;

        draft.notes = map(updatedNotes, (note) => note.id === newNoteId ? newNote : note);
        draft.isEditNoteModalOpen = false;
      });
    };
    case setEditNoteModal().type: {
      return produce(state, (draft) => {
        draft.isEditNoteModalOpen = payload.bool;
        draft.editNote = payload.bool ? payload.note : null
      });
    }
    case deleteNote().type: {
      return produce(state, (draft) => {
        remove(updatedNotes, ({id}) => id === payload.noteId);
        draft.notes = updatedNotes;
      });
    }
    default:
      return state;

  }
};

export default tdpNotesReducer;



