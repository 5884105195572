import React from "react";
import { Col, FormGroup, Label } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";
import TextInput from "../../shared/v2/TextInput";

const OPTIONS = [
  { value: "after", label: "After" },
  { value: "before", label: "Before" },
];

export default class ContingencyRuleSelection extends React.Component {
  render() {
    const { handleContingencyRuleChange, task, createTask, editTaskFor } = this.props;
    return (
      task.type !== "Appointment" && (
        <>
          <Col size={3} className="tw-mb-[16px]">
            <TextInput
              size="1"
              label="# of days"
              name="days"
              onChange={handleContingencyRuleChange}
              value={task.contingency_rule.days || ""}
            />
          </Col>
          <Col size={3} className="tw-mb-[16px]">
            <Dropdown
              size="1"
              label="After/before"
              name="position"
              options={OPTIONS}
              value={OPTIONS.find((opt) => opt.value === task.contingency_rule.position)}
              onChange={(option, handler) =>
                handleContingencyRuleChange({ target: { name: handler.name, value: option.value } })
              }
            />
          </Col>
        </>
      )
    );
  }
}
