import React from 'react';
import { Col } from 'react-bootstrap';
import alerts from '../listing_alerts/listing_alerts.module.css';
import form from '../form.module.css';

const CCEmail = (props) => {
  const { email, removeEmail } = props
  return (
    <div className={alerts.ccEmail}>
      <Col xs={11}>
        <input className={form.formControl} value={email} disabled={true} />
      </Col>
      <Col xs={1}>
        <i className="fa fa-remove" onClick={() => removeEmail(email)} />
      </Col>
    </div>
  );
};

export default CCEmail;
