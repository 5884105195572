import React from "react";

import MlsListingCell from "../MlsListingCell";
import "../listing-row.scss";

// Copied and trimmed down from MlsListingRow
const AgentListingRow = ({ listing }) => {
  const bottomBorder = "tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0";
  const rightBorder = "tw-border-solid tw-border-gray-10 tw-border-b tw-border-r tw-border-l-0 tw-border-t-0";

  return (
    <tr className={`tw-h-72px tw-text-gray-75`}>
      <td
        className={`tw-px-20px tw-whitespace-nowrap ${rightBorder}`}
      >
        <div className="tw-flex">
          <img src={listing.photos[0].url} height={30} width={50} className="tw-self-center tw-mr-12px" />
          <div className="tw-block tw-text-left">
            <p className="tw-text-gray-75 tw-m-0">{listing.streetAddress}</p>
            <p className="tw-text-gray-75 tw-m-0">{`${listing.city}, ${listing.state} ${listing.postalCode}`}</p>
          </div>
        </div>
      </td>
      <MlsListingCell>{listing.mlsNum}</MlsListingCell>
      <MlsListingCell>{listing.represented}</MlsListingCell>
      <td className={`tw-relative tw-px-20px tw-whitespace-nowrap ${listing.statusColor} ${bottomBorder}`}>
        {listing.displayStatus}
      </td>
      <MlsListingCell textAlign={"text-right"}>{listing.displayPrice}</MlsListingCell>
      <MlsListingCell>{listing.formattedListDate}</MlsListingCell>
      <MlsListingCell textAlign={"text-right"}>{listing.displaySoldPrice}</MlsListingCell>
      <MlsListingCell>{listing.displaySoldDate}</MlsListingCell>
      <MlsListingCell>{listing.city}</MlsListingCell>
      <MlsListingCell>{listing.neighborhood}</MlsListingCell>
      <MlsListingCell>{listing.bedrooms}</MlsListingCell>
      <MlsListingCell>{listing.bathsTotalDecimal || "—"}</MlsListingCell>
      <MlsListingCell textAlign={"text-right"}>{listing.displaySquareFeet}</MlsListingCell>
      <MlsListingCell textAlign={"text-right"}>{listing.displayPricePerSquareFoot}</MlsListingCell>
      <MlsListingCell>{listing.lotSize}</MlsListingCell>
      <MlsListingCell>{listing.year}</MlsListingCell>
      <MlsListingCell>{listing.propertyType}</MlsListingCell>
      <MlsListingCell textAlign={"text-right"}>{listing.displayHoaFee}</MlsListingCell>
    </tr>
  );
};

export default AgentListingRow;
