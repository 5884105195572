/* eslint-disable import/prefer-default-export */
import { setThumnail, setSaved, setSaving, setSaveError } from "./creators";
import { saveListingThumbnail, saveListingMLSThumbnail } from "../services";

export const saveForm =
  ({ listingId, selectedPhoto, uuid }) =>
  (dispatch) => {
    dispatch(setSaving());
    const onSuccess = () => {
      dispatch(
        setThumnail("photo", { ...selectedPhoto, cloud_location: selectedPhoto.url }, "headerDetails"),
      );
      dispatch(setSaved());
    };
    const onFailure = (error) => dispatch(setSaveError({ error }));
    const model = { "listing_image[position]": 1 };

    if (selectedPhoto.isMLS) {
      const data = { primary_mls_photo: selectedPhoto.url };
      saveListingMLSThumbnail({ uuid, data }).then(onSuccess).catch(onFailure);
    } else {
      const data = { primary_mls_photo: null };
      saveListingMLSThumbnail({ uuid, data });
      saveListingThumbnail({ listingId, id: selectedPhoto.id, model }).then(onSuccess).catch(onFailure);
    }
  };
