import React, { useState, useEffect, useMemo } from "react";

import TextInput from "@shared/v2/TextInput";
import { Search as SearchIcon } from "@shared/v2/Icomoon";
import ListingsAutocompleteResults from "@shared/ListingsAutocomplete/ListingsAutocompleteResults";
import { usePlacesAutocomplete } from "./usePlacesAutocomplete";

const Search = ({ selectSavedSearch, setLocation, searchBarRef, savedSearches, deleteSearch }) => {
  const mappedSearches = useMemo(
    () => savedSearches.map((s) => ({ ...s, value: s.name, category: "Saved Searches" })),
    [savedSearches],
  );

  const [term, setTerm] = useState("");
  const [selected, setSelected] = useState(null);
  const [results, setResults] = useState(mappedSearches);
  const [isSearchSelected, setIsSearchSelected] = useState(false);
  const [childPosition, setChildPosition] = useState(0);
  const { autocompleteResults, selectedLocation } = usePlacesAutocomplete(term, selected);

  const onSelectAutocompleteResult = (location) => {
    // Clean up results and tracking for them. Only show saved searches in the future
    setChildPosition(0);
    setTerm("");
    setResults(mappedSearches);

    if (location.category === "Saved Searches") {
      selectSavedSearch(location);
    } else {
      setSelected(location);
    }
  };

  const handleKeypress = (event) => {
    if (event.defaultPrevented) return;

    switch (event.key) {
      case "ArrowUp":
        setChildPosition(childPosition > 0 ? childPosition - 1 : 0);
        break;
      case "ArrowDown":
        setChildPosition(results.length - 1 > childPosition ? childPosition + 1 : childPosition);
        break;
      case "Enter":
        onSelectAutocompleteResult(results[childPosition]);
        break;
      default:
        return;
    }

    event.preventDefault();
  };

  useEffect(() => {
    if (selectedLocation) {
      setLocation(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    setResults([
      ...mappedSearches.filter((ms) => matchByTermIgnoreCase(ms.value, term)),
      ...autocompleteResults,
    ]);
  }, [mappedSearches, autocompleteResults, term]);

  return (
    <div className="tw-relative">
      <div className="tw-flex tw-flex-row">
        <TextInput
          ref={searchBarRef}
          autoComplete="off"
          containerClassName="tw-w-full"
          placeholder="Search by address, city, neighborhood, county, or ZIP code"
          onChange={(e) => setTerm(e.target?.value || "")}
          value={term}
          onBlur={() => setIsSearchSelected(false)}
          onFocus={() => setIsSearchSelected(true)}
          onKeyDown={handleKeypress}
        />
        <SearchIcon className="tw-absolute tw-right-[10px] tw-top-[calc(50%-8px)] tw-text-gray-30" size="l" />
      </div>
      {isSearchSelected && (
        <ListingsAutocompleteResults
          searchResults={results}
          selectResult={onSelectAutocompleteResult}
          childPosition={childPosition}
          setChildPosition={setChildPosition}
          deleteSearch={deleteSearch}
        />
      )}
    </div>
  );
};

export default Search;

const matchByTermIgnoreCase = (searchIn, target) =>
  searchIn.match(new RegExp(target || "", "gi"))?.length > 0;
