import { useState } from "react";

const useModalHandlr = ({ toggleDrawer, onCreateTasksByUnassignedFilter }) => {
  const [modalTeamMemberActive, setModalTeamMemberActive] = useState(false);
  const [modalAutoPlansActive, setModalAutoPlansActive] = useState(false);

  const secondaryFilterHandlr = (filterTxt) => {
    toggleDrawer((prev) => !prev);
    if (filterTxt === "Unassigned") onCreateTasksByUnassignedFilter();
    if (filterTxt === "Team Members") setModalTeamMemberActive(true);
    if (filterTxt === "Auto Plans") setModalAutoPlansActive(true);
  };

  return {
    modalTeamMemberActive,
    setModalTeamMemberActive,
    modalAutoPlansActive,
    setModalAutoPlansActive,
    secondaryFilterHandlr,
  };
};

export default useModalHandlr;
