import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useInitPathName = () => {
  const [initPathName, setInitPathName] = useState("");
  const loc = useLocation();

  useEffect(() => {
    const urlSegments = loc.pathname.split("/");
    setInitPathName(`/${urlSegments[1]}/${urlSegments[2]}`);
  }, []);

  return { initPathName };
};

export const usePagePathName = () => {
  const [pagePathName, setPagePathName] = useState("");
  const loc = useLocation();

  useEffect(() => {
    const urlSegments = loc.pathname.split("/");
    setPagePathName(urlSegments[3] || "home");
  }, [loc]);

  return { pagePathName };
};

export default useInitPathName;
