import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "@shared/v2/Dropdown";
import { Row, Col } from "@shared/v1";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import { capitalize } from "lodash";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";
import { CLEAR_MODAL_FILTER_ID } from "../shared/constants";

const IncludeTagFilter = ({ anyOrAll, options, selectedOptions }) => {
  const [prefix, setPrefix] = useState({ label: "Any", value: "any" });
  const hashOptions = options.reduce((acc, { name, id }) => {
    acc[id] = name;
    return acc;
  }, {});

  useEffect(() => {
    if (anyOrAll) setPrefix({ label: capitalize(anyOrAll), value: anyOrAll });
  }, [anyOrAll]);

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => setPrefix({ label: "Any", value: "any" }));

  return (
    <Row>
      <Col size={3} className="!tw-absolute tw-z-[1]">
        <Dropdown
          options={[
            { label: "Any", value: "any" },
            { label: "All", value: "all" },
          ]}
          value={prefix}
          onChange={setPrefix}
        />
        <input name="leads_filter[selected_any_all_tags][]" type="hidden" value={prefix.value} />
      </Col>
      <Col size={9}>
        <DropdownWithMultiCtrlledRemovable
          dropdownClassName="tw-left-[103px]"
          options={options.map(({ name, id }) => ({ label: name, value: `${id}` }))}
          selectedOptions={selectedOptions?.map((id) => ({ label: hashOptions[id], value: `${id}` }))}
          removablePrefix={`Tag (${prefix.label}): `}
          placeHolderTxt=" tags selected"
          ctrlName="leads_filter[selected_tag_filters][]"
        />
      </Col>
    </Row>
  );
};

export default IncludeTagFilter;

IncludeTagFilter.propTypes = {
  anyOrAll: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

IncludeTagFilter.defaultProps = {
  anyOrAll: "any",
};
