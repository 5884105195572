import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: ["tw-text-neutral-gray-30"],
    hover: ["hover:tw-text-neutral-gray-50"],
    active: ["active:tw-text-neutral-gray-75"],
  },
  // FontAwesome also provides styles that conflicts with the sized based styles
  // Adding `!important` here to be able to override it
  medium: {
    default: ["!tw-w-[12px]", "!tw-h-[12px]", "!tw-text-12d"],
  },
  large: {
    default: ["!tw-w-[16px]", "!tw-h-[16px]", "!tw-text-16d"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
