import React from 'react';
import AgentTextsRow from "./AgentTextsRow";
import {ReportHeaderSortContainer, TaskTotalsRow} from "./index";
import AgentTextsHeaders from "./AgentTextsHeaders";
import AgentTextsTotalRow from "./AgentTextsTotalRow";

const AgentTextsHolder = (props) => {
  const agentTextData = props.agentTextData.textData;
  return(
    <div>
      {
        agentTextData.map((a) =>
        <AgentTextsRow
          key={a.id}
          agent={a} />
      )
    }

    {
      agentTextData.length > 1 &&
        <AgentTextsTotalRow
          reportData={agentTextData} />
    }
  </div>
)
}
export default AgentTextsHolder;
