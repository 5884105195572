import React, { useState } from "react";
import { useSelector } from "react-redux";

import IconButton from "@shared/v2/IconButton";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import colors from "../../Reports/components/DashboardShared/helpers/colors";
import AddTransactionModal from "../../Transactions/AddTransactionModal";
import NewTask from "../../Tasks/components/NewTask";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";

export const NewContactModal = () => {
  const currentUser = useCurrentUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <IconButton
        schema="transparent"
        className={`${colors.theme === "place" ? "tw-text-white" : ""} hover:tw-bg-gray/10`}
        onClick={() => setIsModalOpen(true)}
        data-cy="add_contact_button"
      >
        <PlusSolidV6 />
      </IconButton>

      <PersonDetailsModal
        show={isModalOpen}
        person={{
          isLead: true,
          primaryAgentId: currentUser.id,
          leadType: "buyer",
          typeName: "lead",
          status: "new",
        }}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export const NewTaskModal = () => {
  const currentUser = useCurrentUser();
  const accountMilestoneNames = useSelector(({ layout }) => layout.sidebar.accountMilestoneNames);
  return (
    <NewTask
      account_uuid={currentUser.account.uuid}
      accountMilestoneNames={accountMilestoneNames}
      user={currentUser}
    />
  );
};

export const NewTransactionModal = () => {
  const newMilestoneId = useSelector(({ layout }) => layout.sidebar.newMilestoneId);
  return <AddTransactionModal newMilestoneId={newMilestoneId} />;
};

const ACTION_MAP = {
  Leads: NewContactModal,
  People: NewContactModal,
  Tasks: NewTaskModal,
  Transactions: NewTransactionModal,
};

export default ACTION_MAP;
