import React, { useState } from "react";
import { bool, func } from "prop-types";
import CaretDown from "../../../../shared/v2/Icomoon/Icons/CaretDown";
import VisibilityHidden from "../../../../shared/v2/Icomoon/Icons/VisibilityHidden";
import VisibilityVisible from "../../../../shared/v2/Icomoon/Icons/VisibilityVisible";
import useOnClickOutsideRef from "../api/hooks";

const FilterNotes = ({
  setShowAllNotes,
  showAllNotes,
  setShowExternallyVisibleNotes,
  showExternallyVisibleNotes,
  setNotExternallyVisibleNotes,
  notExternallyVisibleNotes,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  const outsideClick = useOnClickOutsideRef(() => setShowFilter(false));

  const switchFilter = (filter) => {
    switch (filter) {
      case "showAllNotes":
        setShowAllNotes(true);
        setShowExternallyVisibleNotes(false);
        setNotExternallyVisibleNotes(false);
        setShowFilter(false);
        break;
      case "showExternallyVisibleNotes":
        setShowAllNotes(false);
        setShowExternallyVisibleNotes(true);
        setNotExternallyVisibleNotes(false);
        setShowFilter(false);
        break;
      case "notExternallyVisibleNotes":
        setShowAllNotes(false);
        setShowExternallyVisibleNotes(false);
        setNotExternallyVisibleNotes(true);
        setShowFilter(false);
        break;
      default:
        setShowAllNotes(true);
        setShowExternallyVisibleNotes(false);
        setNotExternallyVisibleNotes(false);
        setShowFilter(false);
    }
  };

  return (
    <div className="tw-relative tw-flex tw-justify-end tw-mb-[16px]">
      <div className="tw-absolute" ref={outsideClick}>
        <button
          type="button"
          className="tw-border-none tw-bg-transparent tw-not-italic tw-font-normal tw-text-[14px] tw-leading-5 tw-float-right"
          onClick={() => setShowFilter(!showFilter)}
        >
          <div className="tw-text-gray-75 tw-flex">
            {showAllNotes && (
              <div className="tw-ml-[12px] w-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-gap-[12px] tw-items-center tw-h-[32px] tw-text-neutral-gray-75 tw-mr-[8px]">
                All Notes
              </div>
            )}
            {showExternallyVisibleNotes && (
              <div className="tw-ml-[12px] w-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-gap-[12px] tw-items-center tw-h-[32px] tw-text-neutral-gray-75 tw-mr-[8px]">
                <VisibilityVisible /> Visible to Viewers
              </div>
            )}
            {notExternallyVisibleNotes && (
              <div className="tw-ml-[12px] w-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-gap-[12px] tw-items-center tw-h-[32px] tw-text-neutral-gray-75 tw-mr-[8px]">
                <VisibilityHidden />
                Not Visible to Viewers
              </div>
            )}
            <div className="tw-mt-[12px]">
              <CaretDown />
            </div>
          </div>
        </button>
        {showFilter && (
          <div className="tw-shadow-modal  tw-w-[200px] tw-bg-white tw-border-neutral-gray-75">
            <button
              type="button"
              className={`tw-w-[200px] tw-border-none tw-bg-transparent hover:tw-bg-tinted-gray-100 ${
                showAllNotes ? "tw-bg-tinted-gray-100" : "tw-bg-transparent"
              }`}
              onClick={() => switchFilter("showAllNotes")}
            >
              <div className="tw-ml-[12px] tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-items-center tw-h-[32px] tw-text-neutral-gray-75">
                All Notes
              </div>
            </button>
            <button
              type="button"
              className={`tw-w-[200px] tw-border-none hover:tw-bg-tinted-gray-100 ${
                showExternallyVisibleNotes ? "tw-bg-tinted-gray-100" : "tw-bg-transparent"
              }`}
              onClick={() => switchFilter("showExternallyVisibleNotes")}
            >
              <div className="tw-ml-[12px] w-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-gap-[12px] tw-items-center tw-h-[32px] tw-text-neutral-gray-75">
                <VisibilityVisible /> Visible to Viewers
              </div>
            </button>
            <button
              type="button"
              className={`tw-w-[200px] tw-border-none hover:tw-bg-tinted-gray-100 ${
                notExternallyVisibleNotes ? "tw-bg-tinted-gray-100" : "tw-bg-transparent"
              }`}
              onClick={() => switchFilter("notExternallyVisibleNotes")}
            >
              <div className="tw-ml-[12px] w-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal tw-flex tw-gap-[12px] tw-items-center tw-h-[32px] tw-text-neutral-gray-75">
                <VisibilityHidden />
                Not Visible to Viewers
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

FilterNotes.propTypes = {
  setShowAllNotes: func.isRequired,
  showAllNotes: bool.isRequired,
  setShowExternallyVisibleNotes: func.isRequired,
  showExternallyVisibleNotes: bool.isRequired,
  setNotExternallyVisibleNotes: func.isRequired,
  notExternallyVisibleNotes: bool.isRequired,
};

export default FilterNotes;
