import React from "react";
import { func } from "prop-types";

import { PAYLOAD_DEFAULT, payloadPropTypes } from "../propTypes";

import ModalAddEditTaskDate from "../ModalAddEditTaskDate";
import ModalAddEditContingentElements from "../ModalAddEditContingentElements";
import ModalAddEditRecurringElements from "../ModalAddEditRecurringElements";

const ModalAddEditTaskFooter = ({ payload, setPayload }) => (
  <>
    <ModalAddEditTaskDate payloadHandlrs={setPayload} show={!payload.contingent} value={payload.date} />
    <ModalAddEditContingentElements payloadHandlrs={setPayload} payload={payload} />
    <ModalAddEditRecurringElements payloadHandlrs={setPayload} payload={payload} />
  </>
);

ModalAddEditTaskFooter.propTypes = {
  payload: payloadPropTypes,
  setPayload: func,
};

ModalAddEditTaskFooter.defaultProps = {
  payload: PAYLOAD_DEFAULT,
  setPayload: null,
};

export default ModalAddEditTaskFooter;
