import { useEffect, useRef } from "react";
import { getAgentMLSOptions } from "../../services";

export const useOnSelectedChange = (selected, hasError, fn) => {
  const isFirstRun = useRef(true);
  return useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!hasError) {
      fn(selected);
    }
  }, [selected]);
};

export const useSelectedTypesError = ({ selectedTypes, setFormError }) =>
  useEffect(() => {
    setFormError({ property_type: Object.keys(selectedTypes).length === 0 });
  }, [selectedTypes]);

export const useSetPropertyTypes = ({ primaryAgentID, setMLSPropertyTypes }) =>
  useEffect(() => {
    if (!primaryAgentID) return;
    getAgentMLSOptions({
      data: { primary_agent_uuid: primaryAgentID },
      successCb: (response) => {
        if (Array.isArray(response?.data)) {
          setMLSPropertyTypes(response.data);
        }
      },
      errorCb: () => {},
    });
  }, [primaryAgentID]);
