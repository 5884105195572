import React from "react";
import PropTypes from "prop-types";

import {
  ApartmentSolidV6,
  HouseChimneySolidV6,
  HouseUserSolidV6,
  KeySolidV6,
  ShareNodesSolidV6,
} from "../../../shared/v2/Icomoon";

const thumbnailIconsByType = {
  buyer: KeySolidV6,
  seller: HouseChimneySolidV6,
  tenant: HouseUserSolidV6,
  landlord: ApartmentSolidV6,
  referral: ShareNodesSolidV6,
};

const containerSizeDictionary = {
  small: "tw-w-48px tw-h-32px",
  full: "tw-w-full tw-h-full",
};

const containerSizeClasses = (size) => containerSizeDictionary[size] || containerSizeDictionary.small;

const ThumbnailIcon = ({ transactionType, containerSize, ...otherProps }) => {
  const Icon = thumbnailIconsByType[transactionType] || thumbnailIconsByType.seller;

  return (
    <div
      className={`
        tw-flex tw-justify-center tw-items-center
        tw-rounded-4px tw-bg-neutral-gray-10 tw-text-neutral-gray-50
        tw-cursor-pointer
        ${containerSizeClasses(containerSize)} 
      `}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Icon {...otherProps} />
    </div>
  );
};

ThumbnailIcon.propTypes = {
  transactionType: PropTypes.string,
  containerSize: PropTypes.oneOf(["small", "full"]),
};

ThumbnailIcon.defaultProps = {
  transactionType: null,
  containerSize: "small",
};

export default ThumbnailIcon;
