import React from "react";
import PropTypes from "prop-types";
import { ClearIndicator } from "./custom-wrappers";
import { optionShape } from "./utils";

// This component essentially replicates the native react-select behavior,
// so it's basically a copy-paste from react-select,
// adapted so that the components render correctly when called manually

const DropdownMultiValue = ({ selectRef, optionKey, optionIndex, option, className, removablePrefix }) => {
  const components = selectRef.current?.getComponents?.();

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const removeValue = () => {
    const selectValue = selectRef.current?.state?.selectValue ?? [];
    const newSelectValue = selectValue.filter((currentOption) => currentOption.value !== option.value);

    selectRef.current?.props?.onChange?.(newSelectValue, {
      action: "remove-value",
      removedValue: option,
    });

    if (!selectRef.current?.props?.blurInputOnSelect) {
      selectRef.current?.focusInput?.();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && selectRef.current?.props?.backspaceRemovesValue) {
      removeValue();
    }
  };

  return components.MultiValue({
    ...(selectRef.current?.commonProps ?? {}),
    components: {
      Container: components.MultiValueContainer,
      Label: components.MultiValueLabel,
      Remove: components.MultiValueRemove,
    },
    isFocused: false,
    isDisabled: selectRef.current?.props?.isDisabled ?? false,
    key: optionKey,
    index: optionIndex,
    removeProps: {
      // For accessibility
      tabIndex: 0,

      onMouseDown: handleMouseDown,
      onClick: removeValue,
      onTouchEnd: removeValue,
      onKeyDown: handleKeyDown,
    },
    data: option,
    children:
      `${removablePrefix}${selectRef.current?.formatOptionLabel?.(option, "value")}` ??
      `${removablePrefix}${option.label}`,
    className,
  });
};

DropdownMultiValue.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectRef: PropTypes.object.isRequired,
  optionKey: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  option: optionShape.isRequired,
  removablePrefix: PropTypes.string,
};

DropdownMultiValue.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  selectRef: PropTypes.object.isRequired,
  optionKey: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  option: optionShape.isRequired,
  removablePrefix: "",
};

const DropdownClearIndicator = ({ selectRef }) => {
  const isClearable = selectRef.current?.isClearable?.() ?? false;
  const hasValue = selectRef.current?.hasValue?.() ?? false;
  const { isDisabled, isLoading } = selectRef.current?.props ?? {
    isDisabled: false,
    isLoading: false,
  };

  if (!isClearable || isDisabled || !hasValue || isLoading) {
    return null;
  }

  const { commonProps } = selectRef.current;
  const { isFocused } = selectRef.current?.state ?? { isFocused: false };

  const innerProps = {
    onMouseDown: selectRef.current?.onClearIndicatorMouseDown,
    onTouchEnd: selectRef.current?.onClearIndicatorTouchEnd,
    "aria-hidden": "true",
  };

  return (
    <ClearIndicator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(commonProps ?? {})}
      innerProps={innerProps}
      isFocused={isFocused}
    />
  );
};

DropdownClearIndicator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectRef: PropTypes.object.isRequired,
};

const DropdownMultiValues = ({ selectRef, dropdownMultiValueClassName, removablePrefix }) => {
  const selectValue = selectRef.current?.state?.selectValue ?? [];

  return (
    <div
      className={`
        tw-flex tw-flex-wrap tw-gap-[8px]
        tw-my-[4px]
      `}
    >
      {selectValue.map((option, optionIndex) => {
        const optionKey = [
          selectRef.current?.getOptionLabel?.(option) ?? option.label,
          selectRef.current?.getOptionValue?.(option) ?? option.value,
        ].join(" ");

        return (
          <DropdownMultiValue
            key={optionKey}
            selectRef={selectRef}
            optionKey={optionKey}
            optionIndex={optionIndex}
            option={option}
            className={dropdownMultiValueClassName}
            removablePrefix={removablePrefix}
          />
        );
      })}
      {selectValue.length > 0 && <DropdownClearIndicator selectRef={selectRef} />}
    </div>
  );
};

DropdownMultiValues.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectRef: PropTypes.object.isRequired,
  dropdownMultiValueClassName: PropTypes.string,
  removablePrefix: PropTypes.string,
};

DropdownMultiValues.defaultProps = {
  dropdownMultiValueClassName: null,
  removablePrefix: "",
};

export default DropdownMultiValues;
