import React, { Children, cloneElement, isValidElement } from "react";
import PropTypes from "prop-types";
import styleSchema from "./TabBar.styles";

const TabBar = ({ children, className, isPadded }) => (
  <div className="tw-inline-block tw-relative">
    <div className={`${styleSchema.regular} ${isPadded ? "" : "tw-gap-[32px]"} ${className}`}>
      {Children.map(children, (child) => (isValidElement(child) ? cloneElement(child, { isPadded }) : null))}
    </div>
    <div className="tw-absolute tw-bottom-0 tw-h-[1px] tw-w-full tw-rounded-full tw-bg-neutral-gray-10" />
  </div>
);

TabBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isPadded: PropTypes.bool,
};

TabBar.defaultProps = {
  className: "",
  isPadded: false,
};

export default TabBar;
