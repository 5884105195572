import React from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import { Plus } from "@shared/v2/Icomoon";

const Agreementbtn = ({ containerClass, isEmptyState, agreementName, onClickFunc }) => {
  const onClickAgreement = () => {
    onClickFunc(agreementName);
  };
  return (
    <Button
      schema="secondary"
      size="medium"
      onClick={onClickAgreement}
      className={`${containerClass} tw-text-[11px] tw-leading-[16px] tw-justify-center tw-w-[100%] !tw-rounded-8px tw-flex tw-gap-6px tw-items-center`}
    >
      {isEmptyState && <Plus size="m" />}
      {agreementName}
    </Button>
  );
};

Agreementbtn.propTypes = {
  isEmptyState: PropTypes.bool,
  containerClass: PropTypes.string,
  agreementName: PropTypes.string.isRequired,
  onClickFunc: PropTypes.func.isRequired,
};
Agreementbtn.defaultProps = {
  isEmptyState: false,
  containerClass: "",
};
export default Agreementbtn;
