import withRedux from "./withRedux";

//HOC for consumer React components

//state.tdpTasksReducer[prop]
export const tdpTasks = withRedux({
  reducerName: "tdpTasksReducer",
});

export const tdpDetails = withRedux({
  reducerName: "tdpDetailsReducer",
});

export const tdpPeople = withRedux({
  reducerName: "tdpPeopleReducer",
});

export const tdpDates = withRedux({
  reducerName: "tdpDatesReducer",
});

export const tdpTimeline = withRedux({
  reducerName: "tdpTimelineReducer",
});

export const tdpNotes = withRedux({
  reducerName: "tdpNotesReducer",
});

export const tdpFinancials = withRedux({
  reducerName: "tdpFinancialsReducer",
});
