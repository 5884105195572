import React, { useEffect } from "react";

import { bool, func } from "prop-types";
import Modal from "@shared/v2/Modal/Modal";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";
import { useSelector } from "react-redux";

const ReferFriendModal = ({ isModalOpen, onHideHandlr, onSave }) => {
  const currentUser = useCurrentUser();
  const theme = useSelector(({ layout }) => layout.theme);
  useEffect(() => {
    if (!isModalOpen) return;
    const styleElement = document.createElement("style");

    const createForm = () => {
      const formId =
        theme.name === "place-theme"
          ? "2071682d-5a13-4050-ac78-bf7b83297359"
          : "5064edd8-8394-4766-bedf-91feb0ca2c33";

      window.hbspt.forms.create({
        region: "na1",
        portalId: "6041126",
        formId: formId,
        target: "#hbspt-form-referfriend",
        onFormReady: () => populateFormFields(),
        onFormSubmitted: () => onSave(),
      });

      // Added styles after the script has loaded
      styleElement.textContent = `
    .hs-form input[type="text"],
    .hs-form input[type="email"],
    .hs-form textarea {
        width: 100% !important; 
        height: 36px;
        padding: 8px 12px 8px 12px;
        background-color: #f5f5f5; 
        margin-bottom: 15px;
        border: 1px solid #ccc; 
        border-radius: 4px;
    }
    .hs-form input[type="checkbox"]{
      margin-right: 10px;
      margin-left: -40px;
    }
    .form-columns-1,
    .form-columns-2 {
      max-width: 100% !important; 
    }
    .hs-form input[type="submit"] {
        background-color: #59C4C4;
        color: #FFFFFF;
        padding: 8px 20px;
        border: none;
        border-radius: 1000px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        margin-top: 23px;
    }
    .hs-form .actions {
      display: flex;
      justify-content: end;
      background: white;
    }
    .hs-form .hs-submit {
      position: sticky;
      bottom: 0;
    }
    .hs-form input[type="submit"]:hover {
        background-color: #4CA7A7; 
    }
    .hs-form input[type="submit"]:active {
      background-color: #336E7B; 
    }
    .hs-form ul{
      list-style-type: none;
    }
    .hs-error-msgs {
      height: 2px;
      margin-left: -40px;
      margin-top: -14px;
      margin-bottom: 25px;
    }
    .error {
      border: 1px solid red !important; 
    }
    .hs_error_rollup {
      display: none;
    }
        `;
      document.head.appendChild(styleElement);
    };
    const populateFormFields = () => {
      // pre-fill the form with the first name, last name, and email plus uuid
      $('input[name="firstname"]').val(currentUser.firstName).change();
      $('input[name="lastname"]').val(currentUser.lastName).change();
      $('input[name="email"]').val(currentUser.email).change();
      $('input[name="brivity___user_uuid"]').val(currentUser.uuid).change();
    };

    if (!window.hbspt) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.defer = true;
      script.async = true;
      script.onload = createForm;
    } else {
      createForm();
    }
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isModalOpen]);

  return (
    <Modal
      show={isModalOpen}
      onHide={onHideHandlr}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[616px] tw-max-h-[93vh] tw-w-full tw-flex tw-flex-col"
      closeButton
    >
      <Modal.Header title="Refer a friend and get a $250 credit" closeButton />
      <Modal.Body className="tw-overflow-y-auto tw-overflow-x-hidden tw-flow-root">
        <div className="tw-py-[35px]">
          <img src="/assets/refer-friend.png" alt="Refer Friend" />
          <div>
            <div className="tw-py-[16px] tw-text-18px tw-leading-24px tw-font-bold tw-text-gray-75 ">
              The More You Refer, The More You Save!
            </div>
            <div className="tw-text-14px tw-leading-20px tw-font-normal tw-text-gray-75 ">
              Know a real estate rockstar that’s looking to level up their business? Take a minute to fill out
              the form and we’ll reach out to your referral directly.{" "}
              {theme.name === "brivity-theme" ? (
                <span>
                  Once they sign up for Brivity and have been a customer for 90 days, we'll send you a{" "}
                  <b>$250</b> credit that can be applied to your Brivity Platform monthly bill, Facebook ads,
                  Mailer campaigns, or future Brivity add-ons.
                </span>
              ) : (
                <span>
                  Once they sign up for Brivity Platform, Brivity VA, or Forward Coaching and have been a
                  customer for 90 days, we’ll apply a <b>$250</b> credit to the corresponding company.
                  <div className="tw-mt-6">
                    When your referral signs up for:
                    <ul>
                      <li>
                        Brivity Platform: you’ll receive a credit to be used on Facebook ads, Mailer
                        campaigns, or future Brivity add ons.
                      </li>
                      <li>
                        Brivity VA: you’ll receive a credit to be used towards your Brivity VA bill if you
                        currently employ a Brivity Virtual Assistant.
                      </li>
                      <li>
                        Forward Coaching: you’ll receive your credit to be used toward a course or current
                        coaching clients can use towards your monthly coaching fee.
                      </li>
                    </ul>
                    <i>NOTE: Credits will only be applied when referring a non PLACE partner</i>
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        <div id="hbspt-form-referfriend" />
      </Modal.Body>
    </Modal>
  );
};
ReferFriendModal.propTypes = {
  isModalOpen: bool,
  onHideHandlr: func,
  onSave: func,
};

ReferFriendModal.defaultProps = {
  isModalOpen: false,
  onHideHandlr: () => {},
  onSave: () => {},
};
export default ReferFriendModal;
