const valueOrZero = (value) => value ? value : 0

const lastVisit = (value) => value ? moment(value).fromNow() : 'NEVER'

const visits = (value) => value ? value : 'NONE'

export {
  valueOrZero,
  lastVisit,
  visits
}