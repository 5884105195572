import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useGivenOrGeneratedId } from "../../hookHelpers";
import { Display, DisplayEditorToggleBase } from "../InlineEditor";
import TextButton from "../TextButton";
import CancelConfirmMultiline from "../InlineEditor/CancelConfirmMultiline";
import { linebreaksToParagraphs } from "../InlineEditor/stringManipulation";
import TextArea from "./TextArea";

const InlineEditingTextArea = ({
  id: idFromProps,
  value,
  disabled,
  error,
  placeholder,
  externalEditing,
  onInteract,
  onChange,
  onCancelEditing,
  onConfirmEditing,
  className,
  rows,
  displayComponent,
  displayClassName,
  showLineBreak,
  resize,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("inline-editing-text-area", idFromProps);

  const ignoreExternalEdition = [null, undefined].includes(externalEditing);
  const [editing, setEditing] = useState(externalEditing ?? false);
  const [editingValue, setEditingValue] = useState(value);
  const textAreaRef = useRef();

  // We need to keep track of when the user clicked outside, to prevent calling `confirmEditing` twice
  const [clickedOutside, setClickedOutside] = useState(false);

  // Update the editing flag when `externalEditing` changes
  useEffect(() => setEditing(externalEditing ?? false), [externalEditing]);

  // Update the editing value when `value` changes
  useEffect(() => setEditingValue(value), [value]);

  const handleInteract = () => {
    if (onInteract) {
      onInteract();
    }
    if (ignoreExternalEdition) {
      setEditing(true);
    }
  };

  const cancelEditing = () => {
    if (ignoreExternalEdition) {
      setEditing(false);
    }
    setEditingValue(value); // Revert changes made on editing mode
    if (onCancelEditing) {
      onCancelEditing();
    }
  };

  const confirmEditing = () => {
    if (onChange) {
      onChange(editingValue);
    }
    if (ignoreExternalEdition) {
      setEditing(false);
    }
    if (onConfirmEditing) {
      onConfirmEditing();
    }
  };

  const focusTextArea = () => {
    if (!textAreaRef.current) {
      return;
    }
    const textArea = textAreaRef.current;
    textArea.select();
    textArea.focus();
  };

  // Auto-focus when switching to editing mode
  useEffect(() => {
    if (!editing) {
      return;
    }
    focusTextArea();
  }, [editing]);

  const handleKeyDown = (e) => {
    // In case the input is inside of a modal, this prevents closing the modal before closing the input
    e.stopPropagation();

    // We dont track 'enter' here, because we use enter to a new line (TextArea).
    switch (e.key) {
      case "Esc":
      case "Escape":
        cancelEditing();
        break;
      default:
        break;
    }
  };

  const handleFocusOut = () => {
    if (clickedOutside) {
      // Do not call `confirmEditing` a second time if it was already called from `handleClickOutside`
      setClickedOutside(false);
      return;
    }

    confirmEditing();
  };

  const handleClickOutside = () => {
    setClickedOutside(true);

    confirmEditing();
  };

  const displayWhenNoValueIsProvided = displayComponent ? (
    displayComponent(handleInteract)
  ) : (
    <TextButton
      data-cy="inline-editor-display-button"
      className={`tw-p-0 ${displayClassName}`}
      onClick={handleInteract}
    >
      add
    </TextButton>
  );

  const label = useMemo(
    () => (showLineBreak ? linebreaksToParagraphs(value.trim()) : value.trim()),
    [showLineBreak, value],
  );

  const display = value ? (
    <Display
      id={id}
      label={label}
      value={value}
      disabled={disabled}
      onInteract={handleInteract}
      className={displayClassName}
      showLineBreak={showLineBreak}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  ) : (
    displayWhenNoValueIsProvided
  );

  const handleOnChange = (event) => {
    setEditingValue(event.target.value);
  };

  const editor = (
    <TextArea
      textAreaRef={textAreaRef}
      id={id}
      value={editingValue}
      trailing={<CancelConfirmMultiline onCancelEditing={cancelEditing} onConfirmEditing={confirmEditing} />}
      error={error}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      onFocusOut={handleFocusOut}
      className="tw-shadow-dropdown selection:tw-bg-theme-inline-editing-textarea"
      data-cy="inline-editor-input"
      rows={rows}
      resize={resize}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );

  return (
    <DisplayEditorToggleBase
      mode={editing ? "editor" : "display"}
      display={display}
      editor={editor}
      onClickedOutside={handleClickOutside}
      className={className}
    />
  );
};

InlineEditingTextArea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  // To allow changing the `editing` state from the outside,
  // useful for when we don't want the input to close after confirming/canceling (e.g. need to make an external request)
  externalEditing: PropTypes.bool,
  onInteract: PropTypes.func,
  onChange: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onConfirmEditing: PropTypes.func,
  className: PropTypes.string,
  rows: PropTypes.number,
  displayComponent: PropTypes.func,
  displayClassName: PropTypes.string,
  showLineBreak: PropTypes.bool,
  resize: PropTypes.oneOf(["none", "both", "horizontal", "vertical"]),
};

InlineEditingTextArea.defaultProps = {
  id: null,
  disabled: false,
  error: null,
  placeholder: "",
  externalEditing: null,
  onInteract: null,
  onChange: null,
  onCancelEditing: null,
  onConfirmEditing: null,
  className: "",
  rows: 5,
  displayComponent: null,
  displayClassName: "",
  showLineBreak: false,
  resize: "none",
};

export default InlineEditingTextArea;
