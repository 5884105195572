import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { errorShapeForErrorBanner } from "../../../../../shared/v2/ErrorBanner";
import FlatPercentInput from "../../../../../shared/v2/FlatPercentInput";
import FlatPercentToggle from "../../../../../shared/v2/FlatPercentToggle";
import { FloatingForm } from "../../../../../shared/v2/Modal";
import TextComboInput from "../../../../../shared/v2/TextComboInput";
import TextInput from "../../../../../shared/v2/TextInput";

const EditAmountModal = ({
  errors,
  isSaving,
  allowPercentage,
  isPercentage,
  notes,
  onCancel,
  onSave,
  show,
  title,
  value,
}) => {
  // This modal is used in many places with different titles (e.g.: edit agent
  // GCI, edit team GCI, edit brokerage split and so on). Since all of them
  // behave in exactly the same while editing, I opted to keep the state
  // handling logic in here as to not duplicate it for each individual modal
  // instance.
  //
  // That being the case, we need to have some component state but also remember
  // to keep it in sync with the props that are passed in, hence the side-effects
  // defined below.
  const [isCurrentlyPercentage, setIsCurrentlyPercentage] = useState(isPercentage);
  const [currentValue, setCurrentValue] = useState(value);
  const [currentNotes, setCurrentNotes] = useState(notes);

  useEffect(() => setIsCurrentlyPercentage(isPercentage), [isPercentage]);
  useEffect(() => setCurrentValue(value), [value]);
  useEffect(() => setCurrentNotes(notes), [notes]);

  return (
    <FloatingForm
      primaryOption="Save"
      scroll="none"
      contentSize="small"
      title={title}
      errors={errors}
      isPrimaryLoading={isSaving}
      onPrimary={() =>
        onSave({
          // As specified in the Figma: "Notes can be removed by clearing the
          // Notes field of text".
          notes: currentNotes === "" ? null : currentNotes,
          value: currentValue,
          isPercentage: isCurrentlyPercentage,
        })
      }
      show={show}
      onCancel={onCancel}
      closeOnClickOutside
      closeOnEscape
    >
      <div className="tw-flex tw-gap-[8px] tw-mb-24px tw-items-end">
        {allowPercentage ? (
          <>
            <div className="tw-grow">
              <FlatPercentInput
                label="Amount"
                isRequired
                value={currentValue}
                isPercentage={isCurrentlyPercentage}
                onChange={(evt) => setCurrentValue(evt.target.value)}
              />
            </div>
            <FlatPercentToggle
              isPercentage={isCurrentlyPercentage}
              onChange={(_, newIsPercentage) => setIsCurrentlyPercentage(newIsPercentage)}
            />
          </>
        ) : (
          <div className="tw-grow">
            <TextComboInput
              label="Amount"
              isRequired
              value={currentValue}
              leading="$"
              onChange={(evt) => setCurrentValue(evt.target.value)}
            />
          </div>
        )}
      </div>

      <TextInput label="Notes" value={currentNotes} onChange={(evt) => setCurrentNotes(evt.target.value)} />
    </FloatingForm>
  );
};

EditAmountModal.propTypes = {
  errors: PropTypes.arrayOf(errorShapeForErrorBanner),
  isSaving: PropTypes.bool.isRequired,
  allowPercentage: PropTypes.bool,
  isPercentage: PropTypes.bool,
  notes: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

EditAmountModal.defaultProps = {
  errors: [],
  allowPercentage: true,
  isPercentage: false,
  notes: null,
};

export default EditAmountModal;
