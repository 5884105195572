import React, { useEffect, useMemo } from "react";
import axios from "axios";
import SharedAddAutoPlanModal from "../../../shared/AddAutoPlanModal";

const AddAutoPlanModal = ({
  assignableId,
  closeAddAutoPlanModal,
  isModalSubmitting,
  showPreviewPlanModal,
  submitApplyPlan,
  show,
}) => {
  const [autoPlans, setAutoPlans] = React.useState([]);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const options = useMemo(
    () =>
      autoPlans
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [autoPlans],
  );
  const selectedAutoPlan = useMemo(
    () => (selectedPlan ? { label: selectedPlan.name, value: selectedPlan.id } : null),
    [selectedPlan],
  );

  useEffect(() => {
    const abortController = new AbortController();

    axios
      .get("/auto_plans/auto_plans_no_actions.json", {
        params: { plan_type: "undefined", page: "null" },
        signal: abortController.signal,
      })
      .then((results) => {
        setAutoPlans(results.data);
      })
      .catch(console.log);

    return () => {
      abortController.abort();
    };
  }, []);

  const updateAutoPlan = (planId) => setSelectedPlan(autoPlans.find((p) => p.id == planId));

  return (
    <SharedAddAutoPlanModal
      show={show}
      onCancel={closeAddAutoPlanModal}
      onApply={() => submitApplyPlan(selectedPlan, assignableId)}
      isApplying={isModalSubmitting}
      onPreview={() => showPreviewPlanModal(selectedPlan.id)}
      disableInteractionButtons={!selectedPlan}
      availablePlansAsOptions={options}
      selectedAutoPlan={selectedAutoPlan}
      onSelectAutoPlan={(opt) => updateAutoPlan(opt.value)}
    />
  );
};

export default AddAutoPlanModal;
