/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { produce } from "immer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSpecificActionSubmit } from "../reducers/autoPlanEdit";
import { createAction, saveAction } from "./actions/autoPlanActionCreators";

export const  useFormState = ({
  defaultState,
  form,
  autoPlanId,
  show
}) => {
  const [state, setState] = useState({
    ...defaultState,
    ...form,
    auto_plan_id: autoPlanId,
  });

  useEffect(() => {
    setState({
      ...defaultState,
      ...form,
      auto_plan_id: autoPlanId,
    })
  }, [show])


  // most usecase, we'll use this;
  const setStateHandlr = (key) => (val) => setState(produce(state, (draft) => {
    draft[key] = val
  }))

  return {state, setState, setStateHandlr};
}

export const useActionFormSubmitHandler = (autoPlanId) => {
  const specificActionSubmit = useSpecificActionSubmit();
  const isPosting = specificActionSubmit === 'post';
  const dispatch = useDispatch();

  return (form) => {    
    const appendedRequestPath = isPosting ? '' : `/${form.id}`;
    const requestPath = `${autoPlanId}/actions${appendedRequestPath}`
    const requestAction = isPosting ? createAction : saveAction;
  
    dispatch(requestAction(requestPath, form, autoPlanId))
  }
}

