/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import axios from "axios";
import formatter from "../../../../shared/formatters";
import { getBusinessDashboard } from "./api";

const transformYtdTotals = ({ year, ...rest }) => ({
  year,
  ...Object.entries(rest).reduce(
    (acc, [category, value]) => ({
      ...acc,
      [category]: Object.keys(value).reduce(
        (a, key) => ({
          ...a,
          [key]:
            year !== new Date().getFullYear() && /mtd/i.test(key)
              ? {
                  Units: { value: "-" },
                  Volume: { value: "-" },
                  GCI: { value: "-" },
                }
              : {
                  Units: { value: formatter.number(value[key].units) },
                  Volume: {
                    label: /pending|active|pipeline/.test(key) ? "Est. Volume" : "",
                    value: formatter.currency(value[key].volume),
                  },
                  ...(typeof value[key].gci === "undefined"
                    ? undefined
                    : {
                        GCI: {
                          label: /pending|active|pipeline/.test(key) ? "Est. GCI" : "",
                          value: formatter.currency(value[key].gci),
                        },
                      }),
                },
        }),
        {},
      ),
    }),
    {},
  ),
});

const useTransactionSnapshot = (year) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState(transformYtdTotals({}));
  const [error, setError] = React.useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getBusinessDashboard({
      signal: abortController.signal,
      params: {
        section: "ytd_totals",
        selectedYear: year,
      },
    })
      .then((res) => {
        setData(transformYtdTotals(res.data));
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [year]);

  return { data, error, loading };
};

export default useTransactionSnapshot;
