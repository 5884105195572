/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Pdf = (props) => (
  <Icon {...props} name="Pdf">
    <path d="M7.684 8.003c-.156-.5-.153-1.466-.062-1.466.262 0 .237 1.153.062 1.466zm-.053 1.475c-.241.631-.541 1.353-.887 1.959.572-.219 1.219-.537 1.966-.684a4.043 4.043 0 0 1-1.078-1.275zm-2.94 3.9c0 .025.412-.169 1.091-1.256-.209.197-.909.766-1.091 1.256zM9.75 5H14v10.25c0 .416-.334.75-.75.75H2.75a.748.748 0 0 1-.75-.75V.75c0-.416.334-.75.75-.75H9v4.25c0 .412.337.75.75.75zm-.25 5.369c-.625-.381-1.041-.906-1.334-1.681.141-.578.363-1.456.194-2.006-.147-.919-1.325-.828-1.494-.213-.156.572-.012 1.378.253 2.406-.363.862-.897 2.019-1.275 2.681-.003 0-.003.003-.006.003-.847.434-2.3 1.391-1.703 2.125.175.216.5.312.672.312.559 0 1.116-.562 1.909-1.931.806-.266 1.691-.597 2.469-.725.678.369 1.472.609 2 .609.913 0 .975-1 .616-1.356-.434-.425-1.697-.303-2.3-.225zm4.281-7.088L10.719.218a.748.748 0 0 0-.531-.219h-.187v4h4v-.191a.745.745 0 0 0-.219-.528zm-2.315 7.978c.128-.084-.078-.372-1.337-.281 1.159.494 1.337.281 1.337.281z" />
  </Icon>
);

export default Pdf;
