import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "@shared/v2/Modal";
import FieldLabel from "@shared/v2/FieldLabel";
import { EnvelopeSolidV6, FacebookCircleSolidV6, LinkSolidV6 } from "@shared/v2/Icomoon";
import { useWebsiteIDXUrl } from "../../reducers/websiteReducer/selectors";
import { setSnackbar } from "../../reducers/snackbarReducer";

const ShareButton = ({ children, icon, href, onClick }) => {
  let TagName = "button";
  if (href) TagName = "a";
  return (
    <TagName
      href={href}
      type={TagName === "button" ? "button" : undefined}
      className="tw-flex-1 tw-w-full tw-min-w-[calc(50%-8px)] tw-max-w-[calc(50%-8px)] tw-flex tw-items-center tw-gap-[10px] tw-p-[16px] tw-rounded-[8px] tw-border-0 tw-bg-white tw-shadow-card tw-text-14d tw-font-semibold hover:tw-bg-neutral-gray-2.5 tw-whitespace-nowrap"
      onClick={onClick}
      target={TagName === "a" ? "_blank" : undefined}
      rel={TagName === "a" ? "noopener noreferrer" : undefined}
    >
      {icon} {children}
    </TagName>
  );
};

ShareButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

ShareButton.defaultProps = {
  href: undefined,
  onClick: undefined,
};

const SharePostModal = ({ onClose, post }) => {
  const dispatch = useDispatch();
  const websiteIDXUrl = useWebsiteIDXUrl();
  const url = `${websiteIDXUrl}/post/${post?.slug}`;

  return (
    <Modal
      show={Boolean(post)}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[24px]"
    >
      <Modal.Header title="Share Post" closeButton />

      <Modal.Body className="tw-p-[8px] tw-mx-[-8px] tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <FieldLabel label="Share on Social Media" />
          <div className="tw-flex tw-flex-wrap tw-gap-[16px]">
            <ShareButton
              icon={<FacebookCircleSolidV6 size="l" className="tw-text-[#4267B2]" />}
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            >
              Facebook
            </ShareButton>
            <ShareButton
              icon={<i className="fab fa6-x-twitter tw-text-16d tw-text-black" />}
              href={`https://twitter.com/intent/tweet?text=${url}`}
            >
              X (Twitter)
            </ShareButton>
            <ShareButton
              icon={<i className="fab fa6-pinterest tw-text-16d tw-text-[#E60023]" />}
              href={`http://pinterest.com/pin/create/link/?url=${url}`}
            >
              Pinterest
            </ShareButton>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <FieldLabel label="Share Directly" />
          <div className="tw-flex tw-flex-wrap tw-gap-[16px]">
            <ShareButton icon={<EnvelopeSolidV6 size="l" />} href={`mailto:?subject=Test&body=${url}`}>
              Email
            </ShareButton>
            <ShareButton
              icon={<LinkSolidV6 size="l" />}
              onClick={() =>
                window.copyTextToClipboard(url).then(() =>
                  dispatch(
                    setSnackbar({
                      show: true,
                      position: "top",
                      text: "Link copied",
                      schema: "executed",
                      timeout: 5000,
                      closeOnTimeout: () => dispatch(setSnackbar({ show: false })),
                    }),
                  ),
                )
              }
            >
              Copy Link
            </ShareButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SharePostModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  post: PropTypes.shape(),
};

SharePostModal.defaultProps = {
  post: null,
};

export default SharePostModal;
