import React, { useEffect, useState } from "react";
import { isCancel } from "axios";
import { caseTransformingAxios as axios } from "../../../../shared/v2/caseTransformingAxios";
import colors from "../../DashboardShared/helpers/colors";

const DATA_TRANSFORMS = {
  agents: ({
    totalAgents = 0,
    totalShowingAgents = 0,
    totalOperatorAgents = 0,
  }) => ({
    titleName: "Total Agents",
    titleValue: totalAgents + totalShowingAgents + totalOperatorAgents,
    metrics: [
      {
        metricValue: totalAgents,
        metricTitle: "Agents",
      },
      {
        metricValue: totalShowingAgents,
        metricTitle: "Showing Agent",
      },
      {
        metricValue: totalOperatorAgents,
        metricTitle: colors.theme === "place" ? "Operator" : "Team Lead",
      },
    ],
  }),
  staff: ({
    totalStaff = 0,
    totalOperatorAdmin = 0,
    totalVirtualAdmin = 0,
    totalStaffInsideSales = 0,
    totalOtherStaff = 0,
  }) => ({
    titleName: "Total Staff",
    titleValue: totalStaff,
    metrics: [
      {
        metricValue: totalOperatorAdmin,
        metricTitle: "Ops. Admin",
      },
      {
        metricValue: totalVirtualAdmin,
        metricTitle: "Virtual Admin",
      },
      {
        metricValue: totalStaffInsideSales,
        metricTitle: "Inside Sales",
      },
      {
        metricValue: totalOtherStaff,
        metricTitle: "Other",
      },
    ],
  }),
};

const useTotalByType = ({ year, type }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState(DATA_TRANSFORMS[type]({}));
  const [error, setError] = React.useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    axios
      .get("/api/v4/reporting/operator_dashboard", {
        signal: abortController.signal,
        params: {
          section: `total_${type}_breakdown`,
          selected_year: year,
        },
      })
      .then((res) => {
        setData(DATA_TRANSFORMS[type](res.data));
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          setError(err);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [year, type]);

  return { data, error, loading };
};

export default useTotalByType;
