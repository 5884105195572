import React from "react";

import Header from "../../../UI/Header";
import DetailsSpecific from "../DetailsSpecific";
import ImportListingDetailsBtn from "../ImportListingDetailsBtn";

const DetailsWrapper = () => (
  <div>
    <Header header="Details" add={<ImportListingDetailsBtn />} />
    <DetailsSpecific />
  </div>
);

export default DetailsWrapper;
