import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { getExternalApi } from "@shared/externalApi";

export const postOtherInteraction = (data, uuid) =>
  axios.post(`/api/v4/transactions/interactions?uuid=${uuid}`, data, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });

export const postSmsInteraction = ({ conversation, message, messagingServiceUrl, listingId }) => {
  const messagingApi = getExternalApi(messagingServiceUrl);

  const formData = new FormData();
  formData.append("conversationUuid", conversation.uuid);
  formData.append("messageType", "standard");
  if (message.message) formData.append("message", message.message);
  if (message.media) formData.append("attachment", message.media);
  if (listingId) {
    formData.append("attributes", JSON.stringify([{ key: "listing_id", value: listingId }]));
  }

  return messagingApi.post("/Message/Sms", formData);
};

export const getOrCreateConversation = ({ personUuid, personPhoneNumber, messagingServiceUrl }) => {
  const messagingApi = getExternalApi(messagingServiceUrl);
  return messagingApi
    .post("/Conversation/WithUsers", {
      conversationType: "groupSms",
      isChannel: false,
      users: [
        {
          address: personPhoneNumber,
          personUuid,
          isConversationOwner: false,
        },
      ],
    })
    .catch((err) => {
      if (err?.response?.status === 409) return { data: { ...err.response.data, isConflict: true } };
      throw err;
    });
};

export const getSmsHistory = (payload) =>
  caseTransformingAxios.get(`/people/${payload.personId}/interactions/sms_message_history`, {
    params: { max_id: payload.maxId },
  });

export const postEmailInteraction = (interaction, uuid) =>
  axios.post(`/api/v4/transactions/interactions/email?uuid=${uuid}`, interaction, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });

export const getEmailTemplates = () =>
  axios.get(`/api/v4/transactions/interactions/email_templates`, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });

export const parseInteractionTemplate = (id, listingId, recipientId, senderId) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("listing_id", listingId);
  formData.append("recipient_id", recipientId);
  formData.append("sender_id", senderId);

  return axios.post("/interaction_templates/parse.json", formData, {
    headers: {
      "X-CSRF-Token": window.ReactOnRails.authenticityToken(),
    },
  });
};

export const getInteractableEmails = (term) =>
  axios.get(`/interactions/interactable_emails?search=${term}`, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });
