import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

export default class PublicActivityLoginEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.headline = this.headline.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  headline = () => {
    const first_name = this.event.object_attributes.first_name
    const last_name = this.event.object_attributes.last_name
    const full_name = `${first_name} ${last_name}`.trim()

    return (
      <div>
        <span className="bold-detail">
          {`${full_name}`}
        </span>
        {" logged into Brivity"}
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-sign-in timeline-icon-circle"/>
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">
              Time: {this.event.interacted_at_absolute}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              Login
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row details-row">
              {this.headline()}
            </div>
            <div className="row bold-detail details-row details-header">
              Details:
            </div>
            <div className="row details-row">
              Time: {this.event.interacted_at_absolute}
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
