import React from "react";
import { useDispatch } from "react-redux";

import TextComboInput from "@shared/v2/TextComboInput";
import { inputDescription } from "../../ModalViews/styles";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

const LicenseNumber = () => {
  const dispatch = useDispatch();
  const { licenseId } = useContactPerson().loginUserAttributes;
  return (
    <>
      <TextComboInput
        label="License #"
        value={licenseId}
        onChange={(e) =>
          dispatch(setPersonValue({ key: "loginUserAttributes.licenseId", value: e.target.value }))
        }
      />
      <div className={`${inputDescription} tw-tracking-[-.3px]`}>
        If you’re required to display a license number next to other agent information, place it in this
        field.
      </div>
    </>
  );
};

export default LicenseNumber;
