/* eslint-disable no-nested-ternary */
import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";
import { removeCommas } from "../../../../shared/v2/inputFormatUtils";
import format from "../../../../shared/formatters";
import { putDetailAsThunk } from "../../actions/thunks";

import ClosePrice from "../ClosePrice";

const formatExistingNumber = (numericOrStringValue) =>
  numericOrStringValue ? format.number(removeCommas(numericOrStringValue.toString())) : null;

const PricesInfo = ({ price }) => {
  const textualPrice = formatExistingNumber(price);

  return (
    <div className="tw-flex tw-flex-col tw-min-w-[150px] tw-mr-56px tw-justify-between">
      <ClosePrice />
      <div className="tw-flex tw-flex-col" data-cy="header-listing-price">
        <div>
          <div className="tw-flex">
            <div className="tw-inline-block tw-text-12d tw-font-semibold tw-tracking-normal">
              Listing Price
            </div>
          </div>
        </div>
        <div className="tw-flex tw-h-36px">
          <div className="tw-text-18px tw-font-bold tw-leading-36px tw-tracking-normal">
            {(price && `$${textualPrice}`) || "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  price: state.tdpDetailsReducer?.listingDetails?.price,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});

PricesInfo.propTypes = {
  price: string,
};

PricesInfo.defaultProps = {
  price: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(PricesInfo);
