/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronLeftSolidV6 = (props) => (
  <Icon {...props} name="Chevron Left Solid V6">
    <path d="M10.429 16a1.14 1.14 0 0 1-.808-.335L2.764 8.808a1.143 1.143 0 0 1 0-1.616L9.621.335a1.143 1.143 0 0 1 1.616 1.616L5.188 8l6.05 6.05a1.143 1.143 0 0 1-.809 1.95z" />
  </Icon>
);

export default ChevronLeftSolidV6;
