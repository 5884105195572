import React from "react";
import Proptypes from "prop-types";
import { Row, Col } from "../../../../shared/v1";
import Button from "../../../../shared/v2/Button";
import { getCardLink } from "../api/helpers";

const NoCards = ({ name, imageURL, description }) => {
  const getCardUrl = () => getCardLink(name);

  return (
    <div className="tw-my-[10px] ">
      <Row className="tw-m-0 tw-mx-[0px] tw-flex tw-text-center	tw-items-center tw-p-0 tw-px-[16px] tw-py-[16px]">
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-mx-auto" size={12}>
          <div className="tw-flex tw-justify-center	tw-h-[180px] tw-max-w-full tw-block tw-transition tw-ease-out tw-delay-300 tw-drop-shadow-19">
            <img
              alt="coverImage"
              className="tw-block tw-h-[180px] tw-max-w-full tw-min-w-[162px] tw-cursor-default"
              src={imageURL}
            />
          </div>
          <div className="tw-py-[16px] tw-leading-[20px] tw-text-[14px] tw-font-normal tw-text-gray-75">
            {description}
          </div>
          <div className="tw-flex tw-justify-center">
            <Button
              onClick={() => window.open(getCardUrl().url, "_blank")}
              className="tw-flex tw-items-center"
              schema="primary"
              size="medium"
            >
              GET STARTED
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

NoCards.propTypes = {
  name: Proptypes.string,
  description: Proptypes.string,
  imageURL: Proptypes.string,
};
NoCards.defaultProps = {
  description: "",
  name: "",
  imageURL: "",
};
export default NoCards;
