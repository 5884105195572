import React from 'react';
import { Row, Col } from 'react-bootstrap';
import css from './report-styles.module.css';
import { ReportHeader } from './';

const TasksHeaders = ({sortBy, sortKey, sortDesc, sourceTypeTitle}) => {
  const namePrefix = sourceTypeTitle == 'Administrators' ? 'admin' : 'agent';
  return <Col xs={12} className={css.noWrap}>
    <div className={css.singleReportBodyTitle}>
      <h5 className={css.downCaseH5}>
        {sourceTypeTitle}
      </h5>
    </div>
    <div className='th' style={{overflow: 'visible'}}>
      <ReportHeader
        name={`${namePrefix}_agent`}
        className={`col-xs-2 ${css.backgroundWhite}`}
        sortDesc={sortDesc}
        sortKey={sortKey}
        label='Name'
        handleSort={sortBy} />
      <ReportHeader
        name={`${namePrefix}_assigned_task_count`}
        className={`col-xs-2 text-center ${css.backgroundWhite}`}
        sortDesc={sortDesc}
        sortKey={sortKey}
        label='Assigned Tasks'
        handleSort={sortBy} />
      <ReportHeader
        name={`${namePrefix}_completed_task_count`}
        className={`col-xs-2 text-center ${css.backgroundWhite}`}
        sortDesc={sortDesc}
        sortKey={sortKey}
        label='Completed Tasks'
        handleSort={sortBy} />

        <ReportHeader
          name={`${namePrefix}_on_time_task_count`}
          className={`col-xs-2 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label='Completed On Time'
          handleSort={sortBy} />
        <ReportHeader
          name={`${namePrefix}_late_task_count`}
          className={`col-xs-2 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label='Completed Late'
          handleSort={sortBy} />

      <ReportHeader
        name={`${namePrefix}_login_count`}
        className={`col-xs-1 text-center ${css.backgroundWhite}`}
        sortDesc={sortDesc}
        sortKey={sortKey}
        label='# Logins'
        handleSort={sortBy} />
      <ReportHeader
        name={`${namePrefix}_last_login`}
        className={`col-xs-1 text-center ${css.backgroundWhite}`}
        sortDesc={sortDesc}
        sortKey={sortKey}
        label='Last Login'
        handleSort={sortBy} />
    </div>
      </Col>
    }

  export default TasksHeaders;
