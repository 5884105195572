import React from "react";
import { string, func } from "prop-types";
import { putStatusAsThunk } from "../../actions/thunks";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  HEADERDETAILS_PUBLISHED,
  LISTING_UUID,
  STATUS,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

const EllipsisDropdown = ({ uuid, setRename, status, onPutReferralStatusAsThunk }) => (
  <div className="tw-absolute tw-right-0 tw-top-full tw-shadow-modal tw-w-[153px] tw-flex tw-flex-col tw-items-start tw-pt-[10px] tw-bg-white tw-rounded tw-cursor-default">
    <div className="tw-h-[30px] tw-ml-12px">
      <button
        type="button"
        className="tw-flex tw-border-none tw-bg-transparent"
        onClick={() => setRename((prev) => !prev)}
        data-cy="header-dropdown-rename-button"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.1833 3.19859L13.6889 5.70415C13.7944 5.8097 13.7944 5.98193 13.6889 6.08748L7.62222 12.1542L5.04445 12.4403C4.7 12.4791 4.40833 12.1875 4.44722 11.843L4.73333 9.26526L10.8 3.19859C10.9056 3.09304 11.0778 3.09304 11.1833 3.19859ZM15.6833 2.56248L14.3278 1.20693C13.9056 0.784703 13.2194 0.784703 12.7944 1.20693L11.8111 2.19026C11.7056 2.29581 11.7056 2.46804 11.8111 2.57359L14.3167 5.07915C14.4222 5.1847 14.5944 5.1847 14.7 5.07915L15.6833 4.09581C16.1056 3.67081 16.1056 2.9847 15.6833 2.56248ZM10.6667 10.5041V13.3319H1.77778V4.44304H8.16111C8.25 4.44304 8.33333 4.40693 8.39722 4.34581L9.50833 3.2347C9.71945 3.02359 9.56944 2.66526 9.27222 2.66526H1.33333C0.597222 2.66526 0 3.26248 0 3.99859V13.7764C0 14.5125 0.597222 15.1097 1.33333 15.1097H11.1111C11.8472 15.1097 12.4444 14.5125 12.4444 13.7764V9.39304C12.4444 9.09582 12.0861 8.94859 11.875 9.15693L10.7639 10.268C10.7028 10.3319 10.6667 10.4153 10.6667 10.5041Z"
            fill="#666666"
          />
        </svg>

        <div className="tw-ml-10px tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal">Rename</div>
      </button>
    </div>
    <div className="tw-h-[25px] tw-ml-12px">
      <button
        type="button"
        className="tw-flex tw-border-none tw-bg-transparent"
        onClick={() =>
          onPutReferralStatusAsThunk({
            status: "archived",
            uuid,
          })
        }
        data-cy={status === "archived" ? "header-dropdown-delete-button" : "header-dropdown-archive-button"}
      >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.75 11.5C1.75 11.9148 2.08516 12.25 2.5 12.25H11.5C11.9148 12.25 12.25 11.9148 12.25 11.5V4.75H1.75V11.5ZM5.5 6.53125C5.5 6.37656 5.62656 6.25 5.78125 6.25H8.21875C8.37344 6.25 8.5 6.37656 8.5 6.53125V6.71875C8.5 6.87344 8.37344 7 8.21875 7H5.78125C5.62656 7 5.5 6.87344 5.5 6.71875V6.53125ZM12.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V3.625C1 3.83125 1.16875 4 1.375 4H12.625C12.8313 4 13 3.83125 13 3.625V2.5C13 2.08516 12.6648 1.75 12.25 1.75Z"
            fill="#666666"
          />
        </svg>
        <div
          className={`tw-ml-10px tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal ${
            status === "archived" && "tw-text-red-700"
          }`}
        >
          Archive
        </div>
      </button>
    </div>
  </div>
);

const withTdpDetails = tdpDetails({
  WrappedComponent: EllipsisDropdown,
  states: [LISTING_UUID, STATUS, HEADERDETAILS_PUBLISHED],
});

export default withDispatch({
  WrappedComponent: withTdpDetails,
  actions: putStatusAsThunk,
  aliases: "onPutReferralStatusAsThunk",
});

EllipsisDropdown.propTypes = {
  uuid: string.isRequired,
  setRename: func.isRequired,
  status: string.isRequired,
  onPutReferralStatusAsThunk: func,
};

EllipsisDropdown.defaultProps = {
  onPutReferralStatusAsThunk: () => {},
};
