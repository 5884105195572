import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import TextComboInput from "@shared/v2/TextComboInput";
import { headline2 } from "@shared/styles";
import FormAgentOrPondSelect from "@shared/FormAgentOrPondSelect";
import FieldLabelV1 from "../../shared/UtilsV1/FieldLabelV1";
import Toggle from "../../shared/v2/Toggle";
import UploadPersonAvatarField from "../UploadPersonAvatarField";
import FieldSelect from "../../shared/UtilsV1/FieldSelect";
import { containerView } from "./styles";
import { setPersonValue, uploadAvatar } from "../../reducers/contactsReducer";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";

const INTENT_OPTIONS = [
  { label: "N/A", value: "n/a" },
  { label: "Buyer", value: "buyer" },
  { label: "Seller", value: "seller" },
];

function GeneralView({ modalType }) {
  const dispatch = useDispatch();
  const formOptions = useContactFormOptions();
  const person = useContactPerson();
  const stageOptions = useMemo(
    () => [...(formOptions?.leadStageOptions || []), ...(formOptions?.candidateStageOptions || [])],
    [formOptions],
  );

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (person.id) return;
    if (person.typeName === "lead") {
      dispatch(setPersonValue({ key: "stage", value: "New lead" }));
      dispatch(setPersonValue({ key: "stageType", value: "lead" }));
    } else {
      dispatch(setPersonValue({ key: "stage", value: "" }));
      dispatch(setPersonValue({ key: "stageType", value: "" }));
    }
  }, [person.typeName]);

  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>General</div>
      <div className="tw-flex tw-flex-wrap tw-gap-[24px]">
        <TextComboInput
          label="First Name"
          isRequired
          value={person.firstName}
          containerClassName="tw-flex-1 tw-min-w-[35%]"
          onChange={(e) => dispatch(setPersonValue({ key: "firstName", value: e.target.value }))}
          data-cy="first_name"
        />

        <TextComboInput
          label="Last Name"
          isRequired
          value={person.lastName}
          containerClassName="tw-flex-1 tw-min-w-[35%]"
          onChange={(e) => dispatch(setPersonValue({ key: "lastName", value: e.target.value }))}
          data-cy="last_name"
        />

        {modalType === "lead" && (
          <FieldSelect
            flexStyle="tw-flex-1 tw-min-w-[35%]"
            label="Person Type"
            options={formOptions?.typeNameOptions}
            defaultValue="lead"
            onChange={(value) => dispatch(setPersonValue({ key: "typeName", value }))}
            value={person.typeName}
          />
        )}

        <FieldSelect
          flexStyle="tw-flex-1 tw-min-w-[35%]"
          label="Source"
          placeholder="Select Source"
          value={person.sourceId}
          options={formOptions?.sourceOptions}
          onChange={(value) => dispatch(setPersonValue({ key: "sourceId", value }))}
          required={modalType === "lead"}
          isInputSearchable
        />

        {modalType === "lead" && person.typeName === "lead" && (
          <FieldSelect
            fieldSelect
            flexStyle="tw-flex-1 tw-min-w-[35%]"
            label="Stage"
            required
            options={stageOptions}
            defaultValue="New lead"
            onChange={(option) => {
              dispatch(setPersonValue({ key: "stage", value: option.value }));
              dispatch(setPersonValue({ key: "stageType", value: option.type }));
            }}
            value={person.stage}
            data-cy="stage_field"
          />
        )}

        {modalType === "lead" && (
          <FieldSelect
            flexStyle="tw-flex-1 tw-min-w-[35%]"
            label="Status"
            options={formOptions?.statusOptions}
            defaultValue="new"
            onChange={(value) => dispatch(setPersonValue({ key: "status", value }))}
            value={person.status}
          />
        )}

        <FieldSelect
          flexStyle="tw-flex-1 tw-min-w-[35%]"
          label="Intent"
          placeholder="N/A"
          defaultValue="n/a"
          options={modalType === "lead" ? formOptions?.leadTypeOptions : INTENT_OPTIONS}
          onChange={(value) => dispatch(setPersonValue({ key: "leadType", value }))}
          value={person.leadType}
        />
        {person.typeName === "lead" && <div className="tw-flex-1" />}
      </div>

      {modalType === "lead" && !person.id && (
        <div>
          <FieldLabelV1 label="Primary Agent" />
          <FormAgentOrPondSelect
            placeholder="Select an Agent or Pond"
            enableLeadPonds
            leadPond={person.leadPondId}
            primaryAgent={person.primaryAgentId}
            onChange={(params) => {
              dispatch(setPersonValue({ key: "leadPondId", value: params.lead_pond }));
              dispatch(setPersonValue({ key: "primaryAgentId", value: params.primary_agent }));
            }}
          />
        </div>
      )}

      {person.id && (
        <div className="tw-flex tw-gap-[24px]">
          <div className="tw-flex-1">
            <FieldLabelV1
              label="Profile Photo"
              toolTipInfoContent={
                <div className="tw-w-[267px] tw-text-left">
                  For an ideal experience on your Brivity website, please upload an image that is cropped
                  square and is under 1MB in size. 5MB limit.
                </div>
              }
              toolTipPlacement="top"
            />
            <UploadPersonAvatarField
              person={person}
              onUpdate={(file) => dispatch(uploadAvatar({ file, personId: person.id }))}
              onRemove={() => dispatch(setPersonValue({ key: "avatarUrl", value: null }))}
            />
          </div>
          <div className="tw-flex-1" />
        </div>
      )}
      <div className="tw-flex tw-gap-[12px] tw-flex-col">
        <Toggle
          id="ecgv_toggle"
          checked={typeof person.isLead === "undefined" ? modalType === "lead" : person.isLead}
          onChange={() => dispatch(setPersonValue({ key: "isLead", value: !person.isLead }))}
          text="Show in Leads Index"
        />
        {featureFlags.userUpdate && (
          // TODO: this is currently hardcoded, but probably shouldn't be?
          <>
            <Toggle
              id="webroster_toggle"
              checked
              onChange={() =>
                dispatch(
                  setPersonValue({
                    key: "loginUser.showOnWebsiteRoster",
                    value: !person.loginUserAttributes?.showOnWebsiteRoster,
                  }),
                )
              }
              text="Display on Website Roster"
            />
            <Toggle
              id="webprofile_toggle"
              checked
              onChange={() =>
                dispatch(
                  setPersonValue({
                    key: "loginUser.showAgentProfilePage",
                    value: !person.loginUserAttributes?.showAgentProfilePage,
                  }),
                )
              }
              text="Website Profile Page"
            />
          </>
        )}
      </div>
    </div>
  );
}

GeneralView.propTypes = { modalType: PropTypes.string.isRequired };

export default GeneralView;
