/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CalendarDaysSolidV6 = (props) => (
  <Icon {...props} name="Calendar Days Solid V6">
    <path d="M6 1v1h4V1a1 1 0 1 1 2 0v1h1.5A1.5 1.5 0 0 1 15 3.5V5H1V3.5A1.5 1.5 0 0 1 2.5 2H4V1a1 1 0 1 1 2 0zM1 6h14v8.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V6zm2 3.5a.5.5 0 0 0 .5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1a.5.5 0 0 0-.5.5v1zm4 0c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5v1zM11.5 8c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1zM3 13.5a.5.5 0 0 0 .5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1a.5.5 0 0 0-.5.5v1zM7.5 12c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1zm3.5 1.5c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5h-1c-.275 0-.5.225-.5.5v1z" />
  </Icon>
);

export default CalendarDaysSolidV6;
