const states = [
  {
    value: "AK",
    label: "US - AK",
  },
  {
    value: "AL",
    label: "US - AL",
  },
  {
    value: "AR",
    label: "US - AR",
  },
  {
    value: "AZ",
    label: "US - AZ",
  },
  {
    value: "CA",
    label: "US - CA",
  },
  {
    value: "CO",
    label: "US - CO",
  },
  {
    value: "CT",
    label: "US - CT",
  },
  {
    value: "DC",
    label: "US - DC",
  },
  {
    value: "DE",
    label: "US - DE",
  },
  {
    value: "FL",
    label: "US - FL",
  },
  {
    value: "GA",
    label: "US - GA",
  },
  {
    value: "HI",
    label: "US - HI",
  },
  {
    value: "IA",
    label: "US - IA",
  },
  {
    value: "ID",
    label: "US - ID",
  },
  {
    value: "IL",
    label: "US - IL",
  },
  {
    value: "IN",
    label: "US - IN",
  },
  {
    value: "KS",
    label: "US - KS",
  },
  {
    value: "KY",
    label: "US - KY",
  },
  {
    value: "LA",
    label: "US - LA",
  },
  {
    value: "MA",
    label: "US - MA",
  },
  {
    value: "MD",
    label: "US - MD",
  },
  {
    value: "ME",
    label: "US - ME",
  },
  {
    value: "MI",
    label: "US - MI",
  },
  {
    value: "MN",
    label: "US - MN",
  },
  {
    value: "MO",
    label: "US - MO",
  },
  {
    value: "MS",
    label: "US - MS",
  },
  {
    value: "MT",
    label: "US - MT",
  },
  {
    value: "NC",
    label: "US - NC",
  },
  {
    value: "ND",
    label: "US - ND",
  },
  {
    value: "NE",
    label: "US - NE",
  },
  {
    value: "NH",
    label: "US - NH",
  },
  {
    value: "NJ",
    label: "US - NJ",
  },
  {
    value: "NM",
    label: "US - NM",
  },
  {
    value: "NV",
    label: "US - NV",
  },
  {
    value: "NY",
    label: "US - NY",
  },
  {
    value: "OH",
    label: "US - OH",
  },
  {
    value: "OK",
    label: "US - OK",
  },
  {
    value: "OR",
    label: "US - OR",
  },
  {
    value: "PA",
    label: "US - PA",
  },
  {
    value: "RI",
    label: "US - RI",
  },
  {
    value: "SC",
    label: "US - SC",
  },
  {
    value: "SD",
    label: "US - SD",
  },
  {
    value: "TN",
    label: "US - TN",
  },
  {
    value: "TX",
    label: "US - TX",
  },
  {
    value: "UT",
    label: "US - UT",
  },
  {
    value: "VA",
    label: "US - VA",
  },
  {
    value: "VT",
    label: "US - VT",
  },
  {
    value: "WA",
    label: "US - WA",
  },
  {
    value: "WI",
    label: "US - WI",
  },
  {
    value: "WV",
    label: "US - WV",
  },
  {
    value: "WY",
    label: "US - WY",
  },
  {
    value: "AB",
    label: "CAN - AB",
  },
  {
    value: "BC",
    label: "CAN - BC",
  },
  {
    value: "MB",
    label: "CAN - MB",
  },
  {
    value: "NB",
    label: "CAN - NB",
  },
  {
    value: "NL",
    label: "CAN - NL",
  },
  {
    value: "NS",
    label: "  CAN - NS",
  },
  {
    value: "NF",
    label: "CAN - NF",
  },
  {
    value: "NT",
    label: "CAN - NT",
  },

  {
    value: "NU",
    label: "CAN - NU",
  },
  {
    value: "ON",
    label: "CAN - ON",
  },
  {
    value: "PE",
    label: "CAN - PE",
  },
  {
    value: "QC",
    label: "CAN - QC",
  },
  {
    value: "SK",
    label: "CAN - SK",
  },
  {
    value: "YT",
    label: "CAN - YT",
  },
];

export default states;
