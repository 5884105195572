const FINANCIALS_UPDATE_TYPE = {
  UPDATE_TRANSACTION_CLOSE_PRICE: "UPDATE_TRANSACTION_CLOSE_PRICE",
  UPDATE_TRANSACTION_COMMISSION: "UPDATE_TRANSACTION_COMMISSION",
  UPDATE_TRANSACTION_COMMISSION_VALUE: "UPDATE_TRANSACTION_COMMISSION_VALUE",
  ADD_TRANSACTION_LINE_ITEM: "ADD_TRANSACTION_LINE_ITEM",
  UPDATE_TRANSACTION_LINE_ITEM: "UPDATE_TRANSACTION_LINE_ITEM",
  UPDATE_TRANSACTION_LINE_ITEM_TYPE: "UPDATE_TRANSACTION_LINE_ITEM_TYPE",
  UPDATE_TRANSACTION_LINE_ITEM_VALUE: "UPDATE_TRANSACTION_LINE_ITEM_VALUE",
  UPDATE_TRANSACTION_LINE_ITEM_POSITION: "UPDATE_TRANSACTION_LINE_ITEM_POSITION",
  REMOVE_TRANSACTION_LINE_ITEM: "REMOVE_TRANSACTION_LINE_ITEM",
  UPDATE_TEAM_MEMBER_GCI: "UPDATE_TEAM_MEMBER_GCI",
  UPDATE_TEAM_MEMBER_GCI_VALUE: "UPDATE_TEAM_MEMBER_GCI_VALUE",
  UPDATE_TEAM_MEMBER_UNITS: "UPDATE_TEAM_MEMBER_UNITS",
  ADD_TEAM_MEMBER_LINE_ITEM: "ADD_TEAM_MEMBER_LINE_ITEM",
  UPDATE_TEAM_MEMBER_LINE_ITEM: "UPDATE_TEAM_MEMBER_LINE_ITEM",
  UPDATE_TEAM_MEMBER_LINE_ITEM_TYPE: "UPDATE_TEAM_MEMBER_LINE_ITEM_TYPE",
  UPDATE_TEAM_MEMBER_LINE_ITEM_VALUE: "UPDATE_TEAM_MEMBER_LINE_ITEM_VALUE",
  UPDATE_TEAM_MEMBER_LINE_ITEM_POSITION: "UPDATE_TEAM_MEMBER_LINE_ITEM_POSITION",
  REMOVE_TEAM_MEMBER_LINE_ITEM: "REMOVE_TEAM_MEMBER_LINE_ITEM",
  UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT: "UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT",
  UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT_VALUE: "UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT_VALUE",
  UPDATE_TEAM_MEMBER_ROYALTY: "UPDATE_TEAM_MEMBER_ROYALTY",
  UPDATE_TEAM_MEMBER_ROYALTY_VALUE: "UPDATE_TEAM_MEMBER_ROYALTY_VALUE",
  ADD_TEAM_MEMBERS: "ADD_TEAM_MEMBERS",
  UPDATE_TEAM_MEMBER_LINE_ITEM_ACC: "UPDATE_TEAM_MEMBER_LINE_ITEM_ACC",
  UPDATE_COMPANY_LINE_ITEM_POSITION: "UPDATE_COMPANY_LINE_ITEM_POSITION",
  UPDATE_COMPANY_LINE_ITEM_TYPE: "UPDATE_COMPANY_LINE_ITEM_TYPE",
  UPDATE_COMPANY_LINE_ITEM_VALUE: "UPDATE_COMPANY_LINE_ITEM_VALUE",
  ADD_COMPANY_LINE_ITEM: "ADD_COMPANY_LINE_ITEM",
  UPDATE_COMPANY_LINE_ITEM: "UPDATE_COMPANY_LINE_ITEM",
  REMOVE_COMPANY_LINE_ITEM: "REMOVE_COMPANY_LINE_ITEM",
  UPDATE_COMPANY_GCI: "UPDATE_COMPANY_GCI",
  UPDATE_COMPANY_GCI_VALUE: "UPDATE_COMPANY_GCI_VALUE",
};

export default FINANCIALS_UPDATE_TYPE;
