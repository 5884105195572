import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const useIsEditable = () => {
  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);

  const isEditable = useMemo(() => {
    const isUser =
      person.personType === "Agent" ||
      person.personType === "Administrator" ||
      person.personType === "Account Owner";
    const isSelf = String(currentUser.person.id) === person.data?.id;
    return currentUser.account.isAdmin || isSelf || !isUser;
  }, [currentUser, person.personType, person.data?.id]);

  return isEditable;
};

export default useIsEditable;
