import React from "react";

const ViewToggle = ({ onClick, isActive, iconClassName, mxOverride }) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-center tw-cursor-pointer ${mxOverride || "tw-mx-8px"} tw-w-32px tw-h-32px ${
        isActive ? "tw-bg-blue-10" : "tw-bg-gray-10"
      } tw-rounded-full`}
      onClick={onClick}
    >
      <i className={`tw-flex fa ${iconClassName} ${isActive ? "tw-text-teal" : "tw-text-gray-75"}`}></i>
    </div>
  );
};

export default ViewToggle;
