/* eslint-disable react/prop-types */

import React from "react";

const TriangleDownIcon = ({ className = "", fillColor = "#999999" }) => (
  <svg
    width="8px"
    height="5px"
    viewBox="0 0 8 5"
    version="1.1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>Triangle Down Icon</title>
    <path
      d="M0.672857 0H7.32714C7.92547 0 8.22463 0.775217 7.80245 1.22718L4.47531 4.78899C4.21249 5.07034 3.78751 5.07034 3.52749 4.78899L0.197551 1.22718C-0.224633 0.775217 0.0745303 0 0.672857 0Z"
      fill={fillColor}
    />
  </svg>
);

export default TriangleDownIcon;
