import React from "react";
import Alert from "./v2/Alert";
import TextLink from "./v2/TextLink";

const EmailError = () => {
  return (
    <Alert
      variant="error"
      title="Error"
      text={
        <>
          <span>There has been an issue sending your email. Please reconnect your Gmail account on the</span>{" "}
          <TextLink target="_blank" href="/account/personal">
            Personal Settings Page
          </TextLink>
          . <span>Instructions can be found</span>{" "}
          <TextLink
            target="_blank"
            href="https://www.brivityknowledge.com/docs/connect-gmail-to-brivity-crm-2?highlight=gmail"
          >
            here
          </TextLink>
          .{" "}
          <span>
            Be sure to copy your email draft before navigating away from this screen as any changes will be
            lost.
          </span>
        </>
      }
    />
  );
};

export default EmailError;
