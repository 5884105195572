/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const DesktopRegularV6 = (props) => (
  <Icon {...props} name="Desktop Regular V6">
    <path d="M14.222.889H1.778C.796.889 0 1.685 0 2.667v8c0 .982.796 1.778 1.778 1.778h4.119l-.267 1.333H4.222c-.367 0-.667.3-.667.667s.3.667.667.667h7.556a.667.667 0 0 0 0-1.334h-1.409l-.266-1.333h4.119c.982 0 1.778-.796 1.778-1.778v-8c0-.982-.797-1.778-1.778-1.778zM9.008 13.778H6.991l.267-1.333h1.485l.265 1.333zm5.659-3.111a.444.444 0 0 1-.444.444H1.779a.444.444 0 0 1-.444-.444V9.334h13.333v1.333zm0-2.667H1.334V2.667c0-.245.199-.444.444-.444h12.444c.245 0 .444.199.444.444V8z" />
  </Icon>
);

export default DesktopRegularV6;
