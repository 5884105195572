/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { find } from "lodash";
import { produce } from "immer";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/Button";
import { ChevronLeftSolidV6 } from "@shared/v2/Icomoon";
import Dropdown from "@shared/v2/Dropdown";
import RadioButton from "@shared/v2/RadioButton";
import { FormGroup, Row, Col, Button } from "../../shared/v1";
import AutoPlanNameForm from "./AutoPlanNameForm";
import Actions from "./Actions";
import SpecificDates from "./SpecificDates";
import AutoPauseOnCondition from "./AutoPauseOnCondition";
import TaskActionModal from "./TaskActionModal";
import {
  peopleAutoPauseStatusOptions,
  peopleCompletionStatusOptions,
  transactionAutoPauseStatusOptions,
  transactionCompletionStatusOptions,
  // TODO Work on this
  // validateTaskForm
} from "./helpers";
import "./AutoPlan.scss";
import { useIsTaskActionModalOpen, useIsEmailActionModalOpen, useIsTextActionModalOpen, useActionForm, useIsSubmitting } from "../../reducers/autoPlanEdit";
import { toggleTaskActionModal, toggleEmailActionModal, toggleTextActionModal, setPeopleGroups } from "../actions/autoPlansActionCreators";
import EmailActionModal from "./EmailActionModal";
import TextActionModal from "./TextActionModal";
import { useActionFormSubmitHandler } from "../hooks";
import { checkIfDateStringIsWithinRange } from "../helpers";
import { submitActionFailure } from "../actions/autoPlanActionCreators";

const TEXT_EXPIRED = {
  people: "Auto pause plan when lead replies via text?",
  transactions: "Auto pause when transaction expires.",
};
const STATUS_CHANGE = {
  people: "Auto pause plan when lead's status changes?",
  transactions: "Auto pause when transaction's status changes?",
};

const AUTO_PAUSE_ON_COMPLETE = {
  people: "Auto change lead's status when the plan is completed to:",
  transactions: "Auto change transaction's status when the plan is completed to:",
};

const autoPauseReplyOrExpireNames = {
  people: "auto_pause_on_reply",
  transactions: "auto_pause_on_expired",
};

const filterGroupOptionsByPlanTypes = (groups, planTypes) =>
  groups.options.map(({ label, options }) => ({
    label,
    options: options.filter((op) => planTypes.includes(op.for_type)),
  }));

const AutoPlanEdit = ({
  action_path: actionPath,
  assigned_for_options: assignedForOptions,
  assigned_to_options: assignedToOptions,
  auto_plan_id: autoPlanId,
  auto_plan_path: autoPlanPath,
  autoPlan,
  autoPlanForm,
  autoPlanPreferencesForm,
  email_templates: emailTemplates,
  getAutoPlan,
  handleAutoPlanChange,
  isEditingName,
  isEditingPreferences,
  isFetching,
  milestones,
  people_placeholders: peoplePlaceholders,
  resetAutoPlanPreferencesForm,
  saveAutoPlanName,
  saveAutoPlanPreferences,
  toggleEdit,
  transaction_placeholders: transactionPlaceholders,
  updateAutoPlanPreferencesForm,
}) => {
  useEffect(() => {
    getAutoPlan(autoPlanPath);
  }, [autoPlanPath, getAutoPlan]);

  const completionStatusOptions = autoPlan.plan_type === "people"
    ? peopleCompletionStatusOptions
    : transactionCompletionStatusOptions;

  const autoPauseStatusOptions = autoPlan.plan_type === "people"
    ? peopleAutoPauseStatusOptions
    : transactionAutoPauseStatusOptions;

  const planType = autoPlan.plan_type;
  const validPlanTypes = ["all", planType];
  const filteredAssignedToOptions = filterGroupOptionsByPlanTypes(assignedToOptions, validPlanTypes);
  const filteredAssignedForOptions = filterGroupOptionsByPlanTypes(assignedForOptions, validPlanTypes);

  const filteredMilestoneOptions = milestones.filter((op) => op.for_plan_type === planType);
  const autoPauseReplyOrExpireName = autoPauseReplyOrExpireNames[planType];

  const placeholders = planType === "people" ? peoplePlaceholders : transactionPlaceholders;
  const dispatch = useDispatch();
  const isEmailActionModalOpen = useIsEmailActionModalOpen();
  const isTextActionModalOpen = useIsTextActionModalOpen();
  const isSubmitting = useIsSubmitting();
  const isTaskActionModalOpen = useIsTaskActionModalOpen();
  const actionForm = useActionForm();
  const actionFormSubmitHandlr = useActionFormSubmitHandler(autoPlanId);


  useEffect(() => {
    const [generalRoles, teamRoles, userId] = assignedToOptions.options;

    dispatch(setPeopleGroups(generalRoles, teamRoles, userId))
  }, [])
  
  return (
    <>
      <TaskActionModal
        assignedToOptions={filteredAssignedToOptions}
        show={isTaskActionModalOpen}
        autoPlanId={autoPlanId}
        onHide={() => dispatch(toggleTaskActionModal(false))}
        form={actionForm}
        isSubmitting = {isSubmitting}
        onSave={(form) => {
          const yearErrorMsg = checkIfDateStringIsWithinRange(form.day_month_year);
          if(yearErrorMsg){
            dispatch(submitActionFailure(yearErrorMsg));
            return;
          }
          actionFormSubmitHandlr(form)
        }}/>
      <EmailActionModal
        show={isEmailActionModalOpen}
        emailTemplates={emailTemplates}
        autoPlanId={autoPlanId}
        assignedToOptions={filteredAssignedToOptions}
        isSubmitting = {isSubmitting}
        onHide={() => dispatch(toggleEmailActionModal(false))}
        form={actionForm}
        placeholders={placeholders}
        onSave={(form) => {
          const yearErrorMsg = checkIfDateStringIsWithinRange(form.day_month_year);
          
          if(yearErrorMsg){
            dispatch(submitActionFailure(yearErrorMsg));
            return;
          }          
          const adaptedForm = produce(form, (draft) => {
            draft.bcc_emails = draft.bcc_emails.map(el => el.value);
            draft.bcc_people_uuids = draft.bcc_people_uuids.map(el => el.value);
            draft.bcc_role_uuids = draft.bcc_role_uuids.map(el => el.value);
            draft.cc_emails = draft.cc_emails.map(el => el.value);
            draft.cc_people_uuids = draft.cc_people_uuids.map(el => el.value);
            draft.cc_role_uuids = draft.cc_role_uuids.map(el => el.value);
          });

          actionFormSubmitHandlr(adaptedForm)
        }}/>
      <TextActionModal
        show={isTextActionModalOpen}
        onHide={() => dispatch(toggleTextActionModal(false))}
        placeholders={placeholders}
        isSubmitting = {isSubmitting}
        form={actionForm}
        assignedToOptions={filteredAssignedToOptions}
        autoPlanId={autoPlanId}
        onSave={(form) => {
          const yearErrorMsg = checkIfDateStringIsWithinRange(form.day_month_year);
          
          if(yearErrorMsg){
            dispatch(submitActionFailure(yearErrorMsg));
            return;
          }         
          actionFormSubmitHandlr(form)
        }}/>
      <div className="tw-bg-tinted-gray-50 tw-min-h-[100vh]">
        <div className="tw-bg-[#fff]">
          <Row className="tw-max-w-[1200px] tw-mx-auto tw-py-[24px]">
            <Col size={4}>
              <section>
                <TextButton schema='tertiary' className='tw-p-8px tw-color-gray-base'>
                  <a href={`/auto_plans?plan_type=${autoPlan.plan_type}`}  className='hover:tw-no-underline'>
                    <ChevronLeftSolidV6 size='s' className='tw-mr-[4px]'/>
                    <span>Back</span>
                  </a>
                </TextButton>
                <Row>
                  {autoPlan && (
                    <AutoPlanNameForm
                      handleNameChange={handleAutoPlanChange}
                      isEditingName={isEditingName}
                      toggleEdit={toggleEdit}
                      updateName={() => saveAutoPlanName(autoPlanPath, autoPlanForm)}
                      name={autoPlanForm.name}
                    />
                  )}
                </Row>
                <Row className='tw-mt-[26px]'>
                  <Col size={12}>
                    <label className='tw-text-12d tw-text-gray-50 tw-mb-[8px]'>Type:</label>
                    {autoPlan && <div className="action-plan-type-header tw-capitalize tw-text-14d">{autoPlan.plan_type} Plan</div>}
                  </Col>
                </Row>
              </section>
            </Col>
            <Col size={4}>
              <section>
                  <Row>
                    <label className="tw-mb-[12px]">{TEXT_EXPIRED[autoPlan.plan_type]}</label>
                    {autoPauseReplyOrExpireName && (
                      <AutoPauseOnCondition
                        name={autoPauseReplyOrExpireName}
                        checkedValue={
                          autoPlanPreferencesForm && autoPlanPreferencesForm[autoPauseReplyOrExpireName]
                        }
                        handleUpdate={updateAutoPlanPreferencesForm}
                        disabled={isFetching}
                      />
                    )}
                  </Row>
                  <Row>
                    <Dropdown
                      label={AUTO_PAUSE_ON_COMPLETE[autoPlan.plan_type]}
                      labelClassName='!tw-text-12d !tw-font-normal tw-mb-[12px]'
                      name="status_on_complete"
                      className='tw-w-[174px] tw-cursor-pointer'
                      value={find(completionStatusOptions, { 'value': autoPlanPreferencesForm?.status_on_complete })}
                      options={completionStatusOptions}
                      isClearable={false}
                      isSearchable={false}
                      isDisabled={isFetching}
                      onChange={(opt) => updateAutoPlanPreferencesForm("status_on_complete", opt.value)}
                    />
                  </Row>
              </section>
            </Col>
            <Col size={4}>
              <Row>
                <label className='tw-mb-[12px]'>{STATUS_CHANGE[autoPlan.plan_type]}</label>
                <FormGroup>
                  <Row>
                    <Col size={12}>
                      <div className="d-flex flex-items-center">
                        <RadioButton
                          value='any'
                          name="auto_pause_for_status"
                          checked={
                            autoPlanPreferencesForm &&
                            autoPlanPreferencesForm.auto_pause_for_status != null &&
                            autoPlanPreferencesForm.auto_pause_for_status.length > 0
                          }
                          onClick={(e) => updateAutoPlanPreferencesForm(e.target.name, e.target.value)}
                          labelClassName="tw-text-gray-base tw-text-[14px]"
                          containerClassName='tw-mr-[12px] tw-cursor-pointer'
                          label='Yes, To'
                        />                      
                        <Dropdown
                          name="auto_pause_for_status"
                          className='tw-w-[175px] tw-ml-10px tw-cursor-pointer'
                          value={autoPauseStatusOptions && find(autoPauseStatusOptions, { 'value': autoPlanPreferencesForm.auto_pause_for_status })}
                          options={autoPauseStatusOptions}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={
                            isFetching ||
                            !autoPlanPreferencesForm ||
                            !autoPlanPreferencesForm.auto_pause_for_status
                          }
                          onChange={(opt) => updateAutoPlanPreferencesForm("auto_pause_for_status", opt.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <RadioButton
                      value=''
                      name="auto_pause_for_status"
                      checked={
                        !autoPlanPreferencesForm || !autoPlanPreferencesForm.auto_pause_for_status
                      }
                      onClick={(e) => updateAutoPlanPreferencesForm(e.target.name, e.target.value)}
                      labelClassName="tw-text-gray-base tw-text-[14px]"
                      containerClassName='tw-mr-[12px] tw-cursor-pointer'
                      label='No'
                    />                    
                  </div>
                </FormGroup>
              </Row>
              <Row>
                {isEditingPreferences && (
                  <div>
                    <Button
                      variant="info"
                      onClick={() => saveAutoPlanPreferences(autoPlanPath, autoPlanPreferencesForm)}
                      className="m-r-10"
                    >
                      Save
                    </Button>
                    <Button variant="info" invert onClick={resetAutoPlanPreferencesForm}>
                      Cancel
                    </Button>
                  </div>
                )}
              </Row>
            </Col>
          </Row>      
        </div>
        <div>      
          <Row
            className="pull-content-to-side-menu tw-max-w-[1200px] tw-mx-auto"
            deprecatedOverrideMarginClasses="tw--mx-20px tw-mt-[24px]"
          >
            <section>
              <div className="brivity-panel tw-border tw-border-solid tw-border-gray-10 tw-rounded-[8px]">
                <div className="!tw-text-gray-50 !tw-text-18d !tw-px-[16px] !tw-py-[12px] tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-gray-10">Actions</div>
                <div className="tw-p-[32px]">
                  <SpecificDates/>
                  <Row className='tw-mt-[32px]'>
                    <Col size={12}>
                      {autoPlan && 
                        <Actions
                          planType={autoPlan.type}
                          actions={autoPlan.actions}
                          autoPlanStartingTime={autoPlan.starting_time}
                          auto_plan_id={autoPlanId}
                          action_path={actionPath}
                          milestoneOptions={filteredMilestoneOptions}
                          assignedToOptions={filteredAssignedToOptions}
                          assignedForOptions={filteredAssignedForOptions}
                          autoPlanPath={autoPlanPath}
                          email_templates={emailTemplates}
                          placeholders={placeholders}
                        />
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          </Row>
        </div>
      </div>
    </>
  );
};

AutoPlanEdit.propTypes = {
  action_path: PropTypes.string.isRequired,
  assigned_for_options: PropTypes.arrayOf(PropTypes.any).isRequired,
  assigned_to_options: PropTypes.arrayOf(PropTypes.any).isRequired,
  auto_plan_id: PropTypes.string.isRequired,
  auto_plan_path: PropTypes.string.isRequired,
  autoPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  autoPlanForm: PropTypes.objectOf(PropTypes.any).isRequired,
  autoPlanPreferencesForm: PropTypes.objectOf(PropTypes.any).isRequired,
  email_templates: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAutoPlan: PropTypes.func.isRequired,
  handleAutoPlanChange: PropTypes.func.isRequired,
  isEditingName: PropTypes.func.isRequired,
  isEditingPreferences: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  milestones: PropTypes.arrayOf(PropTypes.any).isRequired,
  people_placeholders: PropTypes.arrayOf(PropTypes.any).isRequired,
  resetAutoPlanPreferencesForm: PropTypes.func.isRequired,
  saveAutoPlanName: PropTypes.func.isRequired,
  saveAutoPlanPreferences: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  transaction_placeholders: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateAutoPlanPreferencesForm: PropTypes.func.isRequired,
};

export default AutoPlanEdit;
