import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import { clearErrors, deleteCategory, deleteTag } from "../../reducers/websiteReducer/websiteReducer";
import usePostCountForCategoryTag from "../hooks/usePostCountForCategoryTag";

const DeleteCategoryTagModal = ({ open, onClose, value: propValue }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(propValue);
  const type = Object.prototype.hasOwnProperty.call(value, "tag") ? "tag" : "category";
  const typeName = type === "tag" ? "Tag" : "Category";

  const count = usePostCountForCategoryTag({ type, uuid: value?.uuid });

  useEffect(() => {
    setValue(propValue);
    if (!open) dispatch(clearErrors());
  }, [open, propValue]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[440px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title={`Delete ${typeName}?`} closeButton />

      <Modal.Body className="tw-py-[8px] tw-flex tw-flex-col tw-gap-[32px] tw-overflow-auto">
        Are you sure you want to delete this category? It will be removed from ALL {count} posts that have
        been assigned this category.
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button schema="tertiary" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          schema="warning"
          size="medium"
          onClick={() => {
            const action = type === "tag" ? deleteTag : deleteCategory;
            dispatch(action(value)).then(({ error }) => !error && onClose());
          }}
          disabled={!value?.[type]?.trim()}
        >
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteCategoryTagModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.shape(),
};

DeleteCategoryTagModal.defaultProps = {
  value: {},
};

export default DeleteCategoryTagModal;
