import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TagV1 from "./TagV1";
import FieldLabelV1 from "./FieldLabelV1";
import styleSchema from "./FieldStyle";
import { CaretDown, CaretUp } from "../v2/Icomoon";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function FieldSelectLabelsWithCheckboxOptions({
  placeholder,
  options,
  value,
  onChange,
  onRemove,
  className,
  disabled,
  label,
  required,
  flexStyle,
}) {
  let schemaKey = disabled ? "disabled" : "regular";

  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

  const ref = useRef();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [checkboxOptions, setCheckboxOptions] = useState(
    options.map((option) => {
      return {
        value: option,
        checked: !!value?.includes(option),
      };
    }),
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const temp = checkboxOptions.reduce((newResults, option) => {
      if (option.checked) {
        if (newResults.length <= 0) return [option.value];
        return [...newResults, option.value];
      } else {
        return newResults;
      }
    }, []);

    if (!!temp) {
      onChange(temp);
    } else {
      onChange([]);
    }
  }, [checkboxOptions]);

  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required }} />}
      <div className={`tw-flex tw-text-sm tw-items-center tw-relative ${className}`} ref={ref}>
        <input
          type="text"
          className={classes}
          placeholder={placeholder}
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          readOnly
        />

        <div className="tw-text-neutral-gray-75 tw-absolute tw-right-[12px] tw-top-[9px]">
          {isDropdownOpen ? <CaretUp /> : <CaretDown />}
        </div>

        {isDropdownOpen && (
          <div className="dropdown-content tw-border tw-border-solid tw-rounded tw-text-sm tw-whitespace-nowrap tw-absolute tw-z-10 tw-bg-white tw-min-w-full tw-top-[36px] tw-max-h-[256px] tw-overflow-y-auto">
            {checkboxOptions.length <= 0 ? (
              <p className="tw-px-[14px] tw-py-1">no matches</p>
            ) : (
              checkboxOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="dropdown-item hover:tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1 tw-flex tw-items-center tw-gap-[8px]"
                    onClick={() => {
                      setCheckboxOptions((prev) => {
                        prev[index].checked = !prev[index].checked;

                        return [...prev];
                      });
                    }}
                    data-cy={`${option.value}_checkbox`}
                  >
                    <input type="checkbox" checked={option.checked} readOnly />
                    <div>{option.value}</div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      {
        //labels group
      }
      {value && value.length >= 1 && (
        <div className="tw-flex tw-gap-1 tw-flex-wrap tw-mt-3">
          {value.map((label, index) => {
            return (
              <TagV1
                text={label}
                key={label + index}
                onRemove={() => {
                  setCheckboxOptions((prev) => {
                    prev.find((option) => option.value === label).checked = false;

                    return prev;
                  });
                  onRemove(label);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

FieldSelectLabelsWithCheckboxOptions.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

FieldSelectLabelsWithCheckboxOptions.defaultProps = {
  placeholder: "Select",
  label: "",
  options: ["Option1", "Option2"],
  value: [],
  required: false,
  flexStyle: "tw-flex-1",
  className: "",
  disabled: false,
  onChange: () => {},
  onRemove: () => {},
};

export default FieldSelectLabelsWithCheckboxOptions;
