import React from "react";
import { Button, FormGroup, Col, Form, Modal } from "react-bootstrap";
import Input from "../../../shared/Input";
import Error from "../../../shared/Error";

class CollaboratorExpenseModal extends React.Component {
  state = {
    name: "",
    value: "",
    percentage: false,
    expense_amount: "",
    position: 1,
    isNotValid: false,
    errMsg: ""
  }

  saveExpenseData = () => {
    if (this.state.name && this.state.value) {
      let collaboratorExpense = this.props.listingData.collaborator_expenses.expenses[this.props.collaboratorModalIndex]

      collaboratorExpense.custom_collaborator_expenses_attributes.push(this.state)
      this.props.setFinancialData(this.props.listingData)
      this.props.setIsCollaboratorExpenseModalOpen(false)
      this.props.recalculateCollaboratorExpenses(collaboratorExpense, this.props.listingData)
    } else {
      this.setState({ errMsg: "Enter a name and value.", isNotValid: true })
    }
  }

  render() {
    const {
      isCollaboratorExpenseModalOpen,
      setIsCollaboratorExpenseModalOpen
    } = this.props

    return (
      <Modal
        show={isCollaboratorExpenseModalOpen}
        onHide={() => setIsCollaboratorExpenseModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            New Collaborator Expense
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="transaction-gci-content" style={{ padding: "0 15px" }}>
          <div className="transaction-gci-options transaction-modal">
            {this.state.isNotValid && <Error errorMessage={this.state.errMsg} />}
            <Form horizontal>
              <FormGroup>
                <Col xs={12}>
                  <label>Name</label>
                  <div>
                    <Input
                      value={this.state.name}
                      onChange={e => {this.setState({ name: e.target.value })}} />
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="flat-input transaction-input">
                    <Input
                      value={this.state.value}
                      onChange={(e) => this.setState({ value: e.target.value })}
                    />
                    <span>$</span>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col xs={12}>
                  <Button
                    bsSize="small"
                    className="modal-cancel"
                    onClick={() => setIsCollaboratorExpenseModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    bsSize="small"
                    className="modal-submit pull-right"
                    onClick={() => this.saveExpenseData()}
                  >
                    Save
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default CollaboratorExpenseModal;
