import React, { useState } from "react";
import PropTypes from "prop-types";
import { Upload, User } from "../v2/Icomoon";
import Avatar from "../v2/Avatar";

function UserImageButton({ imgUrl, firstName, lastName, onClick }) {
  const [isOnHover, setIsOnHover] = useState(false);
  return (
    <button
      onMouseOver={() => setIsOnHover(true)}
      onMouseOut={() => setIsOnHover(false)}
      onClick={onClick}
      className={`tw-w-[96px] tw-h-[96px] tw-rounded-full tw-bg-neutral-gray-10 tw-text-neutral-gray-75 tw-border-none tw-flex tw-justify-center tw-items-center tw-overflow-hidden hover:tw-bg-white hover:tw-text-black hover:tw-border hover:tw-border-solid hover:tw-border-black active:tw-bg-black active:tw-text-white`}
    >
      {imgUrl && !imgUrl.includes("user-default.svg") ? (
        <img src={imgUrl} alt="avatar img" width="100%" height="100%" />
      ) : (
        <>
          {isOnHover ? (
            <Upload size="xl" />
          ) : !!firstName && !!lastName ? (
            <Avatar size="large" className="tw-text-28px">
              {firstName + " " + lastName}
            </Avatar>
          ) : (
            <User size="xl" />
          )}
        </>
      )}
    </button>
  );
}

UserImageButton.propTypes = {
  imgUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userName: PropTypes.string,
  onClick: PropTypes.func,
};

UserImageButton.defaultProps = {
  firstName: "",
  lastName: "",
  imgUrl: "",
  userName: "",
  onClick: () => {},
};

export default UserImageButton;
