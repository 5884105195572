import React, {Component} from 'react';
import Pluralize from 'react-pluralize'
import DatePicker from '../../shared/DatePicker';
import { Button, Col, Modal, Row } from '../../shared/v1';

const default_agents = [{label: 'Anyone', value: 'anyone'}]

export default class RescheduleModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.props
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, ...nextProps})
  }

  render() {
    return(
      <Modal
        size={'small'}
        show={this.state.showRescheduleModal}
        onHide={this.state.closeRescheduleModalHandler}>
        <Modal.Header closeButton>
          <Modal.Title>
            Hey! You're about to reschedule <Pluralize singular={'task'} count={this.state.selectedTasks.length}/> tasks!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col size={12}>
              <p>
                Don't worry, your client doesn't see when you reschedule something. Select how far you want to move these dates around.
              </p>
            </Col>
          </Row>
          <Row>
            <Col size={6}>
              <DatePicker
                timeFormat={false}
                dateFormat='MM-DD-YYYY'
                name={'reschedule_date'}
                value={this.state.rescheduleDate}
                onChange={this.state.rescheduleDateHandler}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            className="modal-cancel tw-float-left"
            onClick={this.state.closeRescheduleModalHandler}>
            Cancel
          </Button>
          <Button
            size="small"
            className="modal-submit tw-float-right"
            onClick={this.state.rescheduleTasksHandler}>
            Reschedule
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
