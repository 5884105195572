import React, { Component } from "react";
import { produce } from "immer";
import { Dropdown } from "../../shared/v1";
import css from "./person-detail-styles.module.css";
import form from "./form.module.css";
import axios from "axios";
import { updatePersonPromise } from "../actions/updatePerson";
import PhoneValue from "./PhoneValue";
import { viewPhoneFromFastJsonApi } from "@shared/models/Communications/viewPhone";
import IconButton from "../../shared/v2/IconButton";
import {
  CaretDownSolidV6,
  PencilSolidV6,
  PlusSolidV6,
  SpinnerSolidV6,
  TrashSolidV6,
  XmarkSolidV6,
} from "../../shared/v2/Icomoon";
import Button from "../../shared/v2/Button";
import TextButton from "../../shared/v2/TextButton";
import PhoneNumberModal from "../../Contacts/PhoneNumberModal";
import Tooltip from "@shared/v2/Tooltip";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import PersonDetailError from "./PersonDetailError";

const adjustSmsStatus = (phone, newStatus) => {
  return produce((draft) => {
    if (newStatus === "unsubscribed") {
      draft.attributes.sms_soft_unsubscribed = true;
    } else if (newStatus === "voiceOnly") {
      draft.attributes.sms_soft_unsubscribed = false;
      draft.attributes.sms_textable = false;
    } else if (newStatus === "valid") {
      draft.attributes.sms_soft_unsubscribed = false;
      draft.attributes.sms_textable = true;
    }
  }, phone);
};

const adjustCallStatus = (phone, newStatus) => {
  return produce((draft) => {
    if (newStatus === "unsubscribed") {
      draft.attributes.call_soft_unsubscribed = true;
    } else if (newStatus === "valid") {
      draft.attributes.call_soft_unsubscribed = false;
      draft.attributes.call_callable = true;
    }
  }, phone);
};

class EditableElementWithAdd extends Component {
  state = {
    editingElement: false,
    addingElement: false,
    addingValue: "",
    addingType: null,
    editablePerson: JSON.parse(JSON.stringify(this.props.person)),
    errors: [],
    errorType: null,
    hover: false,
    saving: false,
    showPhoneContext: null,
  };

  elementIndex = () => {
    return this.props.person.included.findIndex((element) => element["attributes"]["id"] === this.props.id);
  };

  element = () => {
    return this.props.person.included[this.elementIndex()];
  };

  editableElement = () => {
    return this.state.editablePerson.included[this.elementIndex()];
  };

  type = () => {
    return this.props.person.included[this.elementIndex()]?.["type"];
  };

  isPrimary = () => {
    switch (this.type()) {
      case "email_detail":
        return this.element().attributes.value === this.props.person.data.attributes.main_email;
      case "phone_detail":
        return this.element().attributes.value === this.props.person.data.attributes.primary_phone_number;
      default:
        return false;
    }
  };

  elementCategory = () => {
    switch (this.type()) {
      case "email_detail":
        return "Email";
      case "phone_detail":
        return "Phone";
      default:
        return undefined;
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  labels = (includeAll) => {
    switch (this.props.type) {
      case "email":
        let email_types = [
          { label: "Personal", value: "personal" },
          { label: "Work", value: "work" },
        ];
        if (includeAll !== true) {
          let existing_emails =
            this.props.person.included
              ?.filter((a) => a.type === "email_detail" && a.id !== null)
              .map((a) => a.attributes) || [];
          existing_emails.forEach((e) => {
            email_types = email_types.filter((t) => t.value !== e.category);
          });
        }
        email_types.push({ label: "Other", value: "other" });
        return email_types;
      case "phone":
        let phone_types = [
          { label: "Mobile", value: "mobile" },
          { label: "Home", value: "home" },
          { label: "Work", value: "work" },
        ];
        if (includeAll !== true) {
          let existing_phones =
            this.props.person.included
              ?.filter((a) => a.type === "phone_detail" && a.id !== null)
              .map((a) => a.attributes) || [];
          existing_phones.forEach((p) => {
            phone_types = phone_types.filter((t) => t.value !== p.category);
          });
        }
        phone_types.push({ label: "Other", value: "other" });
        return phone_types;
      default:
        return [];
    }
  };

  addElementLabel = () => {
    switch (this.props.type) {
      case "email":
        return "Add Email Address";
      case "phone":
        return "Add Phone Number";
      default:
        return;
    }
  };

  updatePerson = (phoneRemovalAck = false) => {
    this.setState({ saving: true }, () => {
      updatePersonPromise(this.state.editablePerson, phoneRemovalAck)
        .then((r) => {
          this.props.updatePersonData(r.data);
          this.setState({ editingElement: false, addingElement: false, errors: [], saving: false });
        })
        .catch((err) => {
          this.setState({ errors: err.response?.data?.errors || [], saving: false });
        });
    });
  };

  updatePersonEmailPhone = (phoneRemovalAck = false) => {
    this.setState({ saving: true }, () => {
      updatePersonPromise(this.state.editablePerson, phoneRemovalAck)
        .then((r) => {
          this.props.updatePersonData(r.data);
          this.setState({ editingElement: false, addingElement: false, errors: [], saving: false });
        })
        .catch((err) => {
          this.setState({
            saving: false,
            errors: err.response?.data?.errors || ["Whoops! Something went wrong."],
          });
        });
    });
  };

  onElementEdit = () => {
    this.setState({ editingElement: true });
  };

  onElementSave = (skipCheck = false) => {
    const hasChanged =
      (this.element().attributes.value || "").replace(/\D/g, "") !==
      (this.editableElement().attributes.value || "").replace(/\D/g, "");
    if (!skipCheck && hasChanged && this.props.conversationCount > 0) {
      this.setState({
        showPhoneContext: {
          type: "edit",
          conversationCount: this.props.conversationCount,
          changedNumbers: [
            {
              previousNumber: this.element().attributes.value,
              currentNumber: this.editableElement().attributes.value,
            },
          ],
        },
      });
      return;
    }
    this.updatePersonEmailPhone(true);
  };

  onElementDelete = (skipCheck = false) => {
    if (!skipCheck && this.props.conversationCount > 0) {
      this.setState({
        showPhoneContext: {
          type: "delete",
          conversationCount: this.props.conversationCount,
          changedNumbers: [{ previousNumber: this.element().attributes.value }],
        },
      });
      return;
    }
    const includedArray = this.state.editablePerson.included.map((a, i) => {
      // Setting value to empty string currently necessary to trigger manage_main_email_state on Person record
      // when removing an email and to get a .changes from ActiveRecord for the timeline_publishable for Phone & Email
      if (a["type"] === "email_detail" || "phone_detail") {
        return i === this.elementIndex()
          ? { ...a, attributes: { ...a.attributes, value: "", _destroy: "0" } }
          : a;
      } else {
        return i === this.elementIndex() ? { ...a, attributes: { ...a.attributes, _destroy: "1" } } : a;
      }
    });
    this.setState({ editablePerson: { ...this.state.editablePerson, included: includedArray } }, () =>
      this.updatePerson(true),
    );
  };

  onDismissElementEdit = () => {
    this.setState({
      editingElement: false,
      editablePerson: JSON.parse(JSON.stringify(this.props.person)),
      errors: [],
    });
  };

  onAddNewDetail = () => {
    this.setState({ addingElement: true });
  };

  onDismissElementAdd = () => {
    this.setState({ addingElement: false, errors: [] });
    this.setInitialAddingValue();
  };

  onAddingElementSave = () => {
    this.setState(
      (prevState) => {
        return {
          editablePerson: {
            ...prevState.editablePerson,
            included: [...prevState.editablePerson.included, this.state.addingValue],
          },
        };
      },
      () => this.updatePersonEmailPhone(),
    );
  };

  handleEditableValueChange = (value, key) => {
    const includedArray = this.state.editablePerson.included.map((a, i) =>
      i === this.elementIndex() ? { ...a, attributes: { ...a.attributes, [key]: value } } : a,
    );
    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        included: includedArray,
      },
    });
  };

  handlePhoneStatusChange = (value, type) => {
    const newState = produce((draft) => {
      const phone = draft.editablePerson.included[this.elementIndex()];
      const transformer = type === "sms" ? adjustSmsStatus : adjustCallStatus;
      const newPhone = transformer(phone, value)();
      draft.editablePerson.included[this.elementIndex()] = newPhone;
    }, this.state);

    this.setState(newState(), this.onElementSave());
  };

  handleAddingValueChange = (value, key) => {
    this.setState((prevState) => {
      return {
        addingValue: {
          ...prevState.addingValue,
          attributes: { ...prevState.addingValue.attributes, [key]: value },
        },
      };
    });
  };

  handleAddingTypeChange = (value) => {
    this.setState((prevState) => {
      return {
        addingValue: {
          ...prevState.addingValue,
          attributes: { ...prevState.addingValue.attributes, category: value["value"] },
        },
      };
    });
  };

  renderSavingState = () => {
    return (
      <div className="tw-py-[8px] tw-px-[20px] hover:tw-bg-gray-5">
        <div className={css.brivityPersonContactLabel}>{this.props.type}</div>
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <p>Saving changes...</p>
          <SpinnerSolidV6 className="tw-animate-spin" />
        </div>
      </div>
    );
  };

  editEmailInput = () => {
    return (
      <div>
        <Dropdown
          isSearchable={false}
          isClearable={false}
          options={this.labels(true)}
          onChange={(option) => this.handleEditableValueChange(option.value, "category")}
          placeholder="Select..."
          value={this.editableElement()["attributes"]["category"]}
          variant="flatBlueishGray"
        />
        <input
          style={{ width: "100%" }}
          value={this.editableElement()["attributes"]["value"]}
          onChange={(e) => this.handleEditableValueChange(e.target.value, "value")}
          className={`${form.rowNarrow} ${form.formControl}`}
        />
      </div>
    );
  };

  editPhoneInput = () => {
    const viewPhone = viewPhoneFromFastJsonApi(this.editableElement());

    return (
      <div>
        <Dropdown
          isSearchable={false}
          isClearable={false}
          options={this.labels(true)}
          onChange={(option) => this.handleEditableValueChange(option.value, "category")}
          placeholder="Select..."
          value={viewPhone.category}
          variant="flatBlueishGray"
        />
        <input
          style={{ width: "100%" }}
          value={viewPhone.rawValue}
          onChange={(e) => this.handleEditableValueChange(e.target.value, "value")}
          className={`${form.rowNarrow} ${form.formControl}`}
        />
      </div>
    );
  };

  renderEditFieldsByType = () => {
    switch (this.type()) {
      case "email_detail":
        return this.editEmailInput();
      case "phone_detail":
        return this.editPhoneInput();
      default:
        return null;
    }
  };

  renderEditFields = () => {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
        {this.renderEditFieldsByType()}
        <div className="tw-flex tw-flex-col tw-gap-[8px] tw-items-center">
          <Button size="small" onClick={() => this.onElementSave()}>
            Save
          </Button>
          <div className="tw-flex tw-gap-[4px]">
            <IconButton size="small" schema="misc-trash" onClick={() => this.onElementDelete()}>
              <TrashSolidV6 />
            </IconButton>
            <IconButton size="small" onClick={this.onDismissElementEdit}>
              <XmarkSolidV6 />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  renderFieldValues = () => {
    switch (this.type()) {
      case "email_detail":
        return this.renderEmailValue();
      case "phone_detail":
        return this.renderPhoneValue();
      default:
        return this.renderOtherValue();
    }
  };

  unsubscribeEmail = () => {
    this.setState({ saving: true }, () => {
      axios
        .patch(`/api/v4/person_detail/unsubscribe_email/${this.props.person.data.id}`, {
          email: this.element()["attributes"]["value"],
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.handleEditableValueChange(["all"], "unsubscribed_message_types");
          this.props.updatePersonData(this.state.editablePerson);
          this.setState({ saving: false });
        })
        .catch((err) => {
          this.setState({
            errors: [...this.state.errors, "Unable to unsubscribe email, please try again later."],
            saving: false,
          });
        });
    });
  };

  emailIsUnsubscribed = () => {
    return this.element()["attributes"]["unsubscribed_message_types"]?.length > 0;
  };

  emailStatus = () => {
    if (this.type() === "email_detail") {
      if (this.emailIsUnsubscribed()) {
        return (
          <div>
            <Tooltip
              placement="top"
              trigger={<img src="/assets/emails/unsubscribed-email.svg" className={css.emailStatusIcon} />}
              content="Unsubscribed Email"
            />
          </div>
        );
      }
      switch (this.element()["attributes"]["is_verified"]) {
        case true:
          return (
            <div>
              <Tooltip
                placement="top"
                trigger={<img src="/assets/emails/valid-email.svg" className={css.emailStatusIcon} />}
                content="Valid Email"
              />
            </div>
          );
        case false:
          return (
            <div>
              <Tooltip
                placement="top"
                trigger={<img src="/assets/emails/invalid-email.svg" className={css.emailStatusIcon} />}
                content="Invalid Email"
              />
            </div>
          );
        case null:
          return (
            <div>
              <Tooltip
                placement="top"
                trigger={<img src="/assets/emails/unverified-email.svg" className={css.emailStatusIcon} />}
                content="Unverified Email"
              />
            </div>
          );
        default:
          return null;
      }
    }
  };

  updateEmailStatus = (status) => {
    const includedArray = this.state.editablePerson.included.map((a, i) =>
      i === this.elementIndex()
        ? { ...a, attributes: { ...a.attributes, is_verified: status, validation_set_by_user: true } }
        : a,
    );
    this.setState(
      {
        editablePerson: {
          ...this.state.editablePerson,
          included: includedArray,
        },
      },
      () => {
        this.updatePerson();
      },
    );
  };

  renderEmailValue = () => {
    return (
      <div className={css.brivityPersonContactItem} style={{ alignItems: "center" }}>
        {this.emailStatus()}
        {this.emailIsUnsubscribed() && (
          <div className="tw-pl-[4px] tw-pr-[10px]">
            <CaretDownSolidV6 className="tw-opacity-60" />
          </div>
        )}
        {!this.emailIsUnsubscribed() && (
          <Popover offset={0} placement="bottom-start">
            <PopoverTrigger>
              <span className="tw-pl-[4px] tw-pr-[10px]">
                <CaretDownSolidV6 />
              </span>
            </PopoverTrigger>
            <PopoverContent>
              {this.element()["attributes"]["is_verified"] === true && (
                <PopoverItem onClick={(e) => this.updateEmailStatus(false)}>
                  <img src="/assets/emails/invalid-email.svg" className={css.emailStatusIcon} /> Invalid Email
                </PopoverItem>
              )}
              {this.element()["attributes"]["is_verified"] === false && (
                <PopoverItem onClick={(e) => this.updateEmailStatus(true)}>
                  <img src="/assets/emails/valid-email.svg" className={css.emailStatusIcon} /> Valid Email
                </PopoverItem>
              )}
              {this.element()["attributes"]["is_verified"] === null && (
                <>
                  <PopoverItem onClick={(e) => this.updateEmailStatus(true)}>
                    <img src="/assets/emails/valid-email.svg" className={css.emailStatusIcon} /> Valid Email
                  </PopoverItem>
                  <PopoverItem onClick={(e) => this.updateEmailStatus(false)}>
                    <img src="/assets/emails/invalid-email.svg" className={css.emailStatusIcon} /> Invalid
                    Email
                  </PopoverItem>
                </>
              )}
              <PopoverItem onClick={(e) => this.unsubscribeEmail()}>
                <img src="/assets/emails/unsubscribed-email.svg" className={css.emailStatusIcon} />{" "}
                Unsubscribe
              </PopoverItem>
            </PopoverContent>
          </Popover>
        )}
        <div className={`${css.brivityPersonContactContents} tw-w-full tw-relative`}>
          <span className={`${css.contactInfoFadeOut} group-hover/editable:after:tw-hidden`}>
            {this.element()["attributes"]["value"]}
          </span>
        </div>
      </div>
    );
  };

  renderPhoneValue = () => {
    const viewPhone = viewPhoneFromFastJsonApi(this.element());
    const { handlePhoneStatusChange } = this;
    const { isDnc } = this.props;
    return <PhoneValue phone={viewPhone} isDnc={isDnc} onChange={handlePhoneStatusChange} />;
  };

  renderOtherValue = () => {
    return (
      <div className={css.brivityPersonContactItem}>
        <div className={`${css.brivityPersonContactContents} tw-w-full`}>
          <span className={`${css.contactInfoFadeOut} group-hover/editable:after:tw-hidden`}>
            {this.element()["attributes"]["value"]}
          </span>
        </div>
      </div>
    );
  };

  addPhoneOrEmailElement = () => {
    return (
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Dropdown
          isSearchable={false}
          isClearable={false}
          placeholder="Select Label"
          value={this.state.addingValue["attributes"]["category"]}
          onChange={this.handleAddingTypeChange}
          options={this.labels()}
          variant="flatBlueishGray"
        />
        <input
          value={this.state.addingValue["attributes"]["value"]}
          onChange={(e) => this.handleAddingValueChange(e.target.value, "value")}
          className={`${form.formControl}`}
        />
      </div>
    );
  };

  renderAddElementByType = () => {
    switch (this.props.type) {
      case "email":
        return this.addPhoneOrEmailElement();
      case "phone":
        return this.addPhoneOrEmailElement();
      default:
        return;
    }
  };

  renderAddingElement = () => {
    return (
      <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
        <div className="tw-flex-1">{this.renderAddElementByType()}</div>
        <div className="tw-flex tw-gap-[8px]">
          <Button size="small" onClick={this.onAddingElementSave}>
            Save
          </Button>
          <IconButton size="small" onClick={this.onDismissElementAdd}>
            <XmarkSolidV6 />
          </IconButton>
        </div>
      </div>
    );
  };

  elementDoesExist = () => {
    return (
      <div className="tw-flex tw-flex-col tw-gap-[8px] tw-py-[8px] tw-px-[20px] hover:tw-bg-gray-5 tw-group/editable">
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
          {/*TODO: can this inline-block be moved on this class?*/}
          <div className={css.brivityPersonContactLabel}>
            {`${this.elementCategory()} (${this.element()["attributes"]["category"]})`}
            {this.isPrimary() && "*"}
          </div>
          {!this.props.disabled && !this.state.editingElement && (
            <div className="tw-invisible group-hover/editable:tw-visible tw-flex tw-gap-[4px]">
              <IconButton size="small" onClick={this.onElementEdit}>
                <PencilSolidV6 />
              </IconButton>
              <IconButton size="small" onClick={this.onAddNewDetail}>
                <PlusSolidV6 />
              </IconButton>
            </div>
          )}
        </div>
        <div className={css.brivityPersonDetailsText}>
          {this.state.editingElement ? this.renderEditFields() : this.renderFieldValues()}
        </div>
        {this.state.addingElement && this.renderAddingElement()}
        <PhoneNumberModal
          showContext={this.state.showPhoneContext}
          onClose={(phoneRemovalAck) => {
            if (phoneRemovalAck) {
              if (this.state.showPhoneContext.type === "delete") {
                this.onElementDelete(true);
              }
              if (this.state.showPhoneContext.type === "edit") {
                this.onElementSave(true);
              }
            }
            this.setState({ showPhoneContext: null });
          }}
        />
      </div>
    );
  };

  elementDoesNotExist = () => {
    return (
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-[8px] tw-px-[20px] tw-py-[8px] hover:tw-bg-gray-5">
        <div className={css.brivityPersonContactLabel}>{this.props.type}</div>
        {!this.state.addingElement && (
          <TextButton schema="sentence" onClick={this.onAddNewDetail}>
            {this.addElementLabel()}
          </TextButton>
        )}
        {this.state.addingElement && this.renderAddingElement()}
      </div>
    );
  };

  setInitialAddingValue = () => {
    switch (this.props.type) {
      case "phone":
        this.setState({
          addingValue: {
            type: "phone_detail",
            attributes: { name: this.props.type, value: "", category: this.labels()[0]["value"] },
          },
        });
        break;
      case "email":
        this.setState({
          addingValue: {
            type: "email_detail",
            attributes: { name: this.props.type, value: "", category: this.labels()[0]["value"] },
          },
        });
        break;
      default:
        break;
    }
  };

  handleHover = () => {
    this.setState((prevState) => ({
      hover: !prevState.hover,
    }));
  };

  componentDidMount() {
    this.setInitialAddingValue();
  }

  render() {
    const { id } = this.props;
    const { errors, saving } = this.state;
    return (
      <>
        <div className="tw-px-20px">
          {errors?.map((error, i) => (
            <PersonDetailError key={i} error={error} />
          ))}
        </div>
        {saving
          ? this.renderSavingState()
          : id === undefined
            ? this.elementDoesNotExist()
            : this.elementDoesExist()}
      </>
    );
  }
}

export default EditableElementWithAdd;
