import React from "react";
import PropTypes from "prop-types";

export const PreviewDetailsDivider = ({ ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className="tw-w-full tw-h-1px tw-bg-neutral-gray-10" {...otherProps} />
);

export const PreviewDetailsGroup = ({ children, ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className="tw-flex tw-flex-col tw-gap-24px" {...otherProps}>
    {children}
  </div>
);

PreviewDetailsGroup.propTypes = {
  children: PropTypes.node,
};

PreviewDetailsGroup.defaultProps = {
  children: null,
};

const gapSizeDictionary = {
  small: "tw-gap-8px",
  medium: "tw-gap-12px",
  large: "tw-gap-16px",
};

export const PreviewDetail = ({ gapSize, children, ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className={`tw-flex tw-flex-col ${gapSizeDictionary[gapSize]}`} {...otherProps}>
    {children}
  </div>
);

PreviewDetail.propTypes = {
  gapSize: PropTypes.oneOf(["small", "medium", "large"]),
  children: PropTypes.node,
};

PreviewDetail.defaultProps = {
  gapSize: "small",
  children: null,
};

export const PreviewDetailTitle = ({ children, ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <p className="tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50" {...otherProps}>
    {children}
  </p>
);

PreviewDetailTitle.propTypes = {
  children: PropTypes.node,
};

PreviewDetailTitle.defaultProps = {
  children: null,
};

export const PreviewDetailText = ({ shouldTruncate, children, ...otherProps }) => (
  <p
    className={`
      tw-m-0 tw-text-14d tw-font-normal tw-text-neutral-gray-75
      ${shouldTruncate ? "tw-truncate" : ""}
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  >
    {children}
  </p>
);

PreviewDetailText.propTypes = {
  shouldTruncate: PropTypes.bool,
  children: PropTypes.node,
};

PreviewDetailText.defaultProps = {
  shouldTruncate: true,
  children: null,
};
