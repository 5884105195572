import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class PropertyFavoriteEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event
  }


  details = () => {
    let description = []
    let fields = this.event.timeline_fields

    for (var key in fields){
      const titleized_key = key.split("_").map(element =>{return capitalize(element)}).join(" ")
      description.push(titleized_key + ": " + fields[key])
    }

    return description.map(description =>{
      return(
        <div key={description} className="row details-row">
          {description}
        </div>
      )
    })
  }

  property(propertyObject) {
    const bedString = !propertyObject.beds ? "" : propertyObject.beds + " Bed"
    const bathString = !propertyObject.baths ? "" : propertyObject.baths + " Bath"
    const bedBathString = (bedString + " " + bathString).trim()

    return(
      <div key={propertyObject.property_id} className="property-wrapper"
        style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' + propertyObject.photo_url + ')'}}>
        <div className="inset-info">
          <span className="price">{'$'+propertyObject.property_price}</span>
          <span>{propertyObject['address']}</span>
          <span>{propertyObject['city']}, {propertyObject['state']} {propertyObject['zip']}</span>
          <span>{bedBathString}</span>
        </div>
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event property-image-event"
           onClick={()=>{this.event && this.event.timeline_fields && this.event.timeline_fields['url'] && window.open(this.event.timeline_fields['url']);}}
      >
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-heart-o timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">{this.event.timeline_fields['first_name']} {this.event.timeline_fields['last_name']} </span>
            <span className="timeline-person-removed-field">unfavorited a property</span>
            <div className="timeline-description-text">
              {this.event.timeline_fields && this.property(this.event.timeline_fields)}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
