import React from "react";
import PropTypes from "prop-types";
import styleSchema, { fontSizeDictionary } from "./TextLink.styles";

const TextLink = ({ href, rel, target, size, children, className, ...otherProps }) => (
  <a
    href={href}
    rel={rel}
    target={target}
    className={`tw-font-normal tw-no-underline hover:tw-underline ${fontSizeDictionary[size]} ${styleSchema.default} ${className}`}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  >
    {children}
  </a>
);

TextLink.propTypes = {
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.oneOf(["_self", "_blank"]),
  size: PropTypes.oneOf(["medium", "small"]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
};

TextLink.defaultProps = {
  href: "#",
  rel: "",
  target: "_self",
  size: "medium",
  children: null,
  className: "",
};

export default TextLink;
