import Button from "@shared/v2/Button";
import RotateRightSolidV6 from "../../../shared/v2/Icomoon/Icons/RotateRightSolidV6";
import StopSolidV6 from "../../../shared/v2/Icomoon/Icons/StopSolidV6";
import UploadSolidV6 from "../../../shared/v2/Icomoon/Icons/UploadSolidV6";

import React, { useState, useRef, useEffect } from "react";
import vmsg from "vmsg";
import wasmUrl from "vmsg/vmsg.wasm";

const VoicemailRecorder = ({
  setVoicemailGreetingChanged,
  voicemailGreetingFile,
  setVoicemailGreetingFile,
  voicemailGreetingFilename,
  setVoicemailGreetingFilename,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(voicemailGreetingFilename === null ? 0 : 3);
  const inputFile = useRef(null);

  const componentByStep = (step) => {
    switch (step) {
      case 0:
        return <RecordOrUpload setStep={setStep} inputFile={inputFile} />;
      case 1:
        return <ReadyToRecord setStep={setStep} voicemailGreetingFilename={voicemailGreetingFilename} />;
      case 2:
        return (
          <RecordingInProgress
            setStep={setStep}
            setVoicemailGreetingFile={setVoicemailGreetingFile}
            setVoicemailGreetingFilename={setVoicemailGreetingFilename}
            setVoicemailGreetingChanged={setVoicemailGreetingChanged}
          />
        );
      case 3:
        return (
          <DoneRecording
            setStep={setStep}
            setIsUploading={setIsUploading}
            voicemailGreetingFilename={voicemailGreetingFilename}
            voicemailGreetingFile={voicemailGreetingFile}
          />
        );
      case 4:
        return (
          <ReplaceConfirm
            setStep={setStep}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            inputFile={inputFile}
          />
        );
      case 5:
        return (
          <DeleteConfirm
            setStep={setStep}
            setVoicemailGreetingFile={setVoicemailGreetingFile}
            setVoicemailGreetingFilename={setVoicemailGreetingFilename}
            setVoicemailGreetingChanged={setVoicemailGreetingChanged}
          />
        );
    }
  };

  const FileUpload = () => {
    return (
      <input ref={inputFile} type="file" accept=".mp3" className="!tw-hidden" onChange={handleFileUpload} />
    );
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setVoicemailGreetingFile(URL.createObjectURL(file));
    setVoicemailGreetingFilename(file.name);
    setVoicemailGreetingChanged(true);
    setIsUploading(false);
    setStep(3);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-8px">
      <FileUpload />
      <span className="tw-text-18px tw-text-gray-75 tw-font-normal">Voicemail</span>
      <p>
        Custom voicemails are optional; if one is not provided, the default automated voicemail will be used.
        {voicemailGreetingFilename === null ? (
          <span className="tw-font-bold"> You are currently using the default.</span>
        ) : (
          ""
        )}
      </p>
      {componentByStep(step)}
    </div>
  );
};

export default VoicemailRecorder;

const RecordOrUpload = ({ setStep, inputFile }) => {
  return (
    <div className="tw-h-[142px] tw-bg-[#f5f7f7] tw-py-[26px] tw-text-center tw-rounded-8px tw-border tw-border-solid tw-border-gray-10">
      <p className="tw-font-semibold tw-mb-0">Record or upload a custom voicemail</p>
      <p className="tw-text-12px tw-text-gray-50 tw-mb-16px">Uploaded files must be in .mp3 format</p>
      <div>
        <Button className="tw-mr-12px" size="medium" onClick={() => setStep(1)}>
          <StopSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
          Record
        </Button>
        <Button schema="secondary" size="medium" onClick={() => inputFile.current.click()}>
          <UploadSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
          Upload
        </Button>
      </div>
    </div>
  );
};

const ReadyToRecord = ({ setStep, voicemailGreetingFilename }) => {
  return (
    <div className="tw-h-[142px] tw-py-32px tw-px-24px tw-text-center tw-rounded-8px tw-border tw-border-solid tw-border-gray-10">
      <div className="tw-flex tw-justify-center tw-mb-16px">
        <p className="tw-font-bold tw-text-gray-50 tw-my-auto tw-mr-auto">CLICK START TO RECORD</p>
        <Button
          className="tw-ml-auto"
          schema="tertiary"
          size="small"
          onClick={() => setStep(voicemailGreetingFilename === null ? 0 : 3)}
        >
          Cancel
        </Button>
      </div>
      <div className="tw-flex">
        <Button className="tw-mr-16px" size="medium" onClick={() => setStep(2)}>
          <StopSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
          Start
        </Button>
        <p className="tw-text-12px tw-my-auto">0:00</p>
      </div>
    </div>
  );
};

const RecordingInProgress = ({
  setStep,
  setVoicemailGreetingFile,
  setVoicemailGreetingFilename,
  setVoicemailGreetingChanged,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [secondsRecording, setSecondsRecording] = useState(0);

  const formatSeconds = () => {
    const minutes = Math.floor(secondsRecording / 60);
    const seconds = secondsRecording % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const startRecording = async () => {
    try {
      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();
      setIsRecording(true);
    } catch (e) {
      console.error(e);
    }
  };

  const stopRecording = async () => {
    if (isRecording) {
      try {
        const blob = await recorder.stopRecording();
        setVoicemailGreetingFile(URL.createObjectURL(blob));
        setVoicemailGreetingFilename(
          `Recorded on ${new Date().toLocaleDateString().replaceAll("/", "-")}.mp3`,
        );
        setVoicemailGreetingChanged(true);
      } catch (e) {
        console.error(e);
      }

      setSecondsRecording(0);
    }

    setIsRecording(false);
  };

  useInterval(
    () => {
      setSecondsRecording(secondsRecording + 1);
    },
    isRecording ? 1000 : null,
  );

  if (!isRecording) {
    startRecording();
  }

  const done = () => {
    if (isRecording) {
      stopRecording();
    }

    setStep(3);
  };

  return (
    <div className="tw-h-[142px] tw-py-32px tw-px-24px tw-rounded-8px tw-border tw-border-solid tw-border-gray-10">
      <p className="tw-font-bold tw-leading-[24px] tw-text-gray-50 tw-mb-16px">RECORDING IN PROGRESS...</p>
      <div className="tw-flex">
        <Button className="tw-mr-16px" size="medium" onClick={done}>
          <StopSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
          Stop
        </Button>
        <p className="tw-text-12px tw-my-auto">{formatSeconds()}</p>
      </div>
    </div>
  );
};

const DoneRecording = ({ setStep, setIsUploading, voicemailGreetingFilename, voicemailGreetingFile }) => {
  const uploadConfirm = () => {
    setIsUploading(true);
    setStep(4);
  };

  return (
    <div className="tw-h-[142px] tw-py-32px tw-px-24px tw-rounded-8px tw-border tw-border-solid tw-border-gray-10">
      <div className="tw-flex tw-ml-auto tw-mb-16px">
        <p className="tw-my-auto tw-text-gray-50">"{voicemailGreetingFilename}"</p>
        <div className="tw-ml-auto">
          <Button className="tw-mr-8px" schema="tertiary" size="small" onClick={() => setStep(4)}>
            <RotateRightSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
            Re-record
          </Button>
          <Button className="tw-mr-8px" schema="tertiary" size="small" onClick={uploadConfirm}>
            <UploadSolidV6 className="tw-mr-8px" viewBox="0 0 14 14" />
            Upload New
          </Button>
          <Button schema="warning" size="small" onClick={() => setStep(5)}>
            Delete
          </Button>
        </div>
      </div>
      <audio className="tw-w-full tw-h-36px" src={voicemailGreetingFile} controls />
    </div>
  );
};

const ReplaceConfirm = ({ setStep, isUploading, setIsUploading, inputFile }) => {
  return (
    <div
      className={`tw-h-[142px] ${isUploading ? "tw-py-21px" : "tw-py-32px"} tw-px-24px tw-rounded-8px tw-border tw-border-solid tw-border-gray-10`}
    >
      <div className="tw-mb-12px">
        <p className="tw-mb-0 tw-font-bold">
          Updating this voicemail will remove the currently uploaded one.
        </p>
        <p className="tw-mb-0">There is no way to get this back. Are you sure?</p>
      </div>
      <div className="tw-flex">
        <div className="tw-ml-auto">
          <Button
            className="tw-mr-16px"
            schema="tertiary"
            size="small"
            onClick={() => setIsUploading(false) || setStep(3)}
          >
            Cancel
          </Button>
          <Button size="small" onClick={() => (isUploading ? inputFile.current.click() : setStep(1))}>
            Continue
          </Button>
        </div>
      </div>
      {isUploading && (
        <div className="tw-flex">
          <div className="tw-ml-auto">
            <p className="tw-text-12px tw-text-gray-50 tw-mt-[6px] tw-my-auto">
              Uploaded files must be in .mp3 format
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const DeleteConfirm = ({
  setStep,
  setVoicemailGreetingFile,
  setVoicemailGreetingFilename,
  setVoicemailGreetingChanged,
}) => {
  const deleteGreeting = () => {
    setVoicemailGreetingFile(null);
    setVoicemailGreetingFilename(null);
    setVoicemailGreetingChanged(true);
    setStep(0);
  };

  return (
    <div className="tw-h-[142px] tw-py-32px tw-px-24px tw-rounded-8px tw-border tw-border-solid tw-border-gray-10">
      <div className="tw-mb-12px">
        <p className="tw-mb-0 tw-font-bold">There is no way to retrieve a deleted voicemail.</p>
        <p className="tw-mb-0">You will have to either upload or record a new one. Are you sure?</p>
      </div>
      <div className="tw-flex">
        <div className="tw-ml-auto">
          <Button className="tw-mr-16px" schema="tertiary" size="small" onClick={() => setStep(3)}>
            Cancel
          </Button>
          <Button schema="warning" size="small" onClick={deleteGreeting}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const recorder = new vmsg.Recorder({
  wasmURL: wasmUrl,
});
