import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ChevronRightSolidV6 } from "@shared/v2/Icomoon";
import Result from "./Result";
import css from "./QuickSearch.module.css";
import Loading from "../../Goals/components/Loader";

const CONTACT_TYPES = ["team", "sphere", "lead"];
const TRASHED_STATUSES = ["trash", "archived"];

const Results = ({
  searchResults,
  cursor,
  onHover,
  hasResults,
  isFetching,
  searchValid,
  searchTerm,
  searchTermLength,
  saveRecentSearch,
}) => {
  const [showTrashed, setShowTrashed] = useState(false);
  const recentSearches = useMemo(() => JSON.parse(localStorage.getItem("recentSearches") || "[]"), []);

  const trashLeads = searchResults.filter(
    (r) => CONTACT_TYPES.includes(r.type) && TRASHED_STATUSES.includes(r.status),
  );
  const otherResults = searchResults.filter(
    (r) =>
      !CONTACT_TYPES.includes(r.type) ||
      (CONTACT_TYPES.includes(r.type) && !TRASHED_STATUSES.includes(r.status)),
  );

  useEffect(() => {
    if (cursor >= otherResults.length) setShowTrashed(true);
  }, [cursor, otherResults.length]);

  return (
    <div className={`${css.resultsContainer} tw-z-[1000]`} id="resultsContainer">
      {isFetching && (
        <div className="tw-h-[100px]">
          <Loading />
        </div>
      )}
      {!searchValid && (
        <div
          className={`${css.searchWarning} tw-border-0 tw-border-b tw-border-solid tw-border-color-ebebeb`}
        >
          Enter {1 - searchTermLength} more characters to search
        </div>
      )}

      {!isFetching && !searchValid && recentSearches.length > 0 && (
        <>
          <div className={css.searchWarning}>Recent Searches</div>
          {recentSearches.map((result, i) => (
            <Result
              key={result.objectId}
              result={result}
              cursor={cursor}
              index={i}
              onHover={onHover}
              saveRecentSearch={saveRecentSearch}
            />
          ))}
        </>
      )}

      {!isFetching &&
        hasResults &&
        otherResults.map((result, i) => (
          <Result
            key={result.objectId}
            result={result}
            cursor={cursor}
            index={i}
            onHover={onHover}
            saveRecentSearch={saveRecentSearch}
          />
        ))}

      {!isFetching && hasResults && trashLeads.length > 0 && (
        <>
          <button
            className="tw-border-none tw-bg-transparent tw-inline-flex tw-items-center tw-gap-[12px] tw-w-full tw-text-gray-75 hover:tw-bg-gray-5 tw-text-start tw-p-[7px]"
            type="button"
            onClick={(e) => {
              setShowTrashed((t) => !t);
              e.currentTarget.parentElement.parentElement.querySelector('input[type="text"]').focus();
            }}
          >
            <ChevronRightSolidV6 className={`tw-transition-transform ${showTrashed ? "tw-rotate-90" : ""}`} />{" "}
            Show trashed & archived contacts
          </button>
          {showTrashed &&
            trashLeads.map((result, i) => (
              <Result
                key={result.objectId}
                result={result}
                cursor={cursor}
                index={otherResults.length + i}
                onHover={onHover}
                saveRecentSearch={saveRecentSearch}
              />
            ))}
        </>
      )}

      {!isFetching && !hasResults && searchValid && (
        <div className={css.searchWarning}>
          No results for <b>{searchTerm}</b>
        </div>
      )}
    </div>
  );
};

Results.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  cursor: PropTypes.number.isRequired,
  onHover: PropTypes.func.isRequired,
  hasResults: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  searchValid: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchTermLength: PropTypes.number.isRequired,
  saveRecentSearch: PropTypes.func.isRequired,
};

export default Results;
