import React from "react";
import { arrayOf, func, string } from "prop-types";

import withSelectedTasks from "../HOCs/withSelectedTasks";

import Button from "../../../../shared/v2/Button";

const TaskSelection = ({ Icon, schema, selectedTasks, children, onClick }) => (
  <Button
    schema={schema}
    className="tw-flex tw-items-center tw-gap-[4px]"
    onClick={onClick}
    disabled={!selectedTasks.length}
  >
    <Icon />
    <span>{children}</span>
  </Button>
);

TaskSelection.propTypes = {
  Icon: func,
  schema: string,
  children: string,
  selectedTasks: arrayOf(string),
  onClick: func,
};

TaskSelection.defaultProps = {
  Icon: null,
  schema: undefined,
  children: null,
  selectedTasks: [],
  onClick: null,
};

export default withSelectedTasks(TaskSelection);
