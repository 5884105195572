import moment from "moment";

import { VALID_DATE_FORMATS } from "../../DateSelector/utils/constants";

export const getValidDateFormats = (dateFormat = "") => {
  if (dateFormat === "") {
    return VALID_DATE_FORMATS;
  }

  return [dateFormat, ...VALID_DATE_FORMATS];
};

const isYearlessFormat = (format) => !/[Yy]/.test(format);

// Get the year in case it's not on impureDate (text input). Priority:
// 1. temporaryDate.year  -> user clicked on a date
// 2. navigationDate.year -> user clicked on a month
// 3. current year        -> assume it's the current year
const getIndirectYear = (state) => {
  const currentYear = moment().year();
  if (state.temporaryDate && state.temporaryDate.year() !== currentYear) {
    return state.temporaryDate.year();
  }
  if (state.navigationDate && state.navigationDate.year() !== currentYear) {
    return state.navigationDate.year();
  }
  return currentYear;
};

export const isDateValid = (state, dateFormat, key = "impureTemporary") => {
  if (typeof state[key] !== "string") return false;

  // Moment doesn't validate 02/29 if the date format and the input doesn't contain years. Ex. MM/DD MM-DD DD/MM DD-MM
  // Under the hood we add the years to check if the state.date (input text) is valid
  if (dateFormat !== "" && isYearlessFormat(dateFormat)) {
    const year = getIndirectYear(state);
    const temporaryYearDate = `${state[key].split(" ")[0]}/${year}`;
    return moment(temporaryYearDate, [`${dateFormat}/YYYY`, ...VALID_DATE_FORMATS]).isValid();
  }

  return moment(state[key].split(" ")[0], getValidDateFormats(dateFormat)).isValid();
};
