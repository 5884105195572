import React from "react";
import PropTypes from "prop-types";
import ChartPieSolidV6 from "../../../shared/v2/Icomoon/Icons/ChartPieSolidV6";
import Tooltip from "../../../shared/v2/Tooltip";
import TypeCell from "./TypeCell";

const allTypes = [
  { value: "buyer", totalsKey: "buyers" },
  { value: "seller", totalsKey: "sellers" },
  { value: "tenant", totalsKey: "tenants" },
  { value: "landlord", totalsKey: "landlords" },
  { value: "referral", totalsKey: "referrals" },
];

const TotalsBreakdown = ({ totals }) => (
  <Tooltip
    tooltipClassName="tw-shadow-theme-totals-breakdown !tw-text-theme-totals-breakdown tw-rounded-4px"
    arrowClassName="before:!tw-bg-theme-totals-breakdown"
    innerClassName="!tw-p-0 !tw-bg-theme-totals-breakdown !tw-text-theme-totals-breakdown"
    placement="right"
    trigger={
      <div className="tw-flex">
        <ChartPieSolidV6 size="custom" className="tw-text-theme-primary tw-w-18px tw-h-18px" />
      </div>
    }
    content={
      <div className="tw-flex tw-flex-col tw-gap-6px tw-w-fit tw-px-8px tw-py-6px">
        {allTypes.map((type) => (
          <div key={type.value} className="tw-flex tw-flex-row">
            <TypeCell value={type.value} themed withTooltip={false} />
            <span className="tw-text-left tw-text-14d tw-capitalize tw-grow tw-ml-6px">{type.value}</span>
            <span className="tw-text-14d tw-font-bold tw-ml-14px">{totals[type.totalsKey] || 0}</span>
          </div>
        ))}
      </div>
    }
  />
);

TotalsBreakdown.propTypes = {
  totals: PropTypes.shape({
    buyers: PropTypes.number,
    sellers: PropTypes.number,
    landlords: PropTypes.number,
    referrals: PropTypes.number,
    tenants: PropTypes.number,
  }),
};

TotalsBreakdown.defaultProps = {
  totals: {},
};

export default TotalsBreakdown;
