/* eslint-disable class-methods-use-this */
import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import FieldLabel from "@shared/v2/FieldLabel";
import Modal from "@shared/v2/Modal";
import RadioButton from "@shared/v2/RadioButton";
import TextInput from "@shared/v2/TextInput";
import UnAssignModal from "./UnAssignModal";

export default class TwilioManageNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subaccountSid: props.subaccountSid,
      loading: true,
      twilioNumbers: [],
      unassignedUsers: [],
      trackingNumbers: [],
      successAlerts: [],
      errors: [],
      assignModalVisible: false,
      numberToAssign: null,
      assignTo: "user",
      userToAssign: "",
      trackingNumberToAssign: "",
      moveAccountModalVisible: false,
      moveAccountUserEmail: "",
      numberToMove: null,
      userToMoveTo: null,
      moveNumberErrors: [],
      setUnAssignModalShow: false,
      unAssignedModalCb: () => {},
    };
  }

  componentDidMount() {
    const { subaccountSid } = this.state;
    const { developerRole } = this.props;
    if (subaccountSid && developerRole) {
      axios.get("/support/twilio/numbers").then((res) => {
        this.setState({
          loading: false,
          twilioNumbers: res.data.twilio_numbers,
          trackingNumbers: res.data.tracking_numbers.map((t) => ({
            label: t.name,
            value: t.id,
            meta: t,
          })),
          unassignedUsers: res.data.unassigned_users.map((u) => ({
            label: `${u.name} - ${u.email}`,
            value: u.id,
          })),
        });
      });
    }
  }

  assignedNumbers = () => {
    const { twilioNumbers, trackingNumbers } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <br />
        <h4>
          <strong>Assigned Twilio Numbers</strong>
        </h4>
        <table className="table">
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>SID</th>
              <th>Name</th>
              <th>Email</th>
              <th style={{ textAlign: "end" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {twilioNumbers
              .filter((n) => n.user_id !== null)
              .map((n) => (
                <tr key={n.sid}>
                  <td width="150px">{n.phone_number}</td>
                  <td width="325px">{n.sid}</td>
                  <td>{n.name}</td>
                  <td>{n.email}</td>
                  <td style={{ textAlign: "end" }}>
                    {n.toll_free ? (
                      <span className="tw-text-[FF0000]">Toll-Free</span>
                    ) : (
                      <Button
                        schema="tertiary"
                        onClick={(e) =>
                          this.setState({
                            setUnAssignModalShow: true,
                            unAssignedModalCb: () => this.unassignNumber(e, n.user_id),
                          })
                        }
                      >
                        Un-assign
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            {trackingNumbers.map((n) => (
              <tr key={n.value}>
                <td width="150px">{n.meta.number}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td width="325px" />
                <td>{n.meta.name}</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td />
                <td />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  unassignedNumbers = () => {
    const { twilioNumbers } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <h4>
          <strong>Unassigned Twilio Numbers</strong>
        </h4>
        <table className="table">
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>SID</th>
              <th>Unassigned on</th>
              <th style={{ textAlign: "end" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {twilioNumbers
              .filter((n) => n.user_id === null)
              .map((n) => (
                <tr key={n.sid}>
                  <td width="150px">{n.phone_number}</td>
                  <td width="325px">{n.sid}</td>
                  <td width="200px">{n.unassigned_at}</td>
                  <td>
                    {n.toll_free ? (
                      <span className="tw-text-[FF0000]">Toll-Free</span>
                    ) : (
                      <div className="tw-flex tw-justify-end tw-gap-[8px]">
                        <Button schema="tertiary" onClick={() => this.showAssignModal(n)}>
                          Assign
                        </Button>
                        <Button schema="tertiary" onClick={() => this.showMoveAccountModal(n)}>
                          Move Account
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  successAlerts = () => {
    const { successAlerts } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        {successAlerts.map((e) => (
          <div key={e} className="alert alert-success" role="alert">
            {e}
          </div>
        ))}
      </div>
    );
  };

  errorAlerts = () => {
    const { errors } = this.state;
    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        {errors.map((e) => (
          <div key={e} className="alert alert-danger" role="alert">
            {e}
          </div>
        ))}
      </div>
    );
  };

  loadingState = () => (
    <div className="row">
      <div className="col text-center">
        <h3>
          <i className="fa fa-pulse fa-spinner" /> Loading Twilio Numbers...
        </h3>
      </div>
    </div>
  );

  unassignNumber = (e, userId) => {
    const { successAlerts, errors } = this.state;
    e.preventDefault();
    this.setState({ loading: true }, () => {
      axios
        .post("/support/twilio/unassign_number", {
          user_id: userId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            errors: [...errors, "There was an error un-assigning the number."],
          });
        });
    });
  };

  showAssignModal = (number) => {
    this.setState({ assignModalVisible: true, numberToAssign: number });
  };

  selectUser = () => {
    const { unassignedUsers, numberToAssign, userToAssign } = this.state;
    return (
      <>
        <Dropdown
          id="userToAssignSelect"
          label="Select a user"
          placeholder="Select a user"
          options={unassignedUsers}
          value={unassignedUsers.find((u) => u.value === userToAssign)}
          onChange={(option) => this.setState({ userToAssign: option.value })}
        />
        <Button
          className="tw-self-start"
          size="medium"
          onClick={() => {
            this.assignNumber(numberToAssign, userToAssign);
          }}
          disabled={userToAssign === ""}
        >
          Assign Number
        </Button>
      </>
    );
  };

  selectTrackingNumber = () => {
    const { trackingNumbers, numberToAssign, trackingNumberToAssign } = this.state;
    return (
      <>
        <Dropdown
          id="trackingNumberToAssignSelect"
          label="Select a tracking number"
          placeholder="Select a tracking number"
          options={trackingNumbers}
          value={trackingNumbers.find((t) => t.value === trackingNumberToAssign)}
          onChange={(option) => this.setState({ trackingNumberToAssign: option.value })}
        />
        <Button
          className="tw-self-start"
          size="medium"
          onClick={() => {
            this.assignNumberToTrackingNumber(numberToAssign, trackingNumberToAssign);
          }}
          disabled={trackingNumberToAssign === ""}
        >
          Assign Number
        </Button>
      </>
    );
  };

  assignModal = () => {
    const { assignModalVisible, assignTo, numberToAssign } = this.state;
    return (
      <Modal
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        show={assignModalVisible}
        onHide={() => this.setState({ assignModalVisible: false, numberToAssign: null, userToAssign: "" })}
      >
        <Modal.Header title="Assign Twilio Number" closeButton />
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
          <span>
            <strong>Number to assign: </strong>
            {numberToAssign?.phone_number}
          </span>
          <div className="tw-flex tw-flex-col">
            <FieldLabel label="Assign to" />
            <div className="tw-flex tw-gap-[8px]">
              <RadioButton
                label="User"
                checked={assignTo === "user"}
                onChange={() => this.setState({ assignTo: "user" })}
              />
              <RadioButton
                label="Tracking Number"
                checked={assignTo === "trackingNumber"}
                onChange={() => this.setState({ assignTo: "trackingNumber" })}
              />
            </div>
          </div>
          {assignTo === "user" ? this.selectUser() : this.selectTrackingNumber()}
        </Modal.Body>
      </Modal>
    );
  };

  assignNumber = (number, userId) => {
    const { errors, successAlerts } = this.state;
    this.setState({ loading: true, assignModalVisible: false }, () => {
      axios
        .post("/support/twilio/assign_number", {
          phone_number: number.phone_number,
          user_id: userId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            trackingNumbers: res.data.tracking_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: [...errors, err.response.data.errors || "There was an error assigning the number"],
          });
        });
    });
  };

  assignNumberToTrackingNumber = (number, trackingNumberId) => {
    const { errors, successAlerts } = this.state;
    this.setState({ loading: true, assignModalVisible: false }, () => {
      axios
        .post("/support/twilio/assign_number_to_tracking_number", {
          phone_number: number.phone_number,
          tracking_number_id: trackingNumberId,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            loading: false,
            twilioNumbers: res.data.twilio_numbers,
            trackingNumbers: res.data.tracking_numbers,
            unassignedUsers: res.data.unassigned_users,
            successAlerts: [...successAlerts, res.data.success],
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            errors: [...errors, err.response.data.errors || "There was an error assigning the number"],
          });
        });
    });
  };

  showMoveAccountModal = (number) => {
    this.setState({ moveAccountModalVisible: true, numberToMove: number });
  };

  moveAccountModal = () => {
    const { moveAccountModalVisible, moveAccountUserEmail, numberToMove, userToMoveTo, moveNumberErrors } =
      this.state;
    return (
      <Modal
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        show={moveAccountModalVisible}
        onHide={() =>
          this.setState({ moveAccountModalVisible: false, numberToMove: null, userToMoveTo: null })
        }
      >
        <Modal.Header title="Move Twilio Number Account" closeButton />
        <Modal.Body>
          {moveNumberErrors.map((e) => (
            <div key={e} className="alert alert-danger" role="alert">
              {e}
            </div>
          ))}
          <strong>Number to move: </strong>
          {numberToMove && numberToMove.phone_number}
          <br />
          <p>The destination user cannot have a currently assigned Twilio number.</p>
          <br />
          {!userToMoveTo && (
            <TextInput
              label="Enter the email address for the destination user"
              value={moveAccountUserEmail}
              onChange={(e) => this.setState({ moveAccountUserEmail: e.target.value })}
            />
          )}
          {userToMoveTo && (
            <div>
              <p>
                <strong>Confirm Destination User:</strong>
              </p>
              <p>
                <strong>Name: </strong>
                {userToMoveTo.user_name}
              </p>
              <p>
                <strong>Email: </strong>
                {userToMoveTo.user_email}
              </p>
              <p>
                <strong>Account: </strong>
                {userToMoveTo.account_name}
              </p>
              <p>
                <strong>Account Id: </strong>
                {userToMoveTo.account_id}
              </p>
              <p>
                <strong>Subaccount Sid: </strong>
                {userToMoveTo.subaccount_sid}
              </p>
            </div>
          )}
          <br />
          <br />
          {!userToMoveTo && (
            <Button size="medium" onClick={() => this.moveNumber(false)}>
              Check User
            </Button>
          )}
          {userToMoveTo && (
            <Button size="medium" onClick={() => this.moveNumber(true)}>
              Confirm - Move Number
            </Button>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  moveNumber = (confirm) => {
    const { numberToMove, successAlerts, moveAccountUserEmail, errors } = this.state;
    if (confirm) {
      this.setState({ loading: true, moveAccountModalVisible: false }, () => {
        axios
          .post("/support/twilio/move_number_account", {
            phone_number: numberToMove.phone_number,
            user_email: moveAccountUserEmail,
            confirm: "true",
            // eslint-disable-next-line no-undef
            authenticity_token: ReactOnRails.authenticityToken(),
          })
          .then((res) => {
            this.setState({
              loading: false,
              twilioNumbers: res.data.twilio_numbers,
              trackingNumbers: res.data.tracking_numbers,
              unassignedUsers: res.data.unassigned_users,
              successAlerts: [...successAlerts, res.data.success],
              moveAccountUserEmail: "",
              numberToMove: null,
              userToMoveTo: null,
              moveNumberErrors: [],
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              errors: [...errors, err.response?.data?.errors || "There was an error moving the number"],
            });
          });
      });
    } else {
      axios
        .post("/support/twilio/move_number_account", {
          phone_number: numberToMove.phone_number,
          user_email: moveAccountUserEmail,
          // eslint-disable-next-line no-undef
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          this.setState({
            userToMoveTo: res.data,
            moveNumberErrors: [],
          });
        })
        .catch((err) => {
          this.setState({ moveNumberErrors: err.response?.data?.errors || ["An error occured"] });
        });
    }
  };

  noSubaccountMessage = () => (
    <div className="row">
      <div className="col text-center">
        <h4>Provision a subaccount before managing numbers for this account</h4>
      </div>
    </div>
  );

  developerOnlyMessage = () => (
    <div className="row">
      <div className="col text-center">
        <h4>The Twilio support tool is limited to superusers with developer access</h4>
      </div>
    </div>
  );

  render() {
    const { loading, subaccountSid, setUnAssignModalShow, unAssignedModalCb } = this.state;
    const { developerRole } = this.props;
    return (
      <>
        <UnAssignModal
          show={setUnAssignModalShow}
          onHideHandlr={() => this.setState({ setUnAssignModalShow: false })}
          onSubmitHandlr={() => {
            unAssignedModalCb();
            this.setState({ setUnAssignModalShow: false });
          }}
        />
        {!developerRole && this.developerOnlyMessage()}
        {!subaccountSid && this.noSubaccountMessage()}
        {developerRole && subaccountSid && loading && this.loadingState()}
        {developerRole && subaccountSid && !loading && this.successAlerts()}
        {developerRole && subaccountSid && !loading && this.errorAlerts()}
        {developerRole && subaccountSid && !loading && this.assignedNumbers()}
        {developerRole && subaccountSid && !loading && <hr />}
        {developerRole && subaccountSid && !loading && this.unassignedNumbers()}
        {developerRole && subaccountSid && this.assignModal()}
        {developerRole && subaccountSid && this.moveAccountModal()}
      </>
    );
  }
}

TwilioManageNumbers.propTypes = {
  subaccountSid: PropTypes.string,
  developerRole: PropTypes.bool,
};

TwilioManageNumbers.defaultProps = {
  subaccountSid: "",
  developerRole: false,
};
