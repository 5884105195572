import React, { useMemo } from "react";
import { string } from "prop-types";
import Linkify from "linkify-react";

const mentionMap = new Map();

const LinkifyNotes = ({ markdownContent }) => {
  const noteContent = useMemo(
    () =>
      (typeof markdownContent === "string" ? markdownContent : markdownContent?.message || "").replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        (_, name, id) => {
          mentionMap.set(id, name);
          return `${window.location.host}/people/${id}`;
        },
      ),
    [markdownContent],
  );

  return (
    <Linkify
      className="tw-text-[14px] tw-whitespace-pre-wrap tw-text-neutral-gray-75 tw-font-normal tw-leading-[20px] tw-tracking-normal"
      tagName="div"
      options={{
        defaultProtocol: "https",
        target: "_blank",
        render: {
          url: ({ attributes, content }) => {
            const segments = content.split("/");
            const potentialMentionId = segments[segments.length - 1];
            return (
              <a className="tw-text-theme-primary" {...attributes}>
                {mentionMap.has(potentialMentionId) ? `@${mentionMap.get(potentialMentionId)}` : content}
              </a>
            );
          },
        },
      }}
    >
      {noteContent}
    </Linkify>
  );
};

export default LinkifyNotes;

LinkifyNotes.propTypes = {
  markdownContent: string.isRequired,
};
