/* eslint-disable consistent-return */
import { useEffect } from "react";

const useConnectWindowCallback = ({ setModalData, showModal }) =>
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.App.openLeadRulesModal = ({ leadRuleId, unavailableAgents, unavailablePonds }) => {
        setModalData({ leadRuleId, unavailableAgents, unavailablePonds });
        showModal(true);
      };
      return () => {
        delete window.App.openLeadRulesModal;
      };
    }
  }, [setModalData, showModal]);
export default useConnectWindowCallback;
