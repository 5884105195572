import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@shared/v2/Alert";
import Check from "@shared/v2/Icomoon/Icons/Check";
import { HouseSolidV6, KeySolidV6, ShareNodesSolidV6 } from "@shared/v2/Icomoon/Icons";
import { fetchAgreements, handleAgreementDelete } from "../../actions/personDetailActionCreators";
import CollapsibleElement from "../CollapsibleElement";
import Loading from "../../../Goals/components/Loader";
import Agreementbtn from "./Agreementbtn";
import AgreementSection from "./AgreementSection";
import AddAgreementModal from "./AddAgreementModal/AddAgreementModal";
import SelectDocTypeModal from "../modals/SelectDocTypeModal";
import { DocButton } from "../modals/SelectDocTypeModal";

const AlertToast = ({ msg, className }) => (
  <Alert
    variant="success"
    containerClass={`${className} tw-z-1000 !tw-top-[100px] !tw-fixed !tw-right-5/12 !tw-left-5/12`}
    text={
      <>
        <Check size="xl" className="tw-w-16px tw-h-16px tw-mb-[-4px]" />
        <span className="tw-capitalize tw-pl-[9px]">{msg}</span>
      </>
    }
    role="alert"
  />
);

const getOption = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const Agreements = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const agreements = useSelector((state) => state.personDetail.agreements);
  const displayToast = useSelector((state) => state.personDetail.displayToast);
  const uploadingDocument = useSelector((state) => state.personDetail.uploadingDocument);
  const availableTransactions = useSelector((state) => getOption(state.personDetail.available_transactions));
  const agreementsButtonText = agreements.length === 0 ? "" : "+ ADD";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);

  const [agreementName, setAgreementName] = useState("buyer");

  const onClickAgreement = (agreementName) => {
    setIsAgreementModalOpen(true);
    setAgreementName(agreementName);
  };

  const onAddClick = () => {
    setIsModalOpen(true);
    setAgreementName("buyer");
  };

  const selectDocTypeCloseModal = () => {
    setIsModalOpen(false);
  };

  const agreementCloseModal = () => {
    setIsAgreementModalOpen(false);
  };

  const setOpenAgreementModal = (documentType) => {
    setAgreementName(documentType);
    setIsAgreementModalOpen(true);
    selectDocTypeCloseModal();
  };

  useEffect(() => {
    if (person.data?.id) dispatch(fetchAgreements(person.data));
  }, [person.data?.id]);

  return (
    <>
      {displayToast?.show && displayToast?.msg && <AlertToast msg={displayToast?.msg} />}
      {displayToast?.transactionMsg && (
        <AlertToast className={displayToast?.msg ? "!tw-mt-[50px]" : ""} msg={displayToast?.transactionMsg} />
      )}

      <CollapsibleElement
        id="pdp-agreements"
        title="Agreements"
        buttonText={agreementsButtonText}
        buttonClickFunction={onAddClick}
      >
        {!person.data && <Loading />}

        <div id="person-agreements-card" className="tw-flex tw-flex-col tw-gap-[12px]">
          {agreements.length === 0 && (
            <>
              <Agreementbtn isEmptyState agreementName="buyer" onClickFunc={onClickAgreement} />
              <Agreementbtn isEmptyState agreementName="seller" onClickFunc={onClickAgreement} />
              <Agreementbtn isEmptyState agreementName="referral" onClickFunc={onClickAgreement} />
            </>
          )}
          {agreements.length > 0 &&
            agreements.map((agreement) => (
              <AgreementSection
                key={agreement.id}
                agreement={agreement}
                onDelete={(...args) => dispatch(handleAgreementDelete(...args))}
                person={person}
              />
            ))}
          {isModalOpen && (
            <SelectDocTypeModal modalOpen={isModalOpen} closeModal={selectDocTypeCloseModal}>
              <DocButton
                text="Buyer Agreement"
                doctype="buyer"
                Icon={KeySolidV6}
                onClickFunc={setOpenAgreementModal}
              />
              <DocButton
                text="Seller Agreement"
                doctype="seller"
                Icon={HouseSolidV6}
                onClickFunc={setOpenAgreementModal}
              />
              <DocButton
                text="Referral Agreement"
                doctype="referral"
                Icon={ShareNodesSolidV6}
                onClickFunc={setOpenAgreementModal}
              />
            </SelectDocTypeModal>
          )}
          {isAgreementModalOpen && (
            <AddAgreementModal
              person={person}
              agreementName={agreementName}
              modalOpen={isAgreementModalOpen}
              closeModal={agreementCloseModal}
              uploadingDocument={uploadingDocument}
              availableTransactions={availableTransactions}
            />
          )}
        </div>
      </CollapsibleElement>
    </>
  );
};

export default Agreements;
