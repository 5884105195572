import axios from "axios";
import moment from "moment";

const ROUTE_OPTIONS = {
  agentActivity: "agent_stats",
  leadSource: "agent_stats/lead_source_report",
  tasks: "agent_stats/task_report",
  appointments: "agent_stats/appointments_report",
  agentCalls: "agent_stats/agent_calls_report",
  agentTexts: "agent_stats/agent_texts_report",
};

const filterByForAgentActivity = (filterRange, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "FILTER_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    agents: data.agents,
    total_leads: data.total_leads,
    total_emails: data.total_emails,
    total_calls: data.total_calls,
    total_texts: data.total_texts,
    total_contacted: data.total_contacted,
  };
};

const filterByForAgentCalls = (filterRange, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "CALL_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    agents: data.agents,
    total_calls: data.total_calls,
  };
};

const filterByForAgentTexts = (filterRange, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "TEXT_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    agents: data.agents,
  };
};

const filterByForTasks = (filterRange, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "FILTER_TASK_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    adminTasks: data.admin,
    agentTasks: data.agent,
    adminTotalTasks: data.admin_total_assigned_tasks,
    adminTotalCompletedTasks: data.admin_total_completed_tasks,
    adminTotalOnTimeTasks: data.admin_total_on_time_tasks,
    adminTotalLateTasks: data.admin_total_late_tasks,
    agentTotalTasks: data.agent_total_assigned_tasks,
    agentTotalCompletedTasks: data.agent_total_completed_tasks,
    agentTotalOnTimeTasks: data.agent_total_on_time_tasks,
    agentTotalLateTasks: data.agent_total_late_tasks,
  };
};

const filterByForAppointments = (filterRange, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "APPOINTMENT_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    agents: data.agents,
  };
};
const filterByForLeadSource = (filterRange, userId, data) => {
  localStorage.setItem("date_range", filterRange);
  return {
    type: "FILTER_SOURCE_REPORT",
    dateRange: localStorage.getItem("date_range") || "today",
    brivitySources: data.brivity_sources.results,
    otherSources: data.other_sources.results,
    userId,
    brivityTotalLeadSourceCount: data.brivity_sources.total,
    brivityStatusCounts: data.brivity_sources.status_counts,
    otherTotalLeadSourceCount: data.other_sources.total,
    otherStatusCounts: data.other_sources.status_counts,
  };
};

const setActiveFilters = (selectedFilter, isChecked) => {
  return {
    type: "SET_ACTIVE_FILTERS",
    selectedFilter,
    isChecked,
  };
};

const defaultStartEndDates = () => {
  const customDates = JSON.parse(localStorage.getItem("custom_dates"));
  if (customDates) {
    return {
      start: moment(customDates.start).startOf("day").toDate(),
      end: moment(customDates.end).toDate(),
    };
  } else {
    return { start: moment().startOf("day").subtract(7, "days").toDate(), end: moment().toDate() };
  }
};

export const sortBy = (sortKey) => {
  return {
    type: "SORT_REPORT",
    sortKey,
  };
};

export const handleStatusCheckboxChange = (filter, isChecked) => {
  return (dispatch) => dispatch(setActiveFilters(filter, isChecked));
};

export const handleDateRangeSelect = (range, activeReport, userId, filters, allLeadsOrTasks) => {
  return range === "custom"
    ? handleCustomRangeSelect(range)
    : fetchNewData(range, activeReport, userId, filters, null, allLeadsOrTasks);
};

const handleCustomRangeSelect = (range) => {
  return (dispatch) =>
    dispatch({
      type: "IS_CUSTOM_RANGE_ACTIVE",
      customRangeVisible: true,
      dateRange: range,
      customDates: defaultStartEndDates(),
    });
};

export const handleCustomDaySelect = (range) => {
  return (dispatch) => dispatch({ type: "SET_CUSTOM_RANGE", range });
};

export const setLeadsConstraint = (isAllLeadsInRange) => {
  return (dispatch) => dispatch({ type: "SET_LEADS_CONSTRAINT", isAllLeadsInRange });
};
export const setTasksConstraint = (isAllTasksInRange) => {
  return (dispatch) => dispatch({ type: "SET_TASKS_CONSTRAINT", isAllTasksInRange });
};
export const setAppointmentsConstraint = (isAllAppointmentsInRange) => {
  return (dispatch) => dispatch({ type: "SET_APPOINTMENTS_CONSTRAINT", isAllAppointmentsInRange });
};
export const setIsFetching = (isFetching) => {
  return (dispatch) => dispatch({ type: "IS_FETCHING", isFetching });
};
export const setError = (error) => {
  return (dispatch) => dispatch({ type: "SET_ERROR", error });
};

const filterByReport = (activeReport, range, data, userId = null) => {
  switch (activeReport) {
    case "teamDashboard":
      return {};
    case "agentActivity":
      return filterByForAgentActivity(range, data);
    case "agentCalls":
      return filterByForAgentCalls(range, data);
    case "agentTexts":
      return filterByForAgentTexts(range, data);
    case "leadSource":
      return filterByForLeadSource(range, userId, data);
    case "tasks":
      return filterByForTasks(range, data);
    case "appointments":
      return filterByForAppointments(range, data);
    default:
      return filterByForAgentActivity(range, data);
  }
};

export const fetchNewData = (
  range,
  activeReport,
  userId = null,
  filters,
  customDates = null,
  allLeadsOrTasks = false,
) => {
  const isCustomRange = range === "custom";
  customDates != null && isCustomRange && localStorage.setItem("custom_dates", JSON.stringify(customDates));
  customDates = JSON.parse(localStorage.getItem("custom_dates"));
  const convertedRange = isCustomRange
    ? `start_date=${moment(customDates.start).format().replace("+", "%2B")}&end_date=${moment(customDates.end)
        .format()
        .replace("+", "%2B")}`
    : range;
  const val = parseFloat(range);
  const route = ROUTE_OPTIONS[activeReport];

  const rangeParam = isCustomRange
    ? convertedRange
    : (val | 0) === val
    ? `days=${convertedRange}`
    : `date_range=${convertedRange}`;

  return (dispatch) => {
    dispatch(setError(false));
    if (isCustomRange) {
      dispatch(handleCustomRangeSelect(range));
    } else {
      dispatch({ type: "IS_CUSTOM_RANGE_ACTIVE", customRangeVisible: false });
    }
    axios
      .get(`/${route}?${rangeParam}&user_id=${userId}&filters=${filters}&all_in_range=${allLeadsOrTasks}`, {
        headers: { "Content-Type": "application/json", Accept: "application/json" },
      })
      .then((res) => {
        dispatch(filterByReport(activeReport, range, res.data, userId));
        dispatch(setIsFetching(false));
        dispatch(setError(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setIsFetching(false));
        dispatch(setError(true));
      });
  };
};
