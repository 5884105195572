import React from "react";
import PropTypes from "prop-types";
import CardLabel from "../BusinessDashboard/CardLabel";
import colors from "./helpers/colors";

const StatCardLink = ({ className, label, link }) =>
  link ? (
    <a className={className} href={link}>
      {label}
    </a>
  ) : null;

StatCardLink.defaultProps = {
  className: undefined,
  link: undefined,
};

StatCardLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  link: PropTypes.string,
};

const StatCard = ({ asCard, className, label, stats = {}, link, mainStat, tooltip }) => {
  const mainValue = stats[mainStat]?.value;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      role={link ? "button" : undefined}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={link ? 0 : undefined}
      onClick={() => {
        if (link) window.location.assign(link);
      }}
      onKeyDown={(e) => {
        if (link && (e.key === "Enter" || e.key === " ")) {
          e.preventDefault();
          window.location.assign(link);
        }
      }}
      className={`tw-whitespace-nowrap tw-bg-white tw-flex tw-justify-start tw-px-[12px] tw-flex-1 ${
        (link && "tw-cursor-pointer tw-transition hover:tw-scale-105") || ""
      } ${
        (asCard &&
          "tw-p-[24px] tw-border-[1px] tw-border-solid tw-border-neutral-gray-10 tw-rounded-[8px]") ||
        ""
      } ${className}`}
    >
      <div className="tw-flex tw-flex-col tw-gap-y-[8px]">
        <div className="tw-text-14d tw-text-gray-50 tw-font-semibold">
          {label} {tooltip}
        </div>
        <div
          className={`tw-text-[28px] ${
            colors.theme === "brivity" ? "tw-text-theme-primary" : "tw-text-theme-primary-alternative"
          }`}
        >
          {typeof mainValue === "undefined" ? "N/A" : mainValue}
        </div>
        {Object.keys(stats)
          .filter((key) => key !== mainStat)
          .map((key) => (
            <CardLabel
              className="tw-gap-x-[4px] !tw-flex-col"
              key={key}
              label={stats[key].label || key}
              value={stats[key].value}
            />
          ))}
      </div>
    </div>
  );
};

StatCard.defaultProps = {
  asCard: false,
  className: "",
  label: "",
  mainStat: "Units",
  link: undefined,
  stats: undefined,
  tooltip: null,
};

StatCard.propTypes = {
  asCard: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  stats: PropTypes.shape({}),
  mainStat: PropTypes.string,
  link: PropTypes.string,
  tooltip: PropTypes.node,
};

export default StatCard;
