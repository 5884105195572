/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MapLocationSolidV6 = (props) => (
  <Icon {...props} name="Map Location Solid V6">
    <path d="M7.589 9.531c-.892-1.089-2.947-3.792-2.947-5.308C4.642 2.382 6.159.89 7.975.89a3.338 3.338 0 0 1 3.358 3.333c0 1.517-2.031 4.219-2.922 5.308a.495.495 0 0 1-.822 0zm3.967 5.33V6.455a9.439 9.439 0 0 0 .309-.675l3.222-1.289a.666.666 0 0 1 .914.619v7.522a.67.67 0 0 1-.419.619l-4.025 1.608zM.419 6.092l3.403-1.361c.067.394.203.786.356 1.153.081.192.169.381.267.572v6.983l-3.53 1.414c-.438.15-.914-.147-.914-.619V6.712c0-.272.166-.542.419-.619zm10.248 8.805-5.358-1.525v-5.4a28.54 28.54 0 0 0 1.586 2.114 1.383 1.383 0 0 0 2.211 0 23.25 23.25 0 0 0 1.561-2.114v6.925z" />
  </Icon>
);

export default MapLocationSolidV6;
