/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TriangleExclamationRegularV6 = (props) => (
  <Icon {...props} name="Triangle Exclamation Regular V6">
    <path d="M15.822 13.031 9.156 1.656C8.9 1.218 8.45 1 8 1s-.9.219-1.184.656L.154 13.031C-.33 13.903.309 15 1.337 15h13.331c1.024 0 1.664-1.094 1.155-1.969zM1.643 13.5 7.972 2.65l6.384 10.85H1.643zM8 10.534a.982.982 0 1 0 0 1.965.982.982 0 0 0 0-1.965zM7.25 5.75v3a.75.75 0 0 0 1.5 0v-3a.75.75 0 0 0-1.5 0z" />
  </Icon>
);

export default TriangleExclamationRegularV6;
