// for setting > users > header column sort
// eslint-disable-next-line import/prefer-default-export
export const sortListByKey = (title, setList, ascOrder = true) => {
  setList((prev) => [
    ...prev.sort((a, b) => {
      if (a[title] < b[title] || (!a[title] && b[title])) {
        return ascOrder ? -1 : 1;
      }
      if (a[title] > b[title] || (a[title] && !b[title])) {
        return ascOrder ? 1 : -1;
      }

      return 0;
    }),
  ]);
};
