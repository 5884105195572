import React, { useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import colors from "../../../Reports/components/DashboardShared/helpers/colors";

const PORTAL_ID = "ringchart-tooltip-portal";

const Tooltip = ({ left, top, tooltip }) => {
  const ref = useRef(null);
  const hide = left === null || top === null;
  const width = ref.current?.offsetWidth || 0;
  const height = ref.current?.offsetHeight || 0;
  return (
    <div
      ref={ref}
      role="tooltip"
      className={`tw-absolute tw-text-14d tw-text-white ${
        colors.theme === "place" ? "tw-bg-black" : "tw-bg-neutral-gray-75"
      } tw-rounded-[4px] tw-p-[8px] after:tw-content-[''] after:tw-w-[12px] after:tw-h-[12px] after:tw-absolute ${
        colors.theme === "place" ? "after:tw-bg-black" : "after:tw-bg-neutral-gray-75"
      } after:tw-bottom-[-6px] after:tw-left-[calc(50%-6px)] after:tw-rotate-45`}
      style={{ left: left - width / 2, top: top - height, visibility: hide ? "hidden" : "initial" }}
    >
      {tooltip.map((t) => (
        <div key={t}>{t}</div>
      ))}
    </div>
  );
};

Tooltip.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  tooltip: PropTypes.arrayOf(PropTypes.string),
};

Tooltip.defaultProps = {
  left: null,
  top: null,
  tooltip: null,
};

const TooltipContainer = ({ left, top, tooltip }) => {
  let tooltipContainer = document.getElementById(PORTAL_ID);
  if (!tooltipContainer) {
    tooltipContainer = document.createElement("div");
    tooltipContainer.style.pointerEvents = "none";
    tooltipContainer.id = PORTAL_ID;
    document.body.appendChild(tooltipContainer);
  }
  return ReactDOM.createPortal(<Tooltip left={left} top={top} tooltip={tooltip} />, tooltipContainer);
};

export default TooltipContainer;
