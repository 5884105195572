/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EyeSolidV3 = (props) => (
  <Icon {...props} name="Eye Solid V3">
    <path d="M15.815 7.323A9.053 9.053 0 0 0 8 2.889 9.055 9.055 0 0 0 .185 7.323a1.333 1.333 0 0 0 0 1.354A9.053 9.053 0 0 0 8 13.111a9.055 9.055 0 0 0 7.815-4.434 1.333 1.333 0 0 0 0-1.354zM8 11.778c-2.088 0-3.778-1.69-3.778-3.778S5.912 4.222 8 4.222 11.778 5.912 11.778 8 10.088 11.778 8 11.778zM10.889 8a2.888 2.888 0 1 1-5.438-1.36v.001a1.19 1.19 0 1 0 1.19-1.19H6.64A2.89 2.89 0 0 1 10.889 8z" />
  </Icon>
);

export default EyeSolidV3;
