import React from "react";

// Copied from:
// https://gitlab.brivity.com/brivity/notifications/-/blob/master/src/app/views/listing_alerts/_block_separator.html.erb
const EmailBodyBlockSeparator = () => {
  return (
    <table
      className="module"
      role="module"
      data-type="spacer"
      border="0"
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      style={{ tableLayout: "fixed" }}
      data-muid="B63d6Y29hfQ7e7HQjxqn8"
    >
      <tbody>
        <tr>
          <td style={{ padding: "0px 0px 20px 0px" }} role="module-content" bgcolor=""></td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmailBodyBlockSeparator;
