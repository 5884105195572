import React from "react";
import PropTypes from "prop-types";
import { VisibilityHidden, VisibilityVisible } from "../../../../shared/v2/Icomoon";

const FeedbackVisibility = ({ externallyVisible, iconSize, ...otherProps }) =>
  externallyVisible ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <VisibilityVisible size={iconSize} className="tw-text-neutral-gray-75" {...otherProps} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <VisibilityHidden size={iconSize} className="tw-text-neutral-gray-75" {...otherProps} />
  );

FeedbackVisibility.propTypes = {
  externallyVisible: PropTypes.bool.isRequired,
  iconSize: PropTypes.oneOf(["s", "m", "l", "xl", "xxl", "xxxl"]),
};

FeedbackVisibility.defaultProps = {
  iconSize: "m",
};

export default FeedbackVisibility;
