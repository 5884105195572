import React from "react";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";

const DeleteSourceModal = ({ source, onDelete, onHide }) => (
  <Modal
    show={Boolean(source)}
    onHide={onHide}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Delete Source" closeButton />
    <Modal.Body>Are you sure you want to delete this source?</Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onHide}>
        Cancel
      </Button>
      <Button size="medium" schema="warning" onClick={onDelete}>
        Yes, Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteSourceModal;
