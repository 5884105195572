/**
 * Helper that creates an error object to be displayed inside of an `ErrorBanner`.
 *
 * @param {React.ReactNode} message The error message to render. Can be a
 *   component or anything usually returned from a render function (e.g. a
 *   string).
 * @param {function} [onShow] Optional callback, will get called when this error
 *   is selected on the error banner. Useful to focus a specific field causing
 *   an error in a form submission, for example.
 * @returns An object for use in an `ErrorBanner`'s `errors` array prop.
 */
export const errorForBanner = (message, onShow = null) => ({ render: message, onShow });

export default errorForBanner;
