import React from "react";
import { string } from "prop-types";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";

import {
  REFERRALPARTNER_AVATAR,
  REFERRALPARTNER_NAME,
  REFERRALPARTNER_TEAM,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import PrimaryPerson from "../../shared/PrimaryPerson";

const PERSON_TYPE = "Referral Partner";

const ReferralPartner = ({ team, avatar, name }) => (
  <PrimaryPerson
    name={name || "No partner assigned"}
    avatarImg={avatar}
    descriptor={team}
    personType={PERSON_TYPE}
    containerClass="tw-h-60px"
  />
);

export default tdpDetails({
  WrappedComponent: ReferralPartner,
  states: [REFERRALPARTNER_TEAM, REFERRALPARTNER_AVATAR, REFERRALPARTNER_NAME],
});

ReferralPartner.propTypes = {
  team: string,
  avatar: string,
  name: string,
};

ReferralPartner.defaultProps = {
  team: null,
  avatar: null,
  name: null,
};
