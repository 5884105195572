import React from "react";
import { bool, func, string } from "prop-types";

import { useDependencyWithCleanUp } from "../../../../shared/hookHelpers";

import Modal from "../../../../shared/v2/Modal";
import ModalField from "../../../shared/ModalField";
import Button from "../../../../shared/v2/Button";

const ModalEditLink = ({
  show,
  onHideHandlr,
  onRemoveHandlr,
  onSaveHandlr,
  titleValue,
  linkValue,
  setTitleValue,
  setLinkValue,
}) => (
  <Modal
    show={show}
    onHide={onHideHandlr}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    closeButton
  >
    <Modal.Header title="Edit Link" className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px">
      <ModalField label="Title" value={titleValue} onChange={(e) => setTitleValue(e.target.value)} />
      <ModalField label="Link" value={linkValue} onChange={(e) => setLinkValue(e.target.value)} />
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="secondary" onClick={onHideHandlr}>
        CANCEL
      </Button>
      <div className="tw-flex tw-gap-x-[8px]">
        <Button size="medium" schema="warning" onClick={onRemoveHandlr}>
          REMOVE
        </Button>
        <Button size="medium" schema="primary" onClick={onSaveHandlr}>
          SAVE
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

const WrapperModalEditLink = ({ show, onHide, onRemove, onSave, titleValue, linkValue }) => {
  const [_titleValue, setTitleValue] = useDependencyWithCleanUp(titleValue);
  const [_linkValue, setLinkValue] = useDependencyWithCleanUp(linkValue);

  const onHideHandlr = () => {
    setLinkValue(linkValue);
    setTitleValue(titleValue);
    onHide();
  };

  const onSaveHandlr = () => {
    if (titleValue !== _titleValue || linkValue !== _linkValue) onSave(_titleValue, _linkValue);
    onHideHandlr();
  };

  const onRemoveHandlr = () => {
    onRemove();
    onHideHandlr();
  };

  return (
    <ModalEditLink
      show={show}
      onHideHandlr={onHideHandlr}
      onRemoveHandlr={onRemoveHandlr}
      onSaveHandlr={onSaveHandlr}
      titleValue={_titleValue}
      linkValue={_linkValue}
      setTitleValue={setTitleValue}
      setLinkValue={setLinkValue}
    />
  );
};

export default WrapperModalEditLink;

ModalEditLink.propTypes = {
  show: bool,
  onHideHandlr: func,
  onRemoveHandlr: func,
  onSaveHandlr: func,
  titleValue: string,
  setTitleValue: func,
  linkValue: string,
  setLinkValue: func,
};

ModalEditLink.defaultProps = {
  show: false,
  onHideHandlr: () => {},
  onRemoveHandlr: () => {},
  onSaveHandlr: () => {},
  titleValue: "",
  setTitleValue: () => {},
  linkValue: "",
  setLinkValue: () => {},
};

WrapperModalEditLink.propTypes = {
  show: bool,
  onHide: func,
  onRemove: func,
  onSave: func,
  titleValue: string,
  linkValue: string,
};

WrapperModalEditLink.defaultProps = {
  show: false,
  onHide: () => {},
  onRemove: () => {},
  onSave: () => {},
  titleValue: "",
  linkValue: "",
};
