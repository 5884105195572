import React, { Component } from 'react';
import alerts from './listing_alerts.module.css';
import form_css from '../form.module.css';
import AdvancedOptions from "./AdvancedOptions";
import AddCCEmails from "./AddCCEmails";
import CCEMail from "./CCEmail";
import MatchingResults from "./MatchingResults";
import Map from "./Map";
import TemplateSelect from './TemplateSelect';
import SearchAutocomplete from "./SearchAutocomplete";
import Error from "../../../shared/Error"
import {
  dropDownOptions,
  bathroomOptions,
  lotSizeOptions,
  mapStateToAlert,
  storiesOptions,
  searchFrequencyOptions,
  sqFTOptions,
  yearOptions,
  mapAlertToState
} from '../../../shared/ListingAlertHelpers';
import {
  getInstantAlertsEnabled,
  getAvailablePropertyTypes,
  getAvailableFeatureFilters,
  getWebsiteDomain,
  getListingCounts,
  getQueryStringForIdx,
  createAlert,
  updateAlert,
  getTemplates
} from './service';
import TextInput from '@shared/v2/TextInput';
import Dropdown from '@shared/v2/Dropdown';
import TextToggle from '@shared/v2/TextToggle';
import Modal from '@shared/v2/Modal';
import Checkbox from '@shared/v2/Checkbox';
import Button from '@shared/v2/Button';
import FilterPill from '@shared/v2/FilterPill';
import { formatPrice } from '../../../SearchListings/components/helpers';

class ListingAlert extends Component {
  doesLeadHaveApp = !!this.props.person.home_app_last_login;

  constructor(props) {
    super(props);
    const alert = mapAlertToState(this.props, this.doesLeadHaveApp);

    this.state = {
      instantAlertsEnabled: false,
      advancedOptions: {}, // These are the available options from the listings API, grouped by view category, not where they data is
      geoCodeAccountAddress: this.props.geoCodeAccountAddress,
      listingAlertCounts: undefined,
      propertyTypes: [], // This is, unintuitively, the available property types from the listings api
      websiteDomain: undefined,
      submitDisabled: false,
      showUrl: false,
      templateId: this.props.templateId,
      templateName: this.props.templateName,
      templates: [],
      ...alert
    };
  }

  async componentDidMount() {
    const personId = this.props.person.id;

    if (!personId) {
      return;
    }

    if (this.state.mlsIds.length === 0) {
      this.setState({ errorMessage: 'No MLSs are configured for your account. Please contact support.', submitDisabled: true })
    } else {
      const promises = [
        getInstantAlertsEnabled(personId),
        getAvailablePropertyTypes(personId, this.props.mlsIds),
        getAvailableFeatureFilters(personId, this.props.mlsIds),
        getTemplates(personId)
      ];

      if (!this.state.websiteDomain) {
        promises.push(getWebsiteDomain(personId));
      }

      const [ instantAlertsEnabled, propertyTypes, advancedOptions, templates, websiteDomain ] = await Promise.all(promises);

      this.setState({ instantAlertsEnabled, propertyTypes, advancedOptions, templates });

      if (websiteDomain) {
        this.setState({ websiteDomain });
      }

      if (this.props.type === 'edit') {
        this.getCountsAndUrl()
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.props.isCreatingTemplate) {
      return;
    }

    const noTemplateChosen = !this.state.templateId;
    const newTemplateChosen = this.state.templateId && prevState.templateId !== this.state.templateId;

    if (noTemplateChosen || newTemplateChosen) {
      return;
    }

    const previousAlert = mapStateToAlert(prevState);
    const currentAlert = mapStateToAlert(this.state);

    if (JSON.stringify(previousAlert) === JSON.stringify(currentAlert)) {
      return;
    }

    this.setState({ templateId: undefined });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSelect = (name, e) => {
    this.setState({ [name]: e.value }, () => {
      this.getCountsAndUrl();
    })
  }

  onTemplateChosen = (templateId) => {
    const templateData = this.state.templates.find(({id}) => id === templateId).query;
    const alert = mapAlertToState({ ...templateData, alertId: this.state.search_id }, this.doesLeadHaveApp);

    this.setState({ ...alert, templateId }, () => {
      this.getCountsAndUrl();
    });
  }

  onMouseOut = (e) => {
    // TODO: This seems like a bug. If i have a min price of 100,000, and set it to empty (effectively 0), that means a new set of listings
    if (e.target.value !== "") {
      this.getCountsAndUrl()
    }
  }

  toggleButtons = (button) => {
    if (button === 'search-by-terms') {
      this.setState({ searchingByTerm: true });
    } else if (button === 'search-by-map') {
      this.setState({ searchingByTerm: false });
    } else if (button === 'sending-emails') {
      this.setState({ activeForEmails: true })
    } else if (button === 'not-sending-emails') {
      this.setState({ activeForEmails: false })
    } else if (button === 'sending-notifications') {
      this.setState({ activeForAppNotifications: true })
    } else if (button === 'not-sending-notifications') {
      this.setState({ activeForAppNotifications: false })
    }
  }

  togglePropertyTypes = (propertyType) => {
    if (this.state.property_types.includes(propertyType)) {
      this.setState((prevState) => ({ property_types: prevState.property_types.filter(property_type => property_type !== propertyType) }), () => { this.getCountsAndUrl() })
    } else {
      this.setState((prevState) => ({ property_types: prevState.property_types.concat(propertyType) }), () => { this.getCountsAndUrl() })
    }
  }

  togglePhotosAvailable = () => {
    this.setState(prevState => ({ photos_available: !prevState.photos_available }), () => this.getCountsAndUrl())
  }

  isPropertyTypeSelected = (propertyType) => this.state.property_types.includes(propertyType);

  toggleAdvanced = (category, value) => {
    if (this.isFeatureValueSelected(category, value)) {
      this.removeAdvancedFeatureValue(category, value);
    } else {
      this.addAdvancedFeatureValue(category, value);
    }
  };

  isFeatureValueSelected = (category, value) => {
    return this.state[category] && this.state[category].fields.some(a => a.term === value);
  };

  addAdvancedFeatureValue = (category, value) => {
    this.setState(prevState => {
      const newValue = {
        term: value,
        matchAny: false,
        excludeTerm: false
      };

      const newFieldList = prevState[category]
        ? [ ...prevState[category].fields, newValue ]
        : [ newValue ];

      return {
        [category]: {
          fields: newFieldList
        }
      };
    }, this.getCountsAndUrl);
  };

  removeAdvancedFeatureValue = (category, value) => {
    this.setState(prevState => {
      const newFieldsList = prevState[category].fields.filter(f => f.term !== value);

      if (!newFieldsList.length) {
        return { [category]: null };
      }

      return {
        [category]: {
          fields: newFieldsList
        }
      };
    }, this.getCountsAndUrl);
  };

  getCountsAndUrl = async () => {
    if (!this.state.multi_search_array?.length && !this.state.polygon?.length) {
      this.setState({ showUrl: false });
      return;
    }

    const alert = mapStateToAlert(this.state);
    const countsPromise = getListingCounts(this.props.person.id, alert);
    const urlPromise = getQueryStringForIdx(this.props.person.id, alert);

    const [ listingCount, queryString ] = await Promise.all([countsPromise, urlPromise]);

    this.setState({
      showUrl: true,
      listingAlertCounts: listingCount,
      search_url: `https://${this.state.websiteDomain}/${queryString}`
    });
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ submitDisabled: true })

    if (this.props.handleFormSubmit !== undefined) {
      this.props.handleFormSubmit(mapStateToAlert(this.state), (response) => {
        if (response.response.status == 422) {
          this.setState({ errorMessage: response.response.data.content, submitDisabled: false })
        }
      });

      return;
    }

    try {
      if (this.props.type === 'edit') {
        await updateAlert(this.props.person.id, this.state.search_id, mapStateToAlert(this.state));
      } else {
        await createAlert(this.props.person.id, mapStateToAlert(this.state));
      }

      this.props.closeListingAlertModal('success');
    } catch (e) {
      this.setState({ errorMessage: e.response.data.content, submitDisabled: false});
    }
  }

  handleAddSearchTerm = (term) => {
    this.setState(prevState => ({
        multi_cat_array: [...prevState.multi_cat_array, term.multiCat],
        multi_search_array: [...prevState.multi_search_array, term.multiSearch]
      }),
      () => {
        this.getCountsAndUrl();
      }
    )
  }

  handleRemoveSearchTerm = (term) => {
    const indexOfTerm = this.state.multi_search_array.indexOf(term)
    this.setState(prevState => ({
          multi_cat_array: prevState.multi_cat_array.filter((term, index) => index !== indexOfTerm),
          multi_search_array: prevState.multi_search_array.filter((term, index) => index !== indexOfTerm)}
      ), () => {
        if (this.state.multi_search_array.length) {
          this.getCountsAndUrl();
        } else {
          this.setState({ showUrl: false });
        }
      }
    )
  }

  handlePolygonUpdate = (coords) => {
    this.setState({ polygon: coords })
    this.getCountsAndUrl()
  }

  handleAddEmail = (email) => {
    if (this.state.cc_emails.indexOf(email) === -1) {
      this.setState(prevState => ({ cc_emails: [...prevState.cc_emails, email] }))
    }
  }

  handleRemoveEmail = (emailToRemove) => {
    this.setState(prevState => ({ cc_emails: prevState.cc_emails.filter(email => email !== emailToRemove) }))
  }

  formatFeatureFields = () => {
    const fields = ['propertySubTypes', 'poolFeatures', 'parkingFeatures', 'propertyFeatures', 'interiorFeatures', 'exteriorFeatures', 'communityFeatures', 'greenFeatures', 'view', 'heating', 'cooling', 'lotFeatures', 'homeStyle', 'financialInformation', 'legacyFields'];
    const chosenFields = [];
    fields.forEach(f => {
      if (this.state[f]) {
        this.state[f].fields.forEach(cf => {
          chosenFields.push({
            ...cf,
            search_category: f
          });
        });
      }
    });

    return chosenFields;
  }

  hasRequiredFields = () => {
    const hasLocation = this.state.searchingByTerm
      ? this.state.multi_search_array?.length > 0
      : this.state.polygon?.length > 0;

    return this.state.search_name && hasLocation;
  }

  render() {
    const { templateName, instantAlertsEnabled, search_name, searchingByTerm, price_min, price_max, numbeds_min, numbeds_max, numbaths_min, numbaths_max, sqfeet_min, sqfeet_max, lotval_min, lotval_max, year_min, year_max, garage_min, garage_max, stories, propertyTypes, advancedOptions, activeForEmails, activeForAppNotifications, frequency, polygon, errorMessage, cc_emails, submitDisabled } = this.state;
    const { validIds, selectedIds, submitButton } = this.props;
    const featureFields = this.formatFeatureFields();

    const propertyAdvancedOptions = [];
    const financialAdvancedOptions = [];
    for (const opt of Object.keys(advancedOptions)) {
      if (!["Financial Information", "Financial Features", "Stories"].includes(opt)) {
        propertyAdvancedOptions.push(opt);
      }

      if (["Financial Information", "Financial Features"].includes(opt)) {
        financialAdvancedOptions.push(opt);
      }
    }

    return (
      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        dialogClassName="tw-w-full"
        contentClassName="tw-m-auto !tw-p-0 tw-max-h-[90vh] tw-flex tw-flex-col tw-max-w-[720px]"
        show
        onHide={this.props.closeListingAlertModal}
        backdrop="static"
      >
        <Modal.Header
          title={this.props.title}
          description={this.props.alertInstructions}
          className="tw-p-32px"
          closeButton
        />
        <Modal.Body className="tw-overflow-y-auto tw-px-32px tw-relative">
          <hr className="!tw-mt-0 !tw-mb-24px" />
          <div>
            { errorMessage && <Error errorMessage={errorMessage} /> }
          </div>
          {selectedIds > 0 && ( // This should mean it is a 'mass' alert creation event
            <div>
              <div className={`info-alert alert ${validIds > 0 ? "tw-text-[#177335] tw-bg-semantic-green-5 tw-border-semantic-green-5" : "tw-text-[#a81e19] tw-bg-semantic-red-5 tw-border-semantic-red-5"}`}>
                <span>{`${validIds} of the ${selectedIds} selected contacts have a valid email address to create a Listing Alert`}</span>
              </div>
            </div>
          )}
          {this.props.isCreatingTemplate ? (
            <div className="tw-flex tw-gap-24px tw-mb-24px">
              <div className={`tw-flex tw-flex-col tw-w-full ${form_css.required}`}>
                <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Template Name</label>
                <TextInput
                  name="templateName"
                  onChange={this.onChange}
                  value={templateName}
                  helperText="This is the name your team will see when selecting a template"
                />
              </div>
            </div>
          ) : (
            <div className="tw-mb-24px">
              <TemplateSelect templates={this.state.templates} templateId={this.state.templateId} onTemplateChosen={this.onTemplateChosen} />
            </div>
          )}
          <div className="tw-flex tw-gap-24px tw-mb-24px">
            <div className={`tw-flex tw-flex-col tw-w-full ${form_css.required}`}>
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Listing Alert Name</label>
              <TextInput
                name="search_name"
                onChange={this.onChange}
                value={search_name}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-w-full">
              <AddCCEmails addEmail={this.handleAddEmail} />
              {cc_emails.length > 0 && (
                <div className="tw--ml-10px">
                  {cc_emails.map(email => <CCEMail key={email} email={email} removeEmail={this.handleRemoveEmail} />)}
                </div>
              )}
            </div>
          </div>
          <div className="tw-flex tw-gap-24px tw-mb-24px">
            <div className="tw-flex tw-flex-col tw-w-full">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50 !tw-mb-12px">Email Alert Status</label>
              <div className="tw-flex tw-gap-8px">
                <Checkbox
                  checked={activeForEmails}
                  className="tw-cursor-pointer"
                  onChange={() => this.toggleButtons(activeForEmails ? "not-sending-emails" : "sending-emails")}
                />
                <span>Send Email Alerts</span>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-w-full">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Email Alerts Frequency</label>
              <div>
                <Dropdown
                  isSearchable={false}
                  isClearable={false}
                  name="frequency"
                  options={searchFrequencyOptions(instantAlertsEnabled)}
                  onChange={(e) => this.onSelect("frequency", e)}
                  placeholder='Select...'
                  value={searchFrequencyOptions(instantAlertsEnabled).find(o => o.value === frequency)}
                  isDisabled={!activeForEmails}
                />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <label className="tw-text-14px tw-font-semibold tw-text-gray-50 !tw-mb-12px">Home App Alerts Status</label>
            {!this.doesLeadHaveApp && <div className="tw-text-[#e5941b] tw-text-14px tw-mb-8px">This lead has not yet joined Brivity Home.</div>}
            <div className="tw-flex tw-gap-8px">
              <Checkbox
                checked={activeForAppNotifications}
                className="tw-cursor-pointer"
                onChange={() => this.toggleButtons(activeForAppNotifications ? "not-sending-notifications" : "sending-notifications")}
                disabled={!this.doesLeadHaveApp}
              />
              <span>Enable Home App Alerts</span>
            </div>
          </div>
          <hr />
          <div className="tw-flex tw-flex-col tw-gap-12px tw-mb-8px">
            <span className="tw-text-18px tw-font-semibold">Search By</span>
            <div>
              <TextToggle
                size="small"
                options={[{ label: "Terms", value: "search-by-terms" }, { label: "Map", value: "search-by-map" }]}
                value={searchingByTerm ? "search-by-terms" : "search-by-map"}
                onChange={e => this.toggleButtons(e.target.value)}
              />
            </div>
          </div>
          {
            searchingByTerm ?
              <div className={`tw-flex tw-flex-col ${form_css.required} form-group`}>
                <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Search Term</label>
                <SearchAutocomplete
                  multiSearchArray={this.state.multi_search_array}
                  multiCatArray={this.state.multi_cat_array}
                  personId={this.props.person.id}
                  handleAddSearchTerm={this.handleAddSearchTerm}
                  handleRemoveSearchTerm={this.handleRemoveSearchTerm}
                  mlsIds={this.props.mlsIds}
                />
              </div>
              :
              <div className="tw-flex tw-flex-col tw-mb-24px" id={alerts.searchByMap}>
                <Map
                  geoCodeAccountAddress={this.state.geoCodeAccountAddress}
                  polygonCoordinates={polygon}
                  handlePolygonUpdate={this.handlePolygonUpdate}
                />
              </div>
          }
          <div className="tw-flex tw-gap-24px tw-mb-24px">
            <div className="tw-flex tw-flex-col tw-w-full">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Min Price</label>
              <PriceInput
                name="price_min"
                placeholder="No Min"
                onChange={this.onChange}
                onBlur={this.onMouseOut}
                value={price_min}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-w-full">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Max Price</label>
              <PriceInput
                name="price_max"
                placeholder="No Max"
                onChange={this.onChange}
                onBlur={this.onMouseOut}
                value={price_max}
              />
            </div>
          </div>
          <div className="tw-mb-24px">
            <span className="tw-text-18px tw-font-semibold">Property Features</span>
          </div>
          <div className="tw-flex tw-gap-24px tw-mb-24px">
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Bedrooms</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="numbeds_min"
                  currentValue={numbeds_min}
                  isMinField
                  optionsFunc={dropDownOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="numbeds_max"
                  currentValue={numbeds_max}
                  optionsFunc={dropDownOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Bathrooms</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="numbaths_min"
                  isMinField
                  currentValue={numbaths_min}
                  optionsFunc={bathroomOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="numbaths_max"
                  currentValue={numbaths_max}
                  optionsFunc={bathroomOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Square Feet</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="sqfeet_min"
                  isMinField
                  currentValue={sqfeet_min}
                  optionsFunc={sqFTOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="sqfeet_max"
                  currentValue={sqfeet_max}
                  optionsFunc={sqFTOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-gap-24px tw-mb-24px">
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Lot Size</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="lotval_min"
                  isMinField
                  currentValue={lotval_min}
                  optionsFunc={lotSizeOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="lotval_max"
                  currentValue={lotval_max}
                  optionsFunc={lotSizeOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Year Built</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="year_min"
                  isMinField
                  currentValue={year_min}
                  optionsFunc={yearOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="year_max"
                  currentValue={year_max}
                  optionsFunc={yearOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Garage</label>
              <div className="tw-flex tw-gap-8px">
                <AlertDropdown
                  name="garage_min"
                  isMinField
                  currentValue={garage_min}
                  optionsFunc={dropDownOptions}
                  onSelect={this.onSelect}
                />
                <AlertDropdown
                  name="garage_max"
                  currentValue={garage_max}
                  optionsFunc={dropDownOptions}
                  onSelect={this.onSelect}
                />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-w-[89px] tw-mb-24px">
            <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Stories</label>
            <Dropdown
              isSearchable={false}
              isClearable={false}
              name="stories"
              options={storiesOptions}
              onChange={(e) => this.onSelect("stories", e)}
              placeholder="Any"
              value={storiesOptions.find(o => o.value === stories)}
              containerClassName="tw-min-w-[95px]"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-mb-24px">
            <label className="tw-text-14px tw-font-semibold tw-text-gray-50">Property Type</label>
            <div className="tw-flex tw-flex-wrap tw-gap-4px">
              {propertyTypes.map(propertyType => (
                <FilterPill key={propertyType} onClick={() => this.togglePropertyTypes(propertyType)} selected={this.isPropertyTypeSelected(propertyType)}>
                  {propertyType}
                </FilterPill>
              ))}
            </div>
          </div>
          {
            propertyAdvancedOptions.map(option => (
              <AdvancedOptions key={option} selectedAdvancedOptions={featureFields} label={option} options={advancedOptions[option]} handleChecked={this.toggleAdvanced} />
            ))
          }
          <div className="tw-mb-24px">
            <span className="tw-text-18px tw-font-semibold">Financial Options</span>
          </div>            {
            financialAdvancedOptions.map(option => (
              <AdvancedOptions key={option} selectedAdvancedOptions={featureFields} label={option} options={advancedOptions[option]} handleChecked={this.toggleAdvanced} />
            ))
          }
          <div
            className="tw-flex tw-mb-24px tw-gap-8px tw-items-center"
            onClick={this.togglePhotosAvailable}
          >
            <Checkbox className="tw-cursor-pointer" checked={this.state.photos_available} readOnly />
            <span className="tw-text-14px tw-cursor-pointer">Include Properties without Photos</span>
          </div>
          <div className="tw-flex">
            <span className="tw-text-12px tw-text-gray-75">Note: when you create and save a new Listing Alert, it will send an initial email immediately.</span>
          </div>
          <hr className="tw-mb-0" />
        </Modal.Body>
        <Modal.Footer className="tw-m-32px">
          <div className="tw-flex">
            <Button
              schema="tertiary"
              size="medium"
              onClick={this.props.closeListingAlertModal}
            >
              CANCEL
            </Button>
            <div className="tw-ml-auto">
              <MatchingResults showUrl={this.state.showUrl} count={this.state.listingAlertCounts} url={this.state.search_url} />
            </div>
            <Button
              disabled={submitDisabled || !this.hasRequiredFields()}
              onClick={this.handleSubmit}
              schema="primary"
              size="medium"
            >
              {submitButton}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ListingAlert.defaultProps = {
  secondaryAlert: undefined,
  submitButton: "Save"
}

export default ListingAlert;

const AlertDropdown = ({name, currentValue, optionsFunc, isMinField, onSelect}) => {
  const boundary = isMinField ? "No Min" : "No Max";
  const options = optionsFunc(boundary);
  const value = options.find(o => o.value == currentValue);
  const containerClassName = isMinField ? "tw-min-w-[95px]" : "tw-min-w-[98px]";

  return (
    <Dropdown
      containerClassName={containerClassName}
      menuShouldComeToFront
      isSearchable={false}
      isClearable={false}
      name={name}
      options={options}
      onChange={(e) => onSelect(name, e)}
      placeholder={boundary}
      value={value}
      components={{ ValueContainer }}
    />
  );
};

const ValueContainer = ({ componentProps, children }) => (
  <Dropdown.ValueContainer
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="tw-whitespace-nowrap"
    preserveStyle
  >
    {children}
  </Dropdown.ValueContainer>
);

const PriceInput = ({ name, placeholder, onBlur, onChange, value }) => {
  const formattedValue = formatPrice(value) || "";

  const cleanValue = (e) => {
    const cleaned = e.target?.value.replace(/\D/g, "");
    onChange({ target: { name, value: cleaned } });
  };

  return (
    <TextInput
      name={name}
      placeholder={placeholder}
      onChange={cleanValue}
      onBlur={onBlur}
      value={formattedValue}
    />
  );
};
