import axios from "axios";
import { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useMlsListingDetails = (blossorId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!blossorId) {
      setData(null);
      return;
    }
    const abortController = new AbortController();

    setLoading(true);
    caseTransformingAxios
      .get("/listings_api", { params: { blossorId }, signal: abortController.signal })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (!axios.isCancel(err)) {
          setData(null);
        }
      });

    return () => abortController.abort();
  }, [blossorId]);

  return { actions: { clear: () => setData(null) }, data, loading };
};

export default useMlsListingDetails;
