/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { func, shape } from "prop-types";
import withDispatch from "../../../../shared/HOCs/withDispatch";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID, TIMEFRAME } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { referralCalendarEditOn } from "../../actions/creators";
import { putRefTimeFrameAsThunk } from "../../actions/thunks";

import Dropdown from "../../../../shared/v2/Dropdown";
import { exactDateHelper, exactDateYearHelper } from "./helpers";

const FIXED_DROPDOWN = [
  { label: "Immediate", value: "Immediate" },
  { label: "Within 90 days", value: "Within 90 days" },
  { label: "Within a year", value: "Within a year" },
  { label: "After a year", value: "After a year" },
  { label: "Custom", value: "Custom" },
  { label: "Unknown", value: "Unknown" },
];

const TIMEFRAME_STARTDATES = {
  Immediate: exactDateHelper(0),
  "Within 90 days": exactDateHelper(90),
  "Within a year": exactDateYearHelper(),
  "After a year": exactDateYearHelper(),
  Custom: "Custom",
  Unknown: exactDateHelper(0),
};

const TimeFrameEdit = ({ onChangeHandlr, timeframe }) => (
  <div className="tw-relative tw-flex tw-items-center tw-ml-6px">
    <Dropdown
      containerClassName="tw-cursor-pointer tw-absolute"
      className="tw-w-[160px]"
      value={timeframe}
      onChange={onChangeHandlr}
      options={FIXED_DROPDOWN}
      menuIsOpen
    />
  </div>
);

const TimeFrameEditWrapper = ({ timeframe, uuid, onReferralCalendarEditOn, onPutRefTimeFrameAsThunk }) => {
  const onChangeHandlr = (option) =>
    option.value === "Custom"
      ? onReferralCalendarEditOn()
      : onPutRefTimeFrameAsThunk({ ...option, uuid }, TIMEFRAME_STARTDATES[option.value]);

  return <TimeFrameEdit onChangeHandlr={onChangeHandlr} timeframe={timeframe} />;
};

const withReduxState = tdpDetails({
  WrappedComponent: TimeFrameEditWrapper,
  states: [TIMEFRAME, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: [putRefTimeFrameAsThunk, referralCalendarEditOn],
  aliases: ["onPutRefTimeFrameAsThunk", "onReferralCalendarEditOn"],
});

TimeFrameEdit.propTypes = {
  onChangeHandlr: func,
  timeframe: shape({}),
};

TimeFrameEdit.defaultProps = {
  onChangeHandlr: () => {},
  timeframe: null,
};
