/* eslint-disable camelcase */
import moment from "moment";
import defaultPropertyDetails from "../constants";
import _ from "lodash";
import format from "@shared/formatters";
import { removeCommas } from "@shared/v2/inputFormatUtils";

export const mergeCompareObjects = (obj1, obj2) => {
  const result = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      const nestedObj = {};
      nestedObj[`mls_${key}`] = obj1[key];
      nestedObj[`current_${key}`] = obj2[key];
      nestedObj.isSelectable = obj1[key] != "—" && obj2[key] != "—" && obj1[key] != obj2[key];
      nestedObj.mlsChecked = true;
      nestedObj.currentChecked = false;
      result[key] = nestedObj;
    }
  }
  return result;
};

export const setDefaultValues = (obj, allKeys) => {
  const newObj = _.defaults({}, obj);

  allKeys.forEach((key) => {
    if (!(key in newObj) || newObj[key] === "" || newObj[key] === null || newObj[key] === undefined) {
      newObj[key] = "—";
    }
  });

  return newObj;
};

export const calcCommission = (price, commission, commissionPerc) =>
  commissionPerc ? 0.01 * +commission * price : +commission;

export const calcStatus = (status) => status.toLowerCase().replace(/ /g, "");

export const createPeopleListed = (teamMembers) =>
  (teamMembers || []).map(({ avatar, first_name, last_name, id }) => ({
    alt: `${first_name} ${last_name}`,
    id,
    avatar,
  }));

export const sortPropDetails = (state, { details }) => {
  const exceptionDetails = [
    "agent_gci",
    "appraisal_date",
    "close_date",
    "close_price",
    "commission",
    "escrow_signing_date",
    "home_inspection_date",
    "mutual_acceptance_date",
    "price",
    "purchase_price",
    "mls_number",
  ];

  const newState = { ...state };

  details.forEach(({ name, value, created_at, id }) => {
    const lowerCasedName = name.toLowerCase();
    const inDefaultPropDetails = defaultPropertyDetails.includes(lowerCasedName);
    const inExceptionDetails = exceptionDetails.includes(lowerCasedName);

    if (inDefaultPropDetails) newState.propertyDetails[lowerCasedName] = value;
    if (name === "mls_number") newState.listingDetails[lowerCasedName] = value;
    if (!inDefaultPropDetails && !inExceptionDetails) {
      newState.additionalDetails[lowerCasedName] = {
        value,
        created_at: moment(created_at).valueOf(),
        id,
      };
    }
  });

  ["propertyDetails", "listingDetails", "additionalDetails"].forEach((detailType) => {
    Object.keys(newState[detailType]).forEach((detailName) => {
      if (detailName === detailName.toLowerCase()) return;
      delete newState[detailType][detailName]; // remove no normalized detail name
    });
  });

  return newState;
};

export const formatExistingNumber = (numericOrStringValue) =>
  numericOrStringValue ? format.number(removeCommas(numericOrStringValue.toString())) : null;
