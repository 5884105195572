import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import InlineEditingInput from "../../../../../shared/v2/InlineEditingInput";
import { renameDocumentError } from "../../actions/creators";
import { renameDocumentAsThunk } from "../../actions/thunks";
import { DocumentPropType } from "../../propTypes";
import { containerZ } from "../utils";

import PreviewDetailsHeader from "./PreviewDetailsHeader";
import PreviewUploadedBy from "./PreviewUploadedBy";
import {
  PreviewDetailsDivider,
  PreviewDetailsGroup,
  PreviewDetail,
  PreviewDetailTitle,
  PreviewDetailText,
} from "./common";

const PreviewDetails = ({ document, renameErrors, dispatch }) => {
  const context = "documents-preview";

  const [editingName, setEditingName] = useState(false);
  const [renameError, setRenameError] = useState("");

  // When a rename error happens, the input remains open,
  // and if the user continues to press the Enter key or click outside it without changing the name,
  // it will keep making the same rename request over and over again,
  // so we need this to make sure that a rename request only happens if the name has actually changed
  const [name, setName] = useState(document.nakedName);

  const handleNameInteract = () => {
    setEditingName(true);
  };

  const handleNameCancel = () => {
    setEditingName(false);
  };

  const handleNameChange = (newName) => {
    const newNameTrimmed = newName.trim();

    if (!newNameTrimmed || newNameTrimmed === document.nakedName) {
      if (renameError) {
        // Clear rename errors when the name is empty or when it hasn't changed,
        // because in those cases a rename request is not made and the name remains the same
        dispatch(renameDocumentError(context, document.id, []));
      } else {
        setEditingName(false);
      }
      return;
    }

    // Only send a rename request if the name has actually changed
    if (newName === name) {
      return;
    }

    const newNameComplete = `${newName}${document.fileExtension}`;
    dispatch(renameDocumentAsThunk(context, document.id, newNameComplete));

    setName(newName);
  };

  useEffect(() => {
    setEditingName(renameErrors.length > 0);
    setRenameError(renameErrors.join(" "));
  }, [renameErrors]);

  return (
    <div
      // 425px is defined in the Figma designs
      className={`tw-relative ${containerZ} tw-w-[425px] tw-bg-white tw-overflow-auto`}
      data-cy="document-preview-details"
    >
      <PreviewDetailsHeader document={document} />

      <PreviewDetailsDivider />

      <div className="tw-flex tw-flex-col tw-gap-32px tw-px-32px tw-py-24px">
        <PreviewDetailsGroup>
          <PreviewDetail data-cy="document-preview-file-name-container">
            <PreviewDetailTitle>Name</PreviewDetailTitle>
            {document.canRename ? (
              <InlineEditingInput
                id="tdp-documents-preview-name"
                name="tdp-documents-preview-name"
                className="tw--my-8px tw-h-36px"
                value={document.nakedName}
                error={renameError}
                externalEditing={editingName}
                withCancelConfirm={false}
                onInteract={handleNameInteract}
                onCancelEditing={handleNameCancel}
                onChange={handleNameChange}
              />
            ) : (
              <PreviewDetailText data-cy="document-preview-file-name">{document.nakedName}</PreviewDetailText>
            )}
          </PreviewDetail>

          <PreviewDetail>
            <PreviewDetailTitle>Type</PreviewDetailTitle>
            <PreviewDetailText data-cy="document-preview-file-type">{document.fileType}</PreviewDetailText>
          </PreviewDetail>

          <PreviewDetail>
            <PreviewDetailTitle>Size</PreviewDetailTitle>
            <PreviewDetailText data-cy="document-preview-file-size">
              {document.humanReadableSize}
            </PreviewDetailText>
          </PreviewDetail>
        </PreviewDetailsGroup>

        <PreviewUploadedBy document={document} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  renameErrors: state.tdpDocumentsReducer.preview.renameErrors,
});

PreviewDetails.propTypes = {
  document: DocumentPropType.isRequired,
  renameErrors: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func,
};

PreviewDetails.defaultProps = {
  renameErrors: [],
  dispatch: () => {},
};

export default connect(mapStateToProps)(PreviewDetails);
