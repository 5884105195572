import React, { useState } from "react";
import { connect } from "react-redux";
import { string, shape, func, bool } from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import { removeCommas } from "@shared/v2/inputFormatUtils";

import BACModalBody from "./BACModalBody";
import { putDetailAsThunk } from "../actions/thunks";

const AddEditBACModal = ({ uuid, modalOpen, closeModal, BACval, isEditing, onPutDetailAsThunk }) => {
  const [BACState, setBACState] = useState(BACval);

  const handleUpdateBAC = (name, value) => {
    setBACState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onSave = () => {
    const newBACState = Object.assign({}, { ...BACState, value: removeCommas(BACState.value) });
    if (!isEditing && newBACState.hasOwnProperty("id")) {
      delete newBACState.id;
    }
    // status is not needed
    if (newBACState.hasOwnProperty("status")) {
      delete newBACState.status;
    }
    onPutDetailAsThunk({
      uuid,
      key: "buyers_agent_commission_attributes",
      value: newBACState,
      group: "listingDetails",
      stateKey: "buyers_agent_commission",
    });
    closeModal();
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName=" tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      show={modalOpen}
      onHide={closeModal}
      data-cy="bac-modal"
    >
      <Modal.Header
        titleClassName="tw-mb-32px tw-text-neutral-gray-75"
        title="Commission Settings"
        closeButton
      />

      <Modal.Body className="tw-mb-32px tw-w-[376px]">
        <BACModalBody BACval={BACState} handleUpdateBAC={handleUpdateBAC} />
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={closeModal} data-cy="bac-cancel-button">
          Close
        </Button>

        <Button
          size="medium"
          schema="primary"
          disabled={!BACState.cooperative_compensation && !BACState.value}
          onClick={() => onSave()}
          data-cy="bac-submit-button"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddEditBACModal.propTypes = {
  modalOpen: bool,
  isEditing: bool,
  closeModal: func,
  BACval: shape({
    id: string,
    percentage: bool,
    value: string,
    cooperative_compensation: bool,
    exclude_from_commissions: bool,
    status: string,
  }),
  onPutDetailAsThunk: func,
  uuid: string,
};

AddEditBACModal.defaultProps = {
  modalOpen: false,
  isEditing: false,
  closeModal: {},
  BACval: {
    id: "",
    percentage: true,
    value: "",
    cooperative_compensation: false,
    exclude_from_commissions: false,
    status: "",
  },

  uuid: "",
  onPutDetailAsThunk: () => {},
};
const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
});
const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditBACModal);
