/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BullhornRegularV6 = (props) => (
  <Icon {...props} name="Bullhorn Regular V6">
    <path d="M6.272 4a8.25 8.25 0 0 0 5.834-2.416L13.293.397c.628-.63 1.706-.184 1.706.707v4.509c.581.275 1 1.016 1 1.887s-.419 1.613-1 1.888v4.509c0 .891-1.078 1.338-1.706.706l-1.187-1.187c-1.494-1.494-3.5-2.381-5.606-2.413v3.247A1.75 1.75 0 0 1 4.75 16h-1A1.75 1.75 0 0 1 2 14.25V11a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h4.272zM13.5 2.311l-.334.334A9.758 9.758 0 0 1 6.5 5.498v4.006c2.503.031 4.891 1.078 6.666 2.853l.334.334V2.311zM3.5 14.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V11H3.5v3.25zM1.5 6v3c0 .275.224.472.5.472h3V5.5H2a.5.5 0 0 0-.5.5z" />
  </Icon>
);

export default BullhornRegularV6;
