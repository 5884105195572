/* eslint-disable no-alert */
import { tryCatchHandlr } from "../../../shared/Utilities";
import {
  getAllListingInfoFromApi,
  putDetailToApi,
  putDetailsToApi,
  deleteListing,
  putRefDetailsToApi,
} from "../api/apiWrappers";
import {
  saveDetail,
  saveDetails,
  saveDetailsFromHeader,
  editReferralDesiredAddress,
  editTimeframeExact,
  editReferralTransactionAmt,
  editReferralFee,
  editReferralTitle,
  editAddressFromPipeline,
  setImportModalOpen,
  isUpdateAddressModal,
} from "./creators";
import {
  adaptGetAllListingInfo,
  adaptDetailsPayloadToApi,
  adaptTimeFrame,
  adaptTransactionAmt,
  adaptReferralFee,
  adaptStatus,
  adaptTitle,
} from "../api/apiAdapters";
import { setRefStatus } from "../../Main/Details/actions/creators";
import { dispatchErrorHeader } from "./helpers";
import { financialsSetClosedPrice } from "../../Main/Financials/actions/creators";

const errBody = "listing";

export const getAllListingInfoAsThunk = (uuid) => async (dispatch) => {
  const req = getAllListingInfoFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      saveDetails([
        { key: "statusCode", value: err.response.status, group: "headerDetails" },
        { key: "error", value: err.response.data?.statusText, group: "headerDetails" },
      ]),
    );
    return;
  }

  dispatch(saveDetail("statusCode", res.status, "headerDetails"));
  const details = adaptGetAllListingInfo(res.data);
  dispatch(financialsSetClosedPrice(res.data.est_close_price || res.data.close_price));
  dispatch(saveDetailsFromHeader(details));
};

export const putDetailAsThunk = (payload) => async (dispatch) => {
  const req = putDetailToApi(adaptDetailsPayloadToApi(payload));
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      saveDetails([
        { key: "statusCode", value: err.response.status, group: "headerDetails" },
        { key: "error", value: err.response.data.errors[0], group: "headerDetails" },
      ]),
    );
    return;
  }

  dispatch(saveDetail("statusCode", res.status, "headerDetails"));
  const { key, group } = payload;

  const details = adaptGetAllListingInfo(res.data);
  const detailsToSave = [];
  if (key === "address_attributes") {
    detailsToSave.push(
      { key: "street_address", value: details.propertyDetails.street_address, group },
      { key: "city", value: details.propertyDetails.city, group },
      { key: "state", value: details.propertyDetails.state, group },
      { key: "zip", value: details.propertyDetails.zip, group },
    );
  } else {
    detailsToSave.push({ key, value: details[group][key], group });
  }
  detailsToSave.push(
    { key: "statusCode", value: null, group: "headerDetails" },
    { key: "error", value: null, group: "headerDetails" },
  );
  dispatch(saveDetails(detailsToSave));
};

export const putDetailsAsThunk = (payload) => async (dispatch) => {
  const payloadDetails = payload.details.map((detail) => adaptDetailsPayloadToApi(detail));
  const req = putDetailsToApi({ uuid: payload.uuid, details: payloadDetails });
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      saveDetails([
        { key: "statusCode", value: err.response.status, group: "headerDetails" },
        { key: "error", value: err.response.data.errors[0], group: "headerDetails" },
      ]),
    );
    return;
  }

  dispatch(saveDetail("statusCode", res.status, "headerDetails"));

  const details = adaptGetAllListingInfo(res.data);
  const detailsToSave = [];
  payloadDetails.forEach(({ key, group }) => {
    if (key === "address_attributes") {
      detailsToSave.push(
        { key: "street_address", value: details.propertyDetails.street_address, group },
        { key: "city", value: details.propertyDetails.city, group },
        { key: "state", value: details.propertyDetails.state, group },
        { key: "zip", value: details.propertyDetails.zip, group },
      );
    } else {
      detailsToSave.push({ key, value: details[group][key], group });
    }
  });
  detailsToSave.push(
    { key: "statusCode", value: null, group: "headerDetails" },
    { key: "error", value: null, group: "headerDetails" },
  );
  dispatch(saveDetails(detailsToSave));
};

export const deleteListingAsThunk = (payload) => async (dispatch) => {
  const req = deleteListing(payload);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      saveDetails([
        { key: "statusCode", value: err.response.status, group: "headerDetails" },
        { key: "error", value: err.response.data.errors[0], group: "headerDetails" },
      ]),
    );
    return;
  }
  window.location = "/listings";

  dispatch(saveDetail("statusCode", res.status, "headerDetails"));
  const { key, group } = payload;

  const details = adaptGetAllListingInfo(res.data);

  dispatch(saveDetail(key, details[group][key], group));
};

export const putRefDesiredAddressAsThunk = (payload, signal) => async (dispatch) => {
  dispatch(editReferralDesiredAddress(payload.value));

  const req = putDetailToApi(adaptDetailsPayloadToApi(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putRefTimeFrameAsThunk = (payload, exactDate, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;

  dispatch(editTimeframeExact(restOfPayload, exactDate));
  const adaptedTimeFrame = adaptTimeFrame(payload, exactDate);
  const req = putRefDetailsToApi(adaptedTimeFrame, signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putRefTransactionAmtAsThunk = (payload, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;
  dispatch(editReferralTransactionAmt(restOfPayload.transactionAmount));
  const req = putDetailToApi(adaptTransactionAmt(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putRefFeeAsThunk = (payload, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;
  dispatch(editReferralFee(restOfPayload));

  const req = putRefDetailsToApi(adaptReferralFee(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putStatusAsThunk = (payload, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;
  dispatch(setRefStatus(restOfPayload.status));

  const req = putDetailToApi(adaptStatus(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putStatusWithPipelineAsThunk = (payload, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;
  dispatch(setRefStatus(restOfPayload.status));

  const req = putDetailToApi(adaptStatus(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);

  if (payload?.preStatus === "pipeline" && (payload?.status === "active" || payload?.status === "pending")) {
    dispatch(setImportModalOpen(true, payload?.preStatus));
  } else if (payload?.status === "comingsoon" && !payload?.streetAddress) {
    dispatch(isUpdateAddressModal(true, payload?.status, payload?.preStatus));
  }
};

export const putReferralTitleAsThunk = (payload, signal) => async (dispatch) => {
  const { uuid, ...restOfPayload } = payload;
  dispatch(editReferralTitle(restOfPayload.title));

  const req = putDetailToApi(adaptTitle(payload), signal);
  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const putAddressFromPipelineStatus = (details, uuid) => async (dispatch) => {
  dispatch(editAddressFromPipeline(details));

  const req = putDetailsToApi({ uuid, details });

  const [, err] = await tryCatchHandlr(req);

  if (err) dispatchErrorHeader(dispatch, err, errBody);
};

export const closeModalAsThunk = (preStatus) => async (dispatch) => {
  dispatch(setImportModalOpen(false, preStatus));
};

export const openAddressModalAsThunk = (isOpen, updatedStatus, preStatus) => async (dispatch) => {
  dispatch(isUpdateAddressModal(isOpen, updatedStatus, preStatus));
};
