/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* global tinymce */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { Button, FormGroup, Col, Checkbox, Dropdown, CancelButton } from "../../shared/v1";
import * as autoPlanActionCreators from "../actions/autoPlanActionCreators";

import BrivityTextEditor from "../../shared/BrivityTextEditor";
import { sortByKey } from "../../shared/Utilities";

import { canBeContingent } from "./helpers";
import {
  AssignTo,
  Delay,
  DelayAndScheduleLabel,
  Detail,
  ScheduleSelector,
  EmailCCSelector,
  RunTime,
  Schedule,
} from "./ActionFormHelpers";
import AiAssistant from "../../shared/AiAssistant/AiAssistant";

const defaultEmail = {
  name: "",
  template_body: "",
  type: "Email",
  delay: 0,
  delay_unit: "seconds",
  contingent: false,
  delay_factor: 1,
  schedule_type: "computed_time",
};

const ccPlaceholders = {
  role: "Select a role",
  people: "Select...",
  email: "Enter Email Address",
};

class EmailForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBCC: false,
      ccType: "people",
      bccType: "people",
    };
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { action } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;

    this.getCCLabels(action);

    const email = { ...defaultEmail, ...action };
    const bccPeopleUUIDs = email.bcc_people_uuids || [];
    const bccEmails = email.bcc_emails || [];
    if (!!bccPeopleUUIDs.length || !!bccEmails.length) this.displayBCC();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.getCCLabels(nextProps.action);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  emailTemplateOptions = (templates) => {
    const emailTemplate = templates || [];
    const options = emailTemplate.map((template) => ({
      label: template.name,
      value: template.id,
      subject: template.subject,
    }));

    return sortByKey(options, "label");
  };

  updateTinyMceEditor = (content) => {
    if (tinymce && tinymce.activeEditor) {
      tinymce.activeEditor.setContent(content);
    }
  };

  handleTemplateChange = async (value) => {
    const templateId = value;
    const { emailTemplates, onChange } = this.props;
    const activeTemplate = emailTemplates.find((t) => templateId === t.id);

    const response = await fetch(`/auto_plans/interaction_template_body?id=${templateId}`);
    const templateBody = await response.json();

    this.updateTinyMceEditor(templateBody);

    // Not including templateBody since updateTinyMceEditor is a controlled component
    onChange({ template_id: templateId, name: activeTemplate.subject });

  };

  handleSubmit = () => {
    const { action, onActionSubmit, actionsPath, autoPlanPath } = this.props;
    let emailContentFromUncontrollerComponent = "";
    if (tinymce && tinymce.activeEditor) {
      emailContentFromUncontrollerComponent = tinymce.activeEditor.getContent();
    }

    const emailToSubmit = { ...action, template_body: emailContentFromUncontrollerComponent };

    onActionSubmit(emailToSubmit, actionsPath, autoPlanPath);
  };

  displayBCC = () => {
    const { showBCC } = this.state;
    if (!showBCC) this.setState({ showBCC: true });
  };

  getCCLabels = (email) => {
    this.loadLabelsForCC(email.cc_role_uuids, email.cc_people_uuids, email.cc_emails, "cc");
    this.loadLabelsForCC(email.bcc_role_uuids, email.bcc_people_uuids, email.bcc_emails, "bcc");
  };

  loadLabelsForCC = (roles, people, emails, prefix) => {
    axios({
      method: "get",
      url: `/auto_plans/serialized_email_recipients?people_uuids[]=${(people || []).join(
        "&people_uuids[]=",
      )}&role_uuids[]=${(roles || []).join("&role_uuids[]=")}`,
      headers: {
        "X-CSRF-Token": ReactOnRails.authenticityToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      // eslint-disable-next-line no-underscore-dangle
      if (this._isMounted)
        this.setState({
          [`${prefix}Roles`]: response.data.roles || [],
          [`${prefix}People`]: response.data.people || [],
          [`${prefix}Emails`]: (emails || []).map((email) => ({
            label: email,
            value: email,
            name: "_emails",
            cc_type: "Email",
          })),
        });
    });
  };

  render() {
    const {
      bccEmails: stateBccEmails,
      bccPeople,
      bccRoles,
      bccType,
      ccEmails: stateCcEmails,
      ccPeople,
      ccRoles,
      ccType,
      showBCC,
    } = this.state;
    const {
      action,
      assignedForOptions,
      assignedToOptions,
      emailTemplates,
      handleDelayUnitChange,
      handleScheduleTypeChange,
      hideAssignedFor,
      isSubmitting,
      milestoneOptions,
      onAssignedForChange,
      onAssignedToChange,
      onCCChange,
      onChange,
      onCloseAction,
      placeholders,
      planType,
      triggers,
    } = this.props;
    const email = { ...defaultEmail, ...action };
    const assignedToValue = email.assigned_to_id || email.assigned_to_role;
    const assignedForValue = email.assigned_for_id || email.assigned_for_role;
    const ccPeopleUUIDs = email.cc_people_uuids || [];
    const ccRoleUUIDs = email.cc_role_uuids || [];
    const ccEmails = email.cc_emails || [];
    const bccPeopleUUIDs = email.bcc_people_uuids || [];
    const bccRoleUUIDs = email.bcc_role_uuids || [];
    const bccEmails = email.bcc_emails || [];

    return (
      <form>
        <FormGroup horizontal>
          <Detail label="Email Subject" value={email.name} onChange={onChange} />
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <label>Email Template</label>
            <Dropdown
              name="template_id"
              value={email.template_id}
              isSearchable
              isClearable={false}
              onUpdate={(opt) => this.handleTemplateChange(opt.value)}
              placeholder="Choose email template"
              options={this.emailTemplateOptions(emailTemplates)}
            />
          </Col>
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <div className="tw-flex tw-justify-between tw-items-end tw-mb-[4px]">
              <label className="tw-mb-0">Body</label>
              <AiAssistant
                isTinyMce
                messageType="Email"
                onInsertClick={(text) =>
                  tinymce?.activeEditor?.execCommand(
                    "mceInsertContent",
                    false,
                    (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                  )
                }
              />
            </div>
            <BrivityTextEditor
              id="auto-plan-email-form"
              value={email.template_body}
              placeholders={placeholders}
            />
          </Col>
        </FormGroup>

        <FormGroup horizontal>
          <ScheduleSelector scheduleType={email.schedule_type} onChange={handleScheduleTypeChange} />

          <DelayAndScheduleLabel
            delayLabel="Send"
            scheduleLabel={email.schedule_type === "computed_time" && "After"}
          />

          <Delay
            delayFactor={email.delay_factor}
            delayOnChange={onChange}
            delayValue={email.delay}
            delayUnitValue={email.delay_unit}
            delayUnitOnChange={handleDelayUnitChange}
            scheduleType={email.schedule_type}
          />

          <Schedule
            type={email.schedule_type}
            delay={email.delay}
            delayFactor={email.delay_factor}
            onChange={onChange}
            parentActionId={email.parent_action_id}
            triggers={triggers}
            milestoneOptions={milestoneOptions}
            milestoneId={email.milestone_id}
          />
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <Checkbox
              className="tw-pt-7px"
              labelClassName="tw-text-gray-base tw-text-[14px] tw-select-none"
              deprecatedOverrideCheckboxMarginClasses="!tw-mt-0 !tw--ml-20px"
              name="contingent"
              inline
              disabled={!canBeContingent(email.schedule_type, triggers)}
              onChange={() => onChange({ contingent: !email.contingent })}
              checked={email.contingent}
            >
              Make Contingent (Due time auto-updates based on when the previous action is completed)
            </Checkbox>
          </Col>
        </FormGroup>

        {(email.delay_unit === "days" || email.schedule_type === "event") && (
          <FormGroup horizontal>
            <RunTime label="Send At" value={email.run_time} onChange={onChange} />
          </FormGroup>
        )}

        <FormGroup horizontal>
          <AssignTo
            label="Send From"
            value={assignedToValue}
            onChange={onAssignedToChange}
            options={assignedToOptions}
          />
          {!hideAssignedFor && (
            <AssignTo
              label="Send To"
              value={assignedForValue}
              onChange={onAssignedForChange}
              options={assignedForOptions}
            />
          )}
        </FormGroup>

        <FormGroup className="tw-relative" horizontal>
          <EmailCCSelector
            planType={planType}
            label="CC"
            showAddBCC={!showBCC}
            onToggleBCC={() => this.displayBCC()}
            name="ccType"
            type={ccType}
            placeholder={ccPlaceholders[ccType]}
            onRadioChange={(e) => this.setState({ ccType: e.target.value })}
            people={ccPeople}
            emails={stateCcEmails}
            roles={ccRoles}
            onOptionSelect={(option) => onCCChange(option, "cc", ccRoleUUIDs, ccPeopleUUIDs, ccEmails)}
            onOptionRemove={(option) => onCCChange(option, "cc", ccRoleUUIDs, ccPeopleUUIDs, ccEmails, true)}
          />
        </FormGroup>

        <FormGroup className="tw-relative" horizontal>
          <div>
            {showBCC && (
              <EmailCCSelector
                planType={planType}
                label="BCC"
                showAddBCC={false}
                name="bccType"
                type={bccType}
                placeholder={ccPlaceholders[bccType]}
                onRadioChange={(e) => this.setState({ bccType: e.target.value })}
                people={bccPeople}
                emails={stateBccEmails}
                roles={bccRoles}
                onOptionSelect={(option) =>
                  onCCChange(option, "bcc", bccRoleUUIDs, bccPeopleUUIDs, bccEmails)
                }
                onOptionRemove={(option) =>
                  onCCChange(option, "bcc", bccRoleUUIDs, bccPeopleUUIDs, bccEmails, true)
                }
              />
            )}
          </div>
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <CancelButton size="small" className="modal-cancel" onClick={onCloseAction} />
            {isSubmitting ? (
              <Button size="small" variant="info" className="modal-submit tw-float-right" disabled>
                Saving..
              </Button>
            ) : (
              <Button
                size="small"
                variant="info"
                className="modal-submit tw-float-right"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            )}
          </Col>
        </FormGroup>
      </form>
    );
  }
}

EmailForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  actionsPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedForOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedToOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  autoPlanPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  emailTemplates: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelayUnitChange: PropTypes.func.isRequired,
  handleScheduleTypeChange: PropTypes.func.isRequired,
  hideAssignedFor: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestoneOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onActionSubmit: PropTypes.func.isRequired,
  onAssignedForChange: PropTypes.func.isRequired,
  onAssignedToChange: PropTypes.func.isRequired,
  onCCChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  placeholders: PropTypes.arrayOf(PropTypes.any).isRequired,
  planType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  triggers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.isSubmitting,
});

const mapDispatchToProps = (dispatch) => ({
  autoPlanActions: bindActionCreators(autoPlanActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
