import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextButton from "@shared/v2/TextButton";
import Toggle from "@shared/v2/Toggle";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import CallBarContainer from "./CallBarContainer";
import CALL_STATES from "../utils/states";
import { useCurrentUser, useOutboundCallRecordingSetting } from "../../reducers/layoutReducer/selectors";
import { startRecording, stopRecording, setDuration } from "../../reducers/dialerReducer/dialerReducer";
import {
  useCallState,
  useIsRecording,
  useIsRecordingConsentRequired,
} from "../../reducers/dialerReducer/selectors";
import RecordConfirmationModal from "./RecordConfirmationModal";

const getTimeString = (time) => {
  const hours = `${Math.floor(time / 3600)}`;
  const minutes = `${Math.floor((time % 3600) / 60)}`;
  const seconds = `${Math.floor((time % 3600) % 60)}`;

  return `${hours}:${minutes.length === 1 ? "0" : ""}${minutes}:${seconds.length === 1 ? "0" : ""}${seconds}`;
};

const CallBar = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const callState = useCallState();
  const isRecording = useIsRecording();
  const isRecordingConsentRequired = useIsRecordingConsentRequired();
  const { outboundNotificationMessage, outboundRecording } = useOutboundCallRecordingSetting();
  const [time, setTime] = useState(0);
  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    if (callState === CALL_STATES.Connected) {
      if (outboundRecording === "automatic") {
        clickStartRecording(false);
      }

      const interval = setInterval(() => {
        setTime((t) => t + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (callState === CALL_STATES.Ended) {
      dispatch(setDuration(time));
    }
    if (callState === CALL_STATES.Started) {
      dispatch(setDuration(null));
      setTime(0);
    }
    return () => {};
  }, [callState]);

  useEffect(() => {
    if (isRecordingConsentRequired) {
      setShowConsentModal(true);
    }
  }, [isRecordingConsentRequired]);

  if (callState === CALL_STATES.Init) return null;

  const closeModal = () => {
    setShowConsentModal(false);
  };

  const clickStartRecording = (recordingConsent) => {
    if (!isRecording) dispatch(startRecording(recordingConsent));
  };

  const onClickRecord = () => {
    clickStartRecording(true);
    closeModal();
  };

  const onClickNoRecord = () => {
    closeModal();
  };

  const toggleRecording = () => {
    if (isRecording) {
      dispatch(stopRecording());
    } else {
      dispatch(startRecording(false));
    }
  };

  return (
    <div className="tw-text-12d tw-font-semibold">
      <CallBarContainer
        variant={callState === CALL_STATES.Ended ? "gray" : "green"}
        className="tw-flex tw-justify-between tw-items-center"
      >
        <span className="tw-flex tw-gap-[8px]">
          {getTimeString(time)}
          {callState === CALL_STATES.Started && (
            <span>Calling you at your phone {formatPhoneNumber(currentUser.routingNumber)}</span>
          )}
          {callState === CALL_STATES.Ringing && <span>Ringing</span>}
          {callState === CALL_STATES.Connected && <span>Connected</span>}
          {callState === CALL_STATES.Ended && <span>Call Ended</span>}
        </span>
        {outboundRecording !== "disabled" && callState === CALL_STATES.Connected && (
          <>
            <Toggle
              labelPlacement="start"
              labelClassName="tw-m-0 !tw-text-inherit tw-font-semibold tw-min-w-[120px] tw-justify-end"
              dotClassName="!tw-bg-semantic-green-110 peer-checked:!tw-bg-semantic-red-10 peer-checked:tw-border-solid peer-checked:tw-border-[1px] peer-checked:tw-border-semantic-red-100"
              trackClassName="!tw-bg-white peer-checked:!tw-bg-white"
              checked={isRecording}
              onChange={() => toggleRecording()}
              text={`Record ${isRecording ? "ON" : "OFF"}`}
            />
            {showConsentModal && (
              <RecordConfirmationModal
                modalOpen={showConsentModal}
                closeModal={closeModal}
                onClickRecord={onClickRecord}
                onClickNoRecord={onClickNoRecord}
              />
            )}
          </>
        )}
      </CallBarContainer>

      {!outboundNotificationMessage && callState !== CALL_STATES.Ended && !isRecording && (
        <CallBarContainer variant="green-light" className="tw-flex tw-justify-between">
          <span>Please inform the other party before recording.</span>
          <TextButton
            className="!tw-text-inherit hover:!tw-text-semantic-green-100"
            schema="sentence"
            onClick={() =>
              window.open("https://our.place.com/spaces/64/call-recording/call-recording", "_blank")
            }
          >
            Learn More
          </TextButton>
        </CallBarContainer>
      )}
    </div>
  );
};

export default CallBar;
