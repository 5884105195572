import React, { useMemo } from "react";
import { Provider } from "react-redux";
import LeadIndex from "./pages/LeadIndex";

export default () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <LeadIndex />
    </Provider>
  );
};
