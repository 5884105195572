/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AngleRightSolidV6 = (props) => (
  <Icon {...props} name="Angle Right Solid V6">
    <path d="M5.167 15.999a1.332 1.332 0 0 1-.942-2.276L9.95 8 4.225 2.276A1.332 1.332 0 1 1 6.11.391l6.666 6.666a1.332 1.332 0 0 1 0 1.885L6.11 15.608c-.26.262-.602.392-.943.392z" />
  </Icon>
);

export default AngleRightSolidV6;
