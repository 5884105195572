/* eslint-disable class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Circle from "@shared/Circle";
import { toTitleCase, sortByKey } from "../../shared/Utilities";
import "./AssignedPlans.scss";
import { determineActionInDisplay, getActionById } from "./helpers";
import InteractionDetail from "./InteractionDetail";
import TaskDetail from "./TaskDetail";
import { actionLookup, actionSubtitle, dateLabel, iconOptions } from "./AssignedPlanDetailHelpers";

class AssignedPlanDetail extends React.Component {
  circlePropsFromState = (action, assignedPlanState) => {
    const additionalClass = action.overdue && action.type === "Task" ? "overdue" : "";

    if (action.state === "completed") {
      return {
        icon: "fa fa-check text-white",
        circleStyle: "tw-bg-theme-primary",
      };
    }
    if (assignedPlanState === "deleted") {
      return {
        icon: "fa fa-trash text-gray",
        circleStyle: "deleted",
      };
    }
    if (action.state === "failed") {
      return {
        icon: "fa fa-close text-white",
        circleStyle: "tw-bg-brand-danger",
      };
    }
    return {
      icon: `${iconOptions(action.type)} text-gray`,
      circleStyle: `tw-border-solid tw-border-[1px] tw-border-gray-15 ${additionalClass}`,
    };
  };

  restartButtonText = (type) => (type === "Task" ? "Retry" : "Send Now");

  markAsComplete(id, assignedPlanId) {
    const { onActionUpdate } = this.props;
    onActionUpdate(`/assigned_plans/${assignedPlanId}/assigned_actions/${id}/mark_complete`);
  }

  restartAssignedAction(id, assignedPlanId) {
    const { onActionUpdate } = this.props;
    onActionUpdate(`/assigned_plans/${assignedPlanId}/assigned_actions/${id}/restart`);
  }

  canRestartAction(action = {}) {
    const { assignedPlan } = this.props;
    const { type, state } = action;

    if (assignedPlan.state === "started" && type === "Task" && state === "failed") {
      return true;
    }

    if (assignedPlan.state === "started" && type !== "Task" && state !== "completed") {
      return true;
    }

    return false;
  }

  renderActionGroup(groupType, actions, subGroupType, actionInDisplayId) {
    const groupLabel = groupType === "days" ? dateLabel(subGroupType) : toTitleCase(subGroupType);
    const sortByField = groupType === "days" ? "run_at" : "sort_key";

    return (
      <React.Fragment key={subGroupType}>
        <div className="tw-font-bold tw-py-[8px]">{groupLabel}</div>

        {sortByKey(actions, sortByField)
          .sort((a, b) => ((a.position || 0) > (b.position || 0) ? 1 : -1))
          .map((action) => this.renderAction(action.id, actionInDisplayId))}
      </React.Fragment>
    );
  }

  renderAction(actionId, actionInDisplayId) {
    const { assignedPlan, previewAssignedAction } = this.props;
    const action = actionLookup(assignedPlan.assigned_actions, actionId);

    return (
      <button
        key={action.id}
        className={`tw-flex tw-border-none tw-items-center tw-gap-[16px] tw-p-[8px] ${action.id === actionInDisplayId ? "tw-bg-gray-5" : "tw-bg-white"}`}
        type="button"
        onClick={() => previewAssignedAction(action)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Circle {...this.circlePropsFromState(action)} />
        <div className="tw-overflow-hidden">
          <div className="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">
            <span>{action.type}: </span>
            <span className="tw-font-bold">{action.name}</span>
          </div>
          <div className="one-line understated">
            <span>{actionSubtitle(action)}</span>
            <span className="text-danger"> {action.failed_message}</span>
            <span className="text-danger"> {action.overdue && action.type === "Task" && "Overdue"}</span>
          </div>
        </div>
      </button>
    );
  }

  render() {
    const { assignedPlan, onShowDeleteModal, activeAssignedActionId, isAssignedActionUpdating } = this.props;
    const actionInDisplay = determineActionInDisplay(assignedPlan, activeAssignedActionId);
    const parentAction = actionInDisplay
      ? getActionById(actionInDisplay.parent_action_id, assignedPlan)
      : null;

    return (
      <div className="tw-flex tw-gap-[8px] tw-h-full">
        <div className="tw-flex-none tw-w-[280px] tw-flex tw-flex-col tw-overflow-x-hidden tw-overflow-y-auto">
          {assignedPlan?.assigned_actions &&
            Object.keys(assignedPlan.grouped_actions)
              .sort()
              .map((groupType) => {
                const group = assignedPlan.grouped_actions[groupType];
                return Object.keys(group)
                  .sort()
                  .map((subGroupKey) =>
                    this.renderActionGroup(groupType, group[subGroupKey], subGroupKey, actionInDisplay.id),
                  );
              })}
        </div>

        <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px] tw-overflow-x-hidden">
          {actionInDisplay && (
            <>
              <div className="tw-flex-1 tw-overflow-auto">
                {(actionInDisplay.type === "Email" || actionInDisplay.type === "Text") && (
                  <InteractionDetail action={actionInDisplay} parentAction={parentAction} />
                )}
                {actionInDisplay.type === "Task" && (
                  <TaskDetail
                    action={actionInDisplay}
                    planType={assignedPlan.plan_type}
                    parentAction={parentAction}
                  />
                )}
              </div>

              <div className="tw-flex tw-justify-between">
                <div>
                  {(assignedPlan.state === "started" || assignedPlan.state === "paused") &&
                    actionInDisplay.state !== "completed" && (
                      <Button
                        disabled={isAssignedActionUpdating}
                        size="medium"
                        schema="warning"
                        onClick={() => onShowDeleteModal(actionInDisplay)}
                      >
                        Delete
                      </Button>
                    )}
                </div>

                <div className="tw-flex tw-gap-[16px]">
                  {assignedPlan.state === "started" && actionInDisplay.type !== "Task" && (
                    <Button
                      size="medium"
                      disabled={isAssignedActionUpdating}
                      onClick={() =>
                        this.markAsComplete(actionInDisplay.id, actionInDisplay.assigned_plan_id)
                      }
                    >
                      Mark as Complete
                    </Button>
                  )}

                  {this.canRestartAction(actionInDisplay) && (
                    <Button
                      disabled={isAssignedActionUpdating}
                      size="medium"
                      schema="tertiary"
                      onClick={() =>
                        this.restartAssignedAction(actionInDisplay.id, actionInDisplay.assigned_plan_id)
                      }
                    >
                      {this.restartButtonText(actionInDisplay.type)}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default AssignedPlanDetail;

AssignedPlanDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  onActionUpdate: PropTypes.func.isRequired,
  activeAssignedActionId: PropTypes.string.isRequired,
  isAssignedActionUpdating: PropTypes.bool.isRequired,
  previewAssignedAction: PropTypes.func.isRequired,
  markAsComplete: PropTypes.func.isRequired,
  restartAssignedAction: PropTypes.func.isRequired,
};
