import axios from "axios";
import { tryCatchHandlr } from "./Utilities";

const service_cache = {};

const cacheHandler = (response) => {
  if (response.request.statusText == "OK") {
    service_cache[response.config.url] = response;
  }
  return response;
};

const cacheChecker = (url) => {
  let ret = null;
  if (service_cache[url]) {
    ret = service_cache[url];
  }
  return ret;
};

export const setUserAgentOrPond = (params, callback, errorCallback) => {
  const url = "/person_permissions";
  axios
    .post(url, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(callback)
    .catch(errorCallback);
};

export const getAssignableAgents = (callback, errorCallback, config = {}) => {
  const url = "/account_active_agents";
  const cachedData = cacheChecker(url);
  if (cachedData && !config.skipCache) {
    return callback(cachedData);
  }
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(cacheHandler)
    .then(callback)
    .catch(errorCallback);
};

export const getAssignablePonds = (page, pageSize, callback, errorCallback, config = {}) => {
  const url = `/lead_ponds.json?per_page=${pageSize}&page=${page}`;
  const cachedData = cacheChecker(url);
  if (cachedData && !config.skipCache) {
    return callback(cachedData);
  }
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(cacheHandler)
    .then(callback)
    .catch(errorCallback);
};

export const getMLSOptions = (callback, errorCallback, config = {}) => {
  const url = "/bulk_market_reports/mlses";
  const cachedData = cacheChecker(url);
  if (cachedData && !config.skipCache) {
    return callback(cachedData);
  }
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(cacheHandler)
    .then(callback)
    .catch(errorCallback);
};

export const updatePrimaryAgentOrPond = async ({ data, errorCb, successCb }) => {
  const url = "/people/agent_pond";
  const [resData, err] = await tryCatchHandlr(
    axios.patch(url, data, {
      headers: {
        "Content-Type": "application/json",
        "x-csrf-token": ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  if (successCb) {
    successCb(resData);
  }
};

export const getPrimaryAgentOrPond = async ({ allpeopleIdsStr, successCb }) => {
  const url = `/people_permissions.json?${allpeopleIdsStr}`;
  const [resData, err] = await tryCatchHandlr(
    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }),
  );
  if (successCb) {
    successCb(resData);
  }
};

export const deleteMarketReportApi = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/delete_report`;
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const deleteCmaReportApi = async ({ id, successCb, errorCb }) => {
  const url = `/cma_reports/${id}`;
  const [resData, err] = await tryCatchHandlr(
    axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};
