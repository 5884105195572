import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import { connect } from "react-redux";
import { uniqueId } from "lodash";

import { optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { errorForBanner } from "../../../../../shared/v2/ErrorBanner";
import { financialsUpdateCanceled } from "../../actions/creators";
import { removeTeamMember, updateFinancials } from "../../actions/thunks";
import FINANCIALS_UPDATE_TYPE from "../../api/actionNames";

import AddEditExpenseIncomeModal from "../AddEditExpenseIncomeModal";
import DeleteExpenseIncomeModal from "../DeleteExpenseIncomeModal";
import EditAmountModal from "../EditAmountModal/EditAmountModal";
import TeamMembersWithLineItems from "../TeamMember/TeamMembersWithLineItems";

const TeamMemberSection = ({ financials, listingTeamMembers, dispatch }) => {
  const [editingGci, setEditingGci] = useState(null);
  const [addingLineItem, setAddingLineItem] = useState(null);
  const [editingLineItem, setEditingLineItem] = useState(null);
  const [removingLineItem, setRemovingLineItem] = useState(null);
  const [editingBrokerageSplitLineItem, setEditingBrokerageSplitLineItem] = useState(null);
  const [editingRoyaltyLineItem, setEditingRoyaltyLineItem] = useState(null);

  const {
    adaptedData,
    lineItemTypeOptions,
    meta: { isUpdating, modalErrors: metaModalErrors },
  } = financials;
  const teamMembers = useMemo(() => {
    if (!adaptedData.teamMembers || adaptedData.teamMembers.length === 0) {
      return [];
    }

    if (listingTeamMembers.length === 0) {
      return adaptedData.teamMembers;
    }

    return adaptedData.teamMembers.map((teamMember) => {
      const teamMemberWithId = {
        ...teamMember,
        id: teamMember.id || uniqueId("tdp-financials-team-member-id-"),
        personId: teamMember.personId || uniqueId("tdp-financials-team-member-person-id-"),
      };

      // Fetch role from `listingTeamMembers`
      const listingTeamMember = listingTeamMembers.find(
        (member) => member.person?.id === teamMember.personId,
      );

      if (!listingTeamMember) {
        return teamMemberWithId;
      }

      return {
        ...teamMemberWithId,
        role: listingTeamMember.role?.title,
      };
    });
  }, [adaptedData.teamMembers, listingTeamMembers]);

  const modalErrors = useMemo(() => metaModalErrors.map((error) => errorForBanner(error)), [metaModalErrors]);

  const handleGciLabelClick = (member) => {
    setEditingGci({ member });
  };

  const handleAddLineItem = (member) => {
    setAddingLineItem({ member });
  };

  const handleEditLineItem = (member, lineItem) => {
    setEditingLineItem({ member, lineItem });
  };

  const handleRemoveLineItem = (member, lineItem) => {
    setRemovingLineItem({ member, lineItem });
  };

  const handleEditBrokerageSplitLineItem = (member, lineItem) => {
    setEditingBrokerageSplitLineItem({ member, lineItem });
  };

  const handleEditRoyaltyLineItem = (member, lineItem) => {
    setEditingRoyaltyLineItem({ member, lineItem });
  };

  const handleGciValueChange = (member, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_GCI_VALUE, {
        memberId: member.id,
        gci: newValue,
        gciType: newValueType,
      }),
    );
  };

  const handleUnitsChange = (member, newUnits) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_UNITS, {
        memberId: member.id,
        units: newUnits,
      }),
    );
  };

  const handleRemoveTeamMember = (member) => {
    dispatch(removeTeamMember(member.id, false));
  };

  const handleLineItemDragEnd = (member, { source, destination }) => {
    if (!destination) {
      return;
    }

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_POSITION, {
        memberId: member.id,
        from: source.index,
        to: destination.index,
      }),
    );
  };

  const handleLineItemOptionChange = (member, lineItem, newOption) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_TYPE, {
        memberId: member.id,
        id: lineItem.id,
        selectedOption: newOption,
      }),
    );
  };

  const handleLineItemValueChange = (member, lineItem, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_VALUE, {
        memberId: member.id,
        id: lineItem.id,
        value: newValue,
        valueType: newValueType,
      }),
    );
  };

  const handleBrokerageSplitValueChange = (member, lineItem, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT_VALUE, {
        memberId: member.id,
        value: newValue,
        valueType: newValueType,
      }),
    );
  };

  const handleRoyaltyValueChange = (member, lineItem, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_ROYALTY_VALUE, {
        memberId: member.id,
        value: newValue,
        valueType: newValueType,
      }),
    );
  };

  const handleSaveGci = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_GCI, {
        memberId: editingGci.member.id,
        gci: data.value,
        gciType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveNewLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.ADD_TEAM_MEMBER_LINE_ITEM, {
        memberId: addingLineItem.member.id,
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveEditedLineItem = (data) => {
    if (data.name.meta.type !== editingLineItem.lineItem.expenseOrIncome) {
      dispatch(
        updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_ACC, {
          memberId: editingLineItem.member.id,
          selectedOption: data.name,
          value: data.value,
          valueType: data.isPercentage ? "percent" : "flat",
          notes: data.notes,
          isInModal: true,
          editingLineItem,
        }),
      );

      return;
    }

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM, {
        memberId: editingLineItem.member.id,
        id: editingLineItem.lineItem.id,
        type: data.name.meta.type,
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveRemovedLineItem = () => {
    const lineItem = removingLineItem || editingLineItem;

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.REMOVE_TEAM_MEMBER_LINE_ITEM, {
        memberId: lineItem.member.id,
        id: lineItem.lineItem.id,
        isInModal: true,
        type: lineItem.lineItem.expenseOrIncome,
      }),
    );
  };

  const handleSaveEditedBrokerageSplitLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT, {
        memberId: editingBrokerageSplitLineItem.member.id,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveEditedRoyaltyLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_ROYALTY, {
        memberId: editingRoyaltyLineItem.member.id,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleCancelSaveGci = () => {
    setEditingGci(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveNewLineItem = () => {
    setAddingLineItem(false);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveEditedLineItem = () => {
    setEditingLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveRemovedLineItem = () => {
    setRemovingLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveEditedBrokerageSplitLineItem = () => {
    setEditingBrokerageSplitLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveEditedRoyaltyLineItem = () => {
    setEditingRoyaltyLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  useEffect(() => {
    if (!isUpdating && modalErrors.length === 0) {
      setEditingGci(null);
      setAddingLineItem(null);
      setEditingLineItem(null);
      setRemovingLineItem(null);
      setEditingBrokerageSplitLineItem(null);
      setEditingRoyaltyLineItem(null);
    }
  }, [isUpdating, modalErrors]);

  return (
    <>
      <TeamMembersWithLineItems
        members={teamMembers}
        onGciLabelClick={handleGciLabelClick}
        onGciValueChange={handleGciValueChange}
        onUnitsChange={handleUnitsChange}
        onRemoveTeamMember={handleRemoveTeamMember}
        onAddLineItem={handleAddLineItem}
        onLineItemDragEnd={handleLineItemDragEnd}
        onLineItemOptionChange={handleLineItemOptionChange}
        onLineItemValueChange={handleLineItemValueChange}
        onEditLineItem={handleEditLineItem}
        onRemoveLineItem={handleRemoveLineItem}
        onBrokerageSplitValueChange={handleBrokerageSplitValueChange}
        onEditBrokerageSplitLineItem={handleEditBrokerageSplitLineItem}
        onRoyaltyValueChange={handleRoyaltyValueChange}
        onEditRoyaltyLineItem={handleEditRoyaltyLineItem}
      />

      {editingGci && (
        <EditAmountModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          title="Edit Agent GCI"
          value={editingGci.member.gci.value}
          isPercentage={editingGci.member.gci.type === "percent"}
          notes={editingGci.member.notes}
          onSave={handleSaveGci}
          onCancel={handleCancelSaveGci}
        />
      )}

      {addingLineItem && (
        <AddEditExpenseIncomeModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          nameList={lineItemTypeOptions.agent}
          onSave={handleSaveNewLineItem}
          onDelete={() => {}}
          onCancel={handleCancelSaveNewLineItem}
        />
      )}

      {editingLineItem && (
        <AddEditExpenseIncomeModal
          show
          isEditing
          isSaving={isUpdating}
          isDeleting={isUpdating}
          errors={modalErrors}
          nameList={editingLineItem.lineItem.options}
          name={editingLineItem.lineItem.selectedOption}
          value={editingLineItem.lineItem.value}
          isPercentage={editingLineItem.lineItem.valueType === "percent"}
          notes={editingLineItem.lineItem.notes}
          onSave={handleSaveEditedLineItem}
          onDelete={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveEditedLineItem}
        />
      )}

      {removingLineItem && (
        <DeleteExpenseIncomeModal
          show
          isDeleting={isUpdating}
          errors={modalErrors}
          onConfirm={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveRemovedLineItem}
        />
      )}

      {editingBrokerageSplitLineItem && (
        <EditAmountModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          title="Edit Brokerage Split"
          value={editingBrokerageSplitLineItem.lineItem.value}
          isPercentage={editingBrokerageSplitLineItem.lineItem.valueType === "percent"}
          notes={editingBrokerageSplitLineItem.lineItem.notes}
          onSave={handleSaveEditedBrokerageSplitLineItem}
          onCancel={handleCancelSaveEditedBrokerageSplitLineItem}
        />
      )}

      {editingRoyaltyLineItem && (
        <EditAmountModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          title="Edit Royalty"
          value={editingRoyaltyLineItem.lineItem.value}
          isPercentage={editingRoyaltyLineItem.lineItem.valueType === "percent"}
          notes={editingRoyaltyLineItem.lineItem.notes}
          onSave={handleSaveEditedRoyaltyLineItem}
          onCancel={handleCancelSaveEditedRoyaltyLineItem}
        />
      )}
    </>
  );
};

TeamMemberSection.propTypes = {
  financials: PropTypes.shape({
    // Schema validation for the inner fields will be handled by the inner
    // components, so we mark them as "any" here.
    adaptedData: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      teamMembers: nullable(PropTypes.any).isRequired,
    }).isRequired,

    lineItemTypeOptions: PropTypes.shape({
      transactionIncome: PropTypes.arrayOf(optionShape),
      transactionExpense: PropTypes.arrayOf(optionShape),
      agent: PropTypes.arrayOf(optionShape),
    }),

    meta: PropTypes.shape({
      isUpdating: PropTypes.bool.isRequired,
      modalErrors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  // Allow forbid prop types since this is implemented by another TDP section
  // eslint-disable-next-line react/forbid-prop-types
  listingTeamMembers: PropTypes.arrayOf(PropTypes.any).isRequired,

  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tdpFinancialsReducer, tdpPeopleReducer }) => ({
  financials: { ...tdpFinancialsReducer.financials },
  listingTeamMembers: tdpPeopleReducer.people.team_members ?? [],
});

export default connect(mapStateToProps)(TeamMemberSection);
