import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import Portal from "../Portal";
import Backdrop from "./Backdrop";
import ModalContextProvider, { ModalContext } from "./ModalContext";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ModalTitle from "./ModalTitle";

import {
  MODAL_ANIMATION_DURATION_MS,
  containerAnimationStyles,
  containerTransitionStyles,
  modalAnimationStyles,
  modalTransitionStyles,
} from "./animationStyles";
import CLASSES from "./classes.json";

const { MODAL_CONTAINER_CLASSES, MODAL_DIALOG_CLASSES, MODAL_CONTENT_CLASSES, MODAL_SIZES } = CLASSES;

const dictKeyFromSizeString = (size) => {
  switch (size) {
    case "sm":
      return "small";
    case "lg":
      return "large";
    default:
      return size;
  }
};

const getModalMarginClasses = (size) => MODAL_SIZES.MARGIN_CLASSES[dictKeyFromSizeString(size)];

const getModalWidthClasses = (size, overrideWidthClasses) => {
  if (overrideWidthClasses !== null) {
    return overrideWidthClasses;
  }

  return MODAL_SIZES.WIDTH_CLASSES[dictKeyFromSizeString(size)];
};

const Modal = ({
  show = false,
  onHide,
  children,
  className,
  size,
  dialogClassName,
  contentClassName,
  variant,
  backdrop,
  deprecatedOverrideDialogWidthClasses,
  ...props
}) => {
  const nodeRef = useRef(null);

  const shouldCloseOnClick = useMemo(() => backdrop !== "static", [backdrop]);

  const renderModalContainer = (context) => {
    const dialogSizeClasses = `
      ${getModalMarginClasses(context.size)}
      ${getModalWidthClasses(context.size, deprecatedOverrideDialogWidthClasses)}
    `;

    return (
      <Portal>
        <Transition
          in={context.show}
          appear
          timeout={MODAL_ANIMATION_DURATION_MS}
          unmountOnExit
          mountOnEnter
          nodeRef={nodeRef}
        >
          {(modalContainerState) => (
            <div
              className="tw-relative tw-z-[1250]"
              ref={nodeRef}
              style={{
                ...containerAnimationStyles(MODAL_ANIMATION_DURATION_MS),
                ...containerTransitionStyles[modalContainerState],
              }}
            >
              {backdrop && <Backdrop shouldCloseOnClick={shouldCloseOnClick} />}
              {/* Both of these are rendered inside the ModalContext which already provides the keyboard listeners */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className={`${MODAL_CONTAINER_CLASSES} ${className}`}
                onClick={() => {
                  if (shouldCloseOnClick) {
                    context.onHide();
                  }
                }}
                data-js-modal-component
                {...props}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={(evt) => evt.stopPropagation()}
                  className={`${MODAL_DIALOG_CLASSES} ${dialogSizeClasses} ${dialogClassName}`}
                >
                  <Transition
                    in={context.show}
                    appear
                    timeout={MODAL_ANIMATION_DURATION_MS}
                    unmountOnExit
                    mountOnEnter
                  >
                    {(modalState) => (
                      <div
                        style={{
                          ...modalAnimationStyles(MODAL_ANIMATION_DURATION_MS),
                          ...modalTransitionStyles[modalState],
                        }}
                        className={`${MODAL_CONTENT_CLASSES} ${contentClassName}`}
                      >
                        {children}
                      </div>
                    )}
                  </Transition>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </Portal>
    );
  };

  return (
    <ModalContextProvider variant={variant} show={show} onHide={onHide} size={size}>
      <ModalContext.Consumer>{(value) => renderModalContainer(value)}</ModalContext.Consumer>
    </ModalContextProvider>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "sm", "large", "lg", "default"]),
  dialogClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  variant: PropTypes.oneOf(["classic", "modern"]),
  backdrop: PropTypes.oneOf([true, false, "static"]),
  deprecatedOverrideDialogWidthClasses: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  onHide: null,
  children: null,
  className: "",
  size: "default",
  dialogClassName: "",
  contentClassName: "",
  variant: "classic",
  backdrop: true,
  deprecatedOverrideDialogWidthClasses: null,
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Context = ModalContext;

export { Modal };
export default Modal;
