import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useClickOutside } from "../../../hookHelpers";

const DisplayEditorToggleBase = ({ mode, display, editor, onClickedOutside, className }) => {
  const isEditing = () => mode === "editor";

  // Short-circuit to avoid creating a ref and registering a document event listener
  if (!isEditing()) {
    return <div className={className}>{display}</div>;
  }

  if (!onClickedOutside) {
    return <div className={className}>{editor}</div>;
  }

  const ref = useRef();

  useClickOutside(ref, () => {
    onClickedOutside();
  });

  return (
    <div ref={ref} className={className}>
      {editor}
    </div>
  );
};

DisplayEditorToggleBase.propTypes = {
  mode: PropTypes.oneOf(["display", "editor"]).isRequired,
  display: PropTypes.node.isRequired,
  editor: PropTypes.node.isRequired,
  onClickedOutside: PropTypes.func,
  className: PropTypes.string,
};

DisplayEditorToggleBase.defaultProps = {
  onClickedOutside: null,
  className: "",
};

export default DisplayEditorToggleBase;
