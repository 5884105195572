/* eslint-disable camelcase */
class Agent {
  constructor({ first_name, last_name, avatar, id, photo_url, show_url }) {
    this.firstName = first_name;
    this.lastName = last_name;
    this.avatar = avatar;
    this.id = id;
    this.photo_url = photo_url;
    this.photoURL = photo_url;
    this.showURL = show_url;
    this.first_name = first_name;
    this.last_name = last_name;
  }

  get name() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].join(" ");
    }
    return "N/A";
  }

  get title() {
    return this.name;
  }

  get initials() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName]
        .filter((f) => !!f)
        .map((s) => s.toUpperCase()[0])
        .join("")
        .slice(0, 2);
    }
    return "N/A";
  }
}

export default Agent;
