import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const IntegrationButton = ({ integration, onClick }) => {
  const theme = useSelector(({ layout }) => layout.theme);
  return (
    <button
      key={integration.slug}
      id={`app-card-${integration.slug}`}
      className={`tw-flex tw-flex-col tw-gap-[8px] tw-flex-1 tw-justify-center tw-items-center tw-rounded-[8px] tw-p-[12px] tw-border-none tw-bg-transparent tw-outline-none hover:tw-bg-gray/15 focus:tw-bg-gray/15 active:tw-bg-gray/30 ${
        theme.name === "place-theme"
          ? "hover:tw-text-semantic-blue-25 active:tw-text-semantic-blue-25"
          : "hover:tw-text-brivity-blue-dark active:tw-text-brivity-blue-dark"
      }`}
      type="button"
      onClick={() => {
        if (integration.launch) window.open(integration.launchUrl, "_blank");
        else onClick?.();
      }}
    >
      <img className="tw-max-w-[60px]" src={integration.imageUrl} alt={integration.headline} />
      <span>{integration.headline}</span>
    </button>
  );
};

IntegrationButton.propTypes = {
  integration: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

IntegrationButton.defaultProps = {
  onClick: undefined,
};

export default IntegrationButton;
