import React from "react";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Sitebody from "./Sitebody";

const AdSite = ({ isFromManageModal, modalOpen, planId, closeModal }) => (
  <Modal
    show={modalOpen}
    onHide={closeModal}
    data-cy="marketing-advertising-plan-adsite-modal"
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[600px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Add Site" closeButton />
    <Modal.Body>
      <span className="tw-text-[14px] tw-font-normal tw-leading-[20px] tw-text-gray-75">
        When you promote this listing on a 3rd party website, add the link here to have it appear in the
        Client Email Update and client timeline for this transaction.
      </span>
      <Sitebody isFromManageModal={isFromManageModal} planId={planId} closeModal={closeModal} />
    </Modal.Body>
  </Modal>
);

AdSite.propTypes = {
  modalOpen: Proptypes.bool,
  isFromManageModal: Proptypes.bool,
  closeModal: Proptypes.func,
  planId: Proptypes.string,
};

AdSite.defaultProps = {
  modalOpen: false,
  isFromManageModal: false,
  closeModal: null,
  planId: null,
};

export default AdSite;
