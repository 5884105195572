import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Chart } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import AnnotationPlugin from "chartjs-plugin-annotation";
import Loading from "../../../Goals/components/Loader";
import Error from "../DashboardShared/Error";
import { Section, SectionLabel, StatCard } from "../BusinessDashboard";
import Dropdown from "../../../shared/v2/Dropdown";
import CustomControl from "../BusinessDashboard/Dropdown/CustomControl";
import { CustomBasicSingleValue } from "../BusinessDashboard/Dropdown/CustomSingleValue";
import CustomValueContainer from "../BusinessDashboard/Dropdown/CustomValueContainer";
import getDateDropdownOptions from "../DashboardShared/helpers/getDateDropdownOptions";
import TextToggle from "../../../shared/v2/TextToggle/TextToggle";
import IconToggle from "../../../shared/v2/IconToggle/IconToggle";
import { ChartBarRegularV6, ChartLineRegularV6 } from "../../../shared/v2/Icomoon";
import Dot from "../BusinessDashboard/Dot";
import usePerAgentProduction from "./services/usePerAgentProduction";
import Checkbox from "../../../shared/v2/Checkbox";
import GoalLinePlugin from "./utils/GoalLinePlugin";
import { STAT_CLASSES } from "./helpers/StatCard";
import CustomDropdownIndicator from "../BusinessDashboard/Dropdown/CustomDropdownIndicator";
import DashIcon from "../DashboardShared/DashIcon";
import DashTooltip from "../DashboardShared/DashTooltip";

Chart.register(AnnotationPlugin);

const PerAgentProductionSection = ({ year }) => {
  const MONTHLY_OVERVIEW_OPTIONS = useMemo(() => getDateDropdownOptions(year), [year]);
  const [agentType, setAgentType] = useState("fullTime");
  const [chartType, setChartType] = useState("bar");
  const [rangeType, setRangeType] = useState(MONTHLY_OVERVIEW_OPTIONS[0]);
  const [includeOperatorProduction, setIncludeOperatorProduction] = useState(true);
  const [rerender, setRerender] = useState(false);
  const { actions, chartOptions, data, error, loading } = usePerAgentProduction({
    year,
    agentType,
    rangeType,
    includeOperatorProduction,
  });
  // hack: when using parsing keys with chartjs it doesn't trigger rerender until next change
  const shouldRerender = rerender === includeOperatorProduction;

  useEffect(() => setRangeType(MONTHLY_OVERVIEW_OPTIONS[0]), [MONTHLY_OVERVIEW_OPTIONS, year]);
  useEffect(() => {
    if (rerender !== includeOperatorProduction) setRerender((r) => !r);
  }, [rerender, includeOperatorProduction]);

  return (
    <Section className="tw-relative tw-flex-1 tw-min-w-[320px] tw-gap-[24px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <SectionLabel>
          Per Agent Production <DashTooltip text="Average monthly unit production per active agent" />
        </SectionLabel>
        <Dropdown
          components={{
            Control: CustomControl,
            SingleValue: CustomBasicSingleValue,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator,
          }}
          value={rangeType}
          onChange={setRangeType}
          options={MONTHLY_OVERVIEW_OPTIONS}
        />
      </div>
      <div>
        <TextToggle
          size="small"
          name="perAgentProduction"
          options={[
            {
              label: "Full Time",
              value: "fullTime",
            },
            {
              label: "Part Time",
              value: "partTime",
            },
          ]}
          value={agentType}
          onChange={(e) => setAgentType(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-wrap">
        <StatCard
          className={STAT_CLASSES}
          label="PAP YTD"
          mainStat="PAP YTD"
          stats={{ "PAP YTD": { value: data?.summary.papYtd } }}
        />
        <StatCard
          className={STAT_CLASSES}
          label="Full Time PAP YTD"
          mainStat="Full Time PAP YTD"
          stats={{ "Full Time PAP YTD": { value: data?.summary.fullTimePapYtd } }}
        />
        <StatCard
          className={STAT_CLASSES}
          label="Part Time PAP YTD"
          mainStat="Part Time PAP YTD"
          stats={{ "Part Time PAP YTD": { value: data?.summary.partTimePapYtd } }}
        />
      </div>
      <div className="tw-flex tw-justify-between">
        <Checkbox
          label="Include Operator's production"
          checked={includeOperatorProduction}
          onChange={(e) => setIncludeOperatorProduction(e.target.checked)}
        />
        <IconToggle
          options={[
            { value: "bar", icon: <ChartBarRegularV6 /> },
            { value: "line", icon: <ChartLineRegularV6 /> },
          ]}
          onChange={(e) => setChartType(e.target.value)}
          value={chartType}
        />
      </div>
      {chartType === "bar" && shouldRerender && (
        <Bar plugins={[ChartDataLabels, GoalLinePlugin]} data={data?.chartData} options={chartOptions} />
      )}
      {chartType === "line" && shouldRerender && (
        <Line plugins={[ChartDataLabels, GoalLinePlugin]} data={data?.chartData} options={chartOptions} />
      )}
      {/** TODO: May need custom chart.js plugin for additional labels */}
      <div className="tw-flex tw-flex-wrap tw-justify-end tw-gap-[24px] tw-text-12d tw-text-neutral-gray-75">
        <div className="tw-flex tw-items-center tw-gap-[8px]">
          <Dot className="!tw-mb-0 tw-bg-theme-primary" />
          <span>PAP</span>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[8px]">
          <Dot className="!tw-mb-0 tw-bg-tinted-gray-100" />
          <span>PAP Last Year</span>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[8px]">
          <DashIcon />
          Annual Goal
          <DashTooltip
            placement="left"
            text="Annual goal for PAP. Configured in Team Business Goals on the Goals settings page."
          />
        </div>
      </div>
      <Error show={Boolean(error)} className="!tw-mb-0 tw-mt-[8px]" onClose={actions.clearError} />
      {loading && <Loading />}
    </Section>
  );
};

PerAgentProductionSection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PerAgentProductionSection;
