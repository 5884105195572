import React from "react";
import { Col, Row, FormGroup } from "../../../../shared/v1";
import { formatValue, formatExpense, formatCompanyExpense } from "../../shared/FinancialViewHelpers";

export const Expense = (props) => {
  const { expense, showBorder, expenseType } = props;

  const { name, percentage, value, notes } = expense;
  return (
    <Row className={showBorder ? "tw-border-0 tw-border-t tw-border-solid tw-border-gray tw-pt-[10px]" : ""}>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">{name}</label>
          <span>
            {percentage
              ? formatValue(value, percentage)
              : expenseType === "company"
              ? formatCompanyExpense(formatValue(value, percentage), name)
              : formatExpense(formatValue(value, percentage))}
          </span>
        </FormGroup>
      </Col>
      {notes && (
        <Col size={4}>
          <FormGroup className="tw-min-h-[50px]">
            <label className="tw-block tw-mb-[5px]">Notes</label>
            <span>{notes}</span>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};
