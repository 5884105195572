import React, { useState } from "react";
import PropTypes from "prop-types";
import FloatingForm from "../../shared/v2/Modal/FloatingForm";
import Checkbox from "../../shared/v2/Checkbox/Checkbox";

const DeleteGoalTypeModal = ({ displayName, onClose, onDelete, show }) => {
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  return (
    <FloatingForm
      show={show}
      title="Are you sure?"
      onCancel={onClose}
      onSecondary={onClose}
      onPrimary={onDelete}
      primaryOption="Delete Custom Goal and Goal Data"
      primaryProps={{ schema: "warning", disabled: !hasAgreedToTerms }}
      closeOnClickOutside
      closeOnEscape
    >
      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        <p>
          Are you sure you want to delete <span className="tw-font-bold">{displayName}</span>? This action
          cannot be undone.
        </p>
        <p>
          Corresponding goal and tracking data for all users in your account will also be deleted. You will no
          longer be able to view past data on this goal.
        </p>
        <Checkbox
          checked={hasAgreedToTerms}
          onChange={(e) => setHasAgreedToTerms(e.target.checked)}
          label="I understand that by deleting this goal, I will be deleting all data corresponding to this goal and that this action cannot be undone. Brivity is not liable for lost data, nor are we able to retrieve it."
        />
      </div>
    </FloatingForm>
  );
};

DeleteGoalTypeModal.propTypes = {
  displayName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DeleteGoalTypeModal;
