export const LINKS_TEXT = [
  "timeline",
  "tasks",
  "notes",
  "details",
  "marketing",
  "feedback",
  "docs",
  "financials",
];

export const REF_LINKS_TEXT = ["timeline", "tasks", "notes", "details", "docs", "financials"];
