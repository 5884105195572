import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CaretDown, CaretUp, CircleInfoSolidV6 } from "../../../shared/v2/Icomoon";
import * as actions from "../actions/default";
import "./UsersTableHeader.scss";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const HEADER_ROWS = [
  { key: "avatar", title: "", sortable: false, colWidth: "tw-w-[56px]" },
  { key: "fullName", title: "Name", sortable: true, colWidth: "tw-w-196px" },
  { key: "agentType", title: "User Type", sortable: true, colWidth: "tw-w-196px" },
  { key: "position", title: "Position", sortable: true, colWidth: "tw-w-196px" },
  { key: "primaryEmail", title: "Brivity Login Email", sortable: false, colWidth: "tw-w-300px" },
  { key: "startDate", title: "Start Date", sortable: true, colWidth: "tw-w-196px" },
  { key: "lastActiveDate", title: "Last Active", sortable: false, colWidth: "tw-w-[150px]" },
  { key: "crmAccess", title: "CRM Access", sortable: false, colWidth: "tw-w-[150px]" },
  { key: "dateAdded", title: "User Added", sortable: false, colWidth: "tw-w-[150px]" },
  { key: "signInCount", title: "Number of Logins", sortable: false, colWidth: "tw-w-[150px]" },
  { key: "editRemove", title: "", sortable: false, colWidth: "tw-w-196px" },
];

const mapStateToProps = (state) => ({
  tableSortColumn: state.userSettings.tableSortColumn,
});

function UsersTableHeader({ updateSortOnTableColumn, tableSortColumn, isFilterActiveUsers }) {
  const currentUser = useCurrentUser();

  return (
    <thead>
      <tr className="tw-select-none">
        {HEADER_ROWS.map(({ key, title, sortable, colWidth, tooltip }) => {
          // Hide CRM Access column for non-admin users and when filtering for archived users
          if (key === "crmAccess" && (!currentUser.account.isAdmin || !isFilterActiveUsers)) return null;

          if (key === "avatar") {
            return (
              <th key={key} className={`tw-bg-white tw-py-[4px] tw-z-10 ${colWidth}`} aria-label="Avatar" />
            );
          }

          if (key === "editRemove") {
            return (
              <th
                key={key}
                className={`tw-bg-white tw-py-[4px] tw-z-10 ${colWidth}`}
                aria-label="Edit/Remove"
              />
            );
          }

          return (
            <th key={key} className={`tw-bg-white tw-py-[4px] tw-z-10 ${colWidth}`}>
              <div
                className={`tw-flex tw-items-center tw-font-semibold tw-text-neutral-gray-50 tw-text-12px tw-py-2 px-[8px] ${
                  key === "signInCount" ? "tw-justify-center" : "tw-w-fit"
                } ${sortable ? "hover:tw-cursor-pointer hover:tw-bg-tinted-gray-50" : ""}`}
                onClick={() => sortable && updateSortOnTableColumn(key)}
                aria-hidden="true"
              >
                <div className="tw-mr-2 tw-ml-[8px]">{title}</div>

                {tooltip && (
                  <div className="tw-inline-flex tw-flex-col tw-justify-center tw-items-center tw-mr-[8px] tw-w-[12px] tw-h-[12px]">
                    <Tooltip
                      multiline
                      placement="top"
                      content={<div className="tw-w-[264px]">{tooltip}</div>}
                      trigger={<CircleInfoSolidV6 className="tw-h-[8px] tw-w-[8px] tw-text-gray-75" />}
                    />
                  </div>
                )}

                {sortable && (
                  <div className="tw-inline-flex tw-flex-col tw-justify-center tw-items-center tw-mr-[8px] tw-w-[12px] tw-h-[12px]">
                    <CaretUp
                      className={`tw-my-[-2.5px] ${
                        tableSortColumn.key === key && !tableSortColumn.isAsc
                          ? "tw-text-black"
                          : "tw-text-neutral-gray-50"
                      }`}
                    />

                    <CaretDown
                      className={`tw-my-[-2.5px] ${
                        tableSortColumn.key === key && tableSortColumn.isAsc
                          ? "tw-text-black"
                          : "tw-text-neutral-gray-50"
                      }`}
                    />
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

UsersTableHeader.propTypes = {
  tableSortColumn: PropTypes.shape({ key: PropTypes.string, isAsc: PropTypes.bool }),
  isFilterActiveUsers: PropTypes.bool,
  updateSortOnTableColumn: PropTypes.func,
};

UsersTableHeader.defaultProps = {
  tableSortColumn: null,
  isFilterActiveUsers: true,
  updateSortOnTableColumn: null,
};

export default connect(mapStateToProps, actions)(UsersTableHeader);
