/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ThumbtackSolidV6 = (props) => (
  <Icon {...props} name="Thumbtack Solid V6">
    <path d="M3 1a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-.922l.356 4.631a5.26 5.26 0 0 1 2.484 2.959l.031.094c.1.303.022.641-.166.9a.917.917 0 0 1-.784.416h-10a.994.994 0 0 1-.811-.416.997.997 0 0 1-.137-.9l.031-.094a5.252 5.252 0 0 1 2.484-2.959L4.922 2h-.923a1 1 0 0 1-1-1zm6 11v3a1 1 0 1 1-2 0v-3h2z" />
  </Icon>
);

export default ThumbtackSolidV6;
