import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";

const CancelConfirmMultiline = ({ onCancelEditing, onConfirmEditing }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
    <Button onClick={onCancelEditing} schema="tertiary" data-test-cancel-trigger>
      Cancel
    </Button>
    <Button onClick={onConfirmEditing} schema="primary" data-test-confirm-trigger>
      Done
    </Button>
  </div>
);

CancelConfirmMultiline.propTypes = {
  onCancelEditing: PropTypes.func.isRequired,
  onConfirmEditing: PropTypes.func.isRequired,
};

export default CancelConfirmMultiline;
