import React from "react";
import PropTypes from "prop-types";

const BASE_CLASSES = `
  before:tw-table
  before:tw-content-['_']
  after:tw-table
  after:tw-content-['_']
  after:tw-clear-both
`;

export const Clearfix = ({ className, children, ...props }) => (
  <div
    className={`
      ${BASE_CLASSES}
      ${className}
    `}
    {...props}
  >
    {children}
  </div>
);

Clearfix.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Clearfix.defaultProps = {
  className: "",
  children: null,
};

export default Clearfix;
