/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MobileRegularV6 = (props) => (
  <Icon {...props} name="Mobile Regular V6">
    <path d="M11.5 0h-7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm.5 14a.5.5 0 0 1-.5.5h-7A.5.5 0 0 1 4 14V2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v12zm-3-1.5H7a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
  </Icon>
);

export default MobileRegularV6;
