import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash";
import format from "@shared/formatters";
import { HouseSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";
import HomeInfoOverlay from "./HomeInfoOverlay";

const transformComments = (comments) => {
  const parsedObject = {};
  if (typeof comments !== "string") return parsedObject;
  const parts = comments
    .split(/\r?\n/)
    .map((l) =>
      l
        .split(",")
        .map((s) => s.replace(/\s*<.+>(What size is your budget\?)?/, "").split(": "))
        .flat(),
    )
    .flat()
    .filter(Boolean);

  for (let i = 0; i < parts.length; i += 2) {
    parsedObject[camelCase(parts[i])] = parts[i + 1];
  }

  return parsedObject;
};

const TimelineGetPrequalifiedEvent = ({ event }) => {
  const timelineFields = useMemo(() => {
    const {
      comments,
      property: { main_photo: mainPhoto, ...property } = {},
      ...others
    } = event.timeline_fields;
    return {
      ...others,
      ...transformComments(comments),
      ...property,
      photo: mainPhoto,
      baths: property.bathrooms && Number(property.bathrooms),
      beds: property.bedrooms && Number(property.bedrooms),
      price: property.value,
    };
  }, [event.timeline_fields]);

  return (
    <BaseEvent
      icon={<HouseSolidV6 size="l" />}
      title={
        <span>
          <strong>Event:</strong> {event.titleized_type}
        </span>
      }
      interactedAt={event.interacted_at_absolute}
    >
      <HomeInfoOverlay timelineFields={timelineFields} />
      {timelineFields.areYouPurchasingOrRefinancing && (
        <div>Purchasing or Refinancing?: {timelineFields.areYouPurchasingOrRefinancing}</div>
      )}
      {timelineFields.whatTypeOfPropertyAreYouPurchasing && (
        <div>Property Type: {timelineFields.whatTypeOfPropertyAreYouPurchasing}</div>
      )}
      {timelineFields.areYouAFirstTimeHomeBuyer && (
        <div>
          First time home buyer?: {timelineFields.areYouAFirstTimeHomeBuyer === "true" ? "Yes" : "No"}
        </div>
      )}
      {timelineFields.purchasePrice && (
        <div>
          Budget:{" "}
          {timelineFields.purchasePrice ? format.currency(timelineFields.purchasePrice, 0) : "Not sure yet"}
        </div>
      )}
      {timelineFields.downPayment && (
        <div>
          Down payment:{" "}
          {timelineFields.downPayment === "undefined"
            ? "Not sure yet"
            : format.currency(timelineFields.downPayment, 0)}
        </div>
      )}
      {timelineFields.url && (
        <div>
          Property:{" "}
          <a href={timelineFields.url} target="_blank" rel="noreferrer">
            {timelineFields.street_address} {timelineFields.city}, {timelineFields.state} {timelineFields.zip}
          </a>
        </div>
      )}
      {timelineFields.tags && <div>Tags: {timelineFields.tags}</div>}
      {timelineFields.leadType && <div>Lead type: {timelineFields.leadType}</div>}
    </BaseEvent>
  );
};

TimelineGetPrequalifiedEvent.propTypes = {
  event: PropTypes.shape({
    titleized_type: PropTypes.string,
    timeline_fields: PropTypes.shape({
      comments: PropTypes.string,
      property: PropTypes.shape(),
    }),
    interacted_at_absolute: PropTypes.string,
  }).isRequired,
};

export default TimelineGetPrequalifiedEvent;
