import React from "react";

const DEFAULT_CLASS =
  "tw-flex-none tw-w-[30px] tw-h-[30px] tw-flex tw-justify-center tw-items-center tw-rounded-full";

const Circle = ({ icon, circleStyle, ...rest }) => (
  <div className={`${DEFAULT_CLASS} ${circleStyle}`} {...rest}>
    {icon && <i className={`${icon} tw-text-14d`} />}
  </div>
);

export default Circle;
