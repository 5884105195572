import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class TimelineTaggingEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false,
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  content = () => {
    const action = this.event.event_type == 'tagging_destroyed' ? 'deleted' : 'added'
    const tagName = this.event.timeline_fields.tag
    const className = `tag-${action}`

    return (
      <div>
        <span className="bold-detail">
          {this.event.timeline_fields.updater_name}
        </span>
        {` ${action} a tag: `}
        <span className={className}>
          {tagName}
        </span>
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <div className="timeline-description-text">
              {this.content()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                Tag
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.content()}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
