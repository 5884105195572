import { useEffect, useMemo, useState } from "react";
import { isCancel } from "axios";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";

const getRecruitingPipeline = (signal, params, isBrivity) =>
  caseTransformingAxios.get(`/api/v4/reporting/${isBrivity ? "business" : "operator"}_dashboard`, {
    params: { section: "recruiting_pipeline", ...params },
    signal,
  });

const useRecruitingPipeline = ({ isBrivity, rangeType = { value: "calendar" }, year }) => {
  const [state, _setState] = useState({ data: null, error: null, loading: false });
  const setState = useMemo(
    () => (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  );

  useEffect(() => {
    const abortController = new AbortController();
    setState({ loading: true });
    getRecruitingPipeline(
      abortController.signal,
      { selected_year: year, range_type: rangeType.value },
      isBrivity,
    )
      .then((res) => setState({ data: res.data, error: null, loading: false }))
      .catch((err) => {
        if (!isCancel(err)) setState({ error: true, loading: false });
      });

    return () => abortController.abort();
  }, [isBrivity, rangeType, year]);

  return {
    ...state,
    actions: {
      clearError: () => setState({ error: null }),
    },
  };
};

export default useRecruitingPipeline;
