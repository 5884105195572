import React from "react";
import { useDispatch } from "react-redux";

import TextComboInput from "@shared/v2/TextComboInput";
import { inputDescription } from "../../ModalViews/styles";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

const Subdomain = () => {
  const dispatch = useDispatch();
  const { subdomain } = useContactPerson().loginUserAttributes;
  return (
    <>
      <div className="tw-flex tw-items-end tw-justify-between">
        <TextComboInput
          label="Subdomain"
          value={subdomain}
          containerClassName="tw-w-[381px]"
          onChange={(e) =>
            dispatch(setPersonValue({ key: "loginUserAttributes.subdomain", value: e.target.value }))
          }
        />
        <span className="tw-text-gray-50 tw-leading-[36px]">.realestateteam.com</span>
      </div>
      <div className={`${inputDescription} tw-tracking-[-.3px]`}>
        You can only create a subdomain for your main site.
      </div>
    </>
  );
};

export default Subdomain;
