/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { InlineEditIcon } from "../icons";
import Switcher from "./Switcher";
import TextInput from "../../../shared/v2/TextInput";

const TextCell = ({
  value,
  isEditable,
  onChange,
  justify,
  editableDisplayClassName,
  editableContainerClassName,
  iconProps,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const iconType = <InlineEditIcon isEditable={isEditable} {...iconProps} />;

  const blankNonEditableDisplay = <span>—</span>;
  const blankEditableDisplay = (
    <span data-cy="editable-blank-text-cell" className="tw-text-teal">
      Add
    </span>
  );
  const nonEditableDisplay = <span>{currentValue}</span>;
  const editableDisplay = (
    <div data-cy="editable-display-text-cell" className={editableContainerClassName}>
      <span className={`tw-mr-8px ${editableDisplayClassName}`}>{currentValue}</span>
      {iconType}
    </div>
  );
  const isEmpty = !currentValue || currentValue.length === 0;

  const maybeToggleToInput = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const updateIfChanged = (newValue) => {
    if (newValue !== value) onChange(newValue);
  };

  const handlekeydownEvent = (event) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      updateIfChanged(event.target.value);
      setIsEditing(false);
    }
  };

  const input = (
    <TextInput
      value={currentValue}
      data-cy="editable-input-text-cell"
      style={{ maxWidth: "10em" }}
      ref={(newInput) => newInput && newInput.focus()}
      onChange={(e) => setCurrentValue(e.target.value)}
      onKeyDown={handlekeydownEvent}
      onBlur={(e) => {
        updateIfChanged(e.target.value);
        setIsEditing(false);
      }}
    />
  );

  let content = null;
  if (!isEditable && !isEmpty) {
    content = nonEditableDisplay;
  } else if (!isEditable && isEmpty) {
    content = blankNonEditableDisplay;
  } else if (isEditable && isEditing) {
    content = input;
  } else if (isEditable && !isEditing && isEmpty) {
    content = blankEditableDisplay;
  } else if (isEditable && !isEditing && !isEmpty) {
    content = editableDisplay;
  }

  return (
    <Switcher justify={justify} onClick={maybeToggleToInput} isActionable={isEditable && !isEditing}>
      {content}
    </Switcher>
  );
};

export default TextCell;
