/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleExclamationRegularV6 = (props) => (
  <Icon {...props} name="Circle Exclamation Regular V6">
    <path d="M8 0C3.581 0 0 3.581 0 8s3.581 8 8 8c4.419 0 8-3.581 8-8s-3.581-8-8-8zm0 14.5c-3.584 0-6.5-2.916-6.5-6.5S4.416 1.5 8 1.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-5a.75.75 0 0 0 .75-.75v-4a.75.75 0 0 0-1.5 0v4c0 .416.338.75.75.75zm0 1.034a.983.983 0 1 0 0 1.967.983.983 0 0 0 0-1.968z" />
  </Icon>
);

export default CircleExclamationRegularV6;
