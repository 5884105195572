export const baseLinkClasses = [
  "tw-text-neutral-gray-75",
  "tw-flex",
  "tw-flex-row",
  "tw-justify-center",
  "tw-items-center",
  "tw-w-[32px]",
  "tw-h-[32px]",
];

export const activeBg = "tw-bg-neutral-gray-10";

export default { baseLinkClasses, activeBg };
