/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UploadSolidV6 = (props) => (
  <Icon {...props} name="Upload Solid V6">
    <path d="M3.294 5.706a1 1 0 0 0 1.414 0L7 3.415v7.584a1 1 0 0 0 2 0V3.415l2.293 2.293a1 1 0 0 0 1.414-1.414l-4-4C8.512.097 8.256-.001 8-.001s-.513.098-.706.293l-4 4.001a.998.998 0 0 0 0 1.413zM15 11h-5a2 2 0 1 1-4 0H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm-1.5 3.25c-.412 0-.75-.338-.75-.75s.338-.75.75-.75.75.338.75.75-.338.75-.75.75z" />
  </Icon>
);

export default UploadSolidV6;
