import axios from "axios";

export const updateInteractionPromise = (interaction) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/people/${interaction.person_id}/interactions/${interaction.id}.json`, { interaction: interaction, person_details_page: 'true', authenticity_token: ReactOnRails.authenticityToken()})
        .then(res => {
          const successObject = {
            success: true,
            data: res.data
          }
          resolve(successObject);
        }).catch(err => {
      const errorObject = {
        success: false,
        data: err.response.data
      }
      reject(errorObject);
    })
  });
}
