import React, { useState } from "react";
import { func, bool, string } from "prop-types";
import Alert from "@shared/v2/Alert";
import TextButton from "@shared/v2/TextButton";
import Check from "@shared/v2/Icomoon/Icons/Check";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import ImportListingDetailsModal from "../ImportListingDetailsModal";

const ImportDetailModal = ({
  preStatus,
  isImportModalOpen,
  closeModalAsThunk,
  status,
  isFromIndex,
  listingUuid,
  closeCurrentModal,
}) => {
  const [showModal, setshowModal] = useState(false);
  const [showAlert, setshowAlert] = useState(false);

  const closeModal = () => {
    closeModalAsThunk();
  };
  const closeNextModal = () => {
    setshowModal(false);
  };
  const importClick = () => {
    closeCurrentModal();
    setshowModal(true);
  };

  return (
    <>
      {showAlert && (
        <Alert
          variant="success"
          containerClass="tw-z-1000 !tw-top-0 !tw-absolute !tw-right-2/5 !tw-left-2/5"
          text={
            <>
              <Check size="xl" className="tw-w-16px tw-h-16px tw-mb-[-4px]" />
              <span className="tw-pl-[9px]">Listing details imported from MLS</span>
            </>
          }
          role="alert"
        />
      )}
      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        // 636px = 616px from Figma + 10px for each horizontal padding (20px)
        dialogClassName="tw-w-[440px] tw-h-auto"
        contentClassName="tw-w-full tw-h-full"
        backdrop="static"
        show={isImportModalOpen}
        onHide={closeModal}
        data-cy="import-listing-modal"
      >
        <Modal.Header
          titleClassName="tw-mb-32px tw-text-neutral-gray-75"
          title="Import Listing Details"
          closeButton={false}
        />

        <Modal.Body className="tw-mb-32px">
          <div className="tw-w-[392px]">
            We noticed you've updated the transaction status from{" "}
            <span className="tw-font-bold tw-capitalize">{preStatus}</span> to{" "}
            <span className="tw-font-bold tw-capitalize"> {status}</span>. Would you like to import listing
            details from the MLS?
          </div>
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-justify-end">
          <TextButton className="tw-pr-[16px]" size="medium" onClick={closeModal}>
            No THanks
          </TextButton>

          <Button
            className="tw-flex tw-items-center"
            size="medium"
            schema="primary"
            onClick={importClick}
            data-cy="import-button"
          >
            IMPORT
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && (
        <ImportListingDetailsModal
          modalOpen={showModal}
          closeModal={closeNextModal}
          setshowAlert={setshowAlert}
          isFromIndex={isFromIndex}
          listingUuid={listingUuid}
          checkAddressAndcloseModal={closeModal}
        />
      )}
    </>
  );
};

ImportDetailModal.propTypes = {
  isImportModalOpen: bool,
  isFromIndex: bool,
  listingUuid: string,
  closeModalAsThunk: func,
  closeCurrentModal: func,
  preStatus: string,
  status: string.isRequired,
};

ImportDetailModal.defaultProps = {
  isImportModalOpen: false,
  isFromIndex: false,
  listingUuid: null,
  preStatus: null,
  closeModalAsThunk: () => {},
  closeCurrentModal: () => {},
};

export default ImportDetailModal;
