/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AtSolidV6 = (props) => (
  <Icon {...props} name="At Solid V6">
    <path d="M6.494.648C3.574 1.221 1.222 3.575.65 6.495c-.864 4.403 2.145 8.319 6.222 8.909a.992.992 0 0 0 1.13-.984v-.021a.984.984 0 0 0-.839-.976A5.516 5.516 0 0 1 2.5 7.979c0-3.216 2.769-5.797 6.044-5.481 2.861.277 4.956 2.852 4.956 5.725v.505c0 .69-.561 1.252-1.25 1.252S11 9.419 11 8.728V4.975a.501.501 0 0 0-.5-.501h-.999a.493.493 0 0 0-.472.365 3.385 3.385 0 0 0-2.689-.16c-1.211.429-2.129 1.528-2.304 2.801a3.503 3.503 0 0 0 3.466 4c.826 0 1.576-.298 2.175-.778a3.252 3.252 0 0 0 3.419 1.172c1.443-.335 2.406-1.747 2.377-3.213v-.653c0-4.662-4.184-8.301-8.978-7.362zm.978 8.861a1.503 1.503 0 0 1 0-3.004c.827 0 1.5.674 1.5 1.502s-.644 1.502-1.5 1.502z" />
  </Icon>
);

export default AtSolidV6;
