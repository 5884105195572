import React, {Component} from 'react';
import ReactOnRails from 'react-on-rails';
import {Button, Checkbox, FormGroup, Col, Row, Table, Modal, Form} from 'react-bootstrap';
import Moment from 'moment';
import axios from 'axios';
import serialize from 'form-serialize';
import css from "../../Reports/components/report-styles.module.css";
import JobRunner from './JobRunner';
import OktaStatus from './OktaStatus';
import UserTable from './UserTable';

export default class SupportUsersIndex extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      isSubmitting: false,
      editUser: null,
      users: props.users,
      current_super_user_role: props.current_super_user_role,
      userError: false,
      successMessage: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showModal = () => {
    this.setState({modalOpen: true})
  }

  hideModal = () => {
    this.setState({modalOpen: false, userError: false, successMessage: null})
  }

  editUserModal = (user) => {
    this.setState({editUser: user, userError: false})
    this.showModal()
  }

  async handleSubmit(event){
    event.preventDefault();
    this.setState({isSubmitting: event});
    const formData = serialize(event.target, { hash: true });

    axios.post(`/support/accounts/${this.state.editUser.id}/update_user`,
      {user: formData, authenticity_token: ReactOnRails.authenticityToken()},
      { headers: {"Content-Type": "application/json"} }
    )
    .then(res => this.userUpdateSuccess(res))
    .catch(error => { this.handleError(error.response.data) });
  }

  updateTableRow = (data) => {
    let key = data.id;
    const index = this.state.users.findIndex(user => user.id === key),
      users = [...this.state.users]
    users[index] = data;
    this.setState({users});
  }

  userUpdateSuccess = (response) => {
    this.setState({isSubmitting: false});
    this.hideModal();
    this.updateTableRow(response.data);
  }

  handleJobSuccess = (message) => {
    this.setState({ successMessage: message });
  }

  handleError = (error) =>{
    this.setState({isSubmitting: false, userError: error});
  }

  userErrors(){
    return(
      <Row>
        <div className={ "alert alert-danger col-xs-12" }>
        {this.state.userError.errors.map(error =>
          error + " "
        )}
        </div>
      </Row>
    )
  }

  personFields = (user) => {
    return(
      <div>
        <div className="form-group">
          <div className={css.flexxy} style={{ alignItems: "baseline" }}>
            <label style={{ paddingRight: "10px", flexShrink: 0 }}>First Name</label>
            <input type="text" className="form-control" defaultValue={user.person.first_name} name={"person_attributes[first_name]"} />
          </div>
        </div>
        <div className="form-group">
          <div className={css.flexxy} style={{ alignItems: "baseline" }}>
            <label style={{ paddingRight: "10px", flexShrink: 0 }}>Last Name</label>
            <input type='text' className="form-control" defaultValue={user.person.last_name} name={"person_attributes[last_name]"} />
          </div>
        </div>
        <div className="form-group">
          <div className={css.flexxy} style={{ alignItems: "baseline" }}>
            <label style={{ paddingRight: "10px", flexShrink: 0 }}>Status</label>
            <select defaultValue={user.person.status} className="form-control" name={"person_attributes[status]"}>
              <option></option>
              {this.props.person_statuses.map(status =>
                this.selectOption(status)
              )}
            </select>
          </div>
        </div>
        <div className="form-group">
          <div className={css.flexxy} style={{ alignItems: "baseline" }}>
            <label style={{ paddingRight: "10px", flexShrink: 0 }}>Type Name</label>
            <select defaultValue={user.person.type_name} className="form-control" name={"person_attributes[type_name]"}>
              <option></option>
              {this.props.person_types.map(type =>
                this.selectOption(type)
              )}
            </select>
          </div>
        </div>
      </div>
    )
  }

  selectOption = (val) => {
    return(
      <option key={val}>{val}</option>
    )
  }

  editUserForm = (user) => {
    let displayError;
    if(this.state.userError){
      displayError = this.userErrors();
    }
    return(
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          { displayError }
          { this.state.successMessage &&
            <Row>
              <div className={ "alert alert-success col-xs-12" }>
                {this.state.successMessage}
              </div>
            </Row>
          }
          <Row>
              <Col xs={4}>
                <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                  <label style={{ paddingRight: "10px", flexShrink: 0 }}>Created At (PST)</label>
                  {Moment(user.created_at).format("MM/DD/YYYY hh:mm A")}
                </div>
              </Col>
              <Col xs={4}>
                <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                  <label style={{ paddingRight: "10px", flexShrink: 0 }}>Updated At (PST)</label>
                  {Moment(user.updated_at).format("MM/DD/YYYY hh:mm A")}
                </div>
              </Col>
              <Col xs={4}>
                <OktaStatus accountId={user.account_id} userId={user.id} />
              </Col>
          </Row>
        </div>
        <div className="form-group">
          <Row>
            <Col xs={6}>
              <JobRunner
                accountId={user.account_id}
                handleSuccess={this.handleJobSuccess}
                handleError={this.handleError}
                userId={user.id}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={6}>
            <h5>User Details</h5>
            <div className="form-group">
              <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                <label style={{ paddingRight: "10px", flexShrink: 0 }}>Email</label>
                <input type='text' className="form-control" defaultValue={user.email} name={"email"} />
              </div>
            </div>
            <div className="form-group">
              <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                <label style={{ paddingRight: "10px", flexShrink: 0 }}>Active</label>
                <select defaultValue={user.active} className="form-control" name={"active"}>
                  <option>true</option>
                  <option>false</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                <label style={{ paddingRight: "10px", flexShrink: 0 }}>Managed Phone Number</label>
                <input type='text' className="form-control" defaultValue={user.managed_phone_number} name={"managed_phone_number"} />
              </div>
            </div>
            <div className="form-group">
              <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                <label style={{ paddingRight: "10px", flexShrink: 0 }}>Lead Parsing Email</label>
                <input
                  type='text'
                  className="form-control"
                  defaultValue={user.user_lead_parsing_email ? user.user_lead_parsing_email.email : ""}
                  name={"user_lead_parsing_email_attributes[email]"}
                  pattern={"[a-z0-9._%a-z\\-]+@+mail\\.brivity\\.com"}
                  title="Lead Parsing email must be lowercase and a mail.brivity.com account"
                />
              </div>
            </div>
            <div className="form-group">
              <div className={css.flexxy} style={{ alignItems: "baseline" }}>
                <label style={{ paddingRight: "10px", flexShrink: 0 }}>CRM Access</label>
                <select defaultValue={user.crm_access} className="form-control" name="crm_access">
                  <option>true</option>
                  <option>false</option>
                </select>
              </div>
            </div>            
          </Col>
          <Col xs={6}>
            <h5>Person Details</h5>
            {user.person ? (
              this.personFields(user)
            ) : (
              <div>
                <h3 className="text-danger">THIS USER IS MISSING A PERSON</h3>
                <p>
                  This is indicative of an exception elsewhere. Find or create a person to associate with this user.
                  <br />
                  Enter a person UUID below to associate this user to a person.
                </p>
                <input type="text" className="form-control" placeholder="Person UUID" name="new_person_uuid" />
              </div>
            )}
          </Col>
        </Row>
        <div className="form-group">
          <Button bsSize="small" className="modal-cancel" onClick={this.hideModal}>Cancel</Button>
          {this.state.isSubmitting ?
            <Button className="modal-submit pull-right" disabled>Saving..</Button> :
            <input type={"submit"} value={"Save"} className="modal-submit pull-right"/>
          }

        </div>
      </form>
    )
  }

  render() {
    return(

      <div>
        <Row>
          <UserTable
            currentSuperUserRole={this.state.current_super_user_role}
            onEditUser={this.editUserModal}
            users={this.state.users}
          />
        </Row>

        <Modal
          show={this.state.modalOpen}
          bsSize={'lg'}
          onHide={this.hideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
                Edit User {this.state.editUser ? this.state.editUser.id : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                {this.state.editUser ? this.editUserForm(this.state.editUser) : "No User Selected"}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
