import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { replace } from "lodash";
import Section from "./Section";
import { PERIOD_OPTIONS } from "./HeadingSelect";
import Goal from "./Goal";
import Loader from "./Loader";
import useGoals, { goalValueToYearly } from "../services/useGoals";
import SectionHeader from "./SectionHeader";

const TeamBusinessGoals = ({ year, isAdmin }) => {
  const businessFormRef = useRef(null);
  const businessAgentFormRef = useRef(null);
  const [period, setPeriod] = useState(PERIOD_OPTIONS[0]);
  const [isEditing, setIsEditing] = useState(false);
  const businessGoals = useGoals({
    year,
    period,
    section: "business",
    load: isAdmin,
  });
  const businessAgentGoals = useGoals({
    year,
    period,
    section: "business_agent",
    load: isAdmin,
  });

  const submitGoals = (ref, section) => (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(ref.current));

    Object.keys(data).forEach((key) => {
      const oldGoal = section.goals[key] || { goalTypeId: key };
      const goalType = businessAgentGoals.goalTypes.find((x) => x.id === oldGoal.goalTypeId)?.name || "";
      const goal = {
        ...oldGoal,
        goalValue: goalValueToYearly(replace(data[key], /,/g, "") || 0, period, goalType),
        year,
      };

      if (goal.goalValue === String(oldGoal.goalValue)) return;

      if (goal.id) {
        section.actions.updateGoal(goal);
      } else {
        section.actions.createGoal(goal);
      }
    });

    setIsEditing(false);
  };

  if (!isAdmin) return null;

  return (
    <Section className="tw-basis-[500px] tw-grow" isEditing={isEditing}>
      <SectionHeader
        description="Set annual business goals for your team based on units sold, units listed, units pended, closed volume, gross commission income (GCI)."
        isEditing={isEditing}
        name="teamBusinessGoalsPeriod"
        onSubmit={(e) => {
          submitGoals(businessFormRef, businessGoals)(e);
          submitGoals(businessAgentFormRef, businessAgentGoals)(e);
        }}
        options={PERIOD_OPTIONS}
        period={period}
        setIsEditing={setIsEditing}
        setPeriod={setPeriod}
        title="Team Business Goals"
      />

      <div className="tw-flex flex tw-flex-col tw-relative">
        <div className="tw-flex tw-justify-between tw-flex-wrap tw-gap-[16px]">
          <form ref={businessFormRef} className="tw-flex tw-flex-col tw-grow">
            {businessGoals.goalTypes.map((goalType) => (
              <Goal
                key={goalType.id}
                isEditing={isEditing}
                goalType={goalType}
                goal={businessGoals.goals[goalType.id]}
                onChange={() => {}}
              />
            ))}
          </form>
          <form ref={businessAgentFormRef} className="tw-flex tw-flex-col tw-grow">
            {businessAgentGoals.goalTypes.map((goalType) => (
              <Goal
                key={goalType.id}
                isEditing={isEditing}
                goalType={goalType}
                goal={businessAgentGoals.goals[goalType.id]}
                onChange={() => {}}
              />
            ))}
          </form>
        </div>

        {(businessGoals.loading || businessAgentGoals.loading) && <Loader />}
      </div>
    </Section>
  );
};

TeamBusinessGoals.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TeamBusinessGoals;
