import axios from "axios";

import { getPostJsonHeaders } from "../shared/axiosHelpers";

export const getTemplates = async () => {
  try {
    const response = await axios.get(`/listing_alert_templates.json`);

    return response.data;
  } catch (e) {
    return createError(e);
  }
};

export const createTemplate = async (name, alert) => {
  const data = {
    name: name,
    query: alert,
  };

  return await axios.post(`/listing_alert_templates`, data, getPostJsonHeaders());
};

export const updateTemplate = async (templateId, name, alert) => {
  const data = {
    name: name,
    query: alert,
  };

  return await axios.put(`/listing_alert_templates/${templateId}`, data, getPostJsonHeaders());
};

export const deleteTemplate = async (templateId) => {
  try {
    await axios.delete(`/listing_alert_templates/${templateId}`, getPostJsonHeaders());

    return {};
  } catch (e) {
    return createError(e);
  }
};

const createError = (e) => {
  const error = e.response ? e.response.data.error : "Something went wrong!";

  return { error };
};
