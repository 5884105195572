import React from "react";
import { object, string } from "prop-types";

import { ChevronLeft, ChevronRight } from "../../../shared/v2/Icomoon";
import { listingUrl } from "../../../Transactions/utils/urlHelpers";

const NavigationDirection = ({ schema, slug, title, searchParams, url }) => (
  <a
    href={listingUrl(url, searchParams)}
    className={`tw-cursor-pointer tw-text-theme-primary tw-w-1/3 hover:tw-text-theme-primary hover:tw-no-underline ${
      !slug && "tw-invisible tw-pointer-events-none"
    } ${schema === "next" && "tw-text-right"}`}
  >
    {schema === "prev" && <ChevronLeft size="m" className="tw-mr-4px" />}
    <span className="tw-font-semibold">{title}</span>
    {schema === "next" && <ChevronRight size="m" className="tw-ml-4px" />}
  </a>
);

NavigationDirection.propTypes = {
  schema: string,
  slug: string,
  title: string,
  // eslint-disable-next-line react/forbid-prop-types
  searchParams: object,
  url: string,
};

NavigationDirection.defaultProps = {
  schema: "",
  slug: null,
  title: null,
  searchParams: null,
  url: null,
};

export default NavigationDirection;
