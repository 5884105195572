import React from "react";
import Proptypes from "prop-types";

const LastInteractions = ({ interactionTypeString, mostRecentInteractionTime }) => (
  <>
    {interactionTypeString ? (
      <>
        <h6>{interactionTypeString}</h6>
        <h6>{mostRecentInteractionTime}</h6>
      </>
    ) : (
      <span className="tw-text-gray-50 tw-flex tw-justify-center">—</span>
    )}
  </>
);

LastInteractions.propTypes = {
  interactionTypeString: Proptypes.string,
  mostRecentInteractionTime: Proptypes.string,
};
LastInteractions.defaultProps = {
  interactionTypeString: null,
  mostRecentInteractionTime: null,
};

export default LastInteractions;
