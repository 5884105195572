const getPostJsonHeaders = () => {
  return {
    headers: {
      "X-CSRF-Token": ReactOnRails.authenticityToken(),
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  };
};

export { getPostJsonHeaders };
