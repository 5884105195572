import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

const getColors = (state) =>
  state?.selectProps?.inputValue || state.hasValue ? "#666666" : `${state.isFocused ? "#59C4C4" : "#C2C2C2"}`;

const LocationSearch = ({ onBlur, onChange, onFocus, placeholder, trailing, value, callApi }) => {
  const [inputValue, setInputValue] = useState(value);

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      callApi(inputText).then((resp) => {
        callback(resp);
      });
    }, 500),
    [],
  );
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      onChange={onChange}
      value={value}
      onInputChange={setInputValue} // allows for actually typing
      inputValue={inputValue} // allows you continue where you left off
      blurInputOnSelect
      onBlur={onBlur}
      isClearable
      onFocus={onFocus}
      placeholder={placeholder}
      loadOptions={loadOptions}
      backspaceRemovesValue
      components={{
        IndicatorSeparator: null,
        // eslint-disable-next-line
        DropdownIndicator: ({ clearValue, hasValue, selectProps, ...props }) => (
          <components.DropdownIndicator {...props}>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setInputValue("");
                clearValue();
              }}
              className="tw-border-0 tw-bg-transparent tw-p-0"
            >
              {trailing}
            </button>
          </components.DropdownIndicator>
        ),
      }}
      styles={{
        input: (styles) => ({ ...styles, color: "#666666", padding: "0 20px 0 0", margin: "0" }),
        valueContainer: (styles) => ({ ...styles, padding: "1px 14px 1px 16px" }),
        singleValue: (styles) => ({ ...styles, color: "#666666" }),
        control: (styles, state) => ({
          ...styles,
          background: "#F5F7F7",
          color: "#666666",
          borderRadius: "2px",
          boxShadow: "none",
          borderColor: state.isFocused ? "var(--text-input-border-active)" : "var(--text-input-border)",
          "&:hover": {
            borderColor: "var(--text-input-border-hover)",
          },
          minHeight: "36px",
        }),
        dropdownIndicator: (styles, state) => ({
          ...styles,
          color: getColors(state),
          "&:focus": {
            color: getColors(state),
          },
          padding: "0 12px 0 0",
        }),
        group: (styles) => ({ ...styles, padding: 0, margin: 0 }),
        groupHeading: () => ({
          color: "#666666",
          backgroundColor: "#F5F7F7",
          padding: "6px 12px",
        }),
        menuList: (styles) => ({ ...styles, maxHeight: "200px" }),
        option: (styles, state) => ({
          ...styles,
          background: state.isSelected ? "#59C4C4" : `${state.isFocused ? "#cccccc" : "#fff"}`,
        }),
        placeholder: (styles) => ({ ...styles, whiteSpace: "nowrap", color: "#999999", margin: 0 }),
      }}
    />
  );
};

LocationSearch.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  trailing: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
};

export default LocationSearch;
