import React from "react";
import PropTypes from "prop-types";
import HousePin from "../HousePin";

const SummaryTile = ({ text, color, total, isSearching }) => (
  <div className="tw-bg-white tw-h-[80px] tw-w-[80px] tw-rounded-md tw-items-center tw-mx-2 tw-font-bold tw-text-gray-500 tw-shadow-md">
    <div>
      <div className="tw-flex tw-justify-center tw-mt-6">
        <HousePin color={color} />
        <span className="tw-text-18d tw-ml-2 tw-select-none">
          {isSearching ? <i className="fa fa-spinner fa-spin" /> : total}
        </span>
      </div>
    </div>
    <p className="tw-text-14d tw-text-center tw-pt-4 tw-select-none">{text}</p>
  </div>
);

SummaryTile.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number,
  isSearching: PropTypes.bool,
};

SummaryTile.defaultProps = {
  text: "",
  color: "green",
  total: 0,
  isSearching: false,
};

export default SummaryTile;
