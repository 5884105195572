import React from "react";
import ReactOnRails from "react-on-rails";
import { Button, Row, Table, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import axios from "axios";
import serialize from "form-serialize";
import capitalize from "capitalize";

export default class SupportAccountFeatures extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      featureToggles: props.feature_toggles,
      features: props.features,
      accountError: false,
    };
  }

  FeatureRow = (feature) => {
    const feature_toggle = this.state.featureToggles.filter((ft) => ft.feature_id === feature.id);
    return (
      <tr key={feature.id} style={{ cursor: "pointer" }}>
        <td>{feature_toggle[0] ? feature_toggle[0].id : ""}</td>
        <td>{feature.name.split("_").map(element =>{return capitalize(element)}).join(" ")}</td>
        <td>{feature.desc}</td>
        <td>{feature.category}</td>
        <td>
          <ToggleButtonGroup
            name={feature.id.toString()}
            type="radio"
            defaultValue={feature_toggle[0] ? feature_toggle[0].enabled : 0}
            className="mb-2"
            data-toggle="buttons"
          >
            <ToggleButton value={true}>Enabled</ToggleButton>
            <ToggleButton value={false}>Disabled</ToggleButton>
          </ToggleButtonGroup>
        </td>
      </tr>
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: event });
    const featureUpdatePath = "/support/accounts/" + this.props.account.id + "/update_account_features";
    const formData = serialize(event.target, { hash: true });
    axios
      .post(
        featureUpdatePath,
        { features: formData, authenticity_token: ReactOnRails.authenticityToken() },
        { headers: { "Content-Type": "application/json" } },
      )
      .then((res) => this.featuresUpdateSuccess(res))
      .catch((error) => {
        this.handleError(error.response.data);
      });
  };

  featuresUpdateSuccess = (response) => {
    return this.setState({
      isSubmitting: false,
      featureToggles: response.data.feature_toggles,
      features: response.data.features,
    });
  };

  handleError = (error) => {
    this.setState({ isSubmitting: false, accountError: error });
  };

  accountErrors() {
    return (
      <Row>
        <div className={"alert alert-danger col-xs-12"}>
          {this.state.accountError.errors.map((error) => error + " ")}
        </div>
      </Row>
    );
  }

  render() {
    let displayError;
    if (this.state.accountError) {
      displayError = this.accountErrors();
    }
    return (
      <div>
        {displayError}
        <Row>
          <form onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.isSubmitting ? "disabled" : false}>
              {displayError}
              <Table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Feature Toggle ID</th>
                    <th>Feature</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th className={"col-xs-2"}></th>
                  </tr>
                </thead>
                <tbody>{this.state.features.map((feature) => this.FeatureRow(feature))}</tbody>
              </Table>
              <div className="form-group">
                {this.state.isSubmitting ? (
                  <Button className="modal-submit pull-right" disabled>
                    Saving..
                  </Button>
                ) : (
                  <input type={"submit"} value={"Save"} className="modal-submit pull-right" />
                )}
              </div>
            </fieldset>
          </form>
        </Row>
      </div>
    );
  }
}
