/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowsRotateSolidV6 = (props) => (
  <Icon {...props} name="Arrows Rotate Solid V6">
    <path d="M14.5.5a1 1 0 0 0-1 1v2.315A6.753 6.753 0 0 0 8 1a6.758 6.758 0 0 0-6.451 4.694 1 1 0 0 0 1.903.616 4.76 4.76 0 0 1 4.547-3.309c1.578 0 3.008.767 3.887 2h-1.387a1 1 0 1 0 0 2h4a1 1 0 0 0 1-1v-4.5a1 1 0 0 0-1-1zm-.694 8.55a.999.999 0 0 0-1.259.643A4.762 4.762 0 0 1 8 13a4.766 4.766 0 0 1-3.888-2H5.5a1 1 0 1 0 0-2h-4a1 1 0 0 0-1 1v4.5a1 1 0 1 0 2 0v-2.315A6.74 6.74 0 0 0 7.972 15a6.757 6.757 0 0 0 6.45-4.694c.2-.525-.091-1.116-.616-1.256z" />
  </Icon>
);

export default ArrowsRotateSolidV6;
