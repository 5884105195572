import React from "react";
import PropTypes from "prop-types";
import RadioButton from "../../../../shared/v2/RadioButton";

const RadioOptions = ({ options, value, onChange }) => {
  return (
    <div className="tw-space-x-8">
      {options.map((o) => (
        <RadioButton
          key={o.label}
          label={o.label}
          value={o.value}
          checked={o.value === value}
          onChange={(e) => onChange(o)}
        />
      ))}
    </div>
  );
};

RadioOptions.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

RadioOptions.defaultProps = {
  value: null,
  options: [],
  onChange: null,
};

export default RadioOptions;
