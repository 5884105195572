import React from "react";

import Dropdown, { getAvatarFromDropdownData } from "../../../../../shared/v2/Dropdown";
import { customDefaultProps, customPropTypes } from "../../../../../shared/v2/Dropdown/utils";

const MemberMultiValueLabel = ({ componentProps, data, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.MultiValueLabel {...componentProps}>
    <div className="tw-flex tw-items-center tw-gap-8px">
      {getAvatarFromDropdownData(componentProps, data)}
      {children}
    </div>
  </Dropdown.MultiValueLabel>
);

MemberMultiValueLabel.propTypes = customPropTypes;

MemberMultiValueLabel.defaultProps = customDefaultProps;

export default MemberMultiValueLabel;
