export const INBOUND_RECORDING_OPTIONS = [
  {
    label: "Record Automatically",
    value: "automatic",
    meta: {
      description: "Recording will automatically start when a contact answers the phone.",
    },
  },
  {
    label: "Recording Disabled",
    value: "disabled",
    meta: {
      description: "Recording feature will not be available for any outbound calls.",
    },
  },
];

export const OUTBOUND_RECORDING_OPTIONS = [
  INBOUND_RECORDING_OPTIONS[0],
  {
    label: "Record on Demand",
    value: "on_demand",
    meta: {
      description: "Recording will not start until the caller turns it on.",
    },
  },
  INBOUND_RECORDING_OPTIONS[1],
];

export const ADMIN_INBOUND_RECORDING_OPTIONS = [
  INBOUND_RECORDING_OPTIONS[0],
  {
    label: "Set Default by Brivity Number",
    value: "by_number",
    meta: {
      description: "Set recording defaults for each user with a Brivity number.",
    },
  },
  INBOUND_RECORDING_OPTIONS[1],
];

export const ADMIN_OUTBOUND_RECORDING_OPTIONS = [
  OUTBOUND_RECORDING_OPTIONS[1],
  OUTBOUND_RECORDING_OPTIONS[0],
  ADMIN_INBOUND_RECORDING_OPTIONS[1],
  OUTBOUND_RECORDING_OPTIONS[2],
];
