import React from 'react';
import { calculateCallsTotals, secondsToHMS, truncateTime} from './reportHelpers';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const TableRow = ({children}) => {
  return <div className='user td col-xs-12'>{children}</div>
}

const TableData = (props) => (
  <div style={{background: '#FFF', display: 'inline-block', position: 'relative'}} className={`${props.value === 'Totals' ? 'text-center col-xs-2' : 'text-center col-xs-1'}`}>
    {props.truncateTime ?
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='right'
        overlay={(
          <Tooltip id='time_total' style={{position: 'absolute', left: '0px', top: '0px'}}>
            {props.value}
          </Tooltip>
        )}>
        <span><strong>{truncateTime(props.value)}</strong></span>
      </OverlayTrigger>

      :
      <strong>{props.value}</strong>
    }
  </div>
)

const AgentCallsTotalRow = (props) => {
  const reportData = props.reportData
  return(
    <TableRow>
      <TableData value='Totals' style='col-xs-2'/>
      <TableData value={calculateCallsTotals(reportData, "call_report_agent_total_lead_count")}/>
      <TableData value={calculateCallsTotals(reportData, "call_report_lead_call_count")}/>
      <TableData value={calculateCallsTotals(reportData, "call_report_manual_calls")}/>
      <TableData value={calculateCallsTotals(reportData, "call_report_dialer_calls")}/>
      <TableData value={calculateCallsTotals(reportData, "call_report_total_calls")}/>
      <TableData value={secondsToHMS(calculateCallsTotals(reportData, "call_report_total_dialer_call_time_seconds"))} truncateTime={true} />
    </TableRow>
  )
}

export default AgentCallsTotalRow;
