import React from "react";
import PropTypes from "prop-types";
import Trigger from "./Trigger";
import "./Triggers.scss";
import TriggerForm from "./TriggerForm";
import "./TriggerTable.scss";
import "../../shared/StandardTable.scss";
import { Modal, CancelButton, DeleteButton, Row, Col } from "../../shared/v1";
import TextButton from "../../shared/v2/TextButton/TextButton";

class Triggers extends React.Component {
  isModalOpen() {
    const { isTriggerDeleteModalOpen, isTriggerModalOpen } = this.props;
    return isTriggerDeleteModalOpen || isTriggerModalOpen;
  }

  renderTriggerDeleteModal = () => {
    const {
      isTriggerDeleteModalOpen,
      deleteTrigger,
      closeTriggerDeleteModal,
      isDeletingTrigger,
      triggerToDelete,
      triggersPath,
      getTriggers,
    } = this.props;
    if (!triggerToDelete) {
      return;
    }
    const deletePath = `${triggersPath}/${triggerToDelete.id}`;
    const deleteCallback = () => {
      getTriggers(triggersPath);
    };

    return (
      <Modal show={isTriggerDeleteModalOpen} onHide={closeTriggerDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this trigger?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CancelButton size="small" className="modal-cancel" onClick={closeTriggerDeleteModal} />
          <DeleteButton
            className="tw-float-right"
            disabled={isDeletingTrigger}
            onClick={() => deleteTrigger(triggerToDelete.id, deletePath, deleteCallback)}
          />
        </Modal.Body>
      </Modal>
    );
  };

  renderModal() {
    const { isTriggerDeleteModalOpen, isTriggerModalOpen } = this.props;

    if (isTriggerDeleteModalOpen) {
      return this.renderTriggerDeleteModal(this.props);
    } else if (isTriggerModalOpen) {
      return this.renderTriggerFormModal(this.props);
    } else {
      return null;
    }
  }

  renderTriggerFormModal = () => {
    const {
      updateTriggerValues,
      isTriggerModalOpen,
      triggerForm,
      closeTriggerModal,
      createTrigger,
      updateTrigger,
      triggersPath,
      accountUuid,
      isTriggerSubmitting,
    } = this.props;
    const isEditing = triggerForm && triggerForm.id;
    const title = isEditing ? "Edit" : "New";
    const updateOrCreateTrigger = isEditing ? updateTrigger : createTrigger;
    const afterSubmitCallback = () => {
      this.props.getTriggers(triggersPath);
    };
    const triggerSavePath = isEditing ? `${triggersPath}/${triggerForm.id}` : triggersPath;

    return (
      isTriggerModalOpen && (
        <Modal show={isTriggerModalOpen} onHide={closeTriggerModal}>
          <Modal.Header closeButton>
            <Modal.Title>{title} Auto Plan Trigger</Modal.Title>
          </Modal.Header>
          <Modal.Body className="trigger-modal-body" deprecatedOverridePaddingClasses="tw-pt-15px tw-px-15px">
            <TriggerForm
              triggerForm={triggerForm}
              accountId={accountUuid}
              onSubmit={(path, form) => updateOrCreateTrigger(path, form, afterSubmitCallback)}
              onSubmitPath={triggerSavePath}
              sources={this.props.sources}
              tags={this.props.tags}
              hasError={this.props.hasError}
              errorMessage={this.props.errorMessage}
              autoPlans={this.props.autoPlans}
              autoPlansPath={this.props.autoPlansPath}
              closeAction={closeTriggerModal}
              isSubmitting={isTriggerSubmitting}
              onTriggerChange={updateTriggerValues}
            />
          </Modal.Body>
        </Modal>
      )
    );
  };

  renderEmptyState = () => <div className="trigger-table-empty-row">No auto plan triggers</div>;

  renderLoadingState = () => <div className="table-empty-row">Loading trigger..</div>;

  render() {
    const {
      triggers,
      showTriggerModal,
      activeTrigger,
      autoPlans,
      openTriggerDeleteModal,
      getTriggers,
      triggersPath,
      updateTrigger,
      isFetchingTriggers,
    } = this.props;
    const hasTriggers = triggers?.length > 0;

    return (
      <div className="brivity-panel triggers-wrap">
        <div className="brivity-panel-header">
          People Auto Plan Triggers
          <TextButton schema="sentence" size="small" onClick={() => showTriggerModal(activeTrigger)}>
            Add Trigger
          </TextButton>
        </div>
        <div className="brivity-panel-body">
          <Row>
            <Col size={12}>
              <div className="standard-table trigger-table">
                {!hasTriggers && !isFetchingTriggers && this.renderEmptyState()}
                {!hasTriggers && isFetchingTriggers && this.renderLoadingState()}

                {hasTriggers && (
                  <Row className="table-header-row">
                    <Col size={1}>Intent</Col>
                    <Col size={2}>Source</Col>
                    <Col size={1}>Status</Col>
                    <Col size={1}>Tag</Col>
                    <Col size={4}>People Auto Plans</Col>
                    <Col size={1}>Enrolled</Col>
                    <Col size={1}>Off/On</Col>
                    <Col size={1} />
                  </Row>
                )}
                {triggers &&
                  triggers.map((trigger) => (
                    <Trigger
                      key={trigger.id}
                      autoPlans={autoPlans}
                      onUpdate={updateTrigger}
                      showTriggerModal={showTriggerModal}
                      getTriggers={getTriggers}
                      showDeleteModal={openTriggerDeleteModal}
                      triggersPath={triggersPath}
                      trigger={trigger}
                    />
                  ))}
              </div>
            </Col>
          </Row>
        </div>

        {this.isModalOpen() && this.renderModal()}
      </div>
    );
  }
}
export default Triggers;
