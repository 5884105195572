/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Cancel = (props) => (
  <Icon {...props} name="Cancel">
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm4.197 3.803c2.111 2.111 2.258 5.338.667 7.601L4.596 3.136c2.265-1.592 5.491-1.443 7.601.667zm-8.394 8.394c-2.111-2.111-2.258-5.338-.667-7.601l8.268 8.268c-2.265 1.592-5.491 1.443-7.601-.667z" />
  </Icon>
);

export default Cancel;
