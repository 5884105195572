import React from "react";
import PropTypes from "prop-types";
import Dot from "../BusinessDashboard/Dot";
import colors from "./helpers/colors";

const TooltipDotRow = ({ color, title, value }) => (
  <div className="tw-flex tw-justify-between">
    <span className="tw-flex tw-items-center tw-gap-[8px]">
      {color === "primary" && (
        <Dot className={colors.theme === "place" ? "tw-bg-semantic-blue-100" : "tw-bg-brivity-blue-100"} />
      )}
      {color === "secondary" && (
        <Dot className={colors.theme === "place" ? "tw-bg-semantic-blue-25" : "tw-bg-brivity-blue-40"} />
      )}
      {color === "gray" && <Dot className="tw-bg-neutral-gray-15" />}
      <span>{title}</span>
    </span>
    <span>{value}</span>
  </div>
);

TooltipDotRow.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

TooltipDotRow.defaultProps = {
  color: "primary",
  value: "",
};

export default TooltipDotRow;
