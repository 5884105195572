import React from "react";
import { func, bool } from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";

const RemoveTransactionModal = ({ modalOpen, closeModal, handleRemoveTransaction }) => {
  const handleRemove = () => {
    closeModal();
    handleRemoveTransaction();
  };
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[440px] tw-w-full"
      show={modalOpen}
      onHide={closeModal}
    >
      <Modal.Header
        closeButton
        title="Remove Transaction?"
        description={<span>Are you sure you want to detach this transaction from the loan record?</span>}
      />

      <Modal.Footer className="tw-pt-[32px] tw-flex tw-gap-[12px] tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="medium" schema="warning" type="submit" onClick={handleRemove}>
          Yes, Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveTransactionModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  handleRemoveTransaction: func,
};

RemoveTransactionModal.defaultProps = {
  modalOpen: false,
  closeModal: () => {},
  handleRemoveTransaction: () => {},
};

export default RemoveTransactionModal;
