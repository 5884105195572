import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { getPostJsonHeaders } from "@shared/axiosHelpers";
import { responseDataToCamelCase } from "@shared/v2/caseTransformingAxios";
import CALL_STATES from "../../Dialer/utils/states";
import { tryCatchHandlr } from "@shared/Utilities";

export default ({ dialerServiceUrl, prospects }) => {
  const abortController = useRef(null);
  const consumer = useRef(null);
  const [session, setSession] = useState(null);
  const [conference, setConference] = useState(null);
  const [callState, setCallState] = useState(null);
  const [disconnect, setDisconnect] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return async () => {
      await tryCatchHandlr(implode());
    };
  }, []);

  useEffect(() => {
    const doSomething = async () => {
      if (callState === CALL_STATES.Ended) {
        await endCall();
      } else if (callState === CALL_STATES.Init) {
        await startCall();
      }
    };

    doSomething();
  }, [callState]);

  useEffect(() => {
    if (!session) {
      return () => {};
    }

    const cleanUp = () => {
      consumer.current?.disconnect();
      setSession(null);
      setDisconnect(false);
    };

    if (disconnect) {
      cleanUp();
      return;
    }

    consumer.current = ActionCable.createConsumer(`${dialerServiceUrl}/cable?token=${session.token}`);
    consumer.current.subscriptions.create(
      { channel: "ConferenceChannel", conference_uuid: session.conferenceUuid },
      {
        received(data) {
          handleCallStatusUpdate(responseDataToCamelCase(data.conference));
        },
        connected() {
          this.perform("follow");
        },
      },
    );

    return () => {
      cleanUp();
    };
  }, [session, disconnect]);

  const startCall = async () => {
    try {
      if (session) {
        await tryCatchHandlr(endSession(dialerServiceUrl, session));
      }

      abortController.current = new AbortController();

      const newSession = await createSession(prospects, abortController.current);
      newSession.conferenceUuid = newSession.conference_uuid;
      setIsLoading(true);
      await startSession(dialerServiceUrl, newSession, abortController.current);
      setSession(newSession);
    } catch (e) {
      console.log("Error while starting session/call", e);
      setCallState(CALL_STATES.Ended);
    } finally {
      setIsLoading(false);
      abortController.current = null;
    }
  };

  const redial = async () => {
    // Redialing really means setting up a whole new call session because only 1 prospect was added even if it's the same prospect. Where session is the duration of the agent being on the call.
    await endSession(dialerServiceUrl, session);
    setDisconnect(true);
    setCallState(CALL_STATES.Init);
  };

  const endCall = async () => {
    if (isLoading) {
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
      abortController.current = null;
    }

    if (!session) {
      return;
    }

    try {
      setIsLoading(true);
      await tryCatchHandlr(endCurrentCall(dialerServiceUrl, session));
      await endSession(dialerServiceUrl, session);
    } catch (e) {
      console.log("error while ending call", e);
    } finally {
      setIsLoading(false);
    }
  };

  const logCall = async (outcome, notes) => {
    try {
      await saveOutcome(dialerServiceUrl, session, outcome, notes, conference);

      setDisconnect(true);
    } catch (e) {
      console.log("Error while logging call", e);
    }
  };

  const implode = async () => {
    try {
      if (!session && abortController.current) {
        abortController.current.abort();
        setCallState(CALL_STATES.Ended);
        return;
      }

      await endCurrentCall(dialerServiceUrl, session);
      await endSession(dialerServiceUrl, session);
    } catch (e) {
      console.log("Error while imploding", e);
    } finally {
      setSession(null);
      setConference(null);
    }
  };

  const handleCallStatusUpdate = (conference) => {
    setConference(conference);

    if (conference.currentParticipant?.humanizedCallStatus === "Ringing") {
      setCallState(CALL_STATES.Ringing);
    }

    if (conference.currentParticipant?.humanizedCallStatus === "In-progress") {
      setCallState(CALL_STATES.Connected);
    }

    if (
      conference.totalParticipants === 1 &&
      (conference.currentParticipant?.humanizedCallStatus === "Completed" ||
        conference.agentParticipant?.humanizedCallStatus === "Completed")
    ) {
      setCallState(CALL_STATES.Ended);
    }

    if (
      conference.agentParticipant?.humanizedCallStatus === "Canceled" ||
      conference.currentParticipant?.humanizedCallStatus === "Canceled"
    ) {
      setCallState(CALL_STATES.Ended);
    }

    if (conference.currentParticipant?.humanizedCallStatus === "Failed") {
      setError("Call to potential owner failed.");
      setCallState(CALL_STATES.Ended);
    }
  };

  return {
    startCall: () => setCallState(CALL_STATES.Init),
    redial,
    endCall: () => setCallState(CALL_STATES.Ended),
    logCall,
    implode,
    callState,
    error,
    isLoading,
  };
};

const createSession = async (prospects, abortController) => {
  const formatted = prospects?.map((p) => ({
    phone_number: p.phone,
    full_name: p.name,
  }));

  const options = {
    ...getPostJsonHeaders(),
    signal: abortController?.signal,
  };

  const { data } = await axios.post("/dialer/create_dialer_session", { prospects: formatted }, options);

  return data;
};

const startSession = async (dialerServiceUrl, session, abortController) => {
  if (!session || !session.token) {
    return;
  }

  await axios.post(
    `${dialerServiceUrl}/dialer/start_dialer_session`,
    { token: session.token },
    { signal: abortController?.signal },
  );
};

const saveOutcome = async (dialerServiceUrl, session, outcome, notes, conference) => {
  if (!session || !session.token) {
    return;
  }

  const data = {
    outcome,
    comment: notes,
    is_prospect_call: true,
    participant_id: conference?.currentParticipant.id,
    token: session.token,
  };

  await axios.post(`${dialerServiceUrl}/dialer/save_outcome`, data);
};

const endCurrentCall = async (dialerServiceUrl, session) => {
  if (!session || !session.token) {
    return;
  }

  // This seems to be able to fail at a time between the session starting and the actual call_sid being recorded in the dialer service
  await axios.post(`${dialerServiceUrl}/dialer/end_current_call`, { token: session.token });
};

const endSession = async (dialerServiceUrl, session) => {
  if (!session || !session.token) {
    return;
  }

  await axios.post(`${dialerServiceUrl}/dialer/end_dialer_session`, { token: session.token });
};
