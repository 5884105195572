import { getExternalApi } from "@shared/externalApi";
import { useEffect, useState } from "react";
import { useWebsiteApiUrl } from "../../reducers/websiteReducer/selectors";

const usePostCountForCategoryTag = ({ type, uuid }) => {
  const websiteApiUrl = useWebsiteApiUrl();
  const axios = getExternalApi(websiteApiUrl);
  const [count, setCount] = useState("");

  useEffect(() => {
    if (uuid) {
      axios
        .get(`/Post/CountFor${type === "tag" ? "Tag" : "Category"}/${uuid}`)
        .then((res) => setCount(res.data || 0))
        .catch(() => setCount(0));
    }
  }, [type, uuid]);

  return count;
};

export default usePostCountForCategoryTag;
