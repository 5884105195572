import React, { useState, useRef, useEffect } from "react";
import Proptypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Col, Row } from "../../../../shared/v1";
import ListingLink from "./ListingLink";
import Share from "./Share";
import ProfileIcon from "../ProfileIcon";
import SiteInput from "./SiteInput";
import { useClickOutside } from "../../../../shared/hookHelpers";
import LAYOUT_STYLES from "../actions/hooks";

const { ROW_FONT, FONT_STYLE } = LAYOUT_STYLES;

const Rows = ({ adsite, isLoading, planId }) => {
  const [hovered, toggleHovered] = useState(false);
  const [siteinput, setSiteinput] = useState(false);
  const [siteEdit, setEdit] = useState(false);
  const [adlink, setlink] = useState(null);
  const ref = useRef(null);

  useClickOutside(ref, () => setSiteinput(false));

  useEffect(() => {
    if (isLoading) {
      setSiteinput(false);
    }
  }, [isLoading]);

  const onSiteInputOpen = () => {
    setSiteinput(true);
  };
  const onSiteInputClose = () => {
    setSiteinput(false);
  };

  return (
    <>
      <Row
        onMouseEnter={() => toggleHovered(true)}
        onMouseLeave={() => toggleHovered(false)}
        className={`${FONT_STYLE} tw-flex tw-p-0 tw-m-0 tw-mx-[0px] tw-items-center tw-h-[40px] hover:tw-bg-[#FAFAFA]`}
      >
        <Col size={4} className={`${ROW_FONT}${siteinput ? " !tw-w-fit !tw-pl-2px tw-ml-8px" : ""}`}>
          {adsite.name}
        </Col>
        <Col size={3} className={`${ROW_FONT}${siteinput ? " tw-text-left" : " tw-text-right"}`}>
          <ListingLink
            googleSearchUrl={adsite.google_search_url}
            siteinput={siteinput}
            hovered={hovered}
            shared={adsite.complete}
            links={adsite.url}
            onSiteInputOpen={onSiteInputOpen}
            setEdit={setEdit}
            setlink={setlink}
          />
        </Col>
        {!siteinput && (
          <Col size={1} className={`${ROW_FONT} tw-text-center`}>
            <ProfileIcon
              imglink={adsite?.completed_by?.avatar}
              name={`${adsite?.completed_by?.first_name || ""} ${
                adsite?.completed_by?.last_name || ""
              }`.trim()}
              initials={adsite?.completed_by?.initials}
            />
          </Col>
        )}
        <Col
          size={2}
          className={`${ROW_FONT} tw-text-center tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-75`}
        >
          {adsite.completed_at ? moment(adsite.completed_at).format("MM/DD/YYYY") : null}
        </Col>
        {!siteinput && (
          <Col size={2} className={`${ROW_FONT} tw-text-right`}>
            <Share
              openremovebtn={siteinput}
              planId={planId}
              siteId={adsite.id}
              links={adsite.url}
              shared={adsite.complete}
            />
          </Col>
        )}
      </Row>
      {siteinput && (
        <span ref={ref}>
          <SiteInput
            shared={adsite.complete}
            planId={planId}
            siteId={adsite.id}
            currentref={ref}
            onClose={onSiteInputClose}
            isedit={siteEdit}
            link={adlink}
          />
        </span>
      )}
    </>
  );
};

Rows.propTypes = {
  adsite: Proptypes.shape({
    id: Proptypes.string,
    name: Proptypes.string,
    complete: Proptypes.bool,
    google_search_url: Proptypes.string,
    completed_by: Proptypes.shape({
      id: Proptypes.string,
      first_name: Proptypes.string,
      last_name: Proptypes.string,
      avatar: Proptypes.string,
      initials: Proptypes.string,
    }),
    completed_at: Proptypes.string,
    position: Proptypes.number,
    url: Proptypes.string,
    advertising_plan_id: Proptypes.string,
    externally_visible: Proptypes.bool,
  }),
  isLoading: Proptypes.bool,
  planId: Proptypes.number,
};
Rows.defaultProps = {
  adsite: null,
  isLoading: false,
  planId: null,
};

const mapStateToProps = (state) => ({
  isLoading: state.tdpMarketingReducer.isLoading,
});

export default connect(mapStateToProps)(Rows);
