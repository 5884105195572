import React, { useMemo } from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, shape } from "prop-types";

import ModalUpload from "@shared/ModalUpload";

import {
  setDocumentIndex,
  toggleCDAUploadModal,
  toggleDeleteModal,
  toggleDocumentsSelected,
} from "../../Main/Docs/actions/creators";
import { uploadDocumentAsThunk, renameDocumentAsThunk } from "../../Main/Docs/actions/thunks";

const CDAUploadModal = ({
  isCDAUploadModalOpen,
  onHideHandlr,
  onUploadDocumentAsThunk,
  onToggleDocumentsSelected,
  onToggleDeleteModal,
  onRenameDocumentAsThunk,
  onPreviewDocument,
  uploadedDocuments,
}) => {
  const context = "documents-upload";

  const shouldShowAllCheckbox = useMemo(
    () => uploadedDocuments.some((doc) => !doc.isUploading),
    [uploadedDocuments],
  );

  const areAllDocumentsSelected = useMemo(
    () => uploadedDocuments.filter((doc) => !doc.isUploading).every((doc) => doc.isSelected),
    [uploadedDocuments],
  );

  const handleSelectAllDocuments = (e) => {
    const documentIds = uploadedDocuments.filter((doc) => !doc.isUploading).map((doc) => doc.id);

    onToggleDocumentsSelected(context, documentIds, e.target.checked);
  };

  const shouldShowBulkActions = useMemo(
    () => uploadedDocuments.some((doc) => doc.isSelected),
    [uploadedDocuments],
  );

  const handleBulkDelete = () => {
    const documentIds = uploadedDocuments.filter((doc) => doc.isSelected).map((doc) => doc.id);
    onToggleDeleteModal(documentIds, true);
  };

  const handleDeleteDocuments = (documentIds) => {
    onToggleDeleteModal(documentIds, true);
  };

  const handleRenameDocument = (documentId, newName) => {
    onRenameDocumentAsThunk(context, documentId, newName);
  };

  const handlePreview = (index) => {
    onPreviewDocument(context, index);
  };

  return (
    <ModalUpload
      isModalOpen={isCDAUploadModalOpen}
      onHideHandlr={onHideHandlr}
      shouldShowAllCheckbox={shouldShowAllCheckbox}
      handleSelectAllDocuments={handleSelectAllDocuments}
      areAllDocumentsSelected={areAllDocumentsSelected}
      shouldShowBulkActions={shouldShowBulkActions}
      handleDeleteDocuments={handleDeleteDocuments}
      handleBulkDelete={handleBulkDelete}
      handleRenameDocument={handleRenameDocument}
      handlePreview={handlePreview}
      uploadedDocuments={uploadedDocuments}
      context={context}
      dispatchedThunkCb={(acceptedFiles) => {
        acceptedFiles.forEach((file, i) => {
          const data = {
            uploadKey: `cda-upload-${i}`,
            fileName: file.name,
            isUploading: true,
            uploadErrors: [],
            renameErrors: [],
          };
          const formData = new FormData();
          formData.append("upload", file);
          formData.append("cda_form", true);
          onUploadDocumentAsThunk(data, formData, true);
        });
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isCDAUploadModalOpen: state.tdpDocumentsReducer.isCDAUploadModalOpen,
  uploadedDocuments: state.tdpDocumentsReducer.uploadedDocuments,
});

const mapDispatchToProps = (dispatch) => ({
  onHideHandlr: () => dispatch(toggleCDAUploadModal(false)),
  onUploadDocumentAsThunk: (data, formData, isFinancialsCDA) =>
    dispatch(uploadDocumentAsThunk(data, formData, isFinancialsCDA)),
  onToggleDocumentsSelected: (context, documentIds, isSelected) =>
    dispatch(toggleDocumentsSelected(context, documentIds, isSelected)),
  onToggleDeleteModal: (documentIds, isCDA) => dispatch(toggleDeleteModal(documentIds, isCDA)),
  onRenameDocumentAsThunk: (context, documentId, newName) =>
    dispatch(renameDocumentAsThunk(context, documentId, newName)),
  onPreviewDocument: (context, index) => dispatch(setDocumentIndex(context, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CDAUploadModal);

CDAUploadModal.propTypes = {
  isCDAUploadModalOpen: bool.isRequired,
  onHideHandlr: func.isRequired,
  onUploadDocumentAsThunk: func.isRequired,
  onToggleDocumentsSelected: func.isRequired,
  onToggleDeleteModal: func.isRequired,
  onRenameDocumentAsThunk: func.isRequired,
  onPreviewDocument: func.isRequired,
  uploadedDocuments: arrayOf(shape({})),
};

CDAUploadModal.defaultProps = {
  uploadedDocuments: [],
};
