import { sortBy } from "lodash";
import format from "../../../shared/formatters";

const formatValToDecimal = (val) => (Math.floor(val * 100) / 100).toFixed(2);

const formatValue = (value, isPercent) => {
  if (!!value) {
    if (!!isPercent) {
      return `${value}%`;
    } else {
      value = format.apiNumber(value, 2, false);
      var str = value.toString().split(".");
      if (str[0].length >= 4) {
        str[0] = str[0].toLocaleString();
      }
      return "$" + str.join(".");
    }
  } else {
    return "";
  }
};

const formatSubtotal = (val) => (val * 1 === 0 ? 0 : `$${formatValToDecimal(val)}`);

const formatExpense = (expenseAmount) => {
  if (!!expenseAmount) {
    return "(-" + expenseAmount + ")";
  } else {
    return "";
  }
};

const formatCompanyExpense = (expenseAmount, name) => {
  if (!!expenseAmount) {
    return `${name.includes("Income") ? "(+" : "(-"}${expenseAmount})`;
  } else {
    return "";
  }
};

const createSortedExpensesPointer = (pseudoIncome) =>
  sortBy(
    [
      pseudoIncome,
      ...pseudoIncome.custom_team_member_expenses_attributes,
      ...pseudoIncome.custom_team_member_revenues_attributes,
    ],
    "position",
  );

const createFinancialsSortedExpensesPointer = (pseudoIncome) => {
  const expensesKey = pseudoIncome.hasOwnProperty("custom_team_member_expenses_attributes")
    ? "custom_team_member_expenses_attributes"
    : "custom_agent_expenses_attributes";

  if (!pseudoIncome[expensesKey].length) return [pseudoIncome];

  const expPointer = [pseudoIncome].concat(pseudoIncome[expensesKey]);
  expPointer.sort((exp1, exp2) => exp1.position - exp2.position);
  return expPointer;
};

const reAssignExpensePositions = (income) => {
  const { position: splitPosition } = income;
  const expensesKey = income.hasOwnProperty("custom_team_member_expenses_attributes")
    ? "custom_team_member_expenses_attributes"
    : "custom_agent_expenses_attributes";
  let tracker = 1;

  income[expensesKey].forEach((exp) => {
    if (exp._destroy) return;

    if (splitPosition === tracker) {
      exp.position = tracker + 1;
      tracker = tracker + 2;
      return;
    }

    exp.position = tracker;
    tracker = tracker + 1;
  });
};

const reconcileGCI = ({ pseudoListing, incomes, gci, isCompany, company_income }) => {
  if (isCompany) {
    const agentsGCI = format.apiNumber(company_income.gross_income, 2, false);

    if (agentsGCI > gci)
      pseudoListing.company_income.gross_income = pseudoListing.company_income.gross_income - 0.01;
  } else {
    const agentsGCI = format.apiNumber(
      incomes.reduce((p, c) => p + c.gross_income, 0),
      2,
      false,
    );

    if (agentsGCI > gci)
      pseudoListing.team_member_incomes.incomes[incomes.length - 1].gross_income =
        pseudoListing.team_member_incomes.incomes[incomes.length - 1].gross_income - 0.01;
  }
};

//TODO: This needs refactoring, discussion around this? maybe abstract this into redux after action call
const reCalculateAgentExpenses = (income, listingData, parentIndex, setFinancial, isCompany) => {
  const pseudoIncome = { ...income };
  const { agent_gci_percentage, agent_gci } = pseudoIncome;
  const pseudoListing = { ...listingData };
  const {
    transaction_income: { gci },
    team_member_incomes: { incomes },
    company_income,
  } = listingData;

  let subtotalTracker = agent_gci_percentage
    ? format.apiNumber(agent_gci * 0.01 * gci, 2, false)
    : agent_gci * 1;
  pseudoIncome.gross_income = format.apiNumber(subtotalTracker, 2, false);

  const expPointer = createSortedExpensesPointer(pseudoIncome);

  expPointer.forEach((exp) => {
    if (exp === undefined) return;
    if (exp._destroy) return;
    if (exp.hasOwnProperty("agent_gci")) {
      if (exp.brokerage_split_percentage) {
        exp.brokerage_split_amount = format.apiNumber(exp.brokerage_split * subtotalTracker * 0.01, 2, false);
        exp.brokerage_split_line_total = (subtotalTracker - exp.brokerage_split_amount).toFixed(2) * 1;
        subtotalTracker = exp.brokerage_split_line_total;
        pseudoIncome.net_income = subtotalTracker;
      } else {
        exp.brokerage_split_amount = exp.brokerage_split * 1;
        exp.brokerage_split_line_total = subtotalTracker - exp.brokerage_split;
        subtotalTracker = exp.brokerage_split_line_total;
        pseudoIncome.net_income = subtotalTracker;
      }

      if (exp.royalty_percentage) {
        exp.royalty_amount = format.apiNumber(
          (exp.brokerage_split_line_total + exp.brokerage_split_amount) * exp.royalty * 0.01,
          2,
          false,
        );
        exp.royalty_line_total = (subtotalTracker - exp.royalty_amount).toFixed(2) * 1;
        subtotalTracker = exp.royalty_line_total;
        pseudoIncome.net_income = subtotalTracker;
      } else {
        exp.royalty_amount = exp.royalty * 1;
        exp.royalty_line_total = subtotalTracker - exp.royalty;
        subtotalTracker = exp.royalty_line_total;
        pseudoIncome.net_income = subtotalTracker;
      }
      return;
    }

    const financialType = exp.hasOwnProperty(isCompany ? "company_expense_type_id" : "agent_expense_type_id")
        ? "expense_amount"
        : "revenue_amount";

    if (exp.percentage) {
      if (exp.position < pseudoIncome.position) {
        exp[financialType] = format.apiNumber(pseudoIncome.gross_income * exp.value * 0.01, 2, false);
      } else {
        exp[financialType] = format.apiNumber(pseudoIncome.royalty_line_total * exp.value * 0.01, 2, false);
      }
    } else {
      exp[financialType] = exp.value;
    }

    const multiplier = () =>
      financialType === "revenue_amount" ? exp[financialType] * 1 : exp[financialType] * -1;

    exp.line_total = format.apiNumber(subtotalTracker + multiplier(), 2, false);

    subtotalTracker = exp.line_total;
    pseudoIncome.net_income = format.apiNumber(subtotalTracker, 2, false);
  });

  if (isCompany) {
    pseudoListing.company_income = { ...pseudoIncome };
  } else {
    pseudoListing.team_member_incomes.incomes[parentIndex] = { ...pseudoIncome };
  }

  reconcileGCI({ pseudoListing, incomes, gci, isCompany, company_income });
  setFinancial(pseudoListing);
};

const calculateGrossIncome = (listingData) =>
  (listingData.transaction_income.gross_income = listingData.transaction_income.commission_percentage
    ? format.apiNumber(
        listingData.transaction_income.closed_volume * (listingData.transaction_income.commission / 100),
        2,
        false,
      )
    : listingData.transaction_income.commission);

const calculateGCI = (listingData) => {
  const pseudoListing = { ...listingData };
  let {
    transaction_income: { gross_income: gci },
  } = pseudoListing;

  pseudoListing.transaction_income.additionalIncome.forEach((exp) => {
    if (exp._destroy) return;
    let { value, type } = exp;

    if (type === "expense") {
      exp.expense_amount = exp.percentage ? format.apiNumber(gci * 0.01 * value, 2, false) : value * 1;
      gci = format.apiNumber(gci - exp.expense_amount, 2, false);
    } else {
      exp.revenue_amount = exp.percentage ? format.apiNumber(gci * 0.01 * value, 2, false) : value * 1;
      gci = format.apiNumber(gci + exp.revenue_amount, 2, false);
    }

    exp.line_total = gci;
  });

  pseudoListing.transaction_income.gci = gci;
  listingData = pseudoListing;
};

const fullRecalculation = ({ ...listingData }, setFinancial) => {
  calculateGrossIncome(listingData);
  calculateGCI(listingData);

  if (!listingData.team_member_incomes.incomes.length && !listingData.company_income) {
    setFinancial(listingData);
    return;
  }
  if (listingData.team_member_incomes.incomes.length) {
    listingData.team_member_incomes.incomes.forEach((income, i) => {
      reCalculateAgentExpenses(income, listingData, i, setFinancial);
    });
  }
  if (listingData.company_income) {
    reCalculateAgentExpenses(listingData.company_income, listingData, false, setFinancial, true);
  }
};

const moveExpense = (expenses, oldIndex, newIndex) => {
  const [expense] = expenses.splice(oldIndex, 1);
  expenses.splice(newIndex, 0, expense);
};

const shiftSplitPosition = (income, deletedPosition) =>
  income.position > deletedPosition && --income.position;

const reorderExpenses = (expPointer, oldIndex, newIndex) => {
  moveExpense(expPointer, oldIndex, newIndex);
  let tracker = 1;
  expPointer.forEach((exp) => {
    exp.position = tracker;
    tracker++;
  });
};

const moveDeletedExpenseToEnd = (expenses) => expenses.sort((exp1, exp2) => exp2.position - exp1.position);

export {
  moveDeletedExpenseToEnd,
  formatValue,
  formatExpense,
  formatCompanyExpense,
  reCalculateAgentExpenses,
  reorderExpenses,
  createSortedExpensesPointer,
  createFinancialsSortedExpensesPointer,
  reAssignExpensePositions,
  shiftSplitPosition,
  formatSubtotal,
  fullRecalculation,
};
