/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BriefcaseSolidV6 = (props) => (
  <Icon {...props} name="Briefcase Solid V6">
    <path d="M10 10.5a.5.5 0 0 1-.5.5h-3a.501.501 0 0 1-.5-.5V9H0v4.5c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5V9h-6v1.5zM14.5 3H12V1.5c0-.8-.7-1.5-1.5-1.5h-5C4.7 0 4 .7 4 1.5V3H1.5C.7 3 0 3.7 0 4.5V8h16V4.5c0-.8-.7-1.5-1.5-1.5zm-4 0h-5V1.5h5V3z" />
  </Icon>
);

export default BriefcaseSolidV6;
