/* eslint-disable react/prop-types */

import React from "react";
import InlineFilter from "./shared/Filters/InlineFilter";

const FilterInlineOptions = ({
  initialTypes,
  initialPublishedStates,
  initialStatuses,
  onTypesChange,
  onPublishedStatesChange,
  onStatusesChange,
}) => (
  <>
    <InlineFilter title="Type" initialOptions={initialTypes} onOptionsChange={onTypesChange} />
    <InlineFilter title="Status" initialOptions={initialStatuses} onOptionsChange={onStatusesChange} />
    <InlineFilter
      title="Publish State"
      initialOptions={initialPublishedStates}
      onOptionsChange={onPublishedStatesChange}
    />
  </>
);

export default FilterInlineOptions;
