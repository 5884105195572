import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers";

const configureStore = (preloadedState) =>
  reduxConfigureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
    preloadedState,
    reducer: rootReducer,
  });

export default configureStore;
