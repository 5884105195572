/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import RadioButton from "@shared/v2/RadioButton";
import { FormGroup } from "../../shared/v1";

const AutoPauseOnCondition = ({ checkedValue, handleUpdate, ...rest }) => (
  <FormGroup> 
    <RadioButton
      value='true'
      checked={checkedValue === true || checkedValue === "true"}
      onClick={(e) => handleUpdate(e.target.name, e.target.value)}
      labelClassName="tw-text-gray-base tw-text-[14px]"
      containerClassName='tw-mr-[12px]'
      label='Yes'
      {...rest}
    />
    <RadioButton
      value='false'
      checked={checkedValue === false || checkedValue === "false"}
      onClick={(e) => handleUpdate(e.target.name, e.target.value)}
      labelClassName="tw-text-gray-base tw-text-[14px]"
      label='No'
      {...rest}
    />
  </FormGroup>
);

export default AutoPauseOnCondition;

AutoPauseOnCondition.propTypes = {
  checkedValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};
