export const getStatusValues = (status) => {
  switch (status) {
    case 1:
    case "Active":
      return { color: "tw-text-semantic-green-100", backgroundColor: "tw-bg-semantic-green-100", text: "Active" };
    case 2:
    case "Pending":
      return { color: "tw-text-semantic-yellow-100", backgroundColor: "tw-bg-semantic-yellow-100", text: "Pending" };
    case 3:
    case "Active Under Contract":
    case "Active With Contingency":
      return { color: "tw-text-semantic-orange-100", backgroundColor: "tw-bg-semantic-orange-100", text: "Contingent" };
    case 4:
    case "Sold":
    case "Closed":
      return { color: "tw-text-semantic-blue-110", backgroundColor: "tw-bg-brand-success", text: "Sold" };
    default:
      return { color: "tw-text-gray-99", backgroundColor: "tw-bg-gray-99", text: "Off Market" };
  }
};
