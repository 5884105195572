import React from "react";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import EditListingBody from "./EditListingBody";

const EditListing = ({ modalOpen, closeModal }) => (
  <Modal
    show={modalOpen}
    onHide={closeModal}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[600px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Edit Listing URLs" closeButton />
    <Modal.Body>
      <EditListingBody closeModal={closeModal} />
    </Modal.Body>
  </Modal>
);

EditListing.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
};
EditListing.defaultProps = {
  modalOpen: false,
  closeModal: null,
};
export default EditListing;
