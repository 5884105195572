/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Message = (props) => (
  <Icon {...props} name="Message">
    <path d="M8 1.412C3.841 1.412.471 4.15.471 7.53c0 1.459.629 2.794 1.676 3.844C1.779 12.856.55 14.177.535 14.192c-.065.068-.082.168-.044.256s.121.141.215.141c1.95 0 3.412-.935 4.135-1.512A8.964 8.964 0 0 0 8 13.648c4.159 0 7.529-2.738 7.529-6.118S12.158 1.412 8 1.412zM4.235 8.471a.94.94 0 1 1 0-1.882.94.94 0 1 1 0 1.882zm3.765 0a.94.94 0 1 1 0-1.882.94.94 0 1 1 0 1.882zm3.765 0a.94.94 0 1 1 0-1.882.94.94 0 1 1 0 1.882z" />
  </Icon>
);

export default Message;
