/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CalendarCheckRegularV6 = (props) => (
  <Icon {...props} name="Calendar Check Regular V6">
    <path d="M7.753 12.753c-.266.319-.741.319-1.034 0l-2-2c-.291-.266-.291-.741 0-1.034.294-.291.769-.291 1.034 0l1.497 1.472 2.969-2.972c.294-.291.769-.291 1.034 0 .319.294.319.769 0 1.034l-3.5 3.5zM5 0c.416 0 .75.336.75.75V2h4.5V.75a.749.749 0 1 1 1.5 0V2H13a2 2 0 0 1 2 2v10c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1.25V.75C4.25.336 4.584 0 5 0zm8.5 6h-11v8a.5.5 0 0 0 .5.5h10c.275 0 .5-.225.5-.5V6z" />
  </Icon>
);

export default CalendarCheckRegularV6;
