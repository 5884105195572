import React from "react";
import Proptypes from "prop-types";

const Address = ({ streetAddress, cityState }) => (
  <>
    {streetAddress && <h6 className="top-row">{streetAddress}</h6>}
    {cityState && <h6 className="top-row">{cityState}</h6>}
    {!streetAddress && !cityState && (
      <span className="tw-text-gray-50 tw-ml-[30px] tw-flex tw-justify-left">—</span>
    )}
  </>
);

Address.propTypes = {
  streetAddress: Proptypes.string,
  cityState: Proptypes.string,
};
Address.defaultProps = {
  streetAddress: null,
  cityState: null,
};

export default Address;
