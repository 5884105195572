import { startCase, camelCase } from "lodash";
import { RECURRING_WEEKDAYS } from "../constants";

export const contingentText = (contingent, dueOn) => {
  const { days, position, event } = contingent || {};

  return contingent
    ? `${days} day${days > 1 ? "s" : ""} ${position} ${startCase(
        camelCase(event.substring(10).replace("_", " ")),
      )}`
    : dueOn;
};

export const recurringText = (rf, rd, rn) => {
  if (!rf) return "None";

  if (rf === "weekly") return `Weekly (${RECURRING_WEEKDAYS[Number(rd) - 1]})`;

  const isEveryDay = Number(rn) === 1;
  if (rf === "number") return `Every${!isEveryDay ? ` ${rn} ` : " "}Day${!isEveryDay ? "s" : ""}`;
  return null;
};
