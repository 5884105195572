import React from "react";
import { useSelector } from "react-redux";
import { bool, func } from "prop-types";

import { isPlace } from "./helpers";

import IconButton from "../../../shared/v2/IconButton";
import { Grid2RegularV6 } from "../../../shared/v2/Icomoon";

const ResourceBtn = ({ open, setOpen }) => {
  const theme = useSelector(({ layout }) => layout.theme.name);

  return (
    <IconButton
      className={`${isPlace(theme) ? "tw-text-white" : ""}  hover:tw-bg-gray/20 active:tw-bg-gray/30`}
      id="resourceIcon"
      aria-haspopup="true"
      aria-expanded={open}
      onClick={setOpen}
      schema="transparent"
    >
      <Grid2RegularV6 size="xxl" />
    </IconButton>
  );
};

export default ResourceBtn;

ResourceBtn.propTypes = {
  open: bool,
  setOpen: func,
};

ResourceBtn.defaultProps = {
  open: false,
  setOpen: () => {},
};
