import React from "react";
import { Col, Row, FormGroup } from "../../../../shared/v1";
import { formatValue } from "../../shared/FinancialViewHelpers";
import { kebabCase } from "lodash";
import format from "../../../../shared/formatters";

export const Split = (props) => {
  const { agentData } = props;
  const { brokerage_split_percentage, royalty_percentage } = agentData;

  return (
    <Row>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Brokerage Split</label>
          <span data-cy={`financial-brokerage-split-parent-${kebabCase(agentData.name)}`}>
            {brokerage_split_percentage
              ? formatValue(agentData.brokerage_split, agentData.brokerage_split_percentage)
              : format.currency(agentData.brokerage_split)}
          </span>
        </FormGroup>
      </Col>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Brokerage Cap</label>
          <span data-cy={`financial-brokerage-cap-parent-${kebabCase(agentData.name)}`}>
            {format.currency(agentData.brokerage_cap)}
          </span>
        </FormGroup>
      </Col>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Brokerage Cap Reset Date</label>
          <span>
            {agentData.brokerage_cap_reset_month || ""}-{agentData.brokerage_cap_reset_day || ""}
          </span>
        </FormGroup>
      </Col>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Royalty</label>
          <span data-cy={`financial-royalty-parent-${kebabCase(agentData.name)}`}>
            {royalty_percentage
              ? formatValue(agentData.royalty, agentData.royalty_percentage)
              : format.currency(agentData.royalty)}
          </span>
        </FormGroup>
      </Col>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Royalty Cap</label>
          <span data-cy={`financial-royalty-cap-parent-${kebabCase(agentData.name)}`}>
            {format.currency(agentData.royalty_cap)}
          </span>
        </FormGroup>
      </Col>
      <Col size={2}>
        <FormGroup className="tw-min-h-[50px]">
          <label className="tw-block tw-mb-[5px]">Royalty Cap Reset Date</label>
          <span>
            {agentData.royalty_cap_reset_month || ""}-{agentData.royalty_cap_reset_day || ""}
          </span>
        </FormGroup>
      </Col>
    </Row>
  );
};
