import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { caseTransformingAxios } from "../shared/v2/caseTransformingAxios";

const getEmailPlacholders = createAsyncThunk(
  "interactionTemplates/getEmailPlaceholders",
  async (_, thunkApi) => {
    const response = await caseTransformingAxios.get("/api/v4/interaction_templates/email_placeholders", {
      signal: thunkApi.signal,
    });
    return response.data;
  },
);

const getInteractionTemplates = createAsyncThunk(
  "interactionTemplates/getInteractionTemplates",
  async ({ params }, thunkApi) => {
    const response = await caseTransformingAxios.get("/api/v4/interaction_templates", {
      params,
      signal: thunkApi.signal,
    });
    return response.data;
  },
);

const createInteractionTemplate = createAsyncThunk(
  "interactionTemplates/createInteractionTemplate",
  async ({ template: { name, subject, body } = {} }, thunkApi) => {
    try {
      const response = await caseTransformingAxios.post(
        "/api/v4/interaction_templates",
        { name, subject, body },
        {
          signal: thunkApi.signal,
        },
      );
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response?.data);
    }
  },
);

const updateInteractionTemplate = createAsyncThunk(
  "interactionTemplates/updateInteractionTemplate",
  async ({ template: { id, name, subject, body } = {} }, thunkApi) => {
    try {
      const response = await caseTransformingAxios.put(
        `/api/v4/interaction_templates/${id}`,
        { name, subject, body },
        {
          signal: thunkApi.signal,
        },
      );
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err.response?.data);
    }
  },
);

const deleteInteractionTemplate = createAsyncThunk(
  "interactionTemplates/deleteInteractionTemplate",
  async ({ template: { id } = {}, archive }, thunkApi) => {
    const response = await caseTransformingAxios.delete(
      `/api/v4/interaction_templates/${id}${archive ? "/archive" : ""}`,
      {
        signal: thunkApi.signal,
      },
    );
    return response.data;
  },
);

export const initialState = {
  deleteTemplate: null,
  editTemplate: null,
  emailPlaceholders: { loading: false, error: null, data: [] },
  interactionTemplates: { loading: false, errors: null, data: [], pagination: {} },
};

export const interactionTemplatesSlice = createSlice({
  name: "interactionTemplates",
  initialState,
  reducers: {
    setDeleteTemplate: (state, action) => {
      state.deleteTemplate = action.payload;
    },
    setEditTemplate: (state, action) => {
      state.editTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmailPlacholders.pending, (state) => {
      state.emailPlaceholders.loading = true;
      state.emailPlaceholders.error = null;
    });
    builder.addCase(getEmailPlacholders.fulfilled, (state, action) => {
      state.emailPlaceholders.loading = false;
      state.emailPlaceholders.data = action.payload.emailPlaceholders;
    });
    builder.addCase(getEmailPlacholders.rejected, (state, action) => {
      state.emailPlaceholders.loading = false;
      state.emailPlaceholders.error = true;
    });

    builder.addCase(getInteractionTemplates.pending, (state) => {
      state.interactionTemplates.loading = true;
      state.interactionTemplates.errors = null;
    });
    builder.addCase(getInteractionTemplates.fulfilled, (state, action) => {
      state.interactionTemplates.loading = false;
      state.interactionTemplates.data = action.payload.interactionTemplates;
      state.interactionTemplates.pagination = action.payload.pagination;
    });
    builder.addCase(getInteractionTemplates.rejected, (state, action) => {
      state.interactionTemplates.loading = false;
      state.interactionTemplates.errors = ["failed to load templates"];
    });

    builder.addCase(createInteractionTemplate.pending, (state) => {
      state.interactionTemplates.loading = true;
      state.interactionTemplates.errors = null;
    });
    builder.addCase(createInteractionTemplate.fulfilled, (state, action) => {
      state.editTemplate = null;
      state.interactionTemplates.loading = false;
      state.interactionTemplates.data = [
        ...state.interactionTemplates.data,
        action.payload.interactionTemplate,
      ];
    });
    builder.addCase(createInteractionTemplate.rejected, (state, action) => {
      state.interactionTemplates.loading = false;
      state.interactionTemplates.errors = action.payload?.errors;
    });

    builder.addCase(updateInteractionTemplate.pending, (state) => {
      state.interactionTemplates.loading = true;
      state.interactionTemplates.errors = null;
    });
    builder.addCase(updateInteractionTemplate.fulfilled, (state, action) => {
      state.editTemplate = null;
      state.interactionTemplates.loading = false;
      state.interactionTemplates.data = state.interactionTemplates.data.map((template) =>
        template.id === action.payload.interactionTemplate.id ? action.payload.interactionTemplate : template,
      );
    });
    builder.addCase(updateInteractionTemplate.rejected, (state, action) => {
      state.interactionTemplates.loading = false;
      state.interactionTemplates.errors = action.payload?.errors;
    });

    builder.addCase(deleteInteractionTemplate.pending, (state) => {
      state.interactionTemplates.loading = true;
      state.interactionTemplates.errors = null;
    });
    builder.addCase(deleteInteractionTemplate.fulfilled, (state, action) => {
      state.deleteTemplate = null;
      state.interactionTemplates.loading = false;
      state.interactionTemplates.data = state.interactionTemplates.data.filter(
        (template) => template.id !== action.meta.arg.template.id,
      );
    });
    builder.addCase(deleteInteractionTemplate.rejected, (state, action) => {
      state.interactionTemplates.loading = false;
      state.interactionTemplates.errors = true;
    });
  },
});

export {
  createInteractionTemplate,
  deleteInteractionTemplate,
  getEmailPlacholders,
  getInteractionTemplates,
  updateInteractionTemplate,
};

export const { setDeleteTemplate, setEditTemplate } = interactionTemplatesSlice.actions;

export default interactionTemplatesSlice.reducer;
