export const RECURRING_FREQUENCY = [
  { label: "Every Day", value: "number" },
  { label: "Every _ Day", value: "number" },
  { label: "Weekly", value: "weekly" },
];

export const RECURRING_DAY_OF_WEEK = [
  { label: "Mondays", value: "1" },
  { label: "Tuesdays", value: "2" },
  { label: "Wednesdays", value: "3" },
  { label: "Thursdays", value: "4" },
  { label: "Fridays", value: "5" },
  { label: "Saturdays", value: "6" },
  { label: "Sundays", value: "7" },
];
