/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import { isEqual } from "lodash";
import Dropdown from "../../../../shared/v2/Dropdown";

const closeDateRangeToOption = (range) => ({
  label: range.name,
  value: range.value,
});

const getCloseDateRangeList = (ranges) => ranges.map(closeDateRangeToOption);

const getSelectedDateRange = (ranges, rangeValue) => {
  if (!rangeValue) {
    return null;
  }

  const selectedRange = ranges.find((range) => range.value === rangeValue);

  if (!selectedRange) {
    return null;
  }

  return closeDateRangeToOption(selectedRange);
};

const CloseDateRangeFilter = ({ initiallySelectedDateRange, dateRangeOptions, onDateRangeChange }) => {
  const selectedDateRange = useMemo(
    () => getSelectedDateRange(dateRangeOptions, initiallySelectedDateRange),
    [dateRangeOptions, initiallySelectedDateRange],
  );
  const dateRangeList = useMemo(() => getCloseDateRangeList(dateRangeOptions), [dateRangeOptions]);

  const handleOnChange = (newSelectedDateRange) => {
    if (isEqual(newSelectedDateRange, selectedDateRange)) {
      return;
    }

    if (!newSelectedDateRange) {
      onDateRangeChange(null);
      return;
    }

    onDateRangeChange(newSelectedDateRange.value);
  };

  return (
    <Dropdown
      label="Close Date Range"
      placeholder="Select a close date range"
      options={dateRangeList}
      value={selectedDateRange}
      onChange={handleOnChange}
      menuShouldComeToFront
      isSearchable
      menuPlacement="top"
    />
  );
};

export default CloseDateRangeFilter;
