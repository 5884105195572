import React from 'react';
import { CookiesProvider } from 'react-cookie';
import Tasks from './Tasks';

export default (props) => (
  <CookiesProvider>
    <Tasks
      user={props.user}
      admin={props.admin}
      ids={props.ids}
      agent={props.agent}
      dueness={props.dueness}
      accountMilestoneNames={props.accountMilestoneNames}
      agents={props.agents}
      orderBy={props.orderBy}
      orderDirection={props.orderDirection}/>
  </CookiesProvider>
)
