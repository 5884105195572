export const communicationOptions = [
  { label: "Any", value: "" },
  { label: "Has received call", value: "call" },
  { label: "Has not received call", value: "no_call" },
];

export const communicationDateOptions = [
  { label: "Today", value: "not_called" },
  { label: "Last 14 days", value: "last-14-days" },
  { label: "Last 30 days", value: "last-30-days" },
  { label: "Last 90 days", value: "last-90-days" },
];

export const statuses = ["Expired", "Withdrawn", "Canceled"];

export const communicationOutcomes = [
  { label: "Talked", value: "talked_to_lead" },
  { label: "Left Message", value: "left_message" },
  { label: "Busy", value: "busy" },
  { label: "Failed", value: "failed" },
  { label: "No Answer", value: "no_answer" },
  { label: "Wrong Number", value: "wrong_number" },
];

export const getDefaultFilters = () => ({
  excluded: ["no_phone", "dnc"],
  communication: null,
  communicationRange: null,
  communicationOutcomes: [],
  statuses: [...statuses],
  propertyTypes: [],
  statusDateMin: "",
  statusDateMax: "",
  priceMin: "",
  priceMax: "",
  bedsMin: "",
  bedsMax: "",
  bathsMin: "",
  bathsMax: "",
  sqftMin: "",
  sqftMax: "",
  lotSizeMin: "",
  lotSizeMax: "",
});

export const toggleArrayValue = (filters, field, value) => {
  const newValues = filters[field].includes(value)
    ? filters[field].filter((f) => f !== value)
    : [...filters[field], value];

  return { ...filters, [field]: newValues };
};

export const setField = (filters, field, value) => {
  return { ...filters, [field]: value };
};

export const countFiltersApplied = (filters) => {
  let count = 0;

  FilterPairs.forEach(([min, max]) => {
    if (filters[min] || filters[max]) {
      count++;
    }
  });

  if (filters.communication) {
    count++;
  }

  if (filters.communicationRange) {
    count++;
  }

  if (filters.propertyTypes?.length) {
    count += filters.propertyTypes.length;
  }

  return count;
};

const FilterPairs = [
  ["priceMin", "priceMax"],
  ["sqftMin", "sqftMax"],
  ["bedsMin", "bedsMax"],
  ["bathsMin", "bathsMax"],
  ["lotSizeMin", "lotSizeMax"],
  ["statusDateMin", "statusDateMax"],
];
