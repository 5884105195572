import React from "react";

import { useDispatch } from "react-redux";

import TextInput from "@shared/v2/TextInput";
import FieldLabel from "@shared/v2/FieldLabel";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

const AboutMe = () => {
  const dispatch = useDispatch();
  const { bio } = useContactPerson().loginUserAttributes;
  return (
    <div className="">
      <FieldLabel label="Bio" htmlFor="aboutMe-1" />
      <TextInput
        onChange={(e) => dispatch(setPersonValue({ key: "loginUserAttributes.bio", value: e.target.value }))}
        value={bio}
        className="tw-w-full tw-h-[136px] tw-mt-[8px]"
        id="aboutMe-1"
        multiline
      />
    </div>
  );
};

export default AboutMe;
