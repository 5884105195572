import React, { Component } from "react";
import PropTypes from "prop-types";
import TaskGrid from "./TaskGrid";
import Task from "./Task";
import PolymorphicGrid from "./PolymorphicGrid";
import SelectAll from "../../shared/SelectAll";
import Pagination from "../../shared/Pagination";
import { Col, Row } from "../../shared/v1";
import ReassignModal from "./ReassignModal";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import { Cookies, withCookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";

const defaultPagination = {
  total_count: 0,
  current_page: 1,
  total_pages: 1,
  prev_page: null,
  next_page: null,
};

class Tasks extends Component {
  constructor(props, context) {
    super(props, context);

    const { cookies } = this.props;
    var filter_cookie = undefined;
    if (this.props.admin) {
      filter_cookie = cookies.get("task_filter") || { selected_filter: "all_tasks", selected_agents: [] };
    } else {
      filter_cookie = { selected_filter: "my_tasks", selected_agents: [] };
    }

    this.state = {
      user: props.user,
      order_by: "name",
      order_direction: "desc",
      order_by: props.orderBy,
      order_direction: props.orderDirection,
      all_tasks: filter_cookie.selected_filter == "all_tasks",
      unassigned_tasks: filter_cookie.selected_filter == "unassigned_tasks",
      my_tasks: filter_cookie.selected_filter == "my_tasks",
      selectedAgents: filter_cookie.selected_agents || [],
      selectedFilter: filter_cookie.selected_filter || "my_tasks",
      filtered: this.props.admin
        ? filter_cookie.selected_filter != "all_tasks"
        : filter_cookie.selected_filter != "my_tasks",
      tasks: undefined,
      anyone: false,
      pagination: props.pagination,
      page: props.page,
      dueness: props.dueness,
      toggleLoader: false,
    };

    this.handleGotoPage = this.handleGotoPage.bind(this);
    this.multiSelectWatcher = TaskHelpers.multiSelectWatcher.bind(this);
  }

  componentDidMount() {
    const TASK_LISTENER = "task:update";
    window.removeEventListener(TASK_LISTENER, this.onEvent);
    window.addEventListener(TASK_LISTENER, this.onEvent);
    this.getTasks();
  }

  componentWillUnmount() {
    const TASK_LISTENER = "task:update";
    window.removeEventListener(TASK_LISTENER, this.onEvent);
  }

  onEvent = () => {
    this.getTasks();
  };

  filterWatcher = (e) => {
    if (this.state.my_tasks || this.state.unassigned_tasks || this.state.selectedAgents.length > 0) {
      this.setState({ ...this.state, filtered: true });
    } else {
      this.setState({ selectedFilter: "all_tasks", all_tasks: true, filtered: false });
    }
  };

  handleGotoPage(newPage) {
    this.setState({ page: newPage }, this.getTasks);
  }

  showMyTasks = (e) => {
    this.setState(
      {
        ...this.state,
        selectedFilter: "my_tasks",
        my_tasks: !this.state.my_tasks,
        unassigned_tasks: false,
        all_tasks: false,
        selectedAgents: [],
      },
      () => {
        this.getTasks();
        this.filterWatcher();
      },
    );
  };

  showUnassignedTasks = (e) => {
    this.setState(
      {
        ...this.state,
        selectedFilter: "unassigned_tasks",
        unassigned_tasks: !this.state.unassigned_tasks,
        my_tasks: false,
        all_tasks: false,
        selectedAgents: [],
      },
      () => {
        this.getTasks();
        this.filterWatcher();
      },
    );
  };

  showAllTasks = (e) => {
    this.setState(
      {
        ...this.state,
        selectedFilter: "all_tasks",
        all_tasks: !this.state.all_tasks,
        unassigned_tasks: false,
        my_tasks: false,
        selectedAgents: [],
      },
      () => {
        this.getTasks();
        this.filterWatcher();
      },
    );
  };

  showTaskFor = (e) => {
    let agents = this.state.selectedAgents;
    this.setState(
      {
        ...this.state,
        selectedFilter: "tasks_by_id",
        all_tasks: false,
        unassigned_tasks: false,
        my_tasks: false,
      },
      () => {
        if (agents.includes(e.target.name)) {
          this.removeAgentFromSelectedAgents(e.target.name);
          this.getTasks();
          this.filterWatcher();
        } else {
          this.addAgentToSelectedAgents(e.target.name);
          this.getTasks();
          this.filterWatcher();
        }
      },
    );
  };

  removeAgentFromSelectedAgents = (id) => {
    let agents = this.state.selectedAgents;
    let index = agents.indexOf(id);
    if (index > -1) {
      agents.splice(index, 1);
    }
    this.setState({ ...this.state, selectedAgents: agents });
  };

  addAgentToSelectedAgents = (id) => {
    let agents = this.state.selectedAgents;
    agents.push(id);
    this.setState({ ...this.state, selectedAgents: agents });
  };

  idsForUrl = () => {
    return this.idsByFilterOptions()[this.state.selectedFilter];
  };

  // TODO: Avoid evaluating all values if only one is needed. Use a case statement or a constant.
  idsByFilterOptions = () => {
    return {
      all_tasks: "",
      my_tasks: this.props.user.uuid,
      unassigned_tasks: "",
      tasks_by_id:
        this.state.selectedAgents.length > 0 ? this.state.selectedAgents.join() : this.props.user.uuid,
    };
  };

  getTasks = (e) => {
    const { page, dueness, order_direction, order_by, selectedFilter } = this.state;

    axios({
      method: "get",
      url: `/tasks/${dueness}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        user_ids: this.idsForUrl(),
        anyone: selectedFilter == "unassigned_tasks",
        all: selectedFilter == "all_tasks",
        page: page,
        order_direction: order_direction,
        order_by: order_by,
      },
    }).then((response) => {
      const { meta, tasks } = response.data;
      const pagination = { ...meta, defaultPagination };
      this.setState({ ...this.state, ...{ tasks: tasks, pagination: pagination } });
      this.multiSelectWatcher();
      this.toggleTaskLoader(false);
    });
  };

  removeTask = () => {
    this.getTasks();
  };

  orderBy = (value_to_order_by, defaultOrder = "desc") => {
    let { state } = this;
    // The first click to order by a column orders 'desc' first and then 'asc' on 2nd click
    if (value_to_order_by !== this.state.order_by) {
      state["order_direction"] = defaultOrder;
    } else {
      state["order_direction"] = this.state.order_direction == "asc" ? "desc" : "asc";
    }
    state["order_by"] = value_to_order_by;
    this.setState({ ...this.state, ...state });
    this.getTasks();
  };

  updateParentTasks = (task) => {
    this.getTasks();
  };

  toggleTaskLoader = (isLoading) => {
    this.setState({ toggleLoader: isLoading });
  };

  render() {
    const { pagination } = this.state;

    return (
      <div id="content" className="tw-flex tw-flex-col tw-h-full">
        <TaskGrid
          tasks={this.state.tasks}
          tasksCount={pagination.total_count}
          user={this.props.user}
          agents={this.props.agents}
          agent={this.props.agent}
          admin={this.props.admin}
          uncomplete={this.props.dueness == "completed" ? true : false}
          dueness={this.props.dueness}
          showMyTasks={this.showMyTasks}
          showUnassignedTasks={this.showUnassignedTasks}
          showAllTasks={this.showAllTasks}
          showTaskFor={this.showTaskFor}
          removeAgentFromSelectedAgents={this.removeAgentFromSelectedAgents}
          addAgentToSelectedAgents={this.addAgentToSelectedAgents}
          selectedAgents={this.state.selectedAgents}
          getTasks={this.getTasks}
          my_tasks={this.state.my_tasks}
          unassigned_tasks={this.state.unassigned_tasks}
          all_tasks={this.state.all_tasks}
          filtered={this.state.filtered}
          selectedFilter={this.state.selectedFilter}
          removeTask={this.removeTask}
          orderBy={this.orderBy}
          order_by={this.state.order_by}
          header_buttons={this.state.header_buttons}
          order_direction={this.state.order_direction}
          accountMilestoneNames={this.props.accountMilestoneNames}
          toggleLoader={this.state.toggleLoader}
          toggleTaskLoader={this.toggleTaskLoader}
        />
        {pagination.total_count > 50 && pagination.current_page && (
          <div className="tw-flex tw-justify-center">
            <Pagination
              currentPage={pagination.current_page}
              nextPage={pagination.next_page}
              prevPage={pagination.prev_page}
              totalPages={pagination.total_pages}
              totalCount={pagination.total_count}
              onGoToPage={this.handleGotoPage}
            />
          </div>
        )}
      </div>
    );
  }
}

Tasks.propTypes = {
  user: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

Tasks.defaultProps = {
  tasks: [],
  user: { name: "No Name" },
  pagination: {},
};

export default withCookies(Tasks);
