import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";

const PlatformCTA = ({ children }) => (
  <Tooltip
    placement="top"
    trigger={children}
    innerClassName="tw-max-w-[200px]"
    content="Filtering by property view/favorite criteria is a Brivity Platform feature. Contact sales@brivity.com to upgrade."
  />
);

PlatformCTA.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PlatformCTA;
