import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import CollapsibleCards from "./CollapsibleCards";
import { sumOfKey } from "../actions/helpers";
import MailerCards from "./MailerCards";
import ListingCards from "./ListingCards";
import NoCards from "./NoCards";
import SocialAds from "./SocialAds";
import format from "../../../../shared/formatters";

const numberOrDash = (value, decimals = 0) => {
  if (value === 0) return 0;
  if (!value) return "-";
  return format.number(value, decimals);
};

const normalizeData = (data) => {
  // TODO: get this to the adapter/reducer level
  // This is likely in the wrong place, as the component should not handle it.
  // However, for the sake of a hot patch, this is where it remains.
  const mailers = data.mailers || [];
  const ads = data.ads || [];

  return {
    ...data,
    mailers: {
      data: mailers,
      budget: numberOrDash(sumOfKey(mailers, "budget_cents") / 100, 2),
      recipients: numberOrDash(sumOfKey(mailers, "recipients")),
    },
    ads: {
      data: ads,
      leads: numberOrDash(sumOfKey(ads, "leads")),
      budget: numberOrDash(sumOfKey(ads, "budget") / 100, 2),
      impressions: numberOrDash(sumOfKey(ads, "impressions")),
      counter: numberOrDash(ads.length),
    },
  };
};

const AssetsCards = ({ assetsCardViews }) => {
  const normalizedData = normalizeData(assetsCardViews);

  return (
    <div className="tw-pb-[32px]">
      <CollapsibleCards
        name="Mailer Campaigns"
        recipients={normalizedData.mailers.recipients}
        budget={normalizedData.mailers.budget}
        counter={normalizedData.mailers.data.length}
      >
        {normalizedData.mailers.data.length > 0 ? (
          normalizedData.mailers.data.map((mailer, i) => (
            <MailerCards
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              name={mailer.name}
              status={mailer.status}
              type={mailer.type}
              createdBy={mailer.created_by}
              size={mailer.size}
              createdAt={mailer.created_at}
              coverImage={mailer.cover_image}
              recipients={mailer.recipients}
              budgetCents={mailer.budget_cents}
            />
          ))
        ) : (
          <NoCards
            name="Mailer Campaigns"
            imageURL={assetsCardViews?.empty_states?.mailers?.image_url}
            description="Generate more leads for your listing by sending a mailer campaign."
          />
        )}
      </CollapsibleCards>

      <CollapsibleCards
        name="Digital Ads"
        leads={normalizedData.ads.leads}
        budget={normalizedData.ads.budget}
        impressions={normalizedData.ads.impressions}
        counter={normalizedData.ads.counter}
      >
        {normalizedData.ads.data.length > 0 ? (
          // eslint-disable-next-line react/prop-types
          normalizedData.ads.data.map((ad, i) => (
            <SocialAds
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              name={ad.name}
              type={ad.type}
              createdBy={ad.created_by}
              startDate={ad.start_date}
              endDate={ad.end_date}
              coverImage={ad.cover_image}
              ctr={ad.ctr}
              cpc={ad.cpc}
              budget={ad.budget}
              clicks={ad.clicks}
              impressions={ad.impressions}
              leads={ad.leads}
              status={ad.status}
            />
          ))
        ) : (
          <NoCards
            name="Digital Ads"
            imageURL={assetsCardViews?.empty_states?.ads?.image_url}
            description="Get more internet leads by advertising your listing on social media."
          />
        )}
      </CollapsibleCards>

      <CollapsibleCards name="Printed Media" counter={assetsCardViews?.print_media?.length || 0}>
        {assetsCardViews?.print_media?.length > 0 ? (
          <ListingCards carddata={assetsCardViews.print_media} />
        ) : (
          <NoCards
            name="Printed Media"
            imageURL={assetsCardViews?.empty_states?.print_media?.image_url}
            description="Easily create marketing material for all your listing activities."
          />
        )}
      </CollapsibleCards>

      <CollapsibleCards name="Social Media Graphics" counter={assetsCardViews?.social_media?.length || 0}>
        {assetsCardViews?.social_media?.length > 0 ? (
          <ListingCards carddata={assetsCardViews.social_media} />
        ) : (
          <NoCards
            name="Social Media Graphics"
            imageURL={assetsCardViews?.empty_states?.social_media?.image_url}
            description="Instantly promote your listing on social media."
          />
        )}
      </CollapsibleCards>

      <CollapsibleCards name="Videos" counter={assetsCardViews?.videos?.length || 0}>
        {assetsCardViews?.videos?.length > 0 ? (
          <ListingCards carddata={assetsCardViews.videos} />
        ) : (
          <NoCards
            name="Videos"
            imageURL={assetsCardViews?.empty_states?.videos?.image_url}
            description="Create a video (Beta)."
          />
        )}
      </CollapsibleCards>
    </div>
  );
};

AssetsCards.propTypes = {
  assetsCardViews: Proptypes.shape({
    ads: Proptypes.arrayOf(Proptypes.shape({})),
    social_media: Proptypes.arrayOf(Proptypes.shape({})),
    mailers: Proptypes.arrayOf(Proptypes.shape({})),
    print_media: Proptypes.arrayOf(Proptypes.shape({})),
    videos: Proptypes.arrayOf(Proptypes.shape({})),
    empty_states: Proptypes.shape({
      mailers: Proptypes.shape({ image_url: Proptypes.string }),
      ads: Proptypes.shape({ image_url: Proptypes.string }),
      social_media: Proptypes.shape({ image_url: Proptypes.string }),
      print_media: Proptypes.shape({ image_url: Proptypes.string }),
      videos: Proptypes.shape({ image_url: Proptypes.string }),
    }),
  }),
};
AssetsCards.defaultProps = {
  assetsCardViews: {
    ads: [],
    social_media: [],
    mailers: [],
    print_media: [],
    videos: [],
    empty_states: {
      mailers: {
        image_url: "",
      },
      ads: {
        image_url: "",
      },
      social_media: {
        image_url: "",
      },
      print_media: {
        image_url: "",
      },
      videos: {
        image_url: "",
      },
    },
  },
};

const mapStateToProps = (state) => ({
  assetsCardViews: state.tdpMarketingReducer.assetsCardViews,
});
export default connect(mapStateToProps)(AssetsCards);
