import React from "react";
import PropTypes from "prop-types";
import { Col } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";

const APPT_TYPES = {
  Buyer: "Buyer Consultation",
  BuyerListing: "Buyer/Listing Consultation",
  MeetAndGreet: "Meet & Greet",
  Recruiting: "Recruiting Appointment",
  RecruitingFollowUp: "Recruiting Follow Up",
  Screening: "Screening",
  Listing: "Listing Consultation",
  Signing: "Signing Appointment",
};

const OUTCOMES = {
  CompleteNotSigned: { label: "Appointment Complete - Not Signed", value: "Appointment Complete" },
  Complete: { label: "Appointment Complete", value: "Appointment Complete" },
  Canceled: { label: "Appointment Canceled", value: "Appointment Cancelled" },
  NoShow: { label: "No Show", value: "No Show" },
  SignedBuyer: {
    label: "Signed - Buyer Agreement",
    value: "Signed - Buyer Agreement",
  },
  SignedListing: {
    label: "Signed - Listing Agreement",
    value: "Signed - Listing Agreement",
  },
  SignedBoth: {
    label: "Signed - Both Buyer/Listing",
    value: "Signed - Both Buyer/Listing",
  },
  SignedRecruit: {
    label: "Signed - Recruit",
    value: "Signed - Recruit",
  },
  NotAFit: {
    label: "Not a Fit",
    value: "Not a Fit",
  },
};

const OutcomeSelection = ({ appointmentType, handleOutcomeOnChange, task }) => {
  const outcomeOptions = React.useMemo(() => {
    const isBuyerSeller = [APPT_TYPES.Buyer, APPT_TYPES.Listing, APPT_TYPES.BuyerListing].includes(
      appointmentType,
    );
    const outcomes = [
      isBuyerSeller ? OUTCOMES.CompleteNotSigned : OUTCOMES.Complete,
      OUTCOMES.Canceled,
      OUTCOMES.NoShow,
    ];

    switch (appointmentType) {
      case APPT_TYPES.Buyer:
        outcomes.splice(0, 0, OUTCOMES.SignedBuyer, OUTCOMES.SignedBoth);
        break;
      case APPT_TYPES.Listing:
        outcomes.splice(0, 0, OUTCOMES.SignedListing, OUTCOMES.SignedBoth);
        break;
      case APPT_TYPES.BuyerListing:
        outcomes.splice(0, 0, OUTCOMES.SignedBuyer, OUTCOMES.SignedBoth, OUTCOMES.SignedListing);
        break;
      case APPT_TYPES.Recruiting:
      case APPT_TYPES.Signing:
        outcomes.splice(0, 0, OUTCOMES.SignedRecruit);
        break;
      case APPT_TYPES.MeetAndGreet:
      case APPT_TYPES.Screening:
      case APPT_TYPES.RecruitingFollowUp:
        outcomes.splice(0, 0, OUTCOMES.SignedRecruit, OUTCOMES.NotAFit);
        break;
      default:
    }

    return outcomes;
  }, [appointmentType]);

  return (
    <Col size={6} className={task.completed && task.type ? "tw-block" : "tw-hidden"}>
      <Dropdown
        label="Outcome"
        isRequired
        options={outcomeOptions}
        value={outcomeOptions.find((opt) => opt.value === task.outcome)}
        onChange={(option) => handleOutcomeOnChange(option.value)}
        isDisabled={!(task.completed && task.type === "Appointment")}
      />
    </Col>
  );
};

OutcomeSelection.propTypes = {
  task: PropTypes.shape().isRequired,
  appointmentType: PropTypes.string.isRequired,
  handleOutcomeOnChange: PropTypes.func.isRequired,
};

export default OutcomeSelection;
