import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Download, Trash } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";

const downloadDocument = (fileName, url) => {
  const anchor = document.createElement("a");
  anchor.setAttribute("download", fileName);
  anchor.setAttribute("href", url);
  anchor.setAttribute("target", "_blank");
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const InlineContext = ({ document, handleDeleteDocument }) => {
  const handleDownload = (e) => {
    e.stopPropagation();
    downloadDocument(document.fileName, document.expiringUrl);
  };

  return (
    <td>
      <div className="tw-flex tw-justify-end tw-items-center tw-gap-8px tw-mb-4px">
        <IconButton
          size="small"
          schema="tertiary"
          onClick={handleDownload}
          data-cy="document-list-item-download-button"
        >
          <Download />
        </IconButton>

        <IconButton
          size="small"
          schema="tertiary"
          onClick={handleDeleteDocument}
          data-cy="document-list-item-delete-button"
        >
          <Trash />
        </IconButton>
      </div>

      <p
        className="tw-m-0 tw-text-12d tw-font-normal tw-text-neutral-gray-50 tw-text-right"
        data-cy="document-list-item-date-created-at"
      >
        {moment(document.uploadedAt).format("MM/DD/YYYY")}
      </p>
    </td>
  );
};

InlineContext.propTypes = {
  document: PropTypes.shape({}).isRequired,
  handleDeleteDocument: PropTypes.func,
};

InlineContext.defaultProps = {
  handleDeleteDocument: () => {},
};

export default InlineContext;
