import React from "react";
import DeleteMilestoneModal from "./DeleteMilestoneModal";
import { Modal } from "../../shared/v1";
import MilestonesForm from "./MilestonesForm";

const renderNewMilestoneModal = (props) => {
  const {
    isNewMilestoneModalOpen,
    closeNewMilestoneModal,
    milestoneFormUpdate,
    submitNewMilestone,
    milestoneForm,
    modalErrorMessage,
    modalHasError,
    isModalSubmitting,
    projectsEnabled,
  } = props;

  const milestonesCreatePath = "/new_milestones";

  return (
    <Modal show={isNewMilestoneModalOpen} onHide={closeNewMilestoneModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Custom Date</Modal.Title>
      </Modal.Header>
      <Modal.Body className="milestone-form">
        <MilestonesForm
          milestonesCreatePath={milestonesCreatePath}
          milestoneForm={milestoneForm}
          onCloseAction={closeNewMilestoneModal}
          onSubmitAction={submitNewMilestone}
          onFormChange={milestoneFormUpdate}
          errorMessage={modalErrorMessage}
          hasError={modalHasError}
          isSubmitting={isModalSubmitting}
          projectsEnabled={projectsEnabled}
        />
      </Modal.Body>
    </Modal>
  );
};

const renderEditMilestoneModal = (props) => {
  const {
    isEditMilestoneModalOpen,
    closeEditMilestoneModal,
    milestoneFormUpdate,
    submitUpdateMilestone,
    milestoneForm,
    modalErrorMessage,
    modalHasError,
    isModalSubmitting,
  } = props;

  const milestonesUpdatePath = `/new_milestones/${milestoneForm.id}`;

  return (
    <Modal show={isEditMilestoneModalOpen} onHide={closeEditMilestoneModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update Custom Date</Modal.Title>
      </Modal.Header>
      <Modal.Body className="milestone-form">
        <MilestonesForm
          milestonesUpdatePath={milestonesUpdatePath}
          milestoneForm={milestoneForm}
          onCloseAction={closeEditMilestoneModal}
          onSubmitAction={submitUpdateMilestone}
          onFormChange={milestoneFormUpdate}
          errorMessage={modalErrorMessage}
          hasError={modalHasError}
          isSubmitting={isModalSubmitting}
        />
      </Modal.Body>
    </Modal>
  );
};

const renderDeleteMilestoneModal = (props) => {
  const {
    isDeleteMilestoneModalOpen,
    isDeletingMilestone,
    closeDeleteMilestoneModal,
    milestonePath,
    modalErrorMessage,
    modalHasError,
    milestonesPath,
    milestoneToDelete,
    deleteMilestone,
  } = props;

  return (
    <DeleteMilestoneModal
      isDeleteMilestoneModalOpen={isDeleteMilestoneModalOpen}
      isDeletingMilestone={isDeletingMilestone}
      closeDeleteMilestoneModal={closeDeleteMilestoneModal}
      milestoneToDelete={milestoneToDelete}
      milestonePath={milestonePath}
      deleteMilestone={deleteMilestone}
      errorMessage={modalErrorMessage}
      hasError={modalHasError}
    />
  );
};

export { renderNewMilestoneModal, renderEditMilestoneModal, renderDeleteMilestoneModal };
