import React, { useState } from "react";
import PropTypes from "prop-types";
import HeadingSelect from "../../../Goals/components/HeadingSelect";
import { Section } from "../BusinessDashboard";
import Button from "../../../shared/v2/Button";
import SummarySection from "./SummarySection";
import RecruitingPipelineSection from "./RecruitingPipelineSection";
import TotalAgentsStaffSection from "./TotalAgentsStaffSection";
import RecruitingSection from "./RecruitingSection";
import PerAgentProductionSection from "./PerAgentProductionSection";
import ProductionStatsSection from "./ProductionStatsSection";

const OperatorDashboard = ({ operatorDashboardGraphsEnabled }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  return (
    <div className="tw-bg-gray-5 tw-flex tw-p-[16px]">
      <div className="tw-relative tw-max-w-[1500px] tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[16px]">
        <Section>
          <div className="tw-flex tw-justify-between">
            <HeadingSelect
              title={<h2 className="tw-text-28d tw-mr-[8px] tw-m-0">Operator Dashboard</h2>}
              name="year"
              value={year}
              onChange={setYear}
            />
            <Button
              size="medium"
              schema="secondary"
              onClick={() => window.location.assign(`/settings/goals?year=${year}`)}
            >
              Settings
            </Button>
          </div>
        </Section>
        <SummarySection year={year} />
        <RecruitingPipelineSection year={year} />
        {operatorDashboardGraphsEnabled && (
          <div className="tw-flex tw-flex-wrap tw-flex-1 tw-min-w-[320px] tw-gap-[16px]">
            <RecruitingSection year={year} />
            <PerAgentProductionSection year={year} />
          </div>
        )}
        <div className="tw-flex tw-flex-wrap tw-flex-1 tw-min-w-[320px] tw-gap-[16px]">
          <div className="tw-flex tw-items-start tw-flex-1 tw-flex-wrap tw-min-w-[320px] tw-gap-[16px]">
            <TotalAgentsStaffSection year={year} type="agents" />
            <TotalAgentsStaffSection year={year} type="staff" />
          </div>
          <div className="tw-flex tw-items-start tw-flex-1 tw-min-w-[320px] tw-gap-[16px]">
            <ProductionStatsSection year={year} />
          </div>
        </div>
      </div>
    </div>
  );
};

OperatorDashboard.propTypes = {
  operatorDashboardGraphsEnabled: PropTypes.bool.isRequired,
};

export default OperatorDashboard;
