import React from "react";
import { connect } from "react-redux";
import { arrayOf, string } from "prop-types";

import AutoPlan from "./fractal";

const AutoPlansInModal = ({ autoPlans }) =>
  Object.keys(autoPlans).map((autoPlan) => <AutoPlan key={autoPlan} autoPlan={autoPlan} />);

const mapStateToProps = (state) => ({
  autoPlans: state.tdpTasksReducer.secondaryFilters.byAutoPlans,
});

AutoPlansInModal.propTypes = {
  autoPlans: arrayOf(string),
};

AutoPlansInModal.defaultProps = {
  autoPlans: [],
};

export default connect(mapStateToProps)(AutoPlansInModal);
