import moment from "moment/moment";

import {
  taskHasRecurring,
  taskHasContingent,
  taskHasAssignedPerson,
  taskHasEventType,
  taskHasDate,
} from "./helpers";

const taskState = (task) =>
  !task
    ? {}
    : {
        title: task.name,
        instructions: task.instructions,
        notes: task.note,
        priority: task.priority,
        visibility: task.externally_visible,
        recurring: taskHasRecurring(task),
        contingent: taskHasContingent(task),
        date: taskHasDate(task),
        assignedPerson: taskHasAssignedPerson(task),
        eventType: taskHasEventType(task),
      };

export const defaultState = (uuid, task) => ({
  uuid,
  title: "",
  instructions: "",
  notes: "",
  priority: 9,
  visibility: false,
  recurring: null,
  contingent: null,
  date: moment().format("MM/DD/YYYY"),
  assignedPerson: null,
  eventType: null,
  ...taskState(task),
});

export const DEFAULT_INPUT_STATE = {
  Instructions: false,
  Notes: false,
};
