import React from "react";
import PropTypes from "prop-types";
import FishSolidV3 from "@shared/v2/Icomoon/Icons/FishSolidV3";

const TimelineLeadPondDeleteEvent = ({ event }) => (
  <div>
    <div className="timeline-event">
      <div className="row">
        <div className="col-xs-1">
          <span className="timeline-icon-circle tw-inline-flex tw-justify-center tw-items-center">
            <FishSolidV3 size="l" />
          </span>
        </div>
        <div className="col-xs-8">
          <span className="bold-detail">{event.timeline_fields.updater_name}</span>
          {" deleted "}
          <span className="bold-detail">{event.timeline_fields.lead_pond}</span>
          <div className="timeline-description-text">
            <div>
              <div className="row details-row">
                {"Reassigned from "}
                <span className="bold-detail">{event.timeline_fields.lead_pond}</span>
                {" to "}
                <span className="bold-detail">{event.timeline_fields.account_owner}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  </div>
);
TimelineLeadPondDeleteEvent.propTypes = {
  event: PropTypes.shape({
    interacted_at_absolute: PropTypes.string,
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      lead_pond: PropTypes.string,
      account_owner: PropTypes.string,
    }),
  }),
};

TimelineLeadPondDeleteEvent.defaultProps = {
  event: null,
};

export default TimelineLeadPondDeleteEvent;
