import React, { useState, useRef, useEffect } from "react";
import { Overlay } from "react-bootstrap";
import { useClickOutside } from "../hookHelpers";
import { useGetAgentState } from "../agentOrPondHookHelpers";
import DropDownSelectOption from "../DropDownSelectOption";
import "../../Filters/components/PondOrAgentFilter/styles.scss";

const FormAgentOrPondSelect = ({
  enableLeadPonds = false,
  primaryAgent,
  leadPond,
  onChange = (params) => {},
  placeholder = "",
}) => {
  const target = useRef(null);
  const [state, setState, handleChange] = useGetAgentState(enableLeadPonds, true);
  const [primaryAgentModel, setPrimaryAgent] = useState(null);
  const [leadPondModel, setLeadPond] = useState(leadPond);

  useClickOutside(target, () => setState((state) => ({ ...state, show: false })));

  useEffect(() => {
    if (!state?.allAssignableAgents.length) return;

    if (!primaryAgentModel) {
      const agent = state.allAssignableAgents.find((a) => a.id === primaryAgent);
      if (agent) setPrimaryAgent(agent);
    }
  }, [primaryAgent, state.allAssignableAgents]);

  const handleAgentSelect = (agent) => {
    const params = {
      primary_agent: agent.id,
    };
    setLeadPond(null);
    setPrimaryAgent(agent);
    setState((state) => ({ ...state, show: false }));
    onChange(params);
  };

  const handlePondSelect = (leadPond) => {
    const params = {
      lead_pond: leadPond.id,
    };
    setState((state) => ({ ...state, show: false }));
    setLeadPond(leadPond);
    setPrimaryAgent(null);
    onChange(params);
  };

  const setShowDropdown = (show) => setState((state) => ({ ...state, show }));

  const { filter, filteredAssignableAgents, filteredAssignablePonds } = state;

  return (
    <div className={`tw-relative`} ref={target} data-cy="pond_agent_field">
      <button
        className="tw-text-[14px] tw-flex tw-items-center tw-h-[32px] tw-py-[6px] tw-bg-tinted-gray-50 tw-w-full tw-border-[1px] tw-border-solid tw-border-neutral-gray-30 tw-text-left tw-truncate"
        onClick={() => setShowDropdown(true)}
      >
        <div className={`tw-flex-1 ${!leadPondModel && !primaryAgentModel ? "tw-text-neutral-gray-50" : ""}`}>
          {leadPondModel ? leadPondModel.name : primaryAgentModel ? primaryAgentModel.name : placeholder}
        </div>
        <input
          type="hidden"
          value={leadPondModel ? leadPondModel.id : primaryAgentModel ? primaryAgentModel.id : null}
          name={leadPondModel ? "lead_pond_id" : "primary_agent_id"}
        />
        <i className="fa fa-caret-down tw-text-[15px] tw-text-neutral-gray-75 tw-px-2" />
      </button>
      <Overlay
        show={state.show}
        onHide={() => setShowDropdown(false)}
        placement="bottom"
        container={target.current}
        target={target.current}
      >
        <div
          className={`tw-bg-white tw-mt-[-8px] tw-z-40 tw-shadow-modal tw-absolute tw-max-h-[350px] tw-w-full tw-overflow-y-auto`}
        >
          <div className="tw-p-4">
            <input
              className="tw-w-full tw-border tw-border-gray-10"
              autoFocus={true}
              onChange={handleChange}
              placeholder="Search..."
              value={filter}
            />
          </div>

          {filteredAssignablePonds.length > 0 && (
            <p className="tw-text-[12px] tw-pt-1 tw-px-2 tw-bg-neutral-gray-10">Pond</p>
          )}
          {filteredAssignablePonds?.map((pond) => (
            <DropDownSelectOption
              key={pond.id}
              showCheckIcon={false}
              option={{ ...pond }}
              handleSelect={() => handlePondSelect(pond)}
            />
          ))}

          {filteredAssignableAgents.length > 0 && (
            <p className="tw-text-[12px] tw-pt-1 tw-px-2 tw-bg-neutral-gray-10">Team</p>
          )}
          {filteredAssignableAgents?.map((agent) => (
            <DropDownSelectOption
              key={agent.id}
              showCheckIcon={false}
              option={{
                ...agent,
                imageSrc: agent.image_src,
              }}
              imgSizeClass="force-img-sm"
              handleSelect={() => handleAgentSelect(agent)}
            />
          ))}
        </div>
      </Overlay>
    </div>
  );
};

export default FormAgentOrPondSelect;
