import React from "react";

const ToggleableButton = ({ text, onClick, isSelected, className = "", includeMargins = true }) => {
  const colors = isSelected ? "tw-bg-gray-75 tw-border-transparent" : "";
  const textStyle = isSelected ? "tw-text-white" : "tw-text-gray-75";
  const margins = includeMargins ? "tw-mr-4px " : "";

  return (
    <div
      onClick={onClick}
      className={`tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-py-4px tw-px-12px ${margins} tw-border tw-border-solid tw-border-gray-15 tw-rounded-full ${colors} ${className}`}
    >
      <span className={`tw-text-12px ${textStyle}`}>{text}</span>
    </div>
  );
};

export default ToggleableButton;
