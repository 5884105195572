/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChartColumnSolidV6 = (props) => (
  <Icon {...props} name="Chart Column Solid V6">
    <path d="M1 1a1 1 0 0 1 1 1v10.5a.5.5 0 0 0 .5.5H15a1 1 0 1 1 0 2H2.5A2.5 2.5 0 0 1 0 12.5V2a1 1 0 0 1 1-1zm4 6a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1zm4 3a1 1 0 1 1-2 0V5a1 1 0 1 1 2 0v5zm2-4a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm4 4a1 1 0 1 1-2 0V3a1 1 0 1 1 2 0v7z" />
  </Icon>
);

export default ChartColumnSolidV6;
