import React from "react";
import { func, string } from "prop-types";

import styles from "../styles";

import Button from "../../../../shared/v2/Button";
import { PlusSolidV6 } from "../../../../shared/v2/Icomoon";
import InlineEditingInput from "../../../../shared/v2/InlineEditingInput";

const AddFinBtn = (onClick) => (
  <Button schema="secondary" className="tw-flex tw-items-center" onClick={onClick}>
    <PlusSolidV6 className="tw-mr-4px" />
    <span>ADD</span>
  </Button>
);

const HeaderFinancial = ({
  value,
  InlineEditComponent,
  label,
  onChange,
  containerClassName,
  flatOrPercent,
  onFlatSelected,
  onPercentSelected,
}) => (
  <div className={`tw-relative tw-h-48px ${containerClassName}`}>
    <div className={styles.TRANSACTION_DETAILS_LABEL}>{label}</div>
    <InlineEditComponent
      className="tw-absolute tw-leading-24px tw-z-10"
      value={value}
      displayClassName="tw-not-italic tw-font-bold tw-text-18d [&>*]:tw-text-18d tw-h-[25.5px]"
      prefix={flatOrPercent === "flat" ? "$ " : ""}
      suffix={flatOrPercent === "percent" ? " %" : ""}
      placeholder=""
      onChange={onChange}
      displayComponent={AddFinBtn}
      flatOrPercent={flatOrPercent}
      onFlatSelected={onFlatSelected}
      onPercentSelected={onPercentSelected}
    />
  </div>
);

export default HeaderFinancial;

HeaderFinancial.propTypes = {
  value: string,
  label: string,
  flatOrPercent: string,
  InlineEditComponent: func,
  onChange: func,
  containerClassName: string,
  onFlatSelected: func,
  onPercentSelected: func,
};

HeaderFinancial.defaultProps = {
  value: null,
  label: null,
  flatOrPercent: "percent",
  InlineEditComponent: InlineEditingInput.Flat,
  onChange: () => {},
  containerClassName: null,
  onFlatSelected: () => {},
  onPercentSelected: () => {},
};
