import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { clearMap, processPoints, setPolyStyle, getRadiusInMeters } from "./helpers";

export const useMap = () => {
  const [map, setMap] = useState(false);
  return [map, setMap];
};

export function useOnDrawingMode({ isDrawing, disableFn, searchLocation, setFormData, currentName }) {
  const [drawingManager, setDrawingManager] = useState(false);

  useEffect(() => {
    if (drawingManager) {
      const mod = {
        searchType: "radius",
      };
      if (!isDrawing) {
        disableFn();
        mod.searchType = searchLocation?.source === "GoogleAPI" ? "radius" : "area";
      } else {
        drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
        mod.searchType = "area";
        mod.name = currentName || "Custom Area";
      }
      setFormData(mod);
    }
  }, [isDrawing, drawingManager]);
  return setDrawingManager;
}

const MARKER_COLORS = {
  active: {
    bdr: "63D689",
    bck: "CAF0D6",
    copy: "177335",
  },
  pending: {
    bdr: "FAB957",
    bck: "FAE4C3",
    copy: "AD6714",
  },
  sold: {
    bdr: "FA5D57",
    bck: "FAC5C3",
    copy: "A81E19",
  },
  off_market: {
    bdr: "C2C2C2",
    bck: "EBEBEB",
    copy: "666666",
  },
};
const numFormatter = (num) => {
  let output = num;
  if (num > 999 && num < 1000000) {
    output = `${(num / 1000).toFixed(0)}K`; // convert to K for number from > 10000 < 1 million
  } else if (num > 1000000) {
    output = `${(num / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
  }
  return output;
};
const markersArray = [];
const addMarker = (marker, type, map) => {
  const color = MARKER_COLORS[type];
  const label = `$${numFormatter(marker?.price)}`;
  const image = `data:image/svg+xml,%3Csvg width='58' height='34' viewBox='0 0 58 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_2_29)'%3E%3Cpath d='M14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28H21.5041L28.8822 33.2784L36.2602 28H44C51.732 28 58 21.732 58 14C58 6.26801 51.732 0 44 0H14Z' fill='%23${color.bck}'/%3E%3Cpath d='M21.9405 27.39L21.7447 27.25H21.5041H14C6.68223 27.25 0.75 21.3178 0.75 14C0.75 6.68223 6.68223 0.75 14 0.75H44C51.3178 0.75 57.25 6.68223 57.25 14C57.25 21.3178 51.3178 27.25 44 27.25H36.2602H36.0196L35.8238 27.39L28.8822 32.3562L21.9405 27.39Z' stroke='%23${color.bdr}' stroke-width='1.5'/%3E%3C/g%3E%3Ctext fill='%23${color.copy}' xml:space='preserve' style='text-align: center' font-family='Arial' font-size='14' font-weight='500' text-anchor='middle' letter-spacing='.05em'%3E%3Ctspan x='29' y='19.4312'%3E${label}%3C/tspan%3E%3C/text%3E%3Cdefs%3E%3Cfilter id='filter0_d_2_29' x='-22' y='-16' width='102' height='77.2784' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2_29' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A`;
  const latLng = new window.google.maps.LatLng(marker.lat, marker.lng);
  const markerElm = new window.google.maps.Marker({
    position: latLng,
    map,
    icon: image,
  });
  markersArray.push(markerElm);
};
export function setMarkers({ map, locationData }) {
  if (locationData && locationData?.listing_info) {
    Object.keys(locationData?.listing_info).forEach((type) => {
      locationData?.listing_info[type]?.forEach((marker) => {
        addMarker(marker, type, map);
      });
    });
  } else {
    markersArray.forEach((marker) => {
      marker.setMap(null);
    });
  }
}
export function useSetMap({ loadFn, clearFn, coords }) {
  return useEffect(() => {
    clearFn();
    loadFn();
  }, [coords]);
}

export function useAutoDetect({ autoDetectFn, coords, geoJSON }) {
  return useEffect(() => {
    if ((!coords || coords.length === 0) && !geoJSON) {
      autoDetectFn();
    }
  }, []);
}

let radiusCircle;
export const useMapDarwings = ({
  isDrawing,
  map,
  radius,
  boundry,
  geoJSON,
  locationData,
  radiusType,
  searchType,
  hasSearch,
  coords,
}) => {
  const fnCall = debounce(() => {
    if (!map) {
      return;
    }
    clearMap(map, radiusCircle);
    setTimeout(() => {
      if (!hasSearch && !boundry && !geoJSON) {
        return;
      }
      if (searchType === "radius" && !isDrawing) {
        const rad = radiusType === "smart-radius" ? locationData?.radius : radius;
        if (rad) {
          radiusCircle = new window.google.maps.Circle({
            map,
            center: { lat: coords[0], lng: coords[1] },
            radius: getRadiusInMeters(rad),
            strokeColor: "#59c4c3",
            strokeWeight: 2,
            fillColor: "#59c4c3",
            fillOpacity: 0.4,
          });
          window.setTimeout(() => {
            map.fitBounds(radiusCircle.getBounds());
          }, 100);
        }
      } else if (searchType === "area") {
        const bdry = isDrawing ? geoJSON : boundry;
        if (bdry) {
          setTimeout(() => {
            map.data.addGeoJson({
              type: "Feature",
              geometry: bdry,
            });
            setPolyStyle(map, isDrawing);
            const bounds = new window.google.maps.LatLngBounds();
            map.data.forEach((feature) => {
              processPoints(window.google, feature.getGeometry(), bounds.extend, bounds);
            });

            window.setTimeout(() => {
              map.fitBounds(bounds);
            }, 100);
          }, 0);
        }
      }
      setMarkers({
        map,
        locationData: isDrawing && !geoJSON ? null : locationData,
      });
    }, 0);
  }, 500);
  useEffect(() => {
    fnCall();
  }, [isDrawing, map, radius, boundry, geoJSON, locationData]);
};
