import React from "react";

import DetailSectionHeader from "../DetailSectionHeader";
import ReferralBrokerageField from "../ReferralBrokerageField";
import ReferralPartnerField from "../ReferralPartnerField";
import ReferralTeamField from "../ReferralTeamField";

const ReferralPartnerDetails = () => (
  <div className="tw-mb-24px">
    <DetailSectionHeader header="Referral Partner Details" />
    <ReferralPartnerField />
    <ReferralTeamField />
    <ReferralBrokerageField />
  </div>
);

export default ReferralPartnerDetails;
