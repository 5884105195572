import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { errorForBanner } from "../../../../shared/v2/ErrorBanner";

import { setApplyAutoPlanSelectedPlan, setApplyAutoPlanModalVisible } from "../actions/creators";
import { applyAutoPlan, showPreviewModalForAssignedPlan } from "../actions/thunks";
import AddAutoPlanModal from "../../../../shared/AddAutoPlanModal";

const AutoPlanApplyModal = ({
  uuid,
  assignedPlans,
  availablePlans,
  availablePlansError,
  availablePlansLoading,
  autoPlanApply,
  autoPlanPreviewStatus,
  isApplyAutoPlanModalVisible,
  onHide,
  onApply,
  onPreview,
  onSelectAutoPlan,
}) => {
  const idsOfAutoPlansCurrentlyAssigned = new Set(
    assignedPlans
      .filter((plan) => !["deleted", "completed"].includes(plan.state))
      .map((plan) => plan.autoPlanId),
  );

  const availablePlansAsOptions = availablePlans
    .map((plan) => ({ label: plan.name, value: plan.id }))
    // Don't show plans that are already applied.
    .filter((planOption) => !idsOfAutoPlansCurrentlyAssigned.has(planOption.value));

  const selectedAutoPlan = autoPlanApply.selectedPlanOption;

  const disableInteractionButtons =
    availablePlansLoading ||
    selectedAutoPlan === null ||
    autoPlanApply.status === "applying" ||
    autoPlanPreviewStatus === "loading";

  const errors = [];
  if (availablePlansError) {
    errors.push("Failed to fetch available auto plans.");
  }
  if (autoPlanApply.errors) {
    errors.push(...autoPlanApply.errors);
  }

  return (
    <AddAutoPlanModal
      show={isApplyAutoPlanModalVisible}
      onCancel={onHide}
      errors={errors.length ? errors.map((error) => errorForBanner(error)) : null}
      onApply={() => onApply(uuid, selectedAutoPlan.value)}
      isApplying={autoPlanApply.status === "applying"}
      isLoading={autoPlanPreviewStatus === "loading"}
      onPreview={() => onPreview(uuid, selectedAutoPlan.value)}
      disableInteractionButtons={disableInteractionButtons}
      availablePlansLoading={availablePlansLoading}
      availablePlansAsOptions={availablePlansAsOptions}
      selectedAutoPlan={selectedAutoPlan}
      onSelectAutoPlan={onSelectAutoPlan}
    />
  );
};

AutoPlanApplyModal.propTypes = {
  uuid: PropTypes.string.isRequired,
  assignedPlans: PropTypes.arrayOf(
    PropTypes.shape({
      autoPlanId: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),
  ).isRequired,
  availablePlans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  availablePlansError: PropTypes.string,
  availablePlansLoading: PropTypes.bool.isRequired,
  autoPlanApply: PropTypes.shape({
    selectedPlanOption: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    status: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  autoPlanPreviewStatus: PropTypes.oneOf(["idle", "loading", "succeeded", "failed"]).isRequired,
  isApplyAutoPlanModalVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onApply: PropTypes.func,
  onPreview: PropTypes.func,
  onSelectAutoPlan: PropTypes.func,
};

AutoPlanApplyModal.defaultProps = {
  availablePlansError: null,
  isApplyAutoPlanModalVisible: false,
  onHide: () => {},
  onApply: () => {},
  onPreview: () => {},
  onSelectAutoPlan: () => {},
};

const mapStateToProps = (state) => ({
  uuid: state.tdpAutoPlansReducer.uuid,
  assignedPlans: state.tdpAutoPlansReducer.assignedPlans.plans,
  availablePlans: state.tdpAutoPlansReducer.availableAutoPlans.plans,
  availablePlansError: state.tdpAutoPlansReducer.availableAutoPlans.meta.error,
  availablePlansLoading: state.tdpAutoPlansReducer.availableAutoPlans.meta.isLoading,
  autoPlanApply: state.tdpAutoPlansReducer.autoPlanApply,
  autoPlanPreviewStatus: state.tdpAutoPlansReducer.planPreview.meta.status,
  isApplyAutoPlanModalVisible: state.tdpAutoPlansReducer.autoPlanApply.showModal,
});

const mapDispatchToProps = (dispatch) => ({
  onHide: () => dispatch(setApplyAutoPlanModalVisible(false)),
  onApply: (uuid, autoPlan) => dispatch(applyAutoPlan(uuid, autoPlan)),
  onPreview: (uuid, autoPlan) => dispatch(showPreviewModalForAssignedPlan(uuid, autoPlan)),
  onSelectAutoPlan: (autoPlanId) => dispatch(setApplyAutoPlanSelectedPlan(autoPlanId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoPlanApplyModal);
