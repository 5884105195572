import React from "react";
import PropTypes from "prop-types";
import { TH, THead, TR } from "../Table/Table";

const TableHead = ({ tab, agentUuid }) => (
  <THead className="tw-sticky tw-top-0 tw-z-10">
    {tab === "Closings" && (
      <>
        <TR className="tw-font-semibold tw-bg-white">
          <TH border="tw-border-[2px]" className="tw-bg-white tw-sticky tw-left-0" />
          <TH border="tw-border" className="tw-text-center" colSpan={4}>
            MTD Closed
          </TH>
          <TH className="tw-text-center" colSpan={3}>
            To Be Closed
          </TH>
        </TR>
        <TR className="tw-bg-[#fafafa]">
          <TH border="tw-border-[2px]" className="tw-bg-[#fafafa] tw-text-center tw-sticky tw-left-0">
            Month
          </TH>
          <TH className="tw-text-center">Units</TH>
          <TH>Volume</TH>
          <TH>{agentUuid ? "Net Income" : "GCI"}</TH>
          <TH border="tw-border">Avg. Sale Price</TH>
          <TH className="tw-text-center">Units</TH>
          <TH>Volume</TH>
          <TH>{agentUuid ? "Net Income" : "GCI"}</TH>
        </TR>
      </>
    )}
    {tab === "Pendings" && (
      <>
        <TR className="tw-font-semibold tw-bg-white">
          <TH border="tw-border-[2px]" className="tw-bg-white tw-sticky tw-left-0" />
          <TH border="tw-border" className="tw-text-center" colSpan={4}>
            MTD Pendings
          </TH>
        </TR>
        <TR className="tw-bg-[#fafafa]">
          <TH border="tw-border-[2px]" className="tw-bg-[#fafafa] tw-text-center tw-sticky tw-left-0">
            Month
          </TH>
          <TH className="tw-text-center tw-w-[20%]">Units</TH>
          <TH>Volume</TH>
          <TH>{agentUuid ? "Est. Net Income" : "GCI"}</TH>
          <TH>Failed Pendings</TH>
        </TR>
      </>
    )}
    {tab === "Listings" && (
      <>
        <TR className="tw-font-semibold tw-bg-white">
          <TH border="tw-border-[2px]" className="tw-bg-white tw-sticky tw-left-0" />
          <TH border="tw-border" className="tw-text-center" colspan={3}>
            MTD Listings
          </TH>
          <TH border="tw-border" className="tw-text-center" colspan={3}>
            Coming Soon
          </TH>
        </TR>
        <TR className="tw-bg-[#fafafa]">
          <TH border="tw-border-[2px]" className="tw-bg-[#fafafa] tw-text-center tw-sticky tw-left-0">
            Month
          </TH>
          <TH className="tw-text-center">Listings Taken</TH>
          <TH>Volume</TH>
          <TH border="tw-border">{agentUuid ? "Net Income" : "GCI"}</TH>
          <TH className="tw-text-center">Units</TH>
          <TH>Volume</TH>
          <TH>{agentUuid ? "Est. Net Income" : "GCI"}</TH>
        </TR>
      </>
    )}
  </THead>
);

TableHead.propTypes = {
  agentUuid: PropTypes.string,
  tab: PropTypes.string.isRequired,
};

TableHead.defaultProps = {
  agentUuid: "",
};

export default TableHead;
