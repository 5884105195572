/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Phone = (props) => (
  <Icon {...props} name="Phone">
    <path d="M14.984 1.193 11.925.487a.711.711 0 0 0-.809.409L9.704 4.191a.704.704 0 0 0 .203.824l1.783 1.459a10.904 10.904 0 0 1-5.213 5.213L5.018 9.904a.705.705 0 0 0-.824-.203L.899 11.113a.714.714 0 0 0-.412.812l.706 3.059a.706.706 0 0 0 .688.547c7.534 0 13.65-6.104 13.65-13.65a.704.704 0 0 0-.547-.688z" />
  </Icon>
);

export default Phone;
