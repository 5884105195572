export const financialsFetchStarted = () => ({
  type: "TDP_FINANCIALS_FETCH_STARTED",
});

export const financialsFetchSuccess = (data) => ({
  type: "TDP_FINANCIALS_FETCH_SUCCESS",
  data,
});

export const financialsFetchFailure = (errors) => ({
  type: "TDP_FINANCIALS_FETCH_FAILURE",
  data: { errors },
});

export const financialsCreateStarted = () => ({
  type: "TDP_FINANCIALS_CREATE_STARTED",
});

export const financialsCreateSuccess = (data) => ({
  type: "TDP_FINANCIALS_CREATE_SUCCESS",
  data,
});

export const financialsCreateFailure = (errors) => ({
  type: "TDP_FINANCIALS_CREATE_FAILURE",
  data: { errors },
});

export const financialsUpdateStarted = () => ({
  type: "TDP_FINANCIALS_UPDATE_STARTED",
});

export const financialsUpdateCanceled = () => ({
  type: "TDP_FINANCIALS_UPDATE_CANCELED",
});

export const financialsUpdateSuccess = (data) => ({
  type: "TDP_FINANCIALS_UPDATE_SUCCESS",
  data,
});

export const financialsUpdateProgress = (data) => ({
  type: "TDP_FINANCIALS_UPDATE_PROGRESS",
  data,
});

export const financialsUpdateFailure = (errors, isInModal) => ({
  type: "TDP_FINANCIALS_UPDATE_FAILURE",
  data: { errors, isInModal },
});

export const financialsFetchSelectableTeamMembersSuccess = (data) => ({
  type: "TDP_FINANCIALS_FETCH_SELECTABLE_TEAM_MEMBERS_SUCCESS",
  data,
});

export const financialsRemoveTeamMemberSuccess = (data) => ({
  type: "TDP_FINANCIALS_REMOVE_TEAM_MEMBER_SUCCESS",
  data,
});

export const financialsRemoveTeamMemberFailure = (errors) => ({
  type: "TDP_FINANCIALS_REMOVE_TEAM_MEMBER_FAILURE",
  data: { errors },
});

export const financialsSetClosedPrice = (closedPrice) => ({
  type: "TDP_FINANCIALS_SET_CLOSED_PRICE",
  closedPrice,
});
