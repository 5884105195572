import React from "react";
import { func } from "prop-types";

import { RECURRING_FREQUENCY, RECURRING_DAY_OF_WEEK } from "./constants";
import { CONTINGENT_RECURRING } from "../constants";
import useRecurringFrequency from "./hooks";
import useHandlrs from "../hooks";

import { payloadPropTypes, PAYLOAD_DEFAULT } from "../propTypes";
import { displayHidden } from "../helpers";

import TextComboInput from "../../../../shared/v2/TextComboInput";
import Dropdown from "../../../../shared/v2/Dropdown";

const ModalAddEditRecurringElements = ({ payloadHandlrs, payload }) => {
  useRecurringFrequency(payloadHandlrs, payload);
  const handlr = useHandlrs(payloadHandlrs);

  return (
    <div className={`tw-flex tw-max-w-[264px] tw-gap-[12px] tw-mt-24px ${displayHidden(payload.recurring)}`}>
      <Dropdown
        containerClassName="tw-flex-1"
        value={payload.recurring?.frequency}
        onChange={(e) => handlr(e, CONTINGENT_RECURRING.recurring, "frequency", true)}
        options={RECURRING_FREQUENCY}
        label="Recurrs"
        menuShouldComeToFront
      />
      {payload.recurring?.frequency?.label === "Every _ Day" && (
        <div className="tw-flex-1">
          <TextComboInput
            trailing="Days"
            label="# of Days"
            size="3"
            value={payload.recurring?.recurring_every_so_many_days || ""}
            onChange={(e) => handlr(e, CONTINGENT_RECURRING.recurring, "recurring_every_so_many_days")}
            isRequired
          />
        </div>
      )}
      {payload.recurring?.frequency?.label === "Weekly" && (
        <Dropdown
          containerClassName="tw-flex-1"
          value={payload.recurring?.recurring_day_of_week}
          onChange={(e) => handlr(e, CONTINGENT_RECURRING.recurring, "recurring_day_of_week", true)}
          options={RECURRING_DAY_OF_WEEK}
          label="Day Of Week"
          menuShouldComeToFront
        />
      )}
    </div>
  );
};

ModalAddEditRecurringElements.propTypes = {
  payloadHandlrs: func,
  payload: payloadPropTypes,
};

ModalAddEditRecurringElements.defaultProps = {
  payloadHandlrs: null,
  payload: PAYLOAD_DEFAULT,
};

export default ModalAddEditRecurringElements;
