import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_MARKETING = "tdpMarketing";
const wrap = curriedNamespaceAction(REDUCER_TDP_MARKETING);

export const saveAllAdvertisingPlan = (advertisingplans) =>
  wrap({ type: "SAVE_ALL_ADVERTISING_PLAN", advertisingplans });

export const saveAllAvailablePlan = (availablePlan) =>
  wrap({ type: "SAVE_ALL_AVAILABLE_PLAN", availablePlan });

export const saveAllAdsitesPlan = (adsites) => wrap({ type: "SAVE_ALL_AD_SITES", adsites });

export const saveAllListingViews = (listingViews) => wrap({ type: "SAVE_ALL_LISTING_VIEWS", listingViews });

export const saveAllAssetsCardViews = (assetsCardViews) =>
  wrap({ type: "SAVE_ALL_ASSETS_CARD_VIEWS", assetsCardViews });

export const getListingFromSearch = (homelisting) => wrap({ type: "GET_LISTING_FROM_SEARCH", homelisting });

export const saveImportedListing = (importedListing) =>
  wrap({ type: "SAVE_IMPORTED_LISTING", importedListing });

export const updateMLS = (mlsID) => wrap({ type: "UPDATE_MLS_ID", mlsID });

export const saveViewReportURL = (viewReportURL) => wrap({ type: "UPDATE_VIEW_REPORT_URL", viewReportURL });

export const fetchAutoCompleteFailure = (autoCompleteError) =>
  wrap({
    type: "FETCH_AUTOCOMPLETE_FAILURE",
    autoCompleteError,
  });

export const importSearchListingFailure = (importSearchError) =>
  wrap({
    type: "IMPORT_SEARCH_FAILURE",
    importSearchError,
  });

export const fetchAssetsCardFailure = (getAssetCardError) =>
  wrap({
    type: "GET_ASSETS_CARD_FAILURE",
    getAssetCardError,
  });

export const fetchEditListingURLFailure = (getEditListingURLError) =>
  wrap({
    type: "GET_EDIT_LISTING_URL_FAILURE",
    getEditListingURLError,
  });

export const fetchMetricsViewFailure = (getMetricsError) =>
  wrap({
    type: "FETCH_METRICS_FAILURE",
    getMetricsError,
  });

export const fetchAdvertisingPlanFailure = (getAdvertisingPlanError) =>
  wrap({
    type: "FETCH_ADVERTISING_PLAN_FAILURE",
    getAdvertisingPlanError,
  });

export const fetchAvailablePlanFailure = (getAvailablePlanError) =>
  wrap({
    type: "FETCH_AVAILABLE_PLAN_FAILURE",
    getAvailablePlanError,
  });

export const fetchAdSiteFailure = (submitAdSiteError) =>
  wrap({
    type: "SUBMIT_AD_SITE_FAILURE",
    submitAdSiteError,
  });

export const fetchManagePlanFailure = (getManagePlanError) =>
  wrap({
    type: "FETCH_MANAGE_PLAN_FAILURE",
    getManagePlanError,
  });

export const destroyPlanFailure = (getDeletePlanError) =>
  wrap({
    type: "DELETE_PLAN_FAILURE",
    getDeletePlanError,
  });

export const loadData = (isLoading) =>
  wrap({
    type: "FETCH_FOR_DATA",
    isLoading,
  });

export const loadMarketingData = (isMarketingLoading) =>
  wrap({
    type: "FETCH_FOR_MARKETING_DATA",
    isMarketingLoading,
  });

export const loadMlsId = ({ loaded, mlsID = null, mlsNumber = null }) =>
  wrap({
    type: "FETCH_FOR_MLS_ID",
    loaded,
    mlsID,
    mlsNumber,
  });

export const setSearchKeyword = (search) => wrap({ type: "SAVE_SEARCH_KEYWORD", search });

export const setIsSearched = (isSearched) => wrap({ type: "SAVE_IS_SEARCH", isSearched });
