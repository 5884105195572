import React from "react";
import { string, func, node } from "prop-types";
import Button from "../../../../shared/v2/Button";
import Tooltip from "../../../../shared/v2/Tooltip";

const TooltipBtn = ({ children, onClick, activeButton, hoverText, dataCy }) => (
  <Button
    data-cy={dataCy}
    schema={activeButton === hoverText ? "primary" : "tertiary"}
    onClick={onClick}
    className="tw-flex tw-items-center tw-w-[52px] tw-justify-center"
  >
    {children}
  </Button>
);

const FilterButton = ({ children, onClick, activeButton, hoverText, dataCy }) =>
  hoverText !== "All" ? (
    <div className="tw-flex tw-justify-center">
      <Tooltip
        innerClassName="!tw-bg-neutral-gray-75"
        arrowClassName="before:!tw-bg-neutral-gray-75"
        trigger={
          <TooltipBtn onClick={onClick} activeButton={activeButton} hoverText={hoverText} dataCy={dataCy}>
            {children}
          </TooltipBtn>
        }
        content={hoverText}
        placement="top"
      />
    </div>
  ) : (
    <div className="tw-flex tw-justify-center">
      <TooltipBtn onClick={onClick} activeButton={activeButton} hoverText={hoverText} dataCy={dataCy}>
        {children}
      </TooltipBtn>
    </div>
  );

TooltipBtn.propTypes = {
  children: node,
  onClick: func,
  activeButton: string,
  hoverText: string,
  dataCy: string,
};

TooltipBtn.defaultProps = {
  children: null,
  onClick: () => {},
  activeButton: null,
  hoverText: null,
  dataCy: null,
};

FilterButton.propTypes = {
  children: node,
  onClick: func,
  activeButton: string,
  hoverText: string,
  dataCy: string,
};

FilterButton.defaultProps = {
  children: null,
  onClick: () => {},
  activeButton: null,
  hoverText: null,
  dataCy: null,
};

export default FilterButton;
