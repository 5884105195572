import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import CalendarDay from "./CalendarDay";

import { getWeeksInMonth } from "./utils/helpers";
import { WEEKDAYS } from "./utils/constants";

const getWeekHeader = () => {
  const weekHeaders = [];

  WEEKDAYS.forEach((i) => {
    weekHeaders.push(moment().weekday(i).format("dd"));
  });

  return weekHeaders;
};

const renderWeekHeader = () => {
  const weekHeader = getWeekHeader();
  const header = weekHeader.map((day) => (
    <div
      className="tw-pb-8px tw-font-semibold tw-w-32px tw-text-center tw-text-theme-dateselector-text-color"
      key={day}
    >
      {day}
    </div>
  ));

  return <div className="tw-flex tw-flex-row tw-justify-around">{header}</div>;
};

const isDisabled = (day, minDate, maxDate) => {
  const isBeforeMinDate = minDate && day.isBefore(moment(minDate));
  const isAfterMaxDate = maxDate && day.isAfter(moment(maxDate));
  return isBeforeMinDate || isAfterMaxDate;
};

const CalendarMonth = ({ temporaryDate, navigationDate, handleDayClick, minDate, maxDate }) => {
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    setWeeks(getWeeksInMonth(navigationDate));
  }, [navigationDate]);

  return (
    <div className="tw-flex tw-flex-col">
      {renderWeekHeader()}
      <table>
        <tbody>
          {weeks.map((week) => (
            <tr key={week}>
              {week.map((day) => (
                <CalendarDay
                  key={day}
                  handleDayClick={handleDayClick}
                  temporaryDate={temporaryDate}
                  navigationDate={navigationDate}
                  day={day}
                  disabled={isDisabled(day, minDate, maxDate)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CalendarMonth.propTypes = {
  temporaryDate: PropTypes.instanceOf(moment),
  navigationDate: PropTypes.instanceOf(moment),
  handleDayClick: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
};

CalendarMonth.defaultProps = {
  temporaryDate: moment(),
  navigationDate: moment(),
  handleDayClick: () => {},
  minDate: null,
  maxDate: null,
};

export default CalendarMonth;
