import React, { forwardRef } from "react";
import PropTypes from "prop-types";

export const Section = forwardRef(({ children, className }, ref) => (
  <div
    className={`tw-relative tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 tw-p-[24px] bs-xs:tw-p-[16px] tw-flex tw-flex-col ${className}`}
    ref={ref}
  >
    {children}
  </div>
));

Section.defaultProps = {
  className: "",
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const SectionLabel = ({ className, children }) => (
  <h3 className={`tw-text-18d tw-font-bold tw-my-0 ${className}`}>{children}</h3>
);

SectionLabel.defaultProps = {
  className: "",
};

SectionLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const Card = ({ children, className }) => (
  <div
    className={`
      tw-shadow
      tw-bg-white
      tw-flex
      tw-items-center
      tw-rounded-md
      ${className}
    `}
  >
    {children}
  </div>
);

Card.defaultProps = {
  className: "",
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
