/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ThumbtackRegularV6 = (props) => (
  <Icon {...props} name="Thumbtack Regular V6">
    <path d="M7.25 11H3a.997.997 0 0 1-.97-1.243l.195-.778a5.241 5.241 0 0 1 2.148-3.075l.339-4.403H3.75a.75.75 0 1 1 0-1.5h8.5a.749.749 0 1 1 0 1.5h-.962l.341 4.403a5.244 5.244 0 0 1 2.147 3.075l.194.778A.999.999 0 0 1 13.001 11h-4.25v4.25c0 .416-.334.75-.778.75a.767.767 0 0 1-.75-.75L7.251 11zM5.813 6.741l-.597.406a3.724 3.724 0 0 0-1.535 2.194l-.04.159h3.61V6.75c0-.416.334-.75.75-.75s.75.334.75.75V9.5h3.609l-.041-.159c-.228-.922-.816-1.691-1.534-2.194l-.625-.406L9.785 1.5H6.216l-.403 5.241z" />
  </Icon>
);

export default ThumbtackRegularV6;
