import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "../../shared/v2/Avatar";
import * as creators from "../actions/creators";

const ResultRow = ({
  searchTab,
  fullName,
  avatar,
  account: { brokerageAffiliation, teamName, address, phone, distance },
  setDetailsId,
  uuid,
}) => (
  <tr className="tw-border-b tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-neutral-gray-10 tw-h-[56px]">
    <td>
      <button
        type="button"
        className="tw-bg-transparent tw-border-0 tw-flex tw-items-center hover:tw-underline"
        onClick={() => setDetailsId(uuid)}
      >
        <Avatar size="medium" className="tw-inline-flex tw-mr-4" alt={fullName} src={avatar} />
        {fullName}
      </button>
    </td>
    <td>{teamName || "—"}</td>
    <td>{brokerageAffiliation || "—"}</td>
    {searchTab === "google_place" && <td>{`${distance || 0} miles`}</td>}
    <td>{address?.city || "—"}</td>
    <td>{address?.locality || "—"}</td>
    <td>{phone || "—"}</td>
  </tr>
);

ResultRow.propTypes = {
  searchTab: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  setDetailsId: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  account: PropTypes.shape.isRequired,
};

export default connect(null, creators)(ResultRow);
