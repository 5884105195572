import React from "react";
import { string, func, bool } from "prop-types";

import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";
import EditUserBtn from "./EditUserBtn";
import AdminUserBtn from "./AdminUserBtn";

const UserActions = ({ userId, isActive, agentType, activeHandlr, suspendHandlr, suspended }) => {
  const currentUser = useCurrentUser();

  if (currentUser.person.id === userId) {
    // If the current user's ID matches the row's user ID, render edit btn.
    return <EditUserBtn userId={userId} />;
  }

  if (!currentUser.account.isAdmin) {
    // If the current user is not an admin, don't render any btn to click for row
    return null;
  }

  if (currentUser.person.userType === "Administrator" && agentType === "Account Owner") {
    // If the current user is an administrator and the row's agent type is 'Account Owner',
    // render the <EditUserBtn> component with the provided user ID, but disable it.
    return <EditUserBtn userId={userId} disabled />;
  }

  // If none of the above conditions are met,
  // render the <AdminUserBtn> component with full suspend/archive privileages.
  return (
    <AdminUserBtn
      isActive={isActive}
      userId={userId}
      agentType={agentType}
      activeHandlr={activeHandlr}
      suspendHandlr={suspendHandlr}
      suspended={suspended}
    />
  );
};

export default UserActions;

UserActions.propTypes = {
  isActive: bool.isRequired,
  userId: string.isRequired,
  agentType: string.isRequired,
  activeHandlr: func.isRequired,
  suspendHandlr: func.isRequired,
  suspended: bool.isRequired,
};
