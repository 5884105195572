import React from "react";

const EventsHeaders = () => (
  <div className="tw-mt-[21px] tw-flex tw-h-[35px] tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-1 tw-border-solid tw-border-neutral-gray-10">
    <div className="tw-font-bs-sans tw-font-semibold tw-text-14px tw-text-gray-50">Details</div>
    <div className="tw-font-bs-sans tw-font-semibold tw-text-14px tw-text-gray-50 tw-ml-auto">Visibility</div>
    <div className="tw-font-bs-sans tw-font-semibold tw-text-14px tw-text-gray-50 tw-ml-[100px]">Time</div>
  </div>
);

export default EventsHeaders;
