import React, { useState, useEffect } from "react";
import _ from "lodash";
import { bool, func, string } from "prop-types";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import ErrorBanner from "@shared/v2/ErrorBanner";
import Modal from "@shared/v2/Modal";
import RadioButton from "@shared/v2/RadioButton";
import TextInput from "@shared/v2/TextInput";
import states from "../TransactionDetailsPage/Header/Summary/EditTitleModal/states";
import { getAllListingInfoFromApi, putDetailsToApi } from "../TransactionDetailsPage/Header/api/apiWrappers";
import { tryCatchHandlr } from "../shared/Utilities";

const UpdatePipelineStatusModal = ({
  isModalOpen,
  closeModal,
  setSubmitted,
  uuid,
  onSubmit,
  revertStatuscloseModal,
}) => {
  const [existingAddressAttributes, setExistingAddressAttributes] = useState({});
  const [addressId, setAddressId] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [customTitle, setCustomTitle] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [error, setError] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [togglecustomTitle, setToggleCustomTitle] = useState(false);
  const [details, setDetails] = useState([]);

  const getAllListingInfo = async () => {
    const req = getAllListingInfoFromApi(uuid);
    const [res, err] = await tryCatchHandlr(req);

    if (err) {
      setError(err.response.data?.statusText);
      return;
    }
    setStreetAddress("");
    setExistingAddressAttributes(res.data.address);
    setAddressId(res.data.address?.id);
    setCity(res.data.address?.city);
    setCustomTitle(res.data?.title);
    setLocality(res.data.address?.locality);
    setPostalCode(res.data.address?.postal_code);
  };
  const setDefault = () => {
    setError("");
    setExistingAddressAttributes({});
    setAddressId("");
    setStreetAddress("");
    setCity("");
    setCustomTitle("");
    setLocality("");
    setPostalCode("");
    setSaveButtonDisabled(false);
    setToggleCustomTitle(false);
    setDetails([]);
  };
  const closeAddressModal = () => {
    setDefault();
    revertStatuscloseModal();
  };

  const putAllListingInfo = async () => {
    if (!_.isEqual(existingAddressAttributes, details.value)) {
      const req = putDetailsToApi({ uuid, details });
      const [, err] = await tryCatchHandlr(req);

      if (err) {
        setError(err.response.data?.statusText);
        return;
      }
    }

    setSubmitted(true);
    setDefault();
    closeModal(false);
  };

  useEffect(() => {
    (async () => {
      if (uuid.length) await getAllListingInfo();
    })();
  }, [uuid]);

  useEffect(() => {
    setToggleCustomTitle(false);
    setStreetAddress("");
  }, [isModalOpen]);

  useEffect(() => {
    if (togglecustomTitle) {
      const filteredDetails = details.filter((item) => item.key !== "use_custom_title");
      const updatedDetails = [
        ...filteredDetails,
        { key: "title", value: customTitle, group: "transactionDetails" },
      ];
      setDetails(updatedDetails);
    } else {
      const filteredDetails = details.filter((item) => item.key !== "title");
      const updatedDetails = [
        ...filteredDetails,
        { key: "use_custom_title", value: togglecustomTitle, group: "transactionDetails" },
      ];
      setDetails(updatedDetails);
    }
  }, [togglecustomTitle]);

  useEffect(() => {
    setSaveButtonDisabled(!streetAddress || !streetAddress.length);
  }, [streetAddress]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedDetails = [
      ...details,
      {
        key: "address_attributes",
        value: {
          id: addressId,
          street_address: streetAddress,
          city,
          locality,
          postal_code: postalCode,
        },
        group: "propertyDetails",
      },
    ];
    setDetails(updatedDetails);
    const submitHandlr = onSubmit ? () => onSubmit(updatedDetails) : () => putAllListingInfo();

    if (!_.isEqual(existingAddressAttributes, updatedDetails.value)) await submitHandlr();
  };

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
      show={isModalOpen}
      onHide={closeAddressModal}
    >
      <Modal.Header
        title="Update Status From Pipeline"
        description="A property address is required to change a transaction’s status from Pipeline."
        closeButton
      />
      <Modal.Body>
        {error && <ErrorBanner errors={[error]} />}
        <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
          Street Address <span className="tw-text-coral">*</span>
        </div>
        <TextInput
          id="address"
          value={streetAddress || ""}
          maxLength={75}
          error={streetAddress && streetAddress.length >= 75 ? "Max characters: 75" : ""}
          onChange={(e) => setStreetAddress(e.target.value)}
          data-cy="header-edit-title-modal-address-input"
          className="tw-mb-24px tw-rounded-[4px]"
        />
        <div className="tw-grid tw-grid-cols-4">
          <div className="tw-col-span-2 tw-mr-[12px]">
            <label
              htmlFor="city"
              className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
            >
              City
            </label>
            <TextInput
              id="city"
              value={city || ""}
              onChange={(e) => setCity(e.target.value)}
              data-cy="header-edit-title-modal-city-input"
              className="tw-rounded-[4px]"
            />
          </div>
          <div className="tw-mr-[6px] tw-ml-12px">
            <label
              htmlFor="states"
              className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
            >
              State / Province
            </label>
            <Dropdown
              id="states"
              value={{ value: locality || "", label: locality || "" }}
              onChange={(e) => setLocality(e.value)}
              options={states}
              className="tw-bg-gray-10 tw-rounded-[4px]"
              isSearchable
              data-cy="header-edit-title-modal-state-select"
              placeholder="Select"
              isPipeline
            />
          </div>
          <div className="tw-ml-6px">
            <label
              htmlFor="postal_code"
              className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
            >
              ZIP / Postal Code
            </label>
            <TextInput
              id="postal_code"
              value={postalCode || ""}
              onChange={(e) => setPostalCode(e.target.value)}
              data-cy="header-edit-title-modal-zip-input"
              className="tw-rounded-[4px]"
            />
          </div>
        </div>
        <div className="tw-pt-[24px] tw-grid tw-grid-cols-3">
          <div>
            <RadioButton
              label="Use Address as Title"
              name="address_as_title"
              id="address_as_title"
              checked={!togglecustomTitle}
              value=""
              onChange={() => setToggleCustomTitle(!togglecustomTitle)}
              data-cy="header-edit-title-modal-use-address-title-radio"
            />
          </div>
          <div className="tw-col-span-2">
            <RadioButton
              label="Continue using custom title"
              name="custom_title"
              id="address.id"
              checked={togglecustomTitle}
              value=""
              onChange={() => setToggleCustomTitle(!togglecustomTitle)}
              data-cy="header-edit-title-modal-use-custom-title-radio"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-items-center">
        <Button
          schema="tertiary"
          size="medium"
          onClick={closeAddressModal}
          data-cy="header-edit-title-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          disabled={saveButtonDisabled}
          schema="primary"
          size="medium"
          onClick={(e) => handleSubmit(e)}
          data-cy="header-edit-title-modal-save-button"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpdatePipelineStatusModal.propTypes = {
  isModalOpen: bool.isRequired,
  closeModal: func.isRequired,
  uuid: string.isRequired,
  onSubmit: func,
  setSubmitted: func,
  revertStatuscloseModal: func.isRequired,
};

UpdatePipelineStatusModal.defaultProps = {
  setSubmitted: () => {},
  onSubmit: () => {},
};

export default UpdatePipelineStatusModal;
