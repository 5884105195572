/* eslint-disable no-underscore-dangle, no-bitwise */
import queryString from "query-string";
import toUSString from "./Currency";

const _pipe = (a, b) => (arg) => b(a(arg));
export const pipe = (...ops) => ops.reduce(_pipe);
export const adjustSeparator = (valueString) => valueString.replace(/,/g, "");
export const numberify = (valueString) => Number(valueString);
export const round = (number) => Math.round(number);

export const formatNumber = (value) => pipe(adjustSeparator, numberify, round)(value);
export const formatNumberRaw = (value) => pipe(adjustSeparator, Number)(value);
export const formatUSD = (value) => pipe(formatNumber, toUSString)(value);
export const formatUSDDecimal = (value) => pipe(formatNumberRaw, toUSString)(value);
export const formatDecimal = (value) => pipe(adjustSeparator, numberify)(value);
export const usdOrEmpty = (value) => (formatUSD(value) === "$NaN" ? "" : `${formatUSD(value)}`);
export const deciamlUsdOrEmpty = (value) =>
  formatUSDDecimal(value) === "$NaN" ? "" : `${formatUSDDecimal(value)}`;
export const numberOrEmpty = (value) => (Number.isNaN(value) ? "" : `${formatNumber(value)}`);
export const percentageOrEmpty = (value) => (Number.isNaN(value) ? "" : `${formatDecimal(value)}%`);

export const getRandomNumber = (min, max) => {
  const cryptoObj = window.crypto || window.msCrypto; // Browser compatibility
  const randomBytes = new Uint32Array(1);
  cryptoObj.getRandomValues(randomBytes);
  const randomNumber = randomBytes[0];
  if (min || max) {
    return min + (randomNumber % (max - min + 1));
  }
  return randomNumber;
};

export const uuidv4 = () => {
  let d = new Date().getTime(); // Timestamp
  let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = getRandomNumber(0, 16); // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const sortBy = (array, key, inverse = false) =>
  [...array].sort((a, b) => {
    if (inverse) {
      return b[key] - a[key];
    }
    return a[key] - b[key];
  });
export const titleize = (string) =>
  string
    // insert a space before all caps
    .replace(/([A-Z])/g, " $1")
    // uppercase the first character
    .replace(/^./, (str) => str.toUpperCase());

export const newUrlBuilder = (filters, currentUrl) => {
  const { origin } = new URL(currentUrl);
  const basePath = "listings";
  const singleStatus = filters.status.length === 1;
  const queryFilters = singleStatus ? { ...filters, status: [] } : filters;
  const pathStatus = singleStatus ? filters.status[0].replace(" ", "_").replace("draft", "drafts") : "";

  const targetUrl = [origin, basePath, pathStatus].join("/");
  const newQuery = queryString.stringify(queryFilters, { arrayFormat: "bracket" });

  return `${targetUrl}?${newQuery}`;
};

export const intFormatUSD = (valueString) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(valueString);
export const pureFormatUSD = (valueString) => intFormatUSD(valueString).slice(1);
export const range = (smaller, greater) =>
  Array(greater - smaller + 1)
    .fill(smaller)
    .map((n, i) => n + i);

const inRange = (min, max, item) => item >= min && item <= max;
const paginationLeftBlock = (currentPage, maxEdgeSize, maxDistance) =>
  range(1, maxEdgeSize).filter((item) => item < currentPage - maxDistance + 1 && item > 0);
const paginationCentralBlock = (currentPage, maxDistance, totalPages) =>
  range(currentPage - maxDistance, currentPage + maxDistance).filter((item) => inRange(1, totalPages, item));
const paginationRightBlock = (currentPage, maxEdgeSize, maxDistance, totalPages) =>
  range(totalPages - maxEdgeSize + 1, totalPages).filter(
    (item) => item > currentPage - maxDistance && item <= totalPages,
  );
const paginationBlocks = (currentPage, maxEdgeSize, maxDistance, totalPages) => [
  paginationLeftBlock(currentPage, maxEdgeSize, maxDistance),
  paginationCentralBlock(currentPage, maxDistance, totalPages),
  paginationRightBlock(currentPage, maxEdgeSize, maxDistance, totalPages),
];

const inSequence = (smaller, greater, step = 1) => smaller === greater - step;
const uniqueArray = (array) => Array.from(new Set(array));

export const paginationOptions = ({ totalPages, currentPage, maxDistance = 4, maxEdgeSize = 2 }) =>
  uniqueArray(paginationBlocks(currentPage, maxEdgeSize, maxDistance, totalPages).flat());

export const paginationOptionsWithExplicitGaps = ({
  totalPages,
  currentPage,
  maxDistance = 4,
  maxEdgeSize = 2,
}) =>
  paginationOptions({ totalPages, currentPage, maxDistance, maxEdgeSize })
    .map((item, index, array) => (inSequence(array[index - 1] || 0, item) ? item : [null, item]))
    .flat();

export const reorderNotesBasedOnStarred = (notes) => {
  const starredNotes = notes.filter((note) => note.starred);
  const unstarredNotes = notes.filter((note) => !note.starred);
  const sortedStarredNotes = starredNotes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const sortedUnstarredNotes = unstarredNotes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  return [...sortedStarredNotes, ...sortedUnstarredNotes];
};

const baseFieldsToLock = [
  "dom",
  "lastUpdated",
  "newMilestones",
  "photo",
  "statusChangedAt",
  "mutualAcceptanceDate",
];

const fieldsToLock = {
  default: new Set([...baseFieldsToLock]),
  buyer: new Set([...baseFieldsToLock, "dateExpired", "expiration"]),
  referral: new Set([
    ...baseFieldsToLock,
    "closePrice",
    "dateListed",
    "listingPrice",
    "mls",
    "mlsNumber",
    "price",
  ]),
};

const financialsFields = new Set(["closePrice", "gci"]);

export const isFinancial = (property) => financialsFields.has(property);

const pipelineFields = new Set(["closePrice", "gci"]);

export const isEditableAt = (transaction, property, canEditFinancials = false) => {
  const columns = fieldsToLock[transaction.type] || fieldsToLock.default;
  if (columns.has(property)) {
    return false;
  }

  if (transaction.status === "pipeline") {
    return !pipelineFields.has(property);
  }

  return !isFinancial(property) || canEditFinancials;
};
