/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentXmarkRegularV6 = (props) => (
  <Icon {...props} name="Comment Xmark Regular V6">
    <path d="M8 .972c-4.419 0-7.972 2.91-7.972 6.5 0 1.488.622 2.852 1.653 3.947-.465 1.234-1.433 2.278-1.449 2.289a.751.751 0 0 0-.144.813c.094.302.361.48.662.48 1.922 0 3.409-.805 4.347-1.445.902.281 1.88.445 2.903.445 4.419 0 7.972-2.91 7.972-6.5S12.419.973 8 .973zM8 12.5a8.1 8.1 0 0 1-2.449-.379l-.711-.223-.609.43a6.673 6.673 0 0 1-1.797.906c.23-.379.449-.805.621-1.258l.332-.875-.644-.684c-.562-.602-1.241-1.596-1.241-2.918 0-2.758 2.914-5 6.472-5 3.584 0 6.472 2.242 6.472 5s-2.858 5-6.446 5zm2.503-7.531a.75.75 0 0 0-1.061 0l-1.47 1.47-1.47-1.47A.75.75 0 1 0 5.441 6.03l1.498 1.471L5.467 8.97a.75.75 0 1 0 1.061 1.061l1.47-1.47 1.47 1.47a.75.75 0 1 0 1.061-1.061l-1.47-1.469 1.47-1.47a.73.73 0 0 0-.026-1.062z" />
  </Icon>
);

export default CommentXmarkRegularV6;
