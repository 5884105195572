import React from "react";

const MlsListingCell = ({ textAlign, children, isFirstRow }) => {
  const textAlignClass = textAlign || "";
  const bottomBorder = `tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0 ${
    isFirstRow ? "tw-border-t" : "tw-border-t-0"
  }`;

  if (textAlignClass === "") {
    return (
      <td className={`tw-relative tw-px-20px tw-whitespace-nowrap ${bottomBorder}`}>
        <div className="tw-flex tw-flex-row tw-justify-start tw-items-center">{children}</div>
      </td>
    );
  }

  return (
    <td className={`tw-relative tw-px-20px tw-whitespace-nowrap ${bottomBorder} ${textAlignClass}`}>
      {children}
    </td>
  );
};

export default MlsListingCell;
