import { tryCatchHandlr } from "../../../../shared/Utilities";
import {
  deleteFeedbackFromApi,
  editFeedbackFromApi,
  getAllFeedbacksFromApi,
  newFeedbackFromApi,
} from "../api/apiWrappers";
import {
  fetchAllFeedbacksSuccess,
  fetchAllFeedbacksFailure,
  fetchAllFeedbacksStarted,
  setFeedbackFormData,
  createFeedbackStarted,
  createFeedbackSuccess,
  createFeedbackFailure,
  updateFeedbackStarted,
  updateFeedbackFailure,
  updateFeedbackSuccess,
  destroyFeedbackSuccess,
  destroyFeedbackStarted,
  destroyFeedbackFailure,
  setDeleteFeedbackPromptData,
  changeFeedbackVisibilityStarted,
  changeFeedbackVisibilityFailure,
  changeFeedbackVisibilitySuccess,
  clearChangeFeedbackVisibilityFailure,
  updateAllFeedbacksStats,
  loadMoreFeedbacksStarted,
  loadMoreFeedbacksFailure,
  loadMoreFeedbacksSuccess,
} from "./creators";
import { adaptGetAllFeedbacks, adaptFeedback, feedbackToApi, adaptError } from "../api/apiAdapters";

const hideFeedbackFormData = {
  action: "new",
  data: null,
  isVisible: false,
  error: null,
  isLoading: false,
};

const hideDeleteFeedbackPromptData = { data: null, isVisible: false, error: null, isLoading: false };

export const getAllFeedbacks = (uuid) => async (dispatch) => {
  dispatch(fetchAllFeedbacksStarted());

  const req = getAllFeedbacksFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(fetchAllFeedbacksFailure(adaptError(err)));
    return;
  }

  const { feedbacks, meta } = adaptGetAllFeedbacks(res.data);

  dispatch(fetchAllFeedbacksSuccess(feedbacks, meta));
};

export const loadMoreFeedbacks = (uuid, page) => async (dispatch) => {
  dispatch(loadMoreFeedbacksStarted());

  const req = getAllFeedbacksFromApi(uuid, page);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadMoreFeedbacksFailure(adaptError(err)));
    return;
  }

  const { feedbacks, meta } = adaptGetAllFeedbacks(res.data);

  dispatch(loadMoreFeedbacksSuccess(feedbacks, meta));
};

const updateShowingFeedbackStats = (uuid) => async (dispatch) => {
  try {
    // TODO: implement an endpoint to fetch only the showing feedback stats
    const page = 1;
    const perPage = 1; // Request only one item per page to reduce the response payload
    const res = await getAllFeedbacksFromApi(uuid, page, perPage);

    const { meta } = adaptGetAllFeedbacks(res.data);

    dispatch(updateAllFeedbacksStats(meta));
  } catch (_err) {
    // Just ignore this error since this thunk should silently update the showing feedback stats
  }
};

export const showFeedbackForm = (formData) => (dispatch) => {
  dispatch(setFeedbackFormData(formData));
};

export const hideFeedbackForm = () => (dispatch) => {
  dispatch(setFeedbackFormData(hideFeedbackFormData));
};

export const newFeedback = (uuid, feedback) => async (dispatch) => {
  dispatch(createFeedbackStarted());

  const req = newFeedbackFromApi(uuid, feedbackToApi(feedback));
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(createFeedbackFailure(adaptError(err)));
    return;
  }

  dispatch(createFeedbackSuccess(adaptFeedback(res.data)));
  // Hide feedback form
  dispatch(setFeedbackFormData(hideFeedbackFormData));

  dispatch(updateShowingFeedbackStats(uuid));
};

export const editFeedback = (uuid, feedback) => async (dispatch) => {
  dispatch(updateFeedbackStarted());

  const req = editFeedbackFromApi(uuid, feedback.id, feedbackToApi(feedback));
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(updateFeedbackFailure(adaptError(err)));
    return;
  }

  dispatch(updateFeedbackSuccess(adaptFeedback(res.data)));
  // Hide feedback form
  dispatch(setFeedbackFormData(hideFeedbackFormData));

  dispatch(updateShowingFeedbackStats(uuid));
};

export const changeFeedbackVisibility = (uuid, feedbackId, externallyVisible) => async (dispatch) => {
  dispatch(changeFeedbackVisibilityStarted(feedbackId));

  try {
    await editFeedbackFromApi(uuid, feedbackId, feedbackToApi({ externallyVisible }));

    dispatch(changeFeedbackVisibilitySuccess(feedbackId, externallyVisible));
  } catch (err) {
    dispatch(changeFeedbackVisibilityFailure(feedbackId, adaptError(err)));
  }
};

export const clearChangeFeedbackVisibilityError = (feedbackId) => (dispatch) => {
  dispatch(clearChangeFeedbackVisibilityFailure(feedbackId));
};

export const showDeleteFeedbackPrompt = (promptData) => (dispatch) => {
  dispatch(setDeleteFeedbackPromptData(promptData));
};

export const hideDeleteFeedbackPrompt = (formData) => (dispatch) => {
  dispatch(setDeleteFeedbackPromptData(hideDeleteFeedbackPromptData));
  dispatch(setFeedbackFormData(formData));
};

const updateCurrentPageAndShowingFeedbackStats = (uuid, page) => async (dispatch) => {
  try {
    const res = await getAllFeedbacksFromApi(uuid, page);

    const { feedbacks, meta } = adaptGetAllFeedbacks(res.data);

    dispatch(loadMoreFeedbacksSuccess(feedbacks, meta));
  } catch (_err) {
    // Just ignore this error since this thunk should silently update the current page and showing
    // feedback stats
  }
};

export const deleteFeedback = (uuid, feedbackId, page) => async (dispatch) => {
  dispatch(destroyFeedbackStarted());

  try {
    await deleteFeedbackFromApi(uuid, feedbackId);

    dispatch(destroyFeedbackSuccess(feedbackId));
    // Hide delete prompt
    dispatch(setDeleteFeedbackPromptData(hideDeleteFeedbackPromptData));
    // Hide feedback form
    dispatch(setFeedbackFormData(hideFeedbackFormData));

    // Reload the current page since after deletion the user view from the current page becomes
    // invalid, and the next page might miss items
    dispatch(updateCurrentPageAndShowingFeedbackStats(uuid, page));
  } catch (err) {
    dispatch(destroyFeedbackFailure(adaptError(err)));
  }
};
