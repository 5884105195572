/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const DropletSolidV6 = (props) => (
  <Icon {...props} name="Droplet Solid V6">
    <path d="M2.5 9.972c0-2.287 3.197-7.177 4.716-9.369a.948.948 0 0 1 1.569 0c1.519 2.192 4.716 7.081 4.716 9.369 0 3.066-2.463 5.528-5.5 5.528s-5.5-2.463-5.5-5.528zm3 0a.52.52 0 0 0-.5-.5.52.52 0 0 0-.5.5A3.504 3.504 0 0 0 8 13.5c.275 0 .5-.225.5-.5s-.225-.5-.5-.5a2.506 2.506 0 0 1-2.5-2.528z" />
  </Icon>
);

export default DropletSolidV6;
