import React, { useState } from "react";
import Proptypes from "prop-types";
import Button from "../../../../shared/v2/Button";
import PlanModal from "../PlanModal";

const PlansOrSites = ({ planId, name, sharedCount, totalCount }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className="tw-pb-[24px]" data-cy="marketing-advertising-plan">
        <div className="tw-border-gray-10 tw-border tw-border-solid tw-rounded-lg">
          <div className="tw-p-[16px]">
            <div
              className="tw-text-gray-75 tw-pb-[10px] tw-text-[14px] tw-leading-5 tw-font-semibold"
              data-cy="marketing-advertising-plan-name"
            >
              {name}
            </div>
            <div
              className="tw-text-gray-75 tw-pb-[10px] tw-text-[14px] tw-leading-5 tw-font-normal"
              data-cy="marketing-advertising-plan-sites-count"
            >
              {`${sharedCount} of ${totalCount} sites shared with client`}
            </div>
            <Button
              onClick={() => setModalOpen(true)}
              className="tw-flex tw-items-center"
              schema="secondary"
              size="small"
              data-cy="marketing-advertising-plan-manage-button"
            >
              Manage
            </Button>
          </div>
        </div>
      </div>
      <PlanModal planId={planId} modalOpen={modalOpen} closeModal={closeModal} />
    </>
  );
};

PlansOrSites.propTypes = {
  name: Proptypes.string,
  sharedCount: Proptypes.number,
  totalCount: Proptypes.number,
  planId: Proptypes.number,
};
PlansOrSites.defaultProps = {
  name: null,
  sharedCount: 0,
  totalCount: 0,
  planId: null,
};

export default PlansOrSites;
