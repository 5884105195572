/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const XmarkRegularV6 = (props) => (
  <Icon {...props} name="Xmark Regular V6">
    <path d="M12.753 11.719a.75.75 0 1 1-1.061 1.061L8 9.06l-3.719 3.719a.75.75 0 1 1-1.061-1.061L6.94 8 3.219 4.253A.75.75 0 1 1 4.28 3.192L8 6.94l3.719-3.719a.75.75 0 1 1 1.061 1.061L9.06 7.999l3.694 3.719z" />
  </Icon>
);

export default XmarkRegularV6;
