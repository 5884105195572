/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EllipsisSolidV6 = (props) => (
  <Icon {...props} name="Ellipsis Solid V6">
    <path d="M4.75 8a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0zm5 0a1.75 1.75 0 1 1-3.501-.001A1.75 1.75 0 0 1 9.75 8zm1.5 0a1.75 1.75 0 1 1 3.501.001A1.75 1.75 0 0 1 11.25 8z" />
  </Icon>
);

export default EllipsisSolidV6;
