import React from "react";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";

const DeleteAgreementModal = ({ modalOpen, closeModal, msg, title, onDelete }) => (
  <Modal
    className="tw-flex tw-justify-center tw-items-center"
    dialogClassName="tw-w-[392px] tw-h-auto"
    contentClassName="tw-w-full tw-h-full"
    backdrop="static"
    show={modalOpen}
    onHide={closeModal}
    data-cy="delete-agreement-modal"
  >
    <Modal.Header titleClassName="tw-mb-32px tw-text-neutral-gray-75" title={title} closeButton />

    <Modal.Body className="tw-mb-32px tw-w-[392px]">
      <p>{msg}</p>
    </Modal.Body>

    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={closeModal} data-cy="documents-delete-cancel-button">
        Cancel
      </Button>
      <Button size="medium" schema="warning" onClick={onDelete} data-cy="documents-delete-submit-button">
        yes, Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

DeleteAgreementModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  onDelete: Proptypes.func,
  msg: Proptypes.string,
  title: Proptypes.string,
};
DeleteAgreementModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  onDelete: null,
  msg: "",
  title: "",
};
export default DeleteAgreementModal;
