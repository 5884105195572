import axios from "axios";

export const getAllFeedbacksFromApi = (uuid, page = 1, perPage = undefined) => {
  let queryString = `?page=${page}`;

  if (perPage) {
    queryString += `&per_page=${perPage}`;
  }

  return axios.get(`/api/v4/transactions/${uuid}/showings${queryString}`);
};

export const newFeedbackFromApi = (uuid, data) =>
  axios.post(`/api/v4/transactions/${uuid}/showings`, {
    showing: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const editFeedbackFromApi = (uuid, feedbackId, data) =>
  axios.patch(`/api/v4/transactions/${uuid}/showings/${feedbackId}`, {
    showing: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const deleteFeedbackFromApi = (uuid, feedbackId) =>
  axios.delete(`/api/v4/transactions/${uuid}/showings/${feedbackId}`, {
    data: {
      authenticity_token: ReactOnRails.authenticityToken(),
    },
  });
