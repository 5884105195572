import axios from "axios";
import { useEffect, useState } from "react";
import { get } from "lodash";
import formatter from "../../../../shared/formatters";
import getChartTextAlignment from "../../DashboardShared/helpers/getChartTextAlignment";
import colors from "../../DashboardShared/helpers/colors";
import { getBusinessDashboard } from "./api";

const EMPTY_CLOSINGS_BY_AGENT = {
  year: new Date().getFullYear(),
  agents: [],
};

const sortAgentsByOption = ({
  data: { agents } = EMPTY_CLOSINGS_BY_AGENT,
  type,
  option,
  showClosed,
  showPending,
}) => {
  if (!agents.length) return agents;

  return agents.sort((a, b) => {
    const bTotal =
      (showClosed ? b[type][option].closed || 0 : 0) + (showPending ? b[type][option].pending || 0 : 0);
    const aTotal =
      (showClosed ? a[type][option].closed || 0 : 0) + (showPending ? a[type][option].pending || 0 : 0);
    return bTotal - aTotal;
  });
};

const getColor = (option) => (context) => {
  const { datasetIndex } = context;
  const color = datasetIndex === 0 ? colors.color1.default : "#FFA826";
  const alignment = getChartTextAlignment(option === "units" ? "number" : "currency")(context);
  return alignment === "end" ? color : "#ffffff";
};

const formatData =
  ({ key, option, type }) =>
  (item, context) => {
    if (context.datasetIndex === 0) {
      const alignment = getChartTextAlignment(option === "units" ? "number" : "currency")(context);
      const otherItem = context.chart.data.datasets[1].data[context.dataIndex];
      if (alignment === "end" && otherItem[type][option].pending > 0) return "";
    }
    const value = item[type][option][key];
    if (typeof value === "undefined" || value === 0) return "";
    return option === "units" ? value : formatter.currency(value, 0);
  };

const getChartData = ({ data, type, option, showClosed, showPending }) => {
  const sortedAgents = sortAgentsByOption({ data, type, option, showClosed, showPending });
  const closedKey = `${type}.${option}.closed`;
  const pendingKey = `${type}.${option}.pending`;
  return {
    labels: sortedAgents.map((a) => a.name),
    length: sortedAgents.length > 1 ? sortedAgents.length : 2,
    datasets: [
      {
        label: "Closed",
        data: [...sortedAgents],
        backgroundColor: colors.color1.default,
        hidden: !showClosed,
        datalabels: {
          color: getColor(option),
          anchor: "end",
          align: getChartTextAlignment(option === "units" ? "number" : "currency"),
          formatter: formatData({ key: "closed", option, type }),
        },
        parsing: {
          xAxisKey: closedKey,
          yAxisKey: closedKey,
        },
      },
      {
        label: "Pending",
        data: [...sortedAgents],
        backgroundColor: "#ffa826",
        hidden: !showPending,
        datalabels: {
          color: getColor(option),
          anchor: "end",
          align: getChartTextAlignment(option === "units" ? "number" : "currency"),
          formatter: formatData({ key: "pending", option, type }),
        },
        parsing: {
          xAxisKey: pendingKey,
          yAxisKey: pendingKey,
        },
      },
    ],
  };
};

const getChartOptions = ({ count, option, year }) => ({
  indexAxis: "y",
  responsive: true,
  aspectRatio: 1 / (count / 11),
  maintainAspectRatio: true,
  layout: {
    padding: {
      right: ({ chart: { width } }) => {
        const chartMaxWidth = 512;
        // This covers a good chunk of cases but there may be some edge cases where the label is cut off
        return (width / chartMaxWidth) * 70;
      },
    },
  },
  onClick: (_, [{ datasetIndex, index } = {}], { config: { data } }) => {
    if (datasetIndex === undefined) return;
    const params = new URLSearchParams();
    const dataset = data.datasets[datasetIndex];
    const [type, , key] = (Object.values(dataset.parsing)?.[0] || "").split(".");
    const path = key === "closed" ? "sold" : key;
    const item = dataset.data[index];
    params.append("agent[]", item.personId);
    params.append("status_changed_range[]", "custom");
    params.append("status_changed_dates[]", `${year}-01-01`);
    params.append("status_changed_dates[]", `${year}-12-31`);
    if (type === "buyerSeller") {
      params.append("type[]", "buyer");
      params.append("type[]", "seller");
    } else if (type === "tenantLandlord") {
      params.append("type[]", "tenant");
      params.append("type[]", "landlord");
    } else if (type === "referrals") {
      params.append("type[]", "referral");
    }
    window.open(`/listings/${path}?${params}`);
  },
  onHover: (e, activeElements) => {
    e.native.target.style.cursor = activeElements.length ? "pointer" : "default";
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: ({ dataIndex, dataset }) => {
          const dataKey = Object.values(dataset.parsing)[0];
          const value = dataKey ? get(dataset.data[dataIndex], dataKey) : dataset.data[dataIndex];
          return `${dataset.label} ${option === "units" ? value : formatter.currency(value, 0)}`;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      border: { display: false },
      grid: { display: true, offset: false },
      position: "top",
      ticks: {
        callback: (value) => (option === "units" ? value : formatter.currency(value, 0)),
      },
    },
    y: {
      stacked: true,
      grid: { display: false },
      offset: true,
    },
  },
});

const useAgentProduction = ({ type, option, showClosed, showPending, year }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const chartData = getChartData({ data, type, option, showClosed, showPending });
  const chartOptions = getChartOptions({ count: chartData.length, option, year });

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();

    getBusinessDashboard({
      params: {
        section: "closings_by_agent",
        selectedYear: year,
      },
      signal: abortController.signal,
    })
      .then((res) => {
        setData(res.data);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [year]);

  return {
    data: chartData,
    error,
    loading,
    chartOptions,
  };
};

export default useAgentProduction;
