/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhonePlusSolidV6 = (props) => (
  <Icon {...props} name="Phone Plus Solid V6">
    <path d="m15.409 10.975-3.391-1.453a.972.972 0 0 0-1.14.28l-1.377 1.682A10.928 10.928 0 0 1 4.517 6.5l1.682-1.378a.978.978 0 0 0 .281-1.139L5.024.592A1 1 0 0 0 3.877.026L.756.753a.971.971 0 0 0-.757.951c0 7.884 6.412 14.297 14.297 14.297.458 0 .85-.312.952-.757l.727-3.15a.98.98 0 0 0-.566-1.118zm-4.687-7.253h1.528v1.5c0 .444.334.778.75.778a.72.72 0 0 0 .722-.722V3.722h1.5c.444 0 .778-.306.778-.722s-.336-.722-.722-.722h-1.556V.722C13.722.336 13.416 0 13 0s-.722.336-.722.722v1.5h-1.556A.79.79 0 0 0 10 3c0 .416.334.722.722.722z" />
  </Icon>
);

export default PhonePlusSolidV6;
