import React, { useState } from 'react';
import FormWrapper from "./form_controls/FormWrapper";
import EmailInput from "./form_controls/EmailInput";
import PasswordInput from "./form_controls/PasswordInput";
import RoleSelectInput from "./form_controls/RoleSelectInput";

const SuperUserForm = (props) => {
  const { onCancel, onSubmit, roleOptions, formErrors } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(roleOptions[0]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    onSubmit({ email, password, role });
  };

  const validate = (name) => {
    if (formErrors && formErrors[name]) {
      return "error"
    }
    return null;
  }

  return (
    <FormWrapper
      formErrors={formErrors}
      onCancel={onCancel}
      onSubmit={handleOnSubmit}
    >
      <EmailInput
        value={email}
        validationState={validate("email")} 
        onChange={(e) => setEmail(e.target.value)}
      />

      <PasswordInput
        value={password}
        validationState={validate("password")}
        onChange={(e) => setPassword(e.target.value)}
      />

      <RoleSelectInput
        value={role}
        validationState={validate('role')}
        onChange={(e) => setRole(e.target.value)}
        options={roleOptions}
      />
    </FormWrapper>
  );
}

export default SuperUserForm;
