import React from "react";

import DetailSectionHeader from "../DetailSectionHeader";
import ReferralTitle from "../ReferralTitle";
import DetailStatus from "../shared/DetailStatus";
import ReferralTransactionAmount from "../ReferralTransactionAmount";
import ReferralFee from "../ReferralFee";
import DetailSouceDropdown from "../DetailSourceDropdown";
import ListingUuid from "../ListingUuid";

const RefDetails = () => (
  <div className="tw-mb-24px">
    <DetailSectionHeader header="Referral Details" />
    <ReferralTitle />
    <DetailStatus label="Status" id="RefStatus" dataCy="RefStatus" />
    <DetailSouceDropdown />
    <ReferralTransactionAmount />
    <ReferralFee />
    <ListingUuid />
  </div>
);

export default RefDetails;
