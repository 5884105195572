import React, { useRef, useState } from "react";
import Button from "@shared/v2/Button";
import TextLink from "@shared/v2/TextLink";
import { GearSolidV6 } from "@shared/v2/Icomoon";

import { Section, SectionLabel } from "../../Reports/components/BusinessDashboard";
import Tooltip from "../../shared/v2/Tooltip";
import CircleInfoSolidV6 from "../../shared/v2/Icomoon/Icons/CircleInfoSolidV6";
import AddBrivityPhone from "./AddBrivityPhone";
import AgentRow from "./AgentRow";
import { checkForRoutingNumber } from "./services";
import Error from "../../Reports/components/DashboardShared/Error";
import TeamCallRecordingSettingsModal from "./TeamCallRecordingSettingsModal";
import { useAgentsWithPhoneUsage } from "../../reducers/layoutReducer/phoneSettingsReducer";
import {
  useAccountCallRecordingSettings,
  useCurrentUser,
  useFeatureFlags,
} from "../../reducers/layoutReducer/selectors";

const PhoneSettings = () => {
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const agentsWithPhoneUsage = useAgentsWithPhoneUsage();
  const accountCallRecordingSettings = useAccountCallRecordingSettings();
  const [agentToAddNumber, setAgentToAddNumber] = useState(null);
  const [error, setError] = useState("");
  const [isCallRecordingModalOpen, setIsCallRecordingModalOpen] = useState(false);
  const ErrorRef = useRef();

  const onManageNumberModalClosed = () => {
    setAgentToAddNumber(null);
  };

  const onNumberSaved = () => {
    setAgentToAddNumber(null);

    window.location.reload();
  };

  const addBrivityNumber = async (agent) => {
    const { error: errorOnAdd } = await checkForRoutingNumber(agent.id);

    if (errorOnAdd) {
      setError(errorOnAdd);
      ErrorRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      setAgentToAddNumber(agent);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-stretch tw-flex-wrap tw-gap-8px tw-bg-tinted-gray-50 tw-p-16px">
      <Section className="tw-p-[32px] tw-gap-8px">
        <span className="tw-text-28px tw-text-gray-75">Phone Settings</span>
        <div ref={ErrorRef}>
          <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
        </div>
        <span className="tw-text-14px">
          If you need help, visit <TextLink href="https://support.brivity.com/">Brivity Support</TextLink> to
          learn more.
        </span>
      </Section>
      <Section className="tw-relative tw-p-[32px] bs-xs:tw-p-32px">
        <div className="tw-pb-32px tw-flex tw-justify-between">
          <SectionLabel>Brivity Numbers</SectionLabel>
          {featureFlags.callRecording && currentUser.account.isAdmin && (
            <Button size="medium" schema="secondary" onClick={() => setIsCallRecordingModalOpen(true)}>
              <GearSolidV6 className="tw-mr-[6px]" />
              Call Recording
            </Button>
          )}
        </div>
        <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto">
          <table>
            <thead className="tw-whitespace-nowrap">
              <tr>
                <th className="tw-p-[8px] tw-border-b-[1px] tw-border-solid tw-border-gray-15 tw-border-t-0 tw-border-x-0">
                  <span className="tw-text-12px tw-text-gray-50 tw-font-normal">Agent Name</span>
                </th>
                <th className="tw-p-[8px] tw-border-b-[1px] tw-border-solid tw-border-gray-15 tw-border-t-0 tw-border-x-0">
                  <div className="tw-flex tw-gap-8px">
                    <span className="tw-text-12px tw-text-gray-50 tw-font-normal tw-flex tw-gap-[4px]">
                      Routing Number
                      <Tooltip
                        innerClassName="tw-max-w-[300px]"
                        placement="right"
                        content="Used for Brivity notifications, texting, and click-to-call"
                        trigger={<CircleInfoSolidV6 />}
                      />
                    </span>
                  </div>
                </th>
                <th className="tw-p-[8px] tw-border-b-[1px] tw-border-solid tw-border-gray-15 tw-border-t-0 tw-border-x-0">
                  <span className="tw-text-12px tw-text-gray-50 tw-font-normal">Brivity Number</span>
                </th>
                {featureFlags.callRecording && (
                  <>
                    <th className="tw-p-[8px] tw-border-b-[1px] tw-border-solid tw-border-gray-15 tw-border-t-0 tw-border-x-0">
                      <span className="tw-text-12px tw-text-gray-50 tw-font-normal tw-flex tw-gap-[4px]">
                        Inbound Call Recording
                        {accountCallRecordingSettings.inboundRecording !== "by_number" && (
                          <Tooltip
                            innerClassName="tw-max-w-[300px]"
                            trigger={<CircleInfoSolidV6 />}
                            content="This has been set for all accounts. Go to Call Recording settings to update."
                          />
                        )}
                      </span>
                    </th>
                    <th className="tw-p-[8px] tw-border-b-[1px] tw-border-solid tw-border-gray-15 tw-border-t-0 tw-border-x-0">
                      <span className="tw-text-12px tw-text-gray-50 tw-font-normal tw-flex tw-gap-[4px]">
                        Outbound Call Recording
                        {accountCallRecordingSettings.outboundRecording !== "by_number" && (
                          <Tooltip
                            innerClassName="tw-max-w-[300px]"
                            trigger={<CircleInfoSolidV6 />}
                            content="This has been set for all accounts. Go to Call Recording settings to update."
                          />
                        )}
                      </span>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody className="tw-whitespace-nowrap">
              {agentsWithPhoneUsage.map((agent) => (
                <AgentRow key={agent.uuid} agent={agent} addBrivityNumber={addBrivityNumber} />
              ))}
            </tbody>
          </table>
        </div>
      </Section>

      <AddBrivityPhone
        agent={agentToAddNumber}
        onClose={onManageNumberModalClosed}
        onNumberSaved={onNumberSaved}
      />

      <TeamCallRecordingSettingsModal
        open={isCallRecordingModalOpen}
        onClose={() => setIsCallRecordingModalOpen(false)}
      />
    </div>
  );
};

export default PhoneSettings;
