import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { isReferral } from "../../../shared/helpers";
import { Upload } from "../../../../shared/v2/Icomoon";
import { TYPE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { showModal } from "../../../UI/Modals/ThumbnailSelector/actions/creators";
import Photo from "./Photo";

const ImagePlaceHolderComp = ({ type, showModalFn }) => (
  <button
    type="button"
    onClick={() => showModalFn(true)}
    className="tw-w-[140px] tw-h-[116px] tw-mt-[29px] tw-relative tw-overflow-hidden tw-rounded tw-group tw-bg-transparent tw-p-0 tw-border-0"
  >
    <Photo />
    <div className="group-hover:tw-bg-[#333333] tw-opacity-60 tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full" />
    {!isReferral(type) && (
      <span className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-border-[2px] tw-border-solid tw-border-white tw-font-bold tw-text-white tw-px-8px tw-py-4px tw-rounded-full tw-hidden group-hover:tw-flex">
        <Upload className="tw-text-white tw-mr-[4px]" size="l" />
        SELECT
      </span>
    )}
  </button>
);

ImagePlaceHolderComp.propTypes = {
  type: Proptypes.string,
  showModalFn: Proptypes.func,
};

ImagePlaceHolderComp.defaultProps = {
  type: null,
  showModalFn: null,
};
const ImageWrapped = connect(null, { showModalFn: showModal })(ImagePlaceHolderComp);

const ImagePlaceHolder = tdpDetails({
  WrappedComponent: ImageWrapped,
  states: TYPE,
});

export default ImagePlaceHolder;
