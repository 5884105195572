import { format } from "date-fns";
import { QUERY_DATE_FORMAT } from "../../../shared/DatePicker";

export const types = [
  {
    name: "Buyer",
    isActive: false,
    id: "buyer",
  },
  {
    name: "Seller",
    isActive: false,
    id: "seller",
  },
  {
    name: "Tenant",
    isActive: false,
    id: "tenant",
  },
  {
    name: "Landlord",
    isActive: false,
    id: "landlord",
  },
  {
    name: "Referral",
    isActive: false,
    id: "referral",
  },
];

export const publishedStates = [
  {
    name: "Published",
    isActive: false,
    id: "published",
  },
  {
    name: "Drafts",
    isActive: false,
    id: "drafts",
  },
];

export const statuses = [
  {
    name: "Pipeline",
    isActive: false,
    id: "pipeline",
  },
  {
    name: "Coming Soon",
    isActive: false,
    id: "coming-soon",
  },
  {
    name: "Active",
    isActive: false,
    id: "active",
  },
  {
    name: "Pending",
    isActive: false,
    id: "pending",
  },
  {
    name: "Closed",
    isActive: false,
    id: "sold",
  },
  {
    name: "Expired",
    isActive: false,
    id: "expired",
  },
  {
    name: "Withdrawn",
    isActive: false,
    id: "withdrawn",
  },
  {
    name: "Canceled",
    isActive: false,
    id: "canceled",
  },
  {
    name: "Archived",
    isActive: false,
    id: "archived",
  },
];

export const agents = [
  {
    name: "Pulkit",
    id: 1,
  },
  {
    name: "Renan",
    id: 2,
  },
  {
    name: "Igor",
    id: 3,
  },
  {
    name: "Justin",
    id: 4,
  },
  {
    name: "Alex",
    id: 5,
  },
  {
    name: "John Doe",
    id: 6,
  },
  {
    name: "Tony Stark",
    id: 7,
  },
];

export const sources = [
  {
    name: "Source - 1",
    id: 1,
  },
  {
    name: "Source - 2",
    id: 2,
  },
  {
    name: "Source - 3",
    id: 3,
  },
  {
    name: "Source - 4",
    id: 4,
  },
  {
    name: "Source - 5",
    id: 5,
  },
  {
    name: "Source - 6",
    id: 6,
  },
  {
    name: "Source - 7",
    id: 7,
  },
];

export const dateRanges = [
  {
    name: "All time",
    value: "all-time",
  },
  {
    name: "Today",
    value: format(new Date(), QUERY_DATE_FORMAT, {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    }),
  },
  {
    name: "Last 7 Days",
    value: "7-day",
  },
  {
    name: "Last 14 Days",
    value: "14-day",
  },
  {
    name: "Last 30 Days",
    value: "30-day",
  },
  {
    name: "Next Month",
    value: "next-month",
  },
  {
    name: "This Month",
    value: "this-month",
  },
  {
    name: "Last Month",
    value: "last-month",
  },
  {
    name: "This Year",
    value: "this-year",
  },
  {
    name: "Last Year",
    value: "last-year",
  },
];

export const statusChangeDateRanges = [
  {
    name: "All time",
    value: "all-time",
  },
  {
    name: "Today",
    value: format(new Date(), QUERY_DATE_FORMAT, {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    }),
  },
  {
    name: "Last 7 Days",
    value: "7-day",
  },
  {
    name: "Last 14 Days",
    value: "14-day",
  },
  {
    name: "Last 30 Days",
    value: "30-day",
  },
  {
    name: "Next Month",
    value: "next-month",
  },
  {
    name: "This Month",
    value: "this-month",
  },
  {
    name: "Last Month",
    value: "last-month",
  },
  {
    name: "This Year",
    value: "this-year",
  },
  {
    name: "Last Year",
    value: "last-year",
  },
  {
    name: "Custom",
    value: "custom",
  },
];
