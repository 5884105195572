import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableExpenseIncomeLineItems from "../ExpenseIncomeLineItem/DraggableExpenseIncomeLineItems";
import { transactionShape } from "../../utils";

/** differentiate expenses or incomes draggable line items. */
const ExpensesIncomeLineItems = ({
  transaction,
  onLineItemDragEnd,
  onLineItemOptionChange,
  onLineItemValueChange,
  onEditLineItem,
  onRemoveLineItem,
  isExpensesLine,
}) => {
  const financialItems = () => {
    if (transaction && transaction.lineItems) {
      return transaction.lineItems.filter(
        (item) => item.expenseOrIncome === (isExpensesLine ? "expense" : "income"),
      );
    }
    return [];
  };
  return (
    <DragDropContext onDragEnd={onLineItemDragEnd}>
      <Droppable droppableId="droppable-transaction-line-items">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DraggableExpenseIncomeLineItems
              lineItems={financialItems()}
              isDraggingOver={snapshot.isDraggingOver}
              onOptionChange={onLineItemOptionChange}
              onValueChange={onLineItemValueChange}
              onEditLineItem={onEditLineItem}
              onRemoveLineItem={onRemoveLineItem}
            />

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ExpensesIncomeLineItems.propTypes = {
  transaction: transactionShape.isRequired,
  onLineItemDragEnd: PropTypes.func,
  onLineItemOptionChange: PropTypes.func,
  onLineItemValueChange: PropTypes.func,
  onEditLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
  isExpensesLine: PropTypes.bool,
};

ExpensesIncomeLineItems.defaultProps = {
  onLineItemDragEnd: () => {},
  onLineItemOptionChange: () => {},
  onLineItemValueChange: () => {},
  onEditLineItem: () => {},
  onRemoveLineItem: () => {},
  isExpensesLine: false,
};

export default ExpensesIncomeLineItems;
