import _ from "lodash";
import { ExistingFeatureFields } from "./helpers";

class SearchModel {
  constructor(props, resetOffset = true) {
    this.queryOptions = {
      offset: 0,
      limit: DefaultLimit,
      sort: [{ field: "listDate", order: "desc" }]
    };

    this.namesByIds = { agentIds: {}, officeIds: {} };

    Object.assign(this, props);
    if (resetOffset) {
      this.queryOptions.offset = 0;
    }

    this.statuses = this.statuses?.length
      ? this.statuses
      : DefaultStatuses;
  }

  getFilters() {
    return FilterFields.reduce((acc, cur) => {
      if (_.isBoolean(this[cur]) || !_.isEmpty(this[cur])) {
        acc[cur] = this[cur];
      }

      return acc;
    }, {});
  }

  getNumberOfFilters() {
    let count = 0;
    if (this["showOnlyNew"]) {
      count++;
    }

    if (this["showTeamListings"]) {
      count++;
    }

    if (this["propertyTypes"]?.length) {
      count += this["propertyTypes"].length;
    }

    FilterPairs.forEach(([min, max]) => {
      if (this[min] || this[max]) {
        count++;
      }
    });

    ExistingFeatureFields.forEach((f) => {
      if (this[f]?.fields) {
        count += this[f].fields.length;
      }
    });

    return count;
  }

  update(field, value) {
    return new SearchModel({
      ...this,
      [field]: value,
    });
  }

  updateMany(values) {
    return new SearchModel({
      ...this,
      ...values,
    });
  }

  addToArray(field, value) {
    if (this.doesValueExist(field, value)) {
      return this;
    }

    return new SearchModel({
      ...this,
      [field]: [...(this[field] || []), value],
    });
  }

  addToArrayWithIdentifer(field, value, identifier) {
    if (this.doesValueExist(field, value)) {
      return this;
    }

    const namesByIds = {
      ...this.namesByIds,
      [field]: {
        ...this.namesByIds[field],
        [value]: identifier
      }
    };

    return new SearchModel({
      ...this,
      namesByIds,
      [field]: [...(this[field] || []), value],
    });
  }

  removeValue(field, value) {
    if (!this[field]?.length) {
      return this;
    }

    const filtered = this[field].filter((v) => !_.isEqual(v, value));

    if (field === "agentIds" || field === "officeIds") {
      delete this.namesByIds[field][value];
    }

    return new SearchModel({
      ...this,
      [field]: filtered.length ? filtered : null,
    });
  }

  removeFeatureValue(field, value) {
    if (!field || !value || !this[field]?.fields) {
      return this;
    }

    const filtered = this[field].fields.filter(f => f.term !== value);

    return new SearchModel({
      ...this,
      [field]: filtered.length
      ? {
        fields: filtered
      }
      : null
    });
  }

  getSort() {
    return this.queryOptions.sort?.[0];
  }

  getIdentifier(field, value) {
    return this.namesByIds[field]?.[value];
  }

  setSort(field, order) {
    if (!field || !order) {
      const { sort, ...queryOptions } = this.queryOptions;

      return !sort
        ? this
        : new SearchModel(
            {
              ...this,
              queryOptions,
            },
            true,
          );
    }

    return new SearchModel(
      {
        ...this,
        queryOptions: {
          ...this.queryOptions,
          sort: [
            {
              field,
              order,
            },
          ],
        },
      },
      true,
    );
  }

  updateOffset(newOffset) {
    return new SearchModel(
      {
        ...this,
        queryOptions: {
          ...this.queryOptions,
          offset: newOffset,
        },
      },
      false,
    );
  }

  clearSearchParams() {
    return new SearchModel({
      mlsIds: [...this.mlsIds],
    });
  }

  isValid() {
    return this.mlsIds?.length > 0 && this.hasRequiredFields();
  }

  doesValueExist(field, value) {
    if (!this[field]) {
      return false;
    }

    return Array.isArray(this[field]) ? this[field].some((v) => _.isEqual(v, value)) : this[field] === value;
  }

  hasRequiredFields() {
    return (
      this.cityStates?.length > 0 ||
      this.neighborhoodCityStates?.length > 0 ||
      this.countyStates?.length > 0 ||
      this.postalCodes?.length > 0 ||
      this.mlsNums?.length > 0 ||
      this.streetAddress?.length > 0 ||
      this.agentIds?.length > 0 ||
      this.officeIds?.length > 0 ||
      this.schoolDistricts?.length > 0
    );
  }
}

export default SearchModel;

const DefaultStatuses = [1];
const DefaultLimit = 48;

const FilterFields = [
  "propertyTypes",
  "statuses",
  "priceMin",
  "priceMax",
  "sqFtMin",
  "sqFtMax",
  "propertyTypes",
  "showOnlyNew",
  "showTeamListings",
  "statuses",
  "bedsMin",
  "bedsMax",
  "bathsMin",
  "bathsMax",
  "lotSizeMin",
  "lotSizeMax",
  "yearMin",
  "yearMax",
  "garageMin",
  "garageMax",
  "storiesMin",
  ...ExistingFeatureFields
];

const FilterPairs = [
  ["priceMin", "priceMax"],
  ["sqFtMin", "sqFtMax"],
  ["bedsMin", "bedsMax"],
  ["bathsMin", "bathsMax"],
  ["lotSizeMin", "lotSizeMax"],
  ["yearMin", "yearMax"],
  ["garageMin", "garageMax"],
  ["storiesMin", ],
];
