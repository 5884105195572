import { cloneDeep } from "lodash";

import {
  adaptClosePriceToApi,
  adaptFromApi,
  adaptFromUi,
  adaptSelectableTeamMembers,
} from "../api/apiAdapters";
import {
  createFinancialData,
  getFinancialData,
  getFinancialSelectableTeamMembers,
  getDefaultCompany,
  deleteTeamMember,
  updateFinancialData,
} from "../api/apiWrappers";
import FINANCIALS_UPDATE_TYPE from "../api/actionNames";

import {
  financialsCreateFailure,
  financialsCreateStarted,
  financialsCreateSuccess,
  financialsFetchFailure,
  financialsFetchSelectableTeamMembersSuccess,
  financialsFetchStarted,
  financialsFetchSuccess,
  financialsRemoveTeamMemberSuccess,
  financialsUpdateCanceled,
  financialsUpdateFailure,
  financialsUpdateProgress,
  financialsUpdateStarted,
  financialsUpdateSuccess,
} from "./creators";
import { getCDAdocument } from "../../Docs/actions/creators";

export const fetchFinancials = () => async (dispatch, getState) => {
  try {
    dispatch(financialsFetchStarted());

    const currentState = getState();
    const { transactionSlug } = currentState.tdpFinancialsReducer;

    const res = await getFinancialData(transactionSlug);

    const rawData = res.data;

    const transactionDetails = { ...currentState.tdpDetailsReducer.transactionDetails };
    transactionDetails.est_close_price = transactionDetails.est_close_price.replace(/[$%,]/g, "");
    const adaptedData = adaptFromApi(rawData, transactionDetails);
    dispatch(getCDAdocument(adaptedData.adaptedData.CDAdocuments));
    dispatch(financialsFetchSuccess(adaptedData));
  } catch (err) {
    if (err.response?.data?.errors) {
      dispatch(financialsFetchFailure(err.response?.data?.errors));
    } else {
      dispatch(
        financialsFetchFailure(["An unexpected error has occurred. Please, try again in a few minutes."]),
      );
    }
  }
};

export const createFinancials = (closePrice) => async (dispatch, getState) => {
  try {
    dispatch(financialsCreateStarted());

    const currentState = getState();
    const { transactionSlug } = currentState.tdpFinancialsReducer;

    await createFinancialData(transactionSlug, adaptClosePriceToApi(closePrice));

    // Fetch financials again since the create route doesn't respond with all the required data
    const res = await getFinancialData(transactionSlug);

    const rawData = res.data;

    const adaptedData = adaptFromApi(rawData, currentState.tdpDetailsReducer.transactionDetails);
    dispatch(getCDAdocument(adaptedData.adaptedData.CDAdocuments));
    dispatch(financialsCreateSuccess(adaptedData));
  } catch (err) {
    if (err.response?.data?.errors) {
      dispatch(financialsCreateFailure(err.response?.data?.errors));
    } else {
      dispatch(
        financialsCreateFailure(["An unexpected error has occurred. Please, try again in a few minutes."]),
      );
    }
  }
};

export const updateFinancials = (type, data) => async (dispatch, getState) => {
  try {
    dispatch(financialsUpdateStarted());

    const currentState = getState();
    const {
      transactionSlug,
      financials: { rawData },
    } = currentState.tdpFinancialsReducer;

    let newRawData = adaptFromUi(type, data, rawData);

    if (!newRawData) {
      // Using a 0-second timeout to allow the UI to quickly change between loading / not loading state,
      // otherwise modals never close when saving without changes
      window.setTimeout(() => dispatch(financialsUpdateCanceled()), 0);
      return;
    }

    if (!data.isInModal) {
      // Keep adapted data to avoid reverting user changes in case of error
      const adaptedData = adaptFromApi(newRawData, currentState.tdpDetailsReducer.transactionDetails);
      dispatch(financialsUpdateProgress({ ...adaptedData }));
    }

    const res = await updateFinancialData(transactionSlug, newRawData);

    newRawData = res.data;

    const newAdaptedData = adaptFromApi(newRawData, currentState.tdpDetailsReducer.transactionDetails);
    dispatch(financialsUpdateSuccess({ ...newAdaptedData }));
    if (newRawData?.errors) dispatch(financialsUpdateFailure(newRawData?.errors, data.isInModal));
  } catch (err) {
    if (err.response?.data?.errors) {
      dispatch(financialsUpdateFailure(err.response?.data?.errors, data.isInModal));
    } else {
      dispatch(
        financialsUpdateFailure(
          ["An unexpected error has occurred. Please, try again in a few minutes."],
          data.isInModal,
        ),
      );
    }
  }
};

export const fetchFinancialsSelectableTeamMembers = () => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const { transactionSlug } = currentState.tdpFinancialsReducer;

    const res = await getFinancialSelectableTeamMembers(transactionSlug);

    const rawData = res.data;

    const adaptedData = adaptSelectableTeamMembers(rawData);
    dispatch(financialsFetchSelectableTeamMembersSuccess(adaptedData));
  } catch (err) {
    // TODO: Handle the error
  }
};

export const fetchFinancialsCompany = () => async (dispatch, getState) => {
  try {
    dispatch(financialsFetchStarted());

    const { transactionSlug } = getState().tdpFinancialsReducer;
    // eslint-disable-next-line no-unused-vars
    const res = await getDefaultCompany(transactionSlug);
    dispatch(fetchFinancials());
  } catch (err) {
    if (err.response?.data?.errors) {
      dispatch(financialsFetchFailure(err.response?.data?.errors));
    } else {
      dispatch(
        financialsFetchFailure(["An unexpected error has occurred. Please, try again in a few minutes."]),
      );
    }
  }
};

export const addTeamMembers = (selectedMembers) => (dispatch) => {
  dispatch(updateFinancials(FINANCIALS_UPDATE_TYPE.ADD_TEAM_MEMBERS, { selectedMembers })).then(() => {
    // TODO: improve this because the user could return to the add team members modal fast enough
    // to see team members that were already added

    // Update selectable team members to avoid adding multiple team members for the same user
    dispatch(fetchFinancialsSelectableTeamMembers());
  });
};
export const addCompany = () => (dispatch) => {
  dispatch(fetchFinancialsCompany());
};

export const removeTeamMember = (memberId, isCompany) => async (dispatch, getState) => {
  // TODO: improve this because the user doesn't have a visual feedback of progress and it could
  // take a while

  try {
    const currentState = getState();
    const {
      transactionSlug,
      financials: { rawData },
    } = currentState.tdpFinancialsReducer;

    await deleteTeamMember(transactionSlug, memberId);

    const newRawData = cloneDeep(rawData);
    if (!isCompany) {
      newRawData.team_member_incomes.incomes = newRawData.team_member_incomes.incomes.filter(
        (income) => income.id !== memberId,
      );
    } else {
      // there is only one company object so if its deleted then ti should be null, so set as null
      newRawData.company_income = null;
    }

    const adaptedData = adaptFromApi(newRawData, currentState.tdpDetailsReducer.transactionDetails);

    if (!isCompany) {
      // Update selectable team members to avoid missing users on the modal to add team members
      dispatch(fetchFinancialsSelectableTeamMembers()).then(() => {
        dispatch(financialsRemoveTeamMemberSuccess(adaptedData));
      });
    } else {
      // if its company then we dont need to update selectable team member
      dispatch(financialsRemoveTeamMemberSuccess(adaptedData));
    }
  } catch (err) {
    if (err.response?.data?.content) {
      dispatch(financialsUpdateFailure([err.response?.data?.content]));
    } else {
      dispatch(
        financialsUpdateFailure(["An unexpected error has occurred. Please, try again in a few minutes."]),
      );
    }
  }
};
