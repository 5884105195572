import React from "react";
import PropTypes from "prop-types";

const TableMessage = ({ text, iconComponent, ...otherProps }) => (
  <tr>
    <td
      className="tw-text-center tw-py-[10px] tw-text-14d tw-border-neutral-gray-10 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-b tw-border-solid"
      colSpan="8"
      {...otherProps}
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
        {text}
        {iconComponent}
      </div>
    </td>
  </tr>
);
TableMessage.propTypes = {
  text: PropTypes.string,
  iconComponent: PropTypes.element,
};

TableMessage.defaultProps = {
  text: null,
  iconComponent: null,
};
export default TableMessage;
