import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import * as actionCreators from "../actions/thunks";

const DeleteAdPlan = ({ modalOpen, deleteAdvertisingPlan, isLoading, uuid, planId, closeModal }) => {
  const destroyPlan = () => {
    deleteAdvertisingPlan(uuid, planId, closeModal);
  };
  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[600px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
      show={modalOpen}
      onHide={closeModal}
    >
      <Modal.Header title="Delete Advertising Plan?" closeButton={!isLoading} />

      <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
        <span className="tw-text-[14px] tw-font-normal tw-leading-[20px] tw-text-gray-75">
          Deleting this plan will not un-share any sites shared with the client.
        </span>
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
        <Button onClick={closeModal} disabled={isLoading} schema="tertiary" size="medium">
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={destroyPlan} schema="warning" size="medium">
          Yes, Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteAdPlan.propTypes = {
  uuid: Proptypes.string.isRequired,
  modalOpen: Proptypes.bool,
  isLoading: Proptypes.bool,
  closeModal: Proptypes.func,
  deleteAdvertisingPlan: Proptypes.func.isRequired,
  planId: Proptypes.number,
};
DeleteAdPlan.defaultProps = {
  modalOpen: false,
  isLoading: false,
  closeModal: null,
  planId: null,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAdvertisingPlan: (uuid, planId, closeModal) =>
    dispatch(actionCreators.deleteAdvertisingPlan(uuid, planId, closeModal)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAdPlan);
