import React, { useState } from "react";

import { Modal } from "../../../shared/v1";
import Button from "../Button";
import { formatSimplePrice } from "../helpers";
import { saveListingSearch } from "../service";

const SaveSearchModal = ({search, onClose, userId}) => {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(generateDefaultSearchName(search));

  const save = async () => {
    setSaving(true);
    await saveListingSearch(userId, name, search);
    setSaving(false);
    onClose(true);
  };

  return (
    <Modal
      id="save-search-modal"
      className="thisthing"
      show={true}
      variant="modern"
      onHide={onClose}
      size="default"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="tw-text-28px tw-text-gray-75">Save Search</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row">
            <span className="tw-text-14px tw-text-gray-75">The current locations and filters will be saved for you.</span>
          </div>
          <div className="tw-flex tw-flex-row tw-mt-32px">
            <span className="tw-text-14px tw-font-semibold tw-text-gray-50 after:tw-text-coral after:tw-ml-2 after:tw-content-['*']">Name the search</span>
          </div>
          <div className="tw-flex tw-flex-row tw-mt-8px">
            <input
              className="tw-w-[536px] tw-h-36px tw-text-14px tw-text-gray-99 tw-pl-12px tw-border-solid tw-border-1px tw-rounded-sm tw-border-gray-15 tw-bg-tinted-gray-50"
              value={name}
              onChange={(e) => setName(e.target?.value)}
            />
          </div>
          <div className="tw-flex tw-flex-row tw-mt-32px">
            <Button size="large" onClick={onClose}>Cancel</Button>
            <Button
              size="large"
              color="blue"
              classNameExtra="tw-ml-auto"
              disabled={!name || saving ? "disabled" : ""}
              onClick={save}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SaveSearchModal;

const generateDefaultSearchName = (search) => {
  const formattedLocations = [];
  LocationFormatters.forEach(({field, textFormatter}) => {
    const locations = search[field]?.map(textFormatter);
    if (locations?.length) {
      formattedLocations.push(...locations);
    }
  });

  let defaultName = formattedLocations.join("; ");

  defaultName += createMinMaxDescription(formatSimplePrice(search.priceMin), formatSimplePrice(search.priceMax), null, "$0");
  defaultName += createMinMaxDescription(search.bedsMin, search.bedsMax, "beds");
  defaultName += createMinMaxDescription(search.bathsMin, search.bathsMax, "baths");
  defaultName += createMinMaxDescription(search.sqFtMin, search.sqFtMax, "SQFT");

  return defaultName;
};

const LocationFormatters = [
  { field: "cityStates", textFormatter: (c) => `${c.city}, ${c.state}` },
  { field: "neighborhoodCityStates", textFormatter: (n) => `${n.neighborhood}, ${n.city}, ${n.state}` },
  { field: "countyStates", textFormatter: (cs) => `${cs.county}, ${cs.state}` },
  { field: "postalCodes", textFormatter: (p) => p },
  { field: "mlsNums", textFormatter: (m) => m },
  { field: "streetAddress", textFormatter: (s) => s },
];

const createMinMaxDescription = (min, max, descriptor = "", base = "0") => {
  if (!min && !max) {
    return "";
  }

  return `; ${min ? min : base}${max ? "-" + max : "+"}${descriptor ? " " + descriptor : ""}`;
};
