import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import IconButton from "../IconButton";
import { ChevronLeft, ChevronRight } from "../Icomoon";

// eslint-disable-next-line react/prop-types
const MinimalIconButton = ({ disabled, onClick, iconComponent }) => (
  <IconButton
    schema="transparent"
    size="small"
    tabIndex={-1}
    aria-hidden
    disabled={disabled}
    onClick={onClick}
  >
    {iconComponent()}
  </IconButton>
);

// eslint-disable-next-line react/prop-types
const HiddenRangeInput = ({ className, onNewValue, ...otherProps }) => (
  <input
    className={`tw-sr-only ${className}`}
    tabIndex={0}
    type="range"
    step={1}
    onChange={(evt) => onNewValue(Number(evt.target.value))}
    {...otherProps}
  />
);

// TODO: Investigate why we need to handle these separatedly depending on
// whether `render` is a function or a node/string. Theoretically this should
// be transparent to us.
const getRenderedError = (currentError) => {
  if (!currentError) {
    return null;
  }
  // eslint-disable-next-line no-nested-ternary
  return typeof currentError.render === "function"
    ? currentError.render()
    : currentError.render !== undefined
      ? currentError.render
      : currentError;
};

const ErrorBanner = ({ errors, ...otherProps }) => {
  // If no errors were received, hide the component.
  if (!errors || !errors.length) {
    return null;
  }

  // Component has two main states - an initial state and a "reviewing errors"
  // state.
  const [isReviewingErrors, setIsReviewingErrors] = useState(false);

  const [totalErrors, setTotalErrors] = useState(errors.length);
  const [currentErrorIndex, setCurrentErrorIndex] = useState(0);
  const [currentError, setCurrentError] = useState(errors[0]);
  const [renderedError, setRenderedError] = useState(getRenderedError(errors[0]));

  // Reset component back to initial state if errors are updated
  useEffect(() => {
    setIsReviewingErrors(false);
    setCurrentErrorIndex(0);

    if (!errors || !errors.length) {
      setTotalErrors(0);
      setCurrentError(null);
      setRenderedError(null);
      return;
    }

    setTotalErrors(errors.length);

    const newError = errors[0];
    setCurrentError(newError);
    setRenderedError(getRenderedError(newError));
  }, [errors]);

  const onNavigateTo = (newErrorIndex) => {
    setCurrentErrorIndex(newErrorIndex);

    const newError = errors[newErrorIndex];

    setCurrentError(newError);
    setRenderedError(getRenderedError(newError));

    if (newError?.onShow) {
      newError.onShow();
    }
  };

  const onStartReviewingErrors = () => {
    setIsReviewingErrors(true);

    if (currentError?.onShow) {
      currentError.onShow();
    }
  };

  const errorCounterOrReviewErrorsButton = isReviewingErrors ? (
    <>
      {currentErrorIndex + 1} / {totalErrors}
    </>
  ) : (
    <Button
      className="hover:!tw-text-semantic-red-120 hover:!tw-border-semantic-red-120 active:!tw-border-semantic-red-120"
      schema="warning"
      onClick={onStartReviewingErrors}
    >
      Review Error{totalErrors > 1 ? "s" : ""}
    </Button>
  );

  const errorNavigationButtons =
    totalErrors > 1 && isReviewingErrors ? (
      <>
        <HiddenRangeInput
          className="tw-peer"
          min={1}
          max={totalErrors}
          value={currentErrorIndex + 1}
          onNewValue={(val) => onNavigateTo(val - 1)}
        />
        <span className="tw-rounded-[12px] peer-focus-visible:tw-outline-theme">
          <MinimalIconButton
            disabled={currentErrorIndex === 0}
            onClick={() => onNavigateTo(currentErrorIndex - 1)}
            iconComponent={ChevronLeft}
          />

          <MinimalIconButton
            disabled={currentErrorIndex === totalErrors - 1}
            onClick={() => onNavigateTo(currentErrorIndex + 1)}
            iconComponent={ChevronRight}
          />
        </span>
      </>
    ) : null;

  const renderError = (error) => {
    const htmlRegex = /<[a-z][\s\S]*>/i;

    return htmlRegex.test(error) ? <div dangerouslySetInnerHTML={{ __html: error }} /> : error;
  };

  return (
    <div
      className="tw-flex tw-justify-between tw-items-center tw-gap-x-[16px] tw-bg-semantic-red-5 tw-rounded-2px tw-px-[16px] tw-py-[10px] tw-min-h-[48px]"
      data-cy="error-banner"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <span className="tw-text-semantic-red-120">
        {isReviewingErrors
          ? renderError(renderedError)
          : `Oops, something wasn't right. ${totalErrors} error${totalErrors > 1 ? "s" : ""} found.`}
      </span>
      <span className="tw-flex tw-gap-x-[12px] tw-min-w-fit tw-items-center tw-text-neutral-gray-75">
        <span>{errorCounterOrReviewErrorsButton}</span>
        {errorNavigationButtons}
      </span>
    </div>
  );
};

export const errorShapeForErrorBanner = PropTypes.shape({
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onShow: PropTypes.func,
});

ErrorBanner.propTypes = {
  errors: PropTypes.arrayOf(errorShapeForErrorBanner),
};

ErrorBanner.defaultProps = {
  errors: [],
};

export default ErrorBanner;
