/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { Col, Input, Button } from "../../shared/v1";
import "./AutoPlan.scss";

class AutoPlanNameForm extends React.Component {
  handleSaveName = (name, value) => {
    const { handleNameChange } = this.props;
    handleNameChange(name, value);
  };

  render() {
    const { isEditingName, name, toggleEdit, updateName } = this.props;
    return (
      <div className='tw-mt-[26px]'>
        {isEditingName ? (
          <div className="">
            <Col size={12}>
              <label>Plan Name</label>
            </Col>
            <Col size={12}>
              <div className="d-flex flex-items-center editable-inline">
                <Input
                  type="text"
                  className="action-name-input"
                  name="name"
                  onChange={(e) => this.handleSaveName(e.target.name, e.target.value)}
                  defaultValue={name}
                />
                <Button variant="info" className="tw-ml-10px" onClick={updateName}>
                  Save
                </Button>
                <Button variant="info" invert className="tw-ml-10px" onClick={toggleEdit}>
                  Cancel
                </Button>
              </div>
            </Col>
          </div>
        ) : (
          <Col size={12}>
            <div className="tw-font-bold tw-text-18d">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span className="tw-mr-10px">{name}</span> <i className="fa fa-pencil edit-icon tw-text-[#C2C2C2] tw-cursor-pointer" onClick={toggleEdit} />
            </div>
          </Col>
        )}
      </div>
    );
  }
}

export default AutoPlanNameForm;

AutoPlanNameForm.propTypes = {
  isEditingName: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  updateName: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
};
