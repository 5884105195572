import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import AppliedFilters from "./AppliedFilters";

const AppliedFiltersApp = ({ clear_link: clearLink, filters }) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <AppliedFilters clearLink={clearLink} filters={filters} />
    </Provider>
  );
};

AppliedFiltersApp.propTypes = {
  clear_link: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AppliedFiltersApp;
