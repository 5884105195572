/* eslint-disable no-param-reassign */

const collapseSchemaProps = (schema) =>
  Object.keys(schema)
    .map((state) => schema[state])
    .flat()
    .join(" ");

/**
 * Concatenates properties of each state for each schema into an array of classNames.
 * @param {object} dictionary - An object with the schemas, each being an object with states (ie: default, hover, focus, active, checked, etc) as key and and array of classNames as values.
 * @example
 * // returns {
 * //     regular: "a b c"
 * //     hover: "d e f",
 * // }
 * schemaGenerator({
 *      regular: {
 *          default: ["a", "b"],
 *          hover: ["c"],
 *      },
 *      error: {
 *          default: ["d"],
 *          hover: ["e", "f"],
 *      }
 *  })
 */
export const schemaGenerator = (dictionary) =>
  Object.keys(dictionary).reduce((acc, schema) => {
    acc[schema] = collapseSchemaProps(dictionary[schema]);
    return acc;
  }, {});

export default schemaGenerator;

/*
 * Measure text using Canvas.measureText method.
 * @param {HTMLElement} element - HTML element to get the font configuration
 * @param {string} text - The text string to measure
 * @return a TextMetrics object or null if Canvas 2D context is not supported.
 * See https://developer.mozilla.org/docs/Web/API/CanvasRenderingContext2D/measureText
 * and https://developer.mozilla.org/docs/Web/API/Canvas_API/Tutorial/Basic_usage#checking_for_support
 * and https://stackoverflow.com/a/21015393/4011478
 */
export const measureText = (element, text) => {
  const context = document.createElement("canvas")?.getContext("2d");
  if (!context) {
    return null;
  }
  // Get font properties from `element`
  const fontWeight = window.getComputedStyle(element, null).getPropertyValue("font-weight") || "normal";
  const fontSize = window.getComputedStyle(element, null).getPropertyValue("font-size") || "16px";
  const fontFamily =
    window.getComputedStyle(element, null).getPropertyValue("font-family") || "Times New Roman";
  context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
  return context.measureText(text);
};

/**
 * Resizes input element based on value length.
 * @param {HTMLInputElement} input - The input element to resize
 * @param {string} value - The value to use for measuring the length
 */
export const resizeInputBasedOnValue = (input, value) => {
  if (!input) {
    return;
  }

  if (value === "") {
    input.style.width = "initial";
    input.style.boxSizing = "border-box";
    return;
  }

  const metrics = measureText(input, value);

  if (!metrics) {
    // Using the `ch` unit approach, when Canvas.measureText method is not supported
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/length#ch
    input.style.width = `${value.length + 1}ch`;
  } else {
    input.style.width = `${metrics.width}px`;
  }

  input.style.boxSizing = "content-box";
};

export const getInitials = (text) => {
  const words = text.split(" ");
  const firstWord = words[0];
  const lastWord = words.length > 1 ? words[words.length - 1] : "";

  return [firstWord, lastWord].map((word) => word[0]?.toUpperCase() || "").join("");
};
