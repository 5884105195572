import React, { useEffect, useState } from "react";


export const SUBJECT_LINE_LIMIT_ERROR = "Subject must be 200 characters or less. Please revise.";

export const isWithinSubjectLineLimit = (str = '') => str.length <= 200;

export default () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorTrigger, setErrorTrigger] = useState(false);

  useEffect(() => {
    setErrorMsg(errorTrigger ? SUBJECT_LINE_LIMIT_ERROR : null)
  }, [errorTrigger]);

  return {
    errorMsg,
    setErrorTrigger,
  }
}