/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { string } from "prop-types";

import styles from "../styles";

import OptionsStatusCell from "../../../shared/TDPOptionsStatusCell";

const Status = ({ labelClassName }) => (
  <div className="tw-flex tw-flex-col tw-items-start">
    <div className={`${styles.TRANSACTION_DETAILS_LABEL} ${labelClassName}`}>Status</div>
    <OptionsStatusCell />
  </div>
);

export default Status;

Status.propTypes = {
  labelClassName: string,
};

Status.defaultProps = {
  labelClassName: "tw-text-[12px] tw-leading-[16px]",
};
