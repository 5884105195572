import React, { useState } from "react";
import { bool, func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  IS_MODAL_RENAME_TITLE_OPEN,
  LISTING_UUID,
  TRANSACTION_TITLE,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import Modal from "../../../../shared/v2/Modal";
import TextInput from "../../../../shared/v2/TextInput";
import Button from "../../../../shared/v2/Button";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import { putReferralTitleAsThunk } from "../../actions/thunks";
import { referralEditTitleModalOff } from "../../actions/creators";

const ModalRename = ({ show, val, headerTitle, label, onChangeHandlr, onSubmitHandlr, onCloseHandlr }) => (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    onHide={onCloseHandlr}
    closeButton
  >
    <Modal.Header title={headerTitle} className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px tw-max-h-[70vh] tw-overflow-auto">
      <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-mb-8px">{label}</div>
      <TextInput value={val} onChange={({ target: { value } }) => onChangeHandlr(value)} />
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button className="tw-mr-auto" size="medium" schema="tertiary" onClick={onCloseHandlr}>
        CANCEL
      </Button>
      <Button size="medium" schema="primary" onClick={onSubmitHandlr}>
        SAVE
      </Button>
    </Modal.Footer>
  </Modal>
);

const ModalRenameWrapper = ({
  isModalRenameTitleOpen,
  title,
  onPutReferralTitleAsThunk,
  onReferralEditTitleModalOff,
  uuid,
}) => {
  const [stateTitle, setStateTitle] = useState(title);
  const onSubmitHandlr = () =>
    onPutReferralTitleAsThunk({
      key: "title",
      title: stateTitle,
      uuid,
    });

  return (
    <ModalRename
      show={isModalRenameTitleOpen}
      val={stateTitle}
      headerTitle="Edit Title"
      label="Transaction Title"
      onCloseHandlr={onReferralEditTitleModalOff}
      onChangeHandlr={setStateTitle}
      onSubmitHandlr={onSubmitHandlr}
    />
  );
};

const withReduxStates = tdpDetails({
  WrappedComponent: ModalRenameWrapper,
  states: [IS_MODAL_RENAME_TITLE_OPEN, TRANSACTION_TITLE, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: [putReferralTitleAsThunk, referralEditTitleModalOff],
  aliases: ["onPutReferralTitleAsThunk", "onReferralEditTitleModalOff"],
});

ModalRenameWrapper.propTypes = {
  uuid: string.isRequired,
  isModalRenameTitleOpen: bool,
  title: string,
  onPutReferralTitleAsThunk: func,
  onReferralEditTitleModalOff: func,
};

ModalRenameWrapper.defaultProps = {
  isModalRenameTitleOpen: false,
  title: null,
  onPutReferralTitleAsThunk: () => {},
  onReferralEditTitleModalOff: () => {},
};

ModalRename.propTypes = {
  show: bool,
  val: string,
  headerTitle: string,
  label: string,
  onChangeHandlr: func,
  onSubmitHandlr: func,
  onCloseHandlr: func,
};

ModalRename.defaultProps = {
  show: false,
  val: null,
  headerTitle: null,
  label: null,
  onChangeHandlr: () => {},
  onSubmitHandlr: () => {},
  onCloseHandlr: () => {},
};
