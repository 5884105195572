import React, { Component } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Dropdown } from '../../../shared/v1';
import modal_css from '../modal.module.css';
import form_css from '../form.module.css';
import buttons from '../button.module.css';
import css from "../person-detail-styles.module.css";
import SearchPeople from "../../../QuickSearch/components/SearchPeople";
import axios from "axios";
import HeaderTitle from "./HeaderTitle";

class AddRelationshipModal extends Component {
  state = {
    page: 1,
    selectedPersonId: null,
    relatedPerson: undefined,
    primary_person_label: null,
    related_person_label: null
  };

  headerTitle = () => {
    if (this.state.page === 1) {
      return "Add a Relationship"
    }
    return "Edit Relationship"
  };

  selectPerson = (newPerson) => {
    this.setState({selectedPersonId: newPerson['object_id']})
  }

  addRelationship = (e) => {
    e.preventDefault();
    const url = '/people/' + this.props.person.data.attributes.slug + '/relationships'
    let form_data = new FormData();
    form_data.append('authenticity_token', ReactOnRails.authenticityToken())
    form_data.append('relationship[person_y_id]', this.state.selectedPersonId)
    form_data.append('person_details_page', 'true')
    form_data.append('view_type', 'new')
    axios.post(url, form_data)
      .then(res => {
        this.setState({relatedPerson: res.data, page: 2});
        this.props.addRelationship(res.data);
      }).catch(err => {
      // TODO: Handle error
      console.log(err)
    })
  }

  handlePrimaryPersonLabelChange = (value) => {
    this.setState({primary_person_label: value})
  }

  handleRelatedPersonLabelChange = (value) => {
    this.setState({related_person_label: value})
  }

  handleSaveRelationshipClick = (e) => {
    e.preventDefault();
    this.props.saveRelationship(this.props.person.data.attributes, this.state.relatedPerson.id, this.state.primary_person_label, this.state.related_person_label);
    this.props.closeAddRelationShipModal();
  }

  handleCancelClick = (e) => {
    this.props.closeAddRelationShipModal();
  }

  render() {
    return (
      <Modal id="add-relationship-modal" className="person-details-modal" show={true} onHide={this.handleCancelClick}>
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title={this.headerTitle()} onClose={this.handleCancelClick} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            { this.state.page === 1 &&
            <div>
              <Row>
                <Col xs={12} className={`form-group ${modal_css.pB15}`}>
                  <label className={form_css.formLabel}>SEARCH</label>
                  <SearchPeople onSelectFunction={this.selectPerson} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <hr className={`${modal_css.divider} ${modal_css.pB30}`}/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-group">
                  <button
                    className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray}`}
                    onClick={this.props.closeAddRelationShipModal}
                  >
                    CANCEL
                  </button>
                  <button
                    className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
                    onClick={this.addRelationship}
                  >
                    NEXT
                  </button>
                </Col>
              </Row>
            </div>
            }
            { this.state.page === 2 &&
            <div>
              <Row>
                <Col xs={6}>
                  <label className={form_css.formLabel}>PRIMARY PERSON</label>
                </Col>
                <Col xs={6}>
                  <label className={form_css.formLabel}>RELATED PERSON</label>
                </Col>
              </Row>
              <Row>
                <Col xs={6} style={{ borderRight: "1px solid #E6E6E6"}}>
                  <Row>
                    <div style={{ position: "absolute", top: "52px", right: "-15px", width: "30px", height: "56px", backgroundColor: "#fff", textAlign: "center"}}>
                      <i className="fa fa-link" style={{ position: "relative", top: "21px", fontSize: "20px", color: "rgba(89, 196, 196, 1)" }}></i>
                    </div>
                    <Col xs={12} className={modal_css.pT30}>
                      <div className={css.brivityPersonAvatarWrapper} style={{ height: "78px", width: "78px"}}>
                        <div className={css.brivityPersonAvatarInitials}>
                          {this.props.person.data.attributes.first_name?.[0].toUpperCase()}{this.props.person.data.attributes.last_name?.[0].toUpperCase()}
                        </div>
                      </div>
                      <p className="text-center" style={{ fontSize: "19px", fontWeight: "600"}}>{this.props.person.data.attributes.first_name} {this.props.person.data.attributes.last_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <label className={form_css.formLabel}>RELATIONSHIP</label>
                      <Dropdown
                        isSearchable
                        isClearable={false}
                        placeholder='Select...'
                        value={this.state.primary_person_label}
                        options={this.props.relationshipOptions.map(r => ({ value: r.value, label: r.label }))}
                        onChange={(option) => this.handlePrimaryPersonLabelChange(option.value)}
                        variant="flatGray"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className={modal_css.pT30}>
                      <div className={css.brivityPersonAvatarWrapper} style={{ height: "78px", width: "78px"}}>
                        <div className={css.brivityPersonAvatarInitials}>
                          {this.state.relatedPerson.related_to_person_first_name[0].toUpperCase()}{this.state.relatedPerson.related_to_person_last_name[0].toUpperCase()}
                        </div>
                      </div>
                      <p className="text-center" style={{ fontSize: "19px", fontWeight: "600"}}>{this.state.relatedPerson.related_person_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <label className={form_css.formLabel}>RELATIONSHIP</label>
                      <Dropdown
                        isSearchable
                        isClearable={false}
                        placeholder='Select...'
                        value={this.state.related_person_label}
                        options={this.props.relationshipOptions.map(r => ({ value: r.value, label: r.label }))}
                        onChange={(option) => this.handleRelatedPersonLabelChange(option.value)}
                        variant="flatGray"
                      />
                    </Col>
                  </Row>
                  <Row className="m-t-30">
                    <Col xs={12}>
                      <div className={css.brivityPersonContactLabel}>
                        Email (Personal)
                      </div>
                      <div className="m-t-10">
                        <span className={css.brivityPersonContactIcon}>
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <span className={css.brivityPersonContactValue}>
                          {this.state.relatedPerson.related_to_person_email ? this.state.relatedPerson.related_to_person_email : 'None'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-t-20">
                    <Col xs={12}>
                      <div className={css.brivityPersonContactLabel}>
                        Phone (Mobile)
                      </div>
                      <div className="m-t-10">
                        <span className={css.brivityPersonContactIcon}>
                          <i className="fa fa-phone"></i>
                        </span>
                        <span className={css.brivityPersonContactValue}>
                          {this.state.relatedPerson.related_to_person_phone ? this.state.relatedPerson.related_to_person_phone : 'None'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-t-20">
                    <Col xs={12}>
                      <div className={css.brivityPersonContactLabel}>
                        Address (Home)
                      </div>
                      <div className="m-t-10">
                        <span className={css.brivityPersonContactIcon}>
                          <i className="fa fa-map-marker"></i>
                        </span>
                        <span className={css.brivityPersonContactValue}>
                          { this.state.relatedPerson.related_to_person_address &&
                              <span>{this.state.relatedPerson.address?.street_address}<br />
                                {this.state.relatedPerson.related_to_person_address?.city}, {this.state.relatedPerson.related_to_person_address?.locality} {this.state.relatedPerson.related_to_person_address?.postal_code}
                              </span>
                          }
                          { !this.state.relatedPerson.related_to_person_address &&
                              <span>None</span>
                          }
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={modal_css.pT30}>
                <Col xs={12}>
                  <hr className={`${modal_css.divider} ${modal_css.pB30}`}/>
                </Col>
              </Row>
              <Row>
                <button className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray}`} onClick={e => this.handleCancelClick(e)}>CANCEL</button>
                <button className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`} onClick={e => this.handleSaveRelationshipClick(e)}>SAVE</button>
              </Row>
            </div>
            }
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddRelationshipModal;
