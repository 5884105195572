import { useEffect, useCallback } from "react";

export const useEventListenerAction = (query, action = () => {}) => {
  const actionable = useCallback(action, []);

  useEffect(() => {
    const element = document.querySelector(query);
    if (!element) return;
    element.addEventListener('click', actionable);
    return () => {
      element.removeEventListener('click', actionable);
    }
  }, [query, actionable]);
}
