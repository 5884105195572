import React from "react";
import { bool, func } from "prop-types";

import useCheckHandlr from "./hooks";

import Checkbox from "../../../../shared/v2/Checkbox/Checkbox";

const ModalAddEditViewToggle = ({ payloadHandlrs, visibility }) => {
  const [checked, checkHandlr] = useCheckHandlr(payloadHandlrs, visibility);

  return (
    <div className="tw-mt-24px">
      <Checkbox
        label="Visible to viewers"
        id="modalAddEditViewToggle"
        name="modalAddEditViewToggle"
        className="tw-cursor-pointer"
        value={checked}
        checked={checked}
        onClick={checkHandlr}
      />
    </div>
  );
};

ModalAddEditViewToggle.propTypes = {
  payloadHandlrs: func,
  visibility: bool,
};

ModalAddEditViewToggle.defaultProps = {
  payloadHandlrs: null,
  visibility: false,
};

export default ModalAddEditViewToggle;
