import React from "react";

import SortMenu from "./SortMenu";
import FilterBubbles from "./Filters/FilterBubbles";
import ViewToggle from "./ViewToggle";
import ToggleableButton from "./ToggleableButton";

const OptionsBar = ({
  searchModel,
  viewOptions,
  setViewOptions,
  applySort,
  clearSearchParams,
  clearArrayValue,
  clearFilterOption,
  clearNewListingFilter,
  toggleListingTypeFilter,
  clearFeatureOption,
  featureFields,
  saveSearch
}) => {
  const updateViewOptions = (view, bool) => {
    setViewOptions((prevOptions) => ({
      ...prevOptions,
      [view]: bool,
    }));
  };

  return (
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-4px">
        <ToggleableButton
          text="New Listings"
          includeMargins={false}
          onClick={() => toggleListingTypeFilter("showOnlyNew", !searchModel["showOnlyNew"])}
          isSelected={searchModel["showOnlyNew"]}
        />
        <ToggleableButton
          text="Company Listings"
          includeMargins={false}
          onClick={() => toggleListingTypeFilter("showTeamListings", !searchModel["showTeamListings"])}
          isSelected={searchModel["showTeamListings"]}
        />
        <FilterBubbles
          searchModel={searchModel}
          featureFields={featureFields}
          clearSearchParams={clearSearchParams}
          clearArrayValue={clearArrayValue}
          clearFilterOption={clearFilterOption}
          clearFeatureOption={clearFeatureOption}
          clearNewListingFilter={clearNewListingFilter}
          saveSearch={saveSearch}
        />
      </div>
      <div className="tw-flex tw-items-center tw-self-start tw-ml-12px">
        <div className="tw-flex tw-ml-auto tw-mr-8px">
          <SortMenu
            sortField={searchModel.getSort()?.field}
            sortOrder={searchModel.getSort()?.order}
            onSave={applySort}
          />
        </div>
        <div className="tw-flex tw-ml-6px tw-mr-6px">
          <i
            className={
              viewOptions.map
                ? "fa fa-toggle-on tw-text-teal tw-text-20px tw-cursor-pointer"
                : "fa fa-toggle-off tw-text-teal tw-text-20px tw-cursor-pointer"
            }
            onClick={() => updateViewOptions("map", !viewOptions.map)}
            style={{ lineHeight: "20px" }}
          />
          <span
            className="tw-flex tw-ml-8px tw-items-center tw-text-gray-50 tw-text-12px tw-font-semibold tw-cursor-pointer"
            onClick={() => updateViewOptions("map", !viewOptions.map)}
          >
            Map
          </span>
        </div>
        <ViewToggle
          onClick={() => updateViewOptions("grid", false)}
          isActive={!viewOptions.grid}
          iconClassName={"fa-th-list"}
        />
        <ViewToggle
          onClick={() => updateViewOptions("grid", true)}
          isActive={viewOptions.grid}
          iconClassName={"fa-th-large"}
          mxOverride="tw-ml-8px"
        />
      </div>
    </div>
  );
};

export default OptionsBar;
