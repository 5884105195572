import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../../shared/v2/Avatar/Avatar";
import { useClickOutside } from "../../shared/hookHelpers";
import Popover, { PopoverLink } from "../Popover";
import {
  GearSolidV6,
  HouseSolidV6,
  KeySolidV6,
  RightFromBracketSolidV6,
  UserSolidV6,
} from "../../shared/v2/Icomoon";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const AVATAR_CLASSES = {
  hover: {
    "brivity-theme": "tw-drop-shadow-[0px_0px_6px_rgba(0,0,0,0.3)]",
    "place-theme": "",
  },
  active: {
    "brivity-theme": "tw-drop-shadow-[0px_0px_12px_#59C4C4]",
    "place-theme": "!tw-border-semantic-blue-100 tw-drop-shadow-[0px_0px_12px_rgba(50,112,250,0.8)]",
  },
};

const BUTTON_CLASSES = {
  hover: {
    "brivity-theme": "tw-bg-[#59C4C4]/30",
    "place-theme": "tw-bg-white/20",
  },
  active: {
    "brivity-theme": "!tw-bg-[#26A4A4]",
    "place-theme": "!tw-bg-white",
  },
};

const TEXT_CLASSES = {
  base: {
    "brivity-theme": "tw-text-theme-primary",
    "place-theme": "tw-text-white/90",
  },
  hover: {
    "brivity-theme": "tw-text-[#189090]",
    "place-theme": "tw-text-white/95",
  },
  active: {
    "brivity-theme": "!tw-text-white",
    "place-theme": "!tw-text-black",
  },
};

const UserDropdown = () => {
  const currentUser = useCurrentUser();
  const theme = useSelector(({ layout }) => layout.theme);
  const ref = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  useClickOutside(ref, () => setOpen(false));

  if (!currentUser) return null;

  return (
    <div ref={ref} className="tw-ml-[2px] tw-relative">
      <button
        className={`tw-transition-all tw-flex tw-items-center tw-rounded-full tw-border-none tw-p-0 tw-pl-[36px] tw-h-[36px] ${
          theme.name === "brivity-theme" ? "tw-bg-[#59C4C4]/10" : "tw-bg-white/10"
        } ${isHovering || isFocused ? BUTTON_CLASSES.hover[theme.name] : ""} ${
          isActive ? BUTTON_CLASSES.active[theme.name] : ""
        }`}
        type="button"
        id="userDropdown"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={() => setOpen((o) => !o)}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onMouseLeave={() => setIsActive(false)}
      >
        <Avatar
          alt={`${currentUser.firstName?.[0]} ${currentUser.lastName?.[0]}`}
          size="none"
          className={`tw-absolute tw-transition ${
            theme.name === "brivity-theme" ? "!tw-ring-theme-primary" : "!tw-ring-neutral-gray-75"
          } ${
            isHovering || isFocused
              ? `tw-left-[-1px] tw-w-[36px] tw-h-[36px] tw-ring-[4px] ${AVATAR_CLASSES.hover[theme.name]}`
              : "tw-left-0 tw-w-[34px] tw-h-[34px] tw-ring-[2px]"
          } ${isActive ? AVATAR_CLASSES.active[theme.name] : ""}
          `}
          src={currentUser.avatar}
        />
        <span
          className={`bs-xs:tw-hidden tw-px-[12px] tw-font-semibold ${TEXT_CLASSES.base[theme.name]} ${
            isHovering || isFocused ? TEXT_CLASSES.hover[theme.name] : ""
          } ${isActive ? TEXT_CLASSES.active[theme.name] : ""}`}
        >
          {currentUser.firstName}
        </span>
      </button>

      <Popover aria-labelledby="userDropdown" className="tw-w-[200px]" open={open}>
        <ul className="tw-m-0 tw-p-0">
          {currentUser.account.uuid ? (
            <>
              <PopoverLink icon={UserSolidV6} href={`/people/${currentUser.person.slug}`} title="Profile" />
              <PopoverLink icon={KeySolidV6} href="/account/personal" title="Account" />
              <PopoverLink icon={GearSolidV6} href="/interaction_templates" title="Settings" />
            </>
          ) : (
            <PopoverLink icon={HouseSolidV6} href="/viewer_listings" title="My Transactions" />
          )}
          <PopoverLink
            icon={RightFromBracketSolidV6}
            method="delete"
            href="/users/sign_out"
            title="Log Out"
          />
        </ul>
      </Popover>
    </div>
  );
};

export default UserDropdown;
