import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as creators from "../actions/creators";
import * as api from "../actions/api";
import Card from "./Card";
import DisplayRow from "./DisplayRow";
import LinkInput from "./UrlInputWrap";

const SECTIONS = [
  { title: "Facebook", key: "facebook", editable: true, comp: LinkInput, placeholder: "Paste Facebook URL" },
  { title: "Twitter", key: "twitter", editable: true, comp: LinkInput, placeholder: "Paste Twitter URL" },
  { title: "LinkedIn", key: "linkedin", editable: true, comp: LinkInput, placeholder: "Paste LinkedIn URL" },
  {
    title: "Instagram",
    key: "instagram",
    editable: true,
    comp: LinkInput,
    placeholder: "Paste Instagram URL",
  },
  { title: "YouTube", key: "youtube", editable: true, comp: LinkInput, placeholder: "Paste YouTube URL" },
  {
    title: "Pinterest",
    key: "pinterest",
    editable: true,
    comp: LinkInput,
    placeholder: "Paste Pinterest URL",
  },
  { title: "TikTok", key: "tiktok", editable: true, comp: LinkInput, placeholder: "Paste TikTok URL" },
];

const SocialCard = ({ model, setValue, saveValue }) => (
  <Card title="Social Media">
    {SECTIONS.map((sec) => (
      <DisplayRow
        key={sec.key}
        title={sec.title}
        editable={sec.editable}
        value={model[sec.key]}
        onChange={(val) => setValue(sec.key, val)}
        onSave={(val) => saveValue(sec.key, val)}
        Comp={sec.comp}
      />
    ))}
  </Card>
);

SocialCard.propTypes = {
  model: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  saveValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.model,
});

export default connect(mapStateToProps, { ...api, ...creators })(SocialCard);
