import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider, useDispatch } from "react-redux";
import IconButton from "@shared/v2/IconButton";
import { PhoneSolidV6 } from "@shared/v2/Icomoon";
import SingleDialerModal from "./SingleDialerModal";
import { setLeadUuid } from "../reducers/dialerReducer/dialerReducer";

const DialerButton = ({ leadUuid }) => {
  const dispatch = useDispatch();
  return (
    <IconButton disabled={!leadUuid} onClick={() => dispatch(setLeadUuid(leadUuid))}>
      <PhoneSolidV6 />
    </IconButton>
  );
};

DialerButton.propTypes = {
  leadUuid: PropTypes.string,
};

DialerButton.defaultProps = {
  leadUuid: null,
};

const SingleDialerApp = ({ leadUuid }) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <DialerButton leadUuid={leadUuid} />
      <SingleDialerModal />
    </Provider>
  );
};

SingleDialerApp.propTypes = {
  leadUuid: PropTypes.string,
};

SingleDialerApp.defaultProps = {
  leadUuid: null,
};

export default SingleDialerApp;
