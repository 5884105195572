import React from "react";
import PropTypes from "prop-types";

import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import Avatar from "../../../shared/v2/Avatar";
import Checkbox from "../../../Transactions/components/shared/Checkbox";

const Option = ({ componentProps, children }) => (
  <Dropdown.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="!tw-flex tw-gap-[8px] tw-items-center tw-group"
    preserveStyle
  >
    <Checkbox checked={componentProps.isSelected} readOnly />
    {children}
  </Dropdown.Option>
);

Option.propTypes = {
  componentProps: PropTypes.shape({
    isSelected: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.shape({}).isRequired,
};

const formatOptionLabel = ({ label, avatarUrl, routingNumber }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-whitespace-nowrap">
    <Avatar className="tw-mr-8px" size="small" src={avatarUrl} alt={label} />
    <span className="tw-overflow-ellipsis tw-w-[90px] tw-font-normal">
      {label} — {routingNumber}
    </span>
  </div>
);

const UsersDropdown = ({ selectedUsers, users, setSelectedUsers, placeholder }) => {
  const userOptions = users.toSorted((a, b) => (a.label > b.label ? 1 : -1));

  const onOptionSelected = (selected) => {
    setSelectedUsers(selected);
  };

  return (
    <Dropdown
      placeholder={placeholder}
      isSearchable
      isMulti
      value={selectedUsers}
      options={userOptions}
      onChange={onOptionSelected}
      formatOptionLabel={formatOptionLabel}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      multiShouldRenderBelowSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      components={{ Option }}
    />
  );
};

UsersDropdown.propTypes = {
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default UsersDropdown;
