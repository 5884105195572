import { connect } from 'react-redux';
import AssignedPlansTable from '../components/AssignedPlansTable';
import * as actions from '../actions/assignedPlansCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({...state.assignedPlans });

// Don't forget to actually use connect!
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, actions)(AssignedPlansTable);
