import React, { useState, useMemo } from "react";

import Toggle from "@shared/v2/Toggle";
import RemovableBubble from "@shared/RemovableBubble";
import { formatPrice } from "../../SearchListings/components/helpers";
import TextButton from "@shared/v2/TextButton";
import {
  communicationDateOptions,
  communicationOptions,
  getDefaultFilters,
  toggleArrayValue,
  setField,
} from "./filters";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import { ChevronDownSolidV6, ChevronUpSolidV6 } from "@shared/v2/Icomoon";

const Options = ({
  location,
  setLocation,
  filters,
  setFilters,
  isMapVisible,
  setIsMapVisible,
  saveSearch,
  currentSort,
  setCurrentSort,
  config,
}) => {
  const [showSortMenu, setShowSortMenu] = useState(false);

  const fieldOptions = useMemo(() => {
    return createOptions(SortFields).reduce((acc, cur) => {
      if (cur.value === "daysOnMarket" && !config.showDaysOnMarketColumn) {
        return acc;
      }

      if (cur.value === "statusDate") {
        acc.push({ label: config.dateName, value: cur.value });
        return acc;
      }

      acc.push(cur);

      return acc;
    }, []);
  }, [config]);

  const sortByLabel = currentSort.field === "statusDate" ? config.dateName : SortFields[currentSort.field];

  return (
    <div className="tw-relative tw-flex tw-justify-between tw-gap-8px">
      <Bubbles
        location={location}
        setLocation={setLocation}
        filters={filters}
        setFilters={setFilters}
        saveSearch={saveSearch}
        config={config}
      />
      <div className="tw-h-[26px] tw-flex tw-flex-row tw-items-center tw-gap-12px">
        <>
          <Button onClick={() => setShowSortMenu(!showSortMenu)} schema="secondary" size="small">
            <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
              Sort By {sortByLabel}{" "}
              {currentSort.order === "asc" ? <ChevronUpSolidV6 size="s" /> : <ChevronDownSolidV6 size="s" />}
            </div>
          </Button>
          {showSortMenu && (
            <SortMenu
              fieldOptions={fieldOptions}
              sortOptions={currentSort}
              setSortOptions={setCurrentSort}
              close={() => setShowSortMenu(false)}
            />
          )}
        </>
        <Toggle
          text="Map"
          checked={isMapVisible}
          onChange={() => setIsMapVisible(!isMapVisible)}
          labelClassName="tw-text-12px tw-text-gray-50 !tw-m-0"
        />
      </div>
    </div>
  );
};

export default Options;

const Bubbles = ({ location, setLocation, filters, setFilters, saveSearch, config }) => {
  const removePairedFilters = (min, max) => {
    setFilters(setField(setField(filters, max, ""), min, ""));
  };

  const removePropertyType = (propertyType) => {
    setFilters(toggleArrayValue(filters, "propertyTypes", propertyType));
  };

  const removeCommunicationsFilters = () => {
    setFilters(setField(setField(filters, "communication", null), "communicationRange", null));
  };

  const removeDateFilters = () => {
    setFilters(setField(setField(filters, "statusDateMin", ""), "statusDateMax", ""));
  };

  const clearAll = () => {
    setFilters(getDefaultFilters());
    setLocation(null);
  };

  const createBubbles = () => {
    const bubbles = [];
    if (location) {
      bubbles.push(
        <RemovableBubble
          key="location-query"
          value={location.isRadius ? location.name : "Polygon Search"}
          remove={() => setLocation(null)}
          includeMargins={false}
        />,
      );
    }

    FilterDataForBubbles.forEach(({ desc, min, max, asPrice }) => {
      const text = createFilterText(desc, filters[min], filters[max], asPrice);
      if (text) {
        bubbles.push(
          <RemovableBubble
            value={text}
            key={text}
            remove={() => removePairedFilters(min, max)}
            includeMargins={false}
          />,
        );
      }
    });

    if (filters.propertyTypes?.length) {
      filters.propertyTypes.forEach((pt) => {
        bubbles.push(
          <RemovableBubble
            value={pt}
            key={pt}
            remove={() => removePropertyType(pt)}
            includeMargins={false}
          />,
        );
      });
    }

    if (filters.communication) {
      const firstBit = `Communication: ${communicationOptions.find((c) => c.value === filters.communication).label}`;
      const secondBit = ` ${filters.communicationRange ? communicationDateOptions.find((c) => c.value === filters.communicationRange).label.toLowerCase() : ""}`;

      bubbles.push(
        <RemovableBubble
          value={firstBit + secondBit}
          key={firstBit}
          remove={() => removeCommunicationsFilters()}
          includeMargins={false}
        />,
      );
    }

    if (filters.statusDateMin || filters.statusDateMax) {
      let dateFilterText = `${config.dateName}:`;
      if (filters.statusDateMin && filters.statusDateMax) {
        dateFilterText += `${filters.statusDateMin} - ${filters.statusDateMax}`;
      } else if (filters.statusDateMin) {
        dateFilterText += `After ${filters.statusDateMin}`;
      } else {
        dateFilterText += `Before ${filters.statusDateMax}`;
      }

      bubbles.push(
        <RemovableBubble
          value={dateFilterText}
          key={dateFilterText}
          remove={() => removeDateFilters()}
          includeMargins={false}
        />,
      );
    }

    if (bubbles.length) {
      bubbles.push(
        <TextButton
          key="save-search"
          size="small"
          schema="default"
          children="Save Search"
          onClick={saveSearch}
        />,
        <TextButton
          key="clear-all"
          size="small"
          schema="remove"
          children="Clear All"
          onClick={() => clearAll()}
        />,
      );
    }

    return bubbles;
  };

  return <div className="tw-flex tw-flex-row tw-gap-4px tw-flex-wrap">{createBubbles()}</div>;
};

const FilterDataForBubbles = [
  {
    desc: "Price",
    min: "priceMin",
    max: "priceMax",
    asPrice: true,
  },
  {
    desc: "Square Feet",
    min: "sqftMin",
    max: "sqftMax",
  },
  {
    desc: "Beds",
    min: "bedsMin",
    max: "bedsMax",
  },
  {
    desc: "Baths",
    min: "bathsMin",
    max: "bathsMax",
  },
  {
    desc: "Lot Size",
    min: "lotSizeMin",
    max: "lotSizeMax",
  },
];

const createFilterText = (desc, min, max, asPrice = false) => {
  if (!min && !max) {
    return;
  }

  const minFormatted = asPrice ? formatPrice(min) : min;
  const maxFormatted = asPrice ? formatPrice(max) : max;

  return minFormatted === maxFormatted
    ? `${desc}: ${minFormatted}`
    : `${desc}: ${minFormatted || "No Min"} - ${maxFormatted || "No Max"}`;
};

const SortMenu = ({ fieldOptions, sortOptions, setSortOptions, close }) => {
  const [order, setOrder] = useState(sortOptions?.order);
  const [field, setField] = useState(sortOptions?.field);

  const saveSort = () => {
    setSortOptions({ order, field });
    close();
  };

  return (
    <>
      <div className="tw-absolute tw-flex tw-items-center tw-right-[90px] tw-top-32px tw-flex-row tw-w-[353px] tw-h-[68px] tw-p-16px tw-z-50 tw-bg-white tw-shadow-modal">
        <div className="tw-mr-12px tw-w-[123px]">
          <Dropdown
            isSearchable={false}
            isClearable={false}
            options={fieldOptions}
            onChange={({ value }) => setField(value)}
            value={fieldOptions.find((f) => f.value === field)}
          />
        </div>
        <div className="tw-mr-20px tw-w-[123px]">
          <Dropdown
            isSearchable={false}
            isClearable={false}
            options={orderOptions}
            onChange={({ value }) => setOrder(value)}
            value={orderOptions.find((o) => o.value === order)}
          />
        </div>
        <Button schema="primary" size="small" onClick={saveSort}>
          Save
        </Button>
      </div>
      <div className="tw-fixed tw-z-40 tw-inset-0 tw-bg-transparent" onClick={close}></div>
    </>
  );
};

const SortOrder = {
  asc: "Ascending",
  desc: "Descending",
};

const SortFields = {
  streetAddress: "Address",
  statusDate: "Status Date",
  daysOnMarket: "Days on Market",
  beds: "Beds",
  baths: "Baths",
  sqft: "SQFT",
  lotSize: "Lot Size",
  yearBuilt: "Year Built",
};

const createOptions = (opts) => {
  return Object.entries(opts).map(([value, label]) => {
    return { value, label };
  });
};

const orderOptions = createOptions(SortOrder);
