import { useEffect, useState } from "react";

/**
 * @summary Hook for matching media queries in JavaScript
 * @param {string} query The media query e.g. (max-width: 480px)
 */
const useMediaQuery = (query) => {
  const [isUp, setIsUp] = useState(false);

  useEffect(() => {
    const matcher = window.matchMedia(query);
    const onChange = (m) => setIsUp(m.matches);
    onChange(matcher);
    matcher.addEventListener("change", onChange);
    return () => matcher.removeEventListener("change", onChange);
  }, []);

  return isUp;
};

export default useMediaQuery;
