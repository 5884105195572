import { schemaGenerator } from "../../utils";

const base = {
  default: [
    "tw-p-0",
    "tw-border-0",
    "tw-w-[24px]",
    "tw-h-[24px]",
    "tw-leading-[24px]",
    "tw-text-center",
    "tw-text-14d",
    "tw-font-semibold",
    "tw-rounded-full",
  ],
  focusVisible: ["focus-visible:tw-outline-theme-offset-1px"],
};

const dictionary = {
  regular: {
    default: [...base.default, "tw-text-neutral-gray-50", "tw-cursor-pointer", "tw-bg-transparent"],
    active: ["active:tw-text-theme-flat-percent-toggle-active"],
    hover: ["hover:tw-text-theme-flat-percent-toggle-hover"],
    focusVisible: base.focusVisible,
  },
  selected: {
    default: [
      ...base.default,
      "tw-bg-theme-flat-percent-toggle",
      "tw-text-theme-flat-percent-toggle-selected",
      "tw-cursor-default",
      "tw-select-none",
    ],
    focusVisible: base.focusVisible,
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
