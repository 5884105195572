import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import * as actions from "../actions/default";
import * as apiActions from "../api/index";
import SelectAll from "./SelectAll";
import { showElement, selectOption } from "./SelectOption";
import { CircleInfoSolidV6 } from "../../shared/v2/Icomoon";

const mapStateToProps = (state) => ({
  autoPlansFeature: state.leadIndex.autoPlansFeature,
  from: state.leadIndex.from,
  hotLeadsFeature: state.leadIndex.hotLeadsFeature,
});

const LeadHeaders = ({ autoPlansFeature, from, hotLeadsFeature }) => {
  const [orderBy, setorderBy] = useState("");
  const [orderDirection, setorderDirection] = useState("");
  const [getcall, setgetcall] = useState(false);

  const getPeople = () => {
    showElement(document.getElementsByClassName("loading-overlay")[0]);
    selectOption("select-none");
    let url = "/people/all_people";
    url = window.Brivity.addParameter(window.location.href, "order_by", `${orderBy}+${orderDirection}`);
    url = window.Brivity.addParameter(url, "order_scope", "");
    window.Turbolinks.cacheCurrentPage();
    window.Turbolinks.visit(url);
  };

  useEffect(() => {
    if (!getcall && orderBy && orderDirection) {
      getPeople();
    } else if (!getcall) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.order_by) {
        const getorder = params.order_by.split(" ");
        const direction = getorder.pop();
        const orderbyname = getorder.join(" ");
        if (direction && orderbyname) {
          setgetcall(true);
          setorderBy(orderbyname);
          setorderDirection(direction);
        }
      }
    }
  }, [orderBy, orderDirection]);

  const orderByname = (valueToOrderBy) => {
    if (orderBy !== valueToOrderBy) {
      setorderDirection("desc");
    } else {
      setorderDirection(orderDirection === "desc" ? "asc" : "desc");
    }
    setorderBy(valueToOrderBy);
    setgetcall(false);
  };
  const selectedArrow = (name, direction) => {
    if (name === orderBy && direction === orderDirection) {
      return "selected";
    }
    return "";
  };
  return (
    <>
      <thead className="people-header">
        <tr>
          <th id="select-dropdown-header" aria-label="select all">
            <SelectAll />
          </th>
          <th>
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_name")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Name
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_name", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("last_name", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>
          {from === "leads" ? (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <th className="finish-lead">&nbsp;</th>
          ) : (
            <th className="display-in-leads">
              <h6>Show in Leads</h6>
            </th>
          )}

          <th className="intent">
            <h6 className="tw-m-0 tw-pl-[14px]">Intent</h6>
          </th>

          <th className="tw-min-w-[150px] tw-w-[150px] status-header text-left">
            <div className="tw-flex tw-justify-center">
              <button
                type="button"
                className=" tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
                onClick={() => orderByname("status")}
              >
                <h6 className="tw-mt-0 tw-mb-0">
                  Status
                  <span className="arrows">
                    <i className={`fa fa-sort-up ${selectedArrow("status", "asc")}`} />
                    <i className={`fa fa-sort-down ${selectedArrow("status", "desc")}`} />
                  </span>
                </h6>
              </button>
            </div>
          </th>

          <th className="tw-min-w-[150px] tw-w-[150px] status-header text-left">
            <div className="tw-flex tw-justify-center tw-mr-[30px]">
              <button
                type="button"
                className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
                onClick={() => orderByname("stage")}
              >
                <h6 className="tw-mt-0 tw-mb-0">
                  Stage
                  <Tooltip
                    className="tw-pl-[4px]"
                    placement="top"
                    trigger={<CircleInfoSolidV6 className="tw-text-gray-75 tw-align-sub" size="m" />}
                    content="Stages describe where a lead is in your pipeline process"
                  />
                  <span className="arrows">
                    <i className={`fa fa-sort-up ${selectedArrow("stage", "asc")}`} />
                    <i className={`fa fa-sort-down ${selectedArrow("stage", "desc")}`} />
                  </span>
                </h6>
              </button>
            </div>
          </th>

          <th className="interaction-icon">
            <h6>Email</h6>
          </th>
          <th className="interaction-icon">
            <h6>Phone</h6>
          </th>

          <th className="interaction-icon">
            <h6>SMS</h6>
          </th>

          <th className="interaction-icon">
            <h6>Appt.</h6>
          </th>

          <th className="interaction-icon">
            <h6>Other</h6>
          </th>

          <th className="address">
            <h6>Address</h6>
          </th>

          <th className="last-interaction text-center">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_interaction_interacted_at")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Interaction
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_interaction_interacted_at", "asc")}`} />
                  <i
                    className={`fa fa-sort-down ${selectedArrow("last_interaction_interacted_at", "desc")}`}
                  />
                </span>
              </h6>
            </button>
          </th>

          <th>
            <h6 className="tw-pl-[40px]">Next Due Tasks</h6>
          </th>

          <th className="primary-agent">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("primary_agent_full_name")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Primary Agent
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("primary_agent_full_name", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("primary_agent_full_name", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          {autoPlansFeature && (
            <th className="action-plans text-center">
              <button
                type="button"
                className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
                onClick={() => orderByname("assigned_plans_activity_date")}
              >
                <h6 className="tw-mt-0 tw-mb-0">
                  Auto Plans
                  <span className="arrows">
                    <i className={`fa fa-sort-up ${selectedArrow("assigned_plans_activity_date", "asc")}`} />
                    <i
                      className={`fa fa-sort-down ${selectedArrow("assigned_plans_activity_date", "desc")}`}
                    />
                  </span>
                </h6>
              </button>
            </th>
          )}

          <th className="stat milestone-date-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("birthdate")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Birthdate
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("birthdate", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("birthdate", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat milestone-date-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("anniversary")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Home Anniversary
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("anniversary", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("anniversary", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="action-plans text-center">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("home_app_invite_sent")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Home App
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("home_app_invite_sent", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("home_app_invite_sent", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          {hotLeadsFeature && (
            <th className="stat">
              <button
                type="button"
                className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
                onClick={() => orderByname("hot_lead_score")}
              >
                <h6 className="tw-mt-0 tw-mb-0">
                  Hot Score
                  <span className="arrows">
                    <i className={`fa fa-sort-up ${selectedArrow("hot_lead_score", "asc")}`} />
                    <i className={`fa fa-sort-down ${selectedArrow("hot_lead_score", "desc")}`} />
                  </span>
                </h6>
              </button>
            </th>
          )}

          <th className="stat last-visit-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_last_visit")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Visit
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_last_visit", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_last_visit", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>
          <th className="stat">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_visits")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Visits
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_visits", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_visits", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_property_views")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Views
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_property_views", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_property_views", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="barely-wider-stat">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_average_price")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Avg. View Price
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_average_price", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_average_price", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat favorites-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_favorites")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Favorites
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_favorites", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_favorites", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="avg-favorites-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_average_price_favorited")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Avg. Fav. Price
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_average_price_favorited", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_average_price_favorited", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("idx_alerts")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Listing Alerts
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("idx_alerts", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("idx_alerts", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_sent_listing_alert")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Sent
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_sent_listing_alert", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("last_sent_listing_alert", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_opened_listing_alert")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Opened
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_opened_listing_alert", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("last_opened_listing_alert", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("market_report_count")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Market Reports
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("market_report_count", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("market_report_count", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_opened_market_report")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Opened
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_opened_market_report", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("last_opened_market_report", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="stat med-width-col">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("last_viewed_market_report")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Last Viewed
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("last_viewed_market_report", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("last_viewed_market_report", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="source">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("sources.name")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Source
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("sources.name", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("sources.name", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          <th className="submitted">
            <button
              type="button"
              className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
              onClick={() => orderByname("submitted_at")}
            >
              <h6 className="tw-mt-0 tw-mb-0">
                Submitted
                <span className="arrows">
                  <i className={`fa fa-sort-up ${selectedArrow("submitted_at", "asc")}`} />
                  <i className={`fa fa-sort-down ${selectedArrow("submitted_at", "desc")}`} />
                </span>
              </h6>
            </button>
          </th>

          {(from === "collaborators" || from === "teams") && (
            <th className="tw-min-w-[140px] tw-w-[140px]">
              <h6 className="tw-ml-[30px] tw-justify-center	tw-flex">Transaction Role</h6>
            </th>
          )}
          {from === "teams" && (
            <th className="tw-min-w-[140px] tw-w-[140px]">
              <button
                type="button"
                className="tw-p-0 focus:tw-outline-none sort-header tw-bg-transparent tw-border-0"
                onClick={() => orderByname("user_type")}
              >
                <h6 className="tw-ml-[30px] tw-mt-0 tw-mb-0 tw-justify-center	tw-flex">
                  User Type
                  <span className="arrows">
                    <i className={`fa fa-sort-up ${selectedArrow("user_type", "asc")}`} />
                    <i className={`fa fa-sort-down ${selectedArrow("user_type", "desc")}`} />
                  </span>
                </h6>
              </button>
            </th>
          )}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className="icon">&nbsp;</th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th className="icon">&nbsp;</th>
        </tr>
      </thead>
    </>
  );
};
LeadHeaders.propTypes = {
  autoPlansFeature: Proptypes.bool,
  from: Proptypes.string,
  hotLeadsFeature: Proptypes.bool,
};
LeadHeaders.defaultProps = {
  autoPlansFeature: false,
  from: null,
  hotLeadsFeature: false,
};
export default connect(mapStateToProps, { ...actions, ...apiActions })(LeadHeaders);
