import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Linkify from "linkify-react";
import Button from "@shared/v2/Button/Button";
import AiCallSummary from "@shared/AiAssistant/AiCallSummary";
import modal_css from "../../../PersonDetail/components/modal.module.css";
import PhoneCallDetails from "../../../Interactions/PhoneCall/components/PhoneCallDetails";
import { updateInteractionPromise } from "../../../PersonDetail/actions/updateInteraction";
import { PhoneSolidV6 } from "@shared/v2/Icomoon";

const mentionMap = new Map();

export default class PublicActivityCallEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false,
      editableInteraction: {
        ...props.event.object_attributes,
        comment: this.calculateCallContent(this.props.event.object_attributes.markdown_comment),
      },
      editableInteractionHasErrors: false,
      editableInteractionErrorMessages: [],
      isSaving: false,
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.headline = this.headline.bind(this);
    this.details = this.details.bind(this);
    this.callResult = this.callResult.bind(this);
    this.callDuration = this.callDuration.bind(this);
    this.notes = this.notes.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.event.object_attributes !== this.props.event.object_attributes) {
      this.setState({
        editableInteraction: {
          ...this.props.event.object_attributes,
          comment: this.calculateCallContent(this.props.event.object_attributes.markdown_comment),
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, ...nextProps });
  }

  calculateCallContent(comment) {
    return comment.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, name, id) => {
      mentionMap.set(id, name);
      return `${window.location.host}/people/${id}`;
    });
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({
      show_modal: false,
      editableInteraction: {
        ...this.props.event.object_attributes,
        comment: this.calculateCallContent(this.props.event.object_attributes.markdown_comment),
      },
      editableInteractionHasErrors: false,
      isSaving: false,
      editableInteractionErrorMessages: [],
    });
  };

  headline = () => {
    const agent_person = this.props.event.agent_person;
    const raw_from = `${agent_person.first_name} ${agent_person.last_name}`.trim();
    const fromString = raw_from.trim().length > 0 ? raw_from : "An unnamed agent";

    const duration = this.props.event.object_attributes.duration;
    let callContext = "";

    if (duration == null) {
      callContext = " logged a call";
    } else {
      callContext = " called this contact";
    }

    return (
      <div>
        <span className="bold-detail">{fromString}</span>
        {callContext}
      </div>
    );
  };

  callResult = () => {
    const result = this.props.event.object_attributes.outcome;

    switch (result) {
      case "talked_to_lead":
        return "Talked";
      case "left_message":
        return "Left Voicemail";
      case "busy":
        return "Busy";
      case "failed":
        return "Call Failed";
      case "no_answer":
        return "No Answer";
      case "wrong_number":
        return "Wrong Number";
      case "canceled":
        return "Canceled";
      case "inbound_call":
        return "Inbound Call";
      default:
        return "Unknown";
    }
  };

  callDuration = (durationSeconds) => {
    const hours = Math.floor(durationSeconds / 60 / 60);
    const minutes = Math.floor((durationSeconds / 60) % 60);
    const seconds = Math.floor(durationSeconds % 60);

    const hoursString = hours.toString().padStart(2, "0");
    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = seconds.toString().padStart(2, "0");

    return (
      <div className="row details-row">Duration: {`${hoursString}:${minutesString}:${secondsString}`}</div>
    );
  };

  notes = () => {
    return (
      <div className="timeline-description-text">
        Notes:&nbsp;
        <Linkify
          onClick={(e) => e.stopPropagation()}
          className="tw-whitespace-pre-wrap tw-break-words"
          tagName="span"
          options={{
            defaultProtocol: "https",
            target: "_blank",
            render: {
              url: ({ attributes, content }) => {
                const segments = content.split("/");
                const potentialMentionId = segments[segments.length - 1];
                return (
                  <a className="tw-text-theme-primary" {...attributes}>
                    {mentionMap.has(potentialMentionId) ? `@${mentionMap.get(potentialMentionId)}` : content}
                  </a>
                );
              },
            },
          }}
        >
          {this.state.editableInteraction.comment}
        </Linkify>
      </div>
    );
  };

  details = () => {
    const durationSeconds = this.props.event.object_attributes.duration;
    const notes = this.props.event.object_attributes.markdown_comment;

    return (
      <div>
        <div className="row details-row">Call Result: {this.callResult()}</div>
        {durationSeconds != null && this.callDuration(durationSeconds)}
        {!!notes && this.notes()}
      </div>
    );
  };

  row = () => {
    return (
      <div className="timeline-event tw-px-[20px] tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-flex tw-gap-[16px]" onClick={this.showModal}>
          <div className="tw-flex tw-justify-center tw-items-center tw-w-[40px] tw-h-[40px] tw-flex-none tw-rounded-full tw-border-solid tw-border-[2px]">
            <PhoneSolidV6 size="l" />
          </div>
          <div className="tw-flex-1">
            <span className="active">{this.headline()}</span>
            <div className="timeline-description-text">{this.details()}</div>
          </div>
          <div className="time-ago">{this.props.event.interacted_at_absolute}</div>
        </div>
        <AiCallSummary callSid={this.props.event.object_attributes.call_sid} />
      </div>
    );
  };

  updateEditableInteraction = (name, value) => {
    if (name == "comment") {
      this.setState({
        editableInteraction: {
          ...this.state.editableInteraction,
          comment: value,
          markdown_comment: value,
        },
      });
    } else {
      this.setState({ editableInteraction: { ...this.state.editableInteraction, [name]: value } });
    }
  };

  submitEditableInteraction = () => {
    this.setState({
      isSaving: true,
    });
    updateInteractionPromise(this.state.editableInteraction)
      .then((r) => {
        this.setState({
          show_modal: false,
          editableInteractionHasErrors: false,
          isSaving: false,
          editableInteractionErrorMessages: [],
        });
        this.props.submitUpdateInteraction(r.data);
      })
      .catch((e) => {
        this.setState({
          editableInteractionHasErrors: true,
          isSaving: false,
          editableInteractionErrorMessages: e.data.errors,
        });
      });
  };

  modal = () => {
    return (
      this.state.show_modal && (
        <Modal bsSize="lg" show={this.state.show_modal} onHide={this.hideModal}>
          <div className="person-details-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                <div className={modal_css.modalTitle}>Edit Call</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-xs-12">
                  <PhoneCallDetails
                    isTimelineEvent
                    interaction={this.state.editableInteraction}
                    notableId={this.props.event.notable_id}
                    updateValues={this.updateEditableInteraction}
                    hasError={this.state.editableInteractionHasErrors}
                    errorMessage={this.state.editableInteractionErrorMessages}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 m-t-20">
                  <div className="d-flex flex-justify-space-between">
                    <Button schema="tertiary" size="medium" onClick={this.hideModal}>
                      CANCEL
                    </Button>
                    <Button
                      schema="primary"
                      size="medium"
                      onClick={(e) => {
                        this.submitEditableInteraction();
                      }}
                      disabled={this.state.isSaving}
                    >
                      {this.state.isSaving && <i className="fa fa-pulse fa-spinner tw-pr-[4px]" />}
                      SAVE
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
