import React, { useEffect, useRef } from "react";
import MlsListingRow from "./MlsListingRow";
import HeaderCell from "../../Transactions/components/shared/HeaderCell";
import { dynamicRow } from "../../Transactions/utils/dynamicRow";

const SearchListingsTable = ({
  mlsListings,
  onShowDetails,
  onShowAgentDetails,
  agentDetailsEnabled,
  onToggleShare,
  maxListingsSelected,
  highlightedListing,
  selectedListings,
  availableHeight,
  currentSort,
  onSort,
  buyerMatches
}) => {
  const rowRefs = useRef({});

  const style = {
    maxHeight: `${availableHeight}px`,
    flexBasis: "100%",
    marginLeft: "-20px",
    marginRight: "-20px",
  };
  const padding = "tw-px-20px tw-pb-8px tw-pt-0";
  const sortOptions = ["asc", "desc"];
  const getSortOrder = (field) => (currentSort?.field === field ? currentSort.order : null);

  const headerColumns = dynamicRow(allColumns, [
    ["checkbox", <HeaderCell key="checkbox" px="tw-p-0 tw-sticky tw-left-0 tw-z-30 tw-bg-white" title="" fontSettings="tw-text-12px tw-font-semibold" />],
    [
      "address",
      <HeaderCell key="address" px={`${padding} tw-sticky tw-left-[36px] tw-z-30 tw-bg-white`} title="Address" fontSettings="tw-text-12px tw-font-semibold" />,
    ],
    ["buyerMatches", <HeaderCell key="buyerMatches" px={padding} justify="tw-justify-center" title="Buyer Matches" fontSettings="tw-text-12px tw-font-semibold" />],
    ["mlsNum", <HeaderCell key="mlsNum" px={padding} title="MLS#" fontSettings="tw-text-12px tw-font-semibold" />],
    ["status", <HeaderCell key="status" px={padding} title="Status" fontSettings="tw-text-12px tw-font-semibold" />],
    [
      "price",
      <HeaderCell
        key="price"
        px={padding}
        justify="tw-justify-end"
        title="List Price"
        isSortable
        sortOptions={sortOptions}
        sortedBy={getSortOrder("price")}
        onSort={(order) => onSort("price", order)}
        fontSettings="tw-text-12px tw-font-semibold"
      />,
    ],
    [
      "listDate",
      <HeaderCell
        key="listDate"
        px={padding}
        title="List Date"
        isSortable
        sortOptions={sortOptions}
        sortedBy={getSortOrder("listDate")}
        onSort={(order) => onSort("listDate", order)}
        fontSettings="tw-text-12px tw-font-semibold"
      />,
    ],
    ["city", <HeaderCell key="city" px={padding} title="City" fontSettings="tw-text-12px tw-font-semibold" />],
    ["neighborhood", <HeaderCell key="neighborhood" px={padding} title="Neighborhood" fontSettings="tw-text-12px tw-font-semibold" />],
    [
      "beds",
      <HeaderCell
        key="beds"
        px={padding}
        title="Beds"
        isSortable
        sortOptions={sortOptions}
        sortedBy={getSortOrder("bedrooms")}
        onSort={(order) => onSort("bedrooms", order)}
        fontSettings="tw-text-12px tw-font-semibold"
      />,
    ],
    [
      "totalBaths",
      <HeaderCell
        key="totalBaths"
        px={padding}
        title="Baths"
        isSortable
        sortOptions={sortOptions}
        sortedBy={getSortOrder("bathsTotalDecimal")}
        onSort={(order) => onSort("bathsTotalDecimal", order)}
        fontSettings="tw-text-12px tw-font-semibold"
      />,
    ],
    [
      "sqft",
      <HeaderCell
        key="sqft"
        px={padding}
        justify="tw-justify-end"
        title="SQFT"
        isSortable
        sortOptions={sortOptions}
        sortedBy={getSortOrder("squareFeet")}
        onSort={(order) => onSort("squareFeet", order)}
        fontSettings="tw-text-12px tw-font-semibold"
      />,
    ],
    ["ppsf", <HeaderCell key="ppsf" px={padding} justify="tw-justify-end" title="$/SF" fontSettings="tw-text-12px tw-font-semibold" />],
    ["lotSize", <HeaderCell key="lotSize" px={padding} title="Lot Size (Acres)" fontSettings="tw-text-12px tw-font-semibold" />],
    ["yearBuilt", <HeaderCell key="yearBuilt" px={padding} title="Year Built" fontSettings="tw-text-12px tw-font-semibold" />],
    ["propertyType", <HeaderCell key="propertyType" px={padding} title="Property Type" fontSettings="tw-text-12px tw-font-semibold" />],
    ["hoaFee", <HeaderCell key="hoaFee" px={padding} justify="tw-justify-end" title="HOA Fee" fontSettings="tw-text-12px tw-font-semibold" />],
    ["listedBy", <HeaderCell key="listedBy" px={padding} title="Listed By" fontSettings="tw-text-12px tw-font-semibold" />],
  ]);

  const isListingSelected = (blossorId) => {
    return selectedListings.some((l) => l.blossorId === blossorId);
  };

  useEffect(() => {
    if (rowRefs.current[highlightedListing]) {
      rowRefs.current[highlightedListing].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [highlightedListing]);

  return (
    <div
      style={style}
      className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0 transactions-table-container"
    >
      <table
        className="tw-border-separate tw-overflow-scroll tw-table-auto tw-flex-nowrap"
        style={{ borderSpacing: 0 }}
      >
        <thead>
          <tr className="tw-text-gray-50 tw-h-32px tw-bg-white tw-sticky tw-top-0 tw-z-20 tw-whitespace-nowrap">
            {headerColumns}
          </tr>
        </thead>
        <tbody className="tw-text-gray-99">
          {mlsListings.map((l, index) => (
            <MlsListingRow
              key={l.blossorId}
              ref={(e) => (rowRefs.current[l.blossorId] = e)}
              listing={l}
              columns={allColumns}
              isFirstRow={index === 0}
              selected={isListingSelected(l.blossorId)}
              highlighted={l.blossorId === highlightedListing}
              onToggleShare={onToggleShare}
              maxListingsSelected={maxListingsSelected}
              onShowDetails={onShowDetails}
              onShowAgentDetails={onShowAgentDetails}
              agentDetailsEnabled={agentDetailsEnabled}
              buyerMatches={buyerMatches[l.blossorId]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchListingsTable;

// Starting values when not yet saved in db
const allColumns = {
  checkbox: { active: true, position: 1 },
  address: { active: true, position: 2 },
  buyerMatches: {active: true, position: 3},
  mlsNum: { active: true, position: 4 },
  status: { active: true, position: 5 },
  price: { active: true, position: 6 },
  listDate: { active: true, position: 8 },
  city: { active: true, position: 8 },
  neighborhood: { active: true, position: 9 },
  beds: { active: true, position: 10 },
  totalBaths: { active: true, position: 11 },
  fullBaths: { active: true, position: 12 },
  sqft: { active: true, position: 13 },
  ppsf: { active: true, position: 14 },
  lotSize: { active: true, position: 15 },
  yearBuilt: { active: true, position: 16 },
  propertyType: { active: true, position: 17 },
  hoaFee: { active: true, position: 18 },
  listedBy: { active: true, position: 19 },
};
