import React from "react";
import Avatar from "@shared/v2/Avatar";

const UserListing = ({ name = "", imageUrl = "" }) => (
  <div className="tw-flex tw-items-center">
    <Avatar className="tw-mr-4" size="medium" src={imageUrl} alt={`${name}`} />
    <span className="">{name}</span>
  </div>
);

export default UserListing;
