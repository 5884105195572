import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import TollFreeAssignForm from "./TollFreeAssignForm";
import TollFreeTable from "./TollFreeTable";

const TwilioTollFree = ({ users, tollFreeNumbers, account }) => {
  const [usersState, setUsersState] = useState(users);
  const [tollFreeNumbersState, setTollFreeNumbersState] = useState(tollFreeNumbers);

  const unassignedTollFreeNumbers = tollFreeNumbersState.filter((t) => t.user_id === null);
  const usersWithoutTollFreeNumbers = usersState.filter(
    (u) => !tollFreeNumbersState.find((t) => t.user_id === u.id),
  );

  const assignPhone = (userId, tollFreeNumberId) => {
    axios
      .post("/toll_free/assign", {
        account_id: account.id,
        user_id: userId,
        toll_free_number_id: tollFreeNumberId,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        setUsersState(response.data.users);
        setTollFreeNumbersState(response.data.toll_free_numbers);
      })
      .catch(() => {});
  };

  const unassignPhone = (tollFreeNumberId) => {
    axios
      .post("/toll_free/unassign", {
        account_id: account.id,
        toll_free_number_id: tollFreeNumberId,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        setUsersState(response.data.users);
        setTollFreeNumbersState(response.data.toll_free_numbers);
      })
      .catch(() => {});
  };

  const buyPhone = () => {
    axios
      .post("/toll_free/buy_phone", {
        account_id: account.id,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        setUsersState(response.data.users);
        setTollFreeNumbersState(response.data.toll_free_numbers);
      })
      .catch(() => {});
  };

  return (
    <div className="row">
      <div className="col-xs-10 col-xs-offset-1 card-rounded">
        <div className="row">
          <h1>Toll-Free</h1>

          <TollFreeAssignForm
            users={usersWithoutTollFreeNumbers}
            tollFreeNumbers={unassignedTollFreeNumbers}
            assignPhone={assignPhone}
            buyPhone={buyPhone}
          />

          <TollFreeTable tollFreeNumbers={tollFreeNumbersState} users={users} unassignPhone={unassignPhone} />
        </div>
      </div>
    </div>
  );
};

TwilioTollFree.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tollFreeNumbers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  account: PropTypes.shape().isRequired,
};

export default TwilioTollFree;
