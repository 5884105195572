import React from "react";
import { connect } from "react-redux";
import { bool, shape, func, arrayOf } from "prop-types";

import { deleteAllSelectedImages } from "../actions/thunks";
import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";

const ModalDeleteImages = ({ isModalOpen, toggleModal, checked, onDeleteImages }) => (
  <Modal
    show={isModalOpen}
    onHide={() => toggleModal((prev) => !prev)}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    closeButton
  >
    <Modal.Header title={`Delete ${checked?.length} Images?`} className="tw-mb-32px" closeButton />
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={() => toggleModal((prev) => !prev)}>
        Cancel
      </Button>
      <Button
        size="medium"
        schema="primary"
        onClick={() => {
          onDeleteImages(checked);
          toggleModal((prev) => !prev);
        }}
      >
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapStateToProps = (state) => ({
  checked: state.tdpDetailsReducer.listingImgs.filter(({ checked }) => checked),
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteImages: (imgs) => dispatch(deleteAllSelectedImages(imgs)),
});

ModalDeleteImages.propTypes = {
  isModalOpen: bool,
  toggleModal: func,
  checked: arrayOf(shape()),
  onDeleteImages: func,
};

ModalDeleteImages.defaultProps = {
  isModalOpen: false,
  toggleModal: () => {},
  checked: null,
  onDeleteImages: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteImages);
