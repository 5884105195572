import React from "react";
import Proptypes from "prop-types";
import IntentDropdown from "./IntentDropdown";

const IntentDetails = ({ intent, currentref, handleToggle, setshow }) => (
  <>
    {intent && intent !== null && intent !== "n/a" ? (
      <>
        {(() => {
          if (intent === "seller/buyer") {
            return (
              <>
                <div className="intent-box s">S</div>
                <div className="tw-ml-4px intent-box b">B</div>
              </>
            );
          }
          if (["S", "B", "T", "R", "C", "L"].includes(intent.charAt(0).toUpperCase())) {
            return (
              <div className={`intent-box ${intent.charAt(0).toLowerCase()}`}>
                {intent.charAt(0).toUpperCase()}
              </div>
            );
          }
          return <IntentDropdown currentref={currentref} handleToggle={handleToggle} setshow={setshow} />;
        })()}
      </>
    ) : (
      <IntentDropdown currentref={currentref} handleToggle={handleToggle} setshow={setshow} />
    )}
  </>
);

IntentDetails.propTypes = {
  intent: Proptypes.string,
  handleToggle: Proptypes.func,
  currentref: Proptypes.shape({}),
  setshow: Proptypes.func,
};
IntentDetails.defaultProps = {
  intent: null,
  handleToggle: null,
  currentref: null,
  setshow: null,
};

export default IntentDetails;
