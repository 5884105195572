import React from "react";
import PropTypes from "prop-types";
import FeedbackVisibility from "../FeedbackVisibility";
import { Edit, Trash } from "../../../../shared/v2/Icomoon";
import Toggle from "../../../../shared/v2/Toggle";

const FeedbackMenuDropdown = ({
  externallyVisible,
  isVisibilityToggleEnabled,
  onEdit,
  onChangeVisibility,
  onDelete,
  className,
}) => (
  <div
    className={`tw-w-220px tw-rounded-4px tw-py-4px tw-bg-white tw-shadow-modal ${className}`}
    data-cy="feedback-menu-dropdown"
  >
    <button
      type="button"
      className="tw-flex tw-flex-row tw-items-center tw-space-x-12px tw-w-full tw-h-36px tw-px-12px tw-py-6px tw-border-0 tw-bg-transparent hover:tw-cursor-pointer hover:tw-bg-tinted-gray-50 active:tw-bg-tinted-gray-100"
      onClick={onEdit}
      data-cy="feedback-menu-dropdown-edit-button"
    >
      <Edit size="m" />
      <span className="tw-text-12d tw-text-neutral-gray-75 tw-font-semibold tw-select-none">Edit</span>
    </button>
    <div className="tw-flex tw-flex-row tw-justify-between tw-h-36px tw-px-12px tw-py-6px hover:tw-bg-tinted-gray-50">
      <div className="tw-flex tw-flex-row tw-items-center tw-space-x-12px">
        <FeedbackVisibility
          externallyVisible={externallyVisible}
          iconSize="m"
          data-cy={`feedback-menu-dropdown-externally-${externallyVisible ? "visible" : "hidden"}`}
        />
        <span className="tw-text-12d tw-text-neutral-gray-75 tw-font-semibold tw-select-none">
          {`${externallyVisible ? "Visible to" : "Hidden from"} Viewers`}
        </span>
      </div>
      <div className="tw-my-auto">
        <Toggle
          id="externallyVisible"
          name="externallyVisible"
          disabled={!isVisibilityToggleEnabled}
          checked={externallyVisible}
          onChange={() => onChangeVisibility(!externallyVisible)}
          data-cy="feedback-menu-dropdown-externally-visible-toggle"
        />
      </div>
    </div>
    <hr className="tw-w-full tw-border-neutral-gray-10 tw-my-4px" />
    <button
      type="button"
      className="tw-flex tw-flex-row tw-items-center tw-space-x-12px tw-w-full tw-h-36px tw-px-12px tw-py-6px tw-border-0 tw-bg-transparent hover:tw-cursor-pointer hover:tw-bg-tinted-gray-50 active:tw-bg-tinted-gray-100"
      onClick={onDelete}
      data-cy="feedback-menu-dropdown-delete-button"
    >
      <Trash size="m" />
      <span className="tw-text-12d tw-text-neutral-gray-75 tw-font-semibold tw-select-none">Delete</span>
    </button>
  </div>
);
FeedbackMenuDropdown.propTypes = {
  externallyVisible: PropTypes.bool.isRequired,
  isVisibilityToggleEnabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onChangeVisibility: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FeedbackMenuDropdown.defaultProps = {
  className: "",
};

export default FeedbackMenuDropdown;
