/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const InboxRegularV6 = (props) => (
  <Icon {...props} name="Inbox Regular V6">
    <path d="M15.884 9.528 13.97 1.756A.995.995 0 0 0 13 .972H3c-.459 0-.858.313-.97.758L.116 9.528a4.002 4.002 0 0 0-.116.956V13a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2.515c0-.323-.038-.645-.116-.957zM3.394 2.5h9.216l1.6 6.5h-2.709a.749.749 0 0 0-.671.415L10.038 11H5.963l-.791-1.584A.75.75 0 0 0 4.5 9H1.791l1.603-6.5zM14.5 13a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-2.5h2.536l.793 1.585a.746.746 0 0 0 .671.415h5c.284 0 .544-.16.671-.415l.792-1.585h2.538V13z" />
  </Icon>
);

export default InboxRegularV6;
