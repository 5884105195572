/* eslint-disable react/prop-types, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import { LeftCaret, RightCaret } from "./icons";
import { paginationOptionsWithExplicitGaps } from "../utils/helpers";

const ArrowLink = ({ targetPage, onClick, children }) => {
  const classNames =
    "tw-cursor-pointer tw-text-gray-75 tw-flex tw-flex-row tw-bg-gray-10 tw-justify-center tw-items-center tw-rounded-full tw-w-32px tw-h-32px";

  if (targetPage) {
    return (
      <a className={classNames} onClick={() => onClick(targetPage)}>
        {children}
      </a>
    );
  }
  return <div className={classNames}>{children}</div>;
};

const PreviousPageLink = ({ prevPage, onClick }) => (
  <ArrowLink targetPage={prevPage} onClick={onClick}>
    <LeftCaret />
  </ArrowLink>
);

const NextPageLink = ({ nextPage, onClick }) => (
  <ArrowLink targetPage={nextPage} onClick={onClick}>
    <RightCaret />
  </ArrowLink>
);

const PageLinks = ({ totalPages, currentPage, onClick }) => {
  const range = 4;

  const pageGap = (key) => (
    <span key={key} className="tw-cursor-default">
      ...
    </span>
  );

  const pageLink = (pageNumber) => {
    const key = `pagination-${pageNumber}`;
    const classNames =
      "tw-text-gray-99 tw-text-14px tw-flex tw-justify-center tw-items-center tw-w-24px tw-h-24px tw-rounded-full";

    if (pageNumber === currentPage) {
      return (
        <span key={key} className={`${classNames} tw-cursor-default tw-bg-gray-10`}>
          {pageNumber}{" "}
        </span>
      );
    }

    return (
      <a
        className={`${classNames} tw-cursor-pointer hover:tw-text-teal`}
        key={key}
        onClick={() => onClick(pageNumber)}
      >
        {pageNumber}{" "}
      </a>
    );
  };

  return paginationOptionsWithExplicitGaps({
    totalPages,
    currentPage,
    maxDistance: range,
    maxEdgeSize: 2,
  }).map((pageNumber, index) => (pageNumber ? pageLink(pageNumber) : pageGap(totalPages + index)));
};

const Pagination = ({ currentPage, nextPage, prevPage, totalPages, onGoToPage }) => {
  const handleGoToPage = (pageNum) => pageNum && onGoToPage && onGoToPage(pageNum);

  return (
    <div className="tw-flex tw-justify-items-center tw-items-center tw-space-x-1">
      <PreviousPageLink prevPage={prevPage} onClick={handleGoToPage} />
      <PageLinks totalPages={totalPages} currentPage={currentPage} onClick={handleGoToPage} />
      <NextPageLink nextPage={nextPage} onClick={handleGoToPage} />
    </div>
  );
};

export default Pagination;
