import { useEffect, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useOpenHouseDetails = ({ openHouse }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!openHouse?.uuid) return;
    const abortController = new AbortController();
    setData({});
    setLoading(true);
    caseTransformingAxios
      .get("/api/v4/reporting/open_house_dashboard/details", {
        params: { openHouseUuid: openHouse.uuid },
        signal: abortController.signal,
      })
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setLoading(false);
        setError(true);
      });
  }, [openHouse]);

  return { data, error, loading };
};

export default useOpenHouseDetails;
