import React from "react";
import { sortableHandle } from "react-sortable-hoc";
import { Col } from "../../../shared/v1";

const DragHandle = sortableHandle(() => (
  <Col size={1} className="drag-handler">
    <span className="handle ui-sortable-handle" data-cy="financial-draghandle">
      <img src="/assets/drag-icon.svg" alt="Drag icon" />
    </span>
  </Col>
));

export default DragHandle;
