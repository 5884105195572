import React, { useMemo } from "react";
import { Provider } from "react-redux";
import configureStore from "../store/configureStore";
import LeadPonds from "./pages/LeadPonds";

export default (props) => {
  const store = useMemo(() => configureStore(props), []);
  return (
    <Provider store={store}>
      <LeadPonds />
    </Provider>
  );
};
