import axios from "axios";
import { tryCatchHandlr } from "../../../../shared/Utilities";
import { toRailsFromReact } from "./normalizer";

const saveBrandingFormApi = async ({ id, formData, successCb, errorCb }) => {
  const url = `/support/accounts/${id}/brand`;

  const [, err] = await tryCatchHandlr(
    axios.patch(
      url,
      { account: toRailsFromReact({ formData }) },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb();
};

export default saveBrandingFormApi;
