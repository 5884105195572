import { Listing, Agent } from "./models";
import { camelizeKeys } from "../shared/Utilities";

const currencyToNumber = (value) =>
  value ? value?.toString()?.replace(/,/g, "")?.replace(/\$/g, "") : value;

export const listingFromAPI = (listingData) => ({
  id: listingData.id,
  uuid: listingData.uuid,
  title: listingData.title,
  address: camelizeKeys(listingData.address),
  agent: listingData.agent,
  closeDate: listingData.close_date,
  closePrice: currencyToNumber(listingData.close_price),
  canceledDate: listingData.canceled_date,
  dateListed: listingData.date_listed,
  dateExpired: listingData.expiration_date,
  mutualAcceptanceDate: listingData.mutual_acceptance_date,

  grossCommission: currencyToNumber(listingData.gross_commission),
  dom: listingData.dom === "n/a" ? null : listingData.dom,
  lastUpdated: listingData.last_update,
  listingPrice: currencyToNumber(listingData.price),
  mlsNumber: listingData.mls_number,
  photoURL: listingData.photo_without_placeholder,

  sourceId: listingData.source_id,
  status: listingData.status?.toLowerCase()?.replace("coming soon", "comingsoon"),
  statusChangedAt: listingData.status_changed_at,
  type: listingData.type,
  useCustomTitle: listingData.use_custom_title,

  showUrl: listingData.show_url,
  editUrl: listingData.edit_url,

  newMilestones: listingData.new_milestones,
});

export const listingToAPI = (listing) => ({
  // Only a small subset is directly updated
  close_date: listing.closeDate,
  canceled_date: listing.canceledDate,
  date_listed: listing.dateListed,
  expiration_date: listing.dateExpired,

  price: listing.listingPrice,
  mls_number: listing.mlsNumber,

  source_id: listing.sourceId,
  status: listing.status?.replace("comingsoon", "coming soon"),
});

const financialAdditionalToAPI = (additional) => {
  const base = {
    name: additional.name,
    percentage: additional.byPercentage,
    value: currencyToNumber(additional.value),
    notes: additional.notes,
    position: Number(additional.order) + 1,
    line_total: currencyToNumber(additional.previousTotal),
    // eslint-disable-next-line no-underscore-dangle
    _destroy: additional._destroy,
  };

  if (additional.id) base.id = additional.id;
  if (additional.isRevenue) {
    base.income_amount = additional.subtotal;
  } else {
    base.expense_amount = additional.subtotal;
    base.transaction_expense_type_id = additional.transaction_expense_type_id;
  }

  return base;
};

const financialAdditionalFromAPI = (additional) => {
  const maybeValue = currencyToNumber(additional.value);
  const value = maybeValue ? Math.abs(Number(maybeValue)) : maybeValue;
  const type = Object.keys(additional).indexOf("expense_amount") === -1 ? "revenue" : "expense";
  const base = {
    name: additional.name,
    amountValue: value,
    amountType: additional.percentage ? "percent" : "flat",
    type,
    notes: additional.notes,
    order: Number(additional.position) - 1,
  };

  if (additional.id) base.id = additional.id;
  if (type === "expense") base.transaction_expense_type_id = additional.transaction_expense_type_id;

  return base;
};

export const financialDetailFromAPI = (detail, additionals, template, listing) => ({
  id: detail.id,
  closePrice: detail.closed_volume,
  commissionValue: detail.commission,
  commissionType: detail.commission_percentage ? "percent" : "flat",
  notes: detail.commission_notes,
  additionals: additionals.map(financialAdditionalFromAPI),
  financialTemplate: camelizeKeys(template),
  listing,
});

export const financialDetailToAPI = (detail) => ({
  closed_volume: currencyToNumber(detail.closePrice),
  commission: currencyToNumber(detail.commissionValue),
  commission_percentage: detail.commissionType === "percent",
  commission_notes: detail.notes,
  gci: currencyToNumber(detail.gci),
  gross_income: currencyToNumber(detail.subtotal),

  custom_transaction_revenues_attributes: detail.additionals
    .filter((a) => a.isRevenue)
    .map(financialAdditionalToAPI),
  custom_transaction_expenses_attributes: detail.additionals
    .filter((a) => !a.isRevenue)
    .map(financialAdditionalToAPI),
});

export const serverPropsAdapter = (props) => {
  const adaptedListingData = props.initialState.listings.data.map(listingFromAPI);
  // eslint-disable-next-line no-underscore-dangle
  const _listings = { ...props.initialState.listings };
  _listings.entries = adaptedListingData.map((l) => new Listing(l));
  _listings.data = adaptedListingData;
  _listings.totals = {
    buyers: _listings.totals.buyers,
    sellers: _listings.totals.sellers,
    landlords: _listings.totals.landlords,
    tenants: _listings.totals.tenants,
    referrals: _listings.totals.referrals,
    count: _listings.totals.buyers + _listings.totals.sellers,
    closedVolume: _listings.totals.closed_volume,
    estimatedClosedVolume: _listings.totals.estimated_closed_volume,
    actualClosedVolume: _listings.totals.actual_closed_volume,
    grossCommission: _listings.totals.gross_commission,
    estimatedGrossCommission: _listings.totals.estimated_gross_commission,
    actualGrossCommission: _listings.totals.actual_gross_commission,
    listingPrice: _listings.totals.price,
  };
  const pagination = props.initialState.pagination ? camelizeKeys(props.initialState.pagination) : null;
  const adaptedStatusFilters = props.initialState.filters.status.map((s) =>
    s.replace(/coming[ _]soon/, "coming-soon").replace("drafts", "draft"),
  );

  return {
    ...props,
    agents: props.agents.map((data) => new Agent(data)),
    initialState: {
      ...props.initialState,
      filters: {
        ...props.initialState.filters,
        status: adaptedStatusFilters,
      },
      listings: _listings,
      pagination,
      updateAddressModal: {
        isUpdateAddressModalOpen: false,
        uuid: "",
        tempStatus: null,
      },
      importModal: {
        isImportModalOpen: false,
        listing: null,
        updatedStatus: "",
      },
    },
    userTeamId: props.userTeamId,
    userFullName: props.userFullName,
  };
};

const numberifyAggregations = (data) => {
  Object.keys(data).forEach((key) => {
    data[key] = Number(data[key]); // eslint-disable-line no-param-reassign
  });
};

export const aggregationsByStatus = (data) => {
  const newData = { ...data };
  if (data.coming_soon) {
    newData.comingsoon = { ...newData.coming_soon };
    delete newData.coming_soon;
  }

  Object.keys(newData).forEach((status) => {
    numberifyAggregations(newData[status]);
  });

  return newData;
};
