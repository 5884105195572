import React from "react";
import moment from "moment";
import Modal from "./v2/Modal";

const MmsMediaModal = ({ mediaIndex, message, onClose }) => {
  const media =
    message?.mmsMedia?.[mediaIndex] || message?.mms_media?.[mediaIndex] || message?.attachments?.[mediaIndex];

  const renderMedia = () => {
    // TODO: bring this back if/when we have the content type for old messages in the new api
    // if (/(?:jpeg|jpg|gif|png)$/.test(media?.contentType || media?.fileName)) {
    return <img src={media?.url || media?.fileUrl || media?.fileAccessKey} className="tw-w-full tw-h-auto" />;
    // } else {
    //   return <div>Unsupported media type</div>;
    // }
  };

  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col"
      show={Boolean(media)}
      onHide={onClose}
    >
      <Modal.Header
        title="MMS Message"
        description={`${message?.createdByAlias || "No Name"} - ${moment(message?.dateCreated).format(
          "MMMM Do YYYY, h:mm a",
        )}`}
        closeButton
      />
      <Modal.Body className="tw-overflow-auto">{renderMedia()}</Modal.Body>
    </Modal>
  );
};

export default MmsMediaModal;
