import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Pagination from "../../../../shared/v2/Pagination";
import { getDocumentsAsThunk } from "../actions/thunks";
import { MetaPropType } from "../propTypes";

const PaginationSection = ({ isFetchingDocuments, meta, numDocuments, numErrors, dispatch }) => {
  if (isFetchingDocuments || numDocuments === 0 || numErrors > 0) {
    // Only show pagination if there are documents being displayed
    return null;
  }

  const handleGoToPage = (page) => {
    dispatch(getDocumentsAsThunk(page));
  };

  return (
    <div className="tw-relative tw-flex tw-justify-center">
      <Pagination
        currentPage={meta.pagination.current}
        totalPages={meta.pagination.totalPages}
        maxCentralNeighbors={2}
        maxEdgeSize={1}
        onGoToPage={handleGoToPage}
        data-cy="documents-pagination-holder"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetchingDocuments: state.tdpDocumentsReducer.isFetchingDocuments,
  meta: state.tdpDocumentsReducer.meta,
  numDocuments: state.tdpDocumentsReducer.documents.length,
  numErrors: state.tdpDocumentsReducer.errors.length,
});

PaginationSection.propTypes = {
  isFetchingDocuments: PropTypes.bool,
  meta: MetaPropType,
  numDocuments: PropTypes.number,
  numErrors: PropTypes.number,
  dispatch: PropTypes.func,
};

PaginationSection.defaultProps = {
  isFetchingDocuments: false,
  meta: [],
  numDocuments: 0,
  numErrors: 0,
  dispatch: () => {},
};

export default connect(mapStateToProps)(PaginationSection);
