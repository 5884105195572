import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Prompt from "../../shared/v2/Modal/Prompt";
import { caseTransformingAxios } from "../../shared/v2/caseTransformingAxios";
import { removeSmartFilterOption } from "../../reducers/layoutReducer";

const SmartFilterModal = ({ option, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Prompt
      show={Boolean(option)}
      title="Delete Filter?"
      description={`Are you sure you want to delete the filter "${option?.name}", this cannot be undone.`}
      onPrimary={() => {
        setLoading(true);
        caseTransformingAxios
          .delete(`${option?.deletePath}.json`)
          .then(() => {
            dispatch(removeSmartFilterOption({ option }));
            setLoading(false);
            onClose();
          })
          .catch(() => {
            // TODO: make this better, but unlikely to happen
            setLoading(false);
            onClose();
          });
      }}
      primaryOption="Delete"
      primaryProps={{ schema: "warning" }}
      isPrimaryLoading={loading}
      cancelProps={{ disabled: loading }}
      onCancel={() => onClose()}
    />
  );
};

SmartFilterModal.propTypes = {
  name: PropTypes.string,
  option: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};

SmartFilterModal.defaultProps = {
  name: "",
  option: null,
};

export default SmartFilterModal;
