import axios from "axios";
import { useEffect, useState } from "react";

const getLeadStats = ({ dateRange, userUuids }, signal) =>
  axios.get("/dashboard/lead_quick_stats", {
    headers: {
      "content-type": "application/json",
    },
    params: {
      dashboard_opportunities_date_range: dateRange,
      selected_users: userUuids,
    },
    signal,
  });

const useQuickStats = ({ dateRange, userUuids }) => {
  const [error, setError] = useState(false);
  const [leadStats, setLeadStats] = useState([]);
  const [loading, setLoading] = useState(false);

  const reload = (signal) => {
    setLoading(true);
    getLeadStats({ dateRange, userUuids }, signal)
      .then(({ data }) => {
        setLeadStats(data);
        setError(false);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    reload(abortController.signal);
    return () => abortController.abort();
  }, [dateRange, userUuids]);

  return { error, leadStats, loading, actions: { reload, setError } };
};

export default useQuickStats;
