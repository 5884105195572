import formatter from "../../../../shared/formatters";

const TOOLTIP_ID = "dashboard-tooltip";

const renderCell = (label, value) =>
  value !== "$0"
    ? `<div class="tw-flex tw-gap-[12px] tw-justify-between">
      <span>${label}:</span>
      <span>${value}</span>
    </div>`
    : "";

const getLabelKey = (label) => {
  const labels = {
    "MTD Closed": "closed",
    "To Be Closed": "toBeClosed",
    "Closed Last Year": "closedPrevYear",
    "MTD Pended": "pendedUnits",
    "Pended Last Year": "pendedPrevYear",
    "MTD Listed": "listingsTaken",
    "Listed Last Year": "listingsTakenPrevYear",
    "Coming Soon": "comingSoon"
  };
  return labels[label];
};

function renderTooltip({ context, content }) {
  const { caretX, caretY, xAlign, opacity, yAlign } = context.tooltip;
  const tooltipEl = document.getElementById(TOOLTIP_ID) || document.createElement("div");
  if (!tooltipEl.id) {
    tooltipEl.id = TOOLTIP_ID;
    tooltipEl.className = "tw-transition-all";
    document.body.appendChild(tooltipEl);
  }

  tooltipEl.classList.remove("above", "below", "no-transform");
  if (yAlign) {
    tooltipEl.classList.add(yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  const halfHeight = tooltipEl.clientHeight / 2;
  const rightPx = xAlign === "left" ? 0 : tooltipEl.clientWidth;

  if (opacity === 0) {
    tooltipEl.style.opacity = 0;
    return undefined;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  tooltipEl.innerHTML = `
        <div class="
          tw-relative
          tw-min-w-[168px]
          tw-rounded-[4px]
          tw-bg-[#121212]
          tw-text-white
          tw-px-[8px]
          tw-py-[6px]
          tw-divide-y
          tw-text-14d
          before:tw-absolute
          before:tw-border-[5px]
          before:tw-border-[#121212]
          before:tw-border-solid
          before:origin-center
          before:tw-rotate-45
          before:tw-content-['']
          before:tw-top-[calc(50%-5px)]
          ${rightPx ? "before:tw-right-[-5px]" : "before:tw-left-[-5px]"}
        ">
          ${content}
        </div>`;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left = `${position.left + window.pageXOffset + caretX - rightPx}px`;
  tooltipEl.style.top = `${position.top + window.pageYOffset + caretY - halfHeight}px`;
  tooltipEl.style.pointerEvents = "none";
  return tooltipEl;
}

export function renderOverviewTooltip({ datasets, rawData, year, tab, agentUuid }, context) {
  const { dataPoints, title } = context.tooltip;
  const { datasetIndex, dataIndex } = dataPoints?.[0] || {};
  const { dotColor, label } = datasets[datasetIndex] || {};
  const labelKey = getLabelKey(label);
  const textYear = /last year/i.test(label) ? year - 1 : year;
  const estText = tab === "Closings" ? "" : "Est. ";
  const gciText = agentUuid ? "Net Income" : "GCI";
  const units = rawData?.chartData?.units?.[labelKey]?.[dataIndex] || 0;
  const gci = formatter.currency(rawData?.chartData?.gci?.[labelKey]?.[dataIndex] || 0, 0);
  const companyIncome = formatter.currency(rawData?.chartData?.companyIncome?.[labelKey]?.[dataIndex] || 0, 0);
  const volume = formatter.currency(rawData?.chartData?.volume?.[labelKey]?.[dataIndex] || 0, 0);
  const avgSalePrice = formatter.currency(rawData?.chartData?.avgSalePrice?.[labelKey]?.[dataIndex] || 0, 0);
  const content = `
    <h4 class="tw-text-14d tw-font-bold tw-mt-0">
      ${title?.[0]} ${textYear}
    </h4>
    <div class="tw-flex tw-items-center tw-gap-[5px]">
      <span class="tw-inline-block tw-rounded-full tw-w-[8px] tw-h-[8px]" style="background-color: ${dotColor};"></span>
      ${label}
    </div>
    <hr class="tw-my-[6px]" />
    <div class="tw-flex tw-flex-col tw-gap-[6px]">
      ${renderCell("Units", units)}
      ${renderCell(`${estText}Volume`, volume)}
      ${renderCell(`${estText}${gciText}`, gci)}
      ${renderCell(`${estText}Company Dollar`, companyIncome)}
      ${renderCell(`${estText}Avg. Sale Price`, avgSalePrice)}
    </div>
  `;

  return renderTooltip({ context, content });
}

export function renderPapTooltip(context) {
  const { dataPoints } = context.tooltip;

  const { dataIndex, dataset: { data } = {} } = dataPoints?.[0] || {};

  if (!data) return "";

  const content = `
    <div class="tw-flex tw-flex-col tw-gap-[6px]">
      ${renderCell("Rookie Agents", data[dataIndex].rookieAgents)}
      ${renderCell("Experienced Agents", data[dataIndex].experiencedAgents)}
    </div>
  `;
  return renderTooltip({ context, content });
}
