/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import { saveTimeline, setEventsLoadingOff } from "./creators";
import getTimelineFromApi from "../api/apiWrappers";
import { tryCatchHandlr } from "../../../../shared/Utilities";
import { adaptGetTimeline, adaptGetTags } from "../api/apiAdapters";

const getTimelineAsThunk = (uuid, signal) => async (dispatch) => {
  const req = getTimelineFromApi(uuid, signal);

  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    //TODO ask product/design for actions around error
    dispatch(setEventsLoadingOff());
    return;
  }

  // sort by date most recent at top
  res.data.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));

  const timeline = adaptGetTimeline(res.data);
  const tags = adaptGetTags(timeline);

  dispatch(saveTimeline(timeline, tags));
};

export default getTimelineAsThunk;
