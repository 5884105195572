import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Avatar from "@shared/v2/Avatar";
import CmaReport from ".";

function CmaReportPreview({
  deleteReportFn,
  modalIsOpen,
  person_status: personStatus,
  person_lead_type: personLeadType,
  person_address: personAddress,
  person_avatar: personAvatar,
  cmaUrl,
  person,
  onClose,
}) {
  const [cmaReports, setCmaReports] = useState([]);

  const fetchCmaReports = () => {
    const { uuid } = person;
    axios.get(`/cma_reports?uuid=${uuid}`).then((res) => setCmaReports(res.data));
  };

  useEffect(() => {
    if (modalIsOpen) fetchCmaReports();
    else setCmaReports([]);
  }, [modalIsOpen]);

  const deleteReportFunc = (id) => {
    if (deleteReportFn(id)) {
      setCmaReports((prevState) => prevState.filter((r) => r.id !== id));
    } else {
      alert("Something went wrong...");
    }
  };

  return (
    <Modal
      show={modalIsOpen}
      onHide={onClose}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[720px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="CMA Reports" closeButton />
      <Modal.Body id="idx-modal-body" className="tw-p-[12px] tw-flex-1 tw-overflow-auto">
        <div className="tw-flex tw-gap-[12px] tw-items-center">
          <Avatar src={personAvatar} size="medium" />
          <div className="tw-flex-1">
            <div className="tw-flex tw-items-center tw-space-x-[12px]">
              <span className="tw-text-[14px] tw-text-neutral-gray-75 tw-font-semibold">
                {!person?.first_name && !person?.last_name
                  ? "No Name"
                  : `${person?.first_name} ${person?.last_name}`}
              </span>
              {personLeadType &&
                personLeadType.split("/").map((t) => (
                  <div key={t} className={`intent-box ${t.toLowerCase()}`}>
                    {t}
                  </div>
                ))}
              <button
                type="button"
                className={`status btn btn-sm ${personStatus?.toLowerCase()} tw-text-white tw-px-[23px] tw-py-[4px] tw-leading-4 tw-rounded-[12px] tw-text-[11px]`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {personStatus}
              </button>
            </div>
            {personAddress && <div className="tw-text-[14px] tw-text-neutral-gray-75">{personAddress}</div>}
          </div>
        </div>
        <div className="tw-h-[1px] tw-w-full tw-my-[16px] tw-bg-neutral-gray-10" />
        <div className="tw-flex tw-flex-wrap tw-gap-[24px]">
          {cmaReports?.map((report) => (
            <div className="tw-flex-1 tw-min-w-[300px]">
              <CmaReport
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...report}
                deleteReportFn={(id) => deleteReportFunc(id)}
                cmaUrl={cmaUrl}
              />
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

CmaReportPreview.propTypes = {
  deleteReportFn: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  person_status: PropTypes.string,
  person_lead_type: PropTypes.string,
  person_address: PropTypes.string,
  person_avatar: PropTypes.string,
  cmaUrl: PropTypes.string,
  person: PropTypes.shape({
    uuid: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  openCmaReportModal: PropTypes.func,
  onClose: PropTypes.func,
};

CmaReportPreview.defaultProps = {
  deleteReportFn: null,
  modalIsOpen: false,
  person: {},
  openCmaReportModal: null,
  onClose: null,
  person_status: "",
  person_lead_type: "",
  person_address: "",
  person_avatar: "",
  cmaUrl: "",
};

export default CmaReportPreview;
