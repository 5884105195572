import axios from "axios";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";

const uuidDresser = (uuid) => (uuid ? `/${uuid}` : "");
const basePath = (uuid) => `/api/v4/transactions${uuidDresser(uuid)}`;

export const searchListingFromApi = async (search) => {
  const params = {
    term: search,
  };
  const queryParams = new URLSearchParams(params);
  return axios.get(`${basePath()}/autocomplete?${queryParams.toString()}`);
};

export const getImportedListingFromApi = async (autocompleteId) =>
  axios.get(`${basePath()}/autocomplete/${autocompleteId.toString()}`);

export const editListingFromApi = (uuid, data) =>
  axios.patch(`${basePath()}/header/${uuid}`, {
    listing: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const getMlsIdFromApi = async (uuid) =>
  caseTransformingAxios.get(`${basePath(uuid)}/marketing/mls_id`);

export const getAssetsCardFromApi = async (uuid) => axios.get(`${basePath(uuid)}/marketing/assets`);

export const getMetricsFromApi = async (uuid) => axios.get(`${basePath(uuid)}/marketing/page_views`);

export const getAdvertisingPlanFromApi = async (uuid) => axios.get(`${basePath(uuid)}/advertising_plans`);

export const getManagePlanFromApi = async (uuid, planId) =>
  axios.get(`${basePath(uuid)}/advertising_plans/${planId}`);

export const deleteAdPlanFromApi = (uuid, planId) =>
  axios.delete(`${basePath(uuid)}/advertising_plans/${planId}/remove`, {
    data: {
      authenticity_token: ReactOnRails.authenticityToken(),
    },
  });

export const getAvailablePlanFromApi = async (uuid) =>
  axios.get(`${basePath(uuid)}/advertising_plans/available`);

export const submitAvailablePlanFromApi = async (uuid, planId) =>
  axios.post(`${basePath(uuid)}/advertising_plans/${planId}/apply`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const submitAdSiteFromApi = async (uuid, planId, data) =>
  axios.post(`${basePath(uuid)}/advertising_plans/${planId}/sites`, {
    listing_advertiser: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const updateAdSiteFromApi = async (uuid, planId, siteId, data) =>
  axios.patch(`${basePath(uuid)}/advertising_plans/${planId}/sites/${siteId}`, {
    listing_advertiser: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const shareAdSiteFromApi = async (uuid, planId, siteId) =>
  axios.patch(`${basePath(uuid)}/advertising_plans/${planId}/sites/${siteId}/complete`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const removeAdSiteFromApi = async (uuid, planId, siteId) =>
  axios.delete(`${basePath(uuid)}/advertising_plans/${planId}/sites/${siteId}`, {
    data: {
      authenticity_token: ReactOnRails.authenticityToken(),
    },
  });

export const submitEditListingURLFromApi = async (uuid, data) =>
  axios.post(`${basePath(uuid)}/marketing/interactions`, {
    listing: data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });
