import React from "react";
import Proptypes from "prop-types";
import { Col, Row } from "../../../../shared/v1";
import TextButton from "../../../../shared/v2/TextButton";
import Button from "../../../../shared/v2/Button";

const Footer = ({ onClose }) => (
  <Row className="tw-pt-[32px] tw-m-0 tw-mx-[0px] tw-p-0 ">
    <Col size={7} className="tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px] tw-p-0">
      <Button
        onClick={onClose}
        className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
        schema="tertiary"
        size="medium"
        data-cy="marketing-advertising-plan-modal-cancel-button"
      >
        CANCEL
      </Button>
    </Col>
    <Col size={5} className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-flex tw-justify-end">
      <TextButton
        className="tw-float-left tw-mr-16px"
        size="medium"
        data-cy="marketing-advertising-plan-modal-save-button"
      >
        SAVE
      </TextButton>
      <Button
        onClick={onClose}
        className="tw-flex tw-items-center"
        schema="primary"
        size="medium"
        data-cy="marketing-advertising-plan-modal-save-close-button"
      >
        SAVE & CLOSE
      </Button>
    </Col>
  </Row>
);

Footer.propTypes = {
  onClose: Proptypes.func,
};
Footer.defaultProps = {
  onClose: null,
};

export default Footer;
