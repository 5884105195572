import React from "react";
import PropTypes from "prop-types";

const CloseButton = ({ children, className, onClick, ...props }) => (
  <button
    type="button"
    className={`
      tw-float-right tw-p-0 tw-uppercase tw-text-15px tw-font-bold tw-leading-none tw-text-black tw-text-shadow-bootstrap-1 tw-opacity-20 tw-bg-transparent tw-border-0 hover:tw-text-black hover:tw-no-underline hover:tw-cursor-pointer hover:tw-opacity-50 focus:tw-text-black focus:tw-no-underline focus:tw-cursor-pointer focus:tw-opacity-50
      tw-inline-block tw-mb-0 tw-text-center tw-whitespace-nowrap tw-align-middle tw-touch-action-manipulation tw-cursor-pointer tw-bg-none tw-select-none focus:tw-outline-bootstrap active:tw-outline-0 active:tw-shadow-bootstrap-15
      ${className}
    `}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);
CloseButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
CloseButton.defaultProps = {
  children: null,
  className: "",
};

export default CloseButton;
