import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Transition } from "react-transition-group";

import Portal from "../../v1/Portal";
import Button from "../Button";
import { SpinnerSolidV6, AcornSolidV6, CheckSolidV6 } from "../Icomoon";

import {
  SNACKBAR_BASE_CLASSES,
  SNACKBAR_CLASSES,
  availableSchemas,
  availablePositions,
  snackbarAnimationStyles,
  snackbarTransitionStyles,
} from "./Snackbar.styles";

const SNACKBAR_ANIMATION_DURATION_MS = 150;

const buttonSchemaMap = {
  default: "transparent-secondary",
  error: "warning",
  executing: "transparent-secondary",
  executed: "transparent-secondary",
};

const defaultIcons = {
  default: AcornSolidV6,
  error: AcornSolidV6,
  executing: SpinnerSolidV6,
  executed: CheckSolidV6,
};

const getDefaultIconFor = (schema) => {
  const Icon = defaultIcons[schema] ?? defaultIcons.default;

  return <Icon size="xl" className={schema === "executing" ? "tw-animate-spin" : ""} />;
};

const Snackbar = ({
  schema,
  className,
  actionLabel,
  onActionButtonClick,
  secondaryActionLabel,
  onSecondaryActionButtonClick,
  show,
  position,
  icon,
  timeout,
  closeOnTimeout,
  text,
}) => {
  const timeoutRef = useRef(null);
  const timeoutHandler = () => {
    closeOnTimeout();
  };

  useEffect(() => {
    if (show && timeout !== 0) {
      timeoutRef.current = window.setTimeout(timeoutHandler, timeout);
      return;
    }

    if (!show && timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [show, timeout]);

  return (
    <Portal>
      <div
        className={`
          tw-fixed tw-left-0 tw-right-0 tw-mx-auto tw-flex
          tw-justify-center tw-w-[90vw] tw-z-[4000]
          ${position === "bottom" ? "tw-bottom-0" : "tw-top-0"}
        `}
      >
        <Transition in={show} appear timeout={SNACKBAR_ANIMATION_DURATION_MS} unmountOnExit mountOnEnter>
          {(modalState) => (
            <div
              style={{
                ...snackbarAnimationStyles(SNACKBAR_ANIMATION_DURATION_MS),
                ...snackbarTransitionStyles[position][modalState],
              }}
              className={`${SNACKBAR_BASE_CLASSES} ${SNACKBAR_CLASSES[schema]} ${className}`}
            >
              {icon || getDefaultIconFor(schema)}
              <span className="tw-truncate tw-max-w-[120ch]">{text}</span>
              {schema !== "executed" && (
                <>
                  {actionLabel && onActionButtonClick && (
                    <Button
                      size="small"
                      schema={buttonSchemaMap[schema]}
                      onClick={onActionButtonClick}
                      className="tw-whitespace-nowrap"
                    >
                      {actionLabel}
                    </Button>
                  )}
                  {secondaryActionLabel && onSecondaryActionButtonClick && (
                    <Button
                      size="small"
                      schema={buttonSchemaMap[schema]}
                      onClick={onSecondaryActionButtonClick}
                      className="tw-whitespace-nowrap"
                    >
                      {secondaryActionLabel}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </Transition>
      </div>
    </Portal>
  );
};

Snackbar.propTypes = {
  schema: PropTypes.oneOf(availableSchemas),
  className: PropTypes.string,
  actionLabel: PropTypes.string,
  onActionButtonClick: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  onSecondaryActionButtonClick: PropTypes.func,
  show: PropTypes.bool,
  position: PropTypes.oneOf(availablePositions),
  icon: PropTypes.node,
  timeout: PropTypes.number,
  closeOnTimeout: PropTypes.func,
  text: PropTypes.string.isRequired,
};

Snackbar.defaultProps = {
  schema: "default",
  className: "",
  actionLabel: "",
  onActionButtonClick: null,
  secondaryActionLabel: "",
  onSecondaryActionButtonClick: null,
  show: false,
  position: "bottom",
  icon: null,
  timeout: 0,
  closeOnTimeout: () => {},
};

export default Snackbar;
