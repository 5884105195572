import { useEffect, useState } from "react";

import { caseTransformingAxios as axios } from "../../shared/v2/caseTransformingAxios";
import { tryCatchHandlr } from "../../shared/Utilities";

const useFetch = (url, startState = null, onSuccess = () => {}, onError = () => {}) => {
  const [state, setState] = useState(startState);

  useEffect(() => {
    (async () => {
      const req = axios.get(url);
      const [res, err] = await tryCatchHandlr(req);
      if (res) {
        onSuccess(res.data, setState);
      } else {
        onError(err);
      }
    })();
  }, []);

  useEffect(() => () => setState(startState), []);

  return state;
};

export default useFetch;
