import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class TimelineSubmittedFormEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.property = this.property.bind(this)
  }

  property(propertyObject) {
    const bedString = !propertyObject.bedrooms ? "" : propertyObject.bedrooms + " Bed"
    const bathString = !propertyObject.bathrooms ? "" : propertyObject.bathrooms + " Bath"
    const bedBathString = (bedString + " " + bathString).trim()

    return(
      <div key={propertyObject.id} className="property-wrapper"
        style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' + propertyObject.main_photo + ')'}}>
        <div className="inset-info">
          <span className="price">{'$'+propertyObject.value}</span>
          <span>{propertyObject['street_address']}</span>
          <span>{propertyObject['city']}, {propertyObject['state']} {propertyObject['zip']}</span>
          <span>{bedBathString}</span>
        </div>
      </div>
    )
  }

  fields_for_details = () => {
    const {event} = this.props

    // deconstruct to remove property object from fields
    const { timeline_fields: { property, ...timeline_fields } } = event
    return timeline_fields
  }

  details = () => {
    const {event} = this.props
    const eventId = event.id
    const scheduleTourType = event.schedule_tour_type
    let description = []
    const fields = this.fields_for_details()
    const searchUrl = fields.search_url;
    if (scheduleTourType) {
      description.push(`Type: ${scheduleTourType}`)
    }
    for (var key in fields){
      const titleized_key = key.split("_").map(element =>{return capitalize(element)}).join(" ")
      if(titleized_key == 'Search Url' && searchUrl) {
        description.push(`${titleized_key}: <span style="color: #59C4C4;">View Search</span>`)
      } else {
        description.push(titleized_key + ": " + fields[key])
      }
    }

    return(
      description.length > 0 &&
      <div className="row bold-detail details-row details-header">
        Details:
      </div> &&
      description.map(description =>{
        return(
          <div className="row details-row" key={`${eventId}-${description}`}>
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
        )
      })
    )
  }

  row = () => {
    const {event} = this.props
    const {timeline_fields, event_type} = event
    const { search_url } = timeline_fields
    const isForcedSearch = event_type == 'forced_registration_search_page_combined' && search_url;
    const propertyObject = timeline_fields.property
    return (
      <div
        className="timeline-event timeline-submitted-form"
        onClick={()=>{isForcedSearch ? window.open(search_url) : propertyObject && propertyObject.url && window.open(propertyObject.url);}}
      >
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">Submitted Form:</span>
            {` ${event.titleized_type}`}
            <div className="timeline-description-text">
              {!isForcedSearch && propertyObject && this.property(propertyObject)}
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
