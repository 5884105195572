import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import PolymorphicTask from './PolymorphicTask';
import PolymorphicGrid from './PolymorphicGrid';
import axios from 'axios';
import TaskMultiSelect from './TaskMultiSelect'
import * as TaskHelpers from './TaskIndexModalHelpers';

export default class PolymorphicTasks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      target: null,
      toggleLoader: false
    }

  }

  toggleTaskLoader = (isLoading) => {
    this.setState({ toggleLoader: isLoading});
  }

  render() {
    return(
      <div>
        { this.props.agent &&
          <div>
            <PolymorphicGrid
              duenessTitle = 'Upcoming Task Details'
              agent={this.props.agent}
              pagination={{}}
              agents={this.props.agents}
              account={this.props.account}
              taskable={this.props.taskable}
              accountMilestoneNames={this.props.accountMilestoneNames}
              dueness={'incomplete'}
              scopes={['incomplete']}
              showButtons={this.multiSelect}
              selectAllClass={'none'}
              createButton={true}
              uncomplete={false}
              multiSelectButtons={true}
              polymorphicTasks={true}
              user={this.state.user}
              toggleTaskLoader={this.toggleTaskLoader}
            />
            <PolymorphicGrid
              duenessTitle = 'Contingent Task Details'
              agent={this.props.agent}
              account={this.props.account}
              taskable={this.props.taskable}
              accountMilestoneNames={this.props.accountMilestoneNames}
              agents={this.props.agents}
              scopes={['contingent_tasks']}
              dueness={'contingent'}
              pagination={{}}
              showButtons={this.multiSelect}
              selectAllClass={'little-more-space'}
              createButton={false}
              uncomplete={false}
              multiSelectButtons={true}
              polymorphicTasks={true}
              user={this.state.user}
              toggleTaskLoader={this.toggleTaskLoader}
            />
         </div>
       }
       { this.props.agent && <PolymorphicGrid
          duenessTitle = 'Completed Task Details'
          agent={this.props.agent}
          agents={this.props.agents}
          account={this.props.account}
          pagination={{}}
          scopes={['complete_tasks']}
          dueness={'completed'}
          taskable={this.props.taskable}
          accountMilestoneNames={this.props.accountMilestoneNames}
          showButtons={this.multiSelect}
          selectAllClass={'little-more-space'}
          createButton={false}
          uncomplete={true}
          multiSelectButtons={false}
          polymorphicTasks={true}
          user={this.state.user}
          toggleTaskLoader={this.toggleTaskLoader}
        /> }
       { !this.props.agent && <PolymorphicGrid
          duenessTitle = 'Completed Task Details'
          agent={this.props.agent}
          agents={this.props.agents}
          account={this.props.account}
          pagination={{}}
          scopes={['complete_tasks', 'is_public']}
          dueness={'completed'}
          taskable={this.props.taskable}
          accountMilestoneNames={this.props.accountMilestoneNames}
          showButtons={this.multiSelect}
          selectAllClass={'little-more-space'}
          createButton={false}
          uncomplete={true}
          multiSelectButtons={false}
          polymorphicTasks={true}
          user={this.state.user}
          toggleTaskLoader={this.toggleTaskLoader}
        /> }
      </div>
    )
  }
}

PolymorphicTasks.propTypes = {
  user: PropTypes.object.isRequired
};

PolymorphicTasks.defaultProps = {
  tasks: [],
  user: {name: "No Name"},
  pagination: {}
};
