import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { filter } from "lodash";
import { Modal } from "react-bootstrap";
import {
  useDebounce,
  useToggleUsers,
  useCanSaveModel,
  useSetModel,
  useSetUserOptions,
  useDuplicateCheck,
  useInitUsers,
  useSetValue,
} from "../helpers";
import Input from "../../shared/Input";
import FormLabel from "./FormLabel";
import Button from "./Button";
import RemoveableUser from "./RemoveableUser";
import UserSelect from "./UserSelect";
import DeleteConfirm from "./DeleteConfirm";

import * as actions from "../actions/default";
import * as apiActions from "../actions/apiActions";
import Toggle from "../../shared/v2/Toggle";

const mapStateToProps = (state) => ({
  isOpen: state.leadPonds.isModalOpen,
  isSaving: state.leadPonds.isSaving,
  hasSaveError: state.leadPonds.hasSaveError,
  pond: state.leadPonds.pondData,
  isLoadingUsers: state.leadPonds.isLoadingUsers ? true : false,
  usersCollection: state.leadPonds.usersCollection,
  hasDuplicate: state.leadPonds.hasDuplicate,
});

const PondModal = ({
  isOpen,
  pond,
  usersCollection = [],
  isLoadingUsers,
  closePondModal,
  createPond,
  isSaving,
  hasSaveError,
  updatePond,
  removePond,
  duplicateCheck,
  getAssignableUsers,
  hasDuplicate,
}) => {
  const input = useRef();
  const [model, setModel] = useState({});
  const [showConfirm, setConfirm] = useState(0);
  const [userOptions, setUserOptions] = useState([]);
  const debouncedDuplicateTerm = useDebounce(model.name, 500);
  const [saveClicked, setSaveClicked] = useState(0);
  // reset pond value with new props instance
  useSetModel(pond, setModel);
  // enforce unique names
  useDuplicateCheck(debouncedDuplicateTerm, duplicateCheck);
  // load inital user data
  useInitUsers(getAssignableUsers);
  // set the user options once avail
  useSetUserOptions(usersCollection, setUserOptions);

  useEffect(() => {
    if (saveClicked && !isSaving && !hasSaveError) {
      closePondModal();
    }
  }, [isSaving]);
  // Change the model based on key
  const setValue = useSetValue(setModel);

  const setAgent = (agent) => {
    setValue("display_agent", {
      ...model.display_agent,
      ...agent,
    });
  };

  const toggleUser = useToggleUsers(model.accessible_by_users, (col) => {
    setValue("accessible_by_users", [...col]);
  });

  // Close the confirmation modal
  const closeConfirm = () => {
    setConfirm(0);
  };

  const confirmDelete = () => {
    removePond(model.id);
    closeConfirm();
    closePondModal();
  };

  // Flag if this is a new pond
  const isNew = model.id == null;
  // Flag if the model is saveable
  const canSave = useCanSaveModel(pond, model, hasDuplicate);
  return (
    <>
      <Modal show={isOpen} onHide={closePondModal}>
        <div className="tw-p-[32px]">
          <div className="tw-flex tw-pb-10 tw-mt-5">
            <h2 className="tw-flex-grow m-0 tw-text-xl tw-text-teal">{`${
              isNew ? "New" : "Edit"
            } Lead Pond`}</h2>
            <button
              className="tw-block tw-text-base tw-border-0 tw-h-10 tw-w-10 tw-bg-gray-30 tw-rounded-full tw-ml-8"
              onClick={() => {
                closePondModal();
              }}
            >
              <i className="fa fa-close" />
            </button>
          </div>
          <div className="tw-h-[1px] tw-bg-gray-15" />
          {/*<Modal.Body>*/}
          <FormLabel htmlFor="lp-name" title="Pond Name" required={!model.name || model.name <= ""} />
          <Input
            ref={input}
            data-cy="lp-name-input"
            type="text"
            autoFocus
            id="lp-name"
            className={`lp-input tw-w-full tw-py-2 tw-px-4 tw-bg-gray-5 tw-border tw-outline-none tw-border-solid tw-rounded-sm tw-text-[14px]
            ${hasDuplicate ? "tw-border-red-500" : "tw-border-gray-15"}`}
            onChange={(e) => {
              setValue("name", e.target.value);
            }}
            value={model.name}
            placeholder="Enter the name for the lead pond"
          />
          <small
            data-cy="lp-dup-error"
            className={`tw-block tw-text-red-500 tw--mb-6 ${hasDuplicate ? "" : "tw-invisible"}`}
          >
            A pond with the same name already exists.
          </small>
          <FormLabel
            htmlFor="lp-agent"
            title="Display Agent"
            copy="Choose a user to display their headshot, name, email, and phone in any auto emails sent to the leads in the Lead Pond, including Listing Alerts."
            required={!model.display_agent}
          />
          {!model.display_agent ? (
            <UserSelect
              value={model.agent}
              isLoading={isLoadingUsers}
              options={userOptions}
              onSelect={setAgent}
              data-cy="lp-agent-select"
            />
          ) : (
            <RemoveableUser
              name={model.display_agent.name}
              imageUrl={model.display_agent.image_url}
              image={model.display_agent.image}
              onClick={() => {
                // delete it from the model
                setValue("display_agent", null, true);
              }}
            />
          )}

          <FormLabel
            htmlFor="lp-users"
            title="Pond Users"
            copy="Choose which users will have access to this pond.  Account admins will automatically have access to this Pond."
            required={!model.accessible_by_users || model.accessible_by_users.length == 0}
          />
          <div className="tw-mb-5">
            <Toggle
              id="notify_agents_of_new_leads"
              name="notify_agents_of_new_leads"
              value={model.notify_agents_of_new_leads}
              checked={model.notify_agents_of_new_leads === true}
              disabled={false}
              onChange={() => {
                setValue("notify_agents_of_new_leads", !model.notify_agents_of_new_leads);
              }}
              text="Notify agents of new leads"
            />
          </div>
          <UserSelect
            value={model.accessible_by_users}
            isLoading={isLoadingUsers}
            isMultiSelect
            options={userOptions.filter((u) => u.value.user_type !== "Admin")}
            onSelect={toggleUser}
            data-cy="lp-users-select"
          />
          {model.accessible_by_users
            ? model.accessible_by_users.map((user) => (
                <RemoveableUser
                  block
                  key={user.uuid}
                  name={user.name}
                  imageUrl={user.image_url}
                  image={user.image}
                  onClick={() => {
                    // delete it from the model
                    const cleared = filter([...model.accessible_by_users], (u) => u.uuid != user.uuid);
                    setValue("accessible_by_users", cleared);
                  }}
                />
              ))
            : null}
          <div className="tw-flex tw-mt-20">
            <Button
              type="cancel"
              title="Cancel"
              onClick={() => {
                closePondModal();
              }}
            />
            <div className="tw-grow" />

            {isNew ? (
              <Button
                data-cy="lp-submit"
                title={isSaving ? <i className="fa fa-spinner fa-pulse" /> : "Create Pond"}
                disabled={!canSave}
                onClick={() => {
                  createPond(model);
                  setSaveClicked(true);
                }}
              />
            ) : (
              <>
                <Button
                  data-cy="lp-remove"
                  type="remove"
                  title="Delete Pond"
                  onClick={() => {
                    setConfirm(1);
                  }}
                  className="tw-mr-6"
                />
                <Button
                  data-cy="lp-submit"
                  title={isSaving ? <i className="fa fa-spinner fa-pulse" /> : "Save"}
                  disabled={!canSave}
                  onClick={() => {
                    updatePond(model);
                    setSaveClicked(true);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
      {showConfirm ? <DeleteConfirm close={closeConfirm} confirm={confirmDelete} /> : null}
    </>
  );
};

export default connect(mapStateToProps, { ...actions, ...apiActions })(PondModal);
