import React from "react";
import { Modal, Button, CancelButton } from "../../shared/v1";

const AcceptSharedPlanModal = (props) => {
  return (
    <Modal show={props.isModalOpen} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="p-b-20">
          Adding this Auto Plan to your account will also add any custom roles and dates associated with the
          plan. Are you sure you want to add "{props.acceptedPlanName}"?
        </p>
        <CancelButton size="small" className="modal-cancel" onClick={props.handleCloseModal} />
        <Button
          variant="info"
          className="tw-float-right"
          onClick={() => props.handleAcceptPlan(props.acceptingPlanId)}
        >
          Yes, Add Plan
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptSharedPlanModal;
