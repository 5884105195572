/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HouseChimneySolidV6 = (props) => (
  <Icon {...props} name="House Chimney Solid V6">
    <path d="m14.217 8.878.019 4.447c0 .078-.006.15-.014.225V14a1.11 1.11 0 0 1-1.111 1.111h-.444c-.031 0-.061-.025-.092-.003-.039-.022-.078.003-.117.003h-1.569A1.11 1.11 0 0 1 9.778 14v-2.444a.888.888 0 0 0-.889-.889H7.111a.888.888 0 0 0-.889.889V14a1.11 1.11 0 0 1-1.111 1.111H3.558c-.042 0-.083-.003-.125-.006a1.118 1.118 0 0 1-.1.006h-.444A1.11 1.11 0 0 1 1.778 14v-3.111l.002-.078V8.878H.89A.88.88 0 0 1 0 7.986c0-.25.083-.472.278-.667L7.4 1.111c.194-.195.417-.223.611-.223s.417.056.586.195l2.958 2.602V2.666c0-.491.397-.889.889-.889h.889c.492 0 .889.398.889.889v3.361l1.467 1.292c.222.194.336.417.306.667 0 .5-.417.892-.889.892h-.889z" />
  </Icon>
);

export default HouseChimneySolidV6;
