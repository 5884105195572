import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { widthBar, iff } from "../actions/helpers";

const TrackLabel = ({ listingViews }) => (
  <>
    <div className="tw-h-[115px]">
      {Object.entries(listingViews.views.breakdown).map((item, index) => (
        <div
          className={`${
            index === 2 ? "tw-justify-end" : "tw-justify-start tw-pr-[32px]"
          } tw-float-left tw-text-left tw-flex tw-min-w-max tw-max-w-1/3 tw-pt-[18px] tw-pb-[40px]`}
          style={{ width: `${widthBar(item[1], listingViews?.views?.total || 0)}%` }}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <span className={listingViews.views?.timeframe && "tw-pt-[9px]"}>
            <span
              className={`tw-pr-[8px] tw-text-[17px] ${
                item[0] === "brivity" ? "tw-text-teal" : iff(item[0] === "zillow", "tw-text-color-1277e1")
              }`}
            >
              ●
            </span>
            <span className="tw-leading-[24px] tw-text-[18px] tw-font-bold tw-text-gray-75">{item[1]}</span>
          </span>
          <span className="tw-pl-[8px]">
            <span className="tw-leading-[20px] tw-capitalize tw-text-[14px] tw-font-normal tw-text-gray-75">
              {item[0]}
            </span>
            <br />
            <span className="tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75">
              {listingViews.views?.timeframe && `(${listingViews.views.timeframe[`${item[0]}`]})`}
            </span>
          </span>
        </div>
      ))}
    </div>
  </>
);

TrackLabel.propTypes = {
  listingViews: Proptypes.shape({
    views: Proptypes.shape({
      total: Proptypes.number,
      breakdown: Proptypes.shape({
        brivity: Proptypes.number,
        zillow: Proptypes.number,
      }),
      timeframe: Proptypes.shape({
        brivity: Proptypes.string,
        zillow: Proptypes.string,
      }),
    }),
  }),
};
TrackLabel.defaultProps = {
  listingViews: {
    views: {
      total: 0,
      breakdown: {
        brivity: 0,
        zillow: 0,
      },
      timeframe: {},
    },
  },
};

const mapStateToProps = (state) => ({
  listingViews: state.tdpMarketingReducer.listingViews,
});
export default connect(mapStateToProps)(TrackLabel);
