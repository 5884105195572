/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Pause = (props) => (
  <Icon {...props} name="Pause">
    <path d="M5.802 14.154H3.165a1.32 1.32 0 0 1-1.319-1.319v-9.67a1.32 1.32 0 0 1 1.319-1.319h2.637a1.32 1.32 0 0 1 1.319 1.319v9.67a1.32 1.32 0 0 1-1.319 1.319zm8.352-1.319v-9.67a1.32 1.32 0 0 0-1.319-1.319h-2.637a1.32 1.32 0 0 0-1.319 1.319v9.67a1.32 1.32 0 0 0 1.319 1.319h2.637a1.32 1.32 0 0 0 1.319-1.319z" />
  </Icon>
);

export default Pause;
