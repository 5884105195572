import React, { useMemo } from "react";
import { Provider } from "react-redux";
import ReportsContainer from "../containers/ReportsContainer";

const ReportsApp = ({}) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <ReportsContainer />
    </Provider>
  );
};

export default ReportsApp;
