export const contextContainer = "tw-flex tw-items-center tw-justify-center tw-ml-auto";
export const overlayContainer =
  "tw-absolute tw-bg-white tw-w-[120px] tw-rounded-4px tw-right-full tw-top-10px tw-py-4px tw-shadow-md";
export const actionClassNames =
  "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-text-neutral-gray-75 tw-bg-white hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0";
export const actionIconClassName = "tw-mr-8px tw-text-neutral-gray-50";
export const downloadClassNames =
  "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-text-neutral-gray-75 tw-bg-white hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0 hover:tw-text-neutral-gray-75 visited:tw-text-neutral-gray-75 hover:tw-no-underline";
export const trashClassNames =
  "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-bg-white tw-text-semantic-red-100 hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0 tw-border-0 tw-border-t tw-border-solid tw-border-neutral-gray-10";
export const trashIconClassName = "tw-mr-8px tw-text-semantic-red-100";
