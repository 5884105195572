import React, { Component } from "react";
import PropTypes from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Button, Col, Row, Overlay } from "../../shared/v1";
import capitalize from "capitalize";
import ReactDOM from "react-dom";
import Task from "./Task";
import PolymorphicGrid from "./PolymorphicGrid";
import SelectAll from "../../shared/SelectAll";
import CheckBox from "../../shared/CheckBox";
import ReassignModal from "./ReassignModal";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import { instanceOf } from "prop-types";
import axios from "axios";
import css from "../../Dashboard/components/dashboard-styles.module.css";

class TaskGrid extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedAgents: this.props.selectedAgents,
      user: this.props.user,
      tasks: this.props.tasks,
      tasksCount: this.props.tasksCount,
      header_buttons: false,
      showReassignModal: false,
      showRescheduleModal: false,
      showDeleteModal: false,
      showFilterOverlay: false,
      assigned_to_user_id: "anyone",
      selectAll: false,
      selectNone: false,
      filtered: this.props.filtered,
      reschedule_date: moment(),
      selectAllTarget: null,
      filterTarget: null,
    };

    //Select All popover
    this.showSelectAllPopover = TaskHelpers.showSelectAllPopover.bind(this);
    this.closeSelectAllPopover = TaskHelpers.closeSelectAllPopover.bind(this);

    //updating a task
    this.updateTask = TaskHelpers.updateTask.bind(this);

    this.completeTask = TaskHelpers.completeTask.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);

    //updating tasks
    this.completeTasks = TaskHelpers.completeTasks.bind(this);
    this.uncompleteTasks = TaskHelpers.uncompleteTasks.bind(this);
    this.closeModal = TaskHelpers.closeModal.bind(this);

    //reschedule modal
    this.handleRescheduleDate = TaskHelpers.handleRescheduleDate.bind(this);
    this.renderRescheduleModal = TaskHelpers.renderRescheduleModal.bind(this);
    this.showRescheduleModal = TaskHelpers.showRescheduleModal.bind(this);
    this.closeRescheduleModal = TaskHelpers.closeRescheduleModal.bind(this);
    this.rescheduleTask = TaskHelpers.rescheduleTask.bind(this);
    this.rescheduleTasks = TaskHelpers.rescheduleTasks.bind(this);
    this.handleState = TaskHelpers.handleState.bind(this);

    //delete modal
    this.renderDeleteModal = TaskHelpers.renderDeleteModal.bind(this);
    this.showDeleteModal = TaskHelpers.showDeleteModal.bind(this);
    this.closeDeleteModal = TaskHelpers.closeDeleteModal.bind(this);
    this.deleteTasks = TaskHelpers.deleteTasks.bind(this);
    this.deleteTask = TaskHelpers.deleteTask.bind(this);

    //reassign modal
    this.renderReassignModal = TaskHelpers.renderReassignModal.bind(this);
    this.showReassignModal = TaskHelpers.showReassignModal.bind(this);
    this.closeReassignModal = TaskHelpers.closeReassignModal.bind(this);
    this.reassignTasks = TaskHelpers.reassignTasks.bind(this);
    this.reassignTask = TaskHelpers.reassignTask.bind(this);

    // utility for agents in modals
    this.setAgentsForModal = TaskHelpers.setAgentsForModal.bind(this);

    // utility multi selected tasks
    this.selectedTasks = TaskHelpers.selectedTasks.bind(this);
    this.multiSelectWatcher = TaskHelpers.multiSelectWatcher.bind(this);

    this.selectNone = TaskHelpers.selectNone.bind(this);
    this.selectAll = TaskHelpers.selectAll.bind(this);
    this.taskMultiSelect = TaskHelpers.taskMultiSelect.bind(this);

    //Task filtering
    this.getTasks = this.props.getTasks.bind(this);
    this.showMyTasks = this.props.showMyTasks.bind(this);
    this.showUnassignedTasks = this.props.showUnassignedTasks.bind(this);
    this.showAllTasks = this.props.showAllTasks.bind(this);
    this.showTaskFor = this.props.showTaskFor.bind(this);
    this.removeAgentFromSelectedAgents = this.props.removeAgentFromSelectedAgents.bind(this);
    this.addAgentToSelectedAgents = this.props.addAgentToSelectedAgents.bind(this);

    // ref for filter
    this.filterRef = React.createRef();
  }

  componentWillMount() {
    const { cookies } = this.props;

    let filter = cookies.get("task_filter");
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ ...nextProps });
  };

  showFilter = (e) => {
    this.setState({ filterTarget: e.target, showFilter: !this.state.showFilter });
  };

  closeFilter = (e) => {
    const { cookies } = this.props;
    this.setState({ showFilter: !this.state.showFilter });
    const filter = {
      selected_filter: this.props.selectedFilter,
      selected_agents: this.props.selectedAgents,
    };

    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    cookies.set("task_filter", filter, { path: "/", expires: date });
  };

  hideFilter = (e) => {
    this.closeFilter();
  };

  headerString = (dueness) => {
    return this.headerStringOptions()[dueness];
  };

  headerStringOptions = () => {
    return {
      due_today: "Due Today",
      overdue: "Overdue",
      upcoming: "Upcoming",
      completed: "Completed",
    };
  };

  selectedArrow = (name, direction) => {
    if (name == this.props.order_by && direction == this.props.order_direction) {
      return "selected";
    }
  };

  tasksCountLabel() {
    if (!this.state.tasksCount) return "";

    const count = this.state.tasksCount + " ";
    const description = this.state.filtered ? "filtered tasks" : "tasks";
    return count + description;
  }

  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  render() {
    return (
      <div id="tasks-grid" className="tasks-grid tw-h-full tw-overflow-auto">
        <SelectAll
          closeSelectAllHandler={this.closeSelectAllPopover}
          showSelectAll={this.state.showSelectAll}
          target={this.state.selectAllTarget}
          selectAllHandler={this.selectAll}
          selectAll={this.state.selectAll}
          selectNone={this.state.selectNone}
          selectNoneHandler={this.selectNone}
        />
        <div className="tw-bg-white tw-shadow tw-sticky tw-top-0 tw-z-10 tw-flex tw-justify-between tw-items-center tw-px-[16px]">
          <div className="tw-flex tw-items-baseline tw-gap-[10px]">
            <h2 className="tw-text-28d tw-font-normal">{this.headerString(this.props.dueness)}</h2>
            <h5 className="tw-text-12d tw-text-gray tw-font-semibold">
              <span className={"header-count " + this.props.dueness}>{this.tasksCountLabel()}</span>
            </h5>
          </div>
          <div>
            {this.props.admin && (
              <Button
                onClick={this.showFilter}
                id={"task-filter"}
                data-js-task-filter
                className={(this.state.filtered ? "btnselected " : "btnunselected ") + " m-l-5"}
                ref={this.filterRef}
              >
                {this.state.filtered ? "filtered " : "filter"}
              </Button>
            )}
            <div className="tw-absolute tw-top-auto tw-left-auto tw-right-0">
              <Overlay
                id={"filter-dropdown popover"}
                className={"filter-dropdown"}
                show={this.state.showFilter}
                rootClose
                onHide={this.hideFilter}
                ref={this.filterRef}
              >
                <div id={"filter-content"} className="tw-flex tw-flex-col tw-flex-nowrap">
                  <h3 className="tw-bg-color-f7f7f7 filter-title tw-flex">Task Filter</h3>
                  <CheckBox
                    divClass="m-l-10 m-r-10 tw-flex"
                    id="show-my-tasks"
                    inputName="my_tasks"
                    label={"Show only my tasks"}
                    inputClass={"checkbox-input"}
                    labelClass={this.props.my_tasks ? "checked" : "unchecked"}
                    objectToCheck={this.state}
                    inputChecked={this.state.my_tasks}
                    onUpdate={this.showMyTasks}
                  ></CheckBox>
                  <CheckBox
                    divClass="m-l-10 m-r-10 tw-flex"
                    id="show-unassigned-tasks"
                    objectToCheck={this.state}
                    inputName="unassigned_tasks"
                    inputClass={"checkbox-input"}
                    labelClass={this.props.unassigned_tasks ? "checked" : "unchecked"}
                    inputChecked={this.unassigned_tasks}
                    onUpdate={this.showUnassignedTasks}
                    label={"Show unassigned tasks"}
                  ></CheckBox>
                  <CheckBox
                    divClass="m-l-10 m-r-10 tw-flex tw-mb-2px"
                    inputName="all_tasks"
                    inputClass={"checkbox-input"}
                    labelClass={this.props.all_tasks ? "checked" : "unchecked"}
                    objectToCheck={this.state}
                    inputChecked={this.all_tasks}
                    id="show-all-tasks"
                    onUpdate={this.showAllTasks}
                    label={"Show all tasks"}
                  ></CheckBox>
                  <hr className="tw-w-full" />
                  {this.props.agents &&
                    this.props.agents.map((agent, index) => (
                      <CheckBox
                        key={index}
                        divClass={"m-l-10 m-r-10 tw-flex"}
                        objectToCheck={this.state}
                        labelClass={this.props.selectedAgents.includes(agent.uuid) ? "checked" : "unchecked"}
                        inputClass={"checkbox-input"}
                        inputChecked={this.props.selectedAgents.includes(agent.uuid)}
                        onUpdate={this.showTaskFor}
                        handleChange={this.showTaskFor}
                        inputName={agent.uuid}
                        label={agent.full_name}
                      ></CheckBox>
                    ))}
                </div>
              </Overlay>
            </div>
          </div>
          {!this.props.uncomplete && this.state.header_buttons && (
            <span className={"tw-float-right"}>
              <Button
                variant="success"
                size="small"
                invert
                onClick={this.completeTasks}
                className={"m-l-5 complete"}
              >
                <span>
                  <i className={"fa fa-clock-o tw-pr-[0.1em] tw-align-text-top"} />
                  Complete
                </span>
              </Button>
              <Button
                variant="danger"
                size="small"
                invert
                onClick={this.showDeleteModal}
                className={"m-l-5 delete"}
              >
                <span>
                  <i className={"fa fa-times tw-pr-[0.1em] tw-align-text-top"} />
                  Delete
                </span>
              </Button>
              <Button
                variant="info"
                size="small"
                invert
                onClick={this.showRescheduleModal}
                className={"m-l-5 reschedule"}
              >
                <span>
                  <i className={"fa fa-clock-o tw-pr-[0.1em] tw-align-text-top"} />
                  Reschedule
                </span>
              </Button>
              <Button
                variant="warning"
                size="small"
                invert
                onClick={this.showReassignModal}
                className={"m-l-5 reassign"}
              >
                <span>
                  <i className={"fa fa-users tw-pr-[0.1em] tw-align-text-top"} />
                  Reassign
                </span>
              </Button>
            </span>
          )}
          {this.props.uncomplete && this.state.header_buttons && (
            <span>
              <Button
                variant="success"
                size="small"
                invert
                onClick={this.uncompleteTasks}
                className={"uncomplete"}
              >
                <span>
                  <i className={"fa fa-square-o tw-pr-[0.1em] tw-align-text-top"} />
                  Un-complete
                </span>
              </Button>
            </span>
          )}
        </div>
        <div>
          <Col size={12}>
            <Row className={"th tasks-header overflow-inherit"} data-dueness={this.props.dueness}>
              <Col size={3}>
                <div className={"sort-header"} onClick={() => this.props.orderBy("name")}>
                  <h6 className="tw-mb-0.357rem">
                    Details
                    <span className={"arrows"}>
                      <i className={"fa fa-sort-up " + this.selectedArrow("name", "asc")} />
                      <i className={"fa fa-sort-down " + this.selectedArrow("name", "desc")} />
                    </span>
                  </h6>
                </div>
              </Col>
              <Col size={1}>
                <div className={"tw-text-center sort-header"} onClick={() => this.props.orderBy("type")}>
                  <h6 className="tw-mb-0.357rem">
                    Task Type
                    <span className={"arrows"}>
                      <i className={"fa fa-sort-up " + this.selectedArrow("type", "asc")} />
                      <i className={"fa fa-sort-down " + this.selectedArrow("type", "desc")} />
                    </span>
                  </h6>
                </div>
              </Col>
              <Col size={1}>
                <div
                  className={"tw-text-center sort-header"}
                  onClick={() => this.props.orderBy("priority", "asc")}
                >
                  <h6 className="tw-mb-0.357rem">
                    Priority
                    <span className={"arrows"}>
                      <i className={"fa fa-sort-up " + this.selectedArrow("priority", "asc")} />
                      <i className={"fa fa-sort-down " + this.selectedArrow("priority", "desc")} />
                    </span>
                  </h6>
                </div>
              </Col>
              <Col size={1}>
                <div className={"tw-text-center sort-header"} onClick={() => this.props.orderBy("due_on")}>
                  <h6 className="tw-mb-0.357rem">
                    Date Due
                    <span className={"arrows"}>
                      <i className={"fa fa-sort-up " + this.selectedArrow("due_on", "asc")} />
                      <i className={"fa fa-sort-down " + this.selectedArrow("due_on", "desc")} />
                    </span>
                  </h6>
                </div>
              </Col>
              <Col size={1}>
                <div className={"tw-text-center"}>
                  <h6 className="tw-mb-0.357rem">Who</h6>
                </div>
              </Col>
              <Col size={2}>
                <div className={"sort-header"} onClick={() => this.props.orderBy("taskable_name")}>
                  <h6 className="tw-mb-0.357rem">
                    Task for
                    <span className={"arrows"}>
                      <i className={"fa fa-sort-up " + this.selectedArrow("taskable_name", "asc")} />
                      <i className={"fa fa-sort-down " + this.selectedArrow("taskable_name", "desc")} />
                    </span>
                  </h6>
                </div>
              </Col>
              <Col size={1}>
                <div className={"tw-text-center"}>
                  <h6 className="tw-mb-0.357rem">Settings</h6>
                </div>
              </Col>
              <Col size={1}>
                <div>
                  <h6 className="tw-mb-0.357rem">Edit</h6>
                </div>
              </Col>
              {this.props.agent && (
                <Col
                  size={1}
                  className={"tw-text-center border-extension little-more-space"}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{ position: "relative" }}
                    onClick={(e) => this.state.tasks && this.showSelectAllPopover(e)}
                    id={"all-incomplete-tasks-dropdown-toggle"}
                    className={
                      "tw-inline-block tw-align-middle tw-touch-manipulation tw-cursor-pointer tw-select-none select-task-button"
                    }
                  >
                    <h6 className="tw-mb-0.357rem">
                      Select
                      <i className={"fa fa-sort-down font-size-12 p-l-3 tw-pr-[0.1em] tw-align-text-top"} />
                    </h6>
                  </div>
                </Col>
              )}
            </Row>
            <div className={"tw-clear-both"} />
            <div className={"tasks-body " + this.props.dueness + "-tasks"}>
              {this.props.toggleLoader && (
                <div className={css.loadingState}>
                  <i
                    style={{ fontSize: "50px", marginLeft: "-450px" }}
                    className="fa fa-spinner fa-pulse"
                  ></i>
                </div>
              )}
              {this.state.tasks &&
                this.state.tasks.map((task) => (
                  <Task
                    dueness={this.props.dueness}
                    agents={this.props.agents}
                    agent={this.props.agent}
                    key={task.id}
                    task={task}
                    user={this.props.user}
                    multiSelectWatcher={this.multiSelectWatcher}
                    removeTask={this.props.removeTask}
                    accountMilestoneNames={this.props.accountMilestoneNames}
                    getTasks={this.getTasks}
                  />
                ))}
            </div>
          </Col>
        </div>
        {this.renderReassignModal()}
        {this.renderRescheduleModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}

TaskGrid.propTypes = {
  user: PropTypes.object.isRequired,
};

TaskGrid.defaultProps = {
  tasks: [],
  user: { name: "No Name" },
};

export default withCookies(TaskGrid);
