import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import { duplicatePost } from "../../reducers/websiteReducer/websiteReducer";

const DuplicatePostModal = ({ onClose, post }) => {
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (post) {
      setError("");
      setTitle(`Copy - ${post?.title}`);
    }
  }, [post]);

  return (
    <Modal
      show={Boolean(post)}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[24px]"
    >
      <Modal.Header title="Duplicate Post" closeButton />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
        <TextInput
          label="Post Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={error}
        />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button size="medium" schema="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          onClick={() => {
            setError("");
            dispatch(duplicatePost({ postUuid: post.uuid, title })).then((state) => {
              if (state.error) setError(state.payload);
              if (!state.error) onClose();
            });
          }}
        >
          Duplicate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DuplicatePostModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  post: PropTypes.shape(),
};

DuplicatePostModal.defaultProps = {
  post: null,
};

export default DuplicatePostModal;
