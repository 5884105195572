const actions = {
  updateAddressModal: {
    toggleUpdateAddressModal: (bool, tempStatus, listing) => ({
      name: "TOGGLE_UPDATE_ADDRESS_MODAL",
      bool,
      tempStatus,
      listing,
    }),
  },

  filters: {
    add: ({ type, value }) => ({
      name: "FILTERS_ADD",
      filter: { type, value },
    }),
    remove: ({ type, value }) => ({
      name: "FILTERS_REMOVE",
      filter: { type, value },
    }),
    set: (filters) => ({ name: "FILTERS_SET", filters }),
    clear: () => ({ name: "FILTERS_CLEAR" }),
    openModal: () => ({ name: "FILTERS_OPEN_MODAL" }),
    closeModal: () => ({ name: "FILTERS_CLOSE_MODAL" }),
  },

  order: {
    set: (value) => ({ name: "ORDER_SET", value }),
  },

  pagination: {
    setPage: (pageNumber) => ({ name: "PAGE_NUMBER_SET", value: pageNumber }),
    setPaginationInfo: (info) => ({ name: "PAGINATION_INFORMATION_SET", value: info }),
  },

  listings: {
    fetchStart: () => ({ name: "LISTING_FETCH_START" }),
    fetchSuccess: (listingData) => ({
      name: "LISTING_FETCH_SUCCESS",
      data: listingData,
    }),
    fetchFailure: () => ({ name: "LISTING_FETCH_FAILURE" }),

    cardFetchStart: (status) => ({ name: "LISTING_CARD_FETCH_START", status }),
    cardFetchSuccess: (listingData, status) => ({
      name: "LISTING_CARD_FETCH_SUCCESS",
      data: listingData,
      status,
    }),

    updateStart: (originalListing, changes) => ({ name: "LISTING_UPDATE_START", originalListing, changes }),
    updateSuccess: (listingData) => ({
      name: "LISTING_UPDATE_SUCCESS",
      listingData,
    }),
    updateListing: (listing, status, addressDetails) => ({
      name: "LISTING_UPDATE_STATE",
      listing,
      status,
      addressDetails,
    }),
    updateStatusRequest: (originalListing, stateChanges) => ({
      name: "LISTING_UPDATE_STATUS_REQ",
      originalListing,
      stateChanges,
    }),
    updateFailure: (originalListing, error) => ({
      name: "LISTING_UPDATE_FAILURE",
      originalListing,
      error,
    }),

    deleteStart: (listing) => ({ name: "LISTING_DELETE_START", listing }),
    deleteSuccess: (listingData) => ({
      name: "LISTING_DELETE_SUCCESS",
      listingData,
    }),
    deleteFailure: (listingData) => ({
      name: "LISTING_DELETE_FAILURE",
      listingData,
    }),

    archiveStart: (listing) => ({ name: "LISTING_ARCHIVE_START", listing }),
    archiveSuccess: (listingData) => ({
      name: "LISTING_ARCHIVE_SUCCESS",
      listingData,
    }),
    archiveFailure: (listingData) => ({
      name: "LISTING_ARCHIVE_FAILURE",
      listingData,
    }),
    updateAggregations: (data) => ({ name: "LISTING_UPDATE_AGGREGATIONS", data }),
  },

  importModal: {
    toggleImportModal: (bool, listing, updatedStatus) => ({
      name: "TOGGLE_IMPORT_MODAL",
      bool,
      listing,
      updatedStatus,
    }),
  },

  saveFiltersModal: {
    openModal: () => ({
      name: "LISTING_FILTERS_OPEN_MODAL",
    }),
    closeModal: () => ({
      name: "LISTING_FILTERS_CLOSE_MODAL",
    }),
    saveStart: (data) => ({
      name: "LISTING_FILTERS_SAVE_START",
      data,
    }),
    saveSuccess: () => ({
      name: "LISTING_FILTERS_SAVE_SUCCESS",
    }),
    saveFailure: (errors) => ({
      name: "LISTING_FILTERS_SAVE_FAILURE",
      errors,
    }),
  },

  financials: {
    get: (listing) => ({ name: "FINANCIALS_GET", listing }),
    show: (listing, detail) => ({ name: "FINANCIALS_SHOW", listing, detail }),
    hide: () => ({ name: "FINANCIALS_HIDE" }),
    updateStart: (listing, detail) => ({
      name: "FINANCIALS_UPDATES_START",
      listing,
      detail,
    }),
    updateSuccess: (data) => ({ name: "FINANCIALS_UPDATES_SUCCESS", data }),
    updateFailure: (listing, detail) => ({
      name: "FINANCIALS_UPDATES_FAILURE",
      listing,
      detail,
    }),
    index: () => ({ name: "FINANCIALS_INDEX" }),
  },

  errors: {
    general: () => ({ name: "GENERAL_ERROR" }),
    clear: () => ({ name: "NO_GENERAL_ERROR" }),
  },

  rendering: {
    setColumns: (columns) => ({ name: "RENDERING_SET_COLUMNS", columns }),
    reorderColumn: ({ sourceIndex, destinationIndex }) => ({
      name: "RENDERING_REORDER_COLUMNS",
      sourceIndex,
      destinationIndex,
    }),
    setDisplay: (display) => ({ name: "RENDERING_SET_DISPLAY", display }),
    setAddTransactionModal: (isOpen, status) => ({
      name: "RENDERING_TOGGLE_ADD_TRANSACTION_MODAL",
      isOpen,
      status,
    }),
  },

  preferences: {
    save: (preferences) => ({ name: "PREFERENCESS_SAVE", preferences }),
  },
};

export default actions;
