import { schemaGenerator } from "../utils";

export const calendarContainerCommon =
  "tw-flex tw-flex-col tw-flex-nowrap tw-py-12px tw-px-12px tw-w-[240px] tw-min-h-[240px] tw-select-none tw-bg-white";
const calendarDayCommon = "tw-justify-center tw-cursor-pointer tw-flex tw-items-center tw-rounded-4px";
const monthSelectorCommon =
  "tw-h-32px tw-w-40px tw-text-center tw-border-0 tw-bg-transparent tw-rounded-4px tw-justify-center tw-content-center";

export const dateInputClassNames = (showTimePicker, showEndTimePicker, dateFormatError) => {
  if ((showTimePicker && showEndTimePicker) || (!showTimePicker && !showEndTimePicker)) {
    return dateFormatError ? "tw-w-full tw-mb-12px" : "tw-w-full";
  }

  return dateFormatError ? "tw-w-[104px] tw-mb-12px" : "tw-w-[104px]";
};

const dictionary = {
  dayDisabled: {
    base: [calendarDayCommon],
    default: ["tw-text-semantic-red-10 tw-w-[31px] tw-h-28px", "!tw-cursor-default"],
  },
  daySelected: {
    base: [calendarDayCommon],
    default: ["tw-bg-theme-dateselector-main-color tw-text-white tw-w-[31px] tw-h-28px"],
  },
  dayTempToday: {
    default: ["tw-text-white"],
  },
  dayToday: {
    base: [calendarDayCommon],
    default: [
      "tw-border-theme-dateselector-secondary-color tw-text-theme-dateselector-secondary-color tw-border-1px tw-border-solid tw-m-auto tw-w-24px tw-h-20px tw-justify-center tw-cursor-pointer tw-flex tw-items-center tw-rounded-4px",
    ],
  },
  dayOutside: {
    base: [calendarDayCommon],
    default: ["tw-text-neutral-gray-30 tw-w-[31px] tw-h-28px"],
    hover: ["hover:tw-bg-neutral-gray-10"],
  },
  dayDefault: {
    base: [calendarDayCommon],
    default: ["tw-text-theme-dateselector-text-color tw-w-[31px] tw-h-28px"],
    hover: ["hover:tw-bg-neutral-gray-10"],
  },
  monthSelected: {
    base: [monthSelectorCommon],
    default: ["tw-text-theme-dateselector-secondary-color tw-font-bold"],
    hover: ["hover:tw-bg-neutral-gray-10"],
    active: ["active:tw-bg-neutral-gray-15"],
  },
  monthDefault: {
    base: [monthSelectorCommon],
    default: ["tw-text-neutral-gray-75"],
    hover: ["hover:tw-bg-neutral-gray-10"],
    active: ["active:tw-bg-neutral-gray-15"],
  },
  navigation: {
    base: ["tw-font-bold tw-flex tw-border-0 tw-bg-transparent tw-rounded-4px tw-items-center tw-p-4px"],
    default: ["tw-text-theme-dateselector-text-color"],
    hover: ["hover:tw-bg-neutral-gray-10"],
    active: ["active:tw-bg-gray-15"],
    disabled: ["disabled:tw-text-neutral-gray-30", "hover:tw-bg-transparent", "active:tw-bg-transparent"],
  },
  navigationMode: {
    base: [
      "tw-font-bold tw-flex tw-border-0 tw-bg-transparent tw-rounded-4px tw-items-center tw-py-1px tw-pl-6px tw-pr-4px",
    ],
    default: ["tw-text-theme-dateselector-text-color"],
    hover: ["hover:tw-bg-neutral-gray-10"],
    active: ["active:tw-bg-gray-15"],
  },
  closeTimePicker: {
    default: [
      "tw-text-neutral-gray-50 tw-flex tw-p-6px tw-border-0 tw-bg-transparent tw-rounded-4px tw-items-center tw-ml-auto",
    ],
    hover: ["hover:tw-bg-neutral-gray-10"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
