import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defaultOrImage } from "../../shared/DefaultOrImage";
import Moment from 'react-moment';
import css from "./report-styles.module.css";
import {truncateTime} from "./reportHelpers";

const AgentTextsRow = ({agent}) => {
  return(
  <Col xs={12} className={`${css.noWrap} user td`} key={agent.id}>
    <Col xs={2} className='ellipsify'>
      <div style={{display: 'inline-block',padding: '0 10px'}}>
        <a href={`/people/${agent.user_path}`}>
          {defaultOrImage(agent.avatar, agent.agent)}
        </a>
      </div>
      <div style={{display: 'inline-block'}}>
        {agent.agent}
      </div>
    </Col>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_agent_total_lead_count}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_lead_text_count}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_non_lead_text_count}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_total_texts}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_total_texts_recd}
      </span>
    </div>
    <div className='col-xs-1 text-center' style={{display: 'inline-block'}}>
      <span>
        {agent.text_report_total_text_count}
      </span>
    </div>
  </Col>
  )
}

export default AgentTextsRow;
