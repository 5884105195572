import React from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { IncomeExpenseForm } from '../shared/IncomeExpenseForm';
import { reCalculateAgentExpenses } from '../shared/FinancialViewHelpers';
import Error from '../../../shared/Error';

class ExpenseModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      name: '',
      value: '',
      percentage: true,
      expense_amount: '',
      type: 'expense',
      agent_expense_type_id: '',
      position: '',
      isNotValid: false,
      errMsg: ''
    }
  }
  saveData() {
    const { state } = this;
    const { 
      listingData, 
      modalIndex,
      setFinancial,
      setIsExpenseModal } = this.props;
 
    if (!state.percentage) {
      if (state.value === '') {
        state.value = 0;
      }
    }
    const income = listingData.team_member_incomes.incomes[modalIndex]
    let nonDeletedCustomExpenses = income.custom_team_member_expenses_attributes.filter(expense => expense._destroy !== '1');

    state.position = nonDeletedCustomExpenses.length ? nonDeletedCustomExpenses[nonDeletedCustomExpenses.length - 1].position + 1 : 1;    
    state.value *= 1
    income.custom_team_member_expenses_attributes.push(state);
    reCalculateAgentExpenses(income, listingData, modalIndex, setFinancial);
    setIsExpenseModal(false)
  }

  saveExpenseData = () => {
    if (this.state.name && this.state.value) {
      if (this.state.percentage) {
        if (this.state.value >= 0 && this.state.value <= 100) {
          this.saveData()
        } else {
          this.setState({
            errMsg: 'Value must be between 0 and 100.',
            isNotValid: true
          });
        }
      } else {
        this.saveData()
      }
    } else {
      this.setState({
        errMsg: 'Enter a name and value.',
        isNotValid: true
      });
    }
  }

  render() {
    const {
      isExpenseModal,
      setIsExpenseModal,      
      listingData,
    } = this.props;

    const { agent_expense_types } = listingData;
  
    return (
      <Modal
        show={isExpenseModal}
        onHide={() => setIsExpenseModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            New Expense
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='auto-plans-form transaction-gci-content'>
          <div className='transaction-gci-options transaction-modal'>
            {this.state.isNotValid && <Error errorMessage={this.state.errMsg} />}
            <Row>
              <Col xs={12}>
                <p>This is unique to this transaction and will not be repeated</p>
              </Col>
            </Row>
              <IncomeExpenseForm
                modalThis={this}
                expList={agent_expense_types}
                setIsOpenNewIncomeModal={setIsExpenseModal}/>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExpenseModal;
