/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UserLargeSolidV6 = (props) => (
  <Icon {...props} name="User Large Solid V6">
    <path d="M8 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm2.972 1H5a5 5 0 0 0-5 5c0 .552.448 1 .972 1H15c.552 0 .972-.448.972-1 .028-2.762-2.209-5-5-5z" />
  </Icon>
);

export default UserLargeSolidV6;
