import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import { connect } from "react-redux";
import { produce } from "immer";
import { optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { errorForBanner } from "../../../../../shared/v2/ErrorBanner";
import { financialsUpdateCanceled } from "../../actions/creators";
import { createFinancials, updateFinancials } from "../../actions/thunks";
import FINANCIALS_UPDATE_TYPE from "../../api/actionNames";

import AddEditExpenseIncomeModal from "../AddEditExpenseIncomeModal";
import DeleteExpenseIncomeModal from "../DeleteExpenseIncomeModal";
import EditAmountModal from "../EditAmountModal/EditAmountModal";
import { TransactionGciWithLineItems } from "../TransactionGCI";
import { emptyTransactionData } from "../TransactionGCI/helperData";

const TransactionSection = ({ financials, dispatch }) => {
  const [editingCommission, setEditingCommission] = useState(false);
  const [addingExpensesLineItem, setAddingExpensesLineItem] = useState(false);
  const [addingIncomeLineItem, setAddingIncomeLineItem] = useState(false);
  const [editingExpenseLineItem, setEditingExpenseLineItem] = useState(false);
  const [editingIncomeLineItem, setEditingIncomeLineItem] = useState(false);

  const [editingLineItem, setEditingLineItem] = useState(null);

  const [removingLineItem, setRemovingLineItem] = useState(null);

  const {
    adaptedData,
    lineItemTypeOptions,
    meta: { isUpdating, modalErrors: metaModalErrors },
    isReferral,
  } = financials;
  const transaction = adaptedData.transaction || emptyTransactionData;

  const modalErrors = useMemo(() => metaModalErrors.map((error) => errorForBanner(error)), [metaModalErrors]);

  const lineItemExpensesOptions = lineItemTypeOptions.transactionExpense;
  const lineItemIncomeOptions = lineItemTypeOptions.transactionIncome;

  const handleCommissionLabelClick = () => {
    setEditingCommission(true);
  };

  const handleAddExpensesLineItem = () => {
    setAddingExpensesLineItem(true);
  };

  const handleAddIncomeLineItem = () => {
    setAddingIncomeLineItem(true);
  };

  const handleEditExpenseLineItem = (lineItem) => {
    setEditingExpenseLineItem(true);
    setEditingLineItem(
      produce(lineItem, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.options = draft.options.filter((option) => option.meta.type === "expense");
      }),
    );
  };

  const handleEditIncomeLineItem = (lineItem) => {
    setEditingIncomeLineItem(true);
    setEditingLineItem(
      produce(lineItem, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.options = draft.options.filter((option) => option.meta.type === "income");
      }),
    );
  };

  const handleRemoveLineItem = (lineItem) => {
    setRemovingLineItem(lineItem);
  };

  const handleClosePriceChange = (newValue) => {
    if (!transaction.hasFinancialData) {
      dispatch(createFinancials(newValue));
      return;
    }

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_CLOSE_PRICE, {
        closePrice: newValue,
      }),
    );
  };

  const handleCommissionValueChange = (newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_COMMISSION_VALUE, {
        commission: newValue,
        commissionType: newValueType,
      }),
    );
  };

  const handleLineItemDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_POSITION, {
        from: source.index,
        to: destination.index,
      }),
    );
  };

  const handleLineItemOptionChange = (lineItem, newOption) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_TYPE, {
        type: lineItem.expenseOrIncome,
        id: lineItem.id,
        selectedOption: newOption,
      }),
    );
  };

  const handleLineItemValueChange = (lineItem, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_VALUE, {
        type: lineItem.expenseOrIncome,
        id: lineItem.id,
        value: newValue,
        valueType: newValueType,
      }),
    );
  };

  const handleSaveCommission = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_COMMISSION, {
        commission: data.value,
        commissionType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveNewLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.ADD_TRANSACTION_LINE_ITEM, {
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveEditedLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM, {
        type: editingLineItem.expenseOrIncome,
        id: editingLineItem.id,
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveRemovedLineItem = () => {
    const lineItem = removingLineItem || editingLineItem;
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.REMOVE_TRANSACTION_LINE_ITEM, {
        type: lineItem.expenseOrIncome,
        id: lineItem.id,
        isInModal: true,
      }),
    );
  };

  const handleCancelSaveCommission = () => {
    setEditingCommission(false);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveNewLineItem = () => {
    setAddingExpensesLineItem(false);
    setAddingIncomeLineItem(false);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveEditedLineItem = () => {
    setEditingLineItem(null);
    setEditingExpenseLineItem(false);
    setEditingIncomeLineItem(false);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveRemovedLineItem = () => {
    setRemovingLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  useEffect(() => {
    if (!isUpdating && modalErrors.length === 0) {
      setEditingCommission(false);
      setAddingExpensesLineItem(false);
      setAddingIncomeLineItem(false);
      setEditingLineItem(null);
      setEditingExpenseLineItem(false);
      setEditingIncomeLineItem(false);
      setRemovingLineItem(null);
    }
  }, [isUpdating, modalErrors]);

  return (
    <>
      <TransactionGciWithLineItems
        isReferral={isReferral}
        transaction={transaction}
        onClosedPriceChange={handleClosePriceChange}
        onCommissionLabelClick={handleCommissionLabelClick}
        onCommissionValueChange={handleCommissionValueChange}
        onAddExpenses={handleAddExpensesLineItem}
        onAddIncome={handleAddIncomeLineItem}
        onLineItemDragEnd={handleLineItemDragEnd}
        onLineItemOptionChange={handleLineItemOptionChange}
        onLineItemValueChange={handleLineItemValueChange}
        onEditExpenseLineItem={handleEditExpenseLineItem}
        onEditIncomeLineItem={handleEditIncomeLineItem}
        onRemoveLineItem={handleRemoveLineItem}
      />

      {editingCommission && (
        <EditAmountModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          title="Edit Commission"
          value={
            transaction.isActualGCI
              ? transaction.actual.commission.value
              : transaction.estimated.commission.value
          }
          allowPercentage={transaction.showClosePrice}
          isPercentage={
            transaction.isActualGCI
              ? transaction.actual.commission.type === "percent"
              : transaction.estimated.commission.type === "percent"
          }
          notes={transaction.notes}
          onSave={handleSaveCommission}
          onCancel={handleCancelSaveCommission}
        />
      )}

      {(addingExpensesLineItem || addingIncomeLineItem) && (
        <AddEditExpenseIncomeModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          nameList={addingExpensesLineItem ? lineItemExpensesOptions : lineItemIncomeOptions}
          expenseOrincome={addingExpensesLineItem ? "expenses" : "income"}
          onSave={handleSaveNewLineItem}
          onDelete={() => {}}
          onCancel={handleCancelSaveNewLineItem}
        />
      )}

      {(editingExpenseLineItem || editingIncomeLineItem) && (
        <AddEditExpenseIncomeModal
          show
          isEditing
          isSaving={isUpdating}
          isDeleting={isUpdating}
          errors={modalErrors}
          expenseOrincome={editingExpenseLineItem ? "expenses" : "income"}
          nameList={editingLineItem.options}
          name={editingLineItem.selectedOption}
          value={editingLineItem.value}
          isPercentage={editingLineItem.valueType === "percent"}
          notes={editingLineItem.notes}
          onSave={handleSaveEditedLineItem}
          onDelete={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveEditedLineItem}
        />
      )}

      {removingLineItem && (
        <DeleteExpenseIncomeModal
          show
          isDeleting={isUpdating}
          errors={modalErrors}
          onConfirm={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveRemovedLineItem}
        />
      )}
    </>
  );
};

TransactionSection.propTypes = {
  financials: PropTypes.shape({
    isReferral: PropTypes.bool,
    // Schema validation for the inner fields will be handled by the inner
    // components, so we mark them as "any" here.
    adaptedData: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      transaction: nullable(PropTypes.any).isRequired,
    }).isRequired,

    lineItemTypeOptions: PropTypes.shape({
      transactionIncome: PropTypes.arrayOf(optionShape),
      transactionExpense: PropTypes.arrayOf(optionShape),
      agent: PropTypes.arrayOf(optionShape),
    }),

    meta: PropTypes.shape({
      isUpdating: PropTypes.bool.isRequired,
      modalErrors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,

  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tdpFinancialsReducer: reducer }) => ({
  financials: { ...reducer.financials },
});

export default connect(mapStateToProps)(TransactionSection);
