import React from "react";
import { useDispatch } from "react-redux";
import { headline2 } from "@shared/styles";
import FieldDateV1 from "../../shared/UtilsV1/FieldDateV1";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";

function CustomDatesView() {
  const dispatch = useDispatch();
  const { milestones = {} } = useContactPerson();
  const { customDateOptions } = useContactFormOptions();
  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>Custom Dates</div>
      <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
        {customDateOptions?.map((option) => (
          <FieldDateV1
            key={option.name}
            label={option.displayName}
            value={milestones[option.name]}
            onChange={(value) => {
              dispatch(setPersonValue({ key: `milestones.${option.name}`, value }));
            }}
          />
        ))}
        {customDateOptions?.length % 2 !== 0 && <div className="tw-flex-1" />}
      </div>
    </div>
  );
}

export default CustomDatesView;
