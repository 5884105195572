import _ from "lodash";

export const expenseInputHandlr = (opt, modalThis, setExpenseValue, expenseType, invertedExpList) => {
  const { label: name, value } = opt;

  const iscompanyLineItemTypeIncome =
    expenseType === "company" && invertedExpList[value] && invertedExpList[value] !== "Custom Expense";

  if (invertedExpList[value] === "Custom") {
    modalThis.setState({
      name: "",
      [expTypeId(expenseType, iscompanyLineItemTypeIncome)]: value,
    });
    return setExpenseValue({ value, label: "" });
  }

  modalThis.setState({
    name,
    [expTypeId(expenseType, iscompanyLineItemTypeIncome)]: value,
  });

  setExpenseValue(opt);
};

export const expenseBtnHandlr = (e, modalThis, setExpenseValue) => {
  setExpenseValue("");
  modalThis.setState({
    type: e.target.value,
  });
};

export const incomeBtnHandlr = (e, modalThis) =>
  modalThis.setState({
    type: e.target.value,
    name: "",
  });

export const incomeExpenseInputTxtHandlr = (e, modalThis, expenseValue, expenseType, invertedExpList) => {
  const { type } = modalThis.state;
  const { value } = expenseValue;

  if (type === "revenue") {
    return modalThis.setState({ name: e.target.value });
  }
  const iscompanyLineItemTypeIncome =
    expenseType === "company" &&
    invertedExpList[expenseValue.value] &&
    invertedExpList[expenseValue.value] !== "Custom Expense";

  modalThis.setState({
    name: e.target.value,
    [expTypeId(expenseType, iscompanyLineItemTypeIncome)]: value,
  });
};

const expTypeId = (expenseType, iscompanyLineItemTypeIncome) =>
  expenseType === "transaction"
    ? "transaction_expense_type_id"
    : expenseType === "company"
    ? iscompanyLineItemTypeIncome
      ? "company_revenue_type_id"
      : "company_expense_type_id"
    : "agent_expense_type_id";
