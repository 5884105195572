import React from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import InlineEditingInput from "../InlineEditingInput";
import { extractNumeric, formatNumber, isValidValue, removeLeadingZeroes } from "../../inputFormatUtils";

export const validateInput = (value) => {
  if (!value) {
    return { isValid: true, newValue: "" };
  }

  const newValue = removeLeadingZeroes(extractNumeric(value)) || "0";
  const isValid = isValidValue(newValue);
  return { isValid, newValue };
};

export const formatInput = (value) => formatNumber(value, true);

const Flat = ({ id, value, placeholder, onChange, withCancelConfirm, className, ...otherProps }) => (
  <InlineEditingInput
    id={id}
    value={value}
    prefix="$"
    placeholder={placeholder}
    onChange={onChange}
    leading={<span>$</span>}
    // Simply to activate the flexbox spacing on the trailing
    additionalTrailing={<span />}
    withCancelConfirm={withCancelConfirm}
    spacing={0}
    className={className}
    size={placeholder?.length || InlineEditingInput.numericDefaultSize}
    validateInput={validateInput}
    formatInput={formatInput}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  />
);

Flat.propTypes = {
  id: PropTypes.string,
  value: nullable(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  withCancelConfirm: PropTypes.bool,
  className: PropTypes.string,
};

Flat.defaultProps = {
  id: null,
  placeholder: "",
  onChange: null,
  withCancelConfirm: true,
  className: "",
};

export default Flat;
