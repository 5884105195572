import React from "react";
import { Col, Row, Clearfix, FormGroup } from "../../../shared/v1";
import { Expense } from "./Expense";
import { formatValue } from "../shared/FinancialViewHelpers";

const TransactionGCI = ({ accountData }) => {
  const { transaction_expense_types } = accountData;

  return (
    <Clearfix className="transaction-gci-wrap">
      <div className="transaction-gci-options">
        <Clearfix className="transaction-gci-header">
          <h3>Office Commission</h3>
        </Clearfix>

        <div className="transaction-gci-content">
          <Row>
            <Col size={2}>
              <FormGroup className="tw-min-h-[50px]">
                <label className="tw-block tw-mb-[5px]">Listing&nbsp;(LOC)</label>
                <span>
                  {formatValue(
                    accountData.account_financial_template?.listing_commission,
                    accountData.account_financial_template?.listing_commission_percentage,
                  )}
                </span>
              </FormGroup>
            </Col>

            <Col size={2}>
              <FormGroup className="tw-min-h-[50px]">
                <label className="tw-block tw-mb-[5px]">Selling&nbsp;(SOC)</label>
                <span>
                  {formatValue(
                    accountData.account_financial_template?.selling_commission,
                    accountData.account_financial_template?.selling_commission_percentage,
                  )}
                </span>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>

      {!!accountData.account_financial_template?.custom_account_expenses_attributes.length && (
        <div className="transaction-gci-options">
          <Clearfix className="transaction-gci-header">
            <h3>Transaction Expenses</h3>
          </Clearfix>

          <div className="transaction-gci-content content-additional">
            {accountData.account_financial_template.custom_account_expenses_attributes.map((expense, i) => {
              return (
                !expense._destroy && (
                  <Expense
                    expense={expense}
                    showBorder
                    key={`custExp0-${i}`}
                    expList={transaction_expense_types}
                    keyString={`transaction-expense-${i}`}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
    </Clearfix>
  );
};

export default TransactionGCI;
