import React from "react";

const fadeStyle = {
  backgroundImage: "linear-gradient(to right, rgba(255,255,255,0), #fff)",
  height: "47px",
  position: "absolute",
  top: "0",
  right: "0",
  width: "40px",
  zIndex: "1",
};

const FadeOut = () => <div style={fadeStyle} />;

export default FadeOut;
