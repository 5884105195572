import moment from 'moment-timezone';

const calculatePercent = (total, amount) => {
  return total !== 0 ? (amount / total * 100) : 0
}

const determineCorrectPercentColor = (allTotalLeads, categoryTotal, individualTotalLeads,individualCategoryTotal) => {
  const individualPercent = calculatePercent(individualTotalLeads, individualCategoryTotal)

  const totalPercent = calculatePercent(allTotalLeads, categoryTotal)
  if (individualPercent === 0 || individualPercent === totalPercent) {
    return '#b3b3b3'
  }
  return individualPercent < totalPercent ? '#f2714d' :'#59c4c4';
}
const truncateTime = (timeString) => {
  if (typeof timeString === 'undefined'){
    timeString = 0;
  }
  const trimmedString = timeString.length > 18 ?
  timeString.substring(0, 18 - 3) + "..." :
  timeString;
  return trimmedString;
}

const calculateCallsTotals = (agents, key) => agents.reduce((sum, current) => sum + current[key], 0)
const calculateTextRespAvg = (agents, key) => {
  const agents_with_resp_time = agents.reduce((sum, current) => current[key] > 0 ? sum + 1 : sum + 0, 0);
  return agents.reduce((sum, current) => sum + current[key], 0) / agents_with_resp_time
}

const secondsToHMS = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600*24));
  const h = Math.floor(seconds % (3600*24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
  const sDisplay =  s + (s == 1 ? " second" : " seconds");
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

const dateFormat = () => 'MM/DD/YY';

const daysAgoRangeDisplay = (min, max) => {
  return `(${daysAgoDisplay(min)} - ${daysAgoDisplay(max)})`;
};

const daysAgoDisplay = numDaysAgo => {
  return moment().subtract(numDaysAgo, 'days').format(dateFormat());
};

const thisYear = () => moment().startOf('year');
const lastYear = () => thisYear().subtract(1, 'year').startOf('year');

const thisMonth = () => moment().startOf('month');
const lastMonth = () => thisMonth().subtract(1, 'month').startOf('month');

const dateRangeOptions = [
  {
    label: 'Today', value: 'today'
  },
  {
    label: 'Yesterday', value: 'yesterday'
  },
  {
    label: `Last 7 days ${daysAgoRangeDisplay(7, 1)}`, value: 'last_seven_days'
  },
  {
    label: `Last 14 days ${daysAgoRangeDisplay(14, 1)}`, value: 'last_fourteen_days'
  },
  {
    label: `Last 30 days ${daysAgoRangeDisplay(30, 1)}`, value: 'last_thirty_days'
  },
  {
    label: `This Month (${thisMonth().format(dateFormat())} - ${daysAgoDisplay(1)})`, value: 'this_month'
  },
  {
    label: `Last Month (${lastMonth().format(dateFormat())} - ${lastMonth().endOf('month').format(dateFormat())})`, value: 'last_month'
  },
  {
    label: `This Year (${thisYear().format(dateFormat())} - ${daysAgoDisplay(1)})`, value: 'this_year'
  },
  {
    label: `Last Year (${lastYear().format(dateFormat())} - ${lastYear().endOf('year').format(dateFormat())})`, value: 'last_year'
  },
  {
    label: `All Time (through ${daysAgoDisplay(1)})`, value: 'all_time'
  },
  {
    label: 'Custom Range', value: 'custom'
  }
];

const leadStatuses = {
  new: 'New',
  unqualified: 'Unqualified',
  watch:  'Watch',
  nurture:  'Nurture',
  hot:  'Hot',
  past: 'Past',
  inactive:  'Inactive',
  archived:  'Archived',
  trash:  'Trash'
}
export {
  calculatePercent,
  determineCorrectPercentColor,
  truncateTime,
  dateRangeOptions,
  leadStatuses,
  calculateCallsTotals,
  secondsToHMS,
  calculateTextRespAvg
}
