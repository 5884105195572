import React from "react";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { bool, func } from "prop-types";

const AgreementsConfirmModal = ({ handleAddAgreement, handleHide, show }) => (
  <Modal
    className="tw-flex tw-justify-center tw-items-center"
    dialogClassName="!tw-w-[440px] tw-h-auto"
    contentClassName="tw-w-full tw-h-full "
    show={show}
    onHide={handleHide}
  >
    <Modal.Header titleClassName="tw-mb-32px tw-text-neutral-gray-75" title="Add Agreement?" closeButton />

    <Modal.Body className="tw-mb-32px">
      <span className="tw-text-14d tw-text-gray-75">Congrats on the signing! Would you like to add the agreement to the contact’s record?</span>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button
        onClick={handleHide}
        className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
        schema="tertiary"
        size="medium"
      >
        CANCEL
      </Button>
      <Button
        className="tw-flex tw-items-center"
        size="medium"
        schema="primary"
        onClick={handleAddAgreement}
      >
        ADD AGREEMENT
      </Button>
    </Modal.Footer>
  </Modal>
);

export default AgreementsConfirmModal;

AgreementsConfirmModal.propTypes = {
  handleAddAgreement: func,
  handleHide: func,
  show: bool
}

AgreementsConfirmModal.defaultProps = {
  handleAddAgreement: () => { },
  handleHide: () => { },
  show: false
}