import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ManageAccessModal from "../modals/ManageAccessModal";
import NonPrimaryTeamMembers from "./NonPrimaryTeamMembers";
import CollapsibleElement from "../CollapsibleElement";
import AgentOrPondSelect from "../../../shared/AgentOrPondSelect";
import * as actions from "../../actions/personDetailActionCreators";
import Loading from "../../../Goals/components/Loader";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const AssignedTo = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const featureFlags = useSelector((state) => state.layout.featureFlags);
  const person = useSelector((state) => state.personDetail.person);
  const manageAccessModalOpen = useSelector((state) => state.personDetail.manageAccessModalOpen);
  const [teamMembers, setTeamMembers] = useState([]);
  const [primaryAgent, setPrimaryAgent] = useState(undefined);
  const [allAssignableRoles, setAllAssignableRoles] = useState([]);

  const openManageAccessModal = () => dispatch(actions.openManageAccessModal());
  const closeManageAccessModal = () => dispatch(actions.closeManageAccessModal());

  const getPersonPermissions = (signal) => {
    axios
      .get(`/person_permissions?person_id=${person.data?.id}`, {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setTeamMembers(response.data);
        setPrimaryAgent(response.data.find((agent) => agent.primary === true));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!person.data?.id) return;

    const abortController = new AbortController();

    getPersonPermissions(abortController.signal);

    axios
      .get("/api/v4/team_roles", {
        signal: abortController.signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setAllAssignableRoles(response.data);
      })
      .catch((error) => {});

    return () => abortController.abort();
  }, [person.data?.id]);

  const handleUpdateAccess = () => {
    // Because we need the team member id which isn't returned from the POST request in the modal
    getPersonPermissions();
  };

  // TODO: do can there be a person without a primary agent and if so what do we render
  const nonPrimaryTeamMembers = teamMembers.filter(
    // This is why we need the team member id, filter out whitelisted agents that are not team members
    (teamMember) => teamMember.id && teamMember.primary === false,
  );

  return (
    <CollapsibleElement
      id="pdp-assigned-to"
      title="Assigned To:"
      buttonText={
        currentUser.account.isAdmin || currentUser.uuid === person.data?.attributes.primary_agent_uuid
          ? "MANAGE"
          : undefined
      }
      buttonClickFunction={openManageAccessModal}
      roundedTop
    >
      {!person.data && <Loading />}
      <div id="person-assigned-to-card" className="assigned-to-wrapper" data-cy="assigned-to-wrapper">
        {primaryAgent && (
          <AgentOrPondSelect
            stacked
            enableLeadPonds={featureFlags.leadPond}
            max="full"
            personId={person.data?.id}
            primaryAgent={primaryAgent}
            leadPond={person.data?.attributes.lead_pond}
            showSubTitle={true}
            nameClasses={"tw-font-semibold"}
            caretClasses="tw-bg-gray-10 tw-h-[22px] tw-w-[22px] tw-rounded-full"
          />
        )}
        {nonPrimaryTeamMembers && !person.data?.attributes.lead_pond && (
          <NonPrimaryTeamMembers
            nonPrimaryTeamMembers={nonPrimaryTeamMembers}
            allAssignableRoles={allAssignableRoles}
          />
        )}
        {manageAccessModalOpen && (
          <ManageAccessModal
            personId={person.data?.id}
            nonPrimaryTeamMembers={nonPrimaryTeamMembers}
            handleUpdateAccess={handleUpdateAccess}
            closeManageAccessModal={closeManageAccessModal}
            primaryAgent={primaryAgent}
            allAssignableRoles={allAssignableRoles}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default AssignedTo;
