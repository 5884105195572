import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useMatch, useResolvedPath } from "react-router-dom";
import errorShape from "../../../Main/Feedback/model/error";
import feedbackShape from "../../../Main/Feedback/model/feedback";
import formDataShape from "../../../Main/Feedback/model/formData";
import metaShape from "../../../Main/Feedback/model/meta";
import MarketingBody from "../MarketingBody";
import MarketingLoadError from "../MarketingLoadError";
import MarketingHeader from "../MarketingHeader";
import MarketingSubheader from "../MarketingSubheader";
import useInitPathName from "../../../Main/hooks";
import FeedbackForm from "../../../Main/Feedback/FeedbackForm";

const Marketing = ({
  getAllFeedbacks,
  newFeedback,
  hideFeedbackForm,
  showFeedbackForm,
  uuid,
  feedbacks,
  meta,
  error,
  formData,
}) => {
  const { initPathName } = useInitPathName();
  const feedbackTabPath = `${initPathName}/feedback`;

  // Verify if the feedback tab is selected
  const feedbackTabResolvedPath = useResolvedPath(feedbackTabPath);
  const isFeedbackTabSelected =
    useMatch({
      path: feedbackTabResolvedPath.pathname,
      end: false,
      caseSensitive: false,
    }) !== null;

  const showNewFeedbackForm = () =>
    showFeedbackForm({ action: "new", data: null, isVisible: true, error: null, isLoading: false });

  const reviewCount = meta?.pagination?.totalCount ?? 0;
  const averageRating = meta?.averageRating ?? 0.0;
  const mostRecentFeedback = feedbacks?.length ? feedbacks[0] : null;

  useEffect(() => {
    getAllFeedbacks();
  }, [uuid]);

  return (
    <>
      <MarketingHeader showNewFeedbackForm={showNewFeedbackForm} />
      <MarketingSubheader
        reviewCount={reviewCount}
        averageRating={averageRating}
        feedbackTabPath={feedbackTabPath}
      />
      {error && <MarketingLoadError getAllFeedbacks={getAllFeedbacks} />}
      {mostRecentFeedback && (
        <MarketingBody
          reviewCount={reviewCount}
          mostRecentFeedback={mostRecentFeedback}
          feedbackTabPath={feedbackTabPath}
        />
      )}
      {!isFeedbackTabSelected && (
        // Only render the feedback form here if the feedback tab is not selected
        <FeedbackForm
          action={formData.action}
          show={formData.isVisible}
          feedback={formData.data}
          error={formData.error}
          isLoading={formData.isLoading}
          onNewFeedback={(feedback) => newFeedback(feedback)}
          onCancel={() => hideFeedbackForm()}
        />
      )}
    </>
  );
};

Marketing.propTypes = {
  getAllFeedbacks: PropTypes.func.isRequired,
  newFeedback: PropTypes.func.isRequired,
  hideFeedbackForm: PropTypes.func.isRequired,
  showFeedbackForm: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  feedbacks: PropTypes.arrayOf(feedbackShape),
  meta: metaShape,
  error: errorShape,
  formData: formDataShape,
};

Marketing.defaultProps = {
  feedbacks: [],
  meta: {
    averageRating: 0.0,
    pagination: {
      totalPages: 0,
      totalCount: 0,
    },
  },
  error: null,
  formData: {
    action: "new",
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
};

export default Marketing;
