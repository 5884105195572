import { connect } from 'react-redux';
import AccountFinancial from '../components/account/AccountFinancial';
import * as actions from '../actions/account';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({ ...state.accountFinancial });

// Don't forget to actually use connect!
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, actions)(AccountFinancial);
