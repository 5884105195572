import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { uniqueId } from "lodash";
import { Spinner } from "./v2/Icomoon";

export const useClickOutside = (ref, callback, includeFocus = false) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    if (includeFocus) document.addEventListener("keyup", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keyup", handleClickOutside);
    };
  }, [ref, callback]);
};

// See https://usehooks.com/useDebounce for hook refrence
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * Component API consumers shouldn't have to worry about internal `id` usage,
 * so this hook generates one if not given.
 *
 * @param {string} prefix Prefix to add to the id (gets appended with "-").
 * @param {string} givenId Given id.
 * @returns {string} Given id or generated id.
 */
export const useGivenOrGeneratedId = (prefix, givenId) =>
  useMemo(() => givenId || uniqueId(`${prefix}-`), [prefix, givenId]);

export const useInput = (initialValue, useTargetOnChange = true) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        setValue(useTargetOnChange ? event.target.value : event);
      },
    },
  };
};

// Generic useState//useEffect combo we see and use everyday.
export const useDependencyWithCleanUp = (dependency, initState = "") => {
  const [something, setSomething] = useState(initState);

  useEffect(() => {
    setSomething(dependency);

    return () => {
      setSomething(initState);
    };
  }, [dependency]);

  return [something, setSomething];
};

export const useIsLoadingIcon = (OtherIcon, loadingIconSize = "m", loadingIconClassName = "") => {
  const [isLoading, setIsLoading] = useState(false);

  const Icon = useMemo(
    () =>
      isLoading ? (
        <Spinner size={loadingIconSize} className={`tw-animate-spin ${loadingIconClassName}`} />
      ) : (
        OtherIcon
      ),
    [isLoading, OtherIcon],
  );

  return {
    Icon,
    isLoading,
    setIsLoading,
  };
};

// Creates a custom selector function that combines a reducer callback and a state callback, which can be used with the useSelector hook to retrieve a specific value from the Redux store.
// i.e.
// const useCreateSelector = createCustomSelector(({ personalSettings }) => personalSettings);
// export const useEmail = () => useCreateSelector((state) => state.user.email);
export const createCustomSelector = (reducerCallback) => (stateCallback) => {
  const selectCustom = createSelector(reducerCallback, stateCallback);

  // Using useSelector with the created selector
  return useSelector(selectCustom);
};
