import PropTypes from "prop-types";

export const extractData = (componentProps) => {
  if (componentProps.data) {
    return componentProps.data;
  }

  const selectedValues = componentProps.getValue();

  // Automatically change the data type based on whether multiple values are enabled or not
  return componentProps.isMulti ? selectedValues || [] : selectedValues[0] || {};
};

export const optionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object,
});

export const groupedOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
});

export const option = PropTypes.oneOfType([optionShape, groupedOptionShape]);

export const data = PropTypes.oneOfType([optionShape, PropTypes.arrayOf(optionShape), PropTypes.shape({})]);

export const customPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.object,
  data,
  children: PropTypes.node,
};

export const customDefaultProps = {
  componentProps: {},
  data: null,
  children: null,
};
