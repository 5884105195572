/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BoltLightningSolidV6 = (props) => (
  <Icon {...props} name="Bolt Lightning Solid V6">
    <path d="M13.912 5.4a.744.744 0 0 0-.662-.4H8.356l1.589-3.972c.093-.23.064-.493-.075-.699S9.472 0 9.25 0h-5.5a.75.75 0 0 0-.743.651l-1 7.5c-.029.214.036.43.179.592s.349.256.564.256h4.572l-1.306 6.066a.75.75 0 0 0 .438.847.484.484 0 0 0 .296.088.75.75 0 0 0 .619-.326l6.5-9.5a.748.748 0 0 0 .043-.774z" />
  </Icon>
);

export default BoltLightningSolidV6;
