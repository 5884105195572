/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Info = (props) => (
  <Icon {...props} name="Info">
    <path d="M8 0a8.001 8.001 0 1 0 .002 16.002A8.001 8.001 0 0 0 8 0zm0 3.548a1.355 1.355 0 1 1 0 2.71 1.355 1.355 0 0 1 0-2.71zm1.806 8.194a.387.387 0 0 1-.387.387H6.58a.387.387 0 0 1-.387-.387v-.774c0-.214.173-.387.387-.387h.387V8.517H6.58a.387.387 0 0 1-.387-.387v-.774c0-.214.173-.387.387-.387h2.065c.214 0 .387.173.387.387v3.226h.387c.214 0 .387.173.387.387v.774z" />
  </Icon>
);

export default Info;
