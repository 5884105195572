import React, { useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Popover, PopoverContent, PopoverTrigger } from "@shared/v2/Popover/Popover";
import colors from "../../PersonDetail/components/colors.module.css";
import button from "../../PersonDetail/components/button.module.css";
import * as actions from "../actions/default";
import * as apiActions from "../api/index";
import statusIntentCss from "../../PersonDetail/components/statusIntentDropdown.module.css";
import IntentDetails from "./IntentDetails";

const mapStateToProps = (state) => ({
  isEmailSent: state.leadIndex.isEmailSent,
  sendEmailError: state.leadIndex.sendEmailError,
});
const Intent = ({ person, updateIntent }) => {
  const [show, setShow] = useState(false);
  const [intent, setIntent] = useState(person.person.lead_type);

  const intentClass = (type) => {
    switch (type) {
      case "buyer":
        return colors.intentBuyer;
      case "seller":
        return colors.intentSeller;
      case "seller/buyer":
        return colors.intentSellerBuyer;
      case "tenant":
        return colors.intentTenant;
      case "landlord":
        return colors.intentLandlord;
      case "recruit":
        return colors.intentRecruit;
      case "candidate":
        return colors.intentCandidate;
      case "n/a":
        return colors.intentNone;
      default:
        return colors.intentBuyer;
    }
  };

  const handleSelect = (index) => {
    const newIntent = person.intent_options[index][1];
    updateIntent(person.person.slug, newIntent, setIntent);
    setShow(false);
  };

  return (
    <div className="tw-relative tw-ml-15px">
      <Popover open={show} onOpenChange={setShow}>
        <PopoverTrigger onClick={() => setShow(true)}>
          <div className="tw-flex tw-justify-center">
            <IntentDetails intent={intent} setshow={() => {}} />
          </div>
        </PopoverTrigger>
        <PopoverContent className="tw-gap-[8px] tw-py-[12px]">
          {person.intent_options.map(([label, value], index) => (
            <button
              key={value}
              type="button"
              className={`focus:tw-outline-none tw-bg-transparent ${intentClass(value)} ${
                button.btnSm
              } ${button.btnDropdown} ${statusIntentCss.statusIntentOption}`}
              onClick={() => handleSelect(index)}
            >
              {label === "N/A" ? "NONE" : label.toUpperCase()}
            </button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};
Intent.propTypes = {
  person: Proptypes.shape({
    intent_options: Proptypes.arrayOf(Proptypes.arrayOf(Proptypes.string)),
    person: Proptypes.shape({ lead_type: Proptypes.string, slug: Proptypes.string }),
  }),
  updateIntent: Proptypes.func,
};
Intent.defaultProps = {
  person: null,
  updateIntent: null,
};

export default connect(mapStateToProps, { ...actions, ...apiActions })(Intent);
