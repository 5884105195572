import React from "react";
import PropTypes from "prop-types";

const Header = ({ children }) => (
  <div className="tw-block tw-w-full tw-bg-white tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-8px tw-mb-16px">
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.element,
};

Header.defaultProps = {
  children: null,
};

export default Header;
