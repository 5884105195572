/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { tryCatchHandlr } from "@shared/Utilities";
import { AtSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const personAPI = (uuid, param) => `/api/v4/activity_feed/person_name?${param}=${uuid}`;
const transactionAPI = (uuid) => `/api/v4/transactions/header/${uuid}`;

const NoteMentionActivity = ({ activity, onReadChange }) => {
  const [notableName, setNotableName] = useState(null);

  useEffect(() => {
    (async () => {
      const notableAPI = activity.data.notableType === "Person" ? personAPI : transactionAPI;

      const [resNotableName, errNotableName] = await tryCatchHandlr(
        caseTransformingAxios.get(`${notableAPI(activity.data.notableId, "person_uuid")}`, {
          authenticity_token: ReactOnRails.authenticityToken(),
        }),
      );

      if (errNotableName) throw errNotableName;

      setNotableName(resNotableName.data.name || resNotableName.data.address?.streetAddress);
    })();
  }, [activity]);

  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={AtSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <span>Added To: {notableName === null ? "" : notableName || "No Name"}</span>
        <span>Mention: {activity.data.note}</span>
      </div>
    </ActivitiesContainer>
  );
};

export default NoteMentionActivity;

NoteMentionActivity.propTypes = {
  ...activityCommonPropTypes,
};

NoteMentionActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
