import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash";
import { DollarSignSolidV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";
import HomeInfoOverlay from "./HomeInfoOverlay";

const transformComments = (comments) => {
  if (typeof comments !== "string") return {};
  return comments
    .split("<br>")
    .map((s) => s.split(": "))
    .reduce((acc, [key, v]) => (key ? { ...acc, [camelCase(key)]: v } : acc), {});
};

const TimelineMakeOfferEvent = ({ event }) => {
  const timelineFields = useMemo(() => {
    const {
      comments,
      property: { main_photo: mainPhoto, ...property },
      ...others
    } = event.timeline_fields;
    return {
      ...others,
      ...transformComments(comments),
      ...property,
      photo: mainPhoto,
      baths: Number(property.bathrooms),
      beds: Number(property.bedrooms),
      price: property.value,
    };
  }, [event.timeline_fields]);

  return (
    <BaseEvent
      icon={<DollarSignSolidV6 size="l" />}
      title={
        <span>
          <strong>Event:</strong> {event.titleized_type}
        </span>
      }
      interactedAt={event.interacted_at_absolute}
    >
      <HomeInfoOverlay timelineFields={timelineFields} />
      {timelineFields.name && <div>Name: {timelineFields.name}</div>}
      {timelineFields.email && <div>Email: {timelineFields.email}</div>}
      {timelineFields.phone && <div>Phone: {timelineFields.phone}</div>}
      {timelineFields.message && <div>Comments: {timelineFields.message}</div>}
      {timelineFields.offerAmount && <div>Offer Amount: {timelineFields.offerAmount}</div>}
      {timelineFields.preApprovedWithALender && (
        <div>Pre-approved with a lender?: {timelineFields.preApprovedWithALender}</div>
      )}
      {timelineFields.seenThisHomeInPerson && (
        <div>Seen this home in person?: {timelineFields.seenThisHomeInPerson}</div>
      )}
      {timelineFields.url && (
        <div>
          URL:{" "}
          <a href={timelineFields.url} target="_blank" rel="noreferrer">
            {timelineFields.url}
          </a>
        </div>
      )}
      {timelineFields.tags && <div>Tags: {timelineFields.tags}</div>}
      {timelineFields.leadType && <div>Lead type: {timelineFields.leadType}</div>}
    </BaseEvent>
  );
};

TimelineMakeOfferEvent.propTypes = {
  event: PropTypes.shape({
    titleized_type: PropTypes.string,
    timeline_fields: PropTypes.shape({
      comments: PropTypes.string,
      property: PropTypes.shape(),
    }),
    interacted_at_absolute: PropTypes.string,
  }).isRequired,
};

export default TimelineMakeOfferEvent;
