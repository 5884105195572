/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";

import { taskHasFilters } from "../../helpers";

const mapStateToProps = (state) => ({
  hasPrimaryFilters: state.tdpTasksReducer.primaryActiveList.length,
  hasActiveTeamMembers: state.tdpTasksReducer.secondaryFilters.activeTeamMembers.length,
  hasActiveUnassigned: state.tdpTasksReducer.secondaryFilters.activeUnassigned,
});

// Wrapping any component with these props from redux ^^
const withFilters = (WrappedComponent) =>
  connect(mapStateToProps)(
    ({ hasPrimaryFilters, hasActiveTeamMembers, hasActiveUnassigned, ...otherProps }) => {
      const taskFilters = taskHasFilters(hasPrimaryFilters, hasActiveTeamMembers, hasActiveUnassigned);

      return <WrappedComponent {...otherProps} taskFilters={taskFilters} />;
    },
  );

export default withFilters;
