import React from "react";
import { string, shape } from "prop-types";
import EventOwnerorReceiver from "./fractal";

const EventAction = ({ owner, children, receiver }) => (
  <>
    <EventOwnerorReceiver user={owner} />
    {children}
    <EventOwnerorReceiver user={receiver} />
  </>
);
EventAction.propTypes = {
  owner: shape({}),
  children: string,
  receiver: string,
};
EventAction.defaultProps = {
  owner: null,
  children: null,
  receiver: null,
};
export default EventAction;
