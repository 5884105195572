import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Tooltip from "@shared/v2/Tooltip";
import {
  ChevronRight,
  ChevronDown,
  Trash as TrashIcon,
  Plus as PlusIcon,
  Edit as EditIcon,
} from "../../../../../shared/v2/Icomoon";

import Avatar from "../../../../../shared/v2/Avatar";
import InlineEditingInput from "../../../../../shared/v2/InlineEditingInput";
import IconButton from "../../../../../shared/v2/IconButton";
import { toNumber } from "../../../../../shared/Utilities";
import { formatNumber } from "../../../../../shared/v2/inputFormatUtils";
import { teamMemberShape } from "../../utils";
import { tdpDetails } from "../../../../../shared/HOCs/withRedux";
import { STATUS, TYPE } from "../../../../../shared/reduxRoutes/tdpDetailsRoutes";

const formatNumberIfFlat = (value, type, commas) => (type === "flat" ? formatNumber(value, commas) : value);

const headerClasses = (isOpen) =>
  `${
    isOpen ? "tw-bg-tinted-gray-50" : "tw-bg-white hover:tw-bg-tinted-gray-50"
  } tw-relative tw-z-10 tw-group tw-flex tw-flex-row tw-items-center tw-h-58px tw-select-none tw-border tw-border-neutral-gray-10 active:tw-border-gray-30 tw-border-solid tw-rounded-4px tw-px-24px tw-py-9px`;

const nameClasses = (isOpen) =>
  `tw-mb-0 tw-text-14d ${
    isOpen ? "tw-text-neutral-gray-75" : "tw-text-neutral-gray-50 group-active:tw-text-neutral-gray-75"
  }`;

const collapseIconClasses = (isOpen) =>
  isOpen
    ? "tw-text-theme-tdp-financials-collapse-icon"
    : "tw-text-theme-tdp-financials-collapse-icon group-hover:tw-text-theme-tdp-financials-collapse-icon-hover group-active:tw-text-theme-tdp-financials-collapse-icon-active";

const Value = ({ children, ...otherProps }) => (
  <div className="tw-text-neutral-gray-75 tw-font-regular" {...otherProps}>
    {children}
  </div>
);

Value.propTypes = {
  children: PropTypes.node.isRequired,
};

/** Formats values like "-100" into "-$100", "50.0" into "$50.0" and so on. */
const formatFlatValue = (valueStr) => {
  if (!valueStr) {
    return "$0.00";
  }

  return valueStr[0] === "-"
    ? `-$${formatNumber(valueStr.substring(1), true)}`
    : `$${formatNumber(valueStr, true)}`;
};

/** Added prefix to labels based on status (primarily: sold) */
const prefixStatusLabel = (status, type) => (status !== "sold" && type !== "referral" ? "Est. " : "");

/**
 * @summary TDP Financials TeamMember component. Responsible to render both an agent or a company row.
 * @param {func} onGciLabelClick - callback to be called when the GCI label is clicked.
 * @param {func} onGciValueClick - callback to be called when the GCI value is clicked.
 * @param {func} onTrashClick - callback to be called when the trash icon is clicked. It works only for agents.
 * @param {func} onUnitClick - callback to be called when the Unit value is clicked.
 * @param {func} onAddExpenses - callback to be called when the add expenses icon is clicked.
 * @param {object} member - member object to be rendered.
 * */
const TeamMember = ({
  children,
  onGciLabelClick,
  onGciValueClick,
  onTrashClick,
  onUnitClick,
  onAddExpenses,
  member,
  status,
  transactionType,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [gciValueType, setGciValueType] = useState(member.gci.type);

  useEffect(() => setGciValueType(member.gci.type), [member]);
  // "Agent GCI" : "Team GCI"
  const agentGciLabel = `${prefixStatusLabel(status, transactionType)}Agent GCI`;
  const teamGciLabel = `${prefixStatusLabel(status, transactionType)}Team GCI`;
  const netIncomeLabel = `${prefixStatusLabel(status, transactionType)}Net Income`;

  return (
    <div className="tw-relative tw-bg-white tw-rounded-4px" data-cy="financials-team-member">
      <div
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === "Enter") setOpen(!isOpen);
        }}
        onClick={() => setOpen(!isOpen)}
        className={headerClasses(isOpen)}
        data-cy="financials-team-member-header"
      >
        {isOpen ? (
          <ChevronDown className={collapseIconClasses(isOpen)} size="xl" data-cy="opened-chevron" />
        ) : (
          <ChevronRight className={collapseIconClasses(isOpen)} size="xl" data-cy="closed-chevron" />
        )}
        <Avatar className="tw-ml-12px" size="medium" src={member.profilePic} alt={member.name} />
        <div className="tw-pl-8px">
          {member.role ? (
            <>
              <p className="tw-mb-0 tw-text-14d tw-text-neutral-gray-100">
                {member.type === "company" ? "Company" : member.role}
              </p>
              <p className={nameClasses(isOpen)}>{member.name}</p>
            </>
          ) : (
            <p className="tw-mb-0 tw-text-14d tw-text-neutral-gray-100">{member.name}</p>
          )}
        </div>
        <div className="tw-ml-auto">
          {member.type === "company" && (
            <div>
              <Value data-cy="financials-team-member-header-net-income">
                {formatFlatValue(member.netIncome)}
              </Value>
            </div>
          )}
          {member.type === "agent" && (
            <>
              <div className="group-hover:tw-hidden">
                <Value data-cy="financials-team-member-header-net-income">
                  {formatFlatValue(member.netIncome)}
                </Value>
              </div>
              <div className="tw-hidden group-hover:tw-block">
                {toNumber(member.gci.value) > 0 ? (
                  <Tooltip
                    className="tw-p-[3px]"
                    innerClassName="tw-max-w-500px"
                    placement="top"
                    trigger={
                      <IconButton
                        size="small"
                        className="tw-bg-white tw-border-white"
                        disabled
                        schema="misc-trash"
                        data-cy="financials-team-member-remove"
                      >
                        <TrashIcon size="m" />
                      </IconButton>
                    }
                    content={
                      status === "sold"
                        ? "Please remove the Agent GCI before deleting the Company Seat"
                        : "Please remove the Estimated Agent GCI before deleting the Company Seat"
                    }
                  />
                ) : (
                  <IconButton
                    size="small"
                    // design team request: It has a different default color to give contrast to the background
                    className="tw-bg-white tw-border-white"
                    schema="misc-trash"
                    onClick={(e) => {
                      // Prevents the header from being clicked
                      e.stopPropagation();
                      onTrashClick(member);
                    }}
                    data-cy="financials-team-member-remove"
                  >
                    <TrashIcon size="m" />
                  </IconButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="tw-relative tw--top-3px tw-z-5 tw-px-24px tw-pb-16px tw-pt-18px tw-bg-white tw-rounded-4px tw-rounded-t-none tw-border-t-0 tw-border tw-border-neutral-gray-10 tw-border-solid tw-bt-0">
          {member.type !== "collaborator" && (
            <div>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <div className="tw-flex tw-flex-col tw-text-left">
                  <div
                    className="tw-group tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-0"
                    data-cy="financials-team-member-gci-label"
                  >
                    {member.type === "agent" ? agentGciLabel : teamGciLabel}
                    <span className="tw-invisible tw-ml-6px group-hover:tw-visible">
                      <IconButton
                        size="small"
                        schema="tertiary"
                        onClick={() => onGciLabelClick(member)}
                        data-cy="financials-team-member-edit-gci"
                      >
                        <EditIcon size="m" />
                      </IconButton>
                    </span>
                  </div>
                  <div className="tw-relative tw-h-25px" data-cy="financials-team-member-gci">
                    <InlineEditingInput.FlatPercent
                      flatOrPercent={gciValueType}
                      value={formatNumberIfFlat(member.gci.value, gciValueType, true)}
                      placeholder="0.00"
                      className="tw-h-36px tw-absolute"
                      onChange={(newValue) => onGciValueClick(member, newValue, gciValueType)}
                      onFlatSelected={() => setGciValueType("flat")}
                      onPercentSelected={() => setGciValueType("percent")}
                    />
                  </div>
                </div>
                {member.showUnits && (
                  <div className="tw-flex tw-flex-col tw-text-center">
                    <label
                      htmlFor={`financials-unit-${member.id}`}
                      className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-0"
                    >
                      Units
                    </label>
                    <div className="tw-relative tw-h-25px" data-cy="financials-team-member-units">
                      <InlineEditingInput
                        value={member.unit}
                        placeholder="0.00"
                        className="tw-h-36px tw-absolute"
                        onChange={(newValue) => onUnitClick(member, newValue)}
                      />
                    </div>
                  </div>
                )}

                <div className="tw-flex tw-flex-col tw-text-right">
                  <div className="tw-text-14d tw-mb-5px tw-text-neutral-gray-50 tw-font-semibold tw-inline">
                    Subtotal
                  </div>
                  <Value data-cy="financials-team-member-subtotal">{formatFlatValue(member.subtotal)}</Value>
                </div>
              </div>
              {member.notes && (
                <p
                  className="tw-text-12d tw-font-normal tw-pt-10px tw-text-neutral-gray-75"
                  data-cy="financials-team-member-notes"
                >
                  {member.notes}
                </p>
              )}
              <hr className="tw-border-t tw-border-solid tw-border-gray-10 tw-mt-16px tw-mb-18px" />
            </div>
          )}
          <div className="tw-flex tw-flex-row tw-justify-between tw-mb-10px">
            <div className="tw-font-semibold tw-text-gray-75">Expenses & Income </div>
            <div>
              <IconButton
                onClick={() => onAddExpenses(member)}
                size="small"
                schema="tertiary"
                data-cy="financials-team-member-add-line-item"
              >
                <PlusIcon size="s" />
              </IconButton>
            </div>
          </div>
          <div className="tw-mb-16px">{children}</div>
          <hr className="tw-border-t tw-border-solid tw-border-gray-10 tw-mt-10px tw-mb-18px" />
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-font-semibold tw-text-gray-75">{netIncomeLabel}</div>
            <div
              className="tw-text-neutral-gray-75 tw-font-semibold"
              data-cy="financials-team-member-net-income"
            >
              {formatFlatValue(member.netIncome)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TeamMember.propTypes = {
  children: PropTypes.node,
  member: teamMemberShape.isRequired,
  onGciLabelClick: PropTypes.func.isRequired,
  onGciValueClick: PropTypes.func.isRequired,
  onTrashClick: PropTypes.func.isRequired,
  onUnitClick: PropTypes.func.isRequired,
  onAddExpenses: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  transactionType: PropTypes.string.isRequired,
};

TeamMember.defaultProps = { children: null };

export default tdpDetails({
  WrappedComponent: TeamMember,
  states: [TYPE, STATUS],
  aliases: ["transactionType"],
});
