import React from "react";

const defaultOrImage = (avatar, name) => {
  if (avatar.match(/^https:/)) {
    return <img className="circled tw-flex-none" src={avatar} alt={name} />;
  } else {
    return <span className="circled user-image default-avatar tw-flex-none">{avatar}</span>;
  }
};

export { defaultOrImage };
