export const SNACKBAR_BASE_CLASSES = `
    tw-py-12px tw-px-16px tw-w-fit tw-max-w-full tw-flex tw-gap-12px
    tw-rounded-[100px] tw-shadow-[0px_2px_8px_rgba(213,213,213,0.5)]
    tw-absolute tw-z-4000 tw-items-center
    tw-border-solid tw-border-1px
  `;

export const SNACKBAR_CLASSES = {
  default: `
        tw-bg-neutral-gray-75 tw-text-neutral-gray-15 tw-border-neutral-gray-15
    `,
  error: `
        tw-bg-semantic-red-5 tw-text-semantic-red-100 tw-border-semantic-red-10
    `,
  executing: `
        tw-bg-neutral-gray-75 tw-text-neutral-gray-15 tw-border-neutral-gray-15
    `,
  executed: `
        tw-bg-semantic-green-5 tw-text-semantic-green-120 tw-border-semantic-green-10
    `,
};

export const availableSchemas = Object.keys(SNACKBAR_CLASSES);

export const snackbarAnimationStyles = (duration) => ({
  transition: `transform ${duration}ms ease-out`,
});

export const snackbarTransitionStyles = {
  top: {
    entering: { transform: "translateY(-100%)" },
    entered: { transform: "translateY(1rem)" },
    exiting: { transform: "translateY(-100%)" },
    exited: { transform: "translateY(-100%)" },
  },
  bottom: {
    entering: { transform: "translateY(0)" },
    entered: { transform: "translateY(calc(-100% - 1rem))" },
    exiting: { transform: "translateY(0)" },
    exited: { transform: "translateY(0)" },
  },
};

export const availablePositions = ["top", "bottom"];
