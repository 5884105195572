import React, { useMemo } from "react";
import { Provider } from "react-redux";
import configureStore from "../../../../store/configureStore";
import NotesIndex from "../components/NotesIndex";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
// railsContext provides contextual information especially useful for server rendering, such as
// knowing the locale. See the React on Rails documentation for more info on the railsContext
const NotesApp = (props) => {
  const store = useMemo(
    () =>
      configureStore({
        notesData: {
          ...props,
          showCreateForm: false,
          showEditForm: false,
          showDeleteForm: false,
          hasError: false,
          errorMessage: null,
          starred: false,
          externallyVisible: true,
        },
      }),
    [],
  );
  return (
    <Provider store={store}>
      <NotesIndex />
    </Provider>
  );
};

export default NotesApp;
