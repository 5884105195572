import React from "react";
import { bool } from "prop-types";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";

import TimeFrameEdit from "../TimeFrameEdit";
import TimeFrameVal from "../TimeFrameVal";
import { IS_TIMEFRAME_EDIT } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

const TimeFrameEditVal = ({ isTimeFrameEdit }) => (isTimeFrameEdit ? <TimeFrameEdit /> : <TimeFrameVal />);

TimeFrameEditVal.propTypes = {
  isTimeFrameEdit: bool,
};

TimeFrameEditVal.defaultProps = {
  isTimeFrameEdit: false,
};

export default tdpDetails({
  WrappedComponent: TimeFrameEditVal,
  states: IS_TIMEFRAME_EDIT,
});
