import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";

const UploadDocContainer = () => (
  <div className="tw-flex">
    <FontAwesomeIcon
      className="tw-text-neutral-gray-30 tw-h-[32px] tw-w-[24px] tw-pr-[16px] tw-mb-12px tw-text-24px"
      icon={faFileAlt}
    />
    <div className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-gray-75">
      Drop your file here, or select from computer
      <br />
      <span className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-gray-50">
        (File Size Limit 15MB)
      </span>
    </div>
  </div>
);

export default UploadDocContainer;
