import React, { useState } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";

import { toggleModalAddTaskOpen, toggleTasksSelectionMode } from "../actions/creators";

import Button from "../../../../shared/v2/Button";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import { ChevronDown } from "../../../../shared/v2/Icomoon";

import Drawer from "../Drawer";
import ModalAddEditTask from "../ModalAddEditTask";

const HeaderOptions = ({
  filtered,
  isModalAddTaskOpen,
  isTasksSelectionMode,
  onToggleModalAddTaskOpen,
  onToggleTasksSelectionMode,
}) => {
  const [isDrawerOpen, toggleDrawer] = useState(false);

  return (
    <div className="tw-ml-auto tw-flex tw-justify-end">
      <ModalAddEditTask show={isModalAddTaskOpen} toggleModalAddTaskOpen={onToggleModalAddTaskOpen} />
      <div className="tw-h-[24px] tw-flex">
        <Button
          className="tw-h-full tw-py-0 tw-mr-[8px]"
          schema="tertiary"
          onClick={onToggleTasksSelectionMode}
        >
          {isTasksSelectionMode ? "CANCEL" : "SELECT TASKS"}
        </Button>
        <Button
          disabled={isTasksSelectionMode}
          className="tw-py-0 tw-mr-[8px]"
          schema={!filtered ? "tertiary" : "primary"}
          onClick={() => toggleDrawer((prev) => !prev)}
        >
          <span>{!filtered ? "FILTER" : "FILTERED "}</span>
          {filtered && <ChevronDown size="s" />}
        </Button>
        <Button
          disabled={isTasksSelectionMode}
          className="tw-w-[24px] tw-flex tw-items-center tw-justify-center"
          schema="tertiary"
          onClick={() => {
            toggleDrawer(false);
            onToggleModalAddTaskOpen((prev) => !prev);
          }}
        >
          <Plus size="s" />
        </Button>
      </div>
      <Drawer show={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filtered: state.tdpTasksReducer.filtered,
  isModalAddTaskOpen: state.tdpTasksReducer.isModalAddTaskOpen,
  isTasksSelectionMode: state.tdpTasksReducer.isTasksSelectionMode,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleModalAddTaskOpen: (boolean) => dispatch(toggleModalAddTaskOpen(boolean)),
  onToggleTasksSelectionMode: () => dispatch(toggleTasksSelectionMode()),
});

HeaderOptions.propTypes = {
  filtered: bool,
  isModalAddTaskOpen: bool,
  isTasksSelectionMode: bool,
  onToggleModalAddTaskOpen: func,
  onToggleTasksSelectionMode: func,
};

HeaderOptions.defaultProps = {
  filtered: false,
  isModalAddTaskOpen: false,
  isTasksSelectionMode: false,
  onToggleModalAddTaskOpen: null,
  onToggleTasksSelectionMode: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOptions);
