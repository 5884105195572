import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import { updateAgentFilters } from "../../reducers/messagingReducer";
import { useAllFilterAgents, useSelectedAgentFilters } from "../../reducers/messagingReducer/selectors";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const ListItem = ({ children }) => (
  <li className="tw-px-[12px] tw-py-[8px] tw-text-14d hover:tw-bg-gray-5">{children}</li>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

const FilterAgentsList = () => {
  const dispatch = useDispatch();
  const [open, setIsOpen] = useState(false);
  const allFilterAgents = useAllFilterAgents();
  const selectedAgentFilters = useSelectedAgentFilters();
  const currentUser = useCurrentUser();
  const filterAgentsListRef = useRef(null);

  useEffect(() => {
    const closeFilterAgentList = (e) => {
      if (!filterAgentsListRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", closeFilterAgentList);
    return () => document.removeEventListener("click", closeFilterAgentList);
  }, [filterAgentsListRef.current]);

  return (
    <div className="tw-relative tw-z-10" ref={filterAgentsListRef}>
      <Button size="medium" onClick={() => setIsOpen((o) => !o)}>
        Filter
      </Button>
      {open && (
        <div className="tw-bg-white tw-z-5 tw-absolute tw-top-full tw-right-0 tw-min-w-[250px] tw-shadow-dropdown tw-rounded-[4px] tw-border-solid tw-border-gray-15">
          <div className="tw-bg-gray-5 tw-px-[12px] tw-py-[8px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-15 tw-text-12d tw-font-semibold">
            Message Filter
          </div>
          <ul className="tw-p-0 tw-m-0 tw-list-none tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-10">
            <ListItem>
              <Checkbox
                id="all_texts"
                label="Show all messages"
                onChange={(e) =>
                  e.target.checked && dispatch(updateAgentFilters({ value: "all", replace: true }))
                }
                checked={selectedAgentFilters[0] === "all"}
              />
            </ListItem>
            <ListItem>
              <Checkbox
                id="my_texts"
                label="Show only my messages"
                onChange={(e) =>
                  e.target.checked &&
                  dispatch(updateAgentFilters({ value: currentUser.person.uuid, replace: true }))
                }
                checked={
                  selectedAgentFilters.length === 1 && selectedAgentFilters[0] === currentUser.person.uuid
                }
              />
            </ListItem>
          </ul>
          <ul className="tw-p-0 tw-m-0 tw-list-none tw-max-h-[260px] tw-overflow-auto">
            {allFilterAgents?.map((agent) => (
              <ListItem key={agent.personUuid}>
                <Checkbox
                  id={agent.personUuid}
                  label={agent.fullName}
                  onChange={() => dispatch(updateAgentFilters({ value: agent.personUuid }))}
                  checked={selectedAgentFilters.includes(agent.personUuid)}
                />
              </ListItem>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterAgentsList;
