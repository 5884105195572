import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import styleSchema from "./IconToggle.styles";

const IconToggleOption = ({ name, value, icon, checked, ...otherProps }) => (
  <label className="tw-m-0 tw-text-center tw-cursor-pointer">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className="tw-sr-only tw-peer"
      {...otherProps}
    />
    <div role="radio" aria-checked={checked} className={styleSchema.regular}>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-[16px] tw-h-[16px]">
        {React.cloneElement(icon, { size: "l" })}
      </div>
    </div>
  </label>
);

IconToggleOption.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  checked: PropTypes.bool.isRequired,
};

IconToggleOption.defaultProps = {
  name: null,
};

const IconToggle = ({ name: nameFromProps, options, value, onChange, className, ...otherProps }) => {
  const name = useGivenOrGeneratedId("icon-toggle", nameFromProps);

  return (
    <fieldset
      className={`tw-inline-block tw-rounded-[100px] tw-border-[1.5px] tw-border-solid tw-border-neutral-gray-10 ${className}`}
    >
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-[24px] tw-m-[-1.5px]">
        {options.map((option) => (
          <IconToggleOption
            key={option.value}
            name={name}
            value={option.value}
            icon={option.icon}
            checked={value === option.value}
            onChange={onChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
        ))}
      </div>
    </fieldset>
  );
};

const createOptionsCustomPropType = (isRequired) => (props, propName, componentName) => {
  const { options } = props;

  if (options === null || typeof options === "undefined") {
    return isRequired ? new Error(`Prop \`${propName}\` is required by \`${componentName}\`.`) : null;
  }

  if (!Array.isArray(options)) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array.`);
  }

  if (options.length < 2) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array with at least two elements.`,
    );
  }

  options.forEach((element) =>
    // Disabling `react/forbid-foreign-prop-types` here since we maintain the IconToggle.Option.propTypes
    // eslint-disable-next-line react/forbid-foreign-prop-types
    PropTypes.checkPropTypes(
      {
        value: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
      },
      element,
      "prop",
      "IconToggle.Option",
    ),
  );

  return null;
};

const optionsCustomPropType = createOptionsCustomPropType(false);
optionsCustomPropType.isRequired = createOptionsCustomPropType(true);

IconToggle.propTypes = {
  name: PropTypes.string,
  options: optionsCustomPropType.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

IconToggle.defaultProps = {
  name: null,
  onChange: null,
  className: "",
};

export default IconToggle;
