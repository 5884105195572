/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LogOut = (props) => (
  <Icon {...props} name="Log Out">
    <path d="m15.779 8.539-5.334 5.334c-.476.476-1.302.143-1.302-.54v-3.048H4.825a.76.76 0 0 1-.762-.762V6.475a.76.76 0 0 1 .762-.762h4.318V2.665c0-.679.822-1.016 1.302-.54l5.334 5.334a.768.768 0 0 1 0 1.079zm-9.683 5.175v-1.27a.382.382 0 0 0-.381-.381H3.048a1.015 1.015 0 0 1-1.016-1.016V4.951c0-.562.454-1.016 1.016-1.016h2.667c.21 0 .381-.171.381-.381v-1.27a.382.382 0 0 0-.381-.381H3.048A3.048 3.048 0 0 0 0 4.951v6.096a3.048 3.048 0 0 0 3.048 3.048h2.667c.21 0 .381-.171.381-.381z" />
  </Icon>
);

export default LogOut;
