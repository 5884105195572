import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { useClickOutside } from "@shared/hookHelpers";
import CheckBox from "@shared/CheckBox";
import Tooltip from "@shared/v2/Tooltip";
import {
  AngleDownRegularV6,
  CalendarCheckRegularV6,
  CommentDotsRegularV6,
  DesktopRegularV6,
  EnvelopeRegularV6,
  ListRegularV6,
  PenToSquareRegularV6,
  PhoneRegularV6,
  PlusSolidV6,
} from "@icons";
import { MergeSolidV6 } from "@shared/v2/Icomoon";

const OTHER_INTERACTION_TYPES = ["Other", "Pop by", "Mail", "Social media"];
const SMS_FILTER_TYPES = ["sms-event", "group_sms_in", "group_sms_out"];

const CustomCheckbox = ({ label, labelClass, checkboxClass, appliedFilters, onChange }) => {
  const labelClassApplied = labelClass.split(" ").every((c) => appliedFilters.has(c));
  return (
    <CheckBox
      divClass={"outer-" + labelClass}
      label={label}
      labelClass={`${labelClass} ${labelClassApplied ? "checked" : ""}`}
      inputClass={checkboxClass}
      inputChecked={labelClassApplied}
      inputName={labelClass}
      objectToCheck={labelClass}
      onUpdate={onChange}
    />
  );
};

const ToggleButton = ({ count, icon, tooltip, onClick, selected }) => {
  const button = (
    <button
      className={`tw-flex tw-gap-[8px] tw-items-center tw-border-0 tw-rounded-[20px] tw-whitespace-nowrap tw-text-14d tw-font-semibold tw-py-[8px] tw-px-[12px] ${
        selected
          ? "tw-text-white tw-bg-teal hover:tw-text-teal hover:tw-bg-teal/40"
          : "tw-text-gray-50 tw-bg-gray-10 hover:tw-bg-gray-15"
      }`}
      onClick={onClick}
    >
      {icon}
      <span>{count}</span>
    </button>
  );

  if (!tooltip) return button;

  return <Tooltip content={tooltip} placement="top" trigger={button} />;
};

const PersonTimelineFilter = ({
  accountActivityFilter,
  appliedFilters,
  handleAccountActivityGroupCheckboxChange,
  handleAllActivityGroupCheckboxChange,
  handleCheckboxChange,
  handleFilterStatsButtonClick,
  handleOtherActivityCheckboxChange,
  handleWebActivityGroupCheckboxChange,
  initialFilters,
  otherActivityFilter,
  webActivityFilter,
  webActivityEventTypes,
}) => {
  const {
    public_activity_events: publicActivityEvents,
    gmail_events: gmailEvents,
    task_events: taskEvents,
    timeline_events: timelineEvents,
    total_gmail_events: totalGmailEvents,
  } = useSelector(({ personDetail }) => personDetail.timeline);
  const timelineLoading = useSelector(({ personDetail }) => personDetail.timeline_loading);
  const [allToggleOpen, setAllToggleOpen] = useState(false);
  const buttonRef = useRef(null);
  useClickOutside(buttonRef, () => setAllToggleOpen(false));

  const messageCounts = () => {
    const mostRecentGmailEventsCount = gmailEvents.length;
    const nonGmailAndMostRecentGmailEventCount =
      publicActivityEvents.filter((event) => event.interaction_type === "message").length +
      mostRecentGmailEventsCount;
    const hasMoreGmailThreads =
      mostRecentGmailEventsCount > 0 && totalGmailEvents > mostRecentGmailEventsCount;

    return hasMoreGmailThreads
      ? `${nonGmailAndMostRecentGmailEventCount}+`
      : nonGmailAndMostRecentGmailEventCount;
  };

  const totalPersonMergeEvents = timelineEvents.filter((e) => e.interaction_type === "person_merge").length;

  return (
    <div
      className={`tw-mt-[15px] tw-ml-[10px] tw-flex tw-flex-wrap tw-justify-center ${totalPersonMergeEvents > 0 ? "tw-gap-[4px]" : "tw-gap-[8px]"}`}
    >
      <div
        className={allToggleOpen ? "open" : ""}
        onClick={() => setAllToggleOpen((open) => !open)}
        ref={buttonRef}
      >
        <ToggleButton
          icon="ALL"
          count={<AngleDownRegularV6 />}
          selected={
            otherActivityFilter && accountActivityFilter && webActivityFilter && appliedFilters.size > 6
          }
        />
        <ul className="dropdown-menu" style={{ left: buttonRef?.current?.offsetLeft }}>
          <li className="p-l-15">
            <CheckBox
              divClass="all-activities-group-filter"
              label="All"
              labelClass={
                appliedFilters.size === initialFilters.size
                  ? "checked group-filter"
                  : "unchecked group-filter"
              }
              inputClass={"all-activity-group-filter filter"}
              inputChecked={appliedFilters.size === initialFilters.size}
              inputName={"all-activity"}
              objectToCheck={"all-activity"}
              onUpdate={handleAllActivityGroupCheckboxChange}
              key={"all-activity-filter"}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Notes"
              appliedFilters={appliedFilters}
              labelClass="note-event"
              checkboxClass="activity-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Emails"
              appliedFilters={appliedFilters}
              labelClass="message-event"
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Phone Calls"
              appliedFilters={appliedFilters}
              labelClass="call-event"
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Texts"
              appliedFilters={appliedFilters}
              labelClass={SMS_FILTER_TYPES.join(" ")}
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Completed Appts"
              appliedFilters={appliedFilters}
              labelClass="appointment-event"
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Scheduled Appts"
              appliedFilters={appliedFilters}
              labelClass="appointment_scheduled-event"
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CheckBox
              divClass="other-activities-group-filter"
              label="Other"
              labelClass={otherActivityFilter ? "checked group-filter" : "unchecked group-filter"}
              inputClass="other-activity-group-filter filter"
              inputChecked={otherActivityFilter}
              inputName="other-activity"
              objectToCheck="other-activity"
              onUpdate={handleOtherActivityCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CheckBox
              divClass="account-activities-group-filter"
              label="Account Activity"
              labelClass={accountActivityFilter ? "checked group-filter" : "unchecked group-filter"}
              inputClass="account-activity-group-filter filter"
              inputChecked={accountActivityFilter}
              inputName="account-activity"
              objectToCheck="account-activity"
              onUpdate={handleAccountActivityGroupCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CheckBox
              divClass="web-activities-group-filter"
              label="Web Activity"
              labelClass={webActivityFilter ? "checked group-filter" : "unchecked group-filter"}
              inputClass="web-activity-group-filter filter"
              inputChecked={webActivityFilter}
              inputName="web-activity"
              objectToCheck="web-activity"
              onUpdate={handleWebActivityGroupCheckboxChange}
            />
          </li>
          <li className="p-l-15">
            <CustomCheckbox
              label="Merges"
              appliedFilters={appliedFilters}
              labelClass="person_merge"
              checkboxClass="interaction-filter filter"
              onChange={handleCheckboxChange}
            />
          </li>
        </ul>
      </div>

      <ToggleButton
        count={
          timelineLoading ? "" : publicActivityEvents.filter((e) => e.interaction_type === "note").length
        }
        icon={<PenToSquareRegularV6 size="l" />}
        tooltip="Notes"
        onClick={() => handleFilterStatsButtonClick("note-event")}
        selected={appliedFilters.size === 1 && appliedFilters.has("note-event")}
      />
      <ToggleButton
        count={timelineLoading ? "" : messageCounts()}
        selected={appliedFilters.size === 1 && appliedFilters.has("message-event")}
        onClick={() => handleFilterStatsButtonClick("message-event")}
        icon={<EnvelopeRegularV6 size="l" />}
        tooltip="Emails"
      />
      <ToggleButton
        count={
          timelineLoading ? "" : publicActivityEvents.filter((e) => e.interaction_type === "call").length
        }
        selected={appliedFilters.size === 1 && appliedFilters.has("call-event")}
        onClick={() => handleFilterStatsButtonClick("call-event")}
        icon={<PhoneRegularV6 size="l" />}
        tooltip="Phone Calls"
      />
      <ToggleButton
        count={
          timelineLoading
            ? ""
            : [...timelineEvents, ...publicActivityEvents].filter((e) =>
                ["sms", "group_sms_in", "group_sms_out"].includes(e.interaction_type),
              ).length
        }
        selected={appliedFilters.size === 3 && SMS_FILTER_TYPES.every((c) => appliedFilters.has(c))}
        onClick={() => handleFilterStatsButtonClick(SMS_FILTER_TYPES.join(" "))}
        icon={<CommentDotsRegularV6 size="l" />}
        tooltip="Texts"
      />
      <ToggleButton
        count={
          timelineLoading
            ? ""
            : publicActivityEvents.filter(
                (e) => e.interaction_type && e.interaction_type.includes("appointment"),
              ).length
        }
        selected={appliedFilters.size === 2 && appliedFilters.has("appointment-event")}
        onClick={() => handleFilterStatsButtonClick("appointment-event")}
        icon={<CalendarCheckRegularV6 size="l" />}
        tooltip="Appointments"
      />
      <ToggleButton
        count={
          timelineLoading
            ? ""
            : publicActivityEvents.filter((e) => OTHER_INTERACTION_TYPES.includes(e.interaction_type)).length
        }
        selected={otherActivityFilter === true && appliedFilters.size === 4}
        onClick={() => handleFilterStatsButtonClick("other-activity")}
        icon={<PlusSolidV6 size="l" />}
        tooltip="Other"
      />
      <ToggleButton
        count={
          timelineLoading
            ? ""
            : taskEvents.length +
              timelineEvents.filter(
                (e) =>
                  !webActivityEventTypes.includes(e.interaction_type) &&
                  e.interaction_type !== "property_view",
              ).length
        }
        selected={accountActivityFilter === true && appliedFilters.size === 4}
        onClick={() => handleFilterStatsButtonClick("account-activity")}
        icon={<ListRegularV6 size="l" />}
        tooltip="Account Activity"
      />
      <ToggleButton
        count={
          timelineLoading
            ? ""
            : timelineEvents.filter((e) => webActivityEventTypes.includes(e.interaction_type)).length
        }
        selected={webActivityFilter === true && appliedFilters.size === webActivityEventTypes.length}
        onClick={() => handleFilterStatsButtonClick("web-activity")}
        icon={<DesktopRegularV6 size="l" />}
        tooltip="Web Activity"
      />
      {totalPersonMergeEvents > 0 && (
        <ToggleButton
          count={timelineLoading ? "" : totalPersonMergeEvents}
          selected={appliedFilters.size === 1 && appliedFilters.has("person_merge")}
          onClick={() => handleFilterStatsButtonClick("person_merge")}
          icon={<MergeSolidV6 size="l" />}
          tooltip="Merges"
        />
      )}
    </div>
  );
};

export default PersonTimelineFilter;
