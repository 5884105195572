import React from "react";
import { func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TRANSACTIONAMT, CLIENTINTENT, LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import { putRefTransactionAmtAsThunk } from "../../actions/thunks";

import HeaderFinancial from "../../shared/HeaderFinancial";

const TRANSACTION_AMT_ALIASES = {
  buyer: "Budget",
  seller: "Estimated Home Value",
  tenant: "Monthly Budget",
  landlord: "Estimated Monthly Rent",
};

const TransactionAmt = ({ transactionAmt, clientIntent, onPutRefTransactionAmtAsThunk, uuid }) => {
  const onChangeHandlr = (transactionAmount) =>
    onPutRefTransactionAmtAsThunk({
      transactionAmount,
      uuid,
    });

  return (
    <HeaderFinancial
      containerClassName="tw-mb-24px"
      value={transactionAmt}
      label={TRANSACTION_AMT_ALIASES[clientIntent]}
      onChange={onChangeHandlr}
      flatOrPercent="flat"
    />
  );
};

const withReduxState = tdpDetails({
  WrappedComponent: TransactionAmt,
  states: [TRANSACTIONAMT, CLIENTINTENT, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: putRefTransactionAmtAsThunk,
  aliases: "onPutRefTransactionAmtAsThunk",
});

TransactionAmt.propTypes = {
  transactionAmt: string,
  clientIntent: string.isRequired,
  onPutRefTransactionAmtAsThunk: func,
  uuid: string.isRequired,
};

TransactionAmt.defaultProps = {
  transactionAmt: "0",
  onPutRefTransactionAmtAsThunk: () => {},
};
