import React from "react";
import { Col, Row } from "react-bootstrap";
import modal_css from "../modal.module.css";

const HeaderTitle = (props) => {
  const { isNewHeader, title, onClose } = props;
  return (
    <Row className="tw-flex tw-items-center">
      <Col xs={10} className={isNewHeader ? modal_css.modalTitle : modal_css.newModalTitle}>
        {title}
        {props.children}
      </Col>
      <Col xs={2}>
        <button type="button" className="close">
          <span aria-hidden="true" onClick={onClose}>
            ×
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Col>
    </Row>
  );
};

export default HeaderTitle;
