import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/v2/Button";
import IconButton from "../../shared/v2/IconButton";
import { TrashAltSolidV3 } from "../../shared/v2/Icomoon";
import Card from "./Card";
import DisplayRow from "./DisplayRow";
import EditableTitle from "./EditableTitle";

const OfficeCard = ({ model, setValue, saveValue, onAdd, onRemove, editable, SECTIONS }) => (
  <Card
    title={
      <EditableTitle
        value={model.name || "Office"}
        onChange={(v) => setValue("name", v)}
        onBlur={() => saveValue("name", model.name)}
        editable={editable}
      />
    }
    action={
      <div className="tw-justify-end tw-flex-1 tw-flex tw-items-center">
        {onRemove && (
          <IconButton schema="misc-trash" size="small" onClick={() => onRemove(model?.id)}>
            <TrashAltSolidV3 size="m" />
          </IconButton>
        )}
        {onAdd && (
          <Button schema="secondary" size="small" onClick={onAdd} className="tw-ml-[8px]">
            + OFFICE
          </Button>
        )}
      </div>
    }
  >
    {SECTIONS.map((sec) => (
      <DisplayRow
        key={sec.key}
        title={sec.title}
        editable={sec.editable}
        value={model[sec.key]}
        displayValue={sec.displayValue}
        onChange={(val) => setValue(sec.key, val)}
        onSave={(val) => saveValue(sec.key, val)}
        Comp={sec.comp}
        isDD={sec.isDD}
      />
    ))}
  </Card>
);

export const OFFICE_PROPS = {
  model: PropTypes.shape({
    account_offices: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address_attributes: PropTypes.shape({}).isRequired,
    address: PropTypes.shape({}).isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  saveValue: PropTypes.func.isRequired,
};

OfficeCard.propTypes = {
  ...OFFICE_PROPS,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

OfficeCard.defaultProps = {
  onAdd: null,
  onRemove: null,
};

export default OfficeCard;
