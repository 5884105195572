/* eslint-disable react/prop-types, react/jsx-props-no-spreading, import/no-named-as-default, import/no-named-as-default-member */

import React from "react";
import HeaderCell from "./shared/HeaderCell";
// eslint-disable-next-line import/no-cycle
import Transaction from "./Transaction";
import TotalsBreakdown from "./shared/TotalsBreakdown";
import CurrencyCell from "./shared/CurrencyCell";
import { dynamicRow } from "../utils/dynamicRow";
import Pagination from "./Pagination";
import Cluster from "../../shared/v2/Cluster";
import ChartPieSolidV6 from "../../shared/v2/Icomoon/Icons/ChartPieSolidV6";
import Tooltip from "../../shared/v2/Tooltip";

const sortOptions = (key) => [key, `${key}_desc`];

const CurrencyTotalCell = ({ value }) => (
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  <td className="tw-font-bold tw-px-28px">
    <CurrencyCell justify="tw-justify-start" value={value.toString()} isEditable={false} />
  </td>
);

const EstimatedBreakdown = ({ total, estimated, actual }) => (
  <td className="tw-bg-gray-10" colSpan={1}>
    <div className="tw-flex tw-items-center tw-space-x-20px tw-font-bold">
      <div className="tw-flex tw-items-center">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <td className="tw-font-bold tw-pl-28px tw-pr-[4.98px]">
          <CurrencyCell justify="tw-justify-start" value={total.toString() || 0} isEditable={false} />
        </td>
        <Tooltip
          tooltipClassName="tw-shadow-theme-totals-breakdown !tw-text-theme-totals-breakdown tw-rounded-4px"
          arrowClassName="before:!tw-bg-theme-totals-breakdown"
          innerClassName="!tw-p-0 !tw-bg-theme-totals-breakdown !tw-text-theme-totals-breakdown"
          placement="top"
          trigger={
            <div className="tw-flex">
              <ChartPieSolidV6 size="custom" className="tw-text-theme-primary tw-w-18px tw-h-18px" />
            </div>
          }
          content={
            <div className="tw-flex tw-flex-col tw-gap-6px tw-min-w-[188px] tw-max-w-fit tw-px-8px tw-py-6px">
              <div key="Estimated" className="tw-flex tw-flex-row">
                <span className="tw-text-left tw-text-14d tw-capitalize tw-grow tw-ml-6px">Estimated:</span>
                <span className="tw-text-14d tw-ml-14px">
                  <CurrencyCell
                    justify="tw-justify-start"
                    value={estimated.toString() || 0}
                    isEditable={false}
                  />
                </span>
              </div>
              <div key="Closed" className="tw-flex tw-flex-row">
                <span className="tw-text-left tw-text-14d tw-capitalize tw-grow tw-ml-6px">Closed:</span>
                <span className="tw-text-14d tw-ml-14px">
                  <CurrencyCell
                    justify="tw-justify-start"
                    value={actual.toString() || 0}
                    isEditable={false}
                  />
                </span>
              </div>
            </div>
          }
        />
      </div>
    </div>
  </td>
);

const TransactionsTable = ({
  listings,
  totals = {},
  order,
  sources,
  updateListing,
  archiveListing,
  deleteListing,
  setOrder: onSort,
  renderableColumns,
  newMilestonesColumns,
  permissions,
  pagination,
  setPage,
}) => {
  const newMilestonesKeys = Object.keys(newMilestonesColumns);

  const headerColumns = dynamicRow(renderableColumns, [
    ["photo", <HeaderCell title="Photo" px="tw-px-[16px]" justify="tw-justify-start" />],
    [
      "address",
      <HeaderCell
        title="Address / Title"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("address")}
        className="tw-border-0 tw-border-r tw-border-solid tw-border-gray-light"
      />,
    ],
    ["type", <HeaderCell title="Type" justify="tw-justify-center" />],
    [
      "status",
      <HeaderCell
        title="Status"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("status")}
        justify="tw-justify-center"
      />,
    ],
    ["mls", <HeaderCell title="MLS#" />],
    ["agent", <HeaderCell title="Agent" />],
    [
      "dateListed",
      <HeaderCell
        title="Date Listed"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("date_listed")}
      />,
    ],
    [
      "price",
      <HeaderCell
        title="Listing Price"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("listing_price")}
      />,
    ],
    [
      "expiration",
      <HeaderCell
        title="Expiration"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("expiration_date")}
      />,
    ],
    [
      "closePrice",
      <HeaderCell
        title="Close Price"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("close_price")}
      />,
    ],
    [
      "gci",
      <HeaderCell
        title="GCI"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("gross_commission")}
      />,
    ],
    [
      "closeDate",
      <HeaderCell
        title="Close Date"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("close_date")}
      />,
    ],
    ["dom", <HeaderCell title="DOM" />],
    [
      "dateCanceled",
      <HeaderCell
        title="Date Canceled"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("date_canceled")}
      />,
    ],
    [
      "mutualAcceptanceDate",
      <HeaderCell
        title="Mutual Acceptance Date"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("mutual_acceptance_date")}
      />,
    ],
    [
      "lastUpdated",
      <HeaderCell
        title="Last Updated"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("last_updated")}
      />,
    ],
    ["source", <HeaderCell title="Source" />],
    [
      "statusChangedAt",
      <HeaderCell
        title="Status Changed At"
        isSortable
        sortedBy={order}
        onSort={onSort}
        sortOptions={sortOptions("status_changed_at")}
      />,
    ],
    ...newMilestonesKeys.map((key) => [key, <HeaderCell title={newMilestonesColumns[key].label} />]),
  ]);

  const footerColumns = dynamicRow(renderableColumns, [
    [
      "photo",
      <td className="tw-bg-gray-10" colSpan={2}>
        <div className="tw-flex tw-items-center tw-ml-32px tw-space-x-20px tw-font-bold">
          <div className="tw-flex tw-items-center">
            <span>Totals:</span>
            <span className="tw-ml-8px">{totals.totals || 0}</span>
            <span className="tw-ml-5px">
              <TotalsBreakdown totals={totals} />
            </span>
          </div>
        </div>
      </td>,
    ],
    ["type", <td aria-label="type" />],
    ["status", <td aria-label="status" />],
    ["mls", <td aria-label="mls" />],
    ["agent", <td aria-label="agent" />],
    ["dateListed", <td aria-label="dateListed" />],
    ["price", <CurrencyTotalCell value={totals.listingPrice || 0} />],
    ["expiration", <td aria-label="expiration" />],
    [
      "closePrice",

      <EstimatedBreakdown
        total={totals.closedVolume}
        estimated={totals.estimatedClosedVolume}
        actual={totals.actualClosedVolume}
      />,
    ],
    [
      "gci",
      <EstimatedBreakdown
        total={totals.grossCommission}
        estimated={totals.estimatedGrossCommission}
        actual={totals.actualGrossCommission}
      />,
    ],
    ["closeDate", <td aria-label="closeDate" />],
    ["dom", <td aria-label="dom" />],
    ["dateCanceled", <td aria-label="dateCanceled" />],
    ["mutualAcceptanceDate", <td aria-label="mutualAcceptanceDate" />],
    ["lastUpdated", <td aria-label="lastUpdated" />],
    ["source", <td aria-label="source" />],
    ["statusChangedAt", <td aria-label="statusChangedAt" />],
    ...newMilestonesKeys.map((key) => [key, <td aria-label="newMilestonesKeys" />]),
  ]);

  return (
    <>
      <div className="tw-w-full tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-flex-1 tw-overflow-auto transactions-table-container">
        <table
          style={{ borderSpacing: 0 }}
          id="transactions-table"
          className="tw-border-separate tw-text-center tw-text-14px tw-full tw-overflow-x-scroll"
        >
          <thead>
            <tr className="transactions__header transactions__row tw-border-0 tw-text-gray-50 tw-h-58px tw-bg-white tw-sticky tw-top-0 tw-z-20">
              {headerColumns}
              <HeaderCell title="Edit" />
            </tr>
          </thead>
          <tbody className="tw-text-gray-99">
            {listings.map((listing) => (
              <Transaction
                key={listing.id}
                listing={listing}
                sources={sources}
                archiveListing={archiveListing}
                deleteListing={deleteListing}
                updateListing={updateListing(listing)}
                renderableColumns={renderableColumns}
                permissions={permissions}
              />
            ))}
          </tbody>
          <tfoot>
            {listings.length > 0 && (
              <tr className="tw-h-58px tw-bg-gray-10 tw-text-gray-75 tw-border tw-border-l-0 tw-border-t-1px tw-border-solid tw-border-gray-light transactions__footer transactions__row tw-sticky tw-bottom-0 tw-z-20">
                {footerColumns}
                <td aria-label="footerColumns" />
              </tr>
            )}
          </tfoot>
        </table>
      </div>

      {pagination.totalPages > 1 && (
        <Cluster align="center" justify="center">
          <div className="tw-p-[12px]">
            <Pagination {...pagination} onGoToPage={setPage} />
          </div>
        </Cluster>
      )}
    </>
  );
};

export default TransactionsTable;
