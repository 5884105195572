import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import HeaderTitle from "./HeaderTitle";
import EditSocialMediaForm from "../EditSocialMediaForm";

const EditSocialMediaModal = ({ closeEditSocialMediaModal }) => (
  <Modal
    id="edit-social-media-modal"
    className="person-details-modal"
    show={true}
    onHide={closeEditSocialMediaModal}
    bsSize={"small"}
  >
    <Modal.Header>
      <Modal.Title>
        <HeaderTitle title="Edit Social Media Links" onClose={closeEditSocialMediaModal} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EditSocialMediaForm />
    </Modal.Body>
  </Modal>
);

export default EditSocialMediaModal;
