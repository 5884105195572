import React from "react";
import PropTypes from "prop-types";

const SIZES = {
  l: "tw-w-[14px] tw-h-[14px]",
  m: "tw-w-[12px] tw-h-[12px]",
};

const Dot = ({ className, size }) => (
  <span className={`tw-inline-flex ${SIZES[size]} tw-rounded-full ${className}`} />
);

Dot.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.string,
};

Dot.defaultProps = {
  size: "l",
};

export default Dot;
