import React from "react";
import { string, func } from "prop-types";
import { HEADING_TEXT_CLASSES, TEXT_CLASSES } from "../api/constants";
import ListingDetailsField from "./ListingDetailsField";
import listingDataShape from "../model/listingData";

const ReviewImportListingDetails = ({ containerClassName, listingData, setListingData }) => (
  <div>
    <div className={`${containerClassName} tw-overflow-y-scroll`}>
      For fields containing existing data, decide whether to retain the current data or replace it with
      information from the MLS import.
      <div className="tw-pt-[25px] tw-pb-[9px]">
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[12px]">
          <span className="tw-flex-1"></span>
          <div className="tw-flex tw-items-center tw-gap-[10px]">
            <div
              className={`${TEXT_CLASSES} tw-pl-[25px] tw-pr-[8px] tw-inline-flex tw-flex-row tw-justify-start tw-w-[218px]`}
            >
              MLS Listing Data
            </div>
            <span className="tw-w-[12px]" />
            <div
              className={`${TEXT_CLASSES} tw-pl-[25px] tw-pr-[8px] tw-inline-flex tw-flex-row tw-justify-start tw-w-[218px]`}
            >
              Current Listing Data
            </div>
          </div>
        </div>
      </div>
      <hr className="tw-border-2 tw-pb-[24px] tw-w-full tw-m-0 tw-border-neutral-gray-10" />
      <div className="tw-grid tw-grid-cols-3 tw-gap-[12px] tw-px-[9px]">
        <div className={HEADING_TEXT_CLASSES}>Listing Details</div>
        <div />
        <div />
      </div>
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Listing Price"
        name="price"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="MLS#"
        name="mlsNumber"
      />
      <div className="tw-pb-[24px]" />
      <hr className="tw-border-2 tw-pb-[24px] tw-w-full tw-m-0 tw-border-neutral-gray-10" />
      <div className="tw-grid tw-grid-cols-3 tw-gap-[12px] tw-px-[9px]">
        <div className={HEADING_TEXT_CLASSES}>Property Details</div>
        <div />
        <div />
      </div>
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Street Address"
        name="streetAddress"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="City"
        name="city"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="State/Province"
        name="state"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Zip/Postal Code"
        name="postalCode"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Property Type"
        name="property_type"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Beds"
        name="beds"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Baths"
        name="baths"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="SQFT"
        name="sqFt"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Neighborhood"
        name="neighborhood"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="County"
        name="county"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Year Built"
        name="year_built"
      />
      <ListingDetailsField
        listingData={listingData}
        setListingData={setListingData}
        label="Parking"
        name="parking"
      />
      <div className="tw-pb-[24px]" />
      <hr className="tw-border-2 tw-pb-[24px] tw-w-full tw-m-0 tw-border-neutral-gray-10" />
      <div>
        <ListingDetailsField
          listingData={listingData}
          setListingData={setListingData}
          label="Listing Description"
          name="description"
        />
      </div>
      <div className="tw-pb-[24px]" />
      <hr className="tw-border-2 tw-pb-[24px] tw-w-full tw-m-0 tw-border-neutral-gray-10" />
      <div>
        <ListingDetailsField
          listingData={listingData}
          setListingData={setListingData}
          label="Listing Photos"
          name="photosAvailable"
        />
      </div>
      <div className="tw-pb-[24px]" />
      <hr className="tw-border-2 tw-pb-[24px] tw-w-full tw-m-0 tw-border-neutral-gray-10" />
    </div>
  </div>
);

ReviewImportListingDetails.propTypes = {
  listingData: listingDataShape,
  setListingData: func,
  containerClassName: string,
};

ReviewImportListingDetails.defaultProps = {
  containerClassName: "",
  setListingData: () => {},
};

export default ReviewImportListingDetails;
