import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import IconButton  from "@shared/v2/IconButton";
import AvatarControl from "./AvatarControl";
import AvatarOption from "./AvatarOption";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";

const DisplayPerson = ({ value, options, onDelete, onChange }) => {
  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-flex-1 tw-mr-2">
        <Dropdown
          value={value}
          options={options}
          onChange={onChange}
          isSearchable
          clearable
          components={{
            Control: AvatarControl,
            Option: AvatarOption,
          }}
        />
      </div>
      <IconButton
        onClick={onDelete}
        className="tw-block tw-bg-gray-200 hover:tw-border-teal tw-rounded tw-border-[1px] tw-border-solid tw-border-gray-300 tw-w-16 tw-p-0"
      >
        <Trash />
      </IconButton>
    </div>
  );
};

DisplayPerson.propTypes = {
  value: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

DisplayPerson.defaultProps = {
  value: null,
  options: [],
  onDelete: null,
  onChange: null,
};

export default DisplayPerson;
