import React from "react";

import Pagination from "../../Transactions/components/Pagination";

const PageBar = ({ totalCount, currentOffset, pageSize, onChangePage }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPage = currentOffset ? currentOffset / pageSize + 1 : 1;
  const nextPage = currentPage === totalPages ? currentPage : currentPage + 1;
  const previousPage = currentPage === 1 ? currentPage : currentPage - 1;

  const onGoToPage = (pageNumber) => {
    const newOffset = (pageNumber - 1) * pageSize;
    onChangePage(newOffset);
  };

  return (
    <div className="tw-flex tw-flex-row tw-w-full tw-justify-center tw-my-16px">
      <Pagination
        currentPage={currentPage}
        nextPage={nextPage}
        prevPage={previousPage}
        totalPages={totalPages}
        onGoToPage={onGoToPage}
      />
    </div>
  );
};

export default PageBar;
