import React from "react";

const Dnc = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="tw-py-0 tw-px-4px tw-rounded-2px tw-border-[1.5px] tw-border-solid tw-border-[#E5342E] tw-text-[#E5342E] tw-text-[8px] tw-font-bold tw-leading-[13px]">
      DNC
    </div>
  );
};

export default Dnc;
