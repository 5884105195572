import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { string, func, bool } from "prop-types";

import IconButton from "@shared/v2/IconButton";
import DropdownPopUp from "@shared/DropdownPopUp";
import {
  AddUser,
  EllipsisSolidV6,
  LockOpenSolidV6,
  LockSolidV6,
  PencilSolidV6,
  TrashCanSolidV6,
} from "@shared/v2/Icomoon";
import { useIsLoadingIcon } from "@shared/hookHelpers";

import { toggleUserModal } from "../actions/default";

const DROPDOWN_OPTION_STYLE = "tw-flex tw-items-center tw-px-12px tw-py-[6px] tw-gap-[8px] tw-cursor-pointer";

const AdminUserBtn = ({ userId, agentType, isActive, activeHandlr, suspendHandlr, suspended }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { Icon, isLoading, setIsLoading } = useIsLoadingIcon(<EllipsisSolidV6 />);
  const LockIcon = suspended ? LockOpenSolidV6 : LockSolidV6;
  const ActiveIcon = isActive ? TrashCanSolidV6 : AddUser;

  const dispatch = useDispatch();

  const onActiveHandlr = () => {
    setShowDropdown(false);
    activeHandlr();
  };

  const onLoadWrapper = async (cb) => {
    if (isLoading) return;

    setShowDropdown(false);
    setIsLoading(true);
    await cb();
    setIsLoading(false);
  };

  // if we need to reuse this component, need to hoist these actions to a parent component
  const onEditHandlr = () => onLoadWrapper(() => dispatch(toggleUserModal(userId)));

  const onSuspendHandlr = () =>
    onLoadWrapper(async () => {
      suspendHandlr();
    });

  return (
    <div className="tw-relative">
      <IconButton
        schema="tertiary"
        onClick={() => {
          if (isLoading) return;

          setShowDropdown(true);
        }}
      >
        {Icon}
      </IconButton>
      <DropdownPopUp
        containerClassName="tw-z-[2] tw-shadow tw-w-[178px]"
        show={showDropdown}
        onHide={() => setShowDropdown(false)}
      >
        <div
          role="button"
          tabIndex="0"
          className={DROPDOWN_OPTION_STYLE}
          onClick={onEditHandlr}
          onKeyUp={onEditHandlr}
        >
          <PencilSolidV6 size="m" />
          <span>Edit Details</span>
        </div>
        {isActive && agentType !== "Account Owner" && (
          <div
            role="button"
            tabIndex="0"
            className={DROPDOWN_OPTION_STYLE}
            onClick={onSuspendHandlr}
            onKeyUp={onSuspendHandlr}
          >
            <LockIcon size="m" />
            <span>{suspended ? "Unsuspend" : "Suspend"} User</span>
          </div>
        )}
        {!suspended && agentType !== "Account Owner" && (
          <div
            role="button"
            tabIndex="0"
            className={DROPDOWN_OPTION_STYLE}
            onClick={onActiveHandlr}
            onKeyUp={onActiveHandlr}
          >
            <ActiveIcon size="m" />
            <span>{isActive ? "Archive" : "Activate"} User</span>
          </div>
        )}
      </DropdownPopUp>
    </div>
  );
};

AdminUserBtn.propTypes = {
  userId: string.isRequired,
  activeHandlr: func.isRequired,
  suspendHandlr: func.isRequired,
  suspended: bool.isRequired,
  isActive: bool.isRequired,
  agentType: string.isRequired,
};

export default AdminUserBtn;
