import {
  errorHandler,
  closeModal,
  getSMSHistory,
  sendingMessage,
  fetchingMessages,
  conversationLoaded,
  moreSmsToLoad,
  isEmailSent,
  sendEmailError,
  emailTemplates,
  parsedTemplate,
  interactableEmails,
  clearEmailError,
  setConversation,
} from "../TransactionDetailsPage/SideLeft/Interactions/actions/creators";

export const defaultState = {
  error: [],
  closeModal: false,
  smsHistory: [],
  sendingMessage: false,
  fetchingMessages: true,
  conversationLoaded: false,
  moreSmsToLoad: true,
  isEmailSent: false,
  sendEmailError: null,
  emailTemplates: [],
  parsedTemplate: null,
  interactableEmails: [],
};

export const tdpInteractionsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case errorHandler().type:
      return {
        ...state,
        error: payload.error,
      };
    case closeModal().type:
      return {
        ...state,
        closeModal: payload.bool,
      };
    case getSMSHistory().type:
      return {
        ...state,
        smsHistory: payload.smsHistory,
      };
    case sendingMessage().type:
      return {
        ...state,
        sendingMessage: payload.bool,
      };
    case fetchingMessages().type:
      return {
        ...state,
        fetchingMessages: payload.bool,
      };
    case conversationLoaded().type:
      return {
        ...state,
        conversationLoaded: payload.bool,
      };
    case moreSmsToLoad().type:
      return {
        ...state,
        moreSmsToLoad: payload.bool,
      };
    case isEmailSent().type:
      return {
        ...state,
        isEmailSent: payload.bool,
      };
    case sendEmailError().type:
      return {
        ...state,
        sendEmailError: payload.error,
      };
    case emailTemplates().type:
      return {
        ...state,
        emailTemplates: payload.templates,
      };
    case parsedTemplate().type:
      return {
        ...state,
        parsedTemplate: payload.template,
      };
    case interactableEmails().type:
      return {
        ...state,
        interactableEmails: payload.term,
      };
    case clearEmailError().type:
      return {
        ...state,
        sendEmailError: null,
      };
    case setConversation().type:
      return {
        ...state,
        conversation: payload.conversation,
      };
    default:
      return state;
  }
};
