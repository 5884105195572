import React from "react";
import PropTypes from "prop-types";

import PanelContextProvider, { PanelContext } from "./PanelContext";
import PanelHeading from "./PanelHeading";
import PanelTitle from "./PanelTitle";
import PanelTrigger from "./PanelTrigger";
import PanelCollapse from "./PanelCollapse";
import PanelBody from "./PanelBody";
import CLASSES from "./classes.json";

const { PANEL_CONTAINER_CLASSES, PANEL_CONTAINER_MARGIN_CLASSES, PANEL_BORDER_CLASSES } = CLASSES;

export const Panel = ({
  className,
  variant,
  collapsed,
  children,
  deprecatedOverrideMarginClasses,
  ...props
}) => {
  const classes = `
    ${PANEL_CONTAINER_CLASSES}
    ${deprecatedOverrideMarginClasses || PANEL_CONTAINER_MARGIN_CLASSES}
    ${PANEL_BORDER_CLASSES[variant] || PANEL_BORDER_CLASSES.fallback}
    ${className}
  `;

  return (
    <PanelContextProvider variant={variant} collapsed={collapsed}>
      <PanelContext.Consumer>
        {() => (
          <div className={classes} {...props}>
            {children}
          </div>
        )}
      </PanelContext.Consumer>
    </PanelContextProvider>
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["default", "primary", "success", "info", "warning", "danger"]),
  collapsed: PropTypes.bool,
  deprecatedOverrideMarginClasses: PropTypes.string,
  children: PropTypes.node,
};

Panel.defaultProps = {
  className: "",
  variant: null,
  collapsed: true,
  deprecatedOverrideMarginClasses: "",
  children: null,
};

Panel.Heading = PanelHeading;
Panel.Title = PanelTitle;
Panel.Trigger = PanelTrigger;
Panel.Collapse = PanelCollapse;
Panel.Body = PanelBody;

export default Panel;
