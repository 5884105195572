import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "../../shared/UtilsV1/FieldLabelV1";
import TagV1 from "../../shared/UtilsV1/TagV1";
import Button from "../../shared/v2/Button";
import { Plus } from "../../shared/v2/Icomoon";
import FieldSelect from "../../shared/UtilsV1/FieldSelect";

function TagsSection({ tags, tagOptions, setPersonValue }) {
  const ref = useRef();
  const [isAddingTags, setIsAddingTags] = useState(false);

  const currentTagOptions = tagOptions.filter((option) => !tags.find(option.value));

  useEffect(() => {
    if (isAddingTags) {
      document.getElementById("tag_input_search").focus();
    }
    const checkIfClickedOutside = (e) => {
      if (isAddingTags && ref.current && !ref.current.contains(e.target)) {
        setIsAddingTags(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isAddingTags]);

  return (
    <div className="tw-flex-1">
      <FieldLabelV1 label="Tags" />
      <div className="tw-flex tw-gap-[6px] tw-flex-wrap tw-relative">
        <Button
          schema="secondary"
          onClick={() => setIsAddingTags(true)}
          className="tw-flex tw-items-center"
          data-cy="add_tag_button"
        >
          <Plus size="s" className="tw-mr-[6px]" />
          ADD
        </Button>

        {isAddingTags && (
          <div className="tw-absolute" ref={ref}>
            <FieldSelect
              options={currentTagOptions}
              onChange={(value) => {
                setPersonValue({ key: "tagNames", value: [...tags, value] });
                setIsAddingTags(false);
              }}
              className="tw-w-[240px]"
              data-cy="tag_section_select"
              isInputSearchable
              id="tag_input_search"
              dropdownWidth="!tw-left-0"
              isAddNotFound
            />
          </div>
        )}
        {tags?.map((tag) => (
          <TagV1
            key={tag}
            text={tag}
            onRemove={() =>
              setPersonValue({
                key: "tagNames",
                value: tags.filter((tagName) => tagName !== tag),
              })
            }
          />
        ))}
      </div>
    </div>
  );
}
TagsSection.propTypes = {
  setPersonValue: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

TagsSection.defaultProps = {
  tags: [],
  tagOptions: [],
};

export default TagsSection;
