import React from "react";
import Proptypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import StagesDropDown from "../../shared/StagesDropDown/StagesDropDown";

const Stage = ({ person }) => {
  const onUpdateStage = (stage) => {
    const param = {
      stage: stage.value,
      stage_type: stage.type,
    };
    window.App.leadUpdateChannel.perform("lead_update", {
      id: person.person_lead_id,
      person_attributes: { ...param },
    });
  };
  return (
    <>
      <div className="tw-relative status-dd">
        {person.person.type_name === "lead" ? (
          <StagesDropDown
            containerClassName="tw-justify-center"
            optionClassName="tw-h-[240px] tw-overflow-auto"
            selectedStage={person.person.stage}
            onUpdateStage={onUpdateStage}
          />
        ) : (
          <div
            className="tw-flex tw-justify-center tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center
             tw-text-center tw-align-middle tw-w-[180px] tw-h-[24px] tw-text-neutral-gray-30 tw-text-12px tw-leading-[20px]
             tw-font-normal"
          >
            <Tooltip
              placement="bottom"
              content="Stages are unavailable for this person type"
              trigger="No Stage"
            />
          </div>
        )}
      </div>
    </>
  );
};
Stage.propTypes = {
  person: Proptypes.shape({
    person_lead_id: Proptypes.number,
    person: Proptypes.shape({ stage: Proptypes.string, type_name: Proptypes.string }),
  }),
};
Stage.defaultProps = {
  person: null,
};

export default Stage;
