const { useSelector } = require("react-redux");

export const useContactMergePotentialDuplicates = () =>
  useSelector((state) => state.contactMerge.potentialDuplicates);
export const useContactMergeLoading = () => useSelector((state) => state.contactMerge.loading);
export const useContactMergeModalIsOpen = () => useSelector((state) => state.contactMerge.modalIsOpen);
export const useContactMergePrimaryContact = () => useSelector((state) => state.contactMerge.primaryContact);
export const useContactMergeOtherContact = () => useSelector((state) => state.contactMerge.otherContact);
export const useContactMergeKeepContact = () => useSelector((state) => state.contactMerge.keepContact);
export const useContactMergeError = () => useSelector((state) => state.contactMerge.error);
export const useContactMergeHasDuplicates = () => useSelector((state) => state.contactMerge.hasDuplicates);
export const useContactMergePrimaryContactSlug = () =>
  useSelector((state) => state.contactMerge.primaryContactSlug);
export const useContactMergeOtherContactSlug = () =>
  useSelector((state) => state.contactMerge.otherContactSlug);
export const useContactMergePage = () => useSelector((state) => state.contactMerge.page);
