import React from "react";
import PropTypes from "prop-types";

const Card = ({ Icon, title, children }) => (
  <div className="tw-bg-white tw-p-[24px] tw-mb-[24px] tw-rounded-lg tw-border tw-border-solid tw-border-neutral-gray-10">
    <div className="tw-flex tw-items-center tw-mb-[16px]">
      <Icon size="l" className="tw-text-[18px] tw-h-[18px] tw-text-neutral-75 tw-mr-[8px]" />
      <h3 className="tw-text-[18px] tw-font-bold tw-text-neutral-75 tw-my-0">{title}</h3>
    </div>
    {children}
  </div>
);

Card.propTypes = {
  Icon: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default Card;
