/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneRegularV6 = (props) => (
  <Icon {...props} name="Phone Regular V6">
    <path d="M15.009 10.009 11.94 8.693a1.632 1.632 0 0 0-1.912.467l-.858 1.047A9.328 9.328 0 0 1 5.795 6.86l1.048-.859c.56-.457.753-1.241.469-1.908L5.989.984A1.64 1.64 0 0 0 4.118.041L1.266.699A1.626 1.626 0 0 0-.002 2.292c0 7.559 6.147 13.706 13.706 13.706.767 0 1.423-.522 1.566-1.267l.658-2.854a1.584 1.584 0 0 0-.921-1.869zm-.512 1.532-.659 2.857c-.015.034-.066.103-.132.103C6.975 14.501 1.5 9.026 1.5 2.295a.13.13 0 0 1 .105-.131l2.854-.659a.139.139 0 0 1 .156.079l1.317 3.073a.136.136 0 0 1-.039.158L4.369 6.037a.75.75 0 0 0-.198.911 10.69 10.69 0 0 0 4.859 4.859c.284.153.69.071.911-.198l1.25-1.527a.128.128 0 0 1 .154-.035l3.071 1.315c.059.053.094.119.081.182z" />
  </Icon>
);

export default PhoneRegularV6;
