import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import { ArrowLeft } from "@icons/index";
import AutoHideMessage from "./components/AutoHideMessage";
import { useSetPAgent, useSetPerson, useLoadReport } from "./hookHelpers";
import Panel1 from "./components/Panel1";
import Panel2 from "./components/Panel2";
import * as creators from "./actions/creators";
import * as api from "./actions/api";
import "./styles.scss";

const mapStateToProps = (state) => ({
  tab: state.addMarketReport.tab,
  isFormInValid: Object.keys(state.addMarketReport.formErrors).some(
    (k) => state.addMarketReport.formErrors[k] === true,
  ),
  missingEmail: state.addMarketReport.person?.emails?.length <= 0,
  isLoading: state.addMarketReport.isLoading,
  isSaving: state.addMarketReport.isSaving,
  isSending: state.addMarketReport.isSending,
  formData: state.addMarketReport.form1,
  form2: state.addMarketReport.form2,
  savedMarketReport: state.addMarketReport.savedMarketReport,
});

const MarketReportModal = ({
  isOpen,
  isSaving,
  isSending,
  close,
  tab,
  setTab,
  isFormInValid,
  missingEmail,
  isLoading,
  formData,
  setPerson,
  savedMarketReport,
  sendMarketReportFn,
  saveMarketReportFn,
  updateMarketReportFn,
  updateMarketReportDetailsFn,
  getMarketReportFn,
  form2,
  resetForms,
  setFormData,
  primaryAgent,
  person,
  id,
}) => {
  useSetPAgent({ setFormData, primaryAgent });
  useSetPerson({ setPerson, person });
  useLoadReport({ id, getMarketReportFn, primaryAgent, tab });
  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[1224px] tw-max-h-[90vh] tw-w-full tw-h-[800px] tw-flex tw-flex-col tw-gap-[32px]"
      show={isOpen}
      onHide={() => {
        resetForms();
        close();
      }}
    >
      <Modal.Header
        title={missingEmail ? "Add an Email Address" : `${id ? "Edit" : "Create"} a Market Report`}
        closeButton
      />

      <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden">
        {isLoading && !missingEmail && (
          <FontAwesomeIcon icon={faSpinner} className="tw-h-[16px] tw-w-[16px] tw-mx-auto fa-spin" />
        )}
        {!isLoading && !missingEmail && (
          <>
            <div className="tw-text-sm tw-flex tw-items-center">
              <button
                type="button"
                className={`tw-bg-transparent tw-border-0 ${tab === 0 ? "tw-text-gray-75" : "tw-text-gray-50"}`}
                onClick={() => setTab(0)}
              >
                Search Criteria
              </button>
              <i className="fa fa-chevron-right" />
              <button
                type="button"
                className={`tw-bg-transparent tw-border-0 ${tab === 1 ? "tw-text-gray-75" : "tw-text-gray-50"}`}
                onClick={() =>
                  savedMarketReport !== null
                    ? updateMarketReportFn(
                        {
                          ...formData,
                          id: savedMarketReport.id,
                        },
                        1,
                      )
                    : saveMarketReportFn(formData, 1)
                }
                disabled={isFormInValid || formData.searchLocation === null}
              >
                Preview and Send
              </button>
            </div>
            {tab === 0 ? <Panel1 /> : <Panel2 />}
          </>
        )}
        <AutoHideMessage />
      </Modal.Body>

      <Modal.Footer className={`tw-flex tw-justify-${tab === 0 ? "end" : "between"}`}>
        {tab === 0 ? (
          <Button
            data-cy="mr-next-btn"
            size="medium"
            schema="primary"
            onClick={() =>
              savedMarketReport !== null
                ? updateMarketReportFn(
                    {
                      ...formData,
                      id: savedMarketReport.id,
                    },
                    1,
                  )
                : saveMarketReportFn(formData, 1)
            }
            className="disabled:tw-cursor-not-allowed"
            disabled={
              isFormInValid ||
              (!formData.searchLocation && !formData.geo_json && !formData.boundry_coordinates)
            }
            isLoading={isSaving}
          >
            Next
          </Button>
        ) : (
          <>
            <Button size="medium" schema="tertiary" onClick={() => setTab(0)}>
              <ArrowLeft className="tw-mr-[4px] tw-pt-[2px]" />
              <span>Back</span>
            </Button>
            <div className="tw-flex tw-gap-[8px]">
              <TextButton
                size="medium"
                onClick={async () => {
                  await updateMarketReportDetailsFn({
                    id: savedMarketReport.id,
                    name: form2.name || savedMarketReport.location,
                    primary_agent_uuid: formData.primary_agent_uuid,
                    home_value_adjustment_as_percentage: savedMarketReport.has_address
                      ? formData.home_value_adjustment_as_percentage
                      : null,
                    home_value_adjustment: savedMarketReport.has_address
                      ? formData.home_value_adjustment * (formData.isHomeAdjPositive ? 1 : -1)
                      : null,
                    report_type: savedMarketReport.has_address ? form2.report_type : "market",
                    recurring_frequency: form2.recurring_frequency,
                  });
                  resetForms();
                  close();
                }}
                isLoading={isSaving}
              >
                Save & Close
              </TextButton>
              <Button
                size="medium"
                schema="primary"
                onClick={async () => {
                  await updateMarketReportDetailsFn({
                    id: savedMarketReport.id,
                    name: form2.name || savedMarketReport.location,
                    primary_agent_uuid: formData.primary_agent_uuid,
                    home_value_adjustment_as_percentage: savedMarketReport.has_address
                      ? formData.home_value_adjustment_as_percentage
                      : null,
                    home_value_adjustment: savedMarketReport.has_address
                      ? formData.home_value_adjustment * (formData.isHomeAdjPositive ? 1 : -1)
                      : null,
                    report_type: savedMarketReport.has_address ? form2.report_type : "market",
                  });
                  await sendMarketReportFn(
                    {
                      id: savedMarketReport.id,
                      primary_agent_uuid: formData.primary_agent_uuid,
                    },
                    {
                      custom_message: form2.email_message,
                      recurring_frequency: form2.recurring_frequency,
                    },
                    close,
                  );
                  resetForms();
                }}
                isLoading={isSending}
              >
                Send Now
              </Button>
            </div>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

MarketReportModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  isSaving: PropTypes.bool,
  isSending: PropTypes.bool,
  tab: PropTypes.number,
  setTab: PropTypes.func,
  isFormInValid: PropTypes.bool,
  missingEmail: PropTypes.bool,
  isLoading: PropTypes.bool,
  resetForms: PropTypes.func,
  setPerson: PropTypes.func,
  sendMarketReportFn: PropTypes.func,
  saveMarketReportFn: PropTypes.func,
  updateMarketReportFn: PropTypes.func,
  updateMarketReportDetailsFn: PropTypes.func,
  getMarketReportFn: PropTypes.func,
  savedMarketReport: PropTypes.shape({
    has_address: PropTypes.bool,
    id: PropTypes.string,
    primary_agent_uuid: PropTypes.string,
    location: PropTypes.string,
  }),
  formData: PropTypes.shape({
    street_address: PropTypes.string,
    searchLocation: PropTypes.shape({
      value: PropTypes.string,
    }),
    geo_json: PropTypes.shape({}),
    boundry_coordinates: PropTypes.shape({}),
    primary_agent_uuid: PropTypes.string,
    home_value_adjustment: PropTypes.number,
    home_value_adjustment_as_percentage: PropTypes.bool,
    isHomeAdjPositive: PropTypes.bool,
  }),
  form2: PropTypes.shape({
    email_message: PropTypes.string,
    name: PropTypes.string,
    recurring_frequency: PropTypes.string,
    report_type: PropTypes.string,
  }),
  setFormData: PropTypes.func,
  primaryAgent: PropTypes.number,

  person: PropTypes.shape({
    id: PropTypes.string,
  }),
  id: PropTypes.string,
};

MarketReportModal.defaultProps = {
  isOpen: false,
  close: () => {},
  isSaving: false,
  isSending: false,
  tab: 0,
  setTab: () => {},
  isFormInValid: false,
  isLoading: false,
  sendMarketReportFn: null,
  saveMarketReportFn: null,
  savedMarketReport: null,
  updateMarketReportFn: null,
  updateMarketReportDetailsFn: null,
  getMarketReportFn: null,
  missingEmail: false,
  formData: null,
  form2: null,
  resetForms: () => {},
  setPerson: null,
  setFormData: null,
  primaryAgent: null,
  person: null,
  id: null,
};

export default connect(mapStateToProps, { ...creators, ...api })(MarketReportModal);
