import React from "react";
import { Trash } from "@shared/v2/Icomoon";
import TextInput from "@shared/v2/TextInput";
import AiAssistant from "@shared/AiAssistant";
import { bool, func, string } from "prop-types";

const TaskInputToggle = ({ label, value, show, setValue, onClose, textareaId  }) => show && (
  <div>
    <div className="tw-flex tw-justify-between tw-items-end tw-mr-[40px] tw-mb-[8px]">
      <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-capitalize">{label}</div>
      <AiAssistant
        textareaId={textareaId}
        messageType="Generic"
        onInsertClick={(text) => setValue(text)}
      />      
    </div>
    <div className="tw-flex tw-items-center">
      <div className="tw-w-full">
        <TextInput
          className="tw-grow"
          value={value}
          textareaId={textareaId}
          onChange={(e) => setValue(e.target.value)}
          multiline
        />
      </div>
      <button
        aria-label="delete"
        type="button"
        onClick={onClose}
        className="tw-w-36px tw-h-36px tw-border-1px tw-rounded-[2px] tw-border-solid tw-border-neutral-gray-15 tw-bg-[#F5F7F7] tw-flex tw-items-center tw-justify-center tw-ml-[4px] tw-cursor-pointer"
      >
        <Trash
          onClick={() => setValue('')}
          size="l" />
      </button>
    </div>
  </div>
)

export default TaskInputToggle;

TaskInputToggle.propTypes = {
  label: string.isRequired, 
  textareaId: string.isRequired,
  value: string,
  onClose: func.isRequired,
  setValue: func.isRequired,
  show: bool
};

TaskInputToggle.defaultProps = {
  value: '',
  show: false
};