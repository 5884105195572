import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Tooltip from "@shared/v2/Tooltip";
import ContactMergeModal from "./ContactMergeModal";
import { setSlugsAndOpen } from "../reducers/contactMergeReducer";

const LeadIndexButton = () => {
  const dispatch = useDispatch();
  const [checkedPeople, setCheckedPeople] = useState([]);
  const disabled =
    checkedPeople.length > 2 ||
    checkedPeople.some((el) => el.dataset.login_user_id || el.dataset.type_name !== "lead");

  useEffect(() => {
    const onChange = (e) => {
      if (
        e.target.classList.contains("multi-select") ||
        e.target.id === "select-all" ||
        e.target.id === "select-page"
      ) {
        setTimeout(() => {
          const people = Array.from(document.querySelectorAll(".multi-select"))
            .filter((el) => el.checked)
            .map((el) => el.closest("tr"));
          setCheckedPeople(people);
        }, 0);
      }
    };
    document.body.addEventListener("change", onChange);
    document.body.addEventListener("click", onChange);
    return () => {
      document.body.removeEventListener("change", onChange);
      document.body.removeEventListener("click", onChange);
    };
  }, []);

  const button = (
    <button
      type="button"
      disabled={disabled}
      className="tw-w-full tw-bg-white hover:tw-bg-gray-5 tw-text-gray-50 tw-text-left tw-border-0 tw-px-[10px] tw-py-[8px] disabled:tw-bg-gray-10 disabled:tw-text-gray-30"
      onClick={() => {
        const [primaryContactSlug, otherContactSlug = null] = checkedPeople.map((el) => el.dataset.slug);
        dispatch(setSlugsAndOpen({ primaryContactSlug, otherContactSlug }));
      }}
    >
      Merge Leads
    </button>
  );

  return (
    <>
      <li className="divider" />
      {disabled && checkedPeople.length < 3 ? (
        <Tooltip trigger={button} content="The selected contacts are not mergeable" placement="left">
          {button}
        </Tooltip>
      ) : (
        button
      )}
      <ContactMergeModal />
    </>
  );
};

export default LeadIndexButton;
