import React from "react";

import {
  BathRegularV6,
  BedEmptyRegularV6,
  BuildingsRegularV6,
  CalendarClockSolidV6,
  Grid2SolidV6,
  MapSolidV6,
  TreeDeciduousSolidV6,
} from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import ZillowLogo from "../ZillowLogo";
import { valueOrDash } from "../helpers";

const PropertyInfoBlock = ({ property }) => {
  return (
    <div className="tw-flex tw-flex-col">
      <span className="tw-text-gray-50 tw-font-bold tw-text-11d">
        {property.status}: {property.statusDateDisplay}
      </span>
      <div className="tw-flex tw-flex-row tw-gap-6px">
        <span className="tw-text-18px tw-text-gray-75">
          {property.streetAddress}, {property.city} {property.state} {property.postalCode}{" "}
        </span>
        {property.streetViewLink ? (
          <IconButton
            onClick={() => window.open(property.streetViewLink, "_blank")}
            schema="tertiary"
            size="small"
          >
            <MapSolidV6 size="m" />
          </IconButton>
        ) : null}
        {property.zillowLink ? (
          <IconButton
            onClick={() => window.open(property.zillowLink, "_blank")}
            schema="tertiary"
            size="small"
          >
            <ZillowLogo />
          </IconButton>
        ) : null}
      </div>
      <hr className="tw-w-full !tw-my-12px" />
      <div className="tw-flex tw-flex-row tw-gap-48px">
        <PropertySpecifics name="Price" value={property.listPriceFormatted} />
        <PropertySpecifics name="DOM" value={property.daysOnMarket} />
        {null && <PropertySpecifics name="Owner" value={property.owner} />}
      </div>
      <hr className="tw-w-full !tw-my-12px" />
      <div className="tw-flex tw-flex-row tw-text-gray-75 tw-text-12px tw-whitespace-nowrap">
        <BedEmptyRegularV6 size="l" />
        <TinyLittleInfoThing value={property.beds} fullTextBlock={`${property.beds} bed`} />
        <VerticalLine />
        <BathRegularV6 size="l" />
        <TinyLittleInfoThing value={property.baths} fullTextBlock={`${property.baths} bath`} />
        <VerticalLine />
        <Grid2SolidV6 size="l" />
        <TinyLittleInfoThing
          value={property.sqftFormatted}
          fullTextBlock={`${property.sqftFormatted} Sq Ft`}
        />
        <VerticalLine />
        <TreeDeciduousSolidV6 size="l" />
        <TinyLittleInfoThing value={property.lotSize} fullTextBlock={`${property.lotSize} acres`} />
        <VerticalLine />
        <CalendarClockSolidV6 size="l" />
        <TinyLittleInfoThing value={property.yearBuilt} fullTextBlock={`Built ${property.yearBuilt}`} />
        <VerticalLine />
        <BuildingsRegularV6 size="l" />
        <TinyLittleInfoThing value={property.propertyType} fullTextBlock={property.propertyType} />
      </div>
    </div>
  );
};

export default PropertyInfoBlock;

const PropertySpecifics = ({ name, value }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4px">
      <span className="tw-text-12px tw-font-semibold tw-text-gray-50">{name}</span>
      <span className="tw-text-14px tw-font-normal tw-text-gray-75">{valueOrDash(value)}</span>
    </div>
  );
};

const TinyLittleInfoThing = ({ value, fullTextBlock }) => {
  return <span className="tw-ml-8px">{value ? fullTextBlock : "--"}</span>;
};

const VerticalLine = () => (
  <div className="tw-border-0 tw-border-l tw-border-solid tw-border-gray-10 tw-h-16px tw-mx-12px" />
);
