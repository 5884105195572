import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

export const getActivities = (...config) =>
  caseTransformingAxios.get("/api/v4/activity_feed", ...config).then((res) => res.data.data);

export const markAsRead = (activity) =>
  caseTransformingAxios.post(`/api/v4/activity_feed/${activity.id}/mark_as_read`);

export const markAllAsRead = () => caseTransformingAxios.post("/api/v4/activity_feed/mark_all_as_read");

export const searchActivities = (...config) =>
  caseTransformingAxios.get("/api/v4/activity_feed/search", ...config).then((res) => res.data.data);

export const getUnreadCount = () =>
  caseTransformingAxios
    .get("/api/v4/activity_feed/unread_count")
    .then((res) => res.data.data)
    .catch(() => 0);
