/* eslint-disable camelcase */
import moment from "moment/moment";
import { tryCatchHandlr } from "../../../../shared/Utilities";
import {
  getAllTasksFromApi,
  getTeamFromApi,
  createTaskFromApi,
  editTaskFromApi,
  deleteTasksFromApi,
  bulkEditTasksFromApi,
} from "../api/apiWrappers";
import { getAllDetailsFromApi } from "../../Details/api/apiWrappers";
import { sortTasks, dispatchEditedTasks } from "./helpers";
import {
  adaptGetAllTasks,
  adaptFilterPayload,
  adaptTeam,
  adaptTitle,
  adaptPostPutTask,
  adaptEditedCreatedTask,
  adaptPutBulkEditTask,
} from "../api/apiAdapters";
import {
  saveAllTasksAndFilters,
  setBackFilterByAutoPlans,
  setBackFilterByTeamMembers,
  saveTeam,
  addCreatedTasks,
  deleteTask,
  togglePrimaryFilter,
  clearSelectedTasks,
} from "./creators";

import saveTitleCrossDetail from "./crossCreators";

import FILTER_CALLBACKS from "./constants";

export const getAllTasksAsThunk = (uuid) => async (dispatch) => {
  const req = getAllTasksFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }
  const adaptedTasks = sortTasks(adaptGetAllTasks(res?.data?.tasks));
  const adaptedFilters = adaptFilterPayload(adaptedTasks);
  dispatch(
    saveAllTasksAndFilters({
      adaptedTasks,
      adaptedFilters,
    }),
  );

  dispatch(togglePrimaryFilter("overdue"));
  dispatch(togglePrimaryFilter("due_today"));
};

export const asyncSetTasksByAutoPlansFilter = () => (dispatch) => {
  setTimeout(() => {
    dispatch(setBackFilterByAutoPlans());
  }, 1000);
};

export const asyncSetTasksByTeamMemberFilter = () => (dispatch) => {
  setTimeout(() => {
    dispatch(setBackFilterByTeamMembers());
  }, 1000);
};

export const getTeamAsThunk = () => async (dispatch) => {
  const req = getTeamFromApi();
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  const team = adaptTeam(res.data);
  dispatch(saveTeam(team));
};

export const getTitleAsThunk = (uuid) => async (dispatch) => {
  const req = getAllDetailsFromApi(uuid);

  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  const title = adaptTitle(res.data);

  dispatch(saveTitleCrossDetail(title));
};

export const getAllTaskAfterRecurringPostAsThunk = (uuid, filtered) => async (dispatch) => {
  const req = getAllTasksFromApi(uuid);
  const [
    {
      data: { tasks },
    },
    err,
  ] = await tryCatchHandlr(req);

  if (err) {
    return;
  }
  const adaptedTasks = sortTasks(adaptGetAllTasks(tasks));
  const adaptedFilters = adaptFilterPayload(adaptedTasks);

  dispatch(
    saveAllTasksAndFilters({
      adaptedTasks,
      adaptedFilters,
    }),
  );

  if (filtered) {
    dispatch(FILTER_CALLBACKS[filtered]());
  }
};

export const postTaskAsThunk = (payload, filtered) => async (dispatch) => {
  const adaptedPayload = adaptPostPutTask(payload);

  const req = createTaskFromApi(adaptedPayload);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }
  const { due_on, recurring } = adaptedPayload;
  if (due_on === moment().format("YYYY-MM-DD") && recurring) {
    dispatch(getAllTaskAfterRecurringPostAsThunk(payload.uuid, filtered));
  }

  const adaptedCreatedTask = [adaptEditedCreatedTask(res.data)];

  dispatch(addCreatedTasks(adaptedCreatedTask));

  if (filtered) {
    dispatch(FILTER_CALLBACKS[filtered]());
  }
};

export const deleteTasksAsThunk = (id, listingUuid, filtered) => async (dispatch) => {
  const req = deleteTasksFromApi(id.toString(), listingUuid);
  const [{ data }, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  dispatch(deleteTask([data.id]));

  if (filtered) {
    dispatch(FILTER_CALLBACKS[filtered]());
  }
};

export const editTaskAsThunk = (payload, uuid, filtered) => async (dispatch) => {
  const adaptedPayload = adaptPostPutTask(payload);
  const req = editTaskFromApi(
    {
      ...adaptedPayload,
      id: payload.id,
    },
    uuid,
  );
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  const adaptedEditedTask = [adaptEditedCreatedTask(res.data)];

  dispatchEditedTasks(dispatch, payload)(adaptedEditedTask, filtered);
};

export const bulkEditTasksAsThunk = (selectedTasks, listingUuid, filtered, payload) => async (dispatch) => {
  const adaptedPayload = adaptPutBulkEditTask(listingUuid, selectedTasks, payload);
  const req = bulkEditTasksFromApi(adaptedPayload);

  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  const adaptedEditedTasks = res.data.tasks.map((task) => adaptEditedCreatedTask(task));

  dispatchEditedTasks(dispatch, payload)(adaptedEditedTasks, filtered);
  dispatch(clearSelectedTasks());
};
