import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../shared/v1";
import AutoPlansForm from "../AutoPlansForm";

const NewAutoPlanModal = ({
  closeNewAutoPlanModal,
  isModalOpen,
  autoPlansRootPath,
  autoPlanForm,
  accountUuid,
  planType,
  submitNewAutoPlan,
  autoPlanFormUpdate,
  modalErrorMessage,
  modalHasError,
  isModalSubmitting,
}) => (
  <Modal show={isModalOpen} onHide={closeNewAutoPlanModal}>
    <Modal.Header closeButton>
      <Modal.Title>New Auto Plan</Modal.Title>
    </Modal.Header>
    <Modal.Body className="auto-plans-form" deprecatedOverridePaddingClasses="tw-px-15px">
      <AutoPlansForm
        autoPlansCreatePath={autoPlansRootPath}
        autoPlanForm={autoPlanForm}
        accountUuid={accountUuid}
        planType={planType}
        onCloseAction={closeNewAutoPlanModal}
        onSubmitAction={submitNewAutoPlan}
        onFormChange={autoPlanFormUpdate}
        errorMessage={modalErrorMessage}
        hasError={modalHasError}
        isSubmitting={isModalSubmitting}
      />
    </Modal.Body>
  </Modal>
);

export default NewAutoPlanModal;

NewAutoPlanModal.propTypes = {
  closeNewAutoPlanModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  autoPlansRootPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanForm: PropTypes.objectOf(PropTypes.any).isRequired,
  accountUuid: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
  submitNewAutoPlan: PropTypes.func.isRequired,
  autoPlanFormUpdate: PropTypes.func.isRequired,
  modalErrorMessage: PropTypes.string.isRequired,
  modalHasError: PropTypes.bool.isRequired,
  isModalSubmitting: PropTypes.bool.isRequired,
};
