import React from "react";
import PropTypes from "prop-types";

import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import { Trash } from "@shared/v2/Icomoon";
import DocumentListItem from "./DocumentListItem";

const headerClassNames = {
  th: "tw-text-12d tw-font-semibold tw-text-neutral-gray-50",
};

const UploadTable = ({
  shouldShowAllCheckbox,
  areAllDocumentsSelected,
  shouldShowBulkActions,
  handleSelectAllDocuments,
  handleBulkDelete,
  handleSelect,
  handleRenameDocument,
  handleDeleteDocuments,
  uploadedDocuments,
  clearDocumentErrors,
  handlePreview,
  context,
}) => (
  <table className="tw-table-fixed" data-cy={`${context}-table`}>
    <thead className="tw-border-t-0 tw-border-r-0 tw-border-b-1px tw-border-l-0 tw-border-solid tw-border-gray-10">
      <tr>
        <th className="tw-w-30px">
          {shouldShowAllCheckbox && (
            <div className="tw-flex tw-items-center">
              <Checkbox
                id="tdp-documents-upload-check-all"
                name="tdp-documents-upload-check-all"
                value="tdp-documents-upload-check-all"
                checked={areAllDocumentsSelected}
                onChange={handleSelectAllDocuments}
                data-cy="documents-upload-table-select-all"
              />
            </div>
          )}
        </th>

        <th className={`${headerClassNames.th} tw-w-[100px] tw-h-40px`}>
          <div className="tw-flex tw-items-center">
            <span>Name</span>

            {shouldShowBulkActions && (
              <div
                className="tw-flex tw-items-center tw-ml-10px"
                data-cy="documents-upload-table-bulk-actions"
              >
                <Button
                  className="tw-flex tw-items-center tw-gap-2px"
                  size="small"
                  schema="warning"
                  onClick={handleBulkDelete}
                  data-cy="documents-upload-table-delete-button"
                >
                  <Trash className="tw-text-neutral-gray-75" size="s" />
                  <span>Delete</span>
                </Button>
              </div>
            )}
          </div>
        </th>

        <th className={`${headerClassNames.th} tw-w-80px tw-text-center tw-invisible`}>Viewers</th>

        <th className={`${headerClassNames.th} tw-w-[220px]`}>{/* Action */}</th>
      </tr>
    </thead>

    <tbody>
      {uploadedDocuments.map((doc, index) => (
        <DocumentListItem
          key={doc.id || doc.uploadKey}
          context={context}
          document={doc}
          index={index}
          isSelected={doc.isSelected}
          onSelect={handleSelect}
          renameDocument={handleRenameDocument}
          deleteDocuments={handleDeleteDocuments}
          clearDocumentErrors={clearDocumentErrors}
          handlePreview={() => handlePreview(index)}
        />
      ))}
    </tbody>
  </table>
);

UploadTable.propTypes = {
  shouldShowAllCheckbox: PropTypes.arrayOf(PropTypes.shape({})),
  areAllDocumentsSelected: PropTypes.arrayOf(PropTypes.shape({})),
  shouldShowBulkActions: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelectAllDocuments: PropTypes.func,
  handleBulkDelete: PropTypes.func,
  handleSelect: PropTypes.func,
  handleRenameDocument: PropTypes.func,
  handleDeleteDocuments: PropTypes.func,
  handlePreview: PropTypes.func,
  clearDocumentErrors: PropTypes.func,
  uploadedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  context: PropTypes.string,
};

UploadTable.defaultProps = {
  shouldShowAllCheckbox: [],
  areAllDocumentsSelected: [],
  shouldShowBulkActions: [],
  handleSelectAllDocuments: () => {},
  handleBulkDelete: () => {},
  handleSelect: () => {},
  handleRenameDocument: () => {},
  handleDeleteDocuments: () => {},
  handlePreview: () => {},
  clearDocumentErrors: () => {},
  uploadedDocuments: [],
  context: "",
};

export default UploadTable;
