import { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useOpenHouseDashboardHosts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    caseTransformingAxios
      .get("/api/v4/reporting/open_house_dashboard/hosts", { signal: abortController.signal })
      .then((res) => setData(res.data))
      .catch(console.log);

    return () => abortController.abort();
  }, []);

  return data;
};

export default useOpenHouseDashboardHosts;
