import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "../../../../../shared/v2/Dropdown";
import { optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { errorShapeForErrorBanner } from "../../../../../shared/v2/ErrorBanner";
import { FloatingForm } from "../../../../../shared/v2/Modal";

import MemberMultiValue from "./MemberMultiValue";
import MemberMultiValueLabel from "./MemberMultiValueLabel";
import MemberMultiValueRemove from "./MemberMultiValueRemove";
import MemberOption from "./MemberOption";

const AddTeamMemberModal = ({ show, isAdding, errors, members, onAdd, onCancel }) => {
  const [placeholder, setPlaceholder] = useState("Select team member");
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleAdd = () => {
    onAdd(selectedMembers);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleChange = (newSelectedMembers) => {
    setSelectedMembers(newSelectedMembers);
  };

  const handleMenuOpen = () => {
    setPlaceholder("Search team member name");
  };

  const handleMenuClose = () => {
    setPlaceholder("Select team member");
  };

  useEffect(() => {
    setSelectedMembers([]);
  }, [show]);

  return (
    <FloatingForm
      show={show}
      errors={errors}
      title="Add a Team Member"
      description="Team members added here are unique to this transaction."
      primaryOption="Add"
      primaryProps={{ disabled: selectedMembers.length === 0 }}
      isPrimaryLoading={isAdding}
      onPrimary={handleAdd}
      onCancel={handleCancel}
      closeOnClickOutside
      closeOnEscape
    >
      <Dropdown.Searchable
        placeholder={placeholder}
        options={members}
        value={selectedMembers}
        isMulti
        menuShouldComeToFront
        maxVisibleOptions={7.5}
        onChange={handleChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        components={{
          MultiValue: MemberMultiValue,
          MultiValueLabel: MemberMultiValueLabel,
          MultiValueRemove: MemberMultiValueRemove,
          Option: MemberOption,
        }}
      />
    </FloatingForm>
  );
};

AddTeamMemberModal.propTypes = {
  show: PropTypes.bool,
  isAdding: PropTypes.bool,
  errors: PropTypes.arrayOf(errorShapeForErrorBanner),
  members: PropTypes.arrayOf(optionShape).isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AddTeamMemberModal.defaultProps = {
  show: true,
  isAdding: false,
  errors: [],
};

export default AddTeamMemberModal;
