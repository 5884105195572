/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SortUpSolidV6 = (props) => (
  <Icon {...props} name="Sort Up Solid V6">
    <path d="M3.864 7h8.272c.769 0 1.153-.931.611-1.472L8.613 1.253a.86.86 0 0 0-1.218 0L3.254 5.529c-.542.541-.158 1.472.611 1.472z" />
  </Icon>
);

export default SortUpSolidV6;
