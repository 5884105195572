import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../shared/v1";
import AssignedPlanDetailPreview from "../../../AssignedPlans/components/AssignedPlanDetailPreview";

const PreviewAutoPlanModal = ({
  closeModal,
  isAutoPlanPreviewModalOpen,
  autoPlanPreview,
  previewAutoPlanAction,
  activeAutoPlanActionId,
}) => (
  <Modal
    size="large"
    show={isAutoPlanPreviewModalOpen}
    onHide={() => closeModal()}
    className="assigned-plan-modal"
  >
    <Modal.Header deprecatedOverridePaddingClasses="tw-pt-15px tw-px-15px" closeButton>
      <Modal.Title className="d-flex">
        {`Auto Plan: ${autoPlanPreview.name}`}
        <span className={`state ${autoPlanPreview.state}`} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body deprecatedOverridePaddingClasses="tw-p-0">
      <AssignedPlanDetailPreview
        autoPlanPreview={autoPlanPreview}
        previewAutoPlanAction={previewAutoPlanAction}
        activeAutoPlanActionId={activeAutoPlanActionId}
      />
    </Modal.Body>
  </Modal>
);

export default PreviewAutoPlanModal;

PreviewAutoPlanModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isAutoPlanPreviewModalOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanPreview: PropTypes.objectOf(PropTypes.any).isRequired,
  previewAutoPlanAction: PropTypes.func.isRequired,
  activeAutoPlanActionId: PropTypes.string.isRequired,
};
