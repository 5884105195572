import React from "react";
import { bool, func, string } from "prop-types";

import withDispatch from "../../../shared/HOCs/withDispatch/withDispatch";
import { isUpdateAddressModal } from "../../Header/actions/creators";
import { putAddressFromPipelineStatus, putStatusWithPipelineAsThunk } from "../../Header/actions/thunks";
import { tdpDetails } from "../../../shared/HOCs/withRedux";
import {
  ISUPDATEADDRESSMODALOPEN,
  LISTING_UUID,
  STREET_ADDRESS,
  TEMP_UPDATED_STATUS,
  TEMP_PRE_STATUS,
} from "../../../shared/reduxRoutes/tdpDetailsRoutes";

import UpdatePipelineStatusModal from "../../../Transactions/UpdatePipelineStatusModal";

const TDPPipelineStatusModal = ({
  show,
  uuid,
  setToggleModal,
  onChangeFromPipelineStatus,
  tempUpdatedStatus,
  tempPreStatus,
  onRevertStatus,
  streetAddress,
}) => {
  const revertStatuscloseModal = () => {
    setToggleModal(false);
    //  PUT req to revert status
    onRevertStatus({
      status: tempPreStatus,
      streetAddress: streetAddress,
      uuid,
    });
  };

  return (
    <UpdatePipelineStatusModal
      isModalOpen={show}
      closeModal={setToggleModal}
      uuid={uuid}
      onSubmit={(details) => {
        details.push({
          key: "status",
          value: tempUpdatedStatus === "comingsoon" ? "coming soon" : tempUpdatedStatus,
        });
        onChangeFromPipelineStatus(details, uuid);
      }}
      revertStatuscloseModal={revertStatuscloseModal}
    />
  );
};

const WithStateTDPPipelineStatusModal = tdpDetails({
  WrappedComponent: TDPPipelineStatusModal,
  states: [ISUPDATEADDRESSMODALOPEN, LISTING_UUID, TEMP_UPDATED_STATUS, TEMP_PRE_STATUS, STREET_ADDRESS],
  aliases: ["show", "uuid", "tempUpdatedStatus", "tempPreStatus", "streetAddress"],
});

export default withDispatch({
  WrappedComponent: WithStateTDPPipelineStatusModal,
  actions: [putAddressFromPipelineStatus, isUpdateAddressModal, putStatusWithPipelineAsThunk],
  aliases: ["onChangeFromPipelineStatus", "setToggleModal", "onRevertStatus"],
});

TDPPipelineStatusModal.propTypes = {
  show: bool,
  setToggleModal: func,
  onRevertStatus: func,
  onChangeFromPipelineStatus: func,
  tempUpdatedStatus: string,
  tempPreStatus: string,
  uuid: string,
  streetAddress: string,
};

TDPPipelineStatusModal.defaultProps = {
  show: false,
  setToggleModal: () => {},
  onRevertStatus: () => {},
  onChangeFromPipelineStatus: () => {},
  tempUpdatedStatus: "active",
  tempPreStatus: "",
  uuid: "",
  streetAddress: null,
};
