import React from "react";
import PropTypes from "prop-types";
import colors from "./helpers/colors";
import { Info } from "../../../shared/v2/Icomoon";
import Tooltip from "../../../shared/v2/Tooltip/Tooltip";

export const DashTooltipRow = ({ title, value }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
    <span>{title}</span>
    <span>{value}</span>
  </div>
);

DashTooltipRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};
DashTooltipRow.defaultProps = {
  title: "",
  value: "",
};

const DashTooltip = ({ text, placement }) => {
  if (!text) return null;
  return (
    <Tooltip
      placement={placement}
      innerClassName="tw-max-w-[300px] !tw-text-left"
      trigger={
        <Info
          className={`tw-ml-[4px] ${colors.theme === "brivity" ? "tw-text-gray-75" : "tw-text-[#121212]"}`}
        />
      }
      content={text}
    />
  );
};

DashTooltip.propTypes = {
  placement: PropTypes.string,
  text: PropTypes.string,
};

DashTooltip.defaultProps = {
  placement: "right",
  text: "",
};

export default DashTooltip;
