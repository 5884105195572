import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { headline2 } from "@shared/styles";
import AddEmailSection from "../ModalSections/AddEmailSection";
import AddPhoneSection from "../ModalSections/AddPhoneSection";
import AddAddressSection from "../ModalSections/AddAddressSection";
import FieldTextV1 from "../../shared/UtilsV1/FieldTextV1";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";

function ContactInfoView({ modalType }) {
  const dispatch = useDispatch();
  const formOptions = useContactFormOptions();
  const person = useContactPerson();

  useEffect(() => {
    if (person.id) return;
    if (person.firstName) {
      dispatch(setPersonValue({ key: "letterSalutation", value: `Dear ${person.firstName}` }));
    }
    if (person.firstName && person.lastName) {
      dispatch(
        setPersonValue({ key: "envelopeSalutation", value: `${person.firstName} ${person.lastName}` }),
      );
    }
  }, [person.id, person.firstName, person.lastName]);

  return (
    <div className={containerView}>
      <div className={` tw-text-neutral-gray-75 ${headline2}`}>Contact Info</div>
      {modalType === "user" && (
        <div className="tw-text-gray-50">
          Only your primary email, work phone and mobile phone can be displayed on your Brivity website.
        </div>
      )}
      <AddEmailSection
        emailDetails={person.emailDetailsAttributes}
        isDisplayByDefault={!person?.id}
        required={modalType === "user"}
        setPersonValue={(...args) => dispatch(setPersonValue(...args))}
      />
      <AddPhoneSection
        phoneList={person.phoneDetailsAttributes}
        isDisplayByDefault={modalType === "lead" && !person?.id}
        setPersonValue={(...args) => dispatch(setPersonValue(...args))}
      />
      <AddAddressSection
        modalType={modalType}
        isDisplayByDefault={modalType === "lead" && !person?.id}
        addressList={person.addressesAttributes}
        setPersonValue={(...args) => dispatch(setPersonValue(...args))}
        stateOptions={formOptions?.stateOptions}
      />

      {person.id && (
        <div className="tw-flex tw-gap-[24px]">
          <FieldTextV1
            label="Letter Salutation"
            value={person.letterSalutation}
            onChange={(value) => dispatch(setPersonValue({ key: "letterSalutation", value }))}
          />
          <FieldTextV1
            label="Envelope Salutation"
            value={person.envelopeSalutation}
            onChange={(value) => dispatch(setPersonValue({ key: "envelopeSalutation", value }))}
          />
        </div>
      )}
    </div>
  );
}

ContactInfoView.propTypes = { modalType: PropTypes.string.isRequired };

export default ContactInfoView;
