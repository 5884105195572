import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";

import Loader from "../../../../../Goals/components/Loader";

import { PlayOrPauseButton, TrashIcon } from "../AssignedPlanIconButtons";
import AssignedPlanStateLabel from "../AssignedPlanStateLabel";
import { shouldShowPlayOrPauseAndDeleteButtons } from "./ActionDetails/helpers";
import PlanDetails from "./PlanDetails";

const AssignedPlanDetailsModal = ({
  plan,
  meta: { error, isLoading },
  isStateUpdateLoading,
  show,
  onClose,
  onPlayOrPauseButtonClicked,
  onTrashButtonClicked,
}) => {
  const modalTitle = isLoading ? "Loading Auto Plan..." : `Auto Plan: ${plan?.name}`;

  // There's no error state design for this modal, so for now we just kick the
  // user back out so he retries.
  if (show && error) {
    onClose();
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-w-[983px] tw-space-y-32px tw-m-auto"
    >
      <Modal.Header
        closeButton
        size="small"
        title={
          <div className="tw-flex tw-gap-[16px]">
            <span className="tw-flex tw-items-start">
              {modalTitle}
              <AssignedPlanStateLabel state={plan?.state} />
            </span>
            {!isLoading && shouldShowPlayOrPauseAndDeleteButtons(plan.state) && (
              <div className="tw-flex tw-gap-[8px]">
                <PlayOrPauseButton
                  isLoading={isLoading || isStateUpdateLoading}
                  isPaused={plan.state === "paused"}
                  onClick={() => {
                    const desiredState = plan.state === "paused" ? "started" : "paused";
                    onPlayOrPauseButtonClicked(plan.id, desiredState);
                  }}
                />
                <TrashIcon
                  isLoading={isLoading || isStateUpdateLoading}
                  onClick={() => onTrashButtonClicked(plan.id)}
                />
              </div>
            )}
          </div>
        }
      />

      <Modal.Body className="tw-h-[60vh] tw-flex tw-border-solid tw-border-0 tw-border-t tw-border-b tw-border-neutral-gray-10">
        {isLoading ? (
          <div className="tw-relative tw-w-full">
            <Loader />
          </div>
        ) : (
          <PlanDetails plan={plan} />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <div className="tw-flex tw-flex-row tw-space-x-12px">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AssignedPlanDetailsModal.propTypes = {
  // Validation of this prop will happen further down the component tree.
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.any,
  meta: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isStateUpdateLoading: PropTypes.bool.isRequired,
  onPlayOrPauseButtonClicked: PropTypes.func.isRequired,
  onTrashButtonClicked: PropTypes.func.isRequired,
};

AssignedPlanDetailsModal.defaultProps = {
  plan: null,
};

const mapStateToProps = (state) => ({
  plan: state.tdpAutoPlansReducer.planDetails.plan,
  meta: {
    error: state.tdpAutoPlansReducer.planDetails.meta.error,
    isLoading: state.tdpAutoPlansReducer.planDetails.meta.isLoading,
  },
});

export default connect(mapStateToProps)(AssignedPlanDetailsModal);
