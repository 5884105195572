import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const cache = {};

const cachedToken = () => {
  const exp = JSON.parse(atob(cache.data?.token?.split(".")[1] || "e30=")).exp || 0;
  return (exp - 30) * 1000 > Date.now() ? cache.data.token : "";
};

const getAiToken = async () => {
  const token = cachedToken();
  if (!token && !cache.promise) {
    cache.promise = caseTransformingAxios
      .post("/api/v4/ai_assist/token")
      .then((res) => {
        cache.promise = null;
        cache.data = res.data;
        return res.data;
      })
      .catch(() => ({ apiUrl: "", token: "" }));
  }
  return (token && cache.data) || cache.promise;
};

const authInterceptor = async (config) => {
  const { token } = await getAiToken();
  return {
    ...config,
    paramsSerializer: { indexes: null },
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  };
};

const getExternalApi = (apiUrl) => {
  if (cache[apiUrl]) return cache[apiUrl];

  cache[apiUrl] = axios.create({ baseURL: apiUrl });
  cache[apiUrl].interceptors.request.use(authInterceptor);
  return cache[apiUrl];
};

export { getAiToken, getExternalApi };
