import React, { Component } from "react";
import moment from "moment";
import Proptypes from "prop-types";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import DatePicker from "@shared/v2/DatePicker";
import Dropdown from "@shared/v2/Dropdown";
import FlatPercentInput from "@shared/v2/FlatPercentInput";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import Tooltip from "@shared/v2/Tooltip";
import CustomTimeFrameDateSelector from "./CustomTimeFrameDateSelector";

const TRANSACTION_STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Archived", value: "archived" },
];

const TIMEFRAME_OPTIONS = [
  { label: "Immediate", value: "immediate" },
  { label: "Within 90 days", value: "within_90_days" },
  { label: "Within a year", value: "within_a_year" },
  { label: "After a year", value: "after_a_year" },
  { label: "Custom", value: "custom" },
  { label: "Unknown", value: "unknown" },
];

const TooltipList = () => (
  <div className="tw-text-left">
    <div>
      <span className="tw-font-semibold">Active: </span>Referral contract has been signed.
    </div>
    <div>
      <span className="tw-font-semibold">Pending: </span>Referral partner’s transaction with the client has
      gone under contract.
    </div>
    <div>
      <span className="tw-font-semibold">Closed: </span>Transaction has closed and your team has received the
      referral income payment.
    </div>
    <div>
      <span className="tw-font-semibold">Expired: </span>Referral contract expired prior to the transaction’s
      completion.
    </div>
    <div>
      <span className="tw-font-semibold">Canceled: </span>Referral contract was rejected or terminated after
      acceptance.
    </div>
  </div>
);

class TenantReferralModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionTitle: "",
      transactionStatus: "active",
      acceptanceDate: "",
      expirationDate: "",
      closeDate: "",
      transactionAmt: "0.00",
      timeframe: { label: "Immediate", value: "immediate" },
      desiredLocations: "",
      isCalendarOpen: false,
      exactDate: moment().format("MM/DD/YYYY"),
      source: null,
    };
  }

  componentDidMount() {
    const { clientName } = this.props;
    this.setState({ transactionTitle: clientName });
  }

  componentDidUpdate(prevProps) {
    const { clientName } = this.props;
    if (prevProps.clientName !== clientName) {
      this.setState({ transactionTitle: clientName });
    }
  }

  backHandlr = () => {
    const { handleBack } = this.props;
    handleBack();
    this.setState({ source: null });
  };

  render() {
    const { showModal, sources, handleSubmit } = this.props;
    const {
      transactionTitle,
      source,
      closeDate,
      transactionStatus,
      acceptanceDate,
      expirationDate,
      transactionAmt,
      timeframe,
      isCalendarOpen,
      desiredLocations,
    } = this.state;
    return (
      <Modal
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        show={showModal}
        onHide={this.backHandlr}
        id="referral-tenant-transaction-modal"
      >
        <Modal.Header title="Tenant Referral Details" closeButton />

        <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
          <div className="tw-min-h-[1px] tw-w-full tw-bg-gray-10" />

          <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
            <div className="tw-col-span-1">
              <TextInput
                isRequired
                label="Transaction Title"
                value={transactionTitle}
                onChange={(e) => this.setState({ transactionTitle: e.target.value })}
              />
            </div>
            <div className="tw-col-span-1">
              <Dropdown
                label="Source"
                onChange={(newSource) => this.setState({ source: newSource })}
                value={source}
                options={sources}
                isSearchable
              />
            </div>
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
            <div className="tw-col-span-1">
              <label
                htmlFor="transactionStatus"
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Transaction Status
                <Tooltip
                  placement="right"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content={<TooltipList />}
                />
              </label>
              <Dropdown
                onChange={(e) => {
                  this.setState({ transactionStatus: e.value });
                }}
                value={TRANSACTION_STATUS_OPTIONS.find((opt) => opt.value === transactionStatus)}
                options={TRANSACTION_STATUS_OPTIONS}
              />
            </div>
            {transactionStatus === "sold" && (
              <div className="tw-col-span-1">
                <label
                  htmlFor="closeDate"
                  className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
                >
                  Close Date
                  <Tooltip
                    placement="top"
                    trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                    content="Date the referral income was received"
                  />
                </label>
                <DatePicker onChange={(e) => this.setState({ closeDate: e })} value={closeDate} />
              </div>
            )}
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
            <div className="tw-col-span-1">
              <label
                htmlFor="acceptanceDate"
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Acceptance Date
                <Tooltip
                  placement="top"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content="Date the referral agreement was signed by both parties"
                />
              </label>
              <DatePicker onChange={(e) => this.setState({ acceptanceDate: e })} value={acceptanceDate} />
            </div>
            <div className="tw-col-span-1">
              <label
                htmlFor="expirationDate"
                className="tw-flex tw-items-center tw-gap-[4px] tw-m-0 tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-mb-3"
              >
                Expiration Date
                <Tooltip
                  placement="top"
                  trigger={<CircleInfoSolidV6 className="tw-text-teal tw-mt-[4px]" size="m" />}
                  content="Date the referral agreement is effective through"
                />
              </label>
              <DatePicker onChange={(e) => this.setState({ expirationDate: e })} value={expirationDate} />
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
            <div className="tw-col-span-1">
              <FlatPercentInput
                label="Monthly Budget"
                value={transactionAmt}
                isPercentage={false}
                onChange={(e) => this.setState({ transactionAmt: e.target.value })}
              />
            </div>
            <div className="tw-col-span-1">
              <Dropdown
                label="Timeframe"
                onChange={(newTimeframe) => {
                  this.setState({
                    isCalendarOpen: newTimeframe.value === "custom",
                    timeframe: newTimeframe,
                  });
                }}
                value={timeframe}
                options={TIMEFRAME_OPTIONS}
              />
              <CustomTimeFrameDateSelector
                show={isCalendarOpen}
                cancelViewingCalendarHandlr={() =>
                  this.setState({
                    timeframe: { label: "Unknown", value: "unknown" },
                    isCalendarOpen: false,
                  })
                }
                submitHandlr={(exactDate) => {
                  this.setState({
                    isCalendarOpen: false,
                    exactDate,
                  });
                }}
              />
              <span className="tw-text-[12px] tw-text-neutral-gray-50">Client`s timeframe for buying</span>
            </div>
          </div>

          <div className="tw-grid tw-grid-rows-1">
            <TextInput
              label="Desired Locations"
              onChange={(e) => this.setState({ desiredLocations: e.target.value })}
              value={desiredLocations}
            />
          </div>

          <div className="tw-min-h-[1px] tw-w-full tw-bg-gray-10" />
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
          <Button
            onClick={this.backHandlr}
            className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
            schema="tertiary"
            size="medium"
          >
            Back
          </Button>
          <Button
            onClick={() => handleSubmit({ ...this.state })}
            className="tw-flex tw-items-center"
            schema="primary"
            size="medium"
          >
            Track Referral
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const optionShape = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
});
TenantReferralModal.propTypes = {
  sources: Proptypes.arrayOf(optionShape),
  handleSubmit: Proptypes.func,
  clientName: Proptypes.string,
  showModal: Proptypes.bool,
  handleBack: Proptypes.func,
};
TenantReferralModal.defaultProps = {
  handleSubmit: null,
  sources: null,
  clientName: "",
  showModal: false,
  handleBack: null,
};

export default TenantReferralModal;
