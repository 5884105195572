import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import AssignedPlanDetailPreview from "./AssignedPlanDetailPreview";

const PlanModalPreview = ({
  closeModal,
  isPlanPreviewModalOpen,
  autoPlanPreview,
  previewAutoPlanAction,
  activeAutoPlanActionId,
}) => (
  <Modal
    show={isPlanPreviewModalOpen}
    onHide={() => closeModal()}
    className="assigned-plan-modal"
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
    contentClassName="tw-w-[983px] tw-space-y-32px tw-m-auto"
  >
    <Modal.Header
      size="small"
      title={
        <>
          Auto Plan: ${autoPlanPreview.name}
          <span className={`state ${autoPlanPreview.state}`} />
        </>
      }
      closeButton
    />
    <Modal.Body>
      <AssignedPlanDetailPreview
        autoPlanPreview={autoPlanPreview}
        previewAutoPlanAction={previewAutoPlanAction}
        activeAutoPlanActionId={activeAutoPlanActionId}
      />
    </Modal.Body>
  </Modal>
);

export default PlanModalPreview;

PlanModalPreview.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isPlanPreviewModalOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanPreview: PropTypes.objectOf(PropTypes.any).isRequired,
  previewAutoPlanAction: PropTypes.func.isRequired,
  activeAutoPlanActionId: PropTypes.string.isRequired,
};
