/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Plus = (props) => (
  <Icon {...props} name="Plus">
    <path d="M14.454 6.387h-4.84v-4.84c0-.594-.482-1.076-1.076-1.076H7.462c-.594 0-1.076.482-1.076 1.076v4.84h-4.84C.952 6.387.47 6.869.47 7.463v1.076c0 .594.482 1.076 1.076 1.076h4.84v4.84c0 .594.482 1.076 1.076 1.076h1.076c.594 0 1.076-.482 1.076-1.076v-4.84h4.84c.594 0 1.076-.482 1.076-1.076V7.463c0-.594-.482-1.076-1.076-1.076z" />
  </Icon>
);

export default Plus;
