import React from 'react';
import { Col, Row, Modal  } from 'react-bootstrap'
import buttons from '../../../PersonDetail/components/button.module.css';

const Delete = (props) => {
  const { closeModal, deleteTasksHandler, submitDisabled } = props

  return (
    <Modal id="delete-task-modal" className="person-details-modal" show={true} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col xs={12} style={{ height: "30px" }}>
              <div style={{ fontSize: "18px" }}>
                Are you sure?
              </div>
              <button type="button" className="close" style={{ top: "-24px" }}>
                <span aria-hidden="true" onClick={closeModal}>×</span>
                <span className="sr-only">Close</span>
              </button>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            Fair warning: unlike archiving a person, deleting a Task is forever. Your client won't be able to see that you deleted it, but they won't see if you did it, either.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
          onClick={closeModal}>
          Cancel
        </button>
        <button
          disabled={submitDisabled}
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnRed}`}
          onClick={deleteTasksHandler}
        >
          Yes. Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Delete;
