import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Button from "@shared/v2/Button";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import Plus from "@shared/v2/Icomoon/Icons/Plus";
import { CREATE_MEDIA_OPTIONS } from "../api/helpers";

const MarketingHeaderOptions = ({ mlsID }) => (
  <Popover>
    <PopoverTrigger>
      <Button className="tw-ml-auto tw-flex tw-gap-4px tw-items-center" schema="primary" size="small">
        <Plus size="s" />
        Create Media
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      {CREATE_MEDIA_OPTIONS.map(
        (option) =>
          (mlsID || option.name !== "Social Media Ad") && (
            <PopoverItem key={option.name} onClick={() => window.open(option.url, "_blank")}>
              {option.name}
            </PopoverItem>
          ),
      )}
    </PopoverContent>
  </Popover>
);

MarketingHeaderOptions.propTypes = {
  mlsID: Proptypes.string,
};

MarketingHeaderOptions.defaultProps = {
  mlsID: null,
};

const mapStateToProps = (state) => ({
  mlsID: state.tdpMarketingReducer.mlsID,
});

export default connect(mapStateToProps)(MarketingHeaderOptions);
