/* eslint-disable import/prefer-default-export */

import { pipe, sortBy } from "./helpers";

const prepare = (template, keyedCells) =>
  keyedCells.map((keyedCell) => ({
    element: keyedCell[1],
    key: keyedCell[0],
    position: template[keyedCell[0]]?.position,
    active: template[keyedCell[0]]?.active,
  }));

const isRenderable = (cell) => cell.active;
const filterColumns = (cells) => cells.filter(isRenderable);
const sortCells = (cells) => sortBy(cells, "position");
const extractElement = (cells) => cells.map((cell) => cell.element);
export const dynamicRow = (template, keyedCells) =>
  pipe(filterColumns, sortCells, extractElement)(prepare(template, keyedCells));
