import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as creators from "../actions/creators";
import * as api from "../actions/api";
import getProvinceObject from "../helpers";
import Dropdown from "../../shared/v2/Dropdown";
import TextInputWrap from "./TextInputWrap";
import NewOfficeModal from "./NewOfficeModal";
import OfficeRemovalModal from "./OfficeRemovalModal";
import OfficeCard, { OFFICE_PROPS } from "./OfficeCard";

const GET_SECTIONS = (states, territories) => [
  { title: "Phone", key: "phone", editable: true, comp: TextInputWrap },
  { title: "Fax", key: "fax", editable: true, comp: TextInputWrap },
  {
    title: "Street Address",
    key: "street_address",
    editable: true,
    comp: TextInputWrap,
    isAddress: true,
  },
  { title: "City", key: "city", editable: true, comp: TextInputWrap, isAddress: true },
  {
    title: "State / Province",
    key: "locality",
    editable: true,
    isDD: true,
    comp: ({ value, onBlur, onChange, onKeyPress }) => (
      <Dropdown
        containerClassName="tw-flex-1"
        isSearchable
        isClearable
        autoFocus
        placeholder="Select"
        value={getProvinceObject(states, territories, value)}
        onChange={(v) => onChange(v?.value)}
        onBlur={onBlur}
        onKeyDown={onKeyPress}
        options={[
          {
            label: "States",
            options: states,
          },
          {
            label: "Province",
            options: territories,
          },
        ]}
      />
    ),
    isAddress: true,
    displayValue: (value) => getProvinceObject(states, territories, value)?.label,
  },
  { title: "ZIP / Postal Code", key: "postal_code", editable: true, comp: TextInputWrap, isAddress: true },
  { title: "Country", key: "country", editable: true, comp: TextInputWrap, isAddress: true },
];

const OfficeCards = ({ model, setValue, saveValue, resetOffice, saveOffice, states, territories }) => {
  const SECTIONS = GET_SECTIONS(states, territories, model);

  const [showOfficeModal, setShowOfficeModal] = useState(false);
  const [officeRemovalId, setShowOfficeRemovalModal] = useState(false);
  const removeOffice = (id) => {
    setValue("account_offices", [...model.account_offices.filter((o) => o.id !== id)]);
    saveValue("account_offices_attributes", [{ id, _destroy: true }]);
  };

  const mapModelToOffice = (m) => ({
    id: m.id,
    name: m.name,
    phone: m.phone,
    fax: m.fax,
    street_address: m.address?.street_address,
    city: m.address?.city,
    locality: m.address?.locality,
    postal_code: m.address?.postal_code,
    country: m.address?.country,
  });
  return (
    <>
      <OfficeCard
        model={mapModelToOffice(model)}
        SECTIONS={SECTIONS}
        setValue={(key, val) => {
          let m = {};
          const isAddress = SECTIONS.find((s) => s.key === key)?.isAddress;
          if (isAddress) {
            m = { ...model.address };
            m[key] = val;
          } else {
            m = val;
          }
          setValue(isAddress ? "address" : key, m);
        }}
        saveValue={(key, val) => {
          let m = {};
          const isAddress = SECTIONS.find((s) => s.key === key)?.isAddress;
          if (isAddress) {
            m[key] = val;
          } else {
            m = val;
          }
          saveValue(isAddress ? "address_attributes" : key, m);
        }}
        onAdd={model?.account_offices.length === 0 ? setShowOfficeModal : null}
      />
      {model?.account_offices?.map((office, officeIdx) => (
        <OfficeCard
          key={office.id}
          SECTIONS={SECTIONS}
          editable
          model={mapModelToOffice(office)}
          setValue={(key, val) => {
            const offices = [...model?.account_offices];
            const m = { ...offices[officeIdx] };
            const isAddress = SECTIONS.find((s) => s.key === key)?.isAddress;
            if (isAddress) {
              m.address = { ...office.address };
              m.address[key] = val;
            } else {
              m[key] = val;
            }
            offices[officeIdx] = m;
            setValue("account_offices", offices);
          }}
          saveValue={(key, val) => {
            const m = { id: office.id };
            const isAddress = SECTIONS.find((s) => s.key === key)?.isAddress;
            if (isAddress) {
              m.address_attributes = {};
              m.address_attributes[key] = val;
            } else {
              m[key] = val;
            }
            saveValue("account_offices_attributes", [m]);
          }}
          onAdd={model?.account_offices.length === officeIdx + 1 ? setShowOfficeModal : null}
          onRemove={setShowOfficeRemovalModal}
        />
      ))}
      {officeRemovalId && (
        <OfficeRemovalModal
          name={model?.account_offices.find((s) => s.id === officeRemovalId).name}
          onRemove={() => {
            removeOffice(officeRemovalId);
            setShowOfficeRemovalModal(false);
          }}
          onClose={() => {
            setShowOfficeRemovalModal(false);
          }}
        />
      )}
      {showOfficeModal && (
        <NewOfficeModal
          onAdd={(m) => {
            saveOffice({ account_offices_attributes: [m] });
            setShowOfficeModal(false);
            resetOffice();
          }}
          onClose={() => {
            setShowOfficeModal(false);
            resetOffice();
          }}
        />
      )}
    </>
  );
};

OfficeCards.propTypes = {
  ...OFFICE_PROPS,
  resetOffice: PropTypes.func.isRequired,
  saveOffice: PropTypes.func.isRequired,
  territories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.model,
  states: state.businessSettings.states,
  territories: state.businessSettings.territories,
});

export default connect(mapStateToProps, { ...creators, ...api })(OfficeCards);
