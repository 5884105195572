/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronDownSolidV6 = (props) => (
  <Icon {...props} name="Chevron Down Solid V6">
    <path d="M8 13.571a1.14 1.14 0 0 1-.808-.335L.336 6.38a1.143 1.143 0 0 1 1.616-1.616L8 10.814l6.049-6.049a1.143 1.143 0 0 1 1.616 1.616l-6.856 6.856a1.14 1.14 0 0 1-.809.334z" />
  </Icon>
);

export default ChevronDownSolidV6;
