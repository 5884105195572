import React from "react";
import PropTypes from "prop-types";
import HeadingSelect from "./HeadingSelect";
import Button from "../../shared/v2/Button";
import Tab from "../../shared/v2/Tab";
import TabBar from "../../shared/v2/TabBar";

const SectionHeader = ({
  agent,
  description,
  isEditing,
  name,
  onSubmit,
  onTabClick,
  options,
  period,
  selectedTab,
  setIsEditing,
  setPeriod,
  tabs,
  title,
}) => (
  <div className="tw-pb-[12px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-neutral-gray-10">
    <div className="tw-flex tw-justify-between tw-items-center">
      <HeadingSelect
        disabled={isEditing}
        name={name}
        onChange={setPeriod}
        options={options}
        title={title}
        value={period}
      />

      {!isEditing && (
        <Button size="medium" schema="secondary" onClick={() => setIsEditing(true)}>
          Edit
        </Button>
      )}

      {isEditing && (
        <div>
          <Button className="tw-mr-6" size="medium" schema="tertiary" onClick={() => setIsEditing(false)}>
            Close
          </Button>
          <Button size="medium" schema="primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>

    {agent?.value && (
      <div className="tw-uppercase tw-text-neutral-gray-50 tw-text-14d tw-font-bold tw-my-[20px]">
        {agent.label}&apos;s Goals
      </div>
    )}

    {tabs && (
      <TabBar className="tw-mt-[24px] tw-mb-[16px]">
        {tabs.map((tab) => {
          const selected = selectedTab === tab;
          return (
            <Tab
              key={tab}
              disabled={!selected && isEditing}
              label={tab}
              selected={selected}
              onClick={() => onTabClick(tab)}
            />
          );
        })}
      </TabBar>
    )}

    <div className="tw-text-14d tw-text-gray-50 tw-mt-4">{description}</div>
  </div>
);

SectionHeader.propTypes = {
  agent: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  description: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTabClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  period: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setPeriod: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SectionHeader.defaultProps = {
  agent: null,
  onTabClick: () => {},
  tabs: null,
  selectedTab: "",
};

export default SectionHeader;
