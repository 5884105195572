export const MODAL_ANIMATION_DURATION_MS = 400;

export const containerAnimationStyles = (duration) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

export const modalAnimationStyles = (duration) => ({
  transition: `transform ${duration}ms ease-out`,
  transform: "translateY(-1000px)",
});

export const containerTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const modalTransitionStyles = {
  entering: { transform: "translateY(calc(50vh + 100%)" },
  entered: { transform: "translateY(0px)" },
  exiting: { transform: "translateY(calc(50vh + 100%)" },
  exited: { transform: "translateY(calc(50vh + 100%)" },
};
