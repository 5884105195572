/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CirclePlaySolidV6 = (props) => (
  <Icon {...props} name="Circle Play Solid V6">
    <path d="M16 8c0 4.419-3.581 8-8 8s-8-3.581-8-8c0-4.419 3.581-8 8-8s8 3.581 8 8zM5.5 5.25v5.5c0 .272.147.522.384.653a.745.745 0 0 0 .756-.013l4.5-2.75a.753.753 0 0 0 0-1.282l-4.5-2.75c-.231-.169-.522-.147-.756-.013a.745.745 0 0 0-.384.653z" />
  </Icon>
);

export default CirclePlaySolidV6;
