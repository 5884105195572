import React from "react";
import PropTypes from "prop-types";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, TrashCanSolidV6 } from "@shared/v2/Icomoon";

const CategoryTagItem = ({ item, onEdit, onDelete }) => (
  <div className="tw-flex tw-justify-between tw-items-center">
    <span>{item.tag || item.category}</span>
    <div className="tw-flex tw-gap-[8px]">
      <IconButton size="small" schema="tertiary" onClick={onEdit}>
        <PencilSolidV6 />
      </IconButton>
      <IconButton size="small" schema="tertiary" onClick={onDelete}>
        <TrashCanSolidV6 />
      </IconButton>
    </div>
  </div>
);

CategoryTagItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CategoryTagItem;
