import React from "react";
import PropTypes from "prop-types";
import { UserGroupSolidV6 } from "@shared/v2/Icomoon";
import { isEmpty, startCase } from "lodash";

const PersonMergeEvent = ({
  event: {
    interacted_at_absolute: interactedAtAbsolute,
    timeline_fields: {
      merged_details: mergedDetails = {},
      primary_person_name: primaryPersonName,
      primary_person_uuid: primaryPersonUuid,
      secondary_person_name: secondaryPersonName,
      secondary_person_uuid: secondaryPersonUuid,
      updater_name: updaterName,
    },
  },
}) => (
  <div className="tw-p-[24px] tw-bg-white tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-10 tw-flex tw-items-start tw-gap-[24px]">
    <div className="tw-flex tw-flex-none tw-items-center tw-justify-center tw-border-solid tw-border-[2px] tw-border-neutral-gray-75 tw-rounded-full tw-p-[10px]">
      <UserGroupSolidV6 size="l" />
    </div>
    <div className="tw-text-14d tw-flex tw-flex-col tw-gap-[4px] tw-flex-1">
      <div className="tw-flex tw-justify-between tw-gap-[8px]">
        {primaryPersonUuid && (
          <div>
            <b>{updaterName}</b> merged this contact into{" "}
            <a href={`/people/${primaryPersonUuid}`} target="_blank" rel="noreferrer">
              <b>{primaryPersonName || "No Name"}</b>
            </a>
          </div>
        )}
        {secondaryPersonUuid && (
          <div>
            <b>{updaterName}</b> merged{" "}
            <a href={`/people/${secondaryPersonUuid}`} target="_blank" rel="noreferrer">
              <b>{secondaryPersonName || "No Name"}</b>
            </a>{" "}
            into this contact
          </div>
        )}
        <span className="tw-text-12d">{interactedAtAbsolute}</span>
      </div>

      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          {Object.entries(mergedDetails).map(
            ([key, value]) =>
              !isEmpty(value) && (
                <div key={key}>
                  <span>
                    {startCase(key)}: {typeof value === "string" ? value : ""}
                  </span>
                  {Array.isArray(value) && (
                    <ul className="tw-m-0 tw-pl-[24px]">
                      {value.map((v) => (
                        <li key={v}>{v}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ),
          )}
        </div>
        <div />
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-[8px]" />
    </div>
  </div>
);

PersonMergeEvent.propTypes = {
  event: PropTypes.shape({
    interacted_at_absolute: PropTypes.string,
    timeline_fields: PropTypes.shape({
      merged_details: PropTypes.shape(),
      primary_person_name: PropTypes.string,
      primary_person_uuid: PropTypes.string,
      secondary_person_name: PropTypes.string,
      secondary_person_uuid: PropTypes,
      updater_name: PropTypes.string,
    }),
  }).isRequired,
};

export default PersonMergeEvent;
