/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Chat = (props) => (
  <Icon {...props} name="Chat">
    <path d="M4.502 6.5a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm3.499 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm3.5 0a1 1 0 1 0 0 2 1 1 0 1 0 0-2zM8.001 1C3.583 1 .002 3.909.002 7.5c0 1.488.622 2.85 1.653 3.947-.466 1.231-1.434 2.275-1.45 2.287A.75.75 0 0 0 .752 15c1.922 0 3.437-.803 4.346-1.447A9.662 9.662 0 0 0 8.001 14C12.419 14 16 11.091 16 7.5S12.419 1 8.001 1zm0 11.5a8.124 8.124 0 0 1-2.45-.378l-.709-.225-.609.431a6.69 6.69 0 0 1-1.797.906c.228-.378.45-.803.622-1.256l.331-.878-.644-.681c-.566-.603-1.244-1.6-1.244-2.919 0-2.756 2.915-5 6.499-5s6.499 2.244 6.499 5-2.915 5-6.499 5z" />
  </Icon>
);

export default Chat;
