import React, { useEffect } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import PlansOrSites from "./plansorsites";
import EmptyState from "./emptystate";
import Header from "../../../UI/Header";
import AdHeaderOptions from "../HeaderOptions/AdHeaderOptions";
import * as actionCreators from "../actions/thunks";
import LoadingOverlay from "../../../../shared/v2/LoadingOverlay";
import errorShape from "../model/error";
import Error from "../Error";

const AdvertisingPlan = ({
  getAdvertisingPlanAsThunk,
  uuid,
  isLoading,
  advertisingplans,
  getDeletePlanError,
}) => {
  useEffect(() => {
    getAdvertisingPlanAsThunk(uuid);
  }, []);

  return (
    <>
      <Header header="Advertising Plan" add={<AdHeaderOptions />} />
      {getDeletePlanError && <Error message={getDeletePlanError.message} />}
      <LoadingOverlay isLoading={isLoading}>
        <div className="tw-min-h-[116px] tw-mt-[24px]">
          {advertisingplans.length > 0 ? (
            <div className="tw-gap-x-[16px] tw-grid tw-grid-cols-2">
              {advertisingplans.map((advertisingplan, i) => (
                <PlansOrSites
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  planId={advertisingplan.id}
                  name={advertisingplan.name}
                  sharedCount={advertisingplan?.sites_count?.completed || 0}
                  totalCount={advertisingplan?.sites_count?.total || 0}
                />
              ))}
            </div>
          ) : (
            !isLoading && <EmptyState />
          )}
        </div>
      </LoadingOverlay>
    </>
  );
};

AdvertisingPlan.propTypes = {
  uuid: Proptypes.string.isRequired,
  isLoading: Proptypes.bool,
  getDeletePlanError: errorShape,
  getAdvertisingPlanAsThunk: Proptypes.func.isRequired,
  advertisingplans: Proptypes.arrayOf(Proptypes.shape({})),
};
AdvertisingPlan.defaultProps = {
  advertisingplans: [],
  getDeletePlanError: null,
  isLoading: false,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  advertisingplans: state.tdpMarketingReducer.advertisingplans,
  getDeletePlanError: state.tdpMarketingReducer.getDeletePlanError,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getAdvertisingPlanAsThunk: (uuid) => dispatch(actionCreators.getAdvertisingPlanAsThunk(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingPlan);
