/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HouseUserSolidV6 = (props) => (
  <Icon {...props} name="House User Solid V6">
    <path d="M15.994 7.986c0 .5-.417.892-.889.892h-.889l.019 4.447a1.779 1.779 0 0 1-1.778 1.786h-8.9a1.779 1.779 0 0 1-1.778-1.778V8.877h-.89a.88.88 0 0 1-.89-.892c0-.25.083-.472.278-.667L7.399 1.11c.194-.195.417-.223.611-.223s.417.056.586.195l7.092 6.236c.222.194.336.417.306.667zM8 5.333a1.78 1.78 0 0 0 0 3.556 1.78 1.78 0 0 0 0-3.556zm-.889 4.445A2.22 2.22 0 0 0 4.889 12c0 .244.2.444.444.444h5.333c.244 0 .444-.2.444-.444a2.22 2.22 0 0 0-2.222-2.222H7.11z" />
  </Icon>
);

export default HouseUserSolidV6;
