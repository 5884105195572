import { schemaGenerator } from "../../utils";

const textAreaDictionary = {
  regular: {
    default: [
      "tw-bg-transparent",
      "tw-border-0",
      "tw-outline-none",
      "tw-placeholder-neutral-gray-50",
      "tw-w-full",
    ],
    focus: ["focus:tw-outline-none"],
    disabled: ["disabled:tw-placeholder-neutral-gray-30"],
  },
};

export const textAreaStyleSchema = schemaGenerator(textAreaDictionary);

const textAreaResizeDictionary = {
  none: "tw-resize-none",
  both: "tw-resize",
  horizontal: "tw-resize-x",
  vertical: "tw-resize-y",
};

export const textAreaResizeSchema = (schema) =>
  textAreaResizeDictionary[schema] || textAreaResizeDictionary.none;

const fieldsetBase = {
  default: [
    "tw-font-regular",
    "tw-border-solid",
    "tw-border",
    "tw-rounded-[2px]",
    "tw-text-left",
    "tw-text-14px",
    "tw-px-12px",
    "tw-text-neutral-gray-75",
    "tw-bg-theme-text-input",
    "tw-z-2",
  ],
  hover: ["hover:tw-bg-theme-text-input-hover"],
  focusWithin: [
    "focus-within:tw-bg-white",
    "focus-within:hover:tw-bg-white",
    "focus-within:tw-outline-none",
    "focus-within:tw-ring-[1.5px]",
    "focus-within:tw-ring-theme-text-input-active",
  ],
  disabled: ["disabled:tw-text-neutral-gray-30", "disabled:tw-bg-theme-text-input-disabled"],
};

const fieldsetDictionary = {
  regular: {
    default: [...fieldsetBase.default, "tw-border-theme-text-input"],
    hover: [...fieldsetBase.hover, "hover:tw-border-theme-text-input-hover"],
    focusWithin: [
      ...fieldsetBase.focusWithin,
      "focus-within:tw-border-theme-text-input-active",
      "focus-within:hover:tw-border-theme-text-input-active",
      "focus-within:tw-ring-offset-0",
    ],
    disabled: [...fieldsetBase.disabled, "disabled:tw-border-theme-text-input-disabled"],
  },
  error: {
    default: [...fieldsetBase.default, "tw-border-semantic-red-25"],
    hover: [...fieldsetBase.hover, "hover:tw-border-semantic-red-50"],
    focusWithin: [
      ...fieldsetBase.focusWithin,
      "focus-within:tw-border-semantic-red-100",
      "focus-within:tw-ring-semantic-red-10",
      "tw-ring-offset-0",
    ],
    disabled: [...fieldsetBase.disabled, "disabled:tw-border-neutral-gray-15"],
  },
};

export const fieldsetStyleSchema = schemaGenerator(fieldsetDictionary);
