import React from "react";
import { func } from "prop-types";

import { displayHidden } from "../helpers";
import { payloadPropTypes, PAYLOAD_DEFAULT } from "../propTypes";
import { CONTINGENT_EVENTS, POSITION } from "./constants";
import { CONTINGENT_RECURRING } from "../constants";

import useHandlrs from "../hooks";

import TextToggle from "../../../../shared/v2/TextToggle/TextToggle";
import TextComboInput from "../../../../shared/v2/TextComboInput";
import Dropdown from "../../../../shared/v2/Dropdown";

const ModalAddEditContingentElements = ({ payloadHandlrs, payload }) => {
  const handlr = useHandlrs(payloadHandlrs);

  return (
    <div className={`tw-flex tw-gap-[14px] tw-mt-24px ${displayHidden(payload.contingent)}`}>
      <div className="tw-flex tw-items-end tw-gap-[12px] tw-flex-1">
        <div className="tw-flex-1">
          <TextComboInput
            label="# of Days"
            trailing="Days"
            size="2"
            value={payload.contingent?.numOfDays || ""}
            onChange={(e) => handlr(e, CONTINGENT_RECURRING.contingent, "numOfDays")}
            isRequired
          />
        </div>
        <div className="tw-flex-1">
          <TextToggle
            name="contingent-stat-1"
            value={payload.contingent?.next}
            onChange={(e) => handlr(e, CONTINGENT_RECURRING.contingent, "next")}
            options={POSITION}
          />
        </div>
      </div>
      <Dropdown
        containerClassName="tw-flex-1"
        menuShouldComeToFront
        label="Event"
        isRequired
        value={payload.contingent?.event || null}
        placeholder="Select Event"
        onChange={(e) => handlr(e, CONTINGENT_RECURRING.contingent, "event")}
        options={CONTINGENT_EVENTS}
      />
    </div>
  );
};

ModalAddEditContingentElements.propTypes = {
  payloadHandlrs: func,
  payload: payloadPropTypes,
};

ModalAddEditContingentElements.defaultProps = {
  payloadHandlrs: null,
  payload: PAYLOAD_DEFAULT,
};

export default ModalAddEditContingentElements;
