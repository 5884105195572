import {
  BriefcaseSolidV6,
  BullhornRegularV6,
  HouseFlagSolidV6,
  MobileSignalOutSolidV6,
  GraduationCapSolidV6,
  UserGraduateSolidV6,
  AreaProCustomv6,
} from "@shared/v2/Icomoon";

export const APP_ICONS_BY_LABEL = {
  marketer: { label: "Marketer", Icon: BullhornRegularV6, linkUrl: "/" },
  cma: { label: "CMA", Icon: HouseFlagSolidV6, linkUrl: "/" },
  recruiter: {
    label: "Recruiter.ai",
    Icon: BriefcaseSolidV6,
    linkUrl: "https://www.brivitymarketer.com/v2/recruiter",
  },
  textToLead: {
    label: "KWKLY",
    Icon: MobileSignalOutSolidV6,
    linkUrl: "https://app.kwkly.com/login.aspx",
  },
  placeAcademy: {
    label: "PLACE Academy",
    Icon: GraduationCapSolidV6,
    linkUrl: "https://learn.place.com/enrollments",
  },
  reportCards: {
    label: "Report Cards",
    Icon: UserGraduateSolidV6,
    linkUrl: "https://learn.place.com/manage/reports/groups",
  },
  areaPro: {
    label: "AreaPro",
    Icon: AreaProCustomv6,
    linkUrl: "https://app.areapro.com/login",
  },
};

const { placeAcademy, marketer, cma, recruiter, textToLead, areaPro } = APP_ICONS_BY_LABEL;

export const OPTIONS_BY_LABEL = {
  [marketer.label]: "Brivity Marketer",
  // Need to change when we support support deep link > marketer.recruiter tab
  // [recruiter.label]: "Brivity Recruiter",
  // Might need this again
  // [designer.label]: "Brivity Designer",
  [cma.label]: "Brivity CMA",
};

export const OWNER_PLACE_RESOURCES = [placeAcademy, marketer, cma, recruiter, textToLead, areaPro];

export const AGENT_RESOURCES = [marketer, cma];

export const AGENT_PLACE_RESOURCES = [marketer, cma, areaPro];

export const OWNER_BRIVITY_RESOURCES = [marketer, cma, textToLead];
