import React from "react";

const MarketReportSentEvent = ({ event }) => {
  const { sent_by, ...fields } = event.timeline_fields;
  const available_criteria = [
    fields.property_type,
    fields.beds,
    fields.baths,
    fields.sqft,
    fields.lot_sqft,
    fields.price,
    fields.search_radius,
  ].filter((obj) => obj);

  return (
    <div className="timeline-event market-report-timeline-event">
      <div className="row">
        <div className="col-xs-1">
          <div className="timeline-icon-circle">
            <img src="/images/CMA-icon.svg" alt="CMA Icon" />
          </div>
        </div>
        <div className="col-xs-7">
          {sent_by == "drip" ? (
            <div>Market Report sent via drip</div>
          ) : (
            <div>
              <span className="bold-detail">{sent_by}</span>
              {" sent Market Report"}
            </div>
          )}
          <div className="timeline-description-text">
            <div>
              Report Name:{" "}
              <a href={fields.report_url} target="_blank">
                {fields.report_name}
              </a>
            </div>
            <div>Location: {fields.location}</div>
            <div>Criteria: {available_criteria.join(" | ")}</div>
            <div>Scheduled Drip: {fields.drip_schedule}</div>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );
};

export default MarketReportSentEvent;
