/* eslint-disable react/prop-types */

import React, { useState } from "react";
import queryString from "query-string";

import AppliedFilter from "./AppliedFilter";
import FloatingForm from "../../../../shared/v2/Modal/FloatingForm";
import TextInput from "../../../../shared/v2/TextInput";

import { normalizeFilters } from "../../../reducer";

const AppliedFiltersWithSave = ({
  openSaveFiltersModal,
  closeSaveFiltersModal,
  saveListingFilters,
  saveFiltersData,
  rawFilters = [],
  filters = [],
  onChange,
  clearFilters,
  className = "",
}) => {
  const [filterName, setFilterName] = useState("");

  if (filters.length === 0) {
    return null;
  }

  const handleSaveFilter = async () => {
    const normalizedFilters = normalizeFilters({ filters: rawFilters }, false);
    const stringfiedQuery = queryString.stringify(normalizedFilters, { arrayFormat: "bracket" });

    const data = {
      name: filterName,
      filters: stringfiedQuery,
    };

    saveListingFilters(data);
  };

  const removeFilter = (removedFilterId) => {
    onChange(removedFilterId);
  };

  return (
    <div className={className} data-cy="applied-filter-list">
      {filters.map(({ id, label }) => (
        <AppliedFilter key={id.value} label={label} onRemove={() => removeFilter(id)} />
      ))}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
      <a data-cy="opener-smart-filter-modal" onClick={openSaveFiltersModal} href="#" className="tw-ml-6px">
        Save Filter
      </a>
      <button
        type="button"
        className="tw-border-0 tw-bg-transparent tw-cursor-pointer"
        onClick={clearFilters}
      >
        Clear all
      </button>
      <FloatingForm
        show={saveFiltersData.isVisible}
        primaryOption="Save"
        title="Save Filter"
        isPrimaryLoading={saveFiltersData.isLoading}
        onPrimary={() => handleSaveFilter()}
        primaryProps={{ "data-cy": "save-smart-filter" }}
        onCancel={() => {
          closeSaveFiltersModal();
        }}
        errors={saveFiltersData.errors}
      >
        <div data-cy="smart-filter-modal">
          <p>
            The current filters will be saved as a smart filter for you on the left navigation bar, under
            <b> Transactions</b>.
          </p>
          <TextInput
            autoFocus
            label="Filter Name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </div>
      </FloatingForm>
    </div>
  );
};

export default AppliedFiltersWithSave;
