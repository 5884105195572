/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AddUser = (props) => (
  <Icon {...props} name="Add User">
    <path d="M15.6 6.8H14V5.2c0-.22-.18-.4-.4-.4h-.8c-.22 0-.4.18-.4.4v1.6h-1.6c-.22 0-.4.18-.4.4V8c0 .22.18.4.4.4h1.6V10c0 .22.18.4.4.4h.8c.22 0 .4-.18.4-.4V8.4h1.6c.22 0 .4-.18.4-.4v-.8c0-.22-.18-.4-.4-.4zM5.6 8a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4zm2.24.8h-.418a4.358 4.358 0 0 1-3.644 0h-.417a3.36 3.36 0 0 0-3.36 3.36v1.04a1.2 1.2 0 0 0 1.2 1.2h8.8a1.2 1.2 0 0 0 1.2-1.2v-1.04a3.36 3.36 0 0 0-3.36-3.36z" />
  </Icon>
);

export default AddUser;
