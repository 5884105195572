import React, {Component} from 'react';
import ViewWebActivityModal from '../../PersonDetail/components/modals/ViewWebActivityModal';
import { valueOrZero, lastVisit, visits } from '../../shared/WebActivityHelpers';

class FavoritesOrViews extends Component {
  state = {
    modalVisible: false
  }

  noCount = (count) =>  count == 0 || count == undefined

  countDisplay = (count) => {
    if (this.noCount(count)) {
      return '—'
    }
    return <span onClick={this.openModal} className="view-or-favorite">{count}</span>
  }

  openModal = () => {
    this.setState({ modalVisible: true })
  }

  closeModal = () => {
    this.setState({ modalVisible: false })
  }

  render() {
    const { modalVisible } = this.state
    const { count, idxDataUri, idxDataToken, isFavorite, person } = this.props

    return (
      <div>
        <div>
          <h6>
            {this.countDisplay(count)}
          </h6>
          <h6 className="lower-row">
            <span>{isFavorite ? 'Favorites' : 'Views'}</span>
          </h6>
        </div>
        { modalVisible &&
          <ViewWebActivityModal
            closeWebActivityModal={this.closeModal}
            idxDataUri={idxDataUri}
            idxDataToken={idxDataToken}
            isFavorite={isFavorite}
            lastVisit={lastVisit(person.idx_last_visit)}
            uuid={person.uuid}
            visits={visits(person.idx_visits)}
            views={valueOrZero(person.idx_property_views)}
          />
        }
      </div>
    )
  }
}

export default FavoritesOrViews;
