import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Moment from "react-moment";
import Pluralize from "react-pluralize";
import ProfileIcon from "../../../shared/ProfileIcon";
import HeaderTitle from "../modals/HeaderTitle";
import form from "../form.module.css";
import button from "../button.module.css";
import capitalize from "capitalize";
import Linkify from "linkify-react";
const milestoneOptions = (accountMilestoneNames) => {
  const personNames = accountMilestoneNames["Person"];
  let personObject = {};

  personNames.forEach((name) => {
    personObject[name] = name
      .split("_")
      .map((element) => capitalize(element))
      .join(" ");
  });

  return personObject;
};

const Details = (props) => {
  const weeklyOptions = [
    { value: "0", label: "On Sunday" },
    { value: "1", label: "On Monday" },
    { value: "2", label: "On Tuesday" },
    { value: "3", label: "On Wednesday" },
    { value: "4", label: "On Thursday" },
    { value: "5", label: "On Friday" },
    { value: "6", label: "On Saturday " },
  ];
  const { task, editTask, completeTaskFromDetails, editAndComplete } = props;

  return (
    <Modal id="task-details" className="person-details-modal" show={true} onHide={props.closeTaskDetails}>
      <Modal.Header>
        <Modal.Title>
          <HeaderTitle
            title={`Task Details for ${task.taskable_name}`}
            onClose={props.closeTaskDetails}
            style={{ fontSize: "18px" }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={5}>
            <label className={form.formLabel}>DATE DUE</label>
            <p id="date" className={props.dueness}>
              {task.state == "completed" && <Moment format="MM/DD/YYYY" date={task.completed_at} />}
              {task.state != "completed" && (
                <div>
                  {task.calculated_due_on && <Moment format="MM/DD/YYYY" date={task.calculated_due_on} />}
                  {!task.calculated_due_on && (
                    <Pluralize plural="days" singular="day" count={parseInt(task.contingency_rule["days"])} />
                  )}
                  {!task.calculated_due_on && " " + task.contingency_rule["position"] + " "}
                  {!task.calculated_due_on &&
                    (task.parent_task_name
                      ? task.parent_task_name
                      : task.contingency_event_and_type[1] != undefined &&
                        milestoneOptions(props.accountMilestoneNames)[task.contingency_event_and_type[1]])}
                </div>
              )}
            </p>

            {task.type === "Appointment" && task.scheduled_time !== null && (
              <>
                <label className={form.formLabel}>APPOINTMENT TIME</label>
                <p> {moment.unix(task.scheduled_time).format("LT")} </p>
              </>
            )}

            <label className={form.formLabel}>WHO TASK IS ASSIGNED TO</label>
            {task.assigned_to_user_id && (
              <div className={"contact-card clearfix m-b-5"}>
                <ProfileIcon dynamic={true} userId={task.assigned_to_user_id} showName={true} />
              </div>
            )}
            {!task.assigned_to_user_id && <p>Anyone</p>}

            <label className={form.formLabel}>PRIORITY</label>
            {task.priority && <p>{task.priority}</p>}
            {!task.priority && <p>UNPRIORITIZED</p>}

            <label className={form.formLabel}>TYPE</label>
            {task.type && <p>{task.type}</p>}

            {!task.assigned_plan_id && <label className={form.formLabel}>RECURRENCE</label>}

            {!task.assigned_plan_id && task.recurring && task.recurring_day_of_week && (
              <p>
                <i className="fa fa-refresh" style={{ marginRight: "10px" }} />
                {weeklyOptions[task.recurring_day_of_week].label}
              </p>
            )}

            {!task.assigned_plan_id && task.recurring && task.recurring_every_so_many_days && (
              <p>
                <i className="fa fa-refresh" style={{ marginRight: "10px" }} />
                Every <Pluralize singular={"day"} count={task.recurring_every_so_many_days} />
              </p>
            )}

            {!task.assigned_plan_id && !task.recurring && (
              <p>
                <i className="fa fa-refresh text-muted p-r-5" />
                Only once
              </p>
            )}
          </Col>
          <Col xs={7}>
            <label className={form.formLabel}>TASK DETAILS</label>
            <p>
              {task.assigned_plan_id && (
                <span>
                  <i className={`fa fa-bolt m-r-5`} style={{ color: "#59c4c4" }} />
                </span>
              )}
              {task.name}
            </p>
            {task.instructions && (
              <div className="tw-whitespace-pre-wrap tw-break-words">
                <label className={`${form.formLabel} show-instructions`}>INSTRUCTIONS</label>
                <Linkify tagName="p" options={{ defaultProtocol: "https", target: "_blank" }}>
                  {task.instructions}
                </Linkify>
              </div>
            )}
            {task.note && (
              <div className="tw-whitespace-pre-wrap tw-break-words">
                <label className={`${form.formLabel} show-instructions`}>NOTES</label>
                <Linkify tagName="p" options={{ defaultProtocol: "https", target: "_blank" }}>
                  {task.note}
                </Linkify>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ marginTop: "-20px" }}>
        {task.state !== "completed" && (
          <button
            className={`${button.btn} ${button.btnLg} ${button.btnBlue} pull-right`}
            onClick={
              task.type === "Appointment" ? () => editAndComplete(task) : () => completeTaskFromDetails(task)
            }
          >
            Complete Task
          </button>
        )}
        <button
          className={`${button.btn} ${button.btnLg} ${button.btnBlue} pull-right m-r-10`}
          onClick={() => editTask(task)}
        >
          Edit
        </button>
        <button
          className={`${button.btn} ${button.btnLg} ${button.btnGray} pull-left`}
          onClick={props.closeTaskDetails}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Details;
