import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import formatter from "../../../../shared/formatters";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";
import colors from "../../DashboardShared/helpers/colors";
import { generateDiffBox, scaleValue } from "../utils/chart";

const transformRecruiting = {
  activeAgents: (
    {
      activeAgents: { annualGoal, chartDataSelectedYear },
      summary: { activeAgents, agentsHiredYearGoal, agentsHiredYtd, activeNetAgentsAddedYtd },
    } = {
      activeAgents: {
        annualGoal: 0,
        chartDataSelectedYear: [],
      },
      summary: { activeAgents: 0, agentsHiredYearGoal: 0, agentsHiredYtd: 0, activeNetAgentsAddedYtd: 0 },
    },
  ) => ({
    summary: {
      agentsHiredYtd: {
        "Agents Hired": { value: agentsHiredYtd },
        "Monthly Goal": { value: Math.round(agentsHiredYearGoal / 12) },
        "Annual Goal": { value: agentsHiredYearGoal },
      },
      activeAgents: {
        "Active Agents": { value: activeAgents },
        "Monthly Goal": { value: Math.round(annualGoal / 12) },
        "Annual Goal": { value: annualGoal },
      },
      netAgentsAddedYtd: activeNetAgentsAddedYtd,
    },
    prevMonthDifferences: chartDataSelectedYear
      .filter((d) => moment(d.date, "YYYY-MM-DD").startOf("month").isBefore(moment()))
      .map(({ prevMonthDiff }) => prevMonthDiff),
    chartData: {
      labels: chartDataSelectedYear.map((d) => moment(d.date).format("MMM")),
      datasets: [
        {
          label: "Full Time",
          data: chartDataSelectedYear,
          parsing: { xAxisKey: "fullTime", yAxisKey: "fullTime" },
          backgroundColor: colors.color1.default,
          hoverBackgroundColor: colors.color1.hover,
          barPercentage: 0.25,
          datalabels: {
            formatter: () => "",
          },
        },
        {
          label: "Part Time",
          data: chartDataSelectedYear,
          parsing: { xAxisKey: "partTime", yAxisKey: "partTime" },
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: colors.color1.default,
            formatter: (value, { dataIndex }) => {
              const { fullTime, partTime } = chartDataSelectedYear[dataIndex];
              const total = fullTime + partTime || 0;
              if (total < 1) return "";
              return formatter.number(total);
            },
          },
          backgroundColor: "#ffa826",
          barPercentage: 0.25,
        },
      ],
    },
  }),
  totalAgents: (
    { allAgents: { chartDataSelectedYear }, summary: { totalAgents, totalNetAgentsAddedYtd } } = {
      allAgents: {
        annualGoal: 0,
        chartDataSelectedYear: [],
      },
      summary: { totalAgents: 0, totalNetAgentsAddedYtd: 0 },
    },
  ) => ({
    summary: { totalAgents, netAgentsAddedYtd: totalNetAgentsAddedYtd },
    chartData: {
      labels: chartDataSelectedYear.map((d) => moment(d.date).format("MMM")),
      datasets: [
        {
          label: "Total Agents",
          data: chartDataSelectedYear.map((d) => d.total),
          backgroundColor: colors.color1.default,
          hoverBackgroundColor: colors.color1.hover,
          barPercentage: 0.25,
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: colors.color1.default,
            formatter: (value) => {
              if (value < 1) return "";
              return formatter.number(value);
            },
          },
        },
      ],
    },
  }),
};

const getChartOptions = (data) => ({
  plugins: {
    legend: { display: false },
    tooltip: {},
    goalLine: {
      goal: data.activeAgents?.annualGoal,
    },
    annotation: {
      clip: false,
      common: {
        drawTime: "afterDraw",
      },
      annotations: data.prevMonthDifferences?.reduce(
        (p, c, i) => ({
          ...p,
          [i]: generateDiffBox(c, i),
        }),
        {},
      ),
    },
  },
  layout: {
    padding: { bottom: (ctx) => scaleValue(ctx, "paddingBottom", 32) },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      offset: true,
    },
    y: {
      stacked: true,
      border: {
        display: false,
      },
      min: 0,
      ticks: {
        beginAtZero: true,
        autoSkip: false,
        padding: 10,
        stepSize: 1,
      },
    },
  },
});

const getRecruitingData = ({ signal, params }) =>
  caseTransformingAxios.get("/api/v4/reporting/operator_dashboard", {
    signal,
    params: { section: "agent_monthly_counts", ...params },
  });

const useRecruitingSection = ({ agentType, rangeType, year }) => {
  const [rawData, setRawData] = useState();
  const [state, _setState] = useState({
    data: { summary: {}, chartData: { datasets: [] } },
    error: null,
    loading: false,
  });
  const setState = useMemo(
    () => (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  );

  useEffect(() => {
    setState({ data: transformRecruiting[agentType](rawData) });
  }, [agentType, rawData]);

  useEffect(() => {
    const abortController = new AbortController();
    setState({ loading: true });
    getRecruitingData({
      signal: abortController.signal,
      params: { selectedYear: year, rangeType: rangeType.value },
    })
      .then(({ data }) => {
        setRawData(data);
        setState({ error: null, loading: false });
      })
      .catch(() => {
        setState({ error: true, loading: false });
      });

    return () => abortController.abort();
  }, [rangeType, year]);

  return {
    ...state,
    chartOptions: getChartOptions(state.data),
    actions: {
      clearError: () => setState({ error: null }),
    },
  };
};

export default useRecruitingSection;
