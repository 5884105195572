/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HeartSolidV6 = (props) => (
  <Icon {...props} name="Heart Solid V6">
    <path d="M0 5.966v-.181a4.466 4.466 0 0 1 3.731-4.406 4.49 4.49 0 0 1 3.894 1.248L8 3.001l.347-.374a4.548 4.548 0 0 1 3.922-1.248A4.466 4.466 0 0 1 16 5.785v.181a4.673 4.673 0 0 1-1.488 3.422L8.865 14.66a1.268 1.268 0 0 1-1.732 0L1.486 9.388A4.678 4.678 0 0 1-.001 5.966z" />
  </Icon>
);

export default HeartSolidV6;
