import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import AvatarPlaceholder from "@shared/v2/Avatar/AvatarPlaceholder";

const AVATAR_CLUSTER_STYLES = {
  1: "tw-absolute tw-top-0 tw-left-0 tw-tansform tw-translate-x-1/2 tw-translate-y-[60%] tw-z-10",
  2: "tw-absolute tw-top-0 tw-left-0",
  3: "tw-absolute tw-top-0 tw-left-0 tw-transform tw-translate-x-[-10%] tw-translate-y-[80%]",
};

const AvatarCluster = ({ children }) => {
  const avatars = Children.toArray(children);
  const count = Children.count(children);
  const displayCount = count > 3 ? count - 2 : null;
  if (count === 1) return children;
  return (
    <div className="tw-relative tw-ml-[2px] tw-w-[36px] tw-h-[40px]">
      {displayCount && (
        <AvatarPlaceholder className={AVATAR_CLUSTER_STYLES[2]} border="tinted" hiddenCount={displayCount} />
      )}
      {avatars.slice(0, displayCount ? 2 : 3).map((avatar, i) =>
        cloneElement(avatar, {
          // eslint-disable-next-line react/no-array-index-key
          key: i,
          border: "tinted",
          className: AVATAR_CLUSTER_STYLES[displayCount && i === 1 ? 3 : i + 1],
        }),
      )}
    </div>
  );
};

AvatarCluster.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AvatarCluster;
