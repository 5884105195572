import React, { useState } from "react";
import PropTypes from "prop-types";
import { DocumentPropType } from "../../propTypes";

import { Overlay } from "../../../../../shared/v1/Overlay";
import IconButton from "../../../../../shared/v2/IconButton";
import {
  VerticalDots,
  Download,
  Trash,
  VisibilityVisible,
  Edit,
} from "../../../../../shared/v2/Icomoon/Icons";

import {
  contextContainer,
  overlayContainer,
  actionClassNames,
  actionIconClassName,
  downloadClassNames,
  trashClassNames,
  trashIconClassName,
} from "./Context.styles";

const Context = ({ document, triggerRename, deleteDocuments, previewDocument }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  const onHideHandler = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    deleteDocuments([document.id]);
  };

  const handleRename = () => {
    setIsOpen(false);
    triggerRename();
  };

  const handleKeyDownPreview = (e) => {
    if (e.key === "Enter") {
      previewDocument();
    }
  };

  const handleKeyDownRename = (e) => {
    if (e.key === "Enter") {
      handleRename();
    }
  };

  const handleKeyDownDelete = (e) => {
    if (e.key === "Enter") {
      handleDelete();
    }
  };

  return (
    <td>
      <div className={contextContainer}>
        <IconButton
          size="small"
          schema="tertiary"
          onClick={handleButtonClick}
          data-cy="document-list-item-context-actions"
        >
          <VerticalDots size="l" />
        </IconButton>
        <Overlay
          className="tw-z-30"
          show={isOpen}
          onClick={handleOverlayClick}
          onHide={onHideHandler}
          rootClose
        >
          <div className={overlayContainer} data-cy="document-list-item-context-actions-menu">
            <div
              role="menuitem"
              tabIndex={0}
              className={actionClassNames}
              onClick={previewDocument}
              onKeyDown={handleKeyDownPreview}
              data-cy="document-list-item-action-preview"
            >
              <VisibilityVisible className={actionIconClassName} size="m" /> <span>Preview</span>
            </div>
            {document.canRename && (
              <>
                <div
                  role="menuitem"
                  tabIndex={0}
                  className={actionClassNames}
                  onClick={handleRename}
                  onKeyDown={handleKeyDownRename}
                  data-cy="document-list-item-action-rename"
                >
                  <Edit className={actionIconClassName} size="m" /> Rename
                </div>
              </>
            )}
            {document.expiringUrl && (
              <a
                role="menuitem"
                tabIndex={0}
                className={downloadClassNames}
                href={document.expiringUrl}
                download
                target="_blank"
                rel="noreferrer"
                data-cy="document-list-item-action-download"
              >
                <Download className={actionIconClassName} size="m" /> Download
              </a>
            )}
            {document.canDelete && (
              <>
                <div
                  role="menuitem"
                  tabIndex={0}
                  className={trashClassNames}
                  onClick={handleDelete}
                  onKeyDown={handleKeyDownDelete}
                  data-cy="document-list-item-action-delete"
                >
                  <Trash className={trashIconClassName} size="m" /> Delete
                </div>
              </>
            )}
          </div>
        </Overlay>
      </div>
    </td>
  );
};

Context.propTypes = {
  document: DocumentPropType,
  triggerRename: PropTypes.func,
  deleteDocuments: PropTypes.func,
  previewDocument: PropTypes.func,
};

Context.defaultProps = {
  document: {},
  triggerRename: () => {},
  deleteDocuments: () => {},
  previewDocument: () => {},
};

export default Context;
