import React, { useState, useEffect } from "react";
import moment from "moment";
import { shape, number, string, bool } from "prop-types";
import IconButton from "../../../../shared/v2/IconButton";
import VisibilityHidden from "../../../../shared/v2/Icomoon/Icons/VisibilityHidden";
import VisibilityVisible from "../../../../shared/v2/Icomoon/Icons/VisibilityVisible";
import StarFilled from "../../../../shared/v2/Icomoon/Icons/StarFilled";
import Avatar from "../../../../shared/v2/Avatar";
import EllipsisIcon from "../../../../Transactions/components/icons/EllipsisIcon";
import EllipsisDropDown from "../EllipsisDropDown";
import useOnClickOutsideRef from "../api/hooks";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { CURRENTUSERID, ISADMIN } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import LinkifyNotes from "../../../shared/LinkifyNotes/LinkifyNotes";

const SingleNote = ({ note, currentUserId, isAdmin }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);

  const ellipsisModal = useOnClickOutsideRef(() => setOpenDropdown(false));

  useEffect(() => {
    if (openEditor) {
      setOpenDropdown(false);
    }
  }, [openEditor]);

  return (
    <>
      <div className="tw-h-auto tw-border-solid tw-border-[1px] tw-rounded-[8px] tw-border-neutral-gray-15">
        <div className="tw-m-[24px] tw-flex tw-justify-between tw-gap-[16px]">
          <div>
            <div className="tw-flex tw-justify-between">
              <div className="tw-flex tw-gap-[10px]">
                <Avatar
                  size="small"
                  src={note.owner.avatar_file_name}
                  alt={`${note.owner.first_name} ${note.owner.last_name}`}
                />
                <div className="tw-mt-[6px] tw-text-neutral-gray-75 tw-text-[14px] tw-font-semibold tw-leading-5 tw-tracking-normal">{`${note.owner.first_name} ${note.owner.last_name}`}</div>
              </div>
            </div>
            <div className="tw-my-[10px] tw-text-[12px] tw-font-semibold tw-leading-4 tw-tracking-normal tw-text-left tw-text-neutral-gray-50">
              {note.created_at.localeCompare(note.updated_at) !== 0 && "Edited"}
            </div>
            <LinkifyNotes markdownContent={note.markdown_content} />
          </div>
          <div className="tw-flex tw-gap-[5px] tw-flex-col tw-items-end tw-float-right">
            <div>
              {note.starred && <StarFilled size="l" className="tw-text-yellow-50 tw-mr-[8px]" />}
              {note.externally_visible ? <VisibilityVisible size="l" /> : <VisibilityHidden size="l" />}
            </div>

            <div className=" tw-text-[12px] tw-font-semibold tw-leading-[16px] tw-tracking-normal tw-whitespace-no-wrap">
              {moment(note.updated_at).fromNow()}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-[5px] tw-text-[12px] tw-font-normal tw-leading-4 tw-tracking-normal">
              <div className="tw-text-end">{moment(note.updated_at).format("MM/DD/YYYY")}</div>
              <div className="tw-text-end">{moment(note.updated_at).format("hh:mm A")} </div>
            </div>
            {(isAdmin || note.owner.id === currentUserId) && (
              <div ref={ellipsisModal} className="tw-relative tw-mt-[10px]">
                <IconButton
                  type="button"
                  size="small"
                  schema="tertiary"
                  onClick={() => setOpenDropdown(!openDropdown)}
                >
                  <EllipsisIcon />
                </IconButton>
                {openDropdown && (
                  <EllipsisDropDown note={note} openEditor={openEditor} setOpenEditor={setOpenEditor} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default tdpDetails({
  WrappedComponent: SingleNote,
  states: [CURRENTUSERID, ISADMIN],
});

SingleNote.propTypes = {
  note: shape({
    id: number,
    content: string,
    created_at: string,
    owner: {
      first_name: string,
      last_name: string,
      avatar_file_name: string,
      id: number,
    },
  }),
  currentUserId: number,
  isAdmin: bool,
};

SingleNote.defaultProps = {
  note: {
    id: "",
    content: "",
    created_at: "",
    owner: {
      first_name: "",
      last_name: "",
      avatar_file_name: "",
      id: "",
    },
  },
  currentUserId: "",
  isAdmin: false,
};
