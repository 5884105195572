import React from "react";

import Dropdown from "../../../../../shared/v2/Dropdown";
import { customDefaultProps, customPropTypes } from "../../../../../shared/v2/Dropdown/utils";

const MemberMultiValue = ({ componentProps, children }) => (
  <div className="tw-border-0 tw-border-b tw-border-solid tw-border-neutral-gray-10 last:tw-border-b-0 tw-w-full tw-pb-8px first:tw-pt-8px last:tw-pb-0">
    <Dropdown.MultiValue
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...componentProps}
      className="tw-flex tw-justify-between tw-items-center"
    >
      {children}
    </Dropdown.MultiValue>
  </div>
);

MemberMultiValue.propTypes = customPropTypes;

MemberMultiValue.defaultProps = customDefaultProps;

export default MemberMultiValue;
