import React from "react";
import PropTypes from "prop-types";
import Linkify from "linkify-react";

import { runAtLabel } from "../../../../../../AssignedPlans/components/helpers";

import ActionDetailField from "./ActionDetailField";
import ActionRunAtFields from "./ActionRunAtFields";

import { eventDetailStringFor, shouldShowContingencyInfoFor, shouldShowEventFieldFor } from "./helpers";

const EmailBody = ({ contents }) => (
  <ActionDetailField name="Email Body">
    <p
      // This is how the old Assigned Plan interaction detail component renders
      // the email's body, so I _assume_ that `action.templateBody` is safe. If
      // it's not, this is possibly a security pitfall that we should look into
      // ASAP!
      //
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: contents }}
    />
  </ActionDetailField>
);
EmailBody.propTypes = { contents: PropTypes.string.isRequired };

const TextBody = ({ contents }) => (
  <ActionDetailField name="Text Message">
    <Linkify
      className="tw-whitespace-pre-wrap"
      tagName="p"
      options={{ defaultProtocol: "https", target: "_blank" }}
    >
      {contents}
    </Linkify>
  </ActionDetailField>
);
TextBody.propTypes = { contents: PropTypes.string.isRequired };

/** Details for an action of either "Email" or "Text" type. */
const EmailOrTextActionDetails = ({ action }) => (
  <>
    {action.type === "Email" && (
      <div className="tw-pb-12px tw-border-solid tw-border-0 tw-border-b tw-border-neutral-gray-10">
        <ActionDetailField name="Email Subject">
          <div className="tw-text-18d">{action.name}</div>
        </ActionDetailField>
      </div>
    )}

    <div className="tw-py-12px tw-border-solid tw-border-0 tw-border-b tw-border-neutral-gray-10">
      <div className="tw-grid tw-grid-cols-3 tw-gap-12px tw-justify-between">
        <ActionDetailField name="Send From" value={action.assignedToLabel} />
        <ActionDetailField name="Send To" value={action.assignedForLabel} />
        <ActionDetailField
          name="Contingent"
          value={action.parentAction?.name}
          hideUnless={shouldShowContingencyInfoFor(action)}
        />
        <ActionDetailField name={runAtLabel(action.type, action.state)}>
          <ActionRunAtFields
            actionState={action.state}
            actionCompletedAt={action.completedAt}
            actionRunAt={action.runAt}
          />
        </ActionDetailField>
        <ActionDetailField
          name="Event"
          value={eventDetailStringFor(action)}
          hideUnless={shouldShowEventFieldFor(action)}
        />
      </div>
    </div>

    {action.type === "Text" && action.state === "failed" && action.failedDescription && (
      <div className="col-xs-12 tw-my-12px tw-px-0">
        <div
          className={`tw-py-12px tw-px-16px ${action.failedDescriptionType === "error" ? "tw-bg-semantic-red-5" : "tw-bg-semantic-yellow-5"}`}
        >
          <p className="tw-text-14d tw-text-semantic-yellow-120 tw-font-semibold tw-mb-[5px]">
            {action.failedDescription_type === "error" ? "Text Failure" : "Possible Failure"}
          </p>
          <p className="tw-mb-0">{action.failedDescription}</p>
        </div>
      </div>
    )}

    <div className="tw-py-12px">
      {action.type === "Email" && <EmailBody contents={action.templateBody} />}
      {action.type === "Text" && <TextBody contents={action.templateBody} />}
    </div>
  </>
);

EmailOrTextActionDetails.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,

    completedAt: PropTypes.string,
    runAt: PropTypes.string,

    assignedForLabel: PropTypes.string.isRequired,
    assignedToLabel: PropTypes.string.isRequired,

    parentAction: PropTypes.shape({ name: PropTypes.string.isRequired }),
    templateBody: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmailOrTextActionDetails;
