/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TrashCanSolidV6 = (props) => (
  <Icon {...props} name="Trash Can Solid V6">
    <path d="M5.225.553A1 1 0 0 1 6.119 0h3.763a1 1 0 0 1 .894.553l.225.447h3a1 1 0 1 1 0 2h-12a1 1 0 0 1 0-2h3l.225-.447zM1.972 4H14v10c0 1.103-.897 2-2 2H3.972c-1.077 0-2-.897-2-2V4zm2.5 2.5v7c0 .275.253.5.5.5.303 0 .5-.225.5-.5v-7c0-.275-.197-.5-.5-.5a.52.52 0 0 0-.5.5zm3 0v7c0 .275.253.5.5.5.303 0 .528-.225.528-.5v-7c0-.275-.225-.5-.528-.5a.52.52 0 0 0-.5.5zm3.028 0v7c0 .275.225.5.5.5s.5-.225.5-.5v-7c0-.275-.225-.5-.5-.5s-.5.225-.5.5z" />
  </Icon>
);

export default TrashCanSolidV6;
