import React from "react";
import PropTypes from "prop-types";
import FieldLabel from "@shared/v2/FieldLabel";
import TextInput from "@shared/v2/TextInput";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import FlatPercentToggle from "@shared/v2/FlatPercentToggle";
import FlatPercentInput from "@shared/v2/FlatPercentInput";
import { formatNumber } from "@shared/v2/inputFormatUtils";
import TextComboInput from "@shared/v2/TextComboInput";

const formatNumberIfFlat = (value, type, commas) => (type === "flat" ? formatNumber(value, commas) : value);

const BuyerorSellerTransactionsField = ({
  transactionTitle,
  setTransactionTitle,
  selectedSource,
  setSelectedSource,
  sources,
  closePrice,
  setClosePrice,
  commission,
  setCommission,
  isCommissionPercentage,
  setIsCommissionPercentage,
  propertyType,
  setPropertyType,
  propertyTypes,
  setPrimaryAgent,
  primaryAgent,
  primaryAgents,
}) => (
  <div>
    <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
      <div className="tw-col-span-1">
        <FieldLabel label="Transaction Title" isRequired className="tw-mb-3" />
        <TextInput value={transactionTitle} onChange={(e) => setTransactionTitle(e.target.value)} />
      </div>
      <div className="tw-col-span-1">
        <Dropdown
          menuShouldComeToFront
          label="Primary Agent"
          options={primaryAgents}
          value={primaryAgent}
          onChange={(val) =>
            setPrimaryAgent((prevDetails) => ({
              ...prevDetails,
              label: val.label,
              value: val.value,
            }))
          }
        />
      </div>
    </div>
    <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
      <div className="tw-col-span-1">
        <Dropdown
          menuShouldComeToFront
          label="Property Type"
          className="tw-z-50"
          onChange={(val) => setPropertyType(val)}
          value={propertyType}
          options={propertyTypes}
        />
      </div>
      <div className="tw-col-span-1">
        <Dropdown
          menuShouldComeToFront
          isSearchable
          className="tw-z-500"
          label="Source"
          onChange={(val) => setSelectedSource(val)}
          value={selectedSource}
          options={sources}
          isRequired
        />
      </div>
    </div>
    <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
      <div className="tw-col-span-1">
        <TextComboInput
          label="Est. Close Price"
          leading="$"
          placeholder="0.00"
          value={closePrice}
          onBlur={(e) => setClosePrice(formatNumber(e.target.value, true))}
          onChange={(e) => setClosePrice(e.target.value)}
        />
      </div>
      <div className="tw-col-span-1 ">
        <div className="tw-flex tw-items-center tw-gap-[4px] tw-w-[184px]">
          <FlatPercentInput
            className="tw-w-[184px]"
            label="Commission"
            value={formatNumberIfFlat(commission, isCommissionPercentage ? "percent" : "flat", true)}
            placeholder="0.00"
            isPercentage={isCommissionPercentage}
            onChange={(e) => {
              const value = e.target.value.trim() || null;
              setCommission(value);
            }}
          />
          <FlatPercentToggle
            className="tw-mt-28px"
            isPercentage={isCommissionPercentage}
            onChange={(_, newIsPercentage) => {
              setCommission("");
              setIsCommissionPercentage(newIsPercentage);
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

const optionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const optionShapePrimaryAgent = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.string,
  primary: PropTypes.string,
});

BuyerorSellerTransactionsField.propTypes = {
  transactionTitle: PropTypes.string,
  setTransactionTitle: PropTypes.func,
  selectedSource: optionShape,
  setSelectedSource: PropTypes.func,
  closePrice: PropTypes.string,
  setClosePrice: PropTypes.func,
  commission: PropTypes.string,
  setCommission: PropTypes.func,
  propertyType: optionShape,
  setPropertyType: PropTypes.func,
  isPercentage: PropTypes.bool,
  setIsCommissionPercentage: PropTypes.func,
  primaryAgents: PropTypes.arrayOf(optionShape),
  primaryAgent: PropTypes.arrayOf(optionShapePrimaryAgent),
  setPrimaryAgent: PropTypes.func,
  sources: PropTypes.arrayOf(optionShape),
  propertyTypes: PropTypes.arrayOf(optionShape),
};
BuyerorSellerTransactionsField.defaultProps = {
  transactionTitle: "",
  selectedSource: null,
  closePrice: "",
  commission: "",
  propertyType: null,
  sources: [],
  propertyTypes: [],
  isPercentage: true,
  primaryAgents: [],
  primaryAgent: null,
  setPrimaryAgent: () => {},
  setIsCommissionPercentage: () => {},
  setTransactionTitle: () => {},
  setSelectedSource: () => {},
  setClosePrice: () => {},
  setCommission: () => {},
  setPropertyType: () => {},
};
export default BuyerorSellerTransactionsField;
