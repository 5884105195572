import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import ListingCard from "./ListingCard/ListingCard";
import Listing from "../../models/Listing";

const getStyle = ({ draggableStyle, virtualStyle }) => {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  return combined;
};

const Item = ({
  item,
  provided,
  isDragging,
  onStatusChanged,
  updateListing,
  sources,
  onDeletion,
  permissions,
}) => (
  <div
    // We don't care about libraries' internals
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...provided.draggableProps}
    // We don't care about libraries' internals
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...provided.dragHandleProps}
    ref={provided.innerRef}
    style={getStyle({
      draggableStyle: provided.draggableProps.style,
      isDragging,
    })}
    className={`
          item ${isDragging ? "is-dragging" : ""}
        `}
  >
    <ListingCard
      listing={item}
      onStatusChanged={onStatusChanged}
      updateListing={updateListing}
      sources={sources}
      onDeletion={onDeletion}
      permissions={permissions}
    />
  </div>
);

Item.propTypes = {
  item: PropTypes.instanceOf(Listing),
  // This comes from the library, so we don't much care about internals
  // eslint-disable-next-line react/forbid-prop-types
  provided: PropTypes.any,
  isDragging: PropTypes.bool,
  editFinancials: PropTypes.bool,
  onStatusChanged: PropTypes.func,
  onDeletion: PropTypes.func,
  updateListing: PropTypes.func,
  // No Source class
  // eslint-disable-next-line react/forbid-prop-types
  sources: PropTypes.array,
  permissions: PropTypes.shape({}),
};

Item.defaultProps = {
  item: null,
  provided: null,
  isDragging: false,
  editFinancials: null,
  onStatusChanged: null,
  onDeletion: null,
  updateListing: null,
  permissions: null,
  sources: [],
};
export default Item;
