/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import TransactionTitle from "../../shared/TransactionTitle";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  TRANSACTION_TITLE,
  STREET_ADDRESS,
  CITY,
  POSTAL_CODE,
  LOCALITY,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

const ReferralTitle = ({ ...props }) => <TransactionTitle className="tw-mb-8px" {...props} />;

export default tdpDetails({
  WrappedComponent: ReferralTitle,
  states: [LOCALITY, STREET_ADDRESS, TRANSACTION_TITLE, CITY, POSTAL_CODE],
  aliases: ["locality", "streetAddress"],
});
