import React from "react";
import PropTypes from "prop-types";

const stateToTextColorMap = {
  running: "tw-text-semantic-green-100",
  paused: "tw-text-semantic-yellow-100",
  completed: "tw-text-semantic-blue-100",
  deleted: "tw-text-semantic-red-100",
};

const AssignedPlanStateLabel = ({ state }) => (
  <div
    className={`tw-mb-[5px] tw-uppercase tw-font-semibold tw-text-11d tw-text-end ${stateToTextColorMap[state]}`}
    data-cy="plan-state-label"
  >
    {state}
  </div>
);

AssignedPlanStateLabel.propTypes = { state: PropTypes.oneOf(Object.keys(stateToTextColorMap)).isRequired };

export default AssignedPlanStateLabel;
