import React from "react";
import PropTypes from "prop-types";
import PersonResult from "./PersonResult";

const PeopleResults = ({
  searchResults,
  onHover,
  onClickHandle,
  hasResults,
  searchValid,
  searchTerm,
  searchTermLength,
  drodownClassName,
}) => {
  return (
    <div
      className={`tw-mt-[4px] tw-rounded-sm tw-border-solid tw-border tw-border-gray-15 tw-shadow-bootstrap-18 tw-z-10 tw-overflow-y-scroll ${drodownClassName}`}
    >
      {!searchValid && (
        <div className={`${drodownClassName ? "" : "tw-h-[32px]"} tw-px-[12px] tw-py-[6px]`}>
          Enter {1 - searchTermLength} more characters to search
        </div>
      )}

      {hasResults &&
        searchResults
          .filter((contact) => contact.label !== null)
          .map((result, i) => {
            return (
              <PersonResult
                drodownClassName={drodownClassName}
                key={result.object_id}
                result={result}
                index={i}
                onHover={onHover}
                onClickHandle={onClickHandle}
              />
            );
          })}
      {!hasResults && searchValid && (
        <div className={`${drodownClassName ? "" : "tw-h-[32px]"} tw-px-[12px] tw-py-[6px]`}>
          No results for <b>{searchTerm}</b>
        </div>
      )}
    </div>
  );
};

PeopleResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  onHover: PropTypes.func,
  onClickHandle: PropTypes.func,
  hasResults: PropTypes.bool,
  searchValid: PropTypes.bool,
  searchTerm: PropTypes.string,
  searchTermLength: PropTypes.number,
  drodownClassName: PropTypes.string,
};
PeopleResults.defaultProps = {
  searchResults: [],
  onHover: () => {},
  onClickHandle: () => {},
  drodownClassName: null,
  hasResults: false,
  searchValid: false,
  searchTerm: "",
  searchTermLength: 0,
};
export default PeopleResults;
