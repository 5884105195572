/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneSolidV6 = (props) => (
  <Icon {...props} name="Phone Solid V6">
    <path d="m15.975 12.094-.727 3.15a.971.971 0 0 1-.952.757C6.412 16-.001 9.588-.001 1.703c0-.458.312-.85.757-.952l3.15-.727A.981.981 0 0 1 5.024.59l1.454 3.391a.978.978 0 0 1-.281 1.139L4.514 6.471a10.928 10.928 0 0 0 4.984 4.984l1.377-1.681a.972.972 0 0 1 1.14-.28l3.391 1.453c.408.215.673.69.567 1.146z" />
  </Icon>
);

export default PhoneSolidV6;
