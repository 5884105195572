import { addMinutes, endOfDay, format, isBefore, startOfDay } from "date-fns";
import { useMemo } from "react";

const getTimeOptions = () => {
  const options = [];
  let startTime = startOfDay(new Date());
  const endTime = endOfDay(new Date());

  while (isBefore(startTime, endTime)) {
    options.push({ value: format(startTime, "HH:mm:ss"), label: format(startTime, "h:mm a") });
    startTime = addMinutes(startTime, 15);
  }

  return options;
};

const useTimeOptions = () => useMemo(getTimeOptions, []);

export default useTimeOptions;
