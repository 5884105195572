import React from "react";

import FinancialDetails from "../FinancialDetails";
import SpecificDetails from "../SpecificDetails";
import EllipsesComponents from "../EllipsesComponents";

const TransactionDetails = () => (
  <div className="tw-flex">
    <FinancialDetails />
    <SpecificDetails />
    <EllipsesComponents />
  </div>
);

export default TransactionDetails;
