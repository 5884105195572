import React, { useMemo } from "react";
import { calculatePercent, calculateCallsTotals } from "./reportHelpers";

const GENERAL_HEADERS = [
  { key: "agent", label: "Name" },
  { key: "appointment_report_total_calls", label: "Called" },
  {
    key: "appointment_report_contacted_count",
    label: "Contacted",
    tooltip: 'Based on the call outcome of "Talked to Contact"',
  },
  { key: "appointment_report_total_set", label: "Total Set" },
  { key: "appointment_report_total_held", label: "Total Held" },
  {
    key: "appointment_report_total_signed",
    label: "Total Signed",
  },
  {
    key: "appointment_report_total_pending",
    label: "Total Pending",
  },
  {
    key: "appointment_report_total_sold",
    label: "Total Sold",
  },
  {
    key: "appointment_report_cancel",
    label: "Cancel",
    border: true,
  },
  {
    key: "appointment_report_no_show",
    label: "Total Canceled",
  },
];

const BUYER_HEADERS = [
  {
    key: "appointment_report_buyer_set",
    label: "Buyer Set",
    border: true,
  },
  {
    key: "appointment_report_buyer_held",
    label: "Buyer Held",
  },
  {
    key: "appointment_report_buyer_signed",
    label: "Buyer Signed",
  },
];

const SELLER_HEADERS = [
  {
    key: "appointment_report_seller_set",
    label: "Seller Set",
    border: true,
  },
  {
    key: "appointment_report_seller_held",
    label: "Seller Held",
  },
  {
    key: "appointment_report_seller_signed",
    label: "Seller Signed",
  },
];

const BUYER_SELLER_HEADERS = [
  {
    key: "appointment_report_buyer_seller_set",
    label: "Buyer/Seller Set",
    border: true,
  },
  {
    key: "appointment_report_buyer_seller_held",
    label: "Buyer/Seller Held",
  },
  {
    key: "appointment_report_buyer_seller_signed",
    label: "Buyer/Seller Signed",
  },
];

const RECRUIT_HEADERS = [
  {
    key: "appointment_report_recruit_set",
    label: "Recruit Set",
    border: true,
  },
  {
    key: "appointment_report_recruit_held",
    label: "Recruit Held",
  },
  {
    key: "appointment_report_recruit_signed",
    label: "Recruit Signed",
  },
];

const OTHER_HEADERS = [
  {
    key: "appointment_report_other_set",
    label: "Other Set",
    border: true,
  },
  {
    key: "appointment_report_other_held",
    label: "Other Held",
  },
];

export const StatCard = ({ data }) => (
  <div
    className={`tw-shadow tw-rounded-[8px] tw-flex tw-flex-col tw-gap-[8px] tw-justify-center tw-items-center tw-min-w-[140px] tw-min-h-[140px] ${
      data.value == 0 ? "tw-bg-neutral-gray-2.5 tw-text-neutral-gray-50" : "tw-text-theme-primary"
    }`}
  >
    <span className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold">{data.label}</span>
    <span className="tw-text-24d tw-font-semibold">{data.value}</span>
    <span className="tw-text-12d tw-text-neutral-gray-50 tw-font-semibold">
      {data.label === "Called" ? data.tag : ""}
    </span>
  </div>
);

export function statCardsTotal(apptFilters, agents, dateRange) {
  const buyerSetTotal = calculateCallsTotals(agents, "appointment_report_buyer_set");
  const buyerSet = apptFilters.isBuyer ? buyerSetTotal : 0;
  const sellerSetTotal = calculateCallsTotals(agents, "appointment_report_seller_set");
  const sellerSet = apptFilters.isSeller ? sellerSetTotal : 0;
  const buyerSellerSetTotal = calculateCallsTotals(agents, "appointment_report_buyer_seller_set");
  const buyerSellerSet = apptFilters.isBuyerSeller ? buyerSellerSetTotal : 0;
  const recruitSetTotal = calculateCallsTotals(agents, "appointment_report_recruit_set");
  const recruitSet = apptFilters.isRecruit ? recruitSetTotal : 0;
  const otherSet = apptFilters.isOther
    ? calculateCallsTotals(agents, "appointment_report_total_set") -
      (buyerSetTotal + sellerSetTotal + buyerSellerSetTotal + recruitSetTotal)
    : 0;
  const buyerHeldTotal = calculateCallsTotals(agents, "appointment_report_buyer_held");
  const buyerHeld = apptFilters.isBuyer ? buyerHeldTotal : 0;
  const sellerHeldTotal = calculateCallsTotals(agents, "appointment_report_seller_held");
  const sellerHeld = apptFilters.isSeller ? sellerHeldTotal : 0;
  const buyerSellerHeldTotal = calculateCallsTotals(agents, "appointment_report_buyer_seller_held");
  const buyerSellerHeld = apptFilters.isBuyerSeller ? buyerSellerHeldTotal : 0;
  const recruitHeldTotal = calculateCallsTotals(agents, "appointment_report_recruit_held");
  const recruitHeld = apptFilters.isRecruit ? recruitHeldTotal : 0;
  const otherHeld = apptFilters.isOther
    ? calculateCallsTotals(agents, "appointment_report_total_held") -
      (buyerHeldTotal + sellerHeldTotal + buyerSellerHeldTotal + recruitHeldTotal)
    : 0;
  const buyerSellerSignedTotal = calculateCallsTotals(agents, "appointment_report_buyer_seller_signed");
  const buyerSellerSigned = apptFilters.isBuyerSeller ? buyerSellerSignedTotal : 0;
  const buyerSignedTotal = calculateCallsTotals(agents, "appointment_report_buyer_signed");
  const buyerSigned = apptFilters.isBuyer ? buyerSignedTotal : 0;
  const sellerSignedTotal = calculateCallsTotals(agents, "appointment_report_seller_signed");
  const sellerSigned = apptFilters.isSeller ? sellerSignedTotal : 0;
  const recruitSignedTotal = calculateCallsTotals(agents, "appointment_report_recruit_signed");
  const recruitSigned = apptFilters.isRecruit ? recruitSignedTotal : 0;
  const otherSigned = apptFilters.isOther
    ? calculateCallsTotals(agents, "appointment_report_total_signed") -
      (buyerSellerSigned + buyerSignedTotal + sellerSignedTotal + recruitSignedTotal)
    : 0;
  const totalCalls = calculateCallsTotals(agents, "appointment_report_total_calls");
  const totalContacted = calculateCallsTotals(agents, "appointment_report_contacted_count");
  const cancelled = calculateCallsTotals(agents, "appointment_report_cancel");
  const noShow = calculateCallsTotals(agents, "appointment_report_no_show");
  const buyerPending = calculateCallsTotals(agents, "appointment_report_buyer_pending");
  const sellerPending = calculateCallsTotals(agents, "appointment_report_seller_pending");
  const buyerSold = calculateCallsTotals(agents, "appointment_report_buyer_sold");
  const sellerSold = calculateCallsTotals(agents, "appointment_report_seller_sold");

  const totalApptSet = buyerSet + sellerSet + recruitSet + buyerSellerSet + otherSet;
  const totalApptHeld = buyerHeld + sellerHeld + recruitHeld + buyerSellerHeld + otherHeld;
  const totalApptSigned = buyerSellerSigned + buyerSigned + sellerSigned + recruitSigned + otherSigned;
  const totalUnderContract = buyerPending + sellerPending;
  const totalClosed = buyerSold + sellerSold;

  const leadStats = [
    { label: "Called", value: totalCalls, tag: dateRange.split("_").join(" ") },
    { label: "Contacted", value: totalContacted, denom: totalCalls },
    { label: "Appt. Set", value: totalApptSet, denom: totalContacted },
    { label: "Appt. Held", value: totalApptHeld, denom: totalApptSet },
    { label: "Signed", value: totalApptSigned, denom: totalApptHeld },
    { label: "Pending", value: totalUnderContract, denom: totalUnderContract },
    { label: "Sold", value: totalClosed, denom: totalApptSigned },
    { label: "Buyer Pending", value: buyerPending, denom: totalUnderContract },
    { label: "Seller Pending", value: sellerPending, denom: totalUnderContract },
    { label: "Buyer Sold", value: buyerSold, denom: totalClosed },
    { label: "Seller Sold", value: sellerSold, denom: totalClosed },
    { label: "Buyer Set", value: buyerSet, denom: totalApptSet },
    { label: "Seller Set", value: sellerSet, denom: totalApptSet },
    { label: "Buyer/Seller Set", value: buyerSellerSet, denom: totalApptSet },
    { label: "Recruit Set", value: recruitSet, denom: totalApptSet },
    { label: "Other Set", value: otherSet, denom: totalApptSet },
    { label: "Buyer Held", value: buyerHeld, denom: totalApptHeld },
    { label: "Seller Held", value: sellerHeld, denom: totalApptHeld },
    { label: "Buyer/Seller Held", value: buyerSellerHeld, denom: totalApptHeld },
    { label: "Recruit Held", value: recruitHeld, denom: totalApptHeld },
    { label: "Other Held", value: otherHeld, denom: totalApptHeld },
    { label: "Buyer Signed", value: buyerSigned, denom: totalApptSigned },
    { label: "Seller Signed", value: sellerSigned, denom: totalApptSigned },
    { label: "Buyer/Seller Signed", value: buyerSellerSigned, denom: totalApptSigned },
    { label: "Recruit Signed", value: recruitSigned, denom: totalApptSigned },
    { label: "Other Signed", value: otherSigned, denom: totalApptSigned },
    { label: "Canceled", value: cancelled, denom: totalApptSet },
    { label: "No Show", value: noShow, denom: totalApptSet },
  ];
  leadStats.slice(1).forEach((stat) => {
    stat.tag = `${calculatePercent(stat.denom, stat.value).toFixed(2)}%`;
  });
  return leadStats;
}

const tooltipGenerator = (stat) =>
  stat.slice(1, 6).map((item, index) => (
    <span key={index}>
      <p> {`${item.value}  (${item.tag})  ${item.label.split(" ")[0]}s`} </p>
    </span>
  ));

const tooltipGeneratorForTransactionsData = (stat) =>
  stat.slice(1, 3).map((item, index) => (
    <span key={index}>
      <p> {`${item.value}  (${item.tag})  ${item.label.split(" ")[0]}s`} </p>
    </span>
  ));

export const ToolTipContent = ({ stat, leadStats }) => {
  const tooltipHeaders = [
    { key: "set" },
    { key: "held" },
    { key: "signed" },
    { key: "called" },
    { key: "contacted" },
    { key: "pending" },
    { key: "sold" },
  ].map((item) => ({
    ...item,
    data: leadStats.filter((stat) => stat.label.toLowerCase().includes(item.key)),
  }));

  return (
    <>
      {tooltipHeaders.map((item) => {
        if (
          (item.key === "called" && stat.label === "Called") ||
          (item.key === "contacted" && stat.label === "Contacted")
        ) {
          return (
            <span key={item.data[0].label}>
              <p> {`${item.data[0].value}  (${item.data[0].tag}) `}</p>
            </span>
          );
        }
        if (
          (stat.label === "Appt. Set" && item.key === "set") ||
          (stat.label === "Appt. Held" && item.key === "held") ||
          (stat.label === "Signed" && item.key === "signed")
        ) {
          return tooltipGenerator(item.data);
        }
        if (
          (stat.label === "Pending" && item.key === "pending") ||
          (stat.label === "Sold" && item.key === "sold")
        ) {
          return tooltipGeneratorForTransactionsData(item.data);
        }
      })}
    </>
  );
};

export function transformAppointmentRow(report, apptFilters) {
  const buyerSet = apptFilters.isBuyer ? report.appointment_report_buyer_set : 0;
  const sellerSet = apptFilters.isSeller ? report.appointment_report_seller_set : 0;
  const buyerSellerSet = apptFilters.isBuyerSeller ? report.appointment_report_buyer_seller_set : 0;
  const recruitSet = apptFilters.isRecruit ? report.appointment_report_recruit_set : 0;
  const buyerHeld = apptFilters.isBuyer ? report.appointment_report_buyer_held : 0;
  const sellerHeld = apptFilters.isSeller ? report.appointment_report_seller_held : 0;
  const buyerSellerHeld = apptFilters.isBuyerSeller ? report.appointment_report_buyer_seller_held : 0;
  const recruitHeld = apptFilters.isRecruit ? report.appointment_report_recruit_held : 0;
  const buyerSigned = apptFilters.isBuyer ? report.appointment_report_buyer_signed : 0;
  const sellerSigned = apptFilters.isSeller ? report.appointment_report_seller_signed : 0;
  const buyerSellerSigned = apptFilters.isBuyerSeller ? report.appointment_report_buyer_seller_signed : 0;
  const recruitSigned = apptFilters.isRecruit ? report.appointment_report_recruit_signed : 0;
  const buyerUnderContract = apptFilters.isBuyer ? report.transactions.pending.buyer : 0;
  const sellerUnderContract = apptFilters.isSeller ? report.transactions.pending.seller : 0;
  const buyerSold = apptFilters.isBuyer ? report.transactions.sold.buyer : 0;
  const sellerSold = apptFilters.isSeller ? report.transactions.sold.seller : 0;
  const buyerCanceled = apptFilters.isBuyer ? report.appointment_report_cancel_buyer : 0;
  const sellerCanceled = apptFilters.isSeller ? report.appointment_report_cancel_seller : 0;
  const recruitCanceled = apptFilters.isRecruit ? report.appointment_report_cancel_recruit : 0;
  const buyerNoShow = apptFilters.isBuyer ? report.appointment_report_no_show_buyer : 0;
  const sellerNoShow = apptFilters.isSeller ? report.appointment_report_no_show_seller : 0;
  const recruitNoShow = apptFilters.isRecruit ? report.appointment_report_no_show_recruit : 0;

  const otherSet = apptFilters.isOther
    ? report.appointment_report_total_set -
      (report.appointment_report_buyer_set +
        report.appointment_report_seller_set +
        report.appointment_report_buyer_seller_set +
        report.appointment_report_recruit_set)
    : 0;
  const otherHeld = apptFilters.isOther
    ? report.appointment_report_total_held -
      (report.appointment_report_buyer_held +
        report.appointment_report_seller_held +
        report.appointment_report_buyer_seller_held +
        report.appointment_report_recruit_held)
    : 0;
  const otherSigned = apptFilters.isOther
    ? report.appointment_report_total_signed -
      (report.appointment_report_buyer_signed +
        report.appointment_report_seller_signed +
        report.appointment_report_buyer_seller_signed +
        report.appointment_report_recruit_signed)
    : 0;
  const otherCanceled = apptFilters.isOther
    ? report.appointment_report_cancel -
      (report.appointment_report_cancel_buyer +
        report.appointment_report_cancel_seller +
        report.appointment_report_cancel_buyer_seller +
        report.appointment_report_cancel_recruit)
    : 0;
  const otherNoShow = apptFilters.isOther
    ? report.appointment_report_no_show -
      (report.appointment_report_no_show_buyer +
        report.appointment_report_no_show_seller +
        report.appointment_report_no_show_buyer_seller +
        report.appointment_report_no_show_recruit)
    : 0;

  const totalSet = buyerSet + sellerSet + buyerSellerSet + recruitSet + otherSet;
  const totalHeld = buyerHeld + sellerHeld + buyerSellerHeld + recruitHeld + otherHeld;
  const totalSigned = buyerSigned + sellerSigned + recruitSigned + otherSigned;
  const totalUnderContract = buyerUnderContract + sellerUnderContract;
  const totalClosed = buyerSold + sellerSold;
  const totalCanceled = buyerCanceled + sellerCanceled + recruitCanceled + otherCanceled;
  const totalNoShow = buyerNoShow + sellerNoShow + recruitNoShow + otherNoShow;

  const totalSetBundle = [
    { label: "buyer", value: buyerSet, color: "#59C4C4" },
    { label: "seller", value: sellerSet, color: "#FFA826" },
    { label: "buyer/seller", value: buyerSellerSet, color: "#F2714D" },
    { label: "recruit", value: recruitSet, color: "#66666" },
    { label: "other", value: otherSet, color: "333333" },
  ];
  const totalHeldBundle = [
    { label: "buyer", value: buyerHeld, color: "#59C4C4" },
    { label: "seller", value: sellerHeld, color: "#FFA826" },
    { label: "buyer/seller", value: buyerSellerHeld, color: "#F2714D" },
    { label: "recruit", value: recruitHeld, color: "#66666" },
    { label: "other", value: otherHeld, color: "333333" },
  ];
  const totalSignedBundle = [
    { label: "buyer", value: buyerSigned, color: "#59C4C4" },
    { label: "seller", value: sellerSigned, color: "#FFA826" },
    { label: "buyer/seller", value: buyerSellerSigned, color: "#F2714D" },
    { label: "recruit", value: recruitSigned, color: "#66666" },
    { label: "other", value: otherSigned, color: "333333" },
  ];
  const totalUnderContractBundle = [
    { label: "buyer", value: buyerUnderContract, color: "#59C4C4" },
    { label: "seller", value: sellerUnderContract, color: "#FFA826" },
  ];
  const totalSoldBundle = [
    { label: "buyer", value: buyerSold, color: "#59C4C4" },
    { label: "seller", value: sellerSold, color: "#FFA826" },
  ];
  const totalCanceledBundle = [
    { label: "buyer", value: buyerCanceled, color: "#59C4C4" },
    { label: "seller", value: sellerCanceled, color: "#FFA826" },
    { label: "recruit", value: recruitCanceled, color: "#66666" },
    { label: "other", value: otherCanceled, color: "333333" },
  ];
  const totalNoShowBundle = [
    { label: "buyer", value: buyerNoShow, color: "#59C4C4" },
    { label: "seller", value: sellerNoShow, color: "#FFA826" },
    { label: "recruit", value: recruitNoShow, color: "#66666" },
    { label: "other", value: otherNoShow, color: "333333" },
  ];

  const orderedCells = [
    { avatar: report.avatar, agent: report.agent, userPath: report.user_path, id: report.id },
    { value: report.appointment_report_total_calls },
    { value: report.appointment_report_contacted_count },
    { value: totalSet, dropDown: totalSetBundle },
    { value: totalHeld, dropDown: totalHeldBundle },
    { value: totalSigned, dropDown: totalSignedBundle },
    { value: totalUnderContract, dropDown: totalUnderContractBundle },
    { value: totalClosed, dropDown: totalSoldBundle },
    { value: totalCanceled, dropDown: totalCanceledBundle },
    { value: totalNoShow, dropDown: totalNoShowBundle },
  ];

  if (apptFilters.isBuyer) {
    orderedCells.push({ value: buyerSet }, { value: buyerHeld }, { value: buyerSigned });
  }
  if (apptFilters.isSeller) {
    orderedCells.push({ value: sellerSet }, { value: sellerHeld }, { value: sellerSigned });
  }
  if (apptFilters.isBuyerSeller) {
    orderedCells.push({ value: buyerSellerSet }, { value: buyerSellerHeld }, { value: buyerSellerSigned });
  }
  if (apptFilters.isRecruit) {
    orderedCells.push({ value: recruitSet }, { value: recruitHeld }, { value: recruitSigned });
  }
  if (apptFilters.isOther) {
    orderedCells.push({ value: otherSet }, { value: otherHeld });
  }
  return orderedCells;
}

export const useAppointmentsData = ({ appointmentData = [], apptFilters, sortKey, sortBy, sortDesc }) => {
  const headersRow = useMemo(() => {
    const headers = [...GENERAL_HEADERS];
    if (apptFilters.isBuyer) {
      headers.push(...BUYER_HEADERS);
    }
    if (apptFilters.isSeller) {
      headers.push(...SELLER_HEADERS);
    }
    if (apptFilters.isBuyerSeller) {
      headers.push(...BUYER_SELLER_HEADERS);
    }
    if (apptFilters.isRecruit) {
      headers.push(...RECRUIT_HEADERS);
    }
    if (apptFilters.isOther) {
      headers.push(...OTHER_HEADERS);
    }
    return headers;
  }, [apptFilters]);

  const appointmentRows = useMemo(
    () => appointmentData.map((row) => transformAppointmentRow(row, apptFilters)),
    [appointmentData, apptFilters, sortKey, sortBy, sortDesc],
  );
  const appointmentTotalRow = useMemo(
    () =>
      appointmentRows.reduce(
        (acc, row) =>
          acc.map((item, index) => ({
            value: item.value + row[index + 1].value,
          })),
        Array(appointmentRows?.[0]?.length - 1 || 0).fill({ value: 0 }),
      ),
    [appointmentRows],
  );

  return { appointmentRows, appointmentTotalRow, headersRow };
};
