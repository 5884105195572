import React from "react";

import { getStatusValues } from "./styleHelpers";

const ListingStatus = ({ status, includeIcon = true }) => {
  const { text, color, backgroundColor } = getStatusValues(status);

  return (
    <div className="tw-inline-flex tw-space-x-2 tw-items-center">
      {includeIcon && <div className={`tw-rounded-full tw-h-8px tw-w-8px tw-flex-1 ${backgroundColor}`}></div>}
      <span className={`tw-flex-1 ${color}`}>
        {text}
      </span>
    </div>
  );
};

export default ListingStatus;
