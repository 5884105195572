import React from "react";
import { bool, func, string, shape } from "prop-types";
import { connect } from "react-redux";
import Tooltip from "@shared/v2/Tooltip";
import { CircleCheckSolidV6, TriangleExclamationSolidV6 } from "@shared/v2/Icomoon/Icons";

import InlineEditingInput from "../../../../shared/v2/InlineEditingInput/InlineEditingInput";
import { putDetailAsThunk } from "../../actions/thunks";
import TextButton from "../../../../shared/v2/TextButton";
import { Plus } from "../../../../shared/v2/Icomoon";
import { TYPE_STYLE, TYPE_TEXT } from "./constants";

const DIGIT_TEST = /^\d+/;

const AddressInfo = ({
  uuid,
  type,
  propertyType,
  title,
  useCustomTitle,
  streetAddress,
  city,
  locality,
  postalCode,
  beds,
  baths,
  sqft,
  daysOnMarket,
  source,
  mlsNumber,
  validMlsNumber,
  onPutDetailAsThunk,
  setRename,
}) => (
  <div className="tw-flex flex-column tw-justify-between tw-my-[29px]">
    <div className="tw-inline-flex tw-text-[14px] tw-capitalize">
      <div
        className={`tw-uppercase tw-border-[2px] tw-border-solid  tw-mr-[12px] tw-flex tw-justify-center tw-items-center tw-text-center tw-h-[24px] tw-px-8px tw-py-4px tw-rounded tw-font-bold tw-leading-[16px] tw-tracking-normal tw-text-center tw-text-[11px] ${TYPE_STYLE[type]}`}
      >
        {TYPE_TEXT[type]}
      </div>
      <div className="tw-text-gray-10">|</div>
      <div className="tw-capitalize tw-mt-[1px] tw-ml-[8px] tw-text-[14px] tw-font-semibold tw-leading-[20px] tw-tracking-normal tw-text-neutral-gray-50">
        {propertyType?.name}
      </div>
    </div>
    <div className="tw-mb-[10px]">
      <div
        className="tw-text-[28px] tw-font-normal tw-leading-[36px] tw-not-italic tw-text-gray-700 tw-max-w-[574px] tw-truncate"
        data-cy="header-summary-title"
      >
        {useCustomTitle ? title || "Custom Transaction Title" : streetAddress}
      </div>
    </div>
    <div className="tw-flex tw-gap-[8px] tw-text-11px tw-h-[20px]">
      <div className="tw-flex tw-gap-[8px] tw-font-normal tw-text-[14px] tw-leading-[20px]">
        {useCustomTitle && streetAddress ? `${streetAddress},` : ""} {city ? `${city},` : ""} {locality}{" "}
        {postalCode}
        {!(streetAddress || city || locality || postalCode) && (
          <TextButton
            className="tw-mt-2px tw--ml-6px tw-uppercase tw-border-none tw-bg-transparent tw-font-bold tw-tracking-normal tw-text-[11px] tw-flex tw-items-center"
            onClick={() => setRename(true)}
          >
            <Plus size="m" className="tw-mr-[4px]" />
            <div className="tw-font-bold tw-tracking-normal tw-text-[11px] tw-ml-3px">ADDRESS</div>
          </TextButton>
        )}
        <div className="tw-text-gray-10">|</div>
      </div>
      <div className="tw-flex tw-gap-[8px]">
        <div className="tw-text-[14px] tw-font-semibold tw-leading-[20px] tw-tracking-normal tw-text-left">
          MLS#:
        </div>
        <div>
          {mlsNumber?.length && (
            <Tooltip
              multiline
              placement="top"
              content={
                <div className="tw-w-[163px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                  {validMlsNumber ? "Valid MLS number" : "Not a valid MLS number"}
                </div>
              }
              trigger={
                validMlsNumber ? (
                  <CircleCheckSolidV6 className="tw-mt-[4px] tw-w-[12px] tw-h-[12px] tw-text-[#46C771]" />
                ) : (
                  <TriangleExclamationSolidV6 className="tw-mt-[4px] tw-w-[12px] tw-h-[12px] tw-text-[#FFA826]" />
                )
              }
            />
          )}
        </div>
        <div className={mlsNumber?.length ? "tw-mt-[-7px]" : "tw-mt-[-1px]"}>
          <InlineEditingInput
            id="mls"
            name="MLS"
            maxLength={40}
            size={mlsNumber?.length || 1}
            value={mlsNumber || ""}
            onChange={(e) =>
              onPutDetailAsThunk({
                uuid,
                key: "mls_number",
                value: e,
                group: "listingDetails",
              })
            }
            editorClassName="tw-mt-[-2px]"
            displayComponent={(onClick) => (
              <TextButton className="tw-mt-2px tw--ml-6px tw-flex" onClick={onClick}>
                <div className="tw-text-[17px]">+</div>
                <div className="tw-font-bold tw-leading-[16px] tw-tracking-normal tw-text-[11px] tw-ml-3px">
                  Add
                </div>
              </TextButton>
            )}
          />
        </div>
      </div>
    </div>

    <div className="tw-flex tw-text-[14px]">
      <div className="tw-leading-[20px] tw-inline-flex">
        <div className="tw-font-normal tw-flex tw-gap-[8px]">
          {beds && (
            <div className="tw-flex tw-gap-[8px] tw-text-[14px] tw-font-normal tw-leading-[20px] tw-tracking-normal">
              <div>{beds}</div>
              <div>Bed</div>
            </div>
          )}
          {baths && (
            <div className="tw-flex tw-gap-[8px] tw-text-[14px] tw-font-normal tw-leading-[20px] tw-tracking-normal">
              {beds && <div className="tw-text-gray-10">|</div>}
              <div>{baths}</div>
              <div>Bath</div>
            </div>
          )}
          {sqft && (
            <div className="tw-flex tw-gap-[8px] tw-text-[14px] tw-font-normal [20px] tw-tracking-normal">
              {(baths || beds) && <div className="tw-text-gray-10">|</div>}
              <div>{sqft}</div>
              <div>SF</div>
            </div>
          )}
          {DIGIT_TEST.test(daysOnMarket) && (
            <div className="tw-flex tw-gap-[8px] tw-text-[14px] tw-font-normal tw-leading-[20px] tw-tracking-normal">
              {(sqft || baths || beds) && <div className="tw-text-gray-10">|</div>}
              <div>{daysOnMarket}</div>
              <div>DOM</div>
            </div>
          )}
        </div>
        {(baths || beds || sqft || (DIGIT_TEST.test(daysOnMarket) && source)) && (
          <div className="tw-ml-[8px] tw-mr-[8px] tw-text-gray-10">|</div>
        )}
        {source && (
          <div className="tw-flex tw-gap-[8px]">
            <div className="tw-text-[14px] tw-font-semibold tw-leading-[20px] tw-tracking-normal">
              Source:
            </div>
            <div className="tw-text-[14px] tw-font-normal tw-leading-[20px] tw-tracking-normal">{source}</div>
          </div>
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  type: state.tdpDetailsReducer.headerDetails?.type,
  propertyType: state.tdpDetailsReducer.propertyDetails.property_type,
  title: state.tdpDetailsReducer.transactionDetails.title,
  useCustomTitle: state.tdpDetailsReducer.transactionDetails.use_custom_title,
  streetAddress: state.tdpDetailsReducer.propertyDetails.street_address,
  city: state.tdpDetailsReducer.propertyDetails.city,
  locality: state.tdpDetailsReducer.propertyDetails.state,
  postalCode: state.tdpDetailsReducer.propertyDetails.zip,
  beds: state.tdpDetailsReducer.propertyDetails.beds,
  baths: state.tdpDetailsReducer.propertyDetails.baths,
  sqft: state.tdpDetailsReducer.propertyDetails.sqft,
  daysOnMarket: state.tdpDetailsReducer.headerDetails?.days_on_market,
  source: state.tdpDetailsReducer.listingDetails.source?.name,
  mlsNumber: state.tdpDetailsReducer.listingDetails.mls_number,
  validMlsNumber: state.tdpDetailsReducer.listingDetails.valid_mls_number,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (uuid) => dispatch(putDetailAsThunk(uuid)),
});

AddressInfo.propTypes = {
  uuid: string.isRequired,
  type: string,
  propertyType: shape({
    name: string,
  }),
  streetAddress: string,
  city: string,
  locality: string,
  postalCode: string,
  title: string,
  useCustomTitle: bool,
  beds: string,
  baths: string,
  sqft: string,
  daysOnMarket: string,
  source: string,
  mlsNumber: string,
  validMlsNumber: bool,
  onPutDetailAsThunk: () => {},
  setRename: func.isRequired,
};

AddressInfo.defaultProps = {
  type: "",
  propertyType: {
    name: "",
  },
  streetAddress: "",
  city: "",
  locality: "",
  postalCode: "",
  title: "",
  useCustomTitle: false,
  mlsNumber: "",
  validMlsNumber: false,
  source: "",
  beds: "",
  baths: "",
  sqft: "",
  daysOnMarket: "",
  onPutDetailAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressInfo);
