import React from "react";
import { string } from "prop-types";

const ActionDescriptor = ({ from }) => (
  <div className="tw-text-12d !tw-text-gray-50">from {from}</div>
);

export default ActionDescriptor;

ActionDescriptor.propTypes = {
  from: string.isRequired
}