import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_NAMESPACE = "tdpDocs";
const wrap = curriedNamespaceAction(REDUCER_NAMESPACE);

export const getDocuments = (documents, meta, uuid) => wrap({ type: "GET_DOCUMENTS", documents, meta, uuid });
export const getCDAdocument = (CDAdocuments) => wrap({ type: "GET_CDA_DOCUMENTS", CDAdocuments });
export const getDocumentsStart = () => wrap({ type: "GET_DOCUMENTS_START" });
export const getDocumentsError = (errors) => wrap({ type: "GET_DOCUMENTS_ERROR", errors });
export const getSidebarDocuments = (documents, meta) =>
  wrap({ type: "GET_SIDEBAR_DOCUMENTS", documents, meta });
export const getSidebarDocumentsStart = () => wrap({ type: "GET_SIDEBAR_DOCUMENTS_START" });
export const getSidebarDocumentsError = (errors) => wrap({ type: "GET_SIDEBAR_DOCUMENTS_ERROR", errors });
export const getDocumentsFromSearch = (documents, meta, search) =>
  wrap({ type: "GET_DOCUMENTS_FROM_SEARCH", documents, meta, search });
export const searchDocumentsError = (search, errors) =>
  wrap({ type: "SEARCH_DOCUMENTS_ERROR", search, errors });
export const getDocumentsFromSort = (documents, meta, orderBy, orderDir) =>
  wrap({ type: "GET_DOCUMENTS_FROM_SORT", documents, meta, orderBy, orderDir });
export const sortDocumentsError = (orderBy, orderDir, errors) =>
  wrap({ type: "SORT_DOCUMENTS_ERROR", orderBy, orderDir, errors });
export const getAllDocumentViewers = (documentId, viewers) =>
  wrap({ type: "GET_ALL_DOCUMENT_VIEWERS", documentId, viewers });
export const renameDocumentSuccess = (documentId, data) =>
  wrap({ type: "RENAME_DOCUMENT_SUCCESS", documentId, data });
export const renameDocumentError = (context, documentId, errors) =>
  wrap({ type: "RENAME_DOCUMENT_ERROR", context, documentId, errors });
export const toggleUploadModal = (isModalOpen) => wrap({ type: "TOGGLE_UPLOAD_MODAL", isModalOpen });
export const uploadDocumentStart = (data) => wrap({ type: "UPLOAD_DOCUMENT_START", data });
export const uploadDocumentSuccess = (data, uploadKey) =>
  wrap({ type: "UPLOAD_DOCUMENT_SUCCESS", data, uploadKey });
export const uploadCDADocSuccess = (data, uploadKey) =>
  wrap({ type: "UPLOAD_CDA_DOC_SUCCESS", data, uploadKey });

export const uploadDocumentError = (isFinancialsCDA, uploadKey, errors) =>
  wrap({ type: "UPLOAD_DOCUMENT_ERROR", uploadKey, errors, isFinancialsCDA });
export const toggleDeleteModal = (documentIdsToDelete, isFinancialsCDA) =>
  wrap({ type: "TOGGLE_DELETE_MODAL", documentIdsToDelete, isFinancialsCDA });
export const deleteDocumentsSuccess = (documentIds) =>
  wrap({ type: "DELETE_DOCUMENTS_SUCCESS", documentIds });
export const deleteDocumentsError = (errors) => wrap({ type: "DELETE_DOCUMENTS_ERROR", errors });
export const setDocumentIndex = (context, index) => wrap({ type: "SET_DOCUMENT_INDEX", context, index });
export const toggleDocumentsSelected = (context, documentIds, isSelected) =>
  wrap({ type: "TOGGLE_DOCUMENTS_SELECTED", context, documentIds, isSelected });
export const toggleCDAUploadModal = (bool) => wrap({ type: "TOGGLE_CDA_UPLOAD_MODAL", bool });
export const sortByCDAType = (sortType) => wrap({ type: "SORT_BY_CDA_NAME", sortType });
