/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ReactOnRails from "react-on-rails";
import "@webcomponents/webcomponentsjs/webcomponents-bundle";

import "../bundles/shared/v1/WebComponents/index";

import { serverPropsAdapter } from "../bundles/Transactions/apiAdapters";
import AutoDialerButton from "../bundles/Dialer/AutoDialerButton";
import AccountFinancialApp from "../bundles/Financials/startup/AccountFinancialApp";
import AccountUsersApp from "../bundles/Settings/Users";
import PhoneSettingsApp from "../bundles/Settings/Phone/PhoneSettingsApp";
import ReportingGoals from "../bundles/Goals/components/ReportingGoalsApp";
import AgreementFilters from "../bundles/Filters/components/AgreementFilters/AgreementFilters";
import AllListingAlertPreview from "../bundles/PersonDetail/components/listing_alerts/AllListingAlertPreview";
import AssignedPlansApp from "../bundles/AssignedPlans/startup/AssignedPlansApp";
import AssignedPlansPreviewApp from "../bundles/AssignedPlans/startup/AssignedPlansPreviewApp";
import AutoPlanEditApp from "../bundles/AutoPlans/startup/AutoPlanEditApp";
import AutoPlanFilters from "../bundles/Filters/components/AutoPlans/AutoPlanFilters";
import AutoPlansApp from "../bundles/AutoPlans/startup/AutoPlansApp";
import BusinessSettings from "../bundles/BusinessSettings/startup/BusinessSettings";
import BulkApplyMarketReports from "../bundles/MarketReports/BulkApplyMarketReports/BulkApplyMarketReports";
import CCEmails from "../bundles/ListingAlerts/components/cc_emails";
import ConferenceCall from "../bundles/Interactions/ConferenceCall/startup/ConferenceCallApp";
import DashboardApp from "../bundles/Dashboard/components/DashboardApp";
import DisabledHistoryFields from "../bundles/Filters/components/DisabledHistoryFields";
import EnableTextForwarding from "../bundles/EnableTextForwarding";
import ExcludeTagFilter from "../bundles/Filters/components/ExcludeTagFilter";
import StageFilter from "../bundles/Filters/components/StageFilter";
import SourceFilter from "../bundles/Filters/components/SourceFilter";
import FavoritesOrViews from "../bundles/People/components/FavoritesOrViews";
import HeaderApp from "../bundles/Layout/Header/HeaderApp";
import InboxApp from "../bundles/Inbox/startup/InboxApp";
import PondOrAgentFilter from "../bundles/Filters/components/PondOrAgentFilter";
import IncludeTagFilter from "../bundles/Filters/components/IncludeTagFilter";
import LeadDNA from "../bundles/LeadDNA/components/LeadDNA";
import LeadPondsApp from "../bundles/LeadPonds/index";
import LeadIndexApp from "../bundles/LeadIndex/index";
import LeadIndexButtonApp from "../bundles/ContactMergeModal/LeadIndexButtonApp";
import LeadRoutingTargetSelector from "../bundles/LeadRoutingTargetSelector";
import AgentOrPondSelect from "../bundles/shared/AgentOrPondSelect";
import FormAgentOrPondSelect from "../bundles/shared/FormAgentOrPondSelect";
import LeadQuickStats from "../bundles/Dashboard/components/LeadQuickStats";
import MarketReportFilters from "../bundles/Filters/components/MarketReports/MarketReportFilters";
import MarketReportPreview from "../bundles/MarketReports/MarketReportPreview";
import MassSendListingAlerts from "../bundles/ListingAlerts/components/MassSendListingAlerts";
import MassUpdateStageModal from "../bundles/LeadIndex/components/MassUpdateStageModal";
import MilestonesApp from "../bundles/Milestones/startup/MilestonesApp";
import ManageAccess from "../bundles/ManageAccess/components/ManageAccess";
import NewTask from "../bundles/Tasks/components/NewTask";
import NewProjectModal from "../bundles/Projects/NewProjectModal";
import OtherInteract from "../bundles/People/components/OtherInteract";
import PartnerSearch from "../bundles/PartnerSearch";
import PersonDetailApp from "../bundles/PersonDetail/startup/PersonDetailApp";
import PolymorphicTasks from "../bundles/Tasks/components/PolymorphicTasks";
import ReportsApp from "../bundles/Reports/startup/ReportsApp";
import SingleDialerApp from "../bundles/Dialer/SingleDialerApp";
import TwilioManageNumbers from "../bundles/TwilioSupport/TwilioManageNumbers";
import TwilioTollFree from "../bundles/TwilioTollFree/components/TwilioTollFree";
import TwilioSubaccountSid from "../bundles/TwilioSupport/TwilioSubaccountSid";
import SearchListingsApp from "../bundles/SearchListings/startup/SearchListingsApp";
import SidebarApp from "../bundles/Layout/Sidebar/SidebarApp";
import SharedAutoPlansApp from "../bundles/SharedAutoPlans/components/SharedAutoPlansApp";
import SmsModalButton from "../bundles/SmsModal/components/SmsModalButton";
import Sources from "../bundles/Sources/components/Sources";
import SuperUsersIndex from "../bundles/Support/SuperUsers/index";
import SupportAccountFeatures from "../bundles/Support/Account/Features";
import SupportTransactionFinancials from "../bundles/Support/Account/Financials/TransactionFinancials";
import SupportUsersIndex from "../bundles/Support/Users/index";
import TaskApp from "../bundles/Tasks/components/TaskApp";
import TaskCounts from "../bundles/Tasks/components/TaskCounts";
import TaskForm from "../bundles/Tasks/components/TaskForm";
import TaskFormLogAppointment from "../bundles/Tasks/components/TaskFormLogAppointment";
import TimelinesApp from "../bundles/Timelines/components/TimelinesApp";
import TransactionFilterModal from "../bundles/Filters/components/Transactions/TransactionFilterModal";
import TransactionDetailsPage from "../bundles/TransactionDetailsPage";
import TransactionFinancialApp from "../bundles/Financials/startup/TransactionFinancialApp";
import TransactionsIndex from "../bundles/Transactions/components/Wrapper";
import WebsiteSearchHistoryFilter from "../bundles/Filters/components/WebsiteSearchHistoryFilter";
import WebsiteApp from "../bundles/Website/WebsiteApp";
import IndexSendListings from "../bundles/SearchListings/components/IndexSendListings";
import NotesApp from "../bundles/Transactions/components/Notes/startup/NotesApp";
import TwilioTrustHub from "../bundles/TwilioTrustHub/components/TwilioTrustHub";
import SuperUsersBranding from "../bundles/Support/SuperUsers/Branding";
import DeleteAutoPlans from "../bundles/Support/DeleteAutoPlans/DeleteAutoPlans";
import OktaPasswordReset from "../bundles/OktaPasswordReset";
import OktaError from "../bundles/OktaError";
import layoutStore from "../bundles/store/layoutStore";
import AppliedFiltersApp from "../bundles/LeadIndex/components/AppliedFiltersApp";
import EmailAllModal from "../bundles/LeadIndex/components/EmailAllModal";
import InteractionTemplatesApp from "../bundles/InteractionTemplates/InteractionTemplatesApp";
import PhoneSystemApp from "../bundles/PhoneSystem/PhoneSystemApp";
import ListingAlertTemplates from "../bundles/ListingAlertTemplates/ListingAlertTemplates";
import OpportunitiesApp from "../bundles/Opportunities/startup/OpportunitiesApp";

ReactOnRails.registerStore({
  layoutStore,
});

ReactOnRails.register({
  AccountFinancialApp,
  AccountUsersApp,
  AgreementFilters,
  AllListingAlertPreview,
  AppliedFiltersApp,
  AssignedPlansApp,
  AssignedPlansPreviewApp,
  AutoDialerButton,
  AutoPlanEditApp,
  AutoPlanFilters,
  AutoPlansApp,
  BulkApplyMarketReports: (props) => <BulkApplyMarketReports {...props} />,
  BusinessSettings,
  CCEmails: (props) => <CCEmails {...props} />,
  ConferenceCall: (props) => <ConferenceCall {...props} />,
  DashboardApp,
  DisabledHistoryFields,
  EmailAllModal,
  EnableTextForwarding,
  ExcludeTagFilter: (props) => <ExcludeTagFilter {...props} />,
  StageFilter: (props) => <StageFilter {...props} />,
  SourceFilter: (props) => <SourceFilter {...props} />,
  FavoritesOrViews: (props) => <FavoritesOrViews {...props} />,
  HeaderApp,
  InboxApp,
  PondOrAgentFilter,
  IncludeTagFilter: (props) => <IncludeTagFilter {...props} />,
  IndexSendListings,
  LeadDNA: (props) => <LeadDNA {...props} />,
  LeadPondsApp,
  LeadIndexApp,
  LeadIndexButtonApp,
  LeadRoutingTargetSelector,
  AgentOrPondSelect,
  FormAgentOrPondSelect,
  LeadQuickStats: (props) => <LeadQuickStats {...props} />,
  MarketReportFilters,
  MarketReportPreview: (props) => <MarketReportPreview {...props} />,
  MassSendListingAlerts: (props) => <MassSendListingAlerts {...props} />,
  MassUpdateStageModal: (props) => <MassUpdateStageModal {...props} />,
  MilestonesApp,
  ManageAccess,
  NewTask: (props) => <NewTask {...props} />,
  NewProjectModal,
  OktaError,
  OktaPasswordReset,
  OtherInteract: (props) => <OtherInteract {...props} />,
  PartnerSearch,
  PersonDetailApp,
  PolymorphicTasks: (props) => <PolymorphicTasks {...props} />,
  ReportingGoals,
  ReportsApp,
  TwilioManageNumbers,
  TwilioTollFree,
  TwilioSubaccountSid,
  SearchListings: (props) => <SearchListingsApp {...props} />,
  SidebarApp,
  SharedAutoPlansApp: (props) => <SharedAutoPlansApp {...props} />,
  SmsModalButton,
  Sources: (props) => <Sources {...props} />,
  SuperUsersIndex: (props) => <SuperUsersIndex {...props} />,
  SupportAccountFeatures: (props) => <SupportAccountFeatures {...props} />,
  SupportTransactionFinancials,
  SupportUsersIndex: (props) => <SupportUsersIndex {...props} />,
  TaskApp,
  TaskCounts: (props) => <TaskCounts {...props} />,
  TaskForm: (props) => <TaskForm {...props} />,
  TaskFormLogAppointment: (props) => <TaskFormLogAppointment {...props} />,
  TimelinesApp: (props) => <TimelinesApp {...props} />,
  TransactionDetailsPage,
  TransactionFilterModal: (props) => <TransactionFilterModal {...props} />,
  TransactionFinancialApp,
  TransactionsIndex: (props) => <TransactionsIndex {...serverPropsAdapter(props)} />,
  WebsiteSearchHistoryFilter: (props) => <WebsiteSearchHistoryFilter {...props} />,
  NotesApp: (props) => <NotesApp {...props} />,
  TwilioTrustHub,
  SuperUsersBranding,
  DeleteAutoPlans,
  InteractionTemplatesApp,
  PhoneSettingsApp,
  PhoneSystemApp,
  SingleDialerApp,
  ListingAlertTemplates,
  Opportunities: (props) => <OpportunitiesApp {...props} />,
  WebsiteApp,
});
