import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import css from "../../Reports/components/report-styles.module.css";

const OktaStatus = ({ accountId, userId }) => {
  const [oktaProfileUrl, setOktaProfileUrl] = useState(null);
  const [oktaStatus, setOktaStatus] = useState(null);
  const [statusLoading, setStatusLoading] = useState(true);

  useEffect(() => {
    axios.get(`/support/accounts/${accountId}/users/${userId}/okta_status`)
      .then((response) => {
        setStatusLoading(false);

        setOktaStatus(response.data.okta_status);

        setOktaProfileUrl(response.data.okta_profile_url);
      })
  }, []);

  return <div className={css.flexy} style={{ alignItems: "baseline" }}>
    <span style={{ paddingRight: "10px", flexShring: 0, fontWeight: 700 }}>Okta Status:</span>
    {oktaStatus &&
      <a href={oktaProfileUrl}>{oktaStatus}</a>
    }
    {!statusLoading && oktaStatus === null &&
      <span>NO OKTA ACCOUNT FOR THIS USER</span>
    }
    {statusLoading &&
      <span>Loading...</span>
    }
  </div>
}

OktaStatus.propTypes = {
  accountId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired
}

export default OktaStatus;
