/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Bolt = (props) => (
  <Icon {...props} name="Bolt">
    <path d="M12.249 5H8.643L9.725.943A.75.75 0 0 0 9 0H4.75a.749.749 0 0 0-.743.651l-1 7.5A.75.75 0 0 0 3.75 9h3.709L6.02 15.077a.75.75 0 0 0 1.379.549l5.499-9.5A.75.75 0 0 0 12.249 5z" />
  </Icon>
);

export default Bolt;
