import React from "react";

const Arrows = ({ sortBy }) => {
  const upperArrowColor = sortBy === "asc" ? "#5e5e5e" : "#999";
  const lowerArrowColor = sortBy === "desc" ? "#5e5e5e" : "#999";

  return (
    <svg className="tw-w-12px tw-h-16px" viewBox="-2 -1 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        data-cy="sort-upper-arrow"
        d="M7.32714 5.2644L0.672857 5.2644C0.0745311 5.2644 -0.224632 4.48919 0.197551 4.03723L3.52469 0.47542C3.78751 0.194067 4.21249 0.194067 4.47251 0.47542L7.80245 4.03723C8.22463 4.48919 7.92547 5.2644 7.32714 5.2644Z"
        fill={upperArrowColor}
      />
      <path
        data-cy="sort-lower-arrow"
        d="M0.672857 7.2644H7.32714C7.92547 7.2644 8.22463 8.03962 7.80245 8.49158L4.47531 12.0534C4.21249 12.3347 3.78751 12.3347 3.52749 12.0534L0.197551 8.49158C-0.224633 8.03962 0.0745303 7.2644 0.672857 7.2644Z"
        fill={lowerArrowColor}
      />
    </svg>
  );
};

export default Arrows;
