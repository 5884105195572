import React from "react";
import PropTypes from "prop-types";
import colors from "./helpers/colors";

const ProgressBar = ({ label, values }) => (
  <div className="tw-flex tw-items-center tw-gap-[16px]">
    <div className="tw-flex-1 tw-relative tw-overflow-hidden tw-h-[10px] tw-rounded-[8px] tw-bg-[#efefef]">
      {values?.map(({ value, color }, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="tw-absolute tw-h-full tw-rounded-[8px]"
          style={{ width: `${value}%`, backgroundColor: color }}
        />
      ))}
    </div>
    {label && (
      <span className={colors.theme === "place" ? "tw-text-semantic-blue-100" : "tw-text-teal"}>
        {label}
      </span>
    )}
  </div>
);

ProgressBar.propTypes = {
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape()),
};

ProgressBar.defaultProps = {
  label: "50%",
  values: [
    { value: 75, color: colors.color2.default },
    { value: 50, color: colors.color1.default },
  ],
};

export default ProgressBar;
