import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import nullable from "prop-types-nullable";
import { connect } from "react-redux";

import { optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { errorForBanner } from "../../../../../shared/v2/ErrorBanner";
import { financialsUpdateCanceled } from "../../actions/creators";
import { removeTeamMember, updateFinancials } from "../../actions/thunks";
import FINANCIALS_UPDATE_TYPE from "../../api/actionNames";

import EditAmountModal from "../EditAmountModal/EditAmountModal";
import AddEditExpenseIncomeModal from "../AddEditExpenseIncomeModal";
import DeleteExpenseIncomeModal from "../DeleteExpenseIncomeModal";
import { CompanyWithLineItems } from "../Company";

const CompanySection = ({ financials, dispatch }) => {
  const [addingLineItem, setAddingLineItem] = useState(null);
  const [editingLineItem, setEditingLineItem] = useState(null);
  const [removingLineItem, setRemovingLineItem] = useState(null);
  const [editingGci, setEditingGci] = useState(null);

  const {
    adaptedData,
    lineItemTypeOptions,
    meta: { isUpdating, modalErrors: metaModalErrors },
  } = financials;

  const { company } = adaptedData;

  const modalErrors = useMemo(() => metaModalErrors.map((error) => errorForBanner(error)), [metaModalErrors]);

  const handleGciLabelClick = (comp) => {
    setEditingGci({ comp });
  };

  const handleAddLineItem = (comp) => {
    setAddingLineItem({ comp });
  };

  const handleEditLineItem = (lineItem) => {
    setEditingLineItem(lineItem);
  };

  const handleRemoveLineItem = (lineItem) => {
    setRemovingLineItem(lineItem);
  };

  const handleRemoveCompany = (comp) => {
    dispatch(removeTeamMember(comp.id, true));
  };

  const handleSaveGci = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_GCI, {
        gci: data.value,
        gciType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleGciValueChange = (newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_GCI_VALUE, {
        gci: newValue,
        gciType: newValueType,
      }),
    );
  };

  const handleLineItemDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_POSITION, {
        from: source.index,
        to: destination.index,
      }),
    );
  };

  const handleLineItemOptionChange = (lineItem, newOption) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_TYPE, {
        type: newOption.meta.type,
        id: lineItem.id,
        selectedOption: newOption,
      }),
    );
  };

  const handleLineItemValueChange = (lineItem, newValue, newValueType) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_VALUE, {
        type: lineItem.expenseOrIncome,
        id: lineItem.id,
        value: newValue,
        valueType: newValueType,
      }),
    );
  };

  const handleSaveNewLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.ADD_COMPANY_LINE_ITEM, {
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveEditedLineItem = (data) => {
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM, {
        type: data.name.meta.type,
        id: editingLineItem.id,
        selectedOption: data.name,
        value: data.value,
        valueType: data.isPercentage ? "percent" : "flat",
        notes: data.notes,
        isInModal: true,
      }),
    );
  };

  const handleSaveRemovedLineItem = () => {
    const lineItem = removingLineItem || editingLineItem;
    dispatch(
      updateFinancials(FINANCIALS_UPDATE_TYPE.REMOVE_COMPANY_LINE_ITEM, {
        type: lineItem.expenseOrIncome,
        id: lineItem.id,
        isInModal: true,
      }),
    );
  };

  const handleCancelSaveGci = () => {
    setEditingGci(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveNewLineItem = () => {
    setAddingLineItem(false);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveEditedLineItem = () => {
    setEditingLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  const handleCancelSaveRemovedLineItem = () => {
    setRemovingLineItem(null);
    dispatch(financialsUpdateCanceled());
  };

  useEffect(() => {
    if (!isUpdating && modalErrors.length === 0) {
      setEditingGci(null);
      setAddingLineItem(null);
      setEditingLineItem(null);
      setRemovingLineItem(null);
    }
  }, [isUpdating, modalErrors]);

  return (
    <>
      {company && (
        <CompanyWithLineItems
          company={company}
          onGciLabelClick={handleGciLabelClick}
          onGciValueChange={handleGciValueChange}
          onRemoveCompany={handleRemoveCompany}
          onAddLineItem={handleAddLineItem}
          onLineItemDragEnd={handleLineItemDragEnd}
          onLineItemOptionChange={handleLineItemOptionChange}
          onLineItemValueChange={handleLineItemValueChange}
          onEditLineItem={handleEditLineItem}
          onRemoveLineItem={handleRemoveLineItem}
        />
      )}
      {addingLineItem && (
        <AddEditExpenseIncomeModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          nameList={lineItemTypeOptions.company}
          onSave={handleSaveNewLineItem}
          onDelete={() => {}}
          onCancel={handleCancelSaveNewLineItem}
        />
      )}

      {editingLineItem && (
        <AddEditExpenseIncomeModal
          show
          isEditing
          isSaving={isUpdating}
          isDeleting={isUpdating}
          errors={modalErrors}
          nameList={editingLineItem.options}
          name={editingLineItem.selectedOption}
          value={editingLineItem.value}
          isPercentage={editingLineItem.valueType === "percent"}
          notes={editingLineItem.notes}
          onSave={handleSaveEditedLineItem}
          onDelete={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveEditedLineItem}
        />
      )}
      {removingLineItem && (
        <DeleteExpenseIncomeModal
          show
          isDeleting={isUpdating}
          errors={modalErrors}
          onConfirm={handleSaveRemovedLineItem}
          onCancel={handleCancelSaveRemovedLineItem}
        />
      )}
      {editingGci && (
        <EditAmountModal
          show
          isSaving={isUpdating}
          errors={modalErrors}
          title="Edit Team GCI"
          value={editingGci.comp.gci.value}
          isPercentage={editingGci.comp.gci.type === "percent"}
          notes={editingGci.comp.notes}
          onSave={handleSaveGci}
          onCancel={handleCancelSaveGci}
        />
      )}
    </>
  );
};

CompanySection.propTypes = {
  financials: PropTypes.shape({
    // Schema validation for the inner fields will be handled by the inner
    // components, so we mark them as "any" here.
    adaptedData: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      company: nullable(PropTypes.any).isRequired,
    }).isRequired,

    lineItemTypeOptions: PropTypes.shape({
      transactionIncome: PropTypes.arrayOf(optionShape),
      transactionExpense: PropTypes.arrayOf(optionShape),
      company: PropTypes.arrayOf(optionShape),
    }),

    meta: PropTypes.shape({
      isUpdating: PropTypes.bool.isRequired,
      modalErrors: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,

  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ tdpFinancialsReducer }) => ({
  financials: { ...tdpFinancialsReducer.financials },
});

export default connect(mapStateToProps)(CompanySection);
