import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/v2/Modal/Modal";
import Button from "../../shared/v2/Button/Button";
import TextInput from "../../shared/v2/TextInput/TextInput";
import HubSpotModal from "./HubSpotModal";
import ZapierWidgetModal from "./ZapierWidgetModal";

const IntegrationModal = ({ currentUser, integration, onClose, show }) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [thirdPartyModal, setThirdPartyModal] = useState(null);

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }
  }, [hasCopied]);

  useEffect(() => {
    const onZapierLinkClick = (e) => {
      if (e.target.id === "zapier-widget-link") {
        setThirdPartyModal("zapier");
      }
    };
    document.addEventListener("click", onZapierLinkClick);
    return () => document.removeEventListener("click", onZapierLinkClick);
  }, []);

  return (
    <>
      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        dialogClassName="tw-max-w-[600px] tw-w-full"
        show={!thirdPartyModal && show}
        onHide={onClose}
      >
        {integration && (
          <>
            <Modal.Header
              title={
                <div className="tw-flex tw-gap-[16px] tw-items-center">
                  <div className="tw-w-[80px] tw-h-[80px] tw-flex tw-justify-center tw-items-center tw-p-[8px] tw-rounded-[4px] tw-border-[1px] tw-border-solid tw-border-gray-10">
                    <img className="tw-max-w-[60px]" src={integration.imageUrl} alt={integration.headline} />
                  </div>
                  <h4 className="tw-flex tw-flex-col tw-m-0">
                    <span className="tw-text-16d tw-font-semibold">{integration.headline}</span>
                    <span
                      className="tw-text-14d"
                      dangerouslySetInnerHTML={{ __html: integration.subtitle }}
                    />
                  </h4>
                </div>
              }
              closeButton
            />
            <Modal.Body className="tw-max-h-[55vh] tw-overflow-y-auto tw-py-[16px] tw-flex tw-flex-col tw-gap-[16px]">
              {integration.screenshotUrl && (
                <img className="tw-max-w-full" src={integration.screenshotUrl} alt={integration.headline} />
              )}
              <div dangerouslySetInnerHTML={{ __html: integration.longDescription }} />
              {integration.urlText && (
                <>
                  <div>
                    Learn more at{" "}
                    <a href={integration.url} target="_blank" rel="noreferrer">
                      {integration.urlText}
                    </a>
                  </div>
                  <div className="tw-font-bold">
                    {integration.headline} is part of the Brivity Platform. If you are not a Brivity Platform
                    customer yet, call 866-295-3322 and sign up today.
                  </div>
                </>
              )}
              {integration.apiKey && (
                <>
                  <span className="tw-text-16d tw-font-semibold">How to connect?</span>
                  <ol className="tw-m-0 tw-pl-[1em] tw-flex tw-flex-col tw-gap-[16px]">
                    <li>
                      <div className="tw-mb-[16px]">Copy the API Key below.</div>
                      <TextInput
                        label="Your Brivity API Key"
                        readOnly
                        value={currentUser.account.apiKey}
                        helperText={
                          <Button
                            schema="secondary"
                            className={`tw-transition-all ${hasCopied ? "tw-opacity-50" : ""}`}
                            onClick={() =>
                              navigator.clipboard
                                ?.writeText(currentUser.account.apiKey)
                                .then(() => setHasCopied(true))
                            }
                          >
                            {hasCopied ? "Copied" : "Copy"}
                          </Button>
                        }
                      />
                    </li>
                    {integration.steps?.map((step, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={i} dangerouslySetInnerHTML={{ __html: step }} />
                    ))}
                  </ol>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-[16px]">
              {integration.slug === "brivity-va" && (
                <>
                  <p className="tw-flex-1 tw-mb-0">Request a consultation for Brivity VA today!</p>
                  <Button
                    className="tw-whitespace-nowrap"
                    size="medium"
                    onClick={() => setThirdPartyModal("hubspot")}
                  >
                    Request Consultation
                  </Button>
                </>
              )}
              {["idx", "kwkly"].includes(integration.slug) && (
                <>
                  <p className="tw-flex-1 tw-mb-0">
                    Learn about the Brivity Platform in a 15 minute demo webinar
                  </p>
                  <Button
                    className="tw-whitespace-nowrap"
                    size="medium"
                    onClick={() => setThirdPartyModal("hubspot")}
                  >
                    Get a Demo
                  </Button>
                </>
              )}
              {integration.apiKey && (
                <>
                  <p className="tw-flex-1 tw-mb-0">
                    Need help? <a href={integration.helpUrl}>Read this article</a>
                  </p>
                  <Button className="tw-whitespace-nowrap" size="medium" onClick={onClose}>
                    Got it
                  </Button>
                </>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
      <HubSpotModal
        formId={integration?.hubspotFormId}
        onClose={() => {
          setThirdPartyModal(null);
          onClose(null);
        }}
        show={thirdPartyModal === "hubspot"}
      />
      <ZapierWidgetModal
        onClose={() => {
          setThirdPartyModal(null);
          onClose(null);
        }}
        show={thirdPartyModal === "zapier"}
      />
    </>
  );
};

IntegrationModal.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  integration: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

IntegrationModal.defaultProps = {
  integration: undefined,
};

export default IntegrationModal;
