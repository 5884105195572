import React from "react";
import { Row, Col } from "react-bootstrap";
import Error from "../../../shared/Error";
import person_detail_css from "../../../PersonDetail/components/person-detail-styles.module.css";
import phone_call_detail_css from "./phone_call_detail_styles.module.css";
import AiAssistant from "@shared/AiAssistant";
import FieldHelper from "@shared/v2/FieldHelper";
import MentionablePersonTextArea from "@shared/mentions/MentionablePersonTextArea";

const outcomeOptions = [
  { value: "talked_to_lead", label: "Talked" },
  { value: "left_message", label: "Left Message" },
  { value: "busy", label: "Busy" },
  { value: "failed", label: "Failed" },
  { value: "no_answer", label: "No Answer" },
  { value: "wrong_number", label: "Wrong Number" },
];

const PhoneCallDetails = (props) => {
  const { interaction, updateValues, hasError, errorMessage, isDnc, notableId, isTimelineEvent } = props;
  const dncHelperText = isDnc ? (
    <p className="tw-m-0 tw-text-12d tw-text-neutral-gray-50 tw-space-x-1">
      <span className="tw-italic">The number you are contacting might be on the Do Not Call registry.</span>
      <a
        className="tw-font-semibold tw-text-neutral-gray-50 visited:tw-text-neutral-gray-50 active:tw-text-neutral-gray-50 hover:tw-text-neutral-gray-50"
        href="https://www.brivityknowledge.com/v1/docs/do-not-call-registry-warning"
        target="_blank"
        rel="noreferrer"
      >
        Click here to learn more
      </a>
    </p>
  ) : null;

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12}>{hasError && <Error errorMessage={errorMessage} />}</Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col xs={12} id="call-outcome">
            <label className={person_detail_css.personDetailLabel}> Call Outcome</label>
            <Row className="m-t-10">
              <Col xs={12} className="d-flex flex-justify-space-between" style={{ maxWidth: 650 }}>
                {outcomeOptions.map((outcome, index) => {
                  return [
                    <a
                      key={index}
                      className={`${phone_call_detail_css.verticalCenter} ${
                        person_detail_css.brivityBtnGrayOutline
                      } ${interaction.outcome === outcome.value ? person_detail_css.active : ""}`}
                      onClick={() => updateValues("outcome", outcome.value)}
                    >
                      {outcome.label}
                    </a>,
                  ];
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} style={{ marginTop: "10px", marginBottom: "2px" }}>
        <div className="tw-flex tw-justify-between tw-items-end tw-mb-[8px]">
          <label className={person_detail_css.personDetailLabel}>Notes</label>
          <AiAssistant
            textareaId="interaction-phone-input"
            messageType="Generic"
            onInsertClick={(text) => props.updateValues("comment", text)}
          />
        </div>
        <MentionablePersonTextArea
          id="interaction-phone-input"
          value={
            isTimelineEvent
              ? interaction.markdown_comment || interaction.comment || ""
              : interaction.comment || ""
          }
          mentionable_id={notableId}
          mentionable_type="Person"
          onChange={(value) => props.updateValues("comment", value)}
          isExternal={false}
        />
        {dncHelperText && <FieldHelper className="tw-mt-4px" helperText={dncHelperText} />}
      </Col>
    </Row>
  );
};

export default PhoneCallDetails;
