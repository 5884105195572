import React, { useState } from "react";
import { take } from "lodash";

import Button from "../Button";

const BuyerMatches = ({buyerMatches: {matches, linkToIndex, count, countText}, listingId, sendToLead}) => {
  const [collapsed, setCollapsed] = useState(true);

  const shownWhileCollapsed = 3;
  const shownWhileExpanded = 10;
  const matchesToShow = take(matches, collapsed ? shownWhileCollapsed : shownWhileExpanded);

  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      <div className="tw-flex tw-flex-row tw-justify-between tw-mb-20px">
        <span className="tw-text-18px tw-font-bold tw-text-gray-75">Buyer Matches</span>
        <a
          href={linkToIndex}
          target="_blank"
          className="hover:tw-no-underline tw-py-2px tw-px-6px tw-cursor-pointer tw-tracking-normal tw-text-center tw-uppercase tw-text-none tw-rounded-30px tw-border-2 tw-border-teal tw-border-solid tw-bg-white"
        >
          <div className="tw-flex tw-flex-row tw-items-center tw-text-teal tw-text-11px tw-font-bold">
            SEE ALL ({countText}) MATCHES
            <i className="fa fa-arrow-right tw-ml-4px tw-text-11px" />
          </div>
        </a>
      </div>
      {matchesToShow.map((match, i) => (
        <div key={match.email} className={`tw-flex tw-flex-row tw-items-center ${i > 0 ? "tw-mt-16px" : ""}`}>
          <div className="tw-flex tw-justify-center tw-items-center tw-h-36px tw-w-36px tw-bg-color-ebebeb tw-rounded-[100px]">
            <span className="tw-text-14px tw-text-gray-75 tw-font-semibold">{match.initials}</span>
          </div>
          <span className="tw-ml-10px tw-text-14px tw-font-semibold tw-text-gray-75">{match.fullName}</span>
          <Button
              classNameExtra="tw-flex tw-justify-center tw-items-center tw-ml-auto"
              color="blue"
              onClick={() => sendToLead(match, listingId)}
            >
            <i className="fa fa-share-square-o tw-text-[9px] tw-mr-6px" />
            SHARE
          </Button>
        </div>
      ))}
      {count > shownWhileCollapsed && (
        <div className="tw-flex tw-items-center tw-text-11px tw-font-bold tw-text-teal tw-cursor-pointer tw-mt-16px" onClick={() => setCollapsed(!collapsed)}>
          <i className={`fa ${collapsed ? "fa-angle-down" : "fa-angle-up"} tw-mr-4px`} />
          <span>SHOW {collapsed ? "MORE" : "LESS"}</span>
        </div>
      )}
    </div>
  );
};

export default BuyerMatches;
