const calendarPositionClassNames = (position) => {
  switch (position) {
    case "bottom-left":
      return `tw-absolute tw-top-[0px] tw-left-[0px]`;
    case "bottom":
      return `tw-absolute tw-top-[0px] tw-left-[calc(50%-120px)]`;
    case "default":
      return `tw-absolute tw-top-[calc(50%-120px)] tw-left-[calc(50%-120px)]`;
    default:
      return "";
  }
};

export default calendarPositionClassNames;
