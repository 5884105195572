/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bool, func, shape, arrayOf, string, number } from "prop-types";
import { submitSendEmail, clearEmailState } from "../../../../LeadIndex/actions/default";
import { sendEmailError, clearEmailError } from "../actions/creators";
import Modal from "../../../../shared/v2/Modal";
import PersonInteractionEmailForm from "../../../../PersonDetail/components/PersonInteractionEmailForm";

const Email = ({
  show,
  onHide,
  senderId,
  onSendEmailError,
  person,
  emailTemplates,
  onSubmitSendEmail,
  uuid,
  emailErrors,
  onClearEmailState,
  isEmailSent,
  listingId,
  onClearEmailError,
}) => {
  useEffect(() => {
    if (isEmailSent) {
      onHide(false);
      onClearEmailError();
    }
  }, [isEmailSent]);

  const submitEmail = () => {
    onSubmitSendEmail();

    setTimeout(() => {
      onHide();
      onClearEmailState();
      onClearEmailError();
    }, 5000);
  };

  const handleSubmitEmailError = (err) => {
    const data = err?.response?.data;
    let errors = data?.content || data?.error || "Whoops something went wrong! Try again later.";
    if (typeof data?.errors === "object" || typeof data?.error === "object") {
      const firstKey = Object.keys(data.errors || data.error)[0];
      errors = `${firstKey} ${data.errors[firstKey].join(", ")}`;
    }

    onSendEmailError({ errors, isSupportError: errors.startsWith("We apologize") });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        onClearEmailState();
        onClearEmailError();
      }}
      className="tw-flex tw-items-center tw-justify-center tm-pl-[25px] tw-mr-[25px]"
      contentClassName="tw-flex tw-flex-col tw-gap-[32px] tw-w-[616px] tw-max-h-[90vh] tw-overflow-hidden"
      closeOnEscape={false}
    >
      <Modal.Header title="Email" closeButton />
      <Modal.Body className="tw-overflow-y-auto tw-overflow-x-hidden">
        <PersonInteractionEmailForm
          send_email_url="/api/v4/transactions/interactions/email"
          isTDP
          isEmailSent={isEmailSent}
          submitEmail={submitEmail}
          person={{
            data: {
              attributes: {
                id: person.id,
                uuid: person.uuid,
                main_email: person.email_address,
              },
            },
            included: [
              {
                attributes: {
                  id: null,
                  detailable_id: null,
                  detailable_type: "Person",
                  name: "email",
                  value: person.email_address,
                  category: "work",
                  position: 1,
                  created_at: null,
                  updated_at: null,
                  canonical_value: null,
                  account_id: null,
                  is_verified: null,
                  validation_primary_status: null,
                  validation_secondary_status: null,
                  validation_results: null,
                  last_validated_at: null,
                  validation_set_by_user: false,
                  friendly_phone: null,
                  unsubscribed_message_types: [],
                },
                id: null,
                type: "email_detail",
              },
            ],
          }}
          sendEmailError={emailErrors}
          handleSubmitEmailError={handleSubmitEmailError}
          email_templates={emailTemplates}
          current_user_id={senderId}
          listingUuid={uuid}
          clearErrors={onClearEmailError}
          listingId={listingId}
        />
      </Modal.Body>
    </Modal>
  );
};

Email.propTypes = {
  uuid: string,
  show: bool,
  onHide: func,
  senderId: func,
  isEmailSent: bool,
  onSubmitSendEmail: func,
  person: shape({}),
  emailTemplates: arrayOf(shape({})),
  onSendEmailError: func,
  onClearEmailState: func,
  listingId: number.isRequired,
  emailErrors: shape({}),
  onClearEmailError: func,
};

Email.defaultProps = {
  uuid: "",
  show: false,
  onHide: () => {},
  senderId: () => {},
  isEmailSent: false,
  onSubmitSendEmail: null,
  person: {},
  emailTemplates: {},
  onSendEmailError: () => {},
  onClearEmailState: null,
  emailErrors: null,
  onClearEmailError: () => {},
};

const mapStateToProps = (state) => ({
  senderId: state.tdpDetailsReducer.currentUser.id,
  emailErrors: state.tdpInteractionsReducer.sendEmailError,
  isEmailSent: state.tdpInteractionsReducer.isEmailSent,
  emailTemplates: state.tdpInteractionsReducer.emailTemplates,
  uuid: state.tdpDetailsReducer.uuid,
  listingId: state.tdpDetailsReducer.headerDetails.listing_id,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSendEmail: () => dispatch(submitSendEmail()),
  onSendEmailError: (err) => dispatch(sendEmailError(err)),
  onClearEmailState: () => dispatch(clearEmailState()),
  onClearEmailError: () => dispatch(clearEmailError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Email);
