import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@shared/v2/Button";
import { MergeSolidV6, TriangleExclamationSolidV6 } from "@shared/v2/Icomoon";
import { useContactMergeHasDuplicates } from "../../reducers/contactMergeReducer/selectors";
import * as actions from "../../reducers/contactMergeReducer";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const DuplicateLeadCard = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);
  const hasDuplicates = useContactMergeHasDuplicates();

  if (
    !hasDuplicates ||
    !currentUser.account.isAdmin ||
    String(currentUser.person.id) === String(person.data?.id) ||
    person.data?.attributes?.type_name !== "lead"
  ) {
    return null;
  }

  return (
    <div className="tw-shadow-card tw-rounded-[8px] tw-p-[12px] tw-flex tw-flex-col tw-gap-[8px] tw-mb-[24px]">
      <span className="tw-flex tw-items-center tw-gap-[4px] tw-text-brand-warning">
        <TriangleExclamationSolidV6 />
        <h4 className="tw-m-0 tw-text-14d tw-font-semibold">Duplicate Lead</h4>
      </span>
      <span>Email or mobile phone is identical to other leads</span>
      <Button
        className="tw-self-start tw-flex tw-items-center"
        schema="secondary"
        onClick={() => {
          dispatch(actions.setOtherContact(null));
          dispatch(actions.setModalIsOpen(true));
        }}
      >
        <MergeSolidV6 className="tw-mr-[4px]" /> Merge Duplicates
      </Button>
    </div>
  );
};

export default DuplicateLeadCard;
