import React from "react";

import ClosePriceLabel from "../ClosePriceLabel";
import ClosePriceVal from "../ClosePriceVal";

const ClosePrice = () => (
  <div className="tw-flex tw-flex-col" data-cy="header-estimated-or-close-price">
    <ClosePriceLabel />
    <ClosePriceVal />
  </div>
);

export default ClosePrice;
