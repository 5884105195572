import React, { useState } from "react";
import Checkbox from "../shared/v2/Checkbox";
import Button from "../shared/v2/Button";
import Modal from "../shared/v2/Modal";
import { TriangleExclamationSolidV6, LightbulbOnSolidV6 } from "@shared/v2/Icomoon";

const EnableTextForwarding = ({ textForwardingEnabled }) => {
  const [checked, setChecked] = useState(textForwardingEnabled);
  const [showModal, setShowModal] = useState(false);

  const onHide = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setChecked(true);
    onHide();
  };

  const handleCancel = () => {
    setChecked(false);
    onHide();
  };

  const handleClickCheckbox = () => {
    if (checked) {
      setChecked(false);
    } else {
      setShowModal(true);
    }
  };

  const openLink = () => {
    window.open("https://www.brivityknowledge.com/docs/upgrade-your-notifications-with-brivity-go", "_blank");
  };

  return (
    <>
      <Modal
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-w-[520px]"
        show={showModal}
        onHide={handleCancel}
      >
        <Modal.Header title="Are You Sure?" className="tw-mb-32px" closeButton />

        <Modal.Body>
          <div>
            <div className="tw-border-solid tw-w-[454px] tw-border-[1px] tw-text-[#E5941B] tw-border-semantic-yellow-10 tw-bg-semantic-yellow-5 tw-rounded-sm">
              <div className="tw-py-[12px] tw-px-[16px]">
                <TriangleExclamationSolidV6 size="l" />
                <span className="tw-pl-[16px] tw-text-[12px] tw-font-semibold tw-leading-[16px] tw-text-semantic-yellow-120">
                  Enabling text forwarding can put your text reputation at risk
                </span>
              </div>
            </div>
            <div className="tw-py-[24px]">
              SPAM or malicious texts sent from contact phone numbers are monitored and can be flagged or
              blocked by the texting service. If this happens too often, your ability to text through Brivity
              may be disabled.
            </div>
            <div className="tw-pb-[24px]">
              Using Brivity GO push notifications instead of text forwarding is the safest and most reliable
              way to get notified about incoming texts.
            </div>
            <div className="!tw-text-brivity-blue-110 tw-cursor-pointer" onClick={openLink}>
              Learn more about how to protect your text reputation and get started with Brivity GO.
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-mt-[40px]">
          <Button
            onClick={handleCancel}
            className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
            schema="tertiary"
            size="medium"
          >
            Cancel
          </Button>
          <div className="tw-flex-1" />
          <Button onClick={handleClick} className="tw-flex tw-items-center" schema="primary" size="medium">
            Enable Text Forwarding
          </Button>
        </Modal.Footer>
      </Modal>
      <input name="user[sms_text_forwarding]" type="hidden" value={checked ? 1 : 0} autocomplete="off" />
      <Checkbox
        checked={checked}
        label="Enable Text Forwarding"
        onChange={handleClickCheckbox}
        className="tw-cursor-pointer"
      />
      <div className="tw-pt-[16px]">
        <div className="tw-border-solid tw-border-[1px] tw-text-brivity-blue-110 tw-border-[#DEF3F3] tw-bg-blue-10 tw-rounded-sm">
          <div className="tw-py-[12px] tw-px-[16px]">
            <div className="tw-items-center tw-grid tw-grid-cols-12 tw-justify-between">
              <div className="tw-col-span-1">
                <LightbulbOnSolidV6 size="l" />
              </div>
              <div className="tw-ml-[-45px] tw-col-span-9 tw-text-[14px] tw-font-bold tw-leading-[20px] tw-text-color-336e7b">
                Tip: Get Notified About Incoming Texts through Brivity GO
                <div className="tw-font-normal">
                  The best way to get notified about incoming texts is through Brivity GO push notifications.
                </div>
              </div>
              <div className="tw-col-span-2 tw-justify-self-end">
                <Button
                  onClick={openLink}
                  className="tw-flex tw-items-center"
                  schema="secondary"
                  size="small"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnableTextForwarding;
