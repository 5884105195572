import React, { Component } from "react";
import axios from "axios";
import tasks from "./tasks.module.css";
import appointments from "../appointments.module.css";
import css from "../person-detail-styles.module.css";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
class CardAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: undefined,
    };
  }

  componentDidMount() {
    this.getAgentImage();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.task.assigned_to_user_id != this.props.task.assigned_to_user_id) {
      this.getAgentImage();
    }
  }

  getAgentImage = () => {
    axios
      .get(`/profile_icon?id=${this.props.task.assigned_to_user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({
          image: { link: response.data.link, name: response.data.name, initials: response.data.initials },
        });
      })
      .catch((response) => {
        console.log("no assigned");
      });
  };

  render() {
    const { task } = this.props;

    return (
      <Row>
        <Col xs={12} key={task.id} className={`${appointments.details}`}>
          <div className={css.materialDesignBox}>
            <div className={css.brivityPersonDetailsTitle}>
              <span>
                <i className="fa fa-calendar-check-o" style={{ fontSize: "14px", marginRight: "8px" }} />
              </span>
              <div className={tasks.name}>
                <a onClick={() => this.props.showTaskDetails(task)} className="no-underline">
                  {" "}
                  {task.name}{" "}
                </a>
              </div>
              &nbsp;
              {moment(task.due_on).format("MM/DD/YY")}
            </div>

            {task.outcome && (
              <div className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>TYPE:</span>
                <span className={css.inlineLabelValue}>{task.appointment_type}</span>
              </div>
            )}
            {task.outcome && (
              <div className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>OUTCOME:</span>
                <span className={css.inlineLabelValue}>{task.outcome}</span>
              </div>
            )}
            {task.location && (
              <div className={css.inlineDetail}>
                <span className={css.brivityPersonDetailsLabel}>LOCATION:</span>
                <span className={css.inlineLabelValue}>{task.location}</span>
              </div>
            )}
            {task.note && (
              <div className={`${css.inlineDetail} tw-break-words`}>
                <span className={css.brivityPersonDetailsLabel}>NOTES:</span>
                <span className={css.inlineLabelValue}>{task.note}</span>
              </div>
            )}
            <div className={css.inlineDetail}>
              <span className={css.brivityPersonDetailsLabel}>STATUS:</span>
              <span className={css.inlineLabelValue}>{task.completed_at ? "Completed" : "Scheduled"}</span>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default CardAppointment;
