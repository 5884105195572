import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Input = forwardRef((props, ref) => {
  const { icon, ...rest } = props;
  const iconType = icon ? <i className={icon}></i> : null
  
  return(
    <div>
      <input 
        data-cy='shared_input-0'
        ref={ref} 
        {...rest}/>
      {iconType}
    </div>
  )
});

Input.propTypes = {
  icon: PropTypes.string,
}

Input.defaultProps = {
  type: 'text',
  className: 'form-control input-sm'
}

export default Input;
