import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bool, func, string, arrayOf, shape, number } from "prop-types";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import Modal from "@shared/v2/Modal";
import { Col, Row } from "../../../../shared/v1";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import Button from "../../../../shared/v2/Button/Button";
import { postPersonAsThunk, getAvailablePeopleAsThunk, getAllPeopleAsThunk } from "../actions/thunks";
import ErrorBanner from "../../../../shared/v2/ErrorBanner";
import useDropdownSearch from "./hooks";
import AccessViewer from "../AccessViewer";
import SubscribeClient from "../SubscribeClient";
import { clearError } from "../actions/creators";
import PrimaryAgentCheckbox from "../PrimaryAgentCheckbox";
import PersonDetailsModal from "../../../../Contacts/PersonDetailsModal";

const ADD_DEFAULT_FIELDS = [
  { field: "type_name", value: "lead" },
  { field: "status", value: "new" },
  { field: "lead_type", value: "buyer" },
];

const AddModal = ({
  currentUser,
  toggleAddModal,
  setToggleAddModal,
  title,
  onClearError,
  isClient,
  isCollaborator,
  isTeamMember,
  roles,
  onPostPersonAsThunk,
  onSearchPeopleAsThunk,
  onGetAvailablePeopleAsThunk,
  uuid,
  isAdmin,
  currentUserId,
  primaryAgentId,
  error,
  onGetAllPeopleAsThunk,
  onUpdateMultipleFormFields,
  type,
}) => {
  const rolesDropdown = useMemo(
    () =>
      sortBy(
        roles.map((role) => ({
          value: role.id,
          label: role.title,
        })),
        "label",
      ),
    [roles],
  );
  const newContact = useMemo(
    () => ({
      isLead: true,
      primaryAgentId: currentUser.id,
      leadType: "buyer",
      typeName: "lead",
      status: "new",
    }),
    [],
  );

  const [checkError, setCheckError] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [toggleViewerAccess, setToggleViewerAccess] = useState(!!isTeamMember);
  const [toggleEmailUpdate, setToggleEmailUpdate] = useState(false);
  const [toggleAddContactModal, setToggleAddContactModal] = useState(false);
  const [person, setPerson] = useState({ person: {} });
  // eslint-disable-next-line no-nested-ternary
  const category = isClient ? "listing_clients" : isCollaborator ? "collaborators" : "team_members";

  useEffect(() => {
    if (checkError && (!error || error?.length === 0)) {
      setToggleAddModal(false);
    }
    setCheckError(false);
  }, [checkError]);

  const handleSave = () => {
    onPostPersonAsThunk(person, uuid, category).then(() => {
      setCheckError(true);
      onGetAllPeopleAsThunk(uuid);
    });
  };

  useEffect(() => {
    setPerson({
      person: {
        external: !!(isClient || isCollaborator),
        collaborator: !!isCollaborator,
        portal_access: toggleViewerAccess,
        gets_updates: toggleEmailUpdate,
        role_id: selectedRole?.value || null,
        person_id: parseInt(selectedPerson?.value, 10) || null,
        primary: false,
      },
    });
  }, [selectedRole, selectedPerson, toggleViewerAccess, toggleEmailUpdate]);

  useEffect(() => {
    if (toggleAddModal) onGetAvailablePeopleAsThunk(uuid);
  }, [toggleAddModal, uuid]);

  const {
    noOptionsMessage,
    availablePeopleDropdown,
    setSearch,
    isLoading,
    error: personError,
  } = useDropdownSearch({
    title,
    uuid,
  });
  return (
    <>
      <Modal
        show={toggleAddModal}
        onHide={() => {
          onClearError();
          setToggleAddModal(false);
          setToggleViewerAccess(!!isTeamMember);
        }}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[500px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title={`Add ${title}`} closeButton />

        <Modal.Body>
          {error && <ErrorBanner errors={error} />}
          <label
            htmlFor="role"
            className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
          >
            Role <span className="tw-text-coral">*</span>
          </label>
          <Dropdown
            isSearchable
            onChange={setSelectedRole}
            placeholder=""
            name="role"
            options={rolesDropdown}
            value={selectedRole}
            className="tw-bg-gray-10 tw-mb-[24px]"
          />

          <label
            htmlFor="person"
            className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
          >
            {title}
            <span className="tw-ml-4px tw-text-coral">*</span>
          </label>
          <div className="tw-h-[36px]">
            <Dropdown
              isLoading={isLoading}
              error={personError}
              helperText={personError}
              isSearchable
              onChange={setSelectedPerson}
              placeholder=""
              value={selectedPerson}
              name="person"
              options={availablePeopleDropdown}
              className="tw-bg-gray-10 tw-max-w-[430px]"
              onInputChange={setSearch}
              noOptionsMessage={noOptionsMessage}
            />
          </div>
          <div className="tw-ml-[-5px] tw-mb-[32px] tw-font-semibold tw-text-[14px] tw-text-theme-button-primary tw-leading-5 tw-mt-[10px]">
            {!isTeamMember && (
              <button
                type="button"
                className="tw-border-none tw-bg-transparent tw-text-14px tw-font-semibold tw-leading-20px tw-tracking-normal"
                onClick={() => {
                  onUpdateMultipleFormFields(ADD_DEFAULT_FIELDS);
                  setToggleAddContactModal(true);
                }}
              >
                Add New Contact
              </button>
            )}
          </div>

          <div>
            <AccessViewer
              isCollaborator={isCollaborator}
              isClient={isClient}
              toggleViewerAccess={toggleViewerAccess}
              onChangeSetToggle={() =>
                setToggleViewerAccess((prev) => {
                  if (prev) setToggleEmailUpdate(false);
                  return !prev;
                })
              }
              isDisabled={!selectedPerson?.email}
            />
            <SubscribeClient
              isClient={isClient}
              isOpen={toggleViewerAccess}
              toggleEmailUpdate={toggleEmailUpdate}
              onChangeSetToggle={() => setToggleEmailUpdate(!toggleEmailUpdate)}
              isDisabled={!selectedPerson?.email}
            />
            {isTeamMember && (
              <div>
                <PrimaryAgentCheckbox
                  checked={person.person.primary}
                  containerClassName="tw-mt-[16px] tw-mb-[26px]"
                  disabled={false}
                  onClickCheckBox={() =>
                    setPerson((prev) => ({
                      ...prev,
                      person: {
                        ...prev.person,
                        primary: !prev.person.primary,
                      },
                    }))
                  }
                  show={type !== "referral"}
                />
                <div>
                  <FontAwesomeIcon icon={faKey} />
                  <div className="tw-text-gray-75 tw-ml-[6px] tw-mb-[5px] tw-text-sm tw-font-normal tw-leading-5 tw-tracking-normal tw-text-left tw-inline-block">
                    Login Access: {isAdmin || currentUserId === primaryAgentId ? "Admin" : "Agent"}
                  </div>
                  <div className="tw-text-gray-50 tw-mb-[12px] tw-ml-[20px] tw-text-sm tw-font-normal tw-tracking-normal tw-text-left tw-inline-block">
                    Administrators can view and edit all parts of a transaction.
                  </div>
                </div>
              </div>
            )}
          </div>
          <Row className="tw-pt-[32px] tw-m-0 tw-p-0 tw-flex ">
            <Col xs={8} className="tw-m-0 tw-p-0">
              <Button
                onClick={() => {
                  onClearError();
                  setToggleAddModal(false);
                }}
                className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                schema="tertiary"
                size="medium"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={4} className="tw-m-0 tw-p-0 tw-flex tw-justify-end">
              <Button
                onClick={handleSave}
                disabled={!selectedPerson?.value || !selectedRole?.value}
                className="tw-flex tw-items-center"
                schema="primary"
                size="medium"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <PersonDetailsModal
        person={newContact}
        show={toggleAddContactModal}
        onClose={() => {
          setToggleAddContactModal(false);
          onGetAllPeopleAsThunk(uuid);
          onSearchPeopleAsThunk(uuid);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  availablePeople: state.tdpPeopleReducer.availablePeople?.people?.people,
  currentUser: state.layout.currentUser,
  uuid: state.tdpDetailsReducer.uuid,
  isAdmin: state.tdpDetailsReducer.isAdmin,
  currentUserId: state.tdpDetailsReducer.currentUserId,
  primaryAgentId: state.tdpDetailsReducer.primaryAgentDetails.agentId,
  error: state.tdpPeopleReducer.error.error,
  closeModal: state.tdpPeopleReducer.closeModal,
  type: state.tdpDetailsReducer.transactionDetails.type,
});

const mapDispatchToProps = (dispatch) => ({
  onPostPersonAsThunk: (person, uuid, category) => dispatch(postPersonAsThunk(person, uuid, category)),
  onGetAllPeopleAsThunk: (uuid) => dispatch(getAllPeopleAsThunk(uuid)),
  onGetAvailablePeopleAsThunk: (uuid) => dispatch(getAvailablePeopleAsThunk(uuid)),
  onClearError: () => dispatch(clearError()),
});

AddModal.propTypes = {
  currentUser: shape().isRequired,
  toggleAddModal: bool,
  setToggleAddModal: func,
  title: string,
  isClient: bool,
  isCollaborator: bool,
  isTeamMember: bool,
  roles: arrayOf(shape({})),
  onPostPersonAsThunk: func,
  onSearchPeopleAsThunk: func,
  onGetAvailablePeopleAsThunk: func,
  uuid: string,
  isAdmin: bool,
  currentUserId: number,
  primaryAgentId: number,
  error: arrayOf(shape({})),
  onUpdateMultipleFormFields: func,
  onGetAllPeopleAsThunk: func,
  onClearError: func,
  type: string,
};

AddModal.defaultProps = {
  toggleAddModal: false,
  setToggleAddModal: () => {},
  title: "",
  isClient: false,
  isCollaborator: false,
  isTeamMember: false,
  roles: [],
  onPostPersonAsThunk: () => {},
  onSearchPeopleAsThunk: () => {},
  onGetAvailablePeopleAsThunk: () => {},
  uuid: "",
  isAdmin: false,
  currentUserId: 0,
  primaryAgentId: 0,
  error: [],
  onUpdateMultipleFormFields: () => {},
  onGetAllPeopleAsThunk: () => {},
  onClearError: () => {},
  type: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddModal);
