import { reverse } from "lodash";
import { transformSms } from "../../../../Inbox/helpers/transformSms";

export const smsHistoryAdapter = (smsHistory) => ({
  smsHistory: reverse(smsHistory?.map(transformSms)),
  sendingMessage: false,
  fetchingMessages: false,
  conversationLoaded: true,
  moreSmsToLoad: smsHistory.length === 10,
});

export const closeModalAdapter = (bool) => ({
  closeModal: bool,
});

export const adaptError = (err) => ({
  error: err,
});
