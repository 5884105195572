/* eslint-disable class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@shared/v2/Checkbox";
import "./AssignedPlans.scss";
import AssignedPlanRow from "./AssignedPlanRow";
import { filterAssignedPlans } from "./helpers";
import {
  renderApplyModal,
  renderDeleteActionModal,
  renderPlanModal,
  renderDeletePlanModal,
  renderPlanPreviewModal,
} from "./ModalHelpers";

const emptyPlanTypeMessage = {
  transactions: "No plans have been applied to the transaction",
  people: "No plans have been applied to the contact",
};

class AssignedPlansTable extends React.Component {
  componentDidMount() {
    const { getAssignedPlans, assignedPlansPath, assignableId, assignableType, platformAccount } = this.props;
    getAssignedPlans(assignedPlansPath, assignableId, assignableType);

    if (!platformAccount) Brivity.AccountsAppstore.start();
  }

  isModalOpen() {
    const {
      isApplyModalOpen,
      isPlanModalOpen,
      isDeleteAssignedActionModalOpen,
      isDeletePlanModalOpen,
      isPlanPreviewModalOpen,
    } = this.props;
    return (
      isApplyModalOpen ||
      isPlanModalOpen ||
      isDeleteAssignedActionModalOpen ||
      isDeletePlanModalOpen ||
      isPlanPreviewModalOpen
    );
  }

  renderModal() {
    const {
      isApplyModalOpen,
      isPlanModalOpen,
      isDeleteAssignedActionModalOpen,
      isDeletePlanModalOpen,
      isPlanPreviewModalOpen,
    } = this.props;

    if (isApplyModalOpen) {
      return renderApplyModal(this.props);
    }
    if (isPlanModalOpen) {
      return renderPlanModal(this.props);
    }
    if (isDeleteAssignedActionModalOpen) {
      return renderDeleteActionModal(this.props);
    }
    if (isDeletePlanModalOpen) {
      return renderDeletePlanModal(this.props);
    }
    if (isPlanPreviewModalOpen) {
      return renderPlanPreviewModal(this.props);
    }
    return null;
  }

  renderEmptyState = (message) => <div className="trigger-table-empty-row">{message}</div>;

  renderLoadingState = () => <div className="table-empty-row">Loading Auto Plans..</div>;

  renderTableMessage(hasFilteredPlans) {
    const { platformAccount, assignedPlans, isFetchingAssignedPlans, planType } = this.props;
    const hasPlans = assignedPlans && assignedPlans.length > 0;

    if (!platformAccount) {
      return this.renderEmptyState(
        "Auto Plans is an exclusive Brivity Platform feature - call (866) 295-3322 and sign up today, or learn about the Brivity Platform in a 15 minute demo webinar.",
      );
    }
    if (!hasPlans && !isFetchingAssignedPlans) {
      return this.renderEmptyState(emptyPlanTypeMessage[planType]);
    }
    if (!hasPlans && isFetchingAssignedPlans) {
      return this.renderLoadingState();
    }
    if (hasPlans && !isFetchingAssignedPlans && !hasFilteredPlans) {
      return this.renderEmptyState("No plans match the filtered criteria");
    }
    return "";
  }

  render() {
    const {
      assignedPlans,
      showApplyModal,
      updateAssignedPlan,
      showPlanModal,
      toggleDeletedPlans,
      showDeletedPlans,
      toggleCompletedPlans,
      showDeleteAssignedPlanModal,
      showCompletedPlans,
      platformAccount,
    } = this.props;

    const filteredAssignedPlans = filterAssignedPlans(assignedPlans, showCompletedPlans, showDeletedPlans);
    const hasFilteredPlans = filteredAssignedPlans && filteredAssignedPlans.length > 0;

    return (
      <div className="assigned-plans white-panel">
        <div className="header-row">
          <div className="row">
            <div className="col-xs-2 ellipsify">
              <div className="header-row-title">
                <span className="text-danger bold">NEW </span> Auto Plans
              </div>
            </div>
            <div className="col-xs-2 ellipsify">
              <div className="column-header">Status</div>
            </div>
            <div className="col-xs-2 ellipsify">
              <div className="column-header">Progress</div>
            </div>
            <div className="col-xs-2 ellipsify">
              <div className="column-header">Next Due Action</div>
            </div>
            <div className="col-xs-4 text-right ellipsify">
              <div className="d-inline-flex p-r-10">
                {assignedPlans && assignedPlans.length > 0 && (
                  <div className="checkbox-inline">
                    <Checkbox onChange={toggleDeletedPlans} checked={showDeletedPlans}>
                      Show Deleted
                    </Checkbox>
                  </div>
                )}
                {assignedPlans && assignedPlans.length > 0 && (
                  <div className="checkbox-inline">
                    <Checkbox onChange={toggleCompletedPlans} checked={showCompletedPlans}>
                      Show Completed
                    </Checkbox>
                  </div>
                )}
              </div>
              {!platformAccount && (
                <span
                  className="action-link pseudo-link"
                  value="LIVE DEMO"
                  data-toggle="modal"
                  data-target="#request-demo-modal"
                >
                  Get a Demo
                </span>
              )}
              {platformAccount && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span className="add-auto-plan-link action-link pseudo-link" onClick={showApplyModal}>
                  {" "}
                  Add Auto Plan
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="white-panel-body">
          {this.renderTableMessage(hasFilteredPlans)}

          {filteredAssignedPlans?.map((plan) => (
            <AssignedPlanRow
              key={plan.id}
              assignedPlan={plan}
              onUpdate={updateAssignedPlan}
              onShowDeleteModal={showDeleteAssignedPlanModal}
              onShowPlanModal={showPlanModal}
            />
          ))}
        </div>
        {this.isModalOpen() && this.renderModal()}
      </div>
    );
  }
}

export default AssignedPlansTable;

AssignedPlansTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlans: PropTypes.arrayOf(PropTypes.any).isRequired,
  showApplyModal: PropTypes.func.isRequired,
  updateAssignedPlan: PropTypes.func.isRequired,
  showPlanModal: PropTypes.func.isRequired,
  toggleDeletedPlans: PropTypes.func.isRequired,
  showDeletedPlans: PropTypes.bool.isRequired,
  toggleCompletedPlans: PropTypes.func.isRequired,
  showCompletedPlans: PropTypes.bool.isRequired,
  showDeleteAssignedPlanModal: PropTypes.func.isRequired,
  isFetchingAssignedPlans: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  platformAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  planType: PropTypes.string.isRequired,
  isApplyModalOpen: PropTypes.bool.isRequired,
  isPlanModalOpen: PropTypes.bool.isRequired,
  isDeleteAssignedActionModalOpen: PropTypes.bool.isRequired,
  isDeletePlanModalOpen: PropTypes.bool.isRequired,
  isPlanPreviewModalOpen: PropTypes.bool.isRequired,
  getAssignedPlans: PropTypes.func.isRequired,
  assignedPlansPath: PropTypes.string.isRequired,
  assignableId: PropTypes.string.isRequired,
  assignableType: PropTypes.string.isRequired,
};
