import { schemaGenerator } from "../utils";

const BASE = ["tw-text-neutral-gray-75", "tw-not-italic", "tw-normal-case", "tw-m-0"];

const schemaDictionary = {
  headline1: {
    default: [...BASE, "tw-font-normal", "tw-text-28d"],
  },
  headline2: {
    default: [...BASE, "tw-font-bold", "tw-text-18d"],
  },
  headline3: {
    default: [...BASE, "tw-font-normal", "tw-text-18d"],
  },
  "subtitle-m-bold": {
    default: [...BASE, "tw-font-bold", "tw-text-14d"],
  },
  "subtitle-m": {
    default: [...BASE, "tw-font-semibold", "tw-text-14d"],
  },
  "subtitle-s": {
    default: [...BASE, "tw-font-semibold", "tw-text-12d"],
  },
  "body-m": {
    default: [...BASE, "tw-font-normal", "tw-text-14d"],
  },
  "body-s": {
    default: [...BASE, "tw-font-normal", "tw-text-12d"],
  },
  inherit: {
    default: [],
  },
};

const schema = schemaGenerator(schemaDictionary);

export default schema;
