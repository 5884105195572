/* global tinyMCE */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getExternalApi } from "@shared/externalApi";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

export const initialState = {
  apiUrl: "",
  archivePost: null,
  blogSettings: null,
  blogSettingsError: "",
  blogSettingsLoading: false,
  categories: [],
  categoriesError: "",
  categoriesLoading: false,
  idxConfiguration: {},
  post: null,
  postError: "",
  postLoading: false,
  postPublishStatusCounts: {
    all: 0,
    archived: 0,
    drafts: 0,
    published: 0,
  },
  postSaved: false,
  posts: [],
  postsError: "",
  postsLoading: false,
  sharePost: null,
  tags: [],
  tagsError: "",
  tagsLoading: false,
  totalPages: 1,
};

const transformPost = (post) => {
  const today = new Date();
  today.setHours(6, 0, 0, 0);
  return {
    ...post,
    dateToPublish: new Date(post.dateToPublish || today).toISOString(),
    meta: post.meta || { title: "", description: "" },
  };
};

const getIDXConfiguration = createAsyncThunk("website/getIDXConfiguration", (_, thunkApi) => {
  const userId = thunkApi.getState().layout.currentUser.id;
  return caseTransformingAxios
    .get(`/account/${userId}/idx_configuration.json`)
    .then((response) => response.data.data);
});

const getPostPublishStatusCounts = createAsyncThunk("website/getPostPublishStatusCounts", (_, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi.get("/Blog/PostPublishStatusCounts").then((response) => response.data);
});

const getBlogSettings = createAsyncThunk("website/getBlogSettings", (_, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi.get("/Blog").then((response) => response.data);
});

const updateBlogSettings = createAsyncThunk("website/updateBlogSettings", (blogSettings, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi.put("/Blog", blogSettings).then((response) => response.data);
});

const getPosts = createAsyncThunk("website/getPosts", ({ page, itemsPerPage, params }, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .get(`/Blog/Posts/${page}/${itemsPerPage}`, { params })
    .then((response) => response.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const getCategories = createAsyncThunk("website/getCategories", (_, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .get("/Category/false")
    .then((response) => response.data.map((category) => ({ ...category, categoryUuid: category.uuid })));
});

const upsertCategory = createAsyncThunk("website/upsertCategory", (category, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi[category.uuid ? "put" : "post"](
    `/Category/${category.uuid ? "Update" : "Create"}Organization`,
    category,
  )
    .then((response) => ({ ...response.data, categoryUuid: response.data.uuid }))
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const deleteCategory = createAsyncThunk("website/deleteCategory", (category, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .delete(`/Category/${category.uuid}`)
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const getTags = createAsyncThunk("website/getTags", (_, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .get("/Tag/false")
    .then((response) => response.data.map((tag) => ({ ...tag, tagUuid: tag.uuid })));
});

const upsertTag = createAsyncThunk("website/upsertTag", (tag, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi[tag.uuid ? "put" : "post"](`/Tag/${tag.uuid ? "Update" : "Create"}Organization`, tag)
    .then((response) => ({ ...response.data, tagUuid: response.data.uuid }))
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const deleteTag = createAsyncThunk("website/deleteTag", (tag, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .delete(`/Tag/${tag.uuid}`)
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const getPost = createAsyncThunk("website/getPost", (uuid, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi.get(`/Post/Details/${uuid}`).then((response) => transformPost(response.data));
});

const createPost = createAsyncThunk("website/createPost", (post, thunkApi) => {
  const { apiUrl, blogSettings } = thunkApi.getState().website;
  const websiteApi = getExternalApi(apiUrl);
  return websiteApi
    .post("/Post/Create", { createPost: true, blogUuid: blogSettings.uuid, ...post })
    .then((response) => response.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const updatePost = createAsyncThunk("website/updatePost", (post, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  if (!tinyMCE.get("blog-post").getContent({ format: "text" }).trim()) {
    return thunkApi.rejectWithValue({ content: true });
  }

  const newPost = new FormData();
  newPost.append("uuid", post.uuid);
  newPost.append("title", post.title);
  newPost.append("slug", post.slug);
  newPost.append("content", post.content);
  newPost.append("authorPersonUuid", post.author.uuid);
  newPost.append("categoryUuids", post.categories?.map((category) => category.categoryUuid).join(","));
  newPost.append("tagUuids", post.tags?.map((tag) => tag.tagUuid).join(","));
  if (!post.isPublished) newPost.append("dateToPublish", post.dateToPublish || "");
  if (typeof post.featuredImageUrl !== "string") newPost.append("featuredImage", post.featuredImageUrl);
  newPost.append("featuredImageAltText", post.featuredImageAltText);
  if ((post.meta.title || "").trim()) newPost.append("meta.title", post.meta.title);
  if ((post.meta.description || "").trim()) newPost.append("meta.description", post.meta.description);

  return websiteApi
    .put("/Post/Update", newPost)
    .then((response) => transformPost(response.data))
    .catch((err) =>
      thunkApi.rejectWithValue(
        typeof err.response.data === "string" ? err.response.data : err.response.data.errors.updatePost?.[0],
      ),
    );
});

const publishPost = createAsyncThunk("website/publishPost", (post, thunkApi) => {
  const newPost = { ...post, isPublished: !post.isPublished };
  if (newPost.isPublished) {
    newPost.datePublished = new Date().toISOString();
  } else {
    newPost.datePublished = null;
  }
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .put(`/Post/${post.isPublished ? "Unpublish" : "Publish"}/${post.uuid}`)
    .then(() => newPost);
});

const archivePost = createAsyncThunk("website/archivePost", (post, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  const newPost = { ...post, isArchived: !post.isArchived };
  if (newPost.isArchived) {
    newPost.dateArchived = new Date().toISOString();
  } else {
    newPost.dateArchived = null;
  }
  newPost.datePublished = null;
  newPost.dateToPublish = null;
  return websiteApi
    .put(`/Post/${post.isArchived ? "ActivateArchived" : "Archive"}/${post.uuid}`)
    .then(() => newPost);
});

const duplicatePost = createAsyncThunk("website/duplicatePost", (post, thunkApi) => {
  const websiteApi = getExternalApi(thunkApi.getState().website.apiUrl);
  return websiteApi
    .post("/Post/Duplicate", post)
    .then((response) => response.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

const websiteSlice = createSlice({
  initialState,
  name: "website",
  reducers: {
    clearErrors: (state) => {
      state.blogSettingsError = "";
      state.categoriesError = "";
      state.tagsError = "";
    },
    setArchivePost(state, action) {
      state.archivePost = action.payload;
    },
    setSharePost(state, action) {
      state.sharePost = action.payload;
    },
    setPostSaved: (state, action) => {
      state.postSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPostPublishStatusCounts.fulfilled, (state, action) => {
      state.postPublishStatusCounts = action.payload;
    });

    builder
      .addCase(getBlogSettings.pending, (state) => {
        state.blogSettingsLoading = true;
      })
      .addCase(getBlogSettings.fulfilled, (state, action) => {
        state.blogSettings = action.payload;
        state.blogSettingsLoading = false;
      })
      .addCase(getBlogSettings.rejected, (state) => {
        state.blogSettingsError = "Whoops something went wrong.";
        state.blogSettingsLoading = false;
      });

    builder
      .addCase(updateBlogSettings.pending, (state) => {
        state.blogSettingsError = "";
        state.blogSettingsLoading = true;
      })
      .addCase(updateBlogSettings.fulfilled, (state, action) => {
        // TODO: utilize payload once given by BE
        state.blogSettings = action.meta.arg;
        state.blogSettingsLoading = false;
      })
      .addCase(updateBlogSettings.rejected, (state) => {
        state.blogSettingsError = "Whoops something went wrong.";
        state.blogSettingsLoading = false;
      });

    builder.addCase(getIDXConfiguration.fulfilled, (state, action) => {
      state.idxConfiguration = action.payload || {};
    });

    builder
      .addCase(getPosts.pending, (state) => {
        state.postsLoading = true;
        state.postsError = "";
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.posts = action.payload.items;
        state.totalPages = action.payload.totalPages;
        state.postsLoading = false;
      })
      .addCase(getPosts.rejected, (state, action) => {
        state.postsError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.getPosts?.[0];
        state.postsLoading = false;
      });

    builder
      .addCase(getCategories.pending, (state) => {
        state.categoriesLoading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.categoriesLoading = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categoriesError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.updateCategory?.[0];
        state.categoriesLoading = false;
      });

    builder
      .addCase(upsertCategory.pending, (state) => {
        state.categoriesError = "";
      })
      .addCase(upsertCategory.fulfilled, (state, { meta: { arg }, payload }) => {
        if (arg.uuid) {
          state.categories = state.categories.map((category) =>
            category.uuid === payload.uuid ? payload : category,
          );
        } else {
          state.categories.push(payload);
        }
      })
      .addCase(upsertCategory.rejected, (state, action) => {
        state.categoriesError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.updateCategory?.[0];
      });

    builder.addCase(deleteCategory.fulfilled, (state, { meta: { arg } }) => {
      state.categories = state.categories.filter((category) => category.uuid !== arg.uuid);
    });

    builder
      .addCase(getTags.pending, (state) => {
        state.tagsLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.tags = action.payload;
        state.tagsLoading = false;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.tagsError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.updateTag?.[0];
        state.tagsLoading = false;
      });

    builder
      .addCase(upsertTag.pending, (state) => {
        state.tagsError = "";
      })
      .addCase(upsertTag.fulfilled, (state, { meta: { arg }, payload }) => {
        if (arg.uuid) {
          state.tags = state.tags.map((tag) => (tag.uuid === payload.uuid ? payload : tag));
        } else {
          state.tags.push(payload);
        }
      })
      .addCase(upsertTag.rejected, (state, action) => {
        state.tagsError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.updateTag?.[0];
      });

    builder.addCase(deleteTag.fulfilled, (state, { meta: { arg } }) => {
      state.tags = state.tags.filter((tag) => tag.uuid !== arg.uuid);
    });

    builder
      .addCase(getPost.pending, (state) => {
        state.postLoading = true;
        state.postSaved = false;
        state.post = null;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.post = action.payload;
        state.postLoading = false;
      })
      .addCase(getPost.rejected, (state, action) => {
        state.postError =
          typeof action.payload === "string" ? action.payload : action.payload.errors.getPost?.[0];
        state.postLoading = false;
      });

    builder
      .addCase(updatePost.pending, (state) => {
        state.postError = "";
        state.postLoading = true;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.post = action.payload;
        state.postLoading = false;
        state.postSaved = true;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.postError = action.payload;
        state.postLoading = false;
      });

    builder
      .addCase(publishPost.fulfilled, (state, action) => {
        if (state.post?.uuid === action.payload.uuid) state.post = action.payload;
        state.posts = state.posts.map((post) => (post.uuid === action.payload.uuid ? action.payload : post));
      })
      .addCase(publishPost.rejected, (state, action) => {
        state.postError = action.payload;
      });

    builder
      .addCase(archivePost.fulfilled, (state, action) => {
        if (action.payload.isArchived) {
          state.postPublishStatusCounts.archived += 1;
          if (action.payload.isPublished) state.postPublishStatusCounts.published -= 1;
          else state.postPublishStatusCounts.drafts -= 1;
        } else {
          state.postPublishStatusCounts.archived -= 1;
          state.postPublishStatusCounts.drafts += 1;
        }
        action.payload.isPublished = false;

        if (state.post?.uuid === action.payload.uuid) state.post = action.payload;
        state.posts = state.posts.map((post) => (post.uuid === action.payload.uuid ? action.payload : post));
      })
      .addCase(archivePost.rejected, (state, action) => {
        state.postError = action.payload;
      });

    builder
      .addCase(createPost.pending, () => {})
      .addCase(duplicatePost.fulfilled, (state, action) => {
        state.postPublishStatusCounts.all += 1;
        state.postPublishStatusCounts.drafts += 1;
        state.posts = [action.payload, ...state.posts];
      })
      .addCase(duplicatePost.rejected, (state, action) => {
        state.postsError = action.payload;
      });
  },
});

export {
  archivePost,
  createPost,
  deleteCategory,
  deleteTag,
  duplicatePost,
  getBlogSettings,
  getCategories,
  getIDXConfiguration,
  getPost,
  getPostPublishStatusCounts,
  getPosts,
  getTags,
  publishPost,
  updateBlogSettings,
  updatePost,
  upsertCategory,
  upsertTag,
};

export const { clearErrors, setArchivePost, setPostSaved, setSharePost } = websiteSlice.actions;

export default websiteSlice.reducer;
