import React from "react";
import { bool } from "prop-types";

import { VisibilityVisible, VisibilityHidden } from "../../../../shared/v2/Icomoon";

const EventVisibility = ({ externallyVisible }) =>
  externallyVisible ? (
    <VisibilityVisible size="xl" className="tw-ml-auto" />
  ) : (
    <VisibilityHidden size="xl" className="tw-ml-auto tw-text-gray-50" />
  );

EventVisibility.propTypes = {
  externallyVisible: bool,
};

EventVisibility.defaultProps = {
  externallyVisible: false,
};

export default EventVisibility;
