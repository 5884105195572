import React from "react";

import Dropdown from "../../../../../shared/v2/Dropdown";
import { customDefaultProps, customPropTypes } from "../../../../../shared/v2/Dropdown/utils";
import { Trash } from "../../../../../shared/v2/Icomoon";
import IconButton from "../../../../../shared/v2/IconButton";

const MemberMultiValueRemove = ({ componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.MultiValueRemove {...componentProps}>
    <IconButton size="small" schema="misc-trash">
      <Trash />
    </IconButton>
  </Dropdown.MultiValueRemove>
);

MemberMultiValueRemove.propTypes = customPropTypes;

MemberMultiValueRemove.defaultProps = customDefaultProps;

export default MemberMultiValueRemove;
