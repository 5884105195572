import React from 'react';
import { Alert } from 'react-bootstrap';

const DismissibleAlert = (props) => {
  if (props.displayAlert) {
    return (
      <Alert
        bsStyle={props.variant}
        onDismiss={props.handleDismissAlert}
      >
        {props.content}
      </Alert>
    );
  } else {
    return null;
  }
};

export default  DismissibleAlert;
