import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export default class PublicActivityAppointmentEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }
  headline = () => {
    const agent_person = this.event.agent_person
    const from = `${agent_person.first_name} ${agent_person.last_name}`.trim()
    const user_full_name = this.props.event.created_by_name;
    switch(this.event.key) {
      case "interaction.create":
        var interaction_type = "scheduled"
        break;
      case "interaction.update":
        var interaction_type = "updated"
        break;
      case "interaction.complete":
        var interaction_type = "completed"
        break;
      default:
        var interaction_type = "updated"
        break;
    }

      if( user_full_name !== null && user_full_name.localeCompare(from)){
        return (
          <div>
            <span className="bold-detail">{user_full_name}</span>
            {` ${interaction_type} an appointment for `}
            <span className="bold-detail">{from}</span>
          </div>
        );
      }
      if(user_full_name === null){
        return (
          <div>
            <span className="bold-detail">an appointment was</span>
            {` ${interaction_type} for ${from}`}
          </div>
        );
      }


    return (
      <div>
        <span className="bold-detail">{from}</span>
        {` ${interaction_type} an appointment`}
      </div>
    );
  }

  details = () => {
    const appointmentType = this.event.object_attributes.appointment_type
    const outcome = this.event.object_attributes.outcome
    const notes = this.event.object_attributes.comment
    const location = this.event.object_attributes.location

    const appointmentTypeDiv = (
      <div className="row details-row">
        Appointment Type: {appointmentType}
      </div>
    )

    const outcomeDiv = (
      <div className="row details-row">
        Outcome: {outcome}
      </div>
    )

    const notesDiv = (
      <div className="row details-row">
        Notes: {notes}
      </div>
    )

    const locationDiv = (
      <div className="row details-row">
        Location: {location}
      </div>
    )

    return (
      <div>
        <div className="row bold-detail details-row details-header">
          Details:
        </div>

        <div className="row details-row">
          Appointment Time: {moment(this.event.object_attributes.scheduled_at).format('LLL')}
        </div>

        {!!appointmentType && appointmentTypeDiv}

        {!!outcome && outcomeDiv}

        {!!notes && notesDiv}

        {!!location && locationDiv}
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-calendar-check-o timeline-icon-circle"/>
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              Appointment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.headline()}
            {this.details()}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
