import React from "react";
import Proptypes from "prop-types";
import AgreementDateSelect from "./AgreementDateSelect";
import AgreementStatusSelect from "./AgreementStatusSelect";
import AgreementTypeSelect from "./AgreementTypeSelect";

const AgreementFilters = ({
  previousSelectedAgreementType,
  previousSelectedStatus,
  previousSelectedExpirationDate,
  previousSelectedSignedDate,
  expirationDateOptions,
  signedDateOptions,
}) => (
  <>
    <AgreementTypeSelect previousSelectedAgreementType={previousSelectedAgreementType} />
    <AgreementStatusSelect previousSelectedStatus={previousSelectedStatus} />
    <AgreementDateSelect
      previousSelectedExpirationDate={previousSelectedExpirationDate}
      previousSelectedSignedDate={previousSelectedSignedDate}
      expirationDateOptions={expirationDateOptions}
      signedDateOptions={signedDateOptions}
    />
    <div className="tw-mt-[25px]">
      <hr />
    </div>
  </>
);

AgreementFilters.propTypes = {
  previousSelectedAgreementType: Proptypes.arrayOf(Proptypes.string),
  previousSelectedStatus: Proptypes.arrayOf(Proptypes.string),
  previousSelectedExpirationDate: Proptypes.arrayOf(Proptypes.string),
  previousSelectedSignedDate: Proptypes.arrayOf(Proptypes.string),
  signedDateOptions: Proptypes.arrayOf(Proptypes.shape({ label: Proptypes.string, value: Proptypes.string })),
  expirationDateOptions: Proptypes.arrayOf(
    Proptypes.shape({ label: Proptypes.string, value: Proptypes.string }),
  ),
};
AgreementFilters.defaultProps = {
  previousSelectedAgreementType: [],
  previousSelectedStatus: [],
  previousSelectedExpirationDate: [],
  previousSelectedSignedDate: [],
  signedDateOptions: [],
  expirationDateOptions: [],
};
export default AgreementFilters;
