/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChartBarRegularV6 = (props) => (
  <Icon {...props} name="Chart Bar Regular V6">
    <path d="M.75 1a.75.75 0 0 1 .75.75v11c0 .416.336.75.75.75h13c.416 0 .75.334.75.75s-.334.75-.75.75h-13A2.25 2.25 0 0 1 0 12.75v-11A.75.75 0 0 1 .75 1zM4 4.25c0-.416.334-.75.75-.75h6.5c.416 0 .75.334.75.75s-.334.75-.75.75h-6.5A.748.748 0 0 1 4 4.25zM9.25 6.5c.416 0 .75.334.75.75S9.666 8 9.25 8h-4.5C4.334 8 4 7.666 4 7.25s.334-.75.75-.75h4.5zm4 3c.416 0 .75.334.75.75s-.334.75-.75.75h-8.5c-.416 0-.75-.334-.75-.75s.334-.75.75-.75h8.5z" />
  </Icon>
);

export default ChartBarRegularV6;
