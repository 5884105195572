/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import { orderBy } from "lodash";

import { editTasks, deleteTask } from "./creators";

import FILTER_CALLBACKS from "./constants";

export const sortTasks = (tasksData) => {
  const tasks = [...tasksData];

  let completedTasks = [];
  let allOtherTasks = [];
  tasks.each((task) => (task.completed_at ? completedTasks.push(task) : allOtherTasks.push(task)));

  const properties = (dateProperty) => [
    (task) => new Date(task[dateProperty]),
    "assignedPlan.id",
    "assignedPlan.position",
    "assigned_person.fullName",
    "priority",
  ];

  completedTasks = orderBy(completedTasks, properties("completed_at"), ["desc", "asc", "desc"]);
  allOtherTasks = orderBy(allOtherTasks, properties("due_on"), ["asc", "desc", "asc", "asc", "desc"]);

  return allOtherTasks.concat(completedTasks);
};

export const resetFilters = (draft) => {
  draft.primaryActiveList = [];
  draft.filtered = false;
  draft.secondaryFilters.activeTeamMembers = [];
  draft.secondaryFilters.activeUnassigned = false;
  draft.secondaryFilters.savedStateTeamMembers = [];
};

export const dispatchEditedTasks = (dispatch, payload) => (adaptedEditedTasks, filtered) => {
  const action = payload["_destroy"]
    ? deleteTask(adaptedEditedTasks.map(({ id }) => id))
    : editTasks(adaptedEditedTasks);

  dispatch(action);

  if (filtered) {
    dispatch(FILTER_CALLBACKS[filtered]());
  }
};
