import React from "react";
import _ from "lodash";
import capitalize from "capitalize";
import axios from "axios";
import { Col, Modal, Row } from "../../shared/v1";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import moment from "moment";
import TaskDetailsInformation from "./TaskDetailsInformation";
import TaskInstructions from "./TaskInstructions";
import TaskNotes from "./TaskNotes";
import PrioritySelection from "./PrioritySelection";
import Errors from "./Errors";
import AppointmentTypeSelection from "./AppointmentTypeSelection";
import AssignTaskToSelection from "./AssignTaskToSelection";
import TaskableAssignment from "./TaskableAssignment";
import ContingencyRuleSelection from "./ContingencyRuleSelection";
import LocationInput from "./LocationInput";
import OutcomeSelection from "./OutcomeSelection";
import CalendarOptions from "./CalendarOptions";
import EventDropdown from "./EventDropdown";
import TaskFormFooter from "./TaskFormFooter";
import Dropdown from "../../shared/v2/Dropdown";
import TextInput from "../../shared/v2/TextInput";
import DatePicker from "../../shared/v2/DatePicker/DatePicker";
import { DATE_FORMAT } from "../../shared/v2/DateSelector/utils/constants";
import { createTask } from "./services";
import FieldLabel from "../../shared/v2/FieldLabel";
import Button from "../../shared/v2/Button";
import { ArrowsRotateSolidV6, MagnetSolidV6 } from "../../shared/v2/Icomoon";

const RECURRING_OPTIONS = [
  { value: "number", label: "Every _ Days" },
  { value: "weekly", label: "Weekly" },
];

const RECURRING_DOW_OPTIONS = [
  { value: 1, label: "On Mondays" },
  { value: 2, label: "On Tuesdays" },
  { value: 3, label: "On Wednesdays" },
  { value: 4, label: "On Thursdays" },
  { value: 5, label: "On Fridays" },
  { value: 6, label: "On Saturdays" },
  { value: 7, label: "On Sundays" },
];

export const defaultTask = {
  name: "",
  id: null,
  priority: 9,
  visibility: "",
  instructions: "",
  account_id: "",
  note: "",
  assigned_to_user_id: "",
  contingency_rule: {},
  contingency_event_and_type: [],
  taskable_id: "",
  taskable_type: "",
  taskable_name: "",
  appointment_type: "",
  outcome: "",
  location: "",
  externally_visible: false,
  contingent: false,
  recurring: false,
  showInstructions: false,
  showNotes: false,
  completed: null,
  user_id: "",
};

const priorityNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
class TaskForm extends React.Component {
  static contextType = Modal.Context;

  constructor(props) {
    super(props);
    const task = props.task || {};
    const outcome = task.outcome || "";
    const location = task.location || "";
    const completed = props.completed || false;
    const taskable = props.taskable || {};
    const user_id = task.user_id || "";
    defaultTask.appointment_type = taskable.appointment_type || null;
    defaultTask.taskable_id = props.task_for ? props.task_for.uuid : taskable ? taskable.uuid : "";
    defaultTask.taskable_type = taskable.taskable_type || "";
    defaultTask.taskable_name = taskable.task_name || "";
    defaultTask.outcome = taskable.outcome || "";
    defaultTask.location = taskable.location || "";
    defaultTask.showInstructions = task.instructions;
    defaultTask.showNotes = task.note;
    defaultTask.user_id = user_id || "";
    defaultTask.due_on = moment().format("YYYY-MM-DD");
    defaultTask.scheduled_time = moment().add(1, "hours").startOf("hour").unix();

    let task_prop = props.task;
    let default_priority = (task_prop && task_prop.priority) || 9;
    this.state = {
      task: {
        ...defaultTask,
        ...task_prop,
        priority: default_priority,
        account_id: props.accountUuid,
        outcome,
        location,
        completed,
        user_id,
      },
      addTo: false,
      showRecurring: task.recurring,
      showContingency: task.contingent,
      showDue: !task.contingent,
      disabledButton: false,
      saveButtonText: "Save Details",
      agents: this.props.agents,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.toggleContingent = this.toggleContingent.bind(this);
    this.toggleRecurring = this.toggleRecurring.bind(this);
    this.focusTextInput = this.focusTextInput.bind(this);
    this.updateTask = TaskHelpers.updateTask.bind(this);
    this.closeModal = this.props.closeModal.bind(this);
    this.createTask = TaskHelpers.createTask.bind(this);
    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this);
    this.handleRecurrence = TaskHelpers.handleRecurrence.bind(this);
    this.handleMultiSelect = TaskHelpers.handleMultiSelect.bind(this);
    this.completeTask = TaskHelpers.completeTask.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);
    this.visibilityOptions = this.visibilityOptions.bind(this);
    this.handleAssignedToUser = TaskHelpers.handleAssignedToUser.bind(this);
    this.tryCatchHandlr = TaskHelpers.tryCatchHandlr.bind(this);
    this.handleGoogleCalendar = TaskHelpers.handleGoogleCalendar.bind(this);
  }

  handleAddToState = () => {
    this.setState((prevState) => ({
      addTo: !prevState.addTo,
    }));
  };
  handleContingencyRuleChange = (e) => {
    let { contingency_rule } = this.state.task;
    contingency_rule[e.target.name] = e.target.value;
    this.setState({
      ...this.state,
      ...{ task: { ...this.state.task, ...contingency_rule } },
    });
  };

  handleDueOnChange = (value) => {
    const momentValue = moment(value, "MM/DD/YYYY hh:mm A");
    this.setState({
      task: {
        ...this.state.task,
        due_on: momentValue.format("YYYY-MM-DD"),
        scheduled_time: momentValue.unix(),
      },
      // moment returns minutes, Date uses miliseconds
      disableCompletedCheckbox: momentValue.unix() * 1000 > Date.now(),
    });
  };

  handleAgreementCallback = () => {
    const AGREEMENT_OUTCOMES = {
      'Signed - Buyer Agreement': 'buyer',
      'Signed - Listing Agreement': 'seller',
      'Signed - Both Buyer/Listing': 'both',
    };

    if (AGREEMENT_OUTCOMES[this.state.task.outcome] && this.props.agreementCallBack) {
      this.props.agreementCallBack(AGREEMENT_OUTCOMES[this.state.task.outcome]);
      return true;
    }

    return false;
  };

  async handleSubmit(event) {
    if (this.state.task.type === "Appointment" && !this.state.task.appointment_type) {
      this.props.showErrors(["Appointment Type is required"]);
      return;
    }
    if (!this.state.task.taskable_id) {
      this.props.showErrors(["Must select person, transaction or project"]);
      return;
    }
    if (this.props.personTaskForm) {
      if (!this.state.task.priority) {
        this.props.showErrors(["Priority is required"]);
        return;
      }
      this.setState({ disabledButton: true });
      this.props.showErrors("");
      const task = this.state.task;
      const formData = { ...task };

      //Edit
      if (this.state.task.id) {
        this.updateTask(this.state.task)
          .then(() => {
            this.props.updateTask?.(this.state.task)

            if (this.handleAgreementCallback()) return;
          })
          .catch(console.log);
        //Create
      } else {
        const [res, err] = await createTask({ formData });
        if (err) {
          this.props.showErrors(TaskHelpers.parseErrors(err));
          this.setState({ disabledButton: false });
        } else {
          this.handleGoogleCalendar(
            this.state.task,
            this.state.agents,
            this.props.user,
            this.props.contactEmail,
            this.state.addTo,
          );
          this.setState({ disabledButton: false });
          this.props.getCardTasks(task.taskable_id);
          this.closeModal();

          if (this.handleAgreementCallback()) return;
        }

        if (this.state.task.type === "Appointment" && !this.props.isAppointment) {
          window.location.reload();
        }
      }
    } else {
      if (this.state.task.id) {
        this.updateTask(this.state.task);
        this.props.showErrors("");
      } else {
        await this.createTask(
          this.state.task,
          this.state.agents,
          this.props.user,
          this.props.contactEmail,
          this.state.addTo,
          this.props.listingUUID,
          this.handleAgreementCallback,
        );

        this.setState({ task: { ...defaultTask, account_id: this.props.accountUuid } });
        this.props.showErrors("");
      }
    }
  }

  handleAppointmentType = ({ value }) =>
    this.setState({ task: { ...this.state.task, appointment_type: value } });

  handleOutcomeOnChange = (outcome) => this.setState({ task: { ...this.state.task, outcome: outcome } });

  handleLocationOnChange = (e) => this.setState({ task: { ...this.state.task, location: e.target.value } });

  toggleCompleteTask = () =>
    this.setState({
      task: {
        ...this.state.task,
        completed: this.state.task.completed ? undefined : "true",
        outcome: this.state.task.completed ? undefined : this.state.task.outcome,
      },
    });

  toggleInstructions = (e) => {
    e.preventDefault();
    this.setState({
      task: { ...this.state.task, showInstructions: !this.state.task.showInstructions, instructions: null },
    });
  };

  toggleNotes = (e) => {
    e.preventDefault();
    this.setState({ task: { ...this.state.task, showNotes: !this.state.task.showNotes, note: null } });
  };

  toggleContingent = (e) => {
    e.preventDefault();
    this.setState({
      showContingency: !this.state.showContingency,
      showDue: !this.state.showDue,
      task: { ...this.state.task, contingent: !this.state.showContingency },
    });
  };

  toggleRecurring = (e) => {
    e.preventDefault();
    this.setState({
      showRecurring: !this.state.showRecurring,
      task: { ...this.state.task, recurring: !this.state.showRecurring },
    });
  };

  focusTextInput() {
    this.textInput.focus();
  }

  taskableOptions = (taskable_type) => {
    let task_options_for_taskable = this.taskableOptionsHash()[taskable_type];
    if (
      this.state.task.contingency_event_and_type.length == 0 ||
      this.state.task.contingency_event_and_type[0] == "milestone"
    ) {
      return task_options_for_taskable;
    } else {
      let current_contingent_event = [
        {
          value: this.state.task.contingency_event_and_type.join("-"),
          label: _.startCase(
            this.state.task.parent_task_name || this.state.task.contingency_event_and_type[1],
          ),
        },
      ];
      return current_contingent_event.concat(task_options_for_taskable);
    }
  };

  taskableOptionsHash = () => {
    const { accountMilestoneNames } = this.props;
    return {
      Person: this.milestonableMilestoneOptions(accountMilestoneNames.Person || accountMilestoneNames.person),
      Listing: this.milestonableMilestoneOptions(
        accountMilestoneNames.Listing || accountMilestoneNames.listing,
      ),
      Project: this.milestonableMilestoneOptions(
        accountMilestoneNames.Project || accountMilestoneNames.project,
      ),
    };
  };

  milestonableMilestoneOptions = (milestones) => {
    let array = [];
    milestones.forEach(function (milestone) {
      array.push({
        value: `milestone-${milestone}`,
        label: milestone
          .split("_")
          .map((element) => {
            return capitalize(element);
          })
          .join(" "),
      });
    });

    return array;
  };

  taskTypeOptions() {
    const taskTypes = [
      "To-Do",
      !this.props.taskable ? "Appointment" : null,
      "Call",
      "Text",
      "Email",
      "Mail",
      "Social Media",
      "Door Knock",
    ];
    return taskTypes.filter(Boolean).map(function (taskType) {
      return { value: taskType, label: taskType };
    });
  }
  appointmentTypeOptions() {
    const realEstateTypes = [
      "Buyer Consultation",
      "Listing Consultation",
      "Buyer/Listing Consultation",
      "Showing Appointment",
      "Client Meeting",
      "General",
      "Follow Up",
    ].map((v) => ({ value: v, label: v }));
    const recruitingTypes = [
      "Meet & Greet",
      "Screening",
      "Recruiting Appointment",
      "Signing Appointment",
      "Recruiting Follow Up",
    ].map((v) => ({ value: v, label: v }));

    return [
      { label: "Real Estate", options: realEstateTypes },
      { label: "Recruiting", options: recruitingTypes },
    ];
  }

  assignTaskToLogForm(user) {
    const { task } = this.state;
    task["assigned_to_user_id"] = user.uuid;
    this.setState({ task });
  }

  visibilityOptions() {
    return [
      {
        value: false,
        label: (
          <div>
            <i className="fa fa-eye-slash p-r-10" />
            <span className="text-base">Hidden from Viewers</span>
          </div>
        ),
      },
      {
        value: true,
        label: (
          <div>
            <i className="fa fa-eye p-r-10" />
            <span className="text-base">Visible to Viewers</span>
          </div>
        ),
      },
    ];
  }

  taskForWatcher = (result) => {
    const taskable_name = result.label !== undefined ? result.label.split(" ").slice(0, 2).join(" ") : null;
    const taskable_email = result.label !== undefined ? result.label.split(" ").slice(2).join(" ") : null;
    this.setState({
      task: {
        ...this.state.task,
        taskable_type: result.object_type,
        taskable_id: result.uuid,
        taskable_name,
        taskable_email,
      },
    });
  };

  taskForLog = (person) => {
    this.setState({
      task: {
        ...this.state.task,
        taskable_type: "Person",
        taskable_id: person.uuid,
      },
    });
  };

  taskForAppointment = (person) => {
    const { task } = this.state;
    task["taskable_type"] = "Person";
    task["taskable_id"] = person.data.attributes.uuid;
    task["taskable_name"] = `${person.data.attributes.first_name} ${person.data.attributes.last_name}`;
    this.setState({ task });
  };

  taskForLogAppointment = (person) => {
    const { task } = this.state;
    task["taskable_type"] = "Person";
    task["taskable_id"] = person.uuid;
    task["taskable_name"] = person.name ? `${person.name}` : `${person.first_name} ${person.last_name}`;
    this.setState({ task });
  };

  handleAccountId = () => {
    const { task } = this.state;
    task["account_id"] = this.props.accountUuid;
    this.setState({ task });
  };

  handleUserId = () => {
    const { task } = this.state;
    if (this.props.user !== undefined) {
      task["user_id"] = this.props.user.uuid;
    }
    this.setState({ task });
  };

  taskPriorityWatcher = (priority) => {
    this.setState({ task: { ...this.state.task, priority: priority } });
  };

  logFormInformation = () => {
    this.assignTaskToLogForm(this.props.user);
    this.taskForLog(this.props.person);
    this.taskForLogAppointment(this.props.person);
    this.handleAccountId();
    this.handleUserId();
  };
  createAppointmentInformation = () => {
    this.taskForAppointment(this.props.person);
    this.handleAccountId();
    this.assignTaskToLogForm(this.props.user);
  };

  componentDidMount() {
    this.handleUserId();
    this.props.isSchedulingAppointment
      ? this.logFormInformation()
      : this.props.isCreatingAppointment
        ? this.createAppointmentInformation()
        : null;
    this.state.task.state === "completed" && this.toggleCompleteTask();

    //TODO: determine if it is appropriate to load
    //agents after the modal has opened for all uses of TaskForm
    if (this.props.agentsNeedLoading === true) {
      axios
        .get("/account_active_agents", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.setState({
            agents: response.data.map((agent) => ({
              label: agent.name,
              value: agent.uuid,
              email: agent.email,
            })),
          });
        });
    } else {
      this.setState({ agents: this.props.agents });
    }
  }

  render() {
    const { task, saveButtonText, agents } = this.state;
    const {
      isSchedulingAppointment,
      newTask,
      isCreatingAppointment,
      editTaskFor,
      personTaskForm,
      errors,
      createTask,
      handleTaskChange,
      taskDetails,
      new_appointment,
      closeModal,
      task_for,
      deleteButton,
      editToDelete,
      submitButton,
      isAppointment,
      isEditing,
      isfromIndex,
      hideCancel,
    } = this.props;
    isSchedulingAppointment || isCreatingAppointment || isAppointment ? (task.type = "Appointment") : null;
    let inputProps = { disabled: this.props.taskDetails ? true : false };
    const DATE_TIME_FORMAT = task.type === "Appointment" ? `${DATE_FORMAT} hh:mm A` : DATE_FORMAT;

    // Fetch modal variant from context or use modern variant by default. There are usages of the
    // TaskForm component without a Modal parent component, thus leading to missing style due to
    // undefined variant.
    // When `isCreatingAppointment` is true, it uses classic variant by default.
    const { variant: variantFromContext } = this.context;
    const modalBodyVariant = variantFromContext || (isCreatingAppointment ? "classic" : "modern");

    //CHECKME: isSchedulingAppointment is coming from TaskFormLogAppointment.jsx , where the TaskForm is getting called from lead or people IDX
    //CHECKME: handleLocationOnChange is coming from TaskForm.jsx
    //CHECKME: personTaskForm is coming from PersonTaskForm.jsx where the TaskForm is getting called in the PDP page from the + on tasks and appointments
    //CHECKME: editTaskFor is coming from TaskIndexModalHelpers.jsx where TaskForm is getting called for editing
    //CHECKME: isCreatingAppointment is coming from the PersonInteractions.jsx which called the  appointment tab on PDP page
    //CHECKME: createTask is coming from the NewTask.jsx which is called when + is click on Task idx
    return (
      <div>
        <Modal.Body
          variant={modalBodyVariant}
          className={`!tw-pb-0 ${isfromIndex && isSchedulingAppointment && "tw-mx-[-32px]"}`}
        >
          <Errors errors={errors} />
          <form className="task-form">
            <Row>
              <TaskDetailsInformation
                personTaskForm={personTaskForm}
                handlePersonTaskChange={handleTaskChange}
                handleTaskChange={this.handleTaskChange}
                task={task}
                toggleInstructions={this.toggleInstructions}
                toggleNotes={this.toggleNotes}
                taskDetails={taskDetails || ""}
              />
              {task.showInstructions && (
                <TaskInstructions
                  task={task}
                  toggleInstructions={this.toggleInstructions}
                  handleTaskChange={this.handleTaskChange}
                />
              )}
              {task.showNotes && (
                <TaskNotes
                  task={task}
                  toggleNotes={this.toggleNotes}
                  handleTaskChange={this.handleTaskChange}
                />
              )}

              <PrioritySelection
                priorityNumbers={priorityNumbers}
                taskPriorityWatcher={this.taskPriorityWatcher}
                task={task}
              />

              {!isSchedulingAppointment &&
                !isCreatingAppointment &&
                (!task.id || task.type !== "Appointment") && (
                  <Col size={6} className="tw-mb-[16px]">
                    <Dropdown
                      label="Task Type"
                      isRequired
                      menuShouldComeToFront
                      options={this.taskTypeOptions()}
                      name="type"
                      onChange={(option, handler) =>
                        this.handleTaskChange({ target: { name: handler.name, value: option.value } })
                      }
                      value={
                        this.taskTypeOptions().find((opt) => opt.value === task.type) ||
                        this.taskTypeOptions()[0]
                      }
                      isDisabled={editTaskFor || taskDetails || new_appointment ? true : false}
                    />
                  </Col>
                )}

              {task.type === "Appointment" ? (
                <>
                  <AppointmentTypeSelection
                    task={task}
                    taskDetails={taskDetails}
                    isCreatingAppointment={isCreatingAppointment}
                    appointmentTypeOptions={this.appointmentTypeOptions}
                    handleAppointmentType={this.handleAppointmentType}
                  />
                  {isSchedulingAppointment && this.state.agents && (
                    <AssignTaskToSelection
                      handleTaskChange={this.handleTaskChange}
                      task={this.state.task}
                      agents={this.state.agents}
                      taskDetails={taskDetails}
                    />
                  )}
                </>
              ) : (
                <AssignTaskToSelection
                  handleTaskChange={this.handleTaskChange}
                  task={this.state.task}
                  agents={this.state.agents}
                  taskDetails={taskDetails}
                />
              )}

              {this.props.showTaskFor && !isSchedulingAppointment && (
                <TaskableAssignment taskForWatcher={this.taskForWatcher} task_for={task_for} />
              )}
              {task.type === "Appointment" && !isSchedulingAppointment && this.state.agents && (
                <AssignTaskToSelection
                  handleTaskChange={this.handleTaskChange}
                  task={task}
                  agents={this.state.agents}
                  taskDetails={taskDetails}
                  isCreatingAppointment={isCreatingAppointment}
                />
              )}
              {(this.state.showDue && !this.state.showContingency) || task.type === "Appointment" ? (
                <Col size={task.type === "Appointment" ? 6 : 12} className="tw-mb-[16px]">
                  <FieldLabel
                    className="tw-mb-[8px]"
                    htmlFor="due_on"
                    isRequired
                    label={`${taskDetails ? "Due Date" : "Date"} ${task.type === "Appointment" ? "& Time" : ""
                      }`}
                  />
                  <DatePicker
                    value={
                      task.scheduled_time
                        ? moment.unix(task.scheduled_time).format(DATE_TIME_FORMAT)
                        : moment(task.due_on, "YYYY-MM-DD").format(DATE_TIME_FORMAT)
                    }
                    enableTimePicker={task.type === "Appointment"}
                    inputProps={{
                      id: "due_on",
                      ...inputProps,
                    }}
                    onChange={this.handleDueOnChange}
                  />
                </Col>
              ) : (
                <ContingencyRuleSelection
                  handleContingencyRuleChange={this.handleContingencyRuleChange}
                  task={task}
                  createTask={createTask}
                  editTaskFor={editTaskFor}
                />
              )}

              {task.type === "Appointment" && (
                <>
                  <LocationInput
                    isSchedulingAppointment={isSchedulingAppointment}
                    handleLocationOnChange={this.handleLocationOnChange}
                    personTaskForm={personTaskForm}
                    editTaskFor={editTaskFor}
                    task={task}
                    isEditing={isEditing}
                    showDue={this.state.showDue}
                    showContingency={this.state.showContingency}
                  />
                  {!task.completed && (
                    <CalendarOptions addTo={this.state.addTo} handleAddTo={this.handleAddToState} />
                  )}
                  <OutcomeSelection
                    isSchedulingAppointment={isSchedulingAppointment}
                    task={task}
                    appointmentType={this.state.task.appointment_type}
                    handleOutcomeOnChange={this.handleOutcomeOnChange}
                    showContingency={this.state.showContingency}
                  />
                </>
              )}
              {task.type !== "Appointment" && (
                <>
                  {this.state.showContingency && (
                    <EventDropdown
                      handleContingencyRuleChange={this.handleContingencyRuleChange}
                      task={task}
                      taskableOptions={this.taskableOptions}
                      createTask={createTask}
                      editTaskFor={editTaskFor}
                    />
                  )}
                  {this.state.showRecurring && (
                    <>
                      <Col size={3} className="tw-mb-[16px]">
                        <Dropdown
                          label="Recurring"
                          name="recurring_frequency"
                          onChange={(option, handler) =>
                            this.handleTaskChange({ target: { name: handler.name, value: option.value } })
                          }
                          value={RECURRING_OPTIONS.find((opt) => opt.value === task.recurring_frequency)}
                          options={RECURRING_OPTIONS}
                        />
                      </Col>
                      {task.recurring_frequency == "number" && (
                        <Col size={3} className="tw-mb-[16px]">
                          <TextInput
                            label="# of days"
                            onChange={this.handleTaskChange}
                            name="recurring_every_so_many_days"
                            value={task.recurring_every_so_many_days || ""}
                          />
                        </Col>
                      )}
                      {task.recurring_frequency == "weekly" && (
                        <Col size={3} className="tw-mb-[16px]">
                          <Dropdown
                            label="Day of Week"
                            isSearchable
                            isClearable={false}
                            name="recurring_day_of_week"
                            onChange={(option, handler) =>
                              this.handleTaskChange({ target: { name: handler.name, value: option.value } })
                            }
                            value={RECURRING_DOW_OPTIONS.find(
                              (opt) => opt.value === task.recurring_day_of_week,
                            )}
                            options={RECURRING_DOW_OPTIONS}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}
              {!this.props.isSchedulingAppointment && task.type !== "Appointment" && (
                <>
                  <Col size={12} className="tw-mb-[16px]">
                    <Dropdown
                      label="Visibility"
                      name="externally_visible"
                      onChange={(option, handler) =>
                        this.handleTaskChange({ target: { name: handler.name, value: option.value } })
                      }
                      value={this.visibilityOptions().find((opt) => opt.value === task.externally_visible)}
                      options={this.visibilityOptions()}
                    />
                  </Col>
                  <Col size={12} className="tw-flex tw-gap-[12px]">
                    <Button onClick={this.toggleContingent}>
                      {this.state.showContingency ? "Select Specific Date" : "Make Contingent"}
                      <MagnetSolidV6 className="tw-ml-[4px] tw-align-middle" />
                    </Button>
                    <Button onClick={this.toggleRecurring}>
                      {this.state.showRecurring ? "Stop Recurrence" : "Make Recurring"}
                      <ArrowsRotateSolidV6 className="tw-ml-[4px] tw-align-middle" />
                    </Button>
                  </Col>
                </>
              )}
              <Col size={12}>
                <hr className="brivity-hr" />
              </Col>
            </Row>
          </form>
          <TaskFormFooter
            isSchedulingAppointment={isSchedulingAppointment}
            editTaskFor={editTaskFor}
            personTaskForm={personTaskForm}
            closeModal={closeModal}
            deleteButton={deleteButton}
            editToDelete={editToDelete}
            task={this.state.task}
            handleCompleteTask={this.toggleCompleteTask}
            handleSubmit={this.handleSubmit}
            submitButton={submitButton}
            saveButtonText={saveButtonText}
            newTask={newTask}
            isEditing={isEditing}
            disableCompletedCheckbox={this.state.disableCompletedCheckbox}
            hideCancel={hideCancel}
          />
        </Modal.Body>
      </div>
    );
  }
}
TaskForm.defaultProps = {
  isSchedulingAppointment: false,
  personTaskForm: false,
  isCreatingAppointment: false,
  taskDetails: false,
  isAppointment: false,
};
export default TaskForm;
