import React from "react";
import PropTypes from "prop-types";
import BuyerorSellerTransactionsField from "./BuyerorSellerTransactionsField";
import ReferralTransactionsField from "./ReferralTransactionsField";

const TransactionsField = ({
  selectedAgreementName,
  transactionTitle,
  setTransactionTitle,
  selectedSource,
  setSelectedSource,
  sources,
  propertyTypes,
  closePrice,
  setClosePrice,
  commission,
  setCommission,
  propertyType,
  setPropertyType,
  referralFee,
  setReferralFee,
  referralAgent,
  setReferralAgent,
  client,
  setClient,
  clientIntent,
  setClientIntent,
  isCommissionPercentage,
  setIsCommissionPercentage,
  isReferralFeePercentage,
  setIsReferralFeePercentage,
  setPrimaryAgent,
  primaryAgent,
  primaryAgents,
}) => {
  return (
    <div>
      {selectedAgreementName == "buyer" || selectedAgreementName == "seller" ? (
        <BuyerorSellerTransactionsField
          transactionTitle={transactionTitle}
          setTransactionTitle={setTransactionTitle}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          sources={sources}
          closePrice={closePrice}
          setClosePrice={setClosePrice}
          commission={commission}
          setCommission={setCommission}
          propertyType={propertyType}
          propertyTypes={propertyTypes}
          setPropertyType={setPropertyType}
          isCommissionPercentage={isCommissionPercentage}
          setIsCommissionPercentage={setIsCommissionPercentage}
          primaryAgent={primaryAgent}
          setPrimaryAgent={setPrimaryAgent}
          primaryAgents={primaryAgents}
        />
      ) : (
        <ReferralTransactionsField
          transactionTitle={transactionTitle}
          setTransactionTitle={setTransactionTitle}
          propertyType={propertyType}
          propertyTypes={propertyTypes}
          setPropertyType={setPropertyType}
          referralFee={referralFee}
          setReferralFee={setReferralFee}
          referralAgent={referralAgent}
          setReferralAgent={setReferralAgent}
          client={client}
          setClient={setClient}
          clientIntent={clientIntent}
          setClientIntent={setClientIntent}
          isReferralFeePercentage={isReferralFeePercentage}
          setIsReferralFeePercentage={setIsReferralFeePercentage}
        />
      )}
    </div>
  );
};
const optionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const optionShapePrimaryAgent = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.string,
  primary: PropTypes.string,
});

TransactionsField.propTypes = {
  selectedAgreementName: PropTypes.string,
  transactionTitle: PropTypes.string,
  setTransactionTitle: PropTypes.func,
  selectedSource: optionShape,
  setSelectedSource: PropTypes.func,
  sources: PropTypes.arrayOf(optionShape),
  propertyTypes: PropTypes.arrayOf(optionShape),
  closePrice: PropTypes.string,
  setClosePrice: PropTypes.func,
  commission: PropTypes.string,
  setCommission: PropTypes.func,
  propertyType: optionShape,
  setPropertyType: PropTypes.func,
  referralFee: PropTypes.string,
  setReferralFee: PropTypes.func,
  referralAgent: PropTypes.string,
  setReferralAgent: PropTypes.func,
  client: PropTypes.string,
  setClient: PropTypes.func,
  clientIntent: PropTypes.string,
  setClientIntent: PropTypes.func,
  primaryAgents: PropTypes.arrayOf(optionShape),
  primaryAgent: PropTypes.arrayOf(optionShapePrimaryAgent),
  setPrimaryAgent: PropTypes.func,
  isCommissionPercentage: PropTypes.bool,
  setIsCommissionPercentage: PropTypes.func,
  isReferralFeePercentage: PropTypes.bool,
  setIsReferralFeePercentage: PropTypes.func,
};
TransactionsField.defaultProps = {
  selectedAgreementName: "buyer",
  transactionTitle: "",
  selectedSource: null,
  closePrice: "",
  commission: "",
  propertyType: null,
  referralFee: "25",
  referralAgent: "",
  client: "",
  clientIntent: "buyer",
  primaryAgents: [],
  primaryAgent: null,
  sources: [],
  propertyTypes: [],
  isCommissionPercentage: true,
  isReferralFeePercentage: true,
  setPrimaryAgent: () => {},
  setIsReferralFeePercentage: () => {},
  setIsCommissionPercentage: () => {},
  setTransactionTitle: () => {},
  setSelectedSource: () => {},
  setClosePrice: () => {},
  setCommission: () => {},
  setPropertyType: () => {},
  setReferralFee: () => {},
  setReferralAgent: () => {},
  setClient: () => {},
  setClientIntent: () => {},
};
export default TransactionsField;
