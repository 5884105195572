/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MobileSignalOutSolidV6 = (props) => (
  <Icon {...props} name="Mobile Signal Out Solid V6">
    <path d="M9.706 7.706a.999.999 0 0 0 0-1.414c-.194-.195-.45-.32-.706-.32s-.512.098-.707.293A.999.999 0 0 0 9 7.972c.256 0 .512-.069.706-.265zM8.972 14h-7V2H7V0H1.472a1.5 1.5 0 0 0-1.5 1.5v13a1.5 1.5 0 0 0 1.5 1.5h7.972a1.5 1.5 0 0 0 1.5-1.5V9h-2l.028 5zM4.5 12c-.275 0-.5.225-.5.5s.225.5.5.5h2c.275 0 .5-.225.5-.5s-.225-.5-.5-.5h-2zm9.378-9.877A7.26 7.26 0 0 0 8.75 0c-.414 0-.751.336-.751.722a.72.72 0 0 0 .722.722c1.536 0 2.98.598 4.066 1.684s1.684 2.53 1.684 4.066a.72.72 0 0 0 .722.722c.473.057.808-.25.808-.693a7.185 7.185 0 0 0-2.122-5.098zM8.722 3c-.414 0-.75.336-.75.722s.336.722.722.722a2.753 2.753 0 0 1 2.75 2.75c0 .414.336.722.722.722S13 7.666 13 7.25c0-1.172-.477-2.234-1.246-3.004S9.922 3 8.722 3z" />
  </Icon>
);

export default MobileSignalOutSolidV6;
