import React from "react";
import { func } from "prop-types";
import IconButton from "../../../../shared/v2/IconButton";
import Edit from "../../../../shared/v2/Icomoon/Icons/Edit";

const EditBtnCirc = ({ onClick }) => (
  <div className="tw-ml-auto">
    {/* Hide away for now, as the bulk editor is not a part of the TDP's MVP. */}
    {onClick && (
      <IconButton size="small" schema="tertiary" onClick={onClick}>
        <Edit size="m" />
      </IconButton>
    )}
  </div>
);

EditBtnCirc.propTypes = {
  onClick: func,
};

EditBtnCirc.defaultProps = {
  onClick: null,
};

export default EditBtnCirc;
