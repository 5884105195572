import axios from "axios";

const basePath = "/api/v4/transactions";
const apiDatesUrl = (uuid) => `${basePath}/${uuid}/dates`;

export const getAllDatesFromApi = (uuid) => axios.get(`${apiDatesUrl(uuid)}`);

export const updateDatesFromApi = (uuid, optionId, data) =>
  axios.put(`${apiDatesUrl(uuid)}/${optionId}`, {
    ...data,
    authenticity_token: ReactOnRails.authenticityToken(),
  });
