import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import Button from "../../../../shared/v2/Button";
import IconButton from "../../../../shared/v2/IconButton";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";
import * as actionCreators from "../actions/thunks";

const ImportSearch = ({ uuid, editListing, isMarketingLoading, importedListing, deleteImport }) => {
  const enableMarketingsec = () => {
    const listingBody = { blueroof_mls_id: importedListing.blueroofMlsId };
    editListing(uuid, listingBody);
  };
  return (
    <>
      <div className="tw-rounded-lg tw-border-solid tw-border tw-border-gray-10 tw-z-0">
        <div className="tw-p-[12px]">
          <div className="tw-pb-[20px]">
            <span className="tw-uppercase tw-float-left tw-leading-[24px] tw-text-[14px] tw-text-gray-75 tw-pb-[8px] tw-font-bold">
              {`listed by ${importedListing?.listAgentName || "-"}`}
            </span>
            <span className="tw-float-right">
              <IconButton
                className="tw-float-right"
                disabled={isMarketingLoading}
                onClick={() => deleteImport(false)}
                size="medium"
                schema="misc-trash"
              >
                <Trash size="m" />
              </IconButton>
            </span>
          </div>
          <br />
          <div className="tw-leading-[16px] tw-text-[14px] tw-text-gray-75 tw-pb-[8px] tw-font-bold">
            {importedListing?.streetAddress || "-"}
          </div>
          <div className="tw-leading-[16px] tw-text-[14px] tw-text-gray-75 tw-pb-[8px] tw-font-normal">
            {`${importedListing?.city || ""} ${importedListing?.state || ""} ${
              importedListing?.postalCode || ""
            } ${importedListing?.county || ""}`}
          </div>
          <div className="tw-leading-[16px] tw-text-[14px] tw-text-gray-75 tw-pb-[8px] tw-font-normal">
            {`$${importedListing?.price || "-"}`}
          </div>
          <div className="tw-leading-[16px] tw-text-[14px] tw-text-gray-75 tw-pb-[8px] tw-font-normal">
            {`${importedListing?.bedrooms || "-"} beds | ${
              importedListing?.bathsTotalDecimal || "-"
            } baths | ${importedListing?.squareFeet || "-"} sqft`}
          </div>
        </div>
      </div>
      <div className="tw-pt-[12px]">
        <Button
          disabled={isMarketingLoading}
          onClick={enableMarketingsec}
          className="tw-flex tw-items-center"
          schema="primary"
          size="medium"
        >
          IMPORT
        </Button>
      </div>
    </>
  );
};

ImportSearch.propTypes = {
  isMarketingLoading: Proptypes.bool,
  uuid: Proptypes.string.isRequired,
  importedListing: Proptypes.shape({
    blueroofMlsId: Proptypes.number,
    listAgentName: Proptypes.string,
    streetAddress: Proptypes.string,
    city: Proptypes.string,
    postalCode: Proptypes.string,
    county: Proptypes.string,
    bedrooms: Proptypes.number,
    bathsTotalDecimal: Proptypes.number,
    squareFeet: Proptypes.number,
    title: Proptypes.string,
    name: Proptypes.string,
    state: Proptypes.string,
    price: Proptypes.string,
  }),
  editListing: Proptypes.func,
  deleteImport: Proptypes.func,
};
ImportSearch.defaultProps = {
  isMarketingLoading: false,
  importedListing: {},
  deleteImport: () => {},
  editListing: () => {},
};
const mapStateToProps = (state) => ({
  isMarketingLoading: state.tdpMarketingReducer.isMarketingLoading,
  uuid: state.tdpMarketingReducer.uuid,
  importedListing: state.tdpMarketingReducer.importedListing,
});

const mapDispatchToProps = (dispatch) => ({
  editListing: (uuid, listingBody) => dispatch(actionCreators.editListing(uuid, listingBody)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImportSearch);
