import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { useSelector } from "react-redux";

export const initialState = {
  agentsWithPhoneUsage: [],
  trackingNumbers: [],
};

const updateUserRecordingSettings = createAsyncThunk(
  "phoneSettings/updateUserRecordingSettings",
  ({ userUuid, settings: callRecordingUserSettings }, thunkApi) =>
    caseTransformingAxios
      .post(
        `/api/v4/call_recording/user_settings/${userUuid}/upsert`,
        {
          callRecordingUserSettings,
        },
        { signal: thunkApi.signal },
      )
      .then(({ data }) => data),
);

export const phoneSettingsSlice = createSlice({
  name: "phoneSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserRecordingSettings.pending, (state, action) => {
      const agent = state.agentsWithPhoneUsage.find((agent) => agent.uuid === action.meta.arg.userUuid);
      if (agent) agent.loading = true;
    });
    builder.addCase(updateUserRecordingSettings.fulfilled, (state, action) => {
      const agent = state.agentsWithPhoneUsage.find((agent) => agent.uuid === action.meta.arg.userUuid);
      if (agent) {
        agent.loading = false;
        agent.callRecordingSettings = action.payload;
      }
    });
    builder.addCase(updateUserRecordingSettings.rejected, (state, action) => {
      const agent = state.agentsWithPhoneUsage.find((agent) => agent.uuid === action.meta.arg.userUuid);
      if (agent) agent.loading = false;
    });
  },
});

export { updateUserRecordingSettings };

export default phoneSettingsSlice.reducer;

export const useAgentsWithPhoneUsage = () => useSelector((state) => state.phoneSettings.agentsWithPhoneUsage);
