import React from "react";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import AdPlan from "./AdPlan";

const PlanModal = ({ planId, modalOpen, closeModal }) => (
  <>
    <Modal
      show={modalOpen}
      onHide={closeModal}
      data-cy="marketing-advertising-plan-modal"
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[600px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="Advertising Plan" closeButton />
      <Modal.Body>
        <AdPlan planId={planId} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </>
);

PlanModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  planId: Proptypes.number,
};

PlanModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  planId: null,
};

export default PlanModal;
