import { useEffect, useRef, useState } from "react";

const getFullscreenElement = () =>
  document.mozFullscreenElement || document.webkitFullscreenElement || document.fullscreenElement;
const exitFullscreen = () => {
  if (document.mozExitFullscreen) document.mozExitFullscreen();
  else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
  else if (document.exitFullscreen) document.exitFullscreen();
};
const requestFullscreen = (element) => {
  if (element.mozRequestFullscreen) element.mozRequestFullscreen();
  else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
  else if (element.requestFullscreen) element.requestFullscreen();
};

const useFullscreen = () => {
  const ref = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(!!getFullscreenElement());
    };
    document.addEventListener("mozfullscreenchange", onFullscreenChange);
    document.addEventListener("webkitfullscreenchange", onFullscreenChange);
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => {
      document.removeEventListener("mozfullscreenchange", onFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", onFullscreenChange);
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (getFullscreenElement()) {
      exitFullscreen();
      return;
    }

    const element = ref.current;
    if (element) requestFullscreen(element);
  };

  return {
    ref,
    isFullscreen,
    toggleFullscreen,
  };
};

export default useFullscreen;
