import { range } from "../../utils/helpers";

const limitYear = 2016;
const namedOptions = [
  ["all_time", "All Time"],
  ["today", "Today"],
  ["7_days", "Last 7 Days"],
  ["14_days", "Last 14 Days"],
  ["next_month", "Next Month"],
  ["this_month", "This Month"],
  ["last_month", "Last Month"],
  ["this_year", "This Year"],
  ["last_year", "Last Year"],
];

const closeDateOptionsBuilder = ([value, name]) => ({
  value,
  id: value,
  name,
  title: name,
});

const baseOptions = namedOptions.map(closeDateOptionsBuilder);

const closeDateOptionsGenerator = () => {
  const thisYear = new Date().getFullYear();
  const literalYears = range(limitYear, thisYear - 2)
    .map((year) => [year.toString(), year.toString()])
    .reverse()
    .map(closeDateOptionsBuilder);

  return [...baseOptions, ...literalYears];
};

export const closeDateOptions = closeDateOptionsGenerator();
export const statusDateOptions = [
  ...baseOptions.filter((option) => option.id !== "next_month"),
  closeDateOptionsBuilder(["custom", "Custom"]),
];
