import React from "react";
import { arrayOf, shape } from "prop-types";

import ResourceLauncher from "./ResourceLauncher";

const ResourceLaunchers = ({ resources }) =>
  resources.map(({ Icon, label, linkUrl }) => (
    <ResourceLauncher Icon={Icon} label={label} linkUrl={linkUrl} key={label} />
  ));

export default ResourceLaunchers;

ResourceLaunchers.propTypes = {
  resources: arrayOf(shape({})).isRequired,
};
