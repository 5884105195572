import React from "react";
import { func, string } from "prop-types";

import RadioButton from "@shared/v2/RadioButton";
import FieldLabel from "@shared/v2/FieldLabel";
import { bodyS } from "@shared/styles";

const PriorityRadio = ({ value, setValue }) => (
  <div>
    <FieldLabel
      className="tw-mb-8px"
      label='Priority'
      isRequired
    />
    <div className="tw-flex tw-justify-between">
      {Array.from({ length: 9 }, (_, index) => index + 1).map((number) => (
        <RadioButton
          value={number}
          label={`${number}`}
          className='tw-cursor-pointer'
          checked={value === number}
          onClick={() => setValue(number)}/>
      ))}
    </div>
    <div className="tw-flex tw-justify-between tw-mt-4px">
        <span className={bodyS}>Highest</span>
        <span className={bodyS}>Lowest</span>
      </div>
  </div>
);

export default PriorityRadio;

PriorityRadio.propTypes = {
  value: string.isRequired, 
  setValue: func.isRequired
}