import { adaptError } from "../api/apiAdapters";
import { getAllDatesFromApi, updateDatesFromApi } from "../api/apiWrappers";
import {
  saveAllDates,
  updateDates,
  fetchAllDatesFailure,
  updateDateFailure,
  showCustomDates,
} from "./creators";
import { tryCatchHandlr } from "../../../../shared/Utilities";
import { getAllDetailsAsThunk } from "../../../Main/Details/actions/thunks";

export const getAllDates = (uuid) => async (dispatch) => {
  const req = getAllDatesFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(fetchAllDatesFailure(adaptError(err)));
    return;
  }

  const dates = res.data;
  dispatch(saveAllDates(dates));
};

export const updateDate = (uuid, optionId, data) => async (dispatch) => {
  dispatch(showCustomDates(false));
  const req = updateDatesFromApi(uuid, optionId, data);
  /* eslint-disable no-unused-vars */
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(updateDateFailure(adaptError(err)));
    return;
  }

  const { date, id } = res.data;

  dispatch(updateDates(true, { date, id }));
  getAllDates(uuid);
  dispatch(getAllDetailsAsThunk(uuid));
};

export const showCustomDateLists = (isOpen) => async (dispatch) => {
  dispatch(showCustomDates(isOpen));
};
