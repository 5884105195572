import React, { useState } from "react";
import Button from "@shared/v2/Button/Button";
import Alert from "@shared/v2/Alert";
import Check from "@shared/v2/Icomoon/Icons/Check";
import ImportListingDetailsModal from "../ImportListingDetailsModal";

const ImportListingDetailsBtn = () => {
  const [showModal, setshowModal] = useState(false);
  const [showAlert, setshowAlert] = useState(false);

  const closeModal = () => {
    setshowModal(false);
  };

  const openModal = () => {
    setshowModal(true);
  };

  return (
    <>
      {showAlert && (
        <Alert
          variant="success"
          containerClass="tw-z-10 !tw-top-0 !tw-absolute !tw-right-2/5 !tw-left-2/5"
          text={
            <>
              <Check size="xl" className="tw-w-16px tw-h-16px tw-mb-[-4px]" />
              <span className="tw-pl-[9px]">Listing details imported from MLS</span>
            </>
          }
          role="alert"
        />
      )}

      <div className="tw-ml-auto">
        <Button schema="secondary" size="small" onClick={openModal}>
          Import Listing Details
        </Button>
      </div>
      {showModal && (
        <ImportListingDetailsModal
          modalOpen={showModal}
          closeModal={closeModal}
          setshowAlert={setshowAlert}
          isFromDetail={true}
        />
      )}
    </>
  );
};

ImportListingDetailsBtn.propTypes = {};

ImportListingDetailsBtn.defaultProps = {};

export default ImportListingDetailsBtn;
