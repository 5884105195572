import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bool, func, string } from "prop-types";

import { getTeamAsThunk, getTitleAsThunk } from "./actions/thunks";

import withTaskSelectionMode from "./HOCs/withTaskSelectionMode";

import Header from "../../UI/Header";
import HeaderOptions from "./HeaderOptions";
import Filters from "./Filters";
import TaskSelections from "./TaskSelections";
import Main from "./Main";
import TasksHeader from "./TasksHeader";

const TasksWrapper = ({ onGetTeamAsThunk, onGetTitleAsThunk, uuid, isTasksSelectionMode }) => {
  useEffect(() => {
    onGetTeamAsThunk();
    onGetTitleAsThunk(uuid);
  }, []);

  return (
    <>
      <Header header={<TasksHeader />} add={<HeaderOptions />} />
      {isTasksSelectionMode ? <TaskSelections /> : <Filters />}
      <Main />
    </>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpTasksReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTeamAsThunk: () => dispatch(getTeamAsThunk()),
  onGetTitleAsThunk: (uuid) => dispatch(getTitleAsThunk(uuid)),
});

TasksWrapper.propTypes = {
  onGetTeamAsThunk: func,
  onGetTitleAsThunk: func,
  uuid: string,
  isTasksSelectionMode: bool,
};

TasksWrapper.defaultProps = {
  onGetTeamAsThunk: null,
  onGetTitleAsThunk: null,
  uuid: "",
  isTasksSelectionMode: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTaskSelectionMode(TasksWrapper));
