/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CalendarDaySolidV6 = (props) => (
  <Icon {...props} name="Calendar Day Solid V6">
    <path d="M6 1v1h4V1a1 1 0 1 1 2 0v1h1.5A1.5 1.5 0 0 1 15 3.5V5H1V3.5A1.5 1.5 0 0 1 2.5 2H4V1a1 1 0 1 1 2 0zM1 6h14v8.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V6zm2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3c.275 0 .5-.225.5-.5v-3c0-.275-.225-.5-.5-.5h-3z" />
  </Icon>
);

export default CalendarDaySolidV6;
