import React, { useMemo } from "react";
import { Provider } from "react-redux";
import PersonDetail from "../components/PersonDetail";

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
// railsContext provides contextual information especially useful for server rendering, such as
// knowing the locale. See the React on Rails documentation for more info on the railsContext
export default ({
  allAgents,
  newMilestoneId,
  gmailUnsynced,
  email_templates,
  appointment_outcomes,
  accountMilestoneNames,
  idxDataUri,
  idxDataToken,
}) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <PersonDetail
        allAgents={allAgents}
        newMilestoneId={newMilestoneId}
        gmailUnsynced={gmailUnsynced}
        emailTemplates={email_templates}
        appointmentOutcomes={appointment_outcomes}
        accountMilestoneNames={accountMilestoneNames}
        idxDataUri={idxDataUri}
        idxDataToken={idxDataToken}
      />
    </Provider>
  );
};
