/* 
    This file is used to help standardizing action creators.

    Each action should be namespaced to the reducer they are intended to be used at. 
    This will be accomplished using the namespace function along with setting the 
    reducer name atop the actions file.
*/
import { snakeCase } from "lodash";
import moment from "moment";

import { pipe } from "../Transactions/utils/helpers";

const upCase = (string) => string.toUpperCase();
const adjustReducerName = pipe(snakeCase, upCase);

const prefix = (reducerName, actionType) => `[${reducerName}]_${actionType}`;

/**
 * Transforms the action's name to apply upper-case, underscore-separated, square-bracket-enclosed reducerName as prefix.
 * @example
 * // return "[TDP_REDUCER]_ADD_IMG"
 * namespaceType("ADD_IMG", "tdpReducer")
 * @param {string} actionType
 * @param {string} reducerName
 * @returns {string}
 */
const namespaceType = (reducerName, actionType) => prefix(adjustReducerName(reducerName), actionType);

/**
 * Returns an action with namespaced type property. Used to apply reducer namespacing.
 * @example
 * // return { type: "[TDP_REDUCER]_ADD_IMG" }
 * namespaceAction("tdpReducer", { type: "ADD_IMG" });
 * @param {string} reducerName
 * @param {object} action - Reducer action's, with `type` as the identifier
 * @returns {object} - Action with namespaced `type` property
 */
const namespaceAction = (reducerName, action) => ({
  ...action,
  type: namespaceType(reducerName, action.type),
});

/**
 * Curries namespace action in order to provide namespace once and apply it to multiple actions
 * @param {string} reducerName
 * @returns {function} a function that applies namespaceAction with the provided action and curried reducerName
 */
const curriedNamespaceAction = (reducerName) => (action) => namespaceAction(reducerName, action);

export default curriedNamespaceAction;

// Returns a string calculated by timeframe created
// const exactDateHelper = (days) => moment().add(days, "days").format("MM/DD/YYYY");

const IMMEDIATE_UNKNOWN = ["Immediate", "Unknown"];

const formatExactDateToRead = (dateString) => {
  // Edge case... if dropdown option chosen is Immediate || Unknown don't format
  if (IMMEDIATE_UNKNOWN.includes(dateString)) return dateString;

  return moment(dateString, "MM/DD/YYYY").format("MMM DD, YYYY");
};

const TIMEFRAME_PREFIX_DICTIONARY = {
  Immediate: null,
  Unknown: null,
  "Within 90 days": "By",
  "Within a year": "By",
  "After a year": "After",
  Custom: "",
};

// Gets a easy to read date for FE ex: By Mar 15, 2023 || After Mar 20, 2023 || Unknown
export const timeframeReadHelper = (timeframe, exactDate) => {
  if (IMMEDIATE_UNKNOWN.includes(timeframe)) return timeframe;

  const pref = TIMEFRAME_PREFIX_DICTIONARY[timeframe];

  return `${pref} ${formatExactDateToRead(exactDate)}`;
};
