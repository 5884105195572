import { eventDetailString as eventDetailStringForSnakeCasedAction } from "../../../../../../AssignedPlans/components/AssignedPlanDetailHelpers";
import { snakefyKeys } from "../../../../../../shared/Utilities";

export const shouldShowPlayOrPauseAndDeleteButtons = (planState) =>
  !["completed", "deleted"].includes(planState);

export const shouldShowContingencyInfoFor = (action) =>
  action.contingent && action.parentAction && action.parentAction.name;

export const shouldShowEventFieldFor = (action) => !!action.milestoneName;

export const eventDetailStringFor = (action) => eventDetailStringForSnakeCasedAction(snakefyKeys(action));

export const canDeleteAction = (actionState, planState) =>
  ["running", "paused"].includes(planState) && actionState !== "completed";

export const canMarkActionAsComplete = (actionType, planState) =>
  planState === "running" && actionType !== "Task";

export const canRestartAction = (actionType, actionState, planState) => {
  // An action can only be restarted if the plan it belongs to is started/running.
  if (planState !== "running") {
    return false;
  }

  // A task action can only be restarted if it has failed.
  if (actionType === "Task") {
    return actionState === "failed";
  }

  // Other action types can be restarted whenever, unless they're already completed.
  return actionState !== "completed";
};
