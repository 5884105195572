import { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { models } from "powerbi-client";

const iframe = document.createElement("iframe");

const usePowerBi = ({ ref, shouldRun, url }) => {
  const [embedConfig, setEmbedConfig] = useState();

  useEffect(() => {
    if (!shouldRun) return;
    const abortController = new AbortController();

    caseTransformingAxios
      .get(url, { signal: abortController.signal })
      .then(({ data }) =>
        setEmbedConfig({
          type: "report",
          tokenType: models.TokenType.Embed,
          embedUrl: data.reportDetails.embedUrl,
          accessToken: data.token,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        }),
      )
      .catch((error) => {
        console.log(error);
      });
    return () => abortController.abort();
  }, [shouldRun]);

  useEffect(() => {
    const embedContainer = ref.current;
    if (!ref.current || !embedConfig) return;
    window.powerbi.embed(embedContainer, embedConfig, iframe);
    return () => window.powerbi.reset(embedContainer);
  }, [embedConfig]);
};

export default usePowerBi;