import React from "react";
import AddTag from "./AddTag";
import EditableElement from "./EditableElement";
import EditableDate from "./EditableDate";
import { useDispatch, useSelector } from "react-redux";
import { addTagToPerson, deleteTagFromPerson, updatePersonData } from "../actions/personDetailActionCreators";
import Loading from "../../Goals/components/Loader";
import CollapsibleElement from "./CollapsibleElement";
import Tag from "@shared/v2/Tag";

const Details = (props) => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);

  const detailsObjects = (type) => {
    return person.included?.filter((a) => a.type === type).map((a) => a.attributes) || [];
  };

  return (
    <CollapsibleElement title="Details" id="pdp-details">
      {!person.data && <Loading />}
      <div id="person-details-card" className="tw-mx-[-20px]">
        <EditableElement
          fieldName="Description"
          elementType={"description"}
          person={person}
          updatePersonData={(data) => dispatch(updatePersonData(data))}
        />
        <div className="tw-px-[20px] tw-py-[8px] hover:tw-bg-gray-5 tw-flex tw-flex-col tw-gap-[8px]">
          <div className="tw-text-11d tw-text-gray-75 tw-font-bold tw-uppercase">Tags</div>
          <div className="tw-flex tw-flex-wrap tw-gap-[4px]">
            {detailsObjects("tag")
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((tag) => (
                <Tag key={tag.id} removable onRemove={() => dispatch(deleteTagFromPerson(tag.id, person))}>
                  {tag.name}
                </Tag>
              ))}
          </div>
          <AddTag person={person} addTagToPerson={(...args) => dispatch(addTagToPerson(...args))} />
        </div>
        {person.data?.attributes.birthdate?.date && (
          <EditableDate
            person={person}
            date={person.data.attributes.birthdate}
            elementType="birthdate"
            updatePersonData={(data) => dispatch(updatePersonData(data))}
            noYear
          />
        )}
        {person.data?.attributes.marital_status?.value && (
          <EditableElement
            person={person}
            fieldName="Marital Status"
            elementType={"marital_status"}
            dropdownOptions={"marital_status_options"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.anniversary?.date && (
          <EditableDate
            person={person}
            date={person.data.attributes.anniversary}
            elementType={"anniversary"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.kids?.value && (
          <EditableElement
            person={person}
            fieldName="Kids"
            elementType={"kids"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.pets?.value && (
          <EditableElement
            person={person}
            fieldName="Pets"
            elementType={"pets"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.timezone?.value && (
          <EditableElement
            person={person}
            fieldName="Timezone"
            elementType={"timezone"}
            dropdownOptions={"timezone_options"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.company?.value && (
          <EditableElement
            person={person}
            fieldName="Company"
            elementType={"company"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.job_title?.value && (
          <EditableElement
            person={person}
            fieldName="Job Title"
            elementType={"job_title"}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        )}
        {person.data?.attributes.custom_dates
          .filter((customDate) => customDate.date != undefined)
          .map((date) => (
            <EditableDate
              person={person}
              date={date}
              elementType={"custom_date"}
              updatePersonData={(data) => dispatch(updatePersonData(data))}
            />
          ))}
        <EditableElement
          elementType={"letter_salutation"}
          person={person}
          fieldName="Letter Salutation"
          updatePersonData={(data) => dispatch(updatePersonData(data))}
        />
        <EditableElement
          elementType={"envelope_salutation"}
          person={person}
          fieldName="Envelope Salutation"
          updatePersonData={(data) => dispatch(updatePersonData(data))}
        />
        {person.data?.attributes.custom_fields &&
          _.flatMap(person.data.attributes.custom_fields, (field, key) => {
            return (
              <EditableElement
                person={person}
                fieldName={key}
                elementType={"custom_field"}
                updatePersonData={(data) => dispatch(updatePersonData(data))}
              />
            );
          })}
      </div>
    </CollapsibleElement>
  );
};

export default Details;
