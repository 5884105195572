import React from "react";
import { connect } from "react-redux";
import { shape, number, arrayOf, func } from "prop-types";

import { setActiveFilter } from "../actions/creators";
import useFilterClickHandlr from "./hooks";

import {
  ChevronDown,
  Tasks,
  Envelope,
  Phone,
  Message,
  Calendar,
  Create,
  Templates,
  Home,
} from "../../../../shared/v2/Icomoon";
import FilterButton from "../FilterButton";

const Filters = ({ tags, onSetActiveFilter }) => {
  const { activeButton, buttonClickHandlr } = useFilterClickHandlr(onSetActiveFilter);

  return (
    <div className="tw-flex tw-gap-[16px]">
      <FilterButton
        dataCy="tdp-timeline_filter-0"
        activeButton={activeButton}
        hoverText="All"
        onClick={() => buttonClickHandlr("All")}
      >
        <span className="tw-leading-[16px]">
          All <ChevronDown size="s" />
        </span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-1"
        activeButton={activeButton}
        hoverText="Tasks"
        onClick={() => buttonClickHandlr("Tasks")}
      >
        <Tasks size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Tasks?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-2"
        activeButton={activeButton}
        hoverText="Emails"
        onClick={() => buttonClickHandlr("Emails")}
      >
        <Envelope size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Emails?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-3"
        activeButton={activeButton}
        hoverText="Phone Calls"
        onClick={() => buttonClickHandlr("Phone Calls")}
      >
        <Phone size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags["Phone Calls"]?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-4"
        activeButton={activeButton}
        hoverText="Texts"
        onClick={() => buttonClickHandlr("Texts")}
      >
        <Message size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Texts?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-5"
        activeButton={activeButton}
        hoverText="Appointments"
        onClick={() => buttonClickHandlr("Appointments")}
      >
        <Calendar size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Appointments?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-6"
        activeButton={activeButton}
        hoverText="Notes"
        onClick={() => buttonClickHandlr("Notes")}
      >
        <Create size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Notes?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-7"
        activeButton={activeButton}
        hoverText="Documents"
        onClick={() => buttonClickHandlr("Documents")}
      >
        <Templates size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags.Documents?.count}</span>
      </FilterButton>
      <FilterButton
        dataCy="tdp-timeline_filter-8"
        activeButton={activeButton}
        hoverText="Transaction Activity"
        onClick={() => buttonClickHandlr("Transaction Activity")}
      >
        <Home size="m" className="tw-mr-[4px]" />
        <span className="tw-leading-[16px]">{tags["Transaction Activity"]?.count}</span>
      </FilterButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tdpTimelineReducer.tags.byName,
});

const mapDispatchToProps = (dispatch) => ({
  onSetActiveFilter: (tagName) => dispatch(setActiveFilter(tagName)),
});

Filters.propTypes = {
  tags: shape({
    "Transaction Activity": shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Documents: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Notes: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Tasks: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Emails: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Texts: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    "Phone Calls": shape({
      count: number,
      eventsById: arrayOf(number),
    }),
    Appointments: shape({
      count: number,
      eventsById: arrayOf(number),
    }),
  }),
  onSetActiveFilter: func,
};

Filters.defaultProps = {
  tags: {
    "Transaction Activity": {
      count: 0,
      eventsById: [],
    },
    Documents: {
      count: 0,
      eventsById: [],
    },
    Notes: {
      count: 0,
      eventsById: [],
    },
    Tasks: {
      count: 0,
      eventsById: [],
    },
    Emails: {
      count: 0,
      eventsById: [],
    },
    Texts: {
      count: 0,
      eventsById: [],
    },
    "Phone Calls": {
      count: 0,
      eventsById: [],
    },
    Appointments: {
      count: 0,
      eventsById: [],
    },
  },
  onSetActiveFilter: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
