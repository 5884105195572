import React, { Component } from "react";
import BrokerageExpenses from "./BrokerageExpenses";
import ExpenseFinder from "./ExpenseFinder";
import axios from "axios";

export default class SupportTransactionFinancials extends Component {
  state = {
    transactionResults: undefined
  }

  setTransactionResults = (data) => this.setState({ transactionResults: data })

  resetTeamMemberIncome = (forBrokerageCap, incomeId, listingId) => {
    axios.patch(`/support/accounts/${this.props.accountId}/reset_team_member_income/${incomeId}`, { listing_id: listingId, for_brokerage_cap: forBrokerageCap })
      .then((res) => this.setState({ transactionResults: res.data }))
  }

  render() {
    const { transactionResults } = this.state
    const { accountId } = this.props

    return (
      <div className="panel-body">
        <ExpenseFinder accountId={accountId} setTransactionResults={this.setTransactionResults} />
        {transactionResults && <BrokerageExpenses transactionResults={transactionResults} resetTeamMemberIncome={this.resetTeamMemberIncome} />}
      </div>
    )
  }
}
