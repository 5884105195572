import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import HeaderTitle from './HeaderTitle';
import Activity from "../WebActivity/Activity";
import modal from '../modal.module.css';
import '../WebActivity/activity.scss';

class ViewWebActivityModal extends Component {
  render() {
    const { uuid, idxDataUri, idxDataToken, visits, lastVisit, views, isFavorite } = this.props
    return (
      <Modal id="view-web-activity-modal" className="person-details-modal" show={true}>
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Web Activity" onClose={this.props.closeWebActivityModal}>
              <div id="activity-metrics" className="pull-right">
                <div>
                  <span className="activity-label">VIEWS:</span>
                  <span className="activity-value">{views}</span>
                </div>
                <div>
                  <span className="activity-label">VISITS:</span>
                  <span className="activity-value">{visits}</span>
                </div>
                <div>
                  <span className="activity-label">LAST VISIT:</span>
                  <span className="activity-value">{lastVisit}</span>
                </div>
              </div>
            </HeaderTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={modal.modalBodyContainer}>
            <Activity uuid={uuid} idxDataUri={idxDataUri} idxDataToken={idxDataToken} isFavorite={isFavorite} closeModal={this.props.closeWebActivityModal} views={views} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ViewWebActivityModal;
