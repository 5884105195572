import React from "react";
import PropTypes from "prop-types";
import { ToggleOffSolidV6, ToggleOnSolidV6, VolumeSlashSolidV6, VolumeSolidV6 } from "@shared/v2/Icomoon";

const CallRecordingMessaging = ({ additionalText, className, willRecord, willNotify }) => {
  const RecordingIcon = willRecord ? ToggleOnSolidV6 : ToggleOffSolidV6;
  const NotificationIcon = willNotify ? VolumeSolidV6 : VolumeSlashSolidV6;
  return (
    <div className={`tw-flex tw-flex-col tw-gap-[8px] tw-text-left ${className}`}>
      <div className="tw-flex tw-items-center tw-gap-[8px]">
        <RecordingIcon />{" "}
        {willRecord ? (
          <span>Recording will start automatically at the beginning of the call</span>
        ) : (
          <span>Recording will not start until you press record for each call</span>
        )}
      </div>
      <div className="tw-flex tw-items-center tw-gap-[8px]">
        <NotificationIcon />{" "}
        {willNotify ? (
          <span>A notification message will play when the recording starts</span>
        ) : (
          <span>No notification message will play when the recording starts</span>
        )}
      </div>
      {additionalText && <span>{additionalText}</span>}
    </div>
  );
};

CallRecordingMessaging.propTypes = {
  additionalText: PropTypes.string,
  className: PropTypes.string,
  willRecord: PropTypes.bool,
  willNotify: PropTypes.bool,
};

CallRecordingMessaging.defaultProps = {
  additionalText: "",
  className: "",
  willRecord: false,
  willNotify: false,
};

export default CallRecordingMessaging;
