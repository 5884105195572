import React, { useEffect, useState } from "react";
import MentionablePersonTextArea from "../../shared/mentions/MentionablePersonTextArea";
import IconButton from "@shared/v2/IconButton";
import AiAssistant from "@shared/AiAssistant";
import Button from "@shared/v2/Button";

const PersonInteractionsNoteForm = ({
  hideCancel,
  isEditing,
  isExternal,
  notableId,
  notableType,
  note,
  onClose,
  onDelete,
  onDiscard,
  submitNote,
  type,
}) => {
  const [state, setState] = useState({
    note: note || {
      content: "",
      notable_id: notableId,
      notable_type: notableType,
      externally_visible: false,
      starred: false,
    },
  });

  useEffect(() => {
    setState((state) => ({ note: { ...state.note, notable_id: notableId } }));
  }, [notableId]);

  const updateNote = (value) => {
    setState((state) => ({ note: { ...state.note, content: value } }));
  };

  const toggleStarred = (starred) => {
    setState((state) => ({ note: { ...state.note, starred } }));
  };

  const toggleVisibility = (externally_visible) => {
    setState((state) => ({ note: { ...state.note, externally_visible } }));
  };

  const saveNote = () => {
    if (
      isEditing &&
      state.note.content === note.content &&
      state.note.starred === note.starred &&
      state.note.externally_visible === note.externally_visible
    ) {
      onClose();
    } else {
      submitNote(state.note);
      onClose?.();
    }
    setState((state) => ({ note: { ...state.note, content: "" } }));
  };

  return (
    <>
      {notableType !== "Person" && !isExternal && (
        <div className="tw-flex tw-justify-between tw-mb-16px tw-flex-1">
          <div className="tw-flex tw-items-center">
            <div style={{ marginRight: "12px" }}>
              <IconButton
                type="button"
                size="small"
                schema={
                  state.note.externally_visible && ["Listing", "Project", "Person"].includes(notableType)
                    ? "primary"
                    : "tertiary"
                }
                onClick={() => toggleVisibility(!state.note.externally_visible)}
              >
                <i
                  className={`fa fa-eye${state.note.externally_visible ? "" : "-slash"} ${
                    notableType === "Person" && "tw-cursor-not-allowed"
                  } tw-text-[14px]`}
                />
              </IconButton>
            </div>
            <div className="tw-text-[12px] tw-inline-block tw-font-semibold">
              {state.note.externally_visible
                ? "Notes are visible to viewers"
                : "Notes are hidden from viewers"}
            </div>
          </div>
          <div className="tw-flex tw-items-center">
            <div className="tw-text-[12px] tw-ml-[5px] tw-font-semibold">
              {state.note.starred ? "Marked as important" : "Mark as important"}
            </div>
            <div style={{ marginLeft: "12px" }}>
              <IconButton
                className="tw-mr-[5px]"
                type="button"
                size="small"
                schema={state.note.starred ? "starred-yellow" : "tertiary"}
                onClick={() => toggleStarred(!state.note.starred)}
              >
                <i className={`fa fa-star${state.note.starred ? "" : "-o"} tw-text-[14px]`} />
              </IconButton>
            </div>
          </div>
        </div>
      )}

      {notableType === "Person" && (
        <div className="tw-flex tw-justify-between tw-mb-16px">
          <div>
            <div className="fa fa-eye-slash tw-cursor-not-allowed tw-text-[14px]" />
            <div className="tw-text-[12px] tw-inline-block tw-ml-[10px] tw-font-normal">
              {state.note.externally_visible
                ? "Notes are visible to viewers"
                : "Notes are hidden from viewers"}
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-gap-[16px]">
            <AiAssistant
              textareaId="mentions-input"
              messageType="Generic"
              onInsertClick={(text) => updateNote(text)}
            />
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <div className="tw-text-[12px] tw-inline-block tw-font-semibold">
                {state.note.starred ? "Marked as important" : "Mark as important"}
              </div>
              <IconButton
                type="button"
                size="small"
                schema={state.note.starred ? "starred-yellow" : "tertiary"}
                onClick={() => toggleStarred(!state.note.starred)}
              >
                <i className={`fa fa-star${state.note.starred ? "" : "-o"} tw-text-[14px]`} />
              </IconButton>
            </div>
          </div>
        </div>
      )}

      <MentionablePersonTextArea
        id="mentions-input"
        value={state.note.content}
        mentionable_id={state.note.notable_id}
        mentionable_type={state.note.notable_type}
        onChange={updateNote}
        isExternal={isExternal}
      />

      {!isExternal && (
        <div className="tw-flex tw-justify-between tw-mt-[32px]">
          {hideCancel ? (
            <span />
          ) : (
            <Button
              size="medium"
              schema="tertiary"
              onClick={() => {
                if (
                  note &&
                  (state.note.content !== note.content ||
                    state.note.starred !== note.starred ||
                    state.note.externally_visible !== note.externally_visible)
                ) {
                  onDiscard();
                } else {
                  onClose();
                }
              }}
            >
              Cancel
            </Button>
          )}
          <div className="tw-flex tw-gap-[8px]">
            {isEditing && (
              <Button size="medium" schema="warning" onClick={onDelete}>
                Delete
              </Button>
            )}
            <Button size="medium" disabled={!state.note.content.length} onClick={() => saveNote()}>
              {type === "Transaction" ? "Add Note" : "Save"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonInteractionsNoteForm;
