/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
// eslint-disable-next-line import/no-cycle
import Item from "./Item";
import scrollStyle from "./ItemsList.module.css";

const bottomScrollReached = (element) => element.offsetHeight + element.scrollTop >= element.scrollHeight;
const maybeTriggerLoadMore = (ref, callback) => {
  if (ref && ref.current && bottomScrollReached(ref.current)) {
    callback();
  }
};

const loader = () => (
  <div className="tw-h-[48px] tw-w-full tw-min-w-full tw-text-black">
    <div className="tw-flex tw-justify-center tw-py-[12px]">
      <FontAwesomeIcon
        className="tw-opacity-50 tw-text-neutral-gray-75 tw-fa-3x tw-fa-spin tw-animate-spin tw-text-[50px]"
        icon={faSpinner}
      />
    </div>
  </div>
);
const ItemList = React.memo(
  ({ column, onStatusChanged, updateListing, sources, agents, onLoadMore, onDeletion, permissions }) => {
    const listingsContainer = useRef(null);

    useEffect(() => {
      // Scroll to bottom as the loader appears and appends content to the container
      if (column.pagination.isFetching && listingsContainer && listingsContainer.current) {
        listingsContainer.current.scrollTop = listingsContainer.current.scrollHeight;
      }
    }, [column.pagination.isFetching, listingsContainer]);

    return (
      <div
        ref={listingsContainer}
        className="listings tw-scrollbar-hidden tw-flex-grow tw-overflow-y-auto tw-bg-white"
        onScroll={debounce(() => {
          if (column.pagination.isFetching) {
            // Do not consider loading more when there is already a pending request
            return;
          }
          maybeTriggerLoadMore(listingsContainer, onLoadMore);
        }, 150)}
      >
        <Droppable key={column.id} droppableId={`${column.id}`}>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              className={`task-list ${scrollStyle["task-list"]} tw-min-h-full tw-space-y-[16px] tw-mx-[12px] tw-pb-[8px]`}
              // library props that handle internals
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProvided.droppableProps}
            >
              {column.items.map((item, index) => (
                <Draggable key={item.id} draggableId={`${item.id} ${item.type}`} index={index}>
                  {/* library uses exactly this template, with shadowing */}
                  {(draggableProvided) => (
                    <Item
                      provided={draggableProvided}
                      item={item}
                      index={index}
                      onStatusChanged={onStatusChanged}
                      updateListing={updateListing}
                      sources={sources}
                      agents={agents}
                      onDeletion={onDeletion}
                      permissions={permissions}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
              {column.pagination.isFetching && loader()}
            </div>
          )}
        </Droppable>
      </div>
    );
  },
);

export default ItemList;
