import React from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, string } from "prop-types";

import { selectAllTasks } from "../actions/creators";
import withSelectedTasks from "../HOCs/withSelectedTasks/withSelectedTasks";

import Checkbox from "../../../../shared/v2/Checkbox";

const TaskSelectAll = ({ onSelectAllTasks, isSelectedAllTaskChecked, selectedTasks }) => (
  <div className="tw-text-12px tw-font-normal tw-flex">
    <Checkbox
      checked={isSelectedAllTaskChecked}
      onClick={onSelectAllTasks}
      className="tw-border-neutral-gray-50 tw-cursor-pointer"
    />
    <span className="tw-ml-16px">
      <span className="tw-font-semibold">{selectedTasks.length}</span> Selected
    </span>
  </div>
);

const mapStateToProps = (state) => ({
  isSelectedAllTaskChecked: state.tdpTasksReducer.isSelectedAllTaskChecked,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectAllTasks: () => dispatch(selectAllTasks()),
});

TaskSelectAll.propTypes = {
  onSelectAllTasks: func,
  isSelectedAllTaskChecked: bool,
  selectedTasks: arrayOf(string),
};

TaskSelectAll.defaultProps = {
  onSelectAllTasks: null,
  isSelectedAllTaskChecked: false,
  selectedTasks: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(withSelectedTasks(TaskSelectAll));
