import React, { useMemo, useState } from "react";
import { Dropdown } from "../../shared/v1";
import Button from "@shared/v2/Button";
import IconButton from "@shared/v2/IconButton";
import { XmarkSolidV6 } from "@shared/v2/Icomoon";

const AddTag = ({ addTagToPerson, person }) => {
  const [addingTag, setAddingTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [newTagText, setNewTagText] = useState(null);
  const tagOptions = useMemo(
    () =>
      (person.meta?.tag_options || [])
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [person.meta],
  );

  const onAddNewTag = (option) => {
    setSelectedTag(option.value);
    setNewTagText(option.label);
  };

  const onElementSave = () => {
    addTagToPerson(newTagText, person);
    setAddingTag(false);
    setNewTagText(null);
    setSelectedTag(null);
  };

  return (
    <div>
      {addingTag && (
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
          <Dropdown.Creatable
            className="tw-text-14d"
            onChange={onAddNewTag}
            isSearchable
            isClearable={false}
            options={tagOptions}
            placeholder="Search tags..."
            value={selectedTag}
          />
          <div className="tw-flex tw-gap-[4px]">
            <Button onClick={onElementSave}>Save</Button>
            <IconButton schema="tertiary" size="small" onClick={() => setAddingTag(false)}>
              <XmarkSolidV6 />
            </IconButton>
          </div>
        </div>
      )}
      {!addingTag && <Button onClick={() => setAddingTag(true)}>+ ADD</Button>}
    </div>
  );
};

export default AddTag;
