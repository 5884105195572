import React from "react";
import { bool, func, string } from "prop-types";
import Checkbox from "../../../../shared/v2/Checkbox/Checkbox";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TYPE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { isReferral } from "../../../shared/helpers";

const SubscribeClientCheckbox = ({ toggleEmailUpdate, onChangeSetToggle, isDisabled }) => (
  <div className="tw-ml-[24px]">
    <Checkbox
      checked={toggleEmailUpdate}
      label="Subscribe to Client Email Updates"
      onChange={() => onChangeSetToggle(!toggleEmailUpdate)}
      className="tw-cursor-pointer"
      disabled={isDisabled}
    />

    <div className="tw-text-gray-50 tw-mb-[12px] tw-ml-[20px] tw-text-sm tw-font-normal tw-tracking-normal tw-text-left tw-inline-block">
      Share your completed tasks and marketing efforts in a daily automated email.
    </div>
  </div>
);

const SubscribeClient = ({ type, isClient, isOpen, toggleEmailUpdate, onChangeSetToggle, isDisabled }) => (
  <>
    {!isReferral(type) && isClient && isOpen && (
      <SubscribeClientCheckbox
        toggleEmailUpdate={toggleEmailUpdate}
        onChangeSetToggle={onChangeSetToggle}
        isDisabled={isDisabled}
      />
    )}
  </>
);

SubscribeClientCheckbox.propTypes = {
  toggleEmailUpdate: bool,
  onChangeSetToggle: func,
  isDisabled: bool,
};

SubscribeClientCheckbox.defaultProps = {
  toggleEmailUpdate: false,
  onChangeSetToggle: () => {},
  isDisabled: false,
};
SubscribeClient.propTypes = {
  isClient: bool,
  isOpen: bool,
  type: string,
  toggleEmailUpdate: bool,
  onChangeSetToggle: func,
  isDisabled: bool,
};

SubscribeClient.defaultProps = {
  isClient: false,
  isOpen: false,
  isDisabled: false,
  type: null,
  toggleEmailUpdate: false,
  onChangeSetToggle: () => {},
};
const WrappedSubscribeClient = tdpDetails({
  WrappedComponent: SubscribeClient,
  states: TYPE,
});

export default WrappedSubscribeClient;
