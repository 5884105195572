import React from "react";
import PropTypes from "prop-types";
import Metric from "./Metric";
import { SectionLabel } from "../BusinessDashboard";

const MetricsGrid = ({ actions, titleName, titleValue, metrics }) => {
  const lastItemShouldSpan = (metrics?.length || 1) % 2 === 1;

  return (
    <>
      <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-start">
        <SectionLabel>
          {titleName}
          {typeof titleValue !== "undefined" && `: ${titleValue}`}
        </SectionLabel>
        {actions}
      </div>
      <div className="tw-grid tw-grid-cols-2 tw-auto-rows-fr tw-gap-[12px] tw-flex-1">
        {metrics?.map(({ metricValue, metricTitle }, i) => {
          const isLastItem = i + 1 === metrics.length;
          return (
            <Metric
              key={metricTitle}
              metricValue={metricValue}
              metricTitle={metricTitle}
              span={lastItemShouldSpan && isLastItem}
            />
          );
        })}
      </div>
    </>
  );
};

MetricsGrid.propTypes = {
  actions: PropTypes.node,
  titleName: PropTypes.string,
  titleValue: PropTypes.number,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      metricValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      metricTitle: PropTypes.string.isRequired,
    }),
  ),
};

MetricsGrid.defaultProps = {
  actions: null,
  titleName: "",
  titleValue: undefined,
  metrics: [],
};

export default MetricsGrid;
