import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "../shared/v2/Dropdown/Dropdown";
import { caseTransformingAxios } from "../shared/v2/caseTransformingAxios";
import Button from "../shared/v2/Button/Button";
import { useCurrentUser } from "../reducers/layoutReducer/selectors";

const useAccountAgents = (accountId) => {
  const [data, setData] = useState({ accountAgents: [], currentAgentId: null });

  useEffect(() => {
    if (!accountId) return;
    caseTransformingAxios.get(`/account_users/${accountId}`).then((res) => setData(res.data));
  }, [accountId]);

  const actions = {
    becomeUser: (personId) => {
      caseTransformingAxios.post("/account_users", { personId }).then(() => window.location.assign("/tasks"));
    },
  };

  return { actions, data };
};

const MetaUser = () => {
  const currentMetaUser = useSelector(({ layout }) => layout.currentMetaUser);
  const currentUser = useCurrentUser();
  const isSelf = currentMetaUser?.primaryPerson.id === currentUser?.person.id;
  const [account, setAccount] = useState(
    currentMetaUser?.accountOptions.find((a) => a.value === currentUser?.account.id),
  );
  const [agent, setAgent] = useState(null);
  const {
    actions,
    data: { accountAgents, currentAgentId },
  } = useAccountAgents(account?.value);

  useEffect(() => {
    if (!currentAgentId) return;
    const newAgent =
      accountAgents.find(({ value }) => value === currentUser.person.id) ||
      accountAgents.find(({ value }) => value === currentAgentId);
    setAgent(newAgent);
  }, [accountAgents, currentAgentId, currentUser]);

  if (!currentMetaUser) return null;
  return (
    <div
      className={`${
        isSelf ? "tw-bg-[#CECECE] acting-as-self" : "tw-bg-[#3B3B3B] acting-as-other"
      } tw-flex tw-justify-between tw-items-center tw-gap-[8px] tw-overflow-x-auto tw-p-[8px]`}
    >
      <span
        className={`tw-text-16d ${
          isSelf ? "tw-text-semantic-yellow-120" : "tw-text-semantic-yellow-50"
        } tw-whitespace-nowrap`}
      >
        TEAM EDITION
      </span>
      <div className="tw-flex-1 tw-flex tw-justify-center tw-gap-[8px]">
        <Dropdown
          isSearchable
          containerClassName="tw-min-w-[200px] tw-max-w-[400px] tw-w-full"
          options={currentMetaUser.accountOptions}
          value={account}
          onChange={setAccount}
          menuShouldComeToFront
        />
        <Dropdown
          isSearchable
          containerClassName="tw-min-w-[200px] tw-max-w-[400px] tw-w-full"
          options={accountAgents}
          value={agent}
          onChange={setAgent}
          menuShouldComeToFront
        />
        <Button size="medium" onClick={() => actions.becomeUser(agent.value)}>
          Switch
        </Button>
      </div>
      {!isSelf && (
        <Button
          className="tw-whitespace-nowrap"
          size="medium"
          onClick={() => actions.becomeUser(currentMetaUser.primaryPerson.id)}
        >
          Default User
        </Button>
      )}
    </div>
  );
};

export default MetaUser;
