import React from "react";
import Proptypes from "prop-types";
import { tdpDates } from "../../../../shared/HOCs/withRedux";
import { CLOSE_DATE } from "../../../../shared/reduxRoutes/tdpDatesRoutes";
import ModifyDate from "./ModifyDate";

const CloseDate = ({ close_date_milestone: closeDate }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[2px] tw-px-[12px] tw-h-[44px] tw-min-h-[44px] tw-rounded tw-bg-tinted-gray-50 tw-text-gray-75 tw-text-[14px] tw-font-semibold tw-leading-[20px]">
    Close Date
    <ModifyDate date={closeDate} />
  </div>
);

CloseDate.propTypes = {
  close_date_milestone: Proptypes.shape({
    id: Proptypes.string,
    name: Proptypes.string,
    date: Proptypes.string,
  }),
};
CloseDate.defaultProps = {
  close_date_milestone: null,
};

const CloseDateWrapped = tdpDates({
  WrappedComponent: CloseDate,
  states: CLOSE_DATE,
});

export default CloseDateWrapped;
