/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { sortBy } from "../../../../Transactions/utils/helpers";

import { nextIndexForProperty } from "./orderHelpers";

const buildCustomTeamMemberExpense = ({ expense, base }) => {
  const expenseTypeName = Object.keys(base.agent_expense_types).find(
    (key) => base.agent_expense_types[key] === expense.agent_expense_type_id,
  );
  return { ...expense, name: expense.name !== null ? expense.name : expenseTypeName };
};

const buildCustomTeamMemberExpenses = ({ selectedMember, base }) =>
  selectedMember.meta.customTeamMemberExpensesAttributes.map((expense) =>
    buildCustomTeamMemberExpense({ expense, base }),
  );

const buildNewTeamMember = ({ base, selectedMember }) => {
  const customTeamMemberExpenses = buildCustomTeamMemberExpenses({ selectedMember, base });

  return {
    user_id: selectedMember.value,
    name: selectedMember.label,
    agent_gci: 0,
    agent_gci_percentage: false,
    agent_gci_units: 0,
    agent_gci_notes: null,
    brokerage_split: selectedMember.meta.brokerageSplit,
    brokerage_split_percentage: selectedMember.meta.brokerageSplitType === "percent",
    brokerage_split_amount: 0,
    brokerake_split_line_total: 0,
    royalty: selectedMember.meta.royalty,
    royalty_percentage: selectedMember.meta.royaltyType === "percent",
    royalty_amount: 0,
    royalty_line_total: 0,
    gross_income: 0,
    net_income: 0,
    custom_team_member_expenses_attributes: customTeamMemberExpenses,
  };
};

export const queries = (data) => {
  const collection = () => data.team_member_incomes.incomes;
  const find = ({ memberId }) => collection().find((teamMember) => teamMember.id === memberId);
  const extrasFor = (teamMember) => teamMember.custom_team_member_expenses_attributes;
  const extrasForType = (teamMember, type) => teamMember[`custom_team_member_${type}_attributes`];
  const findExtraForType = ({ memberId, id, type }) =>
    extrasForType(find({ memberId }), type).find((extra) => extra.id === id);
  const findExtraFor = ({ memberId, id }) => extrasFor(find({ memberId })).find((extra) => extra.id === id);

  // we need to include team member since royalty and brokerage line total is in this level;
  const combinedCollections = (teamMember) => [
    ...extrasForType(teamMember, "expenses"),
    ...extrasForType(teamMember, "revenues"),
    teamMember,
  ];
  const reorderCombinedCollections = (teamMember) => sortBy(combinedCollections(teamMember), "position");
  const reconcilePositionAfterDestroy = (teamMember) =>
    reorderCombinedCollections(teamMember)
      .filter((acc) => !acc._destroy)
      .forEach((acc, i) => {
        acc.position = i + 1;
      });
  const nextExtraPositionFor = (teamMember) =>
    nextIndexForProperty([teamMember, ...extrasFor(teamMember)], "position");

  const reorderExtrasFor = (teamMember) => {
    // In place updating, as the rest of the actions
    teamMember.custom_team_member_expenses_attributes = sortBy(
      teamMember.custom_team_member_expenses_attributes,
      "position",
    );

    teamMember.custom_team_member_revenues_attributes = sortBy(
      teamMember.custom_team_member_revenues_attributes,
      "position",
    );
  };

  return {
    collection,
    combinedCollections,
    reorderCombinedCollections,
    reconcilePositionAfterDestroy,
    find,
    extrasFor,
    extrasForType,
    findExtraFor,
    findExtraForType,
    nextExtraPositionFor,
    reorderExtrasFor,
    actions: {
      buildNewTeamMember,
    },
  };
};

export default queries;
