import * as creators from "./creators";
import saveBrandingFormApi from "../helpers/services";

const DEFAULT_MESSAGE = "Something went wrong.  Please try again.";

export const saveBrandingForm = (id, formData) => async (dispatch) => {
  dispatch(creators.saveForm());
  const onFailure = (error) => {
    dispatch(creators.saveFormError({error: error.response?.data?.errors?.[0]?.context || error.response?.data?.error || DEFAULT_MESSAGE}));
      setTimeout(() => document.querySelector(".alert")?.scrollIntoView());
    }
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error || DEFAULT_MESSAGE);
    }
    return dispatch(creators.saveFormSuccess());
  };
  return saveBrandingFormApi({ id, formData, successCb: onSuccess, errorCb: onFailure });
};
