/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import Avatar from "../../../shared/v2/Avatar";
import { Spinner } from "../../../shared/v2/Icomoon";
import * as actions from "../actions/default";
import DeactivateUserModal from "../DeactivateUserModal";
import ActivateUserModal from "../ActivateUserModal";
import TableMessage from "./TableMessage";
import OktaInviteButton from "./OktaInviteButton";
import UserActions from "../UserActions";
import SuspendUserModal from "../SuspendUserModal";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const mapStateToProps = (state) => ({ isTableDataLoading: state.userSettings.isTableDataLoading });

function UsersTableBody({
  usersData,
  isTableDataLoading,
  getUserTableData,
  setUsersTableErrors,
  isFilterActiveUsers,
}) {
  const currentUser = useCurrentUser();
  const [isDeactivatingUser, setIsDeactivatingUser] = useState({ show: false, userId: null, name: null });
  const [isActivatingUser, setIsActivatingUser] = useState({ show: false, userId: null });
  const [suspendedPayload, setSuspendedPayload] = useState(null);

  return (
    <>
      <tbody>
        {usersData.length <= 0 ? (
          <>
            {isTableDataLoading ? (
              <TableMessage iconComponent={<Spinner size="l" className="tw-animate-spin " />} />
            ) : (
              <TableMessage text="No Data Matches" data-cy="settings-users-no-data-matches" />
            )}
          </>
        ) : (
          usersData.map((user) => {
            const {
              id,
              userId,
              fullName,
              agentType,
              position,
              startDate,
              avatar,
              crmAccess,
              lastActiveDate,
              oktaLastSignInAt,
              isOktaUser,
              oktaInvitedAt,
              email,
              signInCount,
              dateAdded,
              suspended,
              active,
            } = user;
            return (
              <tr
                key={id}
                className="tw-text-14d hover:tw-bg-tinted-gray-50 tw-border-neutral-gray-10 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-b tw-border-solid"
              >
                <td>
                  <div className="tw-my-[10px] tw-mx-10px">
                    <Avatar
                      src={avatar === "/assets/user-default.svg" ? "" : avatar}
                      size="medium"
                      alt={`${fullName} avatar image`}
                    >
                      {fullName}
                    </Avatar>
                  </div>
                </td>
                <td>
                  <div className="tw-ml-[8px]">
                    <a href={`/people/${id}`}>{fullName}</a>
                  </div>
                </td>
                <td>
                  <div className="tw-ml-[8px]">{agentType}</div>
                </td>
                <td>
                  <div className="tw-ml-[8px]">{position}</div>
                </td>
                <td>
                  <div className="tw-text-ellipsis tw-overflow-hidden tw-ml-[8px]">{email}</div>
                </td>
                <td>
                  <div className="tw-ml-[8px]">
                    {startDate ? moment(startDate).format("MM/DD/YYYY") : "--- ---"}
                  </div>
                </td>
                <td>
                  <div className="tw-ml-[8px]">
                    <OktaInviteButton
                      aria-label="invite user"
                      isOktaUser={isOktaUser}
                      lastActiveDate={lastActiveDate}
                      oktaInvitedAt={oktaInvitedAt}
                      oktaLastSignInAt={oktaLastSignInAt}
                      crmAccess={crmAccess}
                      userId={userId}
                    />
                  </div>
                </td>
                {currentUser.account.isAdmin && isFilterActiveUsers && (
                  <td>
                    <Tooltip
                      trigger={
                        <div className="tw-pr-[51px] tw-text-center tw-cursor-default">
                          {(() => {
                            if (suspended) return "Suspended";

                            return crmAccess ? "Yes" : "No";
                          })()}
                        </div>
                      }
                      innerClassName="tw-w-[287px] !tw-text-left"
                      tooltipClassName="tw-ml-[-25px]"
                      content={(() => {
                        if (suspended)
                          return "Suspended users cannot login to Brivity or any associated apps";

                        return crmAccess
                          ? "This user has full access to Brivity and associated apps."
                          : "Users without CRM access can login to their PLACE account. Contact support@place.com to turn on CRM access.";
                      })()}
                    />
                  </td>
                )}
                <td>
                  <div className="tw-ml-[8px]">
                    {dateAdded ? moment(dateAdded).format("MM/DD/YYYY") : "--- ---"}
                  </div>
                </td>
                <td className="tw-text-center">{signInCount}</td>
                <td>
                  <div className="tw-flex tw-gap-[12px] tw-justify-center">
                    <UserActions
                      isActive={active}
                      trashUserHandlr={() => setIsDeactivatingUser({ show: true, userId, name: fullName })}
                      unArchiveUserHandlr={() =>
                        setIsDeactivatingUser({ show: true, userId, name: fullName })
                      }
                      activeHandlr={
                        active
                          ? () => setIsDeactivatingUser({ show: true, userId, name: fullName })
                          : () => setIsActivatingUser({ show: true, userId })
                      }
                      userId={id}
                      suspendHandlr={() => {
                        setSuspendedPayload({ action: suspended ? "unsuspend" : "suspend", userId });
                      }}
                      suspended={suspended}
                      agentType={agentType}
                    />
                  </div>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
      <DeactivateUserModal
        onClose={(data, err) => {
          if (data) getUserTableData();
          if (err) setUsersTableErrors(err);
          setIsDeactivatingUser({ show: false, userId: null, name: null });
        }}
        userId={isDeactivatingUser.userId}
        show={isDeactivatingUser.show}
      />
      {isActivatingUser.show && (
        <ActivateUserModal
          onClose={(data, err) => {
            if (data) getUserTableData();
            if (err) setUsersTableErrors(err);
            setIsActivatingUser({ show: false, userId: null });
          }}
          userId={isActivatingUser.userId}
          show={isActivatingUser.show}
        />
      )}
      <SuspendUserModal
        show={Boolean(suspendedPayload)}
        onHideHandlr={() => setSuspendedPayload(null)}
        suspendedPayload={suspendedPayload}
      />
    </>
  );
}
UsersTableBody.propTypes = {
  usersData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isTableDataLoading: PropTypes.bool.isRequired,
  setUsersTableErrors: PropTypes.func.isRequired,
  getUserTableData: PropTypes.func.isRequired,
  isFilterActiveUsers: PropTypes.bool,
};

UsersTableBody.defaultProps = {
  isFilterActiveUsers: true,
};

export default connect(mapStateToProps, actions)(UsersTableBody);
