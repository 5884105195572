import { useEffect, useState } from "react";
import axios from "axios";

const useEmailPlaceholders = ({ listingId, personUuid }) => {
  const [loading, setLoading] = useState(true);
  const [emailPlaceholders, setEmailPlaceholders] = useState([]);
  useEffect(() => {
    if (!personUuid) return;
    axios
      .get(
        listingId
          ? `/api/v4/transactions/interactions/email_placeholders`
          : `/api/v4/person/${personUuid}/one_off_email_placeholders`,
        { params: { uuid: listingId, person_id: personUuid } },
      )
      .then(({ data }) => {
        setEmailPlaceholders(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Failed to load email placeholders", err);
        setLoading(false);
      });
  }, [listingId, personUuid]);
  return { loading, emailPlaceholders };
};

export default useEmailPlaceholders;
