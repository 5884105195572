// eslint-disable-next-line import/prefer-default-export
export const LOCALITY_LIST = [
  {
    label: "United States",
    options: [
      { label: "Alabama (AL)", value: "AL" },
      { label: "Alaska (AK)", value: "AK" },
      { label: "American Samoa (AS)", value: "AS" },
      { label: "Arizona (AZ)", value: "AZ" },
      { label: "Arkansas (AR)", value: "AR" },
      { label: "California (CA)", value: "CA" },
      { label: "Colorado (CO)", value: "CO" },
      { label: "Connecticut (CT)", value: "CT" },
      { label: "Delaware (DE)", value: "DE" },
      { label: "District of Columbia (DC)", value: "DC" },
      { label: "Federated States of Micronesia (FM)", value: "FM" },
      { label: "Florida (FL)", value: "FL" },
      { label: "Georgia (GA)", value: "GA" },
      { label: "Guam (GU)", value: "GU" },
      { label: "Hawaii (HI)", value: "HI" },
      { label: "Idaho (ID)", value: "ID" },
      { label: "Illinois (IL)", value: "IL" },
      { label: "Indiana (IN)", value: "IN" },
      { label: "Iowa (IA)", value: "IA" },
      { label: "Kansas (KS)", value: "KS" },
      { label: "Kentucky (KY)", value: "KY" },
      { label: "Louisiana (LA)", value: "LA" },
      { label: "Maine (ME)", value: "ME" },
      { label: "Marshall Islands (MH)", value: "MH" },
      { label: "Maryland (MD)", value: "MD" },
      { label: "Massachusetts (MA)", value: "MA" },
      { label: "Michigan (MI)", value: "MI" },
      { label: "Minnesota (MN)", value: "MN" },
      { label: "Mississippi (MS)", value: "MS" },
      { label: "Missouri (MO)", value: "MO" },
      { label: "Montana (MT)", value: "MT" },
      { label: "Nebraska (NE)", value: "NE" },
      { label: "Nevada (NV)", value: "NV" },
      { label: "New Hampshire (NH)", value: "NH" },
      { label: "New Jersey (NJ)", value: "NJ" },
      { label: "New Mexico (NM)", value: "NM" },
      { label: "New York (NY)", value: "NY" },
      { label: "North Carolina (NC)", value: "NC" },
      { label: "North Dakota (ND)", value: "ND" },
      { label: "Northern Mariana Islands (MP)", value: "MP" },
      { label: "Ohio (OH)", value: "OH" },
      { label: "Oklahoma (OK)", value: "OK" },
      { label: "Oregon (OR)", value: "OR" },
      { label: "Palau (PW)", value: "PW" },
      { label: "Pennsylvania (PA)", value: "PA" },
      { label: "Puerto Rico (PR)", value: "PR" },
      { label: "Rhode Island (RI)", value: "RI" },
      { label: "South Carolina (SC)", value: "SC" },
      { label: "South Dakota (SD)", value: "SD" },
      { label: "Tennessee (TN)", value: "TN" },
      { label: "Texas (TX)", value: "TX" },
      { label: "Utah (UT)", value: "UT" },
      { label: "Vermont (VT)", value: "VT" },
      { label: "Virgin Islands (VI)", value: "VI" },
      { label: "Virginia (VA)", value: "VA" },
      { label: "Washington (WA)", value: "WA" },
      { label: "West Virginia (WV)", value: "WV" },
      { label: "Wisconsin (WI)", value: "WI" },
      { label: "Wyoming (WY)", value: "WY" },
    ],
  },
  {
    label: "Canada",
    options: [
      { label: "Alberta (AB)", value: "AB" },
      { label: "British Columbia (BC)", value: "BC" },
      { label: "Manitoba (MB)", value: "MB" },
      { label: "New Brunswick (NB)", value: "NB" },
      { label: "Newfoundland and Labrador (NL)", value: "NL" },
      { label: "Northwest Territories (NT)", value: "NT" },
      { label: "Nova Scotia (NS)", value: "NS" },
      { label: "Nunavut (NU)", value: "NU" },
      { label: "Ontario (ON)", value: "ON" },
      { label: "Prince Edward Island (PE)", value: "PE" },
      { label: "Quebec (QC)", value: "QC" },
      { label: "Saskatchewan (SK)", value: "SK" },
      { label: "Yukon (YT)", value: "YT" },
    ],
  },
];
