import React from "react";
import PropTypes from "prop-types";

const FilterButton = ({ handleFilter }) => (
  <span>
    <button
      data-cy="filter-button"
      style={{
        borderRadius: "35px",
        margin: "-44px 10px 0 10px",
        backgroundColor: "#59C4C4",
        color: "white",
      }}
      type="button"
      className="btn pull-right filtered"
      onClick={handleFilter}
    >
      Filter
    </button>
  </span>
);

FilterButton.propTypes = {
  handleFilter: PropTypes.func.isRequired,
};

export default FilterButton;
