import React from "react";
import PropTypes from "prop-types";
import { viewPhoneFromFlat, viewPhoneShape } from "@shared/models/Communications/viewPhone";
import Tooltip from "@shared/v2/Tooltip/Tooltip";
import DncFlag from "@shared/Sms/DncFlag";
import IconDropdown from "./Interactions/IconDropdown";
import StatusOption from "./Interactions/PhoneStatusOption";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { PopoverItem } from "@shared/v2/Popover";

const callablePhone = viewPhoneFromFlat({});
const callUnsubscribedPhone = viewPhoneFromFlat({ call_soft_unsubscribed: true });

const smsTextablePhone = viewPhoneFromFlat({ sms_textable: true, value: "123" });
const smsNotTextablePhone = viewPhoneFromFlat({ sms_textable: false });
const smsUnsubscribedPhone = viewPhoneFromFlat({ sms_soft_unsubscribed: true });
const smsStatusLabels = {
  valid: "Can Be Texted",
  voiceOnly: "Cannot Be Texted",
  invalid: "Invalid",
  unsubscribed: "Opted Out",
};

const selectPhoneStatusFromContacts = (storeState) =>
  !!storeState.contactsReducer?.featureFlags?.phone_status;
const selectPhoneStatusFromLayout = (state) => !!state.layout?.featureFlags?.phoneStatus;
const phoneStatusEnabled = createSelector(
  [selectPhoneStatusFromContacts, selectPhoneStatusFromLayout],
  (contactStatus, layoutStatus) => contactStatus || layoutStatus,
);

const PhoneValue = ({ phone, hideCallStatus, isDnc, showPrimaryStatus, onChange }) => {
  const { formattedNumber, callIcon, smsIcon, mobileCallIcon, isMobile, isPrimary } = phone;
  const usedIcon = isMobile ? mobileCallIcon : callIcon;
  const editablePhoneStatus = useSelector(phoneStatusEnabled);

  const callIconKey = phone.isMobile ? "mobileCallIcon" : "callIcon";
  const callOptions = [
    {
      status: "valid",
      icon: callablePhone[callIconKey].icon({ size: "l" }),
      onClick: () => onChange("valid", "call"),
    },
    {
      status: "unsubscribed",
      icon: callUnsubscribedPhone[callIconKey].icon({ size: "l" }),
      onClick: () => onChange("unsubscribed", "call"),
    },
  ].map(({ onClick, ...x }) => (
    <PopoverItem onClick={onClick}>
      <StatusOption {...x} />
    </PopoverItem>
  ));

  const smsOptions = [
    {
      status: "valid",
      icon: smsTextablePhone.smsIcon.icon({ size: "xl" }),
      onClick: () => onChange("valid", "sms"),
    },
    {
      status: "voiceOnly",
      icon: smsNotTextablePhone.smsIcon.icon({ size: "xl" }),
      onClick: () => onChange("voiceOnly", "sms"),
    },
    {
      status: "unsubscribed",
      icon: smsUnsubscribedPhone.smsIcon.icon({ size: "xl" }),
      onClick: () => onChange("unsubscribed", "sms"),
    },
  ].map(({ onClick, ...x }) => (
    <PopoverItem onClick={onClick}>
      <StatusOption {...x} statusTexts={smsStatusLabels} />
    </PopoverItem>
  ));

  const currentCallIcon = usedIcon.icon({ size: "xl" });
  const currentSmsIcon = smsIcon.icon({ size: "xl" });

  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-flex-row tw-items-center tw-space-x-12px tw-w-full tw-relative">
        {editablePhoneStatus && (
          <>
            {!hideCallStatus && (
              <div className="tw-flex tw-flex-row tw-items-center">
                <IconDropdown icon={currentCallIcon} tooltip={usedIcon.tooltip} editable={phone.callEditable}>
                  {callOptions}
                </IconDropdown>
              </div>
            )}
            <div className="tw-flex tw-flex-row tw-items-center">
              <IconDropdown icon={currentSmsIcon} tooltip={smsIcon.tooltip} editable={phone.smsEditable}>
                {smsOptions}
              </IconDropdown>
            </div>
          </>
        )}
        {!editablePhoneStatus && (
          <>
            {!hideCallStatus && (
              <Tooltip
                className="tw-flex tw-items-center"
                innerClassName="tw-max-w-[200px]"
                content={usedIcon.tooltip}
                trigger={currentCallIcon}
              />
            )}
            <Tooltip
              className="tw-flex tw-items-center"
              innerClassName="tw-max-w-[200px]"
              content={smsIcon.tooltip}
              trigger={currentSmsIcon}
            />
          </>
        )}
        <span>
          {formattedNumber} {showPrimaryStatus && isPrimary && "(*)"}
        </span>
        <DncFlag show={isDnc} />
      </div>
    </div>
  );
};

PhoneValue.propTypes = {
  hideCallStatus: PropTypes.bool,
  isDnc: PropTypes.bool,
  phone: PropTypes.shape(viewPhoneShape),
  showPrimaryStatus: PropTypes.bool,
};

PhoneValue.defaultProps = {
  hideCallStatus: false,
  isDnc: false,
  phone: {},
  showPrimaryStatus: false,
};

export default PhoneValue;
