import moment from "moment";
import { isCancel } from "axios";
import { startCase } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";
import colors from "../../DashboardShared/helpers/colors";
import { renderPapTooltip } from "../../DashboardShared/helpers/renderCustomTooltip";
import { generateDiffBox, scaleValue } from "../utils/chart";

const decimalFormat = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 1,
});

const getChartOptions = ({ goal, data }) => ({
  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: false,
      external: renderPapTooltip,
    },
    goalLine: {
      goal,
    },
    annotation: {
      clip: false,
      common: {
        drawTime: "afterDraw",
      },
      annotations: data.prevMonthDifferences.reduce(
        (p, c, i) => ({
          ...p,
          [i]: generateDiffBox(c, i),
        }),
        {},
      ),
    },
  },
  layout: {
    padding: { bottom: (ctx) => scaleValue(ctx, "paddingBottom", 32) },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      offset: true,
    },
    x2: {
      stacked: true,
      display: false,
      type: "category",
      grid: {
        offset: true,
      },
      offset: true,
    },
    y: {
      border: {
        display: false,
      },
      min: 0,
      ticks: {
        beginAtZero: true,
        autoSkip: false,
        callback: (value) => (value > 1000 ? `${Math.ceil(value / 1000)}k` : value),
        padding: 10,
        stepSize: 0.5,
      },
    },
  },
});

const transformPerAgentProduction = (
  rawData = {
    summary: { fullTimePapYtd: 0, papYtd: 0, partTimPapYtd: 0 },
    fullTime: { annualGoal: 0, chartDataPreviousYear: [], chartDataSelectedYear: [] },
    partTime: { chartDataPreviousYear: [], chartDataSelectedYear: [] },
    year: 0,
  },
  agentType,
  includeOperatorProduction,
) => {
  const selectedData = rawData[agentType];
  const lastFromPreviousYear = selectedData.chartDataPreviousYear.at(-1);
  const parsingKey = `totalWith${includeOperatorProduction ? "" : "out"}Operator`;
  return {
    summary: rawData.summary,
    prevMonthDifferences: (lastFromPreviousYear
      ? [lastFromPreviousYear].concat(selectedData.chartDataSelectedYear)
      : []
    ).reduce((p, c, i, a) => {
      const current = c;
      const next = a[i + 1];
      if (typeof next === "undefined" || moment(next.date, "YYYY-MM-DD").startOf("month").isAfter(moment())) {
        return p;
      }
      p.push(next[parsingKey] - current[parsingKey]);
      return p;
    }, []),
    chartData: {
      labels: selectedData.chartDataSelectedYear.map(({ date }) => moment(date).format("MMM")),
      datasets: [
        {
          label: `${startCase(agentType)} ${rawData.year}`,
          data: selectedData.chartDataSelectedYear,
          parsing: { yAxisKey: parsingKey, xAxisKey: parsingKey },
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: colors.color1.default,
            formatter: (d) => (d[parsingKey] > 0 ? decimalFormat.format(d[parsingKey]) : ""),
          },
          backgroundColor: colors.color1.default,
          borderColor: colors.color1.default,
          lineTension: 0,
          barPercentage: 0.25,
        },
        {
          label: `${startCase(agentType)} ${rawData.year - 1}`,
          data: selectedData.chartDataPreviousYear,
          parsing: { yAxisKey: parsingKey, xAxisKey: parsingKey },
          datalabels: { formatter: () => "" },
          backgroundColor: "#ECEFEF",
          borderColor: "#ECEFEF",
          lineTension: 0,
          barPercentage: 0.5,
          xAxisID: "x2",
        },
      ],
    },
  };
};

const getPerAgentProduction = ({ signal, params }) =>
  caseTransformingAxios.get("/api/v4/reporting/operator_dashboard", {
    params: { section: "per_agent_production", ...params },
    signal,
  });

const usePerAgentProduction = ({ year, agentType, rangeType, includeOperatorProduction }) => {
  const [rawData, setRawData] = useState(undefined);
  const [state, _setState] = useState({
    data: transformPerAgentProduction(rawData, agentType, includeOperatorProduction),
    error: null,
    loading: false,
  });
  const setState = useMemo(
    () => (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  );

  useEffect(
    () => setState({ data: transformPerAgentProduction(rawData, agentType, includeOperatorProduction) }),
    [rawData, agentType, includeOperatorProduction],
  );

  useEffect(() => {
    const abortController = new AbortController();
    setState({ loading: true });
    getPerAgentProduction({
      signal: abortController.signal,
      params: { selected_year: year, range_type: rangeType.value },
    })
      .then(({ data }) => {
        setState({ error: null, loading: false });
        setRawData(data);
      })
      .catch((err) => {
        if (!isCancel(err)) setState({ error: true, loading: false });
      });

    return () => abortController.abort();
  }, [year, rangeType]);

  return {
    ...state,
    chartOptions: getChartOptions({ goal: rawData?.fullTime?.annualGoal, data: state.data }),
    actions: {
      clearError: () => setState({ error: null }),
    },
  };
};

export default usePerAgentProduction;
