/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentRegularV6 = (props) => (
  <Icon {...props} name="Comment Regular V6">
    <path d="M8 1C3.581 1 .001 3.909.001 7.5c0 1.488.622 2.852 1.653 3.944-.465 1.234-1.433 2.278-1.449 2.289a.753.753 0 0 0-.145.813.757.757 0 0 0 .689.455c1.922 0 3.409-.805 4.347-1.445a9.57 9.57 0 0 0 2.903.445c4.419 0 7.972-2.91 7.972-6.5s-3.553-6.5-7.972-6.5zm.003 11.5a8.1 8.1 0 0 1-2.449-.379l-.711-.223-.609.43a6.689 6.689 0 0 1-1.797.906c.23-.379.449-.805.621-1.258l.332-.875-.644-.683c-.563-.603-1.243-1.6-1.243-2.918 0-2.758 2.914-5 6.5-5s6.5 2.242 6.5 5-2.915 5-6.499 5z" />
  </Icon>
);

export default CommentRegularV6;
