import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import buttons from '../button.module.css';
import Error from "../../../shared/Error";


const DeleteAction = (props) => {
  const {
    assignedPlan,
    deleteAssignedActionModalOpen,
    isDeletingAction,
    closeModal,
    action,
    onActionDelete,
    assignedPlanName,
    hasActionError,
    actionErrorMessage,
    showPlanDetails
  } = props;

  const assignedActionPath = `/assigned_plans/${action.assigned_plan_id}/assigned_actions/${action.id}`;
  const afterDeleteCallback = () => { showPlanDetails(assignedPlan.id) }


  return (
    <Modal
      className="person-details-modal"
      onHide={closeModal}
      show={deleteAssignedActionModalOpen}
    >
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col xs={11}>
              <span className='text-danger' style={{ fontSize: "18px" }}>Do you really want to delete this action?</span>
            </Col>
            <button type="button" className="close" style={{ top: "1px" }}>
              <span aria-hidden="true" onClick={closeModal}>×</span>
              <span className="sr-only">Close</span>
            </button>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            { hasActionError && <Error errorMessage={actionErrorMessage} /> }
            <span>
              Please note that <span className='bold'>{action.type}: {action.name} </span>
              is generated from action plan <span className='bold'> "{assignedPlanName}" </span>
              and deleting this action will delete all tasks and interactions contingent on that.
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='p-t-20'>
              <button
                onClick={closeModal}
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray}`}
              >
                Cancel
              </button>
              <button
                onClick={() => onActionDelete(action.id, assignedActionPath, afterDeleteCallback)}
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnRed} pull-right`}
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteAction;
