import React from "react";
import PropTypes from "prop-types";

import { Prompt } from "../../../../shared/v2/Modal";

const AssignedPlanDeletionConfirmationModal = ({ show, onCancel, onConfirm }) => (
  <Prompt
    title="Delete Auto Plan?"
    description="Are you sure you want to delete this auto plan?"
    primaryOption="Yes, delete"
    primaryProps={{ schema: "warning" }}
    show={show}
    onCancel={onCancel}
    onPrimary={onConfirm}
  />
);
AssignedPlanDeletionConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AssignedPlanDeletionConfirmationModal;
