import axios from "axios";
import { produce } from "immer";
import { updateStageAttribute } from "./helper";

export const updatePersonPromise = (editablePerson, phoneRemovalAck) => {
  const updatedPerson = produce(editablePerson, (draft) => {
    updateStageAttribute(draft);
    delete draft.meta;
  });

  return new Promise((resolve, reject) => {
    axios
      .patch(`/api/v4/person_detail/${updatedPerson.data.id}`, {
        person: updatedPerson,
        authenticity_token: ReactOnRails.authenticityToken(),
        phone_removal_ack: phoneRemovalAck,
      })
      .then((res) => {
        const successObject = {
          success: true,
          data: res.data,
        };
        resolve(successObject);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
