import React from "react";
import { bool, element, number, oneOfType, string } from "prop-types";

import Avatar from "../../../../shared/v2/Avatar";
import TaskItemAssignedPlanIcon from "../TaskItemAssignedPlanIcon";
import { Bolt } from "../../../../shared/v2/Icomoon";

export const ModalShowSection = ({ headerTxt, children, childrenClass }) => (
  <div className="tw-leading-[24px]">
    <div className="tw-mb-[2px] tw-font-semibold">{headerTxt}</div>
    <div className={childrenClass}>{children}</div>
  </div>
);

export const ModalShowAvatar = ({ assignee }) => (
  <div className="tw-flex tw-items-center">
    {assignee && (
      <Avatar
        className="tw-mr-8px"
        size="small"
        alt={assignee?.fullName ?? "Anyone"}
        src={assignee ? assignee?.avatar && assignee?.avatar : "/images/users/team.svg"}
      >
        {assignee?.fullName ?? "Anyone"}
      </Avatar>
    )}
    <div className="tw-text-12px tw-font-semibold">{assignee?.fullName || "None"}</div>
  </div>
);

export const ModalShowPriority = ({ priority }) =>
  priority ? (
    <div className="tw-w-24px tw-h-24px tw-border-neutral-gray-50 tw-flex tw-items-center tw-justify-center tw-border-1px tw-border-solid tw-rounded-full">
      {priority}
    </div>
  ) : (
    <div className="">None</div>
  );

export const ModalShowName = ({ title, hasAssignedPlans }) => (
  <div className="tw-flex tw-items-center">
    <TaskItemAssignedPlanIcon
      size="xl"
      IconComponent={Bolt}
      className="tw--ml-4px tw-mr-5px"
      show={hasAssignedPlans}
    />
    <div>{title || "No Title*"}</div>
  </div>
);

ModalShowName.propTypes = {
  title: string,
  hasAssignedPlans: bool,
};

ModalShowName.defaultProps = {
  title: null,
  hasAssignedPlans: false,
};

ModalShowSection.propTypes = {
  headerTxt: string,
  children: oneOfType([string, element]),
  childrenClass: string,
};

ModalShowSection.defaultProps = {
  headerTxt: null,
  children: null,
  childrenClass: "",
};

ModalShowAvatar.propTypes = {
  assignee: string,
};

ModalShowAvatar.defaultProps = {
  assignee: null,
};

ModalShowPriority.propTypes = {
  priority: number,
};

ModalShowPriority.defaultProps = {
  priority: null,
};
