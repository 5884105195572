import React from "react";
import { func, bool, string } from "prop-types";

import TaskItemName from "../TaskItemName";
import TaskItemAssignedPlanIcon from "../TaskItemAssignedPlanIcon";

const TaskItemNameFrame = ({ hasAssignedPlan, nameOnClick, name }) => (
  <div className="tw-ml-16px tw-mr-16px tw-flex tw-items-center">
    <TaskItemAssignedPlanIcon show={hasAssignedPlan} className="tw-mr-4px" />
    <TaskItemName
      onClick={nameOnClick}
      name={name}
      className="tw-grow tw-text-left tw-border-0 tw-bg-transparent enabled:hover:tw-underline"
    />
  </div>
);

TaskItemNameFrame.propTypes = {
  hasAssignedPlan: bool,
  nameOnClick: func.isRequired,
  name: string,
};

TaskItemNameFrame.defaultProps = {
  hasAssignedPlan: false,
  name: null,
};

export default TaskItemNameFrame;
