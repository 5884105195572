import React, { useState } from "react";
import assigned_to from "./assigned_to.module.css";
import { Col, Row } from "react-bootstrap";

const NonPrimaryTeamMembers = (props) => {
  const [moreOrLess, setMoreOrLess] = useState("MORE");
  const [sign, setSign] = useState("+");
  const [showMore, setShowMore] = useState(false);

  const findRole = (roleId, userType) => {
    if (roleId) {
      const role = props.allAssignableRoles?.find((role) => role.id === roleId);
      return role?.title;
    }
    return userType;
  };
  const handleShowMore = () => {
    setShowMore(!showMore);
    if (showMore) {
      setMoreOrLess("MORE");
      setSign("+");
    } else {
      setMoreOrLess("LESS");
      setSign("-");
    }
  };
  return (
    <>
      {props.nonPrimaryTeamMembers.slice(0, 2).map((teamMember) => (
        <Row key={teamMember.id} className={`${assigned_to.triggerArea} tw-mb-10px`}>
          <Col xs={10} className={`${assigned_to.agentInfo} d-flex flex-items-center`}>
            <div className={assigned_to.image} dangerouslySetInnerHTML={{ __html: teamMember.image }} />
            <div>
              <span>{teamMember.name}</span>
              <strong>{findRole(teamMember.role_id, teamMember.user_type)}</strong>
            </div>
          </Col>
          <Col xs={2} className={`${assigned_to.selectNew} d-flex flex-items-center`} />
        </Row>
      ))}
      {props.nonPrimaryTeamMembers.length > 2 &&
        showMore &&
        props.nonPrimaryTeamMembers.slice(2).map((teamMember) => (
          <Row key={teamMember.id} className={`${assigned_to.triggerArea} tw-mb-10px`}>
            <Col xs={10} className={`${assigned_to.agentInfo} d-flex flex-items-center`}>
              <div className={assigned_to.image} dangerouslySetInnerHTML={{ __html: teamMember.image }} />
              <div>
                <span>{teamMember.name}</span>
                <strong>{findRole(teamMember.role_id, teamMember.user_type)}</strong>
              </div>
            </Col>
            <Col xs={2} className={`${assigned_to.selectNew} d-flex flex-items-center`} />
          </Row>
        ))}
      {props.nonPrimaryTeamMembers.length > 2 && (
        <a
          className={`${assigned_to.moreOrLess} tw-text-sm tw-not-italic tw-font-bold tw-leading-5 tw-tracking-normal tw-text-left tw-text-brand-info tw-no-underline`}
          onClick={() => handleShowMore()}
        >
          {sign}
          {props.nonPrimaryTeamMembers.length - 2} {moreOrLess}
        </a>
      )}
    </>
  );
};

export default NonPrimaryTeamMembers;
