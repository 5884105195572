import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import axios from "axios";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";

const useMlsListingSearch = (searchTerm) => {
  const [debouncedSearchTerm, _setDebouncedSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const options = useMemo(() => data.map((d) => ({ value: d.selectedFields.id, label: d.value })), [data]);

  const setDebouncedSearchTerm = useMemo(
    () => debounce(_setDebouncedSearchTerm, 500),
    [_setDebouncedSearchTerm],
  );

  useEffect(() => {
    setDebouncedSearchTerm(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (!debouncedSearchTerm) return;
    const abortController = new AbortController();
    setData([]);
    setError(null);
    setLoading(true);

    caseTransformingAxios
      .get("/api/v4/transactions/autocomplete", {
        params: { term: searchTerm },
        signal: abortController.signal,
      })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setLoading(false);
          setError("Search failed, try again later");
        }
      });

    return () => {
      abortController.abort();
    };
  }, [debouncedSearchTerm]);

  return { data, error, loading, options };
};

export default useMlsListingSearch;
