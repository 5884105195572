import moment from "moment";

export const getListingUrl = ({ dataKey, year, id, agentId }) => {
  const queryParams = new URLSearchParams();
  let selectedYear = String(year);
  if (selectedYear === String(new Date().getFullYear())) selectedYear = "this_year";
  if (selectedYear === String(new Date().getFullYear() - 1)) selectedYear = "last_year";

  if (id) queryParams.append("source[]", id);
  if (agentId) queryParams.append("agent[]", agentId);

  if (dataKey === "sold") {
    queryParams.append("close_date_range[]", selectedYear);
  } else if (dataKey === "pipeline") {
    queryParams.append("status[]", "pipeline");
  } else if (dataKey === "active") {
    queryParams.append("status[]", "active");
  } else if (selectedYear === year) {
    queryParams.append("status_changed_range[]", "custom");
    const start = moment().set("year", year).startOf("year");
    const end = moment(start).endOf("year");
    queryParams.append("status_changed_dates[]", start.format("YYYY-MM-DD"));
    queryParams.append("status_changed_dates[]", end.format("YYYY-MM-DD"));
  } else {
    queryParams.append("status_changed_range[]", selectedYear);
  }

  return `/listings/${dataKey}?${queryParams}`;
};

export default ({ count, year }) => ({
  indexAxis: "y",
  responsive: true,
  aspectRatio: 1 / (Math.max(9, count) / 15),
  maintainAspectRatio: true,
  onClick: (_, [{ datasetIndex, index } = {}], { config: { data } }) => {
    if (datasetIndex === undefined) return;
    const dataset = data.datasets[datasetIndex];
    const dataKey = Object.values(dataset.parsing || {})[0];
    const item = data.datasets[datasetIndex].data[index];
    window.open(getListingUrl({ dataKey, year, id: item.id }));
  },
  onHover: (e, activeElements) => {
    e.native.target.style.cursor = activeElements.length ? "pointer" : "default";
  },
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false },
  },
  scales: {
    x: {
      stacked: true,
      border: { display: false },
      grid: { display: true, offset: false },
      position: "top",
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      stacked: true,
      grid: { display: false },
      offset: true,
    },
  },
});
