import moment from "moment";

export const exactDateHelper = (days) => moment().add(days, "days").format("MM/DD/YYYY");
export const exactDateYearHelper = () => moment().add(1, "years").format("MM/DD/YYYY");

const TIMEFRAME_DICTIONARY = {
  Immediate: {
    prefix: "",
    value: "date",
  },
  Unknown: {
    prefix: "",
    value: "date",
  },
  "Within 90 days": {
    prefix: "By",
    value: exactDateHelper(90),
  },
  "Within a year": {
    prefix: "By",
    value: exactDateHelper(365),
  },
  "After a year": {
    prefix: "After",
    value: exactDateHelper(365),
  },
  Custom: {
    prefix: "By",
    value: null,
  },
};

export const timeframeExactDate = (timeframe) => TIMEFRAME_DICTIONARY[timeframe].value;
