/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MapSolidV6 = (props) => (
  <Icon {...props} name="Map Solid V6">
    <path d="m10.667 14.114-5.333-1.525V1.887l5.333 1.524v10.703zm.889-10.77 3.531-1.412a.666.666 0 0 1 .914.619v9.299a.67.67 0 0 1-.419.619l-4.025 1.608V3.343zM.419 3.531l4.025-1.61v10.734l-3.53 1.414A.666.666 0 0 1 0 13.45v-9.3c0-.272.166-.517.419-.619z" />
  </Icon>
);

export default MapSolidV6;
