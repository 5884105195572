import React, { useContext } from "react";
import updateListingReq from "./shared/apiHelper";
// eslint-disable-next-line import/no-cycle
import { UpdateFromPipelineContext } from "./Wrapper";
import actions from "../actions";

import UpdatePipelineStatusModal from "../UpdatePipelineStatusModal";

const TDIUpdatePipelineStatusModal = () => {
  const { state, dispatch } = useContext(UpdateFromPipelineContext);

  const setToggleModal = (bool) => {
    dispatch(actions.updateAddressModal.toggleUpdateAddressModal(bool));
  };

  const onSubmit = (details) => {
    const addressDetails = details.find((item) => item.key === "address_attributes").value;
    dispatch(
      actions.listings.updateListing(
        state.updateAddressModal.listing,
        state.updateAddressModal.tempStatus,
        addressDetails,
      ),
    );
    details.push({
      key: "status",
      value:
        state.updateAddressModal.tempStatus === "comingsoon"
          ? "coming soon"
          : state.updateAddressModal.tempStatus,
    });

    updateListingReq(details, state.updateAddressModal.listing.uuid);
  };

  const revertStatuscloseModal = () => {
    // Dispatching state changes before preflight req, will revert if theres error on actions.listings.updateStateRequest
    const copyListing = JSON.parse(JSON.stringify(state.updateAddressModal.listing));
    dispatch(
      actions.listings.updateListing(
        state.updateAddressModal.listing,
        state.updateAddressModal.listing?.status,
      ),
    );
    dispatch(
      actions.listings.updateStatusRequest(copyListing, { status: state.updateAddressModal.listing?.status }),
    );
    setToggleModal(false);
  };

  return (
    <UpdatePipelineStatusModal
      isModalOpen={state?.updateAddressModal?.isUpdateAddressModalOpen}
      uuid={state?.updateAddressModal?.listing?.uuid || ""}
      closeModal={setToggleModal}
      onSubmit={onSubmit}
      revertStatuscloseModal={revertStatuscloseModal}
    />
  );
};

export default TDIUpdatePipelineStatusModal;
