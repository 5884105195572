const modalsClosed = {
  isApplyModalOpen: false,
  isPlanModalOpen: false,
  isPlansModalOpen: false,
  isPlanPreviewModalOpen: false,
  isModalSubmitting: false,
  isDeleteAssignedActionModalOpen: false,
  isDeletePlanModalOpen: false,
}

const defaultState = {
  ...modalsClosed,
  isFetchingAssignedPlans: false,
  isModalSubmitting: false,
  showCompletedPlans: true,
  showDeletedPlans: false,
  assignedPlans: [],
  assignedPlanForm: {},
  hasError: false,
  errorMessage: null,
  isAssignedActionUpdating: false,
};

const assignedPlans = (currentState = {}, payload) => {
  const state = {...defaultState, ...currentState};

  switch (payload.type) {
    case "FETCH_ASSIGNED_PLANS_PENDING":
      return {
        ...state,
        isFetchingAssignedPlans: true
      };
    case "FETCH_ASSIGNED_PLANS_SUCCESS":
      return {
        ...state,
        isFetchingAssignedPlans: false,
        assignedPlans: payload.assignedPlans
      };
    case "FETCH_ASSIGNED_PLANS_FAILURE":
      return {
        ...state,
        isFetchingAssignedPlans: false
      };
    case "FETCH_ASSIGNED_PLAN_PENDING":
      return {
        ...state,
        isFetchingAssignedPlan: true
      };
    case "FETCH_ASSIGNED_PLAN_SUCCESS":
      return {
        ...state,
        isFetchingAssignedPlan: false,
        assignedPlan: payload.assignedPlan
      };
    case "FETCH_ASSIGNED_PLAN_FAILURE":
      return {
        ...state,
        isFetchingAssignedPlan: false
      };
    case "CREATE_ASSIGNED_PLAN_PENDING":
      return {
        ...state,
        isModalSubmitting: true
      };
    case "CREATE_ASSIGNED_PLAN_SUCCESS":
      return {
        ...state,
        ...modalsClosed,
        isModalSubmitting: false,
        assignedPlanForm: {},
        assignedPlans: [payload.assignedPlan, ...state.assignedPlans],
        hasError: false,
        errorMessage: null
      };
    case "CREATE_ASSIGNED_PLAN_FAILURE":
      return {
        ...state,
        isModalSubmitting: false,
        hasError: true,
        errorMessage: payload.errors
      };
    case "SHOW_APPLY_MODAL":
      return {
        ...state,
        ...modalsClosed,
        isApplyModalOpen: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        ...modalsClosed,
        actionToDelete: null,
        activeAssignedActionId: null,
        activeAutoPlanActionId: null,
        errorMessage: null,
        hasError: false,
        assignedPlanForm: {},
      };
    case "SHOW_PLANS_MODAL":
      return {
        ...state,
        ...modalsClosed,
        showCompletedPlans: true,
        showDeletedPlans: false,
        isPlansModalOpen: true,
      };
    case "SHOW_PLAN_MODAL":
      return {
        ...state,
        ...modalsClosed,
        isPlanModalOpen: true,
        assignedPlan: payload.assignedPlan,
      };
    case "SHOW_PLAN_PREVIEW_MODAL":
      return {
        ...state,
        ...modalsClosed,
        isPlanPreviewModalOpen: true,
        autoPlanPreview: payload.autoPlan,
      };
    case "SHOW_DELETE_ASSIGNED_ACTION_MODAL":
      return {
        ...state,
        ...modalsClosed,
        isDeleteAssignedActionModalOpen: true,
        actionToDelete: payload.actionToDelete
      }
    case "SHOW_DELETE_ASSIGNED_PLAN_MODAL":
      return {
        ...state,
        ...modalsClosed,
        isDeletePlanModalOpen: true,
        planToDelete: payload.planToDelete,
        errorMessage: null,
        hasError: false,
      }
    case "UPDATE_VALUES":
      return {
        ...state,
        assignedPlanForm: {
          ...state.assignedPlanForm,
          [payload.name]: payload.value
        }
      };
    case "UPDATE_ASSIGNED_PLAN_SUCCESS":
      return {
        ...state,
        errorMessage: null,
        hasError: false,
        isDeletePlanModalOpen: false,
        assignedPlans: state.assignedPlans.map(
          plan =>
            plan.id === payload.assignedPlan.id
              ? { ...plan, ...payload.assignedPlan }
              : plan
        )
      };
    case "UPDATE_ASSIGNED_PLAN_FAILURE":
      return {
        ...state,
        errorMessage: payload.errors,
        hasError: true,
      }

    case "UPDATE_ASSIGNED_ACTION_PENDING":
      return {
        ...state,
        isAssignedActionUpdating: true
      }

    case "UPDATE_ASSIGNED_ACTION_FAILURE":
      return {
        ...state,
        isAssignedActionUpdating: false
      }

    case "UPDATE_ASSIGNED_ACTION_SUCCESS":
      return {
        ...state,
        isAssignedActionUpdating: false,
        assignedPlan: {
          ...state.assignedPlan,
          assigned_actions: state.assignedPlan.assigned_actions.map(
            a => (a.id === payload.assignedAction.id ? { ...a, ...payload.assignedAction } : a)
          )
        },
      };

    case "DELETE_ASSIGNED_ACTION_SUCCESS":
      return {
        ...state,
        assignedPlan: {
          ...state.assignedPlan,
          assigned_actions: state.assignedPlan.assigned_actions.filter(a => a.id != payload.assignedActionId),
        },
        activeAssignedActionId: null,
        isPlanModalOpen: payload.isPlanModalOpen,
        isDeleteAssignedActionModalOpen: null,
        actionToDelete: null,
        hasError: false,
        errorMessage: null
      };
    case "DELETE_ASSIGNED_ACTION_FAILURE":
      return {
        ...state,
        hasError: true,
        errorMessage: payload.error
      }
    case "TOGGLE_COMPLETED_PLANS":
      return {
        ...state,
        showCompletedPlans: !state.showCompletedPlans
      };
    case "TOGGLE_DELETED_PLANS":
      return {
        ...state,
        showDeletedPlans: !state.showDeletedPlans
      };

    case "PREVIEW_ASSIGNED_ACTION":
    return {
      ...state,
      activeAssignedActionId: payload.assignedAction.id,
    }

    case "PREVIEW_AUTO_PLAN_ACTION":
    return {
      ...state,
      activeAutoPlanActionId: payload.actionId,
    }

    default:
      return state;
  }
};

export default assignedPlans;
