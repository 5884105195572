import { schemaGenerator } from "../utils";

// The Checkbox component is implemented using an `input[type=checkbox]` element and has custom
// styles (following the design system) using the appearance none technique. Although it works, CSS
// selectors take precedence over Tailwind classes that affect `input[type=checkbox]`s globally. So
// to override it, we must decorate some of the Tailwind classes here with the important flag (!).
// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#styling_radio_inputs
// (applies to checkbox as well) and https://developer.mozilla.org/en-US/docs/Web/CSS/appearance

const dictionary = {
  regular: {
    default: [
      "tw-appearance-none",
      "tw-w-[14px]",
      "tw-h-[14px]",
      "!tw-m-0",
      "tw-rounded-[1.5px]",
      "tw-border",
      "tw-border-solid",
      "tw-border-neutral-gray-30",
    ],
    before: [
      "before:tw-content-['_']",
      "before:tw-block",
      "before:tw-relative",
      // Negative offset to account for the border of the input
      "before:tw-top-[-1px]",
      "before:tw-left-[-1px]",
      "before:tw-w-[14px]",
      "before:tw-h-[14px]",
    ],
    // !important is required because we need to override the browser's outline
    focus: ["focus:!tw-outline-0", "group-focus:!tw-outline-0"],
    focusVisible: ["focus-visible:!tw-outline-theme", "group-focus-visible:!tw-outline-theme"],
    hover: [
      "enabled:hover:tw-border-theme-checkbox-hover",
      "enabled:group-hover:tw-border-theme-checkbox-hover",
    ],
    disabled: [
      "disabled:tw-border-solid",
      "disabled:tw-border-neutral-gray-15",
      "disabled:tw-bg-neutral-gray-5",
    ],
    checked: ["checked:tw-border-theme-checkbox-checked"],
    checkedBefore: [
      "checked:before:tw-bg-theme-checkbox-checked",
      "checked:before:tw-bg-theme-checkbox-checked-mask",
    ],
    checkedHover: [
      "checked:enabled:hover:tw-border-theme-checkbox-checked-hover",
      "checked:enabled:group-hover:tw-border-theme-checkbox-checked-hover",
    ],
    checkedHoverBefore: [
      "checked:enabled:hover:before:tw-bg-theme-checkbox-checked-hover",
      "checked:enabled:group-hover:before:tw-bg-theme-checkbox-checked-hover",
    ],
    indeterminate: ["indeterminate:tw-border-theme-checkbox-indeterminate"],
    indeterminateBefore: [
      "indeterminate:before:tw-bg-theme-checkbox-indeterminate",
      "indeterminate:before:tw-bg-theme-checkbox-indeterminate-mask",
    ],
    indeterminateHover: [
      "indeterminate:enabled:hover:tw-border-theme-checkbox-indeterminate-hover",
      "indeterminate:enabled:group-hover:tw-border-theme-checkbox-indeterminate-hover",
    ],
    indeterminateHoverBefore: [
      "indeterminate:enabled:hover:before:tw-bg-theme-checkbox-indeterminate-hover",
      "indeterminate:enabled:group-hover:before:tw-bg-theme-checkbox-indeterminate-hover",
    ],
    checkedDisabled: ["checked:disabled:tw-border-neutral-gray-30"],
    checkedDisabledBefore: [
      "checked:disabled:before:tw-bg-neutral-gray-30",
      "checked:disabled:before:tw-bg-theme-checkbox-checked-mask",
    ],
    indeterminateDisabled: ["indeterminate:disabled:tw-border-neutral-gray-30"],
    indeterminateDisabledBefore: [
      "indeterminate:disabled:before:tw-bg-neutral-gray-30",
      "indeterminate:disabled:before:tw-bg-theme-checkbox-indeterminate-mask",
    ],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
