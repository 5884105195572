// Assignment to parameters in this file are in the context of immer, which
// automatically produces new objects for us without mutating existing state.
/* eslint-disable no-param-reassign */
import { produce } from "immer";
import { merge } from "lodash";

import {
  applyAutoPlanFailure,
  applyAutoPlanStarted,
  applyAutoPlanSuccess,
  assignedActionDeletion,
  assignedActionRestartOrMarkAsComplete,
  autoPlanPreview,
  confirmAssignedActionDeletion,
  confirmAssignedPlanDeletion,
  fetchAssignedPlanDetailsFailure,
  fetchAssignedPlanDetailsStarted,
  fetchAssignedPlanDetailsSuccess,
  fetchAssignedPlansFailure,
  fetchAssignedPlansStarted,
  fetchAssignedPlansSuccess,
  fetchAvailableAutoPlansFailure,
  fetchAvailableAutoPlansStarted,
  fetchAvailableAutoPlansSuccess,
  setApplyAutoPlanModalVisible,
  setApplyAutoPlanSelectedPlan,
  setShowDetailsModalForAssignedPlan,
  setShowPreviewModalForAutoPlan,
  updateAssignedPlanStateFailure,
  updateAssignedPlanStateStarted,
  updateAssignedPlanStateSuccess,
} from "../TransactionDetailsPage/SideRight/AutoPlans/actions/creators";

export const defaultState = {
  uuid: "",

  // Plans that are already assigned to the Transaction.
  assignedPlans: {
    plans: [],

    meta: {
      isLoading: true,
      error: null,

      // For when a plan is being paused/resumed, to stop the user from clicking
      // on the button multiple times while the request is being processed.
      idsBeingUpdated: new Set(),
    },
  },

  // For showing a plan's details.
  planDetails: {
    plan: null,

    meta: {
      showDetailsModalForId: null,

      isLoading: true,
      error: null,
    },
  },

  planPreview: {
    plan: null,
    showModalForId: null,

    meta: {
      status: "idle",
      error: null,
    },
  },

  // For confirming the deletion of an action within an assigned plan.
  assignedActionDelete: {
    status: "idle",
    error: null,
    showModalFor: null,
  },

  // For marking an action as complete, or restarting it.
  assignedActionRestartOrMarkAsComplete: {
    status: "idle",
    error: null,
  },

  // For confirming the deletion of an assigned plan.
  assignedPlanDelete: {
    showModalForId: null,
  },

  // Available Transaction auto plans.
  availableAutoPlans: {
    plans: [],

    meta: {
      isLoading: true,
      error: null,
    },
  },

  // For applying an AutoPlan to the Transaction.
  autoPlanApply: {
    showModal: false,
    selectedPlanOption: null,
    // "null" is the default "settled" state, "applying" means waiting on the
    // request, "success" means the plan was applied and we can close out of the
    // modal.
    status: null,
    errors: null,
  },
};

export const tdpAutoPlansReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    //
    // Fetching AssignedPlans
    //
    case fetchAssignedPlansStarted().type:
      return produce(state, (draft) => {
        draft.assignedPlans.meta.isLoading = true;
        draft.assignedPlans.meta.error = null;
      });

    case fetchAssignedPlansSuccess().type:
      return produce(state, (draft) => {
        draft.assignedPlans.plans = payload.data;
        draft.assignedPlans.meta.isLoading = false;
        draft.assignedPlans.meta.error = null;
      });

    case fetchAssignedPlansFailure().type:
      return produce(state, (draft) => {
        draft.assignedPlans.plans = [];
        draft.assignedPlans.meta.isLoading = false;
        draft.assignedPlans.meta.error = payload.data;
      });

    //
    // Previewing an AutoPlan
    //
    case setShowPreviewModalForAutoPlan().type:
      return produce(state, (draft) => {
        draft.planPreview.showModalForId = payload.data;
      });

    case autoPlanPreview().type:
      return produce(state, (draft) => {
        const { status, result } = payload.data;
        draft.planPreview.meta.status = status;

        switch (status) {
          case "loading":
            draft.planPreview.meta.error = null;
            break;

          case "succeeded":
            draft.planPreview.plan = result;
            break;

          case "failed":
            draft.planPreview.meta.error = result;
            break;

          default:
            break;
        }
      });

    //
    // Show/hide the AssignedPlan Details Modal for the given ID - no ID implies
    // we should hide the modal
    //
    case setShowDetailsModalForAssignedPlan().type:
      return produce(state, (draft) => {
        draft.planDetails.meta.showDetailsModalForId = payload.data;
      });

    //
    // Fetching data about a single AssignedPlan
    //
    case fetchAssignedPlanDetailsStarted().type:
      return produce(state, (draft) => {
        draft.planDetails.meta.isLoading = true;
        draft.planDetails.meta.error = null;
      });

    case fetchAssignedPlanDetailsSuccess().type:
      return produce(state, (draft) => {
        draft.planDetails.meta.isLoading = false;
        draft.planDetails.meta.error = null;

        draft.planDetails.plan = payload.data;
      });

    case fetchAssignedPlanDetailsFailure().type:
      return produce(state, (draft) => {
        draft.planDetails.meta.isLoading = false;
        draft.planDetails.meta.error = payload.data;

        draft.planDetails.plan = null;
      });

    //
    // Deletion of an action within an AssignedPlan
    //
    case confirmAssignedActionDeletion().type:
      return produce(state, (draft) => {
        draft.assignedActionDelete.showModalFor = payload.data;

        if (payload.data === null) {
          // When closing the modal, clear out any data from it.
          draft.assignedActionDelete.status = "idle";
          draft.assignedActionDelete.error = null;
        }
      });

    case assignedActionDeletion().type:
      return produce(state, (draft) => {
        const { status, result } = payload.data;
        draft.assignedActionDelete.status = status;

        switch (status) {
          case "loading":
            draft.assignedActionDelete.error = null;
            break;

          case "succeeded":
            // Close out of the confirmation modal.
            draft.assignedActionDelete.showModalFor = null;
            break;

          case "failed":
            draft.assignedActionDelete.error = result;
            break;

          default:
            break;
        }
      });

    //
    // Restarting an action or marking it as complete
    //
    case assignedActionRestartOrMarkAsComplete().type:
      return produce(state, (draft) => {
        const { status, result } = payload.data;
        draft.assignedActionRestartOrMarkAsComplete.status = status;

        switch (status) {
          case "loading":
            draft.assignedActionRestartOrMarkAsComplete.error = null;
            break;

          case "failed":
            draft.assignedActionRestartOrMarkAsComplete.error = result;
            break;

          default:
            break;
        }
      });

    //
    // Confirm deletion of an AssignedPlan
    //
    case confirmAssignedPlanDeletion().type:
      return produce(state, (draft) => {
        draft.assignedPlanDelete.showModalForId = payload.data;
      });

    //
    // State Update
    //
    case updateAssignedPlanStateStarted().type:
      return produce(state, (draft) => {
        const assignedPlanId = payload.data;
        draft.assignedPlans.meta.idsBeingUpdated.add(assignedPlanId);
      });

    case updateAssignedPlanStateSuccess().type:
      return produce(state, (draft) => {
        const updatedPlan = payload.data;
        const index = draft.assignedPlans.plans.findIndex((plan) => plan.id === updatedPlan.id);
        draft.assignedPlans.plans[index] = merge(draft.assignedPlans.plans[index], updatedPlan);
        draft.assignedPlans.meta.idsBeingUpdated.delete(updatedPlan.id);

        // If the state update was a deletion, close the "really delete this
        // auto plan?" confirmation modal.
        if (updatedPlan.state === "deleted") {
          draft.assignedPlanDelete.showModalForId = null;
        }
      });

    case updateAssignedPlanStateFailure().type:
      return produce(state, (draft) => {
        draft.assignedPlans.meta.error = payload.data.error;

        // Close the "really delete this auto plan?" confirmation modal if the
        // state update failed, so the user knows something went wrong.
        draft.assignedPlanDelete.showModalForId = null;

        // If a failure happens during a state update, we're better off clearing
        // out local state completely and offering a client refresh, otherwise
        // we risk inconsistent server vs. client state.
        draft.assignedPlans.meta.idsBeingUpdated = new Set();
        draft.assignedPlans.plans = [];
      });

    //
    // Fetching available Transaction AutoPlans
    //
    case fetchAvailableAutoPlansStarted().type:
      return produce(state, (draft) => {
        draft.availableAutoPlans.meta.isLoading = true;
        draft.availableAutoPlans.meta.error = null;
      });

    case fetchAvailableAutoPlansSuccess().type:
      return produce(state, (draft) => {
        draft.availableAutoPlans.plans = payload.data;
        draft.availableAutoPlans.meta.isLoading = false;
        draft.availableAutoPlans.meta.error = null;
      });

    case fetchAvailableAutoPlansFailure().type:
      return produce(state, (draft) => {
        draft.availableAutoPlans.plans = [];
        draft.availableAutoPlans.meta.isLoading = false;
        draft.availableAutoPlans.meta.error = payload.data;
      });

    //
    // Apply an AutoPlan
    //
    case applyAutoPlanStarted().type:
      return produce(state, (draft) => {
        draft.autoPlanApply.status = "applying";
        draft.autoPlanApply.errors = null;
      });

    case applyAutoPlanSuccess().type:
      return produce(state, (draft) => {
        draft.autoPlanApply.status = "success";
        draft.autoPlanApply.errors = null;
      });

    case applyAutoPlanFailure().type:
      return produce(state, (draft) => {
        draft.autoPlanApply.status = null;
        draft.autoPlanApply.errors = payload.data;
      });

    case setApplyAutoPlanModalVisible().type:
      return produce(state, (draft) => {
        const isVisible = payload.data;
        draft.autoPlanApply.showModal = isVisible;

        // Reset any internal state (e.g. currently selected option) when
        // closing the modal.
        if (!isVisible) {
          draft.autoPlanApply.status = null;
          draft.autoPlanApply.errors = null;
          draft.autoPlanApply.selectedPlanOption = null;
        }
      });

    case setApplyAutoPlanSelectedPlan().type:
      return produce(state, (draft) => {
        draft.autoPlanApply.selectedPlanOption = payload.data;
      });

    default:
      return state;
  }
};

export default tdpAutoPlansReducer;
