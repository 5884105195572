import React from "react";
import PropTypes from "prop-types";

const LocationResults = ({
  handleHover,
  handleSelect,
  locationType,
  searchResults,
  cursor,
  cursorCategory,
}) => (
  <div>
    <div className="result-label">{locationType === "CityState" ? "City" : "Zip"}</div>
    {searchResults[locationType].map((location, position) => {
      const positionClass = cursorCategory === locationType && cursor === position ? "highlight" : "";
      const prefix = locationType === "CityState" ? "city" : "zip";

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`${prefix}-${position}`}
          className={`result-item ${positionClass}`}
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          onMouseOver={() => handleHover(position, locationType)}
          onMouseDown={() => handleSelect(position, locationType)}
        >
          {location}
        </div>
      );
    })}
  </div>
);

LocationResults.propTypes = {
  handleHover: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  locationType: PropTypes.string.isRequired,
  searchResults: PropTypes.shape().isRequired,
  cursor: PropTypes.number.isRequired,
  cursorCategory: PropTypes.string.isRequired,
};

export default LocationResults;
