/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CheckCircleRegularV3 = (props) => (
  <Icon {...props} name="Check Circle Regular V3">
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 1.548c3.566 0 6.452 2.886 6.452 6.452S11.566 14.452 8 14.452 1.548 11.566 1.548 8 4.434 1.548 8 1.548zm4.523 4.203-.727-.733a.386.386 0 0 0-.547-.002l-4.56 4.523L4.76 7.595a.386.386 0 0 0-.547-.002l-.733.727a.386.386 0 0 0-.002.547l2.928 2.952a.386.386 0 0 0 .547.002l5.567-5.523a.386.386 0 0 0 .002-.547z" />
  </Icon>
);

export default CheckCircleRegularV3;
