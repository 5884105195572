import React, { useMemo, useState } from "react";
import { sortBy } from "lodash";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import Source from "./Source";
import { TBody, TH, THead, TR, Table } from "../../Reports/components/BusinessDashboard/Table/Table";
import Loading from "../../Goals/components/Loader";
import SourceModal from "./SourceModal";
import useSources from "../hooks/useSources";
import DeleteSourceModal from "./DeleteSourceModal";

const Sources = () => {
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const [sortKey, setSortKey] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showNewSourceModal, setShowNewSourceModal] = useState(false);
  const { actions, loading, sources } = useSources();
  const sortedSources = useMemo(() => {
    const sorted = sortBy(sources, [sortKey]);
    if (sortDirection === "desc") sorted.reverse();
    return sorted;
  }, [sources, sortKey, sortDirection]);

  const onSort = (key) => () => {
    setSortKey(key);
    if (sortKey === key) setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full">
      <div className="tw-flex tw-justify-between tw-items-center tw-p-[12px]">
        <h2 className="tw-text-28d tw-font-medium tw-m-0">Sources</h2>
        <Button size="medium" onClick={() => setShowNewSourceModal(true)}>
          + New Source
        </Button>
      </div>

      <div className="tw-flex-1 tw-relative tw-overflow-auto">
        {loading && <Loading />}
        <Table>
          <THead className="tw-bg-white tw-sticky tw-top-0 tw-z-2">
            <TR>
              <TH onSort={onSort("name")} sort={sortKey === "name" && sortDirection}>
                Source
              </TH>
              <TH onSort={onSort("appliedToCount")} sort={sortKey === "appliedToCount" && sortDirection}>
                Applied To
              </TH>
              <TH
                className="tw-text-center"
                onSort={onSort("hidden")}
                sort={sortKey === "hidden" && sortDirection}
              >
                <span>Hide/Show</span>
                <Tooltip
                  placement="top"
                  className="tw-ml-[8px]"
                  innerClassName="tw-max-w-[250px]"
                  trigger={<CircleInfoSolidV6 className="tw-text-theme-primary tw-align-bottom" size="l" />}
                  content="Toggle which sources appear in your account's source list"
                />
              </TH>
              <TH />
            </TR>
          </THead>
          <TBody>
            {sortedSources.map((source) => (
              <Source key={source.id} source={source} onToggle={actions.toggleSource} />
            ))}
          </TBody>
        </Table>
      </div>

      <SourceModal
        sources={sources}
        show={showNewSourceModal}
        onHide={() => setShowNewSourceModal(false)}
        onSubmit={(source) => actions.addSource(source).then(() => setShowNewSourceModal(false))}
      />

      <DeleteSourceModal
        source={sourceToDelete}
        onHide={() => setSourceToDelete(null)}
        onDelete={() => actions.deleteSource(sourceToDelete).then(() => setSourceToDelete(null))}
      />
    </div>
  );
};

export default Sources;
