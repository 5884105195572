import React, { Component } from 'react';

export default class AdsSellEvent extends Component {

  property(propertyObject) {
    const bedString = !propertyObject.bedrooms ? "" : propertyObject.bedrooms + " Bed"
    const bathString = !propertyObject.bathrooms ? "" : propertyObject.bathrooms + " Bath"
    const bedBathString = (bedString + " " + bathString).trim()

    return (
        <div key={propertyObject.id} className="property-wrapper" style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' + propertyObject.main_photo + ')'}}>
          <div className="inset-info">
            <span className="price">{'$' + propertyObject.value}</span>
            <span>{propertyObject['street_address']}</span>
            <span>{propertyObject['city']}, {propertyObject['state']} {propertyObject['zip']}</span>
            <span>{bedBathString}</span>
          </div>
        </div>
    )
  }

  render() {
    const {event} = this.props
    return (
        <div className="timeline-event property-image-event">
          <div className="row">
            <div className="col-xs-1">
              <i className="fa fa-repeat timeline-icon-circle"/>
            </div>
            <div className="col-xs-7">
              <span className="bold-detail">{event.timeline_fields.f_name} {event.timeline_fields.l_name} </span>
              filled out Seller Ad via Brivity Connect
              <div className="timeline-description-text">
                {event.timeline_fields.property && this.property(event.timeline_fields.property)}
                <div className="row details-row">
                  URL: {event.timeline_fields.url}
                </div>
                <div className="row details-row">
                  UTM Type: {event.timeline_fields.utm_type}
                </div>
                <div className="row details-row">
                  UTM Medium: {event.timeline_fields.utm_medium}
                </div>
                <div className="row details-row">
                  UTM Source: {event.timeline_fields.utm_source}
                </div>
                <div className="row details-row">
                  Tags: {event.timeline_fields.utm_campaign}
                </div>
              </div>
            </div>
            <div className="col-xs-1" />
            <div className="col-xs-3 time-ago">
              <span>{event.interacted_at_absolute}</span>
            </div>
          </div>
        </div>
    )
  }
}
