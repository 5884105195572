import { useEffect, useMemo, useState } from "react";
import { upperFirst } from "lodash";
import formatter from "../../../../shared/formatters";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";

const getProductionStats = (signal, opts) =>
  caseTransformingAxios.get("/api/v4/reporting/operator_dashboard", {
    params: { section: "monthly_avg_production_stats", ...opts },
    signal,
  });

const transformProductionStats = (data, stat) => {
  const title = stat === "gci" ? "GCI" : "Net Income";
  const key = upperFirst(stat);
  return {
    titleName: "Production Stats (Monthly Averages)",
    metrics: [
      {
        metricValue: formatter.currency(data[`activeAgents${key}`]),
        metricTitle: `${title} for Active Agents`,
      },
      {
        metricValue: formatter.currency(data[`totalAgents${key}`]),
        metricTitle: `${title} for Total Agents`,
      },
      {
        metricValue: formatter.currency(data[`fullTimeAgents${key}`]),
        metricTitle: `${title} for Full Time Agents`,
      },
      {
        metricValue: formatter.currency(data[`partTimeAgents${key}`]),
        metricTitle: `${title} for Part Time Agents`,
      },
    ],
  };
};

const useProductionStats = ({ year, stat }) => {
  const [rawData, setRawData] = useState(null);
  const [state, _setState] = useState({ data: null, error: null, loading: false });
  const setState = useMemo(
    () => (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  );

  useEffect(() => {
    if (!rawData) return;
    setState({ data: transformProductionStats(rawData, stat) });
  }, [rawData, stat]);

  useEffect(() => {
    setState({ loading: true });
    const abortController = new AbortController();
    getProductionStats(abortController.signal, { selected_year: year })
      .then((res) => setState({ data: setRawData(res.data), error: null, loading: false }))
      .catch(() => setState({ error: true, loading: false }));

    return () => abortController.abort();
  }, [year]);

  return {
    ...state,
    actions: {
      clearError: () => setState({ error: null }),
    },
  };
};

export default useProductionStats;
