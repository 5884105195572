/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Checkbox from "@shared/v2/Checkbox";

import Dropdown from "..";

export const CheckboxGroupHeading = ({ componentProps, data, children }) => {
  const { value: selectedOptions, onChange: updateSelectedOptions } = componentProps.selectProps;

  const groupOptions = useMemo(
    () => data.options.filter((groupOption) => !groupOption.isDisabled),
    [data.options],
  );

  const selectedGroupOptions = useMemo(
    () =>
      groupOptions.filter((groupOption) =>
        selectedOptions.some((selectedOption) => groupOption.value === selectedOption.value),
      ),
    [groupOptions, selectedOptions],
  );

  const checked = useMemo(
    () => selectedGroupOptions.length === groupOptions.length,
    [groupOptions, selectedGroupOptions],
  );

  const indeterminate = useMemo(
    () => selectedGroupOptions.length > 0 && selectedGroupOptions.length < groupOptions.length,
    [groupOptions, selectedGroupOptions],
  );

  const selectAll = useCallback(() => {
    updateSelectedOptions([
      ...selectedOptions,
      ...groupOptions.filter(
        (groupOption) =>
          !selectedOptions.some((selectedOption) => groupOption.value === selectedOption.value),
      ),
    ]);
  }, [groupOptions, selectedGroupOptions]);

  const selectNone = useCallback(() => {
    updateSelectedOptions(
      selectedOptions.filter(
        (selectedOption) => !groupOptions.some((groupOption) => selectedOption.value === groupOption.value),
      ),
    );
  }, [groupOptions, selectedGroupOptions]);

  const handleClick = useCallback(() => {
    if (indeterminate || !checked) {
      selectAll();
    } else {
      selectNone();
    }
  }, [checked, indeterminate, selectAll, selectNone]);

  return (
    <Dropdown.GroupHeading
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...componentProps}
      className="!tw-flex tw-gap-[8px] tw-items-center tw-group !tw-cursor-pointer"
      preserveStyle
      onClick={handleClick}
    >
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        disabled={componentProps.isDisabled}
        readOnly
      />
      {children}
    </Dropdown.GroupHeading>
  );
};

CheckboxGroupHeading.propTypes = {
  componentProps: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.node.isRequired,
};

export const CheckboxOption = ({ componentProps, children }) => (
  <Dropdown.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="!tw-flex tw-gap-[8px] tw-items-center tw-group"
    preserveStyle
  >
    <Checkbox checked={componentProps.isSelected} disabled={componentProps.isDisabled} readOnly />
    {children}
  </Dropdown.Option>
);

CheckboxOption.propTypes = {
  componentProps: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
};
