/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HandHoldingDollarSolidV6 = (props) => (
  <Icon {...props} name="Hand Holding Dollar Solid V6">
    <path d="M15.783 10.231a1.108 1.108 0 0 0-1.554-.235l-3.325 2.449h-3.35c-.243 0-.419-.201-.419-.444s.201-.444.419-.444h2.174c.419 0 .854-.302.927-.739a.89.89 0 0 0-.878-1.038H5.308c-.725 0-1.476.257-2.059.729l-1.292 1.048-1.538-.025c-.218 0-.419.228-.419.469v2.666c0 .245.201.445.419.445h9.614c.612 0 1.22-.2 1.714-.563l3.753-2.764a1.08 1.08 0 0 0 .283-1.553zM7.758 5.75c-.211-.031-.489-.131-.733-.219l-.136-.047c-.347-.147-.728.058-.85.381s.059.728.405.851l.129.046c.236.085.497.169.761.231v.341a.667.667 0 1 0 1.333 0v-.289c.702-.159 1.191-.607 1.281-1.27.241-1.39-1.08-1.768-1.789-1.971l-.147-.051c-.731-.207-.689-.297-.669-.411.032-.188.425-.279.895-.205.137.022.316.071.546.151a.668.668 0 0 0 .436-1.26 5.299 5.299 0 0 0-.551-.159v-.313a.667.667 0 0 0-1.333 0v.292c-.701.159-1.193.604-1.332 1.267-.214 1.374 1.128 1.769 1.633 1.919l.18.052c.879.252.865.33.843.462-.032.188-.423.28-.901.202z" />
  </Icon>
);

export default HandHoldingDollarSolidV6;
