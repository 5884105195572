import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "../../../shared/v2/useMediaQuery";
import { setIsSubmenuOpen, toggleMobileMenu } from "../../../reducers/layoutReducer";

const useMobileMenu = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector(({ layout }) => layout.isMobileMenuOpen);
  const isSubmenuOpen = useSelector(({ layout }) => layout.isSubmenuOpen);

  return {
    actions: {
      toggleMobileMenu: () => dispatch(toggleMobileMenu()),
      setIsSubmenuOpen: (value) => dispatch(setIsSubmenuOpen(value)),
    },
    data: { isMobile, isMobileMenuOpen, isSubmenuOpen },
  };
};

export default useMobileMenu;
