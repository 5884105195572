import React from "react";
import { connect } from "react-redux";
import { func, bool, string } from "prop-types";
import { closeModalAsThunk, openAddressModalAsThunk } from "../../../Header/actions/thunks";
import ImportDetailModal from "./ImportDetailModal";

const ImportModal = ({
  isImportModalOpen,
  closeModalAsThunk,
  status,
  streetAddress,
  setToggleModal,
  preStatus,
}) => {
  const closeModal = () => {
    closeModalAsThunk(preStatus);
  };

  const handleCloseModal = () => {
    closeModal();
    if (!streetAddress) {
      setToggleModal(true, status, preStatus);
    }
  };

  return (
    <ImportDetailModal
      isImportModalOpen={isImportModalOpen}
      closeModalAsThunk={handleCloseModal}
      status={status}
      closeCurrentModal={closeModal}
      preStatus={preStatus}
    />
  );
};

ImportModal.propTypes = {
  isImportModalOpen: bool,
  closeModalAsThunk: func,
  setToggleModal: func,
  status: string.isRequired,
  streetAddress: string,
  preStatus: string,
};

ImportModal.defaultProps = {
  isImportModalOpen: false,
  closeModalAsThunk: () => {},
  setToggleModal: () => {},
  streetAddress: null,
  preStatus: null,
};

const mapStateToProps = (state) => ({
  isImportModalOpen: state.tdpDetailsReducer.isImportModalOpen,
  preStatus: state.tdpDetailsReducer.preStatus,
  status: state.tdpDetailsReducer.transactionDetails.status,
  streetAddress: state.tdpDetailsReducer.propertyDetails?.street_address,
});

const mapDispatchToProps = (dispatch) => ({
  closeModalAsThunk: (preStatus) => dispatch(closeModalAsThunk(preStatus)),
  setToggleModal: (isOpen, updatedStatus, preStatus) =>
    dispatch(openAddressModalAsThunk(isOpen, updatedStatus, preStatus)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
