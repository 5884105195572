import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { Dropdown } from "../../v1/Dropdown";
import { TIMES } from "./utils/constants";

const shouldBlockTime = (blockingTime, _time) => TIMES.indexOf(_time) <= TIMES.indexOf(blockingTime);

const createTimeOptions = (blockingTime) => {
  const timeOptions = [];

  TIMES.forEach((_time) => {
    timeOptions.push({
      label: _time,
      value: _time,
      isDisabled: blockingTime !== "" && shouldBlockTime(blockingTime, _time),
    });
  });

  return timeOptions;
};

const TimePicker = ({ time, blockingTime, placeholder, handleTimeChange, className = "", ...otherProps }) => {
  const [_time, setTime] = useState(time);
  const timeOptions = useMemo(() => createTimeOptions(blockingTime), [time, blockingTime]);

  useEffect(() => {
    setTime(time);
  }, [time]);

  const onTimeChange = (option) => {
    handleTimeChange(option.value);
  };

  return (
    <div className={`tw-flex tw-flex-row tw-items-center ${className}`} {...otherProps}>
      <Dropdown
        options={timeOptions}
        variant="timepicker"
        placeholder={placeholder}
        className="tw-text-center"
        value={_time}
        onChange={onTimeChange}
      />
    </div>
  );
};

TimePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  time: PropTypes.string,
  blockingTime: PropTypes.string,
  handleTimeChange: PropTypes.func,
};

TimePicker.defaultProps = {
  className: "",
  placeholder: "",
  time: "",
  blockingTime: "",
  handleTimeChange: () => {},
};

export default TimePicker;
