/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@shared/v2/Tooltip";
import Avatar from "../../../shared/v2/Avatar";
import { XSolidV6 } from "../../../shared/v2/Icomoon";

const FirstToClaimLimit = 10;

const FirstToClaim = ({ agents, setAgents }) => {
  const removeAgent = (id) => {
    setAgents(agents.filter((agent) => agent.id !== id));
  };

  return agents?.length ? (
    <div className="tw-flex tw-flex-col tw-gap-24px">
      {agents.length > 0 &&
        agents.map((agent, i) => (
          <AgentRemove
            key={agent.id}
            agent={agent}
            removeAgent={removeAgent}
            isOverLimit={i + 1 > FirstToClaimLimit}
          />
        ))}
    </div>
  ) : (
    <span className="tw-text-gray-50">No users selected</span>
  );
};

FirstToClaim.propTypes = {
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setAgents: PropTypes.func.isRequired,
};

export default FirstToClaim;

const AgentRemove = ({ agent, removeAgent, isOverLimit }) => {
  const agentInfo = (
    <div
      className={`tw-flex tw-flex-row tw-items-center tw-whitespace-nowrap tw-overflow-x-hidden ${
        isOverLimit ? "tw-opacity-50" : ""
      }`}
    >
      <Avatar className="tw-mr-8px" size="medium" src={agent.avatarUrl} alt={agent.name} />
      <span className="tw-overflow-ellipsis tw-overflow-x-hidden tw-font-normal tw-mr-12px">
        {agent.name} - {agent.routingNumber}
      </span>
    </div>
  );

  return (
    <div className="tw-flex tw-flex-row tw-items-center">
      {isOverLimit ? (
        <Tooltip
          content="First to Claim supports up to 10 users."
          trigger={agentInfo}
          multiline
          placement="right"
        />
      ) : (
        agentInfo
      )}
      <div className="tw-ml-auto tw-cursor-pointer" onClick={() => removeAgent(agent.id)}>
        <XSolidV6 />
      </div>
    </div>
  );
};

AgentRemove.propTypes = {
  agent: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    routingNumber: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  removeAgent: PropTypes.func.isRequired,
  isOverLimit: PropTypes.bool.isRequired,
};
