/* eslint-disable no-lonely-if */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDebounce } from "../../shared/hookHelpers";
import * as creators from "../actions/creators";
import * as thunks from "../actions/thunks";
import { Spinner } from "../../shared/v2/Icomoon";
import NoResults from "./EmptyStates/NoResults";
import NoSearch from "./EmptyStates/NoSearch";
import ResultsTable from "./ResultsTable";

const Results = ({ searchTab, searchTerm, isSearching, results, changeIsSearching, callPartnersSearch }) => {
  const getEmptyMessage = () => {
    let Comp = (
      <div className="tw-text-center">
        <Spinner size="xxxl" className="fa-spin tw-mx-auto tw-mt-40" />
      </div>
    );

    if (!isSearching) {
      Comp = searchTerm === "" ? <NoSearch /> : <NoResults />;
    }
    return Comp;
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  useEffect(() => {
    const controller = new AbortController();
    if (debouncedSearchTerm > "") {
      const dt = { q: debouncedSearchTerm, placePartnersOnly: true, scope: searchTab };
      if (searchTab === "google_place") {
        dt.q = debouncedSearchTerm.value;
      }
      callPartnersSearch({
        controller,
        data: dt,
      });
    } else {
      changeIsSearching(false);
    }
    return () => {
      controller.abort();
    };
  }, [debouncedSearchTerm, searchTab]);

  return results?.length > 0 && !isSearching ? <ResultsTable /> : getEmptyMessage();
};

const mapStateToProps = (state) => ({
  searchTab: state.partnerSearch.searchTab,
  searchTerm: state.partnerSearch.searchTerm,
  results: state.partnerSearch.results,
  isSearching: state.partnerSearch.isSearching,
});

Results.propTypes = {
  searchTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeIsSearching: PropTypes.func.isRequired,
  callPartnersSearch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { ...creators, ...thunks })(Results);
