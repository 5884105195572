/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MagnetSolidV6 = (props) => (
  <Icon {...props} name="Magnet Solid V6">
    <path d="M5 5v3c0 1.656 1.316 3 3 3 1.656 0 3-1.344 3-3V5h4v3A7 7 0 1 1 1 8V5h4zM1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2H1V2zm10 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2h-4V2z" />
  </Icon>
);

export default MagnetSolidV6;
