import React from "react";
import { func, node } from "prop-types";

const EllipsisButton = ({ children, onClick }) => (
  <button
    type="button"
    className="tw-bg-white tw-border-none hover:tw-bg-gray-5 active:tw-bg-gray-10 tw-p-[12px] tw-text-start"
    onClick={onClick}
  >
    {children}
  </button>
);

EllipsisButton.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
};

const EllipsisDropdown = ({ setToggleAddClientModal, setToggleAddCollaboratorModal, setToggleAddModal }) => (
  <div className="tw-absolute tw-rounded-md tw-overflow-hidden tw-shadow-modal tw-right-0 tw-top-[24px] tw-flex tw-flex-col tw-w-[200px]">
    <EllipsisButton onClick={() => setToggleAddClientModal(true)}>Client</EllipsisButton>
    <EllipsisButton onClick={() => setToggleAddCollaboratorModal(true)}>Collaborator</EllipsisButton>
    <EllipsisButton onClick={() => setToggleAddModal(true)}>Team Member</EllipsisButton>
  </div>
);

EllipsisDropdown.propTypes = {
  setToggleAddClientModal: func.isRequired,
  setToggleAddCollaboratorModal: func.isRequired,
  setToggleAddModal: func.isRequired,
};

export default EllipsisDropdown;
