/* eslint-disable no-param-reassign */
import { useEffect } from "react";
import { produce } from "immer";

export default (payloadHandlrs, payload) => {
  useEffect(() => {
    if (payload.recurring?.frequency?.label === "Every Day") {
      payloadHandlrs((prev) =>
        produce(prev, (draft) => {
          draft.recurring.recurring_every_so_many_days = 1;
        }),
      );
    }

    if (payload.recurring?.frequency?.value === "weekly") {
      payloadHandlrs((prev) =>
        produce(prev, (draft) => {
          draft.recurring.recurring_every_so_many_days = null;
          draft.recurring.recurring_day_of_week = payload?.recurring?.recurring_day_of_week || {
            label: "Mondays",
            value: "1",
          };
        }),
      );
    }

    if (payload.recurring?.frequency?.value === "number") {
      payloadHandlrs((prev) =>
        produce(prev, (draft) => {
          draft.recurring.recurring_every_so_many_days = draft.recurring.recurring_every_so_many_days || 1;
          draft.recurring.recurring_day_of_week = null;
        }),
      );
    }
  }, [payload.recurring?.frequency?.label]);
};
