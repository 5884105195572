import React from "react";
import transaction_css from "../../PersonDetail/components/transactions.module.css";

const EmailAutocompleteResults = (props) => {
  const handleHover = (newChildPosition) => {
    props.setChildPosition(newChildPosition);
  };

  return (
    <div>
      {props.searchResults.length > 0 && (
        <div className={transaction_css.searchResults}>
          <div className={transaction_css.resultChildren}>
            {props.searchResults.map((result, index) => (
              <div
                key={result.personId}
                className={
                  props.childPosition === index
                    ? `${transaction_css.resultItem} ${transaction_css.highlight}`
                    : transaction_css.resultItem
                }
                onMouseOver={() => handleHover(index)}
                onMouseDown={() => props.selectResult(result)}
              >
                <i className="tw-text-gray-base tw-pr-8px fa fa-user"></i>
                {result.fullName} {result.email}
                <span className="tw-text-gray-base">
                  {" "}
                  ({result.status} {result.type})
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailAutocompleteResults;
