import axios from "axios";

export const saveListingThumbnail = ({ listingId, id, model }) => {
  const params = new URLSearchParams();
  Object.keys(model).forEach((k) => {
    params.append(k, model[k]);
  });
  params.append("authenticity_token", ReactOnRails.authenticityToken());
  return axios.put(`/listings/${listingId}/listing_images/${id}`, params);
};

export const saveListingMLSThumbnail = ({ uuid, data }) => {
  return axios.put(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    listing: data,
  });
};
