import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: [
      "tw-m-0",
      "tw-p-0",
      "tw-border-0",
      "tw-bg-transparent",
      "tw-inline-flex",
      "tw-flex-col",
      "tw-flex-nowrap",
      "tw-justify-between",
      "tw-group",
      "tw-cursor-pointer",
    ],
    hover: ["hover:tw-text-theme-tab-label-hover"],
    disabled: ["disabled:tw-text-neutral-gray-15", "disabled:tw-cursor-not-allowed"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
