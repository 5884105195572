import axios from "axios";

export const getTeamFromApi = () =>
  axios.get("/account_active_agents", {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const getAllTasksFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/tasks/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const createTaskFromApi = (payload) =>
  axios.post(`/api/v4/transactions/tasks`, {
    ...payload,
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const deleteTasksFromApi = (id, uuid) =>
  axios.delete(`/api/v4/transactions/tasks/${uuid}`, {
    params: {
      id,
      authenticity_token: window.ReactOnRails.authenticityToken(),
    },
  });

export const editTaskFromApi = (payload, uuid) =>
  axios.put(`/api/v4/transactions/tasks/${uuid}`, {
    task: { ...payload },
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const bulkEditTasksFromApi = (payload) => axios.put(`/api/v4/transactions/tasks/bulk_update`, payload);
