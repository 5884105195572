import { useEffect, useState } from "react";

export default (payloadHandlrs, visibility) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(visibility);
  }, []);

  const checkHandlr = () => {
    setChecked((prev) => {
      payloadHandlrs((payloadPrev) => ({
        ...payloadPrev,
        visibility: !prev,
      }));

      return !prev;
    });
  };

  return [checked, checkHandlr];
};
