import React from "react";
import PropTypes from "prop-types";
import useAgentSummary from "./services/useAgentSummary";
import Avatar from "../../../shared/v2/Avatar";
import { Section } from "../BusinessDashboard";
import Loader from "../../../Dashboard/components/Loader";
import Error from "../DashboardShared/Error";
import DashTooltip from "../DashboardShared/DashTooltip";
import ProgressBar from "../DashboardShared/ProgressBar";
import Tooltip from "../../../shared/v2/Tooltip/Tooltip";
import TooltipDotRow from "../DashboardShared/TooltipDotRow";

const AgentStat = ({ bold, name, tooltip, value }) => (
  <div className="tw-flex tw-flex-wrap tw-items-center tw-text-14d tw-whitespace-nowrap">
    <div className="tw-flex tw-items-center">
      <div className="tw-basis-[180px] tw-flex-1 tw-font-semibold tw-text-neutral-gray-50">{name}</div>
      {tooltip && <DashTooltip text={tooltip} />}
    </div>
    <div
      className={`tw-basis-[85px] tw-text-right tw-flex-1 tw-ml-[24px] ${bold && "tw-text-18d tw-font-bold"}`}
    >
      {value}
    </div>
  </div>
);

AgentStat.propTypes = {
  bold: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AgentStat.defaultProps = {
  bold: false,
  tooltip: "",
  value: undefined,
};

const AgentSummary = ({ agentUuid, year }) => {
  const {
    data: { agent, summary, progressBar },
    error,
    loading,
  } = useAgentSummary({ agentUuid, year });

  return (
    <Section className="tw-flex tw-flex-col tw-gap-[32px]">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-[16px]">
        <Avatar className="tw-w-[80px] tw-h-[80px]" alt={agent.fullName} size="none" src={agent.avatar} />
        <div className="tw-text-18d tw-font-bold tw-m-0">{agent.fullName}</div>
        <div className="tw-text-14d tw-font-semibold tw-text-neutral-gray-50 tw-m-0">
          {agent.admin ? "Admin" : "Agent"}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-grow tw-gap-[24px]">
        <AgentStat
          bold
          name={`${year} Net Income`}
          value={summary.netIncome}
          tooltip="Agent commission income after expenses from the Transaction Financials tab have been deducted"
        />
        <AgentStat
          name="Pending Net Income"
          value={summary.pendingIncome}
          tooltip="Sum of agent's net income for currently pending units"
        />
        <AgentStat
          name="Est Next 30 Days"
          value={summary.netIncomeForTheNext30Days}
          tooltip="Sum of agent's net income of current pending units with an est. close day within next 30 days"
        />
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <AgentStat name={`${year} Net Income Goal`} value={summary.netIncomeGoal} />
          <Tooltip
            trigger={<ProgressBar label={progressBar.label} values={progressBar.values} />}
            content={
              <div className="tw-flex tw-flex-col tw-gap-[8px] tw-w-[200px]">
                <h3 className="tw-text-left tw-text-14d tw-font-bold tw-my-0">{year} Net Income</h3>
                <TooltipDotRow color="primary" title={`${year} YTD`} value={summary.netIncome} />
                <TooltipDotRow color="secondary" title="YTD Goal" value={summary.ytdNetIncomeGoal} />
                <TooltipDotRow color="gray" title={`${year} Goal`} value={summary.netIncomeGoal} />
              </div>
            }
          />
        </div>
        <hr className="tw-m-0 tw-w-full tw-border-t tw-border-solid tw-border-neutral-gray-5" />
        <AgentStat
          name="Listing to Close Price Ratio (YTD)"
          value={`${summary.listedToClosedPriceRatioYtd}%`}
        />
        <AgentStat name="Pending to Closed Ratio (YTD)" value={`${summary.pendingsToCloseRatioYtd}%`} />
        <hr className="tw-m-0 tw-w-full tw-border-t tw-border-solid tw-border-neutral-gray-5" />
        <AgentStat name="Avg Close Price (YTD)" value={summary.avgClosedPrice} />
        {/* <hr className="tw-m-0 tw-w-full tw-border-t tw-border-solid tw-border-neutral-gray-5" />
        <AgentStat name="Cap Total Paid" value="$13,927.46" /> */}
      </div>
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </Section>
  );
};

AgentSummary.propTypes = {
  agentUuid: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AgentSummary;
