import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

const EmailInput = (props) => {
  const { onChange, validationState, value } = props;

  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} htmlFor="email" md={2}>Email</Col>

      <Col md={10}>
        <FormControl
          type="email"
          name="email"
          value={value}
          onChange={onChange}
        />
      </Col>
    </FormGroup>
  )
}

export default EmailInput;
