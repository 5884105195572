import { schemaClasses as schemaClassesFromRegularButton } from "../Button/Button.styles";

import { schemaGenerator } from "../utils";

const tertiary = {
  ...schemaClassesFromRegularButton.tertiary,
  disabled: [
    "disabled:tw-bg-neutral-gray-5",
    "disabled:tw-border-neutral-gray-5",
    "disabled:tw-text-neutral-gray-30",
  ],
};

// A _lot_ of the styles seen in the IconButton are exactly the same as ones
// seen in the regular Button component, and in the past they've changed
// together pretty often. As such, we opted to use the Button's styles as a base
// here and just override whatever is different between them.
const schemaClasses = {
  ...schemaClassesFromRegularButton,
  tertiary,
  transparent: {
    default: ["tw-text-neutral-gray-75", "tw-bg-black", "tw-border-transparent", "tw-bg-opacity-0"],
    hover: ["enabled:hover:tw-bg-opacity-5"],
    active: ["enabled:active:tw-bg-opacity-30"],
    disabled: ["disabled:tw-text-neutral-gray-30"],
  },
  "dark-tertiary": {
    default: ["tw-text-neutral-gray-30", "tw-bg-neutral-gray-100", "tw-border-transparent"],
    hover: ["hover:tw-text-neutral-gray-15", "enabled:hover:tw-bg-neutral-gray-75"],
    active: ["active:tw-text-neutral-gray-100", "enabled:active:tw-bg-neutral-gray-30"],
    disabled: ["disabled:tw-text-neutral-gray-75", "disabled:tw-bg-neutral-gray-100"],
  },
  "starred-yellow": {
    default: ["tw-text-white", "tw-bg-yellow-50", "tw-border-yellow-50"],
    hover: ["hover:tw-bg-yellow-100", "hover:tw-border-yellow-100"],
    active: ["active:tw-bg-yellow-50", "active:tw-border-yellow-50"],
    disabled: ["disabled:tw-bg-neutral-gray-30", "disabled:tw-border-neutral-gray-30"],
  },
  "misc-trash": {
    default: ["tw-text-neutral-gray-75", "tw-bg-neutral-gray-10", "tw-border-neutral-gray-10"],
    hover: ["hover:tw-bg-semantic-red-5", "hover:tw-border-semantic-red-5", "hover:tw-text-semantic-red-100"],
    active: ["active:tw-bg-semantic-red-100", "active:tw-border-semantic-red-100", "active:tw-text-white"],
    disabled: [
      "disabled:tw-bg-neutral-gray-5",
      "disabled:tw-border-neutral-gray-5",
      "disabled:tw-text-neutral-gray-30",
    ],
  },
};

const schemaDictionary = schemaGenerator(schemaClasses);
export const schemaBasedClasses = (schemaName) => schemaDictionary[schemaName];
export const validSchemas = Object.keys(schemaDictionary);

const dimensionsFor = {
  medium: "tw-w-[32px] tw-h-[32px]",
  small: "tw-w-[24px] tw-h-[24px]",
  xsmall: "tw-w-[16px] tw-h-[16px]",
};

const fontSizeFor = {
  medium: "tw-text-14d",
  small: "tw-text-11d",
};

export const sizeBasedClasses = (size) => `${fontSizeFor[size]} ${dimensionsFor[size]}`;

export { border as borderFor } from "../Button/Button.styles";
