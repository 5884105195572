import React from "react";

import { arrayOf, func, shape, string } from "prop-types";

import { ACCOUNT_SOURCES, LISTING_UUID, SOURCE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { putDetailAsThunk } from "../actions/thunks";

import DetailDropdown from "../DetailDropdown";

const sourceToOption = (source) => (source ? { label: source.name, value: source.id } : null);

const DetailSourceDropdown = ({ accountSources, source, onPutDetailAsThunk, uuid, containerClass }) => {
  const saveValueFor =
    (key, stateKey = null) =>
    (value) => {
      onPutDetailAsThunk({
        uuid,
        key,
        value,
        group: "listingDetails",
        stateKey,
      });
    };

  const saveOptionFor = (key, stateKey) => (optionValue) => saveValueFor(key, stateKey)(optionValue?.value);

  return (
    <DetailDropdown
      label="Source"
      val={sourceToOption(source)}
      options={accountSources}
      onBlur={saveOptionFor("source_id", "source")}
      dataCy="source"
      containerClass={containerClass}
    />
  );
};

const withReduxStates = tdpDetails({
  WrappedComponent: DetailSourceDropdown,
  states: [ACCOUNT_SOURCES, LISTING_UUID, SOURCE],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putDetailAsThunk,
  aliases: "onPutDetailAsThunk",
});

DetailSourceDropdown.propTypes = {
  accountSources: arrayOf(shape({})),
  source: shape({}),
  onPutDetailAsThunk: func,
  uuid: string,
  containerClass: string,
};

DetailSourceDropdown.defaultProps = {
  accountSources: [],
  source: null,
  onPutDetailAsThunk: () => {},
  uuid: null,
  containerClass: "",
};
