/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Close = (props) => (
  <Icon {...props} name="Close">
    <path d="m10.854 8 4.281-4.281a1.347 1.347 0 0 0 0-1.903l-.951-.951a1.347 1.347 0 0 0-1.903 0L8 5.146 3.719.865a1.347 1.347 0 0 0-1.903 0l-.951.951a1.347 1.347 0 0 0 0 1.903L5.146 8 .865 12.281a1.347 1.347 0 0 0 0 1.903l.951.951a1.347 1.347 0 0 0 1.903 0L8 10.854l4.281 4.281a1.347 1.347 0 0 0 1.903 0l.951-.951a1.347 1.347 0 0 0 0-1.903L10.854 8z" />
  </Icon>
);

export default Close;
