import React from "react";
import { func } from "prop-types";

import withDispatch from "../../../../shared/HOCs/withDispatch";
import { referralTimeFrameEditOn } from "../../actions/creators";

import AddValueTxtBtn from "../../shared/AddValueTxtBtn";

const TimeFrameAdd = ({ onClick }) => <AddValueTxtBtn btnClassName="tw-ml-10px" onClick={onClick} />;

TimeFrameAdd.propTypes = {
  onClick: func,
};

TimeFrameAdd.defaultProps = {
  onClick: null,
};

export default withDispatch({
  WrappedComponent: TimeFrameAdd,
  actions: referralTimeFrameEditOn,
  aliases: "onClick",
});
