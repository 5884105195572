import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import AgentAdditionalIncomeSortable from "../AgentAdditionalIncomeSortable";
import TransactionInput from "../../shared/TransactionInput";
import { connect } from "react-redux";
import { setFinancial } from "../../../actions/transaction";
import { fullRecalculation } from "../../shared/FinancialViewHelpers";
import { Dropdown } from "../../../../shared/v1";
import format from "../../../../shared/formatters";

const mapDispatchToProps = (dispatch) => ({
  setFinancial: (listingData) => dispatch(setFinancial(listingData)),
});

export const AgentDetailForm = connect(
  null,
  mapDispatchToProps,
)((props) => {
  const {
    listingData,
    onRemoveIncome,
    setFinancialData,
    onAddExpense,
    onSortAdditionalIncome,
    recalculate_team_member_net_income,
    unitOptions,
    income,
    setFinancial,
    idx,
  } = props;

  return (
    <div className="edit-agent-wrap">
      <h2>
        {income.name ? income.name : ""}
        {income.owner && <span> (Account Owner)</span>}
        {!income.owner && (
          <span className="fa fa-times remove-income" onClick={() => onRemoveIncome(income.id)} />
        )}
      </h2>
      <div className="transaction-gci-wrap clearfix">
        <div className="transaction-gci-options">
          <div className="transaction-gci-header clearfix">
            <h3>Income</h3>
          </div>
          <div className="transaction-gci-content">
            <Row>
              <Col xs={1} className="drag-handler" />

              <Col xs={2}>
                <TransactionInput
                  label="Agent GCI"
                  value={income.agent_gci}
                  isPercentage={!!income.agent_gci_percentage}
                  name={`agentGciPercentage${idx}`}
                  onValueUpdate={(e) => {
                    if (!!income.agent_gci_percentage) {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        income.agent_gci = e.target.value;
                        fullRecalculation(listingData, setFinancial);
                      }
                    } else {
                      income.agent_gci = e.target.value;
                      fullRecalculation(listingData, setFinancial);
                    }
                  }}
                  onRadioUpdate={(e) => {
                    if (e.target.value === "true" && income.agent_gci > 100) {
                      income.agent_gci = 0;
                    }
                    income.agent_gci_percentage = e.target.value === "true";
                    fullRecalculation(listingData, setFinancial);
                  }}
                />
              </Col>

              <Col xs={4}>
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    className="description form-control"
                    name={`agentGciNote${idx}`}
                    placeholder=""
                    onChange={(e) => {
                      income.agent_gci_notes = e.target.value;
                      setFinancialData(listingData);
                    }}
                  >
                    {income.agent_gci_notes}
                  </textarea>
                </div>
              </Col>

              <Col xs={1}>
                <div className="form-group">
                  <label>Units</label>
                  <div className="tw-w-[75px]">
                    <Dropdown.Creatable
                      name={`unitDrp${idx}`}
                      onChange={(e) => {
                        if (Number(e.value) >= 0 && Number(e.value) <= 1) {
                          income.agent_gci_units = e.value;
                          setFinancialData(listingData);
                        }
                      }}
                      value={income.agent_gci_units}
                      options={unitOptions}
                      // With a Dropdown.Creatable, by default if the user types
                      // in 0.25 for example, the label will be `Create "0.25"`.
                      // We want `0.25` to show up as-is though, so we override
                      // `formatCreateLabel` to return the raw label like we want.
                      formatCreateLabel={(label) => label}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={2} />

              <Col xs={2} className="text-right">
                <span>{format.currency(income.gross_income)}</span>
              </Col>
            </Row>
          </div>
        </div>

        <div className="transaction-gci-options">
          <div className="transaction-gci-header clearfix">
            <h3>Expenses</h3>
            <span className="add pull-right">
              <a href="javascript:void(0)" onClick={() => onAddExpense(idx)}>
                Add
              </a>
            </span>
          </div>
        </div>

        <div className="transaction-gci-options">
          <AgentAdditionalIncomeSortable
            income={income}
            onSortEnd={(indexes) => onSortAdditionalIncome(indexes, idx)}
            listingData={listingData}
            setFinancialData={setFinancialData}
            parentIndex={idx}
            recalculate_team_member_net_income={recalculate_team_member_net_income}
            useDragHandle
          />

          <div className="transaction-gci-footer clearfix">
            <div className="pull-left definition">
              <h4>Net Income</h4>
            </div>
            <div className="pull-right total-amount">
              <h4>{format.currency(income.net_income)}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
