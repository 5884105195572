import { schemaGenerator } from "../utils";

// The RadioButton component is implemented using an `input[type=radio]` element and has custom
// styles (following the design system) using the appearance none technique. Although it works, CSS
// selectors take precedence over Tailwind classes that affect `input[type=radio]`s globally. So
// to override it, we must decorate some of the Tailwind classes here with the important flag (!).
// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#styling_radio_inputs
// and https://developer.mozilla.org/en-US/docs/Web/CSS/appearance

const dictionary = {
  regular: {
    default: [
      "tw-peer",
      "tw-appearance-none",
      "tw-w-[16px]",
      "tw-h-[16px]",
      "!tw-m-0",
      "tw-rounded-full",
      "tw-border-solid",
      "tw-border",
      "tw-border-neutral-gray-30",
      "tw-bg-white",
      "tw-flex-shrink-0",
      "tw-cursor-pointer",
    ],
    hover: ["hover:tw-bg-neutral-gray-5"],
    focus: ["focus:!tw-outline-0"],
    focusVisible: ["focus-visible:!tw-outline-theme"],
    disabled: [
      "disabled:tw-border",
      "disabled:tw-border-neutral-gray-15",
      "disabled:tw-bg-neutral-gray-5",
      "disabled:tw-cursor-not-allowed",
    ],
    checked: [
      "checked:tw-border-[2px]",
      "checked:tw-border-theme-radio-checked",
      "checked:tw-bg-theme-radio-checked",
      "checked:tw-shadow-radio",
    ],
    checkedHover: ["checked:hover:tw-bg-theme-radio-checked"],
    checkedDisabled: [
      "checked:disabled:tw-border-[2px]",
      "checked:disabled:tw-border-neutral-gray-30",
      "checked:disabled:tw-bg-neutral-gray-30",
      "checked:disabled:tw-shadow-radio",
    ],
    checkedDisabledHover: ["checked:disabled:hover:tw-bg-neutral-gray-30"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
