import React from "react";
import Bars from "./Bars";
import TrackLabel from "./TrackLabel";

const TrackProgressBar = () => (
  <>
    <Bars />
    <TrackLabel />
  </>
);

export default TrackProgressBar;
