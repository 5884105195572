import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import FieldLabel from "../FieldLabel";
import FieldError from "../FieldError";
import FieldHelper from "../FieldHelper";

import { range } from "../../Utilities";
import { useGivenOrGeneratedId } from "../../hookHelpers";

import styleSchema, { spacingValue } from "./TextComboInput.styles";
import inputStyleSchema, { inputSizeValue } from "./Input.styles";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

const TextComboInput = ({
  id: idFromProps,
  leading,
  trailing,
  disabled,
  error,
  inputRef,
  onFieldClick,
  onFocusIn,
  onFocusOut,
  spacing,
  inputSize,
  className,
  labelClassName,
  containerClassName,
  isRequired,
  label,
  helperText,
  toolTipInfoContent,
  toolTipPlacement,
  ...otherProps
}) => {
  const fieldsetRef = useRef();
  const inputElementRef = inputRef || useRef();
  const fieldLabelRef = useRef(null);

  const [enableHoverOnInput, setEnableHoverOnInput] = useState(false);

  const id = useGivenOrGeneratedId("text-combo-input", idFromProps);

  const classes = `
    ${schemaBasedClasses(error ? "error" : "regular")}
    ${className}
  `;

  const handleOnMouseDown = (e) => {
    if (!fieldsetRef.current || !inputElementRef.current) {
      return;
    }

    // Allow the input element handle the mousedown event
    if (inputElementRef.current === e.target) {
      return;
    }

    // Prevent this mouse down event to continue since the current target is the fieldset element
    if (fieldsetRef.current === e.currentTarget) {
      e.preventDefault();
    }
  };

  const handleOnBlur = (e) => {
    const { currentTarget } = e;

    // Give browser time to focus the next element
    window.requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        onFocusOut();
      }
    });
  };

  // In this specific case, the focused border style needs to be applied on the fieldset element instead of the input,
  // and as such, hovering over the label doesn't automatically apply the hover style on it as it does on the other components,
  // so we need this workaround for consistency
  useEffect(() => {
    const fieldLabelEl = fieldLabelRef.current;

    if (!fieldLabelEl) return;

    fieldLabelEl.addEventListener("mouseover", () => {
      setEnableHoverOnInput(true);
    });

    fieldLabelEl.addEventListener("mouseout", () => {
      setEnableHoverOnInput(false);
    });
  }, [fieldLabelRef]);

  return (
    <div
      className={`tw-flex tw-flex-col tw-max-w-full ${containerClassName}`}
      data-cy="text-combo-input-container"
    >
      {/* Using fieldset to leverage the disabled attribute feature */}
      {label && (
        <FieldLabel
          ref={fieldLabelRef}
          className={`tw-mb-8px tw-w-fit ${labelClassName}`}
          htmlFor={id}
          label={label}
          isRequired={isRequired}
          isDisabled={disabled}
          toolTipInfoContent={toolTipInfoContent}
          toolTipPlacement={toolTipPlacement}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <fieldset
        ref={fieldsetRef}
        // We need to use important here to overwrite the default border color
        className={`${classes} ${enableHoverOnInput ? "!tw-border-theme-text-input-hover" : ""}`}
        disabled={disabled}
        onClick={onFieldClick}
        onMouseDown={handleOnMouseDown}
        role="presentation"
        {...(onFocusIn ? { onFocus: onFocusIn } : {})}
        {...(onFocusOut ? { onBlur: handleOnBlur } : {})}
      >
        <div
          className={`
            tw-flex tw-flex-row
            tw-h-[34px] tw-overflow-hidden
            ${spacingValue(spacing)}
          `}
        >
          {leading && <span className="tw-flex-none tw-self-center">{leading}</span>}
          <input
            id={id}
            ref={inputElementRef}
            type="text"
            className={`
              tw-self-center
              ${inputSizeValue(inputSize)}
              ${inputStyleSchema.regular}
            `}
            data-cy="text-combo-input"
            {...otherProps}
          />
          {trailing && <span className="tw-flex-none tw-self-center">{trailing}</span>}
        </div>
      </fieldset>
      {helperText && <FieldHelper className="tw-mt-4px" helperText={helperText} isDisabled={disabled} />}
      {error && <FieldError className="tw-mt-4px" error={error} />}
    </div>
  );
};

TextComboInput.defaultProps = {
  id: null,
  leading: null,
  trailing: null,
  disabled: false,
  error: null,
  inputRef: null,
  onFieldClick: null,
  onFocusIn: null,
  onFocusOut: null,
  spacing: 14,
  inputSize: "full",
  className: "",
  containerClassName: "",
  isRequired: false,
  label: null,
  helperText: null,
  toolTipInfoContent: null,
  labelClassName: "",
  toolTipPlacement: null,
};

TextComboInput.propTypes = {
  id: PropTypes.string,
  leading: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  trailing: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onFieldClick: PropTypes.func,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  // See https://github.com/facebook/prop-types/issues/240#issue-384666636
  inputRef: PropTypes.oneOfType([
    PropTypes.func, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  spacing: PropTypes.oneOf(range(0, 14)),
  inputSize: PropTypes.oneOf(["min", "full"]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  toolTipInfoContent: PropTypes.node,
  labelClassName: PropTypes.string,
  toolTipPlacement: PropTypes.string,
};

export default TextComboInput;
