import { useEffect, useState } from "react";
import { differenceInDays } from "date-fns";
import axios from "axios";
import { getRanksByDate, getRollupsByDate, sortAndRankUsers } from "../helpers";

export default ({ userId, activity, from, to }) => {
  const [rawUsers, setRawUsers] = useState([]);
  const [goalDivisor, setGoalDivisor] = useState(52);
  const [largeQuery, setLargeQuery] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [ranks, setRanks] = useState({});
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);

  useEffect(() => {
    const maxCount = rawUsers.reduce((prevGoal, user) => {
      const annualGoal = user[activity]?.count;
      return annualGoal > prevGoal ? annualGoal : prevGoal;
    }, 0);

    setMaxCount(maxCount);
    setUsers(sortAndRankUsers(rawUsers, activity));
  }, [rawUsers, activity]);

  useEffect(() => {
    const abortController = new AbortController();
    const daysInTimeFrame = differenceInDays(Date.parse(to), Date.parse(from));
    setGoalDivisor(365 / (daysInTimeFrame || 1));
    setLargeQuery(daysInTimeFrame > 365);
    setLoading(true);

    getRollupsByDate({ from, to, signal: abortController.signal })
      .then((rawUsers) => {
        setRawUsers(rawUsers);
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [from, to]);

  useEffect(() => {
    const abortController = new AbortController();
    setRankLoading(true);
    getRanksByDate({ activity, from, to, userId, signal: abortController.signal })
      .then((rankData) => {
        const teamRank = rawUsers.find((u) => u.user_id === userId)?.[activity]?.rank;
        setRanks({ ...rankData, teamRank });
        setError(false);
        setRankLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(true);
          setRankLoading(false);
        }
      });
    return () => {
      abortController.abort();
    };
  }, [rawUsers, userId, activity]);

  return { data: { goalDivisor, largeQuery, maxCount, ranks, users }, error, loading, rankLoading };
};
