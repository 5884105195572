/* eslint-disable react/prop-types */

import React from "react";
import ImageCell from "./shared/ImageCell";
import AgentCell from "./shared/AgentCell";
import TextCell from "./shared/TextCell";
import TypeCell from "./shared/TypeCell";
import CurrencyCell from "./shared/CurrencyCell";
import DateCell from "./shared/DateCell";
// TODO: Revert when pipeline feature flag is merged
// eslint-disable-next-line import/no-cycle
import TDIOptionsStatusCell from "./TDIOptionsStatusCell";
import OptionsInlineCell from "./shared/OptionsInlineCell";
import FadeOut from "./shared/FadeOut";
import { EditIcon, ArchiveIcon, TrashIcon } from "./icons";
import { Listing } from "../models";
import { dynamicRow } from "../utils/dynamicRow";
// eslint-disable-next-line import/no-cycle
import { currentFiltersAsString } from "../utils/urlHelpers";
import { isEditableAt } from "../utils/helpers";
import ThumbnailIcon from "./shared/ThumbnailIcon";
import { isReferral } from "../../TransactionDetailsPage/shared/helpers";

const widths = {
  minWidth: "150px",
  maxWidth: "300px",
};
export const STATUSES = [
  "pipeline",
  "comingsoon",
  "active",
  "pending",
  "sold",
  "expired",
  "withdrawn",
  "canceled",
  "archived",
];

export const isCustomTitle = (type, useCustomTitle) => isReferral(type) || useCustomTitle;

const Transaction = ({
  listing,
  sources,
  updateListing,
  archiveListing,
  deleteListing,
  renderableColumns,
  permissions,
}) => {
  const onChange = (key) => (value) => updateListing(new Listing({ ...listing, [key]: value }));
  const canDeleteListing = permissions.listings.delete && listing.status === "archived";
  const canArchiveListing = !listing.status === "archived";
  const canEditFinancials = false;
  const editableCheck = (property) => isEditableAt(listing, property, canEditFinancials);
  const searchString = currentFiltersAsString();
  const baseColumns = dynamicRow(renderableColumns, [
    [
      "photo",
      <td className="tw-whitespace-nowrap tw-relative" data-cy="transaction-item-photo">
        <div className="tw-flex tw-w-[80px] tw-justify-center tw-items-center">
          {listing.photoURL ? (
            <ImageCell value={listing.photoURL} isEditable={editableCheck("photo")} ratio="wide" />
          ) : (
            <ThumbnailIcon transactionType={listing.type} size="xl" />
          )}
        </div>
      </td>,
    ],
    [
      "address",
      <td
        className="tw-relative tw-border tw-border-t-0 tw-border-b-0 tw-border-l-0 tw-border-r-1px tw-border-gray-light tw-border-solid tw-px-28px tw-text-left tw-overflow-hidden"
        style={widths}
        data-cy="transaction-item-addressTitle"
      >
        <a
          className="tw-no-underline tw-block tw-max-h-38px tw-overflow-hidden"
          href={`${listing.showURL}?${searchString}`}
        >
          {isCustomTitle(listing.type, listing.useCustomTitle) ? (
            <p className="tw-text-gray-75 tw-m-0 tw-truncate tw-text-ellipsis">{listing.title}</p>
          ) : (
            listing.address.fullAddress
              .filter((line) => !!line)
              .map((line) => <p className="tw-text-gray-75 tw-m-0 tw-truncate tw-text-ellipsis">{line}</p>)
          )}
        </a>
        <FadeOut />
      </td>,
    ],
    [
      "type",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td className="tw-whitespace-nowrap tw-relative tw-px-28px" data-cy="transaction-item-type">
        <TypeCell value={listing.type} />
      </td>,
    ],
    [
      "status",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td className="tw-whitespace-nowrap tw-relative tw-px-28px" data-cy="transaction-item-status">
        <TDIOptionsStatusCell listing={listing} />
      </td>,
    ],
    [
      "mls",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-mlsNumber"
      >
        <TextCell
          value={listing.mlsNumber}
          isEditable={editableCheck("mls")}
          onChange={onChange("mlsNumber")}
        />
      </td>,
    ],
    [
      "agent",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td className="tw-whitespace-nowrap tw-relative tw-px-28px" data-cy="transaction-item-agent">
        <AgentCell agent={listing.agent} rounded isEditable={editableCheck("agent")} />
      </td>,
    ],
    [
      "dateListed",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-dateListed"
      >
        <DateCell
          value={listing.dateListed}
          isEditable={editableCheck("dateListed")}
          onChange={onChange("dateListed")}
        />
      </td>,
    ],
    [
      "price",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-listingPrice"
      >
        <CurrencyCell
          value={listing.listingPrice}
          isEditable={editableCheck("price")}
          onChange={onChange("listingPrice")}
        />
        <FadeOut />
      </td>,
    ],
    [
      "expiration",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-dateExpired"
      >
        <DateCell
          value={listing.dateExpired}
          isEditable={editableCheck("expiration")}
          onChange={onChange("dateExpired")}
        />
      </td>,
    ],
    [
      "closePrice",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-closePrice"
      >
        <CurrencyCell value={listing.closePrice} />
        <FadeOut />
      </td>,
    ],
    [
      "gci",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-gci"
      >
        <CurrencyCell value={listing.grossCommission} />
        <FadeOut />
      </td>,
    ],
    [
      "closeDate",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-closeDate"
      >
        <DateCell
          value={listing.closeDate}
          isEditable={editableCheck("closeDate")}
          onChange={onChange("closeDate")}
        />
      </td>,
    ],
    [
      "dom",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-dom"
      >
        <TextCell value={listing.dom} isEditable={editableCheck("dom")} />
      </td>,
    ],
    [
      "dateCanceled",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-dateCanceled"
      >
        <DateCell
          value={listing.canceledDate}
          isEditable={editableCheck("dateCanceled")}
          onChange={onChange("canceledDate")}
        />
      </td>,
    ],
    [
      "mutualAcceptanceDate",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-mutualAcceptanceDate"
      >
        <DateCell value={listing.mutualAcceptanceDate} isEditable={editableCheck("mutualAcceptanceDate")} />
      </td>,
    ],
    [
      "lastUpdated",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-lastUpdated"
      >
        <DateCell value={listing.lastUpdated} isEditable={editableCheck("lastUpdated")} />
      </td>,
    ],
    [
      "source",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-sourceId"
      >
        <OptionsInlineCell
          isEditable={editableCheck("source")}
          optionsList={sources}
          selectedId={listing.sourceId}
          onUpdateOption={onChange("sourceId")}
          selectionWrapperClassName="!tw-z-[100000000]"
        />
      </td>,
    ],
    [
      "statusChangedAt",
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-statusChangedAt"
      >
        <DateCell value={listing.statusChangedAt} isEditable={editableCheck("statusChangedAt")} />
      </td>,
    ],
    ...Object.keys(listing.newMilestones).map((key) => [
      key,
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <td
        className="tw-whitespace-nowrap tw-relative tw-px-28px"
        style={widths}
        data-cy="transaction-item-newMilestone"
      >
        <DateCell value={listing.newMilestones[key]?.date} isEditable={editableCheck("newMilestones")} />
      </td>,
    ]),
  ]);

  return (
    <tr
      className="transactions__transaction transactions__row tw-border tw-border-l-0 tw-border-t-1px tw-border-gray-light tw-border-solid tw-h-58px tw-text-gray-75"
      key={JSON.stringify(listing)}
      data-listing-id={listing.id}
      data-listing-type={listing.type}
      data-cy="transaction-item-list-view"
    >
      {baseColumns}
      <td className="tw-relative tw-px-28px">
        <div className=" tw-flex tw-flex-row tw-justify-start tw-items-center">
          <a href={listing.editURL} className="tw-pointer">
            <button type="button" data-tip="Edit" className="tw-border-0 tw-bg-transparent">
              <EditIcon big aria-label="edit" />
            </button>
          </a>
          {canDeleteListing && (
            <button
              aria-label="delete"
              type="button"
              onClick={() => deleteListing(listing)}
              className="tw-border-0 tw-p-0 tw-bg-transparent tw-text-coral"
              data-tip="Delete"
              data-cy="delete-listing"
            >
              <TrashIcon />
            </button>
          )}
          {canArchiveListing && (
            <button
              aria-label="archive"
              type="button"
              onClick={() => archiveListing(listing)}
              className="tw-border-0 tw-p-0 tw-bg-transparent"
              data-tip="Archive"
              data-cy="archive-listing"
            >
              <ArchiveIcon />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default Transaction;
