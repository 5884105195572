import React from 'react';
import moment from 'moment-timezone';

const timeWithZoneFormat = 'hh:mm a z';

const formattedTimeFromUtcDateTimeString = (dateTimeString) => {
  return dateTimeString ? moment(dateTimeString).tz(Brivity.LocalTimeZone).format(timeWithZoneFormat) : null;
}

const formattedTimeFromUtcTimeString = (timeString) => {
  const utcTimeObject = moment.utc(`2017-01-01 ${timeString}`, 'YYYY-MM-DD HH:mm');
  const localTimeObject = utcTimeObject.tz(Brivity.LocalTimeZone);
  return localTimeObject.format(timeWithZoneFormat);
}

export {
  formattedTimeFromUtcDateTimeString,
  formattedTimeFromUtcTimeString,
  timeWithZoneFormat,
};
