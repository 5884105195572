import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import DraggableExpenseIncomeLineItems from "../ExpenseIncomeLineItem/DraggableExpenseIncomeLineItems";

import Company from "./Company";
import { tdpDetails } from "../../../../../shared/HOCs/withRedux";
import { companyShape } from "../../utils";

const CompanyWithLineItems = ({
  company,
  onGciLabelClick,
  onGciValueChange,
  onRemoveCompany,
  onAddLineItem,
  onLineItemDragEnd,
  onLineItemOptionChange,
  onLineItemValueChange,
  onEditLineItem,
  onRemoveLineItem,
}) => (
  <Company
    key={company.id}
    company={company}
    onGciLabelClick={onGciLabelClick}
    onGciValueClick={onGciValueChange}
    onTrashClick={onRemoveCompany}
    onAddExpenses={onAddLineItem}
  >
    <DragDropContext onDragEnd={onLineItemDragEnd}>
      <Droppable droppableId={`company-${company.id}-droppable-line-items`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <DraggableExpenseIncomeLineItems
              // These are internally indexed on
              // `DraggableExpenseIncomeLineItems` by using the `position`
              // field and falling back to the element's index on the array,
              // if necessary.
              lineItems={company.lineItems}
              isDraggingOver={snapshot.isDraggingOver}
              onOptionChange={onLineItemOptionChange}
              onValueChange={onLineItemValueChange}
              onEditLineItem={onEditLineItem}
              onRemoveLineItem={onRemoveLineItem}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </Company>
);

CompanyWithLineItems.propTypes = {
  company: PropTypes.arrayOf(companyShape).isRequired,
  onGciLabelClick: PropTypes.func,
  onGciValueChange: PropTypes.func,
  onRemoveCompany: PropTypes.func,
  onAddLineItem: PropTypes.func,
  onLineItemDragEnd: PropTypes.func,
  onLineItemOptionChange: PropTypes.func,
  onLineItemValueChange: PropTypes.func,
  onEditLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
};

CompanyWithLineItems.defaultProps = {
  onGciLabelClick: () => {},
  onGciValueChange: () => {},
  onRemoveCompany: () => {},
  onAddLineItem: () => {},
  onLineItemDragEnd: () => {},
  onLineItemOptionChange: () => {},
  onLineItemValueChange: () => {},
  onEditLineItem: () => {},
  onRemoveLineItem: () => {},
};

export default tdpDetails({
  WrappedComponent: CompanyWithLineItems,
});
