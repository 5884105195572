import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { HeartFilled, HeartOutline } from "../../../../shared/v2/Icomoon";

const Rating = ({ rating, size, className }) => {
  const baseRef = useRef(null);
  const ratingRef = useRef(null);

  // The margin between the hearts impacts in the calculus of the filled hearts width
  // If the margin class is changed the `margin` variable must be changed accordingly otherwise
  // rendering issues might occur
  const margin = 4; // In pixels
  const marginClass = "tw-ml-4px";

  useEffect(() => {
    if (!baseRef.current || !ratingRef.current) {
      return;
    }

    if (rating === 0.0) {
      ratingRef.current.style.width = "0";
      return;
    }

    const fullRating = baseRef.current.firstChild.getBoundingClientRect().width * 5;
    ratingRef.current.style.width = `${fullRating * (rating / 5.0) + margin * Number.parseInt(rating, 10)}px`;
  }, [baseRef, ratingRef, rating]);

  return (
    <span
      className={`tw-relative tw-min-h-8px tw-max-h-32px tw-text-semantic-red-100 ${className}`}
      data-cy={`feedback-rating-${rating}`}
    >
      <span ref={baseRef} className="tw-whitespace-nowrap">
        <HeartOutline size={size} />
        <HeartOutline size={size} className={marginClass} />
        <HeartOutline size={size} className={marginClass} />
        <HeartOutline size={size} className={marginClass} />
        <HeartOutline size={size} className={marginClass} />
      </span>
      <span
        ref={ratingRef}
        className="tw-absolute tw-w-0 tw-top-0 tw-left-0 tw-z-10 tw-overflow-hidden tw-whitespace-nowrap"
      >
        <HeartFilled size={size} />
        <HeartFilled size={size} className={marginClass} />
        <HeartFilled size={size} className={marginClass} />
        <HeartFilled size={size} className={marginClass} />
        <HeartFilled size={size} className={marginClass} />
      </span>
    </span>
  );
};
Rating.propTypes = {
  rating: PropTypes.number,
  size: PropTypes.oneOf(["s", "m", "l", "xl", "xxl", "xxxl"]),
  className: PropTypes.string,
};

Rating.defaultProps = {
  rating: 0.0,
  size: "l",
  className: "",
};

export default Rating;
