import React from "react";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";

// careful updating this, it is also used outside of dashboardShared
const Error = ({ className, message, onClose, show }) => {
  if (!show) return null;
  return (
    <Alert
      className={className}
      variant="error"
      text={message || "Whoops! Something went wrong."}
      onClose={onClose}
    />
  );
};

Error.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

Error.defaultProps = {
  className: undefined,
  message: undefined,
  onClose: undefined,
};

export default Error;
