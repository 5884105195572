import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  updateLeadCounts,
  updatePeopleCounts,
  updateTaskCounts,
  updateTransactionCounts,
} from "../../../reducers/layoutReducer";

// milliseconds * seconds * minutes
const MINUTES_5 = 1000 * 60 * 5;

const TYPES = {
  Leads: { action: updateLeadCounts, url: "/leads_counts" },
  People: { action: updatePeopleCounts, url: "/people_counts" },
  Tasks: { action: updateTaskCounts, url: "/tasks/counts" },
  Transactions: { action: updateTransactionCounts, url: "/listings/counts" },
};

const shouldUpdate = (counts) =>
  typeof counts?.lastUpdate === "undefined" || Date.now() - counts.lastUpdate > MINUTES_5;

const useCounts = (type) => {
  const counts = useSelector(({ layout }) => layout.counts?.[type]) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if (!TYPES[type]) return;
    const { action, url } = TYPES[type];
    // in the unlikely event that the page hasn't refreshed within 5 minutes
    // cache the sidebar counts for that amount of time before updating
    // TODO: provide dynamic way to update counts as things are changed
    if (shouldUpdate(counts)) {
      axios.get(url).then(({ data }) => dispatch(action(data)));
    }
  }, [counts]);

  return { counts };
};

export default useCounts;
