import PropTypes from "prop-types";

export const STYLES = {
  pipe: "tw-border-r tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-gray-10",
  row: "tw-text-14d tw-flex tw-gap-8px",
  contentContainer: "tw-flex tw-flex-col tw-gap-4px",
  textThemed: (isPlace) => (isPlace ? "tw-text-semantic-blue-100" : "tw-text-teal"),
  bgThemed: (isPlace) => (isPlace ? "tw-bg-semantic-blue-100" : "tw-bg-teal"),
};

export const activityCommonPropTypes = {
  activity: PropTypes.shape().isRequired,
  Icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  childContainerClass: PropTypes.string,
  onReadChange: PropTypes.func,
  SideContent: PropTypes.node,
};

export const activityCommonDefaultProps = {
  onReadChange: () => {},
  childContainerClass: "",
  SideContent: null,
};
