/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChartLine = (props) => (
  <Icon {...props} name="Chart Line">
    <path d="M15.625 12c.207 0 .375.169.375.375v1.251a.376.376 0 0 1-.375.375H.374a.376.376 0 0 1-.375-.375V2.375c0-.207.169-.375.375-.375h1.251c.207 0 .375.169.375.375V12h13.625zm-1.374-9h-3.5a.75.75 0 0 0-.531 1.281l1.028 1.028-2.249 2.279-1.737-1.737a.371.371 0 0 0-.528 0L3.015 9.532a.374.374 0 0 0-.007.528l.891.919a.373.373 0 0 0 .535.003l2.565-2.565 1.735 1.735a.375.375 0 0 0 .531 0l3.412-3.412 1.044 1.044a.75.75 0 0 0 1.281-.531v-3.5a.749.749 0 0 0-.749-.751z" />
  </Icon>
);

export default ChartLine;
