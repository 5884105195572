import React, { useState } from "react";
import capitalize from "capitalize";
import Modal from "@shared/v2/Modal";

const assignedPlanEvents = {
  assigned_plan_applied: {
    name: "applied",
    cssClass: "assigned-plan-applied text-info",
    iconClass: "fa-bolt",
  },
  assigned_plan_paused: { name: "paused", cssClass: "assigned-plan-paused", iconClass: "fa-pause" },
  assigned_plan_resumed: {
    name: "resumed",
    cssClass: "assigned-plan-resumed text-info",
    iconClass: "fa-play",
  },
  assigned_plan_completed: { name: "completed", cssClass: "assigned-plan-completed", iconClass: "fa-bolt" },
  assigned_plan_deleted: { name: "deleted", cssClass: "assigned-plan-deleted", iconClass: "fa-trash" },
};

const getDetails = ({ id, timeline_fields: timelineFields }) => {
  const rows = [];

  for (const field in timelineFields) {
    if (field == "user_name") {
      continue;
    }
    const titleizedKey = field
      .split("_")
      .map((element) => {
        return capitalize(element);
      })
      .join(" ");

    const description = `${titleizedKey}: ${timelineFields[field]}`;
    rows.push(description);
  }

  return rows.sort().map((description) => {
    return <div key={`${id}-${description}`}>{description}</div>;
  });
};

const getTitle = ({ event_type, timeline_fields }) => {
  const { cssClass, name } = assignedPlanEvents[event_type] || {};
  const updaterName = timeline_fields.user_name;
  const updatedViaTrigger = !updaterName;

  if (updatedViaTrigger) {
    return (
      <div>
        Auto Plan <span className={cssClass}>{name}</span>
        {event_type !== "assigned_plan_completed" && " via trigger"}
      </div>
    );
  } else {
    return (
      <div>
        <span className="updater-name">{updaterName}</span> <span className={cssClass}>{name}</span> an Auto
        Plan
      </div>
    );
  }
};

const getIcon = ({ event_type }) => {
  const { cssClass, iconClass } = assignedPlanEvents[event_type] || {};
  return <i className={`fa ${cssClass} ${iconClass} timeline-icon-circle`} />;
};

const AssignedPlanEvent = ({ event }) => {
  const [showModal, setShowModal] = useState(false);

  const title = getTitle(event);

  return (
    <div>
      <div className="timeline-event" onClick={() => setShowModal(true)}>
        <div className="row">
          <div className="col-xs-1">{getIcon(event)}</div>
          <div className="col-xs-7">
            {title}
            <div className="timeline-description-text">{getDetails(event)}</div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full timeline-content !tw-bg-transparent"
        contentClassName="tw-w-[600px] tw-m-auto tw-space-y-[32px] timeline-event"
      >
        <Modal.Header title={title} closeButton />
        <Modal.Body>
          <div className="tw-text-14d tw-font-semibold">Details:</div>
          {getDetails(event)}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AssignedPlanEvent;
