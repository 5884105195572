/* eslint-disable no-param-reassign */
import { produce } from "immer";

const hashValue = ({ isHash, value }) => (isHash ? { ...value } : value);

export default (payloadHandlrs) => (e, group, key, isHash) => {
  if (e.persist) e.persist();
  const value = e.target?.value ?? e;

  payloadHandlrs((prev) =>
    produce(prev, (draft) => {
      if (key) draft[group][key] = hashValue({ isHash, value });
      if (!key) draft[group] = hashValue({ isHash, value });
    }),
  );
};
