import { useSelector } from "react-redux";

export const useArchivePost = () => useSelector((state) => state.website.archivePost);
export const useBlogSettings = () => useSelector((state) => state.website.blogSettings);
export const useBlogSettingsError = () => useSelector((state) => state.website.blogSettingsError);
export const useBlogSettingsLoading = () => useSelector((state) => state.website.blogSettingsLoading);
export const useCategories = () => useSelector((state) => state.website.categories);
export const useCategoriesError = () => useSelector((state) => state.website.categoriesError);
export const useCategoriesLoading = () => useSelector((state) => state.website.categoriesLoading);
export const usePost = () => useSelector((state) => state.website.post);
export const usePostError = () => useSelector((state) => state.website.postError);
export const usePostLoading = () => useSelector((state) => state.website.postLoading);
export const usePostPublishStatusCounts = () => useSelector((state) => state.website.postPublishStatusCounts);
export const usePosts = () => useSelector((state) => state.website.posts);
export const usePostsError = () => useSelector((state) => state.website.postsError);
export const usePostsLoading = () => useSelector((state) => state.website.postsLoading);
export const usePostSaved = () => useSelector((state) => state.website.postSaved);
export const useSharePost = () => useSelector((state) => state.website.sharePost);
export const useTags = () => useSelector((state) => state.website.tags);
export const useTagsError = () => useSelector((state) => state.website.tagsError);
export const useTagsLoading = () => useSelector((state) => state.website.tagsLoading);
export const useTotalPages = () => useSelector((state) => state.website.totalPages);
export const useWebsiteApiUrl = () => useSelector((state) => state.website.apiUrl);
export const useWebsiteIDXUrl = () => useSelector((state) => state.website.idxConfiguration.fullUrl);
