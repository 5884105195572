import React from "react";
import PropTypes from "prop-types";

import { ModalContext } from "./ModalContext";
import CLASSES from "./classes.json";

const { MODAL_BODY_CLASSES } = CLASSES;

const ModalBody = ({
  className,
  children,
  variant: variantFromProps,
  deprecatedOverridePaddingClasses,
  ...props
}) => {
  const { variant: variantFromContext } = React.useContext(ModalContext);
  const variant = variantFromProps || variantFromContext;

  return (
    <>
      {/* This component is meant to be rendered inside a ModalContext, which */}
      {/* already provides the keyboard listeners so we silence the linter here */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
          ${MODAL_BODY_CLASSES.common}
          ${deprecatedOverridePaddingClasses || MODAL_BODY_CLASSES[variant]}
          ${className}
        `}
        {...props}
      >
        {children}
      </div>
    </>
  );
};
ModalBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["classic", "modern"]),
  deprecatedOverridePaddingClasses: PropTypes.string,
};
ModalBody.defaultProps = {
  children: null,
  className: "",
  variant: null,
  deprecatedOverridePaddingClasses: "",
};

export default ModalBody;
