/* eslint-disable import/prefer-default-export  */
import * as creators from "./creators";
import { loadDetailsApi, searchPartnersApi } from "../services";

export const loadDetails =
  ({ controller, id }) =>
  async (dispatch) => {
    dispatch(creators.getDetails());
    const onFailure = (error) => dispatch(creators.getDetailsError({ error }));
    const onSuccess = (response) => {
      if (response?.data?.error) {
        return onFailure(response?.data?.error);
      }
      return dispatch(creators.getDetailsSuccess({ data: response.data }));
    };
    return loadDetailsApi({ controller, id, successCb: onSuccess, errorCb: onFailure });
  };

export const callPartnersSearch =
  ({ controller, data }) =>
  async (dispatch) => {
    dispatch(creators.searchPartners());
    const onFailure = (error) => dispatch(creators.searchPartnersError({ error }));
    const onSuccess = (response) => {
      if (response?.data?.error) {
        return onFailure(response?.data?.error);
      }
      return dispatch(creators.searchPartnersSuccess(response?.data));
    };
    return searchPartnersApi({ controller, data, successCb: onSuccess, errorCb: onFailure });
  };
