import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "../../../../../shared/v2/Dropdown";
import { customDefaultProps, customPropTypes, optionShape } from "../../../../../shared/v2/Dropdown/utils";
import { errorShapeForErrorBanner } from "../../../../../shared/v2/ErrorBanner";
import FlatPercentInput from "../../../../../shared/v2/FlatPercentInput";
import FlatPercentToggle from "../../../../../shared/v2/FlatPercentToggle";
import { FloatingForm } from "../../../../../shared/v2/Modal";
import TextInput from "../../../../../shared/v2/TextInput";

const CustomNameSingleValue = ({ componentProps, data }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.SingleValue {...componentProps} preserveStyle>
    <span data-cy="dropdown-option">{data?.meta?.name || data?.label}</span>
  </Dropdown.SingleValue>
);

CustomNameSingleValue.propTypes = customPropTypes;

CustomNameSingleValue.defaultProps = customDefaultProps;

const AddEditExpenseIncomeModal = ({
  show,
  isEditing,
  isSaving,
  isDeleting,
  errors,
  nameList,
  name,
  value,
  expenseOrincome,
  isPercentage,
  notes,
  onSave,
  onDelete,
  onCancel,
}) => {
  const [currentNameList, setCurrentNameList] = useState(nameList);
  const [currentName, setCurrentName] = useState(name);

  const [currentValue, setCurrentValue] = useState(value);
  const [isCurrentlyPercentage, setIsCurrentlyPercentage] = useState(isPercentage);
  const [currentNotes, setCurrentNotes] = useState(notes);

  const [showCustomNameField, setShowCustomNameField] = useState(false);

  const shouldDisableSaveButton = useMemo(
    () => !currentName || Number(currentValue) === 0,
    [currentName, currentValue],
  );

  const resetNameIfInvalid = () => {
    if (currentName.meta.isCustomOption && !currentName.meta.name) {
      // Design wants invalid custom options to be the same as no option
      setCurrentName(null);
    }
  };

  const handleSave = () => {
    onSave({
      name: currentName,
      value: currentValue,
      isPercentage: isCurrentlyPercentage,
      notes: currentNotes,
    });
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleCancel = () => {
    onCancel();
  };

  const customNameRef = (node) => {
    node?.focus();
  };

  const handleCustomNameChange = (e) => {
    const newValue = e.target.value;

    const newCurrentName = {
      ...currentName,
      meta: {
        ...currentName.meta,
        name: newValue,
      },
    };

    setCurrentNameList((prevNameList) =>
      prevNameList.map((prevName) => {
        if (prevName.value === currentName.value) {
          return newCurrentName;
        }

        return prevName;
      }),
    );

    setCurrentName(newCurrentName);
  };

  const handleCustomNameBlur = () => {
    setShowCustomNameField(false);
    resetNameIfInvalid();
  };

  const handleNameChange = (newName) => {
    setCurrentName(newName);
    if (newName.meta.isCustomOption) {
      setShowCustomNameField(true);
    }
  };

  const handleValueChange = (_, newValue) => {
    setCurrentValue(newValue);
  };

  const handleIsPercentageChange = (_, newIsPercentage) => {
    setIsCurrentlyPercentage(newIsPercentage);
  };

  const handleNotesChange = (e) => {
    setCurrentNotes(e.target.value);
  };

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  const getTitle = () =>
    `${isEditing ? "Edit" : "Add"} ${iff(
      expenseOrincome === "expenses",
      "Expense",
      iff(expenseOrincome === "income", "Income", "Expense/Income"),
    )}`;

  const getPlaceholder = () =>
    `Select an ${iff(
      expenseOrincome === "expenses",
      "expense",
      iff(expenseOrincome === "income", "income", "expense/income"),
    )}`;

  useEffect(() => setCurrentNameList(nameList), [nameList]);
  useEffect(() => setCurrentName(name), [name]);
  useEffect(() => setCurrentValue(value), [value]);
  useEffect(() => setIsCurrentlyPercentage(isPercentage), [isPercentage]);
  useEffect(() => setCurrentNotes(notes), [notes]);

  return (
    <FloatingForm
      show={show}
      errors={errors}
      title={getTitle()}
      primaryOption={isEditing ? "Save" : "Add"}
      primaryProps={{ disabled: shouldDisableSaveButton }}
      isPrimaryLoading={isSaving}
      onPrimary={handleSave}
      secondaryOption={isEditing ? "Delete" : null}
      secondaryProps={{ schema: "warning" }}
      isSecondaryLoading={isDeleting}
      onSecondary={handleDelete}
      onCancel={handleCancel}
      scroll="none"
      closeOnClickOutside
      closeOnEscape
    >
      <div className="tw-flex tw-flex-col tw-gap-24px">
        <div className="tw-flex tw-items-center tw-gap-24px">
          {showCustomNameField && currentName ? (
            <div className="tw-grow" data-cy="custom-name-input-container">
              <TextInput
                ref={customNameRef}
                label="Name"
                placeholder={`Name custom ${currentName.meta.type}`}
                value={currentName.meta.name}
                isRequired
                onChange={handleCustomNameChange}
                onBlur={handleCustomNameBlur}
              />
            </div>
          ) : (
            <Dropdown
              containerClassName="tw-grow"
              label="Name"
              placeholder={getPlaceholder()}
              options={currentNameList}
              value={currentName}
              isRequired
              onChange={handleNameChange}
              components={{ SingleValue: CustomNameSingleValue }}
            />
          )}

          <div className="tw-flex tw-items-end tw-gap-8px">
            <FlatPercentInput
              className="tw-w-[156px]"
              label="Amount"
              isRequired
              value={currentValue}
              isPercentage={isCurrentlyPercentage}
              onChange={handleValueChange}
            />

            <FlatPercentToggle isPercentage={isCurrentlyPercentage} onChange={handleIsPercentageChange} />
          </div>
        </div>

        <TextInput label="Notes" value={currentNotes} onChange={handleNotesChange} />
      </div>
    </FloatingForm>
  );
};

AddEditExpenseIncomeModal.propTypes = {
  show: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  isDeleting: PropTypes.bool,
  errors: PropTypes.arrayOf(errorShapeForErrorBanner),
  nameList: PropTypes.arrayOf(optionShape).isRequired,
  name: optionShape,
  value: PropTypes.string,
  isPercentage: PropTypes.bool,
  notes: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  expenseOrincome: PropTypes.string,
};

AddEditExpenseIncomeModal.defaultProps = {
  show: true,
  isEditing: false,
  isSaving: false,
  isDeleting: false,
  errors: [],
  name: null,
  value: "0",
  isPercentage: false,
  notes: "",
  expenseOrincome: "",
};

export default AddEditExpenseIncomeModal;
