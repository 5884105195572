import React from "react";
import { ReportHeader } from "./";
import { Col } from "react-bootstrap";
import css from "./report-styles.module.css";

const AgentActivityHeaderResponseTimes = ({ children }) => (
  <div className="col-xs-3" style={{ display: "flex", justifyContent: "flex-start", padding: 0 }}>
    {children}
  </div>
);
const AgentActivityHeaders = ({ sortBy, sortKey, sortDesc }) => (
  <Col xs={12} className={`tw-sticky tw-top-0 tw-z-2 tw-bg-white ${css.noWrap}`}>
    <div className="th">
      <ReportHeader
        name="agent"
        className="col-xs-2"
        sortDesc={sortDesc}
        sortKey={sortKey}
        label="Name"
        handleSort={sortBy}
      />
      <ReportHeader
        name="lead_count"
        sortDesc={sortDesc}
        styles={{ padding: 0 }}
        sortKey={sortKey}
        label="Leads"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />

      <ReportHeader
        name="email_count"
        sortDesc={sortDesc}
        sortKey={sortKey}
        styles={{ padding: 0 }}
        label="Emailed"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />

      <ReportHeader
        name="call_count"
        sortDesc={sortDesc}
        sortKey={sortKey}
        styles={{ padding: 0 }}
        label="Called"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />
      <ReportHeader
        name="contacted_count"
        sortDesc={sortDesc}
        styles={{ padding: 0 }}
        sortKey={sortKey}
        label="Contacted"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />
      <ReportHeader
        name="text_count"
        sortDesc={sortDesc}
        styles={{ padding: 0 }}
        sortKey={sortKey}
        label="Texted"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />
      <AgentActivityHeaderResponseTimes>
        <ReportHeader
          name="email_resp_time"
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Email Resp. Time"
          className="col-xs-4 text-center"
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_resp_time"
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Call Resp. Time"
          className="col-xs-4 text-center"
          handleSort={sortBy}
        />
        <ReportHeader
          name="text_resp_time"
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Text Resp. Time"
          className="col-xs-4 text-center"
          handleSort={sortBy}
        />
      </AgentActivityHeaderResponseTimes>
      <ReportHeader
        name="login_count"
        sortDesc={sortDesc}
        sortKey={sortKey}
        styles={{ padding: 0 }}
        label="Logins"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />
      <ReportHeader
        name="last_login"
        sortDesc={sortDesc}
        sortKey={sortKey}
        label="Last Login"
        className="col-xs-1 text-center"
        handleSort={sortBy}
      />
    </div>
  </Col>
);
export default AgentActivityHeaders;
