import React from "react";
import { useDispatch } from "react-redux";
import { TriangleExclamationSolidV6 } from "@shared/v2/Icomoon";

import { useSuspendedUserError } from "../../../reducers/userSettingsReducer";
import { setSuspendError } from "../actions/default";
import Error from "../../../Reports/components/DashboardShared/Error";

const SuspendError = () => {
  const dispatch = useDispatch();
  const suspendedUserError = useSuspendedUserError();

  return (
    <Error
      show={suspendedUserError}
      message={
        <div className="tw-flex tw-items-center tw-gap-[12px]">
          <TriangleExclamationSolidV6 size="l" />
          <span>
            Unsuccessful: {suspendedUserError} was not suspended. If error persists, contact support by
            calling 855-427-4848.
          </span>
        </div>
      }
      className="tw-flex tw-items-center tw-justify-between"
      onClose={() => dispatch(setSuspendError(false))}
    />
  );
};

export default SuspendError;
