import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  onMinRangeChangeFn,
  onMaxRangeChangeFn,
  onMinInputChangeFn,
  onMaxInputChangeFn,
  onMinInputBlurFn,
  onMaxInputBlurFn,
  isNumeric,
} from "./helpers";
import { useMinMax, useGetPercent, useSetSliderBar } from "./hookHelpers";
import "./styles.scss";
import TextComboInput from "../../../shared/v2/TextComboInput";

const numberWithCommas = (x) => {
  if (typeof x !== "number") {
    return x;
  }
  return x.toLocaleString();
};
const valueDisplay = (value) => (value ? `${numberWithCommas(value)}` : "");

const MultiRangeSlider = ({ min, max, minVal, maxVal, onChange, step }) => {
  const [tmpMin, setTmpMin, tmpMax, setTmpMax] = useMinMax(minVal, maxVal, valueDisplay);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const minInputRef = useRef(null);
  const maxInputRef = useRef(null);
  const range = useRef(null);

  const numMinVal = +minVal?.replace(/\D/g, "");
  const numMaxVal = +maxVal?.replace(/\D/g, "");

  const onMinRangeChange = onMinRangeChangeFn(maxVal, numMaxVal, setTmpMin, onChange, valueDisplay);
  const onMaxRangeChange = onMaxRangeChangeFn(minVal, numMinVal, setTmpMax, onChange, valueDisplay, max);
  const onMinInputChange = onMinInputChangeFn(minVal, maxVal, setTmpMin, onChange);
  const onMaxInputChange = onMaxInputChangeFn(minVal, maxVal, setTmpMax, onChange);
  const onMinInputBlur = onMinInputBlurFn(
    numMaxVal,
    setTmpMin,
    valueDisplay,
    min,
    max,
    minVal,
    maxVal,
    onChange,
  );
  const onMaxInputBlur = onMaxInputBlurFn(
    numMinVal,
    setTmpMax,
    valueDisplay,
    min,
    max,
    minVal,
    maxVal,
    onChange,
  );

  const getPercent = useGetPercent(min, max);
  useSetSliderBar(tmpMin, tmpMax, getPercent, maxValRef, minValRef, (styleName, val) => {
    if (range.current) {
      range.current.style[styleName] = val;
    }
  });

  return (
    <>
      <div className="range-slider tw-relative tw-flex tw-items-center tw-content-center tw-mt-[27px] tw-mb-[20px]">
        <input
          type="range"
          min={min}
          max={max - 1}
          step={step}
          value={numMinVal}
          ref={minValRef}
          onChange={onMinRangeChange}
          className={`thumb tw-absolute tw-w-full tw-h-0 tw-oultine-none tw-appearance-none tw-p-0 ${
            minVal >= max - 1000 ? "tw-z-50" : "tw-z-30"
          }`}
        />
        <input
          type="range"
          min={1000}
          max={max}
          step={1000}
          value={numMaxVal || max}
          ref={maxValRef}
          onChange={onMaxRangeChange}
          className="thumb tw-absolute tw-w-full tw-h-0 tw-oultine-none tw-appearance-none tw-p-0 tw-z-40"
        />

        <div className="tw-relative tw-w-full tw-h-[5px] tw-overflow-x-hidden tw-mt-[4px]">
          <div className="slider__track tw-absolute tw-h-[5px] tw-rounded-sm tw-w-full tw-z-1 tw-bg-gray-30" />
          <div
            ref={range}
            className="slider__range tw-absolute tw-h-[5px] tw-rounded-sm tw-z-2 tw-bg-brivity-blue-100"
          />
        </div>
      </div>
      <div className="tw-flex tw-mt-[27px]">
        <div className="tw-w-1/2 tw-flex-1 tw-overflow-hidden">
          <TextComboInput
            type="text"
            leading={tmpMin <= "0" ? null : "$"}
            ref={minInputRef}
            value={tmpMin <= "0" ? "" : tmpMin}
            onKeyDown={isNumeric}
            onBlur={onMinInputBlur}
            onInput={onMinInputChange}
            placeholder="No min"
          />
        </div>
        <div className="tw-w-[24px] tw-h-[36px] tw-flex tw-items-center tw-justify-center">
          <span className="tw-h-[1px] tw-w-[10px] tw-bg-gray-75" />
        </div>
        <div className="tw-w-1/2 tw-flex-1 tw-overflow-hidden">
          <div className="tw-flex">
            <TextComboInput
              type="text"
              leading={maxVal ? "$" : null}
              ref={maxInputRef}
              value={tmpMax}
              onKeyDown={isNumeric}
              onBlur={onMaxInputBlur}
              onInput={onMaxInputChange}
              placeholder="No max"
            />
          </div>
        </div>
      </div>
    </>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  minVal: PropTypes.string.isRequired,
  maxVal: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
