import axios from "axios";
import { tryCatchHandlr } from "../shared/Utilities";

export const saveMarketReportApi = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/create_report`;
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const updateMarketReportApi = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/update_report`;
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const viewMarketReportApi = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/edit_report`;
  const [resData, err] = await tryCatchHandlr(
    axios.get(
      url,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const sendMarketReportApi = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/send_report`;
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

let getAreaCMACountsController;
export const getAreaCMACounts = async (data, callback, errorCallback) => {
  const url = "/market_reports/property_listings";
  if (getAreaCMACountsController) {
    // cancel the request
    getAreaCMACountsController.abort();
  }
  getAreaCMACountsController = new AbortController();
  const [resData, err] = await tryCatchHandlr(
    axios.post(
      url,
      { ...data },
      {
        signal: getAreaCMACountsController.signal,
        headers: {
          "Content-Type": "application/json",
          "x-csrf-token": window.ReactOnRails.authenticityToken(),
        },
      },
    ),
  );
  if (err) {
    if (err.code === "ERR_CANCELED") {
      return false;
    }
    return errorCallback();
  }
  return callback(resData);
};

export const getSearchAreaResults = async ({ data, callback, errorCallback }) => {
  const url = "/market_reports/search_areas";

  const [resData, err] = await tryCatchHandlr(
    axios.get(
      url,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCallback(err);
  }
  return callback(resData);
};

export const getLocationGeoJSON = async ({ data, callback, errorCallback }) => {
  const url = "/market_reports/geo_json_details";

  const [resData, err] = await tryCatchHandlr(
    axios.get(
      url,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCallback(err);
  }
  return callback(resData);
};

export const getPlaceDetails = async ({ data, callback, errorCallback }) => {
  const url = "/market_reports/place_details";

  const [resData, err] = await tryCatchHandlr(
    axios.get(
      url,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCallback(err);
  }
  return callback(resData);
};

export const getAgentMLSOptions = async ({ data, successCb, errorCb }) => {
  const url = `/market_reports/user_mlses`;
  const [resData, err] = await tryCatchHandlr(
    axios.get(
      url,
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    ),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};
