import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";

import PrimaryPerson from "../../shared/PrimaryPerson";

const PERSON_TYPE = "Primary Agent";

const PrimaryAgentInfo = ({ agent, agentAvatar, primaryAgentRole }) => (
  <div className="tw-flex tw-flex-col tw-gap-[8px]">
    <PrimaryPerson
      name={agent || "No primary agent"}
      avatarImg={agentAvatar}
      descriptor={primaryAgentRole}
      personType={PERSON_TYPE}
      containerClass="tw-h-60px"
    />
  </div>
);

const mapStateToProps = (state) => ({
  agent: state.tdpDetailsReducer.primaryAgentDetails?.agent,
  agentAvatar: state.tdpDetailsReducer.primaryAgentDetails?.agentAvatar,
  primaryAgentRole: state.tdpDetailsReducer.primaryAgentDetails?.primaryAgentRole,
});

PrimaryAgentInfo.propTypes = {
  agent: string,
  agentAvatar: string,
  primaryAgentRole: string,
};

PrimaryAgentInfo.defaultProps = {
  agent: "",
  agentAvatar: null,
  primaryAgentRole: "",
};

export default connect(mapStateToProps)(PrimaryAgentInfo);
