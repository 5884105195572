/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { reorderList, transferBetweenColumns } from "./BoardManipulation";
// eslint-disable-next-line import/no-cycle
import Column from "./Column";
import { isReferral } from "../../../TransactionDetailsPage/shared/helpers";
import { UpdateFromPipelineContext } from "../Wrapper";
import actions from "../../actions";

const STATUSES = [
  "pipeline",
  "comingsoon",
  "active",
  "pending",
  "sold",
  "expired",
  "withdrawn",
  "canceled",
  "archived",
];

const normalizeStatus = (status) => status.replace(/coming[ -_]soon/, "comingsoon");

const getLastWord = (words) => {
  const n = words.split(" ");
  return n[n.length - 1];
};

const validDroppables = (droppable) => ["pipeline", "pending", "comingsoon", "withdrawn"].includes(droppable);

const dragged = (draggableStr, droppableStr) => isReferral(draggableStr) && validDroppables(droppableStr);

const groupByStatus = (listings, statusPagination) => {
  const temp = listings.reduce((acc, listing) => {
    const status = normalizeStatus(listing.status);
    if (!acc[normalizeStatus(status)]) acc[status] = [];
    acc[status].push(listing);
    return acc;
  }, {});

  return {
    columns: STATUSES.reduce((acc, status) => {
      acc[normalizeStatus(status)] = {
        id: status,
        title: status,
        items: temp[status] || [],
        pagination: statusPagination[status] || {},
      };
      return acc;
    }, {}),
    columnOrder: STATUSES,
  };
};

const initialState = (listings, statusPagination) => groupByStatus(listings || [], statusPagination || {});

const Board = ({
  listings = [],
  updateListing,
  sources,
  agents,
  setOrder,
  order,
  totals,
  onLoadMore,
  onDeletion,
  openAddTransactionModal,
  statusPagination = {},
  permissions,
}) => {
  const [state, setState] = useState(initialState(listings, statusPagination));
  const [pipelineStatusApproved, setPipelineStatusApproved] = useState(false);
  const [tempStatus, setTempStatus] = useState({});
  const { dispatch } = useContext(UpdateFromPipelineContext);

  useEffect(() => {
    setState(groupByStatus(listings, statusPagination));
  }, [listings, updateListing, sources, agents, setOrder, order, permissions, openAddTransactionModal]);

  const transferDraggedListing = (result) => {
    const listing = state.columns[result.source.droppableId].items[result.source.index];

    updateListing(listing)({ status: result.destination.droppableId });

    const newState = {
      ...state,
      columns: transferBetweenColumns({
        columns: state.columns,
        sourceId: result.source.droppableId,
        sourceIndex: result.source.index,
        targetId: result.destination.droppableId,
        targetIndex: result.destination.index,
      }),
    };

    setState(newState);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      const columnOrder = reorderList(state.columnOrder, result.source.index, result.destination.index);
      setState({
        ...state,
        columnOrder,
      });
      return;
    }

    // if a user tries to drag a Referral transaction card into one of the forbidden statuses then return
    if (dragged(getLastWord(result.draggableId), result.destination.droppableId)) return;

    if (result.source.droppableId === result.destination.droppableId) {
      // reordering in same list
      const column = state.columns[result.source.droppableId];
      const items = reorderList(column.items, result.source.index, result.destination.index);

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      setState(newState);
      return;
    }

    transferDraggedListing(result);
  };

  const onStatusChanged = (listing, oldStatus, newStatus) => {
    const sourceIndex = state.columns[oldStatus].items.findIndex((item) => item.id === listing.id);

    if (sourceIndex === -1) {
      return;
    }

    updateListing(listing)({ status: newStatus });

    const newState = {
      ...state,
      columns: transferBetweenColumns({
        columns: state.columns,
        sourceId: oldStatus,
        sourceIndex,
        targetId: newStatus,
        targetIndex: 0, // FIXME: check the best place to send the listing to
      }),
    };

    setState(newState);
  };

  useEffect(() => {
    if (!pipelineStatusApproved) return;

    transferDraggedListing(tempStatus);
    setPipelineStatusApproved(false);
    setTempStatus({});
  }, [pipelineStatusApproved]);

  return (
    // This is the default for how the library is used
    // eslint-disable-next-line react/jsx-no-bind
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          className="columns
          tw-flex tw-flex-row tw-flex-grow
          tw-space-x-[16px]
          tw-overflow-y-hidden
          tw-bg-tinted-gray-100
          tw-px-[20px]
          tw-pt-[16px]
        "
        >
          {state.columnOrder.map((columnId, index) => (
            <Column
              key={columnId}
              column={state.columns[columnId]}
              index={index}
              onStatusChanged={onStatusChanged}
              updateListing={updateListing}
              sources={sources}
              agents={agents}
              permissions={permissions}
              totals={totals}
              onLoadMore={() => onLoadMore(columnId)}
              onDeletion={onDeletion}
              openAddTransactionModal={openAddTransactionModal}
            />
          ))}
        </div>
      </DragDropContext>
      {/* {openPipelineStatusChangeModal && (
        <UpdatePipelineStatusModal
          toggleModal={openPipelineStatusChangeModal}
          setToggleModal={setOpenPipelineStatusChangeModal}
          uuid={currentListingUuid}
          setSubmitted={setPipelineStatusApproved}
        />
      )} */}
    </>
  );
};

export default Board;
