import React from "react";
import PropTypes from "prop-types";

const PreTooltipContent = ({ children }) => (
  <pre className="tw-text-wrap tw-bg-transparent tw-border-none tw-p-0 tw-m-0 tw-text-inherit tw-font-inherit tw-break-normal tw-text-left">
    {children}
  </pre>
);

PreTooltipContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreTooltipContent;
