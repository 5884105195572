import React from "react";
import { Col, Row } from 'react-bootstrap';
import { formatExpense } from '../shared/FinancialViewHelpers';
import format from "../../../shared/formatters";

const TeamMemberSplit = ({ income }) => (
  <div className="transaction-gci-content">
    <Row>
      <Col xs={2}>
        <label>Brokerage Split</label>
        {income.brokerage_split_amount &&
          <span>{formatExpense(format.currency(income.brokerage_split_amount))}</span>
        }
      </Col>
      <Col xs={5}>
      </Col>
      <Col xs={1}>
      </Col>
      <Col xs={2} className="text-right">
      </Col>
      <Col xs={2} className="text-right">
        <label>Subtotal</label>
        {income.brokerage_split_line_total &&
          <span>{format.currency(income.brokerage_split_line_total)}</span>
        }
      </Col>
    </Row>
    <Row style={{ paddingTop: "15px" }}>
      <Col xs={2}>
        <label>Royalty</label>
        {income.royalty_amount &&
          <span>{formatExpense(format.currency(income.royalty_amount))}</span>
        }
      </Col>
      <Col xs={5}>
      </Col>
      <Col xs={1}>
      </Col>
      <Col xs={2} className="text-right">
      </Col>
      <Col xs={2} className="text-right">
        <label>Subtotal</label>
        {income.royalty_line_total &&
          <span>{format.currency(income.royalty_line_total)}</span>
        }
      </Col>
    </Row>
  </div>
)

export default TeamMemberSplit;
