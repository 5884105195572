import React from "react";
import { func } from "prop-types";

import withDispatch from "../../../../shared/HOCs/withDispatch";
import { referralDesiredAddressEditOn } from "../../actions/creators";

import AddValueTxtBtn from "../../shared/AddValueTxtBtn";

const ReferralDesiredAddressAdd = ({ onClick }) => (
  <AddValueTxtBtn btnClassName="tw-ml-10px" onClick={onClick} />
);

ReferralDesiredAddressAdd.propTypes = {
  onClick: func,
};

ReferralDesiredAddressAdd.defaultProps = {
  onClick: null,
};

export default withDispatch({
  WrappedComponent: ReferralDesiredAddressAdd,
  actions: referralDesiredAddressEditOn,
  aliases: "onClick",
});
