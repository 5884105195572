import React from "react";
import { useDispatch } from "react-redux";

import TextComboInput from "@shared/v2/TextComboInput";

import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

const Title = () => {
  const dispatch = useDispatch();
  const { jobTitle } = useContactPerson();
  return (
    <TextComboInput
      label="Title"
      value={jobTitle}
      onChange={(e) => dispatch(setPersonValue({ key: "jobTitle", value: e.target.value }))}
      toolTipInfoContent={<div>Displays as the user&apos;s job title on your Brivity website.</div>}
      toolTipPlacement="right"
    />
  );
};

export default Title;
