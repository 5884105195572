import React from "react";
import statusIntentCss from "./statusIntentDropdown.module.css";

const  DropdownOptions = ({ options, editValue, editKey }) => (
  <div className={statusIntentCss.content}>
    {options.map((s => (
        <div
          key={s[1]}
          onClick={(e) => editValue(editKey, s[1])}
          className={`${statusIntentCss.selectedFrequencyText} ${statusIntentCss.statusFrequencyOption}`}
        >
          <div className={`${statusIntentCss.selectedFrequencyTextalign}`}>{s[0]}</div>
        </div>
      )
    ))}
  </div>
);

export default DropdownOptions;
