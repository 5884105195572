import React from "react";

import { CommentDotsSolidV6 } from "@shared/v2/Icomoon";
import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";

const GroupTextActivity = ({ activity, onReadChange }) => {
  const queryParams = new URLSearchParams();
  queryParams.set("tab", "3");

  return (
    <ActivitiesContainer
      activity={activity}
      Icon={CommentDotsSolidV6}
      title={`${activity.title} texted ${activity.subtitle}`}
      onReadChange={onReadChange}
      queryParams={queryParams.toString()}
    >
      {activity.data.MessageText}
    </ActivitiesContainer>
  );
};

GroupTextActivity.propTypes = {
  ...activityCommonPropTypes,
};

GroupTextActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default GroupTextActivity;
