import { sortByKey } from '../../shared/Utilities';

const autoPlanNameLabel = (id, plans = []) => {
  const plan = plans.filter(p => p.id == id)[0];
  return plan ? plan.name : '';
}

const autoPlanOptions = (plans = []) => {
  const options = plans.map(plan => {
    return { label: plan.name, value: plan.id }
  })
  return sortByKey(options, 'label');
}

const statusOptions = [
  {label: 'Any', value: 'any'},
  {label: 'New', value: 'new'},
  {label: 'Unqualified', value: 'unqualified'},
  {label: 'Watch', value: 'watch'},
  {label: 'Nurture', value: 'nurture'},
  {label: 'Hot', value: 'hot'},
  {label: 'Past (Client)', value: 'past'},
  {label: 'Inactive', value: 'inactive'}
];

const intentOptions = [
  {label: 'Any', value: 'any'},
  {label: 'Buyer', value: 'buyer'},
  {label: 'Seller', value: 'seller'},
  {label: 'Candidate', value: 'candidate'},
  {label: 'Recruit', value: 'recruit'}
];

export { autoPlanNameLabel, autoPlanOptions, statusOptions, intentOptions };
