import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import DraggableExpenseIncomeLineItems from "../ExpenseIncomeLineItem/DraggableExpenseIncomeLineItems";
import { teamMemberShape } from "../../utils";

import TeamMember from "./TeamMember";
import { tdpDetails } from "../../../../../shared/HOCs/withRedux";

const TeamMembersWithLineItems = ({
  members,
  onGciLabelClick,
  onGciValueChange,
  onUnitsChange,
  onRemoveTeamMember,
  onAddLineItem,
  onLineItemDragEnd,
  onLineItemOptionChange,
  onLineItemValueChange,
  onEditLineItem,
  onRemoveLineItem,
  onBrokerageSplitValueChange,
  onEditBrokerageSplitLineItem,
  onRoyaltyValueChange,
  onEditRoyaltyLineItem,
}) =>
  members.map((member) => (
    <TeamMember
      key={member.id}
      member={member}
      onGciLabelClick={onGciLabelClick}
      onGciValueClick={onGciValueChange}
      onUnitClick={onUnitsChange}
      onTrashClick={onRemoveTeamMember}
      onAddExpenses={onAddLineItem}
    >
      <DragDropContext onDragEnd={(...args) => onLineItemDragEnd(member, ...args)}>
        <Droppable droppableId={`team-member-${member.id}-droppable-line-items`}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <DraggableExpenseIncomeLineItems
                // These are internally indexed on
                // `DraggableExpenseIncomeLineItems` by using the `position`
                // field and falling back to the element's index on the array,
                // if necessary.
                lineItems={member.lineItems}
                isDraggingOver={snapshot.isDraggingOver}
                onOptionChange={(...args) => onLineItemOptionChange(member, ...args)}
                onValueChange={(...args) => onLineItemValueChange(member, ...args)}
                onEditLineItem={(...args) => onEditLineItem(member, ...args)}
                onRemoveLineItem={(...args) => onRemoveLineItem(member, ...args)}
                onBrokerageSplitValueChange={(...args) => onBrokerageSplitValueChange(member, ...args)}
                onEditBrokerageSplitLineItem={(...args) => onEditBrokerageSplitLineItem(member, ...args)}
                onRoyaltyValueChange={(...args) => onRoyaltyValueChange(member, ...args)}
                onEditRoyaltyLineItem={(...args) => onEditRoyaltyLineItem(member, ...args)}
              />

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </TeamMember>
  ));

TeamMembersWithLineItems.propTypes = {
  members: PropTypes.arrayOf(teamMemberShape).isRequired,
  onGciLabelClick: PropTypes.func,
  onGciValueChange: PropTypes.func,
  onUnitsChange: PropTypes.func,
  onRemoveTeamMember: PropTypes.func,
  onAddLineItem: PropTypes.func,
  onLineItemDragEnd: PropTypes.func,
  onLineItemOptionChange: PropTypes.func,
  onLineItemValueChange: PropTypes.func,
  onEditLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
  onBrokerageSplitValueChange: PropTypes.func,
  onEditBrokerageSplitLineItem: PropTypes.func,
  onRoyaltyValueChange: PropTypes.func,
  onEditRoyaltyLineItem: PropTypes.func,
};

TeamMembersWithLineItems.defaultProps = {
  onGciLabelClick: () => {},
  onGciValueChange: () => {},
  onUnitsChange: () => {},
  onRemoveTeamMember: () => {},
  onAddLineItem: () => {},
  onLineItemDragEnd: () => {},
  onLineItemOptionChange: () => {},
  onLineItemValueChange: () => {},
  onEditLineItem: () => {},
  onRemoveLineItem: () => {},
  onBrokerageSplitValueChange: () => {},
  onEditBrokerageSplitLineItem: () => {},
  onRoyaltyValueChange: () => {},
  onEditRoyaltyLineItem: () => {},
};

// export default TeamMembersWithLineItems;
export default tdpDetails({
  WrappedComponent: TeamMembersWithLineItems,
});
