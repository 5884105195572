import schemaGenerator from "../v2/utils";

const base = {
  default: [
    "tw-font-regular",
    "tw-border-solid",
    "tw-border",
    "tw-rounded-[2px]",
    "tw-text-left",
    "tw-text-14px",
    "tw-placeholder-[#999999]",
    "tw-px-14px",
    "tw-py-8px",
    "tw-text-neutral-gray-75",
    "tw-bg-theme-text-input",
    "tw-w-full",
  ],
  hover: ["hover:tw-bg-theme-text-input-hover"],
  focus: [
    "focus:tw-bg-white",
    "focus:tw-outline-none",
    "focus:tw-ring-[1.5px]",
    "focus:tw-ring-theme-text-input-active",
  ],
  disabled: ["disabled:tw-text-neutral-gray-15", "disabled:tw-bg-theme-text-input-disabled"],
};

const dictionary = {
  regular: {
    default: [...base.default, "tw-border-theme-text-input"],
    hover: [...base.hover, "hover:tw-border-theme-text-input-hover"],
    focus: [...base.focus, "focus:tw-border-theme-text-input-active", "tw-ring-offset-0"],
    disabled: [...base.disabled, "disabled:tw-border-theme-text-input-disabled"],
  },
  error: {
    default: [...base.default, "tw-border-semantic-red-25"],
    hover: [...base.hover, "hover:tw-border-semantic-red-50"],
    focus: ["focus:tw-border-semantic-red-100", "focus:tw-ring-semantic-red-10", "tw-ring-offset-0"],
    disabled: [...base.disabled, "disabled:tw-border-neutral-gray-15"],
  },
  disabled: {
    default: [...base.default, "tw-border-theme-text-input-disabled", "tw-cursor-default"],
    disabled: [...base.disabled, "disabled:tw-border-theme-text-input-disabled"],
  },
};

const styleSchema = schemaGenerator(dictionary);

export default styleSchema;
