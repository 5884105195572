export default (payloadHandlrs, btnTxt, toggleShow) => {
  const btnTxtKey = btnTxt.toLowerCase();

  const textInputHandlr = (e) => {
    e.persist();
    payloadHandlrs((prev) => ({
      ...prev,
      [btnTxtKey]: e.target.value,
    }));
  };

  const trashHandlr = (e) => {
    e.persist();
    payloadHandlrs((prev) => ({
      ...prev,
      [btnTxtKey]: "",
    }));
    toggleShow((prev) => ({
      ...prev,
      [btnTxtKey]: false,
    }));
  };

  return { textInputHandlr, trashHandlr };
};
