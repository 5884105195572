import React from "react";

import { CalendarDaySolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import PipedElements from "../PipedElements";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const ShowingRequestActivity = ({ activity, onReadChange }) => {
  const { propertyStreetAddress, comment, showingDate, showingTime } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={CalendarDaySolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        {propertyStreetAddress && <span>Address: {propertyStreetAddress}</span>}
        {comment && <span>Comment: {comment}</span>}
        <PipedElements>
          {showingDate && <span>Date: {showingDate}</span>}
          {showingTime && <span>Time: {showingTime}</span>}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

ShowingRequestActivity.propTypes = {
  ...activityCommonPropTypes,
};

ShowingRequestActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default ShowingRequestActivity;
