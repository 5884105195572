export const adaptPeople = (data) => ({
  people: data,
});

export const adaptError = (err) => ({
  error: err,
});

export const closeModalAdapter = (bool) => ({
  closeModal: bool,
});
