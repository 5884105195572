import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@shared/v2/IconButton";
import { PhoneHangupSolidV6, PhoneSolidV6 } from "@shared/v2/Icomoon";
import CallRecordingTooltip from "./CallRecordingTooltip";
import { useOutboundCallRecordingSetting } from "../../reducers/layoutReducer/selectors";
import { useCallState, usePhoneNumber } from "../../reducers/dialerReducer/selectors";
import CALL_STATES from "../utils/states";
import { setCallState } from "../../reducers/dialerReducer/dialerReducer";

const CallButton = () => {
  const dispatch = useDispatch();
  const { outboundNotificationMessage, outboundRecording } = useOutboundCallRecordingSetting();
  const callState = useCallState();
  const phoneNumber = usePhoneNumber();

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-gap-[4px] tw-items-center">
      {callState === CALL_STATES.Init && (
        <>
          <CallRecordingTooltip
            disabled={outboundRecording === "disabled"}
            willNotify={outboundNotificationMessage}
            willRecord={outboundRecording === "automatic"}
          />
          <IconButton
            size="medium"
            schema="solid-green"
            onClick={() => dispatch(setCallState(CALL_STATES.Started))}
            disabled={!phoneNumber || phoneNumber?.meta.callUnsubscribed}
          >
            <PhoneSolidV6 />
          </IconButton>
        </>
      )}
      {callState !== CALL_STATES.Init && (
        <IconButton
          size="medium"
          schema="solid-red"
          onClick={() => dispatch(setCallState(CALL_STATES.Ended))}
          disabled={callState === CALL_STATES.Ended}
        >
          <PhoneHangupSolidV6 size="l" />
        </IconButton>
      )}
    </div>
  );
};

export default CallButton;
