import React from "react";
import Proptypes from "prop-types";

const StageOption = ({ stageOption, selectStage, containerClassName, optionClassName }) => (
  <>
    {stageOption.header && (
      <div className="tw-inline-block tw-border-[1px] tw-border-solid last:tw-border-none tw-border-neutral-gray-30 " />
    )}
    <div
      data-cy="leads-stage-option"
      key={stageOption?.value}
      className={`tw-flex ${
        stageOption.header
          ? "tw-pointer-events-none tw-bg-tinted-gray-50"
          : "tw-bg-white hover:tw-bg-tinted-gray-50 active:tw-bg-gray-lightest"
      }  ${containerClassName}`}
      onClick={selectStage}
    >
      <div
        className={`tw-flex tw-flex-col tw-flex-nowrap tw-grow tw-shrink-0 tw-text-[#666] tw-leading-[16px] tw-text-11px
       tw-font-bold tw-align-middle tw-py-[10px] tw-text-center tw-uppercase ${
         stageOption.header ? "" : "tw-cursor-pointer"
       } ${optionClassName}`}
      >
        {stageOption?.label}
      </div>
    </div>
    {stageOption.header ? (
      <div className="tw-inline-block tw-border-[1px] tw-border-solid last:tw-border-none tw-border-neutral-gray-30 " />
    ) : (
      <div className="tw-inline-block tw-border-[1px] tw-border-solid first:tw-border-none last:tw-border-none tw-border-gray-10 tw-mx-[45px]" />
    )}
  </>
);

StageOption.propTypes = {
  stageOption: Proptypes.shape({
    label: Proptypes.string,
    value: Proptypes.string,
    header: Proptypes.string,
  }),
  containerClassName: Proptypes.string,
  optionClassName: Proptypes.string,
  selectStage: Proptypes.func,
};
StageOption.defaultProps = {
  stageOption: {
    label: "",
    value: "",
    header: "",
  },
  containerClassName: "",
  optionClassName: "",
  selectStage: () => {},
};

export default StageOption;
