/* eslint-disable no-underscore-dangle */
import { last } from "lodash";
import FINANCIALS_UPDATE_TYPE from "./actionNames";
import { positionDelta } from "./orderHelpers";
import transactionExtrasOperations from "./transactionExtraOperations";
import teamMemberOperations from "./teamMemberOperations";
import companyOperations from "./companyOperations";
import { assignIfDifferent, toNumber } from "../../../../shared/Utilities";

const changeTracker = ({ updateType, base, updateData }) => {
  const changeChecks = [];
  const transactionQueries = transactionExtrasOperations(base);
  const teamMemberQueries = teamMemberOperations(base);
  const companyQueries = companyOperations(base);

  const assignIfDifferentAndSaveResult = (obj, path, value) => {
    const didChange = assignIfDifferent(obj, path, value);
    changeChecks.push(didChange);
    return didChange;
  };

  switch (updateType) {
    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_CLOSE_PRICE:
      assignIfDifferentAndSaveResult(
        base,
        "transaction_income.closed_volume",
        toNumber(updateData.closePrice),
      );
      break;

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_COMMISSION:
      assignIfDifferentAndSaveResult(base, "transaction_income.commission", toNumber(updateData.commission));
      assignIfDifferentAndSaveResult(
        base,
        "transaction_income.commission_percentage",
        updateData.commissionType === "percent",
      );
      assignIfDifferentAndSaveResult(base, "transaction_income.commission_notes", updateData.notes);
      break;

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_COMMISSION_VALUE:
      assignIfDifferentAndSaveResult(base, "transaction_income.commission", toNumber(updateData.commission));
      assignIfDifferentAndSaveResult(
        base,
        "transaction_income.commission_percentage",
        updateData.commissionType === "percent",
      );
      break;

    case FINANCIALS_UPDATE_TYPE.ADD_TRANSACTION_LINE_ITEM: {
      const selectedType = updateData.selectedOption.meta.type.replace("income", "revenue");
      transactionQueries.collectionFor(selectedType).push({
        type: selectedType,
        transaction_expense_type_id: updateData.selectedOption.value,
        name: updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
        value: toNumber(updateData.value),
        percentage: updateData.valueType === "percent",
        notes: updateData.notes,
        position: transactionQueries.nextPosition(),
      });
      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.ADD_COMPANY_LINE_ITEM: {
      const selectedType = updateData.selectedOption.meta.type.replace("income", "revenue");
      companyQueries.collectionFor(selectedType).push({
        [`company_${selectedType}_type_id`]: updateData.selectedOption.value,
        [`${selectedType}_amount`]: toNumber(updateData.value),
        name: updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
        value: toNumber(updateData.value),
        percentage: updateData.valueType === "percent",
        notes: updateData.notes,
        position: companyQueries.nextPosition(),
      });
      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM: {
      const lineItem = transactionQueries.find({ type: updateData.type, id: updateData.id });
      assignIfDifferentAndSaveResult(
        lineItem,
        "transaction_expense_type_id",
        updateData.selectedOption.value,
      );
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      assignIfDifferentAndSaveResult(lineItem, "notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM: {
      const selectedType = updateData.selectedOption.meta.type.replace("income", "revenue");
      const lineItem = companyQueries.find({ type: updateData.type, id: updateData.id });
      assignIfDifferentAndSaveResult(
        lineItem,
        `company_${selectedType}_type_id`,
        updateData.selectedOption.value,
      );
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      assignIfDifferentAndSaveResult(lineItem, "notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_TYPE: {
      const lineItem = transactionQueries.find({ type: updateData.type, id: updateData.id });
      const selectedType = updateData.selectedOption.meta.type.replace("income", "revenue");
      const didTypeChange = assignIfDifferentAndSaveResult(lineItem, "type", selectedType);
      assignIfDifferentAndSaveResult(
        lineItem,
        "transaction_expense_type_id",
        updateData.selectedOption.value,
      );
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );

      // If the type changed, we need to remove the expense/income from one array
      // and add it to the other
      if (didTypeChange) {
        transactionQueries.collectionFor(selectedType).push({
          ...lineItem,
          // Remove the id, since a new one must be created
          id: undefined,
        });
        // eslint-disable-next-line no-underscore-dangle
        lineItem._destroy = true;
        changeChecks.push(true);
      }
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_TYPE: {
      const lineItem = companyQueries.find({ type: updateData.type, id: updateData.id });
      const selectedType = updateData.selectedOption.meta.type.replace("income", "revenue");
      const didTypeChange = assignIfDifferentAndSaveResult(lineItem, "type", selectedType);
      assignIfDifferentAndSaveResult(
        lineItem,
        `company_${selectedType}_type_id`,
        updateData.selectedOption.value,
      );
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );

      // If the type changed, we need to remove the expense/income from one array
      // and add it to the other
      if (didTypeChange) {
        companyQueries.collectionFor(selectedType).push({
          ...lineItem,
          // Remove the id, since a new one must be created
          id: undefined,
        });
        // eslint-disable-next-line no-underscore-dangle
        lineItem._destroy = true;
        changeChecks.push(true);
      }
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_VALUE: {
      const lineItem = transactionQueries.find({ type: updateData.type, id: updateData.id });
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_VALUE: {
      const lineItem = companyQueries.find({ type: updateData.type, id: updateData.id });
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TRANSACTION_LINE_ITEM_POSITION: {
      if (updateData.from !== updateData.to) {
        const combinedCollections = transactionQueries.combinedCollections();
        const deltas = combinedCollections.map((lineItem) =>
          positionDelta({ current: lineItem.position, from: updateData.from, to: updateData.to }),
        );
        combinedCollections.forEach((lineItem, index) => {
          // In place updating, as the rest of the actions
          // eslint-disable-next-line no-param-reassign
          lineItem.position += deltas[index];
        });
        // We don't need to reorder the line items for transactions,
        // because the positions are not affected by the full recalculation,
        // but we do it anyway to keep it consistent with the classic
        transactionQueries.reorderCollections();
        changeChecks.push(true);
      }
      break;
    }

    case FINANCIALS_UPDATE_TYPE.REMOVE_TRANSACTION_LINE_ITEM: {
      const lineItem = transactionQueries.find({ type: updateData.type, id: updateData.id });
      // eslint-disable-next-line no-underscore-dangle
      lineItem._destroy = true;
      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.REMOVE_COMPANY_LINE_ITEM: {
      const lineItem = companyQueries.find({ type: updateData.type, id: updateData.id });
      // eslint-disable-next-line no-underscore-dangle
      lineItem._destroy = true;
      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_GCI: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "agent_gci", toNumber(updateData.gci));
      assignIfDifferentAndSaveResult(teamMember, "agent_gci_percentage", updateData.gciType === "percent");
      assignIfDifferentAndSaveResult(teamMember, "agent_gci_notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_GCI: {
      assignIfDifferentAndSaveResult(base, "company_income.agent_gci", toNumber(updateData.gci));
      assignIfDifferentAndSaveResult(
        base,
        "company_income.agent_gci_percentage",
        updateData.gciType === "percent",
      );
      assignIfDifferentAndSaveResult(base, "company_income.agent_gci_notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_GCI_VALUE: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "agent_gci", toNumber(updateData.gci));
      assignIfDifferentAndSaveResult(teamMember, "agent_gci_percentage", updateData.gciType === "percent");
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_GCI_VALUE: {
      assignIfDifferentAndSaveResult(base, "company_income.agent_gci", toNumber(updateData.gci));
      assignIfDifferentAndSaveResult(
        base,
        "company_income.agent_gci_percentage",
        updateData.gciType === "percent",
      );
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_UNITS: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "agent_gci_units", toNumber(updateData.units));
      break;
    }

    case FINANCIALS_UPDATE_TYPE.ADD_TEAM_MEMBER_LINE_ITEM: {
      const AGENT_TYPE_MAP = {
        expense: "expense",
        income: "revenue",
      };
      const { type } = updateData.selectedOption.meta;
      const agentMappedType = AGENT_TYPE_MAP[type];

      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      const collection = teamMemberQueries.reorderCombinedCollections(teamMember);

      teamMemberQueries.extrasForType(teamMember, `${agentMappedType}s`).push({
        [`agent_${agentMappedType}_type_id`]: updateData.selectedOption.value,
        [`${agentMappedType}_amount`]: toNumber(updateData.value),
        name: updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
        value: toNumber(updateData.value),
        percentage: updateData.valueType === "percent",
        notes: updateData.notes,
        position: last(collection).position + 1,
      });

      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM: {
      // TODO: HERE
      const AGENT_TYPE_MAP = {
        expense: "expense",
        income: "revenue",
      };

      const agentMappedType = AGENT_TYPE_MAP[updateData.type];
      const lineItem = teamMemberQueries.findExtraForType({
        memberId: updateData.memberId,
        id: updateData.id,
        type: `${agentMappedType}s`,
      });
      assignIfDifferentAndSaveResult(
        lineItem,
        `agent_${agentMappedType}_type_id`,
        updateData.selectedOption.value,
      );
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      assignIfDifferentAndSaveResult(lineItem, "notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_TYPE: {
      const lineItem = teamMemberQueries.findExtraForType({
        memberId: updateData.memberId,
        id: updateData.id,
      });
      assignIfDifferentAndSaveResult(lineItem, "agent_expense_type_id", updateData.selectedOption.value);
      assignIfDifferentAndSaveResult(
        lineItem,
        "name",
        updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
      );
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_VALUE: {
      const lineItem = teamMemberQueries.findExtraFor({ memberId: updateData.memberId, id: updateData.id });
      assignIfDifferentAndSaveResult(lineItem, "value", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(lineItem, "percentage", updateData.valueType === "percent");
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_POSITION: {
      if (updateData.from !== updateData.to) {
        const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });

        const combinedCollections = teamMemberQueries.reorderCombinedCollections(teamMember);

        const elementToMove = combinedCollections.splice(updateData.from - 1, 1)[0];

        combinedCollections.splice(updateData.to - 1, 0, elementToMove);
        combinedCollections.forEach((acc, i) => {
          acc.position = i + 1;
        });

        changeChecks.push(true);
      }
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_COMPANY_LINE_ITEM_POSITION: {
      if (updateData.from !== updateData.to) {
        const combinedCollections = companyQueries.combinedCollections();
        const deltas = combinedCollections.map((lineItem) =>
          positionDelta({ current: lineItem.position, from: updateData.from, to: updateData.to }),
        );
        combinedCollections.forEach((lineItem, index) => {
          // In place updating, as the rest of the actions
          // eslint-disable-next-line no-param-reassign
          lineItem.position += deltas[index];
        });
        // We don't need to reorder the line items for transactions,
        // because the positions are not affected by the full recalculation,
        // but we do it anyway to keep it consistent with the classic
        companyQueries.reorderCollections();
        changeChecks.push(true);
      }
      break;
    }

    case FINANCIALS_UPDATE_TYPE.REMOVE_TEAM_MEMBER_LINE_ITEM: {
      const AGENT_TYPE_MAP = {
        expense: "expense",
        income: "revenue",
      };

      const member = teamMemberQueries.find({ memberId: updateData.memberId });
      const agentMappedType = AGENT_TYPE_MAP[updateData.type];
      const lineItem = teamMemberQueries.findExtraForType({
        memberId: updateData.memberId,
        id: updateData.id,
        type: `${agentMappedType}s`,
      });

      // eslint-disable-next-line no-underscore-dangle
      lineItem._destroy = true;
      teamMemberQueries.reconcilePositionAfterDestroy(member);
      changeChecks.push(true);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "brokerage_split", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(
        teamMember,
        "brokerage_split_percentage",
        updateData.valueType === "percent",
      );
      assignIfDifferentAndSaveResult(teamMember, "brokerage_split_notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_BROKERAGE_SPLIT_VALUE: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "brokerage_split", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(
        teamMember,
        "brokerage_split_percentage",
        updateData.valueType === "percent",
      );
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_ROYALTY: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "royalty", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(teamMember, "royalty_percentage", updateData.valueType === "percent");
      assignIfDifferentAndSaveResult(teamMember, "royalty_notes", updateData.notes);
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_ROYALTY_VALUE: {
      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });
      assignIfDifferentAndSaveResult(teamMember, "royalty", toNumber(updateData.value));
      assignIfDifferentAndSaveResult(teamMember, "royalty_percentage", updateData.valueType === "percent");
      break;
    }

    case FINANCIALS_UPDATE_TYPE.UPDATE_TEAM_MEMBER_LINE_ITEM_ACC: {
      const AGENT_TYPE_MAP = {
        expense: "expense",
        income: "revenue",
      };
      const { type } = updateData.selectedOption.meta;
      const agentMappedType = AGENT_TYPE_MAP[type];

      const teamMember = teamMemberQueries.find({ memberId: updateData.memberId });

      teamMemberQueries.extrasForType(teamMember, `${agentMappedType}s`).push({
        [`agent_${agentMappedType}_type_id`]: updateData.selectedOption.value,
        [`${agentMappedType}_amount`]: toNumber(updateData.value),
        name: updateData.selectedOption.meta.isCustomOption
          ? updateData.selectedOption.meta.name
          : updateData.selectedOption.label,
        value: toNumber(updateData.value),
        percentage: updateData.valueType === "percent",
        notes: updateData.notes,
        position: updateData.editingLineItem.lineItem.position,
      });

      const destroyableLineItem = teamMemberQueries.findExtraForType({
        memberId: updateData.memberId,
        id: updateData.editingLineItem.lineItem.id,
        type: `${AGENT_TYPE_MAP[updateData.editingLineItem.lineItem.expenseOrIncome]}s`,
      });

      destroyableLineItem._destroy = true;
      changeChecks.push(true);

      break;
    }

    case FINANCIALS_UPDATE_TYPE.ADD_TEAM_MEMBERS:
      teamMemberQueries
        .collection()
        .push(
          ...updateData.selectedMembers.map((selectedMember) =>
            teamMemberQueries.actions.buildNewTeamMember({ base, selectedMember }),
          ),
        );
      changeChecks.push(true);
      break;

    default:
      break;
  }

  const didDataChange = changeChecks.find((changeResult) => changeResult === true);
  return [didDataChange, base];
};

export default changeTracker;
