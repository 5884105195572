import React, { useEffect } from "react";
import PropTypes from "prop-types";

export const ModalContext = React.createContext({});

const ModalContextProvider = ({ show, onHide, size, variant, children }) => {
  const [isShown, setIsShown] = React.useState(show);

  const onModalHide = () => {
    setIsShown(false);
    if (onHide) onHide();
  };

  const onEscapeKeyPress = (e) => {
    if (e.key === "Escape") onModalHide();
  };

  useEffect(() => {
    if (!document) return;

    const addListener = () => {
      document.addEventListener("keyup", onEscapeKeyPress);
      document.body.classList.add("tw-overflow-hidden");
    };

    const removeListener = () => {
      document.removeEventListener("keyup", onEscapeKeyPress);
      document.body.classList.remove("tw-overflow-hidden");
    };

    if (isShown) {
      addListener();
    } else {
      removeListener();
    }

    // We only need to return a cleanup function once we actually attach the event listener
    // eslint-disable-next-line consistent-return
    return removeListener;
  }, [isShown]);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  return (
    <ModalContext.Provider
      value={{
        show: isShown,
        onHide: onModalHide,
        size,
        variant,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
ModalContextProvider.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  size: PropTypes.oneOf(["small", "sm", "large", "lg", "default"]).isRequired,
  variant: PropTypes.oneOf(["classic", "modern"]).isRequired,
  children: PropTypes.node,
};
ModalContextProvider.defaultProps = {
  show: false,
  onHide: null,
  children: null,
};

export default ModalContextProvider;
