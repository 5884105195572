/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BoxArchiveSolidV6 = (props) => (
  <Icon {...props} name="Box Archive Solid V6">
    <path d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5V5H1v8.5zm4-6.125C5 7.169 5.169 7 5.375 7h5.25c.206 0 .375.169.375.375v.25a.376.376 0 0 1-.375.375h-5.25A.376.376 0 0 1 5 7.625v-.25zM15 1H1a1 1 0 0 0-1 1v1.5c0 .275.225.5.5.5h15c.275 0 .5-.225.5-.5V2a1 1 0 0 0-1-1z" />
  </Icon>
);

export default BoxArchiveSolidV6;
