import React, { useMemo } from "react";
import { Provider } from "react-redux";

import SearchListings from "../components/SearchListings";

export default ({
  idxDomain,
  brokerImage,
  sender,
  current_user_id,
  team_ids,
  agentDetailPageEnabled
}) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  return (
    <Provider store={store}>
      <SearchListings
        idxDomain={idxDomain}
        brokerImage={brokerImage}
        sender={sender}
        current_user_id={current_user_id}
        team_ids={team_ids}
        agentDetailPageEnabled={agentDetailPageEnabled}
      />
    </Provider>
  )
}