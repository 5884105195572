import { useDispatch } from "react-redux";
import { sendInviteEmail, patchSuspendUserApiFn } from "../api";
import { tryCatchHandlr } from "../../../Transactions/components/Notes/helpers/helper";
import { setErrorOktaBtn, setLoadingOktaBtn, setSuccessOktaBtn, clearOktaBtn } from "./default";

export const saveSuspendingPerson = async (
  userId,
  action,
  onSuccess = () => {},
  onError = () => {},
  onLoading = () => {},
) => {
  onLoading();
  const [res, err] = await tryCatchHandlr(patchSuspendUserApiFn(userId, action));
  if (res) {
    onSuccess(res.data);
    return;
  }

  onError(err);
};

// eslint-disable-next-line import/prefer-default-export
export const useSendInviteEmail = () => {
  const dispatch = useDispatch();

  return async (userId) => {
    dispatch(setLoadingOktaBtn(userId));
    const [res] = await tryCatchHandlr(sendInviteEmail(userId));

    const dispatchedAction = res ? setSuccessOktaBtn : setErrorOktaBtn;
    dispatch(dispatchedAction(userId));

    setTimeout(() => dispatch(clearOktaBtn()), 5000);
  };
};
