import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_TASK = "tdpTask";
const wrap = curriedNamespaceAction(REDUCER_TDP_TASK);

export const saveAllTasksAndFilters = (data) => wrap({ type: "SAVE_ALL_TASKS_AND_FILTERS", data });

export const saveMoreTasks = (moreTasks) =>
  wrap({
    type: "SAVE_MORE_TASKS",
    moreTasks,
  });

export const saveSpecificTasks = (tasks, countOfActiveTasks, urlParams) =>
  wrap({
    type: "SAVE_SPECIFIC_TASKS",
    tasks,
    urlParams,
    countOfActiveTasks,
  });

export const filterTasksByPrimaryFilter = (primaryFilters) =>
  wrap({
    type: "FILTER_TASKS",
    primaryFilters,
  });

export const filterTasksByTeamMembers = (member) =>
  wrap({
    type: "FILTER_TASKS_BY_TEAM_MEMBERS",
    member,
  });

export const filterTasksByAutoPlans = (autoPlan) =>
  wrap({
    type: "FILTER_TASKS_BY_AUTO_PLANS",
    autoPlan,
  });

export const createTasksByTeamMemberFilter = () =>
  wrap({
    type: "CREATE_TASKS_BY_TEAM_MEMBERS_FILTER",
  });

export const createTasksByAutoPlansFilter = () =>
  wrap({
    type: "CREATE_TASKS_BY_AUTO_PLAN_FILTER",
  });

export const createTasksByUnassignedFilter = () =>
  wrap({
    type: "CREATE_TASKS_BY_UNASSIGNED_FILTER",
  });

export const clearTeamMemberFilter = () =>
  wrap({
    type: "CLEAR_TEAM_MEMBER_FILTER",
  });

export const clearAutoPlanFilter = () =>
  wrap({
    type: "CLEAR_AUTO_PLAN_FILTER",
  });

export const setBackFilterByTeamMembers = () =>
  wrap({
    type: "SET_BACK_TEAM_MEMBERS",
  });

export const setBackFilterByAutoPlans = () =>
  wrap({
    type: "SET_BACK_AUTO_PLANS",
  });

export const clearSecondaryFilter = () =>
  wrap({
    type: "CLEAR_SECONDARY_FILTER",
  });

export const togglePrimaryFilter = (primaryFilter) =>
  wrap({
    type: "TOGGLE_PRIMARY_FILTER",
    primaryFilter,
  });

export const saveTeam = (team) =>
  wrap({
    type: "SAVE_TEAM",
    team,
  });

export const addCreatedTasks = (task) =>
  wrap({
    type: "ADD_CREATED_TASK",
    task,
  });

export const deleteTask = (ids) =>
  wrap({
    type: "DELETE_TASK",
    ids,
  });

export const editTasks = (tasks) =>
  wrap({
    type: "EDIT_TASK",
    tasks,
  });

export const toggleModalAddTaskOpen = (bl) =>
  wrap({
    type: "TOGGLE_MODAL_ADD_TASK",
    bl,
  });

export const toggleTasksSelectionMode = () =>
  wrap({
    type: "TOGGLE_TASKS_SELECTION_MODE",
  });

export const taskSelect = (taskId) =>
  wrap({
    type: "TASK_SELECT",
    taskId,
  });

export const selectAllTasks = () =>
  wrap({
    type: "SELECT_ALL_TASKS",
  });

export const clearSelectedTasks = () =>
  wrap({
    type: "CLEAR_SELECTED_TASKS",
  });

export const setTaskModalBulkDelete = () =>
  wrap({
    type: "SET_TASK_MODAL_BULK_DELETE",
  });

export const setModalBulkRescheduleTaskOpen = () =>
  wrap({
    type: "SET_TASK_MODAL_BULK_RESCHEDULE",
  });

export const setModalBulkReassignTaskOpen = () =>
  wrap({
    type: "SET_TASK_MODAL_BULK_REASSIGN",
  });

export const clearTasksAndFilters = () =>
  wrap({
    type: "CLEAR_ACTIVE_TASKS",
  });
