import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { kebabCase } from "lodash";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import FieldLabel from "@shared/v2/FieldLabel";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import { createPost } from "../../reducers/websiteReducer/websiteReducer";

const CreatePostModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [post, setPost] = useState({ title: "" });

  const slug = useMemo(() => `/${kebabCase(post.title.replace(/[^\w\s-]+/g, ""))}`, [post.title]);

  useEffect(() => {
    if (open) {
      setError("");
      setPost({ title: "" });
    }
  }, [open]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="Create New Post" closeButton={!loading} />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
        <TextInput
          autoComplete="off"
          label="Post Title"
          isRequired
          maxLength={256}
          value={post.title}
          onChange={(e) => {
            const { value } = e.target;
            setPost((o) => ({ ...o, title: value }));
          }}
          helperText={error ? "" : `${post.title.trim().length} / 256 characters`}
          error={error}
        />
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <FieldLabel
            label={
              <>
                Slug{" "}
                <Tooltip
                  innerClassName="tw-max-w-[200px]"
                  trigger={<CircleInfoSolidV6 />}
                  content="Site visitors will be able to see your post at this URL after publishing. Tip: You can edit your slug in the post editor!"
                />
              </>
            }
          />
          {slug || "Enter a title to view the page slug"}
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button schema="tertiary" size="medium" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          schema="primary"
          size="medium"
          disabled={!post.title.trim()}
          onClick={() => {
            setLoading(true);
            dispatch(createPost(post)).then(({ error: err, payload }) => {
              setLoading(false);
              if (err) setError("A post with this title already exists! Please choose a unique title.");
              else navigate(`/post/${payload.uuid}`);
            });
          }}
          isLoading={loading}
        >
          Create Post
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreatePostModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreatePostModal;
