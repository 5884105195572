/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowTurnDownRightSolidV6 = (props) => (
  <Icon {...props} name="Arrow Turn Down Right Solid V6">
    <path d="M10.294 13.706c-.197-.194-.294-.45-.294-.706s.097-.513.294-.706L12.585 10H2.501a2.503 2.503 0 0 1-2.5-2.5V2a1 1 0 1 1 2 0v5.5c0 .275.225.5.5.5h10.084l-2.291-2.294c-.391-.391-.391-1.022 0-1.413s1.022-.391 1.413 0l4 4a.998.998 0 0 1 0 1.413l-4 4a.998.998 0 0 1-1.413 0z" />
  </Icon>
);

export default ArrowTurnDownRightSolidV6;
