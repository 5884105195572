import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@shared/v2/IconButton";
import Edit from "@shared/v2/Icomoon/Icons/Edit";
import EditAgreementModal from "./EditAgreementModal/EditAgreementModal";

const AgreementSection = ({ agreement, onDelete, person }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const onClickEditAgreement = () => {
    setIsEditModalOpen(true);
  };
  const agreementCloseModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div>
      <div
        key={agreement.id}
        className="tw-relative tw-shadow-tdp-card tw-overflow-hidden tw-flex tw-flex-col tw-gap-[12px] tw-rounded-[4px] tw-py-[8px] tw-px-[12px]"
      >
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[12px] tw-overflow-hidden">
          <div>
            <div className="tw-text-[12px] tw-leading-[16px] tw-font-normal tw-flex-1 tw-capitalize tw-relative tw-overflow-hidden">{`${agreement.agreementType} Agreement`}</div>
            {agreement.listing && agreement.listing?.value && (
              <div className="tw-pt-[4px] tw-text-[12px] tw-text-gray-50 tw-leading-[16px] tw-font-normal tw-flex-1 tw-capitalize tw-relative tw-overflow-hidden">{`For ${agreement.listing.label}`}</div>
            )}
            {agreement.status && agreement.status !== "none" && (
              <div className="tw-pt-[4px] tw-text-[11px] tw-leading-[16px] tw-font-bold tw-flex-1 tw-uppercase tw-relative tw-overflow-hidden">
                {agreement.status === "active" && (
                  <span className="tw-text-semantic-green-110">
                    {`${agreement.status} ${agreement.expirationDate ? `until ${moment(agreement.expirationDate).format("MM/DD/YYYY")}` : ""}`}
                  </span>
                )}
                {agreement.status === "completed" && (
                  <span className="tw-semantic-blue-100"> {`${agreement.status}`}</span>
                )}
                {agreement.status === "expired" && (
                  <span className="tw-text-[#E5941B]">
                    {`${agreement.status} ${agreement.expirationDate ? `on ${moment(agreement.expirationDate).format("MM/DD/YYYY")}` : ""}`}
                  </span>
                )}
                {agreement.status === "cancelled" && (
                  <span className="tw-text-semantic-red-110">
                    {`canceled ${agreement.cancellationDate ? `on ${moment(agreement.cancellationDate).format("MM/DD/YYYY")}` : ""}`}
                  </span>
                )}
              </div>
            )}
          </div>

          <div>
            <IconButton
              size="small"
              schema="tertiary"
              onClick={onClickEditAgreement}
              data-cy="document-list-item-delete-button"
            >
              <Edit />
            </IconButton>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <EditAgreementModal
          person={person}
          agreement={agreement}
          modalOpen={isEditModalOpen}
          onDelete={onDelete}
          closeModal={agreementCloseModal}
        />
      )}
    </div>
  );
};

AgreementSection.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.number,
    agreementType: PropTypes.string,
  }),
  person: PropTypes.shape({}),
  onDelete: PropTypes.func,
};
AgreementSection.defaultProps = {
  agreement: {},
  person: {},
  onDelete: null,
};
export default AgreementSection;
