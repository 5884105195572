import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { tdpDetails } from "../../../../../shared/HOCs/withRedux";
import { STATUS, TYPE } from "../../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { isReferral as isReferralHelper } from "../../../../shared/helpers";

import { Plus as PlusIcon, Edit as EditIcon } from "../../../../../shared/v2/Icomoon";
import Toggle from "../../../../../shared/v2/Toggle";
import InlineEditingInput from "../../../../../shared/v2/InlineEditingInput";
import IconButton from "../../../../../shared/v2/IconButton";
import { formatNumber } from "../../../../../shared/v2/inputFormatUtils";
import Tooltip from "../../../../../shared/v2/Tooltip";
import { transactionShape } from "../../utils";
import ExpensesIncomeLineItems from "./ExpensesIncomeLineItems";

const transactionNames = () => ({
  default: "Transaction Income",
  referral: "Referral Income",
});

const commissionNames = (actual) => ({
  default: actual ? "Commission" : "Est. Commission",
  referral: "Referral Commission",
});

const transactionName = (type, actual) => transactionNames(actual)[type] || transactionNames(actual).default;

const commissionName = (type, actual) => commissionNames(actual)[type] || commissionNames(actual).default;

const formatNumberIfFlat = (value, type, commas) => (type === "flat" ? formatNumber(value, commas) : value);

/**
 * @summary TDP Financials Transaction GCI component.
 * @param {func} onEstCommissionLabelClick - callback to be called when the est commission label is clicked.
 * @param {func} onCommissionLabelClick - callback to be called when the commission label is clicked.
 * @param {func} onCommissionValueChange - callback to be called when the commission value is changed.
 * @param {func} onClosedPriceChange - callback to be called when the closed price value is changed.
 * @param {func} onAddExpenses - callback to be called when the add expenses icon is clicked.
 * @param {func} onAddIncome - callback to be called when the add income icon is clicked.
 * @param {object} transaction - transaction object.
 * */

export const TransactionGci = ({
  onEstCommissionLabelClick,
  onCommissionLabelClick,
  onCommissionValueChange,
  onClosedPriceChange,
  onAddExpenses,
  onAddIncome,
  transaction,
  isReferral,
  status,
  transactionType,
  onLineItemDragEnd,
  onEditExpenseLineItem,
  onEditIncomeLineItem,
  onRemoveLineItem,
  onLineItemOptionChange,
  onLineItemValueChange,
}) => {
  const [commissionValueType, setCommissionValueType] = useState(transaction.actual.commission.type);

  const editableCommission = transaction.hasFinancialData;

  // TODO Product Requirement: isEstimated is true by default. Confirm if the user can toggle

  const internalIsActualGCI = status === "sold" || isReferral;

  useEffect(() => {
    setCommissionValueType(transaction.actual.commission.type);
  }, [transaction]);

  // TODO #2, kinda related to the above: should we be showing expenses and
  // incomes if we're looking at the estimated values?

  // Subtotal and Net Commission might not be filled in yet, so we fall back to
  // a placeholder in those cases.
  const subtotalValue = transaction.actual.subtotal;
  const subtotalString = subtotalValue ? `$${formatNumber(subtotalValue, true)}` : "—";
  const subtotalTextColor = subtotalValue ? "tw-text-neutral-gray-75" : "tw-text-neutral-gray-30";

  const netCommissionValue = transaction.actual.netCommission;
  const netCommissionString = netCommissionValue ? `$${formatNumber(netCommissionValue, true)}` : "—";
  const netCommissionTextColor = netCommissionValue ? "tw-text-neutral-gray-75" : "tw-text-neutral-gray-30";

  const grossCommissionValue = transaction.actual.grossCommission;
  const grossCommissionString = grossCommissionValue ? `$${formatNumber(grossCommissionValue, true)}` : "—";
  const grossCommissionTextColor = grossCommissionValue
    ? "tw-text-neutral-gray-75"
    : "tw-text-neutral-gray-30";

  let CommissionInput;
  if (isReferral) {
    CommissionInput = InlineEditingInput.Flat;
  } else {
    CommissionInput = transaction.showClosePrice ? InlineEditingInput.FlatPercent : InlineEditingInput.Flat;
  }

  const additionalCommissionInputProps = transaction.showClosePrice
    ? {
        flatOrPercent: commissionValueType,
        onFlatSelected: () => setCommissionValueType("flat"),
        onPercentSelected: () => setCommissionValueType("percent"),
      }
    : {};

  return (
    <div className="tw-relative tw-bg-white tw-rounded-4px" data-cy="financials-transaction">
      <div className="tw-bg-tinted-gray-50 tw-relative tw-z-10 tw-group tw-flex tw-flex-row tw-items-center tw-h-58px tw-select-none tw-border tw-border-neutral-gray-10 tw-border-solid tw-rounded-4px tw-px-24px tw-py-9px">
        <p className="tw-mb-0 tw-text-18d tw-text-neutral-gray-75">
          {transactionName(transactionType, internalIsActualGCI)}
        </p>
        {false && (
          // Hide away for now, as the estimated not a part of the TDP's MVP.
          <div className="tw-ml-auto tw-flex tw-flex-row tw-items-center">
            <span
              className={`tw-text-14d tw-font-semibold ${
                internalIsActualGCI ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-50"
              }`}
            >
              Estimated
            </span>
            <Toggle
              labelClassName="tw-mb-0 tw-mx-12px"
              checked={internalIsActualGCI}
              size="small"
              id="financials-gci-toggle"
            />
            <span
              className={`tw-text-14d tw-font-semibold ${
                internalIsActualGCI ? "tw-text-neutral-gray-50" : "tw-text-neutral-gray-30"
              }`}
            >
              Actual
            </span>
          </div>
        )}
      </div>
      <div className="tw-relative tw--top-3px tw-z-5 tw-px-24px tw-pb-16px tw-pt-18px tw-bg-white tw-rounded-4px tw-rounded-t-none tw-border-t-0 tw-border tw-border-neutral-gray-10 tw-border-solid tw-bt-0">
        <div className="tw-flex tw-flex-row tw-justify-between">
          {transaction.showClosePrice && !isReferralHelper(transactionType) && (
            <div
              // tw-basis is necessary to avoid a layout change when toggling
              // between estimated and actual values due to bigger/smaller content
              className="tw-flex tw-flex-col tw-text-left tw-basis-1/3"
            >
              <div className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-0">
                {internalIsActualGCI ? "Close Price" : "Est. Close Price"}
              </div>
              <div
                className="tw-flex before:tw-relative tw-h-36px"
                data-cy="financials-transaction-close-price"
              >
                <InlineEditingInput.Flat
                  value={formatNumber(transaction.actual.closePrice.value, true)}
                  placeholder="0.00"
                  className="tw-absolute"
                  displayClassName="tw-h-36px"
                  onChange={onClosedPriceChange}
                />
              </div>
            </div>
          )}
          <div className="tw-flex tw-flex-col tw-text-center">
            <div
              className="tw-group tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-0 tw-pr-6px"
              data-cy="financials-transaction-commission-label"
            >
              {commissionName(transactionType, internalIsActualGCI)}

              {editableCommission && (
                <span className="tw-absolute tw-invisible tw-ml-6px group-hover:tw-visible">
                  <IconButton
                    size="small"
                    schema="tertiary"
                    onClick={() =>
                      internalIsActualGCI ? onCommissionLabelClick() : onEstCommissionLabelClick()
                    }
                    data-cy="financials-transaction-edit-commission"
                  >
                    <EditIcon size="m" />
                  </IconButton>
                </span>
              )}
            </div>
            <div className="tw-flex tw-relative tw-h-36px" data-cy="financials-transaction-commission">
              {editableCommission ? (
                <CommissionInput
                  value={formatNumberIfFlat(transaction.actual.commission.value, commissionValueType, true)}
                  placeholder="0.00"
                  className="tw-absolute"
                  displayClassName="tw-h-36px"
                  onChange={(newValue) => onCommissionValueChange(newValue, commissionValueType)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...additionalCommissionInputProps}
                />
              ) : (
                <Tooltip
                  placement="top"
                  trigger={
                    <InlineEditingInput.FlatPercent
                      flatOrPercent={commissionValueType}
                      value={formatNumberIfFlat(
                        transaction.actual.commission.value,
                        commissionValueType,
                        true,
                      )}
                      placeholder="0.00"
                      displayClassName="tw-h-36px"
                      disabled
                    />
                  }
                  content={`
                    Missing "${internalIsActualGCI ? "Close Price" : "Est. Close Price"}" to allow editing ${
                      internalIsActualGCI ? "Commission" : "Est. Commission"
                    }.
                  `}
                />
              )}
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-text-right tw-basis-1/3">
            <div className="tw-text-14d tw-mb-8px tw-text-neutral-gray-50 tw-font-semibold tw-inline">
              Subtotal
            </div>
            <div className={`${subtotalTextColor} tw-font-regular`} data-cy="financials-transaction-subtotal">
              {subtotalString}
            </div>
          </div>
        </div>
        {transaction.notes && (
          <p
            className="tw-text-12d tw-font-normal tw-pt-5px tw-text-neutral-gray-75"
            data-cy="financials-transaction-notes"
          >
            {transaction.notes}
          </p>
        )}
        <hr
          className={`tw-border-t tw-border-solid tw-border-gray-10 tw-mb-18px ${
            transaction.notes ? "tw-mt-16px" : "tw-mt-10px"
          }`}
        />
        <div className="tw-flex tw-flex-row tw-justify-between tw-mb-10px">
          <div className="tw-font-semibold tw-text-gray-75">Income</div>
          <div>
            {transaction.hasFinancialData ? (
              <IconButton
                onClick={onAddIncome}
                size="small"
                schema="tertiary"
                data-cy="financials-transaction-add-line-item-income"
              >
                <PlusIcon size="s" />
              </IconButton>
            ) : (
              <Tooltip
                placement="top"
                trigger={
                  <IconButton
                    disabled
                    size="small"
                    schema="tertiary"
                    data-cy="financials-transaction-add-line-item-income"
                  >
                    <PlusIcon size="s" />
                  </IconButton>
                }
                content={`
                  Missing "${
                    internalIsActualGCI ? "Close Price" : "Est. Close Price"
                  }" to allow adding expenses & income.
                `}
              />
            )}
          </div>
        </div>
        <div className="tw-mb-16px tw--mx-12px">
          <ExpensesIncomeLineItems
            isExpensesLine={false}
            transaction={transaction}
            onLineItemDragEnd={onLineItemDragEnd}
            onEditLineItem={onEditIncomeLineItem}
            onRemoveLineItem={onRemoveLineItem}
            onLineItemOptionChange={onLineItemOptionChange}
            onLineItemValueChange={onLineItemValueChange}
          />
        </div>
        <hr className="tw-border-t tw-border-solid tw-border-gray-10 tw-mt-10px tw-mb-18px" />

        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-font-semibold tw-text-gray-75">Gross Commission</div>
          <div
            className={`${grossCommissionTextColor} tw-font-semibold`}
            data-cy="financials-transaction-net-commission"
          >
            {grossCommissionString}
          </div>
        </div>
        <hr className="tw-border-t tw-border-solid tw-border-gray-10 tw-my-18px" />

        <div className="tw-flex tw-flex-row tw-justify-between tw-mb-10px">
          <div className="tw-font-semibold tw-text-gray-75">Expenses</div>
          <div>
            {transaction.hasFinancialData ? (
              <IconButton
                onClick={onAddExpenses}
                size="small"
                schema="tertiary"
                data-cy="financials-transaction-add-line-item-expense"
              >
                <PlusIcon size="s" />
              </IconButton>
            ) : (
              <Tooltip
                placement="top"
                trigger={
                  <IconButton
                    disabled
                    size="small"
                    schema="tertiary"
                    data-cy="financials-transaction-add-line-item-expense"
                  >
                    <PlusIcon size="s" />
                  </IconButton>
                }
                content={`
                  Missing "${
                    internalIsActualGCI ? "Close Price" : "Est. Close Price"
                  }" to allow adding expenses & income.
                `}
              />
            )}
          </div>
        </div>
        <div className="tw-mb-16px tw--mx-12px">
          <ExpensesIncomeLineItems
            isExpensesLine
            transaction={transaction}
            onLineItemDragEnd={onLineItemDragEnd}
            onEditLineItem={onEditExpenseLineItem}
            onRemoveLineItem={onRemoveLineItem}
            onLineItemOptionChange={onLineItemOptionChange}
            onLineItemValueChange={onLineItemValueChange}
          />
        </div>
        <hr className="tw-border-t tw-border-solid tw-border-gray-10 tw-mt-10px tw-mb-18px" />
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-font-semibold tw-text-gray-75">
            {internalIsActualGCI ? "Net Commission" : "Est. Net Commission"}
          </div>
          <div
            className={`${netCommissionTextColor} tw-font-semibold`}
            data-cy="financials-transaction-net-commission"
          >
            {netCommissionString}
          </div>
        </div>
      </div>
    </div>
  );
};

TransactionGci.propTypes = {
  transaction: transactionShape.isRequired,
  onEstCommissionLabelClick: PropTypes.func.isRequired,
  onCommissionLabelClick: PropTypes.func.isRequired,
  onCommissionValueChange: PropTypes.func.isRequired,
  onClosedPriceChange: PropTypes.func.isRequired,
  onAddExpenses: PropTypes.func.isRequired,
  onAddIncome: PropTypes.func.isRequired,
  transactionType: PropTypes.string.isRequired,
  isReferral: PropTypes.bool,
  status: PropTypes.string,
  onLineItemDragEnd: PropTypes.func,
  onEditExpenseLineItem: PropTypes.func,
  onEditIncomeLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
  onLineItemOptionChange: PropTypes.func,
  onLineItemValueChange: PropTypes.func,
};

TransactionGci.defaultProps = {
  isReferral: false,
  status: "pipeline",
  onLineItemDragEnd: () => {},
  onLineItemOptionChange: () => {},
  onLineItemValueChange: () => {},
  onEditExpenseLineItem: () => {},
  onEditIncomeLineItem: () => {},
  onRemoveLineItem: () => {},
};

export default tdpDetails({
  WrappedComponent: TransactionGci,
  states: [TYPE, STATUS],
  aliases: "transactionType",
});
