import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const PanelContext = createContext({});

const PanelContextProvider = ({ variant, collapsed, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleCollapsed = (e) => {
    e.preventDefault();

    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  return (
    <PanelContext.Provider
      value={{
        variant,
        collapsed: isCollapsed,
        toggleCollapsed,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

PanelContextProvider.propTypes = {
  variant: PropTypes.oneOf(["default", "primary", "success", "info", "warning", "danger"]),
  collapsed: PropTypes.bool,
  children: PropTypes.node,
};

PanelContextProvider.defaultProps = {
  variant: null,
  collapsed: null,
  children: null,
};

export default PanelContextProvider;
