import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeadHeaders from "../components/LeadHeaders";
import LeadRows from "../components/LeadRows";
import SingleDialerModal from "../../Dialer/SingleDialerModal";
import { setSnackbar } from "../../reducers/snackbarReducer";

const LeadIndex = () => {
  const dispatch = useDispatch();
  const from = useSelector((state) => state.leadIndex.from);

  useEffect(() => {
    const text = localStorage.getItem("successMsg");
    if (text)
      dispatch(
        setSnackbar({
          closeOnTimeout: () => {
            dispatch(setSnackbar({ show: false }));
            localStorage.removeItem("successMsg");
          },
          show: true,
          position: "top",
          schema: "executed",
          text,
          timeout: 5000,
        }),
      );
  }, []);

  return (
    <div id="content" className="tw-flex tw-flex-col tw-h-full">
      <div className="sticky-table tw-flex-1">
        <table className={from === "leads" ? "leads-table" : "all-people-table"}>
          <LeadHeaders />
          <LeadRows />
          <SingleDialerModal />
        </table>
      </div>
    </div>
  );
};

export default LeadIndex;
