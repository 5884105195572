/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Bullhorn = (props) => (
  <Icon {...props} name="Bullhorn">
    <path d="M15.974 7.114c0-.579-.37-1.071-.886-1.253V2.684c0-.979-.793-1.772-1.772-1.772-1.799 1.551-3.933 3.544-7.531 3.544h-4.43c-.734 0-1.329.595-1.329 1.329v2.658c0 .734.595 1.329 1.329 1.329h1.198c-.519 1.79.069 3.222.521 4.636a.66.66 0 0 0 .275.357c.575.364 1.893.434 2.596.143a.666.666 0 0 0 .208-1.095c-.512-.499-.834-1.022-.548-1.685a.335.335 0 0 0-.029-.323c-.452-.683-.099-1.72.79-2.014 3.251.2 5.25 2.061 6.95 3.526.979 0 1.772-.793 1.772-1.772V8.368a1.33 1.33 0 0 0 .886-1.253zm-2.658 3.872c-1.512-1.245-3.462-2.573-6.202-2.907V6.148c2.74-.333 4.69-1.661 6.202-2.907v7.745z" />
  </Icon>
);

export default Bullhorn;
