import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@shared/v2/Checkbox";

const LeadCreation = ({ createLead, setCreateLead }) => (
  <div className="tw-flex tw-flex-col tw-gap-8px">
    <span className="tw-text-18px tw-text-gray-75 tw-font-normal">Lead Creation</span>
    <div className="tw-flex tw-flex-row tw-gap-6px">
      <Checkbox
        name="selected"
        valign="start"
        border="tw-outline-none"
        checked={createLead}
        onChange={() => setCreateLead(!createLead)}
        label="Create phone number only leads for inbound calls to this number. Leads will be assigned to whoever answers the phone."
      />
    </div>
  </div>
);

LeadCreation.propTypes = {
  createLead: PropTypes.bool.isRequired,
  setCreateLead: PropTypes.func.isRequired,
};

export default LeadCreation;
