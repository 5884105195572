import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { startCase } from "lodash";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import DetailSelector from "@shared/v2/DetailSelector";
import Tooltip from "@shared/v2/Tooltip";
import { ChevronLeftSolidV6, CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import {
  useContactMergeKeepContact,
  useContactMergeOtherContact,
  useContactMergePrimaryContact,
} from "../reducers/contactMergeReducer/selectors";
import ContactSelectorButton from "./ContactSelectorButton";
import { setKeepContact, updateKeepContact } from "../reducers/contactMergeReducer";
import { ADDRESS_PARTS, GENERAL_DETAILS } from "./helpers/constants";
import useMergeContact from "./hooks/useMergeContact";

const MergeContacts = () => {
  const dispatch = useDispatch();

  const primaryContact = useContactMergePrimaryContact();
  const otherContact = useContactMergeOtherContact() || {};
  const keepContact = useContactMergeKeepContact() || {};
  const onContactDetailChange = (args) => dispatch(updateKeepContact(args));

  const {
    emailTypes,
    primaryEmails,
    otherEmails,
    phoneTypes,
    primaryPhones,
    otherPhones,
    addressTypes,
    primaryAddresses,
    otherAddresses,
    personTypes,
    primaryPersonDetails,
    otherPersonDetails,
    socialMediaTypes,
    primarySocialMediaDetails,
    otherSocialMediaDetails,
    milestoneTypes,
    primaryMilestones,
    otherMilestones,
  } = useMergeContact();

  return (
    <div className="tw-overflow-auto tw-flex tw-flex-col tw-gap-[8px] tw-text-gray-50">
      <p className="tw-m-0">
        Select a primary lead. The other lead’s status will be set to Trash and their information will be
        transferred to the primary lead.
      </p>
      <Alert
        variant="warning"
        title="Warning: This action cannot be undone."
        text={
          <div className="tw-flex tw-items-center tw-gap-[16px]">
            <p className="tw-m-0">
              Information transferred to the primary lead will no longer be available on the trashed
              lead&apos;s profile. Other info will remain on the trashed lead&apos;s profile and not transfer
              over, such as timeline & message history, Auto Plans & tasks, Market Reports and Listing Alerts.
            </p>
            <Button
              className="tw-whitespace-nowrap tw-mb-[24px]"
              schema="semantic-yellow"
              onClick={() => {
                window.open("https://www.brivityknowledge.com/docs/merge-contacts");
              }}
            >
              Learn More
            </Button>
          </div>
        }
      />
      <div className="tw-flex tw-justify-between tw-items-end tw-gap-[12px] tw-mt-[12px]">
        <span className="tw-text-gray-50 tw-font-semibold tw-mb-[16px]">Primary Lead</span>
        <div className="tw-px-[4px] tw-flex tw-items-end tw-gap-[10px]">
          <ContactSelectorButton
            disabled={otherContact.hasListings}
            contact={primaryContact}
            selected={keepContact.id === primaryContact.id}
            onSelect={(c) => dispatch(setKeepContact(c))}
          />
          <div className="tw-mb-[16px]">
            <ChevronLeftSolidV6
              className={`tw-fill-neutral-gray-50 tw-transition-transform ${keepContact.id === primaryContact.id ? "" : "tw-rotate-180"}`}
            />
          </div>
          <ContactSelectorButton
            disabled={primaryContact.hasListings}
            contact={otherContact}
            selected={keepContact.id === otherContact.id}
            onSelect={(c) => dispatch(setKeepContact(c))}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[24px]">
        <hr className="tw-w-full tw-mt-[4px] tw-mb-0" />
        <div className="tw-flex tw-flex-col tw-gap-[12px]">
          <h4 className="tw-m-0 tw-font-normal">General</h4>
          {GENERAL_DETAILS.map(({ label, key }) => (
            <DetailSelector
              key={key}
              label={label}
              firstValue={primaryContact[key]}
              secondValue={otherContact[key]}
              selectedValue={keepContact[key]}
              onSelect={(value) => onContactDetailChange({ key, value })}
            />
          ))}
        </div>
        <hr className="tw-w-full tw-mt-[4px] tw-mb-0" />
        <div className="tw-flex tw-flex-col tw-gap-[12px]">
          <h4 className="tw-m-0 tw-font-normal">
            Contact Info{" "}
            <Tooltip
              placement="right"
              innerClassName="tw-max-w-[300px]"
              trigger={<CircleInfoSolidV6 />}
              content={
                <div className="tw-flex tw-flex-col tw-gap-[12px] tw-text-left">
                  <span>
                    All emails, phones, and addresses from both contacts will be saved to the primary contact
                    after merging.
                  </span>
                  <span>
                    If there is an overlap in Type, the unselected option will be converted to Other.
                  </span>
                </div>
              }
            />
          </h4>
          <h5 className="tw-mb-0 tw-mt-[20px] tw-text-14d tw-normal-case tw-text-neutral-gray-75 tw-font-semibold">
            Email
          </h5>
          {emailTypes.map((type) => {
            const primaryHasMore = (primaryEmails[type] || []).length > (otherEmails[type] || []).length;
            let mainList = [];

            if (type === "other") {
              mainList = [...(primaryEmails[type] || []), ...(otherEmails[type] || [])];
            } else {
              mainList = primaryHasMore ? primaryEmails[type] : otherEmails[type];
            }

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primaryEmails[type]?.[i]?.id
                  : otherEmails[type]?.[i]?.id;

              let firstValue = primaryEmails[type]?.[i]?.value;
              let secondValue = otherEmails[type]?.[i]?.value;
              if (type === "other" && i >= (primaryEmails[type]?.length || 0)) {
                firstValue = "";
                secondValue = main.value;
              } else if (type === "other") {
                secondValue = "";
              }

              return (
                <DetailSelector
                  key={main.id}
                  label={startCase(type)}
                  firstValue={firstValue}
                  secondValue={secondValue}
                  selectedValue={keepContact.emailDetails.find((e) => e.id === id)?.value}
                  onSelect={(value) =>
                    onContactDetailChange({
                      id,
                      type: "emailDetails",
                      key: "value",
                      value,
                    })
                  }
                />
              );
            });
          })}

          <h5 className="tw-mb-0 tw-mt-[16px] tw-text-14d tw-normal-case tw-text-neutral-gray-75 tw-font-semibold">
            Phone
          </h5>
          {phoneTypes.map((type) => {
            const primaryHasMore = (primaryPhones[type] || []).length > (otherPhones[type] || []).length;
            let mainList = [];

            if (type === "other") {
              mainList = [...(primaryPhones[type] || []), ...(otherPhones[type] || [])];
            } else {
              mainList = primaryHasMore ? primaryPhones[type] : otherPhones[type];
            }

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primaryPhones[type]?.[i]?.id
                  : otherPhones[type]?.[i]?.id;

              let firstValue = primaryPhones[type]?.[i]?.value;
              let secondValue = otherPhones[type]?.[i]?.value;
              if (type === "other" && i >= (primaryPhones[type]?.length || 0)) {
                firstValue = "";
                secondValue = main.value;
              } else if (type === "other") {
                secondValue = "";
              }

              return (
                <DetailSelector
                  key={main.id}
                  label={startCase(type)}
                  firstValue={firstValue}
                  secondValue={secondValue}
                  selectedValue={keepContact.phoneDetails.find((p) => p.id === id)?.value}
                  onSelect={(value) =>
                    onContactDetailChange({
                      id,
                      type: "phoneDetails",
                      key: "value",
                      value,
                    })
                  }
                />
              );
            });
          })}

          {addressTypes.map((type) => {
            const primaryHasMore =
              (primaryAddresses[type] || []).length > (otherAddresses[type] || []).length;
            let mainList = [];

            if (type === "other") {
              mainList = [...(primaryAddresses[type] || []), ...(otherAddresses[type] || [])];
            } else {
              mainList = primaryHasMore ? primaryAddresses[type] : otherAddresses[type];
            }

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primaryAddresses[type]?.[i]?.id
                  : otherAddresses[type]?.[i]?.id;

              let firstValue = primaryAddresses[type]?.[i];
              let secondValue = otherAddresses[type]?.[i];
              if (type === "other" && i >= (primaryAddresses[type]?.length || 0)) {
                firstValue = null;
                secondValue = main;
              } else if (type === "other") {
                secondValue = null;
              }
              return (
                <Fragment key={main.id}>
                  <h5 className="tw-mb-0 tw-mt-[16px] tw-text-14d tw-normal-case tw-text-neutral-gray-75 tw-font-semibold">
                    {`${startCase(type)} Address`}
                  </h5>

                  {ADDRESS_PARTS.map(({ label, key }) => (
                    <DetailSelector
                      key={key}
                      label={label}
                      firstValue={firstValue?.[key]}
                      secondValue={secondValue?.[key]}
                      selectedValue={keepContact.addresses.find((a) => a.id === id)?.[key]}
                      onSelect={(value) =>
                        onContactDetailChange({
                          id,
                          type: "addresses",
                          key,
                          value,
                        })
                      }
                    />
                  ))}
                </Fragment>
              );
            });
          })}

          <span />
          <span />

          <DetailSelector
            label="Letter Salutation"
            firstValue={primaryContact.letterSalutation}
            secondValue={otherContact.letterSalutation}
            selectedValue={keepContact.letterSalutation}
            onSelect={(value) => onContactDetailChange({ key: "letterSalutation", value })}
          />
          <DetailSelector
            label="Envelope Salutation"
            firstValue={primaryContact.envelopeSalutation}
            secondValue={otherContact.envelopeSalutation}
            selectedValue={keepContact.envelopeSalutation}
            onSelect={(value) => onContactDetailChange({ key: "envelopeSalutation", value })}
          />
        </div>
        <hr className="tw-w-full tw-mt-[4px] tw-mb-0" />
        <div className="tw-flex tw-flex-col tw-gap-[12px]">
          <h4 className="tw-m-0 tw-font-normal">Person Details</h4>
          <DetailSelector
            label="Description"
            firstValue={primaryContact.description}
            secondValue={otherContact.description}
            selectedValue={keepContact.description}
            onSelect={(value) => onContactDetailChange({ key: "description", value })}
          />

          {personTypes.map((type) => {
            const primaryHasMore =
              (primaryPersonDetails[type] || []).length > (otherPersonDetails[type] || []).length;
            const mainList = primaryHasMore ? primaryPersonDetails[type] : otherPersonDetails[type];

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primaryPersonDetails[type]?.[i]?.id
                  : otherPersonDetails[type]?.[i]?.id;
              return (
                <DetailSelector
                  key={main.id}
                  label={startCase(type)}
                  firstValue={primaryPersonDetails[type]?.[i]?.value}
                  secondValue={otherPersonDetails[type]?.[i]?.value}
                  selectedValue={keepContact.personDetails.find((p) => p.id === id)?.value}
                  onSelect={(value) =>
                    onContactDetailChange({
                      id,
                      type: "personDetails",
                      key: "value",
                      value,
                    })
                  }
                />
              );
            });
          })}

          {milestoneTypes.map((type) => {
            const primaryHasMore =
              (primaryMilestones[type] || []).length > (otherMilestones[type] || []).length;
            const mainList = primaryHasMore ? primaryMilestones[type] : otherMilestones[type];

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primaryMilestones[type]?.[i]?.newMilestoneId
                  : otherMilestones[type]?.[i]?.newMilestoneId;
              return (
                <DetailSelector
                  key={main.id}
                  isDate={type === "birthdate"}
                  label={startCase(type)}
                  firstValue={primaryMilestones[type]?.[i]?.date}
                  secondValue={otherMilestones[type]?.[i]?.date}
                  selectedValue={
                    keepContact.milestonableMilestones.find((m) => m.newMilestoneId === id)?.date
                  }
                  onSelect={(value) =>
                    onContactDetailChange({
                      id,
                      type: "milestonableMilestones",
                      key: "date",
                      value,
                    })
                  }
                />
              );
            });
          })}
        </div>
        <hr className="tw-w-full tw-mt-[4px] tw-mb-0" />
        <div className="tw-flex tw-flex-col tw-gap-[12px]">
          <h4 className="tw-m-0 tw-font-normal">Social Media</h4>
          {socialMediaTypes.map((type) => {
            const primaryHasMore =
              (primarySocialMediaDetails[type] || []).length > (otherSocialMediaDetails[type] || []).length;
            const mainList = primaryHasMore ? primarySocialMediaDetails[type] : otherSocialMediaDetails[type];

            return mainList.map((main, i) => {
              const id =
                keepContact.id === primaryContact.id
                  ? primarySocialMediaDetails[type]?.[i]?.id
                  : otherSocialMediaDetails[type]?.[i]?.id;
              return (
                <DetailSelector
                  key={main.id}
                  label={startCase(type)}
                  firstValue={primarySocialMediaDetails[type]?.[i]?.value}
                  secondValue={otherSocialMediaDetails[type]?.[i]?.value}
                  selectedValue={keepContact.socialMediaDetails.find((s) => s.id === id)?.value}
                  onSelect={(value) =>
                    onContactDetailChange({
                      id,
                      type: "socialMediaDetails",
                      key: "value",
                      value,
                    })
                  }
                />
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default MergeContacts;
