import React, { Component } from "react";
import PropTypes from "prop-types";
import Pluralize from "react-pluralize";
import { Row, Col } from "react-bootstrap";
import CheckBox from "../../shared/CheckBox";
import ProfileIcon from "../../shared/ProfileIcon";
import Moment from "react-moment";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import "./TaskForm.sass";
import TaskSettings from "./TaskSettings";
import capitalize from "capitalize";
import { stripHtml } from "../../shared/Utilities";
import TaskModal from "../../PersonDetail/components/tasks/TaskModal.jsx";
import Tooltip from "@shared/v2/Tooltip";

export default class PolymorphicTask extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      task: props.task,
      user: props.user,
      agents: props.agents,
      showModal: false,
      multiSelectWatcher: props.multiSelectWatcher,
    };
    this.multiSelectWatcher = this.props.multiSelectWatcher.bind(this);
    this.swapForDelete = TaskHelpers.swapForDelete.bind(this);
    this.showModal = TaskHelpers.showModal.bind(this);
    this.closeModal = TaskHelpers.closeModal.bind(this);
    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this);
    this.handleMultiSelect = TaskHelpers.handleMultiSelect.bind(this);
    this.showDetailModal = TaskHelpers.showDetailModal.bind(this);
    this.closeDetailModal = TaskHelpers.closeDetailModal.bind(this);
    this.swapModals = TaskHelpers.swapModals.bind(this);
    this.showDeleteModal = TaskHelpers.showDeleteModal.bind(this);
    this.closeDeleteModal = TaskHelpers.closeDeleteModal.bind(this);
    this.completeTask = TaskHelpers.completeTask.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);
    this.renderDeleteModal = TaskHelpers.renderDeleteModal.bind(this);
    this.renderDetailModal = TaskHelpers.renderDetailModal.bind(this);
    this.deleteTasks = TaskHelpers.deleteTask.bind(this);
    this.renderModal = TaskHelpers.renderModal.bind(this);
    this.taskMultiSelect = TaskHelpers.taskMultiSelect.bind(this);
    this.showReassignModal = TaskHelpers.showReassignModal.bind(this);
    this.renderReassignModal = TaskHelpers.renderReassignModal.bind(this);
    this.closeReassignModal = TaskHelpers.showReassignModal.bind(this);
    this.setAgentsForModal = TaskHelpers.setAgentsForModal.bind(this);
    this.handleState = TaskHelpers.handleState.bind(this);
    this.handleRescheduleDate = TaskHelpers.handleRescheduleDate.bind(this);
    this.getTasks = this.props.getTasks.bind(this);
    this.weeklyOptions = TaskHelpers.weeklyOptions.bind(this);
    this.handleRecurrence = TaskHelpers.handleRecurrence.bind(this);
    this.replaceURLWithHTMLLinks = TaskHelpers.replaceURLWithHTMLLinks.bind(this);
    this.completeAndEditAppointment = TaskHelpers.completeAndEditAppointment.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, ...nextProps });
  }

  toggleInstructions() {
    var task = { ...this.state.task };
    task["showInstructions"] = !task["showInstructions"];
    this.setState({ task });
  }

  setAgentsForModal = () => {
    var agents = [];
    for (var obj in this.state.agents) {
      var agent = this.state.agents[`${obj}`];
      agents.push({ label: agent["full_name"], value: agent["uuid"] });
    }
    return agents;
  };

  editMute = () => {
    return this.props.agent ? "" : "text-mute";
  };

  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  milestoneOptions = () => {
    const { accountMilestoneNames } = this.props;

    const personNames = accountMilestoneNames["Person"];
    const listingNames = accountMilestoneNames["Listing"];
    const projectNames = accountMilestoneNames["Project"];

    const allNamesArray = personNames.concat(listingNames).concat(projectNames);

    let allNamesObject = {};

    allNamesArray.forEach((name) => {
      allNamesObject[name] = name
        .split("_")
        .map((element) => capitalize(element))
        .join(" ");
    });

    return allNamesObject;
  };

  onUpdateTaskTypeChecker = (e) => {
    if (this.state.task.type === "Appointment") {
      this.setState({ completeAppointment: !this.state.completeAppointment });
      this.completeAndEditAppointment();
    } else this.handleTaskChange(e);
  };

  closeTaskDetails = () => {
    let task = { ...this.state.task };
    task["state"] = "running";
    this.setState({ task, completeAppointment: false });
  };
  render() {
    var user = this.state.user;
    var task = this.state.task;
    return (
      <>
        {this.state.completeAppointment && (
          <TaskModal
            closeTaskModal={this.closeTaskDetails}
            agents={this.props.agents}
            account_uuid={this.props.account_uuid}
            user={this.props.user}
            taskable={this.props.taskable}
            accountMilestoneNames={this.props.accountMilestoneNames}
            task={this.state.task}
          />
        )}
        <Row
          className={"striped td task " + this.props.dueness}
          data-object-id={task.id}
          data-contingent={task.check_contingency}
          data-dueness={task.dueness}
          data-incomplete-dueness={task.dueness}
          data-name={task.name}
        >
          <Col xs={5}>
            <div className={this.props.nameColumnClass}>
              <Row className="no-gutter">
                {/* check box select */}
                <Col xs={1}>
                  {!this.props.agent && (
                    <CheckBox
                      divClass={task.dueness + " fancy"}
                      labelClass={`${task.state == "completed" ? "complete text-success checked" : "incomplete unchecked"}`}
                      inputClass={"checkbox-input"}
                      inputChecked={task.state == "completed" && "checked"}
                      objectToCheck={task}
                      inputName={"complete"}
                    />
                  )}
                  {this.props.agent && (
                    <CheckBox
                      divClass={task.dueness + " fancy"}
                      labelClass={`${task.state == "completed" ? "complete text-success checked" : "incomplete unchecked"}`}
                      inputClass={"checkbox-input"}
                      inputChecked={task.state == "completed"}
                      objectToCheck={task}
                      inputName={"complete"}
                      onUpdate={(e) => this.onUpdateTaskTypeChecker(e)}
                    />
                  )}
                </Col>

                {/* Task name or link to Agent */}
                <Col xs={11} className="overflow">
                  {!this.props.agent && (
                    <div>
                      {task.assigned_plan_id && (
                        <span>
                          <i className={`fa fa-bolt m-r-5`} style={{ color: "#59c4c4" }} />
                        </span>
                      )}
                      {stripHtml(task.name)}
                      <div className="task css_fade_out" />
                    </div>
                  )}
                  {this.props.agent && (
                    <div>
                      {task.assigned_plan_id && (
                        <span>
                          <i className={`fa fa-bolt m-r-5`} style={{ color: "#59c4c4" }} />
                        </span>
                      )}
                      <a onClick={this.showDetailModal} className="no-underline">
                        {" "}
                        {task.name}{" "}
                      </a>
                      <div className="task css_fade_out" />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>

          {/* Task Date */}
          <Col xs={1} className="text-center overflow">
            {task.state == "completed" && <Moment format="MM/DD/YYYY" date={task.completed_at} />}
            {task.state != "completed" && (
              <div>
                {task.calculated_due_on && <Moment format="MM/DD/YYYY" date={task.calculated_due_on} />}
                {!task.calculated_due_on && (
                  <Pluralize plural="days" singular="day" count={parseInt(task.contingency_rule["days"])} />
                )}
                {!task.calculated_due_on && " " + task.contingency_rule["position"] + " "}
                {!task.calculated_due_on &&
                  (task.parent_task_name
                    ? task.parent_task_name
                    : !task.calculated_due_on &&
                      task.contingency_event_and_type[1] != undefined &&
                      this.milestoneOptions()[task.contingency_event_and_type[1]])}
              </div>
            )}
          </Col>

          <Col xs={1} className="text-center">
            {this.props.agent && (
              <span>
                {task.priority && <img src={`/images/task_priorities/${task.priority}.svg`} />}
                {!task.priority && <img src={`/images/task_priorities/empty.svg`} />}
              </span>
            )}
          </Col>

          {/* Person image or default */}
          <Col xs={1} className="text-center who">
            {task.state == "completed" && <ProfileIcon dynamic={true} userId={task.completed_by_user_id} />}
            {task.state != "completed" && task.assigned_to_user_id && (
              <ProfileIcon dynamic={true} userId={task.assigned_to_user_id} />
            )}
            {task.assigned_to_user_id == null && task.completed_by_user_id == null && (
              <Tooltip placement="right" trigger={<img src="/images/users/team.svg" />} content="Anyone" />
            )}
          </Col>

          {/* Task Settings */}
          {this.props.agent && <TaskSettings col_size={2} task={task} />}

          {/* Task Edit */}
          <Col xs={1}>
            {this.props.agent && (
              <div>
                <span>
                  <a onClick={() => this.showModal()} href={task.edit_path}>
                    <i className={"fa fa-pencil " + this.editMute()} />
                  </a>
                </span>
                <span>
                  <a href="javascript:void(0)" onClick={this.showDeleteModal}>
                    <i className="fa fa-times" />
                  </a>
                </span>
              </div>
            )}
          </Col>

          {/* Multi-select */}
          <Col xs={1} className="text-center">
            {this.props.agent && (
              <CheckBox
                divClass={"task-multi-select display-inline-block"}
                labelClass={task.multi_select ? "checked" : "unchecked"}
                inputClass={"checkbox-input multi-select"}
                inputChecked={task.multi_select && "checked"}
                objectToCheck={task}
                inputName={"multi_select"}
                onUpdate={this.handleTaskChange}
              />
            )}
          </Col>
          {this.renderModal()}
          {this.renderDetailModal()}
          {this.renderDeleteModal()}
        </Row>
      </>
    );
  }
}

PolymorphicTask.propTypes = {
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

PolymorphicTask.defaultProps = {
  task: {},
  user: { name: "No Name" },
  nameColumnClass: "task-column",
  taskCssStyle: "task",
};
