import React from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const { PANEL_HEADING_CLASSES, PANEL_HEADING_BORDER_CLASSES } = CLASSES;

const PanelHeading = ({ className, deprecatedOverrideBorderClasses, children, ...props }) => {
  const classes = `
    ${PANEL_HEADING_CLASSES}
    ${deprecatedOverrideBorderClasses || PANEL_HEADING_BORDER_CLASSES}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

PanelHeading.propTypes = {
  className: PropTypes.string,
  deprecatedOverrideBorderClasses: PropTypes.string,
  children: PropTypes.node,
};

PanelHeading.defaultProps = {
  className: "",
  deprecatedOverrideBorderClasses: "",
  children: null,
};

export default PanelHeading;
