import React from "react";

const AlertStatus = ({ alert: { isSending, activeForEmails, activeForAppNotifications } }) => {
  const textColor = isSending ? "tw-text-teal" : "tw-text-coral";
  const statusText = createStatusText(isSending, activeForEmails, activeForAppNotifications);

  return (
    <>
      <span className="tw-mb-5px tw-text-10px tw-text-gray-75 tw-font-bold">STATUS:</span>
      <span className={`tw-ml-4px tw-text-14px ${textColor}`}>{statusText}</span>
    </>
  );
}

export default AlertStatus;

const createStatusText = (isSending, activeForEmails, activeForAppNotifications) => {
  if (!isSending) {
    return "Not Sending Alerts";
  }

  if (activeForEmails && activeForAppNotifications) {
    return "Sending Alerts";
  }

  return activeForEmails ? "Sending Email Alerts" : "Sending Home App Alerts";
};
