import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../shared/v2/Button";
import { Plus } from "../../../../shared/v2/Icomoon";

const MarketingHeader = ({ showNewFeedbackForm }) => (
  <div
    className="tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center tw-mb-24px"
    data-cy="marketing-header"
  >
    <span className="tw-font-bold tw-text-18d tw-text-neutral-gray-75" data-cy="marketing-header-title">
      Showing Feedback
    </span>
    <span className="tw-inline-flex tw-flex-row tw-items-center">
      <Button
        size="small"
        schema="secondary"
        onClick={showNewFeedbackForm}
        data-cy="marketing-header-add-feedback-button"
      >
        <span className="tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center tw-space-x-4px">
          <Plus size="s" />
          <span className="tw-uppercase">feedback</span>
        </span>
      </Button>
    </span>
  </div>
);

MarketingHeader.propTypes = {
  showNewFeedbackForm: PropTypes.func.isRequired,
};

export default MarketingHeader;
