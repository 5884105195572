import React from "react";

import ReferralPartner from "../ReferralPartner";
import ReferralStatus from "../ReferralStatus";

const SpecificDetails = () => (
  <div className="tw-w-[237px]">
    <ReferralPartner />
    <ReferralStatus />
  </div>
);

export default SpecificDetails;
