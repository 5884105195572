import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const {
  FORM_CONTROL_BASE_CLASSES,
  FORM_CONTROL_VARIANT_CLASSES,
  FORM_CONTROL_SIZE_CLASSES,
  FORM_CONTROL_PADDING_CLASSES,
} = CLASSES;

export const FormControl = forwardRef(
  (
    { className, componentClass: Component, size, variant, deprecatedOverridePaddingClasses, ...props },
    ref,
  ) => {
    const classes = `
      ${FORM_CONTROL_BASE_CLASSES}
      ${FORM_CONTROL_VARIANT_CLASSES[variant]}
      ${FORM_CONTROL_SIZE_CLASSES[size]}
      ${deprecatedOverridePaddingClasses || FORM_CONTROL_PADDING_CLASSES[size]}
      ${className}
    `;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component ref={ref} className={classes} {...props} />
    );
  },
);

FormControl.propTypes = {
  className: PropTypes.string,
  componentClass: PropTypes.string,
  size: PropTypes.oneOf(["default", "small", "large", "auto"]),
  variant: PropTypes.oneOf(["default", "unstyled"]),
  deprecatedOverridePaddingClasses: PropTypes.string,
};

FormControl.defaultProps = {
  className: "",
  componentClass: "input",
  size: "default",
  variant: "default",
  deprecatedOverridePaddingClasses: "",
};

export default FormControl;
