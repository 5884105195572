import { produce } from "immer";
import * as actionCreators from "../BusinessSettings/actions/creators";

const DEFAULT_OFFICE = {
  name: "",
  phone: "",
  fax: "",
  address_attributes: {
    street_address: "",
    city: "",
    locality: "",
    postal_code: "",
    country: "",
  },
};

const defaultState = {
  isLoading: true,
  hasError: false,
  model: {},
  newOfficeModel: { ...DEFAULT_OFFICE },
  timezones: null,
  states: null,
  awsDirectPost: null,
};

const businessSettingsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.init().type:
      // const store = useMemo(
      //   () =>
      //     configureStore({
      //       businessSettings: {
      //         ...businessSettingsState,
      //         timezones,
      //         states,
      //         territories,
      //         isPlace,
      //         awsDirectPost,
      //       },
      //     }),
      //   [],
      // );


      return {
        ...defaultState,
        ...payload.data,
      };
    case actionCreators.getBusinessSettings().type:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case actionCreators.getBusinessSettingsError().type:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasError = true;
      });
    case actionCreators.getBusinessSettingsSuccess().type:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.model = payload.data?.data;
      });
    case actionCreators.setValue().type:
      return produce(state, (draft) => {
        draft.model[payload.key] = payload.value;
      });
    case actionCreators.setOfficeValue().type:
      return produce(state, (draft) => {
        draft.newOfficeModel[payload.key] = payload.value;
      });
    case actionCreators.resetOffice().type:
      return produce(state, (draft) => {
        draft.newOfficeModel = { ...DEFAULT_OFFICE };
      });
    case actionCreators.addOfficeSuccess().type:
      return produce(state, (draft) => {
        draft.model = payload.data?.data;
      });
    default:
      return state;
  }
};

export default businessSettingsReducer;
