/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ThumbsUpSolidV6 = (props) => (
  <Icon {...props} name="Thumbs Up Solid V6">
    <path d="M4 13.972v-7C4 6.42 3.552 6 3 6H1c-.552 0-1 .448-1 .972v6.972c0 .552.448.972 1 .972h2c.553.056 1-.366 1-.944zm12-6.969a1.5 1.5 0 0 0-1.5-1.499H9.922c.712-1.185 1.079-2.527 1.079-3.001A1.512 1.512 0 0 0 9.454 1C7.481 1 8.63 3.38 6.073 5.425l-.509.406A1.504 1.504 0 0 0 5.005 7c-.001.001 0 0 0 0l-.003 5c0 .472.222.917.6 1.2l1.067.8a4.984 4.984 0 0 0 2.999 1.001h1.834a1.5 1.5 0 0 0 1.461-1.829 1.495 1.495 0 0 0 1.039-1.421c0-.286-.084-.55-.223-.778a1.488 1.488 0 0 0 .825-2.479 1.496 1.496 0 0 0 1.398-1.49z" />
  </Icon>
);

export default ThumbsUpSolidV6;
