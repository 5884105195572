import React from "react";
import PropTypes from "prop-types";

const Divider = ({ verticalMargin }) => (
  <div className={`tw-flex tw-flex-row tw-w-full ${verticalMargin}`}>
    <span className="tw-w-full tw-h-1px tw-bg-gray-10" />
  </div>
);

Divider.propTypes = {
  verticalMargin: PropTypes.string.isRequired,
};

export default Divider;
