/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const GraduationCapSolidV6 = (props) => (
  <Icon {...props} name="Graduation Cap Solid V6">
    <path d="M15.577 5.022 8.51 2.492a1.571 1.571 0 0 0-1.061 0L.401 5.022C.161 5.11 0 5.339 0 5.599s.161.491.401.577l1.5.538a3.47 3.47 0 0 0-.614 1.349.787.787 0 0 0-.19 1.345l-.621 3.725a.4.4 0 0 0 .394.466h1.456a.4.4 0 0 0 .394-.466l-.621-3.725a.822.822 0 0 0 .298-.609.784.784 0 0 0-.318-.622c.106-.444.322-.845.623-1.176l4.765 1.706c.343.123.717.123 1.061 0l7.067-2.53a.613.613 0 0 0-.022-1.155zM8.778 9.46c-.235.092-.505.14-.778.14s-.542-.047-.8-.139L3.57 8.162l-.37 3.57C3.2 12.765 5.327 13.6 8 13.6c2.628 0 4.8-.835 4.8-1.867l-.369-3.573-3.653 1.299z" />
  </Icon>
);

export default GraduationCapSolidV6;
