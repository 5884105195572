import moment from "moment";

export default (tasksData) => {
  const tasks = [...tasksData];

  tasks.sort((task1, task2) => {
    if (moment(task1.due_on).format("X") * 1 > moment(task2.due_on).format("X") * 1) return 1;
    if (moment(task1.due_on).format("X") * 1 < moment(task2.due_on).format("X") * 1) return -1;
    if (task1.assigned_person?.fullName < task2.assigned_person?.fullName) return 1;
    if (task1.assigned_person?.fullName > task2.assigned_person?.fullName) return -1;

    if (task1.priority > task2.priority) return -1;
    return 1;
  });

  return tasks;
};

export const updateDueOn = (contingent, date) => {  
  if(!date) return null;

  const {days, position} = contingent
  
  const originalDate = moment(date, "YYYY-MM-DD");
  const newDate = position === 'after' ? originalDate.add(days, 'days') : originalDate.subtract(days, 'days');

  return newDate.format('MM/DD/YY');
};
