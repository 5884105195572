/* eslint-disable react/prop-types */

import React from "react";

const TriangleUpIcon = ({ className = "", fillColor = "#999999" }) => (
  <svg
    width="8px"
    height="5px"
    viewBox="0 0 8 5"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>Triangle Up Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(3.999999, 2.500001) rotate(-180.000000) translate(-3.999999, -2.500001) translate(0.000000, 0.000000)"
        fill={fillColor}
        fillRule="nonzero"
      >
        <path d="M0.672856908,0 L7.32713991,0 C7.92546991,0 8.22462991,0.775217 7.80244991,1.22718 L4.47530991,4.78899 C4.21248991,5.07034 3.78750991,5.07034 3.52748991,4.78899 L0.197550908,1.22718 C-0.224633092,0.775217 0.074530208,0 0.672856908,0 Z" />
      </g>
    </g>
  </svg>
);

export default TriangleUpIcon;
