const PRIMARY_ACTIVE = {
  overdue: "Overdue, Due Today, or Upcoming",
  due_today: "Overdue, Due Today, or Upcoming",
  upcoming: "Overdue, Due Today, or Upcoming",
  contingent: "Contingent",
  completed_at: "Completed",
};

export default (primaryActiveList) =>
  !primaryActiveList[0]
    ? "No task categories are selected."
    : `No ${PRIMARY_ACTIVE[primaryActiveList[0]]} tasks to display.`;
