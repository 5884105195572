import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as actionCreators from "../actions/thunks";
import InlineEditingDatePicker from "../../../../shared/v2/InlineEditingDatePicker/InlineEditingDatePicker";
import InlineEditingDatePickerPortal from "../../../../shared/v2/InlineEditingDatePickerPortal";

const DATE_FORMAT = "MM/DD/YYYY";
const DATE_TIME_FORMAT = `${DATE_FORMAT} hh:mm A`;

const ModifyDate = ({ date, isNew, updateDate, uuid }) => {
  const DatePicker = isNew ? InlineEditingDatePickerPortal : InlineEditingDatePicker;
  const dateValue = `${date?.date}${date?.display_time ? ` ${date.display_time}` : ""}`;

  const submitDate = (newValue) => {
    const hasTime = / /.test(newValue);
    const newDate = moment.utc(newValue, hasTime ? DATE_TIME_FORMAT : DATE_FORMAT);
    if (newValue !== "" && !newDate.isValid()) return;
    const secondsSinceMidnight = hasTime ? newDate.diff(moment(newDate).startOf("day"), "seconds") : null;
    const data = {
      new_milestone: isNew,
      date: newDate,
      seconds_since_midnight: secondsSinceMidnight,
    };
    updateDate(uuid, date?.id, data);
  };

  return (
    <DatePicker
      className="tw-my-[-7px]"
      portalDOMElement="date-portal"
      position="left"
      value={dateValue}
      customText={date?.display_time}
      dateFormat={DATE_FORMAT}
      onChange={submitDate}
      enableTimePicker={date?.include_time}
      timeOnNewLine={date?.include_time}
    />
  );
};

ModifyDate.propTypes = {
  date: Proptypes.shape({
    id: Proptypes.string.isRequired,
    date: Proptypes.string,
    include_time: Proptypes.bool.isRequired,
    display_time: Proptypes.string,
  }).isRequired,
  isNew: Proptypes.bool,
  updateDate: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
};

ModifyDate.defaultProps = {
  isNew: false,
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDatesReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  updateDate: (uuid, optionId, data) => dispatch(actionCreators.updateDate(uuid, optionId, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ModifyDate);
