import React, { useState } from "react";
import { func, string } from "prop-types";
import moment from "moment";

import RadioButton from "@shared/v2/RadioButton";
import DatePicker from "@shared/v2/DatePicker";
// import Checkbox from "@shared/v2/Checkbox";

import { primaryLight, subtleM } from "@shared/styles";
import { Delay, DelayAndScheduleLabel, Schedule } from "../ActionFormHelpers";
import { checkIfDateStringIsWithinRange } from "../../helpers";

const ComputedTimeOptions = () => (
  <div className="">
    <DelayAndScheduleLabel
      delayLabel="Due"
      scheduleLabel="After"
    />

    <Delay
      // delayFactor={task.delay_factor}
      // delayOnChange={onChange}
      // delayValue={task.delay}
      // delayUnitValue={task.delay_unit}
      // delayUnitOnChange={handleDelayUnitChange}
      // scheduleType={task.schedule_type}
    />

    <Schedule
      type='computed_time'
      // delay={task.delay}
      // delayFactor={task.delay_factor}
      // onChange={onChange}
      // parentActionId={task.parent_action_id}
      // triggers={triggers}
      // milestoneOptions={milestoneOptions}
      // milestoneId={task.milestone_id}
    />  
  </div>  
)

const EventOptions = () => (
  <div className="">
    <DelayAndScheduleLabel
      delayLabel="Due"
    />
    <Delay
      // delayFactor={task.delay_factor}
      // delayOnChange={onChange}
      // delayValue={task.delay}
      // delayUnitValue={task.delay_unit}
      // delayUnitOnChange={handleDelayUnitChange}
      // scheduleType={task.schedule_type}
    />

    <Schedule
      type='event'
      // delay={task.delay}
      // delayFactor={task.delay_factor}
      // onChange={onChange}
      // parentActionId={task.parent_action_id}
      // triggers={triggers}
      // milestoneOptions={milestoneOptions}
      // milestoneId={task.milestone_id}
    />
  </div>
)

// TODO: Reenable when B/E is ready
// eslint-disable-next-line no-unused-vars
const DateTime = ({ setState, value }) => {
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState('specificDateAndTime')
  const setCheckedHandlr = (e) => setChecked(e.target.value);

  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px]">
      <hr className="tw-w-full tw-border-gray-10 tw-m-0"/>
      <div className="tw-flex tw-gap-[24px] tw-hidden">
        {/* <RadioButton
          value='computedTime'
          label='Computed Time'
          className='tw-cursor-pointer'
          checked={checked === 'computedTime'}
          onClick={setCheckedHandlr}
        />
        <RadioButton
          value='event'
          label='Event'
          className='tw-cursor-pointer'
          checked={checked === 'event'}
          onClick={setCheckedHandlr}
        /> */}
        <RadioButton
          value='specificDateAndTime'
          label='Specific Date and Time'
          className='tw-cursor-pointer'
          checked={checked === 'specificDateAndTime'}
          onClick={setCheckedHandlr}
        />
      </div>
      
      {checked === 'computedTime' &&
        <ComputedTimeOptions/>
      }  

      {checked === 'event' &&
        <EventOptions/>
      }
      {checked === 'specificDateAndTime' && 
        <div>
          <div className={`${subtleM} ${primaryLight} tw-mb-8px`}>Due</div>
          <DatePicker
            startTimeClassName="!tw-hidden"
            containerClassName='tw-w-[264px]'
            position='bottom'
            value={value}
            startTimeEnabled
            onChange={setState}
            minDate={moment().format('MM/DD/YYYY')}
            maxDate={moment().set({ month: 11, date: 31 }).add(1, 'years').format('MM/DD/YYYY')}
            enableTimePicker
            inputProps={{
              error: checkIfDateStringIsWithinRange(value)
            }}/>
        </div>
      }      
      {/* TOOD: Re-enable when B/E is ready */}
      {/* <div className="tw-flex tw-items-baseline">
        <Checkbox
          checked={contingent}
          onChange={() => setContingentValue(!contingent)}/>
        <span className='tw-ml-6px tw-text-14d tw-transform-[translateY(-3px)]'>Make Contingent (Due time auto-updates based on when the previous action is completed)</span>
      </div> */}
    </div>
  )
}

export default DateTime;

DateTime.propTypes = { 
  setState: func.isRequired,
  value: string
}

DateTime.defaultProps = { 
  value: null
}