/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BarsSolidV6 = (props) => (
  <Icon {...props} name="Bars Solid V6">
    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zm13 6H2a1 1 0 1 1 0-2h12a1 1 0 1 1 0 2z" />
  </Icon>
);

export default BarsSolidV6;
