/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CalendarCheckSolidV6 = (props) => (
  <Icon {...props} name="Calendar Check Solid V6">
    <path d="M6 1v1h4V1a1 1 0 1 1 2 0v1h1.5A1.5 1.5 0 0 1 15 3.5V5H1V3.5A1.5 1.5 0 0 1 2.5 2H4V1a1 1 0 1 1 2 0zM1 6h14v8.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V6zm10.253 3.503c.319-.266.319-.741 0-1.034-.266-.291-.741-.291-1.034 0L7.25 11.441 5.753 9.969c-.266-.291-.741-.291-1.034 0-.291.294-.291.769 0 1.034l2 2c.294.319.769.319 1.034 0l3.5-3.5z" />
  </Icon>
);

export default CalendarCheckSolidV6;
