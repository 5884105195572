import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import feedbackShape from "../../../Main/Feedback/model/feedback";
import { HeartFilled } from "../../../../shared/v2/Icomoon";

const MarketingBody = ({ reviewCount, feedbackTabPath, mostRecentFeedback }) => {
  const additionalDivs = Math.min(2, reviewCount - 1);
  // Vary the z-index of the additional divs to accomplish the cards stack from the design
  const additionalDivsZIndex = ["tw-z-5", "tw-z-3"];

  return (
    <Link
      to={feedbackTabPath}
      className="hover:tw-no-underline active:tw-no-underline focus:tw-no-underline"
      data-cy="marketing-body"
    >
      <div
        className="tw-relative tw-rounded-8px tw-p-24px tw-bg-white tw-shadow-tdp-card tw-z-10"
        data-cy="marketing-feedback-card"
      >
        <div className="tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center tw-mb-12px">
          <span
            className="tw-font-normal tw-text-12d tw-text-neutral-gray-75"
            data-cy="marketing-feedback-card-most-recent"
          >
            Most Recent
          </span>
          <span
            className="tw-font-normal tw-text-12d tw-text-neutral-gray-75"
            data-cy="marketing-feedback-card-date"
          >
            {mostRecentFeedback.shownAt.format("MM/DD/YY")}
          </span>
          <span className="tw-inline-flex tw-flex-row tw-items-center tw-space-x-4px tw-font-normal tw-text-14d">
            <span className="tw-text-neutral-gray-75" data-cy="marketing-feedback-card-rating">
              {mostRecentFeedback.liked}
            </span>
            <HeartFilled size="l" className="tw-text-semantic-red-100" />
          </span>
        </div>
        <p
          className="tw-line-clamp-6 tw-font-normal tw-text-14d tw-text-neutral-gray-75 tw-m-0"
          data-cy="marketing-feedback-card-content"
        >
          &quot;{mostRecentFeedback.body}&quot;
        </p>
      </div>
      {Array(additionalDivs)
        .fill(1)
        .map((_x, i) => (
          <div
            // Disabling react/no-array-index-key ESLint rule due to only rendering at least two
            // items, and the reordering/shifting issues due to the use of indexes as keys will not
            // affect our use case here
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={`tw-relative tw-rounded-8px tw-p-10px tw-bg-white tw-shadow-tdp-card tw--mt-10px ${additionalDivsZIndex[i]}`}
            data-cy="marketing-feedback-additional-card"
          />
        ))}
    </Link>
  );
};

MarketingBody.propTypes = {
  reviewCount: PropTypes.number.isRequired,
  feedbackTabPath: PropTypes.string.isRequired,
  mostRecentFeedback: feedbackShape.isRequired,
};

export default MarketingBody;
