import React, { useRef } from "react";
import PropTypes from "prop-types";
import { textAreaStyleSchema, textAreaResizeSchema, fieldsetStyleSchema } from "./TextArea.styles";

const schemaBasedClasses = (schemaName) => fieldsetStyleSchema[schemaName] || fieldsetStyleSchema.default;

const TextArea = ({
  value,
  trailing,
  error,
  textAreaRef,
  onFieldClick,
  onFocusIn,
  onFocusOut,
  className,
  textAreaClassName,
  rows,
  resize,
  ...otherProps
}) => {
  const fieldsetRef = useRef();
  const textAreaElementRef = textAreaRef || useRef();

  const classes = `
    ${schemaBasedClasses(error ? "error" : "regular")}
    ${className}
  `;

  const handleOnMouseDown = (e) => {
    if (!fieldsetRef.current || !textAreaElementRef.current) {
      return;
    }

    // Allow the input element handle the mousedown event
    if (textAreaElementRef.current === e.target) {
      return;
    }

    // Prevent this mouse down event to continue since the current target is the fieldset element
    if (fieldsetRef.current === e.currentTarget) {
      e.preventDefault();
    }
  };

  const handleOnBlur = (e) => {
    const { currentTarget } = e;

    // Give browser time to focus the next element
    window.requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        onFocusOut();
      }
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-space-y-[4px] tw-max-w-full" data-cy="text-area-container">
      {/* Using fieldset to leverage the disabled attribute feature */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <fieldset
        ref={fieldsetRef}
        className={classes}
        onClick={onFieldClick}
        onMouseDown={handleOnMouseDown}
        role="presentation"
        {...(onFocusIn ? { onFocus: onFocusIn } : {})}
        {...(onFocusOut ? { onBlur: handleOnBlur } : {})}
      >
        <div className="tw-pt-[8px] tw-pb-[8px]">
          <textarea
            ref={textAreaElementRef}
            value={value}
            className={`
              tw-border-gray-30 tw-border-solid tw-border-1px tw-pl-12px tw-pr-12px tw-pt-8px tw-pb-8px
              ${textAreaStyleSchema.regular}
              ${textAreaResizeSchema(resize)}
              ${textAreaClassName}
            `}
            rows={rows}
            data-cy="text-area"
            {...otherProps}
          />
          <div className="tw-w-full">{trailing}</div>
        </div>
      </fieldset>
      {error && (
        <span className="tw-text-12d tw-text-semantic-red-100 tw-font-regular" data-cy="text-area-error">
          {error}
        </span>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  error: null,
  textAreaRef: null,
  onFieldClick: null,
  onFocusIn: null,
  onFocusOut: null,
  className: "",
  textAreaClassName: "",
  rows: 5,
  resize: "none",
};

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  trailing: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  error: PropTypes.string,
  onFieldClick: PropTypes.func,
  onFocusIn: PropTypes.func,
  onFocusOut: PropTypes.func,
  // See https://github.com/facebook/prop-types/issues/240#issue-384666636
  textAreaRef: PropTypes.oneOfType([
    PropTypes.func, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
  rows: PropTypes.number,
  resize: PropTypes.oneOf(["none", "both", "horizontal", "vertical"]),
};

export default TextArea;
