import React from "react";

import RingChart from "../../shared/v2/RingChart/RingChart";
import Tooltip from "../../shared/v2/Tooltip";
import CircleInfoSolidV6 from "../../shared/v2/Icomoon/Icons/CircleInfoSolidV6";
import Button from "../../shared/v2/Button";
import { durationDisplay } from "./helpers";
import AvatarGroup from "@shared/v2/AvatarGroup";
import Avatar from "@shared/v2/Avatar";
import { format } from "date-fns";

const top6ChartColors = ["#59c4c4", "#ffa826", "#46c771", "#3270fa", "#8e57fa", "#fa64af"];

const top6ChartColorClasses = [
  "tw-bg-teal",
  "tw-bg-yellow-100",
  "tw-bg-green-100",
  "tw-bg-semantic-blue-100",
  "tw-bg-semantic-purple-50",
  "tw-bg-semantic-pink-50",
  "tw-bg-gray-15",
];

const otherChartColor = "#e0e0e0";

const ringChartData = (trackingNumbers, totalCalls) => {
  // take top 6 data points
  // for 7+, roll up into single data point
  // "chartValueSoFar" is to skip over the graph space we've occupied so far...RingChart does not do this for us
  let data = [];
  let chartValueSoFar = 0;
  const top6 = trackingNumbers.slice(0, 6);
  for (let i = 0; i < top6.length; i++) {
    data.push({
      color: top6ChartColors[i],
      value: (chartValueSoFar + top6[i].totalCalls) / totalCalls,
      tooltip: ["", `${top6[i].totalCalls} calls`],
    });
    chartValueSoFar += top6[i].totalCalls;
  }

  const others = trackingNumbers.slice(6);
  if (others.length > 0) {
    let othersCallTotal = 0;
    for (const tn of others) {
      othersCallTotal += tn.totalCalls;
    }

    const othersData = {
      color: otherChartColor,
      value: (chartValueSoFar + othersCallTotal) / totalCalls,
      tooltip: ["", `${othersCallTotal} calls`],
    };
    data.push(othersData);
  }

  data.sort((a, b) => a.value - b.value);
  return data;
};

const Dot = ({ className }) => (
  <span className={`tw-inline-flex tw-w-[12px] tw-h-[12px] tw-rounded-full ${className}`} />
);

const TrackingNumbersListEmptyState = ({ isAdmin, showSettingsPage }) => {
  return (
    <div className="tw-py-32px tw-pl-32px tw-pr-40px tw-w-[330px] tw-text-center tw-border-solid tw-border-r tw-border-0 tw-border-neutral-gray-10">
      {isAdmin ? (
        <>
          <div className="tw-mb-8px">No Tracking Numbers Set Up</div>
          <Button schema="secondary" size="small" onClick={showSettingsPage}>
            EDIT TRACKING NUMBERS
          </Button>
        </>
      ) : (
        <div className="tw-mt-[15px]">No Tracking Numbers Assigned</div>
      )}
    </div>
  );
};

const List2 = ({ stats }) => {
  return (
    <div className="tw-pl-32px tw-overflow-ellipsis tw-w-[192px]">
      {stats.trackingNumbers.slice(4, 7).map((tn, index) => (
        <div key={`list2-${tn.name}`} className="tw-pb-8px tw-truncate">
          <Dot className={`${top6ChartColorClasses[index + 4]} tw-mr-4px`} />
          <span>{tn.name}</span>
        </div>
      ))}
    </div>
  );
};

const TrackingNumbersList = ({ stats }) => {
  return (
    <div className="tw-pl-32px tw-pr-40px tw-border-solid tw-border-r tw-border-0 tw-border-neutral-gray-10 tw-flex">
      <div className="tw-w-[160px]">
        {stats.trackingNumbers?.slice(0, 4).map((tn, index) => (
          <div key={`tnList-${tn.name}`} className="tw-pb-8px tw-truncate">
            <Dot className={`${top6ChartColorClasses[index]} tw-mr-4px`} />
            <span>{tn.name}</span>
          </div>
        ))}
      </div>
      {stats.trackingNumbers?.length > 4 ? <List2 stats={stats} /> : ""}
    </div>
  );
};

const SingleNumberInfo = ({ agents, trackingNumber }) => {
  const mappedAgents = agents.filter((a) => a.routes?.includes(trackingNumber.id));

  return (
    <div className="tw-flex tw-flex-col tw-whitespace-nowrap tw-border-solid tw-border-r tw-border-0 tw-border-neutral-gray-10 tw-pl-32px tw-pr-40px tw-gap-8px tw-text-12px">
      <div>
        <span className="tw-text-gray-50">Total Leads Created: </span>
        <span className="tw-text-gray-75">{trackingNumber.totalLeads || "--"}</span>
      </div>
      <div>
        <span className="tw-text-gray-50">Created: </span>
        <span className="tw-text-gray-75">{format(new Date(trackingNumber.createdAt), "LLLL  do, y")}</span>
      </div>
      <span>Agents Routed:</span>
      <AvatarGroup size="medium">
        {mappedAgents.map((agent, i) => (
          <Avatar
            key={`${agent.full_name}-${i}`}
            size="medium"
            src={agent.avatar_url?.length > 3 ? agent.avatar_url : ""}
            alt={agent.full_name}
          />
        ))}
      </AvatarGroup>
    </div>
  );
};

const TrackingNumbersStats = ({ stats, agents, isAdmin, showSettingsPage }) => {
  const statsSection = () => {
    if (stats.trackingNumbers?.length === 1) {
      return <SingleNumberInfo agents={agents} trackingNumber={stats.trackingNumbers[0]} />;
    } else if (stats.trackingNumbers?.length > 1) {
      return <TrackingNumbersList stats={stats} />;
    } else {
      return <TrackingNumbersListEmptyState isAdmin={isAdmin} showSettingsPage={showSettingsPage} />;
    }
  };

  return (
    <div className="tw-relative tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 bs-xs:tw-p-[15px] tw-flex tw-flex-col tw-p-[32px]">
      <div className="tw-flex">
        <RingChart
          data={
            stats.trackingNumbers?.length > 0
              ? ringChartData(stats.trackingNumbers, stats.totalCalls)
              : [{ color: "#e0e0e0", value: 1 }]
          }
          size={118}
          variant="segmented"
          title="Total"
          label={stats.totalCalls > 0 ? stats.totalCalls : "--"}
        />
        {statsSection()}
        <div className="tw-px-40px tw-w-[276px] tw-text-gray-50 tw-border-solid tw-border-r tw-border-0 tw-border-neutral-gray-10">
          <p className="tw-mb-8px tw-font-semibold">Calls</p>
          <p className="tw-text-28px tw-mb-8px tw-text-gray-75">
            {stats.totalCalls > 0 ? stats.totalCalls : "--"}
          </p>
          <p className="tw-text-12px tw-font-semibold">
            Contacts:{" "}
            <span className="tw-text-14px tw-font-normal tw-text-gray-50">
              {stats.talkedCalls > 0 && stats.totalCalls > 0
                ? `${stats.talkedCalls} (${Math.round((stats.talkedCalls / stats.totalCalls) * 100)}%)`
                : "--"}
              <Tooltip
                className="tw-mx-[4px] tw-w-[12px] tw-h-[12px] tw-text-gray-75"
                placement="bottom"
                content="Outcome of Talked To"
                trigger={<CircleInfoSolidV6 />}
              />
            </span>
          </p>
        </div>
        <div className="tw-px-40px tw-w-[276px] tw-text-gray-50 tw-border-solid tw-border-r tw-border-0 tw-border-neutral-gray-10">
          <p className="tw-mb-8px tw-font-semibold">Average Call Duration</p>
          <p className="tw-text-28px tw-mb-8px">
            {stats.totalCallDuration > 0
              ? durationDisplay(Math.round(stats.totalCallDuration / stats.totalCallsWithDuration))
              : "--"}
          </p>
          <p className="tw-text-12px tw-font-semibold">
            Total Call Time:{" "}
            <span className="tw-text-14px tw-font-normal tw-text-gray-50">
              {stats.totalCallDuration > 0 ? durationDisplay(stats.totalCallDuration) : "--"}
            </span>
          </p>
        </div>
        <div className="tw-px-40px tw-w-[276px] tw-text-gray-50">
          <p className="tw-mb-8px tw-font-semibold">New Leads Added</p>
          <p className="tw-text-28px tw-mb-8px">{stats.totalNewLeads > 0 ? stats.totalNewLeads : "--"}</p>
        </div>
      </div>
    </div>
  );
};

export default TrackingNumbersStats;
