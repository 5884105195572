import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import axios from "axios";
import { useEffect, useState } from "react";

const EMPTY_GOALS = {
  avgSalePrice: "0",
  avgCommission: "0",
  sellerCommissionSplit: "0",
  buyerCommissionSplit: "0",
  pendingToCloseRatio: "0",
  sellerToTotalClosingsRatio: "0",
};

const cleanValues = (goals) =>
  Object.entries(goals).reduce((acc, [key, value]) => {
    acc[key] = String(value).replace(/[^0-9.]/g, "");
    return acc;
  }, {});

const transformGoals = (goals) =>
  Object.entries(goals).reduce((acc, [key, value]) => {
    if (["id", "accountId", "createdAt", "updatedAt"].includes(key)) return acc;
    acc[key] = typeof value === "number" ? value.toLocaleString() : value;
    return acc;
  }, {});

const validateGoals = (goals) =>
  Object.entries(goals).reduce((acc, [key, value]) => {
    const cleanValue = String(value).replace(/[^0-9.]/g, "");
    if (cleanValue.length === 0) acc[key] = "Required field";
    else if (!parseFloat(cleanValue)) acc[key] = "Value must be greater than 0";
    return acc;
  }, {});

const getGoals = (...config) =>
  caseTransformingAxios
    .get("/api/v4/goals/show_wizard_team_defaults", ...config)
    .then((res) => transformGoals(res.data));

const upsertGoals = (goals, ...config) =>
  caseTransformingAxios
    .post(
      "/api/v4/goals/upsert_wizard_team_defaults",
      { goalWizardTeamDefaults: cleanValues(goals) },
      ...config,
    )
    .then((res) => transformGoals(res.data));

const useProductionGoals = ({ show }) => {
  const [goals, setGoals] = useState({ ...EMPTY_GOALS });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      const abortController = new AbortController();
      setLoading(true);
      setErrors({});
      getGoals({ signal: abortController.signal })
        .then((data) => {
          setLoading(false);
          setGoals(data);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setLoading(false);
            if (err.response.status === 404) return;
            setErrors({ global: "Failed to load Goals" });
          }
        });
      return () => {
        abortController.abort();
      };
    }

    setErrors({});
    return () => {};
  }, [show]);

  return {
    actions: {
      saveGoals: () => {
        const newErrors = validateGoals(goals);
        setErrors(newErrors);
        if (!Object.keys(newErrors).length) {
          setLoading(true);
          return upsertGoals(goals)
            .then((data) => {
              setLoading(false);
              setGoals(data);
              return true;
            })
            .catch(() => {
              setLoading(false);
              setErrors({ global: "Failed to save Goals, try again later." });
              return false;
            });
        }
        return Promise.resolve(false);
      },
      setGoals,
    },
    errors,
    goals,
    loading,
  };
};

export default useProductionGoals;
