import { getSearchAreaResults } from "../../services";

export const GEO_TYPE_LABEL = {
  place: "Place",
  "census-place": "Census City",
  county: "County",
  neighborhood: "Neighborhood",
  "postal-city": "City",
  state: "State",
  township: "Township",
  zipcode: "Zip Code",
  "school-district": "School District",
  // Additional Canadian types
  "census-division": "Census Division",
  "census-subdivision": "Census Subdivision",
};
export const getGeoLabel = (type) => GEO_TYPE_LABEL[type] || "Address";
const GEO_TYPES_TO_OMIT = ["census-place", "place"];
const omitGeoType = (result) =>
  GEO_TYPES_TO_OMIT.some((omitPhrase) => result.areaType.indexOf(omitPhrase) > -1);

const groupResults = (results) => {
  const sections = {};
  if (results.data.errors || !Array.isArray(results.data)) {
    return [];
  }
  results.data.forEach((p) => {
    if (omitGeoType(p)) {
      return;
    }
    const section = getGeoLabel(p.areaType);
    if (!sections[section]) {
      sections[section] = [];
    }
    sections[section].push(p);
  });
  return Object.keys(sections)
    .map((k) => ({
      label: k,
      options: sections[k],
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
export const callApi = (val, mlsCountry, formattedDefaultOptions, primaryAgentUuid) =>
  new Promise((resolve) => {
    if (val > "") {
      getSearchAreaResults({
        data: { search: val, mlsCountry, max_result: 12, primary_agent_uuid: primaryAgentUuid },
        callback: (results) => {
          resolve(groupResults(results));
        },
      });
    } else {
      resolve([
        {
          label: "Contact Addresses",
          options: formattedDefaultOptions,
        },
      ]);
    }
  });
