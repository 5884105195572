/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import PropTypes from "prop-types";
import FieldPhoneV1 from "../../../shared/UtilsV1/FieldPhoneV1";
import FieldSelect from "../../../shared/UtilsV1/FieldSelect";
import FieldLabelV1 from "../../../shared/UtilsV1/FieldLabelV1";
import Radio from "../../../shared/UtilsV1/FieldRadioV1/Radio";
import RemoveFieldButton from "../../../shared/v2/RemoveFieldButton";
import PhoneNumberModal from "../../PhoneNumberModal";

function PhoneView({ removable, currentPhone, index, required, phoneList, setPersonValue, options }) {
  const [showContext, setShowContext] = useState(null);
  const { category, value: currentPhoneNumber } = currentPhone;
  const isPrimary = currentPhone.position === 1;

  const onPrimaryChange = (phonePosition) => {
    const tempPhoneList = phoneList.map((phone) => {
      if (phone.position === 1) {
        return { ...phone, position: phonePosition };
      }
      if (phone.position === phonePosition) {
        return { ...phone, position: 1 };
      }
      return phone;
    });

    setPersonValue({ key: "phoneDetailsAttributes", value: tempPhoneList });
  };

  const onRemove = (targetPhone) => {
    const filteredPhoneList = phoneList.map((phone) => {
      // Setting value to empty string to get a .changes from ActiveRecord for timeline_publishable
      if (phone === targetPhone) return { ...phone, value: "", _destroy: true };
      return phone;
    });

    if (targetPhone.position === 1) {
      filteredPhoneList[0].position = 1;
    }
    setPersonValue({ key: "phoneDetailsAttributes", value: filteredPhoneList });
  };

  const updatePhone = (targetPhone, value) =>
    setPersonValue({
      key: "phoneDetailsAttributes",
      value: phoneList.map((phone) => {
        if (phone !== targetPhone) return phone;
        return { ...phone, ...value };
      }),
    });

  return currentPhone?._destroy ? null : (
    <div className="tw-flex">
      <div className="tw-flex tw-gap-[24px] tw-items-center tw-flex-auto">
        <FieldPhoneV1
          label="Phone"
          value={currentPhoneNumber}
          onChange={(value) => updatePhone(currentPhone, { value })}
          required={required && index === 0}
        />
        <div className="tw-flex tw-gap-[8px] tw-flex-1">
          <FieldSelect
            label="Phone Type"
            options={options}
            placeholder="Choose One"
            onChange={(value) => updatePhone(currentPhone, { category: value })}
            value={category}
          />

          {removable && (
            <RemoveFieldButton
              onClick={() => {
                if (currentPhone.archiveConversationsCount > 0) {
                  setShowContext({
                    changedNumbers: [{ previousNumber: currentPhoneNumber }],
                    conversationCount: currentPhone.archiveConversationsCount,
                  });
                } else {
                  onRemove(currentPhone);
                }
              }}
              className="tw-mb-1px tw-mt-auto"
            />
          )}
        </div>
      </div>

      <div>
        <FieldLabelV1 label="Primary" />
        <div
          aria-label="primary"
          className="tw-flex tw-justify-center tw-items-center tw-py-4"
          onClick={() => onPrimaryChange(currentPhone.position)}
          onKeyDown={() => onPrimaryChange(currentPhone.position)}
          role="button"
          tabIndex="0"
        >
          <Radio checked={isPrimary} />
        </div>
      </div>
      <PhoneNumberModal
        showContext={showContext}
        onClose={(phoneRemovalAck) => {
          if (phoneRemovalAck) onRemove(currentPhone);
          setShowContext(null);
        }}
      />
    </div>
  );
}
const phonePropTypeShape = PropTypes.shape({
  category: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  position: PropTypes.number,
  value: PropTypes.string,
  _destroy: PropTypes.bool,
  archiveConversationsCount: PropTypes.number,
});
PhoneView.propTypes = {
  removable: PropTypes.bool,
  currentPhone: phonePropTypeShape,
  index: PropTypes.number,
  phoneList: PropTypes.arrayOf(phonePropTypeShape),
  required: PropTypes.bool,
  setPersonValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

PhoneView.defaultProps = {
  removable: false,
  currentPhone: null,
  index: null,
  required: false,
  phoneList: [],
  options: [],
};

export default PhoneView;
