import React from "react";
import PropTypes from "prop-types";
import Toggle from "@shared/v2/Toggle";

const CreateTransaction = ({ handleToggleTransaction, isCreateTransaction, selectedAgreementName }) => (
  <div className="tw-pb-24px tw-pt-4px">
    <Toggle
      id="transaction-creation"
      name="transaction-creation"
      value={isCreateTransaction}
      checked={isCreateTransaction}
      onChange={handleToggleTransaction}
      text={`Create ${selectedAgreementName === "referral" ? "Active" : "Pipeline"} ${selectedAgreementName} Transaction`}
      labelClassName="tw-text-[14px] !tw-text-gray-50 tw-font-semibold tw-capitalize"
    />
  </div>
);

CreateTransaction.propTypes = {
  handleToggleTransaction: PropTypes.func,
  isCreateTransaction: PropTypes.bool,
  selectedAgreementName: PropTypes.string,
};
CreateTransaction.defaultProps = {
  handleToggleTransaction: () => {},
  isCreateTransaction: false,
  selectedAgreementName: "buyer",
};
export default CreateTransaction;
