import React from "react";

import { CommentDotsSolidV6 } from "@shared/v2/Icomoon";
import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";

const TextActivity = ({ activity, onReadChange }) => {
  const queryParams = new URLSearchParams();
  queryParams.set("tab", "3");
  return (
    <ActivitiesContainer
      activity={activity}
      Icon={CommentDotsSolidV6}
      title={`${activity.title} ${activity.subtitle}`}
      onReadChange={onReadChange}
      queryParams={queryParams.toString()}
    >
      {activity.data.messageText}
    </ActivitiesContainer>
  );
};

TextActivity.propTypes = {
  ...activityCommonPropTypes,
};

TextActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default TextActivity;
