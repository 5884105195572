import React from "react";
import { arrayOf, string, shape } from "prop-types";

import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";

const SourceFilter = ({ selectedOptions, options }) => {
  const hashOptions = options.reduce((acc, { name, id }) => {
    acc[id] = name;
    return acc;
  }, {});

  return (
    <DropdownWithMultiCtrlledRemovable
      options={options.map(({ name, id }) => ({ label: name, value: `${id}` }))}
      selectedOptions={selectedOptions?.map((id) => ({ label: hashOptions[id], value: `${id}` }))}
      removablePrefix="Source: "
      placeHolderTxt=" sources selected"
      ctrlName="leads_filter[selected_sources][]"
    />
  );
};

export default SourceFilter;

SourceFilter.propTypes = {
  selectedOptions: arrayOf(string),
  options: arrayOf(shape({})),
};

SourceFilter.defaultProps = {
  selectedOptions: [],
  options: [],
};
