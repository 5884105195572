import React from 'react';
import { LeadSourceRow, LeadSourceTotalRow   } from './';

const LeadSourcesHolder = (props) => {
  return(
    <div>
      {
        props.sources && props.sources.map((s,i) =>
        <LeadSourceRow
          key={i}
          source={s}
          isOtherSource={props.isOtherSource} />
      )
    }
      { props.statusCounts &&
        <LeadSourceTotalRow
          statusCounts={props.statusCounts}
          totalLeadCount={props.totalLeadCount}/>
      }
  </div>
)
}
export default LeadSourcesHolder;
