import React from "react";
import PropTypes from "prop-types";

const DataLabel = ({ title, value, label = value, isLink }) => (
  <div className="tw-flex tw-items-center tw-mb-[8px]">
    <p className="tw-text-[14px] tw-font-semibold tw-text-neutral-gray-50 tw-w-[90px]">{title}</p>
    {isLink ? (
      <a className="tw-text-[14px] tw-text-semantic-blue-100 tw-flex-1" href={value}>
        {label}
      </a>
    ) : (
      <p className="tw-text-[14px] tw-text-neutral-75">{value}</p>
    )}
  </div>
);

DataLabel.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
};
DataLabel.defaultProps = {
  isLink: false,
  label: null,
};

export default DataLabel;
