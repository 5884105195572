import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bool, func, string, shape, arrayOf } from "prop-types";
import { uniqBy } from "lodash";
import Modal from "../../../../shared/v2/Modal/Modal";
import Button from "../../../../shared/v2/Button";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import { patchPersonAsThunk } from "../actions/thunks";
import ErrorBanner from "../../../../shared/v2/ErrorBanner";
import AccessViewer from "../AccessViewer";
import SubscribeClient from "../SubscribeClient";
import { clearError } from "../actions/creators";
import PrimaryAgentCheckbox from "../PrimaryAgentCheckbox";

const EditModal = ({
  show,
  onHide,
  title,
  onPatchPersonAsThunk,
  roles,
  uuid,
  personData,
  isCollaborator,
  isClient,
  category,
  error,
  onClearError,
  type,
}) => {
  const generateRolesDropdown = () => {
    const rolesDropdown = [];
    switch (title) {
      case "Client":
        rolesDropdown.push(...roles.client);
        break;
      case "Collaborator":
        rolesDropdown.push(...roles.collaborator);
        break;
      case "Team Member":
        rolesDropdown.push(...roles.team_member);
        break;
      default:
        break;
    }

    return rolesDropdown.map((role) => ({
      value: role?.id,
      label: role?.title,
    }));
  };

  const [selectedRole, setSelectedRole] = useState({
    label: personData.role?.title,
    value: personData.role?.id,
  });
  const [toggleViewerAccess, setToggleViewerAccess] = useState(personData.viewer_portal_access);
  const [toggleEmailUpdate, setToggleEmailUpdate] = useState(personData.email_updates);
  const [deselectedEmailUpdates, setDeselectedEmailUpdates] = useState(false);
  const [person, setPerson] = useState({ person: {} });

  useEffect(() => {
    setPerson({
      person: {
        external: !!(isClient || isCollaborator),
        collaborator: !!isCollaborator,
        portal_access: toggleViewerAccess,
        gets_updates: toggleEmailUpdate,
        role_id: selectedRole?.value,
        role_label: selectedRole?.label,
        primary: personData.primary,
        deselected_email_updates: deselectedEmailUpdates,
      },
    });
  }, [selectedRole, toggleViewerAccess, toggleEmailUpdate, personData]);

  const onChangeSetToggle = () => {
    setDeselectedEmailUpdates(toggleEmailUpdate);
    setToggleEmailUpdate(!toggleEmailUpdate);
  };

  const handleSave = () => {
    onPatchPersonAsThunk(person, uuid, personData.listing_person_id, category).then(
      () => (!error || error?.length === 0) && onHide(),
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        onClearError();
        setToggleViewerAccess(personData.viewer_portal_access);
        setToggleEmailUpdate(personData.email_updates);
      }}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[496px]"
    >
      <Modal.Header title={`Edit ${title}`} closeButton />
      <Modal.Body className="tw-flex tw-flex-col">
        {error && <ErrorBanner errors={error} />}
        <div className="tw-mt-12px tw-mb-28px">
          <div className="tw-text-14px tw-font-semibold tw-leading-20px tw-tracking-normal">
            {personData.name}
          </div>
          <div className="tw-text-14px tw-font-normal tw-leading-20px tw-tracking-normal">
            {personData.email_address}
          </div>
        </div>
        <label
          htmlFor="role"
          className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]"
        >
          Role <span className="tw-text-coral">*</span>
        </label>
        <Dropdown
          isSearchable
          onChange={setSelectedRole}
          placeholder=""
          name="role"
          options={generateRolesDropdown()}
          value={selectedRole}
          className="tw-bg-gray-10"
        />
        <div className="tw-mt-34px">
          <AccessViewer
            isCollaborator={isCollaborator}
            isClient={isClient}
            toggleViewerAccess={toggleViewerAccess}
            onChangeSetToggle={() => setToggleViewerAccess((prev) => !prev)}
            isDisabled={personData.viewer_portal_access || !personData.email_address}
          />
          <SubscribeClient
            isOpen
            isClient={isClient}
            toggleEmailUpdate={toggleEmailUpdate}
            onChangeSetToggle={onChangeSetToggle}
            isDisabled={!personData.email_address}
          />
          {category === "team_members" && (
            <PrimaryAgentCheckbox
              checked={person.person.primary}
              disabled={personData.primary}
              onClickCheckBox={() =>
                setPerson((prev) => ({
                  ...prev,
                  person: {
                    ...prev.person,
                    primary: !prev.person.primary,
                  },
                }))
              }
              show={type !== "referral"}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-mt-[42px] tw-flex tw-justify-between">
        <Button
          type="button"
          size="medium"
          schema="tertiary"
          onClick={() => {
            onHide();
            onClearError();
            setToggleViewerAccess(personData.viewer_portal_access);
            setToggleEmailUpdate(personData.email_updates);
          }}
        >
          CANCEL
        </Button>

        <Button type="button" size="medium" schema="primary" onClick={handleSave}>
          SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  roles: {
    client: uniqBy(state.tdpPeopleReducer.people.roles?.client, "title"),
    collaborator: uniqBy(state.tdpPeopleReducer.people.roles?.collaborator, "title"),
    team_member: uniqBy(state.tdpPeopleReducer.people.roles?.team_member, "title"),
  },
  uuid: state.tdpDetailsReducer.uuid,
  error: state.tdpPeopleReducer.error.error,
  closeModal: state.tdpPeopleReducer.closeModal,
  type: state.tdpDetailsReducer.transactionDetails.type,
});

const mapDispatchToProps = (dispatch) => ({
  onPatchPersonAsThunk: (person, uuid, id, category) =>
    dispatch(patchPersonAsThunk(person, uuid, id, category)),
  onClearError: () => dispatch(clearError()),
});

EditModal.propTypes = {
  show: bool,
  onHide: func,
  title: string,
  onPatchPersonAsThunk: func,
  roles: shape({
    client: arrayOf(shape({})),
    collaborator: arrayOf(shape({})),
    team_member: arrayOf(shape({})),
  }),
  uuid: string,
  personData: shape({}),
  isCollaborator: bool,
  isClient: bool,
  category: string,
  error: [],
  onClearError: func,
  type: string,
};

EditModal.defaultProps = {
  show: false,
  onHide: () => {},
  title: "",
  onPatchPersonAsThunk: () => {},
  roles: {},
  uuid: "",
  personData: {},
  isCollaborator: false,
  isClient: false,
  category: "",
  error: [],
  onClearError: () => {},
  type: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
