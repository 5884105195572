import { produce } from "immer";
import { saveTimeline, setActiveFilter, setEventsLoadingOff, setEventsLoadingOn } from "../TransactionDetailsPage/Main/Timeline/actions/creators";

export const defaultState = {
  uuid: '',
  timeline: {
    byId: {}
  },
  activeEvents: [],
  tags: {
    byName: {
      Tasks: { count: 0, eventsById: [] },
      Emails: { count: 0, eventsById: [] },
      'Phone Calls': { count: 0, eventsById: [] },
      Texts: { count: 0, eventsById: [] },
      Appointments: { count: 0, eventsById: [] },
      Notes: { count: 0, eventsById: [] },
      Documents: { count: 0, eventsById: [] },
      'Transaction Activity': { count: 0, eventsById: [] },
    }
  },
  areEventsLoading: true,
}

export const tdpTimelineReducer = ( state = defaultState, payload ) => {
  switch( payload.type ){
    case saveTimeline().type:
      return produce( state, draft => {
        draft.timeline = {...payload.timeline};
        draft.activeEvents = Object.entries(payload.timeline.byId).map(([_,event]) => event);
        draft.tags.byName = {...state.tags.byName, ...payload.tags.byName}
        draft.areEventsLoading = false;
      });
    case setActiveFilter().type:
      return produce( state, draft => {
        const { tagName } = payload;
        const eventIdByTagName = state.tags.byName[tagName]?.eventsById;

        draft.activeEvents = tagName == 'All' ?
          Object.entries(state.timeline.byId).map(([_,event]) => event) :
          eventIdByTagName.map(id => state.timeline.byId[id]);
      });
    case setEventsLoadingOn().type:
      return produce(state, draft => {
        draft.areEventsLoading = true;
      })
    case setEventsLoadingOff().type:
      return produce(state, draft => {
        draft.areEventsLoading = false;
      })
    default:
      return state;
    }
};

export default tdpTimelineReducer;
