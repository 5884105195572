// eslint-disable-next-line import/no-cycle
import { Agent, Address } from "./index";

class Listing {
  constructor({
    id,
    uuid,
    title,
    address,
    closeDate,
    closePrice,
    canceledDate,
    dateListed,
    dateExpired,
    mutualAcceptanceDate,
    dom,
    grossCommission,
    lastUpdated,
    listingPrice,
    mlsNumber,
    newMilestones,
    status,
    statusChangedAt,
    photoURL,
    sourceId,
    agent,
    type,
    useCustomTitle,
    showUrl,
    editUrl,
    showURL,
    editURL,
    failedAt,
  }) {
    this.id = id;
    this.uuid = uuid;
    this.title = title;
    this.address = new Address(address);
    this.closeDate = closeDate;
    this.closePrice = closePrice;
    this.canceledDate = canceledDate;
    this.dateListed = dateListed;
    this.dateExpired = dateExpired;
    this.mutualAcceptanceDate = mutualAcceptanceDate;
    this.dom = dom;
    this.grossCommission = grossCommission;
    this.lastUpdated = lastUpdated;
    this.listingPrice = listingPrice;
    this.newMilestones = newMilestones;
    this.mlsNumber = mlsNumber;
    this.status = status;
    this.statusChangedAt = statusChangedAt;
    this.photoURL = photoURL;
    this.sourceId = sourceId;
    this.type = type;
    this.useCustomTitle = useCustomTitle;

    this.agent = new Agent(agent);
    this.showURL = showUrl || showURL;
    this.editURL = editUrl || editURL;
    this.isDeletable = this.status === "archived";
    this.failedAt = failedAt;
  }
}

export default Listing;
