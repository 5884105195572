import React from "react";
import { func } from "prop-types";

import { Reset, Download } from "../../../../shared/v2/Icomoon";
import ZoomCtrls from "./fractals";

const BUTTON_STYLE = "tw-bg-transparent tw-border-none tw-p-0 tw-cursor-pointer";

const ListingImgPreviewCtrls = ({ setMirrored, centerView, onDownload }) => (
  <div className="tw-flex tw-justify-center tw-items-center tw-gap-24px tw-mx-auto tw-mt-[62px]">
    <button
      className={BUTTON_STYLE}
      onClick={() => setMirrored((prev) => !prev)}
      type="button"
      aria-label="reset"
    >
      <Reset className="tw--scale-x-100" size="xxl" />
    </button>
    <ZoomCtrls centerView={centerView} />
    <button className={BUTTON_STYLE} type="button" onClick={onDownload} aria-label="download">
      <Download size="xxl" />
    </button>
  </div>
);

ListingImgPreviewCtrls.propTypes = {
  setMirrored: func,
  centerView: func,
  onDownload: func,
};

ListingImgPreviewCtrls.defaultProps = {
  setMirrored: () => {},
  centerView: () => {},
  onDownload: null,
};

export default ListingImgPreviewCtrls;
