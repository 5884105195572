import { useState, useEffect } from "react";

export default (payloadHandlrs, priority) => {
  const [selectedRadio, setSelectedRadio] = useState(9);

  useEffect(() => {
    if (priority) setSelectedRadio(priority);
  }, []);

  const selectedRadioHandlr = (idx) => {
    setSelectedRadio(idx + 1);
    payloadHandlrs((prev) => ({
      ...prev,
      priority: idx + 1,
    }));
  };

  return [selectedRadio, selectedRadioHandlr];
};
