import React from "react";
import { Col } from "../../shared/v1";
import { Close } from "../../shared/v2/Icomoon";
import IconButton from "../../shared/v2/IconButton";
import TextInput from "../../shared/v2/TextInput";

const TaskNotes = ({ toggleNotes, task, handleTaskChange }) => (
  <Col size={12} className="tw-mb-[16px]">
    <IconButton
      className="tw-absolute tw-top-0 tw-right-[10px]"
      size="small"
      schema="transparent"
      onClick={toggleNotes}
    >
      <Close />
    </IconButton>
    <TextInput
      multiline
      size="auto"
      name="note"
      label="Notes"
      value={task.note || ""}
      onChange={handleTaskChange}
    />
  </Col>
);

export default TaskNotes;
