import React from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { SortableTeamMemberSplitInput } from "./SortableTeamMemberSplitInput";
import { SortableTeamMemberExpenseInput } from "./SortableTeamMemberExpenseInput";
import { connect } from "react-redux";
import { setFinancial } from "../../actions/transaction";

const mapDispatchToProps = dispatch => ({
  setFinancial: listingData => dispatch(setFinancial(listingData)),
})

const SortableItem = connect(null, mapDispatchToProps)(sortableElement(props => 
  props.value.user_id !== undefined ? 
    SortableTeamMemberSplitInput(props) : 
    SortableTeamMemberExpenseInput(props)
  )
);

const SortableContainer = sortableContainer(
  ({ income, listingData, setFinancialData, parentIndex, recalculate_team_member_net_income }) => {
    return (
      <div>
        {[income]
          .concat(income.custom_team_member_expenses_attributes)
          .sort((el1, el2) => el1.position - el2.position)
          .map(
            (value, index) =>
              !value._destroy && (
                <SortableItem
                  key={`office-${index}`}
                  index={index}
                  value={value}
                  sortIndex={index}
                  listingData={listingData}
                  setFinancialData={setFinancialData}
                  parentIndex={parentIndex}
                  recalculate_team_member_net_income={recalculate_team_member_net_income}
                  income={income}
                />
              ),
          )}
      </div>
    );
  },
);

export default SortableContainer;
