import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "../../shared/v1";
import Loader from "./Loader";
import QuickStat from "./QuickStat";
import useQuickStats from "../services/useQuickStats";
import Error from "../../Reports/components/DashboardShared/Error";
import IconButton from "../../shared/v2/IconButton";
import { ArrowsRotateSolidV6 } from "../../shared/v2/Icomoon";

const DATE_RANGES = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "last_7_days", label: "Last 7 Days" },
  { value: "last_14_days", label: "Last 14 Days" },
  { value: "last_30_days", label: "Last 30 Days" },
  { value: "this_month", label: "This Month" },
  { value: "last_month", label: "Last Month" },
  { value: "this_year", label: "This Year" },
  { value: "last_year", label: "Last Year" },
  { value: "all_time", label: "All Time" },
];

const LeadQuickStats = ({ dateRange: initialDateRange, userUuids }) => {
  const [dateRange, setDateRange] = useState(initialDateRange);
  const { actions, loading, error, leadStats } = useQuickStats({ dateRange, userUuids });

  return (
    <div className="tw-relative tw-flex tw-flex-col tw-gap-[16px] tw-bg-white tw-pt-[16px] tw-px-[20px] tw-pb-[28px] tw-min-h-[237px] tw-rounded-[8px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <h3 className="tw-whitespace-nowrap tw-m-0 tw-text-18d tw-font-normal">
          Opportunities
          <IconButton schema="transparent" onClick={() => actions.reload()} title="Refresh">
            <ArrowsRotateSolidV6 />
          </IconButton>
        </h3>
        <Dropdown
          className="tw-max-w-[200px] tw-ml-[8px]"
          name="dashboard_opportunities_date_range"
          onChange={({ value }) => setDateRange(value)}
          value={dateRange}
          options={DATE_RANGES}
          isClearable={false}
        />
      </div>
      <div className="tw-flex tw-overflow-x-auto tw-pb-[5px]">
        {leadStats.map((stat) => (
          <QuickStat key={stat.name} stat={stat} />
        ))}
      </div>
      {loading && <Loader />}
      <Error show={Boolean(error)} onClose={() => actions.setError(null)} />
    </div>
  );
};

LeadQuickStats.propTypes = {
  dateRange: PropTypes.string.isRequired,
  userUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LeadQuickStats;
