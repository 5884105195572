import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "../../../shared/v2/DatePicker";
import Dropdown from "../../../shared/v2/Dropdown";
import { FloatingForm } from "../../../shared/v2/Modal";
import TextInput from "../../../shared/v2/TextInput";
import FormRow from "./FormRow";

const validateForm = (formData) => {
  const errors = {};
  if (!formData.goalTypeId) errors.goalTypeId = "Must select goal type";
  if (!formData.amount) errors.amount = "Must choose an amount";
  if (!errors.amount && formData.amount < 1) errors.amount = "Must choose amount greater than 0";
  return errors;
};

const NewLeadGenActivityModal = ({ goalTypes, open, openForType, onClose, onSubmit }) => {
  const formRef = useRef(null);
  const dropdownRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [goalType, setGoalType] = useState(null);
  const [activityDate, setActivityDate] = useState(moment().format("MM/DD/YYYY"));
  const goalTypeOptions = useMemo(
    () =>
      goalTypes.map((type) => ({
        label: type.displayName,
        value: type.id,
        meta: type,
      })),
    [goalTypes],
  );

  useEffect(() => {
    if (openForType) setGoalType(goalTypeOptions.find((gt) => String(gt.value) === openForType));
  }, [openForType, goalTypeOptions]);

  const resetForm = () => {
    setGoalType(null);
    formRef.current?.reset();
  };

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <FloatingForm
      title="New Activity"
      show={Boolean(openForType) || open}
      primaryOption="Save"
      onPrimary={() => formRef.current?.dispatchEvent(new Event("submit"))}
      onCancel={closeModal}
      closeOnClickOutside
      closeOnEscape
    >
      <form
        ref={formRef}
        className="tw-flex tw-flex-col tw-gap-[24px]"
        onSubmit={(e) => {
          e.preventDefault();
          const formData = Object.fromEntries(new FormData(e.target));
          if (!formData.noteContent.trim()) delete formData.noteContent;
          const formErrors = validateForm(formData);
          if (Object.keys(formErrors).length > 0) setErrors(formErrors);
          else onSubmit(formData).then(closeModal);
        }}
      >
        <FormRow className="tw-items-end">
          <Dropdown
            containerClassName="tw-basis-[40%] tw-grow"
            label="Type"
            isRequired
            name="goalTypeId"
            value={goalType}
            error={errors.goalTypeId}
            menuShouldComeToFront
            onChange={(type) => {
              setErrors(({ goalTypeId, ...currentErrors }) => currentErrors);
              setGoalType(type);
              dropdownRef.current?.clearValue();
            }}
            options={goalTypeOptions}
          />
        </FormRow>

        <FormRow>
          <div className="tw-flex tw-flex-1 tw-flex-col tw-grow">
            <DatePicker
              className="tw-basis-[40%] tw-grow"
              value={activityDate}
              onChange={setActivityDate}
              inputProps={{ size: 9, label: "Date", isRequired: true }}
            />
            <input
              type="hidden"
              name="activityDate"
              value={moment(activityDate, "MM/DD/YYYY").toISOString()}
            />
          </div>
          <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-[4px]">
            <TextInput
              isRequired
              label={`# ${goalType?.label ? `of ${goalType.label}` : ""}`}
              error={errors.amount}
              className="tw-min-w-[150px] tw-w-full"
              name="amount"
              defaultValue={1}
              type="number"
              onKeyDown={(e) => {
                if (/[-.]/.test(e.key)) e.preventDefault();
              }}
              onChange={() => setErrors(({ amount, ...currentErrors }) => currentErrors)}
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="tw-flex tw-flex-1 tw-flex-col">
            <TextInput label="Notes" multiline name="noteContent" />
          </div>
        </FormRow>
      </form>
    </FloatingForm>
  );
};

NewLeadGenActivityModal.propTypes = {
  goalTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  open: PropTypes.bool.isRequired,
  openForType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

NewLeadGenActivityModal.defaultProps = {
  openForType: null,
};

export default NewLeadGenActivityModal;
