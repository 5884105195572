import React from "react";
import PropTypes from "prop-types";

import { PlayOrPauseButton, TrashIcon } from "./AssignedPlanIconButtons";
import AssignedPlanStateLabel from "./AssignedPlanStateLabel";
import { shouldShowPlayOrPauseAndDeleteButtons } from "./PlanDetails/ActionDetails/helpers";

const AssignedPlanRow = ({
  id,
  name,
  state,
  isLoading,
  currentAction,
  totalActionCount,
  onPlanClicked,
  onPlayOrPauseButtonClicked,
  onTrashButtonClicked,
}) => (
  <div
    className="tw-flex tw-justify-between tw-py-16px tw-border-0 tw-border-b tw-border-neutral-gray-10 tw-border-solid"
    data-cy={`plan-row-${id}`}
  >
    <span>
      <button
        onClick={onPlanClicked}
        type="button"
        // TODO: Waiting for confirmation on desired hover/press state from Design.
        className="hover:tw-underline tw-block tw-p-0 tw-bg-transparent tw-border-0 tw-text-left"
      >
        <div className="tw-font-semibold tw-mb-[5px] tw-mr-16px" style={{ overflowWrap: "anywhere" }}>
          {name}
        </div>
      </button>

      <div>
        <b className="tw-uppercase tw-text-12d">Progress:</b> {currentAction} / {totalActionCount}
      </div>
    </span>

    <span>
      <AssignedPlanStateLabel state={state} />

      {shouldShowPlayOrPauseAndDeleteButtons(state) && (
        <div className="tw-w-[54px] tw-ml-auto tw-flex tw-justify-between">
          <PlayOrPauseButton
            isLoading={isLoading}
            isPaused={state === "paused"}
            onClick={onPlayOrPauseButtonClicked}
          />
          <TrashIcon isLoading={isLoading} onClick={onTrashButtonClicked} />
        </div>
      )}
    </span>
  </div>
);
AssignedPlanRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalActionCount: PropTypes.number.isRequired,
  currentAction: PropTypes.number.isRequired,
  onPlanClicked: PropTypes.func.isRequired,
  onPlayOrPauseButtonClicked: PropTypes.func,
  onTrashButtonClicked: PropTypes.func,
};
AssignedPlanRow.defaultProps = {
  onPlayOrPauseButtonClicked: null,
  onTrashButtonClicked: null,
};

export default AssignedPlanRow;
