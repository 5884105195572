import { number, string } from "prop-types";
import React from "react";

const PriorityIndicator = ({ priority, className }) => priority && (
  <div className={`tw-border-[1.5px] tw-border-solid tw-rounded-full tw-border-orange-light tw-text-orange-light tw-w-[20px] tw-h-[20px] tw-flex tw-items-center tw-justify-center ${className}`}>
    {priority}
  </div>
)

export default PriorityIndicator;

PriorityIndicator.propTypes = {
  priority: number,
  className: string
}

PriorityIndicator.defaultProps = {
  priority: null,
  className: ''
}