/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowDownToBracketSolidV6 = (props) => (
  <Icon {...props} name="Arrow Down To Bracket Solid V6">
    <path d="M15 13v-2a1 1 0 0 0-2 0v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2a1 1 0 0 0-2 0v2a3 3 0 0 0 3 3h8c1.656 0 3-1.344 3-3zm-6.294-2.294 4-4a1 1 0 1 0-1.414-1.414L9 7.587V.999a1 1 0 1 0-2 0v6.588L4.706 5.293a.999.999 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.414-.002z" />
  </Icon>
);

export default ArrowDownToBracketSolidV6;
