/* global ActionCable */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CALL_STATES from "../utils/states";
import { useDialerServiceUrl } from "../../reducers/layoutReducer/selectors";
import {
  actionCableUpdateReceived,
  setCallState,
  createDialerSession,
  logCall,
  startRecording,
  stopRecording,
  endCall,
  getPersonAndNumbers,
  checkValidLeads,
  setPhoneNumber,
  clearSession,
  setCallOutcome,
  setComment,
  setLeadUuids,
} from "../../reducers/dialerReducer/dialerReducer";
import {
  useCallOutcome,
  useCallState,
  useConference,
  useError,
  useLeadUuids,
  useLoading,
  usePerson,
  usePhoneNumber,
  usePhoneNumbers,
  useSession,
  useValidLeads,
} from "../../reducers/dialerReducer/selectors";

const useDialer = ({ listingUuid } = {}) => {
  const callOutcome = useCallOutcome();
  const callState = useCallState();
  const conference = useConference();
  const dialerServiceUrl = useDialerServiceUrl();
  const dispatch = useDispatch();
  const error = useError();
  const leadUuids = useLeadUuids();
  const loading = useLoading();
  const person = usePerson();
  const phoneNumber = usePhoneNumber();
  const phoneNumbers = usePhoneNumbers();
  const session = useSession();
  const validLeads = useValidLeads();
  const [disconnect, setDisconnect] = useState(false);
  const disconnectDialer = () => setDisconnect(true);

  useEffect(() => {
    if (!session) return () => {};

    const consumer = ActionCable.createConsumer(`${dialerServiceUrl}/cable?token=${session.token}`);
    consumer.subscriptions.create(
      { channel: "ConferenceChannel", conference_uuid: session.conferenceUuid },
      {
        received(data) {
          dispatch(actionCableUpdateReceived(data));
        },
        connected() {
          this.perform("follow");
        },
      },
    );

    const cleanUp = () => {
      dispatch(clearSession());
      consumer.disconnect();
      setDisconnect(false);
    };

    if (disconnect) {
      cleanUp();
    }

    return () => cleanUp();
  }, [session, disconnect]);

  useEffect(() => {
    if (callState === CALL_STATES.Init) {
      dispatch(setCallOutcome(null));
      dispatch(setComment(""));
    }
    if (callState === CALL_STATES.Started && !session) {
      dispatch(createDialerSession({ listingUuid }));
    }
    if (session) {
      if (callState === CALL_STATES.Ended) dispatch(endCall());
    }
  }, [callState, session]);

  useEffect(() => {
    if (!Array.isArray(leadUuids) || !leadUuids.length) return;
    if (leadUuids.length === 1) dispatch(getPersonAndNumbers(leadUuids[0]));
    else dispatch(checkValidLeads(leadUuids));
  }, [leadUuids]);

  return {
    data: { callOutcome, callState, conference, leadUuids, person, phoneNumbers, phoneNumber, validLeads },
    error,
    loading,
    disconnectDialer,
    actions: {
      endCall: () => dispatch(setCallState(CALL_STATES.Ended)),
      logCall: (opts) => dispatch(logCall(opts)),
      setCallOutcome: (o) => dispatch(setCallOutcome(o)),
      setLeadUuids: (l) => dispatch(setLeadUuids(l)),
      setComment: (n) => dispatch(setComment(n)),
      setPhoneNumber: (n) => dispatch(setPhoneNumber(n)),
      startCall: () => dispatch(setCallState(CALL_STATES.Started)),
      startRecording: () => dispatch(startRecording()),
      stopRecording: () => dispatch(stopRecording()),
    },
  };
};

export default useDialer;
