import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import AgentDetails from "./AgentDetails";
import "../details.scss";
import { getAgentListings } from "../service";
import { calculateAgentStats, formatPrice } from "../helpers";
import { getStatusValues } from "../styleHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const AgentDetailsModal = ({
  mlsId,
  listAgent,
  listOffice,
  onClose,
}) => {
  const [listingData, setListingData] = useState({});
  const [monthsBack, setMonthsBack] = useState("12");
  const [allListings, setAllListings] = useState([]);

  const onDateChosen = (monthsBack) => {
    const filtered = filterListingsByMonthsBack(allListings, monthsBack);

    setMonthsBack(monthsBack);
    setListingData(createListingData(filtered, listAgent.id));
  };

  useEffect(() => {
    const get = async () => {
      const listings = await getAgentListings(mlsId, listAgent.id);
      const filtered = filterListingsByMonthsBack(listings, monthsBack);

      setAllListings(listings);
      setListingData(createListingData(filtered, listAgent.id));
    };

    if (mlsId && listAgent) {
      get();
    }
  }, [listAgent, mlsId]);

  return (
    <Modal
      id="agent-details-modal"
      className="agent-details-modal"
      dialogClassName="tw-w-[760px] tw-m-0 tw-fixed tw-h-full tw-right-0"
      show={true}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="tw-flex tw-flex-row tw-w-full tw-items-center">
            <span className="tw-font-bold tw-text-gray-99 tw-text-32px">{listAgent.name}</span>
            <div className="tw-flex tw-items-center tw-justify-center tw-h-32px tw-w-32px tw-ml-auto tw-bg-color-ebebeb tw-bg-opacity-90 tw-rounded-112px tw-cursor-pointer">
              <i aria-hidden="true" className="fa fa-remove tw-text-gray-75 tw-text-18px" onClick={onClose} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {listingData.listings?.length > 0
          ? <AgentDetails
            agent={listAgent}
            office={listOffice}
            listingData={listingData}
            monthsBack={monthsBack}
            onDateChosen={onDateChosen}
            onClose={onClose}
          />
          : <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
            <FontAwesomeIcon icon={faSpinner} className="tw-h-[32px] tw-w-[32px] fa-spin" />
          </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default AgentDetailsModal;

const createAgentListings = (listings, agentId) => {
  for (let listing of listings) {
    const statusValues = getStatusValues(listing.standardStatus);

    listing["statusColor"] = statusValues.color;
    listing["represented"] = getRepresented(listing, agentId);
    listing["pricePerSquareFoot"] = getPricePerSqft(listing);
    listing["displayStatus"] = statusValues.text;
    listing["displaySoldDate"] = listing.soldDate ? moment(listing.soldDate).format("L") : "—";
    listing["displayPrice"] = formatPrice(listing.price);
    listing["displaySoldPrice"] = formatPrice(listing.soldPrice) || "—";
    listing["displayPricePerSquareFoot"] = formatPrice(listing.pricePerSquareFoot) || "—";
    listing["displaySquareFeet"] = listing.squareFeet?.toLocaleString() || "—";
    listing["displayHoaFee"] = formatPrice(listing.hoaFee) || "—";
  }

  return listings;
};

const getRepresented = (listing, agentId) => {
  const agent = listing.agents.find(a => a.id === agentId);

  if (agent.agentType === "Listing Agent") {
    return "Seller";
  }

  if (agent.agentType === "Co-Listing Agent") {
    return "Seller (co-list)";
  }

  if (agent.agentType === "Buyer Agent") {
    return "Buyer";
  }

  return "Buyer (co-buyer)";
};

const getPricePerSqft = listing => {
  if (listing.squareFeet > 0) {
    return Math.round(listing.price / listing.squareFeet);
  }

  return null;
};

const getAvailableStatuses = (listings = []) => {
  const statuses = {};
  for (const { standardStatus } of listings) {
    const status = getStatusValues(standardStatus).text;
    statuses[status] = statuses[status]
      ? statuses[status] + 1
      : 1;
  }

  return statuses;
};

const createListingData = (listings, listAgentId) => {
  return {
    listings: createAgentListings(listings, listAgentId),
    availableStatuses: getAvailableStatuses(listings),
    stats: calculateAgentStats(listings, listAgentId)
  };
};

const filterListingsByMonthsBack = (listings, monthsBack) => {
  const now = moment();

  return listings.reduce((acc, listing) => {
    const asDate = moment(listing.listDate);
    if (now.diff(asDate, "months") <= monthsBack) {
      acc.push(listing);
    }

    return acc;
  }, []);
};
