import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Section from "./Section";
import { PERIOD_OPTIONS } from "./HeadingSelect";
import Goal from "./Goal";
import Loader from "./Loader";
import useGoals, { goalValueToYearly } from "../services/useGoals";
import SectionHeader from "./SectionHeader";
import Toggle from "../../shared/v2/Toggle";
import TextButton from "../../shared/v2/TextButton/TextButton";
import PlusIcon from "../../Transactions/components/icons/PlusIcon";
import CustomActivityGoalModal from "./CustomActivityGoalModal";

const GOAL_TABS = ["Team Defaults", "My Goals"];

const DefaultableGoals = ({
  action,
  agent,
  year,
  isAdmin,
  section,
  title,
  description,
  customGoals,
  refresh,
}) => {
  const agentId = agent?.value;
  const formRef = useRef(null);
  const [data, setData] = useState({});
  const [period, setPeriod] = useState(section === "activity" ? PERIOD_OPTIONS[2] : PERIOD_OPTIONS[0]);
  const [tab, setTab] = useState(GOAL_TABS[1]);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { actions, defaults, goals, goalTypes, loading } = useGoals({
    agentId,
    year,
    period,
    section,
    load: refresh,
  });
  const isTeamDefaultTab = isAdmin && tab === GOAL_TABS[0];

  // 0 default not selected, 1 default selected (different from goals), 2 default selected (same as goals)
  const defaultSelection = useMemo(
    () =>
      Object.keys(defaults).reduce((currentDefault, key) => {
        if (currentDefault === 0) return 0;
        const defaultGoalValue = String(defaults[key].goalValue);
        const modifiedValue = String(data[key]?.goalValue);
        const goalValue = String(goals[key]?.goalValue);
        if (currentDefault === 2 && defaultGoalValue === goalValue) return 2;
        if (defaultGoalValue === modifiedValue) return 1;
        return 0;
      }, 2),
    [data, defaults, goals],
  );

  useEffect(() => {
    if (!isEditing) setData({});
  }, [isEditing]);

  const setValue = (goalTypeId) => (key) => (newValue) =>
    setData((currentData) => ({
      ...currentData,
      [goalTypeId]: { ...currentData[goalTypeId], [key]: newValue },
    }));

  const submitGoals = (e) => {
    e.preventDefault();

    Object.keys(data).forEach((key) => {
      const oldGoalType = goalTypes.find((gt) => String(gt.id) === key);
      const oldGoal = (isTeamDefaultTab ? defaults : goals)[key] || { goalTypeId: key };
      const {
        displayName = oldGoalType.displayName,
        format = oldGoalType.format,
        goalValue = oldGoal.goalValue,
      } = data[key];

      const goal = {
        ...oldGoal,
        goalValue: goalValueToYearly(String(goalValue).replaceAll(",", "") || 0, period, oldGoalType.name),
        year,
      };
      if (isTeamDefaultTab) goal.default = true;
      if (goal.goalValue !== String(oldGoal.goalValue)) {
        if (goal.id) {
          actions.updateGoal(goal, agentId);
        } else {
          actions.createGoal(goal, agentId);
        }
      }

      const goalType = { ...oldGoalType, displayName, format };
      if (
        oldGoalType &&
        (goalType.format !== oldGoalType.format || goalType.displayName !== oldGoalType.displayName)
      ) {
        actions.updateGoalType(goalType);
      }
    });

    setIsEditing(false);
  };

  return (
    <Section className="tw-basis-[500px] tw-grow" isEditing={isEditing}>
      <SectionHeader
        agent={agent}
        description={
          isTeamDefaultTab
            ? "Set the default standards for your team. Changes made here will not overwrite existing user-specific customization."
            : description
        }
        isEditing={isEditing}
        name="DefaultableGoalsPeriod"
        onSubmit={submitGoals}
        options={PERIOD_OPTIONS}
        tabs={isAdmin && !agentId ? GOAL_TABS : null}
        selectedTab={tab}
        onTabClick={setTab}
        period={period}
        setIsEditing={setIsEditing}
        setPeriod={setPeriod}
        title={title}
      />

      {!isTeamDefaultTab && defaultSelection < 2 && isEditing && (
        <div className="tw-py-[16px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-neutral-gray-10">
          <div className="tw-bg-neutral-gray-5 tw-p-[12px] tw-flex tw-justify-between">
            <label
              htmlFor="useTeamDefault"
              className="tw-m-0 tw-text-14d tw-text-neutral-gray-75 tw-font-semibold"
            >
              Use team default
            </label>
            <Toggle
              id="useTeamDefault"
              labelClassName="tw-m-0"
              checked={defaultSelection > 0}
              disabled={defaultSelection === 2}
              onChange={() => {
                const dataset = defaultSelection > 0 ? goals : defaults;
                setData(
                  Object.keys(dataset).reduce((obj, key) => {
                    const goal = dataset[key];
                    return {
                      ...obj,
                      [goal.goalTypeId]: { ...obj[goal.goalTypeId], goalValue: goal.goalValue },
                    };
                  }, {}),
                );
              }}
            />
          </div>
        </div>
      )}

      <div className="tw-flex tw-flex-col tw-relative">
        <form ref={formRef} className="tw-flex tw-flex-col">
          {goalTypes.map((goalType) => (
            <Goal
              key={goalType.id}
              editedGoal={data[goalType.id]}
              isDefault={isTeamDefaultTab}
              isEditing={isEditing}
              goalType={goalType}
              goal={(isTeamDefaultTab ? defaults : goals)[goalType.id]}
              onChange={setValue(goalType.id)}
              onDelete={actions.deleteGoalType}
            />
          ))}
        </form>

        {!isTeamDefaultTab && action && <div>{action}</div>}

        {isTeamDefaultTab && customGoals && (
          <>
            <TextButton
              className="tw-self-start tw-px-0 tw-mt-[8px]"
              onClick={() => setIsCreateModalOpen(true)}
            >
              <PlusIcon /> Add Custom Goal
            </TextButton>
            <CustomActivityGoalModal
              show={isCreateModalOpen}
              onClose={() => setIsCreateModalOpen(false)}
              onSubmit={(formData) =>
                actions
                  .createGoalType({ goalType: formData.goalType })
                  .then(({ id }) =>
                    actions.createGoal({
                      ...formData.goal,
                      goal_value: goalValueToYearly(
                        formData.goal.goal_value,
                        period,
                        formData.goal.goalType.name,
                      ),
                      goalTypeId: id,
                      year,
                    }),
                  )
                  .then(() => setIsCreateModalOpen(false))
              }
            />
          </>
        )}

        {loading && <Loader />}
      </div>
    </Section>
  );
};

DefaultableGoals.propTypes = {
  action: PropTypes.node,
  agent: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    meta: { avatar: PropTypes.string },
  }),
  isAdmin: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  customGoals: PropTypes.bool,
  refresh: PropTypes.bool,
};

DefaultableGoals.defaultProps = {
  action: null,
  agent: null,
  customGoals: false,
  refresh: true,
};

export default DefaultableGoals;
