import axios from "axios";
import { tryCatchHandlr } from "../shared/Utilities";

export const getBusinessSettingsApi = async ({ successCb, errorCb }) => {
  const url = "/api/v4/business_settings";
  const [resData, err] = await tryCatchHandlr(axios.get(url, {}));
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const saveBusinessSettingsApi = async ({ data, successCb, errorCb }) => {
  const url = "/api/v4/business_settings";
  const [resData, err] = await tryCatchHandlr(
    axios.patch(url, data, {
      headers: {
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const removeBusinessImageApi = async ({ isTeam, successCb, errorCb }) => {
  const url = `/account/${isTeam ? "team_logo_image" : "broker_image"}`;
  const [resData, err] = await tryCatchHandlr(
    axios.delete(url, {
      headers: {
        "x-csrf-token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};
