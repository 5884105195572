import React from "react";
import PropTypes from "prop-types";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Tooltip from "@shared/v2/Tooltip";
import CallRecordingMessaging from "./CallRecordingMessaging";
import { useIsAdmin } from "../../reducers/layoutReducer/selectors";

const CallRecordingTooltip = ({ disabled, willRecord, willNotify }) => {
  if (disabled) return null;

  const isAdmin = useIsAdmin();

  return (
    <Tooltip
      trigger={
        <div className="tw-flex tw-gap-[4px] tw-items-center">
          <span className="tw-text-12d tw-mb-[2px]">Rec</span>
          <CircleInfoSolidV6 />
        </div>
      }
      content={
        <CallRecordingMessaging
          willRecord={willRecord}
          willNotify={willNotify}
          additionalText={
            isAdmin
              ? "Update the Call Recording settings on your Phone Settings page."
              : "Contact an admin to update your Call Recording settings."
          }
        />
      }
    />
  );
};

CallRecordingTooltip.propTypes = {
  disabled: PropTypes.bool,
  willRecord: PropTypes.bool,
  willNotify: PropTypes.bool,
};

CallRecordingTooltip.defaultProps = {
  disabled: true,
  willRecord: false,
  willNotify: false,
};

export default CallRecordingTooltip;
