import React from "react";
import { Col } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";

const EventDropdown = ({ handleContingencyRuleChange, task, taskableOptions }) => (
  <Col size={6} className="tw-mb-[16px]">
    <Dropdown
      label="Event"
      name="event"
      onChange={(option, handler) =>
        handleContingencyRuleChange({ target: { name: handler.name, value: option.value } })
      }
      value={taskableOptions(task.taskable_type)?.find((opt) => opt.value === task.contingency_rule.event)}
      options={taskableOptions(task.taskable_type)}
    />
  </Col>
);

export default EventDropdown;
