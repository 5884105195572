import React from "react";
import Proptypes from "prop-types";
import { FormGroup } from "react-bootstrap";

const RadioButton = ({ name, isAdmin, isPond, label, selected, radioGroupHandler }) => {
  const radioButtonClass = () =>
    selected ? "fa fa-dot-circle-o brivity-blue" : "brivity-grey-text fa fa-circle-o";
  return (
    <FormGroup onClick={() => radioGroupHandler(name, isAdmin, isPond, label)} className="tw-cursor-pointer">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="radiobutton" className={`tw-pr-[7px] tw-cursor-pointer ${radioButtonClass()}`}>
        &nbsp;
      </label>
      {label && <span>{label}</span>}
    </FormGroup>
  );
};

RadioButton.propTypes = {
  name: Proptypes.number,
  isAdmin: Proptypes.bool,
  isPond: Proptypes.bool,
  label: Proptypes.string,
  selected: Proptypes.bool,
  radioGroupHandler: Proptypes.func,
};
RadioButton.defaultProps = {
  name: null,
  isAdmin: false,
  isPond: false,
  label: null,
  selected: false,
  radioGroupHandler: null,
};
export default RadioButton;
