import React from "react";
import { bool, string } from "prop-types";

const HoverInfoTxt = ({ show, children, containerStyling, textStyling, arrowStyling }) =>
  show && (
    <div
      className={`tw-w-[270px] tw--mt-[50px] tw--ml-[2px] tw-bg-gray-75 tw-absolute tw-flex tw-justify-center tw-px-[8px] tw-py-[6px] tw-rounded-[4px] ${containerStyling}`}
    >
      <div className="tw-text-[#FFFFFF]">
        <p className={`tw-m-0 ${textStyling}`}>{children}</p>
      </div>
      <div
        className={`tw-absolute tw-left-[50%] tw--translate-x-[50%] tw-mx-auto tw-border-t-gray-75 tw-border-r-transparent tw-border-l-transparent tw-border-b-transparent tw-border-t-[8px] tw-border-r-[7px] tw-border-b-[0px] tw-border-l-[7px] tw-border-solid ${arrowStyling}`}
      />
    </div>
  );

HoverInfoTxt.propTypes = {
  show: bool,
  children: string,
  containerStyling: string,
  textStyling: string,
  arrowStyling: string,
};

HoverInfoTxt.defaultProps = {
  show: false,
  children: null,
  containerStyling: "",
  textStyling: "",
  arrowStyling: "",
};

export default HoverInfoTxt;
