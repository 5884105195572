import moment from "moment";

const today = moment();

const getDateDropdownOptions = (year, type) => {
  const dropdownItems = [];
  const ytdEndDate = moment().set("year", year);
  const ytdStartDate = moment(ytdEndDate).subtract(11, "months");
  const calendarEndDate = moment().set("year", year).endOf("year");
  const calendarStartDate = moment(calendarEndDate).startOf("year");

  const isThisYear = String(year) === String(today.get("year"));

  dropdownItems.push({
    label: isThisYear
      ? "This Year"
      : `${calendarStartDate.format("MMM YYYY")} - ${calendarEndDate.format("MMM YYYY")}`,
    value: "calendar",
  });

  dropdownItems.push({
    label: isThisYear
      ? "Last 12 Months"
      : `${ytdStartDate.format("MMM YYYY")} - ${ytdEndDate.format("MMM YYYY")}`,
    value: "mtd",
  });
  if (type === "extended") {
    dropdownItems.push({
      label: isThisYear ? "This Month" : `${ytdEndDate.format("MMM YYYY")}`,
      value: "this_month",
    });
    dropdownItems.push({
      label: isThisYear ? "Last Month" : `${moment(ytdEndDate).subtract(1, "months").format("MMM YYYY")}`,
      value: "last_month",
    });
    dropdownItems.push({
      label: isThisYear
        ? "This Week"
        : `${moment(ytdEndDate).startOf("week").format("MMM D")} - ${moment(ytdEndDate)
            .endOf("week")
            .format("MMM D YYYY")}`,
      value: "this_week",
    });
    dropdownItems.push({
      label: isThisYear
        ? "Last Week"
        : `${moment(ytdEndDate).subtract(1, "weeks").startOf("week").format("MMM D")} - ${moment(ytdEndDate)
            .subtract(1, "weeks")
            .endOf("week")
            .format("MMM D YYYY")}`,
      value: "last_week",
    });
  }

  return dropdownItems;
};

export default getDateDropdownOptions;
