import axios from "axios";
import { actionTypes } from "./actionTypes";
import { tryCatchHandlr } from "../../shared/Utilities";

export const openPondModal = (payload) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.openPondModal, ...payload });
  };
};

export const closePondModal = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.closePondModal });
  };
};

export const createPondStart = (data) => {
  return { type: actionTypes.createPond, ...data };
};

export const createPondSuccess = (data) => {
  return { type: actionTypes.createPondSuccess, ...data };
};

export const createPondError = (data) => {
  return { type: actionTypes.createPondError, ...data };
};

export const updatePondStart = (data) => {
  return { type: actionTypes.updatePond, ...data };
};
export const updatePondSuccess = (data) => {
  return { type: actionTypes.updatePondSuccess, ...data };
};

export const updatePondError = (data) => {
  return { type: actionTypes.updatePondError, ...data };
};

export const removePondSuccess = (data) => {
  return { type: actionTypes.removePondSuccess, ...data };
};

export const removePondError = (data) => {
  return { type: actionTypes.removePondError, ...data };
};

export const duplicateCheckSuccess = (data) => {
  return { type: actionTypes.duplicateCheckSuccess, ...data };
};

export const duplicateCheckError = (data) => {
  return { type: actionTypes.duplicateCheckError, ...data };
};

export const loadMorePondsSuccess = (data) => {
  return { type: actionTypes.loadMorePondsSuccess, ...data };
};

export const loadMorePondsError = (data) => {
  return { type: actionTypes.loadMorePondsError, ...data };
};

export const getAssignableUsersSuccess = (data) => {
  return { type: actionTypes.getAssignableUsersSuccess, ...data };
};

export const getAssignableUsersError = (data) => {
  return { type: actionTypes.getAssignableUsersError, ...data };
};

// export const addPondToSidebar = (pond) =>{
//   let menu = document.getElementsByClassName('.brivity-nav-lead-count.all_leads_count').closest('ul')
//   if (menu) {
//     let a = document.createElement('a'),
//     span = document.createElement('span');
//     a.href=`/people/leads?leads_filter[selected_lead_ponds][ids][]=${pond.id}&leads_filter[selected_lead_ponds][names][]=${encodeURI(pond.name)}`
//     span.class = `brivity-nav-lead-count pull-right ${pond.id}_count`
//     a.innerText = pond.name
//     a.append(span)
//     menu.append(a)
//     App.updateLeadCounts()
//   }
// }

export const updatePondInSidebar = (pond) => {
  let count = document.getElementsByClassName(` brivity-nav-lead-count ${pond.id}-count`)[0];
  if (count) {
    let link = count.closest("a");
    link.innerText = pond.name;
    link.append(count);
  }
};
