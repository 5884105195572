/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CopySolidV6 = (props) => (
  <Icon {...props} name="Copy Solid V6">
    <path d="M12 3V0H8.5A1.5 1.5 0 0 0 7 1.5v9A1.5 1.5 0 0 0 8.5 12h6a1.5 1.5 0 0 0 1.5-1.5V4h-2.972A1.01 1.01 0 0 1 12 3zm1-3v3h3l-3-3zM6 11V4H1.5A1.5 1.5 0 0 0 0 5.5v9A1.5 1.5 0 0 0 1.5 16h6A1.5 1.5 0 0 0 9 14.5V13H8c-1.103 0-2-.897-2-2z" />
  </Icon>
);

export default CopySolidV6;
