import React from "react";
import Proptypes from "prop-types";

const SearchTermResults = ({ handleSelect, results }) => (
  <div className="tw-h-[32px] tw-px-[12px] tw-cursor-pointer tw-py-[6px] hover:tw-bg-gray-lighter hover:tw-text-gray-darker">
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      onClick={() => handleSelect(results.value, results?.selected_fields?.id)}
    >
      {results.value}
    </div>
  </div>
);
SearchTermResults.propTypes = {
  results: Proptypes.shape({
    value: Proptypes.string,
    selected_fields: Proptypes.shape({
      id: Proptypes.string,
    }),
  }),
  handleSelect: Proptypes.func,
};
SearchTermResults.defaultProps = {
  results: {},
  handleSelect: () => {},
};
export default SearchTermResults;
