import { merge } from "lodash";

const getBoxShadowForWhen = (menuIsOpen) => (menuIsOpen ? "rgba(0, 0, 0, 0.125) 0px 3px 5px inset" : "none");

const getFillForWhen = (isDisabled) => (isDisabled ? "rgba(0, 0, 0, 0.15)" : "rgb(51, 51, 51)");

const dropdownIndicatorSharedStyles = {
  ":hover": { fill: "rgb(102, 102, 102)" },
  ":active": { fill: "rgb(153, 153, 153)" },
};

export const sharedStyles = {
  container: (provided) => ({ ...provided, width: "100%" }),

  indicatorSeparator: () => ({ display: "none" }),

  menu: (provided) => ({
    ...provided,

    marginTop: 0,
    zIndex: 3,

    boxShadow: "none",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgb(230, 230, 230) rgb(204, 204, 204) rgb(204, 204, 204)",
  }),

  menuList: (provided) => ({
    ...provided,

    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: "200px",
  }),

  option: (provided, { isSelected, isFocused, isDisabled }) => {
    let textColor = "inherit";
    if (isSelected) {
      textColor = "black";
    } else if (isFocused) {
      textColor = "rgb(51, 51, 51)";
    } else if (isDisabled) {
      textColor = "var(--neutral-gray-30)";
    }

    return {
      ...provided,
      cursor: "pointer",
      padding: "3px 10px",

      backgroundColor: isSelected || isFocused ? "rgb(245, 245, 245)" : "transparent",
      color: textColor,

      ":hover": {
        backgroundColor: isDisabled ? "transparent" : "rgb(245, 245, 245)",
        color: isDisabled ? "var(--neutral-gray-30)" : "rgb(51, 51, 51)",
      },
    };
  },

  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
    padding: 0,
    transform: "scale(0.7)",

    ":hover": { color: "red" },
  }),
};

export const variantStyles = {
  outlined: {
    container: (provided, { selectProps: { variantOverrides = {} } }) =>
      merge(provided, { width: variantOverrides.containerWidth }),
    menu: (provided, { selectProps: { variantOverrides = {} } }) =>
      merge(provided, { width: variantOverrides.menuWidth, marginTop: "-2px", borderRadius: 0 }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (provided, { menuIsOpen, isFocused, selectProps: { variantOverrides = {} } }) => {
      let borderColor = "#E0E0E0";
      if (isFocused && !menuIsOpen) {
        borderColor = "var(--dropdown-border-hover)";
      }

      if (variantOverrides.hasError) {
        borderColor = "#FA9996";
      }

      return merge(provided, {
        boxShadow: getBoxShadowForWhen(menuIsOpen),

        height: variantOverrides.height || "36px",
        width: variantOverrides.controlWidth,
        minHeight: variantOverrides.minHeight || "34px",

        backgroundColor: "#F5F7F7",
        borderColor,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: variantOverrides.disableBorderRadius ? 0 : "2px",

        ":hover": { borderColor },
      });
    },

    indicatorsContainer: (provided, { selectProps: { variantOverrides = {} } }) =>
      variantOverrides.showIndicator ? { ...provided } : { display: "none" },

    singleValue: (provided, { selectProps: { variantOverrides = {} } }) => ({
      ...provided,
      color: variantOverrides.singleValueColor || "#999",
    }),
    input: (provided, { selectProps: { variantOverrides = {} } }) =>
      merge(provided, { paddingLeft: variantOverrides.inputPaddingLeft }),
    valueContainer: (provided, { selectProps: { variantOverrides = {} } }) =>
      merge(provided, { paddingRight: 0, paddingLeft: variantOverrides.valueContainerPaddingLeft }),
  },

  transparent: {
    control: (provided, { menuIsOpen, isDisabled }) => {
      let backgroundColor = menuIsOpen ? "rgb(230, 230, 230)" : "transparent";
      if (isDisabled) {
        backgroundColor = "#F9F9F9";
      }

      return {
        ...provided,

        boxShadow: getBoxShadowForWhen(menuIsOpen),

        border: "none",
        borderRadius: 0,
        backgroundColor,

        minHeight: 0,
        height: "30px",
      };
    },

    placeholder: (provided, { isDisabled }) => ({
      ...provided,

      color: getFillForWhen(isDisabled),
      textAlign: "right",
      paddingBottom: "6px",
      whiteSpace: "nowrap",
    }),

    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      ...dropdownIndicatorSharedStyles,
      fill: getFillForWhen(isDisabled),
      padding: "6px 6px 6px 0",
    }),

    valueContainer: (provided) => ({ ...provided, height: "30px" }),
  },

  default: {
    control: (provided, { menuIsOpen, isDisabled, isFocused }) => {
      let backgroundColor = menuIsOpen ? "rgb(230, 230, 230)" : "#FFF";
      if (isDisabled) {
        backgroundColor = "rgb(249, 249, 249)";
      }

      let borderColor = menuIsOpen ? "rgb(173, 173, 173)" : "rgb(204, 204, 204)";
      if (isFocused && !menuIsOpen) {
        borderColor = "var(--dropdown-border-hover)";
      }

      return {
        ...provided,

        boxShadow: getBoxShadowForWhen(menuIsOpen),

        borderColor,
        borderRadius: 0,

        color: isDisabled ? "rgba(0, 0, 0, 0.15)" : "rgb(51, 51, 51)",
        backgroundColor,

        minHeight: 0,
        height: "30px",

        ":hover": { borderColor },
      };
    },

    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      ...dropdownIndicatorSharedStyles,
      fill: getFillForWhen(isDisabled),
      padding: "5px 5px 5px 0",
    }),

    menuList: (provided, args) => {
      const {
        selectProps: { variantOverrides = {} },
      } = args;

      if (!variantOverrides.menuListMaxHeight) {
        return sharedStyles.menuList(provided);
      }
      return { ...sharedStyles.menuList(provided), maxHeight: variantOverrides.menuListMaxHeight };
    },

    indicatorsContainer: (provided) => ({ ...provided, height: "30px" }),
    valueContainer: (provided) => ({ ...provided, paddingRight: 0, paddingTop: 0 }),
    placeholder: (provided, args) => {
      const {
        selectProps: { variantOverrides = {} },
      } = args;

      return {
        ...provided,
        whiteSpace: "nowrap",
        color: "rgb(170, 170, 170)",
        paddingBottom: variantOverrides.placeholderPaddingBottom || provided.paddingBottom,
      };
    },
  },

  flatGray: {
    control: (provided, { menuIsOpen }) => ({
      ...provided,

      boxShadow: getBoxShadowForWhen(menuIsOpen),

      border: "none",
      borderRadius: 0,
      backgroundColor: "rgb(240, 241, 241)",

      minHeight: "30px",
    }),

    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      ...dropdownIndicatorSharedStyles,
      fill: getFillForWhen(isDisabled),
      padding: "5px 5px 5px 0",
    }),

    valueContainer: (provided) => ({ ...provided, padding: "0 0 0 8px" }),
  },

  flatBlueishGray: {
    singleValue: (provided) => ({ ...provided, fontSize: "14px" }),

    control: (provided, { menuIsOpen }) => ({
      ...provided,

      boxShadow: getBoxShadowForWhen(menuIsOpen),

      border: "none",
      borderRadius: "2px",
      backgroundColor: "rgb(236, 239, 239)",
      minHeight: "29px",
    }),

    dropdownIndicator: (provided, args) => {
      const { isDisabled, variantOverrides = {} } = args;

      return {
        ...provided,
        ...dropdownIndicatorSharedStyles,
        fill: getFillForWhen(isDisabled),
        padding: variantOverrides.dropdownIndicatorPadding || "5px 5px 5px 0",
      };
    },

    indicatorsContainer: (provided) => ({ ...provided, height: "29px" }),
    valueContainer: (provided) => ({ ...provided, padding: "0 0 0 8px" }),

    placeholder: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? "rgba(0, 0, 0, 0.05)" : "rgb(170, 170, 170)",
    }),
  },

  timepicker: {
    input: (provided) => ({
      ...provided,
      color: "var(--neutral-gray-75)",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "2px",
      marginTop: "4px",
      boxShadow: "0px 6px 20px 2px #33333329",
      border: "1px solid var(--neutral-gray-15)",
    }),
    menuList: (provided) => ({
      ...provided,
      color: "var(--neutral-gray-75)",
      "::-webkit-scrollbar": {
        width: "6px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: "var(--neutral-gray-30)",
        borderRadius: "20px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "var(--neutral-gray-50)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--neutral-gray-75)",
    }),
    control: (provided, { menuIsOpen, isFocused }) => {
      const focusBorderStyles = "1px solid var(--text-input-border-active)";

      return {
        ...provided,
        borderRadius: "2px",
        minHeight: "35px",
        backgroundColor: menuIsOpen ? "rgb(255 255 255 / var(--tw-bg-opacity))" : "var(--text-input-bg)",
        boxShadow: isFocused
          ? "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
          : "none",
        border: isFocused ? focusBorderStyles : "1px solid var(--neutral-gray-15)",
        ...(isFocused && {
          outline: "2px solid transparent",
          "box-shadow": "rgb(255,255,255) 0 0 0 0, rgb(230, 237, 250) 0 0 0 1.5px, rgba(0,0,0,0) 0 0 0 0",
        }),

        ":hover": { border: isFocused ? focusBorderStyles : "1px solid var(--text-input-border-hover)" },
      };
    },

    dropdownIndicator: (provided) => ({
      ...provided,
      ...dropdownIndicatorSharedStyles,
      fill: "var(--neutral-gray-75)",
      padding: "7px 5px 5px 0",
    }),

    indicatorsContainer: (provided) => ({ ...provided, height: "35px" }),

    valueContainer: (provided) => ({ ...provided, padding: "0 0 0 8px" }),

    placeholder: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? "rgba(0, 0, 0, 0.05)" : "rgb(170, 170, 170)",
    }),

    // TODO keep this for internal discussion
    // should we create a disabled option style here (first occurrence) or should we add a disabled style on sharedStyles? The latter makes more.
    //
    // option: (provided, state) => ({
    //   ...sharedStyles.option(provided, state),
    //   color: state.isDisabled ? "var(--neutral-gray-30)" : sharedStyles.option.textColor,
    //   ":hover": {
    //     backgroundColor: state.isDisabled
    //       ? "transparent"
    //       : sharedStyles.option(provided, state)[":hover"].backgroundColor,
    //   },
    // }),
  },
};
