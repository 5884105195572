import React from "react";

import { Spinner } from "../../../shared/v2/Icomoon";
import Dash from "./Dash";

const BigQueryWarning = ({ customMinDate, customMaxDate, abandonCustomQuery }) => {
  const from = moment(customMinDate).format("MM/DD/YYYY");
  const to = moment(customMaxDate).format("MM/DD/YYYY");

  return (
    <div className="tw-flex tw-justify-between tw-bg-semantic-yellow-5 tw-text-semantic-yellow-120 tw-px-16px tw-py-12px" role="alert">
      <div className="tw-flex">
        <Spinner size="xxxl" className="tw-self-center tw-mr-16px tw-animate-spin tw-font-bold" />
        <div>
          <p className="tw-font-bold tw-mb-8px">Large Query Detected: {from} {Dash} {to}</p>
          <p>The date range you requested is larger than usual. It may take a while to retrieve this data.</p>
        </div>
      </div>
      <button
        className="tw-self-center tw-text-semantic-yellow-110 tw-font-bold tw-border-semantic-yellow-110 tw-bg-semantic-yellow-5 tw-text-center tw-border-solid tw-rounded-full tw-py-8px tw-px-20px"
        onClick={abandonCustomQuery}
      >
        ABANDON
      </button>
    </div>
  );
};

export default BigQueryWarning;
