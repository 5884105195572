import React, { useEffect, useState } from "react";
import { arrayOf, shape, string } from "prop-types";

import { Dropdown } from "../v1";
import { Col, Row } from "react-bootstrap";
import { omit } from "lodash";
import FieldSelect from "@shared/UtilsV1/FieldSelect";
import RemovableBubbles from "@shared/RemovableBubbles";

const DropdownWithRemovable = ({
  options,
  inputIdPrefix,
  labelPrefix,
  placeholder,
  inputFieldName,
  preSelectedRemovables,
  fieldSelect,
}) => {
  const [selectedOptionsById, setSelectedOptionsById] = useState({});

  // Adds any removable filter to Dropdown if present
  useEffect(() => {
    if (preSelectedRemovables)
      setSelectedOptionsById(
        preSelectedRemovables.reduce(
          (acc, removable) => ({
            ...acc,
            ...removable,
          }),
          {},
        ),
      );
  }, []);

  const onUpdateHandlr = (option) => {
    if (fieldSelect) {
      setSelectedOptionsById((prev) => ({
        ...prev,
        [`(${option?.headerPrefix}) ` + option?.value]: `(${option?.headerPrefix}) ` + option?.label,
      }));
    } else {
      setSelectedOptionsById((prev) => ({
        ...prev,
        [option?.value]: option?.label,
      }));
    }
  };

  const onRemoveBubbleClickHandlr = (value) => setSelectedOptionsById((prev) => omit(prev, value));

  return (
    <div>
      <Row>
        <Col xs={12}>
          {fieldSelect ? (
            <FieldSelect
              className="tw-bg-white"
              onChange={onUpdateHandlr}
              fieldSelect
              placeholder={placeholder}
              options={options}
              value={null}
            />
          ) : (
            <Dropdown onUpdate={onUpdateHandlr} placeholder={placeholder} options={options} value={null} />
          )}
        </Col>
        <Col xs={12}>
          <div className="filter-checkboxes m-t-10">
            <RemovableBubbles
              selections={Object.entries(selectedOptionsById).map(([value, label]) => ({
                label,
                value,
              }))}
              inputFieldName={inputFieldName}
              inputIdPrefix={inputIdPrefix}
              labelPrefix={labelPrefix}
              onRemoveBubbleClickHandlr={onRemoveBubbleClickHandlr}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropdownWithRemovable;

DropdownWithRemovable.propTypes = {
  options: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  inputIdPrefix: string,
  labelPrefix: string,
  placeholder: string,
  inputFieldName: string,
  preSelectedRemovables: arrayOf(string),
};

DropdownWithRemovable.defaultProps = {
  options: [],
  inputIdPrefix: null,
  labelPrefix: null,
  placeholder: "Select",
  inputFieldName: null,
  preSelectedRemovables: [],
};
