import React from "react";
import Proptypes from "prop-types";
import FilterPill from "@shared/v2/FilterPill";
import FieldLabel from "@shared/v2/FieldLabel";

const CallLeadOption = ({ focusOptions, setFormDataValue, selectedFocusOption }) => (
  <div className="tw-flex tw-flex-col tw-gap-[8px] tw-pt-[16px]">
    <div className="tw-flex tw-flex-wrap tw-gap-[6px]">
      <FieldLabel label="Call this lead:" className="!tw-text-12d !tw-text-gray-75 !tw-font-normal" />
      {focusOptions.map((option) => (
        <FilterPill
          key={option.value}
          onClick={() => setFormDataValue("focus", option.value)}
          selected={option.value === selectedFocusOption}
          size="small"
          variant="gray"
        >
          {option.label}
        </FilterPill>
      ))}
    </div>
  </div>
);

CallLeadOption.propTypes = {
  focusOptions: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      value: Proptypes.string,
    }),
  ),
  selectedFocusOption: Proptypes.string,
  setFormDataValue: Proptypes.func,
};
CallLeadOption.defaultProps = {
  focusOptions: [],
  selectedFocusOption: "B",
  setFormDataValue: null,
};
export default CallLeadOption;
