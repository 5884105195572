import { useState, useEffect } from 'react';

export const useStartHandlr = (saveClosedVolume, listingData, closedVolume) => {
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);

  const startButtonHandlr = () => {
    setStartButtonDisabled(true);
    saveClosedVolume(listingData, closedVolume, setStartButtonDisabled);
  }

  return { startButtonHandlr, startButtonDisabled } 
}

export const useInit = (listingData, setIsShowTrDetail) => {
  useEffect(() => {
    if (!!listingData.transaction_income) {
      if (!!listingData.transaction_income.closed_volume) {
        setIsShowTrDetail(true);
      }
    }
  }, [setIsShowTrDetail, listingData])
}
