import React from "react";
import { shape } from "prop-types";

import Tooltip from "@shared/v2/Tooltip";
import { getInitials } from "@shared/v2/utils";

const UploadedBy = ({ document }) => (
  <td>
    <div className="tw-flex tw-items-center tw-justify-center">
      <Tooltip
        aria-label="uploaded by"
        trigger={
          <div
            className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-eee tw-bg-cover tw-bg-center tw-w-32px tw-h-32px"
            style={{
              ...(document.uploadedByImageUrl && {
                backgroundImage: `url(${document.uploadedByImageUrl})`,
              }),
            }}
            data-cy="document-list-item-avatar-image"
          >
            {!document.uploadedByImageUrl && getInitials(document.uploadedByName)}
          </div>
        }
        content={document.uploadedByName}
        placement="top"
      />
    </div>
  </td>
);

UploadedBy.propTypes = {
  document: shape({}),
};

UploadedBy.defaultProps = {
  document: {},
};

export default UploadedBy;
