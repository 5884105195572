import moment from "moment";

const PLACE_TEAM = "PLACE Team";
export const fromRailsToReact = ({
  dbFormData,
  brands,
  brokerages,
  partners,
  people,
  placeBrandedAccounts,
  activePlaceTeams,
}) => ({
  branding: brands?.filter((b) => b.value === dbFormData.brand_id)[0] || { label: "None", value: null },
  brokerage: dbFormData.brokerage_affiliation
    ? brokerages?.filter((b) => b.value === dbFormData.brokerage_affiliation)[0]
    : null,
  entityName: dbFormData.entity_name,
  entityNumber: dbFormData.entity_number,
  teamName: dbFormData.team_name,
  teamType: dbFormData.team_type || PLACE_TEAM,
  defaultCrmAccess: dbFormData.default_crm_access,
  networkAgent: dbFormData.network_agent || "No",
  operationalPartner: partners?.filter((b) => b.value === dbFormData.operator_person_uuid)[0],
  operationalPartners: dbFormData.operating_partner_person_uuids?.map((uuid) => ({
    value: partners?.filter((b) => b.value === uuid)[0],
  })),
  commOnlyOperationalPartners: dbFormData.comm_only_operator_person_uuids?.length
    ? dbFormData.comm_only_operator_person_uuids?.map((uuid) => ({
        value: partners?.filter((b) => b.value === uuid)[0],
      }))
    : [{ value: null }],
  transactionReportAccessUsers: dbFormData.transaction_report_access_person_uuids?.length
    ? dbFormData.transaction_report_access_person_uuids?.map((uuid) => ({
        value: people?.filter((b) => b.value === uuid)[0],
      }))
    : [{ value: null }],
  parentTeamAccount: placeBrandedAccounts?.filter(
    (act) => act.value === dbFormData.parent_team_account_uuid,
  )[0],
  goLive: dbFormData.go_live,
  currentContractEffective: dbFormData.current_contract_effective,
  partnershipEffective: dbFormData.partnership_effective,
  placePartnershipEnded: dbFormData.place_partnership_ended,
});

export const toRailsFromReact = ({ formData }) => {
  const model = {
    brand_id: formData.branding?.value,
    brokerage_affiliation: formData.brokerage?.value,
    entity_name: formData.entityName,
    entity_number: formData.entityNumber,
    team_name: formData.teamName,
    team_type: formData.teamType,
    default_crm_access: formData.defaultCrmAccess,
    network_agent: formData.networkAgent,
    operator_person_uuid: formData.operationalPartner?.value,
    operating_partner_person_uuids: formData.operationalPartners
      ?.filter((p) => p.value != null)
      .map((p) => p.value?.value),
    comm_only_operator_person_uuids: formData.commOnlyOperationalPartners
      ?.filter((p) => p.value != null)
      .map((p) => p.value?.value),
    transaction_report_access_person_uuids: formData.transactionReportAccessUsers
      ?.filter((p) => p.value != null)
      .map((p) => p.value?.value),
    parent_team_account_uuid: formData.teamType === PLACE_TEAM ? null : formData.parentTeamAccount?.value,
    named_milestones: {
      go_live: formData.goLive > "" ? moment(formData.goLive).format("DD-MM-YYYY") : null,
      partnership_effective:
        formData.partnershipEffective > ""
          ? moment(formData.partnershipEffective).format("DD-MM-YYYY")
          : null,
      current_contract_effective:
        formData.currentContractEffective > ""
          ? moment(formData.currentContractEffective).format("DD-MM-YYYY")
          : null,
      place_partnership_ended:
        formData.placePartnershipEnded > ""
          ? moment(formData.placePartnershipEnded).format("DD-MM-YYYY")
          : null,
    },
  };
  return model;
};
