import React from 'react';

const TableRow = ({children}) => {
  return <div className='user td'>{children}</div>
}

const TableData = (props) => (
  <div style={{background: '#FFF'}} className={props.style || 'text-center col-xs-2'}>
    <strong>{props.value}</strong>
  </div>
)

const TaskTotalsRow = ({total, completedTotal, onTimeTotal, lateTotal}) => (
  <TableRow>
    <TableData value='Totals' />
    <TableData value={total} />
    <TableData value={completedTotal} />
    <TableData value={onTimeTotal} />
    <TableData value={lateTotal} />
    <TableData />
  </TableRow>
)

export default TaskTotalsRow;
