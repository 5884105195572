import React from "react";
import { string, func, number, bool } from "prop-types";

import InlineEditingInput from "../../../../shared/v2/InlineEditingInput";
import InlineEditingDatePicker from "../../../../shared/v2/InlineEditingDatePicker/InlineEditingDatePicker";
import { DATE_FORMAT } from "../api/constants";

import DetailFieldLabel from "../DetailFieldLabel";

// The date is a special case because the calendar should be displayed, and the overflow-hidden class hides it.
const maybeOverflow = (schema) => (schema === "date" ? "" : "tw-overflow-hidden");
const maybeRightMargin = (value) => (value ? "" : "!tw-mr-[21px]");

// These are hard-coded to enable the pencil and display to appear aligned to the top horizontal line below headers.
const layoutInlineAlignmentClasses = {
  base: (schema) => `tw-justify-end tw-flex tw-leading-36px ${maybeOverflow(schema)}`.trim(),
  displayMode: (value) =>
    `tw-h-auto tw-mt-1px tw-overflow-hidden tw-text-right hover:tw-underline ${maybeRightMargin(
      value,
    )}`.trim(),
  editorMode: "tw-absolute tw-right-0 tw-flex tw-flex-grow tw-mt-[-8px] !tw-mr-[21px]",
};

const fieldTypeToEditorComponent = {
  currency: InlineEditingInput.Flat,
  percent: InlineEditingInput.Percent,
  text: InlineEditingInput,
  date: InlineEditingDatePicker,
};

const editor = (schema) => fieldTypeToEditorComponent[schema] || fieldTypeToEditorComponent.text;

const editorTypeProps = {
  currency: { prefix: "$", suffix: "", withCancelConfirm: true },
  percent: { prefix: "", suffix: "%", withCancelConfirm: true },
  text: { prefix: "", suffix: "", withCancelConfirm: false },
  date: { dateFormat: DATE_FORMAT, position: "right" },
};

const baseEditorProps = (schema) => editorTypeProps[schema] || editorTypeProps.text;

const DetailInput = ({
  label,
  schema,
  placeholder,
  hoistGroupClass,
  val,
  onBlur,
  id,
  name,
  dataCy,
  containerClass,
  onLabelClick,
  tooltipComp,
  ...otherProps
}) => {
  const groupClass = hoistGroupClass ? "tw-group" : "";

  return (
    <div
      className={`tw-grid tw-gap-3 tw-grid-cols-2 tw-relative tw-py-[8px] tw-w-full tw-flex tw-gap-12px tw-justify-between hover:tw-bg-neutral-gray-5 tw-rounded-4px tw-w-calc[100% + 16px] tw-ml-[-8px] tw-p-8px ${containerClass} ${groupClass}`}
      data-cy={dataCy}
    >
      <DetailFieldLabel htmlFor={id} label={label} onClick={onLabelClick} />
      <div className={`${tooltipComp && "tw-justify-end tw-flex"}`}>
        {tooltipComp}
        {editor(schema)({
          id,
          name,
          placeholder,
          value: val,
          onChange: onBlur,
          showLineBreak: true,
          showRightAlign: true,
          className: layoutInlineAlignmentClasses.base(schema),
          displayClassName: layoutInlineAlignmentClasses.displayMode(val),
          editorClassName: layoutInlineAlignmentClasses.editorMode,
          ...baseEditorProps(schema),
          ...otherProps,
        })}
      </div>
    </div>
  );
};

DetailInput.propTypes = {
  label: string,
  schema: string,
  val: string,
  onBlur: func,
  placeholder: string,
  id: number,
  name: string,
  dataCy: string,
  containerClass: string,
  hoistGroupClass: bool,
  onLabelClick: func,
};

DetailInput.defaultProps = {
  label: "",
  schema: "text",
  placeholder: null,
  id: null,
  name: null,
  val: "",
  onBlur: () => {},
  dataCy: null,
  containerClass: null,
  hoistGroupClass: true,
  onLabelClick: null,
};

export default DetailInput;
