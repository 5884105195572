import React from "react"
import { Row }from "react-bootstrap"
import Pagination from "../../shared/Pagination";

const PaginationWrapper = ({ paginationInfo, onGoToPage }) => {
  return (
    <Row xs={12} style={{ textAlign: "center" }}>
      <div role="navigation" aria-label="navigation">
        <Pagination
          currentPage={paginationInfo.current_page}
          nextPage={paginationInfo.next_page}
          prevPage={paginationInfo.prev_page}
          totalPages={paginationInfo.total_pages}
          totalCount={paginationInfo.total_count}
          onGoToPage={onGoToPage}
        />
      </div>
    </Row>
  )
}

export default PaginationWrapper;
