import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";

const DeleteTrackingNumber = ({ beginDeletionConfirmation }) => {
  const [agreedToDelete, setAgreedToDelete] = useState(false);

  return (
    <div className="tw-flex tw-flex-col tw-gap-24px">
      <span className="tw-text-18px tw-text-gray-75">Delete Tracking Number</span>
      <div className="tw-flex tw-flex-col tw-bg-semantic-red-5 tw-rounded-8px tw-p-16px tw-gap-16px">
        <span className="tw-text-14px tw-text-semantic-red-120 tw-font-bold">
          We will not retain any of the tracking data associated with this number and cannot be held
          accountable for any loss of data.
        </span>
        <span className="tw-text-14px tw-text-semantic-red-120">
          Make sure to remove the tracking number from relevant materials (flyers, websites, other lead
          sources) before continuing.
        </span>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-6px">
        <Checkbox
          name="selected"
          valign="start"
          checked={agreedToDelete}
          onChange={() => setAgreedToDelete(!agreedToDelete)}
          label="I have read the above and understand that Brivity will not be held responsible for any loss of critical data resulting from the deletion of this number."
        />
      </div>
      <div className="tw-flex tw-justify-end">
        <Button schema="warning" size="medium" disabled={!agreedToDelete} onClick={beginDeletionConfirmation}>
          Delete Tracking Number
        </Button>
      </div>
    </div>
  );
};

DeleteTrackingNumber.propTypes = {
  beginDeletionConfirmation: PropTypes.func.isRequired,
};

export default DeleteTrackingNumber;
