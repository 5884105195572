import React, { useEffect } from "react";
import axios from "axios";
import Input from "@shared/Input";
import { Search } from "@shared/v2/Icomoon";
import { useDebounce } from "@shared/hookHelpers";

const ListingsAutocomplete = ({
  mlsIds,
  setMlsIds,
  statuses,
  selectedFields,
  currentUserId,
  searchFields,
  searchResults,
  setSearchResults,
  term,
  setTerm,
  selectResult,
  setChildPosition,
  childPosition,
  searchBarRef,
  placeholder,
  ...props
}) => {
  const debouncedTerm = useDebounce(term, 250);

  useEffect(() => {
    const loadMlses = async () => {
      const response = await axios.get(`/users/${currentUserId}/mls_ids`);
      setMlsIds(response.data.ids);
    };

    loadMlses();
  }, []);

  useEffect(() => {
    let cancelled = false;

    const search = async () => {
      const termCleaned = cleanSearchTerm(debouncedTerm);
      if (!termCleaned.length || termCleaned.length > maxSearchLength || !mlsIds || !statuses?.length) {
        setSearchResults([]);
        return;
      }

      const autocompleteStatuses = statuses.map((s) => getAutocompleteStatus(s)).join(",");
      const response = await axios.get("/autocomplete", {
        params: {
          mlsIds,
          term: termCleaned,
          statuses: autocompleteStatuses,
          searchFields: searchFields.join(","),
          selectedFields: selectedFields.join(","),
        },
      });

      if (!cancelled) {
        setSearchResults(response.data);
      }
    };

    search();
    return () => {
      cancelled = true;
    };
  }, [debouncedTerm, mlsIds, statuses]);

  const handleKeyDown = async (e) => {
    // Enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      await selectResult(searchResults[childPosition]);
    } else if (e.keyCode === 38) {
      // Navigate up
      if (childPosition > 0) {
        setChildPosition(childPosition - 1);
      }
    } else if (e.keyCode === 40) {
      // Navigate down
      if (childPosition < searchResults.length - 1) {
        setChildPosition(childPosition + 1);
      }
    } else if (e.keyCode === 8 && term.length === 1) {
      // Removing last character with backspace
      setSearchResults([]);
      setChildPosition(0);
    }
  };

  return (
    <>
      <Input
        ref={searchBarRef}
        autoComplete="no"
        value={term}
        type="text"
        className="tw-w-full tw-border tw-border-solid tw-border-gray-15 tw-rounded-2px tw-px-12px tw-py-8px tw-bg-tinted-gray-50"
        onChange={(e) => setTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        maxLength={maxSearchLength.toString()}
        {...props}
      />
      <Search className="tw-absolute tw-right-[6px] tw-top-[calc(50%-8px)]" size="l" />
    </>
  );
};

export default ListingsAutocomplete;

const maxSearchLength = 100;

const getAutocompleteStatus = (statusInteger) => {
  switch (statusInteger) {
    case 1:
      return "Active";
    case 2:
      return "Pending";
    case 3:
      return "ActiveUnderContract";
    default:
      return "Active";
  }
};

const cleanSearchTerm = (term) => {
  let cleaned = term.trim();

  // Assuming this is a listing number, which is apparently frequently pasted in with a #
  if (cleaned.startsWith("#") && !cleaned.includes(" ")) {
    cleaned = cleaned.substring(1);
  }

  return cleaned;
};
