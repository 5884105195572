import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "@shared/v2/Modal";
import Typography from "@shared/v2/Typography";
import Button from "@shared/v2/Button";
import { updatePost } from "../../reducers/websiteReducer/websiteReducer";

const UnsavedChangesModal = ({ blocker, post }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={blocker.state === "blocked"}
      onHide={blocker.reset}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[440px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="Unsaved Changes" />
      <Modal.Body>
        <Typography variant="body-m">
          Your post has unsaved changes. If you proceed without saving your post, all edits will be lost.
          <br />
          <br />
          Would you like to save or discard your changes?
        </Typography>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button size="medium" schema="tertiary" onClick={blocker.proceed}>
          Discard Changes
        </Button>
        <Button
          size="medium"
          onClick={() => {
            dispatch(updatePost(post)).then(({ error }) => {
              if (!error) blocker.proceed();
            });
          }}
        >
          Save & Exit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UnsavedChangesModal.propTypes = {
  blocker: PropTypes.shape().isRequired,
  post: PropTypes.shape().isRequired,
};

export default UnsavedChangesModal;
