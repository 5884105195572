import React, { useMemo } from "react";
import { Provider } from "react-redux";
import Inbox from "../components/Inbox";

export default () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <Inbox />
    </Provider>
  );
};
