import React from "react";
import { bool, func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TIMEFRAME_READ } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { referralTimeFrameEditOn } from "../../actions/creators";

import TextBtnWithPencil from "../../shared/TextBtnWithPencil/TextBtnWithPencil";
import withPencilVisibleStates from "../../shared/HOCs/withPencilVisibleStates";

const TimeFrameValRead = ({ timeframeRead, isPencilVisible, setPencilVisible, onClick }) => (
  <TextBtnWithPencil
    txtField={timeframeRead}
    isPencilVisible={isPencilVisible}
    setPencilVisible={setPencilVisible}
    onClick={onClick}
  />
);

const withReduxState = tdpDetails({
  WrappedComponent: withPencilVisibleStates(TimeFrameValRead),
  states: TIMEFRAME_READ,
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: referralTimeFrameEditOn,
  aliases: "onClick",
});

TimeFrameValRead.propTypes = {
  timeframeRead: string,
  isPencilVisible: bool,
  setPencilVisible: func,
  onClick: func,
};

TimeFrameValRead.defaultProps = {
  timeframeRead: null,
  isPencilVisible: false,
  setPencilVisible: () => {},
  onClick: () => {},
};
