/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MultiUser = (props) => (
  <Icon {...props} name="Multi User">
    <path d="M4.8 8c1.548 0 2.8-1.252 2.8-2.8S6.348 2.4 4.8 2.4C3.252 2.4 2 3.652 2 5.2S3.252 8 4.8 8zm1.92.8h-.207c-.52.25-1.098.4-1.712.4s-1.19-.15-1.712-.4h-.208a2.88 2.88 0 0 0-2.88 2.88v.72a1.2 1.2 0 0 0 1.2 1.2h7.2a1.2 1.2 0 0 0 1.2-1.2v-.72a2.88 2.88 0 0 0-2.88-2.88zM12 8a2.4 2.4 0 1 0-.001-4.801A2.4 2.4 0 0 0 12 8zm1.2.8h-.095c-.348.12-.715.2-1.105.2s-.758-.08-1.105-.2H10.8c-.51 0-.98.148-1.393.385.61.658.992 1.53.992 2.495v.96c0 .055-.012.107-.015.16h4.415a1.2 1.2 0 0 0 1.2-1.2c0-1.547-1.252-2.8-2.8-2.8z" />
  </Icon>
);

export default MultiUser;
