import React from "react";
import PropTypes from "prop-types";
import styleSchema from "./Toggle.styles";

const Toggle = ({ selected, onFlatSelected, onPercentSelected }) => {
  const classes = (option) => (selected === option ? styleSchema.selected : styleSchema.regular);
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-space-x-[4px]">
      <button type="button" className={classes("flat")} onClick={onFlatSelected}>
        $
      </button>
      <button type="button" className={classes("percent")} onClick={onPercentSelected}>
        %
      </button>
    </div>
  );
};

Toggle.propTypes = {
  selected: PropTypes.oneOf(["flat", "percent"]).isRequired,
  onFlatSelected: PropTypes.func.isRequired,
  onPercentSelected: PropTypes.func.isRequired,
};

export default Toggle;
