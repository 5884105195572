import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ChevronRight } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import DocumentListItem from "../../Main/Docs/Table/DocumentListItem";
import { setDocumentIndex, toggleDeleteModal } from "../../Main/Docs/actions/creators";
import { renameDocumentAsThunk } from "../../Main/Docs/actions/thunks";
import { DocumentPropType, MetaPropType } from "../../Main/Docs/propTypes";
import useInitPathName from "../../Main/hooks";

const SidebarTable = ({ documents, isFetchingDocuments, errors, meta, dispatch }) => {
  const context = "documents-sidebar";

  const navigate = useNavigate();
  const { initPathName } = useInitPathName();

  const goToDocumentsPage = () => {
    navigate(`${initPathName}/docs`);
  };

  const handleRenameDocument = (documentId, newName) => {
    dispatch(renameDocumentAsThunk(context, documentId, newName));
  };

  const handlePreview = (index) => {
    dispatch(setDocumentIndex(context, index));
  };

  const handleDeleteDocuments = (documentIds) => {
    dispatch(toggleDeleteModal(documentIds, false));
  };

  if (isFetchingDocuments) {
    return <p>Loading documents...</p>;
  }

  if (errors.length > 0) {
    return (
      <p className="tw-text-semantic-red-100" data-cy="documents-sidebar-error-message">
        {errors.join(" ")}
      </p>
    );
  }

  if (documents.length === 0) {
    return <p data-cy="documents-sidebar-empty-message">No documents have been uploaded.</p>;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4px">
      <table className="tw-table-auto tw-w-full" data-cy={`${context}-table`}>
        <tbody>
          {documents.map((doc, index) => (
            <DocumentListItem
              key={doc.id}
              context={context}
              document={doc}
              index={index}
              meta={meta}
              renameDocument={handleRenameDocument}
              deleteDocuments={handleDeleteDocuments}
              handlePreview={() => handlePreview(index)}
            />
          ))}
        </tbody>
      </table>

      <div className="tw-flex tw-justify-end">
        <TextButton
          className="tw-flex tw-items-center tw-gap-8px"
          size="medium"
          onClick={goToDocumentsPage}
          data-cy="documents-sidebar-view-all-button"
        >
          <span>View All</span>
          <ChevronRight />
        </TextButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  documents: state.tdpDocumentsReducer.sidebarDocuments,
  isFetchingDocuments: state.tdpDocumentsReducer.isFetchingSidebarDocuments,
  errors: state.tdpDocumentsReducer.sidebarErrors,
  meta: state.tdpDocumentsReducer.meta,
});

SidebarTable.propTypes = {
  documents: PropTypes.arrayOf(DocumentPropType),
  isFetchingDocuments: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  meta: MetaPropType,
  dispatch: PropTypes.func,
};

SidebarTable.defaultProps = {
  documents: [],
  isFetchingDocuments: false,
  errors: [],
  meta: [],
  dispatch() {},
};

export default connect(mapStateToProps)(SidebarTable);
