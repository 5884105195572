import React, { useMemo, useState } from "react";
import { bool, string } from "prop-types";
import { useDispatch } from "react-redux";

import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, Spinner } from "@shared/v2/Icomoon";
import { toggleUserModal } from "../actions/default";

const EditUserBtn = ({ userId, disabled }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const Icon = useMemo(
    () => (isLoading ? <Spinner className="tw-animate-spin" /> : <PencilSolidV6 />),
    [isLoading],
  );

  return (
    <IconButton
      schema="tertiary"
      onClick={async () => {
        if (isLoading) return;

        setIsloading(true);
        await dispatch(toggleUserModal(userId));
        setIsloading(false);
      }}
      disabled={disabled}
    >
      {Icon}
    </IconButton>
  );
};

export default EditUserBtn;

EditUserBtn.propTypes = {
  userId: string.isRequired,
  disabled: bool,
};

EditUserBtn.defaultProps = {
  disabled: false,
};
