import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_MARKETING = "tdpDetails";
const wrap = curriedNamespaceAction(REDUCER_TDP_MARKETING);

export const saveAllDetails = (details, uuid) => wrap({ type: "SAVE_ALL_DETAILS", details, uuid });

export const saveDetail = (key, value, group) => wrap({ type: "SAVE_DETAIL", key, value, group });
export const saveDetails = (details) => wrap({ type: "SAVE_DETAILS", details });
export const saveLinks = (value) => wrap({ type: "SAVE_LINKS", value });

export const showDetailsFailure = (error) => wrap({ type: "SHOW_DETAILS_FAILURE", error });
export const clearDetailsFailure = () => wrap({ type: "CLEAR_DETAILS_FAILURE" });

export const checkAllImgs = () => wrap({ type: "CHECK_ALL_IMGS" });

export const toggleChecked = (id) => wrap({ type: "TOGGLE_CHECKED", id });

export const deleteImg = (id) => wrap({ type: "DELETE_IMG", id });

export const addImg = (image) => wrap({ type: "ADD_IMG", image });
export const addError = (error) => wrap({ type: "ADD_ERROR", error });
export const clearError = (error) => wrap({ type: "CLEAR_ERROR", error });
export const updateImg = (image) => wrap({ type: "UPDATE_IMG", image });
export const setAccountSources = (sources = []) => wrap({ type: "SET_ACCOUNT_SOURCES", sources });
export const setAccountPropertyTypes = (propertyTypes = []) =>
  wrap({
    type: "SET_ACCOUNT_PROPERTY_TYPES",
    propertyTypes,
  });

export const setRefPartnerDetailsTeam = (team) =>
  wrap({
    type: "SET_REF_PARTNER_DETAILS_TEAM",
    team,
  });

export const setRefPartnerDetailsBrokerage = (brokerage) =>
  wrap({
    type: "SET_REF_PARTNER_DETAILS_BROKERAGE",
    brokerage,
  });
export const setRefTitle = (title) =>
  wrap({
    type: "SET_REF_TITLE",
    title,
  });
export const setRefStatus = (status) =>
  wrap({
    type: "SET_REF_STATUS",
    status,
  });
export const setTransactionAmount = (amount) =>
  wrap({
    type: "SET_TRANSACTION_AMOUNT",
    amount,
  });
export const setReferralFee = (referralFee) =>
  wrap({
    type: "SET_REFERRAL_FEE",
    referralFee,
  });
export const setClientDetailsFinancing = (financing) =>
  wrap({
    type: "SET_FINANCING",
    financing,
  });
export const updatePropertyDetail = (payload) =>
  wrap({
    type: "UPDATE_PROPERTY_DETAIL",
    ...payload,
  });
