import React, { useEffect, useState, useRef } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";

const defaultStyle = (duration) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const clickOnTarget = (e, targetRef) =>
  targetRef && targetRef.current && targetRef.current.contains(e.target);
const clickOnContainer = (e, containerRef) => containerRef.current && containerRef.current.contains(e.target);

export const Overlay = React.forwardRef(
  ({ show, children, className, rootClose, onHide, duration, zIndex, ...props }, targetRef) => {
    const [showOverlay, setShowOverlay] = useState(show);
    const containerRef = useRef(null);

    const handleClick = (e) => {
      if (clickOnTarget(e, targetRef) || clickOnContainer(e, containerRef)) {
        return;
      }

      if (rootClose) {
        if (onHide) {
          onHide();
        }
        setShowOverlay(false);
      }
    };

    useEffect(() => {
      setShowOverlay(show);
    }, [show]);

    useEffect(() => {
      if (showOverlay) {
        document.addEventListener("mousedown", handleClick);
      } else {
        document.removeEventListener("mousedown", handleClick);
      }

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [showOverlay]);

    return (
      <Transition in={showOverlay} unmountOnExit mountOnEnter timeout={duration}>
        {(state) => (
          <div
            className={`
              ${className}
              tw-relative
              ${zIndex}
            `}
            {...props}
            style={{ ...defaultStyle(duration), ...transitionStyles[state] }}
          >
            {React.Children.map(children, (child) => React.cloneElement(child, { ref: containerRef }))}
          </div>
        )}
      </Transition>
    );
  },
);

Overlay.propTypes = {
  show: PropTypes.bool,
  rootClose: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  duration: PropTypes.number,
  zIndex: PropTypes.string,
};

Overlay.defaultProps = {
  show: false,
  rootClose: false,
  onHide: null,
  children: null,
  className: "",
  duration: 100,
  zIndex: "tw-z-10",
};

export default Overlay;
