import React from "react";
import { arrayOf, func, shape } from "prop-types";
import { connect } from "react-redux";

import { toggleCDAUploadModal } from "../../../Docs/actions/creators";
import Button from "../../../../../shared/v2/Button";
import { Upload as UploadIcon } from "../../../../../shared/v2/Icomoon";

const Upload = ({ toggleCDAUploadModl, CDAdocuments }) => (
  <Button
    type="file"
    className="tw-flex tw-items-center tw-gap-4px tw-px-20px tw-uppercase"
    size="small"
    schema={CDAdocuments.length ? "secondary" : "primary"}
    onClick={() => toggleCDAUploadModl(true)}
    data-cy="documents-upload-button"
  >
    <span>Upload</span>
    <UploadIcon size="l" />
  </Button>
);

const mapDispatchToProps = (dispatch) => ({
  toggleCDAUploadModl: () => dispatch(toggleCDAUploadModal(true)),
});

const mapStateToProps = (state) => ({
  CDAdocuments: state.tdpDocumentsReducer.CDAdocuments,
});

export default connect(mapStateToProps, mapDispatchToProps)(Upload);

Upload.propTypes = {
  toggleCDAUploadModl: func.isRequired,
  CDAdocuments: arrayOf(shape({})),
};

Upload.defaultProps = {
  CDAdocuments: [],
};
