import React, { useState, useRef } from "react";
import { Overlay } from "react-bootstrap";
import { useClickOutside } from "../hookHelpers";
import { updatePrimaryAgentOrPond } from "../serviceHelpers";
import { useGetAgentState } from "../agentOrPondHookHelpers";
import { getLetters } from "../Utilities";
import DropDownSelectOption from "../DropDownSelectOption";
import "../../Filters/components/PondOrAgentFilter/styles.scss";

const AgentOrPondSelect = ({
  enableLeadPonds = false,
  personId,
  primaryAgent,
  leadPond,
  small = false,
  showSubTitle = false,
  max = "[200px]",
  nameClasses = "",
  caretClasses = "",
  stacked = false,
}) => {
  const target = useRef(null);
  const [state, setState, handleChange] = useGetAgentState(enableLeadPonds);
  const [primaryAgentModel, setPrimaryAgent] = useState(primaryAgent);
  const [leadPondModel, setLeadPond] = useState(leadPond);
  useClickOutside(target, () => setState({ ...state, show: false }));

  const onChange = (params) => {
    const oldParams = {
      primary_agent: primaryAgentModel,
      lead_pond: leadPondModel,
    };
    updatePrimaryAgentOrPond({
      data: params,
      errorCb: () => {
        setPrimaryAgent(oldParams.primary_agent);
        setLeadPond(oldParams.lead_pond);
      },
    });
  };

  const handleAgentSelect = (agent) => {
    const params = {
      primary_agent: agent.id.toString(),
      lead_pond: null,
      people_ids: [personId.toString()],
    };
    onChange(params);
    setLeadPond(null);
    setPrimaryAgent(agent);
    setState({ ...state, show: false });
  };

  const handlePondSelect = (leadPond) => {
    const params = {
      primary_agent: null,
      lead_pond: leadPond.id,
      people_ids: [personId.toString()],
    };
    setState({ ...state, show: false });
    setLeadPond(leadPond);
    setPrimaryAgent(null);
    onChange(params);
  };

  const { filter, filteredAssignableAgents, filteredAssignablePonds } = state;
  const imgSize = small ? "tw-h-[26px] tw-w-[26px]" : "tw-h-[40px] tw-w-[40px]",
    imgSizeClass = small ? "force-img-sm" : "";
  const prepUrlPath = (url) => {
    return `${url.indexOf("http") === 0 ? "" : "/"}${url}`;
  };
  let displayModel = leadPondModel
    ? {
        image: `<img class='tw-block ${imgSize}' src="/user-default.svg?letters=${getLetters(
          leadPondModel.name,
        )}" />`,
        name: leadPondModel.name,
      }
    : {
        image: primaryAgentModel.imageSrc
          ? `<img class='tw-block ${imgSize} tw-rounded-full' src="${prepUrlPath(
              primaryAgentModel.imageSrc,
            )}" />`
          : primaryAgentModel.image,
        name: primaryAgentModel.name,
      };
  return (
    <div className={`tw-relative tw-max-w-${max} tw-cursor-pointer`} ref={target}>
      {displayModel && (
        <button
          className={
            "display-btn tw-bg-transparent tw-border-0 tw-flex tw-items-center tw-justify-center tw-w-full tw-overflow-hidden tw-p-0 tw-no-underline tw-h-[50px]" +
            (stacked ? " tw-mb-[10px]" : "")
          }
          onClick={() => setState({ ...state, show: !state.show })}
        >
          <div
            className={`tw-mr-6 tw-flex tw-items-center ${imgSizeClass}`}
            dangerouslySetInnerHTML={{ __html: displayModel.image }}
          />
          <div className="tw-flex-1 tw-overflow-hidden tw-text-left">
            <div className={`tw-text-sm tw-truncate ${nameClasses}`}>{displayModel.name}</div>
            {showSubTitle && (
              <small className="tw-font-bold tw-text-[10px] tw-relative tw-top-[-4px]">
                {!leadPondModel ? "Primary Agent" : "Lead Pond"}
              </small>
            )}
          </div>
          <span className={`tw-ml-4 tw-flex tw-items-center tw-justify-center ${caretClasses}`}>
            <i className="fa fa-caret-down tw-text-[14px] tw-text-gray-50" />
          </span>
        </button>
      )}

      <Overlay
        show={state.show}
        onHide={() => setState({ ...state, show: false })}
        placement="bottom"
        container={target.current}
        target={target.current}
      >
        <div
          className={`dd-options tw-bg-white tw-mt-[-8px] tw-z-40 tw-shadow-modal tw-absolute tw-max-h-[350px] tw-w-full tw-overflow-y-auto`}
        >
          <div className="tw-p-4">
            <input
              className="tw-w-full tw-border tw-border-gray-10"
              autoFocus={true}
              onChange={handleChange}
              placeholder="Search..."
              value={filter}
            />
          </div>

          {filteredAssignablePonds?.length > 0 && (
            <p className="tw-text-[12px] tw-py-1 tw-px-2 tw-bg-neutral-gray-10">Pond</p>
          )}
          {filteredAssignablePonds?.map((pond) => (
            <DropDownSelectOption
              key={pond.id}
              data-key={`pond-${pond.id}`}
              showCheckIcon={false}
              option={{ ...pond }}
              handleSelect={() => handlePondSelect(pond)}
            />
          ))}

          {filteredAssignableAgents?.length > 0 && (
            <p className="tw-text-[12px] tw-py-1 tw-px-2 tw-bg-neutral-gray-10">Team</p>
          )}
          {filteredAssignableAgents?.map((agent) => (
            <DropDownSelectOption
              key={agent.id}
              data-key={`agent-${agent.id}`}
              showCheckIcon={false}
              option={{ ...agent, imageSrc: agent.image_src }}
              imgSizeClass="force-img-sm"
              handleSelect={() => handleAgentSelect(agent)}
            />
          ))}
        </div>
      </Overlay>
    </div>
  );
};

export default AgentOrPondSelect;
