import React from "react";
import { Search } from "../../../shared/v2/Icomoon";

const NoResults = () => (
  <div className="tw-text-center tw-mt-40">
    <Search size="xxxl" className="tw-text-neutral-gray-50 tw-mb-[16px]" />
    <h1 className="tw-text-neutral-gray-50 tw-text-[18px] tw-font-bold tw-mb-[8px] tw-mt-0">
      No Partners Found
    </h1>
  </div>
);

export default NoResults;
