import React, { useState } from "react";
import Button from "../shared/v2/Button";
import Modal from "../shared/v2/Modal";
import sendEmail from "./services";

const OktaPasswordReset = () => {
  const [sending, setSending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const send = () => {
    setError(false);
    setSending(true);
    sendEmail()
      .then(() => {
        setShowModal(false);
        setSending(false);
      })
      .catch(() => {
        setError(true);
        setSending(false);
        setTimeout(() => setError(false), 2000);
      });
  };
  return (
    <>
      <Modal
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-w-[440px]"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header title="Password Reset" className="tw-mb-32px" closeButton />

        <Modal.Body>
          <p>
            Are you sure you want to reset the password for this account? A password reset email will be sent
            to the email address used to login to this account.
          </p>
        </Modal.Body>
        <div className="tw-flex tw-mt-[40px]">
          <Button schema="tertiary" size="medium" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <div className="tw-flex-1" />
          <Button schema={error ? "warning" : "secondary"} size="medium" onClick={send}>
            {sending && <i className="fa fa-spinner fa-spin" />}
            {!sending && !error && " Yes, Send"}
            {error && "Error"}
          </Button>
        </div>
      </Modal>
      <Button className="tw-mt-[25px]" schema="secondary" size="medium" onClick={() => setShowModal(true)}>
        Reset Password
      </Button>
    </>
  );
};

export default OktaPasswordReset;
