import React from "react";

import { FileArrowUpSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const getFormName = (activity) => {
  switch (activity.notificationType) {
    case "cash_offer_submitted":
      return "Cash Offer";
    case "cma_requested":
      return "CMA";
    case "home_value_submitted":
      return "Home Value";
    case "sell_my_home_submitted":
      return "Sell My Home";
    case "unlock_equity_requested":
      return "Unlock Equity";
    case "valuation_requested":
      return "Valuation";
    default:
      return "Unknown";
  }
};

const SubmittedFormAcitivity = ({ activity, onReadChange }) => (
  <ActivitiesContainer
    activity={activity}
    title={activity.title}
    Icon={FileArrowUpSolidV6}
    onReadChange={onReadChange}
    childContainerClass="!tw-mr-[0px]"
  >
    <div className={STYLES.contentContainer}>
      <span>Form Name: {getFormName(activity)}</span>
      <span>Content: {activity.content}</span>
    </div>
  </ActivitiesContainer>
);

export default SubmittedFormAcitivity;

SubmittedFormAcitivity.propTypes = {
  ...activityCommonPropTypes,
};

SubmittedFormAcitivity.defaultProps = {
  ...activityCommonDefaultProps,
};
