import React, { useState } from "react";
import { useSelector } from "react-redux";
import Toggle from "../../shared/v2/Toggle/Toggle";

const getUseNewPage = (togglePartial, featureFlags) => {
  let value = false;
  if (togglePartial === "people/feature_toggle") value = featureFlags.newPdp;
  if (togglePartial === "listings/feature_toggle") value = featureFlags.newTransactions;
  if (togglePartial === "listings/feature_toggle_detail") value = featureFlags.newTdp;
  return Boolean(value);
};

const getUrl = (togglePartial, newPage) => {
  let baseUrl = "";
  const urlParams = new URLSearchParams({ use_classic: newPage });
  if (togglePartial === "listings/feature_toggle_detail") {
    if (newPage) {
      baseUrl = window.location.pathname.replace("/transactions/", "/listings/");
      baseUrl = baseUrl.replace(/(timeline|tasks|notes|details|marketing|feedback|docs|financials)\/?$/, "");
      urlParams.append("tab", "tasks-tab");
    } else {
      baseUrl = window.location.pathname.replace("/listings/", "/transactions/");
    }
  }
  return `${baseUrl}?${urlParams}`;
};

const FeatureToggle = () => {
  const togglePartial = useSelector(({ layout }) => layout.togglePartial);
  const featureFlags = useSelector(({ layout }) => layout.featureFlags);
  const theme = useSelector(({ layout }) => layout.theme);
  const newPage = getUseNewPage(togglePartial, featureFlags);
  const [value, setValue] = useState(newPage);

  if (!togglePartial) return null;

  const textStyle = `${theme.name === "place-theme" ? "tw-text-white/40" : "tw-text-black/25"}`;
  const activeTextStyle = `${theme.name === "place-theme" ? "tw-text-white/90" : "tw-text-black/40"}`;

  return (
    <div className="tw-flex tw-items-center tw-gap-[8px] tw-mr-[8px] tw-text-12d tw-font-semibold">
      <span className={`bs-xs:tw-hidden ${value ? textStyle : activeTextStyle}`}>Classic</span>
      <Toggle
        labelClassName="tw-mb-0"
        checked={value}
        onChange={() => {
          setValue(!value);
          window.location.assign(getUrl(togglePartial, value));
        }}
      />
      <span className={`bs-xs:tw-hidden ${value ? activeTextStyle : textStyle}`}>New</span>
    </div>
  );
};

export default FeatureToggle;
