import React from "react";
import { bool, func, arrayOf, shape } from "prop-types";

import { FloatingForm } from "../v2/Modal";
import Button from "../v2/Button";
import Dropdown from "../v2/Dropdown";

const AddAutoPlanModal = ({
  show,
  errors,
  onCancel,
  onApply,
  isApplying,
  isLoading,
  onPreview,
  disableInteractionButtons,
  availablePlansLoading,
  availablePlansAsOptions,
  selectedAutoPlan,
  onSelectAutoPlan,
}) => (
  <FloatingForm
    title="Apply an Auto Plan"
    scroll="overflow-none"
    show={show}
    errors={errors}
    onCancel={onCancel}
    footer={
      <>
        <Button size="medium" schema="tertiary" disabled={isApplying} onClick={onCancel}>
          Cancel
        </Button>

        <div className="tw-flex tw-flex-row tw-space-x-12px">
          <Button
            size="medium"
            schema="secondary"
            onClick={onPreview}
            isLoading={isLoading}
            disabled={disableInteractionButtons}
          >
            Preview
          </Button>

          <Button
            size="medium"
            schema="primary"
            disabled={disableInteractionButtons}
            isLoading={isApplying}
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </>
    }
    closeOnClickOutside
    closeOnEscape
  >
    <Dropdown.Searchable
      label="Auto Plan"
      disabled={availablePlansLoading}
      placeholder={availablePlansLoading ? "Loading..." : "Select an auto plan"}
      options={availablePlansAsOptions}
      value={selectedAutoPlan}
      onChange={onSelectAutoPlan}
    />
  </FloatingForm>
);

export default AddAutoPlanModal;

AddAutoPlanModal.propTypes = {
  show: bool,
  errors: arrayOf(func),
  onCancel: func,
  onApply: func,
  isApplying: bool,
  isLoading: bool,
  onPreview: func,
  disableInteractionButtons: bool,
  availablePlansLoading: bool,
  availablePlansAsOptions: arrayOf(shape({})),
  selectedAutoPlan: shape({}),
  onSelectAutoPlan: func,
};

AddAutoPlanModal.defaultProps = {
  show: false,
  errors: [],
  onCancel: () => {},
  onApply: () => {},
  isApplying: false,
  isLoading: false,
  onPreview: () => {},
  disableInteractionButtons: false,
  availablePlansLoading: false,
  availablePlansAsOptions: [],
  selectedAutoPlan: null,
  onSelectAutoPlan: () => {},
};
