import React from "react";
import { bool, func, shape } from "prop-types";

import ModalAddEditInput from "./fractals";

import BUTTONS from "../ModalAddEditTaskButtons/constants";

const ModalAddEditInputs = ({ enabledAddEditInputs, setEnabledAddEditInputs, payloadHandlrs, payload }) =>
  BUTTONS.map((btnTxt) => (
    <ModalAddEditInput
      key={btnTxt}
      show={enabledAddEditInputs[btnTxt.toLowerCase()] || payload[btnTxt.toLowerCase()]}
      btnTxt={btnTxt}
      payload={payload}
      toggleShow={setEnabledAddEditInputs}
      payloadHandlrs={payloadHandlrs}
    />
  ));

ModalAddEditInputs.propTypes = {
  enabledAddEditInputs: shape({
    Instructions: bool,
    Notes: bool,
  }),
  setEnabledAddEditInputs: func,
  payloadHandlrs: func,
};

ModalAddEditInputs.defaultProps = {
  enabledAddEditInputs: {
    Instructions: false,
    Notes: false,
  },
  setEnabledAddEditInputs: null,
  payloadHandlrs: null,
};

export default ModalAddEditInputs;
