import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Trash, Upload } from "@shared/v2/Icomoon/Icons";

const TimelineAgreementAddendumEvent = ({event}) => {
  const {
    timeline_fields: { updater_name, addendum, agreement, transaction, agreement_type },
    event_type,
    interacted_at_absolute
  } = event;
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = () => (
    <div>
      <span className="bold-detail">{updater_name}</span>
      {` ${event_type === 'agreement_addendum_created' ? 'added' : 'deleted'} a ${agreement_type} agreement addendum`}
    </div>
  );

  const eventBody = () => {
    switch (event_type) {
      case 'agreement_addendum_created':
        return ` ${addendum}`;
      case 'agreement_addendum_deleted':
        return (
          <>
            <span className="tw-italic">{` (${addendum})`}</span>
            <span className="tw-text-[#A81E19]"> removed</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="timeline-event" onClick={showModalHandler}>
        <div className="row">
          <div className="col-xs-1">
            <div className="timeline-icon-circle tw-content-center">
              {event_type === "agreement_addendum_created" ? (
                <Upload className="tw-align-middle" size="l" />
              ) : (
                <Trash className="tw-align-middle" size="l" />
              )}
            </div>
          </div>
          <div className="col-xs-8">
            {headline()}
            {addendum && (
              <div className="timeline-description-text">
                Addendum:
                <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
              </div>
            )}
            {agreement && (
              <div className="timeline-description-text">
                Agreement:
                <span className="tw-text-[#3B3B3B]">{` ${agreement}`}</span>
              </div>
            )}
            {transaction && (
              <div className="timeline-description-text">
                Transaction:
                <span className="tw-text-[#3B3B3B]">{` ${transaction}`}</span>
              </div>
            )}
          </div>
          <div className="col-xs-3 time-ago">
            <span>{interacted_at_absolute}</span>
          </div>
        </div>
      </div>

      <Modal bsSize="sm" show={showModal} onHide={hideModalHandler} className="timeline-content">
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>Agreement Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {headline()}
            {addendum && (
              <>
                <div className="row bold-detail details-row details-header">Details:</div>
                <div className="row details-row tw-mt-2">
                  Addendum:
                  <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
                </div>
                {agreement && (
                  <div className="row details-row tw-mt-2">
                    Agreement:
                    <span className="tw-text-[#3B3B3B]">{` ${agreement}`}</span>
                  </div>
                )}
                {transaction && (
                  <div className="row details-row tw-mt-2">
                    Transaction:
                    <span className="tw-text-[#3B3B3B]">{` ${transaction}`}</span>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

TimelineAgreementAddendumEvent.propTypes = {
  event: PropTypes.shape({
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      agreement_type: PropTypes.string,
      addendum: PropTypes.string,
      agreement: PropTypes.string,
      transaction: PropTypes.string,
    }),
    event_type: PropTypes.string,
    interacted_at_absolute: PropTypes.string
  })
};

TimelineAgreementAddendumEvent.defaultProps = {
  event: {
    timeline_fields: {
      updater_name: "",
      agreement_type: "",
      addendum: "",
      agreement: "",
      transaction: "",
    },
    event_type: "",
    interacted_at_absolute: ""
  }
};

export default TimelineAgreementAddendumEvent;
