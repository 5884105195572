import React from "react";
import PropTypes from "prop-types";

const statusNameColor = {
  valid: "tw-text-neutral-gray-75",
  voiceOnly: "tw-text-semantic-yellow-120",
  invalid: "tw-text-semantic-yellow-120",
  unsubscribed: "tw-text-brivity-coral-110",
};

const statusTooltips = {
  valid: "Can Be Called",
  voiceOnly: "Voice Only",
  invalid: "Invalid",
  unsubscribed: "Opted Out",
};

const StatusOption = ({ status, icon, statusColors, statusTexts }) => {
  const color = (statusColors || statusNameColor)[status];
  const tooltip = (statusTexts || statusTooltips)[status];

  return (
    <div className="tw-cursor-pointer tw-flex tw-items-center tw-space-x-[12px]">
      {icon}
      <span className={`tw-text-14d ${color}`}>{typeof status === "string" ? tooltip : "Unknown"}</span>
    </div>
  );
};

StatusOption.propTypes = {
  status: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  statusColors: PropTypes.object,
  statusTexts: PropTypes.object,
};

StatusOption.defaultValues = {
  statusColors: null,
  statusTexts: null,
};

export default StatusOption;
