import React from "react";
import { Row, Col } from "../../shared/v1";

export default class SharedAutoPlan extends React.Component {
  displaySharedOrNot = () => {
    if (this.props.plan.share_pending) {
      return <span>Adding Plan...</span>;
    } else if (this.props.plan.share_accepted) {
      return <span>Added</span>;
    } else {
      return (
        <a
          onClick={() => {
            this.props.handleOpenModal(this.props.plan.id);
          }}
        >
          <i className="fa fa-plus-square tw-text-brand-info"></i>
          <span>Add to my plans</span>
        </a>
      );
    }
  };

  render() {
    return (
      <Row className="table-row hover:tw-bg-gray-5">
        <Col size={3}>
          <Row>
            <Col size={1}>
              <i className="fa fa-user action-plan-icon"></i>
            </Col>
            <Col size={11}>{this.props.plan.name}</Col>
          </Row>
        </Col>
        <Col size={2}>{this.props.plan.type}</Col>
        <Col size={1}>{this.props.plan.steps}</Col>
        <Col size={1}>{this.props.plan.enrolled}</Col>
        <Col size={2}>{this.props.plan.shared_by}</Col>
        <Col size={1}>{this.props.plan.shared_on}</Col>
        <Col size={2} className="tw-text-right plan-shared-status">
          {this.displaySharedOrNot()}
        </Col>
      </Row>
    );
  }
}
