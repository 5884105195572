import React, { Component } from "react";
import axios from "axios";
import Proptypes from "prop-types";
import debounce from "lodash/debounce";
import Dropdown from "@shared/v2/Dropdown";
import ListingPreviewCard from "./ListingPreviewCard";

class TransactionAutocomplete extends Component {
  debounceSearchAddress = debounce((term) => {
    this.searchAddress(term);
  }, 150);

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      currentTerm: "",
    };
  }

  selectListing = async (term) => {
    this.setState({ searchResults: [] });

    const { selectListing } = this.props;
    await selectListing(term);
  };

  searchAddress = async (term) => {
    if (!term.length) {
      return;
    }
    const { mlsIds } = this.props;
    const idsArrayQuery = mlsIds.map((id) => `mlsIds[]=${id}`).join("&");
    const SearchAddress = this.doSearchAddress(idsArrayQuery, term);
    this.setState({ currentTerm: term }, async () => SearchAddress);
  };

  doSearchAddress = async (idsArrayQuery, term) => {
    const response = await axios.get(`/autocomplete?${idsArrayQuery}&term=${term}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const { currentTerm } = this.state;
    // This happens if a previous query returned later than a later query
    if (currentTerm !== term) {
      return;
    }

    const searchResults = response.data.map((d) => ({ value: d.selected_fields.id, label: d.value }));

    this.setState({ searchResults });
  };

  render() {
    const { selectedBlossorId, listingData, clearListing } = this.props;
    const { searchResults } = this.state;

    return selectedBlossorId ? (
      <ListingPreviewCard listingData={listingData} clearListing={clearListing} />
    ) : (
      <Dropdown
        label="Import Listing Details"
        name="address"
        isSearchable
        onInputChange={this.debounceSearchAddress}
        options={searchResults}
        onChange={(option) => this.selectListing({ key: option.value })}
        placeholder="Search for a listing address to auto-populate the information below"
        menuShouldComeToFront
      />
    );
  }
}

TransactionAutocomplete.propTypes = {
  selectedBlossorId: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  mlsIds: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  listingData: Proptypes.shape({}),
  clearListing: Proptypes.func,
  selectListing: Proptypes.func,
};

TransactionAutocomplete.defaultProps = {
  selectedBlossorId: null,
  listingData: null,
  clearListing: null,
  mlsIds: null,
  selectListing: null,
};

export default TransactionAutocomplete;
