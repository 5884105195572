import {
  saveAllAdvertisingPlan,
  saveAllAvailablePlan,
  saveAllAdsitesPlan,
  saveAllListingViews,
  saveAllAssetsCardViews,
  getListingFromSearch,
  saveImportedListing,
  fetchAutoCompleteFailure,
  importSearchListingFailure,
  fetchAssetsCardFailure,
  fetchEditListingURLFailure,
  fetchMetricsViewFailure,
  fetchAdvertisingPlanFailure,
  fetchAvailablePlanFailure,
  fetchAdSiteFailure,
  fetchManagePlanFailure,
  destroyPlanFailure,
  loadData,
  loadMarketingData,
  updateMLS,
  saveViewReportURL,
  setSearchKeyword,
  setIsSearched,
  loadMlsId,
} from "../TransactionDetailsPage/Main/Marketing/actions/creators";

export const defaultState = {
  advertisingplans: [],
  availablePlan: [],
  homelisting: [],
  importedListing: null,
  adsites: null,
  getMetricsError: null,
  autoCompleteError: null,
  importSearchError: null,
  getAssetCardError: null,
  getAdvertisingPlanError: null,
  submitAdSiteError: null,
  getManagePlanError: null,
  getAvailablePlanError: null,
  getDeletePlanError: null,
  getEditListingURLError: null,
  isLoading: false,
  isSearched: false,
  viewReportURL: "",
  mlsID: "",
  search: "",
  isMarketingLoading: false,
  listingViews: {
    views: {
      total: 0,
      breakdown: {
        brivity: 0,
        zillow: 0
      },
    },
  },
  assetsCardViews: {
    ads: [],
    social_media: [],
    mailers: [],
    print_media: [],
    videos: [],
    empty_states: {
      mailers: {
        image_url: "",
      },
      ads: {
        image_url: "",
      },
      social_media: {
        image_url: "",
      },
      print_media: {
        image_url: "",
      },
      videos: {
        image_url: "",
      },
    },
    external_interactions: {
      sites: {
        zillow: {
          url: "",
        },
      },
      blossor_id: "",
    },
  },
};

export const tdpMarketingReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case saveAllAdvertisingPlan().type:
      return {
        ...state,
        advertisingplans: payload.advertisingplans,
        getManagePlanError: null,
        submitAdSiteError: null,
        getDeletePlanError: null,
        getAdvertisingPlanError: null,
        getAvailablePlanError: null,
      };
    case saveAllAvailablePlan().type:
      return {
        ...state,
        availablePlan: payload.availablePlan,
        getAvailablePlanError: null,
      };
    case saveAllAdsitesPlan().type:
      return {
        ...state,
        advertisingplans: state.advertisingplans.map((advertisingplan) => {
          if (advertisingplan.id === payload.adsites.plan.id) {
            return {
              ...advertisingplan,
              ...payload.adsites.plan,
            };
          }

          return advertisingplan;
        }),
        adsites: payload.adsites,
        getManagePlanError: null,
        submitAdSiteError: null,
      };
    case fetchAdvertisingPlanFailure().type:
      return {
        ...state,
        getAdvertisingPlanError: payload.getAdvertisingPlanError,
      };
    case fetchAvailablePlanFailure().type:
      return {
        ...state,
        getAvailablePlanError: payload.getAvailablePlanError,
      };
    case fetchAdSiteFailure().type:
      return {
        ...state,
        submitAdSiteError: payload.submitAdSiteError,
      };
    case fetchManagePlanFailure().type:
      return {
        ...state,
        getManagePlanError: payload.getManagePlanError,
      };
    case destroyPlanFailure().type:
      return {
        ...state,
        getDeletePlanError: payload.getDeletePlanError,
      };
    case loadData().type:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case saveAllListingViews().type:
      return {
        ...state,
        listingViews: payload.listingViews.data,
        getMetricsError: null,
      };
    case saveAllAssetsCardViews().type:
      return {
        ...state,
        assetsCardViews: payload.assetsCardViews.data,
        getEditListingURLError: null,
        getAssetCardError: null,
        viewReportURL: payload.assetsCardViews.data.view_report_link
      };
    case saveImportedListing().type:
      return {
        ...state,
        importedListing: payload.importedListing,
        autoCompleteError: null,
      };
    case updateMLS().type:
      return {
        ...state,
        mlsID: payload.mlsID,
        autoCompleteError: null,
        importSearchError: null,
      };
    case saveViewReportURL().type:
      return {
        ...state,
        viewReportURL: payload.viewReportURL,
      };
    case getListingFromSearch().type:
      return {
        ...state,
        homelisting: payload.homelisting,
        autoCompleteError: null,
        getAssetCardError: null,
      };
    case fetchAutoCompleteFailure().type:
      return {
        ...state,
        autoCompleteError: payload.autoCompleteError,
      };
    case importSearchListingFailure().type:
      return {
        ...state,
        importSearchError: payload.importSearchError,
      };
    case fetchAssetsCardFailure().type:
      return {
        ...state,
        getAssetCardError: payload.getAssetCardError,
      };
    case fetchEditListingURLFailure().type:
      return {
        ...state,
        getEditListingURLError: payload.getEditListingURLError,
      };

    case fetchMetricsViewFailure().type:
      return {
        ...state,
        getMetricsError: payload.getMetricsError,
      };

    case loadMarketingData().type:
      return {
        ...state,
        isMarketingLoading: payload.isMarketingLoading,
      };
    case loadMlsId({}).type:
      return {
        ...state,
        fetchedMlsId: payload.loaded,
        mlsID: payload.mlsID,
        mlsNumber: payload.mlsNumber,
      };
    case setSearchKeyword().type:
      return {
        ...state,
        search: payload.search,
      };
    case setIsSearched().type:
      return {
        ...state,
        isSearched: payload.isSearched,
      };
    default:
      return state;
  }
};

export default tdpMarketingReducer;
