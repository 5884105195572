import React, { useState } from "react";
import { startCase } from "lodash";
import { Modal } from "react-bootstrap";
import { stripHtml } from "../../../shared/Utilities";
import capitalize from "capitalize";
import IntentButton from "../../../PersonDetail/components/IntentDropdown/IntentButton";

const shortStatus = {
  new: "New",
  unqualified: "Unqual",
  watch: "Watch",
  nurture: "Nurt",
  hot: "Hot",
  "past-client": "Past",
  inactive: "Inactiv",
  archived: "Arch",
  trash: "Trash",
  pending: "Pending",
  "prospective-client": "Prospective",
  "active-client": "Active",
  buyer: "Buyer",
  seller: "Seller",
  "seller/buyer": "Seller & Buyer",
  tenant: "Tenant",
  landlord: "Landlord",
  recruit: "Recruit",
  candidate: "Candidate",
  "n/a": "N/A",
};

const TimelineLeadUpdateEvent = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const details = () => {
    let rows = [];
    const changeset = event.timeline_fields;
    const sortedAttributes = Object.keys(event.timeline_fields).sort();

    sortedAttributes.forEach((attribute) => {
      if (attribute == "updater_name") {
        return;
      }

      let titleizedAttribute = startCase(attribute);

      if (attribute === "anniversary") {
        titleizedAttribute = `Home ${titleizedAttribute}`
      }

      const change = changeset[attribute];

      if (titleizedAttribute == "Status") {
        rows.push(statusDetail(change));
      } else if (titleizedAttribute == "Lead Type") {
        rows.push(intentDetail(change));
      } else if (titleizedAttribute == "Stage") {
        rows.push(stageDetail(change));
      } else if (titleizedAttribute == "Person Type") {
        rows.push(personTypeDetail(change));
      } else {
        rows.push(standardDetail(titleizedAttribute, change));
      }
    });

    return <div>{rows}</div>;
  };

  const statusDetail = (change) => {
    const prevStatus = change[0];
    const newStatus = change[1];

    const prevClasses = `btn btn-sm person-status-${prevStatus}`;
    const newClasses = `btn btn-sm person-status-${newStatus}`;

    const prevShortStatus = shortStatus[prevStatus];
    const newShortStatus = shortStatus[newStatus];

    return (
      <div key={`${event.id}-status-${change}`} className="row details-row">
        {
          <div>
            {"Status: "}
            {prevStatus && (
              <span>
                <span className={prevClasses}>{prevShortStatus}</span>
                {" to "}
              </span>
            )}
            <span className={newClasses}>{newShortStatus}</span>
          </div>
        }
      </div>
    );
  };

  const stageDetail = (change) => {
    const prevStage = change[0];
    const newStage = change[1];
    return (
      <div key={`${event.id}-stage-${change}`} className="row details-row">
        {
          <div>
            {"Stage: "}
            {prevStage && (
              <span>
                <span>{prevStage}</span>
                {" to "}
              </span>
            )}
            <span>{newStage}</span>
          </div>
        }
      </div>
    );
  };

  const personTypeDetail = (change) => {
    const prevPersonType = change[0];
    const newPersonType = change[1];
    return (
      <div key={`${event.id}-person-type-${change}`} className="row details-row">
        {
          <div>
            {"Person Type: "}
            {prevPersonType && (
              <span>
                <span>{capitalize(prevPersonType)}</span>
                {" to "}
              </span>
            )}
            <span>{capitalize(newPersonType)}</span>
          </div>
        }
      </div>
    );
  };

  const intentDetail = (change) => {
    const prevStatus = change[0];
    const newStatus = change[1];
    const prevShortStatus = shortStatus[prevStatus];
    const newShortStatus = shortStatus[newStatus];

    return (
      <div key={`${event.id}-status-${change}`} className="row details-row">
        {
          <div>
            {"Intent: "}
            {prevStatus && (
              <span>
                <IntentButton type={prevStatus} label={prevShortStatus} />
                {" to "}
              </span>
            )}
            <IntentButton type={newStatus} label={newShortStatus} />
          </div>
        }
      </div>
    );
  };

  const standardDetail = (titleizedAttribute, change) => {
    const zeroDate = ["0000-", ""];
    return (
      <div key={`${event.id}-${titleizedAttribute}-${change}`} className="row details-row">
        {`${titleizedAttribute}: `}

        {change[0] && (
          <span className="timeline-person-original-field">
            {"(was "}
            <span>{stripHtml(change[0].replace(...zeroDate))}</span>
            {") "}
          </span>
        )}

        {!change[1] ? (
          <span className="timeline-person-removed-field">removed</span>
        ) : (
          <span>{stripHtml(change[1].replace(...zeroDate))}</span>
        )}
      </div>
    );
  };

  const row = () => (
    <div className="timeline-event" onClick={showModal}>
      <div className="row">
        <div className="col-xs-1">
          <i className="fa fa-user timeline-icon-circle" />
        </div>
        <div className="col-xs-7">
          <span className="bold-detail">{event.timeline_fields.updater_name}</span>
          {" updated profile"}
          <div className="timeline-description-text">{details()}</div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );

  const modal = () => (
    <Modal bsSize="sm" show={isModalOpen} onHide={hideModal} className="timeline-content">
      <div className="timeline-event timeline-event-modal">
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row bold-detail details-row details-header">Details:</div>
          {details()}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </div>
    </Modal>
  );

  return (
    <div>
      {row()}
      {modal()}
    </div>
  );
};

export default TimelineLeadUpdateEvent;
