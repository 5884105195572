import React from "react";
import { Row, Col } from "../../shared/v1";
import SharedAutoPlan from "./SharedAutoPlan";

const SharedAutoPlans = (props) => {
  return (
    <div>
      <Row>
        <Col size={12}>
          <div className="standard-table auto-plan-table">
            <Row div className="table-header-row">
              <Col size={3}>
                <Row>
                  <Col size={11} offset={1}>
                    Name
                  </Col>
                </Row>
              </Col>
              <Col size={2}>Auto Plan Type</Col>
              <Col size={1}>Steps</Col>
              <Col size={1}>Enrolled</Col>
              <Col size={2}>Shared By</Col>
              <Col size={1}>Shared On</Col>
            </Row>
            {props.sharedPlans.map((plan) => (
              <SharedAutoPlan key={plan.id} plan={plan} handleOpenModal={props.handleOpenModal} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SharedAutoPlans;
