import React, { useEffect } from "react";
import PropTypes from "prop-types";

export const ModalContext = React.createContext({});

const ModalContextProvider = ({ show, onHide, children, closeOnEscape }) => {
  const onModalHide = () => {
    if (onHide) onHide();
  };

  const onEscapeKeyPress = (e) => {
    if (closeOnEscape && e.key === "Escape") onModalHide();
  };

  useEffect(() => {
    if (!document) return;

    const addListener = () => {
      document.addEventListener("keyup", onEscapeKeyPress);
      document.body.classList.add("tw-overflow-hidden");
    };

    const removeListener = () => {
      document.removeEventListener("keyup", onEscapeKeyPress);
      document.body.classList.remove("tw-overflow-hidden");
    };

    if (show) {
      addListener();
    } else {
      removeListener();
    }

    // We only need to return a cleanup function once we actually attach the event listener
    // eslint-disable-next-line consistent-return
    return removeListener;
  }, [show]);

  return (
    <ModalContext.Provider
      value={{
        show,
        onHide: onModalHide,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
ModalContextProvider.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  closeOnEscape: PropTypes.bool,
};
ModalContextProvider.defaultProps = {
  show: false,
  onHide: null,
  children: null,
  closeOnEscape: true,
};

export default ModalContextProvider;
