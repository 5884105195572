import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFish, faUser } from "@fortawesome/free-solid-svg-icons";

export default class TimelineLeadCreateEvent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
    this.event = this.props.event;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.transferred_by = this.event.timeline_fields["transferred_by"]
    this.old_primary_agent = this.event.timeline_fields["old_primary_agent"]
    this.new_primary_agent = this.event.timeline_fields["new_primary_agent"]
    this.new_lead_pond = this.event.timeline_fields["new_lead_pond"]
    this.old_lead_pond = this.event.timeline_fields["old_lead_pond"]
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({ show_modal: false });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, ...nextProps });
  }

  description = () => {
    return (
      <div>
        {"Transferred by "}
        <span className="bold-detail">{this.transferred_by}</span>
        {" from "}
        <span className="bold-detail">
          {this.old_primary_agent} {this.old_lead_pond}
        </span>
      </div>
    );
  };

  headline = () => {
    const isPondToPond = this.new_lead_pond && this.old_lead_pond,
      isAgentToPond = this.old_primary_agent && this.new_lead_pond;
    return (
      <div>
         {(isPondToPond || isAgentToPond) && `Assigned to `}
        <span className="bold-detail">
          {this.new_primary_agent}
          {this.new_lead_pond}
        </span>
        {!isPondToPond && !isAgentToPond && ` became the primary agent`}
        {isAgentToPond && ` by `}
        <span className="bold-detail">
          {isAgentToPond && `${this.transferred_by}`}
        </span>
      </div>
    );
  };

  row = () => {
    const isAgentToPond = this.old_primary_agent && this.new_lead_pond,
      icon = this.old_lead_pond || this.new_lead_pond ? faFish : faUser;
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className={`row header ${isAgentToPond ? "tw-flex tw-justifiy-center tw-align-center" : ""}`}>
          <div className="col-xs-1">
            <span className={`timeline-icon-circle tw-inline-flex tw-justify-center tw-items-center`}>
              <FontAwesomeIcon icon={icon} />
            </span>
          </div>
          <div className={`col-xs-7 tw-flex tw-flex-col tw-justify-center tw-align-center`}>
            {this.headline()}
            <div className="timeline-description-text">{this.description()}</div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    return (
      this.state.show_modal && (
        <Modal bsSize="sm" show={this.state.show_modal} onHide={this.hideModal} className="timeline-content">
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>Lead Transferred</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.headline()}
              <div className="row bold-detail details-row details-header">Details:</div>
              {this.description()}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        </Modal>
      )
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
