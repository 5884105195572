import React from "react";
import { Col } from "@shared/v1";
import { Close } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import TextInput from "@shared/v2/TextInput";
import AiAssistant from "@shared/AiAssistant";

const TaskInstructions = ({ task, toggleInstructions, handleTaskChange }) => (
  <Col size={12} className="tw-mb-[16px]">
    <div className="tw-flex tw-justify-end tw-gap-[8px] tw-absolute tw-top-0 tw-right-[10px]">
      <AiAssistant
        textareaId="task-instructions-input"
        messageType="Generic"
        onInsertClick={(text) => handleTaskChange({ target: { name: "instructions", value: text } })}
      />
      <IconButton size="small" schema="transparent" onClick={toggleInstructions}>
        <Close />
      </IconButton>
    </div>
    <TextInput
      id="task-instructions-input"
      multiline
      size="auto"
      name="instructions"
      label="Instructions"
      value={task.instructions || ""}
      onChange={handleTaskChange}
    />
  </Col>
);

export default TaskInstructions;
