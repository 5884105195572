import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Avatar from "@shared/v2/Avatar";
import TextLink from "@shared/v2/TextLink";
import Tooltip from "@shared/v2/Tooltip";
import { useTheme } from "../reducers/layoutReducer/selectors";

const ContactSelectorButton = ({ contact, disabled, onSelect, selected }) => {
  const isPlaceTheme = useTheme().name === "place-theme";
  const fullName = useMemo(
    () => `${contact?.firstName || ""} ${contact?.lastName || ""}`.trim() || "No Name",
    [contact],
  );

  const content = (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <TextLink
        className="tw-uppercase !tw-text-11d tw-font-semibold"
        href={`/people/${contact.slug}`}
        target="_blank"
      >
        View Profile
      </TextLink>
      <button
        type="button"
        disabled={disabled}
        className={`tw-group tw-w-[218px] tw-flex tw-justify-between tw-items-center tw-gap-[8px] tw-p-[8px] tw-rounded-[6px] tw-bg-white tw-border-none tw-text-neutral-gray-50 tw-text-12d tw-font-semibold tw-outline tw-outline-[1px] tw-outline-offset-0 active:tw-outline-theme-primary active:tw-outline-[2px] disabled:tw-border-neutral-gray-10 disabled:tw-bg-neutral-gray-2.5 ${
          selected
            ? "tw-outline-theme-primary"
            : `tw-outline-neutral-gray-10 tw-transition-all tw-duration-150 active:tw-duration-75 ${isPlaceTheme ? "hover:tw-outline-semantic-blue-10 focus:tw-outline-semantic-blue-10" : "hover:tw-outline-brivity-blue-40 focus:tw-outline-brivity-blue-40"}`
        }`}
        onClick={() => onSelect?.(contact)}
      >
        <div className="tw-flex tw-gap-[8px] tw-items-center tw-overflow-hidden">
          <Avatar size="medium" src={contact?.avatarUrl} alt={fullName} />
          <span
            className={`tw-transition-all tw-duration-150 group-active:tw-text-theme-primary-alternative ${isPlaceTheme ? "" : "group-hover:tw-text-theme-primary group-focus:tw-text-theme-primary"} ${selected ? "tw-text-theme-primary-alternative" : ""} tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis`}
          >
            {fullName}
          </span>
        </div>
        <span className="tw-uppercase tw-pr-[8px]">{selected ? "Keep" : "Merge"}</span>
      </button>
    </div>
  );

  if (disabled) {
    return (
      <Tooltip
        innerClassName="tw-max-w-[220px]"
        trigger={content}
        content="This lead cannot be set as the primary lead because the other lead is associated with a transaction."
      />
    );
  }

  return content;
};

ContactSelectorButton.propTypes = {
  contact: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ContactSelectorButton;
