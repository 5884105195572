import React from "react";
import PropTypes from "prop-types";

const Card = ({ title, action, children }) => (
  <div className="tw-bg-white tw-p-[32px] tw-rounded-[8px] tw-border tw-border-solid tw-border-neutral-gray-10 tw-flex-col">
    <div className="tw-flex tw-items-center tw-mb-[24px]">
      <h3 className="tw-text-[18px] tw-text-neutral-75 tw-my-0">{title}</h3>
      {action}
    </div>
    {children}
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func,
  children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
};

Card.defaultProps = {
  children: null,
  action: null,
};
export default Card;
