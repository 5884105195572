import { useState, useEffect } from "react";

const useEnabledButtons = (listingImgs) => {
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    const foundChecked = listingImgs.find(({ checked }) => checked === true);
    setEnable(!!foundChecked);
  }, [listingImgs]);

  return enable;
};

export default useEnabledButtons;
