import { useEffect, useState } from "react";
import { isCancel } from "axios";
import { caseTransformingAxios as axios } from "../../../../shared/v2/caseTransformingAxios";
import formatter from "../../../../shared/formatters";
import colors from "../helpers/colors";

const transformGlanceToChartData = (
  {
    format,
    goal: initialGoal = null,
    ytdGoalTarget = 0,
    total = 0,
    prevYearYtdTarget,
    displayName,
    ...rest
  } = {},
  year,
) => {
  const goal = Number(initialGoal);
  const ytdDifference = total - ytdGoalTarget;
  const prevYearYtdDifference = total - (prevYearYtdTarget || 0);
  const currentPercentage = total / (goal || 1);
  const targetPercentage = ytdGoalTarget / (goal || 1);

  return {
    ...rest,
    format,
    hasGoal: goal !== 0,
    goal: formatter[format](goal),
    total: formatter[format](total),
    ytdGoalTarget: formatter[format](ytdGoalTarget),
    chartLabel: goal === 0 ? "–" : `${formatter.number(currentPercentage * 100)}%`,
    ytdDifference,
    prevYearYtdDifference: prevYearYtdTarget ? prevYearYtdDifference : null,
    displayName,
    baseTooltip: [`${year} Goal:`, formatter[format](goal)],
    chartData: [
      {
        color: colors.color2.default,
        value: targetPercentage,
        tooltip: ["YTD Goal:", formatter[format](ytdGoalTarget)],
      },
      {
        color: colors.color1.default,
        value: currentPercentage,
        tooltip: [`${displayName}:`, formatter[format](total)],
      },
    ],
  };
};

const transformGoalProgress = ({ goals = [], year }) =>
  goals.map((goal) => transformGlanceToChartData(goal, year));

const useGoals = ({ agentUuid, year }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(transformGoalProgress({}));
  const [error, setError] = useState(null);

  const actions = {
    reload: (signal) => {
      setLoading(true);
      return axios
        .get(`/api/v4/reporting/${agentUuid ? "agent" : "business"}_dashboard`, {
          params: { selected_year: year, section: "goal_progress", person_uuid: agentUuid },
          signal,
        })
        .then((res) => {
          setData(transformGoalProgress(res.data));
          setError(null);
          setLoading(false);
        })
        .catch((err) => {
          if (!isCancel(err)) {
            setError(err);
            setLoading(false);
          }
        });
    },
  };

  useEffect(() => {
    const abortController = new AbortController();
    actions.reload(abortController.signal);
    return () => abortController.abort();
  }, [agentUuid, year]);

  return { actions, data, error, loading };
};

export default useGoals;
