import React, { useState } from "react";
import PropTypes from "prop-types";
import format from "@shared/formatters";
import NumberField from "./NumberField";
import DashTooltip from "../../Reports/components/DashboardShared/DashTooltip";
import TextInput from "../../shared/v2/TextInput/TextInput";
import ButtonToggle from "../../shared/v2/ButtonToggle/ButtonToggle";
import IconButton from "../../shared/v2/IconButton";
import { Trash } from "../../shared/v2/Icomoon";
import DeleteGoalTypeModal from "./DeleteGoalTypeModal";

const getTooltipText = ({ name }) => {
  if (name === "per_agent_productivity") return "Per Agent Productivity";
  if (name === "contacts") return 'Calls made with an outcome of "Talked"';
  if (name === "signings")
    return 'Signing appointments completed with an outcome of "Signed - Buyer Agreement", "Signed - Listing Agreement", or "Signed - Both Buyer/Listing"';
  return "";
};

const Goal = ({ editedGoal, goal, goalType, isDefault, isEditing, onChange, onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isCustomGoal = isDefault && goalType.accountId !== null;
  return (
    <div className="tw-flex tw-items-center tw-min-h-[52px] tw-gap-[12px]">
      {isEditing && isCustomGoal ? (
        <div className="tw-flex-1">
          <TextInput
            value={editedGoal?.displayName || goalType.displayName}
            onChange={(e) => onChange("displayName")(e.target.value)}
          />
        </div>
      ) : (
        <label
          className="tw-flex-1 tw-min-w-[160px] tw-text-14d tw-font-semibold tw-text-gray-75 tw-m-0"
          htmlFor={goalType.name}
        >
          {goalType.displayName} <DashTooltip text={getTooltipText(goalType)} />
        </label>
      )}

      {isEditing ? (
        <div className="tw-flex tw-items-center tw-flex-1 tw-gap-[4px]">
          <NumberField
            format={goalType.precision ? "decimal" : editedGoal?.format || goalType.format}
            precision={goalType.precision}
            id={goalType.name}
            name={goalType.id}
            defaultValue={editedGoal?.goalValue !== undefined ? editedGoal.goalValue : goal?.goalValue || 0}
            onChange={onChange("goalValue")}
          />
          {isCustomGoal && (
            <>
              <ButtonToggle
                options={[
                  { label: "$", value: "currency" },
                  { label: "#", value: "number" },
                ]}
                value={editedGoal?.format || goalType.format}
                onChange={(e) => onChange("format")(e.target.value)}
              />
              <IconButton schema="transparent" onClick={() => setShowDeleteModal(true)}>
                <Trash />
              </IconButton>
            </>
          )}
        </div>
      ) : (
        <p className="tw-m-0 tw-flex-1">
          {format[goalType.format](
            goal?.goalValue || 0,
            (goal?.goalValue || 0) % 1 > 0 ? goalType.precision : 0,
          )}
        </p>
      )}

      <DeleteGoalTypeModal
        displayName={goalType.displayName}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() => onDelete(goalType)}
        show={showDeleteModal}
      />
    </div>
  );
};

Goal.propTypes = {
  editedGoal: PropTypes.shape(),
  isDefault: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  goal: PropTypes.shape({
    goalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  goalType: PropTypes.shape({
    accountId: PropTypes.number,
    format: PropTypes.string,
    precision: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displayName: PropTypes.string,
  }).isRequired,
};

Goal.defaultProps = {
  editedGoal: undefined,
  goal: undefined,
  isDefault: false,
  onDelete: () => {},
};

export default Goal;
