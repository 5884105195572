/* eslint-disable spaced-comment */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@shared/v2/Button";
import { SUBJECT_LINE_LIMIT_ERROR, isWithinSubjectLineLimit } from "@shared/hooks/useSubjectLineLimitError";
import { Modal, Row, Col, CancelButton, DeleteButton } from "../../shared/v1";
import TaskForm from "./TaskForm";
import EmailForm from "./EmailForm";
import TextForm from "./TextForm";
import Action from "./Action";
import Error from "../../shared/Error";
import "./ActionForm.scss";
import { getActionById, shortDelayUnits, timeOptions, findDefaultRunAfterTimeOption } from "./helpers";
import { formattedTimeFromUtcDateTimeString } from "../../shared/TimeHelpers";
import { toTitleCase } from "../../shared/Utilities";

import * as autoPlanActionCreators from "../actions/autoPlanActionCreators";
import { ACTION_BTN_LABEL } from "../styles";
import { hasProperties } from "../helpers";

const modalComponents = {
  Task: TaskForm,
  Email: EmailForm,
  Text: TextForm,
};

class Actions extends React.Component {
  scheduleTypeLabels = {
    days: "Day",
    milestones: "Milestone",
  };

  constructor(props) {
    super(props);
    this.state = {
      subjectErrorMsg: null,
    };
  }

  handleActionUpdate = (action, actionsPath, autoPlanPath) => {
    const { autoPlanActions } = this.props;
    if (!this.validateForm(action)) {
      return;
    }

    const parentActionId = action.parent_action_id === "Trigger" ? null : action.parent_action_id;
    const formData = { ...action, parent_action_id: parentActionId };
    autoPlanActions.saveAction(`${actionsPath}/${action.id}`, formData, autoPlanPath);
  };

  handleActionCreate = (action, actionsPath, autoPlanPath) => {
    const { autoPlanActions } = this.props;
    if (!this.validateForm(action)) {
      return;
    }

    const parentActionId = action.parent_action_id === "Trigger" ? null : action.parent_action_id;
    const formData = { ...action, parent_action_id: parentActionId };

    autoPlanActions.createAction(actionsPath, formData, autoPlanPath);
  };

  handleScheduleTypeChange = (scheduleType) => {
    const { actionForm, autoPlanActions } = this.props;
    const newValues =
      scheduleType === "computed_time"
        ? {
          delay: 0,
          delay_factor: 1,
          milestone_id: null,
          run_time: findDefaultRunAfterTimeOption(actionForm.type).value,
          schedule_type: "computed_time",
        }
        : {
          parent_action_id: null,
          delay_unit: "days",
          schedule_type: "event",
          contingent: false,
        };

    autoPlanActions.updateActionManyValues(newValues);
  };

  handleDelayUnitChange = (delayUnit) => {
    const { actionForm, autoPlanActions } = this.props;
    const newValues = {
      delay_unit: delayUnit,
      run_time: delayUnit === "days" ? findDefaultRunAfterTimeOption(actionForm.type).value : null,
    };

    autoPlanActions.updateActionManyValues(newValues);
  };

  handleAssignedToChange(option) {
    const { autoPlanActions } = this.props;
    const { name, value, assigned_to_type: assignedToType } = option;
    const resetFields =
      name === "assigned_to_role" ? ["assigned_to_id", "assigned_to_type"] : ["assigned_to_role"];

    const newValues = { assigned_to_type: assignedToType, [name]: value };
    const resetValues = resetFields.reduce((obj, resetField) => ({ ...obj, [resetField]: null }), {});

    autoPlanActions.updateActionManyValues({ ...newValues, ...resetValues });
  }

  handleAssignedForChange(option) {
    const { autoPlanActions } = this.props;
    const { name, value, assigned_for_type: assignedForType } = option;
    const resetFields =
      name === "assigned_for_role" ? ["assigned_for_id", "assigned_for_type"] : ["assigned_for_role"];

    const newValues = { assigned_for_type: assignedForType, [name]: value };
    const resetValues = resetFields.reduce((obj, resetField) => ({ ...obj, [resetField]: null }), {});

    autoPlanActions.updateActionManyValues({ ...newValues, ...resetValues });
  }

  handleCCChange(option, prefix, roleUuids = [], peopleUuids = [], emails = [], remove = false) {
    if (!option) return;

    const { autoPlanActions } = this.props;
    const { name, value, cc_type: ccType } = option;
    // eslint-disable-next-line no-nested-ternary
    const values = ccType === "Person" ? peopleUuids : ccType === "Role" ? roleUuids : emails;

    if (remove) values.splice(values.indexOf(value), 1);
    else if (!values.includes(value)) values.push(value);

    const filteredSet = new Set(values.filter(Boolean));

    autoPlanActions.updateActionManyValues({ [`${prefix}${name}`]: Array.from(filteredSet) });
  }

  validateForm = (action) => {
    const { autoPlanActions } = this.props;
    const errors = [];

    if (
      action.recurring_frequency === "every" &&
      !this.isEveryNDaysValid(action.recurring_days_of_frequency)
    ) {
      errors.push("Enter 3 digits or less for recurring frequency.");
    }

    if (
      action.recurring_frequency === "weekly" &&
      (!action.recurring_days_of_frequency || action.recurring_days_of_frequency.length === 0)
    ) {
      errors.push("Select the day of week to recur on.");
    }

    if (
      action.recurring_frequency === "monthly" &&
      (!action.recurring_days_of_frequency || action.recurring_days_of_frequency.length === 0)
    ) {
      errors.push("Select the day of month to recur on.");
    }

    // Name is not present or it is empty
    if (!action.name && action.name.trim()) {
      errors.push("Name can't be blank.");
    }

    if (
      action.assigned_to_id === undefined &&
      action.assigned_to_role !== "owner" &&
      action.assigned_to_role !== "primary_agent"
    ) {
      errors.push("Please select who the task should be assigned to.");
    }
    if (errors.length > 0) {
      autoPlanActions.submitActionFailure(errors);
      return false;
    }

    return true;
  };

  setTriggersForModal = (timeline) => {
    const options = [{ label: "Auto Plan starts", value: "Trigger" }];
    // Destructuring would prevent use of the `?` operator here and cause a TypeError
    // eslint-disable-next-line react/destructuring-assignment
    const idForThisAction = this.props.actionForm?.id || 0;

    // We have multiple schedule trigger types (e.g.: after X days, or after a
    // certain milestone like a birthday)
    for (const [scheduleType, optionsForScheduleType] of Object.entries(timeline || {})) {
      // For each of those, we can have multiple of them (e.g.: we can have a
      // triger after 1 day, another after 2... or a trigger for a birthday
      // milestone and another for some other milestone)
      for (const [event, optionsForEvent] of Object.entries(optionsForScheduleType)) {
        // And finally, within each of those, we can have multiple actions
        // (e.g.: several actions triggered after X days), which is how we want
        // to group them together in the UI. As such, we collapse them here into
        // a single option group and label according to the current
        // day/milestone.
        const timelineGroup = Array.prototype.concat(...Object.values(optionsForEvent));
        options.push({
          label: `${this.scheduleTypeLabels[scheduleType]} ${event}`,
          options: Object.values(timelineGroup)
            .map((timelineAction) => {
              const action = this.actionLookup(timelineAction.id);
              if (action.id === idForThisAction) {
                // An action should not be able to trigger itself.
                return null;
              }

              return {
                label: (
                  <span>
                    <strong>{action.type} - </strong> {action.name}
                  </span>
                ),
                value: action.id,
              };
            })
            .filter((option) => option !== null),
        });
      }
    }

    return options;
  };

  relativeTimeLabel = (action) => {
    const atOrBy = action.type === "Task" ? "by" : "at";
    const timeOfDayLabel =
      action.delay_unit === "days" && action.run_time
        ? `${atOrBy} ${formattedTimeFromUtcDateTimeString(action.run_at)}`
        : "";
    const unitLabel = shortDelayUnits[action.delay_unit];

    if (action.parent_action_id) {
      return `${action.delay} ${unitLabel} after ${action.parent_action && action.parent_action.type
        } ${timeOfDayLabel}`;
    }
    return `${action.delay} ${unitLabel} after Trigger ${timeOfDayLabel}`;
  };

  milestoneTimeLabel = (action) => {
    const atOrBy = action.type === "Task" ? "by" : "at";
    let when = "";

    // eslint-disable-next-line eqeqeq
    const timeOption = timeOptions().find((e) => e.value == action.run_time);
    const timeOfDayLabel = timeOption ? `${atOrBy} ${timeOption.label}` : "";
    const unitLabel = shortDelayUnits[action.delay_unit];

    if (action.delay === 0) {
      return timeOfDayLabel;
    }
    if (action.delay > 0) {
      when = "after";
    } else if (action.delay < 0) {
      when = "before";
    }

    return `${Math.abs(action.delay)} ${unitLabel} ${when} ${timeOfDayLabel}`;
  };

  deleteButton = () => {
    const { isDeletingAction } = this.props;
    return (
      <DeleteButton
        className="tw-float-right"
        onClick={this.handleDeleteAction}
        disabled={isDeletingAction}
      />
    );
  };

  handleDeleteAction = () => {
    const {
      actions_path: actionsPath,
      autoPlanActions,
      isDeletingAction,
      activeActionId,
      autoPlanPath,
    } = this.props;
    const deletePath = `${actionsPath}/${activeActionId}`;
    if (!isDeletingAction) autoPlanActions.deleteAction(deletePath, activeActionId, autoPlanPath);
  };

  showNewActionModal = (formOverrides, day = 0) => {
    const { actionForm, auto_plan_id: autoPlanId, autoPlanActions } = this.props;
    const form = { ...actionForm, ...formOverrides };
    let runAfterTime = {};

    if (day !== 0) {
      const runTimeOption = findDefaultRunAfterTimeOption(form.type);
      runAfterTime = { delay: day, delay_unit: "days", run_time: runTimeOption.value };
    }

    const action = { ...form, ...runAfterTime, auto_plan_id: autoPlanId };
    autoPlanActions.openNewActionModal(action);
  };

  // nDaysValue is expected to be an array with one number as a string. For instance: ['1']
  isEveryNDaysValid = (nDaysValue) => {
    if (!nDaysValue || !nDaysValue[0]) {
      return false;
    }

    return +nDaysValue[0] > 0 && +nDaysValue[0] < 1000;
  };

  groupHeader = (type, value) => {
    if (type === "days") {
      return `Day ${value}`;
    }
    return toTitleCase(value);
  };

  actionLookup(id) {
    const { actions } = this.props;
    return actions.find((action) => action.id === id);
  }

  timeLabel(timelineAction, day) {
    const action = this.actionLookup(timelineAction.id);
    if (!action) {
      return null;
    }

    if (action.schedule_type === "event") {
      return this.milestoneTimeLabel(action);
    }
    // eslint-disable-next-line eqeqeq
    if (day == 0) {
      return this.relativeTimeLabel(action);
    }
    // eslint-disable-next-line eqeqeq
    const timeOptionLabel = timeOptions().find((e) => e.value == action.run_time);
    return timeOptionLabel ? timeOptionLabel.label : this.relativeTimeLabel(action);
  }

  renderModal() {
    const {
      autoPlan,
      isModalOpen,
      actionForm,
      autoPlanActions,
      actions_path: actionsPath,
      autoPlanPath,
      assignedToOptions,
      assignedForOptions,
      milestoneOptions,
      placeholders,
      timeline,
      hasError,
      errorMessage,
      email_templates: propsEmailTemplates,
    } = this.props;
    const { subjectErrorMsg } = this.state;
    const actionType = actionForm.type.toLowerCase();
    const title = actionForm.id ? `Update ${actionType}` : `Create a new ${actionType}`;
    const createOrUpdate = actionForm.id ? this.handleActionUpdate : this.handleActionCreate;
    const ModalForm = modalComponents[actionForm.type];
    const emailTemplates = actionForm.type === "Email" ? propsEmailTemplates : null;
    const { days, milestones } = timeline;
    const triggerOptions = this.setTriggersForModal({ days, milestones }) || [];

    return (
      <Modal
        show={isModalOpen}
        className="action-modal"
        enforceFocus={false}
        onHide={autoPlanActions.closeNewActionModal}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body deprecatedOverridePaddingClasses="tw-pt-15px tw-px-15px">
          {hasError && <Error errorMessage={errorMessage} />}
          {subjectErrorMsg && <Error errorMessage={subjectErrorMsg} />}
          <div className="action-form">
            <ModalForm
              className="action-form"
              action={actionForm}
              planType={autoPlan.plan_type}
              hideAssignedFor={autoPlan.treat_assignable_as_assigned_for}
              onChange={autoPlanActions.updateActionManyValues}
              handleScheduleTypeChange={this.handleScheduleTypeChange}
              handleDelayUnitChange={this.handleDelayUnitChange}
              onActionSubmit={(e) => {
                if (actionForm.type === 'Email' && !isWithinSubjectLineLimit(actionForm.name)) {
                  return this.setState({ subjectErrorMsg: SUBJECT_LINE_LIMIT_ERROR });
                }
                this.setState({ subjectErrorMsg: null })
                return createOrUpdate(e, actionsPath, autoPlanPath)
              }}
              actionsPath={actionsPath}
              autoPlanPath={autoPlanPath}
              emailTemplates={emailTemplates}
              triggers={triggerOptions}
              milestoneOptions={milestoneOptions}
              onAssignedToChange={(opt) => this.handleAssignedToChange(opt)}
              onAssignedForChange={(opt) => this.handleAssignedForChange(opt)}
              assignedToOptions={assignedToOptions}
              assignedForOptions={assignedForOptions}
              placeholders={placeholders}
              onCloseAction={() => {
                this.setState({ subjectErrorMsg: null })
                autoPlanActions.closeNewActionModal()
              }}
              onCCChange={(opt, prefix, roles, people, emails, remove) =>
                this.handleCCChange(opt, prefix, roles, people, emails, remove)
              }
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderDeleteModal = () => {
    const { autoPlanActions, isDeleteActionModalOpen, activeActionId, autoPlan } = this.props;
    const activeAction = getActionById(activeActionId, autoPlan);

    return (
      activeAction && (
        <Modal show={isDeleteActionModalOpen} onHide={autoPlanActions.closeDeleteActionModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="tw-text-brand-danger">Do you really want to delete this action?</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <span>
                Please note that deleting{" "}
                <span className="bold">
                  {activeAction.type}: {activeAction.name}{" "}
                </span>
                will also delete all tasks and interactions contingent on that.
              </span>
            </p>
            {activeAction && activeAction.descendants.length > 0 && (
              <div>
                <p>The following actions will also be deleted if this action is removed.</p>

                {activeAction.descendants.map((descendant) => (
                  <p key={`to-delete-${descendant.id}`}>
                    <span className="bold">
                      {descendant.type}: {descendant.name}
                    </span>
                  </p>
                ))}
              </div>
            )}
            <div className="p-t-20">
              <CancelButton
                size="small"
                className="modal-cancel"
                onClick={autoPlanActions.closeDeleteActionModal}
              />
              {this.deleteButton()}
            </div>
          </Modal.Body>
        </Modal>
      )
    );
  };

  renderActions(actions) {
    const { autoPlanActions, assignedForOptions } = this.props;
    return (
      actions &&
      actions.map((a) => {
        const action = this.actionLookup(a.id);

        return (
          action && (
            <Action
              key={action.id}
              action={action}
              assignedForOptions={assignedForOptions}
              autoPlanActions={autoPlanActions}
            />
          )
        );
      })
    );
  }

  renderNewActionButtons(groupType, day) {
    return (
      <Row>
        <Col size={12}>
          <div className="d-flex flex-row flex-items-center">
            <div className={ACTION_BTN_LABEL}>Add a new</div>
            <div className="tw-flex tw-gap-[10px]">
              {Object.keys(modalComponents).map((type) => (
                <Button
                  key={type}
                  size="medium"
                  schema='secondary'
                  onClick={() => this.showNewActionModal({ type }, groupType === "days" && day)}
                >
                  {" "}
                  {type}{" "}
                </Button>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    const { actions, isModalOpen, isDeleteActionModalOpen, activeActionId, timeline } = this.props;
    // extracting dates from timeline since this will be render
    let groups;
    if (timeline) {
      const { dates, ...restOfTimeline } = timeline;
      groups = { ...restOfTimeline };
    }

    return (
      <div className="auto-plan-timeline">
        {(groups && !hasProperties(groups)) && <h3 className="day-label !tw-mt-0 !tw-mb-[16px]">{this.groupHeader('days', 0)}</h3>}
        {(groups && !hasProperties(groups)) && this.renderNewActionButtons("days", 0)}
        {actions &&
          actions.length > 0 &&
          groups &&
          //sorted by grouping (i.e. milestone, days)
          Object.keys(groups).map((groupType) => (
            <div key={groupType}>
              {groups[groupType] &&
                //sorted by within milestone or days (i.e. birthdays, 0, 1, 2)
                Object.keys(groups[groupType]).map((day) => (
                  <div key={day} className="auto-plan-timeline-day">
                    <div>
                      <h3 className="day-label">{this.groupHeader(groupType, day)}</h3>
                    </div>
                    <div className='auto-plan-timeline-day-content p-l-15'>

                      {groups[groupType][day] &&
                        Object.keys(groups[groupType][day])
                          .sort()
                          .map((time) => (
                            <div key={`auto-plan-${time}`} className="d-flex">
                              <div className="auto-plan-timeline-left-column">
                                <div className="time-label">
                                  {" "}
                                  {this.timeLabel(groups[groupType][day][time][0], day)}{" "}
                                </div>
                              </div>
                              <div className="auto-plan-timeline-right-column">
                                <div className="action">
                                  <div>{this.renderActions(groups[groupType][day][time])}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>

                    {this.renderNewActionButtons(groupType, day)}
                  </div>
                ))}
            </div>
          ))}
        {isDeleteActionModalOpen && activeActionId && this.renderDeleteModal()}
        {isModalOpen && this.renderModal()}
      </div>
    );
  }
}

Actions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionForm: PropTypes.objectOf(PropTypes.any).isRequired,
  actions_path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
  activeActionId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedForOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedToOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  auto_plan_id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanActions: PropTypes.objectOf(PropTypes.any).isRequired,
  autoPlanPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  email_templates: PropTypes.arrayOf(PropTypes.any).isRequired,
  errorMessage: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  isDeleteActionModalOpen: PropTypes.bool.isRequired,
  isDeletingAction: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestoneOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  placeholders: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timeline: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  ...state.autoPlanEdit,
});

const mapDispatchToProps = (dispatch) => ({
  autoPlanActions: bindActionCreators(autoPlanActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
