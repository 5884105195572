import React from "react";

import ReferralTypeBox from "../ReferralTypeBox";
import ReferralPropertyType from "../ReferralPropertyType";
import ReferralTitle from "../ReferralTitle";
import ReferralAddress from "../ReferralAddress/ReferralAddress";
import TimeFrame from "../TimeFrame";

const TransactionSummary = () => (
  <div className="TransactionSummary">
    <div className="tw-flex">
      <ReferralTypeBox />
      <div className="tw-border-l-1px tw-border-r-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-neutral-gray-10 tw-mr-12px tw-ml-12px" />
      <ReferralPropertyType />
    </div>
    <ReferralTitle />
    <div className="tw-h-[52px] tw-flex tw-flex-col tw-justify-between">
      <ReferralAddress />
      <TimeFrame />
    </div>
  </div>
);

export default TransactionSummary;
