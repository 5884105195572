/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BathRegularV6 = (props) => (
  <Icon {...props} name="Bath Regular V6">
    <path d="M15.25 8.003H2.5V2.167a.664.664 0 0 1 1.133-.469l.099.099c-.406.847-.266 1.862.437 2.593l.464.464a.738.738 0 0 0 .618 1.145.746.746 0 0 0 .53-.22l3-3a.75.75 0 0 0 0-1.061.71.71 0 0 0-.511-.211.776.776 0 0 0-.416.123l-.463-.464C6.66.463 5.641.324 4.794.733L4.747.687C4.263.203 3.588-.072 2.908.015a2.169 2.169 0 0 0-1.907 2.151V8l-.25.001a.75.75 0 1 0 0 1.5h.25V12c0 .848.367 1.655 1 2.222v1.028a.749.749 0 1 0 1.5-.001v-.301c.164.031.332.047.5.051h8c.168-.004.336-.019.5-.051v.301c0 .415.334.75.75.75s.75-.334.75-.75v-1.028c.633-.566 1-1.374 1-2.222V9.502h.25a.751.751 0 0 0 0-1.5zM6.328 2.228l.442.442-1.101 1.099-.441-.441a.778.778 0 0 1 1.1-1.1zM13.5 12a1.5 1.5 0 0 1-1.5 1.5H4A1.5 1.5 0 0 1 2.5 12V9.503h11V12z" />
  </Icon>
);

export default BathRegularV6;
