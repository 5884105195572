/* eslint-disable camelcase */
import React from "react";
import Tooltip from "@shared/v2/Tooltip";
import personDetailCss from "../../../../PersonDetail/components/person-detail-styles.module.css";

export const OTHER_OPTIONS = [
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Pop By",
    value: "pop_by",
  },
  {
    label: "Mail",
    value: "mail",
  },
  {
    label: "Social Media",
    value: "social_media",
  },
];

export const emailStatus = (unsubscribedMessageTypes, emailDetails) => {
  if (unsubscribedMessageTypes?.length > 0) {
    return (
      <div className="tw-pt-[2px]">
        <Tooltip
          placement="top"
          trigger={
            <img
              src="/assets/emails/unsubscribed-email.svg"
              className={personDetailCss.emailStatusIcon}
              alt="Unsubscribed Email"
            />
          }
          content="Unsubscribed Email"
        />
      </div>
    );
  }
  switch (emailDetails !== undefined && emailDetails[0]?.is_verified) {
    case true:
      return (
        <div className="tw-pt-[2px]">
          <Tooltip
            placement="top"
            trigger={
              <img
                src="/assets/emails/valid-email.svg"
                alt="Valid Email"
                className={personDetailCss.emailStatusIcon}
              />
            }
            content="Valid Email"
          />
        </div>
      );
    case false:
      return (
        <div className="tw-pt-[2px]">
          <Tooltip
            placement="top"
            trigger={
              <img
                src="/assets/emails/invalid-email.svg"
                alt="Email may be invalid"
                className={personDetailCss.emailStatusIcon}
              />
            }
            content="Email may be invalid"
          />
        </div>
      );
    case null:
      return (
        <div className="tw-pt-[2px]">
          <Tooltip
            placement="top"
            trigger={
              <img
                src="/assets/emails/unverified-email.svg"
                alt="Unverified Email"
                className={personDetailCss.emailStatusIcon}
              />
            }
            content="Unverified Email"
          />
        </div>
      );
    default:
      return null;
  }
};

export const otherEmailDetails = (emailDetails) => {
  const otherEmails = emailDetails.filter((d) => d.name === "email" && d.position !== 1);
  return otherEmails;
};

export const resultsExist = (results) => results.length > 0;

// eslint-disable-next-line no-unneeded-ternary
export const sendMeACopy = (interaction) => (interaction.message.send_me_a_copy === 1 ? true : false);

export const SMS_IN = "sms_in";
export const SMS_OUT = "sms_out";

export const isSms = (intType, smsInOrOut) => intType === smsInOrOut;
