import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";

import { Check, Close, VisibilityHidden, VisibilityVisible } from "../../../../../shared/v2/Icomoon";

import ActionRunAtFields from "./ActionDetails/ActionRunAtFields";

const ACTION_STATE_TO_CLASSES_MAP = {
  completed: "tw-bg-semantic-blue-50 tw-border-semantic-blue-50 tw-text-white",
  failed: "tw-bg-semantic-red-50 tw-border-semantic-red-50 tw-text-white",
};

const ACTION_STATE_TO_ICON_MAP = {
  completed: <Check size="m" />,
  failed: <Close size="s" />, // TODO: Use the proper icon as show in the Figma, figure out where it's coming from.
};

const ACTION_TYPE_TO_ICON_MAP = {
  text: <FontAwesomeIcon icon={faComment} className="tw-text-neutral-gray-50 tw-text-11d" />,
  task: <FontAwesomeIcon icon={faList} className="tw-text-neutral-gray-50 tw-text-11d" />,
  email: <FontAwesomeIcon icon={faEnvelope} className="tw-text-neutral-gray-50 tw-text-11d" />,
};

const validActionStates = ["started", "completed", "failed"];

const ActionIcon = ({ state, type, isOverdueTask }) => {
  const renderedIcon = ACTION_STATE_TO_ICON_MAP[state] || ACTION_TYPE_TO_ICON_MAP[type.toLowerCase()];
  const defaultBorderColorClasses = isOverdueTask
    ? "tw-border-brivity-coral-100 tw-bg-white"
    : "tw-border-neutral-gray-30 tw-bg-white";

  const borderColorClasses = ACTION_STATE_TO_CLASSES_MAP[state] || defaultBorderColorClasses;
  const classes = [
    "tw-flex",
    "tw-justify-center",
    "tw-items-center",
    "tw-w-24px",
    "tw-h-24px",
    "tw-rounded-full",
    "tw-border",
    "tw-border-solid",
    borderColorClasses,
  ].join(" ");

  return <div className={classes}>{renderedIcon}</div>;
};
ActionIcon.propTypes = {
  state: PropTypes.oneOf(validActionStates).isRequired,
  type: PropTypes.oneOf(["Email", "Task", "Text"]).isRequired,
  isOverdueTask: PropTypes.bool.isRequired,
};

const ActionRow = ({
  actionName,
  actionState,
  actionSubtitle,
  actionType,
  errorMessage,
  isActionVisible,
  isOverdueTask,
  isSelected,
  onClick,
}) => {
  const rootClasses = [
    isSelected ? "tw-bg-tinted-gray-50" : "",
    "tw-flex",
    "tw-justify-between",
    "tw-items-center",
    "tw-py-12px",
    "tw-border-solid",
    "tw-border-0",
    "tw-border-b",
    "tw-border-neutral-gray-10",

    "tw-bg-transparent",
    "tw-p-0",
    "tw-block",
    "tw-w-full",
  ].join(" ");

  return (
    <button type="button" onClick={onClick} className={rootClasses} data-cy="action-row">
      <span className="tw-flex tw-items-center">
        <span>
          <ActionIcon state={actionState} type={actionType} isOverdueTask={isOverdueTask} />
        </span>
        <span className="tw-px-12px tw-text-left">
          <div className="tw-text-neutral-gray-50 tw-text-12d" data-cy="action-row-type-label">
            {actionType}
          </div>
          <div data-cy="action-row-name-label">{actionName}</div>
          <div className="tw-text-12d">
            <span className="tw-text-neutral-gray-50">{actionSubtitle}</span>
            {errorMessage && <span className="tw-text-semantic-red-100 tw-pl-12px">{errorMessage}</span>}
          </div>
        </span>
      </span>
      <span className="tw-pr-24px">
        {isActionVisible ? (
          <VisibilityVisible size="xl" />
        ) : (
          <VisibilityHidden size="xl" className="tw-text-neutral-gray-50" />
        )}
      </span>
    </button>
  );
};
ActionRow.propTypes = {
  actionName: PropTypes.string.isRequired,
  actionState: PropTypes.oneOf(validActionStates).isRequired,
  actionSubtitle: PropTypes.node.isRequired,
  actionType: PropTypes.oneOf(["Email", "Task", "Text"]).isRequired,
  errorMessage: PropTypes.string,
  isActionVisible: PropTypes.bool.isRequired,
  isOverdueTask: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
ActionRow.defaultProps = {
  errorMessage: null,
};

const ActionList = ({ actions, currentlySelectedActionId, setCurrentlySelectedActionId }) => (
  <>
    {actions.map((action) => (
      <ActionRow
        key={action.id}
        actionName={action.name}
        actionSubtitle={
          <ActionRunAtFields
            actionCompletedAt={action.completedAt}
            actionRunAt={action.runAt}
            actionState={action.state}
            hideDate
          />
        }
        actionState={action.state}
        actionType={action.type}
        errorMessage={action.failedMessage}
        onClick={() => setCurrentlySelectedActionId(action.id)}
        isActionVisible={action.visibility}
        isOverdueTask={action.type === "Task" && action.overdue}
        isSelected={currentlySelectedActionId === action.id}
      />
    ))}
  </>
);
ActionList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      state: PropTypes.oneOf(validActionStates).isRequired,
      type: PropTypes.oneOf(["Email", "Task", "Text"]),
      failedMessage: PropTypes.string,
      overdue: PropTypes.bool,
    }),
  ).isRequired,
  currentlySelectedActionId: PropTypes.string.isRequired,
  setCurrentlySelectedActionId: PropTypes.func.isRequired,
};

export default ActionList;
