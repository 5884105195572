/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Create = (props) => (
  <Icon {...props} name="Create">
    <path d="m11.183 3.199 2.505 2.506a.27.27 0 0 1 0 .383l-6.067 6.067-2.578.286a.54.54 0 0 1-.597-.597l.286-2.578 6.067-6.067a.27.27 0 0 1 .383 0zm4.5-.636-1.356-1.356a1.086 1.086 0 0 0-1.533 0l-.983.983a.272.272 0 0 0 0 .383l2.506 2.506a.27.27 0 0 0 .383 0l.983-.983a1.086 1.086 0 0 0 0-1.533zm-5.016 7.941v2.828H1.778V4.443h6.383a.342.342 0 0 0 .236-.097l1.111-1.111a.333.333 0 0 0-.236-.569H1.333C.597 2.666 0 3.263 0 3.999v9.778c0 .736.597 1.333 1.333 1.333h9.778c.736 0 1.333-.597 1.333-1.333V9.394a.334.334 0 0 0-.569-.236l-1.111 1.111a.342.342 0 0 0-.097.236z" />
  </Icon>
);

export default Create;
