import {
  submitEmailError,
  clearEmailState,
  submitSendEmail,
  updateHomeAppInviteSent,
} from "../LeadIndex/actions/default";

const defaultState = {
  isEmailSent: false,
  sendEmailError: null,
};

const leadIndexReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case submitEmailError().type:
      return {
        ...state,
        sendEmailError: {
          errors: payload.errors,
          isSupportError: payload.isSupportError,
        },
      };
    case clearEmailState().type:
      return {
        ...state,
        isEmailSent: false,
        sendEmailError: null,
      };
    case submitSendEmail().type:
      return {
        ...state,
        isEmailSent: true,
      };
    case updateHomeAppInviteSent().type:
      if (!state.people) return state;
      return {
        ...state,
        people: state.people.map((p) =>
          p.id === payload.id ? { ...p, home_app_invite_sent: payload.inviteSent } : p,
        ),
      };
    default:
      return state;
  }
};

export default leadIndexReducer;
