export const formatValue = (value, asPrice = false) => {
  if (value === null || value === undefined) {
    return "N/A";
  }

  if (Array.isArray(value)) {
    return value.length ? value.join(", ") : "N/A";
  }

  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  if (asPrice) {
    return formatPrice(value);
  }

  return value;
};

export const formatNumber = (num) => (num ? num.toLocaleString("en-US") : "N/A");

export const addParametersToImageUrl = (url, height, width, quality = 80, mode = "crop") =>
  `${url}&height=${height}&width=${width}&quality=${quality}&mode=${mode}`;

export const formatPrice = (price, decimalPlaces = 0) => {
  if (!price && price !== 0) {
    return;
  }

  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };

  return new Intl.NumberFormat("en-US", options).format(price);
};

export const formatSimplePrice = (price) => {
  if (!price) {
    return;
  }

  const slicer = [
    { value: 1_000_000_000, abbr: "B", digits: 2 },
    { value: 1_000_000, abbr: "M", digits: 2 },
    { value: 1_000, abbr: "K", digits: 0 },
    { value: 1, abbr: "", digits: 0 },
  ];

  for (const { value, abbr, digits } of slicer) {
    if (price >= value) {
      const reduced = (price / value).toFixed(digits);
      return `$${reduced}${abbr}`;
    }
  }
};

export const ExistingFeatureFields = [
  "poolFeatures",
  "parkingFeatures",
  "interiorFeatures",
  "exteriorFeatures",
  "communityFeatures",
  "propertyFeatures",
  "greenFeatures",
  "view",
  "heating",
  "cooling",
  "lotFeatures",
  "homeStyle",
  "financialInformation",
  "acreageRange",
  "propertySubTypes"
];

export const calculateAgentStats = (listings, agentId) => {
  let soldProduction = 0;
  let soldUnits = 0;
  let boughtUnits = 0;
  let boughtProduction = 0;
  let closePriceTotal = 0;
  let listPriceTotal = 0;

  for (const listing of listings) {
    if (listing.standardStatus !== "Closed") { // Always be closing
      continue;
    }

    closePriceTotal += listing.soldPrice;
    listPriceTotal += getOriginalPrice(listing);

    if (isSellerAgent(listing, agentId)) {
      soldUnits++;
      soldProduction += listing.soldPrice;
    } else {
      boughtUnits++;
      boughtProduction += listing.soldPrice;
    }
  }

  const totalClosed = soldUnits + boughtUnits;

  return {
    closed: {
      total: totalClosed,
      seller: {
        units: soldUnits,
        volume: soldProduction,
        ratio: totalClosed ? soldUnits / totalClosed : 0
      },
      buyer: {
        units: boughtUnits,
        volume: boughtProduction,
        ratio: totalClosed ? boughtUnits / totalClosed : 0
      }
    },
    stats: {
      avgClosePrice: totalClosed ? closePriceTotal / totalClosed : 0,
      avgListPrice: totalClosed ? listPriceTotal / totalClosed : 0,
      grossProduction: closePriceTotal,
      totalListings: listings.length
    }
  };
};

const getOriginalPrice = listing => {
  if (!listing) {
    return null;
  }

  if (listing.history?.length > 0) {
    for (let hist of listing.history) {
      if (hist.price) {
        return hist.price;
      }
    }
  }

  return listing.price;
};

const isSellerAgent = (listing, agentId) => {
  for (const { agentType, id } of listing.agents) {
    if (ListAgentTypes.includes(agentType) && id === agentId) {
      return true;
    }
  }

  return false;
};

const ListAgentTypes = ["Listing Agent", "Co-Listing Agent"];
