import React from "react";
import { number, string } from "prop-types";

import PriorityIndicator from "./PriorityIndicator";

const ActionHeader = ({ type, name, priority }) => (
  <div className="tw-flex tw-gap-[4px] tw-text-gray-75">
    <span>{type}:</span>
    <span className="tw-text-ellipsis tw-h-[20px] tw-overflow-hidden tw-max-w-[665px] tw-whitespace-nowrap">{name}</span>
    <PriorityIndicator
      priority={priority}
      className="tw-ml-[4px]"/>
  </div>
);

export default ActionHeader;

ActionHeader.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  priority: number
}

ActionHeader.defaultProps = {
  priority: 9
}