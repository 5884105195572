const formatedPrice = (value) => {
  if (value) {
    return `$${value.toLocaleString()}`
  }
}

const formattedPriceRange = (min, max) => {
  if (min == null && max == null) {
    return 'Any'
  } else if (max == null) {
    return `${formatedPrice(min)} - Any`
  } else if (min == null) {
    return `Any - ${formatedPrice(max)}`
  }

  return `${formatedPrice(min)} - ${formatedPrice(max)}`
}

const formattedRange = (min, max) => {
  if (min == null && max == null) {
    return 'Any - Any'
  } else if (max == null) {
    return `${min} - Any`
  } else if (min == null) {
    return `Any - ${max}`
  }

  return `${min} - ${max}`
}

const lotSizeToString = (value) => {
  if (!value) {
    return null;
  }

  const twoPlaces = [0.25, 0.33, 0.75]
  if (twoPlaces.includes(value)) {
    return value.toFixed(2)
  }
  return value.toFixed(1)
}

const yearOptions = (firstOption) => {
  let years = [1800]
  for (let year = 1900; year <= 2000; year+=10) {
    years.push(year)
  }
  const currentYear = new Date().getFullYear()
  for (let year = 2005; year <= currentYear; year++) {
    years.push(year)
  }

  const allYears = years.map(year => ({ label: year, value: year }))

  return [{ label: firstOption, value: '' }].concat(allYears)
};

const mapStateToAlert = (state) => {
  const alert = {
    alertId: state.search_id,
    searchName: state.search_name,
    priceMin: state.price_min,
    priceMax: state.price_max,
    bedsMin: state.numbeds_min,
    bedsMax: state.numbeds_max,
    bathsMin: state.numbaths_min,
    bathsMax: state.numbaths_max,
    sqFtMin: state.sqfeet_min,
    sqFtMax: state.sqfeet_max,
    lotSizeMin: state.lotval_min,
    lotSizeMax: state.lotval_max,
    yearMin: state.year_min,
    yearMax: state.year_max,
    garageMin: state.garage_min,
    garageMax: state.garage_max,
    requirePhotos: !state.photos_available,
    activeForEmails: state.activeForEmails,
    activeForAppNotifications: state.activeForAppNotifications,
    frequency: state.frequency,
    storiesMin: state.stories,
    mlsIds: state.mlsIds,
    propertyTypes: state.property_types,
    polygon: convertPolygonForAlert(state.polygon),
    propertySubTypes: state.propertySubTypes,
    poolFeatures: state.poolFeatures,
    parkingFeatures: state.parkingFeatures,
    propertyFeatures: state.propertyFeatures,
    interiorFeatures: state.interiorFeatures,
    exteriorFeatures: state.exteriorFeatures,
    communityFeatures: state.communityFeatures,
    greenFeatures: state.greenFeatures,
    view: state.view,
    heating: state.heating,
    cooling: state.cooling,
    lotFeatures: state.lotFeatures,
    homeStyle: state.homeStyle,
    financialInformation: state.financialInformation,
    legacyFields: state.legacyFields,
    ccEmails: state.cc_emails
  };

  return {
    templateId: state.templateId,
    templateName: state.templateName,
    listing_alert: { ...alert, ...formatLocationsForAlert(state) },
    search_type: state.searchingByTerm === true ? 'terms' : 'map'
  };
};

const mapAlertToState = (existingAlert = {}, doesLeadHaveApp) => {
  const DefaultFrequency = "daily";
  const DefaultSource = "brivity_crm";
  const { locationCategories, locationValues } = formatLocationsForMultiSearch(existingAlert);

  return {
    // Not a field on an alert, but directly related to data on it
    searchingByTerm: !existingAlert.polygon,
    activeForEmails: existingAlert.activeForEmails === undefined ? true : existingAlert.activeForEmails,
    activeForAppNotifications: (!doesLeadHaveApp|| existingAlert.activeForAppNotifications === undefined) ? false : existingAlert.activeForAppNotifications,
    cc_emails: existingAlert.ccEmails || [],
    frequency: existingAlert.frequency || DefaultFrequency,
    garage_max: existingAlert.garageMax,
    garage_min: existingAlert.garageMin,
    lotval_max: lotSizeToString(existingAlert.lotSizeMax),
    lotval_min: lotSizeToString(existingAlert.lotSizeMin),
    mlsIds: existingAlert.mlsIds || [],
    // These multi_ are related to citys, counties etc. multi_search and multi_search_array have the same info, same with multi_cat
    multi_search_array: locationValues,
    multi_cat_array: locationCategories,
    numbaths_max: existingAlert.bathsMax,
    numbaths_min: existingAlert.bathsMin,
    numbeds_max: existingAlert.bedsMax,
    numbeds_min: existingAlert.bedsMin,
    // photos_available being true on the UI means include listings without photos (INCLUDE PROPERTIES WITHOUT PHOTOS). requirePhotos being true means only listings with photos
    photos_available: existingAlert.requirePhotos === undefined ? false : !existingAlert.requirePhotos,
    polygon: convertPolygonForMap(existingAlert.polygon),
    price_max: existingAlert.priceMax || "",
    price_min: existingAlert.priceMin || "",
    property_types: existingAlert.propertyTypes || [],
    saved_date: existingAlert.createdAt,
    searchingByTerm: !existingAlert.polygon,
    search_id: existingAlert.alertId,
    search_url: existingAlert.searchUrl,
    search_name: existingAlert.searchName || "",
    source: existingAlert.source || DefaultSource,
    sqfeet_max: existingAlert.sqFtMax,
    sqfeet_min: existingAlert.sqFtMin,
    stories: existingAlert.storiesMin,
    year_max: existingAlert.yearMax,
    year_min: existingAlert.yearMin,
    propertySubTypes: existingAlert.propertySubTypes,
    poolFeatures: existingAlert.poolFeatures,
    parkingFeatures: existingAlert.parkingFeatures,
    propertyFeatures: existingAlert.propertyFeatures,
    interiorFeatures: existingAlert.interiorFeatures,
    exteriorFeatures: existingAlert.exteriorFeatures,
    communityFeatures: existingAlert.communityFeatures,
    greenFeatures: existingAlert.greenFeatures,
    view: existingAlert.view,
    heating: existingAlert.heating,
    cooling: existingAlert.cooling,
    lotFeatures: existingAlert.lotFeatures,
    homeStyle: existingAlert.homeStyle,
    financialInformation: existingAlert.financialInformation,
    legacyFields: existingAlert.legacyFields
  };
};

const radioButtonClass = (isSelected, isDisabled = false) => {
  let color = "tw-text-grey";
  if (isDisabled) {
    color = "tw-text-gray-15";
  } else if (isSelected) {
    color = "tw-text-teal";
  }

  return isSelected ? `fa fa-dot-circle-o ${color}` : `fa fa-circle-o ${color}`;
};

const checkBoxClass = (isSelected) => isSelected ? "fa-check-square text-info" : "fa-square-o"

const dropDownOptions = (firstOption) => {
  return [
    { label: firstOption, value: ''}, {label: '1', value: '1'}, {label: '2', value: '2' }, {label: '3', value: '3' }, {label: '4', value: '4' }, {label: '5', value: '5' }, {label: '6', value: '6' }, {label: '7', value: '7' }, {label: '8', value: '8' }, {label: '9', value: '9' }, {label: '10', value: '10' }
  ]
};

const bathroomOptions = (firstOption) => {
  return [
    { label: firstOption, value: ''}, {label: '1', value: '1'}, {label: '1.5', value: '1.5'}, {label: '1.75', value: '1.75'}, {label: '2', value: '2' }, {label: '3', value: '3' }, {label: '4', value: '4' }, {label: '5', value: '5' }, {label: '6', value: '6' }, {label: '7', value: '7' }, {label: '8', value: '8' }, {label: '9', value: '9' }, {label: '10', value: '10' }
  ]
};

const searchFrequencyOptions = (showInstant = false) => {
  const options = [
    { label: "Daily", value: "daily" },
    { label: "Twice Daily", value: "twice_daily" },
    { label: "Multiple Times Per Day", value: "four_times_daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Every 2 Weeks", value: "bi_weekly" },
    { label: "Monthly", value: "monthly" }
  ];

  if (showInstant) {
    options.splice(3, 0, { label: "Immediate", value: "immediate" });
  }

  return options;
};

const lotSizeOptions = (firstOption) => {
  return [
    { label: firstOption, value: ''}, { label: "0.1", value: "0.1" }, { label: "0.2", value: "0.2" }, { label: "0.25", value: "0.25" }, { label: "0.33", value: "0.33" }, { label: "0.5", value: "0.5" }, { label: "0.75", value: "0.75" }, { label: "1", value: "1.0" }, { label: "1.5", value: "1.5" }, { label: "2", value: "2.0" }, { label: "5", value: "5.0" }, { label: "10", value: "10.0" }, { label: "20", value: "20.0" }, { label: "40", value: "40.0" }, { label: "80", value: "80.0" }
  ]
}

const storiesOptions = [
  { label: "Any", value: "" }, { label: "1 Story", value: 1 }, { label: "2 Story", value: 2 }, { label: "3+ Story", value: 3 }
]

const sqFTOptions = (firstOption) => {
  let options = [
    "600",
    "800",
    "1000",
    "1200",
    "1400",
    "1600",
    "1800",
    "2000",
    "2250",
    "2500",
    "2750",
    "3000",
    "3500",
    "4000",
    "4500",
    "5000",
    "6000",
    "7000",
    "8000",
    "9000",
    "10000"
  ].map(val => ({ label: val, value: val }));

  options.unshift({ label: firstOption, value: ''});

  return options;
};

const formatLocationsForMultiSearch = alert => {
  const locationCategories = [];
  const locationValues = [];
  if (alert.neighborhoodCityStates) {
    alert.neighborhoodCityStates.forEach(n => {
      locationCategories.push("NeighborhoodCityState");
      locationValues.push(`${n.neighborhood}, ${n.city}, ${n.state}`);
    });
  }

  if (alert.cityStates) {
    alert.cityStates.forEach(n => {
      locationCategories.push("CityState");
      locationValues.push(`${n.city}, ${n.state}`);
    });
  }

  if (alert.countyStates) {
    alert.countyStates.forEach(n => {
      locationCategories.push("CountyState");
      locationValues.push(`${n.county}, ${n.state}`);
    });
  }

  if (alert.postalCodes) {
    alert.postalCodes.forEach(n => {
      locationCategories.push("Zip Code");
      locationValues.push(n);
    });
  }

  if (alert.elementarySchools) {
    alert.elementarySchools.forEach(n => {
      locationCategories.push("Elementary School");
      locationValues.push(n);
    });
  }

  if (alert.middleSchools) {
    alert.middleSchools.forEach(n => {
      locationCategories.push("Middle School");
      locationValues.push(n);
    });
  }

  if (alert.highSchools) {
    alert.highSchools.forEach(n => {
      locationCategories.push("High School");
      locationValues.push(n);
    });
  }

  if (alert.schoolDistricts) {
    alert.schoolDistricts.forEach(n => {
      locationCategories.push("School District");
      locationValues.push(n);
    });
  }

  if (alert.mlsAreas) {
    alert.mlsAreas.forEach(n => {
      locationCategories.push("Area");
      locationValues.push(n);
    });
  }

  if (alert.buildingNames) {
    alert.buildingNames.forEach(n => {
      locationCategories.push("Building Name");
      locationValues.push(n);
    });
  }

  if (alert.mlsNums) {
    alert.mlsNums.forEach(n => {
      locationCategories.push("MLS");
      locationValues.push(n);
    });
  }

  if (alert.streetAddress) {
    locationCategories.push("Address");
    locationValues.push(alert.streetAddress);
  }

  return { locationCategories, locationValues };
};

const convertPolygonForMap = polygon => {
  if (!polygon) {
    return;
  }

  return polygon.map(({lat, lng}) => `(${lat},${lng})`).join(',');
};

const formatLocationsForDisplay = alert => formatLocationsForMultiSearch(alert).locationValues.join("|");

export {
  checkBoxClass,
  dropDownOptions,
  bathroomOptions,
  formatedPrice,
  formattedPriceRange,
  formattedRange,
  lotSizeOptions,
  lotSizeToString,
  mapStateToAlert,
  mapAlertToState,
  radioButtonClass,
  searchFrequencyOptions,
  sqFTOptions,
  storiesOptions,
  yearOptions,
  formatLocationsForMultiSearch,
  convertPolygonForMap,
  formatLocationsForDisplay
};

const formatLocationsForAlert = ({multi_search_array, multi_cat_array}) => {
  if (!multi_cat_array || !multi_cat_array.length) {
    return {};
  }

  const locations = {};
  multi_cat_array.forEach((m, i) => {
    if (!locations[categoriesMapped[m]]) {
      locations[categoriesMapped[m]] = [];
    }

    if (m === 'NeighborhoodCityState') {
      const [neighborhood, city, state] = multi_search_array[i].split(',').map(s => s.trim());
      locations[categoriesMapped[m]].push({
        neighborhood,
        city,
        state
      });
    } else if (m === 'CityState') {
      const [city, state] = multi_search_array[i].split(',').map(s => s.trim());
      locations[categoriesMapped[m]].push({
        city,
        state
      });
    } else if (m === 'CountyState') {
      const [county, state] = multi_search_array[i].split(',').map(s => s.trim());
      locations[categoriesMapped[m]].push({
        county,
        state
      });
    } else {
      locations[categoriesMapped[m]].push(multi_search_array[i]);
    }
  });

  if (locations['streetAddress'] && locations['streetAddress'].length) {
    locations['streetAddress'] = locations['streetAddress'][0];
  }

  return locations;
};

const categoriesMapped = {
  'NeighborhoodCityState': 'neighborhoodCityStates',
  'CityState': 'cityStates',
  'CountyState': 'countyStates',
  'Zip Code': 'postalCodes',
  'Elementary School': 'elementarySchools',
  'Middle School': 'middleSchools',
  'High School': 'highSchools',
  'School District': 'schoolDistricts',
  'Area': 'mlsAreas',
  'Address': 'streetAddress',
  'Building Name': 'buildingNames',
  'MLS': 'mlsNums'
};

// Of the form (1,2),(3,4),(5,6)
const convertPolygonForAlert = polygon => {
  if (!polygon) {
    return;
  }

  return polygon.split("),(")
    .map(formatPoint);
};

const formatPoint = p => {
  const [lat, lng] = p.replace(/[^0-9\.,-]/g, '')
    .split(',');

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  };
};
