import React from "react";

import TransactionSummary from "./TransactionSummary";
import TransactionDetails from "./TransactionDetails";
import ModalRename from "./ModalRename/ModalRename";

const ReferralHeader = () => (
  <>
    <ModalRename />
    <div className="tw-flex tw-justify-between tw-gap-[10px] tw-mx-[32px] tw-h-[174px] tw-min-w-[1000px] tw-py-[32px]">
      <TransactionSummary />
      <TransactionDetails />
    </div>
  </>
);

export default ReferralHeader;
