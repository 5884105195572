import React from "react";
import PropTypes from "prop-types";

const PanelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="tw-w-[24px]">
    <path d="m21,0H3C1.34,0,0,1.34,0,3v18C0,22.66,1.34,24,3,24h18c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3ZM1.9,21V3c0-.61.49-1.1,1.1-1.1h4.07v20.2H3c-.61,0-1.1-.49-1.1-1.1Zm20.2,0c0,.61-.49,1.1-1.1,1.1h-12.03V1.9h12.03c.61,0,1.1.49,1.1,1.1v18Z" />
  </svg>
);

export const PanelIconCaret = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="currentColor"
    className={`tw-transition-all tw-duration-300 tw-w-[12px] tw-absolute tw-right-[6px] ${className}`}
  >
    <path d="m3.91,11.18c-.26,0-.52-.11-.71-.32-.35-.39-.31-.99.08-1.34l3.79-3.38c.06-.05.07-.11.07-.15s-.01-.1-.07-.15l-3.79-3.36c-.39-.35-.43-.95-.08-1.34s.95-.43,1.34-.08l3.79,3.36c.45.4.71.97.71,1.57,0,.6-.26,1.17-.7,1.57l-3.79,3.38c-.18.16-.41.24-.63.24Z" />
  </svg>
);

PanelIconCaret.propTypes = { className: PropTypes.string };
PanelIconCaret.defaultProps = { className: "" };

export default PanelIcon;
