import React from "react";
import { arrayOf, shape, number, string } from "prop-types";

import { connect } from "react-redux";

import AvatarGroup from "../../../../shared/v2/AvatarGroup/AvatarGroup";
import Avatar from "../../../../shared/v2/Avatar";

const LinkPeople = ({ avatars }) => (
  <div className="tw-flex tw-items-center">
    <div className="tw-mr-12px tw-text-14px tw-text-neutral-gray-75 tw-font-semibold">People Listed:</div>
    <AvatarGroup>
      {avatars.map(({ alt, id, avatar }) => (
        <Avatar alt={alt} src={avatar} key={id} />
      ))}
    </AvatarGroup>
  </div>
);

const mapStateToProps = (state) => ({
  avatars: state.tdpDetailsReducer.peopleListed,
});

LinkPeople.propTypes = {
  avatars: arrayOf(
    shape({
      alt: string,
      id: number,
      avatar: string,
    }),
  ),
};

LinkPeople.defaultProps = {
  avatars: [],
};

export default connect(mapStateToProps)(LinkPeople);
