/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneMissedSolidV6 = (props) => (
  <Icon {...props} name="Phone Missed Solid V6">
    <path d="M15.777 11.01C11.487 6.72 4.51 6.72.22 11.01a.747.747 0 0 0-.106.929l1.318 2.108a.757.757 0 0 0 .918.301l2.638-1.054c.31-.125.5-.44.467-.772l-.166-1.664a8.344 8.344 0 0 1 5.402 0l-.166 1.663a.748.748 0 0 0 .468.773l2.635 1.053c.339.134.703.006.916-.3l1.318-2.108a.714.714 0 0 0-.084-.93zM2.813 5.527a.668.668 0 0 0 .727-.145l.766-.745L5.649 5.98c.525.525 1.197.907 1.935 1a3.196 3.196 0 0 0 2.68-.912l3.22-3.22a.4.4 0 0 0 0-.566l-.565-.565a.4.4 0 0 0-.566 0l-3.22 3.22a1.604 1.604 0 0 1-2.262 0L5.439 3.505l.768-.767A.667.667 0 0 0 5.735 1.6H2.88a.477.477 0 0 0-.478.477V4.91c0 .293.163.535.413.617z" />
  </Icon>
);

export default PhoneMissedSolidV6;
