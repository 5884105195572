import React, { useState } from "react";
import { string, func, shape } from "prop-types";
import { connect } from "react-redux";

import * as actions from "../actions/creators";

import { inputUploadFn } from "./helpers";
import { uploadFiles } from "../../../../shared/AWSUploader";
import Upload from "../../../../shared/v2/Icomoon/Icons/Upload";

const mapStateToProps = (state) => ({
  awsDirectPost: state.tdpDetailsReducer.awsDirectPost,
  slug: state.tdpDetailsReducer.slug,
});

const UploadImage = ({ awsDirectPost, slug, addImg, updateImg, deleteImg, addError, size, onAdd }) => {
  const [progress, setProgress] = useState(0);
  const inputUpload = inputUploadFn({
    addError,
    addImg,
    awsDirectPost,
    deleteImg,
    setProgress,
    slug,
    updateImg,
    uploadFiles,
    onAdd,
  });

  return (
    <button
      type="button"
      className={`tw-relative tw-border-0 tw-overflow-hidden tw-bg-neutral-gray-10 ${
        size === "large" ? "tw-w-[190px] tw-h-[190px]" : "tw-w-[176px] tw-h-[176px]"
      } tw-flex tw-flex-col tw-justify-center tw-items-center`}
      data-cy="image-uplaoder"
    >
      <Upload size="xl" className="tw-mb-8px" />
      <div className="tw-font-bs-sans tw-text-12px tw-font-semibold">
        UPLOAD PHOTOS <br />
        ONLY FILES LESS THAN 5MB
      </div>
      <input
        className="tw-absolute tw-top-[-100%] tw-left-[-100%] tw-h-[200%] tw-w-[200%] tw-opacity-0"
        type="file"
        name="files[]"
        multiple
        onInput={inputUpload}
        data-cy="image-uploader-input"
        accept="image/*"
      />
      {progress < 100 && (
        <div
          data-cy="image-uploader-progress"
          style={{ width: `${progress}%` }}
          className="tw-transition-all tw-absolute tw-bottom-0 tw-left-0 tw-z-5 tw-h-[4px] tw-w-full tw-bg-semantic-green-110"
        />
      )}
    </button>
  );
};
UploadImage.propTypes = {
  awsDirectPost: shape,
  slug: string,
  addImg: func,
  updateImg: func,
  deleteImg: func,
  addError: func,
  size: string,
  onAdd: func,
};

UploadImage.defaultProps = {
  awsDirectPost: null,
  slug: null,
  addImg: null,
  updateImg: null,
  deleteImg: null,
  addError: null,
  size: "large",
  onAdd: null,
};
export default connect(mapStateToProps, actions)(UploadImage);
