import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

const PhoneNumberModal = ({ showContext, onClose }) => {
  const { changedNumbers, conversationCount } = showContext || {};
  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[640px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      show={Boolean(showContext)}
      onHide={onClose}
      closeOnEscape={false}
    >
      <Modal.Header title="Are you sure?" closeButton />
      {changedNumbers?.length === 1 && (
        <Modal.Body>
          <strong>
            Are you sure you want to {changedNumbers[0].currentNumber ? "change" : "delete"} the
            contact&apos;s phone number
            {` ${changedNumbers[0].currentNumber ? "from" : ""} ${changedNumbers[0].previousNumber}`}
            {changedNumbers[0].currentNumber ? ` to ${changedNumbers[0].currentNumber}` : ""}?
          </strong>{" "}
          This will archive {conversationCount} existing conversation{conversationCount > 1 ? "s" : ""} with
          this phone number, and you will lose all conversation history. Existing timeline events will remain.
        </Modal.Body>
      )}
      {changedNumbers?.length > 1 && (
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[16px]">
          <div>
            The following actions will archive {showContext.conversationCount} existing conversation
            {showContext.conversationCount > 1 && "s"} with their respective phone numbers. You will lose all
            conversation history. Existing timeline events will remain.
          </div>
          <ul>
            {changedNumbers.map((changedNumber, i) => {
              if (changedNumber.currentNumber) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={i}>
                    Phone number changed from {changedNumber.previousNumber} to {changedNumber.currentNumber}
                  </li>
                );
              }
              // eslint-disable-next-line react/no-array-index-key
              return <li key={i}>{changedNumber.previousNumber} has been removed from this contact</li>;
            })}
          </ul>
        </Modal.Body>
      )}
      <Modal.Footer className="tw-flex tw-justify-between">
        <Button schema="tertiary" size="medium" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button schema="warning" size="medium" onClick={() => onClose(true)}>
          {changedNumbers?.[0]?.currentNumber ? "Change" : "Delete"} Phone Number
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PhoneNumberModal.propTypes = {
  showContext: PropTypes.shape({
    changedNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        previousNumber: PropTypes.string,
        currentNumber: PropTypes.string,
      }),
    ),
    conversationCount: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
};

PhoneNumberModal.defaultProps = {
  showContext: null,
};

export default PhoneNumberModal;
