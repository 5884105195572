import React from "react";
import { Col, Row, FormGroup, TextArea, Clearfix, Button } from "../../../shared/v1";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import AddExpenseModal from "./AddExpenseModal";
import DragHandle from "../shared/DragHandle";
import TransactionInput from "../shared/TransactionInput";
import { companyOptions } from "./Company/helpers";

const SortableItem = sortableElement(({ value, sortIndex, accountData, setAccountData }) => {
  const { name, transaction_expense_type_id: t_id } = value;

  const { transaction_expense_types } = accountData;

  return (
    <div className="transaction-gci-content content-additional">
      <Row className="tw-border-0 tw-border-t tw-border-solid tw-border-gray tw-pt-[10px]">
        <DragHandle />

        <Col size={2}>
          <TransactionInput
            label={name}
            value={value.value}
            isPercentage={!!value.percentage}
            name={`offTax${sortIndex}`}
            onValueUpdate={(e) => {
              if (!!value.percentage) {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  value.value = e.target.value;
                  setAccountData(accountData);
                }
              } else {
                value.value = e.target.value;
                setAccountData(accountData);
              }
            }}
            onRadioUpdate={(e) => {
              if (e.target.value === "true" && value.value > 100) {
                value.value = "";
              }
              value.percentage = e.target.value === "true";
              setAccountData(accountData);
            }}
          />
        </Col>

        <Col size={4}>
          <FormGroup className="tw-min-h-[50px]">
            <label className="tw-block tw-mb-[5px]">Note</label>

            <TextArea
              size="auto"
              className="description"
              name={`offnote${sortIndex}`}
              placeholder=""
              value={value.notes || ""}
              onChange={(e) => {
                value.notes = e.target.value;
                setAccountData(accountData);
              }}
            />
          </FormGroup>
        </Col>

        <div className="delete-expense">
          <a
            onClick={() => {
              accountData.account_financial_template.custom_account_expenses_attributes[sortIndex]._destroy =
                "1";
              let position = 0;
              accountData.account_financial_template.custom_account_expenses_attributes.map((obj) => {
                if (obj._destroy) {
                  if (obj.position) {
                    delete obj["position"];
                  }
                } else {
                  obj.position = position + 1;
                  position = position + 1;
                }
              });
              setAccountData(accountData);
            }}
          >
            <i className="fa fa-times" />
          </a>
        </div>
      </Row>
    </div>
  );
});

const SortableContainer = sortableContainer(({ items, accountData, setAccountData }) => {
  return (
    <Row>
      {items.map(
        (value, index) =>
          !value._destroy && (
            <SortableItem
              key={`office-${index}`}
              index={index}
              value={value}
              sortIndex={index}
              accountData={accountData}
              setAccountData={setAccountData}
            />
          ),
      )}
    </Row>
  );
});

class TransactionGCIForm extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  onAddExpense = () => {
    this.props.openNewExpenseModal();
    this.props.unsetAgentExpense();
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.accountData.account_financial_template.custom_account_expenses_attributes = arrayMove(
      this.props.accountData.account_financial_template.custom_account_expenses_attributes,
      oldIndex,
      newIndex,
    );
    let position = 0;
    this.props.accountData.account_financial_template.custom_account_expenses_attributes.map((obj) => {
      if (obj._destroy) {
        if (obj.position) {
          delete obj["position"];
        }
      } else {
        obj.position = position + 1;
        position = position + 1;
      }
    });
    this.props.setAccountData(this.props.accountData);
  };

  render() {
    const { isModalOpen, accountData, setAccountData } = this.props;

    return (
      <Clearfix className="transaction-gci-wrap">
        <div className="transaction-gci-options">
          <div className="transaction-gci-header">
            <h3>Office Commission</h3>
          </div>

          <div className="transaction-gci-content">
            <Row>
              <Col size={2}>
                <TransactionInput
                  label="Listing(LOC)"
                  value={accountData.account_financial_template.listing_commission}
                  isPercentage={!!accountData.account_financial_template.listing_commission_percentage}
                  name="loc"
                  onValueUpdate={(e) => {
                    if (!!accountData.account_financial_template.listing_commission_percentage) {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        accountData.account_financial_template.listing_commission = e.target.value;
                        setAccountData(accountData);
                      }
                    } else {
                      accountData.account_financial_template.listing_commission = e.target.value;
                      setAccountData(accountData);
                    }
                  }}
                  onRadioUpdate={(e) => {
                    if (
                      e.target.value === "true" &&
                      accountData.account_financial_template.listing_commission > 100
                    ) {
                      accountData.account_financial_template.listing_commission = "";
                    }
                    accountData.account_financial_template.listing_commission_percentage =
                      e.target.value === "true";
                    setAccountData(accountData);
                  }}
                />
              </Col>

              <Col size={2}>
                <TransactionInput
                  label="Selling(SOC)"
                  value={accountData.account_financial_template.selling_commission}
                  isPercentage={!!accountData.account_financial_template.selling_commission_percentage}
                  name="soc"
                  onValueUpdate={(e) => {
                    if (!!accountData.account_financial_template.selling_commission_percentage) {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        accountData.account_financial_template.selling_commission = e.target.value;
                        setAccountData(accountData);
                      }
                    } else {
                      accountData.account_financial_template.selling_commission = e.target.value;
                      setAccountData(accountData);
                    }
                  }}
                  onRadioUpdate={(e) => {
                    if (
                      e.target.value === "true" &&
                      accountData.account_financial_template.selling_commission > 100
                    ) {
                      accountData.account_financial_template.selling_commission = "";
                    }
                    accountData.account_financial_template.selling_commission_percentage =
                      e.target.value === "true";
                    setAccountData(accountData);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="transaction-gci-options">
          <div className="transaction-gci-header dnd">
            <h3>Transaction Expenses</h3>
            <Button
              size="small"
              variant="info"
              invert
              className="modal-submit tw-float-right focus:tw-bg-white active:tw-bg-white focus:tw-text-gray-darker active:tw-text-gray-darker hover:tw-text-gray-darker"
              onClick={this.onAddExpense}
            >
              <i className="fa fa-plus-square" /> Add Expense
            </Button>
          </div>

          <SortableContainer
            items={accountData.account_financial_template.custom_account_expenses_attributes}
            onSortEnd={this.onSortEnd}
            accountData={accountData}
            setAccountData={setAccountData}
            useDragHandle
          />
        </div>

        {isModalOpen && this.renderModal()}
      </Clearfix>
    );
  }

  renderModal() {
    const {
      isModalOpen,
      closeNewExpenseModal,
      accountData,
      setAccountData,
      expenseType,
      activeAgentExpense,
    } = this.props;

    const {
      agent_expense_types: agentExpenseList,
      transaction_expense_types: transactionExpenseList,
      company_expense_types: companyExpenseList,
      company_revenue_types: companyRevenueList,
    } = accountData;
    return (
      <AddExpenseModal
        isModalOpen={isModalOpen}
        closeNewExpenseModal={closeNewExpenseModal}
        accountData={accountData}
        setAccountData={setAccountData}
        expenseType={expenseType}
        transactionExpenseList={transactionExpenseList}
        agentExpenseList={agentExpenseList}
        companyList={companyOptions(companyExpenseList, companyRevenueList)}
        activeAgentExpense={activeAgentExpense}
      />
    );
  }
}

export default TransactionGCIForm;
