import React from "react";
import { Modal } from "../../shared/v1";
import Button from "../../shared/v2/Button";
import Checkbox from "../../shared/v2/Checkbox/Checkbox";

const TaskFormFooter = ({
  closeModal,
  task,
  handleCompleteTask,
  personTaskForm,
  handleSubmit,
  submitButton,
  saveButtonText,
  disableCompletedCheckbox,
  hideCancel,
}) => (
  <Modal.Footer className="tw-px-0">
    <div className="tw-flex tw-justify-between tw-items-center">
      {hideCancel ? (
        <span />
      ) : (
        <Button data-dismiss="modal" onClick={closeModal} size="medium" schema="tertiary">
          Cancel
        </Button>
      )}

      <div className="tw-flex tw-gap-[8px]">
        <Checkbox
          checked={task.completed === "true"}
          label="Mark As Complete"
          onClick={() => handleCompleteTask()}
          disabled={disableCompletedCheckbox || task.state === "completed"}
        />
        {/* btn will be disabled when its creating or scheduling appointment from PDP/ contact index page ,
            task name, priority, task type, appointment type and dueon date is required feild for appointment*/}
        <Button
          isLoading={saveButtonText === "Saving..."}
          disabled={
            !task.name.length ||
            (task.type === "Appointment" &&
              (!task.type?.length ||
                !task.appointment_type?.length ||
                !task.due_on?.length ||
                (task.completed === "true" && !task.outcome?.length)))
          }
          size="medium"
          onClick={handleSubmit}
        >
          {personTaskForm ? submitButton() : saveButtonText}
        </Button>
      </div>
    </div>
  </Modal.Footer>
);

export default TaskFormFooter;
