import React from "react";
import Skeleton from "./Skeleton";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";

const SMS_DICITONARY = {
  valid: "Can be Texted",
  untextable: "Cannot be Texted",
  "opted-out": "Opted Out",
};

const CALL_DICTIONARY = {
  valid: "Can be Called",
  "opted-out": "Opted Out",
};

const TimelineSoftStatusChangeEvent = ({ event }) => {
  const timelineFields = event.timeline_fields;
  const { lead_phone_number, author_name, change } = timelineFields;
  const phone = viewPhoneFromFlat({ value: lead_phone_number });
  const authorName = author_name;
  const isSms = change.sms_status ? true : false;
  const changeSet = change.sms_status || change.call_status;
  const dictionary = isSms ? SMS_DICITONARY : CALL_DICTIONARY;
  const originalState = dictionary[changeSet[0]];
  const finalState = dictionary[changeSet[1]];
  const typeChange = isSms ? "Text" : "Call";

  return (
    <Skeleton
      iconClassName="fa6 fa6-user"
      title={
        <>
          <span className="tw-font-bold">{authorName}</span> changed {typeChange} Status
        </>
      }
      timestamp={event.interacted_at_absolute}
      body={
        <p>
          {phone.formattedNumber} changed from <span className="tw-font-bold">{originalState}</span> to{" "}
          <span className="tw-font-bold">{finalState}</span>
        </p>
      }
    />
  );
};

export default TimelineSoftStatusChangeEvent;
