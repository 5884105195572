export const FLYOUT_ANIMATION_DURATION_MS = 400;

export const containerAnimationStyles = (duration) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

export const modalAnimationStyles = (duration) => ({
  transition: `transform ${duration}ms ease-out`,
  transform: "translateX(calc(100vw + 100%))",
});

export const containerTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const modalTransitionStyles = {
  entering: { transform: "translateX(calc(100vw + 100%))" },
  entered: { transform: "translateX(calc(100vw - 100%))" },
  exiting: { transform: "translateX(calc(100vw + 100%))" },
  exited: { transform: "translateX(calc(100vw + 100%))" },
};
