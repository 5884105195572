/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import axios from "axios";
import formatter from "../../../../shared/formatters";
import { getBusinessDashboard } from "./api";

const transformSummaryTotals = (
  {
    avgMtdSalesPrice = 0,
    avgYtdCommission = 0,
    avgYtdSalesPrice = 0,
    estTotalGci = 0,
    ytdCompanyDollar = 0,
    ytdPendingToClosedRatio = 0,
  },
  year,
) => ({
  summary: {
    avgMtdSalesPrice: year !== String(new Date().getFullYear()) ? "-" : formatter.currency(avgMtdSalesPrice),
    avgYtdCommission: formatter.currency(avgYtdCommission),
    avgYtdSalesPrice: formatter.currency(avgYtdSalesPrice),
    estTotalGci: formatter.currency(estTotalGci),
    ytdCompanyDollar: formatter.currency(ytdCompanyDollar),
    ytdPendingToClosedRatio,
  },
  year,
});

const useTransactionSummary = (year) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState(transformSummaryTotals({}));
  const [error, setError] = React.useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getBusinessDashboard({
      signal: abortController.signal,
      params: {
        section: "ytd_summary",
        selectedYear: year,
      },
    })
      .then((res) => {
        setData(transformSummaryTotals(res.data, year));
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [year]);

  return { data, error, loading };
};

export default useTransactionSummary;
