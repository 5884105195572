import React from "react";
import Tooltip from "@shared/v2/Tooltip";
import Checkbox from "@shared/v2/Checkbox";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";

const ApptTypeFilter = ({ apptFilters, handleAppointmentType }) => {
  const filters = [
    { value: "Buyer", label: "Buyer", isChecked: apptFilters.isBuyer },
    { value: "Seller", label: "Seller", isChecked: apptFilters.isSeller },
    { value: "Recruit", label: "Recruit", isChecked: apptFilters.isRecruit },
    { value: "Buyer/Seller", label: "Buyer/Seller", isChecked: apptFilters.isBuyerSeller },
    { value: "Other", label: "Other", isChecked: apptFilters.isOther },
  ];
  return (
    <div className="tw-flex tw-gap-[16px] tw-items-center">
      <label className="tw-mb-0">Appt Type:</label>
      {filters.map((filter, index) => (
        <div key={index}>
          <Checkbox
            checked={filter.isChecked}
            label={filter.label}
            onClick={(e) => handleAppointmentType(filter.value, e.target.checked)}
          />
          {index === filters.length - 1 && (
            <Tooltip
              className="tw-ml-[4px]"
              innerClassName="tw-max-w-[200px]"
              placement="top"
              content={'"Other" includes General, Client Meeting, Follow Up, and Showing Appointments'}
              trigger={<CircleInfoSolidV6 size="l" className="tw-text-theme-primary" />}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ApptTypeFilter;
