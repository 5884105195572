/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import moment from "moment";

import { useClickOutside, useGivenOrGeneratedId } from "../../hookHelpers";
import { Display } from "../InlineEditor";
import DateSelector from "../DateSelector";
import TextButton from "../TextButton";
import Button from "../Button";
import { isDateValid } from "../DatePicker/utils/helpers";
import { formatDate, getValidDateFormats } from "../DateSelector/utils/helpers";

import { Calendar } from "../Icomoon";

/**
 * @summary InlineEditingDatePicker component. It's possible to enable up to 2 timepickers.
 * @param {string} value - The datepicker initial value. Provide a string in the following formats YYYY-MM-DD | YYYY-MM-DD HH:MM AM/PM | YYYY-MM-DD HH:MM AM/PM HH:MM AM/PM
 * @param {string} position - Provide the position of the calendar.
 * @param {func} onChange - It's triggered when the user clicks on "done" or outside the datepicker. It returns a string based on dateFormat. Default format is MM/DD/YYYY
 * @param {func} dateFormat - Provide a momentjs date format to override the default one (MM-DD-YYYY). See https://momentjs.com/docs/#/displaying/format/ for available formats.
 * @param {string} className - className for the outermost HTML element.
 * @param {string} displayClassName - className for the display HTML element.
 * */
/* eslint-disable react/prop-types */
const InlineEditingDatePicker = ({
  id: idFromProps,
  value,
  position,
  onChange,
  dateFormat,
  className,
  displayClassName,
  portalDOMElement,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("inline-editing-datepicker", idFromProps);

  const initialDate = moment(value, getValidDateFormats(dateFormat)).isValid() ? value : "";

  const ref = useRef(null);
  const oldValueRef = useRef(initialDate);

  const [isOpen, setIsOpen] = useState(false);
  // final:  Date shown on TextComboInput. "ValidTemporary" becomes "final" when the user clicks on the "Done" button or outside.
  // impureTemporary: User input. it might be wrong, correct or empty. Do not rely on it. It might be anything.
  // validTemporary: It's a valid impureTemporary date. Necessary to send only valid dates to the DateSelector component.
  const [date, setDate] = useState({
    final: initialDate,
    impureTemporary: initialDate,
    validTemporary: initialDate,
  });
  const [error, setError] = useState(false);
  const portalElm = document.getElementById(portalDOMElement);

  const calendarPositionClassNames = (p) => {
    switch (p) {
      case "right":
        return `tw-absolute tw-top-30px tw-left-0`;
      case "left":
        return `tw-absolute tw-top-30px tw-right-0`;
      default:
        return "";
    }
  };

  const handleDone = () => {
    if (date.validTemporary !== "" && !isDateValid(date, dateFormat)) {
      setError(true);
    } else {
      setDate({
        ...date,
        final: date.validTemporary,
      });
      setIsOpen(false);
      setError(false);
    }
  };

  useClickOutside(ref, handleDone);

  const isValid = (val) => val && moment(val, getValidDateFormats(dateFormat)).isValid();

  useEffect(() => {
    // an object breaks the datepicker, and in consequence, the page
    if (typeof value !== "string" && value !== null) return;

    const validDate = isValid(value) ? value : "";

    setDate({
      final: validDate,
      impureTemporary: validDate,
      validTemporary: validDate,
    });
  }, [value]);

  useEffect(() => {
    if (oldValueRef.current === date.final) return;
    oldValueRef.current = date.final;
    if (onChange) {
      onChange(date.final);
    }
  }, [date.final]);

  useEffect(() => {
    if (date.impureTemporary === "" || isDateValid(date, dateFormat)) {
      setDate({
        ...date,
        validTemporary: date.impureTemporary,
      });
    }
  }, [date.impureTemporary]);

  const display = isValid(value) ? (
    <Display
      id={id}
      label={formatDate(value, dateFormat)}
      value={value}
      onInteract={() => setIsOpen(true)}
      icon={<Calendar />}
      data-cy="inline-editing-datepicker-display"
      className={displayClassName}
    />
  ) : (
    <TextButton className={`tw-p-0 ${displayClassName}`.trim()} onClick={() => setIsOpen(true)}>
      add
    </TextButton>
  );

  const PICKER_COMP = (
    <div
      ref={ref}
      className={`${calendarPositionClassNames(
        position,
      )} tw-bg-white tw-z-1000 tw-w-[240px] tw-px-12px tw-shadow-[0px_6px_20px_2px_rgba(51,51,51,0.16)] tw-flex tw-flex-col tw-justify-center tw-items-center`}
    >
      <DateSelector
        onChange={(dateSelectorState) => {
          setDate({
            ...date,
            ...dateSelectorState, // necessary for edge case: leap year in date formats without year
            impureTemporary: dateSelectorState.formattedDate,
          });
        }}
        value={date.validTemporary}
        error={error}
        dateFormat={dateFormat}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      <hr className="tw-mt-0 tw-mb-0 tw-pt-5px tw-pb-5px tw-bg-white tw-border-neutral-gray-10 tw-w-full" />
      <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-bg-white tw-pb-4">
        <TextButton
          className="tw-mr-auto tw-px-0"
          onClick={() => {
            setDate({
              ...date,
              validTemporary: "",
              impureTemporary: "",
            });
          }}
          type="button"
          schema="default"
          size="small"
          data-cy="inline-editing-datepicker-clear"
        >
          Clear
        </TextButton>
        <Button onClick={handleDone} schema="primary" size="small" data-cy="inline-editing-datepicker-done">
          Done
        </Button>
      </div>
    </div>
  );
  const PICKER_DOM = portalDOMElement && portalElm ? createPortal(PICKER_COMP, portalElm) : PICKER_COMP;
  return (
    <div className={className}>
      <div className="tw-relative">
        {display}
        {isOpen && PICKER_DOM}
      </div>
    </div>
  );
};

InlineEditingDatePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
  displayClassName: PropTypes.string,
};

InlineEditingDatePicker.defaultProps = {
  id: null,
  value: null,
  dateFormat: "",
  position: "left",
  className: "",
  displayClassName: "",
};

export default InlineEditingDatePicker;
