import React from "react";

import TimeFrameEditVal from "../TimeFrameEditVal";
import TimeFrameDateSelector from "../TimeFrameDateSelector";

export default () => (
  <div className="tw-flex">
    <span className="tw-font-semibold">Timeframe:</span>
    <TimeFrameEditVal />
    <TimeFrameDateSelector />
  </div>
);
