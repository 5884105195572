import React, { useState } from "react";
import { connect } from "react-redux";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { setModalBulkReassignTaskOpen } from "../actions/creators";
import { bulkEditTasksAsThunk } from "../actions/thunks";
import withFilters from "../HOCs/withFilters";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import Dropdown from "../../../../shared/v2/Dropdown";

const ModalBulkReassignTask = ({
  isModalReassignTaskOpen,
  onSetModalReassignTaskOpen,
  onBulkEditTasksAsThunk,
  selectedTasks,
  listingUuid,
  taskFilters,
  assignableAgents,
}) => {
  const [assignedTeamMate, setAssignedTeamMate] = useState(assignableAgents[0]);

  return (
    <Modal
      show={isModalReassignTaskOpen}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[496px]"
      onHide={onSetModalReassignTaskOpen}
      closeButton
    >
      <Modal.Header title={`Reassign ${selectedTasks.length} Task`} className="tw-mb-16px" closeButton />
      <Modal.Body className="tw-mb-32px tw-max-h-[70vh]">
        <Dropdown
          containerClassName="tw-w-full"
          placeholder="Anyone"
          value={assignedTeamMate}
          options={assignableAgents}
          onChange={setAssignedTeamMate}
          label="Reassign Task(s) To"
          isSearchable
        />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row">
        <Button className="tw-mr-auto" size="medium" schema="tertiary" onClick={onSetModalReassignTaskOpen}>
          CANCEL
        </Button>
        <Button
          size="medium"
          schema="primary"
          onClick={() => {
            onSetModalReassignTaskOpen();
            onBulkEditTasksAsThunk(selectedTasks, listingUuid, taskFilters, {
              assigned_to_user_id: assignedTeamMate.value,
            });
          }}
        >
          REASSIGN
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isModalReassignTaskOpen: state.tdpTasksReducer.isModalReassignTaskOpen,
  selectedTasks: state.tdpTasksReducer.selectedTasks,
  listingUuid: state.tdpTasksReducer.uuid,
  assignableAgents: state.tdpTasksReducer.assignableAgents,
});

const mapDispatchToProps = (dispatch) => ({
  onSetModalReassignTaskOpen: () => dispatch(setModalBulkReassignTaskOpen()),
  onBulkEditTasksAsThunk: (selectedTasks, listingUuid, filtered, payload) =>
    dispatch(bulkEditTasksAsThunk(selectedTasks, listingUuid, filtered, payload)),
});

ModalBulkReassignTask.propTypes = {
  isModalReassignTaskOpen: bool,
  selectedTasks: arrayOf(string),
  onSetModalReassignTaskOpen: func,
  onBulkEditTasksAsThunk: func,
  listingUuid: string,
  taskFilters: string,
  assignableAgents: arrayOf(shape({})),
};

ModalBulkReassignTask.defaultProps = {
  isModalReassignTaskOpen: false,
  selectedTasks: [],
  onSetModalReassignTaskOpen: null,
  onBulkEditTasksAsThunk: null,
  listingUuid: null,
  taskFilters: string,
  assignableAgents: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(withFilters(ModalBulkReassignTask));
