import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grip as GripIcon, AngleDownIcon } from "./icons";
import useOutsideClick from "../utils/useOnClickOutside";
import Checkbox from "./shared/Checkbox";
import Button from "../../shared/v2/Button";

const ColumnOption = ({ label, checked, id, onChange, fixed }) => {
  const labelColor = fixed ? "tw-text-gray" : "tw-text-gray-75";
  const gripColor = fixed ? "tw-invisible" : "tw-text-gray tw-cursor-move";

  return (
    <div
      key={`${id}-${checked}`}
      className="tw-flex tw-bg-white tw-flex-row tw-space-x-2 tw-p-10px tw-items-center tw-justify-between tw-min-w-full"
    >
      <div className="tw-flex tw-flex-row tw-space-x-2 tw-items-center">
        <Checkbox onChange={fixed ? () => {} : onChange} checked={checked} />
        <span className={labelColor}>{label}</span>
      </div>
      <div>
        <span className={`${gripColor}`}>
          <GripIcon />
        </span>
      </div>
    </div>
  );
};

const MaybeOrderableOption = ({ option, onChange, index }) => {
  const content = (
    <ColumnOption
      label={option.label}
      id={option.id}
      onChange={() => onChange(option.id)}
      checked={option.active}
      fixed={!!option.fixed}
    />
  );
  if (option.fixed) return <div>{content}</div>;
  return (
    <Draggable key={option.id} draggableId={option.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {content}
        </div>
      )}
    </Draggable>
  );
};

const ColumnsDropdown = ({ options, onChange, onDrag }) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => setOpened(false));
  const prepareDrag = ({ destination, source }) => {
    if (destination.index === null) return;
    if (destination.index === source.index) return;
    if (options[destination.index].fixed) return;

    onDrag(source.index, destination.index);
  };
  return (
    <div ref={ref} className="tw-relative tw-z-30">
      <Button schema="secondary" size="small" onClick={() => setOpened(!opened)}>
        <div className="tw-flex tw-items-center tw-space-x-2 tw-font-bold">
          <span className="tw-inline-flex tw-items-center">COLUMNS</span>
          <AngleDownIcon />
        </div>
      </Button>
      {opened && (
        <DragDropContext onDragEnd={prepareDrag}>
          <Droppable droppableId="columns-picker-droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="tw-absolute tw-bg-white tw-min-w-96px tw-w-196px tw-bottom-100px tw-border-solid tw-rounded-4px tw-border-2 tw-border-gray-10 tw-max-h-[600px] tw-overflow-y-auto"
              >
                {options.map((option, index) => (
                  <MaybeOrderableOption key={option.id} option={option} index={index} onChange={onChange} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

ColumnOption.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool,
  fixed: PropTypes.bool,
  onChange: PropTypes.func,
};

ColumnOption.defaultProps = {
  label: null,
  id: null,
  checked: false,
  fixed: false,
  onChange: null,
};

MaybeOrderableOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    active: PropTypes.bool,
    fixed: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  index: PropTypes.number.isRequired,
};

MaybeOrderableOption.defaultProps = {
  option: null,
  onChange: null,
};

ColumnsDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  onDrag: PropTypes.func,
};

ColumnsDropdown.defaultProps = {
  options: [],
  onChange: null,
  onDrag: null,
};

export default ColumnsDropdown;
