export const interestLevels = ["5", "4", "3", "2", "1"];

export const interestLevelsOptions = Array.from(interestLevels, (level) => ({
  value: level,
  label: level,
}));

export const interestLevelOptionByValue = (interestLevel) => ({
  value: `${interestLevel}`,
  label: `${interestLevel}`,
});
