import React from "react";
import { arrayOf, func, string } from "prop-types";
import { connect } from "react-redux";
import { filterTasksByTeamMembers } from "../actions/creators";

import Checkbox from "../../../../shared/v2/Checkbox";
import Avatar from "../../../../shared/v2/Avatar/Avatar";

const TeamMember = ({ id, avatar, fullName, activeTeamMembers, onFilterByTeamMembers }) => (
  <button
    type="button"
    onClick={() => onFilterByTeamMembers(id)}
    className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-gray-57 tw-border-0 tw-bg-transparent tw-m-0 tw-p-0 tw-mb-10px"
  >
    <Checkbox checked={activeTeamMembers.includes(id)} value={id} className="tw-z-40" />
    <Avatar size="small" className="tw-ml-8px" alt={fullName} src={avatar}>
      {fullName}
    </Avatar>
    <div className="tw-ml-8px">{fullName}</div>
  </button>
);

const mapStateToProps = (state) => ({
  activeTeamMembers: state.tdpTasksReducer.secondaryFilters.activeTeamMembers,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterByTeamMembers: (teamMember) => dispatch(filterTasksByTeamMembers(teamMember)),
});

TeamMember.propTypes = {
  id: string,
  avatar: string,
  fullName: string,
  onFilterByTeamMembers: func,
  activeTeamMembers: arrayOf(string),
};

TeamMember.defaultProps = {
  id: null,
  avatar: null,
  fullName: null,
  onFilterByTeamMembers: () => {},
  activeTeamMembers: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
