import React from "react";
import PropTypes from "prop-types";

const Error = ({ message }) => (
  <div className="tw-bg-semantic-red-5 tw-rounded-2px tw-px-16px tw-py-10px tw-my-12px">
    <span className="tw-text-semantic-red-120">{message}</span>
  </div>
);

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
