/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LightbulbOnSolidV6 = (props) => (
  <Icon {...props} name="Lightbulb On Solid V6">
    <path d="M6.003 12.96c.003.156.05.311.134.442l.427.642a.898.898 0 0 0 .666.357h1.543c.237 0 .534-.16.666-.357l.427-.642A.924.924 0 0 0 10 12.96v-.959H6.003v.959zM7.988 1.6C5.438 1.606 3.6 3.652 3.6 5.977c0 1.066.385 2.094 1.088 2.895.415.472 1.059 1.456 1.306 2.287l.003.019h4.007l.002-.019c.247-.831.89-1.815 1.306-2.287A4.38 4.38 0 0 0 12.4 5.977c0-2.412-1.98-4.383-4.413-4.377zM8 4c-1.103 0-2 .897-2 1.978 0 .222-.178.4-.4.4s-.4-.178-.4-.4C5.203 4.453 6.452 3.204 8 3.201c.222 0 .4.178.4.4s-.177.4-.4.4zM2.8 6.4a.6.6 0 0 0-.6-.6H.6A.6.6 0 0 0 .6 7h1.6a.6.6 0 0 0 .6-.6zm12.6-.6h-1.6a.6.6 0 0 0 0 1.2h1.6a.6.6 0 0 0 0-1.2zM3.277 2.981l-1.387-.8a.6.6 0 0 0-.909.516.604.604 0 0 0 .31.522l1.388.8a.6.6 0 1 0 .6-1.038zM14.71 9.582l-1.387-.8a.6.6 0 1 0-.6 1.038l1.388.8a.598.598 0 0 0 .809-.225.601.601 0 0 0-.21-.813zm-12.032-.8-1.387.8a.603.603 0 0 0-.006 1.047.602.602 0 0 0 .606-.009l1.387-.8a.6.6 0 0 0-.601-1.037zM13.025 4.1a.611.611 0 0 0 .3-.081l1.385-.8a.599.599 0 0 0 .209-.813.597.597 0 0 0-.809-.225l-1.384.8a.6.6 0 0 0 .3 1.119z" />
  </Icon>
);

export default LightbulbOnSolidV6;
