export default (payloadHandlrs) => {
  const recurringHandlr = () => {
    payloadHandlrs((payloadPrev) => ({
      ...payloadPrev,
      recurring: !payloadPrev.recurring
        ? {
            frequency: { label: "Every Day", value: "number" },
            recurring_every_so_many_days: 1,
            recurring_day_of_week: null,
          }
        : null,
    }));
  };

  const contingentHandlr = () => {
    payloadHandlrs((payloadPrev) => ({
      ...payloadPrev,
      contingent: !payloadPrev.contingent
        ? {
            numOfDays: "1",
            next: "after",
            event: { label: "Close Date", value: "milestone-close_date" },
          }
        : null,
    }));
  };

  return { recurringHandlr, contingentHandlr };
};
