export const CREATE_MEDIA_OPTIONS = [
  {
    cardName: "Mailer Campaigns",
    name: "Mailer Campaign",
    url: "https://www.brivitymarketer.com/v2/create-mailer",
  },
  {
    cardName: "Digital Ads",
    name: "Social Media Ad",
    url: "https://www.brivitymarketer.com/v2/listings?filter=active&adType=listed&adsModal=true",
  },
  {
    cardName: "Printed Media",
    name: "Print Media",
    url: "https://www.brivitymarketer.com/v2/create-print-media",
  },
  {
    cardName: "Social Media Graphics",
    name: "Social Media Post",
    url: "https://www.brivitymarketer.com/v2/create-social-media",
  },
  { cardName: "Videos", name: "Video", url: "https://www.brivitymarketer.com/v2/create-video" },
];

export const getCardLink = (name) => CREATE_MEDIA_OPTIONS.filter((option) => option.cardName === name)[0];

export const getImglink = (avatarCollection) => {
  const defaultAvatarUrl = "/assets/user-default.svg";
  const isCustomAvatar = (url) => url !== defaultAvatarUrl;
  const size = "public"; // or the size we want to use
  if (isCustomAvatar(avatarCollection[size])) {
    return avatarCollection[size];
  }
  return null;
};
