import React, { useState } from "react";
import { string, shape, bool } from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6, DesktopSolidV6 } from "@shared/v2/Icomoon/Icons";

import DetailFieldLabel from "../DetailFieldLabel";
import TextButton from "@shared/v2/TextButton";
import Edit from "@shared/v2/Icomoon/Icons/Edit";
import AddEditBACModal from "./AddEditBACModal";
import { formatExistingNumber } from "../api/helpers";

const BACDetailInput = ({ BACval, dataCy }) => {
  const { percentage, value, status, cooperative_compensation } = BACval;
  const [addingBAC, setAddingBAC] = useState(false);
  const [editingBAC, setEditingBAC] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleOnClickAdd = () => {
    setAddingBAC(true);
  };

  const handleOnClickEdit = () => {
    setEditingBAC(true);
  };

  const BACCloseModal = () => {
    setEditingBAC(false);
    setAddingBAC(false);
  };

  return (
    <div
      className="tw-relative tw-py-[8px] tw-w-full tw-items-center tw-flex tw-gap-12px tw-justify-between hover:tw-bg-neutral-gray-5 tw-rounded-4px tw-w-calc[100% + 16px] tw-ml-[-8px] tw-p-8px"
      data-cy={dataCy}
    >
      <div className="tw-flex tw-gap-8px tw-justify-between tw-items-center">
        <DetailFieldLabel label="Buyer Agent Commission" />
        <Tooltip
          multiline
          placement="top"
          content={
            <div className="tw-w-[266px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
              Amount offered to the buyer’s agent of this transaction. Active listings with a commission value
              can be displayed on the compensation page of your Brivity website.
            </div>
          }
          trigger={<CircleInfoSolidV6 />}
        />
      </div>

      <div className="tw-justify-end tw-flex">
        {(cooperative_compensation || value) && status && (
          <Tooltip
            multiline
            placement="top"
            content={
              <div className="tw-w-[163px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                {status == "active"
                  ? "Displayed on compensation page"
                  : status == "excluded"
                    ? "Excluded from compensation page"
                    : "Cannot be displayed on your compensation page. This listing  must have a valid MLS number and be Active on the MLS."}
              </div>
            }
            trigger={
              <DesktopSolidV6
                className={`tw-mr-[6px] tw-w-[12px] tw-h-[12px] ${status == "active" ? "tw-text-green-100" : status == "excluded" ? "tw-text-[#C2C2C2]" : "tw-text-[#FA423C]"}`}
              />
            }
          />
        )}

        <span className="tw-cursor-pointer">
          {cooperative_compensation || value ? (
            <div className="tw-flex tw-gap-8px tw-justify-between" onClick={handleOnClickEdit}>
              <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {cooperative_compensation ? (
                  <span>Contact</span>
                ) : (
                  <span>
                    {!percentage && "$"}
                    {!percentage ? formatExistingNumber(value) : value}
                    {percentage && "%"}
                  </span>
                )}
              </div>
              <div className="tw-w-[10px] tw-h-[10px]">
                {isHovered && <Edit size="custom" className="tw-w-[10px] tw-h-[10px]" />}
              </div>
            </div>
          ) : (
            <TextButton
              data-cy="inline-editor-display-button"
              className="!tw-mr-[21px] tw-h-auto tw-mt-1px tw-overflow-hidden tw-text-right hover:tw-underline tw-p-0"
              onClick={handleOnClickAdd}
            >
              add
            </TextButton>
          )}
        </span>
      </div>

      {addingBAC && <AddEditBACModal modalOpen={addingBAC} closeModal={BACCloseModal} />}

      {editingBAC && (
        <AddEditBACModal isEditing BACval={BACval} modalOpen={editingBAC} closeModal={BACCloseModal} />
      )}
    </div>
  );
};

BACDetailInput.propTypes = {
  BACval: shape({
    id: string,
    percentage: bool,
    value: string,
    cooperative_compensation: bool,
    exclude_from_commissions: bool,
    status: string,
  }),
  dataCy: string,
};

BACDetailInput.defaultProps = {
  BACval: {
    id: "",
    percentage: false,
    value: "",
    cooperative_compensation: false,
    exclude_from_commissions: false,
    status: "",
  },
  dataCy: null,
};

export default BACDetailInput;
