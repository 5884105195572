import React, { Component } from "react";

import ListingAlert from "../listing_alerts/ListingAlert";

class AddListingAlertModal extends Component {
  render() {
    const { geoCodeAccountAddress, closeListingAlertModal, mlsIds, person } = this.props;

    return (
      <ListingAlert
        person={person}
        closeListingAlertModal={closeListingAlertModal}
        mlsIds={mlsIds}
        geoCodeAccountAddress={geoCodeAccountAddress}
        type="new"
        title="Create New Listing Alert"
      />
    );
  }
}

export default AddListingAlertModal;
