const getEmptyConversation = (currentUser) => ({
  eventType: "text",
  markedAsRead: true,
  receivedAtEpoch: Math.round(Date.now() / 1000),
  uuid: "temp",
  conversationUsers: [
    {
      personUuid: currentUser.person.uuid,
      userUuid: currentUser.uuid,
      isConversationOwner: true,
      alias: `${currentUser.firstName} ${currentUser.lastName}`,
      avatar: currentUser.avatar,
    },
  ],
});

// eslint-disable-next-line import/prefer-default-export
export { getEmptyConversation };
