import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextComboInput from "@shared/v2/TextComboInput";
import Typography from "@shared/v2/Typography";
import format from "@shared/formatters";
import RecommendedGoals from "./RecommendedGoals";
import Loading from "./Loader";
import useWizard from "../services/useWizard";

const TeamProductionWizardModal = ({ agent, show, onClose, year }) => {
  const wizardData = useWizard({ agentId: agent?.value, show, year });
  const { actions, goals, loading } = wizardData;
  const [currentNetIncomeGoal, setCurrentNetIncomeGoal] = useState(goals.currentNetIncomeGoal || 0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    setCurrentNetIncomeGoal(goals.currentNetIncomeGoal);
  }, [goals.currentNetIncomeGoal]);

  useEffect(() => {
    if (!show) setPage(1);
  }, [show]);

  useEffect(() => {
    if (page === 2) {
      actions.setLocalGoals((g) => ({ ...g, currentNetIncomeGoal }));
      setTimeout(() => {
        setPage(3);
      }, 2000);
    }
  }, [page]);

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName={`${page < 3 ? "tw-max-w-[460px]" : "tw-max-w-[620px]"} tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]`}
      show={show}
      onHide={onClose}
    >
      {loading && <Loading />}
      {page === 1 && (
        <>
          <Modal.Header
            title="Let's Get Started"
            description={
              <div className="tw-flex tw-flex-col tw-gap-[16px]">
                <span>
                  Let’s start with how much pretax income you want to take home for the year. That will help
                  us calculate your other goals so they are aligned with achieving your desired net income.
                </span>
                <Typography variant="body-s" className="!tw-italic !tw-text-gray-50">
                  If you don’t know your exact net income goal yet, go ahead and enter an estimate - you can
                  always change it later.
                </Typography>
              </div>
            }
            closeButton
          />
          <Modal.Body>
            <TextComboInput
              isRequired
              leading="$"
              placeholder="0"
              spacing={0}
              value={currentNetIncomeGoal ? format.number(currentNetIncomeGoal, 0) : ""}
              label={`${year} Net Income`}
              onChange={(e) => setCurrentNetIncomeGoal((e.target.value || "").replace(/[^0-9]/g, ""))}
              error={error}
            />
          </Modal.Body>
          <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
            <Button schema="tertiary" size="medium" onClick={onClose}>
              Cancel
            </Button>
            <Button
              size="medium"
              onClick={() => {
                if (!currentNetIncomeGoal) {
                  setError("Please enter your net income goal");
                } else {
                  setError(null);
                  setPage(2);
                }
              }}
            >
              Calculate Goals
            </Button>
          </Modal.Footer>
        </>
      )}
      {page === 2 && (
        <>
          <Modal.Header title={`Calculating Your ${year} Goals...`} />
          <Modal.Body className="tw-relative tw-h-[50px]">
            <Loading />
          </Modal.Body>
          <Modal.Footer className="tw-text-center tw-text-gray-50" onClick={() => setPage(3)}>
            Goals are being generated...
          </Modal.Footer>
        </>
      )}
      {page === 3 && <RecommendedGoals show={show} year={year} onClose={onClose} wizardData={wizardData} />}
    </Modal>
  );
};

TeamProductionWizardModal.propTypes = {
  agent: PropTypes.shape().isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default TeamProductionWizardModal;
