/* eslint-disable react/prop-types, no-alert, no-restricted-globals */
import React, { createContext, useEffect, useMemo, useRef } from "react";
import { Provider } from "react-redux";
import useReducerWithSideEffects from "use-reducer-with-side-effects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { reducer } from "../reducer";
import actions from "../actions";
import Headings from "./Headings";
// eslint-disable-next-line import/no-cycle
import TransactionsTable from "./TransactionsTable";
import FiltersModal from "./FiltersModal";
import { types, statuses, publishedStates } from "./stubs/filterStubs";
import "./Transaction.scss";
import { titleize } from "../utils/helpers";
import { closeDateOptions, statusDateOptions } from "./stubs/closeDateOptions";
// eslint-disable-next-line import/no-cycle
import Board from "./CardsView/Board";
import WrapperAddTransactionModal from "./WrapperAddTransactionModal";
// eslint-disable-next-line import/no-cycle
import TDIUpdatePipelineStatusModal from "./TDIUpdatePipelineStatusModal";
import ImportListingModal from "./ImportListingModal";

export const UpdateFromPipelineContext = createContext();

export const allColumns = {
  photo: { active: true, position: 1, label: "Photo", fixed: true },
  address: { active: true, position: 2, label: "Address / Title", fixed: true },
  type: { active: true, position: 3, label: "Type" },
  status: { active: true, position: 4, label: "Status" },
  mls: { active: true, position: 5, label: "MLS#" },
  agent: { active: true, position: 6, label: "Agent" },
  dateListed: { active: true, position: 7, label: "Date Listed" },
  price: { active: true, position: 8, label: "Listing Price" },
  expiration: { active: true, position: 9, label: "Expiration" },
  closePrice: { active: true, position: 10, label: "Close Price" },
  gci: { active: true, position: 11, label: "GCI" },
  closeDate: { active: true, position: 12, label: "Close Date" },
  dom: { active: true, position: 13, label: "DOM" },
  dateCanceled: { active: true, position: 14, label: "Date Canceled" },
  mutualAcceptanceDate: { active: true, position: 15, label: "Mutual Acceptance Date" },
  lastUpdated: { active: true, position: 16, label: "Last Updated" },
  source: { active: true, position: 17, label: "Source" },
  statusChangedAt: { active: true, position: 18, label: "Status Changed At" },
};

const toggleColumnId = (id, columns) => ({
  ...columns,
  [id]: { ...columns[id], active: !columns[id].active },
});
const getName = (type, id, filtersMapping) => {
  if (id === -1) return "all";
  const usedFilter = filtersMapping[type].find(
    (filter) => filter.id && (filter.id === id || filter.id.toString() === id),
  );
  return usedFilter?.name || "not found";
};

const transformFilterType = (type) => type !== undefined && titleize(type);
const toFilterLabel = (type, value, filtersMapping) => ({
  id: { type, value },
  label: `${transformFilterType(type)}: ${titleize(getName(type, value, filtersMapping))}`,
});

const filtersAdapter = (filtersObject, filtersMapping) => {
  const orderedKeys = [
    "agent",
    "type",
    "status",
    "source",
    "closeDateRange",
    "statusChangedRange",
    "publishedState",
  ];
  return orderedKeys
    .map((key) => filtersObject[key].map((f) => toFilterLabel(key, f, filtersMapping)))
    .flat();
};

const loader = () => (
  <div className="tw-bg-white tw-h-full tw-w-full tw-min-w-full tw-absolute tw-text-black tw-opacity-50 tw-z-10">
    <div className="tw-flex tw-justify-center tw-mt-48px">
      <FontAwesomeIcon
        className="tw-tw-opacity-50 tw-text-neutral-gray-75 tw-fa-3x tw-fa-spin tw-animate-spin tw-text-[50px]"
        icon={faSpinner}
      />
    </div>
  </div>
);

const filterUnknownMilestones = (_renderableColumns, newMilestonesColumns) => {
  const renderableColumns = { ..._renderableColumns };
  Object.keys(renderableColumns).forEach((key) => {
    if (renderableColumns[key].isNewMilestone && !newMilestonesColumns[key]) {
      delete renderableColumns[key];
    }
  });
  return { ...newMilestonesColumns, ...renderableColumns };
};

const Wrapper = ({ initialState, agents, sources }) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  const [state, dispatch] = useReducerWithSideEffects(reducer, { ...initialState });
  const { buyers, sellers, landlords, tenants, referrals } = state.listings.totals;
  const totalListings =
    Number(buyers) + Number(sellers) + Number(landlords) + Number(tenants) + Number(referrals);
  const openAddTransactionModal = (status = null) =>
    dispatch(actions.rendering.setAddTransactionModal(true, status));
  const closeAddTransactionModal = () => dispatch(actions.rendering.setAddTransactionModal(false));
  const reorderColumn = (sourceIndex, destinationIndex) =>
    dispatch(actions.rendering.reorderColumn({ sourceIndex, destinationIndex }));
  const setRenderableColumns = (columns) => dispatch(actions.rendering.setColumns(columns));
  const toggleColumn = (id) => setRenderableColumns(toggleColumnId(id, state.renderableColumns));
  const deleteListing = (listing) => dispatch(actions.listings.deleteStart(listing));
  const promptForDeletion = (listing) => {
    const confirmed = confirm("Are you sure you want to delete this transaction?");
    if (confirmed) {
      deleteListing(listing);
    }
  };
  const updateListing = (listing) => (changes) => {
    if (changes === undefined) {
      return;
    }
    dispatch(actions.listings.updateStart(listing, changes));
  };
  const archiveListing = (listing) => {
    updateListing(listing)({ status: "archived" });
  };

  const openFilterModal = () => dispatch(actions.filters.openModal());
  const closeFilterModal = () => dispatch(actions.filters.closeModal());
  const removeFilter = (filter) => dispatch(actions.filters.remove(filter));
  const clearFilters = () => dispatch(actions.filters.clear());
  const setFilters = (filters) => dispatch(actions.filters.set(filters));
  const setOrder = (value) => dispatch(actions.order.set(value));
  const setPage = (pageNumber) => dispatch(actions.pagination.setPage(pageNumber));
  const setDisplay = (display) => dispatch(actions.rendering.setDisplay(display));
  const toggleDisplay = () => setDisplay(state.display === "list" ? "card" : "list");
  const loadMore = (status) => dispatch(actions.listings.cardFetchStart(status));
  const saveListingFilters = (data) => dispatch(actions.saveFiltersModal.saveStart(data));
  const openSaveFiltersModal = () => dispatch(actions.saveFiltersModal.openModal());
  const closeSaveFiltersModal = () => dispatch(actions.saveFiltersModal.closeModal());

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value || false;
    });
    return ref.current;
  };

  const prevError = usePrevious(state.error && state.error.showError);

  useEffect(() => {
    if (state.error !== undefined && state.error.showError && !prevError) {
      toast(state.error.message !== "" ? state.error.message : "Something went wrong", {
        onOpen: () => {
          dispatch(actions.errors.clear());
        },
      });
    }
  }, [state.error]);

  useEffect(() => {
    setRenderableColumns({
      ...allColumns,
      ...filterUnknownMilestones(initialState.renderableColumns, initialState.newMilestonesColumns),
    });
  }, [initialState]);

  useEffect(() => {
    if (state.display === "card") {
      const possibleStatuses = [
        "pipeline",
        "comingsoon",
        "active",
        "pending",
        "sold",
        "expired",
        "canceled",
        "withdrawn",
        "archived",
      ];

      const usedStatuses = state.filters.status.length === 0 ? possibleStatuses : state.filters.status;
      usedStatuses.forEach((status) => loadMore(status));
    }
  }, [state.display, state.filters]);

  const filtersMapping = {
    agent: agents,
    status: statuses,
    publishedState: publishedStates,
    type: types,
    source: sources,
    closeDateRange: closeDateOptions,
    statusChangedRange: [
      { id: "custom", name: state.filters.statusChangedDates?.join(" - ") },
      { id: "yesterday", name: "Yesterday" },
      { id: "30_days", name: "Last 30 Days" },
      ...statusDateOptions,
    ],
  };

  const renderableColumns = state.renderableColumns || initialState.renderableColumns || allColumns;

  const listView = (
    <TransactionsTable
      newMilestonesColumns={state.newMilestonesColumns}
      listings={state.listings.entries}
      totals={{ ...state.listings.totals, totals: totalListings }}
      sources={sources}
      dispatch={dispatch}
      archiveListing={archiveListing}
      updateListing={updateListing}
      deleteListing={promptForDeletion}
      setOrder={setOrder}
      order={state.order}
      renderableColumns={renderableColumns}
      permissions={initialState.permissions}
      setPage={setPage}
      pagination={state.pagination}
    />
  );

  const cardView = (
    <Board
      listings={state.listings.entries}
      statusPagination={state.listings.byStatusPagination}
      updateListing={updateListing}
      sources={sources}
      setOrder={setOrder}
      order={state.order}
      permissions={initialState.permissions}
      totals={state.listings.byStatusTotals}
      onLoadMore={loadMore}
      onDeletion={promptForDeletion}
      openAddTransactionModal={openAddTransactionModal}
      closeAddTransactionModal={closeAddTransactionModal}
    />
  );

  const baseBackground = state.display === "list" ? "tw-bg-white" : "tw-bg-tinted-gray-100";

  return (
    <Provider store={store}>
      <UpdateFromPipelineContext.Provider value={{ state, dispatch }}>
        <div
          data-cy="transactions-wrapper"
          id="transactions-react__wrapper"
          className={`
          tw-flex tw-flex-col tw-relative tw-h-full
          ${state.display === "list" ? "tw-overflow-auto" : ""}
          ${baseBackground}
        `}
        >
          <WrapperAddTransactionModal
            initialState={initialState}
            currentUserId={state.currentUserId}
            newMilestoneId={state.newMilestoneId}
            showModal={state.addTransactionModalIsOpen}
            onClose={closeAddTransactionModal}
            disableReferralBtn={state.status === "pipeline"}
            columnStatus={state.status}
            isStandalone
          />

          <ToastContainer />

          <ReactTooltip
            className="tw-custom-color transactions__tooltip"
            place="right"
            arrowColor="#666666"
            effect="solid"
          />
          {state.listings.isFetching && loader()}
          <FiltersModal
            key={JSON.stringify(state.filters)}
            initialFilters={state.filters}
            isModalShown={state.filterModalIsOpen}
            initialTypes={types}
            initialAgents={agents}
            initialSources={sources}
            initialStatuses={statuses}
            initialPublishedStates={publishedStates}
            dateRangeOptions={closeDateOptions}
            statusDateOptions={statusDateOptions}
            onClearAll={() => {
              closeFilterModal();
              clearFilters();
            }}
            onFilter={(filters) => {
              closeFilterModal();
              setFilters(filters);
            }}
            onModalClose={closeFilterModal}
          />
          <Headings
            dispatch={dispatch}
            openFilterModal={openFilterModal}
            removeFilter={removeFilter}
            clearFilters={clearFilters}
            openSaveFiltersModal={openSaveFiltersModal}
            closeSaveFiltersModal={closeSaveFiltersModal}
            saveListingFilters={saveListingFilters}
            rawFilters={state.filters}
            saveFiltersData={state.saveFiltersModal}
            filters={filtersAdapter(state.filters, filtersMapping)}
            buyers={buyers}
            sellers={sellers}
            renderableColumns={renderableColumns}
            setRenderableColumns={toggleColumn}
            reorderColumn={reorderColumn}
            updateDisplay={toggleDisplay}
            display={state.display}
            setOrder={setOrder}
            order={state.order}
          />
          {state.display === "list" ? listView : cardView}
          <TDIUpdatePipelineStatusModal />
          <ImportListingModal />
        </div>
      </UpdateFromPipelineContext.Provider>
    </Provider>
  );
};

export default Wrapper;
