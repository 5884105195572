// Assignment to parameters in this file are in the context of immer, which
// automatically produces new objects for us without mutating existing state.
/* eslint-disable no-param-reassign */
import { produce } from "immer";

import {
  financialsCreateFailure,
  financialsCreateStarted,
  financialsCreateSuccess,
  financialsFetchFailure,
  financialsFetchSelectableTeamMembersSuccess,
  financialsFetchStarted,
  financialsFetchSuccess,
  financialsRemoveTeamMemberFailure,
  financialsRemoveTeamMemberSuccess,
  financialsUpdateCanceled,
  financialsUpdateFailure,
  financialsUpdateProgress,
  financialsUpdateStarted,
  financialsUpdateSuccess,
  financialsSetClosedPrice,
} from "../TransactionDetailsPage/Main/Financials/actions/creators";

export const defaultState = {
  transactionSlug: null,
  financials: {
    rawData: {
      transaction_income: {
        closed_volume: null,
      },
    },
    adaptedData: {
      transaction: null,
      teamMembers: null,
      company: null,
      collaborators: null,
      CDAdocument: null,
    },
    lineItemTypeOptions: {
      transactionExpense: [],
      transactionIncome: [],
      agent: [],
      company: [],
    },
    selectableTeamMembers: [],
    meta: {
      errors: [],
      modalErrors: [],
      isLoading: true,
      isUpdating: false,
    },
  },
  closedPrice: null,
};

export const tdpFinancialsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case financialsFetchStarted().type:
    case financialsCreateStarted().type:
      return produce(state, (draft) => {
        draft.financials.meta.errors = [];
        draft.financials.meta.isLoading = true;
      });

    case financialsFetchSuccess().type:
      return produce(state, (draft) => {
        const { rawData, adaptedData, lineItemTypeOptions } = payload.data;

        draft.financials.rawData = rawData;
        draft.financials.adaptedData = adaptedData;
        draft.financials.lineItemTypeOptions = lineItemTypeOptions;
        draft.financials.meta.errors = [];
        draft.financials.meta.isLoading = false;
        draft.financials.isReferral = !!payload.data.rawData.for_referral;

        return draft;
      });
    case financialsCreateSuccess().type:
      return produce(state, (draft) => {
        const { rawData, adaptedData, lineItemTypeOptions } = payload.data;

        draft.financials.rawData = rawData;
        draft.financials.adaptedData = adaptedData;
        draft.financials.lineItemTypeOptions = lineItemTypeOptions;
        draft.financials.meta.errors = [];
        draft.financials.meta.isLoading = false;
      });

    case financialsFetchFailure().type:
    case financialsCreateFailure().type:
      return produce(state, (draft) => {
        const { errors } = payload.data;

        draft.financials.meta.errors = errors;
        draft.financials.meta.isLoading = false;
      });

    case financialsUpdateStarted().type:
      return produce(state, (draft) => {
        draft.financials.meta.isUpdating = true;
      });

    case financialsUpdateCanceled().type:
      return produce(state, (draft) => {
        draft.financials.meta.modalErrors = [];
        draft.financials.meta.isUpdating = false;
      });

    case financialsUpdateProgress().type:
      return produce(state, (draft) => {
        const { rawData, adaptedData, lineItemTypeOptions } = payload.data;

        draft.financials.rawData = rawData;
        draft.financials.adaptedData = adaptedData;
        draft.financials.lineItemTypeOptions = lineItemTypeOptions;
      });

    case financialsUpdateSuccess().type:
      return produce(state, (draft) => {
        const { rawData, adaptedData, lineItemTypeOptions } = payload.data;

        draft.financials.rawData = rawData;
        draft.financials.adaptedData = adaptedData;
        draft.financials.lineItemTypeOptions = lineItemTypeOptions;
        draft.financials.meta.errors = [];
        draft.financials.meta.modalErrors = [];
        draft.financials.meta.isUpdating = false;
      });

    case financialsUpdateFailure().type:
      return produce(state, (draft) => {
        const { errors, isInModal } = payload.data;

        if (isInModal) {
          draft.financials.meta.modalErrors = errors;
        } else {
          draft.financials.meta.errors = errors;
        }
        draft.financials.meta.isUpdating = false;
      });

    case financialsFetchSelectableTeamMembersSuccess().type:
      return produce(state, (draft) => {
        const { selectableTeamMembers } = payload.data;

        draft.financials.selectableTeamMembers = selectableTeamMembers;
      });

    case financialsRemoveTeamMemberSuccess().type:
      return produce(state, (draft) => {
        const { rawData, adaptedData } = payload.data;

        draft.financials.rawData = rawData;
        draft.financials.adaptedData = adaptedData;
        draft.financials.meta.errors = [];
      });

    case financialsRemoveTeamMemberFailure().type:
      return produce(state, (draft) => {
        const { errors } = payload.data;

        draft.financials.meta.errors = errors;
      });

    case financialsSetClosedPrice().type:
      return produce(state, (draft) => {
        draft.closedPrice = payload.closedPrice;
      });
    default:
      return state;
  }
};
