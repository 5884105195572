import React from "react";
import PropTypes from "prop-types";
import { ModalContext } from "./ModalContext";
import CLASSES from "./classes.json";

const { MODAL_BACKDROP_CLASSES } = CLASSES;

const Backdrop = ({ children, className, shouldCloseOnClick }) => {
  const { onHide } = React.useContext(ModalContext);

  return (
    <>
      {/* This component is meant to be rendered inside a ModalContext, which */}
      {/* already provides the keyboard listeners so we silence the linter here */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          if (shouldCloseOnClick) {
            onHide();
          }
        }}
        className={`${MODAL_BACKDROP_CLASSES} ${className}`}
      >
        {children}
      </div>
    </>
  );
};
Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  shouldCloseOnClick: PropTypes.bool,
};
Backdrop.defaultProps = {
  children: null,
  className: "",
  shouldCloseOnClick: false,
};

export default Backdrop;
