import React from "react";
import { useDispatch } from "react-redux";
import FieldLabel from "@shared/v2/FieldLabel";
import FilterPill from "@shared/v2/FilterPill";
import { setCallOutcome } from "../../reducers/dialerReducer/dialerReducer";
import { useCallOutcome } from "../../reducers/dialerReducer/selectors";

const CALL_OUTCOME_OPTIONS = [
  { label: "Talked", value: "talked_to_lead" },
  { label: "Left Message", value: "left_message" },
  { label: "Busy", value: "busy" },
  { label: "Failed", value: "failed" },
  { label: "No Answer", value: "no_answer" },
  { label: "Wrong Number", value: "wrong_number" },
];

const CallOutcome = () => {
  const dispatch = useDispatch();
  const callOutcome = useCallOutcome();

  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <FieldLabel label="Call Outcome" isRequired />
      <div className="tw-flex tw-flex-wrap tw-gap-[6px]">
        {CALL_OUTCOME_OPTIONS.map((option) => (
          <FilterPill
            key={option.value}
            selected={option.value === callOutcome}
            onClick={() => dispatch(setCallOutcome(option.value === callOutcome ? null : option.value))}
            size="medium"
            variant="primary"
          >
            {option.label}
          </FilterPill>
        ))}
      </div>
    </div>
  );
};

export default CallOutcome;
