import React from "react";
import PropTypes from "prop-types";

import Avatar from "../../shared/v2/Avatar/Avatar";

const NameWithAvatar = ({ name, role, avatarUrl, showPath }) => {
  const avatar = avatarUrl.length > 3 ? avatarUrl : null;

  return (
    <div className="tw-flex tw-flex-row">
      <Avatar className="tw-mr-12px" size="medium" src={avatar} alt={name} />
      <div className="tw-flex tw-flex-col">
        <span className="tw-text-14px tw-font-semibold tw-text-neutral-gray-75 tw-cursor-pointer">
          <a href={showPath}>{name}</a>
        </span>
        <span className="tw-text-12px -tw-font-normal tw-text-neutral-gray-50">{role}</span>
      </div>
    </div>
  );
};

NameWithAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  showPath: PropTypes.string.isRequired,
};

export default NameWithAvatar;
