import React from "react";
import Proptypes from "prop-types";
import Avatar from "../../../../shared/v2/Avatar";
import Tooltip from "../../../../shared/v2/Tooltip";

const ProfileIcon = ({ imglink, name, initials }) => (
  <Tooltip trigger={<Avatar src={imglink}>{initials}</Avatar>} content={name || "Anyone"} placement="right" />
);

ProfileIcon.propTypes = {
  imglink: Proptypes.string,
  name: Proptypes.string,
  initials: Proptypes.string,
};
ProfileIcon.defaultProps = {
  imglink: "",
  name: "",
  initials: "",
};

export default ProfileIcon;
