import React from 'react';
import { Checkbox, Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

const ChangePasswordCheckboxInput = (props) => {
  const { onChange, value } = props;

  return (
    <FormGroup>
      <Col componentClass={ControlLabel} htmlFor="" md={2}>Change Password</Col>

      <Col md={10}>
        <Checkbox
          checked={value}
          onChange={onChange}
        />
      </Col>
    </FormGroup>
  )
}

export default ChangePasswordCheckboxInput;
