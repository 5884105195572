import React from "react";
import PropTypes from "prop-types";
import Error from "@shared/Error";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

const DeletePlanModal = ({
  isDeletePlanModalOpen,
  isDeletingPlan,
  closeModal,
  onPlanDelete,
  hasError,
  errorMessage,
}) => (
  <Modal
    show={isDeletePlanModalOpen}
    onHide={() => closeModal()}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
    contentClassName="tw-w-[480px] tw-space-y-32px tw-m-auto"
  >
    <Modal.Header title="Do you really want to delete this plan from this contact?" closeButton />
    <Modal.Body>
      {hasError && <Error errorMessage={errorMessage} />}
      <p>
        Deleting an auto plan from a contact will delete all incomplete tasks and interactions generated by
        this plan. You are still able to keep track of all completed tasks.
      </p>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button schema="secondary" size="medium" onClick={() => closeModal()}>
        Cancel
      </Button>
      <Button schema="warning" size="medium" onClick={() => onPlanDelete()} isLoading={isDeletingPlan}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeletePlanModal;

DeletePlanModal.propTypes = {
  isDeletePlanModalOpen: PropTypes.bool.isRequired,
  isDeletingPlan: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onPlanDelete: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
