/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Download = (props) => (
  <Icon {...props} name="Download">
    <path d="M6.824.471h2.353c.391 0 .706.315.706.706v4.941h2.579c.524 0 .785.632.415 1.003l-4.474 4.477a.57.57 0 0 1-.803 0L3.121 7.121a.587.587 0 0 1 .415-1.003h2.582V1.177c0-.391.315-.706.706-.706zm8.705 11.058v3.294a.705.705 0 0 1-.706.706H1.176a.705.705 0 0 1-.706-.706v-3.294c0-.391.315-.706.706-.706h4.315l1.441 1.441a1.507 1.507 0 0 0 2.135 0l1.441-1.441h4.315c.391 0 .706.315.706.706zm-3.647 2.589c0-.323-.265-.588-.588-.588s-.588.265-.588.588c0 .324.265.588.588.588s.588-.265.588-.588zm1.883 0c0-.323-.265-.588-.588-.588s-.588.265-.588.588c0 .324.265.588.588.588s.588-.265.588-.588z" />
  </Icon>
);

export default Download;
