import React from "react";
import { sortBy } from "lodash";
import { func, shape, string } from "prop-types";

import useFetch from "./hooks";

import Dropdown from "../../shared/v2/Dropdown";

export const DropdownFromAPI = ({ url, value, setValue, onSuccess, onError }) => {
  const options = useFetch(url, [], onSuccess, onError);

  return <Dropdown options={options} onChange={setValue} value={value} isSearchable />;
};

const onSuccess = (data, setState) => {
  const adaptedResponse = data.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`.trim(),
    value: id,
  }));

  setState(sortBy(adaptedResponse, "label"));
};

const DropdownTeamMembers = ({ value, setValue }) => (
  <div className="tw-my-[8px]">
    <DropdownFromAPI
      url="/api/v4/account_people?active_agents=true"
      value={value}
      setValue={setValue}
      onSuccess={onSuccess}
    />
  </div>
);

export default DropdownTeamMembers;

DropdownTeamMembers.propTypes = {
  value: shape({}),
  setValue: func.isRequired,
};

DropdownTeamMembers.defaultProps = {
  value: null,
};

DropdownFromAPI.propTypes = {
  url: string,
  value: shape({}),
  onSuccess: func,
  onError: func,
  setValue: func.isRequired,
};

DropdownFromAPI.defaultProps = {
  url: null,
  value: null,
  onSuccess: () => {},
  onError: () => {},
};
