import React from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const {
  BACKGROUND_COLOR_CLASSES,
  INTERACTION_BACKGROUND_COLOR_CLASSES,

  BORDER_COLOR_CLASSES,
  INVERTED_BORDER_COLOR_CLASSES,
  DISABLED_BORDER_COLOR_CLASSES,

  TEXT_CLASSES_CLASSES,
  TEXT_COLOR_CLASSES,

  ADDITIONAL_CLASSES,
  SIZE_CLASSES,
  DISPLAY_CLASSES,

  BASE_CLASSES,
} = CLASSES;

const sizeClasses = (size) => SIZE_CLASSES[size];

const displayClasses = (display) => DISPLAY_CLASSES[display];

const backgroundColorClasses = ({ variant, invert }) => {
  const bgColorVariant = invert ? "inverted" : variant;

  let interactionBgColorVariant = bgColorVariant;

  // Edge case. "default" variant does not respect the usual inverted
  // background color behavior but instead overrides their own.
  if (invert && variant === "default") {
    interactionBgColorVariant = "invertedDefault";
  }

  return [
    BACKGROUND_COLOR_CLASSES[bgColorVariant],
    INTERACTION_BACKGROUND_COLOR_CLASSES[interactionBgColorVariant],
  ].join(" ");
};

const borderColorClasses = ({ variant, invert }) => {
  // No border.
  if (variant === "simple") {
    return "";
  }

  // Again, the "default" variant does not respect the usual inverted border
  // color behavior but instead overrides their own.
  if (invert && variant === "default") {
    return BORDER_COLOR_CLASSES[variant];
  }

  if (invert) {
    return INVERTED_BORDER_COLOR_CLASSES[variant];
  }

  return [BORDER_COLOR_CLASSES[variant], DISABLED_BORDER_COLOR_CLASSES[variant]].join(" ");
};

const textColorClasses = ({ variant, invert }) => {
  if (invert) {
    // And again, "default" variant does not respect the usual inverted text
    // styles.
    if (variant === "default") {
      return TEXT_COLOR_CLASSES.invertedDefault;
    }

    return TEXT_COLOR_CLASSES.inverted;
  }

  if (["primary", "success", "info", "warning", "danger"].includes(variant)) {
    return TEXT_COLOR_CLASSES.common;
  }

  return TEXT_COLOR_CLASSES[variant];
};

const textStyleClasses = (variant) => {
  if (variant === "link") {
    return TEXT_CLASSES_CLASSES[variant];
  }

  return TEXT_CLASSES_CLASSES.common;
};

const additionalClasses = (variant) => {
  if (variant in ADDITIONAL_CLASSES) {
    return ADDITIONAL_CLASSES[variant];
  }

  return "";
};

const variantClasses = ({ variant, invert }) =>
  [
    backgroundColorClasses({ variant, invert }),
    borderColorClasses({ variant, invert }),
    textColorClasses({ variant, invert }),
    textStyleClasses(variant),
    additionalClasses(variant),
  ].join(" ");

export const Button = React.forwardRef(
  ({ children, className, size, display, variant, invert, ...props }, ref) => {
    const classes = `
      ${BASE_CLASSES}
      ${className}
      ${variantClasses({ variant, invert })}
      ${sizeClasses(size)}
      ${displayClasses(display)}
    `;

    return (
      <button ref={ref} type="button" className={classes} {...props}>
        {children}
      </button>
    );
  },
);
Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(["large", "small", "extraSmall", "default"]),
  display: PropTypes.oneOf(["inline", "block"]),
  variant: PropTypes.oneOf([
    "default",
    "info",
    "primary",
    "success",
    "warning",
    "danger",
    "link",
    "inverted",
  ]),
  invert: PropTypes.bool,
  disabled: PropTypes.bool,
};
Button.defaultProps = {
  children: null,
  className: "",
  size: "default",
  display: "inline",
  variant: null,
  invert: false,
  disabled: false,
};

export default Button;
