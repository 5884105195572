import React, { useState, useRef, useEffect } from "react";
import Proptypes from "prop-types";
import { Row } from "../../../../shared/v1";
import Cards from "./Cards";
import IconButton from "../../../../shared/v2/IconButton";
import ChevronRight from "../../../../shared/v2/Icomoon/Icons/ChevronRight";
import ChevronLeft from "../../../../shared/v2/Icomoon/Icons/ChevronLeft";

const ListingCards = ({ carddata }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [activeSlide]);
  const moveLeft = Math.max(0, activeSlide - 2);
  const moveRight = Math.min(carddata.length - 1, activeSlide + 2);
  return (
    <div className="tw-relative">
      {activeSlide > 0 && (
        <IconButton
          className="tw-z-10 tw-absolute tw-left-0 tw-top-2/4 tw-translate-y-1/2"
          onClick={() => setActiveSlide(moveLeft)}
          size="medium"
          schema="tertiary"
        >
          <ChevronLeft size="m" />
        </IconButton>
      )}
      <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-w-full	tw-h-auto	tw-flex tw-flex-nowrap tw-overflow-x-hidden tw-overflow-y-hidden tw-scroll-smooth">
        {carddata.map((carddetails, index) => {
          const active = index === activeSlide;
          return (
            /* eslint-disable react/no-array-index-key */
            <div
              className={`tw-rounded tw-border-solid tw-border tw-border-gray-10 tw-mx-[10px] tw-my-[10px] slide ${
                active ? "active" : ""
              }`}
              ref={active ? ref : null}
              id={`slide-${index}`}
              key={`slide-${index}`}
            >
              <Cards
                name={carddetails.name}
                numberOfPages={carddetails.number_of_pages}
                createdBy={carddetails.created_by}
                coverImage={carddetails.cover_image}
              />
            </div>
          );
        })}
      </Row>
      {activeSlide < carddata.length - 1 && (
        <IconButton
          className="tw-z-10 tw-absolute tw-right-0	tw-top-2/4 tw-translate-y-1/2"
          onClick={() => setActiveSlide(moveRight)}
          size="medium"
          schema="tertiary"
        >
          <ChevronRight size="m" />
        </IconButton>
      )}
    </div>
  );
};
ListingCards.propTypes = {
  carddata: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
    }),
  ),
};
ListingCards.defaultProps = {
  carddata: [],
};

export default ListingCards;
