/* global $, Turbolinks */
import React from "react";
import axios from "axios";
import Button from "../shared/v2/Button/Button";
import { PlusSolidV6 } from "../shared/v2/Icomoon";

const onClick = () => {
  // TODO: remove jQuery bootstrap reqs
  axios.get("/projects/new").then((res) => {
    const $modal = $("#new_modal");
    $modal.find(".modal-content").html(res.data);
    $modal
      .find("form")
      .on("ajax:error", (e, data) => {
        Brivity.ModalErrors.show(data.responseJSON.content);
      })
      .on("ajax:success", (e, data) => {
        Brivity.Animations.success($modal);
        Turbolinks.visit(data.location);
      });
    $modal.find(".selectpicker").selectpicker();
    $modal.modal("toggle");
  });
};

const NewProjectModal = () => (
  <Button onClick={onClick} size="medium">
    <PlusSolidV6 /> New
  </Button>
);

export default NewProjectModal;
