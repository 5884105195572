import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { MagnifyingGlassSolidV6, PencilSolidV6 } from "../../shared/v2/Icomoon";
import IconButton from "../../shared/v2/IconButton/IconButton";
import TextInput from "../../shared/v2/TextInput/TextInput";
import MenuOption from "./MenuOption";
import SmartFilterModal from "./SmartFilterModal";
import { isSmartFilters } from "../helpers/menu";
import useMobileMenu from "./hooks/useMobileMenu";
import BackButton from "./BackButton";
import useCounts from "./hooks/useCounts";

export const MenuBase = ({ children, className }) => (
  <div
    className={`tw-w-full tw-h-full tw-relative tw-flex tw-flex-col tw-transition-all tw-duration-300 tw-ease-in-out ${className}`}
  >
    {children}
  </div>
);

MenuBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
MenuBase.defaultProps = {
  className: "",
};

export const MenuTitle = ({ title, action, backTitle, onBack }) => (
  <div className="tw-flex-grow-0">
    {onBack && <BackButton onClick={onBack}>{backTitle}</BackButton>}
    <div className="tw-flex tw-flex-grow-0 tw-justify-between tw-items-center tw-ml-[8px]">
      <h2 className="tw-text-18d tw-font-bold tw-mt-[8px]">{title}</h2>
      {action}
    </div>
  </div>
);

MenuTitle.propTypes = {
  title: PropTypes.string.isRequired,
  backTitle: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onBack: PropTypes.func,
};
MenuTitle.defaultProps = {
  action: null,
  onBack: undefined,
  backTitle: "",
};

const Menu = ({ title, action, backTitle, counts: overrideCounts, options, onBack }) => {
  const {
    actions,
    data: { isMobile },
  } = useMobileMenu();
  const prevTitle = useRef(title);
  const { counts } = useCounts(title);
  const theme = useSelector(({ layout }) => layout.theme);
  const [submenu, setSubmenu] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState("");
  const [optionToDelete, setOptionToDelete] = useState(null);
  const regexp = new RegExp(search, "i");
  const smartFilterAction = isSmartFilters(title) ? (
    <IconButton
      onClick={() => setIsEditing((v) => !v)}
      schema={isEditing ? "primary" : "tertiary"}
      size="small"
    >
      <PencilSolidV6 />
    </IconButton>
  ) : null;

  useEffect(() => {
    if (prevTitle.current && prevTitle.current !== title) setSubmenu(null);
    prevTitle.current = title;
  }, [title, prevTitle]);

  useEffect(() => {
    actions.setIsSubmenuOpen(Boolean(submenu));
  }, [submenu]);

  return (
    <MenuBase className={submenu ? "tw-left-[calc(-100%-12px)]" : "tw-left-0"}>
      <MenuTitle
        title={title}
        action={submenu ? null : action || smartFilterAction}
        backTitle={backTitle}
        onBack={
          onBack
            ? () => {
                onBack();
                setIsEditing(false);
              }
            : null
        }
        theme={theme}
      />
      {isSmartFilters(title) && (
        <div className="tw-flex tw-flex-col tw-gap-[12px]">
          <div className="tw-relative">
            <TextInput
              className="tw-ring-inset tw-rounded-[8px] !tw-bg-gray/10 !tw-text-inherit"
              placeholder="Search filters"
              onChange={(e) => setSearch(e.target.value)}
            />
            <MagnifyingGlassSolidV6
              className="tw-absolute tw-text-gray tw-right-[6px] tw-top-[calc(50%-8px)]"
              size="l"
            />
          </div>
          <div className="tw-mb-[12px] tw-border-solid tw-border-t-0 tw-border-gray/15" />
        </div>
      )}
      <div
        className={`nav-scrollbar tw-mb-[24px] tw-flex tw-flex-col tw-gap-[2px] tw-overflow-y-auto ${
          isMobile ? "" : "tw-w-[200px]"
        }`}
      >
        {options?.map((option, i) =>
          regexp.test(option.name) ? (
            <MenuOption
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              counts={overrideCounts || counts}
              option={option}
              Menu={Menu}
              title={title}
              isEditing={isEditing}
              isMobile={isMobile}
              submenu={submenu}
              onClick={() => setSubmenu(option.name)}
              onBack={() => setSubmenu(null)}
              onDelete={() => setOptionToDelete(option)}
            />
          ) : null,
        )}
      </div>
      <SmartFilterModal option={optionToDelete} onClose={() => setOptionToDelete(null)} />
    </MenuBase>
  );
};

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  backTitle: PropTypes.string,
  action: PropTypes.node,
  counts: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  onBack: PropTypes.func,
};

Menu.defaultProps = {
  action: null,
  onBack: undefined,
  backTitle: "",
  counts: null,
  options: null,
};

export default Menu;
