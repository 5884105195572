/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  selectedTasks: state.tdpTasksReducer.selectedTasks,
});

// Wrapping any component with these props from redux ^^
const withSelectedTasks = (WrappedComponent) =>
  connect(mapStateToProps)(({ selectedTasks, ...otherProps }) => (
    <WrappedComponent {...otherProps} selectedTasks={selectedTasks} />
  ));

export default withSelectedTasks;
