/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentSolidV6 = (props) => (
  <Icon {...props} name="Comment Solid V6">
    <path d="M8 1C3.581 1 .001 3.909.001 7.5c0 1.551.667 2.968 1.78 4.084C1.39 13.158.085 14.561.069 14.577a.243.243 0 0 0-.047.273.24.24 0 0 0 .227.15c2.07 0 3.597-.993 4.394-1.606A9.526 9.526 0 0 0 7.999 14c4.419 0 7.972-2.91 7.972-6.5S12.418 1 7.999 1z" />
  </Icon>
);

export default CommentSolidV6;
