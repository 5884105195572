/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ManageAccessModal from "./ManageAccessModal";
import SuccessModal from "./SuccessModal";
import { updatePrimaryAgentOrPond, getPrimaryAgentOrPond } from "../../shared/serviceHelpers";
import { selectOption } from "../../LeadIndex/components/SelectOption";

const ManageAccess = () => {
  const [ModalOpen, setModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [people, setPeople] = useState([]);
  const [agents, setAgents] = useState([]);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [leadPond, setLeadPond] = useState(null);
  const [primaryAgent, setPrimaryAgent] = useState(null);
  const [agentPermission, setAgentPermission] = useState({});
  const [successmodal, setSuccessModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const closeModal = () => {
    setAdmins([]);
    setAgents([]);
    setPeople([]);
    setSelectedIds([]);
    setLeadPond(null);
    setPrimaryAgent(null);
    setAgentPermission({});
    setSubmitResponse(null);
    setModalOpen(false);
    setSaving(false);
    setSuccessModal(false);
    selectOption("select-none");
  };

  const getData = (allpeopleIdsStr) => {
    getPrimaryAgentOrPond({
      allpeopleIdsStr,
      successCb: (response) => {
        setModalOpen(true);
        setPeople(response.data.people);
        setAdmins(response.data.admins);
        const totalAgent = response.data.agents
          .concat(response.data.lead_ponds || [])
          .map((obj) =>
            obj.current_access_level === "some" ? { ...obj, issome: true } : { ...obj, issome: false },
          );
        setAgents(totalAgent.sort((a, b) => a.name.localeCompare(b.name)));
      },
    });
  };
  const getSelectedIds = () => {
    const ids = [];
    document.querySelectorAll("input.multi-select:checked").forEach((item) => {
      ids.push(item.closest("tr").getAttribute("data-person-id")); // eslint-disable-line no-param-reassign
    });
    if (ids.length > 0) {
      setSubmitResponse(null);
      let allpeopleIdsStr = "";
      /* eslint-disable no-plusplus */
      for (let index = 0; index < ids.length; index++) {
        const element = ids[index];
        if (index === 0) {
          allpeopleIdsStr += `people_ids[]=${element}`;
        } else {
          allpeopleIdsStr += `&people_ids[]=${element}`;
        }
      }
      setSelectedIds(ids);
      getData(allpeopleIdsStr);
    }
  };
  const openModal = (e) => {
    if (e?.target?.className?.indexOf("disabled") > -1) {
      e.preventDefault();
      return;
    }
    getSelectedIds();
  };

  const radioGroupHandler = (name, isAdmin, isPond, label) => {
    if (isPond) {
      const newState = agents.map((obj) =>
        obj.id === name ? { ...obj, is_selected: true } : { ...obj, is_selected: false },
      );
      const newStateforadmin = admins.map((obj) => ({ ...obj, is_selected: false }));
      setAdmins(newStateforadmin);
      setPrimaryAgent(null);
      setLeadPond(name);
      setAgents(newState);
    } else if (isAdmin) {
      const newState = admins.map((obj) =>
        obj.person_id === name ? { ...obj, is_selected: true } : { ...obj, is_selected: false },
      );
      const newStateforagent = agents.map((obj) => ({ ...obj, is_selected: false }));
      setAgents(newStateforagent);
      setLeadPond(null);
      setPrimaryAgent(name);
      setAdmins(newState);
    } else if (label) {
      let newCurrentAccessLevel = "";
      if (label === "All") {
        newCurrentAccessLevel = "all";
      } else if (label === "None") {
        newCurrentAccessLevel = "none";
      } else {
        newCurrentAccessLevel = "some";
      }
      const newState = agents.map((obj) =>
        obj.person_id === name ? { ...obj, current_access_level: newCurrentAccessLevel } : obj,
      );
      setAgentPermission({ ...agentPermission, [name]: newCurrentAccessLevel });
      setAgents(newState);
    } else {
      const newState = agents.map((obj) =>
        obj.person_id === name ? { ...obj, is_selected: true } : { ...obj, is_selected: false },
      );
      const newStateforadmin = admins.map((obj) => ({ ...obj, is_selected: false }));
      setAdmins(newStateforadmin);
      setAgentPermission({
        ...agentPermission,
        [name]: newState.filter((obj) => obj.person_id === name)[0].current_access_level,
      });
      setLeadPond(null);
      setPrimaryAgent(name);
      setAgents(newState);
    }
  };
  const handleSubmit = () => {
    setSaving(true);
    const params = {
      primary_agent: primaryAgent,
      people_ids: selectedIds,
      lead_pond: leadPond,
      agent_permissions: agentPermission,
    };
    updatePrimaryAgentOrPond({
      data: params,
      errorCb: (errors) => {
        setSaving(false);
        setSubmitResponse(errors);
      },
      successCb: (response) => {
        setSaving(false);
        setAdmins([]);
        setAgents([]);
        setPeople([]);
        setSelectedIds([]);
        setLeadPond(null);
        setPrimaryAgent(null);
        setAgentPermission({});
        setModalOpen(false);
        if (response?.data?.content) {
          setSuccessModal(true);
          setSubmitResponse(response.data);
        }
      },
    });
  };
  return (
    <>
      <a
        role="button"
        tabIndex={0}
        id="extend-access"
        className="tw-clear-both hover:tw-no-underline tw-block tw-text-gray-dark hover:tw-text-gray-dark hover:tw-bg-gray-5"
        onClick={openModal}
      >
        Manage Access
      </a>
      {ModalOpen && (
        <ManageAccessModal
          people={people}
          admins={admins}
          modalOpen={ModalOpen}
          agents={agents}
          closeModal={closeModal}
          radioGroupHandler={radioGroupHandler}
          handleSubmit={handleSubmit}
          submitResponse={submitResponse}
          saving={saving}
        />
      )}
      {successmodal && (
        <SuccessModal modalOpen={successmodal} closeModal={closeModal} submitResponse={submitResponse} />
      )}
    </>
  );
};

export default ManageAccess;
