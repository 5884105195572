import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { IncomeExpenseForm } from '../shared/IncomeExpenseForm';
import { fullRecalculation } from '../shared/FinancialViewHelpers';
import Error from '../../../shared/Error';

class AddIncomeExpenseModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      name: '',
      transaction_expense_type_id: '',
      value: '',
      percentage: true,
      type: 'expense',
      position: '',
      isNotValid: false,
      errMsg: '',
      subKey: `x${new Date().getTime()}`,
    }
  }

  saveData = () => {
    let nonDeletedAdditionals = this.props.listingData.transaction_income.additionalIncome.filter(additional => additional._destroy !== '1')
    this.state.position = nonDeletedAdditionals.length + 1;
    if (this.state.type === 'expense') {
      if (!this.state.percentage) {
        this.state.expense_amount = this.state.value;
      }
      this.props.listingData.transaction_income.custom_transaction_expenses_attributes.push(this.state);
    }
    if (this.state.type === 'revenue') {
      if (!this.state.percentage) {
        this.state.revenue_amount = this.state.value;
      }
      this.props.listingData.transaction_income.custom_transaction_revenues_attributes.push(this.state);
    }
    this.props.listingData.transaction_income.additionalIncome.push(this.state);
    fullRecalculation(this.props.listingData, this.props.setFinancial);
    this.props.setIsOpenNewIncomeModal(false);
  }

  saveExpenseData = () => {
    if (this.state.name && this.state.value) {
      if (this.state.percentage) {
        if (this.state.value >= 0 && this.state.value <= 100) {
          this.saveData()
        } else {
          this.setState({
            errMsg: 'Value must be between 0 and 100.',
            isNotValid: true
          });
        }
      } else {
        this.saveData()
      }
    } else {
      this.setState({
        errMsg: 'Enter a name and value',
        isNotValid: true
      });
    }
  }

  render() {
    const {
      setIsOpenNewIncomeModal,
      isOpenNewIncomeModal,
      listingData,
    } = this.props;

    const { 
      transaction_expense_types: transactionExpenseList
    } = listingData

    return (
      <Modal
        show={isOpenNewIncomeModal}
        onHide={() => setIsOpenNewIncomeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            New Income Or Expense
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='auto-plans-form transaction-gci-content'>
          <div className='transaction-gci-options transaction-modal tw-border-0'>
            {this.state.isNotValid && <Error errorMessage={this.state.errMsg} />}
            <Row>
              <Col xs={12}>
                <p>This is unique to this transaction and will not be repeated</p>
              </Col>
            </Row>
            
            <IncomeExpenseForm
              addIncome
              modalThis={this}
              expList={transactionExpenseList}
              expenseType='transaction'
              setIsOpenNewIncomeModal={setIsOpenNewIncomeModal}/>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddIncomeExpenseModal;
