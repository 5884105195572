/* global tinyMCE */
import React, { useEffect, useState } from "react";
import "@brivity/ai-components/dist/style.css";
import { AiAssist, AiComponentsProvider } from "@brivity/ai-components";
import useAiToken from "./hooks/useAiToken";

const AiAssistant = ({
  align,
  contactUuid,
  context,
  messageType = "Generic",
  onInsertClick,
  side,
  textareaId,
  isTinyMce,
}) => {
  const [tinyMceDocument, setTinyMceDocument] = useState(null);
  const { token, apiUrl } = useAiToken();

  useEffect(() => {
    if (!isTinyMce || tinyMceDocument) return;
    const timeoutId = setTimeout(() => setTinyMceDocument(tinyMCE.activeEditor.contentDocument), 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isTinyMce, tinyMceDocument]);

  if (!token) return null;

  return (
    <AiComponentsProvider
      apiUrl={apiUrl}
      theme={document.body.classList.contains("place-theme") ? "ai-place" : "ai-brivity"}
      token={token}
    >
      <AiAssist
        align={align}
        contactUuid={contactUuid}
        context={context}
        messageType={messageType}
        onInsertClick={onInsertClick}
        side={side}
        textareaId={textareaId}
        tinyMceDocument={tinyMceDocument}
      />
    </AiComponentsProvider>
  );
};

export default AiAssistant;
