import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isCancel } from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import { addRecipient } from "../../reducers/messagingReducer";
import { useRecipientsHash } from "../../reducers/messagingReducer/selectors";

const useRecipientNumbers = ({ recipient }) => {
  const dispatch = useDispatch();
  const recipientsHash = useRecipientsHash();
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState(null);
  const [number, setNumber] = useState(recipientsHash[recipient.value]?.number);

  useEffect(() => {
    if (!number) return;
    dispatch(addRecipient({ recipient, number }));
  }, [recipient, number]);

  useEffect(() => {
    if (number || !numbers) return;
    const selectedNumber = numbers.find(({ meta }) => meta.primary && !meta.smsUnsubscribed) ||
      numbers.find(({ meta }) => !meta.smsUnsubscribed) ||
      numbers[0] || { empty: true };
    setNumber(selectedNumber);
  }, [numbers]);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    caseTransformingAxios
      .get(`/api/v4/leads/${recipient.value}/phones`, { signal: abortController.signal })
      .then((res) => {
        setLoading(false);
        setNumbers(
          res.data.reduce((acc, meta) => {
            const data = {
              label: formatPhoneNumber(meta.canonicalNumber || meta.phoneNumber),
              value: meta.canonicalNumber || meta.phoneNumber,
              meta: viewPhoneFromFlat({
                ...meta,
                cononicalValue: meta.cononicalNumber,
                value: meta.phoneNumber,
                position: meta.primary ? 1 : undefined,
              }),
            };
            if (data.value) acc.push(data);
            return acc;
          }, []),
        );
      })
      .catch((err) => {
        if (isCancel(err)) return;
        setLoading(false);
      });
    return () => abortController.abort();
  }, []);

  return { actions: { setNumber }, data: { numbers, number }, loading };
};

export default useRecipientNumbers;
