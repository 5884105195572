import React from "react";
import { string } from "prop-types";

const PropertyType = ({ propertyType, className }) => (
  <div
    className={`tw-mt-[1px] tw-text-[14px] tw-font-semibold tw-leading-[20px] tw-tracking-normal tw-text-neutral-gray-50 tw-capitalize ${className}`}
  >
    {propertyType}
  </div>
);

PropertyType.propTypes = {
  propertyType: string.isRequired,
  className: string,
};

PropertyType.defaultProps = {
  className: "",
};

export default PropertyType;
