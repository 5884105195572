import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_NAME = "TDP_TIMELINE_REDUCER";
const wrap = curriedNamespaceAction(REDUCER_NAME);

export const saveTimeline = (timeline, tags) => wrap({ type: "SAVE_TIMELINE", timeline, tags });

export const setActiveFilter = (tagName) => wrap({ type: "SET_ACTIVE_FILTER", tagName });

export const setEventsLoadingOn = () => wrap({ type: "SET_EVENTS_LOADING_ON" });

export const setEventsLoadingOff = () => wrap({ type: "SET_EVENTS_LOADING_OFF" });
