import React, { useEffect, useState } from "react";
import { number, shape, string, func, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import MlsListingDropdown from "@shared/MlsListingDropdown";
import { ArrowLeft } from "@shared/v2/Icomoon";
import useMlsListingDetails from "@shared/MlsListingDropdown/useMlsListingDetails";
import { mergeCompareObjects, setDefaultValues } from "../api/helpers";
import { allKeys } from "../api/constants";
import * as thunkActions from "../actions/thunks";
import ListingPreviewCard from "../../../../Transactions/ListingPreviewCard";
import ReviewImportListingDetails from "./ReviewImportListingDetails";

const getPropertyId = (accountPropertyTypes, labelToFind) => {
  const property = accountPropertyTypes.find((p) => p.label === labelToFind);
  return property ? property.value : null;
};

const addToPayload = (payload, payloadProperty, isAddressAttr, isDetailAttr, propertyValue) => {
  if (isAddressAttr) {
    if (!Object.prototype.hasOwnProperty.call(payload, "address_attributes")) {
      // eslint-disable-next-line no-param-reassign
      payload.address_attributes = {};
      // eslint-disable-next-line no-param-reassign
      payload.address_attributes.address_type = "listing";
    }
    // eslint-disable-next-line no-param-reassign
    payload.address_attributes[payloadProperty] = propertyValue;
  } else if (isDetailAttr) {
    if (!Object.prototype.hasOwnProperty.call(payload, "details")) {
      // eslint-disable-next-line no-param-reassign
      payload.details = [];
    }
    payload.details.push({ name: payloadProperty, value: propertyValue });
  } else {
    // eslint-disable-next-line no-param-reassign
    payload[payloadProperty] = propertyValue;
  }
  return payload;
};

const setProperty = (payload, data, listingProperty, payloadProperty, isAddressAttr, isDetailAttr) => {
  if (!data[listingProperty]) return undefined;
  const mlsProperty = data[listingProperty][`mls_${listingProperty}`];
  const currentProperty =
    data[listingProperty][`current_${listingProperty}`] === "—"
      ? null
      : data[listingProperty][`current_${listingProperty}`];

  const { isSelectable, mlsChecked } = data[listingProperty];

  if (isSelectable) {
    if (mlsChecked) {
      addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, mlsProperty);
    } else if (isAddressAttr) {
      addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, currentProperty);
    }
  } else if (mlsProperty !== "—") {
    addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, mlsProperty);
  } else if (isAddressAttr) {
    addToPayload(payload, payloadProperty, isAddressAttr, isDetailAttr, currentProperty);
  }
  return payload;
};

const ImportListingDetailsModal = ({
  modalOpen,
  isFromDetail,
  closeModal,
  setshowAlert,
  uuid,
  isFromIndex,
  listingUuid,
  onGetAllDetailsAsThunk,
  onGetPropertyTypesAsThunk,
  importDetailAsThunk,
  photosAvailable,
  propertyDetails,
  accountPropertyTypes,
  listingDetailsData,
  checkAddressAndcloseModal,
}) => {
  const [selectedBlossorId, setSelectedBlossorId] = useState(null);
  const [openReview, setOpenReview] = useState(false);
  const [listingData, setListingData] = useState(null);
  const mlsListingDetails = useMlsListingDetails(selectedBlossorId);
  const updatedMlsListingDetails = mlsListingDetails.data
    ? {
        streetAddress: mlsListingDetails.data?.streetAddress,
        postalCode: mlsListingDetails.data?.postalCode,
        price: mlsListingDetails.data?.price,
        mlsNumber: mlsListingDetails.data?.mlsNum,
        beds: mlsListingDetails.data?.bedrooms,
        baths: mlsListingDetails.data?.bathsTotalDecimal,
        sqFt: mlsListingDetails.data?.squareFeet,
        city: mlsListingDetails.data?.city,
        state: mlsListingDetails.data?.state,
        county: mlsListingDetails.data?.county,
        property_type: mlsListingDetails.data?.propertyType,
        neighborhood: mlsListingDetails.data?.neighborhood,
        year_built: mlsListingDetails.data?.year,
        parking: String(mlsListingDetails.data?.parkingFeatures || []),
        photosAvailable: mlsListingDetails.data?.photosAvailable,
        photos: mlsListingDetails.data?.photos,
        description: mlsListingDetails.data?.remarks,
        blossorId: mlsListingDetails.data?.blossorId,
      }
    : null;

  useEffect(() => {
    if (isFromIndex && listingUuid) {
      onGetAllDetailsAsThunk(listingUuid);
      onGetPropertyTypesAsThunk(listingUuid);
    }
  }, [isFromIndex]);

  const handleReview = () => {
    const currentListingData = {
      ...propertyDetails,
      ...listingDetailsData,
      streetAddress: propertyDetails.street_address,
      sqFt: propertyDetails.sqft,
      postalCode: propertyDetails.zip,
      property_type: propertyDetails?.property_type?.name,
      photosAvailable,
      mlsNumber: listingDetailsData.mls_number,
    };

    const comparableListingData = mergeCompareObjects(
      setDefaultValues(updatedMlsListingDetails, allKeys),
      setDefaultValues(currentListingData, allKeys),
    );
    setListingData(comparableListingData);
    setOpenReview(true);
  };

  const handleImport = () => {
    const payloadListing = {};
    setProperty(payloadListing, listingData, "property_type", "property_type");
    setProperty(payloadListing, listingData, "price", "price");
    setProperty(payloadListing, listingData, "mlsNumber", "mls_number");
    setProperty(payloadListing, listingData, "description", "description");
    setProperty(payloadListing, listingData, "photosAvailable", "photosAvailable");
    setProperty(payloadListing, listingData, "streetAddress", "street_address", true);
    setProperty(payloadListing, listingData, "city", "city", true);
    setProperty(payloadListing, listingData, "state", "locality", true);
    setProperty(payloadListing, listingData, "postalCode", "postal_code", true);
    setProperty(payloadListing, listingData, "county", "county", true);
    setProperty(payloadListing, listingData, "neighborhood", "neighborhood", true);
    setProperty(payloadListing, listingData, "beds", "beds", false, true);
    setProperty(payloadListing, listingData, "baths", "baths", false, true);
    setProperty(payloadListing, listingData, "sqFt", "sqft", false, true);
    setProperty(payloadListing, listingData, "year_built", "year_built", false, true);
    setProperty(payloadListing, listingData, "parking", "parking", false, true);

    if (payloadListing.property_type) {
      payloadListing.property_type_id = getPropertyId(accountPropertyTypes, payloadListing.property_type);
      delete payloadListing.property_type;
    }
    if (payloadListing.photosAvailable) {
      payloadListing.blossor_id = mlsListingDetails.data.blossorId;
      payloadListing.use_mls_photos = true;
      delete payloadListing.photosAvailable;
    }
    const userUUID = isFromIndex && listingUuid ? listingUuid : uuid;
    importDetailAsThunk(userUUID, payloadListing, setshowAlert);
    closeModal();
  };

  const handleBack = () => {
    setOpenReview(false);
  };

  const handleClose = () => {
    if (isFromDetail) {
      closeModal();
    } else {
      closeModal();
      checkAddressAndcloseModal();
    }
  };

  const clearListing = () => {
    setOpenReview(false);
    mlsListingDetails.actions.clear();
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 636px = 616px from Figma + 10px for each horizontal padding (20px)
      dialogClassName="tw-w-[636px] tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      show={modalOpen}
      onHide={handleClose}
      data-cy="import-listing-detail-modal"
    >
      <Modal.Header
        titleClassName="tw-mb-32px tw-text-neutral-gray-75"
        title="Import Listing Details"
        closeButton
      />

      <Modal.Body className="tw-mb-32px">
        {!openReview && !mlsListingDetails.data && (
          <MlsListingDropdown
            onChange={(option) => setSelectedBlossorId(option.value)}
            placeholder="Search by Listing Address or MLS #"
          />
        )}

        {!openReview && mlsListingDetails.data && (
          <ListingPreviewCard
            containerClassName="tw-w-[392px]"
            listingData={mlsListingDetails.data}
            clearListing={clearListing}
          />
        )}

        {openReview && (
          <ReviewImportListingDetails
            containerClassName="tw-w-[632px] tw-h-[510px]"
            setListingData={setListingData}
            listingData={listingData}
          />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button
          onClick={openReview ? handleBack : handleClose}
          className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
          schema="tertiary"
          size="medium"
          data-cy="documents-upload-cancel-button"
        >
          {openReview ? (
            <>
              <ArrowLeft />
              <span className="tw-pl-[7px]">Back</span>
            </>
          ) : (
            "Cancel"
          )}
        </Button>
        <Button
          className="tw-flex tw-items-center"
          size="medium"
          schema="primary"
          disabled={!mlsListingDetails.data}
          onClick={openReview ? handleImport : handleReview}
          data-cy="import-listing-detail-review-button"
        >
          {openReview ? "Import" : "Review"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImportListingDetailsModal.propTypes = {
  modalOpen: bool,
  isFromDetail: bool,
  isFromIndex: bool,
  listingUuid: string,
  closeModal: func,
  checkAddressAndcloseModal: func,
  setshowAlert: func,
  uuid: string,
  onGetAllDetailsAsThunk: func.isRequired,
  onGetPropertyTypesAsThunk: func.isRequired,
  importDetailAsThunk: func.isRequired,
  photosAvailable: number,
  listingDetailsData: shape({
    mls_number: string,
    price: string,
    description: string,
  }),
  accountPropertyTypes: arrayOf(shape({ label: string, value: number })),
  propertyDetails: shape({
    street_address: string,
    city: string,
    state: string,
    zip: string,
    neighborhood: string,
    county: string,
    baths: string,
    beds: string,
    sqft: string,
    year_built: string,
    property_type: shape({ id: number, name: string }),
  }),
};

ImportListingDetailsModal.defaultProps = {
  modalOpen: false,
  isFromDetail: false,
  isFromIndex: false,
  listingUuid: null,
  closeModal: () => {},
  checkAddressAndcloseModal: () => {},
  setshowAlert: () => {},
  photosAvailable: 0,
  listingDetailsData: {
    mls_number: "",
    price: "",
    description: "",
  },
  uuid: null,
  accountPropertyTypes: [],
  propertyDetails: shape({
    street_address: null,
    city: null,
    state: null,
    zip: null,
    neighborhood: null,
    county: null,
    baths: null,
    beds: null,
    sqft: null,
    year_built: null,
    property_type: { id: null, name: null },
  }),
};

const mapStateToProps = (state) => ({
  accountPropertyTypes: state.tdpDetailsReducer.meta.accountPropertyTypes,
  listingDetailsData: state.tdpDetailsReducer.listingDetails,
  uuid: state.tdpDetailsReducer.uuid,
  photosAvailable: state.tdpDetailsReducer.listingImgs.length,
  propertyDetails: state.tdpDetailsReducer.propertyDetails,
});

const mapDispatchToProps = (dispatch) => ({
  importDetailAsThunk: (uuid, payload, setshowAlert) =>
    dispatch(thunkActions.importDetailAsThunk(uuid, payload, setshowAlert)),
  onGetAllDetailsAsThunk: (uuid) => dispatch(thunkActions.getAllDetailsAsThunk(uuid)),
  onGetPropertyTypesAsThunk: (uuid) => dispatch(thunkActions.getPropertyTypesAsThunk(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportListingDetailsModal);
