import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import { LoadMoreButton, Row, Col } from "../../shared/v1";
import AutoPlan from "./AutoPlan";
import "./AutoPlans.sass";
import "../../shared/StandardTable.scss";
import Error from "../../shared/Error";
import ShareAutoPlanModal from "./ShareAutoPlanModal";
import DismissibleAlert from "../../shared/DismissibleAlert";
import TextButton from "../../shared/v2/TextButton/TextButton";
import Triggers from "../../Triggers/components/Triggers";
import NewAutoPlanModal from "./modals/NewAutoPlanModal";
import DeleteAutoPlanModal from "./modals/DeleteAutoPlanModal";
import PreviewAutoPlanModal from "./modals/PreviewAutoPlanModal";
import { autoPlanNameLabel } from "./helpers";
import { Search } from "../../shared/v2/Icomoon";
import TextInput from "../../shared/v2/TextInput/TextInput";
import SortCarets from "./SortCarets";

const TableLabel = ({ type }) => <>{type === "transactions" ? "Transaction" : "People"} Plans</>;

TableLabel.propTypes = { type: PropTypes.string.isRequired };

const AutoPlans = ({
  accountUuid,
  activeAutoPlanActionId,
  activeAutoPlanId,
  appRootUrl,
  autoPlanForm,
  autoPlanFormUpdate,
  autoPlanPreview,
  autoPlans,
  autoPlansPath,
  autoPlansRootPath,
  baseLeadFilterPath,
  closeAutoPlanDeleteModal,
  closeAutoPlanShareModal,
  closeModal,
  closeNewAutoPlanModal,
  closeTriggerDeleteModal,
  closeTriggerModal,
  createTrigger,
  deleteAutoPlan,
  deleteTrigger,
  displayAlert,
  emailErrors,
  errorMessage,
  getAutoPlans,
  getTriggers,
  handleClone,
  handleDismissAutoPlanSharedAlert,
  hasError,
  isAutoPlanDeleteModalOpen,
  isAutoPlanPreviewModalOpen,
  isAutoPlanShareModalOpen,
  isCloningAutoPlan,
  isDeletingAutoPlan,
  isFetchingAutoPlans,
  isModalOpen,
  isModalSubmitting,
  modalErrorMessage,
  modalHasError,
  numberShared,
  openAutoPlanDeleteModal,
  openAutoPlanShareModal,
  openTriggerDeleteModal,
  planType,
  platformAccount,
  previewAutoPlanAction,
  shareAutoPlan,
  sharedPlanName,
  showAutoPlanPreviewModal,
  showNewAutoPlanModal,
  showTriggerModal,
  sources,
  submitNewAutoPlan,
  supportPhoneNumber,
  tags,
  triggers,
  triggersPath,
  updateTrigger,
  updateTriggerValues,
}) => {
  const [state, _setState] = useState({
    currentPage: 1,
    orderBy: "created_at",
    orderDir: "desc",
    search: "",
    sortedAutoPlans: [],
  });
  const setState = (newState) => _setState((prevState) => ({ ...prevState, ...newState }));
  const hasAutoPlans = autoPlans?.length > 0;
  const pluralizedAccount = numberShared > 1 ? "accounts" : "account";
  const showLoadMore = state.sortedAutoPlans.length > state.currentPage * 30;
  const autoPlanName = autoPlanNameLabel(activeAutoPlanId, autoPlans);

  useEffect(() => {
    getTriggers(triggersPath);
    getAutoPlans(autoPlansPath, planType);
  }, []);

  useEffect(() => {
    const matcher = new RegExp(state.search, "i");
    const sortedAutoPlans = sortBy(
      (autoPlans || []).filter((plan) => matcher.test(plan.name)),
      [state.orderBy],
    );
    if (state.orderDir === "desc") sortedAutoPlans.reverse();
    setState({ sortedAutoPlans });
  }, [autoPlans, state.search, state.orderBy, state.orderDir]);

  return (
    <>
      {planType === "people" && (
        <section className="p-y-20">
          <div className="section-content">
            <Triggers
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...triggers}
              accountUuid={accountUuid}
              autoPlans={autoPlans}
              closeTriggerDeleteModal={closeTriggerDeleteModal}
              closeTriggerModal={closeTriggerModal}
              createTrigger={createTrigger}
              deleteTrigger={deleteTrigger}
              getTriggers={getTriggers}
              openTriggerDeleteModal={openTriggerDeleteModal}
              showTriggerModal={showTriggerModal}
              sources={sources}
              tags={tags}
              triggersPath={triggersPath}
              updateTrigger={updateTrigger}
              updateTriggerValues={updateTriggerValues}
            />
          </div>
        </section>
      )}

      <div>
        <section className="p-y-20">
          <DismissibleAlert
            variant="success"
            content={`${sharedPlanName} successfully shared with ${numberShared} ${pluralizedAccount}`}
            displayAlert={displayAlert}
            handleDismissAlert={handleDismissAutoPlanSharedAlert}
          />
          <div className="section-content">
            <div className="brivity-panel">
              <div className="brivity-panel-header">
                <TableLabel type={planType} />
                <TextButton
                  className="action"
                  schema="sentence"
                  size="small"
                  onClick={() => showNewAutoPlanModal()}
                >
                  Add Plan
                </TextButton>
              </div>
              <div className="brivity-panel-body">
                {hasAutoPlans && (
                  <div className="tw-relative">
                    <TextInput
                      className="tw-mb-16px"
                      onChange={(e) => setState({ search: e.target.value })}
                      placeholder={`Search ${planType === "transactions" ? "transaction" : planType} plans`}
                    />
                    <div className="tw-absolute tw-top-0 tw-bottom-[16px] tw-right-[12px] tw-flex tw-items-center">
                      <Search className="tw-text-gray-40" size="l" />
                    </div>
                  </div>
                )}
                <Row>
                  <Col size={12}>
                    <div className="standard-table auto-plan-table">
                      {hasError && <Error errorMessage={errorMessage} />}

                      {!hasAutoPlans && !isFetchingAutoPlans && (
                        <div className="table-empty-row">No auto plans</div>
                      )}

                      {!hasAutoPlans && isFetchingAutoPlans && (
                        <div className="table-empty-row">Loading auto plans...</div>
                      )}

                      {hasAutoPlans && (
                        <Row className="table-header-row">
                          <Col size={4}>
                            <Row>
                              <Col size={1} />
                              <Col
                                size={11}
                                role="button"
                                tabIndex="0"
                                aria-label={`Sort Name ${state.orderDir}`}
                                className="tw-cursor-pointer"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setState({
                                      orderBy: "name",
                                      orderDir: state.orderDir === "asc" ? "desc" : "asc",
                                    });
                                  }
                                }}
                                onClick={() =>
                                  setState({
                                    orderBy: "name",
                                    orderDir: state.orderDir === "asc" ? "desc" : "asc",
                                  })
                                }
                              >
                                Name <SortCarets isActive={state.orderBy === "name"} dir={state.orderDir} />
                              </Col>
                            </Row>
                          </Col>
                          <Col size={1}>Steps</Col>
                          <Col size={1}>Enrolled</Col>
                          <Col
                            size={2}
                            role="button"
                            tabIndex="0"
                            aria-label={`Sort Created At ${state.orderDir}`}
                            className="tw-cursor-pointer"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                setState({
                                  orderBy: "name",
                                  orderDir: state.orderDir === "asc" ? "desc" : "asc",
                                });
                              }
                            }}
                            onClick={() =>
                              setState({
                                orderBy: "created_at",
                                orderDir: state.orderDir === "asc" ? "desc" : "asc",
                              })
                            }
                          >
                            Created{" "}
                            <SortCarets isActive={state.orderBy === "created_at"} dir={state.orderDir} />
                          </Col>
                          <Col size={2}>Last Updated</Col>
                          <Col size={2} />
                        </Row>
                      )}
                      {state.sortedAutoPlans?.slice(0, state.currentPage * 30).map((plan) => (
                        <AutoPlan
                          key={plan.id}
                          autoPlan={plan}
                          onPreview={() => showAutoPlanPreviewModal(plan.id)}
                          onCloneAction={handleClone}
                          onDeleteAutoPlan={openAutoPlanDeleteModal}
                          onShareAutoPlan={openAutoPlanShareModal}
                          autoPlansRootPath={autoPlansRootPath}
                          isCloningAutoPlan={isCloningAutoPlan}
                          platformAccount={platformAccount}
                          baseLeadFilterPath={baseLeadFilterPath}
                        />
                      ))}
                      {showLoadMore && (
                        <div className="tw-text-center">
                          <LoadMoreButton
                            onClick={() => setState({ currentPage: state.currentPage + 1 })}
                            disabled={isFetchingAutoPlans}
                            loading={isFetchingAutoPlans}
                          />
                        </div>
                      )}
                    </div>

                    {isModalOpen && (
                      <NewAutoPlanModal
                        closeNewAutoPlanModal={closeNewAutoPlanModal}
                        isModalOpen={isModalOpen}
                        autoPlansRootPath={autoPlansRootPath}
                        autoPlanForm={autoPlanForm}
                        accountUuid={accountUuid}
                        planType={planType}
                        submitNewAutoPlan={submitNewAutoPlan}
                        autoPlanFormUpdate={autoPlanFormUpdate}
                        modalErrorMessage={modalErrorMessage}
                        modalHasError={modalHasError}
                        isModalSubmitting={isModalSubmitting}
                      />
                    )}

                    {isAutoPlanPreviewModalOpen && (
                      <PreviewAutoPlanModal
                        closeModal={closeModal}
                        isAutoPlanPreviewModalOpen={isAutoPlanPreviewModalOpen}
                        autoPlanPreview={autoPlanPreview}
                        previewAutoPlanAction={previewAutoPlanAction}
                        activeAutoPlanActionId={activeAutoPlanActionId}
                      />
                    )}

                    <DeleteAutoPlanModal
                      isAutoPlanDeleteModalOpen={isAutoPlanDeleteModalOpen}
                      deleteAutoPlan={deleteAutoPlan}
                      closeAutoPlanDeleteModal={closeAutoPlanDeleteModal}
                      isDeletingAutoPlan={isDeletingAutoPlan}
                      activeAutoPlanId={activeAutoPlanId}
                      autoPlansRootPath={autoPlansRootPath}
                      autoPlans={autoPlans}
                      supportPhoneNumber={supportPhoneNumber}
                    />

                    <ShareAutoPlanModal
                      isAutoPlanShareModalOpen={isAutoPlanShareModalOpen}
                      closeAutoPlanShareModal={closeAutoPlanShareModal}
                      activeAutoPlanId={activeAutoPlanId}
                      autoPlanName={autoPlanName}
                      shareAutoPlan={shareAutoPlan}
                      emailErrors={emailErrors}
                      sharingLink={`${appRootUrl}accounts/${accountUuid}/share/${activeAutoPlanId}`}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AutoPlans;

AutoPlans.propTypes = {
  accountUuid: PropTypes.string.isRequired,
  activeAutoPlanActionId: PropTypes.string.isRequired,
  activeAutoPlanId: PropTypes.string.isRequired,
  appRootUrl: PropTypes.string.isRequired,
  autoPlanForm: PropTypes.shape().isRequired,
  autoPlanFormUpdate: PropTypes.func.isRequired,
  autoPlanName: PropTypes.string.isRequired,
  autoPlanPreview: PropTypes.shape().isRequired,
  autoPlans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  autoPlansPath: PropTypes.string.isRequired,
  autoPlansRootPath: PropTypes.string.isRequired,
  baseLeadFilterPath: PropTypes.string.isRequired,
  closeAutoPlanDeleteModal: PropTypes.func.isRequired,
  closeAutoPlanShareModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeNewAutoPlanModal: PropTypes.func.isRequired,
  closeTriggerDeleteModal: PropTypes.func.isRequired,
  closeTriggerModal: PropTypes.func.isRequired,
  createTrigger: PropTypes.func.isRequired,
  deleteAutoPlan: PropTypes.func.isRequired,
  deleteTrigger: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  emailErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorMessage: PropTypes.string.isRequired,
  getAutoPlans: PropTypes.func.isRequired,
  getTriggers: PropTypes.func.isRequired,
  handleClone: PropTypes.func.isRequired,
  handleDismissAutoPlanSharedAlert: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  isAutoPlanDeleteModalOpen: PropTypes.bool.isRequired,
  isAutoPlanPreviewModalOpen: PropTypes.bool.isRequired,
  isAutoPlanShareModalOpen: PropTypes.bool.isRequired,
  isCloningAutoPlan: PropTypes.bool.isRequired,
  isDeletingAutoPlan: PropTypes.bool.isRequired,
  isFetchingAutoPlan: PropTypes.bool.isRequired,
  isFetchingAutoPlanActions: PropTypes.bool.isRequired,
  isFetchingAutoPlanEmails: PropTypes.bool.isRequired,
  isFetchingAutoPlanPreview: PropTypes.bool.isRequired,
  isFetchingAutoPlanPreviewActions: PropTypes.bool.isRequired,
  isFetchingAutoPlanPreviewEmails: PropTypes.bool.isRequired,
  isFetchingAutoPlans: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isModalSubmitting: PropTypes.bool.isRequired,
  modalErrorMessage: PropTypes.string.isRequired,
  modalHasError: PropTypes.bool.isRequired,
  numberShared: PropTypes.number.isRequired,
  openAutoPlanDeleteModal: PropTypes.func.isRequired,
  openAutoPlanShareModal: PropTypes.func.isRequired,
  openTriggerDeleteModal: PropTypes.func.isRequired,
  planType: PropTypes.string.isRequired,
  platformAccount: PropTypes.shape().isRequired,
  previewAutoPlanAction: PropTypes.func.isRequired,
  shareAutoPlan: PropTypes.func.isRequired,
  sharedPlanName: PropTypes.string.isRequired,
  showAutoPlanPreviewModal: PropTypes.func.isRequired,
  showNewAutoPlanModal: PropTypes.func.isRequired,
  showTriggerModal: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  submitNewAutoPlan: PropTypes.func.isRequired,
  supportPhoneNumber: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  triggers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  triggersPath: PropTypes.string.isRequired,
  updateTrigger: PropTypes.func.isRequired,
  updateTriggerValues: PropTypes.func.isRequired,
};
