import React from "react";
import "@brivity/ai-components/dist/style.css";
import { AiComponentsProvider, Transcript } from "@brivity/ai-components";
import useAiToken from "./hooks/useAiToken";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";

const AiCallSummary = ({ callSid }) => {
  const featureFlags = useFeatureFlags();
  const { token, apiUrl } = useAiToken();

  if (!featureFlags.callRecording || !token) return null;

  return (
    <AiComponentsProvider
      apiUrl={apiUrl}
      theme={document.body.classList.contains("place-theme") ? "ai-place" : "ai-brivity"}
      token={token}
    >
      <Transcript callSID={callSid} />
    </AiComponentsProvider>
  );
};

export default AiCallSummary;
