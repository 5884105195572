import React from "react";
import moment from "moment-timezone";
import { sortByKey } from "../../shared/Utilities";

const autoPlanOptions = (plans = []) => {
  const options = plans.map((plan) => ({ label: plan.name, value: plan.id }));

  return sortByKey(options, "label");
};

const dateTimeFormat = "MM/DD/YYYY hh:mm a z";
const timeFormat = "hh:mm a z";

const getActionById = (id, assignedPlan) =>
  assignedPlan.assigned_actions.filter((action) => action.id === id)[0];

const getAutoPlanActionById = (id, autoPlan) => autoPlan.actions.filter((action) => action.id === id)[0];

const formattedTime = (datetime) => moment(datetime).tz(Brivity.LocalTimeZone).format(timeFormat);

const determineActionInDisplay = (assignedPlan, activeAssignedActionId) => {
  if (!assignedPlan || !assignedPlan.assigned_actions) {
    return undefined;
  }
  if (activeAssignedActionId) {
    return getActionById(activeAssignedActionId, assignedPlan);
  }
  const sortedActions = sortByKey(assignedPlan.assigned_actions, "run_at");
  const action = sortedActions.filter((sortedAction) => sortedAction.state === "started")[0];
  return action || sortedActions[0];
};

const filterAssignedPlans = (assignedPlans, showCompletedPlans, showDeletedPlans) => {
  const plans = assignedPlans || [];
  return plans.filter(
    (plan) =>
      (plan.state !== "deleted" || showDeletedPlans) && (plan.state !== "completed" || showCompletedPlans),
  );
};

const agentNameLabel = (id, agents = []) => {
  const agent = agents.filter((a) => a.uuid === id)[0];
  return agent ? agent.full_name : "";
};

const roleTypeLabel = (role) => {
  if (role === "primary_agent") {
    return "Primary Agent";
  }
  if (role === "owner") {
    return "Account Owner";
  }
  return "";
};

const fromUserLabel = (action, agents) => {
  const user = action.agent_id ? agentNameLabel(action.agent_id, agents) : roleTypeLabel(action.agent_role);
  return <span className="from-label">{user}</span>;
};

const runAtLabel = (actionType, state) => {
  if (actionType === "Task") {
    return state === "completed" ? "Completed At" : "Due By";
  }
  return state === "completed" ? "Sent At" : "Send At";
};

const runAtValue = (runAt, state, completedAt) => {
  if (!runAt) {
    return "";
  }
  const date = state === "completed" && completedAt ? completedAt : runAt;
  return moment(date).tz(Brivity.LocalTimeZone).format(dateTimeFormat);
};

const visibilityLabel = (visible) => (visible ? "Visible to viewers" : "Hidden from viewers");

export {
  determineActionInDisplay,
  dateTimeFormat,
  timeFormat,
  formattedTime,
  getActionById,
  getAutoPlanActionById,
  filterAssignedPlans,
  fromUserLabel,
  runAtLabel,
  runAtValue,
  visibilityLabel,
  autoPlanOptions,
};
