import React from "react";
import { shape } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TIMEFRAME } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import TimeFrameValRead from "../TimeFrameValRead";
import TimeFrameAdd from "../TimeFrameAdd";

const TimeFrameVal = ({ timeframe }) => (timeframe ? <TimeFrameValRead /> : <TimeFrameAdd />);

TimeFrameVal.propTypes = {
  timeframe: shape({}),
};

TimeFrameVal.defaultProps = {
  timeframe: null,
};

export default tdpDetails({
  WrappedComponent: TimeFrameVal,
  states: TIMEFRAME,
});
