import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatExpense, formatValue } from "../shared/FinancialViewHelpers";
import Input from "../../../shared/Input";


const CollaboratorExpense = ({ value, listingData, setFinancialData, expense, recalculateCollaboratorExpenses }) => {
  return (
    <div className="collaborator-content">
      <Row>
        <Col xs={2}>
          <label>{value.name}</label>
          <Input
            value={value.value}
            onChange={(e) => {
              value.value = e.target.value
              setFinancialData(listingData)
              recalculateCollaboratorExpenses(expense, listingData)
            }}
          />
        </Col>
        <Col xs={4}>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              className="description form-control"
              placeholder=""
              value={value.notes || ''}
              onChange={(e) => {
                value.notes = e.target.value
                setFinancialData(listingData)
              }}
            />
          </div>
        </Col>
        <Col xs={1} />
        <Col xs={2} className="text-right">
          <span>{formatExpense(formatValue(value.expense_amount, false))}</span>
        </Col>
        <Col xs={2} className="text-right">
          <span>{formatValue(value.line_total)}</span>
        </Col>
      </Row>
    </div>
  )
}

export default CollaboratorExpense;
