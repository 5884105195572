/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HeartFilled = (props) => (
  <Icon {...props} name="Heart Filled">
    <path d="M8 2.803c1.626-1.655 4.252-1.87 5.951-.441 2.214 1.864 1.885 4.899.312 6.507l-5.157 5.254a1.542 1.542 0 0 1-2.212-.003L1.737 8.866C.161 7.258-.162 4.223 2.049 2.362 3.749.93 6.374 1.148 8 2.803z" />
  </Icon>
);

export default HeartFilled;
