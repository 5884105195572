export const FLYOUT_CONTAINER_CLASSES =
  "tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-1050 tw-overflow-hidden tw-overflow-scrolling-touch tw-outline-0 tw-transition";
export const FLYOUT_BACKDROP_CLASSES =
  "tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-1040 tw-bg-black tw-opacity-50";
export const FLYOUT_MODAL_CLASSES = "tw-relative tw-w-auto";
export const FLYOUT_HEADER_CLASSES =
  "tw-bg-white tw-p-32px tw-border-b-neutral-gray-10 tw-border-x-transparent tw-border-t-transparent tw-border-solid";
export const FLYOUT_BODY_CLASSES = "tw-p-32px tw-bg-neutral-gray-2.5 tw-h-full tw-overflow-auto";
export const FLYOUT_CONTENT_CLASSES =
  "tw-relative tw-h-[100vh] tw-w-fit tw-bg-clip-padding tw-shadow-[0px_6px_20px_2px_rgba(51,51,51,0.16)] tw-bg-white tw-border-opacity-20 tw-outline-0";
export const SR_ONLY_CLASSES =
  "tw-absolute tw-w-px tw-h-px tw-p-0 tw--m-px tw-overflow-hidden tw-clip-rect-0 tw-border-0";
