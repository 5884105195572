/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { bool, func, string, shape } from "prop-types";

import { useDispatch } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { saveSuspendingPerson } from "../actions/apiActions";
import { setSuspendError, setSuspendUser } from "../actions/default";
import { useTheme } from "../../../reducers/layoutReducer/selectors";

const SUSPEND_TXT_STYLE = "tw-text-14d tw-text-gray-50 tw-flex tw-flex-col tw-gap-[20px]";

const SuspendText = () => {
  const isPlace = useTheme().name === "place-theme";

  return (
    <div className={SUSPEND_TXT_STYLE}>
      <div>
        Suspending this user will immediately lock them out of their Brivity account and any associated apps.
      </div>
      <div>
        A suspended user will still take up a user seat and any automated communication will continue to send
        until the user is archived. You can reassign any tasks, contacts, or settings associated with this
        user while they are suspended.
      </div>
      <div>
        <b>Users should be archived</b> once you are ready to fully remove them from Brivity.{" "}
        <a
          href="https://www.brivityknowledge.com/docs/remove-users-from-your-account"
          target="_blank"
          rel="noreferrer"
          className={isPlace ? "tw-text-[#3270FA]" : "tw-text-brand-info"}
        >
          Learn more about best practices for removing a user.
        </a>
      </div>
    </div>
  );
};

const UnsuspendText = () => (
  <div className={SUSPEND_TXT_STYLE}>
    Unsuspending this user will restore full access to this Brivity account.
  </div>
);

export const SuspendUserModal = ({ show, onSubmitHandlr, onHideHandlr, isSuspended, isLoading }) => (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[440px]"
    onHide={onHideHandlr}
    closeButton
  >
    <Modal.Header
      title={`${isSuspended ? "Unsuspend" : "Suspend"} User?`}
      closeButton
      className="tw-mb-[12px]"
    />
    <Modal.Body className="tw-mb-[32px]">{isSuspended ? <UnsuspendText /> : <SuspendText />}</Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onHideHandlr}>
        CANCEL
      </Button>
      <Button size="medium" schema="warning" onClick={onSubmitHandlr} isLoading={isLoading}>
        {`YES, ${isSuspended ? "UNSUSPEND" : "SUSPEND"}`}
      </Button>
    </Modal.Footer>
  </Modal>
);

// not reusable, use SuspendUserModal if using in other pages;
const UsersSuspendUserModal = ({ show, onHideHandlr, suspendedPayload }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isSuspended = suspendedPayload?.action === "suspend";

  const onSubmitHandlr = async () => {
    setIsLoading(true);

    // params: userid, action, onSuccess, onError
    await saveSuspendingPerson(
      suspendedPayload?.userId,
      suspendedPayload?.action,
      () => dispatch(setSuspendUser(suspendedPayload?.userId, isSuspended)),
      () => dispatch(setSuspendError(true)),
    );
    setIsLoading(false);
    onHideHandlr();
  };

  return (
    <SuspendUserModal
      show={show}
      onSubmitHandlr={onSubmitHandlr}
      onHideHandlr={onHideHandlr}
      isSuspended={!isSuspended}
      isLoading={isLoading}
    />
  );
};

UsersSuspendUserModal.propTypes = {
  show: bool.isRequired,
  onHideHandlr: func.isRequired,
  suspendedPayload: shape({
    userId: string,
    action: string,
    isSuspended: bool,
  }),
};

UsersSuspendUserModal.defaultProps = {
  suspendedPayload: null,
};

SuspendUserModal.propTypes = {
  show: bool.isRequired,
  onSubmitHandlr: func.isRequired,
  onHideHandlr: func.isRequired,
  isSuspended: bool.isRequired,
  isLoading: bool.isRequired,
};

export default UsersSuspendUserModal;
