import React from 'react';
import { Row, Col } from '../../../../shared/v1';
import { useStartHandlr, useInit } from './customHooks';
import TransactionInput from '../../shared/TransactionInput';

export const ClosedVolumeForm = props => {
  const {
    listingData,
    closedVolume,
    setClosedVolume,
    saveClosedVolume,
    setIsShowTrDetail
  } = props;

  useInit(listingData, setIsShowTrDetail);

  const { startButtonHandlr, startButtonDisabled } = useStartHandlr(saveClosedVolume, listingData, closedVolume);

  return (
    <div className="financial-start-wrap">
      <p>Enter the expected closed price and hit Start.</p>

      <div className="transaction-gci-options">
        <div className="transaction-gci-content">
          <Row>
            <Col size={2}>
              <TransactionInput
                label='Closed Volume'
                value={closedVolume}
                isPercentage={false}
                disableFlip={true}
                onValueUpdate={e => {
                  setClosedVolume(e.target.value);
                }} />
            </Col>
          </Row>

          <button 
            className="btn btn-default btn-info-inverse"
            onClick={startButtonHandlr}
            disabled={startButtonDisabled}
            >
            START
          </button>
        </div>
      </div>
    </div>
  );
}
