import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Col, Row } from "../../../shared/v1";
import TextButton from "../../../shared/v2/TextButton";
import Button from "../../../shared/v2/Button";

export default class PublicActivityMessageEvent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
    this.event = this.props.event;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.rowHeadline = this.rowHeadline.bind(this);
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({ show_modal: false });
  };

  showEmailModal = () => {
    this.setState({ show_modal: false });

    Brivity.EmailInteraction.showModal(this.props.person_id, null, this.event.id, this.event.email_subject);
  };

  handleReplyClick = () => {
    const { newDetailsPage, setInteractionTab, setGmailReplyThread } = this.props;
    if (newDetailsPage) {
      const additionalRecipient = this.event.other_recipients || [];
      const ccVal = this.event.cc || [];
      const bccVal = this.event.bcc || [];
      setGmailReplyThread(
        this.event.id,
        `RE: ${this.event.email_subject}`,
        additionalRecipient,
        ccVal,
        bccVal,
      );
      setInteractionTab(1);
      this.hideModal();
      window.scrollTo(0, 0);
    } else {
      this.showEmailModal();
    }
  };

  rowHeadline = () => {
    const agent_person = this.event.agent_person;
    const recipient = this.event.recipient;
    const from = `${agent_person.first_name} ${agent_person.last_name} <${agent_person.main_email}>`;
    const to = `${recipient.first_name} ${recipient.last_name} <${recipient.main_email}>`;
    return (
      <div>
        <span className="bold-detail">{from}</span>
        {" emailed "}
        <span className="bold-detail">{to}</span>
      </div>
    );
  };

  rowMetaData = () => {
    const agent_person = this.event.agent_person;
    const recipient = this.event.recipient;
    const additionalRecipient = this.event.other_recipients || [];
    const recipientString = additionalRecipient.length > 0 ? `, ${additionalRecipient.join(", ")}` : "";
    const from = `${agent_person.first_name} ${agent_person.last_name} <${agent_person.main_email}>`;
    const to = `${recipient.first_name} ${recipient.last_name} <${recipient.main_email}> ${recipientString}`;
    return (
      <div className="row message-meta-data">
        <div className="col-xs-10">{`${from} to ${to}`}</div>
        <div className="col-xs-2 relative-time">{this.event.interacted_at_relative_in_words}</div>
      </div>
    );
  };

  row = () => {
    return (
      <div className="timeline-event mail-timeline-event mail-event-content" onClick={this.showModal}>
        <div className="row header">
          <div className="col-xs-1">
            <i className="fa fa-envelope-o timeline-icon-circle" />
          </div>
          <div className="col-xs-8">
            {this.rowHeadline()}
            <div className="timeline-description-text">
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className="bold-detail mail-row-subject">{this.props.event.email_subject}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">{this.props.event.email_snippet}</div>
              </div>
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    return (
      <Modal
        className="mail-event-modal timeline-mail-modal"
        bsSize="lg"
        show={this.state.show_modal}
        onHide={this.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
          <h4 className="subject">Subject: {this.event.email_subject}</h4>
          <ul>
            <li className="active">
              {this.rowMetaData()}
              <div className="row message-body">
                <div dangerouslySetInnerHTML={{ __html: this.event.email_body }} />
              </div>
            </li>
          </ul>

          <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pt-[5px]">
            <Col size={7} className="tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px] tw-p-0">
              <Button
                onClick={this.hideModal}
                className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                schema="tertiary"
                size="medium"
              >
                CLOSE
              </Button>
            </Col>
            <Col
              size={5}
              className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-flex tw-justify-end"
            >
              {this.event.other_recipients && this.event.other_recipients.length > 0 && (
                <TextButton
                  onClick={this.handleReplyClick}
                  className="tw-float-left tw-mr-16px"
                  size="medium"
                >
                  REPLY ALL
                </TextButton>
              )}

              <Button
                onClick={this.handleReplyClick}
                className="tw-flex tw-items-center"
                schema="primary"
                size="medium"
              >
                REPLY
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
