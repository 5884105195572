import React, { useEffect, useState } from "react";
import { func, bool, shape } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import { fetchAvailableTransactions } from "../../../actions/personDetailActionCreators";

const getOption = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const AddTransactionModal = ({ modalOpen, closeModal, person, handleAddTransaction }) => {
  const dispatch = useDispatch();

  const availableTransactions = useSelector((state) => getOption(state.personDetail.available_transactions));

  const [associatedTransaction, SetAssociatedTransaction] = useState(null);

  useEffect(() => {
    if (person.data?.id) dispatch(fetchAvailableTransactions(person.data.attributes.uuid));
  }, [person.data?.id]);

  const handleAdd = () => {
    closeModal();
    handleAddTransaction(associatedTransaction);
  };
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[530px] tw-w-full"
      show={modalOpen}
      onHide={closeModal}
    >
      <Modal.Header
        closeButton
        title="Add a Transaction"
        description={
          <span>
            Select the transaction associated with the loan. Only transactions in which the borrower is listed
            as a client are included for selection.
          </span>
        }
      />
      <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-pt-[32px] tw-pb-[8px]">
          <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
            <div className="tw-col-span-2">
              <Dropdown
                menuShouldComeToFront
                value={associatedTransaction}
                options={availableTransactions}
                onChange={(value) => SetAssociatedTransaction(value)}
                isSearchable
                placeholder="Select transaction"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-gap-[12px] tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="medium" type="submit" onClick={handleAdd} disabled={!associatedTransaction}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddTransactionModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  handleAddTransaction: func,
  person: shape,
};

AddTransactionModal.defaultProps = {
  modalOpen: false,
  closeModal: () => {},
  handleAddTransaction: () => {},
  person: {},
};

export default AddTransactionModal;
