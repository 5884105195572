import { useSelector } from "react-redux";
import { toggleTaskActionModal, toggleEmailActionModal, toggleTextActionModal, setPeopleGroups } from "../AutoPlans/actions/autoPlansActionCreators";
import { produce } from "immer";
import { keyBy, mapValues } from "lodash";

const defaultState = {
  autoPlan: {
    name: "",
    actions: [],
    plan_type: "",
  },
  autoPlanForm: {
    name: ""
  },
  autoPlanPreferencesForm: {
    auto_pause_on_reply: '',
    auto_pause_on_expired: '',
    auto_pause_for_status: '',
    status_on_complete: '',
  },
  auto_plan_path: '',
  isEditingPreferences: false,
  isEditingName: false,
  isFetching: false,
  isSubmitting: false,
  isModalOpen: false,
  isTaskActionModalOpen: false,
  isEmailActionModalOpen: false,
  isTextActionModalOpen: false,
  isDeleteActionModalOpen: false,
  showInstructions: false,
  isDeletingAction: false,
  actionForm: {
    name: "",
    type: "Task",
    delay: 0,
    delay_unit: 'seconds',
    delay_factor: 1,
  },
  specificActionForm: {},
  specificActionSubmit: 'post',
  generalRolesById: {},
  teamRolesById: {},
  userById: {},
  errorMessage: []
};

const autoPlanEdit = (currentState = {}, payload) => {
  const state = {...defaultState, ...currentState};

  switch (payload.type) {
    case "FETCH_AUTO_PLAN":
      return {
        ...state,
        isFetching: true,
      };

    case "FETCH_AUTO_PLAN_SUCCESS":
      return {
        ...state,
        autoPlan: payload.autoPlan,
        timeline: payload.timeline,
        autoPlanForm: {
          name: payload.autoPlan.name
        },
        autoPlanPreferencesForm: {
          auto_pause_on_reply: payload.autoPlan.auto_pause_on_reply,
          auto_pause_for_status: payload.autoPlan.auto_pause_for_status,
          status_on_complete: payload.autoPlan.status_on_complete,
          auto_pause_on_expired: payload.autoPlan.auto_pause_on_expired,
        },
        activeActionId: null,
        isFetching: false,
        isTaskActionModalOpen: false,
        isEmailActionModalOpen: false,
        isTextActionModalOpen: false,
        actionForm: {
          name: "",
          type: "Task",
          delay: 0,
          delay_unit: 'seconds',
          delay_factor: 1,
        },
      };

    case "UPDATE_AUTO_PLAN_VALUE":
      return {
        ...state,
        autoPlanForm: { ...state.autoPlanForm, [payload.name]: payload.value }
      };

    case "IS_EDITING_NAME":
      return {
        ...state,
        isEditingName: !state.isEditingName,
        autoPlanForm: {
          name: state.autoPlan.name
        },
      };

    case "SHOW_INSTRUCTIONS":
      return {
        ...state,
        showInstructions: !state.showInstructions,
        actionForm: {
          ...state.actionForm,
          instructions: !state.showInstructions
            ? state.actionForm.instructions
            : ""
        }
      };

    case "SHOW_TASK_NOTE":
      return {
        ...state,
        showNote: !state.showNote,
        actionForm: {
          ...state.actionForm,
          note: !state.showNote
            ? state.actionForm.showNote
            : ""
        }
      }

    case "IS_SUBMITTING":
      return {
        ...state,
        isSubmitting: true
      };

    case "OPEN_DELETE_ACTION_MODAL":
      return {
        ...state,
        isDeleteActionModalOpen: true,
        activeActionId: payload.activeActionId
      }

    case "CLOSE_DELETE_ACTION_MODAL":
      return {
        ...state,
        isDeletingAction: false,
        isDeleteActionModalOpen: false,
        activeActionId: null,
        actionForm: defaultState.actionForm
      }

    case "OPEN_NEW_ACTION_MODAL":
      return {
        ...state,
        isModalOpen: true,
        actionForm: payload.actionForm
      };

    case "CLOSE_NEW_ACTION_MODAL":
      return {
        ...state,
        isModalOpen: false,
        showInstructions: false,
        hasError: false,
        errorMessage: undefined,
        actionForm: defaultState.actionForm
      };

    case "OPEN_EDIT_ACTION_MODAL":
      return {
        ...state,
        isModalOpen: true,
        actionForm: {
          ...state.actionForm,
          ...payload.actionForm,
          delay_factor: (payload.actionForm.delay < 0 ? -1 : 1),
        },
        showInstructions: payload.actionForm.instructions && payload.actionForm.instructions.length > 0,
        showNote: payload.actionForm.note && payload.actionForm.note.length > 0
      };
    case "CLOSE_EDIT_ACTION_MODAL":
      return {
        ...state,
        hasError: false,
        errorMessage: undefined,
        isModalOpen: false
      };
    case "UPDATE_ACTION_VALUES":
      return {
        ...state,
        actionForm: { ...state.actionForm, [payload.name]: payload.value }
      };
    case "UPDATE_ACTION_FORM_MULTIPLE_VALUES":
      return {
        ...state,
        actionForm: { ...state.actionForm, ...payload.formUpdates }
      };
    case "AUTO_PLAN_EDIT_ACTION_PENDING":
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
        errorMessage: undefined
      };

    case "ACTION_FAILURE":
      return {
        ...state,
        isSubmitting: false,
        hasError: true,
        errorMessage: payload.errorMessage,
      };

    case "FETCH_ACTION_SUCCESS":
      return {
        ...state,
        activeAction: payload.activeAction
      };

    case "ACTION_CREATE_SUCCESS":
      return produce(state, (draft) => {
        draft.isModalOpen = false;
        draft.isSubmitting = false;
        draft.hasError = false;
        draft.errorMessage = undefined;
        draft.actionForm = defaultState.actionForm;
        draft.isTaskActionModalOpen = false;
        draft.isEmailActionModalOpen = false;
        draft.isTextActionModalOpen = false;  
      })
    case "ACTION_UPDATE_SUCCESS":
      return {
        ...state,
        isModalOpen: false,
        isSubmitting: false,
        hasError: false,
        errorMessage: undefined,
        actionForm: defaultState.actionForm,
        // TODO: re-enable before testing
        isTaskActionModalOpen: false,
        IsEmailActionModalOpen: false,
        isTextActionModalOpen: false,
      };
    case "AUTO_PLAN_UPDATE_SUCCESS":
      return {
        ...state,
        autoPlan: { ...state.autoPlan, ...payload.autoPlan },
        isSubmitting: false,
        isEditingName: false,
        hasError: false,
        errorMessage: undefined
      };

    case "AUTO_PLAN_PREFERENCES_UPDATE_SUCCESS":
      return {
        ...state,
        autoPlan: { ...state.autoPlan, ...payload.autoPlan },
        isEditingPreferences: false
      };

    case "ACTION_DELETE_PENDING":
      return {
        ...state,
        isDeletingAction: true
      };

    case "ACTION_DELETE_SUCCESS":
      return {
        ...state,
        isDeletingAction: false,
        isDeleteActionModalOpen: false,
        activeActionId: null,
        actionForm: defaultState.actionForm
      };

    case 'AUTO_PLAN_PREFERENCES_FORM_UPDATE':
    return {
      ...state,
      autoPlanPreferencesForm: { ...state.autoPlanPreferencesForm, [payload.name]: payload.value },
      isEditingPreferences: true
    };

    case 'AUTO_PLAN_PREFERENCES_FORM_RESET':
    return {
      ...state,
      autoPlanPreferencesForm: {
        auto_pause_on_reply: state.autoPlan.auto_pause_on_reply,
        auto_pause_for_status: state.autoPlan.auto_pause_for_status,
        status_on_complete: state.autoPlan.status_on_complete,
        auto_pause_on_expired: state.autoPlan.auto_pause_on_expired,
      },
      isEditingPreferences: false
    };
    case toggleTaskActionModal().type:
      return produce(state, (draft) => {
        draft.isTaskActionModalOpen = payload.bool;
        draft.specificActionSubmit = payload.action;
        draft.errorMessage = undefined;
        draft.hasError= false,
        draft.actionForm = {...payload.form};
      });
    case toggleEmailActionModal().type:
      return produce(state, (draft) => {
        draft.isEmailActionModalOpen = payload.bool;
        draft.specificActionSubmit = payload.action;
        draft.errorMessage = undefined;
        draft.hasError= false,
        draft.actionForm = {...payload.form};
      })
    case toggleTextActionModal().type:
      return produce(state, (draft) => {
        draft.isTextActionModalOpen = payload.bool;
        draft.specificActionSubmit = payload.action;
        draft.errorMessage = undefined;
        draft.hasError= false,
        draft.actionForm = {...payload.form};
      })
    case setPeopleGroups().type:
      return produce(state, (draft) => {
        const hashMapIt = (array) => mapValues(keyBy(array, 'value'), 'label');

        draft.generalRolesById = hashMapIt(payload.generalRoles?.options);
        draft.teamRolesById = hashMapIt(payload.teamRoles?.options);
        draft.userById = hashMapIt(payload.userId?.options);
      })
      default:
      return state;
  }
};

export default autoPlanEdit;

export const useAutoPlanId = () => 
  useSelector(({ autoPlanEdit }) => autoPlanEdit.autoPlan.id);

export const useIsTaskActionModalOpen = () =>
  useSelector(({ autoPlanEdit }) => autoPlanEdit.isTaskActionModalOpen);

export const useIsEmailActionModalOpen = () => 
  useSelector(({ autoPlanEdit }) => autoPlanEdit.isEmailActionModalOpen);

export const useIsTextActionModalOpen = () =>
  useSelector(({ autoPlanEdit }) => autoPlanEdit.isTextActionModalOpen)

export const useActionForm = () =>
  useSelector(({ autoPlanEdit }) => autoPlanEdit.actionForm)

export const useSpecificActionSubmit = () =>  
  useSelector(({ autoPlanEdit }) => autoPlanEdit.specificActionSubmit)

export const useAutoPlanType = () => 
  useSelector(({ autoPlanEdit }) => autoPlanEdit.autoPlan.plan_type)

export const useIsSubmitting = () =>
  useSelector(({ autoPlanEdit}) => autoPlanEdit.isSubmitting)

export const useGeneralRolesById = () =>
  useSelector(({ autoPlanEdit}) => autoPlanEdit.generalRolesById)

export const useTeamRolesById = () =>
useSelector(({ autoPlanEdit}) => autoPlanEdit.teamRolesById)

export const useUserById = () =>
useSelector(({ autoPlanEdit}) => autoPlanEdit.userById)

export const useTimeline = () =>
  useSelector(({autoPlanEdit }) => autoPlanEdit.timeline)

export const useActions = () =>
  useSelector(({autoPlanEdit }) => autoPlanEdit.autoPlan.actions)

export const useErrorMessages = () =>
  useSelector(({ autoPlanEdit }) => autoPlanEdit.errorMessage)