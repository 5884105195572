/* global tinyMCE */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import TextInput from "@shared/v2/TextInput";
import FieldError from "@shared/v2/FieldError";
import BrivityTextEditor from "@shared/BrivityTextEditor";
import FieldLabel from "@shared/v2/FieldLabel";
import AiAssistant from "@shared/AiAssistant";
import { SUBJECT_LINE_LIMIT_ERROR, isWithinSubjectLineLimit } from "@shared/hooks/useSubjectLineLimitError";
import {
  createInteractionTemplate,
  getEmailPlacholders,
  setEditTemplate,
  updateInteractionTemplate,
} from "../reducers/interactionTemplatesReducer";

const validateTemplate = ({ name = "", subject = "", body = "" }) => {
  let errors = null;
  if (!name.trim()) errors = { ...errors, name: "is required" };
  if (!subject.trim()) errors = { ...errors, subject: "is required" };
  if (!isWithinSubjectLineLimit(subject.trim())) errors = { ...errors, subject: SUBJECT_LINE_LIMIT_ERROR };
  if (!body.trim()) errors = { ...errors, body: "is required" };
  return errors;
};

const InteractionTemplateModal = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const editTemplate = useSelector((state) => state.interactionTemplates.editTemplate);
  const emailPlaceholders = useSelector((state) => state.interactionTemplates.emailPlaceholders.data);
  const { errors: apiErrors, loading } = useSelector(
    (state) => state.interactionTemplates.interactionTemplates,
  );

  useEffect(() => {
    if (!editTemplate) setErrors({});
  }, [editTemplate]);

  useEffect(() => {
    dispatch(getEmailPlacholders());
  }, []);

  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col"
      show={Boolean(editTemplate)}
      onHide={() => dispatch(setEditTemplate(null))}
      closeOnEscape={false}
    >
      <form
        className="tw-flex tw-flex-col tw-gap-[32px] tw-h-full tw-overflow-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          const template = Object.fromEntries(new FormData(e.target).entries());
          template.body = tinyMCE.activeEditor.getContent();
          delete template.image;

          const errs = validateTemplate(template);
          if (errs) {
            setErrors(errs);
            return;
          }

          setErrors({});

          if (editTemplate.id) {
            dispatch(updateInteractionTemplate({ template }));
          } else {
            dispatch(createInteractionTemplate({ template }));
          }
        }}
      >
        <Modal.Header title={`${editTemplate?.id ? "Edit" : "Create"} Email Template`} closeButton />
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[16px] tw-overflow-auto">
          {editTemplate && <input type="hidden" name="id" defaultValue={editTemplate.id} />}
          <TextInput
            isRequired
            name="name"
            label="Template Name"
            defaultValue={editTemplate?.name || ""}
            error={errors.name || apiErrors?.name?.[0]}
          />
          <TextInput
            isRequired
            name="subject"
            label="Subject"
            defaultValue={editTemplate?.subject || ""}
            error={errors.subject || apiErrors?.subject?.[0]}
          />
          <div className="tw-flex tw-flex-col tw-gap-[8px]">
            <FieldLabel label="Body" isRequired />
            <BrivityTextEditor
              id="email-template-body"
              name="body"
              className={errors.body || apiErrors?.body ? "tw-border-solid tw-border-semantic-red-25" : ""}
              defaultValue={editTemplate?.body}
              placeholders={emailPlaceholders}
            />
            {(errors.body || apiErrors?.body) && <FieldError error={errors.body || apiErrors?.body?.[0]} />}
            <AiAssistant
              isTinyMce
              messageType="Email"
              onInsertClick={(text) =>
                tinyMCE.activeEditor.execCommand(
                  "mceInsertContent",
                  false,
                  (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                )
              }
            />
          </div>
          {editTemplate?.id && (
            <>
              <span>
                Created {moment(editTemplate.createdAt).fromNow()} by {editTemplate.createdBy.fullName}
              </span>
              {editTemplate.updatedBy && (
                <span>
                  Updated {moment(editTemplate.updatedAt).fromNow()} by {editTemplate.updatedBy.fullName}
                </span>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="tw-flex tw-flex-wrap-reverse tw-gap-[8px] tw-justify-between">
            <Button
              schema="secondary"
              size="medium"
              onClick={() => dispatch(setEditTemplate(null))}
              isLoading={loading}
            >
              Cancel
            </Button>
            <div className="tw-flex tw-gap-[8px]">
              <Button
                schema="tertiary"
                size="medium"
                onClick={() => tinyMCE.activeEditor.execCommand("mcePreview")}
                isLoading={loading}
              >
                Preview
              </Button>
              <Button size="medium" type="submit" isLoading={loading}>
                Save & Close
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default InteractionTemplateModal;
