export default {
  REFERRAL_TEXT_LABEL: "tw-text-14px tw-mt-8px",
  TRANSACTION_DETAILS_LABEL:
    "tw-font-semibold tw-tracking-normal tw-capitalize tw-mb-8px tw-whitespace-nowrap",
  TYPE_BOX_seller: "tw-border-semantic-yellow-10 tw-text-semantic-yellow-100",
  TYPE_BOX_buyer: "tw-border-semantic-blue-10 tw-text-semantic-blue-110",
  TYPE_BOX_tenant: "tw-border-semantic-blue-10 tw-text-semantic-blue-110",
  TYPE_BOX_landlord: "tw-border-semantic-yellow-10 tw-text-semantic-yellow-100",
  TYPE_BOX_referral: "tw-border-semantic-pink-10 tw-text-semantic-pink-100",
  TYPE_STYLE: {
    buyer: "tw-border-semantic-blue-10 tw-text-semantic-blue-110",
    seller: "tw-border-semantic-yellow-10 tw-text-semantic-yellow-110",
    tenant: "tw-border-semantic-green-10 tw-text-semantic-green-110",
    landlord: "tw-border-semantic-red-10 tw-text-semantic-red-110",
    referral: "tw-border-semantic-pink-10 tw-text-semantic-pink-110",
  },
};
