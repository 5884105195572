import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setMergeContact } from "../../reducers/contactMergeReducer";

const { groupBy, uniq } = require("lodash");
const {
  useContactMergePrimaryContact,
  useContactMergeOtherContact,
  useContactMergeKeepContact,
} = require("../../reducers/contactMergeReducer/selectors");

const groupDetailsByKey = (key, primaryContact, otherContact) => {
  const primaryDetails = groupBy(primaryContact, key);
  const otherDetails = groupBy(otherContact, key);
  const types = uniq([...Object.keys(primaryDetails), ...Object.keys(otherDetails)]);
  return [types, primaryDetails, otherDetails];
};

const mergeMultiCategoryTypes = (types, typeKey, keepDetails, unselectedDetails) =>
  types.reduce((acc, type) => {
    const keep = (keepDetails || []).filter((d) => d[typeKey] === type);
    const other = (unselectedDetails || []).filter((d) => d[typeKey] === type);
    const main = keep.length > other.length ? keep : other;
    return [
      ...acc,
      ...main.map((m, i) =>
        Object.keys(m).reduce((a, k) => ({ ...a, [k]: keep[i]?.[k] || other[i]?.[k] }), {}),
      ),
    ];
  }, []);

const mergeSingleCategoryTypes = ({ types, typeKey, keepDetails, unselectedDetails, valueKey = "value" }) =>
  types.reduce((acc, type) => {
    const keep = keepDetails.find((d) => d[typeKey] === type);
    const other = unselectedDetails.find((d) => d[typeKey] === type);
    return [...acc, { ...(keep || other), [valueKey]: keep?.[valueKey] || other?.[valueKey] }];
  }, []);

const useMergeContact = () => {
  const dispatch = useDispatch();
  const primaryContact = useContactMergePrimaryContact();
  const otherContact = useContactMergeOtherContact();
  const keepContact = useContactMergeKeepContact();

  const [emailTypes, primaryEmails, otherEmails] = useMemo(
    () => groupDetailsByKey("category", primaryContact.emailDetails, otherContact.emailDetails),
    [primaryContact.emailDetails, otherContact.emailDetails],
  );

  const [phoneTypes, primaryPhones, otherPhones] = useMemo(
    () => groupDetailsByKey("category", primaryContact.phoneDetails, otherContact.phoneDetails),
    [primaryContact.phoneDetails, otherContact.phoneDetails],
  );

  const [addressTypes, primaryAddresses, otherAddresses] = useMemo(
    () => groupDetailsByKey("addressType", primaryContact.addresses, otherContact.addresses),
    [primaryContact.addresses, otherContact.addresses],
  );

  const [personTypes, primaryPersonDetails, otherPersonDetails] = useMemo(
    () => groupDetailsByKey("name", primaryContact.personDetails, otherContact.personDetails),
    [primaryContact.personDetails, otherContact.personDetails],
  );

  const [socialMediaTypes, primarySocialMediaDetails, otherSocialMediaDetails] = useMemo(
    () => groupDetailsByKey("name", primaryContact.socialMediaDetails, otherContact.socialMediaDetails),
    [primaryContact.socialMediaDetails, otherContact.socialMediaDetails],
  );

  const [milestoneTypes, primaryMilestones, otherMilestones] = useMemo(
    () =>
      groupDetailsByKey("name", primaryContact.milestonableMilestones, otherContact.milestonableMilestones),
    [primaryContact.milestonableMilestones, otherContact.milestonableMilestones],
  );

  useEffect(() => {
    const unselectedContact = keepContact.id === primaryContact.id ? otherContact : primaryContact;

    const mergeContact = {
      ...unselectedContact,
      ...keepContact,
      emailDetails: mergeMultiCategoryTypes(
        emailTypes,
        "category",
        keepContact.emailDetails,
        unselectedContact.emailDetails,
      ),
      phoneDetails: mergeMultiCategoryTypes(
        phoneTypes,
        "category",
        keepContact.phoneDetails,
        unselectedContact.phoneDetails,
      ),
      addresses: mergeMultiCategoryTypes(
        addressTypes,
        "addressType",
        keepContact.addresses,
        unselectedContact.addresses,
      ),
      personDetails: mergeSingleCategoryTypes({
        types: personTypes,
        typeKey: "name",
        keepDetails: keepContact.personDetails,
        unselectedDetails: unselectedContact.personDetails,
      }),
      socialMediaDetails: mergeSingleCategoryTypes({
        types: socialMediaTypes,
        typeKey: "name",
        keepDetails: keepContact.socialMediaDetails,
        unselectedDetails: unselectedContact.socialMediaDetails,
      }),
      milestonableMilestones: mergeSingleCategoryTypes({
        types: milestoneTypes,
        typeKey: "name",
        keepDetails: keepContact.milestonableMilestones,
        unselectedDetails: unselectedContact.milestonableMilestones,
        valueKey: "date",
      }),
    };
    dispatch(setMergeContact(mergeContact));
  }, [keepContact]);

  return {
    emailTypes,
    primaryEmails,
    otherEmails,
    phoneTypes,
    primaryPhones,
    otherPhones,
    addressTypes,
    primaryAddresses,
    otherAddresses,
    personTypes,
    primaryPersonDetails,
    otherPersonDetails,
    socialMediaTypes,
    primarySocialMediaDetails,
    otherSocialMediaDetails,
    milestoneTypes,
    primaryMilestones,
    otherMilestones,
  };
};

export default useMergeContact;
