import React, { Component } from "react";
import axios from "axios";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { css, cx } from "@emotion/css";
import { Dropdown } from "../../../shared/v1";
import modal_css from "../modal.module.css";
import buttons from "../button.module.css";
import HeaderTitle from "./HeaderTitle";
import RolesRow from "./RolesRow";

class ManageAccessModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryAgent: this.props.primaryAgent,
      teamMemberRole: null,
      selectedTeamMembers: this.props.nonPrimaryTeamMembers || [],
      allAssignableAgents: [],
      allAssignableRoles: this.props.allAssignableRoles || [],
      grantAccess: false,
      primaryAgentSet: false,
    };
  }

  componentDidMount() {
    axios
      .get("/account_active_agents", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({ allAssignableAgents: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleModalClose = () => {
    this.props.closeManageAccessModal();
  };

  handleRemoveAgent = (agentId, roleId) => {
    const agentToRemove = this.state.selectedTeamMembers.find(
      (agent) => agent.agent_id === agentId && agent.role_id === roleId,
    );
    if (agentToRemove) {
      this.setState((prevState) => {
        if (agentToRemove.id) {
          agentToRemove.destroy = true;
          return {
            selectedTeamMembers: prevState.selectedTeamMembers.map((agent) =>
              agent === agentToRemove ? agentToRemove : agent,
            ),
          };
        } else {
          return {
            selectedTeamMembers: prevState.selectedTeamMembers.filter((agent) => agent !== agentToRemove),
          };
        }
      });
    }
  };

  handleAddTeamMembers = () => {
    this.setState({
      selectedTeamMembers: [
        ...this.state.selectedTeamMembers,
        {
          agent_id: "",
          id: null,
          image: "",
          name: "",
          primary: "",
          role_id: null,
          user_type: "",
        },
      ],
    });
  };

  handleAddAgent = (agentId, index) => {
    const selected = [...this.state.selectedTeamMembers];
    const filteredAgent = this.state.allAssignableAgents.find((agent) => agent.agent_id === agentId);
    selected[index] = filteredAgent; // override or set the index to the agent
    filteredAgent.id = null;
    this.setState({
      selectedTeamMembers: selected,
    });
  };

  handleAddRole = (roleId, index) => {
    const selectedTeamMembers = [...this.state.selectedTeamMembers];
    this.setState({
      selectedTeamMembers: selectedTeamMembers.map((selectedTeamMember, i) =>
        index === i ? { ...selectedTeamMember, role_id: roleId } : selectedTeamMember,
      ),
    });
  };

  handleSelectPrimary = (selection) => {
    this.setState({
      primaryAgentSet: true,
      primaryAgent: { ...selection, primary: true },
    });
  };

  agentPermissions = () => {
    const permissions = {};
    for (const agent of this.state.selectedTeamMembers) {
      if (agent.user_type === "Agent") {
        permissions[agent.agent_id] = agent.destroy ? "none" : "all";
      }
    }
    return permissions;
  };

  handleManageAccess = (e) => {
    e.preventDefault();
    const params = {
      people_ids: [this.props.personId],
      agent_permissions: this.agentPermissions(),
      team_members: [...this.state.selectedTeamMembers],
      authenticity_token: ReactOnRails.authenticityToken(),
    };

    if (this.state.primaryAgentSet) {
      params["primary_agent_set"] = true;
      params["primary_agent"] = [this.state.primaryAgent.id];
    }

    axios
      .post("/people_permissions", params)
      .then((response) => {
        this.setState({
          removedAgents: [],
          grantAccess: false,
          primaryAgentSet: false,
        });
        this.props.handleUpdateAccess();
        this.props.closeManageAccessModal();
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  renderAgentValue = (props) => {
    const {
      getStyles,
      innerProps,

      isDisabled,
      isFocused,
      isSelected,

      data: { email, name, agent_id: agentId, primary: isPrimary, user_type: type },
    } = props;

    const classNameFromVariant = cx(
      css(getStyles("singleValue", props)),
      {
        "single-value": true,
        "single-value--is-disabled": isDisabled,
        "single-value--is-focused": isFocused,
        "single-value--is-selected": isSelected,
      },
      "tw-flex tw-justify-between",
    );

    const userType =
      isPrimary === true || this.state.primaryAgent.id === agentId ? "PRIMARY AGENT" : type.toUpperCase();

    return (
      <div className={classNameFromVariant} {...innerProps}>
        <span className="tw-text-gray-75">
          {name} ({email})
        </span>
        <span>{userType}</span>
      </div>
    );
  };

  renderAgentOption = (props) => {
    const {
      getStyles,

      innerRef,
      innerProps,

      isDisabled,
      isFocused,
      isSelected,
    } = props;

    const classNameFromVariant = cx(css(getStyles("option", props)), {
      option: true,
      "option--is-disabled": isDisabled,
      "option--is-focused": isFocused,
      "option--is-selected": isSelected,
    });

    return (
      <div className={classNameFromVariant} ref={innerRef} {...innerProps} style={{ padding: "8px" }}>
        {this.renderAgentValue(props)}
      </div>
    );
  };

  render() {
    const { nonPrimaryTeamMembers } = this.props;
    const { selectedTeamMembers, allAssignableAgents, allAssignableRoles } = this.state;
    const selectedRolesIds = this.state.selectedTeamMembers
      .filter((tm) => !tm.destroy)
      .map((tm) => tm.role_id);
    const availableRoles = this.state.allAssignableRoles.filter(
      (role) => selectedRolesIds.indexOf(role.id) === -1,
    );

    // For some reason, `primaryAgent` is not _completely_ populated at first
    // (notably: the email - which we need to render - is not present), so we
    // need to search in `allAssignableAgents`. Subsequent renders after
    // selecting an option are fine, so we can skip the search in that case.
    const selectedAgent = this.state.primaryAgent.email
      ? this.state.primaryAgent
      : allAssignableAgents.find((agent) => agent.agent_id === this.state.primaryAgent.agent_id);

    return (
      <Modal
        id="manage-access-modal"
        className="person-details-modal"
        show={true}
        onHide={this.handleModalClose}
      >
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle isNewHeader={true} title="Manage Team" onClose={this.handleModalClose} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <p className="tw-text-gray-500 tw-mb-25px">
                  Making another user a team member gives a user the ability to view and edit a person's
                  information. Team members do not need a role to gain access. Access can be revoked only for
                  team members with the user type of agent. The account owner and admins have access to
                  everyone.
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="form-group" id="primary-agent">
                {allAssignableAgents.length > 0 && (
                  <div>
                    <label className="tw-text-14px tw-leading-20px tw-font-semibold tw-text-gray-500 tw-mb-10px">
                      Primary Agent
                    </label>
                    <Dropdown
                      isSearchable
                      isClearable={false}
                      placeholder="Select..."
                      onChange={(e) => this.handleSelectPrimary(e)}
                      options={allAssignableAgents}
                      value={selectedAgent}
                      isOptionSelected={(option) =>
                        selectedAgent ? option.agent_id === selectedAgent.agent_id : false
                      }
                      components={{
                        Option: this.renderAgentOption,
                        SingleValue: this.renderAgentValue,
                      }}
                      // Since we're using a custom shape for options and writing
                      // custom renderers, we need to write our own filterOption
                      // as well so isSearchable works as expected.
                      filterOption={(candidate, input) => {
                        // Show all options if the user hasn't typed anything in yet
                        if (!input) {
                          return true;
                        }

                        const lowercasedInput = input.toLowerCase();
                        const option = candidate.data;

                        // Otherwise, only show candidates that contain the input string
                        // in them
                        return (
                          option.name.toLowerCase().includes(lowercasedInput) ||
                          option.email.toLowerCase().includes(lowercasedInput)
                        );
                      }}
                      variant="outlined"
                      variantOverrides={{ showIndicator: true, disableBorderRadius: true }}
                    />
                    <Row className="tw-pt-25px">
                      <Col xs={12}>
                        <hr className={`${modal_css.divider}`} />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
            <RolesRow
              selectedTeamMembers={selectedTeamMembers}
              allAssignableRoles={allAssignableRoles}
              availableRoles={availableRoles}
              allAssignableAgents={allAssignableAgents}
              nonPrimaryTeamMembers={nonPrimaryTeamMembers}
              handleAddAgent={this.handleAddAgent}
              handleAddRole={this.handleAddRole}
              handleRemoveAgent={this.handleRemoveAgent}
            />
            {availableRoles.length > 0 && (
              <Col xs={12} className="tw-mb-25px tw-mt-12px tw-ml-[-10px]">
                <a
                  className={`${buttons.btn} ${buttons.btnSm} ${buttons.btnSecondary}`}
                  onClick={this.handleAddTeamMembers}
                >
                  <i className="fa fa-plus" id="add-team-member-button" /> ADD TEAM MEMBER
                </a>
              </Col>
            )}
            <Row className={modal_css.pT30}>
              <Col xs={12}>
                <hr className={`${modal_css.divider} ${modal_css.pB30}`} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <a
                  className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
                  onClick={this.handleModalClose}
                >
                  CANCEL
                </a>
                <button
                  className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
                  onClick={this.handleManageAccess}
                >
                  SAVE TEAM
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ManageAccessModal;
