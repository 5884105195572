import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { border, padding, font, height, schemaDictionary, availableSchema } from "./Button.styles";

const schemaBasedClasses = (schemaName) => schemaDictionary[schemaName];

const Button = forwardRef(
  ({ className, children, onClick, disabled, size, schema, isLoading, ...otherProps }, ref) => (
    <button
      ref={ref}
      type="button"
      className={`
        tw-relative
        tw-font-bold
        tw-border-solid tw-rounded-full
        tw-text-center tw-uppercase
        ${schemaBasedClasses(schema, isLoading)}
        ${font(size)}
        ${height(size)}
        ${padding(schema, size)}
        ${border(schema, size)}
        ${isLoading ? "tw-loading" : ""}
        ${className}
      `}
      disabled={disabled || isLoading}
      onClick={onClick}
      data-cy="button"
      {...otherProps}
    >
      {isLoading
        ? // When we turn on `isLoading`, we want to keep the button exactly the
          // same size to avoid layout shifts, which means we can't just stop
          // rendering `children`. Instead, we turn `children` invisible and
          // render an absolutely-positioned spinner over it.
          React.Children.map(children, (child) => {
            if (typeof child === "object") {
              return React.cloneElement(child, {
                className: [child.props?.className, "tw-invisible"].filter(Boolean).join(" "),
              });
            }

            return <span className="tw-invisible">{child}</span>;
          })
        : children}

      {isLoading && (
        <div
          className="tw-absolute tw-left-2/4 tw-top-2/4 -tw-translate-x-2/4 -tw-translate-y-2/4"
          role="status"
        >
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
        </div>
      )}
    </button>
  ),
);

Button.displayName = "Button";

export const buttonPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  schema: PropTypes.oneOf(availableSchema),
  isLoading: PropTypes.bool,
};

export const buttonDefaultProps = {
  className: "",
  children: null,
  onClick: null,
  disabled: false,
  size: "small",
  schema: "primary",
  isLoading: false,
};

Button.propTypes = buttonPropTypes;

Button.defaultProps = buttonDefaultProps;

export default Button;
