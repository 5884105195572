import React from "react";

import ListingDescription from "../ListingDescription";
import Header from "../../../UI/Header";
import EditBtnCirc from "../EditBtnCirc";

const DescriptionWrapper = () => (
  <div className="tw-pb-[32px]  tw-mb-[32px] tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-1 tw-border-solid tw-border-neutral-gray-10">
    <Header header="Listing Description" add={<EditBtnCirc />} />
    <ListingDescription />
  </div>
);

export default DescriptionWrapper;
