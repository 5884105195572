import React from "react";
import TextInput from "@shared/v2/TextInput";
import Dropdown from "@shared/v2/Dropdown";
import RadioButton from "@shared/v2/RadioButton";
import PlatformCTA from "./PlatformCTA";

const DisabledHistoryFields = () => (
  <div className="tw-flex tw-flex-col tw-gap-[16px] tw-px-[10px]">
    <PlatformCTA>
      <div className="tw-flex tw-gap-[12px]">
        <RadioButton labelClassName="!tw-text-12d" label="Property Views & Favorites" disabled />
        <RadioButton labelClassName="!tw-text-12d" label="Views only" disabled />
        <RadioButton labelClassName="!tw-text-12d" label="Favorites only" disabled />
      </div>
    </PlatformCTA>
    <PlatformCTA>
      <div className="tw-flex tw-gap-[16px]">
        <TextInput label="Min Price" disabled />
        <TextInput label="Max Price" disabled />
      </div>
    </PlatformCTA>
    <PlatformCTA>
      <div className="tw-flex tw-items-end tw-gap-[4px]">
        <Dropdown
          label="Bedrooms"
          containerClassName="tw-flex-1"
          id="filters-disabled-history-fields_property-bedrooms"
          placeholder="No Min"
          isDisabled
        />
        <span>-</span>
        <Dropdown containerClassName="tw-flex-1" placeholder="No Max" isDisabled />
      </div>
    </PlatformCTA>
    <PlatformCTA>
      <div className="tw-flex tw-items-end tw-gap-[4px]">
        <Dropdown
          label="Bathrooms"
          containerClassName="tw-flex-1"
          id="filters-disabled-history-fields_property-bathrooms"
          placeholder="No Min"
          isDisabled
        />
        <span>-</span>
        <Dropdown containerClassName="tw-flex-1" placeholder="No Max" isDisabled />
      </div>
    </PlatformCTA>
    <PlatformCTA>
      <TextInput label="Location" disabled />
    </PlatformCTA>
    <hr />
  </div>
);

export default DisabledHistoryFields;
