/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ImageSolidV6 = (props) => (
  <Icon {...props} name="Image Solid V6">
    <path d="M13.972 1h-12C.895 1 0 1.895 0 3v10c0 1.105.895 2 1.972 2h12a2 2 0 0 0 2-2V3c0-1.105-.868-2-2-2zm-10.5 2c.828 0 1.5.672 1.5 1.5S4.328 6 3.472 6s-1.5-.672-1.5-1.5.699-1.5 1.5-1.5zm10.469 9.738A.5.5 0 0 1 13.5 13H2.563a.498.498 0 0 1-.404-.794l2.188-3a.5.5 0 0 1 .808 0l1.014 1.391L9.085 6.25c.094-.165.25-.25.416-.25s.323.083.416.223l4 6a.501.501 0 0 1 .025.515z" />
  </Icon>
);

export default ImageSolidV6;
