import React from "react";
import { node } from "prop-types";

const HeaderTitle = ({ children }) => (
  <div className="tw-text-gray-base tw-font-bs-sans tw-text-18px tw-font-bold">{children}</div>
);

export default HeaderTitle;

HeaderTitle.propTypes = {
  children: node,
};

HeaderTitle.defaultProps = {
  children: "",
};
