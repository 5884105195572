import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "../../shared/v2/Avatar";
import { Spinner } from "../../shared/v2/Icomoon";
import BullseyeArrowSolidV6 from "../../shared/v2/Icomoon/Icons/BullseyeArrowRegularV6";
import BuildingSolidV6 from "../../shared/v2/Icomoon/Icons/BuildingSolidV6";
import Flyout from "../../shared/v2/Flyout";
import * as creators from "../actions/creators";
import * as thunks from "../actions/thunks";
import Button from "../../shared/v2/Button";
import Card from "./Card";
import DataLabel from "./DataLabel";
import SpecializationSection from "./SpecialSection";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";

const mapStateToProps = (state) => ({
  detailsId: state.partnerSearch.detailsId,
  loadingDetails: state.partnerSearch.loadingDetails,
  partnerDetails: state.partnerSearch.partnerDetails,
  hasLoadingError: state.partnerSearch.hasLoadingError,
  newSourceId: state.partnerSearch.newSourceId,
});

const DetailsModal = ({
  detailsId,
  loadingDetails,
  partnerDetails,
  hasLoadingError,
  loadDetails,
  setDetailsId,
  newSourceId,
}) => {
  const {
    account: { brokerageAffiliation, teamName, website, fax, phone, address },
    avatar,
    certificates,
    email,
    fluencies,
    firstName,
    lastName,
    phoneMobile,
    place,
    serviceAreas,
    specializations,
  } = partnerDetails || { account: {} };
  const [showNewContactModal, setShowNewContactModal] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    if (detailsId) loadDetails({ controller, id: detailsId });
    return () => {
      controller.abort();
    };
  }, [detailsId]);
  const CONTACT_DETAILS_RECAP = [];

  const addNew = () => {
    setDetailsId();
    setShowNewContactModal({
      isLead: false,
      typeName: "collaborator",
      firstName,
      lastName,
      emailDetailsAttributes: [{ category: "work", position: 1, value: email?.address }],
      sourceId: newSourceId,
      phoneDetailsAttributes: [
        {
          category: "work",
          name: "phone",
          position: 1,
          value: phone,
        },
      ],
    });
  };
  if (phoneMobile)
    CONTACT_DETAILS_RECAP.push({
      label: phoneMobile,
      value: `tel:${phoneMobile}`,
    });
  if (email?.address)
    CONTACT_DETAILS_RECAP.push({
      label: email?.address,
      value: `mailto:${email?.address}`,
    });
  if (website)
    CONTACT_DETAILS_RECAP.push({
      label: website,
      value: website.indexOf("http") < 0 ? `//${website}` : website,
    });
  return (
    <>
      <PersonDetailsModal
        show={Boolean(showNewContactModal)}
        person={showNewContactModal}
        onClose={() => setShowNewContactModal(null)}
      />
      <Flyout show={Boolean(detailsId)} onHide={setDetailsId}>
        <div className="tw-h-full tw-w-[762px] tw-max-w-[100vw] tw-overflow-y-auto tw-bg-neutral-gray-2.5">
          {loadingDetails && (
            <Spinner size="xxl" className="tw-animate-spin tw-mx-auto tw-block tw-relative tw-top-1/2" />
          )}
          {hasLoadingError && <h3 className="tw-text-center">Something went wrong... Try again</h3>}
          {!loadingDetails && !hasLoadingError && (
            <>
              <div className="tw-bg-white tw-pt-[32px] tw-px-[32px]">
                <div className="tw-flex tw-items-top tw-mb-[8px]">
                  <Avatar
                    size="large"
                    className="tw-inline-block tw-mr-[16px] tw-font-bold"
                    src={avatar}
                    alt={`${firstName} ${lastName}`}
                  />
                  <h2 className="tw-text-[32px] tw-text-black tw-font-bold tw-pt-[8px] tw-flex-1 tw-m-0">
                    {`${firstName} ${lastName}`}
                  </h2>
                </div>

                <div className="tw-flex tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-neutral-gray-10 tw-text-neutral-gray-50 tw-mb-[8px] tw-leading-none">
                  <div className="tw-px-4 first:tw-pl-0">{`${
                    place ? "Operator" : "Owner"
                  } of ${teamName}`}</div>
                  {brokerageAffiliation && (
                    <div className="tw-px-4 first:tw-pl-0">{brokerageAffiliation}</div>
                  )}
                </div>
                <div className="tw-flex tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-neutral-gray-10 tw-mb-[20px]">
                  {CONTACT_DETAILS_RECAP?.map((detail) => (
                    <a
                      href={detail.value}
                      target="_blank"
                      rel="noreferrer"
                      className="tw-px-4 first:tw-pl-0 tw-text-semantic-blue-100 "
                    >
                      {detail.label}
                    </a>
                  ))}
                </div>
                <Button size="small" schema="secondary" className="tw-mb-[28px]" onClick={addNew}>
                  + Add to CRM
                </Button>
              </div>
              <section className="tw-py-[24px] tw-px-[24px]">
                <Card title="Office" Icon={BuildingSolidV6}>
                  {phone && <DataLabel title="Phone" label={phone} value={`tel:${phone}`} isLink />}
                  {fax && <DataLabel title="Fax" label={fax} value={`tel:${fax}`} isLink />}
                  {address && <DataLabel title="Address" value={address?.streetAddress} />}
                </Card>
                <Card title="Specializations" Icon={BullseyeArrowSolidV6}>
                  {serviceAreas?.length > 0 && (
                    <SpecializationSection title="Service Areas" values={serviceAreas} />
                  )}
                  {specializations?.length > 0 && (
                    <SpecializationSection title="Areas of Expertise" values={specializations} />
                  )}
                  {fluencies?.length > 0 && (
                    <SpecializationSection title="Additional Fluent Languages" values={fluencies} />
                  )}
                  {certificates?.length > 0 && (
                    <SpecializationSection title="Certifications & Designations" values={certificates} />
                  )}
                  {serviceAreas?.length === 0 &&
                    specializations?.length === 0 &&
                    fluencies?.length === 0 &&
                    certificates?.length === 0 && (
                      <p className="tw-text-neutral-gray-50 tw-text-[12px] tw-font-semibold">
                        No specializations to display
                      </p>
                    )}
                </Card>
              </section>
            </>
          )}
        </div>
      </Flyout>
    </>
  );
};

DetailsModal.propTypes = {
  newSourceId: PropTypes.string.isRequired,
  detailsId: PropTypes.string.isRequired,
  loadingDetails: PropTypes.bool.isRequired,
  hasLoadingError: PropTypes.bool.isRequired,
  loadDetails: PropTypes.func.isRequired,
  setDetailsId: PropTypes.func.isRequired,
  partnerDetails: PropTypes.shape.isRequired,
};

export default connect(mapStateToProps, { ...creators, ...thunks })(DetailsModal);
