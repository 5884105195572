import React from "react";
import PropTypes from "prop-types";

const FolderTab = ({ tabName, isActive, onClick }) => (
  <div
    className={`tw-pl-0 tw-py-[12px] hover:tw-bg-theme-nav-focus tw-pr-12px ${
      isActive ? `tw-font-semibold tw-border-b-0 tw-bg-theme-nav-focus` : "tw-border-white"
    }`}
    onClick={onClick}
    role="button"
    onKeyDown={onClick}
    tabIndex="0"
  >
    <div className="tw-flex">
      <div
        className={`tw-w-5px tw-h-[44px] -tw-my-[12px] tw-mr-24px ${
          isActive ? "tw-bg-theme-checkbox-checked" : null
        } `}
      />
      <div className="tw-whitespace-nowrap tw-w-[120px]">{tabName}</div>
    </div>
  </div>
);

FolderTab.propTypes = {
  tabName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FolderTab;
