import { produce } from "immer";
import * as actionCreators from "../Support/SuperUsers/Branding/actions/creators";

export const defaultState = {
  isSaving: false,
  saveError: null,
  iSearchingUsers: false,
  id: null,
  brands: [],
  brokerages: [],
  partners: [],
  placeBrandedAccounts: [],
  activePlaceTeams: [],
  formData: {},
  dbBrandData: null,
};

export const superUsersBranding = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.setFormData().type:
      return produce(state, (draft) => {
        draft.formData = { ...state.formData, ...payload.keyValue };
      });
    case actionCreators.addOperationalPartner().type:
      const newPartners = state.formData?.operationalPartners ? [...state.formData.operationalPartners] : [];

      return produce(state, (draft) => {
        draft.formData.operationalPartners = [...newPartners, { value: null }];
      });
    case actionCreators.updateOperationalPartner().type:
      return produce(state, (draft) => {
        const updatedPartners = draft.formData.operationalPartners
          ? [...draft.formData.operationalPartners]
          : [];
        if (updatedPartners[payload.index]) {
          updatedPartners[payload.index].value = payload.value;
        }
        draft.formData.operationalPartners = updatedPartners;
      });
    case actionCreators.removeOperationalPartner().type:
      const reducedPartners = state.formData.operationalPartners
        ? [...state.formData.operationalPartners]
        : [];
      reducedPartners.splice(payload.index, 1);

      return produce(state, (draft) => {
        draft.formData.operationalPartners = reducedPartners;
      });
    case actionCreators.addCommOnlyOperationalPartner().type:
      return produce(state, (draft) => {
        const newPartners = state.formData?.commOnlyOperationalPartners
          ? [...state.formData.commOnlyOperationalPartners]
          : [];
        draft.formData.commOnlyOperationalPartners = [...newPartners, { value: null }];
      });
    case actionCreators.updateCommOnlyOperationalPartner().type:
      return produce(state, (draft) => {
        const updatedPartners = draft.formData.commOnlyOperationalPartners
          ? [...draft.formData.commOnlyOperationalPartners]
          : [];
        if (updatedPartners[payload.index]) {
          updatedPartners[payload.index].value = payload.value;
        }
        draft.formData.commOnlyOperationalPartners = updatedPartners;
      });
    case actionCreators.removeCommOnlyOperationalPartner().type:
      return produce(state, (draft) => {
        const reducedPartners = state.formData.commOnlyOperationalPartners
          ? [...state.formData.commOnlyOperationalPartners]
          : [];
        reducedPartners.splice(payload.index, 1);

        draft.formData.commOnlyOperationalPartners = reducedPartners;
      });
    case actionCreators.addTransactionReportAccess().type:
      return produce(state, (draft) => {
        const newPeople = state.formData?.transactionReportAccessUsers
          ? [...state.formData.transactionReportAccessUsers]
          : [];
        draft.formData.transactionReportAccessUsers = [...newPeople, { value: null }];
      });
    case actionCreators.updateTransactionReportAccess().type:
      return produce(state, (draft) => {
        const updatedPeople = draft.formData.transactionReportAccessUsers
          ? [...draft.formData.transactionReportAccessUsers]
          : [];
        if (updatedPeople[payload.index]) {
          updatedPeople[payload.index].value = payload.value;
        }
        draft.formData.transactionReportAccessUsers = updatedPeople;
      });
    case actionCreators.removeTransactionReportAccess().type:
      return produce(state, (draft) => {
        const reducedPeople = state.formData.transactionReportAccessUsers
          ? [...state.formData.transactionReportAccessUsers]
          : [];
        reducedPeople.splice(payload.index, 1);

        draft.formData.transactionReportAccessUsers = reducedPeople;
      });
    case actionCreators.saveForm().type:
      return produce(state, (draft) => {
        draft.isSaving = true;
        draft.saveError = null;
      });
    case actionCreators.saveFormSuccess().type:
      return produce(state, (draft) => {
        draft.isSaving = false;
      });
    case actionCreators.saveFormError().type:
      const newState = produce(state, (draft) => {
        draft.isSaving = false;
        draft.saveError = payload.error;
      });
      return newState;
    default:
      return state;
  }
};
