import React from "react";

export const CountryCodes = [
  { label: "United States (+1)", value: "US" },
  { label: "Canada (+1)", value: "CA" },
];

export const LeadAssignInfo = {
  first_to_claim: {
    description: (
      <span>
        When a caller dials this number, all users below will be called at the same time. The first user to
        answer will be connected to the caller. <strong>First to Claim supports up to 10 users.</strong>
      </span>
    ),
    pretty: "First To Claim",
  },
  round_robin: {
    description: (
      <span>When a caller dials this number, they will be routed to the following agents in order.</span>
    ),
    pretty: "Round Robin",
  },
};
