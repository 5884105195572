import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuBase, MenuTitle } from "./Menu";
import IntegrationButton from "./IntegrationButton";
import IntegrationModal from "./IntegrationModal";
import { toggleMobileMenu } from "../../reducers/layoutReducer";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const AppsMenu = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const options = useSelector(({ layout }) => layout?.sidebar?.menu?.find((m) => m.name === "Apps")?.options);
  const [openIntegration, setOpenIntegration] = useState(null);

  useEffect(() => {
    if (openIntegration) {
      dispatch(toggleMobileMenu(false));
    }
  });

  return (
    <MenuBase className="tw-min-w-[200px]">
      <MenuTitle title="Apps" />
      <div className="tw-flex tw-flex-wrap tw-gap-[8px]">
        {options.map((integration) =>
          integration.comingSoon ? null : (
            <IntegrationButton
              key={integration.slug}
              integration={integration}
              onClick={() => setOpenIntegration(integration)}
            />
          ),
        )}
      </div>
      <IntegrationModal
        currentUser={currentUser}
        show={openIntegration}
        integration={openIntegration}
        onClose={() => setOpenIntegration(null)}
      />
    </MenuBase>
  );
};

export default AppsMenu;
