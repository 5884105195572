import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Tooltip from "@shared/v2/Tooltip";
import { calculatePercent, determineCorrectPercentColor, truncateTime } from "./reportHelpers";
import { defaultOrImage } from "../../shared/DefaultOrImage";

const AgentReportRow = ({
  report,
  total_calls: totalCalls,
  total_contacted: totalContacted,
  total_emails: totalEmails,
  total_leads: totalLeads,
  total_texts: totalTexts,
}) => (
  <div className="user td">
    <div className="col-xs-2">
      <a className="tw-flex tw-items-center tw-gap-[8px]" href={`/people/${report.user_path}`}>
        <div>{defaultOrImage(report.avatar, report.agent)}</div>
        <div className="tw-overflow-hidden tw-text-ellipsis">{report.agent}</div>
      </a>
    </div>
    <div className="col-xs-1 text-center" style={{ display: "inline-block" }}>
      <span>{report.lead_count}</span>
    </div>
    <div className="col-xs-1 text-center">
      <Tooltip
        id="email_count"
        placement="right"
        trigger={
          <span>
            {report.email_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  totalLeads,
                  totalEmails,
                  report.lead_count,
                  report.email_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.email_count).toFixed(0)}%)
            </span>
          </span>
        }
        content={
          <>
            <p style={{ paddingTop: "5px" }}>
              % of Assigned Leads:{" "}
              <span
                style={{
                  color: determineCorrectPercentColor(
                    totalLeads,
                    totalEmails,
                    report.lead_count,
                    report.email_count,
                  ),
                  fontSize: "13px",
                }}
              >
                {" "}
                ({calculatePercent(report.lead_count, report.email_count).toFixed(0)}%)
              </span>
            </p>
            <p>
              Team Average:{" "}
              <strong>
                <span style={{ fontSize: "13px" }}>
                  {" "}
                  ({calculatePercent(totalLeads, totalEmails).toFixed(0)}%)
                </span>
              </strong>
            </p>
          </>
        }
      />
    </div>
    <div className="col-xs-1 text-center">
      <Tooltip
        id="call_count"
        placement="right"
        trigger={
          <span>
            {report.call_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  totalLeads,
                  totalCalls,
                  report.lead_count,
                  report.call_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.call_count).toFixed(0)}%)
            </span>
          </span>
        }
        content={
          <>
            <p style={{ paddingTop: "5px" }}>
              % of Assigned Leads:{" "}
              <span
                style={{
                  color: determineCorrectPercentColor(
                    totalLeads,
                    totalCalls,
                    report.lead_count,
                    report.call_count,
                  ),
                  fontSize: "13px",
                }}
              >
                {" "}
                ({calculatePercent(report.lead_count, report.call_count).toFixed(0)}%)
              </span>
            </p>
            <p>
              Team Average:{" "}
              <strong>
                <span style={{ fontSize: "13px" }}>
                  {" "}
                  ({calculatePercent(totalLeads, totalCalls).toFixed(0)}%)
                </span>
              </strong>
            </p>
          </>
        }
      />
    </div>
    <div className="col-xs-1 text-center">
      <Tooltip
        id="contacted"
        placement="right"
        trigger={
          <span>
            {report.contacted_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  totalLeads,
                  totalCalls,
                  report.lead_count,
                  report.contacted_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.contacted_count).toFixed(0)}%)
            </span>
          </span>
        }
        content={
          <>
            <p style={{ paddingTop: "5px" }}>
              % Assigned Leads:{" "}
              <span
                style={{
                  color: determineCorrectPercentColor(
                    totalLeads,
                    totalCalls,
                    report.lead_count,
                    report.contacted_count,
                  ),
                  fontSize: "13px",
                }}
              >
                {" "}
                ({calculatePercent(report.lead_count, report.contacted_count).toFixed(0)}%)
              </span>
            </p>
            <p>
              Team Average:{" "}
              <strong>
                <span style={{ fontSize: "13px" }}>
                  {" "}
                  ({calculatePercent(totalLeads, totalContacted).toFixed(0)}%)
                </span>
              </strong>
            </p>
          </>
        }
      />
    </div>

    <div className="col-xs-1 text-center">
      <Tooltip
        id="text_count"
        placement="right"
        trigger={
          <span>
            {report.text_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  totalLeads,
                  totalTexts,
                  report.lead_count,
                  report.text_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.text_count).toFixed(0)}%)
            </span>
          </span>
        }
        content={
          <>
            <p style={{ paddingTop: "5px" }}>
              % of Assigned Leads:{" "}
              <span
                style={{
                  color: determineCorrectPercentColor(
                    totalLeads,
                    totalTexts,
                    report.lead_count,
                    report.text_count,
                  ),
                  fontSize: "13px",
                }}
              >
                {" "}
                ({calculatePercent(report.lead_count, report.text_count).toFixed(0)}%)
              </span>
            </p>
            <p>
              Team Average:{" "}
              <strong>
                <span style={{ fontSize: "13px" }}>
                  {" "}
                  ({calculatePercent(totalLeads, totalTexts).toFixed(0)}%)
                </span>
              </strong>
            </p>
          </>
        }
      />
    </div>
    <div className="col-xs-3" style={{ display: "flex", justifyContent: "flex-start", padding: 0 }}>
      <div className="col-xs-4 text-center">
        {report.email_resp_label !== "-" ? (
          <Tooltip
            id="email_resp_time"
            placement="right"
            trigger={<span>{truncateTime(report.email_resp_label)}</span>}
            content={report.email_resp_label}
          />
        ) : (
          <span>{truncateTime(report.email_resp_label)}</span>
        )}
      </div>
      <div className="col-xs-4 text-center">
        {report.call_resp_label !== "-" ? (
          <Tooltip
            id="call_resp_time"
            placement="right"
            trigger={<span>{truncateTime(report.call_resp_label)}</span>}
            content={report.call_resp_label}
          />
        ) : (
          <span>{truncateTime(report.call_resp_label)}</span>
        )}
      </div>
      <div className="col-xs-4 text-center">
        {report.text_resp_label !== "-" ? (
          <Tooltip
            id="text_resp_time"
            placement="right"
            trigger={<span>{truncateTime(report.text_resp_label)}</span>}
            content={report.text_resp_label}
          />
        ) : (
          <span>{truncateTime(report.text_resp_label)}</span>
        )}
      </div>
    </div>
    <div className="col-xs-1 text-center">{report.login_count}</div>
    {report.last_login ? (
      <div className="col-xs-1 text-center">{format(report.last_login, "MM/dd/yyyy")}</div>
    ) : (
      <div className="col-xs-1" />
    )}
  </div>
);

AgentReportRow.propTypes = {
  report: PropTypes.shape({
    agent: PropTypes.string,
    avatar: PropTypes.string,
    call_count: PropTypes.number,
    call_resp_label: PropTypes.string,
    contacted_count: PropTypes.number,
    email_count: PropTypes.number,
    email_resp_label: PropTypes.string,
    last_login: PropTypes.string,
    lead_count: PropTypes.number,
    login_count: PropTypes.number,
    text_count: PropTypes.number,
    text_resp_label: PropTypes.string,
    user_path: PropTypes.string,
  }).isRequired,
  total_calls: PropTypes.number.isRequired,
  total_contacted: PropTypes.number.isRequired,
  total_leads: PropTypes.number.isRequired,
  total_emails: PropTypes.number.isRequired,
  total_texts: PropTypes.number.isRequired,
};

export default AgentReportRow;
