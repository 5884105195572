/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CaretDownSolidV6 = (props) => (
  <Icon {...props} name="Caret Down Solid V6">
    <path d="m14.301 6.938-5.307 5.344c-.296.263-.639.392-.981.392s-.684-.13-.945-.391L1.761 6.939c-.419-.381-.534-.957-.327-1.454s.694-.827 1.234-.827h10.651c.54 0 1.028.325 1.235.825s.13 1.075-.254 1.455z" />
  </Icon>
);

export default CaretDownSolidV6;
