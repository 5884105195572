/* eslint-disable camelcase */
import React, { useState } from "react";
import { bool, number, oneOf, shape, string } from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";

import { PencilSolidV6, TrashCanSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import { toggleTaskActionModal, toggleEmailActionModal, toggleTextActionModal } from "../../actions/autoPlansActionCreators";
import TimeStamp from "./TimeStamp";
import Icon from "./Icon";
import ActionHeader from "./ActionHeader";
import ActionDescriptor from './ActionDescriptor';
import { useAutoPlanId } from "../../../reducers/autoPlanEdit";
import { deleteAction } from "../../actions/autoPlanActionCreators";


const ACTION_TYPE_TOGGLE_MODAL = {
  'Task': toggleTaskActionModal,
  'Email': toggleEmailActionModal,
  'Text': toggleTextActionModal
}

const convertFormTimestampToObject = ({local_run_at}) => ({
  run_time: moment(local_run_at).format('HH:mm')
});

const ActionV2 = ({action, from}) => {
  const [ showBtns, toggleBtns ] = useState(false);
  const autoPlanId = useAutoPlanId()
  const toggleModal = ACTION_TYPE_TOGGLE_MODAL[action.type];
  const dispatch = useDispatch();

  return (
    <div className="tw-flex tw-h-[80px] tw-pt-[8px]">
      <TimeStamp
        date={action.day_month_year}
        localRunTime={action.local_run_at}/>
      <div 
        className={`tw-flex tw-rounded-[8px] tw-pt-8px hover:tw-bg-tinted-gray-50 tw-cursor-default ${action.contingent ? 'tw-pl-96px' : 'tw-pl-40px'} tw-w-full`}
        onMouseEnter={() => toggleBtns(true)}
        onMouseLeave={() => toggleBtns(false)}>
        <Icon
          type={action.type ? action.type : 'Task' }
          contingent={action.contingent}/>
        <div className="tw-ml-16px tw-pt-[12px]">
          <ActionHeader
            type={action.type ? action.type : 'Task'}
            name={action.name}
            priority={action.priority}
            />
          <ActionDescriptor
            from={from}/>
        </div>
        {showBtns &&
          <div className="tw-flex tw-gap-[8px] tw-ml-auto tw-pt-[16px] tw-pr-[8px]">
            <IconButton 
              onClick={() => dispatch(toggleModal(true, 'put', {
                ...action,
                ...convertFormTimestampToObject(action)
              }))}
              schema='tertiary' 
              size='small'
              className='tw-flex tw-items-center tw-justify-center tw-h-[24px] tw-w-[24px]'>
              <PencilSolidV6 size='m'/>
            </IconButton>
            <IconButton
              onClick={() => dispatch(deleteAction(
                `/auto_plans/${autoPlanId}/actions/${action.id}`,
                action.id,
                `/auto_plans/${autoPlanId}`
              ))} 
              size='small'
              schema='tertiary' 
              className='tw-flex tw-items-center tw-justify-center tw-h-[24px] tw-w-[24px]'>
              <TrashCanSolidV6 size='m'/>
            </IconButton>
          </div>     
        }   
      </div>
    </div>
  )
};

export default ActionV2;

ActionV2.propTypes = {
  action: shape({
    day_month_year: string,
    run_time: string,
    type: oneOf(['Task', 'Email', 'Text']),
    name: string,
    priority: number,
    contingent: bool
  }).isRequired,
  from: string.isRequired
}
