import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Row } from "../../../../../shared/v1";
import UploadedDocHeader from "./UploadedDocHeader";
import UploadedDocs from "./UploadedDocs";

const CDAdoc = ({ CDAuploadErrors, docs }) => (
  <>
    {CDAuploadErrors.length > 0 && (
      <div className="tw-px-7px tw-text-12d tw-font-normal tw-text-semantic-red-100" colSpan={2}>
        {CDAuploadErrors.join(" ")}
      </div>
    )}
    <Row className="tw-p-[24px] tw-m-0 tw-mx-[0px] tw-p-0 tw-border-[1px] tw-border-solid tw-border-neutral-gray-10 tw-rounded-[8px]">
      <UploadedDocHeader />
      {Boolean(docs.length) && <UploadedDocs docs={docs} />}
    </Row>
  </>
);

CDAdoc.propTypes = {
  CDAuploadErrors: PropTypes.arrayOf(PropTypes.string),
  docs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
CDAdoc.defaultProps = {
  CDAuploadErrors: [],
};
const mapStateToProps = (state) => ({
  CDAuploadErrors: state.tdpDocumentsReducer.CDAuploadErrors,
  docs: state.tdpDocumentsReducer.CDAdocuments,
});
export default connect(mapStateToProps)(CDAdoc);
