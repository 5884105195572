import PropTypes from "prop-types";
import nullable from "prop-types-nullable";

const valueAndTypeShape = PropTypes.shape({
  value: nullable(PropTypes.string).isRequired,
  type: PropTypes.oneOf(["percent", "flat"]),
});

const gciDataShape = PropTypes.shape({
  closePrice: PropTypes.shape({
    value: nullable(PropTypes.string).isRequired,
  }).isRequired,
  commission: valueAndTypeShape.isRequired,
  netCommission: nullable(PropTypes.string).isRequired,
  grossCommission: nullable(PropTypes.string).isRequired,
  subtotal: nullable(PropTypes.string).isRequired,
});

const lineItemOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  meta: PropTypes.shape({
    type: PropTypes.oneOf(["expense", "income"]),
    isCustomOption: PropTypes.bool,
    name: PropTypes.string,
  }),
});

const basicLineItemShape = PropTypes.shape({
  selectedOption: lineItemOptionShape.isRequired,
  options: PropTypes.arrayOf(lineItemOptionShape).isRequired,
  id: PropTypes.number,
  position: PropTypes.number,
  expenseOrIncome: PropTypes.oneOf(["expense", "income"]).isRequired,
  notes: nullable(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  valueType: PropTypes.oneOf(["percent", "flat"]).isRequired,
  calculatedValue: PropTypes.string.isRequired,
  subtotal: nullable(PropTypes.string).isRequired,
  isEditable: PropTypes.bool,
  isRemovable: PropTypes.bool,
});

const brokerageSplitAndRoyaltyLineItemShape = PropTypes.shape({
  position: PropTypes.number,
  brokerageSplitItem: basicLineItemShape.isRequired,
  royaltyItem: basicLineItemShape.isRequired,
});

export const lineItemShape = PropTypes.oneOfType([basicLineItemShape, brokerageSplitAndRoyaltyLineItemShape]);

export const transactionShape = PropTypes.shape({
  estimated: gciDataShape.isRequired,
  actual: gciDataShape.isRequired,
  isActualGCI: PropTypes.bool.isRequired,
  hasFinancialData: PropTypes.bool.isRequired,
  notes: PropTypes.string,
});

export const teamMemberShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  personId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  profilePic: PropTypes.string,
  role: PropTypes.string,
  gci: valueAndTypeShape.isRequired,
  unit: PropTypes.number.isRequired,
  notes: nullable(PropTypes.string).isRequired,
  subtotal: nullable(PropTypes.string).isRequired,
  netIncome: nullable(PropTypes.string).isRequired,
});

export const companyShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  profilePic: PropTypes.string,
  role: PropTypes.string,
  gci: valueAndTypeShape.isRequired,
  subtotal: nullable(PropTypes.string).isRequired,
  netIncome: nullable(PropTypes.string).isRequired,
});
