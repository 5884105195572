import React from "react";
import PropTypes from "prop-types";

const DncFlag = ({ show }) => {
  if (!show) return null;
  return (
    <div className="tw-py-0 tw-px-4px tw-rounded-4px tw-border-[2px] tw-border-solid tw-border-[#E5342E] tw-text-[#E5342E] tw-text-[8px] tw-font-bold tw-w-fit tw-leading-[16px]">
      DNC
    </div>
  );
};

DncFlag.propTypes = {
  value: PropTypes.bool,
};

export default DncFlag;
