/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ShareFromSquareSolidV6 = (props) => (
  <Icon {...props} name="Share From Square Solid V6">
    <path d="m15.803 4.875-4.192-3.839c-.367-.335-.944-.063-.944.459v2.06C6.7 3.6 3.556 4.391 3.556 8.127c0 1.508.978 3.003 2.058 3.783.337.244.817-.062.693-.457-1.12-3.559.805-4.343 4.361-4.368v2.061c0 .523.579.794.945.459l4.192-3.839c.263-.217.263-.65-.001-.892zm-4.247 6.68a.889.889 0 0 0-.889.889v.864H1.778V4.444h.889a.889.889 0 1 0 0-1.778h-.889C.796 2.666 0 3.462 0 4.444v8.864c0 .982.796 1.778 1.778 1.778h8.889c.982 0 1.778-.796 1.778-1.778v-.864a.888.888 0 0 0-.889-.889z" />
  </Icon>
);

export default ShareFromSquareSolidV6;
