import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import GmailThread from "./GmailThread";
import { Col, Row } from "../../../shared/v1";
import TextButton from "../../../shared/v2/TextButton";
import Button from "../../../shared/v2/Button";

export default class GmailInteraction extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
      recipientsEmails: [],
      ccEmails: [],
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
    this.event = this.props.interaction;

    this.hideGmailModal = this.hideGmailModal.bind(this);
    this.showEmailModal = this.showEmailModal.bind(this);
    this.row = this.row.bind(this);
    this.headline = this.headline.bind(this);
    this.showMessages = this.showMessages.bind(this);
  }

  componentDidMount() {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    if (this.event.most_recent_message_to) {
      // Regular expression to match email addresses
      // Use the match method to find all email addresses in the most_recent_message_to
      const recipientEmailAddresses = this.event.most_recent_message_to.match(emailRegex);
      if (recipientEmailAddresses) {
        this.setState({ recipientsEmails: recipientEmailAddresses });
      }
    }

    if (this.event.most_recent_message_cc) {
      // Regular expression to match email addresses
      // Use the match method to find all email addresses in the most_recent_message_cc
      const ccEmailAddresses = this.event.most_recent_message_cc.match(emailRegex);
      if (ccEmailAddresses) {
        this.setState({ ccEmails: ccEmailAddresses });
      }
    }
  }

  showGmailModal = () => {
    this.setState({ show_modal: true });
  };
  hideGmailModal = () => {
    this.setState({ show_modal: false });
  };

  headline = () => {
    const message_count = this.event.messages.length;
    const message_count_string = message_count == 1 ? "" : ` (${message_count})`;

    return (
      <div>
        <span className="bold-detail">{this.event.most_recent_message_from}</span>
        {` emailed `}
        <span className="bold-detail">{this.event.most_recent_message_to}</span>
        {message_count_string}
      </div>
    );
  };

  attachmentIcon = () => {
    return <i className="attachment-icon fa fa-paperclip" />;
  };

  row = () => {
    return (
      <div className="timeline-event mail-timeline-event mail-event-content" onClick={this.showGmailModal}>
        <div className="row header">
          <div className="col-xs-1">
            <i className="fa fa-envelope-o timeline-icon-circle" />
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className="bold-detail mail-row-subject">{this.event.subject}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                  <div dangerouslySetInnerHTML={{ __html: this.event.snippet }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            {this.event.has_attachment && this.attachmentIcon()}
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  showEmailModal = () => {
    this.setState({ show_modal: false });

    Brivity.EmailInteraction.showModal(
      this.props.person_id,
      null,
      this.props.interaction.id,
      this.props.interaction.subject,
    );
  };

  handleReplyClick = () => {
    const { newDetailsPage, setInteractionTab, setGmailReplyThread } = this.props;
    if (newDetailsPage) {
      const additionalRecipient =
        this.state.recipientsEmails && this.state.recipientsEmails.length > 1
          ? this.state.recipientsEmails.slice(1)
          : [];
      const ccVal = this.state.ccEmails || [];
      const bccVal = this.event.bcc || [];
      setGmailReplyThread(
        this.props.interaction.id,
        `RE: ${this.props.interaction.subject}`,
        additionalRecipient,
        ccVal,
        bccVal,
      );
      setInteractionTab(1);
      this.hideGmailModal();
      window.scrollTo(0, 0);
    } else {
      this.showEmailModal();
    }
  };

  showMessages = () => {
    return <GmailThread messages={this.event.messages} />;
  };

  modal = () => {
    return (
      <Modal
        className="mail-event-modal timeline-mail-modal"
        bsSize="lg"
        show={this.state.show_modal}
        onHide={this.hideGmailModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
          <h4 className="subject">Subject: {this.event.subject}</h4>
          {this.showMessages()}

          <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pt-[5px]">
            <Col size={7} className="tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px] tw-p-0">
              <Button
                onClick={this.hideGmailModal}
                className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
                schema="tertiary"
                size="medium"
              >
                CLOSE
              </Button>
            </Col>
            <Col
              size={5}
              className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-flex tw-justify-end"
            >
              {this.state.recipientsEmails && this.state.recipientsEmails.length > 1 && (
                <TextButton
                  onClick={this.handleReplyClick}
                  className="tw-float-left tw-mr-16px"
                  size="medium"
                >
                  REPLY ALL
                </TextButton>
              )}

              <Button
                onClick={this.handleReplyClick}
                className="tw-flex tw-items-center"
                schema="primary"
                size="medium"
              >
                REPLY
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
