import React from "react";
import { string, shape, func, bool } from "prop-types";

import FlatPercentInput from "@shared/v2/FlatPercentInput";
import FlatPercentToggle from "@shared/v2/FlatPercentToggle";
import RadioButton from "@shared/v2/RadioButton";
import Checkbox from "@shared/v2/Checkbox/Checkbox";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon/Icons";
import Tooltip from "@shared/v2/Tooltip";
import { formatExistingNumber } from "../api/helpers";

const BACModalBody = ({ BACval, handleUpdateBAC }) => {
  const { percentage, value, cooperative_compensation, exclude_from_commissions } = BACval;

  return (
    <div>
      <span className="tw-text-[#999999]">
        Active listings with a valid MLS number and a buyer agent commission will appear on your{" "}
        <span
          onClick={() =>
            window.open("https://www.brivityknowledge.com/docs/buyer-agent-commission", "_blank")
          }
          className="tw-text-teal tw-cursor-pointer"
        >
          compensation page
        </span>
      </span>
      <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px] tw-pt-[32px] tw-pb-[24px]">
        <RadioButton
          label="Specific Value"
          name="specific_val"
          checked={!cooperative_compensation}
          value=""
          onChange={() => handleUpdateBAC("cooperative_compensation", false)}
          data-cy="bac-radio"
        />
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
          <RadioButton
            label="Cooperative Compensation"
            name="cooperative_compensation"
            checked={cooperative_compensation}
            value=""
            onChange={() => {
              handleUpdateBAC("cooperative_compensation", true);
              handleUpdateBAC("value", "");
              handleUpdateBAC("percentage", true);
              handleUpdateBAC("exclude_from_commissions", false);
            }}
            data-cy="bac-cooperative-compensation-radio"
          />
          <Tooltip
            multiline
            placement="top"
            content={
              <div className="tw-w-[266px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                Use this option if you do not want to display a specific percentage or amount. “Contact Us”
                will be displayed on your website.
              </div>
            }
            trigger={<CircleInfoSolidV6 />}
          />
        </div>
      </div>
      {cooperative_compensation ? (
        <div className="tw-flex tw-bg-[#F5F5F5] tw-border-[1px] tw-border-[#EBEBEB] tw-border-solid tw-rounded-[2px] ">
          <div className="tw-py-[24px] tw-px-[16px]">
            <CircleInfoSolidV6 size="l" className="tw-text-[#999999]" />
          </div>
          <div className="tw-pr-[16px] tw-py-[12px] tw-text-[14px] tw-text-[#666666]">
            “Contact us” will be displayed in place of a specific compensation value on your website.
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-[4px]">
          <div className="tw-w-[296px]">
            <FlatPercentInput
              className="tw-w-[296px]"
              label="Buyer Agent Commission"
              isRequired
              value={!percentage ? formatExistingNumber(String(value)) : String(value)}
              customFormat={!percentage ? formatExistingNumber : null}
              isPercentage={percentage}
              onChange={(e) => {
                handleUpdateBAC("value", e.target.value);
                if (e.target.value == "") {
                  handleUpdateBAC("exclude_from_commissions", false);
                }
              }}
            />
          </div>
          <div className="tw-mt-[28px]">
            <FlatPercentToggle
              isPercentage={percentage}
              onChange={() => {
                handleUpdateBAC("percentage", !percentage);
                handleUpdateBAC("value", 0);
                handleUpdateBAC("exclude_from_commissions", false);
              }}
            />
          </div>
        </div>
      )}

      <div className="tw-py-[24px]">
        {!cooperative_compensation && !value ? (
          <Tooltip
            multiline
            placement="top"
            content={
              <div className="tw-w-[266px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                Enter a value for Buyer Agent Commission
              </div>
            }
            trigger={
              <Checkbox
                onChange={() => handleUpdateBAC("exclude_from_commissions", !exclude_from_commissions)}
                disabled={!cooperative_compensation && !value}
                checked={exclude_from_commissions}
                value={exclude_from_commissions}
                name="exclude_from_commissions"
                id="exclude_from_commissions"
                label="Exclude from my compensation page"
              />
            }
          />
        ) : (
          <Checkbox
            onChange={() => handleUpdateBAC("exclude_from_commissions", !exclude_from_commissions)}
            disabled={!cooperative_compensation && !value}
            checked={exclude_from_commissions}
            value={exclude_from_commissions}
            name="exclude_from_commissions"
            id="exclude_from_commissions"
            label="Exclude from my compensation page"
          />
        )}
      </div>
      <span className="tw-text-[#999999]">
        By adding this information I acknowledge that this listing is listed by my company and that I have
        permission to enhance the listing data.
      </span>
    </div>
  );
};

BACModalBody.propTypes = {
  BACval: shape({
    percentage: bool,
    value: string,
    cooperative_compensation: bool,
    exclude_from_commissions: bool,
    status: string,
  }),
  handleUpdateBAC: func,
};
BACModalBody.defaultProps = {
  BACval: {
    percentage: true,
    value: "",
    cooperative_compensation: false,
    exclude_from_commissions: false,
    status: "",
  },
  handleUpdateBAC: null,
};
export default BACModalBody;
