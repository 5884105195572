import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getDaysInMonth } from "date-fns";
import Dropdown from "./Dropdown";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import FieldHelper from "./FieldHelper";
import IconButton from "./IconButton";
import { TrashCanSolidV6 } from "./Icomoon";

const MONTH_OPTIONS = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((m) => ({
  value: m,
  label: m,
}));

const YEAR_OPTIONS = (() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 1900; i <= currentYear; i += 1) {
    const year = i.toString();
    years.push({ value: year, label: year });
  }
  return years;
})();

const useDayOptions = (year = 0, month = 1) =>
  useMemo(() => {
    const daysInMonth = getDaysInMonth(new Date(year, month - 1));
    const days = [];
    for (let i = 1; i <= daysInMonth; i += 1) {
      const day = i.toString().padStart(2, "0");
      days.push({ value: day, label: day });
    }
    return days;
  }, [year, month]);

const DropdownDatePicker = ({ className, error, helperText, label, onChange, value }) => {
  const prevValue = useRef(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);
  const DAY_OPTIONS = useDayOptions(year?.value, month?.value);

  useEffect(() => {
    const lastDay = DAY_OPTIONS[DAY_OPTIONS.length - 1];
    if ((day?.value || 0) > lastDay.value) {
      setDay(lastDay);
    }
  }, [DAY_OPTIONS, day]);

  const date = useMemo(
    () =>
      month || day || year ? `${year?.value || "0000"}-${month?.value || ""}-${day?.value || ""}` : null,
    [month, day, year],
  );

  useEffect(() => {
    if (value !== prevValue.current) {
      const [y, m, d] = (value || "").split("-");
      setYear(YEAR_OPTIONS.find((o) => o.value === y) || null);
      setMonth(MONTH_OPTIONS.find((o) => o.value === m) || null);
      setDay(DAY_OPTIONS.find((o) => o.value === d) || null);
    }
  }, [value]);

  useEffect(() => {
    prevValue.current = date;
    onChange(date);
  }, [date]);

  return (
    <div className={`tw-flex tw-flex-col ${className}`}>
      {label && <FieldLabel className="tw-mb-[5px]" label={label} />}
      <div className={`tw-flex tw-gap-[4px] ${className || ""}`}>
        <Dropdown
          containerClassName="tw-w-[70px]"
          placeholder="MM"
          isSearchable
          options={MONTH_OPTIONS}
          value={month}
          onChange={setMonth}
        />
        <Dropdown
          containerClassName="tw-w-[70px]"
          placeholder="DD"
          isSearchable
          options={DAY_OPTIONS}
          value={day}
          onChange={setDay}
        />
        <Dropdown
          containerClassName="tw-flex-1"
          placeholder="YYYY"
          isSearchable
          options={YEAR_OPTIONS}
          value={year}
          onChange={setYear}
        />
        <IconButton
          schema="tertiary"
          className="!tw-rounded-[4px] tw-w-[36px] tw-h-[36px]"
          onClick={() => {
            setYear(null);
            setMonth(null);
            setDay(null);
          }}
        >
          <TrashCanSolidV6 size="l" />
        </IconButton>
      </div>
      {helperText && !error && <FieldHelper helperText={helperText} />}
      {error && <FieldError error={error} />}
    </div>
  );
};

DropdownDatePicker.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

DropdownDatePicker.defaultProps = {
  className: "",
  error: "",
  helperText: "",
  label: "",
  value: "",
};

export default DropdownDatePicker;
