import React, { useEffect } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as actionCreators from "../actions/thunks";
import CardsCount from "./CardsCount";
import AssetsCards from "../AssetsCards";
import NoListing from "./NoListing";
import errorShape from "../model/error";
import Error from "../Error";
import Header from "../../../UI/Header";
import MarketingHeaderOptions from "../HeaderOptions/MarketingHeaderOptions";
import ListingViews from "../MarketingMaterials/ListingViews";
import LoadingOverlay from "../../../../shared/v2/LoadingOverlay";

const Cards = ({
  uuid,
  isMarketingLoading,
  mlsID,
  getAssetsCardViewsAsThunk,
  getListingMlsIDAsThunk,
  assetsCardViews,
  getAssetCardError,
  fetchedMlsId,
}) => {
  useEffect(() => {
    if (fetchedMlsId && mlsID) {
      getAssetsCardViewsAsThunk(uuid);
    }
  }, [fetchedMlsId, mlsID]);

  useEffect(() => {
    if (!fetchedMlsId) {
      getListingMlsIDAsThunk(uuid);
    }
  }, [fetchedMlsId]);

  const MarketingMaterials = () => (
    <>
      <ListingViews shareLink={assetsCardViews?.share_link} />
      <div
        className="tw-pb-[21px] tw-grid-cols-5 tw-grid tw-gap-2"
        data-cy="tdp-marketing-materials-container"
      >
        {assetsCardViews?.mailers && (
          <CardsCount
            counter={assetsCardViews.mailers.length}
            cardName="Mailer Campaigns"
            name="Mailer Campaigns"
          />
        )}
        {assetsCardViews?.ads && (
          <CardsCount counter={assetsCardViews.ads.length} cardName="Digital Ads" name="Digital Ads" />
        )}
        {assetsCardViews?.print_media && (
          <CardsCount
            counter={assetsCardViews.print_media.length}
            cardName="Printed Media"
            name="Printed Media"
          />
        )}
        {assetsCardViews?.social_media && (
          <CardsCount
            counter={assetsCardViews.social_media.length}
            cardName="Social Media Graphics"
            name="Social Graphics"
          />
        )}
        {assetsCardViews?.videos && (
          <CardsCount counter={assetsCardViews.videos.length} cardName="Videos" name="Videos" />
        )}
      </div>
      <AssetsCards />
    </>
  );

  const states = {
    fetchingMls: !fetchedMlsId,
    import: fetchedMlsId && !mlsID,
    marketing: fetchedMlsId && mlsID,
  };

  const currentState = Object.keys(states).find((key) => states[key]);

  const components = {
    fetchingMls: <p>Loading Mls Id</p>,
    import: <NoListing />,
    marketing: <MarketingMaterials />,
  };

  return (
    <>
      <div className="tw-mt-[16px] tw-mb-24px">
        <Header header="Marketing Materials" add={<MarketingHeaderOptions />} />
      </div>
      <LoadingOverlay isLoading={isMarketingLoading}>
        {fetchedMlsId && mlsID && getAssetCardError && <Error message={getAssetCardError.message} />}
        <div className="tw-min-h-[116px]">{components[currentState]}</div>
      </LoadingOverlay>
    </>
  );
};
Cards.propTypes = {
  uuid: Proptypes.string.isRequired,
  mlsID: Proptypes.string,
  isMarketingLoading: Proptypes.bool,
  getAssetsCardViewsAsThunk: Proptypes.func.isRequired,
  getListingMlsIDAsThunk: Proptypes.func.isRequired,
  getAssetCardError: errorShape,
  assetsCardViews: Proptypes.shape({
    ads: Proptypes.arrayOf(Proptypes.shape({})),
    social_media: Proptypes.arrayOf(Proptypes.shape({})),
    mailers: Proptypes.arrayOf(Proptypes.shape({})),
    print_media: Proptypes.arrayOf(Proptypes.shape({})),
    videos: Proptypes.arrayOf(Proptypes.shape({})),
    share_link: Proptypes.string,
  }),
  fetchedMlsId: Proptypes.bool,
};
Cards.defaultProps = {
  mlsID: null,
  isMarketingLoading: false,
  getAssetCardError: null,
  assetsCardViews: { ads: [], social_media: [], mailers: [], print_media: [], videos: [] },
  fetchedMlsId: false,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  mlsID: state.tdpMarketingReducer.mlsID,
  getAssetCardError: state.tdpMarketingReducer.getAssetCardError,
  assetsCardViews: state.tdpMarketingReducer.assetsCardViews,
  isMarketingLoading: state.tdpMarketingReducer.isMarketingLoading,
  fetchedMlsId: state.tdpMarketingReducer.fetchedMlsId,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetsCardViewsAsThunk: (uuid) => dispatch(actionCreators.getAssetsCardViewsAsThunk(uuid)),
  getListingMlsIDAsThunk: (uuid) => dispatch(actionCreators.getListingMlsIDAsThunk(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cards);
