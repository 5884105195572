/* eslint-disable react/prop-types */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const InlineEditIcon = (props) => (
  <FontAwesomeIcon style={{ fontSize: "0.675em" }} icon={faPencilAlt} {...props} />
);

export default InlineEditIcon;
