import React from "react";
import PropTypes from "prop-types";

const BaseEvent = ({ children, icon, interactedAt, title }) => (
  <div className="tw-p-[24px] tw-bg-white tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-10 tw-flex tw-items-start tw-gap-[24px]">
    <div className="tw-flex tw-flex-none tw-items-center tw-justify-center tw-border-solid tw-border-[2px] tw-border-neutral-gray-75 tw-rounded-full tw-p-[10px]">
      {icon}
    </div>
    <div className="tw-text-14d tw-flex tw-flex-col tw-gap-[4px] tw-flex-1">
      <div className="tw-flex tw-justify-between tw-gap-[8px]">
        {title}
        <span className="tw-text-12d">{interactedAt}</span>
      </div>

      {children}
    </div>
  </div>
);

BaseEvent.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  interactedAt: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default BaseEvent;
