/* eslint-disable react/prop-types, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from "react";
import { SortIcon } from "../icons";
import { ASCENDING_SORT, DESCENDING_SORT } from "../icons/SortIcon";

const isSortedBy = (options, value) => options.find((option) => option === value);
function ascOrDesc(sortedBy) {
  if (!sortedBy) {
    return null;
  }
  // assumes 'x' and 'x_desc' as the template
  const isDescending = sortedBy.slice(-4) === "desc";
  return isDescending ? DESCENDING_SORT : ASCENDING_SORT;
}
const sortStatus = (options, value) => ascOrDesc(isSortedBy(options, value));

const HeaderCell = ({
  title,
  isSortable,
  sortedBy,
  onSort,
  sortOptions = [],
  justify,
  px,
  className,
  fontSettings,
}) => {
  const sortBy = isSortable ? sortStatus(sortOptions, sortedBy) : null;
  const toggleSortBy = () => {
    const order = sortBy === ASCENDING_SORT ? DESCENDING_SORT : ASCENDING_SORT;
    onSort(order === ASCENDING_SORT ? sortOptions[0] : sortOptions[1]);
  };

  const cursor = isSortable ? "tw-cursor-pointer" : "";
  const onClick = isSortable ? toggleSortBy : null;
  const textAlignment = justify || "tw-justify-start";
  const font = fontSettings || "tw-font-normal";

  return (
    <th
      data-cy={`table-heading-${title.toLowerCase().split(" ").join("-")}`}
      className={`
        tw-sticky tw-top-0
        tw-border-0 tw-border-b tw-border-solid tw-border-gray-light tw-bg-white
        tw-py-6px ${px || "tw-px-28px"}
        ${className}
      `}
    >
      <div className={`tw-flex tw-w-full ${textAlignment} tw-items-center ${font} tw-font-sans`}>
        <span className={`${cursor} tw-select-none`} onClick={onClick}>
          {title}
        </span>
        {isSortable && (
          <div onClick={onClick} className={`${cursor} tw-pl-6px tw-flex`}>
            <SortIcon sortBy={sortBy} />
          </div>
        )}
      </div>
    </th>
  );
};

export default HeaderCell;
