/* eslint-disable no-unneeded-ternary */
import React from "react";
import { func, shape, string, bool } from "prop-types";
import FieldLabel from "../../../../shared/v2/FieldLabel";
import InlineEditingDatePicker from "@shared/v2/InlineEditingDatePicker";

const DATE_FORMAT = "MM/DD/YYYY";
const DATE_TIME_FORMAT = `${DATE_FORMAT} hh:mm A`;

const DetailDateDropdown = ({ date, onBlur, isNew, idFromLists, label, dataCy, containerClass }) => {
  const dateValue = `${date?.date}${date?.display_time ? ` ${date.display_time}` : ""}`;

  const submitDate = (newValue) => {
    const hasTime = / /.test(newValue);
    const newDate = moment.utc(newValue, hasTime ? DATE_TIME_FORMAT : DATE_FORMAT);
    if (newValue !== "" && !newDate.isValid()) return;
    const secondsSinceMidnight = hasTime ? newDate.diff(moment(newDate).startOf("day"), "seconds") : null;
    const data = {
      new_milestone: isNew,
      date: newDate,
      seconds_since_midnight: secondsSinceMidnight,
    };
    onBlur(date?.id || idFromLists, data);
  };

  return (
    <div
      className={`tw-relative tw-h-36px tw-flex tw-flex-row tw-items-center tw-ml-[-8px] tw-p-8px hover:tw-bg-neutral-gray-5 tw-rounded-4px tw-w-calc[100% + 16px] tw-ml-[-8px] tw-p-8px ${containerClass}`}
      data-cy={dataCy}
    >
      <FieldLabel label={label} />
      <InlineEditingDatePicker
        className={`tw-absolute tw-leading-36px ${date?.date ? "tw-right-[15px]" : "tw-right-[37px]"}`}
        portalDOMElement="date-portal"
        position="left"
        value={dateValue}
        customText={date?.display_time}
        dateFormat={DATE_FORMAT}
        enableTimePicker={date?.include_time}
        timeOnNewLine={date?.include_time}
        onChange={submitDate}
      />
    </div>
  );
};

DetailDateDropdown.propTypes = {
  date: shape({
    id: string,
    date: string,
    name: string,
    include_time: bool,
    display_time: string,
  }),
  label: string,
  onBlur: func.isRequired,
  isNew: bool,
  dataCy: string,
  idFromLists: string,
  containerClass: string,
};

DetailDateDropdown.defaultProps = {
  date: null,
  isNew: false,
  dataCy: null,
  idFromLists: "",
  label: "",
  containerClass: "",
};

export default DetailDateDropdown;
