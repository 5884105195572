import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import Button from "@shared/v2/Button";
import TabBar from "@shared/v2/TabBar/TabBar";
import Tab from "@shared/v2/Tab/Tab";
import Checkbox from "../Checkbox";
import Loader from "../Loader";
import ScrollButton from "../ScrollButton";
import { EventItem, AutoPlanEvent } from "..";
import NoEventsIcon from "./assets/NoEventsIcon.png";
import NoAutoPlanEventsIcon from "./assets/NoAutoPlanEventsIcon.png";
import useRecentActivity from "../../services/useRecentActivity";
import Error from "../../../Reports/components/DashboardShared/Error";
import SmsModal from "../../../SmsModal/components/SmsModal";
import { useMessagingServiceUrl, useFeatureFlags } from "../../../reducers/layoutReducer/selectors";
import SingleDialerModal from "../../../Dialer/SingleDialerModal";

const TIMELINES_DEFAULT_FILTERS = [
  "Showing request",
  "Home valuation",
  "Start offer form",
  "Ask a question form",
  "Brivity Connect retargeting",
  "Brivity Connect remarketing",
];

const AUTO_PLAN_DEFAULT_FILTERS = [
  "Cannot determine recipient",
  "Cannot determine sender",
  "Email address invalid",
  "Failed to create task",
  "Failed to send email",
  "Failed to send text",
  "No email address",
  "No phone number",
  "Unmatched placeholders",
  "Unsubscribed",
];

const AUTO_PLAN_DISMISSED_AND_DEFAULT_FILTERS = AUTO_PLAN_DEFAULT_FILTERS.concat("Dismissed");

const EVENT_COMPONENTS = {
  timelines: EventItem,
  auto_plans: AutoPlanEvent,
};

const EMPTY_EVENT_COMPONENTS = {
  timelines: NoEventsIcon,
  auto_plans: NoAutoPlanEventsIcon,
};

const RecentActivity = ({ activeTab, setActiveTab, userUuids }) => {
  const messagingServiceUrl = useMessagingServiceUrl();
  const featureFlags = useFeatureFlags();
  const [showSmsModal, setShowSmsModal] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const { actions, data, loading, error } = useRecentActivity({
    eventType: activeTab,
    userUuids,
  });
  const events = useMemo(
    () =>
      (activeFilters?.[0] === "Dismissed"
        ? data.filter((event) => event.dismissed)
        : data.filter((event) => !event.dismissed && activeFilters.includes(event.type))
      ).sort((a, b) => b.time_ago_epoch - a.time_ago_epoch),
    [data, activeFilters],
  );
  const allFilters =
    activeTab === "timelines" ? TIMELINES_DEFAULT_FILTERS : AUTO_PLAN_DISMISSED_AND_DEFAULT_FILTERS;
  const EventComponent = EVENT_COMPONENTS[activeTab];
  const EmptyEventIcon = EMPTY_EVENT_COMPONENTS[activeTab];

  const resetFilters = () =>
    setActiveFilters(activeTab === "timelines" ? TIMELINES_DEFAULT_FILTERS : AUTO_PLAN_DEFAULT_FILTERS);

  const handleFilterChange = (f, checked) => {
    if (f === "Dismissed") {
      setActiveFilters(checked ? [f] : AUTO_PLAN_DEFAULT_FILTERS);
    } else if (checked) {
      setActiveFilters((currentFilters) => [...currentFilters, f]);
    } else {
      setActiveFilters((currentFilters) => currentFilters.filter((filter) => f !== filter));
    }
  };

  useEffect(resetFilters, [activeTab]);

  return (
    <div className="tw-bg-white tw-relative tw-flex tw-flex-col tw-min-h-[800px] tw-p-[20px] tw-rounded-[8px]">
      <div className="tw-flex tw-justify-between tw-items-end tw-border-solid tw-border-neutral-gray-5 tw-border-0 tw-border-b-[2px]">
        <TabBar>
          <Tab
            selected={activeTab === "timelines"}
            onClick={() => setActiveTab("timelines")}
            label="Recent Activity"
          />
          <Tab
            selected={activeTab === "auto_plans"}
            onClick={() => setActiveTab("auto_plans")}
            label="Auto Plans"
          />
        </TabBar>
        <div className="tw-flex tw-flex-col">
          <Button className="tw-mb-[4px]" onClick={() => setIsFilterOpen((open) => !open)} variant="info">
            Filtered
          </Button>
          {isFilterOpen && (
            <div className="tw-z-10 tw-border-solid tw-border-gray-light tw-w-[220px] tw-self-end tw-absolute tw-pb-0 tw-mt-[37px] tw-bg-white">
              <div className="tw-flex tw-justify-between tw-items-center tw-bg-[#f8f7f6] tw-m-[2px] tw-mb-10px tw-p-[4px] tw-font-light tw-border-solid tw-border-0 tw-border-b-[2px] tw-border-gray-light">
                <span>Timeline Filter</span>
                {/*
                    TODO: This is where we need to query out to the services to get the right information
                    based on current
                    TODO: filter selection.
                  */}
                <i
                  role="button"
                  alt="Refresh"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key !== "Tab") e.preventDefault();
                    if (["Enter", " "].includes(e.key)) resetFilters();
                  }}
                  onClick={resetFilters}
                  className="tw-text-14px tw-text-[#b2b2b4] fa fa-repeat"
                />
              </div>
              {allFilters.map((f) => (
                <div
                  key={f}
                  className={`tw-pt-[2px] tw-pr-[5px] tw-pl-[5px] ${
                    f === "Dismissed" ? "tw-pb-[3px]" : "tw-pb-[6px]"
                  }`}
                >
                  <Checkbox
                    value={f}
                    onChange={(e) => handleFilterChange(f, e.target.checked)}
                    checked={activeFilters.includes(f)}
                    labelText={f}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-pt-[8px]">
        <Error
          show={Boolean(error)}
          message={`Unable to fetch ${activeTab === "timelines" ? "recent activities" : "auto plans"}`}
          onClose={actions.clearError}
        />

        {events.map((event) => (
          <EventComponent
            key={event.id}
            event={event}
            isDismissed={event.dismissed}
            dismissEvent={() => actions.dismissEvent(event)}
            openSmsModalWithPerson={(person) => {
              const phone = viewPhoneFromFlat(
                event.person_dialable_phone_numbers?.find((p) => p.is_primary || p.position === 1) ||
                  event.person_dialable_phone_numbers?.[0],
              );
              setShowSmsModal({
                person: {
                  ...person,
                  data: {
                    ...person.data,
                    attributes: {
                      ...person.data.attributes,
                      primary_phone_number: phone.formattedNumber,
                    },
                  },
                },
                phone,
              });
            }}
            // TODO: use animation library
            // isDismissing={eventToDismiss === ev.id}
          />
        ))}

        {events.length ? (
          <div className="tw-flex tw-justify-center">
            <Button
              className="tw-max-w-[400px] tw-w-full"
              size="medium"
              schema="secondary"
              disabled={loading}
              onClick={actions.loadMore}
            >
              {loading ? "Loading..." : "Load More..."}
            </Button>
          </div>
        ) : (
          <div className="tw-text-center">
            <img height="200px" src={EmptyEventIcon} alt="no events" />
            <h4 className="tw-text-[20px]">Change your filters to see more activity</h4>
          </div>
        )}
      </div>

      {loading && <Loader />}

      {showSmsModal && (
        <SmsModal
          gabbiAi={featureFlags.gabbiAi}
          modalOpen
          person={showSmsModal.person}
          phone={showSmsModal.phone}
          closeModal={() => setShowSmsModal(null)}
          messagingServiceUrl={messagingServiceUrl}
        />
      )}

      <SingleDialerModal />

      <ScrollButton />
    </div>
  );
};

RecentActivity.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  userUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RecentActivity;
