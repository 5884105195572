import React, { useRef } from "react";
import Proptypes from "prop-types";
import { useClickOutside } from "@shared/hookHelpers";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { HouseSolidV6, FileLinesSolidV6, KeySolidV6, ShareNodesSolidV6 } from "@shared/v2/Icomoon/Icons";

export const DocButton = ({ onClickFunc, Icon, text, doctype }) => {
  const onClickDocType = () => {
    onClickFunc(doctype);
  };
  return (
    <Button
      schema="secondary"
      size="medium"
      onClick={onClickDocType}
      className="tw-capitalize tw-text-[18px] !tw-text-gray-75 tw-leading-[24px] tw-font-bold tw-w-[299px] !tw-rounded-[4px]
      tw-flex tw-gap-[16px] tw-items-center tw-border-[1px] !tw-border-gray-10 tw-h-[72px] !tw-p-[24px]
      hover:!tw-bg-transparent
      hover:!tw-border-theme-button-secondary
      hover:!tw-text-theme-button-secondary
      active:!tw-text-theme-button-secondary
      active:!tw-bg-transparent
      active:!tw-border-theme-button-secondary"
    >
      <Icon size="xxl" />
      {text}
    </Button>
  );
};

const SelectDocTypeModal = ({
  onSelectDocType,
  modalOpen,
  closeModal,
  children,
  title = "Select Document Type",
}) => {
  const target = useRef(null);

  useClickOutside(target, () => closeModal());
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      data-cy="select-doc-type-modal"
      show={modalOpen}
      onHide={closeModal}
      ref={target}
    >
      <Modal.Header titleClassName="tw-mb-32px tw-text-gray-75" title={title} />

      <Modal.Body>
        <div className="tw-grid tw-grid-cols-1 tw-gap-[24px]">
          {children || (
            <>
              <DocButton
                text="Buyer Agreement"
                doctype="buyer"
                Icon={KeySolidV6}
                onClickFunc={onSelectDocType}
              />
              <DocButton
                text="Seller Agreement"
                doctype="seller"
                Icon={HouseSolidV6}
                onClickFunc={onSelectDocType}
              />
              <DocButton
                text="Referral Agreement"
                doctype="referral"
                Icon={ShareNodesSolidV6}
                onClickFunc={onSelectDocType}
              />
              <DocButton
                text="Other Document"
                doctype="other"
                Icon={FileLinesSolidV6}
                onClickFunc={onSelectDocType}
              />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

DocButton.Proptypes = {
  onClickFunc: Proptypes.func,
  Icon: Proptypes.shape({}).isRequired,
  doctype: Proptypes.string,
  text: Proptypes.string,
};
DocButton.defaultProps = {
  onClickFunc: null,
  text: null,
  doctype: null,
};

SelectDocTypeModal.Proptypes = {
  onSelectDocType: Proptypes.func,
  modalOpen: Proptypes.bool,
  isDocument: Proptypes.bool,
  closeModal: Proptypes.func,
};
SelectDocTypeModal.defaultProps = {
  modalOpen: false,
  onSelectDocType: null,
  closeModal: null,
  isDocument: false,
};
export default SelectDocTypeModal;
