import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";

import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import Modal from "../../../shared/v2/Modal";
import Error from "../../../Reports/components/DashboardShared/Error";
import UsersDropdown from "./UsersDropdown";
import SourcesDropdown from "./SourcesDropdown";
import LeadAssignType from "./LeadAssignType";
import FirstToClaim from "./FirstToClaim";
import RoundRobin from "./RoundRobin";
import LeadCreation from "./LeadCreation";
import DeleteTrackingNumber from "./DeleteTrackingNumber";
import { updateTrackingNumber, updateVoicemailGreeting } from "./services";
import VoicemailRecorder from "./VoicemailRecorder";
import Divider from "../../../Settings/Phone/Divider";

const TrackingNumberDetailsModal = ({
  routingMethod,
  setRoutingMethod,
  selectedUsers,
  setSelectedUsers,
  noAnswerSetting,
  setNoAnswerSetting,
  voicemailGreetingChanged,
  setVoicemailGreetingChanged,
  voicemailGreetingFile,
  setVoicemailGreetingFile,
  voicemailGreetingFilename,
  setVoicemailGreetingFilename,
  name,
  setName,
  sources,
  source,
  setSource,
  chosenNumber,
  createLead,
  setCreateLead,
  agents,
  isNewNumber,
  onClose,
  trackingNumber,
  beginDeletionConfirmation,
  onNumbersChanged,
  purchaseNumber,
  backToNumberSelection,
  isPlace,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");

  const FIRST_TO_CLAIM_LIMIT = 10;

  const update = async () => {
    setIsFetching(true);

    const { error: updateError } = await updateTrackingNumber(
      trackingNumber.id,
      name,
      source,
      routingMethod,
      selectedUsers,
      createLead,
      noAnswerSetting,
      voicemailGreetingFilename,
    );

    if (voicemailGreetingChanged) {
      await updateVoicemailGreeting(trackingNumber.id, voicemailGreetingFile, voicemailGreetingFilename);
    }

    if (updateError) {
      setError(updateError);
      setIsFetching(false);
    } else {
      onNumbersChanged();
    }
  };

  const canSubmit = isNewNumber
    ? !!name && !!chosenNumber && selectedUsers.length > 0
    : !!name && selectedUsers.length > 0;

  const NoAnswerOptions = [
    { value: "voicemail", label: "Go to voicemail" },
    { value: "dial_next", label: "Dial next agent in round robin" },
  ];

  const noAnswerOption = NoAnswerOptions.find(({ value }) => value === noAnswerSetting);

  const saveText = () => {
    if (!isNewNumber) {
      return "Save";
    }

    return isPlace ? "Activate Number" : "Buy Number";
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      contentSize="medium"
      contentClassName="tw-m-auto !tw-p-0"
    >
      <Modal.Header
        className="tw-px-32px tw-pt-32px"
        title={isNewNumber ? "Tracking Number Details" : "Edit Tracking Number"}
        closeButton
      >
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
        <Divider verticalMargin="tw-mt-32px" />
      </Modal.Header>
      <Modal.Body className="tw-max-h-[760px] tw-overflow-y-scroll tw-px-32px">
        <div className="tw-my-24px tw-text-gray-75">
          {isNewNumber ? (
            <span>
              You’ve selected the tracking number <strong>{chosenNumber.label}</strong>. Now we’ll just need
              some information about your new tracking number:
            </span>
          ) : (
            <span>
              Tracking numbers can be used for specific campaigns and sources, so you know where your calls
              and leads are coming from.
            </span>
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8px tw-mb-24px">
          <span className="tw-text-14px tw-text-gray-50 tw-font-semibold after:tw-content-['*'] after:tw-text-brivity-coral-100 after:tw-ml-4px">
            Tracking Number Name
          </span>
          <input
            className="tw-h-36px tw-text-14px tw-text-gray-99 tw-pl-12px tw-border-solid tw-border-1px tw-rounded-sm tw-border-gray-15 tw-bg-tinted-gray-50"
            placeholder="Where will you use this tracking number?"
            onChange={(e) => setName(e.target?.value || "")}
            value={name}
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <div className="tw-mb-8px">
            <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">
              Source for Tracking Number (optional)
            </span>
          </div>
          <div className="tw-mb-4px">
            <SourcesDropdown
              trackingNumberName={name}
              sources={sources}
              source={source}
              setSource={(value) => setSource(value)}
            />
          </div>
          <span className="tw-text-12px tw-text-gray-50 tw-font-normal">
            Automatically assigns this source to leads created from this number{" "}
          </span>
        </div>
        <Divider verticalMargin="tw-my-24px" />
        <div className="tw-flex tw-flex-col">
          <div className="tw-mb-8px">
            <span className="tw-text-14px tw-text-gray-50 tw-font-semibold after:tw-content-['*'] after:tw-text-brivity-coral-100 after:tw-ml-4px">
              Select who this number will be routed to
            </span>
          </div>
          <div className="tw-mb-24px">
            <UsersDropdown
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              users={agents}
              placeholder={
                selectedUsers.length >= FIRST_TO_CLAIM_LIMIT && routingMethod === "first_to_claim"
                  ? "Maximum number of users reached"
                  : "Search or select users"
              }
            />
          </div>
          <div className="tw-mb-24px">
            <LeadAssignType assignType={routingMethod} setAssignType={setRoutingMethod} />
          </div>
          <div>
            {routingMethod === "first_to_claim" ? (
              <FirstToClaim agents={selectedUsers} setAgents={setSelectedUsers} />
            ) : (
              <RoundRobin agents={selectedUsers} setAgents={setSelectedUsers} />
            )}
          </div>
        </div>
        {routingMethod === "round_robin" && (
          <div className="tw-flex tw-flex-col tw-my-24px">
            <div className="tw-mb-8px">
              <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">
                If Original Agent doesn't answer...
              </span>
            </div>
            <div className="tw-mb-4px">
              <Dropdown
                value={noAnswerOption}
                options={NoAnswerOptions}
                onChange={(selection) => setNoAnswerSetting(selection.value)}
              />
            </div>
          </div>
        )}
        <Divider verticalMargin="tw-my-24px" />
        <LeadCreation createLead={createLead} setCreateLead={setCreateLead} />
        <Divider verticalMargin="tw-my-24px" />
        <VoicemailRecorder
          setVoicemailGreetingChanged={setVoicemailGreetingChanged}
          voicemailGreetingFile={voicemailGreetingFile}
          setVoicemailGreetingFile={setVoicemailGreetingFile}
          voicemailGreetingFilename={voicemailGreetingFilename}
          setVoicemailGreetingFilename={setVoicemailGreetingFilename}
        />
        <div className="tw-mb-24px" />
        {!isNewNumber && (
          <>
            <Divider verticalMargin="tw-mb-24px" />
            <div className="tw-mb-46px">
              <DeleteTrackingNumber
                beginDeletionConfirmation={beginDeletionConfirmation}
                isFetching={isFetching}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="tw-mx-32px tw-mb-32px">
        <Divider verticalMargin="tw-mb-24px" />
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={isNewNumber ? backToNumberSelection : onClose}>
            {isNewNumber ? "Back" : "Cancel"}
          </Button>
          <Button
            size="medium"
            disabled={canSubmit && !isFetching ? "" : "disabled"}
            onClick={isNewNumber ? purchaseNumber : update}
          >
            {saveText()}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

TrackingNumberDetailsModal.propTypes = {
  routingMethod: PropTypes.string.isRequired,
  setRoutingMethod: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedUsers: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  source: PropTypes.number.isRequired,
  setSource: PropTypes.func.isRequired,
  chosenNumber: PropTypes.string.isRequired,
  createLead: PropTypes.bool.isRequired,
  setCreateLead: PropTypes.func.isRequired,
  beginDeletionConfirmation: PropTypes.func.isRequired,
  purchaseNumber: PropTypes.func.isRequired,
  backToNumberSelection: PropTypes.func.isRequired,
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  trackingNumber: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createLead: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    routingMethod: PropTypes.string.isRequired,
  }).isRequired,
  onNumbersChanged: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isNewNumber: PropTypes.bool.isRequired,
};

export default TrackingNumberDetailsModal;
