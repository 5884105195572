import React from "react";

import { FishSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const NewPondActivity = ({ activity, onReadChange }) => {
  const { leadSource, pondName } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={FishSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <span>Pond: {pondName}</span>
        <span>Source: {leadSource}</span>
      </div>
    </ActivitiesContainer>
  );
};

NewPondActivity.propTypes = {
  ...activityCommonPropTypes,
};

NewPondActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default NewPondActivity;
