import React from "react";
import { kebabCase } from "lodash";
import { Col, Row, Clearfix, FormGroup, Panel } from "../../../../shared/v1";
import { Expense } from "../Expense";
import Tooltip from "../../../../shared/v2/Tooltip";
import { Info } from "../../../../shared/v2/Icomoon";

const Company = ({ accountData }) => {
  const { company_expense_template: company_expense, company_expense_types } = accountData;
  const { id, name, custom_agent_expenses_attributes: expenses } = company_expense;

  return (
    <div data-cy={`financials-settings-company-wrapper-${kebabCase(name)}`} id={`accordion${id}`}>
      <Panel className="tw-border-gray" deprecatedOverrideMarginClasses="tw-m-0">
        <Panel.Heading className="tw-bg-[#fff]" deprecatedOverrideBorderClasses="tw-border-0">
          <Panel.Title
            className="title tw-text-brand-primary"
            deprecatedOverrideTextClasses="tw-text-[15px] tw-leading-[1.1]"
          >
            <Panel.Trigger
              className="tw-no-underline hover:tw-no-underline active:tw-no-underline focus:tw-no-underline tw-relative"
              data-parent={`#accordion${id}`}
              id={`company${id}`}
              data-cy={`financials-setting-view-accordion`}
            >
              {name}
            </Panel.Trigger>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse id={`company${id}`}>
          <Panel.Body
            className="tw-bg-[#fbfafa] tw-border-0 tw-border-t tw-border-solid tw-border-[#ddd]"
            deprecatedOverridePaddingClasses="tw-p-0"
          >
            <Clearfix className="transaction-gci-wrap">
              <div className="transaction-gci-options">
                <Clearfix className="transaction-gci-header">
                  <h3 data-cy="company-transaction-details">Details</h3>
                </Clearfix>

                <div className="transaction-gci-content">
                  <Row>
                    <Col size={2}>
                      <FormGroup className="tw-min-h-[50px]">
                        <label className="tw-block tw-mb-[5px]">
                          Company Name
                          <Tooltip
                            multiline
                            placement="top"
                            content={
                              <div className="tw-w-[226px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                                Company Name is how your team will be labeled in transaction financials. It
                                does not affect the Team Name field in Account Settings.
                              </div>
                            }
                            trigger={<Info className="tw-mb-[-1px] tw-ml-6px" />}
                          />
                        </label>
                        <span data-cy="company-name">{name}</span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              {!!company_expense?.custom_agent_expenses_attributes.length && (
                <div className="transaction-gci-options">
                  <Clearfix className="transaction-gci-header">
                    <h3>Company Expenses/Income</h3>
                  </Clearfix>

                  <div className="company-gci-content content-additional">
                    {expenses.map((expense, i) => {
                      return (
                        !expense._destroy && (
                          <Expense
                            expenseType={"company"}
                            expense={expense}
                            showBorder
                            key={`custExp0-${i}`}
                            expList={company_expense_types}
                            keyString={`company-expense-${i}`}
                          />
                        )
                      );
                    })}
                  </div>
                </div>
              )}
            </Clearfix>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
};

export default Company;
