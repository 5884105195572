import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../shared/v2/Modal/Modal";
import { Table, TBody, TD, TR } from "../Table/Table";
import TableHead from "./TableHead";

const TableModal = ({ data, onClose, show, tab, agentUuid }) => (
  <Modal
    show={show}
    onHide={onClose}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-m-0"
    contentClassName="tw-flex tw-flex-col tw-space-y-[32px] tw-min-w-[320px] tw-max-w-[1200px] tw-w-[100%] tw-max-h-[calc(100vh-64px)]"
  >
    <Modal.Header title={`${tab} Overview`} closeButton />
    <Modal.Body className="tw-overflow-auto tw-border-solid tw-border-gray-10">
      <Table className="tw-border-separate">
        <TableHead tab={tab} agentUuid={agentUuid} />
        <TBody>
          {data.rows.map((values, i) => {
            const isActive = new Date().getMonth() === i;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TR key={i}>
                {values.map(({ border, className, value }, j) => (
                  <TD
                    // eslint-disable-next-line react/no-array-index-key
                    key={j}
                    border={border}
                    className={`${className} ${isActive ? "tw-bg-brivity-blue-10" : ""}`}
                  >
                    {value}
                  </TD>
                ))}
              </TR>
            );
          })}
        </TBody>
        <tfoot className="tw-sticky tw-bottom-0">
          <TR className="tw-bg-tinted-gray-50 tw-font-semibold">
            {data.totalsRow.map(({ border, className, value }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TD key={i} border={border} className={className}>
                {value}
              </TD>
            ))}
          </TR>
        </tfoot>
      </Table>
    </Modal.Body>
  </Modal>
);

TableModal.propTypes = {
  agentUuid: PropTypes.string,
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
    totalsRow: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,
};

TableModal.defaultProps = {
  agentUuid: "",
};

export default TableModal;
