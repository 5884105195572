import React from "react";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import { shape } from "prop-types";

import TeamMember from "./fractal";

const TeamMembersInModal = ({ teamMembers }) => {
  const sortedTeamMembers = sortBy(Object.entries(teamMembers), [(ent) => ent[1].fullName]);

  return sortedTeamMembers.map(([id, { avatar, fullName }]) => (
    <TeamMember key={id} id={id} avatar={avatar} fullName={fullName} />
  ));
};

const mapStateToProps = (state) => ({
  teamMembers: state.tdpTasksReducer.secondaryFilters.byTeamMember,
});

TeamMembersInModal.propTypes = {
  teamMembers: shape({}),
};

TeamMembersInModal.defaultProps = {
  teamMembers: null,
};

export default connect(mapStateToProps)(TeamMembersInModal);
