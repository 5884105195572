/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BuildingsRegularV6 = (props) => (
  <Icon {...props} name="Buildings Regular V6">
    <path d="M10.5 9.5c0-.275.225-.5.5-.5h1c.275 0 .5.225.5.5v1c0 .275-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5v-1zm0-6c0-.275.225-.5.5-.5h1c.275 0 .5.225.5.5v1c0 .275-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5v-1zM11 8a.501.501 0 0 1-.5-.5v-1c0-.275.225-.5.5-.5h1c.275 0 .5.225.5.5v1c0 .275-.225.5-.5.5h-1zm3-8a2 2 0 0 1 2 2v12c0 1.103-.897 2-2 2H9c-1.103 0-2-.897-2-2V2a2 2 0 0 1 2-2h5zm0 1.5H9a.5.5 0 0 0-.5.5v12c0 .275.225.5.5.5h5c.275 0 .5-.225.5-.5V2a.5.5 0 0 0-.5-.5zm-8 4H2.25a.749.749 0 0 0-.75.75v7.5c0 .416.336.75.75.75h3.791c.097.572.353 1.088.722 1.5H2.251a2.25 2.25 0 0 1-2.25-2.25v-7.5A2.25 2.25 0 0 1 2.251 4h3.75v1.5zM4.5 10c.275 0 .5.225.5.5v1c0 .275-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5v-1c0-.275.225-.5.5-.5h1zm0-3c.275 0 .5.225.5.5v1c0 .275-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5v-1c0-.275.225-.5.5-.5h1z" />
  </Icon>
);

export default BuildingsRegularV6;
