import React from "react";
import axios from "axios";
import PeopleResults from "./PeopleResults";
import { Search as SearchIcon } from "../../shared/v2/Icomoon";
import TextComboInput from "../../shared/v2/TextComboInput";
import { debounce } from "lodash";

class SearchPeople extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      cursor: 0,
      searchBoxValue: this.props.searchBoxValue || "",
      searchResults: [],
      searchTerm: "",
      hasResults: false,
      searchValid: false,
      scope: this.props.scope,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchBoxValue !== this.props.searchBoxValue) {
      this.setState({ searchBoxValue: this.props.searchBoxValue });
    }
  }

  toggleActive() {
    this.setState({ isActive: !this.state.isActive });
  }

  handleSearchChange = (value) => {
    this.setState((prevState) => ({
      searchBoxValue: value,
      searchTerm: value,
    }));
    this.search();
  };

  search = debounce(() => {
    const { searchBoxValue } = this.state;
    if (!searchBoxValue) return;

    this.setState((prevState) => ({
      isFetching: true,
      searchValid: true,
    }));
    let encodedTerm = searchBoxValue.replace("+", "%2B");
    const scope = this.scope();
    const queryKey = this.queryKey();

    axios(`/search/${scope}?${queryKey}=${encodedTerm}`).then((response) => {
      // Team Members endpoint responds with { people: [data] } instead of the flat [data]
      const data = response.data.people || response.data;
      this.setState((prevState) => ({
        isFetching: false,
        searchValid: true,
        searchResults: data,
        hasResults: data.length >= 1,
      }));
    });
  }, 300);

  handleKeyDown = (e) => {
    const { cursor } = this.state;
    const { searchResults } = this.state;

    if (e.keyCode === 13) {
      e.preventDefault();
      this.setState((prevState) => ({
        searchBoxValue: searchResults[cursor]["label"],
        isActive: false,
      }));
      this.props.onSelectFunction(searchResults[cursor]);
    }

    if (e.keyCode === 38 && cursor > 0) {
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && cursor < searchResults.length - 1) {
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
    }
  };

  handleHover = (index) => {
    this.setState(() => ({ cursor: index }));
  };

  handleClick = (index, e) => {
    e.preventDefault();
    const { searchResults } = this.state;
    this.setState(() => ({
      cursor: index,
      searchBoxValue: searchResults[index]["label"],
      isActive: false,
    }));
    this.props.onSelectFunction(searchResults[index]);
  };

  scope() {
    const defaultScope = "account";
    const scopes = {
      account: "account_people",
      teamMembers: "team_members",
    };
    return scopes[this.state.scope] || scopes[defaultScope];
  }

  queryKey() {
    const defaultScope = "account";
    const scopes = {
      account: "search",
      teamMembers: "q",
    };
    return scopes[this.state.scope] || scopes[defaultScope];
  }

  render() {
    const { isActive, searchTerm, searchResults, hasResults, searchValid, searchBoxValue } = this.state;
    return (
      <div className={`${this.props.labelClassName} tw-pb-[4px] tw-pt-[8px]`}>
        <TextComboInput
          className="tw-w-full"
          inputClassName="tw-p-0"
          autoComplete="no"
          placeholder="Begin typing a person's name"
          onChange={(e) => this.handleSearchChange(e.target.value)}
          onFocus={() => this.setState({ isActive: true })}
          onBlur={() => this.setState({ isActive: false })}
          onKeyDown={this.handleKeyDown}
          value={searchBoxValue}
          trailing={
            <div className="tw-flex tw-items-center">
              <SearchIcon className="tw-text-neutral-gray-30" size="l" />
            </div>
          }
        />
        {isActive && (
          <PeopleResults
            drodownClassName={this.props.drodownClassName}
            searchResults={searchResults}
            hasResults={hasResults}
            onHover={this.handleHover}
            onClickHandle={this.handleClick}
            searchTerm={searchTerm}
            searchTermLength={searchTerm.length}
            searchValid={searchValid}
          />
        )}
      </div>
    );
  }
}

SearchPeople.defaultProps = {
  searchTerm: "",
  searchResults: [],
  scope: "account",
};

export default SearchPeople;
