import { tryCatchHandlr } from "../../../../shared/Utilities";
import { getAllNotesFromApi, postNoteToApi, patchNoteFromApi, deleteNoteFromApi } from "../api/apiWrappers";
import { saveAllNotes, saveError, saveErrors, addNote, updateNote, deleteNote } from "./creators";

export const getAllNotesAsThunk = (uuid) => async (dispatch) => {
  const req = getAllNotesFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    if (err) {
      dispatch(saveError(err.response.data.errors[0]));
      return;
    }
    return;
  }

  dispatch(saveAllNotes(res.data));
};

export const postNoteAsThunk = (payload, uuid) => async (dispatch) => {
  const req = postNoteToApi(payload, uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(saveError(err.response.data.errors[0]));
    return;
  }
  dispatch(addNote(res.data));
};

export const patchNoteAsThunk = (payload, uuid) => async (dispatch) => {
  const req = patchNoteFromApi(payload, uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    const status = err.response?.status;
    const errors = err.response.data?.errors;

    dispatch(saveErrors(errors, status));
    return;
  }

  dispatch(updateNote(res.data));
};

export const deleteNoteAsThunk = (payload, uuid) => async (dispatch) => {
  const req = deleteNoteFromApi(payload, uuid);
  const [, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(saveError(err.response.data.errors[0]));
    return;
  }

  dispatch(deleteNote(payload.id));
};
