import { schemaGenerator } from "../utils";

const dictionary = {
  default: {
    default: ["tw-text-theme-text-button-color", "tw-uppercase"],
    active: ["active:tw-text-theme-text-button-color-focus"],
    hover: ["hover:tw-text-theme-text-button-color-hover"],
    disabled: ["disabled:tw-text-theme-text-button-color-disabled"],
  },
  sentence: {
    default: ["tw-text-theme-text-button-color"],
    active: ["active:tw-text-theme-text-button-color-focus"],
    hover: ["hover:tw-text-theme-text-button-color-hover"],
    disabled: ["disabled:tw-text-theme-text-button-color-disabled"],
  },
  warning: {
    default: ["!tw-text-semantic-red-100", "tw-uppercase"],
    active: ["active:!tw-text-semantic-red-120"],
    hover: ["hover:!tw-text-semantic-red-110"],
    disabled: ["disabled:!tw-text-semantic-red-10"],
  },
  success: {
    default: ["!tw-text-semantic-green-100", "tw-uppercase"],
    active: ["active:!tw-text-semantic-green-120"],
    hover: ["hover:!tw-text-semantic-green-110"],
    disabled: ["disabled:!tw-text-semantic-green-10"],
  },
  remove: {
    default: ["!tw-text-neutral-gray-50", "tw-uppercase"],
    active: ["active:!tw-text-semantic-red-120"],
    hover: ["hover:!tw-text-semantic-red-110"],
    disabled: ["disabled:!tw-text-neutral-gray-15"],
  },
};

export const availableSchema = Object.keys(dictionary);

const schema = schemaGenerator(dictionary);

export default schema;
