import React from "react";

const Skeleton = ({ title, iconClassName, body, timestamp }) => (
  <div className="timeline-event tw-px-[20px]">
    <div className="tw-flex tw-gap-[16px] tw-items-start">
      <i
        className={`${iconClassName} timeline-icon-circle tw-flex-none before:tw-w-full before:tw-h-full before:tw-flex before:tw-justify-center before:tw-items-center`}
      />
      <div className="tw-flex-1 tw-flex tw-gap-[16px] tw-break-words">
        <div className="tw-flex tw-flex-col tw-gap-[8px]">
          <div className="tw-whitespace-pre-wrap timeline-description-text">{title}</div>
          <div>{body}</div>
        </div>
        <div className="tw-min-w-[148px] time-ago">{timestamp}</div>
      </div>
    </div>
  </div>
);

export default Skeleton;
