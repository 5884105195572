/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SquarePenSolidV6 = (props) => (
  <Icon {...props} name="Square Pen Solid V6">
    <path d="M13 1a2 2 0 0 1 2 2v10c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zm-1.819 3.366a1.254 1.254 0 0 0-1.769 0l-.669.669 2.216 2.219.669-.669a1.246 1.246 0 0 0 0-1.769l-.447-.45zM4.484 9.494l-.469 1.853a.532.532 0 0 0 .132.503.509.509 0 0 0 .475.131l1.878-.472c.175-.044.337-.134.466-.263l3.288-3.287-2.219-2.216-3.288 3.259a1.26 1.26 0 0 0-.263.491z" />
  </Icon>
);

export default SquarePenSolidV6;
