import React, { Component } from "react";
import { Col } from "../../shared/v1";
import Tooltip from "../../shared/v2/Tooltip";

export default class TaskSettings extends Component {
  recurringTooltip = () => {
    const { task } = this.props;
    return task.recurring ? "This task is recurring" : "This is a one time task";
  };

  visibleTooltip = () => {
    const { task } = this.props;
    if (task.taskable_type == "Person") {
      return "Hidden from viewers";
    } else {
      return task.externally_visible ? "Visible to viewers" : "Hidden from viewers";
    }
  };

  noteTooltip = () => {
    const { task } = this.props;
    return task.note ? "Has a note" : "Does not have a note";
  };

  instructionsTooltip = () => {
    const { task } = this.props;
    return task.instructions ? "Has instructions" : "Does not have instructions";
  };

  iconMute = (prop) => {
    return prop ? "" : "tw-text-gray";
  };

  eyeOrSlash = () => {
    const { task } = this.props;
    if (task.taskable_type == "Person") {
      return "fa-eye-slash";
    } else {
      return task.externally_visible ? "fa-eye" : "fa-eye-slash";
    }
  };

  render() {
    const { task, col_size } = this.props;
    const { recurring, externally_visible, note, instructions } = task;
    return (
      <Col size={col_size} className="tw-text-center overflow">
        <div className="tw-h-full tw-flex tw-items-center">
          <div className="tw-leading-0 tw-flex tw-gap-[5px]">
            <Tooltip
              placement="top"
              trigger={<i className={`!tw-mt-0 fa fa-refresh ${this.iconMute(recurring)}`} />}
              content={this.recurringTooltip()}
            />
            <Tooltip
              placement="top"
              trigger={
                <i className={`!tw-mt-0 fa ${this.eyeOrSlash()} ${this.iconMute(externally_visible)}`} />
              }
              content={this.visibleTooltip()}
            />
            <Tooltip
              placement="top"
              trigger={<i className={`!tw-mt-0 fa fa-pencil-square-o ${this.iconMute(note)}`} />}
              content={this.noteTooltip()}
            />
            <Tooltip
              placement="top"
              trigger={<i className={`!tw-mt-0 fa fa-list-ol ${this.iconMute(instructions)}`} />}
              content={this.instructionsTooltip()}
            />
          </div>
        </div>
      </Col>
    );
  }
}
