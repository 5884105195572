import React, { useEffect, useState } from "react";
import { bool, node, string } from "prop-types";
import { XmarkLargeSolidV6 } from "@shared/v2/Icomoon";

const Banner = ({ children, show, hasClose, containerClassName, closeIconClassName }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  return (
    isShown && (
      <div
        className={`tw-w-full tw-h-[44px] tw-border-1px tw-border-solid tw-rounded-2px tw-border-[#FAC5C3] tw-bg-[#FAE6E6] tw-flex tw-items-center tw-justify-between tw-px-[16px] ${containerClassName}`}
      >
        {children}
        {hasClose && (
          <XmarkLargeSolidV6
            onClick={() => setIsShown(false)}
            size="m"
            className={`tw-text-[#FA423C] tw-cursor-pointer ${closeIconClassName}`}
          />
        )}
      </div>
    )
  );
};

export default Banner;

Banner.propTypes = {
  children: node,
  show: bool,
  hasClose: bool,
  containerClassName: string,
  closeIconClassName: string,
};

Banner.defaultProps = {
  children: null,
  show: false,
  hasClose: false,
  containerClassName: "",
  closeIconClassName: "",
};
