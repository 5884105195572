import React from "react";
import { Col } from "react-bootstrap";
import Button from "./Button";

const SearchEmptyState = ({ isInitialState, searchBarRef }) => {
  return (
    <Col xs={12}>
      <div className="tw-text-center tw-mt-[206px]">
        <i className="fa fa-search tw-text-36px tw-mb-20px tw-text-teal" />
        <p className="tw-text-gray-75 tw-text-18px tw-font-bold tw-mb-12px">
          {isInitialState ? "Search Listings" : "No matching results"}
        </p>
        <p className="tw-text-gray-75 tw-mb-20px">
          {isInitialState
            ? "Enter an address, MLS#, neighborhood, city, ZIP code, or county to search"
            : "Please try searching with another term"}
        </p>
        <Button size="large" color="blue" onClick={() => searchBarRef.current.focus()}>
          SEARCH
        </Button>
      </div>
    </Col>
  );
};

export default SearchEmptyState;
