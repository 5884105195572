import React, { Component } from 'react';
import moment from 'moment';
import { Col, Modal, Row } from 'react-bootstrap';
import icons from '../icons.module.css';
import button from '../button.module.css';
import alerts from '../listing_alerts/listing_alerts.module.css';
import css from "../person-detail-styles.module.css";
import modal from "../modal.module.css";
import HeaderTitle from "./HeaderTitle";
import { capitalize } from 'lodash';
import AlertStatus from "../listing_alerts/AlertStatus";
import { formattedRange, formattedPriceRange, formatLocationsForDisplay } from '../../../shared/ListingAlertHelpers';

class ViewAllListingAlertsModal extends Component {
  render() {
    const { handleDelete, listingAlerts, onClose, openAddModal, openEditModal, websiteUrl } = this.props;

    return (
      <Modal id="view-all-listing-alerts-modal" className="person-details-modal"  backdrop='static' show={true} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Listing Alerts" onClose={onClose} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className={modal.modalBodyContainer} style={{ paddingTop: "12px", marginTop: "-12px" }}>
            <Col xs={4} className={`m-b-20 text-center `}>
              <div id={alerts.addNewAlert} className={`tw-rounded-lg hover:tw-border-[2px] hover:tw-border-solid  hover:tw-border-[#94DCDC] active:tw-bg-blue-10 ${alerts.viewAllCard}`} onClick={openAddModal}>
                <i className={`${alerts.lgBell} fa fa-bell-o text-info`} />
                <div className="text-info" style={{ fontSize: "14px" }}><strong>CREATE NEW LISTING ALERT</strong></div>
              </div>
            </Col>
            {
              listingAlerts.map((alert, alertIndex) => (
                <Col key={alert.alertId} xs={4} className="m-b-20">
                  <div className={`tw-rounded-lg ${alerts.viewAllCard} ${alerts.alert}`}>
                    <p className="pull-left">
                      <i className="fa fa-bell-o" style={{ fontSize: "14px", marginRight: "8px" }} />
                      Listing Alert
                    </p>
                    <div className={`${icons.editAndDelete} pull-right`}>
                      <a className={`${icons.button} ${icons.gray}`} onClick={() => openEditModal(alertIndex)}>
                        <i className={`${icons.smIcon} fa fa-pencil`} />
                      </a>
                      <a className={`${icons.button} ${icons.gray}`} onClick={() => handleDelete(alertIndex)}>
                        <i className={`${icons.smIcon} fa fa-trash`} />
                      </a>
                    </div>
                    <p style={{ clear: "both" }}>
                      {/*TODO: Do we need to account for the account's time zone? See listing_alert_decorator.rb*/}
                      {moment(alert.createdAt).format("MM/D/YYYY")}
                      <span> at {moment(alert.createdAt).format("h:mm A")}</span>
                    </p>
                    <div className={alerts.viewAllAlert}>
                      <p className="overflow">
                        <span className={css.brivityPersonDetailsLabel}>Name:</span>
                        <span className={css.inlineLabelValue}>
                          <a href={`https://${websiteUrl}/${alert.searchUrl}`} target="_blank">{alert.searchName}</a>
                        </span>
                      </p>
                      <p>
                        <AlertStatus alert={alert} />
                      </p>
                      {
                        alert.active &&
                          <p>
                            <span className={css.brivityPersonDetailsLabel}>Frequency:</span>
                            <span className={css.inlineLabelValue}>{capitalize(alert.frequency)}</span>
                          </p>
                      }
                      <p className="overflow">
                        <span className={css.brivityPersonDetailsLabel}>Search Term:</span>
                        <span className={css.inlineLabelValue}>{formatLocationsForDisplay(alert)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Price:</span>
                        <span className={css.inlineLabelValue}>{formattedPriceRange(alert.priceMin, alert.priceMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Bed:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.bedsMin, alert.bedsMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Bath:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.bathsMin, alert.bathsMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Square Feet:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.sqFtMin, alert.sqFtMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Lot:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.lotSizeMin, alert.lotSizeMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Garage:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.garageMin, alert.garageMax)}</span>
                      </p>
                      <p>
                        <span className={css.brivityPersonDetailsLabel}>Year:</span>
                        <span className={css.inlineLabelValue}>{formattedRange(alert.yearMin, alert.yearMax)}</span>
                      </p>
                    </div>
                  </div>
                </Col>
              ))
            }
          </Row>
          <Row>
            <Col xs={12}>
              <button
                onClick={onClose}
                className={`${button.btn} ${button.btnLg} ${button.btnGray} pull-right`}
              >
                Close
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ViewAllListingAlertsModal;
