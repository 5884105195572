import React from "react";
import { bool, func } from "prop-types";
import { connect } from "react-redux";

import { createTasksByAutoPlansFilter, clearAutoPlanFilter } from "../actions/creators";
import { asyncSetTasksByAutoPlansFilter } from "../actions/thunks";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import AutoPlansInModal from "../AutoPlansInModal";

import modalToggleHandlr from "../handlrs";

const ModalSecondaryFilterAutoPlans = ({
  show,
  setModalAutoPlansActive,
  onCreateTasksByAutoPlansFilter,
  onClearAutoPlanFilter,
  onSetBackFilterByAutoPlans,
}) => (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    onHide={() => modalToggleHandlr(onSetBackFilterByAutoPlans, setModalAutoPlansActive)}
    closeButton
  >
    <Modal.Header title="Filter by Auto Plans" className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px">
      <AutoPlansInModal />
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row">
      <Button
        className="tw-mr-auto"
        size="medium"
        schema="tertiary"
        onClick={() => modalToggleHandlr(onSetBackFilterByAutoPlans, setModalAutoPlansActive)}
      >
        CANCEL
      </Button>
      <Button className="tw-mr-8px" size="medium" schema="secondary" onClick={onClearAutoPlanFilter}>
        CLEAR
      </Button>
      <Button
        size="medium"
        schema="primary"
        onClick={() => modalToggleHandlr(onCreateTasksByAutoPlansFilter, setModalAutoPlansActive)}
      >
        APPLY
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapStateToProps = (state) => ({
  activeAutoPlans: state.tdpTasksReducer.secondaryFilters.activeAutoPlans,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateTasksByAutoPlansFilter: () => dispatch(createTasksByAutoPlansFilter()),
  onClearAutoPlanFilter: () => dispatch(clearAutoPlanFilter()),
  onSetBackFilterByAutoPlans: () => dispatch(asyncSetTasksByAutoPlansFilter()),
});

ModalSecondaryFilterAutoPlans.propTypes = {
  show: bool,
  setModalAutoPlansActive: func,
  onCreateTasksByAutoPlansFilter: func,
  onClearAutoPlanFilter: func,
  onSetBackFilterByAutoPlans: func,
};

ModalSecondaryFilterAutoPlans.defaultProps = {
  show: false,
  setModalAutoPlansActive: () => {},
  onCreateTasksByAutoPlansFilter: () => {},
  onClearAutoPlanFilter: () => {},
  onSetBackFilterByAutoPlans: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSecondaryFilterAutoPlans);
