import React from "react";
import { node, element } from "prop-types";
import HeaderTitle from "../HeaderTitle";

const Header = ({ header, add }) => (
  <div className="tw-flex tw-items-center">
    <HeaderTitle>{header}</HeaderTitle>
    {add}
  </div>
);

export default Header;

Header.propTypes = {
  header: node,
  add: element,
};

Header.defaultProps = {
  header: "",
  add: null,
};
