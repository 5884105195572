import React from "react";
import PropTypes from "prop-types";
import errorShape from "../model/error";
import feedbackShape from "../model/feedback";
import Error from "../Error";
import FeedbackCard from "../FeedbackCard";

const FeedbackBody = ({
  feedbacks,
  error,
  showEditFeedbackForm,
  changeFeedbackVisibility,
  clearChangeFeedbackVisibilityError,
  showDeleteFeedbackPrompt,
}) => (
  <div data-cy="feedback-body">
    {error && <Error message={error.message} />}
    {feedbacks?.map((feedback) => (
      <FeedbackCard
        key={feedback.id}
        feedback={feedback}
        showEditFeedbackForm={showEditFeedbackForm}
        changeFeedbackVisibility={changeFeedbackVisibility}
        clearChangeFeedbackVisibilityError={clearChangeFeedbackVisibilityError}
        showDeleteFeedbackPrompt={showDeleteFeedbackPrompt}
      />
    ))}
  </div>
);
FeedbackBody.propTypes = {
  feedbacks: PropTypes.arrayOf(feedbackShape),
  error: errorShape,
  showEditFeedbackForm: PropTypes.func.isRequired,
  changeFeedbackVisibility: PropTypes.func.isRequired,
  clearChangeFeedbackVisibilityError: PropTypes.func.isRequired,
  showDeleteFeedbackPrompt: PropTypes.func.isRequired,
};

FeedbackBody.defaultProps = {
  feedbacks: [],
  error: null,
};

export default FeedbackBody;
