/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MobilePhone = (props) => (
  <Icon {...props} name="Mobile Phone">
    <path d="M11.6 0H4.4c-.852 0-1.543.672-1.543 1.5v13c0 .828.691 1.5 1.543 1.5h7.2c.852 0 1.543-.672 1.543-1.5v-13c0-.828-.691-1.5-1.543-1.5zM8 15c-.569 0-1.029-.447-1.029-1s.46-1 1.029-1 1.029.447 1.029 1-.46 1-1.029 1zm3.6-3.375a.382.382 0 0 1-.386.375H4.785a.382.382 0 0 1-.386-.375v-9.75c0-.206.174-.375.386-.375h6.429c.212 0 .386.169.386.375v9.75z" />
  </Icon>
);

export default MobilePhone;
