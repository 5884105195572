import React from "react";

import { Overlay } from "@shared/v1";
import { VisibilityVisible, Edit, Download, Trash } from "@shared/v2/Icomoon";
import { bool, func, string } from "prop-types";

const ACTION_STYLE = "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-text-neutral-gray-75 tw-bg-white hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0";
const ACTION_ICON_STYLE = "tw-mr-8px tw-text-neutral-gray-50";
const DOWNLOAD_STYLE = "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-text-neutral-gray-75 tw-bg-white hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0 hover:tw-text-neutral-gray-75 visited:tw-text-neutral-gray-75 hover:tw-no-underline";
const TRASH_STYLE = "tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-bg-white tw-text-semantic-red-100 hover:tw-bg-neutral-gray-5 active:tw-bg-neutral-gray-15 tw-pl-12px tw-pt-9px tw-pb-9px tw-m-0 tw-border-0 tw-border-t tw-border-solid tw-border-neutral-gray-10";
const TRASH_ICON_STYLE = "tw-mr-8px tw-text-semantic-red-100";

const OverlayDropdown = ({ onHide, isOpen, handlePreview, handleRename, downloadLink, handleDelete, containerClassName }) => (
  <Overlay
    className="tw-z-30"
    show={isOpen}
    onClick={(e) => e.stopPropagation()}
    onHide={onHide}
    rootClose
  >
    <div className={`tw-bg-white tw-w-[120px] tw-rounded-4px tw-right-full tw-top-10px tw-py-4px tw-shadow-md tw-absolute ${containerClassName}`}>
      <div
        role="menuitem"
        tabIndex={0}
        className={ACTION_STYLE}
        onClick={handlePreview}
      >
        <VisibilityVisible className={ACTION_ICON_STYLE} size="m" /> <span>Preview</span>
      </div>
      {handleRename && (
        <>
          <div
            role="menuitem"
            tabIndex={0}
            className={ACTION_STYLE}
            onClick={handleRename}
          >
            <Edit className={ACTION_ICON_STYLE} size="m" /> Rename
          </div>
        </>
      )}
      {downloadLink && (
        <a
          role="menuitem"
          tabIndex={0}
          className={DOWNLOAD_STYLE}
          href={downloadLink}
          download
          target="_blank"
          rel="noreferrer"
        >
          <Download className={ACTION_ICON_STYLE} size="m" /> Download
        </a>
      )}
      {handleDelete && (
        <>
          <div
            role="menuitem"
            tabIndex={0}
            className={TRASH_STYLE}
            onClick={handleDelete}
          >
            <Trash className={TRASH_ICON_STYLE} size="m" /> Delete
          </div>
        </>
      )}
    </div>
  </Overlay>
);

export default OverlayDropdown;

OverlayDropdown.propTypes = {
  onHide: func, 
  isOpen: bool, 
  handlePreview: func, 
  handleRename: func, 
  downloadLink: string, 
  handleDelete: func,
  containerClassName: string
}

OverlayDropdown.propTypes = {
  onHide: null, 
  isOpen: false, 
  handlePreview: null, 
  handleRename: null, 
  downloadLink: null, 
  handleDelete: null,
  containerClassName: ""
}