import React, { Component } from "react";
import TaskForm from "./TaskForm";
import { Modal } from "react-bootstrap";
import HeaderTitle from "../../PersonDetail/components/modals/HeaderTitle";
import IconButton from "@shared/v2/IconButton";
import { CalendarCheckSolidV6 } from "@shared/v2/Icomoon";

class TaskFormLogAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal || false,
      interaction: this.props.interaction,
      agents: [],
      task: { user_id: this.props.current_user.uuid },
    };
  }

  modalOpen = () => {
    this.setState({
      showModal: true,
    });
  };
  closeModal = () => {
    this.setState({
      showModal: false,
      error_messages: "",
    });
    if (this.props.onClose) this.props.onClose();
  };
  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  setAgentsForModal = () => {
    let agents = [];
    this.state.agents.map((agent) => {
      return agents.push({ value: agent.uuid, label: agent.name });
    });
    return agents;
  };

  renderTaskForm = () => {
    const {
      person,
      current_user,
      account,
      googleCalendarClientId,
      googleCalendarApiKey,
      v4TaskApi,
      listingUUID,
    } = this.props;
    const { task, error_messages, showModal } = this.state;
    return (
      <Modal show={showModal} onHide={this.closeModal} id="log-appointment" backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Interaction: Schedule Appointment" onClose={this.closeModal} />
          </Modal.Title>
        </Modal.Header>
        <div className="tw-p-[22px] !tw-pt-[32px]">
          <TaskForm
            agentsNeedLoading={true}
            isfromIndex={true}
            isSchedulingAppointment={true}
            closeModal={this.closeModal}
            displayOutcome={false}
            showErrors={this.showErrors}
            errors={error_messages}
            showTaskFor={true}
            person={person}
            user={current_user}
            accountUuid={account.uuid}
            task={task}
            googleCalendarClientId={googleCalendarClientId}
            googleCalendarApiKey={googleCalendarApiKey}
            contactEmail={person.main_email}
            listingUUID={listingUUID}
            v4TaskApi={v4TaskApi}
          />
        </div>
      </Modal>
    );
  };

  render() {
    const { showModal } = this.state;
    return (
      <>
        <IconButton onClick={this.modalOpen} aria-label="schedule appointment">
          <CalendarCheckSolidV6 />
        </IconButton>
        {showModal && this.renderTaskForm()}
      </>
    );
  }
}

export default TaskFormLogAppointment;
