import React, { useState, useEffect } from "react";
import Button from "./Button";
import UserListing from "./UserListing";
import UserCollectionListing from "./UserCollectionListing";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6 } from "@shared/v2/Icomoon";

const PondTableRow = ({ pond, editPond, isAdmin, ...props }) => {
  const [cellStyles, setStyles] = useState("lp-tr tw-p-4 tw-border-b");
  useEffect(() => {
    setStyles(cellStyles + " on");
  }, []);
  return (
    <tr>
      <>
        <td className={cellStyles + " tw-mw-[300px] tw-w-[300px]"}>
          <Button
            type="default"
            onClick={() => {
              editPond(pond);
            }}
          >
            {pond.name ? pond.name : "Untitled Pond"}
          </Button>
        </td>
        <td className={cellStyles}>
          <a href={`/people/${pond.display_agent.slug}?tab=details-tab`}>
            <UserListing name={pond.display_agent.name} imageUrl={pond.display_agent.image_url} />
          </a>
        </td>
        <td
          className={cellStyles}
          onClick={() => {
            editPond(pond);
          }}
        >
          <UserCollectionListing users={pond.accessible_by_users} />
        </td>
        <td className={cellStyles}>
          <a href={`/people/${pond.created_by.slug}?tab=details-tab`}>
            <UserListing name={pond.created_by.name} imageUrl={pond.created_by.image_url} />
          </a>
        </td>
        <td className={cellStyles}>
          <a href={`/people/${pond.last_modified_by.slug}?tab=details-tab`}>
            <UserListing name={pond.last_modified_by.name} imageUrl={pond.last_modified_by.image_url} />
          </a>
        </td>
        {isAdmin && (
          <td className={`${cellStyles} tw-text-center`}>
            <IconButton size="medium" schema="transparent" onClick={() => editPond(pond)}>
              <PencilSolidV6 />
            </IconButton>
          </td>
        )}
      </>
    </tr>
  );
};

export default PondTableRow;
