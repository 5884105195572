import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, FormGroup, Input, CancelButton } from "../../shared/v1";
import SharedEmail from "./SharedEmail";
import Error from "../../shared/Error";

export default class ShareAutoPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sharedEmails: [],
      nextEmailValue: "",
      copiedPlan: false,
    };
  }

  handleKeyDown = (e) => {
    const { nextEmailValue, sharedEmails } = this.state;
    // Handle backspace
    if (e.which === 8) {
      if (nextEmailValue === "" && sharedEmails.length > 0) {
        this.handleRemoveEmail(sharedEmails.last());
      }
    }
    // Handle enter or spacebar
    else if (e.which === 13 || e.which === 32) {
      const nextEmail = nextEmailValue;
      if (nextEmail !== "") {
        this.setState((prevState) => ({
          sharedEmails: prevState.sharedEmails.concat(nextEmail),
          nextEmailValue: "",
          copiedPlan: false,
        }));
      }
    }
  };

  handleChange = (e) => {
    const value = e.target.value.trim();
    this.setState({ nextEmailValue: value });
  };

  handleRemoveEmail = (emailToRemove) => {
    this.setState((prevState) => {
      const emailAfterRemoval = prevState.sharedEmails.filter((email) => email !== emailToRemove);

      return {
        sharedEmails: emailAfterRemoval,
      };
    });
    // eslint-disable-next-line react/no-string-refs
    this.refs.textInput.focus();
  };

  handleCloseModal = () => {
    const { closeAutoPlanShareModal } = this.props;
    this.setState({ sharedEmails: [], nextEmailValue: "", copiedPlan: false });
    closeAutoPlanShareModal();
  };

  allEmails = () => {
    const { sharedEmails, nextEmailValue } = this.state;
    return sharedEmails.concat(nextEmailValue).filter((elem) => elem);
  };

  copySharingLink = () => {
    // eslint-disable-next-line react/no-string-refs
    const copyText = this.refs.sharingAutoPlanLink;
    copyText.select();
    document.execCommand("copy");
    this.setState({ copiedPlan: true });
  };

  render() {
    const { copiedPlan, sharedEmails, nextEmailValue } = this.state;
    const {
      activeAutoPlanId,
      autoPlanName,
      emailErrors,
      isAutoPlanShareModalOpen,
      shareAutoPlan,
      sharingLink,
    } = this.props;
    return (
      <Modal show={isAutoPlanShareModalOpen} onHide={this.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share Auto Plan With Others</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emailErrors && <Error errorMessage={emailErrors} />}
          <strong>
            <p>
              Sharing this Auto Plan with another Brivity account will also share any custom roles and dates
              associated with the plan.
            </p>
          </strong>
          <p>
            Share this plan via a link or enter the email addresses of the Brivity account owners or admins
            you want to share the plan with. If you share the plan via email, they will be notified via email
            and will need to accept the plan on the &quot;Shared with me&quot; tab in Auto Plans.
          </p>
          <FormGroup>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Sharing Link</label>
            <div className="tw-table">
              <Input
                // eslint-disable-next-line react/no-string-refs
                ref="sharingAutoPlanLink"
                style={{ fontWeight: "200" }}
                type="text"
                readOnly
                value={sharingLink}
              />
              <span className="tw-table-cell tw-w-1/100 tw-align-middle">
                <Button
                  variant="info"
                  className="tw-z-2 tw--ml-1px tw-rounded-l-none"
                  type="button"
                  onClick={this.copySharingLink}
                  data-clipboard-text={sharingLink}
                >
                  {copiedPlan ? "Copied" : "Copy"}
                </Button>
              </span>
            </div>
          </FormGroup>
          <FormGroup>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Email</label>
            <div id="owner-emails-field" contentEditable={false}>
              {sharedEmails.map((email, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SharedEmail key={index} email={email} handleRemoveEmail={this.handleRemoveEmail} />
              ))}
              <Input
                // The `nextEmailValue` length was being passed here through the `size` prop,
                // but this is not the same `size` prop as the one from the native input element,
                // so it was causing some visual issues and was removed.
                //
                // If it needs to be added back, a separate prop should be created to handle it.

                className="tw-min-h-[37px]"
                deprecatedOverridePaddingClasses="tw-pt-4px tw-pr-32px tw-pb-6px tw-pl-16px"
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                // eslint-disable-next-line react/no-string-refs
                ref="textInput"
                value={nextEmailValue}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
            </div>
          </FormGroup>
          <CancelButton size="small" className="modal-cancel" onClick={this.handleCloseModal} />
          <Button
            variant="info"
            className="tw-float-right"
            onClick={() => shareAutoPlan(activeAutoPlanId, autoPlanName, this.allEmails())}
          >
            Share
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

ShareAutoPlanModal.propTypes = {
  activeAutoPlanId: PropTypes.string.isRequired,
  autoPlanName: PropTypes.string.isRequired,
  closeAutoPlanShareModal: PropTypes.func.isRequired,
  emailErrors: PropTypes.string.isRequired,
  isAutoPlanShareModalOpen: PropTypes.bool.isRequired,
  shareAutoPlan: PropTypes.func.isRequired,
  sharingLink: PropTypes.string.isRequired,
};
