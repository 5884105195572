/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Check = (props) => (
  <Icon {...props} name="Check">
    <path d="m5.434 13.731-5.2-5.2a.8.8 0 0 1 0-1.131l1.131-1.131a.8.8 0 0 1 1.131 0l3.503 3.503 7.503-7.503a.8.8 0 0 1 1.131 0L15.764 3.4a.8.8 0 0 1 0 1.131l-9.2 9.2a.8.8 0 0 1-1.131 0z" />
  </Icon>
);

export default Check;
