import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Task from "./Task"; //need to implement this using code from PolymorphicTask
import axios from "axios";
import SelectAll from "../../../shared/SelectAll";
import Pagination from "../../../shared/Pagination";
import * as TaskHelpers from "../TaskIndexModalHelpers";
import button from "../../../PersonDetail/components/button.module.css";
import tasks from "../../../PersonDetail/components/tasks/tasks.module.css";

export default class Grid extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: props.user,
      tasks: [],
      taskable: props.taskable,
      account: props.account,
      pagination: {},
      page: props.page || 1,
      showButtons: false,
      showSelectAll: false,
      selectNone: false,
      selectAll: false,
      header_buttons: false,
      incomplete_buttons: false,
      showDeleteModal: false,
      assigned_to_user_id: "anyone",
      reschedule_date: moment(),
      selectAllTarget: null,
    };

    this.multiSelectWatcher = TaskHelpers.multiSelectWatcher.bind(this);
    this.selectAll = TaskHelpers.selectAll.bind(this);
    this.selectNone = TaskHelpers.selectNone.bind(this);
    this.taskMultiSelect = TaskHelpers.taskMultiSelect.bind(this);
    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this);
    this.handleState = TaskHelpers.handleState.bind(this);
    this.handleRescheduleDate = TaskHelpers.handleRescheduleDate.bind(this);

    this.selectedTasks = TaskHelpers.selectedTasks.bind(this);
    this.setAgentsForModal = TaskHelpers.setAgentsForModal.bind(this);

    this.uncompleteTasks = TaskHelpers.uncompleteTasks.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);

    this.completeTasks = TaskHelpers.completeTasks.bind(this);
    this.completeTask = TaskHelpers.completeTask.bind(this);

    this.rescheduleTasks = TaskHelpers.rescheduleTasks.bind(this);
    this.rescheduleTask = TaskHelpers.rescheduleTask.bind(this);

    this.updateTask = TaskHelpers.updateTask.bind(this);
    this.handleGotoPage = this.handleGotoPage.bind(this);

    this.showSelectAllPopover = TaskHelpers.showSelectAllPopover.bind(this);
    this.closeSelectAllPopover = TaskHelpers.closeSelectAllPopover.bind(this);
  }

  getTasks = () => {
    let { page } = this.state;
    axios({
      method: "get",
      url: `/tasks/taskable_index?id=${this.props.taskable.uuid}&scopes=${this.props.scopes}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        page: page,
      },
    }).then((response) => {
      let { meta, tasks } = response.data;
      this.setState({ ...this.state, ...{ tasks: tasks, pagination: meta } });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.multiSelectWatcher();
  };

  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  componentDidMount() {
    this.getTasks();
    const TASK_LISTENER = "task:polymorphic_count_change";
    window.addEventListener(TASK_LISTENER, this.onEvent);
    window.addEventListener("task:update", this.onEvent);
  }

  componentWillUnmount() {
    const TASK_LISTENER = "task:polymorphic_count_change";
    window.removeEventListener(TASK_LISTENER, this.onEvent);
    window.removeEventListener("task:update", this.onEvent);
  }

  onEvent = () => {
    this.getTasks();
  };

  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };

  filteredTasks = () => {
    return {
      incomplete: this.state.tasks,
      completed: this.state.tasks,
      contingent: this.state.tasks,
    };
  };

  handleGotoPage(newPage) {
    this.setState({ page: newPage }, this.getTasks);
  }

  showReassignModal = () => {
    this.props.showReassignModal({
      selectedTasks: this.selectedTasks(),
      assignedToUserId: this.state.assigned_to_user_id,
      agentsOptions: this.setAgentsForModal(),
      form: this.state,
    });
  };

  showRescheduleModal = () => {
    this.props.showRescheduleModal({
      selectedTasks: this.selectedTasks(),
    });
  };

  showDeleteModal = (task) => {
    this.props.showDeleteModal({
      selectedTasks: task ? [task] : this.selectedTasks(),
    });
  };

  render() {
    let { pagination } = this.state;
    return (
      <div>
        <Row
          className={"last-row " + `${this.props.dueness}-complete-tasks-grid`}
          data-page={this.props.completeTasksPage || 1}
        >
          <Col xs={12}>
            <Row className={tasks.taskHeader} style={{ textTransform: "uppercase" }}>
              <Col xs={5}>
                <h6>{this.props.duenessTitle}</h6>
              </Col>
              <Col xs={1} className={"text-center"}>
                <h6>Date</h6>
              </Col>
              <Col xs={1} className={"text-center"}>
                <h6>
                  {this.props.agent && "Priority"}
                  {!this.props.agent && "\u00A0"}
                </h6>
              </Col>
              <Col xs={1} className={"text-center"}>
                <h6>Who</h6>
              </Col>
              <div>
                <Col xs={2} className={"text-center"}>
                  <h6>
                    {this.props.agent && "Settings"}
                    {!this.props.agent && "\u00A0"}
                  </h6>
                </Col>
                {this.props.agent && (
                  <Col xs={1}>
                    <h6>Edit</h6>
                  </Col>
                )}
                {!this.props.agent && (
                  <Col xs={1}>
                    <h6>{"\u00A0"}</h6>
                  </Col>
                )}
                {this.props.agent && (
                  <Col
                    xs={1}
                    className={"text-center " + this.props.selectAllClass}
                    style={{ position: "relative" }}
                  >
                    <SelectAll
                      closeSelectAllHandler={this.closeSelectAllPopover}
                      showSelectAll={this.state.showSelectAll}
                      target={this.state.selectAllTarget}
                      selectAllHandler={this.selectAll}
                      selectAll={this.state.selectAll}
                      selectNone={this.state.selectNone}
                      selectNoneHandler={this.selectNone}
                    />
                    <a
                      style={{ position: "relative" }}
                      onClick={(e) => this.state.tasks && this.showSelectAllPopover(e)}
                      id={"all-incomplete-tasks-dropdown-toggle"}
                      className={"btn btn-secondary select-task-button"}
                    >
                      <h6>
                        SELECT
                        <i className={"fa fa-sort-down font-size-12 p-l-3"} />
                      </h6>
                    </a>
                  </Col>
                )}
                {!this.props.agent && (
                  <Col xs={1}>
                    <h6> &nbsp; </h6>
                  </Col>
                )}
              </div>
            </Row>
            <Row className={"button-row within-tab"}>
              <Col xs={12}>
                {!this.state.header_buttons && <span style={{ display: "none" }}>&nbsp;</span>}
                {this.props.multiSelectButtons && this.state.header_buttons && (
                  <span className={"pull-right"}>
                    <a
                      onClick={this.completeTasks}
                      className={`${button.btn} ${button.btnLg} ${button.btnDarkBlue} m-l-5 complete`}
                    >
                      <span>
                        <i className={"fa fa-clock-o"} />
                        Complete
                      </span>
                    </a>
                    <a
                      onClick={()=>this.showDeleteModal(this.props.task)}
                      className={`${button.btn} ${button.btnLg} ${button.btnDanger} m-l-5 delete`}
                    >
                      <span>
                        <i className={"fa fa-times"} />
                        Delete
                      </span>
                    </a>
                    <a
                      onClick={this.showRescheduleModal}
                      className={`${button.btn} ${button.btnLg} ${button.btnBlue} m-l-5 reschedule`}
                    >
                      <span>
                        <i className={"fa fa-clock-o"} />
                        Reschedule
                      </span>
                    </a>
                    <a
                      onClick={this.showReassignModal}
                      className={`${button.btn} ${button.btnLg} ${button.btnYellow} m-l-5 reassign`}
                    >
                      <span>
                        <i className={"fa fa-users"} />
                        Reassign
                      </span>
                    </a>
                  </span>
                )}
                {this.props.uncomplete && this.state.header_buttons && (
                  <span>
                    <button
                      onClick={this.uncompleteTasks}
                      className={"btn btn-success-inverse btn-sm uncomplete"}
                    >
                      <span>
                        <i className={"fa fa-square-o"} />
                        Un-complete
                      </span>
                    </button>
                  </span>
                )}
              </Col>
            </Row>
            <div className={"clearfix"} />
            <div className={"completed-tasks content"} />
          </Col>
        </Row>
        <div className={this.props.dueness}>
          {this.state.tasks &&
            this.filteredTasks()[this.props.dueness].map((task) => (
              <Task
                dueness={this.props.dueness}
                agents={this.props.agents}
                agent={this.props.agent}
                getTasks={this.getTasks}
                key={task.id}
                task={task}
                user={this.props.user}
                accountMilestoneNames={this.props.accountMilestoneNames}
                taskChange={this.props.taskChange}
                multiSelectWatcher={this.multiSelectWatcher}
                editTask={this.props.editTask}
                showDeleteModal={this.showDeleteModal}
                showTaskDetails={this.props.showTaskDetails}
              />
            ))}
        </div>
        {pagination.total_count > 35 && pagination && (
          <div className="row">
            <div className="col-xs-6 col-xs-offset-3">
              <Pagination
                currentPage={pagination.current_page}
                nextPage={pagination.next_page}
                prevPage={pagination.prev_page}
                totalPages={pagination.total_pages}
                totalCount={pagination.total_count}
                onGoToPage={this.handleGotoPage}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
