import axios from "axios";
import { tryCatchHandlr } from "../../../../shared/Utilities";

const getListingImageById = async ({ id, tmpId, onSuccess, onError }) => {
  const url = `/listing_images/${id}?_=${new Date().getTime()}`;
  const [resData, err] = await tryCatchHandlr(
    axios.get(url, {
      headers: {
        Accept: "application/json",
      },
    }),
  );

  if (err) {
    return onError({ err });
  }
  return onSuccess({ ...resData?.data?.listing_image, tmpId });
};

export const saveFileToListing = async ({ slug, file, onSuccess, onError }) => {
  const url = `/listings/${slug}/uploads`;

  const params = new URLSearchParams();
  params.append("files[]", file?.path);
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;",
        "X-CSRF-Token": window.ReactOnRails.authenticityToken(),
      },
    }),
  );

  if (err) {
    return onError({ name: file.name, id: file.tmpId });
  }
  return getListingImageById({ id: resData?.data[0]?.imageId, tmpId: file?.id, onSuccess, onError });
};

export const inputUploadFn =
  ({ addError, addImg, awsDirectPost, deleteImg, setProgress, slug, updateImg, uploadFiles, onAdd }) =>
  (e) =>
    uploadFiles({
      files: Array.from(e.target.files),
      config: {
        ...awsDirectPost,
      },
      onAdd: (file) => {
        addImg(file);
      },
      onSuccess: (file) => {
        saveFileToListing({
          slug,
          file,
          onSuccess: (listingImage) => {
            // TODO: populating original is a workaround... The api should pass this property
            const mod = { ...listingImage, original: listingImage.url };
            updateImg(mod);
            if (onAdd) {
              onAdd(mod);
            }
          },
          onError: (fileError) => {
            addError(fileError);
            deleteImg(fileError);
          },
        });
      },
      onError: (fileError) => {
        addError(fileError);
        deleteImg(fileError);
      },
      onProgress: setProgress,
    });
