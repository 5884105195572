import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import TextInput from "@shared/v2/TextInput";
import Tooltip from "@shared/v2/Tooltip";
import { Edit, Image, Pdf, Word } from "@shared/v2/Icomoon";

import { useClickOutside } from "@shared/hookHelpers";

const getIcon = (context, fileType) => {
  const type = fileType?.trim().toLowerCase();
  const iconProps =
    context === "documents-sidebar"
      ? {
          className: "tw-text-theme-icon-color tw-w-40px tw-h-40px",
          size: "custom",
        }
      : {
          className: "tw-text-theme-icon-color",
          size: "xxxl",
        };
  switch (type) {
    case "jpg":
    case "jpeg":
    case "png":
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Image {...iconProps} />;
    case "pdf":
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Pdf {...iconProps} />;
    case "doc":
    case "docx":
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Word {...iconProps} />;
    default:
      return (
        <FontAwesomeIcon
          className={
            context === "documents-sidebar"
              ? "tw-text-40px tw-text-theme-icon-color"
              : "tw-text-32px tw-text-theme-icon-color"
          }
          icon={faFile}
        />
      );
  }
};

const sidebarClasses = {
  width: "tw-w-136px",
  maxWidth: "tw-max-w-136px",
  margin: "tw-mr-10px",
};

const tableClasses = {
  width: "tw-w-180px",
  maxWidth: "tw-max-w-180px",
  margin: "tw-mr-12px",
};

const FileInformation = ({
  context,
  editFilename,
  setEditFilename,
  document,
  renameDocument,
  previewDocument,
  clearDocumentErrors,
  onClickOutside,
}) => {
  const classes = context === "documents-sidebar" ? sidebarClasses : tableClasses;

  const [newName, setNewName] = useState(document.nakedName);
  const [renameError, setRenameError] = useState("");

  // When a rename error happens, the input remains open,
  // and if the user continues to press the Enter key or click outside it without changing the name,
  // it will keep making the same rename request over and over again,
  // so we need this to make sure that a rename request only happens if the name has actually changed
  const [hasNewNameChanged, setHasNewNameChanged] = useState(false);

  // We need to keep track of when the input was open and the user clicked outside,
  // to prevent calling `submitRename` twice
  const [clickedOutside, setClickedOutside] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    setNewName(document.nakedName);
    setHasNewNameChanged(false);
  }, [document.nakedName]);

  useEffect(() => {
    setEditFilename(document?.renameErrors?.length > 0);
    setRenameError(document?.renameErrors?.join(" "));
    setHasNewNameChanged(false);
  }, [document?.renameErrors]);

  const handleClick = (e) => {
    if (document.isUploading || !document.canRename) return;

    e.preventDefault();
    e.stopPropagation();
    setEditFilename(true);
  };

  const submitRename = () => {
    const newNameTrimmed = newName.trim();

    if (!newNameTrimmed || newNameTrimmed === document.nakedName) {
      if (renameError) {
        // Clean rename errors when the name is empty or when it hasn't changed,
        // because in those cases a rename request is not made and the name remains the same
        clearDocumentErrors(document.id);
      } else {
        setEditFilename(false);
      }
      return;
    }

    // Only send a rename request if the name has actually changed
    if (!hasNewNameChanged) {
      return;
    }

    const newNameComplete = `${newName}${document.fileExtension}`;

    renameDocument(document.id, newNameComplete);
  };

  const handleKeyDown = (e) => {
    // Propagating this event triggers the `handleKeyDownToEdit` function in the container `div`
    e.stopPropagation();

    if (e.key === "Enter") {
      submitRename();
    }
  };

  const handleKeyDownToEdit = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };

  const handleInputChange = (e) => {
    setNewName(e.target.value);
    setHasNewNameChanged(true);
  };

  const handleInputBlur = () => {
    if (clickedOutside) {
      // Do not call `submitRename` a second time if it was already called from `handleClickOutside`
      setClickedOutside(false);
      return;
    }

    submitRename();
  };

  const handleClickOutside = () => {
    setClickedOutside(true);

    onClickOutside();
    submitRename();
  };

  const handleKeyDownToPreview = (e) => {
    if (e.key === "Enter") {
      previewDocument();
    }
  };

  useClickOutside(ref, handleClickOutside);

  return (
    <td>
      <div
        role="menuitem"
        tabIndex={0}
        className={`tw-relative tw-cursor-pointer tw-group tw-flex tw-flex-row tw-mr-40px ${classes.maxWidth} ${classes.width}`}
        onClick={previewDocument}
        onKeyDown={handleKeyDownToPreview}
        data-cy="document-list-item-file-information"
      >
        <div className="tw-flex tw-flex-col tw-justify-center">
          <div className={`tw-w-27px tw-h-36px ${classes.margin} tw-flex tw-items-center tw-justify-center`}>
            {getIcon(context, document.fileType)}
          </div>
        </div>
        <div
          className="tw-flex tw-flex-col tw-justify-center"
          data-cy="document-list-item-filename-container"
        >
          {editFilename ? (
            <TextInput
              ref={ref}
              onKeyDown={handleKeyDown}
              value={newName}
              onChange={handleInputChange}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onBlur={handleInputBlur}
              className={classes.maxWidth}
              error={renameError}
              data-cy="document-list-item-rename-input"
            />
          ) : (
            <div>
              <Tooltip
                trigger={
                  <div className="tw-relative">
                    <p
                      className={`
                        tw-m-0 tw-text-neutral-gray-75 tw-text-14d tw-truncate ${classes.maxWidth}
                        ${document.isUploading || !document.expiringUrl ? "" : "hover:tw-underline hover:tw-decoration-1"}
                      `}
                      data-cy="document-list-item-filename"
                    >
                      {document.fileName}
                    </p>
                    {!document.isUploading && (
                      <div
                        aria-label="edit"
                        role="button"
                        tabIndex={0}
                        className="tw-absolute tw-top-0 tw-left-full tw-flex tw-justify-center tw-items-center tw-ml-3px tw-p-4px tw-opacity-0 group-hover:tw-opacity-100 focus:tw-opacity-100 group-focus:tw-opacity-100"
                        onClick={handleClick}
                        onKeyDown={handleKeyDownToEdit}
                      >
                        <Edit size="m" />
                      </div>
                    )}
                  </div>
                }
                content={document.fileName}
                placement="top"
                multiline
              />

              {context === "documents-sidebar" ? (
                <p
                  className={`${classes.maxWidth} tw-m-0 tw-mt-6px tw-text-12d tw-font-normal tw-text-neutral-gray-50 tw-truncate`}
                >
                  Uploaded by{" "}
                  <span data-cy="document-list-item-uploader-name">{document.uploadedByName}</span>
                </p>
              ) : (
                document.humanReadableSize && (
                  <p
                    className="tw-m-0 tw-text-neutral-gray-50 tw-text-12d"
                    data-cy="document-list-item-file-size"
                  >
                    {document.humanReadableSize}
                  </p>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </td>
  );
};

FileInformation.propTypes = {
  context: PropTypes.shape({}).isRequired,
  editFilename: PropTypes.bool,
  setEditFilename: PropTypes.func,
  document: PropTypes.shape({}),
  renameDocument: PropTypes.func,
  previewDocument: PropTypes.func,
  clearDocumentErrors: PropTypes.func,
  onClickOutside: PropTypes.func,
};

FileInformation.defaultProps = {
  editFilename: false,
  setEditFilename: () => {},
  document: {},
  renameDocument: () => {},
  previewDocument: () => {},
  clearDocumentErrors: () => {},
  onClickOutside: () => {},
};

export default FileInformation;
