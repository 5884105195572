import React, { useState } from "react";
import Chart from "chart.js/auto";
import HeadingSelect from "../../../Goals/components/HeadingSelect";
import Button from "../../../shared/v2/Button";

import TransactionSnapshot from "./TransactionSnapshot";
import MonthlyOverview from "../DashboardShared/MonthlyOverview";
import GoalsSection from "../DashboardShared/GoalsSection";
import BusinessByLeadSource from "../DashboardShared/BusinessByLeadSource";
import ClosingsBreakdown from "../DashboardShared/ClosingsBreakdown";
import AgentProduction from "./AgentProduction";
import { Section } from "./Section";
import RecruitingPipelineSection from "../OperatorDashboard/RecruitingPipelineSection";
import { useTheme } from "../../../reducers/layoutReducer/selectors";
import TransactionSummary from "./TransactionSummary";
import SalesFunnel from "./SalesFunnel";

Chart.defaults.defaultFontFamily = "Open Sans";

const BusinessDashboard = () => {
  const isPlace = useTheme().name === "place-theme";
  const params = new URLSearchParams(window.location.search);
  const [year, setYear] = useState(params.get("year") || new Date().getFullYear().toString());

  return (
    <div className="tw-bg-gray-5 tw-flex tw-p-[16px]">
      <div className="tw-max-w-[1500px] tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[16px]">
        <Section>
          <div className="tw-flex tw-justify-between">
            <HeadingSelect
              title={<h2 className="tw-text-28d tw-mr-[8px] tw-m-0">Business Dashboard</h2>}
              name="year"
              value={year}
              onChange={setYear}
            />
            <Button
              size="medium"
              schema="secondary"
              onClick={() => window.location.assign(`/settings/goals?year=${year}`)}
            >
              Settings
            </Button>
          </div>
        </Section>

        <TransactionSummary year={year} />

        <TransactionSnapshot year={year} />

        <SalesFunnel year={year} />

        {!isPlace && <RecruitingPipelineSection year={year} isBrivity />}

        <div className="tw-flex tw-items-start tw-flex-wrap tw-gap-[16px]">
          <div className="tw-flex tw-flex-col tw-flex-1 tw-basis-[58%] tw-min-w-[320px] tw-gap-[16px]">
            <MonthlyOverview year={year} />
            <ClosingsBreakdown year={year} />
            <BusinessByLeadSource year={year} />
          </div>
          <div className="tw-flex tw-flex-col tw-flex-1 tw-basis-[34%] tw-min-w-[320px] tw-gap-[16px]">
            <GoalsSection year={year} />
            <AgentProduction year={year} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;
