import React, { useState } from "react";
import Proptypes from "prop-types";
import { clickEventCatch } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";
import { useOnSelectedChange, useGetSelectedInxFromValues } from "./hookHelpers";
import { getIsSelectedFn, getOnSelectOptionFn } from "./helpers";
import {
  generateCommonClasses,
  generateMultipleClasses,
  generateSingleClasses,
  reduceWhiteSpace,
} from "./styles";

const RangeButtons = ({ allowMultiple, options, onChange, value }) => {
  const [selectedRangeIdx, setSelectedRange] = useGetSelectedInxFromValues({ value, options, allowMultiple });

  const [hoverIdx, setHoverIdx] = useState(null);
  const selectOption = getOnSelectOptionFn({
    allowMultiple,
    selectedRangeIdx,
    setSelectedRange,
    setHoverIdx,
  });
  const bothSelected = selectedRangeIdx[1] !== null && typeof selectedRangeIdx[1] !== "undefined";
  const multiAllSelected = allowMultiple && (selectedRangeIdx[0] === 0 || hoverIdx === 0);
  const getIsSelected = getIsSelectedFn(selectedRangeIdx, hoverIdx);
  const debouncedRange = useDebounce(selectedRangeIdx, 500);

  useOnSelectedChange(debouncedRange, () => {
    if (allowMultiple) {
      const min = options[selectedRangeIdx[0]].value;
      const max = options[selectedRangeIdx[1]]?.value;
      onChange(min, max);
    } else {
      onChange(options[selectedRangeIdx[0]]?.value);
    }
  }, [selectedRangeIdx]);

  return (
    <div>
      <div
        className="tw-inline-block tw-relative"
        onMouseLeave={() => setHoverIdx(null)}
        onBlur={() => setHoverIdx(null)}
      >
        {/* Gray line covers */}
        <div className="tw-absolute tw-top-0 tw-left-[4px] tw-w-[98%] tw-h-[2px] tw-bg-gray-15 tw-z-10" />
        <div className="tw-absolute tw-bottom-0 tw-left-[4px] tw-w-[98%] tw-h-[2px] tw-bg-gray-15 tw-z-10" />

        {options.map((o, oIdx) => {
          const isSelected = getIsSelected(oIdx);
          const isFirst = oIdx === 0;
          const isLast = oIdx === options.length - 1;
          const isStart = oIdx === selectedRangeIdx[0];
          const isEnd = oIdx === selectedRangeIdx[1];
          const isHovering = hoverIdx === oIdx;
          const isInHoverSelection = allowMultiple
            ? getIsSelected(oIdx, true) && !multiAllSelected && !bothSelected
            : false;
          const isSelectingRange = allowMultiple
            ? !multiAllSelected && !bothSelected && selectedRangeIdx[0] !== null
            : false;
          const hoverBefore = hoverIdx !== null && hoverIdx < selectedRangeIdx[0];
          const hoverAfter = hoverIdx !== null && hoverIdx > selectedRangeIdx[0];
          // Common styles that single select and multi select share
          const btnClasses = reduceWhiteSpace(
            `${generateCommonClasses({
              isFirst,
              isLast,
              selected: isSelected,
              bothSelected,
              isHovering,
              isInHoverSelection,
            })} ${
              allowMultiple
                ? generateMultipleClasses({
                    selected: isSelected,
                    bothSelected,
                    isHovering,
                    hoverBefore,
                    hoverAfter,
                    multiAllSelected,
                    isSelectingRange,
                    isInHoverSelection,
                    isStart,
                    isEnd,
                    isFirst,
                  })
                : generateSingleClasses({ selected: isSelected })
            }`,
          );

          return (
            <button
              key={o.name}
              type="button"
              onMouseOver={() => setHoverIdx(oIdx)}
              onFocus={() => setHoverIdx(oIdx)}
              onClick={(e) => clickEventCatch(e, selectOption, [oIdx], true)}
              className={btnClasses}
            >
              {o.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RangeButtons;

RangeButtons.propTypes = {
  allowMultiple: Proptypes.bool,
  options: Proptypes.arrayOf(Proptypes.shape({ name: Proptypes.string })).isRequired,
  onChange: Proptypes.func,
  value: Proptypes.arrayOf(Proptypes.number),
};

RangeButtons.defaultProps = {
  allowMultiple: false,
  onChange: () => {},
  value: [],
};
