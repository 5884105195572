/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Tasks = (props) => (
  <Icon {...props} name="Tasks">
    <path d="M3.714 3.714a1.286 1.286 0 1 1-2.573 0 1.286 1.286 0 0 1 2.573 0zm-1.285 3a1.286 1.286 0 1 0 0 2.573 1.286 1.286 0 0 0 0-2.573zm0 4.286a1.286 1.286 0 1 0 0 2.573 1.286 1.286 0 0 0 0-2.573zM5 4.679h9.429a.429.429 0 0 0 .429-.429V3.179a.429.429 0 0 0-.429-.429H5a.429.429 0 0 0-.429.429V4.25c0 .237.192.429.429.429zm0 4.285h9.429a.429.429 0 0 0 .429-.429V7.464a.429.429 0 0 0-.429-.429H5a.429.429 0 0 0-.429.429v1.071c0 .237.192.429.429.429zm0 4.286h9.429a.429.429 0 0 0 .429-.429V11.75a.429.429 0 0 0-.429-.429H5a.429.429 0 0 0-.429.429v1.071c0 .237.192.429.429.429z" />
  </Icon>
);

export default Tasks;
