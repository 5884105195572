import React from "react";

const iconStyle = (icon) => `tw-text-[17px] tw-w-[32px] tw-h-[32px] tw-text-center tw-cursor-pointer ${icon}`;

const SocialIcon = ({ link, icon }) => (
  <a
    className={`tw-flex tw-justify-center tw-items-center tw-w-[38px] tw-h-[38px] tw-rounded-full hover:tw-no-underline ${link ? "tw-text-white hover:tw-text-white tw-bg-theme-primary" : "tw-text-gray-50 hover:tw-text-gray-50 tw-bg-gray-15"}`}
    href={link ? `${link.indexOf("://") === -1 ? "https://" : ""}${link}` : ""}
    target="_blank"
    onClick={(e) => !link && e.preventDefault()}
  >
    <i className={`${icon}`} />
  </a>
);

export default SocialIcon;
