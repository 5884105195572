import { useEffect, useState } from "react";
import { getAssignableAgents, getAssignablePonds } from "./serviceHelpers";

export const useGetAssignableAgents = ({ setState, shouldRun }) => {
  useEffect(() => {
    if (!shouldRun) return;
    getAssignableAgents(
      ({ data }) => {
        setState((state) => ({
          ...state,
          allAssignableAgents: data,
          filteredAssignableAgents: data,
        }));
      },
      (error) => {
        console.log("error", error);
      },
      { skipCache: false },
    );
  }, [shouldRun]);
};

export const useGetAssignablePonds = ({ setState, pageSize = 25, shouldRun }) => {
  const [page, setPage] = useState(1);
  const [runOnce, setRunOnce] = useState(false);

  useEffect(() => {
    if (shouldRun) setRunOnce(true);
  }, [shouldRun]);

  useEffect(() => {
    if (!runOnce) return;
    getAssignablePonds(
      page,
      pageSize,
      (response) => {
        const results = response.data.page || [];
        setState((state) => ({
          ...state,
          allAssignablePonds: [...state.allAssignablePonds, ...results],
          filteredAssignablePonds: [...state.filteredAssignablePonds, ...results],
        }));
        if (response.data.page?.length === pageSize) {
          // auto load the next page until we do not have a complete set returned
          setPage((page) => page + 1);
        }
      },
      (error) => {
        console.log("error", error);
      },
      { skipCache: false },
    );
  }, [page, runOnce]);
};

export const useGetAgentState = (enableLeadPonds, loadFirst) => {
  const [state, setState] = useState({
    allAssignableAgents: [],
    allAssignablePonds: [],
    filter: "",
    filteredAssignableAgents: [],
    filteredAssignablePonds: [],
    show: false,
  });

  useGetAssignableAgents({ setState, shouldRun: state.show || loadFirst });
  useGetAssignablePonds({ setState, shouldRun: enableLeadPonds && (state.show || loadFirst) });

  const handleChange = (e) => {
    const searchString = e.target.value;
    const regex = RegExp(searchString, "gi");
    setState((state) => ({
      ...state,
      filter: searchString,
      filteredAssignableAgents: state.allAssignableAgents.filter((agent) => regex.test(agent.name)),
      filteredAssignablePonds: state.allAssignablePonds.filter((pond) => regex.test(pond.name)),
    }));
  };
  return [state, setState, handleChange];
};
