import React, { useState } from "react";
import "@brivity/ai-components/dist/style.css";
import { AiComponentsProvider, AskPlace, AskPlaceToggleButton } from "@brivity/ai-components";
import useAiToken from "@shared/AiAssistant/hooks/useAiToken";

const AiAskPlace = () => {
  // If we need to make this component triggerable in more locations we'll need to move this to redux
  const [isAskPlaceOpen, setIsAskPlaceOpen] = useState(false);
  const { apiUrl, token } = useAiToken();

  return (
    <AiComponentsProvider apiUrl={apiUrl} token={token}>
      <AskPlaceToggleButton
        onClick={() => setIsAskPlaceOpen((isOpen) => !isOpen)}
        isAskPlaceOpen={isAskPlaceOpen}
      />
      {isAskPlaceOpen && <AskPlace onMinimize={() => setIsAskPlaceOpen(false)} />}
    </AiComponentsProvider>
  );
};

export default AiAskPlace;
