import axios from "axios";

import { getPostJsonHeaders } from "@shared/axiosHelpers";

export const getAgentsAndTrackingNumbers = async () => {
  const { data } = await axios.get("/phone_system/tracking_numbers/settings", getPostJsonHeaders());

  return { agents: data.agents, trackingNumbers: data.tracking_numbers };
};

const mapTrackingNumberRoutes = (users) =>
  users?.map((user, i) => ({
    user_id: user.id,
    position: i,
  }));

const createError = (e) => {
  const error = e.response ? e.response.data.error : "Something went wrong!";

  return { error };
};

export const getAvailableNumbers = async (agentId, countryCode, areaCode) => {
  const data = {
    country_code: countryCode,
    area_code: areaCode,
  };

  try {
    const response = await axios.post(
      `/users/${agentId}/available_managed_numbers`,
      data,
      getPostJsonHeaders(),
    );
    const numbers = response.data.numbers.map(([value, label]) => ({ label, value }));

    return { numbers };
  } catch (e) {
    return createError(e);
  }
};

export const getAvailableTollFreeNumbers = async (agentId, countryCode) => {
  try {
    const response = await axios.get(
      `/users/${agentId}/available_toll_free_numbers?country_code=${countryCode}`,
    );

    const numbers = response.data.numbers.map(([value, label]) => ({ label, value }));

    return { numbers };
  } catch (e) {
    return createError(e);
  }
};

export const getSources = async () => {
  try {
    const response = await axios.get(`/sources`, getPostJsonHeaders());
    const sources = response.data.sources.map(({ text, id }) => ({ label: text, value: id }));

    return { sources };
  } catch (e) {
    return createError(e);
  }
};

const createNewSource = async (name) => {
  const response = await axios.post("/sources", { source: { name } }, getPostJsonHeaders());

  return response.data.source_id;
};

export const buyTrackingNumber = async (
  agentId,
  name,
  number,
  routingMethod,
  users,
  sourceId,
  shouldCreateLeads,
  noAnswerSetting,
  voicemailGreetingFilename,
) => {
  const data = {
    tracking_number: {
      name,
      number,
      routing_rule: routingMethod,
      create_phone_number_leads: shouldCreateLeads,
      new_lead_source_id: sourceId,
      voicemail_greeting: voicemailGreetingFilename,
    },
    tracking_number_routings: mapTrackingNumberRoutes(users),
  };

  if (routingMethod === "round_robin") {
    data.tracking_number.no_answer_setting = noAnswerSetting;
  }

  try {
    if (sourceId === "new") {
      data.tracking_number.new_lead_source_id = await createNewSource(name);
    }

    const response = await axios.post("/phone_system/tracking_numbers", data, getPostJsonHeaders());

    return { trackingNumberId: response.data.tracking_number_id };
  } catch (e) {
    return createError(e);
  }
};

export const updateTrackingNumber = async (
  trackingNumberId,
  name,
  sourceId,
  routingMethod,
  users,
  shouldCreateLeads,
  noAnswerSetting,
  voicemailGreetingFilename,
) => {
  const data = {
    tracking_number: {
      name,
      routing_rule: routingMethod,
      new_lead_source_id: sourceId,
      create_phone_number_leads: shouldCreateLeads,
      voicemail_greeting: voicemailGreetingFilename,
    },
    tracking_number_routings: mapTrackingNumberRoutes(users),
  };

  if (routingMethod === "round_robin") {
    data.tracking_number.no_answer_setting = noAnswerSetting;
  }

  try {
    if (sourceId === "new") {
      data.tracking_number.new_lead_source_id = await createNewSource(name);
    }

    await axios.put(`/phone_system/tracking_numbers/${trackingNumberId}`, data, getPostJsonHeaders());

    return {};
  } catch (e) {
    return createError(e);
  }
};

export const deleteTrackingNumber = async (trackingNumberId) => {
  if (!trackingNumberId) {
    return { error: "No ID!" };
  }

  try {
    await axios.delete(`/phone_system/tracking_numbers/${trackingNumberId}`, getPostJsonHeaders());

    return {};
  } catch (e) {
    return createError(e);
  }
};

export const updateVoicemailGreeting = async (trackingNumberId, file, filename) => {
  try {
    const headers = {
      headers: {
        "X-CSRF-Token": ReactOnRails.authenticityToken(),
        "Content-Type": "multipart/form-data",
      },
    };

    const blob = await fetch(file).then((r) => r.blob());
    const formData = new FormData();
    formData.append("file", blob);

    await axios.put(
      `/phone_system/tracking_numbers/${trackingNumberId}/voicemail_greeting?filename=${encodeURIComponent(filename)}`,
      formData,
      headers,
    );

    return {};
  } catch (e) {
    return createError(e);
  }
};
