/* eslint-disable camelcase */
import React from "react";
import { string, shape, bool, oneOfType, arrayOf } from "prop-types";

import EventIcon from "../EventIcon";
import EventAction from "../EventAction";
import EventDescription from "../EventDescription";
import EventVisibility from "../EventVisibility";

const Event = ({ icon, action, owner, recipient, externally_visible, date, meta }) => {
  const checkUploadcondition =
    (meta?.variant === "upload" && meta?.content?.length > 0) || meta?.variant !== "upload";

  return (
    <>
      {checkUploadcondition && (
        <div className="tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-1 tw-border-solid tw-border-neutral-gray-10 tw-px-[8px] tw-py-[16px] tw-flex tw-items-center">
          <EventIcon icon={icon} />
          <div className="tw-ml-[24px] tw-max-w-[325px] tw-break-all">
            <EventAction owner={owner} receiver={recipient}>
              {action}
            </EventAction>
            <EventDescription description={meta} />
          </div>
          <EventVisibility externallyVisible={externally_visible} />
          <div className="tw-ml-[60px] tw-w-80px tw-text-[12px]">{date}</div>
        </div>
      )}
    </>
  );
};

Event.propTypes = {
  icon: string,
  action: string,
  externally_visible: bool,
  meta: shape({
    variant: string,
    content: oneOfType([
      string,
      arrayOf(
        oneOfType([
          string,
          shape({
            link: string,
            text: string,
          }),
        ]),
      ),
    ]),
  }),
  owner: shape({}),
  recipient: string,
  date: string,
};

Event.defaultProps = {
  icon: null,
  action: null,
  externally_visible: false,
  meta: {
    variant: null,
    content: null,
  },
  owner: null,
  recipient: string,
  date: null,
};

export default Event;
