import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

const shortStatus = {
  'new': 'New',
  'unqualified': 'Unqual',
  'watch': 'Watch',
  'nurture': 'Nurt',
  'hot': 'Hot',
  'past-client': 'Past',
  'inactive': 'Inactiv',
  'archived': 'Arch',
  'trash': 'Trash'
}

export default class TimelineLeadCreateEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false,
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  details = () => {
    let details = {}
    let sorted_rows = [];
    const changeset = this.event.timeline_fields;
    const sorted_whitelisted_fields = [
      'personal_email',
      'work_email',
      'other_email',
      'mobile_phone',
      'home_phone',
      'work_phone',
      'other_phone',
      'source',
      'status'
    ]

    sorted_whitelisted_fields.forEach((whitelisted_field) => {
      let change = changeset[whitelisted_field]

      if(whitelisted_field == 'source' && !change){
        change = ['', 'Unknown']
      }

      if(change){
        const titleizedAttribute = whitelisted_field.split("_").map(element =>{return capitalize(element)}).join(" ")

        if(titleizedAttribute == 'Status'){
          sorted_rows.push(this.statusDetail(change))
        }else {
          sorted_rows.push(this.standardDetail(titleizedAttribute, change))
        }
      }
    })

    return (
      <div>{sorted_rows}</div>
    )
  }

  statusDetail = (change) => {
    const prevStatus = change[0]
    const newStatus = change[1]

    const prevClasses = `btn btn-sm person-status-${prevStatus}`
    const newClasses = `btn btn-sm person-status-${newStatus}`

    const prevShortStatus = shortStatus[prevStatus]
    const newShortStatus = shortStatus[newStatus]

    return (
      <div key={`${this.event.id}-status-${change}`} className="row details-row">
        {
          <div>
            {'Status: '}
            { prevStatus && <span>
                <span className={prevClasses}>
                  {prevShortStatus}
                </span>
                {' to '}
              </span>
            }
            <span className={newClasses}>
              {newShortStatus}
            </span>
          </div>
        }
      </div>
    )
  }

  standardDetail = (titleizedAttribute, change) => {
    return (
      <div key={`${this.event.id}-${titleizedAttribute}-${change}`} className="row details-row">
        {`${titleizedAttribute}: `}

        { change[0] && <span className="timeline-person-original-field">
            {'(was '}
            <span dangerouslySetInnerHTML={{__html: change[0]}} />
            {') '}
          </span>
        }

        {
          !change[1] ? (
            <span className="timeline-person-removed-field">
              removed
            </span>
          ) : (
            <span dangerouslySetInnerHTML={{__html: change[1]}} />
          )
        }
      </div>
    )
  }

  addedByText = () => {
    const changeset = this.event.timeline_fields
    const updater_name = changeset.updater_name
    let added_by_text = ''

    if(updater_name == 'Import'){
      added_by_text = 'Added via import'
    }else if (updater_name == 'API') {
      added_by_text = ''
    } else if (updater_name === 'Tracking Number') {
      return (
        <>
          Lead automatically created from Tracking Number <span className="tw-font-bold">"{changeset.tracking_number_name}"</span>`
        </>
      );
    }else if (updater_name) {
      added_by_text = `Manually added by ${updater_name}`
    }else {
      added_by_text = ''
    }

    return (
      <>
        {added_by_text}
      </>
    );
  }

  contactName = () => {
    const changeset = this.event.timeline_fields
    const first_name = !changeset['first_name'] ? '' : changeset['first_name'][1]
    const last_name = !changeset['last_name'] ? '' : changeset['last_name'][1]
    let contact_name = ''

    if(first_name || last_name){
      contact_name = `${first_name} ${last_name}`.trim()
    }else {
      contact_name = 'No Name'
    }

    return contact_name;
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">
              <span dangerouslySetInnerHTML={{__html: this.contactName() }} />
            </span>
            {' became a contact'}
            <div className="timeline-description-text">
              {this.details()}
              {this.addedByText()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                Profile Created
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row bold-detail details-row details-header">
                Details:
              </div>
              {this.details()}
              {this.addedByText()}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
