const getGeoJsonFromPolygon = (polygon) => {
  if (!polygon) {
    return null;
  }

  const coords = [];

  const vertices = polygon.getPath();
  for (let i = 0; i < vertices.getLength(); i += 1) {
    const xy = vertices.getAt(i);
    coords.push([xy.lng(), xy.lat()]);
  }

  // Add first point as last to close the loop
  const firstPoint = vertices.getAt(0);
  coords.push([firstPoint.lng(), firstPoint.lat()]);

  return {
    coordinates: [coords],
    type: "Polygon",
  };
};

export const getRadiusInMeters = (radiusInMiles) => radiusInMiles * 1609.34;

export const setPolyStyle = (map, editable) => {
  map.data.setStyle({
    strokeColor: "#59c4c3",
    strokeWeight: 2,
    fillColor: "#59c4c3",
    fillOpacity: 0.4,
    editable,
  });
};
export const clearMap = (map, radiusCircle) => {
  if (radiusCircle) {
    radiusCircle.setMap(null);
  }

  map.data.forEach((feature) => {
    map.data.remove(feature);
  });
};

export const processPoints = (google, geometry, callback, thisArg) => {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach((g) => {
      processPoints(google, g, callback, thisArg);
    });
  }
};

export const getUserLocationAndInitMap = ({ successFn, errorFn }) => {
  navigator.geolocation.getCurrentPosition(successFn, errorFn);
};

const makeZoomControl = ({ isFirst, content, contentText }) => {
  const controlUI = document.createElement("div");

  controlUI.style.backgroundColor = "#fff";
  controlUI.style.border = "2px solid #fff";
  controlUI.style.borderRadius = "50%";
  controlUI.style.fontSize = "24px";
  controlUI.style.fontWeight = "700";
  controlUI.style.height = "32px";
  controlUI.style.width = "32px";
  controlUI.style.zIndex = "20";
  controlUI.style.userSelect = "none";
  controlUI.style.position = "relative";
  controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
  controlUI.style.cursor = "pointer";
  controlUI.style.marginBottom = isFirst ? "7px" : "5px";
  controlUI.style.marginRight = "20px";
  controlUI.style.textAlign = "center";
  controlUI.innerHTML = content;
  controlUI.title = contentText;
  return controlUI;
};

export const addZoomControls = (controlDiv, map) => {
  // Set CSS for the control border.
  const zoomIn = makeZoomControl({ isFirst: true, content: "+", contentText: "Zoom In" });
  const zoomOut = makeZoomControl({ content: "-", contentText: "Zoom Out" });

  controlDiv.appendChild(zoomIn);
  controlDiv.appendChild(zoomOut);

  zoomIn.addEventListener("click", () => {
    map.setZoom(map.zoom + 1);
  });
  zoomOut.addEventListener("click", () => {
    map.setZoom(map.zoom - 1);
  });
};

export const createDrawingManager = ({ google, map, onPolygonComplete }) => {
  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: false,
    polygonOptions: {
      strokeColor: "#4CA7A7",
      strokeWeight: 2,
      fillColor: "#4CA7A7",
      fillOpacity: 0.4,
      zIndex: 1,
      editable: true,
      clickable: false,
    },
  });

  drawingManager.setMap(map);
  google.maps.event.addListener(drawingManager, "polygoncomplete", (poly) => {
    const data = getGeoJsonFromPolygon(poly);
    poly.setMap(null);
    drawingManager.setDrawingMode(null);
    // pass data to parent component
    onPolygonComplete({ poly, geoJSON: data });
  });

  return drawingManager;
};

export const createCMAMap = ({
  setMap,
  setDrawingManager,
  google,
  coords,
  onPolygonComplete,
  setFormData,
}) => {
  const mapOptions = {
    zoom: 10,
    // customizations
    minZoom: 6,
    maxZoom: 16,
    mapTypeControl: false,
    rotateControl: false,
    scaleControl: false,
    zoomControl: false,
    clickableIcons: false,
    fullscreenControl: false,
    streetViewControl: false,
  };
  if (coords && coords.length === 2) {
    mapOptions.center = {
      lat: coords[0],
      lng: coords[1],
    };
  }
  const map = new google.maps.Map(document.getElementById("cma-map"), mapOptions);
  map.data.addListener("setgeometry", () => {
    map.data.toGeoJson((data) => setFormData({ geo_json: data?.features[0]?.geometry }));
  });
  // Custom Zoom controls
  const zoomControlDiv = document.createElement("div");
  addZoomControls(zoomControlDiv, map);
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
  // Enable drawing
  setDrawingManager(createDrawingManager({ google, map, onPolygonComplete }));
  setMap(map);
};

export const useBrowserCoords = (coords, browserLatLon) =>
  coords?.length !== 2 && browserLatLon?.length === 2;
