import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { isPlace } from "@shared/Utilities";
import formatters from "../../shared/formatters";
import { sortBy } from "lodash";

export const initialState = {
  counts: {
    Leads: {},
    People: {},
    Tasks: {},
    Transactions: {},
  },
  currentMetaUser: null,
  currentSuperUser: null,
  currentUser: null,
  env: null,
  featureFlags: null,
  isMobileMenuOpen: false,
  sidebar: null,
  submenu: null,
  theme: null,
  support: null,
  accountCallRecordingLoading: false,
};

const transformCounts = (counts) =>
  Object.keys(counts).reduce((acc, key) => ({ ...acc, [key]: formatters.number(counts[key]) }), {
    lastUpdate: Date.now(),
  });

const updateAccountRecordingSettings = createAsyncThunk(
  "phoneSettings/updateAccountRecordingSettings",
  (callRecordingAccountSettings, thunkApi) =>
    caseTransformingAxios
      .post(
        "/api/v4/call_recording/account_settings/upsert",
        {
          callRecordingAccountSettings,
        },
        { signal: thunkApi.signal },
      )
      .then(({ data }) => data),
);

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    addPeopleSmartFilterOption: (state, { payload: { option } }) => {
      state.sidebar.menu.forEach((menu) => {
        const smartFilterIndex =
          menu.name !== "Transactions" ? menu.options?.findIndex((o) => o.name === "Smart Filters") : null;
        const smartFilterOptions = menu.options?.[smartFilterIndex]?.options;
        if (smartFilterOptions) {
          menu.options[smartFilterIndex].options = sortBy(
            [...smartFilterOptions, option],
            [(o) => Boolean(o.deletePath), "name"],
          );
        }
      });
    },
    removeSmartFilterOption: (state, { payload: { option } }) => {
      state.sidebar.menu.forEach((menu) => {
        const smartFilterOptions = menu.options?.find((o) => o.name === "Smart Filters")?.options;
        if (smartFilterOptions) {
          const optionIndex = smartFilterOptions.findIndex(
            (o) => o.id === option.id && o.name === option.name,
          );
          if (optionIndex > -1) {
            smartFilterOptions.splice(optionIndex, 1);
          }
        }
      });
    },
    setIsSubmenuOpen: (state, { payload }) => {
      state.submenu = payload;
    },
    toggleMobileMenu: (state, { payload }) => {
      state.isMobileMenuOpen = typeof payload === "boolean" ? payload : !state.isMobileMenuOpen;
    },
    updateLeadCounts: (state, { payload }) => {
      state.counts = { ...state.counts, Leads: transformCounts(payload) };
    },
    updatePeopleCounts: (state, { payload }) => {
      state.counts = { ...state.counts, People: transformCounts(payload) };
    },
    updateTaskCounts: (state, { payload }) => {
      state.counts = { ...state.counts, Tasks: transformCounts(payload) };
    },
    updateTransactionCounts: (state, { payload }) => {
      const withAllData = { ...payload, all_count: 0 };
      Object.keys(payload).forEach((key) => {
        // draft not related to statuses. So we need to skip it to remove double counting of
        // any transaction that is a draft, as they _also_ are counted in the statuses
        if (key === "drafts_count") return;
        const value = payload[key];
        withAllData.all_count += value;
      });
      state.counts = { ...state.counts, Transactions: transformCounts(withAllData) };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateAccountRecordingSettings.pending, (state) => {
        state.accountCallRecordingLoading = true;
      })
      .addCase(updateAccountRecordingSettings.fulfilled, (state, action) => {
        state.currentUser.account.callRecordingSettings = action.payload;
        state.accountCallRecordingLoading = false;
      })
      .addCase(updateAccountRecordingSettings.rejected, (state) => {
        state.accountCallRecordingLoading = false;
      });
  },
});

export { updateAccountRecordingSettings };

export const {
  addPeopleSmartFilterOption,
  removeSmartFilterOption,
  setIsSubmenuOpen,
  toggleMobileMenu,
  updateLeadCounts,
  updatePeopleCounts,
  updateTaskCounts,
  updateTransactionCounts,
} = layoutSlice.actions;

export default layoutSlice.reducer;

export const usePlaceSelector = () => useSelector(({ layout }) => isPlace(layout.theme.name));
