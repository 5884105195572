import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "./Grid";

export default class Tasks extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: props.user,
      target: null,
      toggleLoader: false,
    };
  }

  toggleTaskLoader = (isLoading) => {
    this.setState({ toggleLoader: isLoading });
  };

  render() {
    const {
      account,
      accountMilestoneNames,
      agent,
      agents,
      editTask,
      showTaskDetails,
      showDeleteModal,
      showReassignModal,
      showRescheduleModal,
      taskable,
    } = this.props;

    return (
      <div>
        <Grid
          duenessTitle="Upcoming Task Details"
          agent={agent}
          pagination={{}}
          agents={agents}
          account={account}
          taskable={taskable}
          accountMilestoneNames={accountMilestoneNames}
          dueness={"incomplete"}
          scopes={["incomplete"]}
          showButtons={this.multiSelect}
          selectAllClass={"none"}
          uncomplete={false}
          multiSelectButtons
          polymorphicTasks
          user={this.state.user}
          toggleTaskLoader={this.toggleTaskLoader}
          editTask={editTask}
          showReassignModal={showReassignModal}
          showRescheduleModal={showRescheduleModal}
          showDeleteModal={showDeleteModal}
          showTaskDetails={showTaskDetails}
        />
        <Grid
          duenessTitle="Contingent Task Details"
          agent={agent}
          account={account}
          taskable={taskable}
          accountMilestoneNames={accountMilestoneNames}
          agents={agents}
          scopes={["contingent_tasks"]}
          dueness={"contingent"}
          pagination={{}}
          showButtons={this.multiSelect}
          selectAllClass={"little-more-space"}
          createButton={false}
          uncomplete={false}
          multiSelectButtons
          polymorphicTasks
          user={this.state.user}
          toggleTaskLoader={this.toggleTaskLoader}
          editTask={editTask}
          showReassignModal={showReassignModal}
          showRescheduleModal={showRescheduleModal}
          showDeleteModal={showDeleteModal}
          showTaskDetails={showTaskDetails}
        />
        <Grid
          duenessTitle="Completed Task Details"
          agent={agent}
          agents={agents}
          account={account}
          pagination={{}}
          scopes={["complete_tasks"]}
          dueness={"completed"}
          taskable={taskable}
          accountMilestoneNames={accountMilestoneNames}
          showButtons={this.multiSelect}
          selectAllClass={"little-more-space"}
          createButton={false}
          uncomplete
          multiSelectButtons={false}
          polymorphicTasks
          user={this.state.user}
          toggleTaskLoader={this.toggleTaskLoader}
          editTask={editTask}
          showReassignModal={showReassignModal}
          showRescheduleModal={showRescheduleModal}
          showDeleteModal={showDeleteModal}
          showTaskDetails={showTaskDetails}
        />
      </div>
    );
  }
}

Tasks.propTypes = {
  user: PropTypes.object.isRequired,
};

Tasks.defaultProps = {
  tasks: [],
  user: { name: "No Name" },
  pagination: {},
};
