/* eslint-disable react/prop-types */

import React, { useEffect, useState, useRef } from "react";
import useOutsideClick from "../../utils/useOnClickOutside";
import Cluster from "../../../shared/v2/Cluster";
import Button from "../../../shared/v2/Button";
import { AngleDownIcon } from "../icons";

const orderDirection = (sortedBy) =>
  // assumes 'x' and 'x_desc' as the template
  sortedBy.slice(-4) === "desc" ? "desc" : "asc";
const orderParam = (sortedBy) => (orderDirection(sortedBy) === "desc" ? sortedBy.slice(0, -5) : sortedBy);

// Commented out items are the oens with no clear definition yet
const paramOptions = {
  address: {
    value: "address",
    label: "Address / Title",
  },
  // agent: {
  //   value: "agent",
  //   label: "Agent",
  // },
  close_date: {
    value: "close_date",
    label: "Close Date",
  },
  close_price: {
    value: "close_price",
    label: "Close Price",
  },
  date_canceled: {
    value: "date_canceled",
    label: "Date Canceled",
  },
  date_listed: {
    value: "date_listed",
    label: "Date Listed",
  },
  // dom: {
  //   value: "dom",
  //   label: "DOM",
  // },
  expiration_date: {
    value: "expiration_date",
    label: "Expiration",
  },
  gross_commission: {
    value: "gross_commission",
    label: "GCI",
  },
  last_updated: {
    value: "last_updated",
    label: "Last Updated",
  },
  listing_price: {
    value: "listing_price",
    label: "Listing Price",
  },
  // mls: {
  //   value: "mls",
  //   label: "MLS#",
  // },
  source: {
    value: "source",
    label: "Source",
  },
  type: {
    value: "type",
    label: "Type",
  },
};

export const paramOptionsArray = Object.keys(paramOptions)
  .sort()
  .map((key) => paramOptions[key]);
const directionOptionsArray = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];

const Ordering = ({ param, direction }) => ({
  param,
  direction: direction || "asc",
  string: `${param}${direction === "desc" ? "_desc" : ""}`,
});

const orderFromString = (string) =>
  Ordering({
    param: orderParam(string),
    direction: orderDirection(string),
  });

const SortingMenu = ({ order, setOrder }) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setOpened(false));

  const originalOrder = orderFromString(order);
  const [presentedOrder, setPresentedOrderOrder] = useState(orderFromString(order));
  useEffect(() => {
    setPresentedOrderOrder(orderFromString(order));
    setOpened(false);
  }, [order]);

  const onOrderChange = (property, value) => {
    setPresentedOrderOrder(Ordering({ ...presentedOrder, [property]: value }));
  };

  return (
    <div ref={ref} className="tw-w-max tw-relative">
      <Button schema="secondary" onClick={() => setOpened(!opened)}>
        <div className="tw-flex tw-items-center tw-space-x-2 tw-font-bold">
          <span>
            SORT BY: {paramOptions[originalOrder.param]?.label} {originalOrder.direction}
          </span>
          <AngleDownIcon />
        </div>
      </Button>

      {opened && (
        <div
          className="
              tw-absolute tw-bottom-100px tw-right-0
              tw-rounded-[5px]
              tw-bg-white 
              tw-max-h-[600px] tw-w-max
              tw-overflow-y-auto
              tw-border-none
              tw-p-[20px]
              tw-z-30
              tw-space-y-[16px]
              tw-shadow-md
            "
        >
          <Cluster justify="start" space="lg" align="center">
            <select value={presentedOrder.param} onChange={(e) => onOrderChange("param", e.target.value)}>
              {paramOptionsArray.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>

            <select
              value={presentedOrder.direction}
              onChange={(e) => onOrderChange("direction", e.target.value)}
            >
              {directionOptionsArray.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>

            <Button onClick={() => setOrder(presentedOrder.string)} schema="primary">
              Save
            </Button>
          </Cluster>
        </div>
      )}
    </div>
  );
};

export default SortingMenu;
