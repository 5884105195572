import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TextButton from "../../shared/v2/TextButton/TextButton";
import { ChevronLeftSolidV6 } from "../../shared/v2/Icomoon";

const BackButton = ({ children, onClick }) => {
  const theme = useSelector(({ layout }) => layout.theme);
  return (
    <TextButton
      className={`tw-outline-none tw-font-semibold tw-normal-case tw-rounded-[8px] tw-px-[8px] tw-py-[4px] hover:tw-bg-gray/15 focus:tw-bg-gray/15 ${
        theme.name === "place-theme"
          ? "tw-text-white hover:tw-text-white"
          : "!tw-text-gray-75 hover:tw-text-gray-75"
      }`}
      onClick={onClick}
    >
      <ChevronLeftSolidV6 size="s" className="tw-mr-[8px]" />
      {children}
    </TextButton>
  );
};

BackButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
