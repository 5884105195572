import React from "react";
import PropTypes from "prop-types";
import { CommentSlashRegularV6 } from "@icons";
import { Col } from "../../shared/v1";

const AutoPlanEvent = ({ event, dismissEvent, isDismissing, isDismissed }) => (
  <Col
    className={`tw-flex tw-py-[20px] tw-border-solid tw-border-0 tw-border-b-[1px] tw-border-gray-light transition-all tw-duration-1000 ${
      isDismissing ? "tw-h-[0px] tw-opacity-0" : "tw-min-h-[100px] tw-opacity-100"
    }`}
  >
    <span className="tw-mr-[15px] tw-flex-none tw-flex tw-justify-center tw-items-center tw-w-[38px] tw-h-[38px] tw-rounded-full tw-border-solid tw-border-[2px]">
      {event.type === "Unsubscribed" ? (
        <CommentSlashRegularV6 size="xl" />
      ) : (
        <i className={`tw-text-[22px] fa fa-${event.icon}`} />
      )}
    </span>
    <div className="tw-flex tw-justify-between tw-w-full">
      <div className="tw-w-[90%] tw-pr-[20px]">
        <a
          href={event.link_to_resolve}
          className="tw-w-full tw-no-underline"
          target="_blank"
          rel="noreferrer"
        >
          <div className="tw-flex tw-flex-col">
            <div>
              <span className="tw-text-[#f2714d]">{event.error_cause}</span>
              {" for "}
              <span className="tw-font-semibold tw-text-gray-75">{event.title}</span>
              {" for Auto Plan "}
              <span>&quot;{event.plan_name}&quot;</span>
            </div>
            <div className="tw-mt-[20px]">
              <span>{event.error_message}</span>
            </div>
          </div>
        </a>
      </div>
      <div>
        <label>{event.time_ago}</label>
        {!isDismissed && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key !== "Tab") e.preventDefault();
              if (["Enter", " "].includes(e.key)) dismissEvent(event);
            }}
            onClick={() => dismissEvent(event)}
            className="tw-text-teal tw-text-right tw-font-[12px]"
          >
            Dismiss
          </div>
        )}
      </div>
    </div>
  </Col>
);

AutoPlanEvent.propTypes = {
  dismissEvent: PropTypes.func.isRequired,
  isDismissing: PropTypes.bool.isRequired,
  isDismissed: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    error_cause: PropTypes.string,
    error_message: PropTypes.string,
    icon: PropTypes.string,
    link_to_resolve: PropTypes.string,
    plan_name: PropTypes.string,
    title: PropTypes.string,
    time_ago: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default AutoPlanEvent;
