import { actionTypes, userSettingsErrorMessages } from "./actionTypes";
import { tryCatchHandlr } from "../../../shared/Utilities";

import { getUsersForAccountSettingApiFn } from "../api";

export const getUserTableData = () => async (dispatch) => {
  const [res, err] = await tryCatchHandlr(getUsersForAccountSettingApiFn());
  if (err) {
    dispatch({
      type: actionTypes.SET_USERS_TABLE_ERRORS,
      errors: [
        userSettingsErrorMessages.FAIL_ON_LOAD_TABLE_USERS_DATA,
        `Description: ${err.response.data.message}`,
      ],
    });
  } else {
    const updatedUsersData = res.data.map(
      ({
        avatar,
        first_name: firstName,
        last_name: lastName,
        login_user: {
          active,
          email,
          suspended,
          last_seen: lastActiveDate,
          okta: isOktaUser,
          role: agentType,
          sign_in_count: signInCount,
          okta_invited_at: oktaInvitedAt,
          okta_last_sign_in_at: oktaLastSignInAt,
          id: userId,
          show_agent_profile_page: showAgentProfilePage,
          show_on_website_roster: showOnWebsiteRoster,
          users_mlses: usersMls,
          crm_access: crmAccess,
        },
        id,
        milestones: { organization_start_date: startDate },
        position: position,
        date_added: dateAdded,
      }) => ({
        avatar,
        id,
        active,
        userId,
        fullName: `${firstName} ${lastName}`,
        lastActiveDate,
        agentType,
        position,
        isOktaUser,
        signInCount,
        oktaInvitedAt,
        oktaLastSignInAt,
        startDate,
        dateAdded,
        email,
        showAgentProfilePage,
        showOnWebsiteRoster,
        mlsAgentId: usersMls?.length ? usersMls[0].mls_agent_id : null,
        crmAccess,
        suspended,
      }),
    );
    const activeUsers = updatedUsersData.filter((user) => user.active);
    const archiveUsers = updatedUsersData.filter((user) => !user.active);
    dispatch({ type: actionTypes.GET_USER_TABLE_DATA, activeUsers, archiveUsers });
  }
};

export const toggleUserModal = (editPersonId) => (dispatch) => {
  dispatch({ type: actionTypes.EDIT_USER_MODAL_TOGGLE, editPersonId });
};

export const setUsersTableErrors = (errors) => (dispatch) => {
  dispatch({ type: actionTypes.SET_USERS_TABLE_ERRORS, errors });
};
export const setUserModalErrors = (errors) => (dispatch) => {
  dispatch({ type: actionTypes.SET_USER_MODAL_ERRORS, errors });
};
export const clearUserModalErrors = () => (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_USER_MODAL_ERRORS });
};

export const setIsTableDataLoading = (isTableDataLoading) => (dispatch) => {
  dispatch({ type: actionTypes.SET_IS_TABLE_DATA_LOADING, isTableDataLoading });
};
export const updateSortOnTableColumn = (key) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_SORT_ON_TABLE_COLUMN, key });
};

export const updateEditedUserAllProps = (editedUser) => ({
  type: "USER_SETTINGS_SET_EDITED_USER",
  editedUser,
});

export const setCrmAccessModal = (bool, userId, personId) => ({
  type: "USER_SETTINGS_SET_CRM_ACCESS_MODAL",
  bool,
  userId,
  personId,
});

export const setLoadingOktaBtn = (userId) => ({
  type: "USER_SETTINGS_SET_LOADING_OKTA_BTN",
  userId,
});

export const setSuccessOktaBtn = (userId) => ({
  type: "USER_SETTINGS_SET_SUCCESS_OKTA_BTN",
  userId,
});

export const setErrorOktaBtn = (userId) => ({
  type: "USER_SETTINGS_SET_ERROR_OKTA_BTN",
  userId,
});

export const clearOktaBtn = () => ({
  type: "USER_SETTINGS_CLEAR_OKTA_BTN",
});

export const setSuspendUser = (editedUser, bool) => ({
  type: "SET_SUSPEND_USER",
  editedUser,
  bool,
});

export const setSuspendError = (bool) => ({
  type: "SET_SUSPEND_ERROR",
  bool,
});
