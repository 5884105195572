import React from "react";
import MentionablePersonTextArea from "@shared/mentions/MentionablePersonTextArea";
import PillButton from "../../Transactions/components/shared/PillButton";
import FieldLabel from "@shared/v2/FieldLabel";

const outcomeOptions = [
  { value: "talked_to_lead", label: "Talked" },
  { value: "left_message", label: "Left Message" },
  { value: "busy", label: "Busy" },
  { value: "failed", label: "Failed" },
  { value: "no_answer", label: "No Answer" },
  { value: "wrong_number", label: "Wrong Number" },
];

const CallOutcomeAndNotes = ({ callOutcome, onFieldChange, currentParticipantId }) => {
  const { outcome, comment } = callOutcome;

  return (
    <div className="tw-flex tw-flex-col">
      <FieldLabel className='tw-mb-8px' label='Call Outcome' isRequired />
      <div className="tw-flex tw-justify-between tw-mb-24px">
        {outcomeOptions.map((k, v) => (
          <PillButton
            onClick={() => onFieldChange("outcome", k.value)}
            className={`tw-border-1px tw-border-solid tw-border-gray-15 tw-text-gray-75 tw-h-[36px] tw-py-8px tw-px-12px ${outcome === k.value ?
              "tw-text-white tw-bg-theme-button-primary" :
              'tw-bg-transparent'
              }`}
            key={v}>
            {k.label}
          </PillButton>
        ))}
      </div>
      <FieldLabel className='tw-mb-8px' label="Notes" />
      <MentionablePersonTextArea
        id="interaction-phone-input"
        value={comment || ""}
        isUuid
        mentionable_id={currentParticipantId}
        mentionable_type="Person"
        onChange={(value) => onFieldChange("comment", value)}
        isExternal={false}
      />
    </div>
  );
};

export default CallOutcomeAndNotes;