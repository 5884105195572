import moment from "moment/moment";

/* eslint-disable camelcase */
const RECURR_DICTIONARY = {
  number: { label: "Every Day", value: "number" },
  numberOther: { label: "Every _ Day", value: "number" },
  weekly: { label: "Weekly", value: "weekly" },
};

const RECURR_WEEKDAYS = ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays", "Sundays"];

export const taskHasRecurring = (task) => {
  if (!task.recurringFrequency) return null;

  let frequencyKey = "numberOther";

  if (task.recurringFrequency === "weekly") {
    frequencyKey = "weekly";
  } else if (task.recurringEverySoManyDays === 1) {
    frequencyKey = "number";
  }

  return {
    frequency: RECURR_DICTIONARY[frequencyKey],
    recurring_day_of_week: task.recurringDayOfWeek
      ? { label: RECURR_WEEKDAYS[task.recurringDayOfWeek - 1], value: `${task.recurringDayOfWeek}` }
      : null,
    recurring_every_so_many_days: task.recurringEverySoManyDays,
  };
};

const MILESTONE_DICTIONARY = {
  "milestone-close_date": "Close Date",
  "milestone-date_listed": "Date Listed",
  "milestone-escrow_signing_date": "Escrow Signing Date",
  "milestone-expiration_date": "Expiration Date",
  "milestone-home_inspection_date": "Home Inspection Date",
  "milestone-mutual_acceptance_date": "Mutual Acceptance Date",
  "milestone-appraisal_date": "Appraisal Date",
  "milestone-canceled_date": "Canceled Date",
  "milestone-termite_inspection": "Termite Inspection",
};

export const taskHasContingent = ({ contingent }) => {
  if (!contingent) return false;

  const { event: mileStone, position: next, days: numOfDays } = contingent;

  return {
    event: { label: MILESTONE_DICTIONARY[mileStone], value: mileStone },
    next,
    numOfDays,
  };
};

export const taskHasAssignedPerson = ({ assigned_person }) => {
  if (!assigned_person) return false;

  return {
    label: assigned_person.fullName,
    value: assigned_person.uuid,
  };
};

export const taskHasEventType = ({ type }) => {
  if (!type) return null;

  return {
    label: type,
    value: type,
  };
};

export const taskHasDate = ({ due_on }) => {
  if (!due_on) return moment().format("MM/DD/YYYY");

  return moment(due_on, "MM/DD/YY").format("MM/DD/YYYY");
};
