import React, {Component} from 'react';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import ReactDOM from 'react-dom';

export default class SelectAll extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.props
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({...this.state, ...nextProps})
  }

  render() {
    const { showSelectAll } = this.state;

    return (
      !!showSelectAll &&
      <OverlayTrigger
        rootClose
        placement='bottom'
        overlay={(<Tooltip id={'tasks-select-all'} className={'hide'}/>)}>
        <div
          ref='new-ref'
          className={'task-multi-select-dropdown'}>
          <div
            className={'select-all-checkbox m-t-5 m-s-5'}>
            <label
              className={'demarginify'}>
              <input
                onChange={this.state.selectAllHandler}
                type={'checkbox'}
                checked={this.state.selectAll}>
              </input>
              <h6
                className={'p-l-5 display-inline-block demarginify'}>
                All on Page
              </h6>
            </label>
          </div>
          <div
            className={'select-all-checkbox m-b-5 m-s-5'}>
            <label
              className={'demarginify'}>
              <input
                onChange={this.state.selectNoneHandler}
                type={'checkbox'}
                checked={this.state.selectNone}>
              </input>
              <h6
                className={'p-l-5 display-inline-block demarginify'}>
                None
              </h6>
            </label>
          </div>
        </div>
      </OverlayTrigger>
    )
  }
}
