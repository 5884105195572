import { snakeCase } from "lodash";
import { useState, useEffect } from "react";
import { useDebounce } from "../../../../shared/hookHelpers";
import { caseTransformingAxios as axios } from "../../../../shared/v2/caseTransformingAxios";

export default ({ title, uuid }) => {
  const [_search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [availablePeopleDropdown, setAvailablePeopleDropdown] = useState([]);

  const noOptionsMessage =
    _search.length > 2
      ? `No ${title.toLowerCase()} found`
      : `Enter ${3 - _search.length} more characters to search`;

  const search = useDebounce(_search, 250);

  useEffect(() => {
    if (search.length < 3) return;
    const abortController = new AbortController();
    setIsLoading(true);
    axios
      .get(`/api/v4/transactions/${uuid}/people/search`, {
        params: {
          type: snakeCase(title),
          query: search,
        },
        signal: abortController.signal,
      })
      .then(({ data }) => {
        setIsLoading(false);
        setError("");
        setAvailablePeopleDropdown(
          data.people.map((result) => ({
            value: result.id,
            label: result.label,
            email: result.emailAddress,
          })),
        );
      })
      .catch(() => {
        setIsLoading(false);
        setError("Whoops! Something went wrong.");
      });
  }, [search]);

  return {
    noOptionsMessage,
    availablePeopleDropdown,
    setSearch,
    isLoading,
    error,
  };
};
