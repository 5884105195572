import React from "react";
import PropTypes from "prop-types";

const Metric = ({ metricValue, metricTitle, span }) => (
  <div
    className={`tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 tw-px-[16px] tw-py-[12px] ${
      span ? "tw-col-span-2" : ""
    }`}
  >
    <div className="tw-font-bold">{metricValue}</div>
    <div>{metricTitle}</div>
  </div>
);

Metric.propTypes = {
  metricValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  metricTitle: PropTypes.string.isRequired,
  span: PropTypes.bool.isRequired,
};

export default Metric;
