import React, { useState } from "react";
import { string, func, shape } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import VisibilityHidden from "../../../../shared/v2/Icomoon/Icons/VisibilityHidden";
import VisibilityVisible from "../../../../shared/v2/Icomoon/Icons/VisibilityVisible";
import StarFilled from "../../../../shared/v2/Icomoon/Icons/StarFilled";
import StarOutline from "../../../../shared/v2/Icomoon/Icons/StarOutline";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";
import Toggle from "../../../../shared/v2/Toggle/Toggle";
import { patchNoteAsThunk } from "../actions/thunks";
import { setEditNoteModal } from "../actions/creators";
import DeleteNoteConfirmationModal from "../DeleteNoteConfirmationModal";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

const EllipsisDropdown = ({ uuid, note, onPatchNoteAsThunk, onSetEditNoteModal }) => {
  const [deleteListing, setDeleteListing] = useState(false);
  return (
    <>
      <div className="tw-shadow-modal tw-flex tw-flex-col tw-items-start tw-ml-[-227px] tw-mt-[-15px] tw-pt-[10px] tw-pb-[4px] tw-absolute tw-bg-white tw-rounded tw-cursor-default tw-w-[224px]">
        <div className="tw-ml-[12px] tw-h-[36px]">
          <button
            type="button"
            className="tw-flex tw-border-none tw-bg-transparent"
            onClick={() => onSetEditNoteModal(true, note)}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            <div className="tw-ml-[12px]">Edit</div>
          </button>
        </div>

        <div className="tw-h-[36px] tw-flex tw-w-[100%] tw-justify-evenly">
          <div>{note.externally_visible ? <VisibilityVisible /> : <VisibilityHidden />}</div>
          <div>Visible to Viewers</div>
          <div>
            <Toggle
              id="starred"
              onChange={() => {
                onPatchNoteAsThunk({ ...note, externally_visible: !note.externally_visible }, uuid);
              }}
              checked={note.externally_visible}
            />
          </div>
        </div>
        <div className="tw-h-[36px] tw-flex tw-w-[100%] tw-justify-evenly">
          <div>{note.starred ? <StarFilled /> : <StarOutline />}</div>
          <div>Mark as Important</div>
          <div>
            <Toggle
              id="mark_as_important"
              onChange={() => {
                onPatchNoteAsThunk({ ...note, starred: !note.starred }, uuid);
              }}
              checked={note.starred}
            />
          </div>
        </div>
        <div className="tw-border tw-border-solid tw-w-[100%] tw-text-gray-10 " />
        <div className="tw-ml-[12px] tw-h-[36px] tw-flex tw-items-center">
          <div className="tw-mr-[7px]">
            <Trash />
          </div>

          <button
            type="button"
            className="tw-flex tw-border-none tw-bg-transparent"
            onClick={() => setDeleteListing(true)}
          >
            <div className="tw-text-semantic-red-110">Delete</div>
          </button>
        </div>
      </div>
      <DeleteNoteConfirmationModal
        note={note}
        setDeleteListing={setDeleteListing}
        deleteListing={deleteListing}
      />
    </>
  );
};

const withTdpDetails = tdpDetails({
  WrappedComponent: EllipsisDropdown,
  states: LISTING_UUID,
});

export default withDispatch({
  WrappedComponent: withTdpDetails,
  actions: [patchNoteAsThunk, setEditNoteModal],
  aliases: ["onPatchNoteAsThunk", "onSetEditNoteModal"],
});

EllipsisDropdown.propTypes = {
  uuid: string.isRequired,
  note: shape({
    id: string,
  }),
  onPatchNoteAsThunk: func.isRequired,
  onSetEditNoteModal: func.isRequired,
};

EllipsisDropdown.defaultProps = {
  note: {
    id: "",
  },
};
