import React from "react";
import PropTypes from "prop-types";
import styleSchema from "./Tag.styles";
import { XmarkLargeSolidV6 } from "../Icomoon";
import IconButton from "../IconButton";

const Tag = ({ children, disableMediaQuery, removable, onRemove, className, ...otherProps }) => {
  const classes = `${removable ? styleSchema.regular : styleSchema.nonRemovable} ${className}`;

  return (
    <div className={classes} {...otherProps}>
      {children}
      {removable && (
        <IconButton
          onPointerUp={onRemove}
          onKeyUp={(e) => {
            if (e.key === "Enter" || e.key === " ") onRemove?.();
          }}
          size="xsmall"
          schema="transparent"
        >
          <XmarkLargeSolidV6 />
        </IconButton>
      )}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  disableMediaQuery: PropTypes.bool,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};

Tag.defaultProps = {
  disableMediaQuery: false,
  removable: true,
  onRemove: null,
  className: "",
};

export default Tag;
