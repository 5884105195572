import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MmsMediaModal from "@shared/MmsMediaModal";
import { CommentDotsRegularV6 } from "@shared/v2/Icomoon";
import BaseEvent from "./BaseEvent";
import SmsStatus from "./SmsStatus";

const TimelineGroupSmsEvent = ({ event }) => {
  const {
    attachments,
    delivery_status: deliveryStatus,
    error_code: errorCode,
    message,
    non_delivery_reasons: nonDeliveryReasons,
    recipients,
    sender,
  } = event.timeline_fields || {};
  const [state, setState] = useState({
    selectedMessage: null,
    mediaIndex: null,
  });
  return (
    <BaseEvent
      title={
        <div>
          <span className="tw-font-semibold">{sender?.full_name}</span> texted{" "}
          <span className="tw-font-semibold">{recipients?.map((r) => r.full_name).join(", ")}</span>
        </div>
      }
      icon={<CommentDotsRegularV6 size="l" />}
      interactedAt={event.interacted_at_absolute}
    >
      <div className="tw-flex tw-justify-between tw-items-start tw-gap-[8px]">
        <div className="tw-break-all">{message}</div>
        <SmsStatus
          deliveryStatus={event.delivery_status || deliveryStatus}
          errorCode={event.non_delivery_reasons?.find((r) => r.error_code)?.error_code || errorCode}
          failedRecipients={
            (event.non_delivery_reasons || nonDeliveryReasons)?.map((reason) => reason.recipient) || []
          }
        />
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-[8px]">
        {(event.mms_media || attachments)?.map(({ url }, i) => (
          <button
            type="button"
            className="tw-border-0 tw-p-0 tw-m-0 tw-bg-transparent"
            aria-label="preview image"
            onClick={() =>
              setState({
                selectedMessage: {
                  createdByAlias: sender?.full_name,
                  dateCreated: moment(event.interacted_at_absolute, "MM/DD/YY [at] h:mm a z"),
                  attachments: (event.mms_media || attachments).map(({ url: fileUrl }) => ({ fileUrl })),
                },
                mediaIndex: i,
              })
            }
          >
            <img
              src={url}
              className="tw-rounded-lg tw-max-h-96 tw-max-w-96 tw-h-auto tw-w-auto"
              alt="unknown"
            />
          </button>
        ))}
      </div>
      <MmsMediaModal
        message={state.selectedMessage}
        mediaIndex={state.mediaIndex}
        onClose={() => setState({ selectedMessage: null, mediaIndex: null })}
      />
    </BaseEvent>
  );
};

TimelineGroupSmsEvent.propTypes = {
  event: PropTypes.shape().isRequired,
};

export default TimelineGroupSmsEvent;
