import axios from "axios";

import { minimalPlanFromApi, planFromApi } from "../api/apiAdapters";

import {
  applyAutoPlanFailure,
  applyAutoPlanStarted,
  applyAutoPlanSuccess,
  assignedActionDeletion,
  assignedActionRestartOrMarkAsComplete,
  autoPlanPreview,
  fetchAssignedPlanDetailsFailure,
  fetchAssignedPlanDetailsStarted,
  fetchAssignedPlanDetailsSuccess,
  fetchAssignedPlansFailure,
  fetchAssignedPlansStarted,
  fetchAssignedPlansSuccess,
  fetchAvailableAutoPlansFailure,
  fetchAvailableAutoPlansStarted,
  fetchAvailableAutoPlansSuccess,
  setApplyAutoPlanModalVisible,
  setShowDetailsModalForAssignedPlan,
  setShowPreviewModalForAutoPlan,
  updateAssignedPlanStateFailure,
  updateAssignedPlanStateStarted,
  updateAssignedPlanStateSuccess,
} from "./creators";

export const fetchAssignedPlans = (uuid) => async (dispatch) => {
  try {
    dispatch(fetchAssignedPlansStarted());

    const res = await axios.get(`/assigned_plans?assignable_type=Listing&assignable_id=${uuid}`);
    if (res.status !== 200) {
      dispatch(fetchAssignedPlansFailure(res.data));
      return;
    }

    dispatch(fetchAssignedPlansSuccess(res.data.map(minimalPlanFromApi)));
  } catch (err) {
    dispatch(fetchAssignedPlansFailure(err));
  }
};

// TODO: Drop this and incorporate it into `showDetailsModalForAssignedPlan`?
export const fetchAssignedPlanDetails = (assignedPlanId) => async (dispatch) => {
  try {
    dispatch(fetchAssignedPlanDetailsStarted());

    const res = await axios.get(`/assigned_plans/${assignedPlanId}`);
    if (res.status !== 200) {
      dispatch(fetchAssignedPlanDetailsFailure(res.data));
      return;
    }

    dispatch(fetchAssignedPlanDetailsSuccess(planFromApi(res.data.assigned_plan)));
  } catch (err) {
    dispatch(fetchAssignedPlanDetailsFailure(err));
  }
};

export const showDetailsModalForAssignedPlan = (assignedPlanId) => async (dispatch) => {
  dispatch(setShowDetailsModalForAssignedPlan(assignedPlanId));

  if (assignedPlanId !== null) {
    dispatch(fetchAssignedPlanDetails(assignedPlanId));
  }
};

export const fetchAutoPlanPreview = (transactionUuid, autoPlanId) => async (dispatch) => {
  try {
    dispatch(autoPlanPreview("loading"));

    const endpoint = `/auto_plans/${autoPlanId}/preview?assignable_type=Listing&assignable_id=${transactionUuid}`;
    const res = await axios.get(endpoint);
    if (res.status !== 200) {
      dispatch(autoPlanPreview("failed", res.data));
      return;
    }

    dispatch(autoPlanPreview("succeeded", planFromApi(res.data.auto_plan)));
  } catch (err) {
    dispatch(autoPlanPreview("failed", err.message));
  }
};

export const showPreviewModalForAssignedPlan = (transactionUuid, assignedPlanId) => async (dispatch) => {
  dispatch(setShowPreviewModalForAutoPlan(assignedPlanId));

  if (transactionUuid && assignedPlanId) {
    dispatch(fetchAutoPlanPreview(transactionUuid, assignedPlanId));
  }
};

export const deleteAction = (assignedPlanId, assignedActionId) => async (dispatch) => {
  try {
    dispatch(assignedActionDeletion("loading"));

    const endpoint = `/assigned_plans/${assignedPlanId}/assigned_actions/${assignedActionId}`;
    const res = await axios.delete(endpoint, {
      params: { authenticity_token: ReactOnRails.authenticityToken() },
    });
    if (res.status !== 204) {
      dispatch(assignedActionDeletion("failed", res.data));
      return;
    }

    dispatch(assignedActionDeletion("succeeded"));
    dispatch(fetchAssignedPlanDetails(assignedPlanId));
  } catch (err) {
    dispatch(assignedActionDeletion("failed", err.message));
  }
};

export const markActionAsComplete = (assignedPlanId, assignedActionId) => async (dispatch) => {
  try {
    dispatch(assignedActionRestartOrMarkAsComplete("loading"));

    const endpoint = `/assigned_plans/${assignedPlanId}/assigned_actions/${assignedActionId}/mark_complete`;
    const res = await axios.patch(endpoint, {
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    if (res.status !== 200) {
      dispatch(assignedActionRestartOrMarkAsComplete("failed", res.data));
      return;
    }

    dispatch(assignedActionRestartOrMarkAsComplete("succeeded"));
    dispatch(fetchAssignedPlanDetails(assignedPlanId));
  } catch (err) {
    dispatch(assignedActionRestartOrMarkAsComplete("failed", err.message));
  }
};

export const restartAction = (assignedPlanId, assignedActionId) => async (dispatch) => {
  try {
    dispatch(assignedActionRestartOrMarkAsComplete("loading"));

    const endpoint = `/assigned_plans/${assignedPlanId}/assigned_actions/${assignedActionId}/restart`;
    const res = await axios.patch(endpoint, {
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    if (res.status !== 200) {
      dispatch(assignedActionRestartOrMarkAsComplete("failed", res.data));
      return;
    }

    dispatch(assignedActionRestartOrMarkAsComplete("succeeded"));
    dispatch(fetchAssignedPlanDetails(assignedPlanId));
  } catch (err) {
    dispatch(assignedActionRestartOrMarkAsComplete("failed", err.message));
  }
};

export const updateAssignedPlanState =
  (assignedPlanId, desiredState, options = {}) =>
  async (dispatch) => {
    try {
      dispatch(updateAssignedPlanStateStarted(assignedPlanId));

      const res = await axios.patch(`/assigned_plans/${assignedPlanId}`, {
        state: desiredState,
        authenticity_token: ReactOnRails.authenticityToken(),
      });
      if (res.status !== 200) {
        dispatch(updateAssignedPlanStateFailure(res.data));
        return;
      }

      dispatch(updateAssignedPlanStateSuccess(minimalPlanFromApi(res.data.assigned_plan)));

      // When the plan's state is updated from within the details modal, we need
      // to reload the details so the modal reflects the new state.
      if (options.triggerPlanDetailsRefresh) {
        dispatch(fetchAssignedPlanDetails(assignedPlanId));
      }

      // And upon deleting, close the details modal if it's currently open.
      if (desiredState === "deleted") {
        dispatch(showDetailsModalForAssignedPlan(null));
      }
    } catch (err) {
      dispatch(updateAssignedPlanStateFailure(err));
    }
  };

export const fetchAvailableAutoPlans = () => async (dispatch) => {
  try {
    dispatch(fetchAvailableAutoPlansStarted());

    const res = await axios.get(`/auto_plans/auto_plans_no_actions?plan_type=transactions`);
    if (res.status !== 200) {
      dispatch(fetchAvailableAutoPlansFailure(res.data));
      return;
    }

    dispatch(fetchAvailableAutoPlansSuccess(res.data.map(minimalPlanFromApi)));
  } catch (err) {
    dispatch(fetchAvailableAutoPlansFailure(err));
  }
};

export const applyAutoPlan = (transactionUuid, autoPlanId) => async (dispatch) => {
  try {
    dispatch(applyAutoPlanStarted());

    const res = await axios.post("/assigned_plans", {
      assignable_id: transactionUuid,
      assignable_type: "Listing",
      auto_plan_id: autoPlanId,
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    if (res.status !== 201) {
      dispatch(applyAutoPlanFailure(res.data));
      return;
    }

    dispatch(applyAutoPlanSuccess(minimalPlanFromApi(res.data)));
    dispatch(setApplyAutoPlanModalVisible(false));
    dispatch(showPreviewModalForAssignedPlan(null));
    dispatch(fetchAssignedPlans(transactionUuid));
  } catch (err) {
    let errorsArray = [err.message];
    if (err.response?.data?.errors && err.response?.data?.errors.length) {
      errorsArray = err.response?.data?.errors;
    }

    dispatch(applyAutoPlanFailure(errorsArray));
  }
};
