import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

export default class GmailMessage extends Component {
  constructor(props, context){
    super(props, context)
  }

  attachment_file_icon_classes = (mime_type) => {
    switch(mime_type) {
      case 'image/gif':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return 'fa fa-file-image-o'
        break;
      case 'application/pdf':
        return 'fa fa-file-pdf-o'
        break;
      default:
        return 'fa fa-file-o'
    }
  }

  attachmentIcon = () => {
    return (
      <i className="attachment-icon fa fa-paperclip"/>
    )
  }

  render() {
    const message = this.props.message
    const activeMessageId = this.props.activeMessageId
    const toggleMessage = this.props.toggleMessage

    return (
      <li
        key={message.id}
        onClick={() => toggleMessage(message.id)}
        className={message.id == activeMessageId ? 'active' : 'inactive'}
      >
        <div className="row message-meta-data">
          <div className="col-xs-10">
            {message.from + ' to ' + message.to}
          </div>
          <div className="col-xs-2 relative-time">
            {message.attachments.length > 0 && this.attachmentIcon()}
            {message.interacted_at_relative_in_words}
          </div>
        </div>

        {
          message.id == activeMessageId &&
          <div>
            <div className="row message-body">
              <div dangerouslySetInnerHTML={{__html: message.body}} />

              <div className="message-attachments">
                {
                  message.attachments.map(attachment => {
                    const query = '?message_id=' + message.id + '&filename=' + attachment.filename + '&content_type=' + attachment.mime_type
                    const path = '/gmail_attachments/' + attachment.body.attachment_id + query

                    return (
                      <a href={path} key={attachment.body.attachment_id} className="message-attachment">
                        <i className={'attachment-file-icon ' + this.attachment_file_icon_classes(attachment.mime_type)} aria-hidden="true" />
                        {attachment.filename}
                      </a>
                    )
                  })
                }
              </div>
            </div>
          </div>
        }
      </li>
    )
  }
}
