import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import { EllipsisSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import { setSnackbar } from "../reducers/snackbarReducer";

const CmaReportMenu = ({ deleteReportFn, id }) => {
  const dispatch = useDispatch();
  const host = process.env.NODE_ENV === "production" ? "www.brivitycma.com" : "www.staging.brivitycma.com";
  const previewUrl = `https://${host}/reports/${id}/preview`;
  const editUrl = `https://${host}/reports/build/${id}`;
  const openPreviewFn = () => window.open(previewUrl, "_blank");
  const editReportFn = () => window.open(editUrl, "_blank");
  const shareReportFn = () =>
    navigator.clipboard.writeText(previewUrl).then(() =>
      dispatch(
        setSnackbar({
          position: "top",
          show: true,
          text: "Link copied",
          schema: "executed",
          timeout: 3000,
          closeOnTimeout: () => dispatch(setSnackbar({ show: false })),
        }),
      ),
    );

  const dropdownLinks = [
    { key: "edit", onClick: editReportFn, icon: "pencil", text: "Edit" },
    { key: "preview", onClick: openPreviewFn, icon: "eye", text: "Preview" },
    { key: "share", onClick: shareReportFn, icon: "link", text: "Copy Link" },
    { key: "delete", onClick: deleteReportFn, icon: "trash", text: "Delete" },
  ];

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton schema="tertiary" size="small" aria-label="menu">
          <EllipsisSolidV6 />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent className="!tw-z-[3001]">
        {dropdownLinks.map((link) => (
          <PopoverItem key={link.key} onClick={link.onClick}>
            <i className={`tw-mr-[8px] fa fa-${link.icon}`} />
            {link.text}
          </PopoverItem>
        ))}
      </PopoverContent>
    </Popover>
  );
};

CmaReportMenu.propTypes = {
  deleteReportFn: PropTypes.func,
  id: PropTypes.string,
};

CmaReportMenu.defaultProps = {
  deleteReportFn: null,
  id: null,
};

export default CmaReportMenu;
