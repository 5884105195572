import React, { useState } from "react";
import PropTypes from "prop-types";
import TextInput from "../../shared/v2/TextInput";
import { PencilSolidV6 } from "../../shared/v2/Icomoon";

const EdiableTitle = ({ value, onChange, editable, onBlur }) => {
  const [focused, setFocused] = useState(false);
  return (
    <div className="tw-flex">
      {focused ? (
        <TextInput
          containerClassName="tw-w-full tw-flex-1"
          autoFocus
          value={value}
          onBlur={() => {
            setFocused(false);
            onBlur();
          }}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <span>
          {editable ? (
            <button
              type="button"
              className="tw-border-0 tw-bg-transparent tw-p-0 tw-group tw-font-[18px]"
              onClick={() => setFocused(true)}
            >
              {value}{" "}
              <span className="group-hover:tw-inline tw-hidden tw-ml-2">
                <PencilSolidV6 size="m" />
              </span>
            </button>
          ) : (
            <span className="tw-font-[18px]">{value}</span>
          )}
        </span>
      )}
    </div>
  );
};
EdiableTitle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};
export default EdiableTitle;
