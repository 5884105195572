import React, { useEffect, useMemo, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
// eslint-disable-next-line import/no-unresolved
import { Bar, Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import Loader from "../../../Goals/components/Loader";
import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import Tab from "../../../shared/v2/Tab";
import TabBar from "../../../shared/v2/TabBar";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import TextToggle from "../../../shared/v2/TextToggle/TextToggle";
import IconButton from "../../../shared/v2/IconButton";
import { ChartLine, Report, Table } from "../../../shared/v2/Icomoon";
import Checkbox from "../../../shared/v2/Checkbox/Checkbox";
import BasicStat from "../BusinessDashboard/BasicStat";
import Dot from "../BusinessDashboard/Dot";
import setCheck from "./helpers/setCheck";
import useMonthlyOverview, { today } from "./services/useMonthlyOverview";
import useFullscreen from "../BusinessDashboard/hooks/useFullscreen";
import TableModal from "../BusinessDashboard/TableModal/TableModal";
import CustomControl from "../BusinessDashboard/Dropdown/CustomControl";
import { CustomBasicSingleValue } from "../BusinessDashboard/Dropdown/CustomSingleValue";
import CustomValueContainer from "../BusinessDashboard/Dropdown/CustomValueContainer";
import Error from "./Error";
import getDateDropdownOptions from "./helpers/getDateDropdownOptions";
import CustomDropdownIndicator from "../BusinessDashboard/Dropdown/CustomDropdownIndicator";

const RadioButton = ({ chart, name, setChart, Icon }) => {
  const isSelected = chart === name;
  return (
    <IconButton
      schema={isSelected ? "primary" : "tertiary"}
      role="radio"
      name={name}
      aria-checked={isSelected}
      tabIndex={isSelected ? 0 : -1}
      onClick={() => setChart(name)}
      onKeyDown={(e) => {
        // simple two radio button, if more are added modify this
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") setChart(name === "bar" ? "line" : "bar");
      }}
    >
      <Icon size="l" />
    </IconButton>
  );
};

RadioButton.propTypes = {
  chart: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setChart: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
};

const MonthlyOverview = ({ agentUuid, year }) => {
  const MONTHLY_OVERVIEW_OPTIONS = useMemo(() => getDateDropdownOptions(year), [year]);
  const radiogroupRef = useRef(null);
  const [rangeType, setRangeType] = useState(MONTHLY_OVERVIEW_OPTIONS[0]);
  const [tab, setTab] = useState("Closings");
  const [inspectedValue, setInspectedValue] = useState("Units");
  const [chartType, setChartType] = useState("bar");
  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [checks, setChecks] = useState([true, true, true]);
  const { isFullscreen, ref, toggleFullscreen } = useFullscreen();

  useEffect(() => setRangeType(MONTHLY_OVERVIEW_OPTIONS[0]), [year]);

  useEffect(() => {
    if (radiogroupRef.current.querySelector(":focus")) {
      radiogroupRef.current.querySelector(`[name=${chartType}]`).focus();
    }
  }, [chartType]);

  const { chartOptions, checkLabels, data, error, loading, tableData } = useMonthlyOverview({
    agentUuid,
    chartType,
    checks,
    inspectedValue,
    rangeType: rangeType.value,
    tab,
    year,
  });

  return (
    <Section
      className={`tw-relative tw-flex tw-flex-col tw-gap-[28px] tw-flex-1 tw-min-w-[320px] ${
        isFullscreen ? "tw-max-w-[100%] tw-px-[12%]" : ""
      }`}
      ref={ref}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <SectionLabel>Monthly Overview</SectionLabel>
        <div className="tw-flex tw-items-center">
          <Dropdown
            components={{
              Control: CustomControl,
              SingleValue: CustomBasicSingleValue,
              ValueContainer: CustomValueContainer,
              DropdownIndicator: CustomDropdownIndicator,
            }}
            value={rangeType}
            onChange={setRangeType}
            options={MONTHLY_OVERVIEW_OPTIONS}
          />
          <IconButton
            className="tw-ml-[8px]"
            size="medium"
            schema="transparent"
            onClick={toggleFullscreen}
            title="Fullscreen"
          >
            <i className="fa fa-arrows-alt" />
          </IconButton>
        </div>
      </div>
      <TabBar>
        <Tab selected={tab === "Closings"} label="Closings" onClick={() => setTab("Closings")} />
        <Tab selected={tab === "Pendings"} label="Pendings" onClick={() => setTab("Pendings")} />
        <Tab selected={tab === "Listings"} label="Listings" onClick={() => setTab("Listings")} />
      </TabBar>
      <h3 className="tw-text-14d tw-font-bold tw-m-0">
        {today.format("MMMM")} {year}
      </h3>
      <div className="tw-flex tw-flex-wrap tw-gap-[8px] tw-justify-between">
        {tab === "Closings" && (
          <>
            <BasicStat label="Closed Units" value={data.summary.closedUnits} />
            <BasicStat label={agentUuid ? "Net Income" : "Closed GCI"} value={data.summary.closedGci} />
            <BasicStat label="To Be Closed" value={data.summary.toBeClosedUnits} />
            <BasicStat label={agentUuid ? "Est. EOM Net Income" : "Est. EOM GCI"} value={data.summary.estTotalGci} />
          </>
        )}
        {tab === "Pendings" && (
          <>
            <BasicStat label="Pended Units" value={data.summary.pendedUnits} />
            <BasicStat label="Pended Volume" value={data.summary.pendedVolume} />
            <BasicStat label={agentUuid ? "Net Income" : "Pended GCI"} value={data.summary.pendedGci} />
            <BasicStat label="Failed Pendings" value={data.summary.failedPendings} />
          </>
        )}
        {tab === "Listings" && (
          <>
            <BasicStat label="Listings Taken" value={data.summary.listingsTaken} />
            <BasicStat label="Volume" value={data.summary.volume} />
            <BasicStat label={agentUuid ? "Net Income" : "GCI"} value={data.summary.gci} />
            <BasicStat label="Coming Soon" value={data.summary.comingSoon} />
          </>
        )}
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <TextToggle
          size="small"
          name="monthlyStat"
          options={[
            {
              label: "Units",
              value: "Units",
            },
            {
              label: "Volume",
              value: "Volume",
            },
            {
              label: agentUuid ? "Net Income" : "GCI",
              value: "GCI",
            },
          ]}
          value={inspectedValue}
          onChange={(e) => setInspectedValue(e.target.value)}
        />

        <div className="tw-flex tw-gap-x-[4px]">
          <div
            className="tw-flex tw-gap-x-[4px]"
            role="radiogroup"
            aria-label="Chart Selection"
            id="chartSelection"
            ref={radiogroupRef}
          >
            <RadioButton Icon={Report} chart={chartType} name="bar" setChart={setChartType} />
            <RadioButton Icon={ChartLine} chart={chartType} name="line" setChart={setChartType} />
          </div>
          <IconButton
            schema="tertiary"
            aria-label="Open Table Modal"
            onClick={() => setIsTableModalOpen(true)}
          >
            <Table size="l" />
          </IconButton>
        </div>
      </div>
      {chartType === "bar" && <Bar data={data.chartData} options={chartOptions} />}
      {chartType === "line" && (
        <Line plugins={[ChartDataLabels]} data={data.chartData} options={chartOptions} />
      )}
      <div className="tw-flex tw-justify-end tw-gap-[20px]">
        {checkLabels.map(({ label, color }, i) =>
          label ? (
            <Checkbox
              key={label}
              id={label}
              name={label}
              value={label}
              checked={checks[i]}
              onChange={setCheck(setChecks, i)}
              label={
                <div className="tw-flex tw-items-center tw-gap-[4px]">
                  <Dot className={color} /> {label}
                </div>
              }
            />
          ) : null,
        )}
      </div>
      <TableModal
        data={tableData}
        tab={tab}
        onClose={() => setIsTableModalOpen(false)}
        show={isTableModalOpen}
        agentUuid={agentUuid}
      />
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </Section>
  );
};

MonthlyOverview.propTypes = {
  agentUuid: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

MonthlyOverview.defaultProps = {
  agentUuid: undefined,
};

export default MonthlyOverview;
