import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: [
      "tw-font-16d",
      "tw-px-[8px]",
      "tw-py-[4px]",
      "tw-text-neutral-gray-50",
      "tw-font-semibold",
      "tw-rounded-[30px]",
    ],
    peerChecked: ["peer-checked:tw-text-white", "peer-checked:tw-bg-theme-icon-toggle-bg-checked"],
    peerFocusVisible: [
      "peer-focus-visible:tw-outline-theme",
      "peer-focus-visible:tw-outline-theme-offset-1px",
    ],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
