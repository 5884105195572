import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverItem, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";

import IconButton from "@shared/v2/IconButton";
import { VerticalDots, Download, Trash, VisibilityVisible, Edit, Upload } from "@shared/v2/Icomoon/Icons";

const Menubtn = ({ document, triggerRename, deleteDocuments, triggerReplace }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hiddenUploadagreementInput = useRef(null);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    deleteDocuments(document.id);
    setIsOpen(false);
  };

  const handleRename = () => {
    setIsOpen(false);
    triggerRename();
  };

  const triggerReplaceDoc = (e) => {
    triggerReplace(document.id, e);
    setIsOpen(false);
  };

  const handleReplace = () => {
    hiddenUploadagreementInput.current?.click();
  };

  const handlePreview = () => {
    if (document.expiringUrl) {
      window.open(document.expiringUrl, "_blank");
    }
    setIsOpen(false);
  };
  return (
    <div className="tw-relative tw-flex tw-justify-start">
      <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-end">
        <PopoverTrigger>
          <IconButton size="small" schema="tertiary" onClick={handleButtonClick}>
            <VerticalDots size="l" />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent className="tw-z-3000 tw-gap-[8px]">
          {document.expiringUrl && (
            <>
              <PopoverItem onClick={handlePreview}>
                <VisibilityVisible size="m" /> <span className="tw-pl-[8px]">Preview</span>
              </PopoverItem>
              <PopoverItem onClick={handlePreview}>
                <Download size="m" /> <span className="tw-pl-[8px]">Download</span>
              </PopoverItem>
            </>
          )}
          <PopoverItem onClick={handleRename}>
            <Edit size="m" /> <span className="tw-pl-[8px]">Rename</span>
          </PopoverItem>
          <input
            type="file"
            ref={hiddenUploadagreementInput}
            onChange={triggerReplaceDoc}
            style={{ display: "none" }}
          />
          <PopoverItem onClick={handleReplace}>
            <Upload size="m" />
            <span className="tw-pl-[8px]">Replace</span>
          </PopoverItem>
          <PopoverItem onClick={handleDelete}>
            <Trash size="m" /> <span className="tw-pl-[8px]">Delete</span>
          </PopoverItem>
        </PopoverContent>
      </Popover>
    </div>
  );
};

Menubtn.propTypes = {
  document: PropTypes.shape({}),
  triggerRename: PropTypes.func,
  deleteDocuments: PropTypes.func,
  triggerReplace: PropTypes.func,
};

Menubtn.defaultProps = {
  document: {},
  triggerRename: () => {},
  deleteDocuments: () => {},
  triggerReplace: () => {},
};

export default Menubtn;
