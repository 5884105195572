/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowUpRightFromSquareSolidV6 = (props) => (
  <Icon {...props} name="Arrow Up Right From Square Solid V6">
    <path d="M9 2a1 1 0 0 1 1-1h3.972c.163 0 .294.027.409.076.091.048.228.12.325.215 0 .002 0 .003.003.004.194.194.262.448.291.702V6a1 1 0 1 1-2 0V4.416L7.706 9.707c-.391.391-1.022.391-1.413 0s-.391-1.022 0-1.413L11.584 3H10a1 1 0 0 1-1-1zM1 4a2 2 0 0 1 2-2h3a1 1 0 1 1 0 2H3v9h9v-3a1 1 0 1 1 2 0v3c0 1.103-.897 2-2 2H3a2 2 0 0 1-2-2V4z" />
  </Icon>
);

export default ArrowUpRightFromSquareSolidV6;
