/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PenToSquareSolidV6 = (props) => (
  <Icon {...props} name="Pen To Square Solid V6">
    <path d="M15.322 1.263a1.75 1.75 0 0 1 0 2.475l-.937.941-3.063-3.062.941-.939a1.75 1.75 0 0 1 2.475 0l.584.586zm-9.934 6.29 5.228-5.23 3.063 3.061-5.231 5.228a1.74 1.74 0 0 1-.684.425l-2.775.925c-.297.087-.566.019-.769-.209-.2-.175-.269-.472-.181-.741l.925-2.775c.088-.259.231-.494.425-.684zM6 1.972c.553 0 1 .476 1 1 0 .581-.447 1-1 1H3c-.552 0-1 .475-1 1V13a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.972c0-.525.447-1 1-1s1 .475 1 1V13c0 1.656-1.344 3-3 3H3a3 3 0 0 1-3-3V4.972a3 3 0 0 1 3-3h3z" />
  </Icon>
);

export default PenToSquareSolidV6;
