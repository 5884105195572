import React from "react";
import { Col } from "../../shared/v1";
import TextInput from "../../shared/v2/TextInput";

export default class LocationInput extends React.Component {
  render() {
    //CHECKME: isSchedulingAppointment is coming from TaskFormLogAppointment.jsx , where the TaskForm is getting called from lead or people IDX
    //CHECKME: handleLocationOnChange is coming from TaskForm.jsx
    //CHECKME: personTaskForm is coming from PersonTaskForm.jsx where the TaskForm is getting called in the PDP page from the + on tasks and appointments
    //CHECKME: editTaskFor is coming from TaskIndexModalHelpers.jsx where TaskForm is getting called for editing
    const { handleLocationOnChange, task } = this.props;
    return (
      <Col size={12} className="tw-mb-[16px]">
        <TextInput
          label="Location"
          onChange={handleLocationOnChange}
          defaultValue={task.location}
          name="location"
        />
      </Col>
    );
  }
}
