/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ListUlSolidV6 = (props) => (
  <Icon {...props} name="List Ul Solid V6">
    <path d="M.5 3a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 .5 3zM15 2a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2h9zm0 5a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2h9zm0 5a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2h9zM.5 13a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 .5 13zm3-5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 3.5 8z" />
  </Icon>
);

export default ListUlSolidV6;
