/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React from "react";
import { produce } from "immer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import moment from "moment";

import TextField from "./TextField";
import DateTime from "../DateTime";
import AssignToDropdown from "../AssignToDropdown";
import { convertDateFormat, formatDateAndTimeToDatePicker, onSaveDateAdapter } from "../../helpers";
import { useFormState } from "../../hooks";
import ErrorInModal from "../ErrorInModal";
import { useSpecificActionSubmit } from "../../../reducers/autoPlanEdit";

const DEFAULT_STATE = {
  type:"Text",
  template_body: "",
  assigned_to_type: null,
  assigned_to_role: 'owner',
  assigned_to_id: null,
  run_time: "08:00",
  day_month_year: moment().format('DD/MM/YYYY'),  
  delay_unit: 'days',    
}

const TextActionModal = ({ show, onHide, onSave, autoPlanId, placeholders, assignedToOptions, form, isSubmitting }) => {
  const formSubmitAction = useSpecificActionSubmit()
  const { state, setState, setStateHandlr } = useFormState({
    defaultState: DEFAULT_STATE,
    form,
    autoPlanId,
    show
  });

  const onHideHandlr = () => {
    setState({
      ...DEFAULT_STATE,
      auto_plan_id: autoPlanId,
    });
    onHide();
  }

  return (
  <Modal
    show={show}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    onHide={onHideHandlr}
    closeButton>
    <Modal.Header title={formSubmitAction === 'post' ? "Create a new text" : "Update text"} className="tw-mb-40px" closeButton />
    <Modal.Body className="tw-gap-[24px] tw-flex tw-flex-col">
      <TextField
        value={state.template_body}
        setValue={setStateHandlr('template_body')}
        options={placeholders.map(({text: label, value}) => ({label, value}))}/>
      <DateTime
        setState={(dateStr) => {
          const { day_month_year, run_time } = convertDateFormat(dateStr);

          setState(prev => produce(prev, (draft) => {
            draft.day_month_year = day_month_year;
            draft.run_time = run_time;
          }))
        }}
        value={formatDateAndTimeToDatePicker(state.day_month_year, state.run_time)}/>
      <AssignToDropdown
        options={assignedToOptions}
        label='Send From'
        state={{
          assigned_to_id: state.assigned_to_id,
          assigned_to_type: state.assigned_to_type,
          assigned_to_role: state.assigned_to_role,
        }}
        setState={(e) => setState(prev => ({
          ...prev,
          assigned_to_id: e.assigned_to_type ? e.value : null,
          assigned_to_role: e.assigned_to_type ? null : e.value,
          assigned_to_type: e.assigned_to_type || null,
        }))}/>
      <ErrorInModal/>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[40px]">
      <Button size="medium" schema="secondary" onClick={onHideHandlr}>
        CANCEL
      </Button>
      <Button size="medium" schema="primary" onClick={() => onSave(onSaveDateAdapter(state))} isLoading={isSubmitting}>
        SAVE
      </Button>
    </Modal.Footer>
  </Modal>
)};

export default TextActionModal;

TextActionModal.propTypes = {
  show: bool,
  isSubmitting: bool,
  autoPlanId: string.isRequired, 
  onHide: func.isRequired, 
  onSave: func.isRequired,
  placeholders: arrayOf(shape({})),
  form: shape({}),
  assignedToOptions: arrayOf(shape({})).isRequired,
}

TextActionModal.defaultProps = {
  show: true,
  isSubmitting: false,
  placeholders: [],
  form: {}
}