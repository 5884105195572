import React from "react";
import PropTypes from "prop-types";

import AvatarPlaceholder from "../Avatar/AvatarPlaceholder";
import { availableSizes } from "../Avatar/Avatar.styles";
import { avatarMarginValue, sizeBasedClasses } from "./AvatarGroup.styles";

const AvatarGroup = ({ className, size, max, children }) => {
  const childrenArray = Array.isArray(children) ? children : [children];

  const hiddenAvatarsCount = Math.max(0, childrenArray.length - max);

  const visibleAvatars = childrenArray.slice(0, max).reverse();

  return (
    <div
      className={`
        tw-flex tw-flex-row-reverse tw-justify-end tw-items-center
        ${sizeBasedClasses(size)}
        ${className}
      `}
    >
      {hiddenAvatarsCount > 0 && (
        <AvatarPlaceholder
          className={avatarMarginValue(size)}
          size={size}
          border="tinted"
          hiddenCount={hiddenAvatarsCount}
        />
      )}

      {visibleAvatars.map((avatar, index) =>
        React.cloneElement(avatar, {
          // `avatar` doesn't have any properties that can be used as a stable key,
          // and reordering the avatar array is unlikely to be a hot path / common operation,
          // so we're better off just silencing in this instance.
          // eslint-disable-next-line react/no-array-index-key
          key: `avatar-${index}`,
          className: [avatar.props?.className, avatarMarginValue(size)].filter(Boolean).join(" "),
          size,
          border: "tinted",
        }),
      )}
    </div>
  );
};

AvatarGroup.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(availableSizes),
  max: PropTypes.number,
  children: PropTypes.node,
};

AvatarGroup.defaultProps = {
  className: "",
  size: "small",
  max: 4,
  children: null,
};

export default AvatarGroup;
