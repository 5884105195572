import React from "react";

import { Section, SectionLabel } from "../BusinessDashboard/Section";
import { Activities, getOrdinalNumber } from "./helpers";
import UsersDropdown from "./UsersDropdown";
import Loader from "../../../Dashboard/components/Loader";

const GlobalLeaderboard = ({
  wasActivityInPeriod,
  selectedActivity,
  ranks,
  isPlaceUser,
  isLoading,
  users,
  selectedUser,
  setSelectedUser,
}) => {
  const activityName = Activities.find(({ key }) => key === selectedActivity).fullName;
  const themeStyles = isPlaceUser ? "tw-w-[512px]" : "tw-w-[460px]";

  return (
    <Section className="tw-flex-1 !tw-p-32px !bs-xs:tw-p-32px">
      <SectionLabel className="!tw-mb-0">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-2px">
            <span>Global Rankings</span>
            <span className="tw-text-14px tw-text-gray-50 tw-font-semibold">{activityName}</span>
          </div>
          <UsersDropdown users={users} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </div>
      </SectionLabel>
      <div className={`tw-relative ${themeStyles}`}>
        {wasActivityInPeriod === false ? (
          <EmptyState activityName={activityName} />
        ) : (
          <Rankings selectedActivity={selectedActivity} ranks={ranks} isPlaceUser={isPlaceUser} />
        )}
        {isLoading && <Loader />}
      </div>
    </Section>
  );
};

export default GlobalLeaderboard;

const EmptyState = ({ activityName }) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-[64px]">
      <span className="tw-text-18px tw-text-gray-50 tw-mb-16px tw-mx-[34px] tw-text-center">{`No ${activityName} logged for the selected time range`}</span>
    </div>
  );
};

const Rankings = ({ ranks, isPlaceUser }) => {
  return (
    <div className="tw-flex tw-justify-between tw-mt-[60px]">
      <Rank rank={ranks.teamRank} group="Team" isPlaceUser={isPlaceUser} />
      <Rank rank={ranks.brivityRank} group="Brivity" />
      {isPlaceUser && <Rank rank={ranks.placeRank} group="PLACE" />}
    </div>
  );
};

const Rank = ({ rank, group, isPlaceUser = true }) => {
  return (
    <div className="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-mb-32px tw-border-solid tw-border-[1px] tw-border-y-0 tw-border-l-0 tw-border-neutral-gray-10 last-of-type:tw-border-0">
      <div
        className={`${
          isPlaceUser ? "tw-text-gray-75" : "tw-text-brivity-blue-100"
        } tw-font-semibold tw-text-[40px] tw-leading-[54px] tw-mb-12px`}
      >
        {rank}
        <sup>{getOrdinalNumber(rank)}</sup>
      </div>
      <span className="tw-text-gray-50 tw-font-semibold tw-text-14px tw-mb-8px">in {group}</span>
    </div>
  );
};
