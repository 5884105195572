import curriedNamespaceAction from "../../../../reducers/helpers";

const TDP_NOTES = "TDP_NOTES";
const wrap = curriedNamespaceAction(TDP_NOTES);

export const saveAllNotes = (notes) => wrap({ type: "SAVE_ALL_NOTES", notes });

export const addNote = (note) => wrap({ type: "ADD_NOTE", note });

export const saveError = (error) => wrap({ type: "SAVE_ERROR", error });

export const saveErrors = (errors = [], status = "") => wrap({ type: "SAVE_ERRORS", errors, status });

export const updateNote = (note) => wrap({ type: "UPDATE_NOTE", note });

export const setEditNoteModal = (bool, note) => wrap({ type: "CLOSE_EDIT_NOTE_MODAL", bool, note });

export const deleteNote = (noteId) => wrap({ type: "DELETE_NOTE", noteId });
