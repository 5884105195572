import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

/** Date and time that an action runs at (a.k.a. is due at/sent at/etc.) */
const ActionRunAtFields = ({ actionState, actionCompletedAt, actionRunAt, hideDate }) => {
  const actionCompleted = actionState === "completed";
  const rawRunAtDateTime = actionCompleted ? actionCompletedAt : actionRunAt;
  const runAtDateTimeAsMoment = moment(rawRunAtDateTime).tz(Brivity.LocalTimeZone);
  const runAtDate = runAtDateTimeAsMoment.format("MM/DD/YYYY");
  const runAtTime = runAtDateTimeAsMoment.format("hh:mm A z");

  if (hideDate) {
    return runAtTime;
  }

  return (
    <>
      <div>{runAtDate}</div>
      <div>{runAtTime}</div>
    </>
  );
};

ActionRunAtFields.propTypes = {
  actionState: PropTypes.string.isRequired,
  actionCompletedAt: PropTypes.string,
  actionRunAt: PropTypes.string.isRequired,

  hideDate: PropTypes.bool,
};

ActionRunAtFields.defaultProps = {
  actionCompletedAt: null,

  hideDate: false,
};

export default ActionRunAtFields;
