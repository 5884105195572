import React from "react";
import { TD, TR } from "../../Reports/components/BusinessDashboard/Table/Table";
import Toggle from "@shared/v2/Toggle";
import { LockSolidV6, TrashCanSolidV6 } from "@shared/v2/Icomoon";
import TextLink from "@shared/v2/TextLink";
import IconButton from "@shared/v2/IconButton";

const Source = ({
  onToggle,
  onDelete,
  source,
  source: { id, hidden, name, isBrivityDefault, appliedTo, appliedToCount },
}) => (
  <TR>
    <TD>{name}</TD>
    <TD>
      <TextLink href={`/people/leads?leads_filter[selected_sources][]=${id}`} target="_blank">
        {appliedTo}
      </TextLink>
    </TD>
    <TD className="tw-text-center">
      {isBrivityDefault ? (
        <LockSolidV6 className="tw-text-gray-50" size="xl" />
      ) : (
        <Toggle
          checked={!hidden}
          onChange={() => onToggle(source)}
          labelClassName="tw-mb-0 !tw-inline-flex"
        />
      )}
    </TD>
    <TD>
      {onDelete && !isBrivityDefault && appliedToCount === 0 && (
        <IconButton onClick={onDelete} schema="tertiary">
          <TrashCanSolidV6 size="l" />
        </IconButton>
      )}
    </TD>
  </TR>
);

export default Source;
