import { useEffect, useMemo, useState } from "react";

const byteNumberFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  style: "unit",
  unit: "byte",
  unitDisplay: "narrow",
});

const useAttachments = ({ accept, maxFiles = 5, maxSizeBytes = 18 * 1024 * 1024, multiple }) => {
  const fileInput = useMemo(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.className = "tw-invisible tw-h-0";
    return input;
  }, []);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const totalSize = files.reduce((total, file) => total + file.size, 0);
    if (files.length > maxFiles) {
      setError(`only ${maxFiles} attachments are allowed`);
    } else if (totalSize > maxSizeBytes) {
      setError(`you can only send up to ${byteNumberFormatter.format(maxSizeBytes)} of attachments`);
    } else {
      setError(null);
    }
  }, [files]);

  useEffect(() => {
    const onChange = (e) => {
      const selectedFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    fileInput.accept = accept;
    fileInput.multiple = multiple;
    fileInput.addEventListener("change", onChange);
    document.body.appendChild(fileInput);

    return () => {
      fileInput.removeEventListener("change", onChange);
      document.body.removeChild(fileInput);
    };
  }, [accept, multiple]);

  return {
    actions: {
      addFiles: () => fileInput.click(),
      clear: () => {
        setError(null);
        setFiles([]);
      },
      removeFile: (file) => setFiles((prevFiles) => prevFiles.filter((f) => f !== file)),
    },
    error,
    files,
  };
};

export default useAttachments;
