/* eslint-disable arrow-body-style */
import React, { useRef, useState } from "react";
import { arrayOf, func, shape, string } from "prop-types";
import InlineEditingSelect from "@shared/v2/InlineEditingSelect";
import TextInput from "@shared/v2/TextInput";
import FieldLabel from "@shared/v2/FieldLabel";
import AiAssistant from "@shared/AiAssistant";

const TextField = ({ value, setValue, options }) => {
  const [placeHoldr, setPlaceHoldr] = useState({
    value: null,
    label: "Insert placeholder",
  });
  const textMessageInputRef = useRef(null);

  const insertPlaceholder = (val) => {
    const { current: textMessageInput } = textMessageInputRef;
    const { selectionStart, selectionEnd } = textMessageInput;

    const prefix = textMessageInput.value.substring(0, selectionStart);
    const suffix = textMessageInput.value.substring(selectionEnd, textMessageInput.value.length);
    const updatedValue = `${prefix}${val}${suffix}`

    setValue(updatedValue)
  };

  return (
    <div className="">
      <div className="tw-flex tw-justify-between">
        <FieldLabel
          label='Text'/>
        <InlineEditingSelect
          id="inline"
          name="inline"
          value={placeHoldr}
          onChange={(e) => {
            setPlaceHoldr(e);
            insertPlaceholder(e.value);
          }}
          options={options}/>    
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[12px]">
        <TextInput
          className='tw-h-[116px]'
          value={value}
          ref={textMessageInputRef}
          onChange={(e) => setValue(e.target.value)}
          textareaId="sp_text_message"
          multiline/>
        <div className="tw-ml-[auto]">
          <AiAssistant
            textareaId="sp_text_message"
            messageType="Generic"
            onInsertClick={(text) => setValue(text)}
          />      
        </div>
      </div>
    </div>
  )
}

export default TextField;

TextField.propTypes = {
  setValue: func.isRequired,
  value: string, 
  options: arrayOf(shape({})),
}

TextField.defaultProps = {
  value: '', 
  options: []
}