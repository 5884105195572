import React from 'react';
import { Button, FormGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { Dropdown } from '../../../shared/v1';
import Error from '../../../shared/Error';

const defaultAgent = {
  label: '',
  value: '',
  id: '',
  name: '',
  isNotValid: false,
  errMsg: ''
};

class AddAgentModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedAgents: [defaultAgent]
    }
  }

  onHideAgent = () => {
    this.props.setIsOpenAddAgentModal(false);
  };

  addAnother = () => {
    this.state.selectedAgents.push(defaultAgent);
    this.setState({ selectedAgents: this.state.selectedAgents });
  };

  saveAgentData = () => {
    let selectedAgents = this.state.selectedAgents.filter(agent => agent.id !== '');
    if (this.state.selectedAgents.length !== selectedAgents.length) {
      this.setState({
        isNotValid: true,
        errMsg: 'Please select an agent'
      });
    } else {
      const { agent_expense_types } = this.props.listingData;

      selectedAgents.forEach(agentObj => {
        const customTeamMemberExpenses = agentObj.custom_team_member_expenses_attributes.map(expense =>{
          const expenseTypeName = Object.keys(agent_expense_types).find(key => agent_expense_types[key] === expense.agent_expense_type_id);
          return ({...expense, name: expense.name !== null ? expense.name : expenseTypeName })
        });

        this.props.listingData.team_member_incomes.incomes.push({
          user_id: agentObj.id,
          name: agentObj.name,
          custom_team_member_expenses_attributes: customTeamMemberExpenses,
          agent_gci: 0,
          agent_gci_units: 0,
          agent_gci_percentage: false,
          gross_income: 0,
          brokerage_split_amount: 0,
          brokerage_split_line_total: 0,
          brokerage_split: agentObj.brokerage_split,
          brokerage_split_percentage: agentObj.brokerage_split_percentage,
          royalty_amount: 0,
          royalty_line_total: 0,
          royalty: agentObj.royalty,
          royalty_percentage: agentObj.royalty_percentage,
          net_income: 0,
        });
      });
      this.props.setFinancialData(this.props.listingData);
      this.props.setIsOpenAddAgentModal(false);
    }
  };

  addAgentClassName = () => {
    const foundObj = this.state.selectedAgents.find(obj => obj.name === '');
    if (foundObj) {
      return 'add_agent_link disabled';
    } else {
      return 'add_agent_link';
    }
  }

  removeAgent = (index) => {
    this.state.selectedAgents.splice(index, 1);
    this.setState({ selectedAgents: this.state.selectedAgents });
  }

  render() {
    const {
      isOpenAddAgentModal,
      agentList
    } = this.props;

    return (
      <Modal
        show={isOpenAddAgentModal}
        onHide={this.onHideAgent}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add Agent To Transaction Financials
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='auto-plans-form transaction-gci-content'>
          <div className='transaction-gci-options transaction-modal'>
            {this.state.isNotValid && <Error errorMessage={this.state.errMsg} />}
            <Row>
              <Col xs={12}>
                <p>This is unique to this transaction and will not be repeated</p>
              </Col>
            </Row>

            <Form horizontal>
              <FormGroup>
                {this.state.selectedAgents.map((agent, i) => {
                  return <Row>
                    <Col xs={(i > 0) ? 11 : 12}>
                      <div className="form-group add-agent-badge">
                        <Dropdown
                          isSearchable
                          isClearable={false}
                          name={`agentDrp${i}`}
                          onChange={(e) => {
                            if ((this.state.selectedAgents.findIndex(obj => obj.id === e.id)) > -1) {
                              this.setState({
                                errMsg: 'Cannot add team member more than once.',
                                isNotValid: true
                              });
                            } else {
                              this.state.selectedAgents[i] = e;
                              this.setState({ selectedAgents: this.state.selectedAgents });
                            }
                          }}
                          value={agent.value || null}
                          options={agentList}
                        />
                      </div>
                    </Col>

                    {i > 0 &&
                      <Col xs={1}>
                        <div className="modal-delete-expense">
                          <a href="javascript: void(0)"
                            onClick={() => { this.removeAgent(i) }}
                          ><i className="fa fa-times" /></a>
                        </div>
                      </Col>}
                  </Row>
                })}

                <Row>
                  <Col xs={12}>
                    <a className={this.addAgentClassName()} href="javascript: void(0)" onClick={this.addAnother}>
                      Add Another Agent
                    </a>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={12}>
                    <Button bsSize="small" className="modal-cancel" onClick={this.onHideAgent}>Cancel</Button>
                    <Button bsSize="small" className="modal-submit pull-right" onClick={this.saveAgentData}>Save</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddAgentModal;
