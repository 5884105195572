/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Calendar = (props) => (
  <Icon {...props} name="Calendar">
    <path d="M14.625 5H1.375A.376.376 0 0 1 1 4.625V3.5A1.5 1.5 0 0 1 2.5 2H4V.375C4 .169 4.169 0 4.375 0h1.25C5.831 0 6 .169 6 .375V2h4V.375c0-.206.169-.375.375-.375h1.25c.206 0 .375.169.375.375V2h1.5A1.5 1.5 0 0 1 15 3.5v1.125a.376.376 0 0 1-.375.375zM1.375 6h13.25c.206 0 .375.169.375.375V14.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V6.375C1 6.169 1.169 6 1.375 6zM5 12.375A.376.376 0 0 0 4.625 12h-1.25a.376.376 0 0 0-.375.375v1.25c0 .206.169.375.375.375h1.25A.376.376 0 0 0 5 13.625v-1.25zm0-4A.376.376 0 0 0 4.625 8h-1.25A.376.376 0 0 0 3 8.375v1.25c0 .206.169.375.375.375h1.25A.376.376 0 0 0 5 9.625v-1.25zm4 4A.376.376 0 0 0 8.625 12h-1.25a.376.376 0 0 0-.375.375v1.25c0 .206.169.375.375.375h1.25A.376.376 0 0 0 9 13.625v-1.25zm0-4A.376.376 0 0 0 8.625 8h-1.25A.376.376 0 0 0 7 8.375v1.25c0 .206.169.375.375.375h1.25A.376.376 0 0 0 9 9.625v-1.25zm4 4a.376.376 0 0 0-.375-.375h-1.25a.376.376 0 0 0-.375.375v1.25c0 .206.169.375.375.375h1.25a.376.376 0 0 0 .375-.375v-1.25zm0-4A.376.376 0 0 0 12.625 8h-1.25a.376.376 0 0 0-.375.375v1.25c0 .206.169.375.375.375h1.25A.376.376 0 0 0 13 9.625v-1.25z" />
  </Icon>
);

export default Calendar;
