import React from "react";

import { DesktopSolidV6 } from "@shared/v2/Icomoon";

import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";
import ActivitiesContainer from "./ActivitiesContainer";

const ReturnSiteActivity = ({ activity, onReadChange }) => (
  <ActivitiesContainer
    activity={activity}
    onReadChange={onReadChange}
    Icon={DesktopSolidV6}
    title={activity.title}
  >
    {activity.data.leadFirstName} has returned to the site after 30 days.
  </ActivitiesContainer>
);

export default ReturnSiteActivity;

ReturnSiteActivity.propTypes = {
  ...activityCommonPropTypes,
};

ReturnSiteActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
