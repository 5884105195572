import React from "react";
import { connect } from "react-redux";
import { arrayOf, shape, string } from "prop-types";

import Avatar from "../../../../shared/v2/Avatar";
import AvatarGroup from "../../../../shared/v2/AvatarGroup/AvatarGroup";

const mapStateToProps = (state) => ({
  byTeamMember: state.tdpTasksReducer.secondaryFilters.byTeamMember,
});

export const TaskHeaderAvatars = connect(mapStateToProps)(({ activeTeamMembers, byTeamMember }) => (
  <AvatarGroup size="medium">
    {activeTeamMembers.map((id) => (
      <Avatar key={id} size="medium" src={byTeamMember[id]?.avatar} alt={byTeamMember[id]?.avatar}>
        {byTeamMember[id]?.fullName}
      </Avatar>
    ))}
  </AvatarGroup>
));

export const TaskHeaderAutoPlansText = ({ activeAutoPlans }) => (
  <span>{activeAutoPlans.length > 1 ? `${activeAutoPlans.length} Autoplans` : activeAutoPlans[0]}</span>
);

TaskHeaderAvatars.propTypes = {
  activeTeamMembers: arrayOf(string),
  byTeamMember: shape({}),
};

TaskHeaderAvatars.defaultProps = {
  activeTeamMembers: arrayOf(string),
  byTeamMember: null,
};

TaskHeaderAutoPlansText.propTypes = {
  activeAutoPlans: arrayOf(string),
};

TaskHeaderAutoPlansText.defaultProps = {
  activeAutoPlans: arrayOf(string),
};
