import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import formatter from "../../../../shared/formatters";
import colors, { BRIVITY_COLORS, PLACE_COLORS } from "../helpers/colors";
import { renderOverviewTooltip } from "../helpers/renderCustomTooltip";

export const today = moment();

const LABELS = {
  Closings: ["MTD Closed", "To Be Closed", "Closed Last Year"],
  Pendings: ["MTD Pended", "", "Pended Last Year"],
  Listings: ["MTD Listed", "Coming Soon", "Listed Last Year"],
};

const getInspectedKey = (value = "") => value.toLowerCase();

const getLabels = (rangeType) => {
  const startDate =
    rangeType === "mtd"
      ? moment().startOf("month").subtract(12, "months")
      : moment().startOf("year").subtract(1, "month");
  return Array(12)
    .fill(null)
    .map(() => startDate.add(1, "month").format("MMM"));
};

const getFailedPendingsPercent = (failedPendings, unitsPended) =>
  formatter.number(
    failedPendings ? (failedPendings / ((failedPendings || 0) + (unitsPended || 0))) * 100 : 0,
  );

const getVariableColor = (tab) => {
  if (tab === "Closings") return BRIVITY_COLORS.color3.default;
  return colors.theme === "brivity" ? PLACE_COLORS.color1.default : colors.color3.default;
};

const transformMonthlyOverviewData = ({
  rawData: { chartData, summary = {} },
  checks,
  chartType,
  inspectedValue,
  rangeType,
  tab,
  year,
}) => {
  const labels = getLabels(rangeType);
  const inspectedKey = getInspectedKey(inspectedValue);

  return {
    summary: Object.keys(summary).reduce(
      (obj, key) => ({
        ...obj,
        [key]: formatter[/volume|gci/i.test(key) ? "currency" : "number"](summary[key], 0),
      }),
      {},
    ),
    chartData: {
      labels,
      datasets: [
        {
          label: LABELS[tab][0],
          hidden: !checks[0],
          data:
            chartData?.[inspectedKey].closed ||
            chartData?.[inspectedKey].pendedUnits ||
            chartData?.[inspectedKey].listingsTaken ||
            [],
          datalabels: {
            align: "right",
            color: colors.color1.default,
            formatter: (value, context) => {
              if (context.dataset.data.length - 1 === context.dataIndex) {
                if (inspectedValue === "Units") return value;
                return formatter.currency(value);
              }
              return "";
            },
          },
          backgroundColor: ({ dataIndex }) =>
            year === String(today.get("year")) && dataIndex > today.get("month") - 1
              ? colors.color1.default
              : colors.color2.default,
          dotColor: colors.color1.default,
          hoverBackgroundColor: colors.color1.hover,
          borderColor: colors.color1.default,
          borderWidth: chartType === "bar" ? 0 : 3,
          lineTension: 0,
          barPercentage: 0.25,
          fill: false,
        },
        {
          label: LABELS[tab][1],
          hidden: !(chartType === "bar" && tab !== "Pendings" && checks[1]),
          backgroundColor: getVariableColor(tab),
          hoverBackgroundColor: getVariableColor(tab),
          dotColor: getVariableColor(tab),
          borderWidth: chartType === "bar" ? 0 : 3,
          lineTension: 0,
          data: chartData?.[inspectedKey].toBeClosed || chartData?.[inspectedKey].comingSoon || [],
          barPercentage: 0.25,
          fill: false,
        },
        {
          label: LABELS[tab][2],
          hidden: !checks[2],
          datalabels: { formatter: () => "" },
          backgroundColor: "#ECEFEF",
          dotColor: "#ECEFEF",
          borderColor: "#C2C2C2",
          borderDash: [10, 10],
          borderWidth: chartType === "bar" ? 0 : 3,
          lineTension: 0,
          data:
            chartData?.[inspectedKey].closedPrevYear ||
            chartData?.[inspectedKey].pendedPrevYear ||
            chartData?.[inspectedKey].listingsTakenPrevYear ||
            [],
          barPercentage: 0.5,
          fill: false,
          xAxisID: "x2",
        },
      ],
    },
  };
};

const createClosingsRow = (
  label,
  unitsClosed,
  volumeClosed,
  gciClosed,
  avgSalePriceClosed,
  unitsToBeClosed,
  volumeToBeClosed,
  gciToBeClosed,
) => [
  {
    border: "tw-border-[2px]",
    className: `${
      label === "YTD" ? "tw-bg-tinted-gray-50" : "tw-bg-white"
    } tw-font-semibold tw-text-center tw-sticky tw-left-0`,
    value: label,
  },
  {
    className: "tw-text-center",
    value: unitsClosed || 0,
  },
  {
    value: formatter.currency(volumeClosed || 0),
  },
  {
    value: formatter.currency(gciClosed || 0),
  },
  {
    border: "tw-border",
    value: formatter.currency(avgSalePriceClosed || 0),
  },
  {
    className: "tw-text-center",
    value: unitsToBeClosed || 0,
  },
  {
    value: formatter.currency(volumeToBeClosed || 0),
  },
  {
    value: formatter.currency(gciToBeClosed || 0),
  },
];

const createPendingsRow = (label, unitsPended, volumePended, gciPended, failedPendings) => [
  {
    border: "tw-border-[2px]",
    className: `${
      label === "YTD" ? "tw-bg-tinted-gray-50" : "tw-bg-white"
    } tw-font-semibold tw-text-center tw-sticky tw-left-0`,
    value: label,
  },
  {
    className: "tw-text-center",
    value: unitsPended || 0,
  },
  {
    value: formatter.currency(volumePended || 0),
  },
  {
    value: formatter.currency(gciPended || 0),
  },
  {
    value: `${failedPendings || 0} (${getFailedPendingsPercent(failedPendings, unitsPended)}%)`,
  },
];

const createListingsRow = (
  label,
  listingsTaken,
  listingsVolume,
  listingsGci,
  comingSoonUnits,
  comingSoonVolume,
  comingSoonGci,
) => [
  {
    border: "tw-border-[2px]",
    className: `${
      label === "YTD" ? "tw-bg-tinted-gray-50" : "tw-bg-white"
    } tw-font-semibold tw-text-center tw-sticky tw-left-0`,
    value: label,
  },
  {
    className: "tw-text-center",
    value: listingsTaken || 0,
  },
  {
    value: formatter.currency(listingsVolume || 0),
  },
  {
    border: "tw-border",
    value: formatter.currency(listingsGci || 0),
  },
  {
    className: "tw-text-center",
    value: comingSoonUnits || 0,
  },
  {
    value: formatter.currency(comingSoonVolume || 0),
  },
  {
    value: formatter.currency(comingSoonGci || 0),
  },
];

const transformTableData = ({ rangeType, rawData: { chartData, year }, tab }) => {
  let totals;
  let rows = [];
  let totalsRow = [];
  switch (tab) {
    case "Pendings": {
      totals = Array(4).fill(0);
      rows = getLabels(rangeType).map((label, i) => {
        const unitsPended = chartData?.units?.pendedUnits?.[i];
        const volumePended = chartData?.volume?.pendedUnits?.[i];
        const gciPended = chartData?.gci?.pendedUnits?.[i];
        const failedPendings = chartData?.units?.failed?.[i];

        // Add to totals
        totals[0] += unitsPended;
        totals[1] += volumePended;
        totals[2] += gciPended;
        totals[3] += failedPendings;

        return createPendingsRow(label, unitsPended, volumePended, gciPended, failedPendings);
      });
      totalsRow = createPendingsRow("YTD", ...totals);
      break;
    }
    case "Listings": {
      totals = Array(6).fill(0);
      rows = getLabels(rangeType).map((label, i) => {
        const listingsTaken = chartData?.units?.listingsTaken?.[i];
        const listingsVolume = chartData?.volume?.listingsTaken?.[i];
        const listingsGci = chartData?.gci?.listingsTaken?.[i];
        const comingSoonUnits = chartData?.units?.comingSoon?.[i];
        const comingSoonVolume = chartData?.volume?.comingSoon?.[i];
        const comingSoonGci = chartData?.gci?.comingSoon?.[i];

        // Add to totals
        totals[0] += listingsTaken;
        totals[1] += listingsVolume;
        totals[2] += listingsGci;
        totals[3] += comingSoonUnits;
        totals[4] += comingSoonVolume;
        totals[5] += comingSoonGci;

        return createListingsRow(
          label,
          listingsTaken,
          listingsVolume,
          listingsGci,
          comingSoonUnits,
          comingSoonVolume,
          comingSoonGci,
        );
      });
      totalsRow = createListingsRow("YTD", ...totals);
      break;
    }
    default: {
      const now = moment();
      const calendarMonths = year > now.get("year") ? 1 : now.get("months") + 1;
      const divisor = rangeType === "mtd" || year < now.get("year") ? 12 : calendarMonths;
      totals = Array(7).fill(0);
      rows = getLabels(rangeType).map((label, i) => {
        const unitsClosed = chartData?.units?.closed?.[i];
        const volumeClosed = chartData?.volume?.closed?.[i];
        const gciClosed = chartData?.gci?.closed?.[i];
        const avgSalePriceClosed = chartData?.avgSalePrice?.closed?.[i];
        const unitsToBeClosed = chartData?.units?.toBeClosed?.[i];
        const volumeToBeClosed = chartData?.volume?.toBeClosed?.[i];
        const gciToBeClosed = chartData?.gci?.toBeClosed?.[i];

        // Add to totals
        totals[0] += unitsClosed;
        totals[1] += volumeClosed;
        totals[2] += gciClosed;
        totals[3] += avgSalePriceClosed;
        totals[4] += unitsToBeClosed;
        totals[5] += volumeToBeClosed;
        totals[6] += gciToBeClosed;

        return createClosingsRow(
          label,
          unitsClosed,
          volumeClosed,
          gciClosed,
          avgSalePriceClosed,
          unitsToBeClosed,
          volumeToBeClosed,
          gciToBeClosed,
        );
      });
      totals[3] /= divisor;
      totalsRow = createClosingsRow("YTD", ...totals);
    }
  }
  return { rows, totalsRow };
};

const getChartOptions = ({
  data: {
    chartData: { datasets },
  },
  rawData,
  year,
  tab,
  agentUuid,
}) => ({
  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: false,
      external: renderOverviewTooltip.bind(renderOverviewTooltip, { datasets, rawData, year, tab, agentUuid }),
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      offset: true,
    },
    x2: {
      display: false,
      type: "category",
      grid: {
        offset: true,
      },
      offset: true,
    },
    y: {
      stacked: true,
      border: {
        display: false,
      },
      min: 0,
      ticks: {
        beginAtZero: true,
        autoSkip: false,
        callback: (value) => (value > 1000 ? `${Math.ceil(value / 1000)}k` : value),
        padding: 10,
      },
    },
  },
});

const getCheckLabels = (tab, chartType) => {
  const variableColor = colors.theme === "brivity" ? "tw-bg-semantic-blue-100" : "tw-bg-[#121212]";
  return [
    {
      label: LABELS[tab][0],
      color: colors.theme === "brivity" ? "tw-bg-brivity-blue-100" : "tw-bg-semantic-blue-100",
    },
    {
      label: chartType === "bar" ? LABELS[tab][1] : "",
      color: tab === "Listings" ? variableColor : "tw-bg-semantic-yellow-100",
    },
    { label: LABELS[tab][2], color: "tw-bg-tinted-gray-100" },
  ];
};

const getSectionFromTab = (agentUuid, tab) => `${agentUuid ? "agent_" : ""}${tab.toLowerCase()}_overview`;

const useMonthlyOverview = ({ agentUuid, chartType, checks, inspectedValue, rangeType, tab, year }) => {
  const [rawData, setRawData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const section = getSectionFromTab(agentUuid, tab);
  const checkLabels = getCheckLabels(tab, chartType);
  const data = transformMonthlyOverviewData({
    rawData,
    checks,
    chartType,
    inspectedValue,
    rangeType,
    tab,
    year,
  });
  const tableData = transformTableData({ rangeType, rawData, tab });
  const chartOptions = getChartOptions({ data, rawData, year, tab, agentUuid });

  useEffect(() => {
    const abortController = new AbortController();

    setLoading(true);
    setError(null);

    axios
      .get(`/api/v4/reporting/${agentUuid ? "agent" : "business"}_dashboard`, {
        params: {
          section,
          selected_year: year,
          range_type: rangeType,
          person_uuid: agentUuid,
        },
        signal: abortController.signal,
      })
      .then(({ data: serverData }) => {
        setRawData(serverData);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err);
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [agentUuid, rangeType, section, year]);

  return { chartOptions, checkLabels, data, error, loading, tableData };
};

export default useMonthlyOverview;
