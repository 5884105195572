import React from "react";
import { string } from "prop-types";

const Loader = ({ containerClass }) => (
  <div
    className={`tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-start tw-pt-[100px] tw-bg-white tw-bg-opacity-50 ${containerClass}`}
  >
    <i className="tw-text-neutral-gray-50 tw-text-[50px] fa fa-spinner fa-spin" />
  </div>
);

export default Loader;

Loader.propTypes = {
  containerClass: string,
};

Loader.defaultProps = {
  containerClass: "",
};
