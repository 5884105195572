import React from "react";
import { string } from "prop-types";

import styles from "../styles";

import Avatar from "../../../../shared/v2/Avatar";

const PrimaryPerson = ({ name, avatarImg, descriptor, personType, containerClass }) => (
  <div className={`tw-mb-12px ${containerClass}`}>
    <div className={styles.TRANSACTION_DETAILS_LABEL}>{personType}</div>
    <div className="tw-flex tw-items-center">
      <Avatar className="tw-mr-10px" src={avatarImg} alt={name} />
      <div className="tw-overflow-hidden tw-whitespace-nowrap">
        <div className="tw-text-14px tw-overflow-hidden tw-overflow-ellipsis">{name}</div>
        <div className="tw-text-12px tw-text-neutral-gray-50">{descriptor}</div>
      </div>
    </div>
  </div>
);

export default PrimaryPerson;

PrimaryPerson.propTypes = {
  name: string,
  avatarImg: string,
  descriptor: string,
  personType: string,
  containerClass: string,
};

PrimaryPerson.defaultProps = {
  name: null,
  avatarImg: null,
  containerClass: null,
  descriptor: null,
  personType: "Listing Agent",
};
