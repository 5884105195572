/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowRightLongSolidV6 = (props) => (
  <Icon {...props} name="Arrow Right Long Solid V6">
    <path d="m15.706 8.706-4 4a1 1 0 1 1-1.414-1.414L12.587 9H1c-.553 0-1-.447-1-1.028S.447 7 1 7h11.587l-2.293-2.293a.999.999 0 1 1 1.414-1.414l4 4a1.001 1.001 0 0 1-.002 1.413z" />
  </Icon>
);

export default ArrowRightLongSolidV6;
