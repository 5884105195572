import React from "react";
import _ from "lodash";
import { autoPlanNameLabel } from "./helpers";
import { Row, Col } from "../../shared/v1";
import IconButton from "../../shared/v2/IconButton";
import { Edit, Trash } from "../../shared/v2/Icomoon";

const intents = {
  buyer: "b",
  seller: "s",
  recruit: "r",
  candidate: "c",
};
class Trigger extends React.PureComponent {
  render() {
    const { trigger, showTriggerModal, autoPlans, showDeleteModal, onUpdate, triggersPath, getTriggers } =
      this.props;
    const triggerPath = `${triggersPath}/${trigger.id}`;
    const updateCallback = () => {
      getTriggers(triggersPath);
    };

    return (
      <Row className="table-row hover:tw-bg-gray-5">
        <Col size={1}>
          <div className={intents[trigger.intent] && `intent-box ${intents[trigger.intent]}`}>
            {intents[trigger.intent] || "Any"}
          </div>
        </Col>
        <Col size={2} className={trigger.source_slug}>
          {trigger.source_name}
        </Col>
        <Col size={1}>{_.capitalize(trigger.status)}</Col>
        <Col size={1}>{trigger.tag}</Col>
        <Col size={4}>{_.capitalize(autoPlanNameLabel(trigger.auto_plan_id, autoPlans))}</Col>
        <Col size={1}>{_.capitalize(trigger.assigned_plans_count)}</Col>
        {trigger.state == "enabled" && (
          <Col size={1} onClick={() => onUpdate(triggerPath, { state: "disabled" }, updateCallback)}>
            <div className="toggle-on">
              <div className="toggle-on-circle"></div>
            </div>
          </Col>
        )}
        {trigger.state == "disabled" && (
          <Col size={1} onClick={() => onUpdate(triggerPath, { state: "enabled" }, updateCallback)}>
            <div className="toggle-off">
              <div className="toggle-off-circle"></div>
            </div>
          </Col>
        )}
        <Col size={1} className="tw-text-right icons">
          <Col size={6}>
            <Edit className="tw-cursor-pointer" onClick={() => showTriggerModal(trigger)} />
          </Col>
          <Col size={6}>
            <Trash className="tw-cursor-pointer" onClick={() => showDeleteModal(trigger)} />
          </Col>
        </Col>
      </Row>
    );
  }
}
export default Trigger;
