import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/v2/Button";
import { UploadSolidV6 } from "../../shared/v2/Icomoon";

const ActionImage = ({ url, onReplace, onDelete }) => (
  <div className="tw-relative tw-overflow-hidden tw-bg-neutral-gray-10 tw-w-[180px] tw-h-[180px] tw-rounded-lg tw-group">
    <img alt="logo" src={url} className="tw-h-full tw-w-full tw-object-contain" />
    <div className="tw-invisible group-hover:tw-visible tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-bg-black tw-bg-opacity-50 tw-flex tw-flex-col tw-justify-center tw-items-center">
      <Button schema="primary" className="tw-mb-[20px] tw-flex tw-items-center" onClick={onReplace}>
        <UploadSolidV6 size="m" className="tw-mr-1" />
        Upload New
      </Button>
      <Button schema="warning" onClick={onDelete}>
        Delete
      </Button>
    </div>
  </div>
);

ActionImage.propTypes = {
  url: PropTypes.string.isRequired,
  onReplace: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActionImage;
