import React from "react";

import { bool, func, string } from "prop-types";
import DetailDropdown from "../DetailDropdown";

const ToOption = (hold_earnest_money) => {
  if (hold_earnest_money === true) {
    return { label: "Yes", value: true };
  }
  if (hold_earnest_money === false) {
    return { label: "No", value: false };
  }
  return { label: "Select", value: null };
};

const options = [
  { label: "Select", value: null },
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const EarnestMoneyDropdown = ({ hold_earnest_money, onBlur, containerClass }) => (
  <DetailDropdown
    label="Will brokerage hold earnest money?"
    val={ToOption(hold_earnest_money)}
    options={options}
    onBlur={onBlur}
    dataCy="earnestmoney"
    containerClass={containerClass}
  />
);

EarnestMoneyDropdown.propTypes = {
  hold_earnest_money: bool,
  onBlur: func,
  containerClass: string,
};

EarnestMoneyDropdown.defaultProps = {
  hold_earnest_money: null,
  onBlur: () => {},
  containerClass: "",
};
export default EarnestMoneyDropdown;
