import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "chart.js/auto";
// eslint-disable-next-line import/no-unresolved
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Checkbox from "../../../shared/v2/Checkbox/Checkbox";
import BasicStat from "../BusinessDashboard/BasicStat";
import Dot from "../BusinessDashboard/Dot";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import useBusinessByLeadSource from "./services/useBusinessByLeadSource";
import setCheck from "./helpers/setCheck";
import colors from "./helpers/colors";
import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import CustomControl from "../BusinessDashboard/Dropdown/CustomControl";
import CustomOption from "../BusinessDashboard/Dropdown/CustomOption";
import CustomSingleValue from "../BusinessDashboard/Dropdown/CustomSingleValue";
import CustomValueContainer from "../BusinessDashboard/Dropdown/CustomValueContainer";
import Loader from "../../../Dashboard/components/Loader";
import Error from "./Error";
import CustomDropdownIndicator from "../BusinessDashboard/Dropdown/CustomDropdownIndicator";

const BusinessByLeadSource = ({ agentUuid, year }) => {
  const chartRef = useRef(null);
  const [checks, setChecks] = useState([true, true]);
  const [selectedSources, setSelectedSources] = useState([]);
  const {
    data: { chartOptions, chartData, sourceLabels, summary },
    error,
    loading,
  } = useBusinessByLeadSource({
    checks,
    agentUuid,
    selectedSources,
    year,
  });

  useEffect(() => {
    setSelectedSources(sourceLabels);
  }, [sourceLabels]);

  useEffect(() => {
    chartRef.current?.resize();
  }, [chartRef.current, chartData?.datasets?.[0]?.data.length]);

  return (
    <Section className="tw-relative tw-flex-1 tw-min-w-[320px] tw-gap-[24px]">
      <div className="tw-flex tw-items-end tw-justify-between">
        <SectionLabel>{agentUuid ? "Closings" : "Business"} by Source</SectionLabel>
        {!agentUuid && (
          <Dropdown
            components={{
              Control: CustomControl,
              Option: CustomOption,
              SingleValue: CustomSingleValue,
              ValueContainer: CustomValueContainer,
              DropdownIndicator: CustomDropdownIndicator,
            }}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            options={sourceLabels}
            value={selectedSources}
            onChange={(source) => {
              if (source.value === "all") {
                if (selectedSources.some((s) => s.value === "all")) setSelectedSources([]);
                else setSelectedSources(sourceLabels);
              } else if (selectedSources.some((s) => s.value === source.value)) {
                setSelectedSources((currentSources) =>
                  currentSources.filter((s) => s.value !== "all" && s.value !== source.value),
                );
              } else {
                setSelectedSources((currentSources) =>
                  currentSources.length === sourceLabels.length - 2
                    ? [sourceLabels[0]].concat([...currentSources, source])
                    : [...currentSources, source],
                );
              }
            }}
          />
        )}
      </div>
      {!agentUuid && (
        <div className="tw-flex tw-flex-wrap tw-gap-[8px] tw-justify-between">
          <BasicStat label="Sources" value={summary.sources} />
          <BasicStat label="YTD Closed Units" value={summary.ytdClosedUnits} />
          <BasicStat label="Total GCI from Sources" value={summary.netFromSources} />
        </div>
      )}
      <div className="tw-flex tw-justify-end tw-gap-[20px]">
        <Checkbox
          checked={checks[0]}
          onChange={setCheck(setChecks, 0)}
          id="closed-units"
          name="closedUnits"
          value="closedUnits"
          label={
            <div className="tw-flex tw-items-center tw-gap-[4px]">
              <Dot
                className={colors.theme === "brivity" ? "tw-bg-brivity-blue-100" : "tw-bg-semantic-blue-100"}
              />{" "}
              Closed Units
            </div>
          }
        />
        <Checkbox
          checked={checks[1]}
          onChange={setCheck(setChecks, 1)}
          id="pending-units"
          name="pendingUnits"
          value="pendingUnits"
          label={
            <div className="tw-flex tw-items-center tw-gap-[4px]">
              <Dot className="tw-bg-semantic-yellow-100" /> Pending Units
            </div>
          }
        />
      </div>
      <Bar ref={chartRef} data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </Section>
  );
};

BusinessByLeadSource.propTypes = {
  agentUuid: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

BusinessByLeadSource.defaultProps = {
  agentUuid: "",
};

export default BusinessByLeadSource;
