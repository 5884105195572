import React from "react";
import Proptypes from "prop-types";
import ModifyDate from "../Date/ModifyDate";

const CustomDates = ({ option }) => (
  <div className="tw-group tw-min-h-[36px] tw-flex tw-cursor-default tw-justify-between tw-items-center tw-px-[16px] hover:tw-bg-tinted-gray-50 active:tw-bg-gray-lightest">
    <span>{option.name}</span>
    <div className="tw-invisible group-hover:tw-visible">
      <ModifyDate date={option} isNew />
    </div>
  </div>
);

CustomDates.propTypes = {
  option: Proptypes.shape({ name: Proptypes.string, id: Proptypes.string }).isRequired,
};

export default CustomDates;
