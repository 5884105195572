import React from "react";
import { connect } from "react-redux";
import { sortableContainer, sortableElement, arrayMove } from "react-sortable-hoc";
import {
  openNewExpenseModal0,
  setAgentExpense0,
  setActiveAgentExpense0,
  setAccountData0,
} from "../../../actions/account";
import { Panel, Button } from "../../../../shared/v1";
import { SplitForm } from "../SplitForm";
import { ExpenseForm } from "../ExpenseForm";
import { expensePointers } from "./helpers";
import { onAddExpense, onSortEnd } from "./handlers";
import { kebabCase } from "lodash";
import Avatar from "../../../../shared/v2/Avatar";

const SortableItem = sortableElement(({ value, sortIndex, accountData, setAccountData, agentPosition }) => {
  return value.hasOwnProperty("royalty") ? (
    <SplitForm agent={value} agentPosition={agentPosition} />
  ) : (
    <ExpenseForm
      value={value}
      sortIndex={sortIndex}
      accountData={accountData}
      agentPosition={agentPosition}
    />
  );
});

const SortableContainer = sortableContainer(({ items, accountData, setAccountData, agentPosition }) => {
  return (
    <div>
      {items.map(
        (value, index) =>
          !value._destroy && (
            <SortableItem
              key={`${index}${value.id}${value.position}`}
              index={index}
              value={value}
              sortIndex={index}
              accountData={accountData}
              agentPosition={agentPosition}
            />
          ),
      )}
    </div>
  );
});

const mapDispatchToProps = (dispatch) => ({
  openNewExpenseModal: () => dispatch(openNewExpenseModal0()),
  setAgentExpense: () => dispatch(setAgentExpense0()),
  setActiveAgentExpense: (agent) => dispatch(setActiveAgentExpense0(agent)),
  setAccountData: (accountData) => dispatch(setAccountData0(accountData)),
});

const mapStateToProps = (state) => ({
  accountData: state.accountFinancial.accountData,
});

export const AgentForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const { openNewExpenseModal, setAgentExpense, setActiveAgentExpense, setAccountData, agentPosition } =
    props;

  const agent = { ...props.agent };
  const avatar = /^http/.test(agent.avatar) ? agent.avatar : null;
  const accountData = { ...props.accountData };
  let expensesPositionPointer = expensePointers(agent);

  return (
    <div className="accordion" id={`accordion${agent.id}`}>
      <Panel className="tw-border-gray" deprecatedOverrideMarginClasses="tw-m-0">
        <Panel.Heading className="tw-bg-[#fff]" deprecatedOverrideBorderClasses="tw-border-0">
          <Panel.Title
            className="title tw-text-brand-primary"
            deprecatedOverrideTextClasses="tw-text-[15px] tw-leading-[1.1]"
          >
            <Panel.Trigger
              data-cy="financials-setting-accordion"
              className="tw-no-underline hover:tw-no-underline active:tw-no-underline focus:tw-no-underline tw-relative tw-flex tw-items-center tw-gap-[8px]"
              data-parent={`#accordion${agent.id}`}
              id={`agent${agent.id}`}
            >
              <Avatar src={avatar} alt={agent.name} size="medium" />
              {agent.name}
            </Panel.Trigger>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse
          id={`agent${agent.id}`}
          data-cy={`financial-settings-agent-form-${kebabCase(agent.name)}`}
        >
          <Panel.Body
            className="tw-bg-[#fbfafa] tw-border-0 tw-border-t tw-border-solid tw-border-[#ddd]"
            deprecatedOverridePaddingClasses="tw-p-0"
          >
            <div className="transaction-gci-options">
              <div className="transaction-gci-header dnd">
                <h3>Agent Expenses</h3>
                <Button
                  size="small"
                  variant="info"
                  invert
                  data-cy={`financials-setting-add-expense`}
                  className="modal-submit tw-float-right focus:tw-bg-white active:tw-bg-white focus:tw-text-gray-darker active:tw-text-gray-darker hover:tw-text-gray-darker"
                  onClick={() => {
                    onAddExpense(agent, { openNewExpenseModal, setAgentExpense, setActiveAgentExpense });
                  }}
                >
                  Add Expense
                </Button>
              </div>
              <SortableContainer
                items={expensesPositionPointer}
                onSortEnd={(indexes) =>
                  onSortEnd(
                    indexes,
                    agentPosition,
                    expensesPositionPointer,
                    accountData,
                    setAccountData,
                    agent,
                  )
                }
                accountData={accountData}
                agentPosition={agentPosition}
                useDragHandle
              />
            </div>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
});
