import React from "react";
import PropTypes from "prop-types";

const ModalFooter = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props}>{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: null,
};

export default ModalFooter;
