import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reorderNotesBasedOnStarred } from "../../../utils/helpers";
import NoteCard from "../../../../PersonDetail/components/notes/NoteCard";
import { DeleteNoteModal, EditNoteModal } from "../helpers/forms";
import { SquarePenSolidV6 } from "../../../../shared/v2/Icomoon";
import * as actions from "../actions/notesActionCreators";

const mapStateToProps = (state) => ({
  currentUser: state.notesData.current_user,
  notes: state.notesData.notes,
  notableType: state.notesData.notableType,
  notableId: state.notesData.notableId,
});

const mapDispatchToProps = (dispatch) => ({
  createNote: (note) => dispatch(actions.createNote(note)),
  updateNote: (note) => dispatch(actions.updateNote(note)),
  deleteNote: (note) => dispatch(actions.deleteNote(note)),
  toggleStarred: (note) => dispatch(actions.toggleStarred(note)),
  toggleVisibility: (note) => dispatch(actions.toggleExternallyVisible(note)),
});

const NotesIndex = ({
  createNote,
  currentUser,
  deleteNote,
  notableId,
  notableType,
  notes,
  toggleStarred,
  toggleVisibility,
  updateNote,
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  return (
    <div className="tw-flex tw-gap-[16px] tw-flex-wrap tw-p-[16px]">
      {currentUser.account_id != null && (
        <button
          type="button"
          className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-[12px] tw-border-none tw-shadow-card tw-bg-white tw-text-teal tw-p-[20px] tw-rounded-[8px] tw-w-[265px] !tw-h-[227px]"
          id="create-new-note"
          onClick={() => setShowCreateForm(true)}
        >
          <SquarePenSolidV6 size="custom" className="tw-w-[48px] tw-h-[48px] tw-text-teal" />
          <span className="tw-text-18d tw-font-semibold">Create New Note</span>
        </button>
      )}

      {showCreateForm && (
        <EditNoteModal
          isEditing={false}
          notableId={notableId}
          notableType={notableType}
          onClose={() => setShowCreateForm(false)}
          updateNote={createNote}
        />
      )}

      {showEditForm && (
        <EditNoteModal
          isEditing
          note={showEditForm}
          notableId={notableId}
          onClose={() => setShowEditForm(false)}
          onDelete={() => setShowDeleteForm(showEditForm)}
          updateNote={updateNote}
        />
      )}

      {showDeleteForm && (
        <DeleteNoteModal
          onClose={() => setShowDeleteForm(false)}
          onDelete={() => {
            deleteNote(showDeleteForm);
            setShowDeleteForm(false);
            setShowEditForm(false);
          }}
        />
      )}

      {reorderNotesBasedOnStarred(notes).map((note) => (
        <NoteCard
          key={note.id}
          note={note}
          onDelete={() => setShowDeleteForm(note)}
          onEdit={() => setShowEditForm(note)}
          onStar={() => toggleStarred(note)}
          onVisible={() => toggleVisibility(note)}
        />
      ))}
    </div>
  );
};

NotesIndex.propTypes = {
  createNote: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({ account_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) })
    .isRequired,
  deleteNote: PropTypes.func.isRequired,
  notableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  notableType: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape()),
  toggleStarred: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
};

NotesIndex.defaultProps = {
  notes: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesIndex);
