import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@shared/v2/IconButton";
import { Download, Trash } from "@shared/v2/Icomoon/Icons";
import ViewAllDocumentsModal from "./modals/ViewAllDocumentsModal";
import icons from "./icons.module.css";
import cards from "./cards.module.css";
import colors from "./colors.module.css";
import css from "./person-detail-styles.module.css";
import {
  fetchDocuments,
  handleDocumentDelete,
  handleDocumentUpload,
  getAppointments,
} from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import Loading from "../../Goals/components/Loader";
import DeleteAgreementModal from "./Agreements/DeleteAgreementModal";
import AddAgreementModal from "./Agreements/AddAgreementModal";
import SelectDocTypeModal from "./modals/SelectDocTypeModal";

const FadeOut = () => (
  <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-w-[48px] tw-bg-gradient-to-r tw-from-transparent tw-to-white" />
);

const DocumentCard = ({
  document: { id, date_added, file_name, image_icon, uploaded_by_name, uploaded_by_url, expiring_url },
  onDelete,
  onClickDownload,
  person,
}) => (
  <div
    key={id}
    className="tw-relative tw-shadow-tdp-card tw-overflow-hidden tw-flex tw-flex-col tw-gap-[12px] tw-rounded-[7px] tw-p-[12px]"
  >
    <div className="tw-flex tw-items-center tw-gap-[12px] tw-overflow-hidden">
      <div className="tw-flex tw-items-center tw-gap-[12px] tw-flex-1 tw-min-w-0">
        <i className={`fa ${image_icon} ${icons.faLg} ${colors.brivityBlue}`} />
        <div className="tw-flex-1 tw-relative tw-overflow-hidden">
          <a
            className="tw-text-14d tw-text-neutral-gray-75 tw-font-semibold tw-whitespace-nowrap"
            href={expiring_url}
            target="_blank"
            title={file_name}
          >
            {file_name}
            <FadeOut />
          </a>
        </div>
      </div>
      <div className={`${icons.downloadAndDelete} tw-flex tw-gap-[4px]`}>
        <IconButton size="small" schema="tertiary" onClick={onClickDownload}>
          <Download className="!tw-w-[9px] !tw-h-[9px]" />
        </IconButton>
        <IconButton size="small" schema="tertiary" onClick={() => onDelete(person.data.attributes, id)}>
          <Trash className="!tw-w-[9px] !tw-h-[9px]" />
        </IconButton>
      </div>
    </div>
    <div className="tw-flex tw-whitespace-nowrap">
      <div className="tw-flex-1 tw-relative tw-overflow-hidden tw-min-w-0">
        <span>
          Uploaded by{" "}
          <a href={uploaded_by_url} title={uploaded_by_name}>
            {uploaded_by_name}
          </a>
        </span>
        <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-w-[48px] tw-bg-gradient-to-r tw-from-transparent tw-to-white" />
        <FadeOut />
      </div>
      <span>{date_added}</span>
    </div>
  </div>
);

const getOption = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const Documents = ({
  docType,
  openAddAgreementModal,
  setDoc,
  agreementCloseModal,
  hiddenUploadInput,
  openSelectDocModal,
  setOpenSelectDocModal,
}) => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const documents = useSelector((state) => state.personDetail.documents);
  const uploadingDocument = useSelector((state) => state.personDetail.uploadingDocument);
  const availableTransactions = useSelector((state) => getOption(state.personDetail.available_transactions));
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [saveDocId, setSaveDocId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (person.data?.id) dispatch(fetchDocuments(person.data));
  }, [person.data?.id]);

  const onClickDownload = (expiringUrl) => {
    if (expiringUrl) {
      window.open(expiringUrl, "_blank");
    }
  };

  const onClickDeleteDoc = (docId) => {
    setOpenDeleteModal(true);
    setSaveDocId(docId);
  };

  const deleteCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteDoc = () => {
    dispatch(handleDocumentDelete(person.data.attributes, saveDocId));
    deleteCloseModal();
  };

  return (
    <CollapsibleElement
      id="pdp-documents"
      title="Documents"
      buttonText="UPLOAD"
      buttonClickFunction={() => setOpenSelectDocModal(true)}
    >
      {!person.data && <Loading />}
      <input
        id="documentUploadInput"
        type="file"
        ref={hiddenUploadInput}
        onChange={(event) =>
          dispatch(handleDocumentUpload(event.target.files[0], person.data.attributes, false))
        }
        style={{ display: "none" }}
      />
      <div id="person-documents-card" className="tw-flex tw-flex-col tw-gap-[12px]">
        <div id="progress" className="tw-relative tw-h-[12px] tw-my-[-12px]">
          <div className={`progress-bar tw-bg-teal ${uploadingDocument ? "tw-w-full" : "tw-w-0"}`} />
        </div>

        {documents.length === 0 && (
          <div className={css.personDetailEmptyStateText}>
            Upload documents associated with this contact here (images, PDFs, .doc, etc.).
          </div>
        )}

        {documents.slice(0, 2).map((document) => (
          <DocumentCard
            key={document.id}
            document={document}
            onClickDownload={() => onClickDownload(document.expiring_url)}
            onDelete={() => onClickDeleteDoc(document.id)}
            person={person}
          />
        ))}

        {documents.length > 2 && (
          <a onClick={() => setShowDocumentsModal(true)} className={`${cards.viewAll} ${colors.brivityBlue}`}>
            VIEW ALL
          </a>
        )}

        {showDocumentsModal && (
          <ViewAllDocumentsModal
            documents={documents}
            onClose={() => setShowDocumentsModal(false)}
            handleDocumentDelete={(...args) => dispatch(handleDocumentDelete(...args))}
            person={person}
          />
        )}
      </div>
      {openDeleteModal && (
        <DeleteAgreementModal
          modalOpen={openDeleteModal}
          onDelete={deleteDoc}
          closeModal={deleteCloseModal}
          title="Delete Document?"
          msg="Deleting this document will remove it from both the agreement and the Documents section."
        />
      )}
      {openSelectDocModal && (
        <SelectDocTypeModal
          modalOpen={openSelectDocModal}
          onSelectDocType={setDoc}
          closeModal={() => setOpenSelectDocModal(false)}
        />
      )}
      {openAddAgreementModal && (
        <AddAgreementModal
          person={person}
          agreementName={docType}
          modalOpen={openAddAgreementModal}
          closeModal={agreementCloseModal}
          uploadingDocument={uploadingDocument}
          availableTransactions={availableTransactions}
          apptAddHandler={() => dispatch(getAppointments(person.data.attributes.uuid))}
        />
      )}
    </CollapsibleElement>
  );
};

export default Documents;
