import React, {Component} from 'react';
import Pluralize from 'react-pluralize'
import { Button, Dropdown, FormGroup, Modal } from '../../shared/v1';

const default_agents = [{label: 'Anyone', value: 'anyone'}]

export default class ReassignModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.props
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, ...nextProps})
  }

  render() {
    return(
      <Modal
        size={"sm"}
        show={this.state.showReassignModal}
        onHide={this.state.closeReassignModalHandler}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to reassign <Pluralize singular={'task'} count={this.state.selectedTasks.length}/>?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <label>
              Assign these Tasks to
            </label>
            <Dropdown
              isSearchable={true}
              name='assigned_to_user_id'
              onUpdate={this.state.dropdownUpdater}
              isClearable={false}
              value={this.state.assignedToUserId}
              options={default_agents.concat(this.state.agentsOptions)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="small"
            className="modal-cancel tw-float-left"
            onClick={this.state.closeReassignModalHandler}>
            Cancel
          </Button>
          <Button
            size="small"
            className="modal-submit submit"
            onClick={this.state.reassignTasksHandler}>
            Reassign
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
