import { useEffect, useState } from "react";
import { isCancel } from "axios";
import formatter from "../../../../shared/formatters";
import { caseTransformingAxios as axios } from "../../../../shared/v2/caseTransformingAxios";
import colors from "../../DashboardShared/helpers/colors";

const keyToFormat = (key) => {
  if (/ratio/i.test(key)) return "number";
  return "currency";
};

const transformProfile = ({ agent, summary }) => ({
  agent: {
    ...agent,
    fullName: `${agent.firstName} ${agent.lastName}`,
  },
  summary: Object.keys(summary).reduce(
    (o, key) => ({ ...o, [key]: formatter[keyToFormat(key)](summary[key]) }),
    {},
  ),
  progressBar: {
    values: [
      {
        value: Math.min(100, Math.round((summary.ytdNetIncomeGoal / (summary.netIncomeGoal || 1)) * 100)),
        color: colors.color2.default,
      },
      {
        value: Math.min(100, Math.round((summary.netIncome / (summary.netIncomeGoal || 1)) * 100)),
        color: colors.color1.default,
      },
    ],
    label: `${formatter.number(Math.round((summary.netIncome / (summary.netIncomeGoal || 1)) * 100))}%`,
  },
});

const useAgentSummary = ({ agentUuid, year }) => {
  const [data, setData] = useState({ agent: {}, summary: {}, progressBar: { values: [] } });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    axios
      .get("/api/v4/reporting/agent_dashboard", {
        signal: abortController.signal,
        params: {
          selected_year: year,
          section: "agent_dashboard_summary",
          person_uuid: agentUuid,
        },
      })
      .then((res) => {
        setData(transformProfile(res.data));
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [agentUuid, year]);

  return { data, error, loading };
};

export default useAgentSummary;
