import axios from "axios";
import { listingsTransactionRemoveSubKey } from "./apiAdapter";
import { tryCatchHandlr } from "../../shared/Utilities";

export const setTransactionExpenseList = (transactionExpenseList) => {
  return {
    type: "SET_TRANSACTION_EXPENSE_LIST",
    transactionExpenseList,
  };
};

export const setIsShowTr = (data) => {
  return {
    type: "SET_IS_SHOW_TR_DETAIL",
    data,
  };
};

export const setIsEditTr = (data) => {
  return {
    type: "SET_IS_EDIT_TR_DETAIL",
    data,
  };
};

export const setIsShowTrDetail = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_SHOW_TR_DETAIL",
      data,
    });
  };
};

export const setIsEditTrDetail = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_EDIT_TR_DETAIL",
      data,
    });
  };
};

export const setIsOpenAddAgentModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_OPEN_ADD_AGENT_MODAL",
      data,
    });
  };
};

export const setIsOpenNewIncomeModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_OPEN_NEW_INCOME_MODAL",
      data,
    });
  };
};

export const setIsExpenseModal = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_EXPENSE_MODAL",
      data,
    });
  };
};

export const setAlert = (data) => {
  return {
    type: "SET_ALERT",
    data,
  };
};

export const setDangerAlert = (data) => {
  return {
    type: "SET_DANGER_ALERT",
    data,
  };
};

export const setFinancialData = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_FINANCIAL_DATA",
      data,
    });
  };
};

export const setFinancial = (data) => {
  return {
    type: "SET_FINANCIAL_DATA",
    data,
  };
};

export const setClosedVolume = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CLOSED_VOLUME",
      data,
    });
  };
};

export const setModalIndex = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MODAL_INDEX",
      data,
    });
  };
};

export const setErrData = (err) => {
  return {
    type: "ERR_DATA",
    errors: err.response.data.errors,
  };
};

export const setAgentList = (data) => {
  return {
    type: "SET_AGENT_LIST",
    data,
  };
};

export const setIsCollaboratorExpenseModalOpen = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_IS_COLLABORATOR_EXPENSE_MODAL_OPEN",
      data,
    });
  };
};

export const setCollaboratorModalIndex = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_COLLABORATOR_MODAL_INDEX",
      data,
    });
  };
};

export const saveClosedVolume = (listingData, closedVolume, setStartButtonDisabled) => {
  return async (dispatch) => {
    try {
      await axios
        .post("/listings/" + listingData.listing_id + "/financials", {
          closed_volume: closedVolume,
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          dispatch(setAlert(true));
          dispatch(setFinancial(res.data));
          dispatch(setIsShowTr(true));
          setTimeout(function () {
            dispatch(setAlert(false));
          }, 2500);
        });
    } catch (err) {
      setStartButtonDisabled(false);
      dispatch(setErrData(err));
      dispatch(setDangerAlert(true));
    }
  };
};

export const saveFinancialData = (listingData) => {
  return async (dispatch) => {
    listingsTransactionRemoveSubKey(listingData);
    const req = axios.patch("/listings/" + listingData.listing_id + "/financials", {
      ...listingData,
      authenticity_token: ReactOnRails.authenticityToken(),
    });
    const [res, err] = await tryCatchHandlr(req);

    if (err) {
      dispatch(setErrData(err));
      dispatch(setDangerAlert(true));
    } else {
      dispatch(setAlert(true));
      dispatch(setIsShowTr(true));
      dispatch(setIsEditTr(false));
      const { data } = res;
      dispatch(setFinancial(data));
      setTimeout(function () {
        dispatch(setAlert(false));
      }, 2500);
    }
    window.scrollTo(0, 0);
  };
};

export const getAgentList = (listingId) => {
  return async (dispatch) => {
    try {
      await axios
        .get("/listings/" + listingId + "/financials/selectable_agents", {
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((res) => {
          res.data.map((obj) => {
            obj["value"] = obj.id;
            obj["label"] = obj.name;
          });
          dispatch(setAgentList(res.data));
        });
    } catch (err) {
      dispatch(setErrData(err));
      dispatch(setDangerAlert(true));
    }
  };
};

export const removeIncome = (incomeId) => {
  return {
    type: "REMOVE_INCOME",
    data: incomeId,
  };
};

export const removeCollaboratorExpense = (expenseId) => {
  return {
    type: "REMOVE_COLLABORATOR_EXPENSE",
    data: expenseId,
  };
};

export const calculate_amount = (previous_line_total, amount, percentage) => {
  if (percentage) {
    let clcAmt = (Number(amount) / 100) * Number(previous_line_total);
    return twoDecimalPlaces(clcAmt);
  } else {
    return twoDecimalPlaces(amount);
  }
};

export const recalculate_transaction_gci_and_team_member_net_incomes = (listingData) => {
  return async (dispatch) => {
    calculate_transaction_gci(listingData);
    let owner = listingData.team_member_incomes.incomes.find((agent) => {
      return agent.owner === true;
    });

    let non_owners_with_flat_gci = listingData.team_member_incomes.incomes.filter((agent) => {
      return agent.owner === false && agent.agent_gci_percentage === false;
    });

    let non_owners_percent_gci = listingData.team_member_incomes.incomes.filter((agent) => {
      return agent.owner === false && agent.agent_gci_percentage === true;
    });

    calculate_team_member_net_income(owner, listingData);
    non_owners_with_flat_gci.forEach((agent) => {
      calculate_team_member_net_income(agent, listingData);
    });
    non_owners_percent_gci.forEach((agent) => {
      calculate_team_member_net_income(agent, listingData);
    });

    dispatch(setFinancial(listingData));
  };
};

export const recalculate_all_team_member_net_incomes = (income, listingData) => {
  return async (dispatch) => {
    if (!income.agent_gci_percentage) {
      //updating flat gci
      calculate_team_member_net_income(income, listingData);
      listingData.team_member_incomes.incomes.forEach((agent) => {
        if (agent.agent_gci_percentage && !agent.owner) {
          calculate_team_member_net_income(agent, listingData);
        }
      });
    } else {
      let flatAgents = listingData.team_member_incomes.incomes.filter(
        (agent) => agent.agent_gci_percentage === false,
      );
      if (income.owner && flatAgents.length > 0) {
        calculate_team_member_net_income(income, listingData);
        listingData.team_member_incomes.incomes.forEach((agent) => {
          if (agent.agent_gci_percentage && !agent.owner) {
            calculate_team_member_net_income(agent, listingData);
          }
        });
      } else {
        calculate_team_member_net_income(income, listingData);
      }
    }

    dispatch(setFinancial(listingData));
  };
};

export const recalculate_team_member_net_income = (income, listingData) => {
  return async (dispatch) => {
    calculate_team_member_net_income(income, listingData);
    dispatch(setFinancial(listingData));
  };
};

export const calculate_transaction_gci = (listingData) => {
  let closedVolume = listingData.transaction_income.closed_volume;
  let commission = listingData.transaction_income.commission;
  let commissionPercentage = listingData.transaction_income.commission_percentage;
  let grossIncome = calculate_amount(closedVolume, commission, commissionPercentage);
  let additionals = listingData.transaction_income.additionalIncome;
  listingData.transaction_income.gross_income = grossIncome;
  let nonDeletedAdditionals = additionals.filter((additional) => additional._destroy !== "1");
  nonDeletedAdditionals.forEach((element, index) => {
    if (element.position === 1) {
      let additional_amount = calculate_amount(grossIncome, element.value, element.percentage);
      if (element.type === "revenue") {
        element.revenue_amount = additional_amount;
      } else if (element.type === "expense") {
        element.expense_amount = additional_amount;
        additional_amount *= -1;
      }
      element.line_total = twoDecimalPlaces(Number(grossIncome) + Number(additional_amount));
    } else {
      let previous_line_total = nonDeletedAdditionals[index - 1].line_total;
      let additional_amount = calculate_amount(previous_line_total, element.value, element.percentage);
      if (element.type === "revenue") {
        element.revenue_amount = additional_amount;
      } else if (element.type === "expense") {
        element.expense_amount = additional_amount;
        additional_amount *= -1;
      }
      element.line_total = twoDecimalPlaces(Number(previous_line_total) + Number(additional_amount));
    }
  });

  listingData.transaction_income.custom_transaction_expenses_attributes = [];
  listingData.transaction_income.custom_transaction_revenues_attributes = [];
  additionals.forEach((obj) => {
    if (obj.type === "expense") {
      listingData.transaction_income.custom_transaction_expenses_attributes.push(obj);
    }
    if (obj.type === "revenue") {
      listingData.transaction_income.custom_transaction_revenues_attributes.push(obj);
    }
  });
  if (nonDeletedAdditionals.length > 0) {
    listingData.transaction_income.gci = twoDecimalPlaces(
      nonDeletedAdditionals[nonDeletedAdditionals.length - 1].line_total,
    );
  } else {
    listingData.transaction_income.gci = grossIncome;
  }
};

export const gross_income = (agent, listingData) => {
  if (!agent.agent_gci_percentage) {
    return agent.agent_gci;
  } else {
    if (agent.owner) {
      return twoDecimalPlaces((Number(agent.agent_gci) / 100) * Number(listingData.transaction_income.gci));
    } else {
      let non_owners_with_flat_gci = listingData.team_member_incomes.incomes.filter(
        (obj) => obj.agent_gci_percentage === false,
      );
      let ownerGrossIncome = 0;
      listingData.team_member_incomes.incomes.map((obj) => {
        if (obj.owner) {
          ownerGrossIncome += Number(obj.gross_income);
        }
      });
      let sumOfAgentsGrossIncomeWithFlatGci = 0;
      listingData.team_member_incomes.incomes.map((obj) => {
        if (!obj.agent_gci_percentage) {
          sumOfAgentsGrossIncomeWithFlatGci += Number(obj.gross_income);
        }
      });
      if (non_owners_with_flat_gci.length > 0 && sumOfAgentsGrossIncomeWithFlatGci > 0) {
        let owner_and_flat_incomes = ownerGrossIncome + sumOfAgentsGrossIncomeWithFlatGci;
        let transaction_gci_less_owner_and_flat_incomes =
          Number(listingData.transaction_income.gci) - Number(owner_and_flat_incomes);
        return twoDecimalPlaces(
          (Number(agent.agent_gci) / 100) * transaction_gci_less_owner_and_flat_incomes,
        );
      } else {
        return twoDecimalPlaces((Number(agent.agent_gci) / 100) * Number(listingData.transaction_income.gci));
      }
    }
  }
};

export const calculate_team_member_net_income = (agent, listingData) => {
  let agentPosition = listingData.team_member_incomes.incomes.findIndex((obj) => obj.id === agent.id);
  let gross_inc = gross_income(agent, listingData);
  let custom_expenses = agent.custom_team_member_expenses_attributes;
  agent.gross_income = gross_inc;
  let brokerage_deduction = calculate_amount(
    gross_inc,
    agent.brokerage_split,
    agent.brokerage_split_percentage,
  );
  agent.brokerage_split_amount = brokerage_deduction;
  agent.brokerage_split_line_total = twoDecimalPlaces(gross_inc - brokerage_deduction);

  let royalty_deduction = calculate_amount(gross_inc, agent.royalty, agent.royalty_percentage);
  agent.royalty_amount = royalty_deduction;
  agent.royalty_line_total = twoDecimalPlaces(agent.brokerage_split_line_total - royalty_deduction);
  let nonDeletedCustomExpenses = custom_expenses.filter((expense) => expense._destroy !== "1");
  nonDeletedCustomExpenses.forEach((element, index) => {
    if (element.position === 1) {
      let custom_deduction = calculate_amount(agent.royalty_line_total, element.value, element.percentage);
      element.expense_amount = custom_deduction;
      element.line_total = twoDecimalPlaces(agent.royalty_line_total - custom_deduction);
    } else {
      let previous_line_total = nonDeletedCustomExpenses[index - 1].line_total;
      let custom_deduction = calculate_amount(
        element.percentage ? agent.royalty_line_total : previous_line_total,
        element.value,
        element.percentage,
      );
      element.expense_amount = custom_deduction;
      element.line_total = twoDecimalPlaces(previous_line_total - custom_deduction);
    }
  });

  if (nonDeletedCustomExpenses.length > 0) {
    agent.net_income = twoDecimalPlaces(
      nonDeletedCustomExpenses[nonDeletedCustomExpenses.length - 1].line_total,
    );
  } else {
    agent.net_income = agent.royalty_line_total;
  }
  listingData.team_member_incomes.incomes[agentPosition] = agent;
};

const twoDecimalPlaces = (value) => {
  let numAsString = value.toString();
  const indexOfDecimalPoint = numAsString.indexOf(".");

  if (indexOfDecimalPoint < 0) {
    return value;
  } else {
    numAsString = numAsString.slice(0, indexOfDecimalPoint + 3);
    return Number(numAsString);
  }
};

export const recalculateCollaboratorExpenses = (expense, listingData) => {
  return async (dispatch) => {
    calculateCollaboratorExpenses(expense, listingData);
    dispatch(setFinancial(listingData));
  };
};

const calculateCollaboratorExpenses = (expense, listingData) => {
  let customExpense = expense.custom_collaborator_expenses_attributes[0];

  customExpense.expense_amount = Number(customExpense.value);
  customExpense.line_total = Number(customExpense.value);
  expense.total_expense = twoDecimalPlaces(customExpense.value);
};
