import {
  saveAllDates,
  fetchAllDatesFailure,
  showCustomDates,
  updateDates,
  updateDateFailure,
} from "../TransactionDetailsPage/SideRight/Dates/actions/creators";

export const defaultState = {
  uuid: "",
  dates: {
    available_dates: [],
  },
  error: null,
  updateError: null,
  isOpen: false,
  isUpdate: false,
};

export const tdpDatesReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case saveAllDates().type:
      return {
        ...state,
        dates: payload.dates,
        error: null,
        updateError: null,
        isOpen: false,
        isUpdate: false,
      };
    case fetchAllDatesFailure().type:
      return {
        ...state,
        error: payload.error,
        updateError: null,
        isOpen: false,
        isUpdate: false,
      };
    case updateDateFailure().type:
      return {
        ...state,
        error: null,
        updateError: payload.updateError,
        isOpen: false,
        isUpdate: false,
      };
    case showCustomDates().type:
      return {
        ...state,
        error: null,
        updateError: null,
        isOpen: payload.isOpen,
        isUpdate: false,
      };
    case updateDates().type:
      return {
        ...state,
        error: null,
        updateError: null,
        isOpen: false,
        isUpdate: true,
      };
    default:
      return state;
  }
};

export default tdpDatesReducer;
