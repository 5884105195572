/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EnvelopeSolidV6 = (props) => (
  <Icon {...props} name="Envelope Solid V6">
    <path d="M14.5 2a1.5 1.5 0 0 1 .9 2.7L8.6 9.8a1.002 1.002 0 0 1-1.2 0L.6 4.7A1.5 1.5 0 0 1 1.5 2h13zm-7.7 8.6a1.998 1.998 0 0 0 2.4 0L16 5.5V12c0 1.103-.897 2-2 2H2a2 2 0 0 1-2-2V5.5l6.8 5.1z" />
  </Icon>
);

export default EnvelopeSolidV6;
