import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ArrowsRotateSolidV6 } from "@icons/index";

const TimelineAgreementDocumentEvent = ({event}) => {
  const {
    timeline_fields: { updater_name, agreement_type, document_name, changes, transaction_name },
    interacted_at_absolute
  } = event;
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = () => (
    <div>
      <span className="bold-detail">{updater_name}</span>
      {` ${action} a ${agreement_type} agreement document`}
    </div>
  );

  const eventBody = () => {
    switch (action) {
      case 'added':
        return ` ${document_name}`;
      case 'deleted':
        return (
          <>
            <span className="tw-italic">{` (${changes['document'][0]})`}</span>
            <span className="tw-text-[#A81E19]"> removed</span>
          </>
        );
      case 'replaced':
        return (
          <>
            <span className="tw-italic">{` (${changes['document'][0]})`}</span>
            {` ${changes['document'][1]}`}
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (changes['document'].length > 0) {
      setAction(() => {
        if (changes['document'].length === 2) return 'replaced';
        return document_name ? 'added' : 'deleted';
      });
    }
  }, [changes, document_name]);

  return (
    <div>
      <div className="timeline-event" onClick={showModalHandler}>
        <div className="row">
          <div className="col-xs-1">
            {action === 'replaced' ? (
              <div className="timeline-icon-circle tw-content-center">
                <ArrowsRotateSolidV6 size="l" className="tw-align-middle" />
              </div>
            ) : (
              <i className={`fa ${action === "deleted" ? "fa-trash" : "fa-upload"} timeline-icon-circle`} />
            )}
          </div>
          <div className="col-xs-8">
            {headline()}
            {changes['document'].length > 0 && (
              <div className="timeline-description-text">
                Agreement:
                <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
              </div>
            )}
            {transaction_name && (
              <div className="timeline-description-text">
                Transaction:
                <span className="tw-text-[#3B3B3B]">{` ${transaction_name}`}</span>
              </div>
            )}
          </div>
          <div className="col-xs-3 time-ago">
            <span>{interacted_at_absolute}</span>
          </div>
        </div>
      </div>

      <Modal bsSize="sm" show={showModal} onHide={hideModalHandler} className="timeline-content">
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>Agreement Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {headline()}
            {changes['document'].length > 0 && (
              <>
                <div className="row bold-detail details-row details-header">Details:</div>
                <div className="row details-row tw-mt-2">
                  Agreement:
                  <span className="tw-text-[#3B3B3B]">{eventBody()}</span>
                </div>
                {transaction_name && (
                  <div className="row details-row tw-mt-2">
                    Transaction:
                    <span className="tw-text-[#3B3B3B]">{` ${transaction_name}`}</span>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

TimelineAgreementDocumentEvent.propTypes = {
  event: PropTypes.shape({
    timeline_fields: PropTypes.shape({
      updater_name: PropTypes.string,
      agreement_type: PropTypes.string,
      document_name: PropTypes.string,
      transaction_name: PropTypes.string,
      changes: PropTypes.shape({
        document: PropTypes.arrayOf(PropTypes.string)
      })
    }),
    interacted_at_absolute: PropTypes.string
  })
};

TimelineAgreementDocumentEvent.defaultProps = {
  event: {
    timeline_fields: {
      updater_name: "",
      agreement_type: "",
      document_name: "",
      transaction_name: "",
      changes: {
        document: []
      }
    },
    interacted_at_absolute: ""
  }
};

export default TimelineAgreementDocumentEvent;
