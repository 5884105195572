import React from "react";
import PropTypes from "prop-types";
import TextButton from "../../../../shared/v2/TextButton";

const MarketingLoadError = ({ getAllFeedbacks }) => (
  <>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <p data-cy="marketing-error-message">We couldn't fetch showing feedback for this transaction.</p>
    <TextButton
      className="tw-pl-0 tw-mb-16px"
      onClick={getAllFeedbacks}
      data-cy="marketing-error-retry-button"
    >
      Try again?
    </TextButton>
  </>
);

MarketingLoadError.propTypes = {
  getAllFeedbacks: PropTypes.func.isRequired,
};

export default MarketingLoadError;
