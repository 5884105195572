import React from "react";
import { Agent } from "../Agent";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  agents: state.accountFinancial.accountData?.agent_expense_templates.agent_expenses,
});

export const Agents = connect(
  mapStateToProps,
  null,
)((props) => {
  const { agents } = props;

  return (
    <div className="agent-expenses-wrap">
      {agents.map((agent, i) => (
        <Agent key={agent.id} agent={agent} idx={i} />
      ))}
    </div>
  );
});
