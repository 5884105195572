import React from "react";
import { string, shape, arrayOf, oneOfType } from "prop-types";
import { replace } from "lodash";
import Rating from "../../Feedback/Rating";
import LinkifyNotes from "../../../shared/LinkifyNotes/LinkifyNotes";

const soldToClosed = (status) => (status === "sold" ? "closed" : status);

export const DescriptionForAdPhoto = ({ content: [copy, linkGroup] }) => {
  const { link, text } = linkGroup;
  return (
    <>
      <span>{copy}</span>
      <a href={link} target="_blank" rel="noreferrer">
        {text}
      </a>
    </>
  );
};
DescriptionForAdPhoto.propTypes = {
  content: arrayOf(
    oneOfType([
      string,
      shape({
        link: string,
        text: string,
      }),
    ]),
  ),
};
DescriptionForAdPhoto.defaultProps = {
  content: [null, null],
};

export const DescriptionForUpload = ({ content }) => (
  <>
    {content.filter(Boolean).map((link) => (
      <div className="tw-w-[190px] tw-h-[75px]" key={link}>
        <img src={link} alt={link} />
      </div>
    ))}
  </>
);

DescriptionForUpload.propTypes = {
  content: arrayOf(string),
};
DescriptionForUpload.defaultProps = {
  content: [],
};

export const DescriptionForCall = ({ content: [callInteractionGroup] }) => {
  const { outcome, notes } = callInteractionGroup;
  return (
    <>
      <div>
        <span className="tw-font-semibold">Call Result:</span> {outcome}
      </div>
      <div>
        <span className="tw-font-semibold">Notes:</span> {notes}
      </div>
    </>
  );
};
DescriptionForCall.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        outcome: string,
        notes: string,
      }),
    ]),
  ),
};
DescriptionForCall.defaultProps = {
  content: [null],
};

export const DescriptionForMessage = ({ content }) => <>{content}</>;
DescriptionForMessage.propTypes = {
  content: string,
};
DescriptionForMessage.defaultProps = {
  content: null,
};

export const DescriptionForAppointment = ({ content: [appointmentGroup] }) => {
  const { type, time } = appointmentGroup;
  return (
    <>
      <div>
        <span className="tw-font-semibold">Time:</span> {time}
      </div>
      <div>
        <span className="tw-font-semibold">Type:</span> {type}
      </div>
    </>
  );
};
DescriptionForAppointment.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        time: string,
        type: string,
      }),
    ]),
  ),
};
DescriptionForAppointment.defaultProps = {
  content: [null],
};

export const DescriptionForOtherInteraction = ({ content: [otherInteractionGroup] }) => {
  const { type, notes } = otherInteractionGroup;
  return (
    <>
      <div>
        <span className="tw-font-semibold">Type:</span> {type}
      </div>
      <div>
        <span className="tw-font-semibold">Notes:</span> {notes}
      </div>
    </>
  );
};
DescriptionForOtherInteraction.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        type: string,
        notes: string,
      }),
    ]),
  ),
};
DescriptionForOtherInteraction.defaultProps = {
  content: [null],
};

export const DescriptionWithLabel = ({ content: [label, timelineGroup] }) => {
  const { text } = timelineGroup;
  return (
    <>
      <div>
        <span className="tw-font-semibold">{label}: </span> {text}
      </div>
    </>
  );
};
DescriptionWithLabel.propTypes = {
  content: arrayOf(
    oneOfType([
      string,
      shape({
        text: string,
      }),
    ]),
  ),
};
DescriptionWithLabel.defaultProps = {
  content: [null, null],
};

export const DescriptionForStatus = ({ content: [statusGroup] }) => {
  const { before, after } = statusGroup;
  return (
    <>
      <span
        className={`tw-mr-8px tw-inline-block tw-text-white tw-font-bold tw-text-center tw-uppercase pill pill-${replace(
          before,
          " ",
          "",
        )}`}
      >
        {soldToClosed(before)}
      </span>
      to
      <span
        className={`tw-ml-8px tw-inline-block tw-text-white tw-font-bold tw-text-center tw-uppercase pill pill-${replace(
          after,
          " ",
          "",
        )}`}
      >
        {soldToClosed(after)}
      </span>
    </>
  );
};
DescriptionForStatus.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        before: string,
        after: string,
      }),
    ]),
  ),
};
DescriptionForStatus.defaultProps = {
  content: [null],
};

export const DescriptionForShowing = ({ content: [showingGroup] }) => {
  const { text, liked } = showingGroup;
  return (
    <>
      <div>{text}</div>
      <div>
        <Rating rating={liked} size="l" />
      </div>
    </>
  );
};
DescriptionForShowing.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        text: string,
        liked: Number,
      }),
    ]),
  ),
};
DescriptionForShowing.defaultProps = {
  content: [null],
};

export const DescriptionForDate = ({ content: [copy, dateGroup] }) => {
  const { before, after } = dateGroup;

  return (
    <>
      <div>
        <span className="tw-font-semibold">Event:</span> {copy}
      </div>
      <div>
        <span className="tw-font-semibold">Previous Date:</span> {before}
      </div>
      <div>
        <span className="tw-font-semibold">New Date:</span> {after}
      </div>
    </>
  );
};
DescriptionForDate.propTypes = {
  content: arrayOf(
    oneOfType([
      string,
      shape({
        before: string,
        after: string,
      }),
    ]),
  ),
};
DescriptionForDate.defaultProps = {
  content: [null, null],
};

export const DescriptionForUpdate = ({ content: [group] }) => {
  const { before, after } = group;

  return (
    <>
      {before && (
        <div>
          <span className="tw-font-semibold">Was:</span>
          <LinkifyNotes markdownContent={before} />
        </div>
      )}
      {after && (
        <div>
          <span className="tw-font-semibold">New:</span>
          <LinkifyNotes markdownContent={after} />
        </div>
      )}
    </>
  );
};
DescriptionForUpdate.propTypes = {
  content: arrayOf(
    oneOfType([
      shape({
        before: string,
        after: string,
      }),
    ]),
  ),
};
DescriptionForUpdate.defaultProps = {
  content: [null, null],
};

export const DescriptionForDocument = ({ content }) => {
  const { name, url, cdaForm } = content;

  // a CDA form document is not linkable for timelines
  if (cdaForm) return name;

  return url ? <a href={url} rel="noreferrer" target="_blank">{` ${name}`}</a> : name;
};
DescriptionForDocument.propTypes = {
  content: shape({
    name: string,
    url: string,
  }),
};
DescriptionForDocument.defaultProps = {
  content: {},
};

export const DescriptionForImport = ({ content }) => {
  const { mls_number } = content;
  return (
    <div>
      <span className="tw-font-semibold">MLS #: </span> {mls_number}
    </div>
  );
};
DescriptionForImport.propTypes = {
  content: shape({
    mls_number: string,
  }),
};
DescriptionForImport.defaultProps = {
  content: {},
};
