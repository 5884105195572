import React from "react";
import { Col, Row } from "react-bootstrap";
import DragHandle from "../../shared/DragHandle";
import ExpenseInput from "../../shared/ExpenseInput";
import { formatExpense } from "../../shared/FinancialViewHelpers";
import { reCalculateAgentExpenses, shiftSplitPosition } from "../../shared/FinancialViewHelpers";
import format from "../../../../shared/formatters";

export const SortableTeamMemberExpenseInput = (props) => {
  const {
    value,
    sortIndex,
    listingData,
    setFinancialData,
    parentIndex,
    income,
    setFinancial,
  } = props;

  return (
    <div className="transaction-gci-options tw-bg-white">
      <div className="transaction-gci-content">
        <Row>
          <DragHandle />

          <Col xs={2}>
            <ExpenseInput
              label={value.name}
              value={value.value}
              amount={value.expense_amount}
              isPercentage={!!value.percentage}
              name={`additionalIncomePercentage${sortIndex}`}
              onValueUpdate={(e) => {
                if (!!value.percentage) {
                  if (e.target.value >= 0 && e.target.value <= 100) {
                    value.value = e.target.value;
                    reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
                  }
                } else {
                  value.value = e.target.value;
                  reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
                }
              }}
              onRadioUpdate={(e) => {
                if (e.target.value === "true" && value.value > 100) {
                  value.value = "";
                }
                value.percentage = e.target.value === "true";
                reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
              }}
            />
          </Col>

          <Col xs={4}>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="description form-control"
                name={`addtionalIncomeNote${sortIndex}`}
                placeholder=""
                value={value.notes || ""}
                onChange={(e) => {
                  value.notes = e.target.value;
                  setFinancialData(listingData);
                }}
              />
            </div>
          </Col>

          <Col xs={1} />

          <Col xs={2} className="text-right">
            <span>{formatExpense(format.currency(value.expense_amount))}</span>
          </Col>

          <Col xs={2} className="text-right">
            <span>{format.currency(value.line_total)}</span>
          </Col>

          <div className="delete-expense">
            <a
              onClick={() => {
                const expenses =
                  listingData.team_member_incomes.incomes[parentIndex].custom_team_member_expenses_attributes;
                const { position: expPosition } = value;
                const idx = expenses.findIndex((exp) => exp.position === expPosition);
                expenses[idx]._destroy = "1";
                delete expenses[idx]["position"];
                shiftSplitPosition(income, expPosition);
                reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
              }}
            >
              <i className="fa fa-times" />
            </a>
          </div>
        </Row>
      </div>
    </div>
  );
};
