import React from "react";
import Proptypes from "prop-types";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import IconButton from "../../../../shared/v2/IconButton";
import Links from "./Links";
import { iff } from "../actions/helpers";

const ListingLink = ({ googleSearchUrl, siteinput, hovered, links, onSiteInputOpen, setEdit, setlink }) => (
  <>
    {!siteinput ? (
      iff(
        links,
        <Links
          hovered={hovered}
          links={links}
          onSiteInputOpen={onSiteInputOpen}
          setEdit={setEdit}
          setlink={setlink}
        />,
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span // eslint-disable-line jsx-a11y/no-static-element-interactions
            onClick={() => window.open(googleSearchUrl, "_blank")}
            className="tw-font-normal tw-text-teal tw-cursor-pointer tw-leading-[16px] tw-ml-[-8px] tw-text-[12px] tw-pr-[8px]"
          >
            Search Google
          </span>
          <IconButton
            onClick={onSiteInputOpen}
            isedit={setEdit(false)}
            setlink={setlink(null)}
            size="small"
            schema="tertiary"
          >
            <Plus size="s" />
          </IconButton>
        </>,
      )
    ) : (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        onClick={() => window.open(googleSearchUrl, "_blank")}
        className="tw-font-normal tw-text-teal tw-cursor-pointer tw-leading-[16px] tw-ml-[-8px] tw-text-[12px] tw-pr-[8px]"
      >
        Search Google
      </span>
    )}
  </>
);

ListingLink.propTypes = {
  links: Proptypes.string,
  googleSearchUrl: Proptypes.string,
  hovered: Proptypes.bool,
  siteinput: Proptypes.bool,
  onSiteInputOpen: Proptypes.func,
  setEdit: Proptypes.func,
  setlink: Proptypes.func,
};
ListingLink.defaultProps = {
  links: "",
  googleSearchUrl: "",
  hovered: false,
  siteinput: false,
  onSiteInputOpen: () => {},
  setEdit: () => {},
  setlink: () => {},
};

export default ListingLink;
