import React from "react";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextComboInput from "@shared/v2/TextComboInput";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import useProductionGoals from "../services/useProductionGoals";
import Loading from "./Loader";

const TeamDefaultsProductionModal = ({ show, onClose, year }) => {
  const { actions, errors, goals, loading } = useProductionGoals({ show, year });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    const cleanValue = value.replace(/[^0-9.]/g, "");
    const endsWithDecimal = cleanValue.endsWith(".");
    formattedValue =
      cleanValue === "" ? "" : `${parseFloat(cleanValue).toLocaleString()}${endsWithDecimal ? "." : ""}`;
    actions.setGoals((prev) => ({ ...prev, [name]: formattedValue }));
  };

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
      show={show}
      onHide={onClose}
    >
      <Modal.Header
        closeButton
        title={`Team Defaults: ${year} Production Data`}
        description={
          <span className="tw-text-gray-50">
            Team defaults for production data are used to provide agents with a baseline set of values when
            they are setting their goals through &quot;Help Me Set My Goals&quot;.
          </span>
        }
      />
      <Modal.Body className="tw-relative tw-flex tw-flex-col tw-gap-[24px]">
        {loading && <Loading />}
        {errors.global && <Alert variant="error" text={errors.global} />}
        <div className="tw-flex tw-gap-[24px]">
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            leading="$"
            label="Avg. Sale Price"
            name="avgSalePrice"
            error={errors.avgSalePrice}
            value={goals.avgSalePrice}
            onChange={handleChange}
          />
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            trailing="%"
            label="Avg. Commission"
            name="avgCommission"
            error={errors.avgCommission}
            value={goals.avgCommission}
            onChange={handleChange}
          />
        </div>
        <div className="tw-flex tw-gap-[24px]">
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            trailing="%"
            label="Seller Commission Split w/ Team"
            name="sellerCommissionSplit"
            error={errors.sellerCommissionSplit}
            value={goals.sellerCommissionSplit}
            onChange={handleChange}
          />
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            trailing="%"
            label="Buyer Commission Split w/ Team"
            name="buyerCommissionSplit"
            error={errors.buyerCommissionSplit}
            value={goals.buyerCommissionSplit}
            onChange={handleChange}
          />
        </div>
        <div className="tw-flex tw-gap-[24px]">
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            trailing="%"
            label="Pending to Closed Ratio"
            name="pendingToCloseRatio"
            error={errors.pendingToCloseRatio}
            value={goals.pendingToCloseRatio}
            onChange={handleChange}
          />
          <TextComboInput
            isRequired
            containerClassName="tw-flex-1"
            trailing="%"
            label={
              <span>
                Seller to Total Closings Ratio{" "}
                <Tooltip
                  trigger={<CircleInfoSolidV6 />}
                  content="The percentage of buyer and seller closings that were sellers"
                  innerClassName="tw-max-w-[230px]"
                />
              </span>
            }
            name="sellerToTotalClosingsRatio"
            error={errors.sellerToTotalClosingsRatio}
            value={goals.sellerToTotalClosingsRatio}
            onChange={handleChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button size="medium" schema="tertiary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          size="medium"
          isLoading={loading}
          onClick={() =>
            actions.saveGoals().then((success) => {
              if (success) onClose();
            })
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TeamDefaultsProductionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default TeamDefaultsProductionModal;
