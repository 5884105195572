/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../shared/v2/Tooltip";

const colorDictionary = {
  buyer: {
    regular: "tw-border-semantic-blue-10 tw-text-semantic-blue-110",
    themed: "tw-border-theme-type-cell-buyer tw-text-theme-type-cell-buyer",
  },
  seller: {
    regular: "tw-border-semantic-yellow-10 tw-text-semantic-yellow-110",
    themed: "tw-border-theme-type-cell-seller tw-text-theme-type-cell-seller",
  },
  landlord: {
    regular: "tw-border-semantic-red-10 tw-text-semantic-red-110",
    themed: "tw-border-theme-type-cell-landlord tw-text-theme-type-cell-landlord",
  },
  referral: {
    regular: "tw-border-semantic-pink-10 tw-text-semantic-pink-110",
    themed: "tw-border-theme-type-cell-referral tw-text-theme-type-cell-referral",
  },
  tenant: {
    regular: "tw-border-semantic-green-10 tw-text-semantic-green-110",
    themed: "tw-border-theme-type-cell-tenant tw-text-theme-type-cell-tenant",
  },
};

const colorFor = (type) => colorDictionary[type] || colorDictionary.seller;

const contentDictionary = {
  buyer: "Buyer",
  seller: "Seller",
  landlord: "Landlord",
  referral: "Referral",
  tenant: "Tenant",
};

const contentFor = (type) => contentDictionary[type] || contentDictionary.seller;

const typeToken = (type, themed = false, withTooltip = true) => {
  const trigger = (
    <span
      className={`
        tw-border-2 tw-border-solid
        tw-flex tw-items-center tw-justify-center
        tw-w-20px tw-h-20px
        tw-rounded-4px
        tw-cursor-default
        tw-text-11d tw-font-bold
        ${themed ? colorFor(type).themed : colorFor(type).regular}
      `}
    >
      {contentFor(type).charAt(0)}
    </span>
  );

  if (!withTooltip) {
    return trigger;
  }

  return <Tooltip placement="top" content={contentFor(type)} trigger={trigger} />;
};

const TypeCell = ({ value, themed, withTooltip, className }) => (
  <div
    className={`
      tw-inline-flex tw-flex-row tw-items-center tw-justify-center tw-flex-nowrap
      ${className}
    `}
  >
    {typeToken(value, themed, withTooltip)}
  </div>
);

TypeCell.propTypes = {
  value: PropTypes.string,
  themed: PropTypes.bool,
  withTooltip: PropTypes.bool,
  className: PropTypes.string,
};

TypeCell.defaultProps = {
  value: null,
  themed: false,
  withTooltip: true,
  className: "",
};

export default TypeCell;
