import React from "react";

import { PhoneSolidV6, PhoneXmarkSolidV6, MobileRegularV6, MobileXMark } from "@icons";

const CALL_STATUS_ICONS = {
  valid: PhoneSolidV6,
  unsubscribed: PhoneXmarkSolidV6,
};

export const STATUS_ICON_MODIFIERS = {
  valid: "tw-text-neutral-gray-75",
  unsubscribed: "tw-text-brivity-coral-110",
};

export const CALL_STATUS_DESCRIPTIONS = {
  valid: "Can Be Called",
  unsubscribed: "Opted-out",
};

export const CALL_STATUS_TOOLTIPS = {
  valid: "Can Be Called",
  unsubscribed: "This number has opted out of phone communication and cannot be called",
};

export const callStatusIcon = (status) => {
  const UsedIcon = CALL_STATUS_ICONS[status] || CALL_STATUS_ICONS.valid;
  const tooltip = CALL_STATUS_TOOLTIPS[status] || CALL_STATUS_TOOLTIPS.valid;
  const iconModifiers = STATUS_ICON_MODIFIERS[status] || STATUS_ICON_MODIFIERS.valid;
  return {
    icon: ({ size = "l" }) => <UsedIcon className={iconModifiers} size={size} />,
    tooltip,
    status,
  };
};
export const callStatusIconForPhone = (phone) => callStatusIcon(phone.callStatus);

const MOBILE_CALL_STATUS_ICONS = {
  valid: MobileRegularV6,
  unsubscribed: MobileXMark,
};

export const mobileCallStatusIcon = (status) => {
  const UsedIcon = MOBILE_CALL_STATUS_ICONS[status] || MOBILE_CALL_STATUS_ICONS.valid;
  const tooltip = CALL_STATUS_TOOLTIPS[status] || CALL_STATUS_TOOLTIPS.valid;
  const iconModifiers = STATUS_ICON_MODIFIERS[status] || STATUS_ICON_MODIFIERS.valid;
  return {
    icon: ({ size }) => <UsedIcon className={iconModifiers} size={size || "l"} />,
    tooltip,
  };
};

export default { callStatusIconForPhone, mobileCallStatusIcon };
