import React from "react";
import Button from "@shared/v2/Button";
import { ArrowRightSolidV6, UserLargeSolidV6 } from "@shared/v2/Icomoon";
import { DncFlag } from "@shared/Sms";

const UpNext = ({ name, phoneNumber, dnc, pdpLink }) => (
  <div className="tw-border-1px tw-border-solid tw-rounded tw-border-gray-10 tw-w-full tw-p-8px tw-flex tw-justify-between tw-text-gray-75 tw-mb-16px">
    <div className="tw-flex tw-items-center tw-gap-8px">
      <ArrowRightSolidV6 className="tw-mr-4px" size="m" />
      <div className="">Up Next:</div>
      <div className="tw-text-14d tw-font-semibold tw-max-w-[214px] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">{name}</div>
      <div className="tw-font-semibold tw-text-gray-50">Mobile: {phoneNumber}</div>
      <DncFlag show={dnc} />
    </div>
    <a href={pdpLink} target='_blank'>
      <Button schema='tertiary'>
        <span>PREVIEW</span>
        <UserLargeSolidV6 className='tw-ml-4px' size='s' />
      </Button>
    </a>
  </div>
);

export default UpNext;
