import React from "react";
import { arrayOf, shape } from "prop-types";
import { find } from "lodash";

import ActionV2 from "../ActionV2";
import { useActions } from "../../../reducers/autoPlanEdit";

const SpecificDatesActions = ({actions}) => {
  const listOfAllActions = useActions();

  return (
    <div className={`tw-mt-[16px] tw-px-[24px] ${Boolean(actions.length) && 'tw-pt-[32px]'}`}>
      {actions?.map(({id}) => {
        const action = find(listOfAllActions, { id });

        return <ActionV2
          key={id}
          action={action}
          from={action.assigned_to_label}/>
        }
      )}
    </div>
  )
}

export default SpecificDatesActions;

SpecificDatesActions.propTypes = {
  actions: arrayOf(shape({}))
};

SpecificDatesActions.defaultProps = {
  actions: [{
    assigned_to_id: null,
    assigned_to_role: 'owner',
    assigned_to_type: null,
    auto_plan_id: "ed325347-400b-4423-a073-e24f9bc32a12",
    contingent: true,
    delay: "0",
    delay_factor: 1,
    delay_unit: "days",
    instructions: "xx",
    name: "neww task",
    note: "yy",
    priority: 3,
    run_time: "17:00",
    day_month_year: '15/05/2024',
    schedule_type: "computed_time",
    type: "Task",
  }]
}
