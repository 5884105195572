import React from "react";
import { bool, func, string } from "prop-types";

import { useDependencyWithCleanUp } from "../../../../shared/hookHelpers";

import Modal from "../../../../shared/v2/Modal";
import ModalField from "../../../shared/ModalField";
import Button from "../../../../shared/v2/Button";

const ModalEditLabel = ({ show, onHideHandlr, onRemoveHandlr, onSaveHandlr, labelValue, setLabelValue }) => (
  <Modal
    show={show}
    onHide={onHideHandlr}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[616px]"
    closeButton
  >
    <Modal.Header title="Edit Property Detail" className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px">
      <ModalField label="Detail Name" value={labelValue} onChange={(e) => setLabelValue(e.target.value)} />
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="secondary">
        CANCEL
      </Button>
      <div className="tw-flex tw-gap-x-[8px]">
        <Button size="medium" schema="warning" onClick={onRemoveHandlr}>
          REMOVE
        </Button>
        <Button size="medium" schema="primary" onClick={onSaveHandlr}>
          SAVE
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

const WrapperModalEditLabel = ({ show, onHide, onRemove, onSave, labelValue }) => {
  const [_labelValue, setLabelValue] = useDependencyWithCleanUp(labelValue);

  const onHideHandlr = () => {
    setLabelValue(labelValue);
    onHide();
  };

  const onSaveHandlr = () => {
    onHideHandlr();
    return labelValue !== _labelValue && onSave(_labelValue);
  };

  const onRemoveHandlr = () => {
    onRemove();
    onHideHandlr();
  };

  return (
    <ModalEditLabel
      show={show}
      onHideHandlr={onHideHandlr}
      onRemoveHandlr={onRemoveHandlr}
      onSaveHandlr={onSaveHandlr}
      labelValue={_labelValue}
      setLabelValue={setLabelValue}
    />
  );
};

export default WrapperModalEditLabel;

ModalEditLabel.propTypes = {
  show: bool,
  onHideHandlr: func,
  onRemoveHandlr: func,
  onSaveHandlr: func,
  labelValue: string,
  setLabelValue: func,
};

ModalEditLabel.defaultProps = {
  show: false,
  onHideHandlr: () => {},
  onRemoveHandlr: () => {},
  onSaveHandlr: () => {},
  labelValue: "",
  setLabelValue: () => {},
};

WrapperModalEditLabel.propTypes = {
  show: bool,
  onHide: func,
  onRemove: func,
  onSave: func,
  labelValue: string,
};

WrapperModalEditLabel.defaultProps = {
  show: false,
  onHide: () => {},
  onRemove: () => {},
  onSave: () => {},
  labelValue: "",
};
