const multiSelects = () => document.querySelectorAll("input.multi-select");

const hideElement = (elem) => {
  if (elem) elem.style.display = "none"; // eslint-disable-line no-param-reassign
};

export const showElement = (elem) => {
  if (elem) elem.style.display = "inline"; // eslint-disable-line no-param-reassign
};

const AddorRemoveClass = (type) => {
  document
    .querySelectorAll(
      "#apply-tags, #extend-access, #mass-update-status, #mass-update-stage-modal,#mass-update-market-reports, #mass-send-listing-alerts",
    )
    .forEach((val) =>
      type === "select-all" ? val.classList.add("disabled") : val.classList.remove("disabled"),
    );
};

const showSelectedCount = (type) => {
  if (document.querySelectorAll("input.multi-select:checked").length > 0) {
    if (type === "select-all") {
      document.getElementById("header-selected-count").innerHTML = "All";
    } else {
      document.getElementById("header-selected-count").innerHTML = `${
        document.querySelectorAll("input.multi-select:checked").length
      }  Selected`;
    }
  } else {
    document.getElementById("header-selected-count").innerHTML = "";
  }
};

export const selectOption = (type) => {
  if (type !== null) {
    const selectRows = document.getElementById("select-rows");
    const isAllSelected = document.getElementById("is-all-selected");
    multiSelects().forEach((item) => {
      item.checked = type !== "select-none"; // eslint-disable-line no-param-reassign
    });
    document
      .querySelectorAll(".multi-select-buttons > button")
      .forEach((val) => (type === "select-none" ? hideElement(val) : showElement(val)));
    if (selectRows !== null) {
      selectRows.checked = type !== "select-none";
      selectRows.indeterminate = false;
    }
    if (isAllSelected !== null) {
      isAllSelected.value = type === "select-all";
    }
    if (type === "select-none") {
      hideElement(document.querySelectorAll("span.mass-call-button")[0]);
    } else {
      showElement(document.querySelectorAll("span.mass-call-button")[0]);
    }
    showSelectedCount(type);
    AddorRemoveClass(type);
  }
};
