/* eslint-disable import/prefer-default-export */
export const adaptError = (error) => {
  if (!error?.response?.data?.message) {
    return {
      message: "Unknown error occurred. Please try again later",
    };
  }

  return error.response.data;
};
