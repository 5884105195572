import React from "react";
import { Col, Row, Modal } from "../../../shared/v1";
import { removeKeys } from "../../shared/helpers";
import { moveDeletedExpenseToEnd } from "../shared/FinancialViewHelpers";
import { IncomeExpenseForm } from "../shared/IncomeExpenseForm";
import Error from "../../../shared/Error";

class AddExpenseModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      name: "",
      value: "",
      percentage: true,
      position: "",
      isNotValid: false,
      agent_expense_type_id: "",
      transaction_expense_type_id: "",
      company_expense_type_id: "",
      company_revenue_type_id: "",
      type: "expense",
      errMsg: "",
    };
  }

  saveExpenseData = () => {
    if (this.state.percentage) {
      if (this.state.value >= 0 && this.state.value <= 100) {
        this.saveData();
      } else {
        this.setState({
          isNotValid: true,
          errMsg: "Please enter value between 0 and 100",
        });
      }
    } else {
      this.saveData();
    }
  };

  saveData = () => {
    if ((this.state.name != "" || this.state.value != "") && this.state.name && this.state.value) {
      if (this.props.expenseType === "office") {
        this.state.position =
          this.props.accountData.account_financial_template.custom_account_expenses_attributes.length + 1;
        const customState = removeKeys(this.state, [
          "agent_expense_type_id",
          "company_expense_type_id",
          "company_revenue_type_id",
        ]);
        this.props.accountData.account_financial_template.custom_account_expenses_attributes.push(
          customState,
        );
      }
      if (this.props.expenseType === "company") {
        this.state.position =
          this.props.accountData.company_expense_template.custom_agent_expenses_attributes.length + 1;
        const customState = removeKeys(this.state, ["agent_expense_type_id", "transaction_expense_type_id"]);
        this.props.accountData.company_expense_template.custom_agent_expenses_attributes.push(customState);
      }

      if (this.props.expenseType === "agent") {
        const data = this.props.accountData.agent_expense_templates.agent_expenses.find(
          this.props.activeAgentExpense,
        );
        const index = this.props.accountData.agent_expense_templates.agent_expenses.indexOf(data);
        this.state.position =
          this.props.accountData.agent_expense_templates.agent_expenses[
            index
          ].custom_agent_expenses_attributes.filter((exp) => !exp._destroy).length + 2;
        const customState = removeKeys(this.state, [
          "transaction_expense_type_id",
          "company_expense_type_id",
          "company_revenue_type_id",
        ]);
        this.props.accountData.agent_expense_templates.agent_expenses[
          index
        ].custom_agent_expenses_attributes.push(customState);
        moveDeletedExpenseToEnd(
          this.props.accountData.agent_expense_templates.agent_expenses[index]
            .custom_agent_expenses_attributes,
        );
      }

      this.props.setAccountData(this.props.accountData);
      this.props.closeNewExpenseModal();
    } else {
      this.setState({
        isNotValid: true,
        errMsg: "Please enter name and value",
      });
    }
  };

  render() {
    const {
      isModalOpen,
      closeNewExpenseModal,
      agentExpenseList,
      transactionExpenseList,
      companyList,
      expenseType,
    } = this.props;
    return (
      <Modal show={isModalOpen} onHide={closeNewExpenseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.expenseType === "company" ? "New Expense/Income" : "New Expense"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="auto-plans-form transaction-gci-content"
          deprecatedOverridePaddingClasses="tw-px-15"
        >
          <div className="transaction-gci-options transaction-modal tw-border-0">
            {this.state.isNotValid && <Error errorMessage={this.state.errMsg} />}
            <Row>
              <Col size={12}>
                <p className="modal-description">This is standardized for all transactions.</p>
              </Col>
            </Row>
            <IncomeExpenseForm
              modalThis={this}
              expList={
                expenseType === "office"
                  ? transactionExpenseList
                  : expenseType === "company"
                  ? companyList
                  : agentExpenseList
              }
              expenseType={
                expenseType === "office" ? "transaction" : expenseType === "company" ? "company" : "agent"
              }
              setIsOpenNewIncomeModal={closeNewExpenseModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddExpenseModal;
