/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ApartmentSolidV6 = (props) => (
  <Icon {...props} name="Apartment Solid V6">
    <path d="M11.111.889c.736 0 1.333.597 1.333 1.333v4h2.222c.736 0 1.333.597 1.333 1.333v6.222c0 .736-.597 1.333-1.333 1.333H8.888v-2.667c0-.492-.397-.889-.889-.889s-.889.397-.889.889v2.667H1.332a1.333 1.333 0 0 1-1.333-1.333V4.888c0-.736.597-1.333 1.333-1.333h2.222V2.222c0-.736.597-1.333 1.333-1.333h6.222zm-.889 8.889c.244 0 .444-.2.444-.444v-.889c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444v.889c0 .244.2.444.444.444h.889zm4 1.333c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444V12c0 .244.2.444.444.444h.889c.244 0 .444-.2.444-.444v-.889zM3.111 9.778c.244 0 .444-.2.444-.444v-.889c0-.244-.2-.444-.444-.444h-.889c-.246 0-.444.2-.444.444v.889c0 .244.199.444.444.444h.889zm.445 1.333c0-.244-.2-.444-.444-.444h-.889c-.246 0-.444.2-.444.444V12c0 .244.199.444.444.444h.889c.244 0 .444-.2.444-.444v-.889zm3.111-1.333c.244 0 .444-.2.444-.444v-.889c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444v.889c0 .244.2.444.444.444h.889zm4-6.667c0-.246-.2-.444-.444-.444h-.889a.445.445 0 0 0-.444.444V4c0 .244.2.444.444.444h.889c.244 0 .444-.2.444-.444v-.889zm-4.889-.444a.445.445 0 0 0-.444.444V4c0 .244.2.444.444.444h.889c.244 0 .444-.2.444-.444v-.889c0-.246-.2-.444-.444-.444h-.889zm4.889 3.111c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444v.889c0 .244.2.444.444.444h.889c.244 0 .444-.2.444-.444v-.889zm3.111 4c.244 0 .444-.2.444-.444v-.889c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444v.889c0 .244.2.444.444.444h.889zm-10.222-4c0-.244-.2-.444-.444-.444h-.889c-.246 0-.444.2-.444.444v.889c0 .244.199.444.444.444h.889c.244 0 .444-.2.444-.444v-.889zm3.111 1.333c.244 0 .444-.2.444-.444v-.889c0-.244-.2-.444-.444-.444h-.889c-.244 0-.444.2-.444.444v.889c0 .244.2.444.444.444h.889z" />
  </Icon>
);

export default ApartmentSolidV6;
