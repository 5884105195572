import {
  togglePrimaryFilter,
  createTasksByTeamMemberFilter,
  createTasksByUnassignedFilter,
} from "./creators";

export default {
  primary: togglePrimaryFilter,
  teamMembers: createTasksByTeamMemberFilter,
  unassigned: createTasksByUnassignedFilter,
};
