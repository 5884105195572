/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentDotsRegularV6 = (props) => (
  <Icon {...props} name="Comment Dots Regular V6">
    <path d="M4.5 6.5c-.553 0-1 .444-1 .972 0 .581.447 1.028 1 1.028s.972-.445.972-1c0-.555-.416-1-.972-1zM8 6.472c-.555 0-.972.445-.972 1s.445.972.972.972c.527 0 .972-.445.972-.972s-.416-1-.972-1zm3.5.028c-.555 0-.972.445-.972 1s.445 1 .972 1c.555 0 1-.445 1-1 0-.556-.443-1-1-1zM8 .972c-4.419 0-7.972 2.91-7.972 6.5 0 1.488.622 2.852 1.653 3.947-.465 1.234-1.433 2.278-1.449 2.289a.751.751 0 0 0-.144.813c.094.298.361.48.662.48 1.922 0 3.409-.805 4.347-1.445.902.281 1.88.445 2.903.445 4.419 0 7.972-2.91 7.972-6.472S12.419.973 8 .973zM8 12.5a8.1 8.1 0 0 1-2.449-.379l-.711-.223-.609.43a6.673 6.673 0 0 1-1.797.906c.23-.379.449-.805.621-1.258l.332-.875-.644-.683C2.181 9.815 1.502 8.819 1.502 7.5c0-2.758 2.914-5 6.472-5s6.472 2.242 6.472 5-2.858 5-6.446 5z" />
  </Icon>
);

export default CommentDotsRegularV6;
