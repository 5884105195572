const keyMapping = (keyMapp, data) => {
  const newData = Object.keys(data).reduce((acc, key) => {
    acc[keyMapp[key] || key] = data[key];
    return acc;
  }, {});
  return newData;
};

export const companyOptions = (companyExpenseList, companyRevenueList) => {
  const keyMappExpense = {
    Custom: "Custom Expense",
  };
  const keyMappIncome = {
    Custom: "Custom Income",
  };
  const companyList = {
    ...keyMapping(keyMappIncome, companyRevenueList),
    ...keyMapping(keyMappExpense, companyExpenseList),
  };
  return companyList;
};
