import React from "react";
import Proptypes from "prop-types";

const PropTypeButton = ({ onClick, opt, selected }) => (
  <button
    type="button"
    className={`tw-relative tw-h-[76px] tw-rounded-lg tw-border-solid tw-font-semibold focus-visible:tw-outline-0 ${
      selected
        ? "tw-bg-blue-10 tw-text-brivity-blue-100 tw-border-brivity-blue-100 hover:tw-bg-blue-10 hover:tw-border-brivity-blue-dark hover:tw-text-brivity-blue-dark"
        : "tw-bg-white tw-border-gray-50 tw-text-gray-50 hover:tw-bg-white hover:tw-text-gray-75 hover:tw-border-gray-75"
    }
tw-group tw-flex tw-flex-col tw-justify-center
`}
    onClick={onClick}
  >
    <p className="tw-w-full tw-px-1 tw-mb-0">{opt?.replace("/", "/ ")}</p>
  </button>
);

export default PropTypeButton;

PropTypeButton.propTypes = {
  onClick: Proptypes.func,
  opt: Proptypes.string,
  selected: Proptypes.bool,
};

PropTypeButton.defaultProps = {
  onClick: () => {},
  opt: null,
  selected: false,
};
