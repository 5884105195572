import React from "react";
import PropTypes from "prop-types";

import styleSchema from "./ClientIntentTextBtn.styles";
import { useGivenOrGeneratedId } from "../../shared/hookHelpers";

const ClientIntentTextBtn = ({ name, value, label, checked, size, ...otherProps }) => (
  <label className="tw-m-0 tw-text-center tw-cursor-pointer tw-h-[52px] tw-grow">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className="tw-sr-only tw-peer"
      {...otherProps}
    />
    <div
      role="radio"
      aria-checked={checked}
      className={`tw-h-[52px] tw-flex tw-items-center tw-justify-center ${styleSchema.default} ${styleSchema[size]}`}
    >
      {label}
    </div>
  </label>
);

ClientIntentTextBtn.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["small", "medium"]).isRequired,
};

ClientIntentTextBtn.defaultProps = {
  name: null,
};

const ClientIntentTextBtns = ({
  name: nameFromProps,
  options,
  value,
  size,
  clientIntentBtnsClassName,
  onChange,
  className,
  ...otherProps
}) => {
  const name = useGivenOrGeneratedId("text-toggle", nameFromProps);

  return (
    <div className="tw-w-full tw-grid tw-grid-cols-4 tw-gap-x-3 tw-gap-y-3 tw-h-full">
      {options.map((option) => (
        <ClientIntentTextBtn
          key={option.value}
          name={name}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          size={size}
          onChange={onChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      ))}
    </div>
  );
};

const createOptionsCustomPropType = (isRequired) => (props, propName, componentName) => {
  const { options } = props;

  if (options === null || options === undefined) {
    return isRequired ? new Error(`Prop \`${propName}\` is required by \`${componentName}\`.`) : null;
  }

  if (!Array.isArray(options)) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array.`);
  }

  if (options.length < 2) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array with at least two elements.`,
    );
  }

  options.forEach((element) =>
    // Disabling `react/forbid-foreign-prop-types` here since we maintain the ClientIntentTextBtns.Option.propTypes
    // eslint-disable-next-line react/forbid-foreign-prop-types
    PropTypes.checkPropTypes(
      {
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      },
      element,
      "prop",
      "ClientIntentTextBtns.Option",
    ),
  );

  return null;
};

const optionsCustomPropType = createOptionsCustomPropType(false);
optionsCustomPropType.isRequired = createOptionsCustomPropType(true);

ClientIntentTextBtns.propTypes = {
  name: PropTypes.string,
  options: optionsCustomPropType.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  clientIntentBtnsClassName: PropTypes.string,
};

ClientIntentTextBtns.defaultProps = {
  name: null,
  size: "medium",
  onChange: null,
  className: "",
  clientIntentBtnsClassName: "",
};

export default ClientIntentTextBtns;
