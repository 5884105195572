import React from "react";
import { string, func, shape, bool } from "prop-types";

import { deleteNoteAsThunk } from "../actions/thunks";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";

const DeleteNoteConfirmationModal = ({
  deleteListing,
  setDeleteListing,
  onDeleteNoteAsThunk,
  uuid,
  note,
}) => (
  <Modal
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-full"
    dialogClassName="tw-max-w-[487px] !tw-w-full"
    show={deleteListing}
    onHide={() => setDeleteListing(false)}
  >
    <Modal.Header closeButton className="tw-mb-[12px]">
      <div className="!tw-mt-[-28px] tw-text-[28px] tw-font-normal tw-leading-9 tw-tracking-normal tw-text-left tw-text-gray-75">
        Delete Note
      </div>
    </Modal.Header>
    <Modal.Body>
      <p className="tw-text-[14px] tw-font-normal tw-text-gray-75 tw-mb-0">
        Are you sure you want to delete this note?
      </p>
    </Modal.Body>
    <Modal.Footer className="tw-mt-[44px] tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={() => setDeleteListing(false)}>
        Cancel
      </Button>
      <Button size="medium" schema="warning" onClick={() => onDeleteNoteAsThunk(note, uuid)}>
        Yes, Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

const withTdpDetails = tdpDetails({
  WrappedComponent: DeleteNoteConfirmationModal,
  states: LISTING_UUID,
});

export default withDispatch({
  WrappedComponent: withTdpDetails,
  actions: deleteNoteAsThunk,
  aliases: "onDeleteNoteAsThunk",
});

DeleteNoteConfirmationModal.propTypes = {
  deleteListing: bool,
  setDeleteListing: func,
  onDeleteNoteAsThunk: func,
  uuid: string,
  note: shape({}),
};

DeleteNoteConfirmationModal.defaultProps = {
  deleteListing: false,
  setDeleteListing: () => {},
  onDeleteNoteAsThunk: () => {},
  uuid: "",
  note: {},
};
