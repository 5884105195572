import React from "react";
import colors from "../../Reports/components/DashboardShared/helpers/colors";
import { Col } from "../../shared/v1";
import Dropdown from "../../shared/v2/Dropdown";

const AppointmentTypeSelection = ({
  isCreatingAppointment,
  task,
  taskDetails,
  handleAppointmentType,
  appointmentTypeOptions,
}) => {
  return (
    <Col size={6} className="tw-mb-[16px]">
      <Dropdown
        menuShouldComeToFront
        label="Appointment Type"
        isRequired
        name="appointment_type"
        onChange={handleAppointmentType}
        value={appointmentTypeOptions().reduce((p, c) => [...p.options, ...c.options]).find((opt) => opt.value === task.appointment_type) || null}
        options={appointmentTypeOptions()}
        isDisabled={taskDetails ? true : false}
      />
    </Col>
  );
};

export default AppointmentTypeSelection;
