import React from "react";
import PropTypes from "prop-types";
import FieldSearchText from "../../shared/UtilsV1/FieldSearchText";
import ActiveUsersSwitchFilter from "./ActiveUsersSwitchFilter";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

function UsersFilter({ setIsFilterActiveUsers, isFilterActiveUsers, setFilterSearchText, filterSearchText }) {
  const currentUser = useCurrentUser();
  return (
    <div className="tw-flex tw-flex-1 tw-gap-[24px] tw-items-center tw-mr-[24px]">
      {currentUser.account.isAdmin && (
        <ActiveUsersSwitchFilter
          setIsFilterActiveUsers={setIsFilterActiveUsers}
          isFilterActiveUsers={isFilterActiveUsers}
        />
      )}
      <FieldSearchText
        onChange={(value) => setFilterSearchText(value)}
        value={filterSearchText}
        placeholder="Search by user name or email"
        className="settings-users-search-text-filter-input"
        flexStyle="tw-w-[360px]"
      />
    </div>
  );
}

UsersFilter.propTypes = {
  setIsFilterActiveUsers: PropTypes.func.isRequired,
  isFilterActiveUsers: PropTypes.bool.isRequired,
  setFilterSearchText: PropTypes.func.isRequired,
  filterSearchText: PropTypes.string.isRequired,
};

export default UsersFilter;
