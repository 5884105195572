import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bool, func, number, string } from "prop-types";
import moment from "moment";
import TextInput from "@shared/v2/TextInput";
import AiAssistant from "@shared/AiAssistant";
import Modal from "@shared/v2/Modal";
import DatePicker from "@shared/v2/DatePicker";
import Dropdown from "@shared/v2/Dropdown";
import Button from "@shared/v2/Button";
import { postOtherInteractionAsThunk } from "../actions/thunks";
import { OTHER_OPTIONS } from "../api/helpers";

const Other = ({ show, onHide, personId, currentUserId, onPostOtherInteraction, closeModal, uuid }) => {
  const [otherInteraction, setInteraction] = useState({
    interaction_type: OTHER_OPTIONS[0],
    comment: "",
    contingency_rule: {},
    contingent: false,
    externally_visible: false,
    interacted_at: moment(new Date()).format(),
    marked_as_read: true,
    person_id: personId,
    user_id: currentUserId,
    uuid,
  });

  const handleSave = () => {
    try {
      onPostOtherInteraction(
        {
          ...otherInteraction,
          interaction_type: otherInteraction.interaction_type.value,
        },
        uuid,
      );

      setInteraction({
        ...otherInteraction,
        interaction_type: OTHER_OPTIONS[0],
        comment: "",
      });
    } catch (e) {
      // console.log(e)
    }
  };

  useEffect(() => {
    if (closeModal) {
      onHide(false);
    }
  }, [closeModal]);

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      contentClassName="tw-w-[616px]"
      className="tw-flex tw-items-center tw-justify-center"
    >
      <Modal.Header title="Other" />
      <Modal.Body className="tw-py-[32px] tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-flex tw-gap-[12px]">
          <DatePicker
            inputProps={{
              label: "Date",
            }}
            containerClassName="tw-flex-1"
            onChange={(date) => setInteraction({ ...otherInteraction, interacted_at: moment(date).format() })}
          />
          <Dropdown
            label="Type"
            options={OTHER_OPTIONS}
            value={otherInteraction.interaction_type}
            onChange={(e) => setInteraction({ ...otherInteraction, interaction_type: e })}
            containerClassName="tw-flex-1"
          />
        </div>
        <TextInput
          label={
            <div className="tw-w-full tw-flex tw-justify-between tw-items-end">
              <span>Notes</span>
              <AiAssistant
                contactUuid={uuid}
                messageType="Generic"
                onInsertClick={(text) => setInteraction({ ...otherInteraction, comment: text })}
              />
            </div>
          }
          value={otherInteraction.comment}
          onChange={(e) => setInteraction({ ...otherInteraction, comment: e.target.value })}
          name="comment"
          multiline
          rows={5}
        />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between">
        <Button size="medium" schema="tertiary" className="tw-mr-2" onClick={() => onHide(false)}>
          Clear
        </Button>
        <Button size="medium" schema="primary" onClick={() => handleSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Other.propTypes = {
  show: bool,
  onHide: func,
  personId: number,
  currentUserId: number,
  onPostOtherInteraction: func.isRequired,
  closeModal: bool.isRequired,
  uuid: string,
};

Other.defaultProps = {
  show: false,
  onHide: () => {},
  personId: "",
  currentUserId: "",
  uuid: "",
};

const mapStateToProps = (state) => ({
  personId: state.tdpDetailsReducer.person.data.attributes.id,
  currentUserId: state.tdpDetailsReducer.currentUser.id,
  closeModal: state.tdpInteractionsReducer.closeModal,
  uuid: state.tdpDetailsReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onPostOtherInteraction: (data, uuid) => dispatch(postOtherInteractionAsThunk(data, uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Other);
