import React from 'react';
import SharedAutoPlan from './SharedAutoPlan';
import ShareAutoPlanModal from './AcceptSharedPlanModal';
import axios from 'axios';
import DismissibleAlert from '../../shared/DismissibleAlert';
import SharedAutoPlansEmptyState from './SharedAutoPlansEmptyState.png';
import NoSharedPlan from './NoSharedPlan';
import SharedAutoPlans from './SharedAutoPlans';
import './SharedAutoPlans.sass'

export default class SharedAutoPlansApp extends React.Component {
  state = {
    sharedPlans: [],
    isModalOpen: false,
    acceptingPlanId: undefined,
    acceptedPlanName: undefined,
    displayAlert: false
  };

  componentDidMount() {
    this.setState(() => ({
      sharedPlans: this.props.sharedPlans
    }));
  }

  handleOpenModal = (id) => {
    const acceptedPlan = this.state.sharedPlans.find((plan) => {
      return plan.id === id
    });
    this.setState(() => ({
      isModalOpen: true,
      acceptingPlanId: id,
      acceptedPlanName: acceptedPlan.name
    }));
  };

  handleCloseModal = () => {
    this.setState(() => ({
      isModalOpen: false,
      acceptingPlanId: undefined,
      acceptedPlanName: undefined
    }));
  };

  handleDismissAlert = () => {
    this.setState(() => ({
      displayAlert: false,
      acceptedPlanName: undefined
    }));
  };

  handleAcceptPlan = (acceptingPlanId) => {
    const path = `/shared_auto_plans/${acceptingPlanId}/accept`;
    axios.patch(path, { authenticity_token: ReactOnRails.authenticityToken() })
    .then((response) => {
      const updated = this.state.sharedPlans.map((plan) => {
        if (plan.id === acceptingPlanId) {
          return {
            ...plan,
            share_pending: true
          }
        } else {
          return plan;
        }
      });

      this.setState(() => ({
        sharedPlans: updated,
        isModalOpen: false,
        acceptingPlanId: undefined,
        displayAlert: true
      }));
    })
    .catch(err => {
      console.log('The was an error', err);
    });
  };

  render() {
    const { sharedPlans } = this.state;
    const alertMessage = (
      <p>
        The shared Auto Plan <strong>{this.state.acceptedPlanName}</strong> is being added to your account. This can take anywhere from 0-5
        minutes. Check back in a few minutes, or refresh the page until you see the status change to "added". If the
        status hasn't updated after 10 minutes, please contact support by calling 855-427-4848.
      </p>
    );

    return (
      <div>

        <DismissibleAlert
          variant={'info'}
          content={alertMessage}
          displayAlert={this.state.displayAlert}
          handleDismissAlert={this.handleDismissAlert} />
        <div className='brivity-panel'>
          <div className='brivity-panel-header'>
            Shared With Me
          </div>
          <div className='brivity-panel-body'>
            {!sharedPlans.length ? (
              <NoSharedPlan src={SharedAutoPlansEmptyState} />
            ) : (
              <SharedAutoPlans
                sharedPlans={sharedPlans}
                handleOpenModal={this.handleOpenModal}
              />
            )}
            <ShareAutoPlanModal
              isModalOpen={this.state.isModalOpen}
              handleCloseModal={this.handleCloseModal}
              handleAcceptPlan={this.handleAcceptPlan}
              acceptingPlanId={this.state.acceptingPlanId}
              acceptedPlanName={this.state.acceptedPlanName} />
          </div>
        </div>
      </div>
    );
  }
}
