import React from "react";
import PropTypes from "prop-types";

const BasicStat = ({ label, value }) => (
  <div className="tw-min-h-[76px] tw-my-[12px] tw-grow tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-solid tw-border-[1px] tw-border-y-0 tw-border-l-0 tw-border-neutral-gray-10 last-of-type:tw-border-0">
    <div className="tw-text-18d tw-font-normal tw-text-neutral-gray-75 tw-mb-[8px]">{value}</div>
    <div className="tw-text-14d tw-font-semibold tw-text-neutral-gray-50">{label}</div>
  </div>
);

BasicStat.defaultProps = {
  value: "",
};

BasicStat.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BasicStat;
