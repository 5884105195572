/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  isTasksSelectionMode: state.tdpTasksReducer.isTasksSelectionMode,
});

// Wrapping any component with these props from redux ^^
const withTaskSelectionMode = (WrappedComponent) =>
  connect(mapStateToProps)(({ isTasksSelectionMode, ...otherProps }) => (
    <WrappedComponent {...otherProps} isTasksSelectionMode={isTasksSelectionMode} />
  ));

export default withTaskSelectionMode;
