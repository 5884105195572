import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import Typography from "@shared/v2/Typography";
import TextComboInput from "@shared/v2/TextComboInput";
import Checkbox from "@shared/v2/Checkbox";
import { usePost } from "../../reducers/websiteReducer/selectors";
import { updatePost } from "../../reducers/websiteReducer/websiteReducer";

const EditingSlugsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [shouldSkipHelp, setShouldSkipHelp] = useState(
    JSON.parse(localStorage.getItem("website:skipEditingSlugsHelp")) || false,
  );
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const initPost = usePost();
  const [slug, setSlug] = useState(initPost?.slug || "");

  useEffect(() => {
    setSlug(initPost?.slug || "");
  }, [initPost?.slug]);

  useEffect(() => {
    if (open) {
      setPage(shouldSkipHelp ? 2 : 1);
      setSlug(initPost?.slug || "");
    }
  }, [open]);

  useEffect(() => {
    localStorage.setItem("website:skipEditingSlugsHelp", JSON.stringify(shouldSkipHelp));
  }, [shouldSkipHelp]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header
        title={page === 1 ? "Editing Slugs" : "Edit Post Slug"}
        description={
          <>
            <span className="tw-font-semibold">Tip: </span>Make sure your slug and post title match as closely
            as possible for the best SEO.
          </>
        }
        closeButton
      />

      <Modal.Body className="tw-px-[8px] tw-mx-[-8px] tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
        {page === 1 && (
          <>
            <Typography variant="body-m">
              Any links you have shared for this post containing the current slug will not work after edits
              have been made. You can alleviate this issue by adding a page redirect in the website builder.
            </Typography>
            <div>
              <Typography variant="body-m" className="tw-font-semibold">
                Example:
              </Typography>
              <img src="/images/seo-example.png" alt="SEO Example" className="tw-mt-[8px] tw-w-full" />
            </div>
          </>
        )}
        {page === 2 && (
          <TextComboInput
            leading="/"
            value={slug}
            placeholder="link-for-your-page"
            label="Slug"
            isRequired
            autoComplete="off"
            onChange={(e) => setSlug(e.target.value)}
            error={error}
          />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-flex tw-justify-between tw-gap-[8px]">
          <Button schema="tertiary" size="medium" onClick={onClose}>
            Cancel
          </Button>

          {page === 1 && (
            <Checkbox
              label="Don't show me this again"
              checked={shouldSkipHelp}
              onChange={() => setShouldSkipHelp((s) => !s)}
            />
          )}

          <Button
            size="medium"
            onClick={() => {
              if (page === 1) setPage(2);
              else {
                if (!slug) {
                  setError("Slug cannot be empty");
                  return;
                }
                setError("");
                dispatch(updatePost({ ...initPost, slug })).then((state) => {
                  if (state.error) setError(state.payload);
                  else onClose();
                });
              }
            }}
          >
            {page === 1 ? "Edit Slug" : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EditingSlugsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditingSlugsModal;
