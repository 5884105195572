import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Toggle from "../../../shared/v2/Toggle";

const DripInput = ({ onChange, value }) => (
  <div data-cy="drip-input-wrapper" className="tw-mb-[24px] tw-text-[14px]">
    <span className="tw-text-[14px]">
      <Toggle
        id="drip"
        name="drip"
        value={value}
        checked={value === true}
        disabled={false}
        onChange={() => {
          onChange(!value);
        }}
        text="Schedule a Drip Campaign"
      />
    </span>
    <div className="tw-flex tw-mt-[8px]">
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="tw-h-[12px] tw-w-[12px] tw-mr-[8px] tw-mt-[4px] tw-text-neutral-gray-50"
      />
      <p className="tw-flex-1 tw-text-[14px] tw-font-normal tw-text-neutral-gray-50 tw-mb-0">
        A Drip Campaign will send a new Market Report with these search criteria at the selected interval.
      </p>
    </div>
  </div>
);

DripInput.propTypes = {
  value: PropTypes.shape({ label: PropTypes.string }),
  onChange: PropTypes.func,
};

DripInput.defaultProps = {
  value: null,
  onChange: () => {},
};

export default DripInput;
