/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleInfoRegularV6 = (props) => (
  <Icon {...props} name="Circle Info Regular V6">
    <path d="M8 0C3.581 0 0 3.581 0 8s3.581 8 8 8c4.419 0 8-3.581 8-8s-3.581-8-8-8zm0 14.5c-3.584 0-6.5-2.916-6.5-6.5S4.416 1.5 8 1.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm1.25-4h-.5V7.75A.75.75 0 0 0 8 7H7c-.412 0-.75.338-.75.75s.338.75.75.75h.25v2h-.5c-.412 0-.75.338-.75.75s.338.75.75.75h2.5a.75.75 0 0 0 0-1.5zM8 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
  </Icon>
);

export default CircleInfoRegularV6;
