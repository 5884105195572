import React from "react";
import FieldLabelV1 from "./FieldLabelV1";
import TextInput from "../v2/TextInput";

function FieldPhoneV1({
  className = "",
  placeholder = "",
  onChange,
  required,
  label,
  value = "",
  flexStyle = "tw-flex-1 ",
  disabled,
}) {
  return (
    <div className={flexStyle} data-cy={`${label}`}>
      <TextInput
        label={label}
        isRequired={required}
        placeholder={placeholder}
        className={className}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        value={value}
      />
    </div>
  );
}

export default FieldPhoneV1;
