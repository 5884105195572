import * as actionCreators from "../Financials/actions/account";
import {
  reAssignExpensePositions,
  shiftSplitPosition,
} from "../Financials/components/shared/FinancialViewHelpers";

const defaultState = {
  expenseType: "",
  isModalOpen: false,
  isShowAlert: false,
  isShowDangerAlert: false,
  accountData: {},
  activeAgentExpense: {},
  errors: "",
  isShowEdit: false,
};

const accountFinancialReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.destroyAgentExpense().type:
      const agentsWithExpToDestroy = state.accountData.agent_expense_templates.agent_expenses;
      const agentWithExpToDestroy = agentsWithExpToDestroy[payload.agentPosition];
      const expenses = agentWithExpToDestroy.custom_agent_expenses_attributes;
      const idxToDestroy = expenses.findIndex((exp) => exp.position == payload.expPosition);

      expenses.push(expenses.splice(idxToDestroy, 1)[0]);
      expenses[expenses.length - 1]._destroy = "1";
      delete expenses[expenses.length - 1].position;

      shiftSplitPosition(agentWithExpToDestroy, payload.expPosition);
      reAssignExpensePositions(agentWithExpToDestroy);

      return {
        ...state,
        accountData: {
          ...state.accountData,
          agent_expense_templates: {
            ...state.accountData.agent_expense_templates,
            agent_expenses: agentsWithExpToDestroy,
          },
        },
      };

    case actionCreators.setAgentExpenseData().type:
      const agents = state.accountData.agent_expense_templates.agent_expenses;
      const expense = agents[payload.agentPosition].custom_agent_expenses_attributes.find(
        (exp) => exp.position == payload.expPosition,
      );
      expense[payload.attr] = payload.data;

      return {
        ...state,
        accountData: {
          ...state.accountData,
          agent_expense_templates: {
            ...state.accountData.agent_expense_templates,
            agent_expenses: agents,
          },
        },
      };

    case actionCreators.setAgentBrokerageData().type:
      const agents_expenses = state.accountData.agent_expense_templates.agent_expenses;
      agents_expenses[payload.agentPosition][payload.attr] = payload.data;

      return {
        ...state,
        accountData: {
          ...state.accountData,
          agent_expense_templates: {
            ...state.accountData.agent_expense_templates,
            agents_expenses,
          },
        },
      };

    case "OPEN_NEW_EXPENSE_MODAL":
      return {
        ...state,
        isModalOpen: true,
      };

    case "CLOSE_NEW_EXPENSE_MODAL":
      return {
        ...state,
        isModalOpen: false,
        showInstructions: false,
        hasError: false,
        errorMessage: undefined,
      };

    case "SHOW_ALERT":
      return {
        ...state,
        isShowAlert: true,
        isShowDangerAlert: false,
        errors: null,
      };

    case "HIDE_ALERT":
      return {
        ...state,
        isShowAlert: false,
      };

    case "SHOW_DANGER_ALERT":
      return {
        ...state,
        isShowDangerAlert: true,
      };

    case "HIDE_DANGER_ALERT":
      return {
        ...state,
        isShowDangerAlert: false,
      };

    case "EXPENSE_TYPE_AGENT":
      return {
        ...state,
        expenseType: "agent",
      };

    case "EXPENSE_TYPE_OFFICE":
      return {
        ...state,
        expenseType: "office",
      };

    case "EXPENSE_TYPE_COMPANY":
      return {
        ...state,
        expenseType: "company",
      };

    case "ACCOUNT_DATA":
      return {
        ...state,
        accountData: { ...payload.data },
      };

    case "ERR_DATA":
      return {
        ...state,
        errors: payload.errors,
      };

    case "ACTIVE_AGENT_EXPENSE":
      return {
        ...state,
        activeAgentExpense: { ...payload.data },
      };

    case "SHOW_EDIT":
      return {
        ...state,
        isShowEdit: true,
      };

    case "HIDE_EDIT":
      return {
        ...state,
        isShowEdit: false,
      };

    default:
      return state;
  }
};

export default accountFinancialReducer;
