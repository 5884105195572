import React, { useEffect } from "react";
import Input from "../../shared/Input";
import axios from "axios";

const EmailAutocomplete = (props) => {
  useEffect(() => {
    let cancelled = false;

    const search = async () => {
      const maxSearchLength = 100;

      if (!props.term.length || props.term.length > maxSearchLength) {
        return;
      }

      const response = await axios.get(`/interactions/interactable_emails?search=${props.term}`);

      if (!cancelled) {
        const searchResults = response.data.map((d) => ({
          personId: d.person_id,
          email: d.id,
          fullName: d.full_name,
          label: d.label,
          status: d.status,
          type: d.type,
        }));
        props.setSearchResults(searchResults);
      }
    };

    search();
    return () => {
      cancelled = true;
    };
  }, [props.term]);

  const handleKeyDown = async (e) => {
    // Enter key
    if (e.keyCode === 13) {
      e.preventDefault();
      await props.selectResult(props.searchResults[props.childPosition]);
    } else if (e.keyCode === 38) {
      // Navigate up
      if (props.childPosition > 0) {
        props.setChildPosition(props.childPosition - 1);
      }
    } else if (e.keyCode === 40) {
      // Navigate down
      if (props.childPosition < props.searchResults.length - 1) {
        props.setChildPosition(props.childPosition + 1);
      }
    } else if (e.keyCode === 8 && props.term.length === 1) {
      // Removing last character with backspace
      props.setSearchResults([]);
      props.setChildPosition(0);
    }
  };

  return (
    <Input
      autoComplete="no"
      value={props.term}
      type="text"
      className="tw-w-full tw-mb-3 tw-border tw-border-solid tw-border-gray-15 tw-rounded-2px tw-px-12px tw-py-8px tw-bg-tinted-gray-50"
      onChange={(e) => props.setTerm(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={props.placeholder}
    />
  );
};

export default EmailAutocomplete;
