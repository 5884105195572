import React from "react";
import PropTypes from "prop-types";
import TextComboInput from "../TextComboInput";
import { Link } from "../Icomoon";
import Button from "../Button";

const LinkField = ({
  placeholder,
  hideActions,
  actionLabel,
  onActionButtonClick,
  secondaryActionLabel,
  onSecondaryActionButtonClick,
  className,
  ...otherProps
}) => {
  const leading = (
    <div className="tw-flex tw-flex-row tw-items-center tw-space-x-[12px]">
      {/*
        Not sure why ESLint considers this to be an anchor.
        Is it because it's called Link? There is no `a` element in the DOM tree.
        That's why we just ignored this ESLint rule here.
      */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link size="l" className="tw-text-neutral-gray-50 group-focus-within:tw-text-theme-link-field-icon" />
      <span className="tw-w-[1px] tw-h-[34px] tw-border-l tw-border-r-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-neutral-gray-15" />
    </div>
  );

  const hasSecondaryAction = secondaryActionLabel && onSecondaryActionButtonClick;

  const trailing = !hideActions ? (
    <>
      <Button className="tw-mr-[2px]" onClick={onActionButtonClick}>
        {actionLabel}
      </Button>

      {hasSecondaryAction && (
        <Button schema="secondary" className="tw-ml-[2px] tw-mr-[2px]" onClick={onSecondaryActionButtonClick}>
          {secondaryActionLabel}
        </Button>
      )}
    </>
  ) : null;

  return (
    <TextComboInput
      className={`tw-group !tw-bg-white !tw-rounded-[4px] ${className}`}
      leading={leading}
      trailing={trailing}
      placeholder={placeholder}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

LinkField.propTypes = {
  placeholder: PropTypes.string,
  hideActions: PropTypes.bool,
  actionLabel: PropTypes.node.isRequired,
  onActionButtonClick: PropTypes.func.isRequired,

  secondaryActionLabel: PropTypes.node,
  onSecondaryActionButtonClick: PropTypes.func,

  className: PropTypes.string,
};

LinkField.defaultProps = {
  placeholder: "Paste your link here",
  className: "",
  hideActions: false,
  secondaryActionLabel: null,
  onSecondaryActionButtonClick: null,
};

export default LinkField;
