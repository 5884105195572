import React from "react";
import { connect } from "react-redux";

import { upperFirst } from "lodash";

//ex. WrappedComponent = Header, actions = [getPostTitleAsThunk] || getPostTitleAsThunk
export default ({ WrappedComponent = null, actions = [], aliases = [] }) => {
  const actionz = Array.isArray(actions) ? actions : [actions];
  const arrayOfAliases = Array.isArray(aliases) ? aliases : [aliases];

  const mapDispatchToProps = (dispatch) => actionz.reduce((acc, action, idx) => ({
    ...acc,
    [arrayOfAliases[idx] || `on${upperFirst(action.name)}`]: function() {
      dispatch(action(...arguments))
    },
  }), {});

  return connect(null, mapDispatchToProps)(
    ({ ...allProps }) => <WrappedComponent {...allProps} />
  );
}
