const activateWithTempPassword = {
  endpoint: ({ accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_activate_with_temp_password`,
  successMessage: "Activated a user and set their password to \"Brivity1\".",
};

const clearOktaEmailBounce = {
  endpoint: ({ accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_clear_okta_email_bounce`,
  successMessage: "Cleared the bounce record from Okta (if one existed)"
}

const initializeUserGroups = {
  endpoint: ({ accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_initialize_user_groups`,
  successMessage: "Added user to CRM Users, and PLACE User groups if applicable. (does not remove groups)"
};

const runProvisionThinkificAccounts = {
  endpoint: ({accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_provision_thinkific_accounts`,
  successMessage: "User Provisioning Started"
}

const runOktaImport = {
  endpoint: ({ accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_okta_import`,
  successMessage: "Okta Import started.",
};

const setTemporaryPassword = {
  endpoint: ({ accountId, userId }) => `/support/accounts/${accountId}/users/${userId}/run_set_temporary_password`,
  successMessage: "User's password set to \"Brivity1\".",
}

const Jobs = {
  "Activate a user with a temporary password": activateWithTempPassword,
  "Clear the Okta email bounce": clearOktaEmailBounce,
  "Initialize User Groups": initializeUserGroups,
  "Provision Thinkific Accounts": runProvisionThinkificAccounts,
  "Run Okta Import": runOktaImport,
  "Set Temporary Password": setTemporaryPassword
};

export default Jobs;
