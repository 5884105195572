import React from "react";
import Proptypes from "prop-types";
import { Col, Row } from "../../../../shared/v1";
import ProfileIcon from "../ProfileIcon";
import { getImglink } from "../api/helpers";

const Cards = ({ name, numberOfPages, createdBy, coverImage }) => (
  <Col className="tw-m-0 tw-mx-[0px] tw-p-0 tw-py-[0px] tw-px-[0px] tw-overflow-hidden">
    <Row className="tw-m-0 tw-mx-[0px] tw-flex tw-items-center	tw-p-0 tw-px-[16px] tw-py-[12px] tw-bg-gray-lightest">
      <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 " size={numberOfPages ? 6 : 10}>
        <div className="tw-truncate tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 ">
          {numberOfPages && "Listed:"} {name}
        </div>
      </Col>
      <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={1}>
        &nbsp;
      </Col>
      <Col
        className={`tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 ${!numberOfPages && "tw-float-right"}`}
        size={2}
      >
        <ProfileIcon
          className="tw-m-[0px]"
          imglink={getImglink(createdBy.avatar_collection)}
          name={"Created by ".concat(createdBy.first_name, " ", createdBy.last_name)}
          initials={createdBy.initials}
        />
      </Col>
      {numberOfPages && (
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={3}>
          <div className=" tw-leading-[16px] tw-text-[11px] tw-font-bold tw-text-gray-75 ">
            PAGES:
            <span className="tw-break-words tw-pl-[8px] tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-75 ">
              {numberOfPages}
            </span>
          </div>
        </Col>
      )}
    </Row>
    <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-p-[24px] tw-flex ">
      <Col
        className="tw-py-[0px] tw-px-[0px] tw-h-[180px] tw-max-w-full tw-block tw-transition tw-ease-out tw-delay-300 tw-drop-shadow-19 
          tw-m-0 tw-mx-[0px] tw-p-0 tw-h-[180px]"
        size={12}
      >
        <img
          alt="coverImage"
          className="tw-rounded tw-border-solid tw-border tw-border-gray-15 tw-block tw-h-[180px] tw-max-w-full tw-w-full tw-cursor-default"
          src={coverImage}
        />
      </Col>
    </Row>
  </Col>
);

Cards.propTypes = {
  name: Proptypes.string,
  numberOfPages: Proptypes.number,
  createdBy: Proptypes.shape({
    avatar_collection: Proptypes.shape({}),
    imglink: Proptypes.string,
    first_name: Proptypes.string,
    last_name: Proptypes.string,
    initials: Proptypes.string,
  }),
  coverImage: Proptypes.string,
};
Cards.defaultProps = {
  name: null,
  numberOfPages: undefined,
  createdBy: { avatar_collection: {}, imglink: "", first_name: "", last_name: "", initials: "" },
  coverImage: "",
};

export default Cards;
