import { schemaGenerator } from "../utils";

const base = {
  default: [
    "tw-font-regular",
    "tw-border-solid",
    "tw-border",
    "tw-rounded-[4px]",
    "tw-text-left",
    "tw-text-14px",
    "tw-px-12px",
    "tw-text-neutral-gray-75",
    "tw-bg-theme-text-input",
  ],
  hover: ["hover:tw-bg-theme-text-input-hover"],
  focusWithin: [
    "focus-within:tw-bg-white",
    "focus-within:hover:tw-bg-white",
    "focus-within:tw-outline-none",
    "focus-within:tw-ring-[1.5px]",
    "focus-within:tw-ring-theme-text-input-active",
  ],
  disabled: ["disabled:tw-text-neutral-gray-30", "disabled:tw-bg-theme-text-input-disabled"],
};

const dictionary = {
  regular: {
    default: [...base.default, "tw-border-theme-text-input"],
    hover: [...base.hover, "hover:tw-border-theme-text-input-hover"],
    focusWithin: [
      ...base.focusWithin,
      "focus-within:tw-border-theme-text-input-active",
      "focus-within:hover:tw-border-theme-text-input-active",
      "focus-within:tw-ring-offset-0",
    ],
    disabled: [...base.disabled, "disabled:tw-border-theme-text-input-disabled"],
  },
  error: {
    default: [...base.default, "tw-border-semantic-red-25"],
    hover: [...base.hover, "hover:tw-border-semantic-red-50"],
    focusWithin: [
      ...base.focusWithin,
      "focus-within:tw-border-semantic-red-100",
      "focus-within:tw-ring-semantic-red-10",
      "tw-ring-offset-0",
    ],
    disabled: [...base.disabled, "disabled:tw-border-neutral-gray-15"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;

const spacingDictionary = {
  0: " ",
  1: "tw-space-x-[1px]",
  2: "tw-space-x-[2px]",
  3: "tw-space-x-[3px]",
  4: "tw-space-x-[4px]",
  5: "tw-space-x-[5px]",
  6: "tw-space-x-[6px]",
  7: "tw-space-x-[7px]",
  8: "tw-space-x-[8px]",
  9: "tw-space-x-[9px]",
  10: "tw-space-x-[10px]",
  11: "tw-space-x-[11px]",
  12: "tw-space-x-[12px]",
  13: "tw-space-x-[13px]",
  14: "tw-space-x-[14px]",
};

export const spacingValue = (spacing) => spacingDictionary[spacing] || spacingDictionary[14];
