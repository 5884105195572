import React from "react";
import { func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID, TRANSACTION_TITLE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { putRefTitle } from "../actions/thunks";

import DetailInput from "../DetailInput";

const ReferralTitle = ({ title, onPutRefTitle, uuid }) => (
  <DetailInput
    dataCy="RefTitle"
    label="Transaction Name"
    schema="text"
    val={title}
    onBlur={(val) => onPutRefTitle(uuid, val)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralTitle,
  states: [LISTING_UUID, TRANSACTION_TITLE],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefTitle,
  aliases: "onPutRefTitle",
});

ReferralTitle.propTypes = {
  title: string,
  onPutRefTitle: func,
  uuid: string,
};

ReferralTitle.defaultProps = {
  title: null,
  onPutRefTitle: () => {},
  uuid: null,
};
