import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as actionCreators from "../actions/thunks";
import IconButton from "../../../../shared/v2/IconButton";
import TextButton from "../../../../shared/v2/TextButton";
import TrackProgressBar from "./TrackProgressBar";
import Check from "../../../../shared/v2/Icomoon/Icons/Check";
import EllipsisIcon from "../../../../Transactions/components/icons/EllipsisIcon";
import EditListing from "./EditListing";
import errorShape from "../model/error";
import Error from "../Error";

const ListingViews = ({
  viewReportURL,
  shareLink,
  getListingViewsAsThunk,
  getMetricsError,
  uuid,
  listingViews,
}) => {
  const [copied, setCopied] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  useEffect(() => {
    getListingViewsAsThunk(uuid);
  }, []);
  const copyToClipboard = () => {
    setCopied(true);
    navigator.clipboard.writeText(shareLink);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <>
      {getMetricsError ? (
        <Error message={getMetricsError.message} />
      ) : (
        <div className="tw-mt-[40px] tw-overflow-x-auto tw-overflow-y-hidden">
          <div className=" tw-leading-[20px] tw-pb-[18px] tw-text-[14px] tw-font-normal tw-text-gray-75">
            Listing views: {listingViews?.views?.total || 0}
            <div className="tw-flex tw-justify-end tw-float-right">
              <TextButton
                onClick={() => window.open(viewReportURL, "_blank")}
                size="small"
                className="tw-border-r tw-border-gray-10"
              >
                View Report
              </TextButton>
              <TextButton onClick={copyToClipboard} size="small" className="tw-pr-[16px]">
                {copied ? (
                  <>
                    Copied <Check size="m" className=" tw-pl-[4px]" />
                  </>
                ) : (
                  "Share Link"
                )}
              </TextButton>
              <IconButton size="small" schema="tertiary" onClick={() => setModalOpen(true)}>
                <EllipsisIcon />
              </IconButton>
            </div>
          </div>
          <TrackProgressBar />
        </div>
      )}

      <EditListing modalOpen={ModalOpen} closeModal={closeModal} />
    </>
  );
};

ListingViews.propTypes = {
  uuid: Proptypes.string.isRequired,
  shareLink: Proptypes.string,
  viewReportURL: Proptypes.string,
  getListingViewsAsThunk: Proptypes.func.isRequired,
  getMetricsError: errorShape,
  listingViews: Proptypes.shape({
    views: Proptypes.shape({
      total: Proptypes.number,
      breakdown: Proptypes.shape({
        brivity: Proptypes.number,
        zillow: Proptypes.number,
      }),
    }),
  }),
};
ListingViews.defaultProps = {
  getMetricsError: null,
  shareLink: "",
  viewReportURL: "",
  listingViews: {
    views: {
      total: 0,
      breakdown: {
        brivity: 0,
        zillow: 0,
      },
    },
  },
};
const mapStateToProps = (state) => ({
  listingViews: state.tdpMarketingReducer.listingViews,
  viewReportURL: state.tdpMarketingReducer.viewReportURL,
  uuid: state.tdpMarketingReducer.uuid,
  getMetricsError: state.tdpMarketingReducer.getMetricsError,
});

const mapDispatchToProps = (dispatch) => ({
  getListingViewsAsThunk: (uuid) => dispatch(actionCreators.getListingViewsAsThunk(uuid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListingViews);
