import { ChevronRightSolidV6, ChevronUpSolidV6 } from "@shared/v2/Icomoon";
import React, { useState } from "react";
import { valueOrDash } from "./helpers";

const CallInfo = ({ call, includeBorder = true }) => {
  const [areNotesVisible, setAreNotesVisible] = useState(false);

  const outcome = call.outcome === "talked_to" ? "Talked" : "Missed Call";
  const preposition = call.outcome === "talked_to" ? "with" : "from";

  return (
    <div className={`tw-flex tw-flex-col`}>
      <div className="tw-flex tw-flex-row tw-gap-12px tw-items-center tw-h-[68px]">
        <div className="tw-cursor-pointer" onClick={() => setAreNotesVisible(!areNotesVisible)}>
          {areNotesVisible ? <ChevronUpSolidV6 /> : <ChevronRightSolidV6 />}
        </div>
        <div className="tw-flex tw-flex-col">
          <span className={`tw-text-14px tw-text-gray-75 ${call.name ? "" : "tw-italic"}`}>
            {call.name || "No Name"}
          </span>
          <span className="tw-text-12px tw-text-gray-75">{call.phoneDisplay}</span>
        </div>
        <div className="tw-flex tw-flex-col tw-ml-auto tw-items-end">
          <div className="tw-text-14px">
            <span className="tw-font-semibold tw-text-gray-75">{outcome}</span>{" "}
            <span className="tw-font-normal tw-text-gray-50">{preposition}</span>{" "}
            <span className="tw-font-semibold tw-text-theme-primary">{call.agent}</span>
          </div>
          <span className="tw-text-12px tw-text-gray-75">
            {call.durationDisplay} on {call.timestampDisplay}
          </span>
        </div>
      </div>
      {areNotesVisible && (
        <div className="tw-flex tw-flex-col tw-text-12px tw-ml-20px tw-gap-6px tw-mb-16px">
          <span className="tw-font-semibold">Notes:</span>
          <p className="!tw-m-0 tw-whitespace-pre-line">{valueOrDash(call.notes)}</p>
        </div>
      )}
      {includeBorder && <hr className="tw-w-full !tw-m-0" />}
    </div>
  );
};

export default CallInfo;
