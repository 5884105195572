/* eslint-disable react/prop-types, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, no-underscore-dangle */

import React, { useState } from "react";
import Button from "../../shared/v2/Button";
import Modal from "../../shared/v2/Modal";
import TextButton from "../../shared/v2/TextButton";
import AgentsFilter from "./shared/Filters/AgentsFilter";
import CloseDateRangeFilter from "./shared/Filters/CloseDateRangeFilter";
import SourceFilter from "./shared/Filters/SourceFilter";
import StatusChangeRangeFilter from "./shared/Filters/StatusChangeDateFilter";
import FilterInlineOptions from "./FilterInlineOptions";

const FiltersModal = ({
  initialFilters,
  isModalShown,
  onModalClose,
  initialTypes,
  initialAgents,
  initialPublishedStates,
  initialStatuses,
  initialSources,
  dateRangeOptions,
  statusDateOptions,
  onClearAll,
  onFilter,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const setAgents = (agents) => {
    setFilters({ ...filters, agent: agents });
  };

  const setType = (types) => {
    setFilters({
      ...filters,
      type: types.filter((f) => f.isActive).map((f) => f.id),
    });
  };

  const setPublishedState = (publishedStates) => {
    setFilters({
      ...filters,
      publishedState: publishedStates.filter((f) => f.isActive).map((f) => f.id),
    });
  };

  const setStatus = (statuses) => {
    setFilters({
      ...filters,
      status: statuses.filter((f) => f.isActive).map((f) => f.id),
    });
  };

  const setSource = (sources) => {
    setFilters({ ...filters, source: sources });
  };

  const setCloseDate = (rangeId) => {
    setFilters({ ...filters, closeDateRange: rangeId ? [rangeId] : [] });
  };

  const setStatusRange = (rangeId) => {
    setFilters({ ...filters, statusChangedRange: rangeId ? [rangeId] : [] });
  };

  const setStatusDate = (dates) => {
    setFilters({ ...filters, statusChangedDates: dates || [] });
  };

  const _filters = { ...initialFilters };
  // eslint-disable-next-line no-return-assign
  Object.keys(_filters).forEach((k) => (_filters[k] = new Set(filters[k].map((x) => x))));
  const usedStatuses = initialStatuses.map((item) => ({ ...item, isActive: _filters.status.has(item.id) }));
  const usedPublishedStates = initialPublishedStates.map((item) => ({
    ...item,
    isActive: _filters.publishedState.has(item.id),
  }));
  const usedTypes = initialTypes.map((item) => ({ ...item, isActive: _filters.type.has(item.id) }));
  const initialCloseDateRange = Array.from(_filters.closeDateRange)[0];
  const initialStatusChangedRange = Array.from(_filters.statusChangedRange)[0];
  const initialStatusChangedDates = Array.from(_filters.statusChangedDates);

  return (
    <Modal show={isModalShown} onHide={onModalClose} contentClassName="tw-w-[662px] tw-m-auto">
      <div data-cy="filter-modal">
        <Modal.Header title="Filter" closeButton />
        <Modal.Body className="tw-overflow-y-auto tw-max-h-[75vh]">
          <div className="tw-w-full tw-space-y-24px tw-mt-32px">
            <div data-cy="agents-filter-section">
              <AgentsFilter
                initialAgents={initialAgents}
                onAgentsChange={setAgents}
                agentIds={filters.agent}
              />
            </div>
            <FilterInlineOptions
              initialStatuses={usedStatuses}
              onStatusesChange={setStatus}
              initialPublishedStates={usedPublishedStates}
              onPublishedStatesChange={setPublishedState}
              initialTypes={usedTypes}
              onTypesChange={setType}
            />
            <div>
              <SourceFilter
                initialSources={initialSources}
                onSourceChange={setSource}
                sourceIds={filters.source}
              />
            </div>
            <div>
              <CloseDateRangeFilter
                initiallySelectedDateRange={initialCloseDateRange}
                dateRangeOptions={dateRangeOptions}
                onDateRangeChange={setCloseDate}
              />
            </div>
            <div>
              <StatusChangeRangeFilter
                initiallySelectedOption={initialStatusChangedRange}
                initiallySelectedDateRange={initialStatusChangedDates}
                dateRangeOptions={statusDateOptions}
                onChangeRange={setStatusRange}
                onChangeDates={setStatusDate}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-align-bottom tw-justify-between tw-mt-48px">
          <Button schema="tertiary" size="medium" onClick={() => onModalClose()}>
            CANCEL
          </Button>
          <div>
            <TextButton className="tw-mr-16px" size="medium" onClick={() => onClearAll()}>
              CLEAR ALL
            </TextButton>
            <Button size="medium" onClick={() => onFilter(filters)}>
              APPLY
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default FiltersModal;
