import React from 'react';

const MatchingResults = (props) => {
  const { showUrl, count, url } = props
  return (
    <div className="pull-right tw-mt-10px tw-mr-12px">
      { showUrl ?
        <a className="tw-text-theme-primary tw-font-semibold tw-no-underline" href={url} target="_blank">VIEW {count} LISTING{count === 1 ? "" : "S"}</a>
        :
        "Add search terms or map area to preview listings"
      }
    </div>
  )
}

export default MatchingResults;
