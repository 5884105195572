import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@shared/v2/Checkbox";

const CustomCheckboxOption = ({
  children,
  onClick,
  showDivider,
  componentProps: { data, isDisabled, isSelected },
}) => (
  <>
    <button
      className="tw-bg-white tw-border-0 tw-w-full tw-text-left tw-py-[4px] tw-px-[6px] tw-cursor-pointer hover:tw-bg-gray-5"
      type="button"
      onClick={(e) => {
        onClick(data, !isSelected);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Checkbox value={data.value} disabled={isDisabled} checked={isSelected} label={children} readOnly />
    </button>
    {showDivider && <hr className="tw-m-0 tw-border-gray-10" />}
  </>
);

CustomCheckboxOption.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  showDivider: PropTypes.bool,
  componentProps: PropTypes.shape({
    data: PropTypes.shape({ value: PropTypes.string, name: PropTypes.string }),
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
  }).isRequired,
};

CustomCheckboxOption.defaultProps = {
  showDivider: false,
};

export default CustomCheckboxOption;
