import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Pagination from "@shared/v2/Pagination";
import TabBar from "@shared/v2/TabBar";
import Tab from "@shared/v2/Tab";
import TextButton from "@shared/v2/TextButton";
import { Section } from "../BusinessDashboard";
import { Table, TBody, TD, TH, THead, TR } from "../BusinessDashboard/Table/Table";
import useOpenHouseDashboardList from "./hooks/useOpenHouseDashboardList";
import OpenHouseFlyout from "./OpenHouseFlyout";
import Loading from "../../../Goals/components/Loader";
import openLeadIndexFromTagName from "./helpers/openLeadIndexFromTagName";

const useUpcomingCount = ({ dateRange, selectedHosts }) => {
  const [upcomingCount, setUpcomingCount] = useState(0);

  useEffect(() => {
    if (!dateRange || selectedHosts.length === 0) return;
    const hostPersonUuids = selectedHosts.map((host) => host.value);
    caseTransformingAxios
      .post("/api/v4/reporting/open_house_dashboard/upcoming_count", { hostPersonUuids, ...dateRange })
      .then((res) => setUpcomingCount(res.data.data || 0))
      .catch(console.log);
  }, [dateRange, selectedHosts]);

  return upcomingCount;
};

const OpenHouseDashboardList = ({ dateRange, selectedHosts, onEdit }) => {
  const [status, setStatus] = useState("completed");
  const [openHouse, setOpenHouse] = useState(null);
  const { actions, data, loading } = useOpenHouseDashboardList({ dateRange, selectedHosts, status });
  const upcomingCount = useUpcomingCount({ dateRange, selectedHosts });

  useEffect(() => {
    if (!data.list?.find((oh) => oh.uuid === openHouse?.uuid)) {
      setOpenHouse(null);
    } else {
      setOpenHouse({ uuid: openHouse.uuid });
    }
  }, [data.list]);

  return (
    <Section className="tw-gap-[16px]">
      <div className="tw-mt-[-12px]">
        <TabBar>
          <Tab label="Completed" selected={status === "completed"} onClick={() => setStatus("completed")} />
          <Tab
            label={
              <span className="tw-flex tw-items-center tw-gap-[4px]">
                Upcoming
                {upcomingCount > 0 && (
                  <div className="tw-flex tw-justify-center tw-items-center tw-px-[4px] tw-min-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-rounded-full">
                    {upcomingCount}
                  </div>
                )}
              </span>
            }
            selected={status === "upcoming"}
            onClick={() => setStatus("upcoming")}
          />
        </TabBar>
      </div>
      <Table>
        <THead>
          <TR className="tw-border-x-0">
            <TH className="tw-border-l-0 tw-py-[8px] tw-font-semibold">Address</TH>
            <TH className="tw-py-[8px] tw-font-semibold">Date & Time</TH>
            <TH className="tw-py-[8px] tw-font-semibold">Host</TH>
            <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Attendees</TH>
            <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Leads</TH>
          </TR>
        </THead>
        <TBody>
          {data.list?.map(
            ({
              address,
              attendees,
              hostName,
              leads,
              startDate,
              startTime,
              endTime,
              uuid,
              activeAt,
              completedAt,
            }) => (
              <TR
                key={uuid}
                className="tw-border-x-0 hover:tw-bg-[#fafafa] active:tw-bg-gray-5 tw-transition-colors tw-ease-out tw-cursor-pointer"
                onClick={() => setOpenHouse({ uuid })}
              >
                <TD>
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-font-semibold">{address.streetAddress}</span>
                    <span className="tw-text-gray-50">
                      {address.city}, {address.state} {address.postalCode}
                    </span>
                  </div>
                </TD>
                <TD>
                  <div className="tw-flex tw-flex-col">
                    <span>{format(parse(startDate, "yyyy-MM-dd", new Date()), "EEE. MM/dd/yyyy")}</span>
                    <span className="tw-text-gray-50">
                      {activeAt && !completedAt ? (
                        <span className="tw-text-semantic-yellow-100">In Progress</span>
                      ) : (
                        `${format(parse(startTime, "HH:mm:ss", new Date()), "hh:mm a")} - ${format(parse(endTime, "HH:mm:ss", new Date()), "hh:mm a")}`
                      )}
                    </span>
                  </div>
                </TD>
                <TD>{hostName}</TD>
                <TD className="tw-text-center">{attendees}</TD>
                <TD className="tw-text-center">
                  {leads > 0 ? (
                    <TextButton
                      className="!tw-font-normal"
                      size="medium"
                      onClick={openLeadIndexFromTagName(address.streetAddress)}
                    >
                      {leads}
                    </TextButton>
                  ) : (
                    leads
                  )}
                </TD>
              </TR>
            ),
          )}
        </TBody>
      </Table>

      {data.totalPages > 1 && (
        <div className="tw-flex tw-justify-center">
          <Pagination
            currentPage={data.page}
            totalPages={data.totalPages}
            onGoToPage={(p) => actions.setPage(p)}
          />
        </div>
      )}

      {loading && <Loading />}

      <OpenHouseFlyout
        openHouse={openHouse}
        onClose={() => setOpenHouse(null)}
        status={status}
        onEdit={onEdit}
      />
    </Section>
  );
};

OpenHouseDashboardList.propTypes = {
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  selectedHosts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default OpenHouseDashboardList;
