import React from "react";
import PropTypes from "prop-types";
import Rating from "../Rating";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import IconButton from "../../../../shared/v2/IconButton";

const FeedbackHeader = ({ reviewCount, averageRating, showNewFeedbackForm }) => (
  <div
    className="tw-flex tw-flex-row tw-flex-nowrap tw-justify-between tw-items-center"
    data-cy="feedback-header"
  >
    <span className="tw-font-bold tw-text-18d tw-text-neutral-gray-75" data-cy="feedback-header-title">
      Showing Feedback
    </span>
    <span className="tw-inline-flex tw-flex-row tw-items-center">
      <span
        className="tw-font-semibold tw-text-14d tw-text-neutral-gray-75"
        data-cy="feedback-header-review-count"
      >
        {reviewCount} Reviews
      </span>
      <span className="tw-w-1px tw-h-24px tw-border-l tw-border-r-0 tw-border-t-0 tw-border-b-0 tw-border-solid tw-border-neutral-gray-10 tw-ml-8px" />
      <span className="tw-ml-8px tw-mt-auto tw-mb-auto tw-h-16px">
        <Rating rating={averageRating} size="l" />
      </span>
      <IconButton
        size="small"
        schema="tertiary"
        className="tw-ml-24px"
        onClick={showNewFeedbackForm}
        data-cy="feedback-header-add-feedback-button"
      >
        <Plus />
      </IconButton>
    </span>
  </div>
);
FeedbackHeader.propTypes = {
  reviewCount: PropTypes.number,
  averageRating: PropTypes.number,
  showNewFeedbackForm: PropTypes.func.isRequired,
};

FeedbackHeader.defaultProps = {
  reviewCount: 0,
  averageRating: 0.0,
};

export default FeedbackHeader;
