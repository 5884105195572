export const fetchAllFeedbacksStarted = () => ({
  type: "FEEDBACKS_FETCH_STARTED",
});

export const fetchAllFeedbacksSuccess = (feedbacks, meta) => ({
  type: "FEEDBACKS_FETCH_SUCCESS",
  feedbacks,
  meta,
});

export const fetchAllFeedbacksFailure = (error) => ({
  type: "FEEDBACKS_FETCH_FAILURE",
  error,
});

export const loadMoreFeedbacksStarted = () => ({
  type: "FEEDBACKS_LOAD_MORE_STARTED",
});

export const loadMoreFeedbacksSuccess = (feedbacks, meta) => ({
  type: "FEEDBACKS_LOAD_MORE_SUCCESS",
  feedbacks,
  meta,
});

export const loadMoreFeedbacksFailure = (error) => ({
  type: "FEEDBACKS_LOAD_MORE_FAILURE",
  error,
});

export const updateAllFeedbacksStats = (meta) => ({
  type: "FEEDBACKS_UPDATE_STATS",
  meta,
});

export const setFeedbackFormData = (formData) => ({
  type: "FEEDBACKS_SET_FORM_DATA",
  formData,
});

export const createFeedbackStarted = () => ({
  type: "FEEDBACKS_CREATE_STARTED",
});

export const createFeedbackSuccess = (feedback) => ({
  type: "FEEDBACKS_CREATE_SUCCESS",
  feedback,
});

export const createFeedbackFailure = (error) => ({
  type: "FEEDBACKS_CREATE_FAILURE",
  error,
});

export const updateFeedbackStarted = () => ({
  type: "FEEDBACKS_UPDATE_STARTED",
});

export const updateFeedbackSuccess = (feedback) => ({
  type: "FEEDBACKS_UPDATE_SUCCESS",
  feedback,
});

export const updateFeedbackFailure = (error) => ({
  type: "FEEDBACKS_UPDATE_FAILURE",
  error,
});

export const changeFeedbackVisibilityStarted = (feedbackId) => ({
  type: "FEEDBACKS_CHANGE_VISIBILITY_STARTED",
  feedbackId,
});

export const changeFeedbackVisibilitySuccess = (feedbackId, externallyVisible) => ({
  type: "FEEDBACKS_CHANGE_VISIBILITY_SUCCESS",
  feedbackId,
  externallyVisible,
});

export const changeFeedbackVisibilityFailure = (feedbackId, error) => ({
  type: "FEEDBACKS_CHANGE_VISIBILITY_FAILURE",
  feedbackId,
  error,
});

export const clearChangeFeedbackVisibilityFailure = (feedbackId) => ({
  type: "FEEDBACKS_CHANGE_VISIBILITY_CLEAR_FAILURE",
  feedbackId,
});

export const setDeleteFeedbackPromptData = (promptData) => ({
  type: "FEEDBACKS_SET_DELETE_PROMPT_DATA",
  promptData,
});

export const destroyFeedbackStarted = () => ({
  type: "FEEDBACKS_DESTROY_STARTED",
});

export const destroyFeedbackSuccess = (feedbackId) => ({
  type: "FEEDBACKS_DESTROY_SUCCESS",
  feedbackId,
});

export const destroyFeedbackFailure = (error) => ({
  type: "FEEDBACKS_DESTROY_FAILURE",
  error,
});
