import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class TimelineContactFormEvent extends Component {
  constructor(props, context){
    super(props, context)
  }

  property = (propertyObject) => {
    const bedString = !propertyObject.bedrooms ? "" : propertyObject.bedrooms + " Bed"
    const bathString = !propertyObject.bathrooms ? "" : propertyObject.bathrooms + " Bath"
    const bedBathString = (bedString + " " + bathString).trim()
    const formattedValue = new Intl.NumberFormat(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }
    ).format(propertyObject.value)

    return(
      <div key={propertyObject.id} className="property-wrapper"
        style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' + propertyObject.main_photo + ')'}}>
        <div className="inset-info">
          <span className="price">{formattedValue}</span>
          <span>{propertyObject['street_address']}</span>
          <span>{propertyObject['city']}, {propertyObject['state']} {propertyObject['zip']}</span>
          <span>{bedBathString}</span>
        </div>
      </div>
    )
  }

  fields_for_details = () => {
    const {event} = this.props

    // deconstruct to remove property object from fields
    const { timeline_fields: { property, ...timeline_fields } } = event
    return timeline_fields
  }

  details = () => {
    const {event} = this.props
    const eventId = event.id
    let description = []
    const key_and_value_strings = this.fields_for_details().comments.split(/<br \/>|<br>/)
    const formatted_keys = {
      'Amount': 'Offer Amount',
      'Need financing_advice': 'Mortgage Pre-Approval',
      'Have seen_home_in_person': 'Have Seen Home In Person',
      'Position': 'Position',
      'Date': 'Date',
      'Resume': 'Resume',
      'Submitted on': 'Submitted On',
      'Form source_url': 'Form Source URL'
    }

    for(const index in key_and_value_strings){
      const detail_string = key_and_value_strings[index]
      let [key, value] = detail_string.split(': ')
      const formatted_key = formatted_keys[key]

      if(formatted_key){
        value = value.replace('Http', 'http')
        value = value.replace('$ ', '$')
        value = capitalize(value)

        description.push(`${formatted_key}: ${value}`)
      } else {
        description.push(detail_string)
      }
    }

    return(
      description.length > 0 &&
      <div className="row bold-detail details-row details-header">
        Details:
      </div> &&
      description.map(description =>{
        return(
          <div className="row details-row" key={`${eventId}-${description}`}>
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
        )
      })
    )
  }

  title = () => {
    const {event} = this.props
    let title = 'Contact Form'

    const comments = this.fields_for_details().comments
    if(comments.startsWith('Make offer form from mobile')){
      title = 'Make An Offer'
    }

    return title
  }

  row = () => {
    const {event} = this.props
    const {timeline_fields} = event
    const propertyObject = timeline_fields.property
    const title = this.title()

    return (
      <div
        className="timeline-event timeline-submitted-form"
        onClick={()=>{propertyObject && propertyObject.url && window.open(propertyObject.url);}}
      >
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">Submitted Form:</span>
            {` ${title}`}
            <div className="timeline-description-text">
              {propertyObject && this.property(propertyObject)}
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
