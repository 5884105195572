import React, { useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import * as actionCreators from "../actions/thunks";
import Checkbox from "../../../../shared/v2/Checkbox";
import { iff } from "../actions/helpers";

const Share = ({ links, shared, planId, siteId, shareAdSiteAsThunk, uuid }) => {
  const [checked, toggleChecked] = useState(false);
  const handleShare = () => {
    shareAdSiteAsThunk(uuid, planId, siteId);
    toggleChecked(!checked);
  };
  return (
    <>
      {iff(
        shared,
        <div className="tw-leading-[20px] tw-text-[14px] tw-font-normal tw-text-gray-50">Shared</div>,
        <span className="tw-pr-[8px]">
          <Checkbox
            className="tw-border-gray-50"
            value="12312"
            disabled={!links}
            checked={checked}
            onChange={handleShare}
          />
        </span>,
      )}
    </>
  );
};

Share.propTypes = {
  shareAdSiteAsThunk: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
  planId: Proptypes.string,
  siteId: Proptypes.string,
  links: Proptypes.string,
  shared: Proptypes.bool,
};
Share.defaultProps = {
  links: "",
  planId: null,
  siteId: null,
  shared: false,
};

const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  shareAdSiteAsThunk: (uuid, planId, siteId) =>
    dispatch(actionCreators.shareAdSiteAsThunk(uuid, planId, siteId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Share);
