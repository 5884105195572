export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },
  "&multiLine": {
    control: {
      minHeight: 125,
    },
    highlighter: {
      border: "none",
      borderRadius: 2,
      boxSizing: "border-box",
      maxHeight: 250,
      outlineOffset: 0,
      overflow: "auto",
      padding: 8,
    },
    input: {
      backgroundColor: "rgba(236,237,239,0.6)",
      border: "none",
      borderRadius: 2,
      color: "var(--neutral-gray-75)",
      maxHeight: 250,
      outlineColor: "var(--primary-color)",
      outlineOffset: 0,
      overflow: "auto",
      padding: 8,
    },
  },

  suggestions: {
    list: {
      maxHeight: "250px",
      overflow: "auto",
      backgroundColor: "white",
      borderRadius: 5,
      boxShadow: "0px 2px 8px #c2c2c2",
      fontSize: 14,
    },
    item: {
      padding: "5px 10px",
      "&focused": {
        backgroundColor: "#F5F5F5",
      },
    },
  },
};
