import { useState, useRef, useEffect } from "react";

export const usePlacesAutocomplete = (term, place) => {
  const timeoutRef = useRef(null);
  const tokenRef = useRef(null);

  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (term.length < 2 || term.length > 100) {
      // cats always type 101 chars
      setAutocompleteResults([]);
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (!tokenRef.current) {
        tokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
      }
      const request = {
        input: term,
        componentRestrictions: {
          country: ["us"],
        },
        types: GoogleTypes,
        sessionToken: tokenRef.current,
      };

      const autocomplete = new window.google.maps.places.AutocompleteService();

      autocomplete.getPlacePredictions(request, (predictions, status) => {
        if (status === "OK") {
          setAutocompleteResults(mapPredictions(predictions));
        } else {
          setAutocompleteResults([]);
        }
      });
    }, 150);
  }, [term]);

  useEffect(() => {
    if (!place) {
      return;
    }

    const fakePlace = new window.google.maps.places.PlacesService(document.createElement("div"));

    // The session token is only supposed to be used for a single loop of (get suggestions, pick sugggestion)
    const token = tokenRef.current;
    tokenRef.current = null;

    const query = {
      placeId: place.id,
      fields: ["formatted_address", "geometry"],
      sessionToken: token,
    };

    fakePlace.getDetails(query, (result, status) => {
      if (status === "OK") {
        setSelectedLocation({
          isRadius: true,
          name: result.formatted_address,
          location: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          },
        });
      }
    });
  }, [place]);

  return { autocompleteResults, selectedLocation };
};

const mapPredictions = (predictions) => {
  const types = Object.keys(GoogleTypesToReadable);

  return predictions
    .map((p) => ({
      value: p.description,
      id: p.place_id,
      category: GoogleTypesToReadable[p.types.filter((t) => types.includes(t))[0]],
    }))
    .sort((a, b) => {
      // Results from google aren't organized by type
      const aOrder = LocationsOrder[a.category];
      const bOrder = LocationsOrder[b.category];

      if (aOrder > bOrder) {
        return 1;
      } else if (aOrder < bOrder) {
        return -1;
      }

      return 0;
    });
};

const GoogleTypesToReadable = {
  street_address: "Street Address",
  neighborhood: "Neighborhood",
  locality: "City",
  postal_code: "ZIP Code",
  administrative_area_level_2: "County",
};

const GoogleTypes = Object.keys(GoogleTypesToReadable);

const LocationsOrder = {
  "Street Address": 1,
  City: 2,
  Neighborhood: 3,
  County: 4,
  "ZIP Code": 5,
};
