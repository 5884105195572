import { schemaGenerator } from "../utils";
import { baseLinkClasses, activeBg } from "./Pagination.styles";

const currentDictionary = {
  regular: {
    default: [...baseLinkClasses, activeBg, "tw-rounded-full", "tw-cursor-default"],
  },
};

const linkDictionary = {
  regular: {
    default: [...baseLinkClasses, "tw-bg-transparent", "tw-border-0", "tw-cursor-pointer"],
    hover: ["hover:tw-text-theme-pagination-link-color-hover"],
    active: ["active:tw-text-theme-pagination-link-color-active"],
    focusVisible: ["focus-visible:tw-outline-theme-offset-none"],
  },
};

export const currentSchema = schemaGenerator(currentDictionary);
export const linkSchema = schemaGenerator(linkDictionary);

export default { currentSchema, linkSchema };
