import React from "react";
import PropTypes from "prop-types";

const ModalBody = ({ className, children, ...props }) => (
  <>
    <div
      data-cy="modal-body"
      className={className}
      // Allow arbitrary attributes, mostly for data
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  </>
);
ModalBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ModalBody.defaultProps = {
  children: null,
  className: "",
};

export default ModalBody;
