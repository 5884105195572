/* eslint-disable camelcase */
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { arrayOf, func, node, number, shape, string } from "prop-types";

import { putDetailAsThunk, updatePropertyDetailAsThunk } from "../actions/thunks";

import DetailSectionHeader from "../DetailSectionHeader";
import DetailInput from "../DetailInput";
import DetailDropdown from "../DetailDropdown";
import ModalPropertyDetail from "../ModalPropertyDetail";
import TextButton from "../../../../shared/v2/TextButton";
import { Plus } from "../../../../shared/v2/Icomoon";
import ModalEditLabel from "../ModalEditLabel";

const propertyTypeToOption = (item) => (item ? { label: item.name, value: item.id } : null);

const PropertyDetails = ({
  uuid,
  propertyDetails,
  additionalDetails,
  accountPropertyTypes,
  onPutDetailAsThunk,
  onUpdatePropertyDetailAsThunk,
}) => {
  const {
    id,
    street_address,
    city,
    state,
    neighborhood,
    county,
    zip,
    property_type,
    beds,
    baths,
    sqft,
    year_built,
    parking,
    foundation,
  } = propertyDetails;
  const [isModalOpen, toggleModal] = useState(false);
  const [isModalEditRemoveLabel, setModalEditRemoveLabel] = useState(false);
  const [customDetailEdit, setCustomDetailEdit] = useState({ label: "", id: null });

  const sortedAdditionalDetails = useMemo(
    () =>
      Object.entries(additionalDetails)
        .filter((detail) => detail[1] !== undefined)
        .sort((a, b) => a[1].created_at - b[1].created_at),
    [additionalDetails],
  );

  const saveToAddress =
    (key, stateKey = null) =>
    (value) => {
      onPutDetailAsThunk({
        uuid,
        key,
        inOption: "address_attributes",
        value,
        group: "propertyDetails",
        addressId: id,
        stateKey,
      });
    };
  const saveDetail = (key) => (value) =>
    onPutDetailAsThunk({
      uuid,
      key,
      inOption: "details",
      value,
      group: "propertyDetails",
    });

  const saveAdditionalDetail = (key) => (value) =>
    onPutDetailAsThunk({
      uuid,
      key,
      inOption: "details",
      value,
      group: "additionalDetails",
    });

  const saveOptionFor = (key, stateKey) => (optionValue) =>
    onPutDetailAsThunk({
      uuid,
      key,
      value: optionValue.value,
      group: "propertyDetails",
      stateKey,
    });

  const onRemovePropDetailHandlr = () => {
    const { id: labelId } = customDetailEdit;
    onUpdatePropertyDetailAsThunk({
      id: labelId,
      _destroy: true,
      uuid,
    });
  };

  const onSavePropDetailHandlr = (name) => {
    const { id: labelId } = customDetailEdit;
    onUpdatePropertyDetailAsThunk({
      id: labelId,
      name,
      uuid,
    });
  };

  return (
    <div>
      <ModalPropertyDetail isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <ModalEditLabel
        show={isModalEditRemoveLabel}
        onHide={() => setModalEditRemoveLabel(false)}
        labelValue={customDetailEdit.label}
        onRemove={onRemovePropDetailHandlr}
        onSave={onSavePropDetailHandlr}
      />
      <DetailSectionHeader header="Property Details" />
      <DetailInput
        dataCy="street"
        label="Street Address"
        val={street_address}
        onBlur={saveToAddress("street_address")}
      />
      <DetailInput dataCy="city" label="City" val={city} onBlur={saveToAddress("city")} />
      <DetailInput
        dataCy="state"
        label="State/Province"
        val={state}
        onBlur={saveToAddress("locality", "state")}
      />
      <DetailInput
        dataCy="postal-code"
        label="Zip/Postal code"
        val={zip}
        onBlur={saveToAddress("postal_code", "zip")}
      />
      <DetailDropdown
        label="Property Type"
        options={accountPropertyTypes}
        val={propertyTypeToOption(property_type)}
        onBlur={saveOptionFor("property_type_id", "property_type")}
      />
      <DetailInput dataCy="beds" label="Beds" val={beds} onBlur={saveDetail("beds")} />
      <DetailInput dataCy="baths" label="Baths" val={baths} onBlur={saveDetail("baths")} />
      <DetailInput dataCy="sqft" label="SQFT" val={sqft} onBlur={saveDetail("sqft")} />
      <DetailInput
        dataCy="neighborhood"
        label="Neighborhood"
        val={neighborhood}
        onBlur={saveToAddress("neighborhood")}
      />
      <DetailInput dataCy="county" label="County" val={county} onBlur={saveToAddress("county")} />
      <DetailInput
        dataCy="year-built"
        label="Year built"
        val={year_built}
        onBlur={saveDetail("year_built")}
      />
      <DetailInput dataCy="parking" label="Parking" val={parking} onBlur={saveDetail("parking")} />
      <DetailInput
        dataCy="foundation"
        label="Foundation"
        val={foundation}
        onBlur={saveDetail("foundation")}
      />
      {sortedAdditionalDetails.map(([label, { value, id: labelId }]) => (
        <DetailInput
          dataCy="additional"
          label={label.replace("_", " ")}
          val={value}
          onBlur={saveAdditionalDetail(label)}
          onLabelClick={() => {
            setModalEditRemoveLabel(true);
            setCustomDetailEdit({
              label: label.replace("_", " "),
              id: labelId,
            });
          }}
        />
      ))}
      <TextButton className="tw-px-0" onClick={() => toggleModal((prev) => !prev)}>
        <Plus size="s" /> Add Property Detail
      </TextButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  propertyDetails: state.tdpDetailsReducer.propertyDetails,
  additionalDetails: state.tdpDetailsReducer.additionalDetails,
  accountPropertyTypes: state.tdpDetailsReducer.meta.accountPropertyTypes,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (payload) => dispatch(putDetailAsThunk(payload)),
  onUpdatePropertyDetailAsThunk: (payload) => dispatch(updatePropertyDetailAsThunk(payload)),
});

PropertyDetails.propTypes = {
  uuid: string,
  propertyDetails: shape({
    street_address: string,
    city: string,
    state: string,
    zip: string,
    neighborhood: string,
    county: string,
    baths: string,
    beds: string,
    sqft: string,
    year_built: string,
    property_type: shape({ id: number, name: string }),
  }),
  additionalDetails: node,
  onPutDetailAsThunk: func,
  accountPropertyTypes: arrayOf(shape({ label: string, value: number })),
  onUpdatePropertyDetailAsThunk: func.isRequired,
};

PropertyDetails.defaultProps = {
  uuid: null,
  propertyDetails: shape({
    street_address: null,
    city: null,
    state: null,
    zip: null,
    neighborhood: null,
    county: null,
    baths: null,
    beds: null,
    sqft: null,
    year_built: null,
    property_type: { id: null, name: null },
  }),
  additionalDetails: null,
  onPutDetailAsThunk: () => {},
  accountPropertyTypes: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
