import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/v2/Modal/Modal";

const HubSpotModal = ({ formId, onClose, show }) => {
  useEffect(() => {
    if (!show) return;
    const createForm = () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "6041126",
        formId: formId || "c0592518-30e7-46d6-8b94-f6d8e79af7e7",
        target: "#hbspt-form-body",
        onFormSubmitted: () => onClose(),
      });
    };
    if (!window.hbspt) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//js.hsforms.net/forms/v2.js";
      script.defer = true;
      script.async = true;
      script.onload = createForm;
    } else {
      createForm();
    }
  }, [show]);
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[600px] tw-w-full"
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton className="tw-pb-[16px]" />
      <Modal.Body id="hbspt-form-body" className="tw-max-h-[80vh] tw-overflow-y-auto" />
    </Modal>
  );
};

HubSpotModal.propTypes = {
  formId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

HubSpotModal.defaultProps = {
  formId: null,
};

export default HubSpotModal;
