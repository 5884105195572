export const hasMinMaxError = (min, max) =>
  min !== null && max !== null && parseInt(min, 10) > parseInt(max, 10);

export const clickEventCatch = (e, fn, arr, passEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (passEvent) {
    arr.unshift(e);
  }
  fn(...arr);
};
