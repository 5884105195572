import React from "react";
import Proptypes from "prop-types";
import LinkIcon from "../../../../shared/v2/Icomoon/Icons/Link";
import Edit from "../../../../shared/v2/Icomoon/Icons/Edit";
import IconButton from "../../../../shared/v2/IconButton";

const Links = ({ hovered, links, onSiteInputOpen, setEdit, setlink }) => (
  <>
    {hovered ? (
      <IconButton
        onClick={onSiteInputOpen}
        isedit={setEdit(true)}
        setlink={setlink(links)}
        size="small"
        schema="tertiary"
      >
        <Edit size="custom" className="tw-w-[10px] tw-h-[10px]" />
      </IconButton>
    ) : (
      <IconButton onClick={() => window.open(links, "_blank")} size="small" schema="primary">
        <LinkIcon size="m" />
      </IconButton>
    )}
  </>
);

Links.propTypes = {
  links: Proptypes.string,
  hovered: Proptypes.bool,
  onSiteInputOpen: Proptypes.func,
  setEdit: Proptypes.func,
  setlink: Proptypes.func,
};
Links.defaultProps = {
  links: "",
  hovered: false,
  onSiteInputOpen: () => {},
  setEdit: () => {},
  setlink: () => {},
};

export default Links;
