import React from 'react';
import { Row, Col } from 'react-bootstrap';
import css from './report-styles.module.css';
import { ReportHeader } from './';

const AgentTextsHeaders = ({sortBy, sortKey, sortDesc}) => {
    return (
      <Col xs={12} className={css.noWrap}>
        <div className='th'>
          <ReportHeader
            name='agent'
            className='col-xs-2'
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Name'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_agent_total_lead_count'
            className={`col-sm-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Total Leads'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_lead_text_count'
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Leads Texted'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_non_lead_text_count'
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Others Texted'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_total_texts'
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Total Texts Sent'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_total_texts_recd'
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Total Texts Received'
            handleSort={sortBy} />
          <ReportHeader
            name='text_report_total_text_count'
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Total Texts'
            handleSort={sortBy} />
        </div>
      </Col>
    )
  }
export default AgentTextsHeaders;
