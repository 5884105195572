/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EyeSolidV6 = (props) => (
  <Icon {...props} name="Eye Solid V6">
    <path d="M7.767 5.344A2.652 2.652 0 0 1 10.667 8 2.668 2.668 0 0 1 8 10.667a2.652 2.652 0 0 1-2.656-2.9c.258.125.558.233.878.233A1.78 1.78 0 0 0 8 6.222c0-.319-.108-.619-.233-.878zm5.583-1.327c1.3 1.206 2.169 2.625 2.581 3.642a.88.88 0 0 1 0 .683c-.411.992-1.281 2.411-2.581 3.642-1.308 1.217-3.106 2.239-5.35 2.239s-4.042-1.022-5.349-2.239C1.351 10.753.482 9.334.069 8.342a.889.889 0 0 1 0-.683c.413-1.017 1.282-2.436 2.582-3.642C3.959 2.801 5.756 1.778 8 1.778s4.042 1.023 5.35 2.239zM8 4C5.792 4 4 5.792 4 8s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4z" />
  </Icon>
);

export default EyeSolidV6;
