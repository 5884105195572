/* eslint-disable react/prop-types, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState, useRef } from "react";
import { filter, includes } from "lodash";

import { TriangleDownIcon, TriangleUpIcon } from "../icons";
import "./OptionsCell.scss";

const OptionsInlineCell = ({
  optionsList = [],
  selectedId = 0,
  onUpdateOption,
  fullWidth = true,
  hint = "",
  isEditable = true,
  wrapperClassName = "",
  selectionWrapperClassName = "",
}) => {
  const [showSelectionPicker, setShowSelectionPicker] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState(optionsList);
  const [option, setOption] = useState(selectedId);
  const optionsNode = useRef();

  const handleClickOutside = (e) => {
    if (optionsNode.current.contains(e.target)) {
      return;
    }

    setShowSelectionPicker(false);
  };

  const selectOption = (id) => {
    setShowSelectionPicker(false);
    setOption(id);
    onUpdateOption(id);
  };

  const selectedTitle = (id) => {
    const fallback = "No option selected";
    const selectedOption = optionsList.find((o) => o.id === id) || {};

    return selectedOption.title || selectedOption.name || fallback;
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filterBySearch = () => {
    const tempResults = filter(optionsList, (o) =>
      includes((o.title || o.name).toLowerCase(), search.toLowerCase()),
    );

    setSearchResults(tempResults);
  };

  const optionsBuilder = (_option) => {
    const { title, id, name } = _option;
    const label = title || name;
    return (
      <div key={id} className="tw-flex tw-bg-white" onClick={() => selectOption(id)}>
        <div className="option tw-max-w-full tw-cursor-pointer tw-flex tw-flex-col tw-flex-wrap tw-grow tw-shrink-0 tw-font-normal tw-text-left tw-transition tw-duration-200 tw-overflow-hidden">
          {option === id ? <b>{label}</b> : label}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setOption(selectedId);
  }, [selectedId]);

  useEffect(() => {
    filterBySearch();
  }, [search]);

  useEffect(() => {
    if (showSelectionPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSelectionPicker]);

  const isEmpty = !selectedId || selectedId === 0;
  const displayValue = isEmpty ? "Add" : selectedTitle(option);

  if (!isEditable && isEmpty) {
    return <div className="tw-flex tw-justify-start">—</div>;
  }

  return (
    <div
      ref={optionsNode}
      className={`options-cell-container tw-relative tw-flex tw-flex-col tw-flex-wrap ${wrapperClassName}`}
    >
      <div
        className="tw-text-black"
        onClick={() => isEditable && setShowSelectionPicker(!showSelectionPicker)}
      >
        <div className="tw-w-max tw-min-w-full selected-option tw-cursor-pointer tw-flex tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center tw-text-center tw-transition tw-duration-200">
          <span className={`${isEmpty ? "tw-text-teal" : ""}`}>{displayValue}</span>

          {isEditable &&
            !isEmpty &&
            (showSelectionPicker ? (
              <TriangleUpIcon
                className={`options-cell-icon ${fullWidth ? "options-single-icon-full-width" : ""}`}
              />
            ) : (
              <TriangleDownIcon
                className={`options-cell-icon ${fullWidth ? "options-single-icon-full-width" : ""}`}
              />
            ))}
        </div>
      </div>
      {showSelectionPicker && (
        <div
          className={`tw-z-10 tw-max-w-220px tw-border-box selectable-options-container tw-absolute tw-top-full tw-left-0 tw-flex tw-flex-col tw-flex-wrap ${
            fullWidth ? "tw-min-w-full" : ""
          } ${selectionWrapperClassName}`}
        >
          <div
            className={`tw-max-w-220px tw-border-box selectable-input-container tw-flex tw-flex-col tw-bg-white ${
              fullWidth ? "options-single-full-width" : ""
            }`}
          >
            {hint && <div className="options-cell-hint">{hint}</div>}
            <input
              type="text"
              className="selectable-search-input tw-h-32px tw-p-4px tw-border-0 tw-bg-gray-light"
              placeholder="Search..."
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          <div
            className={`tw-max-w-220px tw-border-box selectable-options tw-flex tw-flex-col tw-flex-nowrap tw-top-full tw-left-0 tw-bg-white tw-min-w-min ${
              fullWidth ? "tw-min-w-full" : ""
            }`}
          >
            {searchResults.length > 0 ? (
              searchResults.map(optionsBuilder)
            ) : (
              <div className="no-option tw-flex tw-flex-col tw-flex-wrap tw-grow tw-shrink-0 tw-font-normal tw-text-left">
                no results
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsInlineCell;
