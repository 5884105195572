/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CloneRegularV6 = (props) => (
  <Icon {...props} name="Clone Regular V6">
    <path d="M2 14.5h7c.275 0 .5-.225.5-.5v-2H11v2c0 1.103-.897 2-2 2H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2v1.5H2a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5zM5 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v7c0 1.103-.897 2-2 2H7c-1.103 0-2-.897-2-2V2zm2 7.5h7c.275 0 .5-.225.5-.5V2a.5.5 0 0 0-.5-.5H7a.5.5 0 0 0-.5.5v7c0 .275.225.5.5.5z" />
  </Icon>
);

export default CloneRegularV6;
