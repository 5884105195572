import React from "react";
import PropTypes from "prop-types";
import TextToggle from "../../shared/v2/TextToggle";

function ActiveUsersSwitchFilter({ setIsFilterActiveUsers, isFilterActiveUsers }) {
  const options = [
    {
      value: "user_seats",
      label: "User Seats",
    },
    {
      value: "archived",
      label: "Archived Users",
    },
  ];
  return (
    <TextToggle
      options={options}
      value={isFilterActiveUsers ? options[0].value : options[1].value}
      size="medium"
      onChange={(e) => setIsFilterActiveUsers(e.target.value !== "archived")}
    />
  );
}

ActiveUsersSwitchFilter.propTypes = {
  setIsFilterActiveUsers: PropTypes.func.isRequired,
  isFilterActiveUsers: PropTypes.bool.isRequired,
};

export default ActiveUsersSwitchFilter;
