/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from "react";
import moment from "moment";
import { isEqual } from "lodash";
import DatePicker from "../../../../shared/v2/DatePicker";
import Dropdown from "../../../../shared/v2/Dropdown";
import FieldLabel from "../../../../shared/v2/FieldLabel";
import { QUERY_DATE_FORMAT } from "../../../../shared/DatePicker";

const DateContainer = ({ children }) => <div className="tw-w-full">{children}</div>;

const statusDateRangeToOption = (range) => ({
  label: range.name,
  value: range.value,
});

const getDateRangeOptionsList = (ranges) => ranges.map(statusDateRangeToOption);

const getSelectedDateRangeOption = (ranges, rangeValue) => {
  if (!rangeValue) {
    return null;
  }

  const selectedRange = ranges.find((range) => range.value === rangeValue);

  if (!selectedRange) {
    return null;
  }

  return statusDateRangeToOption(selectedRange);
};

const StatusChangeDateFilter = ({
  initiallySelectedOption,
  initiallySelectedDateRange: selectedDateRange,
  dateRangeOptions,
  onChangeRange,
  onChangeDates,
}) => {
  const customOptionRef = useRef();

  const selectedOption = useMemo(
    () => getSelectedDateRangeOption(dateRangeOptions, initiallySelectedOption),
    [dateRangeOptions, initiallySelectedOption],
  );
  const dateRangeOptionsList = useMemo(() => getDateRangeOptionsList(dateRangeOptions), [dateRangeOptions]);

  const handleOnChange = (newSelectedOption) => {
    if (isEqual(newSelectedOption, selectedOption)) {
      return;
    }

    if (!newSelectedOption) {
      onChangeRange(null);
      return;
    }

    onChangeRange(newSelectedOption.value);
  };

  const isCustomOption = selectedOption?.value === "custom";

  let scrollHandler;
  const scrollHandlerTimeout = 100;

  useEffect(() => {
    if (!customOptionRef.current || !isCustomOption) {
      return;
    }

    // Schedule the scroll using JS timeout
    scrollHandler = setTimeout(() => customOptionRef.current.scrollIntoView(), scrollHandlerTimeout);
  }, [customOptionRef, isCustomOption]);

  // This useEffect cancels the previous scheduled scroll handler to avoid scrolling the filters
  // modal when it is opened with the custom option already selected.
  useEffect(() => clearTimeout(scrollHandler), []);

  const setSelectedDateRange = (callback) => callback(selectedDateRange);

  const setStartDate = (date) =>
    onChangeDates(
      setSelectedDateRange(([, end]) =>
        date ? [moment(date).format(QUERY_DATE_FORMAT), end] : [undefined, end],
      ),
    );

  const setEndDate = (date) =>
    onChangeDates(
      setSelectedDateRange(([start]) =>
        date ? [start, moment(date).format(QUERY_DATE_FORMAT)] : [start, undefined],
      ),
    );

  return (
    <>
      <Dropdown
        label="Status Change Date Range"
        placeholder="Select a date range for status change"
        options={dateRangeOptionsList}
        value={selectedOption}
        onChange={handleOnChange}
        menuShouldComeToFront
        isSearchable
        menuPlacement="top"
      />

      {isCustomOption && (
        <div ref={customOptionRef} className="tw-flex tw-space-x-4 tw-mt-24px">
          <DateContainer>
            <FieldLabel label="Start Date" />
            <DatePicker
              value={selectedDateRange[0] || ""}
              dateFormat={QUERY_DATE_FORMAT}
              onChange={setStartDate}
            />
          </DateContainer>
          <DateContainer>
            <FieldLabel label="End Date" />
            <DatePicker
              value={selectedDateRange[1] || ""}
              dateFormat={QUERY_DATE_FORMAT}
              onChange={setEndDate}
            />
          </DateContainer>
        </div>
      )}
    </>
  );
};

export default StatusChangeDateFilter;
