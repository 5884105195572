/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AngleDownRegularV6 = (props) => (
  <Icon {...props} name="Angle Down Regular V6">
    <path d="m13.266 6.797-4.75 4.472c-.144.162-.331.231-.516.231s-.371-.068-.516-.205l-4.75-4.498a.754.754 0 0 1-.029-1.063.749.749 0 0 1 1.061-.028L8 9.715l4.234-4.013a.75.75 0 1 1 1.032 1.093z" />
  </Icon>
);

export default AngleDownRegularV6;
