/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const DollarSignSolidV6 = (props) => (
  <Icon {...props} name="Dollar Sign Solid V6">
    <path d="M8 0a1 1 0 0 1 1 1v1.115l.147.021c.331.049 1.319.208 1.725.311.506.136.859.681.722 1.215a1.002 1.002 0 0 1-1.216.725c-.291-.103-1.175-.222-1.525-.272-1.003-.15-1.863-.075-2.453.15-.571.219-.808.528-.871.875-.061.334-.015.525.04.644.059.125.174.266.404.419.508.334 1.283.559 2.289.831l.087.025c.888.241 1.975.537 2.781 1.072.441.294.856.691 1.112 1.241.259.556.316 1.181.197 1.819-.219 1.216-1.062 2.009-2.053 2.422a5.22 5.22 0 0 1-1.388.344v1.044a1 1 0 1 1-2 0V13.91c-.012 0-.028-.031-.041-.006h-.006c-.759-.119-2.013-.447-2.859-.822a1.001 1.001 0 0 1-.507-1.322c.224-.503.815-.731 1.292-.506.681.291 1.756.575 2.378.672 1 .15 1.822.062 2.378-.163.528-.216.769-.528.837-.903.059-.334.016-.525-.041-.644-.059-.125-.175-.266-.403-.419-.509-.334-1.284-.559-2.291-.831l-.088-.025C6.76 8.7 5.671 8.404 4.865 7.869c-.441-.294-.854-.691-1.111-1.241-.26-.556-.317-1.181-.196-1.847.221-1.216 1.097-1.991 2.125-2.383a5.549 5.549 0 0 1 1.314-.313V1a1 1 0 0 1 1-1z" />
  </Icon>
);

export default DollarSignSolidV6;
