import React from "react";
import { Button, Checkbox, Dropdown, FormGroup, Row, Col, CancelButton } from "../../shared/v1";
import Error from "../../shared/Error";
import { autoPlanOptions, statusOptions, intentOptions } from "./helpers";

const defaultTrigger = {
  auto_plan_id: undefined,
  intent: "any",
  source_slug: "any",
  status: "any",
  tag: "any",
};

class TriggerForm extends React.PureComponent {
  handleSubmit = () => {
    const { onSubmitPath, accountId, onSubmit, triggerForm } = this.props;
    const formData = { ...defaultTrigger, ...triggerForm, account_id: accountId };

    onSubmit(onSubmitPath, formData);
  };

  render() {
    const { sources, tags, onTriggerChange, autoPlans, closeAction, isSubmitting, hasError, errorMessage } =
      this.props;
    const triggerForm = { ...defaultTrigger, ...this.props.triggerForm };

    return (
      <div className="trigger-form">
        {hasError && <Error errorMessage={errorMessage} />}

        <FormGroup className="divider">
          <Row>
            <Col size={12}>
              <div className="p-b-10">Apply Auto Plan</div>
              <label>Auto Plan</label>
              <Dropdown
                name="auto_plan_id"
                value={triggerForm && triggerForm.auto_plan_id}
                onUpdate={(opt) => onTriggerChange("auto_plan_id", opt.value)}
                placeholder="Select a Plan"
                isSearchable={true}
                isClearable={false}
                options={autoPlanOptions(autoPlans)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col size={12}>Auto start when a contact meets following conditions</Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col size={6}>
              <label>Intent</label>

              <Dropdown
                value={triggerForm && triggerForm.intent}
                name="intent"
                onUpdate={(opt) => onTriggerChange("intent", opt.value)}
                isSearchable={false}
                isClearable={false}
                options={intentOptions}
              />
            </Col>

            <Col size={6}>
              <label>Source</label>

              <Dropdown
                name="source_slug"
                value={triggerForm && triggerForm.source_slug}
                onUpdate={(opt) => onTriggerChange("source_slug", opt.value)}
                isClearable={false}
                options={[{ label: "Any", value: "any" }].concat(sources)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col size={6}>
              <label>Status</label>

              <Dropdown
                name="status"
                onUpdate={(opt) => onTriggerChange("status", opt.value)}
                value={triggerForm && triggerForm.status}
                isSearchable={false}
                isClearable={false}
                options={statusOptions}
              />
            </Col>
            <Col size={6}>
              <label>Tag</label>

              <Dropdown
                name="tag"
                value={triggerForm && triggerForm.tag}
                onUpdate={(opt) => onTriggerChange("tag", opt.value)}
                isClearable={false}
                options={[{ label: "Any", value: "any" }].concat(tags)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col size={12}>
              Note: conditions apply to existing contacts that are updated via import but do not apply to mass
              imported contacts. If a contact already has an active Auto Plan applied, triggers will not apply
              another plan. Subsequent plans need to be applied manually.
            </Col>
          </Row>
        </FormGroup>

        {false && (
          <FormGroup>
            <Col size={12}>
              <Checkbox
                labelClassName="tw-text-gray-base tw-text-[14px] tw-select-none"
                deprecatedOverrideLabelPaddingClasses="tw-pl-10px"
                deprecatedOverrideCheckboxMarginClasses="!tw-mt-0 !tw--ml-20px"
                name="new_imported_contact"
                inline={true}
                onChange={() => onTriggerChange("new_imported_contact", !triggerForm.new_imported_contact)}
                checked={triggerForm.new_imported_contact || false}
              >
                Include new imported contacts
              </Checkbox>
            </Col>
          </FormGroup>
        )}

        <FormGroup className="p-t-20">
          <Row>
            <Col size={12}>
              <CancelButton size="small" className="modal-cancel" onClick={closeAction} />
              {isSubmitting ? (
                <Button size="small" variant="info" className="tw-float-right" disabled>
                  Saving...
                </Button>
              ) : (
                <Button size="small" variant="info" className="tw-float-right" onClick={this.handleSubmit}>
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </FormGroup>
      </div>
    );
  }
}
export default TriggerForm;
