import { useEffect } from "react";
import { getLocationGeoJSON, getPlaceDetails } from "../../services";

const useGetGeoLocation = ({ value, setFormData, setLatLon, primaryAgentUuid, currentName }) =>
  useEffect(() => {
    if (value && value.id && value.source === "GoogleAPI") {
      getPlaceDetails({
        data: { place_id: value.id, primary_agent_uuid: primaryAgentUuid },
        callback: (results) => {
          setFormData({
            search_type: "radius",
            radius_type: "smart-radius",
            street_address: results?.data?.address_components?.street_address || null,
            city: results?.data?.address_components?.city,
            region: results?.data?.address_components?.region,
            country: results?.data?.address_components?.country,
            postal_code: results?.data?.address_components?.postal_code,
            state_abbreviation: results?.data?.address_components?.state,
            name: currentName || results?.data?.address,
          });
          setLatLon([results?.data?.lat, results?.data?.lon]);
        },
        errorCallback: () => {},
      });
    } else if (
      value &&
      typeof value.lat !== "undefined" &&
      typeof value.lon !== "undefined" &&
      value.source === "LocationAPI"
    ) {
      setLatLon([value.lat, value.lon]);
      getLocationGeoJSON({
        data: { location_id: value.id, primary_agent_uuid: primaryAgentUuid },
        callback: (results) => {
          const isCityOrNeighborhood = ["postal-city", "neighborhood"].includes(value.areaType);
          const address = {};
          if (isCityOrNeighborhood) {
            address.city = results.data?.city;
            address.state_abbreviation = results.data?.state;
            address.street_address = null;
          }
          setFormData({
            search_type: "area",
            boundry_coordinates: results?.data?.geometry,
            name: currentName || value?.label,
            ...address,
          });
        },
        errorCallback: () => {},
      });
    }
  }, [value]);

export default useGetGeoLocation;
