import React from "react";
import { Col } from "react-bootstrap";
import AppointmentsRow from "./AppointmentsRow";
import { useAppointmentsData } from "./appointmentsReportHelper";
import { TBody, TD, TH, THead, TR, Table } from "./BusinessDashboard/Table/Table";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";

const AppointmentsContainer = ({ sortBy, sortKey, sortDesc, appointmentData, apptFilters }) => {
  const { appointmentRows, appointmentTotalRow, headersRow } = useAppointmentsData({
    appointmentData,
    apptFilters,
    sortKey,
    sortBy,
    sortDesc,
  });

  return (
    <Col xs={12}>
      <Table className="tw-bg-white tw-border-separate">
        <THead className="tw-bg-white tw-whitespace-nowrap tw-sticky tw-top-0">
          <TR>
            {headersRow.map((header, i) => (
              <TH
                key={header.key}
                border={`tw-border-b-[1px] tw-border-r-0 ${header.border ? "tw-border-l-[1px]" : ""}`}
                sort={sortKey === header.key ? `${sortDesc ? "desc" : "asc"}` : false}
                onSort={() => sortBy(header.key)}
              >
                {header.label}{" "}
                {header.tooltip && (
                  <Tooltip
                    className="tw-ml-[4px]"
                    content={header.tooltip}
                    trigger={<CircleInfoSolidV6 className="tw-text-theme-primary" />}
                  />
                )}
              </TH>
            ))}
          </TR>
        </THead>
        <TBody>
          {appointmentRows.map((cells) => (
            <AppointmentsRow key={cells[0].id} cells={cells} />
          ))}

          <TR className="tw-bg-white tw-sticky tw-bottom-0">
            <TD border="tw-border-t-[1px] tw-border-r-0">
              <div className="tw-font-bold">Total:</div>
            </TD>
            {appointmentTotalRow.map((cell, index) => (
              <TD key={index} className="tw-font-bold" border="tw-border-t-[1px] tw-border-r-0">
                <span>{cell.value}</span>
              </TD>
            ))}
          </TR>
        </TBody>
      </Table>
    </Col>
  );
};

export default AppointmentsContainer;
