import React, { useState, useEffect, useMemo } from "react";

import TableHeader from "./TableHeader";
import TableCell from "./TableCell";
import Arrows from "./Arrows";
import { ArrowTurnDownRightSolidV6, ChevronDown, ChevronRight, CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Avatar from "@shared/v2/Avatar";
import Tooltip from "../../shared/v2/Tooltip";
import { formatCallDuration } from "./helpers";
import ReportsFilters from "./ReportsFilters";
import TrackingNumbersStats from "./TrackingNumbersStats";

const TrackingNumbersActivity = ({
  activity,
  agents,
  isAdmin,
  stats,
  selectedTrackingNumbers,
  trackingNumbers,
  handleTrackingNumberFilterOnChange,
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
  chosenView,
  logFilters,
  setLogFilters,
  showSettingsPage,
}) => {
  const mappedActivity = useMemo(() => {
    if (!activity || !agents?.length) {
      return [];
    }

    return Object.entries(activity).map(([tnId, info]) => mapActivity(tnId, info, agents));
  }, [agents, activity]);

  return (
    <div className="tw-flex tw-flex-col tw-items-stretch tw-flex-wrap tw-gap-8px tw-bg-tinted-gray-50">
      <ReportsFilters
        selectedTrackingNumbers={selectedTrackingNumbers}
        trackingNumbers={trackingNumbers}
        handleTrackingNumberFilterOnChange={handleTrackingNumberFilterOnChange}
        minDate={minDate}
        maxDate={maxDate}
        setMinDate={setMinDate}
        setMaxDate={setMaxDate}
        chosenView={chosenView}
        logFilters={logFilters}
        setLogFilters={setLogFilters}
        agents={agents}
      />
      <TrackingNumbersStats
        agents={agents}
        stats={stats}
        isAdmin={isAdmin}
        showSettingsPage={showSettingsPage}
      />
      <div className="tw-relative tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 bs-xs:tw-p-[15px] tw-flex tw-flex-col tw-p-[32px]">
        {mappedActivity?.length > 0 ? (
          <TrackingNumbersActivityTable trackingNumberActivity={mappedActivity} />
        ) : (
          <div className="tw-my-[88px] tw-text-18px tw-text-gray-50 tw-text-center">No Activity Recorded</div>
        )}
      </div>
    </div>
  );
};

const bottomBorder =
  "tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0";

const TrackingNumbersActivityTable = ({ trackingNumberActivity }) => {
  const [sortedNumbers, setSortedNumbers] = useState([]);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    setSortedNumbers(
      trackingNumberActivity.toSorted((a, b) => {
        if (a[sortField] > b[sortField]) {
          return sortDirection === "asc" ? 1 : -1;
        } else if (a[sortField] < b[sortField]) {
          return sortDirection === "asc" ? -1 : 1;
        }

        return 0;
      }),
    );
  }, [trackingNumberActivity, sortField, sortDirection]);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }

    setSortField(field);
  };

  return (
    <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0">
      <table className="tw-border-collapse tw-overflow-scroll tw-table-auto tw-flex-nowrap">
        <thead>
          <tr className="tw-py-12px">
            <TableHeader
              className={`${bottomBorder} tw-pl-[52px]`}
              onClick={() => handleSort("name")}
              sortable
            >
              Tracking Number Name <Arrows sortBy={sortField === "name" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("totalCalls")}
              sortable
            >
              Total Calls <Arrows sortBy={sortField === "totalCalls" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("totalContacts")}
              sortable
            >
              <span>Contacts</span>
              <Tooltip
                className="tw-mx-[4px] tw-w-[12px] tw-h-[12px] tw-text-gray-75 tw-mt-[-6px]"
                placement="bottom"
                content="Outcome of Talked To"
                trigger={<CircleInfoSolidV6 />}
              />
              <Arrows sortBy={sortField === "totalContacts" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("totalMissed")}
              sortable
            >
              Missed <Arrows sortBy={sortField === "totalMissed" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("totalCallTime")}
              sortable
            >
              Call Time <Arrows sortBy={sortField === "totalCallTime" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("uniqueCallers")}
              sortable
            >
              Unique Callers <Arrows sortBy={sortField === "uniqueCallers" ? sortDirection : null} />
            </TableHeader>
            <TableHeader
              className={bottomBorder}
              centerChildren
              onClick={() => handleSort("voicemails")}
              sortable
            >
              Voicemails <Arrows sortBy={sortField === "voicemails" ? sortDirection : null} />
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          {sortedNumbers.map((trackingNumber) => (
            <TableRow key={`tnActivity-${trackingNumber.name}`} trackingNumber={trackingNumber} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrackingNumbersActivity;

const TableRow = ({ trackingNumber }) => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    name,
    routingMethod,
    statsByAgent,
    totalCalls,
    totalContacts,
    totalMissed,
    totalCallTime,
    uniqueCallers,
    voicemails,
    isDeleted,
  } = trackingNumber;

  return [
    <tr className={isDeleted ? "tw-opacity-50 tw-bg-[#fafafa]" : ""}>
      <TableCell className="tw-border-solid tw-border-gray-10 tw-border-b tw-border-r tw-border-l-0 tw-border-t-0">
        <div
          className="tw-flex tw-flex-row tw-items-center tw-gap-16px tw-cursor-pointer tw-pl-8px"
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? <ChevronDown /> : <ChevronRight />}
          <div className="tw-flex tw-flex-col">
            <span className="tw-font-semibold">
              {name}
              {isDeleted ? " (Deleted)" : ""}
            </span>
            <span className="tw-text-12px tw-font-normal tw-text-gray-50">
              {RoutingMethodDisplay[routingMethod]} |{" "}
              {statsByAgent.filter((a) => a.isOnTrackingNumber).length} Assigned
            </span>
          </div>
        </div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{totalCalls || "--"}</div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{totalContacts || "--"}</div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{totalMissed || "--"}</div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{formatCallDuration(totalCallTime) || "--"}</div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{uniqueCallers || "--"}</div>
      </TableCell>
      <TableCell className={bottomBorder}>
        <div className="tw-flex tw-justify-center">{voicemails || "--"}</div>
      </TableCell>
    </tr>,
    statsByAgent
      .filter((a) => a.isOnTrackingNumber)
      .map((statByAgent, i) => (
        <SecondaryRow
          key={`${trackingNumber.name}-${statByAgent.name}`}
          routingMethod={routingMethod}
          isVisible={isVisible}
          isLastRow={i + 1 === statsByAgent.length}
          {...statByAgent}
        />
      )),
    statsByAgent.some((a) => !a.isOnTrackingNumber) ? <PreviouslyAssignedRow isVisible={isVisible} /> : "",
    statsByAgent
      .filter((a) => !a.isOnTrackingNumber)
      .map((statByAgent, i) => (
        <SecondaryRow
          key={`${trackingNumber.name}-${statByAgent.name}`}
          routingMethod={routingMethod}
          isVisible={isVisible}
          isLastRow={i + 1 === statsByAgent.length}
          {...statByAgent}
        />
      )),
  ];
};

const PreviouslyAssignedRow = ({ isVisible }) => {
  return (
    <tr
      className={`${isVisible ? "" : "tw-hidden"} tw-bg-gray-10 tw-text-gray-75 tw-font-semibold tw-text-12px`}
    >
      <SecondaryCell centerChildren={false} isFirstCell>
        <div className="tw-py-[6px] tw-pl-[52px]">Previously Assigned</div>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
      <SecondaryCell>
        <></>
      </SecondaryCell>
    </tr>
  );
};

const SecondaryRow = ({
  isVisible,
  isLastRow,
  name,
  avatar,
  contacts,
  callTime,
  uniqueCallers,
  isOnTrackingNumber,
}) => {
  return (
    <tr
      className={`${isVisible ? "" : "tw-hidden"} ${!isOnTrackingNumber ? "tw-opacity-50" : ""} tw-p-16px tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-bg-[#fafafa]`}
    >
      <SecondaryCell centerChildren={false} isFirstCell isLastRow={isLastRow}>
        <div className="tw-flex tw-flex-row tw-gap-16px tw-items-center tw-py-18px tw-pl-[52px]">
          <ArrowTurnDownRightSolidV6 />
          <Avatar src={avatar} alt={name} />
          {name}
        </div>
      </SecondaryCell>
      <SecondaryCell>--</SecondaryCell>
      <SecondaryCell>{contacts || "--"}</SecondaryCell>
      <SecondaryCell>--</SecondaryCell>
      <SecondaryCell>{formatCallDuration(callTime) || "--"}</SecondaryCell>
      <SecondaryCell>{uniqueCallers || "--"}</SecondaryCell>
      <SecondaryCell>--</SecondaryCell>
    </tr>
  );
};

const SecondaryCell = ({ children, isLastRow = false, isFirstCell = false, centerChildren = true }) => {
  let border = "";
  if (isLastRow || isFirstCell) {
    border = "tw-border-solid tw-border-gray-10 tw-border-l-0 tw-border-t-0";
    if (isLastRow && isFirstCell) {
      border += " tw-border-r tw-border-b";
    } else if (isLastRow) {
      border += " tw-border-b tw-border-r-0";
    } else {
      border += " tw-border-b-0 tw-border-r";
    }
  }

  return (
    <td className={border}>
      <div className={`tw-flex ${centerChildren ? "tw-justify-center" : ""}`}>{children ?? "--"}</div>
    </td>
  );
};

const RoutingMethodDisplay = {
  round_robin: "Round Robin",
  first_to_claim: "First to Claim",
};

const mapActivity = (tnId, info, agents) => ({
  id: tnId,
  name: info.name,
  routingMethod: info.routing_rule,
  totalCalls: +info.total,
  totalContacts: +info.completed,
  totalMissed: +info.missed,
  totalCallTime: +info.duration,
  uniqueCallers: +info.unique_callers,
  voicemails: +info.voicemail,
  isDeleted: info.is_deleted,
  statsByAgent: info.agents
    ?.map((a) => {
      const agent = agents.find((ai) => ai.id == a.user_id);

      if (!agent) {
        return null;
      }

      return {
        name: agent.full_name,
        avatar: agent.avatar_url,
        calls: +a.total,
        contacts: +a.completed,
        missed: +a.missed,
        callTime: +a.duration,
        uniqueCallers: +a.unique_callers,
        isOnTrackingNumber: a.agent_in_routings,
      };
    })
    .filter((a) => a !== null)
    .sort((a, b) => (a.isOnTrackingNumber === b.isOnTrackingNumber ? 0 : a.isOnTrackingNumber ? -1 : 1)),
});
