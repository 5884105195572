import React from "react";
import axios from "axios";
import Dropdown from "../../shared/v2/Dropdown";
import Modal from "../../shared/v2/Modal";
import Checkbox from "../../shared/v2/Checkbox/Checkbox";
import Button from "../../shared/v2/Button";
import Snackbar from "../../shared/v2/Snackbar";
import ErrorBanner from "../../shared/v2/ErrorBanner";

export default class DeleteAutoPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingError: false,
      selectedAutoPlanType: {
        isDisabled: false,
        label: "All",
        value: "all",
      },
      autoPlans: [],
      selectedAutoPlan: this.defaultAutoPlanSelect(),
      autoPlanOptions: [this.defaultAutoPlanSelect()],
      showConfirmModal: false,
      toggleOffTriggers: false,
      confirmCheckbox: false,
      showConfirmError: false,
      success: false,
    };
  }

  componentDidMount() {
    this.fetchAutoPlanList();
  }

  defaultAutoPlanSelect = () => {
    return {
      isDisabled: false,
      label: "All",
      value: "all",
    };
  };

  fetchAutoPlanList = () => {
    this.setState({ loading: true }, () => {
      let endpoints = [
        "/auto_plans.json?plan_type=people&page=null",
        "/auto_plans.json?plan_type=transactions&page=null",
      ];

      Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then(
          axios.spread((...allData) => {
            this.setState({ autoPlans: [...allData[0].data, ...allData[1].data], loading: false }, () => {
              this.selectAutoPlanType(this.defaultAutoPlanSelect());
            });
          }),
        )
        .catch(() => {
          this.setState({ loading: false, loadingError: true });
        });
    });
  };

  selectAutoPlanType = (type) => {
    const { autoPlans } = this.state;

    this.setState({ selectedAutoPlanType: type }, () => {
      if (type.value === "all") {
        this.setState({
          autoPlanOptions: this.formatAutoPlanOptions(autoPlans),
          selectedAutoPlan: this.defaultAutoPlanSelect(),
        });
      } else if (type.value === "Person") {
        this.setState({
          autoPlanOptions: this.formatAutoPlanOptions(autoPlans.filter((a) => a.plan_type === "people")),
          selectedAutoPlan: this.defaultAutoPlanSelect(),
        });
      } else if (type.value === "Transaction") {
        this.setState({
          autoPlanOptions: this.formatAutoPlanOptions(
            autoPlans.filter((a) => a.plan_type === "transactions"),
          ),
          selectedAutoPlan: this.defaultAutoPlanSelect(),
        });
      }
    });
  };

  formatAutoPlanOptions = (list) => {
    let arr = [
      this.defaultAutoPlanSelect(),
      ...list.map((a) => {
        return {
          isDisabled: false,
          label: `${a.name} - (${a.assigned_plans_count} Applied)`,
          value: a.id,
        };
      }),
    ];
    return arr;
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  totalAppliedCount = () => {
    const { autoPlans, selectedAutoPlanType, selectedAutoPlan } = this.state;

    if (selectedAutoPlan.value !== "all") {
      return autoPlans.filter((a) => a.id === selectedAutoPlan.value)[0].assigned_plans_count;
    } else if (selectedAutoPlanType.value === "all" && selectedAutoPlan.value === "all") {
      return autoPlans.reduce(function (a, b) {
        return a + b["assigned_plans_count"];
      }, 0);
    } else {
      return autoPlans
        .filter((a) => a.plan_type === selectedAutoPlanType.value)
        .reduce(function (a, b) {
          return a + b["assigned_plans_count"];
        }, 0);
    }
  };

  totalAutoPlanCount = () => {
    const { autoPlans, selectedAutoPlanType, selectedAutoPlan } = this.state;

    if (selectedAutoPlan.value !== "all") {
      return 1;
    } else if (selectedAutoPlanType.value === "all") {
      return autoPlans.length;
    } else {
      return autoPlans.filter((a) => a.plan_type === selectedAutoPlanType.value).length;
    }
  };

  dismissConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
      toggleOffTriggers: false,
      confirmCheckbox: false,
    });
  };

  handleSubmit = () => {
    const { selectedAutoPlanType, selectedAutoPlan, toggleOffTriggers, confirmCheckbox } = this.state;

    if (!confirmCheckbox) {
      this.setState({ showConfirmError: true });
      return false;
    } else {
      axios
        .post(`/support/accounts/${this.props.accountId}/mass_delete_auto_plans`, {
          assignable_type: selectedAutoPlanType.value,
          auto_plan_id: selectedAutoPlan.value,
          disable_triggers: toggleOffTriggers,
        })
        .then((res) => {
          this.setState({
            success: true,
          });
          this.dismissConfirmModal();
        })
        .catch((err) => {
          alert("There was an error deleting auto plans.");
        });
    }
  };

  confirmModal = () => {
    const { showConfirmModal, toggleOffTriggers, confirmCheckbox, showConfirmError } = this.state;
    return (
      <Modal
        show={showConfirmModal}
        onHide={this.closeConfirmModal}
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-w-[650px]"
      >
        <Modal.Header title="Confirm Deletion" className="tw-mb-32px" closeButton />

        <Modal.Body>
          <div>
            {showConfirmError && <ErrorBanner errors={["You must check the box to confirm."]} />}
            <p>
              {`This action will remove ${this.totalAutoPlanCount()} Auto Plan(s) from ${this.totalAppliedCount()} leads total. `}
              This will not delete the auto plan from Settings {">"} Auto Plans.
              <span className="tw-font-bold"> This action is irreversible.</span>
            </p>
            <Checkbox
              checked={toggleOffTriggers === true}
              onChange={(e) => this.setState({ toggleOffTriggers: !toggleOffTriggers })}
              label="Toggle off triggers for these auto plans"
            />
            <div className="tw-mt-5px tw-mb-5px"></div>
            <Checkbox
              checked={confirmCheckbox === true}
              onChange={(e) => this.setState({ confirmCheckbox: !confirmCheckbox })}
              label="I have read the above and understand the potential repercussions of this action"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="tw-mt-[32px]">
            <div className="tw-flex tw-justify-between">
              <div>
                <Button size="medium" schema="tertiary" onClick={this.dismissConfirmModal}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button size="medium" schema="warning" onClick={this.handleSubmit}>
                  Delete {this.totalAutoPlanCount()} Auto Plans
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  promptConfirm = () => {
    this.setState({ showConfirmModal: true });
  };

  render() {
    const { loading, loadingError, selectedAutoPlanType, autoPlanOptions, selectedAutoPlan, success } = this.state;
    return (
      <>
        <Snackbar
          show={success}
          schema="executed"
          position="top"
          closeOnTimeout={() => {
            this.setState({ success: false });
          }}
          timeout="5000"
          text="Auto Plans have been queued for deletion. This may take several minutes to complete."
        />
        <p className="tw-mb-[20px]">
          Deleting applied auto plans from contacts here does remove the plans from Settings -{">"} Auto Plans.
          <br />
          Choose between deleting all applied Auto Plans or a specific applied plan for all contacts.
        </p>
        {loading && (
          <div>
            <i className="fa fa-pulse fa-spinner" /> Loading Auto Plans...
          </div>
        )}
        {loadingError && (
          <div>There was an error loading the list of auto plans.</div>
        )}
        {(!loading && !loadingError) && (
          <div>
            <div className="tw-grid tw-grid-cols-2">
              <div className="tw-mr-[5px]">
                <Dropdown
                  menuShouldComeToFront
                  label="Plan Type (People / Transactions)"
                  name="auto_plan_type"
                  onChange={(selected) => {
                    this.selectAutoPlanType(selected);
                  }}
                  isClearable={false}
                  value={selectedAutoPlanType}
                  options={[
                    {
                      isDisabled: false,
                      label: "All",
                      value: "all",
                    },
                    {
                      isDisabled: false,
                      label: "People",
                      value: "Person",
                    },
                    {
                      isDisabled: false,
                      label: "Transactions",
                      value: "Transaction",
                    },
                  ]}
                />
              </div>
              <div className="tw-ml-[5px]">
                <Dropdown
                  menuShouldComeToFront
                  label="Auto Plan"
                  name="auto_plan_type"
                  onChange={(selected) => {
                    this.setState({ selectedAutoPlan: selected });
                  }}
                  isClearable={false}
                  value={selectedAutoPlan}
                  options={autoPlanOptions}
                />
              </div>
            </div>
            <div className="tw-mb-[20px]">
              <button
                className="btn btn-danger form-control delete-button tw-mb-20"
                onClick={this.promptConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        )}
        {this.confirmModal()}
      </>
    );
  }
}
