import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Proptypes from "prop-types";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import LoadingOverlay from "@shared/v2/LoadingOverlay";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import Error from "../Error";
import { getAvailablePlanAsThunk, submitAvailablePlanAsThunk } from "../actions/thunks";

const AdApplyPlan = ({ modalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const availablePlans = useSelector((state) => state.tdpMarketingReducer.availablePlan);
  const uuid = useSelector((state) => state.tdpMarketingReducer.uuid);
  const getAvailablePlanError = useSelector((state) => state.tdpMarketingReducer.getAvailablePlanError);
  const isLoading = useSelector((state) => state.tdpMarketingReducer.isLoading);
  const [selectedPlan, setSelectedPlan] = useState({});

  const planOptions = useMemo(
    () =>
      availablePlans.map((result) => ({
        value: result.id.toString(),
        label: result.name,
      })),
    [availablePlans],
  );

  useEffect(() => {
    if (modalOpen && uuid) dispatch(getAvailablePlanAsThunk(uuid));
    if (!modalOpen) setSelectedPlan({});
  }, [modalOpen, uuid]);

  const handleApplyPlan = () => {
    if (selectedPlan?.value) {
      dispatch(submitAvailablePlanAsThunk(uuid, selectedPlan.value, closeModal));
    }
  };

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
      show={modalOpen}
      onHide={closeModal}
    >
      <Modal.Header title="Apply Advertising Plan" closeButton />

      <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
        <LoadingOverlay isLoading={isLoading}>
          {getAvailablePlanError && <Error message={getAvailablePlanError.message} />}
          <div className="tw-flex tw-flex-col">
            <Dropdown
              label="Advertising Plan"
              disabled={isLoading}
              onChange={setSelectedPlan}
              placeholder="Select plan"
              name="plan"
              options={planOptions}
              value={selectedPlan || ""}
            />
            <TextButton
              className="tw-self-start"
              size="medium"
              onClick={() => window.location.assign("/action_plans")}
            >
              Set up a new advertising plan
            </TextButton>
          </div>
        </LoadingOverlay>
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[16px]">
        <Button
          disabled={isLoading}
          onClick={closeModal}
          className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
          schema="tertiary"
          size="medium"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleApplyPlan}
          className="tw-flex tw-items-center"
          schema="primary"
          size="medium"
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AdApplyPlan.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
};

AdApplyPlan.defaultProps = {
  modalOpen: false,
  closeModal: null,
};

export default AdApplyPlan;
