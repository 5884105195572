import { number, shape, string, bool } from "prop-types";

const listingDataShape = shape({
  streetAddress: shape({
    mls_streetAddress: string.isRequired,
    current_streetAddress: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  postalCode: shape({
    mls_postalCode: string.isRequired,
    current_postalCode: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  price: shape({
    mls_price: number.isRequired,
    current_price: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  mlsNumber: shape({
    mls_mlsNumber: string.isRequired,
    current_mlsNumber: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  beds: shape({
    mls_beds: number.isRequired,
    current_beds: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  baths: shape({
    mls_baths: string.isRequired,
    current_baths: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  sqFt: shape({
    mls_sqFt: string.isRequired,
    current_sqFt: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  city: shape({
    mls_city: string.isRequired,
    current_city: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  state: shape({
    mls_state: string.isRequired,
    current_state: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  county: shape({
    mls_county: string.isRequired,
    current_county: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  property_type: shape({
    mls_property_type: string.isRequired,
    current_property_type: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  neighborhood: shape({
    mls_neighborhood: string.isRequired,
    current_neighborhood: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  year_built: shape({
    mls_year_built: string.isRequired,
    current_year_built: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  parking: shape({
    mls_parking: number.isRequired,
    current_parking: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  foundation: shape({
    mls_foundation: string.isRequired,
    current_foundation: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  photosAvailable: shape({
    mls_photosAvailable: number.isRequired,
    current_photosAvailable: number.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
  description: shape({
    mls_description: string.isRequired,
    current_description: string.isRequired,
    isSelectable: bool.isRequired,
    mlsChecked: bool.isRequired,
    currentChecked: bool.isRequired,
  }).isRequired,
}).isRequired;

export default listingDataShape;
