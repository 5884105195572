/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const StarOutline = (props) => (
  <Icon {...props} name="Star Outline">
    <path d="m14.628 5.562-4.033-.614-1.803-3.819a.868.868 0 0 0-1.585 0L5.404 4.948l-4.033.614C.648 5.672.358 6.603.882 7.137L3.8 10.108l-.69 4.197c-.124.759.64 1.327 1.281.972l3.608-1.982 3.608 1.982c.64.352 1.405-.213 1.281-.972l-.69-4.197 2.918-2.971c.525-.534.235-1.465-.489-1.575zm-3.851 4.061.654 3.992L8 11.732l-3.431 1.883.654-3.992-2.777-2.827 3.837-.583L8 2.579l1.717 3.634 3.837.583-2.777 2.827z" />
  </Icon>
);

export default StarOutline;
