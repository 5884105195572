import React from 'react';
import { Modal } from 'react-bootstrap';

// TODO: render no MLS error
class BulkReportsStatusModal extends React.PureComponent {
  renderCreateStatus = () => {
    return this.props.mode === 'create' &&
    <div>
      Market Reports are being created and sent to {this.props.validAddressCount} recipients. Check back in a few
      minutes, or refresh the page until you see the updates applied.
    </div>
  }

  renderChangeStatus = () => {
    return this.props.mode === 'change' &&
    <div>
      Market Report drip frequency is being updated. This can take anywhere from 0-5 minutes. Check back in a few minutes,
      or refresh the page until you see the updates applied. Do not attempt to apply it again. If the frequency hasn't updated
      after 10 minutes, please contact by calling 855-427-4848.
    </div>
  }

  render() {
    const { closeStatusModal } = this.props;
    return(
      <Modal
       className='modal'
       backdrop='static'
       onHide={closeStatusModal}
       show>
       <Modal.Header closeButton>
         <Modal.Title>
           Market Report Actions
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
        {this.renderCreateStatus()}
        {this.renderChangeStatus()}
       </Modal.Body>
       <Modal.Footer>

        <button
          onClick={closeStatusModal}
          className='modal-submit'>
          <span>&nbsp;OK&nbsp;</span>
        </button>
      </Modal.Footer>
     </Modal>
    )
  }
}

export default BulkReportsStatusModal;
