/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UserGraduateSolidV6 = (props) => (
  <Icon {...props} name="User Graduate Solid V6">
    <path d="m1.426 2.492.199.047v1.828A.74.74 0 0 0 1.25 5c0 .262.145.481.347.617l-.488 1.945c-.055.216.067.437.238.437h1.305c.172 0 .293-.223.238-.409l-.488-1.972a.75.75 0 0 0 .348-.619.738.738 0 0 0-.375-.633V2.722L4 3.113V5c0 2.211 1.789 4 4 4s3.972-1.789 3.972-4L12 3.113l2.57-.621c.57-.137.57-.844 0-.984L8.62.07a2.753 2.753 0 0 0-1.238 0L1.426 1.508c-.566.137-.566.848 0 .984zm9.799 7.283L8 13 4.775 9.775C2.59 10.472 1 12.5 1 14.916c0 .6.485 1.084 1.083 1.084h11.834c.598 0 1.082-.484 1.082-1.084 0-2.416-1.591-4.444-3.775-5.141z" />
  </Icon>
);

export default UserGraduateSolidV6;
