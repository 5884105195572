import React from "react";
import Proptypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";

const TagTooltip = ({ person }) => (
  <span>
    {person.tag_tooltip_list && person.tag_tooltip_list.length > 0 && (
      <Tooltip
        placement="left"
        trigger={<i className="fa fa-tags text-danger" id="tagstool" />}
        content={
          <div className="people-tooltip-container">
            {person.tag_tooltip_list.map((tooltip) => (
              <span key={tooltip} className="people-tooltip-tags">
                {tooltip}
              </span>
            ))}
          </div>
        }
      />
    )}
  </span>
);
TagTooltip.propTypes = {
  person: Proptypes.shape({ tag_tooltip_list: Proptypes.arrayOf(Proptypes.string) }),
};
TagTooltip.defaultProps = {
  person: null,
};

export default TagTooltip;
