import React from "react";

import Dropdown from '@shared/v2/Dropdown';
import { GearSolidV6, PlusSolidV6 } from '@shared/v2/Icomoon';
import TextButton from "@shared/v2/TextButton";

const TemplateSelect = ({ templates = [], templateId, onTemplateChosen }) => {
  const options = templates.map(({id, name}) => ({label: name, value: id}));

  const handleTemplateSelect = ({value}) => {
    onTemplateChosen(value);
  };

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-justify-between tw-items-end tw-mb-8px">
        <span className="tw-text-14px tw-font-semibold tw-text-gray-50">Template</span>
        <TextButton
          size="medium"
          onClick={() => window.open("/listing_alert_templates", "_blank")}
        >
          <div className="tw-flex tw-gap-4px tw-items-center">
            <GearSolidV6 size="custom" className="tw-w-8px tw-h-8px" />
            <span className="tw-text-11d tw-font-bold">MANAGE TEMPLATES</span>
          </div>
        </TextButton>
      </div>
      <Dropdown
        menuShouldComeToFront
        isSearchable
        isClearable
        value={options.find(o => o.value === templateId)}
        options={options}
        onChange={handleTemplateSelect}
        placeholder="Custom Listing Alert"
        components={{ NoOptionsMessage }}
        className="tw-mb-4px"
      />
      <span className="tw-text-12px tw-text-gray-50">Start with a template or create a custom Listing Alert</span>
    </div>
  );
};

export default TemplateSelect;

const NoOptionsMessage = ({ componentProps }) => (
  <Dropdown.NoOptionsMessage
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    preserveStyle
  >
    <TextButton
      size="medium"
      onClick={() => window.location.href = "/listing_alert_templates" }
      className="tw-flex"
    >
      <div className="tw-flex tw-gap-4px tw-items-center">
        <PlusSolidV6 size="custom" className="tw-w-8px tw-h-8px" />
        <span className="tw-text-11d tw-font-bold">CREATE A LISTING ALERT TEMPLATE</span>
      </div>
    </TextButton>
  </Dropdown.NoOptionsMessage>
);
