import React from 'react';
import { Row, Col } from 'react-bootstrap';
import css from './report-styles.module.css';
import { ReportHeader } from './';

const LeadSourcesHeaders = ({sortBy, sortKey, sortDesc, sourceTypeTitle, isOtherSources}) => {
    return (
      <Col xs={12} className={css.noWrap}>
        <div className={css.singleReportBodyTitle}>
          <h5 className={css.downCaseH5}>
            {sourceTypeTitle}
          </h5>
        </div>
        <div className='th'>
          <ReportHeader
            name={isOtherSources ? 'other_source' : 'source'}
            className={`col-xs-2 ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Source'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_new' : 'new'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='New'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_unqualified' : 'unqualified'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Unqualified'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_watch' : 'watch'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Watch'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_nurture' : 'nurture'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Nurture'
            handleSort={sortBy} />
          <ReportHeader

            name={isOtherSources ? 'other_hot' : 'hot'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Hot'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_past' : 'past'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Past'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_inactive' : 'inactive'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Inactive'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_archived' : 'archived'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Archived'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_trash' : 'trash'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Trash'
            handleSort={sortBy} />
          <ReportHeader
            name={isOtherSources ? 'other_total_leads' : 'total_leads'}
            className={`col-xs-1 text-center ${css.backgroundWhite}`}
            sortDesc={sortDesc}
            sortKey={sortKey}
            label='Total Leads'
            handleSort={sortBy} />
        </div>
      </Col>
    )
  }
export default LeadSourcesHeaders;
