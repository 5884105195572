export const MODAL_CONTAINER_CLASSES =
  "tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-1050 tw-overflow-hidden tw-overflow-scrolling-touch tw-outline-0 tw-transition";
export const MODAL_BACKDROP_CLASSES =
  "tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-z-1040 tw-bg-black tw-opacity-50";
export const MODAL_DIALOG_CLASSES = "tw-relative tw-w-auto tw-p-10px";
export const MODAL_CONTENT_CLASSES =
  "tw-relative tw-bg-white tw-bg-clip-padding tw-border tw-border-solid tw-border-black tw-border-opacity-20 tw-rounded-8px tw-shadow-bootstrap-2 tw-outline-0 bs-sm:tw-shadow-bootstrap-3 bs-md:tw-shadow-bootstrap-3 bs-lg:tw-shadow-bootstrap-3 tw-p-[32px]";
export const SR_ONLY_CLASSES =
  "tw-absolute tw-w-px tw-h-px tw-p-0 tw--m-px tw-overflow-hidden tw-clip-rect-0 tw-border-0";

export const MODAL_TITLE_CLASSES = {
  medium: "tw-text-28px tw-m-0 tw-p-0 tw-font-normal",
  small: "tw-text-18px tw-m-0 tw-p-0 tw-font-normal",
};

export const MODAL_DESCRIPTION_CLASSES = {
  medium: "tw-text-14px tw-font-normal",
  small: "tw-text-12px tw-font-normal",
};

export const MODAL_CONTENT_WIDTH_CLASSES = {
  none: "",
  small: "tw-w-[440px]",
  medium: "tw-w-[616px]",
  large: "tw-w-[800px]",
};

export const availableHeaderSizes = Object.keys(MODAL_TITLE_CLASSES);

export const availableContentSizes = Object.keys(MODAL_CONTENT_WIDTH_CLASSES);
