import React from "react";
import DeleteActionModal from "./DeleteActionModal";
import DeletePlanModal from "./DeletePlanModal";
import PlanModal from "./PlanModal";
import PlanModalPreview from "./PlanModalPreview";
import AssignedPlanForm from "./AssignedPlanForm";

const renderDeleteActionModal = ({
  actionToDelete,
  assignedPlan,
  assignedPlansPath,
  closeModal,
  deleteAssignedAction,
  errorMessage,
  getAssignedPlan,
  hasError,
  isDeleteAssignedActionModalOpen,
  isDeletingAction,
  showPlanModal,
}) => {
  const assignedPlanPath = `${assignedPlansPath}/${assignedPlan.id}`;
  const showPlanCallback = () => showPlanModal(assignedPlan);
  const getPlanCallback = () => getAssignedPlan(assignedPlanPath);
  const afterDeleteCallback = () => {
    getPlanCallback();
    showPlanCallback();
  };

  return (
    <DeleteActionModal
      isDeleteAssignedActionModalOpen={isDeleteAssignedActionModalOpen}
      isDeletingAction={isDeletingAction}
      closeModal={() => closeModal(showPlanCallback)}
      action={actionToDelete}
      assignedPlanName={assignedPlan.name}
      onActionDelete={(id, plan) => deleteAssignedAction(id, plan, afterDeleteCallback)}
      errorMessage={errorMessage}
      hasError={hasError}
    />
  );
};

const renderDeletePlanModal = ({
  assignedPlanPath,
  closeModal,
  errorMessage,
  hasError,
  isDeletePlanModalOpen,
  isDeletingPlan,
  planToDelete,
  updateAssignedPlan,
}) => {
  const deletePlan = () => {
    const form = { state: "deleted" };
    updateAssignedPlan(`/assigned_plans/${planToDelete.id}`, form);
  };

  return (
    <DeletePlanModal
      isDeletePlanModalOpen={isDeletePlanModalOpen}
      isDeletingPlan={isDeletingPlan}
      closeModal={() => closeModal()}
      planToDelete={planToDelete}
      assignedPlanPath={assignedPlanPath}
      onPlanDelete={deletePlan}
      errorMessage={errorMessage}
      hasError={hasError}
    />
  );
};

const renderPlanModal = ({
  activeAssignedActionId,
  assignableId,
  assignableType,
  assignedPlan,
  assignedPlansPath,
  closeModal,
  deleteAssignedAction,
  getAssignedPlan,
  getAssignedPlans,
  isAssignedActionUpdating,
  isDeleteAssignedActionModalOpen,
  isPlanModalOpen,
  previewAssignedAction,
  showDeleteAssignedActionModal,
  showDeleteAssignedPlanModal,
  updateAssignedAction,
  updateAssignedPlan,
}) => {
  const assignedPlanPath = `${assignedPlansPath}/${assignedPlan.id}`;
  const closeModalCallback = () => getAssignedPlans(assignedPlansPath, assignableId, assignableType);

  return (
    <PlanModal
      assignedPlanPath={assignedPlanPath}
      closeModal={() => closeModal(closeModalCallback)}
      isPlanModalOpen={isPlanModalOpen}
      isAssignedActionUpdating={isAssignedActionUpdating}
      assignedPlan={assignedPlan}
      getAssignedPlan={getAssignedPlan}
      previewAssignedAction={previewAssignedAction}
      updateAssignedAction={updateAssignedAction}
      deleteAssignedAction={deleteAssignedAction}
      activeAssignedActionId={activeAssignedActionId}
      showDeleteAssignedActionModal={showDeleteAssignedActionModal}
      updateAssignedPlan={updateAssignedPlan}
      showDeleteAssignedPlanModal={showDeleteAssignedPlanModal}
      isDeleteAssignedActionModalOpen={isDeleteAssignedActionModalOpen}
    />
  );
};

const renderPlanPreviewModal = ({
  closeModal,
  isPlanPreviewModalOpen,
  autoPlanPreview,
  previewAutoPlanAction,
  activeAutoPlanActionId,
}) => (
  <PlanModalPreview
    autoPlanPreview={autoPlanPreview}
    closeModal={() => closeModal()}
    isPlanPreviewModalOpen={isPlanPreviewModalOpen}
    previewAutoPlanAction={previewAutoPlanAction}
    activeAutoPlanActionId={activeAutoPlanActionId}
  />
);

const renderApplyModal = ({
  assignedPlansPath,
  autoPlansPath,
  closeModal,
  isApplyModalOpen,
  planType,
  showPreviewPlanModal,
  submitApplyModal,
}) => (
  <AssignedPlanForm
    show={isApplyModalOpen} 
    onHide={() => closeModal()}
    assignedPlansPath={assignedPlansPath}
    onCloseAction={closeModal}
    onSubmitAction={submitApplyModal}
    showPreviewPlanModal={showPreviewPlanModal}
    planType={planType}
    autoPlansPath={autoPlansPath}
  />
);

export {
  renderApplyModal,
  renderDeleteActionModal,
  renderPlanModal,
  renderDeletePlanModal,
  renderPlanPreviewModal,
};
