/* eslint-disable react/prop-types */

import React from "react";
import Cluster from "../../../shared/v2/Cluster";
import IconButton from "../../../shared/v2/IconButton";
// eslint-disable-next-line import/no-cycle
import ItemsList from "./ItemsList";
import format from "../../../shared/formatters";

const Field = ({ value, name }) => (
  <div className="tw-space-x-1 tw-text-14d tw-font-semibold tw-text-neutral-gray-75">
    <span>{name}:</span>
    <span>{format.currency(value, 0)}</span>
  </div>
);

const EXCLUDE_AGGREGATED_COUNT = new Set(["gross_commission", "price", "closed_volume"]);

const totalListingsCount = (aggregations) => {
  if (aggregations.count !== undefined) {
    return Number(aggregations.count);
  }

  return Object.entries(aggregations).reduce((acc, [key, val]) => {
    if (EXCLUDE_AGGREGATED_COUNT.has(key)) return acc;
    return Number(acc) + Number(val);
  }, 0);
};
const Column = React.memo(
  ({
    column,
    index,
    onStatusChanged,
    updateListing,
    sources,
    agents,
    editFinancials,
    totals,
    onLoadMore,
    onDeletion,
    openAddTransactionModal,
    permissions,
  }) => {
    const columnTotals = totals[column.id];
    const totalListings = totalListingsCount(columnTotals);
    const totalGrossCommission = columnTotals.gross_commission;
    const totalVolume = columnTotals.closed_volume;

    return (
      <div className="column tw-bg-white tw-flex tw-flex-col tw-flex-grow tw-min-w-[322px] tw-h-full">
        <div className="tw-px-[16px] tw-pt-[12px] tw-mb-[12px] tw-flex-shrink-0">
          <Cluster justify="between" align="center">
            <Cluster justify="start" align="center" space="lg">
              <h3 className="column-title tw-m-0 tw-text-18d tw-capitalize">
                {column.title === "comingsoon" ? "coming soon" : column.title}
              </h3>
              <span className="column-items-length tw-m-0 tw-text-14d">{totalListings}</span>
            </Cluster>
            <IconButton
              size="small"
              schema="tertiary"
              onClick={() => openAddTransactionModal(column.title.toLowerCase())}
            >
              <span className="tw-text-[13px] tw-text-bold">+</span>
            </IconButton>
          </Cluster>
        </div>
        <ItemsList
          column={column}
          index={index}
          onStatusChanged={onStatusChanged}
          updateListing={updateListing}
          sources={sources}
          agents={agents}
          editFinancials={editFinancials}
          totals={totals}
          onLoadMore={onLoadMore}
          onDeletion={onDeletion}
          permissions={permissions}
        />
        <div className="tw-p-[12px] tw-bg-[#F8F7F6] tw-flex-shrink-0">
          <Cluster justify="between">
            <Field name={column.title === "sold" ? "GCI" : "Est. GCI"} value={totalGrossCommission} />
            <Field name={column.title === "sold" ? "Volume" : "Est. Volume"} value={totalVolume} />
          </Cluster>
        </div>
      </div>
    );
  },
);

export default Column;
