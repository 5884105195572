const defaultState = {
  isTriggerModalOpen: false,
  isTriggerDeleteModalOpen: false,
  triggerForm: null,
  hasError: false,
  errorMessage: null,
  isTriggerSubmitting: false,
};

const triggers = (currentState = {}, payload) => {
  const state = {...defaultState, ...currentState};

  switch (payload.type) {
    case 'FETCH_TRIGGERS':
    return {
      ...state,
      isFetchingTriggers: true
    }

    case 'FETCH_TRIGGERS_SUCCESS':
    return {
      ...state,
      isFetchingTriggers: false,
      triggers: payload.triggers
    }

    case 'CREATE_TRIGGER_SUCCESS':
    return {
      ...state,
      isTriggerModalOpen: false,
      isTriggerSubmitting: false,
      triggerForm: null,
      hasError: false,
      errorMessage: null,
    }

    case 'UPDATE_TRIGGER_SUCCESS':
    return {
      ...state,
      isTriggerModalOpen: false,
      isTriggerSubmitting: false,
      triggerForm: null,
      hasError: false,
      errorMessage: null,
    }

    case 'SAVE_TRIGGER_PENDING':
    return {
      ...state,
      isTriggerSubmitting: true,
      hasError: false,
      errorMessage: null,
    }

    case 'SAVE_TRIGGER_FAILURE':
    return {
      ...state,
      hasError: true,
      errorMessage: payload.errorMessage,
      isTriggerSubmitting: false,
    }

    case 'OPEN_TRIGGER_MODAL':
    return {
      ...state,
      isTriggerModalOpen: true,
      isTriggerSubmitting: false,
      triggerForm: payload.triggerForm,
      hasError: false,
      errorMessage: null,
    }

    case 'CLOSE_TRIGGER_MODAL':
    return {
      ...state,
      isTriggerModalOpen: false,
      triggerForm: null,
      hasError: false,
      errorMessage: null,
    }

    case 'OPEN_TRIGGER_DELETE_MODAL':
    return {
      ...state,
      isTriggerDeleteModalOpen: true,
      isTriggerDeleting: false,
      triggerToDelete: payload.triggerToDelete,
      hasError: false,
      errorMessage: null,
    }

    case 'DELETE_TRIGGER_SUCCESS':
    return {
      ...state,
      triggers: state.triggers.filter(trigger => trigger.id != payload.triggerId),
      isTriggerDeleting: true,
      hasError: false,
      errorMessage: null,
      isTriggerDeleteModalOpen: false,
    }

    case 'DELETE_TRIGGER_PENDING':
    return {
      ...state,
      hasError: false,
      message: null,
      isTriggerDeleting: true,
    }

    case 'DELETE_TRIGGER_FAILURE':
    return {
      ...state,
      hasError: true,
      errorMessage: payload.errors,
      isTriggerDeleting: false,
    }

    case 'CLOSE_TRIGGER_DELETE_MODAL':
    return {
      ...state,
      isTriggerDeleteModalOpen: false,
      isTriggerDeleting: false,
      triggerToDelete: null,
    }

    case 'UPDATE_TRIGGER_VALUE':
    return {
      ...state,
      triggerForm: { ...state.triggerForm, [payload.name]: payload.value }
    }

    default:
    return state;
  }
}

export default triggers;
