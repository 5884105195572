import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { PencilSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";
import AvatarGroup from "@shared/v2/AvatarGroup/AvatarGroup";
import Avatar from "@shared/v2/Avatar";
import Button from "@shared/v2/Button";
import IconButton from "@shared/v2/IconButton";
import Pagination from "@shared/v2/Pagination";
import Dropdown from "@shared/v2/Dropdown";
import { LeadAssignInfo } from "./shared";
import TrackingNumberModal from "./TrackingNumberModal";
import { Section } from "../../../Reports/components/BusinessDashboard";
import Error from "../../../Reports/components/DashboardShared/Error";

const TrackingNumbers = ({
  agentId,
  isAdmin,
  isPlace,
  agentsWithRoutingNumbers,
  trackingNumbers,
  sources,
}) => {
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [numberToEdit, setNumberToEdit] = useState(null);
  const [error, setError] = useState("");
  const [numbersToDisplay, setNumbersToDisplay] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const addTrackingNumber = () => {
    setShowTrackingModal(true);
  };

  const editTrackingNumber = (trackingNumber) => {
    setNumberToEdit({ ...trackingNumber });
    setShowTrackingModal(true);
  };

  const closeModal = () => {
    setShowTrackingModal(false);
    setNumberToEdit(null);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    setNumbersToDisplay(trackingNumbers.slice(offset, offset + pageSize));
  }, [trackingNumbers, offset, pageSize]);

  return (
    <Section className="tw-p-[32px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <span className="tw-text-gray-75 tw-m-0 tw-text-28px">Tracking Number Settings</span>
        {isAdmin ? (
          <Button size="medium" onClick={addTrackingNumber}>
            <div className="tw-flex tw-justify-center tw-items-center tw-gap-6px">
              <PlusSolidV6 />
              Add Tracking Number
            </div>
          </Button>
        ) : (
          ""
        )}
      </div>
      <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
      {trackingNumbers.length > 0 ? (
        <>
          <div className="tw-mb-32px tw-mt-16px">
            <span>
              Your team currently has{" "}
              <strong>
                {trackingNumbers.length} tracking number{trackingNumbers.length > 1 ? "s" : ""}
              </strong>
              .
            </span>
          </div>
          <div className="tw-mb-16px">
            <TrackingNumbersTable
              isAdmin={isAdmin}
              trackingNumbers={numbersToDisplay}
              sources={sources}
              onClickEdit={editTrackingNumber}
            />
          </div>
          {trackingNumbers.length > 10 && (
            <PageBar
              totalCount={trackingNumbers.length}
              currentOffset={offset}
              pageSize={pageSize}
              onChangePage={setOffset}
              onChangePageSize={setPageSize}
            />
          )}
        </>
      ) : (
        <div className="tw-flex tw-flex-col tw-mt-[66px] tw-mb-[50px] tw-items-center tw-justify-center tw-gap-[18px]">
          <span className="tw-text-18px tw-whitespace-nowrap">
            Looks like you have no tracking numbers active.
          </span>
          <span className="tw-max-w-[750px] tw-text-center">
            Make it easier to connect with your leads, gain insight into your marketing campaigns, and
            understand your ROI like never before with Tracking Numbers.
          </span>
        </div>
      )}
      {showTrackingModal && (
        <TrackingNumberModal
          isNewNumber={numberToEdit === null}
          agentId={agentId}
          agents={agentsWithRoutingNumbers}
          sources={sources}
          onClose={closeModal}
          trackingNumber={numberToEdit || {}}
          onNumbersChanged={refreshPage}
          isPlace={isPlace}
        />
      )}
    </Section>
  );
};

TrackingNumbers.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  agentId: PropTypes.number.isRequired,
};

const PageSizes = [10, 25, 50].map((i) => ({ label: i, value: i }));

const PageBar = ({ totalCount, currentOffset, pageSize, onChangePage, onChangePageSize }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPage = currentOffset ? currentOffset / pageSize + 1 : 1;
  const nextPage = currentPage === totalPages ? currentPage : currentPage + 1;
  const previousPage = currentPage === 1 ? currentPage : currentPage - 1;

  const onGoToPage = (pageNumber) => {
    const newOffset = (pageNumber - 1) * pageSize;
    onChangePage(newOffset);
  };

  const changePageSize = ({ value }) => {
    onChangePageSize(value);
  };

  return (
    <div className="tw-flex tw-flex-row tw-justify-between">
      <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
        <span>Rows per page:</span>
        <Dropdown
          onChange={changePageSize}
          value={PageSizes.find((p) => p.value === pageSize)}
          options={PageSizes}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        nextPage={nextPage}
        prevPage={previousPage}
        totalPages={totalPages}
        onGoToPage={onGoToPage}
      />
    </div>
  );
};

PageBar.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentOffset: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
};

const TableHeader = ({ children }) => <th className="tw-py-8px">{children}</th>;

const TableCell = ({ children }) => (
  <td className="tw-py-8px tw-pr-12px tw-max-w-[180px] tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden">
    {children}
  </td>
);

const TrackingNumbersTable = ({ isAdmin, trackingNumbers, onClickEdit, sources }) => {
  const sourcesHash = useMemo(
    () =>
      sources?.reduce((acc, { value, label }) => {
        acc[value] = label;

        return acc;
      }, {}),
    [sources],
  );

  return (
    <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0">
      <table className="tw-border-collapse tw-overflow-scroll tw-table-auto tw-flex-nowrap">
        <thead>
          <tr className="tw-text-12px tw-font-semibold tw-text-gray-75 tw-border-1px tw-border-solid tw-border-b-0 tw-border-x-0 tw-border-gray-15">
            <TableHeader>Tracking Number Name</TableHeader>
            <TableHeader>Number</TableHeader>
            <TableHeader>Members</TableHeader>
            <TableHeader>Source</TableHeader>
            <TableHeader>Routing Style</TableHeader>
            {/* <th className="tw-text-12px tw-font-semibold tw-text-gray-75 tw-py-8px">Voicemmail</TableHeader> */}
            {isAdmin ? <TableHeader>Edit</TableHeader> : ""}
          </tr>
        </thead>
        <tbody>
          {trackingNumbers.map((tn) => (
            <tr
              key={`tn-${tn.id}`}
              className="tw-h-36px tw-border-1px tw-border-solid tw-border-x-0 tw-border-gray-15"
            >
              <TableCell>{tn.name}</TableCell>
              <TableCell>{tn.number}</TableCell>
              <TableCell>
                <AvatarGroup size="medium">
                  {tn.users?.map((user) => (
                    <Avatar
                      key={`${tn.number}-${user.id}`}
                      size="medium"
                      src={user.avatarUrl}
                      alt={user.name}
                    >
                      {user.name}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </TableCell>
              <TableCell>{tn.source && sourcesHash[tn.source] ? sourcesHash[tn.source] : "--"}</TableCell>
              <TableCell>{LeadAssignInfo[tn.routingMethod].pretty}</TableCell>
              {isAdmin ? (
                <TableCell>
                  <IconButton schema="tertiary" onClick={() => onClickEdit(tn)}>
                    <PencilSolidV6 />
                  </IconButton>
                </TableCell>
              ) : (
                ""
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TrackingNumbersTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  trackingNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TrackingNumbers;
