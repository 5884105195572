import React from "react";
import PropTypes from "prop-types";
import { ArrowRightLongSolidV6 } from "../../../shared/v2/Icomoon";
import Tooltip from "../../../shared/v2/Tooltip/Tooltip";
import DashTooltip from "../DashboardShared/DashTooltip";
import { useTheme } from "../../../reducers/layoutReducer/selectors";

const TooltipRow = ({ label, value }) => (
  <div className="tw-flex tw-justify-between tw-text-14d">
    <span>{label}:</span>
    <span>{value}</span>
  </div>
);

TooltipRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TooltipRow.defaultProps = {
  value: "",
};

const TooltipContent = ({ data, type }) => (
  <div className="tw-w-[180px] tw-flex tw-flex-col tw-gap-[6px]">
    <div className="tw-text-start tw-text-14d tw-text-white tw-font-bold">
      Appointments {type === "set" ? "Set" : "Held"}
    </div>
    <div className="tw-h-[1px] tw-bg-white" />
    {type === "set" && (
      <>
        <TooltipRow label="Held" value={data?.held || 0} />
        <TooltipRow label="Upcoming" value={data?.upcoming || 0} />
        <TooltipRow label="Canceled" value={data?.appointmentCancelled || 0} />
        <TooltipRow label="No Show" value={data?.noShow || 0} />
      </>
    )}
    {type === "held" && (
      <>
        <TooltipRow label="Completed" value={data?.appointmentComplete || 0} />
        <TooltipRow label="Not A Fit" value={data?.notAFit || 0} />
        <TooltipRow label="Signed" value={data?.signedRecruit || 0} />
      </>
    )}
  </div>
);

TooltipContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.oneOf(["set", "held"]),
};

TooltipContent.defaultProps = {
  type: "set",
};

const PipelineStat = ({ completed, data, label, labelTooltip, value }) => {
  const isPlaceTheme = useTheme().name === "place-theme";
  const valueContent = data ? <Tooltip content={<TooltipContent data={data} />} trigger={value} /> : value;
  const completedContent = data ? (
    <Tooltip content={<TooltipContent type="held" data={data} />} trigger={completed} />
  ) : (
    completed
  );
  return (
    <div className="tw-text-center tw-flex-1 tw-flex tw-flex-col tw-gap-[8px] tw-min-w-[140px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 tw-border-l-0 tw-border-y-0 last-of-type:tw-border-x-0">
      <div className="tw-text-14d">
        {label} {labelTooltip && <DashTooltip text={labelTooltip} placement="top" />}
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-[12px] tw-text-28d">
        {valueContent}
        {typeof completed === "number" && (
          <ArrowRightLongSolidV6
            size="l"
            className={isPlaceTheme ? "tw-text-neutral-gray-50" : "tw-text-gray-30"}
          />
        )}
        {completedContent}
      </div>
    </div>
  );
};

PipelineStat.propTypes = {
  completed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PipelineStat.defaultProps = {
  completed: "",
  data: null,
  labelTooltip: undefined,
  value: 0,
};

export default PipelineStat;
