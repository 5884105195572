import React from "react";
import moment from "moment";
import Linkify from "linkify-react";
import Avatar from "@shared/v2/Avatar";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, StarRegularV6, StarSolidV6, TrashSolidV6 } from "@shared/v2/Icomoon";
import { EyeSlashSolidV6, EyeSolidV6 } from "../../../shared/v2/Icomoon";

const NoteCard = ({ note, onStar, onEdit, onDelete, onVisible, variant = "tall" }) => {
  return (
    <div
      className={`tw-shadow-card tw-bg-white tw-flex-none tw-flex tw-flex-col tw-gap-[12px] tw-p-[20px] tw-rounded-[8px] ${
        variant === "tall" ? "tw-w-[265px] tw-h-[227px]" : "tw-w-full tw-max-h-[168px]"
      }`}
    >
      <div className="tw-flex tw-justify-between tw-items-center">
        <span className="tw-text-sm tw-font-semibold tw-tracking-normal">
          {moment(note.created_at).format("MM/DD/YYYY")}
        </span>
        <div className="tw-flex tw-gap-[4px]">
          {onVisible && note.notable_type !== "Person" && (
            <IconButton
              size="small"
              schema={note.externally_visible ? "primary" : "tertiary"}
              onClick={onVisible}
              aria-label="toggle visibility"
            >
              {note.externally_visible ? <EyeSolidV6 /> : <EyeSlashSolidV6 />}
            </IconButton>
          )}
          <IconButton
            size="small"
            schema={note.starred ? "starred-yellow" : "tertiary"}
            onClick={onStar}
            aria-label="toggle starred"
          >
            {note.starred ? <StarSolidV6 /> : <StarRegularV6 />}
          </IconButton>
          <IconButton size="small" schema="tertiary" onClick={onEdit} aria-label="edit">
            <PencilSolidV6 />
          </IconButton>
          <IconButton size="small" schema="tertiary" onClick={onDelete}>
            <TrashSolidV6 />
          </IconButton>
        </div>
      </div>
      <div className="tw-flex-1 tw-overflow-auto">
        <Linkify
          onClick={(e) => e.stopPropagation()}
          tagName="div"
          options={{ defaultProtocol: "https", target: "_blank" }}
        >
          {note.content}
        </Linkify>
      </div>
      <a href={note.person_url} className="tw-flex tw-items-center tw-gap-[8px]">
        <Avatar size="small" key={note.id} src={note.user_avatar_url} alt={note.user_full_name} />
        <span className="tw-font-semibold">{note.user_full_name}</span>
      </a>
    </div>
  );
};

export default NoteCard;
