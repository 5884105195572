import { useEffect, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { useDebounce } from "@shared/hookHelpers";

const quickSearchTransforms = {
  Person: (data) => data.filter((p) => p.type === "lead"),
  Listing: (data) => data,
  default: (data) => data,
};

const quickSearch = ({ search, model }, signal) =>
  caseTransformingAxios.get("/accounts/search", { params: { search, "options[model]": model }, signal });

const useQuickSearch = ({ search, model, isCoborrower, coborrowers }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const debouncedSearch = useDebounce(search.trim(), 250);

  useEffect(() => setData([]), [model]);

  useEffect(() => {
    if (debouncedSearch.length < 1) return () => {};
    const abortController = new AbortController();
    setLoading(true);
    quickSearch({ search: debouncedSearch, model }, abortController.signal)
      .then((res) => {
        if (isCoborrower) {
          const getData = quickSearchTransforms[model || "default"](res.data);
          const result = getData.filter(
            (itemdata) => !coborrowers.some((itemcoborrowers) => itemcoborrowers.uuid === itemdata.uuid),
          );
          setData(result.map((d) => ({ ...d, value: d.objectId })));
        } else {
          setData(quickSearchTransforms[model || "default"](res.data));
        }
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err);
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [debouncedSearch]);

  return { loading, data, error };
};

export default useQuickSearch;
