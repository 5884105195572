import React from "react";

import { MapLocationSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import PipedElements from "../PipedElements";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

const MarketReportActivity = ({ activity, onReadChange }) => {
  const { marketReportLocation, marketReportPriceMin, marketReportPriceMax } = activity.data;

  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={MapLocationSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <PipedElements>
          <span>{marketReportLocation}</span>
        </PipedElements>
        <PipedElements>
          {(marketReportPriceMin || marketReportPriceMax) && (
            <span>{`Price: ${marketReportPriceMin} - ${marketReportPriceMax}`}</span>
          )}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

MarketReportActivity.propTypes = {
  ...activityCommonPropTypes,
};

MarketReportActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default MarketReportActivity;
