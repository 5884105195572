/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Avatar from "../../../shared/v2/Avatar";
import { XSolidV6 } from "../../../shared/v2/Icomoon";
import DragHandle from "../../../shared/v2/DragHandle";

const reorder = (array, oldIndex, newIndex) => {
  const result = Array.from(array);
  const [removed] = result.splice(oldIndex, 1);
  result.splice(newIndex, 0, removed);

  return result;
};

const RoundRobin = ({ agents, setAgents }) => {
  const removeAgent = (id) => {
    setAgents(agents.filter((agent) => agent.id !== id));
  };

  const onSortEnd = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    setAgents(reorder(agents, source.index, destination.index));
  };

  return agents?.length ? (
    <DragDropContext onDragEnd={onSortEnd}>
      <Droppable droppableId="round-robin-list">
        {(providedDroppable) => (
          <div
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
            className="tw-flex tw-flex-col tw-gap-24px"
          >
            {agents.length > 0 &&
              agents.map((agent, i) => (
                <Draggable index={i} draggableId={`draggable-${agent.id}`} key={agent.id}>
                  {(providedDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                    >
                      <RoundRobinAgent
                        dragHandleProps={providedDraggable.dragHandleProps}
                        agent={agent}
                        removeAgent={removeAgent}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {providedDroppable.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <span className="tw-text-gray-50">No users selected</span>
  );
};

RoundRobin.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAgents: PropTypes.func.isRequired,
};

export default RoundRobin;

const RoundRobinAgent = ({ dragHandleProps, agent, removeAgent }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-whitespace-nowrap">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <DragHandle {...dragHandleProps} className="tw-mr-8px tw-outline-none" />
    <Avatar className="tw-mr-8px" size="medium" src={agent.avatarUrl} alt={agent.name} />
    <span className="tw-overflow-ellipsis tw-overflow-x-hidden tw-mr-12px tw-font-normal">
      {agent.name} - {agent.routingNumber}
    </span>
    <div className="tw-ml-auto tw-cursor-pointer" onClick={() => removeAgent(agent.id)}>
      <XSolidV6 />
    </div>
  </div>
);

RoundRobinAgent.propTypes = {
  dragHandleProps: PropTypes.shape({}).isRequired,
  agent: PropTypes.shape({
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    routingNumber: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  removeAgent: PropTypes.func.isRequired,
};
