/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TrashSolidV6 = (props) => (
  <Icon {...props} name="Trash Solid V6">
    <path d="M5.225.553A1 1 0 0 1 6.119 0h3.763a1 1 0 0 1 .894.553l.225.447h3a1 1 0 1 1 0 2h-12a1 1 0 0 1 0-2h3l.225-.447zm8.112 14.013A1.502 1.502 0 0 1 11.84 16H4.159a1.504 1.504 0 0 1-1.497-1.434L1.972 4H14l-.663 10.566z" />
  </Icon>
);

export default TrashSolidV6;
