/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronRight = (props) => (
  <Icon {...props} name="Chevron Right">
    <path d="m12.327 8.585-6.702 6.702a.827.827 0 0 1-1.17 0l-.782-.782a.827.827 0 0 1-.001-1.169L8.983 8 3.672 2.664a.828.828 0 0 1 .001-1.169l.782-.782a.827.827 0 0 1 1.17 0l6.702 6.702a.827.827 0 0 1 0 1.17z" />
  </Icon>
);

export default ChevronRight;
