import React, { useEffect } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import * as creators from "../../actions/creators";

const mapStateToProps = (state) => ({
  showingSuccess: state.addMarketReport.showSuccess,
  showingError: state.addMarketReport.showError,
});

const AutoHideMessage = ({ showingSuccess, showingError, clearMessage }) => {
  useEffect(() => {
    if (showingError || showingSuccess) {
      const id = setTimeout(() => clearMessage(), 1000);
      return () => clearTimeout(id);
    }
    return () => null;
  }, [showingError, showingSuccess]);
  return showingSuccess || showingError ? (
    <div className="tw-absolute tw-z-[1500] tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-h-[132px] tw-w-[304px] tw-bg-white tw-rounded-xl tw-shadow-xl tw-flex tw-items-center tw-justify-center">
      <FontAwesomeIcon
        icon={showingSuccess ? faCheckCircle : faExclamationCircle}
        className={`tw-h-[31px] tw-min-w-[31px] tw-bg-white ${
          showingSuccess ? "tw-text-green-100" : "tw-text-red-500"
        }`}
      />
      <h3 className="tw-font-[28px] tw-my-0 tw-ml-[8px] tw-text-gray-75">
        {showingSuccess ? "Success" : "Error"}
      </h3>
    </div>
  ) : null;
};

AutoHideMessage.propTypes = {
  showingSuccess: Proptypes.bool,
  showingError: Proptypes.bool,
  clearMessage: Proptypes.func,
};

AutoHideMessage.defaultProps = {
  showingSuccess: false,
  showingError: false,
  clearMessage: null,
};

export default connect(mapStateToProps, creators)(AutoHideMessage);
