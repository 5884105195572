import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { FloatingForm } from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import Tag from "@shared/v2/Tag";
import TextButton from "@shared/v2/TextButton";
import { caseTransformingAxios } from "../../shared/v2/caseTransformingAxios";
import { addPeopleSmartFilterOption } from "../../reducers/layoutReducer";

const AppliedFilters = ({ clearLink, filters }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const saveFilter = (data) => {
    setLoading(true);
    return caseTransformingAxios.post("/user_filters.json", data).then((res) => {
      dispatch(addPeopleSmartFilterOption({ option: res.data }));
      setLoading(false);
      setName("");
      setIsOpen(false);
    });
  };

  const removeFilter = (filter) => {
    const removeQueryParams = new URLSearchParams(filter.param);
    const queryParams = new URLSearchParams(filters.map((f) => f.param).join("&"));

    Array.from(removeQueryParams.entries()).forEach(([key, value]) => {
      queryParams.delete(key, value || undefined);
    });

    window.location.replace(queryParams.size > 0 ? `?${queryParams}` : clearLink);
  };

  return (
    <div id="applied-filters" className="tw-flex tw-flex-wrap tw-gap-[4px]">
      {filters?.map((filter) => (
        <Tag
          key={filter.name || filter}
          removable={Boolean(filter.name)}
          onRemove={() => removeFilter(filter)}
        >
          {filter.name || filter}
        </Tag>
      ))}

      <TextButton className="tw-font-semibold" schema="sentence" onClick={() => setIsOpen(true)}>
        Save Filter
      </TextButton>

      <FloatingForm
        title="Save Filter"
        show={isOpen}
        primaryOption="Save"
        onPrimary={() => saveFilter({ name })}
        onCancel={() => setIsOpen(false)}
        isSecondaryLoading={loading}
        isPrimaryLoading={loading}
      >
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <p className="tw-mb-0">
            Your current filters will be saved as a Smart Filter in the left navigation.
          </p>
          <TextInput label="Filter Name" isRequired value={name} onChange={(e) => setName(e.target.value)} />
        </div>
      </FloatingForm>

      <TextButton
        className="!tw-text-gray-75"
        schema="sentence"
        onClick={() => window.location.assign(clearLink)}
      >
        Clear All
      </TextButton>
    </div>
  );
};

AppliedFilters.propTypes = {
  clearLink: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AppliedFilters;
