import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: ["tw-bg-transparent", "tw-border-0", "tw-outline-none", "tw-placeholder-neutral-gray-50"],
    focus: ["focus:tw-outline-none"],
    disabled: ["disabled:tw-placeholder-neutral-gray-30"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;

const inputSizeDictionary = {
  min: " ",
  full: "tw-flex-grow",
};

export const inputSizeValue = (inputSize) => inputSizeDictionary[inputSize] || inputSizeDictionary.full;
