import axios from "axios";
import { random } from "lodash";
import { tryCatchHandlr } from "./Utilities";

const files = [];
// Process AWS File
// - Get upload url from AWS
// - Upload the file to the AWS url

export const uploadFiles = ({ files, config = {}, onAdd, onProgress = () => {}, onSuccess, onError }) => {
  const url = config?.bucket;
  const dataFields = JSON.parse(config?.fields);
  if (!dataFields) {
    return;
  }
  onProgress(0);
  const fileProgress = {};
  const groupProgress = (idx, progressEvent) => {
    fileProgress[idx].total = progressEvent.total; // total will be slightly bigger than the file size bc of the extra meta data passed in the request
    fileProgress[idx].completed = progressEvent.loaded;

    const totalCompleted = Object.keys(fileProgress)
      .map((k) => fileProgress[k].completed)
      .reduce((prev, cur) => prev + cur, 0);

    const totalSize = Object.keys(fileProgress)
      .map((k) => fileProgress[k].total)
      .reduce((prev, cur) => prev + cur, 0);
    onProgress((totalCompleted / totalSize) * 100);
  };
  files.forEach(async (file, idx) => {
    const tmpId = random(0, 100000000, false);
    if (file.size <= 5000000) {
      fileProgress[idx] = { total: file.size, completed: 0 };
      onAdd({ id: tmpId, isLoading: true, name: file.name });
      const formData = new FormData();
      Object.keys(dataFields).forEach((field) => {
        formData.append(field, dataFields[field]);
      });
      formData.append("file", file);
      await saveFile({
        url,
        idx,
        name: file.name,
        formData,
        tmpId,
        onError,
        onProgress: groupProgress,
        onSuccess,
      });
    } else {
      onError({ name: file.name, id: tmpId, uploadError: true });
    }
  });
};

const saveFile = async ({ url, idx, name, tmpId, formData, onError, onProgress = () => {}, onSuccess }) => {
  const [resData, err] = await tryCatchHandlr(
    axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => onProgress(idx, progressEvent),
    }),
  );
  if (err) {
    return onError({ name, id: tmpId, saveError: true });
  }
  const data = new DOMParser().parseFromString(resData.data, "application/xml");
  console.log("data", data, data.querySelectorAll("PostResponse Location")[0].innerHTML);
  const path = data.querySelectorAll("PostResponse Location")[0]?.innerHTML;
  const fileName = data.querySelectorAll("PostResponse Key")[0]?.innerHTML?.split("/").last();
  onSuccess({ name: fileName, path, id: tmpId, isLoading: false });
};

export const downloadFile = (filePath, id) => {
  axios({
    url: filePath,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${id}.jpg`);
    link.click();
    URL.revokeObjectURL(href);
  });
};
