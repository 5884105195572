/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "@shared/v2/Dropdown";
import { headline2 } from "@shared/styles";
import FieldTextV1 from "../../shared/UtilsV1/FieldTextV1";
import FieldSelect from "../../shared/UtilsV1/FieldSelect";
import FieldRadioV1 from "../../shared/UtilsV1/FieldRadioV1";
import FieldDateV1 from "../../shared/UtilsV1/FieldDateV1";
import FieldSearchLabelsV1 from "../../shared/UtilsV1/FieldSearchLabelsV1";
import FieldSelectLabelsWithCheckboxOptions from "../../shared/UtilsV1/FieldSelectLabelsWithCheckboxOptions";
import FieldEmailV1 from "../../shared/UtilsV1/FieldEmailV1";
import FieldLabelV1 from "../../shared/UtilsV1/FieldLabelV1";
import Button from "../../shared/v2/Button";
import Toggle from "../../shared/v2/Toggle";
import FieldTextAreaV1 from "../../shared/UtilsV1/FieldTextAreaV1";
import { Plus } from "../../shared/v2/Icomoon";
import AddMLSSection from "../ModalSections/AddMLSSection";
import AddSponsorModal from "./AddSponsorModal";
import AddRevenuePartnerModal from "./AddRevenuePartnerModal";
import CertificationsAndDesignationsSection from "../ModalSections/CertificationsAndDesignationsSection";
import { useGetServiceAreaOptions } from "../../Settings/Users/helper/hookHelper";
import { containerView } from "./styles";
import Title from "../ModalSections/Title";
import { useCurrentUser, useFeatureFlags, useTheme } from "../../reducers/layoutReducer/selectors";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";
import LicenseNumber from "../ModalSections/LicenseNumber";

const REV_TYPE_MAP_SRC = {
  "Search PLACE users": "Search PLACE users",
  "Pre-PLACE": "Pre-PLACE",
  "No revenue partner": "No Revenue Partner in Agent Agreement",
};

const reshapeDropdownValue = (value) => ({ label: value, value });

function UserDetailsView() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const featureFlags = useFeatureFlags();
  const currentUser = useCurrentUser();
  const person = useContactPerson();
  const formOptions = useContactFormOptions();
  const [isAddingSponsor, setIsAddingSponsor] = useState(false);
  const [isAddingRevenuePartner, setIsAddingRevenuePartner] = useState(false);
  const [serviceAreaOptions, setServiceAreaOptions] = useState([]);
  const [serviceAreaInput, setServiceAreaInput] = useState("");
  const [fluentLanguageInput, setFluentLanguageInput] = useState("");

  const {
    agentHoursOptions,
    brokerageOptions,
    fluenciesOptions,
    positionOptions: basePositionOptions,
    specializationOptions,
  } = formOptions;

  const isOperator = person.displayedPosition === "Operator";

  const positionOptions = useMemo(() => {
    if (isOperator) return [...(basePositionOptions || []), { label: "Operator", value: "Operator" }];
    return basePositionOptions;
  }, [isOperator, basePositionOptions]);

  const isPlaceBrand = theme.name === "place-theme";

  const { licensed_since: licensedSince, organization_start_date: organizationStartDate } =
    person.milestones || {};
  const { active, role, lastSeen } = person.loginUserAttributes || {};
  const roleOptions = useMemo(
    () =>
      role === "Account Owner"
        ? [{ label: "Account Owner", value: "Account Owner" }]
        : [
            { label: "Agent", value: "Agent" },
            { label: "Administrator", value: "Administrator" },
          ],
    [role],
  );

  useGetServiceAreaOptions(serviceAreaInput, setServiceAreaOptions);

  const setPosition = () => {
    if (isOperator) return reshapeDropdownValue(person.displayedPosition);

    return person.position ? reshapeDropdownValue(person.position) : null;
  };

  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>User Details</div>

      {!active && (
        <>
          <div>This user has been archived</div>
          <FieldDateV1
            label="Last Day"
            value={lastSeen}
            onChange={(value) => dispatch(setPersonValue({ key: "loginUserAttributes.lastSeen", value }))}
            required
          />
          <FieldTextAreaV1
            label="Reason For Leaving"
            value={person.reasonForLeaving}
            onChange={(value) => dispatch(setPersonValue({ key: "reasonForLeaving", value }))}
          />
        </>
      )}
      <FieldSelect
        label="User Role"
        options={roleOptions}
        value={role}
        onChange={(value) => dispatch(setPersonValue({ key: "loginUserAttributes.role", value }))}
        defaultValue="Agent"
        toolTipPlacement="right"
        toolTipInfoContent={
          <div className="tw-text-left tw-text-12d">
            <div>
              <b>User Types</b> define each team member’s permissions within the account.
            </div>
            <div>
              <b>Administrators</b> and the <b>Account Owner</b> have access to everything.
            </div>
            <div>
              <b>Agents</b> cannot change settings or edit the account <i>and</i>
            </div>
            <div>can only view listings and people which they are granted access to.</div>
          </div>
        }
        disabled={!currentUser.account.isAdmin || role === "Account Owner"}
      />
      <Dropdown
        label="Position"
        isRequired
        placeholder="Select your position in the company"
        options={positionOptions}
        value={setPosition()}
        error={!setPosition() && "This field is required"}
        onChange={({ value }) => dispatch(setPersonValue({ key: "position", value }))}
        data-cy="select_position"
        disabled={isOperator}
      />
      <Dropdown
        label="Production Status"
        error={!person.agentStatus && "This field is required"}
        options={[
          { label: "Licensed - Active Agent", value: "Licensed - Active Agent" },
          { label: "Licensed - Inactive Agent", value: "Licensed - Inactive Agent" },
          { label: "Unlicensed", value: "Unlicensed" },
        ]}
        placeholder="Select production status"
        isRequired
        value={person.agentStatus && reshapeDropdownValue(person.agentStatus)}
        toolTipPlacement="right"
        toolTipInfoContent={
          <div className="tw-text-left tw-text-12d">
            <div>
              <b>Unlicensed:</b> User is not a licensed real estate agent.
            </div>
            <div>
              <b>Licensed - Inactive Agent:</b> User is a licensed real estate agent but does not perform
              transactions.
            </div>
            <div>
              <b>Licensed - Active Agent:</b> User is a licensed real estate agent and actively performs
              transactions.
            </div>
          </div>
        }
        onChange={({ value }) => dispatch(setPersonValue({ key: "agentStatus", value }))}
        data-cy="select_agent_status"
      />
      {!!person.agentStatus && person.agentStatus !== "Unlicensed" && (
        <>
          {person.agentStatus !== "Inactive" && (
            <FieldRadioV1
              label="Full / Part Time"
              value={person.agentHours}
              defaultValue="Full Time"
              options={agentHoursOptions}
              onChange={(value) => dispatch(setPersonValue({ key: "agentHours", value }))}
            />
          )}
          <FieldRadioV1
            label="Individual Real Estate LLC?"
            value={person.isRealEstateLlc ? "Yes" : "No"}
            options={["No", "Yes"]}
            onChange={(value) => {
              dispatch(setPersonValue({ key: "isRealEstateLlc", value: value === "Yes" }));
              if (value === "No") {
                dispatch(setPersonValue({ key: "individualRealEstateLlcName", value: null }));
              }
            }}
          />
          {person.isRealEstateLlc && (
            <FieldTextV1
              label="Real Estate LLC Name"
              value={person.individualRealEstateLlcName}
              onChange={(value) => dispatch(setPersonValue({ key: "individualRealEstateLlcName", value }))}
              required
            />
          )}
        </>
      )}
      <div className="tw-flex tw-gap-[24px]">
        <FieldDateV1
          label="Start Date at Team"
          value={organizationStartDate}
          onChange={(value) => dispatch(setPersonValue({ key: "milestones.organization_start_date", value }))}
        />

        {!!person.agentStatus && person.agentStatus !== "Unlicensed" && (
          <FieldDateV1
            label="Licensed Since"
            value={licensedSince}
            onChange={(value) => dispatch(setPersonValue({ key: "milestones.licensed_since", value }))}
          />
        )}
      </div>
      {!!person.agentStatus && person.agentStatus !== "Unlicensed" && (
        <>
          <FieldTextV1
            label="Contract Agreement Link"
            placeholder="Paste contract agreement URL"
            value={person.contractAgreementLink}
            onChange={(value) => dispatch(setPersonValue({ key: "contractAgreementLink", value }))}
          />
          <FieldSelect
            label="Brokerage Before Joining the Team"
            options={brokerageOptions}
            value={person.previousBrokerageName}
            defaultValue="None"
            onChange={(value) => dispatch(setPersonValue({ key: "previousBrokerageName", value }))}
          />
          <AddMLSSection />
          <LicenseNumber />
          <FieldSearchLabelsV1
            label="Service Areas"
            placeholder="Search by city or ZIP / postal code"
            options={serviceAreaOptions}
            value={person.serviceAreas}
            searchText={serviceAreaInput}
            setSearchText={setServiceAreaInput}
            onChange={(value) => {
              const tempServiceAreas =
                !person.serviceAreas || person.serviceAreas.length <= 0
                  ? [value]
                  : [...person.serviceAreas, value];

              dispatch(setPersonValue({ key: "serviceAreas", value: [...tempServiceAreas] }));
            }}
            onRemove={(target) =>
              dispatch(
                setPersonValue({
                  key: "serviceAreas",
                  value: person.serviceAreas.filter((item) => item !== target),
                }),
              )
            }
          />

          <FieldSelectLabelsWithCheckboxOptions
            label="Property Specializations"
            placeholder="Select property types"
            value={person.specializations}
            onChange={(value) => dispatch(setPersonValue({ key: "specializations", value: [...value] }))}
            options={specializationOptions}
            onRemove={(target) =>
              dispatch(
                setPersonValue(
                  "specializations",
                  person.specializations.filter((item) => item !== target),
                ),
              )
            }
          />
        </>
      )}

      <CertificationsAndDesignationsSection />
      <FieldSearchLabelsV1
        label="Additional Fluent Languages"
        options={fluenciesOptions}
        value={person.fluencies}
        searchText={fluentLanguageInput}
        setSearchText={setFluentLanguageInput}
        onChange={(value) => {
          const tempFluencies =
            !person.fluencies || person.fluencies.length <= 0 ? [value] : [...person.fluencies, value].sort();

          dispatch(setPersonValue({ key: "fluencies", value: [...tempFluencies] }));
        }}
        onRemove={(target) =>
          dispatch(
            setPersonValue({
              key: "fluencies",
              value: person.fluencies.filter((item) => item !== target),
            }),
          )
        }
      />
      {!featureFlags.userUpdate && <Title />}
      {isPlaceBrand && (
        <>
          {person.revenuePartnerType ? (
            <div className="tw-flex tw-gap-[24px]">
              <FieldTextV1
                label="Revenue Partner"
                toolTipInfoContent={
                  <div className="tw-text-left tw-text-12d">Who referred this user to PLACE.</div>
                }
                toolTipPlacement="top"
                readOnly
                value={
                  person.revenuePartnerType === "Person" ||
                  (!person.revenuePartnerType && person.revenuePartnerPersonName)
                    ? `${person.revenuePartnerPersonName}${
                        person.revenuePartnerPersonTeamName ? ` (${person.revenuePartnerPersonTeamName})` : ""
                      }`
                    : REV_TYPE_MAP_SRC[person.revenuePartnerType]
                }
              />
            </div>
          ) : (
            <Button
              className="tw-w-fit"
              onClick={() => setIsAddingRevenuePartner(true)}
              data-cy="add_revenue_partner_btn"
              disabled={!currentUser.account.isAdmin}
            >
              <Plus className="tw-mr-4px" size="s" />
              Revenue Partner
            </Button>
          )}
        </>
      )}

      {!isPlaceBrand && (
        <>
          {person.sponsorName ? (
            <div className="tw-flex tw-gap-[24px]">
              <FieldTextV1
                label="Sponsor Name"
                toolTipInfoContent={<div>Who referred this user to your company.</div>}
                disabled
                value={person.sponsorName}
              />
              <FieldEmailV1
                label="Sponsor Email"
                value={person.sponsorEmail}
                onChange={(value) => dispatch(setPersonValue({ key: "sponsorEmail", value }))}
              />
            </div>
          ) : (
            <Button
              className="tw-w-fit tw-flex tw-items-center"
              onClick={() => setIsAddingSponsor(true)}
              disabled={!currentUser.account.isAdmin}
            >
              <Plus className="tw-mr-4px" size="s" />
              Sponsor
            </Button>
          )}
        </>
      )}

      {isAddingSponsor && (
        <AddSponsorModal show={isAddingSponsor} onClose={() => setIsAddingSponsor(false)} />
      )}
      {isAddingRevenuePartner && (
        <AddRevenuePartnerModal
          show={isAddingRevenuePartner}
          onClose={() => setIsAddingRevenuePartner(false)}
        />
      )}
      {isPlaceBrand && (
        <div className="tw-flex tw-items-center tw-gap-[12px]">
          <Toggle
            id="roster_toggle"
            checked={person.exportToRoster === "false"}
            onChange={() =>
              dispatch(
                setPersonValue({
                  key: "exportToRoster",
                  value: person.exportToRoster === "true" ? "false" : "true",
                }),
              )
            }
            text={null}
            data-cy="roster-toggle_field"
          />
          <FieldLabelV1
            {...{
              label: "Hide user from Roster",
              toolTipInfoContent: (
                <div className="tw-text-left tw-max-w-[220px]">
                  <p>Turn on if user is not a unique representation of a real person in the PLACE network.</p>
                  <p>
                    Examples include:
                    <ul className="tw-pl-[20px]">
                      <li>Duplicates of PLACE users who already exist in another CRM</li>
                      <li>Users which do not represent real people (e.g. &quot;Marketing Manager&quot;).</li>
                    </ul>
                  </p>
                </div>
              ),
              toolTipPlacement: "right",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default UserDetailsView;
