import React from "react";
import { string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { CLIENTINTENT } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { isBuyerTenant } from "../../../shared/helpers";

import ReferralDesiredAddress from "../ReferralDesiredAddress";
import ReferralPropertyAddress from "../ReferralPropertyAddress";

const ReferralAddress = ({ clientIntent }) =>
  isBuyerTenant(clientIntent) ? <ReferralDesiredAddress /> : <ReferralPropertyAddress />;

export default tdpDetails({
  WrappedComponent: ReferralAddress,
  states: CLIENTINTENT,
});

ReferralAddress.propTypes = {
  clientIntent: string,
};

ReferralAddress.defaultProps = {
  clientIntent: null,
};
