/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowDown = (props) => (
  <Icon {...props} name="Arrow Down">
    <path d="m13.706 7.014.67.653a.69.69 0 0 1 0 .997l-5.863 5.717a.735.735 0 0 1-1.023 0L1.624 8.664a.69.69 0 0 1 0-.997l.67-.653a.738.738 0 0 1 1.035.012l3.464 3.544V2.118c0-.391.323-.706.724-.706h.966c.401 0 .724.315.724.706v8.452l3.464-3.544a.733.733 0 0 1 1.035-.012z" />
  </Icon>
);

export default ArrowDown;
