/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VerticalDots = (props) => (
  <Icon {...props} name="Vertical Dots">
    <path d="M9.887 8a1.886 1.886 0 1 1-3.774 0 1.886 1.886 0 1 1 3.774 0zm2.725-1.887a1.886 1.886 0 1 0 0 3.774 1.886 1.886 0 1 0 0-3.774zm-9.224 0a1.886 1.886 0 1 0 0 3.774 1.886 1.886 0 1 0 0-3.774z" />
  </Icon>
);

export default VerticalDots;
