import React, { useState } from "react";
import Proptypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import FilterPill from "@shared/v2/FilterPill";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import { CLEAR_MODAL_FILTER_ID } from "../../shared/constants";

const AgreementTypeSelect = ({ previousSelectedAgreementType }) => {
  const [selectedTypes, setSelectedTypes] = useState(previousSelectedAgreementType || []);

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => setSelectedTypes([]));

  const handleOnChange = (type) => {
    setSelectedTypes((prevSelectedType) => {
      if (prevSelectedType.includes(type)) {
        // If type exists, remove it
        return prevSelectedType.filter((item) => item !== type);
      }
      // If type doesn't exist, add it
      return [...prevSelectedType, type];
    });
  };

  return (
    <Row className="m-b-10">
      <Col xs={12}>
        <label htmlFor="selected_agreement_filters-type">Agreement</label>
      </Col>

      <Col xs={12} className="tw-ml-[10px]">
        <Row className="tw-gap-4px">
          {selectedTypes.map((type) => (
            <input
              id="selected_agreement_filters-type"
              type="hidden"
              key={type}
              name="leads_filter[selected_agreement_filters][agreement_type][]"
              value={type}
            />
          ))}
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("buyer")}
            selected={selectedTypes.includes("buyer")}
          >
            Buyer
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("seller")}
            selected={selectedTypes.includes("seller")}
          >
            Seller
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("referral")}
            selected={selectedTypes.includes("referral")}
          >
            Referral
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("none")}
            selected={selectedTypes.includes("none")}
          >
            None
          </FilterPill>
        </Row>
      </Col>
    </Row>
  );
};
AgreementTypeSelect.propTypes = {
  previousSelectedAgreementType: Proptypes.arrayOf(Proptypes.string),
};
AgreementTypeSelect.defaultProps = {
  previousSelectedAgreementType: [],
};
export default AgreementTypeSelect;
