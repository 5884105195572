import React from "react";
import { bool, func, string } from "prop-types";
import { connect } from "react-redux";
import Modal from "../../../../shared/v2/Modal/Modal";
import Button from "../../../../shared/v2/Button";
import { deletePersonAsThunk } from "../actions/thunks";
import ErrorBanner from "../../../../shared/v2/ErrorBanner";
import { clearError } from "../actions/creators";

const DeleteModal = ({ show, onHide, uuid, listingPersonId, onDeletePersonAsThunk, error, onClearError }) => (
  <Modal
    title="Delete"
    show={show}
    onHide={() => {
      onHide();
      onClearError();
    }}
    contentClassName="tw-w-[487px]"
    className="tw-flex tw-items-center tw-justify-center"
  >
    <Modal.Header
      className="tw-text-28px tw-font-normal tw-leading-36px tw-tracking-normal"
      title="Remove Person"
    />
    <Modal.Body className="tw-mb-[44px]">
      {error && <ErrorBanner errors={error} />}
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-12px tw-text-14px tw-font-normal tw-leading-20px tw-tracking-normal ">
        Are you sure you want to remove this person from the transaction?
      </div>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button
        size="medium"
        schema="tertiary"
        className="tw-mr-2"
        onClick={() => {
          onHide();
          onClearError();
        }}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        schema="warning"
        onClick={() => onDeletePersonAsThunk(uuid, listingPersonId).then(() => !error && onHide())}
      >
        Remove
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  error: state.tdpPeopleReducer.error.error,
});

const mapDispatchToProps = (dispatch) => ({
  onDeletePersonAsThunk: (uuid, listingPersonId) => dispatch(deletePersonAsThunk(uuid, listingPersonId)),
  onClearError: () => dispatch(clearError()),
});

DeleteModal.propTypes = {
  show: bool,
  onHide: func,
  uuid: string,
  listingPersonId: string,
  onDeletePersonAsThunk: func,
  error: [],
  onClearError: func,
};

DeleteModal.defaultProps = {
  show: false,
  onHide: () => {},
  uuid: "",
  listingPersonId: "",
  onDeletePersonAsThunk: () => {},
  error: [],
  onClearError: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
