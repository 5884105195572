import { pick } from "lodash";
import { camelizeKeys } from "../../../../shared/Utilities";

/** Converts an AssignedPlan/AutoPlan object from an API response into our desired representation */
export const planFromApi = (originalAutoPlan) => {
  const autoPlan = camelizeKeys(originalAutoPlan);
  if (autoPlan.state === "started") {
    autoPlan.state = "running";
  }

  return autoPlan;
};

/** Same as `planFromApi`, but extracts only the minimal set of fields required for our use-case. */
export const minimalPlanFromApi = (originalAssignedPlan) =>
  planFromApi(
    pick(originalAssignedPlan, [
      "id",
      "name",
      "state",
      "assigned_actions_count",
      "auto_plan_id",
      "total_completed_actions",
    ]),
  );

/**
 * Takes an array of `assignedPlans` and spits out an object, where each key is
 * a possible plan state, and the values are the plans currently in that state.
 */
export const indexAssignedPlansByState = (assignedPlans) =>
  assignedPlans.reduce(
    (acc, plan) => {
      acc[plan.state].push(plan);
      return acc;
    },
    { running: [], paused: [], completed: [], deleted: [] },
  );
