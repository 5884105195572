import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../shared/v2/Button";
import { Plus } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import PhoneView from "./PhoneView";
import { useFilterListOptions } from "../../../Settings/Users/helper/hookHelper";

const PhoneTypeOptions = [
  { label: "Home", value: "home", isTaken: false, index: null },
  { label: "Mobile", value: "mobile", isTaken: false, index: null },
  { label: "Work", value: "work", isTaken: false, index: null },
  { label: "Other", value: "other", isTaken: false, index: null },
];

function AddPhoneSection({ phoneList, required = false, setPersonValue, isDisplayByDefault = false }) {
  const [phoneOptions, setPhoneOptions] = useState([]);

  useFilterListOptions(PhoneTypeOptions, setPhoneOptions, phoneList, "category");

  const onAddPhone = () => {
    const defaultPhone = {
      category: phoneOptions.find((o) => !o.isTaken)?.value,
      name: "phone",
      position: phoneList.length + 1,
      value: "",
      tempId: Date.now(),
    };
    if (phoneList.length > 0) {
      setPersonValue({ key: "phoneDetailsAttributes", value: [...phoneList, { ...defaultPhone }] });
    } else {
      setPersonValue({ key: "phoneDetailsAttributes", value: [{ ...defaultPhone }] });
    }
  };

  useEffect(() => {
    if ((required || isDisplayByDefault) && phoneList.length <= 0) {
      onAddPhone();
    }
  }, []);

  return (
    <>
      {phoneList.length > 0 ? (
        <div>
          <div className="tw-flex tw-flex-col tw-gap-[24px]">
            {phoneList.map((phone, index) => (
              <PhoneView
                key={phone.id ? phone.id : phone.tempId}
                currentPhone={phone}
                index={index}
                removable={!((required || isDisplayByDefault) && phone.position === 1)}
                options={phoneOptions
                  .filter((option) => !option.isTaken || option.index === index)
                  .map((option) => ({ label: option.label, value: option.value }))}
                phoneList={phoneList}
                setPersonValue={setPersonValue}
              />
            ))}
          </div>
          <TextButton onClick={onAddPhone} className="tw-mt-[8px]" data-cy="add_phones_btn">
            <Plus size="s" /> Phone
          </TextButton>
        </div>
      ) : (
        <Button
          onClick={onAddPhone}
          className="tw-w-fit tw-flex tw-items-center"
          data-cy="add_init_phone_btn"
        >
          <Plus className="tw-mr-4px" size="s" /> Add Phone
        </Button>
      )}
    </>
  );
}

AddPhoneSection.propTypes = {
  required: PropTypes.bool,
  phoneList: PropTypes.arrayOf(PropTypes.shape({})),
  setPersonValue: PropTypes.func.isRequired,
  isDisplayByDefault: PropTypes.bool,
};

AddPhoneSection.defaultProps = {
  required: false,
  isDisplayByDefault: false,
  phoneList: [],
};

export default AddPhoneSection;
