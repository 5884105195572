import axios from "axios";

const route = "/support/super_users";

const jsonAcceptHeaders = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

const SuperUserRequests = {
  create: async (params) => {
    await axios.post(route, { super_user: params });
  },
  csvExport: () => window.open(`${route}.csv`),
  index: async (page) => {
    const response = await axios.get(
      `${route}?page=${page}`,
      jsonAcceptHeaders
    );

    return response.data;
  },
  search: async (searchTerm = "", roleFilter = "", page = 1) => {
    const response = await axios.get(
      `${route}?q=${searchTerm}&role=${roleFilter}&page=${page}`,
      jsonAcceptHeaders
    );

    return response.data;
  },
  update: async (id, params) => {
    await axios.put(
      `${route}/${id}`,
      { super_user: params }
    );
  },
  destroy: async (id) => {
    await axios.delete(`${route}/${id}`)
  }

};

export default SuperUserRequests;
