import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import capitalize from "capitalize";
import moment from "moment";
import shownAtDateFormat from "../constants";
import errorShape from "../model/error";
import feedbackShape from "../model/feedback";
import { interestLevelsOptions, interestLevelOptionByValue } from "../model/interestLevel";
import { showingTypesOptions, showingTypeOptionByValue } from "../model/showingTypes";
import FeedbackVisibility from "../FeedbackVisibility";
import RatingDropdownOption from "../RatingDropdownOption";
import RatingDropdownSingleValue from "../RatingDropdownSingleValue";
import ShowingTypeDropdownMenu from "../ShowingTypeDropdownMenu";
import Button from "../../../../shared/v2/Button";
import DatePicker from "../../../../shared/v2/DatePicker";
import Dropdown from "../../../../shared/v2/Dropdown";
import { errorForBanner } from "../../../../shared/v2/ErrorBanner";
import { FloatingForm } from "../../../../shared/v2/Modal";
import TextInput from "../../../../shared/v2/TextInput";
import Toggle from "../../../../shared/v2/Toggle";

const FeedbackForm = ({
  action,
  show,
  feedback,
  error,
  isLoading,
  showDeleteFeedbackPrompt,
  onNewFeedback,
  onUpdateFeedback,
  onCancel,
}) => {
  const [state, setState] = useState({
    feedback: { ...feedback },
  });

  useEffect(() => {
    setState({ ...state, feedback: { ...feedback } });
  }, [feedback]);

  // Make sure interest level has a value
  useEffect(() => {
    if (state.feedback.liked) {
      return;
    }

    setState({ ...state, feedback: { ...state.feedback, liked: 5 } });
  }, [state.feedback]);

  useEffect(() => {
    if (show) {
      return;
    }

    // Clear form state when the modal is closed
    setState({ ...state, feedback: { liked: 5 } });
  }, [show]);

  const memoizedErrors = useMemo(
    () => (error?.errors ? error.errors.map((err) => errorForBanner(err)) : []),
    [error],
  );

  const handleOnPrimary = () => {
    if (action === "new") {
      onNewFeedback(state.feedback);
    } else if (onUpdateFeedback) {
      onUpdateFeedback(state.feedback);
    }
  };

  const updateFeedbackField = (field, value) => {
    const data = { ...state.feedback };
    data[field] = value;

    setState({ ...state, feedback: data });
  };

  const footer = (
    <>
      <div className="tw-flex tw-flex-row tw-space-x-12px">
        <Button size="medium" schema="tertiary" data-cy="feedback-form-cancel-action" onClick={onCancel}>
          Cancel
        </Button>
        {action === "edit" && (
          <Button
            size="medium"
            schema="warning"
            onClick={() => {
              if (showDeleteFeedbackPrompt) {
                showDeleteFeedbackPrompt(feedback);
              }
            }}
            data-cy="feedback-form-delete-action"
          >
            Delete
          </Button>
        )}
      </div>
      <div className="tw-flex tw-flex-row tw-space-x-12px">
        <Button
          size="medium"
          schema="primary"
          onClick={handleOnPrimary}
          isLoading={isLoading}
          data-cy="feedback-form-primary-action"
        >
          {action === "new" ? "Add" : "Save"}
        </Button>
      </div>
    </>
  );

  return (
    <FloatingForm
      show={show}
      onCancel={onCancel}
      title={`${capitalize(action)} Showing Feedback`}
      errors={memoizedErrors}
      closeButton
      footer={footer}
      isLoading={isLoading}
      closeOnClickOutside
      closeOnEscape
    >
      {/* Use Tailwind JIT due to missing values for spacing/gap */}
      <div className="tw-grid tw-grid-cols-2 tw-gap-[24px]">
        <div>
          <label htmlFor="shownBy" className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-8px">
            Shown by
            <span className="tw-text-brivity-coral-100 tw-ml-4px tw-font-bold">*</span>
          </label>
          <TextInput
            id="shownBy"
            name="shownBy"
            type="text"
            value={state.feedback.shownBy ?? ""}
            onChange={(event) => updateFeedbackField("shownBy", event.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="showingType"
            className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-8px"
          >
            Showing Type
          </label>
          <Dropdown
            id="showingType"
            name="showingType"
            value={showingTypeOptionByValue(state.feedback.showingType)}
            onChange={(option) => updateFeedbackField("showingType", option?.value)}
            options={showingTypesOptions}
            components={{
              Menu: ShowingTypeDropdownMenu,
            }}
            isSearchable
            data-cy="feedback-form-showing-type-dropdown"
          />
        </div>
        <div>
          <label htmlFor="shownAt" className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-8px">
            Date
          </label>
          <DatePicker
            inputProps={{
              id: "shownAt",
              name: "shownAt",
              placeholder: "Select Date",
              "data-cy": "feedback-form-date-picker",
            }}
            value={state.feedback.shownAt?.format(shownAtDateFormat)}
            dateFormat={shownAtDateFormat}
            onChange={(value) => updateFeedbackField("shownAt", moment(value, shownAtDateFormat))}
          />
        </div>
        <div>
          <label htmlFor="liked" className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-8px">
            Interest Level
          </label>
          <Dropdown
            id="liked"
            name="liked"
            value={interestLevelOptionByValue(state.feedback.liked)}
            onChange={(option) => updateFeedbackField("liked", option.value)}
            options={interestLevelsOptions}
            components={{
              SingleValue: RatingDropdownSingleValue,
              Option: RatingDropdownOption,
            }}
            data-cy="feedback-form-rating-dropdown"
          />
        </div>
        <div className="tw-inline-flex tw-flex-row tw-items-center tw-justify-between tw-space-x-8px tw-mt-8px tw-mb-32px">
          <div className="tw-inline-flex tw-flex-row tw-items-center tw-space-x-8px">
            <FeedbackVisibility
              // Since we can be composing a new showing feedback `state.feedback.externallyVisible` could be null,
              // that's why we add the double logical not operator (!) here
              externallyVisible={!!state.feedback.externallyVisible}
              iconSize="l"
              data-cy={`feedback-form-externally-${state.feedback.externallyVisible ? "visible" : "hidden"}`}
            />
            <span className="tw-text-neutral-gray-75 tw-font-semibold">
              {`${state.feedback.externallyVisible ? "Visible to" : "Hidden from"} Viewers`}
            </span>
          </div>
          <Toggle
            id="externallyVisible"
            name="externallyVisible"
            // Since we can be composing a new showing feedback `state.feedback.externallyVisible` could be null,
            // that's why we add the double logical not operator (!) here
            checked={!!state.feedback.externallyVisible}
            onChange={() => updateFeedbackField("externallyVisible", !state.feedback.externallyVisible)}
            data-cy="feedback-form-externally-visible-toggle"
          />
        </div>
      </div>
      <div>
        <label htmlFor="body" className="tw-text-14d tw-text-neutral-gray-50 tw-font-semibold tw-mb-8px">
          Feedback
          <span className="tw-text-brivity-coral-100 tw-ml-4px tw-font-bold">*</span>
        </label>
        <TextInput
          id="body"
          name="body"
          placeholder="What did they think of the property?"
          value={state.feedback.body}
          onChange={(event) => updateFeedbackField("body", event.target.value)}
          multiline
          rows={4}
        />
      </div>
    </FloatingForm>
  );
};
FeedbackForm.propTypes = {
  action: PropTypes.oneOf(["new", "edit"]).isRequired,
  show: PropTypes.bool.isRequired,
  feedback: feedbackShape,
  error: errorShape,
  isLoading: PropTypes.bool.isRequired,
  showDeleteFeedbackPrompt: PropTypes.func,
  onNewFeedback: PropTypes.func.isRequired,
  onUpdateFeedback: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

FeedbackForm.defaultProps = {
  feedback: {},
  error: null,
  showDeleteFeedbackPrompt: null,
  onUpdateFeedback: null,
};

export default FeedbackForm;
