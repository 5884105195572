import React from "react";

const AgentListingFilters = ({availableStatuses, selectedStatuses, onToggleStatus}) => {
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-2 tw-whitespace-nowrap">
      <span className="tw-text-12px tw-text-gray-75">Filter listings:</span>
      {StatusOrder.filter(so => availableStatuses[so]).map((status) => {
        return (
          <FilterButton key={status} status={status} selectedStatuses={selectedStatuses} statusCount={availableStatuses[status]} onToggle={onToggleStatus} />
        )
      })}
    </div>
  );
};

export default AgentListingFilters;

// Use this to preserve the order of the filters
const StatusOrder = [
  "Active",
  "Pending",
  "Contingent",
  "Sold",
  "Off Market"
];

const FilterButton = ({status, selectedStatuses, statusCount, onToggle}) => {
  const active = selectedStatuses.includes(status) ? "active": "inactive";

  let textColor = FilterColor[status][active].textColor;
  let backgroundColor = FilterColor[status][active].bgColor;
  let borderColor = FilterColor[status][active].borderColor;
  let iconColor = FilterColor[status][active].iconColor;

  return (
    <button className={`tw-group tw-border tw-px-11px tw-py-3px tw-text-12px tw-border-solid tw-rounded-[16px] ${borderColor} ${backgroundColor}`} onClick={() => onToggle(status)}>
      <div className="tw-inline-flex tw-gap-x-[6px] tw-items-center">
        <div className={`tw-rounded-full tw-h-8px tw-w-8px tw-flex-1 ${iconColor}`}></div>
        <span className={`${textColor}`}>{status} ({statusCount})</span>
      </div>
    </button>
  )
};

const FilterColor = {
  "Active": {
    "active": {
      "textColor": "tw-text-semantic-green-120",
      "bgColor": "tw-bg-semantic-green-5",
      "borderColor": "tw-border-semantic-green-50",
      "iconColor": "tw-bg-semantic-green-100"
    },
    "inactive": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-transparent",
      "borderColor": "tw-border-gray-15 hover:tw-border-semantic-green-50",
      "iconColor": "tw-bg-semantic-green-50 group-hover:tw-bg-semantic-green-100"
    }
  },
  "Pending": {
    "active": {
      "textColor": "tw-text-semantic-yellow-120",
      "bgColor": "tw-bg-semantic-yellow-5",
      "borderColor": "tw-border-semantic-yellow-50",
      "iconColor": "tw-bg-semantic-yellow-100"
    },
    "inactive": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-transparent",
      "borderColor": "tw-border-gray-15 hover:tw-border-semantic-yellow-50",
      "iconColor": "tw-bg-semantic-yellow-50 group-hover:tw-bg-semantic-yellow-100"
    }
  },
  "Contingent": {
    "active": {
      "textColor": "tw-text-semantic-orange-120",
      "bgColor": "tw-bg-semantic-orange-5",
      "borderColor": "tw-border-semantic-orange-50",
      "iconColor": "tw-bg-semantic-orange-100"
    },
    "inactive": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-transparent",
      "borderColor": "tw-border-gray-15 hover:tw-border-semantic-orange-50",
      "iconColor": "tw-bg-semantic-orange-50 group-hover:tw-bg-semantic-orange-100"
    }
  },
  "Sold": {
    "active": {
      "textColor": "tw-text-semantic-blue-120",
      "bgColor": "tw-bg-semantic-blue-5",
      "borderColor": "tw-border-semantic-blue-50",
      "iconColor": "tw-bg-semantic-blue-100"
    },
    "inactive": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-transparent",
      "borderColor": "tw-border-gray-15 hover:tw-border-semantic-blue-50",
      "iconColor": "tw-bg-semantic-blue-50 group-hover:tw-bg-semantic-blue-100"
    }
  },
  "Off Market": {
    "active": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-gray-5",
      "borderColor": "tw-border-gray-50",
      "iconColor": "tw-bg-gray-75"
    },
    "inactive": {
      "textColor": "tw-text-gray-75",
      "bgColor": "tw-bg-transparent",
      "borderColor": "tw-border-gray-15 hover:tw-border-gray-50",
      "iconColor": "tw-bg-gray-50 group-hover:tw-bg-gray-75"
    }
  },

};
