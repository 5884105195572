import React from "react";
import Proptypes from "prop-types";
import RangeButtons from "../RangeButtons";

const BATH_OPTIONS = [
  { name: "Any", value: null },
  { name: "1+", value: "1.0" },
  { name: "1.5+", value: "1.5" },
  { name: "2+", value: "2.0" },
  { name: "2.5+", value: "2.5" },
  { name: "3+", value: "3.0" },
];
const BathsSelector = ({ value, onChange }) => (
  <div data-cy="bath-selector-wrapper" className="tw-mb-[24px]">
    <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Bath</span>
    <RangeButtons value={value} options={BATH_OPTIONS} onChange={onChange} />
  </div>
);

export default BathsSelector;

BathsSelector.propTypes = {
  value: Proptypes.arrayOf(Proptypes.number),
  onChange: Proptypes.func,
};

BathsSelector.defaultProps = {
  value: undefined,
  onChange: () => {},
};
