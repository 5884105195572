import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DatetimeRangePicker from "react-datetime-range-picker";
import { Dropdown } from "../../shared/v1";
import { dateRangeOptions } from "./reportHelpers";
import { statCardsTotal } from "./appointmentsReportHelper";
import {
  ReportHeaderSortContainer,
  UserTaskHolder,
  AgentReportRow,
  AgentReportTotalRow,
  LeadSourcesHolder,
  AllOrDateRangeRadios,
} from "./";
import AgentCallsHolder from "./AgentCallsHolder";
import AgentTextsHolder from "./AgentTextsHolder";
import AppointmentsStatCards from "./AppointmentsStatCards";
import AppointmentsContainer from "./AppointmentsContainer";
import ApptTypeFilter from "./ApptTypeFilter";
import BusinessDashboard from "./BusinessDashboard";
import AgentDashboard from "./AgentDashboard";
import OperatorDashboard from "./OperatorDashboard/OperatorDashboard";
import Leaderboards from "./Leaderboards/Leaderboards";
import PowerBIReport from "./FinancialDashboard/PowerBIReport";
import useSimpleNavigation from "../../shared/v2/useSimpleNavigation";
import Alert from "@shared/v2/Alert";
import { SpinnerSolidV6 } from "@shared/v2/Icomoon";
import OpenHouseDashboard from "./OpenHouseDashboard";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import Referrals from "./Referrals";

const Reports = (props) => {
  const {
    activeFilters,
    adminTasks,
    adminTotalCompletedTasks,
    adminTotalLateTasks,
    adminTotalOnTimeTasks,
    adminTotalTasks,
    agentList,
    agentPersonList,
    agents,
    agentTasks,
    agentTotalCompletedTasks,
    agentTotalLateTasks,
    agentTotalOnTimeTasks,
    agentTotalTasks,
    appointmentData,
    brivitySources,
    brivityStatusCounts,
    brivityTotalLeadSourceCount,
    callData,
    currentUserId,
    customDates,
    customRange,
    dateRange,
    error,
    fetchNewData,
    handleCustomDaySelect,
    handleDateRangeSelect,
    isActivityReportLoaded,
    isAdmin,
    isAllAppointmentsInRange,
    isAllLeadsInRange,
    isAllTasksInRange,
    isFetching,
    isPlaceUser,
    operatorDashboardEnabled,
    operatorDashboardGraphsEnabled,
    otherSources,
    otherStatusCounts,
    otherTotalLeadSourceCount,
    powerbiEnabled,
    powerbiSecondAuth,
    basePowerbiEnabled,
    basePowerbiSecondAuth,
    setError,
    setIsFetching,
    setLeadsConstraint,
    setTasksConstraint,
    sortBy,
    sortDesc,
    sortKey,
    textData,
    userId,
  } = props;
  const featureFlags = useFeatureFlags();
  const {
    query: { report_tab: activeReport },
    push,
  } = useSimpleNavigation();

  const [apptFilters, _setApptFilters] = useState({
    isBuyer:
      JSON.parse(localStorage.getItem("isBuyer")) === null
        ? true
        : JSON.parse(localStorage.getItem("isBuyer")),
    isBuyerSeller:
      JSON.parse(localStorage.getItem("isBuyerSeller")) === null
        ? true
        : JSON.parse(localStorage.getItem("isBuyerSeller")),
    isSeller:
      JSON.parse(localStorage.getItem("isSeller")) === null
        ? true
        : JSON.parse(localStorage.getItem("isSeller")),
    isRecruit:
      JSON.parse(localStorage.getItem("isRecruit")) === null
        ? true
        : JSON.parse(localStorage.getItem("isRecruit")),
    isOther:
      JSON.parse(localStorage.getItem("isOther")) === null
        ? true
        : JSON.parse(localStorage.getItem("isOther")),
  });
  const setApptFilters = (newState) => _setApptFilters((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    if (!activeReport) return push("/agent_stats?report_tab=appointments");
    switch (activeReport) {
      case "agentActivity":
        handleAgentActivityViewSwitch();
        break;
      case "agentCalls": {
        handleAgentCallsViewSwitch();
        break;
      }
      case "agentTexts": {
        handleAgentTextsViewSwitch();
        break;
      }
      case "leadSource": {
        handleLeadSourceViewSwitch();
        break;
      }
      case "tasks": {
        handleTaskViewSwitch();
        break;
      }
      case "appointments": {
        handleAppointmentsViewSwitch();
        break;
      }
    }
  }, [activeReport]);

  const activeReportTitle = {
    appointments: "Appointment Tracking",
    leadSource: "Lead Source",
    tasks: "Tasks",
    agentActivity: "Agent Activity",
    agentCalls: "Call Activity",
    agentTexts: "Text Activity",
  };

  const activeReportText = {
    leadSource: "Track the number of leads and status pipeline of all your leads.",
    tasks: "Track the number of assigned and completed tasks in a given date range.",
    agentCalls: "Track agent call statistics for a given date range.",
    agentTexts: "Track agent text statistics for a given date range.",
  };

  const sourceTypeTitleText = {
    leadSource: "Brivity Sources",
    tasks: "Administrators",
  };

  const handleAppointmentType = (type) => {
    if (type === "Buyer") {
      localStorage.setItem("isBuyer", JSON.stringify(!apptFilters.isBuyer));
      setApptFilters({ isBuyer: !apptFilters.isBuyer });
    }

    if (type === "Seller") {
      localStorage.setItem("isSeller", JSON.stringify(!apptFilters.isSeller));
      setApptFilters({ isSeller: !apptFilters.isSeller });
    }
    if (type === "Buyer/Seller") {
      localStorage.setItem("isBuyerSeller", JSON.stringify(!apptFilters.isBuyerSeller));
      setApptFilters({ isBuyerSeller: !apptFilters.isBuyerSeller });
    }
    if (type === "Recruit") {
      localStorage.setItem("isRecruit", JSON.stringify(!apptFilters.isRecruit));
      setApptFilters({ isRecruit: !apptFilters.isRecruit });
    }
    if (type === "Other") {
      localStorage.setItem("isOther", JSON.stringify(!apptFilters.isOther));
      setApptFilters({ isOther: !apptFilters.isOther });
    }
  };

  const handler = async (range) => {
    setIsFetching(true);
    try {
      await handleCustomDaySelect(range);
      await fetchNewData(
        "custom",
        activeReport,
        userId,
        activeFilters,
        range,
        activeReport === "agentActivity"
          ? isAllLeadsInRange
          : activeReport === "tasks"
            ? isAllTasksInRange
            : false,
      );
    } catch (e) {
      console.log("ERROR");
    }
  };

  const handleLeadConstraintChange = (isAllLeadsInRange) => {
    setIsFetching(true);
    setLeadsConstraint(isAllLeadsInRange);
    fetchNewData(dateRange, activeReport, userId, activeFilters, customDates, isAllLeadsInRange);
  };

  const handleTaskConstraintChange = (isAllTasksInRange) => {
    setIsFetching(true);
    setTasksConstraint(isAllTasksInRange);
    fetchNewData(dateRange, activeReport, null, null, customDates, isAllTasksInRange);
  };

  const handleAllOrDateRangeToggle = (reportType, allIsChecked) =>
    reportType === ("agentActivity" || "agentCalls" || "agentTexts" || "appointments")
      ? handleLeadConstraintChange(allIsChecked)
      : reportType === "tasks"
        ? handleTaskConstraintChange(allIsChecked)
        : null;

  const handleAgentActivityViewSwitch = () => {
    if (isActivityReportLoaded == false) {
      setIsFetching(true);
      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "agentActivity",
      );
    }
  };

  const handleAgentCallsViewSwitch = () => {
    if (callData.length == 0) {
      setIsFetching(true);
      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "agentCalls",
      );
    }
  };

  const handleAgentTextsViewSwitch = () => {
    if (textData.length == 0) {
      setIsFetching(true);
      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "agentTexts",
      );
    }
  };

  const handleTaskViewSwitch = () => {
    if (adminTasks.length == 0 || agentTasks.length == 0) {
      setIsFetching(true);
      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "tasks",
      );
    }
  };

  const handleAppointmentsViewSwitch = () => {
    if (!appointmentData.length) {
      setIsFetching(true);

      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "appointments",
      );
    }
  };

  const handleLeadSourceViewSwitch = () => {
    if (brivitySources.length == 0) {
      setIsFetching(true);
      fetchNewData(
        localStorage.getItem("date_range") !== null ? localStorage.getItem("date_range") : "last_seven_days",
        "leadSource",
      );
    }
  };

  const leadStats = statCardsTotal(apptFilters, appointmentData, dateRange);

  const isDashboardReport = [
    "operatorDashboard",
    "businessDashboard",
    "agentDashboard",
    "leaderboards",
    "openHouseDashboard",
    "powerbi",
    "basePowerbi",
    "referrals",
  ].includes(activeReport);

  return (
    <div className="tw-relative tw-h-full tw-overflow-x-auto tw-flex tw-flex-col">
      {activeReport === "referrals" && <Referrals />}

      {powerbiEnabled && activeReport === "powerbi" && (
        <PowerBIReport
          powerbiSecondAuth={powerbiSecondAuth}
          url="/api/v4/reporting/financial_dashboard_embed_config"
        />
      )}

      {basePowerbiEnabled && activeReport === "basePowerbi" && (
        <PowerBIReport
          powerbiSecondAuth={basePowerbiSecondAuth}
          url="/api/v4/reporting/base_financial_dashboard_embed_config"
        />
      )}

      {operatorDashboardEnabled && activeReport === "operatorDashboard" && (
        <OperatorDashboard operatorDashboardGraphsEnabled={operatorDashboardGraphsEnabled} />
      )}

      {isAdmin && activeReport === "businessDashboard" && <BusinessDashboard />}

      {activeReport === "agentDashboard" && <AgentDashboard agentList={agentPersonList} isAdmin={isAdmin} />}

      {activeReport === "leaderboards" && (
        <Leaderboards isPlaceUser={isPlaceUser} currentUserId={currentUserId} />
      )}

      {activeReport === "openHouseDashboard" && <OpenHouseDashboard />}

      {!isDashboardReport && (
        <div
          id="content"
          className="tw-h-full tw-overflow-x-auto !tw-p-[16px] tw-flex tw-flex-col tw-gap-[12px]"
        >
          {isFetching && (
            <div className="tw-z-3 tw-absolute tw-bg-white/50 tw-inset-0 tw-flex tw-justify-center tw-items-center">
              <SpinnerSolidV6 className="tw-animate-spin tw-w-[48px] tw-h-[48px]" size="custom" />
            </div>
          )}

          {!isFetching && error && (
            <div className="tw-mb-[12px]">
              <Alert
                variant="error"
                title="Whoops, something went wrong."
                text="There was a problem loading the data, please try again later or contact support."
                onClose={() => setError(false)}
              />
            </div>
          )}

          <div className="tw-flex tw-flex-col tw-gap-[12px]">
            <div className="tw-bg-white tw-p-[16px]">
              <h3 className="!tw-m-0 tw-text-24d">{activeReportTitle[activeReport]}</h3>
            </div>
            <div className="tw-bg-white tw-p-[16px] tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-[8px]">
              {["agentActivity", "tasks"].includes(activeReport) ? (
                <AllOrDateRangeRadios
                  report={activeReport}
                  allInRange={
                    activeReport === "agentActivity"
                      ? isAllLeadsInRange
                      : activeReport === "tasks"
                        ? isAllTasksInRange
                        : false
                  }
                  onChange={handleAllOrDateRangeToggle}
                />
              ) : activeReport === "appointments" ? (
                <ApptTypeFilter apptFilters={apptFilters} handleAppointmentType={handleAppointmentType} />
              ) : (
                <h6 className="!tw-m-0 tw-text-14d">{activeReportText[activeReport]}</h6>
              )}

              {activeReport === "leadSource" && agentList.length > 1 && (
                <div className="tw-flex tw-items-center tw-gap-[8px]">
                  <label className="tw-flex-shrink-0 tw-mb-0">Filter by user</label>
                  <Dropdown
                    className="!tw-w-[300px]"
                    value={userId || "all"}
                    options={agentList}
                    isSearchable
                    menuShouldScrollIntoView
                    onChange={(e) => fetchNewData(dateRange, activeReport, e.value, null, customDates)}
                    isClearable={false}
                  />
                </div>
              )}
              <div className="tw-whitespace-nowrap tw-flex tw-items-center tw-gap-[8px]">
                <h6>Date Range</h6>
                <div className="tw-w-[300px]">
                  <Dropdown
                    value={dateRange}
                    options={dateRangeOptions}
                    onChange={(e) => {
                      e.value !== "custom" && setIsFetching(true);
                      handleDateRangeSelect(
                        e.value,
                        activeReport,
                        userId,
                        activeFilters,
                        activeReport === "agentActivity"
                          ? isAllLeadsInRange
                          : activeReport === "tasks"
                            ? isAllTasksInRange
                            : activeReport === "appointments"
                              ? isAllAppointmentsInRange
                              : false,
                      );
                    }}
                    isSearchable={false}
                    isClearable={false}
                    // So all the options fit on-screen without having to scroll
                    variantOverrides={{ menuListMaxHeight: "100%" }}
                  />
                </div>

                {customRange && (
                  <DatetimeRangePicker
                    className="tw-flex"
                    closeOnSelect
                    locale="en"
                    timeFormat={false}
                    isValidStartDate={(current, selected) => current.toDate() < new Date()}
                    isValidEndDate={(current, selected) => current.toDate() <= new Date()}
                    startDate={customDates.start}
                    endDate={customDates.end}
                    onChange={handler}
                  />
                )}
              </div>
            </div>
            {activeReport === "appointments" && <AppointmentsStatCards leadStats={leadStats} />}
          </div>
          <Row className="!tw-m-[0px] tw-relative tw-overflow-auto">
            {((activeReport == "tasks" && adminTasks.length > 0) ||
              (activeReport != "tasks" && activeReport != "appointments")) && (
              <ReportHeaderSortContainer
                sortDesc={sortDesc}
                sourceTypeTitle={sourceTypeTitleText[activeReport]}
                isOtherSources={false}
                activeReport={activeReport}
                sortKey={sortKey}
                sortBy={sortBy}
              />
            )}
            {activeReport === "agentActivity" && (
              <Col xs={12} className="tw-bg-white tw-whitespace-nowrap">
                {agents.map((agent) => (
                  <AgentReportRow key={agent.id} report={agent} {...props} />
                ))}
                {agents.length > 1 && <AgentReportTotalRow {...props} />}
              </Col>
            )}
            {activeReport === "appointments" && (
              <AppointmentsContainer
                sortDesc={sortDesc}
                sortKey={sortKey}
                sortBy={sortBy}
                appointmentData={appointmentData}
                apptFilters={apptFilters}
              />
            )}
            {activeReport === "leadSource" && (
              <div>
                <Col
                  xs={12}
                  className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
                >
                  <LeadSourcesHolder
                    statusCounts={brivityStatusCounts}
                    totalLeadCount={brivityTotalLeadSourceCount}
                    sources={brivitySources}
                  />
                </Col>
                <ReportHeaderSortContainer
                  sortDesc={sortDesc}
                  sourceTypeTitle="Other Sources"
                  isOtherSources={true}
                  activeReport={activeReport}
                  sortKey={sortKey}
                  sortBy={sortBy}
                />
                <Col
                  xs={12}
                  className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
                >
                  <LeadSourcesHolder
                    isOtherSource={true}
                    statusCounts={otherStatusCounts}
                    totalLeadCount={otherTotalLeadSourceCount}
                    sources={otherSources}
                  />
                </Col>
              </div>
            )}

            {activeReport === "agentCalls" && (
              <Col
                xs={12}
                className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
              >
                <AgentCallsHolder agentCallData={props} />
              </Col>
            )}

            {activeReport === "agentTexts" && (
              <Col
                xs={12}
                className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
              >
                <AgentTextsHolder agentTextData={props} />
              </Col>
            )}

            {activeReport === "tasks" && (
              <div>
                {adminTasks.length > 0 && (
                  <Col
                    xs={12}
                    className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
                  >
                    <UserTaskHolder
                      agents={adminTasks}
                      totals={adminTotalTasks}
                      completedTotal={adminTotalCompletedTasks}
                      onTimeTotal={adminTotalOnTimeTasks}
                      lateTotal={adminTotalLateTasks}
                      isAdmin={true}
                    />
                  </Col>
                )}
                <ReportHeaderSortContainer
                  sortDesc={sortDesc}
                  sourceTypeTitle="Agents"
                  isAgentTasks={true}
                  activeReport={activeReport}
                  sortKey={sortKey}
                  sortBy={sortBy}
                />
                <Col
                  xs={12}
                  className="tw-whitespace-nowrap tw-mb-[20px] [&_img]:tw-w-[40px] [&_img]:tw-h-[40px]"
                >
                  <UserTaskHolder
                    isAdmin={false}
                    agents={agentTasks}
                    totals={agentTotalTasks}
                    completedTotal={agentTotalCompletedTasks}
                    onTimeTotal={agentTotalOnTimeTasks}
                    lateTotal={agentTotalLateTasks}
                  />
                </Col>
              </div>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Reports;
