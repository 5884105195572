import React from "react";
import Proptypes from "prop-types";
import moment from "moment";
import { Row, Col } from "../../../../shared/v1";
import ProfileIcon from "../ProfileIcon";
import { getImglink } from "../api/helpers";
import StatCardIcon from "./StatCardIcon";

const SocialAds = ({
  name,
  type,
  createdBy,
  startDate,
  endDate,
  coverImage,
  impressions,
  leads,
  budget,
  ctr,
  clicks,
  cpc,
  status,
}) => (
  <div className="tw-pt-[10px]">
    <div className="tw-rounded tw-border-solid tw-border tw-border-gray-10">
      <Row className="tw-m-[0px] tw-mx-[0px] tw-mb-[10px] tw-flex tw-items-center	tw-p-0 tw-px-[16px] tw-py-[12px] tw-bg-gray-lightest">
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={3}>
          <div className="tw-break-words tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 ">
            {name}
          </div>
          {type && (
            <div className="tw-break-words tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 ">
              {type}
            </div>
          )}
        </Col>
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={1}>
          <ProfileIcon
            imglink={getImglink(createdBy.avatar_collection)}
            name={`Created by ${createdBy.first_name} ${createdBy.last_name}`}
            initials={createdBy.initials}
          />
        </Col>
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={1} />

        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-pl-[12px] " size={5}>
          <div className=" tw-leading-[16px] tw-text-[11px] tw-font-bold tw-text-gray-75 ">
            DURATION:
            <span className="tw-pl-[8px] tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-75 ">
              {`${moment(startDate).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`}
            </span>
          </div>
        </Col>
        <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-justify-end tw-flex" size={2}>
          <div className="tw-uppercase tw-text-center tw-w-[74px] tw-h-[20px] tw-text-white tw-leading-[16px] tw-text-[11px] tw-font-bold tw-bg-semantic-green-50 tw-rounded-full	tw-py-[2px] tw-px-[8px]">
            {status}
          </div>
        </Col>
      </Row>
      <Row
        className={`tw-m-0 tw-mx-[0px] tw-p-0 tw-flex ${coverImage ? "tw-p-[24px]" : "tw-py-[24px] tw-px-0"}`}
      >
        {coverImage && (
          <Col
            className="tw-h-[180px] tw-max-w-full tw-block tw-transition tw-ease-out tw-delay-300 tw-drop-shadow-19 
          tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-h-[180px] tw-pr-[21px] tw-pl-[0px]"
            size={4}
          >
            <img
              alt="coverImage"
              className="tw-rounded tw-border-solid tw-border tw-border-gray-15 tw-block tw-h-[180px] tw-max-w-full tw-w-full tw-cursor-default"
              src={coverImage}
            />
          </Col>
        )}

        <Col
          className={`${
            coverImage && "tw-flex-wrap"
          } tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-flex tw-self-center`}
          size={coverImage ? 8 : 12}
        >
          <Row
            className={`tw-m-0 tw-mx-[0px] tw-p-0 tw-pb-[16px] tw-pt-[0px] tw-flex  tw-gap-[16px] ${
              coverImage ? "tw-p-[24px]" : "tw-px-0 tw-ml-[18px]"
            }`}
          >
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px] " size={4}>
              <div className="tw-p-[12px] tw-px-[5px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <i className="tw-pr-[4px] tw-text-[16px] fa fa-eye tw-text-teal" />
                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">
                  {impressions}
                </div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">Impressions</div>
              </div>
            </Col>
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px] " size={4}>
              <div className="tw-p-[12px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <i className="tw-pr-[4px] tw-text-[16px] fa fa-tint tw-text-teal" />
                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">{leads}</div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">Leads</div>
              </div>
            </Col>
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px] " size={4}>
              <div className="tw-p-[12px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <div className="tw-pb-[3px] tw-text-teal tw-leading-[12px] tw-text-[16px] tw-font-bold">
                  $
                </div>
                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">{budget}</div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">Budget</div>
              </div>
            </Col>
          </Row>
          <Row
            className={`tw-m-0 tw-mx-[0px] tw-p-0 tw-pb-[0px] tw-pt-[0px] tw-flex tw-gap-[16px] ${
              coverImage ? "tw-p-[24px]" : "tw-px-0 tw-ml-[-15px]"
            }`}
          >
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px]" size={4}>
              <div className="tw-p-[12px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <i className="tw-pr-[4px] tw-text-[16px] fa fa-percent tw-text-teal" />

                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">
                  {ctr.toFixed(2)}
                </div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">CTR</div>
              </div>
            </Col>

            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px]" size={4}>
              <div className="tw-p-[12px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <i className="tw-pr-[4px] tw-text-[16px] fa fa-hand-pointer-o tw-text-teal" />
                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">{clicks}</div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">Clicks</div>
              </div>
            </Col>
            <Col className="tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pl-[0px]" size={4}>
              <div className="tw-p-[12px] tw-w-[80px] tw-text-center tw-h-[80px] tw-bg-white tw-shadow-19 tw-rounded">
                <StatCardIcon />
                <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">
                  {cpc.toFixed(2)}
                </div>
                <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">CPC</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

SocialAds.propTypes = {
  name: Proptypes.string,
  type: Proptypes.string,
  createdBy: Proptypes.shape({
    avatar_collection: Proptypes.shape({}),
    imglink: Proptypes.string,
    first_name: Proptypes.string,
    last_name: Proptypes.string,
    initials: Proptypes.string,
  }),
  coverImage: Proptypes.string,
  startDate: Proptypes.string,
  endDate: Proptypes.string,
  budget: Proptypes.string,
  ctr: Proptypes.number,
  clicks: Proptypes.number,
  impressions: Proptypes.number,
  leads: Proptypes.number,
  cpc: Proptypes.number,
  status: Proptypes.string,
};
SocialAds.defaultProps = {
  name: "",
  type: "",
  createdBy: {
    avatar_collection: {},
    imglink: "",
    first_name: "",
    last_name: "",
    initials: "",
  },
  coverImage: "",
  budget: "0",
  startDate: moment().format(),
  endDate: moment().format(),
  ctr: 0,
  clicks: 0,
  impressions: 0,
  leads: 0,
  cpc: 0,
  status: "",
};
export default SocialAds;
