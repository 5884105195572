/* eslint-disable import/prefer-default-export  */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import saveLeadRoutingUsers from "../helpers/services";
import { tryCatchHandlr } from "../../shared/Utilities";
import { setSaving, setSaved, setSaveError } from "./creators";

export const saveForm =
  ({ leadRuleId, tab, selectedAgents, selectedPond }) =>
  (dispatch) => {
    dispatch(setSaving());
    const onSuccess = () => {
      dispatch(setSaved());
    };
    const onFailure = (error) => dispatch(setSaveError({ error }));
    const model = { "lead_rule_user[lead_rule_id]": leadRuleId };

    if (tab === "agents") {
      const makeCall = async () => {
        for (const agent of selectedAgents) {
          model["lead_rule_user[user_id]"] = agent.value;
          const [, err] = await tryCatchHandlr(saveLeadRoutingUsers(model));
          if (err) {
            onFailure();
          }
        }
        onSuccess();
      };
      makeCall();
    } else {
      model["lead_rule_user[lead_pond_id]"] = selectedPond.value;
      saveLeadRoutingUsers(model).then(onSuccess).catch(onFailure);
    }
  };
