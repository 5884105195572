/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const KeySolidV6 = (props) => (
  <Icon {...props} name="Key Solid V6">
    <path d="m8.822 10.741-1.069 1.013a.586.586 0 0 1-.503.247H6v1.25c0 .416-.334.75-.75.75H4v1.25c0 .416-.334.75-.75.75H.75a.749.749 0 0 1-.75-.75v-2.5c0-.2.079-.391.22-.531l5.04-5.041a5.505 5.505 0 0 1-.259-1.678 5.5 5.5 0 1 1 5.5 5.5c-.584 0-1.15-.091-1.678-.259zM11.75 5.5a1.25 1.25 0 1 0 .002-2.498A1.25 1.25 0 0 0 11.75 5.5z" />
  </Icon>
);

export default KeySolidV6;
