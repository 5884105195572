/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const StarRegularV6 = (props) => (
  <Icon {...props} name="Star Regular V6">
    <path d="M7.997.889c.256 0 .489.146.6.376l1.906 3.924 4.258.631a.666.666 0 0 1 .372 1.131l-3.089 3.061.731 4.319a.666.666 0 0 1-.269.653c-.206.147-.503.167-.703.047l-3.806-2.033-3.828 2.033c-.2.119-.472.1-.681-.047a.743.743 0 0 1-.292-.653l.753-4.319L.863 6.951a.664.664 0 0 1 .372-1.131l4.256-.631 1.906-3.924a.669.669 0 0 1 .6-.376zm0 2.193L6.539 6.089a.676.676 0 0 1-.503.369l-3.287.483 2.387 2.364a.672.672 0 0 1 .186.586l-.561 3.322 2.922-1.561a.66.66 0 0 1 .628 0l2.922 1.561-.561-3.322a.674.674 0 0 1 .189-.586l2.386-2.364-3.286-.483a.67.67 0 0 1-.503-.369L7.997 3.082z" />
  </Icon>
);

export default StarRegularV6;
