import React from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const { PANEL_BODY_CLASSES, PANEL_BODY_PADDING_CLASSES } = CLASSES;

const PanelBody = ({ className, deprecatedOverridePaddingClasses, children, ...props }) => {
  const classes = `
    ${PANEL_BODY_CLASSES}
    ${deprecatedOverridePaddingClasses || PANEL_BODY_PADDING_CLASSES}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

PanelBody.propTypes = {
  className: PropTypes.string,
  deprecatedOverridePaddingClasses: PropTypes.string,
  children: PropTypes.node,
};

PanelBody.defaultProps = {
  className: "",
  deprecatedOverridePaddingClasses: "",
  children: null,
};

export default PanelBody;
