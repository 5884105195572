import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import IconButton from "@shared/v2/IconButton";
import { Download, Trash } from "@shared/v2/Icomoon/Icons";
import colors from "../colors.module.css";
import documents from "../documents.module.css";
import icon from "../icons.module.css";

class DocumentRow extends Component {
  state = {
    checked: false,
    id: this.props.id,
  };

  handleClick = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }));
  };

  checkBoxClass = () => (this.state.checked ? icon.checked : "");

  render() {
    const {
      added_ago,
      file_type,
      human_readable_size,
      id,
      image_icon,
      naked_name,
      uploaded_by_image,
      uploaded_by_name,
      uploaded_by_url,
      url,
      expiring_url,
      onClickDeleteDoc,
    } = this.props;

    const onClickDownload = () => {
      if (expiring_url) {
        window.open(expiring_url, "_blank");
      }
    };

    return (
      <Row className={`${documents.documentRow} td`}>
        <Col xs={4} style={{ height: "51px" }}>
          <div className={icon.checkbox}>
            {/*TODO: when we have time we can implement the mass delete. Change to display: inline-block*/}
            <div style={{ display: "none", marginRight: "26px" }}>
              <label
                className={this.checkBoxClass()}
                style={{ height: "51px", display: "flex", alignItems: "center" }}
              >
                <input onClick={this.handleClick} type="checkbox" />
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: "51px", float: "right" }}>
              <span>{naked_name}</span>
            </div>
          </div>
        </Col>
        <Col xs={1}>
          <i className={`fa m-r-10 ${image_icon} ${colors.brivityBlue}`} style={{ fontSize: "19px" }} />
          <span>{file_type}</span>
        </Col>
        <Col xs={1}>
          <span>{human_readable_size}</span>
        </Col>
        <Col xs={2}>
          <span>{added_ago}</span>
        </Col>
        <Col xs={3}>
          <div className={documents.userImage} dangerouslySetInnerHTML={{ __html: uploaded_by_image }} />
          <span>
            <a href={uploaded_by_url} style={{ textDecoration: "none" }}>
              {uploaded_by_name}
            </a>
          </span>
        </Col>
        <Col xs={1}>
          <IconButton size="small" schema="tertiary" onClick={onClickDownload}>
            <Download className="!tw-w-[9px] !tw-h-[9px]" />
          </IconButton>
          <IconButton
            size="small"
            schema="tertiary"
            className="tw-ml-[6px]"
            onClick={() => onClickDeleteDoc(id)}
          >
            <Trash className="!tw-w-[9px] !tw-h-[9px]" />
          </IconButton>
        </Col>
      </Row>
    );
  }
}

export default DocumentRow;
