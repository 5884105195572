import React, { useState } from "react";
import { func } from "prop-types";

import useToggleHandlrs from "./hooks";
import { payloadPropTypes, PAYLOAD_DEFAULT } from "../propTypes";

import { Info } from "../../../../shared/v2/Icomoon";
import Toggle from "../../../../shared/v2/Toggle";
import HoverInfoTxt from "../HoverInfoTxt";

const ModalAddEditTaskToggles = ({ payloadHandlrs, payload }) => {
  const { recurringHandlr, contingentHandlr } = useToggleHandlrs(payloadHandlrs);
  const [isHoverInfoOn, setIsHoverInfoOn] = useState(false);

  return (
    <div className="tw-mt-24px tw-flex">
      <div className="tw-mr-32px">
        <Toggle
          labelClasses="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold"
          id="recurring--1"
          name="recurring--1"
          checked={!!payload.recurring}
          text="Recurring"
          onChange={recurringHandlr}
        />
      </div>
      <div className="tw-flex">
        <Toggle
          labelClasses="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-mr-4px"
          id="contingent--1"
          name="contingent--1"
          checked={!!payload.contingent}
          text="Contingent"
          onChange={contingentHandlr}
        />
        <Info onMouseEnter={() => setIsHoverInfoOn(true)} onMouseLeave={() => setIsHoverInfoOn(false)} />
        <HoverInfoTxt show={isHoverInfoOn} textStyling="tw-text-12px" arrowStyling="tw-mt-[36px]">
          Contingent tasks are due a specified amount of time before or after a specific event occurs
        </HoverInfoTxt>
      </div>
    </div>
  );
};

ModalAddEditTaskToggles.propTypes = {
  payloadHandlrs: func,
  payload: payloadPropTypes,
};

ModalAddEditTaskToggles.defaultProps = {
  payloadHandlrs: null,
  payload: PAYLOAD_DEFAULT,
};

export default ModalAddEditTaskToggles;
