import React, { useRef, useState } from "react";
import { models } from "powerbi-client";
import SecondAuthForm from "./SecondAuthForm";
import usePowerBi from "../../hooks/usePowerBi";

const PowerBIReport = ({ powerbiSecondAuth, url }) => {
  const powerBiRef = useRef(null);
  const [isPowerBiAuthenticated, setIsPowerBiAuthenticated] = useState(powerbiSecondAuth);
  usePowerBi({ ref: powerBiRef, shouldRun: isPowerBiAuthenticated, url });

  if (!isPowerBiAuthenticated) return <SecondAuthForm onSuccess={() => setIsPowerBiAuthenticated(true)} />;

  // TODO: display some sort of error message if getting a token fails?
  return <div ref={powerBiRef} className="tw-overflow-hidden tw-border-0 tw-flex-1" />;
};

export default PowerBIReport;
