import React from "react";
import PropTypes from "prop-types";
import { Prompt } from "../../shared/v2/Modal";

function CannotReActivateUserModal({ show, onClose }) {
  return (
    <Prompt
      description={
        <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-75">
          There are no additional seats available on your team. Please contact a Brivity support
          representative at 855-427-4848 to add additional users.
        </div>
      }
      onCancel={onClose}
      primaryOption="OK"
      onPrimary={onClose}
      show={show}
      title="Cannot Add/Activate User"
    />
  );
}

CannotReActivateUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CannotReActivateUserModal;
