import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { viewPhoneShape } from "@shared/models/Communications/viewPhone";
import Tooltip from "@shared/v2/Tooltip/Tooltip";
import DncFlag from "./DncFlag";

const Contact = ({ phone, isDnc, personName }) => {
  const flagged = phone && (phone.smsUnsubscribed || !phone.textable);
  const flaggedClasses = phone?.smsUnsubscribed ? "tw-text-semantic-red-100" : "tw-text-semantic-yellow-100";

  const warningIcon = phone?.smsIcon?.icon || null;
  const warningTooltip = phone?.smsIcon?.tooltip || null;
  const phoneNumber = phone?.formattedNumber;

  return (
    <label className="tw-text-14d tw-font-semibold tw-capitalize tw-flex tw-items-center tw-gap-[8px] tw-text-neutral-gray-75 tw-mb-0">
      <span>To:</span>
      <span className={flagged && flaggedClasses}>
        {personName} - {phoneNumber}
      </span>
      {flagged && warningIcon && (
        <Tooltip
          placement="bottom"
          className="tw-flex tw-flex-col"
          multiline
          content={<div className="tw-max-w-[184px]">{warningTooltip}</div>}
          trigger={warningIcon({ size: "l" })}
        />
      )}
      {isDnc && (
        <span>
          <DncFlag show={isDnc} />
        </span>
      )}
    </label>
  );
};

Contact.propTypes = {
  phone: PropTypes.shape(viewPhoneShape).isRequired,
  personName: PropTypes.string.isRequired,
  isDnc: PropTypes.bool,
};

Contact.defaultProps = {
  isDnc: false,
};
export default Contact;
