/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HouseCircleXmarkSolidV6 = (props) => (
  <Icon {...props} name="House Circle Xmark Solid V6">
    <path d="M8 10.4H6.4a.8.8 0 0 0-.8.8v2.2a1 1 0 0 1-1 1H3.203c-.038 0-.075-.002-.112-.005a.905.905 0 0 1-.09.005h-.4a1 1 0 0 1-1-1v-2.8l.002-.07V8.79H.802a.792.792 0 0 1-.801-.802c0-.225.075-.425.25-.6l6.41-5.587c.175-.175.375-.2.55-.2s.375.05.528.175l5.315 4.672A4.401 4.401 0 0 0 8.019 10.4h-.018zm.8.4c0-1.987 1.613-3.6 3.6-3.6S16 8.813 16 10.8a3.6 3.6 0 0 1-7.2 0zm5.082-.917a.396.396 0 0 0 0-.565.396.396 0 0 0-.565 0l-.918.917-.917-.917a.396.396 0 0 0-.565 0 .396.396 0 0 0 0 .565l.917.917-.917.918a.396.396 0 0 0 0 .565c.155.157.41.157.565 0l.917-.918.918.918c.155.157.41.157.565 0a.396.396 0 0 0 0-.565l-.917-.918.917-.917z" />
  </Icon>
);

export default HouseCircleXmarkSolidV6;
