import React, { useMemo } from "react";
import { Provider } from "react-redux";
import Header from "./Header";

const HeaderApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <Header />
    </Provider>
  );
};

export default HeaderApp;
