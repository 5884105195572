/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SpinnerSolidV3 = (props) => (
  <Icon {...props} name="Spinner Solid V3">
    <path d="M9.5 1.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 9.5 1.5zM8 13a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 8 13zm6.5-6.5a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 14.5 6.5zM3 8a1.5 1.5 0 1 0-3.001.001A1.5 1.5 0 0 0 3 8zm.404 3.096a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0-.001-3.001zm9.192 0a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0-.001-3.001zM3.404 1.904a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0-.001-3.001z" />
  </Icon>
);

export default SpinnerSolidV3;
