import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { useEffect, useState } from "react";

const useTags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    caseTransformingAxios("/api/v4/tags")
      .then((res) => setTags(res.data.map(({ name }) => ({ value: name, label: name }))))
      .catch((err) => console.log(err));
  }, []);

  return tags;
};

export default useTags;
