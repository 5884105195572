/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Col, Input, CancelButton } from "../../shared/v1";
import Error from "../../shared/Error";

const AutoPlansForm = ({
  autoPlanForm: propsAutoPlanForm,
  onCloseAction,
  autoPlansCreatePath,
  accountUuid,
  onFormChange,
  onSubmitAction,
  isSubmitting,
  hasError,
  errorMessage,
  planType,
}) => {
  const autoPlanForm = propsAutoPlanForm || {};
  const treatAssignableAsAssignedFor = planType === "people";
  const formToSubmit = {
    ...autoPlanForm,
    account_id: accountUuid,
    plan_type: planType,
    treat_assignable_as_assigned_for: treatAssignableAsAssignedFor,
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmitAction(autoPlansCreatePath, formToSubmit);
      }}
    >
      {hasError && <Error errorMessage={errorMessage} />}

      <p className="form-description">
        Please specify name for your new auto plan here. In <span className="bold">Auto Plans</span>, tasks
        and interactions will be created when the plan is applied.
      </p>

      <FormGroup horizontal>
        <Col size={12}>
          <label>Plan Name</label>
          <Input
            size="small"
            name="name"
            onChange={(e) => onFormChange(e.target.name, e.target.value)}
            value={autoPlanForm.name || ""}
          />
        </Col>
      </FormGroup>

      <FormGroup horizontal>
        <Col size={12}>
          <CancelButton size="small" className="modal-cancel" onClick={() => onCloseAction()} />
          <Button
            size="small"
            variant="info"
            className="tw-float-right"
            disabled={isSubmitting}
            onClick={() => onSubmitAction(autoPlansCreatePath, formToSubmit)}
          >
            Next
          </Button>
        </Col>
      </FormGroup>
    </form>
  );
};

export default AutoPlansForm;

AutoPlansForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanForm: PropTypes.objectOf(PropTypes.any).isRequired,
  onCloseAction: PropTypes.func.isRequired,
  autoPlansCreatePath: PropTypes.string.isRequired,
  accountUuid: PropTypes.string.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
};
