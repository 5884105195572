import React from "react";
import buttons from "../button.module.css";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

const DeleteAssignedPlanModal = ({ closeModal, deletePlan }) => (
  <Modal
    className="person-details-modal"
    onHide={closeModal}
    show
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
    contentClassName="tw-w-[480px] tw-space-y-32px tw-m-auto"
  >
    <Modal.Header title="Do you really want to delete this plan from this contact?" closeButton />
    <Modal.Body>
      <p>
        Deleting an auto plan from a contact will delete all incomplete tasks and interactions generated by
        this plan. You are still able to keep track of all completed tasks.
      </p>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button schema="secondary" size="medium" onClick={closeModal}>
        Cancel
      </Button>
      <Button schema="warning" size="medium" onClick={deletePlan}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteAssignedPlanModal;
