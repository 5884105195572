import React from "react";

import Search from "./Search";
import Upload from "./Upload";

const SearchUpload = () => (
  <div className="tw-flex tw-items-center tw-gap-20px tw-mb-20px">
    <Search />
    <Upload />
  </div>
);

export default SearchUpload;
