import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

const PasswordInput = (props) => {
  const { onChange, validationState, value } = props;

  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} md={2}>Password</Col>

      <Col md={10}>
        <FormControl
          type="password"
          name="password"
          value={value}
          onChange={onChange}
        />
      </Col>
    </FormGroup>
  );
}

export default PasswordInput;
