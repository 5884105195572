import React from "react";

const GlobalStatus = () => (
  <div id="global-status" className="tw-hidden">
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className="tw-flex tw-justify-between" href="#" aria-label="global status">
      <div className="status-message" />
      <div className="status-info" />
    </a>
  </div>
);

export default GlobalStatus;
