import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/assignedPlansCreators";
import { getAutoPlans } from "../../AutoPlans/actions/autoPlansActionCreators";
import AddAutoPlanModal from "../../shared/AddAutoPlanModal/AddAutoPlanModal";
import { autoPlanOptions } from "./helpers";

const AssignedPlanForm = ({
  autoPlansPath,
  assignableId,
  assignableType,
  assignedPlansCreators,
  assignedPlansPath,
  autoPlans,
  errorMessage,
  form,
  isModalSubmitting,
  onCloseAction,
  onSubmitAction,
  planType,
  showPreviewPlanModal,
  show,
}) => {
  const { auto_plan_id: autoPlanId } = form;
  const canOpenPreview = !isModalSubmitting && form.auto_plan_id;
  const planOptions = autoPlanOptions(autoPlans);

  useEffect(() => {
    assignedPlansCreators.getAutoPlans(autoPlansPath, planType);
  }, [autoPlansPath, planType]);

  return (
    <AddAutoPlanModal
      show={show}
      errors={errorMessage}
      onCancel={onCloseAction}
      onApply={() =>
        onSubmitAction(assignedPlansPath, {
          ...form,
          assignable_id: assignableId,
          assignable_type: assignableType,
        })
      }
      isApplying={isModalSubmitting}
      onPreview={() => showPreviewPlanModal(autoPlanId, assignableId, assignableType)}
      disableInteractionButtons={!canOpenPreview}
      availablePlansAsOptions={planOptions}
      selectedAutoPlan={planOptions.find((p) => p.value === autoPlanId)}
      onSelectAutoPlan={(opt) => assignedPlansCreators.updateValues("auto_plan_id", opt.value)}
    />
  );
};

AssignedPlanForm.propTypes = {
  assignableId: PropTypes.string.isRequired,
  assignableType: PropTypes.string.isRequired,
  assignedPlansCreators: PropTypes.shape().isRequired,
  assignedPlansPath: PropTypes.string.isRequired,
  autoPlans: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  autoPlansPath: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  form: PropTypes.shape().isRequired,
  // hasError: PropTypes.bool.isRequired,
  isModalSubmitting: PropTypes.bool.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  planType: PropTypes.string.isRequired,
  showPreviewPlanModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

AssignedPlanForm.defaultProps = {
  show: false,
};

const mapStateToProps = ({
  assignedPlans: {
    assignedPlanForm,
    assignableId,
    assignableType,
    isModalSubmitting,
    errorMessage,
    hasError,
  },
  autoPlans: { autoPlans },
}) => ({
  form: assignedPlanForm || {},
  assignableId,
  assignableType,
  autoPlans,
  isModalSubmitting,
  errorMessage,
  hasError,
});

const mapDispatchToProps = (dispatch) => ({
  assignedPlansCreators: bindActionCreators({ ...actions, getAutoPlans }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedPlanForm);
