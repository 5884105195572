import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class PublicActivityEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  // TODO: refactor all these in timelines app
  componentWillReceiveProps (nextProps) {
    this.setState({...this.state, ...nextProps})
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row header">
          <div className="col-xs-1">
            <i className={this.event.icon}/>
          </div>
          <div className="col-xs-8">
            <span className="active">{this.event.interaction_type}</span>
            <div className="timeline-description-text">
              {this.event.object_attributes.comment}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              {capitalize(this.event.interaction_type)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.event.object_attributes.comment}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
