import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TagV1 from "./TagV1";
import FieldLabelV1 from "./FieldLabelV1";
import styleSchema from "./FieldStyle";
import { Search, Close } from "../v2/Icomoon";
const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function FieldSearchLabelsV1({
  placeholder,
  options,
  value,
  onChange,
  onRemove,
  className,
  disabled,
  label,
  required,
  flexStyle,
  searchText,
  setSearchText,
}) {
  let schemaKey = disabled ? "disabled" : "regular";

  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isOnFocus, setIsOnFocus] = useState(false);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required }} />}
      <div className={`tw-flex tw-text-sm tw-items-center tw-relative ${className}`} ref={ref}>
        <input
          type="text"
          className={classes}
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => {
            let eValue = e.target.value;
            eValue.length > 0 && isDropdownOpen === false && setIsDropdownOpen(true);
            eValue.length === 0 && isDropdownOpen === true && setIsDropdownOpen(false);
            setSearchText(eValue);
          }}
          onFocus={() => {
            setIsOnFocus(true);
          }}
          onBlur={() => {
            setIsOnFocus(false);
          }}
        />

        <div
          className={`tw-absolute tw-right-[12px] tw-flex ${
            isOnFocus ? "tw-text-theme-text-button-color" : "tw-text-neutral-gray-30"
          }`}
        >
          {!searchText ? <Search size="xl" /> : <Close size="m" onClick={() => setSearchText("")} />}
        </div>

        {isDropdownOpen && (
          <div className="dropdown-content tw-border tw-border-solid tw-rounded tw-text-sm tw-whitespace-nowrap tw-absolute tw-z-10 tw-bg-white tw-min-w-full tw-top-[36px]">
            {options.length <= 0 ? (
              <div className="tw-px-[14px] tw-py-1">no matches</div>
            ) : (
              options
                .filter((option) => {
                  return option.toLowerCase().includes(searchText.toLowerCase());
                })
                .map((option) => {
                  return (
                    <div
                      key={option}
                      className="dropdown-item hover:tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1"
                      onClick={() => {
                        onChange(option);
                        setIsDropdownOpen(false);
                        setSearchText("");
                      }}
                      data-cy={`${option}_option`}
                    >
                      <p>
                        {option
                          .replace(
                            new RegExp(searchText, "gi"),
                            (highlight) => `<divider>${highlight}<divider>`,
                          )
                          .split("<divider>")
                          .map((section, index) => {
                            if (section.toLowerCase() === searchText.toLowerCase())
                              return <b key={index}>{section}</b>;
                            return section;
                          })}
                      </p>
                    </div>
                  );
                })
            )}
          </div>
        )}
      </div>
      {
        //labels group
      }
      {value?.length >= 1 && (
        <div className="tw-flex tw-gap-1 tw-flex-wrap tw-mt-3">
          {value.map((label) => {
            return (
              <TagV1
                text={label}
                key={label}
                onRemove={() => {
                  onRemove(label);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

FieldSearchLabelsV1.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
};

FieldSearchLabelsV1.defaultProps = {
  placeholder: "Search",
  label: "",
  options: ["Option1", "Option2"],
  value: [],
  required: false,
  flexStyle: "tw-flex-1",
  className: "",
  disabled: false,
  onChange: () => {},
  onRemove: () => {},
  searchText: "",
};

export default FieldSearchLabelsV1;
