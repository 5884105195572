import { numericalCompare, range, inRange, inSequence, uniqueArray } from "../../Utilities";

const paginationLeftBlock = (currentPage, maxEdgeSize) =>
  range(1, maxEdgeSize).filter((item) => inRange(1, currentPage - 1, item));

export const paginationCentralBlock = (currentPage, maxCentralNeighbors, totalPages) => {
  const leftCentral = [];
  const rightCentral = [];
  const leftEdge = Math.max(currentPage - maxCentralNeighbors, 1);
  const rightEdge = Math.min(currentPage + maxCentralNeighbors, totalPages);

  const withinLeft = (n) => inRange(leftEdge, currentPage - 1, n);
  const withinRight = (n) => inRange(currentPage + 1, rightEdge, n);
  const setCompleted = () => leftCentral.length + rightCentral.length === maxCentralNeighbors;

  range(1, maxCentralNeighbors).forEach((i) => {
    if (withinLeft(currentPage - i) && !setCompleted()) {
      leftCentral.push(currentPage - i);
    }
    if (withinRight(currentPage + i) && !setCompleted()) {
      rightCentral.push(currentPage + i);
    }
  });

  return [...leftCentral.sort(numericalCompare), currentPage, ...rightCentral.sort(numericalCompare)];
};

const paginationRightBlock = (currentPage, maxEdgeSize, totalPages) =>
  range(totalPages - maxEdgeSize + 1, totalPages).filter((item) => inRange(currentPage, totalPages, item));

const paginationBlocks = (currentPage, maxEdgeSize, maxCentralNeighbors, totalPages) => [
  paginationLeftBlock(currentPage, maxEdgeSize, maxCentralNeighbors),
  paginationCentralBlock(currentPage, maxCentralNeighbors, totalPages),
  paginationRightBlock(currentPage, maxEdgeSize, totalPages),
];

export const paginationOptions = ({ totalPages, currentPage, maxCentralNeighbors = 4, maxEdgeSize = 2 }) =>
  uniqueArray(paginationBlocks(currentPage, maxEdgeSize, maxCentralNeighbors, totalPages).flat());

export const paginationOptionsWithExplicitGaps = ({
  totalPages,
  currentPage,
  maxCentralNeighbors = 4,
  maxEdgeSize = 2,
}) =>
  paginationOptions({ totalPages, currentPage, maxCentralNeighbors, maxEdgeSize })
    .map((item, index, array) => (inSequence(array[index - 1] || 0, item) ? item : [null, item]))
    .flat();
