import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Loading from "../../../Goals/components/Loader";
import Error from "../DashboardShared/Error";
import useRecruitingSection from "./services/useRecruiting";
import { Section, SectionLabel, StatCard } from "../BusinessDashboard";
import Dropdown from "../../../shared/v2/Dropdown";
import CustomControl from "../BusinessDashboard/Dropdown/CustomControl";
import { CustomBasicSingleValue } from "../BusinessDashboard/Dropdown/CustomSingleValue";
import CustomValueContainer from "../BusinessDashboard/Dropdown/CustomValueContainer";
import getDateDropdownOptions from "../DashboardShared/helpers/getDateDropdownOptions";
import TextToggle from "../../../shared/v2/TextToggle/TextToggle";
import IconToggle from "../../../shared/v2/IconToggle/IconToggle";
import { ChartBarRegularV6, ChartLineRegularV6 } from "../../../shared/v2/Icomoon";
import Dot from "../BusinessDashboard/Dot";
import colors from "../DashboardShared/helpers/colors";
import GoalLinePlugin from "./utils/GoalLinePlugin";
import { STAT_CLASSES } from "./helpers/StatCard";
import CustomDropdownIndicator from "../BusinessDashboard/Dropdown/CustomDropdownIndicator";
import DashIcon from "../DashboardShared/DashIcon";
import DashTooltip from "../DashboardShared/DashTooltip";

const RecruitingSection = ({ year }) => {
  const MONTHLY_OVERVIEW_OPTIONS = useMemo(() => getDateDropdownOptions(year), [year]);
  const [agentType, setAgentType] = useState("activeAgents");
  const [chartType, setChartType] = useState("bar");
  const [rangeType, setRangeType] = useState(MONTHLY_OVERVIEW_OPTIONS[0]);
  const { actions, chartOptions, data, error, loading } = useRecruitingSection({
    agentType,
    rangeType,
    year,
  });

  return (
    <Section className="tw-relative tw-flex-1 tw-min-w-[320px] tw-gap-[24px]">
      <div className="tw-flex tw-justify-between tw-items-center">
        <SectionLabel>Agent Count & Net Added</SectionLabel>
        <Dropdown
          components={{
            Control: CustomControl,
            SingleValue: CustomBasicSingleValue,
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator,
          }}
          value={rangeType}
          onChange={setRangeType}
          options={MONTHLY_OVERVIEW_OPTIONS}
        />
      </div>
      <div>
        <TextToggle
          size="small"
          name="recruitingSelection"
          options={[
            {
              label: "Active Agents",
              value: "activeAgents",
            },
            {
              label: "Total Agents",
              value: "totalAgents",
            },
          ]}
          value={agentType}
          onChange={(e) => setAgentType(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-flex-wrap">
        {agentType === "activeAgents" && (
          <>
            <StatCard
              className={STAT_CLASSES}
              label="Active Agents"
              mainStat="Active Agents"
              stats={data?.summary.activeAgents}
              tooltip={
                <DashTooltip text="Agents who regularly perform transactions and whose production data is counted towards Per Agent Production. The agent's Start Date is required to count them here." />
              }
            />
            <StatCard
              className={STAT_CLASSES}
              label="Net Agents Gain"
              mainStat="Net Agents Gain"
              stats={{ "Net Agents Gain": data?.summary.netAgentsAddedYtd }}
            />
            <StatCard
              className={STAT_CLASSES}
              label="Agents Hired"
              mainStat="Agents Hired"
              stats={data?.summary.agentsHiredYtd}
            />
          </>
        )}
        {agentType === "totalAgents" && (
          <>
            <StatCard
              className={STAT_CLASSES}
              label="Total Agents"
              mainStat="Total Agents"
              stats={{ "Total Agents": { value: data?.summary.totalAgents } }}
            />
            <StatCard
              className={STAT_CLASSES}
              label="Net Agents Gain"
              mainStat="Net Agents Gain"
              stats={{ "Net Agents Gain": { value: data?.summary.netAgentsAddedYtd } }}
            />
          </>
        )}
      </div>
      <div className="tw-flex tw-justify-end">
        <IconToggle
          options={[
            { value: "bar", icon: <ChartBarRegularV6 /> },
            { value: "line", icon: <ChartLineRegularV6 /> },
          ]}
          onChange={(e) => setChartType(e.target.value)}
          value={chartType}
        />
      </div>
      {chartType === "bar" && (
        <Bar plugins={[ChartDataLabels, GoalLinePlugin]} data={data?.chartData} options={chartOptions} />
      )}
      {chartType === "line" && (
        <Line plugins={[ChartDataLabels, GoalLinePlugin]} data={data?.chartData} options={chartOptions} />
      )}
      {/** TODO: May need custom chart.js plugin for additional labels */}
      <div className="tw-flex tw-flex-wrap tw-justify-end tw-gap-[24px] tw-text-12d tw-text-neutral-gray-75">
        <div className="tw-flex tw-items-center tw-gap-[8px]">
          <Dot
            className={`!tw-mb-0 ${
              colors.theme === "place" ? "tw-bg-semantic-blue-100" : "tw-bg-brivity-blue-100"
            }`}
          />
          {agentType === "activeAgents" && <span>Full Time</span>}
          {agentType === "totalAgents" && "Total Agents"}
        </div>
        {agentType === "activeAgents" && (
          <>
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <Dot className="!tw-mb-0 tw-bg-semantic-yellow-100" />
              <span>Part Time</span>
            </div>
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <div className="tw-border tw-border-solid tw-border-neutral-gray-75 tw-w-[16px]" />
              Monthly Goal
            </div>
            <div className="tw-flex tw-items-center tw-gap-[8px]">
              <DashIcon />
              Annual Goal
              <DashTooltip
                placement="left"
                text="Annual goal for Active Agents. Configured in Team Business Goals on the Goals settings page."
              />
            </div>
          </>
        )}
      </div>
      <Error show={Boolean(error)} className="!tw-mb-0 tw-mt-[8px]" onClose={actions.clearError} />
      {loading && <Loading />}
    </Section>
  );
};

RecruitingSection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RecruitingSection;
