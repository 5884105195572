import React from "react";
import PropTypes from "prop-types";

import IconButton from "../../../../shared/v2/IconButton";
import { Pause, Play, Spinner, Trash } from "../../../../shared/v2/Icomoon";

export const PlayOrPauseButton = ({ isLoading, isPaused, onClick }) => {
  const PlayOrPauseIcon = isPaused ? Play : Pause;
  return (
    <IconButton
      disabled={isLoading}
      onClick={onClick}
      size="small"
      schema="tertiary"
      data-cy="pause-or-resume-plan-button"
    >
      {isLoading ? <Spinner /> : <PlayOrPauseIcon />}
    </IconButton>
  );
};
PlayOrPauseButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const TrashIcon = ({ isLoading, onClick }) => (
  <IconButton
    disabled={isLoading}
    onClick={onClick}
    size="small"
    schema="misc-trash"
    data-cy="delete-plan-button"
  >
    {isLoading ? <Spinner /> : <Trash />}
  </IconButton>
);
TrashIcon.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
