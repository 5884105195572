import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Error from "@shared/Error";
import Button from "@shared/v2/Button";

const DeleteActionModal = ({
  isDeleteAssignedActionModalOpen,
  isDeletingAction,
  closeModal,
  action,
  onActionDelete,
  assignedPlanName,
  hasError,
  errorMessage,
}) => {
  const assignedActionPath = `/assigned_plans/${action.assigned_plan_id}/assigned_actions/${action.id}`;

  return (
    <Modal
      show={isDeleteAssignedActionModalOpen}
      onHide={() => closeModal()}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-w-[480px] tw-space-y-32px tw-m-auto"
    >
      <Modal.Header title="Do you really want to delete this action?" closeButton />
      <Modal.Body>
        {hasError && <Error errorMessage={errorMessage} />}
        <p>
          <span>
            Please note that{" "}
            <span className="bold">
              {action.type}: {action.name}{" "}
            </span>
            is generated from action plan <span className="bold"> &quot;{assignedPlanName}&quot; </span>
            and deleting this action will delete all tasks and interactions contingent on that.
          </span>
        </p>
        {action && action.descendants && action.descendants.length > 0 && (
          <div className="p-t-20">
            <p>The following actions will also be deleted if this action is removed.</p>

            {action.descendants.map((descendant) => (
              <p key={descendant.id}>
                <span className="bold">
                  {descendant.type}: {descendant.name}
                </span>
              </p>
            ))}
          </div>
        )}
        <Modal.Footer className="tw-flex tw-justify-between">
          <Button schema="secondary" size="medium" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            schema="warning"
            size="medium"
            onClick={() => onActionDelete(action.id, assignedActionPath)}
            isLoading={isDeletingAction}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteActionModal;

DeleteActionModal.propTypes = {
  isDeleteAssignedActionModalOpen: PropTypes.bool.isRequired,
  isDeletingAction: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  onActionDelete: PropTypes.func.isRequired,
  assignedPlanName: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
