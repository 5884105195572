import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatExpense } from "../shared/FinancialViewHelpers";
import format from "../../../shared/formatters";

const TeamMemberExpense = ({ additionalExpense }) => {
  return (
    <div className="transaction-gci-content">
      <Row>
        <Col xs={2}>
          <label>{additionalExpense.name ? additionalExpense.name : ""}</label>
          {additionalExpense.expense_amount && (
            <span>{formatExpense(format.currency(additionalExpense.expense_amount))}</span>
          )}
        </Col>
        <Col xs={5}></Col>
        <Col xs={1}></Col>
        <Col xs={2} className="text-right"></Col>
        <Col xs={2} className="text-right">
          <label>Subtotal</label>
          <span>{format.currency(additionalExpense.line_total)}</span>
        </Col>
      </Row>
    </div>
  );
};

export default TeamMemberExpense;
