import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import Typography from "@shared/v2/Typography";
import { archivePost } from "../../reducers/websiteReducer/websiteReducer";

const ArchivePostModal = ({ onClose, post }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={Boolean(post)}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[400px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[24px]"
    >
      <Modal.Header title="Archive Post?" closeButton />
      <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
        <Typography variant="body-m">
          The blog post will no longer appear on any Brivity websites. You will be able to edit and publish
          the post in the future if you wish.
        </Typography>
        <Typography variant="body-m">Are you sure you want to archive this post?</Typography>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
        <Button size="medium" schema="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          schema="warning"
          onClick={() => {
            dispatch(archivePost(post)).then(({ error }) => {
              if (!error) onClose();
            });
          }}
        >
          Yes, Archive
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ArchivePostModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  post: PropTypes.shape(),
};

ArchivePostModal.defaultProps = {
  post: null,
};

export default ArchivePostModal;
