import React from 'react';
import alerts from './listing_alerts.module.css';

const SearchTermBubble = (props) => {
  const { term, removeSearchTerm } = props

  return (
    <div className={alerts.removableBubble}>
      <span>{term.label}: {term.multiSearch}</span>
      <i
        className={`${alerts.remove} fa fa-remove`}
        onClick={() => removeSearchTerm(term.multiSearch)}
      />
    </div>
  );
};

export default SearchTermBubble;
