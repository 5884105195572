import React from 'react';
import PropTypes from 'prop-types';

const EmptySmsHistory = ({ show, containerClassName }) => show && (
  <div className={`tw-flex tw-flex-col tw-items-center tw-justify-center ${containerClassName}`}>
    <div className="tw-text-center tw-text-gray-50 tw-w-[458px]">
      <div className="tw-text-14d tw-mb-[8px]">
        You haven’t exchanged any text messages with this person yet.  
      </div>
      <div className="tw-text-12d tw-italic">
        Note: First messages to this sender will feature “By the way, if you prefer not to be texted just tell me to stop” for compliance.
      </div>
    </div>
  </div>
)

EmptySmsHistory.propTypes = {
  show: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
};

EmptySmsHistory.defaultProps = {
  containerClassName: '',
};

export default EmptySmsHistory;
