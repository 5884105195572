import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FieldEmailV1 from "../../shared/UtilsV1/FieldEmailV1";
import FieldTextV1 from "../../shared/UtilsV1/FieldTextV1";
import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";
import { setPersonValue } from "../../reducers/contactsReducer";

function AddSponsorModal({ onClose, show }) {
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({
    name: "",
    confirmedName: "",
    email: "",
  });

  const onAddSponsor = (sponsorName, sponsorEmail) => {
    dispatch(setPersonValue({ key: "sponsorName", value: sponsorName }));
    dispatch(setPersonValue({ key: "sponsorEmail", value: sponsorEmail }));
  };

  const [isAllRequiredFieldsFilled, setIsAllRequiredFieldsFill] = useState(false);

  useEffect(() => {
    if (inputData.name && inputData.name === inputData.confirmedName) {
      setIsAllRequiredFieldsFill(true);
    } else {
      setIsAllRequiredFieldsFill(false);
    }
  }, [inputData.name, inputData.confirmedName]);

  return (
    <Modal
      onHide={onClose}
      show={show}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[440px]"
    >
      <Modal.Header title="Add Sponsor" className="tw-mb-32px" closeButton />

      <Modal.Body className="tw-mb-32px">
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-30">
            You are about to add a sponsor for this user. The user’s sponsor is the person who referred them
            to the company.
          </div>
          <div className="tw-font-bold tw-text-14d tw-text-neutral-gray-75">
            <span className="tw-text-brivity-coral-100">ATTENTION:</span> Adding a sponsor can only be done
            once per user. The sponsor cannot be removed or renamed once it has been added. Please ensure the
            sponsor’s name is spelled correctly.
          </div>
          <FieldTextV1
            label="Sponsor Name"
            required
            value={inputData.name}
            onChange={(value) => setInputData((prev) => ({ ...prev, name: value }))}
          />
          <FieldTextV1
            label="Confirm Sponsor Name"
            required
            value={inputData.confirmedName}
            onChange={(value) => setInputData((prev) => ({ ...prev, confirmedName: value }))}
          />
          <FieldEmailV1
            label="Sponsor Email"
            value={inputData.email}
            onChange={(value) => setInputData((prev) => ({ ...prev, email: value }))}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={!isAllRequiredFieldsFilled}
            onClick={() => {
              onAddSponsor(inputData.name, inputData.email);
              onClose();
            }}
          >
            Add Sponsor
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
AddSponsorModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

AddSponsorModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default AddSponsorModal;
