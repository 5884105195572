/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ToggleOffSolidV6 = (props) => (
  <Icon {...props} name="Toggle Off Solid V6">
    <path d="M5.333 10.667A2.652 2.652 0 0 1 2.666 8a2.636 2.636 0 0 1 2.667-2.667A2.652 2.652 0 0 1 8 8a2.668 2.668 0 0 1-2.667 2.667zm5.334 2.666H5.334A5.333 5.333 0 0 1 .001 8a5.318 5.318 0 0 1 5.333-5.333h5.333A5.319 5.319 0 0 1 16 8a5.335 5.335 0 0 1-5.333 5.333zm0-8.889H5.334a3.556 3.556 0 1 0 0 7.112h5.333a3.556 3.556 0 1 0 0-7.112z" />
  </Icon>
);

export default ToggleOffSolidV6;
