import React, { useState, useEffect } from "react";
import { func, string } from "prop-types";

import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { getAllNotesAsThunk } from "../actions/thunks";
import { tdpDetails, tdpNotes } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { NOTES } from "../../../../shared/reduxRoutes/tdpNotesRoutes";

import SingleNote from "../SingleNote";
import FilterNotes from "../Filter/FilterNotes";
import EditNoteModal from "../EditNoteModal";

const AllNotes = ({ uuid, notes, onGetAllNotesAsThunk }) => {
  useEffect(() => {
    if (uuid.length) onGetAllNotesAsThunk(uuid);
  }, [uuid]);

  const [showMore, setShowMore] = useState(5);
  const [showAllNotes, setShowAllNotes] = useState(true);
  const [showExternallyVisibleNotes, setShowExternallyVisibleNotes] = useState(false);
  const [notExternallyVisibleNotes, setNotExternallyVisibleNotes] = useState(false);

  const notesToDisplay = notes?.filter((note) => {
    if (showAllNotes) return true;
    if (showExternallyVisibleNotes) return note.externally_visible;
    if (notExternallyVisibleNotes) return !note.externally_visible;
    return true;
  });
  return (
    <div className="tw-flex tw-flex-col tw-gap-[16px] tw-mt-[32px]">
      <FilterNotes
        setShowAllNotes={setShowAllNotes}
        showAllNotes={showAllNotes}
        setShowExternallyVisibleNotes={setShowExternallyVisibleNotes}
        showExternallyVisibleNotes={showExternallyVisibleNotes}
        setNotExternallyVisibleNotes={setNotExternallyVisibleNotes}
        notExternallyVisibleNotes={notExternallyVisibleNotes}
      />
      <EditNoteModal />
      {notesToDisplay
        ?.filter((note) => note.starred)
        .map((note) => (
          <SingleNote key={note.id} note={note} />
        ))}
      <div className="tw-flex tw-gap-[16px]">
        <div className="tw-text-[12px] tw-font-semibold tw-leading-[16px] tw-tracking-normal tw-text-neutral-grey-75">
          Recent
        </div>
        <div className="tw-border-[1px] tw-border-solid tw-border-neutral-gray-10 tw-my-[8px] tw-w-[94%]" />
      </div>
      {notesToDisplay
        ?.filter((note) => !note.starred)
        .map((note) => (
          <SingleNote key={note.id} note={note} />
        ))}

      {notesToDisplay.length >= showMore && (
        <button
          onClick={() => setShowMore(showMore + 5)}
          type="button"
          className="tw-border-none tw-bg-transparent tw-text-center tw-text-[14px] tw-font-bold tw-leading-5 tw-tracking-normal tw-text-theme-primary"
        >
          Show More
        </button>
      )}
      {notesToDisplay.length ? (
        notesToDisplay?.length <= showMore && (
          <div className="tw-text-neutral-gray-50 tw-mt-[16px] tw-text-[12px] tw-font-semibold tw-leading-4 tw-tracking-normal tw-text-center">
            End of Notes
          </div>
        )
      ) : (
        <div className="tw-min-w-[300px] tw-text-center tw-border-solid tw-border-neutral-gray-10 tw-h-[68px] tw-rounded-[8px] tw-p-[24px] tw-text-neutral-gray-75 tw-text-[14px] tw-font-normal tw-leading-5 tw-tracking-normal">
          No one has written any notes yet.
        </div>
      )}
    </div>
  );
};

const withTdpDetails = tdpDetails({
  WrappedComponent: AllNotes,
  states: LISTING_UUID,
});

const withTdpNotes = tdpNotes({
  WrappedComponent: withTdpDetails,
  states: NOTES,
});

export default withDispatch({
  WrappedComponent: withTdpNotes,
  actions: getAllNotesAsThunk,
  aliases: "onGetAllNotesAsThunk",
});

AllNotes.propTypes = {
  uuid: string,
  notes: [],
  onGetAllNotesAsThunk: func,
};

AllNotes.defaultProps = {
  uuid: "",
  notes: [],
  onGetAllNotesAsThunk: () => {},
};
