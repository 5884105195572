import React from "react";
import { string } from "prop-types";

import FieldLabel from "../../../../../shared/v2/FieldLabel";

import { DETAIL_FIELD_CONTAINER } from "../styles";

const DetailTxtNonEditField = ({ id, label, dataCy, fieldValue, txtContainerClassName }) => (
  <div className={DETAIL_FIELD_CONTAINER} data-cy={dataCy}>
    <FieldLabel htmlFor={id} label={label} />
    <div className={`tw-mr-20px tw-capitalize ${txtContainerClassName}`}>{fieldValue}</div>
  </div>
);

export default DetailTxtNonEditField;

DetailTxtNonEditField.propTypes = {
  id: string,
  label: string,
  dataCy: string,
  fieldValue: string,
  txtContainerClassName: string,
};

DetailTxtNonEditField.defaultProps = {
  id: null,
  label: null,
  dataCy: null,
  fieldValue: null,
  txtContainerClassName: null,
};
