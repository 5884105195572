import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@shared/v2/Avatar";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoRegularV6, SparklesSolidV6 } from "@shared/v2/Icomoon";
import twilioErrorCodes from "@shared/v2/twilioErrorCodes";
import PreTooltipContent from "./PreTooltipContent";

const ERROR_TITLE = {
  allFailed: "Undelivered to all",
  someFailed: "Undelivered to some",
};

const SmsMessage = ({ content, isInbound, message, failureType, isGabbiMessage, failedRecipients }) => {
  const { dateCreated, createdByAvatar, createdByAlias } = message;
  const createdByName = isGabbiMessage ? "AI" : createdByAlias || "No Name";
  const dateComponent = (
    <span className={`tw-text-12d tw-text-neutral-gray-50${failureType ? " tw-italic" : ""}`}>
      {ERROR_TITLE[failureType] || moment(dateCreated).format("h:mm A")}{" "}
    </span>
  );
  const avatarComponent = isGabbiMessage ? (
    <Tooltip
      content="AI is applied to this lead."
      innerClassName="tw-max-w-[100px]"
      trigger={
        <div className="tw-bg-semantic-purple-5 tw-rounded-full tw-w-[36px] tw-h-[36px] tw-flex-none tw-flex tw-justify-center tw-items-center">
          <SparklesSolidV6 className="tw-text-semantic-purple-100" size="l" />
        </div>
      }
    />
  ) : (
    <Avatar src={createdByAvatar} size="medium" alt={createdByAlias} />
  );
  return (
    <div className={`${isInbound ? "tw-self-start" : "tw-self-end"} tw-flex tw-gap-[10px]`}>
      {isInbound && avatarComponent}
      <div className={`tw-flex tw-flex-col ${isInbound ? "tw-items-start" : "tw-items-end"} tw-gap-[4px]`}>
        <div className="tw-flex tw-items-baseline tw-gap-[8px]">
          {!isInbound && dateComponent}{" "}
          <span className={isGabbiMessage ? "tw-font-semibold tw-text-[#3b1487]" : ""}>{createdByName}</span>{" "}
          {isInbound && dateComponent}
        </div>
        <div className="tw-flex tw-gap-[8px] tw-items-center">
          <Tooltip
            placement={isInbound ? "right" : "left"}
            trigger={content}
            content={moment(dateCreated).format("MM/DD/YYYY [at] h:mm A")}
          />
        </div>
        {failedRecipients.map((recipient) => (
          <div
            key={recipient.alias}
            className="tw-flex tw-gap-[4px] tw-items-center tw-text-12d tw-text-semantic-red-100 tw-font-semibold"
          >
            <Tooltip
              className="tw-inline-flex"
              innerClassName="tw-max-w-[250px] "
              trigger={<CircleInfoRegularV6 />}
              content={
                <PreTooltipContent>
                  {twilioErrorCodes[recipient.errorCode] || "Unknown reason"}
                </PreTooltipContent>
              }
            />
            Message not delivered to {recipient.alias}
          </div>
        ))}
      </div>
      {!isInbound && avatarComponent}
    </div>
  );
};

SmsMessage.propTypes = {
  content: PropTypes.node.isRequired,
  isInbound: PropTypes.bool,
  message: PropTypes.shape().isRequired,
  failureType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  isGabbiMessage: PropTypes.bool,
  failedRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SmsMessage.defaultProps = {
  isInbound: false,
  isGabbiMessage: false,
};

const Media = ({ media, onClick }) => (
  // TODO: bring this back if/when we have the content type for old messages in the new api
  // if (/(?:jpeg|jpg|gif|png)$/.test(media.contentType || media.fileName)) {
  <button className="tw-p-0 tw-border-0 tw-bg-transparent" type="button" onClick={onClick}>
    <img
      src={media.fileUrl || media.fileAccessKey}
      className="tw-rounded-lg tw-max-h-96 tw-max-w-96 tw-h-auto tw-w-auto"
      alt="sms media"
    />
  </button>
);
// }
// return <div>Unsupported media type</div>;

Media.propTypes = {
  media: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SmsMessage;
export { Media };
