/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import { isEqual } from "lodash";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import ClearIndicator from "./ClearIndicator";

const getAgentsList = (agents) =>
  agents.map((agent) => ({
    label: agent.name,
    value: agent.id.toString(),
  }));

const getSelectedAgents = (agents, agentIds) =>
  getAgentsList(agents.filter((agent) => agentIds.includes(agent.id.toString())));

const getSelectedAgentsIds = (agents) => agents.map((agent) => agent.value);

const AgentsFilter = ({ initialAgents, onAgentsChange, agentIds }) => {
  const selectedAgents = useMemo(() => getSelectedAgents(initialAgents, agentIds), [initialAgents, agentIds]);
  const agentsList = useMemo(() => getAgentsList(initialAgents), [initialAgents]);

  const handleOnChange = (newSelectedAgents) => {
    if (isEqual(newSelectedAgents, selectedAgents)) {
      return;
    }

    onAgentsChange(getSelectedAgentsIds(newSelectedAgents));
  };

  return (
    <Dropdown
      label="Agent"
      placeholder="Select one or more agents"
      isMulti
      options={agentsList}
      value={selectedAgents}
      onChange={handleOnChange}
      menuShouldComeToFront
      isSearchable
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{
        ClearIndicator,
      }}
      dataCy="filter-dropdown"
    />
  );
};

export default AgentsFilter;
