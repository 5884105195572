/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const LinkSolidV6 = (props) => (
  <Icon {...props} name="Link Solid V6">
    <path d="M4.313 4.877c1.39-1.39 3.7-1.39 5.09 0 1.25 1.25 1.435 3.243.408 4.68l-.028.04a.8.8 0 0 1-1.115.185.798.798 0 0 1-.187-1.115l.027-.04a2.015 2.015 0 0 0-3.065-2.598L2.638 8.836c-.788.765-.788 2.063 0 2.85a2.011 2.011 0 0 0 2.595.215l.04-.05a.823.823 0 0 1 1.115.208.8.8 0 0 1-.185 1.117l-.04.028a3.615 3.615 0 0 1-4.657-5.498l2.807-2.83zm7.374 6.223A3.614 3.614 0 0 1 6.19 6.442l.027-.04c.235-.357.735-.442 1.115-.185.36.235.445.735.188 1.115l-.028.04c-.572.78-.482 1.9.215 2.598a2.019 2.019 0 0 0 2.85 0l2.805-2.808c.788-.787.788-2.085 0-2.85a2.012 2.012 0 0 0-2.595-.215l-.04.027c-.36.258-.86.152-1.115-.186a.8.8 0 0 1 .185-1.116l.04-.028a3.614 3.614 0 0 1 4.658 5.497l-2.808 2.808z" />
  </Icon>
);

export default LinkSolidV6;
