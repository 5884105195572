//
// Fetch AssignedPlans
//
export const fetchAssignedPlansStarted = () => ({ type: "ASSIGNED_PLANS_FETCH_STARTED" });

export const fetchAssignedPlansSuccess = (data) => ({ type: "ASSIGNED_PLANS_FETCH_SUCCESS", data });

export const fetchAssignedPlansFailure = (error) => ({
  type: "ASSIGNED_PLANS_FETCH_FAILURE",
  data: error,
});

//
// Show/hide the preview modal for a plan
//
export const setShowPreviewModalForAutoPlan = (assignedPlanId) => ({
  type: "AUTO_PLAN_SHOW_PREVIEW_MODAL_FOR_ID",
  data: assignedPlanId,
});

//
// Fetch data for a plan preview
//
export const autoPlanPreview = (status, result) => ({
  type: "AUTO_PLAN_PREVIEW",
  data: { status, result },
});

//
// Show/hide the details modal for an AssignedPlan
//
export const setShowDetailsModalForAssignedPlan = (assignedPlanId) => ({
  type: "ASSIGNED_PLAN_SHOW_DETAILS_MODAL_FOR_ID",
  data: assignedPlanId,
});

//
// Fetch data about a single AssignedPlan
//
export const fetchAssignedPlanDetailsStarted = (assignedPlanId) => ({
  type: "ASSIGNED_PLAN_DETAILS_FETCH_STARTED",
  data: assignedPlanId,
});

export const fetchAssignedPlanDetailsSuccess = (data) => ({
  type: "ASSIGNED_PLAN_DETAILS_FETCH_SUCCESS",
  data,
});

export const fetchAssignedPlanDetailsFailure = (error) => ({
  type: "ASSIGNED_PLAN_DETAILS_FETCH_FAILURE",
  data: error,
});

//
// Confirm deletion of an action within an AssignedPlan
//
export const confirmAssignedActionDeletion = (assignedPlanId, assignedActionId) => ({
  type: "ASSIGNED_ACTION_CONFIRM_DELETION",
  data: assignedPlanId && assignedActionId ? { assignedPlanId, assignedActionId } : null,
});

export const assignedActionDeletion = (status, result) => ({
  type: "ASSIGNED_ACTION_DELETION",
  data: { status, result },
});

//
// Mark an action as completed within an AssignedPlan
//
export const assignedActionRestartOrMarkAsComplete = (status, result) => ({
  type: "ASSIGNED_ACTION_RESTART_OR_MARK_AS_COMPLETE",
  data: { status, result },
});

//
// Confirm deletion of an AssignedPlan
//
export const confirmAssignedPlanDeletion = (assignedPlanId) => ({
  type: "ASSIGNED_PLAN_CONFIRM_DELETION",
  data: assignedPlanId,
});

//
// Update State
//
export const updateAssignedPlanStateStarted = (assignedPlanId) => ({
  type: "ASSIGNED_PLAN_UPDATE_STATE_STARTED",
  data: assignedPlanId,
});

export const updateAssignedPlanStateSuccess = (updatedPlan) => ({
  type: "ASSIGNED_PLAN_UPDATE_STATE_SUCCESS",
  data: updatedPlan,
});

export const updateAssignedPlanStateFailure = (error, assignedPlanId) => ({
  type: "ASSIGNED_PLAN_UPDATE_STATE_FAILURE",
  data: { error, assignedPlanId },
});

//
// Fetch available AutoPlans
//
export const fetchAvailableAutoPlansStarted = () => ({ type: "AUTO_PLANS_FETCH_AVAILABLE_STARTED" });

export const fetchAvailableAutoPlansSuccess = (autoPlans) => ({
  type: "AUTO_PLANS_FETCH_AVAILABLE_SUCCESS",
  data: autoPlans,
});

export const fetchAvailableAutoPlansFailure = (error) => ({
  type: "AUTO_PLANS_FETCH_AVAILABLE_FAILURE",
  data: error,
});

//
// Apply AutoPlan
//
export const applyAutoPlanStarted = () => ({ type: "AUTO_PLAN_APPLY_STARTED" });

export const applyAutoPlanSuccess = () => ({ type: "AUTO_PLAN_APPLY_SUCCESS" });

export const applyAutoPlanFailure = (errors) => ({ type: "AUTO_PLAN_APPLY_FAILURE", data: errors });

export const setApplyAutoPlanModalVisible = (visible) => ({
  type: "AUTO_PLAN_SET_APPLY_MODAL_VISIBLE",
  data: visible,
});

export const setApplyAutoPlanSelectedPlan = (autoPlanId) => ({
  type: "AUTO_PLAN_APPLY_MODAL_SET_SELECTED_PLAN",
  data: autoPlanId,
});
