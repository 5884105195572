/* eslint-disable import/prefer-default-export */
import { tryCatchHandlr } from "../../../shared/Utilities";
import { getPrevNextListingsAsApi } from "../api/apiWrappers";
import { saveNavigationListings } from "./creators";

export const getPrevNextListingsAsThunk = (uuid, params) => async (dispatch) => {
  const queryParams = {
    ...params,
    uuid,
    use_classic: false, // force new view
  };

  const req = getPrevNextListingsAsApi(uuid, queryParams);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  dispatch(saveNavigationListings(res.data));
};
