import React from "react";
import Error from "@shared/Error";
import { arrayOf, string } from "prop-types";

import { useErrorMessages } from "../../../reducers/autoPlanEdit";

const ErrorInModal = ({ additionalErrors }) => {
  const errorMessage = useErrorMessages()

  return (
    <>
      {Boolean(additionalErrors.length) && <Error errorMessage={additionalErrors} />}
      {errorMessage && <Error errorMessage={errorMessage} />}
    </>
  )
};

export default ErrorInModal;

ErrorInModal.propTypes = {
  additionalErrors: arrayOf(string),
}

ErrorInModal.defaultProps = {
  additionalErrors: [],
}