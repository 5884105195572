import React from "react";

import PropTypes from "prop-types";

import { UserPlusSolidV6 } from "@shared/v2/Icomoon";
import ActivitiesContainer from "./ActivitiesContainer";

const NewLeadActivity = ({ activity, onReadChange }) => (
  <ActivitiesContainer
    activity={activity}
    Icon={UserPlusSolidV6}
    title={activity.title}
    onReadChange={onReadChange}
  >
    <span className="tw-text-14d">{activity.content}</span>
  </ActivitiesContainer>
);

export default NewLeadActivity;

NewLeadActivity.propTypes = {
  activity: PropTypes.shape().isRequired,
  onReadChange: PropTypes.func.isRequired,
};
