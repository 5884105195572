import React from "react";
import { Col, Row } from "../../../../shared/v1";
import LAYOUT_STYLES from "../actions/hooks";

const { HEADER_FONT, FONT_STYLE } = LAYOUT_STYLES;

const Headers = () => (
  <>
    <Row className="tw-flex tw-p-0 tw-m-0 tw-mx-[0px] tw-items-center">
      <Col size={4} className={`${HEADER_FONT} ${FONT_STYLE}`}>
        Ad Site
      </Col>
      <Col size={3} className={`${HEADER_FONT} ${FONT_STYLE} tw-text-right`}>
        Link
      </Col>
      <Col size={1} className={`${HEADER_FONT} ${FONT_STYLE} tw-text-center`}>
        Who
      </Col>
      <Col size={2} className={`${HEADER_FONT} ${FONT_STYLE} tw-text-center`}>
        Date
      </Col>
      <Col size={2} className={`${HEADER_FONT} ${FONT_STYLE} tw-text-right`}>
        Share
      </Col>
    </Row>
    <hr className="tw-my-[8px] tw-border-solid tw-border-[1px] tw-border-gray-10" />
  </>
);

export default Headers;
