import React from "react";
import { CommentSlashRegularV6, PhoneVolumeSolidV6 } from "@icons";

const SHARED_ICON_PROPERTIES = { size: "l", className: "tw-text-semantic-yellow-100" };

const nullWarningIcon = () => ({
  icon: null,
  tooltip: null,
});

const nonTextableIcon = {
  // eslint-disable-next-line react/jsx-props-no-spreading
  icon: ({ size = "l" }) => <CommentSlashRegularV6 {...SHARED_ICON_PROPERTIES} size={size} />,
  tooltip:
    "This number may be unreachable via text message (landline or VOIP)\nYour account may be liable for SMS failures.",
};

const nonCallableIcon = {
  // eslint-disable-next-line react/jsx-props-no-spreading
  icon: ({ size = "l" }) => <PhoneVolumeSolidV6 {...SHARED_ICON_PROPERTIES} size={size} />,
  tooltip:
    "This number is on a DNC (Do Not Call) list. You may still choose to contact this   number if you have a personal relationship with them.",
};

const waringIconForPhone = (phone) => {
  let base = nullWarningIcon();

  if (!phone.textable) {
    base = { ...base, ...nonTextableIcon };
  }
  if (!phone.callable) {
    base = { ...base, ...nonCallableIcon };
  }

  return base;
};

export default waringIconForPhone;
