import React from "react";
import Proptypes from "prop-types";
import { XmarkSolidV6 } from "@shared/v2/Icomoon";
import Dropdown from "@shared/v2/Dropdown";
import FieldLabel from "@shared/v2/FieldLabel";
import IconButton from "@shared/v2/IconButton";
import RadioButton from "@shared/v2/RadioButton";

const VISIBILITY_OPTIONS = [
  { label: "Hidden", value: "false" },
  { label: "Visible", value: "true" },
];

const RoleForTransaction = ({
  role: { label, value, agents },
  selectedAgentId,
  primary,
  setPrimaryAgent,
  visible,
  handleAgentSelect,
  handleVisibilitySelect,
  handleRemoveRole,
  showVisibility,
}) => (
  // TODO: add key prop in ListingTransactionModal when mapping
  // TODO: extract transaction modals and this file into a separate folder
  <div className="tw-flex tw-gap-[8px]">
    <Dropdown
      containerClassName="tw-flex-1"
      label={
        <>
          {label}{" "}
          <IconButton size="xsmall" schema="transparent" onClick={() => handleRemoveRole(value)}>
            <XmarkSolidV6 size="m" />
          </IconButton>
        </>
      }
      isSearchable
      options={agents}
      value={agents.find((agent) => agent.value === selectedAgentId)}
      onChange={(agent) => handleAgentSelect(agent.value, value)}
      menuShouldComeToFront
    />
    <Dropdown
      containerClassName={`tw-flex-1 ${showVisibility ? "" : "tw-hidden"}`}
      label="Visibility"
      isSearchable={false}
      options={VISIBILITY_OPTIONS}
      value={VISIBILITY_OPTIONS.find((v) => v.value === visible)}
      onChange={(agent) => handleVisibilitySelect(agent.value, value)}
      menuShouldComeToFront
    />
    <div className="tw-flex tw-flex-col tw-gap-[16px] tw-items-center tw-ml-auto">
      <FieldLabel label="Primary" isRequired={!showVisibility} />
      <RadioButton
        checked={primary === "true"}
        value={value}
        onChange={(e) => setPrimaryAgent(e.target.value)}
      />
    </div>
  </div>
);

const optionShape = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
});

const optionShapeRole = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  agents: Proptypes.arrayOf(optionShape),
});

RoleForTransaction.propTypes = {
  role: optionShapeRole,
  selectedAgentId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  primary: Proptypes.string,
  showVisibility: Proptypes.string,
  setPrimaryAgent: Proptypes.func,
  handleAgentSelect: Proptypes.func,
  handleVisibilitySelect: Proptypes.func,
  handleRemoveRole: Proptypes.func,
  visible: optionShape,
};

RoleForTransaction.defaultProps = {
  selectedAgentId: null,
  primary: "",
  setPrimaryAgent: null,
  visible: null,
  handleAgentSelect: null,
  handleVisibilitySelect: null,
  handleRemoveRole: null,
  showVisibility: "",
  role: {
    label: null,
    value: null,
    agents: [],
  },
};

export default RoleForTransaction;
