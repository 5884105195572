/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AreaProCustomv6 = (props) => (
  <Icon {...props} name="AreaProCustomv6">
    <path d="M11.657 2.71a6.9 6.9 0 0 0-2.86-.825C8.663.973 8.574.381 8.574.381a.424.424 0 0 0-.842 0l-.194 1.525c-2.91.326-5.56 2.412-6.251 5.492a6.6 6.6 0 0 0-.184 1.432l-.823.031a.307.307 0 0 0-.281.304.306.306 0 0 0 .281.304l.836.031a7.16 7.16 0 0 0 3.176 5.462.3.3 0 0 0 .395-.094.3.3 0 0 0-.063-.401A6.51 6.51 0 0 1 1.813 9.53l1.542.054.44 1.005a.425.425 0 0 0 .795-.039l.221-.685 1.024 5.766a.43.43 0 0 0 .43.346c.101-.003.198-.041.274-.108s.125-.159.138-.26L8.186 3.58l1.181 8.001a.427.427 0 0 0 .814.109l.545-1.259c.369 1.199.825 2.377 1.709 3.339l.021.018.388.338a.553.553 0 0 0 .761-.053c3.226-3.461 2.245-9.167-1.95-11.363zM6.184 12.721c-.371-2.119-.83-4.68-.83-4.68a.42.42 0 0 0-.387-.352.43.43 0 0 0-.437.288l-.404 1.242-.097-.225V8.98a.4.4 0 0 0-.388-.241l-1.828.066q.032-.628.194-1.236A6.32 6.32 0 0 1 7.44 2.79l-1.255 9.929zm6.948.212a6.5 6.5 0 0 1-1.11-1.721 22 22 0 0 1-.807-2.146.426.426 0 0 0-.63-.24.42.42 0 0 0-.167.194l-.423.97-1.063-7.194a6.27 6.27 0 0 1 5.08 3.833 6.28 6.28 0 0 1-.88 6.304" />
  </Icon>
);

export default AreaProCustomv6;
