/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import { Provider } from "react-redux";

import Main from "../Main";

const BusinessSettings = (props) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  return (
    <Provider store={store}>
      <Main {...props} />
    </Provider>
  );
};

export default BusinessSettings;
