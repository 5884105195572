export const updateStageAttribute = (editablePerson) => {
  const { type_name, stage } = editablePerson.data.attributes;

  if (type_name !== "lead") {
    editablePerson.data.attributes.stage = "";
    editablePerson.data.attributes.stage_type = "";
  } else if (type_name === "lead" && stage === "") {
    editablePerson.data.attributes.stage = "New lead";
    editablePerson.data.attributes.stage_type = "lead";
  }
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 KB";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};
