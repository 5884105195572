/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PercentSolidV6 = (props) => (
  <Icon {...props} name="Percent Solid V6">
    <path d="M13.706 2.293a.999.999 0 0 0-1.414 0l-10 10a.999.999 0 1 0 1.415 1.413l10-10a1 1 0 0 0-.001-1.414zM4 6c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm8 4c-1.103 0-2 .898-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
  </Icon>
);

export default PercentSolidV6;
