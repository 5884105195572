import React from 'react';

const TimelineTrackingNumberEvent = ({ event }) => {
  const firstName = event.timeline_fields["first_name"] || "";
  const lastName = event.timeline_fields["last_name"] || "";
  const name = `${firstName} ${lastName}`.trim();

  return (
    <div className="timeline-event !tw-cursor-default">
      <div className="row">
        <div className="col-xs-1">
          <i className="fa fa-phone timeline-icon-circle"/>
        </div>
        <div className="col-xs-7">
          <span className="bold-detail">
            {name ? name : "No Name"}
          </span>
          {' called Tracking Number'}
          <div className="timeline-description-text">
            Call Type: Inbound Call to <span className="bold-detail">{event.timeline_fields["tracking_number_name"]}</span>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );
};

export default TimelineTrackingNumberEvent;
