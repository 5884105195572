import React from "react";
import PropTypes from "prop-types";

const BASE_CLASSES = "tw-relative tw-min-h-1px";

const BASE_DICTIONARY = {
  xs: "tw-float-left",
  sm: "bs-sm:tw-float-left",
  md: "bs-md:tw-float-left",
  lg: "bs-lg:tw-float-left",
};

const SIZE_DICTIONARY = {
  xs: {
    1: "tw-w-1/12",
    2: "tw-w-2/12",
    3: "tw-w-3/12",
    4: "tw-w-4/12",
    5: "tw-w-5/12",
    6: "tw-w-6/12",
    7: "tw-w-7/12",
    8: "tw-w-8/12",
    9: "tw-w-9/12",
    10: "tw-w-10/12",
    11: "tw-w-11/12",
    12: "tw-w-full",
  },
  sm: {
    1: "bs-sm:tw-w-1/12",
    2: "bs-sm:tw-w-2/12",
    3: "bs-sm:tw-w-3/12",
    4: "bs-sm:tw-w-4/12",
    5: "bs-sm:tw-w-5/12",
    6: "bs-sm:tw-w-6/12",
    7: "bs-sm:tw-w-7/12",
    8: "bs-sm:tw-w-8/12",
    9: "bs-sm:tw-w-9/12",
    10: "bs-sm:tw-w-10/12",
    11: "bs-sm:tw-w-11/12",
    12: "bs-sm:tw-w-full",
  },
  md: {
    1: "bs-md:tw-w-1/12",
    2: "bs-md:tw-w-2/12",
    3: "bs-md:tw-w-3/12",
    4: "bs-md:tw-w-4/12",
    5: "bs-md:tw-w-5/12",
    6: "bs-md:tw-w-6/12",
    7: "bs-md:tw-w-7/12",
    8: "bs-md:tw-w-8/12",
    9: "bs-md:tw-w-9/12",
    10: "bs-md:tw-w-10/12",
    11: "bs-md:tw-w-11/12",
    12: "bs-md:tw-w-full",
  },
  lg: {
    1: "bs-lg:tw-w-1/12",
    2: "bs-lg:tw-w-2/12",
    3: "bs-lg:tw-w-3/12",
    4: "bs-lg:tw-w-4/12",
    5: "bs-lg:tw-w-5/12",
    6: "bs-lg:tw-w-6/12",
    7: "bs-lg:tw-w-7/12",
    8: "bs-lg:tw-w-8/12",
    9: "bs-lg:tw-w-9/12",
    10: "bs-lg:tw-w-10/12",
    11: "bs-lg:tw-w-11/12",
    12: "bs-lg:tw-w-full",
  },
};

const OFFSET_DICTIONARY = {
  xs: {
    1: "tw-ml-1/12",
    2: "tw-ml-2/12",
    3: "tw-ml-3/12",
    4: "tw-ml-4/12",
    5: "tw-ml-5/12",
    6: "tw-ml-6/12",
    7: "tw-ml-7/12",
    8: "tw-ml-8/12",
    9: "tw-ml-9/12",
    10: "tw-ml-10/12",
    11: "tw-ml-11/12",
    12: "tw-ml-full",
  },
  sm: {
    1: "bs-sm:tw-ml-1/12",
    2: "bs-sm:tw-ml-2/12",
    3: "bs-sm:tw-ml-3/12",
    4: "bs-sm:tw-ml-4/12",
    5: "bs-sm:tw-ml-5/12",
    6: "bs-sm:tw-ml-6/12",
    7: "bs-sm:tw-ml-7/12",
    8: "bs-sm:tw-ml-8/12",
    9: "bs-sm:tw-ml-9/12",
    10: "bs-sm:tw-ml-10/12",
    11: "bs-sm:tw-ml-11/12",
    12: "bs-sm:tw-ml-full",
  },
  md: {
    1: "bs-md:tw-ml-1/12",
    2: "bs-md:tw-ml-2/12",
    3: "bs-md:tw-ml-3/12",
    4: "bs-md:tw-ml-4/12",
    5: "bs-md:tw-ml-5/12",
    6: "bs-md:tw-ml-6/12",
    7: "bs-md:tw-ml-7/12",
    8: "bs-md:tw-ml-8/12",
    9: "bs-md:tw-ml-9/12",
    10: "bs-md:tw-ml-10/12",
    11: "bs-md:tw-ml-11/12",
    12: "bs-md:tw-ml-full",
  },
  lg: {
    1: "bs-lg:tw-ml-1/12",
    2: "bs-lg:tw-ml-2/12",
    3: "bs-lg:tw-ml-3/12",
    4: "bs-lg:tw-ml-4/12",
    5: "bs-lg:tw-ml-5/12",
    6: "bs-lg:tw-ml-6/12",
    7: "bs-lg:tw-ml-7/12",
    8: "bs-lg:tw-ml-8/12",
    9: "bs-lg:tw-ml-9/12",
    10: "bs-lg:tw-ml-10/12",
    11: "bs-lg:tw-ml-11/12",
    12: "bs-lg:tw-ml-full",
  },
};

const paddingClasses = (deprecatedOverridePaddingClasses) => {
  if (deprecatedOverridePaddingClasses !== null) {
    return deprecatedOverridePaddingClasses;
  }

  return "tw-px-10px";
};

const baseClasses = (sizeObj) =>
  Object.keys(sizeObj)
    .map((breakpoint) => BASE_DICTIONARY[breakpoint])
    .join(" ");

const sizeClasses = (sizeObj) =>
  Object.entries(sizeObj)
    .map(([breakpoint, size]) => SIZE_DICTIONARY[breakpoint][size])
    .join(" ");

const offsetClasses = (offsetObj) =>
  Object.entries(offsetObj)
    .map(([breakpoint, offset]) => OFFSET_DICTIONARY[breakpoint][offset])
    .join(" ");

export const Col = ({ size, offset, deprecatedOverridePaddingClasses, children, className, ...props }) => {
  const sizeObj = typeof size === "number" ? { xs: size } : size;
  const offsetObj = typeof offset === "number" ? { xs: offset } : offset;

  const classes = `
    ${BASE_CLASSES}
    ${baseClasses(sizeObj)}
    ${sizeClasses(sizeObj)}
    ${offsetObj ? offsetClasses(offsetObj) : ""}
    ${paddingClasses(deprecatedOverridePaddingClasses)}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
Col.propTypes = {
  size: PropTypes.oneOf([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    PropTypes.shape({
      xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    }),
  ]),
  offset: PropTypes.oneOf([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    PropTypes.shape({
      xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
      lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    }),
    null,
  ]),
  deprecatedOverridePaddingClasses: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
Col.defaultProps = {
  size: 12,
  offset: null,
  deprecatedOverridePaddingClasses: null,
  children: null,
  className: "",
};

export default Col;
