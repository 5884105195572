/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* global tinymce */
import React, { useEffect, useState } from "react";
import { produce } from "immer";
import axios from "axios";
import moment from "moment";
import { tryCatchHandlr } from "@shared/Utilities";
import Button from "@shared/v2/Button";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Modal from "@shared/v2/Modal";
import FieldLabel from "@shared/v2/FieldLabel";
import BrivityTextEditor from "@shared/BrivityTextEditor";
import AiAssistant from "@shared/AiAssistant";
import useSubjectLineLimitError, { isWithinSubjectLineLimit } from "@shared/hooks/useSubjectLineLimitError";
import EmailSubjectField from "./EmailSubjectField";
import EmailTemplateDropdown from "./EmailTemplateDropdown";
import CCRadioButtons from "./CCRadioButtons";
import BCCField from "./BCCField";
import DateTime from "../DateTime";
import AssignToDropdown from "../AssignToDropdown";
import { convertDateFormat, formatDateAndTimeToDatePicker, onSaveDateAdapter } from "../../helpers";
import ErrorInModal from "../ErrorInModal";
import { useSpecificActionSubmit } from "../../../reducers/autoPlanEdit";

const CC_TYPE_TO_STATE_MAP = {
  Email: 'cc_emails',
  Role: 'cc_role_uuids',
  Person: 'cc_people_uuids',
}

const BCC_TYPE_TO_STATE_MAP = {
  Email: 'bcc_emails',
  Role: 'bcc_role_uuids',
  Person: 'bcc_people_uuids',
}

const DEFAULT_STATE = {
  name: '',
  template_id: null,
  template_body: '',
  assigned_to_id: null,
  assigned_to_role: 'owner',
  assigned_to_type: null,
  run_time: "08:00",
  day_month_year: moment().format('DD/MM/YYYY'),
  delay_unit: 'days',
  type: "Email",
  bcc_emails: [],
  bcc_people_uuids: [],
  bcc_role_uuids: [],
  cc_emails: [],
  cc_people_uuids: [],
  cc_role_uuids: [],
}

const loadLabelsForCC = (roles, people) => axios({
  method: "get",
  url: "/auto_plans/serialized_email_recipients",
  params: { people_uuids: (people || []), role_uuids: (roles || []) },
  headers: {
    "X-CSRF-Token": ReactOnRails.authenticityToken(),
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

const EmailActionModal = ({ show, onHide, onSave, form, emailTemplates, assignedToOptions, isSubmitting, autoPlanId, placeholders }) => {
  const formSubmitAction = useSpecificActionSubmit()
  const {
    errorMsg,
    setErrorTrigger
  } = useSubjectLineLimitError();
  const [state, setState] = useState({
    ...DEFAULT_STATE,
    ...form,
    auto_plan_id: autoPlanId,
  });

  useEffect(() => {
    (async () => {
      if (show) {
        const [ccRes] = await tryCatchHandlr(loadLabelsForCC(form.cc_role_uuids, form.cc_people_uuids));
        const [bccRes] = await tryCatchHandlr(loadLabelsForCC(form.bcc_role_uuids, form.bcc_people_uuids));

        setState({
          ...DEFAULT_STATE,
          ...form,
          auto_plan_id: autoPlanId,
          cc_people_uuids: ccRes.data.people ? ccRes.data.people : [],
          cc_role_uuids: ccRes.data.roles ? ccRes.data.roles : [],
          bcc_people_uuids: bccRes.data.people ? bccRes.data.people : [],
          bcc_role_uuids: bccRes.data.roles ? bccRes.data.roles : [],
          cc_emails: form.cc_emails ? form.cc_emails.map(value => ({ label: value, value })) : [],
          bcc_emails: form.bcc_emails ? form.bcc_emails.map(value => ({ label: value, value })) : [],
        })
      }
    })()
  }, [show])

  const setStateHandlr = (key) => (val) => setState(produce(state, (draft) => {
    draft[key] = val
  }))


  const onHideHandlr = () => {
    setState({
      ...DEFAULT_STATE,
      auto_plan_id: autoPlanId,
    });
    onHide();
  }

  return (
    <Modal
      show={show}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      onHide={onHideHandlr}
      closeButton
    >
      <Modal.Header title={formSubmitAction === 'post' ? "Create a new Email" : 'Update Email'} className="tw-mb-40px" closeButton />
      <Modal.Body className="tw-gap-[24px] tw-flex tw-flex-col tw-h-[480px] tw-overflow-y-scroll tw-pt-[8px] tw-border tw-border-solid tw-border-gray-10 tw-border-l-0 tw-border-r-0">
        <EmailSubjectField
          value={state.name}
          setValue={(subject) => {
            if (isWithinSubjectLineLimit(subject)) {
              setErrorTrigger(false);
            }

            return setStateHandlr('name')(subject)
          }} />
        <EmailTemplateDropdown
          options={emailTemplates.map(({ name, id, ...rest }) => ({
            ...rest,
            label: name,
            value: id
          }))}
          setState={async (e) => {
            const response = await fetch(`/auto_plans/interaction_template_body?id=${e.value}`);
            const templateBody = await response.json();

            if (tinymce && tinymce.activeEditor) {
              tinymce.activeEditor.setContent(templateBody);
            }

            setState(prev => ({
              ...prev,
              name: e.subject || null,
              template_id: e.value || null
            }))
          }}
          stateValue={state.template_id} />
        {/* Email Body Editor */}
        <div className="tw-block">
          <div className="tw-flex tw-justify-between tw-items-baseline">
            <FieldLabel
              className="tw-mb-8px tw-w-fit"
              label='Body'
            />
            <AiAssistant
              isTinyMce
              messageType="Email"
              onInsertClick={(text) =>
                tinymce?.activeEditor?.execCommand(
                  "mceInsertContent",
                  false,
                  (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                )
              }
            />
          </div>
          <BrivityTextEditor
            id="auto-plan-edit-email-form-x"
            value={form.template_body}
            placeholders={placeholders}
          />
        </div>
        <CCRadioButtons
          ccEmails={state.cc_emails}
          ccPeopleUuids={state.cc_people_uuids}
          ccRoleUuids={state.cc_role_uuids}
          onSelect={({ label, value, cc_type }) => {
            const ccKey = CC_TYPE_TO_STATE_MAP[cc_type];
            setState(prev => produce(prev, (draft) => {
              draft[ccKey].push({ label, value, cc_type })
            }))
          }}
          onRemove={({ value, cc_type }) => {
            const ccKey = CC_TYPE_TO_STATE_MAP[cc_type];
            setState(prev => produce(prev, (draft) => {
              const idxToRemove = draft[ccKey].findIndex(({ value: val }) => val === value);
              draft[ccKey].splice(idxToRemove, 1);
            }))
          }} />
        <BCCField
          bccEmails={state.bcc_emails}
          bccPeopleUuids={state.bcc_people_uuids}
          bccRoleUuids={state.bcc_role_uuids}
          onSelect={({ label, value, cc_type }) => {
            const bccKey = BCC_TYPE_TO_STATE_MAP[cc_type];
            setState(prev => produce(prev, (draft) => {
              draft[bccKey].push({ label, value, cc_type })
            }))
          }}
          onRemove={({ value, cc_type }) => {
            const bccKey = BCC_TYPE_TO_STATE_MAP[cc_type];
            setState(prev => produce(prev, (draft) => {
              const idxToRemove = draft[bccKey].findIndex(({ value: val }) => val === value);
              draft[bccKey].splice(idxToRemove, 1);
            }))
          }} />
        <DateTime
          setState={(dateStr) => {
            const { day_month_year, run_time } = convertDateFormat(dateStr);

            setState(prev => produce(prev, (draft) => {
              draft.day_month_year = day_month_year;
              draft.run_time = run_time;
            }))
          }}
          value={formatDateAndTimeToDatePicker(state.day_month_year, state.run_time)} />
        <AssignToDropdown
          options={assignedToOptions}
          label='Send From'
          state={{
            assigned_to_id: state.assigned_to_id,
            assigned_to_type: state.assigned_to_type,
            assigned_to_role: state.assigned_to_role,
          }}
          setState={(e) => setState(prev => ({
            ...prev,
            assigned_to_id: e.assigned_to_type ? e.value : null,
            assigned_to_role: e.assigned_to_type ? null : e.value,
            assigned_to_type: e.assigned_to_type || null,
          }))} />
        <ErrorInModal additionalErrors={errorMsg ? [errorMsg] : []} />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[40px]">
        <Button size="medium" schema="secondary" onClick={onHideHandlr}>
          CANCEL
        </Button>

        <Button size="medium" schema="primary" onClick={() => {
          if (!isWithinSubjectLineLimit(state.name)) {
            return setErrorTrigger(true);
          }

          return onSave(onSaveDateAdapter({
            ...state,
            template_body: tinymce.activeEditor.getContent(),
          }))
        }} isLoading={isSubmitting}>
          SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default EmailActionModal;

EmailActionModal.propTypes = {
  show: bool,
  onHide: func.isRequired,
  onSave: func.isRequired,
  isSubmitting: bool,
  autoPlanId: string.isRequired,
  emailTemplates: arrayOf(shape({})).isRequired,
  assignedToOptions: arrayOf(shape({})).isRequired,
  placeholders: arrayOf(shape({})),
  form: shape({})
}

EmailActionModal.defaultProps = {
  isSubmitting: false,
  show: false,
  placeholders: [],
  form: {}
}