/* eslint-disable react/prop-types, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */

import React from "react";
import { CloseIcon } from "../../icons";
import PillButton from "../PillButton";

const AppliedFilter = ({ label, onRemove }) => (
  <PillButton
    className="tw-bg-gray-light"
    data-cy={`applied-filter-${label?.toLowerCase().replace(/ /g, "")}`}
  >
    <div className="tw-flex tw-flex-row tw-items-center">
      <span className="tw-font-bold tw-text-12px tw-align-middle tw-mr-8px tw-select-none tw-text-gray-75">
        {label}
      </span>
      <span className="tw-flex" role="button" onClick={onRemove} aria-label="remove">
        <CloseIcon />
      </span>
    </div>
  </PillButton>
);

export default AppliedFilter;
