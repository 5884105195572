import React, { useState } from "react";
import Button from "@shared/v2/Button";
import { ChevronRightSolidV6 } from "../../shared/v2/Icomoon";

const CollapsibleElement = ({
  useSecondaryColor,
  useTertiaryColor,
  title,
  buttonText,
  disabled,
  buttonId,
  children,
  buttonClickFunction,
  customButton,
  customButtonSchema,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const buttonSchema =
    customButtonSchema || (useSecondaryColor ? "warning" : useTertiaryColor ? "tertiary" : "primary");

  return (
    <div
      className="tw-relative tw-shadow-tdp-card tw-bg-white tw-flex tw-flex-col tw-gap-[20px] tw-p-[20px] tw-border-solid tw-border-[1px] tw-border-x-0 tw-border-t-0 tw-border-gray-10 first-of-type:tw-rounded-t-[4px] last-of-type:tw-rounded-b-[4px]"
      {...props}
    >
      <div className="tw-flex tw-justify-between">
        <button
          className="tw-bg-transparent tw-border-none tw-p-0 tw-flex tw-items-center tw-gap-[8px]"
          onClick={() => setIsOpen((o) => !o)}
        >
          <ChevronRightSolidV6
            className={`tw-text-theme-primary tw-transition-all ${isOpen ? "tw-rotate-90" : ""}`}
          />
          <span className="tw-text-18d">{title}</span>
        </button>
        {buttonText ? (
          <Button
            id={buttonId || buttonText}
            disabled={disabled}
            onClick={buttonClickFunction}
            schema={buttonSchema}
          >
            {buttonText}
          </Button>
        ) : (
          customButton || <span />
        )}
      </div>
      <div
        className={`tw-transition-all tw-text-[12px] ${
          isOpen ? "tw-h-full tw-opacity-1" : "tw-h-0 tw-opacity-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleElement;
