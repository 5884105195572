/* eslint-disable no-param-reassign, camelcase, no-restricted-globals, no-unused-vars */
import { UpdateWithSideEffect, Update, NoUpdate, SideEffect } from "use-reducer-with-side-effects";
import { produce } from "immer";
import actions from "./actions";
import { Listing } from "./models";
import apiWrapper from "./apiWrapper";
import {
  listingFromAPI,
  listingToAPI,
  financialDetailFromAPI,
  financialDetailToAPI,
  aggregationsByStatus,
} from "./apiAdapters";
import { newUrlBuilder, pipe, sortBy } from "./utils/helpers";
import NotifyError from "../shared/NotifyError";

const reorder = (list, startIndex, endIndex) => {
  const result = sortBy([...list], "position");
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reassignPositions = (list) => list.map((item, index) => ({ ...item, position: index + 1 }));
const hashifyColumns = (array) => array.reduce((acc, column) => ({ ...acc, [column.id]: column }), {});

const clearIfAll = (selectedIds) =>
  selectedIds.map((id) => id.toString()).indexOf("-1") >= 0 ? [] : selectedIds;

const normalizeStatus = (statuses) => statuses.map((status) => status.replace("-", "_"));

export const normalizeFilters = ({ filters, pagination, order }, includePageOrder = true) => {
  const keysToIgnore = ["closeDateRange", "statusChangedRange", "statusChangedDates", "publishedState"];
  const filtersWithAll = Object.keys(filters).reduce((acc, key) => {
    if (keysToIgnore.includes(key)) {
      return acc;
    }

    acc[key] = clearIfAll(filters[key]);
    return acc;
  }, {});

  // Prevent the server from crashing when the user omits the status changed dates
  const statusChangedDates = filters.statusChangedDates.filter((date) => date);
  let statusChangedRange;
  if (filters.statusChangedRange.includes("custom") && statusChangedDates.length !== 2) {
    statusChangedRange = filters.statusChangedRange.filter((item) => item !== "custom");
  } else {
    statusChangedRange = filters.statusChangedRange;
  }

  return {
    ...filtersWithAll,
    close_date_range: filters.closeDateRange,
    status_changed_range: statusChangedRange,
    status_changed_dates: statusChangedDates,
    status: normalizeStatus(filters.status),
    published_state: filters.publishedState,
    ...(includePageOrder && { page: pagination?.currentPage }),
    ...(includePageOrder && { order: [order] }),
  };
};

const newAddress = (filters) => {
  const targetUrl = newUrlBuilder(filters, window.location);
  window.location = targetUrl;
};

const addFilter = (filters, { type, value }) => {
  const newFilters = { ...filters };
  if (newFilters[type] && newFilters[type].indexOf(value) < 0) {
    newFilters[type].push(value);
  }
  return newFilters;
};

const removeFilter = (filters, { type, value }) => {
  const newFilters = { ...filters };
  if (newFilters[type]) {
    newFilters[type] = newFilters[type].filter((f) => f !== value);
  }
  return newFilters;
};

const initialStateGen = () => ({
  updateAddressModal: {
    isUpdateAddressModalOpen: false,
    listing: {
      uuid: "",
    },
    tempStatus: null,
  },
  importModal: {
    isImportModalOpen: false,
    listing: null,
    updatedStatus: "",
  },
  listings: {
    isFetching: false,
    data: [],
    entries: [],
    totals: {
      buyers: 0,
      sellers: 0,
      landlords: 0,
      tenants: 0,
      referrals: 0,
      gci: 0,
      closedVolume: 0,
    },
  },
  saveFiltersModal: {
    isLoading: false,
    isVisible: false,
    errors: [],
  },
  filters: {
    agent: [],
    status: [],
    source: [],
    closeDateRange: [],
    statusChangedRange: [],
    statusChangedDates: [],
    type: [],
    publishedState: [],
  },
  order: "",
  filterModalIsOpen: false,
  pagination: {
    currentPage: 1,
    nextPage: null,
    prevPage: null,
    totalPages: 1,
    totalCount: 0,
  },
  error: {
    showError: false,
    message: "",
  },
  renderableColumns: null,
  display: "list",
  addTransactionModalIsOpen: false,
});

export const initialState = initialStateGen();

export const reducer = (state, action) => {
  switch (action.name) {
    case actions.pagination.setPage().name: {
      return UpdateWithSideEffect(
        { ...state, pagination: { ...state.pagination, currentPage: action.value } },
        (_, dispatch) => dispatch(actions.listings.fetchStart()),
      );
    }

    case actions.pagination.setPaginationInfo().name: {
      return Update({ ...state, pagination: action.value });
    }

    case actions.listings.fetchStart().name: {
      return UpdateWithSideEffect({ ...state, listings: { ...state.listings, isFetching: true } }, () => {
        newAddress(normalizeFilters(state));
      });
    }
    case actions.listings.fetchSuccess().name: {
      return Update({
        ...state,
        listings: {
          ...state.listings,
          isFetching: false,
          data: action.data.listings,
          entries: action.data.listings.map((listing) => new Listing(listing)),
          totals: action.data.totals,
        },
        pagination: {
          currentPage: action.data.pagination.current_page,
          nextPage: action.data.pagination.next_page,
          prevPage: action.data.pagination.prev_page,
          totalPages: action.data.pagination.total_pages,
          totalCount: action.data.pagination.total_count,
        },
      });
    }
    case actions.listings.fetchFailure().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          listings: {
            ...state.listings,
            isFetching: false,
          },
        },
        (_, dispatch) => {
          dispatch(actions.errors.general());
        },
      );
    }
    case actions.listings.cardFetchStart("").name: {
      const { status } = action;
      const pagination = state.listings.byStatusPagination || {};
      if (pagination[status] && (pagination[status].next_page === null || pagination[status].isFetching)) {
        return NoUpdate();
      }

      return UpdateWithSideEffect(
        {
          ...state,
          listings: {
            ...state.listings,
            byStatusPagination: {
              ...pagination,
              [status]: {
                ...pagination[status],
                isFetching: true,
              },
            },
          },
        },

        (currentState, dispatch) => {
          const onSuccess = (response) => {
            dispatch(actions.listings.cardFetchSuccess(response.data, status));
          };
          const onFailure = (response) => {
            // eslint-disable-next-line no-console
            console.log(response);
          };

          const newPage = pagination[status]?.next_page || 1;
          const filters = {
            ...normalizeFilters(currentState),
            status: [status.replace("comingsoon", "coming-soon")],
            page: newPage,
          };

          apiWrapper.listings.list(filters).then(onSuccess, onFailure);
        },
      );
    }
    case actions.listings.cardFetchSuccess("").name: {
      return Update({
        ...state,
        listings: {
          ...state.listings,
          data: [...state.listings.data, ...action.data.listings.map(listingFromAPI)],
          entries: [
            ...state.listings.entries,
            ...action.data.listings.map((listingData) => new Listing(listingFromAPI(listingData))),
          ],
          byStatusPagination: {
            ...state.listings.byStatusPagination,
            [action.status]: {
              ...action.data.meta,
              isFetching: false,
            },
          },
          byStatusTotals: {
            ...state.listings.byStatusTotals,
            [action.status.replace(/coming[-_ ]soon/, "comingsoon")]: {
              ...action.data.totals,
            },
          },
        },
      });
    }

    case actions.listings.updateStart().name: {
      // something is changing actions
      const initialListingState = { ...action.originalListing };
      return SideEffect([
        (_, dispatch) => {
          const data = listingToAPI({ ...action.originalListing, ...action.changes });
          const changedStatus =
            initialListingState.status !== action.changes.status ||
            (Object.keys(action.changes).length === 1 && action.changes.status);

          const onSuccess = (response) => {
            dispatch(actions.listings.updateSuccess(response.data.listing));
            if (changedStatus) {
              dispatch(actions.financials.index());
              if (
                initialListingState.status === "pipeline" &&
                (action.changes.status === "active" || action.changes.status === "pending")
              ) {
                dispatch(
                  actions.importModal.toggleImportModal(true, initialListingState, action.changes.status),
                );
              } else if (
                action.changes.status === "comingsoon" &&
                !initialListingState?.address?.streetAddress
              ) {
                dispatch(
                  actions.updateAddressModal.toggleUpdateAddressModal(
                    true,
                    action.changes.status,
                    initialListingState,
                  ),
                );
              }
            }
          };

          const onFailure = (error) => {
            if (error.response.status > 404) {
              NotifyError(error, "Failed updating listing");
            }

            dispatch(actions.listings.updateFailure(initialListingState, error.response.data));
          };

          apiWrapper.listings.update(action.originalListing, data).then(onSuccess, onFailure);
        },
      ]);
    }

    // Update listing status and/or address only
    case actions.listings.updateListing().name: {
      return Update(
        produce(state, (draft) => {
          const listingIdx = state.listings.entries.findIndex(({ uuid }) => uuid === action.listing.uuid);
          const listing = draft.listings.entries[listingIdx];

          // Update Totals
          const STATUS_ACTUAL_VALUES = "sold";
          const isActualValues = listing.status === STATUS_ACTUAL_VALUES;
          const isActionActualValues = action.status === STATUS_ACTUAL_VALUES;
          const closePrice = Number(listing.closePrice || 0);
          const gci = Number(listing.gci || 0);

          if (isActualValues && !isActionActualValues) {
            draft.listings.totals.actualClosedVolume -= closePrice;
            draft.listings.totals.estimatedClosedVolume += closePrice;
            draft.listings.totals.actualGci -= gci;
            draft.listings.totals.estimatedGci += gci;
          }

          if (!isActualValues && isActionActualValues) {
            draft.listings.totals.actualClosedVolume += closePrice;
            draft.listings.totals.estimatedClosedVolume -= closePrice;
            draft.listings.totals.actualGci += gci;
            draft.listings.totals.estimatedGci -= gci;
          }

          draft.listings.byStatusTotals[listing.status].gci =
            Number(draft.listings.byStatusTotals[listing.status].gci) - gci;
          draft.listings.byStatusTotals[listing.status].closed_volume =
            Number(draft.listings.byStatusTotals[listing.status].closed_volume) - closePrice;
          draft.listings.byStatusTotals[listing.status][`${listing.type}s`] -= 1;

          draft.listings.byStatusTotals[action.status].gci =
            Number(draft.listings.byStatusTotals[action.status].gci) + gci;
          draft.listings.byStatusTotals[action.status].closed_volume =
            Number(draft.listings.byStatusTotals[action.status].closed_volume) + closePrice;
          draft.listings.byStatusTotals[action.status][`${listing.type}s`] += 1;

          // Update listing
          listing.status = action.status;

          if (action.addressDetails) {
            listing.address.city = action.addressDetails.city || "";
            listing.address.locality = action.addressDetails.locality || "";
            listing.address.postalCode = action.addressDetails.postal_code || "";
            listing.address.streetAddress = action.addressDetails.street_address || "";
            draft.updateAddressModal.isUpdateAddressModalOpen = false;
          }
        }),
      );
    }

    case actions.listings.updateStatusRequest().name: {
      // something is changing actions
      const initialListingState = { ...action.originalListing };
      return SideEffect([
        (_, dispatch) => {
          const data = listingToAPI({ ...action.originalListing, ...action.stateChanges });

          const onFailure = (error) => {
            if (error.response.status > 404) {
              NotifyError(error, "Failed updating listing");
            }

            dispatch(actions.listings.updateFailure(initialListingState, error.response.data));
          };

          apiWrapper.listings.update(action.originalListing, data).then(() => {
            if (
              initialListingState.status === "pipeline" &&
              (action.stateChanges?.status === "active" || action.stateChanges?.status === "pending")
            ) {
              dispatch(
                actions.importModal.toggleImportModal(
                  true,
                  action.originalListing,
                  action.stateChanges?.status,
                ),
              );
            } else if (
              action.stateChanges?.status === "comingsoon" &&
              !action.originalListing?.address?.streetAddress
            ) {
              dispatch(
                actions.updateAddressModal.toggleUpdateAddressModal(
                  true,
                  action.stateChanges?.status,
                  action.originalListing,
                ),
              );
            }
          }, onFailure);
        },
      ]);
    }

    case actions.listings.updateSuccess().name: {
      const adaptedData = listingFromAPI(action.listingData);

      return Update({
        ...state,
        listings: {
          ...state.listings,
          data: state.listings.data.map((entry) => (entry.id === adaptedData.id ? adaptedData : entry)),
          entries: state.listings.data.map((entry) =>
            entry.id === adaptedData.id ? new Listing(adaptedData) : new Listing(entry),
          ),
        },
      });
    }
    case actions.listings.updateFailure().name: {
      const error = action.error || {};
      // Grab the first error message, which is most likely relevant
      const message = error[Object.keys(error)?.[0]]?.[0];
      return Update({
        ...state,
        listings: {
          ...state.listings,
          entries: state.listings.entries.map((entry) =>
            entry.id === action.originalListing.id
              ? new Listing({ ...action.originalListing, failedAt: new Date() })
              : new Listing(entry),
          ),
        },
        error: {
          showError: true,
          message,
        },
      });
    }

    case actions.listings.archiveStart().name: {
      return UpdateWithSideEffect({ ...state }, (_, dispatch) => {
        // the second argument can also be an array of side effects
        const onSuccess = (listing) => dispatch(actions.listings.archiveSuccess(listing));
        const onFailure = (error) => {
          if (error.response.status > 404) {
            NotifyError(error, "Failed archiving listing");
          }
          dispatch(actions.listings.archiveFailure());
        };
        apiWrapper.listings.archive(action.listing).then(onSuccess, onFailure);
      });
    }
    case actions.listings.archiveSuccess().name: {
      return Update({
        ...state,
        listings: {
          ...state.listings,
          data: state.listings.data.map((entry) =>
            entry.id === action.listingData.id ? action.listingData : entry,
          ),
          entries: state.listings.data.map((entry) =>
            entry.id === action.listingData.id ? new Listing(action.listingData) : entry,
          ),
        },
      });
    }
    case actions.listings.archiveFailure().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          listings: {
            ...state.listings,
            entries: state.listings.data.map((entry) => new Listing(entry)),
          },
        },
        (_, dispatch) => {
          dispatch(actions.errors.general());
        },
      );
    }

    case actions.listings.deleteStart().name: {
      return UpdateWithSideEffect({ ...state }, (_, dispatch) => {
        const onSuccess = () => {
          dispatch(actions.listings.deleteSuccess(action.listing));
        };
        const onFailure = (error) => {
          if (error.response.status > 404) {
            NotifyError(error, "Failed archiving listing");
          }
          dispatch(actions.listings.deleteFailure());
        };
        // the second argument can also be an array of side effects
        apiWrapper.listings.delete(action.listing).then(onSuccess, onFailure);
      });
    }
    case actions.listings.deleteSuccess().name: {
      return Update({
        ...state,
        listings: {
          ...state.listings,
          data: state.listings.data.filter((entry) => entry.id !== action.listingData.id),
          entries: state.listings.entries.filter((entry) => entry.id !== action.listingData.id),
        },
      });
    }
    case actions.listings.deleteFailure().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          listings: {
            ...state.listings,
            entries: state.listings.data.map((entry) => new Listing(entry)),
          },
        },
        (_, dispatch) => {
          dispatch(actions.errors.general());
        },
      );
    }

    case "LISTING_UPDATE_AGGREGATIONS": {
      const newAggregation = aggregationsByStatus(action.data);

      return Update({
        ...state,
        listings: {
          ...state.listings,
          byStatusTotals: newAggregation,
        },
      });
    }

    case actions.saveFiltersModal.openModal().name: {
      return Update({
        ...state,
        saveFiltersModal: {
          ...state.saveFiltersModal,
          isVisible: true,
          errors: [],
        },
      });
    }

    case actions.saveFiltersModal.closeModal().name: {
      return Update({
        ...state,
        saveFiltersModal: {
          ...state.saveFiltersModal,
          isVisible: false,
          errors: [],
        },
      });
    }

    case actions.saveFiltersModal.saveStart().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          saveFiltersModal: {
            ...state.saveFiltersModal,
            isLoading: true,
            isVisible: true,
            errors: [],
          },
        },
        (_, dispatch) => {
          const onSuccess = () => {
            dispatch(actions.saveFiltersModal.saveSuccess());
          };

          const onFailure = (res) => {
            dispatch(actions.saveFiltersModal.saveFailure(res.response.data.errors));
          };

          apiWrapper.saveFiltersModal.create(action.data).then(onSuccess, onFailure);
        },
      );
    }

    case actions.saveFiltersModal.saveSuccess().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          listings: {
            ...state.listings,
            isFetching: true,
          },
          saveFiltersModal: {
            isLoading: false,
            isVisible: false,
            errors: [],
          },
        },
        (_, __) => {
          // Necessary to populate the smart filters on the left navigation bar.
          location.reload();
        },
      );
    }

    case actions.saveFiltersModal.saveFailure().name: {
      return Update({
        ...state,
        saveFiltersModal: {
          isLoading: false,
          isVisible: true,
          errors: action.errors,
        },
      });
    }

    case actions.filters.add({ filter: {} }).name: {
      return UpdateWithSideEffect(
        {
          ...state,
          filters: addFilter(state.filters, action.filter),
          pagination: { ...state.pagination, currentPage: 1 },
        },
        (_, dispatch) => {
          // the second argument can also be an array of side effects
          dispatch(actions.listings.fetchStart());
        },
      );
    }
    case actions.filters.remove({ filter: {} }).name: {
      return UpdateWithSideEffect(
        {
          ...state,
          filters: removeFilter(state.filters, action.filter),
          pagination: { ...state.pagination, currentPage: 1 },
        },
        (_, dispatch) => {
          // the second argument can also be an array of side effects
          dispatch(actions.listings.fetchStart());
        },
      );
    }
    case actions.filters.set().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          filters: { ...initialStateGen().filters, ...action.filters },
          pagination: { ...state.pagination, currentPage: 1 },
        },
        (_, dispatch) => {
          dispatch(actions.listings.fetchStart());
        },
      );
    }
    case actions.filters.clear().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          filters: initialStateGen().filters,
        },
        (_, dispatch) => {
          dispatch(actions.listings.fetchStart());
        },
      );
    }

    case actions.filters.openModal().name: {
      return Update({ ...state, filterModalIsOpen: true });
    }

    case actions.filters.closeModal().name: {
      return Update({ ...state, filterModalIsOpen: false });
    }

    case actions.order.set().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          order: action.value,
        },
        (_, dispatch) => {
          dispatch(actions.listings.fetchStart());
        },
      );
    }

    case actions.errors.general().name: {
      return Update({
        ...state,
        error: {
          showError: true,
          message: "Something went wrong",
        },
      });
    }

    case actions.errors.clear().name: {
      return Update({
        ...state,
        error: {
          showError: false,
          message: "",
        },
      });
    }

    case actions.rendering.setColumns().name: {
      return UpdateWithSideEffect(
        {
          ...state,
          renderableColumns: action.columns,
        },
        (_, dispatch) => dispatch(actions.preferences.save(action.columns)),
      );
    }

    case actions.rendering.reorderColumn({}).name: {
      const columnArray = Object.keys(state.renderableColumns).map((key) => ({
        ...state.renderableColumns[key],
        id: key,
      }));
      const result = pipe(
        () => reorder(columnArray, action.sourceIndex, action.destinationIndex),
        reassignPositions,
        hashifyColumns,
      )(columnArray);

      return UpdateWithSideEffect(
        {
          ...state,
          renderableColumns: result,
        },
        (_, dispatch) => dispatch(actions.preferences.save(result)),
      );
    }

    case actions.rendering.setAddTransactionModal().name: {
      return Update({
        ...state,
        addTransactionModalIsOpen: action.isOpen,
        status: action.status,
      });
    }

    case actions.preferences.save({}).name: {
      return SideEffect([
        () => {
          apiWrapper.preferences.create({ dashboard: "listings", preferences: action.preferences });
        },
      ]);
    }

    case actions.rendering.setDisplay("").name: {
      return SideEffect(() => {
        newAddress({ ...normalizeFilters(state), display: action.display });
      });
    }

    case actions.updateAddressModal.toggleUpdateAddressModal().name: {
      return Update(
        produce(state, (draft) => {
          draft.updateAddressModal.isUpdateAddressModalOpen = action.bool;
          draft.updateAddressModal.tempStatus = action.tempStatus;
          draft.updateAddressModal.listing = action.listing;
        }),
      );
    }

    case actions.importModal.toggleImportModal().name: {
      return Update(
        produce(state, (draft) => {
          draft.importModal.isImportModalOpen = action.bool;
          draft.importModal.listing = action.listing;
          draft.importModal.updatedStatus = action.updatedStatus;
        }),
      );
    }

    default: {
      return NoUpdate();
    }
  }
};
