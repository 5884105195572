import React from "react";

const CheckBox = ({ divClass, inputChecked, inputClass, inputName, label, labelClass, onUpdate }) => (
  <div className={divClass + " checkbox"}>
    <label className={labelClass}>
      <input
        className={inputClass}
        type="checkbox"
        checked={!!inputChecked}
        name={inputName}
        onChange={(e) =>
          onUpdate({ target: { name: inputName, value: e.target.checked, input_class: inputClass } })
        }
      />
      {label}
    </label>
  </div>
);

export default CheckBox;
