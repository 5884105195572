import React from "react";
import PropTypes from "prop-types";

import { FloatingForm } from "../../../../../shared/v2/Modal";
import ErrorBanner, { errorForBanner } from "../../../../../shared/v2/ErrorBanner";

const ActionDeletionConfirmationModal = ({
  action,
  planName,
  error,
  show,
  isLoading,
  onCancel,
  onConfirm,
}) => {
  const willDeleteOtherActions = !!action.descendants?.length;

  return (
    <FloatingForm
      title="Delete Action?"
      show={show}
      onCancel={onCancel}
      primaryOption="Yes, delete"
      primaryProps={{ schema: "warning" }}
      onPrimary={onConfirm}
      isPrimaryLoading={isLoading}
      closeOnClickOutside
      closeOnEscape
    >
      <div className="tw-flex tw-flex-col tw-gap-y-12px">
        <ErrorBanner errors={error ? [errorForBanner(error)] : null} />

        <p>
          Please note that{" "}
          <b>
            {action.type}: {action.name}
          </b>{" "}
          is generated from <b>Auto Plan: {planName}</b>, and deleting this action will delete all tasks and
          interactions contingent on that.
        </p>

        {willDeleteOtherActions && (
          <>
            <p>As such, the following actions will also be deleted if this action is removed:</p>
            <ul className="tw-pl-24px">
              {action.descendants.map((descendantAction) => (
                <li key={descendantAction.id}>
                  <b>
                    {descendantAction.type}: {descendantAction.name}
                  </b>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </FloatingForm>
  );
};

ActionDeletionConfirmationModal.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,

    descendants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  planName: PropTypes.string.isRequired,
  error: PropTypes.string,

  show: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ActionDeletionConfirmationModal.defaultProps = {
  error: null,
};

export default ActionDeletionConfirmationModal;
