/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CaretDown = (props) => (
  <Icon {...props} name="Caret Down">
    <path d="M3.113 4.706h9.774c.676 0 1.014.84.536 1.332l-4.885 5.028a.746.746 0 0 1-1.075 0L2.578 6.038c-.479-.492-.141-1.332.536-1.332z" />
  </Icon>
);

export default CaretDown;
