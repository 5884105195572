import React from 'react';
import alerts from './listing_alerts.module.css';


const SearchTermResults = (props) => {
  const { categoryPosition, currentCategoryPosition, currentChildPosition, handleHover, handleSelect,  results } = props

  return (
    <div>
      <div className={alerts.resultLabel}>
        {results.text}
      </div>
      {
        results.children.map((result, index) => {
          const positionClass = currentCategoryPosition === categoryPosition && currentChildPosition === index ? alerts.highlight : ''

          return (
            <div
              key={result.value}
              className={`${alerts.resultItem} ${positionClass}`}
              onMouseOver={() => handleHover(categoryPosition, index)}
              onMouseDown={() => handleSelect({ multiCat: result.category, multiSearch: result.value, label: result.label })}
            >
              {result.text}
            </div>
          )
        })
      }
    </div>
  );
};

export default SearchTermResults;
