import React from "react";
import PropTypes from "prop-types";

import { transactionShape } from "../../utils";

import TransactionGCI from "./TransactionGCI";
import { tdpDetails } from "../../../../../shared/HOCs/withRedux";

/** The TransactionGci component, plus its line items. */
const TransactionGciWithLineItems = ({
  transaction,
  onClosedPriceChange,
  onCommissionLabelClick,
  onCommissionValueChange,
  onAddExpenses,
  onAddIncome,
  onLineItemDragEnd,
  onLineItemOptionChange,
  onLineItemValueChange,
  onEditExpenseLineItem,
  onEditIncomeLineItem,
  onRemoveLineItem,
  isReferral,
}) => (
  <TransactionGCI
    transaction={transaction}
    onClosedPriceChange={onClosedPriceChange}
    onCommissionLabelClick={onCommissionLabelClick}
    onEstCommissionLabelClick={() => {}}
    onCommissionValueChange={onCommissionValueChange}
    onAddExpenses={onAddExpenses}
    onAddIncome={onAddIncome}
    isReferral={isReferral}
    onLineItemDragEnd={onLineItemDragEnd}
    onLineItemOptionChange={onLineItemOptionChange}
    onLineItemValueChange={onLineItemValueChange}
    onEditExpenseLineItem={onEditExpenseLineItem}
    onEditIncomeLineItem={onEditIncomeLineItem}
    onRemoveLineItem={onRemoveLineItem}
  />
);

TransactionGciWithLineItems.propTypes = {
  transaction: transactionShape.isRequired,
  onClosedPriceChange: PropTypes.func,
  onCommissionLabelClick: PropTypes.func,
  onCommissionValueChange: PropTypes.func,
  onAddExpenses: PropTypes.func,
  onAddIncome: PropTypes.func,
  onLineItemDragEnd: PropTypes.func,
  onLineItemOptionChange: PropTypes.func,
  onLineItemValueChange: PropTypes.func,
  onEditExpenseLineItem: PropTypes.func,
  onEditIncomeLineItem: PropTypes.func,
  onRemoveLineItem: PropTypes.func,
  isReferral: PropTypes.bool,
};

TransactionGciWithLineItems.defaultProps = {
  onClosedPriceChange: () => {},
  onCommissionLabelClick: () => {},
  onCommissionValueChange: () => {},
  onAddExpenses: () => {},
  onAddIncome: () => {},
  onLineItemDragEnd: () => {},
  onLineItemOptionChange: () => {},
  onLineItemValueChange: () => {},
  onEditExpenseLineItem: () => {},
  onEditIncomeLineItem: () => {},
  onRemoveLineItem: () => {},
  isReferral: false,
};

export default tdpDetails({
  WrappedComponent: TransactionGciWithLineItems,
});
