/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Report = (props) => (
  <Icon {...props} name="Report">
    <path d="M14.672 11.667c.18 0 .328.155.328.344v1.146a.338.338 0 0 1-.328.344H1.328A.338.338 0 0 1 1 13.157V2.844c0-.189.148-.344.328-.344h1.094c.18 0 .328.155.328.344v8.823h11.922zM6.25 10.406V8.343a.338.338 0 0 0-.328-.344H4.828a.338.338 0 0 0-.328.344v2.063c0 .189.148.344.328.344h1.094c.18 0 .328-.155.328-.344zm5.25 0V6.51a.338.338 0 0 0-.328-.344h-1.094a.338.338 0 0 0-.328.344v3.896c0 .189.148.344.328.344h1.094c.18 0 .328-.155.328-.344zm-2.625 0V4.677a.338.338 0 0 0-.328-.344H7.453a.338.338 0 0 0-.328.344v5.729c0 .189.148.344.328.344h1.094c.18 0 .328-.155.328-.344zm5.25 0V3.76a.338.338 0 0 0-.328-.344h-1.094a.338.338 0 0 0-.328.344v6.646c0 .189.148.344.328.344h1.094c.18 0 .328-.155.328-.344z" />
  </Icon>
);

export default Report;
