import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Typography from "@shared/v2/Typography";

const FeaturedImageHelpModal = ({ open, onClose }) => (
  <Modal
    show={open}
    onHide={onClose}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Featured Images" closeButton />
    <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Typography variant="subtitle-m">How the Featured Image is Used</Typography>
        <Typography variant="body-m">
          <ul className="tw-mb-0 tw-pl-[2em]">
            <li>Thumbnail image for your post</li>
            <li>Main image at the top of the post page</li>
            <li>
              Image used for link previews when sharing on social media platforms or messaging applications
              (Open Graph Image)
            </li>
          </ul>
        </Typography>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Typography variant="subtitle-m">Upload Limits and Recommendations</Typography>
        <Typography variant="body-m">
          <ul className="tw-mb-0 tw-pl-[2em]">
            <li>5 MB upload limit</li>
            <li>Recommended maximum 1 MB file size</li>
            <li>Compress your images before uploading to increase your site performance</li>
            <li>200 pixels by 200 pixels minimum size for social media sharing</li>
            <li>We recommend using images that are high quality and at least 2000 pixels wide</li>
            <li>
              Add a description of your image to the “Alt Image Text” field to improve SEO and user
              accessibility (ADA compliance)
            </li>
          </ul>
        </Typography>
      </div>
      <Typography variant="body-s" className="!tw-text-gray-50">
        <strong>Disclaimer:</strong> Only upload images that you own or have proper permissions and licenses
        to use. Using images without permission can lead to fines, legal action, or the removal of your
        website.
      </Typography>
    </Modal.Body>
  </Modal>
);

FeaturedImageHelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeaturedImageHelpModal;
