import { useState } from "react";

const useZoom = (centerView) => {
  const [zoomVal, setZoomVal] = useState(0);

  const zoomInOut = (zoomIn) => {
    setZoomVal((prev) => {
      const validator = zoomIn ? prev > 0 : prev < 100;
      const num = zoomIn ? prev - 10 : prev + 10;

      if (validator) centerView(1 + num * 0.01);

      return validator ? num : prev;
    });
  };

  const zoomRange = (e) => {
    setZoomVal(() => {
      const num = +e.target.value * 0.01;
      centerView(1 + num);
      return +e.target.value;
    });
  };

  return [zoomVal, zoomRange, zoomInOut];
};

export default useZoom;
