import React from "react";
import capitalize from "capitalize";
import { Row, Col } from "../../shared/v1";

class Milestone extends React.Component {
  render() {
    const { milestone, showEditMilestoneModal, showDeleteMilestoneModal, milestonableMilestonesUsageCounts } =
      this.props;
    const usage_count = milestonableMilestonesUsageCounts[milestone.name] || 0;
    const name =
      milestone.name &&
      milestone.name
        .split("_")
        .map((element) => capitalize(element))
        .join(" ");

    return (
      <Row className="milestone-table-row">
        {milestone.milestonable_type == "Listing" && (
          <Col size={2} className="milestone-table-text-align-left">
            {milestone.visible ? (
              <i className="fa fa-eye brivity-blue" />
            ) : (
              <i className="fa fa-eye-slash gray" />
            )}
          </Col>
        )}
        <Col size={6}>{name}</Col>
        <Col size={2} className="milestone-table-text-align-center">
          {usage_count}
        </Col>
        <Col size={2}>
          {milestone.default && (
            <div className="gray disabled-cursor inline-item pseudo-link tw-float-right">
              <i className="fa fa-pencil p-r-7" />
              <i className="fa fa-times" />
            </div>
          )}
          {!milestone.default && (
            <div className="brand-primary inline-item pseudo-link tw-float-right">
              <i className="fa fa-pencil p-r-7" onClick={() => showEditMilestoneModal(milestone)} />
              <i className="fa fa-times" onClick={() => showDeleteMilestoneModal(milestone)} />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
export default Milestone;
