import React, { useCallback, useEffect, useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Search as SearchIcon } from "../../../../shared/v2/Icomoon";
import TextComboInput from "../../../../shared/v2/TextComboInput";
import SearchTermResults from "./SearchTermResults";
import * as actionCreators from "../actions/thunks";
import ImportSearch from "./ImportSearch";
import errorShape from "../model/error";

const HomeSearch = ({
  importSearchError,
  getAssetCardError,
  getsearchListingAsThunk,
  importSearchListingAsThunk,
  homelisting,
  search,
  setSearch,
  isSearched,
  importedListing,
  setSearched,
}) => {
  const [searchTerm, setSearchTerm] = useState(search);
  const [hasTyped, setHasTyped] = useState(false);
  const [selectedAutocompleteId, setSelectedAutocompleteId] = useState(null);

  const handleMouseSelect = (searchvalue, autocompleteId) => {
    setSearchTerm(searchvalue);
    setSelectedAutocompleteId(autocompleteId);
    setSearched(true);
  };
  useEffect(() => {
    if (isSearched && searchTerm && selectedAutocompleteId) {
      importSearchListingAsThunk(searchTerm, selectedAutocompleteId);
    }
  }, [isSearched]);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setHasTyped(true);
  };
  const deleteImport = () => {
    setSearched(false);
    setSearchTerm("");
    setSelectedAutocompleteId(null);
    setSearch("", true);
  };
  useEffect(() => {
    if (importSearchError || getAssetCardError) {
      deleteImport();
    }
  }, [importSearchError, getAssetCardError]);
  const submitSearch = useCallback(() => {
    const searchTermTrimmed = searchTerm.trim();
    if (searchTermTrimmed === search) {
      // We already searched for this term, so no need to search again
      // (happens when typing a space and waiting 1 second)
      return;
    }
    getsearchListingAsThunk(searchTermTrimmed);
  }, [search, searchTerm]);

  useEffect(() => {
    if (hasTyped) {
      // Trigger search while user types, with a max of 1 search per second
      const timeoutId = window.setTimeout(submitSearch, 1000);
      return () => window.clearTimeout(timeoutId);
    }
    return () => {};
  }, [hasTyped, submitSearch]);
  return (
    <div data-cy="tdp-search-address-container">
      {!importedListing && (
        <TextComboInput
          className="tw-w-full"
          inputClassName="tw-p-0"
          autoComplete="no"
          placeholder="Search listing address or MLS # to import data"
          onChange={handleChange}
          value={searchTerm}
          disabled={isSearched}
          trailing={
            isSearched ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <div className="tw-flex tw-items-center">
                <SearchIcon className="tw-text-neutral-gray-30" size="l" />
              </div>
            )
          }
        />
      )}
      {!isSearched && search && (
        <div className="tw-mt-[4px] tw-rounded-sm tw-border-solid tw-border tw-border-gray-15 tw-shadow-bootstrap-18 tw-z-10 tw-overflow-y-scroll">
          {homelisting.length > 0 ? (
            homelisting.map((results, i) => (
              <SearchTermResults
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                handleSelect={handleMouseSelect}
                results={results}
              />
            ))
          ) : (
            <div className="tw-h-[32px] tw-px-[12px] tw-py-[6px]">No matches found! try another search.</div>
          )}
        </div>
      )}
      {isSearched && importedListing && <ImportSearch deleteImport={deleteImport} />}
    </div>
  );
};

HomeSearch.propTypes = {
  importSearchError: errorShape,
  getAssetCardError: errorShape,
  search: Proptypes.string,
  homelisting: Proptypes.arrayOf(Proptypes.shape({})),
  getsearchListingAsThunk: Proptypes.func.isRequired,
  importSearchListingAsThunk: Proptypes.func.isRequired,
  setSearch: Proptypes.func.isRequired,
  setSearched: Proptypes.func.isRequired,
  isSearched: Proptypes.bool,
  importedListing: Proptypes.shape({}),
};
HomeSearch.defaultProps = {
  search: "",
  importSearchError: null,
  getAssetCardError: null,
  homelisting: [],
  isSearched: false,
  importedListing: null,
};
const mapStateToProps = (state) => ({
  importSearchError: state.tdpMarketingReducer.importSearchError,
  getAssetCardError: state.tdpMarketingReducer.getAssetCardError,
  homelisting: state.tdpMarketingReducer.homelisting,
  importedListing: state.tdpMarketingReducer.importedListing,
  search: state.tdpMarketingReducer.search,
  isSearched: state.tdpMarketingReducer.isSearched,
});

const mapDispatchToProps = (dispatch) => ({
  getsearchListingAsThunk: (search) => dispatch(actionCreators.getsearchListingAsThunk(search)),
  importSearchListingAsThunk: (searchvalue, autocompleteId) =>
    dispatch(actionCreators.importSearchListingAsThunk(searchvalue, autocompleteId)),
  setSearch: (search, isDelete) => dispatch(actionCreators.setSearch(search, isDelete)),
  setSearched: (isSearched) => dispatch(actionCreators.setSearched(isSearched)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeSearch);
