import React, { useRef } from "react";
import PropTypes from "prop-types";
import UserImageButton from "../shared/UtilsV1/UserImageButton";
import Button from "../shared/v2/Button";

function UploadPersonAvatarField({ person, onUpdate, onRemove }) {
  const uploadInput = useRef();

  return (
    <div
      className="tw-rounded"
      style={{
        background: "repeating-linear-gradient(-45deg, #c2c2c2, #c2c2c2 8px, #fff 8px, #fff 16px)",
        padding: "1px",
      }}
    >
      <div className="tw-py-4 tw-pl-4 tw-pr-7 tw-flex tw-justify-between tw-bg-white">
        <UserImageButton
          size="large"
          imgUrl={person?.avatarUrl}
          onClick={() => uploadInput.current.click()}
          firstName={person?.firstName}
          lastName={person?.lastName}
        />
        {!person?.avatarUrl || person?.avatarUrl.includes("user-default.svg") ? (
          <div className="tw-flex tw-items-center">
            <Button size="medium" onClick={() => uploadInput.current.click()}>
              Upload
            </Button>
          </div>
        ) : (
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-[14px]">
            <Button size="medium" schema="secondary" onClick={() => uploadInput.current.click()}>
              Change
            </Button>

            <Button schema="warning" onClick={onRemove}>
              Remove
            </Button>
          </div>
        )}
        <input
          type="file"
          onChange={(e) => onUpdate(e.target.files?.[0])}
          style={{ display: "none" }}
          ref={uploadInput}
        />
      </div>
    </div>
  );
}

UploadPersonAvatarField.propTypes = {
  person: PropTypes.shape().isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default UploadPersonAvatarField;
