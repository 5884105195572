/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";
import FormElement from "../../shared/FormElement";
import FormAgentOrPondSelect from "../../shared/FormAgentOrPondSelect";
import DatePicker from "../../shared/v2/DatePicker";
import ErrorBanner from "../../shared/v2/ErrorBanner";
import RadioButton from "../../shared/v2/RadioButton/RadioButton";
import { useCurrentUser, useTheme } from "../../reducers/layoutReducer/selectors";

const DeactivateUserModal = ({ onClose, show, userId }) => {
  const theme = useTheme();
  const currentUser = useCurrentUser();
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [organizationEndDate, setOrganizationEndDate] = useState(null);
  const [reassignLeadsTo, setReassignLeadsTo] = useState({ primary_agent: currentUser.account.ownerId });
  const [reasonForLeaving, setReasonForLeaving] = useState("");
  const [departureType, setDepartureType] = useState("Voluntarily");

  const onReload = (data) => {
    onClose(data);
    localStorage.setItem(
      "successMsg",
      "User is archived. Reassigning their leads and tasks is in process. This might take a few minutes. ",
    );
    window.location.replace(
      `${window.location.origin}/people/team?leads_filter%5Bselected_sources%5D%5B%5D=`,
    );
  };

  useEffect(() => {
    setReassignLeadsTo({ primary_agent: currentUser.account.ownerId });
  }, [currentUser.account.ownerId]);

  const deactivateUserOnClick = () => {
    const newErrors = [];
    if (!organizationEndDate) newErrors.push("Last Day is required.");
    if (!reasonForLeaving) newErrors.push("Reason For Leaving is required.");
    setErrors(newErrors);
    if (newErrors.length > 0) return;

    setIsDeleting(true);
    const model = {
      organizationEndDate:
        organizationEndDate === "" ? null : moment(organizationEndDate).format("DD/MM/YYYY"),
      reasonForLeaving,
      departureType,
    };
    if (reassignLeadsTo.primary_agent) {
      model.reassignLeadsToId = reassignLeadsTo.primary_agent;
      model.reassignLeadsToType = "user";
    } else {
      model.reassignLeadsToId = reassignLeadsTo.lead_pond;
      model.reassignLeadsToType = "lead_pond";
    }
    caseTransformingAxios
      .patch(`/users/${userId}/deactivate`, model)
      .then((res) => {
        setIsDeleting(false);
        onReload(res.data || true);
      })
      .catch((err) => {
        setIsDeleting(false);
        onClose(undefined, err?.response?.data.content || err?.response?.data.message);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px] tw-max-w-full"
    >
      <Modal.Header title="Archive User?" closeButton />
      <div className="tw-flex tw-flex-col tw-gap-[24px] tw-mt-[12px]">
        <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-50">
          Before archiving a user, we recommend that you reassign their tasks to the remaining users on your
          team. If you do not reassign these items, they will become &quot;unassigned&quot; and you can
          reassign them later.
        </div>
        <div className="tw-text-14d tw-font-normal tw-text-neutral-gray-50">
          Reassigning leads and tasks for this user may take a few minutes once they have been archived.
        </div>

        <ErrorBanner errors={errors} />

        <FormElement
          label="Last Day"
          required
          element={
            <DatePicker
              placeholder="MM / DD / YYYY"
              value={organizationEndDate}
              onChange={setOrganizationEndDate}
            />
          }
        />
        <FormElement
          label="Reassign Leads To"
          element={
            <FormAgentOrPondSelect
              enableLeadPonds
              leadPond={reassignLeadsTo?.lead_pond}
              primaryAgent={reassignLeadsTo?.primary_agent}
              onChange={setReassignLeadsTo}
            />
          }
        />
        <FormElement
          label="Reason For Leaving"
          required
          element={
            <>
              <textarea
                className="tw-block tw-w-full tw-h-[76px] tw-p-2 tw-bg-tinted-gray-50 tw-border-neutral-gray-15"
                value={reasonForLeaving}
                onChange={(e) => setReasonForLeaving(e.target.value)}
              />
              {theme.name === "place-theme" && (
                <div role="radiogroup" className="tw-mt-[8px] tw-flex tw-gap-[16px]">
                  <RadioButton
                    label="Left Team Voluntarily"
                    name="departure_type"
                    checked={departureType === "Voluntarily"}
                    value="Voluntarily"
                    onChange={(e) => setDepartureType(e.target.value)}
                  />
                  <RadioButton
                    label="Left Team Involuntarily"
                    name="departure_type"
                    checked={departureType === "Involuntarily"}
                    value="Involuntarily"
                    onChange={(e) => setDepartureType(e.target.value)}
                  />
                </div>
              )}
            </>
          }
        />
        <div className="tw-flex tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={onClose} disabled={isDeleting}>
            Cancel
          </Button>
          <Button schema="warning" size="medium" isLoading={isDeleting} onClick={deactivateUserOnClick}>
            {isDeleting ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="tw-h-[10px] tw-w-[10px] tw-text-neutral-gray-75 tw-mx-auto fa-spin"
              />
            ) : (
              "Yes, Archive"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeactivateUserModal.propTypes = {
  userId: PropTypes.number,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeactivateUserModal.defaultProps = {
  userId: null,
};

export default DeactivateUserModal;
