import React from 'react';
import { FormGroup } from 'react-bootstrap';
import css from './BulkMarketReports.module.css'

class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  radioButtonClass = () => this.props.selected ? "fa fa-dot-circle-o brivity-blue" : "brivity-grey-text fa fa-circle-o";

  handleClick = () => this.props.radioGroupHandler(this.props.name);

  render() {
    return (
      <FormGroup onClick={this.handleClick} className={css.groupRadio}>
        <label className={this.radioButtonClass()} />
        <span>{this.props.label}</span>
      </FormGroup>
    )
  }
}

export default RadioButton;
