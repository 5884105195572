import React from "react";
import PropTypes from "prop-types";
import Button from "../../shared/v2/Button";
import { Table, TBody, TD, THead, TR, TH } from "../../Reports/components/BusinessDashboard/Table/Table";
import userName from "./userName";

const TollFreeTable = ({ tollFreeNumbers, users, unassignPhone }) => {
  const findUser = (userId) => users.find((u) => u.id === userId);

  return (
    <div className="row">
      <h2>Purchased Toll-Free Numbers</h2>

      <Table>
        <THead>
          <TR className="tw-font-semibold tw-bg-blue-10">
            <TH>Toll Free Number</TH>
            <TH>User</TH>
            <TH>Previous Phone Number</TH>
            <TH>Assigned at</TH>
            <TH>Assign</TH>
          </TR>
        </THead>
        <TBody>
          {tollFreeNumbers.map((tollFreeNumber) => {
            const user = findUser(tollFreeNumber.user_id);

            return (
              <TR key={tollFreeNumber.id} className="hover:tw-bg-neutral-gray-5">
                <TD className="tw-border-solid tw-border-neutral-gray-5 tw-border-t-0 tw-border-b-2 tw-border-x-0">
                  {tollFreeNumber.toll_free_phone_number}
                </TD>
                <TD className="tw-border-solid tw-border-neutral-gray-5 tw-border-t-0 tw-border-b-2 tw-border-x-0">
                  {user && userName(user)}
                </TD>
                <TD className="tw-border-solid tw-border-neutral-gray-5 tw-border-t-0 tw-border-b-2 tw-border-x-0">
                  {tollFreeNumber.previous_user_local_phone_number}
                </TD>
                <TD className="tw-border-solid tw-border-neutral-gray-5 tw-border-t-0 tw-border-b-2 tw-border-x-0">
                  {tollFreeNumber.assigned_at}
                </TD>
                <TD className="tw-border-solid tw-border-neutral-gray-5 tw-border-t-0 tw-border-b-2 tw-border-x-0">
                  {user && (
                    <Button size="small" schema="primary" onClick={() => unassignPhone(tollFreeNumber.id)}>
                      Reassign to brivity number
                    </Button>
                  )}
                </TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

TollFreeTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tollFreeNumbers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  unassignPhone: PropTypes.func.isRequired,
};

export default TollFreeTable;
