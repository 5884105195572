import React, { useState, useEffect, useRef, forwardRef } from "react";

import Avatar from "../../../shared/v2/Avatar";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import ActivitiesDropdown from "./ActivitiesDropdown";
import Dash from "./Dash";
import { Activities } from "./helpers";
import "./leaderboards.scss";
import Loader from "../../../Dashboard/components/Loader";

const TeamTable = ({
  users = [],
  selectedActivity,
  timeFrame,
  setSelectedActivity,
  maxCount,
  goalDivider,
  selectedUser,
  isPlaceUser,
  isLoading,
}) => {
  const columnRefs = useRef({});
  const shouldAutoScroll = useRef(false);
  const [hoveredColumn, setHoveredColumn] = useState("");

  const activity = SortOptions.find((s) => s.value === selectedActivity);

  useEffect(() => {
    if (!shouldAutoScroll.current) {
      shouldAutoScroll.current = true;
      return;
    }

    if (columnRefs.current[selectedActivity]) {
      columnRefs.current[selectedActivity].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedActivity]);

  return (
    <Section className="tw-relative tw-p-32px bs-xs:tw-p-32px">
      <div className="tw-flex tw-justify-between tw-mb-16px">
        <SectionLabel>
          Leaderboard {Dash} {timeFrame}
        </SectionLabel>
        <div className="tw-flex tw-items-center tw-gap-8px tw-mb-[16px]">
          <span className="tw-text-gray-50">Ranked By:</span>
          <ActivitiesDropdown
            activity={activity}
            onActivityChosen={setSelectedActivity}
            options={SortOptions}
          />
        </div>
      </div>
      <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0 tw-ml-[-24px]">
        <table className="tw-overflow-scroll tw-table-auto tw-flex-nowrap tw-border-separate">
          <thead>
            <tr className="tw-h-[34px]">
              <InitialHeader isPlaceUser={isPlaceUser} />
              {Activities.map(({ key, shortName }) => (
                <TableHeader
                  key={key}
                  ref={(e) => (columnRefs.current[key] = e)}
                  name={shortName}
                  nameForChange={key}
                  isSelectedActivity={key === selectedActivity}
                  setSelectedActivity={setSelectedActivity}
                  isPlaceUser={isPlaceUser}
                  isHoveredColumn={hoveredColumn === key}
                  setHoveredColumn={setHoveredColumn}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => (
              <TableRow
                key={user.user_id}
                user={user}
                rank={user[selectedActivity].rank}
                maxActivityCount={maxCount}
                isSelectedUser={selectedUser === user["user_id"]}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity}
                isLastRow={i === users.length - 1}
                goalDivider={goalDivider}
                isPlaceUser={isPlaceUser}
                hoveredColumn={hoveredColumn}
                setHoveredColumn={setHoveredColumn}
              />
            ))}
          </tbody>
        </table>
      </div>
      {isLoading && <Loader />}
    </Section>
  );
};

export default TeamTable;

const SortOptions = Activities.map(({ fullName, key }) => ({ label: fullName, value: key }));

const InitialHeader = ({ isPlaceUser }) => {
  return (
    <th className="tw-sticky tw-left-0 tw-bg-white not-selected-header">
      <div className="tw-flex tw-items-center tw-pl-24px tw-mb-18px tw-font-normal tw-text-gray-75 tw-text-12px">
        <div
          className={`tw-mr-8px tw-h-12px tw-w-12px ${
            isPlaceUser ? "tw-bg-semantic-blue-100" : "tw-bg-brivity-blue-100"
          } tw-rounded-full`}
        />
        <div className="tw-mr-16px">Actual Progress</div>
        <div
          className={`tw-mr-8px tw-h-12px tw-w-12px ${
            isPlaceUser ? "tw-bg-semantic-blue-10" : "tw-bg-brivity-blue-20"
          } tw-rounded-full`}
        />
        <div className="tw-mr-16px">Goal for Period</div>
        <div
          className="tw-mr-8px tw-h-12px tw-w-12px tw-rounded-full"
          style={{ background: "linear-gradient(270deg, #E5941B 0%, #FAE4C3 99.14%)" }}
        />
        <div>Surpassed Goal</div>
      </div>
    </th>
  );
};

const TableHeader = forwardRef(
  (
    {
      name,
      isSelectedActivity,
      nameForChange,
      setSelectedActivity,
      isPlaceUser,
      isHoveredColumn,
      setHoveredColumn,
    },
    ref,
  ) => {
    const headerBorderStyle =
      isSelectedActivity && isPlaceUser
        ? "place-selected-header tw-text-semantic-blue-100"
        : isSelectedActivity
        ? "selected-header tw-text-brivity-blue-100"
        : "not-selected-header tw-text-gray-50 tw-cursor-pointer";

    const hoverStyle = getHoverStyle(isHoveredColumn, isPlaceUser);

    return (
      <th
        ref={ref}
        className={`tw-min-w-[112px] tw-scroll-ml-[761px] tw-whitespace-nowrap ${headerBorderStyle} ${hoverStyle}`}
        onClick={() => setSelectedActivity(nameForChange)}
        onMouseEnter={() => setHoveredColumn(nameForChange)}
        onMouseLeave={() => setHoveredColumn("")}
      >
        <div className={`tw-flex tw-items-center tw-justify-center tw-gap-4px tw-font-semibold`}>
          {name}
          {isSelectedActivity && <Arrows isPlaceUser={isPlaceUser} />}
        </div>
      </th>
    );
  },
);

const TableRow = ({
  user,
  rank,
  maxActivityCount,
  isSelectedUser,
  selectedActivity,
  isLastRow,
  goalDivider,
  isPlaceUser,
  setSelectedActivity,
  hoveredColumn,
  setHoveredColumn,
}) => {
  return (
    <tr className="tw-h-[68px]">
      <InitialCell
        user={user}
        rank={rank}
        selectedActivity={selectedActivity}
        maxActivityCount={maxActivityCount}
        isSelectedUser={isSelectedUser}
        goalDivider={goalDivider}
        isPlaceUser={isPlaceUser}
      />
      {Activities.map(({ key }) => (
        <TableCell
          key={`${user["user_id"]}-${key}`}
          user={user}
          activity={key}
          setSelectedActivity={setSelectedActivity}
          isSelectedActivity={key === selectedActivity}
          isLastRow={isLastRow}
          goalDivider={goalDivider}
          isPlaceUser={isPlaceUser}
          isHoveredColumn={hoveredColumn === key}
          setHoveredColumn={setHoveredColumn}
        />
      ))}
    </tr>
  );
};

const InitialCell = ({
  user,
  rank,
  maxActivityCount,
  selectedActivity,
  isSelectedUser,
  goalDivider,
  isPlaceUser,
}) => {
  const { count, goal } = getActivityData(user, selectedActivity, goalDivider);

  const goalPercent = Math.min(100, (goal / maxActivityCount) * 100);
  const countPercent = Math.min(100, (count / maxActivityCount) * 100);

  const wasGoalSurpassed = goal > 0 && count >= goal;
  const countStyle = wasGoalSurpassed
    ? { width: `${countPercent}%`, background: "linear-gradient(270deg, #E5941B 0%, #FAE4C3 99.14%)" }
    : { width: `${countPercent}%`, backgroundColor: isPlaceUser ? "#3270FA" : "#59C4C4" };

  const currentUserName = isPlaceUser ? "tw-text-semantic-blue-100" : "tw-text-brivity-blue-100";

  return (
    <td
      className={`tw-sticky tw-left-0 tw-whitespace-nowrap ${
        isSelectedUser ? "tw-bg-semantic-blue-2.5" : "tw-bg-white"
      }`}
    >
      <div className="tw-flex tw-items-center tw-pl-24px">
        <span className={`tw-text-14px ${rank ? "tw-text-gray-75" : "tw-text-gray-50"} tw-w-[40px]`}>
          {rank || Dash}
        </span>
        <Avatar
          className="tw-mr-12px"
          size="medium"
          src={user["avatar_url"]}
          alt={`${user["first_name"]} ${user["last_name"]}`}
        />
        <span
          className={`tw-font-bold tw-text-14px ${
            isSelectedUser ? currentUserName : "tw-text-gray-75"
          } tw-w-[168px] tw-overflow-hidden tw-text-ellipsis`}
        >{`${user["first_name"]} ${user["last_name"]}`}</span>
        <div className="tw-w-[333px] tw-mr-32px tw-flex tw-items-center">
          <div className="tw-flex-1 tw-relative tw-overflow-hidden tw-h-8px tw-rounded-[8px] tw-bg-[#efefef]">
            <div
              className="tw-absolute tw-h-full tw-rounded-[8px]"
              style={{ width: `${goalPercent}%`, backgroundColor: isPlaceUser ? "#C8D9FA" : "#DEF3F3" }}
            />
            <div className="tw-absolute tw-h-full tw-rounded-[8px]" style={countStyle} />
            {wasGoalSurpassed && (
              <div
                className="tw-top-[2.5px] tw-w-[5px] tw-h-[5px] tw-bg-semantic-yellow-5 tw-relative"
                style={{
                  left: `${(goal / maxActivityCount) * 100}%`,
                  transform: "rotate(-45deg)",
                  transformOrigin: "0 100%",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </td>
  );
};

const TableCell = ({
  user,
  activity,
  setSelectedActivity,
  isSelectedActivity,
  isLastRow,
  goalDivider,
  isPlaceUser,
  isHoveredColumn,
  setHoveredColumn,
}) => {
  const { count, goal } = getActivityData(user, activity, goalDivider);
  const hoverStyle = getHoverStyle(isHoveredColumn, isPlaceUser);

  const theme = isPlaceUser ? "place-" : "";
  const selectedStyle =
    isSelectedActivity && isLastRow
      ? `${theme}selected-bottom`
      : isSelectedActivity
      ? `${theme}selected-cells`
      : "tw-cursor-pointer";

  return (
    <td
      className={`tw-text-12px tw-text-gray-50 ${selectedStyle} ${hoverStyle}`}
      onMouseEnter={() => setHoveredColumn(activity)}
      onMouseLeave={() => setHoveredColumn("")}
      onClick={() => setSelectedActivity(activity)}
    >
      <div className="tw-flex tw-items-center tw-justify-center">
        <span className="tw-text-14px tw-text-gray-75 tw-mr-4px">{count}</span> / {goal}
      </div>
    </td>
  );
};

const Arrows = ({ isPlaceUser }) => {
  const fillColor = isPlaceUser ? "#3270FA" : "#59c4c4";

  return (
    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        data-cy="sort-upper-arrow"
        d="M7.32714 5.2644L0.672857 5.2644C0.0745311 5.2644 -0.224632 4.48919 0.197551 4.03723L3.52469 0.47542C3.78751 0.194067 4.21249 0.194067 4.47251 0.47542L7.80245 4.03723C8.22463 4.48919 7.92547 5.2644 7.32714 5.2644Z"
        fill={fillColor}
      />
      <path
        data-cy="sort-lower-arrow"
        d="M0.672857 7.2644H7.32714C7.92547 7.2644 8.22463 8.03962 7.80245 8.49158L4.47531 12.0534C4.21249 12.3347 3.78751 12.3347 3.52749 12.0534L0.197551 8.49158C-0.224633 8.03962 0.0745303 7.2644 0.672857 7.2644Z"
        fill={fillColor}
      />
    </svg>
  );
};

const getActivityData = (user, activity, goalDivider = 52) => {
  if (!user[activity]) {
    return { count: 0, goal: 0 };
  }

  const count = user[activity].count ?? 0;
  const goal = user[activity]["annual_goal"] ? Math.ceil(user[activity]["annual_goal"] / goalDivider) : 0;

  return { count, goal };
};

const getHoverStyle = (isHoveredColumn, isPlaceUser) =>
  isHoveredColumn ? (isPlaceUser ? "tw-bg-semantic-blue-2.5" : "tw-bg-brivity-blue-10") : "";
