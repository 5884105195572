import React, { useEffect, useState } from "react";
import TextLink from "@shared/v2/TextLink";
import Modal from "@shared/v2/Modal";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Button from "@shared/v2/Button";
import { HandshakeRegularV6 } from "@shared/v2/Icomoon";

const useTransactions = ({ personId, show }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (!personId || !show) return;
    const abortController = new AbortController();
    caseTransformingAxios
      .get(`/people/${personId}/transactions`, { signal: abortController.signal })
      .then((res) => setTransactions(res.data))
      .catch((err) => {
        console.log(err);
      });
    return () => abortController.abort();
  }, [personId, show]);

  return transactions;
};

const ViewAllTransactionsModal = ({ onClose, person, show }) => {
  const transactions = useTransactions({ show, personId: person.data?.attributes.id });
  return (
    <Modal
      id="view-all-transactions-modal"
      className="person-details-modal"
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[720px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      show={show}
      onHide={onClose}
    >
      <Modal.Header title="Transactions" closeButton />
      <Modal.Body className="tw-overflow-auto">
        {transactions.map(({ address, lastUpdated, link, mls, primaryImage, status, statusClass }, i) => (
          <div
            key={i}
            className="tw-flex tw-flex-wrap tw-justify-between tw-gap-[16px] tw-px-[8px] tw-py-[32px] tw-border-solid tw-border-gray-10 tw-border-t-0 tw-border-x-0"
          >
            <div className="tw-overflow-hidden tw-flex tw-gap-[16px]">
              <div className="tw-flex-none tw-w-[42px] tw-h-[42px] tw-rounded-full tw-border-solid tw-border-[2px] tw-flex tw-justify-center tw-items-center">
                <HandshakeRegularV6 size="xxl" />
              </div>
              <div
                className="[&>img]:tw-h-[80px] [&>img]:tw-w-[120px]"
                dangerouslySetInnerHTML={{ __html: primaryImage }}
              />
              <div className="tw-overflow-hidden tw-flex tw-flex-col tw-gap-[8px]">
                <a
                  href={link}
                  target="_blank"
                  className="tw-text-gray-base tw-font-bold tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden"
                >
                  {address}
                </a>
                <span>MLS {mls}</span>
                <span className={`${statusClass} tw-font-bold`}>{status}</span>
              </div>
            </div>
            <div className="tw-text-12d">{lastUpdated}</div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-justify-end">
        <Button size="medium" onClick={onClose}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewAllTransactionsModal;
