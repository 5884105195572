/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PlusSolidV6 = (props) => (
  <Icon {...props} name="Plus Solid V6">
    <path d="M16 8a1.23 1.23 0 0 1-1.231 1.231H9.231v5.538a1.23 1.23 0 1 1-2.462 0V9.231H1.231a1.23 1.23 0 1 1 0-2.461h5.538V1.232a1.23 1.23 0 1 1 2.462 0V6.77h5.538C15.45 6.77 16 7.32 16 8z" />
  </Icon>
);

export default PlusSolidV6;
