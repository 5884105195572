import React from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";

const OfficeRemovalModal = ({ onRemove, onClose, name }) => (
  <Modal show onHide={onClose} contentSize="small" contentClassName="tw-m-auto">
    <Modal.Header title={`Delete ${name}?`} closeButton className="tw-mb-[23px]" />
    <div className="tw-flex">
      <Button
        onClick={() => {
          onClose();
        }}
        size="medium"
        schema="tertiary"
      >
        CANCEL
      </Button>
      <div className="tw-flex-1" />
      <Button onClick={onRemove} size="medium" schema="warning">
        YES, DELETE
      </Button>
    </div>
  </Modal>
);

OfficeRemovalModal.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default OfficeRemovalModal;
