import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";
import { Info } from "../Icomoon";
import Tooltip from "../Tooltip";

import styleSchema from "./RadioButton.styles";

const textColor = (disabled) => (disabled ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-75");

// Returns true when `text` has more than five words (considering that each word is separated by a space character).
// For example "Lorem Ipsum is simply dummy text.", is considered a multiline text.
const isMultilineText = (text) => text && text.trim().split(" ").length > 5;

const isMultiline = (additionalInfo) => {
  // `additionalInfo.multiline` could be omitted (null/undefined)
  if (typeof additionalInfo.multiline === "boolean") {
    return additionalInfo.multiline;
  }

  return isMultilineText(
    typeof additionalInfo.content === "string" ? additionalInfo.content : additionalInfo.content.textContent,
  );
};

const RadioButton = ({
  id: idFromProps,
  value,
  label,
  labelClassName,
  containerClassName,
  checked,
  disabled,
  additionalInfo,
  className,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("radio-button", idFromProps);

  return (
    <div className={`tw-inline-flex tw-flex-row tw-justify-start tw-items-center ${containerClassName}`}>
      <input
        id={id}
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        className={`${styleSchema.regular} ${className}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
      <label
        htmlFor={id}
        className={`tw-my-0 tw-mr-0 tw-ml-[8px] tw-select-none tw-text-14d tw-cursor-pointer peer-disabled:tw-cursor-not-allowed ${textColor(
          disabled,
        )} ${labelClassName}`}
      >
        {label}
      </label>
      {additionalInfo?.content && (
        <Tooltip
          placement={additionalInfo.placement || "right"}
          multiline={isMultiline(additionalInfo)}
          trigger={
            <div className="tw-inline-flex tw-justify-center tw-items-center tw-w-[22px] tw-h-[20px]">
              <Info size="m" className={textColor(disabled)} />
            </div>
          }
          content={<span>{additionalInfo.content}</span>}
        />
      )}
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalInfo: PropTypes.shape({
    content: PropTypes.node,
    multiline: PropTypes.bool,
    placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

RadioButton.defaultProps = {
  id: null,
  checked: false,
  disabled: false,
  className: "",
  additionalInfo: null,
  labelClassName: "",
  containerClassName: "",
};

export default RadioButton;
