import { useEffect, useState } from "react";
import { defaultState, DEFAULT_INPUT_STATE } from "./constants";

export default (toggleModalAddTaskOpen, uuid, onPostTaskAsThunk, onEditTaskAsThunk, task, taskFilters) => {
  const [payload, setPayload] = useState(defaultState(uuid, task));

  const [enabledAddInputs, setEnabledAddInputs] = useState({
    ...DEFAULT_INPUT_STATE,
  });

  useEffect(() => {
    setPayload(defaultState(uuid, task));
  }, [uuid, task]);

  const resetStates = () => {
    setEnabledAddInputs({
      ...DEFAULT_INPUT_STATE,
    });
    toggleModalAddTaskOpen(false);
  };

  const editHandlr = (completed = {}) => {
    onEditTaskAsThunk(
      {
        ...payload,
        ...completed,
        id: task.id,
      },
      uuid,
      taskFilters,
    );

    resetStates();
  };

  const closeHandlr = (postTask) => {
    if (postTask) {
      onPostTaskAsThunk(
        {
          ...payload,
          ...postTask,
        },
        taskFilters,
      );
    }

    resetStates();
  };

  return {
    payload,
    setPayload,
    editHandlr,
    enabledAddInputs,
    setEnabledAddInputs,
    closeHandlr,
  };
};
