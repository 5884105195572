import React, { useContext } from "react";
import PropTypes from "prop-types";

import { PanelContext } from "./PanelContext";
import CLASSES from "./classes.json";

const { PANEL_TRIGGER_CLASSES } = CLASSES;

const PanelTrigger = ({ className, id, children, ...props }) => {
  const { collapsed, toggleCollapsed } = useContext(PanelContext);

  const classes = `
    ${PANEL_TRIGGER_CLASSES}
    ${className}
  `;

  return (
    <a
      href={id && `#${id}`}
      className={classes}
      aria-controls={id}
      aria-expanded={!collapsed}
      data-js-collapsed={collapsed}
      role="button"
      onClick={toggleCollapsed}
      {...props}
    >
      {children}
    </a>
  );
};

PanelTrigger.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
};

PanelTrigger.defaultProps = {
  className: "",
  id: "",
  children: null,
};

export default PanelTrigger;
