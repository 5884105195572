import React from "react";
import PropTypes from "prop-types";

import ActivitiesDropdown from "../../../Reports/components/Leaderboards/ActivitiesDropdown";
import { LeadAssignInfo } from "./shared";

const LeadAssignTypeOptions = Object.entries(LeadAssignInfo).map(([key, value]) => ({
  value: key,
  label: value.pretty,
}));

const LeadAssignType = ({ assignType, setAssignType }) => {
  const assignTypeOption = LeadAssignTypeOptions.find(({ value }) => value === assignType);

  return (
    <div className="tw-flex tw-flex-col tw-bg-gray-5 tw-rounded-8px tw-p-16px tw-gap-16px">
      <div className="tw-flex tw-flex-row tw-gap-8px">
        <span>Route calls via</span>
        <ActivitiesDropdown
          activity={assignTypeOption}
          onActivityChosen={setAssignType}
          options={LeadAssignTypeOptions}
        />
      </div>
      <div className="tw-text-12px tw-text-gray-50">{LeadAssignInfo[assignType].description}</div>
    </div>
  );
};

LeadAssignType.propTypes = {
  assignType: PropTypes.string.isRequired,
  setAssignType: PropTypes.func.isRequired,
};

export default LeadAssignType;
