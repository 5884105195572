import React from "react";
import { ToolTipContent, StatCard } from "./appointmentsReportHelper";
import { ArrowRightLongSolidV6 } from "@shared/v2/Icomoon";
import Tooltip from "@shared/v2/Tooltip";

const AppointmentsStatCards = ({ leadStats }) => {
  const stats = leadStats.slice(0, 7);
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-p-[16px] tw-gap-[8px] tw-bg-white tw-overflow-auto">
      {stats.map((stat, i, a) => (
        <React.Fragment key={stat.label}>
          <Tooltip
            multiline
            placement="right"
            trigger={<StatCard data={stat} />}
            content={<ToolTipContent stat={stat} leadStats={leadStats} />}
          />
          {i !== a.length - 1 && <ArrowRightLongSolidV6 size="l" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AppointmentsStatCards;
