/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import moment from "moment";
import { InlineEditIcon } from "../icons";
import DatePicker from "./DatePicker";
import Switcher from "./Switcher";

const format = (dateValue) => moment(dateValue).format("MM-DD-YYYY");

const DateCell = ({ value, isEditable, onChange, justify }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const iconType = <InlineEditIcon isEditable={isEditable} />;

  const blankNonEditableDisplay = <span>—</span>;
  const blankEditableDisplay = (
    <span data-cy="editable-blank-date-cell" className="tw-text-teal">
      Add
    </span>
  );
  const nonEditableDisplay = <span>{format(currentValue)}</span>;
  const editableDisplay = (
    <div data-cy="editable-display-date-cell">
      <span className="tw-mr-8px">{format(currentValue)}</span>
      {iconType}
    </div>
  );
  const isEmpty = !currentValue || currentValue.length === 0;

  const maybeToggleToInput = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const updateIfChanged = (newValue) => {
    if (newValue !== value) onChange(newValue);
  };

  const closeAndUpdateIfChanged = (momentValue) => {
    const ISOValue = momentValue.format("YYYY-MM-DD");
    updateIfChanged(ISOValue);
    setIsEditing(false);
  };

  const input = (
    <DatePicker
      onChange={(e) => {
        if (typeof e === "string") {
          return;
        }
        closeAndUpdateIfChanged(e);
      }}
      onBlur={() => setIsEditing(false)}
      timeFormat={false}
      showCalendar
      value={value}
    />
  );

  let content = null;
  if (!isEditable && !isEmpty) {
    content = nonEditableDisplay;
  } else if (!isEditable && isEmpty) {
    content = blankNonEditableDisplay;
  } else if (isEditable && isEditing) {
    content = input;
  } else if (isEditable && !isEditing && isEmpty) {
    content = blankEditableDisplay;
  } else if (isEditable && !isEditing && !isEmpty) {
    content = editableDisplay;
  }

  return (
    <Switcher justify={justify} onClick={maybeToggleToInput} isActionable={isEditable && !isEditing}>
      {content}
    </Switcher>
  );
};

export default DateCell;
