import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ checked, labelText, ...props }) => {
  const fontAwesomeCheckboxIcon = checked ? "\uF14A" : "\uF096";

  return (
    <label className="tw-text-12px tw-pl-30px tw-mb-0 tw-cursor-pointer tw-text-gray-75">
      <div className="tw-inline-block tw-w-1px">
        <span
          className="tw-ml-[-27px] tw-pr-[13px] tw-text-teal"
          style={{
            font: "normal normal normal 17px/1 FontAwesome",
            textRendering: "auto",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
          }}
        >
          {fontAwesomeCheckboxIcon}
        </span>
      </div>

      <input type="checkbox" className="tw-hidden" checked={checked} {...props} />

      {labelText}
    </label>
  );
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
};
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
