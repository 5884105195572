import React from "react";
import moment from "moment";
import { shape } from "prop-types";

const TimeUploaded = ({ document }) => (
  <td>
    <div className="tw-text-neutral-gray-75 tw-text-14d" data-cy="document-list-item-date-created-at">
      {moment(document.uploadedAt).format("MM/DD/YYYY")}
    </div>
    <div className="tw-text-neutral-gray-50 tw-text-12d" data-cy="document-list-item-time-created-at">
      {moment(document.uploadedAt).format("hh:mm A")}
    </div>
  </td>
);

TimeUploaded.propTypes = {
  document: shape({}),
};

TimeUploaded.defaultProps = {
  document: {},
};

export default TimeUploaded;
