import Button from "@shared/v2/Button";
import React, { useMemo } from "react";
import { useDispatch, Provider } from "react-redux";
import { setLeadUuids } from "../reducers/dialerReducer/dialerReducer";

const getLeadUuids = () =>
  Array.from(
    document.querySelectorAll("table.leads-table input:checked,table.all-people-table input:checked"),
  ).map((node) => node.closest("tr").dataset.personUuid);

const AutoDialerButton = () => {
  const dispatch = useDispatch();
  return (
    <Button size="medium" onClick={() => dispatch(setLeadUuids(getLeadUuids()))}>
      Call
    </Button>
  );
};

const AutoDialerButtonApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);

  return (
    <Provider store={store}>
      <AutoDialerButton />
    </Provider>
  );
};

export default AutoDialerButtonApp;
