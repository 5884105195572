import InlineEditingInput from "./InlineEditingInput";
import Flat from "./Flat/Flat";
import FlatPercent from "./FlatPercent/FlatPercent";
import Percent from "./Percent/Percent";

// Export sub-components
InlineEditingInput.Flat = Flat;
InlineEditingInput.FlatPercent = FlatPercent;
InlineEditingInput.Percent = Percent;

export default InlineEditingInput;
