/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PaperclipSolidV6 = (props) => (
  <Icon {...props} name="Paperclip Solid V6">
    <path d="M12.381 2.619c-.763-.763-2-.763-2.763 0l-5.75 5.75a3.368 3.368 0 0 0 4.763 4.763l4.75-4.75a.876.876 0 0 1 1.237 1.237l-4.75 4.75a5.118 5.118 0 0 1-7.237-7.237l5.75-5.75a3.703 3.703 0 0 1 5.237 5.237l-5.5 5.5a2.289 2.289 0 1 1-3.237-3.237l4.5-4.5a.876.876 0 0 1 1.237 1.237l-4.5 4.5c-.209.209-.209.553 0 .763s.553.209.763 0l5.5-5.5c.762-.763.762-2 0-2.763z" />
  </Icon>
);

export default PaperclipSolidV6;
