import React, { useEffect, useState } from "react";

import TeamLeaderboard from "./TeamLeaderboard";
import GlobalLeaderboard from "./GlobalLeaderboard";
import TeamTable from "./TeamTable";
import Header from "./Header";
import BigQueryWarning from "./BigQueryWarning";
import { CurrentYear, makeDateRangeFromPhrase } from "./helpers";
import useFullscreen from "../BusinessDashboard/hooks/useFullscreen";
import useStateWithPersistence from "./hooks/useStateWithPersistence";
import useLeaderboard from "./hooks/useLeaderboard";

const Leaderboards = ({ currentUserId, isPlaceUser }) => {
  const [selectedUserId, setSelectedUserId] = useState(currentUserId);
  const [selectedActivity, setSelectedActivity] = useStateWithPersistence("activity", "outbound_calls");
  const [year, setYear] = useStateWithPersistence("year", CurrentYear);
  const [timeFrame, setTimeFrame] = useStateWithPersistence("timeFrame", "this-month");
  const [timeFrameDescription, setTimeFrameDescription] = useStateWithPersistence(
    "timeFrameDescription",
    "This Month",
  );
  const [customMinDate, setCustomMinDate] = useStateWithPersistence("customMinDate", new Date());
  const [customMaxDate, setCustomMaxDate] = useStateWithPersistence("customMaxDate", new Date());
  const { ref, toggleFullscreen } = useFullscreen();

  const updateTimeFrameInfo = (key, description) => {
    setTimeFrame(key);
    setTimeFrameDescription(description);
  };

  const {
    data: { goalDivisor, largeQuery, maxCount, ranks, users },
    loading,
    rankLoading,
  } = useLeaderboard({
    activity: selectedActivity,
    userId: selectedUserId,
    from: customMinDate,
    to: customMaxDate,
  });

  useEffect(() => {
    if (timeFrame === "custom") return;
    const { from, to } = makeDateRangeFromPhrase(year, timeFrame);
    setCustomMinDate(from);
    setCustomMaxDate(to);
  }, [timeFrame, year]);

  const abandonCustomQuery = () => {
    // Reset to default - "this month"
    setYear(CurrentYear);
    setTimeFrame("this-month");
  };

  return (
    <div ref={ref} className="leaderboards tw-bg-gray-5 tw-flex tw-p-[16px]">
      <div className="tw-w-full tw-max-w-[1336px] tw-mx-auto tw-flex tw-flex-col tw-gap-[16px]">
        {largeQuery === true && loading === true ? (
          <BigQueryWarning
            customMinDate={customMinDate}
            customMaxDate={customMaxDate}
            abandonCustomQuery={abandonCustomQuery}
          />
        ) : (
          ""
        )}
        <Header
          year={year}
          setYear={setYear}
          timeFrame={timeFrame}
          setTimeFrame={updateTimeFrameInfo}
          customMinDate={customMinDate}
          customMaxDate={customMaxDate}
          setCustomMinDate={setCustomMinDate}
          setCustomMaxDate={setCustomMaxDate}
          setFullscreen={toggleFullscreen}
        />
        <div className="tw-flex tw-items-stretch tw-flex-wrap tw-gap-[16px]">
          <GlobalLeaderboard
            wasActivityInPeriod={wasActivityInPeriod(ranks)}
            selectedActivity={selectedActivity}
            ranks={ranks}
            isPlaceUser={isPlaceUser}
            isLoading={loading || rankLoading}
            users={users} // Consider a separate state piece as this won't need to change on sort
            setSelectedUser={setSelectedUserId}
            selectedUser={selectedUserId}
          />
          <TeamLeaderboard
            users={users}
            selectedActivity={selectedActivity}
            isPlaceUser={isPlaceUser}
            isLoading={loading}
          />
        </div>
        <TeamTable
          users={users}
          maxCount={maxCount}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          goalDivider={goalDivisor}
          selectedUser={selectedUserId}
          isPlaceUser={isPlaceUser}
          isLoading={loading}
          timeFrame={timeFrameDescription}
        />
      </div>
    </div>
  );
};

export default Leaderboards;

const wasActivityInPeriod = (ranks) => {
  return ranks.teamRank !== null && ranks.teamRank !== undefined;
};
