import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../actions/creators";

import Dropdown from "../../shared/v2/Dropdown";

const CustomOption = ({ componentProps, data, children }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Dropdown.Option {...componentProps} preserveStyle className="tw-flex tw-overflow-hidden">
    <img src={data?.avatar} alt="Avatar" className="tw-h-[26px] tw-w-[26px] tw-mr-[12px]" />{" "}
    <span className="tw-flex-1">{children}</span>
  </Dropdown.Option>
);

CustomOption.propTypes = {
  componentProps: Proptypes.shape({ isSelected: Proptypes.bool, isDisabled: Proptypes.bool }),
  data: Proptypes.shape({ value: Proptypes.string, avatar: Proptypes.string }),
  children: Proptypes.arrayOf(Proptypes.shape({})),
};

CustomOption.defaultProps = {
  componentProps: null,
  data: null,
  children: null,
};

const mapStateToProps = (state) => ({
  availablePonds: state.leadRouting.availablePonds.filter(
    (a) => state.leadRouting.unavailablePonds?.indexOf(a.value) < 0,
  ),
  selectedPond: state.leadRouting.selectedPond,
});

const PondSelector = ({ selectPond, selectedPond, availablePonds }) => (
  <Dropdown
    placeholder="Select Pond"
    value={selectedPond}
    onChange={selectPond}
    isSearchable
    options={availablePonds}
    components={{ Option: CustomOption }}
  />
);

export default connect(mapStateToProps, actions)(PondSelector);

PondSelector.propTypes = {
  selectPond: Proptypes.func,
  selectedPond: Proptypes.arrayOf(Proptypes.shape({})),
  availablePonds: Proptypes.arrayOf(Proptypes.shape({})),
};

PondSelector.defaultProps = {
  selectPond: null,
  selectedPond: null,
  availablePonds: null,
};
