import React from "react";
import Proptypes from "prop-types";
import Pluralize from "react-pluralize";

const Name = ({ person }) => {
  const isNoName = !person.full_name?.trim();
  const TagName = person.fetch_results_count > 0 ? "a" : "span";

  return (
    <div className="tw-flex tw-flex-col">
      <a
        className={`hover:tw-underline ${isNoName ? " !tw-text-gray-dark" : ""}`}
        href={person.person_path_with_query_param}
      >
        {person.name_or_no_name}
      </a>
      {isNoName && person.lead_last_search_at && (
        <TagName
          href={TagName === "a" ? "#" : undefined}
          className={person.fetch_results_count > 0 ? "has-matches" : "no-matches"}
        >
          <Pluralize singular="match" plural="matches" zero="No matches" count={person.fetch_results_count} />
          &nbsp;found
        </TagName>
      )}
      {isNoName && !person.lead_last_search_at && (
        <a href="/#" className="find-matches">
          Search for details
        </a>
      )}
    </div>
  );
};

Name.propTypes = {
  person: Proptypes.shape({
    full_name: Proptypes.string,
    person_path_with_query_param: Proptypes.string,
    name_or_no_name: Proptypes.string,
    fetch_results_count: Proptypes.number,
    lead_last_search_at: Proptypes.string,
  }).isRequired,
};

export default Name;
