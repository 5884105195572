import React from "react";
import getContactName from "./getContactName";
import Skeleton from "./Skeleton";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";

const TimelineCallHardResubscribeEvent = ({ event }) => {
  const timelineFields = event.timeline_fields;
  const { lead, change, lead_phone_number } = timelineFields;
  const phone = viewPhoneFromFlat({ value: lead_phone_number });
  const leadName = getContactName(lead.first_name, lead.last_name);
  const initialState = change.named_status[0];
  const finalState = change.named_status[1];

  return (
    <Skeleton
      iconClassName="fa6 fa6-user"
      title={
        <>
          <span className="tw-font-bold">{leadName}</span> has opted into phone contact.
        </>
      }
      timestamp={event.interacted_at_absolute}
      body={
        <p>
          {phone.formattedNumber} changed <span className="tw-font-bold">Call Status</span> from{" "}
          <span className="tw-font-bold">{initialState}</span> to{" "}
          <span className="tw-font-bold">{finalState}</span>
        </p>
      }
    />
  );
};

export default TimelineCallHardResubscribeEvent;
