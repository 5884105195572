import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Button from "../../shared/v2/Button";
import TextInput from "../../shared/v2/TextInput/TextInput";
import Dropdown from "../../shared/v2/Dropdown/Dropdown";

const SectionHeader = ({ title, topMargin = true }) => (
  <div className={`col-xs-12 tw-mb-24px ${topMargin ? "tw-mt-60px" : ""}`}>
    <div className="tw-text-18d tw-text-neutral-gray-75 tw-font-normal tw-border-0 tw-border-b-2px tw-border-solid tw-border-neutral-gray-30 tw-pb-6px">
      {title}
    </div>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  topMargin: PropTypes.bool,
};

SectionHeader.defaultProps = {
  topMargin: true,
};

class TwilioTrustHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        business_name: props.trustHub.business_name,
        address_street: props.trustHub.address_street,
        address_street_secondary: props.trustHub.address_street_secondary,
        address_city: props.trustHub.address_city,
        address_region: props.trustHub.address_region,
        address_postal_code: props.trustHub.address_postal_code,
        address_country: props.trustHub.address_country || "US",
        business_type: props.trustHub.business_type,
        website_url: props.trustHub.website_url || props.trustHub.default_website_url,
        auth_rep_first_name: props.trustHub.auth_rep_first_name,
        auth_rep_last_name: props.trustHub.auth_rep_last_name,
        auth_rep_title: props.trustHub.auth_rep_title,
        auth_rep_email: props.trustHub.auth_rep_email,
        auth_rep_phone: props.trustHub.auth_rep_phone,
        ein: props.trustHub.ein,
        sole_prop_phone_number: props.trustHub.sole_prop_phone_number,
        required: props.trustHub.required,
      },
      registrationComplete: props.trustHub.registration_complete,
      saving: false,
      errors: [],
      success: false,
    };
  }

  updateState = (k, v) => {
    const { form } = this.state;
    if (k === "address_country") {
      form.required = v === "US";
    }
    this.setState((prevState) => ({ ...prevState, form: { ...form, [k]: v } }));
  };

  setEin = (ein) => {
    const { form } = this.state;
    const formattedEin = ein.replace(/\s/g, "");
    this.setState((prevState) => ({ ...prevState, form: { ...form, ein: formattedEin } }));
  };

  saveChanges = (submitOrSave = "save") => {
    // We avoid destructuring to produce a copy of the object, as it will be mutated
    // eslint-disable-next-line react/destructuring-assignment
    const form = { ...this.state.form };
    const { accountId } = this.props;
    const allowEin = this.allowEin();

    // Remove value if not allowed
    if (!allowEin) {
      form.ein = null;
    } else {
      form.sole_prop_phone_number = null;
      form.address_street_secondary = null;
    }

    // Adjust required
    form.required = this.forUS();

    const path = submitOrSave === "save" ? "/twilio_trust_hub" : "/twilio_trust_hub/submit";

    this.setState({ saving: true, submitOrSave }, () => {
      axios
        .post(path, {
          account_id: accountId,
          twilio_trust_hub: form,
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((resp) => {
          this.setState({
            saving: false,
            submitOrSave: null,
            errors: [],
            registrationComplete: resp.data.registration_complete,
            success: true,
          });
        })
        .catch((err) => {
          this.setState({ saving: false, errors: err.response.data.errors });
        });
    });
  };

  submitForReview = () => {
    const { form } = this.state;
    const { accountId } = this.props;
    this.setState({ saving: true }, () => {
      axios
        .post("/twilio_trust_hub/submit", {
          account_id: accountId,
          twilio_trust_hub: form,
          authenticity_token: ReactOnRails.authenticityToken(),
        })
        .then((resp) => {
          this.setState({
            saving: false,
            errors: [],
            registrationComplete: resp.data.registration_complete,
            success: true,
          });
        })
        .catch((err) => {
          this.setState({ saving: false, errors: err.response.data.errors });
        });
    });
  };

  // We add this here just to encapsulate the available data
  // eslint-disable-next-line class-methods-use-this
  countryOptions() {
    return [
      { value: null, label: "Select a country" },
      { value: "US", label: "United States" },
      { value: "Other", label: "Other" },
    ];
  }

  allowedBusinessTypes() {
    const { allowedBusinessTypes } = this.props;
    return [
      { value: "", label: "Select your business type" },
      ...allowedBusinessTypes.map((type) => ({ value: type, label: type })),
    ];
  }

  allowEin() {
    // This is hardcoded based on the backend value. If that name changes, this has to change too.
    const { form } = this.state;
    return (
      form.business_type !== null && form.business_type !== "" && form.business_type !== "Sole Proprietorship"
    );
  }

  forUS() {
    const { form } = this.state;
    return form.address_country === "US";
  }

  renderShortForm = () => {
    const { form, saving, submitOrSave, success, errors } = this.state;
    const countryOptions = this.countryOptions();

    return (
      <>
        {errors.map((e) => (
          <div className="col-xs-12">
            <div className="alert alert-danger">
              <i className="fa fa-times" /> Error: {e}
            </div>
          </div>
        ))}

        {success && (
          <div className="col-xs-12">
            <div className="alert alert-success">
              <i className="fa fa-check" /> Changes Saved Successfully
            </div>
          </div>
        )}
        <div className="col-xs-12">
          <div className="form-group">
            <Dropdown
              id="country"
              label="Country"
              placeholder="Select a country"
              value={countryOptions.find((option) => option.value === form.address_country)}
              onChange={(option) => this.updateState("address_country", option.value)}
              options={countryOptions}
            />
            {form.address_country === "Other" && (
              <div className="tw-text-12d tw-text-neutral-gray-50 tw-mt-4px">
                This is the only information required for non-US-based accounts. If you have any questions,
                please reach out to support if you need to change it.
              </div>
            )}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="tw-mt-24px tw-flex tw-space-x-12px">
            <Button
              size="medium"
              schema="primary"
              disabled={saving}
              onClick={() => this.saveChanges("submit")}
            >
              {saving && submitOrSave === "submit" ? (
                <span>
                  Submitting&nbsp;
                  <i className="fa fa-pulse fa-spinner" />
                </span>
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </div>
        </div>
      </>
    );
  };

  renderForm = () => {
    const { form, saving, submitOrSave, success, errors } = this.state;

    const allowedBusinessTypes = this.allowedBusinessTypes();
    const countryOptions = this.countryOptions();
    const displayEin = this.allowEin();
    const displayOtpPhone = !displayEin;
    const displayAddressSecondLine = displayOtpPhone;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <Dropdown
                id="country"
                label="Country"
                placeholder="Select a country"
                value={countryOptions.find((option) => option.value === form.address_country)}
                onChange={(option) => this.updateState("address_country", option.value)}
                options={countryOptions}
              />
              {form.address_country === "Other" && (
                <div className="tw-text-12d tw-text-neutral-gray-50 tw-mt-4px">
                  This is the only information required for non-US-based accounts. If you have any questions,
                  please reach out to support if you need to change it.
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <TextInput
                label="Legal Business Name (Not your DBA)"
                value={form.business_name}
                onChange={(e) => this.updateState("business_name", e.target.value)}
                helperText="Enter the exact legal business name of your business as registered with the government. For Sole Proprietor businesses, your business name is usually your first and last name."
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className={displayEin ? "col-xs-12" : "col-xs-6"}>
            <div className="form-group">
              <TextInput
                label="Registered Street Address"
                helperText="May be different than your office address"
                value={form.address_street}
                onChange={(e) => this.updateState("address_street", e.target.value)}
              />
            </div>
          </div>

          {displayAddressSecondLine && (
            <div className="col-xs-6">
              <div className="form-group">
                <TextInput
                  label="Apt, Suite, Unit, Building (Optional)"
                  value={form.address_street_secondary}
                  onChange={(e) => this.updateState("address_street_secondary", e.target.value)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="City"
                value={form.address_city}
                onChange={(e) => this.updateState("address_city", e.target.value)}
              />
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="State/Province/Region"
                value={form.address_region}
                onChange={(e) => this.updateState("address_region", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="Postal Code"
                value={form.address_postal_code}
                onChange={(e) => this.updateState("address_postal_code", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div className="form-group">
              <TextInput
                label="Brivity Website"
                value={form.website_url}
                onChange={(e) => this.updateState("website_url", e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <SectionHeader title="Business Type" />

          <div className="col-xs-12 tw-mb-24px">
            <div className="tw-p-32px tw-bg-brivity-blue-10">
              <p className="tw-text-12d tw-font-bold tw-text-brivity-blue-dark">Notice for US customers</p>
              <p className="tw-text-12d">
                US customers <span className="tw-font-bold">who have a Tax ID</span> need to register their
                business to send text messages.
              </p>
              <p className="tw-text-12d">
                US customers <span className="tw-font-bold">who do not have a Tax ID</span> need to register
                as a Sole Proprietor brand.
              </p>
            </div>
          </div>

          <div className="col-xs-12">
            <p className="tw-text-12d tw-mb-24px">
              You cannot register multiple Brivity numbers under a sole proprietorship - only the Account
              Owner number will be registered. For teams, select another business type.
            </p>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <Dropdown
                id="business_type"
                label="Business Type"
                placeholder="Select your business type"
                value={allowedBusinessTypes.find((option) => option.value === form.business_type)}
                onChange={(option) => this.updateState("business_type", option.value)}
                options={allowedBusinessTypes}
              />
            </div>
          </div>

          {displayEin && (
            <div className="col-xs-6">
              <div className="d-flex flex-column flex-grow">
                <div className="form-group">
                  <TextInput
                    label="EIN (Tax ID)"
                    value={form.ein}
                    onChange={(e) => this.setEin(e.target.value)}
                    helperText="Must be a 9-digit EIN number e.g. 123456789 or 12-3456789. Do not enter a Social Security Number."
                  />
                </div>
              </div>
            </div>
          )}

          <SectionHeader title="Brivity Account Owner / Point of Contact" />

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="First Name"
                value={form.auth_rep_first_name}
                onChange={(e) => this.updateState("auth_rep_first_name", e.target.value)}
              />
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="Last Name"
                value={form.auth_rep_last_name}
                onChange={(e) => this.updateState("auth_rep_last_name", e.target.value)}
              />
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="Title"
                value={form.auth_rep_title}
                onChange={(e) => this.updateState("auth_rep_title", e.target.value)}
              />
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="Email Address"
                value={form.auth_rep_email}
                onChange={(e) => this.updateState("auth_rep_email", e.target.value)}
              />
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-group">
              <TextInput
                label="Business / Contact Phone Number"
                helperText="Not your Brivity Number"
                value={form.auth_rep_phone}
                onChange={(e) => this.updateState("auth_rep_phone", e.target.value)}
              />
            </div>
          </div>

          {displayOtpPhone && (
            <>
              <SectionHeader title="Mobile Phone Number for Account Verification" />

              <div className="col-xs-12">
                <p className="tw-text-12d">
                  This mobile number is critical in the registration process as it is used for sending an SMS
                  verification request to you, which you much respond to. The OTP number you&apos;ll receive a
                  message from is (915) 278-2000.
                </p>
                <ul className="tw-text-12d tw-mb-24px">
                  <li>This must be a valid US or Canadian mobile number only where you can be reached.</li>
                  <li>
                    This cannot be a number you&apos;ve acquired from a CPaaS or VoIP provider such as Twilio,
                    Google Voice, etc.
                  </li>
                  <li>This cannot be your Brivity Number.</li>
                </ul>
              </div>

              <div className="col-xs-6">
                <div className="form-group">
                  <TextInput
                    label="Mobile Number for Verification"
                    helperText="Not your Brivity Number"
                    value={form.sole_prop_phone_number}
                    onChange={(e) => this.updateState("sole_prop_phone_number", e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {errors.map((e) => (
            <div className="col-xs-12">
              <div className="alert alert-danger">
                <i className="fa fa-times" /> Error: {e}
              </div>
            </div>
          ))}

          {success && (
            <div className="col-xs-12">
              <div className="alert alert-success">
                <i className="fa fa-check" /> Changes Saved Successfully
              </div>
            </div>
          )}

          <div className="col-xs-12">
            <div className="tw-mt-24px tw-flex tw-space-x-12px">
              <Button
                size="medium"
                schema="secondary"
                disabled={saving}
                onClick={() => this.saveChanges("save")}
              >
                {saving && submitOrSave === "save" ? (
                  <span>
                    Saving Progress&nbsp;
                    <i className="fa fa-pulse fa-spinner" />
                  </span>
                ) : (
                  <span>SAVE PROGRESS</span>
                )}
              </Button>

              <Button
                size="medium"
                schema="primary"
                disabled={saving}
                onClick={() => this.saveChanges("submit")}
              >
                {saving && submitOrSave === "submit" ? (
                  <span>
                    Submitting for review&nbsp;
                    <i className="fa fa-pulse fa-spinner" />
                  </span>
                ) : (
                  <span>SUBMIT FOR REVIEW</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { registrationComplete, form } = this.state;
    const { required } = form;

    const formUI = required && this.forUS() ? this.renderForm : this.renderShortForm;

    return (
      <div className="row">
        <div className="col-xs-5 col-xs-offset-3 card-rounded">
          <div className="row">
            <SectionHeader title="Texting Registration" topMargin={false} />
            <div className="col-xs-12">
              <p>
                Mobile carriers now require customers texting through an application to register a business
                license with their account. The goal is to reduce spam texting, boost your deliverability, and
                prevent text messages from being flagged as spam. Only one person (Account Owner or
                Administrator) per account is required to fill out this form, so if you do not know the
                Business License information for your business, consult the Business Owner to fill out this
                form instead. Learn more about the new A2P 10DLC texting registration requirements{" "}
                <a
                  href="https://www.brivityknowledge.com/docs/brivity-number-registration-1"
                  style={{ color: "#59C4C4" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </p>
            </div>
          </div>
          {registrationComplete && (
            <div className="alert alert-info">
              <div className="tw-flex">
                <div className="tw-flex tw-flex-col tw-justify-center tw-pr-10px">
                  <i className="fa fa-check" />
                </div>
                <div>
                  Your Texting Registration Profile has been successfully saved. Should you need to update
                  this information, please contact Brivity Support.
                </div>
              </div>
            </div>
          )}
          {!registrationComplete && formUI()}
        </div>
      </div>
    );
  }
}

TwilioTrustHub.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  trustHub: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accountId: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allowedBusinessTypes: PropTypes.any.isRequired,
};

export default TwilioTrustHub;
