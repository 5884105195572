import React, { Component } from "react";
import Moment from "react-moment";
import CheckBox from "../../../shared/CheckBox";
import axios from "axios";
import tasks from "./tasks.module.css";
import Tooltip from "@shared/v2/Tooltip";

class CardTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: props.task,
      image: undefined,
    };
  }

  componentDidMount() {
    this.getAgentImage();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.task.assigned_to_user_id != this.props.task.assigned_to_user_id) {
      this.getAgentImage();
    }
  }

  getAgentImage = () => {
    axios
      .get(`/profile_icon?id=${this.props.task.assigned_to_user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({
          image: { link: response.data.link, name: response.data.name, initials: response.data.initials },
        });
      })
      .catch((response) => {
        console.log("no assigned");
      });
  };

  displayAgentImage = () => {
    if (this.props.task.assigned_to_user_id) {
      const { image } = this.state;

      return (
        <div>
          {image.link && (
            <Tooltip
              placement="left"
              trigger={<img className="tw-w-[27px] tw-h-[27px] profile-icon" src={image.link} />}
              content={image.name}
            />
          )}
          {image.initials && (
            <Tooltip
              placement="left"
              trigger={<span className="circled user-image default-avatar">{image.initials}</span>}
              content={image.name}
            />
          )}
        </div>
      );
    }
    return (
      <Tooltip
        placement="left"
        trigger={<img src="/images/users/team.svg" className="tw-w-[27px] tw-h-[27px]" />}
        content="Anyone"
      />
    );
  };

  taskClass = (task) => {
    switch (task.dueness) {
      case "overdue":
        return "text-danger";
      case "due_today":
        return "text-warning";
      case "upcoming":
        return "text-info";
    }
  };

  handleTaskChange = (e) => {
    const { task } = this.state;
    const key = e.target.name;

    task["state"] = "completed";
    this.setState({ task });

    if (key == "complete" && task["state"] == "completed" && task["type"] !== "Appointment") {
      this.props.completeTask(task);
    }
    if (key == "complete" && task["state"] == "completed" && task["type"] === "Appointment") {
      this.props.editTask(task);
    }
  };

  isChecked = () => (this.state.task.state === "completed" ? "checked" : "unchecked");

  render() {
    const task = this.props.task;

    return (
      <div className="tw-shadow-tdp-card tw-rounded-[6px] tw-px-[8px] tw-py-[4px] tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center">
          <CheckBox
            divClass={task.dueness + " fancy"}
            labelClass={`${this.isChecked()} incomplete`}
            inputClass={"checkbox-input"}
            inputChecked={task.state == "completed"}
            objectToCheck={task}
            inputName={"complete"}
            onUpdate={this.handleTaskChange}
          />
          <div>
            {task.assigned_plan_id && (
              <span>
                <i className={`${tasks.assignedPlanApplied} fa fa-bolt m-r-5`} />
              </span>
            )}
            <div className={tasks.name}>
              <a onClick={() => this.props.showTaskDetails(task)} className="no-underline">
                {" "}
                {task.name}{" "}
              </a>
            </div>
            <div className={`${tasks.type} ${this.taskClass(task)}`}>
              {task.type} -{" "}
              {task.calculated_due_on ? <Moment format="MM/DD/YYYY" date={task.calculated_due_on} /> : "TBD"}
            </div>
            <div className="fade-out" />
          </div>
        </div>
        {this.state.image && this.displayAgentImage()}
      </div>
    );
  }
}

export default CardTask;
