import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import GmailMessage from './GmailMessage'

export default class GmailThread extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      activeMessageId: props.messages[0].id
    }
  }

  toggleMessage = (messageId) => {
    this.setState({activeMessageId: messageId})
  }

  render() {
    const { messages } = this.props
    const { activeMessageId } = this.state

    return (
      <ul>
        {
          messages.map(message => {
            return (
              <GmailMessage key={message.id} message={message} activeMessageId={activeMessageId} toggleMessage={this.toggleMessage} />
            )
          })
        }
      </ul>
    )
  }
}
