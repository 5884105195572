import React, { useState, useEffect } from "react";

const ListingImages = ({ images, size, imageToMoveTo, scrollToImage }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [mouseInArea, setMouseInArea] = useState(false);

  const cardValues = SizeValues[size || "medium"];
  const imgUrl = size === "large" ? images[imageIndex].largeUrl : images[imageIndex].url;
  const lastImage = images.length - 1;

  const nextImage = (e) => {
    e.stopPropagation();
    const nextIndex = imageIndex === lastImage ? 0 : imageIndex + 1;

    setImageIndex(nextIndex);
  };

  const previousImage = (e) => {
    e.stopPropagation();
    const previousIndex = imageIndex === 0 ? lastImage : imageIndex - 1;

    setImageIndex(previousIndex);
  };

  useEffect(() => {
    if (scrollToImage && imageToMoveTo !== imageIndex) {
      setImageIndex(imageToMoveTo);
    }
  }, [imageToMoveTo]);

  useEffect(() => {
    if (scrollToImage && imageIndex !== imageToMoveTo) {
      scrollToImage(imageIndex);
    }
  }, [imageIndex]);

  return (
    <div
      className={`tw-flex tw-w-full`}
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
    >
      <div className="tw-flex tw-flex-col tw-flex-none tw-relative">
        <ArrowButton
          cardValues={cardValues}
          direction={"left"}
          mouseInArea={mouseInArea}
          onClick={previousImage}
        />
        <Loading key={imgUrl} url={imgUrl} height={cardValues.imgHeight} width={cardValues.imgWidth} />
        <ArrowButton
          cardValues={cardValues}
          direction={"right"}
          mouseInArea={mouseInArea}
          onClick={nextImage}
        />
        <div style={{ transform: "translateX(-50%)" }} className="tw-absolute tw-bottom-3 tw-left-1/2">
          <span className="tw-text-white tw-text-12px">{`${imageIndex + 1} / ${images.length}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ListingImages;

const ArrowButton = ({ cardValues, direction, mouseInArea, onClick }) => {
  const xSpacing = direction === "right" ? "tw-right-5" : "tw-left-5";
  const icon = direction === "right" ? "fa-chevron-right" : "fa-chevron-left";
  const bubbleStyle = { fontSize: `${cardValues.changeIconSize}px` };

  return (
    <div
      style={{ transform: "translateY(-50%)" }}
      className={`tw-absolute ${cardValues.changeBubbleSize} ${xSpacing} tw-top-1/2 ${
        mouseInArea ? "" : "tw-hidden"
      }`}
    >
      <div
        className={`tw-flex tw-items-center tw-justify-center tw-bg-gray-10 tw-bg-opacity-90 tw-rounded-[100px] ${cardValues.changeBubbleSize} tw-cursor-pointer`}
        onClick={onClick}
      >
        <i style={bubbleStyle} className={`fa ${icon}`}></i>
      </div>
    </div>
  );
};

const Loading = ({ url, height, width }) => {
  const [loaded, setLoaded] = useState(false);

  const loadingStyle = {
    height,
    width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      {loaded ? null : (
        <div style={loadingStyle}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )}
      <img
        key={url}
        className={loaded ? "" : "tw-hidden"}
        height={height}
        width={width}
        src={url}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

const SizeValues = {
  small: {
    imgHeight: 143,
    imgWidth: 235,
    changeBubbleSize: "tw-h-24px tw-w-24px",
    changeIconSize: 8,
  },
  medium: {
    imgHeight: 194,
    imgWidth: 320,
    changeBubbleSize: "tw-h-32px tw-w-32px",
    changeIconSize: 10,
  },
  large: {
    imgHeight: 353,
    imgWidth: 498,
    changeBubbleSize: "tw-h-32px tw-w-32px",
    changeIconSize: 10,
  },
};
