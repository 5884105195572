/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const TintSolidV3 = (props) => (
  <Icon {...props} name="Tint Solid V3">
    <path d="M8.913.69c-.248-.899-1.545-.941-1.826 0C5.625 5.62 2.5 6.96 2.5 10.434c0 3.076 2.46 5.565 5.5 5.565s5.5-2.489 5.5-5.565c0-3.492-3.118-4.792-4.587-9.744zM8 14c-1.93 0-3.5-1.57-3.5-3.5a.5.5 0 0 1 1 0C5.5 11.878 6.622 13 8 13a.5.5 0 0 1 0 1z" />
  </Icon>
);

export default TintSolidV3;
