import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@shared/v2/Button";

import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import RadioButton from "../../../shared/v2/RadioButton/RadioButton";
import { getAvailableNumbers, getAvailableTollFreeNumbers } from "./services";
import { CountryCodes } from "./shared";
import Modal from "../../../shared/v2/Modal";
import Error from "../../../Reports/components/DashboardShared/Error";
import Divider from "../../../Settings/Phone/Divider";

const NumberSelection = ({
  countryCode,
  setCountryCode,
  areaCode,
  setAreaCode,
  generateTollFree,
  setGenerateTollFree,
  numberOptions,
  setNumberOptions,
  agentId,
  chosenNumber,
  setChosenNumber,
  onConfirmNumber,
  onClose,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");

  const onGenerateTollFree = () => {
    setGenerateTollFree(true);
    setAreaCode("");
  };

  const getNumberOptions = async () => {
    setIsFetching(true);
    setError("");
    setChosenNumber({});

    const { numbers, error: numbersError } = generateTollFree
      ? await getAvailableTollFreeNumbers(agentId, countryCode.value)
      : await getAvailableNumbers(agentId, countryCode.value, areaCode);

    if (numbersError) {
      setError(numbersError);
      setNumberOptions([]);
    } else {
      setNumberOptions(numbers || []);
    }

    setIsFetching(false);
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      contentSize="medium"
      contentClassName="tw-m-auto !tw-p-0"
    >
      <Modal.Header className="tw-px-32px tw-pt-32px" title="Choose a Tracking Number" closeButton>
        <Error message={error} show={Boolean(error)} onClose={() => setError("")} />
        <Divider verticalMargin="tw-mt-32px" />
      </Modal.Header>
      <Modal.Body className="tw-px-32px">
        <div className="tw-my-24px tw-text-gray-75">
          Tracking numbers can be used for specific campaigns and sources, so you know where your calls and
          leads are coming from.
        </div>
        <div className="tw-mb-16px">
          <span>Would you like a local or toll-free number?</span>
        </div>
        <div className="tw-flex tw-flex-row tw-mb-24px tw-gap-32px">
          <RadioButton
            name="selected"
            label="Local"
            checked={!generateTollFree}
            onChange={() => setGenerateTollFree(false)}
          />
          <RadioButton
            name="selected"
            label="Toll-Free"
            checked={generateTollFree}
            onChange={onGenerateTollFree}
          />
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-mb-24px tw-gap-16px">
          <div className="tw-flex tw-flex-col tw-flex-1">
            <span className="tw-mb-8px">Country Code</span>
            <Dropdown
              onChange={setCountryCode}
              className="tw-whitespace-nowrap"
              value={countryCode}
              options={CountryCodes}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-flex-1">
            <span className={generateTollFree ? "tw-mb-8px tw-text-gray-30" : "tw-mb-8px"}>Area Code</span>
            <input
              className={`tw-h-36px tw-text-14px tw-text-gray-99 tw-pl-12px tw-border-solid tw-border-1px tw-rounded-sm tw-border-gray-15 tw-bg-tinted-gray-50 ${
                generateTollFree ? "tw-placeholder-gray-30" : ""
              }`}
              placeholder={generateTollFree ? "Not applicable for toll-free" : "Enter an area code"}
              onChange={(e) => setAreaCode(e.target?.value || "")}
              value={areaCode}
              disabled={generateTollFree}
            />
          </div>
        </div>
        <div className="tw-flex tw-justify-end tw-mb-24px tw-px-8px">
          <Button
            size="medium"
            schema="secondary"
            disabled={!isFetching && (areaCode || generateTollFree) && countryCode?.value ? "" : "disabled"}
            onClick={getNumberOptions}
          >
            Search
          </Button>
        </div>
        {numberOptions.length > 0 && (
          <div className="tw-mb-[68px]">
            <Divider verticalMargin="tw-mb-24px" />
            <div className="tw-mb-24px">
              <span>Choose a new tracking number:</span>
            </div>
            <div className="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-gap-y-8px tw-px-16px">
              {numberOptions.map((number, i) => (
                <div
                  className={`tw-flex tw-basis-1/2 tw-whitespace-nowrap ${i % 2 === 0 ? "" : "tw-ml-auto"}`}
                >
                  <RadioButton
                    label={number.label}
                    checked={chosenNumber.value === number.value}
                    onChange={() => setChosenNumber(number)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="tw-mx-32px tw-mb-32px">
        <Divider verticalMargin="tw-mb-24px" />
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            disabled={chosenNumber?.value && !isFetching ? "" : "disabled"}
            onClick={onConfirmNumber}
          >
            Next
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

NumberSelection.propTypes = {
  agentId: PropTypes.string.isRequired,
  chosenNumber: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setChosenNumber: PropTypes.func.isRequired,
  countryCode: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setCountryCode: PropTypes.func.isRequired,
  areaCode: PropTypes.string.isRequired,
  setAreaCode: PropTypes.func.isRequired,
  generateTollFree: PropTypes.bool.isRequired,
  setGenerateTollFree: PropTypes.func.isRequired,
  numberOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setNumberOptions: PropTypes.func.isRequired,
  onConfirmNumber: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NumberSelection;
