import axios from "axios";

export const getFinancialData = (transactionSlug) =>
  axios.get(`/listings/${transactionSlug}/financials`, {
    headers: { Accept: "application/json" },
  });

export const createFinancialData = (transactionSlug, createData) =>
  axios.post(`/listings/${transactionSlug}/financials`, createData, {
    headers: { Accept: "application/json", "x-csrf-token": ReactOnRails.authenticityToken() },
  });

export const getDefaultCompany = (transactionSlug) =>
  axios.post(`/listings/${transactionSlug}/financials/add_company_seat`, null, {
    headers: { Accept: "application/json", "x-csrf-token": ReactOnRails.authenticityToken() },
  });

export const updateFinancialData = (transactionSlug, updatedData) =>
  axios.patch(`/listings/${transactionSlug}/financials`, updatedData, {
    headers: { Accept: "application/json", "x-csrf-token": ReactOnRails.authenticityToken() },
  });

export const getFinancialSelectableTeamMembers = (transactionSlug) =>
  axios.get(`/listings/${transactionSlug}/financials/selectable_agents`, {
    headers: { Accept: "application/json" },
  });

export const deleteTeamMember = (transactionSlug, memberId) =>
  axios.delete(`/listings/${transactionSlug}/team_member_incomes/${memberId}`, {
    headers: { Accept: "application/json", "x-csrf-token": ReactOnRails.authenticityToken() },
  });
