import React from "react";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";

const PersonDetailError = ({ error }) => {
  if (typeof error === "string") {
    return <Alert variant="error" title="Error" text={error} />;
  }
  if (typeof error === "object") {
    if (error.context.email_validations || error.context.emailValidations) {
      const emailValidations = error.context.email_validations || error.context.emailValidations || [];
      return (
        <Alert
          variant="error"
          title="Email Validation Failed"
          text={
            <div>
              <p>This contact shares an email address with the following contact(s)</p>
              <ul>
                {emailValidations.map((validation) => {
                  const [slug, name] = Object.entries(validation)[0];
                  return (
                    <li key={slug}>
                      <a href={`/people/${slug}`} target="_blank" rel="noopener noreferrer">
                        {name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          }
        />
      );
    }
    if (error.context.phone_validations || error.context.phoneValidations) {
      const phoneValidations = error.context.phone_validations || error.context.phoneValidations || [];
      return (
        <Alert
          variant="error"
          title="Phone Validation Failed"
          text={
            <div>
              <p>This contact shares a phone number with the following contact(s)</p>
              <ul>
                {phoneValidations.map(({ name, url }) => (
                  <li key={url}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          }
        />
      );
    }
  }
  return <Alert title="Error" text="Whoops something went wrong, try again later." />;
};

PersonDetailError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
};

export default PersonDetailError;
