import React from "react";
import PropTypes from "prop-types";
import SingleStatCard from "./SingleStatCard";
import useTransactionSummary from "./services/useTransactionSummary";
import Error from "../DashboardShared/Error";
import Loader from "../../../Goals/components/Loader";

const TransactionSummary = ({ year }) => {
  const isThisYear = String(year) === String(new Date().getFullYear());
  const { data, error, loading } = useTransactionSummary(year);
  return (
    <div className="tw-relative tw-flex tw-flex-wrap tw-gap-[8px]">
      <SingleStatCard
        label={`${isThisYear ? "YTD" : year} Pending to Closed Ratio`}
        value={`${data.summary?.ytdPendingToClosedRatio}%`}
      />
      <SingleStatCard
        label={`Avg. ${isThisYear ? "YTD" : year} Sale Price`}
        value={data.summary?.avgYtdSalesPrice}
      />
      <SingleStatCard label="Avg. MTD Sale Price" value={data.summary?.avgMtdSalesPrice} />
      <SingleStatCard label="Avg. YTD Commission" value={data.summary?.avgYtdCommission} />
      <SingleStatCard
        label="Est. Total GCI"
        value={data.summary?.estTotalGci}
        tooltip="Estimated Total GCI calculates incomes from transactions closed and to be closed in a given year."
      />
      <SingleStatCard
        label="YTD Company Dollar"
        value={data.summary?.ytdCompanyDollar}
        tooltip="Sum of Team GCI in Financials for Closed Transactions."
      />
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </div>
  );
};

TransactionSummary.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TransactionSummary;
