/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";

import { propsKey } from "../../Utilities";
import { get } from "lodash";

// Wrapping component with Redux props
//ex. WrappedComponent = Header, states ['title', 'referral.budget']
const withRedux = ({ reducerName }) => ({ WrappedComponent = null, states = [], aliases = [] }) => {
  const arrayOfStates = Array.isArray(states) ? states : [states];
  const arrayOfAliases = Array.isArray(aliases) ? aliases : [aliases];
  
  const mapStateToProps = (reduxState) => arrayOfStates.reduce(
    (acc, state, idx) => ({
      ...acc,
      [arrayOfAliases[idx] || propsKey(state)]: get(reduxState, `${reducerName}.${state}`)
    }),{}
  );

  return connect(mapStateToProps)(({ ...allProps }) => <WrappedComponent {...allProps} />);
};

export default withRedux;
