import React from "react";
import { Col } from "../../shared/v1";
import Toggle from "../../shared/v2/Toggle";
import "./calendar-toggle.css";

const CalendarOptions = ({ addTo, handleAddTo }) => (
  <Col size={6}>
    <Toggle text="Add to Google Calendar" onChange={handleAddTo} checked={addTo} />
  </Col>
);

export default CalendarOptions;
