import React from "react";
import Proptypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "@shared/v2/Dropdown";
import Toggle from "@shared/v2/Toggle";

const OPTIONS = [
  {
    label: "0.25 miles",
    value: "0.25",
  },
  {
    label: "0.5 miles",
    value: "0.5",
  },
  {
    label: "1 miles",
    value: "1.0",
  },
  {
    label: "1.5 miles",
    value: "1.5",
  },
  {
    label: "2 miles",
    value: "2.0",
  },
  {
    label: "3 miles",
    value: "3.0",
  },
  {
    label: "4 miles",
    value: "4.0",
  },
  {
    label: "5 miles",
    value: "5.0",
  },
  {
    label: "10 miles",
    value: "10.0",
  },
  {
    label: "25 miles",
    value: "25.0",
  },
  {
    label: "50 miles",
    value: "50.0",
  },
  {
    label: "100 miles",
    value: "100.0",
  },
];

const RadiusSelector = ({ value, onChange, toggle, onToggle }) => {
  let left = Math.floor((value / 12) * 100);
  if (left > 90) {
    left = 90;
  }
  return (
    <div data-cy="radius-input-wrapper" className="tw-mb-[24px]">
      <Toggle
        id="smart-rad"
        name="smart-rad"
        value={toggle}
        checked={toggle === true}
        disabled={false}
        onChange={() => onToggle(!toggle)}
        text="Use Smart Radius"
      />

      {!toggle ? (
        <Dropdown
          name="radius"
          value={OPTIONS.find((o) => o.value === value)}
          options={OPTIONS}
          isClearable={false}
          isSearchable={false}
          onChange={(opt) => onChange(opt.value)}
        />
      ) : (
        <div className="tw-flex tw-items-start tw-mt-[8px]">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="tw-h-[12px] tw-w-[12px] tw-text-gray-50 tw-mt-[8px]"
          />
          <p className="tw-text-gray-50 tw-font-[14px] tw-ml-[8px]">
            Smart radius will automatically expand the radius of your search until the report returns 15
            results.
          </p>
        </div>
      )}
    </div>
  );
};

export default RadiusSelector;

RadiusSelector.propTypes = {
  value: Proptypes.number,
  toggle: Proptypes.bool,
  onChange: Proptypes.func,
  onToggle: Proptypes.func,
};

RadiusSelector.defaultProps = {
  value: 0,
  toggle: false,
  onToggle: () => {},
  onChange: () => {},
};
