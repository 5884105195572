import React, { cloneElement, useState } from "react";
import { element, func, string } from "prop-types";

const ImageOption = ({ children, clicked, dataCy }) => {
  const [iconColor, setIconColor] = useState("");

  return (
    <div
      className="tw-w-[32px] tw-h-[32px] tw-bg-color-ebebeb tw-rounded-full tw-cursor-pointer tw-flex tw-items-center tw-justify-center"
      onMouseEnter={() => setIconColor("#FA423C")}
      onMouseLeave={() => setIconColor("")}
      onKeyDown={clicked}
      onClick={clicked}
      role="button"
      tabIndex={-1}
      data-cy={dataCy}
    >
      {React.Children.map(children, (child) =>
        cloneElement(child, {
          className: [child.props?.className, `tw-text-[${iconColor}]`].filter(Boolean).join(" "),
        }),
      )}
    </div>
  );
};

ImageOption.propTypes = {
  children: element,
  dataCy: string,
  clicked: func,
};

ImageOption.defaultProps = {
  children: null,
  dataCy: null,
  clicked: () => {},
};

export default ImageOption;
