import React from "react";
import { Col, Row } from "react-bootstrap";
import { arrayMove } from "react-sortable-hoc";
import format from "../../../shared/formatters";
import AddAgentModal from "./AddAgentModal";
import AddIncomeExpenseModal from "./AddIncomeExpenseModal";
import ExpenseModal from "./ExpenseModal";
import TransactionAdditionalIncomeSortable from "./TransactionAdditionalIncomeSortable";
import TransactionInput from "../shared/TransactionInput";
import {
  formatValue,
  reCalculateAgentExpenses,
  reorderExpenses,
  createSortedExpensesPointer,
  fullRecalculation,
} from "../shared/FinancialViewHelpers";
import { AgentsDetailForm } from "./AgentsDetailForm";
import CollaboratorExpense from "./CollaboratorExpense";
import CollaboratorExpenseModal from "./CollaboratorExpenseModal";

const unitOptions = [
  { label: "0", value: "0" },
  { label: ".1", value: ".1" },
  { label: ".2", value: ".2" },
  { label: ".3", value: ".3" },
  { label: ".4", value: ".4" },
  { label: ".5", value: ".5" },
  { label: ".6", value: ".6" },
  { label: ".7", value: ".7" },
  { label: ".8", value: ".8" },
  { label: ".9", value: ".9" },
  { label: "1", value: "1" },
];

class TransactionFinancialDetailForm extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentWillMount() {
    this.props.getAgentList(this.props.listingData.listing_id);
  }

  onAddAgent = () => {
    this.props.setIsOpenAddAgentModal(true);
  };

  onAddIncome = () => {
    this.props.setIsOpenNewIncomeModal(true);
  };

  onAddExpense = (i) => {
    this.props.setIsExpenseModal(true);
    this.props.setModalIndex(i);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.listingData.transaction_income.additionalIncome = arrayMove(
      this.props.listingData.transaction_income.additionalIncome,
      oldIndex,
      newIndex,
    );
    let position = 0;
    this.props.listingData.transaction_income.additionalIncome.map((obj) => {
      if (obj._destroy) {
        if (obj.position) {
          delete obj["position"];
        }
      } else {
        obj.position = position + 1;
        position = position + 1;
      }
    });
    this.props.listingData.transaction_income.custom_transaction_expenses_attributes = [];
    this.props.listingData.transaction_income.custom_transaction_revenues_attributes = [];
    this.props.listingData.transaction_income.additionalIncome.forEach((income) => {
      if (income.type === "expense") {
        this.props.listingData.transaction_income.custom_transaction_expenses_attributes.push(income);
      }
      if (income.type === "revenue") {
        this.props.listingData.transaction_income.custom_transaction_revenues_attributes.push(income);
      }
    });

    this.props.setFinancialData(this.props.listingData);
    this.props.recalculate_transaction_gci_and_team_member_net_incomes(this.props.listingData);
  };

  onSortAdditionalIncome = (indexes, i) => {
    const { setFinancial, listingData } = this.props;

    const { oldIndex, newIndex } = indexes;

    const pseudoIncome = listingData.team_member_incomes.incomes[i];
    const expPointer = createSortedExpensesPointer(pseudoIncome);
    reorderExpenses(expPointer, oldIndex, newIndex);
    pseudoIncome.custom_team_member_expenses_attributes.sort((exp1, exp2) => exp1.position - exp2.position);

    reCalculateAgentExpenses(pseudoIncome, listingData, i, setFinancial);
  };

  onRemoveIncome = (incomeId) => {
    this.props.removeIncome(incomeId);
  };

  onAddCollaboratorExpense = (collaboratorIndex) => {
    this.props.setIsCollaboratorExpenseModalOpen(true);
    this.props.setCollaboratorModalIndex(collaboratorIndex);
  };

  onRemoveCollaboratorExpense = (expenseId) => {
    this.props.removeCollaboratorExpense(expenseId);
  };

  render() {
    const {
      listingData,
      isOpenAddAgentModal,
      isOpenNewIncomeModal,
      setFinancialData,
      saveFinancialData,
      isExpenseModal,
      recalculate_transaction_gci_and_team_member_net_incomes,
      recalculate_all_team_member_net_incomes,
      recalculate_team_member_net_income,
      recalculateCollaboratorExpenses,
      isCollaboratorExpenseModalOpen,
    } = this.props;

    return (
      <div className="financial-details-wrap">
        <div className="financial-header clearfix">
          <div className="pull-right">
            <button className="btn btn-default btn-default-inverse" onClick={this.onAddAgent}>
              ADD AGENT
            </button>
            <button
              className="btn btn-info btn-info-inverse"
              onClick={(e) => {
                saveFinancialData(listingData);
              }}
            >
              SAVE
            </button>
          </div>
        </div>
        <div className="transaction-gci-wrap clearfix">
          <div className="transaction-gci-options">
            <div className="transaction-gci-header clearfix">
              <h3>Transactions Details</h3>
            </div>
            <div className="transaction-gci-content">
              <Row>
                <Col xs={1} className="drag-handler" />
                <Col xs={2}>
                  <TransactionInput
                    label="Closed Volume"
                    value={listingData.transaction_income.closed_volume}
                    isPercentage={false}
                    disableFlip={true}
                    onValueUpdate={(e) => {
                      listingData.transaction_income.closed_volume = e.target.value;
                      fullRecalculation(listingData, setFinancialData);
                    }}
                  />
                </Col>
                <Col xs={7} />
                <Col xs={2} className="text-right">
                  <span>
                    {listingData.transaction_income.closed_volume
                      ? format.currency(listingData.transaction_income.closed_volume)
                      : ""}
                  </span>
                </Col>
              </Row>
            </div>
            <div className="transaction-gci-content">
              <Row>
                <Col xs={1} className="drag-handler" />
                <Col xs={2}>
                  <TransactionInput
                    label="Commission"
                    value={listingData.transaction_income.commission}
                    isPercentage={!!listingData.transaction_income.commission_percentage}
                    name="commisionPercentage"
                    onValueUpdate={(e) => {
                      if (!!listingData.transaction_income.commission_percentage) {
                        if (e.target.value >= 0 && e.target.value <= 100) {
                          listingData.transaction_income.commission = e.target.value;
                          fullRecalculation(listingData, setFinancialData);
                        }
                      } else {
                        listingData.transaction_income.commission = e.target.value;
                        fullRecalculation(listingData, setFinancialData);
                      }
                    }}
                    onRadioUpdate={(e) => {
                      if (e.target.value === "true" && listingData.transaction_income.commission > 100) {
                        listingData.transaction_income.commission = "";
                      }
                      listingData.transaction_income.commission_percentage = e.target.value === "true";
                      fullRecalculation(listingData, setFinancialData);
                    }}
                  />
                </Col>

                <Col xs={4}>
                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      className="description form-control"
                      name={`commisionNotes`}
                      placeholder=""
                      onChange={(e) => {
                        listingData.transaction_income.commission_notes = e.target.value;
                        setFinancialData(listingData);
                      }}
                    >
                      {listingData.transaction_income.commission_notes}
                    </textarea>
                  </div>
                </Col>
                <Col xs={3} />

                <Col xs={2} className="text-right">
                  <span>
                    {listingData.transaction_income.gross_income
                      ? format.currency(listingData.transaction_income.gross_income)
                      : ""}
                  </span>
                </Col>
              </Row>
            </div>

            <div className="transaction-gci-options">
              <div className="transaction-gci-header clearfix">
                <h3>Expenses & Income</h3>
                <span className="add pull-right">
                  <a href="javascript:void(0)" onClick={() => this.onAddIncome()}>
                    Add
                  </a>
                </span>
              </div>

              <TransactionAdditionalIncomeSortable
                items={listingData.transaction_income.additionalIncome}
                onSortEnd={this.onSortEnd}
                listingData={listingData}
                setFinancialData={setFinancialData}
                recalculate_transaction_gci_and_team_member_net_incomes={
                  recalculate_transaction_gci_and_team_member_net_incomes
                }
                useDragHandle
              />

              <div className="transaction-gci-footer clearfix">
                <div className="pull-left definition">
                  <h4>Transaction GCI</h4>
                </div>
                <div className="pull-right total-amount">
                  <h4>
                    {listingData.transaction_income.gci
                      ? format.currency(listingData.transaction_income.gci)
                      : ""}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AgentsDetailForm
          onRemoveIncome={this.onRemoveIncome}
          recalculate_all_team_member_net_incomes={recalculate_all_team_member_net_incomes}
          setFinancialData={setFinancialData}
          listingData={listingData}
          unitOptions={unitOptions}
          onAddExpense={this.onAddExpense}
          onSortAdditionalIncome={this.onSortAdditionalIncome}
          recalculate_team_member_net_income={recalculate_team_member_net_income}
        />
        {listingData.collaborator_expenses.expenses.map((expense, index) => (
          <div className="edit-agent-wrap">
            <h2>
              {expense.name}
              <span
                className="fa fa-times remove-income"
                onClick={() => this.onRemoveCollaboratorExpense(expense.id)}
              />
            </h2>

            <div className="collaborator-expense-wrap clearfix">
              <div className="collaborator-options">
                <div className="collaborator-header clearfix">
                  <h3>Expenses</h3>
                  {expense.custom_collaborator_expenses_attributes.length === 0 && (
                    <span className="add pull-right">
                      <a onClick={() => this.onAddCollaboratorExpense(index)}>Add</a>
                    </span>
                  )}
                </div>
                {expense.custom_collaborator_expenses_attributes.map((customExpense, index) => (
                  <CollaboratorExpense
                    key={index}
                    expense={expense}
                    listingData={listingData}
                    setFinancialData={setFinancialData}
                    recalculateCollaboratorExpenses={recalculateCollaboratorExpenses}
                    value={customExpense}
                  />
                ))}
                <div className="collaborator-expense-footer clearfix">
                  <div className="pull-left definition">
                    <h4>Total</h4>
                  </div>
                  <div className="pull-right total-amount">
                    <h4>{formatValue(expense.total_expense, false)}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="financial-header clearfix">
          <div className="pull-right">
            <button className="btn btn-default btn-default-inverse" onClick={this.onAddAgent}>
              ADD AGENT
            </button>
            <button
              className="btn btn-info btn-info-inverse"
              onClick={(e) => {
                saveFinancialData(listingData);
              }}
            >
              SAVE
            </button>
          </div>
        </div>

        {isOpenAddAgentModal && this.renderAgentModal()}
        {isOpenNewIncomeModal && this.renderAdditionalModal()}
        {isExpenseModal && this.renderExpenseModal()}
        {isCollaboratorExpenseModalOpen && this.renderCollaboratorExpenseModal()}
      </div>
    );
  }

  renderAgentModal() {
    const { isOpenAddAgentModal, setIsOpenAddAgentModal, listingData, agentList, setFinancialData } =
      this.props;

    return (
      <AddAgentModal
        isOpenAddAgentModal={isOpenAddAgentModal}
        setIsOpenAddAgentModal={setIsOpenAddAgentModal}
        listingData={listingData}
        agentList={agentList}
        setFinancialData={setFinancialData}
      />
    );
  }

  renderAdditionalModal() {
    const {
      isOpenNewIncomeModal,
      setIsOpenNewIncomeModal,
      setFinancial,
      listingData,
      transactionExpenseList,
      recalculate_transaction_gci_and_team_member_net_incomes,
    } = this.props;

    return (
      <AddIncomeExpenseModal
        isOpenNewIncomeModal={isOpenNewIncomeModal}
        setIsOpenNewIncomeModal={setIsOpenNewIncomeModal}
        setFinancial={setFinancial}
        listingData={listingData}
        transactionExpenseList={transactionExpenseList}
        recalculate_transaction_gci_and_team_member_net_incomes={
          recalculate_transaction_gci_and_team_member_net_incomes
        }
      />
    );
  }

  renderExpenseModal() {
    const {
      isExpenseModal,
      setIsExpenseModal,
      modalIndex,
      setFinancial,
      setFinancialData,
      transactionExpenseList,
      listingData,
      recalculate_team_member_net_income,
    } = this.props;

    return (
      <ExpenseModal
        isExpenseModal={isExpenseModal}
        setIsExpenseModal={setIsExpenseModal}
        modalIndex={modalIndex}
        setFinancialData={setFinancialData}
        setFinancial={setFinancial}
        listingData={listingData}
        transactionExpenseList={transactionExpenseList}
        recalculate_team_member_net_income={recalculate_team_member_net_income}
      />
    );
  }

  renderCollaboratorExpenseModal() {
    const {
      isCollaboratorExpenseModalOpen,
      setIsCollaboratorExpenseModalOpen,
      collaboratorModalIndex,
      setFinancialData,
      listingData,
      recalculateCollaboratorExpenses,
    } = this.props;

    return (
      <CollaboratorExpenseModal
        isCollaboratorExpenseModalOpen={isCollaboratorExpenseModalOpen}
        setIsCollaboratorExpenseModalOpen={setIsCollaboratorExpenseModalOpen}
        collaboratorModalIndex={collaboratorModalIndex}
        setFinancialData={setFinancialData}
        listingData={listingData}
        recalculateCollaboratorExpenses={recalculateCollaboratorExpenses}
      />
    );
  }
}

export default TransactionFinancialDetailForm;
