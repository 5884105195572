import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class TimelineDocumentEvent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
    };
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({ show_modal: false });
  };

  headline = () => {
    const from = this.props.event.timeline_fields.uploader_name;
    const action = this.props.event.event_type == "document_destroyed" ? "deleted" : "uploaded";

    return (
      <div>
        <span className="bold-detail">{from}</span>
        {` ${action} a document`}
      </div>
    );
  };

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-upload timeline-icon-circle" />
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">
              Document Name:
              <a
                href={this.props.event.timeline_fields.document_url}
                target="_blank"
              >{` ${this.props.event.timeline_fields.document_name}`}</a>
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.props.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    return (
      this.state.show_modal && (
        <Modal bsSize="sm" show={this.state.show_modal} onHide={this.hideModal} className="timeline-content">
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.headline()}
              <div className="row bold-detail details-row details-header">Details:</div>
              <div className="row details-row">
                Document Name:
                <a
                  href={this.props.event.timeline_fields.document_url}
                  target="_blank"
                >{` ${this.props.event.timeline_fields.document_name}`}</a>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        </Modal>
      )
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
