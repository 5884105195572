import React, { Component } from 'react';
import axios from 'axios';
import groupBy from 'lodash/groupBy';
import SearchTermResults from './SearchTermResults';
import SearchTermBubble from './SearchTermBubble';
import alerts from './listing_alerts.module.css';
import TextInput from '@shared/v2/TextInput';

const mapSearchFromMultiCat = (multiCatArray, multiSearchArray) => multiSearchArray.map((searchValue, index) => {
  const multiCatAtIndex = multiCatArray[index];
  const searchResult = { multiSearch: searchValue, multiCat: multiCatAtIndex }
  // TODO: determine if any of the other multiCatAtIndex values need to be mapped
  if (multiCatAtIndex === 'CityState') {
    searchResult['label'] = 'City'
  } else if (multiCatAtIndex === 'NeighborhoodCityState') {
    searchResult['label'] = 'Neighborhood'
  } else if (multiCatAtIndex === 'CountyState') {
    searchResult['label'] = 'County'
  } else {
    searchResult['label'] = multiCatAtIndex
  }
  return searchResult
});

class SearchAutocomplete extends Component {
  state = {
    currentCategoryPosition: 0,
    currentChildPosition: 0,
    multi_search: '',
    searchTermActive: false,
    searchResults: [],
    selectedSearchTerms: []
  }

  componentDidMount() {
    this.mapPropsToSearch();
  }

  componentDidUpdate(prevProps, _) {
    if (this.props.multiCatArray === prevProps.multiCatArray && this.props.multiSearchArray === prevProps.multiSearchArray) {
      return;
    }

    this.mapPropsToSearch(true);
  }

  mapPropsToSearch(propsUpdated = false) {
    const multiCatArray = this.props.multiCatArray || []
    const multiSearchArray = this.props.multiSearchArray || []

    if (propsUpdated || multiCatArray.length > 0 && multiSearchArray.length > 0) {
      this.setState({ selectedSearchTerms: mapSearchFromMultiCat(multiCatArray, multiSearchArray) });
    }
  }

  fetchResults = (term) => {
    const idsArrayQuery = this.props.mlsIds.map((id) => `mlsIds[]=${id}`).join('&')

    // TODO: Do we need to pass multi_search back up to the parent?
    this.setState({ multi_search: term }, () => this.doFetchResults(idsArrayQuery, term))
  };

  doFetchResults = (idsArrayQuery, term) => {
    axios.get(`/people/${this.props.personId}/listing_alert_autocomplete.json?${idsArrayQuery}&term=${term}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(response => {
        // This happens if a previous query returned later than a later query
        if (this.state.multi_search !== term) {
          return;
        }

        const searchResults = response.data
        const groupedResults = groupBy(searchResults, (obj) => obj.label)
        let mappedResults = []

        Object.keys(groupedResults).each((key) => {
          let children = groupedResults[key].map((object) => {
            const isAddress = object.label === "Address";
            const value = isAddress ? object.selected_fields.address : object.value;
            return { text: object.value, category: object.category, value: value, label: object.label }
          })

          mappedResults.push({ text: key, children: children })
        })

        const order = {
          "Address": 1,
          "City": 2,
          "Neighborhood": 3,
          "MLS": 4,
          "County": 5,
          "Zip Code": 6,
          "Building Name": 7,
          "School District": 8,
          "Elementary School": 9,
          "Middle School": 10,
          "High School": 11,
          "Area": 12
        }

        const orderedResults = mappedResults.sort((v1, v2) => {
          if (order[v1.text] < order[v2.text]) {
            return -1
          } else if (order[v1.text] > order[v2.text]) {
            return 1
          } else {
            return 0
          }
        })

        this.setState({ searchResults: orderedResults })
      })
  };

  handleHover = (currentCategoryPosition, currentChildPosition) => {
    this.setState({ currentCategoryPosition, currentChildPosition })
  };

  handleMouseSelect = (value) => {
    if (this.state.selectedSearchTerms.filter(term => term.multiSearch === value.multiSearch).length > 0) {
      this.setState({ currentCategoryPosition: 0, currentChildPosition: 0, multi_search: '', searchResults: [], searchTermActive: false })
    } else {
      this.setState((prevState) => ({
        currentCategoryPosition: 0,
        currentChildPosition: 0,
        multi_search: '',
        searchResults: [],
        selectedSearchTerms: [...prevState.selectedSearchTerms, value],
        searchTermActive: false
      }), () => { this.props.handleAddSearchTerm(value) })
    }

  }

  removeSearchTerm = (multiSearch) => {
    this.setState((prevState) => ({ selectedSearchTerms: prevState.selectedSearchTerms.filter(term => term.multiSearch !== multiSearch) }), () => {
      this.props.handleRemoveSearchTerm(multiSearch)
    })
  }

  handleKeyDown = (e) => {
    const { searchResults, currentCategoryPosition, currentChildPosition, multi_search } = this.state

    if (e.keyCode === 13) { //Enter has been pressed
      e.preventDefault()
      const selectedTerm = searchResults[currentCategoryPosition].children[currentChildPosition]
      const termValue = { multiCat: selectedTerm.category, multiSearch: selectedTerm.value, label: selectedTerm.label }
      this.setState((prevState) => ({
        currentCategoryPosition: 0,
        currentChildPosition: 0,
        multi_search: '',
        searchResults: [],
        selectedSearchTerms: [...prevState.selectedSearchTerms, termValue]
      }), () => { this.props.handleAddSearchTerm(termValue) })
    } else if (e.keyCode === 38) { //navigate up
      if (currentChildPosition > 0) {
        this.setState(prevState => ({ currentChildPosition: prevState.currentChildPosition - 1 }))
      } else if (currentCategoryPosition > 0) {
        const children = searchResults[currentCategoryPosition - 1].children
        this.setState(prevState => ({ currentCategoryPosition: prevState.currentCategoryPosition - 1, currentChildPosition: children.length - 1 }))
      }
    } else if (e.keyCode === 40) { //navigate down
      if (currentChildPosition < searchResults[currentCategoryPosition].children.length - 1) {
        this.setState(prevState => ({ currentChildPosition: prevState.currentChildPosition + 1 }))
      } else if (currentCategoryPosition < searchResults.length - 1) {
        this.setState(prevState => ({ currentCategoryPosition: prevState.currentCategoryPosition + 1, currentChildPosition: 0 }))
      }
    } else if (e.keyCode === 8 && multi_search.length === 1) { //removing last character with backspace
      this.setState({
        currentCategoryPosition: 0,
        currentChildPosition: 0,
        multi_search: '',
        searchResults: []
      })
    }
  };

  render() {
    const { multi_search, searchTermActive, currentCategoryPosition, currentChildPosition, selectedSearchTerms, searchResults } = this.state

    return (
      <div>
        <TextInput
          autoComplete="no"
          name="multi_search"
          onFocus={() => this.setState({ searchTermActive: true })}
          onChange={(e) => this.fetchResults(e.target.value)}
          onKeyDown={this.handleKeyDown}
          value={multi_search}
        />
        {(searchTermActive && searchResults.length > 0) &&
          <div id={alerts.searchResults}>
            <div className={alerts.resultChildren}>
              {
                searchResults.map((results, index) =>
                  <SearchTermResults
                    key={results.text}
                    categoryPosition={index}
                    currentCategoryPosition={currentCategoryPosition}
                    currentChildPosition={currentChildPosition}
                    handleHover={this.handleHover}
                    handleSelect={this.handleMouseSelect}
                    results={results}
                  />
                )
              }
            </div>
          </div>
        }
        <div>
          <span className="tw-text-12px tw-text-gray-50">Search by City, Zip, County, Neighborhood, or School District</span>
        </div>
        <div id={alerts.searchBubbles}>
          {
            selectedSearchTerms.map(term => (
              <SearchTermBubble key={term.multiSearch} term={term} removeSearchTerm={this.removeSearchTerm} />
            ))
          }
        </div>
      </div>
    );
  }
}

export default SearchAutocomplete;
