import React from "react";
import Avatar from "../v2/Avatar";
import { getLetters } from "../Utilities";

const DropDownSelectOption = ({
  handleSelect = () => {},
  isSelected = false,
  option = {},
  showCheckIcon = true,
}) => (
  <button
    className={`tw-h-[40px] tw-flex tw-gap-[4px] tw-items-center tw-truncate tw-px-2 tw-py-1 tw-bg-transparent hover:tw-bg-gray-5 tw-border-0 tw-w-full`}
    onClick={(e) => {
      e.preventDefault();
      handleSelect(option);
    }}
  >
    {showCheckIcon && (
      <i className={`tw-pr-4 tw-w-[24px] fa fa-${isSelected ? "check-square-o" : "square-o"}`} />
    )}
    <Avatar src={option.imageSrc} alt={option.name} className="tw-shrink-0" />
    <small>{option.name}</small>
  </button>
);

export default DropDownSelectOption;
