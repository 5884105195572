/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const RotateRightSolidV6 = (props) => (
  <Icon {...props} name="Rotate Right Solid V6">
    <path d="M14.653 1.003c.433 0 .849.337.849.845v4.559a.599.599 0 0 1-.599.599H10.35a.843.843 0 0 1-.597-1.441l1.41-1.411a5 5 0 0 0-3.166-1.145 5 5 0 0 0-4.994 4.994 4.998 4.998 0 0 0 4.993 4.993c2.286 0 2.795-1.197 3.597-1.197.578 0 .999.47.999.999 0 1.095-2.55 2.2-4.594 2.2-3.856 0-6.997-3.141-6.997-6.997s3.142-6.988 6.998-6.988c1.706 0 3.319.637 4.575 1.727l1.488-1.488a.828.828 0 0 1 .591-.249z" />
  </Icon>
);

export default RotateRightSolidV6;
