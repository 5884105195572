import React, { Component } from 'react';

import transitions from '../transitions.module.css';
import Checkbox from '@shared/v2/Checkbox';
import { ChevronDownSolidV6, ChevronUpSolidV6 } from '@shared/v2/Icomoon';

class AdvancedOptions extends Component {
  CollapsedMax = 3;

  state = {
    collapsedView: true
  }

  toggleShowMoreOptions = () => {
    this.setState((prevState) => ({ collapsedView: !prevState.collapsedView }))
  }

  showMoreOptionsClass = () => this.state.collapsedView ? transitions.laOptionsCollapsed : ''

  isOptionSelected = (option) => {
    return this.props.selectedAdvancedOptions.some(s => s.term === option.search_value && s.search_category === option.search_category);
  }

  toggleOptionSelection = (option) => {
    this.props.handleChecked(option.search_category, option.search_value);
  }

  render() {
    const sortedOptions = this.props.options.sort((a, b) => a.label.localeCompare(b.label));
    const options = this.state.collapsedView && sortedOptions.length > this.CollapsedMax
      ? sortedOptions.slice(0, this.CollapsedMax)
      : sortedOptions;

    return (
      <div className="tw-mb-24px">
        <div className={`tw-flex tw-flex-col tw-mb-8px ${transitions.laOptions}`}>
          <span className="tw-text-14px tw-font-semibold tw-text-gray-50 tw-mb-8px">{this.props.label}</span>
          <div className="tw-grid tw-grid-cols-3 tw-gap-y-8px">
          {(options).map(option => (
            <AdvancedOption
              key={option.id}
              option={option}
              onSelect={() => this.toggleOptionSelection(option)}
              isOptionSelected={this.isOptionSelected(option)}
            />
          ))}
          </div>
        </div>
        {this.props.options.length > this.CollapsedMax && (
            <div className="tw-flex tw-gap-4px tw-cursor-pointer tw-text-theme-primary tw-text-12px tw-items-center" onClick={this.toggleShowMoreOptions}>
              <span className="tw-font-bold">
                {this.state.collapsedView ?  'MORE' : 'LESS'}
              </span>
              {this.state.collapsedView ? <ChevronDownSolidV6 size="s" /> : <ChevronUpSolidV6 size="s" />}
          </div>
        )}
      </div>
    );
  }
}

export default AdvancedOptions;

const AdvancedOption = ({option, onSelect, isOptionSelected}) => {
  return (
    <div
      className="tw-flex tw-gap-8px"
      onClick={onSelect}
    >
      <div className="tw-flex tw-flex-col tw-items-start tw-mt-3px">
        <Checkbox className="tw-cursor-pointer" checked={isOptionSelected} readOnly />
        </div>
      <span className="tw-cursor-pointer tw-text-gray-75">{option.label}</span>
    </div>
  );
};
