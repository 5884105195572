import React from "react";
import PropTypes from "prop-types";

import { InboxRegularV6 } from "@shared/v2/Icomoon";

const EmptyState = ({ show, children, containerClassName }) =>
  show ? (
    <div className={`tw-flex tw-flex-col tw-items-center tw-gap-16px ${containerClassName}`}>
      <InboxRegularV6 size="xxxxl" className="tw-text-gray-30" />
      {children}
    </div>
  ) : null;

export default EmptyState;

EmptyState.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
};

EmptyState.defaultProps = {
  show: false,
  children: null,
  containerClassName: "",
};
