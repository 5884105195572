/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { arrayOf, func, shape, string } from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { useGeneralRolesById, useTeamRolesById, useUserById } from "../../../reducers/autoPlanEdit";

const AssignToDropdown = ({options, setState, label, state, containerClassName}) => {
  const [ value, setValue ] = useState(null);

  const TYPE_TO_USE_ID = {
    'Role': useTeamRolesById(),
    'User': useUserById()
  }

  const generalRolesById = useGeneralRolesById()

  useEffect(() => {
    const { 
      assigned_to_type,
      assigned_to_id,
      assigned_to_role
    } = state;

    const lookupTable = TYPE_TO_USE_ID[assigned_to_type] || generalRolesById;
    const lookupValue = assigned_to_role || assigned_to_id;

    setValue({label: lookupTable[lookupValue], value: lookupValue})
  }, [state])
  
  return (
    <Dropdown
      containerClassName={`tw-w-[264px] ${containerClassName}`}
      label={label}
      options={options}
      value={value}
      onChange={setState}
      isRequired/>
  )
};

export default AssignToDropdown;

AssignToDropdown.propTypes = {
  options: arrayOf(shape({})).isRequired,
  label: string.isRequired,
  setState: func.isRequired,
  state: arrayOf(shape({})),
  containerClassName: string
}

AssignToDropdown.defaultProps = {
  state: [],
  containerClassName: ''
}
