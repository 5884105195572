/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { extractData } from "../utils";

const MultiValueContainer = ({ children, ...componentProps }) => {
  const Component = componentProps.selectProps?.custom?.MultiValueContainer;

  if (!Component) {
    return null;
  }

  return (
    <Component componentProps={componentProps} data={extractData(componentProps)}>
      {children}
    </Component>
  );
};

MultiValueContainer.propTypes = {
  selectProps: PropTypes.shape({
    custom: PropTypes.shape({
      MultiValueContainer: PropTypes.func,
    }),
  }),
  children: PropTypes.node,
};

MultiValueContainer.defaultProps = {
  selectProps: {
    custom: {
      MultiValueContainer: null,
    },
  },
  children: null,
};

export default MultiValueContainer;
