import React from "react";
import { string, func } from "prop-types";
import ContactsDetailSelector from "@shared/v2/DetailSelector/DetailSelector";
import { TEXT_CLASSES, DETAIL_TEXT_CLASSES } from "../api/constants";
import listingDataShape from "../model/listingData";

const ListingDetailsField = ({ label, name, listingData, setListingData }) => {
  const setImportListingDetails = (selectedKey, targetname) => {
    const keyChecked = selectedKey === "mls" ? "mlsChecked" : "currentChecked";
    const keyUnChecked = selectedKey === "mls" ? "currentChecked" : "mlsChecked";
    setListingData((prevState) => ({
      ...prevState,
      [targetname]: {
        ...prevState[targetname],
        [keyChecked]: true,
        [keyUnChecked]: false,
      },
    }));
  };
  const mlsValue = String(listingData[name][`mls_${name}`]);
  const currentValue = String(listingData[name][`current_${name}`]);

  return (
    <>
      {listingData[name].isSelectable ? (
        <div className="tw-px-[9px] tw-pt-[12px]">
          <ContactsDetailSelector
            containerClassName={`${TEXT_CLASSES} !tw-text-left tw-truncate`}
            firstValue={mlsValue}
            label={label}
            onSelect={(value) => setImportListingDetails(value === mlsValue ? "mls" : "current", name)}
            secondValue={currentValue}
            selectedValue={listingData[name].currentChecked ? currentValue : mlsValue}
          />
        </div>
      ) : (
        <div className="tw-px-[9px] tw-pt-[12px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[12px]">
            <span className={`tw-flex-1 ${TEXT_CLASSES} tw-pt-[8px]`}>{label}</span>
            <div className="tw-flex tw-items-center tw-gap-[10px]">
              <div className={DETAIL_TEXT_CLASSES}>{mlsValue}</div>
              <span className="tw-w-[12px]" />
              <div className={DETAIL_TEXT_CLASSES}>{currentValue}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
ListingDetailsField.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  listingData: listingDataShape,
  setListingData: func,
};
ListingDetailsField.defaultProps = {
  setListingData: () => {},
};

export default ListingDetailsField;
