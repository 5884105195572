import React from "react";
import { bool, func, oneOfType, shape, string } from "prop-types";
import { connect } from "react-redux";

import usePayload from "./hooks";
import { postTaskAsThunk, editTaskAsThunk } from "../actions/thunks";

import withFilters from "../HOCs/withFilters";
import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import TextInput from "../../../../shared/v2/TextInput";
import ModalAddEditTaskButtons from "../ModalAddEditTaskButtons";
import ModalAddEditInputs from "../ModalAddEditInputs";
import ModalAddEditPriorityRadios from "../ModalAddEditPriorityRadios";
import ModalAddEditViewToggle from "../ModalAddEditViewToggle";
import ModalAddEditTaskToggles from "../ModalAddEditTaskToggles";
import ModalAddEditTaskFooter from "../ModalAddEditTaskFooter";
import ModalAddEditDropdowns from "../ModalAddEditDropdowns";

const ModalAddEditTask = ({
  show,
  toggleModalAddTaskOpen,
  title,
  uuid,
  onPostTaskAsThunk,
  toggleModalShowTask,
  onEditTaskAsThunk,
  task,
  taskFilters,
}) => {
  const { payload, setPayload, enabledAddInputs, setEnabledAddInputs, closeHandlr, editHandlr } = usePayload(
    toggleModalAddTaskOpen,
    uuid,
    onPostTaskAsThunk,
    onEditTaskAsThunk,
    task,
    taskFilters,
  );

  return (
    <Modal
      show={show}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      onHide={() => {
        if (task) toggleModalShowTask((prev) => !prev);
        closeHandlr();
      }}
      closeButton
    >
      <Modal.Header
        title={`${task ? "Edit" : "Add"} Task ${title ? `for ${title}` : ""}`}
        className="tw-mb-32px"
        closeButton
      />
      <Modal.Body className="tw-mb-32px tw-max-h-[70vh] tw-overflow-auto">
        <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold tw-mb-8px">
          Task Details <span className="tw-font-bold tw-text-brivity-coral-100">*</span>
        </div>
        <TextInput
          value={payload.title}
          onChange={(e) => {
            e.persist();
            setPayload((prev) => ({
              ...prev,
              title: e.target.value,
            }));
          }}
        />
        <ModalAddEditTaskButtons
          enabledAddEditInputs={enabledAddInputs}
          setEnabledAddEditInputs={setEnabledAddInputs}
          payload={payload}
        />
        <ModalAddEditInputs
          enabledAddEditInputs={enabledAddInputs}
          setEnabledAddEditInputs={setEnabledAddInputs}
          payloadHandlrs={setPayload}
          payload={payload}
        />
        <ModalAddEditPriorityRadios payloadHandlrs={setPayload} priority={payload.priority} />
        <ModalAddEditDropdowns payload={payload} payloadHandlrs={setPayload} />
        <ModalAddEditViewToggle payloadHandlrs={setPayload} visibility={payload.visibility} />
        <hr className="tw-border-solid tw-border-neutral-gray-10" />
        <ModalAddEditTaskToggles payloadHandlrs={setPayload} payload={payload} />
        <ModalAddEditTaskFooter payload={payload} setPayload={setPayload} />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row">
        <Button
          className="tw-mr-auto"
          size="medium"
          schema="tertiary"
          onClick={() => {
            if (task) toggleModalShowTask((prev) => !prev);
            closeHandlr();
          }}
        >
          CANCEL
        </Button>
        <Button
          className="tw-mr-8px"
          size="medium"
          schema="secondary"
          disabled={payload.title.length === 0 || Boolean(payload.recurring)}
          onClick={() => {
            if (task) {
              editHandlr({
                completed: "true",
              });
              return;
            }
            closeHandlr({ completed: "true" });
          }}
        >
          {`${task ? "SAVE" : "ADD"} & COMPLETE`}
        </Button>
        <Button
          size="medium"
          schema="primary"
          disabled={payload.title.length === 0}
          onClick={() => {
            if (task) {
              editHandlr({
                run: true,
              });
              return;
            }
            closeHandlr({ completed: false });
          }}
        >
          {task ? "SAVE DETAILS" : "ADD TASK"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  title: state.tdpDetailsReducer.transactionDetails.title,
  uuid: state.tdpTasksReducer.uuid,
});

ModalAddEditTask.propTypes = {
  show: bool,
  toggleModalAddTaskOpen: func,
  title: string,
  uuid: string,
  onPostTaskAsThunk: func,
  onEditTaskAsThunk: func,
  toggleModalShowTask: func,
  task: oneOfType([bool, shape({})]),
  taskFilters: string,
};

ModalAddEditTask.defaultProps = {
  show: false,
  toggleModalAddTaskOpen: null,
  title: null,
  uuid: null,
  onPostTaskAsThunk: null,
  onEditTaskAsThunk: null,
  toggleModalShowTask: null,
  task: false,
  taskFilters: "",
};

export default connect(mapStateToProps, {
  onEditTaskAsThunk: editTaskAsThunk,
  onPostTaskAsThunk: postTaskAsThunk,
})(withFilters(ModalAddEditTask));
