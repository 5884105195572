/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { bool, func } from "prop-types";

import FieldLabel from "../../../../shared/v2/FieldLabel";
import { Edit } from "../../../../shared/v2/Icomoon";

const DETAILFIELD_CLASSES = {
  container: (_bool) => (_bool ? "tw-group hover:tw-cursor-pointer hover:tw-underline" : ""),
  label: (_bool) => (_bool ? "tw-group group-hover:tw-cursor-pointer" : ""),
};

const DetailFieldLabelIcon = ({ show }) =>
  show && <Edit size="custom" className="tw-w-[10px] tw-h-[10px] tw-invisible group-hover:tw-visible" />;

const DetailFieldLabel = ({ onClick, ...props }) => (
  <div
    className={`tw-flex tw-items-center tw-gap-[8px] ${DETAILFIELD_CLASSES.container(onClick)}`}
    role="button"
    tabIndex="0"
    onClick={onClick}
    aria-hidden="true"
  >
    <FieldLabel {...props} className={DETAILFIELD_CLASSES.label(onClick)} />
    <DetailFieldLabelIcon show={!!onClick} />
  </div>
);
export default DetailFieldLabel;

DetailFieldLabel.propTypes = {
  onClick: func,
};

DetailFieldLabel.defaultProps = {
  onClick: null,
};

DetailFieldLabelIcon.propTypes = {
  show: bool,
};

DetailFieldLabelIcon.defaultProps = {
  show: false,
};
