import React, { useState } from "react";
import { connect } from "react-redux";
import { bool, func, node, string } from "prop-types";

import { putDetailAsThunk } from "../actions/thunks";

import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";
import TextInput from "../../../../shared/v2/TextInput";
import defaultPropertyDetails from "../constants";
import { showDetailsFailure } from "../actions/creators";

const ModalPropertyDetail = ({
  isModalOpen,
  toggleModal,
  uuid,
  onPutDetailAsThunk,
  onShowDetailsFailure,
  additionalDetails,
}) => {
  const [detailValue, setDetailValue] = useState(null);

  const handleSave = () => {
    const value = detailValue.toLowerCase().split(" ").join("_");
    const checkNormalizedMatch = (details) => details.toLowerCase() === value;
    if (
      defaultPropertyDetails.some(checkNormalizedMatch) ||
      Object.keys(additionalDetails).some(checkNormalizedMatch)
    ) {
      onShowDetailsFailure({
        errors: [`Details ["Name ${detailValue} already exists"]`],
        title: "Error - Failed to update transaction",
      });
    } else {
      onPutDetailAsThunk({
        uuid,
        key: value, // any new values will use the standard convention
        inOption: "details",
        value: null,
        group: "additionalDetails",
      });
    }
    toggleModal((prev) => !prev);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => toggleModal((prev) => !prev)}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      closeButton
    >
      <Modal.Header
        title="Add A Property Detail"
        description="Adding a property detail from this page only adds it to this transaction. If you need this field on other transactions, you should edit your property settings!"
        className="tw-mb-32px"
        closeButton
      />
      <Modal.Body className="tw-mb-32px">
        <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
          Detail
        </div>
        <TextInput onBlur={(e) => setDetailValue(e.target.value)} />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={() => toggleModal((prev) => !prev)}>
          Cancel
        </Button>
        <Button size="medium" schema="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  additionalDetails: state.tdpDetailsReducer.additionalDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (payload) => dispatch(putDetailAsThunk(payload)),
  onShowDetailsFailure: (error) => dispatch(showDetailsFailure(error)),
});

ModalPropertyDetail.propTypes = {
  isModalOpen: bool,
  toggleModal: func,
  uuid: string,
  onPutDetailAsThunk: func,
  onShowDetailsFailure: func,
  additionalDetails: node,
};

ModalPropertyDetail.defaultProps = {
  isModalOpen: false,
  toggleModal: () => {},
  uuid: null,
  onPutDetailAsThunk: () => {},
  onShowDetailsFailure: () => {},
  additionalDetails: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPropertyDetail);
