/* eslint-disable no-underscore-dangle */

// TODO: Remove when no longer needed (e.g. when usage in haml is replaced with React)

import { createPopper } from "@popperjs/core";
import classes from "./classes.json";

const getOrCreateTooltipPortal = () => {
  let tooltipPortal = document.querySelector("#tooltip-portal");

  if (!tooltipPortal) {
    // Create portal and append into document body
    tooltipPortal = document.createElement("div");
    tooltipPortal.setAttribute("id", "tooltip-portal");
    tooltipPortal.setAttribute("class", "tw-relative");

    document.body.appendChild(tooltipPortal);
  }

  return tooltipPortal;
};

const addClassesFromString = (element, classesString) => {
  classesString
    .split(" ")
    .filter((value) => value.trim() !== "")
    .forEach((value) => element.classList.add(value.trim()));
};

const tooltipTemplate = document.createElement("template");
tooltipTemplate.innerHTML = `
  <slot name="trigger"></slot>
  <slot name="content"></slot>
`;

const defaultMultiline = "false";
const defaultMaxWidthClass = "tw-max-w-200px";
const defaultTooltipClass = classes.baseClasses;
const defaultArrowClass = classes.baseArrowClasses;
const defaultInnerClass = classes.inner.baseClasses;

const defaultPlacement = "bottom";
const availablePlacements = ["top", "right", "left", "bottom"];

const showEvents = ["mouseenter", "focus"];
const hideEvents = ["mouseleave", "blur"];

const transitionDuration = 150;

class CRMTooltip extends HTMLElement {
  static get observedAttributes() {
    return ["placement", "multiline", "deprecatedoverridemaxwidthclass"];
  }

  constructor() {
    super();

    // Event handlers that are not attached to this element need to be bound
    // if they need access to `this`.
    this._onSlotChange = this._onSlotChange.bind(this);
    this._onShowTooltip = this._onShowTooltip.bind(this);
    this._onHideTooltip = this._onHideTooltip.bind(this);
    this._onContentChanged = this._onContentChanged.bind(this);

    this.attachShadow({
      mode: "open",
    });

    this.shadowRoot.appendChild(tooltipTemplate.content.cloneNode(true));

    this._triggerSlot = this.shadowRoot.querySelector("slot[name=trigger]");
    this._contentSlot = this.shadowRoot.querySelector("slot[name=content]");

    // Create the tooltip DOM
    this._arrow = document.createElement("div");
    addClassesFromString(this._arrow, defaultArrowClass);

    this._inner = document.createElement("div");
    addClassesFromString(this._inner, `${defaultInnerClass} ${defaultMaxWidthClass}`);

    this._tooltip = document.createElement("div");
    this._tooltip.appendChild(this._arrow);
    this._tooltip.appendChild(this._inner);
    addClassesFromString(this._tooltip, defaultTooltipClass);

    this._popper = document.createElement("div");
    this._popper.classList.add("tw-z-3000");
    this._popper.style.display = "none";
    this._popper.appendChild(this._tooltip);

    getOrCreateTooltipPortal().appendChild(this._popper);

    this._triggerSlot.addEventListener("slotchange", this._onSlotChange);
    this._contentSlot.addEventListener("slotchange", this._onSlotChange);
    this._contentObserver = new MutationObserver(this._onContentChanged);
  }

  get placement() {
    return this.getAttribute("placement");
  }

  set placement(value) {
    if (!value || !availablePlacements.includes(value)) {
      this.setAttribute("placement", defaultPlacement);
      return;
    }

    this.setAttribute("placement", value);
  }

  get multiline() {
    if (this.getAttribute("multiline") === null) {
      return defaultMultiline;
    }

    return this.getAttribute("multiline");
  }

  set multiline(value) {
    if (typeof value !== "boolean") {
      return;
    }

    this.setAttribute("multiline", value);
  }

  get deprecatedOverrideMaxWidthClass() {
    if (this.getAttribute("deprecatedoverridemaxwidthclass") === null) {
      return defaultMultiline;
    }

    return this.getAttribute("deprecatedoverridemaxwidthclass");
  }

  set deprecatedOverrideMaxWidthClass(value) {
    if (!value) {
      this.setAttribute("deprecatedoverridemaxwidthclass", defaultMaxWidthClass);
      return;
    }

    this.setAttribute("deprecatedoverridemaxwidthclass", value);
  }

  get tooltipClass() {
    if (this.getAttribute("tooltipclass") === null) {
      return "";
    }

    return this.getAttribute("tooltipclass");
  }

  set tooltipClass(value) {
    if (!value) {
      this.setAttribute("tooltipclass", "");
      return;
    }

    this.setAttribute("tooltipclass", value);
  }

  get arrowClass() {
    if (this.getAttribute("arrowclass") === null) {
      return "";
    }

    return this.getAttribute("arrowclass");
  }

  set arrowClass(value) {
    if (!value) {
      this.setAttribute("arrowclass", "");
      return;
    }

    this.setAttribute("arrowclass", value);
  }

  get innerClass() {
    if (this.getAttribute("innerclass") === null) {
      return "";
    }

    return this.getAttribute("innerclass");
  }

  set innerClass(value) {
    if (!value) {
      this.setAttribute("innerclass", "");
      return;
    }

    this.setAttribute("innerclass", value);
  }

  connectedCallback() {
    this._updateInnerClasses();

    if (!this.placement) {
      // Attribute changed callback already calls internal methods to update placement and tooltip
      this.placement = defaultPlacement;
    } else {
      this._updateTooltip();
    }
  }

  disconnectedCallback() {
    this._releaseResources();
  }

  attributeChangedCallback(name) {
    switch (name) {
      case "placement":
        this._updateTooltip();
        break;

      case "multiline":
        this._updateInnerClasses();
        break;

      case "deprecatedoverridemaxwidthclass":
        this._updateInnerClasses();
        break;

      case "tooltipclass":
        this._updateTooltipClasses();
        break;

      case "arrowclass":
        this._updatePlacement();
        break;

      case "innerclass":
        this._updateInnerClasses();
        break;

      default:
        break;
    }
  }

  _onSlotChange(event) {
    switch (event.target.name) {
      case "trigger":
        this._triggerSlotChanged();
        break;
      case "content":
        this._contentSlotChanged();
        break;
      default:
        // Unsupported slot
        break;
    }
  }

  _triggerSlotChanged() {
    // If trigger is not undefined, we must re-create the popper instance from scratch
    if (this._trigger) {
      // Detach event listeners
      this._clearEventListeners(this._trigger);

      // Delete popper instance
      this._destroyPopperInstance();
    }

    if (this._triggerSlot.assignedElements().length === 0) {
      this._trigger = undefined;
      return;
    }

    [this._trigger] = this._triggerSlot.assignedElements();

    // Attach event listeners
    this._registerEventListeners(this._trigger);
  }

  _contentSlotChanged() {
    // Clear popper's inner content
    this._inner.innerHTML = "";

    // Stop watching previous content element
    this._contentObserver.disconnect();

    if (this._contentSlot.assignedElements().length === 0) {
      this._content = undefined;
      return;
    }

    [this._content] = this._contentSlot.assignedElements();

    // Move new content element into popper's inner div
    this._inner.appendChild(this._content.cloneNode(true));

    // Hide content
    this._content.style.display = "none";

    // Configure the observer to watch for content updates to reflect into the inner content
    this._contentObserver.observe(this._content, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  _onShowTooltip() {
    this._clearTimeout();

    this._popper.style.display = "block";

    if (!this._popperInstance) {
      // Create popper instance only when the tooltip is shown and only if it hasn't been created yet
      this._createPopperInstance(this._trigger, this._popper);
    }

    // Enable the revertPlacementUpdate and updatePlacement modifiers, and event listeners
    this._popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: "revertPlacementUpdate", enabled: true },
        { name: "updatePlacement", enabled: true },
        { name: "eventListeners", enabled: true },
      ],
    }));

    // Request async update
    this._popperInstance.update();
  }

  _onHideTooltip() {
    // Use `setTimeout` to mimic Bootstrap's tooltip fade effect
    this._clearTimeout();

    this._onHideTimeout = setTimeout(() => {
      this._onHideTimeout = undefined;

      this._popper.style.display = "none";

      if (!this._popperInstance) {
        return;
      }

      // Disable the revertPlacementUpdate and updatePlacement modifiers, and event listeners
      this._popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: "revertPlacementUpdate", enabled: false },
          { name: "updatePlacement", enabled: false },
          { name: "eventListeners", enabled: false },
        ],
      }));

      // Request async update
      this._popperInstance.update();
    }, transitionDuration);
  }

  _clearTimeout() {
    if (this._onHideTimeout) {
      clearTimeout(this._onHideTimeout);
      this._onHideTimeout = undefined;
    }
  }

  _onContentChanged() {
    // Since content styled as display none, we remove it before replacing
    const content = this._content.cloneNode(true);
    content.style.removeProperty("display");

    // Move new content element into popper's inner div
    this._inner.innerHTML = "";
    this._inner.appendChild(content);
  }

  _createPopperInstance(reference, popper) {
    // Create popper instance and register event listeners
    this._popperInstance = createPopper(reference, popper, {
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
        {
          name: "arrow",
          options: {
            padding: 5,
            element: this._arrow,
          },
        },
        // TODO: find a way to prevent placement update until the reference element is fully visible
        {
          name: "revertPlacementUpdate",
          enabled: true,
          phase: "main",
          options: {
            element: this,
          },
          requires: ["flip", "hide"],
          fn({ state, options }) {
            const { modifiersData, placement } = state;
            const { element } = options;

            if (modifiersData?.hide?.isReferenceHidden && placement !== element.placement) {
              // Revert placement update since the reference element is hidden
              // eslint-disable-next-line no-param-reassign
              state.placement = element.placement;
            }
          },
        },
        {
          name: "updatePlacement",
          enabled: true,
          phase: "main",
          options: {
            element: this,
          },
          requires: ["revertPlacementUpdate"],
          fn({ state, options }) {
            const { placement } = state;
            const { element } = options;
            if (placement !== element.placement) {
              element.placement = placement;

              // eslint-disable-next-line no-param-reassign
              state.reset = true;
            }
          },
        },
      ],
      placement: this.placement,
    });
  }

  _destroyPopperInstance() {
    if (!this._popperInstance) {
      return;
    }

    // Destroy popper instance
    this._popperInstance.destroy();
    this._popperInstance = undefined;
  }

  _updatePopperInstance() {
    if (!this._popperInstance) {
      return;
    }

    // Update placement via `setOptions`
    this._popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers],
      placement: this.placement,
    }));

    // Request async update
    this._popperInstance.update();
  }

  _updateTooltip() {
    // Conditionally add the popper to tooltip portal
    const tooltipPortal = getOrCreateTooltipPortal();
    if (!tooltipPortal.contains(this._popper)) {
      tooltipPortal.appendChild(this._popper);
    }

    this._updateTooltipClasses();
    this._updatePlacement();
    this._createOrUpdatePopperInstance();
  }

  _updateTooltipClasses() {
    // Clear class list
    this._tooltip.className = "";

    // Update classes that style the tooltip container
    addClassesFromString(this._tooltip, `${defaultTooltipClass} ${this.tooltipClass}`);
  }

  _updatePlacement() {
    // Clear class list
    this._arrow.className = "";

    // Update classes that style tooltip based on the placement
    addClassesFromString(
      this._arrow,
      `${defaultArrowClass} ${this.arrowClass} ${classes.placementClasses[this.placement]}`,
    );
  }

  _updateInnerClasses() {
    // Clear class list
    this._inner.className = "";

    // Update classes that style the tooltip inner container
    addClassesFromString(
      this._inner,
      `${defaultInnerClass} ${this.innerClass} ${this.deprecatedOverrideMaxWidthClass} ${
        this.multiline === "true" ? classes.inner.multiLineClasses : classes.inner.singleLineClasses
      }`,
    );
  }

  _createOrUpdatePopperInstance() {
    if (this._popperInstance) {
      this._updatePopperInstance();
      return;
    }

    // Do nothing while trigger instance is not defined
    if (!this._trigger) {
      return;
    }

    // Create popper instance
    this._createPopperInstance(this._trigger, this._popper);

    // Attach event listeners
    this._registerEventListeners(this._trigger);
  }

  _releaseResources() {
    // Clear event listeners
    this._clearEventListeners(this._trigger);

    this._destroyPopperInstance();

    // Conditionally remove the popper from tooltip portal
    const tooltipPortal = getOrCreateTooltipPortal();
    if (tooltipPortal.contains(this._popper)) {
      tooltipPortal.removeChild(this._popper);
    }
  }

  _registerEventListeners(reference) {
    if (!reference) {
      return;
    }

    // Attach event listeners
    showEvents.forEach((event) => {
      reference.addEventListener(event, this._onShowTooltip);
    });

    hideEvents.forEach((event) => {
      reference.addEventListener(event, this._onHideTooltip);
    });
  }

  _clearEventListeners(reference) {
    if (!reference) {
      return;
    }

    // Detach event listeners
    showEvents.forEach((event) => reference.removeEventListener(event, this._onShowTooltip));
    hideEvents.forEach((event) => reference.removeEventListener(event, this._onHideTooltip));

    // Stop watching
    this._contentObserver.disconnect();
  }
}

// Define crm-tooltip element only once
if (customElements.get("crm-tooltip") === undefined) {
  customElements.define("crm-tooltip", CRMTooltip);
}
