import React from "react";
import Button from "../../../../bundles/shared/v2/Button";
import { Modal } from "react-bootstrap";
import IconButton from "../../../shared/v2/IconButton";

const DeleteListingAlertModal = ({ loading, onClose, onDelete, open }) => (
  <Modal id="delete-alert-listing-modal" backdrop='static' show={open} onHide={onClose}>
    <Modal.Header>
      <Modal.Title className="tw-flex tw-justify-between tw-border-none">
        <span className="tw-text-semantic-orange-50">
          Are you sure you want to delete this Listing Alert?
        </span>
        <IconButton size="small" schema="tertiary" onClick={onClose} disabled={loading}>
          <i className="fa fa-close tw-text-11d" />
        </IconButton>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Deleting a listing alert will also delete the corresponding saved search from this contact's IDX
      account. This action cannot be undone.
    </Modal.Body>
    <Modal.Footer>
      <div className="tw-flex tw-justify-between">
        <Button schema="tertiary" onClick={onClose} disabled={loading}>
          CANCEL
        </Button>
        <Button schema="warning" className="tw-relative" onClick={onDelete} disabled={loading}>
          DELETE
          {loading && (
            <span
              className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-rounded-full"
              // tw-bg-inherit not available in v2 only v3
              style={{ backgroundColor: "inherit" }}
            >
              <i className="fa fa-spinner fa-pulse tw-text-large" />
            </span>
          )}
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default DeleteListingAlertModal;
