/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { linkSchema, currentSchema } from "./NumberedLink.styles";

const PageLink = ({ pageNumber, onClick }) => (
  <button
    type="button"
    className={linkSchema.regular}
    onClick={() => onClick(pageNumber)}
    data-cy="pagination-link"
  >
    {pageNumber}
  </button>
);

const CurrentPosition = ({ pageNumber }) => (
  <span className={currentSchema.regular} data-cy="pagination-current">
    {pageNumber}
  </span>
);

export const NumberedLink = ({ currentPage, pageNumber, onClick }) =>
  pageNumber === currentPage ? (
    <CurrentPosition pageNumber={pageNumber} />
  ) : (
    <PageLink pageNumber={pageNumber} onClick={onClick} />
  );

NumberedLink.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

CurrentPosition.propTypes = {
  pageNumber: PropTypes.number.isRequired,
};

PageLink.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NumberedLink;
