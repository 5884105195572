import React from "react";
import PropTypes from "prop-types";
import { ModalContext } from "./ModalContext";
import { MODAL_BACKDROP_CLASSES } from "./Modal.styles";

/**
 * @summary Creates the background behind the modal content
 * @description This component is meant to be rendered inside a ModalContext and provide
 * a background-color for the dropdown.
 * */

const Backdrop = ({ children, className }) => {
  const { onHide } = React.useContext(ModalContext);

  return (
    <>
      {/* ModalContext already provides the keyboard listeners so we silence the linter here */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={() => onHide()} className={`${MODAL_BACKDROP_CLASSES} ${className}`}>
        {children}
      </div>
    </>
  );
};
Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Backdrop.defaultProps = {
  children: null,
  className: "",
};

export default Backdrop;
