import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import Typography from "@shared/v2/Typography";
import TabButton from "./Blog/TabButton";
import {
  getCategories,
  getIDXConfiguration,
  getTags,
  setArchivePost,
  setSharePost,
} from "../reducers/websiteReducer/websiteReducer";
import SharePostModal from "./Post/SharePostModal";
import { useArchivePost, useSharePost } from "../reducers/websiteReducer/selectors";
import ArchivePostModal from "./Post/ArchivePostModal";

const WebsiteIndex = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const sharePost = useSharePost();
  const archivePost = useArchivePost();

  useEffect(() => {
    dispatch(getIDXConfiguration());
    dispatch(getCategories());
    dispatch(getTags());
  }, []);

  return (
    <div className="tw-h-full tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-gap-[40px] tw-px-[32px] tw-py-[8px] tw-border-b-[1px] tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-neutral-gray-10 tw-h-[57px]">
        <Typography variant="headline1">Website</Typography>
        <div className="tw-flex tw-gap-[12px]">
          <TabButton active={/^\/blog|^\/post/.test(pathname)} onClick={() => navigate("/blog")}>
            Blog
          </TabButton>
        </div>
      </div>
      <div className="tw-flex-1 tw-bg-gray-5 tw-overflow-auto">
        <Outlet />
      </div>

      <SharePostModal onClose={() => dispatch(setSharePost(null))} post={sharePost} />
      <ArchivePostModal onClose={() => dispatch(setArchivePost(null))} post={archivePost} />
    </div>
  );
};

export default WebsiteIndex;
