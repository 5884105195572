import React, { Component } from 'react';
import capitalize from 'capitalize';

export default class MarketReportFrequencyUpdatedEvent extends Component {
  constructor(props, context){
    super(props, context)

    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event
  }

  details = () => {
    let rows = [];
    const fields = this.event.timeline_fields;

    return (
      <div>
        <tr>
          Report Name: <a href={fields.report_url} target="_blank">{fields.report_name}</a>
        </tr>
        <tr>
          Scheduled Drip: {fields.drip_schedule}
        </tr>
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event market-report-timeline-event">
        <div className="row">
          <div className="col-xs-1">
            <div className="timeline-icon-circle">
              <img src="/images/CMA-icon.svg" alt="CMA Icon" />
            </div>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">
              {this.event.timeline_fields.updated_by}
            </span>
             {' updated Market Report Frequency'}
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
