import React from "react";
import PropTypes from "prop-types";

const createElement = (tagName, opts) =>
  Object.entries(opts).reduce((c, [k, v]) => {
    // eslint-disable-next-line no-param-reassign
    c[k] = v;
    return c;
  }, document.createElement(tagName));

const formAction = (href, method) => (e) => {
  if (!method) return;
  e.preventDefault();
  const form = createElement("form", { action: href, method: "post" });
  const methodInput = createElement("input", { type: "hidden", name: "_method", value: method });
  const authInput = createElement("input", {
    type: "hidden",
    name: "authenticity_token",
    value: ReactOnRails.authenticityToken(),
  });
  form.append(methodInput);
  form.append(authInput);
  document.body.append(form);
  form.submit();
};

const Popover = ({ children, className, open, ...props }) => (
  <div
    {...props}
    className={`${
      open ? "tw-absolute" : "tw-hidden"
    } tw-bg-white tw-rounded-[4px] tw-shadow-dropdown tw-top-[calc(100%+8px)] tw-right-0 after:tw-content-[''] after:tw-w-[12px] after:tw-h-[12px] after:tw-bg-white after:tw-absolute after:tw-top-[-6px] after:tw-right-[10px] after:tw-rotate-45 ${className}`}
  >
    {children}
  </div>
);

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
};

Popover.defaultProps = {
  className: "",
  open: false,
};

const PopoverLink = ({ href, icon: Icon, method, title, ...props }) => (
  <li className="tw-rounded-md tw-list-none tw-overflow-hidden">
    <a
      {...props}
      className="tw-flex tw-items-center hover:tw-no-underline hover:tw-bg-neutral-gray-5 focus:tw-no-underline focus:tw-bg-neutral-gray-5 tw-gap-[8px] tw-p-[8px] tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-[1px] tw-border-neutral-gray-10"
      href={href}
      onClick={formAction(href, method)}
    >
      <Icon size="xl" /> <span>{title}</span>
    </a>
  </li>
);

PopoverLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  method: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PopoverLink.defaultProps = {
  method: null,
};

export default Popover;
export { PopoverLink };
