import React from "react";
import Header from "./components/Header";
import Results from "./components/Results";

export default () => (
  <div className="tw-bg-white tw-h-full tw-overflow-y-auto tw-px-[16px] tw-pb-[16px]">
    <Header />
    <Results />
  </div>
);
