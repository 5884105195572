export const exampleTransactionData = {
  estimated: {
    closePrice: {
      value: "50,000.10",
    },
    commission: {
      type: "percent",
      value: "10",
    },
    netCommission: "30,000.25",
    grossCommission: "33,000.25",
    subtotal: "35,000.50",
  },
  actual: {
    closePrice: {
      value: "63,000.00",
    },
    commission: {
      type: "percent",
      value: "25",
    },
    netCommission: "31,500.60",
    grossCommission: "33,500.60",
    subtotal: "60,000.50",
  },
  hasFinancialData: true,
  isActualGCI: true,
  notes:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
};

export const emptyTransactionData = {
  estimated: {
    closePrice: {
      value: null,
    },
    commission: {
      type: "percent",
      value: null,
    },
    netCommission: null,
    grossCommission: null,
    subtotal: null,
  },
  actual: {
    closePrice: {
      value: null,
    },
    commission: {
      type: "percent",
      value: null,
    },
    netCommission: null,
    grossCommission: null,
    subtotal: null,
  },
  hasFinancialData: false,
  isActualGCI: true,
  notes: null,
  lineItems: [],
};
