import { schemaGenerator } from "../utils";

const dictionary = {
  default: {
    default: [
      "tw-text-neutral-gray-50",
      "tw-font-semibold",
      "tw-rounded-[30px]",
      "tw-border-[1.5px]",
      "tw-border-solid",
      "tw-border-transparent",
    ],
    peerChecked: [
      "peer-checked:tw-text-theme-text-toggle-color-checked",
      "peer-checked:tw-border-theme-text-toggle-border-checked",
    ],
    peerFocusVisible: ["peer-focus-visible:tw-outline-theme"],
  },
  small: {
    default: ["tw-font-12d", "tw-px-[12px]", "tw-py-[2px]"],
  },
  medium: {
    default: ["tw-font-14d", "tw-px-[16px]", "tw-py-[8px]"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
