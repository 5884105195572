import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Dropdown } from "../../../shared/v1";

const RolesRow = ({
  allAssignableAgents,
  allAssignableRoles,
  availableRoles,
  handleAddAgent,
  handleAddRole,
  handleRemoveAgent,
  selectedTeamMembers,
}) => {
  const teamMemberOptions = useMemo(
    () =>
      allAssignableAgents.map((agent) => ({
        label: agent.name,
        value: agent.agent_id,
      })),
    [allAssignableAgents],
  );
  const allRoleOptions = useMemo(
    () => allAssignableRoles.map(({ id, title }) => ({ label: title, value: id })),
    [allAssignableRoles],
  );
  const roleOptions = useMemo(
    () =>
      availableRoles
        .map((role) => ({
          label: role.title,
          value: role.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [availableRoles],
  );

  return selectedTeamMembers.map((selectedTeamMember, i) => {
    if (selectedTeamMember.destroy) return null;
    const selectedOption = allRoleOptions?.find((option) => option.value === selectedTeamMember.role_id);
    return (
      <Row className="tw-flex tw-ml-[-22px] tw-mb-16px" key={i} id="roles-row-wrapper">
        <Col className="form-group tw-ml-24px tw-w-[240px] tw-h-36px">
          <label className="tw-text-14px tw-leading-20px tw-font-semibold tw-text-gray-500 ">
            Team Member
          </label>
          <div className="tw-w-full tw-pt-4px">
            <Dropdown
              isSearchable
              isClearable={false}
              placeholder="Select a team member"
              value={selectedTeamMember?.agent_id || null}
              options={teamMemberOptions}
              onChange={(e) => handleAddAgent(e.value, i)}
              isDisabled={selectedTeamMember?.id ? true : false}
              variant="outlined"
              variantOverrides={{
                disableBorderRadius: true,
                showIndicator: !selectedTeamMember?.id,
                singleValueColor: "#666",
              }}
            />
          </div>
        </Col>
        <Col
          className="form-group tw-ml-24px tw-w-[240px] tw-h-36px"
          id={selectedTeamMember?.role_id && "roles-row-dropdown"}
        >
          <label className="tw-text-14px tw-leading-20px tw-font-semibold tw-text-gray-500 ">Role</label>
          <div className="tw-w-full tw-pt-4px">
            <Dropdown
              isSearchable
              isClearable={false}
              placeholder="Select a Role"
              value={selectedOption || null}
              options={selectedOption ? [selectedOption, ...roleOptions] : roleOptions}
              onChange={(e) => handleAddRole(e.value, i)}
              variant="outlined"
              variantOverrides={{
                disableBorderRadius: true,
                showIndicator: true,
                singleValueColor: "#666",
              }}
            />
          </div>
        </Col>
        <div
          className="tw-w-24px tw-h-24px tw-mt-34px tw-ml-10px tw-pt-2px tw-pl-6px tw-bg-gray-10"
          style={{ borderRadius: "50%" }}
        >
          <i
            id="delete-button"
            className="fa fa-trash tw-cursor-pointer"
            onClick={() => handleRemoveAgent(selectedTeamMember.agent_id, selectedTeamMember.role_id)}
          />
        </div>
      </Row>
    );
  });
};

export default RolesRow;
