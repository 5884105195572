import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import { widthBar, iff } from "../actions/helpers";

const Bars = ({ listingViews }) => (
  <>
    {listingViews?.views?.total && listingViews.views.total > 0 && listingViews?.views?.breakdown ? (
      <>
        {Object.entries(listingViews.views.breakdown).map((item, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={`tw-float-left tw-h-[13.49px] 
              ${item[0] === "brivity" ? "tw-bg-teal" : iff(item[0] === "zillow", "tw-bg-color-1277e1")}`}
            style={{
              width: `${widthBar(item[1], listingViews.views.total)}%`,
            }}
          />
        ))}
      </>
    ) : (
      <div className="tw-float-left tw-h-[13.49px] tw-w-full tw-bg-gray-lightest" />
    )}
  </>
);

Bars.propTypes = {
  listingViews: Proptypes.shape({
    views: Proptypes.shape({
      total: Proptypes.number,
      breakdown: Proptypes.shape({
        brivity: Proptypes.number,
        zillow: Proptypes.number,
      }),
    }),
  }),
};
Bars.defaultProps = {
  listingViews: {
    views: {
      total: 0,
      breakdown: {
        brivity: 0,
        zillow: 0,
      },
    },
  },
};

const mapStateToProps = (state) => ({
  listingViews: state.tdpMarketingReducer.listingViews,
});
export default connect(mapStateToProps)(Bars);
