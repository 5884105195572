import React from "react";

const ZillowLogo = () => {
  return (
    <svg
      className="!tw-overflow-visible"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="currentColor"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.93785 3.80145C7.99313 3.78918 8.0177 3.80758 8.05124 3.84437C8.24164 4.05571 8.85015 4.79776 9.01551 5.00014C9.04622 5.03693 9.02496 5.07703 9.00039 5.09826C7.77203 6.05495 6.40523 7.41026 5.64365 8.37922C5.62853 8.40092 5.64081 8.40092 5.6531 8.39761C6.97973 7.82728 10.0965 6.91635 11.5001 6.66208V4.88692L6.00649 0.547852L0.503418 4.88362V6.82436C2.20754 5.81295 6.15059 4.24583 7.93785 3.80145Z" />
      <path d="M3.4665 11.259C3.42067 11.2835 3.38335 11.2774 3.3465 11.2406L2.32081 10.0202C2.29341 9.98624 2.2901 9.96784 2.32696 9.91595C3.11972 8.75688 4.73786 6.95059 5.76968 6.18731C5.78811 6.17505 5.78197 6.15948 5.76023 6.16892C4.68541 6.51847 1.63009 7.83086 0.5 8.46865V12.6138H11.4972V8.62197C9.99856 8.87623 5.50889 10.2103 3.4665 11.259Z" />
    </svg>
  );
};

export default ZillowLogo;
