import React from "react";

import Dropdown from "../../../shared/v2/Dropdown/Dropdown";

const DateDropdown = ({monthsBack, options, onDateChosen}) => {
  const onOptionSelected = ({value}) => {
    onDateChosen(value);
  };

  return (
    <Dropdown
      className="tw-whitespace-nowrap"
      value={monthsBack}
      options={options}
      onChange={onOptionSelected}
      components={{
        SingleValue: DateValue,
        ValueContainer: DateContainer,
        Control: DateControl,
        Menu: DateMenu
      }}
    />
  )
};

export default DateDropdown;

const DateControl = ({ componentProps, children }) => (
  <Dropdown.Control
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="tw-flex tw-justify-center tw-cursor-pointer tw-items-center tw-gap-[7px]"
  >
    {children}
  </Dropdown.Control>
);

const DateContainer = ({ componentProps, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.ValueContainer
    {...componentProps}
    className="tw-flex tw-items-center"
  >
    {children}
  </Dropdown.ValueContainer>
);

const DateValue = ({ componentProps, children }) => (
  <Dropdown.SingleValue
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="tw-text-14px tw-font-bold tw-text-gray-75"
  >
    {children}
  </Dropdown.SingleValue>
);

const DateMenu = ({ componentProps, children }) => (
  <Dropdown.Menu
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    preserveStyle
    className="!tw-w-[160px] !tw-z-30"
  >
    {children}
  </Dropdown.Menu>
);
