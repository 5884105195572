import React from "react";
import { CommentRegularV6, CommentSlashRegularV6, CommentXmarkRegularV6 } from "@icons";
import twilioErrorCodes from "@shared/v2/twilioErrorCodes";
import PreTooltipContent from "../../../../Inbox/components/PreTooltipContent";

const SMS_STATUS_ICONS = {
  valid: CommentRegularV6,
  invalid: CommentSlashRegularV6,
  unsubscribed: CommentXmarkRegularV6,
};

const SMS_STATUS_ICON_MODIFIERS = {
  valid: "tw-text-neutral-gray-75",
  invalid: "tw-text-semantic-yellow-100",
  unsubscribed: "tw-text-brivity-coral-110",
};

export const SMS_STATUS_DESCRIPTIONS = {
  valid: "Can Be Texted",
  invalid: "Cannot Be Texted",
  unsubscribed: "Opted-out",
};

export const SMS_STATUS_TOOLTIPS = {
  valid: "Can Be Texted",
  invalid:
    "This number may be unreachable via text message (landline or VOIP)\nYour account may be liable for SMS failures.",
  unsubscribed: "This number has opted out of text communication and cannot be texted",
};

const smsStatusIconForStatus = (status, errorCode) => {
  const UsedIcon = SMS_STATUS_ICONS[status];
  return {
    icon: ({ size = "l" }) => <UsedIcon className={SMS_STATUS_ICON_MODIFIERS[status]} size={size} />,
    tooltip: (
      <PreTooltipContent>{twilioErrorCodes[errorCode] || SMS_STATUS_TOOLTIPS[status]}</PreTooltipContent>
    ),
    status,
  };
};

export default smsStatusIconForStatus;
