import React from "react";
import { filter } from "lodash";
import UserListing from "./UserListing";
import { Dropdown } from "../../shared/v1";
import Button from "./Button";

const UserOption = (props) => {
  const {
    data: { label, value },
    selectProps: { selectedValues, onSelect, isMultiSelect },
    isFocused,
  } = props;

  // flag if the option is selected from the user array
  const isSelected = filter(selectedValues, (u) => u.uuid === value.uuid).length > 0;
  return (
    <Button
      type="default"
      onClick={(evt) => {
        evt.preventDefault();
        onSelect(value);
      }}
      className={`tw-flex tw-items-center tw-w-full tw-py-2 tw-px-4${isFocused ? " tw-bg-gray-20" : ""}`}
    >
      {isMultiSelect && <i className={`tw-pr-4 fa fa-${isSelected ? "check-square-o" : "square-o"}`} />}
      <UserListing name={label} imageUrl={value.image_url} />
    </Button>
  );
};

const UserSelect = ({
  className,
  options = [],
  value = null,
  isLoading = false,
  isMultiSelect = false,
  onSelect,
  ...props
}) => (
  <div data-cy={props["data-cy"]} className="tw-relative">
    <Dropdown
      value={null}
      options={options}
      isLoading={isLoading}
      isClearable={false}
      isSearchable
      isMultiSelect={isMultiSelect}
      placeholder="Search or select users"
      components={{ Option: UserOption }}
      selectedValues={value}
      onSelect={onSelect}
    />
  </div>
);

export default UserSelect;
