import React from "react";
import { string } from "prop-types";

import DetailLinkField from "../shared/DetailLinkField/DetailLinkField";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { CLIENTDETAILS_NAME, CLIENTDETAILS_PDPLINK } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import { DETAIL_FIELD_VALUE_LINK } from "../shared/styles";

const ClientNameField = ({ name, pdpLink }) => (
  <DetailLinkField
    id="refClientName"
    label="Client Name"
    dataCy="refClientName"
    fieldValue={name}
    txtLink={pdpLink}
    txtBtnClassName={DETAIL_FIELD_VALUE_LINK}
  />
);

const withReduxState = tdpDetails({
  WrappedComponent: ClientNameField,
  states: [CLIENTDETAILS_NAME, CLIENTDETAILS_PDPLINK],
});

export default withReduxState;

ClientNameField.propTypes = {
  name: string,
  pdpLink: string,
};

ClientNameField.defaultProps = {
  name: null,
  pdpLink: null,
};
