import React from "react";
import PropTypes from "prop-types";
import RingChart from "../../../shared/v2/RingChart/RingChart";
import Dot from "../BusinessDashboard/Dot";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import useClosingsBreakdown from "./services/useClosingsBreakdown";
import Loader from "../../../Goals/components/Loader";
import format from "../../../shared/formatters";
import colors from "./helpers/colors";
import Error from "./Error";

const Percentage = ({ children }) => (
  <span className="tw-ml-[4px] tw-text-12d tw-text-gray-50">({children})</span>
);

Percentage.propTypes = {
  children: PropTypes.node.isRequired,
};

const BreakdownRow = ({ color, label, data, ...props }) => (
  <tr {...props}>
    <td className="tw-bg-white tw-sticky tw-left-0 tw-pr-[4px]">
      {color && <Dot size="m" className={color} />} {label}
    </td>
    <td className="tw-text-start tw-px-[8px]">
      {data.units || 0}
      {label !== "Total" && <Percentage>{data.unitsPercent}%</Percentage>}
    </td>
    <td className="tw-text-end tw-px-[8px]">
      {typeof data.volume === "undefined" ? "—" : format.currency(data.volume)}
    </td>
    <td className="tw-text-end tw-px-[8px]">{format.currency(data.gci || 0)}</td>

    <td className="tw-text-end tw-px-[8px]">
      {label !== "Total" && (
        <>{typeof data.avgClosePrice === "undefined" ? "—" : format.currency(data.avgClosePrice)}</>
      )}
    </td>
    <td className="tw-text-end tw-px-[8px]">
      {label !== "Total" &&
        (typeof data.avgCommission === "undefined" ? (
          "—"
        ) : (
          <>
            {format.currency(data.avgCommission)}<Percentage>{data.avgCommissionPercent}%</Percentage>
          </>
        ))}
    </td>
  </tr>
);

BreakdownRow.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
};
BreakdownRow.defaultProps = {
  color: undefined,
};

const ClosingsBreakdown = ({ agentUuid, year }) => {
  const {
    data: { buyer, seller, tenant, landlord, referral, total },
    error,
    loading,
  } = useClosingsBreakdown({ agentUuid, year });

  return (
    <Section className="tw-gap-[16px]">
      <SectionLabel>Closings Breakdown</SectionLabel>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-gap-[16px]">
        <div className="tw-min-w-[104px]">
          <RingChart
            data={[
              { color: "#FFA826", value: seller.units / total.units },
              { color: colors.color1.default, value: (seller.units + buyer.units) / total.units },
              { color: "#FA5D57", value: (seller.units + buyer.units + landlord.units) / total.units },
              {
                color: "#63D689",
                value: (seller.units + buyer.units + landlord.units + tenant.units) / total.units,
              },
              {
                color: "#FA64AF",
                value:
                  (seller.units + buyer.units + landlord.units + tenant.units + referral.units) / total.units,
              },
            ].filter(({ value }) => value > 0)}
            size={104}
            variant="segmented"
            title="Total"
            label={total.units}
          />
        </div>
        <div className="tw-flex-grow tw-overflow-auto">
          <table className="tw-w-full tw-whitespace-nowrap tw-border-separate tw-border-spacing-y-[8px]">
            <thead>
              <tr className="tw-text-neutral-gray-50">
                <th className="tw-bg-white tw-sticky tw-left-0 tw-pr-[4px]">Type</th>
                <th className="tw-text-center tw-px-[8px]">Count</th>
                <th className="tw-text-center tw-px-[8px]">Volume</th>
                <th className="tw-text-center tw-px-[8px]">{agentUuid ? "Net Income" : "GCI"}</th>
                <th className="tw-text-center tw-px-[8px]">Avg. Close Price</th>
                <th className="tw-text-center tw-px-[8px]">Avg. Commission</th>
              </tr>
            </thead>
            <tbody>
              <BreakdownRow color="tw-bg-semantic-yellow-100" data={seller} label="Seller" />
              <BreakdownRow
                color={colors.theme === "brivity" ? "tw-bg-brivity-blue-100" : "tw-bg-semantic-blue-100"}
                data={buyer}
                label="Buyer"
              />
              <BreakdownRow color="tw-bg-semantic-red-50" data={landlord} label="Landlord" />
              <BreakdownRow color="tw-bg-semantic-green-50" data={tenant} label="Tenant" />
              <BreakdownRow color="tw-bg-semantic-pink-50" data={referral} label="Referral" />
            </tbody>
            <tfoot>
              <BreakdownRow className="tw-bg-tinted-gray-50 tw-font-semibold" data={total} label="Total" />
            </tfoot>
          </table>
        </div>
      </div>
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </Section>
  );
};

ClosingsBreakdown.propTypes = {
  agentUuid: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

ClosingsBreakdown.defaultProps = {
  agentUuid: undefined,
};

export default ClosingsBreakdown;
