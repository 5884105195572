import React, { useEffect, useState } from "react";
import { isEqual, filter } from "lodash";
import { useDebounce as useDebounceFn } from "../shared/hookHelpers";

export const getFirstLettersOfString = (letterString = "", partCount = 2) => {
  const includeSpaces = false;
  // break the string to an array on spaces
  const parts = letterString.split(" ");
  // determine the max number of letters to return
  const maxParts = partCount > parts.length ? parts.length : partCount;

  // set the return string
  let retString = "";
  if (maxParts > 0) {
    // loop through the avail. parts
    let loopIdx = 0;
    for (loopIdx; loopIdx < maxParts; loopIdx++) {
      // add the first letter of the string part to the retrun string
      retString += parts[loopIdx][0] + (includeSpaces ? " " : "");
    }
    // remove trailing spaces
    retString.trim();
  }
  return retString.toUpperCase();
};

export const useDebounce = useDebounceFn;

export const useSetViewWrapper = () => {
  return useEffect(() => {
    // get the view wrapper
    const el = document.getElementsByClassName("content-wrapper")[0],
      cname = "fp-reset-wrap";
    if (el) {
      // add custom view class
      el.classList.add(cname);
      return () => {
        // remove custom view class
        el.classList.remove(cname);
      };
    }
  }, []);
};

export const useLoadImage = (src, setFn) => {
  return useEffect(() => {
    if (!src) {
      return;
    }
    // Load the passed in image src
    let img = document.createElement("img");
    img.onload = () => {
      setFn(src);
    };
    img.onerror = () => {
      console.log("can not load image", src);
    };
    img.src = src;
  }, [src]);
};

export const useToggleUsers = (users, setFn) => {
  return (agent) => {
    // check if it exists
    const existing = users ? [...users] : [];
    const fnd = filter(existing, (u) => u.uuid == agent.uuid);
    if (fnd.length == 0) {
      // add it if not found
      setFn([...existing, agent]);
    } else {
      // remove the user
      const removed = filter(existing, (u) => u.uuid != agent.uuid);
      setFn(removed);
    }
  };
};

export const useCanSaveModel = (orig, model, hasDuplicate) => {
  const isDirty = !isEqual(orig, model);
  // Flag if the model is complete enough to save
  return (
    model.name > "" &&
    !hasDuplicate &&
    model.accessible_by_users &&
    model.accessible_by_users.length > 0 &&
    model.display_agent &&
    isDirty
  );
};

export const useSetModel = (model, setModel) => {
  return useEffect(() => {
    setModel(model ? { ...model } : {});
  }, [model]);
};

export const useSetUserOptions = (collection, setFn) => {
  return useEffect(() => {
    if (collection && collection.length) {
      setFn(
        collection.map((u) => ({
          label: u.name,
          value: { ...u },
        })),
      );
    }
  }, [collection]);
};

export const useDuplicateCheck = (term, checkFn) => {
  return useEffect(() => {
    checkFn(term);
  }, [term]);
};

export const useInitUsers = (loadFn) => {
  return useEffect(() => {
    loadFn();
  }, []);
};

export const useSetValue = (setFn) => {
  // Change the model based on key - removeKey param will delete the objects key/val pair
  return (key, val, removeKey = false) => {
    setFn((p) => {
      p[key] = val;
      if (removeKey) {
        delete p[key];
      }
      return { ...p };
    });
  };
};
