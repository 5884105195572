import React from "react";
import { bool, oneOfType, string, number, func } from "prop-types";

import TextInput from "../../../shared/v2/TextInput";

const ModalField = ({ label, isRequired, value, onChange }) => (
  <>
    <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
      {label}
      {isRequired && <span className="tw-text-coral">*</span>}
    </div>
    <TextInput onChange={onChange} value={value} className="tw-mb-32px" data-cy="new-link-title" />
  </>
);

export default ModalField;

ModalField.propTypes = {
  label: string,
  isRequired: bool,
  value: oneOfType([string, number]),
  onChange: func,
};

ModalField.defaultProps = {
  label: "Custom Detail",
  isRequired: true,
  value: "",
  onChange: () => {},
};
