import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import ReportingGoals from "./ReportingGoals";

const ReportingGoalsApp = ({ agents }) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <ReportingGoals agents={agents} />
    </Provider>
  );
};

ReportingGoalsApp.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ReportingGoalsApp;
