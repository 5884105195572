/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CloudArrowUpSolidV6 = (props) => (
  <Icon {...props} name="Cloud Arrow Up Solid V6">
    <path d="M3.6 13.6a3.6 3.6 0 0 1-1.195-6.997 3.999 3.999 0 0 1 7.463-2.197c.38-.277.84-.405 1.332-.405 1.325 0 2.4 1.053 2.4 2.4 0 .305-.058.595-.16.865a3.2 3.2 0 0 1-.64 6.335H3.6zm1.975-5.425c-.232.235-.232.615 0 .828.235.255.615.255.827 0l.998-.955V11.4c0 .333.268.6.6.6s.6-.267.6-.6V8.048l.975.955c.235.255.615.255.828 0 .255-.213.255-.593 0-.828l-2-2c-.213-.232-.593-.232-.828 0l-2 2z" />
  </Icon>
);

export default CloudArrowUpSolidV6;
