import React, { useMemo, useState } from "react";
import Dropdown from "@shared/v2/Dropdown";
import LeadQuickStats from "./LeadQuickStats";

import LeadGenActivities from "./LeadGenActivities/LeadGenActivities";
import RecentActivity from "./RecentActivity/RecentActivity";
import { useAgentList, useDateRange } from "../../reducers/dashboardReducer/selectors";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import CustomCheckboxOption from "./CustomCheckboxOption";

const Dashboard = () => {
  const currentUser = useCurrentUser();
  const agentList = useAgentList();
  const dateRange = useDateRange();
  const [activeTab, setActiveTab] = useState("timelines");
  const [selectedUsers, setSelectedUsers] = useState([agentList[0]]);
  const userUuids = useMemo(() => selectedUsers.map((u) => u.value), [selectedUsers]);

  return (
    <>
      <div
        data-cy="dashboard-header"
        className="tw-bg-white tw-sticky tw-top-0 tw-z-2 tw-shadow tw-flex tw-justify-between tw-items-center tw-px-[20px] tw-py-[12px]"
      >
        <h2 className="tw-m-0">Dashboard</h2>
        {currentUser.account.isAdmin && (
          <Dropdown
            containerClassName="tw-min-w-[200px] tw-ml-[8px]"
            components={{
              Option: (props) => (
                <CustomCheckboxOption
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  onClick={(agent, checked) => {
                    if (agent.value === "all") {
                      setSelectedUsers(checked ? [agent] : []);
                    } else {
                      setSelectedUsers((currentAgents) => {
                        if (checked) return [...currentAgents.filter((a) => a.value !== "all"), agent];
                        return currentAgents.length > 1
                          ? currentAgents.filter((a) => a.value !== agent.value)
                          : [agentList[0]];
                      });
                    }
                  }}
                  // eslint-disable-next-line
                  showDivider={props.data.value === "all"}
                />
              ),
            }}
            placeholder={
              <span className="tw-text-gray-base">
                {selectedUsers?.[0].label}
                {selectedUsers.length > 1 ? ` +${selectedUsers.length - 1}` : ""}
              </span>
            }
            options={agentList}
            value={selectedUsers}
            isMulti
            closeMenuOnSelect={false}
            isClearable={false}
            multiShouldRenderBelowSelect={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
          />
        )}
      </div>
      <div className="tw-bg-neutral-gray-5 tw-pt-[12px] tw-pb-[48px] tw-px-[12px]">
        <div
          data-cy="dashboard-content"
          className="tw-max-w-[1320px] tw-mx-auto tw-flex tw-flex-col tw-gap-[16px] tw-bg-neutral-gray-5 tw-pb-[24px]"
        >
          <LeadQuickStats dateRange={dateRange} userUuids={userUuids} />
          <div className="tw-flex tw-flex-wrap tw-items-start tw-gap-[16px]">
            <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-[16px] tw-basis-[55%]">
              <RecentActivity userUuids={userUuids} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className="tw-flex tw-flex-col tw-items-stretch tw-flex-1 tw-gap-[16px] tw-basis-[35%]">
              <LeadGenActivities userUuids={userUuids} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
