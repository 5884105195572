import React from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import "chart.js/auto";
import { Bar, getDatasetAtEvent } from "react-chartjs-2";
import Banner from "@shared/v2/Banner";
import { TriangleExclamationSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import * as actions from "../../actions/personDetailActionCreators";
import "./activity.scss";
import countBy from "lodash/countBy";
import { Dropdown } from "../../../shared/v1";
import { buildTopFive, buildQuartileData, options, sortValues } from "./ActivityHelpers";
import { BARCOLORS, COLORLABELCLASSES } from "./Colors";
import Properties from "./Properties";
import PropertyFilters from "./PropertyFilters";
import ButtonDropDown from "../ButtonDropDown";

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      stats: [],
      primaryFilter: "price",
      secondaryFilter: null,
      secondaryData: [],
      showSecondary: false,
      titleText: "",
      chartData: {
        labels: [],
        datasets: [],
      },
      isFavorited: this.props.isFavorite,
      sortBy: "most_recent",
      errorFetchingData: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get(`${this.props.idxDataUri}/api/v1/idx_data/${this.props.uuid}`, {
        headers: {
          Authorization: this.props.idxDataToken,
        },
      })
      .then((res) => {
        this.setState({ stats: res.data.stats });
        this.buildDatasets(this.state.primaryFilter, res.data.stats, true);
        this.sortStats(this.state.sortBy);
      })
      .catch((err) => {
        this.setState({ errorFetchingData: true, stats: [] });
      });
  }

  buildDatasets(filterKey, data, usePrimaryTitle) {
    const sortedArray = sortValues(filterKey, data);

    if (filterKey == "price") {
      this.quartileRanges(filterKey, sortedArray, usePrimaryTitle);
    } else {
      this.getTopFive(filterKey, sortedArray, usePrimaryTitle);
    }
  }

  getTopFive(filterKey, sortedArray, usePrimaryTitle) {
    const quantities = countBy(sortedArray, filterKey);
    const arrayed = Object.keys(quantities).map((val) => {
      return { [val]: quantities[val] };
    });
    let labelDescriptor, sorted;

    if (filterKey == "bedrooms" || filterKey == "bathrooms") {
      labelDescriptor = filterKey == "bedrooms" ? "bed" : "bath";
      sorted = arrayed.sort((a, b) => {
        return Object.keys(a)[0] == Object.keys(b)[0] ? 0 : +(Object.keys(a)[0] > Object.keys(b)[0]) || -1;
      });
    } else {
      sorted = arrayed.sort((a, b) => {
        return Object.values(b)[0] == Object.values(a)[0]
          ? 0
          : +(Object.values(b)[0] > Object.values(a)[0]) || -1;
      });
    }

    if (usePrimaryTitle) {
      this.setState({ titleText: `Showing ${sortedArray.length} properties sorted by ${filterKey}` });
    }

    const chartData = buildTopFive(
      filterKey,
      sorted,
      sortedArray,
      labelDescriptor,
      BARCOLORS,
      COLORLABELCLASSES,
    );
    this.setState({ chartData });
  }

  quartileRanges(filterKey, sortedArray, usePrimaryTitle) {
    let q1, q3, iqr, maxAllowable, minAllowable, normalAndOutliers, rangeMultiplier;

    if (usePrimaryTitle) {
      this.setState({ titleText: `Showing ${sortedArray.length} properties in all price ranges` });
    }

    if (sortedArray.length > 6) {
      //find quartiles
      if ((sortedArray.length / 4) % 1 === 0) {
        q1 =
          (1 / 2) *
          (sortedArray[sortedArray.length / 4][filterKey] +
            sortedArray[sortedArray.length / 4 + 1][filterKey]);
        q3 =
          (1 / 2) *
          (sortedArray[sortedArray.length * (3 / 4)][filterKey] +
            sortedArray[sortedArray.length * (3 / 4) + 1][filterKey]);
      } else {
        q1 = sortedArray[Math.floor(sortedArray.length / 4 + 1)][filterKey];
        q3 =
          sortedArray.length == 7
            ? sortedArray[6][filterKey]
            : sortedArray[Math.ceil(sortedArray.length * (3 / 4) + 1)][filterKey];
      }

      iqr = q3 - q1;
      // High outliers are anything beyond the 3rd quartile + 1.5 * the inter-quartile range (IQR)
      // Low outliers are anything beneath the 1st quartile - 1.5 * IQR
      // (most common method according to http://mathworld.wolfram.com/Outlier.html)
      maxAllowable = q3 + iqr * 1.5;
      minAllowable = q1 - iqr * 1.5;

      // normal is the 0 element of the matrix, outliers or "other" is 1
      normalAndOutliers = [[], []];
      sortedArray.forEach((value) => {
        if (value[filterKey] >= minAllowable && value[filterKey] <= maxAllowable) {
          normalAndOutliers[0].push(value);
        } else {
          normalAndOutliers[1].push(value);
        }
      });

      rangeMultiplier = (maxAllowable - minAllowable) / 5;
    } else {
      this.getTopFive(filterKey, sortedArray, usePrimaryTitle);
      return true;
    }

    const chartData = buildQuartileData(
      filterKey,
      normalAndOutliers,
      rangeMultiplier,
      BARCOLORS,
      COLORLABELCLASSES,
    );
    this.setState({ chartData });
  }

  filterPrimary(primaryKey) {
    this.setState({ primaryFilter: primaryKey });
    this.buildDatasets(primaryKey, this.state.stats, this.state.titleText, true);
    this.sortStats(this.state.sortBy);
  }

  filterSecondary(secondaryKey) {
    this.buildSecondaryData(this.state.secondaryData, secondaryKey, this.state.titleText);
  }

  determineInitialSecondary() {
    if (this.state.primaryFilter == "price") {
      return "bedrooms";
    } else {
      return "price";
    }
  }

  startSecondaryData(secondaryData, secondaryKey, title) {
    !this.state.showSecondary && this.buildSecondaryData(secondaryData, secondaryKey, title);
  }

  buildSecondaryData(secondaryData, secondaryKey, title) {
    this.setState({ secondaryData, secondaryFilter: secondaryKey, showSecondary: true, titleText: title });
    this.buildDatasets(secondaryKey, secondaryData, false);
  }

  closeSecondary = () => {
    this.setState({ showSecondary: false, titleText: this.state.titleText });
    this.filterPrimary(this.state.primaryFilter);
  };

  toggleButtons = () => {
    this.setState((prevState) => ({ isFavorited: !prevState.isFavorited }));
  };

  filteredProperties = () => {
    if (this.state.isFavorited) {
      return this.state.stats.filter((property) => property.favorite === this.state.isFavorited);
    }
    return this.state.stats;
  };

  favoriteCount = () => this.state.stats.filter((property) => property.favorite === true).length;

  handleSelectPropertyCriteria = (option) => {
    this.filterPrimary(option.value, this.state.stats);
  };

  handleSelectSortBy = (option) => {
    this.setState({ sortBy: option.value }, () => {
      this.sortStats(option.value);
    });
  };

  sortStats = (value) => {
    let stats = [...this.state.stats];
    let sortedStats = stats.sort((a, b) => {
      if (value === "most_recent") {
        return a.most_recent_view < b.most_recent_view ? 1 : -1;
      } else if (value === "oldest") {
        return a.most_recent_view > b.most_recent_view ? 1 : -1;
      } else if (value === "most_views") {
        return a.history_ext.length < b.history_ext.length ? 1 : -1;
      }
    });

    this.setState({ stats: sortedStats });
  };

  statsOrSpinner = (stats) =>
    stats.length > 0 ? (
      <Properties stats={this.filteredProperties()} />
    ) : (
      <div className="tw-flex tw-flex-col tw-gap-24px tw-items-center">
        <div className="tw-text-18d tw-font-bold">
          No Web Activity
        </div>
        <div className="tw-text-14d tw-w-[489px] tw-text-center">This lead has not viewed or favorited any listings on your Brivity website. You can set them up on a Listing Alert to increase web activity!</div>
        <Button
          size='medium'
          schema='primary'
          onClick={() => {
            this.props.closeModal()
            this.props.openAddListingAlertModal()
          }}>
          CREATE LISTING ALERT
        </Button>
      </div>
    );

  contactSupport = () => (
    <Banner show>
      <div className="tw-text-14d tw-flex tw-items-center tw-gap-[16px]">
        <TriangleExclamationSolidV6 size='l' className='tw-text-[#E5342E]' />
        <span className="tw-text-[#A81E19]">
          An error occurred while getting the web activity. Please try again later.
        </span>
      </div>
    </Banner>
  )

  render() {
    const filterValues = [
      {
        label: "Price",
        value: "price",
        disabled: this.state.primaryFilter == "price" && this.state.showSecondary == true,
      },
      // {label: 'Type', value: 'type'},
      {
        label: "Beds",
        value: "bedrooms",
        disabled: this.state.primaryFilter == "bedrooms" && this.state.showSecondary == true,
      },
      {
        label: "Baths",
        value: "bathrooms",
        disabled: this.state.primaryFilter == "bathrooms" && this.state.showSecondary == true,
      },
      // {label: 'SqFt', value: 'sqft'},
      {
        label: "City",
        value: "city",
        disabled: this.state.primaryFilter == "city" && this.state.showSecondary == true,
      },
      // {label: 'Area', value: 'area'},
      // {label: 'Neighborhood', value: 'neighborhood'},
      {
        label: "Zip Code",
        value: "zip",
        disabled: this.state.primaryFilter == "zip" && this.state.showSecondary == true,
      },
    ];
    const { stats, isFavorited, sortBy, titleText, errorFetchingData } = this.state;

    return (
      <div id="lead-dna-details">
        <Row>
          <Col xs={12}>
            <div className="white-panel">
              <Row style={{ marginBottom: "20px" }}>
                <Col xs={4}>
                  <label style={{ marginRight: "10px", color: "#666" }}>Show distribution by</label>
                  <ButtonDropDown
                    value={this.state.primaryFilter}
                    options={filterValues}
                    handleSelect={this.handleSelectPropertyCriteria}
                  />
                </Col>
                <Col xs={6} style={{ fontWeight: "bold", fontSize: "12px" }}>
                  {titleText}
                </Col>

                {this.state.showSecondary && (
                  <div className="form-group">
                    <label className="inline-label">Of that, show by: </label>
                    <div id="secondary-select-wrapper" className="tw-bottom-[10px]">
                      <div className="tw-flex">
                        <div className="tw-flex-shrink tw-px-7px tw-py-4px" onClick={this.closeSecondary}>
                          <i className="fa fa-times-circle"></i>
                        </div>
                        <div className="tw-relative tw-w-[100px] tw-bottom-1px">
                          <Dropdown
                            value={this.state.secondaryFilter}
                            options={filterValues}
                            isSearchable={false}
                            menuShouldScrollIntoView
                            onChange={(e) => this.filterSecondary(e.value)}
                            isClearable={false}
                            variant="flatBlueishGray"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Row>
              <Row>
                <div className="col-xs-12">
                  {stats.length > 0 ? (
                    <div>
                      <div className="chart-container">
                        <Bar
                          ref={this.chartRef}
                          data={this.state.chartData}
                          height={14}
                          options={options(this.state)}
                          onClick={(event) => {
                            if (!this.chartRef.current) return;
                            const dataset = getDatasetAtEvent(this.chartRef.current, event);
                            this.startSecondaryData(
                              this.state.chartData.datasets[dataset[0].datasetIndex].metaCollection,
                              this.determineInitialSecondary(),
                              this.state.chartData.datasets[dataset[0].datasetIndex].secondaryTitle,
                            );
                          }}
                        />
                      </div>
                      <ChartLegend datasets={this.state.chartData.datasets} />
                    </div>
                  ) : null}
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <div id="properties-container">
          <PropertyFilters
            isFavorited={isFavorited}
            toggleButtons={this.toggleButtons}
            allViewCount={stats.length}
            favoriteCount={this.favoriteCount()}
            sortBy={sortBy}
            handleSelectSortBy={this.handleSelectSortBy}
          />
          {(this.props.views && !stats.length) ? this.contactSupport() : this.statsOrSpinner(stats)}
        </div>
      </div>
    );
  }
}

export default (props) => {
  const dispatch = useDispatch();

  return <Activity {...props} openAddListingAlertModal={() => dispatch(actions.openAddListingAlertModal())} />;
};

class ChartLegend extends React.Component {
  render() {
    const datasets = _.map(this.props.datasets, function (ds, index) {
      return (
        <li key={ds.label} className={`${ds.color}`} dangerouslySetInnerHTML={{ __html: ds.label }}></li>
      );
    });

    return <ul className={"leaddna-legend"}>{datasets}</ul>;
  }
}
