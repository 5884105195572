/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { extractData } from "../utils";

const DropdownIndicator = ({ children, ...componentProps }) => {
  const Component = componentProps.selectProps?.custom?.DropdownIndicator;

  if (!Component) {
    return null;
  }

  return (
    <Component componentProps={componentProps} data={extractData(componentProps)}>
      {children}
    </Component>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    custom: PropTypes.shape({
      DropdownIndicator: PropTypes.func,
    }),
  }),
  children: PropTypes.node,
};

DropdownIndicator.defaultProps = {
  selectProps: {
    custom: {
      DropdownIndicator: null,
    },
  },
  children: null,
};

export default DropdownIndicator;
