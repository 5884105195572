import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverItem, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";
import IconButton from "@shared/v2/IconButton";
import { VerticalDots, XmarkSolidV6, UserPlusSolidV6, BoxArchiveSolidV6 } from "@shared/v2/Icomoon/Icons";
import RemoveTransactionModal from "./RemoveTransactionModal";
import AddCoBorrowerModal from "./AddCoBorrowerModal";
import RemoveCoBorrowerModal from "./RemoveCoBorrowerModal";

const MortgageLoanMenu = ({
  handleRemoveTransaction,
  handleAddRemoveCoBorrower,
  handleToggleArchive,
  coborrowers,
  primary_borrower,
  archived,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showAddCoBorrowerModal, setshowAddCoBorrowerModal] = useState(false);
  const [showRemoveCoBorrowerModal, setshowRemoveCoBorrowerModal] = useState(false);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onClickRemoveTransaction = () => {
    handleRemoveTransaction();
    setIsOpen(false);
  };

  const closeModal = () => {
    setshowModal(false);
  };

  const openModal = () => {
    setshowModal(true);
  };

  const closeCoBorrowerModal = () => {
    setshowAddCoBorrowerModal(false);
  };

  const openCoBorrowerModal = () => {
    setshowAddCoBorrowerModal(true);
  };

  const closeRemoveCoBorrowerModal = () => {
    setshowRemoveCoBorrowerModal(false);
  };

  const openRemoveCoBorrowerModal = () => {
    setshowRemoveCoBorrowerModal(true);
  };

  const onClickAddCoBorrower = (value) => {
    handleAddRemoveCoBorrower(value);
    setIsOpen(false);
  };

  const onClickRemoveCoBorrower = (selectedCoBorrower) => {
    handleAddRemoveCoBorrower(selectedCoBorrower, true);
    setIsOpen(false);
  };
  const onClickArchive = () => {
    handleToggleArchive();
  };
  return (
    <>
      <div className="tw-relative tw-flex tw-justify-start">
        <Popover open={isOpen} onOpenChange={setIsOpen} placement="bottom-end">
          <PopoverTrigger>
            <IconButton size="small" schema="tertiary" onClick={handleButtonClick}>
              <VerticalDots size="l" />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent className="tw-z-3000">
            <PopoverItem onClick={openCoBorrowerModal}>
              <UserPlusSolidV6 size="m" /> <span className="tw-pl-[8px]">Add a Co-Borrower</span>
            </PopoverItem>
            <PopoverItem onClick={openRemoveCoBorrowerModal}>
              <XmarkSolidV6 size="m" /> <span className="tw-pl-[8px]">Remove a Co-Borrower</span>
            </PopoverItem>
            <PopoverItem onClick={openModal}>
              <XmarkSolidV6 size="m" /> <span className="tw-pl-[8px]">Remove Transaction</span>
            </PopoverItem>
            <PopoverItem onClick={onClickArchive}>
              <BoxArchiveSolidV6 size="m" />
              <span className="tw-pl-[8px]">{archived ? "Un-Archive Loan" : "Archive Loan"}</span>
            </PopoverItem>
          </PopoverContent>
        </Popover>
      </div>
      {showModal && (
        <RemoveTransactionModal
          modalOpen={showModal}
          closeModal={closeModal}
          handleRemoveTransaction={onClickRemoveTransaction}
        />
      )}

      {showAddCoBorrowerModal && (
        <AddCoBorrowerModal
          coborrowers={[...primary_borrower, ...coborrowers]}
          modalOpen={showAddCoBorrowerModal}
          closeModal={closeCoBorrowerModal}
          handleAddCoBorrower={onClickAddCoBorrower}
        />
      )}

      {showRemoveCoBorrowerModal && (
        <RemoveCoBorrowerModal
          coborrowers={coborrowers}
          modalOpen={showRemoveCoBorrowerModal}
          closeModal={closeRemoveCoBorrowerModal}
          handleRemoveCoBorrower={onClickRemoveCoBorrower}
        />
      )}
    </>
  );
};

MortgageLoanMenu.propTypes = {
  onClickRemoveTransaction: PropTypes.func,
  handleAddRemoveCoBorrower: PropTypes.func,
  handleToggleArchive: PropTypes.func,
  archived: PropTypes.bool,
  coborrowers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string,
    }),
  ),
  primary_borrower: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      full_name: PropTypes.string,
    }),
  ),
};

MortgageLoanMenu.defaultProps = {
  onClickRemoveTransaction: () => {},
  handleAddRemoveCoBorrower: () => {},
  handleToggleArchive: () => {},
  coborrowers: [],
  archived: false,
  primary_borrower: [],
};

export default MortgageLoanMenu;
