import React from "react";
import { useSelector } from "react-redux";
import { BarsSolidV6 } from "../../shared/v2/Icomoon";
import IconButton from "../../shared/v2/IconButton/IconButton";
import useMobileMenu from "../Sidebar/hooks/useMobileMenu";

const MobileHamburger = () => {
  const {
    actions,
    data: { isMobile },
  } = useMobileMenu();
  const theme = useSelector(({ layout }) => layout.theme);

  if (!isMobile) return null;
  return (
    <IconButton
      schema="transparent"
      className={`tw-flex-none tw-ml-[-4px]
        ${theme.name === "place-theme" ? "tw-text-white" : ""}  hover:tw-bg-gray/20 active:tw-bg-gray/30
    `}
      onClick={actions.toggleMobileMenu}
    >
      <BarsSolidV6 size="xxl" />
    </IconButton>
  );
};

export default MobileHamburger;
