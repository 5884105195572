import React from "react";

const RemovableBubble = ({value, remove, includeMargins = true}) => {
  const margins = includeMargins ? "tw-mx-4px tw-mb-4px" : "";

  return (
    <div className={`tw-flex tw-items-center tw-justify-center tw-py-4px tw-pl-8px tw-pr-10px tw-border tw-border-solid tw-border-gray-light tw-rounded-16px tw-bg-gray-light ${margins}`}>
      <span className="tw-font-bold tw-text-11d tw-text-gray-75 tw-mr-8px">{value}</span>
      <i onClick={remove} className="fa fa-remove tw-text-12d tw-cursor-pointer" />
    </div>
  );
};

export default RemovableBubble;
