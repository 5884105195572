import React, { useRef, useState } from "react";

import { useClickOutside } from "../../../shared/hookHelpers";

import ResourceBtn from "./ResourceBtn";
import ResourcesDropdown from "./ResourcesDropdown";

const Resources = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));
  const ARIA_LABELLED_ID = "resourceIcon";

  return (
    <div ref={ref} className="tw-relative">
      <ResourceBtn open={open} id={ARIA_LABELLED_ID} setOpen={() => setOpen((o) => !o)} />
      <ResourcesDropdown ariaLabelledBy={ARIA_LABELLED_ID} open={open} />
    </div>
  );
};

export default Resources;
