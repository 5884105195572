import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { borderFor, schemaBasedClasses, validSchemas, sizeBasedClasses } from "./IconButton.styles";

const IconButton = forwardRef(({ children, className, onClick, schema, size, type, ...otherProps }, ref) => (
  <button
    ref={ref}
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={`
      tw-font-bold
      tw-overflow-hidden
      tw-border-solid tw-rounded-full
      tw-uppercase
      tw-round-full
      tw-inline-flex tw-justify-center tw-items-center
      ${borderFor(schema)}
      ${schemaBasedClasses(schema)}
      ${sizeBasedClasses(size)}
      ${className}
    `}
    onClick={onClick}
    data-cy="icon-button"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
  >
    {children}
  </button>
));

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  schema: PropTypes.oneOf(validSchemas),
  size: PropTypes.oneOf(["xsmall", "small", "medium", "custom"]),
  type: PropTypes.string,
};

IconButton.defaultProps = {
  children: null,
  className: "",
  onClick: null,
  schema: "primary",
  size: "medium",
  type: "button",
};

export default IconButton;
