import React from "react";
import PropTypes from "prop-types";
import { Popover } from "@shared/v2/Popover";
import { AngleDownSolidV6 } from "@shared/v2/Icomoon";

const getPostStatusName = (post) => {
  if (post?.isArchived) {
    return "Archived";
  }
  if (post?.isPublished) {
    return "Published";
  }
  return "Draft";
};

const getPostStatusClass = (post) => {
  if (post?.isArchived) {
    return "tw-bg-gray-99";
  }
  if (post?.isPublished) {
    return "tw-bg-theme-primary";
  }
  return "tw-bg-neutral-gray-50";
};

const PostStatusPill = ({ isButton, post }) => {
  const TagName = isButton ? "button" : "div";
  return (
    <TagName
      className={`tw-inline-flex tw-gap-[4px] tw-justify-center tw-items-center tw-w-[120px] tw-py-[4px] tw-px-[8px] tw-border-none tw-rounded-full tw-uppercase tw-text-11d tw-text-center tw-font-bold tw-text-white ${getPostStatusClass(post)}`}
    >
      {getPostStatusName(post)} {isButton && <AngleDownSolidV6 size="s" />}
    </TagName>
  );
};

PostStatusPill.propTypes = {
  isButton: PropTypes.bool,
  post: PropTypes.shape(),
};

PostStatusPill.defaultProps = {
  isButton: false,
  post: {},
};

const PostStatus = ({ onChange, post }) => {
  const currentStatus = <PostStatusPill isButton={Boolean(onChange)} post={post} />;

  if (!onChange) {
    return currentStatus;
  }

  return (
    <Popover>
      <Popover.Trigger>
        <div>{currentStatus}</div>
      </Popover.Trigger>
      <Popover.Content>
        <Popover.Item onClick={() => onChange({ isPublished: true, isArchived: false })}>
          <PostStatusPill post={{ isPublished: true }} />
        </Popover.Item>
        <Popover.Item onClick={() => onChange({ isPublished: false, isArchived: false })}>
          <PostStatusPill post={{ isArchived: false, isPublished: false }} />
        </Popover.Item>
        <Popover.Item onClick={() => onChange({ isPublished: false, isArchived: true })}>
          <PostStatusPill post={{ isArchived: true }} />
        </Popover.Item>
      </Popover.Content>
    </Popover>
  );
};

PostStatus.propTypes = {
  onChange: PropTypes.func,
  post: PropTypes.shape(),
};

PostStatus.defaultProps = {
  onChange: undefined,
  post: {},
};

export default PostStatus;
