import { schemaGenerator } from "../utils";

const base = [
  "tw-group",
  "tw-inline-flex",
  "tw-gap-[4px]",
  "tw-rounded-full",
  "tw-h-[24px]",
  "tw-text-center",
  "tw-py-[4px]",
  "tw-px-[8px]",
  "tw-bg-neutral-gray-10",
  "tw-font-bold",
  "tw-text-11d",
  "tw-text-neutral-gray-75",
  "tw-border-0",
  "tw-select-none",
  "tw-whitespace-nowrap",
  "tw-cursor-default",
];

const dictionary = {
  regular: {
    default: [...base, "tw-pr-[4px]"],
    active: [
      "active:tw-bg-neutral-gray-75",
      "active:tw-text-[white]",
      "active:lg:tw-bg-neutral-gray-10",
      "active:lg:tw-text-neutral-gray-75",
    ],
  },
  nonRemovable: { default: base, hover: ["hover:tw-cursor-default"] },
};

const schema = schemaGenerator(dictionary);

export default schema;
