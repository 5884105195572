import React from "react";
import { func } from "prop-types";

import useZoom from "./hooks";
import { Plus, Minus } from "../../../../shared/v2/Icomoon";

const ZoomCtrls = ({ centerView }) => {
  const [zoomVal, zoomRange, zoomInOut] = useZoom(centerView);

  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-gap-16px">
      <button
        aria-label="zoom out"
        className="tw-bg-transparent tw-border-none tw-p-0 tw-cursor-pointer tw-flex tw-items-center"
        onClick={() => zoomInOut(true)}
        type="button"
      >
        <Minus size="xxl" />
      </button>
      <div className="range-slider tw-relative tw-flex tw-flex-col tw-items-center">
        <input
          type="range"
          value={zoomVal}
          min={0}
          max={100}
          step={10}
          onChange={(e) => {
            e.persist();
            zoomRange(e);
          }}
          className="zoomer tw-appearance-none"
        />
        <div className="tw-absolute tw-mt-10px">{`${100 + zoomVal}%`}</div>
      </div>
      <button
        aria-label="zoom in"
        className="tw-bg-transparent tw-border-none tw-p-0 tw-cursor-pointer tw-flex tw-items-center"
        onClick={() => zoomInOut()}
        type="button"
      >
        <Plus size="xxl" />
      </button>
    </div>
  );
};

ZoomCtrls.propTypes = {
  centerView: func,
};

ZoomCtrls.defaultProps = {
  centerView: () => {},
};

export default ZoomCtrls;
