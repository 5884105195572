/* eslint-disable import/prefer-default-export */
import moment from "moment";
import { adaptGetAllListingInfo } from "../api/apiAdapters";
import { saveDetailsFromHeader } from "./creators";

const exactDateHelper = (days) => moment().add(days, "days").format("MM/DD/YYYY");

const TIMEFRAME_DICTIONARY = {
  Immediate: {
    prefix: "",
    value: "date",
  },
  Unknown: {
    prefix: "",
    value: "date",
  },
  "Within 90 days": {
    prefix: "By",
    value: exactDateHelper(90),
  },
  "Within a year": {
    prefix: "By",
    value: exactDateHelper(365),
  },
  "After a year": {
    prefix: "After",
    value: exactDateHelper(365),
  },
  Custom: {
    prefix: "By",
    value: null,
  },
};

export const timeframeExactDate = (timeframe) => TIMEFRAME_DICTIONARY[timeframe].value;

export const errorWrapper = (dispatch, errLoad, errMessages) => (errAdapter, creator) => {
  const errorMessages = errMessages.join("\n\n");
  const adaptedErrLoad = errAdapter(errLoad);

  dispatch(creator(adaptedErrLoad));
  // eslint-disable-next-line no-alert
  alert(errorMessages);
};

// This updates all Header microcomponents on error
export const dispatchErrorHeader = (dispatch, err, errBody = "body") => {
  const errMessages = err?.response?.data?.errors;
  const errLoad = err?.response?.data[errBody];

  const errWrap = errorWrapper(dispatch, errLoad, errMessages);
  errWrap(adaptGetAllListingInfo, saveDetailsFromHeader);
};
