import React, { useState } from "react";
import { func } from "prop-types";

import useOnClickOutsideRef from "../../api/hooks";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import { referralEditTitleModalOn } from "../../actions/creators";

import { EllipsisIcon } from "../../../../Transactions/components/icons";
import EllipsesDropdown from "../EllipsesDropdown";

const EllipsesComponents = ({ onReferralEditTitleModalOn }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [deleteListing, setDeleteListing] = useState(false);

  const ellipsisModal = useOnClickOutsideRef(() => setToggleDropdown(false));

  return (
    <div className="tw-relative tw-self-center tw-flex tw-items-center tw-justify-center tw-mr">
      <div
        aria-label="toggle menu"
        ref={ellipsisModal}
        role="button"
        className="tw-flex tw-items-center tw-justify-center tw-bg-gray-10 tw-w-[32px] tw-h-[32px] tw-rounded-full tw-cursor-pointer"
        onClick={() => setToggleDropdown(!toggleDropdown)}
        onKeyDown={() => setToggleDropdown(!toggleDropdown)}
        tabIndex={0}
      >
        <EllipsisIcon className="tw-text-[18px]" />
      </div>
      {toggleDropdown && (
        <EllipsesDropdown
          deleteListing={deleteListing}
          setDeleteListing={setDeleteListing}
          setToggleDropdown={toggleDropdown}
          setRename={onReferralEditTitleModalOn}
        />
      )}
    </div>
  );
};

export default withDispatch({
  WrappedComponent: EllipsesComponents,
  actions: referralEditTitleModalOn,
  aliases: "onReferralEditTitleModalOn",
});

EllipsesComponents.propTypes = {
  onReferralEditTitleModalOn: func,
};

EllipsesComponents.defaultProps = {
  onReferralEditTitleModalOn: () => {},
};
