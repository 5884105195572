import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "../actions/creators";
import getProvinceObject from "../helpers";
import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";
import TextInput from "../../shared/v2/TextInput";
import Dropdown from "../../shared/v2/Dropdown";

const NewOfficeModal = ({ onAdd, onClose, model, setOfficeValue, states, territories }) => (
  <Modal show onHide={onClose} contentSize="medium" contentClassName="tw-m-auto">
    <Modal.Header title="Add Office" closeButton className="tw-mb-[23px]">
      {" "}
    </Modal.Header>
    <div className="tw-mb-[24px]">
      <TextInput
        value={model.name}
        onChange={(v) => setOfficeValue("name", v.target.value)}
        label="Office Name"
        isRequired
      />
    </div>
    <div className="tw-flex tw-mb-[24px]">
      <TextInput
        containerClassName="tw-flex-1 tw-mr-[24px]"
        value={model.phone}
        onChange={(v) => setOfficeValue("phone", v.target.value)}
        label="Phone"
      />
      <TextInput
        containerClassName="tw-flex-1"
        value={model.fax}
        onChange={(v) => setOfficeValue("fax", v.target.value)}
        label="Fax"
      />
    </div>
    <div className="tw-mb-[24px]">
      <TextInput
        value={model.address_attributes.street_address}
        onChange={(v) =>
          setOfficeValue("address_attributes", {
            ...model.address_attributes,
            street_address: v.target.value,
          })
        }
        label="Street Address"
        isRequired
      />
    </div>
    <div className="tw-flex tw-mb-[24px]">
      <TextInput
        containerClassName="tw-flex-1 tw-mr-[24px]"
        value={model.address_attributes.city}
        onChange={(v) =>
          setOfficeValue("address_attributes", { ...model.address_attributes, city: v.target.value })
        }
        label="City"
      />
      <Dropdown
        containerClassName="tw-flex-1 tw-mr-[24px] tw-min-w-[108px]"
        isSearchable
        label="State / Province"
        placeholder="Select"
        value={getProvinceObject(states, territories, model.address_attributes.locality)}
        onChange={(v) =>
          setOfficeValue("address_attributes", { ...model.address_attributes, locality: v.value })
        }
        options={[
          {
            label: "States",
            options: states,
          },
          {
            label: "Province",
            options: territories,
          },
        ]}
      />
      <TextInput
        containerClassName="tw-flex-1 tw-overflow-hidden"
        value={model.address_attributes.postal_code}
        onChange={(v) =>
          setOfficeValue("address_attributes", { ...model.address_attributes, postal_code: v.target.value })
        }
        label="ZIP / Postal Code"
      />
    </div>
    <div className="tw-mb-[24px]">
      <TextInput
        value={model.address_attributes.country}
        onChange={(v) =>
          setOfficeValue("address_attributes", { ...model.address_attributes, country: v.target.value })
        }
        label="Country"
      />
    </div>
    <div className="tw-flex">
      <Button
        onClick={() => {
          onClose();
        }}
        size="medium"
        schema="tertiary"
      >
        Cancel
      </Button>
      <div className="tw-flex-1" />
      <Button
        disabled={model.name <= "" || model.address_attributes.street_address <= ""}
        onClick={() => onAdd(model)}
        size="medium"
      >
        Add
      </Button>
    </div>
  </Modal>
);

NewOfficeModal.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  territories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  model: PropTypes.shape({
    account_offices: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address_attributes: PropTypes.shape({
      street_address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      locality: PropTypes.string.isRequired,
      postal_code: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setOfficeValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.newOfficeModel,
  territories: state.businessSettings.territories,
  states: state.businessSettings.states,
});

export default connect(mapStateToProps, creators)(NewOfficeModal);
