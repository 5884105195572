import React from "react";
import PropTypes from "prop-types";
import colors from "../../Reports/components/DashboardShared/helpers/colors";

const getStatColor = (stat) => {
  if (stat.count === 0) return "tw-text-neutral-gray-50";
  if (colors.theme === "place") return "tw-text-semantic-blue-100";
  return "tw-text-teal";
};

const QuickStat = ({ stat }) => (
  <div
    role="button"
    tabIndex={0}
    className={`tw-shadow tw-text-center tw-w-full tw-min-w-[16rem] tw-h-[137px] tw-inline-block tw-rounded-[10px] tw-px-[20px] tw-pb-[20px] tw-mt-[5px] tw-mx-[10px] tw-text-14d tw-font-semibold ${
      stat.count === 0 ? "hover:tw-bg-gray-lighter" : "hover:tw-bg-[#f3fdfd]"
    }`}
    key={stat.name}
    onClick={() => window.open(stat.path, "_blank")}
    onKeyDown={(e) => {
      if (e.key !== "Tab") e.preventDefault();
      if (["Enter", " "].includes(e.key)) window.open(stat.path, "_blank");
    }}
  >
    <div className={getStatColor(stat)}>
      <h1 className="tw-text-24d tw-font-semibold">{stat.count}</h1>
      <p className="tw-text-neutral-gray-50 tw-m-auto tw-max-w-[124px]">{stat.name}</p>
    </div>
  </div>
);

QuickStat.propTypes = {
  stat: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
};

export default QuickStat;
