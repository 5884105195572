import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import TextComboInput from "@shared/v2/TextComboInput";
import RemoveFieldButton from "../../../shared/v2/RemoveFieldButton";
import { isStrInputLen, isNumValid } from "../../ModalViews/helpers";
import FieldSelect from "../../../shared/UtilsV1/FieldSelect";
import { useContactFormOptions, useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

function MLSFieldView({ value: curMls, index, setIsAddActive, onChange }) {
  const dispatch = useDispatch();
  const { mlsOptions } = useContactFormOptions();
  const { loginUserAttributes } = useContactPerson();
  const { usersMlsesAttributes } = loginUserAttributes;
  const { mlsId, mlsAgentId } = curMls;

  useEffect(() => {
    if (index === usersMlsesAttributes.length - 1) {
      if (!!mlsId && !!mlsAgentId) {
        setIsAddActive(true);
      } else {
        setIsAddActive(false);
      }
    }
  }, [index, mlsId, mlsAgentId, usersMlsesAttributes]);

  const onRemove = (targetMLS) => {
    let filtereduserMLSList;
    if (Object.hasOwnProperty.call(targetMLS, "id")) {
      filtereduserMLSList = usersMlsesAttributes.map((mls) =>
        mls === targetMLS ? { ...mls, _destroy: true } : mls,
      );
    } else {
      filtereduserMLSList = usersMlsesAttributes.filter((mls) => mls !== targetMLS);
    }

    dispatch(setPersonValue({ key: "loginUserAttributes.usersMlsesAttributes", value: filtereduserMLSList }));
  };

  return (
    <div className="tw-flex tw-gap-[24px]">
      <FieldSelect
        label="MLS"
        placeholder="Select MLS"
        options={mlsOptions}
        value={mlsId}
        dropdownWidth="!tw-w-[516px] tw-left-0"
        onChange={(value) => {
          onChange({ mlsId: value, mlsAgentId });
        }}
        error={isNumValid(mlsId)}
        isDropdownSearchable
        required
      />
      <div className="tw-flex tw-gap-2 tw-flex-1">
        <TextComboInput
          label="MLS Agent ID"
          value={mlsAgentId}
          onChange={(e) => {
            onChange({ mlsId, mlsAgentId: e.target.value });
          }}
          className="tw-h-[37.25px]"
          labelClassName="tw-mb-[4px]"
          toolTipInfoContent={
            <div className="tw-text-left tw-text-12d tw-w-[161px]">
              Enables your Brivity website to display agent-specific listings on their profile page. If you
              have multiple IDs, only the first one will be used to generate these listings.
            </div>
          }
          toolTipPlacement="top"
          isRequired
          error={isStrInputLen(mlsAgentId)}
        />

        <RemoveFieldButton onClick={() => onRemove(curMls)} className="tw-mb-[1px] tw-mt-[24px]" />
      </div>
    </div>
  );
}

MLSFieldView.propTypes = {
  value: PropTypes.shape({
    mlsId: PropTypes.number,
    mlsAgentId: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  setIsAddActive: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MLSFieldView;
