import {
  saveAllAdvertisingPlan,
  saveAllAvailablePlan,
  saveAllAdsitesPlan,
  saveAllListingViews,
  saveAllAssetsCardViews,
  getListingFromSearch,
  saveImportedListing,
  updateMLS,
  fetchAutoCompleteFailure,
  importSearchListingFailure,
  fetchAssetsCardFailure,
  fetchEditListingURLFailure,
  fetchAdvertisingPlanFailure,
  fetchManagePlanFailure,
  destroyPlanFailure,
  fetchAvailablePlanFailure,
  fetchAdSiteFailure,
  loadData,
  loadMarketingData,
  setSearchKeyword,
  setIsSearched,
  loadMlsId,
} from "./creators";

import { tryCatchHandlr } from "../../../../shared/Utilities";

import {
  searchListingFromApi,
  getImportedListingFromApi,
  getAssetsCardFromApi,
  editListingFromApi,
  updateAdSiteFromApi,
  shareAdSiteFromApi,
  removeAdSiteFromApi,
  deleteAdPlanFromApi,
  submitAvailablePlanFromApi,
  submitAdSiteFromApi,
  submitEditListingURLFromApi,
  getAvailablePlanFromApi,
  getManagePlanFromApi,
  getAdvertisingPlanFromApi,
  getMetricsFromApi,
  getMlsIdFromApi,
} from "../api/apiWrappers";
import { adaptError, AdSiteError } from "../api/apiAdapters";
import { sortByPosition } from "./helpers";

export const getAdvertisingPlanAsThunk = (uuid) => async (dispatch) => {
  dispatch(loadData(true));
  const req = getAdvertisingPlanFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadData(false));
    dispatch(fetchAdvertisingPlanFailure(adaptError(err)));
    return;
  }

  const advertisingplans = res.data;
  dispatch(loadData(false));
  dispatch(saveAllAdvertisingPlan(sortByPosition(advertisingplans)));
};

export const getAvailablePlanAsThunk = (uuid) => async (dispatch) => {
  dispatch(loadData(true));
  const req = getAvailablePlanFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadData(false));
    dispatch(fetchAvailablePlanFailure(adaptError(err)));
    return;
  }

  const availablePlan = res.data;
  dispatch(loadData(false));
  dispatch(saveAllAvailablePlan(availablePlan));
};

export const submitAvailablePlanAsThunk = (uuid, planId, closeModal) => async (dispatch) => {
  dispatch(loadData(true));
  const req = submitAvailablePlanFromApi(uuid, planId);
  // eslint-disable-next-line no-unused-vars
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadData(false));
    dispatch(fetchAvailablePlanFailure(adaptError(err)));
    return;
  }
  closeModal();
  dispatch(getAdvertisingPlanAsThunk(uuid));
};

export const getManagePlanAsThunk = (uuid, planId) => async (dispatch) => {
  dispatch(loadData(true));
  const req = getManagePlanFromApi(uuid, planId);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadData(false));
    dispatch(fetchManagePlanFailure(adaptError(err)));
    return;
  }
  const adsites = res.data;
  adsites.sites = sortByPosition(adsites.sites);
  dispatch(loadData(false));
  dispatch(saveAllAdsitesPlan(adsites));
};

export const submitAdSiteAsThunk =
  (uuid, planId, data, closeModal, isFromManageModal) => async (dispatch) => {
    if (data.name) {
      dispatch(loadData(true));
      const req = submitAdSiteFromApi(uuid, planId, data);
      // eslint-disable-next-line no-unused-vars
      const [res, err] = await tryCatchHandlr(req);
      if (err) {
        dispatch(loadData(false));
        dispatch(fetchAdSiteFailure(adaptError(err)));
        return;
      }
      closeModal();
      if (isFromManageModal) {
        dispatch(getManagePlanAsThunk(uuid, planId));
      } else {
        dispatch(getAdvertisingPlanAsThunk(uuid)(dispatch));
      }
    } else {
      dispatch(fetchAdSiteFailure(AdSiteError(null)));
    }
  };

export const updateAdSiteAsThunk = (uuid, planId, siteId, data) => async (dispatch) => {
  dispatch(loadData(true));
  const req = updateAdSiteFromApi(uuid, planId, siteId, data);
  // eslint-disable-next-line no-unused-vars
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadData(false));
    dispatch(fetchManagePlanFailure(adaptError(err)));
    return;
  }
  dispatch(getManagePlanAsThunk(uuid, planId));
  dispatch(getAdvertisingPlanAsThunk(uuid));
};
export const shareAdSiteAsThunk = (uuid, planId, siteId) => async (dispatch) => {
  dispatch(loadData(true));
  const req = shareAdSiteFromApi(uuid, planId, siteId);
  // eslint-disable-next-line no-unused-vars
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadData(false));
    dispatch(fetchManagePlanFailure(adaptError(err)));
    return;
  }
  dispatch(getManagePlanAsThunk(uuid, planId));
  dispatch(getAdvertisingPlanAsThunk(uuid));
};
export const removeAdSiteAsThunk = (uuid, planId, siteId) => async (dispatch) => {
  dispatch(loadData(true));
  const req = removeAdSiteFromApi(uuid, planId, siteId);
  // eslint-disable-next-line no-unused-vars
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadData(false));
    dispatch(fetchManagePlanFailure(adaptError(err)));
    return;
  }
  dispatch(getManagePlanAsThunk(uuid, planId));
  dispatch(getAdvertisingPlanAsThunk(uuid));
};

export const deleteAdvertisingPlan = (uuid, planId, closeModal) => async (dispatch) => {
  dispatch(loadData(true));
  try {
    await deleteAdPlanFromApi(uuid, planId);
    closeModal("true");
    dispatch(getAdvertisingPlanAsThunk(uuid));
  } catch (err) {
    closeModal("true");
    dispatch(loadData(false));
    dispatch(destroyPlanFailure(adaptError(err)));
  }
};

export const getListingViewsAsThunk = (uuid) => async (dispatch) => {
  const req = getMetricsFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    return;
  }

  const listingViews = res.data;
  dispatch(saveAllListingViews(listingViews));
};

export const getListingMlsIDAsThunk = (uuid) => async (dispatch) => {
  const req = getMlsIdFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadMlsId({ loaded: false }));
    // dispatch(fetchAssetsCardFailure(adaptError(err)));
    return;
  }

  dispatch(updateMLS(res.data.blueroofMlsId));
  dispatch(loadMlsId({ loaded: true, mlsID: res.data.blueroofMlsId, mlsNumber: res.data.mlsNumber }));
};

export const getAssetsCardViewsAsThunk = (uuid) => async (dispatch) => {
  dispatch(loadMarketingData(true));
  const req = getAssetsCardFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(loadMarketingData(false));
    dispatch(fetchAssetsCardFailure(adaptError(err)));
    return;
  }

  const assetsCardViews = res.data;
  dispatch(loadMarketingData(false));
  dispatch(saveAllAssetsCardViews(assetsCardViews));
};

export const submitEditListingURLAsThunk = (uuid, data, closeModal) => async (dispatch) => {
  dispatch(loadMarketingData(true));
  const req = submitEditListingURLFromApi(uuid, data);
  // eslint-disable-next-line no-unused-vars
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadMarketingData(false));
    dispatch(fetchEditListingURLFailure(adaptError(err)));
    return;
  }
  closeModal();
  dispatch(getListingViewsAsThunk(uuid));
  dispatch(getAssetsCardViewsAsThunk(uuid));
};

export const setSearch = (search, isDelete) => (dispatch) => {
  if (isDelete) {
    dispatch(getListingFromSearch([]));
    dispatch(saveImportedListing(null));
  }
  dispatch(setSearchKeyword(search));
};

export const getsearchListingAsThunk = (search) => async (dispatch) => {
  const req = searchListingFromApi(search);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(fetchAutoCompleteFailure(adaptError(err)));
    return;
  }

  const homelisting = res.data;
  dispatch(setSearch(search, false));
  dispatch(getListingFromSearch(homelisting));
};

export const importSearchListingAsThunk = (search, autocompleteId) => async (dispatch) => {
  dispatch(loadMarketingData(true));
  dispatch(setSearch(search, false));
  dispatch(getListingFromSearch([]));
  const req = getImportedListingFromApi(autocompleteId);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadMarketingData(false));
    dispatch(importSearchListingFailure(adaptError(err)));
    return;
  }

  const importedListing = res.data;
  dispatch(loadMarketingData(false));

  dispatch(saveImportedListing(importedListing));
};

export const editListing = (uuid, listingBody) => async (dispatch) => {
  dispatch(loadMarketingData(true));
  const req = editListingFromApi(uuid, listingBody);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    dispatch(loadMarketingData(false));
    dispatch(importSearchListingFailure(adaptError(err)));
    return;
  }
  const mlsID = res.data.blueroof_mls_id;
  dispatch(loadMarketingData(false));
  dispatch(updateMLS(mlsID));
};

export const setSearched = (isSearched) => (dispatch) => {
  dispatch(setIsSearched(isSearched));
};
