/* eslint-disable camelcase */
class Address {
  constructor(properties) {
    this.locality = properties?.locality || "";
    this.postalCode = properties?.postalCode || "";
    this.streetAddress = properties?.streetAddress || "";
    this.city = properties?.city || "";
  }

  get firstLine() {
    return this.streetAddress;
  }

  get secondLine() {
    const partOne = this.city;
    const partTwo = [this.locality, this.postalCode].filter((x) => !!x).join(" ");

    return [partOne, partTwo].filter((x) => !!x).join(", ");
  }

  get fullAddress() {
    return [this.firstLine, this.secondLine];
  }
}

export default Address;
