import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Dropdown from "../shared/v2/Dropdown/Dropdown";
import Button from "../shared/v2/Button/Button";
import { LogOut } from "../shared/v2/Icomoon";
import TextInput from "../shared/v2/TextInput/TextInput";
import { useCurrentUser } from "../reducers/layoutReducer/selectors";

const SuperUser = () => {
  const currentSuperUser = useSelector(({ layout }) => layout.currentSuperUser);
  const currentUser = useCurrentUser();
  const env = useSelector(({ layout }) => layout.env);
  const isProduction = env === "production";
  const options = useMemo(
    () =>
      currentSuperUser && currentUser
        ? [
            { label: "CRM View", value: "/account/personal" },
            { label: "Superuser Home", value: `/super_users/${currentSuperUser.id}` },
            { label: "Account Actions", value: `/support/accounts/${currentUser.account.id}` },
            { label: "Health Board", value: `/support/accounts/${currentUser.account.id}/health_board` },
            { label: "Imports", value: `/support/accounts/${currentUser.account.id}/imports` },
            { label: "Exports", value: `/support/accounts/${currentUser.account.id}/exports` },
            { label: "Users", value: `/support/accounts/${currentUser.account.id}/users` },
            {
              label: "Feature Toggles",
              value: `/support/accounts/${currentUser.account.id}/feature_toggles`,
            },
            {
              label: "Transaction Financials",
              value: `/support/accounts/${currentUser.account.id}/transaction_financials`,
            },
            {
              label: "Update People",
              value: `/support/people_mass_updates?account_uuid=${currentUser.account.uuid}`,
            },
          ]
        : [],
    [currentSuperUser, currentUser],
  );
  const value =
    options.find((o) => o.value === `${window.location.pathname}${window.location.search}`) || options[0];

  if (!currentSuperUser) return null;
  return (
    <div
      className={`${
        isProduction ? "tw-bg-neutral-gray-75" : "tw-bg-[#FBC02D]"
      } tw-overflow-x-auto tw-text-white tw-flex tw-gap-[8px] tw-justify-between tw-items-center tw-p-[8px]`}
    >
      {currentUser ? (
        <>
          <div className="tw-flex tw-flex-1 tw-gap-[16px]">
            <Dropdown
              className="tw-min-w-[250px]"
              value={value}
              options={options}
              onChange={({ value: url }) => window.location.assign(url)}
              menuShouldComeToFront
              maxVisibleOptions={10}
            />
            <form
              className="tw-flex tw-flex-1 tw-gap-[8px] tw-items-center"
              action={`/super_users/${currentUser.account.id}/become`}
              acceptCharset="UTF-8"
              method="post"
            >
              <input type="hidden" name="utf8" value="✓" />
              <input name="authenticity_token" type="hidden" value={ReactOnRails.authenticityToken()} />
              <TextInput
                id="become_user_email"
                name="become_user_email"
                containerClassName="tw-flex-1 tw-max-w-[500px]"
                placeholder="Enter a user's email address"
              />
              <Button className="tw-whitespace-nowrap" type="submit" size="medium">
                Log In As User
              </Button>
            </form>
          </div>
          <div className="tw-flex-1 tw-min-w-[320px] tw-text-16d tw-text-center tw-break-words">
            Signed in as: {currentUser.email}
          </div>
        </>
      ) : (
        <span className="tw-text-16d tw-font-semibold">BRIVITY SUPERUSER</span>
      )}
      <form action="/super_users/sign_out" method="post">
        <input type="hidden" name="_method" value="delete" />
        <input name="authenticity_token" type="hidden" value={ReactOnRails.authenticityToken()} />
        <Button
          className="tw-flex tw-gap-[8px] tw-items-center tw-whitespace-nowrap"
          size="medium"
          type="submit"
        >
          <LogOut size="l" /> Log Out
        </Button>
      </form>
    </div>
  );
};

export default SuperUser;
