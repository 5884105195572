import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_DATES = "tdpDates";
const wrap = curriedNamespaceAction(REDUCER_TDP_DATES);

export const saveAllDates = (dates) =>
  wrap({
    type: "DATES_FETCH_SUCCESS",
    dates,
  });

export const fetchAllDatesFailure = (error) =>
  wrap({
    type: "DATES_FETCH_FAILURE",
    error,
  });

export const updateDateFailure = (updateError) =>
  wrap({
    type: "DATES_UPDATE_FAILURE",
    updateError,
  });

export const showCustomDates = (isOpen) =>
  wrap({
    type: "SHOW_CUSTOM_DATE",
    isOpen,
  });

export const updateDates = (isUpdate, tasksPayload) =>
  wrap({
    type: "UPDATE_DATES",
    isUpdate,
    tasksPayload,
  });
