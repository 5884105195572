import React, { Component } from 'react';
import capitalize from 'capitalize';

export default class FacebookAdRemarketEvent extends Component {
  constructor(props, context) {
    super(props, context)
    this.event = this.props.event
    this.event_type = this.props.event_type
  }

  contactName = () => {
    const changeset = this.event.timeline_fields
    const first_name = !changeset['f_name'] ? '' : changeset['f_name']
    const last_name = !changeset['l_name'] ? '' : changeset['l_name']
    let contact_name = ''

    if(first_name || last_name){
      contact_name = `${first_name} ${last_name}`.trim()
    }else {
      contact_name = 'No Name'
    }

    return contact_name;
  }
  render() {

    return (
        <div className="timeline-event">
          <div className="row">
            <div className="col-xs-1">
              <i className="fa fa-repeat timeline-icon-circle"/>
            </div>
            <div className="col-xs-8">
            <span className="bold-detail">
              {this.contactName()}
            </span>
              {` returned to website via Brivity Connect Remarketing Campaign`}
            </div>
            <div className="col-xs-3 time-ago">
              <span>{this.event.interacted_at_absolute}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-1"/>
            <div className="col-xs-11">
              <div className="row bold-detail details-row">
                Details:
              </div>
              <div className="row details-row">
                Tag: {this.event.timeline_fields.utm_campaign}
              </div>
              {this.event.timeline_fields.referrer &&
                <div className="row details-row">
                  Referrer: <a href={this.event.timeline_fields.referrer}
                               target='_blank'>{this.event.timeline_fields.referrer.slice(0, 50)}</a>
                </div>
              }
              {this.event.timeline_fields.url &&
                <div className="row details-row">
                  Landing Page: <a href={this.event.timeline_fields.url}
                                   target='_blank'>{this.event.timeline_fields.url.slice(0, 50)}</a>
                </div>
              }
            </div>
          </div>
        </div>
    )
  }
}
