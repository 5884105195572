import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatValue, formatExpense } from "../shared/FinancialViewHelpers";
import format from '../../../shared/formatters';
import { connect } from "react-redux";
import { setFinancial } from "../../actions/transaction";
import axios from "axios";
import TeamMemberSplit from "./TeamMemberSplit";
import TeamMemberExpense from "./TeamMemberExpense";

const mapDispatchToProps = (dispatch) => ({
  setFinancial: (listingData) => dispatch(setFinancial(listingData)),
});

const TransactionFinancialDetail = connect(
  null,
  mapDispatchToProps,
)((props) => {
  const {
    listingData,
    setIsEditTrDetail,
    setIsShowTrDetail,
    removeIncome,
    removeCollaboratorExpense,
    setFinancial,
  } = props;

  const onRemoveIncome = (incomeId) => {
    removeIncome(incomeId);
    const path = `/listings/${listingData.listing_id}/team_member_incomes/${incomeId}`;
    axios.delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } });
  };

  const onRemoveCollaboratorExpense = (expenseId) => {
    removeCollaboratorExpense(expenseId);
    const path = `/listings/${listingData.listing_id}/collaborator_expenses/${expenseId}`;
    axios.delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() } });
  };

  return (
    <div className="financial-details-wrap setting-financial-view">
      <div className="financial-header clearfix">
        <div className="pull-right">
          <button
            className="btn btn-default btn-default-inverse"
            onClick={() => {
              setIsEditTrDetail(true);
              setIsShowTrDetail(false);
            }}
          >
            EDIT
          </button>
        </div>
      </div>
      <div className="transaction-gci-wrap clearfix">
        <div className="transaction-gci-options">
          <div className="transaction-gci-header clearfix">
            <h3>Transactions Details</h3>
          </div>
          <div className="transaction-gci-content">
            <Row>
              <Col xs={2}>
                <label>Closed Volume</label>
                <span>{format.currency(listingData.transaction_income.closed_volume)}</span>
              </Col>
              <Col xs={5}></Col>
              <Col xs={1}></Col>
              <Col xs={2} className="text-right">
                <label>Commission</label>
                <span>
                  {formatValue(
                    listingData.transaction_income.commission,
                    listingData.transaction_income.commission_percentage,
                  )}
                </span>
              </Col>
              <Col xs={2} className="text-right">
                <label>Subtotal</label>
                <span>{format.currency(listingData.transaction_income.gross_income)}</span>
              </Col>
            </Row>
          </div>
        </div>
        {listingData.transaction_income.additionalIncome.length > 0 && (
          <div className="transaction-gci-options">
            <div className="transaction-gci-header clearfix">
              <h3>Expenses & Income</h3>
            </div>
            {listingData.transaction_income.additionalIncome.map(
              (additional) =>
                !additional._destroy && (
                  <div className="transaction-gci-content">
                    <Row>
                      <Col xs={2}>
                        <label>{additional.name}</label>
                        {additional.expense_amount && (
                          <span>{formatExpense(format.currency(additional.expense_amount))}</span>
                        )}
                        {additional.revenue_amount && (
                          <span>{format.currency(additional.revenue_amount)}</span>
                        )}
                      </Col>
                      <Col xs={5}></Col>
                      <Col xs={1}></Col>
                      <Col xs={2} className="text-right"></Col>
                      <Col xs={2} className="text-right">
                        <label>Subtotal</label>
                        <span>{format.currency(additional.line_total)}</span>
                      </Col>
                    </Row>
                  </div>
                ),
            )}
          </div>
        )}
        <div className="transaction-gci-footer clearfix">
          <div className="pull-left definition">
            <h4>Transaction GCI</h4>
          </div>
          <div className="pull-right total-amount">
            <h4>{format.currency(listingData.transaction_income.gci)}</h4>
          </div>
        </div>
      </div>
      {listingData.team_member_incomes.incomes.map((income) => {
        const sortedCollection = [income]
          .concat(income.custom_team_member_expenses_attributes)
          .sort((el1, el2) => el1.position - el2.position);
        return (
          <div className="edit-agent-wrap">
            <h2>
              {income.name ? income.name : ""}
              {income.owner && <span> (Account Owner)</span>}
              {income.agent_gci === 0 && (
                <span
                  className="fa fa-times remove-income"
                  onClick={() => {
                    onRemoveIncome(income.id);
                  }}
                />
              )}
            </h2>
            <div className="transaction-gci-wrap clearfix">
              <div className="transaction-gci-options">
                <div className="transaction-gci-header clearfix">
                  <h3>Income</h3>
                </div>
                <div className="transaction-gci-content">
                  <Row>
                    <Col xs={2}>
                      <label>Agent GCI</label>
                      <span>{formatValue(income.agent_gci, income.agent_gci_percentage)}</span>
                    </Col>
                    <Col xs={5}></Col>
                    <Col xs={1}></Col>
                    <Col xs={2} className="text-right">
                      <label>Units</label>
                      <span>{income.agent_gci_units ? income.agent_gci_units : ""}</span>
                    </Col>
                    <Col xs={2} className="text-right">
                      <label>Subtotal</label>
                      <span>{format.currency(income.gross_income)}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="transaction-gci-options">
                <div className="transaction-gci-header clearfix">
                  <h3>Expenses</h3>
                </div>
                {sortedCollection.map((element, index) =>
                  element.user_id ? (
                    <TeamMemberSplit income={element} key={index} />
                  ) : (
                    <TeamMemberExpense additionalExpense={element} key={index} />
                  ),
                )}
              </div>
            </div>
            <div className="transaction-gci-footer clearfix">
              <div className="pull-left definition">
                <h4>Net Income</h4>
              </div>
              <div className="pull-right total-amount">
                <h4>{format.currency(income.net_income)}</h4>
              </div>
            </div>
          </div>
        );
      })}
      {listingData.collaborator_expenses?.expenses?.map((collaborator) => (
        <div className="edit-collaborator-wrap">
          <h2>
            {collaborator.name}
            {collaborator.total_expense === 0 && (
              <span
                className="fa fa-times remove-income"
                onClick={() => {
                  onRemoveCollaboratorExpense(collaborator.id);
                }}
              />
            )}
          </h2>
          <div className="collaborator-expense-wrap clearfix">
            <div className="collaborator-options">
              <div className="collaborator-header clearfix">
                <h3>Expenses</h3>
              </div>
              {collaborator.custom_collaborator_expenses_attributes.map((expense) => (
                <div className="transaction-gci-content">
                  <Row>
                    <Col xs={2}>
                      <label>{expense.name}</label>
                      {expense.expense_amount && (
                        <span>{formatExpense(formatValue(expense.expense_amount, false))}</span>
                      )}
                    </Col>
                    <Col xs={5}></Col>
                    <Col xs={1}></Col>
                    <Col xs={2} className="text-right"></Col>
                    <Col xs={2} className="text-right">
                      <label>Subtotal</label>
                      <span>{formatValue(expense.line_total, false)}</span>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
          <div className="collaborator-expense-footer clearfix">
            <div className="pull-left definition">
              <h4>Total</h4>
            </div>
            <div className="pull-right total-amount">
              <h4>{formatValue(collaborator.total_expense, false)}</h4>
            </div>
          </div>
        </div>
      ))}
      <div className="financial-header clearfix">
        <div className="pull-right">
          <button
            className="btn btn-default btn-default-inverse"
            onClick={() => {
              setIsEditTrDetail(true);
              setIsShowTrDetail(false);
            }}
          >
            EDIT
          </button>
        </div>
      </div>
    </div>
  );
});

export default TransactionFinancialDetail;
