import { useEffect, useState } from "react";

const getQueryParams = () => Object.fromEntries(new URLSearchParams(window.location.search).entries());

export default () => {
  const [fullPath, setFullPath] = useState(`${window.location.pathname}${window.location.search}`);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [query, setQuery] = useState(getQueryParams());

  useEffect(() => {
    const onPushPopState = () => {
      setFullPath(`${window.location.pathname}${window.location.search}`);
      setPathname(window.location.pathname);
      setQuery(getQueryParams());
    };
    window.addEventListener("popstate", onPushPopState);
    window.addEventListener("pushstate", onPushPopState);
    return () => {
      window.removeEventListener("popstate", onPushPopState);
      window.removeEventListener("pushstate", onPushPopState);
    };
  }, []);

  return {
    back: () => window.history.back(),
    fullPath,
    pathname,
    push: (url) => {
      const pushStateEvent = new CustomEvent("pushstate");
      window.history.pushState(null, null, url);
      window.dispatchEvent(pushStateEvent);
    },
    query,
  };
};
