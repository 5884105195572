import { useEffect, useState } from "react";
import { format } from "date-fns";
import { tz } from "@date-fns/tz";

import { getCallLogs, getLogCount } from "./services";
import { getTrackingNumberIds } from "./helpers";
import formatPhoneNumber from "../../shared/FormatPhoneNumber";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const mapCallLogs = (callLogs, timeZone) =>
  callLogs?.map((log) => {
    let outcome = "Missed";

    if (log.voicemail_url !== null) {
      outcome = "Voicemail";
    } else if (log.outcome === "completed") {
      outcome = "Talked";
    }

    return {
      callLogId: log.id,
      answererFullName: log.answerer_full_name,
      answererAvatarUrl: log.answerer_avatar_url?.length > 3 ? log.answerer_avatar_url : null,
      callerPhone: formatPhoneNumber(log.caller_phone),
      callerFullName: log.caller_full_name,
      callerUrl: log.caller_url,
      outcome,
      duration: log.duration,
      createdAt: format(log.created_at, "MM/dd/yyyy 'at' h:mm a", { in: tz(timeZone) }),
      trackingNumberName: log.tracking_number.name,
      isNumberDeleted: !!log.tracking_number.deleted_at,
      voicemailUrl: log.voicemail_url,
    };
  });

export default ({ trackingNumbers, selectedTrackingNumbers, minDate, maxDate, offset, logFilters, sort }) => {
  const currentUser = useCurrentUser();
  const [callLogs, setCallLogs] = useState([]);
  const [logsCount, setLogsCount] = useState(0);

  const limit = 25;

  useEffect(() => {
    const load = async () => {
      const results = await getCallLogs(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
        sort[0],
        sort[1],
        offset,
        limit,
        logFilters,
      );

      setCallLogs(mapCallLogs(results, currentUser.account.timeZone));
    };

    load();
  }, [sort, selectedTrackingNumbers, minDate, maxDate, offset, logFilters]);

  useEffect(() => {
    const load = async () => {
      const logCount = await getLogCount(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
        logFilters,
      );
      setLogsCount(logCount);
    };

    load();
  }, [selectedTrackingNumbers, minDate, maxDate, logFilters]);

  return { callLogs, logsCount };
};
