
import React from "react";
import { Col, Row } from 'react-bootstrap';
import ExpenseInput from "../../shared/ExpenseInput";
import { formatExpense, reCalculateAgentExpenses } from '../../shared/FinancialViewHelpers';
import DragHandle from '../../shared/DragHandle';
import format from "../../../../shared/formatters";

export const SortableTeamMemberSplitInput = ({ sortIndex, listingData, setFinancialData, recalculate_team_member_net_income, income, setFinancial, parentIndex }) => (
  <div className="transaction-gci-options tw-bg-white">
    <div className="transaction-gci-content">
      <Row>
        <Col xs={1} className="drag-handler" />
        <Col xs={2}>
          <ExpenseInput
            label='Brokerage Split'
            value={income.brokerage_split}
            amount={income.brokerage_split_amount}
            isPercentage={!!income.brokerage_split_percentage}
            name={`brokerageSplitPercentage${sortIndex}`}
            onValueUpdate={e => {
              if (!!income.brokerage_split_percentage) {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  income.brokerage_split = e.target.value;
                  reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
                }
              }
              else {
                income.brokerage_split = e.target.value;
                reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
              }
            }}
            onRadioUpdate={e => {
              if (e.target.value === "true" && income.brokerage_split > 100) {
                income.brokerage_split = '';
              }
              income.brokerage_split_percentage = (e.target.value === "true");
              reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
            }}
          />
        </Col>

        <Col xs={4}>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              className="description form-control"
              name={`brokerageSplitNote${sortIndex}`}
              placeholder=""
              onChange={e => {
                income.brokerage_split_notes = e.target.value;
                setFinancialData(listingData);
              }}
            >
              {income.brokerage_split_notes}
            </textarea>
          </div>
        </Col>

        <Col xs={1} />

        <Col xs={2} className="text-right">
          <span>{formatExpense(format.currency(income.brokerage_split_amount))}</span>
        </Col>

        <Col xs={2} className="text-right">
          <span>{format.currency(income.brokerage_split_line_total)}</span>
        </Col>
      </Row>
    </div>
    <Row className="agent-split-drag-handler">
      <DragHandle />
    </Row>
    <div className="transaction-gci-content" style={{ borderTop: "none" }}>
      <Row>
        <Col xs={1} className="drag-handler" />

        <Col xs={2}>
          <ExpenseInput
            label='Royalty'
            value={income.royalty}
            amount={income.royalty_amount}
            isPercentage={!!income.royalty_percentage}
            name={`royaltyPercentage${sortIndex}`}
            onValueUpdate={e => {
              if (!!income.royalty_percentage) {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  income.royalty = e.target.value;
                  reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
                }
              }
              else {
                income.royalty = e.target.value;
                reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
              }

            }}
            onRadioUpdate={e => {
              if (e.target.value === "true" && income.royalty > 100) {
                income.royalty = '';
              }
              income.royalty_percentage = (e.target.value === "true");
              reCalculateAgentExpenses(income, listingData, parentIndex, setFinancial);
            }} />
        </Col>

        <Col xs={4}>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              className="description form-control"
              name={`royaltyNote${sortIndex}`}
              placeholder=""
              onChange={e => {
                income.royalty_notes = e.target.value;
                setFinancialData(listingData);
              }}
            >
              {income.royalty_notes}
            </textarea>
          </div>
        </Col>

        <Col xs={1} />

        <Col xs={2} className="text-right">
          <span>{formatExpense(format.currency(income.royalty_amount))}</span>
        </Col>

        <Col xs={2} className="text-right">
          <span>{format.currency(income.royalty_line_total)}</span>
        </Col>
      </Row>
    </div>
  </div>
)
