import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { User as UserIcon } from "../Icomoon";
import { sizeBasedClasses, borderValue, iconSizeValue, availableSizes } from "./Avatar.styles";
import { getInitials } from "../utils";

const Avatar = ({ className, size, border, alt, src, ...otherProps }) => {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    // Make sure that `src` is a valid image URL

    if (!src) {
      setImgSrc("");

      return;
    }

    setImgSrc("");

    const img = document.createElement("img");
    img.onload = () => {
      setImgSrc(img.src);
    };
    img.src = src;
  }, [src]);

  let children;

  // The variant to render is decided based on the props:
  //
  // * `alt` and `src` are provided:
  //   * `src` is a valid image URL: renders image
  //   * `src` is not a valid image URL: renders text in `alt`
  // * `children` is provided:
  //   * renders text in `children`
  // * nothing is provided:
  //   * renders user icon
  if (alt && src && imgSrc) {
    children = (
      <img
        className={`
          tw-w-full tw-h-full
          tw-rounded-full
          tw-object-cover
        `}
        alt={alt}
        src={src}
        data-cy="avatar-image"
      />
    );
  } else if (otherProps.children || alt) {
    const text = otherProps.children || alt;

    children = <span>{getInitials(text)}</span>;
  } else {
    children = (
      <UserIcon
        className={`
          tw-flex tw-justify-center tw-items-center
          tw-text-neutral-gray-30
        `}
        size={iconSizeValue(size)}
        data-cy="avatar-icon"
      />
    );
  }

  return (
    <div
      className={`
        tw-flex tw-justify-center tw-items-center tw-flex-none
        tw-rounded-full
        tw-overflow-hidden
        tw-bg-neutral-gray-10 tw-text-neutral-gray-75
        ${sizeBasedClasses(size)}
        ${borderValue(border)}
        ${className}
      `}
      data-cy="avatar"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([...availableSizes, "none"]),
  border: PropTypes.oneOf(["neutral", "tinted", "none"]),
  alt: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.string,
};

Avatar.defaultProps = {
  className: "",
  size: "small",
  border: "neutral",
  alt: "",
  src: "",
  children: "",
};

export default Avatar;
