import React from "react";
import PropTypes from "prop-types";

const BASE_CLASSES =
  "before:tw-table before:tw-content-['_'] after:tw-table after:tw-content-['_'] after:tw-clear-both";

const marginClasses = (deprecatedOverrideMarginClasses) => {
  if (deprecatedOverrideMarginClasses !== null) {
    return deprecatedOverrideMarginClasses;
  }

  return "tw--mx-10px";
};

export const Row = ({ children, deprecatedOverrideMarginClasses, className, ...props }) => {
  const classes = `
    ${BASE_CLASSES}
    ${marginClasses(deprecatedOverrideMarginClasses)}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
Row.propTypes = {
  children: PropTypes.node,
  deprecatedOverrideMarginClasses: PropTypes.string,
  className: PropTypes.string,
};
Row.defaultProps = {
  children: null,
  deprecatedOverrideMarginClasses: null,
  className: "",
};

export default Row;
