import React from "react";
import Proptypes from "prop-types";

const SelectButton = ({ selectedOption, option, handleClick, id }) => (
  <button
    type="button"
    id={id}
    onClick={handleClick}
    className={`tw-w-full tw-text-left focus:tw-outline-none tw-border-0 tw-py-[6px] tw-px-[12px] hover:tw-bg-tinted-gray-50 active:tw-bg-gray-10 tw-whitespace-nowrap ${
      selectedOption === id ? "tw-bg-gray-10 tw-font-semibold" : "tw-bg-transparent tw-font-normal"
    }`}
  >
    {option}
  </button>
);

SelectButton.propTypes = {
  option: Proptypes.string,
  handleClick: Proptypes.func,
  id: Proptypes.string,
  selectedOption: Proptypes.string,
};
SelectButton.defaultProps = {
  option: null,
  handleClick: null,
  id: null,
  selectedOption: null,
};

export default SelectButton;
