import React from "react";
import PropTypes from "prop-types";

const SpecializationSection = ({ title, values }) => (
  <div className="tw-mb-[20px]">
    <h3 className="tw-text-[12px] tw-mt-0 tw-font-semibold tw-text-neutral-gray-50">{title}</h3>
    {values?.map((val) => (
      <span
        key={val}
        className="tw-inline-flex tw-font-bold tw-text-[11px] tw-mr-2 tw-mb-2 tw-py-[4px] tw-px-[8px] tw-bg-neutral-gray-10 tw-text-neutral-gray-75 tw-rounded-full tw-h-[24px]"
      >
        {val}
      </span>
    ))}
  </div>
);

SpecializationSection.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SpecializationSection;
