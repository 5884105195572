import { useState, useEffect } from "react";

export default (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return loadValue(key) || defaultValue;
  });

  useEffect(() => {
    saveValue(key, value);
  }, [key, value]);

  return [value, setValue];
};

const saveValue = (key, value) => {
  const stringy = JSON.stringify(value);
  localStorage.setItem(key, stringy);
};

const loadValue = (key) => JSON.parse(localStorage.getItem(key));
