import React, { useState } from "react";
import { number } from "prop-types";
import AiAssistant from "@shared/AiAssistant";
import Header from "../../UI/Header";
import HeaderOptions from "./HeaderOptions";
import MentionablePersonTextArea from "../../../shared/mentions/MentionablePersonTextArea";
import FooterOptions from "./FooterOptions";
import AllNotes from "./AllNotes";
import { tdpNotes } from "../../../shared/HOCs/withRedux";
import { NOTABLEID } from "../../../shared/reduxRoutes/tdpDetailsRoutes";

const NotesWrapper = ({ notableId }) => {
  const [starred, setStarred] = useState(false);
  const [externallyVisible, setExternallyVisible] = useState(false);
  const [note, setNote] = useState({
    content: "",
  });

  return (
    <>
      <Header
        header="Notes"
        add={
          <div className="tw-ml-auto tw-flex tw-gap-[12px]">
            <AiAssistant
              textareaId="tdp-notes-input"
              messageType="Generic"
              onInsertClick={(text) => setNote({ ...note, content: text })}
            />
            <HeaderOptions
              starred={starred}
              setStarred={setStarred}
              externallyVisible={externallyVisible}
              setExternallyVisible={setExternallyVisible}
            />
          </div>
        }
      />
      <MentionablePersonTextArea
        id="tdp-notes-input"
        value={note.content}
        mentionable_id={notableId}
        mentionable_type="Listing"
        onChange={(e) =>
          setNote({
            ...note,
            content: e,
          })
        }
      />
      <FooterOptions
        note={note}
        setNote={setNote}
        starred={starred}
        externallyVisible={externallyVisible}
        setExternallyVisible={setExternallyVisible}
      />

      <AllNotes />
    </>
  );
};

export default tdpNotes({
  WrappedComponent: NotesWrapper,
  states: NOTABLEID,
});

NotesWrapper.propTypes = {
  notableId: number,
};

NotesWrapper.defaultProps = {
  notableId: "",
};
