/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Minus = (props) => (
  <Icon {...props} name="Minus">
    <path d="M14.454 9.614H1.546A1.076 1.076 0 0 1 .47 8.538V7.462c0-.594.482-1.076 1.076-1.076h12.908c.594 0 1.076.482 1.076 1.076v1.076c0 .594-.482 1.076-1.076 1.076z" />
  </Icon>
);

export default Minus;
