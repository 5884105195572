/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FileArrowUpSolidV6 = (props) => (
  <Icon {...props} name="File Arrow Up Solid V6">
    <path d="M10 0v4h4l-4-4zM9 4V0H3.5A1.5 1.5 0 0 0 2 1.5v13A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5V5h-3.972C9.447 5 9 4.553 9 4zm2.003 6.753c-.119.175-.309.247-.503.247s-.384-.073-.53-.22L8.75 9.559v3.191a.75.75 0 0 1-1.5 0V9.559l-1.22 1.22a.75.75 0 1 1-1.061-1.061l2.5-2.5a.75.75 0 0 1 1.061 0l2.5 2.5c.292.294.292.769-.027 1.035z" />
  </Icon>
);

export default FileArrowUpSolidV6;
