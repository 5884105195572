/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChartLineRegularV6 = (props) => (
  <Icon {...props} name="Chart Line Regular V6">
    <path d="M1.5 12.75c0 .416.336.75.75.75h13c.416 0 .75.334.75.75s-.334.75-.75.75h-13A2.25 2.25 0 0 1 0 12.75v-11a.75.75 0 0 1 1.5 0v11zm9.003-3.247c-.266.319-.741.319-1.034 0L6.972 7.059 4.503 9.503c-.266.319-.741.319-1.034 0-.291-.266-.291-.741 0-1.034l3-3a.734.734 0 0 1 .503-.219c.228 0 .419.078.531.219L10 7.941l3.719-3.722c.294-.291.769-.291 1.034 0 .319.294.319.769 0 1.034l-4.25 4.25z" />
  </Icon>
);

export default ChartLineRegularV6;
