import { useState } from "react";

export const onSelectFn = (sel, option, isSelected) => {
  let col = [...sel];
  if (isSelected) {
    col = col.filter((c) => c.id !== option.id);
  } else {
    col = [...col, option];
  }
  return col;
};

export const useOnAgentSelect = (selectedAgentsFilters) => {
  const [selectedAgents, onAgentSelect] = useState(
    selectedAgentsFilters
      ? selectedAgentsFilters.map((i, idx) => ({
          name: selectedAgentsFilters[idx].name,
          id: selectedAgentsFilters[idx].id,
        }))
      : [],
  );
  return [selectedAgents, onAgentSelect];
};

export const useOnPondSelect = (selectedPondsFilters) => {
  const [selectedPonds, onPondSelect] = useState(
    selectedPondsFilters
      ? selectedPondsFilters.ids.map((i, idx) => ({ name: selectedPondsFilters.names[idx], id: i }))
      : [],
  );
  return [selectedPonds, onPondSelect];
};
