import axios from "axios";

export const getAllPeopleFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/${uuid}/people/`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const searchPeopleFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/${uuid}/people/search`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const postPersonToApi = (person, uuid) =>
  axios.post(`/api/v4/transactions/${uuid}/people`, person, {
    params: { authenticity_token: window.ReactOnRails.authenticityToken() },
  });

export const getSourcesFromApi = () =>
  axios.get(`/sources`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const patchPersonToApi = (person, uuid, id) =>
  axios.patch(`/api/v4/transactions/${uuid}/people/${id}`, person, {
    params: { authenticity_token: window.ReactOnRails.authenticityToken() },
  });

export const deletePersonFromApi = (uuid, id) =>
  axios.delete(`/api/v4/transactions/${uuid}/people/${id}`, {
    params: { authenticity_token: window.ReactOnRails.authenticityToken() },
  });

export const getAvailablePeopleFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/${uuid}/people/available`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });
