import React, { useRef, useState } from "react";
import { useClickOutside } from "../../shared/hookHelpers";
import Results from "./Results";
import css from "./QuickSearch.module.css";
import { Search } from "../../shared/v2/Icomoon";
import useQuickSearch from "../../Dashboard/services/useQuickSearch";

const saveRecentSearch = (result) => {
  const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");
  for (let i = 0; i < recentSearches.length; i += 1) {
    if (recentSearches[i].path === result.path) {
      recentSearches.splice(i, 1);
      break;
    }
  }
  recentSearches.unshift(result);
  const limitedRecentSearches = recentSearches.slice(0, 5);
  localStorage.setItem("recentSearches", JSON.stringify(limitedRecentSearches));
};

const QuickSearch = () => {
  const ref = useRef();
  const [isActive, setIsActive] = useState(false);
  const [blockNextHover, setBlockNextHover] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [search, setSearch] = useState("");
  const { data, error, loading } = useQuickSearch({ search });
  useClickOutside(ref, () => setIsActive(false));

  const scrollIntoView = (dir) => {
    const searchInputContainer = ref.current?.querySelector("#searchResultItemActive");
    if (searchInputContainer) {
      if (dir === "up") {
        searchInputContainer.previousElementSibling.scrollIntoView({ block: "nearest" });
      }
      if (dir === "down") {
        searchInputContainer.nextElementSibling.scrollIntoView({ block: "nearest" });
      }
      setBlockNextHover(true);
    }
  };

  const handleKeyDown = (e) => {
    const searchValid = search.trim() && !error;
    const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");

    if (e.key === "Enter") {
      e.preventDefault();
      let selected;

      if (searchValid) {
        selected = data[cursor];
      } else if (cursor <= recentSearches.length - 1) {
        selected = recentSearches[cursor];
      }

      if (selected) {
        saveRecentSearch(selected);
        window.location.replace(selected.path);
      }
      // arrow up/down button should select next/previous list element
    } else if (e.key === "ArrowUp" && cursor > 0) {
      setCursor((c) => c - 1);
      scrollIntoView("up");
    } else if (
      e.key === "ArrowDown" &&
      ((searchValid && cursor < data.length - 1) || (!searchValid && cursor < recentSearches.length - 1))
    ) {
      setCursor((c) => c + 1);
      scrollIntoView("down");
    }
  };

  const handleHover = (index) => {
    if (!blockNextHover) setCursor(index);
    setBlockNextHover(false);
  };

  return (
    <div ref={ref}>
      <form role="search">
        <div className={css.searchDropdown}>
          <div className={css.searchInputContainer} id="searchInputContainer">
            <input
              className={`${css.formControl} !tw-bg-gray/10 !tw-text-inherit`}
              type="text"
              onFocus={() => setIsActive(true)}
              maxLength={50}
              placeholder="Search by name, address, phone or MLS#"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Search className="tw-absolute tw-right-[6px] tw-top-[calc(50%-8px)]" size="l" />
          </div>
          {isActive && (
            <Results
              searchResults={data}
              hasResults={search.trim() && data.length > 0}
              onHover={handleHover}
              searchTerm={search}
              cursor={cursor}
              searchTermLength={search.length}
              isFetching={loading}
              searchValid={search.trim()}
              saveRecentSearch={saveRecentSearch}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default QuickSearch;
