import React from "react";
import { func, string } from "prop-types";

import { putStatusAsThunk } from "../../actions/thunks";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

import Status from "../../shared/Status";

const ReferralStatus = ({ onPutReferralStatusAsThunk, uuid }) => (
  <Status
    uuid={uuid}
    labelClassName="tw-text-[14px] tw-leading-[16px]"
    onChangeHandlr={(status) => {
      onPutReferralStatusAsThunk({
        status,
        uuid,
      });
    }}
  />
);

const ReferralStatusState = tdpDetails({
  WrappedComponent: ReferralStatus,
  states: LISTING_UUID,
});

export default withDispatch({
  WrappedComponent: ReferralStatusState,
  actions: putStatusAsThunk,
  aliases: "onPutReferralStatusAsThunk",
});

ReferralStatus.propTypes = {
  onPutReferralStatusAsThunk: func,
  uuid: string.isRequired,
};

ReferralStatus.defaultProps = {
  onPutReferralStatusAsThunk: () => {},
};
