import React, { Component } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import AssignedPlanDetailPreview from "../../../AssignedPlans/components/AssignedPlanDetailPreview";
import  auto_plans from './auto_plans.module.css';

class PreviewAutoPlan extends Component {

  render() {
    const { activeAutoPlanActionId, autoPlanPreview, closeAutoPlanPreview, previewAutoPlanAction } = this.props
    return (
      <Modal id="preview-auto-plan-modal" className="person-details-modal" onHide={closeAutoPlanPreview} show>
        <Modal.Header>
          <Modal.Title>
            <Row>
              <Col xs={11}>
                <span className={auto_plans.headerName}>{`Auto Plan: ${autoPlanPreview.name}`}</span>
              </Col>
              <button type="button" className={`close ${auto_plans.closeAutoPlan}`}>
                <span aria-hidden="true" onClick={closeAutoPlanPreview}>×</span>
                <span className="sr-only">Close</span>
              </button>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignedPlanDetailPreview
            activeAutoPlanActionId={activeAutoPlanActionId}
            autoPlanPreview={autoPlanPreview}
            previewAutoPlanAction={previewAutoPlanAction}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default PreviewAutoPlan;
