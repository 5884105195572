import React from "react";
import PropTypes from "prop-types";
import IconButton from "../IconButton";
import { XmarkSolidV6 } from "../Icomoon";

const VARIANT_STYLES = {
  success: "tw-bg-semantic-green-5 tw-text-semantic-green-120",
  error: "tw-bg-semantic-red-5 tw-text-semantic-red-120",
  warning: "tw-bg-semantic-yellow-5 tw-text-semantic-yellow-120",
  info: "tw-bg-semantic-blue-5 tw-text-semantic-blue-120",
  brivity: "tw-bg-[#EEF9F9] tw-text-[#336E7B] tw-border-solid tw-border-[1px] tw-border-[#DEF3F3]",
  gray: "tw-bg-gray-5 tw-text-gray-120 tw-border-solid tw-border-[1px] tw-border-gray-10",
};

const Alert = ({ title, text, onClose, variant, containerClass }) => (
  <div
    className={`tw-relative tw-py-[12px] tw-px-[16px] tw-rounded-[8px] tw-flex tw-flex-col tw-gap-[4px] ${VARIANT_STYLES[variant]} ${containerClass}`}
  >
    {title && <h4 className="tw-m-0 tw-text-14d tw-font-bold">{title}</h4>}
    <div className="tw-m-0">{text}</div>
    {onClose && (
      <IconButton
        className="tw-absolute tw-top-0 tw-right-0 !tw-text-inherit"
        schema="transparent"
        onClick={onClose}
      >
        <XmarkSolidV6 size="l" />
      </IconButton>
    )}
  </div>
);

Alert.propTypes = {
  title: PropTypes.string,
  containerClass: PropTypes.string,
  text: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(Object.keys(VARIANT_STYLES)),
};

Alert.defaultProps = {
  title: "",
  containerClass: "",
  text: "",
  onClose: null,
  variant: "info",
};

export default Alert;
