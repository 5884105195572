/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SpinnerSolidV6 = (props) => (
  <Icon {...props} name="Spinner Solid V6">
    <path d="M9.5 1.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 9.5 1.5zm0 13a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 9.5 14.5zM0 8a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 0 8zm16 0a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 16 8zM2.343 13.656a1.5 1.5 0 1 1 2.122-2.122 1.5 1.5 0 0 1-2.122 2.122zm2.123-9.19a1.502 1.502 0 1 1 0-2.123 1.505 1.505 0 0 1 0 2.123zm7.068 7.068a1.504 1.504 0 0 1 2.122 0 1.499 1.499 0 0 1 0 2.122 1.499 1.499 0 0 1-2.122 0 1.504 1.504 0 0 1 0-2.122z" />
  </Icon>
);

export default SpinnerSolidV6;
