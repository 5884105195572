import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "@shared/v2/Toggle";
import {
  ArrowUpRightFromSquareSolidV6,
  BoxArchiveSolidV6,
  EllipsisSolidV6,
  PenToSquareSolidV6,
  TrashSolidV6,
} from "@shared/v2/Icomoon";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import ImagePlaceHolder from "./ImagePlaceHolder";
import AddressInfo from "./AddressInfo";
import PricesInfo from "./PricesInfo";
import PrimaryAgentInfo from "./PrimaryAgentInfo";
import Status from "../shared/Status";
import EditTitleModal from "./EditTitleModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import IconButton from "../../../shared/v2/IconButton";
import { putDetailAsThunk } from "../actions/thunks";

const Summary = () => {
  const dispatch = useDispatch();
  const uuid = useSelector((state) => state.tdpDetailsReducer.uuid);
  const status = useSelector((state) => state.tdpDetailsReducer.transactionDetails.status);
  const published = useSelector((state) => state.tdpDetailsReducer.headerDetails.published);
  const daysOnMarket = useSelector((state) => state.tdpDetailsReducer.headerDetails.days_on_market);
  const [showRename, setShowRename] = useState(false);
  const [showDeleteListing, setShowDeleteListing] = useState(false);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-gap-[10px] tw-mx-[32px] tw-h-[174px] tw-min-w-[1000px]">
        <div className="tw-flex tw-gap-[32px]">
          <ImagePlaceHolder />

          <AddressInfo setRename={setShowRename} />
        </div>
        <div className="tw-flex tw-justify-start tw-mt-[29px] tw-mb-[25px]">
          <PricesInfo />
          <div className="tw-flex tw-flex-col tw-justify-between   tw-w-[200px]">
            <PrimaryAgentInfo />
            <Status />
          </div>
          <div className="tw-relative tw-self-center tw-flex tw-items-center tw-justify-center">
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton schema="tertiary" data-cy="header-dropdown-toggle">
                  <EllipsisSolidV6 />
                </IconButton>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverItem onClick={() => setShowRename(true)} icon={<PenToSquareSolidV6 />}>
                  Rename
                </PopoverItem>
                {daysOnMarket && daysOnMarket !== "n/a" && (
                  <>
                    <PopoverItem
                      data-cy={
                        status === "archived"
                          ? "header-dropdown-delete-button"
                          : "header-dropdown-archive-button"
                      }
                      icon={status === "archived" ? <TrashSolidV6 /> : <BoxArchiveSolidV6 />}
                      className={status === "archived" ? "tw-text-semantic-red-120" : ""}
                      onClick={
                        status === "archived"
                          ? () => setShowDeleteListing(true)
                          : () =>
                              dispatch(
                                putDetailAsThunk({
                                  uuid,
                                  key: "status",
                                  value: "archived",
                                  group: "transactionDetails",
                                }),
                              )
                      }
                    >
                      {status === "archived" ? "Delete" : "Archive"}
                    </PopoverItem>
                    <hr className="tw-m-0 tw-border-[1px] tw-border-solid tw-border-gray-10" />
                    <PopoverItem
                      onClick={() => {
                        dispatch(
                          putDetailAsThunk({
                            uuid,
                            key: "published",
                            value: !published,
                            group: "headerDetails",
                          }),
                        );
                      }}
                    >
                      <Toggle
                        checked={published}
                        onChange={() => {}}
                        text={
                          <span className="tw-flex tw-gap-[8px] tw-items-center">
                            <ArrowUpRightFromSquareSolidV6 /> Publish
                          </span>
                        }
                        labelClassName="tw-pointer-events-none tw-mb-0"
                        labelPlacement="start"
                      />
                    </PopoverItem>
                  </>
                )}
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
      <EditTitleModal rename={showRename} setRename={setShowRename} />
      <ConfirmDeleteModal deleteListing={showDeleteListing} setDelete={setShowDeleteListing} />
      <div className="tw-flex tw-justify-center tw-items-center" />
    </>
  );
};

export default Summary;
