import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "../FieldLabelV1";
import Radio from "./Radio";

const FieldRadioV1 = ({
  options,
  value,
  onChange,
  label,
  disabled,
  required,
  flexStyle,
  toolTipInfoContent,
  toolTipPlacement,
  defaultValue,
}) => {
  useEffect(() => {
    if (!value && !!defaultValue) {
      onChange(defaultValue);
    }
  }, []);

  return (
    <div className={flexStyle}>
      {label && <FieldLabelV1 {...{ label, required, toolTipInfoContent, toolTipPlacement }} />}
      <div className="tw-flex tw-gap-[24px]">
        {options.map((option) => {
          return (
            <div
              className={`tw-flex tw-items-center tw-gap-2 hover:tw-cursor-pointer`}
              onClick={() => onChange(option)}
              key={option}
              data-cy={`${option}_radio_btn`}
            >
              <Radio checked={option === value} disabled={disabled} />
              <div className="tw-text-neutral-gray-75">{option}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FieldRadioV1.propTypes = {
  toolTipPlacement: PropTypes.string,
  toolTipInfoContent: PropTypes.element,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

FieldRadioV1.defaultProps = {
  toolTipInfoContent: null,
  toolTipPlacement: "",
  label: "",
  value: "",
  defaultValue: "",
  flexStyle: "tw-flex-1",
  required: false,
  disabled: false,
  options: ["Option1", "Option2"],
  onChange: () => {},
};

export default FieldRadioV1;
