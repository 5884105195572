import React from "react";
import Dropdown from "../../../../shared/v2/Dropdown";

const AvatarControl = ({ componentProps, data, children }) => {
  const src = data?.image_url ?? null;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Dropdown.Control {...componentProps} preserveStyle>
      <div
        className="tw-h-8 tw-w-8 tw-rounded-full tw-mr-2 tw-bg-center"
        style={{ backgroundImage: `url('${src}')` }}
      />
      {children}
    </Dropdown.Control>
  );
};
export default AvatarControl;
