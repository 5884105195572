import React, { useRef } from "react";
import Proptypes from "prop-types";

import Button from "@shared/v2/Button/Button";
import Upload from "@shared/v2/Icomoon/Icons/Upload";
import UploadDocContainer from "./UploadDocContainer";
import Addendum from "./Addendum";

const Addendums = ({
  addendums,
  handleFileDrop,
  handleDocumentUpload,
  handleAddendumsReplace,
  deleteAddendum,
  renameAddendum,
}) => {
  const hiddenUploadAddendumsInput = useRef(null);

  return (
    <div>
      <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
        Addendums
      </div>

      {addendums.map((addendum, index) => (
        <Addendum
          key={addendum.id}
          index={index}
          addendum={addendum}
          handleAddendumsReplace={handleAddendumsReplace}
          deleteAddendum={deleteAddendum}
          renameAddendum={renameAddendum}
        />
      ))}

      <div
        className="tw-border-gray-15 tw-border-dashed tw-bg-[#FAFAFA] tw-w-[552px] tw-mb-24px !tw-py-[13px] tw-px-15px tw-border tw-rounded-4px"
        data-cy="agreement-edit-documents-upload"
        onDrop={handleFileDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <input
          id="addendumUploadInput"
          type="file"
          ref={hiddenUploadAddendumsInput}
          onChange={handleDocumentUpload}
          style={{ display: "none" }}
        />
        <div className="!tw-flex tw-grid tw-grid-cols-6 tw-gap-[16px] tw-justify-between tw-h-[33px]">
          <UploadDocContainer />
          <div className="tw-col-end-8 tw-col-span-1 tw-pt-[4px]">
            <label htmlFor="addendumUploadInput">
              <Button
                size="small"
                schema="secondary"
                onClick={() => hiddenUploadAddendumsInput.current?.click()}
              >
                <Upload size="m" />
                {"   upload"}
              </Button>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

Addendums.propTypes = {
  addendums: Proptypes.arrayOf(Proptypes.shape({})),
  handleAddendumsReplace: Proptypes.func,
  deleteAddendum: Proptypes.func,
  renameAddendum: Proptypes.func,
  handleFileDrop: Proptypes.func,
  handleDocumentUpload: Proptypes.func,
};
Addendums.defaultProps = {
  addendums: [],
  handleAddendumsReplace: () => {},
  deleteAddendum: () => {},
  renameAddendum: () => {},
  handleFileDrop: () => {},
  handleDocumentUpload: () => {},
};
export default Addendums;
