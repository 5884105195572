import React from "react";
import PropTypes from "prop-types";

import Button from "../../../../shared/v2/Button";
import { Plus } from "../../../../shared/v2/Icomoon";

const ApplyAutoPlanCallToAction = ({ onApplyAutoPlanClicked }) => (
  <div className="tw-pb-16px">
    <p>No auto plans have been applied.</p>

    <Button schema="secondary" onClick={onApplyAutoPlanClicked}>
      <Plus size="s" className="tw-mr-4px" />
      Apply Auto Plan
    </Button>
  </div>
);
ApplyAutoPlanCallToAction.propTypes = {
  onApplyAutoPlanClicked: PropTypes.func.isRequired,
};

export default ApplyAutoPlanCallToAction;
