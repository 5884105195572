/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SortDownSolidV6 = (props) => (
  <Icon {...props} name="Sort Down Solid V6">
    <path d="m12.747 10.472-4.138 4.275a.855.855 0 0 1-1.217 0l-4.138-4.275C2.712 9.932 3.095 9 3.864 9h8.272c.767 0 1.151.931.611 1.472z" />
  </Icon>
);

export default SortDownSolidV6;
