import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import ErrorAlert from "@shared/UtilsV1/ErrorAlert";
import Modal from "@shared/v2/Modal";
import TextButton from "@shared/v2/TextButton";
import FolderTab from "@shared/UtilsV1/FolderTab";
import GeneralView from "./ModalViews/GeneralView";
import UserDetailsView from "./ModalViews/UserDetailsView";
import ContactInfoView from "./ModalViews/ContactInfoView";
import PersonalDetailsView from "./ModalViews/PersonalDetailsView";
import CustomDatesView from "./ModalViews/CustomDatesView";
import WebsiteInfoView from "./ModalViews/WebsiteInfoView";
import AccountSettingsView from "./ModalViews/AccountSettingsView";
import { useFeatureFlags } from "../reducers/layoutReducer/selectors";
import usePersonDetailsModal from "./hooks/usePersonDetailsModal";
import PdpLink from "./PdpLink";
import PhoneNumberModal from "./PhoneNumberModal";

const percentVisible = (parent, child) => {
  if (!child) return 0;
  const childTop = child.offsetTop;
  const childBottom = childTop + child.clientHeight;
  const parentTop = parent.scrollTop;
  const parentBottom = parentTop + parent.clientHeight;
  const totalHeight = Math.max(childBottom, parentBottom) - Math.min(childTop, parentTop);
  const parentCompensation = totalHeight - parent.clientHeight;
  const portionVisible = child.clientHeight - parentCompensation;
  return portionVisible <= 0 ? 0 : portionVisible / child.clientHeight;
};

const TAB_NAMES = {
  general: "General",
  userDetails: "User Details",
  personalDetails: "Personal Details",
  contactInfo: "Contact Info",
  customDates: "Custom Dates",
  accountSettings: "Account Settings",
  websiteInfo: "Website Info",
};

const PersonDetailsModal = ({
  onClose,
  person,
  personId,
  show,
  modalType = "lead",
  showAddDetailsButton,
  containerClassName = "",
}) => {
  const [activeTabName, setActiveTabName] = useState(TAB_NAMES.general);
  const GeneralViewRef = useRef();
  const UserDetailsViewRef = useRef();
  const ContactInfoViewRef = useRef();
  const PersonalDetailsViewRef = useRef();
  const CustomDatesViewRef = useRef();
  const WebsiteInfoViewRef = useRef();
  const AccountSettingsViewRef = useRef();
  const featureFlags = useFeatureFlags();
  const isEditing = Boolean(person?.id || personId);
  const typeName = modalType === "lead" ? "Contact" : "User";
  const title = isEditing ? `Edit ${typeName} Details` : `Add ${typeName}`;
  const { actions, errors, formLoading, showPhoneContext, loading } = usePersonDetailsModal({
    modalType,
    person,
    personId,
    show,
  });

  const onScroll = (e) => {
    let tmpVisible = 0;
    let pVisible = percentVisible(e.target, GeneralViewRef.current);
    const activeTab = [
      [AccountSettingsViewRef, TAB_NAMES.accountSettings],
      [WebsiteInfoViewRef, TAB_NAMES.websiteInfo],
      [CustomDatesViewRef, TAB_NAMES.customDates],
      [PersonalDetailsViewRef, TAB_NAMES.personalDetails],
      [ContactInfoViewRef, TAB_NAMES.contactInfo],
      [UserDetailsViewRef, TAB_NAMES.userDetails],
      [GeneralViewRef, TAB_NAMES.general],
    ].reduce((p, [ref, tabName]) => {
      tmpVisible = percentVisible(e.target, ref.current);
      if (tmpVisible >= pVisible) {
        pVisible = tmpVisible;
        return tabName;
      }
      return p;
    }, TAB_NAMES.general);
    if (activeTabName !== activeTab) setActiveTabName(activeTab);
  };

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[850px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
      show={show}
      onHide={onClose}
      containerClassName={containerClassName}
    >
      <Modal.Header
        className="tw-relative"
        title={title}
        description={
          modalType === "lead"
            ? "Fill out this basic info in order to keep in touch with this contact through your CRM."
            : ""
        }
        closeButton
      >
        <PdpLink personId={personId} />
      </Modal.Header>

      <Modal.Body className="tw-relative tw-flex tw-flex-col tw-overflow-hidden">
        {errors?.length > 0 && (
          <div className="tw-flex-1 tw-min-h-[72px] tw-max-h-[240px] tw-overflow-auto tw-mb-[8px]">
            <ErrorAlert errorMessages={errors} onClose={actions.clearErrors} />
          </div>
        )}

        {formLoading && (
          <div className="tw-flex tw-w-full tw-justify-center">
            <Spinner size="xxxl" className="tw-animate-spin" />
          </div>
        )}

        {!formLoading && (
          <div className="tw-flex tw-overflow-hidden">
            <div className="tw-w-[160px] tw-text-14px tw-text-neutral-gray-75 tw-border-r tw-border-solid tw-border-0 tw-border-neutral-gray-15 ">
              <FolderTab
                tabName={TAB_NAMES.general}
                isActive={activeTabName === TAB_NAMES.general}
                onClick={() => GeneralViewRef.current.scrollIntoView({ behavior: "smooth" })}
              />

              {modalType === "user" && (
                <FolderTab
                  tabName={TAB_NAMES.userDetails}
                  isActive={activeTabName === TAB_NAMES.userDetails}
                  onClick={() => UserDetailsViewRef.current.scrollIntoView({ behavior: "smooth" })}
                />
              )}

              <FolderTab
                tabName={TAB_NAMES.contactInfo}
                isActive={activeTabName === TAB_NAMES.contactInfo}
                onClick={() => ContactInfoViewRef.current.scrollIntoView({ behavior: "smooth" })}
              />
              <FolderTab
                tabName={TAB_NAMES.personalDetails}
                isActive={activeTabName === TAB_NAMES.personalDetails}
                onClick={() => PersonalDetailsViewRef.current.scrollIntoView({ behavior: "smooth" })}
              />

              <FolderTab
                tabName={TAB_NAMES.customDates}
                isActive={activeTabName === TAB_NAMES.customDates}
                onClick={() => CustomDatesViewRef.current.scrollIntoView({ behavior: "smooth" })}
              />

              {modalType === "user" && (
                <>
                  {featureFlags.userUpdate && (
                    <FolderTab
                      tabName={TAB_NAMES.websiteInfo}
                      isActive={activeTabName === TAB_NAMES.websiteInfo}
                      onClick={() => WebsiteInfoViewRef.current.scrollIntoView({ behavior: "smooth" })}
                    />
                  )}
                  <FolderTab
                    tabName={TAB_NAMES.accountSettings}
                    isActive={activeTabName === TAB_NAMES.accountSettings}
                    onClick={() => AccountSettingsViewRef.current.scrollIntoView({ behavior: "smooth" })}
                  />
                </>
              )}
            </div>
            <div className="tw-flex-1 tw-overflow-auto" onScroll={onScroll}>
              <div className="tw-px-[24px] tw-mr-[12px]">
                <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
              </div>
              <div className="tw-relative tw-flex-auto tw-flex tw-flex-col tw-p-[24px] tw-gap-[24px] tw-overflow-y-auto scrollbar">
                <div ref={GeneralViewRef}>
                  <GeneralView modalType={modalType} />
                </div>

                {modalType === "user" && (
                  <>
                    <hr className="tw-w-full tw-border-gray-10" />
                    <div ref={UserDetailsViewRef}>
                      <UserDetailsView />
                    </div>
                  </>
                )}

                <hr className="tw-w-full tw-border-gray-10" />
                <div ref={ContactInfoViewRef}>
                  <ContactInfoView modalType={modalType} />
                </div>

                <hr className="tw-w-full tw-border-gray-10" />
                <div ref={PersonalDetailsViewRef}>
                  <PersonalDetailsView
                    birthdateError={errors
                      ?.find((e) => e.startsWith("Birthdate"))
                      ?.replace("Birthdate: ", "")}
                  />
                </div>

                {(isEditing || modalType === "user") && (
                  <>
                    <hr className="tw-w-full tw-border-gray-10" />
                    <div ref={CustomDatesViewRef}>
                      <CustomDatesView />
                    </div>
                  </>
                )}

                {modalType === "user" && (
                  <>
                    {featureFlags.userUpdate && (
                      <>
                        <hr className="tw-w-full tw-border-gray-10" />
                        <div ref={WebsiteInfoViewRef}>
                          <WebsiteInfoView />
                        </div>
                      </>
                    )}
                    <div ref={AccountSettingsViewRef}>
                      <AccountSettingsView />
                    </div>
                  </>
                )}
              </div>
              <div className="tw-px-[24px] tw-mr-[12px]">
                <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
              </div>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between tw-items-center">
        <Button
          onClick={() => {
            actions.clearErrors();
            onClose();
          }}
          data-cy="ec-modal-cancel-btn"
          schema="tertiary"
          size="medium"
        >
          Cancel
        </Button>

        <div className="tw-flex tw-items-center tw-gap-[16px]">
          {showAddDetailsButton && modalType === "lead" && !personId && (
            <TextButton
              size="medium"
              disabled={formLoading || loading}
              onClick={() =>
                actions.upsertPerson({ modalType }).then(({ payload }) => {
                  if (payload.slug) window.location.assign(`/people/${payload.slug}`);
                })
              }
            >
              Add More Details
            </TextButton>
          )}

          {personId && (
            <TextButton
              size="medium"
              disabled={formLoading || loading}
              onClick={() => actions.upsertPerson({ modalType })}
            >
              Save Changes
            </TextButton>
          )}

          <Button
            className="tw-flex tw-items-center"
            size="medium"
            isLoading={formLoading || loading}
            onClick={() =>
              actions.upsertPerson({ modalType }).then(({ meta, payload }) => {
                if (meta.requestStatus === "fulfilled") onClose(payload);
              })
            }
          >
            Save & Close
          </Button>
        </div>
      </Modal.Footer>
      <PhoneNumberModal
        showContext={showPhoneContext}
        onClose={(phoneRemovalAck) => {
          if (phoneRemovalAck) {
            actions.setPersonValue({ key: "phoneRemovalAck", value: true });
            actions.upsertPerson({ modalType }).then(({ meta, payload }) => {
              if (meta.requestStatus === "fulfilled") onClose(payload);
            });
          }
          actions.setShowPhoneContext(null);
        }}
      />
    </Modal>
  );
};

PersonDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  person: PropTypes.shape(),
  personId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalType: PropTypes.oneOf(["lead", "user"]),
  showAddDetailsButton: PropTypes.bool,
  containerClassName: PropTypes.string,
};

PersonDetailsModal.defaultProps = {
  onClose: () => {},
  person: null,
  personId: null,
  modalType: "lead",
  showAddDetailsButton: true,
  containerClassName: "",
};

export default PersonDetailsModal;
