import { useState, useEffect } from "react";

const useToggleAndOptionsHandlr = (checked) => {
  const [options, showOptions] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(checked);
    showOptions(checked);
  }, [checked]);

  return [options, showOptions, toggle, setToggle];
};

export default useToggleAndOptionsHandlr;
