import React, { Component } from 'react';

export default class ListingAlertFrequencyEvent extends Component {
  constructor(props, context){
    super(props, context)

    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event
  }

  details = () => {
    let rows = [];
    const fields = this.event.timeline_fields;

    return (
      <div>
        <tr>
          {'Frequency: '}
          <span className="bold-detail" style={{textTransform: 'capitalize'}}>
            {fields.from}
          </span>
          {' to '}
          <span className="bold-detail" style={{textTransform: 'capitalize'}}>
            {fields.to}
          </span>
        </tr>
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event listing-alert-event">
        <div className="row">
          <div className="col-xs-1">
            <i className={"fa fa-bell-o brivity-blue timeline-icon-circle"}></i>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">
              {'ATTENTION: Brivity'}
            </span>
            {' auto-updated Listing Alert frequency for "'}
            <span className="bold-detail">
              {this.event.timeline_fields.name}
            </span>
            {'" due to lack of engagement.'}
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
