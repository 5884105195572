import React, { useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as creators from "../../actions/creators";
import { useOnSelectedChange, useSelectedTypesError, useSetPropertyTypes } from "./hookHelpers";

import { useDebounce } from "../../../shared/hookHelpers";
import PropertyList from "./list";

const PropertyTypesSelector = ({
  primaryAgentID,
  value,
  onChange,
  setFormError,
  propertyTypeOptions,
  setMLSPropertyTypes,
}) => {
  const [selectedTypes, setSelected] = useState(value);
  const hasError = Object.keys(selectedTypes).length === 0;

  const debouncedSelectedTypes = useDebounce(selectedTypes, 500);
  useOnSelectedChange(debouncedSelectedTypes, hasError, onChange);
  useSelectedTypesError({ selectedTypes, setFormError });
  useSetPropertyTypes({ primaryAgentID, setMLSPropertyTypes });
  return (
    <div data-cy="ptypes-wrapper" className="tw-mb-[24px]">
      <div className="tw-text-18d tw-text-gray-75 tw-mb-[8px]">Search Criteria</div>
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">
        Property Types
        <sup className={`tw-text-red-500 tw-ml-1 ${!hasError && "tw-invisible"}`}>*</sup>
      </span>
      <PropertyList
        propertyTypeOptions={propertyTypeOptions}
        setSelected={setSelected}
        selectedTypes={selectedTypes}
        hasError={hasError}
      />
      {!propertyTypeOptions && (
        <div className={`tw-mb-[8px] tw-text-12d ${hasError ? "tw-text-red-500" : "tw-text-gray-75"}`}>
          There are no property types available
        </div>
      )}
      <input type="hidden" name="property_type" value={Object.keys(selectedTypes).join(",")} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  propertyTypeOptions: state.addMarketReport.mlsToPropertyTypes,
});

export default connect(mapStateToProps, creators)(PropertyTypesSelector);

PropertyTypesSelector.propTypes = {
  onChange: Proptypes.func,
  value: Proptypes.shape({ name: Proptypes.bool }),
  setFormError: Proptypes.func,
  propertyTypeOptions: Proptypes.arrayOf(Proptypes.string),
  primaryAgentID: Proptypes.string,
  setMLSPropertyTypes: Proptypes.func,
};

PropertyTypesSelector.defaultProps = {
  onChange: () => {},
  value: {},
  setFormError: () => {},
  propertyTypeOptions: null,
  primaryAgentID: null,
  setMLSPropertyTypes: () => {},
};
