/* eslint-disable react/prop-types */

import React from "react";
import "./Checkbox.css";

const Checkbox = ({ checked, disabled, onChange, name, value, id }) => {
  let background = checked ? "tw-bg-teal" : "tw-bg-white";
  let border = checked ? "tw-border-teal" : "tw-border-gray-50";

  if (disabled) {
    border = "tw-border-gray-15";
    background = "tw-bg-gray-5";
  }

  return (
    <div>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={disabled ? () => {} : onChange}
        checked={checked}
        value={value}
        className={`${border} ${background} tw-border-solid tw-border tw-appearance-none tw-rounded-[3px] tw-m-0 tw-checkbox`}
      />
    </div>
  );
};

export default Checkbox;
