import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

export default class TaskEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.task_event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  headline = () => {
    return (
      <div>
        <span className="bold-detail">
          {this.event.owning_user_name}
        </span>
        {" completed a task"}
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event task-timeline-event" onClick={this.showModal}>
        <div className="row header">
          <div className="col-xs-1">
            <i className="fa fa-check-square-o timeline-icon-circle"/>
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">
              {`Details: ${this.event.description}`}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              Completed Task
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.headline()}
            <div className="row details-row details-header">
              {`Details: ${this.event.description}`}
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
