import React from "react";
import { SideContainer, WidgetContainer } from "../UI/Containers";
import Divider from "../UI/Divider";

import People from "./People";
import Documents from "./Documents";

const SideLeft = () => (
  <SideContainer>
    <WidgetContainer>
      <People />
    </WidgetContainer>
    <Divider />
    <WidgetContainer>
      <Documents />
    </WidgetContainer>
  </SideContainer>
);

export default SideLeft;
