import React from 'react';
import { ClosedVolumeForm } from './ClosedVolumeForm';
import TransactionFinancialDetail from './TransactionFinancialDetail';
import TransactionFinancialDetailForm from './TransactionFinancialDetailForm';
import Error from '../../../shared/Error';

import '../shared/Financials.sass';

class TransactionFinancial extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      additionalIncome: []
    }
  }

  componentDidMount(){      
    const { 
      listingData: { 
        transaction_expense_types: transactionExpenseList 
      },
      setTransactionExpenseList
    } = this.props;

    setTransactionExpenseList(transactionExpenseList);
  }

  componentWillReceiveProps() {
    if(this.props.listingData.transaction_income){
      let additionalIncome = this.props.listingData.transaction_income.custom_transaction_expenses_attributes.concat(this.props.listingData.transaction_income.custom_transaction_revenues_attributes);
      additionalIncome.sort(function (a, b) {
        return a.position - b.position
      });
      this.setState({ 'additionalIncome': additionalIncome });
    }
  }

  render() {
    const {
      listingData,
      closedVolume,
      isShowTransactionDetail,
      isEditTransactionDetail,
      setIsShowTrDetail,
      setIsEditTrDetail,
      setIsOpenAddAgentModal,
      setIsOpenNewIncomeModal,
      isOpenAddAgentModal,
      isOpenNewIncomeModal,
      saveClosedVolume,
      setFinancialData,
      setFinancial,
      setClosedVolume,
      isShowAlert,
      isShowDangerAlert,
      errors,
      setModalIndex,
      modalIndex,
      saveFinancialData,
      getAgentList,
      agentList,
      setIsExpenseModal,
      isExpenseModal,
      recalculate_transaction_gci_and_team_member_net_incomes,
      recalculate_all_team_member_net_incomes,
      recalculate_team_member_net_income,
      removeIncome,
      removeCollaboratorExpense,
      recalculateCollaboratorExpenses,
      setIsCollaboratorExpenseModalOpen,
      setCollaboratorModalIndex,
      collaboratorModalIndex,
      isCollaboratorExpenseModalOpen,
      transactionExpenseList
    } = this.props;

    if (!!listingData.transaction_income) {
      listingData.transaction_income.additionalIncome = this.state.additionalIncome;
    }

    return (
      <div>
        {
          isShowAlert &&
          <div className="alert alert-success" role="alert">
            Successfully updated.
          </div>
        }

        {isShowDangerAlert && <Error errorMessage={errors} />}

        {
          (!isShowTransactionDetail && !isEditTransactionDetail) &&
          <ClosedVolumeForm
            listingData={listingData}
            closedVolume={closedVolume}
            saveClosedVolume={saveClosedVolume}
            setClosedVolume={setClosedVolume}
            setIsShowTrDetail={setIsShowTrDetail} />
        }

        {
          isShowTransactionDetail &&
          <TransactionFinancialDetail
            setIsEditTrDetail={setIsEditTrDetail}
            setIsShowTrDetail={setIsShowTrDetail}
            listingData={listingData}
            removeIncome={removeIncome}
            removeCollaboratorExpense={removeCollaboratorExpense}
          />
        }

        {
          isEditTransactionDetail &&
          <TransactionFinancialDetailForm
            setIsOpenAddAgentModal={setIsOpenAddAgentModal}
            setIsOpenNewIncomeModal={setIsOpenNewIncomeModal}
            isOpenAddAgentModal={isOpenAddAgentModal}
            isOpenNewIncomeModal={isOpenNewIncomeModal}
            listingData={listingData}
            setFinancial={setFinancial}
            setFinancialData={setFinancialData}
            setModalIndex={setModalIndex}
            modalIndex={modalIndex}
            saveFinancialData={saveFinancialData}
            setIsExpenseModal={setIsExpenseModal}
            isExpenseModal={isExpenseModal}
            getAgentList={getAgentList}
            agentList={agentList}
            transactionExpenseList={transactionExpenseList}
            recalculate_transaction_gci_and_team_member_net_incomes={recalculate_transaction_gci_and_team_member_net_incomes}
            recalculate_all_team_member_net_incomes={recalculate_all_team_member_net_incomes}
            recalculate_team_member_net_income={recalculate_team_member_net_income}
            removeIncome={removeIncome}
            recalculateCollaboratorExpenses={recalculateCollaboratorExpenses}
            setIsCollaboratorExpenseModalOpen={setIsCollaboratorExpenseModalOpen}
            setCollaboratorModalIndex={setCollaboratorModalIndex}
            collaboratorModalIndex={collaboratorModalIndex}
            isCollaboratorExpenseModalOpen={isCollaboratorExpenseModalOpen}
            removeCollaboratorExpense={removeCollaboratorExpense}
          />
        }
      </div>
    );
  }
}

export default TransactionFinancial;
