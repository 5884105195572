import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";

import Avatar from "../../shared/v2/Avatar";
import Pagination from "../../shared/v2/Pagination";
import PhoneMissedSolidV6 from "../../shared/v2/Icomoon/Icons/PhoneMissedSolidV6";
import PhoneArrowDownLeftSolidV6 from "../../shared/v2/Icomoon/Icons/PhoneArrowDownLeftSolidV6";
import TableHeader from "./TableHeader";
import TableCell from "./TableCell";
import Arrows from "./Arrows";
import { getRecordingMetadata } from "./services";
import { durationDisplay, formatCallDuration } from "./helpers";
import { Section } from "../../Reports/components/BusinessDashboard";
import { ChevronDown, ChevronRight, PauseSolidV6, PlaySolidV6, VolumeSolidV6 } from "@shared/v2/Icomoon";
import ReportsFilters from "./ReportsFilters";

const TrackingNumbersLogs = ({
  agents,
  isAdmin,
  sort,
  setSort,
  offset,
  setOffset,
  logsCount,
  callLogs,
  selectedTrackingNumbers,
  trackingNumbers,
  handleTrackingNumberFilterOnChange,
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
  chosenView,
  logFilters,
  setLogFilters,
}) => {
  const handleSort = (field) => {
    let sortDirection = sort[1];

    if (field === sort[0]) {
      sortDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSort([field, sortDirection]);
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-stretch tw-flex-wrap tw-gap-8px tw-bg-tinted-gray-50">
      <ReportsFilters
        selectedTrackingNumbers={selectedTrackingNumbers}
        trackingNumbers={trackingNumbers}
        handleTrackingNumberFilterOnChange={handleTrackingNumberFilterOnChange}
        minDate={minDate}
        maxDate={maxDate}
        setMinDate={setMinDate}
        setMaxDate={setMaxDate}
        chosenView={chosenView}
        logFilters={logFilters}
        setLogFilters={setLogFilters}
        agents={agents}
      />
      <div className="tw-relative tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 bs-xs:tw-p-[15px] tw-flex tw-flex-col tw-p-[32px]">
        <TrackingNumbersLogTable
          callLogs={callLogs}
          handleSort={handleSort}
          sortField={sort[0]}
          sortDirection={sort[1]}
          totalCount={logsCount}
          currentOffset={offset}
          pageSize={25}
          onChangePage={setOffset}
          isAdmin={isAdmin}
        />
        {callLogs.length === 0 ? (
          <div className="tw-my-[88px] tw-text-18px tw-text-gray-50 tw-text-center">No Calls Recorded</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const LogRow = ({ log }) => {
  const [isVisible, setIsVisible] = useState(false);

  return [
    <tr
      className={`tw-h-36px tw-leading-[24px] tw-border-1px tw-border-solid tw-border-x-0 tw-border-gray-15 ${log.voicemailUrl ? "tw-cursor-pointer hover:tw-bg-[#f5f7f7]" : ""}`}
      onClick={() => log.voicemailUrl && setIsVisible(!isVisible)}
    >
      <TableCell>
        {log.voicemailUrl ? (
          <span className="tw-text-gray-50">{isVisible ? <ChevronDown /> : <ChevronRight />}</span>
        ) : (
          ""
        )}
      </TableCell>
      <TableCell>
        <a
          href={log.callerUrl}
          className="tw-font-bold tw-text-gray-75 hover:tw-text-gray-75 hover:tw-no-underline"
        >
          {log.callerFullName ? log.callerFullName : log.callerPhone}
        </a>
      </TableCell>
      <TableCell>
        <span className={log.isNumberDeleted ? "tw-text-gray-30" : ""}>
          {log.trackingNumberName}
          {log.isNumberDeleted ? " (Deleted)" : ""}
        </span>
      </TableCell>
      <TableCell>
        {log.answererFullName ? (
          <div className="tw-flex tw-items-center">
            <Avatar
              className="tw-mr-12px"
              size="small"
              src={log.answererAvatarUrl}
              alt={log.answererFullName}
            />
            <span>{log.answererFullName}</span>
          </div>
        ) : (
          "--"
        )}
      </TableCell>
      <TableCell>
        {log.outcome === "Talked" && <TalkedOutcome outcome={log.outcome} />}
        {log.outcome === "Missed" && <MissedOutcome outcome={log.outcome} />}
        {log.outcome === "Voicemail" && <VoicemailOutcome outcome={log.outcome} />}
      </TableCell>
      <TableCell>{log.duration === 0 ? "--" : durationDisplay(log.duration)}</TableCell>
      <TableCell>{log.createdAt}</TableCell>
    </tr>,
    isVisible ? (
      <tr className={isVisible ? "" : "tw-hidden"}>
        <td colSpan={7}>
          <ExpandedInfo recordingLink={log.voicemailUrl} />
        </td>
      </tr>
    ) : null,
  ];
};

const TrackingNumbersLogTable = ({
  callLogs,
  handleSort,
  sortField,
  sortDirection,
  totalCount,
  currentOffset,
  pageSize,
  onChangePage,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPage = currentOffset ? currentOffset / pageSize + 1 : 1;
  const nextPage = currentPage === totalPages ? currentPage : currentPage + 1;
  const previousPage = currentPage === 1 ? currentPage : currentPage - 1;

  const onGoToPage = (pageNumber) => {
    const newOffset = (pageNumber - 1) * pageSize;
    onChangePage(newOffset);
  };

  return (
    <div>
      <div className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0">
        <table className="tw-border-collapse tw-overflow-scroll tw-table-auto tw-flex-nowrap">
          <thead>
            <tr
              className={`tw-text-12px tw-text-gray-75 tw-border-1px tw-border-solid tw-border-b-0 tw-border-x-0 tw-border-gray-15 ${callLogs.length || 0 === 0 ? "tw-border-b-1px" : ""}`}
            >
              <th></th>
              <TableHeader>Caller</TableHeader>
              <TableHeader>Tracking Number</TableHeader>
              <TableHeader sortable={true} onClick={() => handleSort("user_id")}>
                Answered by <Arrows sortBy={sortField === "user_id" ? sortDirection : null} />
              </TableHeader>
              <TableHeader>Outcome</TableHeader>
              <TableHeader sortable={true} onClick={() => handleSort("duration")}>
                Duration <Arrows sortBy={sortField === "duration" ? sortDirection : null} />
              </TableHeader>
              <TableHeader sortable={true} onClick={() => handleSort("id")}>
                Date <Arrows sortBy={sortField === "id" ? sortDirection : null} />
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {callLogs.map((log) => (
              <LogRow key={`log-${log.callLogId}`} log={log} />
            ))}
          </tbody>
        </table>
      </div>
      {callLogs.length > 0 ? (
        <div className="tw-flex tw-justify-end tw-mt-32px">
          <Pagination
            currentPage={currentPage}
            nextPage={nextPage}
            prevPage={previousPage}
            totalPages={totalPages}
            onGoToPage={onGoToPage}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const RecordingPlayback = ({ recordingLink }) => {
  const audioRef = useRef();
  const animationRef = useRef();
  const progressRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [current, setCurrent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);

  const forUpdating = useCallback(() => {
    const totalPlayed = audioRef.current?.currentTime;
    const percentPlayed = duration === 0 ? 0 : totalPlayed / duration;

    setCurrent(totalPlayed);
    if (progressRef.current) {
      progressRef.current.style.width = `${percentPlayed * 100}%`;
    }

    animationRef.current = requestAnimationFrame(forUpdating);
  }, [audioRef, duration]);

  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]);

  useLayoutEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }

    animationRef.current = requestAnimationFrame(forUpdating);

    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [audioRef, isPlaying, forUpdating]);

  useEffect(() => {
    if (!recordingLink || !recordingLink.endsWith(".mp3")) {
      return;
    }

    const get = async () => {
      const data = await getRecordingMetadata(recordingLink);

      setDuration(data?.duration);
    };

    get();
  }, [recordingLink]);

  const onEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-16px">
      <div onClick={() => setIsPlaying(!isPlaying)}>
        <div className="tw-bg-gray-10 tw-w-32px tw-h-32px tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer">
          {isPlaying ? <PauseSolidV6 /> : <PlaySolidV6 />}
        </div>
      </div>
      <div>
        <span className="tw-whitespace-nowrap">
          {formatCallDuration(current) || "00:00"} / {formatCallDuration(duration)}
        </span>
      </div>
      <div className="tw-flex tw-items-center tw-w-full">
        <div className="tw-flex-1 tw-relative tw-overflow-hidden tw-h-8px tw-rounded-[8px] tw-bg-[#efefef]">
          <div
            ref={progressRef}
            className="tw-absolute tw-h-full tw-rounded-[8px]"
            style={{ backgroundColor: "#666666" }}
          />
        </div>
      </div>
      <div className="tw-ml-auto">
        <div className="tw-cursor-pointer tw-mt-5px" onClick={() => setIsVolumeOpen(!isVolumeOpen)}>
          <VolumeSolidV6 size="l" />
        </div>
        {isVolumeOpen && <Volume volume={volume} onChangeVolume={setVolume} />}
      </div>
      <audio ref={audioRef} src={recordingLink} preload="metadata" onEnded={onEnded} />
    </div>
  );
};

const ExpandedInfo = ({ recordingLink }) => {
  return (
    <div className="tw-bg-gray-10 tw-pl-64px tw-pb-48px tw-pr-32px tw-pt-32px">
      <Section className="tw-bg-white tw-p-24px">
        <RecordingPlayback recordingLink={recordingLink} />
      </Section>
    </div>
  );
};

const Volume = ({ volume, onChangeVolume }) => {
  const onChange = ({ target }) => {
    onChangeVolume(target.value / 100);
  };

  const volumeTo11 = volume * 100;

  return (
    <div className="tw-absolute tw-right-20px tw-top-[-110px] tw-w-[27px] tw-h-[126px] tw-z-50 tw-bg-white tw-shadow-modal tw-p-8px">
      <input
        className="tw-h-full tw-p-0"
        orient="vertical"
        style={{ WebkitAppearance: "slider-vertical" }}
        min="0"
        max="100"
        type="range"
        value={volumeTo11}
        onChange={onChange}
      />
    </div>
  );
};

const TalkedOutcome = ({ outcome }) => {
  return (
    <div className="tw-flex tw-items-center tw-text-[#36b25f]">
      <PhoneArrowDownLeftSolidV6 className="tw-mr-8px tw-w-16px tw-h-16px" />
      {outcome}
    </div>
  );
};

const MissedOutcome = ({ outcome }) => {
  return (
    <div className="tw-flex tw-items-center tw-text-[#e5342e]">
      <PhoneMissedSolidV6 className="tw-mr-8px tw-w-16px tw-h-16px" />
      {outcome}
    </div>
  );
};

const VoicemailOutcome = ({ outcome }) => {
  return (
    <div className="tw-flex tw-items-center tw-text-gray-75">
      <PlaySolidV6 className="tw-mr-8px tw-w-16px tw-h-16px" />
      {outcome}
    </div>
  );
};

export default TrackingNumbersLogs;
