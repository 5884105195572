/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VisibilityHidden = (props) => (
  <Icon {...props} name="Visibility Hidden">
    <path d="m7.966 11.555.852 1.217a8.597 8.597 0 0 1-.818.039A8.524 8.524 0 0 1 .644 8.638a1.252 1.252 0 0 1 0-1.274 8.461 8.461 0 0 1 2.51-2.681l1.492 2.132a3.553 3.553 0 0 0 3.318 4.741zm7.39-2.918a8.477 8.477 0 0 1-3.534 3.28l1.084 1.548a.627.627 0 0 1-.154.874l-.343.24a.627.627 0 0 1-.874-.154L3.093 2.534a.627.627 0 0 1 .154-.874l.343-.24a.627.627 0 0 1 .874.154l1.334 1.898a8.634 8.634 0 0 1 2.201-.284 8.525 8.525 0 0 1 7.356 4.173 1.252 1.252 0 0 1 0 1.274zM11.556 8a3.555 3.555 0 0 0-4.882-3.301l.505.707a2.722 2.722 0 0 1 2.102.194H9.28a1.119 1.119 0 1 0 1.119 1.119v-.001a2.719 2.719 0 0 1-.216 2.901l.505.708a3.539 3.539 0 0 0 .867-2.328zm-4.235 2.633L5.294 7.737a2.72 2.72 0 0 0 2.027 2.896z" />
  </Icon>
);

export default VisibilityHidden;
