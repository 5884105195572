import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: ["tw-inline-flex", "tw-flex-row", "tw-flex-wrap", "tw-justify-start", "tw-items-center"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
