import { sortBy } from "../../../../Transactions/utils/helpers";

import { nextIndexForProperty } from "./orderHelpers";

const companyOperations = (data) => {
  const collectionFor = (type) =>
    type === "expense"
      ? data.company_income.custom_team_member_expenses_attributes
      : data.company_income.custom_team_member_revenues_attributes;

  const combinedCollections = () => [...collectionFor("expense"), ...collectionFor("income")];
  const find = ({ type, id }) =>
    (type ? collectionFor(type) : combinedCollections()).find((lineItem) => lineItem.id === id);

  const nextPosition = () => nextIndexForProperty(combinedCollections(), "position");

  const reorderCollections = () => {
    // In place updating, as the rest of the actions
    // eslint-disable-next-line no-param-reassign
    data.company_income.custom_team_member_expenses_attributes = sortBy(
      data.company_income.custom_team_member_expenses_attributes,
      "position",
    );

    // In place updating, as the rest of the actions
    // eslint-disable-next-line no-param-reassign
    data.company_income.custom_team_member_revenues_attributes = sortBy(
      data.company_income.custom_team_member_revenues_attributes,
      "position",
    );
  };

  return {
    collectionFor,
    combinedCollections,
    find,
    nextPosition,
    reorderCollections,
  };
};

export default companyOperations;
