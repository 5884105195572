import React from "react";
import PropTypes from "prop-types";
import styleSchema, { availableSchema } from "./TextButton.styles";

const fontSizeDictionary = {
  "sentence-medium": "tw-text-12d",
  small: "tw-text-11d",
  medium: "tw-text-14d",
};

const fontWeightDictionary = {
  "sentence-medium": "tw-font-semibold",
  "sentence-small": "tw-font-normal",
  fallback: "tw-font-bold",
};

const fontSizeClass = (schema, size) => fontSizeDictionary[`${schema}-${size}`] || fontSizeDictionary[size];

const fontWeightClass = (schema, size) =>
  fontWeightDictionary[`${schema}-${size}`] || fontWeightDictionary.fallback;

const TextButton = ({ size, disabled, onClick, className, children, schema, ...otherProps }) => (
  <button
    type="button"
    className={` tw-bg-transparent tw-border-0 ${fontWeightClass(schema, size)} ${fontSizeClass(
      schema,
      size,
    )} ${styleSchema[schema]} ${className}`}
    onClick={onClick}
    disabled={disabled}
    {...otherProps}
  >
    {children}
  </button>
);

export const textButtonPropTypes = {
  schema: PropTypes.oneOf(availableSchema),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export const textButtonDefaultProps = {
  schema: "default",
  disabled: false,
  size: "small",
  children: null,
  onClick: null,
  className: "",
};

TextButton.propTypes = textButtonPropTypes;

TextButton.defaultProps = textButtonDefaultProps;

export default TextButton;
