import React, { useEffect, useState } from "react";
import Dropdown from "@shared/v2/Dropdown";
import { arrayOf, func, number, shape } from "prop-types";

const EmailTemplateDropdown = ({ options, setState, stateValue }) => {
  const [ value, setValue ] = useState(null);
  useEffect(() => {
    setValue(options.find(option => option.value  === stateValue));
  }, [stateValue])
  
  return (
    <Dropdown
      label='Email Template'
      placeholder='Choose email template'
      className='tw-z-2'
      options={options}
      value={value}
      onChange={setState}/>
  )
}

export default EmailTemplateDropdown;

EmailTemplateDropdown.propTypes = {
  options: arrayOf(shape({})).isRequired,
  setState: func.isRequired,
  stateValue: number
}

EmailTemplateDropdown.defaultProps = {
  stateValue: null
}