import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import { setResources, textColor } from "./helpers";
import { OPTIONS_BY_LABEL } from "./constants";
import ResourceLaunchers from "./ResourceLaunchers";

const AppsResources = () => {
  const theme = useSelector(({ layout }) => layout.theme.name);
  const isAdmin = useSelector(({ layout }) => layout.currentUser.account.isAdmin);
  const isOwner = useSelector(({ layout }) => layout.currentUser.account.isOwner);
  const appOptions = useSelector(({ layout }) =>
    layout.sidebar ? layout.sidebar?.menu.find((m) => m.name === "Apps").options : [],
  );

  // returns array of furnished {linkURL, Icon, label} for resourceLaunchers
  const resources = useMemo(() => {
    const rsrc = setResources({ theme, isAdmin, isOwner });

    const optionsByHeadline = appOptions.reduce(
      (acc, { headline, launchUrl }) => ({
        ...acc,
        [headline]: launchUrl,
      }),
      {},
    );

    return rsrc.map(({ label, linkUrl, ...rest }) => ({
      ...rest,
      linkUrl: optionsByHeadline[OPTIONS_BY_LABEL[label]] || linkUrl,
      label,
    }));
  }, [theme, isAdmin, isOwner, appOptions]);

  return (
    <div className="tw-p-[16px]">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-8px">
        <span className={textColor(theme)}>Apps</span>
      </div>
      <div className="tw-flex tw-justify-between tw-over tw-flex-wrap">
        <ResourceLaunchers resources={resources} />
      </div>
    </div>
  );
};

export default AppsResources;
