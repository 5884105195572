import React from "react";
import { shape, string, oneOfType, arrayOf } from "prop-types";
import {
  DescriptionForAdPhoto,
  DescriptionForUpload,
  DescriptionForCall,
  DescriptionForMessage,
  DescriptionForAppointment,
  DescriptionWithLabel,
  DescriptionForStatus,
  DescriptionForShowing,
  DescriptionForDate,
  DescriptionForOtherInteraction,
  DescriptionForUpdate,
  DescriptionForDocument,
  DescriptionForImport,
} from "./fractals";
import LinkifyNotes from "../../../shared/LinkifyNotes/LinkifyNotes";

const EventDescription = ({ description }) => (
  <div className="tw-mt-[4px]">
    {description === null && ""}
    {description?.variant === "normal" && <LinkifyNotes markdownContent={description.content} />}
    {description?.variant === "ads" && <DescriptionForAdPhoto content={description.content} />}
    {description?.variant === "upload" && <DescriptionForUpload content={description.content} />}
    {description?.variant === "call" && <DescriptionForCall content={description.content} />}
    {description?.variant === "message" && <DescriptionForMessage content={description.content} />}
    {description?.variant === "appointment" && <DescriptionForAppointment content={description.content} />}
    {description?.variant === "label" && <DescriptionWithLabel content={description.content} />}
    {description?.variant === "status" && <DescriptionForStatus content={description.content} />}
    {description?.variant === "showing" && <DescriptionForShowing content={description.content} />}
    {description?.variant === "date" && <DescriptionForDate content={description.content} />}
    {description?.variant === "other" && <DescriptionForOtherInteraction content={description.content} />}
    {description?.variant === "note" && <DescriptionForUpdate content={description.content} />}
    {description?.variant === "document" && <DescriptionForDocument content={description.content} />}
    {description?.variant === "import" && <DescriptionForImport content={description.content} />}
  </div>
);

EventDescription.propTypes = {
  description: shape({
    content: oneOfType([
      arrayOf(
        oneOfType([
          string,
          shape({
            link: string,
            text: string,
          }),
        ]),
      ),
      string,
      oneOfType([
        shape({
          type: string,
          time: string,
        }),
      ]),
      oneOfType([
        string,
        shape({
          text: string,
        }),
      ]),
    ]),
    variant: string,
  }),
};

EventDescription.defaultProps = {
  description: {
    variant: null,
    content: null,
  },
};

export default EventDescription;
