import { useEffect, useState, useRef } from "react";

export const useOnSelectedChange = (selected, fn) => {
  const isFirstRun = useRef(true);
  return useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fn(selected);
  }, [selected]);
};

export const useGetSelectedInxFromValues = ({ value, options, allowMultiple }) => {
  let defaultArr = [...value];
  if (!allowMultiple) {
    const idx = options.findIndex((o) => o.value === value[0] || o.value === value[0]?.toString());
    defaultArr = [idx];
  }
  const [selectedRangeIdx, setSelectedRange] = useState(defaultArr);
  return [selectedRangeIdx, setSelectedRange];
};
