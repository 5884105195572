import React from "react";
import PropTypes from "prop-types";

const BACKGROUND_COLOR_CLASSES =
  "tw-bg-color-eee disabled:tw-bg-gray enabled:focus:tw-bg-gray-light enabled:hover:tw-bg-gray-light enabled:active:tw-bg-gray-light enabled:active:hover:tw-bg-color-d4d4d4 disabled:hover:tw-bg-gray";

const BORDER_COLOR_CLASSES =
  "enabled:tw-border-gray-light enabled:focus:tw-border-color-8c8c8c enabled:hover:tw-border-color-adadad enabled:active:tw-border-color-adadad enabled:active:hover:tw-border-color-8c8c8c disabled:tw-border-gray-light disabled:hover:tw-border-color-ccc";

const TEXT_COLOR_CLASSES =
  "tw-text-color-333 focus:tw-text-color-333 hover:tw-text-color-333 active:tw-text-color-333";

const SIZE_CLASSES = {
  large: "tw-leading-1.3333333 tw-rounded-6px",
  small: "tw-leading-normal tw-rounded-3px",
  extraSmall: "tw-leading-normal tw-rounded-3px",
  default: "tw-leading-1.428571429 tw-rounded-4px",
};

const BASE_CLASSES =
  "tw-block tw-w-2/4 tw-h-[50px] tw-font-semibold hover:tw-no-underline focus:tw-no-underline tw-border tw-border-solid tw-p-[10px] tw-text-15px tw-text-center tw-align-middle tw-inline-block tw-m-[5px] tw-whitespace-nowrap tw-touch-action-manipulation tw-select-none tw-bg-none focus:tw-outline-bootstrap active:tw-outline-0 enabled:tw-cursor-pointer enabled:active:tw-shadow-bootstrap-15 disabled:!tw-cursor-not-allowed disabled:tw-opacity-65 disabled:tw-shadow-none";

const sizeClasses = (size) => SIZE_CLASSES[size];

export const LoadMoreButton = React.forwardRef(({ className, size, loading, onClick, ...props }, ref) => {
  const classes = `
    ${BASE_CLASSES}
    ${BACKGROUND_COLOR_CLASSES}
    ${BORDER_COLOR_CLASSES}
    ${TEXT_COLOR_CLASSES}
		${sizeClasses(size)}
    ${className}
  `;

  return (
    <button ref={ref} type="button" className={classes} onClick={onClick} {...props}>
      {loading ? "Loading..." : "Load More"}
    </button>
  );
});

LoadMoreButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["large", "small", "extraSmall", "default"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

LoadMoreButton.defaultProps = {
  className: "",
  size: "default",
  disabled: false,
  loading: false,
  onClick: null,
};

export default LoadMoreButton;
