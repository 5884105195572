import React, { useMemo, useState } from "react";
import { bool, func } from "prop-types";
import { Provider } from "react-redux";

import { useDebounce } from "@shared/hookHelpers";
import Modal from "@shared/v2/Modal";
import TextComboInput from "@shared/v2/TextComboInput";
import { MagnifyingGlassSolidV6 } from "@shared/v2/Icomoon";
import { fa6Brand, fa6Solid } from "./constants";

import AvailableIcon from "./AvailableIcon";

const MagnifyingGlassIcon = () => (
  <MagnifyingGlassSolidV6 size="m" className="tw-align-middle tw-text-neutral-gray-30" />
);

const useFilteredIcons = (icons, query) =>
  useMemo(() => icons.filter((className) => className.includes(query.replace(/ /g, "-"))), [icons, query]);

export const IconPickerModal = ({ onSelect, show, onHide }) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const solidIcons = useFilteredIcons(fa6Solid, debouncedQuery);
  const brandIcons = useFilteredIcons(fa6Brand, debouncedQuery);

  const onSelectHandlr = (classNameFont) => {
    onSelect(classNameFont);
    onHide();
  };

  return (
    <Modal
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px] tw-px-[24px]"
      show={show}
      onHide={onHide}
    >
      <Modal.Header title="Select an Icon" className="tw-mb-32px" closeButton />
      <div className="tw-p-[8px]">
        <TextComboInput
          trailing={<MagnifyingGlassIcon />}
          containerClassName="tw-mb-24px"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
          placeholder="Search for an icon"
        />
        <div className="tw-h-[240px] tw-w-[550px] tw-overflow-y-auto tw-flex tw-gap-[20px] tw-flex-wrap">
          {solidIcons.map((classNameFont) => (
            <AvailableIcon
              key={classNameFont}
              onSelect={() => onSelectHandlr(`fa6 fa6-${classNameFont}`)}
              classNameFont={classNameFont}
            />
          ))}
          {brandIcons.map((classNameFont) => (
            <AvailableIcon
              onSelect={() => onSelectHandlr(`fab fa6-${classNameFont}`)}
              classNameFont={classNameFont}
              fontPrefix="fab fa6"
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ({ onSelect, onHide, show }) => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <IconPickerModal onSelect={onSelect} onHide={onHide} show={show} />
    </Provider>
  );
};

IconPickerModal.propTypes = {
  onSelect: func,
  onHide: func,
  show: bool,
};

IconPickerModal.defaultProps = {
  onSelect: () => {},
  onHide: () => {},
  show: false,
};
