import React from "react";
import TaskFor from "./TaskFor";
import { Col } from "../../shared/v1";
import { useGivenOrGeneratedId } from "../../shared/hookHelpers";

const TaskableAssignment = ({ taskForWatcher, task_for, id: idFromProps }) => {
  const id = useGivenOrGeneratedId("task", idFromProps);
  return (
    <Col size={6} className="tw-mb-[16px]">
      <TaskFor id={id} onChange={taskForWatcher} taskFor={task_for} />
    </Col>
  );
};

export default TaskableAssignment;
