import queryString from "query-string";
// eslint-disable-next-line import/no-cycle
import { STATUSES } from "../components/Transaction";

// comingsoon -> coming_soon
const NORMALIZED_STATUSES = () => [...STATUSES, "coming_soon"];
const ENCODING_PROPS = { arrayFormat: "bracket" };

export const windowLocation = () => (window === undefined ? null : window.location);
export const queryToString = (params) => queryString.stringify(params, ENCODING_PROPS);
export const queryToObject = (query) => queryString.parse(query, ENCODING_PROPS);
export const pathStatus = (location) => {
  // /listings/[singleStatus]?queryString
  // listings/{{uuid|slug}}/section?queryString
  const parts = location.pathname.split("/");

  if (parts.length === 3 && NORMALIZED_STATUSES().includes(parts[2])) {
    return parts[2];
  }
  return undefined;
};

export const filters = (location) => {
  const searchParams = queryToObject(location.search);
  const statusFromPath = pathStatus(location);

  /* To pass in status (as pathName if available) as param to TDP */
  if (statusFromPath) {
    searchParams.status = [statusFromPath];
  }
  return searchParams;
};

export const currentPathStatus = () => pathStatus(windowLocation());
export const currentQueryAsObject = () => queryToObject(windowLocation().search);
export const currentQueryAsString = () => queryToString(currentQueryAsObject());
export const currentFilters = () => filters(windowLocation());
export const currentFiltersAsString = () => queryToString(currentFilters());

export const backToListingsUrl = (params) => `/listings?${queryToString(params)}`;
export const listingUrl = (url, params) => `${url}?${queryToString(params)}`;
