import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import { debounce } from "lodash";
import Pagination from "@shared/v2/Pagination";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const Pdf = ({ className, file, showPagination }) => {
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [passwordError, setPasswordError] = useState("");
  const [size, setSize] = useState(0);
  const pageRef = useRef(null);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (!pageRef.current) return;
        setSize(pageRef.current.clientWidth);
      }, 250),
    [pageRef.current, setSize],
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  if (passwordError) return <div>{passwordError}</div>;

  return (
    <>
      <div className={className} ref={pageRef}>
        <Document
          file={file}
          error="Failed to load document."
          onPassword={() => setPasswordError("Failed to load, this document is password protected.")}
        >
          <Page
            className="tw-flex"
            pageNumber={page}
            onLoadSuccess={({ _transport }) => {
              // eslint-disable-next-line no-underscore-dangle
              setPages(_transport._numPages);
              handleResize();
            }}
            renderAnnotationLayer={false}
            width={size}
          />
        </Document>
      </div>
      {showPagination && (
        <div className="tw-flex tw-justify-center">
          <Pagination totalPages={pages} currentPage={page} onGoToPage={setPage} />
        </div>
      )}
    </>
  );
};

Pdf.propTypes = {
  className: PropTypes.string,
  file: PropTypes.string.isRequired,
  showPagination: PropTypes.bool,
};

Pdf.defaultProps = {
  className: "",
  showPagination: false,
};

export default Pdf;
