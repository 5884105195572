/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const StarSolidV6 = (props) => (
  <Icon {...props} name="Star Solid V6">
    <path d="m10.589 5.064 3.992.589a.888.888 0 0 1 .495 1.511l-2.894 2.839.683 4.072a.892.892 0 0 1-1.294.933l-3.567-1.903-3.564 1.903c-.3.158-.664.133-.939-.064s-.414-.536-.356-.869l.683-4.072L.933 7.164a.893.893 0 0 1-.219-.911.885.885 0 0 1 .715-.6l3.988-.589 1.789-3.676a.886.886 0 0 1 1.597 0l1.786 3.676z" />
  </Icon>
);

export default StarSolidV6;
