import React from "react";

import TransactionAmt from "../TransactionAmt";
import ReferralFee from "../ReferralFee";

const FinancialDetails = () => (
  <div className="tw-w-[116px] tw-mr-56px">
    <TransactionAmt />
    <ReferralFee />
  </div>
);

export default FinancialDetails;
