import React from "react";
import PropTypes from "prop-types";
import Loading from "../../../Goals/components/Loader";
import Error from "../DashboardShared/Error";
import RollupStatCard from "../DashboardShared/RollupStatCard";
import useSummary from "./services/useSummary";
import { DashTooltipRow } from "../DashboardShared/DashTooltip";
import { YtdDifference } from "../BusinessDashboard/CardLabel";
import format from "../../../shared/formatters";

const SummarySection = ({ year }) => {
  const { actions, data, error, loading } = useSummary({ year });

  return (
    <div className="tw-relative">
      <div className="tw-relative tw-flex tw-flex-wrap tw-gap-[16px]">
        <RollupStatCard
          label="Company Dollar YTD"
          format="currency"
          value={data?.companyDollar.value}
          tooltip="Sum of Team GCI in Financials for Closed Transactions"
        />
        <RollupStatCard
          label="Closed Units YTD"
          overviewTooltip={
            <div className="tw-flex tw-flex-col tw-gap-[4px] tw-min-w-[180px]">
              <DashTooltipRow
                title="On Target:"
                value={
                  <>
                    <YtdDifference
                      format={data?.closedUnits.format}
                      value={data?.closedUnits.ytdDifferencePercent}
                      postfix="%"
                    />
                  </>
                }
              />
              <DashTooltipRow title="YTD Goal:" value={data?.closedUnits.ytdGoal} />
              <DashTooltipRow title="Annual Goal:" value={data?.closedUnits.goal} />
            </div>
          }
          overviewTooltipPlacement="right"
          format="number"
          value={data?.closedUnits.value}
          ytdDifference={data?.closedUnits.ytdDifference}
          text="on target"
        />
        <RollupStatCard
          label="GCI YTD"
          format="currency"
          overviewTooltip={
            <div className="tw-flex tw-flex-col tw-gap-[4px] tw-min-w-[180px]">
              <DashTooltipRow
                title="On Target:"
                value={
                  <>
                    <YtdDifference
                      format="number"
                      value={data?.closedGci.ytdDifferencePercent}
                      postfix="%"
                    />
                  </>
                }
              />
              <DashTooltipRow title="YTD Goal:" value={format.currency(data?.closedGci.ytdGoal)} />
              <DashTooltipRow title="Annual Goal:" value={format.currency(data?.closedGci.goal)} />
            </div>
          }
          overviewTooltipPlacement="right"
          value={data?.closedGci.value}
          ytdDifference={data?.closedGci.ytdDifference}
          text="on target"
        />
        <RollupStatCard
          label="Volume YTD"
          format="currency"
          overviewTooltip={
            <div className="tw-flex tw-flex-col tw-gap-[4px] tw-min-w-[180px]">
              <DashTooltipRow
                title="On Target:"
                value={
                  <>
                    <YtdDifference
                      format="number"
                      value={data?.closedVolume.ytdDifferencePercent}
                      postfix="%"
                    />
                  </>
                }
              />
              <DashTooltipRow title="YTD Goal:" value={format.currency(data?.closedVolume.ytdGoal)} />
              <DashTooltipRow title="Annual Goal:" value={format.currency(data?.closedVolume.goal)} />
            </div>
          }
          overviewTooltipPlacement="right"
          value={data?.closedVolume.value}
          ytdDifference={data?.closedVolume.ytdDifference}
          text="on target"
        />
        <RollupStatCard
          label="Operator % Production YTD"
          tooltip="Percentage of the team's closed units performed by the Operator"
          format="percent"
          value={data?.operatorPercentProduction.value}
          postfix="%"
          text={`Goal: ${data?.operatorPercentProduction.goal}%`}
          overviewTooltip={
            <DashTooltipRow title="Units:" value={data?.operatorPercentProduction.operatorUnits} />
          }
          overviewTooltipPlacement="left"
        />
      </div>
      <Error show={Boolean(error)} className="!tw-mb-0 tw-mt-[8px]" onClose={actions.clearError} />
      {loading && <Loading />}
    </div>
  );
};

SummarySection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default SummarySection;
