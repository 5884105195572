import React from 'react';
import { Col } from 'react-bootstrap';
import css from './report-styles.module.css';

const LeadSourceRow = (props) => {
  const { source, isOtherSource } = props;
  return (
    <div className='user td'>
      <Col
        xs={2}
        className={`${css.backgroundWhite} ellipsify`}>
        {isOtherSource ? source.other_source : source.source}
      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_new : source.new}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_unqualified : source.unqualified}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_watch : source.watch}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_nurture : source.nurture}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_hot : source.hot}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_past : source.past}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_inactive : source.inactive}
      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_archived: source.archived}

      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        {isOtherSource ? source.other_trash : source.trash}
      </Col>
      <Col
        xs={1}
        className={`text-center ${css.backgroundWhite}`}>
        <strong>
          {isOtherSource ? source.other_total_leads : source.total_leads}
        </strong>
      </Col>
    </div>
  )
}
export default LeadSourceRow;
