import React from "react";
import PropTypes from "prop-types";

import Dropdown from "../../shared/v2/Dropdown/Dropdown";

const formatOptionLabel = ({ label, link, isCurrentNumber }) =>
  isCurrentNumber ? (
    label
  ) : (
    <a href={link} data-method="patch">
      {label}
    </a>
  );

const RoutingNumbersDropdown = ({ selectedNumberId, numbers, changeRoutingNumber }) => {
  const phoneOptions = numbers.map((n) => ({
    value: n.detailId,
    label: `${n.routingNumber} (${n.category})`,
    link: n.updateRoutingPath,
    isCurrentNumber: selectedNumberId === n.detailId,
  }));

  const phone = phoneOptions.find((s) => s.value === selectedNumberId);

  return (
    <Dropdown
      className="tw-whitespace-nowrap tw-w-[210px]"
      value={phone}
      options={phoneOptions}
      formatOptionLabel={formatOptionLabel}
      onChange={changeRoutingNumber}
    />
  );
};

RoutingNumbersDropdown.propTypes = {
  selectedNumberId: PropTypes.string.isRequired,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      detailId: PropTypes.string,
      routingNumber: PropTypes.string,
      category: PropTypes.string,
      updateRoutingNumber: PropTypes.string,
    }),
  ).isRequired,
  changeRoutingNumber: PropTypes.func.isRequired,
};

export default RoutingNumbersDropdown;
