/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React from "react";
import { produce } from "immer";
import { arrayOf, bool, func, shape, string } from "prop-types";
import moment from "moment";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import { useSpecificActionSubmit } from "../../../reducers/autoPlanEdit";
import AssignToDropdown from "../AssignToDropdown";
import TaskDetailField from "./TaskDetailField";
import DateTime from '../DateTime';
import PriorityRadio from "../PriorityRadio";
// import RecurringFrequencyDropdown from "../RecurringFrequencyDropdown";
import TaskToggles from "../TaskToggles";
import { convertDateFormat, formatDateAndTimeToDatePicker, onSaveDateAdapter } from "../../helpers";
import { useFormState } from "../../hooks";
import ErrorInModal from "../ErrorInModal";

const DEFAULT_STATE = {
  assigned_to_id: null,
  assigned_to_role: 'owner',
  assigned_to_type: null,
  delay_unit: 'days',
  contingent: false,
  instructions: '',
  name: null,
  note: '',
  priority: 9,
  run_time: "17:00",
  day_month_year: moment().format('DD/MM/YYYY'),
  recurring_frequency: "never",
  recurring_days_of_frequency: "",
  schedule_type: "specific_date_time",
  type: "Task",
}

const TaskActionModal = ({show, onHide, onSave, form, assignedToOptions, autoPlanId, isSubmitting}) => {
  const formSubmitAction = useSpecificActionSubmit();
  const { state, setState, setStateHandlr } = useFormState({
    defaultState: DEFAULT_STATE,
    form,
    autoPlanId,
    show
  });

  const onHideHandlr = () => {
    setState({
      ...DEFAULT_STATE,
      auto_plan_id: autoPlanId,
    });
    onHide();
  }

  return (
    <Modal
      show={show}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      onHide={onHideHandlr}
      closeButton
    >
      <Modal.Header title={formSubmitAction === 'post' ? 'Create a new Task' : 'Update Task'} className="tw-mb-40px" closeButton />
      <Modal.Body className="tw-gap-[24px] tw-flex tw-flex-col tw-h-[480px] tw-overflow-y-auto">
        <TaskDetailField
          value={state.name}
          setValue={setStateHandlr('name')}/>
        <TaskToggles
          instructions={state.instructions}
          notes={state.note}
          setInstructions={setStateHandlr('instructions')}
          setNotes={setStateHandlr('note')}/>
        <DateTime
          setState={(dateStr) => {
            const { day_month_year, run_time } = convertDateFormat(dateStr);

            setState(prev => produce(prev, (draft) => {
              draft.day_month_year = day_month_year;
              draft.run_time = run_time;
            }))
          }}
          value={formatDateAndTimeToDatePicker(state.day_month_year, state.run_time)}/>
        <hr className="tw-w-full tw-border-gray-10 tw-m-0"/>
        <AssignToDropdown
          options={assignedToOptions}
          label='Assign To'
          state={{
            assigned_to_id: state.assigned_to_id,
            assigned_to_type: state.assigned_to_type,
            assigned_to_role: state.assigned_to_role,
          }}
          setState={(e) => setState(prev => ({
            ...prev,
            assigned_to_id: e.assigned_to_type ? e.value : null,
            assigned_to_role: e.assigned_to_type ? null : e.value,
            assigned_to_type: e.assigned_to_type || null,
          }))}/>
        <PriorityRadio
          value={state.priority} 
          setValue={setStateHandlr('priority')}/>
        {/* TODO: Re-enable when B/E is good to go */}
        {/* <RecurringFrequencyDropdown
          setValueOne={(freq) => setState((prev) => produce(prev, (draft) => {
            draft.recurring_days_of_frequency = '';
            draft.recurring_frequency = freq;
          }))}
          valueOne={state.recurring_frequency}/> */}
        <ErrorInModal/>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between tw-mt-[40px]">
        <Button size="medium" schema="secondary" onClick={onHideHandlr}>
          CANCEL
        </Button>

        <Button size="medium" schema="primary" onClick={() => onSave(onSaveDateAdapter(state))} isLoading={isSubmitting}>
          SAVE
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default TaskActionModal;

TaskActionModal.propTypes = {
  show: bool,
  onHide: func,
  onSave: func,
  isSubmitting: bool,
  form: shape({}),
  autoPlanId: string.isRequired,
  assignedToOptions: arrayOf(shape({})).isRequired,
}

TaskActionModal.defaultProps = {
  show: false,
  isSubmitting: false,
  onHide: () => {},
  onSave: () => {},
  form: {}
}