import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UsersFilter from "./UsersFilter";
import Button from "../../shared/v2/Button";
import CannotReActivateUserModal from "./CannotReActivateUserModal";
import { Plus } from "../../shared/v2/Icomoon";
import * as actions from "./actions/default";
import { sortListByKey } from "./helper";
import { useSetCrmModal } from "./helper/hookHelper";
import ErrorAlert from "../../shared/UtilsV1/ErrorAlert";
import CrmAccessModal from "./CrmAccessModal";
import { useCrmAccessUserId, useIsCrmAccessModalOpenSelector } from "../../reducers/userSettingsReducer";
import { useSendInviteEmail } from "./actions/apiActions";
import PersonDetailsModal from "../../Contacts/PersonDetailsModal";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import UsersTableHeader from "./UsersTable/UsersTableHeader";
import UsersTableBody from "./UsersTable/UsersTableBody";
import SuspendError from "./SuspendError";

const mapStateToProps = (state) => ({
  activeUsers: state.userSettings.activeUsers,
  archiveUsers: state.userSettings.archiveUsers,
  editPersonId: state.userSettings.editPersonId,
  isUserModalOpen: state.userSettings.isUserModalOpen,
  usersTableErrors: state.userSettings.usersTableErrors,
  tableSortColumn: state.userSettings.tableSortColumn,
});

const CrmAccessModalForUsers = () => {
  const isCrmAccessModalOpen = useIsCrmAccessModalOpenSelector();
  const userId = useCrmAccessUserId();
  const setCrmAccessModalHandlr = useSetCrmModal();
  const sendInviteEmail = useSendInviteEmail();

  return (
    <CrmAccessModal
      show={isCrmAccessModalOpen}
      onHide={() => setCrmAccessModalHandlr(false)}
      onSave={() => {
        sendInviteEmail(userId);
        setCrmAccessModalHandlr(false);
      }}
    />
  );
};

function Users({
  activeUsers,
  archiveUsers,
  isUserModalOpen,
  editPersonId,
  usersTableErrors,
  toggleUserModal,
  getUserTableData,
  setUsersTableErrors,
  setIsTableDataLoading,
  tableSortColumn,
}) {
  const currentUser = useCurrentUser();
  const isSeatsAvailable = currentUser.account.seats > 0;
  const [usersData, setUsersData] = useState([...activeUsers]);
  const [isFilterActiveUsers, setIsFilterActiveUsers] = useState(true);
  const [filterSearchText, setFilterSearchText] = useState("");

  const [isCannotReActivateUser, setIsCannotReActivateUser] = useState(false);

  const onLoadFunctions = () => {
    setIsTableDataLoading(true);
    getUserTableData();
  };

  useEffect(() => {
    onLoadFunctions();
  }, []);

  useEffect(() => {
    if (isFilterActiveUsers) {
      setUsersData([...activeUsers]);
      sortListByKey(tableSortColumn.key, setUsersData, tableSortColumn.isAsc);
    } else {
      setUsersData([...archiveUsers]);
      sortListByKey(tableSortColumn.key, setUsersData, tableSortColumn.isAsc);
    }
  }, [isFilterActiveUsers, activeUsers, archiveUsers]);

  useEffect(() => {
    if (filterSearchText === "") {
      setUsersData([...(isFilterActiveUsers ? activeUsers : archiveUsers)]);
    } else {
      const lowerCaseSearchText = filterSearchText.toLowerCase();
      setUsersData(() => {
        const curUsersData = [...(isFilterActiveUsers ? activeUsers : archiveUsers)];
        return curUsersData?.filter(
          ({ fullName, email }) =>
            fullName?.toLowerCase().includes(lowerCaseSearchText) ||
            email?.toLowerCase().includes(lowerCaseSearchText),
        );
      });
    }
  }, [filterSearchText]);

  useEffect(() => {
    sortListByKey(tableSortColumn.key, setUsersData, tableSortColumn.isAsc);
  }, [tableSortColumn]);

  return (
    <div
      className="tw-relative tw-h-[75vh] tw-w-full tw-px-4 tw-py-[24px] settings-users-tab-view"
      data-cy="settings-users-tab-view"
    >
      <SuspendError />
      {usersTableErrors.length > 0 && (
        <div className="tw-absolute tw-inset-5 tw-top-10 tw-z-1000">
          <ErrorAlert errorMessages={usersTableErrors} onClose={() => setUsersTableErrors([])} />
        </div>
      )}
      <div className="tw-flex tw-mb-24px tw-overflow-x-auto">
        <UsersFilter
          isFilterActiveUsers={isFilterActiveUsers}
          setFilterSearchText={setFilterSearchText}
          filterSearchText={filterSearchText}
          setIsFilterActiveUsers={setIsFilterActiveUsers}
        />

        {currentUser.account.isAdmin && (
          <Button
            onClick={() => {
              if (isSeatsAvailable) {
                toggleUserModal();
              } else {
                setIsCannotReActivateUser(true);
              }
            }}
            size="medium"
            data-cy="settings-users-add-new-user-button"
            className="tw-ml-auto"
          >
            <div className="tw-whitespace-nowrap">
              <Plus className="tw-mr-6px" />
              Add User
            </div>
          </Button>
        )}
      </div>

      <div className="tw-flex-1 tw-overflow-auto tw-h-full">
        <table className="tw-table-fixed table-sticky tw-w-full">
          <UsersTableHeader isFilterActiveUsers={isFilterActiveUsers} />
          <UsersTableBody usersData={usersData} isFilterActiveUsers={isFilterActiveUsers} />
        </table>
      </div>

      <PersonDetailsModal
        modalType="user"
        show={isUserModalOpen}
        personId={editPersonId}
        onClose={(person) => {
          if (person) onLoadFunctions();
          toggleUserModal();
        }}
      />

      {isCannotReActivateUser && (
        <CannotReActivateUserModal
          onClose={() => setIsCannotReActivateUser(false)}
          show={isCannotReActivateUser}
        />
      )}

      <CrmAccessModalForUsers />
    </div>
  );
}

const userPropTypes = PropTypes.objectOf(PropTypes.any);

Users.propTypes = {
  activeUsers: PropTypes.arrayOf(userPropTypes),
  archiveUsers: PropTypes.arrayOf(userPropTypes),
  editPersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isUserModalOpen: PropTypes.bool.isRequired,
  toggleUserModal: PropTypes.func.isRequired,
  getUserTableData: PropTypes.func.isRequired,
  setUsersTableErrors: PropTypes.func.isRequired,
  setIsTableDataLoading: PropTypes.func.isRequired,
  tableSortColumn: PropTypes.shape({ key: PropTypes.string, isAsc: PropTypes.bool }).isRequired,
  usersTableErrors: PropTypes.arrayOf(PropTypes.string),
};

Users.defaultProps = {
  activeUsers: null,
  archiveUsers: null,
  editPersonId: null,
  usersTableErrors: [],
};

export default connect(mapStateToProps, actions)(Users);
