const defaultState = {
    isShowTransactionDetail: false,
    isEditTransactionDetail: false,
    isOpenAddAgentModal: false,
    isOpenNewIncomeModal: false,
    isExpenseModal: false,
    isShowAlert: false,
    isShowDangerAlert: false,
    errors: null,
    listingData: {},
    modalIndex: null,
    agentList: [],
    closedVolume: 0,
    collaboratorModalIndex: null,
    isCollaboratorExpenseModalOpen: false
};

const transactionFinancialReducer = (state = defaultState, payload) => {
    switch (payload.type) {

        case "SET_IS_SHOW_TR_DETAIL":
            return {
                ...state,
                isShowTransactionDetail: payload.data
            };

        case "SET_IS_EDIT_TR_DETAIL":
            return {
                ...state,
                isEditTransactionDetail: payload.data,
                listingData: {
                    ...state.listingData,
                    removed_income_ids: [],
                    removed_collaborator_ids: []
                }
            };

        case "SET_IS_OPEN_ADD_AGENT_MODAL":
            return {
                ...state,
                isOpenAddAgentModal: payload.data
            };

        case "SET_IS_OPEN_NEW_INCOME_MODAL":
            return {
                ...state,
                isOpenNewIncomeModal: payload.data
            };

        case "SET_IS_EXPENSE_MODAL":
            return {
                ...state,
                isExpenseModal: payload.data
            };

        case "SET_ALERT":
            return {
                ...state,
                isShowAlert: payload.data,
                isShowDangerAlert: false,
                errors: null
            };

        case "SET_DANGER_ALERT":
            return {
                ...state,
                isShowDangerAlert: payload.data
            };

        case "SET_FINANCIAL_DATA":
            return {
                ...state,
                listingData: { ...payload.data }
            };

        case "ERR_DATA":
            return {
                ...state,
                errors: payload.errors
            };

        case "SET_MODAL_INDEX":
            return {
                ...state,
                modalIndex: payload.data
            };

        case "SET_AGENT_LIST":
            return {
                ...state,
                agentList: payload.data
            };

        case 'SET_CLOSED_VOLUME':
            return {
                ...state,
                closedVolume: payload.data
            }

        case "REMOVE_INCOME":
            return {
                ...state,
                listingData: {
                    ...state.listingData,
                    team_member_incomes: {
                        incomes: state.listingData.team_member_incomes.incomes.filter(income => income.id !== payload.data)
                    },
                    removed_income_ids: [...state.listingData.removed_income_ids, payload.data]
                }
            }

        case "REMOVE_COLLABORATOR_EXPENSE":
            return {
                ...state,
                listingData: {
                    ...state.listingData,
                    collaborator_expenses: {
                        expenses: state.listingData.collaborator_expenses.expenses.filter(expense => expense.id !== payload.data)
                    },
                    removed_collaborator_ids: [...state.listingData.removed_collaborator_ids, payload.data]
                }
            }

        case "SET_IS_COLLABORATOR_EXPENSE_MODAL_OPEN":
            return {
                ...state,
                isCollaboratorExpenseModalOpen: payload.data
            };

        case "SET_COLLABORATOR_MODAL_INDEX":
            return {
                ...state,
                collaboratorModalIndex: payload.data
            };

        default:
            return state;
    }
};

export default transactionFinancialReducer;
