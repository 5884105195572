import React from "react";
import { bool, func, string } from "prop-types";

import Checkbox from "../../../../shared/v2/Checkbox";

const DEFAULT_VALUES = {
  label: "Set as Primary Agent",
};

const PrimaryAgentCheckbox = ({ checked, onClickCheckBox, label, containerClassName, disabled, show }) =>
  show && (
    <div className={`tw-flex ${containerClassName}`}>
      <Checkbox
        className="tw-cursor-pointer"
        checked={checked}
        onClick={onClickCheckBox}
        disabled={disabled}
      />
      <span className="tw-ml-[7px]">{label}</span>
    </div>
  );

export default PrimaryAgentCheckbox;

PrimaryAgentCheckbox.propTypes = {
  checked: bool,
  disabled: bool,
  onClickCheckBox: func,
  label: string,
  containerClassName: string,
  show: bool,
};

PrimaryAgentCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  onClickCheckBox: () => {},
  label: DEFAULT_VALUES.label,
  containerClassName: "",
  show: true,
};
