import React from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const { MODAL_FOOTER_CLASSES } = CLASSES;

const ModalFooter = ({ children, className, ...props }) => (
  <div className={`${MODAL_FOOTER_CLASSES} ${className}`} {...props}>
    {children}
  </div>
);
ModalFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ModalFooter.defaultProps = {
  children: null,
  className: "",
};

export default ModalFooter;
