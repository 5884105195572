/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const User = (props) => (
  <Icon {...props} name="User">
    <path d="M8 8c2.244 0 4.064-1.791 4.064-4S10.245 0 8 0C5.756 0 3.937 1.791 3.937 4S5.756 8 8 8zm2.844 1h-.53a5.603 5.603 0 0 1-4.628 0h-.53C2.8 9 .889 10.881.889 13.2v1.3c0 .828.683 1.5 1.524 1.5h11.175c.841 0 1.524-.672 1.524-1.5v-1.3c0-2.319-1.911-4.2-4.267-4.2z" />
  </Icon>
);

export default User;
