import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import configureStore from "../../../store/configureStore";
import { defaultState } from "../../../reducers/superUsersBrandingReducer";
import { fromRailsToReact } from "./helpers/normalizer";
import Page from "./page";

const BrandingPage = ({
  id,
  is_place_super_user,
  db_brand_data,
  brands,
  brokerages,
  partners,
  people,
  place_branded_accounts,
  active_place_teams,
}) => {
  const store = useMemo(
    () =>
      configureStore({
        superUsersBranding: {
          ...defaultState,
          id,
          isPlaceSuperUser: is_place_super_user,
          formData: fromRailsToReact({
            dbFormData: db_brand_data,
            brands,
            brokerages,
            partners,
            people,
            placeBrandedAccounts: place_branded_accounts,
            activePlaceTeams: active_place_teams,
          }),
          brands,
          brokerages,
          partners,
          people,
          activePlaceTeams: active_place_teams,
          placeBrandedAccounts: place_branded_accounts,
        },
      }),
    [],
  );
  return (
    <Provider store={store}>
      <Page />
    </Provider>
  );
};

BrandingPage.propTypes = {
  label: PropTypes.string,
  comp: PropTypes.shape({}),
  className: PropTypes.string,
};

BrandingPage.defaultProps = {
  label: "",
  comp: <div />,
  className: "",
};
export default BrandingPage;
