import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { sortBy } from "lodash";
import { useDispatch } from "react-redux";
import { CircleSolidV6, SparklesSolidV6 } from "@shared/v2/Icomoon";
import Tooltip from "@shared/v2/Tooltip/Tooltip";
import Avatar from "@shared/v2/Avatar";
import { focusConversation, markAsRead } from "../../reducers/messagingReducer";
import AvatarCluster from "./AvatarCluster";
import {
  useDraftTextMessages,
  useFocusedConversation,
  useRecipientsHash,
} from "../../reducers/messagingReducer/selectors";
import { useTheme } from "../../reducers/layoutReducer/selectors";

const getIsFocused = (focusedConversation, conversation) => focusedConversation.uuid === conversation.uuid;

const ConversationDescription = ({ conversation, recipients }) => {
  const { uuid, friendlyName, mostRecentMessage, conversationUsers } = conversation;
  const createdByUser = conversationUsers?.find(
    (user) => user.personUuid === mostRecentMessage?.createdByUserPersonUuid,
  );

  if (friendlyName) {
    return (
      <div className="tw-flex tw-items-center tw-gap-[8px]">
        <span className="tw-font-semibold">{friendlyName}</span>
      </div>
    );
  }

  if (uuid === "temp") {
    return <span className="tw-font-semibold">{recipients || "New Message"}</span>;
  }

  return <span className="tw-font-semibold">{createdByUser?.alias}</span>;
};

ConversationDescription.propTypes = {
  conversation: PropTypes.shape().isRequired,
  recipients: PropTypes.string.isRequired,
};

const Conversation = ({ conversation = {} }) => {
  const dispatch = useDispatch();
  const isPlaceTheme = useTheme().name === "place-theme";
  const focusedConversation = useFocusedConversation();
  const recipientsHash = useRecipientsHash();
  const recipients = sortBy(
    Object.values(recipientsHash).map((r) => r.recipient.meta),
    "alias",
  );
  const draftMessages = useDraftTextMessages();
  const draftMessage = draftMessages[focusedConversation.uuid] || "";
  const isFocused = getIsFocused(focusedConversation, conversation);
  const isUnread = conversation.userUnreadMessageCount > 0;

  const clickConversation = () => {
    dispatch(focusConversation(conversation));
    if (isUnread) dispatch(markAsRead(conversation));
  };

  return (
    <div
      className={`tw-transition-all tw-w-full tw-flex tw-gap-[16px] tw-px-[24px] tw-py-[16px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-10 ${
        isFocused ? `${isPlaceTheme ? "tw-bg-semantic-blue-5" : "tw-bg-brivity-blue-10"}` : ""
      }`}
      onClick={clickConversation}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          clickConversation();
        }
      }}
    >
      <div className="tw-flex tw-items-center tw-gap-[8px]">
        {isUnread ? (
          <CircleSolidV6 size="s" className={isPlaceTheme ? "tw-text-semantic-blue-100" : "tw-text-teal"} />
        ) : (
          <div className="tw-w-[8px]" />
        )}
        <AvatarCluster>
          {conversation.isAiControlled && (
            <Tooltip
              content="AI is applied to this lead."
              innerClassName="tw-max-w-[100px]"
              trigger={
                <div className="tw-bg-semantic-purple-5 tw-w-[24px] tw-h-[24px] tw-border-solid tw-border-semantic-purple-10 tw-rounded-full tw-flex-none tw-flex tw-justify-center tw-items-center">
                  <SparklesSolidV6 className="tw-text-semantic-purple-100" />
                </div>
              }
            />
          )}
          {(conversation.uuid === "temp" ? recipients : conversation.conversationUsers).map((u) => (
            <Avatar key={u.uuid} src={u.avatar} alt={u.alias} />
          ))}
        </AvatarCluster>
      </div>

      <div className="tw-overflow-hidden tw-flex-1 tw-flex tw-flex-col tw-gap-[4px]">
        <ConversationDescription
          conversation={conversation}
          recipients={recipients.map((r) => r.alias).join(", ")}
        />
        <div className="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">
          {conversation.mostRecentMessage && (
            <>
              {conversation.mostRecentMessage.createdByAlias
                ? `${conversation.mostRecentMessage.createdByAlias}: `
                : ""}
              {conversation.mostRecentMessage.message || <span className="tw-italic">Attachment</span>}
            </>
          )}
          {conversation.uuid === "temp" && (draftMessage || "Draft message")}
          &nbsp;
        </div>
      </div>

      <div className="tw-text-12d tw-font-normal tw-text-neutral-gray-50">
        <Tooltip
          placement="left"
          trigger={moment(conversation.dateUpdated || conversation.dateCreated).fromNow()}
          content={moment(conversation.dateUpdated || conversation.dateCreated).format("MMM D, YYYY h:mm A")}
        />
      </div>
    </div>
  );
};

Conversation.propTypes = {
  conversation: PropTypes.shape().isRequired,
};

export default Conversation;
