import curriedNamespaceAction from "../../reducers/helpers";

const REDUCER_PSEARCH = "partnerSearch";
const wrap = curriedNamespaceAction(REDUCER_PSEARCH);

export const changeSearchTab = (tab) => wrap({ type: "SET_SEARCH_TAB", tab });
export const changeSearchTerm = (term) => wrap({ type: "SET_SEARCH_TERM", term });
export const changePlaceOnlySearch = (checked) => wrap({ type: "SET_PLACE_SEARCH", checked });
export const changeIsSearching = (searching) => wrap({ type: "SET_IS_SEARCHING", searching });
export const setDetailsId = (id) => wrap({ type: "OPEN_DETAILS", id });

export const getDetails = () => wrap({ type: "GET_DETAILS" });
export const getDetailsError = (error) => wrap({ type: "GET_DETAILS_ERROR", error });
export const getDetailsSuccess = (data) => wrap({ type: "GET_DETAILS_SUCCESS", data });

export const searchPartners = () => wrap({ type: "SEARCH_PARTNERS" });
export const searchPartnersError = (error) => wrap({ type: "SEARCH_PARTNERS_ERROR", error });
export const searchPartnersSuccess = (results) => wrap({ type: "SEARCH_PARTNERS_SUCCESS", results });
