import React from "react";

import HeaderWrapper from "./HeaderWrapper";
import FiltersWrapper from "./FiltersWrapper";
import MainWrapper from "./MainWrapper";

const TimelineWrapper = () => (
  <>
    <HeaderWrapper />
    <FiltersWrapper />
    <MainWrapper />
  </>
);

export default TimelineWrapper;
