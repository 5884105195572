import React from "react";

import Dropdown from "../../../shared/v2/Dropdown/Dropdown";
import Avatar from "../../../shared/v2/Avatar";

const UsersDropdown = ({selectedUser, users, setSelectedUser}) => {
  const userOptions = users.map((u) => ({ value: u["user_id"], label: `${u["first_name"]} ${u["last_name"]}`, avatar: u["avatar_url"] }))
    .sort((a, b) => a.label > b.label ? 1 : -1);

  const user = userOptions.find(s => s.value === selectedUser);

  const onOptionSelected = ({value}) => {
    setSelectedUser(value);
  };

  return (
    <Dropdown
      className="tw-whitespace-nowrap tw-w-[185px]"
      value={user}
      options={userOptions}
      onChange={onOptionSelected}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default UsersDropdown;

const formatOptionLabel = ({label, avatar}) => {
  return (
    <div className="tw-flex tw-flex-row tw-items-center">
      <Avatar className="tw-mr-8px" size="small" src={avatar} alt={label} />
      <div className="tw-overflow-ellipsis tw-w-[90px] tw-font-normal">{label}</div>
    </div>
  );
};
