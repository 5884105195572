import React from "react";
import PropTypes from "prop-types";
import colors from "../../Reports/components/DashboardShared/helpers/colors";

const Section = ({ children, className, isEditing }) => (
  <div
    className={`tw-bg-white tw-p-[16px] sm:tw-p-[32px] tw-w-full tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 tw-rounded-[8px] ${
      isEditing
        ? `tw-ring-2 ${
            colors.theme === "brivity"
              ? "tw-border-brivity-blue-100 tw-ring-brivity-blue-40"
              : "tw-border-semantic-blue-100 tw-ring-semantic-blue-25"
          }`
        : ""
    } ${className}`}
  >
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isEditing: PropTypes.bool,
};

Section.defaultProps = {
  className: "",
  isEditing: false,
};

export default Section;
