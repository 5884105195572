import React, { useState, useEffect } from "react";

import AgentListingRow from "./AgentListingRow";
import HeaderCell from "../../../Transactions/components/shared/HeaderCell";

const AgentListingsTable = ({ mlsListings }) => {
  const [ sortField, setSortField ] = useState("listDate");
  const [ sortOrder, setSortOrder ] = useState("desc");
  const [ listings, setListings ] = useState(mlsListings);

  const onSort = (field, order) => {
    const sorted = sortListings(listings, field, order);

    setListings(sorted);
    setSortOrder(order);
    setSortField(field);
  };

  const style = {
    flexBasis: "100%",
    marginLeft: "-20px",
    marginRight: "-20px",
  };
  const padding = "tw-px-20px tw-pb-8px tw-pt-0 tw-border-b tw-border-r-0 tw-border-l-0 tw-border-t-0 tw-border-solid tw-border-gray-10";
  const sortOptions = ["asc", "desc"];
  const getSortOrder = (field) => (sortField === field ? sortOrder : null);

  useEffect(() => {
    setListings(sortListings(mlsListings, sortField, sortOrder));
  }, [mlsListings]);

  return (
    <div
      style={style}
      className="tw-width-auto tw-relative tw-flex tw-flex-col tw-flex-nowrap tw-overflow-auto tw-grow tw-shrink-0 transactions-table-container"
    >
      <table
        className="tw-border-separate tw-overflow-scroll tw-table-auto tw-flex-nowrap"
        style={{ borderSpacing: 0 }}
      >
        <thead>
          <tr className="tw-text-gray-50 tw-h-32px tw-bg-white tw-sticky tw-top-0 tw-z-20 tw-whitespace-nowrap">
            <HeaderCell px={padding} title="Address" />
            <HeaderCell px={padding} title="MLS#" />
            <HeaderCell
              px={padding}
              title="Represented"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("represented")}
              onSort={(order) => onSort("represented", order)}
            />
            <HeaderCell
              px={padding}
              title="Status"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("displayStatus")}
              onSort={(order) => onSort("displayStatus", order)}
            />
            <HeaderCell
              px={padding}
              justify="tw-justify-end"
              title="List Price"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("price")}
              onSort={(order) => onSort("price", order)}
            />
            <HeaderCell
              px={padding}
              title="List Date"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("listDate")}
              onSort={(order) => onSort("listDate", order)}
            />
            <HeaderCell
              px={padding}
              justify="tw-justify-end"
              title="Sold Price"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("soldPrice")}
              onSort={(order) => onSort("soldPrice", order)}
            />
            <HeaderCell
              px={padding}
              title="Sold Date"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("soldDate")}
              onSort={(order) => onSort("soldDate", order)}
            />
            <HeaderCell px={padding} title="City" />
            <HeaderCell px={padding} title="Neighborhood" />
            <HeaderCell
              px={padding}
              title="Beds"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("bedrooms")}
              onSort={(order) => onSort("bedrooms", order)}
            />
            <HeaderCell
              px={padding}
              title="Baths"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("bathsTotalDecimal")}
              onSort={(order) => onSort("bathsTotalDecimal", order)}
            />
            <HeaderCell
              px={padding}
              justify="tw-justify-end"
              title="SQFT"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("squareFeet")}
              onSort={(order) => onSort("squareFeet", order)}
            />
            <HeaderCell
              px={padding}
              justify="tw-justify-end"
              title="$/SF"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("pricePerSquareFoot")}
              onSort={(order) => onSort("pricePerSquareFoot", order)}
            />
            <HeaderCell
              px={padding}
              title="Lot Size (Acres)"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("lotSize")}
              onSort={(order) => onSort("lotSize", order)}
            />
            <HeaderCell px={padding} title="Year Built" />
            <HeaderCell
              px={padding}
              title="Property Type"
              isSortable
              sortOptions={sortOptions}
              sortedBy={getSortOrder("propertyType")}
              onSort={(order) => onSort("propertyType", order)}
            />
            <HeaderCell px={padding} justify="tw-justify-end" title="HOA Fee" />
          </tr>
        </thead>
        <tbody className="tw-text-gray-99">
          {listings.map((l, index) => (
            <AgentListingRow
              key={l.blossorId}
              listing={l}
              isFirstRow={index === 0}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AgentListingsTable;

const sortListings = (listings = [], sortField, sortOrder) => {
  return [...listings].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === "asc" ? -1 : 1;
    }

    if (a[sortField] > b[sortField]) {
      return sortOrder === "asc" ? 1 : -1;
    }

    return 0;
  });
};
