import PropTypes from "prop-types";
import React from "react";
import css from "./QuickSearch.module.css";

const Result = ({ result, cursor, index, onHover, saveRecentSearch }) => {
  const itemClass = cursor === index ? css.searchResultItemActive : css.searchResultItem;

  const onClick = () => {
    saveRecentSearch(result);
    window.location.replace(result.path);
  };

  return (
    // This is handled by the input element in the parent component
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={cursor === index ? 0 : -1}
      id={cursor === index ? "searchResultItemActive" : "searchResultItem"}
      className={itemClass}
      data-slug={result.slug}
      data-item-id={result.id}
      onClick={onClick}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={(e) => onHover(index, e)}
    >
      <i className={`${result.icon} ${result.color}`} />
      {result.label}
      <span className={result.color}>
        {" "}
        ({result.status} {result.type})
      </span>
    </div>
  );
};

Result.propTypes = {
  result: PropTypes.shape().isRequired,
  onHover: PropTypes.func.isRequired,
  cursor: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  saveRecentSearch: PropTypes.func.isRequired,
};

export default Result;
