/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";

import CLASSES from "./classes.json";

const {
  BASE_CLASSES,
  INLINE_CLASSES,
  NORMAL_CLASSES,
  INLINE_MARGIN_CLASSES,
  NORMAL_MARGIN_CLASSES,
  BASE_LABEL_CLASSES,
  INLINE_LABEL_CLASSES,
  NORMAL_LABEL_CLASSES,
  INLINE_LABEL_PADDING_CLASSES,
  NORMAL_LABEL_PADDING_CLASSES,
  BASE_LABEL_TEXT_CLASSES,
  INLINE_LABEL_TEXT_CLASSES,
  NORMAL_LABEL_TEXT_CLASSES,
  CHECKED_COLOR_CLASSES,
  UNCHECKED_COLOR_CLASSES,
  BASE_CHECKBOX_CLASSES,
  INLINE_CHECKBOX_CLASSES,
  NORMAL_CHECKBOX_CLASSES,
  INLINE_CHECKBOX_MARGIN_CLASSES,
  NORMAL_CHECKBOX_MARGIN_CLASSES,
} = CLASSES;

export const Checkbox = React.forwardRef(
  (
    {
      id: idFromProps,
      className,
      labelClassName,
      checkboxClassName,
      labelTextClassName,
      name,
      inline,
      deprecatedOverrideMarginClasses,
      deprecatedOverrideLabelPaddingClasses,
      deprecatedOverrideCheckboxMarginClasses,
      checkedColor,
      uncheckedColor,
      children,
      ...props
    },
    ref,
  ) => {
    const id = useGivenOrGeneratedId("checkbox", idFromProps);

    const classes = `
      ${BASE_CLASSES}
      ${inline ? INLINE_CLASSES : NORMAL_CLASSES}
      ${deprecatedOverrideMarginClasses || (inline ? INLINE_MARGIN_CLASSES : NORMAL_MARGIN_CLASSES)}
      ${className}
    `;

    const labelClasses = `
      ${BASE_LABEL_CLASSES}
      ${inline ? INLINE_LABEL_CLASSES : NORMAL_LABEL_CLASSES}
      ${
        deprecatedOverrideLabelPaddingClasses ||
        (inline ? INLINE_LABEL_PADDING_CLASSES : NORMAL_LABEL_PADDING_CLASSES)
      }
      ${labelClassName}
    `;

    const labelTextBeforeContent = inline
      ? ""
      : String.raw`before:tw-content-["\f096"] peer-checked:before:tw-content-["\f14a"]`;

    const labelTextClasses = `
      ${BASE_LABEL_TEXT_CLASSES}
      ${CHECKED_COLOR_CLASSES[checkedColor]}
      ${UNCHECKED_COLOR_CLASSES[uncheckedColor]}
      ${inline ? INLINE_LABEL_TEXT_CLASSES : NORMAL_LABEL_TEXT_CLASSES}
      ${labelTextBeforeContent}
      ${labelTextClassName}
    `;

    const checkboxClasses = `
      ${BASE_CHECKBOX_CLASSES}
      ${inline ? INLINE_CHECKBOX_CLASSES : NORMAL_CHECKBOX_CLASSES}
      ${
        deprecatedOverrideCheckboxMarginClasses ||
        (inline ? INLINE_CHECKBOX_MARGIN_CLASSES : NORMAL_CHECKBOX_MARGIN_CLASSES)
      }
      ${checkboxClassName}
    `;

    return (
      <div className={classes}>
        <label htmlFor={id} className={labelClasses}>
          <input ref={ref} name={name} id={id} type="checkbox" className={checkboxClasses} {...props} />
          <span className={labelTextClasses}>{children}</span>
        </label>
      </div>
    );
  },
);

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  deprecatedOverrideMarginClasses: PropTypes.string,
  deprecatedOverrideLabelPaddingClasses: PropTypes.string,
  deprecatedOverrideCheckboxMarginClasses: PropTypes.string,
  checkedColor: PropTypes.oneOf(["default", "info"]),
  uncheckedColor: PropTypes.oneOf(["default", "info"]),
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  id: "",
  className: "",
  labelClassName: "",
  checkboxClassName: "",
  labelTextClassName: "",
  inline: false,
  deprecatedOverrideMarginClasses: "",
  deprecatedOverrideLabelPaddingClasses: "",
  deprecatedOverrideCheckboxMarginClasses: "",
  checkedColor: "default",
  uncheckedColor: "default",
  children: null,
};

export default Checkbox;
