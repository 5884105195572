import React from "react";

import { BookmarkSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const SavedSearchActivity = ({ activity, onReadChange }) => {
  const {
    searchLocation,
    searchBedsMin,
    searchBedsMax,
    searchBathsMin,
    searchBathsMax,
    searchPriceMin,
    searchPriceMax,
    additionalFilters,
  } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={BookmarkSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <PipedElements>
          {searchLocation && <span>{searchLocation}</span>}
          {(searchBedsMin || searchBedsMax) && (
            <span>
              Bed: {searchBedsMin} - {searchBedsMax}
            </span>
          )}
          {(searchBathsMin || searchBathsMax) && (
            <span>
              Bath: {searchBathsMin} - {searchBathsMax}
            </span>
          )}
        </PipedElements>
        <PipedElements>
          {(searchPriceMin || searchPriceMax) && (
            <span>
              Price: {searchPriceMin} - {searchPriceMax}
            </span>
          )}
          {additionalFilters && <span>{additionalFilters}</span>}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

export default SavedSearchActivity;

SavedSearchActivity.propTypes = {
  ...activityCommonPropTypes,
};

SavedSearchActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
