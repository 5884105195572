/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { string } from "prop-types";
import { Routes, Route, Navigate } from "react-router-dom";
import useInitPathName from "./hooks";

import { MainContainer } from "../UI/Containers";
import { tdpDetails } from "../../shared/HOCs/withRedux";
import { USERTYPE } from "../../shared/reduxRoutes/tdpDetailsRoutes";
import { isAgent } from "../shared/helpers";

import NaviLinks from "./Navigation";
import Timeline from "./Timeline";
import Tasks from "./Tasks";
import Notes from "./Notes";
import Details from "./Details";
import Marketing from "./Marketing";
import Feedback from "./Feedback";
import Docs from "./Docs";
import Financials from "./Financials";

const Main = ({ userType }) => {
  const { initPathName } = useInitPathName();

  return (
    <MainContainer>
      <NaviLinks />
      <Routes>
        <Route path={`${initPathName}/timeline`} element={<Timeline />} />
        <Route path={`${initPathName}/tasks`} element={<Tasks />} />
        <Route path={`${initPathName}/notes`} element={<Notes />} />
        <Route path={`${initPathName}/details`} element={<Details />} />
        <Route path={`${initPathName}/marketing`} element={<Marketing />} />
        <Route path={`${initPathName}/feedback`} element={<Feedback />} />
        <Route path={`${initPathName}/docs`} element={<Docs />} />
        <Route
          path={`${initPathName}/financials`}
          element={isAgent(userType) ? <Navigate to={`${initPathName}/timeline`} /> : <Financials />}
        />
        <Route path={initPathName} element={<Timeline />} />
      </Routes>
    </MainContainer>
  );
};

export default tdpDetails({
  WrappedComponent: Main,
  states: USERTYPE,
});

Main.propTypes = {
  userType: string.isRequired,
};
