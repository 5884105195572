/* eslint-disable spaced-comment */
import { createTask, createTags } from "./helpers";

export const adaptGetTimeline = (events) => ({
  //using index for now, need to ask BE to supply event id
  byId: events.reduce(
    (prev, event, id) => ({
      ...prev,
      [id]: createTask({ ...event, id }),
    }),
    {},
  ),
});

export const adaptGetTags = (timeline) => createTags(timeline);
