/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Envelope = (props) => (
  <Icon {...props} name="Envelope">
    <path d="M14.501 1.713H1.498c-.829 0-1.5.704-1.5 1.572v9.431c0 .868.672 1.572 1.5 1.572h13.003c.829 0 1.5-.704 1.5-1.572V3.285c0-.868-.672-1.572-1.5-1.572zm0 1.572v1.336c-.701.598-1.818 1.528-4.207 3.487-.526.434-1.569 1.476-2.295 1.464-.725.012-1.768-1.03-2.295-1.464-2.388-1.959-3.506-2.889-4.207-3.487V3.285H14.5zm-13.003 9.43V6.637c.716.598 1.732 1.436 3.28 2.706.683.563 1.88 1.807 3.221 1.8 1.335.008 2.516-1.218 3.221-1.799a514.463 514.463 0 0 0 3.28-2.707v6.078H1.497z" />
  </Icon>
);

export default Envelope;
