import React from "react";
import { Row, Col } from "react-bootstrap";
import css from "./report-styles.module.css";
import { ReportHeader } from "./";

const AgentCallsHeaders = ({ sortBy, sortKey, sortDesc }) => {
  return (
    <Col xs={12} className={css.noWrap}>
      <div className="th">
        <ReportHeader
          name="agent"
          className="col-xs-2"
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Name"
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_agent_total_lead_count"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Total Leads"
          tooltipDesc={
            "All contacts assigned to a given agent or belonging to a pond that the agent is the display agent for"
          }
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_lead_call_count"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Leads Called"
          tooltipDesc={"Number of unique leads that have been contacted by phone"}
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_manual_calls"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Manual"
          tooltipDesc={"Calls that were not made through Brivity but were manually logged into the CRM"}
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_dialer_calls"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Dialer"
          tooltipDesc={"Number of calls made through Brivity’s dialer tool"}
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_total_calls"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Total Calls"
          tooltipDesc={"Overall number of calls made, combining both manual and dialer calls"}
          handleSort={sortBy}
        />
        <ReportHeader
          name="call_report_total_dialer_call_time_seconds"
          className={`col-xs-1 text-center ${css.backgroundWhite}`}
          sortDesc={sortDesc}
          sortKey={sortKey}
          label="Total Call Time"
          tooltipDesc={"Cumulative duration of all calls made"}
          handleSort={sortBy}
        />
      </div>
    </Col>
  );
};
export default AgentCallsHeaders;
