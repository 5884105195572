import React from "react";
import Proptypes from "prop-types";

import EditAgreement from "./EditAgreement";
import Addendums from "./Addendums";
import DatePicker from "@shared/v2/DatePicker/DatePicker";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import Checkbox from "@shared/v2/Checkbox/Checkbox";
import Tooltip from "@shared/v2/Tooltip";
import Info from "@shared/v2/Icomoon/Icons/Info";

const EditAgreementBody = ({
  agreement,
  setAgreement,
  addendumState,
  handleAgreementDocumentUpload,
  handleAgreementFileDrop,
  handleAddendumsFileDrop,
  handleAddendumsDocumentUpload,
  handleAgreementDocumentReplace,
  handleAddendumsReplace,
  deleteAgreementDocument,
  deleteAddendum,
  renameAgreementDocument,
  renameAddendum,
  availableTransactions,
}) => {
  return (
    <div>
      <EditAgreement
        document={agreement.document}
        agreementType={agreement.agreementType}
        handleFileDrop={handleAgreementFileDrop}
        handleDocumentUpload={handleAgreementDocumentUpload}
        handleAgreementDocumentReplace={handleAgreementDocumentReplace}
        deleteAgreementDocument={deleteAgreementDocument}
        renameAgreementDocument={renameAgreementDocument}
      />
      <Addendums
        handleAddendumsReplace={handleAddendumsReplace}
        addendums={addendumState}
        handleFileDrop={handleAddendumsFileDrop}
        handleDocumentUpload={handleAddendumsDocumentUpload}
        deleteAddendum={deleteAddendum}
        renameAddendum={renameAddendum}
      />
      <div>
        <div className="tw-pb-24px tw-grid tw-grid-cols-2 tw-gap-[24px] tw-justify-between">
          <div>
            <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
              Agreement Signed Date
            </div>
            <DatePicker
              inputProps={{ placeholder: "MM/DD/YYYY" }}
              onChange={(value) => setAgreement("signedDate", moment(value).format("YYYY-MM-DD"))}
              value={agreement?.signedDate}
            />
          </div>

          <div>
            <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
              Agreement Expiration Date
            </div>
            <DatePicker
              inputProps={{ placeholder: "MM/DD/YYYY" }}
              onChange={(value) => setAgreement("expirationDate", moment(value).format("YYYY-MM-DD"))}
              value={agreement?.expirationDate}
            />
          </div>
        </div>
        <Dropdown
          value={agreement?.listing}
          options={availableTransactions}
          onChange={(value) => setAgreement("listing", value)}
          label="Associated Transaction"
          isSearchable
        />
        <div className="!tw-flex tw-pt-24px tw-grid tw-grid-cols-2 tw-gap-[24px]">
          <div>
            <Checkbox
              label="Agreement Completed"
              id="agreementCompleted"
              name="agreementCompleted"
              className="tw-cursor-pointer"
              value={agreement?.isCompleted}
              checked={agreement?.isCompleted}
              disabled={agreement?.isCanceled}
              onClick={() => setAgreement("isCompleted", !agreement?.isCompleted)}
            />
            <Tooltip
              multiline
              placement="top"
              content={
                <div className="tw-w-[226px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
                  An agreement is completed when the transaction it is associated with is closed. Setting a
                  transaction to Closed will automatically update the associated agreement’s status to
                  Completed unless the agreement has been Canceled.
                </div>
              }
              trigger={<Info className="tw-mb-[-1px] tw-ml-6px" />}
            />
          </div>
          <Checkbox
            label="Agreement canceled"
            id="agreementCanceled"
            name="agreementCanceled"
            className="tw-cursor-pointer"
            value={agreement?.isCanceled}
            checked={agreement?.isCanceled}
            disabled={agreement?.isCompleted}
            onClick={() => setAgreement("isCanceled", !agreement?.isCanceled)}
          />
        </div>
        {agreement?.isCanceled && (
          <div className="tw-pt-24px tw-grid tw-grid-cols-2 tw-gap-[24px] tw-justify-between">
            <div>
              <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
                Agreement Cancellation Date
              </div>
              <DatePicker
                inputProps={{ placeholder: "MM/DD/YYYY" }}
                onChange={(value) => setAgreement("cancellationDate", moment(value).format("YYYY-MM-DD"))}
                value={agreement?.cancellationDate}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

EditAgreementBody.propTypes = {
  agreement: Proptypes.shape({
    id: Proptypes.number,
    agreementType: Proptypes.string,
    document: Proptypes.shape({}),
    expirationDate: null,
    cancellationDate: null,
    signedDate: null,
  }),
  addendumState: Proptypes.arrayOf(Proptypes.shape({})),
  handleAgreementDocumentUpload: Proptypes.func,
  handleAgreementFileDrop: Proptypes.func,
  handleAddendumsFileDrop: Proptypes.func,
  handleAddendumsDocumentUpload: Proptypes.func,
  handleAddendumsReplace: Proptypes.func,
  deleteAddendum: Proptypes.func,
  renameAddendum: Proptypes.func,
  handleAgreementDocumentReplace: Proptypes.func,
  deleteAgreementDocument: Proptypes.func,
  renameAgreementDocument: Proptypes.func,
  setAgreement: Proptypes.func,
  availableTransactions: Proptypes.arrayOf(Proptypes.shape({})),
};
EditAgreementBody.defaultProps = {
  agreement: {},
  addendumState: [],
  handleAgreementDocumentUpload: () => {},
  handleAgreementFileDrop: () => {},
  handleAddendumsFileDrop: () => {},
  handleAddendumsDocumentUpload: () => {},
  handleAddendumsReplace: () => {},
  deleteAddendum: () => {},
  renameAddendum: () => {},
  handleAgreementDocumentReplace: () => {},
  deleteAgreementDocument: () => {},
  renameAgreementDocument: () => {},
  setAgreement: () => {},
  availableTransactions: [],
};
export default EditAgreementBody;
