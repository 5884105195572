/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BookBlankSolidV6 = (props) => (
  <Icon {...props} name="Book Blank Solid V6">
    <path d="M15 10.5v-9A1.5 1.5 0 0 0 13.5 0H4a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10c.552 0 1-.448 1-.972a.985.985 0 0 0-.5-.847v-2.543c.306-.301.5-.695.5-1.139zM13 14H4a1 1 0 0 1 0-2h9v2z" />
  </Icon>
);

export default BookBlankSolidV6;
