import React from "react";
import Proptypes from "prop-types";
import { format, parseISO } from "date-fns";

const MilestoneDate = ({ milestoneDate, short }) => (
  <>
    {milestoneDate ? (
      <h5>{format(parseISO(milestoneDate), short ? "MM/dd" : "MM/dd/yyyy")}</h5>
    ) : (
      <span className="tw-text-gray-50">—</span>
    )}
  </>
);

MilestoneDate.propTypes = {
  milestoneDate: Proptypes.string,
  short: Proptypes.bool,
};
MilestoneDate.defaultProps = {
  milestoneDate: null,
  short: false,
};

export default MilestoneDate;
