import React, { useMemo } from "react";
import { Provider } from "react-redux";
import Sidebar from "./Sidebar";

const SidebarApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <Sidebar />
    </Provider>
  );
};

export default SidebarApp;
