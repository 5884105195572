/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MinusSolidV6 = (props) => (
  <Icon {...props} name="Minus Solid V6">
    <path d="M13.5 9h-11a1 1 0 1 1 0-2h11a1 1 0 1 1 0 2z" />
  </Icon>
);

export default MinusSolidV6;
