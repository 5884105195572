const buildTopFive = (filterKey, sorted, sortedArray, labelDescriptor, barColors, colorLabelClasses) => {
  let chartData = { labels: [], datasets: [] };
  let dataTotal = 0;
  let otherTotal = 0;
  let colors = [...barColors];
  let colorClasses = [...colorLabelClasses];
  let color, colorClass;

  sorted.forEach((element, index) => {
    if (index < 5) {
      let filteredMeta = sortedArray.filter((el) => el[filterKey] == Object.keys(element)[0]);
      color = colors.shift();
      colorClass = colorClasses.shift();
      let labelName =
        filterKey == "price"
          ? "$" + Math.round(Object.keys(element)[0]).toLocaleString()
          : Object.keys(element)[0];

      chartData.datasets.push({
        stack: "1",
        label: `<span class="color-text ${colorClass}">${labelName}${
          labelDescriptor == undefined ? "" : " " + labelDescriptor
        }</span> ${Object.values(element)[0]}`,
        color: colorClass,
        backgroundColor: color[0],
        hoverBackgroundColor: color[1],
        data: [Object.values(element)[0]],
        metaCollection: filteredMeta,
        secondaryTitle: `Showing ${filteredMeta.length} properties with ${
          Object.keys(element)[0]
        } ${filterKey}`,
        index: index,
        barThickness: 40,
      });

      chartData.labels[index] = `<span class="color-text">${Object.keys(element)[0]}</span> ${
        Object.values(element)[0]
      }`;
      dataTotal += Object.values(element)[0];
    } else {
      let filteredMeta = sortedArray.filter((el) => el[filterKey] == Object.keys(element)[0]);
      color = colors.shift();
      colorClass = colorClasses.shift();
      otherTotal += Object.values(element)[0];

      if (chartData.datasets[5] === undefined) {
        chartData.datasets.push({
          stack: "1",
          label: `<span class="color-text ${colorClass}">Other</span> ${Object.values(element)[0]}`,
          color: colorClass,
          backgroundColor: color[0],
          hoverBackgroundColor: color[1],
          data: [Object.values(element)[0]],
          metaCollection: filteredMeta,
          secondaryTitle: `Showing ${filteredMeta.length} properties with other ${filterKey}`,
          index: index,
        });
      } else {
        chartData.datasets[5].label = `<span class="color-text ${colorClass}">Other</span> ${otherTotal}`;
        chartData.datasets[5].data[0] += Object.values(element)[0];
        Array.prototype.push.apply(chartData.datasets[5].metaCollection, filteredMeta);
      }
      chartData.labels[5] = `<span class="color-text colorClass">Other</span> ${otherTotal}`;

      dataTotal += Object.values(element)[0];
    }
  });

  chartData.datasets.forEach((dataset, index) => {
    let percentage = Math.round((dataset.data[0] / dataTotal) * 100);

    dataset.data[0] = percentage;
    chartData.labels[index] += ` (${percentage}%)`;
    dataset.label += ` (${percentage}%)`;
  });

  return chartData;
};

const buildQuartileData = (filterKey, normalAndOutliers, rangeMultiplier, barColors, colorLabelClasses) => {
  let chartData = { labels: [], datasets: [] };
  let dataTotal = 0;
  let colors = [...barColors];
  let colorClasses = [...colorLabelClasses];
  let color, colorClass;
  rangeMultiplier = rangeMultiplier * 4;

  for (var index = 0; index <= 5; index++) {
    var dataset = {
      stack: "1",
      label: "",
      data: [0],
      metaCollection: [],
      secondaryTitle: "",
      index: index,
      barThickness: 40,
    };

    if (index === 0) {
      dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
        if (dat[filterKey] > 0 && dat[filterKey] < rangeMultiplier) {
          dataset.metaCollection.push(dat);
          return acc + 1;
        }
        return acc;
      }, 0);

      if (dataset.data[0] > 0) {
        color = colors.shift();
        colorClass = colorClasses.shift();
        dataset.backgroundColor = color[0];
        dataset.hoverBackgroundColor = color[1];
        dataset.color = colorClass;

        chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(
          rangeMultiplier,
        ).toLocaleString()} - ${Math.ceil(rangeMultiplier * 2).toLocaleString()}</span> ${dataset.data[0]}`;
        dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(
          rangeMultiplier,
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * 2).toLocaleString()}</span> ${dataset.data[0]}`;
        (dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(
          rangeMultiplier,
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * 2).toLocaleString()} range`),
          (dataTotal += dataset.data[0]);
      }
    } else if (index <= 3) {
      dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
        if (dat[filterKey] >= rangeMultiplier * index && dat[filterKey] < rangeMultiplier * (index + 1)) {
          dataset.metaCollection.push(dat);
          return acc + 1;
        }
        return acc;
      }, 0);

      if (dataset.data[0] > 0) {
        color = colors.shift();
        colorClass = colorClasses.shift();
        dataset.backgroundColor = color[0];
        dataset.hoverBackgroundColor = color[1];
        dataset.color = colorClass;

        chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - ${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${
          dataset.data[0]
        }`;
        dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${
          dataset.data[0]
        }`;
        (dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()} range`),
          (dataTotal += dataset.data[0]);
      }
    } else if (index == 4) {
      // here we need to include values that are equal to the top end of the multiplier
      dataset.data[0] = normalAndOutliers[0].reduce((acc, dat) => {
        if (dat[filterKey] >= rangeMultiplier * index && dat[filterKey] <= rangeMultiplier * (index + 1)) {
          dataset.metaCollection.push(dat);
          return acc + 1;
        }
        return acc;
      }, 0);

      if (dataset.data[0] > 0) {
        color = colors.shift();
        colorClass = colorClasses.shift();
        dataset.backgroundColor = color[0];
        dataset.hoverBackgroundColor = color[1];
        dataset.color = colorClass;

        chartData.labels[index] = `<span class="color-text ${colorClass}">${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - ${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${
          dataset.data[0]
        }`;
        dataset.label = `<span class="color-text ${colorClass}">$${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()}</span> ${
          dataset.data[0]
        }`;
        (dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties in $${Math.floor(
          rangeMultiplier * (index + 1),
        ).toLocaleString()} - $${Math.ceil(rangeMultiplier * (index + 2)).toLocaleString()} range`),
          (dataTotal += dataset.data[0]);
      }
    } else {
      dataset.data[0] = normalAndOutliers[1].reduce((acc, dat) => {
        dataset.metaCollection.push(dat);
        return acc + 1;
      }, 0);

      if (dataset.data[0] > 0) {
        color = colors.shift();
        colorClass = colorClasses.shift();
        dataset.backgroundColor = color[0];
        dataset.hoverBackgroundColor = color[1];
        dataset.color = colorClass;

        chartData.labels[index] = `<span class="color-text ${colorClass}">Other</span> ${dataset.data[0]}`;
        dataset.label = `<span class="color-text ${colorClass}">Other</span> ${dataset.data[0]}`;
        (dataset.secondaryTitle = `Showing ${dataset.metaCollection.length} properties with other range`),
          (dataTotal += dataset.data[0]);
      }
    }

    if (dataset.data[0] > 0) {
      chartData.datasets.push(dataset);
    }
  }

  chartData.datasets.forEach((dataset, index) => {
    let percentage = Math.round((dataset.data[0] / dataTotal) * 100);

    dataset.data[0] = percentage;
    chartData.labels[index] += ` (${percentage}%)`;
    dataset.label += ` (${percentage}%)`;
  });

  return chartData;
};

const sortValues = (sortKey, data) => {
  const multiplier = 1;
  const sorted = data.sort((a, b) => {
    const aVal = a[sortKey] ? a[sortKey] : 0;
    const bVal = b[sortKey] ? b[sortKey] : 0;
    return aVal > bVal ? multiplier : aVal < bVal ? -multiplier : 0;
  });

  return sorted;
};

const options = ({ titleText, showSecondary }) => ({
  indexAxis: "y",
  maintainAspectRatio: false,
  title: {
    display: false,
    text: titleText,
    position: "top",
    fontSize: 12,
    fontFamily: "Open Sans",
    fontStyle: "bold",
  },
  barPercentage: 1,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      titleFont: {
        family: "Open Sans",
        size: 14,
        style: "normal",
      },
      titleColor: "#808080",
      enabled: !showSecondary,
      backgroundColor: "white",
      borderColor: "gray",
      borderWidth: 1,
      titleMarginBottom: 0,
      cornerRadius: 0,
      callbacks: {
        label: (tooltipItem, data) => {
          return "";
        },
        title: (tooltipItem, data) => {
          return "click for more insights";
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
        max: 100,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
});

export { buildTopFive, buildQuartileData, options, sortValues };
