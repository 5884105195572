/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "../actions/creators";
import ResultRow from "./ResultRow";
import DetailsModal from "./DetailsModal";

const mapStateToProps = (state) => ({
  results: state.partnerSearch.results,
  searchTab: state.partnerSearch.searchTab,
});

const HEADINGS = ["Operator / Owner", "Team", "Brokerage"];
const HEADINGS_2 = ["City", "State", "Office Phone"];
const ResultsTable = ({ results, searchTab }) => {
  const DISTANCE = searchTab === "google_place" ? ["Distance"] : [];
  return (
    <>
      <DetailsModal />
      <table className="tw-w-full tw-mt-8">
        <thead className="tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-h-[32px] tw-text-neutral-gray-50 tw-text-[12px]">
          {[...HEADINGS, ...DISTANCE, ...HEADINGS_2].map((h) => (
            <th className="tw-font-semibold">{h}</th>
          ))}
        </thead>
        {results?.map((result) => (
          <ResultRow {...result} searchTab={searchTab} />
        ))}
      </table>
    </>
  );
};

ResultsTable.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchTab: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, creators)(ResultsTable);
