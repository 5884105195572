import React, { useState, useEffect } from "react";

import AgentListingFilters from "./AgentListingFilters";
import AgentStats from "./AgentStats";
import AgentListingsTable from "./AgentListingsTable";
import ListingsMap from "./ListingsMap";
import { getStatusValues } from "../styleHelpers";
import { useStateWithProps } from "../customHooks";
import DateDropdown from "./DateDropdown";
import { useFeatureFlags } from "../../../reducers/layoutReducer/selectors";

const AgentDetails = ({ agent, office, listingData, monthsBack, onDateChosen, onClose }) => {
  const [selectedStatuses, setSelectedStatuses] = useState(DefaultStatuses);
  const [listingsToShow, setListingsToShow] = useStateWithProps(listingData.listings);
  const featureFlags = useFeatureFlags();

  const showListingLimitDisclaimer = listingData.listings?.length >= 1000;

  const onToggleStatus = (status) => {
    const filtered = selectedStatuses.includes(status)
      ? selectedStatuses.filter(s => s !== status)
      : [...selectedStatuses, status];

    setSelectedStatuses(filtered);
  };

  useEffect(() => {
    const filtered = listingData.listings?.filter(({ standardStatus }) => {
      const mapped = getStatusValues(standardStatus).text;

      return selectedStatuses.includes(mapped);
    }) || [];

    setListingsToShow(filtered);
  }, [selectedStatuses, listingData]);

  return (
    <div className="tw-flex tw-h-full tw-box-border tw-flex-col tw-text-gray-50">
      <p className="tw-mb-4px">
        {office.name || "Unknown office"}<span className="tw-text-color-ebebeb tw-mx-12px">|</span>Office ID: #{office.id}<span className="tw-text-color-ebebeb tw-mx-12px">|</span>Agent ID: #{agent.id}
      </p>
      <p className="tw-mb-12px">
        {agent.phone || "Unknown phone number"}<span className="tw-text-color-ebebeb tw-mx-12px">|</span>{agent.email || "Unknown email address"}
      </p>
      <div className="tw-mb-24px">
        <button
          className="tw-text-11d tw-py-[2.5px] tw-px-[6.5px] tw-cursor-pointer tw-rounded-30px tw-border-[1.5px] tw-border-teal tw-border-solid tw-bg-white"
          onClick={() => onClose() || openAddToCrmModal(agent, office)}
        >
          <div className="tw-inline-flex tw-space-x-[4px] tw-items-center tw-whitespace-nowrap tw-text-teal tw-font-bold">
            <i className="fa fa-plus tw-text-10px" />
            <span>ADD TO CRM</span>
          </div>
        </button>
      </div>
      <Disclaimer
        showListingLimitDisclaimer={showListingLimitDisclaimer}
        marginBottom="tw-mb-4px"
        monthsBack={monthsBack}
        onDateChosen={onDateChosen}
      />
      {featureFlags?.partnerSearch &&
        <span className='tw-text-gray-50 tw-text-12d tw-italic tw-mb-16px'>
          These estimated metrics are based on a variety of sources and may not represent finalized agent production
        </span>
      }
      <div className="tw-mb-24px">
        <AgentStats listingStats={listingData?.stats} />
      </div>
      <div className="tw-mb-12px">
        <AgentListingFilters
          selectedStatuses={selectedStatuses}
          availableStatuses={listingData.availableStatuses}
          onToggleStatus={onToggleStatus}
        />
      </div>
      <hr className="tw-w-full tw-bg-gray-10 tw-mb-24px tw-mt-0" />
      <div className="tw-mb-40px">
        <ListingsMap listings={listingsToShow} />
      </div>
      <Disclaimer
        showListingLimitDisclaimer={showListingLimitDisclaimer}
        marginBottom="tw-mb-12px"
        monthsBack={monthsBack}
        onDateChosen={onDateChosen}
      />
      <AgentListingsTable mlsListings={listingsToShow} />
    </div>
  );
};

export default AgentDetails;

const Disclaimer = ({ showListingLimitDisclaimer, marginBottom, monthsBack, onDateChosen }) => {
  const monthsBackOption = DateFilterOptions.find(o => o.value === monthsBack);
  const textColor = showListingLimitDisclaimer ? "tw-text-brivity-coral-100" : "tw-text-gray-50";
  const verbiage = showListingLimitDisclaimer
    ? `(Maxed out) Displaying agent's 1,000 most recent listings from the last ${monthsBackOption.label.toLowerCase()}.`
    : "";

  return (
    <div className={`tw-flex tw-flex-row tw-items-center ${marginBottom}`}>
      <span className="tw-text-14px tw-text-gray-75 tw-mr-8px">Displaying data from the last:</span>
      <DateDropdown monthsBack={monthsBackOption} options={DateFilterOptions} onDateChosen={onDateChosen} />
      <span className={`tw-ml-8px ${textColor} tw-text-10px tw-italic`}>
        {verbiage}
      </span>
    </div>

  );
};

const openAddToCrmModal = (agent, office) => {
  const name = agent.name;
  let firstName = "";
  let lastName = "";
  if (name) {
    // Naive guessing at how to split up possible agent name;
    const space = name.indexOf(" ");

    firstName = name.substring(0, space).trim();
    lastName = name.substring(space + 1).trim();
  }

  const brokerageDescription = office.name ? `${office.name}\n` : "";
  const description = `${brokerageDescription}MLS Agent ID#${agent.id}`;

  Brivity.CreateLead.createRecruitModal(firstName, lastName, agent.email, agent.phone, description);
};

const DefaultStatuses = ["Active", "Pending", "Contingent", "Sold", "Off Market"];

const DateFilterOptions = [
  { label: "6 Months", value: "6" },
  { label: "1 Year", value: "12" },
  { label: "2 Years", value: "24" }
];
