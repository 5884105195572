/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { runAtLabel, runAtValue } from "./helpers";
import { eventDetailString } from "./AssignedPlanDetailHelpers";

const InteractionDetail = ({ action, parentAction }) => (
  <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden">
    <div className="row header-wrap">
      <div className="col-xs-4">
        <label>Send From</label>
        <div>{action.assigned_to_label}</div>
      </div>
      <div className="col-xs-4">
        <label>Send To</label>
        <div>{action.assigned_for_label}</div>
      </div>

      {parentAction && action.contingent && (
        <div className="col-xs-4">
          <label>Contingent</label>
          <div className="ellipsify">{parentAction.name}</div>
        </div>
      )}
    </div>
    <div className="row row-border sub-header-wrap">
      <div className="col-xs-4">
        <label>{runAtLabel(action.type, action.state)}</label>
        <div>{runAtValue(action.run_at, action.state, action.completed_at)}</div>
      </div>

      {action.milestone_name && (
        <div className="col-xs-4">
          <label>Event</label>
          <div>{eventDetailString(action)}</div>
        </div>
      )}
    </div>

    <div className="row content-wrap sub-header-wrap">
      {action.type === "Email" && (
        <div>
          <div className="col-xs-9">
            <div className="action-name bold">{action.name}</div>
          </div>
          <div className="col-xs-3 action-fail-message">
            {action.state === "failed" && action.failed_message}
          </div>
          <div className="col-xs-12 tw-break-words">
            <p dangerouslySetInnerHTML={{ __html: action.template_body }} />
          </div>
        </div>
      )}
      {action.type === "Text" && (
        <div>
          {action.state === "failed" && action.failed_description && (
            <div className="col-xs-12 tw-mb-[12px]">
              <div
                className={`tw-py-[12px] tw-px-[16px] ${action.failed_description_type === "error" ? "tw-bg-semantic-red-5" : "tw-bg-semantic-yellow-5"}`}
              >
                <p className="tw-text-14d tw-text-semantic-yellow-120 tw-font-semibold tw-mb-[5px]">
                  {action.failed_description_type === "error" ? "Text Failure" : "Possible Failure"}
                </p>
                <p className="tw-mb-0">{action.failed_description}</p>
              </div>
            </div>
          )}
          <div className="col-xs-9 tw-pt-0 tw-break-words">
            <p>{action.template_body}</p>
          </div>
          <div className="col-xs-3 action-fail-message">
            {action.state === "failed" && action.failed_message}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default InteractionDetail;

InteractionDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  parentAction: PropTypes.objectOf(PropTypes.any).isRequired,
};
