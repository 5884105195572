/* eslint-disable no-param-reassign */
import { produce } from "immer";

import {
  getDocuments,
  getDocumentsStart,
  getDocumentsError,
  getSidebarDocuments,
  getSidebarDocumentsStart,
  getSidebarDocumentsError,
  getDocumentsFromSearch,
  searchDocumentsError,
  getDocumentsFromSort,
  sortDocumentsError,
  getAllDocumentViewers,
  renameDocumentSuccess,
  renameDocumentError,
  toggleUploadModal,
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  toggleDeleteModal,
  deleteDocumentsSuccess,
  deleteDocumentsError,
  setDocumentIndex,
  toggleDocumentsSelected,
  getCDAdocument,
  toggleCDAUploadModal,
  uploadCDADocSuccess,
  sortByCDAType,
} from "../TransactionDetailsPage/Main/Docs/actions/creators";

export const defaultState = {
  uuid: "",
  documents: [],
  CDAdocuments: [],
  sidebarDocuments: [],
  sidebarDocumentsCount: 5,
  isUploadModalOpen: false,
  isCDAUploadModalOpen: false,
  uploadedDocuments: [],
  isDeleteModalOpen: false,
  documentIdsToDelete: [],
  isCDA: false,
  deleteErrors: [],
  search: "",
  orderBy: "",
  orderDir: "",
  errors: [],
  sidebarErrors: [],
  meta: {
    pagination: {
      current: 0,
      next: 0,
      previous: 0,
      totalPages: 1,
      totalCount: 0,
      countPerPage: 25,
    },
    viewerCounts: {},
    permissions: {},
    updatedAtMs: 0,
  },
  sidebarMeta: {
    pagination: {
      current: 0,
      next: 0,
      previous: 0,
      totalPages: 1,
      totalCount: 0,
      countPerPage: 25,
    },
    viewerCounts: {},
    permissions: {},
    updatedAtMs: 0,
  },
  preview: {
    currentDocumentContext: null,
    currentDocumentIndex: null,
    renameErrors: [],
  },
  isFetchingDocuments: false,
  isFetchingSidebarDocuments: false,
  sortByCDA: null,
  sortCDADirection: null,
};

const canUseForSidebar = (state) =>
  (!state.meta.pagination.current || state.meta.pagination.current === 1) &&
  !state.search &&
  !state.orderBy &&
  !state.orderDir;

const areSidebarDocumentsLoaded = (state) => state.sidebarMeta.pagination.current > 0;

const normalizeDocumentIndex = (index, numDocuments) => (index < 0 || index >= numDocuments ? null : index);

export const tdpDocumentsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case getDocuments().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.errors = [];
        draft.uuid = payload.uuid;
        draft.documents = payload.documents;
        draft.meta = payload.meta;

        if (canUseForSidebar(draft)) {
          draft.isFetchingSidebarDocuments = false;
          draft.sidebarErrors = [];
          draft.sidebarDocuments = payload.documents.slice(0, draft.sidebarDocumentsCount);
          draft.sidebarMeta = payload.meta;
        }
      });
    case getCDAdocument().type:
      return produce(state, (draft) => {
        draft.CDAdocuments = payload.CDAdocuments;
      });
    case getDocumentsStart().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = true;

        if (canUseForSidebar(draft) && !areSidebarDocumentsLoaded(draft)) {
          draft.isFetchingSidebarDocuments = true;
        }
      });
    case getDocumentsError().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.errors = payload.errors;

        if (canUseForSidebar(draft) && !areSidebarDocumentsLoaded(draft)) {
          draft.isFetchingSidebarDocuments = false;
          draft.sidebarErrors = payload.errors;
        }
      });
    case getSidebarDocuments().type:
      return produce(state, (draft) => {
        draft.isFetchingSidebarDocuments = false;
        draft.sidebarErrors = [];
        draft.sidebarDocuments = payload.documents.slice(0, draft.sidebarDocumentsCount);
        draft.sidebarMeta = payload.meta;
      });
    case getSidebarDocumentsStart().type:
      return produce(state, (draft) => {
        draft.isFetchingSidebarDocuments = true;
      });
    case getSidebarDocumentsError().type:
      return produce(state, (draft) => {
        draft.isFetchingSidebarDocuments = false;
        draft.sidebarErrors = payload.errors;
      });
    case getDocumentsFromSearch().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.search = payload.search;
        draft.errors = [];
        draft.documents = payload.documents;
        draft.meta = payload.meta;
      });
    case searchDocumentsError().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.search = payload.search;
        draft.errors = payload.errors;
      });
    case getDocumentsFromSort().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.orderBy = payload.orderBy;
        draft.orderDir = payload.orderDir;
        draft.errors = [];
        draft.documents = payload.documents;
        draft.meta = payload.meta;
      });
    case sortDocumentsError().type:
      return produce(state, (draft) => {
        draft.isFetchingDocuments = false;
        draft.orderBy = payload.orderBy;
        draft.orderDir = payload.orderDir;
        draft.errors = payload.errors;
      });
    case getAllDocumentViewers().type:
      return produce(state, (draft) => {
        const draftDoc = draft.documents.find((doc) => doc.id === payload.documentId);

        if (draftDoc) {
          draftDoc.uploadedByRole =
            payload.viewers.find((viewer) => viewer.id === draftDoc.uploadedById)?.role ?? "";
          draftDoc.viewers = payload.viewers;
        }

        const draftSidebarDoc = draft.sidebarDocuments.find((doc) => doc.id === payload.documentId);

        if (draftSidebarDoc) {
          draftSidebarDoc.uploadedByRole =
            payload.viewers.find((viewer) => viewer.id === draftSidebarDoc.uploadedById)?.role ?? "";
          draftSidebarDoc.viewers = payload.viewers;
        }
      });
    case renameDocumentSuccess().type:
      return produce(state, (draft) => {
        const draftDoc = draft.documents.find((doc) => doc.id === payload.documentId);

        if (draftDoc) {
          draftDoc.fileName = payload.data.document.fileName;
          draftDoc.nakedName = payload.data.document.nakedName;
          draftDoc.expiringUrl = payload.data.document.expiringUrl;
          draftDoc.renameErrors = [];
        }

        const draftSidebarDoc = draft.sidebarDocuments.find((doc) => doc.id === payload.documentId);

        if (draftSidebarDoc) {
          draftSidebarDoc.fileName = payload.data.document.fileName;
          draftSidebarDoc.nakedName = payload.data.document.nakedName;
          draftSidebarDoc.expiringUrl = payload.data.document.expiringUrl;
          draftSidebarDoc.renameErrors = [];
        }

        const draftUploadedDoc = draft.uploadedDocuments.find((doc) => doc.id === payload.documentId);

        if (draftUploadedDoc) {
          draftUploadedDoc.fileName = payload.data.document.fileName;
          draftUploadedDoc.nakedName = payload.data.document.nakedName;
          draftUploadedDoc.expiringUrl = payload.data.document.expiringUrl;
          draftUploadedDoc.renameErrors = [];
        }

        const draftCDADoc = draft.CDAdocuments.find((doc) => doc.id === payload.documentId);

        if (draftCDADoc) {
          draftCDADoc.fileName = payload.data.document.fileName;
          draftCDADoc.nakedName = payload.data.document.nakedName;
          draftCDADoc.expiringUrl = payload.data.document.expiringUrl;
          draftCDADoc.renameErrors = [];
        }

        draft.preview.renameErrors = [];
      });
    case renameDocumentError().type:
      return produce(state, (draft) => {
        switch (payload.context) {
          case "CDA-document": {
            const draftDoc = draft.CDAdocuments.find((doc) => doc.id === payload.documentId);

            if (draftDoc) {
              draftDoc.renameErrors = payload.errors;
            }

            break;
          }

          case "documents-table": {
            const draftDoc = draft.documents.find((doc) => doc.id === payload.documentId);

            if (draftDoc) {
              draftDoc.renameErrors = payload.errors;
            }

            break;
          }

          case "documents-sidebar": {
            const draftSidebarDoc = draft.sidebarDocuments.find((doc) => doc.id === payload.documentId);

            if (draftSidebarDoc) {
              draftSidebarDoc.renameErrors = payload.errors;
            }

            break;
          }

          case "documents-upload": {
            const draftUploadedDoc = draft.uploadedDocuments.find((doc) => doc.id === payload.documentId);

            if (draftUploadedDoc) {
              draftUploadedDoc.renameErrors = payload.errors;
            }

            break;
          }

          case "documents-preview":
            draft.preview.renameErrors = payload.errors;
            break;

          default:
            break;
        }
      });
    case toggleUploadModal().type:
      return produce(state, (draft) => {
        draft.isUploadModalOpen = payload.isModalOpen;
        draft.uploadedDocuments = [];
      });
    case uploadDocumentStart().type:
      return produce(state, (draft) => {
        draft.uploadedDocuments.push(payload.data);
      });
    case uploadDocumentSuccess().type:
      return produce(state, (draft) => {
        draft.documents.unshift(payload.data.document);
        draft.sidebarDocuments.unshift(payload.data.document);

        if (draft.sidebarDocuments.length > draft.sidebarDocumentsCount) {
          draft.sidebarDocuments = draft.sidebarDocuments.slice(0, draft.sidebarDocumentsCount);
        }

        const uploadedDocIndex = draft.uploadedDocuments.findIndex(
          (doc) => doc.uploadKey === payload.uploadKey,
        );

        if (uploadedDocIndex !== -1) {
          draft.uploadedDocuments[uploadedDocIndex] = payload.data.document;
        }

        draft.meta.pagination.totalCount += 1;
        draft.meta.viewerCounts[payload.data.document.id] = 0;
        draft.meta.permissions[payload.data.document.id] = payload.data.meta.permissions;

        draft.sidebarMeta.pagination.totalCount += 1;
        draft.sidebarMeta.viewerCounts[payload.data.document.id] = 0;
        draft.sidebarMeta.permissions[payload.data.document.id] = payload.data.meta.permissions;
      });
    case uploadDocumentError().type:
      return produce(state, (draft) => {
        if (payload.isFinancialsCDA) {
          draft.CDAuploadErrors = payload.errors;
        } else {
          const draftUploadedDoc = draft.uploadedDocuments.find((doc) => doc.uploadKey === payload.uploadKey);

          if (draftUploadedDoc) {
            draftUploadedDoc.uploadErrors = payload.errors;
          }
        }
      });
    case toggleDeleteModal().type:
      return produce(state, (draft) => {
        draft.isDeleteModalOpen = payload.documentIdsToDelete.length > 0;
        draft.documentIdsToDelete = payload.documentIdsToDelete;
        draft.isCDA = payload.isFinancialsCDA;
        draft.deleteErrors = [];
      });
    case deleteDocumentsSuccess().type:
      return produce(state, (draft) => {
        draft.uploadedDocuments = draft.uploadedDocuments.filter(
          (doc) => !payload.documentIds.includes(doc.id),
        );
        if (draft.isCDA) {
          draft.CDAdocuments = draft.CDAdocuments.filter((doc) => !payload.documentIds.includes(doc.id));
          draft.isCDA = false;
        } else {
          draft.documents = draft.documents.filter((doc) => !payload.documentIds.includes(doc.id));

          // Necessary to avoid a duplicate request when we can grab the sidebar documents from `documents`
          if (canUseForSidebar(draft)) {
            draft.sidebarDocuments = draft.documents.slice(0, draft.sidebarDocumentsCount);
          } else {
            draft.sidebarDocuments = draft.sidebarDocuments.filter(
              (doc) => !payload.documentIds.includes(doc.id),
            );
          }

          draft.meta.pagination.totalCount -= payload.documentIds.length;
          draft.sidebarMeta.pagination.totalCount -= payload.documentIds.length;
        }

        draft.isDeleteModalOpen = false;
        draft.documentIdsToDelete = [];

        draft.deleteErrors = [];
      });
    case deleteDocumentsError().type:
      return produce(state, (draft) => {
        draft.deleteErrors = payload.errors;
      });
    case setDocumentIndex().type:
      return produce(state, (draft) => {
        draft.preview.currentDocumentContext = payload.context;
        draft.preview.currentDocumentIndex = normalizeDocumentIndex(payload.index);
      });
    case toggleDocumentsSelected().type:
      return produce(state, (draft) => {
        switch (payload.context) {
          case "documents-table":
            draft.documents = draft.documents.map((doc) => {
              if (payload.documentIds.includes(doc.id)) {
                return {
                  ...doc,
                  isSelected: payload.isSelected,
                };
              }

              return doc;
            });

            break;
          case "CDA-document":
            draft.CDAdocuments = draft.CDAdocuments.map((doc) => {
              if (payload.documentIds.includes(doc.id)) {
                return {
                  ...doc,
                  isSelected: payload.isSelected,
                };
              }

              return doc;
            });

            break;

          case "documents-upload":
            draft.uploadedDocuments = draft.uploadedDocuments.map((doc) => {
              if (payload.documentIds.includes(doc.id)) {
                return {
                  ...doc,
                  isSelected: payload.isSelected,
                };
              }

              return doc;
            });

            break;

          default:
            break;
        }
      });
    case toggleCDAUploadModal().type:
      return produce(state, (draft) => {
        draft.isCDAUploadModalOpen = payload.bool;
        draft.isUploadModalOpen = payload.isModalOpen;
        draft.uploadedDocuments = [];
      });
    case uploadCDADocSuccess().type:
      return produce(state, (draft) => {
        const uploadedDocIndex = draft.uploadedDocuments.findIndex(
          (doc) => doc.uploadKey === payload.uploadKey,
        );

        if (uploadedDocIndex !== -1) {
          draft.uploadedDocuments[uploadedDocIndex] = payload.data.document;
        }

        draft.meta.pagination.totalCount += 1;
        draft.meta.viewerCounts[payload.data.document.id] = 0;
        draft.meta.permissions[payload.data.document.id] = payload.data.meta.permissions;
      });
    case sortByCDAType().type:
      return produce(state, (draft) => {
        draft.sortCDADirection =
          !draft.sortCDADirection || draft.sortCDADirection === "ASCENDING_SORT"
            ? "DESCENDING_SORT"
            : "ASCENDING_SORT";

        draft.sortByCDA = payload.sortType;

        // Sort CDA documents based on the sort direction
        const sortedCDADocs = [...state.CDAdocuments];

        if (payload.sortType === "fileName") {
          sortedCDADocs.sort((b, a) => {
            if (draft.sortCDADirection === "DESCENDING_SORT") {
              return b[payload.sortType].localeCompare(a.fileName);
            }

            return a[payload.sortType].localeCompare(b.fileName);
          });
        } else {
          sortedCDADocs.sort((a, b) => {
            if (draft.sortCDADirection === "DESCENDING_SORT") {
              return Date.parse(b[payload.sortType]) - Date.parse(a[payload.sortType]);
            }

            return Date.parse(a[payload.sortType]) - Date.parse(b[payload.sortType]);
          });
        }

        draft.CDAdocuments = sortedCDADocs;
      });
    default:
      return state;
  }
};
