import React from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";

import { NoTasksInFilters, NoTasks } from "./fractals";

const EmptyStates = ({ hasTasks }) => (hasTasks ? <NoTasksInFilters /> : <NoTasks />);

const mapStateToProps = (state) => ({
  hasTasks: Boolean(state.tdpTasksReducer?.tasks?.length),
});

EmptyStates.defaultProps = {
  hasTasks: bool,
};

EmptyStates.propTypes = {
  hasTasks: false,
};

export default connect(mapStateToProps)(EmptyStates);
