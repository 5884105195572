import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import TextToggle from "../../../shared/v2/TextToggle/TextToggle";
import Loader from "../../../Goals/components/Loader";
import { Section, SectionLabel } from "./Section";
import StatCard from "../DashboardShared/StatCard";
import Error from "../DashboardShared/Error";
import useTransactionSnapshot from "./services/useTransactionSnapshot";

const SNAP_STAT_CLASS = "!tw-bg-transparent tw-min-w-[190px] !tw-px-[24px]";

const Spacer = () => (
  <hr className="tw-h-[180px] tw-m-[auto_-12px] tw-border-solid tw-border-y-0 tw-border-r-0 tw-border-l-[1px] tw-border-gray-10" />
);

const TransactionSnapshot = ({ year }) => {
  const [transactionType, setTransactionType] = useState("buyerSeller");
  const { data, error, loading } = useTransactionSnapshot(year);
  const isThisYear = String(year) === String(new Date().getFullYear());
  const isLastYear = String(year) === String(new Date().getFullYear() - 1);
  const transactionTypeData = data[transactionType] || {};

  const queryParams = useMemo(() => {
    const params = new URLSearchParams();
    if (transactionType === "buyerSeller") {
      params.append("type[]", "buyer");
      params.append("type[]", "seller");
    }
    if (transactionType === "tenantLandlord") {
      params.append("type[]", "tenant");
      params.append("type[]", "landlord");
    }
    if (transactionType === "referral") {
      params.append("type[]", "referral");
    }
    return params;
  }, [transactionType]);

  return (
    <Section className="tw-relative tw-gap-[16px]">
      <SectionLabel>Transaction Snapshot</SectionLabel>
      <div>
        <TextToggle
          size="small"
          name="businessStat"
          options={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Buyer & Seller",
              value: "buyerSeller",
            },
            {
              label: "Tenant & Landlord",
              value: "tenantLandlord",
            },
            {
              label: "Referral",
              value: "referral",
            },
          ]}
          value={transactionType}
          onChange={(e) => setTransactionType(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-gap-[12px] tw-overflow-hidden tw-mx-[-12px]">
        <StatCard
          className={SNAP_STAT_CLASS}
          label={`${isThisYear ? "YTD" : year} Closed`}
          stats={transactionTypeData.ytdClosed}
          link={`/listings/sold?close_date_range[]=${isThisYear ? "this_year" : `${isLastYear ? "last_year" : year}`}&${queryParams}`}
        />
        <Spacer />
        <StatCard
          className={SNAP_STAT_CLASS}
          label="MTD Closed"
          stats={transactionTypeData.mtdClosed}
          link={isThisYear ? `/listings/sold?close_date_range[]=this_month&${queryParams}` : ""}
        />
        {transactionType !== "referral" && (
          <>
            <Spacer />
            <StatCard
              className={SNAP_STAT_CLASS}
              label="Pending"
              stats={transactionTypeData.pending}
              link={`/listings/pending?${queryParams}`}
            />
          </>
        )}
        <Spacer />
        <StatCard
          className={SNAP_STAT_CLASS}
          label="Active"
          stats={transactionTypeData.active}
          link={`/listings/active?${queryParams}`}
        />
        {transactionType !== "referral" && (
          <>
            <Spacer />
            <StatCard
              className={SNAP_STAT_CLASS}
              label="Coming Soon"
              stats={transactionTypeData.comingSoon}
              link={`/listings?status[]=coming_soon&${queryParams}`}
            />
            <Spacer />
            <StatCard
              className={SNAP_STAT_CLASS}
              label="Pipeline"
              stats={transactionTypeData.pipeline}
              link={`/listings?status[]=pipeline&${queryParams}`}
            />
          </>
        )}
      </div>
      <Error show={Boolean(error)} />
      {loading && <Loader />}
    </Section>
  );
};

TransactionSnapshot.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TransactionSnapshot;
