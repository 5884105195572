import React from "react";
import PropTypes from "prop-types";
import DatePicker from "@shared/v2/DatePicker/DatePicker";

const SignedorExpirationDate = ({ setExpirationDate, setSignedDate, signedDate, expirationDate }) => (
  <div className="tw-pb-4px tw-grid tw-grid-cols-2 tw-gap-[24px] tw-justify-between">
    <div>
      <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
        Agreement Signed Date
      </div>
      <DatePicker
        inputProps={{ placeholder: "MM/DD/YYYY" }}
        onChange={(value) => setSignedDate(moment(value).format("YYYY-MM-DD"))}
        value={signedDate}
      />
    </div>

    <div>
      <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
        Agreement Expiration Date
      </div>
      <DatePicker
        inputProps={{ placeholder: "MM/DD/YYYY" }}
        onChange={(value) => setExpirationDate(moment(value).format("YYYY-MM-DD"))}
        value={expirationDate}
      />
    </div>
  </div>
);

SignedorExpirationDate.propTypes = {
  setExpirationDate: PropTypes.func,
  setSignedDate: PropTypes.func,
  signedDate: PropTypes.string,
  expirationDate: PropTypes.string,
};
SignedorExpirationDate.defaultProps = {
  setExpirationDate: () => {},
  setSignedDate: () => {},
  expirationDate: "",
  signedDate: "",
};
export default SignedorExpirationDate;
