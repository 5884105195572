const dimensionsDictionary = {
  "extra-large": "tw-w-96px tw-h-96px",
  large: "tw-w-48px tw-h-48px",
  medium: "tw-w-36px tw-h-36px",
  "medium-small": "tw-w-32px tw-h-32px",
  small: "tw-w-24px tw-h-24px",
};

export const availableSizes = Object.keys(dimensionsDictionary);

export const dimensionsValue = (size) => dimensionsDictionary[size];

const fontDictionary = {
  "extra-large": "tw-font-normal tw-text-28d",
  large: "tw-font-normal tw-text-18d",
  medium: "tw-font-semibold tw-text-14d",
  "medium-small": "tw-font-semibold tw-text-14d",
  small: "tw-font-semibold tw-text-12d",
};

export const fontValue = (size) => fontDictionary[size];

export const sizeBasedClasses = (size) =>
  size === "none" ? "" : `${dimensionsValue(size)} ${fontValue(size)}`;

const borderDictionary = {
  neutral: "tw-border tw-border-solid tw-border-neutral-gray-15",
  tinted: "tw-border tw-border-solid tw-border-tinted-gray-50",
};

export const borderValue = (border) => (border === "none" ? "" : borderDictionary[border]);

const iconSizeDictionary = {
  "extra-large": "xxxl",
  large: "xl",
  medium: "l",
  "medium-small": "l",
  small: "m",
};

export const iconSizeValue = (size) => iconSizeDictionary[size];
