import React from "react";
import PropTypes from "prop-types";

const Nav = ({ children }) => (
  <div className="tw-bg-white tw-w-full tw-mb-[16px] tw-border tw-border-solid tw-border-neutral-gray-10 tw-rounded-8px">
    {children}
  </div>
);

Nav.propTypes = {
  children: PropTypes.element,
};

Nav.defaultProps = {
  children: null,
};

export default Nav;
