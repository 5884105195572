import React from "react";
import Proptypes from "prop-types";
import { Modal } from "react-bootstrap";
import PersonInteractionEmailForm from "../../PersonDetail/components/PersonInteractionEmailForm";
import HeaderTitle from "../../PersonDetail/components/modals/HeaderTitle";

const EmailModal = ({
  gmailUnsynced,
  sendEmailError,
  sendEmailUrl,
  isEmailSent,
  person,
  currentUserId,
  emailTemplates,
  modalOpen,
  closeModal,
  submitEmail,
  handleSubmitEmailError,
  defaultHomeAppTabContent,
}) => (
  <Modal
    id="email-interaction-modal"
    className="modal"
    backdrop="static"
    show={modalOpen}
    onHide={closeModal}
    enforceFocus={false}
  >
    <Modal.Header>
      <Modal.Title>
        <HeaderTitle title="Interaction: Email" onClose={closeModal} />
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <PersonInteractionEmailForm
        defaultHomeAppTabContent={defaultHomeAppTabContent}
        gmailUnsynced={gmailUnsynced}
        sendEmailError={sendEmailError}
        send_email_url={sendEmailUrl}
        isEmailSent={isEmailSent}
        submitEmail={submitEmail}
        handleSubmitEmailError={handleSubmitEmailError}
        person={person}
        email_templates={emailTemplates}
        current_user_id={currentUserId}
        closeModal={closeModal}
      />
    </Modal.Body>
  </Modal>
);

EmailModal.propTypes = {
  gmailUnsynced: Proptypes.bool.isRequired,
  person: Proptypes.shape({ full_name: Proptypes.string }),
  currentUserId: Proptypes.number,
  emailTemplates: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
    }),
  ),
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  isEmailSent: Proptypes.bool,
  sendEmailError: Proptypes.shape({ content: Proptypes.string, type: Proptypes.string }),
  submitEmail: Proptypes.func,
  handleSubmitEmailError: Proptypes.func,
  sendEmailUrl: Proptypes.string,
  defaultHomeAppTabContent: Proptypes.string,
};
EmailModal.defaultProps = {
  person: null,
  currentUserId: null,
  emailTemplates: [],
  modalOpen: false,
  closeModal: null,
  isEmailSent: false,
  sendEmailError: null,
  submitEmail: null,
  handleSubmitEmailError: null,
  sendEmailUrl: null,
  defaultHomeAppTabContent: null,
};

export default EmailModal;
