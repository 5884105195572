import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import { PlaySolidV6, PauseSolidV6 } from "@shared/v2/Icomoon";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import Dropdown from "@shared/v2/Dropdown";
import Modal from "@shared/v2/Modal";
import TextLink from "@shared/v2/TextLink";
import ShadowScroll from "@shared/v2/ShadowScroll";
import {
  ADMIN_INBOUND_RECORDING_OPTIONS,
  ADMIN_OUTBOUND_RECORDING_OPTIONS,
} from "./helpers/recordingOptions";
import {
  outboundRecordingNotificationOptions,
  inboundRecordingNotificationOptions,
} from "./helpers/recordingNotificationOptions";
import { CustomOption } from "./CallRecordingDropdown";
import {
  useAccountCallRecordingLoading,
  useAccountCallRecordingSettings,
  useIsPlace,
  useIsTwoPartyState,
} from "../../reducers/layoutReducer/selectors";
import { updateAccountRecordingSettings } from "../../reducers/layoutReducer";

const transitionStyles = {
  entering: "tw-opactiy-0 tw-max-h-0",
  entered: "tw-opacity-1 tw-max-h-[500px] tw-overflow-visible",
  exiting: "tw-opacity-0 tw-max-h-0",
  exited: "tw-opacity-0 tw-max-h-0 tw-mt-[-8px] tw-overflow-hidden",
};

const TeamCallRecordingSettingsModal = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const loading = useAccountCallRecordingLoading();
  const accountCallRecordingSettings = useAccountCallRecordingSettings();
  const isPlace = useIsPlace();
  const isTwoPartyState = useIsTwoPartyState();
  const twoPartyRef = useRef(null);
  const [formData, setFormData] = React.useState({});
  const [isPlaying, setIsPlaying] = React.useState(false); // Track play/pause state
  const audioRef = useRef(new Audio("https://dialer.staging.brivity.com/audio/start2.mp3"));

  // const showTwoPartyNotice =
  // formData.inboundNotificationMessage === false || formData.outboundNotificationMessage === false;
  const showTwoPartyNotice =
    formData.inboundNotificationMessageCondition !== "always" ||
    formData.outboundNotificationMessageCondition !== "always";
  const initialAgreeTwoParty =
    accountCallRecordingSettings.inboundNotificationMessageCondition !== "always" ||
    accountCallRecordingSettings.outboundNotificationMessageCondition !== "always";
  const [agreeTwoParty, setAgreeTwoParty] = React.useState(initialAgreeTwoParty);

  const setFormValue = (optOrEvent, action) => {
    const target = optOrEvent?.target;
    setFormData((prev) => ({
      ...prev,
      [action?.name || target.name]: optOrEvent.value || target.checked,
    }));
  };

  useEffect(() => {
    if (showTwoPartyNotice) setAgreeTwoParty(initialAgreeTwoParty);
  }, [showTwoPartyNotice]);

  useEffect(() => {
    if (accountCallRecordingSettings && open) {
      setFormData(accountCallRecordingSettings);
      setAgreeTwoParty(initialAgreeTwoParty);
    }
  }, [accountCallRecordingSettings, open]);

  useEffect(() => {
    const audio = audioRef.current;

    // Handle audio ending to reset to play icon
    const handleAudioEnd = () => setIsPlaying(false);
    audio.addEventListener("ended", handleAudioEnd);

    // Clean up event listener when component unmounts
    return () => audio.removeEventListener("ended", handleAudioEnd);
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    setIsPlaying(!isPlaying);
  };

  const PlayMessage = () => (
    <div>
      <Button size="small" schema="tertiary" onClick={togglePlayPause}>
        {isPlaying ? <PauseSolidV6 size="s" /> : <PlaySolidV6 size="s" />}
        <span className="tw-pl-[4px]"> message</span>
      </Button>
    </div>
  );

  const outboundNotificationOptions = outboundRecordingNotificationOptions(isPlace, isTwoPartyState);
  const inboundNotificationOptions = inboundRecordingNotificationOptions(isPlace, isTwoPartyState);
  console.log(isPlace, isTwoPartyState, outboundNotificationOptions, inboundNotificationOptions);

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[762px] tw-w-full"
      contentClassName="tw-w-[762px] tw-max-h-[90vh] tw-flex"
      show={open}
      onHide={onClose}
    >
      <form
        className="tw-flex tw-flex-col tw-gap-[32px]"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(updateAccountRecordingSettings(formData)).then(onClose);
        }}
      >
        <Modal.Header
          closeButton
          title="Team Call Recording Settings"
          description={
            <div>
              <span>
                These call recording settings will apply to outgoing calls made by all team members from the
                Brivity CRM, Brivity GO, and incoming calls to any Brivity Number. Learn more about{" "}
                <TextLink href={"https://our.place.com/spaces/64/call-recording/call-recording"}>
                  recommended settings
                </TextLink>
                .
              </span>
              {isTwoPartyState && (
                <div className="tw-pt-[26px]">
                  <div className="tw-bg-gray-5 tw-border-[2px] tw-border-neutral-gray-10 tw-border-solid tw-rounded-[2px]">
                    <div className="tw-px-[16px] tw-py-[12px]">
                      <div className="tw-font-semibold tw-text-14d tw-text-gray-75">
                        Your business is located in a state that requires 2-party consent to record
                      </div>
                      <div className="tw-text-12d tw-text-gray-75">
                        Your settings are restricted to protect you from legal risk when recording phone
                        calls.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
        <Modal.Body className="tw-flex-1 tw-overflow-auto">
          <div className="!tw-grid tw-grid-cols-2 tw-gap-[40px] tw-justify-between tw-pb-[16px]">
            <div>
              <h3 className="tw-text-18d tw-font-bold tw-m-0 tw-pb-[16px]">Inbound Calls</h3>
              <Dropdown
                label="Inbound Recording"
                components={{ Option: CustomOption }}
                options={ADMIN_INBOUND_RECORDING_OPTIONS}
                value={ADMIN_INBOUND_RECORDING_OPTIONS.find((o) => o.value === formData.inboundRecording)}
                name="inboundRecording"
                menuShouldComeToFront
                onChange={setFormValue}
              />
            </div>
            <div>
              <h3 className="tw-text-18d tw-font-bold tw-m-0 tw-pb-[16px]">Outbound Calls</h3>
              <Dropdown
                label="Outbound Recording"
                components={{ Option: CustomOption }}
                options={ADMIN_OUTBOUND_RECORDING_OPTIONS}
                value={ADMIN_OUTBOUND_RECORDING_OPTIONS.find((o) => o.value === formData.outboundRecording)}
                name="outboundRecording"
                menuShouldComeToFront
                onChange={setFormValue}
              />
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-2 tw-gap-[40px] tw-justify-between tw-pb-[16px]">
            {formData.inboundRecording !== "disabled" ? (
              <Dropdown
                label="Notification Message"
                components={{ Option: CustomOption }}
                options={inboundNotificationOptions}
                value={inboundNotificationOptions.find(
                  (o) => o.value === formData.inboundNotificationMessageCondition,
                )}
                name="inboundNotificationMessageCondition"
                menuShouldComeToFront
                onChange={setFormValue}
                toolTipPlacement="top"
                toolTipInfoContent={
                  <div className="tw-text-left tw-text-14d tw-w-[217px]">
                    Audio notification message must always play for businesses operating in states that
                    require 2-party consent to record phone calls.
                  </div>
                }
              />
            ) : (
              <div />
            )}
            {formData.outboundRecording !== "disabled" ? (
              <Dropdown
                label="Notification Message"
                components={{ Option: CustomOption }}
                options={outboundNotificationOptions}
                value={outboundNotificationOptions.find(
                  (o) => o.value === formData.outboundNotificationMessageCondition,
                )}
                name="outboundNotificationMessageCondition"
                menuShouldComeToFront
                onChange={setFormValue}
                toolTipPlacement="top"
                toolTipInfoContent={
                  <div className="tw-text-left tw-text-14d tw-w-[217px]">
                    Audio notification message must always play for businesses operating in states that
                    require 2-party consent to record phone calls.
                  </div>
                }
              />
            ) : (
              <div />
            )}
          </div>
          <div className="tw-grid tw-grid-cols-2 tw-gap-[40px] tw-justify-between tw-pb-[16px]">
            {formData.inboundRecording !== "disabled" ? <PlayMessage /> : <div />}
            {formData.outboundRecording !== "disabled" ? <PlayMessage /> : <div />}
          </div>
          <Transition in={showTwoPartyNotice} nodeRef={twoPartyRef}>
            {(state) => (
              <div
                ref={twoPartyRef}
                className={`tw-transition-all tw-duration-300 tw-ease-linear ${transitionStyles[state]}`}
              >
                <Alert
                  variant="warning"
                  title="Notice: 2-Party Recording Consent Laws"
                  text={
                    <div className="tw-flex tw-flex-col tw-gap-[8px]">
                      <ShadowScroll
                        className="tw-flex tw-flex-col tw-gap-[4px] tw-max-h-[116px]"
                        fromColor="tw-from-semantic-yellow-5"
                      >
                        <div>
                          Certain countries, states, and jurisdictions require the consent of all parties to
                          record telephone conversations. By disabling the notification feature and
                          subsequently recording telephone conversations without providing proper notice and
                          consent, you may be in violation of federal and/or state law.
                        </div>
                        <div>
                          By selecting the &quot;I agree&quot; checkbox, the Brivity account owner agrees
                          without reservation or limitation, to defend, indemnify, and hold harmless Brivity
                          Inc, its parent companies, affiliates, and their respective directors, officers,
                          employees, and agents from and against any and all losses, damages, claims,
                          liabilities, penalties, fines, judgments, settlements, actions, suits, proceedings,
                          litigation, investigations, legal costs and expenses, and demands made by any party
                          due to or arising out of the use of the Brivity Dialer call recording feature by the
                          Brivity account owner and/or his/her/its employees, agents, affiliates, or
                          representatives.
                        </div>
                      </ShadowScroll>
                      <div className="tw-flex tw-gap-[8px] tw-justify-between tw-items-center">
                        <Checkbox
                          label="I agree"
                          labelClassName="!tw-text-inherit"
                          checked={agreeTwoParty}
                          onChange={(e) => setAgreeTwoParty(e.target.checked)}
                        />
                        <Button
                          schema="secondary"
                          size="small"
                          className="!tw-text-semantic-yellow-120 !tw-border-inherit"
                          onClick={() =>
                            window.open(
                              "https://our.place.com/spaces/64/call-recording/call-recording",
                              "_blank",
                            )
                          }
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  }
                >
                  Two party consent
                </Alert>
              </div>
            )}
          </Transition>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-gap-[12px] tw-justify-between">
          <Button size="medium" schema="tertiary" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            type="submit"
            isLoading={loading}
            disabled={showTwoPartyNotice && !agreeTwoParty}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

TeamCallRecordingSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TeamCallRecordingSettingsModal;
