import { arrayMove } from "react-sortable-hoc";

export const onAddExpense = (agent, {openNewExpenseModal, setAgentExpense, setActiveAgentExpense}) => {
  openNewExpenseModal();
  setAgentExpense();
  setActiveAgentExpense(agent);
}

export const onSortEnd = (indexes, agentPosition, expensesPositionPointer, accountData, setAccountData, agent) => {
  const { 
    oldIndex, 
    newIndex
  } = indexes;    
  const newExpPointer = arrayMove([...expensesPositionPointer].filter(exp => !exp.hasOwnProperty('_destroy')), oldIndex, newIndex);

  let position = 1;
  for(let exp of newExpPointer){
    exp.position = position;
    position = position + 1;
  }
  accountData.agent_expense_templates.agent_expenses[agentPosition] = agent;

  setAccountData(accountData);
};
