import React from 'react';
import { Col, Row, Modal } from 'react-bootstrap'
import DatePicker from '../../../shared/DatePicker';
import buttons from '../../../PersonDetail/components/button.module.css';

const Reschedule = (props) => {
  const { closeModal, rescheduleDate, rescheduleDateHandler, rescheduleTasksHandler, submitDisabled, taskCount } = props

  return (
    <Modal id="reschedule-task-modal" className="person-details-modal" show={true} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col xs={12} style={{ height: "30px" }}>
              <div style={{ fontSize: "18px" }}>
                Hey! You're about to reschedule {taskCount} { taskCount > 1 ? 'tasks' : 'task' }!
              </div>
              <button type="button" className="close" style={{ top: "-24px" }}>
                <span aria-hidden="true" onClick={closeModal}>×</span>
                <span className="sr-only">Close</span>
              </button>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "0px" }}>
        <Row>
          <Col xs={12} className="form-group">
            <p>
              Don't worry, your client doesn't see when you reschedule something. Select how far you want to move these dates around.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <DatePicker
              timeFormat={false}
              dateFormat='MM-DD-YYYY'
              name={'reschedule_date'}
              value={rescheduleDate}
              onChange={rescheduleDateHandler}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          disabled={submitDisabled}
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue}`}
          onClick={rescheduleTasksHandler}
        >
          Reschedule
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Reschedule;
