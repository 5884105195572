import React from "react";
import PropTypes from "prop-types";

import CLASSES from "./classes.json";

const { PANEL_TITLE_CLASSES, PANEL_TITLE_TEXT_CLASSES } = CLASSES;

const PanelTitle = ({ className, deprecatedOverrideTextClasses, children, ...props }) => {
  const classes = `
    ${PANEL_TITLE_CLASSES}
    ${deprecatedOverrideTextClasses || PANEL_TITLE_TEXT_CLASSES}
    ${className}
  `;

  return (
    <h4 className={classes} {...props}>
      {children}
    </h4>
  );
};

PanelTitle.propTypes = {
  className: PropTypes.string,
  deprecatedOverrideTextClasses: PropTypes.string,
  children: PropTypes.node,
};

PanelTitle.defaultProps = {
  className: "",
  deprecatedOverrideTextClasses: "",
  children: null,
};

export default PanelTitle;
