import { useState } from "react";

const useFilterClickHandlr = (onSetActiveFilter) => {
  const [activeButton, setActiveButton] = useState("All");

  const buttonClickHandlr = (tagName) => {
    setActiveButton(tagName);
    onSetActiveFilter(tagName);
  };

  return { activeButton, buttonClickHandlr };
};

export default useFilterClickHandlr;
