import moment from "moment";
import format from "../../../../shared/formatters";
import { removeCommas } from "../../../../shared/v2/inputFormatUtils";
import { calcStatus, createPeopleListed, sortPropDetails } from "./helpers";
import { DATE_FORMAT } from "./constants";

const SERVER_DATE_FORMAT = "YYYY-MM-DD";

export const adaptDetailsPayloadToApi = (payload) => {
  switch (payload.key) {
    case "date_listed":
    case "expiration_date": {
      const date = moment(payload.value, DATE_FORMAT);

      return {
        ...payload,
        value: date.isValid() ? date.format(SERVER_DATE_FORMAT) : null,
      };
    }
    case "est_close_price":
      return {
        ...payload,
        value: Number(removeCommas(payload.value)),
      };
    case "status":
      return {
        ...payload,
        value: payload.value === "comingsoon" ? "coming soon" : payload.value,
      };
    default:
      return payload;
  }
};

const adaptDatesPayloadFromApi = (dateAsString) => {
  if (dateAsString === null) {
    return null;
  }

  const date = moment(dateAsString, SERVER_DATE_FORMAT);

  return date.isValid() ? date.format(DATE_FORMAT) : null;
};

const createPhotoset = (photos) =>
  photos.map((url, index) => ({
    id: index + 1,
    url,
    original: url,
    checked: false,
    deleteUrl: "",
    name: "",
    isMLS: true,
  }));

export const adaptGetAllDetails = (data) => {
  const state = {
    transactionDetails: {
      title: data.title,
      use_custom_title: data.use_custom_title,
      published: data.published ? "Published" : "Draft",
      status: calcStatus(data.status),
      est_close_price: format.number(data.est_close_price, 2, true),
      estimated_commission: data.estimated_commission,
      type: data.type,
    },
    listingDetails: {
      date_listed: adaptDatesPayloadFromApi(data.date_listed),
      expiration_date: adaptDatesPayloadFromApi(data.expiration_date),
      price: data.price,
      source: data.source,
      description: data.description,
      valid_mls_number: data["valid_mls_number?"],
      buyers_agent_commission: {
        id: data?.buyers_agent_commission?.id || "",
        percentage: data?.buyers_agent_commission?.percentage ?? true,
        value: data?.buyers_agent_commission?.value || "",
        cooperative_compensation: data?.buyers_agent_commission?.cooperative_compensation || false,
        exclude_from_commissions: data?.buyers_agent_commission?.exclude_from_commissions || false,
        status: data?.buyers_agent_commission?.status || "",
      },
      deposit: {
        id: data?.deposit?.id,
        hold_earnest_money: data?.deposit?.hold_earnest_money,
        amount: data?.deposit?.amount,
        additional_amount: data?.deposit?.additional_amount,
        deposit_due: {
          id: data?.deposit?.deposit_due?.id,
          date: data?.deposit?.deposit_due?.date,
          name: data?.deposit?.deposit_due?.name,
          include_time: data?.deposit?.deposit_due?.include_time,
          display_time: data?.deposit?.deposit_due?.display_time,
        },
        additional_earnest_money_due: {
          id: data?.deposit?.additional_earnest_money_due?.id,
          date: data?.deposit?.additional_earnest_money_due?.date,
          name: data?.deposit?.additional_earnest_money_due?.name,
          include_time: data?.deposit?.additional_earnest_money_due?.include_time,
          display_time: data?.deposit?.additional_earnest_money_due?.display_time,
        },
      },
    },
    propertyDetails: {
      id: data.address?.id,
      street_address: data.address?.street_address,
      city: data.address?.city,
      state: data.address?.locality,
      zip: data.address?.postal_code,
      property_type: data.property_type,
      neighborhood: data.address?.neighborhood,
      county: data.address?.county,
    },
    referralPartnerDetails: {
      team: data.team,
      brokerage: data.brokerage,
      name: data.referral_partner
        ? `${data.referral_partner?.first_name} ${data.referral_partner?.last_name}`
        : null,
      pdpLink: data.referral_partner ? `/people/${data.referral_partner?.slug}` : null,
    },
    referralDetails: {
      transactionAmt: format.number(data.transaction_amount, 2, true),
      referralFee: data.referral_fee ? format.number(data.referral_fee, 2, !data["fee_percentage?"]) : null,
      clientIntent: data.client_intent,
    },
    clientDetails: {
      financing: data.financing,
      addressField: data.desired_locations,
      name: data.referral_client
        ? `${data.referral_client?.first_name} ${data.referral_client?.last_name}`
        : null,
      pdpLink: data.referral_client ? `/people/${data.referral_client?.slug}` : null,
    },
    additionalDetails: {},
    propertyPagePath: data.property_page_path,
    links: data.links,
    useMLSphotos: data.use_mls_photos,
    blossorId: data.blossor_id,
    listingImgs: data.use_mls_photos
      ? createPhotoset(data.photos || [])
      : (data.photos || []).map((p) => ({ ...p, checked: false })),
    peopleListed: createPeopleListed(data.visible_team_members),
    checkAll: false,
  };

  return sortPropDetails(state, data);
};

export const adaptError = (error) => {
  const adaptedError = { ...error?.response?.data };

  if (!adaptedError.errors || !adaptedError.errors.length) {
    adaptedError.errors = ["We're sorry, an unexpected error has occurred. Please try again later."];
  }

  return adaptedError;
};
