export const showingTypesOptions = [
  {
    value: "showing_by_team",
    label: "Showing by Team",
  },
  {
    value: "external_showing",
    label: "External Showing",
  },
  {
    value: "open_house",
    label: "Open House",
  },
  {
    value: "broker_tour",
    label: "Broker Tour",
  },
];

export const showingTypeOptionByValue = (showingType) =>
  showingTypesOptions.find((type) => type.value === showingType);

export const showingTypeLabel = (showingType) => showingTypeOptionByValue(showingType)?.label;
