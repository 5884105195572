import React, { Component } from "react";
import { Col } from "../../shared/v1";
import FieldLabel from "../../shared/v2/FieldLabel";
import RadioButton from "../../shared/v2/RadioButton/RadioButton";

class PrioritySelection extends Component {
  render() {
    const { priorityNumbers, task, taskPriorityWatcher } = this.props;
    return (
      <Col size={12} className="tw-mb-[16px]">
        <FieldLabel className="tw-mb-8px" label="Priority (1: highest, 9: lowest)" isRequired />
        <div className={"priority-buttons"}>
          {priorityNumbers.map((priorityNumber) => (
            <RadioButton
              key={priorityNumber}
              labelClassName="after:!tw-content-none"
              value={priorityNumber}
              label={priorityNumber}
              name="priority"
              onClick={() => taskPriorityWatcher(priorityNumber)}
              checked={task.priority === priorityNumber}
            />
          ))}
        </div>
      </Col>
    );
  }
}

export default PrioritySelection;
