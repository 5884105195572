import React, { useEffect } from "react";
import { func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import getTimelineAsThunk from "../actions/thunks";
import withDispatch from "../../../../shared/HOCs/withDispatch";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import EventsHeader from "../EventsHeaders";
import LoadingEvents from "../LoadingEvents";
import { setEventsLoadingOn } from "../actions/creators";

const MainWrapper = ({ uuid, onGetTimelineAsThunk, onSetEventsLoadingOn }) => {
  useEffect(() => {
    const abortController = new AbortController();
    onSetEventsLoadingOn();
    onGetTimelineAsThunk(uuid, abortController.signal);

    return () => abortController.abort();
  }, []);

  return (
    <>
      <EventsHeader />
      <LoadingEvents />
    </>
  );
};

const withReduxStates = tdpDetails({
  WrappedComponent: MainWrapper,
  states: LISTING_UUID,
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: [getTimelineAsThunk, setEventsLoadingOn],
  aliases: ["onGetTimelineAsThunk", "onSetEventsLoadingOn"],
});

MainWrapper.propTypes = {
  uuid: string,
  onGetTimelineAsThunk: func,
  onSetEventsLoadingOn: func,
};

MainWrapper.defaultProps = {
  uuid: null,
  onGetTimelineAsThunk: () => {},
  onSetEventsLoadingOn: () => {},
};
