import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "../../shared/UtilsV1/FieldLabelV1";
import styleSchema from "../../shared/UtilsV1/FieldStyle";
import { CaretUp, CaretDown } from "../../shared/v2/Icomoon";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function RevenuePartnerSearchableDropdown({
  placeholder,
  options,
  onChange,
  className,
  disabled,
  label,
  required,
  flexStyle,
  setSearchText,
  searchText,
}) {
  const schemaKey = disabled ? "disabled" : "regular";

  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [filteredSearchOption, setFilteredSearchOption] = useState(options);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (searchText.length > 0) {
      setFilteredSearchOption(() => {
        const optionsFilteredWithInput = options.filter((option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase()),
        );
        return optionsFilteredWithInput;
      });
    } else {
      setFilteredSearchOption(options);
    }
  }, [searchText, options]);

  return (
    <div className={flexStyle}>
      {label && <FieldLabelV1 label={label} required={required} />}
      <div className={`tw-flex tw-text-sm tw-items-center tw-relative ${className}`} ref={ref}>
        <input
          type="text"
          className={classes}
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onFocus={() => {
            setIsDropdownOpen(true);
          }}
          data-cy="revenue_partner_search_input"
        />

        <div className="tw-text-neutral-gray-75 tw-absolute tw-right-[12px] tw-top-[9px]">
          {isDropdownOpen ? <CaretUp /> : <CaretDown />}
        </div>

        {isDropdownOpen && (
          <div className="dropdown-content tw-border tw-border-solid tw-rounded tw-text-sm tw-whitespace-nowrap tw-absolute tw-z-10 tw-max-h-[256px] tw-bg-white tw-min-w-full tw-top-[36px]">
            {filteredSearchOption.length <= 0 ? (
              <div className="tw-px-[14px] tw-py-1">no matches</div>
            ) : (
              filteredSearchOption.map((option) => (
                <div
                  key={option.label}
                  className="dropdown-item hover:tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1"
                  onClick={() => {
                    setSearchText(option.label);
                    onChange(option.value);
                    setIsDropdownOpen(false);
                  }}
                  data-cy={option.label}
                  aria-hidden="true"
                >
                  {option.label}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}

RevenuePartnerSearchableDropdown.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        data: PropTypes.shape({}),
      }),
    }),
  ),
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

RevenuePartnerSearchableDropdown.defaultProps = {
  placeholder: "Search",
  label: "",
  options: [
    { label: "Option1", value: { value: "Option1", data: null } },
    { label: "Option2", value: { value: "Option2", data: null } },
  ],
  required: false,
  flexStyle: "tw-flex-1",
  className: "",
  disabled: false,
  onChange: () => {},
};

export default RevenuePartnerSearchableDropdown;
