import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";

const CustomValueContainer = ({ componentProps, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown.ValueContainer {...componentProps} className="tw-w-full tw-flex tw-justify-center">
    {children}
  </Dropdown.ValueContainer>
);

CustomValueContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomValueContainer;
