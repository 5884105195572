import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import { Dropdown } from '../../shared/v1';
import button from './button.module.css';
import css from "./person-detail-styles.module.css";
import cards from './cards.module.css'
import icons from './icons.module.css';

class EditAddRelationship extends Component {
  state = {
    editingRelationship: false,
    addingRelationship: false,
    newRelatedToLabel: this.props.relationship.related_person_label
  };

  onRelationshipEdit = () => {
    this.setState({ editingRelationship: true });
  };

  onDismissRelationshipEdit = () => {
    this.setState({ editingRelationship: false });
  };

  onAddNewRelationship = () => {
    this.setState({ addingRelationship: true });
  };

  onDismissRelationshipAdd = () => {
    this.setState({ addingRelationship: false });
  };

  handleChangeLabel = (value) => {
    this.setState({newRelatedToLabel: value})
  }

  onSaveChanges = () => {
    this.props.saveRelationship(this.props.person.data.attributes, this.props.relationship.id, null, this.state.newRelatedToLabel);
    this.setState({ editingRelationship: false });
  }

  handleDelete = () => {
    this.props.deleteRelationship(this.props.person.data.attributes, this.props.relationship.id)
    this.setState({ editingRelationship: false })
  }

  render() {
    return (
      <div className={cards.cardRowNarrow}>
        <Row className={`${icons.inlineEditable} ${cards.fullRowHover}`}>
          { !this.state.editingRelationship ? (
            <div>
              <Col xs={10} className="d-flex flex-items-center" style={{height: "50px"}}>
                <a
                  className={css.brivityPersonRelationshipAvatar}
                  href={this.props.relationship.related_person_url}
                  dangerouslySetInnerHTML={{__html: this.props.relationship.related_to_person_image}}
                  style={{ marginRight: "18px" }}
                />
                <div className="overflow">
                  <a className={css.brivityPersonRelationshipName} href={this.props.relationship.related_person_url}>{this.props.relationship.related_person_name}</a>
                  <div className={css.brivityPersonRelationshipType}>{this.props.relationship.related_person_label === "Unspecified" ? ' ' : this.props.relationship.related_person_label}</div>
                  <div className={`${icons.hidden} fade-out`} />
                </div>
              </Col>
              <Col xs={2} className={`${icons.edit} d-flex flex-items-center`} style={{ height: "50px", justifyContent: "flex-end" }}>
                <a onClick={this.onRelationshipEdit} className={`${icons.blue} ${icons.button} ${icons.hidden}`}>
                  <i className={`${icons.smIcon} fa fa-pencil`} />
                </a>
              </Col>
            </div>
          ) : (
            <div>
              <Col xs={9} className="d-flex flex-items-center" style={{ height: "50px" }}>
                <a
                  className={css.brivityPersonRelationshipAvatar}
                  href={this.props.relationship.related_person_url}
                  dangerouslySetInnerHTML={{__html: this.props.relationship.related_to_person_image}}
                  style={{marginRight: "18px"}}
                />
                <div className="tw-text-14px" style={{ width: "100%" }}>
                  <Dropdown
                    isSearchable={false}
                    isClearable={false}
                    options={this.props.relationshipOptions}
                    onChange={(option) => this.handleChangeLabel(option.value)}
                    value={this.state.newRelatedToLabel === "Unspecified" ? null : this.state.newRelatedToLabel}
                    variant="flatBlueishGray"
                  />
                </div>
              </Col>
              <Col xs={3} className={`${icons.edit} d-flex flex-items-center`} style={{ height: "50px", justifyContent: "flex-end", flexWrap: "wrap"}}>
                <div style={{width: "50px"}}>
                  <div className="text-center" style={{ marginBottom: "4px" }}>
                    <a className={`${button.btn} ${button.btnSmNarrow} ${button.btnBlue}`} onClick={this.onSaveChanges}>Save</a>
                  </div>
                  <div>
                    <a className={`${icons.gray} ${icons.button} pull-left`} onClick={this.handleDelete}>
                      <i className={`${icons.smIcon} fa fa-trash`} />
                    </a>
                    <a className={`${icons.gray} ${icons.button} pull-right`} onClick={this.onDismissRelationshipEdit}>
                      <i className={`${icons.smIcon} fa fa-times`} />
                    </a>
                  </div>
                </div>
              </Col>
            </div>
          )}
        </Row>
      </div>
    );
  }
}

export default EditAddRelationship;
