/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowTurnUpSolidV6 = (props) => (
  <Icon {...props} name="Arrow Turn Up Solid V6">
    <path d="M12.706 5.706c-.194.197-.45.294-.734.294s-.512-.098-.707-.293L9 3.416V13.5C9 14.879 7.878 16 6.5 16H3a1 1 0 1 1 0-2h3.5c.275 0 .5-.225.5-.5V3.416L4.707 5.707a.999.999 0 1 1-1.414-1.414l3.972-4a.999.999 0 0 1 1.414 0l4 4a.977.977 0 0 1 .028 1.414z" />
  </Icon>
);

export default ArrowTurnUpSolidV6;
