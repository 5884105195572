import * as creators from "./creators";
import { calcMinPrice, calcMaxPrice, convertToInt } from "../components/Panel1/hookHelpers";
import {
  sendMarketReportApi,
  saveMarketReportApi,
  updateMarketReportApi,
  viewMarketReportApi,
} from "../services";

const preProcessData = (data) => {
  const dt = { ...data };
  dt.geo_json = data?.geo_json || data?.boundry_coordinates;
  if (dt.geo_json) {
    dt.geo_json = JSON.stringify(dt.geo_json);
  }
  delete dt.searchLocation;
  if (data?.search_type === "radius" && data?.radius_type === "smart-radius") {
    dt.smart_radius = true;
    delete dt.radius;
  } else if (data?.search_type === "area") {
    // remove this for area calls
    delete dt.lat_lng;
  }
  dt.min_price = calcMinPrice(dt.min_price);
  dt.max_price = calcMaxPrice(dt.max_price);

  dt.min_area = convertToInt(dt.min_area);
  dt.max_area = convertToInt(dt.max_area);
  dt.min_lot = convertToInt(dt.min_lot);
  dt.max_lot = convertToInt(dt.max_lot);
  dt.min_year_built = convertToInt(dt.min_year_built);
  dt.max_year_built = convertToInt(dt.max_year_built);

  if ((dt.min_beds && !dt.max_beds && dt.min_beds < 5) || !dt.min_beds) {
    dt.max_beds = dt.min_beds;
  }

  delete dt.boundry_coordinates;
  dt.property_type = Object.keys(dt.property_type)
    .map((k) => k)
    .join("|");
  return dt;
};

export const getMarketReportFn = (data) => async (dispatch) => {
  dispatch(creators.getMarketReport());
  const onFailure = (error) => dispatch(creators.getMarketReportError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.getMarketReportSuccess({ data: response.data }));
  };
  return viewMarketReportApi({ data, successCb: onSuccess, errorCb: onFailure });
};

export const saveMarketReportFn = (data, tab) => async (dispatch) => {
  dispatch(creators.saveMarketReport());
  const dt = { ...data };
  dt.property_type = Object.keys(dt.property_type).join(",");
  const onFailure = (error) => dispatch(creators.saveMarketReportError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error || !response.data?.id) {
      return onFailure(response?.data?.error);
    }
    const adaptedData = {
      ...response.data,
      report_type: response.data.has_address ? "home_value" : "market_report",
    };
    return dispatch(creators.saveMarketReportSuccess({ data: adaptedData, tab: tab }, tab));
  };
  return saveMarketReportApi({ data: preProcessData(data), successCb: onSuccess, errorCb: onFailure });
};

export const updateMarketReportFn = (data, tab) => async (dispatch) => {
  dispatch(creators.updateMarketReport());
  const onFailure = (error) => dispatch(creators.updateMarketReportError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error || !response.data?.id) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.updateMarketReportSuccess({ data: response.data }, tab));
  };
  return updateMarketReportApi({ data: preProcessData(data), successCb: onSuccess, errorCb: onFailure });
};

export const updateMarketReportDetailsFn = (data) => async (dispatch) => {
  dispatch(creators.updateMarketReport());
  const onFailure = (error) => dispatch(creators.updateMarketReportError({ error }));
  const onSuccess = (response) => {
    if (response?.data?.error || !response.data?.id) {
      return onFailure(response?.data?.error);
    }
    return dispatch(creators.updateMarketReportSuccess({ data: response.data }, 1));
  };
  return updateMarketReportApi({ data, successCb: onSuccess, errorCb: onFailure });
};

export const sendMarketReportFn = (reportData, personalizationData, close) => async (dispatch) => {
  dispatch(creators.sendMarketReport());
  const onSuccess = (response) => {
    dispatch(creators.sendMarketReportSuccess({ data: response.data }));
    return setTimeout(() => close(), 1000);
  };
  const onFailure = (error) => dispatch(creators.sendMarketReportError({ error }));
  return sendMarketReportApi({
    data: { ...reportData, ...personalizationData },
    successCb: onSuccess,
    errorCb: onFailure,
  });
};
