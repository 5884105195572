import React from "react";
import AdvertisingPlan from "./AdvertisingPlan";
import Cards from "./Cards";

const MarketingWrapper = () => (
  <>
    <AdvertisingPlan />
    <Cards />
  </>
);

export default MarketingWrapper;
