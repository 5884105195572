import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import MLSFieldView from "./MLSFieldView";
import TextButton from "../../../shared/v2/TextButton";
import { Plus } from "../../../shared/v2/Icomoon";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";
import { setPersonValue } from "../../../reducers/contactsReducer";

function AddMLSSection() {
  const dispatch = useDispatch();
  const {
    loginUserAttributes: { usersMlsesAttributes = [] },
  } = useContactPerson();
  const userMLSList = useMemo(
    // eslint-disable-next-line no-underscore-dangle
    () => usersMlsesAttributes.filter((mls) => !mls._destroy),
    [usersMlsesAttributes],
  );
  const [isAddActive, setIsAddActive] = useState(false);

  const onAddMLS = () => {
    const mlsInit = { mlsId: null, mlsAgentId: "", tempId: Date.now() };
    dispatch(
      setPersonValue({
        key: "loginUserAttributes.usersMlsesAttributes",
        value: [...usersMlsesAttributes, { ...mlsInit }],
      }),
    );
  };

  useEffect(() => {
    if (userMLSList.length === 0) onAddMLS();
  }, [userMLSList]);

  const onUpdateMls = (targetMLS, value) => {
    dispatch(
      setPersonValue({
        key: "loginUserAttributes.usersMlsesAttributes",
        value: usersMlsesAttributes.map((mls) => {
          if (mls !== targetMLS) return mls;
          return { ...targetMLS, ...value };
        }),
      }),
    );
  };

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-[24px]">
        {userMLSList.map((mls, index) => (
          <MLSFieldView
            key={mls.mlsId || mls.tempId}
            value={mls}
            index={index}
            setIsAddActive={setIsAddActive}
            onChange={(value) => onUpdateMls(mls, value)}
          />
        ))}
      </div>
      <TextButton onClick={onAddMLS} disabled={!isAddActive} className="tw-mt-[8px]" data-cy="add_mls_btn">
        <div className="tw-flex tw-gap-1 tw-items-center">
          <Plus size="s" />
          <div className="tw-text-[11px] tw-uppercase tw-font-bold">MLS</div>
        </div>
      </TextButton>
    </div>
  );
}

export default AddMLSSection;
