import { pick } from "lodash";
import moment from "moment";
import { camelizeKeys, snakefyKeys } from "../../../../shared/Utilities";

// The date format of the `shown_at` column (server-side)
const shownAtDateFormat = "YYYY-MM-DD";

const feedbackFromApi = (feedback) => ({
  ...camelizeKeys(pick(feedback, ["id", "liked", "body", "shown_by", "showing_type"])),
  externallyVisible: !!feedback.externally_visible, // It handles null as false
  shownAt: moment(feedback.shown_at, shownAtDateFormat),
});

const metaFromApi = (meta) => ({
  ...camelizeKeys(pick(meta, ["pagination"])),
  averageRating: Number.parseFloat(meta.average_rating ?? 0.0),
});

export const adaptGetAllFeedbacks = (data) => {
  let { showings, meta } = data;

  if (showings?.length) {
    showings = showings.map((showing) => feedbackFromApi(showing));
  } else {
    showings = [];
  }

  if (meta) {
    meta = metaFromApi(meta);
  }

  return { feedbacks: showings, meta };
};

export const adaptFeedback = (data) => feedbackFromApi(data);

export const feedbackToApi = (feedback) => ({
  ...snakefyKeys(pick(feedback, ["liked", "body", "externallyVisible", "shownBy", "showingType"])),
  ...(feedback.shownAt ? { shown_at: feedback.shownAt.format(shownAtDateFormat) } : {}),
});

export const adaptError = (error) => {
  if (!error?.response?.data?.message) {
    return {
      message: "Unknown error occurred. Please try again later",
    };
  }

  return error.response.data;
};
