/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const DesktopSolidV6 = (props) => (
  <Icon {...props} name="Desktop Solid V6">
    <path d="M14.667.889H1.334C.598.889.001 1.486.001 2.222v8.889c0 .736.597 1.333 1.333 1.333h5.333l-.444 1.333h-2c-.367 0-.667.3-.667.667s.3.667.667.667h7.556a.667.667 0 0 0 0-1.334h-2l-.444-1.333h5.333c.736 0 1.333-.597 1.333-1.333V2.222c0-.736-.597-1.333-1.333-1.333zm-.445 8H1.778V2.667h12.444v6.222z" />
  </Icon>
);

export default DesktopSolidV6;
