import React from "react";
import { useSelector } from "react-redux";
import { func } from "prop-types";
import Button from "@shared/v2/Button/Button";
import useMediaQuery from "@shared/v2/useMediaQuery";

const ReferFriendBtn = ({ openModal }) => {
  const isMobile = useMediaQuery("(max-width: 880px)");
  const theme = useSelector(({ layout }) => layout.theme);
  return (
    <Button
      schema={`${theme.name === "place-theme" ? "transparent-primary" : "primary"}`}
      onClick={openModal}
      className={`${
        theme.name === "place-theme" ? "hover:tw-border-white active:tw-border-white" : ""
      } tw-mr-[4px]`}
      size="small"
    >
      Refer {isMobile ? "" : "A Friend"}
    </Button>
  );
};

ReferFriendBtn.propTypes = {
  openModal: func,
};

ReferFriendBtn.defaultProps = {
  openModal: () => {},
};
export default ReferFriendBtn;
