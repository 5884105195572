import React, { useMemo } from "react";
import { Provider } from "react-redux";
import LeadIndexButton from "./LeadIndexButton";

const LeadIndexButtonApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <LeadIndexButton />
    </Provider>
  );
};

export default LeadIndexButtonApp;
