/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HandshakeRegularV6 = (props) => (
  <Icon {...props} name="Handshake Regular V6">
    <path d="M12.653 4.777C12.204 4.273 11.116 3.236 9.585 3A2.047 2.047 0 0 0 8 3.455c-.45-.344-1.022-.52-1.587-.434-1.992.308-3.245 1.983-3.297 2.056a.601.601 0 0 0 .968.71c.01-.014 1.005-1.348 2.513-1.581a.858.858 0 0 1 .539.112L5.682 5.803c-.31.308-.502.697-.502 1.157 0 .438.17.849.479 1.157.331.31.741.458 1.179.458s.849-.17 1.158-.48l.284-.284 3.105 2.522c.058.047.066.131.012.199l-.692.894c-.046.056-.131.065-.179.027l-.556-.467-.655.694a.256.256 0 0 1-.173.093.234.234 0 0 1-.17-.043l-.81-.692-.392.366-.07.073a.857.857 0 0 1-.582.315.857.857 0 0 1-.612-.17L4.261 9.591H2.404V4.804h-2.4v6.39l1.6.001a.793.793 0 0 0 .679-.404h1.516l1.927 1.742a2.047 2.047 0 0 0 1.515.452c.34-.035.66-.152.937-.338l.023.02c.301.247.682.36 1.067.32.33-.034.632-.176.848-.384a1.342 1.342 0 0 0 1.531-.412l.692-.894a1.336 1.336 0 0 0-.196-1.875L9.136 6.978l.209-.21a.601.601 0 0 0-.848-.849L7.15 7.264a.443.443 0 0 1-.617 0 .434.434 0 0 1 .004-.621l2.148-2.191a.837.837 0 0 1 .724-.251c1.494.231 2.505 1.568 2.515 1.582a.578.578 0 0 0 .296.209h1.386v4.377c0 .442.358.801.777.801l1.623.022V4.795l-3.347-.025zM1.2 10.4a.4.4 0 1 1 .001-.799.4.4 0 0 1-.001.799zm13.6 0a.4.4 0 1 1 .001-.799.4.4 0 0 1-.001.799z" />
  </Icon>
);

export default HandshakeRegularV6;
