import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const DirectToTimelineEvent = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const row = () => (
    <div className="timeline-event" onClick={showModal}>
      <div className="row">
        <div className="col-xs-1">
          <i className="fa fa-phone timeline-icon-circle" />
        </div>
        <div className="col-xs-7">
          <span className="bold-detail">Event:</span>
          {" Callingly Call"}
          <div className="timeline-description-text">
            <div>
              <div className="row details-row">
                <span>
                  {event.timeline_fields.event}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );

  const modal = () => (
    <Modal bsSize="sm" show={isModalOpen} onHide={hideModal} className="timeline-content">
      <div className="timeline-event timeline-event-modal">
        <Modal.Header closeButton>
          <Modal.Title>Profile Updated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row bold-detail details-row details-header">Details:</div>
          <div>
            <div className="row details-row">
              <span>
                {event.timeline_fields.event}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </div>
    </Modal>
  );

  return (
    <div>
      {row()}
      {modal()}
    </div>
  );
};

export default DirectToTimelineEvent;
