import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import TextComboInput from "@shared/v2/TextComboInput";
import { CheckSolidV6, XmarkSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import RadioButton from "@shared/v2/RadioButton";
import Typography from "@shared/v2/Typography";
import { isStrInputLen } from "./helpers";
import DeactivateUserModal from "../../Settings/Users/DeactivateUserModal";
import CannotReActivateUserModal from "../../Settings/Users/CannotReActivateUserModal";
import ActivateUserModal from "../../Settings/Users/ActivateUserModal";
import OptionsSection from "./OptionsSection";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useCurrentUser, useSupportPhoneNumber, useTheme } from "../../reducers/layoutReducer/selectors";
import { useContactPerson } from "../../reducers/contactsReducer/selectors";

function AccountSettingsView() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isPlace = useTheme().name === "place-theme";
  const supportPhoneNumber = useSupportPhoneNumber();
  const person = useContactPerson();
  const isSeatsAvailable = currentUser.account.seats > 0;
  const primaryEmail = useMemo(
    () => person.emailDetailsAttributes?.find((email) => email.position === 1)?.value || "",
    [person.emailDetailsAttributes],
  );
  const [isDeactivatingUser, setIsDeactivatingUser] = useState(false);
  const [isActivatingUser, setIsActivatingUser] = useState(false);
  const [isCannotReActivateUser, setIsCannotReActivateUser] = useState(false);

  useEffect(() => {
    dispatch(setPersonValue({ key: "loginUserAttributes.email", value: primaryEmail }));
  }, [primaryEmail]);

  if (!person.loginUserAttributes) return null;

  return (
    <div className={containerView}>
      <Typography variant="headline2">Account Settings</Typography>
      <TextComboInput
        label="Brivity Login Email"
        isRequired
        value={person.loginUserAttributes.email || ""}
        containerClassName="tw-flex-1"
        error={isStrInputLen(person.loginUserAttributes.email)}
        onChange={(e) =>
          dispatch(setPersonValue({ key: "loginUserAttributes.email", value: e.target.value }))
        }
        data-cy="brivity-login-meail"
      />
      {person.id && currentUser.person.id !== person.id && (
        <OptionsSection
          label="Resend User Invitation"
          copy="This user has already been invited to log in through their Brivity login email address. Would you like to resend this email?"
        >
          <Checkbox
            label="Yes, resend email invitation"
            className="tw-cursor-pointer"
            labelClassName="tw-cursor-pointer tw-text-semantic-blue-120"
            id="reinvite"
            checked={person.loginUserAttributes.inviteToOkta === true}
            onChange={() =>
              dispatch(
                setPersonValue({
                  key: "loginUserAttributes.inviteToOkta",
                  value: !person.loginUserAttributes.inviteToOkta,
                }),
              )
            }
          />
        </OptionsSection>
      )}
      {!person.id && (
        <OptionsSection
          label={`Invite User ${isPlace ? "to PLACE Platform" : ""}`}
          isRequired
          copy="Do you want to invite this user to log in? They will get an email at their primary email address."
        >
          <RadioButton
            labelClassName="tw-text-semantic-blue-120"
            label="Yes, invite this user to login"
            checked={person.loginUserAttributes.inviteToOkta === true}
            onChange={() =>
              dispatch(setPersonValue({ key: "loginUserAttributes.inviteToOkta", value: true }))
            }
          />
          {isPlace && (
            <Typography
              className="tw-flex tw-flex-col tw-gap-[4px] tw-text-semantic-blue-120 tw-pl-[24px]"
              variant="body-s"
            >
              <span>They will get access to:</span>
              <span>
                <CheckSolidV6 size="s" className="tw-mr-[2px]" /> PLACE Academy
              </span>
              <span>
                <CheckSolidV6 size="s" className="tw-mr-[2px]" /> Our PLACE
              </span>
              <span className={currentUser.account.defaultCrmAccess ? "" : "tw-text-semantic-red-120"}>
                {currentUser.account.defaultCrmAccess ? (
                  <CheckSolidV6 size="s" className="tw-mr-[2px]" />
                ) : (
                  <XmarkSolidV6 size="s" className="tw-mr-[2px]" />
                )}{" "}
                Brivity CRM
              </span>
              {!currentUser.account.defaultCrmAccess && (
                <span>
                  Note: This user will not have access to the Brivity CRM. Contact support in the live chat or
                  {` ${supportPhoneNumber} `} if you want this user to have access to Brivity.
                </span>
              )}
            </Typography>
          )}
          <RadioButton
            labelClassName="tw-text-semantic-blue-120"
            label="No, I'll do this later"
            checked={person.loginUserAttributes.inviteToOkta === false}
            onChange={() =>
              dispatch(setPersonValue({ key: "loginUserAttributes.inviteToOkta", value: false }))
            }
          />
        </OptionsSection>
      )}
      {person.id && (
        <div>
          {!person.loginUserAttributes.active ? (
            <Button
              schema="secondary"
              size="medium"
              onClick={() => (isSeatsAvailable ? setIsActivatingUser(true) : setIsCannotReActivateUser(true))}
            >
              Activate User
            </Button>
          ) : (
            <Button
              className={
                person.agentType === "Account Owner" || person.id === currentUser.person.id ? "tw-hidden" : ""
              }
              schema="warning"
              size="medium"
              onClick={() => setIsDeactivatingUser(true)}
            >
              Archive User
            </Button>
          )}
        </div>
      )}
      <DeactivateUserModal
        show={isDeactivatingUser}
        onClose={(data) => {
          setIsDeactivatingUser(false);
          if (data) dispatch(setPersonValue({ key: "loginUserAttributes.active", value: false }));
        }}
        userId={person.loginUserAttributes.id}
      />
      {isCannotReActivateUser && (
        <CannotReActivateUserModal
          show={isCannotReActivateUser}
          onClose={() => setIsCannotReActivateUser(false)}
        />
      )}
      {isActivatingUser && (
        <ActivateUserModal
          show={isActivatingUser}
          onClose={(data) => {
            setIsActivatingUser(false);
            if (data) dispatch(setPersonValue({ key: "loginUserAttributes.active", value: true }));
          }}
          userId={person.loginUserAttributes.id}
        />
      )}
    </div>
  );
}

export default AccountSettingsView;
