import React from "react";
import { string } from "prop-types";

import FieldLabel from "../../../../../shared/v2/FieldLabel";

import { DETAIL_FIELD_CONTAINER } from "../styles";

const DetailLinkField = ({ id, label, dataCy, fieldValue, txtBtnClassName, txtLink }) => (
  <div className={DETAIL_FIELD_CONTAINER} data-cy={dataCy}>
    <FieldLabel htmlFor={id} label={label} />
    <a href={txtLink} className={txtBtnClassName}>
      {fieldValue}
    </a>
  </div>
);

export default DetailLinkField;

DetailLinkField.propTypes = {
  id: string,
  label: string,
  dataCy: string,
  fieldValue: string,
  txtBtnClassName: string,
  txtLink: string,
};

DetailLinkField.defaultProps = {
  id: null,
  label: null,
  dataCy: null,
  fieldValue: null,
  txtBtnClassName: null,
  txtLink: null,
};
