import React from "react";
import { string, func, bool } from "prop-types";
import { connect } from "react-redux";

import { clearSecondaryFilter } from "../actions/creators";
import TextButton from "../../../../shared/v2/TextButton";

const mapDispatchToProps = (dispatch) => ({
  onClearSecondaryFilter: () => dispatch(clearSecondaryFilter()),
});

export const SecondaryFilter = ({ children, clicked }) => (
  <button
    type="button"
    className="tw-p-[12px] tw-py-[6px] tw-px-[12px] tw-cursor-pointer tw-text-left tw-border-0 tw-bg-transparent tw-block tw-w-full"
    onClick={clicked}
  >
    {children}
  </button>
);

export const Clearer = connect(
  (state) => ({ filtered: state.tdpTasksReducer.filtered }),
  mapDispatchToProps,
)(({ filtered, onClearSecondaryFilter, toggleDrawer }) => (
  <TextButton
    disabled={!filtered}
    onClick={() => {
      onClearSecondaryFilter();
      toggleDrawer();
    }}
  >
    CLEAR FILTER
  </TextButton>
));

SecondaryFilter.propTypes = {
  children: string,
  clicked: func,
};

SecondaryFilter.defaultProps = {
  children: null,
  clicked: () => {},
};

Clearer.propTypes = {
  filtered: bool,
  onClearSecondaryFilter: func,
  toggleDrawer: func,
};

Clearer.defaultProps = {
  onClearSecondaryFilter: () => {},
  toggleDrawer: () => {},
};
