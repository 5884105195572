import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormData,
  setErrors,
  setPersonValue,
  setShowPhoneContext,
  upsertPerson,
} from "../../reducers/contactsReducer";
import { useShowPhoneContext } from "../../reducers/contactsReducer/selectors";

const usePersonDetailsModal = ({ modalType, person, personId, show }) => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.contactsReducer.errors);
  const formLoading = useSelector((state) => state.contactsReducer.formOptionsLoading);
  const loading = useSelector((state) => state.contactsReducer.loading);
  const showPhoneContext = useShowPhoneContext();

  useEffect(() => {
    if (!show) return;
    dispatch(getFormData({ modalType, personId, person }));
  }, [person, personId, show]);

  return {
    actions: {
      clearErrors: () => dispatch(setErrors()),
      upsertPerson: (opts) => dispatch(upsertPerson(opts)),
      setPersonValue: (opts) => dispatch(setPersonValue(opts)),
      setShowPhoneContext: (opts) => dispatch(setShowPhoneContext(opts)),
    },
    errors,
    formLoading,
    showPhoneContext,
    loading,
  };
};

export default usePersonDetailsModal;
