import React, { useEffect } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { getDocumentsAsThunk } from "./actions/thunks";

import SearchUpload from "./SearchUpload";
import Table from "./Table";
import Pagination from "./Pagination";
import { MetaPropType } from "./propTypes";

const Documents = ({ dispatch, meta }) => {
  useEffect(() => {
    if (!meta.pagination.current) {
      // Only fetch documents if they haven't been fetched yet
      dispatch(getDocumentsAsThunk());
    }
  }, [meta.pagination.current]);

  useEffect(() => {
    if (meta.pagination.current > 0) {
      // Document URLs expire every 600 seconds (from `DocumentDecorator`)
      const expireAtMs = meta.updatedAtMs + 600000;

      // Refresh documents to prevent URLs from expiring for when previewing/downloading
      const timeoutMs = Math.max(0, expireAtMs - Date.now());
      const timeoutId = window.setTimeout(
        () => dispatch(getDocumentsAsThunk(meta.pagination.current, true)),
        timeoutMs,
      );
      return () => window.clearTimeout(timeoutId);
    }

    return () => {};
  }, [meta.pagination.current, meta.updatedAtMs]);

  return (
    <div className="tw-flex tw-flex-col">
      <h2 className="tw-text-18d tw-font-bold tw-text-neutral-gray-75 tw-m-0 tw-mb-24px">Documents</h2>
      <SearchUpload />
      <Table />
      <Pagination />
    </div>
  );
};

Documents.propTypes = {
  dispatch: func,
  meta: MetaPropType,
};

Documents.defaultProps = {
  dispatch: () => {},
  meta: {},
};

const mapStateToProps = (state) => ({
  meta: state.tdpDocumentsReducer.meta,
});

export default connect(mapStateToProps)(Documents);
