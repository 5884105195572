/* eslint-disable react/prop-types, react/jsx-props-no-spreading */

import React, { useMemo } from "react";
import { isEqual } from "lodash";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import ClearIndicator from "./ClearIndicator";

const getSourceList = (sources) =>
  sources.map((source) => ({
    label: source.name,
    value: source.id.toString(),
  }));

const getSelectedSources = (sources, sourceIds) =>
  getSourceList(sources.filter((source) => sourceIds.includes(source.id.toString())));

const getSelectedSourcesIds = (sources) => sources.map((agent) => agent.value);

const SourceFilter = ({ initialSources, onSourceChange, sourceIds = [] }) => {
  const selectedSources = useMemo(
    () => getSelectedSources(initialSources, sourceIds),
    [initialSources, sourceIds],
  );
  const sourceList = useMemo(() => getSourceList(initialSources), [initialSources]);

  const handleOnChange = (newSelectedSources) => {
    if (isEqual(newSelectedSources, selectedSources)) {
      return;
    }

    onSourceChange(getSelectedSourcesIds(newSelectedSources));
  };

  return (
    <Dropdown
      label="Source"
      placeholder="Select one or more sources"
      isMulti
      options={sourceList}
      value={selectedSources}
      onChange={handleOnChange}
      menuShouldComeToFront
      isSearchable
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{
        ClearIndicator,
      }}
    />
  );
};

export default SourceFilter;
