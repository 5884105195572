export const BRIVITY_COLORS = {
  theme: "brivity",
  color1: {
    default: "#59C4C4",
    hover: "#4CA7A7",
  },
  color2: {
    default: "#BDE7E7",
    hover: "#94DCDC",
  },
  color3: {
    default: "#FFA826",
    hover: "#E5941B",
  },
};

export const PLACE_COLORS = {
  theme: "place",
  color1: {
    default: "#3270FA",
    hover: "#2454B2",
  },
  color2: {
    default: "#96B7FA",
    hover: "#578EFA",
  },
  color3: {
    default: "#121212",
    hover: "#666666",
  },
};

const colors = document.body.classList.contains("place-theme") ? PLACE_COLORS : BRIVITY_COLORS;

export default colors;
