import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Moment from 'moment';

const SuperUsersTable = (props) => {
  const { onEdit, onRemove, superUsers } = props;

  const handleOnRemove = (e, superUser) => {
    e.preventDefault();

    if (window.confirm("Are you sure?")) {
      onRemove(superUser.id);
    }
  }

  const dateFormat = "MM/DD/YYYY h:mm:ss a";

  const superUserTableRow = (superUser) =>
    <tr key={superUser.id}>
      <td>{superUser.id}</td>
      <td>{superUser.email}</td>
      <td>{superUser.role}</td>
      <td>{Moment(superUser.created_at).format(dateFormat)}</td>
      <td>{Moment(superUser.updated_at).format(dateFormat)}</td>
      <td>
        <Button
          onClick={() => onEdit(superUser)}>
          Edit
        </Button>
      </td>
      <td>
        <Button
          onClick={(e) => handleOnRemove(e, superUser)}>
          Remove
        </Button>
      </td>
    </tr>

  return (
    <Row>
      <Col md={12}>
        <table className="table table-striped table-hover table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Role</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th aria-label="edit super user" />
              <th aria-label="remove super user" />
            </tr>
          </thead>
          <tbody>{superUsers.map(superUserTableRow)}</tbody>
        </table>
      </Col>
    </Row>
  )
}
export default SuperUsersTable;
