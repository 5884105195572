import { produce } from "immer";
import { actionTypes } from "../Settings/Users/actions/actionTypes";
import {
  setCrmAccessModal,
  setLoadingOktaBtn,
  setSuccessOktaBtn,
  setErrorOktaBtn,
  clearOktaBtn,
  setSuspendError,
  setSuspendUser,
} from "../Settings/Users/actions/default";
import { createCustomSelector } from "@shared/hookHelpers";

const initialState = {
  activeUsers: [],
  archiveUsers: [],
  isSeatsAvailable: false,
  editPersonId: null,
  isUserModalOpen: false,
  showSuspendUserError: null,
  isTableDataLoading: false,
  tableSortColumn: { key: "fullName", isAsc: true },
  usersTableErrors: [],
  isCrmAccessModalOpen: false,
  crmAccessUserId: null,
  loadingCrmUserIdAccess: null,
  successCrmUserIdAccess: null,
  errorCrmUserIdAccess: null,
};

const userSettings = (state = initialState, payload) => {
  const defaultState = { ...initialState, ...state };

  switch (payload.type) {
    case actionTypes.GET_USER_TABLE_DATA:
      const { key: title, isAsc: ascOrder } = state.tableSortColumn;

      const sortByTitleAndOrder = (a, b, title, ascOrder) => {
        if (a[title] < b[title] || (!a[title] && b[title])) {
          return ascOrder ? -1 : 1;
        }
        if (a[title] > b[title] || (a[title] && !b[title])) {
          return ascOrder ? 1 : -1;
        }

        return 0;
      };
      return {
        ...state,
        activeUsers: payload.activeUsers.sort((a, b) => sortByTitleAndOrder(a, b, title, ascOrder)),
        archiveUsers: payload.archiveUsers.sort((a, b) => sortByTitleAndOrder(a, b, title, ascOrder)),
        isTableDataLoading: false,
      };

    case actionTypes.SUSPEND_USER:
      return {
        ...state,
        editingUser: payload.person,
      };
    case actionTypes.SET_USERS_TABLE_ERRORS:
      return {
        ...state,
        usersTableErrors: payload.errors.length === 0 ? [] : state.usersTableErrors.concat(payload.errors),
      };

    case actionTypes.SET_IS_TABLE_DATA_LOADING:
      return {
        ...state,
        isTableDataLoading: payload.isTableDataLoading,
      };

    case actionTypes.UPDATE_SORT_ON_TABLE_COLUMN:
      if (state.tableSortColumn.key === payload.key) {
        return {
          ...state,
          tableSortColumn: { key: payload.key, isAsc: !state.tableSortColumn.isAsc },
        };
      } else {
        return {
          ...state,
          tableSortColumn: { key: payload.key, isAsc: true },
        };
      }
    case actionTypes.EDIT_USER_MODAL_TOGGLE:
      return produce(state, (draft) => {
        draft.isUserModalOpen = !state.isUserModalOpen;
        draft.editPersonId = payload.editPersonId;
      });
    case setCrmAccessModal().type:
      return produce(state, (draft) => {
        draft.isCrmAccessModalOpen = payload.bool;
        draft.crmAccessUserId = payload.userId;
      });
    case setLoadingOktaBtn().type:
      return produce(state, (draft) => {
        draft.loadingCrmUserIdAccess = payload.userId;
      });
    case setSuccessOktaBtn().type:
      return produce(state, (draft) => {
        draft.loadingCrmUserIdAccess = null;
      });
    case setErrorOktaBtn().type:
      return produce(state, (draft) => {
        draft.loadingCrmUserIdAccess = null;
        draft.errorCrmUserIdAccess = payload.userId;
      });
    case clearOktaBtn().type:
      return produce(state, (draft) => {
        draft.errorCrmUserIdAccess = null;
      });
    case setSuspendUser().type:
      return {
        ...state,
        activeUsers: state.activeUsers.map((user) => {
          if (user.userId === payload.editedUser) {
            return { ...user, suspended: payload.bool };
          }
          return user;
        }),
      };
    case setSuspendError().type:
      return produce(state, (draft) => {
        draft.showSuspendUserError = payload.bool;
      });
    default:
      return defaultState;
  }
};

export default userSettings;

// Selectors
const useCreateSelector = createCustomSelector(({ userSettings }) => userSettings);

export const useIsCrmAccessModalOpenSelector = () => useCreateSelector((state) => state.isCrmAccessModalOpen);
export const useCrmAccessUserId = () => useCreateSelector((state) => state.crmAccessUserId);
export const useLoadingCrmUserIdAccess = () => useCreateSelector((state) => state.loadingCrmUserIdAccess);
export const useSuccessCrmUserIdAcccess = () => useCreateSelector((state) => state.successCrmUserIdAccess);
export const useErrorCrmUserIdAccess = () => useCreateSelector((state) => state.errorCrmUserIdAccess);
export const useSuspendedUserError = () => useCreateSelector((state) => state.showSuspendUserError);
