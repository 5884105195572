import React, { useState } from "react";
import { func, bool, shape, arrayOf, string } from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import Dropdown from "@shared/v2/Dropdown/Dropdown";

const getOption = (data) => {
  return data.map((item) => ({
    label: `${item.full_name}  (${item.primary_email_address})`,
    value: item.id,
  }));
};

const RemoveCoBorrowerModal = ({ modalOpen, closeModal, handleRemoveCoBorrower, coborrowers }) => {
  const coborrowersList = getOption(coborrowers);

  const [selectedCoBorrower, setSelectedCoBorrower] = useState(null);

  const handleRemove = () => {
    closeModal();
    handleRemoveCoBorrower(selectedCoBorrower);
  };
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[440px] tw-w-full"
      show={modalOpen}
      onHide={closeModal}
    >
      <Modal.Header
        closeButton
        title="Remove a Co-Borrower"
        description={
          <span>
            Select a co-borrower to remove from the loan record. The loan will no longer appear on that
            person’s contact record.
          </span>
        }
      />
      <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-pt-[32px] tw-pb-[8px]">
          <div className="tw-grid tw-grid-cols-2 tw-mb-[24px] tw-gap-[24px]">
            <div className="tw-col-span-2">
              <Dropdown
                menuShouldComeToFront
                value={selectedCoBorrower}
                options={coborrowersList}
                onChange={(value) => setSelectedCoBorrower(value)}
                isSearchable
                placeholder="Select a co-borrower"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className=" tw-flex tw-gap-[12px] tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          size="medium"
          disabled={!selectedCoBorrower}
          schema="warning"
          type="warning"
          onClick={handleRemove}
        >
          Remove Co-Borrower
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RemoveCoBorrowerModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  handleRemoveCoBorrower: func,
  coborrowers: arrayOf(
    shape({
      id: string,
      full_name: string,
      primary_email_address: string,
    }),
  ),
};

RemoveCoBorrowerModal.defaultProps = {
  modalOpen: false,
  closeModal: () => {},
  handleRemoveCoBorrower: () => {},
  coborrowers: [],
};

export default RemoveCoBorrowerModal;
