import React from "react";
import PropTypes from "prop-types";

import { indexAssignedPlansByState } from "../api/apiAdapters";

import AssignedPlanRow from "./AssignedPlanRow";
import CollapsibleCategory from "./CollapsibleCategory";

const AssignedPlansByState = ({
  assignedPlans,
  onPlanClicked,
  onPlayOrPauseButtonClicked,
  onTrashButtonClicked,
}) =>
  Object.entries(indexAssignedPlansByState(assignedPlans)).map(([stateName, statePlans]) => {
    const statePlanCount = statePlans.length;

    return (
      <CollapsibleCategory key={stateName} name={stateName} count={statePlanCount}>
        {statePlans.map((plan) => (
          <AssignedPlanRow
            id={plan.id}
            key={plan.id}
            name={plan.name}
            state={plan.state}
            isLoading={plan.isLoading}
            currentAction={plan.totalCompletedActions}
            totalActionCount={plan.assignedActionsCount}
            onPlanClicked={() => onPlanClicked(plan.id)}
            onPlayOrPauseButtonClicked={() => {
              const desiredState = plan.state === "paused" ? "started" : "paused";
              onPlayOrPauseButtonClicked(plan.id, desiredState);
            }}
            onTrashButtonClicked={() => onTrashButtonClicked(plan.id)}
          />
        ))}
      </CollapsibleCategory>
    );
  });
AssignedPlansByState.propTypes = {
  assignedPlans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      totalCompletedActions: PropTypes.number.isRequired,
      assignedActionsCount: PropTypes.number.isRequired,
      /* To disable pause/resume buttons while a request is underway. */
      isLoading: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onPlanClicked: PropTypes.func.isRequired,
  onPlayOrPauseButtonClicked: PropTypes.func.isRequired,
  onTrashButtonClicked: PropTypes.func.isRequired,
};

export default AssignedPlansByState;
