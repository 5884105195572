/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import Circle from "../../shared/Circle";
import { toTitleCase, sortByKey } from "../../shared/Utilities";
import "./AssignedPlans.scss";
import { getAutoPlanActionById } from "./helpers";
import InteractionDetail from "./InteractionDetail";
import TaskDetail from "./TaskDetail";
import { actionLookup, actionSubtitle, dateLabel, iconOptions } from "./AssignedPlanDetailHelpers";

class AssignedPlanDetailPreview extends React.Component {
  circlePropsFromState = (action) => {
    if (action.state === "failed") {
      return {
        icon: "fa fa-close text-white",
        circleStyle: "background-danger",
      };
    }
    return {
      icon: `${iconOptions(action.type)} text-gray`,
      circleStyle: "default",
    };
  };

  renderAction(actionId, actionInDisplayId) {
    const { autoPlanPreview, previewAutoPlanAction } = this.props;
    const action = actionLookup(autoPlanPreview.actions, actionId);

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        key={action.id}
        className={`grid-item d-flex flex-items-center pseudo-link ${
          action.id === actionInDisplayId ? "active" : ""
        }`}
        onClick={() => previewAutoPlanAction(action.id)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Circle {...this.circlePropsFromState(action)} />

        <div>
          <div className="one-line title">
            <span>{`${action.type}: `}</span>
            <span className="bold">{action.name}</span>
          </div>
          <div className="one-line understated">
            <span>{actionSubtitle(action)}</span>
            <span className="text-danger"> {action.failed_message}</span>
            <span className="text-danger"> {action.overdue && action.type === "Task" && "Overdue"}</span>
          </div>
        </div>
      </div>
    );
  }

  renderActionGroup(groupType, actions, subGroupType, actionInDisplayId) {
    const groupLabel = groupType === "days" ? dateLabel(subGroupType) : toTitleCase(subGroupType);
    const sortByField = groupType === "days" ? "run_at" : "sort_key";

    return (
      <div key={subGroupType}>
        <div className="day-label">{groupLabel}</div>

        {
          sortByKey(actions, sortByField)
            .sort((a, b) => ((a.position || 0) > (b.position || 0)) ? 1 : -1)
            .map((action) => this.renderAction(action.id, actionInDisplayId))
        }
      </div>
    );
  }

  render() {
    const { autoPlanPreview, activeAutoPlanActionId } = this.props;
    const actionInDisplay =
      autoPlanPreview?.actions &&
      (getAutoPlanActionById(activeAutoPlanActionId, autoPlanPreview) ||
        autoPlanPreview.actions.sortBy("run_at")[0]);
    const parentAction = actionInDisplay
      ? getAutoPlanActionById(actionInDisplay.parent_action_id, autoPlanPreview)
      : null;

    return (
      <div className="row">
        <div className="assigned-plan-detail-grid col-xs-12">
          <div className="left-column">
            <div className="grid-items">
              {autoPlanPreview &&
                autoPlanPreview.actions &&
                autoPlanPreview.grouped_actions &&
                Object.keys(autoPlanPreview.grouped_actions)
                  .sort()
                  .map((groupType) => {
                    const group = autoPlanPreview.grouped_actions[groupType];
                    return Object.keys(group)
                      .sort()
                      .map((subGroupKey) =>
                        this.renderActionGroup(
                          groupType,
                          group[subGroupKey],
                          subGroupKey,
                          actionInDisplay.id,
                        ),
                      );
                  })}
            </div>
          </div>
          <div className="right-column">
            {actionInDisplay && (
              <div className="active-action">
                {(actionInDisplay.type === "Email" || actionInDisplay.type === "Text") && (
                  <InteractionDetail action={actionInDisplay} parentAction={parentAction} />
                )}
                {actionInDisplay.type === "Task" && (
                  <TaskDetail action={actionInDisplay} parentAction={parentAction} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AssignedPlanDetailPreview;

AssignedPlanDetailPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  autoPlanPreview: PropTypes.objectOf(PropTypes.any).isRequired,
  activeAutoPlanActionId: PropTypes.string.isRequired,
  previewAutoPlanAction: PropTypes.func.isRequired,
};
