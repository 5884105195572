import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import createLeadSourceChartOptions from "../helpers/createLeadSourceChartOptions";
import { transformBusinessByLeadSourceData, transformSummaryData } from "../transforms/businessByLeadSource";

const useBusinessByLeadSource = ({ agentUuid, checks, selectedSources, year }) => {
  const [rawData, setRawData] = useState();
  const [chartData, setChartData] = useState({ datasets: [] });
  const [summary, setSummary] = useState({ sources: 0, ytdClosedUnits: 0, netFromSources: 0 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const sourceLabels = useMemo(
    () =>
      [{ value: "all", label: "All Sources" }].concat(
        (rawData?.leadSources || []).map(({ id: value, name: label }) => ({ label, value })),
      ),
    [rawData],
  );

  useEffect(() => {
    setChartData(transformBusinessByLeadSourceData({ checks, selectedSources, rawData }));
    setSummary(transformSummaryData(rawData));
  }, [checks, selectedSources, rawData]);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);

    axios
      .get(`/api/v4/reporting/${agentUuid ? "agent" : "business"}_dashboard`, {
        params: {
          person_uuid: agentUuid,
          section: `${agentUuid ? "agent" : "business"}_by_lead_source`,
          selected_year: year,
        },
        signal: abortController.signal,
      })
      .then(({ data }) => {
        setRawData(data);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [agentUuid, year]);

  return {
    data: {
      chartOptions: createLeadSourceChartOptions({ count: selectedSources.length || 0, year }),
      chartData,
      sourceLabels,
      summary,
    },
    error,
    loading,
  };
};

export default useBusinessByLeadSource;
