import React from "react";
import PropTypes from "prop-types";
import { SortDownSolidV6, SortUpSolidV6 } from "@shared/v2/Icomoon";

const BORDER_STYLE = "tw-border-solid tw-border-l-0 tw-border-y-0 tw-border-gray-10";

export const Table = ({ children, className, ...props }) => (
  <table className={`tw-w-full ${className}`} {...props}>
    {children}
  </table>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Table.defaultProps = {
  className: "",
};

export const THead = ({ children, className, ...props }) => (
  <thead className={`tw-text-12d tw-text-neutral-gray-50 ${className}`} {...props}>
    {children}
  </thead>
);

THead.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

THead.defaultProps = {
  className: "",
};

export const TBody = ({ children, className, ...props }) => (
  <tbody className={`tw-text-14d ${className}`} {...props}>
    {children}
  </tbody>
);

TBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

TBody.defaultProps = {
  children: null,
  className: "",
};

export const TH = ({ border, children, className, onSort, sort, ...props }) => (
  <td className={`tw-p-[12px] ${border ? `${border} ${BORDER_STYLE}` : ""} ${className}`} {...props}>
    <div className="tw-inline-flex tw-items-center tw-gap-[4px]">
      {children}
      {onSort && (
        <button
          aria-label="sort"
          type="button"
          className="tw-flex tw-flex-col tw-border-0 tw-p-0 tw-bg-transparent"
          onClick={onSort}
        >
          <SortUpSolidV6 className={`tw-mb-[-8px]${sort === "asc" ? " tw-text-black" : ""}`} size="l" />
          <SortDownSolidV6 className={`tw-mt-[-8px]${sort === "desc" ? " tw-text-black" : ""}`} size="l" />
        </button>
      )}
    </div>
  </td>
);

TH.propTypes = {
  border: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onSort: PropTypes.func,
  sort: PropTypes.oneOf(["asc", "desc", false]),
};

TH.defaultProps = {
  border: "",
  children: null,
  className: "",
  onSort: null,
  sort: false,
};

export const TR = ({ children, className, wrapText, ...props }) => (
  <tr
    className={`tw-border tw-border-solid tw-border-gray-10 ${
      wrapText ? "" : "tw-whitespace-nowrap"
    } ${className}`}
    {...props}
  >
    {children}
  </tr>
);

TR.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  wrapText: PropTypes.bool,
};

TR.defaultProps = {
  className: "",
  wrapText: false,
};

export const TD = ({ border, children, className, isActive, ...props }) => (
  <td
    className={`tw-p-[12px] ${isActive ? "tw-bg-brivity-blue-10" : ""} ${
      border ? `${border} ${BORDER_STYLE}` : ""
    } ${className}`}
    {...props}
  >
    {children}
  </td>
);

TD.propTypes = {
  border: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

TD.defaultProps = {
  border: "",
  children: null,
  className: "",
  isActive: false,
};
