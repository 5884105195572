import { connect } from "react-redux";
import AutoPlans from "../components/AutoPlans";
import * as autoPlanActions from "../actions/autoPlansActionCreators";
import * as triggerActions from "../../Triggers/actions/triggersActionCreators";

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({ ...state.autoPlans, triggers: state.triggers, supportPhoneNumber: state.layout.support.phoneNumber });

// Don't forget to actually use connect!
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, { ...autoPlanActions, ...triggerActions })(AutoPlans);
