import React from "react";
import { string } from "prop-types";

import {
  FileSolidV3,
  ImageSolidV6,
  Bullhorn,
  Create,
  HeartOutline,
  Check,
  Bolt,
  Trash,
  Pause,
  Play,
  Envelope,
  Chat,
  Phone,
  Calendar,
  Home,
  User,
  ArrowDownToBracketSolidV6,
} from "../../../../shared/v2/Icomoon";

const EventIcon = ({ icon }) => (
  <div className="tw-border-solid tw-border-2px tw-rounded-full tw-w-[40px] tw-h-[40px] tw-uppercase tw-font-bold tw-inline-flex tw-justify-center tw-items-center">
    {icon === "document" && <FileSolidV3 size="l" />}
    {icon === "listingadvertiser" && <Bullhorn size="l" />}
    {icon === "upload" && <ImageSolidV6 size="l" />}
    {icon === "note" && <Create size="l" />}
    {icon === "showing" && <HeartOutline size="l" />}
    {icon === "marketer" && <Bullhorn size="l" />}
    {icon === "task" && <Check size="l" />}
    {icon === "added" && <Bolt size="l" />}
    {icon === "applied" && <Bolt size="l" />}
    {icon === "deleted" && <Trash size="l" />}
    {icon === "paused" && <Pause size="l" />}
    {icon === "resumed" && <Play size="l" />}
    {icon === "completed" && <Bolt size="l" />}
    {(icon === "message" || icon === "gmail") && <Envelope size="l" />}
    {(icon === "sms_in" || icon === "sms_out") && <Chat size="l" />}
    {icon === "call" && <Phone size="l" />}
    {icon === "appointment" && <Calendar size="l" />}
    {icon === "other" && <Chat size="l" />}
    {icon === "pop_by" && <Chat size="l" />}
    {icon === "mail" && <Chat size="l" />}
    {icon === "social_media" && <Chat size="l" />}
    {icon === "listing" && <Create size="l" />}
    {icon === "listing_import" && <ArrowDownToBracketSolidV6 size="l" />}
    {icon === "listing_status" && <Home size="l" />}
    {icon === "create" && <Create size="l" />}
    {icon === "trash" && <Trash size="l" />}
    {icon === "listing_person" && <User size="l" />}
    {icon === "listing_transaction_name" && <Create size="l" />}
    {icon === "date" && <Calendar size="l" />}
  </div>
);

EventIcon.propTypes = {
  icon: string,
};

EventIcon.defaultProps = {
  icon: null,
};

export default EventIcon;
