import namespaceAction from "../../reducers/helpers";

const REDUCER_NAME = "leadRoutingReducer";
const wrap = namespaceAction(REDUCER_NAME);

export const setModalData = (data) => wrap({ type: "SET_DATA", data });
export const showModal = (show) => wrap({ type: "SHOW_MODAL", show });
export const selectTab = (tab) => wrap({ type: "SET_TAB", tab });

export const selectAgents = (agents) => wrap({ type: "SELECT_AGENTS", agents });
export const selectPond = (pond) => wrap({ type: "SELECT_POND", pond });

export const reset = () => wrap({ type: "RESET" });

export const setSaving = () => wrap({ type: "SAVING" });
export const setSaved = () => wrap({ type: "SAVED" });
export const setSaveError = (error) => wrap({ type: "SAVE_ERROR", error });
