import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "../../shared/v2/Button";

const ScrollButton = () => {
  const [show, setShow] = useState(false);
  const [portal, setPortal] = useState(null);

  useEffect(() => {
    const div = document.createElement("div");
    document.body.appendChild(div);
    setPortal(div);
    return () => div.remove();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShow((document.scrollingElement?.scrollTop || 0) > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  if (!portal || !show) return null;

  return ReactDOM.createPortal(
    <Button
      className="!tw-fixed tw-bottom-[10px] tw-right-[10px] tw-w-[100px]"
      schema="secondary"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      Back to Top
    </Button>,
    portal,
  );
};

export default ScrollButton;
