/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const StarFilled = (props) => (
  <Icon {...props} name="Star Filled">
    <path d="M7.208 1.129 5.405 4.948l-4.033.614C.649 5.672.359 6.603.883 7.137l2.918 2.971-.69 4.197c-.124.759.64 1.327 1.281.972L8 13.295l3.608 1.982c.64.352 1.405-.213 1.281-.972l-.69-4.197 2.918-2.971c.525-.534.235-1.465-.489-1.575l-4.033-.614-1.803-3.819a.868.868 0 0 0-1.585 0z" />
  </Icon>
);

export default StarFilled;
