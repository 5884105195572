import React from "react";
import PropTypes from "prop-types";
import { ChevronLeftSolidV6 } from "@shared/v2/Icomoon";
import RadioButton from "@shared/v2/RadioButton";
import { format, isValid, parseISO } from "date-fns";

const formatDate = (date) => {
  const parsedDate = parseISO(date || "");
  if (isValid(parsedDate)) {
    return format(parsedDate, `MM/dd${parsedDate.getFullYear() < 1 ? "" : "/yyyy"}`);
  }
  return date;
};

const ContactDetail = ({ inherit, value, onClick, selected }) => {
  if (onClick) {
    return (
      <RadioButton
        label={value}
        value={value}
        checked={selected}
        labelClassName="tw-w-full tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
        containerClassName={`tw-w-[218px] tw-p-[8px] tw-rounded-[8px] ${selected ? "tw-bg-gray-5" : ""}`}
        onChange={onClick}
      />
    );
  }
  return (
    <span
      className={`chtw-flex-1 tw-w-[218px] tw-py-[8px] tw-pl-[32px] tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis ${inherit ? "tw-text-gray-30" : "tw-text-neutral-gray-75"}`}
      title={value}
    >
      {value}
    </span>
  );
};

ContactDetail.propTypes = {
  inherit: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

ContactDetail.defaultProps = {
  onClick: null,
  selected: false,
};

const ContactsDetailSelector = ({
  isDate,
  label,
  firstValue,
  secondValue,
  selectedValue,
  onSelect,
  showInherit,
  containerClassName,
}) => {
  const sanitizedFirstValue = (firstValue ?? "").trim();
  const sanitizedSecondValue = (secondValue ?? "").trim();
  const sanitizedSelectedValue = (selectedValue ?? "").trim();
  const firstSelected = sanitizedSelectedValue === sanitizedFirstValue;
  const showArrow =
    showInherit &&
    ((firstSelected && sanitizedSecondValue && !sanitizedFirstValue) ||
      (!firstSelected && sanitizedFirstValue && !sanitizedSecondValue));
  const showRadio =
    sanitizedFirstValue && sanitizedSecondValue && sanitizedFirstValue !== sanitizedSecondValue;

  if (!sanitizedFirstValue && !sanitizedSecondValue) return null;

  const finalFirstValue =
    firstSelected && showInherit ? sanitizedFirstValue || sanitizedSecondValue : sanitizedFirstValue || "—";
  const finalSecondValue =
    firstSelected || !showInherit ? sanitizedSecondValue || "—" : sanitizedSecondValue || sanitizedFirstValue;

  return (
    <div
      className={`tw-flex tw-justify-between tw-items-center tw-gap-[12px] tw-overflow-hidden ${containerClassName}`}
    >
      <span className="tw-flex-1 tw-font-semibold">{label}</span>
      <div className="tw-flex tw-items-center tw-gap-[10px]">
        <ContactDetail
          value={isDate ? formatDate(finalFirstValue) : finalFirstValue}
          inherit={showInherit && firstSelected && !sanitizedFirstValue}
          onClick={showRadio ? () => onSelect(firstValue) : undefined}
          selected={firstSelected}
        />
        {showArrow ? (
          <ChevronLeftSolidV6
            className={`tw-fill-gray-30 tw-transition-transform ${firstSelected ? "" : "tw-rotate-180"}`}
          />
        ) : (
          <span className="tw-w-[12px]" />
        )}
        <ContactDetail
          value={isDate ? formatDate(finalSecondValue) : finalSecondValue}
          inherit={showInherit && !firstSelected && !sanitizedSecondValue}
          onClick={showRadio ? () => onSelect(secondValue) : undefined}
          selected={!firstSelected}
        />
      </div>
    </div>
  );
};

ContactsDetailSelector.propTypes = {
  isDate: PropTypes.bool,
  label: PropTypes.string.isRequired,
  firstValue: PropTypes.string,
  secondValue: PropTypes.string,
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  showInherit: PropTypes.bool,
  containerClassName: PropTypes.string,
};

ContactsDetailSelector.defaultProps = {
  isDate: false,
  firstValue: "",
  secondValue: "",
  selectedValue: "",
  showInherit: true,
  containerClassName: "",
};

export default ContactsDetailSelector;
