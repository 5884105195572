import React from "react";
import PropTypes from "prop-types";

const HORIZONTAL_FORM_GROUP_STYLE_CLASSES =
  "before:tw-table before:tw-content-['_'] after:tw-table after:tw-content-['_'] after:tw-clear-both";

const HORIZONTAL_MARGIN_CLASSES = "tw--mx-10px";

export const FormGroup = ({ children, className, horizontal, deprecatedOverrideMarginClasses, ...props }) => {
  const classes = `
    tw-mb-15px
    ${deprecatedOverrideMarginClasses || (horizontal ? HORIZONTAL_MARGIN_CLASSES : "")}
    ${horizontal ? HORIZONTAL_FORM_GROUP_STYLE_CLASSES : ""}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  deprecatedOverrideMarginClasses: PropTypes.string,
};
FormGroup.defaultProps = {
  children: null,
  className: "",
  horizontal: false,
  deprecatedOverrideMarginClasses: "",
};

export default FormGroup;
