/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const GaugeHighSolidV6 = (props) => (
  <Icon {...props} name="Gauge High Solid V6">
    <path d="M16 8c0 4.419-3.581 8-8 8s-8-3.581-8-8c0-4.419 3.581-8 8-8s8 3.581 8 8zM8 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0 11a2.002 2.002 0 0 0 1.434-3.394l1.975-4.553a.72.72 0 0 0-.356-.991.754.754 0 0 0-.991.384L8.059 8.999c-.019 0-.041-.028-.059-.028-1.103 0-2 .925-2 2.028s.897 2 2 2zM4.5 3.5a1 1 0 1 0 0 2 1 1 0 1 0 0-2zM3 9a1 1 0 1 0 .002-1.998A1 1 0 0 0 3 9zm10-2a1 1 0 1 0 0 2 1 1 0 1 0 0-2z" />
  </Icon>
);

export default GaugeHighSolidV6;
