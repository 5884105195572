import Tooltip from "@shared/v2/Tooltip";
import React, { Component } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";

export default class PublicActivityOtherEvent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_modal: false,
    };
    this.interacted_at_epoch = this.props.interacted_at_epoch;
    this.event = this.props.event;

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show_modal: true });
  };

  hideModal = () => {
    this.setState({ show_modal: false });
  };

  headline = () => {
    const agent_person = this.event.agent_person;
    const from = `${agent_person.first_name} ${agent_person.last_name}`.trim();

    return (
      <div>
        <span className="bold-detail">{from}</span>
        {" created an Interaction"}
      </div>
    );
  };

  details = () => {
    const interactionType = this.event.interaction_type;
    const notes = this.event.object_attributes.comment;
    const scheduledAt = moment(this.event.object_attributes.interacted_at).format("LL");

    const interactionTypeDiv = <div className="row details-row">Type: {interactionType}</div>;

    const notesDiv = (
      <div className="tw-whitespace-pre-wrap tw-my-[-20px] row details-row">Notes: {notes}</div>
    );

    return (
      <div>
        <div className="row details-row">Scheduled at: {scheduledAt}</div>

        {!!interactionType && interactionTypeDiv}

        {!!notes && notesDiv}
      </div>
    );
  };

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-comments-o timeline-icon-circle" />
          </div>
          <div className="col-xs-8">
            {this.headline()}
            <div className="timeline-description-text">{this.details()}</div>
          </div>
          <div className="col-xs-3 time-ago">
            <Tooltip
              placement="left"
              content={this.event.interacted_at_relative_in_words}
              trigger={this.event.interacted_at_absolute}
            />
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    return (
      this.state.show_modal && (
        <Modal bsSize="sm" show={this.state.show_modal} onHide={this.hideModal} className="timeline-content">
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>Interaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.headline()}
              {this.details()}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        </Modal>
      )
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}
