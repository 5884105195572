import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styleSchema from "./DateSelector.styles";

import { TEST_DATE_STATUS } from "./utils/constants";

const dayStatus = (temporaryDate, navigationDate, day) => {
  if (day.isSame(temporaryDate, "day")) {
    return TEST_DATE_STATUS.TEMPORARY;
  }

  if (navigationDate.month() !== day.month()) {
    return TEST_DATE_STATUS.OUTSIDE_MONTH;
  }

  return TEST_DATE_STATUS.DEFAULT;
};

const dayModifierClassNames = (temporaryDate, navigationDate, day, disabled) => {
  if (disabled) return styleSchema.dayDisabled;

  if (day.isSame(temporaryDate, "day")) return styleSchema.daySelected;

  if (navigationDate.month() !== day.month()) return styleSchema.dayOutside;

  return styleSchema.dayDefault;
};

const isToday = (day) => day.isSame(moment(), "day");
const isTempDate = (day, temporaryDate) => day.isSame(temporaryDate, "day");

const sameDateStyle = (day, temporaryDate) =>
  isTempDate(day, temporaryDate) ? styleSchema.dayTempToday : styleSchema.dayToday;

const todayDOM = (day, temporaryDate) => (
  <span className={sameDateStyle(day, temporaryDate)}>{day.format("D")}</span>
);

const dayHTML = (day, temporaryDate) => (isToday(day) ? todayDOM(day, temporaryDate) : day.format("D"));

const CalendarDay = ({ temporaryDate, navigationDate, day, handleDayClick, disabled }) => (
  <td
    className="tw-w-32px tw-h-28px tw-text-center"
    onClick={disabled ? undefined : () => handleDayClick(day)}
    role="gridcell"
    tabIndex={0}
    onKeyPress={disabled ? undefined : () => handleDayClick(day)}
    aria-label={day.format("MMMM DD, YYYY")}
    data-test-date={day.format("YYYY/MM/DD")}
    data-test-date-status={dayStatus(temporaryDate, navigationDate, day)}
  >
    <div className={dayModifierClassNames(temporaryDate, navigationDate, day, disabled)}>
      {dayHTML(day, temporaryDate)}
    </div>
  </td>
);

CalendarDay.propTypes = {
  day: PropTypes.instanceOf(moment),
  temporaryDate: PropTypes.instanceOf(moment),
  navigationDate: PropTypes.instanceOf(moment),
  handleDayClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

CalendarDay.defaultProps = {
  day: moment(),
  temporaryDate: moment(),
  navigationDate: moment(),
  handleDayClick: () => {},
};

export default CalendarDay;
