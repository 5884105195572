import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../shared/v2/TextInput";

const TextInputWrap = ({ value, onChange, onBlur, onKeyPress }) => (
  <TextInput
    containerClassName="tw-w-full tw-flex-1"
    autoFocus
    value={value}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    onChange={(e) => onChange(e.target.value)}
  />
);
TextInputWrap.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};
export default TextInputWrap;
