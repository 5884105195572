import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@shared/v2/Tooltip/Tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";
import { CaretDownSolidV6 } from "@shared/v2/Icomoon";

const IconDropdown = ({ icon, tooltip, editable, children }) => {
  const popoverCaret = (
    <span className="tw-pl-[4px]">
      <CaretDownSolidV6 className={editable ? "" : "tw-opacity-60"} />
    </span>
  );

  return (
    <>
      <Tooltip placement="top" content={tooltip} trigger={icon} innerClassName="tw-max-w-[250px]" />
      {!editable && popoverCaret}
      {editable && (
        <Popover placement="bottom-start" offset={0}>
          <PopoverTrigger>{popoverCaret}</PopoverTrigger>
          <PopoverContent>{children}</PopoverContent>
        </Popover>
      )}
    </>
  );
};

IconDropdown.propTypes = {
  editable: PropTypes.bool,
  children: PropTypes.func,
  tooltip: PropTypes.string,
  icon: PropTypes.func,
};

IconDropdown.defaultValue = {
  editable: true,
  children: null,
  tooltip: null,
  icon: null,
};

export default IconDropdown;
