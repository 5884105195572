import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Close, Download } from "../../../../../shared/v2/Icomoon";
import IconButton from "../../../../../shared/v2/IconButton";
import { setDocumentIndex } from "../../actions/creators";
import { DocumentPropType } from "../../propTypes";

const downloadDocument = (fileName, url) => {
  const anchor = document.createElement("a");
  anchor.setAttribute("download", fileName);
  anchor.setAttribute("href", url);
  anchor.setAttribute("target", "_blank");
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const PreviewDetailsHeader = ({ document, dispatch }) => {
  const handleDownload = () => {
    downloadDocument(document.fileName, document.expiringUrl);
  };

  const handleClose = () => {
    dispatch(setDocumentIndex(null, null));
  };

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-px-32px tw-py-20px">
      <p className="tw-m-0 tw-text-18d tw-font-normal tw-text-neutral-gray-75">Details</p>

      <div className="tw-flex tw-gap-18px">
        <IconButton
          size="medium"
          schema="tertiary"
          onClick={handleDownload}
          data-cy="document-preview-download-button"
        >
          <Download size="l" />
        </IconButton>

        <IconButton
          size="medium"
          schema="tertiary"
          onClick={handleClose}
          data-cy="document-preview-close-button"
        >
          <Close size="l" />
        </IconButton>
      </div>
    </div>
  );
};

PreviewDetailsHeader.propTypes = {
  document: DocumentPropType.isRequired,
  dispatch: PropTypes.func,
};

PreviewDetailsHeader.defaultProps = {
  dispatch: () => {},
};

export default connect()(PreviewDetailsHeader);
