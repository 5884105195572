import React from "react";

import { NavContainer } from "../UI/Containers";
import NavigationListings from "../NavigationListings";

export default () => (
  <NavContainer>
    <NavigationListings />
  </NavContainer>
);
