import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "../../../../shared/v2/Button";
import { Upload as UploadIcon } from "../../../../shared/v2/Icomoon";
import { toggleUploadModal } from "../actions/creators";

const Upload = ({ dispatch }) => (
  <Button
    className="tw-flex tw-items-center tw-gap-4px tw-px-20px tw-uppercase"
    size="medium"
    onClick={() => dispatch(toggleUploadModal(true))}
    data-cy="documents-upload-button"
  >
    <span>Upload</span>
    <UploadIcon size="l" />
  </Button>
);

Upload.propTypes = {
  dispatch: PropTypes.func,
};

Upload.defaultProps = {
  dispatch: () => {},
};

export default connect()(Upload);
