import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Row, Col, Modal } from "react-bootstrap";
import CallOutcomeAndNotes from "./CallOutcomeAndNotes";
import moment from "moment";
import api from "@shared/phoneDncStatusApi";
import { DncFlag } from "@shared/Sms";
import { tryCatchHandlr } from "@shared/Utilities";
import { ArrowUpRightFromSquareSolidV6, PhoneHangupSolidV6 } from "@shared/v2/Icomoon";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import IntentDetails from "../../LeadIndex/components/IntentDetails";
import Button from "@shared/v2/Button";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import FieldLabel from "@shared/v2/FieldLabel";
import { usePlaceSelector } from "../../reducers/layoutReducer";
import UpNext from "./UpNext";
import StatusDropdown from "@shared/StatusDropdown/StatusDropdown";
import { produce } from "immer";
import StagesDropDown from "@shared/StagesDropDown";
import Toggle from "@shared/v2/Toggle";
import AddTaskPane from "@shared/AddTaskPane";
import { createTask } from "../../Tasks/components/services";

const nameFormat = (name) => (name.trim().length > 0 ? name : "No Name");

const ConferenceCallForm = ({
  dialerServiceUrl,
  dialerServiceToken,
  closeCallModal,
  startCall,
  setHideHeader,
}) => {
  const [conferenceCall, setConferenceCall] = useState(null);
  const [loadingConferenceCall, setLoadingConferenceCall] = useState(true);
  const [websocketsConnected, setWebsocketsConnected] = useState(false);
  const [possibleStaleState, setPossibleStaleState] = useState(false);
  const [currentCallTime, setCurrentCallTime] = useState("0:00:00");
  const [callOutcome, setCallOutcome] = useState({ outcome: null, comment: "" });
  const [endSessionLoading, setEndSessionLoading] = useState(false);
  const [endCallLoading, setEndCallLoading] = useState(false);
  const [endCallErrors, setEndCallErrors] = useState([]);
  const [savingCallOutcome, setSavingCallOutcome] = useState(false);
  const [savingOutcomeErrors, setSavingOutcomeErrors] = useState([]);
  const [savingOutcomeSuccess, setSavingOutcomeSuccess] = useState(null);
  const [savingLeadError, setSavingLeadError] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [dncStatuses, setDncStatuses] = useState({});
  const [dncNextStatuses, setDncNextStatuses] = useState({});
  const [person, setPerson] = useState({});
  const [isPersonLoading, setPersonLoading] = useState(true);
  const [isPersonStatusLoading, setPersonStatusLoading] = useState(false);
  const [taskToggle, setTaskToggle] = useState(false);
  const [agents, setAgents] = useState([]);
  const [task, setTask] = useState(null);

  const isPlace = usePlaceSelector();
  const currentUser = useCurrentUser();

  let timer;

  useEffect(() => {
    const manageSubscriptionAndTimer = () => {
      if (typeof App !== "undefined" && App.phone_call) {
        App.phone_call.unsubscribe();
      }
      stopTimer();
    };

    if (startCall) {
      startConference();
      setupSubscription();
      startTimer();
    } else {
      manageSubscriptionAndTimer();
    }

    return () => {
      manageSubscriptionAndTimer();
      setHideHeader(false);
      setLoadingConferenceCall(true);
    };
  }, [startCall]);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (taskToggle) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [taskToggle]);

  useEffect(() => {
    (async () => {
      const [res] = await tryCatchHandlr(
        axios.get("/account_active_agents", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }),
      );

      if (res) {
        setAgents(
          res.data.map((agent) => ({
            label: agent.name,
            value: agent.uuid,
            email: agent.email,
          })),
        );
      }
    })();
  }, []);

  useEffect(() => {
    const previousId = conferenceCall?.current_participant?.id;
    const currentId = conferenceCall?.current_participant?.id;

    if (previousId !== currentId) {
      getPhoneStatuses();
      getPhoneNextStatuses();
    }
  }, [conferenceCall]);

  const currentPhoneNumber = () => conferenceCall?.current_participant?.phone_number;

  const getPhoneStatuses = () => {
    api.index([currentPhoneNumber()]).then((x) => {
      const mergedState = {
        ...dncStatuses,
        ...x.data,
      };
      setDncStatuses(mergedState);
    });
  };

  const getPhoneNextStatuses = () => {
    api.index([conferenceCall?.next_participant?.phone_number]).then((x) => {
      const mergedState = {
        ...dncNextStatuses,
        ...x.data,
      };
      setDncNextStatuses(mergedState);
    });
  };

  const isDnc = () => api.isDnc(currentPhoneNumber(), dncStatuses);
  const isNextDnc = () => api.isDnc(conferenceCall?.next_participant?.phone_number, dncStatuses);

  const conferenceCallRef = useRef(conferenceCall);
  conferenceCallRef.current = conferenceCall;

  const startTimer = () => {
    timer = setInterval(() => updateCallTime(), 1000);
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const maybeLeadingZero = (num) => ("0" + num).slice(-2);

  const updateCallTime = () => {
    if (
      conferenceCallRef?.current &&
      conferenceCallRef?.current?.current_participant &&
      conferenceCallRef?.current?.current_participant.humanized_call_status === "In-progress" &&
      conferenceCallRef?.current?.current_participant.call_answered_timestamp
    ) {
      let seconds = Math.ceil(
        moment
          .duration(
            moment().diff(moment(conferenceCallRef?.current?.current_participant.call_answered_timestamp)),
          )
          .asSeconds(),
      );
      let hours = parseInt(seconds / 60 / 60);
      let minutes = parseInt((seconds / 60) % 60);
      let secs = seconds % 60;
      let callTimeString = `${hours}:${maybeLeadingZero(minutes)}:${maybeLeadingZero(secs)}`;
      setCurrentCallTime(callTimeString);
    }
  };

  const setupSubscription = () => {
    if (typeof App !== "undefined") {
      App.dialer_cable = ActionCable.createConsumer(`${dialerServiceUrl}/cable?token=${dialerServiceToken}`);
      App.phone_call = App.dialer_cable.subscriptions.create("ConferenceChannel", {
        connected: function () {
          this.followCurrentCall();
          websocketConnected();
        },
        received: function (data) {
          setConferenceCall(data.conference);
        },
        disconnected: function () {
          websocketDisconnected();
        },
        rejected: function () {
          websocketDisconnected();
        },
        failed: function () {
          websocketDisconnected();
        },
        followCurrentCall: function () {
          this.perform("follow");
        },
      });

      const websocketConnected = () => {
        setWebsocketsConnected(true);
        if (possibleStaleState) {
          fetchCurrentConferenceState();
        }
      };

      const websocketDisconnected = () => {
        setWebsocketsConnected(false);
        setPossibleStaleState(true);
      };
    }
  };

  const getPerson = async (uuid) => {
    const abortController = new AbortController();

    const [res, err] = await tryCatchHandlr(
      axios.get(`/api/v4/person/${uuid}/dialer_details`, { signal: abortController.signal }),
    );

    if (err) {
      return console.error(err);
    }

    setPerson({
      ...res.data.person,
      leadId: res.data.lead_id,
    });
    setPersonLoading(false);
  };

  App.leadUpdateChannel.received = (data) => {
    if (isPersonStatusLoading) {
      // for status
      if (data['status'] === 'success') {
        setPerson(
            produce(person, (draft) => {
              draft.data.attributes.status = updatedStatus;
            }),
        );
        setSavingLeadError(null);
      } else {
        setSavingLeadError(data["message"]);
      }
      setPersonStatusLoading(false);
    } else {
      // for everything other than status
      if (data['status'] === 'failed') setSavingLeadError(data["message"]);
    }
  }
  const setStatus = async (newStatus) => {
    setPersonStatusLoading(true);
    setSavingLeadError(null);
    setUpdatedStatus(newStatus);

    window.App.leadUpdateChannel.perform("lead_update", {
      id: person.leadId,
      person_attributes: {
        status: newStatus,
      },
    });
  };

  const startConference = () => {
    axios
      .post(`${dialerServiceUrl}/dialer/start_dialer_session`, {
        token: dialerServiceToken,
      })
      .then((resp) => {
        getPerson(resp.data.current_participant.person_uuid);
        setConferenceCall(resp.data);
        setLoadingConferenceCall(false);
      });
  };

  const hangUpCall = () => {
    setEndCallLoading(true);
    axios
      .post(`${dialerServiceUrl}/dialer/end_current_call`, {
        token: dialerServiceToken,
      })
      .then((resp) => {
        setConferenceCall(resp.data);
        setEndCallLoading(false);
        setEndCallErrors([]);
        setLoadingConferenceCall(false);
      })
      .catch(() => {
        setEndCallLoading(false);
        setEndCallErrors(["There was an error ending the current call, please try again."]);
      });
  };

  const fetchCurrentConferenceState = () => {
    axios.get(`${dialerServiceUrl}/dialer/fetch_dialer_state?token=${dialerServiceToken}`).then((resp) => {
      setConferenceCall(resp.data);
    });
  };

  const dialNextLead = () => {
    axios.post(`${dialerServiceUrl}/dialer/dial_next_lead`, { token: dialerServiceToken }).then((resp) => {
      getPerson(resp.data.current_participant.person_uuid);
      setConferenceCall(resp.data);
      setSavingOutcomeSuccess(null);
    });
  };

  const handleOutcomeInputChange = (key, value) => {
    setCallOutcome({ ...callOutcome, [key]: value });
  };

  const endDialerSession = () => {
    setEndSessionLoading(true);
    let callOutcomePromise = Promise.resolve();
    if (callOutcome.outcome) callOutcomePromise = submitOutcome(true);
    callOutcomePromise.finally(() => {
      axios
        .post(`${dialerServiceUrl}/dialer/end_dialer_session`, {
          token: dialerServiceToken,
        })
        .then((resp) => {
          setConferenceCall(resp.data);
          setEndSessionLoading(false);
          closeCallModal();
        })
        .then(() => {
          window.location.reload();
        });
    });
  };

  const submitOutcome = async (pause = false) => {
    setSavingCallOutcome(true);

    if (task) {
      await createTask({
        formData: {
          ...task,
          account_id: currentUser.account.uuid,
          taskable_id: conferenceCall?.current_participant?.person_uuid,
          taskable_name:
            conferenceCall?.current_participant?.person_full_name.trim().length > 0
              ? conferenceCall?.current_participant?.person_full_name
              : "No Name",
          taskable_type: "Person",
          user_id: currentUser.uuid,
        },
      });
      setTaskToggle(false);
    }

    return axios
      .post(`${dialerServiceUrl}/dialer/save_outcome`, {
        token: dialerServiceToken,
        participant_id: conferenceCall.current_participant.id,
        ...callOutcome,
      })
      .then((resp) => {
        setConferenceCall(resp.data);
        setCurrentCallTime(null);
        setCallOutcome({ outcome: null, comment: "" });
        setSavingOutcomeErrors([]);
        setSavingCallOutcome(false);
        setSavingOutcomeSuccess(true);
        if (pause) return;
        if (
          conferenceCall.current_participant_position < conferenceCall.total_participants &&
          conferenceCall.conference_status !== "conference-end"
        ) {
          dialNextLead();
        } else if (conferenceCall.conference_status !== "conference-end") {
          endDialerSession();
        }
      })
      .catch(() => {
        setSavingCallOutcome(false);
        setSavingOutcomeErrors(["There was an error saving the call outcome. Please try again."]);
      });
  };

  const websocketError = () =>
    conferenceCall && conferenceCall.conference_status !== "conference-end" && !websocketsConnected;

  const renderWebsocketError = () => (
    <Col xs={12}>
      <div className="alert alert-danger">
        <div className="text-center">
          <i className="fa fa-exclamation-triangle tw-text-30px" />
          <br />
          <span className="tw-text-20px tw-font-bold">Connection Unstable</span>
          <br />
          <span>Your browser has lost connection with Brivity Dialer.</span>
          <br />
          <span>Please wait while we attempt to reconnect...</span>
          <br />
          <i className="fa fa-spinner fa-pulse" />
        </div>
      </div>
    </Col>
  );

  const renderLeadError = () => (
      <Col xs={12} className="tw-mt-8">
        <div className="alert alert-danger">
          <div className="text-center">
            <span>{savingLeadError}</span>
          </div>
        </div>
      </Col>
  );

  const waitingForAgentParticipant = () =>
    websocketsConnected &&
    conferenceCall &&
    conferenceCall.current_participant_position === 0 &&
    conferenceCall.conference_status !== "conference-end";

  const renderInitializingDialerSession = () => (
    <div className="alert alert-info">
      <div className="text-center">
        <i className="fa fa-spinner fa-pulse tw-text-30px" />
        <br />
        <span className="tw-text-20px tw-font-bold">Initializing Dialer</span>
        <br />
        <span>Setting up your dialer session...</span>
      </div>
    </div>
  );

  const renderWaitingForAgent = () => (
    <div className="!tw-border-[#94DCDC] tw-border-solid tw-border-1px tw-bg-blue-10 tw-py-24px">
      <div className="text-center tw-text-brand-success">
        <span className="tw-font-bold tw-text-18d">Connecting to Agent</span>
        <br />
        <br />
        <span className="tw-text-14d">Brivity Dialer is calling your routing number.</span>
        <br />
        <span className="tw-text-14d">Answer the call on your phone to begin the dialer session.</span>
      </div>
    </div>
  );

  const conferenceCallIsComplete = () =>
    conferenceCall && conferenceCall.conference_status === "conference-end" && savingOutcomeSuccess;

  const renderConferenceComplete = () => (
    <Col xs={12}>
      <div className="alert alert-info">
        <div className="text-center">
          <i className="fa fa-phone tw-text-30px" />
          <br />
          <span className="tw-text-20px tw-font-bold">Dialer Session Complete</span>
        </div>
      </div>
    </Col>
  );

  const endCallBtnDisabled = () => {
    const callStatus = conferenceCall?.current_participant?.humanized_call_status;
    const conferenceStatus = conferenceCall?.conference_status;

    return (
      endCallLoading ||
      conferenceStatus === "conference-end" ||
      !["Queued", "Initiated", "Ringing", "In-progress"].includes(callStatus)
    );
  };

  const saveContinueBtnDisabled = () => {
    const callComplete = ["Completed", "Failed", "Busy", "No-answer", "Canceled"].includes(
      conferenceCall?.current_participant?.humanized_call_status,
    );
    return !callComplete || savingCallOutcome || callOutcome.outcome === null;
  };

  const saveBtnText = () => {
    if (!conferenceCall && conferenceCallIsComplete()) return "Save & Complete";

    if (
      conferenceCall?.current_participant_position < conferenceCall?.total_participants &&
      conferenceCall?.conference_status !== "conference-end"
    ) {
      return "Save & Continue";
    } else {
      return "Save & Complete";
    }
  };

  const conferenceIsReady = () =>
    websocketsConnected &&
    conferenceCall &&
    conferenceCall.current_participant &&
    !conferenceCallIsComplete();

  const renderCallStatus = () => (
    <div className="phone-call">
      <div>
        <div className="tw-flex tw-justify-between">
          <div className="form-group contact-card">
            <div className="tw-flex tw-items-center">
              {person?.data?.attributes?.lead_type !== "n/a" && (
                <div className="tw-mr-8px">
                  <IntentDetails intent={person?.data?.attributes?.lead_type} />
                </div>
              )}
              <a
                href={conferenceCall?.current_participant?.person_url}
                target="_blank"
                className={`tw-text-18d tw-font-bold ${isPlace ? "!tw-text-[#121212]" : "!tw-text-teal"}`}
              >
                <span className="tw-mr-6px">
                  {nameFormat(conferenceCall?.current_participant?.person_full_name)}
                </span>
                <ArrowUpRightFromSquareSolidV6 className={isPlace ? "!tw-text-[#3270FA]" : ""} size="l" />
              </a>
            </div>
            <div className="tw-text-14d tw-font-semibold tw-text-[#999999] overflow tw-flex tw-items-center tw-space-x-[4px] tw-mt-8px">
              <span>Mobile: {conferenceCall.current_participant.phone_number}</span>
              <DncFlag show={isDnc()} />
            </div>
          </div>
          <div className="tw-w-[208px]">
            <div className="tw-text-12d tw-text-gray-50 tw-mb-5px">
              Call {conferenceCall.current_participant_position} of {conferenceCall.total_participants}
            </div>
            <div className="tw-w-full tw-h-8px tw-rounded-[16px] tw-bg-[#000000] tw-bg-opacity-20 tw-overflow-hidden">
              <div
                role="progressbar"
                className={`progress-bar call-status active-call ${isPlace ? "tw-bg-[#121212]" : "tw-bg-teal"}`}
                aria-valuenow={1}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width: `${((conferenceCall.current_participant_position / conferenceCall.total_participants) * 100).toFixed()}%`,
                }}
              />
            </div>
          </div>
        </div>
        <div className="tw-flex">
          <div className="tw-py-10px tw-px-16px tw-border-[1px] tw-border-solid tw-border-gray-30 tw-flex tw-items-center tw-text-gray-75 tw-bg-gray-5 tw-text-12d tw-font-semibold tw-rounded-lg tw-w-[448px] tw-mr-auto">
            <div
              className={`${conferenceCall.current_participant.humanized_call_status === "Completed" ? "tw-bg-neutral-gray-50" : "tw-bg-semantic-green-110"} tw-w-8px tw-h-8px tw-rounded-full tw-mr-4px`}
            />
            <span>
              {conferenceCall.current_participant.humanized_call_status
                ? conferenceCall.current_participant.humanized_call_status
                : `Calling you at your phone ${conferenceCall?.agent_participant?.phone_number}`}
            </span>
            <span className="tw-font-normal tw-ml-auto">{currentCallTime}</span>
          </div>
          <Button disabled={endCallBtnDisabled()} onClick={hangUpCall} size="medium" schema="solid-red">
            <PhoneHangupSolidV6 size="m" className="tw-mr-4px" />
            <span>End Call</span>
          </Button>
        </div>
        <hr className="tw-w-full tw-border-gray-10 tw-m-0 tw-mt-24px tw-mb-16px" />
      </div>
      <div className="tw-h-[365px] tw-overflow-y-auto" ref={scrollRef}>
        {[...savingOutcomeErrors, ...endCallErrors].map((e, i) => {
          return (
            <Row key={i}>
              <Col xs={12}>
                <div className="alert alert-danger">{e}</div>
              </Col>
            </Row>
          );
        })}
        <div className="tw-flex tw-gap-24px tw-mb-24px">
          <div className="tw-w-[140px]">
            <FieldLabel className="tw-mb-8px" label="Status" />
            <StatusDropdown
              person={{}}
              status={person?.data?.attributes?.status}
              statusOptions={person?.meta?.status_options}
              btnClassName="tw-m-0 tw-w-full"
              onChange={setStatus}
              isLoading={isPersonLoading || isPersonStatusLoading}
            />
          </div>
          <div className="tw-w-[163px]">
            <FieldLabel className="tw-mb-8px" label="Stage" />
            <StagesDropDown
              selectedStage={person?.data?.attributes?.stage}
              isLoading={isPersonLoading}
              onUpdateStage={(stage) =>
                window.App.leadUpdateChannel.perform("lead_update", {
                  id: person.leadId,
                  person_attributes: {
                    stage: stage.value,
                    stage_type: stage.type,
                  },
                })
              }
              containerClassName="!tw-border-[1.5px] !tw-border-solid !tw-border-gray-50 hover:!tw-border-gray-50 tw-w-full tw-justify-center"
            />
          </div>
        </div>
        <CallOutcomeAndNotes
          currentParticipantId={conferenceCall?.current_participant?.person_uuid}
          callOutcome={callOutcome}
          onFieldChange={handleOutcomeInputChange}
        />
        <Toggle
          text="Add Follow Up Task"
          labelPlacement="end"
          labelClassName="tw-mb-8px tw-mt-24px"
          onChange={() => {
            setTaskToggle(!taskToggle);
          }}
          checked={taskToggle}
        />
        {taskToggle && <AddTaskPane agents={agents} initialUserUuid={currentUser.uuid} setState={setTask} />}
      </div>
    </div>
  );

  return (
    <div>
      {loadingConferenceCall && renderInitializingDialerSession()}
      {waitingForAgentParticipant() && renderWaitingForAgent()}
      {(() => {
        if (conferenceIsReady()) {
          setHideHeader(true);
          return renderCallStatus();
        }
      })()}
      {conferenceCallIsComplete() && renderConferenceComplete()}
      {websocketError() && renderWebsocketError()}
      {savingLeadError && renderLeadError()}
      <div className="">
        <hr className="tw-w-full tw-border-gray-10 tw-m-0 tw-mt-16px tw-mb-16px" />
        {conferenceIsReady() && conferenceCall?.next_participant && (
          <UpNext
            dnc={isNextDnc()}
            pdpLink={conferenceCall?.next_participant?.person_url}
            phoneNumber={formatPhoneNumber(conferenceCall?.next_participant?.phone_number)}
            name={nameFormat(conferenceCall?.next_participant?.person_full_name)}
          />
        )}
        <Modal.Footer style={{ padding: 0 }}>
          <div className="tw-flex tw-justify-between">
            <Button schema="tertiary" onClick={endDialerSession} isLoading={endSessionLoading} size="medium">
              End Session
            </Button>
            <Button
              disabled={saveContinueBtnDisabled()}
              onClick={() => submitOutcome()}
              isLoading={savingCallOutcome}
              size="medium"
            >
              {saveBtnText()}
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default ConferenceCallForm;
