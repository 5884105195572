export const CONTINGENT_EVENTS = [
  { label: "Close Date", value: "milestone-close_date" },
  { label: "Date Listed", value: "milestone-date_listed" },
  { label: "Escrow Signing Date", value: "milestone-escrow_signing_date" },
  { label: "Expiration Date", value: "milestone-expiration_date" },
  { label: "Home Inspection Date", value: "milestone-home_inspection_date" },
  { label: "Mutual Acceptance Date", value: "milestone-mutual_acceptance_date" },
  { label: "Appraisal Date", value: "milestone-appraisal_date" },
  { label: "Canceled Date", value: "milestone-canceled_date" },
  { label: "Termite Inspection", value: "milestone-termite_inspection" },
];

export const POSITION = [
  { label: "After", value: "after" },
  { label: "Before", value: "before" },
];
