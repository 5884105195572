/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VolumeSlashSolidV6 = (props) => (
  <Icon {...props} name="Volume Slash Solid V6">
    <path d="m15.77 13.327-2.219-1.74A4.768 4.768 0 0 0 15.2 7.999a4.702 4.702 0 0 0-1.772-3.678.6.6 0 0 0-.76.929c.848.672 1.333 1.682 1.333 2.749s-.486 2.077-1.333 2.773c-.026.021-.04.051-.062.075l-1.108-.868c.013.001.024.008.037.008a.603.603 0 0 0 .38-.135A2.39 2.39 0 0 0 12.801 8c0-.716-.323-1.391-.886-1.851a.6.6 0 0 0-.76.929c.283.232.445.567.445.922s-.162.691-.445.922a.6.6 0 0 0-.196.635L9.6 8.493V3.2c0-.315-.183-.6-.471-.729s-.625-.078-.861.131L5.372 5.177.97 1.727a.597.597 0 0 0-.842.102.599.599 0 0 0 .102.842l14.777 11.578a.6.6 0 0 0 .842-.102c.228-.238.181-.615-.079-.82zM1.6 6.8v2.4c0 .663.537 1.178 1.2 1.178h2.096l3.373 2.997a.725.725 0 0 0 .531.225.795.795 0 0 0 .8-.8v-1.258L2.219 5.758c-.367.205-.619.57-.619 1.043z" />
  </Icon>
);

export default VolumeSlashSolidV6;
