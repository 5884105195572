import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Search as SearchIcon } from "../../../../shared/v2/Icomoon";
import TextComboInput from "../../../../shared/v2/TextComboInput";
import { searchDocumentsError } from "../actions/creators";
import { searchDocumentsAsThunk } from "../actions/thunks";

const Search = ({ statefulSearch, dispatch }) => {
  const [searchTerm, setSearchTerm] = useState(statefulSearch);
  const [hasTyped, setHasTyped] = useState(false);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setHasTyped(true);
  };

  const submitSearch = useCallback(() => {
    const searchTermTrimmed = searchTerm.trim();

    if (searchTermTrimmed === statefulSearch) {
      // We already searched for this term, so no need to search again
      // (happens when typing a space and waiting 1 second)
      return;
    }

    if (searchTermTrimmed && searchTermTrimmed.length < 3) {
      dispatch(searchDocumentsError(searchTerm, ["Search term must have 3 characters or more."]));
      return;
    }

    dispatch(searchDocumentsAsThunk(searchTermTrimmed));
  }, [statefulSearch, searchTerm]);

  useEffect(() => {
    if (hasTyped) {
      // Trigger search while user types, with a max of 1 search per second
      const timeoutId = window.setTimeout(submitSearch, 1000);
      return () => window.clearTimeout(timeoutId);
    }

    return () => {};
  }, [hasTyped, submitSearch]);

  return (
    <div className="tw-grow" data-cy="documents-search">
      <TextComboInput
        className="tw-w-full"
        placeholder="Search by document name"
        value={searchTerm}
        trailing={
          <div className="tw-flex tw-items-center">
            <SearchIcon className="tw-text-neutral-gray-30" size="l" />
          </div>
        }
        onChange={handleChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  statefulSearch: state.tdpDocumentsReducer.search,
});

Search.propTypes = {
  statefulSearch: PropTypes.string,
  dispatch: PropTypes.func,
};

Search.defaultProps = {
  statefulSearch: "",
  dispatch: () => {},
};

export default connect(mapStateToProps)(Search);
