import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { autoPlanNameLabel } from "../helpers";
import { FloatingForm } from "../../../shared/v2/Modal";
import Button from "../../../shared/v2/Button";
import RadioButton from "../../../shared/v2/RadioButton/RadioButton";

const DeleteAutoPlanModal = ({
  isAutoPlanDeleteModalOpen,
  deleteAutoPlan,
  closeAutoPlanDeleteModal,
  isDeletingAutoPlan,
  activeAutoPlanId,
  autoPlansRootPath,
  autoPlans,
  supportPhoneNumber,
}) => {
  const [deleteNow, setDeleteNow] = useState(false);
  const deletePath = `${autoPlansRootPath}/${activeAutoPlanId}`;
  const autoPlanName = autoPlanNameLabel(activeAutoPlanId, autoPlans);

  useEffect(() => {
    if (!isAutoPlanDeleteModalOpen) setDeleteNow(false);
  }, [isAutoPlanDeleteModalOpen]);

  return (
    <FloatingForm
      show={isAutoPlanDeleteModalOpen}
      onCancel={closeAutoPlanDeleteModal}
      title="Remove Auto Plan"
      footer={
        <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
          <Button
            size="medium"
            schema="secondary"
            onClick={() => closeAutoPlanDeleteModal()}
            disabled={isDeletingAutoPlan}
          >
            Cancel
          </Button>
          {deleteNow ? (
            <Button
              size="medium"
              schema="warning"
              onClick={() => deleteAutoPlan(deletePath)}
              isLoading={isDeletingAutoPlan}
            >
              Delete
            </Button>
          ) : (
            <div>
              <span className="tw-text-neutral-gray-50">Contact support at {supportPhoneNumber}</span>{" "}
            </div>
          )}
        </div>
      }
    >
      <p className="tw-mb-[24px]">How would you like to remove this Auto Plan?</p>
      <p className="tw-mb-[24px]">
        Auto Plan: <b>{autoPlanName}</b>
      </p>
      <div className="tw-flex tw-flex-col tw-gap-[24px]">
        <div className="tw-flex tw-items-start">
          <RadioButton
            name="deleteNow"
            style={{ alignSelf: "start", flex: "0 0 auto" }}
            value={false}
            checked={!deleteNow}
            onChange={() => setDeleteNow(false)}
            disabled={isDeletingAutoPlan}
            label={
              <>
                <b>Remove this Auto Plan from all contacts and then delete the template</b>
                <div className="tw-text-neutral-gray-50">
                  Support will need to help you remove this Auto Plan from your contacts. You can contact
                  support via chat, or by calling {supportPhoneNumber}. All triggers associated with this Auto
                  Plan will also be deleted.
                </div>
              </>
            }
          />
        </div>
        <div className="tw-flex tw-items-start">
          <RadioButton
            name="deleteNow"
            style={{ alignSelf: "start", flex: "0 0 auto" }}
            value
            checked={deleteNow}
            onChange={() => setDeleteNow(true)}
            disabled={isDeletingAutoPlan}
            label={
              <>
                <b>Delete this Auto Plan template without removing it from contacts</b>
                <div className="tw-text-neutral-gray-50">
                  Warning: You will <b>not</b> be able to remove this Auto Plan from contacts after it has
                  been deleted.
                </div>
              </>
            }
          />
        </div>
      </div>
    </FloatingForm>
  );
};

export default DeleteAutoPlanModal;

DeleteAutoPlanModal.propTypes = {
  isAutoPlanDeleteModalOpen: PropTypes.bool.isRequired,
  deleteAutoPlan: PropTypes.func.isRequired,
  closeAutoPlanDeleteModal: PropTypes.func.isRequired,
  isDeletingAutoPlan: PropTypes.bool.isRequired,
  activeAutoPlanId: PropTypes.string.isRequired,
  autoPlansRootPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  autoPlans: PropTypes.objectOf(PropTypes.any).isRequired,
  supportPhoneNumber: PropTypes.string.isRequired,
};
