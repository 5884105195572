import React from "react";
import { bool, string } from "prop-types";
import { useSelector } from "react-redux";

import Popover from "../../Popover";
import PlaceResources from "./PlaceResources";
import AppsResources from "./AppsResources";
import { isPlace } from "./helpers";

const ResourcesDropdown = ({ ariaLabelledBy, open }) => {
  const theme = useSelector(({ layout }) => layout.theme.name);

  return (
    <Popover aria-labelledby={ariaLabelledBy} className="tw-w-[232px]" open={open}>
      {isPlace(theme) && <PlaceResources />}
      <AppsResources />
    </Popover>
  );
};

export default ResourcesDropdown;

ResourcesDropdown.propTypes = {
  ariaLabelledBy: string.isRequired,
  open: bool,
};

ResourcesDropdown.defaultProps = {
  open: false,
};
