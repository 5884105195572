import React from "react";

export default function Radio({ checked = false, disabled = false }) {
  return (
    <i
      className={`fa ${
        checked
          ? ` fa-dot-circle-o  ${disabled ? "tw-text-neutral-gray-30 " : "tw-text-theme-text-button-color"} `
          : `fa-circle-thin tw-text-neutral-gray-30 hover:tw-bg-neutral-gray-5 tw-rounded-full tw-overflow-hidden ${
              disabled ? "tw-text-neutral-gray-15 tw-bg-neutral-gray-5 " : ""
            } `
      }  `}
      aria-hidden="true"
    />
  );
}
