import { useEffect, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useOpenHouseLeads = ({ leadPersonUuids }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!leadPersonUuids?.length) {
      setData([]);
      return;
    }
    const abortController = new AbortController();
    setData([]);
    setLoading(true);
    caseTransformingAxios
      .post(
        "/api/v4/reporting/open_house_dashboard/leads",
        { leadPersonUuids },
        {
          signal: abortController.signal,
        },
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setLoading(false);
        setError(true);
      });
  }, [leadPersonUuids]);

  return { data, error, loading };
};

export default useOpenHouseLeads;
