import React, { Component } from 'react';
import alerts from '../listing_alerts/listing_alerts.module.css';
import TextInput from '@shared/v2/TextInput';

class AddCCEmails extends Component {
  state = {
    ccEmail: ''
  }

  handleChange = (e) => {
    this.setState({ ccEmail: e.target.value })
  }

  validEmail = (email) => /^[^@\s]+@[^@\s]+$/.test(email)

  displayEmail = () => {
    const { ccEmail } = this.state

    if (this.validEmail(ccEmail)) {
      return <span><i className="fa fa-envelope text-danger"/> {ccEmail}</span>
    }
    return `Invalid Email ${ccEmail}`
  }

  handleClick = () => {
    const { ccEmail } = this.state
    if (this.validEmail(ccEmail)) {
      this.props.addEmail(ccEmail)
      this.setState({ ccEmail: '' })
    }
  }

  render() {
    return (
      <div className="tw-flex tw-flex-col form-group tw-mb-10px">
        <label className="tw-text-14px tw-font-semibold tw-text-gray-50">CC</label>
        <TextInput
          onChange={this.handleChange}
          placeholder="Enter Email Address"
          value={this.state.ccEmail}
        />
        {this.state.ccEmail.length > 0 &&
          <div id={alerts.ccResult} onClick={this.handleClick}>
            <span>{this.displayEmail()}</span>
          </div>
        }
      </div>
    );
  }
}

export default AddCCEmails;
