import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { MONTHS } from "./utils/constants";

import styleSchema from "./DateSelector.styles";

const monthModifierClassNames = (month, temporaryDate, navigationDate, noYear) => {
  if (noYear) {
    return navigationDate.format("MMM") === month
      ? `${styleSchema.monthSelected}`
      : `${styleSchema.monthDefault}`;
  }

  if (navigationDate.clone().month(month).isSame(temporaryDate, "month")) {
    return `${styleSchema.monthSelected}`;
  }

  return `${styleSchema.monthDefault}`;
};

const arialLabelMonth = (month, navigationDate) =>
  `Go to ${month} ${navigationDate.clone().format("YYYY")} to select a day`;

const isDisabled = ({ month, navigationDate, minDate, maxDate }) => {
  const isBeforeMinDate =
    minDate && moment(navigationDate).set("month", month).endOf("month").isSameOrBefore(moment(minDate));
  const isAfterMaxDate =
    maxDate && moment(navigationDate).set("month", month).startOf("month").isSameOrAfter(moment(maxDate));
  return isBeforeMinDate || isAfterMaxDate;
};

const MonthSelector = ({ temporaryDate, navigationDate, handleMonthClick, minDate, maxDate, noYear }) => {
  const onMonthClick = (month) => {
    handleMonthClick(navigationDate.clone().month(month));
  };

  return (
    <div className="tw-flex tw-flex-col tw-flex-wrap tw-flex-auto tw-justify-center">
      <div className="tw-grid tw-grid-cols-4 tw-justify-items-center">
        {MONTHS.map((month) => {
          const disabled = isDisabled({ month, navigationDate, minDate, maxDate });
          return (
            <button
              key={month}
              tabIndex={0}
              type="button"
              onClick={() => onMonthClick(month)}
              className={`
                ${monthModifierClassNames(month, temporaryDate, navigationDate, noYear)} 
                ${disabled ? "!tw-text-semantic-red-10" : ""}                
              `}
              aria-label={arialLabelMonth(month, navigationDate)}
              data-test-date={month}
              disabled={disabled}
            >
              {month}
            </button>
          );
        })}
      </div>
    </div>
  );
};

MonthSelector.propTypes = {
  temporaryDate: PropTypes.instanceOf(moment),
  navigationDate: PropTypes.instanceOf(moment),
  handleMonthClick: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  noYear: PropTypes.bool,
};

MonthSelector.defaultProps = {
  temporaryDate: moment(),
  navigationDate: moment(),
  handleMonthClick: () => {},
  minDate: null,
  maxDate: null,
  noYear: false,
};

export default MonthSelector;
