/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MagnifyingGlassSolidV6 = (props) => (
  <Icon {...props} name="Magnifying Glass Solid V6">
    <path d="m15.634 13.866-3.741-3.741a6.5 6.5 0 0 0 1.046-4.522C12.556 2.743 10.212.416 7.35.054A6.505 6.505 0 0 0 .054 7.35c.362 2.864 2.69 5.209 5.55 5.591a6.495 6.495 0 0 0 4.522-1.046l3.741 3.741a1.25 1.25 0 1 0 1.768-1.77zM2.472 6.5c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4-4-1.794-4-4z" />
  </Icon>
);

export default MagnifyingGlassSolidV6;
