import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import Dropdown from "@shared/v2/Dropdown";
import * as creators from "../../actions/creators";

const mapStateToProps = (state) => ({
  personEmails: state.addMarketReport.person?.emails,
});

const ContactEmailInput = ({ personEmails, onChange, value, setFormError }) => {
  const options = personEmails?.map((e) => ({
    label: `${e.address} (${capitalize(e.category)})`,
    value: e.address,
  }));
  useEffect(() => {
    if (personEmails?.length > 0) {
      onChange(personEmails[0].address);
      setFormError({ contact_email: false });
    }
  }, []);
  const hasError = value == null;
  return (
    <div data-cy="email-input-wrapper" className="tw-mb-[24px]">
      <Dropdown
        label="Contact Email"
        error={hasError}
        name="contact_email"
        value={{ label: value, value }}
        options={options}
        isClearable={false}
        isSearchable
        placeholder="Select an email"
        onChange={(opt) => {
          if (!opt) return;
          onChange(opt.value);
          setFormError({ contact_email: false });
        }}
      />
    </div>
  );
};

ContactEmailInput.propTypes = {
  personEmails: PropTypes.arrayOf(PropTypes.shape({ address: PropTypes.string, position: PropTypes.int })),
  value: PropTypes.shape({ label: PropTypes.string }),
  onChange: PropTypes.func,
  setFormError: PropTypes.func,
};

ContactEmailInput.defaultProps = {
  personEmails: null,
  value: null,
  setFormError: () => {},
  onChange: () => {},
};

export default connect(mapStateToProps, creators)(ContactEmailInput);
