import React from "react";
import { bool, func, shape, string } from "prop-types";
import { postNoteAsThunk } from "../actions/thunks";
import Button from "../../../../shared/v2/Button";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

const FooterOptions = ({
  uuid,
  note,
  setNote,
  starred,
  externallyVisible,
  setExternallyVisible,
  onPostNoteAsThunk,
}) => {
  const clearNote = () => {
    setNote({
      ...note,
      content: "",
      starred: false,
      externally_visible: false,
    });
    setExternallyVisible(false);
  };

  const postNote = async () => {
    await onPostNoteAsThunk({ ...note, starred, externally_visible: externallyVisible }, uuid);
    clearNote();
  };

  return (
    <div className="tw-flex tw-justify-between tw-mt-[16px]">
      <Button size="medium" schema="tertiary" onClick={clearNote}>
        Clear
      </Button>
      <Button size="medium" onClick={postNote} disabled={!note.content.length}>
        Add Note
      </Button>
    </div>
  );
};

const withTdpDetails = tdpDetails({
  WrappedComponent: FooterOptions,
  states: LISTING_UUID,
});

export default withDispatch({
  WrappedComponent: withTdpDetails,
  actions: postNoteAsThunk,
  aliases: "onPostNoteAsThunk",
});

FooterOptions.propTypes = {
  uuid: string,
  note: shape({}),
  setNote: func.isRequired,
  starred: bool.isRequired,
  externallyVisible: bool.isRequired,
  setExternallyVisible: func.isRequired,
  onPostNoteAsThunk: func.isRequired,
};

FooterOptions.defaultProps = {
  uuid: "",
  note: {},
};
