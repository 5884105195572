import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { CalendarDaysSolidV6, ChevronDownSolidV6, ChevronUpSolidV6 } from "@shared/v2/Icomoon";
import { primaryDisabled, headline2 } from "@shared/styles";
import AddNewActions from "../AddNewActions";
import { useTimeline } from "../../../reducers/autoPlanEdit";
import { toggleEmailActionModal, toggleTaskActionModal, toggleTextActionModal } from "../../actions/autoPlansActionCreators";
import SpecificDatesActions from "./SpecificDatesActions";

const SpecificDatesAddNewActions = () => {
  const dispatch = useDispatch()
  
  return (
  <AddNewActions
    taskBtnOnClick={() => dispatch(toggleTaskActionModal(true))} 
    emailBtnOnClick={() => dispatch(toggleEmailActionModal(true))}
    textBtnOnClick={() => dispatch(toggleTextActionModal(true))}/>
)}

const SpecificDates = () => {
  const [expand, setExpand] = useState(false);
  const Chevron = expand ? ChevronUpSolidV6 : ChevronDownSolidV6;
  const timeline = useTimeline();

  const hasActions = timeline?.dates.length;

  return (
    <div className={`tw-border-[2px] tw-border-[#EBEBEB] tw-border-solid tw-rounded-[12px] tw-p-[24px] ${primaryDisabled}`}>
      <div
        onClick={() => setExpand(!expand)}
        onKeyDown={() => setExpand(!expand)} 
        role="button"
        tabIndex="0"
        className={`tw-flex tw-items-center ${hasActions ? 
        'tw-text-gray-base' : ''}`}>
        <CalendarDaysSolidV6
          size='xxl'
          className='tw-mr-[12px]'/>
        <span className={`${headline2} tw-mr-[12px]`}>Specific Dates ({hasActions})</span>
        <Chevron
          size='l'
          className='tw-cursor-pointer'/>
      </div>      
      {expand &&
        <>
          <SpecificDatesActions actions={timeline?.dates ? timeline.dates : []}/>
          <SpecificDatesAddNewActions/>
        </>}
    </div>
  )
};

export default SpecificDates;
