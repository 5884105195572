import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChevronLeft, ChevronRight } from "../../../../../shared/v2/Icomoon";
import IconButton from "../../../../../shared/v2/IconButton";
import { setDocumentIndex } from "../../actions/creators";
import { ContextPropType, DocumentPropType } from "../../propTypes";
import { baseZ, containerZ } from "../utils";

import PreviewPlaceholder from "./PreviewPlaceholder";
import PreviewPdf from "./PreviewPdf";
import PreviewImage from "./PreviewImage";

const PreviewNavigator = ({ context, document, index, numDocuments, dispatch }) => {
  const handleClose = () => {
    dispatch(setDocumentIndex(null, null));
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const goToPreviousDocument = () => {
    dispatch(setDocumentIndex(context, index - 1));
  };

  const goToNextDocument = () => {
    dispatch(setDocumentIndex(context, index + 1));
  };

  const preview = useMemo(() => {
    const type = document.fileType.toLowerCase();
    const url = document.expiringUrl;

    switch (type) {
      case "pdf":
        return <PreviewPdf url={url} />;

      case "jpg":
      case "jpeg":
      case "png":
        return <PreviewImage url={url} name={document.nakedName} />;

      default:
        return <PreviewPlaceholder>Cannot preview documents of type {document.fileType}.</PreviewPlaceholder>;
    }

    // Do not re-render document while it's being previewed
  }, [document.id]);

  return (
    <>
      <div
        className={`tw-fixed ${baseZ} tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-bg-black tw-opacity-50`}
      />

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        // We need to define a min width because of the PDF previewer
        // (it self defines an inline width on runtime based on the container width),
        // and because of smaller screens
        //
        // 836px = 612px from preview canvas + 32px for each of the previous/next buttons (64px)
        // + 40px spacing between each element (160px)
        className={`
          tw-relative ${containerZ}
          tw-flex tw-justify-between tw-items-center tw-flex-1
          tw-min-w-[836px]
          tw-px-40px
          tw-overflow-y-scroll
        `}
        onClick={handleClose}
        data-cy="document-preview-container"
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className="tw-relative tw-w-36px tw-h-36px" onClick={handleClick}>
          <IconButton
            className="tw-fixed tw-m-4px"
            size="medium"
            schema="tertiary"
            disabled={index === 0}
            onClick={goToPreviousDocument}
            data-cy="document-preview-previous-button"
          >
            <ChevronLeft size="m" />
          </IconButton>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          // 612px is defined in the Figma designs
          className="tw-self-start tw-w-[612px] tw-my-56px"
          onClick={handleClick}
          data-cy="document-preview"
        >
          {preview}
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className="tw-relative tw-w-36px tw-h-36px" onClick={handleClick}>
          <IconButton
            className="tw-fixed tw-m-4px"
            size="medium"
            schema="tertiary"
            disabled={index === numDocuments - 1}
            onClick={goToNextDocument}
            data-cy="document-preview-next-button"
          >
            <ChevronRight size="m" />
          </IconButton>
        </div>
      </div>
    </>
  );
};

PreviewNavigator.propTypes = {
  context: ContextPropType.isRequired,
  document: DocumentPropType.isRequired,
  index: PropTypes.number.isRequired,
  numDocuments: PropTypes.number.isRequired,
  dispatch: PropTypes.func,
};

PreviewNavigator.defaultProps = {
  dispatch: () => {},
};

export default connect()(PreviewNavigator);
