import React from "react";
import { HandHoldingDollarSolidV6 } from "@shared/v2/Icomoon";

const EnvoyReferralCreated = ({ event }) => {
  const { ...fields } = event.timeline_fields;

  return (
    <div className="timeline-event">
      <div className="row">
        <div className="col-xs-1">
          <div className="timeline-icon-circle tw-content-center">
            <HandHoldingDollarSolidV6 size="l" />
          </div>
        </div>
        <div className="col-xs-7">
          <div>
            <span className="bold-detail">{fields.user}</span> {" submitted an Envoy Referral"}
          </div>
          <div className="timeline-description-text">
            <div>Loan Officer: {fields.loan_officer}</div>
            <div>Details: {fields.buy_or_refinance} | {fields.property_state} | {fields.budget ? fields.budget : "No budget"} | {fields.timeframe}</div>
            <div>Notes: {fields.notes}</div>
          </div>
        </div>
        <div className="col-xs-1" />
        <div className="col-xs-3 time-ago">
          <span>{event.interacted_at_absolute}</span>
        </div>
      </div>
    </div>
  );
};

export default EnvoyReferralCreated;
