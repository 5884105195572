import React from "react";
import PropTypes from "prop-types";

import IconButton from "../../../../shared/v2/IconButton";
import { Plus } from "../../../../shared/v2/Icomoon";

const Header = ({ onApplyAutoPlanClicked }) => (
  <div className="tw-flex tw-justify-between tw-mb-16px">
    <span className="tw-font-bold tw-text-18d tw-text-neutral-gray-75">Auto Plans</span>

    <IconButton onClick={onApplyAutoPlanClicked} size="small" schema="tertiary" data-cy="apply-plan-button">
      <Plus size="s" />
    </IconButton>
  </div>
);
Header.propTypes = {
  onApplyAutoPlanClicked: PropTypes.func.isRequired,
};

export default Header;
