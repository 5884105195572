/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PauseSolidV6 = (props) => (
  <Icon {...props} name="Pause Solid V6">
    <path d="M11.5 1.972h-1c-.828 0-1.5.672-1.5 1.472v9a1.5 1.5 0 0 0 1.5 1.5l1 .056a1.5 1.5 0 0 0 1.5-1.5v-9c0-.828-.672-1.528-1.5-1.528zm-6 0h-1a1.5 1.5 0 0 0-1.5 1.5v9C3 13.328 3.672 14 4.5 14h1A1.5 1.5 0 0 0 7 12.5v-9c0-.828-.672-1.528-1.5-1.528z" />
  </Icon>
);

export default PauseSolidV6;
