import axios from "axios";

const saveLeadRoutingUsers = (model) => {
  const params = new URLSearchParams();
  Object.keys(model).forEach((k) => {
    params.append(k, model[k]);
  });
  params.append("authenticity_token", ReactOnRails.authenticityToken());
  params.append("inline_api", true);
  return axios.post("/lead_rule_users", params);
};

export default saveLeadRoutingUsers;
