/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BullseyeArrowRegularV6 = (props) => (
  <Icon {...props} name="Bullseye Arrow Regular V6">
    <path d="m11.45 6.672 1.431.238A5 5 0 0 1 8 13.001a5 5 0 0 1-5-5A4.998 4.998 0 0 1 9.091 3.12l.238 1.431-.153.153c-.366-.131-.763-.231-1.175-.231-1.934 0-3.5 1.594-3.5 3.5 0 1.963 1.566 3.528 3.5 3.528a3.505 3.505 0 0 0 3.297-4.675l.153-.153zm4.103-1.313c.262.8.447 1.716.447 2.641 0 4.419-3.581 8-8 8s-8-3.581-8-8a8 8 0 0 1 8-8c.925 0 1.813.157 2.641.446L9.429 1.658a6.499 6.499 0 0 0-7.928 6.343c0 3.591 2.909 6.5 6.5 6.5s6.5-2.909 6.5-6.5c0-.491-.053-.969-.184-1.428l1.238-1.213zm-7.05 3.144c-.266.319-.741.319-1.034 0-.291-.266-.291-.741 0-1.034l2.881-2.878-.266-1.583a1 1 0 0 1 .278-.871l1.6-1.6c.228-.254.684-.166.819.168l.719 1.768 1.797.719a.545.545 0 0 1 .166.847l-1.6 1.6c-.228.228-.553.303-.869.278l-1.584-.266-2.906 2.853z" />
  </Icon>
);

export default BullseyeArrowRegularV6;
