/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Table = (props) => (
  <Icon {...props} name="Table">
    <path d="M14.5 1h-13A1.5 1.5 0 0 0 0 2.5v11A1.5 1.5 0 0 0 1.5 15h13a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 14.5 1zM7 13H2v-3h5v3zm0-5H2V5h5v3zm7 5H9v-3h5v3zm0-5H9V5h5v3z" />
  </Icon>
);

export default Table;
