/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import { padStart, parseInt, words } from "lodash";
import moment from "moment-timezone";

export const onSaveDateAdapter = (form) => {
  const { day_month_year, run_time } = form;
  
  const dateTimeInLocal = moment.tz(`${day_month_year} ${run_time}`, 'DD/MM/YYYY HH:mm', Brivity.LocalTimeZone);

  if (dateTimeInLocal.isDST()) {
    dateTimeInLocal.add(1, 'hour');
  }

  const formattedDateTime = {
    run_time: dateTimeInLocal.utc().format('HH:mm')
  };

  return {
    ...form,
    ...formattedDateTime
  };
}


export const convertDateFormat = (inputDate) =>  {
  const dateObj = new Date(inputDate);
  
  const pad2 = (num) => padStart(num, 2, '0');
  const dayMonthYear = `${pad2(dateObj.getDate())}/${pad2(dateObj.getMonth() + 1)}/${dateObj.getFullYear()}`;
  const runTime = `${pad2(dateObj.getHours())}:${pad2(dateObj.getMinutes())}`;
  
  return { day_month_year: dayMonthYear, run_time: runTime };
}

export const formatDateAndTimeToDatePicker = (date, time) => {
  const [day, month, year] = date.split('/').map(Number);
  const [hours, minutes] = time.split(':').map(Number);

  const formattedDate = new Date(year, month - 1, day, hours, minutes);

  const options = { month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

  return formattedDate.toLocaleString('en-US', options).replace(',', '');
}

// checks if properties within object has something in it (with days && milestones
export const hasProperties = (data) => (
  (data.days && Object.keys(data.days).length > 0) ||
  (data.milestones && Object.keys(data.milestones).length > 0)
);

export const extractYearFromDateString = (dateString) => {
  const [,, year] = words(dateString, /[^\/: ]+/g).map(parseInt);
  
  return year
}

export const yearErrorMessageHandlr = (year) => {
  const currentYear = new Date().getFullYear();
  return year < currentYear && "You cannot select a past date" ||
         year > currentYear + 1 && "Must be no further out than next year" ||
         null; // Or you can return false if you just need a boolean result
}
 
export const checkIfDateStringIsWithinRange = (dateString) =>  {
  const dateStringYear = extractYearFromDateString(dateString);

  return yearErrorMessageHandlr(dateStringYear)
}