import React from "react";
import { func, string } from "prop-types";
import Button from "@shared/v2/Button";
import { PlusSolidV6 } from "@shared/v2/Icomoon";

const BtnToggle = ({ label, className , onClick}) => (
  <Button
    size="small"
    schema='secondary'
    onClick={onClick}
    className={`tw-inline-flex tw-items-center ${className}`}
  >
    <PlusSolidV6
      className='tw-mr-[4px]'/>  
    <span className="tw-uppercase">{label}</span>
  </Button>
);

export default BtnToggle;

BtnToggle.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  className: string,
}

BtnToggle.defaultProps = {
  className: '',
}