import axios from "axios";
import { tryCatchHandlr } from "../../shared/Utilities";

export const updateTaskById = async ({ id, formData }) =>
  await tryCatchHandlr(
    axios.patch(`/tasks/${id}`, {
      task: formData,
      authenticity_token: window.ReactOnRails.authenticityToken(),
    }),
  );

export const createTask = async ({ formData }) =>
  await tryCatchHandlr(
    axios.post("/tasks/", {
      task: formData,
      authenticity_token: window.ReactOnRails.authenticityToken(),
    }),
  );
