import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as creators from "../actions/creators";
import * as api from "../actions/api";
import Card from "./Card";
import DisplayRow from "./DisplayRow";
import LinkInput from "./UrlInputWrap";

const SECTIONS = [
  { title: "Zillow", key: "zillow_reviews", editable: true, comp: LinkInput },
  { title: "Trulia", key: "trulia_reviews", editable: true, comp: LinkInput },
  { title: "Realtor.com", key: "realtor_com_reviews", editable: true, comp: LinkInput },
  { title: "Yelp", key: "yelp_reviews", editable: true, comp: LinkInput },
  { title: "Facebook", key: "facebook_reviews", editable: true, comp: LinkInput },
  { title: "Google", key: "google_reviews", editable: true, comp: LinkInput },
];

const ReviewCard = ({ model, setValue, saveValue }) => (
  <Card title="Review Links">
    {SECTIONS.map((sec) => (
      <DisplayRow
        key={sec.key}
        title={sec.title}
        editable={sec.editable}
        value={model[sec.key]}
        onChange={(val) => setValue(sec.key, val)}
        onSave={(val) => saveValue(sec.key, val)}
        Comp={sec.comp}
      />
    ))}
  </Card>
);

ReviewCard.propTypes = {
  model: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  saveValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.model,
});

export default connect(mapStateToProps, { ...creators, ...api })(ReviewCard);
