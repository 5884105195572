import { enableMapSet } from "immer";
import reports from "./reportsReducer";
import assignedPlans from "./assignedPlans";
import autoPlans from "./autoPlans";
import autoPlanEdit from "./autoPlanEdit";
import businessSettings from "./businessSettingsReducer";
import triggers from "./triggers";
import milestones from "./milestonesReducer";
import addMarketReport from "./addMarketReportReducer";
import accountFinancial from "./accountFinancialReducer";
import transactionFinancial from "./transactionFinancialReducer";
import { tdpAutoPlansReducer } from "./tdpAutoPlansReducer";
import { tdpDatesReducer } from "./tdpDatesReducer";
import { tdpDetailsReducer } from "./tdpDetailsReducer";
import { tdpDocumentsReducer } from "./tdpDocumentsReducer";
import { tdpFinancialsReducer } from "./tdpFinancialsReducer";
import { tdpTimelineReducer } from "./tdpTimelineReducer";
import { tdpTasksReducer } from "./tdpTasksReducer";
import { tdpMarketingReducer } from "./tdpMarketingReducer";
import { tdpFeedbackReducer } from "./tdpFeedbackReducer";
import { tdpPeopleReducer } from "./tdpPeopleReducer";
import { tdpInteractionsReducer } from "./tdpInteractionsReducer";
import { tdpNotesReducer } from "./tdpNotesReducer";
import tdpThumbnailSelector from "./tdpThumbnailSelectorReducer";
import personDetail from "./personalDetailReducer/personDetailReducer";
import leadPonds from "./leadPondsReducer";
import LeadIndex from "./leadIndexReducer";
import leadRouting from "./leadRouteReducer";
import notesData from "./notesReducer";
import userSettings from "./userSettingsReducer";
import contactsReducer from "./contactsReducer";
import { superUsersBranding } from "./superUsersBrandingReducer";
import partnerSearch from "./partnerSearchReducer";

enableMapSet();

export default {
  accountFinancial,
  addMarketReport,
  assignedPlans,
  autoPlanEdit,
  autoPlans,
  businessSettings,
  contactsReducer,
  LeadIndex,
  leadPonds,
  leadRouting,
  milestones,
  notesData,
  partnerSearch,
  personDetail,
  reports,
  superUsersBranding,
  tdpAutoPlansReducer,
  tdpDatesReducer,
  tdpDetailsReducer,
  tdpDocumentsReducer,
  tdpFeedbackReducer,
  tdpFinancialsReducer,
  tdpInteractionsReducer,
  tdpMarketingReducer,
  tdpNotesReducer,
  tdpPeopleReducer,
  tdpTasksReducer,
  tdpThumbnailSelector,
  tdpTimelineReducer,
  transactionFinancial,
  triggers,
  userSettings,
};
