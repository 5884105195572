/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const RocketSolidV6 = (props) => (
  <Icon {...props} name="Rocket Solid V6">
    <path d="m4.894 12.028-.966-.994c-.266-.238-.359-.625-.269-.978A13.8 13.8 0 0 1 4.056 9H.75c-.269 0-.518-.144-.652-.378s-.131-.522.007-.753l1.64-2.766c.405-.684 1.141-1.131 1.937-1.131h2.569c.075-.097.15-.212.225-.325C9.035-.127 12.848-.254 15.123.165a.882.882 0 0 1 .713.712c.419 2.276.294 6.089-3.481 8.648-.109.075-.256.15-.353.197v2.597c0 .797-.419 1.531-1.103 1.938l-2.766 1.637a.75.75 0 0 1-.753.009.753.753 0 0 1-.378-.653V11.9c-.441.15-.825.278-1.116.372a.977.977 0 0 1-.991-.244zM12 5.222c.691 0 1.25-.531 1.25-1.25 0-.663-.559-1.25-1.25-1.25s-1.25.587-1.25 1.25c0 .719.559 1.25 1.25 1.25z" />
  </Icon>
);

export default RocketSolidV6;
