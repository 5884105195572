import React from "react";
import { func, string } from "prop-types";

const TaskItemName = ({ onClick, name, className, ...otherProps }) => (
  <button
    onClick={onClick}
    type="button"
    className="tw-grow tw-text-left tw-border-0 tw-bg-transparent enabled:hover:tw-underline"
    {...otherProps}
  >
    <p className="tw-line-clamp-2 tw-m-0">{name}</p>
  </button>
);

TaskItemName.propTypes = {
  onClick: func,
  name: string,
  className: string,
};

TaskItemName.defaultProps = {
  onClick: null,
  name: "",
  className: "",
};

export default TaskItemName;
