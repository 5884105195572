/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MobileXMark = (props) => (
  <Icon {...props} name="Mobile X Mark">
    <path d="M12 0H4C3.17188 0 2.5 0.671875 2.5 1.5V14.5C2.5 15.3281 3.17188 16 4 16H12C12.8281 16 13.5 15.3281 13.5 14.5V1.5C13.5 0.671875 12.8281 0 12 0ZM9.5 13.9719C9.5 14.275 9.275 14.5 9 14.5H6.97188C6.725 14.5 6.5 14.275 6.5 14C6.5 13.725 6.725 13.5 7 13.5H9C9.275 13.5 9.5 13.725 9.5 13.9719ZM11.5 2V12H4.5V2H11.5ZM5.24828 4.018C5.55616 3.74091 6.03038 3.76586 6.30747 4.07375L8 5.95433L9.69253 4.07375C9.96962 3.76586 10.4438 3.74091 10.7517 4.018C11.0596 4.29509 11.0846 4.76931 10.8075 5.07719L9.00902 7.07547L10.8075 9.07375C11.0846 9.38163 11.0596 9.85585 10.7517 10.1329C10.4438 10.41 9.96962 10.3851 9.69253 10.0772L8 8.19661L6.30747 10.0772C6.03038 10.3851 5.55616 10.41 5.24828 10.1329C4.94039 9.85585 4.91544 9.38163 5.19253 9.07375L6.99098 7.07547L5.19253 5.07719C4.91544 4.76931 4.94039 4.29509 5.24828 4.018Z" />
  </Icon>
);

export default MobileXMark;
