import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "./FieldLabelV1";
import styleSchema from "./FieldStyle";
import { CaretDown, CaretUp } from "../v2/Icomoon";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function FieldSelectWithCategoryOptionsWithSearchableDropdown({
  placeholder,
  options,
  optionsForValueMatch,
  value,
  onChange,
  className,
  label,
  required,
  flexStyle,
  toolTipInfoContent,
  toolTipPlacement,
  disabled,
  searchable,
  ...otherProps
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let schemaKey = disabled ? "disabled" : "regular";

  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

  const [searchInputText, setSearchInputText] = useState("");
  const [filteredSearchOptions, setFilteredSearchOptions] = useState(options);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);
        setSearchInputText("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (searchInputText) {
      setFilteredSearchOptions(
        [
          ...options.map((category) => ({
            category: category.category,
            options: category.options.filter((option) =>
              option.label.toLowerCase().includes(searchInputText.toLowerCase()),
            ),
          })),
        ].filter((category) => category?.options.length > 0),
      );
    } else {
      setFilteredSearchOptions(options);
    }
  }, [searchInputText]);

  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && (
        <FieldLabelV1
          {...{
            label,
            required,
            toolTipInfoContent,
            toolTipPlacement,
          }}
        />
      )}
      <div className="tw-relative" ref={ref}>
        <input
          type="button"
          value={!!value ? optionsForValueMatch.find((option) => option.value === value)?.label : placeholder}
          className={`${classes} ${!value ? "tw-text-neutral-gray-50" : ""}`}
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          {...otherProps}
        />

        <div className="tw-text-neutral-gray-75 tw-absolute tw-right-[12px] tw-top-[9px]">
          {isDropdownOpen ? <CaretUp /> : <CaretDown />}
        </div>

        {isDropdownOpen && (
          <div className="tw-text-left tw-border tw-border-solid tw-border-neutral-gray-15 tw-text-sm tw-whitespace-nowrap tw-absolute tw-z-10 tw-bg-white tw-rounded tw-max-h-[256px] tw-overflow-auto tw-w-fit">
            {searchable && (
              <div className="tw-p-[8px]">
                <input
                  type="text"
                  value={searchInputText}
                  onChange={(e) => setSearchInputText(e.target.value)}
                  placeholder="Search"
                  className={`${classes} tw-h-[24px]`}
                  data-cy="category_dropdown_search_input"
                />
              </div>
            )}
            {filteredSearchOptions?.map((categoryOptions) => {
              return (
                <div key={categoryOptions.category}>
                  <div className="tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1">{categoryOptions.category}</div>
                  {categoryOptions.options.map((subOption) => {
                    return (
                      <div
                        key={subOption.label}
                        className="hover:tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1"
                        onClick={() => {
                          onChange(subOption.value);
                          setIsDropdownOpen(false);
                          setSearchInputText("");
                        }}
                        data-cy={`${subOption.label}`}
                      >
                        {subOption.label}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {filteredSearchOptions.length <= 0 && (
              <div className="tw-px-[14px] tw-py-1">No option available</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
FieldSelectWithCategoryOptionsWithSearchableDropdown.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    }),
  ),
  optionsForValueMatch: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
};

FieldSelectWithCategoryOptionsWithSearchableDropdown.defaultProps = {
  label: "",
  required: false,
  flexStyle: "tw-flex-1",
  className: "",
  error: "",
  placeholder: "Select",
  disabled: false,
  options: [
    { category: "Category 1", options: [{ label: "option1", value: "option1" }] },
    { category: "Category 2", options: [{ label: "option2", value: "option2" }] },
  ],
  optionsForValueMatch: [
    [
      { label: "option1", value: "option1" },
      { label: "option2", value: "option2" },
    ],
  ],
  searchable: false,
  value: "",
};
export default FieldSelectWithCategoryOptionsWithSearchableDropdown;
