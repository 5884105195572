import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";

import {
  MODAL_ANIMATION_DURATION_MS,
  containerAnimationStyles,
  containerTransitionStyles,
  modalAnimationStyles,
  modalTransitionStyles,
} from "./animationStyles";

import Portal from "../../v1/Portal";
import Backdrop from "./Backdrop";
import ModalContextProvider, { ModalContext } from "./ModalContext";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";

import {
  MODAL_CONTAINER_CLASSES,
  MODAL_DIALOG_CLASSES,
  MODAL_CONTENT_CLASSES,
  MODAL_CONTENT_WIDTH_CLASSES,
  availableContentSizes,
} from "./Modal.styles";

/**
 * @summary A Generic Modal interface, binding state, portal, listeners and allowing any content, including Header, Body and Footer.
 * @param {func} onHide - function for what happens clicking outside, cancel or close button.
 * @param {node} children - elements within the body
 * @param {string} className - arbitrary classNames for the outrmost component.
 * @param {string} contentClassName - classNames for the content wrapper.
 * @param {string} dialogClassName - classNames for the content wrapper's wrapper, mostly for layout.
 * */

const Modal = ({
  show,
  onHide,
  children,
  className,
  dialogClassName,
  contentClassName,
  contentSize,
  closeOnEscape,
  closeOnClickOutside,
  containerClassName,
  ...props
}) => {
  const nodeRef = useRef(null);
  const renderModalContainer = (context) => (
    <Portal>
      <Transition
        in={context.show}
        timeout={MODAL_ANIMATION_DURATION_MS}
        unmountOnExit
        mountOnEnter
        nodeRef={nodeRef}
      >
        {(modalContainerState) => (
          <div
            className={`tw-relative tw-z-1250 ${containerClassName}`}
            ref={nodeRef}
            style={{
              ...containerAnimationStyles(MODAL_ANIMATION_DURATION_MS),
              ...containerTransitionStyles[modalContainerState],
            }}
          >
            <Backdrop />
            {/* Both of these are rendered inside the ModalContext which already provides the keyboard listeners */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              data-cy="modal-content-container-wrapper-n2"
              className={`${MODAL_CONTAINER_CLASSES} ${className}`}
              onClick={() => {
                if (closeOnClickOutside) {
                  context.onHide();
                }
              }}
              // Allow arbitrary attributes, mostly for data
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              <div
                className={`${MODAL_DIALOG_CLASSES} ${dialogClassName}`}
                data-cy="modal-content-container-wrapper"
              >
                <Transition
                  in={context.show}
                  appear
                  timeout={MODAL_ANIMATION_DURATION_MS}
                  unmountOnExit
                  mountOnEnter
                >
                  {(modalState) => (
                    /* This component is meant to be rendered inside a ModalContext, which */
                    /* already provides the keyboard listeners so we silence the linter here */
                    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
                    <div
                      style={{
                        ...modalAnimationStyles(MODAL_ANIMATION_DURATION_MS),
                        ...modalTransitionStyles[modalState],
                      }}
                      className={`${MODAL_CONTENT_CLASSES} ${contentClassName} ${MODAL_CONTENT_WIDTH_CLASSES[contentSize]}`}
                      data-cy="modal-content-container"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {children}
                    </div>
                  )}
                </Transition>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );

  return (
    <ModalContextProvider show={show} onHide={onHide} closeOnEscape={closeOnEscape}>
      <ModalContext.Consumer>{(value) => renderModalContainer(value)}</ModalContext.Consumer>
    </ModalContextProvider>
  );
};
Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  contentSize: PropTypes.oneOf(availableContentSizes),
  closeOnEscape: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  containerClassName: PropTypes.string,
};
Modal.defaultProps = {
  show: false,
  onHide: null,
  children: null,
  className: "",
  dialogClassName: "",
  contentClassName: "",
  contentSize: "none",
  closeOnEscape: true,
  closeOnClickOutside: false,
  containerClassName: "",
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;

export default Modal;
