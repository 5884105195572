import { useEffect, useState, useMemo } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Portal = ({ children, portalId }) => {
  const [hasMounted, setHasMounted] = useState(false);

  const portalRoot = useMemo(() => {
    const portal = document.createElement("div");
    portal.setAttribute("data-portal", portalId);
    return portal;
  }, []);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    const target = document.body;
    const existingPortal = document.querySelector(`[data-portal="${portalId}"]`);

    if (!existingPortal) {
      target.appendChild(portalRoot);
    }
  }, [portalRoot]);

  if (!hasMounted) {
    return null;
  }

  return ReactDOM.createPortal(children, document.querySelector(`[data-portal="${portalId}"]`));
};

Portal.propTypes = {
  children: PropTypes.node,
  portalId: PropTypes.string,
};

Portal.defaultProps = {
  children: null,
  portalId: "common-portal",
};

export default Portal;
