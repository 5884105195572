import React from "react";
import { uniqueId } from "lodash";

/**
 * Converts text into HTML paragraphs
 * @param  {string} text Paragraph text
 * @return {React.node} Paragraph node with unique key
 */
export const convertTextToParagraph = (text) => <p key={uniqueId("paragraph-")}>{text}</p>;

/**
 * Converts unix-style linebreaks (\n) into HTML paragraphs
 * @param  {string} text Raw text entry
 * @return {string | [React.node]} Either the original value or a collection of paragraphs
 */
export const linebreaksToParagraphs = (text) =>
  text && text.includes("\n") ? text.split("\n").map(convertTextToParagraph) : text;
