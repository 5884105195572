import { useState } from "react";

const useImgStyle = () => {
  const [mirrored, setMirrored] = useState(false);

  const imgStyle = () => `tw-w-full tw-h-full tw-w-[630px] tw-h-[420px] ${mirrored && "tw--scale-x-100"}`;

  return [imgStyle, setMirrored];
};

export default useImgStyle;
