import React from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "./FieldLabelV1";
import styleSchema from "./FieldStyle";

const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function FieldTextV1({
  onChange,
  required,
  label,
  value,
  flexStyle,
  toolTipInfoContent,
  toolTipPlacement,
  disabled,
  className,
  ...otherProps
}) {
  let schemaKey = disabled ? "disabled" : "regular";

  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;
  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required, toolTipInfoContent, toolTipPlacement }} />}
      <input
        type="text"
        defaultValue={value || ""}
        className={classes}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}
FieldTextV1.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  flexStyle: PropTypes.string,
  toolTipInfoContent: PropTypes.element,
  toolTipPlacement: PropTypes.string,
};

FieldTextV1.defaultProps = {
  onChange: () => {},
  required: false,
  disabled: false,
  label: "",
  value: "",
  flexStyle: "tw-flex-1",
  toolTipInfoContent: null,
  toolTipPlacement: null,
};
export default FieldTextV1;
