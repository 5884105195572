import React from "react";
import { func, string } from "prop-types";
import { isString, trim } from "lodash";

import withDispatch from "../../../../shared/HOCs/withDispatch";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { ADDRESS_FIELD, LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { referralDesiredAddressEditOff } from "../../actions/creators";
import { putRefDesiredAddressAsThunk } from "../../actions/thunks";

import InlineEditingInput from "../../../../shared/v2/InlineEditingInput/InlineEditingInput";

const ReferralDesiredAddressEdit = ({ onChange, onCancelEditing, addressField }) => (
  <div className="tw-relative tw-flex tw-items-center">
    <InlineEditingInput
      className="tw-leading-[36px] tw-absolute tw-ml-10px"
      displayClassName="tw-text-right !tw-mr-[21px]"
      editorClassName="!tw-mr-[21px]"
      id="ReferralDesiredAddressEdit"
      name="ReferralDesiredAddressEdit"
      onCancelEditing={onCancelEditing}
      onChange={onChange}
      placeholder=""
      value={addressField}
      externalEditing
    />
  </div>
);

const ReferralDesiredAddressEditWrapper = ({
  onPutRefDesiredAddressAsThunk,
  onReferralDesiredAddressEditOff,
  addressField,
  uuid,
}) => {
  const onChangeHandlr = (val) =>
    onPutRefDesiredAddressAsThunk({
      key: "desired_locations",
      value: isString(val) ? trim(val) : val,
      uuid,
    });

  return (
    <ReferralDesiredAddressEdit
      onChange={onChangeHandlr}
      onCancelEditing={onReferralDesiredAddressEditOff}
      addressField={addressField}
    />
  );
};

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralDesiredAddressEditWrapper,
  states: [ADDRESS_FIELD, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: [putRefDesiredAddressAsThunk, referralDesiredAddressEditOff],
  aliases: ["onPutRefDesiredAddressAsThunk", "onReferralDesiredAddressEditOff"],
});

ReferralDesiredAddressEdit.propTypes = {
  onChange: func,
  onCancelEditing: func,
  addressField: string,
};

ReferralDesiredAddressEdit.defaultProps = {
  onChange: func,
  onCancelEditing: func,
  addressField: string,
};

ReferralDesiredAddressEditWrapper.propTypes = {
  onPutRefDesiredAddressAsThunk: func,
  onReferralDesiredAddressEditOff: func,
  addressField: string,
  uuid: string,
};

ReferralDesiredAddressEditWrapper.defaultProps = {
  onPutRefDesiredAddressAsThunk: () => {},
  onReferralDesiredAddressEditOff: () => {},
  addressField: null,
  uuid: null,
};
