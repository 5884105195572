import { useEffect, useState } from "react";

export const useExpenseList = (expList) => {
  const [expenseList, setExpenseList] = useState([]);

  useEffect(() => {
    setExpenseList(expenseEntries(expList));
  }, [setExpenseList, expList]);

  return { expenseList };
};

const expenseEntries = (expList) =>
  Object.entries(expList).map(([label, value]) => {
    return { label, value };
  });
