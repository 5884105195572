export const DATE_FORMAT = "MM/DD/YYYY";
export const TEXT_CLASSES =
  "tw-text-left tw-h-[36px] tw-rounded-[8px] tw-text-14px tw-font-semibold tw-text-gray-50 tw-leading-[20px] ";
export const HEADING_TEXT_CLASSES =
  "tw-flex tw-text-center tw-h-[36px] tw-rounded-[8px] tw-text-18px tw-font-normal tw-text-gray-50 tw-leading-[24px] ";
export const DETAIL_TEXT_CLASSES =
  "tw-inline-flex tw-flex-row tw-justify-start tw-w-[218px] tw-text-left tw-items-center tw-truncate tw-h-[36px] tw-rounded-[8px] tw-pl-[32px] tw-pr-[8px] tw-text-14px tw-font-normal tw-text-gray-75 tw-leading-[20px] ";
export const allKeys = [
  "streetAddress",
  "postalCode",
  "price",
  "mlsNumber",
  "beds",
  "baths",
  "sqFt",
  "city",
  "state",
  "county",
  "property_type",
  "neighborhood",
  "year_built",
  "parking",
  "foundation",
  "photosAvailable",
  "photos",
  "description",
];
