import moment from "moment-timezone";
import { sortByKey } from "../../shared/Utilities";

const timeWithZoneFormat = "hh:mm a z";
const timeFormat = "HH:mm";

const priorityOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
];

const recurringFrequencyOptions = [
  { value: "never", label: "Never" },
  { value: "daily", label: "Daily" },
  { value: "every", label: "Every __ Days" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

const weeklyOptions = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
];

const monthlyOptions = Array(31)
  .fill()
  .map((_, i) => {
    const day = i + 1;

    return { value: day, label: `Day ${day}` };
  });

const timeUnitOptions = [
  { value: "seconds", label: "Seconds" },
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
];

const timeUnitOptionsForEvents = [{ value: "days", label: "Days" }];

const delayFactorOptions = [
  { value: -1, label: "Before" },
  { value: 1, label: "After" },
];

const recurringFrequencyTypes = [
  { value: "never", label: "Never" },
  { value: "number", label: "Every _ Days" },
  { value: "weekly", label: "Weekly" },
];

const peopleAutoPauseStatusOptions = [
  { label: "Any Status", value: "any" },
  { label: "New", value: "new" },
  { label: "Unqualified", value: "unqualified" },
  { label: "Watch", value: "watch" },
  { label: "Nurture", value: "nurture" },
  { label: "Hot", value: "hot" },
  { label: "Past (Client)", value: "past" },
  { label: "Inactive", value: "inactive" },
  { label: "Trash", value: "trash" },
  { label: "Archived", value: "archived" },
];

const transactionAutoPauseStatusOptions = [
  { label: "Any Status", value: "any" },
  { label: "Pipeline", value: "pipeline" },
  { label: "Coming Soon", value: "coming soon" },
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Withdrawn", value: "withdrawn" },
  { label: "Canceled", value: "canceled" },
  { label: "Archived", value: "archived" },
];

const peopleCompletionStatusOptions = [
  { label: "Don't Change", value: "" },
  { label: "New", value: "new" },
  { label: "Unqualified", value: "unqualified" },
  { label: "Watch", value: "watch" },
  { label: "Nurture", value: "nurture" },
  { label: "Hot", value: "hot" },
  { label: "Past (Client)", value: "past" },
  { label: "Inactive", value: "inactive" },
];

const transactionCompletionStatusOptions = [
  { label: "Don't Change", value: "" },
  { label: "Pipeline", value: "pipeline" },
  { label: "Coming Soon", value: "coming soon" },
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Withdrawn", value: "withdrawn" },
  { label: "Canceled", value: "canceled" },
  { label: "Archived", value: "archived" },
];

const canBeContingent = (scheduleType, triggerOptions = []) => {
  if (scheduleType === "event") {
    return false;
  }

  return triggerOptions.filter((option) => !option.disabled && option.value !== "Trigger").length > 0;
};

const timeOptions = (increment = 15) => {
  const totalMinutes = 1440;

  const options = Array(totalMinutes / increment)
    .fill()
    .map((_, i) => {
      const minutes = (i + 1) * increment;
      const time = moment().startOf("year").add(minutes, "minutes");
      const label = time.tz(Brivity.LocalTimeZone).format(timeWithZoneFormat);
      const value = time.utc().format(timeFormat);
      const valueForSort = time.tz(Brivity.LocalTimeZone).format(timeFormat);

      return { value, label, valueForSort };
    });
  return sortByKey(options, "valueForSort");
};

const findDefaultRunAfterTimeOption = (type) => {
  if (type === "Task") {
    return timeOptions().find((e) => e.valueForSort === "17:00");
  }
  return timeOptions().find((e) => e.valueForSort === "09:00");
};

const shortDelayUnits = {
  seconds: "sec",
  minutes: "min",
  hours: "hours",
  days: "days",
};

const getActionById = (id, autoPlan) => autoPlan.actions.filter((action) => action.id === id)[0];

const autoPlanNameLabel = (id, autoPlans = []) => {
  const autoPlan = autoPlans.filter((a) => a.id === id)[0];
  return autoPlan ? autoPlan.name : "";
};

export {
  priorityOptions,
  recurringFrequencyOptions,
  weeklyOptions,
  monthlyOptions,
  canBeContingent,
  timeOptions,
  timeUnitOptionsForEvents,
  timeUnitOptions,
  delayFactorOptions,
  recurringFrequencyTypes,
  getActionById,
  autoPlanNameLabel,
  shortDelayUnits,
  timeWithZoneFormat,
  peopleAutoPauseStatusOptions,
  peopleCompletionStatusOptions,
  transactionAutoPauseStatusOptions,
  transactionCompletionStatusOptions,
  findDefaultRunAfterTimeOption,
};
