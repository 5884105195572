import React, { useEffect, useState } from "react";
import { func, string } from "prop-types";
import BtnToggle from "../BtnToggle";
import TaskInputToggle from "../TaskActionModal/TaskInputToggle";

const TaskToggles = ({setInstructions, setNotes, instructions, notes}) => {
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [isNotesOpens, setIsNotesOpen] = useState(false);

  useEffect(() => {
    if(instructions.trim().length){
      setIsInstructionsOpen(true)
    }
  }, [instructions])

  useEffect(() => {
    if(notes.trim().length){
      setIsNotesOpen(true)
    }
  }, [notes])

  return (
    <>
      <div className='tw-flex tw-gap-[8px]'>
        {!isInstructionsOpen &&
          <BtnToggle
            label='instructions'
            onClick={() => setIsInstructionsOpen(true)}/>
        }        
        {!isNotesOpens &&
          <BtnToggle
            label='notes'
            onClick={() => setIsNotesOpen(true)}/>
        }        
      </div>
      <TaskInputToggle
        show={isInstructionsOpen}
        onClose={() => setIsInstructionsOpen(false)}
        setValue={setInstructions}
        value={instructions}
        textareaId='sp_instructions'
        label='instructions'/>
      <TaskInputToggle
        show={isNotesOpens}
        onClose={() => setIsNotesOpen(false)}
        setValue={setNotes}
        value={notes}
        textareaId='sp_notes'
        label='notes'/>
    </>
  )
}

export default TaskToggles;

TaskToggles.propTypes = {
  setInstructions: func.isRequired, 
  setNotes: func.isRequired, 
  instructions: string, 
  notes: string
}

TaskToggles.defaultProps = {
  instructions: '', 
  notes: ''
}
