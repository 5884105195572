/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FishSolidV6 = (props) => (
  <Icon {...props} name="Fish Solid V6">
    <path d="M5.014 4.819c1.089-.917 2.558-1.708 4.319-1.708s3.231.792 4.319 1.708c1.083.917 1.856 1.989 2.247 2.772a.886.886 0 0 1 0 .817c-.392.758-1.164 1.856-2.247 2.772-1.089.917-2.558 1.708-4.319 1.708s-3.231-.792-4.319-1.708a9.986 9.986 0 0 1-1.186-1.2l-2.491 1.453a.888.888 0 0 1-1.219-1.208L1.39 8 .118 5.775a.887.887 0 0 1 1.219-1.208L3.828 6.02a9.986 9.986 0 0 1 1.186-1.2zm6.542 2.292c-.492 0-.889.397-.889.889s.397.889.889.889.889-.397.889-.889-.397-.889-.889-.889z" />
  </Icon>
);

export default FishSolidV6;
