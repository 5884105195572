import React from "react";
import { arrayOf, string } from "prop-types";

import { useLinkClickHandler, useMatch, useResolvedPath } from "react-router-dom";
import useInitPathName from "../hooks";

import { LINKS_TEXT, REF_LINKS_TEXT } from "./fixtures";
import { tdpDetails } from "../../../shared/HOCs/withRedux";
import { TRANSACTION_TYPE, USERTYPE } from "../../../shared/reduxRoutes/tdpDetailsRoutes";
import { isAgent, isReferral } from "../../shared/helpers";

import Tab from "../../../shared/v2/Tab";
import TabBar from "../../../shared/v2/TabBar";

const isTabSelected = (tabPath) => {
  const path = useResolvedPath(tabPath);
  const match = useMatch({ path: path.pathname, end: false, caseSensitive: false });
  return match !== null;
};

const setLinkTextByTransactionType = (type) => (isReferral(type) ? REF_LINKS_TEXT : LINKS_TEXT);

const NaviLinksTab = ({ navText }) => {
  const { initPathName } = useInitPathName();
  const tabPath = `${initPathName}/${navText}`;
  const selected = isTabSelected(tabPath);
  const handleOnClick = useLinkClickHandler(tabPath);

  return (
    <div
      className={`tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-[2px] 
       hover:tw-text-theme-tab-label-focus ${
         selected ? "tw-text-theme-tab-label-focus" : "tw-text-[#FFFFFF]"
       } `}
    >
      <Tab
        key={navText}
        label={navText}
        selected={selected}
        data-js-selected={selected}
        onClick={handleOnClick}
      />
    </div>
  );
};

const NaviLinks = ({ linksText }) => (
  <div className="tw-box-border tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-gray-10 tw-border-b-[1px] tw-flex tw-flex-col tw-mb-32px tw-min-w-600px tw-max-w-750px tw-overflow-x-auto">
    <TabBar className="tw-w-full !tw-justify-between !tw-space-x-0 tw-flex-nowrap">
      {linksText.map((navText) => (
        <NaviLinksTab navText={navText} key={navText} />
      ))}
    </TabBar>
  </div>
);

const NaviLinksWrapper = ({ userType, type }) => {
  let linksText = setLinkTextByTransactionType(type);
  if (isAgent(userType)) linksText = linksText.filter((tabName) => tabName !== "financials");

  return <NaviLinks linksText={linksText} />;
};

export default tdpDetails({
  WrappedComponent: NaviLinksWrapper,
  states: [TRANSACTION_TYPE, USERTYPE],
});

NaviLinksWrapper.propTypes = {
  type: string,
  userType: string.isRequired,
};

NaviLinksWrapper.defaultProps = {
  type: null,
};

NaviLinks.propTypes = {
  linksText: arrayOf(string),
};

NaviLinks.defaultProps = {
  linksText: [],
};

NaviLinks.propTypes = {
  linksText: arrayOf(string),
};

NaviLinks.defaultProps = {
  linksText: [],
};

NaviLinksTab.propTypes = {
  navText: string.isRequired,
};
