import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import Typography from "@shared/v2/Typography";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import CategoryTagModal from "./CategoryTagModal";
import CategoryTagItem from "./CategoryTagItem";
import DeleteCategoryTagModal from "./DeleteCategoryTagModal";
import { updateBlogSettings } from "../../reducers/websiteReducer/websiteReducer";
import {
  useBlogSettings,
  useBlogSettingsError,
  useCategories,
  useTags,
} from "../../reducers/websiteReducer/selectors";

const BlogSettingsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const initBlogSettings = useBlogSettings();
  const blogSettingsError = useBlogSettingsError();
  const tags = useTags();
  const categories = useCategories();
  const [blogSettings, setBlogSettings] = useState(initBlogSettings || { name: "", description: "" });
  const [catTagModel, setCatTagModel] = useState();
  const [deleteCatTagModel, setDeleteCatTagModel] = useState();

  useEffect(() => {
    setBlogSettings(initBlogSettings || { name: "", description: "" });
  }, [initBlogSettings]);

  return (
    <Modal
      show={open}
      onHide={onClose}
      closeOnEscape={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[800px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
    >
      <Modal.Header title="Blog Settings" closeButton />
      <Modal.Body className="tw-px-[8px] tw-mx-[-8px] tw-flex tw-flex-col tw-gap-[32px] tw-overflow-auto">
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <Typography variant="headline2">General</Typography>
          <TextInput
            label="Blog Title"
            placeholder="Give your blog an eye-catching name!"
            value={blogSettings.name || ""}
            onChange={(e) => setBlogSettings({ ...blogSettings, name: e.target.value })}
          />
          <TextInput
            label="Blog Description"
            placeholder="Brief summary of your blog's purpose and content, designed to inform readers and improve SEO. Highlight what makes your content unique, such as expert buying and selling tips, market insights, or home improvement ideas. Keep it concise, engaging, and relevant to attract your target audience and set clear expectations for your readers."
            multiline
            rows={5}
            value={blogSettings.description || ""}
            onChange={(e) => setBlogSettings({ ...blogSettings, description: e.target.value })}
          />
        </div>

        <div className="tw-bg-gray-10 tw-min-h-[1px] tw-w-full" />

        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
            <Typography variant="headline2">Categories</Typography>
            <Button schema="secondary" onClick={() => setCatTagModel({ category: "" })}>
              <PlusSolidV6 size="s" /> Add
            </Button>
          </div>

          <Typography variant="body-m">
            Categories organize your blog posts into broad topics, helping readers easily find related
            content. Use categories to group similar posts together for a clear and structured blog
            experience.
          </Typography>

          {categories.length === 0 && (
            <Typography variant="body-m" className="!tw-text-gray-50">
              No blog categories
            </Typography>
          )}

          {categories.length > 0 && (
            <div className="tw-flex tw-flex-col tw-gap-[8px] tw-pb-[8px] tw-pr-[8px] tw-max-h-[256px] tw-overflow-auto tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-[1px] tw-border-gray-10">
              {categories.map((o, i) => (
                <React.Fragment key={o.uuid}>
                  <CategoryTagItem
                    item={o}
                    onEdit={() => setCatTagModel(o)}
                    onDelete={() => setDeleteCatTagModel(o)}
                  />
                  {i !== categories.length - 1 && <div className="tw-min-h-[1px] tw-bg-gray-5 tw-w-full" />}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        <div className="tw-bg-gray-10 tw-min-h-[1px] tw-w-full" />

        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[8px]">
            <Typography variant="headline2">Tags</Typography>
            <Button schema="secondary" onClick={() => setCatTagModel({ tag: "" })}>
              <PlusSolidV6 size="s" /> Add
            </Button>
          </div>

          <Typography variant="body-m">
            Tags are specific keywords that describe the details of your blog post and connect related
            content. Tags make it easier for readers to discover posts on similar topics across different
            categories.
          </Typography>

          {tags.length === 0 && (
            <Typography variant="body-m" className="!tw-text-gray-50">
              No blog tags
            </Typography>
          )}

          {tags.length > 0 && (
            <div className="tw-flex tw-flex-col tw-gap-[8px] tw-pb-[8px] tw-pr-[8px] tw-max-h-[256px] tw-overflow-auto tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-[1px] tw-border-gray-10">
              {tags.map((o, i) => (
                <React.Fragment key={o.uuid}>
                  <CategoryTagItem
                    item={o}
                    onEdit={() => setCatTagModel(o)}
                    onDelete={() => setDeleteCatTagModel(o)}
                  />
                  {i !== tags.length - 1 && <div className="tw-min-h-[1px] tw-bg-gray-5 tw-w-full" />}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-col tw-gap-[16px]">
        {blogSettingsError && <Alert variant="error" text={blogSettingsError} />}
        <div className="tw-flex tw-justify-between tw-gap-[8px]">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            onClick={() => {
              dispatch(updateBlogSettings(blogSettings)).then(({ error }) => !error && onClose());
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>

      <CategoryTagModal open={Boolean(catTagModel)} onClose={() => setCatTagModel()} value={catTagModel} />
      <DeleteCategoryTagModal
        open={Boolean(deleteCatTagModel)}
        onClose={() => setDeleteCatTagModel()}
        value={deleteCatTagModel}
      />
    </Modal>
  );
};

BlogSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BlogSettingsModal;
