import React from "react";
import PropTypes from "prop-types";

const FieldHelper = ({ helperText, isDisabled, className }) => (
  <div
    className={`
      tw-text-12d
      ${isDisabled ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-50"}
      ${className}
    `}
  >
    {helperText}
  </div>
);

FieldHelper.propTypes = {
  helperText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

FieldHelper.defaultProps = {
  isDisabled: false,
  className: "",
};

export default FieldHelper;
