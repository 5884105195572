import React from "react";
import PropTypes from "prop-types";
import DashTooltip from "../DashboardShared/DashTooltip";
import formatter from "../../../shared/formatters";
import { ArrowDown, ArrowUp } from "../../../shared/v2/Icomoon";

const styles = {
  label: {
    s: "tw-text-12d",
    m: "tw-text-14d",
    l: "tw-text-14d tw-mb-[4px]",
  },
  value: {
    s: "tw-text-14d tw-font-normal",
    m: "tw-text-16d tw-font-semibold",
    l: "tw-text-18d tw-font-bold",
  },
  arrow: "tw-flex tw-justify-center tw-items-center tw-m-[-2px] tw-w-[12px] tw-h-[12px]",
};

const YtdDifference = ({ format, text, value: _value, postfix }) => {
  const value = Number(_value);
  const isValid = !Number.isNaN(value);
  const isPositive = value >= 0;
  return (
    <div className="tw-flex tw-items-center tw-gap-[6px]">
      {isValid && (
        <span
          className={`tw-flex tw-items-center tw-gap-[2px] tw-text-14d tw-font-normal ${
            isPositive ? "tw-text-semantic-green-100" : "tw-text-coral"
          }`}
        >
          {isPositive ? <ArrowUp className={styles.arrow} /> : <ArrowDown className={styles.arrow} />}
          {formatter[format](Math.abs(value))}
          {postfix}
        </span>
      )}
      {text && <span className="tw-text-14d tw-text-neutral-gray-50">{text}</span>}
    </div>
  );
};

YtdDifference.propTypes = {
  format: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  postfix: PropTypes.string,
};

YtdDifference.defaultProps = {
  format: "number",
  text: "",
  value: undefined,
  postfix: "",
};

const CardLabel = ({
  className = "",
  format,
  ytdDifference,
  label,
  size = "s",
  tooltip = "",
  value,
  valueColor = "tw-text-neutral-gray-75",
}) => {
  const isPositive = ytdDifference >= 0;
  return (
    <div className={`tw-relative tw-flex tw-min-w-[65px] ${className}`}>
      <span
        className={`tw-flex tw-items-center tw-text-neutral-gray-50 tw-font-semibold ${styles.label[size]}`}
      >
        {label}
        {tooltip && <DashTooltip text={tooltip} />}
      </span>
      <span className={`tw-flex tw-items-center ${styles.value[size]} ${valueColor}`}>{value}</span>
      {typeof ytdDifference !== "undefined" && (
        <span
          className={`tw-flex tw-items-center tw-gap-[2px] tw-text-14d tw-font-normal ${
            isPositive ? "tw-text-semantic-green-100" : "tw-text-coral"
          }`}
        >
          <div className={styles.arrow}>{isPositive ? <ArrowUp size="s" /> : <ArrowDown size="s" />}</div>
          {formatter[format](Math.abs(ytdDifference))}
        </span>
      )}
    </div>
  );
};

CardLabel.defaultProps = {
  format: undefined,
  className: "",
  ytdDifference: undefined,
  size: "s",
  tooltip: "",
  valueColor: "tw-text-neutral-gray-75",
  value: undefined,
};

CardLabel.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  ytdDifference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueColor: PropTypes.string,
};

export default CardLabel;
export { YtdDifference };
