import React from "react";
import PropTypes from "prop-types";
import { YtdDifference } from "../BusinessDashboard/CardLabel";
import DashTooltip from "./DashTooltip";
import colors from "./helpers/colors";
import formatter from "../../../shared/formatters";
import Tooltip from "../../../shared/v2/Tooltip/Tooltip";

const RollupStatCard = ({
  format,
  label,
  overviewTooltip,
  overviewTooltipPlacement,
  postfix,
  text,
  tooltip,
  value,
  ytdDifference,
}) => {
  const actualFormat = format === "percent" ? "number" : format;
  const body = (
    <div className="tw-flex tw-flex-col tw-gap-[8px] tw-p-[24px]">
      <div className="tw-text-neutral-gray-50 tw-whitespace-nowrap">
        {label} {tooltip && <DashTooltip text={tooltip} />}
      </div>
      <div
        className={`tw-text-28d ${
          colors.theme === "place" ? "tw-text-[#121212]" : "tw-text-brivity-blue-100"
        }`}
      >
        {formatter[actualFormat](value, format === "percent" ? 2 : undefined)}
        {postfix}
      </div>
      {(!Number.isNaN(Number(ytdDifference)) || text) && (
        <YtdDifference format={actualFormat} value={ytdDifference} text={text} />
      )}
    </div>
  );

  return (
    <div className="tw-flex-1 tw-rounded-[8px] tw-bg-white tw-shadow tw-min-w-[244px]">
      {overviewTooltip ? (
        <Tooltip content={overviewTooltip} placement={overviewTooltipPlacement} trigger={body} />
      ) : (
        body
      )}
    </div>
  );
};

RollupStatCard.propTypes = {
  format: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  overviewTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  overviewTooltipPlacement: PropTypes.string,
  postfix: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ytdDifference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RollupStatCard.defaultProps = {
  overviewTooltip: "",
  overviewTooltipPlacement: "top",
  postfix: "",
  text: "",
  tooltip: "",
  value: "",
  ytdDifference: undefined,
};

export default RollupStatCard;
