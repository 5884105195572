import React from "react";
import Proptypes from "prop-types";
import moment from "moment";
import { Row, Col } from "../../../../shared/v1";
import ProfileIcon from "../ProfileIcon";
import MultiUser from "../../../../shared/v2/Icomoon/Icons/MultiUser";
import { getImglink } from "../api/helpers";
import { DollarSignRegularV6 } from "../../../../shared/v2/Icomoon";
import format from "../../../../shared/formatters";

const CardSnippet = ({ icon, value, name }) => (
  <div
    className="
  tw-flex tw-flex-col tw-items-center tw-justify-around tw-m-12px tw-p-16px tw-bg-white tw-shadow-19 tw-rounded
  tw-h-[128px] tw-w-[128px]"
  >
    {icon({ className: "tw-text-teal", size: "xl" })}
    <div className="tw-text-gray-99 tw-leading-[24px] tw-text-[18px] tw-font-bold">{value}</div>
    <div className="tw-leading-[16px] tw-text-[12px] tw-font-normal">{name}</div>
  </div>
);

CardSnippet.propTypes = {
  name: Proptypes.string.isRequired,
  value: Proptypes.number,
  icon: Proptypes.func.isRequired,
};

CardSnippet.defaultProps = {
  value: null,
};

const MailerCards = ({
  name,
  status,
  type,
  createdBy,
  size,
  createdAt,
  coverImage,
  recipients,
  budgetCents,
}) => {
  const normalizedBudget = budgetCents ? format.currency(budgetCents / 100, 0) : "-";
  const normalizedRecipients = recipients ? format.number(recipients, 0) : "-";

  return (
    <div className="tw-pt-[10px]">
      <div className="tw-rounded tw-border-solid tw-border tw-border-gray-10">
        <Row className="tw-m-[0px] tw-mx-[0px] tw-mb-[10px] tw-flex tw-items-center	tw-p-0 tw-px-[16px] tw-py-[12px] tw-bg-gray-lightest">
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={3}>
            <div className="tw-break-words tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 ">
              {name}
            </div>
            <div className="tw-break-words tw-leading-[16px] tw-text-[12px] tw-font-semibold tw-text-gray-75 ">
              {type}
            </div>
          </Col>
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={1} />
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={1}>
            <ProfileIcon
              imglink={getImglink(createdBy.avatar_collection)}
              name={`Created by ${createdBy.first_name} ${createdBy.last_name}`}
              initials={createdBy.initials}
            />
          </Col>
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 " size={2}>
            <div className=" tw-leading-[16px] tw-text-[11px] tw-font-bold tw-text-gray-75 ">
              SIZE:
              <span className="tw-break-words tw-pl-[8px] tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-75 ">
                {size}
              </span>
            </div>
          </Col>
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-pl-[12px] " size={3}>
            <div className=" tw-leading-[16px] tw-text-[11px] tw-font-bold tw-text-gray-75 ">
              CREATED:
              <span className="tw-pl-[8px] tw-leading-[16px] tw-text-[12px] tw-font-normal tw-text-gray-75 ">
                {moment(createdAt).format("MM/DD/YYYY")}
              </span>
            </div>
          </Col>
          <Col className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-justify-end tw-flex" size={2}>
            <div className="tw-uppercase text-center tw-h-[20px] tw-text-white tw-leading-[16px] tw-text-[11px] tw-font-bold tw-bg-semantic-green-50 tw-rounded-full tw-py-[2px] tw-px-[8px]">
              {status}
            </div>
          </Col>
        </Row>
        <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-p-[24px] tw-flex ">
          <Col
            className="tw-max-w-full tw-block tw-transition tw-ease-out tw-delay-300 tw-drop-shadow-19 
          tw-m-0 tw-mx-[0px] tw-py-[0px] tw-px-[0px] tw-p-0 tw-pr-[21px] tw-pl-[0px]"
            size={8}
          >
            <img
              alt="coverImage"
              className="tw-rounded tw-border-solid tw-border tw-border-gray-15 tw-block tw-max-h-[280px] tw-object-contain tw-max-w-full tw-cursor-default"
              src={coverImage}
            />
          </Col>
          <Col
            className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0 tw-flex tw-gap-[8px] tw-self-center"
            size={4}
          >
            <CardSnippet name="Recipients" value={normalizedRecipients} icon={MultiUser} />
            <CardSnippet name="Budget" value={normalizedBudget} icon={DollarSignRegularV6} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

MailerCards.propTypes = {
  name: Proptypes.string,
  status: Proptypes.string,
  type: Proptypes.string,
  createdBy: Proptypes.shape({
    avatar_collection: Proptypes.shape({}),
    imglink: Proptypes.string,
    first_name: Proptypes.string,
    last_name: Proptypes.string,
    initials: Proptypes.string,
  }),
  size: Proptypes.string,
  coverImage: Proptypes.string,
  createdAt: Proptypes.string,
  recipients: Proptypes.number,
  budgetCents: Proptypes.number,
};
MailerCards.defaultProps = {
  name: "",
  status: "",
  type: "",
  createdBy: {
    avatar_collection: {},
    imglink: "",
    first_name: "",
    last_name: "",
    initials: "",
  },
  size: "",
  coverImage: "",
  createdAt: moment().format(),
  recipients: 0,
  budgetCents: 0,
};
export default MailerCards;
