/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as creators from "../actions/creators";
import * as api from "../actions/api";
import Card from "./Card";
import UploadImage from "./UploadImage";

const BrandingCard = ({ model, setValue, saveValue, removeImage }) => (
  <Card title="Branding">
    <div className="tw-flex">
      <div className="tw-mr-[48px] tw-w-[180px]">
        <h3 className="tw-text-[14px] tw-font-semibold tw-text-neutral-gray-50 tw-mb-[12px] tw-my-0">
          Team Logo
        </h3>
        <UploadImage
          image={model?.team_logo_image_url}
          onSave={(file) => {
            setValue("team_logo_image_url", file.path);
            saveValue("team_logo_image_temporary_url", file.path);
          }}
          onRemove={() => {
            removeImage(true);
            setValue("team_logo_image_url", null);
          }}
        />
      </div>
      <div className="tw-w-[180px]">
        <h3 className="tw-text-[14px] tw-font-semibold tw-text-neutral-gray-50 tw-mb-[12px] tw-my-0">
          Brokerage Logo
        </h3>
        <UploadImage
          image={model?.broker_image_url}
          onSave={(file) => {
            setValue("broker_image_url", file.path);
            saveValue("broker_image_temporary_url", file.path);
          }}
          onRemove={() => {
            removeImage();
            setValue("broker_image_url", null);
          }}
        />
      </div>
    </div>
  </Card>
);

BrandingCard.propTypes = {
  model: PropTypes.shape({
    team_logo_image_url: PropTypes.string.isRequired,
    broker_image_url: PropTypes.string.isRequired,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  saveValue: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  model: state.businessSettings.model,
});

export default connect(mapStateToProps, { ...creators, ...api })(BrandingCard);
