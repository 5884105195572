import React, { useEffect, useState } from "react";
import { arrayOf, func, shape } from "prop-types";

import BtnToggle from "../BtnToggle";
import { EmailCCSelectorV2 } from "../ActionFormHelpers";
import { useAutoPlanType } from "../../../reducers/autoPlanEdit";

import { CC_PLACEHOLDERS } from "../../constants";

const BCCField = ({ bccPeopleUuids, bccEmails, bccRoleUuids, onSelect, onRemove }) => {
  const [ isBccOn, setBcc ] = useState(false); 
  const planType = useAutoPlanType();
  const [ccType, setCCType] = useState('people');

  useEffect(() => {
    if([...bccPeopleUuids, ...bccEmails, ...bccRoleUuids].length)
    setBcc(true)
  }, [[...bccPeopleUuids, ...bccEmails, ...bccRoleUuids].length])

  return (
  <div className="">
    { !isBccOn &&
      <BtnToggle
        label='ADD BCC'
        onClick={() => setBcc(true)}
        />
    }
    {isBccOn &&
      <EmailCCSelectorV2
        planType={planType}
        label="BCC"
        name="bccType"
        type={ccType}
        placeholder={CC_PLACEHOLDERS[ccType]}
        onRadioChange={(e) => setCCType(e.target.value)}
        people={bccPeopleUuids}
        emails={bccEmails}
        roles={bccRoleUuids}
        onOptionSelect={onSelect}
        onOptionRemove={onRemove}
        />
    }
  </div>
)}

export default BCCField;

BCCField.propTypes = {
  onSelect: func.isRequired, 
  onRemove: func.isRequired,
  bccEmails: arrayOf(shape({})), 
  bccPeopleUuids: arrayOf(shape({})),  
  bccRoleUuids: arrayOf(shape({})), 
}

BCCField.defaultProps = {
  bccEmails: [], 
  bccPeopleUuids: [], 
  bccRoleUuids: []
}