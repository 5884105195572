import React from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { Dropdown } from "../../../shared/v1";
import buttons from "../../../PersonDetail/components/button.module.css";
import forms from "../../../PersonDetail/components/form.module.css";

const Reassign = (props) => {
  const {
    agentsOptions,
    assignedToUserId,
    closeModal,
    dropdownUpdater,
    reassignTasksHandler,
    submitDisabled,
    taskCount,
  } = props;
  const default_agents = [{ label: "Anyone", value: "anyone" }];

  return (
    <Modal id="reassign-task-modal" className="person-details-modal" show onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col xs={12} style={{ height: "30px" }}>
              <div style={{ fontSize: "18px" }}>
                Do you really want to reassign {taskCount} {taskCount > 1 ? "tasks" : "task"}?
              </div>
              <button type="button" className="close" style={{ top: "-24px" }}>
                <span aria-hidden="true" onClick={closeModal}>
                  ×
                </span>
                <span className="sr-only">Close</span>
              </button>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingBottom: "0px" }}>
        <Row>
          <Col xs={12} className="form-group">
            <label className={forms.formLabel}>Assign these Tasks to</label>
            <Dropdown
              isClearable={false}
              isSearchable
              name="assigned_to_user_id"
              onUpdate={dropdownUpdater}
              value={assignedToUserId}
              options={default_agents.concat(agentsOptions)}
              variant="flatBlueishGray"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          disabled={submitDisabled}
          className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue}`}
          onClick={reassignTasksHandler}
        >
          Reassign
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Reassign;
