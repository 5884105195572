/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentDotsSolidV6 = (props) => (
  <Icon {...props} name="Comment Dots Solid V6">
    <path d="M8 .972c-4.419 0-7.972 2.91-7.972 6.5 0 1.551.667 2.968 1.78 4.084C1.417 13.13.112 14.533.096 14.549a.243.243 0 0 0-.047.273.24.24 0 0 0 .227.149c2.07 0 3.597-.992 4.394-1.606a9.556 9.556 0 0 0 3.356.606c4.419 0 7.972-2.91 7.972-6.472S12.418.972 7.999.972zm-4.028 7.5c-.555 0-1-.445-1-.972s.445-1 1-1c.555 0 1 .445 1 1s-.419.972-1 .972zm4.028 0c-.555 0-.972-.445-.972-.972s.445-1 .972-1c.527 0 .972.445.972 1s-.416.972-.972.972zm3.972 0c-.555 0-1-.445-1-.972s.445-1 1-1c.555 0 1 .445 1 1s-.419.972-1 .972z" />
  </Icon>
);

export default CommentDotsSolidV6;
