import React, { Component } from 'react';

export default class TaskCount extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
    count: this.props.count || {}
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({...this.state, ...this.nextProps})
  }

  render() {
    return(
      <span className={`tw-float-right sidebar-count sidebar-tasks-count-${this.props.count_for}`}>
        {this.props.count[this.props.count_for]}
      </span>
    )
  }
}
