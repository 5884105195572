import React from "react";
import PropTypes from "prop-types";
import CustomInput from "../custom/Input";
import { customDefaultProps, customPropTypes } from "../utils";

const SearchableInput = ({ componentProps, children }) => {
  const handleMouseUp = (e) => {
    if (componentProps.selectProps?.wasSearchTextSelected?.current) {
      // If the user clicked on the input to open the menu,
      // we prevent it from changing the selection range,
      // so that the search text will always be selected at first
      e.preventDefault();

      // eslint-disable-next-line no-param-reassign
      componentProps.selectProps.wasSearchTextSelected.current = false;
    }
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomInput {...componentProps} isHidden={false} preserveStyle onMouseUp={handleMouseUp}>
      {children}
    </CustomInput>
  );
};

SearchableInput.propTypes = {
  ...customPropTypes,
  componentProps: PropTypes.shape({
    selectProps: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      wasSearchTextSelected: PropTypes.object,
    }),
  }),
};

SearchableInput.defaultProps = {
  ...customDefaultProps,
  componentProps: {
    selectProps: {
      wasSearchTextSelected: null,
    },
  },
};

export default SearchableInput;
