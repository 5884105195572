import { useEffect, useState } from "react";
import { isCancel } from "axios";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";

const transformClosingsBreakdown = (
  { year, ...rawData } = { buyer: {}, seller: {}, tenant: {}, landlord: {}, referral: {} },
) => ({
  ...rawData,
  total: Object.values(rawData).reduce(
    (c, { units = 0, volume = 0, gci = 0 }) => ({
      units: c.units + units,
      volume: c.volume + volume,
      gci: c.gci + gci,
    }),
    {
      units: 0,
      volume: 0,
      gci: 0,
    },
  ),
  year,
});

const useClosingsBreakdown = ({ agentUuid, year }) => {
  const [data, setData] = useState(transformClosingsBreakdown());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();

    caseTransformingAxios
      .get(`/api/v4/reporting/${agentUuid ? "agent" : "business"}_dashboard`, {
        params: {
          section: `${agentUuid ? "agent_" : ""}closings_breakdown`,
          selectedYear: year,
          personUuid: agentUuid,
        },
        signal: abortController.signal,
      })
      .then((res) => {
        setData(transformClosingsBreakdown(res.data));
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          setError(true);
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [agentUuid, year]);

  return { data, error, loading };
};

export default useClosingsBreakdown;
