import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SearchInput from './form_controls/SearchInput';
import SuperUserRequests from "./SuperUserRequests";

const HeaderControls = (props) => {
  const { backUrl, onAddSuperUser, onSearch, roleOptions } = props;

  const onExportCSV = () => {
    SuperUserRequests.csvExport();
  };

  return (
    <Row>
      <Col md={2}><a href={backUrl}>Back</a></Col>

      <Col md={10}>
        <div className="pull-right">
          <Button onClick={onAddSuperUser}>Add Super User</Button>

          <Button
            style={{ marginLeft: "8px" }}
            onClick={onExportCSV}
          >
            Export All Super Users
          </Button>

          <SearchInput
            onSearch={onSearch}
            options={roleOptions}
          />
        </div>
      </Col>
    </Row>
  )
}

export default HeaderControls;
  
