import React from "react";
import { Expense } from "../Expense";
import { Split } from "../Split";
import { createFinancialsSortedExpensesPointer } from "../../shared/FinancialViewHelpers";
import { getRandomNumber } from "../../../../Transactions/utils/helpers";

export const Expenses = (props) => {
  const { agentPosition, agent } = props;
  const expenses = createFinancialsSortedExpensesPointer(agent);

  return (
    <div className="transaction-gci-options">
      <div className="transaction-gci-header">
        <h3>Agent Expenses</h3>
      </div>

      <div className="transaction-gci-content">
        {expenses.map((exp, j) =>
          exp.hasOwnProperty("royalty") ? (
            <Split agentPosition={agentPosition} key={`split-${exp.id}-${exp.name}`} agentData={agent} />
          ) : (
            <Expense
              expense={exp}
              key={`exp-${exp.agent_expense_type_id * exp.value * Math.floor(getRandomNumber(0, 99999999))}`}
              keyString={`agent-additional-expense-${j}`}
            />
          ),
        )}
      </div>
    </div>
  );
};
