import React, { Component } from "react";
import TextInput from "../../shared/v2/TextInput";
import { Col } from "../../shared/v1";
import Button from "../../shared/v2/Button";
import { Plus } from "../../shared/v2/Icomoon";

const TaskDetailsInformation = ({ task, toggleInstructions, toggleNotes, handleTaskChange, taskDetails }) => (
  <Col size={12} className="tw-flex tw-flex-col tw-gap-[16px] tw-mb-[16px]">
    <TextInput
      label="Task Details (max 255 characters)"
      isRequired
      name="name"
      onChange={handleTaskChange}
      value={task.name}
      maxLength={255}
      disabled={taskDetails ? true : false}
    />

    <div className="tw-flex tw-gap-[12px]">
      {!task.showInstructions && (
        <Button onClick={toggleInstructions}>
          Add Instructions
          <Plus size="s" className="tw-ml-[4px]" />
        </Button>
      )}
      {!task.showNotes && (
        <Button onClick={toggleNotes}>
          Add Notes
          <Plus size="s" className="tw-ml-[4px]" />
        </Button>
      )}
    </div>
  </Col>
);

export default TaskDetailsInformation;
