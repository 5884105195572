import React, { useState } from "react";
import { func, number, shape, string } from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import Avatar from "@shared/v2/Avatar";
import OverlayDropdown from "@shared/OverlayDropdown";
import { ArrowUpRightFromSquareRegularV6, VerticalDots } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import FileInformation from "@shared/ModalUpload/DocumentListItem/FileInformation";

import { setDocumentIndex, toggleDocumentsSelected } from "../../../Docs/actions/creators";
import { renameDocumentAsThunk } from "../../../Docs/actions/thunks";

const UploadDoc = ({
  doc,
  context,
  handleDeleteDocument,
  onToggleDocumentsSelected,
  onSetDocumentIndex,
  onRenameDocumentAsThunk,
  idx,
}) => {
  const [isOverlayDropdownOpen, setIsOverlayDropdownOpen] = useState(false);
  const [editFilename, setEditFilename] = useState(false);

  const handleOverlayCb = (cb) => () => {
    cb();
    setIsOverlayDropdownOpen(false);
  };

  const handleRenameDocument = (docId, newName) => onRenameDocumentAsThunk(context, docId, newName);

  return (
    <div className="tw-flex tw-items-center">
      <Checkbox checked={doc.isSelected} onClick={() => onToggleDocumentsSelected(doc.id, !doc.isSelected)} />
      <div className="tw-ml-[6px]">
        <FileInformation
          context={context}
          editFilename={editFilename}
          setEditFilename={setEditFilename}
          renameDocument={handleRenameDocument}
          previewDocument={() => onSetDocumentIndex(idx)}
          document={doc}
        />
      </div>
      <div className="tw-w-[73.5px] tw-flex tw-justify-center tw-mr-[16px]">
        <Avatar alt={doc.uploadedByName} src={doc.uploadedByImageUrl} />
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-text-14d tw-text-gray-75 tw-font-[400]">
          {moment(doc.uploadedAt).format("MM/DD/YYYY")}
        </div>
        <div className="tw-text-12d tw-text-gray-50 tw-font-[400]">
          {moment(doc.uploadedAt).format("hh:mm A")}
        </div>
      </div>
      <Button
        className="tw-flex tw-items-center tw-gap-[4px] tw-ml-auto"
        schema="secondary"
        onClick={() => onSetDocumentIndex(idx)}
      >
        <ArrowUpRightFromSquareRegularV6 />
        <span>Open</span>
      </Button>
      <IconButton
        size="small"
        schema="tertiary"
        className="tw-ml-[12px]"
        onClick={() => setIsOverlayDropdownOpen(true)}
        data-cy="document-list-item-context-actions"
      >
        <VerticalDots size="l" />
      </IconButton>
      <OverlayDropdown
        isOpen={isOverlayDropdownOpen}
        onHide={() => setIsOverlayDropdownOpen(false)}
        containerClassName="tw-mt-[4px]"
        handlePreview={handleOverlayCb(() => onSetDocumentIndex(idx))}
        handleRename={handleOverlayCb(() => setEditFilename(true))}
        downloadLink={doc.expiringUrl}
        handleDelete={handleOverlayCb(handleDeleteDocument)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onToggleDocumentsSelected: (documentId, checkedStatus) =>
    dispatch(toggleDocumentsSelected("CDA-document", [documentId], checkedStatus)),
  onSetDocumentIndex: (index) => dispatch(setDocumentIndex("CDA-document", index)),
  onRenameDocumentAsThunk: (context, documentId, newName) =>
    dispatch(renameDocumentAsThunk(context, documentId, newName)),
});

export default connect(null, mapDispatchToProps)(UploadDoc);

UploadDoc.propTypes = {
  doc: shape({}),
  context: string,
  handleDeleteDocument: func.isRequired,
  onToggleDocumentsSelected: func.isRequired,
  onSetDocumentIndex: func.isRequired,
  onRenameDocumentAsThunk: func.isRequired,
  idx: number,
};

UploadDoc.defaultProps = {
  doc: {},
  context: "",
  idx: null,
};
