/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowLeftSolidV6 = (props) => (
  <Icon {...props} name="Arrow Left Solid V6">
    <path d="M14.972 8c0 .553-.419 1-.972 1H4.416l3.294 3.294A.999.999 0 0 1 7.001 14a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414L4.416 7H14c.553 0 .972.447.972 1z" />
  </Icon>
);

export default ArrowLeftSolidV6;
