import React from "react";
import PropTypes from "prop-types";

const OverviewCard = ({ action, centered, description, title, value }) => (
  <div
    className={`tw-flex-1 tw-flex tw-flex-col ${centered ? "tw-items-center" : ""} tw-gap-[12px] tw-px-[8px]`}
  >
    <div className="tw-text-28d tw-font-semibold tw-text-theme-primary-alternative">{value}</div>
    <div className="tw-text-14d tw-font-bold">{title}</div>
    <hr className="tw-m-0 tw-w-full tw-border-solid" />
    <div className="tw-text-12d tw-font-semibold tw-text-gray-50">{description}</div>
    {action}
  </div>
);

OverviewCard.propTypes = {
  action: PropTypes.node,
  centered: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
};

OverviewCard.defaultProps = {
  action: null,
  centered: false,
  description: "",
  title: "",
  value: "",
};

export default OverviewCard;
