import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import IconButton from "@shared/v2/IconButton";
import Typography from "@shared/v2/Typography";
import { LockSolidV6, PhoneSolidV6 } from "@shared/v2/Icomoon";
import TypeCell from "../../../Transactions/components/shared/TypeCell";
import Status from "../../../LeadIndex/components/Status";
import { setLeadUuid } from "../../../reducers/dialerReducer/dialerReducer";
import { TD, TR } from "../BusinessDashboard/Table/Table";

const STATUS_OPTIONS = {
  new: "new",
  unqual: "unqualified",
  hot: "hot",
  watch: "watch",
  nurt: "nurture",
  past: "past-client",
  inactiv: "inactive",
  trash: "trash",
  arch: "archived",
  pending: "pending",
};

const LeadRow = ({
  lead: {
    canBeAccessedByCurrentUser,
    fullName,
    hasPrimaryPhoneNumber,
    intent,
    lastInteractionAt,
    lastInteractionType,
    leadId,
    status,
    uuid,
  },
  hasAgent,
}) => {
  const dispatch = useDispatch();
  const person = useMemo(
    () => ({ person_lead_id: leadId, status_options: STATUS_OPTIONS, person: { status: status || "new" } }),
    [leadId, status],
  );

  return (
    <TR className="tw-border-x-0 hover:tw-bg-neutral-gray-2.5 active:tw-bg-gray-5 active:tw-duration-[50ms] tw-transition-colors tw-ease-out">
      <TD>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <Typography variant="body-m">{fullName}</Typography>{" "}
          {!canBeAccessedByCurrentUser && <LockSolidV6 className="tw-text-gray-50" />}
        </div>
      </TD>
      <TD>
        <div className="tw-flex tw-justify-center tw-gap-[4px]">
          {(intent === "seller" || intent === "seller/buyer") && <TypeCell value="seller" />}
          {(intent === "buyer" || intent === "seller/buyer") && <TypeCell value="buyer" />}
        </div>
      </TD>
      <TD className="tw-text-center">
        <Typography variant="body-m" className="!tw-text-gray-50">
          {hasAgent === true ? "Yes" : ""}
          {hasAgent === false ? "No" : ""}
          {hasAgent === undefined ? "—" : ""}
        </Typography>
      </TD>
      <TD className="tw-text-center">
        <Status disabled={!canBeAccessedByCurrentUser} person={person} />
      </TD>
      <TD>
        {lastInteractionType && (
          <Typography variant="body-m" className="!tw-text-gray-50">
            {startCase(lastInteractionType)} Logged{" "}
            {lastInteractionAt ? moment(lastInteractionAt).fromNow() : ""}
          </Typography>
        )}
      </TD>
      <TD className="tw-text-right">
        <IconButton
          schema="secondary"
          disabled={!canBeAccessedByCurrentUser || !hasPrimaryPhoneNumber}
          onClick={() => dispatch(setLeadUuid(uuid))}
        >
          <PhoneSolidV6 />
        </IconButton>
      </TD>
    </TR>
  );
};

LeadRow.propTypes = {
  lead: PropTypes.shape().isRequired,
  hasAgent: PropTypes.bool,
};

LeadRow.defaultProps = {
  hasAgent: undefined,
};

export default LeadRow;
