import React from "react";
import Dropdown from "../../../../shared/v2/Dropdown";

const AvatarOption = ({ componentProps, data, children }) => {
  const src = data?.image_url ?? null;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dropdown.Option {...componentProps} className="!tw-flex tw-gap-[8px]" preserveStyle>
      <div
        className="tw-h-8 tw-w-8 tw-rounded-full tw-mr-2 tw-bg-center"
        style={{ backgroundImage: `url('${src}')` }}
      />
      {children}
    </Dropdown.Option>
  );
};
export default AvatarOption;
