import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import { camelCase } from "lodash";
import { createHumps } from "../shared/v2/caseTransformingAxios";

import addMarketReport from "../reducers/addMarketReportReducer";
import autoPlans from "../reducers/autoPlans";
import contactsReducer from "../reducers/contactsReducer";
import interactionTemplates from "../reducers/interactionTemplatesReducer";
import businessSettings from "../reducers/businessSettingsReducer";
import layout from "../reducers/layoutReducer";
import leadIndex from "../reducers/leadIndexReducer";
import messaging, { initialState as messagingInitialState } from "../reducers/messagingReducer";
import personDetail from "../reducers/personalDetailReducer/personDetailReducer";
import phoneSettings from "../reducers/layoutReducer/phoneSettingsReducer";
import reports from "../reducers/reportsReducer";
import tdpThumbnailSelector from "../reducers/tdpThumbnailSelectorReducer";
import tdpAutoPlansReducer, { defaultState as autoPlansDefaultState } from "../reducers/tdpAutoPlansReducer";
import tdpDatesReducer, { defaultState as dateDefaultState } from "../reducers/tdpDatesReducer";
import tdpMarketingReducer, { defaultState as marketingDefaultState } from "../reducers/tdpMarketingReducer";
import triggers from "../reducers/triggers";
import dashboard from "../reducers/dashboardReducer/dashboardReducer";
import userSettings from "../reducers/userSettingsReducer";
import { tdpDetailsReducer, defaultState as detailsDefaultState } from "../reducers/tdpDetailsReducer";
import { tdpDocumentsReducer, defaultState as documentsDefaultState } from "../reducers/tdpDocumentsReducer";
import {
  tdpFinancialsReducer,
  defaultState as financialsDefaultState,
} from "../reducers/tdpFinancialsReducer";
import tdpTimelineReducer, { defaultState as timelineDefaultState } from "../reducers/tdpTimelineReducer";
import { tdpTasksReducer, defaultState as tasksDefaultState } from "../reducers/tdpTasksReducer";
import { tdpFeedbackReducer, defaultState as feedbackDefaultState } from "../reducers/tdpFeedbackReducer";
import { tdpPeopleReducer, defaultState as peopleDefaultState } from "../reducers/tdpPeopleReducer";
import {
  tdpInteractionsReducer,
  defaultState as interactionsDefaultState,
} from "../reducers/tdpInteractionsReducer";
import tdpNotesReducer, { defaultState as notesDefaultState } from "../reducers/tdpNotesReducer";
import partnerSearch, { defaultState as partnerSearchState } from "../reducers/partnerSearchReducer";
import dialer from "../reducers/dialerReducer/dialerReducer";
import website, { initialState as websiteState } from "../reducers/websiteReducer/websiteReducer";
import contactMerge from "../reducers/contactMergeReducer/contactMergeReducer";
import snackbar from "../reducers/snackbarReducer";

// TODO: utilize currentUser from layoutStore
const getTdpPreloadedState = (tdp) => {
  if (!tdp) return {};
  return {
    tdpAutoPlansReducer: {
      ...autoPlansDefaultState,
      uuid: tdp.uuid,
    },
    tdpDatesReducer: {
      ...dateDefaultState,
      uuid: tdp.uuid,
    },
    tdpMarketingReducer: {
      ...marketingDefaultState,
      uuid: tdp.uuid,
    },
    tdpDetailsReducer: {
      ...detailsDefaultState,
      uuid: tdp.uuid,
      awsDirectPost: tdp.awsDirectPost,
      currentUserId: tdp.currentUserId,
      currentUser: tdp.currentUser,
      isAdmin: tdp.isAdmin,
      slug: tdp.slug,
      person: tdp.person,
      smsEnabled: tdp.smsEnabled,
      accountUUID: tdp.accountUUID,
      messagingServiceUrl: tdp.messagingServiceUrl,
    },
    tdpDocumentsReducer: {
      ...documentsDefaultState,
      uuid: tdp.uuid,
    },
    tdpFinancialsReducer: {
      ...financialsDefaultState,
      transactionSlug: tdp.slug,
    },
    tdpTimelineReducer: {
      ...timelineDefaultState,
      uuid: tdp.uuid,
    },
    tdpTasksReducer: {
      ...tasksDefaultState,
      uuid: tdp.uuid,
    },
    tdpFeedbackReducer: {
      ...feedbackDefaultState,
      uuid: tdp.uuid,
    },
    tdpPeopleReducer: {
      ...peopleDefaultState,
      uuid: tdp.uuid,
      agents: tdp.agents,
    },
    tdpInteractionsReducer: {
      ...interactionsDefaultState,
      uuid: tdp.uuid,
      managedNumber: tdp.managedNumber,
      userNumber: tdp.userNumber,
    },
    tdpNotesReducer: {
      ...notesDefaultState,
      notableId: tdp.notableId,
    },
  };
};

const layoutStore = ({ tdp, ...preloadedState }, railsContext) =>
  reduxConfigureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
    preloadedState: {
      ...createHumps(camelCase)({
        ...preloadedState,
        // combined server + client initial state
        messaging: { ...messagingInitialState, ...preloadedState.messaging },
        partnerSearch: { ...partnerSearchState, ...preloadedState.partner_search },
        website: { ...websiteState, ...preloadedState.website },
      }),
      ...getTdpPreloadedState(tdp),
    },
    reducer: {
      addMarketReport,
      autoPlans,
      businessSettings,
      contactMerge,
      contactsReducer,
      dashboard,
      dialer,
      interactionTemplates,
      layout,
      leadIndex,
      messaging,
      partnerSearch,
      personDetail,
      phoneSettings,
      reports,
      snackbar,
      triggers,
      tdpAutoPlansReducer,
      tdpDatesReducer,
      tdpDetailsReducer,
      tdpDocumentsReducer,
      tdpFeedbackReducer,
      tdpFinancialsReducer,
      tdpInteractionsReducer,
      tdpMarketingReducer,
      triggers,
      tdpNotesReducer,
      tdpPeopleReducer,
      tdpTasksReducer,
      tdpThumbnailSelector,
      tdpTimelineReducer,
      userSettings,
      website,
    },
  });

export default layoutStore;
