import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

export default class ExpenseFinder extends Component {
  state = {
    email: '',
    fetchingData: false,
    forBrokerageCap: true,
    slug: ''
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ fetchingData: true })
    const { email, forBrokerageCap, slug } = this.state
    const { accountId, setTransactionResults } = this.props

    axios.get(`/support/accounts/${accountId}/list_transaction_financials`, { params: { email, forBrokerageCap, slug } })
      .then((res) => this.setState({ fetchingData: false }, () => setTransactionResults(res.data)))
  }

  radioButtonClass = (isSelected) => isSelected ? "fa fa-dot-circle-o brivity-blue" : "brivity-grey-text fa fa-circle-o";

  toggleButtons = () => this.setState((prevState) => ({ forBrokerageCap: !prevState.forBrokerageCap }))

  render() {
    const { email, fetchingData, forBrokerageCap, slug } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <Row>
          <Col xs={6}>
            <div className="form-group">
              <label htmlFor="email">Login User Email</label>
              <input className="form-control" name="email" value={email} onChange={this.handleChange} />
            </div>
          </Col>
          <Col xs={6}>
            <div className="form-group">
              <label htmlFor="slug">Listing Slug</label>
              <input className="form-control" name="slug" value={slug} onChange={this.handleChange} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="form-group">
            <div>
              <div>Issue with:</div>
              <div style={{ width: "190px", display: "inline-block" }}>
                <span className={this.radioButtonClass(forBrokerageCap)} onClick={() => this.toggleButtons()} style={{ marginRight: "8px" }} />
                <span>Brokerage Split Amounts</span>
              </div>
              <div style={{ width: "140px", display: "inline-block" }}>
                <span className={this.radioButtonClass(!forBrokerageCap)} onClick={() => this.toggleButtons()} style={{ marginRight: "8px" }} />
                <span>Royalty Amounts</span>
              </div>
            </div>
          </Col>
        </Row>
        <button className="btn btn-info" disabled={fetchingData}>Submit</button>
      </form>
    )
  }
}
