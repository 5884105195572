import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import * as creators from "../../actions/creators";
import { callApi } from "./helpers";
import useGetGeoLocation from "./hookHelpers";

const mapStateToProps = (state) => ({
  primaryAgentUuid: state.addMarketReport.form1.primary_agent_uuid,
  mlsCountry: state.addMarketReport.mls_country,
  isDrawing: state.addMarketReport.isDrawing,
  isEdit: state.addMarketReport.savedMarketReport?.id,
});

const getColors = (state) =>
  state?.selectProps?.inputValue || state.hasValue ? "#666666" : `${state.isFocused ? "#59C4C4" : "#C2C2C2"}`;

const SearchAreaInput = ({
  isDrawing,
  setDrawing,
  primaryAgentUuid,
  defaultOptions,
  value,
  onChange,
  mlsCountry,
  setLatLon,
  setFormData,
  isEdit,
  currentName,
}) => {
  const formattedDefaultOptions = defaultOptions
    .filter((o) => o > "")
    .map((o) => ({
      label: o,
      value: o,
    }));
  const defaultInput = isEdit ? null : formattedDefaultOptions[0]?.label;
  const [inputValue, setInputValue] = useState(value ? value?.label : defaultInput);
  useGetGeoLocation({ value, setFormData, setLatLon, primaryAgentUuid, currentName });

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      callApi(inputText, mlsCountry, formattedDefaultOptions, primaryAgentUuid).then((resp) =>
        callback(resp),
      );
    }, 1000),
    [],
  );
  const hasError = value == null;
  return (
    <div data-cy="search-area-wrapper" className="tw-mb-8">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">
        Search Area
        <sup className={`tw-text-red-500 tw-ml-1 ${hasError ? "" : "tw-invisible"}`}>*</sup>
      </span>
      <div className="tw-relative">
        {isDrawing ? (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setDrawing(false);
            }}
            className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-rounded-lg tw-bg-tinted-gray-50 tw-border-gray-30 tw-border-solid tw-border tw-shadow-0 tw-z-50 tw-pl-4 tw-pr-6"
          >
            <span className="tw-flex-1 tw-text-left">Custom Area</span>
            <FontAwesomeIcon icon={faTimes} className="tw-h-[16px] tw-w-[16px]" />
          </button>
        ) : null}
        <AsyncSelect
          cacheOptions
          defaultOptions
          defaultMenuIsOpen={!isEdit && inputValue !== undefined && !value}
          onChange={onChange}
          value={value}
          onInputChange={setInputValue} // allows for actually typing
          inputValue={inputValue} // allows you continue where you left off
          blurInputOnSelect
          placeholder="Search Address, Neighborhood, City, or Zip Code"
          loadOptions={loadOptions}
          backspaceRemovesValue
          components={{
            IndicatorSeparator: null,
            // eslint-disable-next-line
            DropdownIndicator: ({ clearValue, hasValue, selectProps, ...props }) => (
              <components.DropdownIndicator {...props}>
                <button
                  aria-label="clear"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setInputValue("");
                    clearValue();
                  }}
                  className="tw-border-0 tw-bg-transparent tw-padding-0"
                >
                  <FontAwesomeIcon
                    icon={selectProps.inputValue || hasValue ? faTimes : faSearch}
                    className="tw-h-[16px] tw-w-[16px]"
                  />
                </button>
              </components.DropdownIndicator>
            ),
          }}
          styles={{
            input: (styles) => ({ ...styles, color: "#666666" }),
            singleValue: (styles) => ({ ...styles, color: "#666666" }),
            control: (styles, state) => ({
              ...styles,
              background: "#F5F7F7",
              color: "#666666",
              boxShadow: `0 0 2px ${state.isFocused ? "#59C4C4" : "#C2C2C2"}`,
              borderColor: state.isFocused ? "#59C4C4" : "#C2C2C2",
              "&:hover": {
                borderColor: "#59C4C4",
              },
            }),
            dropdownIndicator: (styles, state) => ({
              ...styles,
              color: getColors(state),
              "&:focus": {
                color: getColors(state),
              },
            }),
            group: (styles) => ({ ...styles, padding: 0, margin: 0 }),
            groupHeading: () => ({
              color: "#666666",
              backgroundColor: "#F5F7F7",
              padding: "6px 12px",
            }),
            menuList: (styles) => ({ ...styles, maxHeight: "200px" }),
            option: (styles, state) => ({
              ...styles,
              background: state.isSelected ? "#59C4C4" : `${state.isFocused ? "#cccccc" : "#fff"}`,
            }),
            placeholder: (styles) => ({ ...styles, whiteSpace: "nowrap", color: "#666666" }),
          }}
        />
      </div>
      <span className="tw-text-[12px] tw-text-gray-50">
        Search by Address, Neighborhood, County, City, or Zip Code
      </span>
    </div>
  );
};

export default connect(mapStateToProps, creators)(SearchAreaInput);

SearchAreaInput.propTypes = {
  isDrawing: PropTypes.bool,
  primaryAgentUuid: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  mlsCountry: PropTypes.string,
  setDrawing: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  selectProps: PropTypes.shape({ inputValue: PropTypes.string }),
  setLatLon: PropTypes.func,
  setFormData: PropTypes.func,
  isEdit: PropTypes.bool,
  currentName: PropTypes.string,
};

SearchAreaInput.defaultProps = {
  isDrawing: false,
  primaryAgentUuid: null,
  defaultOptions: [],
  mlsCountry: null,
  value: null,
  setDrawing: () => {},
  onChange: () => {},
  selectProps: null,
  setLatLon: null,
  setFormData: null,
  isEdit: false,
  currentName: null,
};
