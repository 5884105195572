import React from "react";
import { Button, Row, Col } from "../../shared/v1";
import Milestone from "./Milestone";
import {
  renderNewMilestoneModal,
  renderEditMilestoneModal,
  renderDeleteMilestoneModal,
} from "./ModalHelpers";
import "./Milestones.scss";

class Milestones extends React.Component {
  isMilestoneModalOpen() {
    const { isNewMilestoneModalOpen, isEditMilestoneModalOpen, isDeleteMilestoneModalOpen } = this.props;
    return isNewMilestoneModalOpen || isEditMilestoneModalOpen || isDeleteMilestoneModalOpen;
  }

  renderModal() {
    const { isNewMilestoneModalOpen, isEditMilestoneModalOpen, isDeleteMilestoneModalOpen } = this.props;

    if (isNewMilestoneModalOpen) {
      return renderNewMilestoneModal(this.props);
    } else if (isDeleteMilestoneModalOpen) {
      return renderDeleteMilestoneModal(this.props);
    } else if (isEditMilestoneModalOpen) {
      return renderEditMilestoneModal(this.props);
    } else {
      return null;
    }
  }

  render() {
    const {
      milestones,
      showNewMilestoneModal,
      showEditMilestoneModal,
      showDeleteMilestoneModal,
      projectsEnabled,
    } = this.props;
    const person_milestones =
      milestones && milestones.filter((milestone) => milestone.milestonable_type == "Person");
    const listing_milestones =
      milestones && milestones.filter((milestone) => milestone.milestonable_type == "Listing");
    const project_milestones =
      milestones && milestones.filter((milestone) => milestone.milestonable_type == "Project");

    return (
      <div className="brivity-panel milestones-index">
        <div className="brivity-panel-header">
          Dates & Milestones
          <div className="action new-custom-date-action">
            <Button variant="info" invert className="tw-float-right" onClick={() => showNewMilestoneModal()}>
              <i className="fa fa-plus-square tw-text-brand-info custom-date-new-icon tw-align-text-top tw-pr-[0.1em]" />
              New Custom Date
            </Button>
          </div>
        </div>
        <div className="brivity-panel-body standard-table">
          <Row className="table-header-row">
            <Col size={4} className="milestone-table-header-row p-r-50">
              <Row>
                <Col size={6}>Person Dates</Col>
                <Col size={2} className="milestone-table-text-align-center">
                  Usage
                </Col>
                <Col size={2} className="milestone-table-text-align-right">
                  Edit
                </Col>
              </Row>
            </Col>
            <Col size={4} className="milestone-table-header-row p-r-50">
              <Row>
                <Col size={2} className="milestone-table-text-align-left">
                  Visible
                </Col>
                <Col size={6}>Transaction Dates</Col>
                <Col size={2} className="milestone-table-text-align-center">
                  Usage
                </Col>
                <Col size={2} className="milestone-table-text-align-right">
                  Edit
                </Col>
              </Row>
            </Col>
            {projectsEnabled && (
              <Col size={4} className="milestone-table-header-row">
                <Row>
                  <Col size={6}>Project Dates</Col>
                  <Col size={2} className="milestone-table-text-align-center">
                    Usage
                  </Col>
                  <Col size={2} className="milestone-table-text-align-right">
                    Edit
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row>
            <Col size={4} className="p-r-50">
              {person_milestones &&
                person_milestones.map((milestone) => (
                  <Milestone
                    key={milestone.id}
                    milestone={milestone}
                    showDeleteMilestoneModal={showDeleteMilestoneModal}
                    showEditMilestoneModal={showEditMilestoneModal}
                    milestonableMilestonesUsageCounts={this.props.milestonableMilestonesUsageCounts}
                  />
                ))}
            </Col>
            <Col size={4} className="p-r-50">
              {listing_milestones &&
                listing_milestones.map((milestone) => (
                  <Milestone
                    key={milestone.id}
                    milestone={milestone}
                    showDeleteMilestoneModal={showDeleteMilestoneModal}
                    showEditMilestoneModal={showEditMilestoneModal}
                    milestonableMilestonesUsageCounts={this.props.milestonableMilestonesUsageCounts}
                  />
                ))}
            </Col>
            <Col size={4}>
              {projectsEnabled &&
                project_milestones &&
                project_milestones.map((milestone) => (
                  <Milestone
                    key={milestone.id}
                    milestone={milestone}
                    showDeleteMilestoneModal={showDeleteMilestoneModal}
                    showEditMilestoneModal={showEditMilestoneModal}
                    milestonableMilestonesUsageCounts={this.props.milestonableMilestonesUsageCounts}
                  />
                ))}
            </Col>
          </Row>
          {this.isMilestoneModalOpen() && this.renderModal()}
        </div>
      </div>
    );
  }
}

export default Milestones;
