/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const StopSolidV6 = (props) => (
  <Icon {...props} name="Stop Solid V6">
    <path d="M14 4v7.972a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2z" />
  </Icon>
);

export default StopSolidV6;
