/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EyeSlashSolidV6 = (props) => (
  <Icon {...props} name="Eye Slash Solid V6">
    <path d="M3.768 3.919C4.875 3.057 6.295 2.4 8 2.4c2.02 0 3.638.921 4.815 2.015 1.17 1.085 1.953 2.362 2.323 3.277a.8.8 0 0 1 0 .615c-.335.808-1.008 1.945-1.998 2.96l2.63 2.06a.601.601 0 0 1-.74.945L.23 2.672a.599.599 0 1 1 .74-.944l2.797 2.191zm1.81 1.419 2.257 1.77c.105-.212.165-.452.165-.73 0-.265-.097-.535-.21-.768.07-.008.14-.032.21-.032a2.4 2.4 0 0 1 2.4 2.4c0 .365-.072.69-.222.985l.987.755a3.6 3.6 0 0 0 .435-1.74c0-1.965-1.612-3.6-3.6-3.6-.932 0-1.785.378-2.422.96zM8 13.6c-2.02 0-3.637-.92-4.815-2.015C2.015 10.477 1.233 9.2.861 8.307a.8.8 0 0 1 0-.615 9.511 9.511 0 0 1 1.216-2.055l2.358 1.858A3.606 3.606 0 0 0 8 11.6c.468 0 .915-.09 1.325-.253l1.83 1.44A6.57 6.57 0 0 1 8 13.6z" />
  </Icon>
);

export default EyeSlashSolidV6;
