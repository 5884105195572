import React from "react";
import { Col, Row } from "react-bootstrap";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import DragHandle from "../shared/DragHandle";
import ExpenseInput from "../shared/ExpenseInput";
import { formatExpense, fullRecalculation } from "../shared/FinancialViewHelpers";
import { setFinancial } from "../../actions/transaction";
import { connect } from "react-redux";
import format from "../../../shared/formatters";

const mapDispatchToProps = (dispatch) => ({
  setFinancial: (listingData) => dispatch(setFinancial(listingData)),
});

const SortableItem = connect(
  null,
  mapDispatchToProps,
)(
  sortableElement(
    ({
      key,
      value,
      sortIndex,
      listingData,
      setFinancialData,
      setFinancial,
    }) => {
      const { name } = value;

      return (
        <div className="transaction-gci-content" key={key}>
          <Row>
            <DragHandle />
            <Col xs={2}>
              {(value.expense_amount || value.type === "expense") && (
                <ExpenseInput
                  label={name}
                  value={value.value}
                  amount={value.expense_amount}
                  isPercentage={!!value.percentage}
                  name={`addtionalTransactionPercentage${sortIndex}`}
                  onValueUpdate={(e) => {
                    if (!!value.percentage) {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        listingData.transaction_income.additionalIncome[sortIndex].value = e.target.value;
                        fullRecalculation(listingData, setFinancial);
                      }
                    } else {
                      listingData.transaction_income.additionalIncome[sortIndex].value = e.target.value;
                      fullRecalculation(listingData, setFinancial);
                    }
                  }}
                  onRadioUpdate={(e) => {
                    if (e.target.value === "true" && value.value > 100) {
                      value.value = "";
                    }
                    value.percentage = e.target.value === "true";
                    fullRecalculation(listingData, setFinancial);
                  }}
                />
              )}
              {(value.revenue_amount || value.type === "revenue") && (
                <ExpenseInput
                  label={name}
                  value={value.value}
                  amount={value.revenue_amount}
                  isPercentage={!!value.percentage}
                  name={`addtionalTransactionPercentage${sortIndex}`}
                  onValueUpdate={(e) => {
                    if (!!value.percentage) {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        listingData.transaction_income.additionalIncome[sortIndex].value = e.target.value;
                        fullRecalculation(listingData, setFinancial);
                      }
                    } else {
                      listingData.transaction_income.additionalIncome[sortIndex].value = e.target.value;
                      fullRecalculation(listingData, setFinancial);
                    }
                  }}
                  onRadioUpdate={(e) => {
                    if (e.target.value === "true" && value.value > 100) {
                      value.value = "";
                    }
                    value.percentage = e.target.value === "true";
                    fullRecalculation(listingData, setFinancial);
                  }}
                />
              )}
            </Col>

            <Col xs={4}>
              <div className="form-group">
                <label>Notes</label>
                <textarea
                  className="description form-control"
                  name={`addtionalTransactionNote${sortIndex}`}
                  placeholder=""
                  value={value.notes || ""}
                  onChange={(e) => {
                    value.notes = e.target.value;
                    setFinancialData(listingData);
                  }}
                />
              </div>
            </Col>

            <Col xs={1} />

            <Col xs={2} className="text-right">
              {value.expense_amount && <span>{formatExpense(format.currency(value.expense_amount))}</span>}
              {value.revenue_amount && <span>{format.currency(value.revenue_amount)}</span>}
            </Col>

            <Col xs={2} className="text-right">
              <span>{format.currency(value.line_total)}</span>
            </Col>

            <div className="delete-expense">
              <a
                onClick={() => {
                  listingData.transaction_income.additionalIncome[sortIndex]._destroy = "1";

                  let position = 0;
                  listingData.transaction_income.additionalIncome.map((obj) => {
                    if (obj._destroy) {
                      if (obj.position) {
                        delete obj["position"];
                      }
                    } else {
                      obj.position = position + 1;
                      position = position + 1;
                    }
                  });

                  listingData.transaction_income.custom_transaction_expenses_attributes = [];
                  listingData.transaction_income.custom_transaction_revenues_attributes = [];
                  listingData.transaction_income.additionalIncome.forEach((income) => {
                    if (income.type === "expense") {
                      listingData.transaction_income.custom_transaction_expenses_attributes.push(income);
                    }
                    if (income.type === "revenue") {
                      listingData.transaction_income.custom_transaction_revenues_attributes.push(income);
                    }
                  });
                  fullRecalculation(listingData, setFinancial);
                }}
              >
                <i className="fa fa-times" />
              </a>
            </div>
          </Row>
        </div>
      );
    },
  ),
);

const SortableContainer = sortableContainer(
  ({ items, listingData, setFinancialData, recalculate_transaction_gci_and_team_member_net_incomes }) => {
    return (
      <div>
        {[...items].map(
          (value, index) =>
            !value._destroy && (
              <SortableItem
                key={`TransactAddInc${value.id ? value.id : value.subKey}`}
                index={index}
                value={value}
                sortIndex={index}
                listingData={listingData}
                setFinancialData={setFinancialData}
                recalculate_transaction_gci_and_team_member_net_incomes={
                  recalculate_transaction_gci_and_team_member_net_incomes
                }
              />
          )
        ).filter(item => !!item)}
      </div>
    );
  },
);

export default SortableContainer;
