/* eslint-disable no-param-reassign */
import React from "react";
import { components } from "react-select";
import PropTypes from "prop-types";

const GroupHeading = ({ className, preserveStyle, children, ...otherProps }) => {
  if (!preserveStyle) {
    // So that the default styles aren't applied, for full customization
    otherProps.getStyles = () => ({});
  }

  return (
    <components.GroupHeading {...otherProps} className={className}>
      {children}
    </components.GroupHeading>
  );
};

GroupHeading.propTypes = {
  className: PropTypes.string,
  preserveStyle: PropTypes.bool,
  children: PropTypes.node,
};

GroupHeading.defaultProps = {
  className: "",
  preserveStyle: false,
  children: null,
};

export default GroupHeading;
