import React from "react";
import getContactName from "./getContactName";
import Skeleton from "./Skeleton";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";

const TimelineSmsHardUntextableEvent = ({ event }) => {
  const timelineFields = event.timeline_fields;
  const { lead, lead_phone_number, author } = timelineFields;
  const phone = viewPhoneFromFlat({ value: lead_phone_number });
  const leadName = getContactName(lead.first_name, lead.last_name);
  const autoPlanName = author.auto_plan_name;

  return (
    <Skeleton
      iconClassName="fa6 fa6-user"
      title={
        <span className="tw-font-bold">
          Failed to send text to {leadName} for {autoPlanName}
        </span>
      }
      timestamp={event.interacted_at_absolute}
      body={
        <p>
          {phone.formattedNumber} is unable to receive text messages. It may be a landline or VOIP number.
          Change the number's text status to resume sending texts.
        </p>
      }
    />
  );
};

export default TimelineSmsHardUntextableEvent;
