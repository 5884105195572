import React from "react";
import PropTypes from "prop-types";

class AssignedPlanRow extends React.Component {
  updatePlanState(state) {
    const { onUpdate, assignedPlan } = this.props;
    const { id } = assignedPlan;
    const form = { state };
    onUpdate(`/assigned_plans/${id}`, form);
  }

  render() {
    const { assignedPlan, onShowPlanModal, onShowDeleteModal } = this.props;

    return (
      <div className="row assigned-plan-row">
        <div className="col-xs-2 ellipsify">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span className="pseudo-link underline" onClick={() => onShowPlanModal(assignedPlan)}>
            {assignedPlan.name}
          </span>
        </div>
        <div className={`col-xs-2 ellipsify state ${assignedPlan.state}`} />
        <div className="col-xs-2 ellipsify">
          {assignedPlan.total_completed_actions} / {assignedPlan.assigned_actions_count} completed
        </div>
        <div className="col-xs-5 ellipsify">
          {assignedPlan.state !== "deleted" && assignedPlan.next_action_description}
        </div>
        <div className="col-xs-1 ellipsify">
          <div className="applied-plan-actions">
            {assignedPlan.state === "paused" && (
              <div className="inline-item pseudo-link">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <i className="fa fa-play" onClick={this.updatePlanState.bind(this, "started")} />
              </div>
            )}
            {assignedPlan.state === "started" && (
              <div className="inline-item pseudo-link">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <i className="fa fa-pause" onClick={this.updatePlanState.bind(this, "paused")} />
              </div>
            )}
            {assignedPlan.state !== "deleted" && assignedPlan.state !== "completed" && (
              <div className="inline-item pseudo-link">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <i className="fa fa-trash" onClick={() => onShowDeleteModal(assignedPlan)} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AssignedPlanRow;

AssignedPlanRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlan: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onShowPlanModal: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
};
