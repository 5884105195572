import React from "react";
import ErrorBanner from "@shared/v2/ErrorBanner";
import IconButton from "@shared/v2/IconButton";
import TabBar from "@shared/v2/TabBar";
import Tab from "@shared/v2/Tab";
import FilterPill from "@shared/v2/FilterPill";
import { PenToSquareSolidV6 } from "@shared/v2/Icomoon";
import ConversationMessages from "./ConversationMessages";
import FilterAgentsList from "./FilterAgentsList";
import Button from "../../shared/v2/Button";
import useMessaging from "../hooks/useMessaging";
import Conversation from "./Conversation";
import Loading from "../../Goals/components/Loader";
import { getEmptyConversation } from "../helpers/defaults";
import { useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import useUnreadCounts from "../hooks/useUnreadCounts";
import { useSelectedAgentFilters } from "../../reducers/messagingReducer/selectors";

const Inbox = () => {
  const featureFlags = useFeatureFlags();
  const { aiUnreadCount, allUnreadCount, groupUnreadCount } = useUnreadCounts();
  const isAllSelected = useSelectedAgentFilters()[0] === "all";
  const {
    actions,
    conversations,
    currentTab,
    currentUser,
    focusedConversation,
    errors,
    hasMore,
    loadingConversations,
    hideAi,
    unreadOnly,
    aiFilters,
  } = useMessaging();
  const emptyConversations = focusedConversation.uuid !== "temp" && conversations?.length === 0;

  return (
    <div className="tw-bg-white tw-w-full tw-max-w-[1640px] tw-mx-auto tw-h-full tw-flex tw-flex-col">
      <div className="tw-flex tw-justify-between tw-items-center tw-p-[16px] tw-gap-[8px]">
        <h2 className="tw-font-normal tw-m-0 tw-whitespace-nowrap">Message Center</h2>
        {currentUser.account.isAdmin && <FilterAgentsList />}
      </div>
      <div id="content" className="tw-flex-1 tw-flex tw-flex-col tw-overflow-hidden">
        <div className="tw-mx-[16px]">
          <ErrorBanner errors={errors} />
        </div>

        <TabBar isPadded>
          <Tab
            label={
              <div className="tw-flex tw-gap-[8px] tw-items-center">
                All
                {allUnreadCount > 0 && (
                  <div className="tw-flex tw-justify-center tw-items-center tw-px-[4px] tw-min-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-rounded-full">
                    {allUnreadCount > 99 ? "99+" : allUnreadCount}
                  </div>
                )}
              </div>
            }
            selected={currentTab === "all"}
            onClick={() => actions.setTab("all")}
          />
          {featureFlags.groupSms && (
            <Tab
              label={
                <div className="tw-flex tw-gap-[8px] tw-items-center">
                  Group
                  {groupUnreadCount > 0 && (
                    <div className="tw-flex tw-justify-center tw-items-center tw-px-[4px] tw-min-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-rounded-full">
                      {groupUnreadCount > 99 ? "99+" : groupUnreadCount}
                    </div>
                  )}
                </div>
              }
              selected={currentTab === "group"}
              onClick={() => actions.setTab("group")}
            />
          )}
          {featureFlags.gabbiAi && (
            <Tab
              label={
                <div className="tw-flex tw-gap-[8px] tw-items-center">
                  AI
                  {aiUnreadCount > 0 && (
                    <div className="tw-flex tw-justify-center tw-items-center tw-px-[4px] tw-min-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-rounded-full">
                      {aiUnreadCount > 99 ? "99+" : aiUnreadCount}
                    </div>
                  )}
                </div>
              }
              selected={currentTab === "ai"}
              onClick={() => actions.setTab("ai")}
            />
          )}
        </TabBar>

        <div className="tw-flex-1 tw-flex tw-overflow-y-hidden tw-overflow-x-auto">
          <div className="tw-flex tw-flex-col tw-flex-1 tw-min-w-[240px] tw-max-w-[640px] tw-border-solid tw-border-y-0 tw-border-l-0 tw-border-r-[2px] tw-border-gray-10 !tw-px-0">
            <div className="tw-flex tw-justify-between tw-items-center tw-py-[16px] tw-px-[12px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-gray-5">
              <div className="tw-relative tw-flex-1 tw-flex tw-gap-[8px]">
                {currentTab !== "ai" &&
                  (!currentUser.account.isAdmin || (currentUser.account.isAdmin && !isAllSelected)) && (
                    <FilterPill onClick={() => actions.setUnreadOnly(!unreadOnly)} selected={unreadOnly}>
                      Unread Only
                    </FilterPill>
                  )}
                {featureFlags.gabbiAi && currentTab === "all" && (
                  <FilterPill onClick={() => actions.setHideAi(!hideAi)} selected={hideAi}>
                    Hide AI
                  </FilterPill>
                )}
                {currentTab === "ai" && (
                  <>
                    <FilterPill
                      onClick={() => actions.toggleAiFilter("all")}
                      selected={aiFilters.includes("all")}
                    >
                      All
                    </FilterPill>
                    <div className="tw-border-[1px] tw-border-solid tw-border-gray-5 tw-h-[24px] tw-mx-[-4px]" />
                    <FilterPill
                      onClick={() => actions.toggleAiFilter("engaged")}
                      selected={aiFilters.includes("engaged")}
                    >
                      Engaged
                    </FilterPill>
                    <FilterPill
                      onClick={() => actions.toggleAiFilter("needsAgent")}
                      selected={aiFilters.includes("needsAgent")}
                    >
                      Needs Agent
                    </FilterPill>
                  </>
                )}
                {loadingConversations && <Loading />}
              </div>

              <IconButton
                disabled={focusedConversation.uuid === "temp"}
                onClick={() => actions.focusConversation(getEmptyConversation(currentUser))}
              >
                <PenToSquareSolidV6 />
              </IconButton>
            </div>

            <div
              data-testid="texts-table"
              className="tw-relative tw-flex-1 tw-flex tw-flex-col tw-overflow-auto"
            >
              {focusedConversation.uuid === "temp" && <Conversation conversation={focusedConversation} />}
              {conversations.map((conversation) => (
                <Conversation key={conversation.uuid} conversation={conversation} />
              ))}

              {loadingConversations && emptyConversations && <Loading />}

              {!loadingConversations && emptyConversations && (
                <div className="tw-relative tw-flex-1 tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-24d">
                  Conversations will appear here
                </div>
              )}

              {hasMore && (
                <div className="tw-flex tw-justify-center tw-my-[8px]">
                  <Button
                    className="tw-min-w-1/2"
                    size="medium"
                    onClick={() => actions.nextPage()}
                    isLoading={loadingConversations}
                  >
                    Load More...
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ConversationMessages />
        </div>
      </div>
    </div>
  );
};

export default Inbox;
