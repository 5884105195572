import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import IconButton from "@shared/v2/IconButton";
import { CalendarCheckSolidV6, CommentSolidV6, EnvelopeSolidV6, PhoneSolidV6 } from "@shared/v2/Icomoon";
import { Col } from "../../shared/v1";
import OtherInteract from "../../People/components/OtherInteract";

import EventDetails from "./EventDetails";
import { eventTypeProps, getIconClass, LABEL_CATEGORIES } from "../utils/events";
import Tooltip from "../../shared/v2/Tooltip/Tooltip";
import { setLeadUuid } from "../../reducers/dialerReducer/dialerReducer";

const EventItem = ({
  event,
  event: {
    email_present: emailPresent,
    event_type: eventType,
    interaction,
    kind,
    managed_number: managedNumber,
    person,
    person_avatar: personAvatar,
    person_email: personEmail,
    person_phone_number: personPhoneNumber,
    time_ago: timeAgo,
    timeline_fields: timelineFields,
  },
  openSmsModalWithPerson,
}) => {
  const dispatch = useDispatch();
  const launchEmailModal = () => {
    if (emailPresent) Brivity.EmailInteraction.showModal(person.id);
  };

  const launchApptModal = () => {
    Brivity.Interaction.showModal(person.id, "appointment");
  };

  const otherEventInteraction = {
    ...interaction,
    interaction_type: "other",
    interacted_at: new Date(),
  };
  const propertyObject = timelineFields?.property || {};
  const iconClass = getIconClass(kind);
  const bedString = !propertyObject.bedrooms ? "" : `${propertyObject.bedrooms} Bed`;
  const bathString = !propertyObject.bathrooms ? "" : `${propertyObject.bathrooms} Bath`;
  const bedBathString = `${bedString} | ${bathString}`.trim();

  if (!timelineFields) return null;
  return (
    <Col className="tw-flex tw-py-[20px] tw-min-h-[191px] tw-border-solid tw-border-neutral-gray-5 tw-border-0 tw-border-b-[1px]">
      <span className="fa-stack fa-lg tw-mr-[15px] tw-mt-[-8px] tw-w-[2.3em]">
        <i className="fa fa-circle fa-stack-2x" />
        <i
          className={`tw-text-[22px] tw-text-gray-75 tw-bg-white tw-p-[0px] tw-border-solid tw-border-[2px] tw-border-gray-75 tw-rounded-full fa fa-${iconClass} fa-stack-1x `}
        />
      </span>

      <div className="tw-flex tw-justify-between tw-w-full">
        <div className="tw-flex tw-flex-col tw-w-[30%]">
          <div>
            <a href={`/people/${person.slug}`}>
              <span className="tw-text-gray-75 tw-font-bold">
                {person.first_name} {person.last_name}
              </span>
            </a>{" "}
            <span>{LABEL_CATEGORIES[kind]}</span>;
          </div>
          <div id="detail-interaction-buttons">
            <div className="interaction-icon tw-flex tw-p-[4px]">
              <Tooltip
                className="tw-p-[3px]"
                innerClassName="tw-max-w-500px"
                placement="right"
                trigger={
                  <IconButton onClick={launchEmailModal} disabled={!emailPresent}>
                    <EnvelopeSolidV6 />
                  </IconButton>
                }
                content={personEmail || ""}
              />
              <Tooltip
                className="tw-p-[3px]"
                innerClassName="tw-max-w-500px"
                placement="right"
                trigger={
                  <IconButton
                    onClick={() => dispatch(setLeadUuid(person.uuid))}
                    disabled={!personPhoneNumber}
                  >
                    <PhoneSolidV6 />
                  </IconButton>
                }
                content={personPhoneNumber || ""}
              />
              <Tooltip
                innerClassName="tw-max-w-[500px]"
                placement="right"
                className="tw-p-[3px]"
                trigger={
                  <IconButton
                    disabled={!managedNumber || !personPhoneNumber}
                    onClick={() => openSmsModalWithPerson({ data: { attributes: person } })}
                  >
                    <CommentSolidV6 />
                  </IconButton>
                }
                content={managedNumber && personPhoneNumber}
              />
              <Tooltip
                innerClassName="tw-max-w-[500px]"
                placement="right"
                className="tw-p-3px"
                trigger={
                  <IconButton onClick={launchApptModal}>
                    <CalendarCheckSolidV6 />
                  </IconButton>
                }
                content="Log Appointment"
              />
              <span className="tw-p-[3px]">
                <OtherInteract
                  otherInteract={{
                    person,
                    personAvatar,
                    personFirstName: person.first_name,
                    personLastName: person.last_name,
                    interaction: otherEventInteraction,
                  }}
                />
              </span>
            </div>
          </div>
          <EventDetails event={event} />
        </div>

        {eventType !== "ads-remarket" && (
          <div
            className="tw-self-center"
            role="button"
            tabIndex={0}
            onClick={() => timelineFields.property?.url && window.open(timelineFields.property.url)}
            onKeyDown={(e) => {
              if ((e.key === "Enter" || e.key === " ") && timelineFields.property?.url) {
                window.open(timelineFields.property.url);
              }
            }}
          >
            {timelineFields.property?.main_photo && (
              <div
                className="tw-relative tw-cursor-pointer tw-w-[260px] tw-h-[150px] tw-bg-cover"
                style={{
                  backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${timelineFields.property.main_photo}`,
                }}
              >
                <div className="tw-absolute tw-bottom-[20px] tw-left-[20px] tw-w-[80%] tw-text-shadow-bootstrap-2 tw-text-white">
                  <span className="tw-text-[24px]">{`$ ${timelineFields.property.value} `}</span>
                  <span>{timelineFields.property.street_address}</span>
                  <span>
                    {` ${timelineFields.property.city}, ${timelineFields.property.state} ${timelineFields.property.zip} `}
                  </span>
                  <span>{bedBathString}</span>
                </div>
              </div>
            )}
          </div>
        )}

        <div>
          <label>{timeAgo}</label>
        </div>
      </div>
    </Col>
  );
};

EventItem.propTypes = {
  event: eventTypeProps.isRequired,
  openSmsModalWithPerson: PropTypes.func.isRequired,
};

export default EventItem;
