import React from "react";
import { string } from "prop-types";

import styles from "../styles";

const { TYPE_STYLE } = styles;

const TransactionTypeBox = ({ type, className }) => (
  <div
    className={`tw-border-[2px] tw-border-solid tw-flex tw-justify-center tw-items-center tw-text-center tw-h-[24px] tw-rounded tw-uppercase tw-px-3px tw-font-bold tw-leading-[16px] tw-tracking-normal tw-text-center tw-text-[11px] tw-uppercase ${className} ${TYPE_STYLE[type]}`}
  >
    {type}
  </div>
);

TransactionTypeBox.propTypes = {
  type: string.isRequired,
  className: string,
};

TransactionTypeBox.defaultProps = {
  className: "",
};

export default TransactionTypeBox;
