import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const TimelineAgreementEvent = (props) => {
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const headline = () => {
    const from = props.event.timeline_fields.updater_name;
    const action = props.event.event_type === "agreement_deleted" ? "deleted" : "added";
    const agreementType = props.event.timeline_fields.agreement_type;
    return (
      <div>
        <span className="bold-detail">{from}</span>
        {` ${action} a ${agreementType} agreement`}
      </div>
    );
  };

  const row = () => {
    return (
      <div className="timeline-event" onClick={showModalHandler}>
        <div className="row">
          <div className="col-xs-1">
            <i
              className={`fa ${props.event.event_type === "agreement_deleted" ? "fa-trash" : "fa-upload"} timeline-icon-circle`}
            />
          </div>
          <div className="col-xs-8">
            {headline()}
            {props.event.timeline_fields.document_name && (
              <div className="timeline-description-text">
                Document Name:
                {` ${props.event.timeline_fields.document_name}`}
              </div>
            )}
          </div>
          <div className="col-xs-3 time-ago">
            <span>{props.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    );
  };

  const modal = () => {
    return (
      <Modal bsSize="sm" show={showModal} onHide={hideModalHandler} className="timeline-content">
        <div className="timeline-event timeline-event-modal">
          <Modal.Header closeButton>
            <Modal.Title>Agreement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {headline()}
            {props.event.timeline_fields.document_name && (
              <>
                <div className="row bold-detail details-row details-header">Details:</div>
                <div className="row details-row">
                  Document Name:
                  {` ${props.event.timeline_fields.document_name}`}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {row()}
      {modal()}
    </div>
  );
};

export default TimelineAgreementEvent;
