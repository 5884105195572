/**
 * Assigns `val` to `obj[key]`, if `obj[key]` is `undefined`.
 * @param {object} obj Object to assign to.
 * @param {object} val Value to be assigned to `key`.
 * @param {object} key Key to assign to inside `obj`.
 */
const assignValueToIfUndefined = (obj, val, key) => {
  if (obj[key] === undefined) {
    // eslint-disable-next-line no-param-reassign
    obj[key] = val;
  }
};

const makeRenamedPropsCompatible = (originalProps) => {
  const {
    clearable,
    closeOnSelect,
    disabled,
    onClose,
    scrollMenuIntoView,
    searchable,

    ...props
  } = originalProps;

  assignValueToIfUndefined(props, clearable, "isClearable");
  assignValueToIfUndefined(props, closeOnSelect, "closeMenuOnSelect");
  assignValueToIfUndefined(props, disabled, "isDisabled");
  assignValueToIfUndefined(props, onClose, "onMenuClose");
  assignValueToIfUndefined(props, scrollMenuIntoView, "menuShouldScrollIntoView");
  assignValueToIfUndefined(props, searchable, "isSearchable");

  return props;
};

const buildValueCompatibleWithLegacyOnUpdateCallback = (evt, nameFromProps) => ({
  target: { value: evt ? evt.value : undefined, name: nameFromProps },
  ...evt,
});

const makeCallbacksCompatibleInPlace = (props) => {
  // There used to be a DropdownSelect component, which was extremely similar to
  // the current Dropdown component but had a different API for handling user
  // selection (it had an `onUpdate` instead of `onChange`, and the callback
  // received a custom-made object instead of the original event). This adapter
  // makes it possible the use this component in both cases with no changes to
  // any callbacks/handlers.
  if (!props.onChange && props.onUpdate) {
    // eslint-disable-next-line no-param-reassign
    props.onChange = (evt) => props.onUpdate(buildValueCompatibleWithLegacyOnUpdateCallback(evt, props.name));
  }

  return props;
};

const recursivelyFindOptionByValue = (options, value) => {
  // Option groups are built by nesting `options` objects inside an existing
  // `option`, so to find a full `option` value based on its `value` we need to
  // recursively traverse the original `options` object.
  let result = options.find((opt) => opt.value === value);
  if (result) {
    return result;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const option of options) {
    if (option.options) {
      result = recursivelyFindOptionByValue(option.options, value);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
};

const makeValuePropCompatibleInPlace = (props) => {
  // As of react-select v2, passing anything other than a `{ label, value }`
  // shaped object means we need to implement a bunch of handlers for the Select
  // component to behave as expected. This function standardizes every usage
  // style seen (so far...) across the codebase.

  // Need to specifically test against `undefined` and `null` because other
  // falsy values are valid and must still be handled (e.g.: `false`, `0`).
  if (props.value === undefined || props.value === null) {
    // No value yet - nothing to do.
    return;
  }

  if (typeof props.value === "object") {
    // Already received an object - nothing to do.
    return;
  }

  // Otherwise, we need to convert to the appropiate shape. First...
  if (props.options) {
    // ...try to match the value we got to something in the options list, which
    // was the old react-select behaviour.
    const option = recursivelyFindOptionByValue(props.options, props.value);
    if (option) {
      // Success! Assign to props and bail.
      props.value = option; // eslint-disable-line no-param-reassign
      return;
    }
  }

  // Couldn't find a suitable value in the options array, assume label is the
  // same as value (default behavior back in react-select v1 AFAICT), and build
  // the value object by hand.
  // eslint-disable-next-line no-param-reassign
  props.value = { label: props.value, value: props.value };
};

/**
 * Takes `originalProps`, which were meant to be used with react-select v1 or
 * the legacy `DropdownSelect` component, and converts them to make as much as
 * we can compatible with the new `Dropdown` component.
 *
 * @param {object} originalProps Original props.
 * @returns The updated props, compatible with the new `Dropdown`.
 */
export const makePropsCompatible = (originalProps) => {
  const props = makeRenamedPropsCompatible(originalProps);
  makeValuePropCompatibleInPlace(props);
  makeCallbacksCompatibleInPlace(props);

  // For consistency with react-select v1.
  if (props.noOptionsMessage === undefined) {
    props.noOptionsMessage = () => "No results found";
  }

  // Again, to keep behavior consistent with react-select v1. Currently defaults
  // to `true` but used to default to `false` on v1.
  if (props.menuShouldScrollIntoView === undefined) {
    props.menuShouldScrollIntoView = false;
  }

  // For use in test helpers, so we can interact with Dropdowns programatically.
  if (props.classNamePrefix === undefined) {
    // Value should be kept in sync with the one in `react_select_helpers.rb`.
    props.classNamePrefix = "react-select";
  }

  return props;
};

export default makePropsCompatible;
