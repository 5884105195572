/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const WandMagicSparklesSolidV6 = (props) => (
  <Icon {...props} name="Wand Magic Sparkles Solid V6">
    <path d="M6.911 1.028a.213.213 0 0 1 .4 0l.392 1.047 1.047.392c.083.031.139.111.139.2s-.056.169-.139.2l-1.047.392-.392 1.046c-.031.083-.111.139-.2.139s-.169-.056-.2-.139l-.392-1.046-1.072-.392c-.083-.031-.114-.111-.114-.2s.031-.169.114-.2l1.072-.392.392-1.047zm4.961.252a1.333 1.333 0 0 1 1.886 0l.961.961a1.336 1.336 0 0 1 0 1.885L4.125 14.72a1.336 1.336 0 0 1-1.885 0l-.961-.961a1.333 1.333 0 0 1 0-1.886L11.872 1.28zm.945 1.257L9.898 5.453l.647.647 2.917-2.917-.644-.646zM.208 4.145l1.57-.589.589-1.57a.321.321 0 0 1 .6 0l.589 1.57 1.569.589a.321.321 0 0 1 0 .6l-1.569.589-.589 1.569a.321.321 0 0 1-.6 0l-.589-1.569-1.57-.589a.321.321 0 0 1 0-.6zm9.778 7.111 1.569-.589.589-1.569c.047-.15.167-.208.3-.208s.253.058.3.208l.589 1.569 1.569.589a.321.321 0 0 1 0 .6l-1.569.589-.589 1.569a.321.321 0 0 1-.6 0l-.589-1.569-1.569-.589c-.15-.047-.208-.167-.208-.3s.058-.253.208-.3z" />
  </Icon>
);

export default WandMagicSparklesSolidV6;
