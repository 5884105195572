import React from "react";
import { bool, string } from "prop-types";

import { Info } from "../../../../../shared/v2/Icomoon";
import { tdpDetails } from "../../../../../shared/HOCs/withRedux";
import { STATUS, TYPE } from "../../../../../shared/reduxRoutes/tdpDetailsRoutes";

const FeatureBanner = ({ containerClassName, show }) =>
  show && (
    <div
      className={`tw-w-full tw-bg-brivity-blue-10 tw-h-32px tw-py-10px tw-px-12px tw-rounded-4px tw-text-brivity-blue-dark tw-text-12px tw-flex tw-items-center ${containerClassName}`}
    >
      <Info className="tw-text-brivity-blue-100 tw-mr-8px" />
      <span>
        Values are <b>Estimated</b> until transaction status is set to Closed.
      </span>
    </div>
  );

const BANNER_TYPE = ["referral"];

const WithStatusFeatureBanner = ({ status, transactionType }) => {
  const show = status !== "sold" && !BANNER_TYPE.includes(transactionType);

  return <FeatureBanner show={show} />;
};

export default tdpDetails({
  WrappedComponent: WithStatusFeatureBanner,
  states: [TYPE, STATUS],
  aliases: ["transactionType"],
});

FeatureBanner.propTypes = {
  containerClassName: string,
  show: bool,
};

FeatureBanner.defaultProps = {
  containerClassName: "",
  show: true,
};

WithStatusFeatureBanner.propTypes = {
  transactionType: string.isRequired,
  status: string,
};

WithStatusFeatureBanner.defaultProps = {
  status: "pipeline",
};
