import React from "react";
import { connect } from "react-redux";
import { bool, func, string } from "prop-types";
import Modal from "../../../../shared/v2/Modal/Modal";
import Button from "../../../../shared/v2/Button/Button";
import { deleteListingAsThunk } from "../../actions/thunks";

const ConfirmDeleteModal = ({ deleteListing, setDelete, uuid, onDeleteListing }) => (
  <Modal
    show={deleteListing}
    onHide={() => setDelete(false)}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[487px]"
  >
    <Modal.Header>
      <div className="tw-text-[28px] tw-leading-9 tw-tracking-normal tw-text-left"> Delete Transaction</div>
    </Modal.Header>

    <Modal.Body className="tw-text-[14px] tw-font-normal tw-mt-[12px] tw-text-gray-75">
      <div>Are you sure you want to delete this listing?</div>
      <div>This action cannot be undone.</div>
    </Modal.Body>
    <Modal.Footer className="tw-mt-[22px]">
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-4px">
        <Button schema="tertiary" size="medium" onClick={() => setDelete(false)}>
          Cancel
        </Button>
        <Button schema="warning" size="medium" onClick={() => onDeleteListing(uuid)}>
          Yes,Delete
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteListing: (uuid) => dispatch(deleteListingAsThunk(uuid)),
});

ConfirmDeleteModal.propTypes = {
  uuid: string,
  deleteListing: bool.isRequired,
  setDelete: func.isRequired,
  onDeleteListing: func.isRequired,
};

ConfirmDeleteModal.defaultProps = {
  uuid: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteModal);
