import React, { useState } from "react";
import PropTypes from "prop-types";

import IconButton from "../../../../shared/v2/IconButton";
import { ChevronDown, ChevronRight } from "../../../../shared/v2/Icomoon";

const ChevronButton = ({ disabled, isExpanded, onClick, ...otherProps }) => {
  const ChevronIcon = isExpanded ? ChevronDown : ChevronRight;

  const chevronColorClass = disabled ? "tw-text-neutral-gray-15" : "tw-text-theme-primary";

  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      size="custom"
      schema="transparent"
      className="tw-w-18px tw-h-18px tw-self-center"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <ChevronIcon className={`${isExpanded ? "" : "tw-pl-1px"} tw-pt-1px ${chevronColorClass}`} />
    </IconButton>
  );
};
ChevronButton.propTypes = {
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
ChevronButton.defaultProps = { disabled: false };

const CollapsibleCategory = ({ name, count, children }) => {
  const [isExpandedByUser, setExpanded] = useState(false);

  // If there are no items for this item/category, it should be disabled.
  const isDisabled = count === 0;

  // If the category is disabled, it must not be expanded even if the user had
  // previously manually expanded it.
  const isExpanded = isDisabled ? false : isExpandedByUser;

  // Separator should only show up when the section is expanded.
  let borderClasses = "tw-border-solid tw-border-0 tw-border-b-neutral-gray-10";
  if (isExpanded) {
    borderClasses += " tw-border-b-1px";
  }

  return (
    <>
      <div className={`tw-flex tw-gap-8px tw-pb-16px ${borderClasses}`}>
        <ChevronButton
          aria-expanded={isExpanded}
          disabled={isDisabled}
          isExpanded={isExpanded}
          onClick={() => setExpanded(!isExpanded)}
          data-cy={`${name}-plans-chevron`}
        />
        <span className={`tw-font-bold tw-capitalize ${isDisabled ? "tw-text-neutral-gray-15" : ""}`}>
          {name} ({count})
        </span>
      </div>

      {isExpanded && <div className="tw-mb-16px">{children}</div>}
    </>
  );
};
CollapsibleCategory.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CollapsibleCategory;
