import React from "react";
import PropTypes from "prop-types";
import { Close } from "../v2/Icomoon";

const tagStyles = {
  default:
    "tw-bg-neutral-gray-10 tw-h-[24px] tw-w-fit tw-px-8px tw-py-4px tw-rounded-full tw-text-[11px] tw-flex tw-gap-[6px] tw-items-center tw-justify-center tw-font-bold hover:tw-text-semantic-red-100",
  hover: "hover:tw-text-semantic-red-100 hover:tw-bg-semantic-red-5 hover:tw-cursor-pointer",
  press: "active:tw-bg-semantic-red-100 active:tw-text-white",
};

function TagV1({ text, onRemove, disabled }) {
  return (
    <div
      className={`${tagStyles.default} ${tagStyles.hover} ${tagStyles.press}`}
      onClick={!disabled && !!onRemove && onRemove}
      data-cy={`${text}_close_btn`}
    >
      {text}
      {!disabled && onRemove && <Close size="s" />}
    </div>
  );
}
TagV1.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
};

TagV1.defaultProps = {
  text: "",
  disabled: false,
  onRemove: () => {},
};

export default TagV1;
