import { get } from "lodash";
import formatter from "../../../../shared/formatters";

const getChartTextAlignment =
  (format, customDatasetIndex) =>
  ({ dataset, dataIndex, chart: { ctx, chartArea, config, scales } }) => {
    const hasCustomDatasetIndex = typeof customDatasetIndex === "number";
    const workingDataset = hasCustomDatasetIndex ? config.data.datasets[customDatasetIndex] : dataset;
    const dataKey = workingDataset.parsing ? Object.values(workingDataset.parsing)[0] : null;
    const { max } = scales.x || { max: 1 };
    const value = dataKey ? get(workingDataset.data[dataIndex], dataKey) : workingDataset.data[dataIndex];
    const valueWidth = (value / max) * chartArea.width;
    const textWidth = ctx.measureText(formatter[format](value, 0)).width + 20;

    if ((hasCustomDatasetIndex && value === 0) || valueWidth > textWidth) return "start";
    return "end";
  };

export default getChartTextAlignment;
