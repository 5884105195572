import React from 'react';
import { Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { defaultOrImage } from "../../shared/DefaultOrImage";

const UserDataRow = ({agent, isAdmin}) => {
  const prefix = isAdmin ? 'admin' : 'agent';
  return (<div className='user td'>
    <Col xs={2}>
      <div style={{display: 'inline-block',padding: '0 10px'}}>
        <a href={`/people/${agent[`${prefix}_user_path`]}`}>
          {defaultOrImage(agent[`${prefix}_avatar`], agent[`${prefix}_agent`])}
        </a>
      </div>
      <div style={{display: 'inline-block'}}>
        {`${agent[`${prefix}_agent`]}`}
      </div>
    </Col>
    <div className='col-xs-2 text-center'>
      {`${agent[`${prefix}_assigned_task_count`]}`}
    </div>
    <div className='col-xs-2 text-center'>
      <span>
        {`${agent[`${prefix}_completed_task_count`]}`}

      </span>
    </div>
      <div className='col-xs-2 text-center'>
        <span>
          {`${agent[`${prefix}_on_time_task_count`]}`}
        </span>
      </div>
      <div className='col-xs-2 text-center'>
        <span>
          {`${agent[`${prefix}_late_task_count`]}`}
        </span>
      </div>
    <div className='col-xs-1 text-center'>
      <span>
        {`${agent[`${prefix}_login_count`]}`}
      </span>
    </div>

    {agent[`${prefix}_last_login`] ?
      <div
        className='text-center'
        style={{display: 'inline-block', width: '8.33%', background: '#FFF',borderBottom: 'solid 1px #E6E6E6'}}>
        <Moment format="MM/DD/YYYY">
          {`${agent[`${prefix}_last_login`]}`}
        </Moment>
      </div>
      :
      <div style={{display: 'inline-block', width: '8.33%', background: '#FFF',borderBottom: 'solid 1px #E6E6E6'}}>
      </div>
    }
  </div>
)
}
export default UserDataRow;
