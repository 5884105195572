import React from "react";
import skeletonCss from "../../PersonDetail/components/person_detail_skeleton.module.css";

const LoadingSkeleton = () => (
  <div>
    {_.times(8, (i) => (
      <div key={i} className={skeletonCss.skeleton}>
        <div className={skeletonCss.timelineSkeletonWrapper}>
          <div className={skeletonCss.timelineSkeletonWrapperInner}>
            <div className={skeletonCss.timelineSkeletonWrapperBody}>
              <div className={skeletonCss.timelineSkeletonIcon}></div>
              <div className={skeletonCss.timelineSkeletonTitle}></div>
              <div className={skeletonCss.timelineSkeletonDetail1}></div>
              <div className={skeletonCss.timelineSkeletonDetail2}></div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default LoadingSkeleton;
