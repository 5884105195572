import React, {Component} from 'react';

export default class TaskTaskable extends Component {

  render(){
   return(
     <div>
       { this.props.link &&
         <a href={this.props.link}>
           {this.props.taskable_name}
         </a> }
     </div>
   )
  }
}
