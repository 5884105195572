import React from "react";
import { string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { STATUS } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

const ClosePriceLabel = ({ label }) => (
  <div className="tw-inline-flex tw-capitalize">
    <div className="tw-text-12d tw-font-semibold tw-tracking-normal">{label}</div>
  </div>
);

const PipelineClosePriceLabel = ({ status }) => {
  const label = `${status !== "sold" ? "Est. " : ""}Close Price`;

  return <ClosePriceLabel label={label} />;
};

const WithStatusPipelineClosePriceLabel = tdpDetails({
  WrappedComponent: PipelineClosePriceLabel,
  states: STATUS,
});

export default WithStatusPipelineClosePriceLabel;

ClosePriceLabel.propTypes = {
  label: string,
};

ClosePriceLabel.defaultProps = {
  label: "Est. Close Price",
};

PipelineClosePriceLabel.propTypes = {
  status: string,
};

PipelineClosePriceLabel.defaultProps = {
  status: null,
};
