/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CommentSlashRegularV6 = (props) => (
  <Icon {...props} name="Comment Slash Regular V6">
    <path d="m15.77 13.328-2.952-2.315C13.8 10.1 14.4 8.91 14.4 7.6c0-2.873-2.865-5.178-6.377-5.178-1.694 0-3.228.538-4.373 1.412L.97 1.728a.601.601 0 0 0-.741.944l14.777 11.577c.133.108.263.15.37.15a.597.597 0 0 0 .472-.23.579.579 0 0 0-.08-.843zm-3.905-3.063L4.607 4.577c.912-.606 2.095-.978 3.395-.978 2.87 0 5.2 1.794 5.2 4 0 1.025-.51 1.958-1.338 2.665zM8.003 11.6a6.487 6.487 0 0 1-1.96-.303l-.571-.177-.487.343a5.356 5.356 0 0 1-1.438.725c.184-.303.36-.644.497-1.006l.266-.7-.515-.547c-.45-.482-.992-1.277-.992-2.334 0-.421.086-.826.243-1.207l-.967-.758a4.337 4.337 0 0 0-.478 1.943c0 1.191.498 2.281 1.323 3.157-.372.988-1.147 1.822-1.159 1.831a.602.602 0 0 0-.116.65.59.59 0 0 0 .551.384c1.538 0 2.728-.644 3.478-1.156A7.805 7.805 0 0 0 8 12.801a7.65 7.65 0 0 0 2.63-.462l-1.153-.904a6.664 6.664 0 0 1-1.474.166z" />
  </Icon>
);

export default CommentSlashRegularV6;
