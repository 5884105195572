/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const AcornSolidV6 = (props) => (
  <Icon {...props} name="Acorn Solid V6">
    <path d="M8 15c1.47-.715 5.5-2.567 5.5-7.972h-11C2.5 12.434 6.503 14.284 8 15zm4-12.999H8.859a2.24 2.24 0 0 1 .473-.784C9.509.986 9.5.689 9.284.505L8.932.15a.492.492 0 0 0-.375-.148.511.511 0 0 0-.356.168 3.984 3.984 0 0 0-.918 1.832H3.999c-1.656 0-3 1.343-2.972 2.999-.028.55.421 1 .972 1h12c.551 0 .972-.449.972-.999 0-1.657-1.344-2.999-2.972-2.999z" />
  </Icon>
);

export default AcornSolidV6;
