import React from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import { camelCase } from "lodash";
import formatters from "@shared/formatters";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Flyout from "@shared/v2/Flyout";
import Avatar from "@shared/v2/Avatar";
import Typography from "@shared/v2/Typography";
import Tag from "@shared/v2/Tag";
import IconButton from "@shared/v2/IconButton";
import { PencilSolidV6, XmarkSolidV6 } from "@shared/v2/Icomoon";
import OverviewCard from "./OverviewCard";
import { Table, TBody, TH, THead, TR } from "../BusinessDashboard/Table/Table";
import useOpenHouseDetails from "./hooks/useOpenHouseDetails";
import Loading from "../../../Goals/components/Loader";
import LeadRow from "./LeadRow";
import useOpenHouseLeads from "./hooks/useOpenHouseLeads";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";
import openLeadIndexFromTagName from "./helpers/openLeadIndexFromTagName";

const transformOpenHouse = ({ address, attendees, workingWithAgent, leads, tags, ...openHouse }) => ({
  openHouse,
  propertyDetails: address,
  tags,
});

const getStatus = (openHouse) => {
  if (openHouse.activeAt && !openHouse.completedAt) return "In Progress";
  if (!openHouse.completedAt) return "Upcoming";
  return "Completed";
};

const OpenHouseFlyout = ({ openHouse, onClose, onEdit }) => {
  const currentUser = useCurrentUser();
  const {
    data,
    data: {
      address,
      attendees,
      completedAt,
      hostName,
      hostPersonUuid,
      instructions,
      isActive,
      leads,
      leadPersonUuids,
      listing,
      startDate,
      startTime,
      endTime,
      tags,
      workingWithAgent,
    },
    error,
    loading,
  } = useOpenHouseDetails({ openHouse });
  const { data: leadList, loading: leadListLoading } = useOpenHouseLeads({ leadPersonUuids });

  const canEdit =
    (currentUser.account.isAdmin || currentUser.person.uuid === hostPersonUuid) && !isActive && !completedAt;

  return (
    <Flyout show={Boolean(openHouse)} onHide={onClose}>
      <Flyout.Body className="tw-relative tw-bg-white tw-max-w-[908px] tw-w-[100vw] !tw-p-[24px]">
        <div className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-gap-[16px]">
            <Typography variant="headline3">
              {address
                ? `${address.streetAddress} ${address.city}, ${address.state} ${address.postalCode}`
                : ""}
            </Typography>
            <div className="tw-flex tw-gap-[8px]">
              {canEdit && (
                <IconButton schema="tertiary" onClick={() => onEdit(transformOpenHouse(data))}>
                  <PencilSolidV6 />
                </IconButton>
              )}
              <IconButton schema="tertiary" onClick={onClose}>
                <XmarkSolidV6 />
              </IconButton>
            </div>
          </div>
          <div className="tw-text-14d tw-flex tw-gap-[8px] tw-items-end">
            <span
              className={`${completedAt ? "tw-text-semantic-green-110" : "tw-text-[#e5941b]"} tw-uppercase tw-font-bold`}
            >
              {getStatus(data)}:
            </span>
            <div className="tw-flex tw-gap-[4px]">
              <Typography variant="subtitle-m-bold" className="!tw-text-gray-50">
                {startDate ? format(parse(startDate, "yyyy-MM-dd", new Date()), "EEE. MM/dd/yyyy") : ""}
              </Typography>
              <span className="tw-text-gray-10">|</span>
              <Typography variant="subtitle-m-bold" className="!tw-text-gray-50">
                {`${startTime ? format(parse(startTime, "HH:mm:ss", new Date()), "hh:mm a") : ""} - ${endTime ? format(parse(endTime, "HH:mm:ss", new Date()), "hh:mm a") : ""}`}
              </Typography>
            </div>
          </div>
          <div className="tw-relative tw-flex tw-flex-col tw-gap-[16px]">
            <div className="tw-flex tw-gap-[24px]">
              <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
                <div className="tw-flex tw-gap-[8px]">
                  <Avatar size="medium" alt={hostName} />
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-text-14d tw-font-bold">Host</span>
                    <span className="tw-text-12d tw-text-gray-50">{hostName}</span>
                  </div>
                </div>
                <div className="tw-flex tw-gap-[32px]">
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">
                      {listing?.price ? formatters.currency(listing?.price) : "--"}
                    </Typography>
                    <Typography variant="body-m">Listing Price</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.bedrooms || "--"}</Typography>
                    <Typography variant="body-m">Beds</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">{listing?.bathsTotalDecimal || "--"}</Typography>
                    <Typography variant="body-m">Baths</Typography>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-[8px]">
                    <Typography variant="headline2">
                      {listing?.squareFeet ? listing?.squareFeet.toLocaleString() : "--"}
                    </Typography>
                    <Typography variant="body-m">Sq Ft</Typography>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-[4px]">
                  <Typography variant="subtitle-s" className="!tw-text-gray-50">
                    Tags
                  </Typography>
                  <div className="tw-flex tw-gap-[8px]">
                    {tags?.map((tag) => (
                      <Tag key={tag} removable={false}>
                        {tag}
                      </Tag>
                    ))}
                  </div>
                </div>
              </div>

              <div className="tw-flex-1 tw-flex tw-items-stretch tw-gap-[16px]">
                {completedAt && (
                  <>
                    <div className="tw-flex-1 tw-border-solid tw-border-[1px] tw-border-gray-10 tw-rounded-[8px] tw-p-[8px]">
                      <OverviewCard
                        description="Total number of open house visitors"
                        title="Attendees"
                        value={attendees || 0}
                      />
                    </div>
                    <div className="tw-flex-1 tw-border-solid tw-border-[1px] tw-border-gray-10 tw-rounded-[8px] tw-p-[8px]">
                      <OverviewCard
                        description="Attendees who signed-in with the app"
                        title="Leads"
                        value={leads || 0}
                        action={
                          leads > 0 ? (
                            <Button
                              schema="secondary"
                              onClick={openLeadIndexFromTagName(address.streetAddress)}
                            >
                              View Leads in Index
                            </Button>
                          ) : undefined
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {instructions && (
              <div className="tw-flex tw-flex-col">
                <Typography variant="subtitle-s" className="!tw-text-gray-50">
                  Notes
                </Typography>
                <Typography variant="body-s">{instructions}</Typography>
              </div>
            )}

            {loading && <Loading />}
          </div>

          {completedAt && (
            <div className="tw-relative tw-min-h-[150px]">
              <Table>
                <THead>
                  <TR className="tw-border-x-0">
                    <TH className="tw-border-l-0 tw-py-[8px] tw-font-semibold">Name</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Intent</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Agent</TH>
                    <TH className="tw-py-[8px] tw-font-semibold tw-text-center">Status</TH>
                    <TH className="tw-py-[8px] tw-font-semibold">Last Interaction</TH>
                    <TH className="tw-py-[8px]" />
                  </TR>
                </THead>
                <TBody>
                  {leadList.map((lead) => (
                    <LeadRow
                      key={lead.uuid}
                      lead={lead}
                      hasAgent={workingWithAgent?.[camelCase(lead.uuid)]}
                    />
                  ))}
                </TBody>
              </Table>

              {leadListLoading && <Loading />}
            </div>
          )}

          {error && (
            <Alert variant="error" text="Whoops something went wrong loading the details, try again later." />
          )}
        </div>
      </Flyout.Body>
    </Flyout>
  );
};

OpenHouseFlyout.propTypes = {
  openHouse: PropTypes.shape({ uuid: PropTypes.string }),
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

OpenHouseFlyout.defaultProps = {
  openHouse: null,
};

export default OpenHouseFlyout;
