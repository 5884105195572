import React from 'react';
import { Button, Form } from 'react-bootstrap';
import FormErrors from "./FormErrors";

const FormWrapper = (props) => {
  const { children, formErrors, onCancel, onSubmit } = props;

  return (
    <div>
      { formErrors && <FormErrors formErrors={formErrors} /> }
      <Form horizontal>
        { children }
      </Form>

      <Form inline>
        <div className="pull-right">
          <Button onClick={onCancel} style={{ marginRight: "8px"}}>
            Cancel
          </Button>

          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </Form>
    </div>
  );
}

export default FormWrapper;
