/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VisibilityVisible = (props) => (
  <Icon {...props} name="Visibility Visible">
    <path d="M15.356 7.363A8.522 8.522 0 0 0 8 3.19 8.523 8.523 0 0 0 .644 7.363a1.252 1.252 0 0 0 0 1.274A8.522 8.522 0 0 0 8 12.81a8.523 8.523 0 0 0 7.356-4.173 1.252 1.252 0 0 0 0-1.274zM8 11.556c-1.965 0-3.556-1.59-3.556-3.556S6.034 4.444 8 4.444c1.965 0 3.556 1.59 3.556 3.556S9.966 11.556 8 11.556zM10.719 8a2.719 2.719 0 1 1-5.118-1.28v.001a1.12 1.12 0 1 0 1.12-1.12H6.72A2.719 2.719 0 0 1 10.719 8z" />
  </Icon>
);

export default VisibilityVisible;
