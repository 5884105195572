import React from "react";
import Proptypes from "prop-types";
import { startCase } from "lodash";
import ModifyDate from "./ModifyDate";

const TimeFrame = ({ date }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[20px] tw-text-gray-75 tw-text-[14px] tw-font-normal tw-leading-[20px]">
    {startCase(date.name)}
    <ModifyDate date={date} />
  </div>
);

TimeFrame.propTypes = {
  date: Proptypes.shape({
    id: Proptypes.string,
    date: Proptypes.string,
    name: Proptypes.string,
    include_time: Proptypes.bool,
  }).isRequired,
};

export default TimeFrame;
