import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../shared/v2/Button";
import IconButton from "../../../../shared/v2/IconButton";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import AdSite from "../AdSite";
import AdApplyPlan from "../AdApplyPlan";

const AdHeaderOptions = () => {
  const [adsiteModalOpen, setadsiteModalOpen] = useState(false);
  const [adplanModalOpen, setadplanModalOpen] = useState(false);

  const adsitecloseModal = () => {
    setadsiteModalOpen(false);
  };
  const adplancloseModal = () => {
    setadplanModalOpen(false);
  };
  return (
    <>
      <div className="tw-h-[24px] tw-flex tw-ml-auto">
        <Button
          onClick={() => setadplanModalOpen(true)}
          className="tw-mr-[8px] tw-gap-4px tw-flex tw-items-center"
          schema="tertiary"
          size="small"
        >
          <Plus size="s" />
          PLAN
        </Button>
        <Button
          onClick={() => setadsiteModalOpen(true)}
          className="tw-mr-[8px] tw-gap-4px tw-flex tw-items-center"
          schema="tertiary"
          size="small"
        >
          <Plus size="s" />
          SITE
        </Button>
        <IconButton onClick={() => window.location.assign("/action_plans")} size="small" schema="tertiary">
          <FontAwesomeIcon icon={faCog} className="tw-text-[12px]" />
        </IconButton>
      </div>

      <AdSite
        isFromManageModal={false}
        planId={null}
        modalOpen={adsiteModalOpen}
        closeModal={adsitecloseModal}
      />

      <AdApplyPlan modalOpen={adplanModalOpen} closeModal={adplancloseModal} />
    </>
  );
};

export default AdHeaderOptions;
