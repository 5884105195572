import React from "react";
import PropTypes from "prop-types";
import paginationShape from "../model/pagination";
import Button from "../../../../shared/v2/Button";

const FeedbackInfiniteScroll = ({ pagination, isLoading, loadMoreFeedbacks }) => {
  // Do not render anything when there is no next page
  if (!pagination.next) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-mt-16px">
      <Button
        onClick={loadMoreFeedbacks}
        disabled={isLoading}
        data-cy="feedback-infinite-scroll-load-more-button"
      >
        {isLoading ? "Loading..." : "Load More"}
      </Button>
    </div>
  );
};

FeedbackInfiniteScroll.propTypes = {
  pagination: paginationShape.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadMoreFeedbacks: PropTypes.func.isRequired,
};

export default FeedbackInfiniteScroll;
