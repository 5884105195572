import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const initialState = {
  actionLabel: "",
  className: "",
  closeOnTimeout: () => {},
  icon: null,
  onActionButtonClick: null,
  onSecondaryActionButtonClick: null,
  position: "bottom",
  schema: "default",
  secondaryActionLabel: "",
  show: false,
  text: "",
  timeout: 0,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;

export const useSnackbar = () => useSelector(({ snackbar }) => snackbar);
