import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";

const SingleStatCard = ({ label, tooltip, value }) => (
  <div className="tw-flex-1 tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-gray-10 tw-flex tw-flex-col tw-gap-[8px] tw-p-[16px] tw-min-w-[230px]">
    <div className="tw-flex tw-items-center tw-gap-[4px]">
      <h3 className="tw-text-14d tw-font-semibold tw-text-gray-50 tw-m-0">{label}</h3>
      {tooltip && (
        <Tooltip
          className="tw-inline-flex"
          innerClassName="tw-max-w-[220px]"
          trigger={<CircleInfoSolidV6 size="l" />}
          content={tooltip}
        />
      )}
    </div>
    <span className="tw-text-18d">{value}</span>
  </div>
);

SingleStatCard.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SingleStatCard.defaultProps = {
  tooltip: "",
};

export default SingleStatCard;
