import React from "react";
import PropTypes from "prop-types";
import styleSchema from "./Tab.styles";

const Tab = ({ label, selected, disabled, className, isPadded, ...otherProps }) => {
  const enabledAndSelected = !disabled && selected;

  return (
    // Using the button element to leverage the "disabled" attribute and focus capability
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      type="button"
      disabled={disabled}
      className={`${styleSchema.regular} ${
        enabledAndSelected ? "tw-text-theme-tab-label-focus" : "tw-text-neutral-gray-30"
      } ${className}`}
      tabIndex={-1}
      {...otherProps}
    >
      <span
        className={`tw-font-14d tw-font-bold tw-uppercase tw-my-[12px] ${isPadded ? "tw-mx-[16px]" : ""}`}
        tabIndex={!disabled ? 0 : undefined}
        role="button"
      >
        {label}
      </span>
      <div
        className={`tw-h-[2px] tw-w-full tw-m-0 tw-border-0 tw-rounded-full ${
          enabledAndSelected ? "tw-bg-theme-tab-focus" : "tw-bg-transparent"
        }`}
      />
    </button>
  );
};

Tab.propTypes = {
  label: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isPadded: PropTypes.bool,
};

Tab.defaultProps = {
  selected: false,
  disabled: false,
  className: "",
  isPadded: false,
};

export default Tab;
