import React, { useState, useEffect } from "react";

import ListingsAutocomplete from "../../shared/ListingsAutocomplete/ListingsAutocomplete";
import ListingsAutocompleteResults from "../../shared/ListingsAutocomplete/ListingsAutocompleteResults";

const SearchAutocomplete = ({ mlsIds, setMlsIds, currentUserId, selectResult, searchBarRef, statuses, savedSearches, deleteSearch, selectSearch }) => {
  const [isActive, setIsActive] = useState(false);
  const [searchResults, setSearchResults] = useStateWithMappedProps(savedSearches);
  const [childPosition, setChildPosition] = useState(0);
  const [autocompleteTerm, setAutocompleteTerm] = useState("");

  const createResultsList = (autocompleteResults) => {
    if (!savedSearches?.length) {
      setSearchResults(autocompleteResults);
      return;
    }

    const mappedSearches = mapSavedSearches(savedSearches)
      .filter(s => filterByTermIgnoreCase(s.value, autocompleteTerm));
    const results = [...mappedSearches, ...autocompleteResults];

    setSearchResults(results);
  };

  const selectAutocompleteResult = (result) => {
    setSearchResults(mapSavedSearches(savedSearches));
    setChildPosition(0);
    setAutocompleteTerm("");

    if (result.category === "Saved Searches") {
      selectSearch(result.id);
      return;
    }

    const { field, newValue, identifier } = mapResultToSearchModel(result);
    selectResult(field, newValue, identifier);
  };

  return (
    <>
      <ListingsAutocomplete
        searchBarRef={searchBarRef}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        currentUserId={currentUserId}
        mlsIds={mlsIds}
        setMlsIds={setMlsIds}
        statuses={statuses}
        selectResult={selectAutocompleteResult}
        searchFields={searchFields}
        selectedFields={selectedFields}
        searchResults={searchResults}
        setSearchResults={createResultsList}
        childPosition={childPosition}
        setChildPosition={setChildPosition}
        term={autocompleteTerm}
        setTerm={setAutocompleteTerm}
        placeholder={"Search by address, MLS#, neighborhood, city, ZIP code, county, school district, agent, or office"}
      />
      {isActive && (
        <ListingsAutocompleteResults
          searchResults={searchResults}
          selectResult={selectAutocompleteResult}
          childPosition={childPosition}
          setChildPosition={setChildPosition}
          deleteSearch={deleteSearch}
        />
      )}
    </>
  );
};

export default SearchAutocomplete;

const searchFields = [ "ln", "fullAddress", "cityState", "districtCityState", "countyState", "postalCode", "schoolDistrict", "agentName", "officeName" ];

const selectedFields = [ "ln", "address", "city", "district", "postalCode", "countyState", "state", "schoolDistrict", "officeId", "agentId", "agentName", "officeName" ];

const mapResultToSearchModel = (result) => {
  let field;
  let newValue;
  let identifier;

  switch (result.category) {
    case "CityState":
      field = "cityStates";
      newValue = {
        city: result.selected_fields.city,
        state: result.selected_fields.state,
      };
      break;
    case "NeighborhoodCityState":
      field = "neighborhoodCityStates";
      newValue = {
        neighborhood: result.selected_fields.district,
        city: result.selected_fields.city,
        state: result.selected_fields.state,
      };
      break;
    case "CountyState":
      field = "countyStates";
      newValue = {
        // Hack to handle data bug: Autocomplete db doesn't have "county" as its own field
        county: result.selected_fields.countyState.split(",")[0],
        state: result.selected_fields.state,
      };
      break;
    case "Zip Code":
      field = "postalCodes";
      newValue = result.selected_fields.postalCode;
      break;
    case "School District":
      field = "schoolDistricts";
      newValue = result.selected_fields.schoolDistrict;
      break;
    case "MLS":
      field = "mlsNums";
      newValue = result.selected_fields.ln;
      break;
    case "Address":
      field = "streetAddress";
      newValue = result.selected_fields.address;
      break;
    case "Office":
      field = "officeIds";
      newValue = result.selected_fields.officeIds.join("|");
      identifier = result.selected_fields.officeName;
      break;
    case "Agent":
      field = "agentIds";
      newValue = result.selected_fields.agentId;
      identifier = result.selected_fields.agentName;
      break;
  }

  console.log(newValue);
  return { field, newValue, identifier };
};

const useStateWithMappedProps = (initialValue) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    setState(mapSavedSearches(initialValue));
  }, [initialValue]);

  return [state, setState];
};

const mapSavedSearches = (savedSearches) => savedSearches?.map(({id, name}) => ({id, value: name, category: "Saved Searches"})) || [];

const filterByTermIgnoreCase = (searchIn, target) => searchIn.match(new RegExp(target || "", "gi"))?.length > 0;
