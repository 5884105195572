/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from "react";

const Switcher = ({ children, justify, onClick, isActionable }) => {
  const triggerableContainer = () => (
    <div
      className={`tw-flex tw-flex-row tw-items-center tw-flex-nowrap ${justify || "tw-justify-start"} ${
        isActionable ? "actionable" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );

  return triggerableContainer(children);
};

export default Switcher;
