import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TextButton from "../../../shared/v2/TextButton";

import {
  confirmAssignedPlanDeletion,
  setApplyAutoPlanModalVisible,
  setShowPreviewModalForAutoPlan,
} from "./actions/creators";
import {
  fetchAssignedPlans,
  fetchAvailableAutoPlans,
  showDetailsModalForAssignedPlan,
  updateAssignedPlanState,
} from "./actions/thunks";

import ApplyAutoPlanCallToAction from "./Components/ApplyAutoPlanCallToAction";
import AssignedPlanDeletionConfirmationModal from "./Components/AssignedPlanDeletionConfirmationModal";
import AssignedPlanDetailsModal from "./Components/PlanDetails/AssignedPlanDetailsModal";
import AssignedPlansByState from "./Components/AssignedPlansByState";
import AutoPlanApplyModal from "./Components/AutoPlanApplyModal";
import AutoPlanPreviewModal from "./Components/PlanDetails/AutoPlanPreviewModal";
import Header from "./Components/Header";

const AutoPlans = ({
  dispatch,
  uuid,
  assignedPlans,
  meta: { error, isLoading, idsBeingUpdated },
  confirmingDeletionForPlanId,
  showingDetailsForPlanId,
  showingPreviewForPlanId,
}) => {
  useEffect(() => {
    dispatch(fetchAssignedPlans(uuid));
    dispatch(fetchAvailableAutoPlans());
  }, [uuid]);

  const shouldShowCallToAction = !error && !isLoading && assignedPlans.length === 0;

  return (
    <div className="tw-block">
      <Header onApplyAutoPlanClicked={() => dispatch(setApplyAutoPlanModalVisible(true))} />

      <AutoPlanApplyModal />
      <AssignedPlanDeletionConfirmationModal
        show={confirmingDeletionForPlanId !== null}
        onConfirm={() => dispatch(updateAssignedPlanState(confirmingDeletionForPlanId, "deleted"))}
        onCancel={() => dispatch(confirmAssignedPlanDeletion(null))}
      />

      <AssignedPlanDetailsModal
        show={showingDetailsForPlanId !== null}
        onClose={() => dispatch(showDetailsModalForAssignedPlan(null))}
        isStateUpdateLoading={idsBeingUpdated.has(showingDetailsForPlanId)}
        onPlayOrPauseButtonClicked={(assignedPlanId, desiredState) =>
          dispatch(updateAssignedPlanState(assignedPlanId, desiredState, { triggerPlanDetailsRefresh: true }))
        }
        onTrashButtonClicked={(assignedPlanId) => dispatch(confirmAssignedPlanDeletion(assignedPlanId))}
      />

      <AutoPlanPreviewModal
        transactionUuid={uuid}
        show={showingPreviewForPlanId !== null}
        onClose={() => dispatch(setShowPreviewModalForAutoPlan(null))}
      />

      {error && (
        <>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>We couldn't fetch the auto plans for this transaction.</p>
          <TextButton className="tw-pl-0 tw-mb-16px" onClick={() => dispatch(fetchAssignedPlans(uuid))}>
            Try again?
          </TextButton>
        </>
      )}

      {shouldShowCallToAction && (
        <ApplyAutoPlanCallToAction
          onApplyAutoPlanClicked={() => dispatch(setApplyAutoPlanModalVisible(true))}
        />
      )}

      <AssignedPlansByState
        assignedPlans={assignedPlans.map((plan) => ({
          ...plan,
          isLoading: idsBeingUpdated.has(plan.id),
        }))}
        onPlanClicked={(planId) => dispatch(showDetailsModalForAssignedPlan(planId))}
        onPlayOrPauseButtonClicked={(assignedPlanId, desiredState) =>
          dispatch(updateAssignedPlanState(assignedPlanId, desiredState))
        }
        onTrashButtonClicked={(assignedPlanId) => dispatch(confirmAssignedPlanDeletion(assignedPlanId))}
      />
    </div>
  );
};

AutoPlans.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  // Shape validation of the items inside the `assignedPlans` array is handled
  // by a component further down the tree, so we ignore it here.
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlans: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    // This is a JS Set, which has no PropType validator.
    // eslint-disable-next-line react/forbid-prop-types
    idsBeingUpdated: PropTypes.object.isRequired,
  }).isRequired,
  confirmingDeletionForPlanId: PropTypes.string,
  showingDetailsForPlanId: PropTypes.string,
  showingPreviewForPlanId: PropTypes.string,
};

AutoPlans.defaultProps = {
  confirmingDeletionForPlanId: null,
  showingDetailsForPlanId: null,
  showingPreviewForPlanId: null,
};

const mapStateToProps = (state) => ({
  uuid: state.tdpAutoPlansReducer.uuid,

  assignedPlans: state.tdpAutoPlansReducer.assignedPlans.plans,
  meta: {
    error: state.tdpAutoPlansReducer.assignedPlans.meta.error,
    isLoading: state.tdpAutoPlansReducer.assignedPlans.meta.isLoading,
    idsBeingUpdated: state.tdpAutoPlansReducer.assignedPlans.meta.idsBeingUpdated,
  },
  confirmingDeletionForPlanId: state.tdpAutoPlansReducer.assignedPlanDelete.showModalForId,
  showingDetailsForPlanId: state.tdpAutoPlansReducer.planDetails.meta.showDetailsModalForId,
  showingPreviewForPlanId: state.tdpAutoPlansReducer.planPreview.showModalForId,
});

export default connect(mapStateToProps)(AutoPlans);
