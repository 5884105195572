import React from "react";
import PropTypes from "prop-types";

const TabButton = React.forwardRef(({ active, children, ...props }, ref) => (
  <button
    ref={ref}
    type="button"
    className={`${active ? "tw-bg-tinted-gray-100 tw-text-neutral-gray-75" : "tw-bg-transparent tw-text-neutral-gray-50"} tw-border-0 tw-rounded-[6px] tw-px-[10px] tw-py-[6px] hover:tw-bg-tinted-gray-50 hover:tw-text-neutral-gray-75 tw-uppercase tw-font-bold tw-flex tw-items-center tw-gap-[4px]`}
    {...props}
  >
    {children}
  </button>
));

TabButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TabButton.defaultProps = {
  active: false,
};

export default TabButton;
