import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import modal from "../modal.module.css";
import button from "../button.module.css";
import Tasks from "../../../Tasks/components/People/Tasks";

const ViewAllTasksModal = (props) => {
  const {
    account,
    accountMilestoneNames,
    allAgents,
    editTaskFromViewAll,
    isAgent,
    onClose,
    person,
    showReassignModal,
    showRescheduleModal,
    showDeleteModal,
    showTaskDetails,
    taskFromViewAll,
    user,
  } = props;

  return (
    <Modal id="view-all-tasks-modal" className="person-details-modal" show={true} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col xs={9} className={modal.modalTitle}>
              Tasks
            </Col>
            <Col xs={3}>
              <div className="tw-flex tw-justify-between tw-items-center">
                <a
                  className={`tw-h-[30px] tw-flex tw-items-center tw-ml-[20px] ${button.btn} ${button.btnSm} ${button.btnBlue}`}
                  onClick={taskFromViewAll}
                >
                  Create New Task
                  <i className="fa fa-plus tw-ml-[10px]" />
                </a>
                <button type="button" className="close">
                  <span aria-hidden="true" onClick={onClose}>
                    ×
                  </span>
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="content">
        <div className={modal.modalBodyContainer}>
          <Tasks
            account={account}
            dueness="completed"
            user={user}
            taskable={person}
            accountMilestoneNames={accountMilestoneNames}
            agent={isAgent}
            agents={allAgents}
            editTask={editTaskFromViewAll}
            showReassignModal={showReassignModal}
            showRescheduleModal={showRescheduleModal}
            showDeleteModal={showDeleteModal}
            showTaskDetails={showTaskDetails}
          />
        </div>
        <Row style={{ paddingBottom: "40px" }}>
          <Col xs={12}>
            <button onClick={onClose} className={`${button.btn} ${button.btnLg} ${button.btnGray} pull-left`}>
              Cancel
            </button>
            <button
              onClick={onClose}
              className={`${button.btn} ${button.btnLg} ${button.btnBlue} pull-right`}
            >
              Done
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ViewAllTasksModal;
