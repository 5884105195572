import React, { Component } from "react";
import { Col, FormControl, FormGroup, Row } from "react-bootstrap";
import { Dropdown } from "../../shared/v1";
import interact_css from "./person_interactions.module.css";
import person_detail_css from "./person-detail-styles.module.css";
import DatePicker from "../../shared/DatePicker";
import moment from "moment";
import AiAssistant from "../../shared/AiAssistant/AiAssistant";
import Button from "@shared/v2/Button";

class PersonOtherInteractionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brivityOtherInteractionWrapper: {
        interaction_type: "other",
        comment: "",
        contingency_rule: {},
        contingent: false,
        externally_visible: false,
        interacted_at: moment(),
        marked_as_read: true,
        person_id: this.props.person.data.attributes.id,
        user_id: this.props.current_user_id,
        person_details_page: "true",
        authenticity_token: ReactOnRails.authenticityToken(),
      },
    };
  }

  handleChange(name, value) {
    this.setState((prevState) => ({
      brivityOtherInteractionWrapper: {
        ...prevState.brivityOtherInteractionWrapper,
        [name]: value,
      },
    }));
  }

  handleDateChange(value) {
    const newDate = moment(value);
    this.setState((prevState) => ({
      brivityOtherInteractionWrapper: {
        ...prevState.brivityOtherInteractionWrapper,
        interacted_at: newDate,
      },
    }));
  }

  submitInteraction = () => {
    const { submitOtherInteraction } = this.props;
    submitOtherInteraction(
      this.props.person.meta.interactions_url,
      this.state.brivityOtherInteractionWrapper,
    );
    this.handleChange("interaction_type", this.state.brivityOtherInteractionWrapper.interaction_type);
    this.handleChange("comment", "");
    this.handleChange("interacted_at", moment());
  };

  clearInteraction = () => {
    this.setState({
      brivityOtherInteractionWrapper: {
        interaction_type: "Other",
        comment: "",
        contingency_rule: {},
        contingent: false,
        externally_visible: false,
        interacted_at: moment(),
        marked_as_read: true,
        person_details_page: "true",
      },
    });
    this.refs.datepicker?.clear();
  };

  render() {
    const {
      brivityOtherInteractionWrapper: { interaction_type: interactionType },
    } = this.state;
    const selectedInteractionType = interactionType === -1 ? null : interactionType;

    return (
      <div>
        <Row>
          <Col sm={6} xs={12}>
            <label className={person_detail_css.personDetailLabel}>DATE </label>
            <DatePicker
              timeFormat={false}
              name={"interacted_at"}
              selected={this.state.brivityOtherInteractionWrapper.interacted_at}
              onChange={(value) => this.handleDateChange(value)}
              value={this.state.brivityOtherInteractionWrapper.interacted_at}
            />
          </Col>
          <Col sm={6} xs={12}>
            <label className={person_detail_css.personDetailLabel}>TYPE </label>
            <Dropdown
              isSearchable={false}
              isClearable={false}
              options={[
                { label: "Other", value: "other" },
                { label: "Pop By", value: "pop_by" },
                { label: "Mail", value: "mail" },
                { label: "Social Media", value: "social_media" },
              ]}
              onChange={(option) => this.handleChange("interaction_type", option.value)}
              placeholder="Select..."
              value={selectedInteractionType}
              variant="flatBlueishGray"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="m-t-10">
            <FormGroup style={{ marginBottom: "-3px" }}>
              <div className="tw-flex tw-justify-between tw-items-end tw-mb-[8px]">
                <label className={person_detail_css.personDetailLabel}>NOTES</label>
                <AiAssistant
                  textareaId="other-input"
                  messageType="Generic"
                  onInsertClick={(text) => this.handleChange("comment", text)}
                />
              </div>
              <FormControl
                id="other-input"
                componentClass="textarea"
                placeholder="Leave a note..."
                bsClass={interact_css.interactTextInput}
                value={this.state.brivityOtherInteractionWrapper.comment}
                name="comment"
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-justify-space-between">
              <Button size="medium" schema="tertiary" onClick={this.clearInteraction}>
                Clear
              </Button>
              <Button size="medium" onClick={this.submitInteraction}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PersonOtherInteractionForm;
