import React from "react";
import PropTypes from "prop-types";
import { useHref, useLinkClickHandler } from "react-router-dom";
import Rating from "../../../Main/Feedback/Rating";
import TextLink from "../../../../shared/v2/TextLink";

const MarketingSubheader = ({ reviewCount, averageRating, feedbackTabPath }) => (
  <div
    className="tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-space-x-8px tw-mb-24px"
    data-cy="marketing-subheader"
  >
    <Rating rating={averageRating} size="xl" className="tw-h-20px" />
    <TextLink
      onClick={useLinkClickHandler(feedbackTabPath)}
      href={useHref(feedbackTabPath)}
      data-cy="marketing-subheader-review-count"
    >
      {reviewCount > 0 ? `See all ${reviewCount} reviews` : "No reviews"}
    </TextLink>
  </div>
);

MarketingSubheader.propTypes = {
  reviewCount: PropTypes.number.isRequired,
  averageRating: PropTypes.number.isRequired,
  feedbackTabPath: PropTypes.string.isRequired,
};

export default MarketingSubheader;
