import React from "react";
import PropTypes from "prop-types";

import { ModalContext } from "./ModalContext";
import CLASSES from "./classes.json";

const { MODAL_TITLE_CLASSES } = CLASSES;

const ModalTitle = ({ children, className, variant: variantFromProps, ...props }) => {
  const { variant: variantFromContext } = React.useContext(ModalContext);
  const variant = variantFromProps || variantFromContext;

  const classes = `
    ${MODAL_TITLE_CLASSES.common}
    ${MODAL_TITLE_CLASSES[variant]}
    ${className}
  `;

  return (
    <h4 className={classes} {...props}>
      {children}
    </h4>
  );
};
ModalTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["classic", "modern"]),
};
ModalTitle.defaultProps = {
  children: null,
  className: "",
  variant: null,
};

export default ModalTitle;
