import { sortBy } from "../../../../Transactions/utils/helpers";

const movedDown = ({ from, to }) => from < to;

export const moved = ({ current, from, to }) => {
  const rangeStart = Math.min(from, to);
  const rangeEnd = Math.max(from, to);
  return current >= rangeStart && current <= rangeEnd;
};

export const positionDelta = ({ current, from, to }) => {
  if (from === current) {
    return to - from;
  }
  if (!moved({ current, from, to })) {
    return 0;
  }
  if (movedDown({ from, to }) && current <= to) {
    return -1;
  }
  return 1;
};

const applyOrder = ({ items, from, to, key }) => {
  const deltas = items.map((item) => positionDelta({ current: item[key], from, to }));

  return items.map((item, index) => ({
    ...item,
    [key]: item[key] + deltas[index],
  }));
};

export const reorderBy = ({ items, from, to, key }) => sortBy(applyOrder({ items, from, to, key }), key);

export const nextIndexForProperty = (collection, property) =>
  Math.max(
    ...[
      0, // Added to prevent errors on empty arrays
      ...collection.map((item) => item[property]),
    ],
  ) + 1;
