import React from "react";
import PropTypes from "prop-types";
import errorShape from "../model/error";
import Error from "../Error";
import { Prompt } from "../../../../shared/v2/Modal";

const DeleteFeedbackPrompt = ({ show, shownBy, error, isLoading, onDeleteFeedback, onCancel }) => (
  <Prompt
    title="Delete Showing Feedback"
    description={
      <>
        {error && <Error message={error.message} />}
        <span>
          Are you sure you want to delete the showing feedback by <b>{shownBy}</b>? Please note that this
          action cannot be undone.
        </span>
      </>
    }
    primaryOption="Delete"
    primaryProps={{ schema: "warning" }}
    isPrimaryLoading={isLoading}
    onPrimary={onDeleteFeedback}
    show={show}
    onCancel={onCancel}
  />
);

DeleteFeedbackPrompt.propTypes = {
  show: PropTypes.bool.isRequired,
  shownBy: PropTypes.string.isRequired,
  error: errorShape,
  isLoading: PropTypes.bool.isRequired,
  onDeleteFeedback: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeleteFeedbackPrompt.defaultProps = {
  error: null,
};

export default DeleteFeedbackPrompt;
