/* eslint-disable camelcase */
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import CmaReportMenu from "./cmaReportMenu";

const INLINE_DETAIL = "tw-flex tw-overflow-ellipse tw-mb-[10px]";
const LABEL_CLASS =
  "tw-text-[12px] tw-text-neutral-gray-50 tw-font-semibold tw-w-[75px] tw-mr-[14px] tw-inline-block";
const VALUE_CLASS = "tw-text-[12px] tw-text-neutral-gray-75 tw-font-semibold tw-no-wrap tw-flex-1";

const CmaReport = ({ id, name, location, last_viewed, view_count, created_at, deleteReportFn, cmaUrl }) => (
  <div className="tw-shadow-card tw-p-[24px] tw-bg-white tw-rounded-[7px] tw-overflow-y-auto tw-h-[224px]">
    <div className="tw-flex tw-overflow-ellipsis tw-mb-[14px]">
      <a
        rel="noreferrer"
        href={`https://${cmaUrl}/reports/${id}`}
        target="_blank"
        className="tw-text-[14px] tw-text-left tw-font-bold tw-text-neutral-gray-75 tw-truncate tw-flex-1 tw-border-0 tw-bg-transparent tw-p-0 tw-mr-[8px]"
      >
        {name}
      </a>
      <CmaReportMenu id={id} deleteReportFn={() => deleteReportFn(id)} />
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Address</span>
      <span className={VALUE_CLASS}>{location}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Last Viewed</span>
      <span className={VALUE_CLASS}>{last_viewed ? moment(last_viewed).format("MM/DD/YY") : "N/A"}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Created</span>
      <span className={VALUE_CLASS}>{moment(created_at).format("MM/DD/YY")}</span>
    </div>
    <div className={INLINE_DETAIL}>
      <span className={LABEL_CLASS}>Total Views</span>
      <span className={VALUE_CLASS}>{view_count} views</span>
    </div>
  </div>
);

CmaReport.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  view_count: PropTypes.number,
  last_viewed: PropTypes.string,
  created_at: PropTypes.string,
  cmaUrl: PropTypes.string,
  deleteReportFn: PropTypes.func,
};
CmaReport.defaultProps = {
  id: null,
  name: "",
  location: "",
  last_viewed: "",
  created_at: "",
  view_count: "",
  cmaUrl: "",
  deleteReportFn: null,
};

export default CmaReport;
