import React from "react";
import IconButton from "@shared/v2/IconButton";
import Modal from "@shared/v2/Modal";
import { PauseSolidV6, PlaySolidV6, TrashCanSolidV6 } from "@shared/v2/Icomoon";
import AssignedPlanDetail from "../../../AssignedPlans/components/AssignedPlanDetail";
import auto_plans from "./auto_plans.module.css";

const PlanDetails = (props) => {
  const {
    activeAssignedActionId,
    assignedPlan,
    closeModal,
    deleteAssignedActionModalOpen,
    isActionUpdating,
    previewAssignedAction,
    showAssignedActionDeleteModal,
    showDeleteAssignedPlanModal,
    updateAction,
  } = props;

  const updateCallback = () => {
    // TODO: this doesn't exist, so commenting out for now
    // getAssignedPlan(`/assigned_plans/${assignedPlan.id}`);
  };

  const updatePlanState = (state) => {
    const { updateAssignedPlan, assignedPlan } = props;
    const { id } = assignedPlan;
    const form = { state: state };
    updateAssignedPlan(`/assigned_plans/${id}`, form, updateCallback);
  };

  const plan = () => {
    switch (assignedPlan.state) {
      case "completed":
        return { state: "Completed", class: auto_plans.completed };
      case "deleted":
        return { state: "Deleted", class: auto_plans.deleted };
      case "started":
        return { state: "Running", class: auto_plans.started };
      case "paused":
        return { state: "Paused", class: auto_plans.paused };
      default:
        return {};
    }
  };

  return (
    <Modal
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[900px] tw-max-h-[90vh] tw-w-full tw-h-[560px] tw-flex tw-flex-col tw-gap-[32px]"
      id="show-auto-plan-details-modal"
      className="person-details-modal"
      onHide={closeModal}
      show
    >
      <Modal.Header
        closeButton
        size="small"
        title={
          <div className="tw-flex tw-justify-between tw-gap-[16px]">
            <div className="tw-flex tw-gap-[12px] tw-mr-auto">
              <span>{`Auto Plan: ${assignedPlan.name}`}</span>
              <span className={`${auto_plans.state} ${plan().class}`}>{plan().state}</span>
            </div>
            <div className="tw-flex tw-items-start tw-gap-[8px]">
              {assignedPlan.state == "paused" && (
                <IconButton onClick={updatePlanState.bind(this, "started")} schema="tertiary">
                  <PlaySolidV6 />
                </IconButton>
              )}
              {assignedPlan.state == "started" && (
                <IconButton onClick={updatePlanState.bind(this, "paused")} schema="tertiary">
                  <PauseSolidV6 />
                </IconButton>
              )}
              {assignedPlan.state != "deleted" && assignedPlan.state != "completed" && (
                <IconButton onClick={() => showDeleteAssignedPlanModal(assignedPlan)} schema="tertiary">
                  <TrashCanSolidV6 />
                </IconButton>
              )}
            </div>
          </div>
        }
      />

      <Modal.Body className="tw-flex-1 tw-h-full tw-overflow-hidden">
        <AssignedPlanDetail
          isAssignedActionUpdating={isActionUpdating}
          assignedPlan={assignedPlan}
          previewAssignedAction={previewAssignedAction}
          activeAssignedActionId={activeAssignedActionId}
          onActionUpdate={updateAction}
          onShowDeleteModal={showAssignedActionDeleteModal}
          isDeleteModalOpen={deleteAssignedActionModalOpen}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PlanDetails;
