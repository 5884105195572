/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PlaceCustomv6 = (props) => (
  <Icon {...props} name="PlaceCustomv6">
    <path d="M8.676 2H3.408v12h2.105V3.854h2.878c1.185 0 2.049.753 2.049 1.909-.007 1.15-.864 1.909-2.056 1.909h-.836L6.87 9.519h1.806c2.495 0 3.916-1.728 3.916-3.756C12.592 3.728 11.191 2 8.676 2z" />
  </Icon>
);

export default PlaceCustomv6;
