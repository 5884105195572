import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import project from "./projects.module.css";
import css from "./person-detail-styles.module.css";
import CollapsibleElement from "./CollapsibleElement";

const Projects = () => {
  const projects = useSelector((state) => state.personDetail.projects);

  if (!projects?.length) return null;

  return (
    <CollapsibleElement title="Projects" id="pdp-projects">
      <Row>
        {projects.map(({ id, name, type }) => (
          <div key={id} className={css.materialDesignBox}>
            <Row>
              <Col xs={12}>
                <div className={`${project.projectIcon} pull-left`}>
                  <a href={`/projects/${id}`} target="_blank">
                    <i className="fa fa-rocket text-info" />
                  </a>
                </div>
                <div className="overflow">
                  <a href={`/projects/${id}`} target="_blank">
                    {name}
                  </a>
                  <div className={project.type}>{type}</div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Row>
    </CollapsibleElement>
  );
};

export default Projects;
