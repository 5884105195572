import React from "react";
import { useDispatch } from "react-redux";

import TextComboInput from "@shared/v2/TextComboInput";

import { defaultLink } from "../../ModalViews/styles";
import { setPersonValue } from "../../../reducers/contactsReducer";
import { useContactPerson } from "../../../reducers/contactsReducer/selectors";

const DefaultLinks = () => {
  const dispatch = useDispatch();
  const { zillowReviews, googleReviews, facebookReviews, realtorComReviews, yelpReviews, truliaReviews } =
    useContactPerson();

  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px]">
      <div className="tw-flex tw-justify-between">
        <TextComboInput
          value={zillowReviews}
          containerClassName={defaultLink}
          label="Zillow"
          onChange={(e) => dispatch(setPersonValue({ key: "zillowReviews", value: e.target.value }))}
        />
        <TextComboInput
          value={googleReviews}
          containerClassName={defaultLink}
          label="Google"
          onChange={(e) => dispatch(setPersonValue({ key: "googleReviews", value: e.target.value }))}
        />
      </div>
      <div className="tw-flex tw-justify-between">
        <TextComboInput
          value={facebookReviews}
          containerClassName={defaultLink}
          label="Facebook"
          onChange={(e) => dispatch(setPersonValue({ key: "facebookReviews", value: e.target.value }))}
        />
        <TextComboInput
          value={realtorComReviews}
          containerClassName={defaultLink}
          label="Realtor.com"
          onChange={(e) => dispatch(setPersonValue({ key: "realtorComReviews", value: e.target.value }))}
        />
      </div>
      <div className="tw-flex tw-justify-between">
        <TextComboInput
          value={yelpReviews}
          containerClassName={defaultLink}
          label="Yelp"
          onChange={(e) => dispatch(setPersonValue({ key: "yelpReviews", value: e.target.value }))}
        />
        <TextComboInput
          value={truliaReviews}
          containerClassName={defaultLink}
          label="Trulia"
          onChange={(e) => dispatch(setPersonValue({ key: "truliaReviews", value: e.target.value }))}
        />
      </div>
    </div>
  );
};

export default DefaultLinks;
