import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import capitalize from 'capitalize';

export default class TimelineLeadCreateUserNotificationEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.state = {
      show_modal: false,
    }
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.leadNameText = this.leadNameText.bind(this)
  }

  showModal = () => {
    this.setState({show_modal: true})
  }

  hideModal = () => {
    this.setState({show_modal: false})
  }

  details = () => {
    let description = []
    let fields = this.event.timeline_fields

    for (var key in fields){
      if(["name", "full name"].includes(key)){
        continue
      }
      const titleized_key = key.split("_").map(element =>{return capitalize(element)}).join(" ")
      description.push(titleized_key + ": " + fields[key])
    }

    return description.map(description =>{
      return(
        <div key={description} className="row details-row">
          {description}
        </div>
      )
    })
  }

  leadNameText = () => {
    if(this.event.timeline_fields.hasOwnProperty("name")) {
      return this.event.timeline_fields['name'] + ' '
    } else {
      return 'No name '
    }
  }

  row = () => {
    return (
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-user timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">{this.leadNameText()}</span>
            became a new contact
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  modal = () => {
    return(
      this.state.show_modal && <Modal
        bsSize='sm'
        show={this.state.show_modal}
        onHide={this.hideModal}
        className="timeline-content"
        >
          <div className="timeline-event timeline-event-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                Contact Added
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <span className="bold-detail">{this.leadNameText()}</span>
                became a new contact
              </div>
              <div className="row bold-detail details-row details-header">
                Details:
              </div>
              {this.details()}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </div>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
        { this.modal() }
      </div>
    )
  }
}
