/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import axios from "axios";
import { tryCatchHandlr } from "@shared/Utilities";

export const getHomeValue = async ({ streetAddress, city, state, postalCode }) => {
  const [response, error] = await tryCatchHandlr(
    axios.get("/market_reports/home_value", {
      params: {
        streetAddress,
        city,
        state,
        postalCode,
      },
    }),
  );

  return { response, error };
};
