import React from "react";
import PropTypes from "prop-types";
import TextButton from "@shared/v2/TextButton";

const PdpLink = ({ personId }) =>
  personId ? (
    <TextButton
      onClick={() => window.open(`/people/${personId}`, "_blank", { noreferrer: true })}
      className="tw-absolute tw-top-[4px] tw-right-[52px] !tw-m-0"
      size="medium"
    >
      See Full Details
    </TextButton>
  ) : null;

PdpLink.propTypes = {
  personId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PdpLink.defaultProps = {
  personId: null,
};

export default PdpLink;
