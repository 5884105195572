import React, { Component } from 'react';
import capitalize from 'capitalize';

export default class MarketReportViewedEvent extends Component {
  constructor(props, context){
    super(props, context)

    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event
  }

  details = () => {
    let rows = [];
    const fields = this.event.timeline_fields;

    return (
      <div>
        <tr>
          Report Name: <a href={fields.report_url} target="_blank">{fields.report_name}</a>
        </tr>
      </div>
    )
  }

  row = () => {
    return (
      <div className="timeline-event market-report-timeline-event">
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-home timeline-icon-circle"/>
          </div>
          <div className="col-xs-7">
            <span className="bold-detail">
              {this.event.timeline_fields.viewed_by}
            </span>
             {' viewed Market Report'}
            <div className="timeline-description-text">
              {this.details()}
            </div>
          </div>
          <div className="col-xs-1" />
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        { this.row() }
      </div>
    )
  }
}
