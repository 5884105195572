const modalsClosed = {
  isModalOpen: false,
  isAutoPlanDeleteModalOpen: false,
  isAutoPlanPreviewModalOpen: false,
  isAutoPlanShareModalOpen: false,
};

const initialState = {
  ...modalsClosed,
  isFetchingAutoPlans: false,
  isCloningAutoPlan: false,
  displayAlert: false,
};

const autoPlans = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_AUTO_PLANS":
      return {
        ...state,
        isFetchingAutoPlans: action.isFetchingAutoPlans,
      };
    case "AUTO_PLANS_SUCCESS":
      return {
        ...state,
        isFetchingAutoPlans: false,
        autoPlans: action.autoPlans,
      };
    case "AUTO_PLANS_FAILURE":
      return {
        ...state,
        isFetchingAutoPlans: false,
      };
    case "OPEN_AUTO_PLAN_DELETE_MODAL":
      return {
        ...state,
        isAutoPlanDeleteModalOpen: true,
        activeAutoPlanId: action.activeAutoPlanId,
      };

    case "OPEN_AUTO_PLAN_SHARE_MODAL":
      return {
        ...state,
        isAutoPlanShareModalOpen: true,
        activeAutoPlanId: action.activeAutoPlanId,
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        ...modalsClosed,
        activeAutoPlanId: null,
      };

    case "CLOSE_AUTO_PLAN_DELETE_MODAL":
      return {
        ...state,
        ...modalsClosed,
        activeAutoPlanId: null,
      };

    case "CLOSE_AUTO_PLAN_SHARE_MODAL":
      return {
        ...state,
        ...modalsClosed,
        activeAutoPlanId: null,
        emailErrors: null,
      };

    case "CLOSE_AUTO_PLAN_SHARED_ALERT":
      return {
        ...state,
        displayAlert: false,
      };

    case "SHOW_NEW_AUTO_PLAN_MODAL":
      return {
        ...state,
        isModalOpen: true,
      };

    case "SHOW_AUTO_PLAN_PREVIEW_MODAL":
      return {
        ...state,
        isAutoPlanPreviewModalOpen: true,
        autoPlanPreview: action.autoPlan,
      };

    case "PREVIEW_AUTO_PLAN_ACTION":
      return {
        ...state,
        activeAutoPlanActionId: action.actionId,
      };

    case "AUTO_PLAN_FORM_UPDATE":
      return {
        ...state,
        autoPlanForm: {
          ...state.autoPlanForm,
          [action.name]: action.value,
          type: "AutoPlan",
        },
      };
    case "AUTO_PLAN_CREATE_FAILURE":
      return {
        ...state,
        modalErrorMessage: action.errors,
        modalHasError: true,
        isModalSubmitting: false,
      };
    case "AUTO_PLAN_CREATE_PENDING":
      return {
        ...state,
        modalErrorMessage: null,
        modalHasError: false,
        isModalSubmitting: true,
      };

    case "CLOSE_NEW_AUTO_PLAN_MODAL":
      return {
        ...state,
        ...modalsClosed,
        modalHasError: false,
        modalErrorMessage: false,
        autoPlanForm: {},
      };

    case "DELETE_AUTO_PLAN_PENDING":
      return {
        ...state,
        isDeletingAutoPlan: true,
      };

    case "DELETE_AUTO_PLAN_SUCCESS":
      return {
        ...state,
        ...modalsClosed,
        isDeletingAutoPlan: false,
        autoPlans: state.autoPlans.filter((a) => a.id != state.activeAutoPlanId),
        activeAutoPlanId: null,
      };

    case "DELETE_AUTO_PLAN_FAILURE":
      return {
        ...state,
        isDeletingAutoPlan: false,
        activeAutoPlanId: null,
      };

    case "CLONE_AUTO_PLAN_PENDING":
      return {
        ...state,
        isCloningAutoPlan: true,
        errorMessage: null,
        hasError: false,
      };
    case "CLONE_AUTO_PLAN_SUCCESS":
      return {
        ...state,
        isCloningAutoPlan: false,
      };
    case "CLONE_AUTO_PLAN_FAILURE":
      return {
        ...state,
        isCloningAutoPlan: false,
        hasError: true,
        errorMessage: action.errorMessage,
      };

    case "SHARE_AUTO_PLAN_PENDING":
      return {
        ...state,
      };
    case "SHARE_AUTO_PLAN_SUCCESS":
      return {
        ...state,
        ...modalsClosed,
        activeAutoPlanId: null,
        emailErrors: null,
        displayAlert: true,
        sharedPlanName: action.sharedPlanName,
        numberShared: action.numberShared,
      };
    case "SHARE_AUTO_PLAN_FAILURE":
      return {
        ...state,
        emailErrors: action.errors,
      };

    default:
      return state;
  }
};

export default autoPlans;
