import { useEffect, useState } from "react";
import { getAiToken } from "@shared/externalApi";

const useAiToken = () => {
  const [data, setData] = useState({});
  const getToken = () => getAiToken().then(setData);

  useEffect(() => {
    // token payload || {}
    const payload = JSON.parse(atob(data.token?.split(".")[1] || "e30="));
    let timeoutId = null;

    if (payload.exp) {
      // in the unlikely but possible event that a user remains interacting with the ai
      // assistant for longer than the token lasts, give it a little refresh
      const timeout = new Date((payload.exp - 30) * 1000).valueOf() - Date.now();
      timeoutId = setTimeout(getToken, Math.max(0, timeout));
    }

    return () => {
      // if unmounted cancel refresh
      clearTimeout(timeoutId);
    };
  }, [data.token]);

  useEffect(() => {
    getToken();
  }, []);

  return data;
};

export default useAiToken;
