/* eslint-disable camelcase */
import axios from "axios";
import ReactOnRails from "react-on-rails";

// get city and zipcode by search input
export const getServiceAreaOptionsByInput = (term) =>
  axios.get(`/api/v4/account_people/service_area_search?q=${term}`, {
    authenticity_token: ReactOnRails.authenticityToken(),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

// get user settings table data
export const getUsersForAccountSettingApiFn = () =>
  axios.get(`/api/v4/account_people/`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const patchSuspendUserApiFn = (userId, action) =>
  axios.patch(`/users/${userId}/${action}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

// search for revenue partner
export const getRevenuePartnerOptions = (searchText) =>
  axios.get(`/api/v4/account_people/revenue_partner_person_search?q=${searchText}`, {
    authenticity_token: ReactOnRails.authenticityToken(),
  });

export const sendInviteEmail = (userId) => axios.patch(`/users/${userId}/invite_to_okta`);
