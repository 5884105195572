import React from 'react';
import { Modal } from 'react-bootstrap';

class HubspotRequestDemoModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (typeof hbspt == 'undefined') {
      const head = document.getElementsByTagName('head').item(0)
      const js = document.createElement('script')
      js.setAttribute('type', 'text/javascript')
      js.setAttribute('src', '//js.hsforms.net/forms/v2.js')
      js.setAttribute('defer', 'true')
      js.async = true
      head.appendChild(js)
    }
  }

  render() {
    const { closeModal } = this.props;

    if (this.props.show) {
      hbspt.forms.create({
        portalId: "6041126",
        formId: "c0592518-30e7-46d6-8b94-f6d8e79af7e7",
        target: "#hb-request-demo .modal-body"
      })
    }

    return (
      <Modal
        id='hb-request-demo'
        className='modal fade'
        backdrop='static'
        role='dialog'
        tabIndex='-1'
        onHide={closeModal}
        show={this.props.show}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        </Modal.Body>
      </Modal>
    )
  }
}

export default HubspotRequestDemoModal;