/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useMemo } from "react";
import { arrayOf, shape } from "prop-types";
import { connect, useDispatch } from "react-redux";

import Checkbox from "@shared/v2/Checkbox";
import IconButton from "@shared/v2/IconButton";
import { Download, Trash } from "@shared/v2/Icomoon";

import { SortIcon } from "../../../../../Transactions/components/icons";
import UploadDoc from "./UploadedDoc";

import { toggleDeleteModal, toggleDocumentsSelected, sortByCDAType } from "../../../Docs/actions/creators";

const LABEL_STYLE = "tw-text-12d tw-text-gray-50 tw-font-semibold";
const context = "CDA-document";

const mapDispatchToProps = (dispatch) => ({
  onToggleDocumentsSelected: (ctx, docs, checkStatus) =>
    dispatch(toggleDocumentsSelected(ctx, docs, checkStatus)),
  onToggleDeleteModal: (documentIds) => dispatch(toggleDeleteModal(documentIds, true)),
  onSortByType: (sortType) => dispatch(sortByCDAType(sortType)),
});

const mapStateToProps = (state) => ({
  documents: state.tdpDocumentsReducer.CDAdocuments,
  sortByCDA: state.tdpDocumentsReducer.sortByCDA,
  sortCDADirection: state.tdpDocumentsReducer.sortCDADirection,
});

const UploadedDocsSorts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({
  onToggleDocumentsSelected,
  documents,
  onToggleDeleteModal,
  onSortByType,
  sortByCDA,
  sortCDADirection,
}) => {
  const allChecked = useMemo(() => documents.every((doc) => doc.isSelected), [documents]);

  const shouldOpenBulkActions = useMemo(() => documents.some((doc) => doc.isSelected), [documents]);

  const handleOnChangeAll = (checkStatus) => {
    onToggleDocumentsSelected(
      context,
      documents.map((doc) => doc.id),
      checkStatus,
    );
  };

  const handleBulkDownload = () => {
    const documentsToDownload = documents.filter((doc) => doc.isSelected);

    documentsToDownload.forEach((doc) => {
      // TODO the ideal solution would be to create a zip file with all the documents

      // This requires the user to accept popups
      window.open(doc.expiringUrl, "_blank");
    });
  };

  const handleBulkDelete = () => {
    const documentIds = documents.filter((doc) => doc.isSelected).map((doc) => doc.id);
    onToggleDeleteModal(documentIds);
  };

  return (
    <div className="tw-flex tw-items-center tw-h-[24px]">
      <Checkbox
        checked={allChecked}
        onChange={(e) => {
          handleOnChangeAll(e.target.checked);
        }}
      />
      <div className="tw-flex tw-items-center tw-gap-[6px] tw-ml-[8px] tw-w-[211px]">
        <div className={LABEL_STYLE}>Name</div>
        <div
          onClick={() => onSortByType("fileName")}
          onKeyDown={() => onSortByType("fileName")}
          role="button"
          aria-label="Sort by Name"
        >
          <SortIcon sortBy={sortByCDA === "fileName" && sortCDADirection} />
        </div>
        {shouldOpenBulkActions && (
          <div className="tw-flex tw-items-center tw-ml-10px" data-cy="documents-table-bulk-actions">
            <IconButton
              schema="tertiary"
              size="small"
              className="tw-mr-8px"
              onClick={handleBulkDownload}
              data-cy="documents-table-download-button"
            >
              <Download size="m" />
            </IconButton>
            <IconButton
              schema="misc-trash"
              size="small"
              onClick={handleBulkDelete}
              data-cy="documents-table-delete-button"
            >
              <Trash className="tw-text-neutral-gray-75" size="m" />
            </IconButton>
          </div>
        )}
      </div>
      <div className={`${LABEL_STYLE} tw-mr-[16px]`}>Uploaded By</div>
      <div className="tw-flex tw-items-center tw-gap-[6px]">
        <div className={LABEL_STYLE}>Time Uploaded</div>
        <div
          onClick={() => onSortByType("uploadedAt")}
          onKeyDown={() => onSortByType("uploadedAt")}
          role="button"
          aria-label="Sort by Name"
        >
          <SortIcon sortBy={sortByCDA === "uploadedAt" && sortCDADirection} />
        </div>
      </div>
    </div>
  );
});

const UploadedDocs = ({ docs }) => {
  const dispatch = useDispatch();

  const handleDeleteDocument = (documentIds) => {
    dispatch(toggleDeleteModal(documentIds, true));
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-[18px] tw-mt-[24px]">
      <UploadedDocsSorts />
      {docs.map((doc, idx) => (
        <UploadDoc
          key={doc.id}
          doc={doc}
          handleDeleteDocument={() => handleDeleteDocument([doc.id])}
          idx={idx}
        />
      ))}
    </div>
  );
};

export default UploadedDocs;

UploadedDocs.propTypes = {
  docs: arrayOf(shape({})),
};

UploadedDocs.defaultProps = {
  docs: [],
};
