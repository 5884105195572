/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Home = (props) => (
  <Icon {...props} name="Home">
    <path d="M13.555 9.57v3.981c0 .37-.297.667-.667.667H9.665a.334.334 0 0 1-.333-.333v-3.111a.334.334 0 0 0-.333-.333h-2a.334.334 0 0 0-.333.333v3.111c0 .183-.15.333-.333.333H3.11a.666.666 0 0 1-.667-.667V9.57c0-.1.044-.194.122-.258l5.223-4.3c.122-.1.3-.1.425 0l5.223 4.3a.34.34 0 0 1 .119.258zm2.323-1.692-2.322-1.914V2.116a.334.334 0 0 0-.333-.333h-1.556a.334.334 0 0 0-.333.333v2.017L8.848 2.086a1.332 1.332 0 0 0-1.695 0L.122 7.878a.333.333 0 0 0-.044.469l.708.861a.333.333 0 0 0 .469.044l6.534-5.381c.122-.1.3-.1.425 0l6.534 5.381a.335.335 0 0 0 .47-.044l.708-.861a.333.333 0 0 0-.047-.469z" />
  </Icon>
);

export default Home;
