/* global tinyMCE */
import { useEffect, useState } from "react";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const validateForm = (formData) => {
  let errors = null;
  if (!formData.get("[message][subject]")?.trim()) {
    errors = { ...errors, subject: "is required" };
  }
  if (!tinyMCE.activeEditor.getContent()?.trim()) {
    errors = { ...errors, body: "is required" };
  }
  return errors;
};

const useMassEmail = (open) => {
  const [state, _setState] = useState({ data: null, errors: null, loading: false });
  const setState = (newState) => _setState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    if (!open || state.data) return () => {};
    const abortController = new AbortController();
    setState({ loading: true });
    caseTransformingAxios
      .get("/api/v4/interactions/email_all_form", { signal: abortController.signal })
      .then((res) => setState({ data: res.data, errors: null, loading: false }))
      .catch((err) => setState({ errors: err?.response?.data, loading: false }));
    return () => abortController.abort();
  }, [open, state.data]);

  return {
    actions: {
      sendEmail: (form) => {
        setState({ loading: true });
        const errors = validateForm(form);
        if (!errors) {
          setState({ loading: true });
          return caseTransformingAxios
            .post("/api/v4/interactions/email_all", form)
            .then((res) => {
              setState({ loading: false });
              return res.data;
            })
            .catch((err) => {
              setState({ errors: { global: err?.response?.data?.content }, loading: false });
              throw err;
            });
        }
        setState({ errors });
        return Promise.reject();
      },
      parseTemplate: (id) =>
        caseTransformingAxios
          .get("/api/v4/interactions/parsed_email_template", {
            params: { id },
          })
          .then((res) => res.data)
          .catch((err) => console.log(err)),
    },
    ...state,
  };
};

export default useMassEmail;
