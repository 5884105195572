import React from "react";
import PropTypes from "prop-types";
import CloseButton from "./CloseButton";

const BASE_CLASSES = "tw-p-15px tw-border tw-border-solid tw-rounded-4px";

const ALERT_VARIANTS_STYLE_CLASSES = {
  success: "tw-text-color-3c763d tw-bg-color-dff0d8 tw-border-color-d6e9c6",
  info: "tw-text-color-31708f tw-bg-color-d9edf7 tw-border-color-bce8f1",
  warning: "tw-text-color-8a6d3b tw-bg-color-fcf8e3 tw-border-color-faebcc",
  danger: "tw-text-color-a94442 tw-bg-color-f2dede tw-border-color-ebccd1",
};

const variantClasses = (variant) => {
  if (variant in ALERT_VARIANTS_STYLE_CLASSES) {
    return ALERT_VARIANTS_STYLE_CLASSES[variant];
  }

  return "tw-border-transparent";
};

const marginClasses = (deprecatedOverrideMarginClasses) => {
  if (deprecatedOverrideMarginClasses !== null) {
    return deprecatedOverrideMarginClasses;
  }

  return "tw-mb-20px";
};

export const Alert = ({ variant, deprecatedOverrideMarginClasses, children, className, ...props }) => {
  const classes = `
    ${BASE_CLASSES}
    ${variantClasses(variant)}
    ${marginClasses(deprecatedOverrideMarginClasses)}
    ${className}
  `;

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
Alert.propTypes = {
  variant: PropTypes.oneOf(["success", "info", "warning", "danger"]).isRequired,
  deprecatedOverrideMarginClasses: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
Alert.defaultProps = {
  deprecatedOverrideMarginClasses: null,
  children: null,
  className: "",
};

Alert.CloseButton = CloseButton;

export default Alert;
