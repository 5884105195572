const formatNumber = (number, decimals = 0) => {
  const defaultOptions = {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }
  return new Intl.NumberFormat('en-US',
  { ...defaultOptions }).format(number)
};

const formatCurrency = (number, decimals = 2) => {
  const defaultOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }

  return new Intl.NumberFormat('en-US', defaultOptions).format(number)
};

const apiNumber = (value, digits, comma=true) => Number(
  formatNumber(value, digits)
  .replace((comma ? '':/,/g), '')
);

const format = {
    number: (number, decimals) => formatNumber(number, decimals),
    currency: (number, decimals) => formatCurrency(number, decimals),
    apiNumber: (value, digits, comma) => apiNumber(value, digits, comma),
}

export default format;
