import React from "react";
import Proptypes from "prop-types";
import moment from "moment";
import { tdpDates } from "../../../../shared/HOCs/withRedux";
import { EXPIRATION_DATE } from "../../../../shared/reduxRoutes/tdpDatesRoutes";
import ModifyDate from "./ModifyDate";

const getExpiredCounter = (date) => {
  const isPositive = Math.sign(moment().diff(moment(date)));
  let result = "-";
  if (Number.isNaN(isPositive)) {
    result = "-";
  } else if (isPositive === 1) {
    result = "Expired";
  } else {
    result = `${Math.abs(moment().diff(moment(date), "days"))} Days`;
  }
  return result;
};

const Expiration = ({ expiration }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[2px] tw-px-[12px] tw-h-[44px] tw-min-h-[44px] tw-rounded tw-bg-tinted-gray-50 tw-text-gray-75 tw-text-[14px] tw-font-semibold tw-leading-[20px]">
    Expiration
    <span className="tw-font-normal">{getExpiredCounter(expiration.date)}</span>
    <ModifyDate date={expiration} />
  </div>
);

Expiration.propTypes = {
  expiration: Proptypes.shape({ id: Proptypes.string, name: Proptypes.string, date: Proptypes.string }),
};
Expiration.defaultProps = {
  expiration: null,
};

const ExpirationWrapped = tdpDates({
  WrappedComponent: Expiration,
  states: EXPIRATION_DATE,
});

export default ExpirationWrapped;
