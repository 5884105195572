import React from 'react';
import AgentCallsRow from "./AgentCallsRow";
import {ReportHeaderSortContainer, TaskTotalsRow} from "./index";
import AgentCallsHeaders from "./AgentCallsHeaders";
import AgentCallsTotalRow from "./AgentcallsTotalRow";

const AgentCallsHolder = (props) => {
  const agentCallData = props.agentCallData.callData;
  return(
    <div>
      {
        agentCallData.map((a) =>
        <AgentCallsRow
          key={a.id}
          agent={a} />
      )
    }

    {
      agentCallData.length > 1 &&
        <AgentCallsTotalRow
          reportData={agentCallData} />
    }
  </div>
)
}
export default AgentCallsHolder;
