import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TrackingNumbers from "./TrackingNumbers/Settings/TrackingNumbers";
import { getTrackingNumbers, getAgents } from "./TrackingNumbers/services";
import TrackingNumbersActivity from "./TrackingNumbers/TrackingNumbersActivity";
import TrackingNumbersLogs from "./TrackingNumbers/TrackingNumbersLogs";

import useTrackingNumberSettings from "./TrackingNumbers/Settings/useTrackingNumberSettings";
import useTrackingNumberActivity from "./TrackingNumbers/useTrackingNumberActivity";
import useTrackingNumberLogs from "./TrackingNumbers/useTrackingNumberLogs";
import { useCurrentUser } from "../reducers/layoutReducer/selectors";

const PAGES = ["reporting", "logs", "settings"];

const mapTrackingNumberOptions = (trackingNumbers) =>
  trackingNumbers.map((tn) => ({
    label: tn.name,
    value: tn.id,
  }));

const PhoneSystem = () => {
  const currentUser = useCurrentUser();
  const [selectedPage, setSelectedPage] = useState("reporting");
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [logFilters, setLogFilters] = useState({});
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [selectedTrackingNumbers, setSelectedTrackingNumbers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState(["id", "desc"]);

  const {
    agentsWithRoutingNumbers,
    trackingNumbers: settingsTrackingNumbers,
    sources,
  } = useTrackingNumberSettings();
  const { activity, stats } = useTrackingNumberActivity({
    selectedTrackingNumbers,
    trackingNumbers,
    minDate,
    maxDate,
  });
  const { callLogs, logsCount } = useTrackingNumberLogs({
    selectedTrackingNumbers,
    trackingNumbers,
    minDate,
    maxDate,
    offset,
    logFilters,
    sort,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const view = searchParams.get("view");
    if (view && PAGES.includes(view)) {
      setSelectedPage(view);
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      const [trackingNumbersResult, agentsResult] = await Promise.all([getTrackingNumbers(), getAgents()]);
      const mappedTrackingNumberOptions = mapTrackingNumberOptions(trackingNumbersResult);

      setTrackingNumbers(mappedTrackingNumberOptions);
      setSelectedTrackingNumbers(mappedTrackingNumberOptions);
      setAgents(agentsResult.agents);
    };

    load();
  }, []);

  const getSection = () => {
    switch (selectedPage) {
      case "settings":
        return (
          <TrackingNumbers
            isAdmin={currentUser.account.isAdmin}
            agentId={currentUser.id}
            agentsWithRoutingNumbers={agentsWithRoutingNumbers}
            trackingNumbers={settingsTrackingNumbers}
            sources={sources}
            isPlace={currentUser.account.isPlace}
          />
        );
      case "reporting":
        return (
          <TrackingNumbersActivity
            isAdmin={currentUser.account.isAdmin}
            agents={agents}
            activity={activity}
            stats={stats}
            selectedTrackingNumbers={selectedTrackingNumbers}
            trackingNumbers={trackingNumbers}
            handleTrackingNumberFilterOnChange={(o) => setSelectedTrackingNumbers(o)}
            minDate={minDate}
            maxDate={maxDate}
            setMinDate={setMinDate}
            setMaxDate={setMaxDate}
            chosenView={selectedPage}
            logFilters={logFilters}
            setLogFilters={setLogFilters}
            showSettingsPage={() => setSelectedPage("settings")}
          />
        );
      case "logs":
      default:
        return (
          <TrackingNumbersLogs
            agents={agents}
            isAdmin={currentUser.account.isAdmin}
            sort={sort}
            setSort={setSort}
            offset={offset}
            setOffset={setOffset}
            logsCount={logsCount}
            callLogs={callLogs}
            selectedTrackingNumbers={selectedTrackingNumbers}
            trackingNumbers={trackingNumbers}
            handleTrackingNumberFilterOnChange={(o) => setSelectedTrackingNumbers(o)}
            minDate={minDate}
            maxDate={maxDate}
            setMinDate={setMinDate}
            setMaxDate={setMaxDate}
            chosenView={selectedPage}
            logFilters={logFilters}
            setLogFilters={setLogFilters}
          />
        );
    }
  };

  return (
    <div className="tw-h-full tw-overflow-y-auto tw-bg-tinted-gray-50">
      <div className="tw-flex tw-flex-row tw-gap-[40px] tw-ml-0 tw-sticky tw-top-0 tw-shadow tw-items-stretch tw-bg-white tw-z-10 tw-border-solid tw-border-b tw-border-b-gray-10 tw-border-t-0 tw-border-r-0 tw-border-l-0">
        <span className="tw-ml-32px tw-mt-8px tw-mb-12px tw-text-gray-75 tw-text-28px">Phone System</span>
        <div className="tw-flex tw-flex-row tw-gap-32px tw-mb-14px tw-items-end">
          <MenuOption
            name="Reporting"
            value="reporting"
            onClick={setSelectedPage}
            isSelected={selectedPage === "reporting"}
          />
          <MenuOption
            name="Logs"
            value="logs"
            onClick={setSelectedPage}
            isSelected={selectedPage === "logs"}
          />
          <MenuOption
            name="Settings"
            value="settings"
            onClick={setSelectedPage}
            isSelected={selectedPage === "settings"}
          />
        </div>
      </div>
      <div className="tw-p-16px">{getSection()}</div>
    </div>
  );
};

export default PhoneSystem;

const MenuOption = ({ name, value, onClick, isSelected }) => (
  <button
    type="button"
    className={`${isSelected ? "tw-text-gray-75 tw-bg-gray-lightest" : "tw-bg-transparent tw-text-gray-50 tw-cursor-pointer"} tw-border-0 tw-uppercase tw-font-bold tw-rounded-lg tw-px-10px tw-py-6px hover:tw-text-brand-warning`}
    onClick={() => onClick(value)}
  >
    {name}
  </button>
);

MenuOption.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
