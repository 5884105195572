const getContactName = (firstName, lastName) => {
  let contactName = "No Name";

  if (firstName || lastName) {
    contactName = `${firstName} ${lastName}`.trim();
  }

  return contactName;
};

export default getContactName;
