/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const EnvelopeRegularV6 = (props) => (
  <Icon {...props} name="Envelope Regular V6">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8c0 1.103-.897 2-2 2H2a2 2 0 0 1-2-2V4zm1.5 0v.691l5.391 4.425a1.745 1.745 0 0 0 2.219 0l5.391-4.425v-.719a.52.52 0 0 0-.5-.5h-12a.52.52 0 0 0-.5.5V4zm0 2.631V12a.5.5 0 0 0 .5.5h12c.275 0 .5-.225.5-.5V6.631l-4.437 3.644c-1.2.984-2.925.984-4.153 0L1.501 6.631z" />
  </Icon>
);

export default EnvelopeRegularV6;
