import React, { useEffect } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../actions/creators";
import * as thunks from "../actions/thunks";

import Modal from "../../shared/v2/Modal";
import Button from "../../shared/v2/Button";
import RadioButton from "../../shared/v2/RadioButton";

import AgentSelector from "./AgentSelector";
import PondSelector from "./PondSelector";

import useConnectWindowCallback from "../helpers/hooks";

const mapStateToProps = (state) => ({
  tab: state.leadRouting.tab,
  show: state.leadRouting.show,
  leadRuleId: state.leadRouting.leadRuleId,
  selectedAgents: state.leadRouting.selectedAgents,
  selectedPond: state.leadRouting.selectedPond,
  saving: state.leadRouting.isSaving,
  saved: state.leadRouting.isSaved,
});

const AGENT_TAB = "agents";

const TargetSelector = ({
  tab,
  show,
  leadRuleId,
  selectedAgents,
  selectedPond,
  saveForm,
  saving,
  saved,
  setModalData,
  showModal,
  selectTab,
  reset,
}) => {
  useConnectWindowCallback({ setModalData, showModal });

  const canSave = (tab === AGENT_TAB && selectedAgents?.length) || selectedPond;
  useEffect(() => {
    if (saved) {
      window.location.reload();
      reset();
    }
  }, [saved]);
  return (
    <Modal
      show={show}
      onHide={reset}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[598px]"
    >
      <Modal.Header title="Add Agent" closeButton />
      <div className="tw-h-[1px] tw-bg-gray-15 tw-my-[24px]" />
      <div className="tw-flex tw-space-x-[32px] tw-mb-[8px]">
        <RadioButton
          value={AGENT_TAB}
          label="Agent"
          checked={tab === AGENT_TAB}
          onClick={() => selectTab(AGENT_TAB)}
        />
        <RadioButton
          value="pond"
          label="Lead Pond"
          checked={tab !== AGENT_TAB}
          onClick={() => selectTab("pond")}
        />
      </div>
      {tab === AGENT_TAB ? <AgentSelector /> : <PondSelector />}
      <div className="tw-flex tw-mt-[60px]">
        <Button size="medium" schema="tertiary" onClick={reset}>
          Cancel
        </Button>
        <div className="tw-flex-1" />
        <Button
          disabled={!canSave}
          size="medium"
          schema="primary"
          onClick={() => saveForm({ leadRuleId, tab, selectedAgents, selectedPond })}
        >
          {saving && <i className="fa fa-pulse fa-spinner" />}
          {!saving && !saved && "Save"}
        </Button>
        {saving}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, { ...actions, ...thunks })(TargetSelector);

TargetSelector.propTypes = {
  tab: Proptypes.string,
  show: Proptypes.bool,
  leadRuleId: Proptypes.number,
  selectedAgents: Proptypes.arrayOf(Proptypes.shape({})),
  selectedPond: Proptypes.shape({}),
  saveForm: Proptypes.func,
  saving: Proptypes.bool,
  saved: Proptypes.bool,
  setModalData: Proptypes.func,
  showModal: Proptypes.func,
  selectTab: Proptypes.func,
  reset: Proptypes.func,
};

TargetSelector.defaultProps = {
  tab: null,
  show: false,
  leadRuleId: null,
  selectedAgents: null,
  selectedPond: null,
  saveForm: null,
  saving: null,
  saved: false,
  setModalData: null,
  showModal: null,
  selectTab: null,
  reset: null,
};
