/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CircleQuestionSolidV6 = (props) => (
  <Icon {...props} name="Circle Question Solid V6">
    <path d="M8 0C3.581 0 0 3.581 0 8s3.581 8 8 8c4.419 0 8-3.581 8-8s-3.581-8-8-8zm0 12.5c-.563 0-1-.437-1-1s.409-1 1-1c.534 0 1 .437 1 1s-.466 1-1 1zm2.159-4.437-1.409.875v.063c0 .406-.344.75-.75.75s-.75-.344-.75-.75v-.5c0-.25.125-.5.375-.656l1.781-1.062a.67.67 0 0 0 .344-.594c0-.375-.341-.688-.716-.688H7.437a.676.676 0 0 0-.687.688c0 .406-.344.75-.75.75s-.75-.344-.75-.75c0-1.219.969-2.188 2.159-2.188h1.597c1.275 0 2.244.969 2.244 2.188 0 .75-.406 1.469-1.091 1.875z" />
  </Icon>
);

export default CircleQuestionSolidV6;
