/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import Proptypes, { func, string } from "prop-types";
import "./OptionsStatusCell.scss";

const statusMapping = {
  pipeline: {
    title: "PIPELINE",
    className: "pipeline",
    value: "pipeline",
  },
  comingsoon: {
    title: "COMING SOON",
    className: "comingsoon",
    value: "comingsoon",
  },
  active: {
    title: "ACTIVE",
    className: "active",
    value: "active",
  },
  pending: {
    title: "PENDING",
    className: "pending",
    value: "pending",
  },
  sold: {
    title: "CLOSED",
    className: "sold",
    value: "sold",
  },
  expired: {
    title: "EXPIRED",
    className: "expired",
    value: "expired",
  },
  withdrawn: {
    title: "WITHDRAWN",
    className: "withdrawn",
    value: "withdrawn",
  },
  canceled: {
    title: "CANCELED",
    className: "canceled",
    value: "canceled",
  },
  archived: {
    title: "ARCHIVED",
    className: "archived",
    value: "archived",
  },
};

const statusesToHide = {
  default: [],
  referral: ["comingsoon", "pending", "withdrawn", "pipeline"],
};

const isStatusVisible = (transactionType, status) => {
  const statuses = statusesToHide[transactionType] || statusesToHide.default;
  return !statuses.includes(status);
};

export const OptionsStatusCell = ({
  transactionType,
  isEditable = true,
  onStatusChange,
  size = 122,
  removeKey,
  setShowStatusPicker,
  showStatusPicker,
  status = "active",
  statusNode,
}) => {
  const optionsBuilder = (statusOption) => (
    <div
      data-cy="listing-status-option"
      key={statusMapping[statusOption]?.value}
      className="tw-flex tw-bg-white option"
      onClick={() => onStatusChange(statusMapping[statusOption]?.value)}
    >
      <div
        className={`tw-flex tw-flex-col tw-flex-nowrap tw-grow tw-shrink-0 tw-text-white tw-font-bold tw-text-center tw-uppercase tw-transition tw-duration-200 pill pill-${statusMapping[statusOption]?.className}`}
      >
        {statusMapping[statusOption]?.title}
      </div>
    </div>
  );

  return (
    <div ref={statusNode} className="tw-relative tw-flex tw-justify-center">
      <div
        className="tw-text-white"
        onClick={() => (isEditable ? setShowStatusPicker(!showStatusPicker) : _)}
        data-cy="listing-status-button"
      >
        <div
          className={`tw-flex tw-justify-center tw-flex-row tw-flex-nowrap tw-grow tw-shrink-0 tw-items-center tw-font-bold tw-uppercase tw-text-center tw-transition tw-duration-200 selectedPill ${
            isEditable ? "tw-cursor-pointer" : "tw-cursor-auto"
          } tw-px-10px pill-${statusMapping[status]?.className}`}
          style={{ width: size === "auto" ? "auto" : `${size}px` }}
        >
          {statusMapping[status]?.title}
          {isEditable && (
            <i
              className={`fa fa-chevron-down tw-ml-1 tw-text-11d tw-transition-transform ${
                showStatusPicker && "fa-rotate-180"
              }`}
            />
          )}
        </div>
      </div>
      {showStatusPicker && (
        <div className="tw-z-30 tw-flex tw-flex-col tw-flex-wrap tw-absolute tw-top-full tw-left-0 tw-bg-white selectableStatuses tw-min-w-[122px]">
          {/* Filter out statuses that match `removeKey` or that should be hidden based on transaction type */}
          {Object.keys(statusMapping)
            .filter((statusKey) => statusKey !== removeKey && isStatusVisible(transactionType, statusKey))
            .map(optionsBuilder)}
        </div>
      )}
    </div>
  );
};

// Use this if you want to hook own state with specific behaviors/sideffect
export const WithShowPicker = ({ transactionType, status, selectedStatus }) => {
  const [showStatusPicker, setShowStatusPicker] = useState(false);

  const onStatusChange = (_status) => {
    setShowStatusPicker(false);
    selectedStatus(_status);
  };

  const statusNode = useRef();

  const handleClickOutside = (e) => {
    if (statusNode.current.contains(e.target)) {
      return;
    }

    setShowStatusPicker(false);
  };

  useEffect(() => {
    if (showStatusPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showStatusPicker]);

  return (
    <OptionsStatusCell
      onStatusChange={onStatusChange}
      setShowStatusPicker={setShowStatusPicker}
      showStatusPicker={showStatusPicker}
      status={status}
      transactionType={transactionType}
      statusNode={statusNode}
    />
  );
};

// Use this for legacy functionality
const WithStatus = ({ selectedStatus, onUpdateStatus, ...props }) => {
  const [status, setStatus] = useState(selectedStatus);

  useEffect(() => {
    setStatus(selectedStatus);
  }, [selectedStatus]);

  const setStatusHandlr = () => {
    setStatus(status);
    onUpdateStatus(status);
  };

  return <WithShowPicker status={status} selectedStatus={setStatusHandlr} {...props} />;
};

OptionsStatusCell.propTypes = {
  transactionType: Proptypes.string,
  setShowStatusPicker: Proptypes.func,
  showStatusPicker: Proptypes.bool,
  status: Proptypes.string,
  onStatusChange: Proptypes.func,
  statusNode: Proptypes.node,
};

OptionsStatusCell.defaultProps = {
  transactionType: "default",
  setShowStatusPicker: () => {},
  showStatusPicker: false,
  status: "active",
  onStatusChange: () => {},
  statusNode: null,
};

WithShowPicker.propTypes = {
  selectedStatus: string,
  transactionType: string,
  status: string,
};

WithShowPicker.defaultProps = {
  selectedStatus: "active",
  transactionType: "default",
  status: "active",
};

WithStatus.propTypes = {
  selectedStatus: string,
  onUpdateStatus: func,
};

WithStatus.defaultProps = {
  selectedStatus: "active",
  onUpdateStatus: () => {},
};

export default WithStatus;
