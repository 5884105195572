import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Popover, PopoverContent, PopoverTrigger } from "@shared/v2/Popover";
import IconButton from "../../../../shared/v2/IconButton";
import * as actionCreators from "../actions/thunks";
import TextButton from "../../../../shared/v2/TextButton";
import TextComboInput from "../../../../shared/v2/TextComboInput";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import CustomDates from "../CustomDates/CustomDates";
import { getSearchableDate } from "../actions/helper";
import { Search } from "../../../../shared/v2/Icomoon";
import colors from "../../../../Reports/components/DashboardShared/helpers/colors";

const DateHeaderOptions = ({ dates, isOpen, showCustomDateLists }) => {
  const [search, setSearch] = useState(null);
  const [options, setOptions] = useState(dates?.available_dates || []);

  useEffect(() => {
    if (search) {
      setOptions(getSearchableDate(dates?.available_dates || [], search));
    } else {
      setOptions(dates?.available_dates || []);
    }
  }, [dates.available_dates, search]);

  return (
    <Popover placement="bottom-end" open={isOpen} onOpenChange={showCustomDateLists}>
      <PopoverTrigger>
        <IconButton
          onClick={() => showCustomDateLists(!isOpen)}
          className="tw-ml-auto"
          size="small"
          schema="tertiary"
        >
          <Plus size="s" />
        </IconButton>
      </PopoverTrigger>
      <div className="tw-relative tw-z-[1001]" id="date-portal" />
      <PopoverContent>
        <div className="tw-h-[56px]">
          <div className="tw-px-[12px] tw-pb-[8px] tw-pt-[12px] tw-h-[56px]">
            <TextComboInput
              value={search}
              size="3"
              trailing={
                <Search size="xl" className="tw-align-middle" style={{ color: colors.color1.default }} />
              }
              placeholder="Search dates"
              onChange={(e) => setSearch(e.target.value)}
              className="tw-w-full"
            />
          </div>
        </div>
        <div className="tw-max-h-[232px] tw-overflow-y-auto">
          {options.map((option) => (
            <CustomDates key={option.id} option={option} />
          ))}
        </div>
        <div className="tw-h-[40px]">
          <div className="tw-shadow-20 tw-p-[12px] tw-h-[40px]">
            <TextButton size="small" onClick={() => window.location.assign("/new_milestones")}>
              <FontAwesomeIcon icon={faCog} className="tw-text-[12px] tw-mr-[4px]" />
              Custom Dates Settings
            </TextButton>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

DateHeaderOptions.propTypes = {
  showCustomDateLists: Proptypes.func.isRequired,
  isOpen: Proptypes.bool,
  dates: Proptypes.shape({
    available_dates: Proptypes.arrayOf(Proptypes.string),
  }),
};

DateHeaderOptions.defaultProps = {
  dates: {
    available_dates: [],
  },
  isOpen: false,
};

const mapStateToProps = (state) => ({
  dates: state.tdpDatesReducer.dates,
  isOpen: state.tdpDatesReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  showCustomDateLists: (show) => dispatch(actionCreators.showCustomDateLists(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateHeaderOptions);
