/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const XmarkLargeSolidV6 = (props) => (
  <Icon {...props} name="Xmark Large Solid V6">
    <path d="M13.294 1.293a1 1 0 1 1 1.413 1.414L9.416 7.972l5.291 5.322c.391.391.391 1.022 0 1.413s-1.022.391-1.413 0L7.972 9.416l-5.265 5.291a.999.999 0 1 1-1.414-1.413l5.292-5.322-5.292-5.265a.999.999 0 1 1 1.414-1.414l5.265 5.292 5.322-5.292z" />
  </Icon>
);

export default XmarkLargeSolidV6;
