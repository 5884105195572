import { useEffect, useState } from "react";
import axios from "axios";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const useSources = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();

    caseTransformingAxios
      .get("/source_settings.json", { signal: abortController.signal })
      .then((res) => {
        setSources(res.data.data.map((s) => s.attributes));
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        console.error(err);
        setLoading(false);
      });

    return () => abortController.abort();
  }, []);

  return {
    actions: {
      addSource: (source) =>
        caseTransformingAxios
          .post("/source_settings", source)
          .then((res) => {
            setSources((prev) => [...prev, res.data.data.attributes]);
          })
          .catch((err) => {
            setError(err.response?.data);
          }),
      toggleSource: (source) =>
        caseTransformingAxios
          .patch(`/source_settings/${source.id}`, {
            hidden: !source.hidden,
          })
          .then(() => {
            setSources((prev) => prev.map((s) => (s.id === source.id ? { ...s, hidden: !s.hidden } : s)));
          })
          .catch((err) => {
            console.error(err);
          }),
      deleteSource: (source) =>
        caseTransformingAxios
          .delete(`/source_settings/${source.id}`)
          .then(() => {
            setSources((prev) => prev.filter((s) => s.id !== source.id));
          })
          .catch((err) => {
            console.error(err);
          }),
    },
    error,
    loading,
    sources,
  };
};

export default useSources;
