import axios from "axios";
import { caseTransformingAxios as csAxios } from "../../../shared/v2/caseTransformingAxios";

export const getAllListingInfoFromApi = (uuid) =>
  axios.get(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
  });

export const putDetailToApi = ({ uuid, key: name, value }, signal) =>
  axios.put(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    listing: {
      [name]: value,
    },
    signal,
  });

export const putDetailsToApi = ({ uuid, details }) =>
  axios.put(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    listing: {
      ...Object.fromEntries(details.map(({ key, value }) => [key, value])),
    },
  });

export const deleteListing = (uuid) =>
  axios.delete(`/api/v4/transactions/header/${uuid}`, {
    params: { authenticity_token: ReactOnRails.authenticityToken() },
  });

export const putRefDetailsToApi = ({ uuid, ...payload }, signal) =>
  csAxios.put(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    listing: payload,
    signal,
  });
