import axios from "axios";
import { tryCatchHandlr } from "../../shared/Utilities";

export const destroyAgentExpense = (agentPosition, expPosition) => ({
  type: "DESTROY_AGENT_EXPENSE_DATA",
  agentPosition,
  expPosition,
});

export const setAgentExpenseData = (attr, data, agentPosition, expPosition) => ({
  type: "SET_AGENT_EXPENSE_DATA",
  attr,
  data,
  agentPosition,
  expPosition,
});

export const setAgentBrokerageData = (attr, data, agentPosition) => ({
  type: "SET_AGENT_BROKERAGE_DATA",
  attr,
  data,
  agentPosition,
});

export const openNewExpenseModal = () => {
  return (dispatch) => {
    dispatch({ type: "OPEN_NEW_EXPENSE_MODAL" });
  };
};

export const openNewExpenseModal0 = () => ({ type: "OPEN_NEW_EXPENSE_MODAL" });

export const setAgentExpenseList = (agentExpenseList) => {
  return {
    type: "SET_AGENT_EXPENSE_LIST",
    agentExpenseList,
  };
};

export const setCompanyExpenseList = (companyExpenseList) => {
  return {
    type: "SET_COMPANY_EXPENSE_LIST",
    companyExpenseList,
  };
};

export const setCompanyRevenueList = (companyRevenueList) => {
  return {
    type: "SET_COMPANY_REVENUE_LIST",
    companyRevenueList,
  };
};

export const setTransactionExpenseList = (transactionExpenseList) => {
  return {
    type: "SET_TRANSACTION_EXPENSE_LIST",
    transactionExpenseList,
  };
};

export const closeNewExpenseModal = () => {
  return (dispatch) => {
    dispatch({ type: "CLOSE_NEW_EXPENSE_MODAL" });
  };
};

export const hideAlert = () => {
  return (dispatch) => {
    dispatch({ type: "HIDE_ALERT" });
  };
};

export const hideDangerAlert = () => {
  return (dispatch) => {
    dispatch({ type: "HIDE_DANGER_ALERT" });
  };
};

export const setAgentExpense = () => {
  return (dispatch) => {
    dispatch({ type: "EXPENSE_TYPE_AGENT" });
  };
};
export const setCompanyExpense = () => {
  return (dispatch) => {
    dispatch({ type: "EXPENSE_TYPE_COMPANY" });
  };
};

export const setAgentExpense0 = () => ({ type: "EXPENSE_TYPE_AGENT" });

export const unsetAgentExpense = () => {
  return (dispatch) => {
    dispatch({ type: "EXPENSE_TYPE_OFFICE" });
  };
};

export const setActiveAgentExpense = (data) => {
  return (dispatch) => {
    dispatch({
      type: "ACTIVE_AGENT_EXPENSE",
      data,
    });
  };
};

export const setActiveAgentExpense0 = (data) => ({
  type: "ACTIVE_AGENT_EXPENSE",
  data,
});

export const setAccountData = (data) => {
  return (dispatch) => {
    dispatch({
      type: "ACCOUNT_DATA",
      data,
    });
  };
};

export const setAccountData0 = (data) => ({
  type: "ACCOUNT_DATA",
  data,
});

export const showEdit = () => {
  return (dispatch) => {
    dispatch({ type: "SHOW_EDIT" });
  };
};

export const hideEdit = () => {
  return (dispatch) => {
    dispatch({ type: "HIDE_EDIT" });
  };
};

export const submitTransactionData = (transactionData) => {
  return async (dispatch) => {
    const req = axios.put("/financials/" + transactionData.account_financial_template.id, {
      ...transactionData,
      authenticity_token: ReactOnRails.authenticityToken(),
    });

    const [res, err] = await tryCatchHandlr(req);

    if (err) {
      dispatch({
        type: "ERR_DATA",
        errors: err.response.data.errors,
      });
      dispatch({ type: "SHOW_DANGER_ALERT" });
      return;
    }

    dispatch({ type: "SHOW_ALERT" });
    dispatch({ type: "HIDE_EDIT" });
    const { data } = res;

    dispatch({
      type: "ACCOUNT_DATA",
      data,
    });
    setTimeout(function () {
      dispatch({ type: "HIDE_ALERT" });
    }, 2500);
  };
};
