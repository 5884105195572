/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Templates = (props) => (
  <Icon {...props} name="Templates">
    <path d="M14.222 14.222v-8H9.778V1.778h-8v12.444h12.444zm0 1.778H1.778C.8 16 0 15.2 0 14.222V1.778C0 .8.8 0 1.778 0h8.889L16 5.333v8.889C16 15.2 15.2 16 14.222 16zM8 6.222H3.556V8H8V6.222zm4.444 3.556H3.555v1.778h8.889V9.778z" />
  </Icon>
);

export default Templates;
