import { tryCatchHandlr } from "../../../../shared/Utilities";
import {
  getAllPeopleFromApi,
  postPersonToApi,
  patchPersonToApi,
  deletePersonFromApi,
  getAvailablePeopleFromApi,
} from "../api/apiWrappers";
import {
  saveAllPeople,
  postPerson,
  getAvailablePeople,
  errorHandler,
  closeModal,
  patchPerson,
} from "./creators";
import { adaptError, adaptPeople } from "../api/apiAdapters";

export const getAllPeopleAsThunk = (uuid) => async (dispatch) => {
  const req = getAllPeopleFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    return;
  }
  const details = adaptPeople(res.data);

  dispatch(saveAllPeople(details, uuid));
};

export const postPersonAsThunk = (person, uuid, category) => async (dispatch) => {
  const req = postPersonToApi(person, uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    const error = adaptError(err.response.data.errors);
    dispatch(errorHandler(error));
    return;
  }

  dispatch(postPerson(res.data, uuid, category));
  dispatch(closeModal(true));
  setTimeout(() => {
    dispatch(closeModal(false));
  }, 1000);
};

export const patchPersonAsThunk = (person, uuid, id, category) => async (dispatch) => {
  const req = patchPersonToApi(person, uuid, id);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    const error = adaptError(err.response.data.errors);
    dispatch(errorHandler(error));
    return;
  }

  dispatch(patchPerson(id, category, res.data));
  dispatch(closeModal(true));
  setTimeout(() => {
    dispatch(closeModal(false));
  }, 1000);
};

export const deletePersonAsThunk = (uuid, listingPersonId) => async (dispatch) => {
  const req = deletePersonFromApi(uuid, listingPersonId);
  const [, err] = await tryCatchHandlr(req);
  if (err) {
    const error = adaptError(err.response.data.errors || [err.response.data.message]);
    dispatch(errorHandler(error));
    return;
  }
  dispatch(getAllPeopleAsThunk(uuid));
};

export const getAvailablePeopleAsThunk = (uuid) => async (dispatch) => {
  const req = getAvailablePeopleFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);
  if (err) {
    const error = adaptError(err.response.data.errors);
    dispatch(errorHandler(error));
    return;
  }

  const details = adaptPeople(res.data);
  dispatch(getAvailablePeople(details, uuid));
};
