import React from "react";
import { func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { LISTING_UUID, REFERRALPARTNER_BROKERAGE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { putRefBrokerage } from "../actions/thunks";

import DetailInput from "../DetailInput";

const ReferralBrokerageField = ({ brokerage, onPutRefBrokerage, uuid }) => (
  <DetailInput
    dataCy="ReferralBrokerageField"
    label="Brokerage"
    schema="text"
    val={brokerage}
    onBlur={(newBrokerage) => onPutRefBrokerage(uuid, newBrokerage)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralBrokerageField,
  states: [REFERRALPARTNER_BROKERAGE, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefBrokerage,
  aliases: "onPutRefBrokerage",
});

ReferralBrokerageField.propTypes = {
  brokerage: string,
  onPutRefBrokerage: func,
  uuid: string,
};

ReferralBrokerageField.defaultProps = {
  brokerage: null,
  onPutRefBrokerage: () => {},
  uuid: null,
};
