import React, { useRef, useState } from "react";
import Proptypes from "prop-types";

import Button from "@shared/v2/Button/Button";
import Upload from "@shared/v2/Icomoon/Icons/Upload";

import FileInformation from "@shared/ModalUpload/DocumentListItem/FileInformation";

import Menubtn from "./Menubtn";
import UploadDocContainer from "./UploadDocContainer";

const EditAgreement = ({
  document,
  agreementType,
  handleFileDrop,
  handleDocumentUpload,
  handleAgreementDocumentReplace,
  deleteAgreementDocument,
  renameAgreementDocument,
}) => {
  const [editFilename, setEditFilename] = useState(false);
  const hiddenUploaddocInput = useRef(null);

  const handleTriggerRename = () => {
    setEditFilename(true);
  };
  const handleTriggerReplace = (docId, e) => {
    handleAgreementDocumentReplace(docId, e);
  };
  const deleteDocuments = (docId) => {
    deleteAgreementDocument(docId);
  };

  const renameDocument = (docId, newName) => {
    renameAgreementDocument(docId, newName);
  };
  const handlePreview = () => {
    if (document.expiringUrl) {
      window.open(document.expiringUrl, "_blank");
    }
  };
  return (
    <div>
      <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
        {agreementType} Agreement
      </div>

      <div
        className={`${document?.fileName ? "tw-border-solid tw-bg-[#FFFFFF] tw-border-neutral-gray-10" : "tw-border-gray-15 tw-border-dashed tw-bg-[#FAFAFA]"} tw-w-[552px] tw-mb-24px !tw-py-[13px] tw-px-15px tw-border tw-rounded-4px`}
        data-cy="agreement-edit-documents-upload"
        onDrop={handleFileDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <input
          id="agreementdocumentUploadInput"
          type="file"
          ref={hiddenUploaddocInput}
          onChange={handleDocumentUpload}
          style={{ display: "none" }}
        />

        <div className="!tw-flex tw-grid tw-grid-cols-6 tw-gap-[16px] tw-justify-between tw-h-[33px]">
          {!document?.fileName ? (
            <UploadDocContainer />
          ) : (
            <FileInformation
              editFilename={editFilename}
              setEditFilename={setEditFilename}
              renameDocument={renameDocument}
              previewDocument={handlePreview}
              document={document}
            />
          )}

          <div className="tw-col-end-8 tw-col-span-1 tw-pt-[4px]">
            {document?.fileName ? (
              <Menubtn
                triggerReplace={handleTriggerReplace}
                document={document}
                triggerRename={handleTriggerRename}
                deleteDocuments={deleteDocuments}
              />
            ) : (
              <label htmlFor="agreementdocumentUploadInput">
                <Button size="small" schema="secondary" onClick={() => hiddenUploaddocInput.current?.click()}>
                  <Upload size="m" />
                  {"   upload"}
                </Button>
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EditAgreement.propTypes = {
  document: Proptypes.shape({}),
  agreementType: Proptypes.string,
  handleAgreementDocumentReplace: Proptypes.func,
  deleteAgreementDocument: Proptypes.func,
  renameAgreementDocument: Proptypes.func,
  handleFileDrop: Proptypes.func,
  handleDocumentUpload: Proptypes.func,
};
EditAgreement.defaultProps = {
  document: {},
  agreementType: "",
  handleAgreementDocumentReplace: () => {},
  deleteAgreementDocument: () => {},
  renameAgreementDocument: () => {},
  handleFileDrop: () => {},
  handleDocumentUpload: () => {},
};
export default EditAgreement;
