export default ({ agentId, filters, title, dateRange }) => {
  let url = "/people/leads";
  const queryParams = new URLSearchParams();

  if (title !== "Pended" && title !== "Closed" && agentId) {
    queryParams.append("leads_filter[selected_primary_agents][]", agentId);
  }

  if (title === "Called" || title === "Contacted") {
    if (filters.buyer) queryParams.append("leads_filter[selected_intention_filters][]", "buyer");
    if (filters.seller) queryParams.append("leads_filter[selected_intention_filters][]", "seller");
    if (filters.buyerSeller) queryParams.append("leads_filter[selected_intention_filters][]", "seller/buyer");
    queryParams.append("leads_filter[selected_intention_filters][]", "n/a");
    queryParams.append("leads_filter[selected_communications_filters][]", "call_true");
    queryParams.append("leads_filter[selected_communications_filters][]", dateRange);
  }

  if (title === "Contacted") {
    queryParams.append(
      "leads_filter[selected_communications_call_outcome][]",
      "communications_call_outcome_talked_to_lead",
    );
  }

  if (title === "Appt. Set" || title === "Appt. Held" || title === "Signed") {
    queryParams.append(
      "leads_filter[selected_appointment_filters][]",
      title === "Appt. Set" ? "appointment_set" : "appointment_held",
    );
    queryParams.append("leads_filter[selected_appointment_filters][]", dateRange);
  }

  if (title === "Appt. Set" || title === "Appt. Held") {
    if (filters.buyer) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_type_buyer_consultation",
      );
    }
    if (filters.seller) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_type_listing_consultation",
      );
    }
    if (filters.buyerSeller) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_type_buyer_listing_consultation",
      );
    }
  }

  if (title === "Signed") {
    if (filters.buyer) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_outcome_signed_buyer_agreement",
      );
    }
    if (filters.seller) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_outcome_signed_listing_agreement",
      );
    }
    if (filters.buyerSeller) {
      queryParams.append(
        "leads_filter[selected_appointment_filters][]",
        "appointment_outcome_signed_both_buyer_listing",
      );
    }
  }

  if (title === "Pended" || title === "Closed") {
    if (agentId) queryParams.append("agent[]", agentId);
    if (filters.buyer) queryParams.append("type[]", "buyer");
    if (filters.seller) queryParams.append("type[]", "seller");
  }

  if (title === "Pended") {
    url = "/listings/pending";
    queryParams.append("status_changed_range[]", dateRange);
  }

  if (title === "Closed") {
    url = "/listings/sold";
    queryParams.append("close_date_range[]", dateRange);
  }

  window.open(`${url}?${queryParams}`, "_blank");
};
