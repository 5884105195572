import React from "react";
import PropTypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Typography from "@shared/v2/Typography";
import Button from "@shared/v2/Button";

const SeoHelpModal = ({ open, onClose }) => (
  <Modal
    show={open}
    onHide={onClose}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[620px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="SEO" closeButton />
    <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[24px] tw-overflow-auto">
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Typography variant="subtitle-m">Slug</Typography>
        <Typography variant="body-m">
          The slug is the part of your blog post&apos;s URL that comes after your website&apos;s main address.
          It helps readers and search engines understand what your post is about. For example, if your post is
          about &quot;Top Tips for First-Time Homebuyers,&quot; your slug could be something like
          top-tips-first-time-homebuyers. Keep it short and easy to read.
          <br />
          <br />
          For the best SEO results, make sure your slug matches your post title as closely as possible! You
          can change your slug at any time, but any links you have shared for the post in the past will be
          broken. You can set up redirects in the website builder to help resolve this issue. Example:
          <img src="/images/seo-example.png" alt="SEO Example" className="tw-mt-[8px] tw-w-full" />
        </Typography>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Typography variant="subtitle-m">Meta Title</Typography>
        <Typography variant="body-m">
          The meta title is the clickable headline that appears in search engine results as well as the
          browser tab text. It&apos;s like your post&apos;s first impression! Make it clear and appealing to
          grab attention. For example, &quot;5 Essential Homebuying Tips for First-Timers.&quot;
        </Typography>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[8px]">
        <Typography variant="subtitle-m">Meta Description</Typography>
        <Typography variant="body-m">
          The meta description is a brief summary of your post that shows up under the title in search
          results. Think of it as your pitch to potential readers. Describe what they&apos;ll learn or gain by
          reading your blog, like: &quot;Discover expert tips to make your first homebuying experience smooth
          and stress-free.&quot;
        </Typography>
      </div>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between tw-gap-[8px]">
      <Button schema="tertiary" size="medium" onClick={onClose}>
        Close
      </Button>
      <Button size="medium" onClick={onClose}>
        Got it!
      </Button>
    </Modal.Footer>
  </Modal>
);

SeoHelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SeoHelpModal;
