import React from "react";
import PropTypes from "prop-types";
import { sizeBasedClasses, borderValue, availableSizes } from "./Avatar.styles";

const AvatarPlaceholder = ({ className, size, border, hiddenCount }) => (
  <div
    className={`
      tw-flex tw-justify-center tw-items-center
      tw-rounded-full
      tw-overflow-hidden
      tw-bg-neutral-gray-15 tw-text-white
      ${sizeBasedClasses(size)}
      ${borderValue(border)}
      ${className}
    `}
  >
    +{hiddenCount}
  </div>
);

AvatarPlaceholder.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([...availableSizes, "none"]),
  border: PropTypes.oneOf(["neutral", "tinted", "none"]),
  hiddenCount: PropTypes.number,
};

AvatarPlaceholder.defaultProps = {
  className: "",
  size: "small",
  border: "neutral",
  hiddenCount: 1,
};

export default AvatarPlaceholder;
