/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SuitcaseSolidV6 = (props) => (
  <Icon {...props} name="Suitcase Solid V6">
    <path d="M4 1.75C4 .783 4.784 0 5.75 0h4.5C11.216 0 12 .783 12 1.75V15H4V1.75zM5.5 3h5V1.75a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25V3zM2 3h1v12H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm12 12h-1V3h1c1.103 0 2 .897 2 2v8c0 1.103-.897 2-2 2z" />
  </Icon>
);

export default SuitcaseSolidV6;
