export const adaptError = (error) => {
  if (!error?.response?.data?.message) {
    return {
      message: "Unknown error occurred. Please try again later",
    };
  }
  return error.response.data;
};
export const AdSiteError = (error) => {
  if (!error?.response?.data?.message) {
    return {
      message: "Site Name is required.",
    };
  }
  return error.response.data;
};
