import axios from "axios";
import { tryCatchHandlr } from "../../../shared/Utilities";

const updateListingReq = async (details, uuid) => {
  const req = axios.put(`/api/v4/transactions/header/${uuid}`, {
    authenticity_token: window.ReactOnRails.authenticityToken(),
    listing: {
      ...Object.fromEntries(details.map(({ key, value }) => [key, value])),
    },
  });

  const [, err] = await tryCatchHandlr(req);

  // eslint-disable-next-line no-alert
  if (err) alert(err.response.data.errors[0]);
};

export default updateListingReq;
