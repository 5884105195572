import React, { Component } from 'react';

export default class TimelineValidationUpdateEvent extends Component {
  constructor(props, context){
    super(props, context)
    this.interacted_at_epoch = this.props.interacted_at_epoch
    this.event = this.props.event
  }

  changes = () => {
    if (this.event.timeline_fields.detail.validation.length > 1) {
      return (
          <span>{this.event.timeline_fields.detail.value} changed from <strong>{this.event.timeline_fields.detail.validation[0]}</strong> to <strong>{this.event.timeline_fields.detail.validation[1]}</strong></span>
      )
    }
    else {
      return (
          <span>{this.event.timeline_fields.detail.value} changed to <strong>{this.event.timeline_fields.detail.validation[0]}</strong></span>
      )
    }
  }

  render() {
    return (
        <div className="timeline-event">
          <div className="row">
            <div className="col-xs-1">
              <i className="fa fa-user timeline-icon-circle"/>
            </div>
            <div className="col-xs-8">
              <span className="bold-detail">{this.event.timeline_fields.updater_name}</span>{` changed ${this.event.timeline_fields.detail.type} validation status`}
              <div className="timeline-description-text">
                {this.changes()}
              </div>
            </div>
            <div className="col-xs-3 time-ago">
              <span>{this.event.interacted_at_absolute}</span>
            </div>
          </div>
        </div>
    )
  }
}
