/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useGivenOrGeneratedId } from "../../hookHelpers";
import { CaretDown } from "../Icomoon";
import { Display, DisplayEditorToggleBase } from "../InlineEditor";
import Dropdown from "../Dropdown";
import TextButton from "../TextButton";
import { measureText } from "../utils";

const InlineEditingSelect = ({
  id: idFromProps,
  displayLabel,
  value,
  options,
  disabled,
  error,
  placeholder,
  onSelect,
  onChange,
  onCancelEditing,
  onConfirmEditing,
  className,
  displayClassName,
  editorClassName,
  displayIconAlwaysVisible,
  menuShouldComeToFront,
  ...otherProps
}) => {
  const id = useGivenOrGeneratedId("inline-editing-select", idFromProps);

  const displayData = useMemo(
    () =>
      value
        ? {
            label: displayLabel || value.label,
            value: (typeof value.value === "number" ? value.value.toString() : value.value) || "",
          }
        : null,
    [displayLabel, value],
  );

  const [editing, setEditing] = useState(false);
  const [editingValue, setEditingValue] = useState(value);
  const dropdownRef = useRef();
  const [closed, setClosed] = useState(true);
  const longestOption = useMemo(
    () =>
      [...options, placeholder].reduce((a, b) => (a?.label?.length > b?.label?.length ? a : b), {
        // Empty initial value to avoid TypeError when options is empty
      }),
    [options, placeholder],
  );

  // Update the editing value when `value` changes
  useEffect(() => setEditingValue(value), [value]);

  const handleChange = (newValue) => {
    setEditingValue(newValue);
    if (onSelect) {
      onSelect(newValue);
    }
  };

  const cancelEditing = () => {
    setEditing(false);
    setEditingValue(value); // Revert changes made on editing mode
    if (onCancelEditing) {
      onCancelEditing();
    }
  };

  const confirmEditing = () => {
    if (onChange) {
      onChange(editingValue);
    }
    setEditing(false);
    if (onConfirmEditing) {
      onConfirmEditing();
    }
  };

  const defaultContent = "0".repeat(20);
  // Additional width:
  // - 24px from the control's padding
  // - 12px from the caret
  // - 10px from the gap between the control and the caret
  // - 2px from the input's margin
  const additionalWidthInPixel = 48;
  const additionalWidthInCh = 5;

  const resizeControlBasedOnText = (control, text) => {
    const metrics = measureText(control, text);

    if (!metrics) {
      // Using the `ch` unit approach, when Canvas.measureText method is not supported
      // See https://developer.mozilla.org/en-US/docs/Web/CSS/length#ch
      control.style.width = `${text.length + additionalWidthInCh}ch`;
    } else {
      control.style.width = `${metrics.width + additionalWidthInPixel}px`;
    }
  };

  const resizeDropdownInputBasedOnOptions = () => {
    if (!dropdownRef.current) {
      return;
    }

    const { controlRef } = dropdownRef.current;
    const text = longestOption?.label || placeholder || defaultContent;

    resizeControlBasedOnText(controlRef, text);
  };

  // Update dropdown input styles when editingValue changes
  useEffect(() => {
    if (!editing) {
      return;
    }
    resizeDropdownInputBasedOnOptions();
  }, [editing, editingValue]);

  // Update dropdown input styles when switching to editing mode and/or
  // options prop changes
  useEffect(() => {
    if (!editing) {
      return;
    }
    resizeDropdownInputBasedOnOptions();
  }, [editing, options]);

  const handleOnInputChange = (inputValue) => {
    if (!editing || !dropdownRef.current || !inputValue) {
      return;
    }

    const { controlRef } = dropdownRef.current;
    const text = inputValue.length > defaultContent.length ? inputValue : defaultContent;

    resizeControlBasedOnText(controlRef, text);
  };

  const handleKeyDown = (e) => {
    if (!closed) {
      // Ignore key down events when the dropdown is open
      return;
    }
    switch (e.key) {
      case "Esc":
      case "Escape":
        e.preventDefault();
        cancelEditing();
        break;
      case "Enter":
        e.preventDefault();
        confirmEditing();
        break;
      default:
        break;
    }
  };

  const display = displayData ? (
    <Display
      id={id}
      label={displayData.label}
      value={displayData.value}
      disabled={disabled}
      icon={<CaretDown />}
      iconAlwaysVisible={displayIconAlwaysVisible}
      onInteract={() => setEditing(true)}
      className={displayClassName}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  ) : (
    <TextButton className={`tw-p-0 ${displayClassName}`} onClick={() => setEditing(true)}>
      add
    </TextButton>
  );

  const editor = (
    <Dropdown
      ref={dropdownRef}
      id={id}
      value={editingValue}
      options={options}
      isSearchable
      isDisabled={disabled}
      error={error}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={handleOnInputChange}
      autoFocus
      openMenuOnFocus
      selectInputOnMenuOpen={false}
      blurInputOnSelect={false}
      emptyInputRemovesValue={false}
      onBlur={confirmEditing}
      onMenuClose={() => setClosed(true)}
      onMenuOpen={() => setClosed(false)}
      onKeyDown={handleKeyDown}
      className={editorClassName}
      menuShouldComeToFront={menuShouldComeToFront}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );

  return (
    <DisplayEditorToggleBase
      mode={editing ? "editor" : "display"}
      display={display}
      editor={editor}
      className={className}
    />
  );
};

const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  role: PropTypes.string,
});

InlineEditingSelect.propTypes = {
  id: PropTypes.string,
  displayLabel: PropTypes.string,
  value: optionShape,
  options: PropTypes.arrayOf(optionShape).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onConfirmEditing: PropTypes.func,
  className: PropTypes.string,
  displayClassName: PropTypes.string,
  editorClassName: PropTypes.string,
  displayIconAlwaysVisible: PropTypes.bool,
  menuShouldComeToFront: PropTypes.bool,
};

InlineEditingSelect.defaultProps = {
  id: null,
  displayLabel: null,
  value: null,
  disabled: false,
  error: null,
  placeholder: "",
  onSelect: null,
  onChange: null,
  onCancelEditing: null,
  onConfirmEditing: null,
  className: "",
  displayClassName: "",
  editorClassName: "",
  displayIconAlwaysVisible: true,
  menuShouldComeToFront: false,
};

export default InlineEditingSelect;
