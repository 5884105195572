const STYLES = {
  headline2: 'tw-font-bold tw-text-18d',
  headline3: 'tw-text-18d tw-text-gray-50',
  subtleM: 'tw-text-14d tw-font-bold',
  bodyS: "tw-text-12d tw-text-gray-50",
  primaryDisabled: 'tw-text-[#C2C2C2]',
  primaryLight: 'tw-text-[#999999]'
}

export default STYLES;

export const { 
  headline2,
  headline3,
  subtleM,
  bodyS,
  primaryDisabled,
  primaryLight
} = STYLES;