import { produce } from "immer";
import {
  saveAllPeople,
  deletePerson,
  postPerson,
  patchPerson,
  getAvailablePeople,
  errorHandler,
  closeModal,
  clearError,
} from "../TransactionDetailsPage/SideLeft/People/actions/creators";

export const defaultState = {
  uuid: "",
  people: {},
  searchResults: {},
  error: [],
  availablePeople: {},
  closeModal: false,
};

export const tdpPeopleReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case saveAllPeople().type:
      return {
        ...state,
        ...payload.details,
        uuid: payload.uuid,
      };
    case patchPerson().type:
      return produce(state, (draft) => {
        const editedPerson = state.people[payload.category].find((person) => person.id === payload.id);
        const index = state.people[payload.category].indexOf(editedPerson);
        if(payload.person?.primary) {
          const members = state.people[payload.category].map(teamMember => ({
            ...teamMember,
            primary: false
          }));
          draft.people[payload.category] = members;
        }
        draft.people[payload.category][index] = payload.person;
      });
    case postPerson().type:
      return produce(state, (draft) => {
        const listingPerson = payload.person
        const addedPerson = Object.assign({}, listingPerson.person, {
          role: listingPerson.role, viewer_portal_access: listingPerson.viewer_portal_access,
          email_updates: listingPerson.email_updates, listing_person_id: listingPerson.id
        });
        draft.people[payload.category].push(addedPerson);
      });

    case deletePerson().type:
      return produce(state, (draft) => {
        const deletedPerson = state.people[payload.category].find(({ id }) => id === payload.personId);
        const index = state.people[payload.category].indexOf(deletedPerson);
        draft.people[payload.category].splice(index, 1);
      });

    case getAvailablePeople().type:
      return {
        ...state,
        availablePeople: payload.availablePeople,
      };

    case errorHandler().type:
      return {
        ...state,
        error: payload.error,
      };

    case closeModal().type:
      return {
        ...state,
        error: [],
        closeModal: payload.bool,
      };

    case clearError().type:
      return produce(state, (draft) => {
        draft.error = [];
      });
    default:
      return state;
  }
};
