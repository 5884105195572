import { useMemo, useEffect } from "react";
import ReactDOM from "react-dom";

// borrows a bit from react-google-maps-api
const CustomOverlay = ({
  map,
  position,
  mapPaneName = "floatPane",
  pixelOffset,
  allowReposition,
  elementSize,
  zIndex,
  children
}) => {
  const container = useMemo(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";

    return div;
  }, []);

  const overlay = useMemo(() => {
    return createOverlay(
      map,
      container,
      mapPaneName,
      position,
      pixelOffset,
      allowReposition,
      elementSize
    );
  }, [container, mapPaneName, position]);

  useEffect(() => {
    overlay?.setMap(map);

    return () => {
      overlay?.setMap(null);
    }
  }, [map, overlay]);

  useEffect(() => {
    container.style.zIndex = `${zIndex}`;
  }, [zIndex, container]);

  return ReactDOM.createPortal(children, container);
}

export default CustomOverlay;

const createOverlay = (map, container, pane, position, pixelOffset, allowReposition, elementSize) => {
  class Overlay extends google.maps.OverlayView {
    constructor(position, pane, container) {
      super();

      this.container = container;
      this.pane = pane;
      this.position = position;
    }

    onAdd() {
      const pane = this.getPanes()?.[this.pane];
      pane?.appendChild(this.container);
    }

    draw() {
      window.google.maps.OverlayView.preventMapHitsFrom(this.container);

      const point = this.getProjection().fromLatLngToDivPixel(this.position);
      if (point === null) {
        return;
      }

      const { x, y } = point;
      let left = pixelOffset ? pixelOffset.x + x : x;
      let top = pixelOffset ? pixelOffset.y + y : y;

      if (allowReposition && elementSize) {
        const topVisible = this.isPointVisible(0, top);
        const leftVisible = this.isPointVisible(left, 0);
        const rightVisible = this.isPointVisible(left + elementSize.width, 0);

        if (!topVisible) {
          top = y + 12;
        }

        if (!leftVisible) {
          left = x;
        }

        if (!rightVisible) {
          left = x - elementSize.width;
        }
      }

      this.container.style.transform = `translate(${left}px, ${top}px)`;
    }

    isPointVisible = (x, y) => {
      const pointAsLatLng = this.getProjection()?.fromDivPixelToLatLng({ x, y });
      return map.getBounds().contains(pointAsLatLng);
    };

    onRemove() {
      if (this.container?.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }

  return new Overlay(position, pane, container);
};
