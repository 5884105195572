import React from "react";
import { bool, func } from "prop-types";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";

const CrmAccessModal = ({ show, onHide, onSave }) => (
  <Modal
    show={show}
    onHide={onHide}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[440px]"
    closeButton
  >
    <Modal.Header title="Invite user without granting CRM access?" className="tw-mb-12px" closeButton />
    <Modal.Body className="tw-mb-32px">
      <div className="tw-text-14d tw-text-gray-50">
        This user will not have access to the Brivity CRM but will have access to all other apps associated
        with their account. Contact support at 855-427-4848 if you want this user to have access to Brivity.
      </div>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onHide}>
        Cancel
      </Button>
      <Button size="medium" schema="primary" onClick={onSave}>
        Yes, Invite user
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CrmAccessModal;

CrmAccessModal.propTypes = {
  show: bool,
  onHide: func,
  onSave: func,
};

CrmAccessModal.defaultProps = {
  show: false,
  onHide: () => {},
  onSave: () => {},
};
