import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../actions/creators";

import Checkbox from "../../shared/v2/Checkbox";
import Dropdown from "../../shared/v2/Dropdown";

const CustomOption = ({ componentProps, data, children }) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Dropdown.Option {...componentProps} preserveStyle className="tw-flex tw-overflow-hidden">
    <Checkbox
      id={`checkbox-${data.value}`}
      name={`checkbox-${data.value}`}
      value={data.value}
      checked={componentProps.isSelected}
      disabled={componentProps.isDisabled}
      readOnly
    />
    <img src={data?.avatar} alt="Avatar" className="tw-h-[26px] tw-w-[26px] tw-mx-[12px]" />{" "}
    <span className="tw-flex-1">{children}</span>
  </Dropdown.Option>
);

CustomOption.propTypes = {
  componentProps: Proptypes.shape({ isSelected: Proptypes.bool, isDisabled: Proptypes.bool }),
  data: Proptypes.shape({ value: Proptypes.string, avatar: Proptypes.string }),
  children: Proptypes.arrayOf(Proptypes.shape({})),
};

CustomOption.defaultProps = {
  componentProps: null,
  data: null,
  children: null,
};

const mapStateToProps = (state) => ({
  availableAgents: state.leadRouting.availableAgents.filter(
    (a) => state.leadRouting.unavailableAgents.indexOf(a.value) < 0,
  ),
  selectedAgents: state.leadRouting.selectedAgents,
});

const AgentSelector = ({ selectAgents, selectedAgents, availableAgents }) => (
  <div data-cy="new_lead_rule_user">
    <Dropdown
      placeholder="Select Agents"
      value={selectedAgents}
      onChange={selectAgents}
      isMulti
      isSearchable
      multiShouldRenderBelowSelect={false}
      options={availableAgents}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
      components={{ Option: CustomOption }}
    />
  </div>
);

export default connect(mapStateToProps, actions)(AgentSelector);

AgentSelector.propTypes = {
  selectAgents: Proptypes.func,
  selectedAgents: Proptypes.arrayOf(Proptypes.shape({})),
  availableAgents: Proptypes.arrayOf(Proptypes.shape({})),
};

AgentSelector.defaultProps = {
  selectAgents: null,
  selectedAgents: null,
  availableAgents: null,
};
