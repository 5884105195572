import React from "react";
import PropTypes from "prop-types";

import { startCase, toLower } from "lodash";

import DropdownWithRemovable from "../../shared/DropdownWithRemovable";
import { LEAD_STAGE_MAPPING, CANDIDATE_STAGE_MAPPING } from "../../shared/StagesDropDown/StagesDropDown";

const StageFilter = ({ stages }) => {
  const addHeaderToObjects = (array) => {
    const headerValue = array[0]?.header;

    return array.map(({ label, value, header }) => ({
      label: startCase(toLower(label)),
      value,
      header,
      headerPrefix: headerValue,
    }));
  };
  return (
    <DropdownWithRemovable
      options={[
        ...addHeaderToObjects(LEAD_STAGE_MAPPING),
        ...addHeaderToObjects(CANDIDATE_STAGE_MAPPING),
      ].map(({ label, value, header, headerPrefix }) => ({
        label: startCase(toLower(label)),
        value,
        header,
        headerPrefix,
      }))}
      fieldSelect
      inputIdPrefix="leads_filter_selected_stage_filters_"
      labelPrefix="Stage: "
      preSelectedRemovables={stages?.map((stage) => ({ [stage]: stage }))}
      inputFieldName="leads_filter[selected_stage_filters][]"
    />
  );
};

StageFilter.propTypes = {
  stages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StageFilter;
