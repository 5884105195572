/* eslint-disable react/prop-types */

import React from "react";

const cellSizeDictionary = {
  small: "tw-h-24px tw-w-24px",
  medium: "tw-h-36px tw-w-36px",
  large: "tw-h-48px tw-w-48px",
};

const imageSizeDictionary = {
  small: "tw-h-20px",
  medium: "tw-h-30px",
  large: "tw-h-40px",
};

const InitialsCircle = ({ initials, name, size = "large" }) => (
  <span
    className={`tw-inline-flex tw-items-center tw-justify-center tw-bg-gray-light ${cellSizeDictionary[size]} tw-rounded-full`}
    data-tip={name}
  >
    {initials}
  </span>
);

const AgentCell = ({ agent, size = "large" }) => {
  const { photoURL, name, showURL } = agent;
  const hasImage = !!photoURL;
  const hasProfileLink = !!showURL;

  const content = hasImage ? (
    <img
      className={`tw-my-4px tw-rounded-full ${imageSizeDictionary[size]} tw-m-w-full`}
      src={photoURL}
      alt={name}
      data-tip={name}
    />
  ) : (
    <InitialsCircle initials={agent.initials} name={agent.name} size={size} />
  );

  return (
    <div
      className={`tw-flex tw-flex-row tw-items-center tw-justify-center tw-flex-nowrap ${cellSizeDictionary[size]}`}
    >
      {hasProfileLink ? (
        <a className="tw-py-4px tw-flex tw-items-center tw-overflow-hidden" href={showURL}>
          {content}
        </a>
      ) : (
        <div className="tw-py-4px tw-flex tw-items-center tw-overflow-hidden tw-select-none">{content}</div>
      )}
    </div>
  );
};

export default AgentCell;
