/* eslint-disable no-param-reassign */
import { schemaGenerator } from "../utils";

const borderDictionary = {
  secondary: {
    medium:
      "tw-border-theme-button-medium hover:tw-border-theme-button-medium-hover active:tw-border-theme-button-medium-active disabled:tw-border-theme-button-medium",
    small:
      "tw-border-theme-button-small hover:tw-border-theme-button-small-hover active:tw-border-theme-button-small-active disabled:tw-border-theme-button-small",
  },
  other: {
    medium: "tw-border-2px active:tw-border-3px disabled:tw-border-2px",
    small: "tw-border-[1.5px] active:tw-border-2px disabled:tw-border-[1.5px]",
  },
};
export const border = (schema, size) => {
  const schemaKey = schema === "secondary" ? schema : "other";
  return borderDictionary[schemaKey][size];
};

const paddingDictionary = {
  secondary: {
    medium: [
      "tw-px-theme-button-horizontal-medium",
      "tw-py-theme-button-vertical-medium",
      "hover:tw-px-theme-button-horizontal-medium-hover",
      "hover:tw-py-theme-button-vertical-medium-hover",
      "active:tw-px-theme-button-horizontal-medium-active",
      "active:tw-py-theme-button-vertical-medium-active",
      "disabled:tw-px-theme-button-horizontal-medium",
      "disabled:tw-py-theme-button-vertical-medium",
    ].join(" "),
    small: [
      "tw-px-theme-button-horizontal-small",
      "tw-py-theme-button-vertical-small",
      "hover:tw-px-theme-button-horizontal-small-hover",
      "hover:tw-py-theme-button-vertical-small-hover",
      "active:tw-px-theme-button-horizontal-small-active",
      "active:tw-py-theme-button-vertical-small-active",
      "disabled:tw-px-theme-button-horizontal-small",
      "disabled:tw-py-theme-button-vertical-small",
    ].join(" "),
  },
  other: {
    medium:
      "tw-px-18px tw-py-6px active:tw-px-[17px] active:tw-py-[5px] disabled:tw-px-18px disabled:tw-py-6px",
    small:
      "tw-px-[6.5px] tw-py-[2.5px] active:tw-px-6px active:tw-py-2px disabled:tw-px-[6.5px] disabled:tw-py-[2.5px]",
  },
};
export const padding = (schema, size) => {
  const schemaKey = schema === "secondary" ? schema : "other";
  return paddingDictionary[schemaKey][size];
};

const fontSizeDictionary = {
  medium: "tw-text-14d",
  small: "tw-text-11d",
};
export const font = (size) => fontSizeDictionary[size] || fontSizeDictionary.small;

const heightDictionary = {
  medium: "tw-h-36px",
  small: "tw-h-24px",
};

export const height = (size) => heightDictionary[size] || heightDictionary.small;

export const schemaClasses = {
  primary: {
    default: ["tw-text-white", "tw-bg-theme-button-primary", "tw-border-theme-button-primary"],
    hover: ["hover:tw-bg-theme-button-primary-hover", "hover:tw-border-theme-button-primary-hover"],
    active: ["active:tw-bg-theme-button-primary-active", "active:tw-border-theme-button-primary-active"],
    loading: ["loading:tw-bg-theme-button-primary-active", "loading:tw-border-theme-button-primary-active"],
    disabled: [
      "disabled:tw-bg-theme-button-primary-disabled",
      "disabled:tw-border-theme-button-primary-disabled",
    ],
  },
  secondary: {
    default: ["tw-text-theme-button-secondary", "tw-bg-transparent", "tw-border-theme-button-secondary"],
    hover: ["hover:tw-bg-theme-button-secondary-hover", "hover:tw-border-theme-button-secondary-hover"],
    active: [
      "active:tw-text-theme-button-secondary-active",
      "active:tw-bg-theme-button-secondary-active",
      "active:tw-border-theme-button-secondary-active",
    ],
    loading: [
      "loading:tw-text-theme-button-secondary-active",
      "loading:tw-bg-theme-button-secondary-active",
      "loading:tw-border-theme-button-secondary-active",
    ],
    disabled: [
      "disabled:tw-bg-transparent",
      "disabled:tw-border-theme-button-secondary-disabled",
      "disabled:tw-text-theme-button-secondary-disabled",
    ],
  },
  tertiary: {
    default: ["tw-text-neutral-gray-75", "tw-bg-neutral-gray-10", "tw-border-neutral-gray-10"],
    hover: ["hover:tw-bg-neutral-gray-15", "hover:tw-border-neutral-gray-15"],
    active: ["active:tw-bg-neutral-gray-30", "active:tw-border-neutral-gray-30"],
    loading: [
      "loading:tw-bg-neutral-gray-30",
      "loading:tw-border-neutral-gray-30",
      "loading:tw-text-neutral-gray-75",
    ],
    disabled: [
      "disabled:tw-bg-neutral-gray-10",
      "disabled:tw-border-neutral-gray-10",
      "disabled:tw-text-neutral-gray-30",
    ],
  },
  warning: {
    default: ["tw-text-semantic-red-100", "tw-bg-transparent", "tw-border-semantic-red-100"],
    hover: ["hover:tw-bg-semantic-red-5", "hover:tw-border-semantic-red-100"],
    active: ["active:tw-bg-semantic-red-5", "active:tw-border-semantic-red-100"],
    loading: [
      "loading:tw-bg-semantic-red-5",
      "loading:tw-border-semantic-red-100",
      "loading:tw-text-semantic-red-100",
    ],
    disabled: [
      "disabled:tw-bg-transparent",
      "disabled:tw-border-semantic-red-10",
      "disabled:tw-text-semantic-red-10",
    ],
  },
  "transparent-primary": {
    default: ["tw-text-white", "tw-bg-transparent", "tw-border-white"],
    hover: [
      "hover:tw-bg-theme-button-transparent-primary-hover",
      "hover:tw-border-theme-button-transparent-primary-hover",
    ],
    active: [
      "active:tw-bg-theme-button-transparent-primary-active",
      "active:tw-border-theme-button-transparent-primary-active",
    ],
    loading: [
      "loading:tw-bg-theme-button-transparent-primary-active",
      "loading:tw-border-theme-button-transparent-primary-active",
      "loading:tw-text-white",
    ],
    disabled: [
      "disabled:tw-bg-transparent",
      "disabled:tw-border-neutral-gray-30",
      "disabled:tw-text-neutral-gray-30",
    ],
  },
  "transparent-secondary": {
    default: ["tw-text-white", "tw-bg-transparent", "tw-border-white"],
    hover: ["hover:tw-bg-black", "hover:tw-bg-opacity-50"],
    active: ["active:tw-bg-black", "active:tw-bg-opacity-75"],
    loading: ["loading:tw-bg-black", "loading:tw-bg-opacity-75"],
    disabled: [
      "disabled:tw-bg-transparent",
      "disabled:tw-border-neutral-gray-30",
      "disabled:tw-text-neutral-gray-30",
    ],
  },
  "semantic-blue": {
    default: ["tw-bg-white", "tw-border-semantic-blue-100", "tw-text-semantic-blue-100"],
    hover: ["hover:tw-bg-semantic-blue-5"],
    active: ["active:tw-bg-semantic-blue-10"],
    loading: ["loading:tw-bg-semantic-blue-10"],
    disabled: [
      "disabled:tw-bg-white",
      "disabled:tw-border-semantic-blue-10",
      "disabled:tw-text-semantic-blue-10",
    ],
  },
  "semantic-yellow": {
    default: ["tw-bg-transparent", "tw-border-[#E5941B]", "tw-text-[#E5941B]", "tw-border-[2px]"],
    hover: ["hover:tw-bg-semantic-yellow-10"],
    active: ["active:tw-bg-semantic-yellow-15"],
    loading: ["loading:tw-bg-semantic-yellow-15"],
    disabled: [
      "disabled:tw-bg-white",
      "disabled:tw-border-semantic-yellow-10",
      "disabled:tw-text-semantic-yellow-10",
    ],
  },
  "solid-green": {
    default: ["tw-text-white", "tw-bg-semantic-green-100", "tw-border-semantic-green-100"],
    hover: ["hover:tw-bg-semantic-green-110", "hover:tw-border-semantic-green-110"],
    active: ["active:tw-bg-semantic-green-120", "active:tw-border-semantic-green-120"],
    disabled: ["disabled:tw-bg-neutral-gray-30", "disabled:tw-border-neutral-gray-30"],
  },
  "solid-red": {
    default: ["tw-text-white", "tw-bg-semantic-red-100", "tw-border-semantic-red-100"],
    hover: ["hover:tw-bg-semantic-red-110", "hover:tw-border-semantic-red-110"],
    active: ["active:tw-bg-semantic-red-120", "active:tw-border-semantic-red-120"],
    disabled: ["disabled:tw-bg-neutral-gray-30", "disabled:tw-border-neutral-gray-30"],
  },
};

export const availableSchema = Object.keys(schemaClasses);

export const schemaDictionary = schemaGenerator(schemaClasses);
