/* eslint-disable spaced-comment */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";

import { getPrevNextListingsAsThunk } from "./action/thunks";

import NavigationDirection from "./NavigationDirection";
import { backToListingsUrl, currentFilters } from "../../Transactions/utils/urlHelpers";

const NavigationListings = ({
  uuid,
  nextSlug,
  prevSlug,
  nextTitle,
  prevTitle,
  nextUrl,
  prevUrl,
  onGetPrevNextListingsAsThunk,
}) => {
  const searchParams = currentFilters();
  searchParams.use_classic = searchParams.use_classic || false;

  /*URL to go back to listings w/ filters*/
  const backToListingsURL = backToListingsUrl(searchParams);

  useEffect(() => {
    onGetPrevNextListingsAsThunk(uuid, searchParams);
  }, [uuid]);

  return (
    <div className="tw-h-36px tw-px-16px tw-flex tw-items-center tw-justify-between">
      <NavigationDirection
        schema="prev"
        slug={prevSlug}
        title={prevTitle}
        searchParams={searchParams}
        url={prevUrl}
      />
      <a
        href={backToListingsURL}
        className="tw-text-neutral-gray-50 tw-font-bold tw-w-1/3 hover:tw-text-neutral-gray-50 hover:tw-no-underline tw-text-center"
      >
        BACK TO TRANSACTIONS
      </a>
      <NavigationDirection
        schema="next"
        slug={nextSlug}
        title={nextTitle}
        searchParams={searchParams}
        url={nextUrl}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  nextSlug: state.tdpDetailsReducer.navigationListing.nextSlug,
  prevSlug: state.tdpDetailsReducer.navigationListing.previousSlug,
  nextTitle: state.tdpDetailsReducer.navigationListing.nextTitle,
  prevTitle: state.tdpDetailsReducer.navigationListing.previousTitle,
  nextUrl: state.tdpDetailsReducer.navigationListing.nextUrl,
  prevUrl: state.tdpDetailsReducer.navigationListing.previousUrl,
});

const mapDispatchToProps = {
  onGetPrevNextListingsAsThunk: getPrevNextListingsAsThunk,
};

NavigationListings.propTypes = {
  uuid: string,
  onGetPrevNextListingsAsThunk: func,
  nextSlug: string,
  prevSlug: string,
  nextTitle: string,
  prevTitle: string,
  nextUrl: string,
  prevUrl: string,
};
NavigationListings.defaultProps = {
  uuid: null,
  onGetPrevNextListingsAsThunk: null,
  nextSlug: null,
  prevSlug: null,
  nextTitle: null,
  prevTitle: null,
  nextUrl: null,
  prevUrl: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationListings);
