/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import Linkify from "linkify-react";
import PropTypes from "prop-types";
import { runAtLabel, runAtValue, visibilityLabel } from "./helpers";
import { eventDetailString } from "./AssignedPlanDetailHelpers";
import RecurringDetail from "./RecurringDetail";

const TaskDetail = ({ action, parentAction, planType }) => (
  <div className="tw-flex tw-flex-col">
    <div className="header-wrap">
      <div className="col-xs-4">
        <label>{runAtLabel(action.type, action.state)}</label>
        <div>
          {runAtValue(action.run_at, action.state, action.completed_at)}
          {action.overdue && (
            <span>
              <span className="text-danger"> Overdue</span>
            </span>
          )}
        </div>
      </div>
      <div className="col-xs-4">
        <label>Task For</label>
        <div>{action.assigned_to_label}</div>
      </div>
      <div className="col-xs-4">
        {parentAction && action.contingent && (
          <div>
            <label>Contingent</label>
            <div className="ellipsify">{parentAction.name}</div>
          </div>
        )}
      </div>
    </div>

    <div className="row-border sub-header-wrap">
      <div className="col-xs-4">
        <label>Priority</label>
        <div>{action.priority}</div>
      </div>

      {planType === "transactions" && (
        <div className="col-xs-4">
          <label>Visibility</label>
          <div>{visibilityLabel(action.visibility)}</div>
        </div>
      )}

      {action.milestone_name && (
        <div className="col-xs-4">
          <label>Event</label>
          <div>{eventDetailString(action)}</div>
        </div>
      )}

      {action.recurring_frequency !== "never" && (
        <div className="col-xs-4">
          <RecurringDetail
            recurringFrequency={action.recurring_frequency}
            recurringLimit={action.recurring_limit}
            recurringDaysOfFrequency={action.recurring_days_of_frequency}
          />
        </div>
      )}
    </div>

    <div className="row-border sub-header-wrap">
      <div className="col-xs-9">
        <label>Task Details</label>
        <div className="">{action.name}</div>
      </div>
      <div className="col-xs-3 action-fail-message">{action.state === "failed" && action.failed_message}</div>
    </div>

    <div className="content-wrap">
      <div className="col-xs-12 tw-whitespace-pre-wrap">
        <div className="body-content-wrap">
          {action.instructions && (
            <div className="row p-b-10">
              <div className="col-xs-12">
                <label>Instructions</label>
                <Linkify tagName="p" options={{ defaultProtocol: "https", target: "_blank" }}>
                  {action.instructions}
                </Linkify>
              </div>
            </div>
          )}
          {action.note && (
            <div className="p-b-10">
              <div className="col-xs-12">
                <label>Notes</label>
                <Linkify tagName="p" options={{ defaultProtocol: "https", target: "_blank" }}>
                  {action.note}
                </Linkify>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default TaskDetail;

TaskDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  parentAction: PropTypes.objectOf(PropTypes.any).isRequired,
  planType: PropTypes.string.isRequired,
};
