import React, { useState } from "react";
import { transformAppointmentRow } from "./appointmentsReportHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Avatar from "@shared/v2/Avatar";
import { TD, TR } from "./BusinessDashboard/Table/Table";

const AppointmentsRow = ({ cells }) => {
  const [isOpen, setIsOpen] = useState(false);
  const agentCell = cells?.[0];

  return (
    <TR>
      <TD className="tw-py-[8px] tw-align-top" border="tw-border-b-[1px] tw-border-r-0">
        <div className="tw-flex tw-items-center tw-gap-[8px]">
          <a href={`/people/${agentCell.user_path}`}>
            <Avatar
              src={/$https/.test(agentCell.avatar) ? agentCell.avatar : ""}
              alt={agentCell.agent}
              size="medium"
            />
          </a>
          {agentCell.agent}
        </div>
      </TD>

      {cells.slice(1).map((header, index) => (
        <TD key={index} className="tw-align-top" border="tw-border-b-[1px] tw-border-r-0">
          <div className="tw-flex tw-gap-[4px]">
            <span>{header.value}</span>
            <span>
              {header.dropDown && (
                <FontAwesomeIcon
                  className="tw-fa-1x tw-text-gray-50"
                  icon={isOpen ? faCaretUp : faCaretDown}
                  onClick={() => setIsOpen(!isOpen)}
                />
              )}
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[4px]">
            {isOpen &&
              header.dropDown?.map(({ color, label, value }, i) => (
                <div key={i} className="tw-flex tw-gap-[4px] tw-text-12d">
                  <span style={{ color }}>{value}</span>
                  <span className="tw-text-gray-50 first-letter:tw-capitalize">{label}</span>
                </div>
              ))}
          </div>
        </TD>
      ))}
    </TR>
  );
};

export default AppointmentsRow;
