/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowLeft = (props) => (
  <Icon {...props} name="Arrow Left">
    <path d="m8.986 13.706-.653.67a.69.69 0 0 1-.997 0L1.619 8.513a.735.735 0 0 1 0-1.023l5.717-5.866a.69.69 0 0 1 .997 0l.653.67a.738.738 0 0 1-.012 1.035L5.43 6.793h8.452c.391 0 .706.323.706.724v.966a.714.714 0 0 1-.706.724H5.43l3.544 3.464a.733.733 0 0 1 .012 1.035z" />
  </Icon>
);

export default ArrowLeft;
