import React from "react";
import Proptypes from "prop-types";

const IntentDropdown = ({ currentref, handleToggle, setshow }) => (
  <button
    aria-label="intent"
    type="button"
    className="focus:tw-outline-none intent-box none tw-bg-transparent tw-border-0"
    ref={currentref}
    onClick={handleToggle}
    onBlur={() => setshow(false)}
  >
    <i className="fa fa-plus tw-pb-[3px]" style={{ fontSize: "10px" }} />
  </button>
);

IntentDropdown.propTypes = {
  handleToggle: Proptypes.func,
  currentref: Proptypes.shape({}),
  setshow: Proptypes.func,
};
IntentDropdown.defaultProps = {
  handleToggle: null,
  currentref: null,
  setshow: null,
};

export default IntentDropdown;
