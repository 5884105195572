import React, { useState } from "react";
import Proptypes from "prop-types";

import FileInformation from "@shared/ModalUpload/DocumentListItem/FileInformation";

import Menubtn from "./Menubtn";

const Addendum = ({ addendum, handleAddendumsReplace, deleteAddendum, renameAddendum }) => {
  const [editFilename, setEditFilename] = useState(false);

  const handleTriggerRename = () => {
    setEditFilename(true);
  };

  const handleTriggerReplace = (docId, e) => {
    handleAddendumsReplace(docId, e);
  };

  const deleteDocuments = (docId) => {
    deleteAddendum(docId);
  };

  const renameDocument = (docId, newName) => {
    renameAddendum(docId, newName);
  };

  const handlePreview = () => {
    if (addendum.expiringUrl) {
      window.open(addendum.expiringUrl, "_blank");
    }
  };

  return (
    <div
      className="tw-border-solid tw-bg-[#FFFFFF] tw-border-neutral-gray-10 tw-w-[552px] tw-mb-8px !tw-py-[13px] tw-px-15px tw-border tw-rounded-4px"
      data-cy="agreement-edit-documents-upload"
      key={addendum.id}
    >
      <div className="!tw-flex tw-grid tw-grid-cols-6 tw-gap-[16px] tw-justify-between tw-h-[33px]">
        <FileInformation
          editFilename={editFilename}
          setEditFilename={setEditFilename}
          renameDocument={renameDocument}
          previewDocument={handlePreview}
          document={addendum}
        />
        <div className="tw-col-end-8 tw-col-span-1 tw-pt-[4px]">
          <Menubtn
            document={addendum}
            triggerReplace={handleTriggerReplace}
            triggerRename={handleTriggerRename}
            deleteDocuments={deleteDocuments}
          />
        </div>
      </div>
    </div>
  );
};

Addendum.propTypes = {
  addendum: Proptypes.shape({}),
  handleAddendumsReplace: Proptypes.func,
  deleteAddendum: Proptypes.func,
  renameAddendum: Proptypes.func,
};
Addendum.defaultProps = {
  addendum: {},
  handleAddendumsReplace: () => {},
  deleteAddendum: () => {},
  renameAddendum: () => {},
};
export default Addendum;
