import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import Avatar from "@shared/v2/Avatar";
import AvatarGroup from "@shared/v2/AvatarGroup";

const userCollectionListing = ({ users = [], max = 4 }) => {
  const [showFullList, toggleUserList] = useState(0);

  const toggleUserListFn = debounce((val) => {
    toggleUserList(val);
  }, 150);

  return (
    <span
      className="tw-inline-flex tw-relative tw-cursor-pointer"
      onMouseOver={() => {
        toggleUserListFn(1);
      }}
      onMouseOut={() => {
        toggleUserListFn(0);
      }}
    >
      <AvatarGroup max={max} size="medium">
        {users.map((user) => {
          return <Avatar key={user.uuid} src={user.image_url} alt={`${user.name}`} />;
        })}
      </AvatarGroup>
      {showFullList ? (
        <div className="tw-absolute tw-right-0 tw-w-52 tw-mh-72 tw-z-50 tw--mr-56">
          <div className=" tw-absolute tw-h-[10px] tw-w-[10px] tw--left-5px tw-top-15px tw-bg-gray-50 tw-rotate-45 tw--z-1" />
          <div className="tw-h-full tw-w-full tw-p-3 tw-rounded-md tw-overflow-y-auto tw-overflow-x-hidden tw-z-1 tw-bg-gray-50 ">
            {users.map((user) => {
              return (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  key={user.uuid}
                  href={`/people/${user.slug}?tab=details-tab`}
                  title={user.name}
                  className="tw-block tw-text-white tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-w-full"
                >
                  {user.name}
                </a>
              );
            })}
          </div>
        </div>
      ) : null}
    </span>
  );
};

export default userCollectionListing;
