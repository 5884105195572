import React from "react";
import PropTypes from "prop-types";

const START_YEAR = 2017;

const DEFAULT_YEAR_OPTIONS = Array(new Date().getFullYear() + 2 - START_YEAR)
  .fill(null)
  .map((_, i) => START_YEAR + i);

export const DEFAULT_YEAR_OPTIONS_DROPDOWN = DEFAULT_YEAR_OPTIONS.map((y) => ({ value: y, label: y }));

export const PERIOD_OPTIONS = ["Yearly", "Monthly", "Weekly"];

const HeadingSelect = ({ disabled, title, name, value, onChange, options }) => (
  <div className="tw-flex tw-flex-wrap tw-items-baseline">
    {typeof title === "string" && <h3 className="tw-text-18d tw-font-bold tw-mr-[16px]">{title}</h3>}
    {typeof title !== "string" && title}
    <div className="tw-min-w-[60px]">
      <select
        disabled={disabled}
        className="tw-border-0 tw-bg-transparent"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        name={name}
      >
        {options.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default HeadingSelect;

HeadingSelect.defaultProps = {
  disabled: false,
  options: DEFAULT_YEAR_OPTIONS,
};

HeadingSelect.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};
