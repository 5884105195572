import React from "react";
import { bool, func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import {
  CLIENTDETAILS_FINANCING,
  CLIENTINTENT,
  LISTING_UUID,
} from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";

import DetailDropdown from "../DetailDropdown";
import { putRefFinancial } from "../actions/thunks";

const isClientIntentBuyer = (clientIntent) => {
  if (!clientIntent) return clientIntent;
  return clientIntent.toLowerCase() === "buyer";
};

const FINANCING_OPTIONS = [
  { label: "Pre-Approved", value: "Pre-Approved" },
  { label: "Pre-Qualified", value: "Pre-Qualified" },
  { label: "Cash Offer", value: "Cash Offer" },
  { label: "None", value: "None" },
];

const FinancingField = ({ financing, onBlur, show }) =>
  show && (
    <DetailDropdown
      dataCy="ClientDetailsFinancing"
      label="Financing"
      options={FINANCING_OPTIONS}
      val={financing}
      onBlur={({ value }) => onBlur(value)}
    />
  );

const FinancingFieldWrapper = ({ financing, onPutRefFinancial, clientIntent, uuid }) => (
  <FinancingField
    show={isClientIntentBuyer(clientIntent)}
    financing={{ label: financing, value: financing }}
    onBlur={(fin) => onPutRefFinancial(uuid, fin)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: FinancingFieldWrapper,
  states: [CLIENTDETAILS_FINANCING, CLIENTINTENT, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefFinancial,
  aliases: "onPutRefFinancial",
});

FinancingField.propTypes = {
  financing: string,
  onBlur: func,
  show: bool,
};

FinancingField.defaultProps = {
  financing: null,
  onBlur: () => {},
  show: false,
};

FinancingFieldWrapper.propTypes = {
  financing: string,
  onPutRefFinancial: func,
  clientIntent: string,
  uuid: string,
};

FinancingFieldWrapper.defaultProps = {
  financing: null,
  onPutRefFinancial: () => {},
  clientIntent: null,
  uuid: null,
};
