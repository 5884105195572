import { useEffect } from "react";

import { EVENTS } from "../constants";

export default (payloadHandlrs, assignableAgents, payload, currentUserUuid) => {
  const { assignedPerson, eventType } = payload;
  const currentUser = assignableAgents.find((agent) => agent.value === currentUserUuid);

  useEffect(() => {
    payloadHandlrs((prev) => ({
      ...prev,
      eventType: eventType || EVENTS[0],
      assignedPerson: assignedPerson || currentUser,
    }));
    return () =>
      payloadHandlrs((prev) => ({
        ...prev,
        assignedPerson: assignedPerson || currentUser,
      }));
  }, [currentUser, EVENTS]);
};
