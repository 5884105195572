/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Image = (props) => (
  <Icon {...props} name="Image">
    <path d="M14 3.811V4h-4V0h.189c.199 0 .39.079.53.22l3.061 3.061c.141.141.22.331.22.53zM9.75 5A.752.752 0 0 1 9 4.25V0H2.75A.75.75 0 0 0 2 .75v14.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75V5H9.75zm-4.233.5a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 5.517 5.5zm6.5 7.5h-8l.015-1.515 1.235-1.235c.146-.146.369-.131.515.015L7.017 11.5l3.235-3.235a.375.375 0 0 1 .53 0L12.017 9.5V13z" />
  </Icon>
);

export default Image;
