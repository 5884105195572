import format from "../formatters";

export const removeCommas = (value) => value.replace(/,/g, "");

export const formatNumber = (value, commas) => {
  if (Number.isNaN(value) || value === "NaN") {
    return "NaN";
  }

  if (value !== 0 && !value) {
    return "";
  }

  const formattedNumber = format.number(removeCommas(value), 2);
  return commas ? formattedNumber : removeCommas(formattedNumber);
};

export const extractNumeric = (value) => value.replace(/[^0-9,.]/g, "");

/**
 * - `000` => `0`
 * - `005` => `5`
 * - `00.5` => `0.5`
 */
export const removeLeadingZeroes = (value) => value.replace(/^(0(?!([,.]|$)))+/g, "");

export const isValidValue = (value) => formatNumber(value, false) !== "NaN";

export const isValidPercentage = (value) => Number.parseFloat(value) <= 100;

export const areValuesDifferent = (valueA, valueB) =>
  formatNumber(valueA, false) !== formatNumber(valueB, false);
