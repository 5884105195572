import Button from "@shared/v2/Button";
import React from "react";

const EmptyState = ({ isInitialState, onClickSearchButton, error }) => {
  const header = getHeader(isInitialState, error);
  const message = getMessage(isInitialState, error);

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-mt-[206px] tw-items-center">
      <div className="tw-text-center">
        <i className="fa fa-search tw-text-36px tw-mb-20px tw-text-theme-primary" />
        <p className="tw-text-gray-75 tw-text-18px tw-font-bold tw-mb-12px">{header}</p>
        <p className="tw-text-gray-75 tw-mb-20px">{message}</p>
        {isInitialState && (
          <Button size="medium" schema="primary" onClick={onClickSearchButton}>
            SEARCH
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;

const getHeader = (isInitialState, error) => {
  if (error) {
    return "Looks like the search failed.";
  }

  return isInitialState ? "Search Properties" : "No matching results";
};

const getMessage = (isInitialState, error) => {
  if (error) {
    return (
      <>
        Please refresh and try again.
        <br />
        If the problem persists, contact support.
      </>
    );
  }

  return isInitialState
    ? "Enter an address, neighborhood, city, ZIP code, or county to search"
    : "Please try searching in a different area";
};
