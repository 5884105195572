import React from "react";
import { func, number, oneOfType, string } from "prop-types";

import { TRANSACTION_AMT_ALIASES } from "../../../shared/constants";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TRANSACTIONAMT, CLIENTINTENT, LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { putRefTransactionAmt } from "../actions/thunks";

import DetailInput from "../DetailInput";

const ReferralTransactionAmount = ({ label, transactionAmt, onSetTransactionAmount }) => (
  <DetailInput
    schema="currency"
    label={label}
    val={transactionAmt}
    onBlur={onSetTransactionAmount}
    dataCy="refTransactionAmt"
  />
);

const ReferralTransactionAmountWrapper = ({ clientIntent, transactionAmt, onPutRefTransactionAmt, uuid }) => (
  <ReferralTransactionAmount
    label={TRANSACTION_AMT_ALIASES[clientIntent]}
    transactionAmt={transactionAmt}
    onSetTransactionAmount={(val) => onPutRefTransactionAmt(uuid, val)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralTransactionAmountWrapper,
  states: [CLIENTINTENT, TRANSACTIONAMT, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefTransactionAmt,
  aliases: "onPutRefTransactionAmt",
});

ReferralTransactionAmount.propTypes = {
  label: string,
  transactionAmt: oneOfType([string, number]),
  onSetTransactionAmount: func,
};

ReferralTransactionAmount.defaultProps = {
  label: null,
  transactionAmt: null,
  onSetTransactionAmount: () => {},
};

ReferralTransactionAmountWrapper.propTypes = {
  clientIntent: string,
  transactionAmt: oneOfType([string, number]),
  onPutRefTransactionAmt: func,
  uuid: string,
};

ReferralTransactionAmountWrapper.defaultProps = {
  clientIntent: null,
  transactionAmt: null,
  onPutRefTransactionAmt: () => {},
  uuid: null,
};
