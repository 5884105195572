import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { headline2 } from "@shared/styles";
import DropdownDatePicker from "@shared/v2/DropdownDatePicker";
import TextInput from "@shared/v2/TextInput";
import FieldTextV1 from "../../shared/UtilsV1/FieldTextV1";
import FieldDateV1 from "../../shared/UtilsV1/FieldDateV1";
import TagsSection from "../ModalSections/TagsSection";
import FieldTextWithIconV1 from "../../shared/UtilsV1/FieldTextWithIconV1";
import FieldSelect from "../../shared/UtilsV1/FieldSelect";
import { containerView } from "./styles";
import { setPersonValue } from "../../reducers/contactsReducer";
import { useContactFormOptions, useContactPerson } from "../../reducers/contactsReducer/selectors";

const PersonalDetailsView = ({ birthdateError }) => {
  const dispatch = useDispatch();
  const person = useContactPerson();
  const formOptions = useContactFormOptions();
  const { anniversary, birthdate } = person.milestones || {};

  return (
    <div className={containerView}>
      <div className={`tw-text-neutral-gray-75 ${headline2}`}>Personal Details</div>
      <TextInput
        label="Description"
        name="description"
        multiline
        value={person.description}
        onChange={(e) => dispatch(setPersonValue({ key: "description", value: e.target.value }))}
      />
      <TagsSection
        tagOptions={formOptions.tagsOptions}
        tags={person.tagNames}
        setPersonValue={(...args) => dispatch(setPersonValue(...args))}
      />

      <div className="tw-flex tw-gap-[24px]">
        <FieldSelect
          label="Timezone"
          options={formOptions.timezoneOptions}
          value={person.timezone}
          onChange={(value) => dispatch(setPersonValue({ key: "timezone", value }))}
          dropdownWidth="tw-w-fit"
        />
        <DropdownDatePicker
          error={birthdateError}
          label="Birthdate"
          value={birthdate}
          onChange={(value) =>
            dispatch(
              setPersonValue({
                key: "milestones.birthdate",
                value,
              }),
            )
          }
        />
      </div>

      <div className="tw-flex tw-gap-[24px]">
        <FieldSelect
          label="Marital Status"
          options={formOptions.maritalStatusOptions}
          value={person.maritalStatus}
          onChange={(value) => dispatch(setPersonValue({ key: "maritalStatus", value }))}
        />
        <FieldDateV1
          label="Home Anniversary"
          dateFormat="MM/DD/YYYY"
          value={anniversary}
          onChange={(value) =>
            dispatch(
              setPersonValue({
                key: "milestones.anniversary",
                value,
              }),
            )
          }
        />
      </div>

      <div className="tw-flex tw-gap-[24px]">
        <FieldTextV1
          label="Kids"
          value={person.kids}
          onChange={(value) => dispatch(setPersonValue({ key: "kids", value }))}
        />
        <FieldTextV1
          label="Pets"
          value={person.pets}
          onChange={(value) => dispatch(setPersonValue({ key: "pets", value }))}
        />
      </div>

      <div className="tw-flex tw-gap-[24px]">
        <FieldTextV1
          label="Company"
          value={person.company}
          onChange={(value) => dispatch(setPersonValue({ key: "company", value }))}
        />
        <FieldTextV1
          label="Job Title"
          value={person.jobTitle}
          onChange={(value) => dispatch(setPersonValue({ key: "jobTitle", value }))}
        />
      </div>

      {formOptions.militaryStatusOptions && (
        <div className="tw-flex tw-gap-[24px]">
          <FieldSelect
            label="Military Status"
            options={formOptions.militaryStatusOptions}
            value={person.militaryStatus}
            onChange={(value) => dispatch(setPersonValue({ key: "militaryStatus", value }))}
          />
        </div>
      )}

      <hr className="tw-w-full tw-border-gray-10" />
      <div className="tw-flex tw-gap-[24px]">
        <FieldTextWithIconV1
          label="Facebook"
          iconClassName="fa-facebook"
          value={person.facebook}
          onChange={(value) => dispatch(setPersonValue({ key: "facebook", value }))}
          placeholder="Facebook profile URL"
        />
        <FieldTextWithIconV1
          label="Twitter"
          iconClassName="fa-twitter"
          value={person.twitter}
          onChange={(value) => dispatch(setPersonValue({ key: "twitter", value }))}
          placeholder="Twitter profile URL"
        />
      </div>
      <div className="tw-flex tw-gap-[24px]">
        <FieldTextWithIconV1
          label="LinkedIn"
          iconClassName="fa-linkedin"
          value={person.linkedin}
          onChange={(value) => dispatch(setPersonValue({ key: "linkedin", value }))}
          placeholder="LinkedIn profile URL"
        />
        <FieldTextWithIconV1
          label="Instagram"
          iconClassName="fa-instagram"
          value={person.instagram}
          onChange={(value) => dispatch(setPersonValue({ key: "instagram", value }))}
          placeholder="Instagram profile URL"
        />
      </div>
      <div className="tw-flex tw-gap-[24px]">
        <FieldTextWithIconV1
          label="YouTube"
          value={person.youtube}
          onChange={(value) => dispatch(setPersonValue({ key: "youtube", value }))}
          iconClassName="fa-youtube-play"
          placeholder="YouTube profile URL"
        />
        <FieldTextWithIconV1
          label="Pinterest"
          value={person.pinterest}
          onChange={(value) => dispatch(setPersonValue({ key: "pinterest", value }))}
          iconClassName="fa-pinterest-p"
          placeholder="Pinterest profile URL"
        />
      </div>
      <div className="tw-flex tw-gap-[24px]">
        <FieldTextWithIconV1
          label="TikTok"
          value={person.tiktok}
          onChange={(value) => dispatch(setPersonValue({ key: "tiktok", value }))}
          iconClassName="fa-tiktok"
          placeholder="TikTok profile URL"
        />

        <div className="tw-flex-1" />
      </div>
    </div>
  );
};

PersonalDetailsView.propTypes = {
  birthdateError: PropTypes.string,
};

PersonalDetailsView.defaultProps = {
  birthdateError: "",
};

export default PersonalDetailsView;
