import React from "react";
import PropTypes from "prop-types";

import { availableSizes, sizeClasses } from "./Icon.styles";

const Icon = ({ className, size, name, children, ...otherProps }) => (
  <svg
    // SVGs have `overflow: hidden` globally applied by default,
    // but to prevent them from shrinking in containers with not enough width,
    // we force the overflow to be visible
    className={`
      !tw-overflow-visible
      ${sizeClasses(size)}
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 16 16"
    fill="currentColor"
    data-icon={name}
    {...otherProps}
  >
    {children}
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(availableSizes),
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Icon.defaultProps = {
  className: "",
  size: "m",
};

export default Icon;
