import React from "react";
import PropTypes from "prop-types";
import PersonResult from "./PersonResult";
import { Col, Row } from "react-bootstrap";

const PeopleResults = ({
  searchResults,
  onHover,
  onClickHandle,
  searchValid,
  searchTerm,
  searchTermLength,
}) => (
  <Row>
    <Col xs={11} className="tw-pr-0">
      <div className="tw-mt-[4px] tw-max-h-[392px] tw-overflow-y-scroll tw-rounded-sm tw-border-solid tw-border tw-border-gray-15 tw-shadow-bootstrap-18 tw-z-10">
        {!searchValid && (
          <div className="tw-h-[32px] tw-px-[12px] tw-py-[6px]">
            Enter {1 - searchTermLength} more characters to search
          </div>
        )}

        {searchResults.map((result, i) => {
          return (
            <PersonResult
              key={result.person_id}
              result={result}
              index={i}
              onHover={onHover}
              onClickHandle={onClickHandle}
            />
          );
        })}
        {!searchResults.length && searchValid && (
          <div className="tw-h-[32px] tw-px-[12px] tw-py-[6px]">
            No results for <b>{searchTerm}</b>
          </div>
        )}
      </div>
    </Col>
  </Row>
);

PeopleResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  onHover: PropTypes.func,
  onClickHandle: PropTypes.func,
  searchValid: PropTypes.bool,
  searchTerm: PropTypes.string,
  searchTermLength: PropTypes.number,
};
PeopleResults.defaultProps = {
  searchResults: [],
  onHover: () => {},
  onClickHandle: () => {},
  searchValid: false,
  searchTerm: "",
  searchTermLength: 0,
};
export default PeopleResults;
