import React, { useState } from "react";
import PropTypes from "prop-types";
import TextToggle from "@shared/v2/TextToggle/TextToggle";
import { Section } from "../BusinessDashboard";
import MetricsGrid from "./MetricsGrid";
import useProductionStats from "./services/useProductionStats";
import Loader from "../../../Dashboard/components/Loader";
import Error from "../DashboardShared/Error";

const ProductionStatsSection = ({ year }) => {
  const [stat, setStat] = useState("gci");
  const { data, loading, error } = useProductionStats({ year, stat });

  return (
    <Section className="tw-gap-[16px] tw-flex-1 tw-min-h-[230px]">
      <Error show={Boolean(error)} message="Whoops! Something went wrong." />
      <MetricsGrid
        actions={
          <TextToggle
            size="small"
            name="productionStat"
            options={[
              {
                label: "GCI",
                value: "gci",
              },
              {
                label: "Net Income",
                value: "netIncome",
              },
            ]}
            value={stat}
            onChange={(e) => setStat(e.target.value)}
          />
        }
        titleName={data?.titleName}
        metrics={data?.metrics}
      />
      {loading && <Loader />}
    </Section>
  );
};

ProductionStatsSection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ProductionStatsSection;
