import React from "react";

import { getStatusValues } from "../styleHelpers";

const StatusOptions = ({ availableStatuses, statuses, onToggle }) => {
  return (
    <div className="tw-flex tw-flex-row tw-flex-wrap tw-w-full">
      {availableStatuses.map((status) => (
        <Status key={`status-${status}`} status={status} isSelected={statuses.includes(status)} onToggle={onToggle} />
      ))}
    </div>
  );
};

export default StatusOptions;

const Status = ({ status, isSelected, onToggle }) => {
  const {backgroundColor, text} = getStatusValues(status);
  const colors = isSelected ? `${backgroundColor} tw-border-transparent` : "tw-border-gray-15";
  const textStyle = isSelected ? "tw-text-white" : "tw-text-gray-75";

  return (
    <div
      onClick={() => onToggle(status)}
      className={`tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-py-4px tw-px-12px tw-mr-4px tw-border tw-border-solid tw-rounded-full ${colors}`}
    >
      <span className={`tw-text-12px ${textStyle}`}>{text}</span>
    </div>
  );
};
