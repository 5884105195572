/* eslint-disable camelcase */
import moment from "moment";

const createPerson = ({ first_name, last_name, avatar_url, uuid, id }) => ({
  fullName: `${first_name} ${last_name}`,
  avatar: avatar_url,
  uuid,
  id,
});

const createContingent = (contingencyRule) => !!Object.keys(contingencyRule).length && contingencyRule;

export const createDate = (date) => {
  const momentDate = moment(date, "YYYY-MM-DD").format("MM/DD/YY");

  return momentDate === "Invalid date" ? null : momentDate;
};

export const createTask = (task) => ({
  id: task.id,
  name: task.name,
  assignedPlan: task.assigned_plan,
  assigned_person: task.assigned_person && createPerson(task.assigned_person),
  completed_by: task.completed_by && createPerson(task.completed_by),
  priority: task.priority,
  type: task.type,
  due_on: createDate(task.due_on),
  recurringFrequency: task.recurring ? task.recurring_frequency : null,
  recurringDayOfWeek: task.recurring ? task.recurring_day_of_week : null,
  recurringEverySoManyDays: task.recurring ? task.recurring_every_so_many_days : null,
  externally_visible: task.externally_visible,
  dueness: task.dueness,
  completed_at: createDate(task.completed_at),
  state: task.state,
  contingent: task.contingent && createContingent(task.contingency_rule),
  contingentEventId: task.contingency_event_id,
  instructions: task.instructions,
  note: task.note,
});

export const adaptPostDueOn = (date) =>
  date ? moment(date, "MM/DD/YYYY").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

export const adaptRecurringDays = (payload) =>
  payload.recurring?.frequency?.value === "number"
    ? payload.recurring?.recurring_every_so_many_days * 1
    : null;

export const adaptRecurringDayOfWeek = (payload) =>
  payload.recurring?.frequency?.value === "weekly" ? payload.recurring?.recurring_day_of_week.value : null;

export const adaptContingentRule = (payload) =>
  payload.contingent
    ? {
        days: payload.contingent?.numOfDays * 1,
        position: payload.contingent?.next,
        event: payload.contingent?.event?.value,
      }
    : null;

export const createFilters = (fl, tasks) => {
  const filters = JSON.parse(JSON.stringify(fl));

  tasks.forEach(
    ({ assigned_plan, assigned_person, completed_at, contingent, contingentEventId, due_on }, idx) => {
      if (completed_at) {
        filters.primaryFilters.byName.completed_at.count += 1;
        filters.primaryFilters.byName.completed_at.tasks.push(idx);
        return;
      }
      if (contingent && !due_on) {
        // contingent tasks with due_on TBD.
        filters.primaryFilters.byName.contingent.count += 1;
        filters.primaryFilters.byName.contingent.tasks.push(idx);
        if (!filters.byContingentId[contingentEventId]) {
          filters.byContingentId[contingentEventId] = { tasks: [idx] };
        } else {
          filters.byContingentId[contingentEventId].tasks.push(idx);
        }

        return;
      }

      if (due_on && moment(due_on, "MM/DD/YY").isAfter(moment(), "day")) {
        filters.primaryFilters.byName.upcoming.count += 1;
        filters.primaryFilters.byName.upcoming.tasks.push(idx);
      }

      if (due_on && moment(due_on, "MM/DD/YY").isSame(moment(), "day")) {
        filters.primaryFilters.byName.due_today.count += 1;
        filters.primaryFilters.byName.due_today.tasks.push(idx);
      }

      if (due_on && moment(due_on, "MM/DD/YY").isBefore(moment(), "day")) {
        filters.primaryFilters.byName.overdue.count += 1;
        filters.primaryFilters.byName.overdue.tasks.push(idx);
      }

      if (!assigned_person && !assigned_plan) {
        filters.secondaryFilters.byUnAssigned.count += 1;
        filters.secondaryFilters.byUnAssigned.tasks.push(idx);
      }

      if (assigned_person) {
        if (assigned_person.id in filters.secondaryFilters.byTeamMember) {
          filters.secondaryFilters.byTeamMember[assigned_person.id].count += 1;
          filters.secondaryFilters.byTeamMember[assigned_person.id].tasks.push(idx);
        } else {
          filters.secondaryFilters.byTeamMember = {
            ...filters.secondaryFilters.byTeamMember,
            [assigned_person.id]: {
              count: 1,
              tasks: [idx],
              id: assigned_person.id,
              fullName: assigned_person.fullName,
              avatar: assigned_person.avatar,
            },
          };
        }
      }
    },
  );

  return filters;
};
