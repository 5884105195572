/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const FishSolidV3 = (props) => (
  <Icon {...props} name="Fish Solid V3">
    <path d="M9.086 3.556c-2.499 0-4.682 1.521-5.896 2.823L.764 4.544c-.337-.255-.84.017-.754.407L.682 8 .01 11.049c-.086.391.417.662.754.407L3.19 9.621c1.215 1.301 3.397 2.823 5.897 2.823 3.818 0 6.914-3.556 6.914-4.444s-3.096-4.444-6.914-4.444zm2.429 5.111a.667.667 0 1 1 .001-1.335.667.667 0 0 1-.001 1.335z" />
  </Icon>
);

export default FishSolidV3;
