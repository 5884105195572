/* eslint-disable react/jsx-props-no-spreading */
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { ADDRESS_FIELD } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { referralDesiredAddressEditOn } from "../../actions/creators";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import withPencilVisibleStates from "../../shared/HOCs/withPencilVisibleStates";

import TextBtnWithPencil from "../../shared/TextBtnWithPencil";

const withReduxState = tdpDetails({
  WrappedComponent: withPencilVisibleStates(TextBtnWithPencil),
  states: ADDRESS_FIELD,
  aliases: "txtField",
});

export default withDispatch({
  WrappedComponent: withReduxState,
  actions: referralDesiredAddressEditOn,
  aliases: "onClick",
});
