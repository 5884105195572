/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PlaySolidV6 = (props) => (
  <Icon {...props} name="Play Solid V6">
    <path d="M13.281 6.719c.447.275.719.759.719 1.281s-.272 1.006-.719 1.253l-8.999 5.5c-.463.309-1.043.322-1.516.056S2 14.043 2 13.5v-11a1.5 1.5 0 0 1 2.282-1.28l8.999 5.499z" />
  </Icon>
);

export default PlaySolidV6;
