/* eslint-disable import/prefer-default-export  */
import { caseTransformingAxios } from "../shared/v2/caseTransformingAxios";
import { tryCatchHandlr } from "../shared/Utilities";

export const loadDetailsApi = async ({ controller, id, errorCb, successCb }) => {
  const url = `/api/v4/partner_searches/${id}`;
  const [resData, err] = await tryCatchHandlr(
    caseTransformingAxios.get(url, {
      signal: controller.signal,
    }),
  );
  if (err) {
    return errorCb(err);
  }
  return successCb(resData);
};

export const searchPartnersApi = async ({ controller, data, errorCb, successCb }) => {
  const url = `/api/v4/partner_searches/`;
  const [resData, err] = await tryCatchHandlr(
    caseTransformingAxios.post(url, data, {
      signal: controller.signal,
    }),
  );
  if (err) {
    if (err.name === "CanceledError") return;
    errorCb(err);
    return;
  }
  successCb(resData);
};

export const autoComplete = async (searchText) => {
  if (!(searchText || "").trim()) return null;
  const url = `/api/v4/partner_searches/`;
  const [resData, err] = await tryCatchHandlr(
    caseTransformingAxios.post(url, { q: searchText, scope: "area_suggestion" }),
  );

  return err
    ? null
    : resData?.data?.areaSuggestions?.map((p) => ({ label: p.description, value: p.placeId }));
};
