import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import Checkbox from "../../../../shared/v2/Checkbox";

const CustomOption = ({ componentProps, children }) => (
  <Dropdown.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="tw-flex tw-items-center tw-px-[8px] tw-py-[4px] tw-cursor-pointer"
  >
    <Checkbox checked={componentProps.isSelected} /> <span className="tw-pl-[8px]">{children}</span>
  </Dropdown.Option>
);

CustomOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  componentProps: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default CustomOption;
