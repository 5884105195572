import React from "react";

import PropTypes from "prop-types";

import { FLYOUT_HEADER_CLASSES } from "./Flyout.styles";

const FlyoutHeader = ({ children }) => <div className={FLYOUT_HEADER_CLASSES}>{children}</div>;

FlyoutHeader.propTypes = {
  children: PropTypes.node,
};

FlyoutHeader.defaultProps = {
  children: null,
};

export default FlyoutHeader;
