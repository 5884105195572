import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { RecipientNumberControl, RecipientNumberOption } from "./CustomDropdownComponents";
import useRecipientNumbers from "../hooks/useRecipientNumbers";

const RecipientNumberDropdown = ({ onRemove, recipient }) => {
  const { actions, data, loading } = useRecipientNumbers({ recipient });
  return (
    <Dropdown
      components={{ Control: RecipientNumberControl, Option: RecipientNumberOption }}
      onRemove={onRemove}
      options={data.numbers || []}
      placeholder={`${recipient?.label}: ${data.number?.label || (loading ? "..." : "No Number")}`}
      isLoading={loading}
      isClearable={false}
      isOptionDisabled={(option) => option.meta?.smsStatus === "unsubscribed"}
      selectedNumber={data.number}
      styles={{
        dropdownIndicator: (base) => ({ ...base, padding: 0, color: "inherit" }),
        menu: (base) => ({ ...base, minWidth: "200px" }),
        placeholder: (base) => ({ ...base, color: "inherit" }),
        valueContainer: (base) => ({
          ...base,
          fontSize: "inherit",
          padding: 0,
          lineHeight: "inherit",
        }),
      }}
      onChange={actions.setNumber}
    />
  );
};

RecipientNumberDropdown.propTypes = {
  onRemove: PropTypes.func.isRequired,
  recipient: PropTypes.shape().isRequired,
};

export default RecipientNumberDropdown;
