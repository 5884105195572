import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import V1Alert from "@shared/v1/Alert";
import Button from "@shared/v2/Button";
import AiAssistant from "@shared/AiAssistant";
import Tooltip from "@shared/v2/Tooltip";
import IconButton from "@shared/v2/IconButton";
import Dropdown from "@shared/v2/Dropdown";
import TextInput from "@shared/v2/TextInput";
import Checkbox from "@shared/v2/Checkbox";
import useSubjectLineLimitError, { isWithinSubjectLineLimit } from "@shared/hooks/useSubjectLineLimitError";
import { PaperclipSolidV6, PlusSolidV6, XmarkSolidV6 } from "@icons";
import { produce } from "immer";
import person_detail_css from "./person-detail-styles.module.css";
import BrivityTextEditor from "../../shared/BrivityTextEditor";
import { updatePersonPromise } from "../actions/updatePerson";
import SearchInteraction from "./SearchInteraction";
import Alert from "@shared/v2/Alert";
import EmailError from "@shared/EmailError";
import Loading from "../../Goals/components/Loader";
import useEmailPlaceholders from "../actions/useEmailPlaceholders";

const PersonInteractionEmailForm = ({
  send_email_url,
  isTDP,
  current_user_id,
  gmailReplyThreadSubject,
  gmailReplyThreadId,
  additionalRecipientsVal,
  CCval,
  BCCval,
  person,
  updatePersonData,
  clearErrors = () => { },
  listingId = "",
  defaultHomeAppTabContent,
  email_templates,
  submitEmail,
  handleSubmitEmailError,
  listingUuid,
  closeModal,
  gmailUnsynced,
  sendEmailError,
  isEmailSent,
}) => {
  const { loading, emailPlaceholders } = useEmailPlaceholders({
    personUuid: person.data?.attributes?.uuid,
    listingUuid,
  });
  const [brivityEmailWrapper, setBrivityEmailWrapper] = useState({
    url: send_email_url,
    data: {
      interaction_template: "",
      message: {
        bcc: BCCval || [],
        cc: CCval || [],
        recipients: additionalRecipientsVal || [],
        content: "",
        interaction_template_id: "",
        listing_id: null,
        recipient_id: person.data.attributes.id,
        save_note: true,
        send_me_a_copy: 0,
        sender_id: current_user_id,
        subject: "",
        template: null,
        thread_id: null,
        user_submitted: true,
        attachment: [],
        handleclick: false,
      },
    },
  });
  const hiddenAttachmentInput = useRef(null);

  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [showRecipients, setShowRecipients] = useState(false);
  const [editablePerson, setEditablePerson] = useState(person);
  const [changedPrimaryEmail, setChangedPrimaryEmail] = useState(false);

  const {
    errorMsg,
    setErrorTrigger,
  } = useSubjectLineLimitError();

  useEffect(() => {
    if (gmailReplyThreadSubject) {
      handleSubjectChange(gmailReplyThreadSubject);
    }
  }, [gmailReplyThreadSubject]);

  useEffect(() => {
    if (gmailReplyThreadId) {
      setThreadId(gmailReplyThreadId);
    }
  }, [gmailReplyThreadId]);

  useEffect(() => {
    if (additionalRecipientsVal) {
      selectRecipients(additionalRecipientsVal);
      if (additionalRecipientsVal.length > 0) {
        showtoggleRecipients(true);
      }
    }
  }, [additionalRecipientsVal]);

  useEffect(() => {
    if (CCval) {
      selectCC(CCval);
      if (CCval.length > 0) {
        showtoggleCC(true);
      }
    }
  }, [CCval]);

  useEffect(() => {
    if (BCCval) {
      selectBCC(BCCval);
      if (BCCval.length > 0) {
        showtoggleBCC(true);
      }
    }
  }, [BCCval]);

  useEffect(() => {
    // Selecting a template with an ID of 0 populates the user's email signature
    if (!loading) selectTemplate(0);
  }, [loading]);

  useEffect(() => {
    if (person) setEditablePerson(person);
  }, [person]);

  useEffect(() => {
    if (editablePerson !== null && changedPrimaryEmail) {
      setChangedPrimaryEmail(false);
      updatePersonPromise(editablePerson).then((r) => {
        updatePersonData(r.data);
      });
    }
  }, [editablePerson.data.attributes.main_email]);

  useEffect(() => {
    if (brivityEmailWrapper.data.message.handleclick) {
      setBrivityEmailWrapper((prevState) =>
        produce(prevState, (draft) => {
          draft.data.message.handleclick = false;
        }),
      );
      submitSendEmail();
    }
  }, [brivityEmailWrapper.data.message.handleclick]);

  const changePrimaryEmail = (email_detail) => {
    setChangedPrimaryEmail(true);
    setEditablePerson((prevPerson) =>
      produce(prevPerson, (draftState) => {
        const newMainEmail = draftState.included.find(({ id }) => id === email_detail.id);
        draftState.included
          .filter((i) => i["type"] === "email_detail")
          .forEach((e) => {
            e["attributes"]["set_main_email"] = false;
          });
        newMainEmail["attributes"]["set_main_email"] = true;
        draftState.data.attributes.main_email = newMainEmail["attributes"]["value"];
      }),
    );
  };

  const handleSubjectChange = (value) => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.subject = value;
      }),
    );
  };
  const updateEmailBody = (value) => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.body = value;
      }),
    );
  };
  const setThreadId = (id) => {
    if (id !== 0) {
      setBrivityEmailWrapper((prevState) =>
        produce(prevState, (draft) => {
          draft.data.message.thread_id = id;
        }),
      );
    }
  };

  const selectTemplate = (id) => {
    clearErrors();

    if (id !== 0) {
      setBrivityEmailWrapper((prevState) =>
        produce(prevState, (draft) => {
          draft.data.interaction_template = id;
          draft.data.message.interaction_template_id = id;
        }),
      );
    }

    let formData = new FormData();
    formData.append("id", id);
    formData.append("listing_id", listingId);
    formData.append("recipient_id", brivityEmailWrapper.data.message.recipient_id);
    formData.append("sender_id", brivityEmailWrapper.data.message.sender_id);

    axios
      .post("/interaction_templates/parse.json", formData, {
        headers: {
          "X-CSRF-Token": window.ReactOnRails.authenticityToken(),
        },
      })
      .then((res) => {
        let emailBody = "";
        if (id !== 0) {
          handleSubjectChange(res.data.subject);
          emailBody = res.data.body;
        } else {
          // This prop is currently being set from the Home App card and is also used in PersonInteractionSmsForm. If we allow other areas of the app to set default email/text content
          // in the future, then we should rename this to be more generic. Keep in mind that we are currently updating
          // the person's home_app_invite_sent when this email is sent (called in submitEmail on line 295 below) so we will need to also include a prop
          // signifying where the default content is being set as we would not want make this update
          emailBody = defaultHomeAppTabContent || "";
        }

        updateEmailBody(emailBody + res.data.signature);
        tinymce.activeEditor.setContent(emailBody + res.data.signature);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showtoggleCC = (haveValue) => {
    if (!haveValue) {
      selectCC([]);
    }
    setShowCC(true);
  };
  const hidetoggleCC = () => {
    selectCC([]);
    setShowCC(false);
  };
  const showtoggleRecipients = (haveValue) => {
    if (!haveValue) {
      selectRecipients([]);
    }
    setShowRecipients(true);
  };
  const hidetoggleRecipients = () => {
    selectRecipients([]);
    setShowRecipients(false);
  };

  const showtoggleBCC = (haveValue) => {
    if (!haveValue) {
      selectBCC([]);
    }
    setShowBCC(true);
  };
  const hidetoggleBCC = () => {
    selectBCC([]);
    setShowBCC(false);
  };

  const selectRecipients = (recipientsVal) => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.recipients = recipientsVal;
      }),
    );
  };

  const selectCC = (ccVal) => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.cc = ccVal;
      }),
    );
  };

  const selectBCC = (bccVal) => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.bcc = bccVal;
      }),
    );
  };

  const toggleSendMeACopy = () => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.send_me_a_copy = prevState.data.message.send_me_a_copy === 0 ? 1 : 0;
      }),
    );
  };

  const handleAttachmentClick = () => {
    if (hiddenAttachmentInput.current) {
      hiddenAttachmentInput.current.click();
    }
  };

  const handleAttachmentUpload = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded === undefined) return;

    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.attachment = [...brivityEmailWrapper.data.message.attachment, fileUploaded];
      }),
    );
  };

  const deleteAttachment = (index) => {
    let attachments = [...brivityEmailWrapper.data.message.attachment];
    attachments.splice(index, 1);
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.attachment = attachments;
      }),
    );
  };
  const handleSendEmailClick = () => {
    setBrivityEmailWrapper((prevState) =>
      produce(prevState, (draft) => {
        draft.data.message.content = tinymce.activeEditor.getContent();
        draft.data.message.handleclick = true;
      }),
    );
  };

  const clearForm = () => {
    tinymce.activeEditor.setContent("");
    setBrivityEmailWrapper({
      url: send_email_url,
      data: {
        interaction_template: "",
        message: {
          bcc: BCCval || [],
          cc: CCval || [],
          recipients: additionalRecipientsVal || [],
          content: "",
          interaction_template_id: "",
          listing_id: null,
          recipient_id: person.data.attributes.id,
          save_note: true,
          send_me_a_copy: false,
          sender_id: current_user_id,
          subject: "",
          template: null,
          thread_id: null,
          user_submitted: true,
          attachment: [],
          handleclick: false,
        },
      },
    });
    setShowCC(false);
    setShowBCC(false);
    setShowRecipients(false);
    selectTemplate(0);

    clearErrors();
  };

  const mainEmailEmailDetail = () => {
    return editablePerson.included.filter(
      (d) => d.type === "email_detail" && d["attributes"]["position"] === 1,
    )[0];
  };

  const otherEmailDetails = () => {
    return editablePerson.included.filter(
      (d) => d.type === "email_detail" && d["attributes"]["position"] !== 1,
    );
  };

  const visibleoption = () => {
    let arr = email_templates.map((e) => ({
      value: e.id,
      label: e.name,
    }));
    return arr;
  };

  const submitSendEmail = () => {
    const INT_MSG = isTDP ? "interaction[message]" : "message";

    let formData = new FormData();
    formData.append("authenticity_token", window.ReactOnRails.authenticityToken());

    formData.append(`${INT_MSG}[recipient_id]`, brivityEmailWrapper.data.message.recipient_id);
    formData.append(`${INT_MSG}[save_note]`, true);
    brivityEmailWrapper.data.message.cc.forEach((cc) => formData.append(`${INT_MSG}[cc][]`, cc));
    brivityEmailWrapper.data.message.bcc.forEach((bcc) => formData.append(`${INT_MSG}[bcc][]`, bcc));
    brivityEmailWrapper.data.message.recipients.forEach((recipients) =>
      formData.append(`${INT_MSG}[other_recipients][]`, recipients),
    );
    brivityEmailWrapper.data.message.attachment.forEach((a) =>
      formData.append(`${INT_MSG}[attachments][]`, a),
    );
    formData.append(`${INT_MSG}[sender_id]`, brivityEmailWrapper.data.message.sender_id);
    formData.append(`${INT_MSG}[subject]`, brivityEmailWrapper.data.message.subject);
    formData.append(`${INT_MSG}[user_submitted]`, true);

    formData.append(`${INT_MSG}[body]`, brivityEmailWrapper.data.message.content);

    formData.append(
      `${INT_MSG}[interaction_template_id]`,
      brivityEmailWrapper.data.message.interaction_template_id,
    );
    formData.append(`${INT_MSG}[send_me_a_copy]`, brivityEmailWrapper.data.message.send_me_a_copy);

    if (isTDP) {
      formData.append("uuid", listingUuid);
      formData.append("interaction[person_id]", brivityEmailWrapper.data.message.sender_id);
      formData.append("interaction[interaction_template]", brivityEmailWrapper.data.interaction_template);
    } else {
      formData.append("interaction_template", brivityEmailWrapper.data.interaction_template);
      formData.append("person_details_page", "true");
    }

    axios
      .post(brivityEmailWrapper.url, formData)
      .then((res) => {
        if (isTDP) {
          submitEmail();
        } else {
          submitEmail(res.data, !!defaultHomeAppTabContent, brivityEmailWrapper.data.message.recipient_id);
        }

        clearForm();
      })
      .catch((err) => {
        handleSubmitEmailError(err);
      });
  };

  const sendEmailClick = () => {
    if (!isWithinSubjectLineLimit(brivityEmailWrapper.data.message.subject)) {
      return setErrorTrigger(true)
    }
    handleSendEmailClick()
  }

  const renderSuccessIcon = () => (
    <Row className="m-b-5">
      <div className="form-success m-t-20 m-b-20 p-b-20 p-t-20">
        <i className="fa fa-check-circle" style={{ color: "#59c4c4" }}></i>
      </div>
    </Row>
  );
  const emailStatus = (email_detail) => {
    if (email_detail["attributes"]["unsubscribed_message_types"].length > 0) {
      return (
        <div style={{ paddingTop: "2px" }}>
          <Tooltip
            placement="top"
            trigger={
              <img
                src="/assets/emails/unsubscribed-email.svg"
                className={person_detail_css.emailStatusIcon}
              />
            }
            content="Unsubscribed Email"
          />
        </div>
      );
    }
    switch (email_detail["attributes"]["is_verified"]) {
      case true:
        return (
          <div style={{ paddingTop: "2px" }}>
            <Tooltip
              placement="top"
              trigger={
                <img src="/assets/emails/valid-email.svg" className={person_detail_css.emailStatusIcon} />
              }
              content="Valid Email"
            />
          </div>
        );
      case false:
        return (
          <div style={{ paddingTop: "2px" }}>
            <Tooltip
              placement="top"
              trigger={
                <img src="/assets/emails/invalid-email.svg" className={person_detail_css.emailStatusIcon} />
              }
              content="Email may be invalid"
            />
          </div>
        );
      case null:
        return (
          <div style={{ paddingTop: "2px" }}>
            <Tooltip
              placement="top"
              trigger={
                <img
                  src="/assets/emails/unverified-email.svg"
                  className={person_detail_css.emailStatusIcon}
                />
              }
              content="Unverified Email"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const emailDropdownList = () => {
    return (
      <div className={`p-b-10 d-flex`} style={{ alignItems: "center" }}>
        {emailStatus(mainEmailEmailDetail())}
        <div
          className={`${person_detail_css.selectEmailDropdown} ${otherEmailDetails().length > 0 ? person_detail_css.cursorPointer : ""
            }`}
        >
          <span className="p-l-5 p-r-5">{mainEmailEmailDetail()["attributes"]["value"]}</span>
          {otherEmailDetails().length > 0 && (
            <span>
              <i className="fa fa-caret-down" />
              <div className={person_detail_css.selectEmailDropdownContent}>
                {otherEmailDetails().map((email) => {
                  return (
                    <div
                      className="d-flex p-b-10"
                      onClick={(e) => changePrimaryEmail(email)}
                      key={email.id}
                      style={{ alignItems: "center" }}
                    >
                      {emailStatus(email)}
                      <span className="p-l-5 p-r-5">{email["attributes"]["value"]}</span>
                    </div>
                  );
                })}
              </div>
            </span>
          )}
        </div>
      </div>
    );
  };

  const closeOrClearBtn = () => (
    <Button
      onClick={closeModal ? closeModal : clearForm}
      size="medium"
      schema={!isTDP ? "tertiary" : "secondary"}
    >
      {closeModal ? "Cancel" : "Clear"}
    </Button>
  );

  const renderEmailForm = () => {
    return (
      <div>
        {gmailUnsynced && (
          <V1Alert variant="danger" className="tw-flex tw-justify-between tw-items-center">
            <div>
              <span className="tw-text-14d tw-font-semibold">Gmail Unsync:</span> Looks like your Gmail has
              become unsynced. Emails may not send correctly. Please go re-sync it on your personal page.
            </div>
            <Button
              className="tw-whitespace-nowrap"
              schema="warning"
              onClick={() => window.location.assign("/account/personal")}
            >
              Re-Sync
            </Button>
          </V1Alert>
        )}
        <Row className="m-b-5 tw-flex tw-items-center">
          <Col xs={7}>{emailDropdownList()}</Col>
          <Col xs={5}>
            <div className="tw-flex tw-justify-end tw-gap-[4px]">
              <div className={showRecipients ? "d-none" : "d-block"}>
                <Button schema="secondary" onClick={showtoggleRecipients}>
                  <PlusSolidV6 size="s" />
                  &nbsp; RECIPIENT
                </Button>
              </div>
              <div className={showCC ? "d-none" : "d-block"}>
                <Button schema="secondary" onClick={showtoggleCC}>
                  <PlusSolidV6 size="s" />
                  &nbsp; CC
                </Button>
              </div>
              <div className={showBCC ? "d-none" : "d-block"}>
                <Button schema="secondary" onClick={showtoggleBCC}>
                  <PlusSolidV6 size="s" />
                  &nbsp; BCC
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={`form-group ${showRecipients ? "d-block" : "d-none"}`}>
          <Col xs={12}>
            <label className="tw-text-[#999999]	tw-font-semibold	tw-leading-5 tw-text-[14px]">
              Additional Recipients
            </label>

            <SearchInteraction
              tagsEmail={brivityEmailWrapper.data.message.recipients}
              onSelectFunction={selectRecipients}
              onHide={hidetoggleRecipients}
            />
          </Col>
        </Row>
        <Row className={`form-group ${showCC ? "d-block" : "d-none"}`}>
          <Col xs={12}>
            <label className="tw-text-[#999999]	tw-font-semibold	tw-leading-5 tw-text-[14px]">CC</label>
            <SearchInteraction
              tagsEmail={brivityEmailWrapper.data.message.cc}
              onSelectFunction={selectCC}
              onHide={hidetoggleCC}
            />
          </Col>
        </Row>
        <Row className={`form-group ${showBCC ? "d-block" : "d-none"}`}>
          <Col xs={12}>
            <label className="tw-text-[#999999]	tw-font-semibold	tw-leading-5 tw-text-[14px]">BCC</label>
            <SearchInteraction
              tagsEmail={brivityEmailWrapper.data.message.bcc}
              onSelectFunction={selectBCC}
              onHide={hidetoggleBCC}
            />
          </Col>
        </Row>
        <Row className="m-b-20">
          <Col xs={6}>
            <TextInput
              label="Subject"
              isRequired
              value={brivityEmailWrapper.data.message.subject}
              onChange={(e) => {
                if (isWithinSubjectLineLimit(e.target.value)) {
                  setErrorTrigger(false)
                }
                handleSubjectChange(e.target.value)
              }}
            />
          </Col>
          <Col xs={6}>
            <Dropdown
              label="Email Template"
              options={visibleoption()}
              value={
                visibleoption().find((o) => o.value === brivityEmailWrapper.data.interaction_template) || null
              }
              isSearchable
              name="type"
              onChange={(option) => selectTemplate(option.value)}
              isClearable={false}
              placeholder="Type to search"
              menuShouldComeToFront
            />
          </Col>
        </Row>
        <Row className="m-b-20">
          <Col xs={12}>
            {loading && <Loading />}
            {!loading && (
              <BrivityTextEditor
                id="person-email-interaction-form"
                value={brivityEmailWrapper.data.message.body}
                placeholders={emailPlaceholders}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="tw-flex tw-flex-wrap">
              {brivityEmailWrapper.data.message.attachment.map((e, index) => (
                <Row key={index}>
                  <Col xs={12}>
                    <span className="tw-font-bold tw-flex tw-items-center tw-gap-[8px]">
                      {e.name}{" "}
                      <IconButton size="small" schema="misc-trash" onClick={() => deleteAttachment(index)}>
                        <XmarkSolidV6 size="l" />
                      </IconButton>
                    </span>
                  </Col>
                </Row>
              ))}
            </div>
            <div className="tw-flex tw-gap-[4px]">
              <Button schema="secondary" onClick={handleAttachmentClick}>
                <PaperclipSolidV6 /> Add Attachment
              </Button>
              {!loading && (
                <AiAssistant
                  isTinyMce
                  messageType="Email"
                  align="start"
                  side="top"
                  contactUuid={person.data.attributes.uuid}
                  onInsertClick={(text) => {
                    tinyMCE.activeEditor.execCommand(
                      "mceInsertContent",
                      false,
                      (text || "").replace(/\r\n|\r|\n/g, "<br />"),
                    );
                  }}
                />
              )}
              <input
                type="file"
                ref={hiddenAttachmentInput}
                onChange={handleAttachmentUpload}
                className="!tw-hidden"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="tw-pt-[15px]">
            {errorMsg && <Alert title="Error" variant="error" text={errorMsg} />}
            {!sendEmailError?.isSupportError && sendEmailError?.errors && (
              <Alert title="Error" variant="error" text={sendEmailError.errors} />
            )}
            {sendEmailError?.isSupportError && <EmailError />}
          </Col>
        </Row>
        <Row className="tw-mt-[24px]">
          <Col xs={12}>
            <div className="tw-flex tw-gap-[8px] tw-justify-between tw-items-center">
              {closeOrClearBtn()}
              <div className="tw-flex tw-gap-[8px] tw-items-center">
                <Checkbox
                  label="Send me a copy"
                  checked={brivityEmailWrapper.data.message.send_me_a_copy === 1}
                  onClick={toggleSendMeACopy}
                />
                {mainEmailEmailDetail()["attributes"]["is_verified"] !== false && (
                  <Button onClick={sendEmailClick} size="medium">
                    Send
                  </Button>
                )}
                {mainEmailEmailDetail()["attributes"]["is_verified"] === false && (
                  <Tooltip
                    content="Email may be invalid"
                    placement="top"
                    trigger={
                      <Button onClick={sendEmailClick} size="medium">
                        Send
                      </Button>
                    }
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return <div>{isEmailSent ? renderSuccessIcon() : renderEmailForm()}</div>;
};

export default PersonInteractionEmailForm;
