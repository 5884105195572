import React from "react";
import { bool, func, string } from "prop-types";
import Checkbox from "../../../../shared/v2/Checkbox/Checkbox";
import { isReferral } from "../../../shared/helpers";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TYPE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

const AccessViewerCheckbox = ({ toggleViewerAccess, onChangeSetToggle, isDisabled }) => (
  <div>
    <Checkbox
      checked={toggleViewerAccess}
      label="Grant Viewer Login Access to Client Portal"
      onChange={() => onChangeSetToggle(!toggleViewerAccess)}
      className="tw-cursor-pointer"
      disabled={isDisabled}
    />

    <div className="tw-text-gray-50 tw-mb-[12px] tw-ml-[20px] tw-mt-[-2px] tw-text-sm tw-font-normal tw-leading-5 tw-tracking-normal tw-text-left tw-inline-block">
      Allow clients to access a limited view of this transaction.
    </div>
  </div>
);

const AccessViewer = ({
  type,
  isCollaborator,
  isClient,
  toggleViewerAccess,
  onChangeSetToggle,
  isDisabled,
}) => (
  <>
    {!isReferral(type) && (isCollaborator || isClient) && (
      <AccessViewerCheckbox
        toggleViewerAccess={toggleViewerAccess}
        onChangeSetToggle={onChangeSetToggle}
        isDisabled={isDisabled}
      />
    )}
  </>
);

AccessViewerCheckbox.propTypes = {
  toggleViewerAccess: bool,
  onChangeSetToggle: func,
  isDisabled: bool,
};

AccessViewerCheckbox.defaultProps = {
  toggleViewerAccess: false,
  isDisabled: false,
  onChangeSetToggle: () => {},
};
AccessViewer.propTypes = {
  isCollaborator: bool,
  isClient: bool,
  type: string,
  toggleViewerAccess: bool,
  onChangeSetToggle: func,
  isDisabled: bool,
};

AccessViewer.defaultProps = {
  isCollaborator: false,
  isClient: false,
  toggleViewerAccess: false,
  isDisabled: false,
  onChangeSetToggle: () => {},
  type: null,
};
const WrappedAccessViewer = tdpDetails({
  WrappedComponent: AccessViewer,
  states: TYPE,
});

export default WrappedAccessViewer;
