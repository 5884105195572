import React, { Component } from "react";
import SmsModal from "./SmsModal";
import Tooltip from "@shared/v2/Tooltip";
import IconButton from "@shared/v2/IconButton";
import { CommentSolidV6 } from "@shared/v2/Icomoon";

class SmsModalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsModalOpen: false,
      defaultHomeAppTabContent: "",
    };
  }

  componentDidMount() {
    window.addEventListener("homeAppSmsClick", this.homeAppClickListener, false);
  }

  componentWillUnmount() {
    window.removeEventListener("homeAppSmsClick", this.homeAppClickListener);
  }

  homeAppClickListener = (e) => {
    if (e.detail.personId === this.props.person.data.attributes.id) {
      this.setState({ defaultHomeAppTabContent: e.detail.text, smsModalOpen: true });
    }
  };

  openModal = () => {
    this.setState({ smsModalOpen: true });
  };

  closeModal = () => {
    this.state.onSuccess?.();
    this.setState({ smsModalOpen: false });
  };

  smsButton = () => {
    const { smsEnabled } = this.props;
    if (smsEnabled) {
      return (
        <Tooltip
          placement="right"
          trigger={
            <IconButton aria-label="sms" onClick={this.openModal}>
              <CommentSolidV6 />
            </IconButton>
          }
          content="Send Text"
        />
      );
    } else {
      return <i className="fa fa-commenting-o sms gray" />;
    }
  };

  render() {
    const { person, iconClassName, phone, messagingServiceUrl, gabbiAi } = this.props;
    const { smsModalOpen, defaultHomeAppTabContent } = this.state;

    return (
      <div>
        <div className={iconClassName}>{this.smsButton()}</div>
        {this.state.smsModalOpen && (
          <SmsModal
            gabbiAi={gabbiAi}
            messagingServiceUrl={messagingServiceUrl}
            personId={person.data.attributes.id}
            person={person}
            modalOpen={smsModalOpen}
            closeModal={this.closeModal}
            defaultHomeAppTabContent={defaultHomeAppTabContent}
            phone={phone}
          />
        )}
      </div>
    );
  }
}

export default SmsModalButton;
