import React, { forwardRef } from "react";

import { dynamicRow } from "../../Transactions/utils/dynamicRow";
import MlsListingCell from "./MlsListingCell";
import { formatPrice } from "./helpers";
import Checkbox from "../../Transactions/components/shared/Checkbox";
import Tooltip from "@shared/v2/Tooltip";

const MlsListingRow = (
  { onShowDetails, onShowAgentDetails, agentDetailsEnabled, listing, columns, isFirstRow, selected, highlighted, onToggleShare, maxListingsSelected, buyerMatches = {} },
  ref,
) => {
  const bottomBorder = `tw-border-solid tw-border-gray-10 tw-border-b tw-border-r-0 tw-border-l-0 ${
    isFirstRow ? "tw-border-t" : "tw-border-t-0"
  }`;
  const rightBorder = `tw-border-solid tw-border-gray-10 tw-border-b tw-border-r tw-border-l-0 tw-border-t-0 ${
    isFirstRow ? "tw-border-t" : "tw-border-t-0"
  }`;

  const fields = dynamicRow(columns, [
    [
      "checkbox",
      <td
        ref={ref}
        key="checkbox"
        className={`tw-pl-20px tw-sticky tw-left-0 tw-z-10 ${
          selected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"
        } tw-p-0 tw-whitespace-nowrap ${bottomBorder}`}
      >
        <div className="tw-flex tw-flex-row tw-justify-start tw-items-center">
          <Checkbox
            name="selected"
            border={"tw-outline-none"}
            checked={selected}
            disabled={maxListingsSelected && !selected}
            onChange={() => onToggleShare(listing)}
          />
        </div>
      </td>,
    ],
    [
      "address",
      <td
        key="address"
        className={`tw-sticky tw-left-[36px] tw-cursor-pointer tw-z-10 ${
          selected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"
        } tw-px-20px tw-whitespace-nowrap ${rightBorder}`}
        onClick={() => onShowDetails(listing.blossorId)}
      >
        <div className="tw-flex">
          <img src={listing.photos[0].url} height={30} width={50} className="tw-self-center tw-mr-12px" />
          <div className="tw-block tw-text-left">
            <p className="tw-text-gray-75 tw-m-0">{listing.streetAddress}</p>
            <p className="tw-text-gray-75 tw-m-0">{`${listing.city}, ${listing.state} ${listing.postalCode}`}</p>
          </div>
        </div>
      </td>,
    ],
    [
      "buyerMatches",
      <BuyerMatchCell key="buyerMatches" blossorId={listing.blossorId} buyerMatches={buyerMatches} bottomBorder={bottomBorder} />
    ],
    ["mlsNum", <MlsListingCell key="mlsNum" isFirstRow={isFirstRow}>{listing.mlsNum}</MlsListingCell>],
    [
      "status",
      <td
        key="status"
        className={`tw-relative tw-px-20px tw-whitespace-nowrap ${getStatusColor(
          listing.standardStatus,
        )} ${bottomBorder}`}
      >
        {listing.standardStatus === "Active Under Contract" ? "Contingent" : listing.standardStatus}
      </td>,
    ],
    [
      "price",
      <MlsListingCell key="price" textAlign={"text-right"} isFirstRow={isFirstRow}>
        {formatPrice(listing.price)}
      </MlsListingCell>,
    ],
    [
      "listDate",
      <MlsListingCell key="listDate" isFirstRow={isFirstRow}>{listing.formattedListDate}</MlsListingCell>,
    ],
    ["city", <MlsListingCell key="city" isFirstRow={isFirstRow}>{listing.city}</MlsListingCell>],
    ["neighborhood", <MlsListingCell key="neighborhood" isFirstRow={isFirstRow}>{listing.neighborhood}</MlsListingCell>],
    ["beds", <MlsListingCell key="beds" isFirstRow={isFirstRow}>{listing.bedrooms}</MlsListingCell>],
    [
      "totalBaths",
      <MlsListingCell key="totalBaths" isFirstRow={isFirstRow}>{listing.bathsTotalDecimal || "—"}</MlsListingCell>,
    ],
    [
      "sqft",
      <MlsListingCell key="sqft" textAlign={"text-right"} isFirstRow={isFirstRow}>
        {listing.squareFeet?.toLocaleString() || "—"}
      </MlsListingCell>,
    ],
    [
      "ppsf",
      <MlsListingCell key="ppsf" textAlign={"text-right"} isFirstRow={isFirstRow}>
        {(listing.squareFeet > 0 && `$${Math.round(listing.price / listing.squareFeet).toLocaleString()}`) ||
          "—"}
      </MlsListingCell>,
    ],
    ["lotSize", <MlsListingCell key="lotSize" isFirstRow={isFirstRow}>{listing.lotSize}</MlsListingCell>],
    ["yearBuilt", <MlsListingCell key="yearBuilt" isFirstRow={isFirstRow}>{listing.year}</MlsListingCell>],
    ["propertyType", <MlsListingCell key="propertyType" isFirstRow={isFirstRow}>{listing.propertyType}</MlsListingCell>],
    [
      "hoaFee",
      <MlsListingCell key="hoaFee" textAlign={"text-right"} isFirstRow={isFirstRow}>
        {formatPrice(listing.hoaFee) || "—"}
      </MlsListingCell>,
    ],
    [
      "listedBy",
      <td
        key="listedBy"
        className={`tw-relative tw-px-20px tw-whitespace-nowrap ${
          selected || highlighted ? "tw-bg-tinted-gray-50" : "tw-bg-white"
        } ${bottomBorder}`}
      >
        <p
          onClick={() => onShowAgentDetails(listing)}
          className={`tw-mb-4px ${agentDetailsEnabled ? "tw-text-teal hover:tw-underline active:tw-text-brivity-blue-dark active:tw-underline tw-cursor-pointer" : "tw-text-gray-75"}`}>
            {listing.listAgentName}
        </p>
        <p className="tw-text-gray-50 tw-text-12px tw-m-0">{listing.listOfficeName || "—"}</p>
      </td>,
    ],
  ]);

  return (
    <tr
      className={`tw-h-72px tw-text-gray-75 ${selected || highlighted ? "tw-bg-tinted-gray-50" : ""}`}
    >
      {fields}
    </tr>
  );
};

export default forwardRef(MlsListingRow);

const getStatusColor = (standardStatus) => {
  if (standardStatus === "Pending") {
    return "tw-text-semantic-yellow-100";
  }

  if (standardStatus === "Active Under Contract") {
    return "tw-text-semantic-orange-100";
  }

  return "tw-text-semantic-green-100";
};

const BuyerMatchCell = ({bottomBorder, blossorId, buyerMatches: {matches, linkToIndex, count, countText} = {}}) => {
  const tooltipText = generateNameTooltip(matches, count);

  const matchText = count === 1 ? "Match" : "Matches";
  const hoverStyle = "hover:tw-rounded hover:tw-border hover:tw-border-solid hover:tw-border-brivity-blue-40 hover:tw-no-underline";
  const activeStyle = "active:tw-rounded active:tw-border active:tw-border-solid active:tw-border-brivity-blue-100 active:tw-no-underline";

  return (
    <td className={`buyer-match tw-relative tw-px-20px tw-whitespace-nowrap ${bottomBorder}`}>
      {matches?.length ? (
        <div className="tw-flex tw-flex-row tw-justify-center">
            <Tooltip
              placement="right"
              innerClassName="tw-max-w-[200px]"
              multiline
              trigger={
                <a className={`tw-flex tw-flex-col tw-items-center tw-p-8px ${hoverStyle} ${activeStyle}`} href={linkToIndex} target="_blank">
                  <p className="tw-text-teal tw-text-14px tw-font-bold tw-m-0">{countText}</p>
                  <p className="tw-text-gray-50 tw-text-12px tw-font-semibold tw-m-0">{matchText}</p>
                </a>
              }
              content={<span>{tooltipText}</span>}
            />
        </div>) : (
        <div className="tw-flex tw-flex-col tw-items-center">
          <span className="tw-text-12px tw-font-semibold tw-text-gray-50">—</span>
        </div>
      )}
    </td>
  );
};

// count is the total, matches only includes a set amount as we just don't use that much data on the front end
const generateNameTooltip = (matches, count) => {
  if (!matches?.length) {
    return;
  }

  const MaxNames = 5;
  const names = matches.slice(0, MaxNames).map(m => m.fullName).join(", ");

  return matches.length > MaxNames ? `${names},...+${count - MaxNames} More`: names;
};
