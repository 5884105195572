import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const openLeadIndexFromTagName = (tagName) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  caseTransformingAxios.post("/api/v4/tags/tag_by_name", { tagName }).then(({ data }) => {
    const urlParams = new URLSearchParams();
    urlParams.append("leads_filter[selected_any_all_tags][]", "any");
    urlParams.append("leads_filter[selected_tag_filters][]", data.id);
    window.open(`/people/leads?${urlParams}`, true);
  });
};

export default openLeadIndexFromTagName;
