import React from "react";
import Proptypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import { useOnMinMaxSelectedChange, useMinMaxState } from "../../hookHelpers";
import { hasMinMaxError } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";

const BASE_OPTIONS = [
  {
    label: "500 sqft",
    value: "500",
  },
  {
    label: "1,000 sqft",
    value: "1000",
  },
  {
    label: "1,500 sqft",
    value: "1500",
  },
  {
    label: "2,000 sqft",
    value: "2000",
  },
  {
    label: "2,500 sqft",
    value: "2500",
  },
  {
    label: "3,000 sqft",
    value: "3000",
  },
  {
    label: "3,500 sqft",
    value: "3500",
  },
  {
    label: "4,000 sqft",
    value: "4000",
  },
  {
    label: "4,500 sqft",
    value: "4500",
  },
  {
    label: "5,000 sqft",
    value: "5000",
  },
  {
    label: "5,500 sqft",
    value: "5500",
  },
  {
    label: "6,000 sqft",
    value: "6000",
  },
  {
    label: "6,500 sqft",
    value: "6500",
  },
  {
    label: "7,000 sqft",
    value: "7000",
  },
  {
    label: "7,500 sqft",
    value: "7500",
  },
  {
    label: "8,000 sqft",
    value: "8000",
  },
  {
    label: "8,500 sqft",
    value: "8500",
  },
  {
    label: "9,000 sqft",
    value: "9000",
  },
  {
    label: "9,500 sqft",
    value: "9500",
  },
  {
    label: "10,000 sqft",
    value: "10000",
  },
];
const minOptions = [{ label: "No min", value: null }, ...BASE_OPTIONS];
const maxOptions = [{ label: "No max", value: null }, ...BASE_OPTIONS];
const SqFeet = ({ min, max, onChange }) => {
  const [selectedMin, onSelectMin, selectedMax, onSelectMax] = useMinMaxState(min, max);
  const hasError = hasMinMaxError(selectedMin, selectedMax);
  const debouncedMin = useDebounce(selectedMin, 500);
  const debouncedMax = useDebounce(selectedMax, 500);
  useOnMinMaxSelectedChange(debouncedMin, debouncedMax, hasError, onChange);

  return (
    <div data-cy="sqfeet-wrapper" className="tw-mb-[24px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Sq Feet</span>
      <div className="tw-flex tw-gap-[24px]">
        <Dropdown
          containerClassName="tw-flex-1"
          name="min_area"
          value={minOptions.find((o) => o.value === selectedMin) || null}
          options={minOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="No Min"
          onChange={(opt) => onSelectMin(opt.value)}
        />
        <Dropdown
          containerClassName="tw-flex-1"
          error={hasError ? "Max must be greater than min" : ""}
          name="max_area"
          value={maxOptions.find((o) => o.value === selectedMax) || null}
          options={maxOptions}
          isSearchable={false}
          isClearable={false}
          placeholder="No Max"
          onChange={(opt) => onSelectMax(opt.value)}
        />
      </div>
    </div>
  );
};

export default SqFeet;

SqFeet.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  onChange: Proptypes.func,
};

SqFeet.defaultProps = {
  min: undefined,
  max: undefined,
  onChange: () => {},
};
