import React from "react";
import { bool, oneOf } from "prop-types";
import { ArrowTurnUpSolidV6, CommentDotsRegularV6, EnvelopeRegularV6, ListUlSolidV6 } from "@shared/v2/Icomoon";


const TYPE_ICON_MAP = {
  Task: ListUlSolidV6,
  Email: EnvelopeRegularV6,
  Text: CommentDotsRegularV6,
}


const Icon = ({type, contingent}) => {
  const TypeIcon = TYPE_ICON_MAP[type];

  return (
    <div className={`${contingent ? 'tw-bg-[#DEF3F3] tw-text-teal': 'tw-bg-teal tw-text-white'} tw-w-[48px] tw-h-[48px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-relative tw-flex-none`}>
      {contingent && 
        <div className="tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-white tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0">
          <ArrowTurnUpSolidV6 size='s'/>
        </div>
      }      
      <TypeIcon
      size='l'/>
    </div>
  )
};

export default Icon;

Icon.propTypes = {
  type: oneOf(['Task', 'Email', 'Text']).isRequired,
  contingent: bool,
}

Icon.defaultProps = {
  contingent: false
}