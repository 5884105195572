import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import FieldError from "@shared/v2/FieldError";
import FieldLabelV1 from "./FieldLabelV1";
import styleSchema from "./FieldStyle";
import { CaretUp, CaretDown } from "../v2/Icomoon";
import IconButton from "../v2/IconButton";
const schemaBasedClasses = (schemaName) => styleSchema[schemaName] || styleSchema.default;

function FieldSelect({
  placeholder,
  options,
  value,
  onChange,
  className,
  disabled,
  label,
  required,
  flexStyle,
  defaultValue,
  toolTipPlacement,
  toolTipInfoContent,
  isInputSearchable,
  isDropdownSearchable,
  dropdownWidth,
  error,
  isAddNotFound,
  fieldSelect,
  ...otherProps
}) {
  let schemaKey = disabled ? "disabled" : "regular";
  schemaKey = error ? "error" : schemaKey;
  const classes = `${schemaBasedClasses(schemaKey)} ${className}`;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [filteredSearchOption, setFilteredSearchOption] = useState(options);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false);

        if (isDropdownSearchable) {
          setSearchText("");
        }

        if (isInputSearchable)
          setSearchText(() => {
            const targetValue = options.find((option) => option.value === value);
            return targetValue ? targetValue.label : "";
          });
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isInputSearchable)
      setSearchText(() => {
        const targetValue = options.find((option) => option.value === value);
        return targetValue ? targetValue.label : "";
      });
  }, [value]);

  useEffect(() => {
    if (defaultValue && !value) {
      onChange(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setFilteredSearchOption(() => {
        const optionsFilteredWithInput = options.filter((option) =>
          `${option.label}${option.description ? ` (${option.description})` : ""}`
            .toLowerCase()
            .includes(searchText.toLowerCase()),
        );
        return optionsFilteredWithInput;
      });
    } else {
      setFilteredSearchOption(options);
    }
  }, [searchText, options]);

  return (
    <div className={`${flexStyle}`} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required, toolTipPlacement, toolTipInfoContent }} />}
      <div className={`tw-flex tw-flex-col tw-text-sm tw-items-center tw-relative ${className}`} ref={ref}>
        {isInputSearchable && (
          <input
            type="text"
            className={classes}
            value={searchText}
            placeholder={placeholder}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onFocus={() => {
              setIsDropdownOpen(true);
            }}
            {...otherProps}
          />
        )}

        {!isInputSearchable && (
          <input
            type="button"
            value={
              !!value && options.find((option) => option.value === value)
                ? options.find((option) => option.value === value)?.label
                : placeholder
            }
            className={`${classes} ${!value ? "tw-text-neutral-gray-50" : ""}`}
            onClick={() => {
              setIsDropdownOpen((prev) => !prev);
            }}
            disabled={disabled}
            {...otherProps}
            aria-hidden
          />
        )}
        {error && <FieldError className="tw-mt-4px tw-mr-auto" error={error} />}
        <div
          className={`${
            disabled ? "tw-text-neutral-gray-15" : "tw-text-neutral-gray-75"
          } tw-absolute tw-right-[12px] tw-top-[9px]`}
        >
          {isDropdownOpen ? <CaretUp /> : <CaretDown />}
        </div>

        {isDropdownOpen && (
          <div
            className={`dropdown-content tw-border tw-border-solid tw-rounded tw-text-sm tw-whitespace-nowrap tw-absolute tw-z-10 tw-bg-white tw-min-w-full tw-max-h-[256px] tw-top-[36px] tw-overflow-auto tw-w-fit tw-pb-[4px] ${dropdownWidth}`}
          >
            {isDropdownSearchable && (
              <div className="tw-p-[8px]">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search"
                  className={`${classes} tw-h-[24px]`}
                  data-cy={`${label}_search_input`}
                  disabled={disabled}
                />
              </div>
            )}
            {filteredSearchOption.length <= 0 ? (
              isAddNotFound ? (
                <div className="tw-flex tw-justify-between tw-items-center tw-px-[14px] tw-pt-[4px] tw-gap-[8px]">
                  <div>Create tag "{searchText}"</div>
                  <IconButton
                    schema="secondary"
                    size="small"
                    onClick={() => {
                      onChange(searchText);
                      setSearchText("");
                      setIsDropdownOpen(false);
                    }}
                  >
                    <i className="fa fa-plus tw-text-11d" />
                  </IconButton>
                </div>
              ) : (
                <div className="tw-px-[14px] tw-py-1">no matches</div>
              )
            ) : (
              filteredSearchOption.map((option) => {
                return (
                  <div
                    key={option.value}
                    className={`dropdown-item hover:tw-bg-tinted-gray-50 tw-px-[14px] tw-py-1 ${
                      option.header ? "tw-pointer-events-none tw-bg-tinted-gray-50" : ""
                    }`}
                    onClick={() => {
                      if (option.value === value && isInputSearchable) {
                        setSearchText(option.label);
                      } else {
                        onChange(fieldSelect ? option : option.value);
                        if (isDropdownSearchable) setSearchText("");
                      }
                      setIsDropdownOpen(false);
                    }}
                    data-cy={`option_${option.label}`}
                  >
                    {`${option.label}${option.description ? ` (${option.description})` : ""}`}
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
}

FieldSelect.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      header: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  flexStyle: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isInputSearchable: PropTypes.bool,
  isDropdownSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dropdownWidth: PropTypes.string,
  error: PropTypes.string,
};

FieldSelect.defaultProps = {
  placeholder: "Select",
  label: "",
  options: [
    { label: "Option1", value: "Option1" },
    { label: "Option2", value: "Option2" },
  ],
  value: "",
  required: false,
  flexStyle: "tw-flex-1",
  className: "",
  disabled: false,
  isInputSearchable: false,
  isDropdownSearchable: false,
  dropdownWidth: "",
  error: "",
};

export default FieldSelect;
