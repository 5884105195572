import React, { useState } from "react";
import TextInput from "../v2/TextInput/TextInput";
import { Search } from "../v2/Icomoon";

function FieldSearchText({
  className = "",
  placeholder = "",
  onChange,
  value = "",
  flexStyle = "tw-flex-1",
  disabled = false,
}) {
  const [isOnFocus, setIsOnFocus] = useState(false);

  return (
    <div className={`tw-relative ${flexStyle}`}>
      <TextInput
        placeholder={placeholder}
        disabled={disabled}
        value={value || ""}
        onFocus={() => setIsOnFocus(true)}
        onBlur={() => setIsOnFocus(false)}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className={className}
      />

      <Search
        className={`tw-absolute tw-right-[12px] tw-top-[10px] ${
          isOnFocus ? "tw-text-theme-text-button-color" : "tw-text-neutral-gray-30"
        }`}
        size="l"
      />
    </div>
  );
}

export default FieldSearchText;
