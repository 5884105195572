import React from "react";
import Proptypes from "prop-types";
import { Modal, Col, Row, Alert } from "react-bootstrap";
import _ from "lodash";
import buttons from "../../PersonDetail/components/button.module.css";
import HeaderTitle from "../../PersonDetail/components/modals/HeaderTitle";
import RadioButton from "./RadioButton";

const ManageAccessModal = ({
  people,
  admins,
  agents,
  modalOpen,
  closeModal,
  radioGroupHandler,
  handleSubmit,
  submitResponse,
  saving,
}) => (
  <div>
    <Modal className="modal" backdrop="static" show={modalOpen} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <HeaderTitle
            title={`Manage Access to ${people.length > 1 ? `${people.length} People` : "Person"}`}
            onClose={closeModal}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submitResponse?.errors && (
          <Alert bsStyle="danger">
            {_.flatMap(submitResponse.errors, (field, key) => (
              <span>
                <strong>Error: </strong> {`${key} ${field[0]}`}
                <br />
              </span>
            ))}
          </Alert>
        )}
        {submitResponse?.content && (
          <Alert bsStyle="danger">
            <span>
              <strong>Error: </strong> {submitResponse.content}
              <br />
            </span>
          </Alert>
        )}
        <Row>
          <Col xs={12}>
            <p>
              Extending access gives a user the ability to view and edit a person&#39;s information. Access
              can be revoked only for teammates with the user type of agent. The account owner and admins have
              access to everyone.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p>You can only manage access for up to 50 people at a time.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={2}>
                <label htmlFor="Primary">Primary</label>
              </Col>
              <Col xs={5}>
                <label htmlFor="Name">Name</label>
              </Col>
              <Col xs={5}>
                <label htmlFor="Access">Access</label>
              </Col>
            </Row>
          </Col>
        </Row>
        {admins.map((admin) => (
          <Row key={admin.person_id} className="agent">
            <Col xs={2} className="primary-agent-container text-center">
              <RadioButton
                name={admin.person_id}
                isAdmin
                isPond={false}
                selected={admin.is_selected}
                radioGroupHandler={radioGroupHandler}
              />
            </Col>
            <Col xs={5}>
              <p>{admin.name}</p>
            </Col>
            <Col xs={5} className="access-level">
              <span className="tw-text-[#969696] tw-text-[0.9em]">Admin access</span>
            </Col>
          </Row>
        ))}
        <hr />
        {agents.map((agent) => (
          <Row key={agent.id || agent.person_id} className="agent">
            <Col xs={2} className="primary-agent-container text-center">
              <RadioButton
                name={agent.id || agent.person_id}
                isAdmin={false}
                isPond={agent?.id && true}
                selected={agent.is_selected}
                radioGroupHandler={radioGroupHandler}
              />
            </Col>
            <Col xs={5}>
              <p>{agent.name}</p>
            </Col>
            <Col xs={5} className="tw-p-[0px]">
              {agent?.person_id && (
                <Row>
                  <Col xs={3} className="tw-p-[0px]">
                    <RadioButton
                      name={agent.person_id}
                      isAdmin={false}
                      isPond={false}
                      label="None"
                      selected={agent.current_access_level === "none"}
                      radioGroupHandler={radioGroupHandler}
                    />
                  </Col>
                  {(agent.current_access_level === "some" || agent.issome) && (
                    <Col xs={5} className="tw-p-[0px] tw-pl-[8px]">
                      <RadioButton
                        name={agent.person_id}
                        isPond={false}
                        isAdmin={false}
                        label={`Some (${agent.some_access_count})`}
                        selected={agent.current_access_level === "some"}
                        radioGroupHandler={radioGroupHandler}
                      />
                    </Col>
                  )}
                  <Col xs={3} className="tw-p-[0px] tw-pl-[8px]">
                    <RadioButton
                      name={agent.person_id}
                      isAdmin={false}
                      isPond={false}
                      label="All"
                      selected={agent.current_access_level === "all"}
                      radioGroupHandler={radioGroupHandler}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        ))}
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-justify-space-between">
              <button
                type="button"
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} m-r-5`}
                onClick={() => closeModal()}
              >
                Close
              </button>
              <button
                className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} m-l-5`}
                disabled={saving}
                type="button"
                onClick={() => handleSubmit()}
              >
                {saving && (
                  <span>
                    Save &nbsp;
                    <i className="fa fa-pulse fa-spinner" />
                  </span>
                )}
                {!saving && <span>Save</span>}
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  </div>
);

ManageAccessModal.propTypes = {
  people: Proptypes.arrayOf(
    Proptypes.shape({
      person_id: Proptypes.number,
    }),
  ),
  admins: Proptypes.arrayOf(
    Proptypes.shape({
      person_id: Proptypes.number,
    }),
  ),
  agents: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
    }),
  ),
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  radioGroupHandler: Proptypes.func,
  handleSubmit: Proptypes.func,
  submitResponse: Proptypes.shape({ content: Proptypes.string, errors: Proptypes.arrayOf(Proptypes.string) }),
  saving: Proptypes.bool,
};
ManageAccessModal.defaultProps = {
  people: null,
  admins: null,
  agents: null,
  modalOpen: false,
  closeModal: null,
  radioGroupHandler: null,
  handleSubmit: null,
  submitResponse: null,
  saving: false,
};

export default ManageAccessModal;
