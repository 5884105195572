import { produce } from "immer";
import { findIndex, remove } from "lodash";
import {
  saveAllTasksAndFilters,
  filterTasksByTeamMembers,
  filterTasksByAutoPlans,
  createTasksByTeamMemberFilter,
  createTasksByAutoPlansFilter,
  clearTeamMemberFilter,
  clearAutoPlanFilter,
  setBackFilterByTeamMembers,
  setBackFilterByAutoPlans,
  clearSecondaryFilter,
  createTasksByUnassignedFilter,
  togglePrimaryFilter,
  saveTeam,
  addCreatedTasks,
  deleteTask,
  editTasks,
  taskSelect,
  toggleModalAddTaskOpen,
  toggleTasksSelectionMode,
  selectAllTasks,
  clearSelectedTasks,
  setTaskModalBulkDelete,
  setModalBulkRescheduleTaskOpen,
  setModalBulkReassignTaskOpen,
  clearTasksAndFilters,
} from "../../TransactionDetailsPage/Main/Tasks/actions/creators";
import { DEFAULT_FILTERS } from "../../TransactionDetailsPage/Main/Tasks/constants";
import { createFilters } from "../../TransactionDetailsPage/Main/Tasks/api/helpers";
import { sortTasks, resetFilters } from "../../TransactionDetailsPage/Main/Tasks/actions/helpers";
import { updateDueOn } from "./helpers";
import EXCLUSIVE_PRIMARY_FILTERS from "./constants";
import { updateDates } from "../../TransactionDetailsPage/SideRight/Dates/actions/creators";

const filterObj = {
  count: 0,
  tasks: [],
};

export const defaultState = {
  tasks: [],
  uuid: "",
  activeTasks: [],
  primaryActiveList: [],
  filtered: false,
  assignableAgents: [],
  primaryFilters: {
    byName: {
      overdue: {
        ...filterObj,
      },
      due_today: {
        ...filterObj,
      },
      upcoming: {
        ...filterObj,
      },
      contingent: {
        ...filterObj,
      },
      completed_at: {
        ...filterObj,
      },
    },
  },
  secondaryFilters: {
    byTeamMember: {},
    byAutoPlans: {},
    byUnAssigned: {
      ...filterObj,
    },
    activeTeamMembers: [],
    activeUnassigned: false,
    savedStateTeamMembers: [],
    activeAutoPlans: [],
    savedStateAutoPlans: [],
  },
  byContingentId: {},
  isModalAddTaskOpen: false,
  isModalBulkDeleteOpen: false,
  isTasksSelectionMode: false,
  selectedTasks: [],
  isSelectedAllTaskChecked: false,
  isModalRescheduleTaskOpen: false,
  isModalReassignTaskOpen: false,
};

export const tdpTasksReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case updateDates().type:
      const {id: contingentId, date: eventDate} = payload.tasksPayload;
      const updatableTaskId = state.byContingentId?.[contingentId]?.tasks;

      return produce(state, (draft) => {
        if(updatableTaskId){
          updatableTaskId.forEach((taskId) => {
            const task = draft.tasks[taskId];
            const updatedDueOn = updateDueOn(task.contingent, eventDate);
            task.due_on = updatedDueOn;

            // Since there's 2 sources of truth, activeTasks and tasks, we need to modify both sources. This probably needs a rework to have one source(pref tasks);
            const activeTaskIdx = state.activeTasks.findIndex((tsk) => task.id === tsk.id);
            if(activeTaskIdx > -1) {
              draft.activeTasks[activeTaskIdx].due_on = updatedDueOn;
            }
          })
        }
      });
    case saveAllTasksAndFilters().type:
      const { adaptedTasks, adaptedFilters } = payload.data;
      return produce(state, (draft) => {
        draft.tasks = adaptedTasks;
        draft.primaryFilters = adaptedFilters.primaryFilters;
        draft.secondaryFilters = {
          ...state.secondaryFilters,
          ...adaptedFilters.secondaryFilters,
        };
        draft.byContingentId = adaptedFilters.byContingentId;
      });
    case togglePrimaryFilter().type: {
      return produce(state, (draft) => {
        let pFilters = [...state.primaryActiveList];

        if (!payload.primaryFilter) {
          null;
        } else if (!EXCLUSIVE_PRIMARY_FILTERS.includes(payload.primaryFilter)) {
          pFilters = pFilters
            .filter((filter) => filter !== "contingent")
            .filter((filter) => filter !== "completed_at");
          pFilters = pFilters.includes(payload.primaryFilter)
            ? pFilters.filter((el) => el !== payload.primaryFilter)
            : pFilters.concat([payload.primaryFilter]);
        } else {
          pFilters = pFilters[0] === payload.primaryFilter ? [] : [payload.primaryFilter];
        }

        draft.primaryActiveList = pFilters;

        draft.activeTasks = pFilters.length
          ? pFilters
              .map((filter) => state.primaryFilters.byName[filter].tasks)
              .flat()
              .sort((a, b) => a - b)
              .map((taskIdx) => state.tasks[taskIdx])
          : [];

        if (pFilters.length) {
          draft.secondaryFilters.activeTeamMembers = [];
          draft.secondaryFilters.activeUnassigned = false;
          draft.secondaryFilters.savedStateTeamMembers = [];
          draft.secondaryFilters.activeAutoPlans = [];
          draft.secondaryFilters.savedStateAutoPlans = [];
          draft.filtered = false;
        }
      });
    }
    case filterTasksByTeamMembers().type: {
      return produce(state, (draft) => {
        const teamMemberFilters = [...state.secondaryFilters.activeTeamMembers];

        teamMemberFilters.includes(payload.member)
          ? teamMemberFilters.splice(teamMemberFilters.indexOf(payload.member), 1)
          : teamMemberFilters.push(payload.member);

        draft.secondaryFilters.activeTeamMembers = teamMemberFilters;
      });
    }
    case filterTasksByAutoPlans().type: {
      return produce(state, (draft) => {
        const autoPlanFilters = [...state.secondaryFilters.activeAutoPlans];

        autoPlanFilters.includes(payload.autoPlan)
          ? autoPlanFilters.splice(autoPlanFilters.indexOf(payload.autoPlan), 1)
          : autoPlanFilters.push(payload.autoPlan);

        draft.secondaryFilters.activeAutoPlans = autoPlanFilters;
      });
    }
    case setBackFilterByTeamMembers().type: {
      return produce(state, (draft) => {
        draft.secondaryFilters.activeTeamMembers = state.secondaryFilters.savedStateTeamMembers;
      });
    }
    case setBackFilterByAutoPlans().type: {
      return produce(state, (draft) => {
        draft.secondaryFilters.activeAutoPlans = state.secondaryFilters.savedStateAutoPlans;
      });
    }
    case createTasksByTeamMemberFilter().type: {
      return produce(state, (draft) => {
        draft.primaryActiveList = [];
        draft.secondaryFilters.activeUnassigned = false;
        draft.secondaryFilters.activeAutoPlans = [];
        draft.selectedTasks = [];
        draft.isSelectedAllTaskChecked = false;
        const teamMembersKeys = Object.keys(state.secondaryFilters.byTeamMember);
        const activeTeamMembers = state.secondaryFilters.activeTeamMembers.filter((id) =>
          teamMembersKeys.includes(id),
        );

        draft.secondaryFilters.activeTeamMembers = activeTeamMembers;
        draft.filtered = !!activeTeamMembers.length;
        draft.secondaryFilters.savedStateTeamMembers = activeTeamMembers;

        draft.activeTasks = !activeTeamMembers.length
          ? []
          : activeTeamMembers
              .map((teamMember) => state.secondaryFilters.byTeamMember[teamMember].tasks)
              .flat()
              .sort((a, b) => a - b)
              .map((taskIdx) => state.tasks[taskIdx]);
      });
    }
    case createTasksByAutoPlansFilter().type: {
      return produce(state, (draft) => {
        draft.primaryActiveList = [];
        draft.secondaryFilters.activeUnassigned = false;
        draft.secondaryFilters.activeTeamMembers = [];
        draft.selectedTasks = [];
        draft.isSelectedAllTaskChecked = false;
        draft.secondaryFilters.savedStateAutoPlans = state.secondaryFilters.activeAutoPlans;
        draft.filtered = !!state.secondaryFilters.activeAutoPlans.length;
        draft.activeTasks = !state.secondaryFilters.activeAutoPlans.length
          ? state.tasks
          : state.secondaryFilters.activeAutoPlans
              .map((autoPlan) => state.secondaryFilters.byAutoPlans[autoPlan].tasks)
              .flat()
              .sort((a, b) => a - b)
              .map((taskIdx) => state.tasks[taskIdx]);
      });
    }
    case createTasksByUnassignedFilter().type: {
      return produce(state, (draft) => {
        const unAssignedTasks = state.secondaryFilters.byUnAssigned.tasks.length;
        draft.primaryActiveList = [];
        draft.selectedTasks = [];
        draft.filtered = true;
        draft.isSelectedAllTaskChecked = false;
        draft.secondaryFilters.activeUnassigned = true;
        draft.secondaryFilters.activeTeamMembers = [];
        draft.secondaryFilters.activeAutoPlans = [];
        draft.activeTasks = !unAssignedTasks
          ? []
          : state.secondaryFilters.byUnAssigned.tasks.map((idx) => state.tasks[idx]);
      });
    }
    case clearTeamMemberFilter().type: {
      return produce(state, (draft) => {
        draft.secondaryFilters.activeTeamMembers = [];
      });
    }
    case clearAutoPlanFilter().type: {
      return produce(state, (draft) => {
        draft.secondaryFilters.activeAutoPlans = [];
      });
    }
    case clearSecondaryFilter().type: {
      return produce(state, (draft) => {
        draft.activeTasks = state.tasks;
        draft.isSelectedAllTaskChecked = false;
        resetFilters(draft);
      });
    }
    case saveTeam().type: {
      return produce(state, (draft) => {
        draft.assignableAgents = payload.team;
      });
    }
    case addCreatedTasks().type: {
      const tasks = [...state.tasks].concat(payload.task);

      const sortedTasks = sortTasks(tasks);
      const updatedFilters = createFilters({ ...DEFAULT_FILTERS }, sortedTasks);

      return produce(state, (draft) => {
        draft.tasks = sortedTasks;

        draft.primaryFilters = updatedFilters.primaryFilters;
        draft.secondaryFilters = {
          ...state.secondaryFilters,
          ...updatedFilters.secondaryFilters,
        };
        draft.byContingentId = updatedFilters.byContingentId
      });
    }
    case deleteTask().type: {
      const delTasks = [...state.tasks];

      //Deletes either one or more tasks from FE
      payload.ids.forEach((id) => {
        const idx = findIndex(delTasks, ["id", id]);
        delTasks.splice(idx, 1);
      });

      const delSortedTasks = sortTasks(delTasks);
      const delUpdatedFilters = createFilters({ ...DEFAULT_FILTERS }, delSortedTasks);

      return produce(state, (draft) => {
        draft.tasks = delSortedTasks;

        draft.primaryFilters = delUpdatedFilters.primaryFilters;
        draft.secondaryFilters = {
          ...state.secondaryFilters,
          ...delUpdatedFilters.secondaryFilters,
        };
        draft.byContingentId = delUpdatedFilters.byContingentId;
        draft.isSelectedAllTaskChecked = false;
      });
    }
    case editTasks().type: {
      const editTasks = [...state.tasks];

      payload.tasks.forEach((task) => {
        const { id } = task;
        const taskIdx = findIndex(editTasks, ["id", id]);
        editTasks[taskIdx] = task;
      });

      const sortedEditedTasks = sortTasks(editTasks);
      const updatedEditedFilters = createFilters({ ...DEFAULT_FILTERS }, sortedEditedTasks);

      return produce(state, (draft) => {
        draft.tasks = sortedEditedTasks;
        draft.primaryFilters = updatedEditedFilters.primaryFilters;

        draft.secondaryFilters = {
          ...state.secondaryFilters,
          ...updatedEditedFilters.secondaryFilters,
        };
        draft.byContingentId = updatedEditedFilters.byContingentId
        draft.isSelectedAllTaskChecked = false;
      });
    }
    case toggleModalAddTaskOpen().type: {
      return {
        ...state,
        isModalAddTaskOpen: payload.bl,
      };
    }
    case toggleTasksSelectionMode().type: {
      return produce(state, (draft) => {
        draft.selectedTasks = [];
        draft.isTasksSelectionMode = !state.isTasksSelectionMode;
        if (!state.isTasksSelectionMode) draft.isSelectedAllTaskChecked = false;
      });
    }
    case taskSelect().type: {
      const { taskId } = payload;

      return produce(state, (draft) => {
        const selectedTasks = [...state.selectedTasks];
        //Task checked will add into a collection of selected task and vice-versa
        selectedTasks.includes(taskId)
          ? remove(selectedTasks, (id) => id === taskId)
          : selectedTasks.push(taskId);

        draft.selectedTasks = selectedTasks;
      });
    }
    case selectAllTasks().type: {
      return produce(state, (draft) => {
        draft.isSelectedAllTaskChecked = !state.isSelectedAllTaskChecked;
        draft.selectedTasks = !state.isSelectedAllTaskChecked ? state.activeTasks.map(({ id }) => id) : [];
      });
    }
    case clearSelectedTasks().type: {
      return {
        ...state,
        selectedTasks: [],
      };
    }
    case setTaskModalBulkDelete().type: {
      return {
        ...state,
        isModalBulkDeleteOpen: !state.isModalBulkDeleteOpen,
      };
    }
    case setModalBulkRescheduleTaskOpen().type: {
      return {
        ...state,
        isModalRescheduleTaskOpen: !state.isModalRescheduleTaskOpen,
      };
    }
    case setModalBulkReassignTaskOpen().type: {
      return {
        ...state,
        isModalReassignTaskOpen: !state.isModalReassignTaskOpen,
      };
    }
    case clearTasksAndFilters().type: {
      return produce(state, (draft) => {
        draft.primaryActiveList = [];
        draft.activeTasks = [];
        draft.secondaryFilters.activeTeamMembers = [];
        draft.secondaryFilters.activeUnassigned = false;
      });
    }
    default:
      return state;
  }
};
