import React from "react";
import Proptypes from "prop-types";
import { tdpDates } from "../../../../shared/HOCs/withRedux";
import { MUTUAL_ACCEPTANCE_DATE } from "../../../../shared/reduxRoutes/tdpDatesRoutes";
import ModifyDate from "./ModifyDate";
import { isReferral } from "../../../shared/helpers";

const MutualAcceptanceDate = ({ mutual_acceptance_date_milestone: mutualAcceptanceDate, type }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[2px] tw-px-[12px] tw-h-[44px] tw-min-h-[44px] tw-rounded tw-bg-tinted-gray-50 tw-text-gray-75 tw-text-[14px] tw-font-semibold tw-leading-[20px]">
    {isReferral(type) ? "Acceptance Date" : "Mutual Acceptance"}
    <ModifyDate date={mutualAcceptanceDate} />
  </div>
);

MutualAcceptanceDate.propTypes = {
  mutual_acceptance_date_milestone: Proptypes.shape({
    id: Proptypes.string,
    name: Proptypes.string,
    date: Proptypes.string,
  }),
};
MutualAcceptanceDate.defaultProps = {
  mutual_acceptance_date_milestone: null,
};

const MutualAcceptanceDateWrapped = tdpDates({
  WrappedComponent: MutualAcceptanceDate,
  states: MUTUAL_ACCEPTANCE_DATE,
});

export default MutualAcceptanceDateWrapped;
