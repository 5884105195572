import React from "react";
import PropTypes from "prop-types";

const Widget = ({ children }) => <div className="tw-px-24px tw-py-32px">{children}</div>;

Widget.propTypes = {
  children: PropTypes.node,
};

Widget.defaultProps = {
  children: null,
};

export default Widget;
