const initialModalsState = {
  isNewMilestoneModalOpen: false,
  isEditMilestoneModalOpen: false,
  isDeleteMilestoneModalOpen: false,
  modalErrorMessage: null,
  modalHasError: false,
  isModalSubmitting: false
}

const initialState = {
  ...initialModalsState,
  milestoneForm: {}
}

const milestonesReducer = (currentState = initialState, payload) => {
  const state = {...initialState, ...currentState }

  switch (payload.type) {
    // Index

    case 'FETCH_MILESTONES':
    return {
      ...state,
      isFetchingMilestones: payload.isFetchingMilestones
    }

    case 'MILESTONES_SUCCESS':
    return {
      ...state,
      isFetchingMilestones: false,
      milestones: payload.milestones
    }

    case 'MILESTONES_FAILURE':
    return {
      ...state,
      isFetchingMilestones: false
    }

    // New

    case 'MILESTONE_SHOW_NEW_MODAL':
    return {
      ...state,
      isNewMilestoneModalOpen: true,
      milestoneForm: {
        milestonable_type: "Person",
        visible: 'true'
      }
    }

    case 'MILESTONE_CLOSE_NEW_MODAL':
    return {
      ...state,
      ...initialModalsState
    }

    case 'MILESTONE_CREATE_PENDING':
    return {
      ...state,
      modalErrorMessage: null,
      modalHasError: false,
      isModalSubmitting: true
    }

    case 'MILESTONE_CREATE_SUCCESS':
    return {
      ...state,
      ...initialModalsState,
    }

    case 'MILESTONE_FORM_UPDATE':
    return {
      ...state,
      milestoneForm: {
        ...state.milestoneForm,
        [payload.name]: payload.value,
        type: 'Milestone'
      }
    }

    case 'MILESTONE_CREATE_FAILURE':
    return {
      ...state,
      modalErrorMessage: payload.errors,
      modalHasError: true,
      isModalSubmitting: false
    }


    // Edit

    case 'MILESTONE_SHOW_EDIT_MODAL':
    return {
      ...state,
      isEditMilestoneModalOpen: true,
      milestoneForm: payload.milestone
    }

    case 'MILESTONE_CLOSE_EDIT_MODAL':
    return {
      ...state,
      ...initialModalsState
    }

    case 'MILESTONE_UPDATE_PENDING':
    return {
      ...state,
      modalErrorMessage: null,
      modalHasError: false,
      isModalSubmitting: true
    }

    case 'MILESTONE_UPDATE_SUCCESS':
    return {
      ...state,
      ...initialModalsState,
    }

    case 'MILESTONE_UPDATE_FAILURE':
    return {
      ...state,
      modalErrorMessage: payload.errors,
      modalHasError: true,
      isModalSubmitting: false
    }


    // Delete

    case "MILESTONE_SHOW_DELETE_MODAL":
    return {
      ...state,
      ...initialModalsState,
      isDeleteMilestoneModalOpen: true,
      milestoneToDelete: payload.milestone,
      errorMessage: null,
      hasError: false,
    }

    case 'MILESTONE_CLOSE_DELETE_MODAL':
    return {
      ...state,
      ...initialModalsState
    }

    case 'DELETE_MILESTONE_PENDING':
    return {
      ...state,
      isDeletingMilestone: true,
    }

    case 'DELETE_MILESTONE_SUCCESS':
    return {
      ...state,
      ...initialModalsState,
      isDeletingMilestone: false,
      milestones: state.milestones.filter(a => a.id != state.milestoneToDelete.id),
      milestoneToDelete: null
    }

    case 'DELETE_MILESTONE_FAILURE':
    return {
      ...state,
      isDeletingMilestone: false,
      activeMilestoneId: null,
      modalHasError: true,
      modalErrorMessage: payload.errors
    }

    default:
    return state;
  }
}

export default milestonesReducer;
