import React, { useState, useEffect, useRef } from "react";

import CustomOverlay from "./CustomOverlay";
import ListingCard from "./ListingCard";
import { formatSimplePrice } from "./helpers";
import "./search-map.scss";

const SearchMap = ({
  listings,
  viewedListings,
  onClickListing,
  onToggleShare,
  maxListingsSelected,
  onUserDragMap,
  selectedListings,
}) => {
  const container = useRef(null);
  const [heightOffset, setHeightOffset] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [bounds, setBounds] = useState(null);

  const mapOptions = {
    zoom: 11,
    center: { lat: 33.773, lng: -112.3321 },
    mapTypeId: "roadmap",
    panControl: false,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP,
    },
    clickableIcons: false,
  };

  const getCurrentViewPolygon = (map) => {
    if (!map) {
      return;
    }

    const bounds = map.getBounds();
    const north = bounds.getNorthEast().lat();
    const south = bounds.getSouthWest().lat();
    const east = bounds.getNorthEast().lng();
    const west = bounds.getSouthWest().lng();

    const polygon = [
      {
        lat: north,
        lng: east,
      },
      {
        lat: north,
        lng: west,
      },
      {
        lat: south,
        lng: west,
      },
      {
        lat: south,
        lng: east,
      },
    ];

    onUserDragMap(polygon);
  };

  const onClickMarker = (index) => {
    if (index === selectedListing) {
      deselectListing();
      return;
    }

    onClickListing(listings[index].blossorId);
    setSelectedListing(index);
  };

  const deselectListing = () => {
    onClickListing(null);
    setSelectedListing(null);
  };

  useEffect(() => {
    setHeightOffset(container.current.getBoundingClientRect().top || 0);
  }, []);

  useEffect(() => {
    if (!listings.length) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    listings.forEach((listing) => {
      if (!listing.lat || !listing.lng) {
        return;
      }

      bounds.extend({ lat: listing.lat, lng: listing.lng });
    });

    deselectListing();
    setBounds(bounds);
  }, [listings]);

  return (
    <div ref={container}>
      <Map
        className="tw-w-full search-map tw-rounded-t-[8px]"
        style={{ height: `calc(100vh - ${heightOffset}px)` }}
        options={mapOptions}
        bounds={bounds}
        onClick={deselectListing}
        onDragEnd={getCurrentViewPolygon}
      >
        {listings &&
          listings.length &&
          listings.map((listing, i) => (
            <CustomOverlay
              key={listing.blossorId}
              position={{ lat: listing.lat, lng: listing.lng }}
              pixelOffset={{ x: -32, y: -34 }}
              zIndex={selectedListing === i ? 10 : "initial"}
            >
              <ListingMarker
                price={listing.price}
                onClick={() => onClickMarker(i)}
                isSelected={selectedListing === i}
                viewed={viewedListings.includes(listing.blossorId)}
              />
            </CustomOverlay>
          ))}
        {listings.length && selectedListing !== null && (
          <CustomOverlay
            position={{ lat: listings[selectedListing].lat, lng: listings[selectedListing].lng }}
            pixelOffset={{ x: -118, y: -252 }} // This offset is to account for the ListingMarker
            allowReposition={true}
            elementSize={{ width: 235, height: 214 }}
          >
            <ListingCard
              key={listings[selectedListing].blossorId}
              listing={listings[selectedListing]}
              onToggleShare={onToggleShare}
              maxListingsSelected={maxListingsSelected}
              isChecked={selectedListings.some((l) => l.blossorId === listings[selectedListing].blossorId)}
              size={"small"}
            />
          </CustomOverlay>
        )}
      </Map>
    </div>
  );
};

export default SearchMap;

const ListingMarker = ({ price, onClick, isSelected, viewed }) => {
  let selectedStyling;
  if (isSelected) {
    selectedStyling = "tw-bg-teal tw-border-teal selected";
  } else if (viewed) {
    selectedStyling = "tw-bg-gray-15 tw-border-gray-15 viewed";
  } else {
    selectedStyling = "tw-bg-white tw-border-gray-30";
  }

  const divStyle = `search-map-marker tw-relative tw-flex tw-cursor-pointer tw-justify-center tw-px-8px tw-py-3px tw-items-center tw-rounded-666px tw-border-1px tw-border-solid ${selectedStyling}`;

  return (
    <div onClick={onClick} className={divStyle}>
      <span
        className={`tw-text-14px tw-font-sans tw-font-semibold ${
          isSelected ? "tw-text-white" : "tw-text-gray-75"
        }`}
      >
        {formatSimplePrice(price) || "N/A"}
      </span>
    </div>
  );
};

const Map = ({ bounds, options, style, onClick, onDragEnd, className, children }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [listeners, setListeners] = useState([]);

  useEffect(() => {
    return () => {
      for (const listener of listeners || []) {
        listener.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef.current && !map) {
      const m = new window.google.maps.Map(mapRef.current, { ...options });
      const newListeners = [
        window.google.maps.event.addListener(m, "click", onClick),
        window.google.maps.event.addListener(m, "dragend", () => onDragEnd(m)),
      ];

      setListeners(newListeners);
      setMap(m);
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (bounds && map) {
      // If we have only one bound, don't zoom in quite so far
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const coordinateManipulator = 0.01;
        bounds.extend({lat: bounds.getNorthEast().lat() + coordinateManipulator, lng: bounds.getNorthEast().lng() + coordinateManipulator});
        bounds.extend({lat: bounds.getSouthWest().lat() - coordinateManipulator, lng: bounds.getSouthWest().lng() - coordinateManipulator});
      }

      map.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <div ref={mapRef} className={className} style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </div>
  );
};
