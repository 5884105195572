import React from "react";
import capitalize from "capitalize";

const FORMATTED_KEYS = {
  Amount: "Offer Amount",
  "Need financing_advice": "Mortgage Pre-Approval",
  "Have seen_home_in_person": "Have Seen Home In Person",
};

const OfferDetails = ({ event }) => {
  const descriptionItems = [];
  const keyAndValueStrings = event.timeline_fields.comments?.split(/<br \/>|<br>/) || [];

  for (let index = 0; index < keyAndValueStrings.length; index += 1) {
    const detailString = keyAndValueStrings[index];
    const [key, value] = detailString.split(": ");
    const formattedKey = FORMATTED_KEYS[key];

    if (index === 0) {
      descriptionItems.push(detailString);
    } else if (formattedKey) {
      let formattedValue = value;
      formattedValue = formattedValue.replace("Http", "http");
      formattedValue = formattedValue.replace("$ ", "$");
      formattedValue = capitalize(formattedValue);
      descriptionItems.push(`<span class="tw-text-[#666] tw-font-bold">${formattedKey}:</span> ${value}`);
    }
  }

  return descriptionItems.map((description, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={i} dangerouslySetInnerHTML={{ __html: description }} />
  ));
};

export default OfferDetails;
