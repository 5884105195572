/* eslint-disable react/prop-types, react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from "react";

const PillButton = ({ className, children, onClick, role, ...otherProps }) => (
  <div
    className={`tw-rounded-full tw-cursor-pointer tw-py-4px tw-px-12px tw-mb-4px tw-mx-0.5 tw-inline-flex tw-justify-center tw-items-center ${className}`}
    onClick={onClick}
    role={role}
    {...otherProps}
  >
    {children}
  </div>
);

export default PillButton;
