import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "../../shared/v2/Dropdown";
import { Search as SearchIcon } from "../../shared/v2/Icomoon";
import { useDebounce } from "../../shared/hookHelpers";

const searchAccounts = (search, signal) =>
  axios.get("/accounts/search", {
    signal,
    params: { search },
    headers: {
      "X-CSRF-Token": ReactOnRails.authenticityToken(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export default ({ id, onChange, taskFor }) => {
  const [value, setValue] = useState(taskFor ? { ...taskFor, value: taskFor.uuid } : null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [_search, setSearch] = useState("");
  const search = useDebounce(_search, 250);

  useEffect(() => {
    if (search.length < 3) return;
    setLoading(true);
    const abortController = new AbortController();
    searchAccounts(search, abortController.signal)
      .then(({ data }) => {
        setLoading(false);
        setData(data.map((d) => ({ ...d, value: d.uuid })));
      })
      .catch(() => setLoading(false));
    return () => abortController.abort();
  }, [search]);

  useEffect(() => {
    if (value) onChange(value);
  }, [value]);

  return (
    <Dropdown
      id={id}
      name="search_query"
      className="tw-max-w-[324px]"
      label="Select person, transaction or project"
      placeholder="Search by Name, Email, or Address"
      isRequired
      isSearchable
      isLoading={loading}
      onInputChange={setSearch}
      getOptionLabel={(option) => (
        <>
          <i className={`${option.icon} ${option.color} task-result-icon`} /> {option.label} ({option.type})
        </>
      )}
      noOptionsMessage={
        _search.length < 3 ? `Enter ${3 - _search.length} more characters to search` : "No Options"
      }
      onChange={setValue}
      value={value}
      options={data}
      menuShouldComeToFront
      shouldFilterOptions={false}
      inputTrailing={<SearchIcon size="l" style={{ verticalAlign: "middle" }} />}
    />
  );
};
