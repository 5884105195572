import axios from "axios";
import {
  camelCase,
  isArray,
  isObjectLike,
  isPlainObject,
  keys,
  map,
  replace,
  set,
  snakeCase,
  startsWith,
  transform,
} from "lodash";

// modified version of https://github.com/cape-io/lodash-humps
function createIteratee(converter, self) {
  return (result, value, key) =>
    // custom bit to not convert keys that start with an underscore
    set(result, startsWith(key, "_") ? key : converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter) {
  return function humps(node) {
    if (isArray(node)) return map(node, humps);
    if (isPlainObject(node)) return transform(node, createIteratee(keyConverter, humps));
    return node;
  };
}
// end modified lodash-humps

/**
 * this regex is 3 capturing groups (), the two on the ends (r1 and r3) capture the brackets
 * if any, and the center group (r2) captures the text to be snake_cased if any and applies
 * it globally to the string, capturing each set of groups and replacing each portion of the
 * string with the updated snakified value until there are no longer any matching sets
 */
const snakifyParamKey = (key) =>
  replace(key, /(\[?)(\w*)(\]?)/g, (m, r1, r2, r3) => `${r1}${snakeCase(r2)}${r3}`);

// converts camelCase params preserving nested key format
const snakifyParams = (params) =>
  keys(params).reduce((o, key) => ({ ...o, [snakifyParamKey(key)]: params[key] }), {});

/**
 * This is an axios instance with request and response transforms that
 * can make dev life easier when working with a snake_case backend api.
 *
 * Simply put all data keys passed to a POST/PUT/PATCH request will be
 * converted to snake_case. All response data keys from any request will
 * be converted to camelCase no matter how deeply nested they may be.
 */

const caseTransformingAxios = axios.create();

const requestDataToSnakeCase = (data) => data && createHumps(snakeCase)(data);
const responseDataToCamelCase = (data) => data && createHumps(camelCase)(data);

caseTransformingAxios.interceptors.request.use(
  ({ data, headers, params, ...config }) => ({
    ...config,
    data: requestDataToSnakeCase(data),
    headers: { ...headers, "x-csrf-token": ReactOnRails.authenticityToken() },
    params: snakifyParams(params),
  }),
  (error) => Promise.reject(error),
);

caseTransformingAxios.interceptors.response.use(
  ({ data, ...response }) => ({ ...response, data: responseDataToCamelCase(data) }),
  (error) => Promise.reject(error),
);

// We want to make this as obvious as possible when importing
export { caseTransformingAxios, createHumps, requestDataToSnakeCase, responseDataToCamelCase, snakifyParams };
