/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Upload = (props) => (
  <Icon {...props} name="Upload">
    <path d="M9.177 11.764H6.824a.705.705 0 0 1-.706-.706V6.116h-2.58a.587.587 0 0 1-.415-1.003L7.597.636a.57.57 0 0 1 .803 0l4.477 4.477a.587.587 0 0 1-.415 1.003h-2.58v4.942a.705.705 0 0 1-.706.706zm6.353-.235v3.294a.705.705 0 0 1-.706.706H1.175a.705.705 0 0 1-.706-.706v-3.294c0-.391.315-.706.706-.706h4v.235c0 .909.738 1.647 1.647 1.647h2.353c.909 0 1.647-.738 1.647-1.647v-.235h4c.391 0 .706.315.706.706zm-3.647 2.588c0-.324-.265-.588-.588-.588s-.588.265-.588.588c0 .324.265.588.588.588s.588-.265.588-.588zm1.882 0c0-.324-.265-.588-.588-.588s-.588.265-.588.588c0 .324.265.588.588.588s.588-.265.588-.588z" />
  </Icon>
);

export default Upload;
