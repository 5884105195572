import React from "react";
import { func, bool, arrayOf, shape, string } from "prop-types";
import Person from "../Person";
import { AngleRightSolidV6 } from "../../../../shared/v2/Icomoon";

const PeopleSubCategories = ({ setDropdown, dropdown, data, title, setAppointmentModalUser }) => (
  <div className="tw-mb-[18px]">
    <button
      type="button"
      className="tw-p-0 tw-bg-transparent tw-border-none tw-text-[14px] tw-font-bold tw-leading-[20px] tw-tracking-normal tw-flex tw-items-center tw-gap-[4px]"
      onClick={() => setDropdown((open) => !open)}
    >
      <AngleRightSolidV6
        className={`tw-text-theme-primary tw-transition-all ${dropdown ? "" : "tw-rotate-90"}`}
      />
      <div className="tw-ml-8px tw-text-[14px] tw-font-bold tw-leading-[20px] tw-tracking-normal">{`${title} (${data?.length})`}</div>
    </button>

    {!dropdown && (
      <div className="tw-mt-[24px]">
        {data?.map((listingPerson) => {
          const person = {
            ...listingPerson.person,
            ...{
              role: listingPerson.role,
              viewer_portal_access: listingPerson.viewer_portal_access,
              email_updates: listingPerson.email_updates,
              listing_person_id: listingPerson.id,
              primary: listingPerson?.primary,
            },
          };
          return (
            <Person
              key={person?.id}
              person={person}
              title={title}
              isCollaborator={title === "Collaborators"}
              isClient={title === "Clients"}
              setAppointmentModalUser={setAppointmentModalUser}
            />
          );
        })}
      </div>
    )}
  </div>
);

PeopleSubCategories.propTypes = {
  setDropdown: func.isRequired,
  dropdown: bool.isRequired,
  data: arrayOf(shape({})),
  title: string,
  setAppointmentModalUser: func.isRequired,
};

PeopleSubCategories.defaultProps = {
  data: [],
  title: "",
};

export default PeopleSubCategories;
