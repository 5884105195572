import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "../actions/creators";
import { autoComplete } from "../services";
import { Search } from "../../shared/v2/Icomoon";
import TextToggle from "../../shared/v2/TextToggle";
import TextComboInput from "../../shared/v2/TextComboInput";
import LocationSearch from "./LocationSearch";

const mapStateToProps = (state) => ({
  searchTab: state.partnerSearch.searchTab,
  resultsCount: state.partnerSearch.results.length,
  searchTerm: state.partnerSearch.searchTerm,
  isSearching: state.partnerSearch.searchTerm > "",
});

const SEARCH_OPTIONS = [
  {
    value: "google_place",
    label: "Area",
  },
  {
    value: "owner_operator",
    label: "Owner/Operator",
  },
  {
    value: "team",
    label: "Team",
  },
];

const SEARCH_PLACEHOLDERS = {
  google_place: "Search by city or state / province",
  owner_operator: "Search by owner / operator name or email",
  team: "Search by team name",
};

const Header = ({
  searchTab,
  resultsCount,
  searchTerm,
  isSearching,
  changeSearchTab,
  changeSearchTerm,
  changeIsSearching,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <div>
      <h1 className="tw-text-neutral-gray-75 tw-text-[28px] tw-mb-8">
        Find a Partner {isSearching && <span className="tw-text-[14px]">{resultsCount} results</span>}
      </h1>
      <div className="tw-flex tw-items-center tw-mb-7">
        <p className="tw-text-neutral-gray-50 tw-text-[14px] tw-mr-4 tw-mb-0">Search by:</p>
        <TextToggle
          options={SEARCH_OPTIONS}
          value={searchTab}
          size="small"
          onChange={(e) => changeSearchTab(e.target.value)}
        />
        <div className="tw-flex-1" />
      </div>
      {searchTab === "google_place" ? (
        <LocationSearch
          value={searchTerm}
          placeholder={SEARCH_PLACEHOLDERS[searchTab]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => {
            changeSearchTerm(e);
            changeIsSearching(true);
          }}
          trailing={
            <Search
              className={`tw-pt-[3px] ${focused ? "tw-text-semantic-blue-100" : "tw-text-neutral-gray-30 "}`}
              size="xl"
            />
          }
          callApi={autoComplete}
        />
      ) : (
        <TextComboInput
          value={searchTerm}
          placeholder={SEARCH_PLACEHOLDERS[searchTab]}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onInput={(e) => {
            changeSearchTerm(e.target.value);
            changeIsSearching(true);
          }}
          trailing={
            <Search
              className={`tw-pt-[3px] ${focused ? "tw-text-semantic-blue-100" : "tw-text-neutral-gray-30 "}`}
              size="xl"
            />
          }
        />
      )}
    </div>
  );
};

Header.propTypes = {
  searchTab: PropTypes.string.isRequired,
  resultsCount: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  changeSearchTab: PropTypes.func.isRequired,
  changeSearchTerm: PropTypes.func.isRequired,
  changeIsSearching: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, creators)(Header);
