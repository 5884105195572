import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: ["tw-border-neutral-gray-15", "tw-bg-tinted-gray-50", "tw-text-neutral-gray-75"],
    hover: ["hover:tw-border-semantic-red-100", "hover:tw-bg-white", "hover:tw-text-semantic-red-100"],
    active: ["active:tw-border-semantic-red-100", "active:tw-bg-semantic-red-100", "active:tw-text-white"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
