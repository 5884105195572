/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const SparklesSolidV6 = (props) => (
  <Icon {...props} name="Sparkles Solid V6">
    <path d="M10.234 2.662 12 2l.663-1.766a.36.36 0 0 1 .674 0L14 2l1.766.662a.361.361 0 0 1 0 .675L14 4l-.663 1.766a.36.36 0 0 1-.674 0L12 4l-1.766-.663C10.065 3.284 10 3.149 10 3s.066-.285.234-.338zM8.056 5.853 11.618 7.5a.499.499 0 0 1 0 .909l-3.562 1.647-1.647 3.562a.499.499 0 0 1-.909 0l-1.647-3.562L.29 8.409a.5.5 0 0 1 0-.909l3.563-1.647L5.5 2.29a.5.5 0 0 1 .909 0l1.647 3.563zm4.607 4.381c.053-.169.187-.234.337-.234s.284.066.337.234L14 12l1.766.663a.36.36 0 0 1 0 .674L14 14l-.663 1.766a.36.36 0 0 1-.674 0L12 14l-1.766-.663C10.065 13.284 10 13.15 10 13s.066-.284.234-.337L12 12l.663-1.766z" />
  </Icon>
);

export default SparklesSolidV6;
