const getControlBoxShadowColor = (state) => {
  if (state.selectProps.error) {
    return "var(--semantic-red-10)";
  }

  return "var(--dropdown-box-shadow-focus)";
};

const getControlBorderColor = (state, isHover, isPipeline) => {
  if (isHover) {
    if (state.isDisabled) {
      return null;
    }

    if (state.selectProps.error) {
      return "var(--semantic-red-50)";
    }

    if (state.isFocused) {
      return null;
    }

    return "var(--dropdown-border-hover)";
  }

  if (state.isDisabled) {
    return "var(--neutral-gray-10)";
  }

  if (state.selectProps.error && state.isFocused) {
    return "var(--semantic-red-100)";
  }

  if (state.selectProps.error) {
    return "var(--semantic-red-25)";
  }

  if (state.isFocused) {
    return "var(--dropdown-border-focus)";
  }

  if (state.hasValue) {
    return isPipeline ? "var(--text-input-border)" : "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-15)";
};

const getControlBackgroundColor = (state, isPipeline) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-2_5)";
  }

  if (state.isFocused) {
    return "var(--white)";
  }

  return isPipeline ? "var(--text-input-bg)" : "var(--tinted-gray-50)";
};

const getPlaceholderColor = (state) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-50)";
};

const getSingleValueColor = (state) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-75)";
};

const getMultiValueRemoveColor = (state, isHover) => {
  if (isHover || state.isFocused) {
    return "var(--semantic-red-100)";
  }

  return "var(--neutral-gray-75)";
};

const getInputColor = (state) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-75)";
};

const getInputSelectionColor = (state) => {
  if (state.selectProps.error) {
    return "var(--semantic-red-5)";
  }

  return "var(--dropdown-input-selection)";
};

const getIndicatorColor = (state) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-75)";
};

const getOptionBackgroundColor = (state, isHover, isActive) => {
  if (state.isDisabled) {
    return "var(--white)";
  }

  if (isActive || state.isSelected) {
    return "var(--tinted-gray-100)";
  }

  if (isHover || state.isFocused) {
    return "var(--tinted-gray-50)";
  }

  return "var(--white)";
};

const getOptionColor = (state) => {
  if (state.isDisabled) {
    return "var(--neutral-gray-30)";
  }

  return "var(--neutral-gray-75)";
};

const styles = (isPipeline = false) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: "36px",
    boxShadow: state.isFocused ? `0 0 0 1.5px ${getControlBoxShadowColor(state)}` : "none",
    borderColor: getControlBorderColor(state, false, isPipeline),
    borderRadius: isPipeline ? "4px" : "2px",
    backgroundColor: getControlBackgroundColor(state, isPipeline),
    padding: "7px 12px 7px 10px",
    gap: "8px",

    "&:hover": {
      borderColor: getControlBorderColor(state, true),
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    gap: state.selectProps.isMulti ? "8px" : null,
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "400",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    padding: 0,
    color: getPlaceholderColor(state),
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: getSingleValueColor(state),
  }),

  multiValue: (provided) => ({
    ...provided,
    margin: 0,
    borderRadius: "9999px",
    backgroundColor: "var(--neutral-gray-10)",
    padding: "4px 8px",
    gap: "8px",
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: "transparent",
    padding: 0,
    paddingLeft: 0,
    color: "var(--neutral-gray-75)",
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: "600",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: "transparent",
    paddingRight: 0,
    paddingLeft: 0,
    color: getMultiValueRemoveColor(state, false),

    "&:hover": {
      backgroundColor: "transparent",
      color: getMultiValueRemoveColor(state, true),
    },

    "&:focus-visible": {
      outline: "none",
      color: getMultiValueRemoveColor(state, true),
    },
  }),

  input: (provided, state) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    color: getInputColor(state),

    "input::selection": {
      backgroundColor: getInputSelectionColor(state),
    },
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    color: getIndicatorColor(state),
    transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : null,
    cursor: "pointer",

    "&:hover": {
      color: getIndicatorColor(state),
    },
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),

  menu: (provided) => ({
    ...provided,
    marginTop: "4px",
    boxShadow: "0px 6px 20px 2px rgba(51, 51, 51, 0.16)",
    border: "1px solid var(--neutral-gray-15)",
    borderRadius: "2px",
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),

  group: (provided) => ({
    ...provided,
    padding: 0,
  }),

  groupHeading: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    minHeight: "32px",
    marginBottom: 0,
    backgroundColor: "var(--tinted-gray-50)",
    padding: "4px 12px",
    color: "var(--neutral-gray-75)",
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: "600",
    textTransform: "none",
  }),

  option: (provided, state) => ({
    ...provided,
    minHeight: "32px",
    backgroundColor: getOptionBackgroundColor(state, false, false),
    padding: "6px 12px",
    color: getOptionColor(state),
    lineHeight: "20px",
    fontSize: "14px",
    fontWeight: !state.isDisabled && state.isSelected ? "600" : "400",
    cursor: state.isDisabled ? "not-allowed" : "pointer",

    "&:hover": {
      backgroundColor: getOptionBackgroundColor(state, true, false),
    },

    "&:active": {
      backgroundColor: getOptionBackgroundColor(state, false, true),
    },
  }),
});

export default styles;
