/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import moment from "moment-timezone";
import { capitalize, has, keys, values, startCase } from "lodash";

const getSocialKey = (meta) => meta.split(".")[1];

const createIcon = ({ type, sub_type, action, meta }) => {
  // eslint-disable-next-line default-case
  switch (sub_type) {
    case "assigned_plan":
      return action;
    case "listing":
      if (has(meta, "status")) return "listing_status";
      if (has(meta, "title")) return "listing_transaction_name";
      if (action === "imported") return "listing_import";
      return "date";
  }

  // eslint-disable-next-line default-case
  switch (type) {
    case "timeline":
    case "interaction":
      return sub_type;
  }

  return type;
};

const createAction = ({ type, sub_type, meta, action, owner }) => {
  // the following events are identified by the type
  // eslint-disable-next-line default-case
  switch (type) {
    case "showing":
      if (owner == null) return "Showing feedback";
      return "added a showing feedback";
    case "listingadvertiser":
      return "posted in advertising";
    case "marketer":
      return `posted your listing to ${capitalize(getSocialKey(meta))}`;
    case "task":
      return `${action} a task`;
    case "upload":
      return `${action} a photo`;
    case "note":
      return `${action} a note`;
  }

  // the following events are identified by the sub_type
  // eslint-disable-next-line default-case
  switch (sub_type) {
    case "sms_in":
    case "sms_out":
      return "texted ";
    case "call":
      return "logged a call with";
    case "gmail":
    case "message":
      return "emailed ";
    case "appointment":
      return "scheduled an appointment with";
    case "other":
    case "pop_by":
    case "mail":
    case "social_media":
      return "logged an interaction with";
    case "assigned_plan":
      return `${action} an Auto Plan`;
    case "document":
      if (meta.cda_form) return `${action} a CDA document`;
      return `${action} a document`;
    case "listing_person":
      if (action === "added") return `${action} ${meta} to transaction`;
      return `${action} ${meta} from transaction`;
    case "listing":
      if (has(meta, "status")) return "updated the transaction status";
      if (has(meta, "title")) return "renamed the transaction";
      if (action === "imported") return "imported listing data";
      return "changed a date";
    case "note":
      return `${action} a note`;
  }

  return null;
};

const createMeta = ({ type, sub_type, meta, action }) => {
  // the following events are identified by the sub_type
  // eslint-disable-next-line default-case
  switch (sub_type) {
    case "message":
    case "gmail":
    case "sms_in":
    case "sms_out":
      return {
        variant: "message",
        content: meta.message,
      };
    case "call":
      return {
        variant: "call",
        content: [
          {
            outcome: capitalize(meta.outcome).replaceAll("_", " "),
            notes: meta.notes,
          },
        ],
      };
    case "other":
    case "pop_by":
    case "mail":
    case "social_media":
      return {
        variant: "other",
        content: [
          {
            type: capitalize(meta.type).replaceAll("_", " "),
            notes: meta.notes,
          },
        ],
      };
    case "appointment":
      return {
        variant: "appointment",
        content: [
          {
            time: moment.unix(meta.time).tz(Brivity.LocalTimeZone).format("MM/DD/YY [at] hh:mm a z"),
            type: capitalize(meta.type).replaceAll("_", " "),
          },
        ],
      };
    case "assigned_plan":
      return {
        variant: "label",
        content: [
          "Auto Plan name",
          {
            text: meta.name,
          },
        ],
      };
    case "document":
      return {
        variant: "document",
        content: {
          name: meta.name,
          url: meta.url,
          cdaForm: meta.cda_form,
        },
      };
    case "listing_person":
      return {
        variant: "normal",
        content: "",
      };
    case "note":
      if (action === "updated") {
        return {
          variant: "note",
          content: [
            {
              before: meta.content?.[0],
              after: meta.content?.[1],
            },
          ],
        };
      }
      return {
        variant: "normal",
        content: meta.content?.[0],
      };
    case "listing": {
      // if title has changed
      if (has(meta, "title")) {
        return {
          variant: "label",
          content: [
            "Transaction name",
            {
              text: meta.title[1],
            },
          ],
        };
      }
      // if status has changed
      if (has(meta, "status")) {
        return {
          variant: "status",
          content: [
            {
              before: meta.status[0],
              after: meta.status[1],
            },
          ],
        };
      }
      // if listing data is imported
      if (action === "imported") {
        return {
          variant: "import",
          content: {
            mls_number: meta.mls_number,
          },
        };
      }
      // every other change is date related
      const parsedDate = moment(values(meta)[0][1]).tz(Brivity.LocalTimeZone);
      // check if the parsed date is valid
      const after = parsedDate.isValid() ? parsedDate.format("MM/DD/YYYY [at] hh:mm a z") : "Date removed";
      return {
        variant: "date",
        content: [
          `${startCase(keys(meta)[0])}`,
          {
            before:
              values(meta)[0][0] === ""
                ? ""
                : moment(values(meta)[0][0]).tz(Brivity.LocalTimeZone).format("MM/DD/YYYY [at] hh:mm a z"),
            after,
          },
        ],
      };
    }
  }

  // the following types are identified by the type
  // eslint-disable-next-line default-case
  switch (type) {
    case "marketer":
      return null;
    case "showing":
      return {
        variant: "showing",
        content: [
          {
            text: meta.body,
            liked: meta.liked,
          },
        ],
      };
    case "listingadvertiser":
      return {
        variant: "ads",
        content: [
          "Your listing was posted on ",
          {
            text: meta.name,
            link: meta.url,
          },
        ],
      };
    case "task":
      return {
        variant: "label",
        content: [
          "Task Detail",
          {
            text: meta.name,
          },
        ],
      };
    case "upload":
      return {
        variant: "upload",
        content: meta,
      };
  }

  return { variant: "normal", content: meta };
};

const createOwner = ({ owner }) => ({
  name: owner?.name,
  slug: owner?.slug,
});

const createRecipient = ({ recipient }) => ({
  name: recipient ? `${recipient?.first_name} ${recipient?.last_name}` : "",
  slug: recipient?.slug,
});

const createDate = ({ date }) =>
  moment.unix(date).tz(Brivity.LocalTimeZone).format("MM/DD/YY [at] hh:mm a z") ?? "No Date";

const createGroup = ({ type, sub_type, meta, action }) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case "task":
      return "Tasks";
    case "note":
      return "Notes";
    case "showing":
    case "listingadvertiser":
    case "marketer":
    case "upload":
      return "Transaction Activity";
  }

  switch (sub_type) {
    case "sms_in":
    case "sms_out":
      return "Texts";
    case "call":
      return "Phone Calls";
    case "gmail":
    case "message":
      return "Emails";
    case "document":
      return "Documents";
    case "appointment":
    case "note":
      return "Notes";
    case "listing":
      if (!has(meta, "status") && !has(meta, "title") && action !== "imported") return "Appointments";
    // the rest are transaction activities including listing status and title change
    // eslint-disable-next-line no-fallthrough
    default:
      return "Transaction Activity";
  }
};

export const createTask = ({
  type,
  sub_type,
  meta,
  owner,
  action,
  recipient,
  date,
  externally_visible,
  id,
}) => ({
  icon: createIcon({ type, sub_type, action, meta }),
  action: createAction({ type, sub_type, meta, action, owner }),
  meta: createMeta({ type, sub_type, meta, action }),
  owner: createOwner({ owner }),
  recipient: createRecipient({ recipient }),
  date: createDate({ date }),
  group: createGroup({ type, sub_type, meta, action }),
  externally_visible,
  id,
});

export const createTags = (timeline) => {
  const tags = {
    byName: {},
  };

  // iterate over all the timeline events and group them by group name
  for (const idx in timeline.byId) {
    const { group } = timeline.byId[idx];
    const { byName } = tags;

    if (!byName[group]) {
      byName[group] = {
        count: 1,
        eventsById: [timeline.byId[idx].id],
      };
    } else {
      byName[group].count += 1;
      byName[group].eventsById.push(timeline.byId[idx].id);
    }
  }

  return tags;
};
