import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { Search } from "@shared/v2/Icomoon";
import useMlsListingSearch from "./useMlsListingSearch";

const MlsListingDropdown = ({ label, onChange, placeholder, value }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const mlsSearch = useMlsListingSearch(searchTerm);

  return (
    <Dropdown
      label={label}
      placeholder={placeholder}
      value={value}
      options={mlsSearch.options}
      isLoading={mlsSearch.loading}
      error={mlsSearch.error}
      isSearchable
      menuShouldComeToFront
      inputValue={value?.label || searchTerm}
      onInputChange={setSearchTerm}
      onChange={(option) => onChange?.(option || null)}
      inputTrailing={<Search size="xl" className="tw-mr-[-10px] tw-fill-gray-30" />}
      components={{ DropdownIndicator: null }}
      preventOptionUpdate
    />
  );
};

MlsListingDropdown.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.shape(),
};

MlsListingDropdown.defaultProps = {
  label: "",
  placeholder: "Search",
  value: null,
};

export default MlsListingDropdown;
