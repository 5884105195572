/* eslint-disable class-methods-use-this */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Checkbox from "@shared/v2/Checkbox";
import TextButton from "@shared/v2/TextButton";
import { filterAssignedPlans } from "./helpers";

class AssignedPlansCard extends React.Component {
  componentDidMount() {
    const { getAssignedPlans } = this.props;
    getAssignedPlans();
  }

  safeCount = (list) => (list ? list.length : 0);

  whenStateChangedLabel = (plan = {}) => {
    const { state } = plan;

    if (state === "completed" || state === "deleted") {
      const time = plan[`${state}_at`];
      const timeLabel = moment(time).format("MM/DD/YYYY");
      return time ? `on ${timeLabel}` : "";
    }
    return "";
  };

  canDeletePlan = (state) => state !== "deleted" && state !== "completed";

  updatePlanState(id, state) {
    const { onUpdate } = this.props;
    const form = { state };
    onUpdate(`/assigned_plans/${id}`, form);
  }

  emptyPlansLabel() {
    const filteredLength = filterAssignedPlans().length;
    const { assignedPlans } = this.props;
    const totalLength = assignedPlans.length;

    return filteredLength === 0 && totalLength > 0
      ? "No plans match the filtered criteria"
      : "No plans have been applied to the contact";
  }

  render() {
    const {
      assignedPlans,
      onShowApplyModal,
      onShowPlanModal,
      showCompletedPlans,
      showDeletedPlans,
      toggleCompletedPlans,
      toggleDeletedPlans,
      onShowDeletePlanModal,
    } = this.props;
    const filtertedAssignedPlans = filterAssignedPlans(assignedPlans, showCompletedPlans, showDeletedPlans);

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="assigned-plans-card">
            <div className="assigned-plans-card-filter-row">
              <div className="description">{`Assigned Plans (${this.safeCount(
                filtertedAssignedPlans,
              )})`}</div>
              <div className="actions">
                {assignedPlans?.length > 0 && (
                  <div className="action-item">
                    <Checkbox
                      onChange={toggleDeletedPlans}
                      checked={showDeletedPlans}
                      label="Show Deleted Plans"
                    />
                  </div>
                )}
                {assignedPlans?.length > 0 && (
                  <div className="action-item">
                    <Checkbox
                      onChange={toggleCompletedPlans}
                      checked={showCompletedPlans}
                      label="Show Completed Plans"
                    />
                  </div>
                )}
                <TextButton onClick={onShowApplyModal}>Add New Plan</TextButton>
              </div>
            </div>

            {assignedPlans &&
              filtertedAssignedPlans.map((plan) => (
                <div key={plan.id} className={`assigned-plan-row d-flex ${plan.state}`}>
                  <div className="content">
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <div className="title bold pseudo-link" onClick={() => onShowPlanModal(plan)}>
                      {plan.name}
                    </div>
                    <div className="sub-title">
                      <div className={`inline-item state ${plan.state}`} />
                      <div className="inline-item understated">{this.whenStateChangedLabel(plan)}</div>
                      <div className="inline-item">Auto Plan</div>
                      <div className="inline-item understated">
                        {plan.total_completed_actions} of {plan.assigned_actions_count} completed
                      </div>
                      <div className="inline-item">Next: {plan.next_action_description}</div>
                    </div>
                  </div>
                  <div className="assigned-plan-actions">
                    {plan.state === "paused" && (
                      <div className="inline-item">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <i
                          className="fa fa-play assigned-plan-action"
                          onClick={this.updatePlanState.bind(this, plan.id, "started")}
                        />
                      </div>
                    )}
                    {plan.state === "started" && (
                      <div className="inline-item">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <i
                          className="fa fa-pause assigned-plan-action"
                          onClick={this.updatePlanState.bind(this, plan.id, "paused")}
                        />
                      </div>
                    )}
                    {this.canDeletePlan(plan.state) && (
                      <div className="inline-item">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <i
                          className="fa fa-trash assigned-plan-action"
                          onClick={() => onShowDeletePlanModal(plan)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {assignedPlans && filtertedAssignedPlans.length === 0 && (
              <div className="assigned-plan-row d-flex">
                <div className="content">
                  <span className="understated">{this.emptyPlansLabel(filtertedAssignedPlans.length)}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AssignedPlansCard;

AssignedPlansCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  assignedPlans: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAssignedPlans: PropTypes.func.isRequired,
  onShowApplyModal: PropTypes.func.isRequired,
  onShowDeletePlanModal: PropTypes.func.isRequired,
  onShowPlanModal: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showCompletedPlans: PropTypes.bool.isRequired,
  showDeletedPlans: PropTypes.bool.isRequired,
  toggleCompletedPlans: PropTypes.func.isRequired,
  toggleDeletedPlans: PropTypes.func.isRequired,
};
