import React, { useState } from "react";
import DismissibleAlert from "@shared/DismissibleAlert";
import Check from "@shared/v2/Icomoon/Icons/Check";

import ReferFriendBtn from "./ReferFriendBtn";
import ReferFriendModal from "./ReferFriendModal";

const ReferFriend = ({}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isReferred, setReferred] = useState(false);
  const alertMessage = (
    <p className="tw-w-[254px]">
      <Check size="xl" className="tw-mb-[-4px]" />{" "}
      <span className="tw-pl-[9px]">Referral submitted successfully.</span>
    </p>
  );
  const onHideHandlr = () => {
    setModalOpen(false);
  };
  const onReferred = () => {
    setReferred(true);
    setModalOpen(false);
  };
  const showModal = () => {
    setModalOpen(true);
  };
  const handleDismissAlert = () => {
    setReferred(false);
  };

  return (
    <div>
      <ReferFriendBtn openModal={showModal} />
      <ReferFriendModal isModalOpen={isModalOpen} onHideHandlr={onHideHandlr} onSave={onReferred} />
      <div className="tw-fixed tw-right-[42%] tw-left-[37%]">
        <DismissibleAlert
          variant="success"
          content={alertMessage}
          displayAlert={isReferred}
          handleDismissAlert={handleDismissAlert}
        />
      </div>
    </div>
  );
};

export default ReferFriend;
