import React from "react";
import { useDispatch } from "react-redux";
import Dropdown from "@shared/v2/Dropdown";
import CALL_STATES from "../utils/states";
import {
  useCallState,
  useLoading,
  usePerson,
  usePhoneNumber,
  usePhoneNumbers,
} from "../../reducers/dialerReducer/selectors";
import { setPhoneNumber } from "../../reducers/dialerReducer/dialerReducer";

const SinglePerson = () => {
  const dispatch = useDispatch();
  const loading = useLoading();
  const callState = useCallState();
  const person = usePerson();
  const phoneNumbers = usePhoneNumbers();
  const phoneNumber = usePhoneNumber();
  if (loading) return <span />;
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <span className="tw-text-18d tw-font-bold tw-leading-[18px]">{person?.fullName || "No Name"}</span>
      <Dropdown
        className="tw-min-w-[240px]"
        options={phoneNumbers}
        value={phoneNumber}
        isDisabled={callState !== CALL_STATES.Init}
        onChange={(p) => dispatch(setPhoneNumber(p))}
      />
    </div>
  );
};

export default SinglePerson;
