import React, { useState, useEffect, useRef } from "react";

import CustomOverlay from "../CustomOverlay";
import { formatSimplePrice } from "../helpers";
import "./listings-map.scss";

const ListingsMap = ({
  listings
}) => {
  const container = useRef(null);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (!listings?.length) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    listings.forEach((listing) => {
      if (!listing.lat || !listing.lng) {
        return;
      }

      bounds.extend({ lat: listing.lat, lng: listing.lng });
    });

    setBounds(bounds);
  }, [listings]);

  return (
    <div ref={container}>
      <Map
        className="tw-w-full tw-h-[300px] tw-rounded-[12px] listings-map"
        options={DefaultOptions}
        bounds={bounds}
      >
        {listings &&
          listings.length &&
          listings.map((listing, i) => (
            <CustomOverlay
              key={listing.blossorId}
              position={{ lat: listing.lat, lng: listing.lng }}
              pixelOffset={{ x: -32, y: -34 }}
              zIndex={ZIndexByStatus[listing.standardStatus]}
            >
              <ListingMarker
                price={listing.price}
                standardStatus={listing.standardStatus}
              />
            </CustomOverlay>
          ))}
      </Map>
    </div>
  );
};

export default ListingsMap;

const ZIndexByStatus = {
  "Active": 5,
  "Pending": 4,
  "Active Under Contract": 3,
  "Closed": 2,
  "Expired": 1
};

const ListingMarker = ({ price, standardStatus }) => {
  const styleByStatus = getStyleByStatus(standardStatus);
  const divStyle = `listings-map-marker tw-relative tw-flex tw-justify-center tw-px-8px tw-py-3px tw-items-center tw-rounded-666px tw-border tw-border-solid ${styleByStatus}`;

  return (
    <div className={divStyle}>
      <span className="tw-text-14px tw-font-sans tw-font-semibold">
        {formatSimplePrice(price) || "N/A"}
      </span>
    </div>
  );
};

const getStyleByStatus = standardStatus => {
  switch (standardStatus) {
    case "Active":
      return "tw-text-semantic-green-120 tw-bg-semantic-green-10 tw-border-semantic-green-50 active";
    case "Pending":
      return "tw-text-semantic-yellow-120 tw-bg-semantic-yellow-10 tw-border-semantic-yellow-50 pending";
    case "Active Under Contract":
      return "tw-text-semantic-orange-120 tw-bg-semantic-orange-10 tw-border-semantic-orange-50 contingent";
    case "Closed":
      return "tw-text-semantic-blue-120 tw-bg-semantic-blue-10 tw-border-semantic-blue-50 sold";
    default:
      return "tw-text-gray-75 tw-bg-gray-5 tw-border-gray-50 offmarket";
  }
};

const Map = ({ bounds, options, style, className, children }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (mapRef.current && !map) {
      const m = new window.google.maps.Map(mapRef.current, { ...options });

      setMap(m);
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (bounds && map) {
      // If we have only one bound, don't zoom in quite so far
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const coordinateManipulator = 0.01;
        bounds.extend({lat: bounds.getNorthEast().lat() + coordinateManipulator, lng: bounds.getNorthEast().lng() + coordinateManipulator});
        bounds.extend({lat: bounds.getSouthWest().lat() - coordinateManipulator, lng: bounds.getSouthWest().lng() - coordinateManipulator});
      }

      map.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <div ref={mapRef} className={className} style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </div>
  );
};

const DefaultOptions = {
  zoom: 11,
  center: { lat: 33.773, lng: -112.3321 },
  mapTypeId: "roadmap",
  panControl: false,
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: {
    position: 9, // Corresponds to RIGHT_BOTTOM
  },
  clickableIcons: false,
};
