import React from "react";
import { bool } from "prop-types";
import { tdpTimeline } from "../../../../shared/HOCs/withRedux";
import { ARE_EVENTS_LOADING } from "../../../../shared/reduxRoutes/tdpTimelinesRoutes";

import { Spinner } from "../../../../shared/v2/Icomoon";
import Events from "../Events";

const LoadingEvents = ({ areEventsLoading }) =>
  areEventsLoading ? (
    <div className="tw-w-full tw-h-[68px] tw-flex tw-items-center tw-justify-center">
      <Spinner size="xxxl" className="tw-animate-spin" />
    </div>
  ) : (
    <Events />
  );

export default tdpTimeline({
  WrappedComponent: LoadingEvents,
  states: ARE_EVENTS_LOADING,
});

LoadingEvents.propTypes = {
  areEventsLoading: bool,
};

LoadingEvents.defaultProps = {
  areEventsLoading: true,
};
