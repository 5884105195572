import React from "react";

import { HeartSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const FavPropertyActivity = ({ activity, onReadChange }) => {
  const { propertyStreetAddress, propertyPrice, beds, baths } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={HeartSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        <span>{`Address: ${propertyStreetAddress}`}</span>
        <PipedElements>
          <span>{`Price: ${propertyPrice}`}</span>
          {beds && <span>{`Bed: ${beds}`}</span>}
          {baths && <span>{`Bath: ${baths}`}</span>}
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

export default FavPropertyActivity;

FavPropertyActivity.propTypes = {
  ...activityCommonPropTypes,
};

FavPropertyActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
