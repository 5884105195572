/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UserSolidV6 = (props) => (
  <Icon {...props} name="User Solid V6">
    <path d="M8 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm1.584 1.5H6.415a5.417 5.417 0 0 0-5.416 5.416c0 .598.485 1.083 1.083 1.083h11.834a1.081 1.081 0 0 0 1.083-1.083A5.416 5.416 0 0 0 9.583 9.5z" />
  </Icon>
);

export default UserSolidV6;
