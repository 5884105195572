/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { string, bool, shape, number, func, arrayOf } from "prop-types";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import TextButton from "@shared/v2/TextButton";
import Avatar from "../../../../shared/v2/Avatar";
import Check from "../../../../shared/v2/Icomoon/Icons/Check";
import Tooltip from "../../../UI/ToolTip";
import IconButton from "../../../../shared/v2/IconButton";
import { submitSendEmail } from "../../../../LeadIndex/actions/default";
import {
  Envelope,
  Phone,
  Chat,
  Calendar,
  Edit,
  CloneRegularV6,
  AngleRightSolidV6,
  BuildingSolidV6,
} from "../../../../shared/v2/Icomoon";
import EllipsisIcon from "../../../../Transactions/components/icons/EllipsisIcon";
import Trash from "../../../../shared/v2/Icomoon/Icons/Trash";
import EditModal from "../EditModal";
import DeleteModal from "../DeleteModal";
import Other from "../../Interactions/Other";
import SmsModal from "../../../../SmsModal/components/SmsModal";
import Email from "../../Interactions/Email";
import { setLeadUuid } from "../../../../reducers/dialerReducer/dialerReducer";

const isActionElement = (rootEl, el) => {
  let currEl = el;
  while (currEl !== rootEl) {
    if (currEl === null || ["BUTTON", "A"].includes(currEl.tagName)) return true;
    currEl = currEl.parentElement;
  }
  return false;
};

// requires tw-group class on parent element
const CopyButton = ({ copyText }) => (
  <TextButton
    className="tw-flex tw-items-center tw-gap-[4px] tw-invisible group-hover:tw-visible"
    onClick={() => navigator.clipboard.writeText(copyText)}
  >
    <CloneRegularV6 size="l" /> Copy
  </TextButton>
);

CopyButton.propTypes = {
  copyText: string.isRequired,
};

const Person = ({
  person,
  title,
  isCollaborator,
  isClient,
  isAdmin,
  setAppointmentModalUser,
  setCallLeadUuid,
  currentUserId,
  team,
  messagingServiceUrl,
  listingId,
  gabbiAi,
}) => {
  const [tooltipEnabled, setToolTipEnabled] = useState(false);
  const [displayExpand, setDisplayExpand] = useState(false);
  const [viewPortal, setViewPortal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleSmsInteractModal, setToggleSmsInteractModal] = useState(false);
  const [toggleOtherInteractModal, setToggleOtherInteractModal] = useState(false);
  const [toggleEmailInteractModal, setToggleEmailInteractModal] = useState(false);
  const hasEditPeoplePrivilages = useMemo(
    () => isAdmin || team.some((member) => member.person.call_interaction.user_id === currentUserId),
    [team, currentUserId],
  );
  const [showDisabledTrashToolTip, setShowDisabledTrashToolTip] = useState(false);

  const phones = useMemo(
    () => person.person_dialable_phone_numbers?.map(viewPhoneFromFlat),
    [person.person_dialable_phone_numbers, currentUserId],
  );
  const primaryPhone = useMemo(
    () =>
      phones?.reduce((acc, phone) => {
        if (phone.isPrimary || (phone.position && phone.position < acc.position)) {
          return phone;
        }
        return acc;
      }, phones[0]),
    [phones],
  );

  const smsPerson = useMemo(
    () => ({
      data: {
        attributes: {
          first_name: person.first_name,
          last_name: person.last_name,
          primary_phone_number: person.phone_number,
          id: person.id,
          uuid: person.uuid,
        },
      },
    }),
    [person],
  );

  const closeModal = () => {
    setToggleEmailInteractModal(false);
  };

  return (
    <div
      className={`tw-mb-[8px] tw-mx-[-12px] tw-rounded-lg ${
        displayExpand
          ? "tw-h-auto tw-border-1px tw-border-solid tw-border-neutral-gray-10 tw-p-[12px]"
          : "tw-p-[13px]"
      }`}
      onMouseEnter={() => setDisplayExpand(true)}
      onMouseLeave={!viewPortal ? () => setDisplayExpand(false) : null}
      onClick={(e) => {
        if (!isActionElement(e.currentTarget, e.target)) setViewPortal((open) => !open);
      }}
      role="button"
      tabIndex="0"
      onKeyDown={(e) => {
        if (["Enter"].includes(e.key)) setViewPortal((open) => !open);
      }}
    >
      <div className="tw-flex tw-w-full tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center">
          <a href={`/people/${person.slug}?tab=details-tab`}>
            <Avatar
              size="medium"
              className="tw-mr-[10px] tw-min-w-[36px]"
              src={person.avatar}
              alt={person.name}
            />
          </a>
          <div>
            <div className="tw-break-all  tw-text-[14px] tw-font-semibold tw-leading-[20px] tw-tracking-normal tw-text-neutral-grey-75">
              {person.role?.title}
            </div>

            <div className="tw-text-14 tw-font-normal tw-leading-20px tw-tracking-normal tw-text-neutral-grey-50 tw-flex">
              {person.viewer_portal_access && (
                <>
                  <div className="tw-text-[10px] tw-font-normal tw-leading-3 tw-tracking-normal tw-mt-[15px]">
                    {tooltipEnabled && (
                      <Tooltip
                        className="tw-text-theme-text-button-color tw-uppercase focus:tw-text-theme-text-button-color-focus hover:tw-text-theme-text-button-color-hover tw-ml-[-65px] tw-h-[26px] tw-items-center tw--ml-[76px]"
                        tooltipEnabled={tooltipEnabled}
                        hoverText="Has Access to Viewer Portal"
                      />
                    )}
                  </div>

                  <div
                    onMouseEnter={() => setToolTipEnabled(true)}
                    onMouseLeave={() => setToolTipEnabled(false)}
                    className={`${
                      title === "Team" && "tw-hidden"
                    }   tw-border-solid tw-border-[2px] tw-rounded-full tw-w-[16px] tw-h-[16px] tw-flex tw-justify-center tw-items-center tw-mt-[3px] tw-mr-[5px]`}
                  >
                    <Check size="s" />
                  </div>
                </>
              )}
              <div className="tw-break-all tw-flex tw-justify-between tw-w-[191px]">
                <a
                  href={`/people/${person.slug}?tab=details-tab`}
                  className="tw-text-[14px] tw-font-normal  tw-leading-[20px] tw-tracking-normal !tw-text-neutral-gray-50 "
                >
                  {person.name}
                </a>
              </div>
            </div>
          </div>
        </div>
        <AngleRightSolidV6
          className={`tw-transition-all tw-text-theme-primary ${viewPortal ? "tw-rotate-90" : ""} ${
            displayExpand ? "" : "tw-hidden"
          }`}
        />
      </div>
      {viewPortal && (
        <div className="tw-mt-16px">
          <div className="tw-flex tw-gap-[6px] tw-mb-[16px]">
            <IconButton onClick={() => setToggleEmailInteractModal(true)} disabled={!person.email_address}>
              <Envelope size="l" />
            </IconButton>
            <IconButton onClick={() => setCallLeadUuid(person.uuid)} disabled={!person.phone_number}>
              <Phone size="l" />
            </IconButton>
            <IconButton onClick={() => setToggleSmsInteractModal(true)} disabled={!person.phone_number}>
              <Chat size="l" />
            </IconButton>
            <IconButton onClick={() => setAppointmentModalUser(person)}>
              <Calendar size="l" />
            </IconButton>
            <IconButton onClick={() => setToggleOtherInteractModal(true)}>
              <EllipsisIcon />
            </IconButton>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[4px]">
            {person.email_address && (
              <div className="tw-flex tw-gap-[8px] tw-group">
                <Envelope size="l" className="tw-mt-3px" />
                <div className="tw-break-all">{person.email_address}</div>
                <CopyButton copyText={person.email_address} />
              </div>
            )}
            {person.phone_number && (
              <div className="tw-flex tw-gap-[8px] tw-group">
                <Phone className="tw-mt-3px" size="l" />
                {person.phone_number}
                <CopyButton copyText={person.phone_number} />
              </div>
            )}
            {isCollaborator && person.company && (
              <div className="tw-flex tw-gap-[8px]">
                <BuildingSolidV6 size="l" />
                {person.company}
              </div>
            )}
          </div>
          {hasEditPeoplePrivilages && (
            <div className="tw-mt-[8px] tw-flex tw-gap-[8px] tw-justify-end">
              <IconButton size="small" onClick={() => setToggleEditModal(true)}>
                <Edit size="s" />
              </IconButton>
              <Tooltip
                hoverText="The primary agent cannot be removed from transaction. Please transfer the primary agent status to another team member in order to remove this user."
                className="tw-h-[93px] tw-translate-x-[145px] tw-translate-y-[-58px]"
                pointerClassName="!tw-mt-[86px]"
                tooltipEnabled={person.primary && showDisabledTrashToolTip}
              />
              <IconButton
                size="small"
                onMouseEnter={() => setShowDisabledTrashToolTip((prev) => !prev)}
                onMouseLeave={() => setShowDisabledTrashToolTip((prev) => !prev)}
                className={`tw-absolute !tw-bg-transparent tw-border-0 ${!person.primary && "tw-hidden"}`}
              />
              <IconButton size="small" onClick={() => setToggleDeleteModal(true)} disabled={person.primary}>
                <Trash size="s" />
              </IconButton>
            </div>
          )}
        </div>
      )}
      <EditModal
        show={toggleEditModal}
        onHide={setToggleEditModal}
        title={title === "Clients" || title === "Collaborators" ? title.slice(0, -1) : "Team Member"}
        personData={person}
        isCollaborator={isCollaborator}
        isClient={isClient}
        category={isClient ? "listing_clients" : isCollaborator ? "collaborators" : "team_members"}
      />
      <DeleteModal
        show={toggleDeleteModal}
        onHide={setToggleDeleteModal}
        listingPersonId={person.listing_person_id}
      />
      <Other show={toggleOtherInteractModal} onHide={setToggleOtherInteractModal} />
      <SmsModal
        gabbiAi={gabbiAi}
        modalOpen={toggleSmsInteractModal}
        closeModal={() => setToggleSmsInteractModal(false)}
        person={smsPerson}
        phone={primaryPhone}
        messagingServiceUrl={messagingServiceUrl}
        listingId={listingId}
      />
      <Email
        show={toggleEmailInteractModal}
        onHide={closeModal}
        recipientId={person.id}
        person={person}
        recipientEmailAddress={person.email_address}
        emailDetails={person.email_details}
        unsubscribedMessageTypes={person.unsubscribed_message_types}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.tdpDetailsReducer.isAdmin,
  interaction: state.tdpInteractionsReducer.interaction,
  team: state.tdpPeopleReducer.people?.team_members,
  currentUserId: state.tdpDetailsReducer.currentUser.id,
  messagingServiceUrl: state.tdpDetailsReducer.messagingServiceUrl,
  listingId: state.tdpDetailsReducer.headerDetails.listing_id,
  gabbiAi: state.tdpDetailsReducer.gabbiAi,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSendEmail: () => dispatch(submitSendEmail()),
  setCallLeadUuid: (uuid) => dispatch(setLeadUuid(uuid)),
});

Person.propTypes = {
  person: shape({
    id: number,
    avatar: string,
    name: string,
    role: shape({
      title: string,
      id: number,
    }),
    viewer_portal_access: bool,
    primary: bool,
  }),
  title: string,
  isCollaborator: bool,
  isClient: bool,
  isAdmin: bool,
  setAppointmentModalUser: func.isRequired,
  setCallLeadUuid: func.isRequired,
  currentUserId: string,
  team: arrayOf(shape({})),
  messagingServiceUrl: string.isRequired,
  listingId: number.isRequired,
  gabbiAi: bool.isRequired,
};

Person.defaultProps = {
  person: {
    id: 0,
    avatar: "",
    name: "",
    role: {
      title: "",
      id: 0,
    },
    viewer_portal_access: false,
    primary: false,
  },
  title: "",
  isCollaborator: false,
  isClient: false,
  isAdmin: false,
  currentUserId: null,
  team: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Person);
