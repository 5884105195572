import React from "react";

import Checkbox from "@shared/v2/Checkbox";

const FeatureSection = ({header, options, filters, toggleFeatureField}) => {
  return (
    <div className="tw-flex tw-flex-col">
      <span className="tw-text-14px tw-text-gray-50 tw-font-semibold tw-mb-12px">{header}</span>
      <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-y-3">
        {options.map(({label, search_category, search_value}) => (
          <div
            className="tw-w-1/3"
            key={`${search_category}-${search_value}`}
          >
            <Checkbox
              checked={!!(filters[search_category]?.fields?.some(s => s.term === search_value))}
              onChange={() => toggleFeatureField(search_category, search_value)}
              label={label}
            />
          </div>
        ))}
      </div>
    </div>

  );
};

export default FeatureSection;
