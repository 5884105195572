import { schemaGenerator } from "../utils";

const dictionary = {
  regular: {
    default: [
      "tw-border-theme-button-toggle-option",
      "tw-bg-theme-button-toggle-option",
      "tw-text-theme-button-toggle-option",
      "tw-font-normal",
    ],
    hover: [
      "hover:tw-border-theme-button-toggle-option-hover",
      "hover:tw-bg-theme-button-toggle-option-hover",
      "hover:tw-text-theme-button-toggle-option-hover",
      "hover:tw-font-normal",
    ],
    peerChecked: [
      "peer-checked:tw-border-theme-button-toggle-option-checked",
      "peer-checked:tw-bg-theme-button-toggle-option-checked",
      "peer-checked:tw-text-theme-button-toggle-option-checked",
      "peer-checked:tw-font-semibold",
    ],
    peerFocusVisible: ["peer-focus-visible:tw-outline-theme"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
