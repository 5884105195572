import React from "react";
import PropTypes from "prop-types";

/** Individual detail field for an action. E.g. "Priority", "Recurring", etc. */
const ActionDetailField = ({ children, name, value, hideUnless }) => {
  // This prop is used to improve code readability in components higher up the
  // tree: instead of writing conditionals everywhere, we can write a bunch of
  // `ActionDetailField`s side-by-side and simply hide them by passing in the
  // conditional to `hideUnless`.
  if (!hideUnless) {
    return null;
  }

  return (
    <span>
      <div className="tw-font-semibold">{name}</div>
      {children || value}
    </span>
  );
};

ActionDetailField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.node,
  hideUnless: PropTypes.bool,
};

ActionDetailField.defaultProps = {
  children: null,
  value: null,
  hideUnless: true,
};

export default ActionDetailField;
