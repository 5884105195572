const paddingDictionary = {
  "extra-large": "tw-pl-20px",
  large: "tw-pl-15px",
  medium: "tw-pl-10px",
  "medium-small": "tw-pl-10px",
  small: "tw-pl-5px",
};

export const paddingValue = (size) => paddingDictionary[size];

const avatarMarginDictionary = {
  "extra-large": "tw-ml-[-20px]",
  large: "tw-ml-[-15px]",
  medium: "tw-ml-[-10px]",
  "medium-small": "tw-ml-[-10px]",
  small: "tw-ml-[-5px]",
};

export const avatarMarginValue = (size) => avatarMarginDictionary[size];

export const sizeBasedClasses = (size) => paddingValue(size);
