/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneXmarkSolidV6 = (props) => (
  <Icon {...props} name="Phone Xmark Solid V6">
    <path d="m15.409 10.975-3.391-1.453a.972.972 0 0 0-1.14.28l-1.377 1.682A10.923 10.923 0 0 1 4.517 6.5l1.682-1.378a.978.978 0 0 0 .281-1.139L5.024.592A1 1 0 0 0 3.877.026L.756.753a.971.971 0 0 0-.757.951c0 7.884 6.412 14.297 14.297 14.297.458 0 .85-.312.952-.757l.727-3.15a.98.98 0 0 0-.566-1.118zm-4.531-9.036L11.939 3l-1.061 1.059a.75.75 0 1 0 1.061 1.061L13 4.059l1.061 1.061a.75.75 0 1 0 1.061-1.061l-1.061-1.061 1.061-1.061A.75.75 0 1 0 14.061.876l-1.088 1.062L11.912.877a.75.75 0 0 0-1.061 0 .776.776 0 0 0 .028 1.061z" />
  </Icon>
);

export default PhoneXmarkSolidV6;
