/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";

import { contingentText, recurringText } from "./helpers";
import { editTaskAsThunk } from "../actions/thunks";
import { defaultState } from "../ModalAddEditTask/constants";

import withFilters from "../HOCs/withFilters/withFilters";
import { ModalShowSection, ModalShowAvatar, ModalShowPriority, ModalShowName } from "./fractals";
import Modal from "../../../../shared/v2/Modal";
import { Trash, Edit } from "../../../../shared/v2/Icomoon";
import Button from "../../../../shared/v2/Button";
import IconButton from "../../../../shared/v2/IconButton";

const ModalShowTask = ({
  isTaskModalOpen,
  setModalAddTaskOpen,
  setTaskModalOpen,
  setTaskModalDelete,
  onEditTaskAsThunk,
  task,
  uuid,
  currentUser,
  taskFilters,
}) => {
  const {
    name,
    contingent,
    due_on,
    priority,
    type,
    recurringFrequency,
    recurringDayOfWeek,
    assigned_person,
    recurringEverySoManyDays,
    instructions,
    completed_at,
    note,
    id,
    assignedPlan,
  } = task;

  return (
    <Modal
      show={isTaskModalOpen}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px] tw-flex tw-flex-col tw-gap-[32px]"
      onHide={() => setTaskModalOpen((prev) => !prev)}
      closeButton
    >
      <Modal.Header title="Task Details" className="tw-flex tw-space-y-0 tw-justify-between tw-items-center">
        <div className="tw-flex tw-gap-[4px] !tw-mt-0">
          <IconButton
            schema="tertiary"
            onClick={() => {
              setTaskModalDelete((prev) => !prev);
              setTaskModalOpen((prev) => !prev);
            }}
          >
            <Trash size="l" />
          </IconButton>
          <IconButton
            schema="tertiary"
            onClick={() => {
              setModalAddTaskOpen((prev) => !prev);
              setTaskModalOpen((prev) => !prev);
            }}
          >
            <Edit size="l" />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-[16px] tw-w-[29%]">
            <ModalShowSection headerTxt="Date Due">
              {due_on || contingentText(contingent, due_on)}
            </ModalShowSection>
            <ModalShowSection headerTxt="Assignee" childrenClass="tw-flex">
              <ModalShowAvatar assignee={assigned_person} />
            </ModalShowSection>
            <ModalShowSection headerTxt="Priority">
              <ModalShowPriority priority={priority} />
            </ModalShowSection>
            <ModalShowSection headerTxt="Type">{type}</ModalShowSection>
            <ModalShowSection headerTxt="Recurrence">
              {recurringText(recurringFrequency, recurringDayOfWeek, recurringEverySoManyDays)}
            </ModalShowSection>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[16px] tw-w-[65%] tw-h-[314px] tw-overflow-auto">
            <ModalShowSection headerTxt="Task Details">
              <ModalShowName title={name} hasAssignedPlans={!!assignedPlan} />
            </ModalShowSection>
            {completed_at && <ModalShowSection headerTxt="Date Completed">{completed_at}</ModalShowSection>}
            {instructions && (
              <ModalShowSection
                headerTxt="Instructions"
                childrenClass="tw-whitespace-pre-wrap tw-leading-[20px]"
              >
                {instructions}
              </ModalShowSection>
            )}
            {note && (
              <ModalShowSection headerTxt="Notes" childrenClass="tw-whitespace-pre-wrap tw-leading-[20px]">
                {note}
              </ModalShowSection>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row">
        <Button
          className="tw-mr-auto tw-cursor-pointer"
          size="medium"
          schema="tertiary"
          onClick={() => setTaskModalOpen((prev) => !prev)}
        >
          CLOSE
        </Button>
        <Button
          size="medium"
          className="tw-cursor-pointer"
          schema="primary"
          disabled={!!completed_at}
          onClick={() => {
            onEditTaskAsThunk(
              {
                ...defaultState(uuid, task),
                completed: "true",
                completed_by_user_id: currentUser.uuid,
                id,
              },
              uuid,
              taskFilters,
            );
            setTaskModalOpen((prev) => !prev);
          }}
        >
          {`COMPLETE${completed_at ? "D" : ""} TASK`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpTasksReducer.uuid,
  currentUser: state.tdpDetailsReducer.currentUser,
});

const mapDispatchToProps = {
  onEditTaskAsThunk: editTaskAsThunk,
};

ModalShowTask.propTypes = {
  isTaskModalOpen: bool,
  uuid: string,
  currentUser: string.isRequired,
  setTaskModalOpen: func,
  setTaskModalDelete: func,
  onEditTaskAsThunk: func,
  task: shape({}),
  setModalAddTaskOpen: func,
  taskFilters: string,
};

ModalShowTask.defaultProps = {
  isTaskModalOpen: false,
  uuid: null,
  setTaskModalOpen: null,
  setTaskModalDelete: null,
  onEditTaskAsThunk: null,
  task: null,
  setModalAddTaskOpen: null,
  taskFilters: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(withFilters(ModalShowTask));
