import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Plus } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import colors from "../../../Reports/components/DashboardShared/helpers/colors";
import Tooltip from "../../../shared/v2/Tooltip/Tooltip";
import formatters from "../../../shared/formatters";
import { useTheme } from "../../../reducers/layoutReducer/selectors";

const compactFormatter = new Intl.NumberFormat("en", {
  notation: "compact",
  roundingPriority: "morePrecision",
  maximumFractionDigits: 1,
  maximumSignificantDigits: 2,
});

const LeadGenActivityCard = ({ label, goalValue, isButton, value, onClick, onViewHistory }) => {
  const isPlace = useTheme().name === "place-theme";
  const ref = useRef(null);
  const [_isHovering, setIsHovering] = useState(false);
  const [isEdge, setIsEdge] = useState(false);
  const isHovering = isButton && _isHovering;
  const showTooltip = String(value).length > 3 || String(goalValue).length > 3;
  const bgColor = isPlace
    ? "hover:tw-bg-semantic-blue-10 focus:tw-bg-semantic-blue-10"
    : "hover:tw-bg-brivity-blue-20 focus:tw-bg-brivity-blue-20";

  useEffect(() => {
    const el = ref.current;
    if (isButton || !el) return () => {};
    const handleResize = debounce(() => {
      const meetsEdge = Math.abs(el.offsetLeft + el.offsetWidth - el.parentElement.offsetWidth) <= 40; // padding
      setIsEdge(meetsEdge);
    }, 200);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return (
    // disabling eslint since this button can be a non-button as well
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      role={isButton ? "button" : ""}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={isButton ? 0 : undefined}
      className={`tw-relative tw-flex tw-flex-col tw-flex-1 tw-justify-center tw-items-center tw-gap-[12px] tw-p-[8px] tw-min-h-[110px] tw-min-w-[142px] tw-transition-all tw-duration-300 ${
        isButton
          ? `tw-shadow tw-rounded-[8px] hover:tw-scale-105 focus:tw-scale-110 ${bgColor}`
          : `${
              isEdge ? "" : "after:tw-content-['']"
            } after:tw-border-solid after:tw-border-r-0 after:tw-border-[1px] after:tw-border-neutral-gray-5 after:tw-absolute after:tw-top-0 after:tw-right-[-12px] after:tw-bottom-0`
      }`}
      onClick={isButton ? onClick : undefined}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onFocus={() => setIsHovering(true)}
      onBlur={() => setIsHovering(false)}
      onKeyDown={(e) => {
        if (e.key !== "Tab") e.preventDefault();
        if (["Enter", " "].includes(e.key) && isButton) onClick();
      }}
    >
      <Tooltip
        tooltipClassName={showTooltip ? "" : "tw-invisible"}
        placement="bottom"
        trigger={
          <div
            className={`${
              isButton ? "tw-whitespace-nowrap tw-text-[24px]" : "tw-text-[26px]"
            } tw-font-semibold`}
          >
            {isHovering ? (
              <Plus className="tw-text-neutral-gray-50" size="xl" />
            ) : (
              <>
                <span className={colors.theme === "place" ? "tw-text-semantic-blue-100" : "tw-text-teal"}>
                  {compactFormatter.format(value)}
                </span>{" "}
                <span className="tw-text-neutral-gray-50">/ {compactFormatter.format(goalValue)}</span>
              </>
            )}
          </div>
        }
        content={`${formatters.number(value)} / ${formatters.number(goalValue)} ${!isButton ? label : ""}`}
      />
      <div
        className={`tw-text-12d tw-font-semibold tw-text-center tw-text-neutral-gray-50 ${
          isHovering ? "tw-transition-all tw-duration-300 tw-mt-[-12px]" : ""
        }`}
      >
        {label}
      </div>

      {onViewHistory && (
        <div
          className={`tw-absolute tw-overflow-hidden tw-inset-x-0 ${
            isHovering ? "tw-h-[24px]" : "tw-h-0"
          } tw-bottom-[-24px] tw-flex tw-justify-end`}
          onFocus={() => setIsHovering(true)}
          onBlur={() => setIsHovering(false)}
        >
          <TextButton
            onClick={(e) => {
              e.stopPropagation();
              onViewHistory();
            }}
            onKeyDown={(e) => {
              if (![" ", "Enter"].includes(e.key)) return;
              e.stopPropagation();
              onViewHistory();
            }}
          >
            View History
          </TextButton>
        </div>
      )}
    </div>
  );
};

LeadGenActivityCard.propTypes = {
  label: PropTypes.string,
  goalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onViewHistory: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

LeadGenActivityCard.defaultProps = {
  label: "",
  goalValue: null,
  isButton: false,
  onViewHistory: undefined,
  value: null,
};

export default LeadGenActivityCard;
