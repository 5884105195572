import React from "react";
import { Panel } from "@shared/v1";
import { Expenses } from "../Expenses";
import { kebabCase } from "lodash";
import Avatar from "@shared/v2/Avatar";

export const Agent = (props) => {
  const { idx, agent } = props;

  const { id, name, custom_agent_expenses_attributes: expenses } = agent;
  const avatar = /^http/.test(agent.avatar) ? agent.avatar : null;

  return (
    <div data-cy={`financials-settings-agent-wrapper-${kebabCase(name)}`} id={`accordion${id}`}>
      <Panel className="tw-border-gray" deprecatedOverrideMarginClasses="tw-m-0">
        <Panel.Heading className="tw-bg-[#fff]" deprecatedOverrideBorderClasses="tw-border-0">
          <Panel.Title
            className="title tw-text-brand-primary"
            deprecatedOverrideTextClasses="tw-text-[15px] tw-leading-[1.1]"
          >
            <Panel.Trigger
              className="tw-no-underline hover:tw-no-underline active:tw-no-underline focus:tw-no-underline tw-relative tw-flex tw-items-center tw-gap-[8px]"
              data-parent={`#accordion${id}`}
              id={`agent${id}`}
              data-cy={`financials-settings-view-accordion-${kebabCase(name)}`}
            >
              <Avatar src={avatar} alt={name} size="medium" />
              {name}
            </Panel.Trigger>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse id={`agent${id}`}>
          <Panel.Body
            className="tw-bg-[#fbfafa] tw-border-0 tw-border-t tw-border-solid tw-border-[#ddd]"
            deprecatedOverridePaddingClasses="tw-p-0"
          >
            <Expenses agent={agent} agentPosition={idx} expenses={expenses} />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </div>
  );
};
