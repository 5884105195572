import React from "react";
import { bool, func, string } from "prop-types";
import AiAssistant from "@shared/AiAssistant";

import { displayHidden } from "../helpers";
import useTextInputHandlr from "./hooks";
import { PAYLOAD_DEFAULT, payloadPropTypes } from "../propTypes";

import TextInput from "../../../../shared/v2/TextInput";
import { Trash } from "../../../../shared/v2/Icomoon";

const ModalAddEditInput = ({ show, toggleShow, payloadHandlrs, btnTxt, payload }) => {
  const { textInputHandlr, trashHandlr } = useTextInputHandlr(payloadHandlrs, btnTxt, toggleShow);

  return (
    <div className={`tw-mt-24px ${displayHidden(show)}`}>
      <div className="tw-flex tw-justify-between tw-items-end tw-mr-[40px] tw-mb-[8px]">
        <div className="tw-text-neutral-gray-50 tw-text-14px tw-font-semibold">{btnTxt}</div>
        {btnTxt === "Instructions" && (
          <div className="tw-flex tw-justify-start tw-mt-[8px]">
            <AiAssistant
              textareaId="tdp-instructions-input"
              messageType="Generic"
              onInsertClick={(text) => textInputHandlr({ persist: () => {}, target: { value: text } })}
            />
          </div>
        )}
      </div>
      <div className="tw-flex tw-items-center">
        <div className="tw-w-full">
          <TextInput
            id="tdp-instructions-input"
            className="tw-grow"
            onChange={textInputHandlr}
            value={payload[btnTxt.toLowerCase()]}
            multiline
          />
        </div>
        <button
          aria-label="delete"
          type="button"
          onClick={trashHandlr}
          className="tw-w-36px tw-h-36px tw-border-1px tw-rounded-[2px] tw-border-solid tw-border-neutral-gray-15 tw-bg-[#F5F7F7] tw-flex tw-items-center tw-justify-center tw-ml-[4px] tw-cursor-pointer"
        >
          <Trash size="l" />
        </button>
      </div>
    </div>
  );
};

ModalAddEditInput.propTypes = {
  show: bool,
  toggleShow: func,
  payloadHandlrs: func,
  btnTxt: string,
  payload: payloadPropTypes,
};

ModalAddEditInput.defaultProps = {
  show: false,
  toggleShow: null,
  payloadHandlrs: null,
  btnTxt: null,
  payload: PAYLOAD_DEFAULT,
};

export default ModalAddEditInput;
