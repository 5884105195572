import React, { useState } from "react";
import moment from "moment/moment";
import { connect } from "react-redux";
import { arrayOf, bool, func, string } from "prop-types";

import { setModalBulkRescheduleTaskOpen } from "../actions/creators";
import { bulkEditTasksAsThunk } from "../actions/thunks";
import withFilters from "../HOCs/withFilters";

import Modal from "../../../../shared/v2/Modal";
import DatePicker from "../../../../shared/v2/DatePicker/DatePicker";
import Button from "../../../../shared/v2/Button";

const ModalBulkRescheduleTask = ({
  isModalRescheduleTaskOpen,
  onSetModalRescheduleTaskOpen,
  onBulkEditTasksAsThunk,
  selectedTasks,
  listingUuid,
  taskFilters,
}) => {
  const [rescheduleDate, setRescheduleDate] = useState(moment(new Date()).format("MM/DD/YYYY"));

  return (
    <Modal
      show={isModalRescheduleTaskOpen}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[496px]"
      onHide={onSetModalRescheduleTaskOpen}
      closeButton
    >
      <Modal.Header title={`Reschedule ${selectedTasks.length} Task`} className="tw-mb-16px" closeButton />
      <Modal.Body className="tw-mb-32px tw-max-h-[70vh]">
        <div className="tw-mb-32px tw-text-12px tw-text-neutral-gray-75">
          Your client does not see when you reschedule a task. Select a new due date below to reschedule.
        </div>
        <div className="tw-font-semibold tw-text-14px tw-text-neutral-gray-50 tw-mb-8px">
          Reschedule Tasks To{" "}
        </div>
        <DatePicker onChange={setRescheduleDate} value={rescheduleDate} position="bottom-left" />
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-row">
        <Button className="tw-mr-auto" size="medium" schema="tertiary" onClick={onSetModalRescheduleTaskOpen}>
          CANCEL
        </Button>
        <Button
          size="medium"
          schema="primary"
          onClick={() => {
            onSetModalRescheduleTaskOpen();
            onBulkEditTasksAsThunk(selectedTasks, listingUuid, taskFilters, {
              due_on: moment(rescheduleDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
            });
          }}
        >
          RESCHEDULE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isModalRescheduleTaskOpen: state.tdpTasksReducer.isModalRescheduleTaskOpen,
  selectedTasks: state.tdpTasksReducer.selectedTasks,
  listingUuid: state.tdpTasksReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onSetModalRescheduleTaskOpen: () => dispatch(setModalBulkRescheduleTaskOpen()),
  onBulkEditTasksAsThunk: (selectedTasks, listingUuid, filtered, payload) =>
    dispatch(bulkEditTasksAsThunk(selectedTasks, listingUuid, filtered, payload)),
});

ModalBulkRescheduleTask.propTypes = {
  isModalRescheduleTaskOpen: bool,
  selectedTasks: arrayOf(string),
  onSetModalRescheduleTaskOpen: func,
  onBulkEditTasksAsThunk: func,
  listingUuid: string,
  taskFilters: string,
};

ModalBulkRescheduleTask.defaultProps = {
  isModalRescheduleTaskOpen: false,
  selectedTasks: [],
  onSetModalRescheduleTaskOpen: null,
  onBulkEditTasksAsThunk: null,
  listingUuid: null,
  taskFilters: string,
};

export default connect(mapStateToProps, mapDispatchToProps)(withFilters(ModalBulkRescheduleTask));
