import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

/**
 * Stop-gap, general use loading indicator overlay. For when the design team
 * does not specify what the loading state should look like for a given
 * component/page.
 */
const LoadingOverlay = ({ children, isLoading }) => (
  <div className="tw-relative">
    {isLoading && (
      <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-bg-white tw-bg-opacity-50 tw-z-50">
        <div className="tw-text-18px" role="status">
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
        </div>
      </div>
    )}

    {children}
  </div>
);
LoadingOverlay.propTypes = { children: PropTypes.node, isLoading: PropTypes.bool.isRequired };
LoadingOverlay.defaultProps = { children: null };

export default LoadingOverlay;
