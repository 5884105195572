import React from "react";

import { DesktopSolidV6 } from "@shared/v2/Icomoon";

import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import ActivitiesContainer from "./ActivitiesContainer";

// TODO: this one needs to be mapped and updated, might actually just be ReturnSiteActivity
const RetargetCampainActivity = ({ activity, onReadChange }) => {
  const { text, tags } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={DesktopSolidV6}
      title={activity.title}
    >
      <div className={STYLES.contentContainer}>
        {text && <span>{text}</span>}
        {tags && <span>Tags: {tags}</span>}
      </div>
    </ActivitiesContainer>
  );
};

export default RetargetCampainActivity;

RetargetCampainActivity.propTypes = {
  ...activityCommonPropTypes,
};

RetargetCampainActivity.defaultProps = {
  ...activityCommonDefaultProps,
};
