import curriedNamespaceAction from "../../../../reducers/helpers";

const REDUCER_TDP_PEOPLE = "tdpPeople";
const wrap = curriedNamespaceAction(REDUCER_TDP_PEOPLE);

export const saveAllPeople = (details, uuid) => wrap({ type: "SAVE_ALL_PEOPLE", details, uuid });

export const postPerson = (person, uuid, category) => wrap({ type: "POST_PERSON", person, uuid, category });

export const patchPerson = (id, category, person) => wrap({ type: "PATH_PERSON", id, category, person });

export const deletePerson = (personId, category) => wrap({ type: "DELETE_PERSON", personId, category });

export const getAvailablePeople = (availablePeople) =>
  wrap({ type: "GET_AVAILABLE_PEOPLE", availablePeople });

export const errorHandler = (error) => wrap({ type: "ERROR_HANDLER", error });

export const closeModal = (bool) => wrap({ type: "CLOSE_MODAL", bool });

export const clearError = () => wrap({ type: "CLEAR_ERROR" });
