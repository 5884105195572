/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Edit = (props) => (
  <Icon {...props} name="Edit">
    <path d="m15.56 4.438-1.441 1.441a.375.375 0 0 1-.531 0L10.119 2.41a.375.375 0 0 1 0-.531L11.56.438a1.504 1.504 0 0 1 2.122 0l1.878 1.878a1.499 1.499 0 0 1 0 2.122zM8.881 3.116.674 11.323.011 15.12a.751.751 0 0 0 .869.869l3.797-.666 8.207-8.207a.375.375 0 0 0 0-.531L9.415 3.116a.378.378 0 0 0-.534 0zM3.877 10.62a.436.436 0 0 1 0-.619L8.69 5.188c.172-.172.447-.172.619 0s.172.447 0 .619L4.496 10.62a.436.436 0 0 1-.619 0zm-1.128 2.628h1.5v1.135l-2.016.353-.972-.972.353-2.016h1.135v1.5z" />
  </Icon>
);

export default Edit;
