import React from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Tooltip from "@shared/v2/Tooltip";
import ModifyDate from "./ModifyDate";
import { CircleInfoSolidV6 } from "../../../../shared/v2/Icomoon";

const ReferralExpiration = ({ expirationDate }) => (
  <div className="tw-flex tw-justify-between tw-items-center tw-gap-[2px] tw-px-[12px] tw-min-h-[44px] tw-h-[44px] tw-rounded tw-bg-tinted-gray-50 tw-text-gray-75 tw-text-[14px] tw-font-semibold tw-leading-[20px]">
    <span>
      Expiration
      <Tooltip
        className="tw-ml-[6px]"
        placement="bottom"
        trigger={<CircleInfoSolidV6 className="tw-text-teal" size="m" />}
        content="Date the referral agreement is effective through"
      />
    </span>
    <span className="tw-float-right">
      <ModifyDate date={expirationDate} />
    </span>
  </div>
);

ReferralExpiration.propTypes = {
  expirationDate: Proptypes.shape({ id: Proptypes.string, name: Proptypes.string, date: Proptypes.string }),
};
ReferralExpiration.defaultProps = {
  expirationDate: null,
};

const mapStateToProps = (state) => ({
  expirationDate: state.tdpDatesReducer.dates.expiration,
});
export default connect(mapStateToProps)(ReferralExpiration);
