import React, { useState, useMemo, useEffect } from "react";

import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import Checkbox from "../../Transactions/components/shared/Checkbox";
import Avatar from "@shared/v2/Avatar";

const durationOptions = [
  {
    label: "Less than 30 seconds",
    value: "up_to_30_s",
  },
  {
    label: "More than 30 seconds",
    value: "more_than_30_s",
  },
  {
    label: "More than 5 minutes",
    value: "more_than_5_m",
  },
];

const outcomeOptions = [
  {
    label: "Talked",
    value: "completed",
  },
  {
    label: "Missed",
    value: "no_answer",
  },
  // {
  //   label: "Voicemail",
  //   value: "machine_start"
  // }
];

const Option = ({ componentProps, children }) => (
  <Dropdown.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="!tw-flex tw-gap-[8px] tw-items-center tw-group"
    preserveStyle
  >
    <Checkbox checked={componentProps.isSelected} readOnly />
    {children}
  </Dropdown.Option>
);

const formatOptionLabel = ({ name, number, avatar }) => (
  <div className="tw-flex tw-items-center tw-gap-8px tw-whitespace-nowrap">
    <Avatar src={avatar?.length > 3 ? avatar : null} alt={name} />
    <span className="tw-overflow-ellipsis tw-font-normal">
      {name} {number ? `\u2014 ${number}` : ""}
    </span>
  </div>
);

const RemovableBubble = ({ value, onRemove }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-py-4px tw-pl-8px tw-pr-10px tw-border tw-border-solid tw-border-gray-light tw-rounded-16px tw-bg-gray-light tw-mx-4px tw-mb-4px">
      <span className="tw-font-bold tw-text-11d tw-text-gray-75 tw-mr-8px">{value}</span>
      <i onClick={onRemove} className="fa fa-remove tw-text-12d tw-cursor-pointer" />
    </div>
  );
};

const createAgentOption = (agent) => ({
  name: agent.full_name,
  label: agent.full_name,
  value: agent.id,
  avatar: agent.avatar_url,
  number: agent.number,
});

const LogFilters = ({ filters, agents, onApply, onClose }) => {
  const [duration, setDuration] = useState(filters.duration || "");
  const [outcome, setOutcome] = useState(filters.outcome || "");
  const [selectedAgents, setSelectedAgents] = useState(filters.agents || []);

  const agentOptions = useMemo(() => agents.map(createAgentOption), [agents]);

  const filterListOfOptions = (option, arr, func) => {
    func(arr?.filter((a) => a.value !== option.value) || []);
  };

  const applyFilters = () => {
    const cleaned = {
      duration,
      outcome,
      agents: selectedAgents,
    };

    onApply(cleaned);
  };

  const clearFilters = () => {
    setDuration("");
    setOutcome("");
    setSelectedAgents([]);
  };

  return (
    <Modal
      show
      onHide={onClose}
      dialogClassName="tw-overflow-y-auto tw-max-h-full"
      contentSize="medium"
      contentClassName="tw-m-auto !tw-p-0"
    >
      <Modal.Header className="tw-px-32px tw-pt-32px" title="Tracking Number Filters" closeButton>
        <div className="tw-flex tw-flex-row tw-w-full !tw-mt-32px !tw-mb-8px">
          <span className="tw-w-full tw-h-1px tw-bg-gray-10" />
        </div>
      </Modal.Header>
      <Modal.Body className="tw-overflow-y-scroll tw-px-40px tw-flex tw-flex-col tw-gap-24px">
        <div className="tw-flex tw-flex-col tw-gap-8px">
          <span className="tw-text-gray-50 tw-font-semibold">Call Duration</span>
          <Dropdown
            placeholder="No filter selected"
            value={duration}
            options={durationOptions}
            onChange={setDuration}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-8px">
          <span className="tw-text-gray-50 tw-font-semibold">Call Outcome</span>
          <Dropdown
            placeholder="No filter selected"
            value={outcome}
            options={outcomeOptions}
            onChange={setOutcome}
          />
        </div>
        <div className={`tw-flex tw-flex-col tw-gap-8px ${selectedAgents.length > 0 ? "" : "tw-mb-8px"}`}>
          <span className="tw-text-gray-50 tw-font-semibold">Agent Answered</span>
          <Dropdown
            placeholder="Search or select agent"
            isMulti
            value={selectedAgents}
            options={agentOptions}
            onChange={setSelectedAgents}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            multiShouldRenderBelowSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            isSearchable
            formatOptionLabel={formatOptionLabel}
            components={{ Option }}
            menuShouldComeToFront
          />
        </div>
        {selectedAgents.length > 0 && (
          <div className="tw-flex tw-flex-row tw-gap-4px tw-flex-wrap tw-justify-start tw-mb-4px">
            {selectedAgents.map((agent) => (
              <RemovableBubble
                value={agent.name}
                onRemove={() => filterListOfOptions(agent, selectedAgents, setSelectedAgents)}
              />
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="tw-mx-32px tw-mb-32px">
        <div className="tw-flex tw-flex-row tw-w-full tw-mb-32px">
          <span className="tw-w-full tw-h-1px tw-bg-gray-10" />
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <button
            className="tw-mr-8px tw-bg-transparent tw-font-bold tw-ml-auto tw-border-0 tw-cursor-pointer tw-text-center tw-upppercase tw-text-14px tw-text-theme-primary"
            onClick={clearFilters}
          >
            Clear Filters
          </button>
          <Button size="medium" schema="primary" onClick={applyFilters}>
            Filter
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LogFilters;
