import axios from "axios";
import axiosRetry from "axios-retry";

// Creates a new instance to avoid global cascade of retries
const client = axios.create();

axiosRetry(client, {
  retryDelay: (retryCount) => {
    return retryCount * 500;
  },
  retries: 2,
});

const api = {
  index: (phoneNumbers, signal) => {
    const commaSeparatedNumbers = phoneNumbers.join(",");
    const path = "/api/v4/dnc_phones";
    return client.get(path, { params: { phone_numbers: commaSeparatedNumbers }, signal });
  },
  isDnc: (phoneNumber, dncStatuses) => {
    return Boolean(dncStatuses[phoneNumber]);
  },
};

export default api;
