import React, { useMemo } from "react";
import { Provider } from "react-redux";
import Users from "./Users";

export default () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <Users />
    </Provider>
  );
};
