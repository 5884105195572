// At this point the scaled values are unreadable
const MIN_HYPOTENUSE = 375;
// Predefined max in case the chart was initially rendered at small width
const MAX_HYPOTENUSE = 696;

const diffFormat = new Intl.NumberFormat("en-US", {
  signDisplay: "exceptZero",
  style: "decimal",
  maximumFractionDigits: 1,
});

// TODO: make this generic
export const scaleValue = (ctx, option, origValue) => {
  const { chart } = ctx;
  if (option === "paddingBottom" && !chart.chartArea) {
    return origValue;
  }
  if (!chart.chartArea) return 0;
  const { width, height } = chart.chartArea;
  const hypo = Math.sqrt(width ** 2 + height ** 2);
  let size;
  let value;

  // don't render on really small widths
  if (hypo < MIN_HYPOTENUSE) {
    value = 0;
  } else if (!ctx.size) {
    size = MAX_HYPOTENUSE;
    ctx.size = size;
    value = origValue;
  } else {
    size = ctx.size;
    if (/yMin|yMax/.test(option)) value = (size / hypo) * origValue;
    else value = (hypo / size) * origValue;
  }
  if (option === "font") {
    return { size: value };
  }
  return value;
};

export const generateDiffBox = (value, i) => {
  let backgroundColor = "#F5F5F5";
  let color = "#666666";
  if (value > 0) {
    backgroundColor = "#EDFAF1";
    color = "#36B25F";
  } else if (value < 0) {
    backgroundColor = "#FAE6E6";
    color = "#E5342E";
  }

  return {
    type: "box",
    backgroundColor,
    borderRadius: 3,
    borderWidth: 0,
    label: {
      color,
      display: true,
      content: diffFormat.format(value).replace("0.", "."),
      font: (ctx) => scaleValue(ctx, "font", 12),
    },
    position: {
      x: "center",
      y: "start",
    },
    xMin: i - 0.3,
    xMax: i + 0.3,
    yMax: (ctx) => scaleValue(ctx, "yMax", ctx.chart.scales.y.max * -0.12),
    yMin: (ctx) => scaleValue(ctx, "yMin", ctx.chart.scales.y.max * -0.19),
  };
};
