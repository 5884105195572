import React, { useState } from "react";
import PropTypes from "prop-types";
import MarketReportPresenceSelect from "./MarketReportPresenceSelect";
import LastViewedMarketReportSelect from "./LastViewedMarketReportSelect";

const MarketReportFilters = ({
  lastViewedMarketReportRangeOptions,
  previousSelectedReportPresence,
  previousSelectedRange,
}) => {
  const [isLastMarketReportDropdownDisabled, setIsLastMarketReportDropdownDisabled] = useState(false);

  return (
    <>
      <MarketReportPresenceSelect
        previousSelectedReportPresence={previousSelectedReportPresence}
        setIsLastMarketReportDropdownDisabled={setIsLastMarketReportDropdownDisabled}
      />
      <LastViewedMarketReportSelect
        isDisabled={isLastMarketReportDropdownDisabled}
        lastViewedMarketReportRangeOptions={lastViewedMarketReportRangeOptions}
        previousSelectedRange={previousSelectedRange}
      />
      <hr />
    </>
  );
};

MarketReportFilters.propTypes = {
  lastViewedMarketReportRangeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  previousSelectedReportPresence: PropTypes.string.isRequired,
  previousSelectedRange: PropTypes.string.isRequired,
};

export default MarketReportFilters;
