import React from "react";
import { bool } from "prop-types";
import { connect } from "react-redux";

import withTaskSelectionMode from "../HOCs/withTaskSelectionMode";

import TaskSelectAll from "../TaskSelectAll";

const Label = ({ isTasksSelectionMode, isCompletedTab }) => (
  <div className="tw-text-neutral-gray-50 tw-font-semibold tw-flex tw-mb-12px tw-text-12px">
    {isTasksSelectionMode ? <TaskSelectAll /> : <div>Tasks</div>}
    <div className="tw-ml-auto tw-mr-[25px] tw-w-[32px]">Who</div>
    <div className="tw-mr-[25px] tw-w-[43px]">Priority</div>
    {isCompletedTab ? (
      <div className="tw-mr-[12px] tw-ml-[12px] tw-w-[54px]">Date</div>
    ) : (
      <div className="tw-mr-[25px] tw-w-[54px]">Due Date</div>
    )}
    <div className="tw-w-[49px]">Visibility</div>
  </div>
);

const mapStateToProps = (state) => ({
  isCompletedTab: state.tdpTasksReducer.primaryActiveList.indexOf("completed_at") > -1,
});

Label.propTypes = {
  isTasksSelectionMode: bool,
  isCompletedTab: bool,
};

Label.defaultProps = {
  isTasksSelectionMode: false,
  isCompletedTab: false,
};

export default connect(mapStateToProps)(withTaskSelectionMode(Label));
