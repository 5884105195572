/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import FieldLabel from "@shared/v2/FieldLabel";
import Typography from "@shared/v2/Typography";

const OptionsSection = ({ label, children, copy, isRequired }) => (
  <div className="tw-flex tw-flex-col tw-gap-[8px] tw-bg-semantic-blue-5 tw-p-[16px] tw-rounded-[4px] tw-border tw-border-solid tw-border-semantic-blue-120">
    <FieldLabel className="tw-text-semantic-blue-120 tw-mb-[4px]" label={label} isRequired={isRequired} />
    {copy && <Typography className="tw-text-semantic-blue-120">{copy}</Typography>}
    {children}
  </div>
);

OptionsSection.propTypes = {
  label: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

OptionsSection.defaultProps = {
  children: null,
};

export default OptionsSection;
