import React from "react";
import PropTypes from "prop-types";
import { XmarkSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";

const HomeEstimateValue = ({ header, value, containerClassName, onClose }) => (
  <div
    className={`tw-border-solid tw-border-[1px] tw-border-gray-30 tw-py-[16px] tw-rounded-[4px] tw-text-gray-75 tw-flex tw-flex-col tw-items-center tw-relative tw-w-[168px] ${containerClassName}`}
  >
    {onClose && (
      <IconButton
        size="xsmall"
        schema="transparent"
        onClick={onClose}
        className="tw-absolute tw-right-8px tw-top-8px"
      >
        <XmarkSolidV6 />
      </IconButton>
    )}

    <div className="tw-text-14d tw-font-semibold">{header}</div>
    <div className="tw-text-18d tw-font-normal">{value}</div>
  </div>
);

export default HomeEstimateValue;

HomeEstimateValue.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  containerClassName: PropTypes.string,
  onClose: PropTypes.func,
};

HomeEstimateValue.defaultProps = {
  header: "Automated Est.",
  value: "$0",
  containerClassName: "",
  onClose: null,
};
