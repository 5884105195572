import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Button, FormGroup, Col, Checkbox, Dropdown, TextArea, CancelButton } from "../../shared/v1";
import * as autoPlanActionCreators from "../actions/autoPlanActionCreators";
import { canBeContingent } from "./helpers";
import {
  AssignTo,
  Delay,
  DelayAndScheduleLabel,
  ScheduleSelector,
  RunTime,
  Schedule,
} from "./ActionFormHelpers";
import AiAssistant from "../../shared/AiAssistant";

const defaultText = {
  template_body: "",
  type: "Text",
  delay: 0,
  delay_unit: "seconds",
  contingent: false,
  delay_factor: 1,
  schedule_type: "computed_time",
};

class TextForm extends React.Component {
  insertPlaceholder = (opt) => {
    const { onChange } = this.props;

    // eslint-disable-next-line react/no-string-refs
    const { textMessageInput } = this.refs;
    const { selectionStart } = textMessageInput;
    const { selectionEnd } = textMessageInput;

    const prefix = textMessageInput.value.substring(0, selectionStart);
    const suffix = textMessageInput.value.substring(selectionEnd, textMessageInput.value.length);
    const updatedValue = prefix + opt.value + suffix;

    onChange({ template_body: updatedValue });
  };

  placeholderOptions() {
    const { placeholders } = this.props;

    return placeholders.map((placeholder) => ({ label: placeholder.text, value: placeholder.value }));
  }

  render() {
    const {
      action,
      actionsPath,
      assignedForOptions,
      assignedToOptions,
      autoPlanPath,
      handleDelayUnitChange,
      handleScheduleTypeChange,
      hideAssignedFor,
      isSubmitting,
      milestoneOptions,
      onActionSubmit,
      onAssignedForChange,
      onAssignedToChange,
      onChange,
      onCloseAction,
      triggers,
    } = this.props;
    const text = { ...defaultText, ...action };
    const assignedToValue = text.assigned_to_id || text.assigned_to_role;
    const assignedForValue = text.assigned_for_id || text.assigned_for_role;

    return (
      <form>
        <FormGroup horizontal>
          <Col size={6}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Text</label>
          </Col>
          <Col size={6}>
            <div className="tw-float-right tw-w-[274px]">
              <Dropdown
                onUpdate={(opt) => this.insertPlaceholder(opt)}
                placeholder="Insert placeholder"
                isSearchable
                isClearable={false}
                // TODO: fix this, _especially_ when/if upgrading React
                // eslint-disable-next-line react/no-string-refs
                onClose={() => this.refs.textMessageInput.focus()}
                options={this.placeholderOptions()}
                variant="transparent"
              />
            </div>
          </Col>
          <Col size={12}>
            <TextArea
              size="auto"
              id="autoplans-sms-input"
              name="template_body"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              // eslint-disable-next-line react/no-string-refs
              ref="textMessageInput"
              className="tw-resize-none"
              rows="7"
              onChange={(e) => onChange({ [e.target.name]: e.target.value })}
              value={text.template_body}
            />
            <div className="tw-flex tw-justify-end tw-mt-[12px]">
              <AiAssistant
                textareaId="autoplans-sms-input"
                messageType="SMS"
                onInsertClick={(value) => onChange({ template_body: value })}
              />
            </div>
          </Col>
        </FormGroup>

        <FormGroup horizontal>
          <ScheduleSelector scheduleType={text.schedule_type} onChange={handleScheduleTypeChange} />

          <DelayAndScheduleLabel
            delayLabel="Send"
            scheduleLabel={text.schedule_type === "computed_time" && "After"}
          />

          <Delay
            delayFactor={text.delay_factor}
            delayOnChange={onChange}
            delayValue={text.delay}
            delayUnitValue={text.delay_unit}
            delayUnitOnChange={handleDelayUnitChange}
            scheduleType={text.schedule_type}
          />

          <Schedule
            type={text.schedule_type}
            delayFactor={text.delay_factor}
            delay={text.delay}
            onChange={onChange}
            parentActionId={text.parent_action_id}
            triggers={triggers}
            milestoneOptions={milestoneOptions}
            milestoneId={text.milestone_id}
          />
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <Checkbox
              className="tw-pt-7px"
              labelClassName="tw-text-gray-base tw-text-[14px] tw-select-none"
              deprecatedOverrideCheckboxMarginClasses="!tw-mt-0 !tw--ml-20px"
              name="contigent"
              inline
              onChange={() => onChange({ contingent: !text.contingent })}
              disabled={!canBeContingent(text.schedule_type, triggers)}
              checked={text.contingent}
            >
              Make Contingent (Due time auto-updates based on when the previous action is completed)
            </Checkbox>
          </Col>
        </FormGroup>

        {(text.delay_unit === "days" || text.schedule_type === "event") && (
          <FormGroup horizontal>
            <RunTime label="Send At" value={text.run_time} onChange={onChange} />
          </FormGroup>
        )}

        <FormGroup horizontal>
          <AssignTo
            label="Send From"
            value={assignedToValue}
            onChange={onAssignedToChange}
            options={assignedToOptions}
          />
          {!hideAssignedFor && (
            <AssignTo
              label="Send To"
              value={assignedForValue}
              onChange={onAssignedForChange}
              options={assignedForOptions}
            />
          )}
        </FormGroup>

        <FormGroup horizontal>
          <Col size={12}>
            <CancelButton size="small" className="modal-cancel" onClick={onCloseAction} />
            {isSubmitting ? (
              <Button size="small" variant="info" className="modal-submit tw-float-right" disabled>
                Saving..
              </Button>
            ) : (
              <Button
                size="small"
                variant="info"
                className="modal-submit tw-float-right"
                onClick={() => onActionSubmit(text, actionsPath, autoPlanPath)}
              >
                Save
              </Button>
            )}
          </Col>
        </FormGroup>
      </form>
    );
  }
}

TextForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  actionsPath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedForOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedToOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  autoPlanPath: PropTypes.string.isRequired,
  handleDelayUnitChange: PropTypes.func.isRequired,
  handleScheduleTypeChange: PropTypes.func.isRequired,
  hideAssignedFor: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  milestoneOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onActionSubmit: PropTypes.func.isRequired,
  onAssignedForChange: PropTypes.func.isRequired,
  onAssignedToChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  placeholders: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  triggers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  isSubmitting: state.isSubmitting,
});

const mapDispatchToProps = (dispatch) => ({
  autoPlanActions: bindActionCreators(autoPlanActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextForm);
