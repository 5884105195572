/* eslint-disable no-unneeded-ternary */
import React from "react";
import { arrayOf, func, shape, string } from "prop-types";
import OptionsStatusCell from "../../../shared/TDPOptionsStatusCell";
import InlineEditingSelect from "../../../../shared/v2/InlineEditingSelect/InlineEditingSelect";
import FieldLabel from "../../../../shared/v2/FieldLabel";

// These are hard-coded to enable the pencil and display to appear aligned to the top horizontal line below headers.
const layoutInlineAlignmentClasses = {
  base: "tw-absolute tw-leading-36px tw-right-0",
  displayMode: "!tw-mr-[21px] hover:tw-underline",
  editorMode: "!tw-mr-[21px]",
};

const DetailDropdown = ({ label, schema, val, options, onBlur, dataCy, containerClass }) => (
  <div
    className={`tw-relative tw-h-36px tw-flex tw-flex-row tw-items-center tw-rounded-4px tw-w-calc[100% + 16px] tw-ml-[-8px] tw-p-8px hover:tw-bg-neutral-gray-5 tw-rounded-4px tw-w-calc[100% + 16px] tw-ml-[-8px] tw-p-8px ${containerClass}`}
    data-cy={dataCy}
  >
    <FieldLabel label={label} />
    {schema !== "normal" ? (
      <div className="tw-absolute tw-right-0 !tw-mr-[21px] tw-my-auto">
        <OptionsStatusCell />
      </div>
    ) : (
      <InlineEditingSelect
        options={options}
        onChange={onBlur}
        value={val}
        className={layoutInlineAlignmentClasses.base}
        displayClassName={layoutInlineAlignmentClasses.displayMode}
        editorClassName={layoutInlineAlignmentClasses.editorMode}
      />
    )}
  </div>
);

DetailDropdown.propTypes = {
  label: string,
  schema: string,
  val: string,
  options: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  onBlur: func.isRequired,
  dataCy: string,
  containerClass: string,
};

DetailDropdown.defaultProps = {
  label: "",
  schema: "normal",
  val: "comingsoon",
  options: [],
  dataCy: null,
  containerClass: "",
};

export default DetailDropdown;
