const createAgreement = (rawAgreement) => ({
  agreementType: rawAgreement?.agreement_type,
  id: rawAgreement?.id,
  signedDate: rawAgreement?.signed_at,
  expirationDate: rawAgreement?.expired_at,
  cancellationDate: rawAgreement?.cancelled_at,
  isCompleted: rawAgreement?.completed,
  isCanceled: rawAgreement?.cancelled,
  listing: rawAgreement?.listing
    ? { label: rawAgreement?.listing?.name, value: rawAgreement?.listing?.id }
    : null,
  status: rawAgreement?.status,
  document: createDocument(rawAgreement?.document),
  addendums: createAddendums(rawAgreement?.addendums || []),
});

const createDocument = (rawDoc) => ({
  fileExtension: `.${rawDoc?.file_name.split(".").pop()}`,
  url: rawDoc?.url,
  uploadedById: rawDoc?.uploaded_by_id,
  uploadedByImageUrl: rawDoc?.uploaded_by_image_url,
  uploadedByImage: rawDoc?.uploaded_by_image,
  uploadedByName: rawDoc?.uploaded_by_name,
  uploadedByUrl: rawDoc?.uploaded_by_url,
  fileName: rawDoc?.file_name,
  fileType: rawDoc?.file_type,
  humanReadableSize: rawDoc?.human_readable_size,
  id: rawDoc?.id,
  nakedName: rawDoc?.naked_name,
  imageIcon: rawDoc?.image_icon,
  size: rawDoc?.size,
  uploadedAt: rawDoc?.uploaded_at,
  addedAgo: rawDoc?.added_ago,
  dateAdded: rawDoc?.date_added,
  expiringUrl: rawDoc?.expiring_url,
  documentSubType: rawDoc?.document_sub_type,
  canRename: true,
});

export const createAgreements = (rawAgreements) =>
  rawAgreements.map((rawAgreement) => createAgreement(rawAgreement));

export const createAddendums = (rawAddendums) =>
  rawAddendums.map((rawAddendum) => createDocument(rawAddendum));

export const formattedStages = (rawFinancingStages) =>
  rawFinancingStages.map((stage) => ({
    label: stage.toUpperCase(),
    value: stage,
  }));
