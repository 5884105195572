import React from "react";
import { bool, func, string } from "prop-types";

import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { ADDRESS_FIELD, CLIENTINTENT, LISTING_UUID } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { isBuyerTenant } from "../../../shared/helpers";
import { putRefDesiredLocations } from "../actions/thunks";

import DetailInput from "../DetailInput";

export const DesiredLocationsField = ({ addressField, onEditReferralDesiredAddress, show }) =>
  show && (
    <DetailInput
      dataCy="DesiredLocationsField"
      label="Desired Locations"
      schema="text"
      val={addressField}
      onBlur={onEditReferralDesiredAddress}
      containerClass="tw-flex tw-items-start"
    />
  );

const DesiredLocationsFieldWrapper = ({ addressField, onPutRefDesiredLocations, clientIntent, uuid }) => (
  <DesiredLocationsField
    addressField={addressField}
    onEditReferralDesiredAddress={(val) => onPutRefDesiredLocations(uuid, val)}
    show={isBuyerTenant(clientIntent)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: DesiredLocationsFieldWrapper,
  states: [ADDRESS_FIELD, CLIENTINTENT, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefDesiredLocations,
  aliases: "onPutRefDesiredLocations",
});

DesiredLocationsField.propTypes = {
  addressField: string,
  onEditReferralDesiredAddress: func,
  show: bool,
};

DesiredLocationsField.defaultProps = {
  addressField: null,
  onEditReferralDesiredAddress: () => {},
  show: true,
};

DesiredLocationsFieldWrapper.propTypes = {
  addressField: string,
  onPutRefDesiredLocations: func,
  clientIntent: string,
  uuid: string,
};

DesiredLocationsFieldWrapper.defaultProps = {
  addressField: null,
  onPutRefDesiredLocations: () => {},
  clientIntent: null,
  uuid: null,
};
