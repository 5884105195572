/* eslint-disable react/prop-types */
import React from "react";
import Cluster from "../../../../shared/v2/Cluster";
import AgentCell from "../../shared/AgentCell";
import TypeCell from "../../shared/TypeCell";
import { LeftCaret } from "../../icons";
// eslint-disable-next-line import/no-cycle
import TDIOptionsStatusCell from "../../TDIOptionsStatusCell";

const ListingCardFooter = ({ listing, collapsed, setCollapsed }) => (
  <div>
    <Cluster justify="between" align="center">
      <Cluster justify="start" align="center" space="sm">
        <TypeCell value={listing.type} />
        <AgentCell size="small" agent={listing.agent} />
        <TDIOptionsStatusCell listing={listing} />
      </Cluster>
      <div>
        <button
          aria-label="toggle"
          type="button"
          data-cy="transaction-item-card-collapse-button"
          className={`
                        tw-flex tw-justify-center tw-items-center
                        tw-rounded-full tw-border-0
                        tw-px-8px tw-py-4px
                        tw-text-12d ${collapsed ? "tw-text-neutral-gray-75" : "tw-text-brivity-blue-100"}
                        tw-bg-transparent
                        tw-transition-transform
                        ${collapsed ? "tw--rotate-90" : "tw-rotate-90"}
                    `}
          onClick={() => setCollapsed(!collapsed)}
        >
          <LeftCaret />
        </button>
      </div>
    </Cluster>
  </div>
);

export default ListingCardFooter;
