import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Jobs from './jobs'

const JobRunner = (props) => {
  const { accountId, handleSuccess, handleError, userId } = props;

  const [selectedJob, setSelectedJob] = useState(Object.keys(Jobs)[0]);

  const onJobSuccess = () => { handleSuccess(Jobs[selectedJob].successMessage) }

  const onJobError = (e) => {
    handleError(e.response.data)
  }

  const runJob = () => {
    const endpoint = Jobs[selectedJob].endpoint({ accountId, userId });

    axios.post(endpoint).then(onJobSuccess).catch(onJobError)
  }

  return (
    <div>
      <label htmlFor="JobSelect">Select Job:</label>

      <select
        id="JobSelect"
        onChange={e => setSelectedJob(e.target.value)}
        style={{ margin: "0px 12px" }}
        value = {selectedJob}
      >
        {Object.keys(Jobs).map((job) => <option key={job}>{job}</option>)}
      </select>

      <button onClick={runJob} type="button">Perform</button>
    </div>
  );
}

JobRunner.propTypes = { 
  accountId: PropTypes.number.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}

export default JobRunner;
