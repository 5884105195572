import React, { useState } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "../../../../shared/v1";
import Button from "../../../../shared/v2/Button";
import LinkIcon from "../../../../shared/v2/Icomoon/Icons/Link";
import * as actionCreators from "../actions/thunks";
import TextButton from "../../../../shared/v2/TextButton";

const SiteInput = ({
  planId,
  siteId,
  updateAdSiteAsThunk,
  removeAdSiteAsThunk,
  isLoading,
  shared,
  uuid,
  isedit,
  link,
}) => {
  const [adlink, setlink] = useState(link);
  const handleSubmit = () => {
    const data = {
      url: adlink,
    };
    updateAdSiteAsThunk(uuid, planId, siteId, data);
  };
  const hadndleRemoveSite = () => {
    removeAdSiteAsThunk(uuid, planId, siteId);
  };
  return (
    <Row>
      <Col size={12}>
        {!shared && !planId && (
          <TextButton
            onClick={hadndleRemoveSite}
            className="tw-float-right tw-mt-[-27px] tw-p-[0px] tw-w-[71px]"
            schema="warning"
            size="small"
          >
            REMOVE SITE
          </TextButton>
        )}
        <div className="tw-w-full tw-h-36px tw-rounded tw-border-1 tw-border-solid tw-border-gray-15 tw-flex tw-items-center tw-p-[6px]">
          <div
            className="tw-w-34px tw-h-full tw-flex tw-items-center tw-justify-center tw-mr-[10px] tw-border-solid tw-border-r-1 
          tw-border-b-0 tw-border-l-0 tw-border-t-0 tw-border-gray-15 tw-text-gray-50"
          >
            <LinkIcon size="m" />
          </div>
          <input
            onChange={(e) => setlink(e.target.value)}
            value={adlink}
            placeholder="Paste Site URL"
            className="tw-border-none tw-outline-none tw-text-14px tw-text-gray-75 tw-w-full"
          />
          <div className="tw-h-[24px]">
            <Button
              onClick={handleSubmit}
              disabled={!adlink || isLoading}
              className="tw-flex tw-items-center"
              schema="primary"
              size="small"
            >
              {isedit ? "UPDATE" : "ADD"}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

SiteInput.propTypes = {
  updateAdSiteAsThunk: Proptypes.func.isRequired,
  removeAdSiteAsThunk: Proptypes.func.isRequired,
  uuid: Proptypes.string.isRequired,
  planId: Proptypes.string,
  siteId: Proptypes.string,
  isLoading: Proptypes.bool,
  isedit: Proptypes.bool,
  link: Proptypes.string,
  shared: Proptypes.bool,
};

SiteInput.defaultProps = {
  isLoading: false,
  planId: null,
  siteId: null,
  isedit: false,
  link: null,
  shared: false,
};
const mapStateToProps = (state) => ({
  uuid: state.tdpMarketingReducer.uuid,
  isLoading: state.tdpMarketingReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateAdSiteAsThunk: (uuid, planId, siteId, data) =>
    dispatch(actionCreators.updateAdSiteAsThunk(uuid, planId, siteId, data)),
  removeAdSiteAsThunk: (uuid, planId, siteId) =>
    dispatch(actionCreators.removeAdSiteAsThunk(uuid, planId, siteId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SiteInput);
