import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import TextButton from "@shared/v2/TextButton";
import Tooltip from "@shared/v2/Tooltip";
import {
  CommentDotsSolidV6,
  EnvelopeSolidV6,
  LinkSolidV6,
  MobilePhone,
  SpinnerSolidV6,
} from "@shared/v2/Icomoon";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import { useMainEmail } from "../../reducers/personalDetailReducer/selectors";
import Loading from "../../Goals/components/Loader";
import { homeAppTxt } from "@shared/Utilities";

const HomeApp = () => {
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const mainEmail = useMainEmail();
  const currentUser = useCurrentUser();
  const [state, _setState] = useState({ showPopover: false, loadingUrl: false });
  const setState = (newState) => _setState((prevState) => ({ ...prevState, ...newState }));
  const person = useSelector((state) => state.personDetail.person);
  const { home_app_invite_sent: homeAppInviteSent } = person.data?.attributes || {};
  const isEmailEnabled =
    (person.included?.filter((a) => a.type === "email_detail").map((a) => a.attributes) || []).length > 0;
  const isPhoneEnabled =
    featureFlags.platformAccount && person.data?.attributes.primary_phone_number.length > 0;

  const handleSelectedType = (type, appUrl) => {
    const sharedUrlContent = "Download my Brivity Home search app to search for homes here: ";

    if (type == "email") {
      dispatch(
        actions.setInteractionTabWithHomeAppContent(
          `${sharedUrlContent} <a href="${appUrl}" target="_blank">${appUrl}</a>.<br/><br/>`,
          1,
        ),
      );
    } else if (type == "text") {
      dispatch(
        actions.setInteractionTabWithHomeAppContent(
          homeAppTxt({
            recipientFirstName: person.data?.attributes.first_name,
            recipientEmailAddress: mainEmail,
            teamName: currentUser.account?.teamName,
            primaryAgentName: currentUser.fullName,
            brivityAppLink: appUrl,
          }),
          3,
        ),
      );
    } else if (type == "copy") {
      navigator.clipboard.writeText(appUrl).catch((error) => {
        console.log(error);
      });
    }
  };

  const fetchBrivityHomeURL = (type) => {
    setState({ loadingUrl: true, showPopover: false });

    axios
      .get(`/people/${person.data?.attributes.slug}/home_app`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setState({ loadingUrl: false });
        handleSelectedType(type, response.data.url);
      })
      .catch(() => setState({ loadingUrl: false }));
  };

  const handleToggle = () => setState({ showPopover: !state.showPopover });

  return (
    <CollapsibleElement id="pdp-brivity-home-app" title="Brivity Home App">
      {!person.data && <Loading />}
      <div
        id="person-home-app-card"
        className="tw-relative tw-z-2 tw-shadow-card tw-rounded-[6px] tw-p-[8px]"
      >
        {homeAppInviteSent ? (
          <p className="tw-p-0 tw-m-0">
            Invite sent {homeAppInviteSent}
            <TextButton className="tw-normal-case" onClick={handleToggle}>
              Resend
              {state.loadingUrl && <SpinnerSolidV6 className="tw-animate-spin" />}
            </TextButton>
          </p>
        ) : !mainEmail ? (
          <Tooltip
            multiline
            placement="left"
            content={
              <div className="tw-w-[180px]">
                An email address is required to log in to Brivity Home. Please add an email address to the
                contact before sending an invite.
              </div>
            }
            trigger={
              <div className="tw-flex tw-items-center">
                <MobilePhone className="tw-text-gray-30" size="l" />
                <TextButton className="tw-normal-case !tw-text-gray-30">
                  {homeAppInviteSent ? `Invite sent ${homeAppInviteSent}` : "Send search app invite"}
                </TextButton>
                {state.loadingUrl && <SpinnerSolidV6 className="tw-animate-spin" />}
              </div>
            }
          />
        ) : (
          <div className="tw-flex tw-items-center">
            <MobilePhone className="tw-text-neutral-gray-50" size="l" />
            <TextButton className="tw-normal-case" onClick={handleToggle}>
              {homeAppInviteSent ? `Invite sent ${homeAppInviteSent}` : "Send search app invite"}
            </TextButton>
            {state.loadingUrl && <SpinnerSolidV6 className="tw-animate-spin" />}
          </div>
        )}
        {state.showPopover && (
          <div className="tw-absolute tw-min-w-[150px] tw-shadow-dropdown tw-bg-white tw-rounded-[6px] tw-overflow-y-auto">
            {isPhoneEnabled && (
              <button
                className="tw-bg-white hover:tw-bg-neutral-gray-2.5 tw-border-0 tw-px-[16px] tw-py-[8px] tw-w-full tw-flex tw-justify-between tw-gap-[8px]"
                onMouseDown={() => {
                  fetchBrivityHomeURL("text");
                }}
              >
                Text <CommentDotsSolidV6 size="l" />
              </button>
            )}
            {isEmailEnabled && (
              <button
                className="tw-bg-white hover:tw-bg-neutral-gray-2.5 tw-border-0 tw-px-[16px] tw-py-[8px] tw-w-full tw-flex tw-justify-between tw-gap-[8px]"
                onMouseDown={() => {
                  fetchBrivityHomeURL("email");
                }}
              >
                Email <EnvelopeSolidV6 size="l" />
              </button>
            )}
            <button
              className="tw-bg-white hover:tw-bg-neutral-gray-2.5 tw-border-0 tw-px-[16px] tw-py-[8px] tw-w-full tw-flex tw-justify-between tw-gap-[8px]"
              onMouseDown={() => {
                fetchBrivityHomeURL("copy");
              }}
            >
              Copy Link <LinkSolidV6 size="l" />
            </button>
          </div>
        )}
      </div>
    </CollapsibleElement>
  );
};

export default HomeApp;
