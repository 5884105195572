import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import {
  areValuesDifferent,
  extractNumeric,
  formatNumber,
  isValidPercentage,
  isValidValue,
  removeLeadingZeroes,
} from "../inputFormatUtils";
import TextComboInput from "../TextComboInput";
import { resizeInputBasedOnValue } from "../utils";

const FlatPercentInput = ({
  inputRef,
  className,
  value,
  customFormat,
  isPercentage,
  onChange,
  onBlur,
  hideDefaultLeading,
  hideDefaultTrailing,
  ...otherProps
}) => {
  const [displayValue, setDisplayValue] = useState(value);

  const textComboInputRef = inputRef || useRef();

  const formatNum = customFormat || formatNumber;
  const handleFieldClick = () => {
    if (textComboInputRef.current) {
      textComboInputRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const newValue = removeLeadingZeroes(extractNumeric(e.target.value)) || "0";
    const isNewValueValid = isValidValue(newValue) && (!isPercentage || isValidPercentage(newValue));

    if (isNewValueValid) {
      setDisplayValue(newValue);

      if (onChange && areValuesDifferent(value, newValue)) {
        onChange(e, formatNum(newValue, true));
      }
    }
  };

  const handleBlur = (e) => {
    if (displayValue !== "0") {
      setDisplayValue(formatNum(displayValue, true));
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (areValuesDifferent(value, displayValue)) {
      setDisplayValue(value === "0" ? value : formatNum(value, true));
    }
  }, [value]);

  useEffect(() => {
    resizeInputBasedOnValue(textComboInputRef.current, displayValue);
  }, [textComboInputRef.current, displayValue]);

  const defaultLeading = hideDefaultLeading ? "" : "$";
  const defaultTrailing = hideDefaultTrailing ? "" : "%";

  return (
    <TextComboInput
      inputRef={textComboInputRef}
      className={`
        tw-cursor-text
        ${className}
      `}
      value={displayValue}
      leading={isPercentage ? "" : defaultLeading}
      trailing={isPercentage ? defaultTrailing : ""}
      spacing={0}
      inputSize="min"
      onFieldClick={handleFieldClick}
      onChange={handleChange}
      onBlur={handleBlur}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

FlatPercentInput.propTypes = {
  // See https://github.com/facebook/prop-types/issues/240#issue-384666636
  inputRef: PropTypes.oneOfType([
    PropTypes.func, // for legacy refs
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  value: PropTypes.string,
  isPercentage: PropTypes.bool,
  onChange: PropTypes.func,
  customFormat: PropTypes.func,
  onBlur: PropTypes.func,
  hideDefaultLeading: PropTypes.bool,
  hideDefaultTrailing: PropTypes.bool,
};

FlatPercentInput.defaultProps = {
  inputRef: null,
  className: "",
  value: "0",
  isPercentage: false,
  onChange: null,
  customFormat: null,
  onBlur: null,
  hideDefaultLeading: false,
  hideDefaultTrailing: false,
};

export default FlatPercentInput;
