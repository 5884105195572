import React, { useState } from "react";
import PropTypes from "prop-types";
import PersonInteractionsNoteForm from "../../../../PersonDetail/components/PersonInteractionsNoteForm";
import Button from "../../../../shared/v2/Button";
import Modal from "../../../../shared/v2/Modal";

export const DeleteNoteModal = ({ onClose, onDelete }) => (
  <Modal
    show
    id="transaction-notes"
    onHide={() => onClose(false)}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Delete Note" closeButton />
    <Modal.Body>
      <p>Are you sure you want to delete this note?</p>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onClose}>
        Cancel
      </Button>
      <Button size="medium" schema="warning" onClick={onDelete}>
        Yes, Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

DeleteNoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export const DiscardNoteModal = ({ onClose, onDiscard }) => (
  <Modal
    show
    id="transaction-notes"
    onHide={onClose}
    dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
    contentClassName="tw-max-w-[480px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
  >
    <Modal.Header title="Discard Note" closeButton />
    <Modal.Body>
      <p>Are you sure you want to discard this note? Your note will not be saved.</p>
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-justify-between">
      <Button size="medium" schema="tertiary" onClick={onClose}>
        Keep Editing
      </Button>
      <Button size="medium" schema="warning" onClick={onDiscard}>
        Discard
      </Button>
    </Modal.Footer>
  </Modal>
);

DiscardNoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export const EditNoteModal = ({
  isEditing,
  isExternal = false,
  notableId,
  notableType,
  note,
  onClose,
  onDelete,
  updateNote,
}) => {
  const [showDiscardForm, setShowDiscardForm] = useState(false);

  return (
    <>
      <Modal
        show
        id="transaction-notes"
        onHide={onClose}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[640px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title={`${isEditing ? "Edit" : "New"} Note`} closeButton />
        <Modal.Body className="tw-flex tw-flex-col tw-overflow-hidden">
          <PersonInteractionsNoteForm
            isEditing={isEditing}
            isExternal={isExternal}
            notableId={notableId}
            notableType={notableType}
            note={note}
            onClose={onClose}
            onDelete={onDelete}
            onDiscard={() => setShowDiscardForm(true)}
            submitNote={updateNote}
          />
        </Modal.Body>
      </Modal>

      {showDiscardForm && (
        <DiscardNoteModal
          onClose={() => setShowDiscardForm(false)}
          onDiscard={() => {
            setShowDiscardForm(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

EditNoteModal.propTypes = {
  isEditing: PropTypes.bool,
  isExternal: PropTypes.bool,
  notableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  notableType: PropTypes.string,
  note: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  updateNote: PropTypes.func.isRequired,
};

EditNoteModal.defaultProps = {
  isEditing: false,
  isExternal: false,
  notableId: null,
  notableType: "",
  note: null,
  onDelete: () => {},
};
