/* disable prop validation for complex component overrides */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import Avatar from "../../shared/v2/Avatar";
import TextComboInput from "../../shared/v2/TextComboInput/TextComboInput";
import { MagnifyingGlassSolidV6 } from "../../shared/v2/Icomoon";
import Dropdown from "../../shared/v2/Dropdown";

export const Option = ({ children, componentProps }) => (
  <Dropdown.Option {...componentProps} preserveStyle>
    <div className="tw-flex tw-items-center tw-gap-[8px]">
      <Avatar src={componentProps.data.meta.avatar} /> {children}
    </div>
  </Dropdown.Option>
);

export const SingleValue = ({ children, componentProps }) => (
  <Dropdown.SingleValue {...componentProps} preserveStyle>
    <div className="tw-flex tw-items-center tw-gap-[8px]">
      <Avatar src={componentProps.data.meta.avatar} /> {children}
    </div>
  </Dropdown.SingleValue>
);

export const Menu = ({ children, componentProps }) => (
  <Dropdown.Menu {...componentProps} preserveStyle>
    <div className="tw-px-[12px] tw-pt-[12px] tw-pb-[8px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-[1px] tw-border-neutral-gray-10">
      <TextComboInput
        trailing={<MagnifyingGlassSolidV6 size="m" className="tw-align-middle tw-text-neutral-gray-30" />}
        placeholder="Search"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onPointerUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.target.focus();
          componentProps.selectProps.onMenuOpen?.();
        }}
        onKeyDown={(e) => {
          if (!/Up|Down|Left|Right|Esc|Tab|Enter/.test(e.key)) e.stopPropagation();
        }}
        onChange={(e) =>
          componentProps.selectProps.onInputChange?.(e.target.value, { action: "input-change" })
        }
        onBlur={() => {
          /** this is not ideal, but seems to be the easiest way to keep the close functionality
           * without making larger changes */
          setTimeout(() => {
            componentProps.selectProps.onInputChange?.("", { action: "menu-close" });
            componentProps.selectProps.onMenuClose?.();
          }, 200);
        }}
      />
    </div>
    {children}
  </Dropdown.Menu>
);
