import axios from "axios";

const BasePath = "/phone_system/tracking_numbers/";

const createError = (e) => {
  const error = e.response ? e.response.data.error : "Something went wrong!";

  return { error };
};

const appendOptionalFilters = (
  path,
  trackingNumbers,
  minDate,
  maxDate,
  duration,
  outcome,
  agents,
  callers,
) => {
  let filters = [];

  if (trackingNumbers.length > 0) {
    filters.push(`tracking_number_ids=${trackingNumbers.join(",")}`);
  }

  if (minDate) {
    filters.push(`min_date=${minDate}`);
  }

  if (maxDate) {
    filters.push(`max_date=${maxDate}`);
  }

  if (duration) {
    filters.push(`duration=${duration.value}`);
  }

  if (outcome) {
    filters.push(`outcome=${outcome.value}`);
  }

  if (agents?.length) {
    filters.push(`agents=${agents.map((a) => a.value).join(",")}`);
  }

  if (path.includes("?")) {
    return `${path}&${filters.join("&")}`;
  }

  return `${path}?${filters.join("&")}`;
};

export const getCallLogs = async (
  trackingNumbers,
  minDate,
  maxDate,
  sortBy,
  sortDirection,
  offset,
  limit,
  filters,
) => {
  try {
    let path = `${BasePath}call_logs?sort_by=${sortBy}&sort_direction=${sortDirection}&offset=${offset}&limit=${limit}`;
    path = appendOptionalFilters(
      path,
      trackingNumbers,
      minDate,
      maxDate,
      filters.duration,
      filters.outcome,
      filters.agents,
    );

    const response = await axios.get(path);
    return response.data?.tracking_number_calls || [];
  } catch (e) {
    return createError(e);
  }
};

export const getStats = async (trackingNumbers, minDate, maxDate) => {
  try {
    let path = `${BasePath}stats`;
    path = appendOptionalFilters(path, trackingNumbers, minDate, maxDate);

    const response = await axios.get(path);
    return response.data;
  } catch (e) {
    return createError(e);
  }
};

export const getTrackingNumbers = async () => {
  try {
    const response = await axios.get(BasePath);
    return response.data;
  } catch (e) {
    return createError(e);
  }
};

export const getLogCount = async (trackingNumbers, minDate, maxDate, filters) => {
  try {
    let path = `${BasePath}call_log_count`;
    path = appendOptionalFilters(
      path,
      trackingNumbers,
      minDate,
      maxDate,
      filters.duration,
      filters.outcome,
      filters.agents,
    );

    const response = await axios.get(path);
    return response.data?.log_count || 0;
  } catch (e) {
    return createError(e);
  }
};

export const getActivity = async (trackingNumbers, minDate, maxDate) => {
  try {
    let path = `${BasePath}activity`;
    path = appendOptionalFilters(path, trackingNumbers, minDate, maxDate);

    const { data } = await axios.get(path);

    return data?.activity;
  } catch (e) {
    return createError(e);
  }
};

export const getAgents = async () => {
  try {
    const path = `${BasePath}agents`;

    const { data } = await axios.get(path);

    return data;
  } catch (e) {
    return createError(e);
  }
};

export const getRecordingMetadata = async (recordingLink) => {
  const metadataUrl = `${recordingLink.substring(0, recordingLink.length - 4)}.json`;
  const { data } = await axios.get(metadataUrl);

  return data || {};
};
