import React, { useState } from "react";
import { connect } from "react-redux";
import { string, bool, func, number } from "prop-types";

import { deleteListingImageAsThunk } from "../actions/thunks";
import { toggleChecked } from "../actions/creators";
import ImageOption from "../ImageOption";
import Checkbox from "../../../../shared/v2/Checkbox";
import ModalPreviewImg from "../ModalPreviewImg";
import ModalDeleteImage from "../ModalDeleteImage";
import { downloadFile } from "../../../../shared/AWSUploader";
import { Trash, VisibilityVisible, Download } from "../../../../shared/v2/Icomoon";

const ImageOptions = ({
  setToggle,
  showOptions,
  checked,
  id,
  onToggleChecked,
  onDeleteImg,
  url,
  deleteUrl,
  useMLSphotos,
}) => {
  const checkBoxHandlr = () => {
    setToggle(false);
    onToggleChecked(id);
  };
  const [isModalOpen, toggleModal] = useState("");
  const [isDeleteModalOpen, toggleDeleteModal] = useState("");
  return (
    <>
      <ModalDeleteImage
        isModalOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        onDelete={() => onDeleteImg({ id, deleteUrl })}
      />
      <ModalPreviewImg isModalOpen={isModalOpen} toggleModal={toggleModal} url={url} id={id} />
      <div className="tw-h-full tw-w-full tw-relative">
        <div className="tw-flex tw-justify-end">
          <Checkbox
            checked={checked}
            value={id}
            label=""
            onClick={checkBoxHandlr}
            className="tw-z-40 tw-cursor-pointer"
          />
        </div>
        <div className="tw-h-full tw-w-full tw-top-0 tw-flex tw-items-center tw-justify-center tw-absolute">
          <div className="tw-flex tw-gap-x-[12px]">
            {!useMLSphotos && (
              <ImageOption dataCy="listing-image-delete" clicked={() => toggleDeleteModal(true)}>
                <Trash size="l" />
              </ImageOption>
            )}

            <ImageOption
              dataCy="listing-image-preview"
              clicked={() => {
                showOptions(false);
                toggleModal(true);
              }}
            >
              <VisibilityVisible size="l" />
            </ImageOption>
            <ImageOption
              clicked={() => {
                showOptions(false);
                downloadFile(url, id);
              }}
            >
              <Download size="l" />
            </ImageOption>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  useMLSphotos: state.tdpDetailsReducer.useMLSphotos,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleChecked: (id) => dispatch(toggleChecked(id)),
  onDeleteImg: (payload) => dispatch(deleteListingImageAsThunk(payload)),
});

ImageOptions.propTypes = {
  useMLSphotos: bool,
  setToggle: func,
  showOptions: func,
  checked: bool,
  id: number,
  onToggleChecked: func,
  onDeleteImg: func,
  url: string,
  deleteUrl: string,
};

ImageOptions.defaultProps = {
  useMLSphotos: false,
  setToggle: () => {},
  showOptions: null,
  checked: false,
  id: 0,
  onToggleChecked: () => {},
  onDeleteImg: () => {},
  url: "",
  deleteUrl: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageOptions);
