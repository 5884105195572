import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Overlay } from "../../../../shared/v1/Overlay";
import { setDocumentIndex } from "../actions/creators";
import { getAllDocumentViewersAsThunk } from "../actions/thunks";
import { ContextPropType, DocumentPropType } from "../propTypes";

import PreviewNavigator from "./PreviewNavigator";
import PreviewDetails from "./PreviewDetails";
import { containerZ } from "./utils";

const Preview = ({
  documents,
  CDAdocuments,
  sidebarDocuments,
  currentDocumentContext,
  currentDocumentIndex,
  dispatch,
}) => {
  const [isOpen, setOpen] = useState(false);

  const iff = (condition, then, otherwise) => (condition ? then : otherwise);

  const relevantDocuments = iff(
    currentDocumentContext === "documents-table",
    documents,
    iff(currentDocumentContext === "CDA-document", CDAdocuments, sidebarDocuments),
  );

  const document = useMemo(() => {
    if (typeof currentDocumentIndex === "number") {
      return relevantDocuments[currentDocumentIndex] || null;
    }

    return null;
  }, [relevantDocuments, currentDocumentIndex]);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Esc":
      case "Escape":
        dispatch(setDocumentIndex(null, null));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (document && document.viewers.length === 0) {
      // Fetch the document viewers to get the uploader's role,
      // since this information is not available in the document
      dispatch(getAllDocumentViewersAsThunk(document.id));
    }
  }, [document]);

  useEffect(() => {
    setOpen(!!document);
  }, [document]);

  useEffect(() => {
    // Hide body scroll while preview is open because it has its own scroll
    window.document.body.classList.toggle("tw-overflow-hidden", isOpen);
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  return (
    <Overlay show={isOpen} zIndex={containerZ}>
      <div className="tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex">
        {document && (
          <>
            <PreviewNavigator
              context={currentDocumentContext}
              document={document}
              index={currentDocumentIndex}
              numDocuments={relevantDocuments.length}
            />
            <PreviewDetails document={document} />
          </>
        )}
      </div>
    </Overlay>
  );
};

const mapStateToProps = (state) => ({
  documents: state.tdpDocumentsReducer.documents,
  CDAdocuments: state.tdpDocumentsReducer.CDAdocuments,
  sidebarDocuments: state.tdpDocumentsReducer.sidebarDocuments,
  currentDocumentContext: state.tdpDocumentsReducer.preview.currentDocumentContext,
  currentDocumentIndex: state.tdpDocumentsReducer.preview.currentDocumentIndex,
});

Preview.propTypes = {
  documents: PropTypes.arrayOf(DocumentPropType),
  CDAdocuments: PropTypes.arrayOf(DocumentPropType),
  sidebarDocuments: PropTypes.arrayOf(DocumentPropType),
  currentDocumentContext: ContextPropType,
  currentDocumentIndex: PropTypes.number,
  dispatch: PropTypes.func,
};

Preview.defaultProps = {
  documents: [],
  CDAdocuments: [],
  sidebarDocuments: [],
  currentDocumentContext: null,
  currentDocumentIndex: null,
  dispatch: () => {},
};

export default connect(mapStateToProps)(Preview);
