import React from "react";
import PropTypes from "prop-types";
import DropdownWithMultiCtrlledRemovable from "@shared/DropdownWithMultiCtrlledRemovable";

const ExcludeTagFilter = ({ tags, excludedTags }) => (
  <DropdownWithMultiCtrlledRemovable
    options={tags.map(({ id: value, name: label }) => ({ label, value: `${value}` }))}
    selectedOptions={excludedTags?.map((excludedId) => {
      const { id, name } = tags.find((t) => t.id === excludedId);

      return { label: name, value: `${id}` };
    })}
    removablePrefix={`Tag (Exclude): `}
    placeHolderTxt=" excluded tags selected"
    ctrlName="leads_filter[selected_exclude_tag_filters][]"
  />
);

ExcludeTagFilter.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  excludedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ExcludeTagFilter;
