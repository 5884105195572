import React from "react";
import { Search } from "../../../shared/v2/Icomoon";

const NoSearch = () => (
  <div className="tw-text-center tw-mt-40">
    <Search size="xxxl" className="tw-text-neutral-gray-50 tw-mb-[16px]" />
    <h1 className="tw-text-neutral-gray-50 tw-text-[18px] tw-font-bold tw-mb-[8px] tw-mt-0">
      Search Partners
    </h1>
    <p className="tw-text-neutral-gray-50 tw-text-[14px] tw-mb-[8px]">
      Search by Area, Operator / Owner, or Team
    </p>
  </div>
);

export default NoSearch;
