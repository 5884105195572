import React from "react";

const Button = ({ size, color, text, classNameExtra, disabled, ...props }) => {
  let className =
    "tw-border-0 tw-cursor-pointer tw-tracking-normal tw-text-center tw-uppercase tw-text-none tw-rounded-666px";
  switch (size) {
    case "large":
      className += " tw-text-14px tw-py-8px tw-px-20px tw-h-36px tw-font-bold";
      break;
    default:
      className += " tw-text-11px tw-font-bold tw-py-4px tw-px-8px";
      break;
  }

  switch (color) {
    case "blue":
      className += ` tw-text-white ${disabled === "disabled" ? "tw-bg-blue-40" : "tw-bg-teal"}`;
      break;
    case "clear":
      className += " tw-text-teal tw-bg-transparent";
      break;
    case "gray":
    default:
      className += " tw-text-gray-75 tw-bg-gray-10";
      break;
  }

  className += ` ${classNameExtra || ""}`;

  return (
    <button className={className} disabled={disabled} {...props}>
      {props.children || text}
    </button>
  );
};

export default Button;
