import PropTypes from "prop-types";

import { camelizeKeys } from "@shared/Utilities";
import { callStatusIconForPhone, mobileCallStatusIcon } from "./viewPhoneIcons/CallStatusIcon";
import warningIconForPhone from "./viewPhoneIcons/PhoneWarningIcon";
import smsStatusIconForStatus from "./viewPhoneIcons/SmsStatusIcon";
import formatPhoneNumber from "../../FormatPhoneNumber";

const aiStatus = (phone) => {
  if (!phone.textable) return "untextable";
  if (phone.smsUnsubscribed || phone.smsAccountUnsubscribed) return "hard-opt-out";
  if (phone.smsSoftUnsubscribed) return "soft-opt-out";
  if (!phone.isPrimary) return "non-primary";
  return "valid";
};

const textableStatus = (phone) => {
  if (phone.smsUnsubscribed) {
    return "unsubscribed";
  }
  return phone.textable ? "valid" : "invalid";
};

const viewPhone = (data) => {
  const originalData = data.raw ? data.raw : data;
  const source = camelizeKeys(originalData);
  const { attributes } = source;
  const base = {
    // Used mostly for debugging purposes while we normalize the data.
    raw: originalData.raw ? originalData.raw : { ...originalData },

    // We want to avoid using `value` directly when possible, so that the display
    // of the phone number can be controlled by the `formattedNumber` property.
    // Value is still accessible, but it has to be made extra conciously.
    rawValue: attributes.value,
    id: source.id,
    formattedNumber: formatPhoneNumber(attributes.value),
    category: attributes.category,
    unsubscribed: attributes.unsubscribed,
    textable: attributes.smsTextable,
    valid: !!attributes.canonicalValue,
    callable: attributes.callCallable,
    callUnsubscribed:
      attributes.callUnsubscribed || attributes.callSoftUnsubscribed || attributes.callAccountUnsubscribed,
    smsUnsubscribed:
      attributes.smsUnsubscribed || attributes.smsSoftUnsubscribed || attributes.smsAccountUnsubscribed,
    isPrimary: attributes.position === 1,
    position: attributes.position,

    callStatus: "valid",
    isMobile: attributes.category === "mobile",
  };

  // We remove this check, as there is no UI for invalid phones, but it soon will exist
  // if (!base.valid) {
  //   base.callStatus = "invalid";
  // } else
  if (base.callUnsubscribed) {
    base.callStatus = "unsubscribed";
  } else if (!base.textable) {
    base.callStatus = "voiceOnly";
  }

  base.icon = callStatusIconForPhone(base);
  base.warning = warningIconForPhone(base);

  base.smsStatus = textableStatus(base);
  base.smsIcon = smsStatusIconForStatus(base.smsStatus, attributes.errorCode);
  base.smsEditable = base.smsStatus !== "unsubscribed";
  base.aiStatus = aiStatus(base);

  base.callIcon = callStatusIconForPhone(base);
  base.mobileCallIcon = mobileCallStatusIcon(base.callStatus);
  base.callEditable = !base.callUnsubscribed;

  return base;
};

viewPhone.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  attributes: {
    account_id: PropTypes.number,
    call_account_unsubscribed: PropTypes.bool,
    call_callable: PropTypes.bool,
    call_soft_unsubscribed: PropTypes.bool,
    canonical_value: PropTypes.string,
    category: PropTypes.string.isRequired,
    created_at: PropTypes.string,
    detailable_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    detailable_type: PropTypes.string,
    error_code: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    position: PropTypes.number,
    sms_account_unsubscribed: PropTypes.bool,
    sms_soft_unsubscribed: PropTypes.bool,
    sms_textable: PropTypes.bool,
    updated_at: PropTypes.string,
    value: PropTypes.string.isRequired,
    dnc: PropTypes.bool,
  },
};

viewPhone.defaultValues = {
  attributes: {
    account_id: null,
    call_account_unsubscribed: false,
    call_callable: true,
    call_soft_unsubscribed: false,
    canonical_value: null,
    created_at: null,
    detailable_id: null,
    detailable_type: null,
    id: null,
    position: null,
    sms_account_unsubscribed: false,
    sms_soft_unsubscribed: false,
    sms_textable: true,
    updated_at: null,
    dnc: null,
  },
};

export const viewPhoneShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  raw: PropTypes.object.isRequired,
  rawValue: PropTypes.string,
  formattedNumber: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  unsubscribed: PropTypes.bool.isRequired,
  textable: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  callable: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(["valid", "invalid", "unsubscribed", "voiceOnly"]).isRequired,
  icon: {
    icon: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
  },
  warning: {
    icon: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
  },
  smsIcon: {
    icon: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
  },
};

export const possibleStatuses = ["valid", "voiceOnly", "invalid", "unsubscribed"];

// Standardizes the shape we initially use to build a phone.
export const flatToNonFlatAdapter = (flatPhoneData) => ({
  id: flatPhoneData.id,
  type: "phone_detail",
  attributes: { ...flatPhoneData },
});

// Helpers to incentivize building phones with pre-defined structures. If _not_
// coming from one of the default serializers, it will produce issues.
export const viewPhoneFromFastJsonApi = (phoneData) => viewPhone(phoneData);
export const viewPhoneFromFlat = (phoneData) => viewPhone(flatToNonFlatAdapter(phoneData));

export default viewPhone;
