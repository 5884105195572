import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";

import { useGivenOrGeneratedId } from "../../hookHelpers";
import ButtonToggle from "../ButtonToggle";

const options = [
  {
    label: <FontAwesomeIcon icon={faDollarSign} className="tw-w-[16px] tw-h-[16px]" />,
    value: "flat",
  },
  {
    label: <FontAwesomeIcon icon={faPercent} className="tw-w-[16px] tw-h-[16px]" />,
    value: "percent",
  },
];

const FlatPercentToggle = ({ className, name: nameFromProps, isPercentage, onChange, ...otherProps }) => {
  const name = useGivenOrGeneratedId("flat-percent-toggle", nameFromProps);

  const handleChange = (e) => {
    if (onChange) {
      onChange(e, e.target.value === "percent");
    }
  };

  return (
    <ButtonToggle
      className={className}
      name={name}
      value={isPercentage ? "percent" : "flat"}
      options={options}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
};

FlatPercentToggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  isPercentage: PropTypes.bool,
  onChange: PropTypes.func,
};

FlatPercentToggle.defaultProps = {
  className: "",
  name: null,
  isPercentage: false,
  onChange: null,
};

export default FlatPercentToggle;
