import React from "react";
import PropTypes from "prop-types";

const ModalFooter = ({ handleFilter }) => (
  <div className="modal-footer" style={{ padding: "10em 0 13px 0" }}>
    <div className="pull-left">
      <button
        style={{
          borderRadius: "30px",
          color: "#9F9F9F",
        }}
        className="btn"
        data-dismiss="modal"
        type="button"
        onClick={handleFilter}
      >
        Cancel
      </button>
    </div>
    <div className="pull-right">
      <button
        className="btn"
        type="submit"
        style={{
          borderRadius: "30px",
          backgroundColor: "#59C4C4",
          color: "white",
        }}
      >
        Filter
      </button>
    </div>
  </div>
);

ModalFooter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
};

export default ModalFooter;
