/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { string, shape, func } from "prop-types";

import DetailSectionHeader from "../DetailSectionHeader";
import DetailInput from "../DetailInput";
import DetailDropdown from "../DetailDropdown";
import DetailStatus from "../shared/DetailStatus/DetailStatus";

import { putDetailAsThunk } from "../actions/thunks";

const publishedOptions = [
  { value: false, label: "Draft" },
  { value: true, label: "Published" },
];

const TransactionDetails = ({ uuid, transactionDetails, onPutDetailAsThunk }) => {
  const { title, published } = transactionDetails;
  const publishedValue = publishedOptions.find((o) => o.label === published || o.value === !!published);

  const saveValueFor = (key) => (value) => {
    onPutDetailAsThunk({
      uuid,
      key,
      value,
      group: "transactionDetails",
    });
  };

  const savePublished = (optionValue) => {
    const newPublished = publishedOptions.find((o) => o.label === optionValue.label).value;
    saveValueFor("published")(newPublished);
  };

  return (
    <div className="tw-mb-24px">
      <DetailSectionHeader header="Transaction Details" />
      <DetailInput
        dataCy="name"
        label="Transaction Name"
        schema="text"
        val={title}
        onBlur={saveValueFor("title")}
      />
      <DetailDropdown
        label="Published State"
        options={publishedOptions}
        val={publishedValue}
        onBlur={savePublished}
        dataCy="published"
      />
      <DetailStatus label="Status" id="TransactionDetailsStatus" dataCy="TransactionDetailsStatus" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  transactionDetails: state.tdpDetailsReducer.transactionDetails,
  type: state.tdpDetailsReducer.headerDetails.type,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (payload) => dispatch(putDetailAsThunk(payload)),
});

TransactionDetails.propTypes = {
  uuid: string,
  transactionDetails: shape({
    title: string,
    publishedState: string,
    status: string,
  }),
  onPutDetailAsThunk: func,
};

TransactionDetails.defaultProps = {
  uuid: null,
  transactionDetails: shape({
    title: null,
    publishedState: null,
    status: null,
  }),
  onPutDetailAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
