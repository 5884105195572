import React from "react";
import { func, string, bool } from "prop-types";

import TextButton from "../../../../shared/v2/TextButton";
import { PencilSolidV6 } from "../../../../shared/v2/Icomoon";

const TextBtnWithPencil = ({ txtField, isPencilVisible, setPencilVisible, onClick }) => (
  <TextButton
    className="tw-flex tw-items-center tw-cursor-pointer !tw-text-14px !tw-text-gray-75 tw-font-normal !tw-normal-case"
    onMouseEnter={() => setPencilVisible(true)}
    onMouseLeave={() => setPencilVisible(false)}
    onClick={onClick}
  >
    <span>{txtField}</span>
    {isPencilVisible && <PencilSolidV6 className="tw-ml-8px" />}
  </TextButton>
);

TextBtnWithPencil.propTypes = {
  txtField: string,
  setPencilVisible: func,
  isPencilVisible: bool,
  onClick: func,
};

TextBtnWithPencil.defaultProps = {
  txtField: null,
  setPencilVisible: () => {},
  isPencilVisible: false,
  onClick: () => {},
};

export default TextBtnWithPencil;
