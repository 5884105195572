import React from "react";
import EmailBodyBlockSeparator from "./EmailBodyBlockSeparator";
import EmailBodyProperty from "./EmailBodyProperty";
import EmailBodySeparator from "./EmailBodySeparator";

// Copied from:
// https://gitlab.brivity.com/brivity/notifications/-/blob/master/src/app/views/listing_alerts/_properties.html.erb
const EmailBodyProperties = ({ listings, message, idxDomain, brokerImage, sender }) => {
  return (
    <center
      className="wrapper"
      data-link-color="#1188E6"
      data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#333333; background-color:#CDCDCD;"
    >
      <div className="webkit">
        <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="wrapper" bgcolor="#CDCDCD">
          <tbody>
            <tr>
              <td valign="top" bgcolor="#CDCDCD" width="100%">
                <table
                  width="100%"
                  role="content-container"
                  className="outer"
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                >
                  <tbody>
                    <tr>
                      <td width="100%">
                        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                          <tbody>
                            <tr>
                              <td>
                                <center>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td width="600">
                                          <table
                                            width="100%"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            style={{ width: "100%", maxWidth: "600px" }}
                                            align="center"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  role="modules-container"
                                                  style={{
                                                    padding: "0px 0px 0px 0px",
                                                    color: "#333333",
                                                    textAlign: "left",
                                                  }}
                                                  bgcolor="#CDCDCD"
                                                  width="100%"
                                                  align="left"
                                                  id="message-content"
                                                >
                                                  <table
                                                    className="module preheader preheader-hide"
                                                    role="module"
                                                    data-type="preheader"
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%"
                                                    style={{
                                                      display: "none !important",
                                                      msoHide: "all",
                                                      visibility: "hidden",
                                                      opacity: "0",
                                                      color: "transparent",
                                                      height: "0",
                                                      width: "0",
                                                    }}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td role="module-content"></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <table
                                                    className="wrapper"
                                                    role="module"
                                                    data-type="image"
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%"
                                                    style={{ tableLayout: "fixed" }}
                                                    data-muid="n2kt8EaugnLkvi4Le5ESH5"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: "6px",
                                                            lineHeight: "10px",
                                                            padding: "40px 10px 20px 10px",
                                                            backgroundColor: "#CDCDCD",
                                                          }}
                                                          valign="top"
                                                          align="center"
                                                        >
                                                          <a href={idxDomain} target="_blank">
                                                            <img
                                                              className="max-width"
                                                              border="0"
                                                              style={{
                                                                display: "block",
                                                                color: "#000000",
                                                                textDecoration: "none",
                                                                fontFamily: "Helvetica, arial, sans-serif",
                                                                fontSize: "16px",
                                                              }}
                                                              src={brokerImage}
                                                              alt=""
                                                              width="270"
                                                              data-proportionally-constrained="false"
                                                              data-responsive="false"
                                                              height=""
                                                            />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <table
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    align="center"
                                                    width="100%"
                                                    role="module"
                                                    data-type="columns"
                                                    style={{
                                                      padding: "20px",
                                                      backgroundColor: "#ffffff",
                                                      marginBottom: "20px",
                                                    }}
                                                    bgcolor="#ffffff"
                                                  >
                                                    <tbody>
                                                      <tr role="module-content">
                                                        <td height="100%" valign="top">
                                                          <table
                                                            className="column"
                                                            width="560"
                                                            style={{
                                                              width: "560px",
                                                              borderSpacing: "0",
                                                              borderCollapse: "collapse",
                                                              margin: "0px 20px 0px 20px",
                                                            }}
                                                            cellPadding="0"
                                                            cellSpacing="0"
                                                            align="left"
                                                            border="0"
                                                            bgcolor=""
                                                          >
                                                            <tbody>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    padding: "0px",
                                                                    margin: "0px",
                                                                    borderSpacing: "0",
                                                                  }}
                                                                >
                                                                  <table
                                                                    className="module"
                                                                    role="module"
                                                                    data-type="text"
                                                                    border="0"
                                                                    cellPadding="0"
                                                                    cellSpacing="0"
                                                                    width="100%"
                                                                    style={{ tableLayout: "fixed" }}
                                                                    data-muid="fcL4FqGKJVqkdhNL89o8dV.1"
                                                                    data-mc-module-version="2019-10-22"
                                                                  >
                                                                    <tbody>
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            padding: "20px 0px",
                                                                            lineHeight: "22px",
                                                                            textAlign: "inherit",
                                                                          }}
                                                                          height="100%"
                                                                          valign="top"
                                                                          bgcolor=""
                                                                          role="module-content"
                                                                        >
                                                                          <div>
                                                                            <div
                                                                              style={{
                                                                                fontFamily: "inherit",
                                                                                textAlign: "center",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  boxSizing: "border-box",
                                                                                  paddingTop: "0px",
                                                                                  paddingRight: "0px",
                                                                                  paddingBottom: "0px",
                                                                                  paddingLeft: "0px",
                                                                                  marginTop: "0px",
                                                                                  marginRight: "0px",
                                                                                  marginBottom: "0px",
                                                                                  marginLeft: "0px",
                                                                                  fontStyle: "inherit",
                                                                                  fontVariantLigatures:
                                                                                    "inherit",
                                                                                  fontVariantCaps: "inherit",
                                                                                  fontVariantNumeric:
                                                                                    "inherit",
                                                                                  fontVariantEastAsian:
                                                                                    "inherit",
                                                                                  fontWeight: "inherit",
                                                                                  fontStretch: "inherit",
                                                                                  lineHeight: "inherit",
                                                                                  fontFamily: "inherit",
                                                                                  fontSize: "14px",
                                                                                  verticalAlign: "baseline",
                                                                                  borderTopWidth: "0px",
                                                                                  borderRightWidth: "0px",
                                                                                  borderBottomWidth: "0px",
                                                                                  borderLeftWidth: "0px",
                                                                                  borderTopStyle: "initial",
                                                                                  borderRightStyle: "initial",
                                                                                  borderBottomStyle:
                                                                                    "initial",
                                                                                  borderLeftStyle: "initial",
                                                                                  borderTopColor: "initial",
                                                                                  borderRightColor: "initial",
                                                                                  borderBottomColor:
                                                                                    "initial",
                                                                                  borderLeftColor: "initial",
                                                                                  borderImageSource:
                                                                                    "initial",
                                                                                  borderImageSlice: "initial",
                                                                                  borderImageWidth: "initial",
                                                                                  borderImageOutset:
                                                                                    "initial",
                                                                                  borderImageRepeat:
                                                                                    "initial",
                                                                                  color: "#333333",
                                                                                  letterSpacing: "normal",
                                                                                  textAlign: "center",
                                                                                  textIndent: "0px",
                                                                                  textTransform: "none",
                                                                                  whiteSpace: "pre-wrap",
                                                                                  wordSpacing: "0px",
                                                                                  WebkitTextStrokeWidth:
                                                                                    "0px",
                                                                                  backgroundColor:
                                                                                    "rgb(255, 255, 255)",
                                                                                  textDecorationStyle:
                                                                                    "initial",
                                                                                  textDecorationColor:
                                                                                    "initial",
                                                                                }}
                                                                              >
                                                                                {message}
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <table
                                                    className="module"
                                                    role="module"
                                                    data-type="spacer"
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%"
                                                    style={{
                                                      tableLayout: "fixed",
                                                      backgroundColor: "#ffffff",
                                                    }}
                                                    data-muid="dvof51iWHHLqXsGeHbj7T8.1.1"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{ padding: "0px 0px 20px 0px" }}
                                                          role="module-content"
                                                          bgcolor=""
                                                        ></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  {listings.map((listing, index) => {
                                                    return (
                                                      <React.Fragment key={listing.blossorId}>
                                                        <EmailBodyProperty {...listing} />
                                                        {index < listings.length - 1 && (
                                                          <EmailBodySeparator />
                                                        )}
                                                      </React.Fragment>
                                                    );
                                                  })}
                                                  <EmailBodyBlockSeparator />
                                                  <table
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    align="center"
                                                    width="100%"
                                                    role="module"
                                                    data-type="columns"
                                                    data-version="2"
                                                    style={{
                                                      padding: "30px 0px 10px 0px",
                                                      backgroundColor: "#CDCDCD",
                                                      boxSizing: "border-box",
                                                    }}
                                                    bgcolor="#CDCDCD"
                                                  >
                                                    <tbody>
                                                      <tr role="module-content">
                                                        <td height="100%" valign="top">
                                                          <center>
                                                            <table
                                                              cellPadding="0"
                                                              cellSpacing="0"
                                                              border="0"
                                                              width="100%"
                                                              style={{
                                                                borderSpacing: "0",
                                                                borderCollapse: "collapse",
                                                                tableLayout: "fixed",
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    width="600.000px"
                                                                    valign="top"
                                                                    style={{
                                                                      padding: "0px 0px 0px 0px",
                                                                      borderCollapse: "collapse",
                                                                    }}
                                                                  >
                                                                    <table
                                                                      width="600"
                                                                      style={{
                                                                        width: "600px",
                                                                        borderSpacing: "0",
                                                                        borderCollapse: "collapse",
                                                                        margin: "0px 0px 0px 0px",
                                                                      }}
                                                                      cellPadding="0"
                                                                      cellSpacing="0"
                                                                      align="left"
                                                                      border="0"
                                                                      bgcolor="#CDCDCD"
                                                                      className="column column-0 of-1
                                                                      empty"
                                                                    >
                                                                      <tbody>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              padding: "0px",
                                                                              margin: "0px",
                                                                              borderSpacing: "0",
                                                                            }}
                                                                          >
                                                                            <table
                                                                              className="wrapper"
                                                                              role="module"
                                                                              data-type="image"
                                                                              border="0"
                                                                              cellPadding="0"
                                                                              cellSpacing="0"
                                                                              width="100%"
                                                                              style={{ tableLayout: "fixed" }}
                                                                              data-muid="ke9ZKYu1LkHueEEbKySEge"
                                                                            >
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      fontSize: "6px",
                                                                                      lineHeight: "10px",
                                                                                      padding:
                                                                                        "0px 0px 0px 0px",
                                                                                    }}
                                                                                    valign="top"
                                                                                    align="center"
                                                                                  >
                                                                                    <img
                                                                                      className="max-width"
                                                                                      border="0"
                                                                                      style={{
                                                                                        display: "block",
                                                                                        color: "#000000",
                                                                                        textDecoration:
                                                                                          "none",
                                                                                        fontFamily:
                                                                                          "Helvetica, arial, sans-serif",
                                                                                        fontSize: "16px",
                                                                                      }}
                                                                                      src={sender.photo}
                                                                                      alt=""
                                                                                      width="210"
                                                                                      height=""
                                                                                      data-proportionally-constrained="false"
                                                                                      data-responsive="false"
                                                                                    />
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                            <table
                                                                              className="module"
                                                                              role="module"
                                                                              data-type="text"
                                                                              border="0"
                                                                              cellPadding="0"
                                                                              cellSpacing="0"
                                                                              width="100%"
                                                                              style={{ tableLayout: "fixed" }}
                                                                              data-mc-module-version="2019-10-22"
                                                                              data-muid="smCFPDMDPDhpM3zbc47ZX"
                                                                            >
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      padding:
                                                                                        "10px 0px 10px 0px",
                                                                                      lineHeight: "22px",
                                                                                      textAlign: "inherit",
                                                                                    }}
                                                                                    height="100%"
                                                                                    valign="top"
                                                                                    bgcolor=""
                                                                                  >
                                                                                    <div>
                                                                                      <div
                                                                                        style={{
                                                                                          fontFamily:
                                                                                            "inherit",
                                                                                          textAlign: "center",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontFamily:
                                                                                              "arial, helvetica, sans-serif",
                                                                                            fontSize: "18px",
                                                                                          }}
                                                                                        >
                                                                                          <strong>
                                                                                            {sender.fullName}
                                                                                          </strong>
                                                                                        </span>
                                                                                        <br />
                                                                                        <a
                                                                                          href={`tel:${sender.phoneNumber}`}
                                                                                        >
                                                                                          {sender.phoneNumber}
                                                                                        </a>
                                                                                        <br />
                                                                                        <a
                                                                                          href={`mailto:${sender.emailAddress}`}
                                                                                        >
                                                                                          {
                                                                                            sender.emailAddress
                                                                                          }
                                                                                        </a>
                                                                                      </div>
                                                                                      <div></div>
                                                                                    </div>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                            <table
                                                                              className="wrapper"
                                                                              role="module"
                                                                              data-type="image"
                                                                              border="0"
                                                                              cellPadding="0"
                                                                              cellSpacing="0"
                                                                              width="100%"
                                                                              style={{ tableLayout: "fixed" }}
                                                                              data-muid="dX2Uuit4wom5Tpei8ecsfc"
                                                                            >
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td
                                                                                    style={{
                                                                                      fontSize: "6px",
                                                                                      lineHeight: "10px",
                                                                                      padding:
                                                                                        "0px 10px 20px 10px",
                                                                                      backgroundColor:
                                                                                        "#CDCDCD",
                                                                                    }}
                                                                                    valign="top"
                                                                                    align="center"
                                                                                  >
                                                                                    <a
                                                                                      href={idxDomain}
                                                                                      target="_blank"
                                                                                    >
                                                                                      <img
                                                                                        className="max-width"
                                                                                        border="0"
                                                                                        style={{
                                                                                          display: "block",
                                                                                          color: "#000000",
                                                                                          textDecoration:
                                                                                            "none",
                                                                                          fontFamily:
                                                                                            "Helvetica, arial, sans-serif",
                                                                                          fontSize: "16px",
                                                                                        }}
                                                                                        src={brokerImage}
                                                                                        alt=""
                                                                                        width="270"
                                                                                        data-proportionally-constrained="false"
                                                                                        data-responsive="false"
                                                                                      />
                                                                                    </a>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </center>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                  <table
                                                    className="module"
                                                    role="module"
                                                    data-type="text"
                                                    border="0"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    width="100%"
                                                    style={{ tableLayout: "fixed" }}
                                                    data-mc-module-version="2019-10-22"
                                                    data-muid="c5u3U8ucQ6yquUm6q35dqz"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            padding: "2px 0px 0px 0px",
                                                            lineHeight: "22px",
                                                            textAlign: "inherit",
                                                            backgroundColor: "#CDCDCD",
                                                          }}
                                                          height="100%"
                                                          valign="top"
                                                          bgcolor="#CDCDCD"
                                                        >
                                                          <div>
                                                            <div
                                                              style={{
                                                                fontFamily: "inherit",
                                                                textAlign: "center",
                                                              }}
                                                            >
                                                              <span
                                                                style={{ color: "#696969", fontSize: "12px" }}
                                                              >
                                                                You are receiving this email because you opted
                                                                in via our website.
                                                              </span>
                                                            </div>
                                                            <div
                                                              style={{
                                                                fontFamily: "inherit",
                                                                textAlign: "center",
                                                              }}
                                                            >
                                                              <span
                                                                style={{ color: "#696969", fontSize: "12px" }}
                                                              >
                                                                © {new Date().getFullYear()} All rights
                                                                reserved.
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </center>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </center>
  );
};

export default EmailBodyProperties;
