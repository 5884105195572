/* eslint-disable react/prop-types */
import React from "react";
import TextCell from "../../shared/TextCell";
import DateCell from "../../shared/DateCell";
import { Listing } from "../../../models";
import CurrencyCell from "../../shared/CurrencyCell";
import OptionsInlineCell from "../../shared/OptionsInlineCell";
import { isEditableAt } from "../../../utils/helpers";

const label = (name) => (
  <span
    className="
      tw-font-semibold
      tw-text-11d
      tw-text-neutral-gray-75
      tw-m-0
      tw-uppercase
    "
  >
    {name}:
  </span>
);

const financialCurrency = ({ value, isEditable, onChange }) => (
  <CurrencyCell value={value} isEditable={isEditable} onActivate={onChange} justify="start" />
);
const currency = ({ value, isEditable, onChange }) => (
  <CurrencyCell value={value} isEditable={isEditable} onChange={onChange} justify="start" />
);
const date = ({ value, isEditable, onChange }) => (
  <DateCell value={value} isEditable={isEditable} onChange={onChange} justify="start" />
);
const raw = ({ value, isEditable, onChange }) => (
  <TextCell value={value} isEditable={isEditable} onChange={onChange} justify="start" />
);
const dropdown = ({ value, isEditable, onChange, options }) => (
  <div className="tw-flex-grow">
    <OptionsInlineCell
      optionsList={options}
      onUpdateOption={onChange}
      selectedId={value}
      isEditable={isEditable}
      fullWidth
    />
  </div>
);

const fieldDictionary = {
  text: raw,
  date,
  currency,
  financialCurrency,
  options: dropdown,
};

const Field = ({ type, name, value, onChange, isEditable, options = null, propertyKey }) => (
  <div
    className="tw-space-x-4px tw-flex tw-flex-row tw-items-center"
    data-cy={`transaction-item-${propertyKey}`}
  >
    {label(name)}
    {(fieldDictionary[type] || raw)({ value, isEditable, onChange, options })}
  </div>
);

const collapsableProperties = [
  { name: "MLS#", type: "raw", key: "mlsNumber" },
  { name: "Expiration", type: "date", key: "dateExpired" },
  { name: "Close Date", type: "date", key: "closeDate" },
  { name: "DOM", type: "raw", key: "dom" },
  { name: "Source", type: "options", key: "sourceId" },
  { name: "Last Updated", type: "date", key: "lastUpdated" },
];

const uncollapsableProperties = [
  { name: "Date Listed", type: "date", key: "dateListed" },
  { name: "Listing Price", type: "currency", key: "listingPrice" },
  { name: "Close Price", type: "financialCurrency", key: "closePrice" },
  { name: "GCI", type: "financialCurrency", key: "grossCommission" },
];

const buildGenericField = (property, listing, listUpdater, sources) => ({
  ...property,
  value: listing[property.key],
  isEditable: isEditableAt(listing, property.key),
  onChange: (value) => listUpdater(property.key, value),
  options: property.type === "options" ? sources : null,
});

const ListingCardBody = ({ listing, collapsed, updateListing, sources }) => {
  const listUpdater = (key, value) => updateListing(listing)(new Listing({ ...listing, [key]: value }));
  const collapsableFields = collapsableProperties.map((property) =>
    buildGenericField(property, listing, listUpdater, sources),
  );
  const uncollapsableFields = uncollapsableProperties.map((property) =>
    buildGenericField(property, listing, listUpdater, sources),
  );
  const collapsableHeight = collapsed ? "tw-h-[0px]" : "tw-h-auto tw-z-10";

  return (
    <div className="tw-space-y-[4px]">
      {uncollapsableFields.map((f) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Field {...f} propertyKey={f.key} />
      ))}

      {!collapsed && (
        <div className={`${collapsableHeight} tw-space-y-[4px] tw-transition tw-overflow-y-visible tw-my-0`}>
          {collapsableFields.map((f) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Field {...f} propertyKey={f.key} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ListingCardBody;
