import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ModalUpload from "@shared/ModalUpload";
import { uploadDocumentAsThunk, renameDocumentAsThunk } from "../../Main/Docs/actions/thunks";
import {
  toggleUploadModal,
  toggleDeleteModal,
  toggleDocumentsSelected,
  setDocumentIndex,
  renameDocumentError,
} from "../../Main/Docs/actions/creators";

const DocModalUpload = ({ uploadedDocuments, isModalOpen }) => {
  const dispatch = useDispatch();

  const context = "documents-upload";

  const shouldShowAllCheckbox = useMemo(
    () => uploadedDocuments.some((doc) => !doc.isUploading),
    [uploadedDocuments],
  );

  const areAllDocumentsSelected = useMemo(
    () => uploadedDocuments.filter((doc) => !doc.isUploading).every((doc) => doc.isSelected),
    [uploadedDocuments],
  );

  const shouldShowBulkActions = useMemo(
    () => uploadedDocuments.some((doc) => doc.isSelected),
    [uploadedDocuments],
  );

  const handleSelectAllDocuments = (e) => {
    const documentIds = uploadedDocuments.filter((doc) => !doc.isUploading).map((doc) => doc.id);
    dispatch(toggleDocumentsSelected(context, documentIds, e.target.checked));
  };

  const handleBulkDelete = () => {
    const documentIds = uploadedDocuments.filter((doc) => doc.isSelected).map((doc) => doc.id);
    dispatch(toggleDeleteModal(documentIds, false));
  };

  const handleSelect = (isSelected, documentId) => {
    dispatch(toggleDocumentsSelected(context, [documentId], isSelected));
  };

  const handleRenameDocument = (documentId, newName) => {
    dispatch(renameDocumentAsThunk(context, documentId, newName));
  };

  const handleDeleteDocuments = (documentIds) => {
    dispatch(toggleDeleteModal(documentIds, false));
  };

  const handlePreview = (index) => {
    dispatch(setDocumentIndex(context, index));
  };

  const clearDocumentErrors = (documentId) => {
    dispatch(renameDocumentError(context, documentId, []));
  };

  return (
    <ModalUpload
      shouldShowAllCheckbox={shouldShowAllCheckbox}
      areAllDocumentsSelected={areAllDocumentsSelected}
      shouldShowBulkActions={shouldShowBulkActions}
      handleSelectAllDocuments={handleSelectAllDocuments}
      handleBulkDelete={handleBulkDelete}
      handleSelect={handleSelect}
      handleRenameDocument={handleRenameDocument}
      handleDeleteDocuments={handleDeleteDocuments}
      handlePreview={handlePreview}
      clearDocumentErrors={clearDocumentErrors}
      uploadedDocuments={uploadedDocuments}
      isModalOpen={isModalOpen}
      dispatchedThunkCb={(acceptedFiles) => {
        acceptedFiles.forEach((file, i) => {
          const data = {
            uploadKey: `upload-${i}`,
            fileName: file.name,
            isUploading: true,
            uploadErrors: [],
            renameErrors: [],
          };
          const formData = new FormData();
          formData.append("upload", file);
          formData.append("cda_form", false);
          dispatch(uploadDocumentAsThunk(data, formData, false));
        });
      }}
      onHideHandlr={() => dispatch(toggleUploadModal(false))}
      context={context}
    />
  );
};

const mapStateToProps = (state) => ({
  uploadedDocuments: state.tdpDocumentsReducer.uploadedDocuments,
  isModalOpen: state.tdpDocumentsReducer.isUploadModalOpen,
});

export default connect(mapStateToProps)(DocModalUpload);

DocModalUpload.propTypes = {
  uploadedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      uploadKey: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      isUploading: PropTypes.bool.isRequired,
      uploadErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
      renameErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};
