import PropTypes from "prop-types";

export const ContextPropType = PropTypes.oneOf([
  "documents-table",
  "documents-sidebar",
  "documents-upload",
  "documents-preview",
  "CDA-document",
]);

export const DocumentPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fileName: PropTypes.string,
  nakedName: PropTypes.string,
  fileExtension: PropTypes.string,
  fileType: PropTypes.string,
  imageIcon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  humanReadableSize: PropTypes.string,
  uploadedAt: PropTypes.string,
  expiringUrl: PropTypes.string,
  url: PropTypes.string,
  uploadedById: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uploadedByImageUrl: PropTypes.string,
  uploadedByName: PropTypes.string,
  uploadedByUrl: PropTypes.string,
  canRename: PropTypes.bool,
  canDelete: PropTypes.bool,
  viewersCount: PropTypes.arrayOf(PropTypes.number),
  renameErrors: PropTypes.arrayOf(PropTypes.string),
});

export const MetaPropType = PropTypes.shape({
  pagination: PropTypes.shape({
    current: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
    countPerPage: PropTypes.number,
  }),
  updatedAtMs: PropTypes.number,
});
