import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FieldHelper from "@shared/v2/FieldHelper";
import MentionablePersonTextArea from "@shared/mentions/MentionablePersonTextArea";
import AiAssistant from "@shared/AiAssistant";
import FieldLabel from "@shared/v2/FieldLabel";
import DncHelperText from "./DncHelperText";
import { useComment } from "../../reducers/dialerReducer/selectors";
import { setComment } from "../../reducers/dialerReducer/dialerReducer";

const CallNotes = ({ isDnc, mentionableIdOrUuid, mentionableType }) => {
  const dispatch = useDispatch();
  const note = useComment();
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <div className="tw-flex tw-justify-between tw-gap-[8px] tw-items-end">
        <FieldLabel label="Notes" />
        <AiAssistant
          messageType="Generic"
          textareaId="interaction-phone-input"
          onInsertClick={(text) => dispatch(setComment(text))}
        />
      </div>
      <MentionablePersonTextArea
        id="interaction-phone-input"
        value={note}
        isUuid
        mentionable_id={mentionableIdOrUuid}
        mentionable_type={mentionableType}
        onChange={(text) => dispatch(setComment(text))}
        isExternal={false}
      />
      {isDnc && <FieldHelper helperText={<DncHelperText />} />}
    </div>
  );
};

CallNotes.propTypes = {
  isDnc: PropTypes.bool,
  mentionableIdOrUuid: PropTypes.string,
  mentionableType: PropTypes.string,
};

CallNotes.defaultProps = {
  isDnc: false,
  mentionableIdOrUuid: "",
  mentionableType: "Person",
};

export default CallNotes;
