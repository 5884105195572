import React, { useState } from "react";
import PropTypes from "prop-types";
import Loader from "../../../Goals/components/Loader";
import { ChevronRight } from "../../../shared/v2/Icomoon";
import TextButton from "../../../shared/v2/TextButton";
import GoalModal from "../BusinessDashboard/GoalModal";
import RingChartCard from "./RingChartCard";
import { Section, SectionLabel } from "../BusinessDashboard/Section";
import useGoals from "./services/useGoals";
import Error from "./Error";

const GoalsSection = ({ agentUuid, year }) => {
  const [goalType, setGoalType] = useState(null);
  const { actions, data, error, loading } = useGoals({ agentUuid, year });

  return (
    <Section className="tw-relative tw-flex-1 tw-min-w-[320px]">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-[32px]">
        <SectionLabel>{agentUuid ? "" : "Team"} Goals</SectionLabel>
        <TextButton
          className="tw-flex tw-items-center tw-text-14d"
          schema="sentence"
          onClick={() => window.location.assign(`/settings/goals?year=${year}`)}
        >
          Goal settings <ChevronRight size="s" className="tw-mt-[2px] tw-ml-[2px]" />
        </TextButton>
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-[32px]">
        {data.map((d) => (
          <RingChartCard key={d.displayName} year={year} data={d} onSetGoal={() => setGoalType(d)} />
        ))}
      </div>
      <Error show={Boolean(error)} />
      {loading && <Loader />}
      <GoalModal
        year={year}
        open={Boolean(goalType)}
        goalType={goalType}
        onClose={() => setGoalType(null)}
        onGoalCreated={() => {
          actions.reload();
          setGoalType(null);
        }}
      />
    </Section>
  );
};

GoalsSection.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  agentUuid: PropTypes.string,
};

GoalsSection.defaultProps = {
  agentUuid: undefined,
};

export default GoalsSection;
