/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const HouseFlagSolidV6 = (props) => (
  <Icon {...props} name="House Flag Solid V6">
    <path d="M12 1.6a.8.8 0 0 1 .8.8h2.8a.4.4 0 0 1 .4.4V6c0 .22-.18.4-.4.4h-2.8v8h-1.6v-12a.8.8 0 0 1 .8-.8zm-1.6 12.8H8.8a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 0-.8-.8H5.6a.8.8 0 0 0-.8.8v2.4c0 .443-.357.8-.8.8H2.4a.8.8 0 0 1-.8-.8V8.8H.777c-.312 0-.612-.207-.728-.522s-.025-.668.23-.885l5.601-4.8a.798.798 0 0 1 1.04 0l3.48 2.982V14.4z" />
  </Icon>
);

export default HouseFlagSolidV6;
