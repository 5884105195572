import React from "react";
import PropTypes from "prop-types";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TYPE, PHOTO } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import ThumbnailIcon from "../../../../Transactions/components/shared/ThumbnailIcon";

/* eslint-disable camelcase */
const PhotoComp = ({ type, cloud_location }) =>
  cloud_location ? (
    <img
      className="tw-object-cover tw-absolute tw-top-0 tw-left-0"
      alt="listing"
      width="140px"
      height="116px"
      src={cloud_location}
    />
  ) : (
    <ThumbnailIcon
      transactionType={type}
      containerSize="full"
      size="custom"
      className="tw-w-[100px] tw-h-[100px]"
    />
  );

PhotoComp.propTypes = {
  cloud_location: PropTypes.string,
  type: PropTypes.string,
};

PhotoComp.defaultProps = {
  cloud_location: null,
  type: null,
};
const Photo = tdpDetails({
  WrappedComponent: PhotoComp,
  states: [TYPE, PHOTO],
});
export default Photo;
