import React from 'react';
import axios from 'axios';
import BulkReportsOptionsModal from './BulkReportsOptionsModal';
import BulkReportsStatusModal from './BulkReportsStatusModal';
import BulkReportsNoPlatformModal from './BulkReportsNoPlatformModal';
import HubspotRequestDemoModal from '../../shared/HubspotRequestDemoModal';

class BulkApplyMarketReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      optionsModalOpen: false,
      statusModalOpen: false,
      noPlatformModalOpen: false,
      selectedLeads: [],
      validAddressCount: 0,
      validReportCount: 0,
      validIdsWithAddresses: [],
      validIdsWithReports: [],
      mode: 'create',
      showGetDemo: false
    }
  }

  closeOptionsModal = () => {
    this.setState({optionsModalOpen: false})
  }

  changeReports = (data) => {
    const path = `/bulk_market_reports/bulk_change_reports`

    axios.post(path, { authenticity_token: ReactOnRails.authenticityToken(), data: data })
    .then(res => console.log('Bulk Change Reports Done',res))
    .catch(err => console.log('ERROR',err))
  }

  createReports = (data) => {
    const path = `/bulk_market_reports/bulk_create_reports`

    axios.post(path, { authenticity_token: ReactOnRails.authenticityToken(), data: data })
    .then(res => console.log('Bulk Create Reports Done',res))
    .catch(err => console.log('ERROR',err))
  }

  showStatusModal = (data, mode) => {
    if (mode === 'create') {
      data.contacts = this.state.validIdsWithAddresses
      this.createReports(data)
    }
    else if (mode === 'change') {
      data.contacts = this.state.validIdsWithReports
      this.changeReports(data)
    }
    this.setState({optionsModalOpen: false, statusModalOpen: true, mode: mode})
  }

  closeStatusModal = () => {
    this.setState({statusModalOpen: false})
  }

  getValidLeads = () => {
    const path = `/bulk_market_reports/valid_leads`
    let ids = Brivity.PeoplePage.grid().multiSelect().getSelectedObjectIds()

    axios.get(path, { params: { people_ids: ids }})
    .then(res => {
      this.setState({
        optionsModalOpen: true,
        validAddressCount: res.data.withAddress.length,
        validIdsWithAddresses: res.data.withAddress,
        validReportCount: res.data.withReport.length,
        validIdsWithReports: res.data.withReport,
        selectedLeads: ids
      })
    })
  }

  closeNoPlatformModal = (cancel) => {
    if (!cancel) {
      this.setState({noPlatformModalOpen: false, showGetDemo: true})
    }
    else {
      this.setState({noPlatformModalOpen: false })
    }
  }

  onClick = () => {
    if (this.props.platform) {
        this.getValidLeads()
    }
    else {
      this.setState({noPlatformModalOpen: true})
    }
  }

  renderOptionsModal = () => {
    return this.state.optionsModalOpen &&
    <BulkReportsOptionsModal
      selectedLeads={this.state.selectedLeads}
      validAddressCount={this.state.validAddressCount}
      validReportCount={this.state.validReportCount}
      showStatusModal={this.showStatusModal}
      closeOptionsModal={this.closeOptionsModal} />
  }

  renderStatusModal = () => {
    return this.state.statusModalOpen &&
    <BulkReportsStatusModal
      validAddressCount={this.state.validAddressCount}
      validReportCount={this.state.validReportCount}
      mode={this.state.mode}
      closeStatusModal={this.closeStatusModal} />
  }

  renderNoPlatformModal = () => {
    return this.state.noPlatformModalOpen &&
      <BulkReportsNoPlatformModal
        closeNoPlatformModal={this.closeNoPlatformModal} />
  }

  closeRequestDemo = () => {
    this.setState({showGetDemo: false})
  }

  render() {
    return (
      <div>
        <div onClick={this.onClick}>
          Market Report
        </div>
        {this.renderNoPlatformModal()}
        {this.renderOptionsModal()}
        {this.renderStatusModal()}
        <HubspotRequestDemoModal show={this.state.showGetDemo} closeModal={this.closeRequestDemo} />
      </div>
    )
  }
}

export default BulkApplyMarketReports;
