import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChevronDown, ChevronRight, Plus } from "../../../shared/v2/Icomoon";
import IconButton from "../../../shared/v2/IconButton";
import { toggleUploadModal } from "../../Main/Docs/actions/creators";
import { getDocumentsAsThunk, getSidebarDocumentsAsThunk } from "../../Main/Docs/actions/thunks";

import ModalDeleteDocuments from "../../Main/Docs/ModalDeleteDocuments";
import { MetaPropType } from "../../Main/Docs/propTypes";
import { usePagePathName } from "../../Main/hooks";

import SidebarTable from "./SidebarTable";

const Documents = ({ meta, sidebarMeta, dispatch }) => {
  const { pagePathName } = usePagePathName();

  const [isExpanded, setExpanded] = useState(false);

  const Icon = isExpanded ? ChevronDown : ChevronRight;

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setExpanded(!isExpanded);
    }
  };

  const handleOpenUploadModal = () => {
    dispatch(toggleUploadModal(true));
  };

  useEffect(() => {
    if (!meta.pagination.current && pagePathName && pagePathName !== "docs") {
      // Only fetch documents if they haven't been fetched yet,
      // and only if the documents page isn't open
      dispatch(getDocumentsAsThunk());
    }
  }, [meta.pagination.current, pagePathName]);

  useEffect(() => {
    if (sidebarMeta.pagination.current > 0) {
      // Document URLs expire every 600 seconds (from `DocumentDecorator`)
      const expireAtMs = sidebarMeta.updatedAtMs + 600000;

      // Refresh documents to prevent URLs from expiring for when previewing/downloading
      const timeoutMs = Math.max(0, expireAtMs - Date.now());
      const timeoutId = window.setTimeout(() => dispatch(getSidebarDocumentsAsThunk(), true), timeoutMs);
      return () => window.clearTimeout(timeoutId);
    }

    return () => {};
  }, [sidebarMeta.pagination.current, sidebarMeta.updatedAtMs]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-16px">
      <div className="tw-flex tw-justify-between tw-items-center">
        <div
          className="tw-cursor-pointer"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          data-cy="documents-sidebar-header"
        >
          <h2 className="tw-m-0 tw-text-18d tw-font-bold tw-text-neutral-gray-75">
            <Icon className="tw-text-theme-primary" size="l" />
            <span className="tw-ml-8px">
              Documents (
              <span data-cy="documents-sidebar-header-count">{sidebarMeta.pagination.totalCount}</span>)
            </span>
          </h2>
        </div>

        <IconButton
          size="small"
          schema="tertiary"
          onClick={handleOpenUploadModal}
          data-cy="documents-sidebar-upload-button"
        >
          <Plus size="s" />
        </IconButton>
      </div>

      {isExpanded && <SidebarTable />}
      <ModalDeleteDocuments />
    </div>
  );
};

const mapStateToProps = (state) => ({
  meta: state.tdpDocumentsReducer.meta,
  sidebarMeta: state.tdpDocumentsReducer.sidebarMeta,
});

Documents.propTypes = {
  meta: MetaPropType,
  sidebarMeta: MetaPropType,
  dispatch: PropTypes.func,
};

Documents.defaultProps = {
  meta: {},
  sidebarMeta: {},
  dispatch: () => {},
};

export default connect(mapStateToProps)(Documents);
