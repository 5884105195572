import React from "react";
import PropTypes from "prop-types";
import { CaretDown, CaretUp } from "../../shared/v2/Icomoon";

const SortCarets = ({ isActive, dir }) => (
  <div className="tw-inline-flex tw-flex-col tw-align-middle">
    <CaretUp className={`tw-mb-[-3px] ${isActive && dir === "asc" ? "tw-text-black" : ""}`} />
    <CaretDown className={`tw-mt-[-2px] ${isActive && dir === "desc" ? "tw-text-black" : ""}`} />
  </div>
);

export default SortCarets;

SortCarets.propTypes = {
  isActive: PropTypes.bool.isRequired,
  dir: PropTypes.string.isRequired,
};
