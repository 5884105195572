import React from "react";
import { func, string } from "prop-types";

import TextButton from "../../../../shared/v2/TextButton";
import { PlusSolidV6 } from "../../../../shared/v2/Icomoon";

const AddValueTxtBtn = ({ onClick, text, iconClassName, btnClassName }) => (
  <TextButton className={`tw-inline-flex tw-items-center tw-px-0 ${btnClassName}`} onClick={onClick}>
    <PlusSolidV6 size="s" className={iconClassName} />
    <span className="tw-text-11px tw-leading-16px tw-ml-4px">{text}</span>
  </TextButton>
);

AddValueTxtBtn.propTypes = {
  onClick: func.isRequired,
  text: string,
  iconClassName: string,
  btnClassName: string,
};

AddValueTxtBtn.defaultProps = {
  text: "ADD",
  iconClassName: "",
  btnClassName: "",
};

export default AddValueTxtBtn;
