import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

const RoleSelectInput = (props) => {
  const { onChange, options, validationState, value } = props

  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} md={2}>Role</Col>

      <Col md={10}>
        <FormControl
          componentClass="select"
          placeholder="select"
          value={value}
          onChange={onChange}
        >
          { options.map((o) => <option key={o} value={o}>{o}</option>) }
        </FormControl>
      </Col>
    </FormGroup>
  )
}

export default RoleSelectInput;
