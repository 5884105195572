import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PreviewPlaceholder from "./PreviewPlaceholder";

const PreviewImage = ({ url, name }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
  }, [url]);

  useEffect(() => {
    const img = document.createElement("img");
    img.onload = () => setLoading(false);
    img.onerror = () => setError(true);
    img.onabort = () => setError(true);
    img.src = url;
  }, [url]);

  if (error) {
    return <PreviewPlaceholder>Failed to load document.</PreviewPlaceholder>;
  }

  if (loading) {
    return <PreviewPlaceholder>Loading document...</PreviewPlaceholder>;
  }

  return (
    <div
      // 612px and 792px are defined in the Figma designs
      className="tw-flex tw-justify-center tw-items-center tw-w-[612px] tw-h-[792px]"
    >
      <img className="tw-max-w-[612px] tw-max-h-[792px]" src={url} alt={name} />
    </div>
  );
};

PreviewImage.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PreviewImage;
