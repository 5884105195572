import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../../shared/v2/Tooltip";
import { Info } from "../v2/Icomoon";

function FieldLabelV1({ label, required, toolTipInfoContent, toolTipPlacement, title }) {
  return (
    <div className="tw-flex tw-mb-2 tw-gap-[4px]">
      <div
        className="tw-text-14px tw-font-semibold tw-text-gray-50 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
        title={title}
      >
        {label}
      </div>
      {required && <div className="tw-text-14px tw-font-bold tw-text-brivity-coral-100">*</div>}
      {toolTipInfoContent && (
        <ToolTip
          content={toolTipInfoContent}
          trigger={<Info size="m" className="tw-text-theme-datepicker-text-color" />}
          placement={toolTipPlacement}
        />
      )}
    </div>
  );
}
FieldLabelV1.propTypes = {
  toolTipPlacement: PropTypes.string,
  toolTipInfoContent: PropTypes.element,
  label: PropTypes.string,
  required: PropTypes.bool,
};

FieldLabelV1.defaultProps = {
  toolTipInfoContent: null,
  toolTipPlacement: "top",
  label: "",
  required: false,
};

export default FieldLabelV1;
