import React, { useRef, useState } from "react";
import moment from "moment";
import { bool, func } from "prop-types";
import { useClickOutside } from "../shared/hookHelpers";
import { DateSelectorWithOptions } from "../shared/DateSelectorWithOptions";

const useClickOutsideCalendar = (cancelViewingCalendarHandlr) => {
  const target = useRef(null);
  useClickOutside(target, cancelViewingCalendarHandlr);

  return target;
};

const useDate = () => {
  const [date, setDate] = useState(moment().format("MM/DD/YYYY"));
  const onDateChangeHandlr = (e) => setDate(e.date);

  return { date, onDateChangeHandlr };
};

const CustomTimeFrameDateSelector = ({ show, cancelViewingCalendarHandlr, submitHandlr }) => {
  const target = useClickOutsideCalendar(cancelViewingCalendarHandlr);
  const { date, onDateChangeHandlr } = useDate();
  const clearHandlr = () => onDateChangeHandlr({ date: "" });

  return (
    <DateSelectorWithOptions
      show={show}
      refOuter={target}
      dateSelectorValue={date}
      clearHandlr={clearHandlr}
      submitHandlr={() => submitHandlr(date)}
      dateSelectorOnChangeHandlr={onDateChangeHandlr}
    />
  );
};

export default CustomTimeFrameDateSelector;

CustomTimeFrameDateSelector.propTypes = {
  show: bool,
  cancelViewingCalendarHandlr: func,
  submitHandlr: func,
};

CustomTimeFrameDateSelector.defaultProps = {
  show: false,
  cancelViewingCalendarHandlr: () => {},
  submitHandlr: () => {},
};
