import React, { useContext } from "react";
import ImportDetailModal from "../../TransactionDetailsPage/Main/Details/ImportModal/ImportDetailModal";
import actions from "../actions";
import { UpdateFromPipelineContext } from "./Wrapper";

const ImportListingModal = () => {
  const { state, dispatch } = useContext(UpdateFromPipelineContext);

  const closeModal = () => {
    dispatch(
      actions.importModal.toggleImportModal(
        false,
        state?.importModal?.listing,
        state?.importModal?.updatedStatus,
      ),
    );
  };

  const handleCloseModal = () => {
    closeModal();
    if (!state?.importModal?.listing?.address?.streetAddress) {
      dispatch(
        actions.updateAddressModal.toggleUpdateAddressModal(
          true,
          state?.importModal?.updatedStatus,
          state?.importModal?.listing,
        ),
      );
    }
  };

  return (
    <ImportDetailModal
      isImportModalOpen={state?.importModal?.isImportModalOpen}
      closeModalAsThunk={handleCloseModal}
      status={state?.importModal?.updatedStatus}
      isFromIndex
      listingUuid={state?.importModal?.listing?.uuid}
      closeCurrentModal={closeModal}
      preStatus={state?.importModal?.listing?.status}
    />
  );
};

export default ImportListingModal;
