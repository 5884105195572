import axios from "axios";
import { sortBy } from "lodash";
import { useEffect, useRef, useState } from "react";

const headers = {
  "content-type": "application/json",
  accept: "application/json",
};

export const getDashboardData = (params, signal) =>
  axios.get("/dashboard", {
    headers,
    params,
    signal,
  });

export const markEventDismissed = (id) =>
  axios.post(
    "/mark_event_dismissed",
    {
      auto_plan_uuid: id,
      authenticity_token: ReactOnRails.authenticityToken(),
    },
    { headers },
  );

const useRecentActivity = ({ eventType, userUuids }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const prevEventType = useRef();

  useEffect(() => {
    const startPage = eventType === "timelines" ? 0 : 1;
    if (prevEventType.current !== eventType) {
      prevEventType.current = eventType;
      if (page !== startPage) {
        setPage(startPage);
        return () => {};
      }
    }

    const abortController = new AbortController();
    setLoading(true);
    getDashboardData(
      {
        event_type: eventType,
        page,
        user_uuids: userUuids.filter((uuid) => uuid !== "all"),
      },
      abortController.signal,
    )
      .then((res) => {
        setData((currentData) =>
          sortBy(page === startPage ? res.data : [...currentData, ...res.data], "time_ago_epoch"),
        );
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setLoading(false);
          setError(true);
        }
      });
    return () => abortController.abort();
  }, [eventType, page, userUuids]);

  const actions = {
    clearError: () => setError(null),
    dismissEvent: (event) => {
      setLoading(true);
      markEventDismissed(event.id)
        .then(() =>
          setData((currentData) =>
            currentData.map((e) => (e.id === event.id ? { ...e, dismissed: true } : e)),
          ),
        )
        .catch()
        .finally(() => setLoading(false));
    },
    loadMore: () => setPage((p) => p + 1),
  };

  return { actions, data, error, eventType, loading };
};

export default useRecentActivity;
