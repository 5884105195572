import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "../../shared/v1";
import CheckBox from "../../shared/CheckBox";
import ProfileIcon from "../../shared/ProfileIcon";
import * as TaskHelpers from "./TaskIndexModalHelpers";
import Moment from "react-moment";
import TaskSettings from "./TaskSettings";
import TaskTaskable from "./TaskTaskable";
import capitalize from "capitalize";
import { stripHtml } from "../../shared/Utilities";
import Tooltip from "../../shared/v2/Tooltip";

export default class Task extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showDeleteModal: false,
      task: props.task,
      user: props.user,
      agents: props.agents,
      showModal: false,
      taskable_name: "",
      cancel_get_user: null,
      cancel_taskable: null,
    };
    this.multiSelectWatcher = this.props.multiSelectWatcher.bind(this);
    this.selectAll = TaskHelpers.selectAll.bind(this);
    this.selectNone = TaskHelpers.selectNone.bind(this);
    this.taskMultiSelect = TaskHelpers.taskMultiSelect.bind(this);
    this.deleteTasks = TaskHelpers.deleteTask.bind(this);
    this.swapForDelete = TaskHelpers.swapForDelete.bind(this);
    this.showModal = TaskHelpers.showModal.bind(this);
    this.showModalComplete = TaskHelpers.showModalComplete.bind(this);
    this.closeModal = TaskHelpers.closeModal.bind(this);
    this.handleTaskChange = TaskHelpers.handleTaskChange.bind(this);
    this.showDetailModal = TaskHelpers.showDetailModal.bind(this);
    this.closeDetailModal = TaskHelpers.closeDetailModal.bind(this);
    this.swapModals = TaskHelpers.swapModals.bind(this);
    this.showDeleteModal = TaskHelpers.showDeleteModal.bind(this);
    this.closeDeleteModal = TaskHelpers.closeDeleteModal.bind(this);
    this.getTasks = this.props.getTasks.bind(this);
    this.completeTask = TaskHelpers.completeTask.bind(this);
    this.uncompleteTask = TaskHelpers.uncompleteTask.bind(this);
    this.renderDeleteModal = TaskHelpers.renderDeleteModal.bind(this);
    this.renderDetailModal = TaskHelpers.renderDetailModal.bind(this);
    this.renderModal = TaskHelpers.renderModal.bind(this);
    this.showReassignModal = TaskHelpers.showReassignModal.bind(this);
    this.closeReassignModal = TaskHelpers.showReassignModal.bind(this);
    this.setAgentsForModal = TaskHelpers.setAgentsForModal.bind(this);
    this.handleMultiSelect = TaskHelpers.handleMultiSelect.bind(this);
    this.weeklyOptions = TaskHelpers.weeklyOptions.bind(this);
    this.handleRecurrence = TaskHelpers.handleRecurrence.bind(this);
    this.replaceURLWithHTMLLinks = TaskHelpers.replaceURLWithHTMLLinks.bind(this);
  }

  componentWillUnmount() {
    this.state.cancel_taskable && this.state.cancel_taskable();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, ...nextProps });
  }

  milestoneOptions = () => {
    const { accountMilestoneNames } = this.props;

    const personNames = accountMilestoneNames["Person"];
    const listingNames = accountMilestoneNames["Listing"];
    const projectNames = accountMilestoneNames["Project"];

    const allNamesArray = personNames.concat(listingNames).concat(projectNames);

    let allNamesObject = {};

    allNamesArray.forEach((name) => {
      allNamesObject[name] = name
        .split("_")
        .map((element) => capitalize(element))
        .join(" ");
    });

    return allNamesObject;
  };
  showErrors = (messages) => {
    this.setState({ error_messages: messages });
  };
  render() {
    const { task, user } = this.state;
    return (
      <Row
        className={`striped td task ${task.row_style ? task.row_style : ""}`}
        data-object-id={task.id}
        data-contingent={task.check_contingency}
        data-dueness={this.props.dueness}
        data-incomplete-dueness={this.props.dueness}
        data-name={task.name}
      >
        <Col size={3}>
          <div className={this.props.nameColumnClass}>
            <Row deprecatedOverrideMarginClasses="tw-mx-0">
              {/* check box select */}
              <Col size={1} deprecatedOverridePaddingClasses="tw-px-0">
                <CheckBox
                  divClass={this.props.dueness + " fancy"}
                  labelClass={`${
                    task.state == "completed"
                      ? "complete tw-text-brand-success checked"
                      : "incomplete unchecked"
                  }`}
                  inputClass={"checkbox-input"}
                  inputChecked={task.state == "completed" && "checked"}
                  objectToCheck={task}
                  inputName={"complete"}
                  onUpdate={task.type !== "Appointment" ? this.handleTaskChange : this.showModalComplete}
                />
              </Col>

              {/* Task name or link to Agent */}
              <Col
                size={11}
                deprecatedOverridePaddingClasses="tw-px-0"
                className="tw-overflow-hidden tw-whitespace-nowrap"
              >
                {task.assigned_plan_id && (
                  <span>
                    <i className={`fa fa-bolt m-r-5`} style={{ color: "#59c4c4" }} />
                  </span>
                )}

                {this.props.agent && (
                  <a style={{ cursor: "pointer" }} onClick={this.showDetailModal}>
                    {stripHtml(task.name)}
                  </a>
                )}
                {!this.props.agent && task.name}
                <div className="task css_fade_out" />
              </Col>
            </Row>
          </div>
        </Col>

        <Col size={1} className="tw-text-center tw-overflow-hidden tw-whitespace-nowrap">
          {task.type}
        </Col>

        <Col size={1} className="tw-text-center">
          <span>
            {task.priority && <img src={`/images/task_priorities/${task.priority}.svg`} />}
            {task.priority == null && <img src={`/images/task_priorities/empty.svg`} />}
          </span>
        </Col>

        {/* Task Date */}
        <Col size={1} className="tw-text-center tw-overflow-hidden tw-whitespace-nowrap">
          {task.state == "completed" && <Moment format="MM/DD/YYYY" date={task.completed_at} />}
          {task.state != "completed" && (
            <div>
              {task.calculated_due_on && <Moment format="MM/DD/YYYY" date={task.calculated_due_on} />}
              {!task.calculated_due_on && (
                <Pluralize plural="days" singular="day" count={parseInt(task.contingency_rule["days"])} />
              )}
              {!task.calculated_due_on && " " + task.contingency_rule["position"] + " "}
              {!task.calculated_due_on &&
                (task.parent_task_name
                  ? task.parent_task_name
                  : task.contingency_event_and_type[1] != undefined &&
                    this.milestoneOptions()[task.contingency_event_and_type[1]])}
            </div>
          )}
        </Col>

        {/* Person image or default */}
        <Col size={1} className="tw-text-center who">
          {task.link && <ProfileIcon link={task.link} name={task.user_name} />}
          {task.initials && <ProfileIcon link={task.link} name={task.user_name} initials={task.initials} />}
          {!task.link && !task.initials && (
            <Tooltip
              placement="right"
              trigger={<img src="/images/users/team.svg" className="tooltipper" />}
              content="Anyone"
            />
          )}
        </Col>

        <Col size={2} className="tw-overflow-hidden tw-whitespace-nowrap">
          {task.taskable_link && (
            <TaskTaskable link={task.taskable_link} taskable_name={task.taskable_name || "No Name"} />
          )}
        </Col>
        {/* Task Settings */}
        <TaskSettings col_size={1} task={task} />

        {/* Task Edit */}
        <Col size={1}>
          <span>
            <a onClick={this.showModal}>
              <i className={"fa fa-pencil m-r-5"} />
            </a>
          </span>
          {
            <span>
              <a href={"javascript:void(0)"} onClick={this.showDeleteModal}>
                <i className={"fa fa-times"} />
              </a>
            </span>
          }
        </Col>

        {/* Multi-select */}
        <Col size={1} className="tw-text-center checkbox-centerer">
          <CheckBox
            divClass={"demarginify task-multi-select tw-text-center checkbox-aligner"}
            labelClass={task.multi_select ? "checked" : "unchecked"}
            inputClass={"checkbox-input multi-select"}
            inputChecked={task.multi_select && "checked"}
            objectToCheck={task}
            inputName={"multi_select"}
            onUpdate={this.handleTaskChange}
          />
        </Col>
        {this.renderModal()}
        {this.renderDeleteModal()}
        {this.renderDetailModal()}
      </Row>
    );
  }
}

Task.propTypes = {
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

Task.defaultProps = {
  task: {},
  user: { name: "No Name" },
  nameColumnClass: "task-column",
  taskCssStyle: "task",
};
