/* eslint-disable no-plusplus */
import moment from "moment";

import { VALID_DATE_FORMATS, DATE_FORMAT, TIMES } from "./constants";

export const getWeeksInMonth = (date) => {
  // Get first and last days from previous/next months to fill the table on CalendarMonth component
  const firstDay = date.clone().startOf("month").weekday(0);
  const lastDay = date.clone().endOf("month").weekday(6);
  const totalDays = lastDay.diff(firstDay, "days") + 1;

  const currentDay = firstDay.clone();
  const weeksInMonth = [];

  for (let i = 0; i < totalDays; i += 1) {
    if (i % 7 === 0) {
      weeksInMonth.push([]);
    }

    weeksInMonth[weeksInMonth.length - 1].push(currentDay.clone());
    currentDay.add(1, "day");
  }

  return weeksInMonth;
};

export const getDateFormat = (dateFormat) => {
  if (dateFormat !== "") {
    return dateFormat;
  }

  return DATE_FORMAT;
};

export const getMutatedDateFormat = (dateFormat) => getDateFormat(dateFormat).replace(/-/g, "/");

export const getValidDateFormats = (dateFormat) => {
  if (dateFormat === "") {
    return VALID_DATE_FORMATS;
  }

  return [dateFormat, ...VALID_DATE_FORMATS];
};

export const getNextPossibleDateSelectorTime = (lastPossibleTime) => {
  if (lastPossibleTime === TIMES[TIMES.length - 1]) {
    return TIMES[0];
  }

  const index = TIMES.indexOf(lastPossibleTime);

  if (index === -1) {
    return lastPossibleTime;
  }

  return TIMES[index + 1];
};

export const getClosestTime = () => {
  const timeNow = moment();
  const minutes = timeNow.minutes();
  timeNow.minutes(minutes - (minutes % 15));
  return timeNow.format("hh:mm A");
};

export const meridianCount = (datetime, enableTimePicker) => {
  if (!enableTimePicker) {
    return 0;
  }

  const times = datetime.split(" ");

  let count = 0;

  times.forEach((time) => {
    if (time.toLowerCase().match(/am|pm/) && enableTimePicker) {
      count++;
    }
  });

  return count;
};

export const getStartTime = (datetime, enableTimePicker) => {
  const times = datetime.split(" ");

  if (times.length >= 3 && datetime.toLowerCase().match(/am|pm/) && enableTimePicker) {
    return `${times[1]} ${times[2]}`;
  }

  return "";
};

export const getEndTime = (datetime, enableEndTimePicker) => {
  const times = datetime.split(" ");

  if (times.length === 5 && datetime.toLowerCase().match(/am|pm/) && enableEndTimePicker) {
    return `${times[3]} ${times[4]}`;
  }

  return "";
};

/**
 * @summary format user input to automatically add forward slashes. Offer default support for separators `/` as default and `-` for backwards compatibility.
 * @return {string} formatted date string with forward slashes `/`.
 * @param {string} allowedCharsUserInput - a string with only numbers, forward slash and - characters
 * @param {string} format - format to apply to the date (MM-DD-YYYY or YYYY-MM-DD etc.)
 */
export const maskUserInput = (allowedCharsUserInput, format) => {
  if (format.length === 0) {
    return allowedCharsUserInput;
  }

  let formattedUserInput = "";
  let separatorCounter = 1;
  const cleanUserInput = allowedCharsUserInput.replace(/[/]|[-]/g, "");

  for (let i = 0; i < cleanUserInput.length; i++) {
    if (format[i + separatorCounter] === "/" || format[i + separatorCounter] === "-") {
      separatorCounter++;
      formattedUserInput += `${cleanUserInput[i]}/`;
    } else {
      formattedUserInput += cleanUserInput[i];
    }
  }

  return formattedUserInput;
};

export const formatDatetime = (state, dateFormat) => {
  if (state.impureDate === "" || !moment(state.impureDate, dateFormat).isValid()) {
    return state.impureDate;
  }

  const formatedDate = moment(state.impureDate, dateFormat).format(getMutatedDateFormat(dateFormat));

  if (
    state.showTimePicker &&
    state.temporaryStartTime !== "" &&
    state.showEndTimePicker &&
    state.temporaryEndTime !== ""
  ) {
    return `${formatedDate} ${state.temporaryStartTime} ${state.temporaryEndTime}`;
  }

  if (state.showTimePicker && state.temporaryStartTime !== "") {
    return `${formatedDate} ${state.temporaryStartTime}`;
  }

  return formatedDate;
};

export const formatDate = (rawFormattedDate, dateFormat) => {
  if (typeof rawFormattedDate !== "string" || rawFormattedDate === "") return "";

  const [onlyDate, ...rest] = rawFormattedDate.split(" ");
  const onlyTime = rest.join(" ");

  if (onlyDate === "") {
    return onlyDate;
  }

  const formattedDate = moment(onlyDate, getValidDateFormats(dateFormat)).format(
    getMutatedDateFormat(dateFormat),
  );

  if (onlyTime !== "") {
    return `${formattedDate} ${onlyTime}`;
  }

  return formattedDate;
};
