/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const UserGroupSolidV6 = (props) => (
  <Icon {...props} name="User Group Solid V6">
    <path d="M5.6 8a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4zm1.268 1.2H4.333a4.332 4.332 0 0 0-4.332 4.333c0 .48.388.867.867.867h9.466c.48 0 .868-.387.868-.867A4.333 4.333 0 0 0 6.87 9.2zm5.11.4h-1.846a5.065 5.065 0 0 1 1.869 3.933c0 .32-.095.615-.25.867h3.45c.443 0 .8-.36.8-.822 0-2.188-1.79-3.978-4.022-3.978zM10.8 8c1.548 0 2.8-1.252 2.8-2.8s-1.252-2.8-2.8-2.8c-.628 0-1.201.214-1.668.563.288.553.468 1.172.468 1.837 0 .888-.298 1.704-.79 2.368.507.512 1.21.832 1.99.832z" />
  </Icon>
);

export default UserGroupSolidV6;
