import { useEffect, useMemo, useState } from "react";
import { caseTransformingAxios } from "../../../../shared/v2/caseTransformingAxios";

const getSummary = (signal, opts) =>
  caseTransformingAxios.get("/api/v4/reporting/operator_dashboard", {
    params: { section: "summary", ...opts },
    signal,
  });

const useSummary = ({ year }) => {
  const [state, _setState] = useState({ data: null, error: null, loading: false });
  const setState = useMemo(
    () => (newState) => _setState((oldState) => ({ ...oldState, ...newState })),
    [_setState],
  );

  useEffect(() => {
    const abortController = new AbortController();
    setState({ loading: true });
    getSummary(abortController.signal, { selected_year: year })
      .then((res) => setState({ data: res.data, error: null, loading: false }))
      .catch(() => setState({ error: true, loading: false }));

    return () => abortController.abort();
  }, [year]);

  return {
    ...state,
    actions: {
      clearError: () => setState({ error: null }),
    },
  };
};

export default useSummary;
