import React from "react";
import PropTypes from "prop-types";

export default class CCSearchResults extends React.Component {
  shortQuery = () => {
    const { type, search_query: searchQuery, search_query_length: searchQueryLength } = this.props;
    switch (type) {
      case "email":
        return (
          <div className="cc-search-results-container">
            <div className="cc-search-result">Invalid email {searchQuery}</div>
          </div>
        );
      default:
        return (
          <div className="cc-search-results-container">
            <div className="cc-search-result">Enter {3 - searchQueryLength} more characters to search</div>
          </div>
        );
    }
  };

  noResults = () => {
    const { type, search_query: searchQuery } = this.props;
    switch (type) {
      case "email":
        return (
          <div className="cc-search-results-container">
            <div className="cc-search-result">Invalid email {searchQuery}</div>
          </div>
        );
      case "role":
        return (
          <div className="cc-search-results-container">
            <div className="cc-search-result">Invalid role {searchQuery}</div>
          </div>
        );
      default:
        return (
          <div className="cc-search-results-container">
            <div className="cc-search-result">No results for {searchQuery}</div>
          </div>
        );
    }
  };

  render() {
    const {
      search_query_length: searchQueryLength,
      width,
      results,
      highlightedIndex,
      highlight,
      unhighlight,
      selectObject,
    } = this.props;

    return (
      searchQueryLength > 0 && (
        <div
          style={{ width }}
          className="cc-search-results-container cc-multi-select-dropdown selectivity-results-container"
        >
          <div className="selectivity-results">
            {searchQueryLength < 3 && this.shortQuery()}
            {results.length === 0 && searchQueryLength > 2 && this.noResults()}
            {results.map((result, index) => (
              // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className={`${highlightedIndex === index && "highlighted-result "} cc-search-result`}
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                onMouseOver={() => highlight(index)}
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                onMouseOut={() => unhighlight(index)}
                onClick={() => selectObject(result)}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <i className={`${result.icon} ${result.color}`} />
                {result.label}
                {!!result.email && ` ${result.email}`}
                {!!result.type && result.cc_type === "Person" && (
                  <span className={result.color}> ({result.type})</span>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    );
  }
}

CCSearchResults.propTypes = {
  highlight: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.arrayOf(PropTypes.any).isRequired,
  search_query: PropTypes.string.isRequired,
  search_query_length: PropTypes.number.isRequired,
  selectObject: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  unhighlight: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};
