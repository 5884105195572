const inputUploadFn =
  ({ onError, awsDirectPost, uploadFiles, onAdd, onSave }) =>
  (e) =>
    uploadFiles({
      files: Array.from(e.target.files),
      config: {
        ...awsDirectPost,
      },
      onAdd: (file) => {
        onAdd(file);
      },
      onSuccess: (file) => {
        onSave(file);
      },
      onError: (fileError) => {
        onError(fileError);
      },
    });
export default inputUploadFn;
