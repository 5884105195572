import React from "react";
import { bool, func } from "prop-types";
import IconButton from "../../../../shared/v2/IconButton";
import StarFilled from "../../../../shared/v2/Icomoon/Icons/StarFilled";
import StarOutline from "../../../../shared/v2/Icomoon/Icons/StarOutline";
import VisibilityVisible from "../../../../shared/v2/Icomoon/Icons/VisibilityVisible";
import VisibilityHidden from "../../../../shared/v2/Icomoon/Icons/VisibilityHidden";

const HeaderOptions = ({ starred, setStarred, externallyVisible, setExternallyVisible }) => (
  <div
    className="tw-h-[24px] tw-flex 
    tw-ml-auto"
  >
    <IconButton
      type="button"
      size="small"
      schema={externallyVisible ? "primary" : "tertiary"}
      onClick={() => setExternallyVisible(!externallyVisible)}
    >
      {externallyVisible ? <VisibilityVisible size="l" /> : <VisibilityHidden size="l" />}
    </IconButton>
    <IconButton
      type="button"
      size="small"
      schema={starred ? "starred-yellow" : "tertiary"}
      onClick={() => setStarred(!starred)}
      className="tw-ml-[6px]"
    >
      {starred ? <StarFilled size="l" /> : <StarOutline size="l" />}
    </IconButton>
  </div>
);

HeaderOptions.propTypes = {
  starred: bool.isRequired,
  setStarred: func.isRequired,
  externallyVisible: bool.isRequired,
  setExternallyVisible: func.isRequired,
};
export default HeaderOptions;
