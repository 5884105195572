import { useEffect, useState } from "react";

import { getAgentsAndTrackingNumbers, getSources } from "./services";

export default () => {
  const [agentsWithRoutingNumbers, setAgentsWithRoutingNumbers] = useState([]);
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    let cancelled = false;

    const getAvailableSources = async () => {
      const sourceStuff = getSources();
      const agentStuff = getAgentsAndTrackingNumbers();

      const [{ sources: available }, agentsssss] = await Promise.all([sourceStuff, agentStuff]);

      if (!cancelled) {
        const agentsMapped = mapAgentsWithRoutingNumber(agentsssss.agents);

        setAgentsWithRoutingNumbers(agentsMapped);
        setTrackingNumbers(mapTrackingNumbers(agentsssss.trackingNumbers, agentsMapped));

        setSources(available);
      }
    };

    getAvailableSources();

    return () => {
      cancelled = true;
    };
  }, []);

  return { agentsWithRoutingNumbers, trackingNumbers, sources };
};

const mapTrackingNumbers = (trackingNumbers, agents) => {
  const agentsById = agents.reduce((acc, agent) => {
    acc[agent.id] = agent;

    return acc;
  }, {});

  return trackingNumbers.map((trackingNumber) => ({
    id: trackingNumber.id,
    name: trackingNumber.name,
    number: trackingNumber.number,
    routingMethod: trackingNumber.routing_rule,
    createLead: trackingNumber.create_leads,
    source: trackingNumber.source_id,
    noAnswerSetting: trackingNumber.no_answer_setting,
    voicemailGreeting: trackingNumber.voicemail_greeting,
    voicemailGreetingUrl: trackingNumber.voicemail_greeting_url,
    users: trackingNumber.tracking_number_routings
      .map((route) => ({
        id: route.user_id,
        label: agentsById[route.user_id].name,
        value: route.user_id,
        routingNumber: agentsById[route.user_id].routingNumber,
        name: agentsById[route.user_id].name,
        avatarUrl: agentsById[route.user_id].avatarUrl,
        position: route.position,
      }))
      .toSorted((a, b) => a.position > b.position),
  }));
};

const mapAgentsWithRoutingNumber = (agents) => {
  const usable = [];
  agents.forEach((agent) => {
    usable.push({
      id: agent.id,
      name: agent.full_name,
      avatarUrl: agent.avatar_url.length > 3 ? agent.avatar_url : null,
      routingNumber:
        agent.routing_numbers?.find((rn) => agent.routing_number_id === rn.detail_id)?.routing_number || null,
      label: agent.full_name,
      value: agent.id,
    });
  });

  return usable;
};
