/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronRightSolidV6 = (props) => (
  <Icon {...props} name="Chevron Right Solid V6">
    <path d="M5.571 16a1.143 1.143 0 0 1-.808-1.951L10.814 8 4.763 1.951A1.143 1.143 0 0 1 6.379.335l6.857 6.857c.446.446.446 1.17 0 1.616l-6.857 6.857a1.135 1.135 0 0 1-.808.335z" />
  </Icon>
);

export default ChevronRightSolidV6;
