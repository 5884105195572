import React, { useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import * as actionCreators from "../actions/thunks";
import { Col, Row } from "../../../../shared/v1";
import TextInput from "../../../../shared/v2/TextInput";
import errorShape from "../model/error";
import Button from "../../../../shared/v2/Button";
import Error from "../Error";

const EditListingBody = ({
  uuid,
  assetsCardViews,
  submitEditListingURLAsThunk,
  getEditListingURLError,
  closeModal,
  isMarketingLoading,
}) => {
  const [zillow, setZillow] = useState(assetsCardViews?.external_interactions?.sites?.zillow?.url);

  const handleSubmit = () => {
    const data = {
      zillow_url: zillow,
      blossor_id: assetsCardViews?.external_interactions?.blossor_id,
    };
    submitEditListingURLAsThunk(uuid, data, closeModal);
  };
  return (
    <>
      {getEditListingURLError && <Error message={getEditListingURLError.message} />}
      <Row className="tw-m-0 tw-mx-[0px] tw-p-0 tw-pt-[32px]">
        <Col size={12} className="tw-m-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-p-0">
          <div className="tw-text-[14px] tw-font-semibold tw-text-gray-50 tw-leading-[20px] tw-mb-[8px]">
            Zillow URL
          </div>
          <TextInput
            onChange={(e) => setZillow(e.target.value)}
            value={zillow}
            disabled={isMarketingLoading}
            multiline
            className="tw-w-full"
          />
        </Col>
      </Row>
      <Row className="tw-pt-[32px] tw-m-0 tw-mx-[0px] tw-p-0 ">
        <Col size={8} className="tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px] tw-p-0">
          <Button
            onClick={closeModal}
            className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
            schema="tertiary"
            size="medium"
          >
            CANCEL
          </Button>
        </Col>
        <Col size={4} className="tw-py-[0px] tw-px-[0px] tw-m-0 tw-mx-[0px] tw-p-0 tw-flex tw-justify-end">
          <Button
            disabled={isMarketingLoading}
            onClick={handleSubmit}
            className="tw-flex tw-items-center"
            schema="primary"
            size="medium"
          >
            SAVE
          </Button>
        </Col>
      </Row>
    </>
  );
};

EditListingBody.propTypes = {
  closeModal: Proptypes.func,
  uuid: Proptypes.string.isRequired,
  submitEditListingURLAsThunk: Proptypes.func.isRequired,
  getEditListingURLError: errorShape,
  isMarketingLoading: Proptypes.bool,
  assetsCardViews: Proptypes.shape({
    external_interactions: Proptypes.shape({
      sites: Proptypes.shape({
        zillow: Proptypes.shape({
          url: Proptypes.string,
        }),
      }),
      blossor_id: Proptypes.string,
    }),
  }),
};
EditListingBody.defaultProps = {
  closeModal: null,
  isMarketingLoading: false,
  getEditListingURLError: null,
  assetsCardViews: {
    external_interactions: {
      sites: {
        zillow: {
          url: "",
        },
      },
      blossor_id: "",
    },
  },
};
const mapStateToProps = (state) => ({
  isMarketingLoading: state.tdpMarketingReducer.isMarketingLoading,
  uuid: state.tdpMarketingReducer.uuid,
  assetsCardViews: state.tdpMarketingReducer.assetsCardViews,
  getEditListingURLError: state.tdpMarketingReducer.getEditListingURLError,
});
const mapDispatchToProps = (dispatch) => ({
  submitEditListingURLAsThunk: (uuid, data, closeModal) =>
    dispatch(actionCreators.submitEditListingURLAsThunk(uuid, data, closeModal)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditListingBody);
