import React from "react";
import { Row } from "../../shared/v1";

const NoSharedPlan = (props) => {
  return (
    <div>
      <Row id="no-shared-plans" className="tw-text-center">
        <img height="130px" src={props.src} />
        <h4>Shared auto plans will show here!</h4>
      </Row>
    </div>
  );
};

export default NoSharedPlan;
