import React from 'react';

const TableRow = ({children}) => {
  return <div className='user td'>{children}</div>
}

const TableData = (props) => (
  <div style={{background: '#FFF'}} className={`${props.value === 'Totals' ? 'text-center col-xs-2' : 'text-center col-xs-1'} ${props.style}`}>
    <strong>{props.value}</strong>
  </div>
)

const LeadSourceTotalRow = ({statusCounts, totalLeadCount}) => (
  <TableRow>
    <TableData value='Totals' />
    <TableData value={statusCounts.new} />
    <TableData value={statusCounts.unqualified} />
    <TableData value={statusCounts.watch} />
    <TableData value={statusCounts.nurture} />
    <TableData value={statusCounts.hot} />
    <TableData value={statusCounts.past} />
    <TableData value={statusCounts.inactive} />
    <TableData value={statusCounts.archived} />
    <TableData value={statusCounts.trash} />
    <TableData value={totalLeadCount} />
  </TableRow>
)

export default LeadSourceTotalRow;
