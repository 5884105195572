import React from "react";
import PropTypes from "prop-types";

import Prompt from "../../../../../shared/v2/Modal/Prompt";

const DeleteExpenseIncomeModal = ({ show, isDeleting, onConfirm, onCancel }) => {
  const description = (
    <div>
      <p className="tw-m-0">Deleting an expense or income cannot be undone.</p>
      <p className="tw-m-0">Are you sure you wish to proceed?</p>
    </div>
  );

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Prompt
      show={show}
      title="Delete Expense/Income?"
      description={description}
      primaryOption="Delete"
      primaryProps={{ schema: "warning" }}
      isPrimaryLoading={isDeleting}
      onPrimary={handleConfirm}
      onCancel={handleCancel}
    />
  );
};

DeleteExpenseIncomeModal.propTypes = {
  show: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

DeleteExpenseIncomeModal.defaultProps = {
  show: false,
  isDeleting: false,
};

export default DeleteExpenseIncomeModal;
