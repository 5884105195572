import React, { useState } from "react";

import { Modal } from "../../../shared/v1/Modal";
import Button from "../Button";
import { deleteListingSearch } from "../service";

const DeleteSearchModal = ({searchId, onDelete, userId}) => {
  const [deleting, setDeleting] = useState(false);

  const deleteSearch = async () => {
    setDeleting(true);
    await deleteListingSearch(userId, searchId);
    setDeleting(false);
    onDelete(true);
  };

  return (
    <Modal
      id="delete-search-modal"
      show={true}
      variant="modern"
      onHide={onDelete}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="tw-text-28px tw-text-gray-75">Delete Saved Search</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row">
            <span className="tw-text-14px tw-text-gray-75">Are you sure you want to delete this search? This action cannot be undone.</span>
          </div>
          <div className="tw-flex tw-flex-row tw-mt-32px">
            <Button size="large" onClick={onDelete}>Cancel</Button>
            <button
              className="tw-ml-auto tw-py-6px tw-px-18px tw-cursor-pointer tw-tracking-normal tw-text-center tw-uppercase tw-text-none tw-rounded-30px tw-border-2 tw-border-semantic-red-100 tw-border-solid tw-bg-white"
              onClick={deleteSearch}
              disabled={deleting ? "disabled" : ""}
            >
              <span className="tw-flex tw-flex-row tw-items-center tw-whitespace-nowrap tw-text-semantic-red-100 tw-text-14px tw-font-bold">
                YES, DELETE
              </span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteSearchModal;
