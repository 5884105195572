import React from "react";
import PropTypes from "prop-types";
import FieldLabelV1 from "./FieldLabelV1";
import TextInput from "../v2/TextInput/TextInput";

function FieldTextAreaV1({
  className,
  placeholder,
  onChange,
  required,
  label,
  value,
  flexStyle,
  disabled,
  ...otherProps
}) {
  return (
    <div className={flexStyle} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required }} />}

      <TextInput
        multiline={true}
        style={{ resize: "none" }}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value || ""}
        {...{ disabled, placeholder }}
        className={`tw-h-[76px] ${className}`}
        {...otherProps}
      />
    </div>
  );
}
FieldTextAreaV1.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  flexStyle: PropTypes.string,
  disabled: PropTypes.bool,
};

FieldTextAreaV1.defaultProps = {
  className: "",
  placeholder: "",
  onChange: () => {},
  required: false,
  label: "",
  value: "",
  flexStyle: "tw-flex-1 ",
  disabled: false,
};
export default FieldTextAreaV1;
