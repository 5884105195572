import React from "react";
import { string } from "prop-types";

import { DETAILS_WRAPPER, DETAILS_COLUMN } from "../shared/styles";
import { isReferral } from "../../../shared/helpers";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { TRANSACTION_TYPE } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

import TransactionDetails from "../TransactionDetails";
import ListingDetails from "../ListingDetails";
import PropertyDetails from "../PropertyDetails";
import ReferralPartnerDetails from "../ReferralPartnerDetails";
import RefDetails from "../RefDetails";
import ClientIntentDetails from "../ClientIntentDetails";
import RefPropertyDetails from "../RefPropertyDetails";
import EarnestMoney from "../EarnestMoney";

const NonReferralDetails = () => (
  <div className={DETAILS_WRAPPER}>
    <div className={DETAILS_COLUMN}>
      <TransactionDetails />
      <EarnestMoney />
      <ListingDetails />
    </div>
    <div className={DETAILS_COLUMN}>
      <PropertyDetails />
    </div>
  </div>
);

const ReferralDetails = () => (
  <div className={DETAILS_WRAPPER}>
    <div className={DETAILS_COLUMN}>
      <ReferralPartnerDetails />
      <RefDetails />
    </div>
    <div className={DETAILS_COLUMN}>
      <ClientIntentDetails />
      <RefPropertyDetails />
    </div>
  </div>
);

const DetailsSpecifics = ({ type }) => (isReferral(type) ? <ReferralDetails /> : <NonReferralDetails />);

export default tdpDetails({
  WrappedComponent: DetailsSpecifics,
  states: TRANSACTION_TYPE,
});

DetailsSpecifics.propTypes = {
  type: string,
};

DetailsSpecifics.defaultProps = {
  type: null,
};
