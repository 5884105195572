/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import TextComboInput from "@shared/v2/TextComboInput";
import { isStrInputLen } from "../../ModalViews/helpers";
import FieldSelect from "../../../shared/UtilsV1/FieldSelect";
import FieldLabelV1 from "../../../shared/UtilsV1/FieldLabelV1";
import Radio from "../../../shared/UtilsV1/FieldRadioV1/Radio";
import RemoveFieldButton from "../../../shared/v2/RemoveFieldButton";

function EmailView({
  emailDetails,
  value: currentEmail,
  index,
  setPersonValue,
  options,
  removable,
  required,
}) {
  const onPrimaryChange = (emailPosition) => {
    const tempEmailDetails = emailDetails.map(({ set_main_email: _, ...email }) => {
      if (email.position === 1) {
        return { ...email, position: emailPosition };
      }
      if (email.position === emailPosition) {
        return { ...email, position: 1, set_main_email: true };
      }
      return email;
    });

    setPersonValue({ key: "emailDetailsAttributes", value: tempEmailDetails });
  };

  const onRemove = (targetEmail) => {
    const filteredEmailDetails = emailDetails.map((email) => {
      // Setting value to empty string currently necessary to trigger manage_main_email_state on Person record
      // when removing an email and to get a .changes from ActiveRecord for timeline_publishable
      if (email === targetEmail) return { ...email, value: `delete${email.value}`, _destroy: true };
      return email;
    });

    if (targetEmail.position === 1 && filteredEmailDetails.length > 0) {
      filteredEmailDetails[0].position = 1;
    }
    setPersonValue({ key: "emailDetailsAttributes", value: filteredEmailDetails });
  };

  const updateEmail = (targetEmail, value) =>
    setPersonValue({
      key: "emailDetailsAttributes",
      value: emailDetails.map((email) => {
        if (email !== targetEmail) return email;
        return { ...email, ...value };
      }),
    });

  return currentEmail?._destroy ? null : (
    <div className="tw-flex" data-cy={`email_${index}`}>
      <div className="tw-flex tw-gap-[24px] tw-flex-auto">
        <TextComboInput
          label="Email"
          isRequired={required}
          value={currentEmail.value}
          containerClassName="tw-flex-1"
          error={required && isStrInputLen(currentEmail.value)}
          onChange={(e) => {
            updateEmail(currentEmail, { value: e.target.value });
          }}
          data-cy="email-email-view"
        />
        <div className="tw-flex tw-gap-[8px] tw-flex-1">
          <FieldSelect
            label="Email Type"
            options={options}
            onChange={(newValue) => updateEmail(currentEmail, { category: newValue })}
            value={currentEmail.category}
            placeholder="Choose One"
          />

          {removable && (
            <RemoveFieldButton onClick={() => onRemove(currentEmail)} className="tw-mt-auto tw-mb-1px" />
          )}
        </div>
      </div>

      <div>
        <FieldLabelV1 label="Primary" />
        <div
          aria-label="primary"
          className="tw-flex tw-justify-center tw-items-center tw-py-4 "
          onClick={() => onPrimaryChange(currentEmail.position)}
          onKeyDown={() => onPrimaryChange(currentEmail.position)}
          role="button"
          tabIndex="0"
        >
          <Radio checked={currentEmail.position === 1} />
        </div>
      </div>
    </div>
  );
}
EmailView.propTypes = {
  emailDetails: PropTypes.arrayOf(PropTypes.shape({})),
  setPersonValue: PropTypes.func.isRequired,

  value: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.number,
    value: PropTypes.string,
    _destroy: PropTypes.bool,
  }),
  index: PropTypes.number,
  removable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
};

EmailView.defaultProps = {
  emailDetails: [],
  options: [],
  value: null,
  index: null,
  removable: false,
  required: false,
};

export default EmailView;
