import { isArray, isObject, camelCase, transform, snakeCase, get, set, startCase } from "lodash";

const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};

export const sortByKey = (data, sortKey) => {
  const list = [...data];
  const multiplier = 1;

  return list.sort((a, b) => {
    let aVal = a[sortKey];
    let bVal = b[sortKey];

    if (isString(aVal) && isString(bVal)) {
      aVal = aVal ? aVal.toLowerCase() : 0;
      bVal = bVal ? bVal.toLowerCase() : 0;
    } else {
      aVal = aVal ? aVal : 0;
      bVal = bVal ? bVal : 0;
    }

    return aVal > bVal ? multiplier : aVal < bVal ? -multiplier : 0;
  });
};

export const stripHtml = (text) => {
  let tmpDoc, htmlStrippedText;
  //Strip HTML tags from text
  tmpDoc = document.implementation.createHTMLDocument("New").body;
  tmpDoc.innerHTML = text;
  htmlStrippedText = tmpDoc.innerText || tmpDoc.textContent || "";
  return htmlStrippedText;
};

export const toTitleCase = (str) => {
  if (!str) {
    return "";
  }

  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase());
};

export const tryCatchHandlr = async (promise) => {
  try {
    const data = await promise;
    return [data, null];
  } catch (err) {
    return [null, err];
  }
};

export const promiseDebounce = (inner, ms = 500) => {
  let timer = null;
  let resolves = [];
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      let result = inner(...args);
      resolves.forEach((r) => r(result));
      resolves = [];
    }, ms);
    return new Promise((r) => resolves.push(r));
  };
};

export const getLetters = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  return (nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : nameParts[0][1])).toUpperCase();
};

export const range = (smaller, greater, step = 1) =>
  Array(greater - smaller + 1)
    .fill(smaller)
    .map((n, i) => n + i * step);

export const inRange = (min, max, item) => item >= min && item <= max;
export const inSequence = (smaller, greater, step = 1) => smaller === greater - step;
export const uniqueArray = (array) => Array.from(new Set(array));
export const numericalCompare = (a, b) => a - b;

/**
 * Recursively transforms the given object's keys from snake_case to camelCase.
 * @param {object} obj - The object to transform.
 * @example
 * // returns {
 * //   exampleKey: [1, 2, 3],
 * //   anotherKey: {
 * //     innerKey: "example_value",
 * //   }
 * // }
 * camelizeKeys({
 *   example_key: [1, 2, 3],
 *   another_key: {
 *     inner_key: "example_value"
 *   }
 * })
 */
export const camelizeKeys = (obj) => {
  if (!obj) return;

  return transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelizeKeys(value) : value;
  });
};

/**
 * Recursively transforms the given object's keys from camelCase to snake_case.
 * @param {object} obj - The object to transform.
 * @example
 * // returns {
 * //   example_key: [1, 2, 3],
 * //   another_key: {
 * //     inner_key: "example_value",
 * //   }
 * // }
 * snakefyKeys({
 *   exampleKey: [1, 2, 3],
 *   anotherKey: {
 *     innerKey: "example_value"
 *   }
 * })
 */
export const snakefyKeys = (obj) =>
  transform(obj, (acc, value, key, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);
    acc[snakeKey] = isObject(value) ? snakefyKeys(value) : value;
  });

/**
 * Logs a deprecation warning only if not in production.
 *
 * Warnings are prefixed with `[DEPRECATION WARNING]`.
 *
 * @param {string} warning
 */
export const deprecationWarning = (warning) => {
  if (process.env.NODE_ENV !== "production") {
    console.warn(`[DEPRECATION WARNING] ${warning}`);
  }
};

/**
 * Accepts a string or number and returns a number.
 *
 * @param {string | number} value
 * @returns {number}
 */
export const toNumber = (value) => (typeof value === "number" ? value : Number(value.replaceAll(",", "")));

/**
 * Uses lodash's get/set to assign a value to a path in an object only if different than the current value.
 *
 * @param {Record<string, unknown>} obj
 * @param {string} path
 * @param {unknown} value
 * @returns {boolean} `true` if assigned, `false` if not.
 */
export const assignIfDifferent = (obj, path, value) => {
  const currentValue = get(obj, path);

  if (currentValue !== value) {
    set(obj, path, value);
    return true;
  }

  return false;
};

export const removeAllSpacesFromString = (str) => str.replace(/\s/g, "");

//Splits strings by seperator
export const strSplitter = (str = "", serpator = ".") => str.split(serpator);

//Creates shallow copy of an object
export const shallowCopy = (iterable = {}) => JSON.parse(JSON.stringify(iterable));

// Retrieves the the last string splits by seperator (default .)
export const propsKey = (str = "", serpator = ".") => {
  const seperateStr = strSplitter(str, serpator);
  return seperateStr[seperateStr.length - 1];
};

//Scrolls page to either targetted element or page
export const scrollElementToTop = (elementById, top = 0, behavior = "instant") => {
  const element = document.getElementById(elementById);

  const scrollThis = elementById ? element : window;

  scrollThis.scrollTo({
    top,
    behavior,
  });
};

// Changes string into PascalCase;
export const pascalCase = (str) => startCase(str).replace(/ /g, '');

// Returns true if theme is Place;
export const isPlace = (theme) => theme === 'place-theme';

export const homeAppTxt = ({
  recipientFirstName = '', 
  recipientEmailAddress = '',
  teamName = '',
  primaryAgentName= '',
  brivityAppLink = '',
}) => `Hi ${recipientFirstName}, this is ${primaryAgentName} with ${teamName}. You can download my Brivity Home app to search for homes here: ${brivityAppLink}.\n\nTo log in, use the email address I have on file for you: ${recipientEmailAddress}.`