export const actionTypes = {
  GET_USER_TABLE_DATA: "GET_USER_TABLE_DATA",
  GET_CURRENT_USER_AND_FORM_OPTIONS: "GET_CURRENT_USER_AND_FORM_OPTIONS",
  GET_SOURCE_OPTIONS: "GET_SOURCE_OPTIONS",

  EDIT_USER_MODAL_TOGGLE: "EDIT_USER_MODAL_TOGGLE",
  EDIT_USER: "EDIT_USER",

  LOAD_EDITING_USER: "LOAD_EDITING_USER",
  UPDATE_EDITING_PERSON: "UPDATE_EDITING_PERSON",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  SET_USERS_TABLE_ERRORS: "SET_USERS_TABLE_ERRORS",
  SET_USER_MODAL_ERRORS: "SET_USER_MODAL_ERRORS",
  CLEAR_USER_MODAL_ERRORS: "CLEAR_USER_MODAL_ERRORS",
  SET_IS_TABLE_DATA_LOADING: "SET_IS_TABLE_DATA_LOADING",
  UPDATE_SORT_ON_TABLE_COLUMN: "UPDATE_SORT_ON_TABLE_COLUMN",
  SET_IS_DELETING: "SET_IS_DELETING",
  SET_ICON_PICKER_MODAL: "SET_ICON_PICKER_MODAL",
  UPDATE_EDITING_PERSON_LOGIN: "UPDATE_EDITING_PERSON_LOGIN",
  SUSPEND_USER: "SUSPEND_USER",
};

const failErrorMessage = "unsuccessfully. Please try to refresh page or contact engineers.";
export const userSettingsErrorMessages = {
  FAIL_ON_LOAD_USER_DATA: `This user Data fetching ${failErrorMessage}`,
  FAIL_ON_LOAD_TABLE_USERS_DATA: `Users Data fetching ${failErrorMessage}`,
  FAIL_ON_LOAD_SOURCES: `Source options fetching ${failErrorMessage}`,
  FAIL_ON_LOAD_FORM_OPTIONS: `Options fetching ${failErrorMessage}`,
  FAIL_ON_UPLOAD_AVATAR: `Avatar Image uploaded ${failErrorMessage}`,
};

export default actionTypes;
