import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Loader from "../../../../../Goals/components/Loader";

import Button from "../../../../../shared/v2/Button";
import Modal from "../../../../../shared/v2/Modal";

import PlanDetails from "./PlanDetails";
import { applyAutoPlan } from "../../actions/thunks";

const AssignedPlanPreviewModal = ({
  dispatch,
  transactionUuid,
  plan,
  meta,
  show,
  onClose,
  planApplyStatus,
}) => {
  const isLoading = meta.state === "loading";
  const modalTitle = isLoading ? "Loading Auto Plan Preview..." : `Previewing Auto Plan: ${plan?.name}`;

  // There's no error state design for this modal, so for now we just kick the
  // user back out so he retries.
  if (show && meta.error) {
    onClose();
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-min-h-full tw-max-h-full"
      contentClassName="tw-w-[983px] tw-space-y-[32px] tw-m-auto"
    >
      <Modal.Header title={modalTitle} closeButton />

      <Modal.Body className="tw-h-[60vh] tw-flex tw-border-solid tw-border-0 tw-border-t tw-border-b tw-border-neutral-gray-10">
        {isLoading || plan === null ? (
          <div className="tw-relative tw-w-full">
            <Loader />
          </div>
        ) : (
          <PlanDetails plan={plan} />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-flex-row tw-justify-between">
        <Button size="medium" schema="tertiary" onClick={onClose}>
          Close
        </Button>

        <Button
          size="medium"
          schema="primary"
          onClick={() => dispatch(applyAutoPlan(transactionUuid, plan?.id))}
          isLoading={planApplyStatus === "applying"}
          disabled={plan === null}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AssignedPlanPreviewModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  transactionUuid: PropTypes.string.isRequired,
  // Validation of this prop will happen further down the component tree.
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.any,
  meta: PropTypes.shape({
    error: PropTypes.string,
    state: PropTypes.oneOf(["idle", "loading", "succeeded", "failed"]),
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  planApplyStatus: PropTypes.oneOf(["applying", "success"]),
};

AssignedPlanPreviewModal.defaultProps = {
  plan: null,
  planApplyStatus: null,
};

const mapStateToProps = (state) => ({
  plan: state.tdpAutoPlansReducer.planPreview.plan,
  meta: {
    error: state.tdpAutoPlansReducer.planPreview.meta.error,
    state: state.tdpAutoPlansReducer.planPreview.meta.state,
  },
  planApplyStatus: state.tdpAutoPlansReducer.autoPlanApply.status,
});

export default connect(mapStateToProps)(AssignedPlanPreviewModal);
