/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const CalendarClockSolidV6 = (props) => (
  <Icon {...props} name="Calendar Clock Solid V6">
    <path d="M2.667 1.778a.888.888 0 1 1 1.778 0v.889h3.556v-.889a.888.888 0 1 1 1.778 0v.889h1.333c.736 0 1.333.597 1.333 1.333v1.333H.001V4c0-.736.597-1.333 1.333-1.333h1.333v-.889zm9.777 4.464a4.89 4.89 0 0 0-3.255 8.87H1.333A1.333 1.333 0 0 1 0 13.779V6.223h12.444v.019zM16 11.111c0 2.208-1.792 4-4 4s-4-1.792-4-4 1.792-4 4-4 4 1.792 4 4zm-4.469-1.778v1.778c0 .244.225.444.444.444h1.358c.244 0 .444-.2.444-.444s-.2-.444-.444-.444h-.914V9.334a.43.43 0 0 0-.444-.444c-.219 0-.444.2-.444.444z" />
  </Icon>
);

export default CalendarClockSolidV6;
