import React from "react";
import { CaretDown as CaretDownIcon } from "../../Icomoon";
import CustomDropdownIndicator from "../custom/DropdownIndicator";
import { customDefaultProps, customPropTypes } from "../utils";

const DropdownIndicator = ({ componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CustomDropdownIndicator {...componentProps} preserveStyle>
    <CaretDownIcon size="m" />
  </CustomDropdownIndicator>
);

DropdownIndicator.propTypes = customPropTypes;

DropdownIndicator.defaultProps = customDefaultProps;

export default DropdownIndicator;
