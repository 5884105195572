import React, { useMemo } from "react";
import { Provider } from "react-redux";
import InteractionTemplates from "./InteractionTemplates";

const InteractionTemplatesApp = () => {
  const store = useMemo(() => ReactOnRails.getStore("layoutStore"), []);
  return (
    <Provider store={store}>
      <InteractionTemplates />
    </Provider>
  );
};

export default InteractionTemplatesApp;
