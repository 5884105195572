import React from "react";
import PropTypes from "prop-types";

const FormElement = ({ label, element, required, className }) => {
  return (
    <div className={className}>
      <label className="tw-mb-[8px] tw-font-normal tw-text-[14px] tw-text-neutral-gray-50">
        {label}
        {required && <span className="tw-text-brivity-coral-100 tw-ml-1">*</span>}
      </label>
      {element}
    </div>
  );
};

FormElement.propTypes = {
  label: PropTypes.string,
  element: PropTypes.shape({}),
  className: PropTypes.string,
  required: PropTypes.bool,
};

FormElement.defaultProps = {
  label: "",
  element: <div />,
  className: "",
  required: false,
};

export default FormElement;
