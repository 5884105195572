import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnreadCounts } from "../../reducers/messagingReducer";

const useUnreadCounts = () => {
  const dispatch = useDispatch();
  const aiUnreadCount = useSelector((state) => state.messaging.aiUnreadCount);
  const allUnreadCount = useSelector((state) => state.messaging.allUnreadCount);
  const groupUnreadCount = useSelector((state) => state.messaging.groupUnreadCount);

  useEffect(() => dispatch(getUnreadCounts()).abort, []);

  return { aiUnreadCount, allUnreadCount, groupUnreadCount };
};

export default useUnreadCounts;
