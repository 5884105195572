/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const VideoSolidV6 = (props) => (
  <Icon {...props} name="Video Solid V6">
    <path d="M10.667 4v8c0 .736-.597 1.333-1.333 1.333h-8A1.333 1.333 0 0 1 .001 12V4c0-.736.597-1.333 1.333-1.333h8c.736 0 1.333.597 1.333 1.333zM16 4.431v7.136c0 .708-.81 1.122-1.4.716l-3.045-2.097V5.814l3.044-2.099c.592-.406 1.4.01 1.4.716z" />
  </Icon>
);

export default VideoSolidV6;
