import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as creators from "../../actions/creators";
import "./styles.scss";

import { createCMAMap, clearMap, useBrowserCoords } from "./helpers";
import { useOnDrawingMode, useSetMap, useMapDarwings, useMap } from "./hookHelpers";

const mapStateToProps = (state) => ({
  coords: state.addMarketReport.form1.lat_lng,
  browserLatLon: state.addMarketReport.browserLatLon,
  radius: state.addMarketReport.form1.radius,
  radiusType: state.addMarketReport.form1.radius_type,
  isDrawing: state.addMarketReport.isDrawing,
  searchLocation: state.addMarketReport.form1.searchLocation,
  searchType: state.addMarketReport.form1.search_type,
  hasSearch: state.addMarketReport.form1.searchLocation !== null,
  geoJSON: state.addMarketReport.form1.geo_json,
  boundry: state.addMarketReport.form1.boundry_coordinates,
  locationData: state.addMarketReport.locationData,
  currentName: state.addMarketReport?.savedMarketReport?.name,
});

const Map = ({
  coords,
  browserLatLon,
  boundry,
  setDrawing,
  setLatLon,
  isDrawing,
  searchType,
  geoJSON,
  radius,
  radiusType,
  setFormData,
  hasSearch,
  locationData,
  setLocationData,
  searchLocation,
  currentName,
}) => {
  const [map, setMap] = useMap();
  const setDrawingManager = useOnDrawingMode({
    isDrawing,
    disableFn: () => {
      clearMap(map);
      if (useBrowserCoords(coords, browserLatLon)) {
        setLatLon(browserLatLon);
      }
    },
    searchLocation,
    setFormData,
    currentName,
  });

  const initMap = () => {
    createCMAMap({
      setFormData,
      setMap,
      setDrawingManager,
      google: window.google,
      coords,
      onPolygonComplete: (poly) => {
        setFormData({ geo_json: poly.geoJSON, search_type: "area" });
      },
    });
  };
  useMapDarwings({
    isDrawing,
    map,
    radius,
    boundry,
    geoJSON,
    locationData,
    radiusType,
    searchType,
    hasSearch,
    coords,
  });

  useSetMap({
    loadFn: () => initMap(),
    clearFn: () => {
      document.getElementById("cma-map").innerHTML = "";
    },
    coords,
  });

  return (
    <>
      <button
        type="button"
        className="tw-bg-white tw-font-bold tw-z-2 tw-absolute tw-right-[20px] tw-px-[20px] tw-top-[20px] tw-h-[36px] tw-rounded-full tw-border-0 tw-shadow-lg"
        onClick={(e) => {
          e.preventDefault();
          const s = !isDrawing;
          setDrawing(s);
          setLocationData(null);
        }}
      >
        {isDrawing ? "CLEAR" : "DRAW AREA"}
      </button>
      <div id="cma-map" className="tw-inset-0 tw-absolute" />
    </>
  );
};

Map.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number),
  browserLatLon: PropTypes.arrayOf(PropTypes.number),
  setDrawing: PropTypes.func,
  setLatLon: PropTypes.func,
  setFormData: PropTypes.func,
  setLocationData: PropTypes.func,
  isDrawing: PropTypes.bool,
  hasSearch: PropTypes.bool,
  searchType: PropTypes.string,
  radius: PropTypes.number,
  radiusType: PropTypes.string,
  geoJSON: PropTypes.arrayOf(PropTypes.number),
  boundry: PropTypes.arrayOf(PropTypes.number),
  locationData: PropTypes.shape({
    radius: PropTypes.number,
  }),
  searchLocation: PropTypes.shape({}),
  currentName: PropTypes.string,
};

Map.defaultProps = {
  coords: null,
  browserLatLon: null,
  setDrawing: () => {},
  setLatLon: () => {},
  setFormData: () => {},
  setLocationData: null,
  isDrawing: false,
  hasSearch: false,
  searchType: "radius",
  searchLocation: null,
  radius: 0,
  radiusType: null,
  geoJSON: null,
  boundry: null,
  locationData: null,
  currentName: null,
};

export default connect(mapStateToProps, creators)(Map);
