import React, { Component } from "react";
import PropTypes from "prop-types";
import capitalize from "capitalize";
import Modal from "@shared/v2/Modal";
import TextLink from "@shared/v2/TextLink";
import Pdf from "./Pdf";

class TimelineEvent extends Component {
  constructor(props) {
    super(props);
    const { interacted_at_epoch: interactedAtEpoch } = this.props;
    this.state = { showModal: false };
    this.interacted_at_epoch = interactedAtEpoch;
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  details = (showPagination) => {
    const { event } = this.props;
    const description = [];
    Object.entries(event.timeline_fields).forEach(([key, value]) => {
      const titleizedKey = key
        .split("_")
        .map((element) => capitalize(element))
        .join(" ");
      description.push(`${titleizedKey}: ${value}`);
    });
    const { to, from, file_name: fileName, file_uri: fileUri, name } = event.timeline_fields;
    const newDescription = [`To: ${to}`, `From: ${from}`, `Name: ${name}`];

    switch (event.event_type) {
      case "modified_listing_alert_frequency":
        return newDescription.map((newD) => (
          <div key={newD} className="row details-row">
            {newD}
          </div>
        ));
      case "disabled_monthly_listing_alert":
        return <div className="row details-row">Name: {name}</div>;
      case "unsubscribed_listing_alerts":
        return (
          <p>
            Listing Alert emails have been deactivated. To protect your email deliverability reputation, the
            status can not be changed. Please reach out to this contact via an alternate method.
          </p>
        );
      case "recruiter_upload":
        return (
          <>
            <div className="tw-break-words">
              File Name:{" "}
              <TextLink href={fileUri} target="_blank">
                {fileName}
              </TextLink>
            </div>
            <Pdf className="tw-flex-1 tw-overflow-y-auto" file={fileUri} showPagination={showPagination} />
          </>
        );
      default:
        return description.map((desc) => (
          <div key={desc} className="row tw-break-words details-row">
            {desc}
          </div>
        ));
    }
  };

  row = () => {
    const {
      event: {
        event_type: eventType,
        titleized_type: titleizedType,
        interacted_at_absolute: interactedAtAbsolute,
      },
    } = this.props;

    let eventText = titleizedType;
    if (eventType === "modified_listing_alert_frequency") {
      eventText = "System modified Listing Alert frequency due to inactivity";
    }
    if (eventType === "disabled_monthly_listing_alert") {
      eventText = "System paused Listing Alert due to extended inactivity";
    }
    if (eventType === "unsubscribed_listing_alerts") eventText = "Contact unsubscribed from Listing Alerts";

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div className="timeline-event" onClick={this.showModal}>
        <div className="row">
          <div className="col-xs-1">
            <i
              className="fa fa-bell-o timeline-icon-circle"
              style={{
                color:
                  eventType === "disabled_monthly_listing_alert" ||
                  eventType === "unsubscribed_listing_alerts"
                    ? "#F2714D"
                    : null,
              }}
            />
          </div>
          <div className="col-xs-8">
            <span className="bold-detail">Event: </span>
            {eventText}
            <div className="timeline-description-text">{this.details()}</div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{interactedAtAbsolute}</span>
          </div>
        </div>
      </div>
    );
  };

  modal = () => {
    const { showModal } = this.state;
    const { event } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={this.hideModal}
        closeOnEscape={false}
        closeOnClickOutside={false}
        dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
        contentClassName="tw-max-w-[700px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col tw-gap-[32px]"
      >
        <Modal.Header title="Event" closeButton />
        <Modal.Body className="tw-flex tw-flex-col tw-overflow-hidden">
          <div>
            <span className="tw-font-bold">Event: </span>
            {event.titleized_type}
          </div>
          <div className="tw-font-bold">Details: </div>
          {this.details(true)}
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        {this.row()}
        {this.modal()}
      </div>
    );
  }
}

TimelineEvent.propTypes = {
  event: PropTypes.shape().isRequired,
  interacted_at_epoch: PropTypes.number.isRequired,
};

export default TimelineEvent;
