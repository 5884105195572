/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { arrayOf, func, number, shape, string } from "prop-types";

import { putDetailAsThunk } from "../actions/thunks";

import DetailSectionHeader from "../DetailSectionHeader";
import DetailInput from "../DetailInput";
import DetailDropdown from "../DetailDropdown";

const propertyTypeToOption = (item) => (item ? { label: item.name, value: item.id } : null);

const SELLER_LANDLORD = ["seller", "landlord"];
const isSellerLandlord = (clientIntent) => SELLER_LANDLORD.includes(clientIntent);

const RefPropertyDetails = ({
  uuid,
  propertyDetails,
  accountPropertyTypes,
  onPutDetailAsThunk,
  clientIntent,
}) => {
  const { id, street_address, city, state, zip, property_type } = propertyDetails;

  const saveToAddress =
    (key, stateKey = null) =>
    (value) => {
      onPutDetailAsThunk({
        uuid,
        key,
        inOption: "address_attributes",
        value,
        group: "propertyDetails",
        addressId: id,
        stateKey,
      });
    };

  const saveOptionFor = (key, stateKey) => (optionValue) =>
    onPutDetailAsThunk({
      uuid,
      key,
      value: optionValue.value,
      group: "propertyDetails",
      stateKey,
    });

  return (
    isSellerLandlord(clientIntent) && (
      <div>
        <DetailSectionHeader header="Property Details" />
        <DetailDropdown
          label="Property Type"
          options={accountPropertyTypes}
          val={propertyTypeToOption(property_type)}
          onBlur={saveOptionFor("property_type_id", "property_type")}
        />
        <DetailInput
          dataCy="street"
          label="Street Address"
          val={street_address}
          onBlur={saveToAddress("street_address")}
        />
        <DetailInput dataCy="city" label="City" val={city} onBlur={saveToAddress("city")} />
        <DetailInput
          dataCy="state"
          label="State/Province"
          val={state}
          onBlur={saveToAddress("locality", "state")}
        />
        <DetailInput
          dataCy="postal-code"
          label="Zip/Postal code"
          val={zip}
          onBlur={saveToAddress("postal_code", "zip")}
        />
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  propertyDetails: state.tdpDetailsReducer.propertyDetails,
  additionalDetails: state.tdpDetailsReducer.additionalDetails,
  accountPropertyTypes: state.tdpDetailsReducer.meta.accountPropertyTypes,
  clientIntent: state.tdpDetailsReducer.referralDetails.clientIntent,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailAsThunk: (payload) => dispatch(putDetailAsThunk(payload)),
});

RefPropertyDetails.propTypes = {
  uuid: string,
  propertyDetails: shape({
    street_address: string,
    city: string,
    state: string,
    zip: string,
    id: string,
    property_type: shape({ id: number, name: string }),
  }),
  onPutDetailAsThunk: func,
  accountPropertyTypes: arrayOf(shape({ label: string, value: number })),
  clientIntent: string,
};

RefPropertyDetails.defaultProps = {
  uuid: null,
  propertyDetails: shape({
    street_address: null,
    city: null,
    state: null,
    zip: null,
    id: null,
    property_type: { id: null, name: null },
  }),
  onPutDetailAsThunk: () => {},
  accountPropertyTypes: [],
  clientIntent: "buyer",
};

export default connect(mapStateToProps, mapDispatchToProps)(RefPropertyDetails);
