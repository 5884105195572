/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneHangupSolidV6 = (props) => (
  <Icon {...props} name="Phone Hangup Solid V6">
    <path d="m15.885 8.94-1.318 2.106a.754.754 0 0 1-.916.299l-2.635-1.052a.747.747 0 0 1-.468-.772l.166-1.661a8.424 8.424 0 0 0-5.424 0l.166 1.663a.75.75 0 0 1-.467.771l-2.638 1.053a.757.757 0 0 1-.918-.301L.115 8.94a.746.746 0 0 1 .106-.928c4.289-4.283 11.269-4.283 15.556 0 .25.25.295.633.108.928z" />
  </Icon>
);

export default PhoneHangupSolidV6;
