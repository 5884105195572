import { useEffect, useState } from "react";

import { getStats, getActivity } from "./services";
import { getTrackingNumberIds } from "./helpers";

export default ({ trackingNumbers, selectedTrackingNumbers, minDate, maxDate }) => {
  const [stats, setStats] = useState({});
  const [activity, setActiviy] = useState([]);

  useEffect(() => {
    const load = async () => {
      const results = await getStats(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
      );
      setStats(mapStats(results));
    };

    load();
  }, [selectedTrackingNumbers, minDate, maxDate]);

  useEffect(() => {
    const load = async () => {
      const results = await getActivity(
        getTrackingNumberIds(selectedTrackingNumbers, trackingNumbers),
        minDate,
        maxDate,
      );
      setActiviy(results);
    };

    load();
  }, [selectedTrackingNumbers, minDate, maxDate]);

  return { activity, stats };
};

const mapStats = (stats) => {
  return {
    trackingNumbers: stats.tracking_numbers.map((tn) => ({
      id: tn.id,
      name: tn.name,
      totalCalls: tn.total_calls,
      createdAt: tn.created_at,
      totalLeads: tn.total_leads,
    })),
    totalCalls: stats.total_calls,
    missedCalls: stats.missed_calls,
    talkedCalls: stats.talked_calls,
    totalCallDuration: stats.total_call_duration,
    totalCallsWithDuration: stats.total_calls_with_duration,
    totalNewLeads: stats.total_new_leads,
  };
};
