import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import errorShape from "../model/error";
import feedbackShape from "../model/feedback";
import formDataShape from "../model/formData";
import metaShape from "../model/meta";
import promptDataShape from "../model/promptData";
import Feedback from "../Feedback";
import {
  getAllFeedbacks,
  newFeedback,
  editFeedback,
  deleteFeedback,
  hideDeleteFeedbackPrompt,
  showDeleteFeedbackPrompt,
  hideFeedbackForm,
  showFeedbackForm,
  changeFeedbackVisibility,
  clearChangeFeedbackVisibilityError,
  loadMoreFeedbacks,
} from "../actions/thunks";

const FeedbackWrapper = ({ dispatch, uuid, feedbacks, meta, error, isLoading, formData, promptData }) => (
  <Feedback
    getAllFeedbacks={() => dispatch(getAllFeedbacks(uuid))}
    loadMoreFeedbacks={() => dispatch(loadMoreFeedbacks(uuid, meta.pagination.next))}
    newFeedback={(feedback) => dispatch(newFeedback(uuid, feedback))}
    editFeedback={(feedback) => dispatch(editFeedback(uuid, feedback))}
    changeFeedbackVisibility={(feedbackId, externallyVisible) =>
      dispatch(changeFeedbackVisibility(uuid, feedbackId, externallyVisible))
    }
    clearChangeFeedbackVisibilityError={(feedbackId) =>
      dispatch(clearChangeFeedbackVisibilityError(feedbackId))
    }
    deleteFeedback={(feedbackId) => dispatch(deleteFeedback(uuid, feedbackId, meta.pagination.current))}
    showFeedbackForm={(data) => dispatch(showFeedbackForm(data))}
    hideFeedbackForm={() => dispatch(hideFeedbackForm())}
    showDeleteFeedbackPrompt={(data) => dispatch(showDeleteFeedbackPrompt(data))}
    hideDeleteFeedbackPrompt={(data) => dispatch(hideDeleteFeedbackPrompt(data))}
    uuid={uuid}
    feedbacks={feedbacks}
    meta={meta}
    error={error}
    isLoading={isLoading}
    formData={formData}
    promptData={promptData}
  />
);

FeedbackWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  feedbacks: PropTypes.arrayOf(feedbackShape),
  meta: metaShape,
  error: errorShape,
  isLoading: PropTypes.bool,
  formData: formDataShape,
  promptData: promptDataShape,
};

FeedbackWrapper.defaultProps = {
  feedbacks: [],
  meta: {
    averageRating: 0.0,
    pagination: {
      totalPages: 0,
      totalCount: 0,
    },
  },
  error: null,
  isLoading: false,
  formData: {
    action: "new",
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
  promptData: {
    data: null,
    isVisible: false,
    error: null,
    isLoading: false,
  },
};

const mapStateToProps = (state) => ({
  uuid: state.tdpFeedbackReducer.uuid,
  feedbacks: state.tdpFeedbackReducer.feedbacks,
  meta: state.tdpFeedbackReducer.meta,
  error: state.tdpFeedbackReducer.error,
  isLoading: state.tdpFeedbackReducer.isLoading,
  formData: state.tdpFeedbackReducer.formData,
  promptData: state.tdpFeedbackReducer.promptData,
});

export default connect(mapStateToProps)(FeedbackWrapper);
