/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowUpRightFromSquareRegularV6 = (props) => (
  <Icon {...props} name="Arrow Up Right From Square Regular V6">
    <path d="M9.75 2.5a.749.749 0 1 1 0-1.5h4.5c.416 0 .75.336.75.75v4.5c0 .416-.334.75-.75.75s-.75-.334-.75-.75V3.559L7.253 9.753c-.266.319-.741.319-1.034 0-.291-.266-.291-.741 0-1.034L12.441 2.5H9.75zM1 3.75C1 2.783 1.783 2 2.75 2h3.5a.749.749 0 1 1 0 1.5h-3.5a.25.25 0 0 0-.25.25v9.5c0 .137.112.25.25.25h9.5c.137 0 .25-.113.25-.25v-3.5c0-.416.334-.75.75-.75s.75.334.75.75v3.5A1.75 1.75 0 0 1 12.25 15h-9.5A1.75 1.75 0 0 1 1 13.25v-9.5z" />
  </Icon>
);

export default ArrowUpRightFromSquareRegularV6;
