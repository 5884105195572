import * as actionCreators from "../LeadRoutingTargetSelector/actions/creators";

const defaultState = {
  availableAgents: [],
  availablePonds: [],

  unavailableAgents: [],
  unavailablePonds: [],

  show: false,
  tab: "agents",

  isSaving: false,
  isSaved: false,
  hasSaveError: false,

  selectedAgents: null,
  selectedPond: null,
};

const leadRouting = (state = defaultState, payload) => {
  switch (payload.type) {
    case actionCreators.setModalData().type:
      return {
        ...state,
        leadRuleId: payload.data.leadRuleId,
        tab: "agents",
        unavailableAgents: payload.data.unavailableAgents,
        unavailablePonds: payload.data.unavailablePonds,
      };
    case actionCreators.showModal().type:
      return {
        ...state,
        show: payload.show,
      };
    case actionCreators.selectTab().type:
      return {
        ...state,
        tab: payload.tab,
      };

    case actionCreators.setSaving().type:
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        hasSaveError: false,
      };
    case actionCreators.setSaved().type:
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        hasSaveError: false,
      };
    case actionCreators.setSaveError().type:
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        hasSaveError: true,
      };

    case actionCreators.selectAgents().type:
      return {
        ...state,
        selectedAgents: [...payload.agents],
      };
    case actionCreators.selectPond().type:
      return {
        ...state,
        selectedPond: payload.pond,
      };
    case actionCreators.reset().type:
      return {
        ...state,
        selectedPond: null,
        selectedAgents: null,
        tab: "agents",
        show: false,
      };
    default:
      return state;
  }
};

export default leadRouting;
