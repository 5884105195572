import React, { useState } from "react";
import PropTypes from "prop-types";

import Checkbox from "../../Transactions/components/shared/Checkbox";
import Dropdown from "../../shared/v2/Dropdown";
import Button from "../../shared/v2/Button";
import DatePicker from "../../shared/v2/DatePicker";
import LogFilters from "./LogFilters";

const ReportsFilters = ({
  selectedTrackingNumbers,
  trackingNumbers,
  handleTrackingNumberFilterOnChange,
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
  chosenView,
  logFilters,
  setLogFilters,
  agents,
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);

  const applyFilters = (filters) => {
    setLogFilters(filters);
    setFiltersVisible(false);
  };

  return (
    <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-[8px] tw-border-solid tw-border-[1px] tw-border-neutral-gray-10 bs-xs:tw-p-[15px] tw-pl-[32px] tw-pr-20px tw-py-20px">
      <span className="tw-flex tw-text-gray-75 tw-m-0 tw-whitespace-nowrap tw-text-28px">
        Tracking Number {chosenView === "logs" ? "Logs" : "Reporting"}
      </span>
      <div className="tw-flex tw-gap-[16px]">
        <div className="tw-w-[220px]">
          <Dropdown
            placeholder={
              selectedTrackingNumbers.length === 0 ||
              selectedTrackingNumbers.length === trackingNumbers.length
                ? "All Tracking Numbers"
                : `${selectedTrackingNumbers.length} number${selectedTrackingNumbers.length === 1 ? "" : "s"} selected`
            }
            isMulti
            value={selectedTrackingNumbers}
            options={trackingNumbers}
            onChange={handleTrackingNumberFilterOnChange}
            formatOptionLabel={formatOptionLabel}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            multiShouldRenderBelowSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={false}
            components={{ Option }}
            isSearchable
          />
        </div>
        <DatePicker
          value={moment(minDate).format("MM/DD/YYYY")}
          onChange={(value) => setMinDate(moment(value, "MM/DD/YYYY").toISOString().substring(0, 10))}
          inputProps={{ size: 10, placeholder: "Min Date" }}
          position="bottom"
        />
        <DatePicker
          value={moment(maxDate).format("MM/DD/YYYY")}
          onChange={(value) => setMaxDate(moment(value, "MM/DD/YYYY").toISOString().substring(0, 10))}
          inputProps={{ size: 10, placeholder: "Max Date" }}
          position="bottom"
        />
        {chosenView === "logs" && (
          <Button schema="secondary" size="medium" onClick={() => setFiltersVisible(true)}>
            Filters
          </Button>
        )}
      </div>
      {filtersVisible && (
        <LogFilters
          filters={logFilters}
          onApply={applyFilters}
          onClose={() => setFiltersVisible(false)}
          agents={agents}
        />
      )}
    </div>
  );
};

export default ReportsFilters;

const formatOptionLabel = ({ label }) => (
  <div className="tw-flex tw-whitespace-nowrap">
    <span className="tw-overflow-ellipsis tw-w-[90px] tw-font-normal">{label}</span>
  </div>
);

const Option = ({ componentProps, children }) => (
  <Dropdown.Option
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...componentProps}
    className="!tw-flex tw-gap-[8px] tw-items-center tw-group"
    preserveStyle
  >
    <Checkbox checked={componentProps.isSelected} readOnly />
    {children}
  </Dropdown.Option>
);

Option.propTypes = {
  componentProps: PropTypes.shape({
    isSelected: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.shape({}).isRequired,
};
