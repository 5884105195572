import React from "react";

import { Info } from "@shared/v2/Icomoon";
import Tooltip from "@shared/v2/Tooltip";

import Upload from "./Upload";

const UploadedDocHeader = () => (
  <div className="tw-text-gray-75 tw-text-18d">
    Revenue Supporting Documents
    <Tooltip
      multiline
      placement="top"
      content={
        <div className="tw-w-[195px] tw-text-left tw-font-normal tw-leading-[16px] tw-text-[12px]">
          Upload the transaction’s CDA, settlement statement, notice of transaction completion, or alternative
          revenue support.
        </div>
      }
      tooltipClassName="tw-ml-[2px]"
      trigger={<Info className="tw-mb-[-1px] tw-ml-6px" />}
    />
    <span className="tw-float-right">
      <Upload />
    </span>
  </div>
);

export default UploadedDocHeader;
