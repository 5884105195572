/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ChevronUpSolidV6 = (props) => (
  <Icon {...props} name="Chevron Up Solid V6">
    <path d="M14.858 11.572a1.14 1.14 0 0 1-.808-.335L8 5.189 1.949 11.24A1.143 1.143 0 0 1 .333 9.624l6.858-6.858a1.143 1.143 0 0 1 1.616 0l6.858 6.858a1.138 1.138 0 0 1-.807 1.948z" />
  </Icon>
);

export default ChevronUpSolidV6;
