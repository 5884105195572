import React from 'react';
import Datetime from 'react-datetime';
import Input from './Input';
import moment from 'moment';

export const QUERY_DATE_FORMAT = "YYYY-MM-DD";

class DatePicker extends React.Component {
  handleDateOnChange = (e) => {
    const value = e;
    if(moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD'], true).isValid()) {
      this.props.onChange(e);
    }
  }

  handleDateOnBlur= (e) => {
    const value = e;
    if(!moment(value, ['MM-DD-YYYY', 'YYYY-MM-DD'], true).isValid()) {
      this.props.onChange('');
    }
  }

  handleDateOnFocus= () => {
    if(!moment(this.props.value, ['MM-DD-YYYY', 'YYYY-MM-DD'], true).isValid()) {
      this.props.onChange(moment().format('YYYY-MM-DD'));
    }
  }

  showCalendar = (props, openCalendar) => {
    return(
      <Input
        {...props}
        className='form-control input-sm date select-format' />
    )
  }

  clear = () => {
    this.refs.datetime.value = '';
  }

  formatValue = () => {
    if (this.props.dateFormat != undefined && this.props.dateFormat == 'MM-DD') {
      return moment(this.props.value, 'MM-DD');
    }
    else if(moment(this.props.value, 'YYYY-MM-DD', true).isValid()) {
      return moment(this.props.value, 'YYYY-MM-DD');
    }
    else {
      return '';
    }
  }

  render() {
    const {style,className,inputProps } = this.props;
    return (
      <Datetime
        {...this.props}
        style={style}
        className={className}
        value={this.formatValue()}
        renderInput={this.showCalendar}
        onChange={this.handleDateOnChange}
        onBlur={this.handleDateOnBlur}
        onFocus={this.handleDateOnFocus}
        inputProps={inputProps}
        />
    )
  }
}

export default DatePicker;
