import { schemaGenerator } from "../utils";

const dotBase = {
  default: ["tw-absolute", "tw-bg-white", "tw-rounded-full", "tw-translate-x-0", "tw-transition"],
  peerChecked: ["peer-checked:tw-translate-x-full"],
};

const dotDictionary = {
  small: {
    default: [...dotBase.default, "tw-w-[16px]", "tw-h-[16px]", "tw-left-[2px]", "tw-top-[2px]"],
    peerChecked: [...dotBase.peerChecked, "peer-checked:tw-left-[6px]"],
  },
  large: {
    default: [...dotBase.default, "tw-w-[24px]", "tw-h-[24px]", "tw-left-[3px]", "tw-top-[3px]"],
    peerChecked: [...dotBase.peerChecked, "peer-checked:tw-left-[9px]"],
  },
};

const trackBase = {
  default: ["tw-block", "tw-rounded-full", "tw-bg-neutral-gray-50"],
  peerChecked: ["peer-checked:tw-bg-theme-simple-toggle-color"],
  peerDisabled: [
    "peer-disabled:tw-bg-neutral-gray-15",
    "peer-disabled:peer-checked:tw-bg-theme-simple-toggle-color-disabled",
  ],
  peerFocusVisible: ["peer-focus-visible:tw-outline-theme", "peer-focus-visible:tw-outline-theme-offset-1px"],
};

const trackDictionary = {
  small: {
    default: [...trackBase.default, "tw-w-[40px]", "tw-h-[20px]"],
    peerChecked: trackBase.peerChecked,
    peerDisabled: trackBase.peerDisabled,
    peerFocusVisible: trackBase.peerFocusVisible,
  },
  large: {
    default: [...trackBase.default, "tw-w-[60px]", "tw-h-[30px]"],
    peerChecked: trackBase.peerChecked,
    peerDisabled: trackBase.peerDisabled,
    peerFocusVisible: trackBase.peerFocusVisible,
  },
};

const dotSchema = schemaGenerator(dotDictionary);
const trackSchema = schemaGenerator(trackDictionary);

const schemas = {
  dotSchema,
  trackSchema,
};

export default schemas;
