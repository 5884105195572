export const getIsSelectedFn = (selectedRangeIdx, hoverIdx) => (oIdx, fromHover) => {
  const [x, y] = selectedRangeIdx;
  const start = fromHover && hoverIdx !== null ? Math.min(x, hoverIdx) : x;
  const end = fromHover && hoverIdx !== null ? Math.max(x, hoverIdx) : y;
  return start <= oIdx && oIdx <= (end || start);
};

export const getOnSelectOptionFn =
  ({ allowMultiple, selectedRangeIdx, setSelectedRange, setHoverIdx }) =>
  (e, oIdx) => {
    e.target.blur();
    if (allowMultiple) {
      const [start, end] = selectedRangeIdx;
      if (start === null || start === 0 || oIdx === 0) {
        setSelectedRange([oIdx]);
      } else if ((end === null || typeof end === "undefined") && start !== oIdx) {
        const range = [Math.min(start, oIdx), Math.max(start, oIdx)];
        setSelectedRange(range);
      } else {
        setSelectedRange([oIdx]);
      }
    } else {
      setSelectedRange([oIdx]);
    }
    setHoverIdx(null);
  };
