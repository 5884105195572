import React, { useEffect } from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";
import Image from "../../../../../shared/v2/Icomoon/Icons/Image";

import * as actions from "../actions/creators";
import * as thunks from "../actions/thunks";

import Modal from "../../../../../shared/v2/Modal";
import Button from "../../../../../shared/v2/Button";
import UploadImage from "../../../../Main/Details/UploadImage";

const mapStateToProps = (state) => ({
  listingId: state.tdpDetailsReducer.slug,
  show: state.tdpThumbnailSelector.show,
  availableImages: state.tdpDetailsReducer.listingImgs,
  savedThumbnail: state.tdpDetailsReducer.headerDetails.photo,
  selectedPhoto: state.tdpThumbnailSelector.selectedPhoto,
  saving: state.tdpThumbnailSelector.isSaving,
  saved: state.tdpThumbnailSelector.isSaved,
  uuid: state.tdpDetailsReducer.uuid,
});
const ThumbnailSelector = ({
  listingId,
  show,
  availableImages,
  savedThumbnail,
  selectedPhoto,
  selectPhoto,
  saveForm,
  saving,
  saved,
  reset,
  uuid,
}) => {
  const canSave = selectedPhoto && selectedPhoto?.id !== savedThumbnail?.id;
  useEffect(() => {
    if (saved) {
      reset();
    }
  }, [saved]);
  useEffect(() => {
    if (show) {
      selectPhoto(savedThumbnail);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={reset}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
    >
      <Modal.Header title="Select Thumbnail Image" closeButton />
      <h3 className="tw-text-neutral-gray tw-text-[18px] tw-font-bold tw-mt-[32px] tw-mb-[24px]">
        Listing Photos {`(${availableImages?.length || 0})`}
      </h3>
      <div className="tw-max-h-[50vh] tw-overflow-y-scroll tw-grid tw-grid-cols-3 tw-gap-[12px]">
        <UploadImage
          size="small"
          onAdd={(photo) => {
            selectPhoto({ ...photo, cloud_location: photo.url });
          }}
        />
        {availableImages?.length
          ? availableImages.map((p) =>
              p?.isLoading ? (
                <div
                  data-cy="image-upload-processing"
                  className="tw-bg-neutral-gray-10 tw-w-[176px] tw-h-[176px] tw-flex tw-flex-col tw-justify-center tw-items-center"
                >
                  <Image size="xl" className="tw-mb-8px" />
                  <div className="tw-font-bs-sans tw-text-12px tw-font-semibold tw-text-center">
                    PROCESSING IMAGE...
                    <br />
                    {p?.name}
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className={`tw-h-[176px] tw-w-[176px] tw-rounded-[4px] tw-overflow-hidden tw-border-[3px] tw-border-solid tw-p-0 ${
                    p.id === selectedPhoto?.id ? "tw-border-theme-primary" : "tw-border-white"
                  }`}
                  onClick={() => selectPhoto(p)}
                >
                  <img src={p.url || ""} alt="Listing Rule" className="tw-h-full tw-w-full tw-object-cover" />
                </button>
              ),
            )
          : null}
      </div>

      <div className="tw-flex tw-mt-[60px]">
        <Button size="medium" schema="tertiary" onClick={reset}>
          Cancel
        </Button>
        <div className="tw-flex-1" />
        <Button
          disabled={!canSave}
          size="medium"
          schema="primary"
          onClick={() => saveForm({ listingId, selectedPhoto, uuid })}
        >
          {saving && <i className="fa fa-pulse fa-spinner" />}
          {!saving && !saved && "Save"}
        </Button>
        {saving}
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, { ...actions, ...thunks })(ThumbnailSelector);

ThumbnailSelector.propTypes = {
  listingId: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
  show: Proptypes.bool,
  savedThumbnail: Proptypes.shape({
    id: Proptypes.number,
  }),
  selectedPhoto: Proptypes.shape({
    id: Proptypes.number,
  }),
  availableImages: Proptypes.arrayOf(Proptypes.shape({})),
  selectPhoto: Proptypes.func,
  saveForm: Proptypes.func,
  saving: Proptypes.bool,
  saved: Proptypes.bool,
  reset: Proptypes.func,
  uuid: Proptypes.string.isRequired,
};

ThumbnailSelector.defaultProps = {
  listingId: null,
  show: false,
  savedThumbnail: null,
  selectedPhoto: null,
  availableImages: null,
  selectPhoto: null,
  saveForm: null,
  saving: null,
  saved: false,
  reset: null,
};
