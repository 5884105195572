import React from "react";
import { func, string } from "prop-types";

import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import withDispatch from "../../../../shared/HOCs/withDispatch/withDispatch";
import { LISTING_UUID, REFERRALPARTNER_TEAM } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";
import { putRefTeam } from "../actions/thunks";

import DetailInput from "../DetailInput";

const ReferralTeamFieldWrapper = ({ team, uuid, onPutRefTeam }) => (
  <DetailInput
    dataCy="ReferralTeamField"
    label="Team"
    schema="text"
    val={team}
    onBlur={(newTeamName) => onPutRefTeam(uuid, newTeamName)}
  />
);

const withReduxStates = tdpDetails({
  WrappedComponent: ReferralTeamFieldWrapper,
  states: [REFERRALPARTNER_TEAM, LISTING_UUID],
});

export default withDispatch({
  WrappedComponent: withReduxStates,
  actions: putRefTeam,
  aliases: "onPutRefTeam",
});

ReferralTeamFieldWrapper.propTypes = {
  team: string,
  uuid: string,
  onPutRefTeam: func,
};

ReferralTeamFieldWrapper.defaultProps = {
  team: null,
  uuid: null,
  onPutRefTeam: () => {},
};
