import React from "react";
import { connect } from "react-redux";
import { arrayOf, shape, string, func } from "prop-types";

import { EVENTS } from "../constants";
import useInit from "./hooks";
import useHandlrs from "../hooks";
import { payloadPropTypes } from "../propTypes";
import Dropdown from "../../../../shared/v2/Dropdown";

const ModalAddEditDropdowns = ({ assignableAgents, payloadHandlrs, payload, currentUserUuid }) => {
  const dropdownHandlr = useHandlrs(payloadHandlrs);
  useInit(payloadHandlrs, assignableAgents, payload, currentUserUuid);

  return (
    <div className="tw-flex tw-justify-between tw-mt-24px">
      <Dropdown
        containerClassName="tw-w-[264px]"
        label="Task Type"
        value={payload.eventType}
        placeholder="Select Task Type"
        onChange={(e) => dropdownHandlr(e, "eventType")}
        options={EVENTS}
      />
      <Dropdown
        containerClassName="tw-w-[264px]"
        placeholder="Select Team Member"
        value={payload.assignedPerson}
        options={assignableAgents}
        onChange={(e) => dropdownHandlr(e, "assignedPerson")}
        label="Assigned To"
        isSearchable
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  assignableAgents: state.tdpTasksReducer.assignableAgents,
  currentUserUuid: state.tdpDetailsReducer.currentUser?.uuid,
});

ModalAddEditDropdowns.propTypes = {
  assignableAgents: arrayOf(
    shape({
      label: string,
      value: string,
    }),
  ),
  payload: payloadPropTypes,
  payloadHandlrs: func,
  currentUserUuid: string,
};

ModalAddEditDropdowns.defaultProps = {
  assignableAgents: [],
  payload: null,
  payloadHandlrs: null,
  currentUserUuid: "",
};

export default connect(mapStateToProps)(ModalAddEditDropdowns);
