import React from "react";
import PropTypes from "prop-types";
import { FlyoutContext } from "./FlyoutContext";
import { FLYOUT_BACKDROP_CLASSES } from "./Flyout.styles";

/**
 * @summary Creates the background behind the flyout content
 * @description This component is meant to be rendered inside a FlyoutContext and provide
 * a background-color for the flyout.
 * */

const Backdrop = ({ children, className }) => {
  const { onHide } = React.useContext(FlyoutContext);

  return (
    <>
      {/* FlyoutContext already provides the keyboard listeners so we silence the linter here */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={() => onHide()} className={`${FLYOUT_BACKDROP_CLASSES} ${className}`}>
        {children}
      </div>
    </>
  );
};
Backdrop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Backdrop.defaultProps = {
  children: null,
  className: "",
};

export default Backdrop;
