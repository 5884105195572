import PropTypes from "prop-types";
import { omit } from "lodash";

import { deprecationWarning } from "../../Utilities";
import { buttonDefaultProps, buttonPropTypes } from "../Button/Button";
import { textButtonDefaultProps, textButtonPropTypes } from "../TextButton/TextButton";

// Props that should not be able to be overridden in the modal,
// due to design system-imposed constraints
const hardcodedButtonProps = ["size", "onClick", "children"];
const hardcodedCancelButtonProps = [...hardcodedButtonProps, "schema"];

export const primaryPropTypes = PropTypes.shape(omit(buttonPropTypes, hardcodedButtonProps));
export const secondaryPropTypes = PropTypes.shape(omit(textButtonPropTypes, hardcodedButtonProps));
export const cancelPropTypes = PropTypes.shape(omit(buttonPropTypes, hardcodedCancelButtonProps));

export const primaryDefaultProps = omit(buttonDefaultProps, hardcodedButtonProps);
export const secondaryDefaultProps = omit(textButtonDefaultProps, hardcodedButtonProps);
export const cancelDefaultProps = omit(buttonDefaultProps, hardcodedCancelButtonProps);

/**
 * Takes `optionProps`, which should have `primaryProps` and `secondaryProps`,
 * and optionally `primarySchema` and `secondarySchema`,
 * and returns props compatible with the new API, by moving `primarySchema` to `primaryProps.schema` if provided,
 * and `secondarySchema` to `secondaryProps.schema` if provided.
 *
 * @param {Record<string, unknown>} optionProps Option props.
 * @returns {{
 *   primaryProps: Record<string, unknown>;
 *   secondaryProps: Record<string, unknown>;
 * }} The updated props, compatible with the new API.
 */
export const makeOptionPropsCompatible = (optionProps) => {
  // Clone props so we don't mutate the default props
  const primaryProps = { ...optionProps.primaryProps };
  const secondaryProps = { ...optionProps.secondaryProps };

  if (optionProps.primarySchema) {
    deprecationWarning(
      "FloatingForm/Prompt: `primarySchema` is deprecated, use `primaryOptions.schema` instead.",
    );
    primaryProps.schema = optionProps.primarySchema;
  }

  if (optionProps.secondarySchema) {
    deprecationWarning(
      "FloatingForm/Prompt: `secondarySchema` is deprecated, use `secondaryProps.schema` instead.",
    );
    secondaryProps.schema = optionProps.secondarySchema;
  }

  return { primaryProps, secondaryProps };
};
