import React, { Component } from "react";

class NewModalHeader extends Component {
  render() {
    return (
        <span className="new-modal-header" style={{padding:this.props.isCreatingAppointment ? "0 15px 0 20px":null}}>
          <div className="task-header" id={this.props.id} style={{marginLeft:this.props.isSchedulingAppointment ? "29px" : null}}>{this.props.header}</div>
          <div className="tw-text-center tw-touch-manipulation tw-cursor-pointer tw-ml-10px tw-border tw-border-solid tw-border-r-3px tw-border-b-3px tw-border-transparent tw-pt-[0.3em] tw-pb-[0.3em] tw-pr-[0.8em] tw-pl-[0.8em] tw-text-15px tw-float-right fa-stack fa-2x" onClick={this.props.closeModal}>
            <i style={{paddingLeft: this.props.isSchedulingAppointment ? null : "70px"}} className="tw-align-text-top tw-pr-[0.1em] fa fa-circle close-button"></i>
            <i className="tw-align-text-top tw-pr-[0.1em] fa fa-close x-button"></i>
          </div>
        </span>
    );
  }
}

export default NewModalHeader;
