import React from "react";

import Avatar from "../Avatar";

/**
 * @param {object} componentProps Passed from Dropdown.
 * @param {object} data Passed from Dropdown.
 * @param {boolean} isInControl Whether or not the avatar is in a control
 * (if it is, it's only visible when the menu is closed and there's an option selected).
 * @returns {Avatar}
 */
const getAvatarFromDropdownData = (componentProps, data, isInControl) => {
  const className = isInControl ? "tw--my-2px" : "";
  const src = data.meta?.src ?? null;
  const alt = data.meta?.alt ?? null;

  let avatar = null;
  if (componentProps.isDisabled) {
    avatar = <Avatar className={className} />;
  } else if (!isInControl || (!componentProps.selectProps.menuIsOpen && componentProps.hasValue)) {
    avatar = <Avatar className={className} src={src} alt={alt} />;
  }

  return avatar;
};

export default getAvatarFromDropdownData;
