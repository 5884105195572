import React, { useEffect, useState } from "react";
import { func, bool, arrayOf, shape, string } from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import Dropdown from "@shared/v2/Dropdown/Dropdown";
import Search from "@shared/v2/Icomoon/Icons/Search";
import TextLink from "@shared/v2/TextLink";
import { useDebounce } from "@shared/hookHelpers";
import PersonDetailsModal from "../../../../Contacts/PersonDetailsModal";
import useQuickSearch from "../../../../Dashboard/services/useQuickSearch";

const AddCoBorrowerModal = ({ modalOpen, closeModal, handleAddCoBorrower, coborrowers }) => {
  const [value, setValue] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [_search, setSearch] = useState("");
  const search = useDebounce(_search, 250);
  const { data, loading } = useQuickSearch({ search, model: "Person", isCoborrower: true, coborrowers });

  const handleAdd = () => {
    closeModal();
    handleAddCoBorrower(value);
  };

  const closeContactModal = () => {
    setshowModal(false);
  };

  const openModal = () => {
    setshowModal(true);
  };

  const handleCloseContact = (person) => {
    closeContactModal();
    if (person) {
      setValue({
        label: `${person?.firstName} ${person?.lastName}`,
        value: person?.id,
        object_type: "Person",
        slug: person?.slug,
        object_id: person?.id,
        uuid: person?.uuid,
      });
    }
  };
  return (
    <>
      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        dialogClassName="tw-max-w-[510px] tw-w-full"
        show={modalOpen}
        onHide={closeModal}
      >
        <Modal.Header
          closeButton
          title="Add a Co-Borrower"
          description={<span>Select a co-borrower associated with the loan.</span>}
        />
        <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-gap-[16px]">
          <div className="tw-pt-[32px] tw-pb-[8px]">
            <div className="tw-grid tw-grid-cols-2  tw-gap-[24px]">
              <div className="tw-col-span-2">
                <Dropdown
                  placeholder="Search by contact name, email, or phone "
                  isRequired
                  isSearchable
                  isLoading={loading}
                  onInputChange={setSearch}
                  noOptionsMessage={
                    _search.length < 3
                      ? `Enter ${3 - _search.length} more characters to search`
                      : "No Options"
                  }
                  onChange={setValue}
                  value={value}
                  options={data}
                  menuShouldComeToFront
                  shouldFilterOptions={false}
                  inputTrailing={<Search size="l" style={{ verticalAlign: "middle" }} />}
                />
              </div>
            </div>
            <div className="tw-pt-[4px] tw-mb-[24px] tw-text-[12px] tw-text-gray-50 tw-leading-[16px] tw-font-normal">
              Don’t have the co-borrower as a contact in your CRM?{" "}
              <TextLink className="tw-text-[12px] tw-leading-[16px]" onClick={openModal}>
                Add them here
              </TextLink>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-gap-[12px] tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="medium" type="submit" onClick={handleAdd} disabled={!value}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {showModal && (
        <PersonDetailsModal showAddDetailsButton={false} show={showModal} onClose={handleCloseContact} />
      )}
    </>
  );
};

AddCoBorrowerModal.propTypes = {
  modalOpen: bool,
  closeModal: func,
  handleAddCoBorrower: func,
  coborrowers: arrayOf(
    shape({
      id: string,
      full_name: string,
    }),
  ),
};

AddCoBorrowerModal.defaultProps = {
  modalOpen: false,
  closeModal: () => {},
  handleAddCoBorrower: () => {},
  coborrowers: [],
};

export default AddCoBorrowerModal;
