import React from "react";
import PropTypes from "prop-types";
import RingChart from "../../../shared/v2/RingChart";
import CardLabel from "../BusinessDashboard/CardLabel";
import Button from "../../../shared/v2/Button";

const RingChartCard = ({
  onSetGoal,
  year,
  data: {
    baseTooltip,
    format,
    hasGoal,
    displayName,
    chartData,
    chartLabel,
    goal,
    total,
    ytdDifference,
    prevYearYtdDifference,
  },
}) => (
  <div className="tw-relative tw-flex tw-flex-1 tw-basis-[47%]">
    <div className="tw-min-h-[104px]">
      <RingChart
        size={104}
        strokeWidth={10}
        data={chartData}
        baseTooltip={baseTooltip}
        label={hasGoal ? chartLabel : "–"}
      />
    </div>
    <div className="tw-flex tw-flex-col tw-ml-[20px] tw-grow tw-gap-[8px]">
      <CardLabel className="tw-flex-col" size="l" label={displayName} value={total} />
      <div className="tw-flex tw-flex-wrap tw-w-full tw-gap-[24px]">
        <CardLabel className="tw-flex-col" label={`${year} Goal`} value={goal} />
        <CardLabel
          format={format}
          className="tw-flex-col"
          label="YTD Goal"
          tooltip="Amount from YTD goal"
          ytdDifference={ytdDifference}
        />
        {prevYearYtdDifference && (
          <CardLabel
            format={format}
            className="tw-flex-col"
            label={`${year - 1} YTD`}
            ytdDifference={prevYearYtdDifference}
          />
        )}
      </div>
    </div>
    {!hasGoal && (
      <div className="tw-absolute tw-inset-0 tw-bg-white/90 tw-flex tw-justify-center tw-items-center">
        <Button onClick={() => onSetGoal(displayName)}>Set Goal for {displayName}</Button>
      </div>
    )}
  </div>
);

RingChartCard.propTypes = {
  onSetGoal: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.shape({
    baseTooltip: PropTypes.arrayOf(PropTypes.string),
    format: PropTypes.string,
    hasGoal: PropTypes.bool,
    displayName: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    chartData: PropTypes.any,
    chartLabel: PropTypes.string,
    goal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ytdDifference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prevYearYtdDifference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default RingChartCard;
