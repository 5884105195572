import { useSelector } from "react-redux";

export const useCallOutcome = () => useSelector((state) => state.dialer.outcome);
export const useCallState = () => useSelector((state) => state.dialer.callState);
export const useIsRecording = () => useSelector((state) => state.dialer.isRecording);
export const useIsRecordingConsentRequired = () => useSelector((state) => state.dialer.isRecordingConsentRequired);
export const useConference = () => useSelector((state) => state.dialer.conference);
export const useError = () => useSelector((state) => state.dialer.error);
export const useLeadUuids = () => useSelector((state) => state.dialer.leadUuids);
export const useLoading = () => useSelector((state) => state.dialer.loading);
export const useComment = () => useSelector((state) => state.dialer.comment);
export const usePerson = () => useSelector((state) => state.dialer.person);
export const usePhoneNumber = () => useSelector((state) => state.dialer.phoneNumber);
export const usePhoneNumbers = () => useSelector((state) => state.dialer.phoneNumbers);
export const useSession = () => useSelector((state) => state.dialer.session);
export const useValidLeads = () => useSelector((state) => state.dialer.validLeads);
