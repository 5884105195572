/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PenToSquareRegularV6 = (props) => (
  <Icon {...props} name="Pen To Square Regular V6">
    <path d="M11.659.78a2.248 2.248 0 0 1 3.181 0l.378.379a2.251 2.251 0 0 1 0 3.182l-6.162 6.166a2.281 2.281 0 0 1-.975.572l-3.125.894a.71.71 0 0 1-.737-.219c-.191-.166-.266-.447-.191-.709l.894-3.125c.106-.369.303-.703.572-.975L11.66.782zm2.094 1.061c-.266-.293-.741-.293-1.034 0l-.909.909 1.441 1.441.909-.938c.294-.266.294-.741 0-1.033l-.406-.379zm-7.387 6.49-.525 1.828 1.828-.525a.726.726 0 0 0 .325-.191l4.197-4.194-1.441-1.441-4.194 4.197a.735.735 0 0 0-.191.325zM6.25 2a.749.749 0 1 1 0 1.5h-3.5c-.69 0-1.25.559-1.25 1.25v8.5c0 .691.56 1.25 1.25 1.25h8.5a1.25 1.25 0 0 0 1.25-1.25v-3.5c0-.416.334-.75.75-.75s.75.334.75.75v3.5A2.75 2.75 0 0 1 11.25 16h-8.5A2.75 2.75 0 0 1 0 13.25v-8.5A2.75 2.75 0 0 1 2.75 2h3.5z" />
  </Icon>
);

export default PenToSquareRegularV6;
