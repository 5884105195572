import { useEffect, useState } from "react";
import { isCancel } from "axios";
import { caseTransformingAxios as axios } from "../../shared/v2/caseTransformingAxios";

const getAgentActivities = (opts) => axios.get("/api/v4/agent_activities/goal_progress_web", opts);

const createAgentActivity = (data) => axios.post("/api/v4/agent_activities", data);

const useLeadGenActivities = ({ period, startDate, endDate, userUuids }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const refreshData = (signal) => {
    setLoading(true);
    getAgentActivities({
      params: {
        thisMonthSelected: period === "this_month" || undefined,
        startDate,
        endDate,
        userUuids,
      },
      signal,
    })
      .then((res) => {
        setData(res.data);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          setError(err.response.data);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    refreshData(abortController.signal);
    return () => abortController.abort();
  }, [startDate, endDate, userUuids]);

  const actions = {
    createAgentActivity(activityData) {
      return createAgentActivity(activityData)
        .then(() => refreshData())
        .catch((err) => setError(err.response.data));
    },
    refreshData() {
      return refreshData();
    },
    setError,
  };

  return { actions, data, error, loading };
};

export default useLeadGenActivities;
