/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";
import { bool, elementType, string } from "prop-types";
import { BoltLightningSolidV6 } from "../../../../shared/v2/Icomoon";

const TaskItemAssignedPlanIcon = ({ IconComponent, show, className, iconSize, ...otherProps }) => {
  const Icon = useMemo(() => IconComponent || BoltLightningSolidV6, [IconComponent]);
  const size = iconSize || "m";

  return show && <Icon size={size} className={className} {...otherProps} />;
};

TaskItemAssignedPlanIcon.propTypes = {
  IconComponent: elementType,
  show: bool,
  iconSize: string,
  className: string,
};

TaskItemAssignedPlanIcon.defaultProps = {
  IconComponent: null,
  iconSize: null,
  show: false,
  className: "",
};

export default TaskItemAssignedPlanIcon;
