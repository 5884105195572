import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "../../../shared/v1";

const DateRangeDropdown = ({ dateOptions, handleDateSelect, selectedDate }) => (
  <div>
    <hr
      style={{
        height: "2px",
        color: "#F3F3F3",
        backgroundColor: "#F3F3F3",
        margin: "0.8em 0",
      }}
    />
    <label
      htmlFor="close-date-range"
      style={{
        letterSpacing: "0.2px",
        fontSize: "14px",
        fontFamily: "Open Sans",
      }}
    >
      <b>CLOSE DATE RANGE</b>
    </label>
    <Dropdown
      id="close-date-range"
      options={dateOptions}
      onChange={(date) => handleDateSelect(date)}
      value={selectedDate}
      isClearable={false}
      variant="flatGray"
    />
  </div>
);

DateRangeDropdown.propTypes = {
  dateOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleDateSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.shape().isRequired,
};

export default DateRangeDropdown;
