import React from "react";
import { number, oneOfType, string } from "prop-types";
import { trim } from "lodash";
import { tdpDetails } from "../../../../shared/HOCs/withRedux";
import { CITY, LOCALITY, POSTAL_CODE, STREET_ADDRESS } from "../../../../shared/reduxRoutes/tdpDetailsRoutes";

const FullPropertyAddress = ({ streetAddress, city, state, zipCode }) => {
  const propertyAddress = [streetAddress, city, state, zipCode].filter(Boolean).join(", ");

  return (
    <div className="tw-flex tw-items-center tw-mb-8px">
      <div className="tw-font-semibold">Property Address:</div>
      <div className="!tw-text-14px !tw-text-gray-75 tw-font-normal !tw-normal-case tw-ml-[6px]">
        {trim(propertyAddress)}
      </div>
    </div>
  );
};

export default tdpDetails({
  WrappedComponent: FullPropertyAddress,
  states: [STREET_ADDRESS, LOCALITY, POSTAL_CODE, CITY],
  aliases: ["streetAddress", "state", "zipCode"],
});

FullPropertyAddress.propTypes = {
  streetAddress: string,
  city: string,
  state: string,
  zipCode: oneOfType([string, number]),
};

FullPropertyAddress.defaultProps = {
  streetAddress: null,
  city: null,
  state: null,
  zipCode: null,
};
