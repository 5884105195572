import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import useAiToken from "@shared/AiAssistant/hooks/useAiToken";
import {
  focusConversation,
  getConversations,
  getMessages,
  getPhoneStatuses,
  nextConversationPage,
  receiveSignalRMessageResetCount,
  receiveSignalRMessageStatusFailure,
  receiveSignalRNewConversation,
  receiveSignalRNewMessage,
  setHideAi,
  setTab,
  setUnreadOnly,
  toggleAiFilter,
} from "../../reducers/messagingReducer";
import {
  useAiFilters,
  useConversations,
  useConversationsPage,
  useCurrentTab,
  useErrors,
  useFocusedConversation,
  useHasMore,
  useHideAi,
  useLoadingConversations,
  useMessagesPage,
  useMessagingServiceUrl,
  useSelectedAgentFilters,
  useSelectedMessages,
  useUnreadOnly,
} from "../../reducers/messagingReducer/selectors";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";

const shouldNotFetch = (focusedConversation) =>
  !focusedConversation.uuid || focusedConversation.uuid === "temp";

const useMessaging = () => {
  const { token } = useAiToken();
  const dispatch = useDispatch();
  const messagingServiceUrl = useMessagingServiceUrl();
  const currentUser = useCurrentUser();
  const focusedConversation = useFocusedConversation();
  const conversationsPage = useConversationsPage();
  const messagesPage = useMessagesPage();
  const selectedAgentFilters = useSelectedAgentFilters();
  const conversations = useConversations();
  const selectedMessages = useSelectedMessages();
  const hasMore = useHasMore();
  const currentTab = useCurrentTab();
  const loadingConversations = useLoadingConversations();
  const errors = useErrors();
  const hideAi = useHideAi();
  const unreadOnly = useUnreadOnly();
  const aiFilters = useAiFilters();

  const agentIds = selectedAgentFilters.filter((o) => o !== "all").join(",");

  useEffect(() => {
    if (shouldNotFetch(focusedConversation)) return () => {};
    return dispatch(getMessages()).abort;
  }, [focusedConversation, messagesPage]);

  useEffect(() => {
    if (shouldNotFetch(focusedConversation)) return () => {};
    return dispatch(getPhoneStatuses()).abort;
  }, [focusedConversation]);

  useEffect(
    () => dispatch(getConversations()).abort,
    [agentIds, conversationsPage, currentTab, hideAi, unreadOnly, aiFilters],
  );

  useEffect(() => {
    if (!token) return () => {};

    const connection = new HubConnectionBuilder()
      .withUrl(`${messagingServiceUrl}/MessageHub?access_token=${token}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        withCredentials: true,
      })
      .withAutomaticReconnect()
      .configureLogging(process.env.NODE_ENV === "production" ? LogLevel.Error : LogLevel.Debug)
      .build();

    connection.on("ActivateConversation", (conversationUuid) => {
      connection.send("AddPersonToGroup", conversationUuid);
    });

    connection.on("CreateConversation", (conversation) => {
      connection.send("AddPersonToGroup", conversation.uuid);
      dispatch(receiveSignalRNewConversation(conversation));
    });

    connection.on("NewMessage", (message) => {
      dispatch(receiveSignalRNewMessage(message));
    });

    connection.on("ResetConversationUnreadMessageCount", (conversationUuid, priorCount, isChannel) => {
      dispatch(receiveSignalRMessageResetCount({ conversationUuid, priorCount, isChannel }));
    });

    connection.on("UpdateSmsOrGroupSmsConversationUserMessageStatusOnFailure", (deliveryStatus) => {
      dispatch(receiveSignalRMessageStatusFailure(deliveryStatus));
    });

    // TODO: better error messaging
    connection.start().catch(console.log);

    return () => {
      // TODO: better error messaging
      connection.stop().catch(console.log);
    };
  }, [messagingServiceUrl, token]);

  return {
    actions: {
      focusConversation: (conversation) => dispatch(focusConversation(conversation)),
      nextPage: () => dispatch(nextConversationPage()),
      setTab: (tab) => dispatch(setTab(tab)),
      setHideAi: () => dispatch(setHideAi(!hideAi)),
      setUnreadOnly: () => dispatch(setUnreadOnly(!unreadOnly)),
      toggleAiFilter: (filters) => dispatch(toggleAiFilter(filters)),
    },
    conversations,
    currentTab,
    currentUser,
    errors,
    focusedConversation,
    hasMore,
    loadingConversations,
    selectedMessages,
    hideAi,
    unreadOnly,
    aiFilters,
  };
};

export default useMessaging;
