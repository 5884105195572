import React from "react";
import PropTypes from "prop-types";
import { Trash as TrashIcon } from "../Icomoon";
import styleSchema from "./RemoveFieldButton.styles";

const RemoveFieldButton = ({ className, type, onClick }) => (
  <button
    aria-label="remove"
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={`
      tw-inline-flex tw-justify-center tw-items-center
      tw-w-[36px] tw-h-[36px]
      tw-border tw-border-solid tw-rounded-[2px]
      ${styleSchema.regular}
      ${className}
    `}
    onClick={onClick}
  >
    <TrashIcon size="l" />
  </button>
);

RemoveFieldButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

RemoveFieldButton.defaultProps = {
  className: "",
  type: "button",
  onClick: null,
};

export default RemoveFieldButton;
