import React from "react";
import { CircleExclamationRegularV6 } from "../../../shared/v2/Icomoon";

const DncBanner = () => (
  <div
    className={`tw-py-12px tw-px-16px tw-rounded-8px tw-text-semantic-yellow-120 tw-bg-semantic-yellow-5 tw-flex tw-space-x-16px tw-items-center`}
  >
    <div className="tw-text-semantic-yellow-110">
      <CircleExclamationRegularV6 size="xl" />
    </div>
    <div className="tw-space-y-4px">
      <p className="tw-font-bold tw-m-0 tw-text-14d">Some numbers might be on the DNC list</p>
      <p className="tw-m-0 tw-text-14d tw-space-x-[4px]">
        Certain numbers may be on the Do Not Call registry. You may be held liable for contacting these
        individuals.{` `}
        <a
          className="tw-font-semibold tw-text-semantic-yellow-120 visited:tw-text-semantic-yellow-120 active:tw-text-semantic-yellow-120 hover:tw-text-semantic-yellow-120"
          href="https://www.brivityknowledge.com/v1/docs/do-not-call-registry-warning"
          target="_blank"
        >
          Learn More.
        </a>
      </p>
    </div>
  </div>
);

export default DncBanner;
