import React from "react";
import PropTypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { Control, Option, ValueContainer } from "@shared/v2/Dropdown/custom";
import Loading from "../../Goals/components/Loader";

const CustomControl = ({ children, componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Control className="tw-flex tw-justify-start" {...componentProps}>
    {children}
  </Control>
);

CustomControl.propTypes = {
  children: PropTypes.node.isRequired,
  componentProps: PropTypes.shape().isRequired,
};

const CustomValueContainer = ({ children, componentProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ValueContainer className="tw-flex tw-justify-start" {...componentProps}>
    {children}
  </ValueContainer>
);

CustomValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  componentProps: PropTypes.shape().isRequired,
};

export const CustomOption = ({ children, componentProps, data }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Option preserveStyle {...componentProps}>
    <div className="tw-flex tw-flex-col">
      {children}
      <span className="tw-font-normal tw-text-neutral-gray-50 tw-whitespace-break-spaces">
        {data.meta.description}
      </span>
    </div>
  </Option>
);

CustomOption.propTypes = {
  children: PropTypes.node.isRequired,
  componentProps: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
};

const CallRecordingDropdown = ({ loading, ...props }) => (
  <div className="tw-relative">
    {loading && <Loading />}
    <Dropdown
      components={{
        Control: CustomControl,
        Option: CustomOption,
        ValueContainer: CustomValueContainer,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </div>
);

CallRecordingDropdown.propTypes = {
  loading: PropTypes.bool,
};

CallRecordingDropdown.defaultProps = {
  loading: false,
};

export default CallRecordingDropdown;
