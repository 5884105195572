/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const PhoneVolumeSolidV6 = (props) => (
  <Icon {...props} name="Phone Volume Solid V6">
    <path d="M8.894 5.684a.75.75 0 0 0-.95 1.162c.353.289.557.709.557 1.152s-.203.863-.557 1.152a.75.75 0 1 0 .95 1.162c.704-.576 1.107-1.419 1.107-2.314s-.404-1.737-1.107-2.315zm1.89-2.337a.75.75 0 1 0-.952 1.16c1.059.868 1.666 2.131 1.666 3.466s-.607 2.597-1.666 3.466a.75.75 0 0 0 .952 1.16C12.193 11.473 13 9.785 13 7.973s-.806-3.444-2.216-4.625zM12.7 1.036a.75.75 0 1 0-.951 1.161C13.497 3.629 14.5 5.745 14.5 7.973s-1.003 4.372-2.752 5.803a.75.75 0 0 0 .952 1.16c2.098-1.691 3.301-4.229 3.301-6.963s-1.203-5.217-3.3-6.936zm-7.972 4.42a.775.775 0 0 0 .772-.469L6.555 2.35c.133-.34-.02-.754-.298-.917L4.149.115a.75.75 0 0 0-.932.106c-4.288 4.288-4.288 11.269 0 15.557.249.25.632.293.93.106l2.109-1.318a.755.755 0 0 0 .3-.916l-1.054-2.635a.75.75 0 0 0-.773-.468l-1.664.166a8.414 8.414 0 0 1 .001-5.423l1.664.166z" />
  </Icon>
);

export default PhoneVolumeSolidV6;
