/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const MergeSolidV6 = (props) => (
  <Icon {...props} name="Merge Solid V6">
    <path d="M0 3a1 1 0 0 1 1-1h3.519c.609 0 1.181.276 1.563.751L9.482 7h2.519V5.5c0-.303.181-.578.462-.694s.603-.05.791.162l2.5 2.5c.319.294.319.769 0 1.034l-2.5 2.5c-.188.241-.509.306-.791.191s-.462-.391-.462-.694v-1.5H9.482l-3.4 4.222a1.939 1.939 0 0 1-1.563.778H1a1 1 0 1 1 0-2h3.519l3.2-4-3.2-4H1a1 1 0 0 1-1-1z" />
  </Icon>
);

export default MergeSolidV6;
