import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PersonTimelineFilter from "./PersonTimelineFilter";
import personDetailCss from "../../PersonDetail/components/person-detail-styles.module.css";
import * as actions from "../../PersonDetail/actions/personDetailActionCreators";
import LoadingSkeleton from "./LoadingSkeleton";
import LoadingFailure from "./LoadingFailure";
import { useSelector } from "react-redux";
import EventComponentSelector from "./EventComponentSelector";
import ErrorBoundary from "@shared/v2/ErrorBoundary";

const WEB_ACTIVITY_EVENT_TYPES = [
  "listing-alert-event",
  "modified_listing_alert_frequency",
  "property_favorite_created",
  "property_favorite_removed",
  "contact_form_questions_about",
  "home_value",
  "scheduled_showing",
  "market_report_frequency_updated",
  "market_report_sent",
  "qualification_form",
  "signup",
  "ads-retarget",
  "ads-remarket",
  "ads-sell",
  "contact_form_ask_a_question",
  "kwkly_property_view",
  "cma_report_created",
];

const INITIAL_FILTERS = new Set([
  "action-event",
  "document-event",
  "note-event",
  "task-event",
  "listing-alert-event",
  "appointment-event",
  "appointment_scheduled-event",
  "call-event",
  "sms-event",
  "group_sms_in",
  "group_sms_out",
  "message-event",
  "login-event",
  "Pop by-event",
  "Other-event",
  "Mail-event",
  "Social media-event",
  "person_created",
  "person_updated",
  "person_merge",
  ...WEB_ACTIVITY_EVENT_TYPES,
]);

const timelineBubbleSort = (arr) => {
  var len = arr.length;
  for (var i = 0; i < len; i++) {
    for (var j = 0; j < len - i - 1; j++) {
      if (arr[j].interacted_at_epoch < arr[j + 1].interacted_at_epoch) {
        var temp = arr[j];
        arr[j] = arr[j + 1];
        arr[j + 1] = temp;
      }
    }
  }
  return arr;
};

const TimelinesApp = ({ newDetailsPage }) => {
  const dispatch = useDispatch();
  const {
    gmail_events: gmailEvents,
    public_activity_events: publicActivityEvents,
    task_events: taskEvents,
    timeline_events: timelineEvents,
  } = useSelector(({ personDetail }) => personDetail.timeline);
  const timelineLoading = useSelector(({ personDetail }) => personDetail.timeline_loading);
  const timelineLoadingSuccess = useSelector(({ personDetail }) => personDetail.timeline_loading_success);
  const person = useSelector(({ personDetail }) => personDetail.person);
  const [sortedTimeline, setSortedTimeline] = useState([]);
  const [filters, _setFilters] = useState({
    appliedFilters: new Set(INITIAL_FILTERS),
    accountActivityFilter: true,
    webActivityFilter: true,
    otherActivityFilter: true,
  });
  const setFilters = (newData) => _setFilters((prevData) => ({ ...prevData, ...newData }));

  // TODO: setup a websocket for timeline so we don't need to call
  // fetchTimeline() with each update event
  useEffect(() => {
    if (person.data?.id) dispatch(actions.fetchTimeline(person.data.attributes.slug));
  }, [person.data?.id]);

  const handleCheckboxChange = (e) => {
    const filtersToChange = e.target.name.split(" ");
    const value = e.target.value;
    const inputClass = e.target.input_class;
    const newAppliedFilters = new Set(filters.appliedFilters);
    if (value) {
      filtersToChange.forEach((filter) => newAppliedFilters.add(filter));
    } else {
      if (inputClass.includes("activity-filter")) {
        setFilters({ activityFilter: false });
      } else if (inputClass.includes("interaction-filter")) {
        setFilters({ interactionFilter: false });
      }

      filtersToChange.forEach((filter) => newAppliedFilters.delete(filter));
    }
    console.log(newAppliedFilters);

    setFilters({ appliedFilters: newAppliedFilters });
  };

  const handleAllActivityGroupCheckboxChange = () => {
    setFilters({
      appliedFilters: new Set(INITIAL_FILTERS),
      otherActivityFilter: true,
      accountActivityFilter: true,
      webActivityFilter: true,
    });
  };

  const handleAccountActivityGroupCheckboxChange = () => {
    const accountActivityFilter = !filters.accountActivityFilter;
    const appliedFilters = new Set(filters.appliedFilters);

    if (accountActivityFilter) {
      appliedFilters.add("action-event");
      appliedFilters.add("document-event");
      appliedFilters.add("task-event");
      appliedFilters.add("login-event");
      appliedFilters.add("person_created");
      appliedFilters.add("person_updated");
    } else {
      appliedFilters.delete("action-event");
      appliedFilters.delete("document-event");
      appliedFilters.delete("task-event");
      appliedFilters.delete("login-event");
      appliedFilters.delete("person_created");
      appliedFilters.delete("person_updated");
    }

    setFilters({ accountActivityFilter, appliedFilters });
  };

  const handleWebActivityGroupCheckboxChange = () => {
    const webActivityFilter = !filters.webActivityFilter;
    const appliedFilters = new Set(filters.appliedFilters);

    if (webActivityFilter) {
      WEB_ACTIVITY_EVENT_TYPES.forEach((e) => {
        appliedFilters.add(e);
      });
    } else {
      WEB_ACTIVITY_EVENT_TYPES.forEach((e) => {
        appliedFilters.delete(e);
      });
    }

    setFilters({ webActivityFilter, appliedFilters });
  };

  const handleOtherActivityCheckboxChange = () => {
    const otherActivityFilter = !filters.otherActivityFilter;
    const appliedFilters = new Set(filters.appliedFilters);

    if (otherActivityFilter) {
      appliedFilters.add("Other-event");
      appliedFilters.add("Pop by-event");
      appliedFilters.add("Mail-event");
      appliedFilters.add("Social media-event");
    } else {
      appliedFilters.delete("Other-event");
      appliedFilters.delete("Pop by-event");
      appliedFilters.delete("Mail-event");
      appliedFilters.delete("Social media-event");
    }

    setFilters({ otherActivityFilter, appliedFilters });
  };

  useEffect(() => {
    const newTimeline = [];

    timelineEvents?.forEach((event) => {
      if (event.event_type === "property_view") return;
      newTimeline.push(event);
    });

    taskEvents?.forEach(function (event) {
      if (filters.appliedFilters.has(event.interaction_type + "-event")) {
        newTimeline.push(event);
      }
    });

    gmailEvents?.forEach(function (event) {
      if (filters.appliedFilters.has("message-event")) {
        newTimeline.push(event);
      }
    });

    publicActivityEvents?.forEach((event) => {
      if (filters.appliedFilters.has(`${event.interaction_type}-event`)) {
        newTimeline.push(event);
      }
    });

    setSortedTimeline(timelineBubbleSort(newTimeline));
  }, [filters, publicActivityEvents, timelineEvents, taskEvents, gmailEvents]);

  const handleFilterStatsButtonClick = (filter) => {
    if (filter === "other-activity") {
      setFilters({
        appliedFilters: new Set(["Pop by-event", "Other-event", "Mail-event", "Social media-event"]),
        otherActivityFilter: true,
        accountActivityFilter: false,
        webActivityFilter: false,
      });
    } else if (filter === "web-activity") {
      setFilters({
        appliedFilters: new Set(WEB_ACTIVITY_EVENT_TYPES),
        otherActivityFilter: false,
        accountActivityFilter: false,
        webActivityFilter: true,
      });
    } else if (filter === "account-activity") {
      setFilters({
        appliedFilters: new Set(["action-event", "document-event", "task-event", "login-event"]),
        otherActivityFilter: false,
        accountActivityFilter: true,
        webActivityFilter: false,
      });
    } else if (filter === "appointment-event") {
      setFilters({
        appliedFilters: new Set(["appointment-event", "appointment_scheduled-event"]),
        otherActivityFilter: false,
        accountActivityFilter: false,
        webActivityFilter: false,
      });
    } else {
      setFilters({
        appliedFilters: new Set(filter.split(" ")),
        otherActivityFilter: false,
        accountActivityFilter: false,
        webActivityFilter: false,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div
          className={`col-xs-12 ${personDetailCss.cardRoundedTop}`}
          style={{ backgroundColor: "#ffffff", paddingBottom: "15px", borderBottom: "1px solid #E6E6E6" }}
        >
          <PersonTimelineFilter
            accountActivityFilter={filters.accountActivityFilter}
            appliedFilters={filters.appliedFilters}
            handleAccountActivityGroupCheckboxChange={handleAccountActivityGroupCheckboxChange}
            handleAllActivityGroupCheckboxChange={handleAllActivityGroupCheckboxChange}
            handleCheckboxChange={handleCheckboxChange}
            handleFilterStatsButtonClick={handleFilterStatsButtonClick}
            handleOtherActivityCheckboxChange={handleOtherActivityCheckboxChange}
            handleWebActivityGroupCheckboxChange={handleWebActivityGroupCheckboxChange}
            initialFilters={new Set(INITIAL_FILTERS)}
            otherActivityFilter={filters.otherActivityFilter}
            webActivityEventTypes={[...WEB_ACTIVITY_EVENT_TYPES]}
            webActivityFilter={filters.webActivityFilter}
          />
        </div>
      </div>
      <div className="timeline-content">
        {timelineLoading && <LoadingSkeleton />}
        {!timelineLoading && !timelineLoadingSuccess && <LoadingFailure />}
        {!timelineLoading && timelineLoadingSuccess
          ? sortedTimeline.map((event) => (
              <ErrorBoundary key={event.id}>
                <EventComponentSelector
                  appliedFilters={filters.appliedFilters}
                  event={event}
                  newDetailsPage={newDetailsPage}
                  webActivityEventTypes={WEB_ACTIVITY_EVENT_TYPES}
                />
              </ErrorBoundary>
            ))
          : null}
      </div>
    </>
  );
};

export default TimelinesApp;
