import axios from 'axios';

// Index

const fetchMilestones = () => {
  return {
    type: 'FETCH_MILESTONES',
    isFetchingMilestones: true,
  }
}
const milestonesSuccess = (milestones) => {
  return {
    type: 'MILESTONES_SUCCESS',
    milestones,
  }
}

export const getMilestones = () => {
  const path = '/new_milestones.json';
  return dispatch => {
    dispatch(fetchMilestones())
    axios.get(path)
      .then(results => {
        dispatch(milestonesSuccess(results.data))
      })
      .catch(err => {
        dispatch({type: 'MILESTONES_FAILURE', isFetchingMilestones: false})
      }
    );
  }
}


// New

export const showNewMilestoneModal = () => {
  return dispatch => {
    dispatch({type: 'MILESTONE_SHOW_NEW_MODAL'})
  }
}

export const closeNewMilestoneModal = () => {
  return dispatch => {
    dispatch({type: 'MILESTONE_CLOSE_NEW_MODAL'})
  }
}

export const milestoneFormUpdate = (name, value) => {
  return {
    type: 'MILESTONE_FORM_UPDATE',
    name,
    value
  }
}

export const submitNewMilestone = (milestoneCreatePath, milestone) => {
  const formData = { ...milestone }
  return dispatch => {
    dispatch({type: 'MILESTONE_CREATE_PENDING'})

    axios.post(milestoneCreatePath, { milestone: formData, authenticity_token: ReactOnRails.authenticityToken() })
    .then(res => {
      dispatch({type: 'MILESTONE_CREATE_SUCCESS'})
      dispatch(getMilestones())
    })
    .catch(err => {
      dispatch({type: 'MILESTONE_CREATE_FAILURE', errors: err.response.data.errors})
    })
  }
}


// Edit

export const showEditMilestoneModal = (milestone) => {
  return dispatch => {
    dispatch({
      type: "MILESTONE_SHOW_EDIT_MODAL",
      milestone
    });
  };
};

export const closeEditMilestoneModal = () => {
  return dispatch => {
    dispatch({type: 'MILESTONE_CLOSE_EDIT_MODAL'})
  }
}

export const submitUpdateMilestone = (milestoneUpdatePath, milestone) => {
  const formData = { ...milestone }
  return dispatch => {
    dispatch({type: 'MILESTONE_UPDATE_PENDING'})

    axios.patch(milestoneUpdatePath, { milestone: formData, authenticity_token: ReactOnRails.authenticityToken() })
    .then(res => {
      dispatch({type: 'MILESTONE_UPDATE_SUCCESS'})
      dispatch(getMilestones())
    })
    .catch(err => {
      dispatch({type: 'MILESTONE_UPDATE_FAILURE', errors: err.response.data.errors})
    })
  }
}


// Delete

export const showDeleteMilestoneModal = (milestone) => {
  return dispatch => {
    dispatch({
      type: "MILESTONE_SHOW_DELETE_MODAL",
      milestone
    });
  };
};

const deleteMilestoneFailure = (errors) => {
  return {
    type: 'DELETE_MILESTONE_FAILURE',
    errors: errors
  }
}

const deleteMilestoneSuccess = () => {
  return {
    type: 'DELETE_MILESTONE_SUCCESS'
  }
}

const deleteMilestonePending = () => {
  return {
    type: 'DELETE_MILESTONE_PENDING'
  }
}

export const closeDeleteMilestoneModal = () => {
  return dispatch => {
    dispatch({type: 'MILESTONE_CLOSE_DELETE_MODAL'})
  }
}

export const deleteMilestone = (path) => {
  return dispatch => {
    dispatch(deleteMilestonePending())
    axios.delete(path, { params: { authenticity_token: ReactOnRails.authenticityToken() }})
      .then(res => {
        dispatch(deleteMilestoneSuccess())
      })
      .catch(err => {
        dispatch(deleteMilestoneFailure(err.response.data.errors))
      })
  }
}
