import React, { Component } from 'react';
import capitalize from 'capitalize';

export default class FacebookAdRetargetEvent extends Component {
  constructor(props, context) {
    super(props, context)
    this.event = this.props.event
    this.event_type = this.props.event_type
  }

  contactName = () => {
    const changeset = this.event.timeline_fields
    const first_name = !changeset['f_name'] ? '' : changeset['f_name']
    const last_name = !changeset['l_name'] ? '' : changeset['l_name']
    let contact_name = ''

    if(first_name || last_name){
      contact_name = `${first_name} ${last_name}`.trim()
    }else {
      contact_name = 'No Name'
    }

    return contact_name;
  }

  property = (propertyObject) => {
    const bedString = !propertyObject.bedrooms ? "" : propertyObject.bedrooms + " Bed"
    const bathString = !propertyObject.bathrooms ? "" : propertyObject.bathrooms + " Bath"
    const bedBathString = (bedString + " " + bathString).trim()

    return(
        <div className="property-wrapper"
             style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' + propertyObject.main_photo + ')'}}>
          <div className="inset-info">
            <span className="price">{'$'+propertyObject.value}</span>
            <span>{propertyObject['street_address']}</span>
            <span>{propertyObject['city']}, {propertyObject['state']} {propertyObject['zip']}</span>
            <span>{bedBathString}</span>
          </div>
        </div>
    )
  }

  render() {

    return (
      <div className="timeline-event property-image-event">
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-repeat timeline-icon-circle"/>
          </div>
          <div className="col-xs-8">
            <span className="bold-detail">
              {this.contactName()}
            </span>
            {` returned to website via Brivity Connect Retargeting Campaign`}
            <br />
            <div className="timeline-description-text">
              {this.event.timeline_fields.property && this.property(this.event.timeline_fields.property)}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{this.event.interacted_at_absolute}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-1"/>
          <div className="col-xs-11">
            <div className="row details-row">
              Tag: {this.event.timeline_fields.utm_campaign}
            </div>
              {this.event.timeline_fields.referrer &&
                <div className="row details-row">
                  URL: <a href={this.event.timeline_fields.referrer} target='_blank'>{this.event.timeline_fields.referrer.slice(0, 50)}</a>
                </div>
              }
          </div>
        </div>
      </div>
    )
  }
}
