/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const Play = (props) => (
  <Icon {...props} name="Play">
    <path d="M13.506 6.807 3.835.804C3.049.317 1.846.79 1.846 1.995v12.002c0 1.082 1.118 1.734 1.989 1.191l9.671-6c.863-.534.865-1.849 0-2.383z" />
  </Icon>
);

export default Play;
