/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const ArrowUp = (props) => (
  <Icon {...props} name="Arrow Up">
    <path d="m2.294 8.986-.67-.653a.69.69 0 0 1 0-.997l5.863-5.717a.735.735 0 0 1 1.023 0l5.866 5.717a.69.69 0 0 1 0 .997l-.67.653a.738.738 0 0 1-1.035-.012L9.207 5.43v8.452a.714.714 0 0 1-.724.706h-.966a.714.714 0 0 1-.724-.706V5.43L3.329 8.974a.733.733 0 0 1-1.035.012z" />
  </Icon>
);

export default ArrowUp;
