import React from "react";

const TableHeader = ({ onClick, sortable, children, className = "", centerChildren = false }) => (
  <th
    className={`tw-py-8px tw-px-16px tw-font-semibold ${sortable ? "tw-cursor-pointer" : ""} ${className}`}
    onClick={onClick}
  >
    <div
      className={`tw-flex tw-gap-4px tw-text-gray-50 ${centerChildren ? "tw-justify-center tw-items-center" : ""}`}
    >
      {children}
    </div>
  </th>
);

export default TableHeader;
