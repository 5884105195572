import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

const LastSeen = ({ smsHistory }) => {
  if (smsHistory.length === 0) {
    return null;
  }

  const lastInteraction = smsHistory[smsHistory.length - 1];
  const lastSeen = moment(lastInteraction.interacted_at || lastInteraction.dateCreated);
  const formattedTime = lastSeen.fromNow();

  return (
    <div className="tw-flex tw-space-x-4px tw-text-12px tw-text-neutral-gray-50">
      <div>Last seen</div>
      <div>{formattedTime}</div>
    </div>
  );
};

const interactionShape = { interacted_at: PropTypes.string, dateCreated: PropTypes.string };

LastSeen.propTypes = {
  smsHistory: PropTypes.arrayOf(PropTypes.shape(interactionShape)).isRequired,
};

export default LastSeen;
