import React from "react";
import { connect } from "react-redux";

import { arrayOf, bool, func, string } from "prop-types";
import { deleteTasksAsThunk, bulkEditTasksAsThunk } from "../actions/thunks";

import withFilters from "../HOCs/withFilters";
import Modal from "../../../../shared/v2/Modal";
import Button from "../../../../shared/v2/Button";

const ModalDeleteTask = ({
  isTaskModalDeleteOpen,
  setTaskModalOpen,
  setTaskModalDelete,
  onDeleteTasksAsThunk,
  onBulkEditTasksAsThunk,
  ids,
  uuid,
  taskFilters,
}) => (
  <Modal
    show={isTaskModalDeleteOpen}
    className="tw-flex tw-items-center tw-justify-center"
    contentClassName="tw-w-[496px]"
    onHide={() => {
      setTaskModalDelete((prev) => !prev);
      setTaskModalOpen((prev) => !prev);
    }}
    closeButton
  >
    <Modal.Header title={`Delete ${ids.length} Task?`} className="tw-mb-32px" closeButton />
    <Modal.Body className="tw-mb-32px tw-max-h-[70vh] tw-overflow-auto">
      Unlike archiving a contact, deleting a task is permanent. Your client won’t be able to see that the
      task(s) was deleted, but they also won’t be able to see if you completed the task(s), either.
    </Modal.Body>
    <Modal.Footer className="tw-flex tw-flex-row">
      <Button
        className="tw-mr-auto"
        size="medium"
        schema="tertiary"
        onClick={() => {
          setTaskModalDelete((prev) => !prev);
          setTaskModalOpen((prev) => !prev);
        }}
      >
        CANCEL
      </Button>
      <Button
        size="medium"
        schema="warning"
        onClick={() => {
          if (ids.length > 1) {
            onBulkEditTasksAsThunk(ids, uuid, taskFilters, { _destroy: true });
          } else {
            onDeleteTasksAsThunk(ids, uuid, taskFilters);
          }

          setTaskModalDelete((prev) => !prev);
        }}
      >
        YES, DELETE
      </Button>
    </Modal.Footer>
  </Modal>
);

ModalDeleteTask.propTypes = {
  isTaskModalDeleteOpen: bool,
  setTaskModalOpen: func,
  setTaskModalDelete: func,
  onDeleteTasksAsThunk: func,
  ids: arrayOf(string),
  uuid: string,
  taskFilters: string,
  onBulkEditTasksAsThunk: func,
};

ModalDeleteTask.defaultProps = {
  isTaskModalDeleteOpen: false,
  setTaskModalOpen: null,
  setTaskModalDelete: null,
  onDeleteTasksAsThunk: null,
  ids: [],
  uuid: null,
  taskFilters: "",
  onBulkEditTasksAsThunk: null,
};

const mapStateToProps = (state) => ({
  uuid: state.tdpTasksReducer.uuid,
});

const mapDispatchToProps = (dispatch) => ({
  onBulkEditTasksAsThunk: (selectedTasks, listingUuid, filtered, payload) =>
    dispatch(bulkEditTasksAsThunk(selectedTasks, listingUuid, filtered, payload)),
  onDeleteTasksAsThunk: (ids, uuid, taskFilters) => dispatch(deleteTasksAsThunk(ids, uuid, taskFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withFilters(ModalDeleteTask));
